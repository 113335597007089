import type { CollectionViewGroupBy } from '@next-space/fe-api-idl';
import {
  CollectionSchemaType,
  CollectionViewType,
  GroupLevel,
  GroupSortType,
} from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { getViewFormat } from './get-view-format';

export const useUpdateGroupProperty = () => {
  const updateGroupProperty = (viewId: string, propertyId: string, isSubGroup = false) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { view, collection, groupBy, subGroupBy } = viewInfo;

    const oldGroupBy = isSubGroup ? subGroupBy : groupBy;
    let newGroupBy: CollectionViewGroupBy = {
      property: propertyId,
      hideEmptyGroups: oldGroupBy?.hideEmptyGroups,
      enableBoardColorColumns: oldGroupBy?.enableBoardColorColumns,
    };

    const schemaType = collection.data.schema?.[propertyId]?.type;
    switch (schemaType) {
      case CollectionSchemaType.NUMBER: {
        newGroupBy = {
          ...newGroupBy,
          start: 0,
          end: 1000,
          size: 100,
          sort: GroupSortType.ASCENDING,
        };
        break;
      }

      case CollectionSchemaType.TITLE:
      case CollectionSchemaType.TEXT:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL:
      case CollectionSchemaType.EMAIL: {
        newGroupBy = { ...newGroupBy, groupLevel: GroupLevel.EXACT, sort: GroupSortType.ASCENDING };
        break;
      }

      case CollectionSchemaType.CREATED_AT:
      case CollectionSchemaType.UPDATED_AT:
      case CollectionSchemaType.DATE: {
        newGroupBy = {
          ...newGroupBy,
          groupLevel: GroupLevel.RELATIVE,
          sort: GroupSortType.ASCENDING,
        };
        break;
      }

      case CollectionSchemaType.RELATION: {
        newGroupBy = { ...newGroupBy, sort: GroupSortType.ASCENDING };
        break;
      }

      default:
        break;
    }

    const isBoardColumn = view.type === CollectionViewType.BOARD && !isSubGroup;
    updateViewFormat(viewId, {
      [`${isBoardColumn ? 'boardGroupBy' : 'collectionGroupBy'}`]: newGroupBy,
      [`${isBoardColumn ? 'boardGroups' : 'collectionGroups'}`]: [],
    });
  };

  return useCallback(updateGroupProperty, []);
};
