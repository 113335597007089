import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
/**
 * 查找地址query参数
 */
export const useGetQuery = (search?: string) => {
  const location = useLocation();
  return useCallback(
    (paramKey: string, defaultValue?: string) => {
      const query = new URLSearchParams(search ?? location.search);
      const param = query.get(paramKey);
      return param ? param : defaultValue;
    },
    [location.search, search]
  );
};
