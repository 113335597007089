/**
 * Grid Layout 组件, 按需渲染 Cell
 */

import type { ReactElement, ReactNode } from 'react';
import { forwardRef, useImperativeHandle } from 'react';

import type { ScrollRef } from '../types';
import { Content } from './content';
import { GridProvider, useGrid } from './context';

export type Grid = ReturnType<typeof useGrid> | undefined;

export interface Props {
  scrollXRef: ScrollRef;
  scrollYRef: ScrollRef;
  rowCount: number;
  colCount: number;
  getColWidth(index: number): number;
  getRowHeight(index: number): number;
  getRowKey(index: number): string;
  getColKey(index: number): string;
  renderCell(x: number, y: number): ReactNode;
  renderRow?(y: number, cells: ReactElement[]): ReactElement;
}

export const Grid = forwardRef<Grid, Props>((props, ref) => {
  return (
    <GridProvider {...props}>
      <GridRef ref={ref} />
      <Content />
    </GridProvider>
  );
});

const GridRef = forwardRef<Grid>((_props, ref) => {
  const grid = useGrid();
  useImperativeHandle(ref, () => grid, [grid]);
  return null;
});
