import type { FC } from 'react';

import { Button } from '../button';
import { Input } from '../input';

interface Props {
  onChange: (value: string) => void;
  placeholder: string;
  onButtonClick: () => void;
}
export const InputWithButton: FC<Props> = (props) => {
  return (
    <div className="flex flex-col w-[370px] next-modal px-2.5 py-3.5">
      <Input
        autoFocus
        className="h-8 rounded-sm"
        placeholder={props.placeholder}
        onChange={props.onChange}
        onEnter={props.onButtonClick}
      />
      <Button
        size="large"
        colorType="active"
        className="mt-3.5 text-t4-medium"
        onClick={props.onButtonClick}
      >
        提交
      </Button>
    </div>
  );
};
