import { globalResizeObserver } from '@flowus/common/utils/global-resize-observer';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { setAppUiState } from 'src/services/app';

export const useObserverBodySize = () => {
  useEffect(() => {
    const resize = debounce(
      (entry: ResizeObserverEntry) => {
        const { offsetWidth, offsetHeight } = entry.target as HTMLElement;
        setAppUiState({
          $isMobileSize: offsetWidth <= 640,
          $bodyHeight: offsetHeight,
          $bodyWidth: offsetWidth,
        });
      },
      500,
      {
        leading: true,
        trailing: true,
      }
    );

    const unobserve = globalResizeObserver.observe(document.body, resize);

    return unobserve;
  }, []);
};
