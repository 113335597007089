import { useEffect } from 'react';
import { request } from 'src/common/request';
import { useCurrentSpace } from 'src/hooks/space';
import type { LocalUser } from 'src/redux/reducers/users';
import { usersActions } from 'src/redux/reducers/users';
import { cache, dispatch } from 'src/redux/store';
import { useSubscriptionDataUsersById } from 'src/services/app/hook/subscription-data';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $appUiStateCache } from '../../services/app/hook';

export const useGetAllSpaceUsers = () => {
  const currentSpace = useCurrentSpace();

  useEffect(() => {
    const getUsers = async () => {
      const subscriptionData = $appUiStateCache.$subscriptionData;
      const spaceData = subscriptionData[currentSpace.uuid];
      if (spaceData) {
        const { users } = spaceData;
        const requests = users
          .filter(({ userId }) => !cache.users[userId])
          .map(({ userId }) => ({ id: userId, table: 'user' as 'user' }));
        if (requests.length) {
          const { users } = await request.editor.queryRecords({
            requests,
          });
          if (users) {
            dispatch(usersActions.updateSpaceUsers({ users }));
          }
        }
      }
    };

    void getUsers();
  }, [currentSpace.uuid]);
};

const useSpacePermissions = () => {
  const currentSpace = useCurrentSpace();
  return useSubscriptionDataUsersById(currentSpace.uuid);
};

export const useUsers = () => {
  const permissions = useSpacePermissions();

  return useObservableStore(
    ({ users }) => permissions.map(({ userId }) => users[userId]).filter(Boolean) as LocalUser[],
    [permissions]
  );
};

export const useAllUser = () => {
  return useObservableStore((store) => store.users, [], { allUser: true });
};
