import type { VirtualElement } from '@popperjs/core';

/**
 * 根据焦点光标位置生成虚拟dom
 */
export const getSelectionVirtualElement = (): VirtualElement | null => {
  const range = window.getSelection().getRangeAt(0).cloneRange();
  const [rect] = range.getClientRects();
  if (rect) {
    return { getBoundingClientRect: () => rect };
  }
  return null;
};

/**
 * 根据element事件获取生成位置
 */
export const getVirtualElement = (element: React.MouseEvent | MouseEvent): VirtualElement => {
  const { clientX: x, clientY: y } = element;

  return {
    getBoundingClientRect: () => ({
      width: 0,
      height: 0,
      x,
      y,
      top: y,
      right: x,
      bottom: y,
      left: x,
      toJSON: () => 'null',
    }),
  };
};

/** 根据element获取rect */
export const elementToGetBoundingClientRect = (element: Element | VirtualElement) => {
  if (!isHTML(element)) {
    return element as VirtualElement;
  }
  const rect = element.getBoundingClientRect();
  return { getBoundingClientRect: () => rect };
};

export const isHTML = (element?: Element | VirtualElement) => {
  return Boolean((element as HTMLElement)?.tagName);
};
