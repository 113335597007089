import { CardColorSource, CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCardColorSetting } from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { useBitable } from '../../context';

export const CardColorList = [
  { type: CardColorSource.EMPTY, name: '不显示' },
  { type: CardColorSource.CUSTOM, name: '自定义颜色' },
  { type: CardColorSource.PROPERTY, name: '跟随单选属性颜色' },
];

export const ColorSettingList: FC = () => {
  const { viewId, viewType, collectionId } = useBitable();
  const { propertyId, type: colorType } = useCardColorSetting(viewId) ?? {};

  const [properties = []] = useProperties(viewId);
  const schema = useObservableBlock(collectionId, (block) => block?.data.schema ?? {});

  const [visible, setVisible] = useState(colorType === 'property');

  const selectProperties: ListItem<any>[] = [];
  Object.keys(schema).forEach((key) => {
    const propertySchema = schema[key];
    if (propertySchema?.type === CollectionSchemaType.SELECT) {
      selectProperties.push({
        type: ListItemType.OPERATION,
        data: {
          title: propertySchema.name,
          property: key,
          order: properties.findIndex((item) => item.property === key),
          icon: 'IcTitleSelect',
          hasArrow: key === propertyId,
          arrow: <Icon size="normal" name="IcCheck02" />,
        },
      });
    }
  });
  selectProperties.sort((a, b) => a.data.order - b.data.order);

  const items: ListItem<any>[] = [];
  CardColorList.forEach((item) => {
    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        type: item.type,
        hasArrow: item.type === colorType,
        arrow: <Icon size="normal" name="IcCheck02" />,
      },
    });
  });

  if (visible) {
    items.push(
      { type: ListItemType.LINE },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '单选',
          hasArrow: true,
          type: 'property',
          renderSubMenu: () => {
            return (
              <ListView
                className="next-modal-scroll py-1.5 w-[160px]"
                items={selectProperties}
                onItemClick={(item) => {
                  const { property } = item.data;

                  if (property !== propertyId) {
                    updateViewFormat(viewId, {
                      [viewType === CollectionViewType.TIMELINE
                        ? 'timelineColorSetting'
                        : 'calendarColorSetting']: {
                        type: CardColorSource.PROPERTY,
                        propertyId: property,
                      },
                    });
                  }
                }}
              />
            );
          },
        },
      }
    );
  }

  return (
    <div className="next-modal-scroll w-[160px] py-[5px]">
      <ListView
        items={items}
        onItemClick={async (item) => {
          const { type } = item.data;
          if (colorType !== type) {
            updateViewFormat(viewId, {
              [viewType === CollectionViewType.TIMELINE
                ? 'timelineColorSetting'
                : 'calendarColorSetting']: {
                type,
                propertyId: type === 'property' ? selectProperties[0]?.data.property : propertyId,
              },
            });

            setVisible(type === 'property');
          }
        }}
      />
    </div>
  );
};
