import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { getManaged, removeManaged, setManaged } from 'src/utils/local-storage';

export abstract class AbstractDraftManager {
  abstract hasUnsavedDrafts(): boolean;
  abstract clearUnsavedDrafts(): void;
  abstract loadDraft(key: string): SegmentDTO[] | undefined | undefined;
  abstract saveDraft(key: string, value: SegmentDTO[] | undefined, noSaveDraft: boolean): boolean;
}

export class LocalStorageDraftManager extends AbstractDraftManager {
  private unsavedDrafts = new Set<string>();

  constructor(private prefix = 'Draft:') {
    super();
  }

  override hasUnsavedDrafts() {
    return this.unsavedDrafts.size > 0;
  }

  override loadDraft(key: string) {
    return getManaged<SegmentDTO[]>(`${this.prefix}${key}`);
  }

  override clearUnsavedDrafts() {
    this.unsavedDrafts.clear();
  }

  override saveDraft(key: string, value: SegmentDTO[] | undefined, noSave = false) {
    const prefixedKey = `${this.prefix}${key}`;
    if (noSave) {
      removeManaged(prefixedKey);
      if (segmentsToText(value) !== '') {
        this.unsavedDrafts.add(key);
      } else {
        this.unsavedDrafts.delete(key);
      }
      return false;
    }
    if (value === undefined || segmentsToText(value) === '') {
      removeManaged(prefixedKey);
      return true;
    }
    const saved = setManaged(prefixedKey, value, {
      expiredAt: Date.now() + 1000 * 60 * 60 * 24,
      safeToPurge: false,
    });
    if (!saved) {
      this.unsavedDrafts.add(key);
    }
    return saved;
  }
}

const DraftContext = createContext<AbstractDraftManager | null>(null);

export const DraftProvider = (props: { manager: AbstractDraftManager; children?: ReactNode }) => {
  return <DraftContext.Provider value={props.manager}>{props.children}</DraftContext.Provider>;
};

export const useStateWithDraft = (
  key?: string,
  initialValue?: SegmentDTO[] | undefined,
  noSaveDraft = false
): [
  value: SegmentDTO[] | undefined,
  setValue: (
    cb: SegmentDTO[] | undefined | ((value: SegmentDTO[] | undefined) => SegmentDTO[] | undefined)
  ) => void
] => {
  const manager = useContext(DraftContext);
  const [value, setValue] = useState(
    initialValue ?? (key == null || noSaveDraft ? undefined : manager?.loadDraft(key))
  );
  const setValue2 = useCallback(
    (
      cb: SegmentDTO[] | undefined | ((value: SegmentDTO[] | undefined) => SegmentDTO[] | undefined)
    ) => {
      // 解决问题:发送评论后还有放弃保存这条评论的弹窗
      // 由于这个setValue+callback是异步的，可能还没执行就走到弹窗的逻辑（随机的，有时弹有时不弹），因为如果值是undefined就直接设置
      if (cb === undefined) {
        setValue(cb);
        if (key != null && manager != null) {
          void manager?.saveDraft(key, cb, noSaveDraft);
        }
        return;
      }
      setValue((v) => {
        const newValue = typeof cb === 'function' ? cb(v) : cb;
        if (key != null && manager != null) {
          void manager?.saveDraft(key, newValue, noSaveDraft);
        }
        return newValue;
      });
    },
    [key, manager, noSaveDraft]
  );
  return [value, setValue2];
};
