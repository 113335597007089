import { pick, reduce } from 'lodash-es';
import type { ToolbarShortcutDTO } from './type';

export const toolbarShortcutToArray = (toolbarShortcut: ToolbarShortcutDTO) => {
  return Object.values(toolbarShortcut).sort((a, b) => a.index - b.index);
};

export const toolbarShortcutToMap = (
  toolbarShortcut: ReturnType<typeof toolbarShortcutToArray>
) => {
  const obj = reduce(
    toolbarShortcut,
    (result, value, index) => {
      // 这样处理是为了避免多余的脏数据属性添加进来
      value.index = index;
      result[value.id] = pickToolbarShortcutMap(value);
      return result;
    },
    {} as ToolbarShortcutDTO
  );
  return obj;
};

export const pickToolbarShortcutMap = (toolbarItem: ToolbarShortcutDTO[string]) => {
  return pick(toolbarItem, ['type', 'id', 'fixed', 'title', 'index']);
};
