import { lightTagBackgroundColor } from '@flowus/common/block/color/data';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

interface Props {
  className?: string;
  text?: string;
  absolute?: boolean;
  spanClass?: string;
  onClick?: () => void;
}
export const ProIcon: FC<Props> = (props) => {
  const { spanClass, absolute = true, className, text = 'Pro' } = props;
  return (
    <div
      className={cx(
        'flex items-center justify-center px-1 whitespace-nowrap border border-[#FFECAA]',
        absolute && 'absolute right-[-4px] top-[-8px]',
        className
      )}
      style={{
        background: lightTagBackgroundColor.yellow,
        borderRadius: '10px 10px 10px 2px',
      }}
      onClick={props.onClick}
    >
      <span className={cx('text-t4 text-[#8F6C28]', spanClass)}>{text}</span>
    </div>
  );
};
