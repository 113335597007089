import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

const ONE_MINUTE_IN_MS = 1000 * 60;
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;
const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24;

export function timeAgoText(timestamp: number, now: number) {
  const distance = now - timestamp;
  if (distance <= ONE_MINUTE_IN_MS) {
    return '刚刚';
  }
  if (distance <= ONE_HOUR_IN_MS) {
    return `${Math.floor(distance / ONE_MINUTE_IN_MS)}分钟前`;
  }
  if (distance <= ONE_DAY_IN_MS) {
    return `${Math.floor(distance / ONE_HOUR_IN_MS)}小时前`;
  }

  const time = dayjs(timestamp);
  if (time.isSame(now, 'year')) {
    return time.format('M月D日 HH:mm');
  }

  return time.format('YYYY/M/D HH:mm');
}

function computeDistanceToNextUpdate(timestamp: number, now: number, delayInMs = 200) {
  const distance = now - timestamp;
  if (distance <= ONE_MINUTE_IN_MS) {
    return timestamp + ONE_MINUTE_IN_MS - now + delayInMs;
  }
  if (distance <= ONE_HOUR_IN_MS) {
    return ONE_MINUTE_IN_MS - (distance % ONE_MINUTE_IN_MS) + delayInMs;
  }
  if (distance <= ONE_DAY_IN_MS) {
    return ONE_HOUR_IN_MS - (distance % ONE_HOUR_IN_MS) + delayInMs;
  }
  // 一年就算了吧
  return null;
}

export const TimeAgo: FC<{ timestamp: number }> = ({ timestamp }) => {
  const [version, setVersion] = useState(0);
  useEffect(() => {
    const distanceToNextUpdate = computeDistanceToNextUpdate(timestamp, Date.now());
    if (distanceToNextUpdate == null) return;
    const timer = setTimeout(() => {
      setVersion((version) => version + 1);
    }, distanceToNextUpdate);
    return () => clearTimeout(timer);
  }, [setVersion, version, timestamp]);
  return <>{timeAgoText(timestamp, Date.now())}</>;
};
