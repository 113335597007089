import type { FC, MouseEvent } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { CreatePageWidthMode } from 'src/hooks/public/get-page-width-fixed';
import { useCurrentUser } from 'src/hooks/user';
import { SettingModalCommon } from '../common';
import { useUpdateUserSetting } from '../helper';
import { Option } from '../option';
import { createPageWidthList } from './const';

export const PageWidthSetting: FC<{}> = () => {
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const updateUserSetting = useUpdateUserSetting();
  const pageWidthMode = currentUser.setting?.pageWidthMode ?? CreatePageWidthMode.FIXED;
  const selectedMode = createPageWidthList.find((i) => i.value === pageWidthMode);

  const openMethodMenu = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-36 py-2.5"
          items={createPageWidthList.map((i) => ({
            type: ListItemType.OPERATION,
            data: {
              title: i.title,
              value: i.value,
              selected: pageWidthMode === i.value,
            },
          }))}
          onItemClick={(item) => {
            void updateUserSetting({ pageWidthMode: item.data.value });
            onCloseModal();
          }}
        />
      ),
    });
  };

  return (
    <Option
      title="新建页面宽度"
      description="新建文档页面会默认为指定方式"
      rightPart={
        <SettingModalCommon.DropdownButton onClick={openMethodMenu} content={selectedMode?.title} />
      }
    />
  );
};
