import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRecentlyUpdateCollection } from './get-recently-update-collection';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import type { NextBlock } from '@flowus/common/block/type';
import type { SearchItem } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';

export const useGetRecentlyUpdateCollection = () => {
  const [collectionItems, setCollectionItems] = useState<SearchItem[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    void getRecentlyUpdateCollection().then((res) => {
      if (res.recordMap?.blocks) {
        dispatch(
          blocksActions.update({
            blocks: res.recordMap?.blocks as Record<string, NextBlock>,
          })
        );
      }
      if (res.recordMap?.collectionViews) {
        dispatch(collectionViewsActions.update(res.recordMap.collectionViews));
      }
      const items = res.results
        .map((v) => {
          const block = res.recordMap?.blocks?.[v.pageId ?? ''];
          if (block) {
            return covertNextBlockToSearchItem(block as NextBlock);
          }
          return undefined;
        })
        .filter((v) => v) as SearchItem[];

      setCollectionItems(items);
    });
  }, [dispatch]);
  return collectionItems;
};
