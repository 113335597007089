import { AggregationAction } from '@next-space/fe-api-idl';
import _ from 'lodash-es';
export const countAll = (values: string[]) => {
  return values.length;
};
export const countAllWithDup = (values: string[]) => {
  return _.uniq(values ?? []).length;
};
export const sum = (values: string[]) => {
  values = values.filter((v) => v !== undefined);
  return _.sumBy(values, (a) => {
    return parseFloat(a);
  });
};
export const max = (values: string[]) => {
  values = values.filter((v) => v !== undefined);
  return _.maxBy(values, (a) => {
    return parseFloat(a);
  });
};
export const min = (values: string[]) => {
  values = values.filter((v) => v !== undefined);
  return _.minBy(values, (a) => {
    return parseFloat(a);
  });
};
export const meanBy = (values: string[]) => {
  values = values.filter((v) => v !== undefined);
  if (values.length === 0) return 0;
  return _.meanBy(values, (a) => {
    return parseFloat(a);
  });
};

export const CALCULATE_FUNS: Record<string, (values: string[]) => number | string | undefined> = {
  [AggregationAction.COUNT_ALL]: countAll,
  [AggregationAction.DEDUPLICATION]: countAllWithDup,
  [AggregationAction.SUM]: sum,
  [AggregationAction.MAX]: max,
  [AggregationAction.MIN]: min,
  [AggregationAction.AVERAGE]: meanBy,
};
