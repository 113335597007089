/** 文字背景色 浅 */
export const lightTextBackground: Record<string, string> = {
  grey: '#F2F2F2',
  brown: '#F0EDE0',
  orange: '#FFE9D4',
  yellow: '#FEF7D2',
  green: '#D9F2EE',
  blue: '#DCEEFF',
  purple: '#E9E9FF',
  pink: '#FEEAF2',
  red: '#FEE6E2',
  light_yellow: '#FFFCBF',
  light_green: '#D1FFF0',
  light_blue: '#CCF0FF',
  light_purple: '#E5DAFF',
  light_red: '#FFD3D3',
  yellow_green: '#F9F592,#96FBC4',
  green_blue: '#84FAB0,#79D3FF',
  blue_purple: '#6BE4FF,#928FFF',
  purple_red: '#D4A5FF,#FF7697',
  red_orange: '#F9748F,#FFC6AD',
};

/** 文字背景色 深 */
export const darkTextBackground: Record<string, string> = {
  grey: '#414242',
  brown: '#373428',
  orange: '#503011',
  yellow: '#5C4D19',
  green: '#233D29',
  blue: '#253E5A',
  purple: '#322B62',
  pink: '#422238',
  red: '#4F2828',
  light_yellow: '#675700',
  light_green: '#005E47',
  light_blue: '#00597E',
  light_purple: '#3B1695',
  light_red: '#873634',
  yellow_green: '#4B492C,#2D4B3B',
  green_blue: '#284B35,#243F4C',
  blue_purple: '#20444C,#2C2B4C',
  purple_red: '#40314C,#4C232D',
  red_orange: '#4B232B,#4C3B34',
};

/** 文字色 浅 */
export const lightTextColor: Record<string, string> = {
  grey: '#929495',
  brown: '#845C21',
  orange: '#E96900',
  yellow: '#E5B007',
  green: '#009828',
  blue: '#206CCF',
  purple: '#5543D3',
  pink: '#CB1E83',
  red: '#CB272D',
  black: '#000000',
};

/** 文字色 深 */
export const darkTextColor: Record<string, string> = {
  grey: '#7C7C7C',
  brown: '#95854C',
  orange: '#EA7E2A',
  yellow: '#BC9721',
  green: '#4DB972',
  blue: '#3A9FCA',
  purple: '#705EF4',
  pink: '#BB428B',
  red: '#D04B4B',
  black: '#B8B8B8',
};

/** Tag文字色 浅 */
export const lightTagTextColor: Record<string, string> = {
  grey: '#474D58',
  brown: '#51482B',
  orange: '#683D26',
  yellow: '#8C652F',
  green: '#3B5B46',
  blue: '#263C68',
  purple: '#472B84',
  pink: '#8B2B69',
  red: '#8B2730',
};

/** Tag文字色 深 */
export const darkTagTextColor: Record<string, string> = {
  grey: '#FFFFFFCC',
  brown: '#FFFFFFCC',
  orange: '#FFFFFFCC',
  yellow: '#FFFFFFCC',
  green: '#FFFFFFCC',
  blue: '#FFFFFFCC',
  purple: '#FFFFFFCC',
  pink: '#FFFFFFCC',
  red: '#FFFFFFCC',
};

/** Tag背景色 浅 */
export const lightTagBackgroundColor: Record<string, string> = {
  grey: '#ECEDED',
  brown: '#E8E5D7',
  orange: '#F8DEC5',
  yellow: '#FFF2C5',
  green: '#D2ECD5',
  blue: '#D2E1F9',
  purple: '#DCDCF8',
  pink: '#F8DAE6',
  red: '#FFD8D7',
};

/** Tag背景色 深 */
export const darkTagBackgroundColor: Record<string, string> = {
  grey: '#4B4B4B',
  brown: '#423D2B',
  orange: '#623B1A',
  yellow: '#6E5710',
  green: '#26472E',
  blue: '#26466A',
  purple: '#3A307B',
  pink: '#512241',
  red: '#632E2E',
};

/** 看板文字颜色 浅 */
export const lightBoardGroupTextColor: Record<string, string> = {
  grey: '#40454F',
  brown: '#494127',
  orange: '#5E3722',
  yellow: '#5E4422',
  green: '#35523F',
  blue: '#22365E',
  purple: '#402777',
  pink: '#8B2B69',
  red: '#7D232B',
};

/** 看板文字颜色 深 */
export const darkBoardGroupTextColor: Record<string, string> = {
  grey: '#AAAAAA',
  brown: '#A18151',
  orange: '#BC814F',
  yellow: '#B3973C',
  green: '#46A05E',
  blue: '#546D8F',
  purple: '#69629A',
  pink: '#945A7C',
  red: '#C1585C',
};

/** 看板数字颜色 浅 */
export const lightBoardGroupNumberTextColor: Record<string, string> = {
  grey: '#40454FCC',
  brown: '#494127CC',
  orange: '#5E3722CC',
  yellow: '#5E4422CC',
  green: '#35523FCC',
  blue: '#22365ECC',
  purple: '#402777CC',
  pink: '#8B2B69CC',
  red: '#7D232BCC',
};

/** 看板数字颜色 深 */
export const darkBoardGroupNumberTextColor: Record<string, string> = {
  grey: '#747474',
  brown: '#6F5E43',
  orange: '#796A37',
  yellow: '#7D5E42',
  green: '#3D6F4A',
  blue: '#4C6380',
  purple: '#5E598A',
  pink: '#84526F',
  red: '#814749',
};

/** 看板背景颜色 浅 */
export const lightBoardGroupBackgroundColor: Record<string, string> = {
  grey: '#F7F9F9',
  brown: '#F8F7F5',
  orange: '#FFF9F4',
  yellow: '#FFFCF3',
  green: '#F4F9F5',
  blue: '#F2F7FB',
  purple: '#F5F5FE',
  pink: '#FDF6F8',
  red: '#FDF6F6',
};

/** 看板背景颜色 深 */
export const darkBoardGroupBackgroundColor: Record<string, string> = {
  grey: '#252525',
  brown: '#27231E',
  orange: '#2A231D',
  yellow: '#29251B',
  green: '#1C261F',
  blue: '#1F242A',
  purple: '#23222C',
  pink: '#2A2027',
  red: '#2A1E1F',
};

/** 着重背景色 浅 */
export const lightMarkBlockBackgroundColor: Record<string, string> = {
  grey: '#F6F6F6',
  brown: '#F4F3ED',
  orange: '#FFF2E6',
  yellow: '#FEFAE4',
  green: '#E4F6F3',
  blue: '#E9F6FF',
  purple: '#EFEFFF',
  pink: '#FEF0F6',
  red: '#FEEFEC',
  light_yellow: '#FFFDD5',
  light_green: '#E1FFF5',
  light_blue: '#D9F4FF',
  light_purple: '#EEE7FF',
  light_red: '#FFE3E3',
};

/** 着重背景色 深 */
export const darkMarkBlockBackgroundColor: Record<string, string> = {
  grey: '#252525',
  brown: '#2A2820',
  orange: '#3F2F1F',
  yellow: '#41381B',
  green: '#1F3729',
  blue: '#203246',
  purple: '#272230',
  pink: '#341F2D',
  red: '#362121',
  light_yellow: '#4F4407',
  light_green: '#074939',
  light_blue: '#07455F',
  light_purple: '#30166F',
  light_red: '#652D2B',
};

/** 着重边框色 浅 */
export const lightMarkBlockBorderColor: Record<string, string> = {
  grey: '#E5E6E6',
  brown: '#E1DDCC',
  orange: '#FDD9B6',
  yellow: '#F9EBBC',
  green: '#C2E3C6',
  blue: '#CCDAF2',
  purple: '#D6D6F4',
  pink: '#F5DBE7',
  red: '#F7D2D1',
  black: '#000000',
};

/** 着重边框色 深 */
export const darkMarkBlockBorderColor: Record<string, string> = {
  grey: '#7C7C7C4D',
  brown: '#95854C4D',
  orange: '#EA7E2A4D',
  yellow: '#BC97214D',
  green: '#4DB9724D',
  blue: '#3A9FCA4D',
  purple: '#705EF44D',
  pink: '#BB428B4D',
  red: '#D04B4B4D',
  black: '#B8B8B84D',
};

/** 日历视图背景色 浅 */
export const lightCardBackgroundColor: Record<string, string> = {
  grey: '#F2F2F2',
  brown: '#F0EDE0',
  orange: '#FFE9D4',
  yellow: '#FEF7D2',
  green: '#DEF3E0',
  blue: '#DCEEFF',
  purple: '#E9E9FF',
  pink: '#FEEAF2',
  red: '#FEE6E2',
};

/** 日历视图背景色 深色 */
export const darkCardBackgroundColor: Record<string, string> = {
  grey: '#414242',
  brown: '#373428',
  orange: '#503011',
  yellow: '#5C4D19',
  green: '#233D29',
  blue: '#253E5A',
  purple: '#322B62',
  pink: '#422238',
  red: '#4F2828',
};

/** 多维表进度条 浅色 */
export const lightDataBaseProgressBar: Record<string, string> = {
  grey: '#A2A4A6',
  brown: '#A9834A',
  orange: '#FB7145',
  yellow: '#FFC408',
  green: '#32B851',
  blue: '#5094ED',
  purple: '#7767E5',
  pink: '#EB58AE',
  red: '#F95959',
};

/** 多维表进度条 深色 */
export const darkDataBaseProgressBar: Record<string, string> = {
  grey: '#7C7C7C',
  brown: '#95854C',
  orange: '#EA7E2A',
  yellow: '#BC9721',
  green: '#4DB972',
  blue: '#3A9FCA',
  purple: '#705EF4',
  pink: '#BB428B',
  red: '#D04B4B',
};

/** 多维表进度条 浅 */
export const lightDataBaseProgressBarBg: Record<string, string> = {
  grey: '#0000001A',
  brown: '#0000001A',
  orange: '#0000001A',
  yellow: '#0000001A',
  green: '#0000001A',
  blue: '#0000001A',
  purple: '#0000001A',
  pink: '#0000001A',
  red: '#0000001A',
};

/** 多维表进度条 深 */
export const darkDataBaseProgressBarBg: Record<string, string> = {
  grey: '#FFFFFF1A',
  brown: '#FFFFFF1A',
  orange: '#FFFFFF1A',
  yellow: '#FFFFFF1A',
  green: '#FFFFFF1A',
  blue: '#FFFFFF1A',
  purple: '#FFFFFF1A',
  pink: '#FFFFFF1A',
  red: '#FFFFFF1A',
};

/** icon库着色 浅 */
export const lightRaw: Record<string, string> = {
  grey: '#A2A4A6',
  brown: '#A9834A',
  orange: '#FB7145',
  yellow: '#FFC408',
  green: '#32B851',
  blue: '#5094ED',
  purple: '#7767E5',
  pink: '#EB58AE',
  red: '#F95959',
  black: '#000000',
};

/** icon库着色 深色 */
export const darkRaw: Record<string, string> = {
  grey: '#7C7C7C',
  brown: '#95854C',
  orange: '#EA7E2A',
  yellow: '#BC9721',
  green: '#4DB972',
  blue: '#3A9FCA',
  purple: '#705EF4',
  pink: '#BB428B',
  red: '#D04B4B',
  black: '#B8B8B8',
};

export const correctColorKey: Record<string, string> = {
  pink_purple: 'pink',
  blue_cobalt: 'blue',
};
