/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BlockStatus, BlockType, PermissionRole, TextType } from '@next-space/fe-api-idl';
import { TOC_CACHES } from 'src/redux/middlewares/cache-lift';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { isLinkPageBlock, isPageLike } from 'src/utils/block-type-utils';
import { getPermissions } from '../share/use-permissions';

export const useSubPages = (pageId?: string) => {
  return useObservableStore(
    (state) => {
      if (!pageId) {
        return [];
      }
      return getSubPages(state.blocks, pageId);
    },
    [pageId],
    { wait: 200 }
  );
};

export const getSubPages = (blocks: Record<string, NextBlock>, id: string) => {
  let subPages = TOC_CACHES.getToc(id);
  if (subPages) {
    return subPages;
  }

  const result: Set<string> = new Set();

  const loop = (blockId: string) => {
    const block = blocks[blockId];
    if (!block) return;

    if (blockId !== id) {
      block.data.segments?.forEach((segment) => {
        if (segment.type === TextType.LINK_PAGE) {
          const _id = segment.uuid;
          if (!_id) return;
          const _block = blocks[_id];
          if (!_block) return;
          if (_block.status === BlockStatus.DELETED) return;
          if (_block && _block.parentId === blockId) {
            result.add(_id);
          }
        }
      });
    }

    block.subNodes.forEach((item) => {
      const _block = blocks[item];
      if (!_block) return;
      const linkPage = isLinkPageBlock(_block);
      if (isPageLike(_block.type) || linkPage) {
        const { role } = getPermissions(_block.uuid);
        if (role !== PermissionRole.NONE) {
          result.add(item);
        }
      }
      if (isPageLike(_block.type) || _block.type === BlockType.TEMPLATE) {
        return;
      }
      loop(item);
    });
  };

  loop(id);

  subPages = [...result];
  TOC_CACHES.setToc(id, subPages);

  return subPages;
};
