import type { FC } from 'react';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { useModel } from 'src/common/create-model';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { Modals } from 'src/modals';
import { useSpaceCapacity } from 'src/services/capacity';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { UpgradeCouponList, UpgradePaddingUiKit } from './common';
import type { UpgradeSupportParams } from './hook/use-upgrade-support';
import { createUpgradeSupportModel } from './hook/use-upgrade-support';
import { UpgradeComponents } from './upgrade-components';

const UpgradeCapacityContext = createUpgradeSupportModel();

const UpgradeCapacity: FC = () => {
  const {
    couponId,
    selectCoupon,
    onPay,
    close,
    payMethod,
    setPayMethod,
    planId,
    onSelectPlan,
    planList,
    curCalculateQuote,
    loading,
    lastDate,
    couponList,
  } = useModel(UpgradeCapacityContext);
  const spaceCapacity = useSpaceCapacity();

  return (
    <>
      <UpgradeComponents.Header close={close} />
      <div className="px-7">
        <UpgradePaddingUiKit height={54} className="text-h2">
          购买容量包
        </UpgradePaddingUiKit>
        <UpgradePaddingUiKit height={36} className="text-grey3 text-t3">
          已使用{getReadableFileSizeString(spaceCapacity.currentCapacity)}，
          <span>
            &nbsp;总容量&nbsp;
            {getReadableFileSizeString(spaceCapacity.maxCapacity)}&nbsp;=&nbsp;
          </span>
          <span>{getReadableFileSizeString(spaceCapacity.baseCapacity)}基础容量</span>
          <span hidden={!(spaceCapacity.buyCapacity + spaceCapacity.giveCapacity)}>
            &nbsp;+&nbsp;
            {getReadableFileSizeString(spaceCapacity.buyCapacity + spaceCapacity.giveCapacity)}
            补充容量包
          </span>
          <span hidden={!spaceCapacity.peopleCapacity}>
            &nbsp;+&nbsp;
            {getReadableFileSizeString(spaceCapacity.peopleCapacity)}席位容量
          </span>
        </UpgradePaddingUiKit>
      </div>
      {/* 选择套餐 */}
      <UpgradeComponents.SelectPlan
        items={planList}
        loading={loading}
        selectId={planId}
        itemClick={onSelectPlan}
        itemRender={(item) => {
          return (
            <>
              <div className="text-t2-medium mb-2.5">{item.name}</div>
              <div className="text-h3">
                {getCurrencySymbols(item.currentPriceUnit)}
                {item.currentPrice}/年
              </div>
            </>
          );
        }}
      />
      {/* 购买后的失效日期 */}
      <UpgradeComponents.PlanEndTime endTime={lastDate} />
      {/* 优惠券 */}
      <UpgradeCouponList
        onSelectId={selectCoupon}
        couponList={couponList}
        selectId={couponId}
        className={'px-8 my-2.5'}
      />
      {/* 购买方式 */}
      <UpgradeComponents.PayMethod payMethod={payMethod} switchPayMethod={setPayMethod} />
      {/* 付费 */}
      <UpgradeComponents.PayBar
        price={curCalculateQuote?.amount}
        priceUnit={curCalculateQuote?.amountUnit}
        disablePay={loading}
        onPay={onPay}
      />
    </>
  );
};

export const useOpenUpgradeCapacityModal = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params?: UpgradeSupportParams) =>
      openModal.modal({
        modalId: Modals.UPGRADE_SUPPORT,
        className: 'w-[600px] max-h-[80vh] next-modal-scroll',
        content: () => (
          <UpgradeCapacityContext
            planId={params?.planId}
            isFrom={params?.isFrom}
            upgradeType="capacity"
          >
            <UpgradeCapacity />
          </UpgradeCapacityContext>
        ),
      }),
    [openModal]
  );
};
