import type { BlockType, PermissionDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { BlockStatus, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useTransaction } from 'src/hooks/use-transaction';
import { CREATE_BLOCK, LIST_AFTER_BLOCK, LIST_BEFORE_BLOCK } from 'src/redux/actions';
import { dispatch, getState } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { TocType } from 'src/views/main/aside/toc/types';
import { v4 as uuidV4 } from 'uuid';
import { getPageWidthFixed } from '../public/get-page-width-fixed';
import { getCurrentSpace } from '../space';
import { getCurrentSpaceView } from '../space/get-space';
import { useCurrentUser } from '../user';

/**
 * 创建页面
 * 目前指只支持创建空页面
 */
export const useCreateDocPage = () => {
  const transaction = useTransaction();
  const currentUser = useCurrentUser();

  const createDocPage = (
    type:
      | BlockType.PAGE
      | BlockType.FOLDER
      | BlockType.COLLECTION_VIEW_PAGE
      | BlockType.MIND_MAPPING_PAGE,
    where: NextWhere,
    permissionType?: TocType,
    segments?: SegmentDTO[]
  ) => {
    checkIsMaximumCapacityDialog();
    const { blocks } = getState();
    const parent = blocks[where.parentId];
    if (!parent) return;

    const spaceId =
      // @ts-ignore 某个情况下，space 的 block 居然没有 spaceId
      (parent.type === 'space' ? parent.uuid : parent.spaceId) || getCurrentSpace().uuid;
    const uuid = uuidV4();

    const permissions: PermissionDTO[] = [];
    if (permissionType === TocType.PRIVATE) {
      permissions.push({
        role: PermissionRole.EDITOR,
        type: PermissionType.USER,
        userId: currentUser.uuid,
      });
    }
    if (permissionType === TocType.SPACE) {
      permissions.push({
        role: PermissionRole.EDITOR,
        type: PermissionType.SPACE,
      });
    }

    transaction(
      () => {
        const createdAt = Date.now();
        dispatch(
          CREATE_BLOCK({
            block: {
              uuid,
              spaceId,
              parentId: where.parentId,
              textColor: '',
              backgroundColor: '',
              type,
              version: 0,
              status: BlockStatus.NORMAL,
              local: true,
              data: {
                segments: segments ?? [],
                pageFixedWidth: getPageWidthFixed(),
                directoryMenu: $currentUserCache.setting?.directoryMenu,
                format: {
                  pageLayout: getCurrentSpaceView()?.setting.pageLayout,
                  fontFormat: getCurrentSpaceView()?.setting.fontFormat,
                  commentAlignment: getCurrentSpace().setting?.commentAlignment ?? 'top',
                },
              },
              subNodes: [],
              permissions,
              updatedBy: $currentUserCache.uuid,
              createdAt,
              updatedAt: createdAt,
            },
          })
        );

        if (where.first || where.before) {
          dispatch(
            LIST_BEFORE_BLOCK({
              uuid,
              parentId: where.parentId,
              before: where.before,
              ignoreUpdateTime: true,
            })
          );
        } else {
          dispatch(
            LIST_AFTER_BLOCK({
              uuid,
              parentId: where.parentId,
              after: where.after,
              ignoreUpdateTime: true,
            })
          );
        }
      },
      { noThrottle: true }
    );

    return uuid;
  };

  return useCallback(createDocPage, [currentUser.uuid, transaction]);
};
