import { cx } from '@flowus/common/cx';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import type { BlockElement } from '../core/type';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

/**
 * Header 组件没有 children
 */
export const HeaderElement: BlockElement = ({ id, root, children }) => {
  const block = usePickBlock(id, ['data'], ['level']);
  const scene = usePageScene();

  if (!block) return null;

  const { level = 1 } = block.data;

  return (
    <BlockDrop
      id={id}
      TagName={`h${level}` as any}
      horizontal={root}
      className={cx(
        'heading',
        scene !== PageScene.PAGE_FEEDS && {
          'mt-[25px] mb-px': level === 1,
          'mt-[15px] mb-px': level === 2,
          'my-2': level === 3,
          'my-px': level === 4,
        }
      )}
    >
      <Editable
        className={cx('w-full px-0.5', {
          'text-h1 py-1.5': level === 1,
          'text-h2 py-2': level === 2,
          'text-h3 py-0.5': level === 3,
          'text-h4 py-0.5': level === 4,
        })}
        uuid={id}
        placeholder={`标题${level}`}
      />
      {children && <BlockChildren blockId={id}>{children}</BlockChildren>}
    </BlockDrop>
  );
};
