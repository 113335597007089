import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { SearchParams, ViewPath } from 'src/utils';
import { getLocationOrigin } from 'src/utils/location-utils';
import { useCurrentUser } from '../user';

export const useShareSpaceLink = () => {
  const { spaceShareInfo } = useCurrentSpacePlanInfo();
  const currentUser = useCurrentUser();
  const spaceShareLink = `${getLocationOrigin()}${ViewPath.login}?${SearchParams.inviteTeam}=${
    spaceShareInfo?.inviterId
  }&${SearchParams.inviteCode}=${currentUser.inviteCode}`;

  return spaceShareLink;
};
