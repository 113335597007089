import { cx } from '@flowus/common/cx';
import type { UserDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { colors } from 'src/colors';
import { Avatar } from 'src/common/components/avatar';
import { useUserName } from 'src/hooks/user/use-remark-name';

/** 用户头像 */
export const UserAvatar: FC<{ user?: UserDTO; className?: string; forceNickname?: string }> = ({
  user,
  className,
  forceNickname,
}) => {
  const userName = useUserName(user?.uuid ?? '', user?.nickname);
  return (
    <Avatar
      color={user?.backgroundColor || colors.grey}
      name={forceNickname ?? userName}
      icon={{ type: 'upload', value: user?.avatar }}
      className={cx('text-white1 rounded-full', className)}
      imgClassName="rounded-full"
    />
  );
};
