import { cx } from '@flowus/common/cx';
import { useContext, type FC } from 'react';
import { NoBindIdContext } from 'src/editor/editor/raw/no-bind-id-context';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useIsSelected } from 'src/redux/managers/ui';
import { useIsSelecting } from 'src/services/app';
import { useDropInfoIsHovering } from 'src/services/app/hook/use-drop-info';

interface Props {
  /** id */
  uuid: string;
  showDefaultBorder?: boolean;
}

export const DriveDnd: FC<JSX.IntrinsicElements['div'] & Props> = ({
  className,
  children,
  uuid,

  showDefaultBorder,
  ...rest
}) => {
  const isDragging = useIsDragging();
  const isHovering = useDropInfoIsHovering(uuid);
  const isSelected = useIsSelected(uuid);
  const isSelecting = useIsSelecting();
  const canBindId = useContext(NoBindIdContext);

  return (
    <div
      {...rest}
      data-draggable
      data-no-cancel-selected
      data-block-id={canBindId ? uuid : undefined}
      className={cx(
        'transition-border mt-px',
        isDragging && 'pointer-events-none',
        isSelected && 'bg-active_color_10',
        className
      )}
      style={{
        boxShadow:
          isSelected || (isDragging && isHovering)
            ? '0px 0px 0px 1px var(--active_color)'
            : showDefaultBorder
            ? '0px 1px 0px 0px var(--grey6)'
            : 'none',
      }}
    >
      <div className={cx('contents', isSelecting && 'pointer-events-none')}>{children}</div>
    </div>
  );
};
