import { cx } from '@flowus/common/cx';
import { MindMapContext } from '@flowus/mind-map';
import type { FC } from 'react';
import { useContext } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { HeaderCover } from 'src/views/main/page-doc/header-cover';
import { RichText } from '../uikit/editable/rich-text';
import type { PageProps } from './types';
import { useFetchFirstTextBlock } from './use-fetch-first-text-block';

export const SmallDefaultCard: FC<PageProps> = (props) => {
  const { id, ownerBlockId, illegal, showIcon, showCover, desc } = props;
  const firstTextBlockSegments = useFetchFirstTextBlock(props.id, !desc);
  const isInMinMap = !!useContext(MindMapContext);
  return (
    <div
      className={cx('flex justify-between items-center w-full h-[90px] animate-hover pl-3', {
        'min-w-[300px] max-w-[800px]': isInMinMap,
        'border-l border-t border-b': showCover,
        border: !showCover,
      })}
      contentEditable={false}
      onClick={props.onClick}
    >
      <div className="flex items-center overflow-hidden">
        {showIcon && (
          <IconTrigger
            trigger={!ownerBlockId} // feat:引用page不支持更改图标
            iconSize={40}
            className="mr-2"
            tooltipClassName="flex-shrink-0"
            hasOverlay={Boolean(ownerBlockId)}
            blockId={id}
            offset={[100, 0]}
            defaultIcon={<BlockDefaultIcon uuid={id} />}
          />
        )}
        <div>
          <div className="text-t1 h-6 overflow-hidden break-words line-clamp-1">
            {illegal ? ILLEGAL_TEXT : props.title}
          </div>
          {!illegal && desc && (
            <div
              className="text-t4 text-grey3 h-4 overflow-hidden pr-1 mt-1 break-words line-clamp-1"
              style={{
                color: props.descColor,
              }}
            >
              {desc}
            </div>
          )}
          {!illegal && !desc && firstTextBlockSegments && (
            <RichText
              className={cx('text-t4 text-grey3 h-4 overflow-hidden pr-1 mt-1 line-clamp-1')}
              segments={firstTextBlockSegments}
            />
          )}
        </div>
      </div>
      {showCover && (
        <HeaderCover
          uuid={props.id}
          height={90}
          hiddenEditorButton={true}
          className="flex-shrink-0 relative rounded-r w-[30%]"
        />
      )}
    </div>
  );
};
