import type { Context } from '../../core/context';
import type { IPropertyListDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isIdentToken, parseFunctionArgs } from '../syntax/parser';
import type { StringValueToken } from '../syntax/tokenizer';
import type { LengthPercentage } from '../types/length-percentage';
import { isLengthPercentage } from '../types/length-percentage';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum BACKGROUND_SIZE {
  AUTO = 'auto',
  CONTAIN = 'contain',
  COVER = 'cover',
}

export type BackgroundSizeInfo = LengthPercentage | StringValueToken;
export type BackgroundSize = BackgroundSizeInfo[][];

export const backgroundSize: IPropertyListDescriptor<BackgroundSize> = {
  name: 'background-size',
  initialValue: '0',
  prefix: false,
  type: PropertyDescriptorParsingType.LIST,
  parse: (_context: Context, tokens: CSSValue[]): BackgroundSize => {
    return parseFunctionArgs(tokens).map((values) => values.filter(isBackgroundSizeInfoToken));
  },
};

const isBackgroundSizeInfoToken = (value: CSSValue): value is BackgroundSizeInfo =>
  isIdentToken(value) || isLengthPercentage(value);
