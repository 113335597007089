import { CollectionViewType } from '@next-space/fe-api-idl';
import type { Placement } from '@popperjs/core';
import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { HelperIcon } from 'src/components/helper-icon';
import { useAddCollectionView } from 'src/hooks/block/use-add-collection-view';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { useBitable } from '../../context';
import { TableViewMap, ViewIconMap } from '../const';

const AddViewInlineList: FC<{
  onClose: () => void;
  closeAddPanel: () => void;
}> = ({ onClose, closeAddPanel }) => {
  const { changeView, embed, collectionId, viewParentId } = useBitable();
  const collection = usePickBlock(collectionId, ['views']);

  const [viewType, setViewType] = useState(CollectionViewType.TABLE);
  const addCollectionView = useAddCollectionView();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const viewTitle = useRef('');
  const isLinkTable = collectionId !== viewParentId;

  const submit = (closeAddPanel: () => void) => {
    bizTracker.event('bitable_view_create', {
      from_scene: embed ? 'inline' : 'inThePage',
      view_type: viewType,
    });

    const hasTableView = collection?.views?.some(
      (it) => cache.collectionViews[it]?.type === CollectionViewType.TABLE
    );
    const newViewId = addCollectionView(viewParentId, viewTitle.current, viewType);
    if (newViewId) {
      changeView(newViewId);
    }
    if (!hasTableView && viewType === CollectionViewType.FORM) {
      addCollectionView(viewParentId, '表单视图', CollectionViewType.TABLE);
    }
    closeAddPanel();
    onClose();
  };

  return (
    <div className="w-[240px] next-modal p-2" onClick={() => inputRef.current?.focus()}>
      <Input
        autoFocus
        inputRef={inputRef}
        placeholder="请输入名称"
        className="h-8 flex-1"
        onChange={(text) => (viewTitle.current = text)}
        onEnter={() => submit(closeAddPanel)}
      />

      <div className="p-1 mb-1">
        <div className="text-t2 text-grey3 my-2">视图类型</div>
        {(isLinkTable
          ? TableViewMap.filter((i) => i.type !== CollectionViewType.FORM)
          : TableViewMap
        ).map((view) => (
          <div
            className="flex justify-between items-center animate-hover px-1"
            key={view.type}
            onClick={() => setViewType(view.type)}
          >
            <div className="flex h-8 items-center">
              <Icon name={ViewIconMap[view.type]} size="middle" />
              <span className="text-t2 ml-1">{view.name}</span>
            </div>
            <span className="flex items-center">
              {viewType === view.type && <Icon name="IcCheck02" size="small" />}
              <HelperIcon popup="查看视图帮助" link={view.helperLink} />
            </span>
          </div>
        ))}
      </div>

      <Button
        className="h-8 text-t2"
        size="auto"
        colorType="active"
        onClick={() => submit(closeAddPanel)}
      >
        创建
      </Button>
    </div>
  );
};

export const useShowAddViewPanel = () => {
  const openModal = useOpenModal();

  const showAddViewPanel = (popcorn: HTMLElement, onClose: () => void, placement?: Placement) => {
    openModal.dropdown({
      popcorn,
      placement: placement ?? 'right',
      content({ onCloseModal }) {
        return <AddViewInlineList onClose={onClose} closeAddPanel={onCloseModal} />;
      },
    });
  };

  return useCallback(showAddViewPanel, [openModal]);
};
