import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, CollectionViewType, GroupLevel } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useRef } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { EditorPersonList } from 'src/components/editor-person-list';
import { SearchMenu } from 'src/components/search';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useAddGroup } from 'src/hooks/block/use-add-group';
import {
  useCardImageDir,
  useCardSize,
  useGroupBy,
} from 'src/hooks/collection-view/use-collection-view';
import { useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';
import { $searchParams } from 'src/utils';
import { getGroupSchemaType } from './bitable-manager/group-list/helper';
import { getCardSize } from './const';
import { useBitable } from './context';

interface Props {
  isSubGroup?: boolean;
}
export const AddGroup: FC<Props> = ({ isSubGroup = false }) => {
  const { viewId, viewType, readonly, managerReadonly, isLocked, collectionId, embed } =
    useBitable();
  const openModal = useOpenModal();
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);
  const groupBy = useGroupBy(viewId, isSubGroup);

  if (readonly || managerReadonly || isLocked || $searchParams.print) return null;

  const schemaType = getGroupSchemaType(collectionId, groupBy?.property);
  if (
    disableAddGroup(groupSchema?.type) ||
    (schemaType === 'text' && groupBy?.groupLevel === GroupLevel.ALPHABET_PREFIX)
  ) {
    return null;
  }

  const isBoardColumn = viewType === CollectionViewType.BOARD && !isSubGroup;
  if (isBoardColumn) {
    const width = getCardSize(cardSize, imageDir);
    return (
      <div className="flex-shrink-0 bg-white1" style={{ width }}>
        <div className={cx('bg-grey/5 rounded-md p-[5px]')}>
          <button
            className="animate-hover-black3 flex h-[38px] w-full items-center rounded-md"
            onClick={(event) => {
              openModal.dropdown({
                popcorn: event.currentTarget,
                placement: 'bottom',
                content: ({ onCloseModal }) => (
                  <AddGroupInput isSubGroup={isSubGroup} width={width} closeModal={onCloseModal} />
                ),
              });
            }}
          >
            <Icon size="middle" name="IcAddBoard" className="text-grey3 mx-[5px]" />
            <span className="text-t2 text-grey4">创建分组</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <button
        className={cx(
          'animate-hover-black3 sticky flex h-[38px] items-center rounded-md pr-2 pl-1',
          embed ? 'left-0' : 'left-24'
        )}
        onClick={(event) => {
          openModal.dropdown({
            popcorn: event.currentTarget,
            placement: 'bottom-start',
            content: ({ onCloseModal }) => (
              <AddGroupInput isSubGroup={isSubGroup} width={270} closeModal={onCloseModal} />
            ),
          });
        }}
      >
        <Icon size="middle" name="IcAddBoard" className="text-grey4 mr-[5px]" />
        <span className="text-t2 text-grey4">创建分组</span>
      </button>
    </div>
  );
};

interface AddGroupInputProps {
  closeModal: () => void;
  width: number;
  isSubGroup?: boolean;
}

const AddGroupInput: FC<AddGroupInputProps> = ({ closeModal, isSubGroup = false }) => {
  const { viewId } = useBitable();
  const openModal = useOpenModal();
  const addGroup = useAddGroup();
  const valueRef = useRef('');
  const inputRef = useRef<HTMLInputElement>(null);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);

  const submit = () => {
    const newGroupName = valueRef.current.replace(/,/g, '').trim();
    if (!newGroupName) {
      closeModal();
      return;
    }

    if (checkRepeatName(viewId, newGroupName, isSubGroup)) {
      openModal.warning({
        title: <>已存在同名分组</>,
        noCancel: true,
        confirm() {
          inputRef.current?.focus();
        },
      });

      return;
    }

    addGroup(viewId, newGroupName, isSubGroup);
    closeModal();
  };

  const onSelect = (newGroupName: string) => {
    if (checkRepeatName(viewId, newGroupName)) {
      openModal.warning({
        title: <>已存在同名分组</>,
        noCancel: true,
      });
      return;
    }

    addGroup(viewId, newGroupName, isSubGroup);
    closeModal();
  };

  if (groupSchema?.type === CollectionSchemaType.PERSON) {
    return (
      <div className="next-modal">
        <EditorPersonList onSelect={onSelect} />
      </div>
    );
  }

  if (groupSchema?.type === CollectionSchemaType.RELATION) {
    return (
      <SearchMenu
        onSelect={({ uuid }) => onSelect(uuid)}
        searchType="page"
        source="relationMenu"
        canSearchWithEmpty={true}
        parentId={groupSchema.collectionId}
      />
    );
  }

  return (
    <div className="next-modal box-border flex w-[320px] py-2 pr-3">
      <Input
        autoFocus
        inputRef={inputRef}
        showBorder={false}
        className="text-t2 h-8 flex-1 rounded-sm border-none !bg-transparent"
        onEnter={submit}
        onChange={(text) => (valueRef.current = text)}
      />
      <Button
        onClick={submit}
        colorType="active"
        className="text-t4 flex h-[32px] w-[62px] items-center border-none text-white"
      >
        完成
      </Button>
    </div>
  );
};

const disableAddGroup = (schemaType?: CollectionSchemaType) => {
  return (
    schemaType === CollectionSchemaType.CHECKBOX ||
    schemaType === CollectionSchemaType.NUMBER ||
    schemaType === CollectionSchemaType.DATE ||
    schemaType === CollectionSchemaType.FORMULA ||
    schemaType === CollectionSchemaType.CREATED_AT ||
    schemaType === CollectionSchemaType.CREATED_BY ||
    schemaType === CollectionSchemaType.UPDATED_AT ||
    schemaType === CollectionSchemaType.UPDATED_BY
  );
};

export const checkRepeatName = (viewId: string, newGroupName: string, isSubGroup?: boolean) => {
  const { groups = [], subGroups = [] } = getViewFormat(viewId) ?? {};
  const oldGroups = isSubGroup ? subGroups : groups;
  return !!oldGroups.find((option) => option.value === newGroupName);
};
