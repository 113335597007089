import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { Input } from 'src/common/components/input';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useAllSpaces, useCurrentSpace } from 'src/hooks/space';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { cache } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';
import { getDiffPermissions } from 'src/utils/permission-utils';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import type { SearchItem } from '../search';
import type { MovePanelProps } from './types';
import { RecentlyList } from './recently-list';
import { ResultList } from './result-list';
import { SpaceList } from './space-list';
import { TocList } from './toc-list';

/** 移动到新ui */
export const MovePanel: FC<MovePanelProps> = (props) => {
  const ids = useMemo(() => {
    const ids = cache.ui.selectedBlocks.length
      ? selectedBlocksToIds(cache.ui.selectedBlocks)
      : [props.uuid];
    return ids;
  }, [props.uuid]);

  const [keyword, setKeyword] = useState('');

  const onInputChange = useCallback((v: string) => {
    setKeyword(v);
  }, []);
  const hideCategory = !!keyword;
  const spaces = useAllSpaces();
  const isTeamSpace = useIsTeamSpace();
  const currentSpace = useCurrentSpace();
  const [spaceItems, setSpaceItems] = useState<SearchItem[]>([]);

  // NOTE:这部分代码是为了计算空间个数的，spaceItems.len > 0 的情况下才展示空间tab
  useLayoutEffect(() => {
    // 只允许用户把 page 移动到根目录
    const { role, isRestricted, permissions } = getPermissions(ids[0]);
    const { spacePermission } = getDiffPermissions(permissions);
    const block = cache.blocks[ids[0] ?? ''];
    if (!block) return;
    if (
      ids.every((id) => {
        const _block = cache.blocks[id];
        if (!_block) return false;
        return isPageLike(_block.type);
      })
    ) {
      const items = [];
      // 移动到个人根目录需要全部权限
      if (role === PermissionRole.EDITOR) {
        const spaceItems: SearchItem[] = [];

        const { spaceId } = block;
        spaces.forEach((space) => {
          // 外部协作空间
          if (!space.permissions) return;

          const isSelfSpace = spaceId === space.uuid;
          const item: SearchItem = {
            ...space,
            title: isSelfSpace ? '个人页面' : space.title,
            avatarTitle: space.title,
            // @ts-ignore: 为了和 SPACE 区分
            type: isSelfSpace ? 'PRIVATE' : 'SPACE',
            data: {
              icon: space.icon,
            },
          };

          if (isSelfSpace) {
            spaceItems.unshift(item);
          } else {
            spaceItems.push(item);
          }
        });

        items.unshift(...spaceItems);
      }

      const canMoveToSpace =
        spacePermission && spacePermission.role !== PermissionRole.NONE
          ? role === PermissionRole.EDITOR || (isRestricted && role === PermissionRole.WRITER)
          : role === PermissionRole.EDITOR;

      if (isTeamSpace && canMoveToSpace) {
        const spaceItem: SearchItem = {
          ...currentSpace,
          title: '空间页面',
          avatarTitle: currentSpace.title,
          // @ts-ignore: SPACE 也看作 block
          type: 'SPACE',
          data: {
            icon: currentSpace.icon,
          },
        };
        items.unshift(spaceItem);
      }
      setSpaceItems(items);
    }
  }, [currentSpace, ids, isTeamSpace, spaces]);

  return (
    <div
      className="relative pt-2.5 next-modal w-[360px]"
      style={{
        paddingBottom: props.footer ? '40px' : undefined,
      }}
    >
      <Input
        autoFocus
        className="h-8 mx-2.5"
        {...bindDataTestId(TestIds.moveToInput)}
        placeholder={props.placeholder || `搜索页面`}
        onChange={onInputChange}
      />
      {hideCategory && <ResultList {...props} keyword={keyword} />}
      {!hideCategory && <div className="bg-grey6 h-px w-full mt-3.5" />}
      {!hideCategory && (
        <Tab border={false} defaultActiveIndex={1}>
          {spaceItems.length > 0 && (
            <TabPane title="空间">
              <SpaceList
                uuids={ids}
                spaceItems={spaceItems}
                onSelect={(pageInfo) => {
                  props.onSelect?.(pageInfo);
                }}
              />
            </TabPane>
          )}
          <TabPane title="最近使用">
            <RecentlyList
              onSelect={(pageInfo) => {
                props.onSelect?.(pageInfo);
              }}
            />
          </TabPane>
          <TabPane title="目录">
            <TocList
              onSelect={(pageInfo) => {
                props.onSelect?.(pageInfo);
              }}
            />
          </TabPane>
        </Tab>
      )}
      {props.footer}
    </div>
  );
};
