import type { createRequest } from '@next-space/fe-api-idl';

const ACTIVE_FROM_KEY = 'active-from';

const DURATION = 30 * 1000;
/** 临时活跃统计埋点 */
export class ActiveTracker {
  private active = true;
  private timer?: number;
  private lastEnterDate?: Date;
  private request: ReturnType<typeof createRequest>;

  constructor(request: any) {
    this.request = request;
  }
  /**获取对应时区的时间，东x区为正，西x区为负 */
  private getTimeWithTimeZone = (timeZone: number) => {
    const now = new Date();
    //零时区的时间
    let zeroTimeZone = now.getTime() + now.getTimezoneOffset() * 60 * 1000;
    //timeZone时区时间
    zeroTimeZone += timeZone * 60 * 60 * 1000;
    const date = new Date(zeroTimeZone);
    return date;
  };

  setActive = (active: boolean) => {
    this.active = active;
  };
  /**检查是否是同一天 */
  private checkIfSameDay = () => {
    const now = this.getTimeWithTimeZone(8);
    //当前年份或月份或天数对不上说明已经不是当天，需要报一个enter
    if (
      this.lastEnterDate?.getFullYear() !== now.getFullYear() ||
      this.lastEnterDate?.getMonth() !== now.getMonth() ||
      this.lastEnterDate?.getDay() !== now.getDay()
    ) {
      //确保enter成功再更新日期
      void this.reportEnter();
    }
  };

  setFrom(from: string) {
    if (from) {
      sessionStorage.setItem(ACTIVE_FROM_KEY, from);
    }
  }

  reportEnter = async (
    business: { spaceId?: string; activeFrom?: 'new_activation' | 'share_link' } = {}
  ) => {
    const activeFrom = sessionStorage.getItem(ACTIVE_FROM_KEY) || undefined;
    sessionStorage.removeItem(ACTIVE_FROM_KEY);

    let retry = 5;
    while (retry > 0) {
      try {
        await this.request.infra.behaviorReport.raw({
          topic: 'activity',
          business: {
            type: 'enter',
            activeFrom,
            ...business,
          },
        });
        this.lastEnterDate = this.getTimeWithTimeZone(8);
        break;
      } catch (err) {
        await sleep(1500);
        retry--;
      }
    }
  };

  start() {
    void this.reportEnter();
    this.timer = window.setTimeout(this.loop, DURATION);
  }

  stop() {
    window.clearTimeout(this.timer);
  }

  private loop = () => {
    if (this.active) {
      this.checkIfSameDay();
      this.active = false;
      this.request.infra.behaviorReport
        .raw({ topic: 'activity', business: { type: 'active' } })
        .catch(() => {});
    }
    this.timer = window.setTimeout(this.loop, DURATION);
  };
}

const sleep = (durationInMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, durationInMs);
  });
};
