import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { SearchParams } from '@flowus/login/const';
import { createMemoryHistory } from 'history';
import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { LoadingContainer } from 'src/common/components/loading-container';
import { NextModalProvider } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { useNormalizePath } from 'src/hooks/page';
import { StoreContextProvider } from 'src/services/store-context/provider';
import { getLocationOrigin } from 'src/utils/location-utils';
import { $pageId, PAGE_SCOPE } from 'src/views/main/page-states';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import type { PageContent } from './page-content';
import { judgeSharePage } from 'src/utils/getPageId';
import { ViewPath } from 'src/utils';

interface Props {
  pageId: string;
  blockId?: string;
  loading?: boolean;
  once?: boolean;
}
let PageContent0: typeof PageContent;

export const antiCycleSet_PageContent = (pageContent: typeof PageContent) => {
  PageContent0 = pageContent;
};
/**
 * 1.没有任何网络操作
 * 2.不响应任何点击(比如点击page块不会进页面)
 */
export const LitePage: FC<Props> = (props) => {
  const { pageId, blockId, loading, once } = props;

  let path = useNormalizePath(pageId);
  if (blockId) {
    path += `#${blockId}`;
  }
  const templateHistory = useFinalValue(() => createMemoryHistory({ initialEntries: [path] }));

  useEffect(() => {
    const unregister = templateHistory.block(() => {
      // 禁止一切跳转
      return false;
    });
    return unregister;
  }, [templateHistory]);

  return (
    <StoreContextProvider once={once} wait={1000} waitMode="debounce">
      <Router history={templateHistory}>
        <JotaiProvider scope={PAGE_SCOPE} initialValues={[[$pageId, pageId]]}>
          <PageSceneContext.Provider value={PageScene.PAGE_LITE_PREVIEW}>
            <EditableNamespace.Provider value={UUID_NAMESPACE.PAGE_LITE}>
              <NextModalProvider>
                {loading ? <LoadingContainer className="flex-1" /> : <PageContent0 uuid={pageId} />}
              </NextModalProvider>
            </EditableNamespace.Provider>
          </PageSceneContext.Provider>
        </JotaiProvider>
      </Router>
    </StoreContextProvider>
  );
};

export const IframeLitePage: FC<{
  id: string;
  className?: string;
  share?: boolean;
}> = memo((props) => {
  const { id, className, share } = props;

  return (
    <iframe
      className={cx('w-full h-full', className)}
      sandbox="allow-same-origin allow-top-navigation allow-forms	allow-scripts"
      src={`${getLocationOrigin()}${
        share ?? judgeSharePage() ? ViewPath.share : ViewPath.main
      }${id}?${SearchParams.embed}=true`}
    ></iframe>
  );
});
