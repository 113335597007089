import equal from 'fast-deep-equal/es6/react';
import _equal from 'fast-deep-equal/es6';
import { shallowEqual } from 'react-redux';
// 为了其他地方不引入这个库
export const deepEqual = equal;
export const fastEqual = _equal;

export const deepEqualLoose = (obj1: any, obj2: any): boolean => {
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    const keys1 = Object.keys(obj1).filter((k) => obj1[k] !== undefined);
    const keys2 = Object.keys(obj2).filter((k) => obj2[k] !== undefined);
    keys1.sort();
    keys2.sort();
    if (!shallowEqual(keys1, keys2)) {
      return false;
    }
    for (const k of keys1) {
      if (!deepEqualLoose(obj1[k], obj2[k])) {
        return false;
      }
    }
    return true;
  }
  return deepEqual(obj1, obj2);
};
