import { replace, split, toLower } from 'lodash-es';

/** 获取string的文字，转小写并去掉空格 */
export const stringToLowerCaseAndRemoveSpace = (str = '') => {
  return toLower(replace(str, /\s+/g, ''));
};

export const getIsChinese = (str = '') => {
  const reg = /^[\u4e00-\u9FA5]+$/;
  return reg.test(str);
};

export const insertString = (str: string, char: string, start: number, end?: number) => {
  if (!str) {
    return char;
  }
  if (!char) {
    return str;
  }

  const arr = split(str, '');
  arr.splice(start, end ?? 0, char);
  return arr.join('');
};

export const capitalizeFirstLetter = (str = '') => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toLowerCaseFirstLetter = (str = '') => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
