import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { publicLink } from 'src/common/const';

export const useOpenDownloadDesktopDialog = () => {
  const openModal = useOpenModal();

  return useCallback(
    (lowVersion: boolean) => {
      openModal.warning({
        title: lowVersion ? '当前客户端版本过小' : '达文件数量/大小处理上限',
        content: lowVersion ? '请到官网下载最新版本' : '请使用客户端',
        cancelText: '取消',
        confirmText: '去下载',
        colorType: 'active',
        confirm: () => {
          window.open(publicLink.appDownload);
        },
      });
    },
    [openModal]
  );
};
