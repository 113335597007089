// eslint-disable-next-line import/no-unresolved
import { parse } from 'csv-parse/browser/esm/sync';
import { readTextFromBlob } from 'src/components/file-preview/txt';
import { Regex } from '.';

export interface Guest {
  name: string;
  contact: string;
}
const MAX_LINE_COUNT = 2000;
const FILE_LIMIT_SIZE = 3 * 1024 * 1024; // 3M
/** 读取外来协作者名单 */
export const readGuestListFromFile = async (file: File): Promise<Guest[]> => {
  if (file.size > FILE_LIMIT_SIZE) {
    throw Error('文件大小不能超过3Mb');
  }

  if (file.name.endsWith('.txt')) {
    const text = await readTextFromBlob(file);
    if (typeof text === 'string') {
      return readGuestListFromTxt(text);
    }
  } else if (file.name.endsWith('.csv')) {
    const text = await readTextFromBlob(file);
    if (typeof text === 'string') {
      return readGuestListFromCsv(text);
    }
  }
  return [];
};
/** 解析csv文本,如果手机号码相同，则默认后面覆盖前面的 */
const readGuestListFromCsv = (text: string) => {
  let result;
  try {
    result = parse(text);
  } catch {
    throw Error(`csv文件格式不对`);
  }
  const guests: Map<string, string> = new Map<string, string>();
  if (result instanceof Array) {
    if (result.length > MAX_LINE_COUNT) {
      throw Error(`csv文件不能超过${MAX_LINE_COUNT}行`);
    }
    result.forEach((r, index) => {
      if (r instanceof Array && r.length === 2) {
        const name = r[0];
        const contact = r[1];
        if (!name || !contact) {
          throw Error('csv格式不对');
        }

        if (!__BUILD_IN__) {
          if (!Regex.Mobile.test(contact)) {
            throw new Error(`${index + 1}, [${r.join(',')}]`);
          }
        } else {
          if (!Regex.Email.test(contact)) {
            throw new Error(`${index + 1}, [${r.join(',')}]`);
          }
        }

        guests.set(contact, name);
      } else {
        throw Error('csv格式不对');
      }
    });

    const obj = Array.from(guests, ([contact, name]) => {
      return { contact, name } as Guest;
    });
    return obj;
  }
  throw Error('csv格式不对');
};
/** 解析txt文本，如果手机号码相同，则默认后面覆盖前面的 */
const readGuestListFromTxt = (text: string) => {
  let lines = text.split(/[\r\n]+/g);
  lines = lines.filter((l) => l.trim()); // 过滤掉空行
  const guests: Map<string, string> = new Map<string, string>();
  if (lines.length > MAX_LINE_COUNT) {
    throw Error(`txt文件不能超过${MAX_LINE_COUNT}行`);
  }
  lines.forEach((line, index) => {
    const strArr = line.split(',');
    if (strArr.length !== 2) {
      throw new Error(`${index + 1}, [${line}]`);
    }
    const name = strArr[0];
    const contact = strArr[1];
    if (!name || !contact) {
      throw new Error(`${index + 1}, [${line}]`);
    }
    if (__BUILD_IN__) {
      if (!Regex.Email.test(contact)) {
        throw new Error(`${index + 1}, [${line}]`);
      }
    } else {
      if (!Regex.Mobile.test(contact)) {
        throw new Error(`${index + 1}, [${line}]`);
      }
    }
    guests.set(contact, name);
  });
  const obj = Array.from(guests, ([contact, name]) => {
    return { contact, name } as Guest;
  });
  return obj;
};
