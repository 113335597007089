import { cacheFileSizeMap } from 'src/services/global-cache';

export function getReadableFileSizeString(fileSizeInBytes = 0, fractionDigits = 1) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (fileSizeInBytes === 0) return `0B`;

  const { value, index } = getReadableFileSize(fileSizeInBytes, fractionDigits);

  return `${value}${units[index]}`;
}

export function getReadableFileSize(fileSizeInBytes = 0, fractionDigits = 1) {
  const cacheKey = `${fileSizeInBytes}-${fractionDigits}`;
  // 如果已经计算过了，直接返回
  if (cacheFileSizeMap.has(cacheKey)) {
    return cacheFileSizeMap.get(cacheKey);
  }

  if (fileSizeInBytes === 0) {
    return {
      value: 0,
      index: 0,
    };
  }

  let i = 0;
  let target = Math.max(1, fileSizeInBytes);
  while (target > 1024) {
    target = target / 1024;
    i++;
  }

  const result = { value: Number(target.toFixed(fractionDigits).replace(/\.0+$/, '')), index: i };

  // 存储计算结果
  cacheFileSizeMap.set(cacheKey, result);

  return result;
}
