import { memo } from 'react';
import { useTableInfo } from './hook';
import { TableCell } from './table-cell';
import { useTableContext } from './table-context';

export const TableRow = memo(({ rowIndex }: { rowIndex: number }) => {
  const { tableId, spans, recordId } = useTableContext();
  const { tableBlockColumnOrder } = useTableInfo(tableId);

  return (
    <tr className="align-baseline">
      {tableBlockColumnOrder.map((propertyId, columnIndex) => {
        const span = spans[recordId]?.[propertyId] ?? {};

        if (span.colSpan === 0 || span.rowSpan === 0) {
          return null;
        }

        return (
          <TableCell
            span={span}
            key={`${columnIndex}-${propertyId}`}
            propertyId={propertyId}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
          />
        );
      })}
    </tr>
  );
});
