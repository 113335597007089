import { clamp } from 'lodash-es';
import { getAutoScrollY } from 'src/utils/auto-scroll';

import { MIN_HEIGHT, PADDING_TOP, UNIT_HEIGHT } from './const';

export type OnResizeEnd = (index: number, length: number, isMoving?: boolean) => void;

const getIndex = (top: number, unitHeight: number) => {
  top -= PADDING_TOP;
  let index = Math.floor(top / unitHeight);
  index = top - index * unitHeight > unitHeight / 2 ? index + 1 : index;
  return index;
};

export const addResize = (
  todoNode: HTMLDivElement,
  timelinePage: HTMLDivElement,
  containerNode: HTMLDivElement,
  onResizeEnd: OnResizeEnd,
  onClick: (event: MouseEvent) => void,
  options: {
    disableTop: boolean;
    disableBottom: boolean;
  }
) => {
  const { autoScrollY, rafY } = getAutoScrollY();
  const { disableTop, disableBottom } = options;

  let isMoving = false;

  const MinTop = PADDING_TOP;
  const MaxBottom = containerNode.clientHeight - PADDING_TOP;

  const handleResize = (event: MouseEvent) => {
    event.preventDefault();

    const target = event.target as HTMLDivElement | null;
    if (!target) return;

    let { clientY } = event;
    const mouseDownY = event.clientY;
    const isTop = target.classList.contains('resize-top');

    const initTodoRect = {
      top: parseInt(todoNode.style.top, 10),
      height: parseInt(todoNode.style.height, 10),
      bottom: parseInt(todoNode.style.height, 10) + parseInt(todoNode.style.top, 10),
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isMoving) {
        if (Math.abs(event.clientY - mouseDownY) < 5) {
          return;
        }
        isMoving = true;
      }

      autoScrollY({ y: event.clientY, container: timelinePage, offset: { top: 120 } });
      clientY = event.clientY;
      updateUI();
    };

    const updateUI = () => {
      const containerRect = containerNode.getBoundingClientRect();

      const offsetContainerY = clientY - containerRect.y;
      if (isTop) {
        let top = offsetContainerY;

        const tempTop = top < MinTop ? MinTop : top;
        const index = getIndex(tempTop, UNIT_HEIGHT);
        top = index * UNIT_HEIGHT + PADDING_TOP;

        const realTop = clamp(top, MinTop, initTodoRect.bottom);
        const height =
          top > initTodoRect.bottom
            ? Math.min(top, MaxBottom) - initTodoRect.bottom
            : initTodoRect.bottom - top;

        if (disableBottom && realTop + height > initTodoRect.bottom) return;
        todoNode.style.top = `${realTop}px`;
        todoNode.style.height = `${Math.max(height, MIN_HEIGHT)}px`;
      } else {
        let bottom = offsetContainerY;

        const index = getIndex(bottom, UNIT_HEIGHT);
        bottom = index * UNIT_HEIGHT + PADDING_TOP;

        const realBottom = clamp(bottom, MinTop, MaxBottom);
        const realTop = bottom > initTodoRect.top ? initTodoRect.top : realBottom;
        const realHeight = Math.abs(realBottom - initTodoRect.top);

        if (disableTop && realTop < initTodoRect.top) return;
        todoNode.style.top = `${realTop}px`;
        todoNode.style.height = `${Math.max(realHeight, MIN_HEIGHT)}px`;
      }
    };

    const handleMouseUp = () => {
      isMoving = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      timelinePage.removeEventListener('scroll', updateUI);
      cancelAnimationFrame(rafY.id);

      const top = parseInt(todoNode.style.top, 10);
      const height = parseInt(todoNode.style.height, 10);
      const index = getIndex(top, UNIT_HEIGHT);
      onResizeEnd?.(index, height < UNIT_HEIGHT ? 0 : height / UNIT_HEIGHT, isMoving);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    timelinePage.addEventListener('scroll', updateUI);
  };

  const handleMove = (event: MouseEvent) => {
    event.preventDefault();

    const target = event.target as HTMLDivElement | null;
    if (!target) return;

    let { clientY } = event;
    const mouseDownY = event.clientY;
    const todoRect = todoNode.getBoundingClientRect();
    const offsetTodoY = event.clientY - todoRect.top;

    const initTodoRect = {
      top: parseInt(todoNode.style.top, 10),
      height: parseInt(todoNode.style.height, 10),
      bottom: parseInt(todoNode.style.height, 10) + parseInt(todoNode.style.top, 10),
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isMoving) {
        if (Math.abs(event.clientY - mouseDownY) < 5) {
          return;
        }
        isMoving = true;
      }

      clientY = event.clientY;
      autoScrollY({ y: clientY, container: timelinePage, offset: { top: 120 } });
      updateUI();
    };

    const updateUI = () => {
      const containerRect = containerNode.getBoundingClientRect();
      const offsetContainerY = clientY - containerRect.y;

      const realTop = clamp(
        offsetContainerY - offsetTodoY,
        MinTop,
        MaxBottom - initTodoRect.height
      );

      const index = getIndex(realTop, UNIT_HEIGHT);
      const top = index * UNIT_HEIGHT + PADDING_TOP;
      todoNode.style.top = `${top}px`;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      timelinePage.removeEventListener('scroll', updateUI);
      cancelAnimationFrame(rafY.id);

      const top = parseInt(todoNode.style.top, 10);
      const height = parseInt(todoNode.style.height, 10);
      const index = getIndex(top, UNIT_HEIGHT);
      onResizeEnd?.(index, height < UNIT_HEIGHT ? 0 : height / UNIT_HEIGHT, isMoving);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    timelinePage.addEventListener('scroll', updateUI);
  };

  const handleMouseDown = (event: MouseEvent) => {
    const target = event.target as HTMLDivElement | null;
    if (!target) return;
    const isResize =
      target.classList.contains('resize-top') || target.classList.contains('resize-bottom');
    if (isResize) {
      handleResize(event);
    } else {
      if (disableTop || disableBottom) return;
      handleMove(event);
    }
  };

  const handleMouseUp = (event: MouseEvent) => {
    if (!isMoving) {
      onClick(event);
    }
    isMoving = false;
  };

  todoNode.addEventListener('mousedown', handleMouseDown);
  todoNode.addEventListener('mouseup', handleMouseUp);
  return () => {
    todoNode.removeEventListener('mouseup', handleMouseUp);
    todoNode.removeEventListener('mousedown', handleMouseDown);
  };
};
