import type { Context } from '../../core/context';
import type { IPropertyValueDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isNumberToken } from '../syntax/parser';
export const opacity: IPropertyValueDescriptor<number> = {
  name: 'opacity',
  initialValue: '1',
  type: PropertyDescriptorParsingType.VALUE,
  prefix: false,
  parse: (_context: Context, token: CSSValue): number => {
    if (isNumberToken(token)) {
      return token.number;
    }
    return 1;
  },
};
