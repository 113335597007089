import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useSetNextModalState } from '.';
import { RenderDropdown } from '../dropdown';
import { RenderLoading } from '../loading';
import { RenderModal } from '../modal';
import type { ModalSchema } from '../type';
import { RenderWarning } from '../warning';
import { useCloseModal } from './use-close-modal';

enum OpenModalType {
  MODAL,
  DROPDOWN,
  LOADING,
  WARNING,
}

export const useOpenModal = () => {
  const closeModal = useCloseModal();
  const setNextModalState = useSetNextModalState();

  const createNode = useCallback(
    (
      props:
        | ModalSchema.RenderModal
        | ModalSchema.RenderLoading
        | ModalSchema.RenderDropdown
        | ModalSchema.RenderWarning,
      Render,
      modalType: OpenModalType
    ) => {
      const modalId: string = props?.modalId || uuid();

      setNextModalState((preState) => {
        const isOpen = Boolean(preState[modalId]);
        const newState = { ...preState };

        if (!isOpen) {
          const node: ModalSchema.NodesType = {
            [modalId]: {
              id: modalId,
              config: props as any,
              element: <Render {...props} modalId={modalId} />,
            },
          };

          Object.assign(newState, node);
        }
        if (
          isOpen &&
          (modalType === OpenModalType.LOADING || (props as ModalSchema.RenderModal)?.forceUpdate)
        ) {
          const node: ModalSchema.NodesType = {
            [modalId]: {
              id: modalId,
              config: props as any,
              element: <Render {...props} noAnimation modalId={modalId} />,
            },
          };

          Object.assign(newState, node);
        }

        return newState;
      });

      return { modalId };
    },
    [setNextModalState]
  );

  const openModal = useMemo(
    () => ({
      modal: (props: ModalSchema.RenderModal) =>
        createNode(props, RenderModal, OpenModalType.MODAL),
      dropdown: <T extends ModalSchema.CloseModal>(props: ModalSchema.RenderDropdown<T>) =>
        createNode(props, RenderDropdown, OpenModalType.DROPDOWN),
      warning: (props: ModalSchema.RenderWarning) =>
        createNode(props, RenderWarning, OpenModalType.WARNING),
      loading: (props: ModalSchema.RenderLoading) =>
        createNode(props, RenderLoading, OpenModalType.LOADING),
      closeModal,
    }),
    [closeModal, createNode]
  );

  return openModal;
};
