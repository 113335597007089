import { cx } from '@flowus/common/cx';
import { builtins, builtinsEn } from '@flowus/formula';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import _ from 'lodash-es';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useCollectionId } from 'src/hooks/collection-view/use-collection-id';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { FormulaCodeEditor } from './formula-code-editor';

const builtinsObj = __BUILD_IN__ ? builtinsEn : builtins;

export const FormulaManual: FC<{
  className?: string;
  search?: string;
  recordId: string;
  propId: string;
  onComplete?: (kind: 'property' | 'constant' | 'function', name: string) => void;
}> = ({ className, search, recordId, propId, onComplete }) => {
  const collectionId = useCollectionId(recordId) ?? '';
  const [selectedKey, setSelectedKey] = useState<string | null>(null);
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema;
  });
  const formulaTool = getFormulaTool(collectionId);
  const menu = useMemo(() => {
    const availablePropIds = formulaTool.getAvailablePropIds(propId);
    const propCat: typeof builtinsObj[0] = {
      groupEn: 'Properties',
      groupCn: '属性',
      list: _.uniqBy(
        Object.entries(schema ?? {})
          .filter(([propId]) => availablePropIds.has(propId))
          .map(([usePropId, propSchema]) => {
            const resultType = (() => {
              let { type } = propSchema;
              if (type === CollectionSchemaType.FORMULA) {
                type = formulaTool.getTypeAsCollectionSchemaType(usePropId);
              }
              switch (type) {
                case CollectionSchemaType.NUMBER:
                  return 'number';
                case CollectionSchemaType.TEXT:
                  return 'string';
                case CollectionSchemaType.CHECKBOX:
                  return 'boolean';
                case CollectionSchemaType.DATE:
                case CollectionSchemaType.CREATED_AT:
                case CollectionSchemaType.UPDATED_AT:
                  return 'date';
                default:
                  return 'string';
              }
            })();
            const value = formulaTool.getValue(recordId, usePropId);
            return {
              title: propSchema.name,
              resultType,
              description: `获取当前记录的“${propSchema.name}”属性`,
              descriptionEn: `获取当前记录的“${propSchema.name}”属性`,
              syntax: `prop(${JSON.stringify(propSchema.name)})`,
              examples:
                value == null
                  ? `empty(prop(${JSON.stringify(propSchema.name)})) == true`
                  : `prop(${JSON.stringify(propSchema.name)}) == ${
                      resultType === 'date'
                        ? `fromTimestamp(${(value as Date).getTime()})`
                        : JSON.stringify(value)
                    }`,
            };
          }),
        (it) => it.title
      ),
    };
    if (search == null) return [propCat, ...builtinsObj];
    const lowerSearch = search.toLowerCase();
    return [propCat, ...builtinsObj]
      .map((cat) => {
        return {
          ...cat,
          list:
            cat.groupEn === 'Properties' && lowerSearch === 'prop'
              ? cat.list
              : cat.list.filter((it) => {
                  return it.title.toLowerCase().includes(lowerSearch);
                }),
        };
      })
      .filter((cat) => cat.list.length > 0);
  }, [formulaTool, propId, recordId, schema, search]);

  const selItem = selectedKey
    ? _.flatMap(menu, (cat) => cat.list).find((it) => it.title === selectedKey) ?? menu[0]?.list[0]
    : menu[0]?.list[0];

  return (
    <div className={cx(className, 'flex min-h-0 text-t2')}>
      <div className="h-full relative overflow-auto w-[180px] border-r border-grey6">
        {menu.map((cat) => {
          return (
            <dl key={cat.groupEn}>
              <dt className="h-7 leading-7 mx-1 px-1 text-grey3 sticky top-0 z-10 bg-white1">
                {__BUILD_IN__ ? cat.groupEn : cat.groupCn}
              </dt>
              {cat.list.map((it) => {
                return (
                  <dd
                    className={cx(
                      'mx-1 px-2 h-7 animate-hover flex items-center',
                      it === selItem && 'bg-black_006'
                    )}
                    onPointerEnter={() => {
                      setSelectedKey(it.title);
                    }}
                    onPointerDown={(event) => {
                      if (document.activeElement?.closest('.formula-editor') != null) {
                        event.preventDefault();
                      }
                    }}
                    onClick={() => {
                      const kind =
                        cat.groupEn === 'Properties'
                          ? 'property'
                          : cat.groupEn === 'Constants'
                          ? 'constant'
                          : 'function';
                      onComplete?.(kind, it.title);
                    }}
                    key={it.title}
                  >
                    <Icon
                      size="middle"
                      className="text-grey3"
                      name={
                        it.resultType === 'number'
                          ? 'IcTitleNum'
                          : it.resultType === 'string'
                          ? 'IcTitleText'
                          : it.resultType === 'boolean'
                          ? 'IcTitleCheckbox'
                          : it.resultType === 'date'
                          ? 'IcTitleDate'
                          : 'IcTitleText'
                      }
                    />
                    <div className="ml-2 flex-1 min-w-0 text-ellipsis">{it.title}</div>
                  </dd>
                );
              })}
            </dl>
          );
        })}
      </div>
      <div className="h-full overflow-auto flex-1 p-3">
        {selItem && (
          <article className="allow-text-selection">
            <h1 className="text-h3 mt-1 mb-3">{selItem.title}</h1>
            <p className="text-t3 my-2">{selItem.description}</p>
            <section className={cx(!selItem.syntax && 'hidden')}>
              <h2 className="text-t3-bold my-2">语法</h2>
              <pre className="rounded text-t3 whitespace-pre-wrap break-words text-mono py-1.5 px-2.5 bg-grey6">
                <code>{selItem.syntax}</code>
              </pre>
            </section>
            <section className={cx(!selItem.examples && 'hidden')}>
              <h2 className="text-t3-bold my-2">示例</h2>
              <FormulaCodeEditor
                readOnly
                className="rounded text-t3 py-1.5 px-2.5 bg-grey6"
                key={selItem.title}
                initialValue={selItem.examples}
              />
            </section>
          </article>
        )}
      </div>
    </div>
  );
};
