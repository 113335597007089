import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import { deepEqual } from '@flowus/common/utils/tools';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { parseRightPageId, useCloseRight } from 'src/utils/right-utils';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import type { CellViewProps } from './types';
import {
  useOpenPageWay,
  useShowingTablePageIcon,
} from 'src/hooks/collection-view/use-collection-view';
import { Subitem } from './subitem';
import { SubitemParent } from './subitem-parent';

export const TitleValue: FC<CellViewProps> = memo(({ className, recordId }) => {
  const { illegal } = usePermissions(recordId);
  const { relationEditor, viewId } = useBitable();
  const { segments, showIcon, icon } = useObservableBlock(recordId, (block) => ({
    segments: block?.data.segments,
    showIcon: (block?.subNodes ?? []).length > 0 || block?.data.icon?.type,
    icon: block?.data.icon,
  }));
  const openWay = useOpenPageWay(viewId);
  const openPage = useOpenPage();
  const closeRight = useCloseRight();
  const isShowPageIcon = useShowingTablePageIcon(viewId);

  return (
    <div className={cx('p-2 leading-none flex', { 'opacity-30': illegal }, className)}>
      {showIcon && isShowPageIcon && (
        <IconTrigger
          className={cx('mr-1 inline-block h-5 w-5 p-0', {
            // window/mac以及icon类型特殊处理
            '!align-middle': icon?.type !== 'emoji',
          })}
          blockId={recordId}
          iconSize={20}
          defaultIcon={<BlockDefaultIcon uuid={recordId} size="middle" />}
        />
      )}
      {illegal ? (
        <span className="border-b border-grey6 align-middle leading-[19px]">{ILLEGAL_TEXT}</span>
      ) : $searchParams.print ? (
        <a href={`/${recordId}`} className="underline">
          <RichText
            className="text-t2 inline align-middle"
            plugins={TITLE_EDITOR_PLUGINS}
            segments={segments}
            interactable={false}
          />
        </a>
      ) : (
        <RichText
          className="text-t2 inline align-middle"
          plugins={TITLE_EDITOR_PLUGINS}
          segments={segments}
          interactable={false}
        />
      )}
      <Subitem recordId={recordId} />
      <SubitemParent recordId={recordId} className="mt-0.5" />
      <BlockDiscussionsBadge
        className="inline-flex align-middle pointer-events-none"
        blockId={recordId}
      />
      {!relationEditor && (
        <div
          className="animate-hover-opaque absolute top-[5px] right-[5px] rounded border border-grey6 bg-white1 opacity-0 transition-opacity group-hover:opacity-100 py-0.5 px-1"
          onClick={(event) => {
            event.stopPropagation();
            const rightPageId = parseRightPageId(location.search);
            if (rightPageId === recordId) {
              closeRight();
            } else {
              openPage(recordId, {
                illegal,
                forceOpenInRight: event.altKey,
                openWay,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              });
            }
          }}
        >
          <Tooltip popup={openWay === 'openInRight' ? '右侧边栏打开' : '打开此页面'}>
            <div className="flex items-center text-t4 space-x-1">
              <Icon
                size="middle"
                name={openWay === 'openInRight' ? 'IcBlockRightSidebarOpen' : 'IcBlockFullPageOpen'}
                className="text-grey3"
              />
              <div className="text-grey1">打开</div>
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
}, deepEqual);
