import { cacheSearchMenuItems } from 'src/services/global-cache';

export const setSearchMenuItemByCache = (str: string, arr: any[]) => {
  cacheSearchMenuItems[str] = arr;
};

export const getSearchMenuItemByCache = (str: string) => {
  return cacheSearchMenuItems[str];
};

export const clearSearchMenuItemByCache = () => {
  for (const key in cacheSearchMenuItems) {
    delete cacheSearchMenuItems[key];
  }
};
