import type { MindMapEngine, MindNode, Rect } from '@flowus/mind-map';
import { useMindMapEngine } from '@flowus/mind-map';
import { MindMappingType } from '@next-space/fe-api-idl';
import type { Immutable } from 'immer';
import { throttle } from 'lodash-es';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { getDropInfo } from 'src/services/app/hook/use-drop-info';
import { usePageScrollRef } from 'src/views/main/page-doc/context';

interface Props {
  id: string;
}
const MARGIN = 60; // 左右间距
export const CollapseSelector: FC<Props> = (props) => {
  const engine = useMindMapEngine();
  const isDragging = useIsDragging();
  const pageScrollRef = usePageScrollRef();

  useEffect(() => {
    // 希望能避免一些isDragging的重复渲染，所以清空dropInfo放在这里
    if (!isDragging) {
      engine.clearDropInfo();
    }
  }, [engine, isDragging]);

  useEffect(() => {
    const move = throttle((e: MouseEvent) => {
      const element = pageScrollRef?.current;
      if (!element) return;
      const isDragging = !!getDropInfo();
      if (isDragging) return;
      const state = engine.getState();
      const scaleFactor = state.scale * 0.01;
      const nodeList = element.querySelectorAll('[data-block-id]');
      let hoverId: string | undefined = '';
      for (const node of nodeList) {
        const { blockId } = (node as HTMLElement).dataset;
        if (!blockId) continue;
        const mindNode = state.mindNodes[blockId];
        if (!mindNode) continue;
        if (!mindNode.height) continue;
        hoverId = getHoverId(e, engine, node, mindNode, scaleFactor);
        if (hoverId) break;
      }
      if (engine.getState().rootId === hoverId) {
        hoverId = undefined; // root节点不能折叠
      }
      if (state.hoverId !== hoverId) {
        engine.setHoverId(hoverId);
      }
    }, 32);
    document.addEventListener('mousemove', move);
    return () => {
      document.removeEventListener('mousemove', move);
    };
  }, [engine, pageScrollRef, props.id]);

  return null;
};

/** 检查鼠标是否移动到某个需要显示收缩按钮的位置上 */
const getHoverId = (
  e: MouseEvent,
  engine: MindMapEngine,
  node: Element,
  mindNode: Immutable<MindNode>,
  scaleFactor: number
) => {
  const { blockId } = (node as HTMLElement).dataset;
  const { direction } = engine.getState();
  const compareRect = getCompareRect(direction, node, mindNode, scaleFactor);
  if (!compareRect) return;
  if (
    intersectRect(compareRect, {
      x: e.clientX,
      y: e.clientY,
      width: 1,
      height: 1,
    })
  ) {
    return blockId;
  }
};

const getCompareRect = (
  direction: MindMappingType,
  node: Element,
  mindNode: Immutable<MindNode>,
  scaleFactor: number
) => {
  const rect = node.getBoundingClientRect();
  const getLogicDiagramLeftRect = () => {
    return {
      x: rect.left,
      y: rect.top + (mindNode.height / 2 - mindNode.regionHeight / 2) * scaleFactor,
      width: (mindNode.width + MARGIN) * scaleFactor,
      height: mindNode.regionHeight * scaleFactor,
    };
  };
  const getLogicDiagramRightRect = () => {
    return {
      x: rect.left - MARGIN * scaleFactor,
      y: rect.top + (mindNode.height / 2 - mindNode.regionHeight / 2) * scaleFactor,
      width: (mindNode.width + MARGIN) * scaleFactor,
      height: mindNode.regionHeight * scaleFactor,
    };
  };
  const getOrganizationStructureRect = () => {
    return {
      x: rect.left,
      y: rect.top,
      width: mindNode.width * scaleFactor,
      height: (mindNode.height + MARGIN / 2) * scaleFactor,
    };
  };
  if (direction === MindMappingType.LOGIC_DIAGRAM_LEFT) {
    return getLogicDiagramLeftRect();
  } else if (direction === MindMappingType.LOGIC_DIAGRAM_RIGHT) {
    return getLogicDiagramRightRect();
  } else if (direction === MindMappingType.MIND_MAPPING) {
    if (mindNode.dir === 'right' || mindNode.dir === undefined) {
      return getLogicDiagramLeftRect();
    }
    return getLogicDiagramRightRect();
  } else if (direction === MindMappingType.ORGANIZATION_STRUCTURE) {
    return getOrganizationStructureRect();
  }
};

const intersectRect = (a: Rect, b: Rect) => {
  return (
    a.x <= b.x + b.width && b.x <= a.x + a.width && a.y <= b.y + b.height && b.y <= a.y + a.height
  );
};
