import type { Context } from '../../core/context';
import type { IPropertyValueDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isNumberToken } from '../syntax/parser';
import { TokenType } from '../syntax/tokenizer';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface zIndex {
  order: number;
  auto: boolean;
}

export const zIndex: IPropertyValueDescriptor<zIndex> = {
  name: 'z-index',
  initialValue: 'auto',
  prefix: false,
  type: PropertyDescriptorParsingType.VALUE,
  parse: (_context: Context, token: CSSValue): zIndex => {
    if (token.type === TokenType.IDENT_TOKEN) {
      return { auto: true, order: 0 };
    }

    if (isNumberToken(token)) {
      return { auto: false, order: token.number };
    }

    throw new Error(`Invalid z-index number parsed`);
  },
};
