import { Tooltip } from '@flowus/common/components/tooltip';
import { PermissionRole } from '@next-space/fe-api-idl/lib/models/PermissionRole';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { getUrlWithoutDomain } from 'src/common/utils/url-utils';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { useCheckLogin } from 'src/hooks/share/use-check-login';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useCurrentUser } from 'src/hooks/user';
import { setAppUiState, useAllowCommentInSharePage } from 'src/services/app/hook';
import { fetchSubscriptionData } from 'src/services/app/hook/subscription-data';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { SearchParams, ViewPath } from 'src/utils';
import { isPageLike } from 'src/utils/block-type-utils';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { locationToUrl } from 'src/utils/location-utils';

/** 评论按钮 */
export const CommentButton: FC<{}> = () => {
  const pageId = useGetPageId();
  const currentUser = useCurrentUser();
  const isLogin = Boolean(currentUser.uuid);
  const blockType = useObservableBlock(pageId, (state) => state?.type);
  const { roleWithoutPublic, publicRole, illegal } = usePermissions(pageId);
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const isOwner = Boolean(roleWithoutPublic !== PermissionRole.NONE && isPageLike(blockType));
  const isSharePage = judgeSharePage();
  const checkLogin = useCheckLogin();

  // const openButtonTips = useOpenButtonTips({
  //   localKey: LocalStorageKey.COMMENT_TIPS,
  //   endDay: 1,
  // });

  if (!(!__PRIVATE__ && isSharePage && !illegal && publicRole === PermissionRole.COMMENTER)) {
    return null;
  }
  // useEffect(() => {
  //   if (isLogin) return;
  //   openButtonTips(ref, {
  //     content: '本文开启了评论权限,注册即刻发表评论',
  //   });
  // }, [isLogin, openButtonTips]);

  if (allowCommentInSharePage) return null;
  return (
    <Tooltip popup="评论">
      <IconUiSizeBox
        size={30}
        className="text-t2-medium flex-shrink-0 animate-click animate-hover"
        onClick={() => {
          if (checkLogin()) {
            // 如果本来就有文档页面读及以上权限，可以艾特人，这里需要拉一下空间人数
            if (isLogin && isOwner) {
              void fetchSubscriptionData(getCurrentSpaceId());
            }
            // 如果没页面权限，纯已登录的路人，则可以直接在分享页评论（这个逻辑很怪，有这个页面读权限的人反而需要跳转过去）
            setAppUiState({ $allowCommentInSharePage: true });
          }
        }}
      >
        <Icon name={'IcComment'} size="middle" />
      </IconUiSizeBox>
    </Tooltip>
  );
};
