import { useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  initialInView?: boolean;
  rootMargin?: string;
  root?: Element | null | undefined;
}
// eslint-disable-next-line no-unused-vars
export function useVisible<T extends HTMLElement>(props?: Props) {
  const [visible, setVisible] = useState(false);
  const currentRef = useRef<T | null>();

  const { ref } = useInView({
    root: props?.root,
    triggerOnce: true,
    initialInView: props?.initialInView,
    rootMargin: props?.rootMargin,
    onChange: (status) => {
      if (status) {
        setVisible(status);
      }
    },
  });

  return [
    visible,
    (node: T | null) => {
      ref(node);
      currentRef.current = node;
    },
    currentRef,
  ] as const;
}
