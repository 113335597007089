import { useCallback } from 'react';
import { request } from 'src/common/request';
import { updateCurrentUser } from 'src/services/user/current-user';

export const useFetchMe = () => {
  return useCallback(async () => {
    const userRes = await request.infra.getMyUserInfo.raw();
    if (userRes.code === 200) {
      updateCurrentUser(userRes.data);
    }
    return userRes;
  }, []);
};
