import { Button } from 'src/common/components/button';
import { setLocalStorage } from 'src/utils/local-storage';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import {
  AIEditorFrom,
  AiBitableEdit,
  AIEditorScene,
} from 'src/editor/editor/uikit/ai-editor/const';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import type { ModalSchema } from 'src/common/components/next-modal';
import { PRODUCT_NAME } from 'src/const/title';

export const useOpenAiEditorFromBitable = () => {
  const openAIEditor = useOpenAIEditor();
  const openModal = useOpenModal();

  return (collectionId: string, popcorn: ModalSchema.PopcornType, isDescription = false) => {
    const openEditor = () => {
      void openAIEditor({
        popcorn,
        blockId: collectionId,
        from: AIEditorFrom.ContextMenu,
        isBitable: true,
        editorScene: AIEditorScene.BlockSelected,
        isDescription,
      });
    };

    if (!localStorage.getItem(AiBitableEdit)) {
      openModal.modal({
        closeAfterCallBack: () => {
          openEditor();
        },
        content: ({ onCloseModal }) => {
          return <TipModal closeSelf={onCloseModal} openEditor={openEditor} />;
        },
      });
    } else {
      openEditor();
    }
  };
};

const TipModal: FC<{ closeSelf: () => void; openEditor: () => void }> = ({
  closeSelf,
  openEditor,
}) => {
  const [check, setCheck] = useState(false);
  return (
    <div className="px-7 py-6">
      <p className="mb-2.5 w-60 text-t1-medium text-center">
        {PRODUCT_NAME} 辅助功能目前仅针对当前视图进行数据处理
      </p>

      <div
        className="flex items-center cursor-pointer text-t2 justify-center"
        onClick={() => setCheck(!check)}
      >
        <Icon
          name={check ? 'IcCheckboxCheck' : 'IcCheckbox'}
          size="large"
          className={check ? 'text-active_color' : ''}
        />
        不再提示
      </div>

      <Button
        className={'h-8 flex items-center mt-8 w-full justify-center'}
        colorType={'active'}
        autoFocus
        onClick={() => {
          closeSelf();
          if (check) {
            setLocalStorage(AiBitableEdit, JSON.stringify(true));
          }
          openEditor();
        }}
      >
        知道了
      </Button>
    </div>
  );
};
