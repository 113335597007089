import colors from '@flowus/tailwind-config/colors.json';

export { colors };

export enum ColorKey {
  blue = 'blue',
  green = 'green',
  yellow = 'yellow',
  orange = 'orange',
  red = 'red',
  pink = 'pink',
  purple = 'purple',
  active_color = 'active_color',
  brown = 'brown',
  grey = 'grey',
  black = 'black',
  white = 'white',
  light_yellow = 'light_yellow',
  light_green = 'light_green',
  light_blue = 'light_blue',
  light_purple = 'light_purple',
  light_red = 'light_red',
  yellow_green = 'yellow_green',
  green_blue = 'green_blue',
  blue_purple = 'blue_purple',
  purple_red = 'purple_red',
  red_orange = 'red_orange',
}
