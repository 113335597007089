import { ActiveTracker } from '@flowus/active-tracker';
import { useEffect } from 'react';
import { request } from 'src/common/request/request';

import { useCurrentUser } from '../user';

export const activeTracker = new ActiveTracker(request);
export const useReportActive = () => {
  const currentUser = useCurrentUser();
  const isLogin = Boolean(currentUser.uuid);
  useEffect(() => {
    if (!isLogin) return;
    if (__HOST_LOCAL__ || __HOST_TEST__ || __HOST_STAGING__) return;

    const online = () => {
      void activeTracker.reportEnter();
    };
    const onPointerMove = () => {
      activeTracker.setActive(true);
    };
    const visibilityChange = () => {
      if (document.visibilityState === 'visible') {
        void activeTracker.reportEnter();
      }
    };
    window.addEventListener('visibilitychange', visibilityChange);
    window.addEventListener('online', online);
    window.addEventListener('pointermove', onPointerMove, { capture: true });
    activeTracker.start();

    return () => {
      activeTracker.stop();
      window.removeEventListener('visibilitychange', visibilityChange);
      window.removeEventListener('online', online);
      window.removeEventListener('pointermove', onPointerMove, { capture: true });
    };
  }, [isLogin]);
};
