import { isPageLike } from '@flowus/common/block/permissions';
import { isExportPageType } from '@flowus/common/block/utils';
import { message } from '@flowus/common/components/message';
import { checkImagePreviewFormat, FileRegex } from '@flowus/common/regex';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { ILLEGAL_TEXT } from 'src/common/const';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import { useOpenMoveTo } from 'src/components/move-to';
import { useOpenCompleteDelete } from 'src/components/open-complete-delete';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useBlockStatus } from 'src/hooks/block/use-block-status';
import { useCopyBlockRecord } from 'src/hooks/block/use-copy-block-record';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useReadonly, useRestorePage } from 'src/hooks/page';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useCanMove } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { Modals } from 'src/modals';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { updateBlock } from 'src/redux/managers/block/update';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { isMindMap } from 'src/utils/block-type-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { combineFullName, getFileNameInfo } from 'src/utils/file';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { getVirtualElement } from 'src/utils/virtualElement';
import { ExportFile } from '../../header/export-file';
import { ExportPanel } from '../../header/export-file/export-panel';
import { getSelectBlockHasNoFile } from './hook';

enum OptionsType {
  DOWN,
  MOVE,
  OPEN,
  COPY,
  RENAME,
  DELETE,
  RESTORE,
  COMPLETE_DELETE,
  COPY_LINK,
  COPY_AND_MOVE,
}
interface Props {
  uuid: string;
  formHeaderMenu?: boolean;
}
export const useDrive = ({ uuid, formHeaderMenu }: Props) => {
  const openPage = useOpenPage();
  const block = usePickBlock(uuid, ['updatedBy', 'updatedAt', 'data']);
  const isPage = isPageLike(block?.type);
  const history = useHistory();
  const transaction = useTransaction();
  const openModal = useOpenModal();
  const multipleDownload = useMultipleDownload();
  const openFilePreview = useOpenFilePreview();
  const openMoveTo = useOpenMoveTo();
  const restore = useRestorePage();
  const openCompleteDelete = useOpenCompleteDelete(uuid);
  const openImagePreview = useOpenImagePreview();
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const canMove = useCanMove(uuid);
  const readonly = useReadonly(uuid);
  const isSelected = useObservableStore(
    (state) => selectedBlocksToIds(state.ui.selectedBlocks).some((i) => i === uuid),
    [],
    { obsSelectBlocks: [{ blockId: uuid }] }
  );

  const isDragging = useIsDragging();
  const isDeleted = useBlockStatus(uuid);
  const copyBlockRecord = useCopyBlockRecord();
  // 被风控了
  const { illegal, role } = usePermissions(uuid);

  const fileName: string = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);
  const fileInfo = useMemo(() => {
    return getFileNameInfo(fileName);
  }, [fileName]);
  const popcorn = useRef<ReturnType<typeof getVirtualElement>>();
  const [name, setName] = useState(fileInfo.name);
  const [showRename, setShowRename] = useState(false);
  const userName = useUserName(block?.updatedBy ?? '');
  const isSharePage = judgeSharePage();
  const editorUser = [PermissionRole.WRITER, PermissionRole.EDITOR].includes(role);

  useEffect(() => {
    return () => {
      openModal.closeModal(Modals.DRIVE_FILE_CONTEXTMENU);
    };
  }, [openModal]);

  /** 同步外部file Name更新 */
  useEffect(() => {
    setName(isPage ? fileName : fileInfo.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileName]);

  /** 修改文件名 */
  const rename = () => {
    setShowRename(false);
    if (name === fileInfo.name || (isPage && name === fileName)) return;
    transaction(() => {
      if (!name && !isPage) {
        setName(fileInfo.name);
      } else {
        updateBlock(uuid, {
          updatedAt: Date.now(),
          data: {
            segments: textToSegments(isPage ? name : combineFullName(name, fileInfo.extName)),
          },
        });
      }
    });
  };

  /** 复制 */
  const onCopyBlocks = useCallback(() => {
    void copyBlockRecord(cache.ui.selectedBlocks);
  }, [copyBlockRecord]);

  const openExportPage = () => {
    openModal.modal({
      content: (_) => {
        if (isMindMap(block?.type)) {
          return <ExportPanel onCloseModal={_.onCloseModal} id={uuid} />;
        }
        return <ExportFile uuid={uuid} onCloseModal={_.onCloseModal} />;
      },
    });
  };

  const onCopyAndMove = () => {
    if (!popcorn.current) return;
    openMoveTo({
      popcorn: popcorn.current,
      uuid,
      placement: 'left',
      offset: [0, 20],
      placeholder: '搜索拷贝到...的页面',
      copyAndMove: {},
    });
  };

  /** 预览文件 */
  const previewFile = useCallback(() => {
    if (location.href.includes('preview')) {
      history.push(`/preview/${uuid}`);
      return;
    }

    if (checkImagePreviewFormat(fileInfo.extName)) {
      openImagePreview({ uuid });
      return;
    }

    openFilePreview(uuid);
  }, [fileInfo.extName, openFilePreview, uuid, history, openImagePreview]);

  /** 打开文件、文件夹 */
  const handleClick = (ev: MouseEvent) => {
    bizTracker.event('content_view');

    if (isPage) {
      openPage(uuid, { forceOpenInRight: ev?.altKey });
      return;
    }

    if (!isPage && block?.type !== BlockType.FILE) {
      message.warning('非文件暂不支持预览');
      return;
    }

    if (FileRegex.pdf.test(fileInfo.extName)) {
      window.open(`/${isSharePage ? 'share/' : 'preview/'}${uuid}`);
    } else {
      previewFile();
    }
  };

  /** 下载文件 */
  const handleDownloadFile = useCallback(() => {
    void multipleDownload(cache.ui.selectedBlocks.map((i) => i.blockId));
  }, [multipleDownload]);

  /** 多选block */
  const selectBlock = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // if (readonly) return;
    if (!isSelected) {
      dispatch(uiActions.updateSelectBlocks([...cache.ui.selectedBlocks, { blockId: uuid }]));
    } else {
      dispatch(
        uiActions.updateSelectBlocks(cache.ui.selectedBlocks.filter((i) => i.blockId !== uuid))
      );
    }
  };

  /** 选中当前block */
  const selectCurrentBlock = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (readonly) return;
    dispatch(uiActions.updateSelectBlocks([{ blockId: uuid }]));
  };

  /** 右键唤起菜单 */
  const openMenuList = (event: MouseEvent<HTMLElement>, isPDFPreviewPage = false) => {
    event.preventDefault();
    event.stopPropagation();
    if (readonly) return;
    bizTracker.event('content_menu');
    popcorn.current = getVirtualElement(event);
    if (!isSelected) {
      dispatch(uiActions.updateSelectBlocks([{ blockId: uuid }]));
    }
    const { selectedBlocks } = cache.ui;
    const firstSelectBlock = cache.blocks[selectedBlocks[0]?.blockId ?? ''];
    const selectOnlyOne = selectedBlocks.length === 1;

    const showMenuListFooter = editorUser && selectOnlyOne && !isSharePage;
    const hiddenDownload = getSelectBlockHasNoFile();
    const showExportPage =
      hiddenDownload &&
      selectOnlyOne &&
      !isMindMap(firstSelectBlock?.type) &&
      isExportPageType(firstSelectBlock?.type);

    /** 菜单内容 */
    let items = [
      {
        type: ListItemType.OPERATION,
        isHidden: !selectOnlyOne,
        data: {
          type: OptionsType.OPEN,
          icon: 'IcMenuOpen',
          title: '打开',
          onClick: handleClick,
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: formHeaderMenu || !showExportPage,
        data: {
          type: OptionsType.DOWN,
          icon: 'IcDownload',
          title: '导出',
          onClick: openExportPage,
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: formHeaderMenu || hiddenDownload,
        data: {
          type: OptionsType.DOWN,
          icon: 'IcDownload',
          title: '下载',
          onClick: handleDownloadFile,
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: !selectOnlyOne || readonly || formHeaderMenu || isPDFPreviewPage,
        data: {
          type: OptionsType.RENAME,
          icon: 'IcEdit02',
          title: '重命名',
          onClick: () => setShowRename(true),
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isPDFPreviewPage,
        data: {
          type: OptionsType.COPY,
          icon: 'IcDuplicate',
          title: '拷贝副本',
          onClick: onCopyBlocks,
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isPDFPreviewPage,
        data: {
          type: OptionsType.COPY_AND_MOVE,
          icon: 'IcCopyToPage',
          title: '拷贝副本至页面',
          onClick: onCopyAndMove,
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isPDFPreviewPage || !selectOnlyOne,
        data: {
          type: OptionsType.COPY_LINK,
          icon: 'IcLink',
          title: !isPage ? '拷贝块访问链接' : '拷贝页面访问链接',
          onClick: debounce(
            () => {
              bizTracker.event('copy_page_link');
              if (!isPage) {
                const pageId = getOwnerPage(uuid);
                if (pageId) {
                  void writeTextInClipboard(`${getLocationOrigin()}/${pageId}#${uuid}`, {
                    message: '已复制链接',
                  });
                }
              } else {
                void writeTextInClipboard(`${getLocationOrigin()}/${uuid}`, {
                  message: '已复制链接',
                });
              }
            },
            300,
            { leading: true }
          ),
        },
      },
      {
        type: ListItemType.LINE,
        isHidden: readonly || formHeaderMenu,
        data: {},
      },
      {
        type: ListItemType.OPERATION,
        isHidden: !canMove,
        data: {
          type: OptionsType.MOVE,
          icon: 'IcMove',
          title: '移动到',
          onClick: () => {
            if (!popcorn.current) return;
            openMoveTo({ popcorn: popcorn.current, uuid });
          },
        },
      },
      {
        type: ListItemType.LINE,
        isHidden: readonly || formHeaderMenu,
        data: {},
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isDeleted || readonly || formHeaderMenu,
        data: {
          type: OptionsType.DELETE,
          icon: 'IcTrash',
          title: '删除',
          onClick: () => {
            transaction(() => {
              archiveBlock(selectedBlocksToIds(cache.ui.selectedBlocks));
              selectedBlockHistory([]);
            });
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: !isDeleted || readonly,
        data: {
          type: OptionsType.RESTORE,
          icon: 'IcTrashRestore',
          title: '恢复',
          onClick: () => {
            void restore(uuid);
          },
        },
      },
      {
        type: ListItemType.LINE,
        isHidden: !isDeleted || readonly,
        data: {},
      },
      {
        type: ListItemType.OPERATION,
        isHidden: !isDeleted || readonly,
        data: {
          type: OptionsType.COMPLETE_DELETE,
          icon: 'IcTrash',
          title: '彻底删除',
          onClick: openCompleteDelete,
        },
      },
    ];

    if (showMenuListFooter) {
      items.push({
        type: ListItemType.LINE,
        isHidden: false,
        data: {},
      });
    }

    // 风控菜单，只有彻底删除
    if (illegal) {
      items = [
        {
          type: ListItemType.OPERATION,
          isHidden: readonly,
          data: {
            type: OptionsType.COMPLETE_DELETE,
            icon: 'IcTrash',
            title: '彻底删除',
            onClick: () => openCompleteDelete(),
          },
        },
      ];
    }

    openModal.dropdown({
      modalId: Modals.DRIVE_FILE_CONTEXTMENU,
      popcorn: popcorn.current,
      placement: 'right-start',
      offset: [4, 4],
      content: (_) => (
        <ListView
          className="w-[200px] py-2 overflow-y-auto max-h-[80vh] next-modal"
          onItemClick={() => _.onCloseModal()}
          items={items}
          customFooter={
            showMenuListFooter && (
              <UpdateUserInfo
                name={userName}
                updatedAt={block?.updatedAt}
                isByAI={block?.data.isByAI}
              />
            )
          }
        />
      ),
    });
  };

  const isPDF = fileInfo.extName.toLowerCase() === 'pdf';
  return {
    selectCurrentBlock,
    selectBlock,
    isSelected,
    openMenuList,
    handleClick,
    rename,
    isDragging,
    showRename,
    illegal,
    name,
    readonly,
    setName,
    fileName,
    isPDF,
    isPage,
  };
};
