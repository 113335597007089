import { contentToString, sliceContent } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

export const useLigatures = (uuid: string) => {
  const getEditorModel = useGetOrInitEditorModel();
  const ligatures = (eventKey: string) => {
    const model = getEditorModel(uuid);
    if (!model) return;

    const { selection } = model;
    if (!selection) return;

    const { focusOffset } = selection;

    if (focusOffset < 2) return;

    const pre = contentToString(sliceContent(model.content, focusOffset - 2, focusOffset - 1));

    const replaceLigature = (ligature: string) => {
      model.performChange((ctx) => {
        ctx
          .shadow()
          .select(focusOffset - 2, focusOffset)
          .replace(ligature);
      });
    };

    if (pre === '-' && (eventKey === '>' || eventKey === '》')) {
      replaceLigature('→');
    }

    if ((pre === '<' || pre === '《') && eventKey === '-') {
      replaceLigature('←');
    }

    if ((pre === '!' || pre === '！') && eventKey === '=') {
      replaceLigature('≠');
    }

    if ((pre === '<' || pre === '《') && eventKey === '=') {
      replaceLigature('≤');
    }

    if ((pre === '>' || pre === '》') && eventKey === '=') {
      replaceLigature('≥');
    }
  };

  return useCallback(ligatures, [getEditorModel, uuid]);
};
