import { debounce } from 'lodash-es';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { eatIndex } from '../eat-index';
import { isWindow } from '../is-window';
import { useGrid } from './context';

/** 缓冲数量 */
export const BUFFER_SIZE = 5;

export const useCells = () => {
  const {
    getLeft,
    getTop,
    getRowHeight,
    getColWidth,
    getRowKey,
    getColKey,
    renderCell,
    renderRow,
  } = useGrid();

  const [[startX, endX], [startY, endY]] = useRange();

  const rowNodes: ReactElement[] = [];

  for (let y = startY; y < endY; y++) {
    const top = getTop(y);
    const height = getRowHeight(y);
    const rowKey = getRowKey(y);

    const cellNodes: ReactElement[] = [];
    for (let x = startX; x < endX; x++) {
      const colKey = getColKey(x);
      const left = getLeft(x);
      const width = getColWidth(x);
      cellNodes.push(
        <div
          key={`${colKey}-${rowKey}`}
          className="absolute cell"
          style={{ top: 0, left, width, height }}
        >
          {renderCell(x, y)}
        </div>
      );
    }

    if (renderRow) {
      rowNodes.push(
        <div key={rowKey} className="absolute" style={{ top, left: 0 }}>
          {renderRow(y, cellNodes)}
        </div>
      );
    } else {
      rowNodes.push(
        <div key={rowKey} className="absolute" style={{ top, left: 0 }}>
          {cellNodes}
        </div>
      );
    }
  }

  return rowNodes;
};
const useRange = () => {
  const { contentRef, scrollXRef, scrollYRef, colCount, rowCount, getColWidth, getRowHeight } =
    useGrid();

  type Range = [[startX: number, endX: number], [startY: number, endY: number]];
  const [state, setState] = useState<Range>([
    [0, 0],
    [0, 0],
  ]);

  useEffect(() => {
    const $content = contentRef.current;
    const $scrollX = scrollXRef.current;
    const $scrollY = scrollYRef.current;
    if (!$content || !$scrollX || !$scrollY) {
      return;
    }

    let offsetX: number;
    let viewportWidth: number;
    let getScrollTop: () => number;

    let offsetY: number;
    let viewportHeight: number;
    let getScrollLeft: () => number;

    const prepare = () => {
      if (isWindow($scrollX)) {
        // 相对于 html 滚动
        offsetX = $content.offsetLeft;
        viewportWidth = window.innerWidth;
        getScrollLeft = () => window.scrollX;
      } else {
        // 相对于 $scrollX 元素滚动
        offsetX = $content.offsetLeft - $scrollX.offsetLeft;
        viewportWidth = $scrollX.getBoundingClientRect().width;
        getScrollLeft = () => $scrollX.scrollLeft;
      }

      if (isWindow($scrollY)) {
        // 相对于 html 滚动
        offsetY = $content.offsetTop;
        viewportHeight = window.innerHeight;
        getScrollTop = () => window.scrollY;
      } else {
        // 相对于 $scrollY 元素滚动
        offsetY = $content.offsetTop - $scrollY.offsetTop;
        viewportHeight = $scrollY.getBoundingClientRect().height;
        getScrollTop = () => $scrollY.scrollTop;
      }
    };

    const handleScrollX = () => {
      /** 滚动出视口的距离 */
      const overflowWidth = getScrollLeft() - offsetX;

      let start = eatIndex(overflowWidth, getColWidth);
      let end = eatIndex(viewportWidth, getColWidth, start);

      // 加上缓冲空间
      start = Math.max(start - BUFFER_SIZE, 0);
      end = Math.min(end + BUFFER_SIZE, colCount);

      setState(([, yRange]) => {
        return [[start, end], yRange];
      });
    };

    const handleScrollY = () => {
      /** 滚动出视口的距离 */
      const overflowHeight = getScrollTop() - offsetY;

      let start = eatIndex(overflowHeight, getRowHeight);
      let end = eatIndex(viewportHeight, getRowHeight, start);

      // 加上缓冲空间
      start = Math.max(start - BUFFER_SIZE, 0);
      end = Math.min(end + BUFFER_SIZE, rowCount);

      setState(([xRange]) => {
        return [xRange, [start, end]];
      });
    };

    const apply = debounce(
      () => {
        prepare();
        handleScrollX();
        handleScrollY();
      },
      60,
      { leading: true }
    );

    apply();

    $scrollX.addEventListener('scroll', handleScrollX);
    $scrollY.addEventListener('scroll', handleScrollY);
    window.addEventListener('resize', apply);

    // teardown
    return () => {
      $scrollX.removeEventListener('scroll', handleScrollX);
      $scrollY.removeEventListener('scroll', handleScrollY);
      window.removeEventListener('resize', apply);
    };
  }, [contentRef, scrollXRef, scrollYRef, rowCount, colCount, getColWidth, getRowHeight]);

  return state;
};
