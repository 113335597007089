import { RenderingStates } from 'pdfjs-dist/lib/web/ui_utils';
import type { PDFPageView, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';

import type { AnnotationType, PDFAnnotation } from '../type';
import { ShapeEditor } from './shape-editor';

export class ShapeEditorManager {
  public shapeEditorMap: Record<string, ShapeEditor> = {};

  constructor(
    public pdfViewer: PDFViewer,
    public onAddShape: (annotation: PDFAnnotation) => void
  ) {}

  getLoadedPage = () => {
    if (!this.pdfViewer) return [];

    const renderedPages: PDFPageView[] = [];

    for (let i = 0; i < this.pdfViewer.pagesCount; i++) {
      const pageView = this.pdfViewer.getPageView(i);
      if (pageView.renderingState === RenderingStates.FINISHED) {
        renderedPages.push(pageView);
      }
    }
    return renderedPages;
  };

  enableAll = (annotationType: AnnotationType, color: string) => {
    const renderedPages = this.getLoadedPage();

    const ids = new Set<number>();
    renderedPages.forEach((pageView) => {
      ids.add(pageView.id);
      this.ensureShapeEditor(pageView, annotationType, color);
    });

    Object.entries(this.shapeEditorMap).forEach(([pageNumber, polygonEditor]) => {
      if (ids.has(Number(pageNumber))) return;
      polygonEditor.enable(annotationType, color);
    });
  };

  disableAll = () => {
    Object.values(this.shapeEditorMap).forEach((polygonEditor) => {
      polygonEditor.disable();
    });
  };

  ensureShapeEditor = (pageView: PDFPageView, annotationType: AnnotationType, color: string) => {
    const pageNumber = pageView.id;

    let shapeEditor = this.shapeEditorMap[pageNumber];
    if (shapeEditor) {
      shapeEditor?.enable(annotationType, color);
    } else {
      if (!this.pdfViewer) return;
      shapeEditor = new ShapeEditor(this.pdfViewer, pageNumber, this.onAddShape, {
        annotationType,
        color,
      });

      this.shapeEditorMap[pageView.id] = shapeEditor;
    }
  };

  setEditorColor = (color: string) => {
    Object.values(this.shapeEditorMap).forEach((shapeEditor) => {
      shapeEditor.setColor(color);
    });
  };
}
