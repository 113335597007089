import { addOssProcessParams } from '../url';

/**
 * 有些图片格式img标签不支持，需要加参数到img_process,目前仅针对预览查看的时候用得到
 */
export const NEED_CONVERT_IMAGE_FORMAT = ['heic', 'heif', 'tif', 'bmp', 'tiff'];

/**对特殊后缀的图片做转码 */
export const getFormatImageUrl = (url: string, extName: string) => {
  const needConvert = NEED_CONVERT_IMAGE_FORMAT.includes(extName.toLocaleLowerCase());
  if (needConvert) {
    return addOssProcessParams(url, '/image/format,png');
  }
  return url;
};
