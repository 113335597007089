import Color from 'color';

const lighten = (color = '', amount: number) => {
  return Color(color).mix(Color('white'), amount).hex();
};

const darken = (color = '', amount: number) => {
  return Color(color).mix(Color('black'), amount).hex();
};

const fade = (color = '', amount: number) => {
  return Color(color).fade(amount).rgb().string();
};

export { darken, fade, lighten };
