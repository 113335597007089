import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { GroupName, GroupNameScene } from 'src/bitable/group-header/group-name';
import { Icon } from 'src/common/components/icon';
import { useUpdateGroupVisible } from 'src/hooks/block/use-update-group-visible';
import { realGroupName } from 'src/hooks/collection-view/table-groups';

interface Props {
  visible?: boolean;
  isSubGroup?: boolean;
  groupName: string;
  showDragButton?: boolean;
}

export const GroupItem: FC<Props> = ({ visible, groupName, isSubGroup, showDragButton }) => {
  const { viewId, isLocked } = useBitable();
  const updateGroupVisible = useUpdateGroupVisible();

  return (
    <div
      className={cx(
        'text-t2 animate-hover flex h-8 cursor-pointer items-center justify-between pr-3',
        showDragButton ? 'pl-3' : 'pl-4'
      )}
    >
      <div className="flex flex-1 items-center overflow-hidden">
        {showDragButton && (
          <div className="mr-1 flex h-5 w-5 items-center justify-center">
            <Icon size="normal" name="IcMenu" className="text-grey4" />
          </div>
        )}

        <GroupName
          isSubGroup={isSubGroup}
          groupName={groupName}
          scene={GroupNameScene.GROUP_LIST}
        />
      </div>

      <button
        className={cx('h-5 w-5', isLocked && 'cursor-not-allowed opacity-20')}
        onClick={() => {
          if (isLocked) return;
          updateGroupVisible({
            viewId,
            groupName: realGroupName(groupName),
            visible: !visible,
            isSubGroup,
          });
        }}
      >
        <Icon name={visible ? 'IcMenuItemHide' : 'IcMenuItemShow'} size="middle" />
      </button>
    </div>
  );
};
