import { useOpenModal } from '@flowus/common/next-modal';
import { useHistory } from 'react-router-dom';
import { useCreateDocPage, useFetchPage } from 'src/hooks/page';
import { useCurrentSpace } from 'src/hooks/space';
import { useCloseAllModal } from 'src/redux/managers/ui';
import { IMPORT_MODAL_ID } from './configs';

export const useCommon = () => {
  const createDocPage = useCreateDocPage();
  const fetchPage = useFetchPage();
  const currentSpace = useCurrentSpace();
  const closeAllModal = useCloseAllModal();
  const history = useHistory();
  const openModal = useOpenModal();

  const openLoading = (title: string) => {
    openModal.loading({
      title,
      modalId: IMPORT_MODAL_ID,
      forceUpdate: true,
      autoClose: true,
      keyboard: true,
    });
  };
  const closeLoading = () => {
    openModal.closeModal(IMPORT_MODAL_ID);
  };

  return {
    createDocPage,
    fetchPage,
    currentSpace,
    closeAllModal,
    history,
    openModal,
    openLoading,
    closeLoading,
  };
};
