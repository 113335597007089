import type { Point } from '../type';

export const convertPathToRect = (paths: Point[]) => {
  let left = Infinity;
  let top = Infinity;
  let right = 0;
  let bottom = 0;
  paths.forEach((point) => {
    if (point.x < left) left = point.x;
    if (point.y < top) top = point.y;
    if (point.x > right) right = point.x;
    if (point.y > bottom) bottom = point.y;
  });

  return {
    left,
    top,
    right,
    bottom,

    width: right - left,
    height: bottom - top,
  };
};
