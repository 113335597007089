import { SessionKeys, setSessionStorage } from '@flowus/common/storage';
import { SingleInput } from '@flowus/common/uikit/single-input';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';
import { request } from 'src/common/request';
import { Placeholder } from 'src/components/empty/placeholder';
import { useGetQuery } from 'src/hooks/page/use-get-query';
import { SearchParams } from 'src/utils';
import type { Header as Header0 } from '../main/header';

interface Props {
  uuid: string;
}
let Header: typeof Header0;
export const set_cycle_header = (component: typeof Header0) => {
  Header = component;
};

export const SharePasswordPage: FC<Props> = ({ uuid }) => {
  const [error, setError] = useState('');
  const getQuery = useGetQuery();
  const password = getQuery(SearchParams.password);

  const success = useCallback(
    async (value: string) => {
      setSessionStorage(SessionKeys.PASSWORD, value);
      const res = await request.editor.getDoc.raw(uuid);
      // @ts-ignore type
      if (res.code === 200 || res.code === 1407) {
        localStorage.setItem(`share-psw-${uuid}`, value);
        location.reload();
        setError('');
      } else {
        sessionStorage.clear();
        setError(res.msg);
      }
    },
    [uuid]
  );

  useEffect(() => {
    if (password) {
      void success(password);
    }
  }, [password, success]);

  return (
    <>
      <Header emptyStyle />
      <Placeholder icon="🔐" title={<span className="text-h2">分享访问密码</span>}>
        <div className="flex my-2.5">
          <SingleInput
            size={4}
            success={success}
            onError={() => setError('密码错误')}
            onChange={() => setError('')}
          />
        </div>
        {error && (
          <div className="text-t2 text-red flex">
            <IconWidget emojiStr="⚠️" className="mr-1" />
            {error}
          </div>
        )}
      </Placeholder>
    </>
  );
};
