import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React from 'react';

interface Props {
  className?: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}
export const ToolButtonWrapper: FC<Props> = (props) => {
  return (
    <div
      className={cx(
        'flex items-center justify-center w-full h-full',
        {
          'cursor-pointer hover:bg-black_006': !props.disabled,
          'opacity-30': props.disabled,
          'bg-black_006': props.selected && !props.disabled,
        },
        props.className
      )}
      onClick={props.onClick}
      onPointerDown={(e) => {
        // 点击可阻止外层的tooltip消失
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {props.children}
    </div>
  );
};
