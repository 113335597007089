import { cx } from '@flowus/common/cx';
import { useDebounceFn } from 'ahooks';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { $appUiStateCache, setAppUiState, useCollectionSearchById } from 'src/services/app';
import { useBitable } from '../context';

export const BitableSearch: FC<{ className?: string }> = ({ className }) => {
  const inputRef = useRef<HTMLInputElement>();
  const { viewId } = useBitable();
  const currentKeyword = useCollectionSearchById(viewId);
  const [inputValue, setInputValue] = useState('');
  const [focus, setFocus] = useState(false);

  const updateKeywords = useCallback(
    (value: string | undefined) => {
      const collectionSearch = $appUiStateCache.$collectionSearch;
      setAppUiState({
        $collectionSearch: {
          ...collectionSearch,
          [viewId]: value,
        },
      });
    },
    [viewId]
  );

  useEffect(() => {
    return () => updateKeywords(undefined);
  }, [updateKeywords]);

  const { run: onInput } = useDebounceFn(updateKeywords, { wait: 500 });

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      onInput(value);
    },
    [onInput]
  );

  const handleClear = (event: React.MouseEvent) => {
    event.stopPropagation();
    setFocus(false);
    setInputValue('');
    updateKeywords(undefined);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (isHotkey('esc')(event)) {
      setFocus(false);
      setInputValue('');
      updateKeywords(undefined);
    }
  };

  const onBlur = () => {
    setFocus(false);
    if (inputValue === '') {
      updateKeywords(undefined);
    }
  };

  const isShowInput = currentKeyword !== undefined || focus;

  return (
    <div
      className={cx('relative flex items-center', className)}
      onClick={(event) => {
        event.stopPropagation();
        const timer = setTimeout(() => {
          inputRef.current?.focus();
          updateKeywords('');
          setInputValue('');
          clearTimeout(timer);
        });
        setFocus(true);
      }}
    >
      <Icon size="middle" className="mr-1 text-grey3" name="IcSearch" />

      <Input
        type="text"
        showBorder={false}
        inputRef={inputRef}
        onBlur={onBlur}
        placeholder="输入关键字"
        className="border-none !bg-transparent transition-all duration-200 focus:border-0"
        inputClassName={'px-0 focus:border-none'}
        style={{ width: isShowInput ? 160 : 0 }}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {isShowInput && (
        <Icon
          size="middle"
          className={cx(
            'animate-hover cursor-pointer text-grey4',
            inputValue ? 'opacity-100' : 'opacity-0'
          )}
          name="IcUploadCancel"
          onClick={handleClear}
        />
      )}

      {!isShowInput && <span className="text-t2 whitespace-nowrap text-grey3">搜索</span>}
    </div>
  );
};
