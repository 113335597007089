import type { Tracker } from './types/tracker';

/**
 * 火山引擎的埋点相关实现类,请不要随意修改，尤其是初始化的代码
 * 文档地址: https://www.volcengine.com/docs/6285/65979
 */
export class VolcanoEngineTracker implements Tracker {
  windowFunName: string;
  /**
   * @windowFunName 绑定到window的方法名
   */
  constructor(appId: number, debug: boolean, bindWindowFunName: string) {
    this.windowFunName = bindWindowFunName;
    // 初始化sdk
    const scriptFun = document.createElement('script');
    scriptFun.innerHTML = `(function(win, export_obj) {
      win['TeaAnalyticsObject'] = export_obj;
      if (!win[export_obj]) {
          function _collect() {
              _collect.q.push(arguments);
          }
          _collect.q = _collect.q || [];
          win[export_obj] = _collect;
      }
      win[export_obj].l = +new Date();
  })(window, '${this.windowFunName}');`;
    document.body.appendChild(scriptFun);

    const script = document.createElement('script');
    script.src =
      'https://lf3-data.volccdn.com/obj/data-static/log-sdk/collect/5.0/collect-rangers-v5.0.0.js';
    script.async = true;
    document.body.appendChild(script);

    Reflect.get(window, this.windowFunName)('init', {
      app_id: appId,
      channel: 'cn',
      log: debug,
    });
    /**
     * 为了默认提供对页面 pv、uv 的统计支持，SDK会在 start 方法调用后，上报一次pv事件。
     * 默认的调用可以通过 config 方法设置 disable_auto_pv来禁止(摘自文档)
     */
    this.trackEvent('config', { disable_auto_pv: true });
    Reflect.get(window, this.windowFunName)('start');
  }
  /**
   *
   * @param userId
   * @param params 给用户属性带参数
   * @returns
   */
  login = (userId: string, params?: any, flush?: boolean) => {
    this.trackEvent('config', { user_unique_id: userId, ...params }, flush);
    return this as unknown as Tracker;
  };
  logout = () => {
    this.trackEvent('config', { user_unique_id: '' }, true);
    return this as unknown as Tracker;
  };
  trackEvent = (eventName: string, data: any, _flush?: boolean) => {
    // if (flush) {
    //   Reflect.get(window, this.windowFunName)('beconEvent', eventName, data);
    // } else {
    //   Reflect.get(window, this.windowFunName)(eventName, data);
    // }
    if (
      !['page_enter', 'page_enter_time', 'block_fold', 'node_fold', 'rtf_choose'].includes(
        eventName
      )
    ) {
      // 火山的beconEvent有时候埋不上
      Reflect.get(window, this.windowFunName)(eventName, data);
    }
    return this as unknown as Tracker;
  };
  addUserAttribute = (data: any, flush?: boolean) => {
    this.trackEvent('profileSet', data, flush);
    return this as unknown as Tracker;
  };
  evtParams: any = {};
  setCommonParams = (appendExtParams: any, flush?: boolean) => {
    this.evtParams = appendExtParams;
    this.trackEvent(
      'config',
      {
        ...this.evtParams,
      },
      flush
    );
    return this as unknown as Tracker;
  };
  addCommonParams = (extParams: any, flush?: boolean) => {
    Object.assign(this.evtParams, extParams);
    this.trackEvent(
      'config',
      {
        evtParams: { ...this.evtParams },
      },
      flush
    );

    return this as unknown as Tracker;
  };
}
