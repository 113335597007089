import { useCallback } from 'react';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { blurDocument } from 'src/utils/dom';

/** 用于 需要在 undo/redo 是恢复块选择高亮的情况 */
export const useSelectedBlockHistory = () => {
  const selectedBlockHistory = (selectedBlocks: SelectBlock[], old?: SelectBlock[]) => {
    const oldSelectedBlocks = cache.ui.selectedBlocks;
    dispatch(
      HISTORY_EFFECTS({
        init() {
          blurDocument();
          dispatch(uiActions.updateSelectBlocks(selectedBlocks));
        },
        redo() {
          blurDocument();
          dispatch(uiActions.updateSelectBlocks(selectedBlocks));
        },
        undo() {
          blurDocument();
          dispatch(uiActions.updateSelectBlocks(old || oldSelectedBlocks));
        },
      })
    );
  };

  return {
    selectedBlockHistory: useCallback(selectedBlockHistory, []),
    selectedBlock: cache.ui.selectedBlocks,
  };
};
