import { isBuildIn } from 'src/env';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';

export const useHasAIBlock = (pageId: string) => {
  return useObservableStore(
    ({ blocks }) => {
      if (isBuildIn()) return false;

      return blocks[pageId]?.subNodes.some((uuid) => {
        return blocks[uuid]?.data.isByAI;
      });
    },
    [],
    { waitMode: 'debounce', wait: 10000 }
  );
};
