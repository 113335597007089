import { createSetState } from '@flowus/common/zustand/utils';
import { create } from 'zustand';
import type { DesktopStateType } from './type';

const initState = {
  isFullscreen: false,
  isUpdateReload: false,
  isUpdateAvailable: false,
};
export const $desktopState = create<DesktopStateType>(() => initState);
export const $setDesktopState = createSetState($desktopState);
