import { useBarChartStyle, useUpdateChartFormatStyle } from 'src/hooks/chart';
import { CheckboxText } from 'src/views/logoff/checkbox-text';
import { ChartLine } from '../../chart-line';
import { Input } from 'src/common/components/input';
import { useChartBlockId } from '../chart-config-context';
import type { FC } from 'react';

export const BarStyle: FC = () => {
  const chartBlockId = useChartBlockId();
  const updateChartFormatStyle = useUpdateChartFormatStyle();
  const {
    showDataLabel,
    showGridLine,
    showXAxisLabel,
    showXAxisLine,
    showXAxisCalibration,
    showYAxisLabel,
    showYAxisLine,
    showYAxisCalibration,
    xAxisTtile,
    yAxisTtile,
  } = useBarChartStyle(chartBlockId);
  return (
    <>
      <div className="grid grid-cols-2 mt-4 w-full">
        <CheckboxText
          defaultChecked={showDataLabel}
          content="数据标签"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showDataLabel: checked,
            });
          }}
        />
        <CheckboxText
          defaultChecked={showGridLine}
          content="网格线"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showGridLine: checked,
            });
          }}
        />
      </div>
      <ChartLine className="my-4" />
      <div className="text-t2-medium">横轴</div>
      <div className="grid gap-y-2 grid-cols-2  mt-4 w-full   flex-wrap">
        <CheckboxText
          defaultChecked={showXAxisLabel}
          content="显示标签"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showXAxisLabel: checked,
            });
          }}
        />
        <CheckboxText
          defaultChecked={showXAxisLine}
          content="显示轴线"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showXAxisLine: checked,
            });
          }}
        />
        <CheckboxText
          defaultChecked={showXAxisCalibration}
          content="显示刻度"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showXAxisCalibration: checked,
            });
          }}
        />
      </div>
      <ChartLine className="my-4" />
      <div className="text-t2-medium">纵轴</div>
      <div className="grid gap-y-2 grid-cols-2  mt-4 w-full   flex-wrap">
        <CheckboxText
          defaultChecked={showYAxisLabel}
          content="显示标签"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showYAxisLabel: checked,
            });
          }}
        />
        <CheckboxText
          defaultChecked={showYAxisLine}
          content="显示轴线"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showYAxisLine: checked,
            });
          }}
        />
        <CheckboxText
          defaultChecked={showYAxisCalibration}
          content="显示刻度"
          onCheck={(checked) => {
            updateChartFormatStyle(chartBlockId, {
              showYAxisCalibration: checked,
            });
          }}
        />
      </div>
      <ChartLine className="my-4" />
      <div className="text-t2-medium">轴标题</div>
      <div className="mt-4 text-t2 flex items-center space-x-2">
        <div className="shrink-0">横轴标题</div>
        <Input
          className="text-t2 w-full h-7 bg-white1"
          value={xAxisTtile}
          focusWidthBorder={false}
          placeholder="请输入标题"
          onChange={(v) => {
            updateChartFormatStyle(chartBlockId, {
              xAxisTtile: v,
            });
          }}
        />
      </div>
      <div className="text-t2 flex items-center space-x-2 mt-3">
        <div className="shrink-0">纵轴标题</div>
        <Input
          className="text-t2 w-full h-7 bg-white1"
          value={yAxisTtile}
          focusWidthBorder={false}
          placeholder="请输入标题"
          onChange={(v) => {
            updateChartFormatStyle(chartBlockId, {
              yAxisTtile: v,
            });
          }}
        />
      </div>
    </>
  );
};
