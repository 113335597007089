import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import type { RecordWalker } from 'src/bitable/shared';
import { recordWalkerByCollectionViewId } from 'src/bitable/shared';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { useGetSortedRecordIds } from '../use-get-sorted-records';
import { EMPTY_GROUP_NAME } from './const';
import { selectCollectionGroups } from './select-collection-groups';

export const realGroupName = (groupName?: string) => {
  return groupName !== EMPTY_GROUP_NAME ? groupName : undefined;
};

export const useBiTableGroups = (viewId: string, deps?: any[]) => {
  const { sortedRecordIds, child2ParentMap, parent2ChildMap, filteredRecordIdSet } =
    useGetSortedRecordIds(viewId, deps);

  const tableGroups = useObservableStore(
    (store) => {
      const { blocks, users, collectionViews } = store;
      return selectCollectionGroups({
        blocks,
        collectionViews,
        users,
        viewId,
        sortedRecordIds,
      });
    },
    [viewId, sortedRecordIds],
    { allUser: true, wait: 100, waitMode: 'debounce' }
  );
  return { tableGroups, child2ParentMap, parent2ChildMap, filteredRecordIdSet };
};

export const createRecordWalker = (
  findIndex: (recordId: string) => { records: string[]; index: number } | null
) => {
  return {
    checkRecord: (recordId: string) => {
      return findIndex(recordId) !== null;
    },
    prevRecord: (recordId: string) => {
      const result = findIndex(recordId);
      if (result == null) return null;
      const { records, index } = result;
      if (index <= 0) return null;
      return records[index - 1] ?? null;
    },
    nextRecord: (recordId: string) => {
      const result = findIndex(recordId);
      if (result == null) return null;
      const { records, index } = result;
      if (index >= records.length - 1) return null;
      return records[index + 1] ?? null;
    },
  };
};

export const useProvideRecordWalker = (viewId: string, walker: RecordWalker) => {
  const set = useSetAtom(recordWalkerByCollectionViewId);
  useEffect(() => {
    void set((table) => ({ ...table, [viewId]: walker }));
    return () => {
      void set((table) => {
        const { [viewId]: _walker, ...rest } = table;
        return rest;
      });
    };
  }, [set, viewId, walker]);
};

export const useBiTableGroupsAndProvideRecordWalker = (viewId: string, active = true) => {
  const { tableGroups, child2ParentMap, parent2ChildMap, filteredRecordIdSet } =
    useBiTableGroups(viewId);
  const set = useSetAtom(recordWalkerByCollectionViewId);
  useEffect(() => {
    if (!active) return;
    const findIndex = (recordId: string) => {
      if (tableGroups == null) return null;
      if (tableGroups.withoutValidGroup) {
        const index = tableGroups.sortedRecordIds.indexOf(recordId);
        if (index >= 0) return { records: tableGroups.sortedRecordIds, index };
        return null;
      }
      for (const group of tableGroups.visibleGroups) {
        const index = group.recordIds.indexOf(recordId);
        if (index >= 0) {
          return { records: group.recordIds, index };
        }
      }
      return null;
    };
    const walker = createRecordWalker(findIndex);
    void set((table) => ({ ...table, [viewId]: walker }));
    return () => {
      void set((table) => {
        const { [viewId]: _walker, ...rest } = table;
        return rest;
      });
    };
  }, [active, set, tableGroups, viewId]);
  return { tableGroups, child2ParentMap, parent2ChildMap, filteredRecordIdSet };
};
