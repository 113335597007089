import type { CollectionViewGroups } from '@next-space/fe-api-idl';
import { GroupSortType } from '@next-space/fe-api-idl';
import { getGroupSort } from 'src/bitable/bitable-manager/group-list/utils';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';

import { getDateFromGroupName } from './date-number-groupname';

const numberSorter = (valueA: string, valueB: string, sort: GroupSortType) => {
  if (valueA === 'out_of_range') return 1;
  if (valueB === 'out_of_range') return -1;
  if (!valueA) return -1;
  if (!valueB) return 1;

  return sort === GroupSortType.ASCENDING
    ? Number(valueA.split(',')[0]) - Number(valueB.split(',')[0])
    : Number(valueB.split(',')[0]) - Number(valueA.split(',')[0]);
};

const dateSorter = (valueA: string, valueB: string, sort: GroupSortType) => {
  if (!valueA) return -1;
  if (!valueB) return 1;

  return sort === GroupSortType.ASCENDING
    ? getDateFromGroupName(valueA) - getDateFromGroupName(valueB)
    : getDateFromGroupName(valueB) - getDateFromGroupName(valueA);
};

const textSorter = (valueA: string, valueB: string, sort: GroupSortType) => {
  if (!valueA) return -1;
  if (!valueB) return 1;

  return sort === GroupSortType.ASCENDING
    ? valueA.localeCompare(valueB)
    : valueB.localeCompare(valueA);
};

export const textGroupSort = (groups: CollectionViewGroups, oldSort?: GroupSortType) => {
  const sort = getGroupSort('text', oldSort);
  if (sort !== GroupSortType.MANUAL) {
    groups.sort((a, b) => textSorter(a.value ?? '', b.value ?? '', sort));
  }
};

export const numberGroupSort = (groups: CollectionViewGroups, oldSort?: GroupSortType) => {
  const sort = getGroupSort('number', oldSort);
  groups.sort((a, b) => numberSorter(a.value ?? '', b.value ?? '', sort));
};

export const dateGroupSort = (groups: CollectionViewGroups, oldSort?: GroupSortType) => {
  const sort = getGroupSort('date', oldSort);
  groups.sort((a, b) => dateSorter(a.value ?? '', b.value ?? '', sort));
};

export const relationGroupSort = (groups: CollectionViewGroups, oldSort?: GroupSortType) => {
  const { blocks } = getState();
  const sort = getGroupSort('text', oldSort);

  if (sort !== GroupSortType.MANUAL) {
    groups.sort((groupA, groupB) => {
      if (!groupA.value) return -1;
      if (!groupB.value) return 1;

      const titleA = segmentsToText(blocks[groupA.value]?.data.segments);
      const titleB = segmentsToText(blocks[groupB.value]?.data.segments);
      return textSorter(titleA, titleB, sort);
    });
  }
};
