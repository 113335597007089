import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { PageNavigator } from 'src/common/components/page-navigator';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { segmentsToText } from 'src/editor/utils/editor';
import { useGetBreadcrumb } from 'src/hooks/block/use-navs';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';

interface Props {
  uuid: string;
  canClick?: boolean;
}

export const IconLink: FC<Props> = ({ uuid, canClick }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const getBreadcrumb = useGetBreadcrumb();
  const navs = getBreadcrumb(uuid);
  const openPage = useOpenPage();

  // 被风控了
  const { illegal } = usePermissions(uuid);

  if (!block) return null;

  const content = (
    <>
      <IconUiSizeBox size={16} className="flex items-center">
        <IconTrigger
          blockId={uuid}
          trigger={false}
          iconSize={16}
          defaultIcon={<BlockDefaultIcon uuid={uuid} size="small" />}
          hasOverlay={!canClick}
        />
      </IconUiSizeBox>

      <div className="px-1 text-black decoration-grey5 text-ellipsis">
        {illegal
          ? ILLEGAL_TEXT
          : segmentsToText(block.data.segments) || getUntitledName(block.type)}
      </div>

      {Boolean(navs.length && !illegal) && (
        <PageNavigator showAll className="flex-shrink-0 text-grey3" navs={navs} />
      )}
    </>
  );

  const classNames = cx(
    'flex items-center text-grey3 text-t3-medium py-0.5 px-1 w-[calc(100%-20px)]',
    canClick && 'animate-hover',
    illegal && 'opacity-30'
  );

  return canClick ? (
    <div
      onClick={(event) =>
        openPage(uuid, {
          forceOpenInRight: event.altKey,
          forceOpenNewTab: event.ctrlKey || event.metaKey,
        })
      }
      className={classNames}
    >
      {content}
    </div>
  ) : (
    <div className={classNames}>{content}</div>
  );
};
