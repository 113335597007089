import type { Point, Rect } from '../type';
import type { CropShape } from './imagedata-to-image';
import { imagedataToDataUrl } from './imagedata-to-image';

/**
 * cropRectArea
 * @param canvas canvas Dom node
 * @param rect 相对 canvas 的 css 尺寸
 * @param path 相对 canvas 的 path 坐标
 * @returns
 */
export const cropRectArea = async (
  canvas: HTMLCanvasElement,
  rect: Rect,
  path?: Point[],
  shape?: CropShape
) => {
  const radio = canvas.width / canvas.clientWidth;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const { x, y, width, height } = rect;
  const imageData = ctx.getImageData(x * radio, y * radio, width * radio, height * radio);
  const blobUrl = await imagedataToDataUrl(imageData, path, shape);

  return blobUrl;
};
