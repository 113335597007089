import { configureStore } from '@reduxjs/toolkit';
import { cacheMiddleware } from './middlewares/cache';
import { opHistoryMiddleware } from './middlewares/op-history';
import { patchRxjsMiddleware } from './middlewares/patch-rxjs';
import { persistenceMiddleware } from './middlewares/persistence';
// import { persistReducers } from './persist';
import * as reducers from './reducers';

type RootState = {
  [K in keyof typeof reducers]: ReturnType<typeof reducers[K]>;
};

export const store = configureStore<RootState>({
  reducer: reducers,
  devTools: __HOST_LOCAL__,
  middleware: (getDefaults): any => {
    return getDefaults({
      serializableCheck: false,
    }).concat(persistenceMiddleware, patchRxjsMiddleware, opHistoryMiddleware, cacheMiddleware);
  },
});

// export const persister = persistStore(store);

export const { getState, dispatch, subscribe } = store;

export const cache = new Proxy(getState(), {
  get(_, p) {
    return Reflect.get(getState(), p);
  },
});
