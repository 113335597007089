import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { WEEKDAYS_SHORT, formatWeekText } from 'src/utils/date-utils';
import { ONE_MINUTES, ONE_MOMENT, UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { formatDate, getUnitLength, isWeekOrMonth } from '../utils/get-timeline-dates';
import { TIME_FORMAT } from 'src/common/const';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';

export const useLeft = () => {
  const { timelineDates, zoomLevel } = useTimeline();
  const isShowTime = zoomLevel === ZoomLevel.HOUR || zoomLevel === ZoomLevel.DAY;
  const [left, setLeft] = useState(0);
  const timer = useRef<Timeout>();
  const compteLeft = useCallback(() => {
    if (isShowTime) {
      const minutes = new Date().setSeconds(0, 0) - (timelineDates[0] as number);
      return (minutes / ONE_MOMENT) * UnitWidthMap[zoomLevel];
    }
    return (
      getUnitLength(Date.now(), timelineDates[0] as number, zoomLevel, { abs: false }) *
      UnitWidthMap[zoomLevel]
    );
  }, [isShowTime, zoomLevel, timelineDates]);

  useEffect(() => {
    setLeft(compteLeft());

    const callback = () => {
      timer.current = setTimeout(callback, ONE_MINUTES);
      setLeft(compteLeft());
    };

    timer.current = setTimeout(callback, ONE_MINUTES);
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [compteLeft]);

  return left;
};
export const TimelineToday = () => {
  const { timelineDates, zoomLevel } = useTimeline();
  const isShowTime = zoomLevel === ZoomLevel.HOUR || zoomLevel === ZoomLevel.DAY;
  const left = useLeft();

  if (
    Date.now() > (timelineDates[timelineDates.length - 1] as number) ||
    Date.now() < (timelineDates[0] as number)
  ) {
    return null;
  }

  let dateTimeLeft = left;
  if (isWeekOrMonth(zoomLevel)) {
    dateTimeLeft =
      getUnitLength(Date.now(), timelineDates[0] as number, zoomLevel, {
        abs: false,
        needDateStart: true,
      }) *
        UnitWidthMap[zoomLevel] +
      UnitWidthMap[zoomLevel] / 2;
  }

  const renderContent = () => {
    if (isShowTime) {
      return dayjs(new Date()).format(TIME_FORMAT);
    }

    if (zoomLevel === ZoomLevel.WEEK || zoomLevel === ZoomLevel.BIWEEK) {
      return formatWeekText(
        `${WEEKDAYS_SHORT[formatDate(Date.now()).day]} ${formatDate(Date.now()).date}`
      );
    }

    return new Date().getDate();
  };

  return (
    <>
      <span
        style={{ left: dateTimeLeft }}
        className={cx(
          'px-1 absolute inline-flex justify-center items-center rounded-full -translate-x-1/2  z-10',
          isShowTime ? 'text-red' : 'text-white bg-red',
          zoomLevel === ZoomLevel.MONTH ||
            zoomLevel === ZoomLevel.QUARTER ||
            zoomLevel === ZoomLevel.YEAR
            ? 'w-6 h-6'
            : 'h-[25px]'
        )}
      >
        {renderContent()}
      </span>

      <span
        style={{ left }}
        className="w-2 h-2 rounded-full bg-red -bottom-2 absolute -translate-x-1/2 -translate-y-1/2"
      />
    </>
  );
};

export const IndicateLine = () => {
  const { timelineDates } = useTimeline();
  const left = useLeft();

  if (
    Date.now() > (timelineDates[timelineDates.length - 1] as number) ||
    Date.now() < (timelineDates[0] as number)
  ) {
    return null;
  }

  return (
    <div
      style={{ left, height: 'calc(100% - 76px)' }}
      className={'absolute w-px bg-red bottom-0 top-[76px]'}
    ></div>
  );
};
