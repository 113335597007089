import { cx } from '@flowus/common/cx';
import { throttle } from 'lodash-es';
import { css } from 'otion';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Audio as AudioPro, AudioPlayerControlSprite } from 'react-audio-player-pro';
import 'react-audio-player-pro/dist/style.css';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { Tooltip } from 'src/common/components/tooltip';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { segmentsToText } from 'src/editor/utils/editor';
import {
  useCheckViewLimit,
  useFileViewLimit,
  useWaitingLimit,
} from 'src/hooks/limit/file-down-limit';
import { useReadonly } from 'src/hooks/page';
import { usePickBlock } from 'src/utils/pick-block';
import { Caption } from '../caption';
import { useSelectFile } from '../file/empty-file';
import { InfiniteProgress } from '../image/infinite-progress';
import { EmptyAudio } from './empty-audio';

interface Props {
  id: string;
  root?: boolean;
  defaultOpenPanel: boolean;
  url?: string;
  progress?: number;
  externalLink?: boolean;
  ownerBlockId?: string;
}

export const Audio: FC<Props> = (props) => {
  const { id, ownerBlockId } = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const blockId = ownerBlockId ?? id;
  const readonly = useReadonly(blockId);
  const showProgress = props.progress !== -1 && props.progress !== 100;
  const selectFile = useSelectFile({ uuid: blockId });
  const checkViewLimit = useCheckViewLimit();
  const [mask, setMask] = useState(checkViewLimit());
  const fileViewLimit = useFileViewLimit();
  const waitTimer = useWaitingLimit();

  const onMount = (node: HTMLAudioElement | null) => {
    if (audioRef.current) return;

    audioRef.current = node;

    if (!node) {
      return;
    }

    node.addEventListener('error', (error) => {
      // @ts-ignore error
      const errorCode = error.target?.error.code;
      // @ts-ignore error
      const errorMsg = error.target?.error.message;

      if (!error || !props.url) {
        return;
      }

      if (errorMsg) {
        message.error(errorMsg);
        return;
      }

      switch (errorCode) {
        case MediaError.MEDIA_ERR_DECODE:
          message.error('视频解码时发生错误');
          break;
        case MediaError.MEDIA_ERR_NETWORK:
          message.error('加载失败');
          break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
          message.error('浏览器不支持播放该音频源');
          break;
        default:
      }
    });

    const playLimit = throttle(async () => {
      if (!mask) return;
      node.pause();
      await fileViewLimit();
      void node.play();
      setMask(false);
      node.removeEventListener('playing', playLimit);
    }, 100);

    node.addEventListener('playing', playLimit);
    node.addEventListener('waiting', () => waitTimer.start());
    node.addEventListener('playing', () => waitTimer.stop());
  };

  if (!props.url && !showProgress) {
    return (
      <BlockDrop id={blockId} horizontal={props.root} className="my-1">
        <EmptyAudio
          readonly={readonly}
          defaultOpenPanel={props.defaultOpenPanel}
          onClick={(e) => {
            selectFile(e.currentTarget, 'bottom');
          }}
        />
      </BlockDrop>
    );
  }

  if (props.url?.startsWith('blob')) {
    return null;
  }

  return (
    <BlockDrop id={blockId} horizontal={props.root} className="my-2.5">
      <div
        data-column-item-min-width="230px"
        className={cx(
          'flex flex-col justify-center group',
          ownerBlockId && 'pl-1 border-dashed border-grey5 border-l-[2px]'
        )}
      >
        <Tooltip
          equalWidth
          popupClass="whitespace-pre-wrap w-full break-words"
          placement="top"
          className="overflow-x-auto"
          popup={<PopTitle id={id} />}
          offset={[0, 8]}
        >
          <AudioPlayerControlSprite />
          <AudioPro
            preload="metadata"
            useRepeatButton
            src={props.url ?? ''}
            onDidMount={onMount}
            className={cx(
              'min-w-[350px]',
              css({
                selectors: {
                  '&.ce6c17': {
                    paddingRight: '12px',
                    backgroundColor: 'var(--white2)',
                    border: '1px solid var(--grey6)',
                  },
                  '& .e0b58b': {
                    backgroundColor: 'var(--white2)',
                  },
                  '& .cc7be8': {
                    backgroundColor: 'var(--grey6)',
                  },
                  '& .c7b08a': {
                    color: 'var(--grey3)',
                  },
                  '& .a3d9e7': {
                    display: 'none',
                  },
                  '& .bff2b5.a45085 .ac7b4a': {
                    backgroundColor: 'var(--grey6)',
                  },
                },
              })
            )}
          />
          {/* // autoPlayAfterSrcChange={false}
            // autoPlay={false}
            // onError={(e) => {
            //   const { error } = e.target as any;
            //   if (!error || !props.url) {
            //     return;
            //   }
            //   switch (error.code) {
            //     case MediaError.MEDIA_ERR_DECODE:
            //       setErrorMsg('视频解码时发生错误');
            //       break;
            //     case MediaError.MEDIA_ERR_NETWORK:
            //       setErrorMsg('加载失败');
            //       break;
            //     case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            //       setErrorMsg(`浏览器不支持播放该音频源`);
            //       break;
            //     default:
            //   }
            // }}
            // onPlayError={(err) => {
            //   if (errorMsg) {
            //     message.warning(errorMsg);
            //   } else {
            //     message.warning(err.message);
            //   }
            // }} */}
        </Tooltip>
        {showProgress && (
          <div className="absolute flex flex-row rounded-sm bg-black opacity-75 right-2">
            <InfiniteProgress />
            <div className="text-white1 text-t4">{props.progress}%</div>
          </div>
        )}
        {props.externalLink && (
          <div className="absolute flex items-center top-3 right-3 bg-black/75 group-hover:opacity-100 opacity-0 rounded-sm">
            <Icon size="middle" className="text-white1" name={'IcGlobeShare'} />
          </div>
        )}
      </div>
      <Caption blockId={blockId} />
    </BlockDrop>
  );
};
const PopTitle: FC<{ id: string }> = (props) => {
  const block = usePickBlock(props.id, ['data'], ['segments']);
  return <>{segmentsToText(block?.data.segments)}</>;
};
