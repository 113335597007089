import type { FC } from 'react';
import { useIsCollapse } from 'src/services/app';
import { useIsFullscreen } from 'src/services/desktop';
import { isMacElectron } from 'src/utils/electron';

const AsideHeaderPlaceholder: FC = () => {
  const isFullscreen = useIsFullscreen();
  const isDesktopMode = isMacElectron && !isFullscreen;
  const isCollapsed = useIsCollapse();

  return (
    <div
      className="flex-shrink-0 drag-container w-full transition-all"
      style={{
        height: isDesktopMode && !isCollapsed ? '36px' : '0px',
      }}
    />
  );
};

const PreviewPDFAsideHeaderPlaceholder: FC = () => {
  const isFullscreen = useIsFullscreen();
  const isDesktopMode = isMacElectron && !isFullscreen;

  return (
    <div
      className="flex-shrink-0 drag-container w-full transition-all"
      style={{
        width: isDesktopMode ? '70px' : '0px',
      }}
    />
  );
};
export const DesktopComponents = {
  AsideHeaderPlaceholder,
  PreviewPDFAsideHeaderPlaceholder,
};
