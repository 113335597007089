import { encryptionPhone } from '@flowus/common';
import { CaptchaSendType } from '@next-space/fe-api-idl';
import { reject } from 'lodash-es';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { request } from 'src/common/request';
import { CaptchaPhone } from 'src/components/captcha';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import type { LogoffReasonInfo } from '.';

interface Props {
  account: string;
  reasonInfo: LogoffReasonInfo;
  onConfirm: () => void;
  cancel: () => void;
}

/** 输入验证码完成注销 */
export const CaptchaPanel: FC<Props> = (props) => {
  const [value, setValue] = useState<string>('');
  const [warning, setWarning] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div className="w-[400px] p-10">
      <div className="text-t1-medium">输入验证码完成注销</div>
      <div className="my-4">{`已向注册手机号${encryptionPhone(props.account)}发送验证码`}</div>
      <Input
        disableFloat
        maxLength={6}
        inputRef={inputRef}
        className="w-full h-[50px] mb-5 h-md:text-h4 px-3"
        inputClassName="h-full px-0 text-t2"
        placeholder="请输入验证码"
        onEnter={() => {}}
        value={value}
        onChange={(value) => {
          setValue(value.toString());
        }}
        warning={warning}
        addonAfter={
          <CaptchaPhone
            autoClick
            onClick={() => inputRef.current?.focus()}
            account={props.account}
            type={CaptchaSendType.REMOVE_ACCOUNT}
            onError={(msg) => {
              setWarning(msg);
              inputRef.current?.focus();
            }}
          />
        }
      />
      <Button
        className="mb-3 font-medium w-full"
        colorType={'red'}
        disable={value.length === 0}
        onClick={async () => {
          const res = await request.infra.removeUser.raw($currentUserCache.uuid, {
            captcha: value.trim(),
            reasons: props.reasonInfo.reasons,
            otherReason: props.reasonInfo.otherReason,
          });
          if (res.code !== 200) {
            setWarning(res.msg);
            // do nothing
            bizTracker.event('close_account', {
              is_success: false,
              close_reason: res.msg,
            });
            return;
          }
          props.onConfirm();
        }}
      >
        确定注销
      </Button>
      <Button
        className="font-medium w-full"
        onClick={() => {
          props.cancel();
          reject(new Error('cancel'));
        }}
      >
        取消
      </Button>
    </div>
  );
};
