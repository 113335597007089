import { useMindMapEngine } from '@flowus/mind-map';
import { useCallback } from 'react';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { $appUiStateCache } from 'src/services/app';

export const useToggleNode = () => {
  const engine = useMindMapEngine();
  return useCallback(
    (id: string, expanded?: boolean, saveLocal?: boolean) => {
      const expand = $appUiStateCache.$expandFoldRecord[id] ?? true;
      const ret = expanded ?? !expand;
      engine.updateMindNode({
        id,
        expanded: ret,
      });
      patchExpand([id], ret, saveLocal);
    },
    [engine]
  );
};

export const useToggleAllNode = () => {
  const engine = useMindMapEngine();
  return useCallback(
    (expanded?: boolean) => {
      const rootNode = engine.getRootMindNode();
      const someNodeExpanded = rootNode.childrenIds.some((id) => {
        const node = engine.getMindNode(id);
        return node?.expanded;
      });
      const ret = expanded ?? !someNodeExpanded;
      const uuids: string[] = [];
      const loop = (id: string) => {
        const block = cache.blocks[id];
        if (!block) return;
        uuids.push(id);
        block.subNodes.forEach((cId) => {
          loop(cId);
        });
      };
      rootNode.childrenIds.forEach((id) => {
        loop(id);
      });
      engine.toggleAllMindNode(uuids, ret);
      patchExpand(uuids, ret);
    },
    [engine]
  );
};
