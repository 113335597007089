import { cx } from '@flowus/common/cx';
import React from 'react';

export function NoticeBanner(props: { className?: string; children?: React.ReactNode }) {
  const { className, children } = props;
  return (
    <div
      className={cx(
        'h-[60px] w-full bg-grey8 flex-shrink-0 text-grey3 flex items-center justify-center sticky cursor-default top-0 left-0 z-20',
        className
      )}
    >
      {children}
    </div>
  );
}
