import type { FC } from 'react';
import { useMemo } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { segmentsToText } from 'src/editor/utils/editor';
import { getUntitledName } from 'src/utils/get-untitled-name';
import type { SearchItem } from '../search';

interface Props {
  uuids: string[];
  spaceItems: SearchItem[];
  onSelect?(pageInfo: SearchItem): void;
}
/**
  这块我是copy移动到之前的代码，具体权限逻辑相关的问题需要找志强咨询。
 */
export const SpaceList: FC<Props> = (props) => {
  const items = useMemo(() => {
    return props.spaceItems.map((searchItem: SearchItem) => ({
      type: ListItemType.SEARCH,
      data: {
        uuid: searchItem.uuid,
        title:
          segmentsToText(searchItem.data.segments) ||
          searchItem.title ||
          getUntitledName(searchItem.type),
        avatarTitle: searchItem.avatarTitle,
        icon: searchItem.data.icon,
        navs: searchItem.navs,
        type: searchItem.type,
        backgroundColor: searchItem.backgroundColor,
      },
    }));
  }, [props.spaceItems]);

  const handleMenuItemClick = (_: unknown, index: number) => {
    const searchItem = props.spaceItems[index];
    if (searchItem) {
      props.onSelect?.(searchItem);
    }
  };
  if (items.length === 0) return null;

  return (
    <div className={'relative overflow-y-auto h-[300px]'}>
      <ListView
        items={items}
        hasMore={false}
        scrollContainerStyle={{ top: '52px' }}
        onItemClick={handleMenuItemClick}
      />
    </div>
  );
};
