import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { fetchSharePreviewContent } from 'src/hooks/page/use-fetch-record-content';
import { useSharePayWatermark } from 'src/hooks/share/use-share-pay-watermark';
import { SnapshotPreview } from 'src/views/main/header/page-history';

interface PreviewPageProps {
  uuid: string;
  spaceId: string;
  accessFee: number;
  onClose: () => void;
  previewCount?: number;
}
const PreviewPage: FC<PreviewPageProps> = (props) => {
  const { uuid, spaceId, previewCount } = props;
  useSharePayWatermark(uuid, 'preview-watermark');
  const [recordContent] = useApiState({
    getApi: () => fetchSharePreviewContent(uuid, spaceId, previewCount),
    enable: !!(uuid && spaceId),
  });

  if (!recordContent) {
    return <LoadingContainer className="w-full h-full" />;
  }

  return (
    <>
      <SnapshotPreview
        docId={uuid}
        snapshot={{
          blocks: recordContent?.blocks ?? {},
          collectionViews: recordContent?.collectionViews,
        }}
      />
      {/* <PayAccessFeeContent
        uuid={uuid}
        className="border-t pb-24"
        payInfo={{
          ownerUser: currentUser,
          spaceId,
          openPreview: true,
          accessFee,
          publishAccessFee: accessFee,
          publishAccessPageId: uuid,
          accessPageId: uuid,
          spaceBackgroundColor: space.backgroundColor,
          spaceTitle: space.title,
          spaceIcon: space.icon,
          publishAccessPageTitle: segmentsToText(block?.data.segments) || getUntitledName(),
        }}
      /> */}

      <Icon
        className="absolute right-4 top-4 rounded-full bg-white1 text-black p-1"
        onClick={props.onClose}
        name={'IcBannerClose'}
        size="large"
      />
    </>
  );
};

export const useOpenPreviewPage = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params: Omit<PreviewPageProps, 'onClose'>) => {
      openModal.modal({
        className: 'bg-white1 overflow-hidden',
        content: ({ onCloseModal }) => {
          return (
            <div className="relative">
              <div className="overflow-scroll h-[90vh] w-[80vw]">
                <PreviewPage {...params} onClose={onCloseModal} />
              </div>
            </div>
          );
        },
      });
    },
    [openModal]
  );
};
