import { getOssName } from '@flowus/common/url';
import { WpsComponent } from '@flowus/common/wps';
import { useEffect, useState, type FC } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { request } from 'src/common/request';
import { checkPageId } from 'src/hooks/page/check-page-id';
import { useCurrentUser } from 'src/hooks/user';
import type { FilePreviewProps } from '.';
import { useFilePreviewMode } from './hook';

export const OfficePreview: FC<FilePreviewProps> = ({ downloadUrl, uuid, propertyId }) => {
  const [loading, setLoading] = useState(true);
  const { officeOptions } = useFilePreviewMode();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (uuid) {
      if (currentUser.uuid) {
        void checkPageId(uuid).then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    }
    document.body.classList.add('overscroll-none');

    return () => {
      document.body.classList.remove('overscroll-none');
    };
  }, [currentUser.uuid, uuid]);

  if (!downloadUrl || !uuid) return null;
  const ossName = getOssName(downloadUrl);

  if (loading) return <LoadingContainer />;

  if (!officeOptions.editor) {
    const previewUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
      `${downloadUrl}`
    )}`;

    return <iframe title="office" src={previewUrl} width="100%" height="100%" frameBorder="1" />;
  }

  const refreshOss = () => {
    void checkPageId(uuid);
  };

  return (
    <WpsComponent
      className="w-full h-full flex-1 overflow-hidden"
      appId={import.meta.env.VITE_WPS_KEY}
      getToken={async () => {
        const res = await request.infra.getWpsToken({
          blockId: uuid,
          ossName,
          schemaId: propertyId,
        });
        if (res.ossName !== ossName) {
          refreshOss();
        }
        return res;
      }}
    />
  );
};
