import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type LinePosition = 'left' | 'right' | 'top' | 'bottom';
export interface SimpleTableState {
  activityTableId?: string;
  /** 直接用 focusEditable 键盘左右移动光标时，光标会跟不上 */
  focus?: boolean;
  hoveringCell?: {
    rowIndex: number;
    columnIndex: number;
  };
  dropInfo?: { position: LinePosition; propertyId?: string; recordId?: string };
  /** 选择简单表格的行或列 */
  chosenCells?: { tableId: string; propertyId?: string; recordId?: string };
  selecting?: { tableId: string; rowIndex: number; columnIndex: number };
  draggingStart?: {
    initX: number;
    initY: number;
  };
  draggingBarType?: 'row' | 'column' | 'corner';
}

export const initialState: SimpleTableState = {};

export const simpleTableSlice = createSlice({
  name: 'simple-table',
  initialState,
  reducers: {
    update(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const simpleTableReducer = simpleTableSlice.reducer;
export const simpleTableActions = simpleTableSlice.actions;
