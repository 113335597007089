import { emitter } from '@flowus/common';
import { Modals } from '@flowus/shared';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { $checkCopySubNodesCache, refreshCheckCopySubNode } from 'src/services/capacity';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { useFetchSpaceCapacity } from '../drive/use-fetch-space-capacity';
import { getCurrentSpaceId, getSpaceBySpaceId } from '../space/get-space';
import { useOpenCapacityDialog } from '../space/use-open-capacity-dialog';

export const useCopySubNodesApi = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  const fetchSpaceCapacity = useFetchSpaceCapacity();

  return useCallback(
    async (
      params: Parameters<typeof request.editor.copySubNodes.raw>[0] & { targetSpaceId?: string }
    ) => {
      // 从分享页转存需要传递targetSpaceId，getCurrentSpaceId是不准的
      const spaceId = params.targetSpaceId ?? getCurrentSpaceId();
      checkIsMaximumCapacityDialog(spaceId);
      const res = await request.editor.copySubNodes.raw(params);
      // @ts-ignore error code
      if (res.code === 412) {
        // 如果是拷贝到我的空间要使用选中的空间
        const space = getSpaceBySpaceId(spaceId);
        const capacityInfo = await fetchSpaceCapacity(spaceId);
        openCapacityDialog({
          title: '复制失败',
          maxCapacity: capacityInfo.maxCapacity,
          isNovice: capacityInfo.isNovice,
          planType: space.planType,
          spaceId,
        });
      }

      return res;
    },
    [fetchSpaceCapacity, openCapacityDialog]
  );
};

export const useCheckCopySubNodes = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  const fetchSpaceCapacity = useFetchSpaceCapacity();

  return useCallback(
    async (params: {
      ids: string[];
      spaceId: string;
      forceAwait?: boolean;
      addCopyLimit?: boolean;
      isMoveOtherSpace?: boolean;
    }) => {
      const { ids, spaceId, forceAwait, addCopyLimit, isMoveOtherSpace } = params;

      // 是否需要等
      if (forceAwait) {
        await refreshCheckCopySubNode({ ids, spaceId });
      } else {
        void refreshCheckCopySubNode({ ids, spaceId });
      }

      const curCheck = $checkCopySubNodesCache[spaceId];

      if (!curCheck) return;

      const {
        reachBlockCountLimit,
        reachCapacityLimit,
        reachSingleFileMaxSizeLimit,
        reachCopyLimit,
      } = curCheck;
      if (
        reachBlockCountLimit ||
        reachCapacityLimit ||
        reachSingleFileMaxSizeLimit ||
        (reachCopyLimit && addCopyLimit)
      ) {
        const capacityInfo = await fetchSpaceCapacity(spaceId);
        const space = getSpaceBySpaceId(spaceId);

        const run = () => {
          if (reachBlockCountLimit) {
            emitter.emit('modalId', { modalId: Modals.MAXIMUM_CAPACITY_DIALOG, spaceId });
            return;
          }

          // 优先容量
          if (reachCapacityLimit) {
            openCapacityDialog({
              title: '复制失败',
              isMoveOtherSpace,
              maxCapacity: capacityInfo.maxCapacity,
              isNovice: capacityInfo.isNovice,
              planType: space.planType,
              spaceId,
            });
            return;
          }

          // 单文件
          if (reachSingleFileMaxSizeLimit) {
            openCapacityDialog({
              title: '复制失败',
              isMoveOtherSpace,
              maxCapacity: capacityInfo.singleFileMaxSize,
              isNovice: capacityInfo.isNovice,
              planType: space.planType,
              singleFile: true,
              spaceId,
            });
            return;
          }

          // 拷贝次数
          if (reachCopyLimit) {
            openCapacityDialog({
              title: '复制失败',
              isMoveOtherSpace,
              maxCapacity: capacityInfo.maxCapacity,
              isNovice: capacityInfo.isNovice,
              planType: space.planType,
              spaceId,
              copyLimit: true,
            });
          }
        };

        run();
        throw new Error('达到上限');
      }
    },
    [fetchSpaceCapacity, openCapacityDialog]
  );
};
