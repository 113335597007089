import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { addBlock } from 'src/redux/managers/block/add';

import { useTransaction } from '../use-transaction';

export const useInsertTemplateRecord = () => {
  const transaction = useTransaction();
  return useCallback(
    (collectionId: string) => {
      return transaction(() => {
        const recordId = addBlock(
          {
            type: BlockType.PAGE,
            isTemplate: true,
          },
          {
            parentId: collectionId,
            templatePages: true,
          }
        );
        return recordId;
      });
    },
    [transaction]
  );
};
