import {
  getBlockBackgroundColor,
  getBlockTextColor,
  getMarkBlockBackgroundColor,
} from '@flowus/common/block/color/get-block-color';
import { useMindMapId } from '@flowus/mind-map';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { useHasUnresolvedComments } from 'src/editor/editor/inline/comment';
import { useBlockStyle } from 'src/hooks/block/use-block-style';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useIsInFixWidthContext } from '../fix-width-context-provider';
import { useIsDarkMode } from '@flowus/common';

export const useMindNodeStyle = (uuid: string, level: number): CSSProperties => {
  const blockStyle = useBlockStyle(uuid);
  const mindMapId = useMindMapId();
  const isDarkMode = useIsDarkMode();
  // 主题分支覆盖块的颜色
  const nodeStyle = useMindNodeColorStyle(uuid, level);
  if (uuid === mindMapId) {
    // 由于root节点文本默认是白色的，如果只设置了背景，需要让文本颜色改为黑色
    if (blockStyle.backgroundColor && !nodeStyle?.backgroundColor) {
      blockStyle.color = `${isDarkMode ? 'var(--white1)' : 'var(--black)'}`;
    }
  }

  const isInFixWidthContext = useIsInFixWidthContext();
  if (isInFixWidthContext) {
    return {
      color: blockStyle.color || nodeStyle?.color,
      backgroundColor: blockStyle.backgroundColor || nodeStyle?.backgroundColor,
      left: '-9999px',
      top: '-9999px',
    };
  }

  return {
    color: blockStyle.color || nodeStyle?.color,
    backgroundColor: blockStyle.backgroundColor || nodeStyle?.backgroundColor,
  };
};

export const useMindNodePadding = (uuid: string, level: number) => {
  const hasUnresolvedComments = useHasUnresolvedComments();
  const padding = useMemo(() => {
    if (level === 0) return 30;
    if (level === 1) return 20;
    return 16;
  }, [level]);
  return { paddingLeft: padding, paddingRight: hasUnresolvedComments ? 0 : padding };
};

export const useMindNodeColor = (uuid: string) => {
  const mindMapId = useMindMapId();
  const nodeColor = useObservableStore(
    (state) => {
      // 一层一层往上找
      let id = uuid;
      let nodeColor: string | undefined;
      while (id) {
        const b = state.blocks[id];
        nodeColor = b?.data?.format?.mindMappingFormat?.nodeThemeColor;
        if (nodeColor) break;
        if (id === mindMapId) {
          break;
        }
        id = b?.parentId ?? '';
      }
      return nodeColor;
    },
    [uuid]
  );
  return nodeColor;
};

const useMindNodeColorStyle = (
  uuid: string,
  level: number
): Pick<CSSProperties, 'color' | 'backgroundColor'> | undefined => {
  const nodeColor = useMindNodeColor(uuid);
  if (!nodeColor) return;
  const backgroundColor =
    level === 1 ? getBlockBackgroundColor(nodeColor) : getMarkBlockBackgroundColor(nodeColor);

  return {
    color: getBlockTextColor(nodeColor),
    backgroundColor,
  };
};
