import React from 'react';
import { CodeBlockContent } from 'src/editor/editor/plugin/code';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';

export const CodeNode: MindNodeElement = React.memo((props) => {
  return (
    <NodeWrapper id={props.id} className="group !bg-grey8" level={props.level}>
      <CodeBlockContent id={props.id} />
    </NodeWrapper>
  );
});
