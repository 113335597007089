export const parseEditorKeyToBlockId = (editorKey?: string | null) => {
  if (!editorKey) {
    return '';
  }
  const array = editorKey.split('::');
  let blockId = array[array.length - 1];
  if (blockId) {
    // 因为codeBlock是uuid_mock,因此这里要截取id
    blockId = blockId.slice(0, 36);
  }
  return blockId ?? '';
};
