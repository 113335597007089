import { $dropInfo, useIsDraggingBlock } from 'src/services/app/hook/use-drop-info';

/** 单独移出来的原因是因为之前有循环引用 */
export const useIsDragging = () => {
  return useIsDraggingBlock();
};

export const useHoveringEmpty = (viewId: string) => {
  return $dropInfo((state) => {
    if (!state) return false;
    return !!state.empty && state.empty.viewId === viewId;
  });
};
