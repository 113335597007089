import allData from '../data/all.json';
import { uncompress } from './data';
import { unifiedToNative } from './index';
/**
    `emoji 分类：`
    1. Objects -> 2. Activity -> 3. Travels & places -> 4. Smiley & people -> 5. Animals & nature -> 6.food ->7. Flags -> 8. Symbols
    `随机显示规则：`
      第一次选择，在前6个分类里随机显示，之后在全分类里随机选择，每个分类连续选择不能超过3次
 */
//分类的index
const IDX_PEOPLE = 0;
const IDX_NATURE = 1;
const IDX_FOODS = 2;
const IDX_ACTIIVITY = 3;
const IDX_PLACES = 4;
const IDX_OBJECTS = 5;
const IDX_SYMBOLS = 6;
const IDX_FLAGS = 7;

//每次打开emoji panel时第一次随机选择的结果
const firstRandomCategory = [
  IDX_OBJECTS,
  IDX_ACTIIVITY,
  IDX_PLACES,
  IDX_PEOPLE,
  IDX_NATURE,
  IDX_FOODS,
];

const allRandomCategory = [
  IDX_OBJECTS,
  IDX_ACTIIVITY,
  IDX_PLACES,
  IDX_PEOPLE,
  IDX_NATURE,
  IDX_FOODS,
  IDX_SYMBOLS,
  IDX_FLAGS,
];
//随机信息，记录上一次选中同一次分类的次数
const randomInfo = {};

export function getRandomEmoji(isFirst) {
  const data = getAllEmojiData();

  //reset
  if (isFirst) {
    randomInfo.categoryIndex = Infinity;
    randomInfo.count = 0;
  }
  const [categoryIndex, emojiData] = getCategoryRandomEmoji(
    data,
    isFirst ? firstRandomCategory : allRandomCategory
  );
  if (randomInfo.categoryIndex === categoryIndex) {
    randomInfo.count++;
  } else {
    randomInfo.categoryIndex = categoryIndex;
    randomInfo.count = 1;
  }
  if (randomInfo.count > 3) {
    //如果连续3次以上随机到同一个分类，就重新获取
    return getRandomEmoji();
  }
  return emojiData;
}

//随机获取分类数组里面的某个emoji
function getCategoryRandomEmoji(data, categorys) {
  const categoryIndex = Math.round(Math.random() * (categorys.length - 1));
  return [categoryIndex, getSingleCategoryRandomEmoji(data, categoryIndex)];
}

//随机获取单个分类emoji
function getSingleCategoryRandomEmoji(data, categoryIndex) {
  const category = data.categories[categoryIndex];
  const randomIndex = Math.round(Math.random() * (category.emojis.length - 1));
  const key = category.emojis[randomIndex];
  const emojiData = data.emojis[key];
  const emojiStr = unifiedToNative(emojiData.unified);
  return emojiStr;
}
export function getAllEmojiData() {
  if (allData.compressed) {
    uncompress(allData);
  }
  return allData;
}
