import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SelectBlock, SelectCell } from '../types';

export enum NotFoundStatus {
  FULL_PAGE = 'FULL_PAGE',
  PAGE = 'PAGE',
}

export interface UIState {
  /** 选中的block,第0个表示focused,shift+方向键/鼠标点击时作为锚点*/
  // 推荐你调用uiActions.updateSelectBlocks方法,别直接用update方法更新这个变量
  selectedBlocks: SelectBlock[];

  /** shift+mousedown 选中block是从上到下(focusBlock是第一个)还是从下到上(focusBlock是最后一个)，判断focusBlock是哪一个*/
  isSelectTopToBottom?: boolean;

  /** 当前被选中的 table 中的 cell, 第0个就是focus的cell */
  selectedCells: SelectCell[];
}

const initialState: UIState = {
  selectedBlocks: [],
  selectedCells: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<typeof initialState>>) {
      if (action.payload.selectedBlocks) {
        state.isSelectTopToBottom = true;
      }
      Object.assign(state, action.payload);
    },
    updateSelectBlocks(state, action: PayloadAction<SelectBlock[]>) {
      state.selectedBlocks = action.payload;
      if (action.payload.length) {
        state.selectedCells = [];
      }
      state.isSelectTopToBottom = true;
    },
    addSelectBlock(state, action: PayloadAction<SelectBlock>) {
      const found = state.selectedBlocks.find((s) => s.blockId === action.payload.blockId);
      if (!found) {
        state.selectedBlocks.push(action.payload);
      }
    },
    removeSelectBlock(state, action: PayloadAction<SelectBlock>) {
      const index = state.selectedBlocks.findIndex((s) => s.blockId === action.payload.blockId);
      if (index >= 0) {
        state.selectedBlocks.splice(index, 1);
      }
    },
    updateSelectCells(state, action: PayloadAction<SelectCell[]>) {
      state.selectedCells = action.payload;
      if (action.payload.length) {
        state.selectedBlocks = [];
        state.isSelectTopToBottom = true;
      }
    },
  },
  extraReducers() {},
});

export const uiReducer = uiSlice.reducer;
export const uiActions = uiSlice.actions;
