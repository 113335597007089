import type { MindMapEngine } from '../mind-map-engine';

/**划线的 */
export const CANVAS_PADDING = 300;
export class CanvasGraph {
  private engine: MindMapEngine;
  private canvasContext: CanvasRenderingContext2D | null = null;
  private canvasElement: HTMLCanvasElement | null = null;
  private dragCanvasElement: HTMLCanvasElement | null = null;
  private dragCanvasContext: CanvasRenderingContext2D | null = null;
  private width = 0;
  private height = 0;
  private scale = 1;
  constructor(engine: MindMapEngine) {
    this.engine = engine;
    if (window.devicePixelRatio) {
      //抗锯齿 TODO: 位置偏移不对，暂不打开
      // this.scale = window.devicePixelRatio;
    }
  }
  getCanvasContext = () => {
    return this.canvasContext;
  };
  getDragCanvasContext = () => {
    return this.dragCanvasContext;
  };

  setCanvas = (canvasElement: HTMLCanvasElement) => {
    this.canvasElement = canvasElement;
    this.canvasContext = canvasElement.getContext('2d');
  };
  setDragCanvas = (canvasElement: HTMLCanvasElement) => {
    this.dragCanvasElement = canvasElement;
    this.dragCanvasContext = canvasElement.getContext('2d');
    this.dragCanvasContext?.scale(this.scale, this.scale);
    this.dragCanvasElement?.setAttribute('width', this.width.toString());
    this.dragCanvasElement?.setAttribute('height', this.height.toString());
    this.layout();
  };

  offsetX = CANVAS_PADDING / 2;
  offsetY = CANVAS_PADDING / 2;
  setCanvasSize = (width: number, height: number) => {
    this.width = (width + CANVAS_PADDING) * this.scale;
    this.height = (height + CANVAS_PADDING) * this.scale;
    this.canvasContext?.scale(this.scale, this.scale);

    this.canvasElement?.setAttribute('width', this.width.toString());
    this.canvasElement?.setAttribute('height', this.height.toString());
    this.layout();
  };
  layout = () => {
    this.canvasContext?.save();
    this.dragCanvasContext?.save();
    this.canvasContext?.translate(this.offsetX, this.offsetY);
    this.dragCanvasContext?.translate(this.offsetX, this.offsetY);

    this.clear();
    this.canvasContext?.beginPath();
    this.engine.dragLines();
    this.canvasContext?.restore();
    this.dragCanvasContext?.restore();
  };
  clear = () => {
    this.canvasContext?.clearRect(
      -this.offsetX,
      -this.offsetY,
      this.width + this.offsetX,
      this.height + this.offsetY
    );
    this.dragCanvasContext?.clearRect(
      -this.offsetX,
      -this.offsetY,
      this.width + this.offsetX,
      this.height + this.offsetY
    );
  };

  destroy = () => {};
}
