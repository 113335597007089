import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState } from 'react';
import { Checkbox } from 'src/components/check-box';

interface Props {
  content: React.ReactNode;
  defaultChecked?: boolean;
  className?: string;
  onCheck: (check: boolean) => void;
}
export const CheckboxText: FC<Props> = (props) => {
  const [check, setCheck] = useState(props.defaultChecked);
  return (
    <div
      className={cx('flex items-center text-t2', props.className)}
      onClick={() => {
        setCheck(!check);
        props.onCheck(!check);
      }}
    >
      <Checkbox size="middle" checked={check} className="mr-0.5" />
      {props.content}
    </div>
  );
};
