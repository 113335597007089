import type { FC } from 'react';
import { cx } from '../cx';

export const EllipsisSpan: FC<JSX.IntrinsicElements['span']> = ({
  children,
  className,
  ...props
}) => {
  return (
    <span
      className={cx('whitespace-nowrap overflow-hidden overflow-ellipsis', className)}
      {...props}
    >
      {children}
    </span>
  );
};
