import QRCode from 'qrcode';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { useCurrentUser } from 'src/hooks/user';
import { bizTracker } from 'src/utils/biz-tracker';

interface ShareQrProps {
  url: string;
  title: string;
}
const ShareQr: FC<ShareQrProps> = (props) => {
  const { url } = props;
  const [qrCode, setQrCode] = useState<string>('');
  const currentUser = useCurrentUser();

  useEffect(() => {
    void (async () => {
      // 二维码url
      const _qr = await QRCode.toDataURL(`${url}`, {
        errorCorrectionLevel: 'H',
      });
      setQrCode(_qr);
    })();
  }, [currentUser.avatar, currentUser.backgroundColor, currentUser.nickname, url]);

  return (
    <div className="next-modal">
      <img className="w-[140px] h-[140px]" src={qrCode}></img>
    </div>
  );
};

export const useOpenShareLinkQr = () => {
  const openModal = useOpenModal();
  return useCallback(
    (e: React.MouseEvent, params: ShareQrProps) => {
      bizTracker.event('click_share_btn', {
        type: 'qr',
      });
      openModal.dropdown({
        popcorn: e.currentTarget,
        placement: 'right-end',
        content: () => <ShareQr {...params} />,
      });
    },
    [openModal]
  );
};
