import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { checkPropRecur } from 'src/bitable/v2';
import type { BiSchemaResolver } from 'src/bitable/v2/utils/resolvers';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { ICON_MAP } from '../../../const';

interface PropertyProps {
  collectionId: string;
  propertyId: string;
  closeSelf: () => void;
}

export const SelectPropertyList: FC<PropertyProps> = ({ collectionId, propertyId, closeSelf }) => {
  const { relationPropertySchema } = usePropertySchema(collectionId, propertyId);
  const updatePropertySchema = useUpdatePropertySchema();
  const allProperties = useObservableStore(
    ({ blocks }) => {
      const collection = blocks[relationPropertySchema?.collectionId ?? ''];
      const allProperties: (CollectionSchema & { propertyId: string })[] = [];
      const resolver: BiSchemaResolver = {
        findSchema(collId, propId) {
          return blocks[collId]?.data.schema?.[propId];
        },
      };
      Object.entries(collection?.data.schema ?? {}).forEach(([propId, schema]) => {
        if (
          schema.type !== CollectionSchemaType.ROLLUP &&
          !checkPropRecur(
            collectionId,
            propertyId,
            relationPropertySchema?.collectionId ?? '',
            propId,
            resolver
          )
        ) {
          allProperties.push({
            ...schema,
            propertyId: propId,
          });
        }
      });

      return allProperties;
    },
    [collectionId, propertyId, relationPropertySchema?.collectionId]
  );

  const items = allProperties.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        icon: ICON_MAP[item.type],
        title: item.name,
        propertyId: item.propertyId,
        type: item.type,
      },
    };
  });

  const handleClick = (targetProperty: string, targetPropertyType: CollectionSchemaType) => {
    const { blocks } = getState();
    const propertySchema = (blocks[collectionId]?.data.schema ?? {})[propertyId] ?? {};
    updatePropertySchema(collectionId, propertyId, {
      ...propertySchema,
      targetProperty,
      targetPropertyType,
      aggregation: undefined,
      showAs: undefined,
    });
    closeSelf();
  };

  return (
    <div className="flex flex-col w-[300px] next-modal-scroll py-2">
      <ListView
        items={items}
        onItemClick={(item) => handleClick(item.data.propertyId, item.data.type)}
      />
    </div>
  );
};
