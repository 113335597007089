import { createCache, createSetState } from '@flowus/common/zustand/utils';
import produce from 'immer';
import { useIsExistModalId } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';
import type { PatchState } from 'src/utils/type-utils';
import { $appModalState } from './state';
import type { AppModalStateType } from './type';

export const $appModalStateCache = createCache($appModalState);

const $setAppModalState = createSetState($appModalState);

export const setAppModalState: PatchState<AppModalStateType> = (key, value) => {
  $setAppModalState(
    produce((pre) => {
      pre[key] = value;
    })
  );
};

export const useSettingState = () => {
  const $settingState = $appModalState((state) => state.$settingState);
  return $settingState;
};

export const useIsOpenAiEditor = () => {
  return useIsExistModalId(Modals.AI_EDITOR);
};
