import { Compare } from '@flowus/common';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

import type { BiRecordResolver } from '../utils/resolvers';
import { segmentsToText } from '../utils/segments-utils';
import { BiArrayValueType } from './_abstract';

export class BiRelationType extends BiArrayValueType<string> {
  constructor(private relCollId: string, private resolver: BiRecordResolver) {
    super();
  }

  fromSegments(segments: SegmentDTO[] | undefined): string[] | undefined {
    if (segments === undefined) return undefined;
    const ids: string[] = [];
    for (const segment of segments) {
      if (segment.type === TextType.LINK_PAGE && segment.uuid !== undefined) {
        ids.push(segment.uuid);
      }
    }
    if (ids.length === 0) return undefined;
    return ids;
  }

  toSegments(value: string[]): SegmentDTO[] | undefined {
    return value.map((it) => {
      return {
        type: TextType.LINK_PAGE,
        enhancer: {},
        text: '@',
        uuid: it,
      };
    });
  }

  override isNonNullEmpty(value: string[]): boolean {
    return value.every(
      (it) => segmentsToText(this.resolver.findRecord(this.relCollId, it)?.data.segments) === ''
    );
  }

  override compare(value1: string[], value2: string[]): number {
    // NOTE：产品要求排序的时候忽略掉空标题的记录
    return super.compare(
      value1
        .map((it) => segmentsToText(this.resolver.findRecord(this.relCollId, it)?.data.segments))
        .filter((it) => it !== ''),
      value2
        .map((it) => segmentsToText(this.resolver.findRecord(this.relCollId, it)?.data.segments))
        .filter((it) => it !== '')
    );
  }

  compareElement(value1: string, value2: string): number {
    return Compare.pinyin(value1, value2);
  }
}
