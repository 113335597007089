import { useEffect, useMemo, useRef, type FC } from 'react';
import { Select } from './select';
import { HorizontalAxisConfig } from './axis-config';
import { useOpenModal } from '@flowus/common/next-modal';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { SearchMenu } from 'src/components/search';
import { useStore } from 'react-redux';
import { updateBlock } from 'src/redux/managers/block/update';
import type { RootState } from 'src/redux/types';
import { useBlock } from 'src/hooks/block/use-block';
import { cx } from '@flowus/common/cx';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { ViewIconMap, ViewNameMap } from 'src/bitable/bitable-manager/const';
import { ListView } from 'src/common/components/list-view';
import { segmentsToText } from 'src/editor/utils/editor';
import { usePickBlock } from 'src/utils/pick-block';
import { useChartBlockId } from './chart-config-context';
import { IconTrigger } from 'src/components/icon-trigger';
import { useFetchPage } from 'src/hooks/page';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { useUpdateChartCollectionId } from 'src/hooks/chart';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { Icon } from 'src/common/components/icon';
import { getChartTypeName } from '@flowus/chart/chart-util';
import { SelectChartTypePanel } from './select-chart-type-panel';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useGetRecentlyUpdateCollection } from './use-get-recently-update-collection';
const getSelectOptioinWidth = (container: HTMLDivElement | null) => {
  const selectOption = container?.querySelector('.select-option');
  return selectOption?.getBoundingClientRect().width ?? 300;
};
/** 数据和类型 */
export const DataSourceConfig: FC = () => {
  const openModal = useOpenModal();
  const divRef = useRef<HTMLDivElement>(null);
  const divSelectRef = useRef<HTMLDivElement>(null);
  const chartBlockId = useChartBlockId();
  const chartBlock = useBlock(chartBlockId);
  const collectionId = chartBlock?.data.format?.chartFormat?.ref?.uuid;
  const viewId = chartBlock?.data.format?.chartFormat?.ref?.viewId;
  const view = useObservableStore(
    (state) => {
      const { blocks, collectionViews } = state;
      const cb = blocks[chartBlockId];
      const viewId = cb?.data.format?.chartFormat?.ref?.viewId;
      if (viewId) {
        return collectionViews[viewId];
      }
    },
    [chartBlockId]
  );

  const collectionBlock = usePickBlock(collectionId, ['views', 'data'], ['segments', 'format']);
  const store = useStore();
  const updateChartCollectionId = useUpdateChartCollectionId();
  const fetchPage = useFetchPage();
  const collectionItems = useGetRecentlyUpdateCollection();

  const collectionName = useMemo(() => {
    return segmentsToText(collectionBlock?.data.segments) || getUntitledName(collectionBlock?.type);
  }, [collectionBlock?.data.segments, collectionBlock?.type]);

  useEffect(() => {
    if (!collectionItems.length) return;
    if (!collectionId) {
      setTimeout(() => {
        divSelectRef.current?.click();
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionItems]);

  const collectionTitleNode = collectionId ? (
    <div className="flex items-center">
      <IconTrigger
        blockId={collectionId}
        trigger={false}
        iconSize={20}
        defaultIcon={<BlockDefaultIcon uuid={collectionId} size="middle" />}
      />
      <div className="ml-0.5">{collectionName}</div>
    </div>
  ) : undefined;
  const viewTitleNode = view ? (
    <div className="flex items-center">
      <Icon name={ViewIconMap[view.type]} size="middle" />
      <div className="ml-0.5">{view?.title}</div>
    </div>
  ) : undefined;
  if (!chartBlock) return null;

  return (
    <div ref={divRef} className="w-full px-4 py-3">
      <div className="space-y-4">
        <div className="text-t2-medium">数据源表格</div>
        <Select
          ref={divSelectRef}
          title={collectionTitleNode}
          className="select-option"
          placeHolder="请选择数据源"
          onClick={(e) => {
            const width = getSelectOptioinWidth(divRef.current);
            openModal.dropdown({
              popcorn: e.currentTarget,
              placement: 'bottom',
              offset: [0, 0],
              content: ({ onCloseModal }) => (
                <SearchMenu
                  style={{
                    width,
                  }}
                  defaultValue={collectionItems}
                  placeholder="搜索多维表"
                  searchType="collection"
                  source="collectionSettingsSource"
                  onSelect={async (target) => {
                    void fetchPage(target.uuid);
                    updateBlock(
                      chartBlockId,
                      {
                        data: {
                          format: {
                            chartFormat: {
                              ref: {
                                uuid: target.uuid,
                                viewId: '',
                              },
                            },
                          },
                        },
                      },
                      false,
                      // @ts-ignore typecheck
                      store
                    );
                    onCloseModal();
                  }}
                />
              ),
            });
          }}
        />
        <div className="text-t2-medium">数据范围</div>
        <Select
          title={viewId === '' ? '全部数据' : viewTitleNode}
          className={cx({ 'cursor-not-allowed': !collectionBlock })}
          placeHolder="全部数据"
          onClick={(e) => {
            if (!collectionId) return;
            const width = getSelectOptioinWidth(divRef.current);
            const state: RootState = store.getState() as RootState;
            const viewIds = collectionBlock?.views;
            if (!viewIds) return;
            const items = viewIds
              .map((viewId) => {
                const view = state.collectionViews[viewId];
                if (!view) return null;
                const title = view.title || ViewNameMap[view.type];
                const icon = ViewIconMap[view.type];
                const item: ListItem = {
                  type: ListItemType.OPERATION,
                  data: {
                    icon,
                    title,
                    selected: chartBlock.data.format?.chartFormat?.ref?.viewId === viewId,
                    selectColor: 'var(--active_color)',
                    onClick: () => {
                      updateChartCollectionId(chartBlockId, collectionId, viewId);
                    },
                  },
                };
                return item;
              })
              .filter((v) => v) as ListItem[];
            const allItems: ListItem[] = [...items];
            allItems.unshift(
              ...[
                {
                  type: ListItemType.OPERATION,
                  data: {
                    icon: ViewIconMap[CollectionViewType.TABLE],
                    title: '全部数据',
                    selected: !chartBlock.data.format?.chartFormat?.ref?.viewId,
                    selectColor: 'var(--active_color)',
                    onClick: () => {
                      updateChartCollectionId(chartBlockId, collectionId, '');
                    },
                  },
                },
                {
                  type: ListItemType.LINE,
                },
              ]
            );
            openModal.dropdown({
              popcorn: e.currentTarget,
              content: ({ onCloseModal }) => {
                return (
                  <div style={{ width }} className="next-modal max-h-[300px] overflow-auto">
                    <ListView
                      items={allItems}
                      onItemClick={() => {
                        onCloseModal();
                      }}
                    />
                  </div>
                );
              },
            });
          }}
        />
        <div className="text-t2-medium">图表类型</div>
        <Select
          title={getChartTypeName(chartBlock.data.format?.chartFormat)}
          placeHolder="请选图表类型"
          onClick={(e) => {
            const width = getSelectOptioinWidth(divRef.current);
            openModal.dropdown({
              popcorn: e.currentTarget,
              content: ({ onCloseModal }) => {
                return (
                  <SelectChartTypePanel
                    style={{
                      scrollbarWidth: 'none',
                      width,
                    }}
                    onClose={onCloseModal}
                  />
                );
              },
            });
          }}
        />
      </div>
      {/* 横轴 */}
      <HorizontalAxisConfig />
    </div>
  );
};
