import type { CollectionFilter, CollectionSchema, FormLogicRule } from '@next-space/fe-api-idl';
import { SPECIAL_SPACE_ID } from 'src/common/const';
import { getState } from 'src/redux/store';
import { preprocessFilter } from '../bitable-manager/config-table-filter';
import { isFilterValid } from '../table-view/body/filters';

export const checkPropsChangeCauseNewFormLogic = (
  collId: string,
  viewId: string,
  newProps: string[],
  formLogic: FormLogicRule[]
) => {
  const view = getState().collectionViews[viewId];
  const schema = getState().blocks[collId]?.data.schema ?? {};
  const oldProps = (view?.format.formProperties ?? []).map((it) => it.property);
  const formLogic1 = preprocessFormLogic(schema, oldProps, formLogic);
  const formLogic2 = preprocessFormLogic(schema, newProps, formLogic1);
  if (formLogic2.length < formLogic1.length) {
    return formLogic2;
  }
  return undefined;
};

export const preprocessFormLogic = (
  schema: Record<string, CollectionSchema>,
  visibleProperties: string[],
  formLogic: FormLogicRule[]
) => {
  return formLogic
    .filter((it) => {
      const filterPropertyIndex = visibleProperties.indexOf(it.condition.property);
      const gotoPropertyIndex = visibleProperties.indexOf(it.gotoProperty);

      if (filterPropertyIndex === -1 || !schema[it.condition.property]) {
        return false;
      }

      if (it.gotoProperty !== SPECIAL_SPACE_ID) {
        if (gotoPropertyIndex === -1 || !schema[it.gotoProperty]) {
          return false;
        }
        if (gotoPropertyIndex <= filterPropertyIndex) {
          return false;
        }
      }

      return isFilterValid(it.condition, schema);
    })
    .map((it): FormLogicRule => {
      return {
        condition: preprocessFilter(schema, it.condition) as CollectionFilter,
        gotoProperty: it.gotoProperty,
      };
    });
};
