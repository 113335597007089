import type { FC } from 'react';
import type { ElementItemProps } from './types';

export const supportItems: { [type: string]: FC<ElementItemProps> } = {};
export const disableSelectedItems = new Set<string>();

export const registerSupportItem = (
  type: string,
  element: FC<ElementItemProps>,
  disableActive?: boolean
) => {
  supportItems[type] = element;
  if (disableActive) {
    disableSelectedItems.add(type);
  }
};

export const listViewItemDefaultClassName = 'py-1.5 px-2.5 mx-1 ';

export const listViewNormalClassNameNotModal =
  'max-h-[65vh] w-[260px] rounded py-2 overflow-y-auto';

export const listViewNormalClassName = `next-modal ${listViewNormalClassNameNotModal}`;
