import type { FC, PropsWithChildren } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { BehaviorSubject } from 'rxjs';
import { cx } from '../cx';
import { deepEqual } from '../utils';
import { emitter } from '../utils/emitter';
import { ModalContext, useGetOpenModalList } from './hooks/index';
import { NextModalContext } from './hooks/state';
import { useCloseModal } from './hooks/use-close-modal';
import './style.css';
import type { ModalSchema } from './type';

interface NextModalProviderProps {
  initDelay?: number;
  element?: () => HTMLElement | null | undefined;
  config?: ModalSchema.NextModalProviderConfig;
}
export const NextModalProvider: FC<PropsWithChildren<NextModalProviderProps>> = (props) => {
  const obs$ = useRef(new BehaviorSubject({})).current;
  const config = useRef(props.config).current;

  return (
    <NextModalContext.Provider value={{ state: obs$, config }}>
      <RenderNodes {...props} />
      {props.children}
    </NextModalContext.Provider>
  );
};

export const RenderNodes: FC<NextModalProviderProps> = memo((props) => {
  const { element, config } = props;
  const nodes = useGetOpenModalList();
  const closeModal = useCloseModal();
  //由于element可能在二次渲染之后才能拿得到，如果没渲染就没办法显示弹窗,所以提供一个方法，在useEffect上获取
  const [el, setEl] = useState<HTMLElement>();

  useEffect(() => {
    if (element) {
      //有些情况无法直接拿到，要delay一下
      setTimeout(() => {
        const el = element();
        if (!el) {
          setEl(document.body);
          return;
        }
        setEl(el);
      }, props.initDelay ?? 0);
    } else {
      setEl(document.body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callback = (id: string) => {
      if (nodes.some((item) => item.id === id)) {
        closeModal(id, { ignoreEmitter: true });
      }
    };
    emitter.on('closeModal', callback);
    return () => {
      emitter.off('closeModal', callback);
    };
  }, [closeModal, nodes]);

  if (!el) return null;

  return createPortal(
    <>
      <ModalContext.Provider value={true}>
        {nodes.map((item) => (
          <div key={item.id} className={cx('fixed z-[8848]', config?.nodeClassName)}>
            {item.element}
          </div>
        ))}
      </ModalContext.Provider>
    </>,
    el
  );
}, deepEqual);
