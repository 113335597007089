import type { Placement } from '@floating-ui/react-dom-interactions';
import {
  autoUpdate,
  flip,
  FloatingOverlay,
  shift,
  useClick,
  useFloating,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import { useClickAway } from 'ahooks';
import isHotkey from 'is-hotkey';
import type { FC, MouseEvent, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import type { ModalSchema } from '.';
import { sleep } from '../async';
import { cx } from '../cx';
import { isHTML } from '../dom';
import { globalListenerHelper, PRIORITY_DIALOG } from '../hooks/global-listener-helper';
import { useDebounceElementSize } from '../hooks/use-debounce-element-size';
import { globalResizeObserver } from '../utils/global-resize-observer';
import { globalModalPopper } from './hooks/state';
import { useCloseModal } from './hooks/use-close-modal';

// #region dropdown
export const RenderDropdown: FC<ModalSchema.RenderDropdown> = (props) => {
  const {
    popcorn,
    content: Content,
    contentProps,
    placement,
    modalId,
    modifiers = [],
    escCloseBeforeCallBack,
    mask = true,
    autoClose = true,
    closeBeforeCallBack,
    closeAfterCallBack,
    offset = [0, 8],
    className = '',
    keyboard = true,
    flip = true,
    id = 'dropDownContent',
    closeNoAnimate = false,
    arrow,
    arrowClassName,
    disableEsc,
    animation = 'slideIn',
    blockMaskClick = true,
  } = props;
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const windowSize = useDebounceElementSize(document.body);
  const popcornSize = useDebounceElementSize(isHTML(popcorn) ? (popcorn as HTMLElement) : null);
  const closeModal = useCloseModal();
  const popper = useRef<Instance | null>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const closeCallBacked = useRef(false);
  const lastReferenceRect = useRef({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const onCloseModal = useCallback<ModalSchema.CloseModalType>(
    (opts) => {
      let defaultOpts = opts;
      if (closeCallBacked.current) return;
      const res = closeBeforeCallBack?.();
      closeCallBacked.current = true;

      if (res === 'prevent') {
        closeCallBacked.current = false;
        return;
      }

      defaultOpts = {
        closeAfterCallBack: () => {
          closeAfterCallBack?.();
          opts?.closeAfterCallBack?.();
        },
        noAnimation: opts?.noAnimation || closeNoAnimate,
      };

      if (modalId) {
        closeModal(modalId, defaultOpts);
      }
    },
    [closeAfterCallBack, closeBeforeCallBack, closeModal, closeNoAnimate, modalId]
  );

  useClickAway(
    () => {
      !mask && autoClose && onCloseModal();
    },
    contextMenuRef,
    'pointerdown'
  );

  const onEscDown = useCallback(
    (event: KeyboardEvent) => {
      if (isHotkey('esc')(event)) {
        event.preventDefault();
        event.stopPropagation();
        escCloseBeforeCallBack?.();
        onCloseModal();
      }
    },
    [onCloseModal, escCloseBeforeCallBack]
  );

  const forceUpdate = () => {
    try {
      if (contextMenuRef.current) {
        void popper.current?.update();
      }
    } catch {
      // 可能会报错
    }
  };

  useEffect(() => {
    void sleep(500).then(forceUpdate);
  }, [windowSize, popcornSize]);

  useEffect(() => {
    if (!contextMenuRef.current) return;
    const _modifiers: ModalSchema.RenderDropdown['modifiers'] = [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false, // true by default
        },
      },
      {
        name: 'offset',
        options: { offset },
      },
      {
        name: 'checkHeight',
        enabled: true,
        phase: 'afterMain',
        fn: ({ state }) => {
          const checkHtml = isHTML(state.elements.reference);
          if (checkHtml) {
            if (state.rects.reference.x === 0 && state.rects.reference.y === 0) {
              state.rects.reference = lastReferenceRect.current;
              state.reset = true;
            } else {
              lastReferenceRect.current = state.rects.reference;
            }
          }
        },
      },
    ];

    if (flip) {
      _modifiers.push({
        name: 'preventOverflow',
        options: {
          tether: false,
          altAxis: true,
        },
      });
    } else {
      _modifiers.push({ name: 'flip', enabled: false });
    }

    if (arrow) {
      _modifiers.push({
        name: 'arrow',
        options: {
          element: arrowRef.current,
        },
      });
    }

    _modifiers.push(...modifiers);

    popper.current = createPopper(popcorn, contextMenuRef.current, {
      placement: placement || 'bottom-end',
      strategy: 'fixed',
      modifiers: _modifiers,
    });

    globalModalPopper.set(modalId ?? '', new WeakRef(popper.current));

    const observe = globalResizeObserver.observe(contextMenuRef.current, () => forceUpdate());

    if (keyboard) {
      if (disableEsc) return;
      globalListenerHelper.addEventListener('keydown', onEscDown, PRIORITY_DIALOG, true);
    }

    return () => {
      if (keyboard) {
        if (disableEsc) return;
        globalListenerHelper.removeEventListener('keydown', onEscDown, true);
      }
      observe();
      popper.current?.destroy();
      onCloseModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {mask && (
        <div
          data-modal-mask-id={modalId}
          className="fixed inset-0"
          onContextMenuCapture={(e) => e.preventDefault()}
          onPointerDown={(e) => {
            if (blockMaskClick) {
              e.preventDefault();
            }
          }}
          onClick={(event) => {
            event.stopPropagation();
            autoClose && onCloseModal();
          }}
        />
      )}

      <div
        ref={contextMenuRef}
        id={id}
        data-modal-id={modalId}
        className={cx(
          '-inset-full next-modal-dropdown',
          animation === 'slideIn' && 'dropDownContent',
          animation === 'fadeIn' && 'animate__animated animate__fadeIn',
          className
        )}
        style={animation === 'fadeIn' ? { animationDuration: '0.1s' } : {}}
        onClick={(e) => e.stopPropagation()}
      >
        <Content onCloseModal={onCloseModal} {...(contentProps ?? {})} popper={popper} />
        {arrow && (
          <div id="arrow" ref={arrowRef} className={cx('w-5 h-5 top-[-9px] z-1', arrowClassName)}>
            <div id="arrow-inner" />
          </div>
        )}
      </div>
    </>
  );
};
// #endregion

export const Dropdown: FC<{
  onClick?: (MouseEvent: MouseEvent) => void;
  onClose?: () => void;
  onChange?: (status: boolean) => void;
  content?: ReactNode;
  placement?: Placement;
  visible?: boolean;
  className?: string;
  disable?: boolean;
}> = ({
  children,
  className,
  visible = false,
  onClose,
  onChange,
  content,
  onClick,
  placement = 'bottom',
  disable = false,
}) => {
  const windowSize = useDebounceElementSize(document.body);
  const [open, setOpen] = useState(visible);
  const { x, y, reference, floating, strategy, context, refs, update } = useFloating({
    open,
    middleware: [flip(), shift({ crossAxis: true })],
    onOpenChange: setOpen,
    placement,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, {
      toggle: true,
    }),
  ]);

  useEffect(() => {
    if (open && refs.reference.current && refs.floating.current) {
      autoUpdate(refs.reference.current, refs.floating.current, update);
    }
  }, [open, update, refs.reference, refs.floating]);

  useEffect(() => {
    setTimeout(() => {
      if (open && refs.reference.current && refs.floating.current) {
        autoUpdate(refs.reference.current, refs.floating.current, update);
      }
    }, 100);
  }, [open, refs.floating, refs.reference, update, windowSize]);

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  useEffect(() => {
    !open && onClose?.();
  }, [onClose, open]);

  useEffect(() => {
    onChange?.(open);
  }, [onChange, open]);

  return (
    <>
      <div
        ref={reference}
        {...getReferenceProps({ ref: reference })}
        className={className}
        onClick={disable ? undefined : () => setOpen(!open)}
      >
        {children}
      </div>
      {open &&
        !disable &&
        ReactDOM.createPortal(
          <FloatingOverlay
            lockScroll
            className={'z-[8848] animate__animated animate__fadeIn'}
            style={{ animationDuration: '0.1s' }}
            onClick={() => setOpen(false)}
          >
            <div
              ref={floating}
              {...getFloatingProps({
                ref: floating,
                style: {
                  position: strategy,
                  top: y ?? '',
                  left: x ?? '',
                },
                onClick: (e) => {
                  e.stopPropagation();
                  onClick?.(e);
                },
              })}
            >
              {content}
            </div>
          </FloatingOverlay>,
          document.body
        )}
    </>
  );
};
