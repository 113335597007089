import type { FC } from 'react';
// import { createContext } from 'use-context-selector';

// interface FlexibleInteractionContextValue {
//   setInteractive: (interactive: boolean) => void;
// }

interface FlexibleInteractionProviderProps {
  defaultWait?: number;
  waitMode?: 'throttle' | 'debounce';
  extendsStore?: boolean;
  costumerWaitMode?: 'throttle' | 'debounce';
  costumerWait?: number;
}

// export const FlexibleInteractionContext = createContext<FlexibleInteractionContextValue>({
//   setInteractive: () => {},
// });

export const FlexibleInteractionProvider: FC<FlexibleInteractionProviderProps> = (props) => {
  // const {
  //   children,
  //   defaultWait = 0,
  //   waitMode = 'throttle',
  //   extendsStore,
  //   costumerWaitMode = 'debounce',
  //   costumerWait = 1000,
  // } = props;
  // const [interactive, setInteractive] = useState(true);

  // const { wait, mode } = useMemo(() => {
  //   if (interactive) {
  //     return { wait: defaultWait, mode: waitMode } as const;
  //   }
  //   return { wait: costumerWait, mode: costumerWaitMode } as const;
  // }, [costumerWait, costumerWaitMode, defaultWait, interactive, waitMode]);

  return (
    // <FlexibleInteractionContext.Provider value={{ setInteractive }}>
    // <StoreContextProvider wait={wait} waitMode={mode} extendsStore={extendsStore}>
    <>{props.children}</>
    // </StoreContextProvider>
    // </FlexibleInteractionContext.Provider>
  );
};

// export const useSetFlexibleView = (defaultRef?: RefObject<HTMLDivElement | null>) => {
//   const ref = useRef<HTMLDivElement>(defaultRef?.current || null);
//   const setInteractive = useContextSelector(
//     FlexibleInteractionContext,
//     (context) => context.setInteractive
//   );

//   useLayoutEffect(() => {
//     const dom = ref.current;
//     if (!dom) return;
//     const unobserve = observe(dom, setInteractive);
//     return unobserve;
//   }, [setInteractive]);

//   return { ref };
// };

// export const useSetFlexibleInteraction = (defaultRef?: RefObject<HTMLDivElement | null>) => {
//   const ref = useRef<HTMLDivElement>(defaultRef?.current || null);
//   const setInteractive = useContextSelector(
//     FlexibleInteractionContext,
//     (context) => context.setInteractive
//   );

//   useLayoutEffect(() => {
//     const dom = ref.current || defaultRef?.current;
//     if (!dom) return;
//     const enter = () => setInteractive(true);
//     const leave = () => setInteractive(false);
//     dom.addEventListener('mouseenter', enter);
//     dom.addEventListener('mouseleave', leave);

//     return () => {
//       dom.removeEventListener('mouseenter', enter);
//       dom.removeEventListener('mouseleave', leave);
//     };
//   }, [defaultRef, setInteractive]);

//   return { ref };
// };
