import { cx } from '@flowus/common/cx';

interface Props<T> {
  values: T[];
  onSwicth: (value: T) => void;
  active: T;
  onlyOne?: T;
}
/** 支持n个选项 */
export function MultiSwitchText<T extends { desc: string }>(props: Props<T>) {
  const { values, onlyOne } = props;
  if (values.length < 2) {
    // what r u kidding?
    return null;
  }
  return (
    <div className="flex">
      {onlyOne ? (
        <div
          className={cx(
            'text-white cursor-pointer text-t3  border px-2 py-1 rounded-md border-active_color bg-active_color'
          )}
        >
          {onlyOne.desc}
        </div>
      ) : (
        values.map((v, index) => {
          return (
            <div
              key={index}
              className={cx('cursor-pointer text-t3  border-t border-b px-2 py-1', {
                'text-white bg-active_color border-active_color': props.active.desc === v.desc,
                'border-l rounded-l-md': index === 0,
                'border-r rounded-r-md': index === values.length - 1,
              })}
              onClick={() => {
                props.onSwicth(v);
              }}
            >
              {v.desc}
            </div>
          );
        })
      )}
    </div>
  );
}
