import type { FC } from 'react';
import React, { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';

import { annotationFilter } from './annotation-filter';

interface ColorSelectProps {
  setFilterColor: React.Dispatch<React.SetStateAction<string | undefined>>;
  filterColor?: string;
  colorOptions: { name: string; value: string }[];
}

export const SelectColor: FC<ColorSelectProps> = ({
  filterColor,
  setFilterColor,
  colorOptions,
}) => {
  const [color, setColor] = useState(filterColor);

  const items = colorOptions.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        value: item.value,
        icon: (
          <span
            className="rounded-full w-[18px] h-[18px]"
            style={{ backgroundColor: item.value }}
          />
        ),
        arrow: <Icon name="IcCheck02" size="normal" />,
        hasArrow: color === item.value,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    setColor(data.value);
    annotationFilter.filter = data.value;
    setFilterColor(data.value);
  };

  return (
    <ListView className="next-modal w-[160px] py-[5px]" items={items} onItemClick={handleClick} />
  );
};
