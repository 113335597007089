import { convertToData } from '@flowus/chart/flowus-chart-util';
import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux/es/hooks/useStore';
import { useBlock } from 'src/hooks/block/use-block';
import { getTablePropertyValueForChart } from 'src/hooks/collection-view/get-property-value';
import { useGetSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import type { NextBlock, RootState } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { usePickBlock } from 'src/utils/pick-block';
import type { EChartsOption } from 'echarts';
import { useDebounceElementSize } from '@flowus/common/hooks';
import { getBlockTextColor } from '@flowus/common/block/color/get-block-color';
import { useNumberChartColor, useNumberFormat, useNumberFormatCount } from 'src/hooks/chart';
import { formatNumber } from 'src/bitable/table-view/cell/num';
import { ChartTitle } from 'src/editor/component/chart/chart-title';
import { useTryFetchChartPerson } from 'src/hooks/chart/use-try-fetch-chart-person';
interface Props {
  uuid: string;
}
/** 只显示数子的chart */
export const NumberChart: FC<Props> = (props) => {
  const store = useStore();
  const block = usePickBlock(props.uuid, ['data'], ['format', 'segments']);
  const collection = useBlock(block?.data.format?.chartFormat?.ref?.uuid);
  const format = block?.data.format?.chartFormat;
  const divRef = useRef<HTMLDivElement>(null);
  const colorKey = useNumberChartColor(props.uuid);
  const textColor = getBlockTextColor(colorKey);
  const numberFormat = useNumberFormat(props.uuid);
  const numberFormatCount = useNumberFormatCount(props.uuid);
  const [echartsOption, setEchartsOptions] = useState<EChartsOption>();
  const { width, height } = useDebounceElementSize(divRef.current, {
    type: 'all',
  });
  const tryFetchChartPerson = useTryFetchChartPerson();
  const [fetchPeople, setFetchPeople] = useState(false);

  let { sortedRecordIds } = useGetSortedRecordIds(
    format?.ref?.viewId ?? '',
    [collection?.uuid],
    false
  );
  if (!format?.ref?.viewId) {
    sortedRecordIds = collection?.subNodes ?? [];
  }

  useEffect(() => {
    if (collection?.uuid && sortedRecordIds) {
      void tryFetchChartPerson({
        collectionId: collection?.uuid,
        viewId: format?.ref?.viewId,
        recordIds: collection?.subNodes,
        store,
        callback: () => {
          setFetchPeople(true);
        },
      });
    }
  }, [
    collection?.uuid,
    format?.ref?.viewId,
    store,
    tryFetchChartPerson,
    collection?.subNodes,
    sortedRecordIds,
  ]);

  const records = useObservableStore(
    (state) => {
      const { blocks } = state;
      return sortedRecordIds
        .map((id) => {
          return blocks[id];
        })
        .filter((v) => v) as NextBlock[];
    },
    [props.uuid, sortedRecordIds]
  );

  useEffect(() => {
    if (!format) return;
    const options = convertToData(
      collection as BlockDTO,
      records as BlockDTO[],
      format,
      (recordId, propertyId, groupLevel) => {
        return getTablePropertyValueForChart(
          recordId,
          propertyId,
          groupLevel,
          store.getState() as RootState
        );
      }
    );
    options && setEchartsOptions(options);
  }, [collection, format, records, store, fetchPeople]);

  let total = echartsOption?.total as number | undefined;
  if (typeof total === 'string') {
    total = parseFloat(total);
  }

  let renderNumber;
  if (numberFormat === 'number') {
    renderNumber = total?.toFixed(Math.min(numberFormatCount, 100));
  } else {
    renderNumber = formatNumber(total ?? 0, numberFormat);
  }
  const scaleFontSize = computeFontSize(renderNumber, width, height);
  return (
    <div ref={divRef} className="w-full h-full flex flex-col justify-center">
      <div
        className="font-semibold w-full text-center flex justify-center items-center"
        style={{
          color: textColor,
          fontSize: scaleFontSize,
          lineHeight: scaleFontSize,
        }}
      >
        {/* 宽度没算出来就不要渲染了否则会算不出来 */}
        <div>{width > 0 ? renderNumber : undefined}</div>
      </div>
      <ChartTitle id={props.uuid} className="text-center text-grey4 text-t2 shrink-0" type="desc" />
    </div>
  );
};

/** 有可能数字比较大导致屏幕容不下，因此数字是动态算出来的 */
const computeFontSize = (
  count: string | undefined | number,
  containerWidth: number,
  containerHeight: number
) => {
  if (count === undefined) return;
  if (containerWidth === 0) {
    containerWidth = 600;
  }
  if (containerHeight <= 0) {
    containerHeight = 300;
  }
  const countLen = `${count}`.length;
  return `min(${(containerWidth / countLen) * 1.5}px,${containerHeight / 1.5}px)`;
};
