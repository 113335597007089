import { DaySetting } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useCurrentSpaceDaySetting } from 'src/hooks/space';
import { FlexibleInteractionProvider } from 'src/services/store-context/components';
import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { useBitable } from '../../context';
import { Week } from './body';
import { CalenderContext, useCalender } from './context';
import { Header } from './header';
import { getCalenderDates, getOneMonthDates } from './utils/compute-calendar-date';

export const CalenderWrapper: FC<{ embed: boolean }> = memo(({ embed }) => {
  const startDay = useCurrentSpaceDaySetting();
  const [calenderDates, setCalenderDates] = useState<number[]>(
    embed ? getOneMonthDates(startDay) : getCalenderDates(startDay)
  );
  const calenderDatesRef = useRef<number[]>([]);
  const container = useRef<HTMLDivElement | null>(null);
  const calenderHeader = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setCalenderDates(embed ? getOneMonthDates(startDay) : getCalenderDates(startDay));
  }, [embed, startDay]);

  const calenderContextData = useMemo(() => {
    return {
      embed,
      startDay,
      container,
      calenderDates,
      setCalenderDates,
      calenderDatesRef,
      calenderHeader,
    };
  }, [calenderDates, embed, startDay]);

  calenderDatesRef.current = calenderDates;

  return (
    <CalenderContext.Provider value={calenderContextData}>
      <Calender />
    </CalenderContext.Provider>
  );
});

export const Calender = () => {
  const { viewId } = useBitable();
  const { container, calenderDates, setCalenderDates, embed, startDay } = useCalender();
  // useSetFlexibleInteraction(container);
  const isAppendDates = useRef<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<number>(getDateTimeStamp(new Date().setDate(2)));
  const scroll = usePageScrollRef();

  /**
   * 在 collection page 滚动时更新当前月份值
   */
  useEffect(() => {
    if (embed) return;

    const containerNode = container.current?.closest('[data-page-id]');
    if (!containerNode) return;

    const handleScroll = () => {
      if (
        containerNode.scrollHeight < containerNode.scrollTop + containerNode.clientHeight + 200 &&
        !isAppendDates.current
      ) {
        isAppendDates.current = true;
        setCalenderDates((calenderDates) =>
          calenderDates.concat(
            getCalenderDates(
              startDay,
              'append',
              (calenderDates[calenderDates.length - 1] as number) + ONE_DAY
            )
          )
        );
      }
    };

    containerNode.addEventListener('scroll', handleScroll);
    return () => containerNode.addEventListener('scroll', handleScroll);
  }, [container, embed, setCalenderDates, startDay]);

  /**
   * collection page 初始只渲染了两个月的日期，如果屏幕很高，可能高度不够，这里做一次检测，如果高度不够则继续渲染
   */
  useEffect(() => {
    if (embed) return;
    isAppendDates.current = false;

    const containerNode = container.current?.closest('[data-page-id]');
    if (!containerNode) return;
    if (containerNode.scrollHeight <= containerNode.clientHeight) {
      setCalenderDates((calenderDates) =>
        calenderDates.concat(
          getCalenderDates(
            startDay,
            'append',
            (calenderDates[calenderDates.length - 1] as number) + ONE_DAY
          )
        )
      );
    }
  }, [calenderDates.length, container, embed, setCalenderDates, startDay]);

  const renderCalenderDate = useMemo(() => {
    const arr: { value: number; index: number }[] = [];
    calenderDates.forEach((value, index) => {
      if (index % 7 === 0) {
        arr.push({
          value,
          index,
        });
      }
    });
    return arr;
  }, [calenderDates]);

  return (
    <>
      <Header currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} />
      <div
        data-view-id={viewId}
        className="max-h-full w-full mx-auto flex items-center flex-col overflow-auto relative calendar-container"
        style={{
          background:
            startDay === DaySetting.MON
              ? 'linear-gradient(90deg, transparent 71.42857142857143%, var(--grey8) 71.42857142857143%)'
              : 'linear-gradient(90deg, var(--grey8) 14.285714285%, transparent 14.285714285%, transparent 85.714285714%, var(--grey8) 85.714285714%)',
        }}
        ref={container}
      >
        <FlexibleInteractionProvider
          defaultWait={200}
          waitMode="throttle"
          costumerWait={1000}
          costumerWaitMode="debounce"
        >
          <Virtuoso
            className="w-full"
            data={renderCalenderDate}
            customScrollParent={scroll.current as HTMLDivElement}
            defaultItemHeight={124}
            overscan={600}
            itemContent={(_, item) => (
              <Week
                key={item.value}
                dates={calenderDates.slice(item.index, item.index + 7)}
                setCurrentMonth={setCurrentMonth}
              />
            )}
          />
        </FlexibleInteractionProvider>
      </div>
    </>
  );
};
