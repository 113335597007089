import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { EnhanceImage } from 'src/common/components/enhance-image';
import { Icon } from 'src/common/components/icon';

export interface IconImageProps {
  trigger: boolean;
  emojiSize?: number;
  hasOverlay?: boolean;
  className?: string;
  src: string;
  onClick?: (e: MouseEvent) => void;
}

export const IconImage: FC<IconImageProps> = (props) => {
  const { trigger, emojiSize, hasOverlay, className, onClick, src } = props;
  return (
    <span
      className={cx(
        'items-center justify-center relative inline-flex align-sub flex-shrink-0',
        { 'rounded animate-hover': trigger },
        className,
        hasOverlay && 'p-0'
      )}
      style={{ fontSize: emojiSize }}
    >
      <EnhanceImage
        onClick={onClick}
        className={cx('object-cover object-center rounded-sm', { 'cursor-pointer ': trigger })}
        style={{ height: '1em', width: '1em' }}
        src={src}
      />
      {hasOverlay && (
        <Icon
          size="normal"
          className="absolute right-[-1px] bottom-[0]"
          name="MIcBadgePagelink"
          style={{ fontSize: '0.65em', color: 'black' }}
        />
      )}
    </span>
  );
};
