import type { IEditorModel } from '@next-space/fe-inlined';
import { useCallback, useContext } from 'react';
import type { InlinePlugin } from '../../inline/inline-plugin';
import { useSyncId } from '../../plugin/sync-block-context';
import { EditableNamespace } from '../editable-context';
import { _EditorModelMap } from '../editable-models';
import { makeEditorKey, makeEditorModelConfig, setEditorModel } from './helper';
import type { DEFAULT_PLUGINS as DEFAULT_PLUGINS0 } from './plugins';

let DEFAULT_PLUGINS: typeof DEFAULT_PLUGINS0;

export const antiCycleSet_DEFAULT_PLUGINS = (DEFAULT_PLUGINS0: typeof DEFAULT_PLUGINS) => {
  DEFAULT_PLUGINS = DEFAULT_PLUGINS0;
};

export const newEditorModelWithoutRegister = (
  plugins: InlinePlugin[] = DEFAULT_PLUGINS,
  editorKey?: string
) => {
  // 由于editorModel不支持设置插件，要支持插件可配置的话，只能每次都configure一个新的EditorModel类型出来
  const EditorModel = makeEditorModelConfig(plugins);
  const model = new EditorModel(editorKey);
  for (const plugin of plugins) {
    plugin.postInit?.(model);
  }
  return model;
};

export const useGetOrInitEditorModel = () => {
  const syncId = useSyncId();
  const namespace = useContext(EditableNamespace);
  function _getOrInitEditorModel<T extends boolean>(
    uuid: string,
    autoCreate?: T,
    plugins = DEFAULT_PLUGINS
  ): T extends true ? IEditorModel : IEditorModel | undefined {
    const editorKey = makeEditorKey(uuid, namespace, syncId);
    return getOrInitEditorModel(editorKey, !!autoCreate, plugins) as any;
  }
  return useCallback(_getOrInitEditorModel, [namespace, syncId]);
};

const getOrInitEditorModel = (editorKey: string, autoCreate: boolean, plugins: InlinePlugin[]) => {
  let model = _EditorModelMap.get(editorKey);
  if (!model && autoCreate) {
    model = newEditorModelWithoutRegister(plugins, editorKey);
    setEditorModel(editorKey, model);
  }
  return model;
};
