import type { ErrorHandler, RequestFactory } from '@next-space/fe-api-idl';
import { createRequest } from '@next-space/fe-api-idl';
import type { AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios';
import Axios from 'axios';
import { message } from '../components/message';

const PROXY_TARGET = (() => {
  try {
    return (
      process.env.PROXY_TARGET ||
      process.env.NEXT_PUBLIC_PROXY_TARGET ||
      // @ts-ignore env
      import.meta.env.PROXY_TARGET
    );
  } catch {
    return '';
  }
})();

export const createApiRequest = (options: {
  onError: ErrorHandler;
  onRequest?: (res: InternalAxiosRequestConfig<any>) => any;
  onResponse?: (res: AxiosResponse<any, any>) => any;
  config?: CreateAxiosDefaults;
}) => {
  const axios = Axios.create({
    validateStatus() {
      return true;
    },
    ...options.config,
  });

  axios.interceptors.request.use((config) => {
    let { url = '' } = config;
    const { method, params } = config;
    if (method === 'get' && params) {
      url += '?';
      Object.keys(params).forEach((k) => {
        const v = params[k];
        if (v !== undefined) {
          url += `${k}=${encodeURIComponent(v)}&`;
        }
      });
      config.url = url.slice(0, url.length - 1);
      config.params = undefined;
    }
    return options.onRequest?.(config) || config;
  });

  axios.interceptors.response.use((res) => {
    return options.onResponse?.(res) || res;
  });

  const factory: RequestFactory = async (options) => {
    const { method, path, headers, query, formData, body } = options;
    const url = path.replace(/[:]/g, '_');

    let data = body;
    if (formData) {
      data = new FormData();
      Object.entries(data).forEach(([k, v]) => {
        // undefined or null
        if (v != null) {
          data.append(k, v);
        }
      });
    }
    try {
      const res = await axios({
        method,
        url,
        params: query,
        data,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });

      if (res.data.code !== 200) {
        // eslint-disable-next-line no-console
        console.error(res.data);
      }

      return {
        url,
        ok: true,
        status: res.status,
        statusText: res.statusText,
        body: res.data,
      };
    } catch {
      return {
        url,
        ok: true,
        status: 500,
        statusText: '500',
        body: {
          code: 500,
          msg: 'network error',
        },
      };
    }
  };

  return {
    api: createRequest(factory, options.onError),
    axios,
  };
};

const { api: API, axios: apiAxios } = createApiRequest({
  onError: (res) => {
    /**
     * 通过 {API}.raw() 调用的方法不会触发 onError
     */

    if (res.code === 502) {
      return;
    }

    const _errMsg = res.msg || (res as any).message;
    const msg: string = String(_errMsg).toLocaleLowerCase();

    try {
      if (_errMsg) {
        if (!/内部错误|超时|server error|timeout/.test(msg)) {
          message.error({
            key: 'msg',
            content: _errMsg,
          });
        }
      }

      // 报错后 5 秒关闭所有 message。以免出现卡 loading 的情况
      // 先获取是因为避免关闭后来新弹出的 message
      const keys = message.getAll();
      setTimeout(() => {
        keys.forEach(message.closeMessage);
      }, 5 * 1000);
    } catch {
      // eslint-disable-next-line no-console
      console.error(msg);
    }
  },

  config: {
    baseURL: PROXY_TARGET ? `${PROXY_TARGET}/api` : `/api`,
    withCredentials: true,
  },
});

export type ApiType = typeof API;

export { API, apiAxios };
