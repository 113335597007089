import { createContext, useContext } from 'react';

interface Relation {
  child2ParentMap?: Map<string, string[]>;
  parent2ChildMap?: Map<string, string[]>;
  filteredRecordIdSet?: Set<string>;
}

export const RelationContext = createContext<Relation>({
  child2ParentMap: new Map(),
  parent2ChildMap: new Map(),
  filteredRecordIdSet: new Set(),
});

export const useRelationMap = () => {
  return useContext(RelationContext);
};
