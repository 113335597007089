import { getBoardGroupTextColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { bizTracker } from 'src/utils/biz-tracker';
import { DEFAULT_ROW_HEIGHT } from './const';
import { useBitable } from './context';
import { RecordCreatedFrom } from './table-view/types';

interface Props {
  where?: {
    after?: string;
    before?: string;
  };
  groupName?: string;
  subGroupName?: string;
  color?: string;
}

const CreateSceneMap = {
  [CollectionViewType.TABLE]: 'table_bottom',
  [CollectionViewType.BOARD]: 'kanban_bottom',
  [CollectionViewType.GALLERY]: 'gallary',
  [CollectionViewType.TIMELINE]: 'timeline',
  [CollectionViewType.CALENDAR]: 'calendar',
  [CollectionViewType.FORM]: 'form',
  [CollectionViewType.LIST]: 'list',
  [CollectionViewType.SPACE]: '',
};

export const AddRecord: FC<Props> = (props) => {
  const { where, groupName, subGroupName, color } = props;
  const { viewId, viewType, readonly, managerReadonly } = useBitable();
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const isDragging = useIsDragging();

  if (readonly || managerReadonly) return null;
  if (!viewType) return null;

  const isBoard = viewType === CollectionViewType.BOARD;
  const isTable = viewType === CollectionViewType.TABLE;
  const isGallery = viewType === CollectionViewType.GALLERY;
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const isList = viewType === CollectionViewType.LIST;

  const createRecord = (event: MouseEvent) => {
    bizTracker.event('bitable_record_create', { from_scene: CreateSceneMap[viewType] });

    const propertyValues = getValuesFromGroupValue({
      viewId,
      groupValue: groupName,
      subGroupValue: subGroupName,
      isNewRecord: true,
    });

    void insertRecord({
      viewId,
      where,
      propertyValues,
      from: isTimeline ? RecordCreatedFrom.TIMELINE_TABLE : undefined,
    });

    (event.currentTarget as HTMLButtonElement).blur();
  };

  return (
    <button
      className={cx('relative text-grey4 space-x-1 flex w-full print:hidden', {
        'h-[36px] min-w-max flex-1 items-center border-b duration-150': isTable || isTimeline,
        'h-[38px] items-center rounded-md': isBoard,
        'min-h-[38px] items-center justify-center next-modal shadow-modal-sm': isGallery,
        'h-9 items-center rounded-md': isList,
        'animate-hover-black3 animate-click': !isDragging,
      })}
      onClick={createRecord}
      style={{ color: getBoardGroupTextColor(color) }}
    >
      {isTable && (
        <>
          <div
            className="sticky left-0 flex flex-shrink-0 items-center space-x-1"
            style={{ height: DEFAULT_ROW_HEIGHT - 1 }}
          >
            <Icon size="middle" name="IcAddBoard" />
            <span className="text-t2">创建记录</span>
          </div>
          <div className="w-12 flex-1" />
        </>
      )}

      {!isTable && (
        <>
          <Icon size="middle" name="IcAddBoard" />
          <span className="text-t2">创建记录</span>
        </>
      )}
    </button>
  );
};
