import { sleep } from '@flowus/common/async';
import { useEffect } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';
import { fetchSubscriptionData } from 'src/services/app/hook/subscription-data';
import { emitter } from '@flowus/common/utils/emitter';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { getCurrentSpaceId } from '../space/get-space';
import { SearchParams } from 'src/utils';
import { OpenDialogEnum } from '../space/use-open-dialog-from-location';

/** 接收emitter发送的消息，用于fn里需要唤起modal的需求 */
export const useListenerEmitterModal = () => {
  const openModal = useOpenModal();
  const openSettingModal = useOpenSettingModal();

  const openMaximumCapacityDialog = (spaceId = getCurrentSpaceId()) => {
    openModal.warning({
      modalId: Modals.MAXIMUM_CAPACITY_DIALOG,
      title: '试用块数已达上限',
      confirmText: '升级获得无限块',
      forceUpdate: true,
      maxWidth: 350,
      colorType: 'active',
      footer: (
        <div className="text-t3 text-grey3 mt-2.5">
          块数量每15分钟自动更新，你可以
          <span
            className="mx-0.5 animate-click underline underline-offset-2"
            onClick={async () => {
              message.loading({
                key: 'refreshCount',
                content: '正在刷新计数',
              });
              await fetchSubscriptionData(spaceId);
              message.closeMessage('refreshCount');
              message.success('刷新计数成功');
              openModal.closeModal(Modals.MAXIMUM_CAPACITY_DIALOG);
            }}
          >
            刷新计数
          </span>
          后重试
        </div>
      ),
      confirm: () => {
        if (spaceId !== getCurrentSpaceId()) {
          message.success('正在切换空间，稍等片刻...');
          // 切换空间再升级
          const params = new URLSearchParams();
          params.set(SearchParams.spaceId, spaceId);
          params.set(SearchParams.openDialog, OpenDialogEnum.openUpgradeSpace);
          window.location.href = `${window.location.origin}?${params.toString()}`;
          return;
        }
        openSettingModal({
          from: OpenSettingFrom.spaceSetBlock,
          defaultMenuType: SettingMenuType.upgrade,
        });
      },
    });
  };

  useEffect(() => {
    emitter.on('modalId', ({ modalId, spaceId }) => {
      // 弹的太快，可能会focus到button上，然后被同时监听回车事件的modal的confirm事件给触发了
      void sleep(100).then(() => {
        switch (modalId) {
          case Modals.MAXIMUM_CAPACITY_DIALOG:
            openMaximumCapacityDialog(spaceId);
            break;
          default:
            break;
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
