import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { useNormalizePath } from 'src/hooks/page/use-normalize-path';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';

interface Props {
  id: string;
  inline?: boolean;
  disable?: boolean; // 不响应点击
  className?: string;
}
/** 无法访问 */
export const NoAccess: FC<Props> = (props) => {
  const blockId = useObservableBlock(props.id, (block) => {
    return block?.data.ref?.uuid ?? props.id;
  });
  const path = useNormalizePath(blockId);
  const content = (
    <>
      <span className="relative " style={{ fontSize: '20px' }}>
        <Icon name="MIcPageUnable" size="middle" />
        <Icon
          size="normal"
          className="absolute right-[-1px] bottom-[0]"
          name="MIcBadgePagelink"
          style={{ fontSize: '0.65em', color: 'black' }}
        />
      </span>
      <span className="relative ml-1 w-full font-medium underline decoration-grey5 underline-offset-2">
        无法访问
      </span>
    </>
  );
  if (props.disable) {
    return (
      <span className={cx('px-0.5 items-center rounded p-1', props.className)}>{content}</span>
    );
  }
  return (
    <NavLink to={path} className="px-0.5 items-center rounded p-1 animate-hover cursor-pointer">
      {content}
    </NavLink>
  );
};
