import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React, { useRef } from 'react';
import { ResizeStatus, useResizeListener } from '@flowus/common/hooks/use-resize-listener';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { usePressedSpace } from 'src/services/app';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { NodeWrapper } from '../component/node-wrapper';
import { useMindNodePadding } from '../hook/use-mind-node-style';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import { getDefaultPlaceHolder } from '../utils/mind-node-util';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

/** 根节点，跟textNode几乎一样,除了按住节点可拖拽之外 */
export const RootNode: MindNodeElement = React.memo((props) => {
  const segments = useObservableBlock(props.id, (state) => state?.data.segments ?? []);
  const elementRef = useRef<HTMLDivElement>(null);
  const placeHolder = getDefaultPlaceHolder(props.level);
  const updateSegments = useThrottleUpdateSegments(props.id);
  const pageScrollRef = usePageScrollRef();
  const { paddingLeft, paddingRight } = useMindNodePadding(props.id, props.level);
  const cursor = useRef(pageScrollRef.current?.style.cursor);
  const isPressedSpace = usePressedSpace();
  useMonitorFocused(elementRef, props.id);
  useResizeListener(elementRef, {
    onResize: (result) => {
      if (!pageScrollRef.current || !elementRef.current) return;
      if (isPressedSpace) return;
      switch (result.resizeStatus) {
        case ResizeStatus.START:
          cursor.current = pageScrollRef.current?.style.cursor;
          if (document.activeElement) {
            const el = document.activeElement.closest(`[data-block-id='${props.id}']`);
            if (el) {
              return true;
            }
          }
          break;
        case ResizeStatus.RESIZING:
          elementRef.current.style.cursor = 'grabbing';
          pageScrollRef.current.scrollBy(-result.deltaX, -result.deltaY);
          break;
        case ResizeStatus.END: {
          // 还原回去
          elementRef.current.style.cursor = cursor.current ?? '';
          break;
        }
        default:
      }
    },
    dispatchEvent: true,
  });
  return (
    <NodeWrapper ref={elementRef} id={props.id} level={props.level} className="mind-map-root-node">
      <MindMapRichText
        placeholder={placeHolder}
        uuid={props.id}
        className="whitespace-pre-wrap break-words"
        segments={segments}
        onChange={(
          segments: SegmentDTO[],
          prevContent: IContent,
          prevSelection: ISelection | null
        ) => {
          updateSegments(segments, [prevContent, prevSelection]);
        }}
        style={{
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
        }}
      />
    </NodeWrapper>
  );
});
