import { type FC } from 'react';
import { XAxisField } from './x-axis-field';
import { YAxisField } from './y-axis-field';
import { GroupCombine } from './group-combine';

export const HorizontalAxisConfig: FC = () => {
  return (
    <div className="mt-4">
      {/* 横轴设置项 */}
      <XAxisField />
      {/* 纵轴设置项 */}
      <YAxisField />
      {/* 聚合 */}
      <GroupCombine />
    </div>
  );
};
