import { cx } from '@flowus/common/cx';
import type { PDFFormat } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { clamp } from 'lodash-es';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import 'pdfjs-dist/web/pdf_viewer.css';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { segmentsToText } from 'src/editor/utils/editor';
import { useUpdatePDFAnnotation } from 'src/hooks/block/use-update-PDFAnnotation';
import { useUpdatePDFFormat } from 'src/hooks/block/use-update-PDFFormat';
import { useReadonly } from 'src/hooks/page';
import { useUpload } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_REDO, HISTORY_UNDO } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { dispatch, getState } from 'src/redux/store';
import { $appUiStateCache, setAppUiState, useAiPDFSideBar } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { dataURItoBlob } from 'src/utils/data-url-to-blob';
import { emitter } from '@flowus/common/utils/emitter';
import { getFileNameInfo } from 'src/utils/file';
import { getVirtualElement } from 'src/utils/virtualElement';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { AISideBar } from './ai-sidebar';
import { AnnotationList } from './component/annotation-list';
import { PDFOutline } from './component/outline';
import { PasswordModal } from './component/password-modal';
import { AnnotationRightMenu } from './component/right-menu';
import { PDFHeader } from './header';
import { PDFViewApplication } from './service/pdf-viewer-application';
import { pdfPreviewCache } from './service/storage';
import './style.css';
import type { PDFAnnotation } from './type';
import {
  PDF_AI_SOURCE_COVER,
  AnnotationColor,
  AnnotationType,
  ScrollMode,
  SideBarType,
  SpreadMode,
} from './type';
import { isImageAnnotation } from './utils';
import { convertAnnotation } from './utils/convert-annotation';
import { decodeName } from '@flowus/common/utils';

export interface FilePreviewProps {
  downloadUrl: string;
  uuid?: string;
  recordId?: string;
  propertyId?: string;
  embed?: boolean;
  style?: React.CSSProperties;
}

enum LoadingStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export const PdfPreview: FC<FilePreviewProps> = ({
  uuid,
  recordId,
  propertyId,
  downloadUrl: fileUrl,
  embed,
}) => {
  const isBlobUrl = fileUrl.startsWith('blob:');
  const pdfViewer = useRef<PDFViewer>();
  const readonly = useReadonly(uuid);
  const scene = usePageScene();
  const pdfApplication = useRef<PDFViewApplication>();
  const updatePDFFormat = useUpdatePDFFormat();
  const passwordRef = useRef<string>();
  const transaction = useTransaction();
  const updatePDFAnnotation = useUpdatePDFAnnotation();

  const upload = useUpload();
  const viewerContainer = useRef<HTMLDivElement>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const thumbnailContainer = useRef<HTMLDivElement>(null);
  const sideBarRef = useRef<HTMLDivElement>(null);
  const moveBarRef = useRef<HTMLDivElement>(null);
  const aiMoveBarRef = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();
  const [loadStatus, setLoadStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);

  const $showPDFSidebar = useAiPDFSideBar();

  // useSyncAnnotation(pdfApplication, uuid);

  const pdfPreviewStatus = pdfPreviewCache.get(uuid ?? '') ?? {};
  const [sidebarType, setSidebarType] = useState(
    pdfPreviewStatus.sideBarType ?? SideBarType.THUMBNAIL_VIEW
  );

  useEffect(() => {
    if (!embed && uuid) {
      const { blocks } = getState();
      const block = blocks[uuid];
      if (!block) return;
      const text = segmentsToText(block?.data.segments);
      const { name, extName } = getFileNameInfo(text);
      document.title = `${name}.${extName}`;
    }
  }, [embed, uuid]);

  useEffect(() => {
    // emitter.on('pdfRenderPage', (data) => {
    //   const { source } = data;
    //   const { textLayerDiv, viewport } = source;
    //   if (!textLayerDiv || !viewport) return;
    //   const point1 = viewport.convertToViewportPoint(56.69292, 159.4032);
    //   const point2 = viewport.convertToViewportPoint(535.59992, 765.5412);
    //   const node = document.createElement('div');
    //   node.classList.add('pdf-ai-source');
    //   textLayerDiv.parentElement.appendChild(node);
    //   node.style.cssText = `
    //     position: absolute;
    //     left: ${point1[0]}px;
    //     top : ${point2[1]}px;
    //     width: ${point2[0] - point1[0]}px;
    //     height: ${point1[1] - point2[1]}px;
    //     border: 2px solid var(--active_color);
    //     background: rgba(24, 160, 251, 0.10);
    // `;
    // });
  }, [fileUrl]);

  useEffect(() => {
    if (sidebarType === SideBarType.THUMBNAIL_VIEW) {
      pdfViewer.current?.update();
    }
  }, [sidebarType]);

  useEffect(() => {
    if (
      !scrollContainer.current ||
      !thumbnailContainer.current ||
      !viewerContainer.current ||
      isBlobUrl
    ) {
      return;
    }

    setLoadStatus(LoadingStatus.LOADING);

    let pdfFormat: PDFFormat | undefined;
    if (uuid) {
      const block = getState().blocks[uuid];
      pdfFormat = block?.data.format?.pdfFormat;
    }

    const annotations: Record<string, PDFAnnotation> = {};
    if (uuid) {
      const { blocks } = getState();
      const block = blocks[uuid];
      passwordRef.current = pdfFormat?.password;
      block?.subNodes.forEach((uuid) => {
        const block = blocks[uuid];
        if (!block) return;

        const { pdfAnnotation } = block.data;
        if (!pdfAnnotation) return;

        const annotation = {
          ...pdfAnnotation,
          uuid,
        } as unknown as PDFAnnotation;

        annotations[uuid] = annotation;
      });
    }

    const application = (pdfApplication.current = new PDFViewApplication(
      fileUrl,
      scrollContainer.current,
      thumbnailContainer.current,
      viewerContainer.current,
      {
        color: AnnotationColor.YELLOW,
        annotations,
        annotationType: AnnotationType.NONE,
        showAISideBar: $showPDFSidebar,
      }
    ));

    application.onAIButtonClick = (page, text, rect) => {
      const pdfQuotes = $appUiStateCache.$pdfQuotes;

      let newRect: number[] = [];
      if (rect) {
        const { x, y, xMax, yMax } = rect;
        newRect = [x, yMax, xMax, y]; // 左上右下
      }
      if (!pdfQuotes.find((item) => item[0] === page && item[1] === text)) {
        setAppUiState({
          $pdfQuotes: [...pdfQuotes, [page, text, newRect]],
        });
      }
    };

    application.onPassword = async (updateCallback: any, reason: 1 | 2) => {
      openModal.modal({
        keyboard: false,
        closeBeforeCallBackForClickOutSide: () => {
          setLoadStatus(LoadingStatus.FAIL);
        },
        content: ({ onCloseModal }) => (
          <PasswordModal
            uuid={uuid}
            onCancel={() => {
              onCloseModal();
              setLoadStatus(LoadingStatus.FAIL);
            }}
            onPassword={(password, remainPassword) => {
              onCloseModal();
              updateCallback(password);
              if (remainPassword) {
                passwordRef.current = password;
              } else {
                passwordRef.current = undefined;
              }
            }}
            reason={reason}
          />
        ),
      });
    };

    application.onCopyPermission = () => {
      message.warning('用户设置了密码保护，无法复制！');
    };

    application.onPagesInit = () => {
      if (!application.pdfViewer) return;
      pdfViewer.current = application.pdfViewer;

      const { pdfViewer: viewer } = application;

      if (pdfFormat?.rotation) {
        viewer.pagesRotation = pdfFormat.rotation;
      }

      const annotationPos = new URLSearchParams(location.search).get('annotationPos');
      if (annotationPos) {
        const [page, x, y] = annotationPos.split(',');

        if (page) {
          let hash = `page=${page}&zoom=page-actual`;
          if (x && y) {
            hash += `,${x},${Number(y) + 10}`;
          }
          viewer.linkService.setHash(hash);
        } else {
          viewer.currentScaleValue = 'page-actual';
        }
      } else {
        const format = pdfPreviewCache.get(uuid ?? '');
        if (format) {
          viewer.scrollMode = format.scrollMode === ScrollMode.HORIZONTAL ? 1 : 0;
          viewer.spreadMode = format.spreadMode === SpreadMode.DOUBLE ? 1 : 0;
        }
        if (format?.lastViewPos) {
          viewer.linkService.setHash(format.lastViewPos);
        } else {
          viewer.currentScaleValue = 'page-actual';
        }
      }

      if (uuid && passwordRef.current && !readonly) {
        updatePDFFormat(uuid, { password: passwordRef.current });
      }

      viewer.container.addEventListener('mouseup', () => {
        const nodes = document.querySelectorAll(`.${PDF_AI_SOURCE_COVER}`);
        nodes.forEach((node) => node.remove());
      });

      setLoadStatus(LoadingStatus.SUCCESS);
    };

    application.onAddAnnotation = (annotation) => {
      if (uuid) {
        const pdfAnnotation = convertAnnotation(annotation);
        if (isImageAnnotation(annotation.type)) {
          bizTracker.event('highlight_shape', { file_id: uuid });

          if (!annotation.url) return;

          const blob = dataURItoBlob(annotation.url);
          if (!blob) return;

          void upload({
            file: new File([blob], '图片标注.png'),
            type: 'file',
            onComplete: (res) => {
              if (res.success) {
                transaction(() => {
                  pdfAnnotation.ossName = res.ossName;
                  pdfAnnotation.size = blob.size;

                  addBlock(
                    {
                      uuid: annotation.uuid,
                      type: BlockType.PDF_ANNOTATION,
                      data: { pdfAnnotation, localUrl: annotation.url },
                    },
                    { parentId: uuid, last: true }
                  );

                  const text = `{"annotationId": "${annotation.uuid}", "type": "imageBlock"}`;
                  void writeTextInClipboard(text);
                });
              }
            },
          });
        } else {
          bizTracker.event('highlight_text', { file_id: uuid });

          transaction(() => {
            addBlock(
              { uuid: annotation.uuid, type: BlockType.PDF_ANNOTATION, data: { pdfAnnotation } },
              { parentId: uuid, last: true }
            );
            const text = `{"annotationId": "${annotation.uuid}"}`;
            void writeTextInClipboard(text);
          });
        }
      }
    };

    application.onDeleteAnnotation = (newAnnotation) => {
      transaction(() => {
        archiveBlock(newAnnotation.uuid);
      });
    };

    application.onUpdateAnnotation = (newAnnotation) => {
      const pdfAnnotation = convertAnnotation(newAnnotation);
      if (isImageAnnotation(newAnnotation.type)) {
        if (!newAnnotation.url) return;

        const blob = dataURItoBlob(newAnnotation.url);
        if (!blob) return;

        void upload({
          file: new File([blob], '图片标注.png'),
          type: 'file',
          onComplete: (res) => {
            if (res.success) {
              pdfAnnotation.ossName = res.ossName;
              pdfAnnotation.size = blob.size;
              updatePDFAnnotation(newAnnotation.uuid, pdfAnnotation, newAnnotation.url);

              // const text = `{"annotationId": "${newAnnotation.uuid}", "type": "imageBlock"}`;
              // void writeTextInClipboard(text);
            }
          },
        });
      } else {
        updatePDFAnnotation(newAnnotation.uuid, pdfAnnotation);

        // const text = `{"annotationId": "${newAnnotation.uuid}"}`;
        // void writeTextInClipboard(text);
      }
    };

    application.onAnnotationContextMenu = (event: MouseEvent, annotation) => {
      if (!uuid) return null;

      event.preventDefault();
      event.stopPropagation();

      openModal.dropdown({
        popcorn: getVirtualElement(event),
        placement: 'right',
        content: ({ onCloseModal }) => {
          return (
            <AnnotationRightMenu
              pdfId={uuid}
              application={application}
              annotation={annotation}
              closeModal={onCloseModal}
            />
          );
        },
      });
    };

    void application.loadPDF(passwordRef.current);

    emitter.on('pdfCursor', (cursor) => {
      if (
        cursor === AnnotationType.RECTANGLE ||
        cursor === AnnotationType.ELLIPSE ||
        cursor === AnnotationType.POLYGON ||
        cursor === AnnotationType.ERASER ||
        cursor === AnnotationType.HIGHLIGHT ||
        cursor === AnnotationType.NONE
      ) {
        application.setAnnotationType(cursor);
      }
    });

    emitter.on('pdfCursorColor', (color) => {
      application.setAnnotationColor(color);
    });

    emitter.on('sidebarType', (sideBarType) => {
      if (!sideBarType) return;

      if (uuid) {
        const format = pdfPreviewCache.get(uuid ?? '') ?? {};
        pdfPreviewCache.put(uuid, { ...format, sideBarType });
      }

      setSidebarType(sideBarType);
    });

    const handleKeydown = (event: KeyboardEvent) => {
      if (isHotkey('mod+z')(event)) {
        dispatch(HISTORY_UNDO());
        application.undo();
      }

      if (isHotkey('mod+shift+z')(event)) {
        dispatch(HISTORY_REDO());
        application.redo();
      }
    };
    document.addEventListener('keydown', handleKeydown);

    const storeViewPos = () => {
      if (uuid && application.pdfViewer) {
        const search = application.pdfViewer._location?.pdfOpenParams.slice(1);

        if (search) {
          const format = pdfPreviewCache.get(uuid ?? '') ?? {};
          pdfPreviewCache.put(uuid, { ...format, lastViewPos: search });
        }
      }
    };
    window.addEventListener('beforeunload', storeViewPos);

    return () => {
      storeViewPos();
      setAppUiState({ $pdfAIContents: [], $pdfQuotes: [] });
      document.removeEventListener('keydown', handleKeydown, true);
      window.removeEventListener('beforeunload', storeViewPos);
      application.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl]);

  useEffect(() => {
    const moveBar = moveBarRef.current;
    const sideBar = sideBarRef.current;
    if (!moveBar || !sideBar) return;

    const handleMousedown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const handleMousemove = (event: MouseEvent) => {
        sideBar.style.width = `${clamp(event.clientX, 200, 300)}px`;
      };

      const handleMouseup = () => {
        document.removeEventListener('mousemove', handleMousemove);
        document.removeEventListener('mouseup', handleMouseup);

        moveBar.style.transform = 'none';
        if (uuid) {
          const format = pdfPreviewCache.get(uuid ?? '') ?? {};
          pdfPreviewCache.put(uuid, { ...format, sideBarWidth: sideBar.clientWidth });
        }
      };

      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);
    };

    moveBar.addEventListener('mousedown', handleMousedown);
  }, [uuid]);

  useEffect(() => {
    const moveBar = aiMoveBarRef.current;
    const container = scrollContainer.current;
    if (!moveBar && !container) return;

    const handleMousedown = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const handleMousemove = (event: MouseEvent) => {
        if (!moveBar || !container) return;

        const { parentElement } = moveBar;
        if (!parentElement) return;

        const newWidth = clamp(window.innerWidth - event.clientX, 250, 450);

        parentElement.style.width = `${newWidth}px`;
        container.style.width = `calc(100% - ${newWidth}px)`;
      };

      const handleMouseup = () => {
        document.removeEventListener('mousemove', handleMousemove);
        document.removeEventListener('mouseup', handleMouseup);
      };

      document.addEventListener('mousemove', handleMousemove);
      document.addEventListener('mouseup', handleMouseup);
    };

    moveBar?.addEventListener('mousedown', handleMousedown);
  }, [uuid]);

  const loading = loadStatus === LoadingStatus.LOADING;
  return (
    <div className="relative h-full w-full text-black flex flex-col">
      {pdfViewer.current && uuid && pdfApplication.current && (
        <PDFHeader
          uuid={uuid}
          application={pdfApplication.current}
          pdfViewer={pdfViewer.current}
          embed={embed}
          downloadUrl={isBlobUrl ? undefined : fileUrl}
        />
      )}

      <div className="relative flex overflow-auto border-t bg-grey7 flex-1">
        <div
          className={cx(
            'relative',
            (sidebarType === SideBarType.NONE ||
              scene === PageScene.PAGE_LITE_PREVIEW ||
              loading) &&
              'hidden'
          )}
          ref={sideBarRef}
          style={{ width: `${pdfPreviewCache.get(uuid ?? '')?.sideBarWidth ?? 220}px` }}
        >
          <div
            className={cx(
              'w-full h-full bg-grey8 overflow-auto py-2 ',
              sidebarType === SideBarType.THUMBNAIL_VIEW && 'hidden'
            )}
          >
            {pdfViewer.current && sidebarType === SideBarType.CATALOGUE && (
              <PDFOutline pdfViewer={pdfViewer.current} />
            )}
            {pdfViewer.current && sidebarType === SideBarType.ANNOTATION && uuid && (
              <AnnotationList pdfViewer={pdfViewer.current} uuid={uuid} />
            )}
          </div>

          <div
            id="thumbnailView"
            ref={thumbnailContainer}
            className={cx(
              'w-full h-full bg-grey8 overflow-auto relative py-4',
              sidebarType !== SideBarType.THUMBNAIL_VIEW && 'hidden'
            )}
          />

          <div
            className="absolute w-0.5 animate-hover cursor-col-resize right-0 top-0 h-full z-10"
            ref={moveBarRef}
          />
        </div>

        <div
          className={cx(
            'relative flex-1',
            !loading && sidebarType !== SideBarType.NONE && 'border-l'
          )}
        >
          <div
            ref={scrollContainer}
            className="absolute h-full overflow-auto py-2"
            style={{
              width: $showPDFSidebar ? `calc(100% - 360px)` : '100%',
            }}
          >
            <div ref={viewerContainer} className={cx('pdfViewer', embed && 'embedPreview')} />
          </div>

          <div
            className={cx(
              'absolute top-0 right-0 bottom-0 w-[360px] hidden',
              $showPDFSidebar && pdfApplication.current && 'block'
            )}
          >
            <div
              className="absolute w-0.5 left-0 animate-hover cursor-col-resize top-0 h-full z-10"
              ref={aiMoveBarRef}
            />
            {$showPDFSidebar && pdfApplication.current && !isBlobUrl && (
              <AISideBar
                uuid={uuid}
                recordId={recordId}
                propertyId={propertyId}
                ossName={decodeName(new URL(fileUrl).pathname.slice(1))}
                application={pdfApplication.current}
              />
            )}
          </div>
        </div>
      </div>

      {loadStatus === LoadingStatus.LOADING && (
        <div className="flex h-full w-full items-center justify-center absolute left-0 top-0">
          <LoadingIcon />
        </div>
      )}

      {loadStatus === LoadingStatus.FAIL && (
        <div className="flex h-full w-full items-center justify-center absolute left-0 top-0">
          预览失败
        </div>
      )}
    </div>
  );
};
