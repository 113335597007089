import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { ComponentProps, FC } from 'react';
import React, { createContext, useContext, useMemo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { LAST_USED_COLOR_INFO } from 'src/common/const';
import type { Direction } from 'src/common/hooks/use-control-select-list-element';
import { useControlSelectListElement } from 'src/common/hooks/use-control-select-list-element';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { setLocalStorage } from 'src/utils/local-storage';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { v4 as uuid } from 'uuid';

const DEFAULT_PER_LINE_COUNT = 5;
export interface ColorPickerProp {
  perLine?: number;
  items: CategoryItem[];
  /** 禁用最近使用颜色 */
  disabledHasRecently?: boolean;
  onColorSelect?: (color: ColorInfo) => void;
  footer?: React.ReactNode;
  showBg?: boolean;
}
export interface CategoryItem extends Pick<ColorPickerProp, 'onColorSelect'> {
  perLine?: number;
  title: string;
  items: ColorInfo[];
  rightText?: string;
}
export interface ColorInfo extends Pick<ComponentProps<'div'>, 'onClick'> {
  isBgColor?: boolean;
  desc: string;
  colorkey: string; // 这是传给后台用的key
}
export const ColorPicker: FC<ColorPickerProp> = (props) => {
  const { showBg = true } = props;
  const id = useRef(uuid());
  const divRef = useRef<HTMLDivElement>(null);
  const [hasRecently, itemsWithRecentlyUsed, totalSize] = useMemo(() => {
    // 加上 上次使用这个分类
    const colorInfo = localStorage.getItem(LAST_USED_COLOR_INFO);
    const items = props.items.slice(0);
    let totalSize = items.reduce((total, current) => {
      return total + current.items.length;
    }, 0);
    let hasRecently = false;
    if (colorInfo !== 'undefined' && colorInfo != null && items[0] && !props.disabledHasRecently) {
      const lastColorInfoCategory: CategoryItem = {
        perLine: items[0].perLine,
        title: '上次使用',
        items: [JSON.parse(colorInfo)],
        rightText: `${ShortcutSystemSymbol}+Shift+H`,
      };
      items.unshift(lastColorInfoCategory);
      totalSize += 1;
      hasRecently = true;
    }
    return [hasRecently, items, totalSize];
  }, [props.items, props.disabledHasRecently]);

  const findElement = (index: number) => {
    if (index < 0 || index >= totalSize) return null;
    let count = 0;
    for (const categoryItem of itemsWithRecentlyUsed) {
      for (let i = 0; i < categoryItem.items.length; i++) {
        if (index === count) {
          return divRef.current?.querySelector(
            `[data-list-item="${id.current}-${categoryItem.title}-${i}"]`
          );
        }
        count++;
      }
    }
    return null;
  };
  const findNextIndex = (currentIndex: number, direction: Direction) => {
    const perLine = props.perLine ?? DEFAULT_PER_LINE_COUNT;
    let nextIndex;
    if (direction === 'up' || direction === 'down') {
      // special case for color picker，这个特殊case需要结合页面展示
      if (currentIndex === 0 && direction === 'down' && hasRecently) {
        nextIndex = currentIndex + 1;
      } else if (currentIndex === 1 && direction === 'up' && hasRecently) {
        nextIndex = currentIndex - 1;
      } else {
        nextIndex = direction === 'up' ? currentIndex - perLine : currentIndex + perLine;
      }
    } else {
      nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    }
    if (nextIndex < 0) nextIndex = totalSize - 1;
    if (nextIndex >= totalSize) nextIndex = 0;
    return nextIndex;
  };
  const [selectedIndex] = useControlSelectListElement(
    totalSize,
    findElement,
    findNextIndex,
    PRIORITY_DIALOG + 1,
    { scrollContainer: divRef }
  );
  let selectCategoryIndex = -1;
  let selectCategoryItemIndex = -1;
  let count = 0;
  let found = false;
  // selectedIndex是整体items的index， 由于colorPicker是分类列表，因此还需要算出具体在哪个分类，分类里面的哪个位置才行
  itemsWithRecentlyUsed.forEach((categoryItem, index) => {
    if (found) return;
    selectCategoryIndex = index;
    for (let i = 0; i < categoryItem.items.length; i++) {
      if (selectedIndex === count) {
        found = true;
        selectCategoryItemIndex = i;
        break;
      }
      count++;
    }
  });
  return (
    <div
      ref={divRef}
      data-no-cancel-selected
      className={cx(showBg && 'next-modal', 'overflow-y-auto pt-1.5 max-h-[80vh] select-none')}
      onPointerDown={(e) => e.preventDefault()}
    >
      <SelectedCategoryItemContext.Provider value={selectCategoryItemIndex}>
        {itemsWithRecentlyUsed.map((item, index) => {
          return (
            <div className="mb-2.5" key={index}>
              <Category
                selected={selectCategoryIndex === index}
                id={id.current}
                {...item}
                perLine={props.perLine}
                onColorSelect={props.onColorSelect}
              />
            </div>
          );
        })}
      </SelectedCategoryItemContext.Provider>
      {props.footer}
    </div>
  );
};

const ITEM_WIDTH = 42.5;
const Category: FC<CategoryItem & { id: string; selected: boolean }> = (props) => {
  const { title, rightText, items, perLine = DEFAULT_PER_LINE_COUNT, id, selected } = props;
  const width = ITEM_WIDTH * perLine + 8 * 3;
  const selectedCategoryItemIndex = useSelectCategoryItemIndex(); // 这是选中的分类item index
  return (
    <div style={{ width }}>
      <div className="flex items-center justify-between text-t2 text-grey3 px-3.5 h-9">
        <div>{title}</div>
        <div>{rightText}</div>
      </div>
      <div className="flex flex-row flex-wrap justify-between px-2">
        {items.map((item, index) => {
          return (
            <ColorImage
              selected={selected && selectedCategoryItemIndex === index} // 只有分类index和分类item的index都对才算被选中
              data-list-item={`${id}-${title}-${index}`}
              key={index}
              {...item}
              onClick={() => {
                setLocalStorage(LAST_USED_COLOR_INFO, JSON.stringify(item));
                props.onColorSelect?.({ ...item });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export const ColorImage: FC<
  ColorInfo & {
    selected: boolean;
    icon?: React.ReactNode;
    hideIcon?: boolean;
    bgColor?: string;
    hover?: boolean;
    disable?: boolean;
  }
> = (props) => {
  const {
    desc,
    isBgColor,
    selected,
    hideIcon,
    icon,
    colorkey,
    bgColor,
    hover = true,
    disable = false,
    ...rest
  } = props;
  const isDefaultBgColor = !colorkey && isBgColor;

  const iconContent = useMemo(() => {
    if (isDefaultBgColor) {
      return <span className="w-full h-px bg-black_006 -rotate-45"></span>;
    }
    if (hideIcon) return null;
    if (isBgColor) {
      if (selected) {
        return <Icon name="IcCheck02" size="middle" className="text-white-base" />;
      }

      return null;
    }

    if (icon) return icon;

    return (
      <Icon
        size="normal"
        name="IcText"
        style={{ color: isBgColor || !colorkey ? undefined : getBlockTextColor(colorkey) }}
        className={cx('text-[18px]', !colorkey && 'text-black')}
      />
    );
  }, [colorkey, hideIcon, icon, isBgColor, isDefaultBgColor, selected]);

  return (
    <Tooltip offset={[0, 5]} popup={desc}>
      <div
        className={cx(
          'h-10 w-10 flex items-center justify-center rounded',
          { 'normal-bg': selected },
          { 'animate-hover ': hover },
          { 'cursor-pointer ': !disable }
        )}
        {...rest}
      >
        <div
          className={cx(
            'rounded-full flex items-center justify-center p-1 w-[30px] h-[30px]',
            isBgColor && 'p-0'
          )}
          style={{
            background: bgColor || (isBgColor ? getBlockBackgroundColor(colorkey) : undefined),
            boxShadow: 'inset 0 0 0 1px var(--black_006)',
          }}
        >
          {iconContent}
        </div>
        {/* {desc && <div className="text-t2 mt-[3px]">{desc}</div>} */}
      </div>
    </Tooltip>
  );
};
/** 为了方便透传，内部直接使用context，不加那么多参数在组件prop上 */
const SelectedCategoryItemContext = createContext(0);
const useSelectCategoryItemIndex = () => {
  return useContext(SelectedCategoryItemContext);
};
