import axios from 'axios';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { GalleryItem } from 'src/common/components/gallery/gallery-item';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useCloseModal } from 'src/common/components/next-modal';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import { request } from 'src/common/request';
import { ImageUploadPanel } from 'src/components/icon-trigger/image-upload-panel';
import { useUpload } from 'src/hooks/space/use-upload';
import { Modals } from 'src/modals';
import { InputExternalLink } from '../block-empty-view/input-external-link';

interface Props {
  selectImage: (args: {
    ossName?: string;
    type: 'upload' | 'click' | 'http';
    file?: File;
    url?: string;
    // unsplash 需要
    download?: string;
  }) => void;
  deleteCoverImage?: () => void;
  blockId: string;
}

interface ImageListType {
  groupName?: string;
  list?: {
    ossName: string;
    url: string;
    download?: string;
    html?: string;
    author?: string;
    description?: string;
    illegal?: boolean;
  }[];
}

const RECOMMENDS = ['study', 'team', 'work', 'tech'];

export const CoverImages = ({ blockId, selectImage, deleteCoverImage }: Props) => {
  const [imageList, setImageList] = useState<ImageListType[]>([]);
  const [unSplashList, setUnsplashList] = useState<ImageListType[]>([]);
  const upload = useUpload();
  const closeModal = useCloseModal();
  const [text, setText] = useState('');

  // 获取封面图列表
  useEffect(() => {
    void (async () => {
      const res = await request.editor.getCoverImageList();
      setImageList(res);
    })();
  }, []);

  const searchPics = useMemo(
    () =>
      debounce(async (search: string) => {
        const res = await request.infra.getExternalUrlList({ search, source: 'unsplash' });

        if (res) {
          setUnsplashList([
            {
              groupName: '图片来源于 Unsplash',
              list:
                res.results?.map((item) => ({
                  ossName: item.name ?? '',
                  author: item.name ?? '',
                  description: item.name ?? '',
                  illegal: false,
                  url: item.raw ?? '',
                  download: item.links?.download_location,
                  html: item.html ?? '',
                })) || [],
            },
          ]);
        }
      }, 200),
    []
  );

  useEffect(() => {
    void searchPics(text || (RECOMMENDS[Date.now() % 4] ?? ''));
  }, [searchPics, text]);

  // 点击图片
  const clickImage = (image: string) => selectImage({ ossName: image, type: 'click' });

  // 上传图
  const uploadImage = (file: File) => {
    message.loading({ key: 'uploadCover', content: '上传中' });
    closeModal(Modals.HEADER_COVER_IMAGE);
    void upload({
      file,
      key: blockId,
      searchType: 'cover',
      type: 'file',
      onComplete(ret) {
        if (ret.success) {
          selectImage({ ossName: ret.ossName, type: 'upload', file });
          message.success('上传完成');
          message.closeMessage('uploadCover');
        }
      },
    });
  };

  return (
    <Tab
      className="next-modal w-[534px] overflow-y-auto"
      customButtons={() => {
        return (
          deleteCoverImage && (
            <div
              className="animate-hover mr-2 flex cursor-pointer items-center rounded py-1.5 px-1"
              onClick={deleteCoverImage}
            >
              <Icon size="middle" name="MIcClear"></Icon>
              <div className="text-t2 text-grey3">清除</div>
            </div>
          )
        );
      }}
    >
      <TabPane title="图库">
        <div className="max-h-[35vh] overflow-y-auto px-3 py-1">
          {imageList.map((item) => (
            <div key={item.groupName}>
              <div className="text-grey3 text-t2 flex h-9 items-center">{item.groupName}</div>
              <div className="grid grid-cols-4 gap-2">
                {item.list?.map((img, index) => (
                  <GalleryItem
                    key={`${img.url}${index}`}
                    description={img.description}
                    illegal={img.illegal}
                    url={`${img.url}/resize,w_640/quality,q_80/`}
                    onClick={() => {
                      if (img.illegal) {
                        message.error('图片无法使用');
                      } else {
                        clickImage(img.ossName);
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </TabPane>
      <TabPane title="上传">
        <ImageUploadPanel
          tip="建议上传宽度大于 1500 像素的图片"
          onSelectImage={(imageInfo) => {
            selectImage({ ossName: imageInfo.ossName ?? '', type: 'click' });
          }}
          fileType="cover"
          onUploadFile={uploadImage}
          style={{
            height: '35vh',
          }}
        />
      </TabPane>
      <TabPane title="链接">
        <InputExternalLink
          placeholder="输入图片链接"
          onInputUrl={(value) =>
            selectImage({
              type: 'http',
              url: value,
            })
          }
        />
      </TabPane>
      {!__PRIVATE__ && (
        <TabPane icon="IcUnsplash" title="Unsplash">
          <div className="max-h-[35vh] overflow-y-auto px-3 py-1">
            <div className="bg-grey8 text-t2 border-grey6 my-4 flex w-full items-center rounded-sm border">
              <input
                spellCheck={false}
                placeholder="搜索图片..."
                className="text-t2 h-7.5 placeholder-grey4 w-full flex-grow cursor-text bg-transparent px-2 align-middle text-black outline-none"
                value={text}
                onChange={(e) => {
                  const text = e.target.value.trim();
                  setText(text);
                }}
              />
            </div>

            {unSplashList.map((item) => (
              <div key={item.groupName}>
                <div className="text-grey3 text-t2 flex h-9 items-center">{item.groupName}</div>
                <div className="grid grid-cols-4 gap-2">
                  {item.list?.length !== 0 ? (
                    item.list?.map((img, index) => (
                      <GalleryItem
                        key={`${img.url}${index}`}
                        description={img.description ?? ''}
                        node={
                          <a
                            href={img.html}
                            target="_blank"
                            className="text-ellipsis text-t4 text-grey3 mt-0.5 animate-hover px-1"
                            rel="noreferrer"
                          >{`by: ${img.author}`}</a>
                        }
                        url={`${img.url}&w=200&q=80`}
                        onClick={() => {
                          if (img.illegal) {
                            message.error('图片无法使用');
                          } else {
                            selectImage({ type: 'http', url: `${img.url}&q=80&w=1920` });
                            if (img.download) {
                              // https://help.unsplash.com/en/articles/2511258-guideline-triggering-a-download
                              void axios(img.download);
                            }
                          }
                        }}
                      />
                    ))
                  ) : (
                    <div className="text-grey2 my-2">无结果</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </TabPane>
      )}
    </Tab>
  );
};
