import type { CaptchaSendType } from '@next-space/fe-api-idl';

import { loginInjector } from '../utils/init';

export enum SmsCode {}

interface VerifySmsCodeParams {
  account: string;
  isEmail?: boolean;
  code: string;
  onError?: (err: string) => void;
  type: CaptchaSendType;
}
export const useVerifySmsCode = () => {
  return async (params: VerifySmsCodeParams) => {
    const { account, isEmail, code, onError, type } = params;

    const res = await (!isEmail
      ? loginInjector.request.infra.phoneAuthorization.raw({
          phone: account,
          captcha: code,
          type,
        })
      : loginInjector.request.infra.emailAuthorization.raw({
          email: account,
          captcha: code,
          type,
        }));

    if (res.code === 200) {
      return res.data;
    }
    onError?.(res.msg);
  };
};
