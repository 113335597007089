import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { VisibleRender } from 'src/common/components/visible-render';
import { IconLink } from '../icon-link';

interface Props {
  uuid: string;
}

export const BackLinkGroup: FC<Props> = ({ uuid, children }) => {
  const [fold, setFold] = useState(false);

  return (
    <VisibleRender rootMargin="40px 0px" className="mb-2">
      <div className="flex items-center py-0.5">
        <Icon
          name="IcToggleBlackUnfold"
          size="middle"
          className={cx('animate-hover', fold && '-rotate-90')}
          onClick={() => {
            setFold((pre) => !pre);
          }}
        />

        <IconLink canClick uuid={uuid} />
      </div>

      {!fold && children}
    </VisibleRender>
  );
};
