import { useAlreadyLayout, useMindMapEngine } from '@flowus/mind-map';
import { useUnmount } from 'ahooks';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useSizeCallback } from 'src/common/utils/use-size';
import { useLocationBlock } from 'src/hooks/block/use-location-block';
import { useReadonly } from 'src/hooks/page';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AsideToolbar } from './component/aside-toolbar';
import { BottomToolBar } from './component/bottom-toolbar';
import { selectCount } from './component/collapse-count';
import { CollapseSelector } from './component/collapse-selector';
import { HiddenTextarea } from './component/hidden-textarea';
import { MindMapContainer } from './component/mind-map-container';
import { DragRenderer } from './drag-renderer';
import { FixWidthContextProvider } from './fix-width-context-provider';
import { useAlignSiblingNode } from './hook/use-align-sibling-node';
import { useMindMapMouseDown } from './hook/use-mind-map-mouse-down';
import { useMindMapStyle } from './hook/use-mind-map-style';
import { useSmoothScroll } from './hook/use-smooth-scroll';
import { LineRenderer } from './line-renderer';
import { MindNodeRenderer } from './mind-node-renderer';
import { deleteMindMapEngine, putMindMapEngine } from './utils/mind-engine-utils';
import { Selector } from './utils/selector';
import { PageScene, usePageScene } from 'src/views/main/scene-context';

interface Props {
  id: string;
}
/** 导图编辑器 */
export const MindMapEditor: FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pageScrollRef = usePageScrollRef();
  const { mindMapStyle, mindMapLineStyle } = useMindMapStyle(props.id);
  const engine = useMindMapEngine();
  const readonly = useReadonly(props.id);
  const alignSiblingNode = useAlignSiblingNode(props.id);
  useSmoothScroll(pageScrollRef, containerRef);
  useMindMapMouseDown(pageScrollRef);
  const locationBlock = useLocationBlock({ container: pageScrollRef });
  const pageScene = usePageScene();

  const alreadyLayout = useAlreadyLayout();

  useSizeCallback(
    pageScrollRef.current,
    (ret) => {
      const [oldWidth, oldHeight] = engine.getSize();
      if (ret.width && ret.height) {
        engine.setSize(ret.width, ret.height);
        if (oldWidth && oldHeight) {
          const diffWidth = Math.floor(ret.width - oldWidth);
          const diffHeight = Math.floor(ret.height - oldHeight);
          // 宽高改变后需要移动对应的位置，保持ui看上去没变动
          pageScrollRef.current?.scrollBy({ left: diffWidth, top: diffHeight });
        }
      }
    },
    'all'
  );

  useEffect(() => {
    // 由于要等节点渲染完后才能计算宽高，这里delay一下
    setTimeout(
      () => {
        engine.setAlignSiblingNode(alignSiblingNode);
      },
      alignSiblingNode ? 300 : 0
    );
  }, [alignSiblingNode, engine]);

  useEffect(() => {
    engine.setRootId(props.id);
    engine.setDirection(mindMapStyle, true);
    engine.setLineStyle(mindMapLineStyle);
  }, [engine, mindMapLineStyle, mindMapStyle, props.id]);

  useEffect(() => {
    if (!pageScrollRef.current) {
      return;
    }
    if (!containerRef.current) {
      return;
    }
    const rect = pageScrollRef.current?.getBoundingClientRect();
    if (rect) {
      engine.setSize(rect.width, rect.height);
    }
    engine.setContainer(containerRef.current);
    engine.setScroller(pageScrollRef.current);
  }, [engine, pageScrollRef]);

  useUnmount(() => {
    engine.destroy();
  });

  useEffect(() => {
    putMindMapEngine(props.id, engine);
    return () => {
      deleteMindMapEngine(props.id);
    };
  }, [engine, props.id]);

  useEffect(() => {
    if (PageScene.MAIN !== pageScene) return;
    const count = selectCount(cache, props.id, props.id);
    // 如果节点超过150就先默认全部折叠，后面再考虑优化
    if (count > 150) {
      const b = cache.blocks[props.id];
      if (b?.subNodes) {
        patchExpand(b.subNodes, false, false);
      }
      locationBlock();
    }
  }, [props.id, locationBlock, pageScene]);
  return (
    <>
      <MindMapContainer ref={containerRef} id={props.id}>
        <HiddenTextarea container={containerRef} />
        <LineRenderer />
        <MindNodeRenderer id={props.id} level={0} />
        {alignSiblingNode && (
          <FixWidthContextProvider>
            <MindNodeRenderer id={props.id} level={0} />
          </FixWidthContextProvider>
        )}
        <DragRenderer />
      </MindMapContainer>
      <AsideToolbar id={props.id} />
      <BottomToolBar id={props.id} />
      <CollapseSelector id={props.id} />
      {!alreadyLayout && <LoadingContainer className="absolute left-0 top-0" />}
      {!readonly && <Selector />}
    </>
  );
};
