import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { darken } from 'src/utils/color';
import { usePickBlock } from 'src/utils/pick-block';

export const ColorIcon: FC<{ blockId: string }> = (props) => {
  const { blockId } = props;
  const block = usePickBlock(blockId, ['textColor', 'backgroundColor']);
  const textColor = getBlockTextColor(block?.textColor);
  const backgroundColor = getBlockBackgroundColor(block?.backgroundColor);
  const borderColor = textColor || backgroundColor;
  return (
    <div
      className="rounded-full w-6 h-6 flex items-center justify-center"
      style={{
        color: textColor,
        backgroundColor,
        borderWidth: '1px',
        borderColor: borderColor
          ? borderColor.startsWith('linear-gradient')
            ? undefined
            : darken(borderColor, 0.1)
          : undefined,
      }}
    >
      <Icon name={'IcText'} size="small" />
    </div>
  );
};
