import { MindMappingLineStyle, MindMappingType } from '@next-space/fe-api-idl';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';

export const useMindMapStyle = (mindMapId: string) => {
  return useObservableBlock(mindMapId, (state) => {
    const format = state?.data.format?.mindMappingFormat;
    const mindMapStyle = format?.type ?? MindMappingType.LOGIC_DIAGRAM_LEFT;
    const mindMapLineStyle = format?.lineStyle ?? MindMappingLineStyle.NORMAL;
    return { mindMapStyle, mindMapLineStyle };
  });
};
