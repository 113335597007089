import type { FC } from 'react';
import { message } from 'src/common/components/message';
import { publicLink } from 'src/common/const';
import { HelperIconBox } from 'src/components/helper-icon';
import { UploadLocalFile } from 'src/editor/component/upload-panel/upload-local-file';
import { Images } from 'src/image';
import type { Guest } from 'src/utils/import-guest-util';
import { readGuestListFromFile } from 'src/utils/import-guest-util';

interface Props {
  /** true为导入外部协作，false为导入空间成员，区别是文案不一样*/
  isGuest: boolean;
  onReceiveGuestList: (guestList: Guest[]) => void;
}
/** 导入外部协作者或者空间成员 */
export const ImportMember: FC<Props> = (props) => {
  return (
    <div className="next-modal px-7 pb-4 w-[480px]">
      <div className="flex justify-between mt-7 items-center">
        <div className="text-h2 ">
          {'批量导入'}&nbsp;{props.isGuest ? '外部协作者' : '空间成员'}
        </div>
        <HelperIconBox className="text-grey4 text-t4" linkContainer link={publicLink.importGuest}>
          查看帮助
        </HelperIconBox>
      </div>
      <div className="text-t3-medium mt-7">
        {__BUILD_IN__ ? '支持批量导入姓名和邮箱，快速添加' : '支持批量导入姓名和手机号，快速添加'}
        &nbsp;{props.isGuest ? '外部协作者至当前页面' : '成员至当前空间'}
      </div>
      <div className="text-t4 text-grey3 mt-2.5 flex items-center">
        <span className="w-1 h-1 rounded-full bg-grey3 ml-1 mr-1.5" />
        请使用 UTF-8 编码格式，上传后缀为 .csv 或 .txt 格式的文件
      </div>
      <div className="text-t4 text-grey3 flex items-center">
        <span className="w-1 h-1 rounded-full bg-grey3 ml-1 mr-1.5" />
        {'每行一个对应关系，以英文逗号分割。对应关系为：'}
        {__BUILD_IN__ ? '姓名,邮箱' : '姓名,手机号'}
      </div>
      <div className="flex justify-between mt-5">
        <img className="w-[200px]" src={Images.importGuestLeft} />
        <img className="w-[200px]" src={Images.importGuestRight} />
      </div>
      <UploadLocalFile
        singleFile
        labelClassName="w-full"
        style={{ width: '100%' }}
        title="选择本地文件"
        onSelect={async (files) => {
          const file = files[0];
          if (!file) return;
          if (!file.name.endsWith('.txt') && !file.name.endsWith('.csv')) {
            message.warning('文件格式不对');
            return;
          }
          const id = message.loading({ content: '解析中...' });
          try {
            const guests = await readGuestListFromFile(file);
            if (guests.length === 0) {
              throw Error('解析到的列表为空，请检查文本格式是否正确');
            }
            props.onReceiveGuestList(guests);
          } catch (e: any) {
            if (e instanceof Error) {
              message.error(e.message);
            } else {
              message.error(e);
            }
          } finally {
            message.closeMessage(id);
          }
        }}
        inputAttribute={{
          accept: '.csv,.txt',
        }}
      />
      <div className="text-t4 text-grey3 text-center mt-[-6px]">
        内容应小于2000行，文件大小不超过3MB；若超过空间限制人数将导入失败
      </div>
    </div>
  );
};
