import { AggregationAction } from '@next-space/fe-api-idl';

export interface Series {
  name: string;
  data: (number | '-' | string)[];
  stack?: string;
  xAxisValueToMap: Record<string, number | string>;
}

export const COMMON_CALCULATE_ITEMS: Record<string, string> = {
  [AggregationAction.COUNT_ALL]: '计数',
  [AggregationAction.DEDUPLICATION]: '去重计数',
};
export const NUMBER_CALCULATE_ITEMS: Record<string, string> = {
  ...COMMON_CALCULATE_ITEMS,
  [AggregationAction.SUM]: '求和',
  [AggregationAction.MAX]: '最大值',
  [AggregationAction.MIN]: '最小值',
  [AggregationAction.AVERAGE]: '平均值',
};
export const ALL_CALCULATE_ITEMS: Record<string, string> = {
  ...COMMON_CALCULATE_ITEMS,
  [AggregationAction.SUM]: '求和',
  [AggregationAction.MAX]: '最大值',
  [AggregationAction.MIN]: '最小值',
  [AggregationAction.AVERAGE]: '平均值',
  '': '',
};

export const isChartNumberAggregation = (aggregation?: AggregationAction) => {
  if (!aggregation) return false;
  return [
    AggregationAction.SUM,
    AggregationAction.MAX,
    AggregationAction.MIN,
    AggregationAction.AVERAGE,
  ].includes(aggregation);
};
