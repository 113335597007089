import { toMdast } from 'hast-util-to-mdast';
import rehypeParse from 'rehype-parse';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import remarkParse from 'remark-parse';
import showdown from 'showdown';
import { unified } from 'unified';
import { visit } from 'unist-util-visit';
import { filter } from 'unist-util-filter';

export const fromMarkdown = (doc: string) => {
  // 不处理 单个 \n + ![ 的话会变成行内图片， 所以要处理成 \n\n![
  // 正则里的 . 代表除了 \n 之外的任意字符, 用在这正好
  const md = doc.replace(/(.)\n!\[/g, '$1\n\n![');
  return unified().use(remarkParse).use(remarkGfm).use(remarkMath).parse(md);
};

/**from https://github.com/mlewand/win-clipboard/blob/07fedc3f2a346ced39163dae75042884bde010e4/lib/html.js#L55 */
const decodeHtml = (html: string, keepWholeHtml: boolean) => {
  let startFrag = /StartFragment:\s*0+(\d+?)$/gm.exec(html);
  let endFrag = /EndFragment:\s*0+(\d+?)$/gm.exec(html);

  if (keepWholeHtml) {
    startFrag = /StartHTML:\s*0+(\d+?)$/gm.exec(html);
    endFrag = /EndHTML:\s*0+(\d+?)$/gm.exec(html);
  }

  const startFragIndex = Number(startFrag?.[1] ?? 0);
  const endFragIndex = Number(endFrag?.[1] ?? html.length);

  return html.slice(startFragIndex, endFragIndex);
};

export const fromHtml = (html: string) => {
  // 提取 HTML 片段
  const hast = unified().use(rehypeParse).parse(decodeHtml(html, true));

  visit(hast, (node) => {
    if (node.type === 'element' && node.tagName === 'content') {
      node.tagName = 'div';
    }
  });

  const noCommentHast = filter(hast, (node) => node.type !== 'comment');

  if (!noCommentHast) return;

  return noCommentHast;
};

export const fromHtml2Mdast = (html: string) => {
  const hast = fromHtml(html);

  if (!hast) return;

  return toMdast(hast);
};

const converter = new showdown.Converter();

export const markdownToHtml = (markdown: string) => {
  return converter.makeHtml(markdown);
};
