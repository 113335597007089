import { cleanUrlSearchParams } from '@flowus/common/url';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useOpenUpgradeSpacePlan } from 'src/components/upgrade-space-plan';
import type { UpgradeSpacePlanType } from 'src/components/upgrade-space-plan/types';
import { SearchParams } from 'src/utils';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useOpenSettingModal,
  useOpenUpgradeSpace,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import { useOpenWelfareCode } from 'src/views/main/setting-modal/use-open-welfare-code';

export enum OpenDialogEnum {
  tutorial = 'tutorial',
  upgrade = 'upgrade',
  space_info = 'space_info',
  space_setting = 'space_setting',
  welfare = 'welfare',
  openUpgradeSpace = 'openUpgradeSpace',
  // 继承自 UpgradeSpacePlanType
  maxCapacity = 'maxCapacity',
  maxFileSize = 'maxFileSize',
  maxCopyLimit = 'maxCopyLimit',
  payCapacity = 'payCapacity',
  maxGuestCount = 'maxGuestCount',
  subscribeAiLimit = 'subscribeAiLimit',
}

/**
 *  根据url地址的参数显示对应对话框
 *  应用于分享页面转存空间不足时需要跳转到自己页面并弹出对应弹窗的逻辑
 */
export const useOpenDialogFromLocation = () => {
  const location = useLocation();
  const openNotificationJumpUrl = useOpenNotificationJumpUrl();

  useEffect(() => {
    const type = getTypeByNotificationJumpUrl(location.search);
    openNotificationJumpUrl(type);
  }, [location.search, openNotificationJumpUrl]);
};

export const useOpenNotificationJumpUrl = () => {
  const openSettingModal = useOpenSettingModal();
  const openWelfareCode = useOpenWelfareCode();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openUpgradeSpacePlan = useOpenUpgradeSpacePlan();

  return useCallback(
    (type: OpenDialogEnum) => {
      switch (type) {
        case OpenDialogEnum.upgrade: {
          openSettingModal({
            defaultMenuType: SettingMenuType.upgrade,
            from: OpenSettingFrom.file,
          });
          break;
        }
        case OpenDialogEnum.space_setting: {
          openSettingModal({
            defaultMenuType: SettingMenuType.members,
            from: OpenSettingFrom.file,
          });
          break;
        }
        case OpenDialogEnum.space_info: {
          openSettingModal({
            defaultMenuType: SettingMenuType.spaceInfo,
            from: OpenSettingFrom.file,
          });
          break;
        }
        case OpenDialogEnum.welfare: {
          openWelfareCode();
          break;
        }
        case OpenDialogEnum.maxCapacity:
        case OpenDialogEnum.maxGuestCount:
        case OpenDialogEnum.subscribeAiLimit:
        case OpenDialogEnum.payCapacity:
        case OpenDialogEnum.maxFileSize:
        case OpenDialogEnum.maxCopyLimit: {
          openUpgradeSpacePlan({ type: type as unknown as UpgradeSpacePlanType });
          break;
        }
        case OpenDialogEnum.openUpgradeSpace: {
          openUpgradeSpace(OpenSettingFrom.switch_space);
          break;
        }
        default:
      }

      cleanUrlSearchParams([SearchParams.openDialog]);
    },
    [openSettingModal, openUpgradeSpace, openUpgradeSpacePlan, openWelfareCode]
  );
};

export const getTypeByNotificationJumpUrl = (search: string) => {
  const query = new URLSearchParams(search);
  const type = query.get(SearchParams.openDialog) as OpenDialogEnum;
  return type;
};
