import { useCallback } from 'react';

import { useMindMapEngine } from '../context';

export const useDeleteMindNode = () => {
  const engine = useMindMapEngine();
  return useCallback(
    (id: string) => {
      engine.deleteMindNode(id);
    },
    [engine]
  );
};
