import type { FC } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { Images } from 'src/image';

interface Props {
  transparent?: boolean;
  snapshot?: string;
  isWidthMax: boolean;
}
export const ExportPreview: FC<Props> = (props) => {
  const { snapshot, transparent, isWidthMax = true } = props;

  const renderSnapshot = () => {
    if (!snapshot) {
      return <LoadingContainer className="h-full" />;
    }
    return <img src={snapshot} className={isWidthMax ? 'w-full' : 'h-full'} />;
  };

  return (
    <div
      className="w-[250px] h-[250px] shrink-0 p-5 flex items-center justify-center"
      style={{
        background: transparent ? `url(${Images.transparent})` : undefined,
      }}
    >
      {renderSnapshot()}
    </div>
  );
};
