import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { getRelationRecords } from 'src/bitable/table-view/cell/relation/get-relation-records';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { useSubitemExpand } from 'src/services/database/state';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';

/** TODO */
export const useOpenSubItem = (collectionId: string | undefined) => {
  return useObservableBlock(collectionId, (_) => {
    return isOpenSubitem(collectionId);
  });
};
export const useSubitemSetting = (collectionId: string | undefined) => {
  return useObservableBlock(collectionId, (b) => {
    return b?.data.format?.openSubItem;
  });
};

export const isOpenSubitem = (collectionId: string | undefined) => {
  if (!collectionId) return;
  const block = getState().blocks[collectionId];
  const openSubItem = block?.data.format?.openSubItem;
  if (!openSubItem) return false;
  if (!openSubItem.parentPropertyId) return false;
  return Boolean(block?.data.schema?.[openSubItem.parentPropertyId]);
};
export const getSubitemTree = (collection: NextBlock | undefined) => {
  if (!collection) return;
  const openSubitem = isOpenSubitem(collection.uuid);
  if (openSubitem) {
    const { subNodes } = collection;
    // 打开了subitem功能的重新按层级排序
    const subitem = collection.data.format?.openSubItem;
    const _parentPropertyId = subitem?.parentPropertyId;
    if (subitem && _parentPropertyId) {
      const parent2ChildMap = new Map<string, string[]>();
      const child2ParentMap = new Map<string, string[]>();
      subNodes.forEach((childId) => {
        const { relationRecords: parentRelationRecords } = getRelationRecords(
          childId,
          _parentPropertyId
        );
        parentRelationRecords.forEach((pId) => {
          // 记录父关系
          const childIds = parent2ChildMap.get(pId) ?? [];
          childIds.push(childId);
          parent2ChildMap.set(pId, childIds);
          // 记录子-父关系
          const parentIds = child2ParentMap.get(childId) ?? [];
          parentIds.push(pId);
          child2ParentMap.set(childId, parentIds);
        });
      });
      return [parent2ChildMap, child2ParentMap];
    }
  }
};

export const getAllRecordIds = (collectionId: string, ids: string[], removeDup = true) => {
  const subitemTree = getSubitemTree(getState().blocks[collectionId]);
  let allIds: string[] = ids;
  const subitemMap = subitemTree?.[0];
  if (subitemMap) {
    ids.forEach((v) => {
      const childIds = getSubitemChildIds(v, subitemMap);
      allIds.push(v);
      allIds.push(...childIds);
    });
    if (removeDup) {
      // 去重
      allIds = [...new Set(allIds)];
    }
  }
  return allIds;
};

const getSubitemChildIds = (parentId: string, childIdMap: Map<string, string[]>, deep = 0) => {
  const set = childIdMap.get(parentId);
  const ids: string[] = [];
  if (deep >= 10) return [];
  if (set) {
    set.forEach((v) => {
      ids.push(v);
      const childIds = getSubitemChildIds(v, childIdMap, deep + 1);
      ids.push(...childIds);
    });
    return ids;
  }
  return [];
};

export const getAllExpandIds = (collectionId: string, viewId: string, ids: string[]) => {
  const subitemTree = getSubitemTree(getState().blocks[collectionId]);
  const allIds: string[] = [];
  const subitemMap = subitemTree?.[0];
  if (subitemMap) {
    ids.forEach((v) => {
      const childIds = getExpandSubitemChildIds(collectionId, viewId, v, subitemMap);
      allIds.push(v);
      allIds.push(...childIds);
    });
    return allIds;
  }
  return ids;
};
const getExpandSubitemChildIds = (
  collectionId: string,
  viewId: string,
  recordId: string,
  childIdMap: Map<string, string[]>,
  deep = 0
) => {
  const childIds = childIdMap.get(recordId);
  const expandState = useSubitemExpand.getState();
  const expandIds: string[] = [];
  if (deep >= 10) return [];
  childIds?.forEach((v) => {
    const expand = expandState[collectionId + viewId]?.[recordId];
    if (expand) {
      expandIds.push(v);
    }
  });

  return expandIds;
};

export const useSubitemStyle = (viewId: string) => {
  return useObservableStore(
    (s) => {
      return getSubitemStyle(viewId, s);
    },
    [viewId]
  );
};
export const getSubitemStyle = (viewId: string, s: RootState) => {
  const view = s.collectionViews[viewId];
  let defaultStyle: CollectionViewDTO['format']['subItemstyle'] = 'Card';

  if (isNestedStyleViewType(view?.type)) {
    defaultStyle = 'Nested';
  }
  return view?.format.subItemstyle ?? defaultStyle;
};

export const isNestedStyleViewType = (type?: CollectionViewType) => {
  // 表格、目录、时间轴视图 为逐层嵌套
  return (
    type &&
    [CollectionViewType.TABLE, CollectionViewType.TIMELINE, CollectionViewType.LIST].includes(type)
  );
};
