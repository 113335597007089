import type { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { isAnyOf } from '@reduxjs/toolkit';
import { forEachRight, get, last, mapValues } from 'lodash-es';
import { $spaceViewsCache } from 'src/services/spaces/space-views';
import { $spacesCache } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';
import {
  CREATE_BLOCK,
  HISTORY_CLEAR,
  HISTORY_EFFECTS,
  HISTORY_END,
  HISTORY_REDO,
  HISTORY_START,
  HISTORY_UNDO,
  LIST_AFTER_BLOCK,
  LIST_AFTER_FAVORITE,
  LIST_AFTER_TEMPLATE,
  LIST_BEFORE_BLOCK,
  LIST_BEFORE_FAVORITE,
  LIST_BEFORE_TEMPLATE,
  LIST_REMOVE_BLOCK,
  LIST_REMOVE_FAVORITE,
  LIST_REMOVE_TEMPLATE,
  TRANSACTION_FLUSH,
  UPDATE_BLOCK,
  UPDATE_SPACE_VIEW,
} from '../actions';
import {
  CREATE_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW_PAGESORT,
  LIST_BEFORE_COLLECTION_VIEW,
  LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
  LIST_REMOVE_COLLECTION_VIEW,
  LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
  UPDATE_COLLECTION_VIEW,
} from '../actions/collection-view';
import {
  CREATE_COMMENT,
  LIST_AFTER_COMMENT,
  LIST_REMOVE_COMMENT,
  UPDATE_COMMENT,
} from '../actions/comments';
import {
  CREATE_DISCUSSION,
  LIST_AFTER_DISCUSSION,
  LIST_REMOVE_DISCUSSION,
  UPDATE_DISCUSSION,
} from '../actions/discussion';
import type { NextBlock, RootState } from '../types';
import { effectsMap } from './effectsmap';

let tracking = false;

interface Operation {
  action: AnyAction;
  snapshot: RootState & {
    user: typeof $currentUserCache;
    spaceViews: typeof $spaceViewsCache;
    spaces: typeof $spacesCache;
  };
}

const undo: Operation[][] = [];
const redo: Operation[][] = [];
export let historyVersion = 0;

const applyUndo = (dispatch: Dispatch) => {
  const ops = undo.pop();
  if (!ops) return;

  forEachRight(ops, (operation) => {
    const { action, snapshot } = operation;

    if (isAnyOf(HISTORY_EFFECTS)(action)) {
      const id = action.payload;
      effectsMap.get(id)?.undo();
    }

    if (isAnyOf(CREATE_BLOCK)(action)) {
      const {
        block: { uuid },
        ignoreOp,
      } = action.payload;
      dispatch(UPDATE_BLOCK({ uuid, patch: { status: -1 }, ignoreOp }));
    }

    if (isAnyOf(CREATE_COLLECTION_VIEW)(action)) {
      const { uuid } = action.payload.collectionView;
      dispatch(UPDATE_COLLECTION_VIEW({ uuid, patch: { status: -1 } }));
    }

    if (isAnyOf(LIST_REMOVE_COLLECTION_VIEW)(action)) {
      const { uuid, parentId } = action.payload;
      const views = snapshot.blocks[parentId]?.views;
      if (!views) return;
      const index = views.indexOf(uuid);
      const prevSibling = views[index - 1];
      const nextSibling = views[index + 1];

      if (nextSibling) {
        dispatch(LIST_BEFORE_COLLECTION_VIEW({ uuid, parentId, before: nextSibling }));
      } else {
        dispatch(LIST_AFTER_COLLECTION_VIEW({ uuid, parentId, after: prevSibling }));
      }
    }

    if (isAnyOf(LIST_REMOVE_COLLECTION_VIEW_PAGESORT)(action)) {
      const { uuid, viewId } = action.payload;
      const pageSort = snapshot.collectionViews[viewId]?.pageSort;
      if (!pageSort) return;
      const index = pageSort.indexOf(uuid);
      const prevSibling = pageSort[index - 1];
      const nextSibling = pageSort[index + 1];

      if (nextSibling) {
        dispatch(LIST_BEFORE_COLLECTION_VIEW_PAGESORT({ uuid, viewId, before: nextSibling }));
      } else {
        dispatch(LIST_AFTER_COLLECTION_VIEW_PAGESORT({ uuid, viewId, after: prevSibling }));
      }
    }

    if (isAnyOf(LIST_AFTER_COLLECTION_VIEW, LIST_BEFORE_COLLECTION_VIEW)(action)) {
      const { uuid, parentId } = action.payload;
      dispatch(LIST_REMOVE_COLLECTION_VIEW({ uuid, parentId }));
    }

    if (
      isAnyOf(LIST_AFTER_COLLECTION_VIEW_PAGESORT, LIST_BEFORE_COLLECTION_VIEW_PAGESORT)(action)
    ) {
      const { uuid, viewId } = action.payload;
      dispatch(LIST_REMOVE_COLLECTION_VIEW_PAGESORT({ uuid, viewId }));
    }

    if (isAnyOf(UPDATE_BLOCK)(action)) {
      const { uuid, patch, ignoreOp } = action.payload;
      const oldBlock = snapshot.blocks[uuid];
      if (!oldBlock) return;
      const undoPatch: Record<string, unknown> = {};

      Object.keys(patch).forEach((key) => {
        if (['type', 'backgroundColor', 'textColor', 'status'].includes(key)) {
          undoPatch[key] = oldBlock[key as keyof NextBlock];
        }
      });

      undoPatch.data = mapValues(patch.data, (_, key) => get(oldBlock.data, key));

      dispatch(
        UPDATE_BLOCK({
          uuid,
          patch: undoPatch,
          ignoreOp,
        })
      );
    }

    if (isAnyOf(LIST_AFTER_BLOCK, LIST_BEFORE_BLOCK)(action)) {
      const { uuid, ignoreOp } = action.payload;
      dispatch(LIST_REMOVE_BLOCK({ uuid, ignoreOp }));
    }

    if (isAnyOf(LIST_REMOVE_BLOCK)(action)) {
      const { payload } = action;
      const block = snapshot.blocks[payload.uuid];
      if (!block) return;
      const parent = snapshot.blocks[block.parentId];
      if (!parent) return;
      const index = parent.subNodes.indexOf(payload.uuid);
      const prevSibling = parent.subNodes[index - 1];
      const nextSibling = parent.subNodes[index + 1];
      if (nextSibling) {
        dispatch(
          LIST_BEFORE_BLOCK({
            uuid: payload.uuid,
            parentId: parent.uuid,
            before: nextSibling,
            ignoreOp: payload.ignoreOp,
          })
        );
      } else {
        dispatch(
          LIST_AFTER_BLOCK({
            uuid: payload.uuid,
            parentId: parent.uuid,
            after: prevSibling,
            ignoreOp: payload.ignoreOp,
          })
        );
      }
    }

    if (isAnyOf(UPDATE_COLLECTION_VIEW)(action)) {
      const { payload } = action;
      const oldViews = snapshot.collectionViews[payload.uuid];
      if (!oldViews) return;

      const patch = {
        pageSort: oldViews.pageSort,
        format: oldViews.format,
        status: oldViews.status,
        updatedAt: oldViews.updatedAt,
      };

      dispatch(
        UPDATE_COLLECTION_VIEW({
          uuid: payload.uuid,
          patch,
        })
      );
    }
    if (isAnyOf(LIST_AFTER_FAVORITE, LIST_BEFORE_FAVORITE)(action)) {
      const { payload } = action;
      dispatch(
        LIST_REMOVE_FAVORITE({ uuid: payload.uuid, parentId: snapshot.user.currentSpaceViewId })
      );
    }
    if (isAnyOf(LIST_REMOVE_FAVORITE)(action)) {
      const { payload } = action;
      const spaceView = snapshot.spaceViews[snapshot.user.currentSpaceViewId];
      if (!spaceView) return;
      const index = spaceView.favoritePages.indexOf(payload.uuid);
      if (index === 0) {
        dispatch(
          LIST_BEFORE_FAVORITE({ uuid: payload.uuid, parentId: snapshot.user.currentSpaceViewId })
        );
      } else {
        const prePageId = spaceView.favoritePages[index - 1];
        prePageId &&
          dispatch(
            LIST_AFTER_FAVORITE({
              uuid: payload.uuid,
              parentId: snapshot.user.currentSpaceViewId,
              after: prePageId,
            })
          );
      }
    }
    if (isAnyOf(LIST_AFTER_TEMPLATE, LIST_BEFORE_TEMPLATE)(action)) {
      const { payload } = action;
      const spaceView = snapshot.spaceViews[snapshot.user.currentSpaceViewId];
      if (!spaceView) return;
      const space = snapshot.spaces[spaceView.spaceId];
      if (!space) return;
      dispatch(LIST_REMOVE_TEMPLATE({ uuid: payload.uuid, parentId: space.uuid }));
    }
    if (isAnyOf(LIST_REMOVE_TEMPLATE)(action)) {
      const { payload } = action;
      const spaceView = snapshot.spaceViews[snapshot.user.currentSpaceViewId];
      if (!spaceView) return;
      const space = snapshot.spaces[spaceView.spaceId];
      if (!space) return;
      const index = space.customTemplates?.indexOf(payload.uuid);
      if (index === 0) {
        dispatch(LIST_BEFORE_TEMPLATE({ uuid: payload.uuid, parentId: space.uuid }));
      } else {
        const prePageId = spaceView.favoritePages[index - 1];
        prePageId &&
          dispatch(
            LIST_AFTER_TEMPLATE({
              uuid: payload.uuid,
              parentId: space.uuid,
              after: prePageId,
            })
          );
      }
    }
    // TODO(yuxiang): Handle DISCUSSION COMMENT
  });

  dispatch(TRANSACTION_FLUSH());
  redo.push(ops);
};

const applyRedo = (dispatch: Dispatch) => {
  const ops = redo.pop();
  if (!ops) return;

  ops.forEach((operation) => {
    const { action } = operation;

    if (isAnyOf(HISTORY_EFFECTS)(action)) {
      const id = action.payload;
      effectsMap.get(id)?.redo();
    } else {
      dispatch(action);
    }
  });

  dispatch(TRANSACTION_FLUSH());

  undo.push(ops);
};

let customState: RootState | undefined;
export const opHistoryMiddleware: Middleware = ({ getState, dispatch }) => {
  return (next) => {
    return (action) => {
      if (isAnyOf(HISTORY_START)(action)) {
        historyVersion = 1;
        tracking = true;
        redo.length = 0;
        while (undo.length > 49) {
          undo.shift();
        }
        if (last(undo)?.length !== 0) {
          undo.push([]);
        }
        customState = action.payload.state;
      }

      if (isAnyOf(HISTORY_END)(action)) {
        tracking = false;
        customState = undefined;
      }

      if (
        tracking &&
        isAnyOf(
          CREATE_BLOCK,
          UPDATE_BLOCK,
          LIST_REMOVE_BLOCK,
          LIST_AFTER_BLOCK,
          LIST_BEFORE_BLOCK,
          CREATE_COLLECTION_VIEW,
          UPDATE_COLLECTION_VIEW,
          LIST_REMOVE_COLLECTION_VIEW,
          LIST_AFTER_COLLECTION_VIEW,
          LIST_BEFORE_COLLECTION_VIEW,
          LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
          LIST_AFTER_COLLECTION_VIEW_PAGESORT,
          LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
          HISTORY_EFFECTS,
          LIST_AFTER_FAVORITE,
          LIST_BEFORE_FAVORITE,
          LIST_REMOVE_FAVORITE,
          UPDATE_SPACE_VIEW,
          CREATE_DISCUSSION,
          UPDATE_DISCUSSION,
          LIST_AFTER_DISCUSSION,
          LIST_REMOVE_DISCUSSION,
          CREATE_COMMENT,
          UPDATE_COMMENT,
          LIST_AFTER_COMMENT,
          LIST_REMOVE_COMMENT
        )(action)
      ) {
        const snapshot = customState || (getState() as RootState);
        last(undo)?.push({
          snapshot: {
            ...snapshot,
            user: { ...$currentUserCache },
            spaceViews: { ...$spaceViewsCache },
            spaces: { ...$spacesCache },
          },
          action,
        });
      }

      if (isAnyOf(HISTORY_EFFECTS)(action)) {
        const id = action.payload;
        effectsMap.get(id)?.init();
      }

      if (isAnyOf(HISTORY_UNDO)(action)) {
        next(action);
        applyUndo(dispatch);
        return;
      }

      if (isAnyOf(HISTORY_REDO)(action)) {
        next(action);
        applyRedo(dispatch);
        return;
      }

      if (isAnyOf(HISTORY_CLEAR)(action)) {
        effectsMap.clear();
        undo.length = 0;
        redo.length = 0;
        tracking = false;
        customState = undefined;
      }

      next(action);
    };
  };
};
