import type { SegmentDTO } from '@next-space/fe-api-idl';

import { BiValueType } from './_abstract';

class BiUndefType extends BiValueType<undefined> {
  fromSegments(_segments: SegmentDTO[] | undefined): undefined {
    return undefined;
  }

  toSegments(_value: undefined): SegmentDTO[] | undefined {
    return undefined;
  }

  isNonNullEmpty(_value: undefined): boolean {
    return true;
  }

  compare(_value1: undefined, _value2: undefined): number {
    return 0;
  }
}

export const undefType = new BiUndefType();
