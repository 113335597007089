import type {
  CollectionSchema,
  CollectionViewDTO,
  CollectionViewGroups,
  CollectionViewProperties,
} from '@next-space/fe-api-idl';
import {
  BlockType,
  CardSize,
  CollectionSchemaType,
  CollectionViewType,
  CoverAspectType,
  CoverType,
  PermissionRole,
} from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { cloneDeep } from 'lodash-es';
import { message } from 'src/common/components/message';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import type { NextCollectionView } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';
import { ActivityIds } from '../activities/activity-ids';
import { useUpdateTask } from '../activities/use-update-task';
import { ActivitiesListType } from '../activities/use-activity';
import { getPermissions } from '../share/use-permissions';
import { useTransaction } from '../use-transaction';
import {
  addProperty,
  getGroupOptions,
  getNewPropertyVisibleObj,
  makePropertyName,
} from './use-create-property';
import { getViewFormat } from './get-view-format';

export const useAddCollectionView = () => {
  const transaction = useTransaction();
  const updateTask = useUpdateTask();

  const createView = (
    viewParentId: string,
    viewTitle: string,
    viewType = CollectionViewType.TABLE
  ) => {
    const { blocks } = getState();
    let collectionId = viewParentId;
    const parent = blocks[viewParentId];
    if (
      parent?.type === BlockType.REFERENCE_COLLECTION ||
      parent?.type === BlockType.REFERENCE_COLLECTION_PAGE
    ) {
      collectionId = parent.data.ref?.uuid ?? '';
    }
    const collection = blocks[collectionId];
    if (!collection) return;

    let format: NextCollectionView['format'] = {};
    const schema = collection.data.schema ?? {};

    /* 新建视图时和当前展示的视图的属性顺序一致 */
    let currentViewProperties: CollectionViewProperties = [];
    const currentViewId = localStorage.getItem(`viewId:${viewParentId}`);
    if (currentViewId) {
      const { properties = getPropertiesFromSchema(schema, viewType), view } =
        getViewFormat(currentViewId) ?? {};
      currentViewProperties = cloneDeep(properties);

      if (viewType !== CollectionViewType.TABLE) {
        currentViewProperties.forEach((item) => {
          if (item.property !== 'title') {
            item.visible = false;
          }
        });
      } else {
        if (view?.type !== CollectionViewType.TABLE) {
          currentViewProperties.forEach((item) => (item.visible = true));
        }
      }
    }
    let newPropertyId: string;

    if (viewType === CollectionViewType.TABLE) {
      format = composeTableViewFormat({
        tableProperties: currentViewProperties,
      });
    }

    if (viewType === CollectionViewType.GALLERY) {
      void updateTask(ActivityIds.CREATE_GALLERY_VIEW, ActivitiesListType.advancedList, {
        step1: true,
      });

      format = composeGalleryViewFormat({
        galleryProperties: currentViewProperties,
      });
    }

    if (viewType === CollectionViewType.LIST) {
      format = composeListViewFormat({
        listProperties: currentViewProperties,
      });
    }

    if (viewType === CollectionViewType.BOARD) {
      const propertyId = Object.keys(schema).find(
        (schemaId) => schema[schemaId]?.type === CollectionSchemaType.SELECT
      );

      if (propertyId) {
        format = composeBoardViewFormat({
          groupProperty: propertyId,
          boardProperties: currentViewProperties,
          options: schema[propertyId]?.options,
        });
      } else {
        const { role } = getPermissions(collectionId);
        if (role === PermissionRole.READER) {
          message.warning('当前多维表无分类属性，不支持创建看板视图！');
          return;
        }
        newPropertyId = uuidV4();
        const options = getGroupOptions();

        const { inVisibleProperty } = getNewPropertyVisibleObj(newPropertyId);

        format = composeBoardViewFormat({
          groupProperty: newPropertyId,
          boardProperties: currentViewProperties.concat(inVisibleProperty),
          options,
        });

        return transaction(() => {
          updateBlock(collectionId, {
            data: {
              schema: {
                ...collection.data.schema,
                [newPropertyId]: {
                  name: makePropertyName(schema, '分类'),
                  type: CollectionSchemaType.SELECT,
                  options,
                },
              },
            },
          });

          const newViewId = CollectionViewManager.add(
            {
              title: viewTitle,
              format,
              pageSort: collection.subNodes,
              type: viewType,
            },
            { parentId: viewParentId, last: true }
          );

          // 为所有视图添加 property
          addProperty(collection.uuid, [newPropertyId], newViewId);
          return newViewId;
        });
      }
    }

    if (viewType === CollectionViewType.TIMELINE) {
      void updateTask(ActivityIds.CREATE_TIMELINE_VIEW, ActivitiesListType.advancedList);

      const dateProperties = Object.entries(schema).filter(
        ([_, item]) => item.type === CollectionSchemaType.DATE
      );

      const { role } = getPermissions(collectionId);
      if (role === PermissionRole.READER && dateProperties.length < 2) {
        message.warning('当前多维表无日期属性，不支持创建时间轴视图！');
        return;
      }

      let timelineBy = dateProperties[0]?.[0];
      let timelineByEnd = dateProperties[1]?.[0];
      const newTimelineProperties = getPropertiesFromSchema(schema, viewType);

      if (timelineBy && timelineByEnd) {
        format = composeTimelineViewFormat({
          timelineBy,
          timelineByEnd,
          timelineProperties: newTimelineProperties,
        });
      } else {
        const newProperty: { property: string; visible: boolean }[] = [];
        const newSchema = { ...schema };
        if (!timelineBy) {
          timelineBy = uuidV4();
          const invisibleProperty = { property: timelineBy, visible: false };
          newProperty.push(invisibleProperty);
          newTimelineProperties.push(invisibleProperty);
          newSchema[timelineBy] = {
            name: makePropertyName(collection.data.schema, '开始时间'),
            type: CollectionSchemaType.DATE,
          };
        }
        if (!timelineByEnd) {
          timelineByEnd = uuidV4();
          const invisibleProperty = { property: timelineByEnd, visible: false };
          newProperty.push(invisibleProperty);
          newTimelineProperties.push(invisibleProperty);
          newSchema[timelineByEnd] = {
            name: makePropertyName(collection.data.schema, '结束时间'),
            type: CollectionSchemaType.DATE,
          };
        }

        // 构造 format
        format = composeTimelineViewFormat({
          timelineBy,
          timelineByEnd,
          timelineProperties: newTimelineProperties,
        });

        return transaction(() => {
          updateBlock(collection.uuid, { data: { schema: newSchema } });

          const newViewId = CollectionViewManager.add(
            {
              title: viewTitle,
              format,
              pageSort: collection.subNodes,
              type: viewType,
            },
            { parentId: viewParentId, last: true }
          );

          // 为所有视图添加 property
          addProperty(
            collection.uuid,
            newProperty.map((item) => item.property),
            newViewId
          );

          return newViewId;
        });
      }
    }

    if (viewType === CollectionViewType.CALENDAR) {
      void updateTask(ActivityIds.CREATE_CALENDAR_VIEW, ActivitiesListType.advancedList);

      const dateProperties = Object.entries(schema).filter(
        ([_, item]) => item.type === CollectionSchemaType.DATE
      );

      const { role } = getPermissions(collectionId);
      if (role === PermissionRole.READER && dateProperties.length < 2) {
        message.warning('当前多维表无日期属性，不支持创建日历视图！');
        return;
      }

      let calendarBy = dateProperties[0]?.[0];
      let calendarByEnd = dateProperties[1]?.[0];
      const newCalendarProperties = getPropertiesFromSchema(schema, viewType);

      if (calendarBy && calendarByEnd) {
        format = composeCalendarViewFormat({
          calendarProperties: newCalendarProperties,
          calendarBy,
          calendarByEnd,
        });
      } else {
        const newProperty: { property: string; visible: boolean }[] = [];
        const newSchema = { ...schema };
        if (!calendarBy) {
          calendarBy = uuidV4();
          const invisibleProperty = { property: calendarBy, visible: false };
          newProperty.push(invisibleProperty);
          newCalendarProperties.push(invisibleProperty);
          newSchema[calendarBy] = {
            name: makePropertyName(collection.data.schema, '开始时间'),
            type: CollectionSchemaType.DATE,
          };
        }
        if (!calendarByEnd) {
          calendarByEnd = uuidV4();
          const invisibleProperty = { property: calendarByEnd, visible: false };
          newProperty.push(invisibleProperty);
          newCalendarProperties.push(invisibleProperty);
          newSchema[calendarByEnd] = {
            name: makePropertyName(collection.data.schema, '结束时间'),
            type: CollectionSchemaType.DATE,
          };
        }

        // 构造 format
        format = composeCalendarViewFormat({
          calendarProperties: newCalendarProperties,
          calendarBy,
          calendarByEnd,
        });

        return transaction(() => {
          updateBlock(collection.uuid, {
            data: { schema: newSchema },
          });

          const newViewId = CollectionViewManager.add(
            {
              title: viewTitle,
              format,
              pageSort: collection.subNodes,
              type: viewType,
            },
            { parentId: viewParentId, last: true }
          );

          // 为所有视图添加 property
          addProperty(
            collection.uuid,
            newProperty.map((item) => item.property),
            newViewId
          );

          return newViewId;
        });
      }
    }

    if (viewType === CollectionViewType.FORM) {
      void updateTask(ActivityIds.CREATE_FORM_VIEW, ActivitiesListType.advancedList);

      format = {
        formSerialNumber: true,
        formShowDescription: true,
        formDescription: [],
      };
    }

    return transaction(() => {
      // 添加视图
      const newViewId = CollectionViewManager.add(
        {
          title: viewTitle,
          format,
          pageSort: collection.subNodes,
          type: viewType,
        },
        { parentId: viewParentId, last: true }
      );

      return newViewId;
    });
  };

  return useCallback(createView, [transaction, updateTask]);
};

export const composeTableViewFormat = ({
  tableProperties,
  oldViewFormat = {},
}: {
  tableProperties: NextCollectionView['format']['tableProperties'];
  oldViewFormat?: NextCollectionView['format'];
}) => {
  const result = {
    ...oldViewFormat,
    tableProperties,
    tableWrap: typeof oldViewFormat.tableWrap !== 'undefined' ? oldViewFormat.tableWrap : true,
  };

  return result;
};

export const composeFormViewFormat = ({
  formProperties,
  oldViewFormat = {},
}: {
  formProperties: NextCollectionView['format']['formProperties'];
  oldViewFormat?: NextCollectionView['format'];
}) => {
  const result = {
    ...oldViewFormat,
    formProperties,
    formShowDescription:
      typeof oldViewFormat.formShowDescription !== 'undefined'
        ? oldViewFormat.formShowDescription
        : true,
    formSerialNumber:
      typeof oldViewFormat.formSerialNumber !== 'undefined' ? oldViewFormat.formSerialNumber : true,
  };

  return result;
};

export const composeBoardViewFormat = ({
  groupProperty,
  boardProperties,
  options = [],
  oldViewFormat = {},
}: {
  groupProperty: string;
  boardProperties: CollectionViewProperties | undefined;
  options?: CollectionSchema['options'];
  oldViewFormat?: NextCollectionView['format'];
}): CollectionViewDTO['format'] => {
  const boardGroups: CollectionViewGroups = options.map((option) => ({
    property: groupProperty,
    propertyType: CollectionSchemaType.SELECT,
    value: option.value,
    visible: true,
  }));
  boardGroups.unshift({
    property: groupProperty,
    propertyType: CollectionSchemaType.SELECT,
    visible: true,
  });

  return {
    ...oldViewFormat,
    boardGroupBy: { property: groupProperty },
    boardCoverType: oldViewFormat.boardCoverType ?? CoverType.NONE,
    boardCardSize: oldViewFormat.boardCardSize ?? CardSize.MEDIUM,
    boardCoverAspect: oldViewFormat.boardCoverAspect ?? CoverAspectType.COVER,
    boardProperties,
    boardGroups,
  };
};

export const composeGalleryViewFormat = ({
  galleryProperties,
  oldViewFormat = {},
}: {
  galleryProperties: CollectionViewProperties | undefined;
  oldViewFormat?: NextCollectionView['format'];
}) => {
  const result = {
    ...oldViewFormat,
    galleryProperties,
    galleryCoverType: oldViewFormat.galleryCoverType ?? CoverType.PAGE_CONTENT,
    galleryCardSize: oldViewFormat.galleryCardSize ?? CardSize.MEDIUM,
    galleryCoverAspect: oldViewFormat.galleryCoverAspect ?? CoverAspectType.COVER,
  };

  return result;
};

export const composeListViewFormat = ({
  listProperties,
  oldViewFormat = {},
}: {
  listProperties: CollectionViewProperties | undefined;
  oldViewFormat?: NextCollectionView['format'];
}) => {
  const result = { ...oldViewFormat, listProperties };

  return result;
};

export const composeTimelineViewFormat = ({
  timelineProperties,
  oldViewFormat = {},
  timelineBy,
  timelineByEnd,
}: {
  timelineProperties: CollectionViewProperties | undefined;
  oldViewFormat?: NextCollectionView['format'];
  timelineBy: string;
  timelineByEnd: string;
}) => {
  const result = {
    ...oldViewFormat,
    timelineProperties,
    timelineBy,
    timelineByEnd,
    timelineTableProperties: timelineProperties,
    timelineShowTable: true,
  };

  return result;
};

export const composeCalendarViewFormat = ({
  calendarProperties,
  oldViewFormat = {},
  calendarBy,
  calendarByEnd,
}: {
  calendarProperties: CollectionViewProperties | undefined;
  oldViewFormat?: NextCollectionView['format'];
  calendarBy: string;
  calendarByEnd: string;
}) => {
  const result = {
    ...oldViewFormat,
    calendarProperties,
    calendarBy,
    calendarByEnd,
  };

  return result;
};

export const getPropertiesFromSchema = (
  schema: Record<string, CollectionSchema>,
  type: CollectionViewType = CollectionViewType.TABLE
) => {
  return Object.keys(schema).map((property) => {
    return {
      property,
      visible: property === 'title' || type === CollectionViewType.TABLE,
    };
  });
};
