import { cx } from '@flowus/common/cx';
import type { CollectionSchema, SegmentDTO } from '@next-space/fe-api-idl';
import { useLayoutEffect, useRef, useState } from 'react';
import type { BiValueType } from 'src/bitable/v2/values/_abstract';
import { Icon } from 'src/common/components/icon';
import { RichTextDiff } from 'src/components/rich-text-diff';

import { CollectionPropertySubject, PropertyValueSubject } from '../subject';

export function RecordTextPropChange(props: {
  schema: CollectionSchema;
  from: SegmentDTO[] | undefined;
  to: SegmentDTO[] | undefined;
  onClick?: () => void;
}) {
  const { schema, from, to, onClick } = props;
  return (
    <div className="mt-1 px-1 animate-hover" onClick={onClick}>
      <CollectionPropertySubject
        className="text-t4 text-grey3 mb-1.5 flex items-center"
        schema={schema}
      />
      <RichTextDiff className="mt-1 py-1" segmentsA={from} segmentsB={to} />
    </div>
  );
}

export function RecordPropChange<T>(props: {
  schema: CollectionSchema;
  valueType: BiValueType<T>;
  from: T;
  to: T;
  onClick?: () => void;
}) {
  const { valueType, from: fromValue, to: toValue, schema, onClick } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [vertical, setVertical] = useState(false);
  useLayoutEffect(() => {
    const div = ref.current;
    if (div == null) return;
    if (div.scrollWidth > div.offsetWidth) {
      setVertical(true);
    }
  }, []);
  return (
    <div className="mt-1 px-1 animate-hover" onClick={onClick}>
      <CollectionPropertySubject
        className="text-t4 text-grey3 mb-1.5 flex items-center"
        schema={schema}
      />
      <div ref={ref} className={cx('flex', vertical ? 'flex-col' : 'items-center')}>
        <PropertyValueSubject type={valueType} value={fromValue} />
        <Icon name="IcDateEnd" size="middle" className="mx-2 text-grey3" />
        <PropertyValueSubject type={valueType} value={toValue} />
      </div>
    </div>
  );
}
