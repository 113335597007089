import type { SpaceViewDTO } from '@next-space/fe-api-idl';
import { BlockStatus, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { getSubPages } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpaceView } from 'src/hooks/space';
import type { RootState } from 'src/redux/types';
import { $appUiStateCache, useSharedPages } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getRolePermissions } from 'src/utils/permission-utils';

export const useTocIds = (pageId: string, userId: string, isGuest?: boolean) => {
  const currentSpaceView = useCurrentSpaceView();
  const sharePage = useSharedPages();
  return useObservableStore(
    ({ blocks }) => getTocIds(pageId, userId, blocks, currentSpaceView, isGuest),
    [pageId, userId, sharePage, currentSpaceView?.sharePages, isGuest]
  );
};

export const getTocIds = (
  pageId: string,
  userId: string,
  blocks: RootState['blocks'],
  currentSpaceView?: SpaceViewDTO,
  isGuest?: boolean
) => {
  const rootPages = getSubPages(blocks, pageId);
  // const space = getCurrentSpace();

  const allRoot = new Set([...rootPages, ...$appUiStateCache.$sharedPages]);

  const team: string[] = [];
  const person: string[] = [];
  const allShare: string[] = [];

  allRoot.forEach((id) => {
    // if (space.setting && space.setting?.pdfFolderId === id) return;

    const page = blocks[id];
    if (!page || page.status !== BlockStatus.NORMAL) {
      return;
    }
    const { roleWithoutPublic } = getPermissions(id);
    if (roleWithoutPublic === PermissionRole.NONE) {
      return;
    }

    const { permissions } = page;
    const rolePermissions = getRolePermissions(
      permissions.filter((p) => p.role !== PermissionRole.NONE)
    );

    if (page.parentId !== page.spaceId) {
      allShare.push(id);
      return;
    }

    const spacePermission = rolePermissions.find((o) => o.type === PermissionType.SPACE);
    // 有 space 类型权限的展示在 空间页面
    if (!isGuest && spacePermission) {
      team.push(id);
      return;
    }

    const ownPermission = rolePermissions.find((o) => o.userId === userId);
    // 有且只有自己有权限的展示在 个人页面
    if (ownPermission && rolePermissions.length === 1) {
      person.push(id);
      return;
    }

    // 其他的展示在 共享页面
    allShare.push(id);
  });

  const share: string[] = [];

  if (currentSpaceView) {
    // 按 spaceView.sharePages 顺序排序
    currentSpaceView.sharePages.forEach((uuid) => allShare.includes(uuid) && share.push(uuid));
  }
  // 剩余的排在后面
  allShare.forEach((uuid) => !share.includes(uuid) && share.push(uuid));

  return { team, person, share };
};
