import type { CardSize, CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { cache } from 'src/redux/store';
import { updateViewFormat } from '../block/use-update-collection-view';

/**
 * for bitable
 * 更新 card size
 */
export const useUpdateCardSize = () => {
  const updateCardSize = (viewId: string, value: CardSize) => {
    const { collectionViews } = cache;
    const view = collectionViews[viewId];
    if (!view) return;

    const newFormat: CollectionViewDTO['format'] = { boardCardSize: value };

    switch (view.type) {
      case CollectionViewType.BOARD:
        newFormat.boardCardSize = value;
        break;
      case CollectionViewType.GALLERY:
        newFormat.galleryCardSize = value;
        break;
      default:
    }

    updateViewFormat(viewId, newFormat);
  };
  return useCallback(updateCardSize, []);
};
