import { useCallback } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import type { ViewModeType } from 'src/redux/types';

import { useTransaction } from '../use-transaction';

export const useChangeViewMode = (id: string) => {
  const transaction = useTransaction();
  return useCallback(
    (status: ViewModeType) => {
      transaction(() => {
        updateBlock(id, { data: { viewMode: status } });
      });
    },
    [id, transaction]
  );
};
