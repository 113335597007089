import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';

export abstract class BiValueType<T = unknown> {
  abstract fromSegments(segments: SegmentDTO[] | undefined): T | undefined;
  abstract toSegments(value: T): SegmentDTO[] | undefined;
  abstract isNonNullEmpty(value: T): boolean;
  isEmpty(value: T | undefined): boolean {
    return value === undefined || this.isNonNullEmpty(value);
  }
  abstract compare(value1: T, value2: T): number;
  // NOTE: 所有的 resolver(s) 必须放到 userSelector 中执行
  useRender(_useSelector: <T>(f: () => T) => T): ReactNode {
    return null;
  }
}

export abstract class BiArrayValueType<T = unknown> extends BiValueType<T[]> {
  isNonNullEmpty(value: T[]) {
    return value.length === 0;
  }
  compare(value1: T[], value2: T[]): number {
    const iter1 = value1[Symbol.iterator]();
    const iter2 = value2[Symbol.iterator]();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
    while (true) {
      const r1 = iter1.next();
      const r2 = iter2.next();
      if (r1.done && r2.done) {
        return 0;
      }
      if (r1.done) {
        return -1;
      }
      if (r2.done) {
        return 1;
      }

      const r = this.compareElement(r1.value, r2.value);
      if (r !== 0) return r;
    }
  }
  abstract compareElement(value1: T, value2: T): number;
}
