import { cx } from '@flowus/common/cx';
import type { ActivityDetailDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Fragment } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOpenNoticeGuide } from 'src/components/activities/use-open-notice-guide';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useNewUserTask } from 'src/hooks/activities/use-activity';
import { Modals } from 'src/modals';
import { bizTracker } from 'src/utils/biz-tracker';
import { createAnchorDomClick } from 'src/utils/dom';
import { TemplateCenter } from 'src/views/main/template';
import { followCursor } from 'tippy.js';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { ResourceCenter } from '../../resource-center';

/** 新手任务 */
export const NewUserTaskList: FC = () => {
  const openNoticeGuide = useOpenNoticeGuide();
  const { basicList, advancedList } = useNewUserTask();
  const closeSettingModal = useCloseSettingModal();
  const openModal = useOpenModal();

  if (
    (basicList.length === 0 && advancedList.length === 0) ||
    [...basicList, ...advancedList].every((it) => it.status === 'completed')
  ) {
    return null;
  }

  const openActivity = (item: ActivityDetailDTO) => {
    if (!item.uuid) return;
    bizTracker.event('online_activity_click', { activity_id: item.uuid });
    if (item.uuid === ActivityIds.GUIDE_FIRST_TIME_CREATED_SPACE) {
      openNoticeGuide();
      return;
    }
    createAnchorDomClick(item.link);
  };

  const renderItem = (item: ActivityDetailDTO) => {
    const isCompleted = item.status === 'completed';

    return (
      <Tooltip
        popup={item.link && item.synopsis}
        followCursor="horizontal"
        arrow
        plugins={[followCursor]}
        className={'flex px-3 py-2 cursor-pointer group'}
        onClick={() => openActivity(item)}
      >
        <Icon name="IcBullrted" size="large" className="mr-1" />
        <div className="flex-1 text-t2">
          {item.name} <span className="text-active_color text-t4">⭐️+{item.score}</span>
        </div>
        <div
          className={cx(
            'flex-shrink-0 ml-auto flex',
            !isCompleted && 'opacity-0 group-hover:opacity-100'
          )}
        >
          {isCompleted ? (
            <Icon
              name={isCompleted ? 'IcCircleCheck' : 'IcCircleCheckbox'}
              size="large"
              className={cx(isCompleted && 'text-active_color')}
            />
          ) : (
            <span
              className="text-t3 text-active_color"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                closeSettingModal();

                if (item.uuid === ActivityIds.COPY_PAGE_FROM_TEMPLATE) {
                  openModal.modal({
                    modalId: Modals.TEMPLATE_CENTER,
                    content: () => <ResourceCenter onlyShowTab="template" from="quickSetting" />,
                  });
                }
              }}
            >
              {item.uuid === ActivityIds.MOBILE_SORT_BLOCK ||
              item.uuid === ActivityIds.MOBILE_SHARE_LONG_PAGE_IMAGE
                ? '去登录'
                : '去完成'}
            </span>
          )}
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="flex my-2.5 items-center text-t2 h-9 top-0">
        解锁功能赚积分
        <span className="ml-2.5 flex items-center relative text-t3 py-px px-1.5 text-white bg-red rounded-sm after:content-[''] after:inline-block after:w-[7px] after:h-[7px] after:bg-red after:rotate-45 after:absolute after:-left-0.5">
          新用户专享
        </span>
      </div>
      <div className="flex">
        <div className="w-full py-2.5 border border-black/10 rounded">
          <div className="h-9 flex items-center px-3 text-t2 text-grey3">基础入门</div>
          {basicList.map((item) => (
            <Fragment key={item.uuid}>{renderItem(item)}</Fragment>
          ))}
        </div>
        <div className="w-4 flex-shrink-0" />
        <div className="w-full py-2.5 border border-black/10 rounded">
          <div className="h-9 flex items-center px-3 text-t2 text-grey3">进阶操作</div>
          {advancedList.map((item) => (
            <Fragment key={item.uuid}>{renderItem(item)}</Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
