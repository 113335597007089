import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React, { useMemo, useRef } from 'react';
import { Checkbox } from 'src/components/check-box';
import { segmentsToText } from 'src/editor/utils/editor';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useTransaction } from 'src/hooks/use-transaction';
import { multiUpdateBlock } from 'src/redux/managers/block/update';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockSymbol } from '../component/block-symbol-container';
import { NodeWrapper } from '../component/node-wrapper';
import { useLastWidthBeforeEditing } from '../hook/use-last-width-before-editing';
import { useMindNodePadding } from '../hook/use-mind-node-style';
import { getDefaultPlaceHolder } from '../utils/mind-node-util';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

export const TodoNode: MindNodeElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['data'], ['segments', 'checked']);
  const placeHolder = getDefaultPlaceHolder(props.level);
  const updateSegments = useThrottleUpdateSegments(props.id);
  const transaction = useTransaction();

  const isEmpty = useMemo(() => {
    return !segmentsToText(block?.data.segments);
  }, [block?.data.segments]);

  const containerRef = useRef<HTMLDivElement>(null);
  const lastWidth = useLastWidthBeforeEditing(props.id, containerRef, isEmpty, props.level);
  const readonly = useReadonly(props.id);
  const { paddingRight } = useMindNodePadding(props.id, props.level);

  if (!block) return null;
  const { checked } = block.data;

  return (
    <NodeWrapper
      id={props.id}
      ref={containerRef}
      className="flex items-center"
      level={props.level}
      style={{
        paddingLeft: `8px`,
        minWidth: lastWidth,
      }}
    >
      <BlockSymbol
        symbolClassName="disable-select"
        symbol={
          <Checkbox
            size="large"
            checked={checked}
            onClick={(event) => {
              if (readonly) return;
              event.stopPropagation();
              transaction(() => {
                multiUpdateBlock({ data: { checked: !checked } }, props.id);
              });
            }}
          />
        }
        editorNode={
          <MindMapRichText
            placeholder={placeHolder}
            uuid={props.id}
            className={cx(
              'whitespace-pre-wrap break-words self-start',
              checked ? 'opacity-50' : 'no-underline',
              {
                'line-through': checked,
              }
            )}
            segments={block.data.segments}
            onChange={(
              segments: SegmentDTO[],
              prevContent: IContent,
              prevSelection: ISelection | null
            ) => {
              updateSegments(segments, [prevContent, prevSelection]);
            }}
            style={{
              paddingRight: `${paddingRight}px`,
            }}
          />
        }
      />
    </NodeWrapper>
  );
});
