export const PDF_AI_SOURCE_COVER = 'PDF_AI_SOURCE_COVER';

export const PermissionFlag = {
  PRINT: 0x04,
  MODIFY_CONTENTS: 0x08,
  COPY: 0x10,
  MODIFY_ANNOTATIONS: 0x20,
  FILL_INTERACTIVE_FORMS: 0x100,
  COPY_FOR_ACCESSIBILITY: 0x200,
  ASSEMBLE: 0x400,
  PRINT_HIGH_QUALITY: 0x800,
};

export const PresentationModeState = {
  UNKNOWN: 0,
  NORMAL: 1,
  CHANGING: 2,
  FULLSCREEN: 3,
};

export const TextLayerMode = {
  DISABLE: 0,
  ENABLE: 1,
  ENABLE_ENHANCE: 2,
};

export enum AnnotationType {
  RECTANGLE = 'rectangle',
  ELLIPSE = 'ellipse',
  POLYGON = 'polygon',
  HIGHLIGHT = 'highlight',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
  PENCIL = 'pencil',
  ERASER = 'eraser',
  MOVE = 'move',
  NONE = 'none',
}

export const AnnotationColor = {
  BLUE: '#3EC1FA',
  GREEN: '#00E79B',
  YELLOW: '#FAE143',
  ORANGE: '#FFB94E',
  PINK: '#FF5682',
  PURPLE: '#E76BEC',
};

export interface PDFCoordinate {
  x: number;
  y: number;
  xMax: number;
  yMax: number;
}

export interface PDFAnnotation {
  uuid: string;
  pageNumber: number;
  type: AnnotationType;
  pdfRects?: Record<string, PDFCoordinate[]>;
  path?: Point[];
  url?: string;
  ossName?: string;
  size?: number;
  localUrl?: string;
  text?: string;
  width?: number;
  height?: number;
  color: string;
  live?: boolean;
}

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Point {
  x: number;
  y: number;
  isTemp?: boolean;
}

export interface PDFRect {
  x: number;
  y: number;
  xMax: number;
  yMax: number;
}

export interface DrawStyle {
  lineWidth?: number;
  fillStyle?: string | CanvasGradient | CanvasPattern;
  strokeStyle?: string | CanvasGradient | CanvasPattern;
  lineCap?: CanvasLineCap;
  lineJoin?: CanvasLineJoin;
  joinShape?: 'round' | 'rectangle';
  closePath?: boolean;
}

export enum SideBarType {
  CATALOGUE = 'catalogue',
  THUMBNAIL_VIEW = 'thumbnailView',
  ANNOTATION = 'annotation',
  NONE = 'none',
}

export enum ScrollMode {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum SpreadMode {
  SINGLE = 'single',
  DOUBLE = 'double',
}
export interface PDFPreviewStatus {
  lastViewPos?: string;
  sideBarType?: SideBarType;
  sideBarWidth?: number;
  scrollMode?: ScrollMode;
  spreadMode?: SpreadMode;
}

export enum OperationType {
  ADD = 'add',
  UPDATE = 'update',
  DELETE = 'delete',
}

export interface OP {
  type: OperationType;
  uuid: string;
  patch?: Record<string, any>;
}

export interface SelectionRange {
  pdfRects: Record<string, PDFRect[]>;
  text: string;
}
