import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { LargeDefaultCardStyleClassName } from 'src/editor/component/page-block';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { RichText } from '../uikit/editable/rich-text';
import { BackgroundContainer } from './background-container';
import type { PageProps } from './types';
import { useCoverImageUrl } from './use-cover-image-url';
import { useFetchFirstTextBlock } from './use-fetch-first-text-block';
/** 大卡片模式中 填充背景样式 */
export const LargeFillBackgroundCard: FC<PageProps> = (props) => {
  const { id, ownerBlockId, illegal, showIcon, showCover, desc, isInColumn } = props;
  const firstTextBlockSegments = useFetchFirstTextBlock(props.id, !desc);
  const hasCover = !!useCoverImageUrl(id) && showCover;
  const fillBgColor = !!useObservableBlock(id, (block) => block?.backgroundColor) && !hasCover;
  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '256px',
    };
  }
  return (
    <BackgroundContainer
      blockId={id}
      fillBgColor={fillBgColor}
      showCover={hasCover}
      {...attribute}
      className={cx(
        'overflow-hidden flex flex-col relative w-[256px] h-[184px] animate-hover border',
        {
          'w-full': isInColumn,
        }
      )}
      onClick={props.onClick}
    >
      {showIcon && (
        <IconTrigger
          tooltipClassName="w-fit mt-4"
          trigger={!ownerBlockId}
          iconSize={40}
          className={'left-2.5 w-10 h-10'}
          hasOverlay={Boolean(ownerBlockId)}
          blockId={id}
          offset={[100, 0]}
          defaultIcon={<BlockDefaultIcon uuid={id} />}
        />
      )}
      <div className="h-full pb-1 px-4 overflow-auto">
        <div
          className={cx('text-ellipsis text-t1 break-words flex-shrink-0 mt-2', {
            'text-white': hasCover || fillBgColor,
          })}
        >
          {illegal ? ILLEGAL_TEXT : props.title}
        </div>
        {!illegal && desc && (
          <div
            className={cx(LargeDefaultCardStyleClassName, {
              'text-white': hasCover || fillBgColor,
            })}
            style={{ color: props.descColor }}
          >
            {desc}
          </div>
        )}
        {!illegal && !desc && firstTextBlockSegments && (
          <RichText
            className={cx(LargeDefaultCardStyleClassName, {
              'text-white': hasCover || fillBgColor,
            })}
            segments={firstTextBlockSegments}
          />
        )}
      </div>
    </BackgroundContainer>
  );
};
