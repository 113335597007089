import { Compare } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { DiscussionDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { DraftProvider, LocalStorageDraftManager } from 'src/common/components/draft-manager';
import { Icon } from 'src/common/components/icon';
import { LRUCache } from 'src/common/utils/lru-cache';
import { useIsRecord } from 'src/hooks/block/use-is-record';
import { useReadonly } from 'src/hooks/page';
import { getCurrentSpace } from 'src/hooks/space';
import { useTransaction } from 'src/hooks/use-transaction';
import { SpacePlanType } from 'src/redux/types';
import { useAllowCommentInSharePage } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { PAGE_SCOPE } from '../main/page-states';
import { CommentEditor } from './comment-editor';
import { useCommentOperations } from './comment-operations';
import { Discussion } from './discussion';
import { $showTempDiscussion, $toFocusDiscussion } from './page-states';

const commentExpandMap = new LRUCache<String, Boolean>(localStorage, 'comment-expand');

export const setPageDiscussionExpand = (pageId: string, expand: boolean) => {
  const oldValue = commentExpandMap.get(pageId);
  if (oldValue === expand) return;
  commentExpandMap.put(pageId, expand);
};

export const PageOpenDiscussions = (props: { pageId: string; isBottom?: boolean }) => {
  const draftManager = useFinalValue(() => new LocalStorageDraftManager());
  const openDiscussions = useObservableStore(
    (state) => {
      if (!state.blocks[props.pageId]?.discussions?.length) return [];
      const discussions = (state.blocks[props.pageId]?.discussions ?? [])
        .map((it) => state.discussions[it])
        .filter((it) => it != null && !it.resolved) as DiscussionDTO[];
      discussions.sort(Compare.reversed(Compare.by((it) => it.createdAt)));
      return discussions;
    },
    [props.pageId],
    { wait: 500 }
  );
  const isShare = judgeSharePage();
  const space = getCurrentSpace();
  const isRecord = useIsRecord(props.pageId);
  const defaultShowComments =
    (space.planType === SpacePlanType.team || space.planType === SpacePlanType.freeTeam) &&
    isRecord;
  const [showTempDiscussion, setShowTempDiscussion] = useAtom($showTempDiscussion, PAGE_SCOPE);
  const toFocusDiscussion = useAtomValue($toFocusDiscussion, PAGE_SCOPE);
  const pageId = useGetPageId();
  const readonly = useReadonly(pageId, false, true);
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const [expand, setExpand] = useState<Boolean>(commentExpandMap.get(props.pageId) ?? true);

  const commentCount = useMemo(() => {
    const discussion = openDiscussions.find((v) => !v.resolved);
    return discussion?.comments.length ?? 0;
  }, [openDiscussions]);

  useEffect(() => {
    const listener = () => {
      setExpand(commentExpandMap.get(props.pageId) ?? true);
    };
    commentExpandMap.addChangeListener(listener);
    return () => {
      commentExpandMap.removeChangeListener(listener);
    };
  }, [props.pageId]);

  // 这个是分享可评论的flag,只有点击评论按钮才会显示
  const commentInSharePage = isShare && allowCommentInSharePage;
  const showTempDiscussion2 =
    (!isShare && (defaultShowComments || showTempDiscussion)) || commentInSharePage;
  if (openDiscussions.length <= 0 && !showTempDiscussion2) return null;

  return (
    <DraftProvider manager={draftManager}>
      <div
        data-disable-select
        className={cx('text-t2', {
          'border-b border-b-grey6 pb-1': expand && !props.isBottom,
          'border-t border-t-grey6 pt-1 my-5': props.isBottom,
        })}
      >
        <div
          hidden={commentCount <= 0}
          className={'w-full flex items-center justify-between py-1 px-2 animate-hover my-1'}
          onClick={() => {
            commentExpandMap.put(props.pageId, !expand);
          }}
        >
          <span className={'text-t2 text-grey3'}>{`页面评论（${commentCount}）`}</span>
          <Icon
            className="cursor-pointer animate-hover"
            name={expand ? 'IcArrowHide' : 'IcArrowShow'}
            size="middle"
            onClick={() => {
              commentExpandMap.put(props.pageId, !expand);
            }}
          />
        </div>
        {expand &&
          openDiscussions.map((discussion, index) => (
            <Discussion
              hideBlockContext={true}
              showReplyEditor={index === openDiscussions.length - 1}
              readonly={readonly}
              key={discussion.uuid}
              allowCommentInSharePage={allowCommentInSharePage}
              draftKey={`PageOpenDiscussion:${discussion.uuid}`}
              discussionId={discussion.uuid}
              autoFocus={toFocusDiscussion === discussion.uuid || allowCommentInSharePage}
              onResolve={() => {
                if (openDiscussions.length === 1) {
                  setShowTempDiscussion(false);
                }
              }}
            />
          ))}
        {!readonly && openDiscussions.length === 0 && showTempDiscussion2 && (
          <PageTempDiscussion
            draftKey={`PageTempDiscussion:${props.pageId}`}
            pageId={props.pageId}
            autoFocus={(!isRecord && showTempDiscussion) || commentInSharePage}
          />
        )}
      </div>
    </DraftProvider>
  );
};

// 点击添加页面评论按钮创建的临时评论，不进入 store
export const PageTempDiscussion = (props: {
  pageId: string;
  draftKey?: string;
  autoFocus?: boolean;
  className?: string;
}) => {
  const transaction = useTransaction();
  const commentOps = useCommentOperations();
  const setToFocusDiscussion = useSetAtom($toFocusDiscussion, PAGE_SCOPE);
  const doComment = (text: SegmentDTO[] | undefined) => {
    transaction(() => {
      const discussionId = commentOps.addDiscussion({ blockId: props.pageId });
      if (discussionId == null) return;
      // 没啥比较好的办法把 focus 定过去
      setToFocusDiscussion(discussionId);
      setTimeout(() => {
        setToFocusDiscussion(null);
      }, 200);

      commentOps.appendComment({
        discussionId,
        text,
      });
    });
  };
  return (
    <div className={cx('py-1 px-2', props.className)}>
      <CommentEditor draftKey={props.draftKey} autoFocus={props.autoFocus} onComment={doComment} />
    </div>
  );
};
