import { useMindMapEngine } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { cache } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { $appUiStateCache } from 'src/services/app';
import { HideChildrenBlockList } from '../utils/const';

export const useUpdateMindNode = () => {
  const engine = useMindMapEngine();
  return useCallback(
    (
      block: Pick<NextBlock, 'type' | 'uuid' | 'parentId' | 'subNodes'>,
      width: number,
      height: number,
      isRoot: boolean,
      themeColor: string | undefined
    ) => {
      engine.updateMindNode({
        id: block.uuid,
        parentId: engine.getState().rootId === block.uuid ? '' : block.parentId,
        childrenIds:
          !isRoot && HideChildrenBlockList.includes(block.type)
            ? []
            : block.subNodes.filter(
                (subId) => cache.blocks[subId]?.type !== BlockType.PDF_ANNOTATION
              ),
        expanded: $appUiStateCache.$expandFoldRecord[block.uuid] ?? true,
        width,
        height,
        themeColor,
      });
    },
    [engine]
  );
};
