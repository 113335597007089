import { setTempImageBlockId } from 'src/bitable/board-view/card/page-content/use-page-content';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { dispatch } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';

export const fetchRecordContent = async (blockIds: string[], state: RootState) => {
  const spaceId = state.blocks[blockIds[0] ?? '']?.spaceId ?? '';

  const res = await request.editor.docsPreviewBatch({ spaceId, blockIds });

  const { blocks = {}, collectionViews } = res;
  const spaceBlock = blocks[spaceId];
  if (spaceBlock) {
    // @ts-ignore type check
    spaceBlock['type'] = 'SPACE';
  }

  const blockToImage: Record<string, string> = res.blockToImage ?? {};
  Object.keys(blockToImage).forEach((v) => {
    const image = blockToImage[v];
    if (image) {
      setTempImageBlockId(v, image);
    }
  });
  dispatch(blocksActions.update({ blocks: res.imageBlocks as Record<string, NextBlock> }));
  dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
  collectionViews && dispatch(collectionViewsActions.update(collectionViews));

  return { ...res, blocks };
};
export const fetchSharePreviewContent = async (
  blockId: string,
  spaceId?: string,
  previewCount?: number
) => {
  spaceId = spaceId ?? '';
  const res = await request.editor.docsSharePagePreview({ spaceId, blockId, previewCount });

  const { blocks = {}, collectionViews } = res;
  const spaceBlock = blocks[spaceId];
  if (spaceBlock) {
    // @ts-ignore type check
    spaceBlock['type'] = 'SPACE';
  }
  dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
  collectionViews && dispatch(collectionViewsActions.update(collectionViews));

  return { ...res, blocks };
};
