import { clamp } from 'lodash-es';

import type { Rect } from '../type';

/**
 * 只要 node 的 style 存在 left top width height 即可使用此方法，
 * @param node 需要被调整的节点
 * @param onResizeEnd 调整结束后的回调
 */

interface Params {
  source: HTMLElement;
  container?: HTMLElement;
  onResizeEnd?: (rect: Rect) => void;
  onResizing?: (rect: Rect) => void;
}
export const addResize = (params: Params) => {
  const { source, onResizeEnd, onResizing, container = document.body } = params;
  const resizeContainer = document.createElement('div');

  resizeContainer.classList.add('resizeContainer');
  resizeContainer.style.cursor = 'move';

  resizeContainer.innerHTML = `
    <div style="position: absolute; user-select: none; width: 100%; height: 10px; top: -5px; left: 0px; cursor: row-resize;"></div>
    <div style="position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; right: -5px; cursor: col-resize;"></div>
    <div style="position: absolute; user-select: none; width: 100%; height: 10px; bottom: -5px; left: 0px; cursor: row-resize;"></div>
    <div style="position: absolute; user-select: none; width: 10px; height: 100%; top: 0px; left: -5px; cursor: col-resize;"></div>
    <div style="position: absolute; user-select: none; width: 20px; height: 20px; right: -10px; top: -10px; cursor: ne-resize;"></div>
    <div style="position: absolute; user-select: none; width: 20px; height: 20px; right: -10px; bottom: -10px; cursor: se-resize;"></div>
    <div style="position: absolute; user-select: none; width: 20px; height: 20px; left: -10px; bottom: -10px; cursor: sw-resize;"></div>
    <div style="position: absolute; user-select: none; width: 20px; height: 20px; left: -10px; top: -10px; cursor: nw-resize;"></div>
  `;

  const handleMouseDown = (event: MouseEvent) => {
    if (event.button !== 0) return;
    event.stopPropagation();
    event.preventDefault(); // 避免框选文本

    const target = event.target as HTMLElement;
    const { cursor } = target.style;

    const mouseDownPos = {
      x: event.clientX,
      y: event.clientY,
    };

    const nodeRect = {
      x: parseInt(source.style.left, 10),
      y: parseInt(source.style.top, 10),
      width: parseInt(source.style.width, 10),
      height: parseInt(source.style.height, 10),
      bottom: parseInt(source.style.height, 10) + parseInt(source.style.top, 10),
      right: parseInt(source.style.width, 10) + parseInt(source.style.left, 10),
    };

    const boundary = container.getBoundingClientRect();
    const handleMouseMove = (event: MouseEvent) => {
      const clientY = clamp(event.clientY, boundary.top, boundary.bottom);
      const clientX = clamp(event.clientX, boundary.left, boundary.right);
      const deltaX = clientX - mouseDownPos.x;
      const deltaY = clientY - mouseDownPos.y;

      const resizeTop = () => {
        const top = clamp(nodeRect.y + deltaY, 0, nodeRect.bottom);
        source.style.top = `${top}px`;
        source.style.height = `${Math.abs(nodeRect.height - deltaY)}px`;
      };
      const resizeBottom = () => {
        const top =
          deltaY < 0 && Math.abs(deltaY) > nodeRect.height
            ? nodeRect.y - (Math.abs(deltaY) - nodeRect.height)
            : nodeRect.y;
        source.style.top = `${top}px`;
        source.style.height = `${Math.abs(nodeRect.height + deltaY)}px`;
      };
      const resizeLeft = () => {
        const left = clamp(nodeRect.x + deltaX, 0, nodeRect.right);
        source.style.left = `${left}px`;
        source.style.width = `${Math.abs(nodeRect.width - deltaX)}px`;
      };
      const resizeRight = () => {
        const left =
          deltaX < 0 && Math.abs(deltaX) > nodeRect.width
            ? nodeRect.x - (Math.abs(deltaX) - nodeRect.width)
            : nodeRect.x;

        source.style.left = `${left}px`;
        source.style.width = `${Math.abs(nodeRect.width + deltaX)}px`;
      };
      const move = () => {
        const top = clamp(nodeRect.y + deltaY, 0, boundary.height - nodeRect.height);
        const left = clamp(nodeRect.x + deltaX, 0, boundary.width - nodeRect.width);

        source.style.top = `${top}px`;
        source.style.left = `${left}px`;
      };

      switch (cursor) {
        case 'row-resize': {
          if (target.style.top) {
            resizeTop();
          } else {
            resizeBottom();
          }
          break;
        }
        case 'col-resize': {
          if (target.style.left) {
            resizeLeft();
          } else {
            resizeRight();
          }
          break;
        }
        case 'ne-resize': {
          resizeTop();
          resizeRight();
          break;
        }
        case 'se-resize': {
          resizeRight();
          resizeBottom();
          break;
        }
        case 'sw-resize': {
          resizeLeft();
          resizeBottom();
          break;
        }
        case 'nw-resize': {
          resizeTop();
          resizeLeft();
          break;
        }
        case 'move': {
          move();
          break;
        }
        default:
          break;
      }

      const newRect = {
        x: parseInt(source.style.left, 10),
        y: parseInt(source.style.top, 10),
        width: parseInt(source.style.width, 10),
        height: parseInt(source.style.height, 10),
      };

      onResizing?.(newRect);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      const newRect = {
        x: parseInt(source.style.left, 10),
        y: parseInt(source.style.top, 10),
        width: parseInt(source.style.width, 10),
        height: parseInt(source.style.height, 10),
      };

      if (
        Math.abs(newRect.x - nodeRect.x) < 2 &&
        Math.abs(newRect.y - nodeRect.y) < 2 &&
        Math.abs(newRect.width * newRect.height - nodeRect.width * nodeRect.height) < 4
      ) {
        return;
      }

      onResizeEnd?.(newRect);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  resizeContainer.addEventListener('mousedown', handleMouseDown);
  source.appendChild(resizeContainer);
};

// window.getComputedStyle 的性能差吗？
