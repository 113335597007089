import type { CollectionSchema } from '@next-space/fe-api-idl';
import type { Store } from '@reduxjs/toolkit';
import { UPDATE_BLOCK } from '../../actions';
import { dispatch as dispatch0, getState as getState0 } from '../../store';
import type { NextBlock } from '../../types';
import { updateTime } from './update-time';

type Patch = Partial<Omit<NextBlock, 'uuid'>>;

export const multiUpdateBlock = (patch: Patch, uuid?: string) => {
  const { ui } = getState0();
  const { selectedBlocks } = ui;
  if (uuid && selectedBlocks.findIndex((v) => v.blockId === uuid) === -1) {
    updateBlock(uuid, patch);
  } else {
    selectedBlocks.forEach((selectBlock) => updateBlock(selectBlock.blockId, patch));
  }
};

/** 更新 block */
export const updateBlock = (
  uuid: string,
  patch: Patch,
  ignoreOp = false,
  store?: Store<ReturnType<typeof getState0>>
) => {
  const getState = store ? store.getState.bind(store) : getState0;
  const dispatch = store ? store.dispatch.bind(store) : dispatch0;

  const { blocks } = getState();
  const block = blocks[uuid];
  if (!block) return;

  dispatch(
    UPDATE_BLOCK({
      uuid,
      patch,
      ignoreOp,
    })
  );

  // get-calendar-render-time 这个文件用 updateAt 做 key 了缓存，updateAt 需要在更新值之后更新
  if (!ignoreOp) {
    // ignore op不应该更新时间，否则服务器和本地时间不一致
    updateTime(uuid, ignoreOp, store);
  }
};

export const updateSchema = (
  collectionId: string,
  schema?: Record<string, CollectionSchema>,
  options?: {
    ignoreOp?: boolean;
  }
) => {
  dispatch0(
    UPDATE_BLOCK({
      uuid: collectionId,
      patch: { data: { schema } },
      path: ['data', 'schema'],
      ignoreOp: options?.ignoreOp,
    })
  );

  if (!options?.ignoreOp) {
    updateTime(collectionId);
  }
};
