import { cx } from '@flowus/common/cx';
import type { IconSize } from '@flowus/common/icon-svg/types';
import type { Placement } from '@popperjs/core';
import type { FC, ReactNode } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { publicLink } from 'src/common/const';
import { createAnchorDomClick } from 'src/utils/dom';

export const helperLink = publicLink;

export const HelperIcon: FC<{
  className?: string;
  link?: string;
  popup?: ReactNode;
  size?: IconSize;
  placement?: Placement;
}> = ({ className, link, popup, size = 'small', placement }) => {
  if (__BUILD_IN__) return null;
  return (
    <Tooltip placement={placement} popup={popup} interactive className="inline-block">
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Icon
          name="IcInviteHelp"
          size={size}
          className={cx('ml-1 text-grey4 animate-click', className)}
        />
      </a>
    </Tooltip>
  );
};

export const HelperIconBox: FC<{
  iconClassName?: string;
  className?: string;
  link?: string;
  beforeIcon?: boolean;
  linkContainer?: boolean;
  popup?: ReactNode;
  showBuildin?: boolean;
}> = ({
  iconClassName,
  showBuildin,
  className,
  children,
  link,
  beforeIcon,
  popup,
  linkContainer,
}) => {
  if (__BUILD_IN__ && !showBuildin) return null;

  const Helper = (
    <HelperIcon
      popup={popup}
      link={link}
      className={cx(
        'text-grey4 flex items-center',
        beforeIcon ? 'mr-1' : 'ml-1',
        linkContainer && 'pointer-events-none',
        iconClassName
      )}
    />
  );
  const content = (
    <>
      {beforeIcon && Helper}
      {children}
      {!beforeIcon && Helper}
    </>
  );
  if (linkContainer) {
    return (
      <div
        className={cx('flex items-center animate-click text-ellipsis', className)}
        onClick={() => {
          if (link) {
            createAnchorDomClick(link);
          }
        }}
      >
        {content}
      </div>
    );
  }

  return <div className={cx('flex items-center text-ellipsis', className)}>{content}</div>;
};
