export class Deferred<T = void> {
  resolve!: T extends undefined
    ? (value?: unknown | PromiseLike<unknown>) => void
    : (value: T | PromiseLike<T>) => void;

  reject!: (error: Error) => void;

  promise = new Promise<T>((_resolve, _reject) => {
    // @ts-ignore: ignore
    this.resolve = _resolve;
    this.reject = _reject;
  });
}
