import { cx } from '@flowus/common/cx';
import { Tooltip } from '@flowus/common/tooltip';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { useRelationMap } from 'src/bitable/relation-context';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { useSubitemStyle } from 'src/hooks/block/use-open-subitem';

interface Props {
  recordId: string;
}

export const RelationChildPage: FC<Props> = (props) => {
  const { parent2ChildMap } = useRelationMap();
  const { viewId } = useBitable();
  const childIds = parent2ChildMap?.get(props.recordId);
  const subitemStyle = useSubitemStyle(viewId);
  if (subitemStyle !== 'Card') return null;
  return (
    <>
      {childIds?.map((uuid: string) => {
        return (
          <Tooltip
            key={uuid}
            popup="按住Alt点击可右侧打开"
            delay={{ open: 1000 }}
            flexCenter={false}
            className="inline"
          >
            <InlinePage
              interactable
              uuid={uuid}
              autoIconSize={true}
              className={cx('text-t4 leading-none p-0 mr-2 whitespace-nowrap text-ellipsis')}
            />
          </Tooltip>
        );
      })}
    </>
  );
};
