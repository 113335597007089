import { useEffect, useState } from 'react';
import type { BehaviorSubject } from 'rxjs';
import { fastEqual } from '../utils/tools';
import { useStateRef } from './react-utils';

export function useObservable<T, S>(
  obs$: BehaviorSubject<T>,
  selector: (nextState: T) => S,
  dep: any[],
  defaultValue: S
) {
  const [state, setState, stateRef] = useStateRef(defaultValue);
  const [deps, setDeps] = useState(dep);

  useEffect(() => {
    if (!fastEqual(deps, dep)) {
      setDeps(dep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dep]);

  useEffect(() => {
    const subscription = obs$.subscribe((nextState) => {
      const newState = selector(nextState);
      if (!fastEqual(stateRef.current, newState)) {
        setState(newState);
      }
    });

    return () => {
      if (subscription && !subscription.closed) {
        subscription.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return state;
}
