import type { FC } from 'react';
import { useRef } from 'react';
import { InputWithButton } from 'src/common/components/input-with-button';
import { useOpenModal } from 'src/common/components/next-modal';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import type { ReadonlyProp } from 'src/redux/types';
import { BlockEmptyView } from '../block-empty-view';

interface Props {
  defaultOpenPanel: boolean;
  onButtonClick?(url: string): void;
}
export const EmptyBookmark: FC<Props & ReadonlyProp> = (props) => {
  const openModal = useOpenModal();
  const updateTask = useUpdateTask();
  const inputRef = useRef('');
  return (
    <BlockEmptyView
      readonly={props.readonly}
      defaultOpenPanel={props.defaultOpenPanel}
      onClick={(e) => {
        openModal.dropdown({
          popcorn: e.currentTarget,
          placement: 'bottom',
          content: ({ onCloseModal }) => {
            return (
              <InputWithButton
                onButtonClick={() => {
                  if (!inputRef.current.trim()) {
                    return; // do nothing if no input value
                  }
                  props.onButtonClick?.(inputRef.current.trim());
                  void updateTask(ActivityIds.CREATE_WEB_BOOKMARK, ActivitiesListType.advancedList);
                  onCloseModal();
                }}
                placeholder={'https://www.example.com'}
                onChange={(v) => {
                  inputRef.current = v;
                }}
              />
            );
          },
        });
      }}
      iconProp={{ name: 'IcUploadBookmark', size: 'normal' }}
      text={'添加网址书签'}
      className={props.readonly ? 'cursor-default' : 'cursor-pointer'}
    />
  );
};
