import { useCallback } from 'react';
import { batch } from 'react-redux';
import { HISTORY_END, HISTORY_START, TRANSACTION_FIRE, TRANSACTION_FLUSH } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import type { RootState } from 'src/redux/types';

export const transaction = <T>(
  dispatchAction: () => T,
  opt?: {
    noThrottle?: boolean;
    state?: RootState;
  }
) => {
  let res;
  batch(() => {
    const { state, noThrottle } = opt || {};
    // 由于undo是基于snapshot的，如果在调用transaction之前有一个ignore op的操作A，snapshot就会有变更。
    // 为了使用snapshot不使用A之后的数据，这里可以扩展成可以指定state
    dispatch(HISTORY_START({ state }));
    res = dispatchAction();
    dispatch(HISTORY_END());
    if (noThrottle) {
      dispatch(TRANSACTION_FIRE());
    } else {
      dispatch(TRANSACTION_FLUSH());
    }
  });
  return res as unknown as T;
};

export const useTransaction = () => {
  return useCallback(transaction, []);
};

/** 不要跟上面的合并，会有其他bug */
export const useAsyncTransaction = () => {
  const transaction = <T>(
    dispatchAction: () => Promise<T>,
    noThrottle = false,
    state?: RootState
  ) => {
    let res;
    void batch(async () => {
      // 由于undo是基于snapshot的，如果在调用transaction之前有一个ignore op的操作A，snapshot就会有变更。
      // 为了使用snapshot不使用A之后的数据，这里可以扩展成可以指定state
      dispatch(HISTORY_START({ state }));
      const timer = setTimeout(() => {
        dispatch(HISTORY_END());
      }, 20 * 1000);
      res = await dispatchAction();
      clearTimeout(timer);
      dispatch(HISTORY_END());
      if (noThrottle) {
        dispatch(TRANSACTION_FIRE());
      } else {
        dispatch(TRANSACTION_FLUSH());
      }
    });
    return res as unknown as T;
  };

  return useCallback(transaction, []);
};
