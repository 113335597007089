import { DateFormat, TimeFormat } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useMemo } from 'react';
import type { Option } from 'src/components/option-item';
import { OptionItem } from 'src/components/option-item';

interface Props {
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  onSelectDateFormat: (format: DateFormat) => void;
  onSelectTimeFormat: (format: TimeFormat) => void;
  disableTimeFormat?: boolean;
}
const dateFormatOptions: Option[] = [
  { id: DateFormat.LL, name: '完整日期' },
  { id: DateFormat.YYYY_MM_DD, name: '年/月/日' },
  { id: DateFormat.DD_MM_YYYY, name: '日/月/年' },
  { id: DateFormat.MM_DD_YYYY, name: '月/日/年' },
  { id: DateFormat.RELATIVE, name: '相对日期' },
];
const timeFormatOptions: Option[] = [
  { id: TimeFormat.H_MM_A, name: '12 小时' },
  { id: TimeFormat.H_MM, name: '24 小时' },
];

export const FormatSetting: FC<Props> = (props) => {
  const dateFormatSelectedIndex = useMemo(() => {
    return dateFormatOptions.findIndex((v) => v.id === props.dateFormat);
  }, [props.dateFormat]);
  const timeFormatSelectedIndex = useMemo(() => {
    return timeFormatOptions.findIndex((v) => v.id === props.timeFormat);
  }, [props.timeFormat]);

  return (
    <div className="next-modal px-4 py-1 w-[250px]">
      <OptionItem
        name="日期格式"
        selectedIndex={dateFormatSelectedIndex}
        options={dateFormatOptions}
        onSelect={(option) => {
          props.onSelectDateFormat(option.id as DateFormat);
        }}
      />
      <OptionItem
        name="时间格式"
        disable={props.disableTimeFormat}
        selectedIndex={timeFormatSelectedIndex}
        options={timeFormatOptions}
        onSelect={(option) => {
          props.onSelectTimeFormat(option.id as TimeFormat);
        }}
      />
    </div>
  );
};
