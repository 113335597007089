import { Uuid } from '@flowus/common';
import { sleep } from '@flowus/common/async';
import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { PermissionRole, TextType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import { useEffect, useRef } from 'react';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { useStateWithDraft } from 'src/common/components/draft-manager';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useSize } from 'src/common/utils/use-size';
import { UserAvatar } from 'src/components/user-avatar';
import type { RichTextEdit as RichTextEdit0 } from 'src/editor/editor/uikit/editable';
import { newEditorModelWithoutRegister } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { INLINE_KEYDOWN_OPTION, SHARE_COMMENT_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentUser } from 'src/hooks/user';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { isPageLike } from 'src/utils/block-type-utils';
import { useGetPageId } from 'src/utils/getPageId';

let RichTextEdit: typeof RichTextEdit0;

export const antiCycleSet_RichTextEdit = (RichTextEdit0: typeof RichTextEdit) => {
  RichTextEdit = RichTextEdit0;
};

export type RemoteAction =
  | { type: 'focus'; payload: never }
  | {
      type: 'reply';
      payload: {
        to: string;
      };
    }
  | { type: 'resolve' };

const addMention = (value: SegmentDTO[] | undefined, userId: string) => {
  const newValue = value == null ? [] : value.slice(0);
  newValue.push({
    type: TextType.USER,
    uuid: userId,
    enhancer: {},
    text: '',
  });
  return newValue;
};

export const CommentEditor = (props: {
  draftKey?: string;
  noSaveDraft?: boolean;
  showAvatar?: boolean;
  initialText?: SegmentDTO[] | undefined;
  remoteAction?: Observable<RemoteAction>;
  autoFocus?: boolean;
  onComment?: (content: SegmentDTO[] | undefined) => void;
}) => {
  const noSaveDraft = props.noSaveDraft ?? false;
  const showAvatar = props.showAvatar ?? true;

  // 如果没有页面读权限，则不显示@
  const pageId = useGetPageId();
  const blockType = useObservableBlock(pageId, (state) => state?.type);
  const { roleWithoutPublic } = usePermissions(pageId);
  const canAt = roleWithoutPublic !== PermissionRole.NONE && isPageLike(blockType);

  const model = useFinalValue(() => newEditorModelWithoutRegister());
  const divRef = useRef<HTMLDivElement>(null);
  const size = useSize(divRef, 'height');
  const [value, setValue] = useStateWithDraft(props.draftKey, props.initialText, noSaveDraft);
  useEffect(() => {
    if (props.remoteAction == null) return;
    const subscription = props.remoteAction.subscribe({
      next: (action) => {
        switch (action.type) {
          case 'focus': {
            void model.requestFocus();
            break;
          }
          case 'reply': {
            setValue((value) => addMention(value, action.payload.to));
            setTimeout(() => {
              model.performChange((ctx) => {
                ctx.select(model.content.length).collapse('focus');
              });
              void model.requestFocus();
            });
            break;
          }
          default: {
            // Unreachable
          }
        }
      },
    });
    return () => subscription.unsubscribe();
  }, [props.remoteAction, model, setValue]);
  const user = useCurrentUser();
  const uuid = useFinalValue(() => Uuid.v4());
  const disabled = segmentsToText(value) === '';
  const isActive = useObservable(
    () => model.onActiveChange.pipe(map(() => model.active)),
    model.active
  );
  return (
    <div data-is-comment className="flex items-start text-t2">
      {showAvatar && <UserAvatar user={user} className="text-t2 mt-1 w-6 h-6" />}
      <div
        className={cx(
          'relative flex-1 min-w-0 px-1 ml-1',
          css({
            selectors: {
              '&:focus-within .biz-btns': {
                opacity: 1,
              },
            },
          }),
          (size.height ?? 0) > 120 && 'pb-2'
        )}
      >
        <div
          ref={divRef}
          className={cx(
            'max-h-[166px] overflow-y-auto px-1 py-1.5 rounded',
            css({
              selectors: {
                '&:hover:not(:focus-within)': {
                  backgroundColor: 'rgba(0, 0, 0, 0.03)',
                },
              },
            })
          )}
        >
          <RichTextEdit
            className={cx(
              'whitespace-pre-wrap biz-inline-editable',
              css({
                selectors: {
                  ':not(.biz-placeholder-shown)::after': {
                    display: 'inline-block',
                    content: '""',
                    width: '100px',
                  },
                },
              })
            )}
            autoFocus={props.autoFocus}
            autoFocusCaretToEnd
            editorModel={model}
            placeholder={
              isActive ? '在此发表评论（Enter 发送，Shift+Enter 换行）' : '在此发表评论...'
            }
            uuid={uuid}
            toolbar
            segments={value}
            keydownOption={canAt ? INLINE_KEYDOWN_OPTION : SHARE_COMMENT_KEYDOWN_OPTION}
            onChange={setValue}
            onKeyDownCapture={(e) => {
              if (isHotkey('mod+x')(e)) {
                e.stopPropagation();
              }
            }}
            onKeyDown={(event) => {
              let stopPropagation;
              if (isHotkey('shift+enter')(event)) {
                stopPropagation = true;
                event.preventDefault();
                model.performChange((ctx) => {
                  ctx.insert('\n');
                });
                void model.scrollCaretIntoViewIfNeeded();
              } else if (isHotkey('enter')(event)) {
                stopPropagation = true;
                if (segmentsToText(value) !== '') {
                  setValue(undefined);
                  props.onComment?.(value);
                }
              } else if (isHotkey('mod+v')(event)) {
                stopPropagation = true;
                // 需要等 pasteHandler 处理 完了跳光标
                setTimeout(() => {
                  void model.scrollCaretIntoViewIfNeeded();
                }, 100);
              }
              if (stopPropagation) {
                event.stopPropagation();
              }
            }}
          />
        </div>
        <div className="absolute right-1 bottom-2 biz-btns flex opacity-0">
          {canAt && (
            <Tooltip popup={disabled ? '' : '提及日期、页面、或成员'}>
              <button
                onClick={async (e) => {
                  e.stopPropagation();
                  await model.requestFocus();

                  // 不确定有没有case会需要，先加上
                  await sleep(0);

                  model.inputInsert('@', 'input');
                }}
                className="text-grey3 animate-hover flex"
              >
                <Icon size="normal" name={'IcTitleEmail'} className="w-6 h-6" />
              </button>
            </Tooltip>
          )}
          <Tooltip popup={disabled ? '' : '发送评论'}>
            <button
              onClick={() => {
                if (!disabled) {
                  setValue(undefined);
                  props.onComment?.(value);
                }
              }}
              disabled={disabled}
              className={cx('flex', disabled ? 'text-grey4' : 'text-active_color')}
            >
              <Icon size="normal" name={'IcCommentSend'} className="w-6 h-6" />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
