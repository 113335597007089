import { createContext, useContext } from 'react';
import type { TableIndexRanges, TableSpans } from './types';

export const TableContext = createContext<{
  recordId: string;
  tableId: string;
  spans: TableSpans;
  indexRanges: TableIndexRanges;
}>({
  recordId: '',
  tableId: '',
  spans: {},
  indexRanges: [],
});

export const useTableContext = () => {
  return useContext(TableContext);
};
