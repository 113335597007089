/**
 * Grid Layout 组件, 按需渲染 Cell
 */
import { useGrid } from './context';
import { useCells } from './use-cells';

export const Content = () => {
  const { totalWidth, totalHeight, contentRef } = useGrid();

  const cells = useCells();

  return (
    <div
      className="relative cell-container"
      ref={contentRef}
      style={{ width: totalWidth, height: totalHeight }}
    >
      {cells.length > 0 ? (
        cells
      ) : (
        <span className="relative pl-2 text-grey3 text-t3 top-1">表格内容为空</span>
      )}
    </div>
  );
};
