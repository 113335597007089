import type { Instance } from '@popperjs/core';
import { createContext } from 'react';
import { BehaviorSubject } from 'rxjs';
import type { ModalSchema } from '../type';

export const globalModalPopper = new Map<string, WeakRef<Instance>>();

export const NextModalContext = createContext<{
  state: BehaviorSubject<ModalSchema.NodesType>;
  config?: ModalSchema.NextModalProviderConfig;
}>({
  state: new BehaviorSubject({}),
});
