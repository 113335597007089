import { fastEqual } from '@flowus/common/utils/tools';
import { throttle } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { OVERSCAN } from 'src/bitable/const';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { cache, dispatch } from 'src/redux/store';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { useTableInfo } from './hook';
import { TableCell } from './table-cell';
import { TableContext } from './table-context';
import type { TableProps, TableSpans } from './types';

export const Table: FC<TableProps> = memo(({ tableId, tableRef, indexRanges }) => {
  const { subNodes, tableBlockColumnOrder, tableBlockRanges } = useTableInfo(tableId);
  const pageRef = usePageScrollRef();

  const spans = useMemo(() => {
    // 根据 tableBlockRanges 把跨行跨列的单元格的 colSpan 和 rowSpan 设置为 0
    return tableBlockRanges.reduce((result, { start, end }) => {
      if (!start || !end) return result;
      const [startRowId, startColumnId] = start;
      const [endRowId, endColumnId] = end;
      if (!startRowId || !startColumnId || !endRowId || !endColumnId) return result;
      const startRowIndex = subNodes.indexOf(startRowId);
      const startColumnIndex = tableBlockColumnOrder.indexOf(startColumnId);
      const endRowIndex = subNodes.indexOf(endRowId);
      const endColumnIndex = tableBlockColumnOrder.indexOf(endColumnId);

      result[startRowId] = {
        ...(result[startRowId] ?? {}),
        [startColumnId]: {
          colSpan: endColumnIndex - startColumnIndex + 1,
          rowSpan: endRowIndex - startRowIndex + 1,
        },
      };

      const rowIds = subNodes.slice(startRowIndex, endRowIndex + 1);
      const columnIds = tableBlockColumnOrder.slice(startColumnIndex, endColumnIndex + 1);

      for (const rowId of rowIds) {
        for (const columnId of columnIds) {
          if (rowId === startRowId && columnId === startColumnId) continue;
          result[rowId] = { ...(result[rowId] ?? {}), [columnId]: { colSpan: 0, rowSpan: 0 } };
        }
      }

      return result;
    }, {} as TableSpans);
  }, [subNodes, tableBlockColumnOrder, tableBlockRanges]);

  const onMouseMove = useMemo(() => {
    return throttle(
      (event: MouseEvent) => {
        const { hoveringCell, draggingStart, draggingBarType } = cache.simpleTable;
        if (draggingStart || draggingBarType) return;
        const target = event.target as HTMLElement;
        const td = target.closest('td');
        if (td) {
          const rowIndex = Number(td.dataset.tableRowIndex ?? 0);
          const columnIndex = Number(td.dataset.tableColumnIndex ?? 0);
          if (
            !hoveringCell ||
            hoveringCell.rowIndex !== rowIndex ||
            hoveringCell.columnIndex !== columnIndex
          ) {
            dispatch(
              simpleTableActions.update({
                activityTableId: tableId,
                hoveringCell: { rowIndex, columnIndex },
              })
            );
          }
        }
      },
      50,
      { leading: true, trailing: true }
    );
  }, [tableId]);

  const onMouseLeave = useMemo(() => {
    return throttle(
      () => {
        const { draggingStart } = cache.simpleTable;
        if (draggingStart) return;
        dispatch(simpleTableActions.update({ hoveringCell: undefined }));
      },
      50,
      { leading: true, trailing: true }
    );
  }, []);

  if (!pageRef.current) return null;

  return (
    <TableVirtuoso
      className="mt-3 ml-3 mr-1 mb-7"
      overscan={OVERSCAN}
      computeItemKey={(index) => index}
      increaseViewportBy={tableBlockRanges.length ? Infinity : pageRef.current.clientHeight}
      customScrollParent={pageRef.current}
      totalCount={subNodes.length}
      components={{
        Table: memo(
          (props) => (
            <table
              {...props}
              className="w-max"
              ref={tableRef}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            />
          ),
          fastEqual
        ),
        // @ts-ignore 无法正确推断类型
        TableRow: memo((props) => {
          const index = props['data-index'];
          const recordId = subNodes[index];

          if (!recordId) return null;

          return (
            <TableContext.Provider value={{ tableId, recordId, spans, indexRanges }}>
              <tr {...props} className="align-baseline"></tr>
            </TableContext.Provider>
          );
        }, fastEqual),
      }}
      itemContent={(rowIndex) => {
        const recordId = subNodes[rowIndex] ?? '';

        return tableBlockColumnOrder.map((propertyId, columnIndex) => {
          const span = spans[recordId]?.[propertyId] ?? {};

          if (span.colSpan === 0 || span.rowSpan === 0) {
            return null;
          }

          return (
            <TableCell
              span={span}
              key={`${recordId}-${propertyId}`}
              propertyId={propertyId}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
            />
          );
        });
      }}
    />
  );
}, fastEqual);
