import { SpacePlanType } from 'src/redux/types';

import { useCurrentSpace } from './use-current-space';

export const getIsTeamSpace = (planType?: SpacePlanType | string) => {
  return [SpacePlanType.freeTeam, SpacePlanType.team, SpacePlanType.smallTeam].includes(
    planType as SpacePlanType
  );
};

export const useIsTeamSpace = () => {
  const currentSpace = useCurrentSpace();
  const isTeamSpace = getIsTeamSpace(currentSpace.planType);
  return isTeamSpace;
};
