import * as Sentry from '@sentry/react';
import type { FC } from 'react';
import { Suspense, useState } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { ErrorTip } from 'src/error-tip';

export const SentryErrorBoundary: FC = (props) => {
  const { children } = props;
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Sentry.ErrorBoundary
      onError={(err) => setErrorMessage(JSON.stringify(err.stack))}
      fallback={<ErrorTip errorMessage={errorMessage} />}
    >
      <Suspense fallback={<LoadingContainer fixed />}>{children}</Suspense>
    </Sentry.ErrorBoundary>
  );
};
