import { cx } from '@flowus/common/cx';
import { BlockStatus, BlockType } from '@next-space/fe-api-idl';
import type { IFormat, ITextComponent } from '@next-space/fe-inlined';
import color from 'color';
import { useAtomValue } from 'jotai';
import { css } from 'otion';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import type { RootState } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $blockId, BLOCK_SCOPE } from '../uikit/block-states';
import type { InlinePlugin } from './inline-plugin';

const PREFIX = 'comment/discussion-';
const UNDERLINE_COLOR = '#FFC408';
const BG_COLORS = [
  color.rgb(UNDERLINE_COLOR).alpha(0.1).toString(),
  color.rgb(UNDERLINE_COLOR).alpha(0.2).toString(),
  color.rgb(UNDERLINE_COLOR).alpha(0.3).toString(),
  color.rgb(UNDERLINE_COLOR).alpha(0.4).toString(),
  color.rgb(UNDERLINE_COLOR).alpha(0.5).toString(),
];

export const DRAFT_COMMENT_CSS = css({
  backgroundColor: BG_COLORS[0],
  textDecoration: 'underline',
  textUnderlineOffset: '0.15em',
  textDecorationColor: UNDERLINE_COLOR,
});

export const getAssociatedDiscussions = (format: IFormat) => {
  if (format.customs == null) return [];
  const keys = Object.keys(format.customs);
  const result: string[] = [];
  for (const key of keys) {
    if (key.startsWith(PREFIX) && format.customs[key] === true) {
      result.push(key.slice(PREFIX.length));
    }
  }
  return result;
};

export const blockDiscussionIdsSelector = (
  blockId: string | undefined,
  opts: { noTable: boolean; noResolved?: boolean },
  state: {
    blocks: RootState['blocks'];
    discussions: RootState['discussions'];
  } = getState()
) => {
  const result: string[] = [];
  if (blockId == null) return result;
  const block = state.blocks[blockId];
  if (opts.noTable && block?.type === BlockType.TABLE) {
    return result;
  }
  for (const discussionId of block?.discussions ?? []) {
    const discussion = state.discussions[discussionId];
    if (
      discussion &&
      discussion.status === BlockStatus.NORMAL &&
      (opts.noResolved ? !discussion?.resolved : segmentsToText(discussion.context) === '')
    ) {
      result.push(discussion.uuid);
    }
  }
  return result;
};

// 不包含划选评论
export const useBlockDiscussionIds = (opts?: { noTable?: boolean; noResolved?: boolean }) => {
  const blockId = useAtomValue($blockId, BLOCK_SCOPE);
  return useObservableStore(
    (state) => {
      if (blockId == null) return [];

      return blockDiscussionIdsSelector(
        blockId,
        {
          noTable: opts?.noTable ?? false,
          noResolved: opts?.noResolved ?? false,
        },
        state
      );
    },
    [opts, blockId]
  );
};

export const useHasUnresolvedComments = () => {
  const blockId = useAtomValue($blockId, BLOCK_SCOPE);
  return useObservableStore(
    (state) => {
      if (blockId == null) return false;

      const unresolvedCommentIds = blockDiscussionIdsSelector(
        blockId,
        {
          noTable: false,
          noResolved: true,
        },
        state
      );
      return unresolvedCommentIds.length > 0;
    },
    [blockId]
  );
};

export const newDiscussionCustom = (discussionId: string) => {
  return { [`${PREFIX}${discussionId}`]: true };
};

export const useComputeCommentStyles = (format: IFormat): React.CSSProperties => {
  const blockDiscussionIds = useBlockDiscussionIds({ noTable: true });
  const discussionIds = [...blockDiscussionIds, ...getAssociatedDiscussions(format)];
  const level = useObservableStore(
    (state) => {
      const openDiscussions = discussionIds
        .map((it) => state.discussions[it])
        .filter((it) => it != null && it.status === BlockStatus.NORMAL && !it.resolved);
      return openDiscussions.length >= 5 ? 5 : openDiscussions.length;
    },
    [discussionIds]
  );
  return level >= 1
    ? {
        backgroundColor: BG_COLORS[level - 1],
        textDecoration: 'underline',
        textUnderlineOffset: '0.15em',
        textDecorationColor: UNDERLINE_COLOR,
      }
    : {};
};

export const CommentInlinePlugin: InlinePlugin = {
  elementMeta: { tag: 'comment' },
  initialize: (api) => {
    api.overrideTextComponent((Text) => {
      const MyText: ITextComponent = ({ format, className, ...props }) => {
        const styles = useComputeCommentStyles(format);
        return <Text className={cx(className, css(styles))} {...props} format={format} />;
      };
      return MyText;
    });
  },
};
