import { useCallback, version } from 'react';
import { useSyncExternalStoreWithSelector as useSyncExternalStoreWithSelectorShim } from 'use-sync-external-store/shim/with-selector';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import type { MindMapState } from '../../core/types';
import { useMindMapEngine } from '../context';

const useSyncExternal = version.startsWith('18')
  ? useSyncExternalStoreWithSelector
  : useSyncExternalStoreWithSelectorShim;

export function useMindMapSelector<R>(
  selector: (state: MindMapState) => R,
  isEqual = Object.is
): R {
  const engine = useMindMapEngine();
  const selected = useSyncExternal(
    engine.subscribe,
    engine.getSnapShot,
    engine.getSnapShot,
    useCallback(() => selector(engine.getSnapShot()), [engine, selector]),
    isEqual
  );

  return selected;
}
