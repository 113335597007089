import { fastEqual } from '@flowus/common/utils/tools';
import { BlockType, CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import { uniqWith } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { cleanSelectBlock } from 'src/hooks/utils/clean-select';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import type { NextBlock, SelectBlock } from 'src/redux/types';
import {
  $appUiStateCache,
  setAppUiState,
  useColumnResizing,
  usePressedSpace,
} from 'src/services/app';
import { getAutoScroll } from 'src/utils/auto-scroll';
import { isCollection } from 'src/utils/block-type-utils';
import type { Rectangle } from 'src/utils/collision';
import { isIntersect } from 'src/utils/collision';
import {
  blurDocument,
  querySelectorAllFromMainContent,
  querySelectorFromMainContent,
} from 'src/utils/dom';
import { useGetPageId } from 'src/utils/getPageId';
import { useIsInRight } from 'src/utils/right-utils';

const DIS = 50;
const HORIZONTAL_OFFSET = 50; // @zhiqiang 该值太大，导致内嵌多维表框选不中，暂时使用 原来的 HALF_BLOCK_HEIGHT
const HALF_BLOCK_HEIGHT = 5;
const MOVE_OFFSET_NUM = 5;
const { autoScroll, raf } = getAutoScroll();

const useSelect = () => {
  const isDragging = useIsDragging();
  const columnResizing = useColumnResizing();

  const block = useRef<HTMLElement | null>(null);
  const start = useRef<[x: number, y: number, scrollTop: number]>();
  const [end, setEnd] = useState<[x: number, y: number, scrollTop: number]>();
  const isInRight = useIsInRight();
  const pageId = useGetPageId();

  // 框选 相关逻辑
  useEffect(() => {
    const container = querySelectorFromMainContent(
      '.next-space-page',
      isInRight
    ) as HTMLElement | null;

    if (!container || isDragging || columnResizing) return;
    const handleMouseup = () => {
      start.current = undefined;
      block.current = null;
      setEnd(undefined);
      cancelAnimationFrame(raf.id);
      globalListenerHelper.removeEventListener('mousemove', handleMouseMove);
      globalListenerHelper.removeEventListener('mouseup', handleMouseup);
    };

    const handleMousedown = (event: MouseEvent) => {
      if ($appUiStateCache.$blockMenuListId || event.button !== 0) {
        return;
      }
      if (document.querySelector('[data-modal-id="SETTING"]')) return;

      const target = event.target as HTMLElement;

      if (
        target.closest(
          '[data-disable-select], [data-draggable], [data-property-id], [data-ignore-draggable]'
        )
      ) {
        return;
      }
      const panelName = isInRight ? '.right-panel' : '.main-panel';
      block.current = target.closest(`${panelName} [data-block-id]`);
      start.current = [event.clientX, event.clientY, container.scrollTop];
      globalListenerHelper.addEventListener('mousemove', handleMouseMove);
      globalListenerHelper.addEventListener('mouseup', handleMouseup);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const moveOffset =
        start.current &&
        Math.abs(start.current[0] - event.clientX) < MOVE_OFFSET_NUM &&
        Math.abs(start.current[1] - event.clientY) < MOVE_OFFSET_NUM;

      if (!start.current || moveOffset || cache.ui.selectedCells.length > 0) {
        return;
      }

      if (block.current) {
        const rect = block.current.getBoundingClientRect();
        const id = block.current.dataset.blockId;
        const isTitle = pageId === id;
        const cacheBlock = id && cache.blocks[id];

        let maxOffset = true;
        if (cacheBlock) {
          if ([BlockType.COLLECTION_VIEW].includes(cacheBlock.type)) {
            maxOffset = false;
          }
        }

        const x1 = rect.left - (maxOffset ? HORIZONTAL_OFFSET : HALF_BLOCK_HEIGHT);
        const x2 = rect.right + (maxOffset ? HORIZONTAL_OFFSET : HALF_BLOCK_HEIGHT);
        const y1 = rect.top - HALF_BLOCK_HEIGHT;
        const y2 = rect.bottom + HALF_BLOCK_HEIGHT;

        if (
          (isTitle || (event.clientX > x1 && event.clientX < x2)) &&
          event.clientY > y1 &&
          event.clientY < y2
        ) {
          return;
        }
      }

      block.current = null;
      window.getSelection().removeAllRanges();
      document.dispatchEvent(new Event('_clearTextSelection'));

      autoScroll(event.clientX, event.clientY, container, DIS);
      // autoScroll(container, event.clientX, event.clientY);

      setEnd([event.clientX, event.clientY, container.scrollTop]);
    };

    const handleScroll = () => {
      setEnd((prev) => {
        if (!prev) return prev;
        const [x, y] = prev;
        return [x, y, container.scrollTop];
      });
    };

    container.addEventListener('scroll', handleScroll);
    container.addEventListener('mousedown', handleMousedown);
    return () => {
      start.current = undefined;
      cancelAnimationFrame(raf.id);

      container.removeEventListener('scroll', handleScroll);
      container.removeEventListener('mousedown', handleMousedown);
      globalListenerHelper.removeEventListener('mouseup', handleMouseup);
      globalListenerHelper.removeEventListener('mousemove', handleMouseMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnResizing, isDragging, isInRight]);

  return useMemo(() => {
    if (!start.current || !end) return;
    const [startX, startY, startTop] = start.current;
    const [endX, endY, endTop] = end;

    const dh = endTop - startTop;
    const sy = startY - dh;
    const left = Math.min(startX, endX);
    const top = Math.min(sy, endY);
    const height = Math.abs(endY - sy);
    const width = Math.abs(endX - startX);

    return { top, left, width, height };
  }, [end]);
};

interface SelectorProps {
  type?: 'page' | 'collection';
}

interface BlockPosCache {
  left: number;
  width: number;
  height: number;
  offsetTop: number;
  viewId?: string;
  groupValue?: string;
  subGroupValue?: string;
  syncId?: string;
}

export const Selector: FC<SelectorProps> = ({ type = 'page' }) => {
  const columnResizing = useColumnResizing();
  const isInRight = useIsInRight();
  const style = useSelect();
  const blockPosCache = useRef<Map<string, BlockPosCache[]>>(new Map());
  const isPressSpace = usePressedSpace();
  const unSelectBlock = useCallback(() => {
    // 不return会导致rerender
    if (cache.ui.selectedBlocks.length === 0 && cache.ui.selectedCells.length === 0) return;
    cleanSelectBlock();
  }, []);

  useEffect(() => {
    if (isPressSpace) return;
    const root = document.getElementById('root');

    if (!style || columnResizing) {
      setAppUiState({ $isSelecting: false });
      root?.classList.remove('selecting');
      blockPosCache.current = new Map();
      return;
    }

    if (!$appUiStateCache.$isSelecting) {
      setAppUiState({ $isSelecting: true });
      root?.classList.add('selecting');
      blockPosCache.current = new Map();

      blurDocument();
    }

    const { blocks } = cache;

    const pageEl = querySelectorFromMainContent(
      `[data-mind-page-id]`,
      isInRight
    ) as HTMLElement | null;
    if (!pageEl) return;

    const firstElementChildRect = pageEl.firstElementChild?.getBoundingClientRect();
    if (!firstElementChildRect) return;

    const selectedNodes: SelectBlock[] = [];
    // 分开算是为了解决什么场景的问题?
    const columnSelectedNodes: SelectBlock[] = [];

    const listNodes = querySelectorAllFromMainContent(
      `.next-space-page [data-block-id]`,
      isInRight
    );
    const { collectionViews } = getState();
    for (const node of listNodes) {
      const { blockId, viewId, groupValue, subGroupValue, syncId } = (node as HTMLElement).dataset;
      if (!blockId) return;

      const _cache = blockPosCache.current.get(blockId);
      const isCalendar = viewId && collectionViews[viewId]?.type === CollectionViewType.CALENDAR;
      if (_cache) {
        if (isCalendar || groupValue) {
          const rect = node.getBoundingClientRect();
          const blockInfo = {
            left: rect.left,
            width: rect.width,
            height: rect.height,
            offsetTop: rect.top - firstElementChildRect.top,
            viewId,
            groupValue,
            subGroupValue,
            syncId,
          };
          blockPosCache.current.set(blockId, uniqWith(_cache.concat(blockInfo), fastEqual));
        }
      } else {
        const rect = node.getBoundingClientRect();
        const blockInfo = {
          left: rect.left,
          width: rect.width,
          height: rect.height,
          offsetTop: rect.top - firstElementChildRect.top,
          viewId,
          groupValue,
          subGroupValue,
          syncId,
        };

        blockPosCache.current.set(blockId, [blockInfo]);
      }
    }

    let firstBlockTop: number | undefined;
    let lastBlockTop: number | undefined;
    let firstBlock: NextBlock | undefined;
    let lastBlock: NextBlock | undefined;

    for (const [blockId, blockInfo] of blockPosCache.current) {
      let intersect = false;
      let blockRect: Rectangle | undefined;
      const intersectRect: BlockPosCache[] = [];

      blockInfo.forEach((item, index) => {
        const rect = {
          left: item.left,
          top: firstElementChildRect.top + item.offsetTop,
          width: item.width,
          height: item.height,
        };

        if (index === 0) {
          blockRect = rect;
        }

        if (isIntersect(rect, style)) {
          intersectRect.push(item);
          intersect = true;
        }
      });

      if (!intersect || !blockRect) continue;

      const block = blocks[blockId];
      if (!block) continue;
      if (block.type === BlockType.COLUMN_LIST) continue;

      const parent = blocks[block.parentId];
      if (!parent) continue;

      if (!firstBlockTop || blockRect.top < firstBlockTop) {
        firstBlockTop = blockRect.top;
        firstBlock = block;
      }
      if (!lastBlockTop || blockRect.top > lastBlockTop) {
        lastBlockTop = blockRect.top;
        lastBlock = block;
      }

      const firstRect = intersectRect[0];
      if (!firstRect) return;
      const blockData: SelectBlock = {
        blockId,
        viewId: firstRect.viewId,
        syncId: firstRect.syncId,
      };

      if (firstRect.groupValue) {
        const allGroupValues: Record<string, string[]> = {};
        intersectRect.forEach((item) => {
          const { groupValue } = item;
          if (groupValue) {
            const oldValues = allGroupValues[groupValue ?? ''] ?? [];

            allGroupValues[groupValue] = item.subGroupValue
              ? oldValues.concat(item.subGroupValue)
              : oldValues;
          }
        });

        blockData.groupValues = allGroupValues;
      }

      if (parent.type === BlockType.COLUMN) {
        columnSelectedNodes.push(blockData);
      } else {
        selectedNodes.push(blockData);
      }
    }

    if (!firstBlock || !lastBlock) {
      unSelectBlock();
      return;
    }

    let result = [...selectedNodes, ...columnSelectedNodes];
    // 多维表里的 record 只读无法框选
    result = result.filter((item) => {
      const block = cache.blocks[item.blockId];
      const parentId = block?.parentId;
      if (parentId) {
        const parent = cache.blocks[parentId];
        if (parent && isCollection(parent.type)) {
          const { role } = getPermissions(item.blockId);
          return role !== PermissionRole.READER;
        }
      }
      return true;
    });

    if (!fastEqual(result, cache.ui.selectedBlocks)) {
      dispatch(uiActions.updateSelectBlocks(result));
    }
  }, [style, columnResizing, type, isInRight, isPressSpace, unSelectBlock]);

  const reallyLeft = useMemo(() => {
    // 如果是右边panel的话，坐标需要矫正一下，[left]坐标需要减去main-panel的right值
    if (isInRight && style?.left && !isSafari) {
      const mainPanelRight = document
        .querySelector('.main-panel .next-space-page')
        ?.getBoundingClientRect().right;
      if (!mainPanelRight) return undefined;
      return style.left - mainPanelRight;
    }
    return style?.left;
  }, [isInRight, style?.left]); // 只监听left变化就够了

  return (
    <div
      style={{ ...style, left: reallyLeft, zIndex: 1000 }}
      className="fixed bg-active_color opacity-[0.12] pointer-events-none"
    />
  );
};
