import type { Location } from 'history';

import type { SearchParams } from '.';

export function getLocationId(location: Location) {
  return (
    location.pathname +
    (location.search ? `?${location.search}` : '') +
    (location.hash ? `#${location.hash}` : '')
  );
}

export type LocationSearchParams =
  | { key: SearchParams; value: string | undefined }
  | { key: SearchParams; value?: string }[];

export const splicePathSearchParams = (path: string, params: LocationSearchParams) => {
  const query = new URLSearchParams(location.search);
  if (Array.isArray(params)) {
    params.forEach(({ key, value }) => value && query.set(key, value));
  } else {
    params.value && query.set(params.key, params.value);
  }
  const queryString = query.toString();
  return `${path}${queryString ? `?${queryString}` : ''}`;
};
