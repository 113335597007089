import isHotkey from 'is-hotkey';
import { useCallback } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useShortcutCode } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-code';
import { useShortcutEquation } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-equation';
import { useShortcutFormat } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-format';
import { isMindNodeFocused } from 'src/mind-map/utils/mind-node-util';
import { cache } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';

/**
 *
 * 选中块响应富文本快捷键事件
 * NOTE:文档和思维导图略微不一样
 */
export const useRichTextShortCut = () => {
  const getEditorModal = useGetOrInitEditorModel();
  const shortcutFormat = useShortcutFormat();
  const shortcutEquation = useShortcutEquation();
  const shortcutCode = useShortcutCode();
  return useCallback(
    (e: KeyboardEvent, isMindMap: boolean) => {
      // 选中块才响应富文本快捷键
      if (cache.ui.selectedBlocks.length === 0) return;

      const isFocused = isMindNodeFocused();
      if (isMindMap && isFocused) return; // 思维啊导图focus不响应快捷键

      let responseShortcutKey = false;
      switch (true) {
        case isHotkey('mod+b')(e):
        case isHotkey('mod+u')(e):
        case isHotkey('mod+i')(e):
        case isHotkey('mod+e')(e):
        case isHotkey('mod+shift+s')(e):
        case isHotkey('mod+shift+x')(e):
          e.preventDefault();
          responseShortcutKey = true;
          break;
        default:
      }
      if (!responseShortcutKey) return;
      // 这里是处理多个块被选中的情况
      const selectAll = (blockId: string) => {
        const modal = getEditorModal(blockId);
        modal?.performChange((ctx) => {
          ctx.select(0, modal.content.length);
        });
      };
      // 计算框选的所有块，只有文档页面如此，思维导图是不算被选中的块的subnode的
      const allBlockIds: Record<string, string> = {};
      const loop = (id: string) => {
        if (allBlockIds[id]) return; // return if dup
        const block = cache.blocks[id];
        if (!block) return;
        if (isPageLike(block.type)) return;
        allBlockIds[id] = id;
        block.subNodes.forEach(loop);
      };
      cache.ui.selectedBlocks.forEach((selectedBlock) => {
        if (isMindMap) {
          allBlockIds[selectedBlock.blockId] = selectedBlock.blockId;
        } else {
          loop(selectedBlock.blockId);
        }
      });

      switch (true) {
        case isHotkey('mod+b')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutFormat(blockId, 'bold');
          });
          break;
        }
        case isHotkey('mod+u')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutFormat(blockId, 'underline');
          });
          break;
        }
        case isHotkey('mod+i')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutFormat(blockId, 'italic');
          });
          break;
        }
        case isHotkey('mod+e')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutCode(blockId);
          });
          break;
        }
        case isHotkey('mod+shift+s')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutFormat(blockId, 'lineThrough');
          });
          break;
        }
        case isHotkey('mod+shift+x')(e): {
          Object.entries(allBlockIds).forEach(([blockId, _]) => {
            selectAll(blockId);
            shortcutEquation(blockId);
          });
          break;
        }
        default:
      }
      return true;
    },
    [getEditorModal, shortcutCode, shortcutEquation, shortcutFormat]
  );
};
