import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListViewProps } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';

export const Radio = ({
  checked,
  title,
  className,
  onClick,
}: {
  checked?: boolean;
  title: string;
  className?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={cx('flex items-center animate-click', className)} onClick={onClick}>
      <div className="relative flex items-center">
        <Icon name="IcRadio" size="middle" />
        {checked && (
          <Icon name="MIcRadioSelected" size="middle" className="absolute text-active_color" />
        )}
      </div>
      <div className="text-t2">{title}</div>
    </div>
  );
};

export const Select = ({
  items,
  onClick,
  value,
}: {
  items: ListViewProps['items'];
  onClick: (value: any) => void;
  value?: string | number | boolean;
}) => {
  const openModal = useOpenModal();

  const item = items.find((o) => o.data.value === value);

  return (
    <div
      className="flex items-center animate-click"
      onClick={(event) => {
        openModal.dropdown({
          popcorn: event.target as HTMLElement,
          content({ onCloseModal }) {
            return (
              <div className="next-modal w-[200px] py-2">
                <ListView
                  items={items}
                  onItemClick={(item) => {
                    onClick(item.data.value);
                    onCloseModal();
                  }}
                />
              </div>
            );
          },
        });
      }}
    >
      <div className="text-t2">{item?.data.title}</div>
      <Icon name="IcArrowDown01" className="ml-2 text-grey4" size="tiny" />
    </div>
  );
};

export const Option: FC<{ title: string; className?: string }> = ({
  title,
  className,
  children,
}) => {
  return (
    <div className={cx('flex items-center justify-between mb-4', className)}>
      <div className="text-t2 text-grey3">{title}</div>
      {children}
    </div>
  );
};
