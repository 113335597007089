import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { ViewPath } from 'src/utils';
import { isFlowUsApp } from 'src/utils/electron-util';
import { emitter } from '@flowus/common/utils/emitter';
import { locationToUrl } from 'src/utils/location-utils';
import { pdfPreviewCache } from '../service/storage';
import { SideBarType } from '../type';

const SideBarViewMap = [
  { name: '文字目录', type: SideBarType.CATALOGUE },
  { name: '缩略图目录', type: SideBarType.THUMBNAIL_VIEW },
  { name: '高亮标注', type: SideBarType.ANNOTATION },
  { name: '隐藏边栏', type: SideBarType.NONE },
];

interface Props {
  uuid?: string;
  sideBarViews: { name: string; type: SideBarType }[];
  closeModal: () => void;
}

export const SidebarSetting: FC<Props> = ({ uuid, closeModal, sideBarViews }) => {
  const pdfFormat = pdfPreviewCache.get(uuid ?? '') ?? {};
  const [sideBarType, setSideBarType] = useState(pdfFormat?.sideBarType ?? SideBarType.NONE);

  const items = sideBarViews.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        type: item.type,
        arrow: <Icon name="IcCheck02" size="normal" />,
        hasArrow: item.type === sideBarType,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    emitter.emit('sidebarType', data.type);
    setSideBarType(data.type);
    closeModal();
  };

  return (
    <ListView
      className="next-modal w-[160px] py-[5px] bg-white4"
      items={items}
      onItemClick={handleClick}
    />
  );
};

interface SideBarButtonProps {
  uuid?: string;
  className?: string;
  pdfViewer: PDFViewer;
}

const getInitSidebar = (uuid?: string) => {
  return uuid
    ? SideBarViewMap
    : SideBarViewMap.filter((item) => item.type !== SideBarType.ANNOTATION);
};
export const SideBarButton: FC<SideBarButtonProps> = ({ uuid, className, pdfViewer }) => {
  const openModal = useOpenModal();
  const [sideBarViews, setSideBarViews] = useState(getInitSidebar(uuid));

  useEffect(() => {
    if (!pdfViewer.pdfDocument) return;

    pdfViewer.pdfDocument
      .getOutline()
      .then((outlines) => {
        if (!outlines || outlines?.length === 0) {
          setSideBarViews(getInitSidebar(uuid).slice(1));
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [pdfViewer.pdfDocument, uuid]);

  const openSetting = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <SidebarSetting sideBarViews={sideBarViews} uuid={uuid} closeModal={onCloseModal} />
      ),
    });
  };

  return (
    <>
      {isFlowUsApp.check && (
        <IconUiSizeBox
          size={26}
          className={'mr-1 animate-hover'}
          popup={'后退'}
          onClick={() => {
            locationToUrl(ViewPath.main);
          }}
        >
          <Icon size="xlarge" name="IcArrowBack" />
        </IconUiSizeBox>
      )}
      <Tooltip placement="bottom" popup="侧边栏">
        <button
          className={cx('flex items-center animate-hover p-1', className)}
          onClick={openSetting}
        >
          <Icon size="middle" name={'IcPreviewCatalog'} />
          <Icon size="xxsmall" name={'IcArrowDown01'} className="ml-1" />
        </button>
      </Tooltip>
    </>
  );
};
