import { useCallback } from 'react';
import { batch } from 'react-redux';
import { request } from 'src/common/request';
import { $currentUserCache, deleteUserSpaceView } from 'src/services/user/current-user';
import { getSpaceViewIdBySpaceId, switchSpaceViewId } from '../user/switch-space-view-id';
import { useOpenModal } from '@flowus/common/next-modal';
import { useIsProSpace } from './use-is-pro-space';
import { useCurrentSpace } from './use-current-space';
import { useHistory } from 'react-router-dom';
import { jumpAfterFetchSpaces } from './use-jump-after-fetch-spaces';
import { ViewPath } from 'src/utils';
import { Deferred } from '@flowus/common';

/**
 * 删除空间
 */
export const useDeleteSpace = () => {
  return useCallback(async (uuid: string) => {
    const spaceViewId = getSpaceViewIdBySpaceId(uuid);
    batch(() => {
      // 删除空间之后，会切换到第一个空间
      deleteUserSpaceView(spaceViewId);
      switchSpaceViewId($currentUserCache.spaceViews[0] ?? '');
    });
    await request.infra.deleteSpace(uuid);
  }, []);
};

export const useOpenDeleteSpace = () => {
  const openModal = useOpenModal();
  const isProSpace = useIsProSpace();
  const deleteSpace = useDeleteSpace();
  const currentSpace = useCurrentSpace();
  const history = useHistory();

  return useCallback(() => {
    const defer = new Deferred();
    if (isProSpace) {
      openModal.warning({
        title: '当前空间还未到期，无法删除',
        confirmText: '知道了',
      });
      defer.reject(new Error('cancel'));
      return defer.promise;
    }

    openModal.warning({
      title: '此操作无法撤销。',
      content: '删除工作空间，将永久删除其中所包含的所有页面和文件',
      countTime: 10,
      confirmText: '确认删除',
      confirm: async () => {
        // 需要等删除空间后才能跳转，要不然会发不出去
        await deleteSpace(currentSpace.uuid);
        if (!jumpAfterFetchSpaces()) {
          history.push(ViewPath.main);
        }
        defer.resolve();
      },
      cancel: () => {
        defer.reject(new Error('cancel'));
      },
    });
    return defer.promise;
  }, [currentSpace.uuid, deleteSpace, history, isProSpace, openModal]);
};
