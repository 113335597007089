import { createContext, useContext } from 'react';

import type { MindMapEngine } from '../core/mind-map-engine';

export const MindMapContext = createContext<MindMapEngine | undefined>(undefined);

export const useMindMapEngine = () => {
  const engine = useContext(MindMapContext);
  if (!engine) {
    throw new Error('mindEngine is undefined');
  }
  return engine;
};
