import type { SpaceSettingDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';

interface Props {
  commentAlignment: SpaceSettingDTO['commentAlignment'];
  onChangeCommentAlignment: (alignment: SpaceSettingDTO['commentAlignment']) => void;
}

export const CommentSettingList: FC<Props> = (props) => {
  const items = [
    { type: 'top', title: '顶部' },
    { type: 'bottom', title: '底部' },
  ].map((item) => ({
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: { ...item, selected: item.type === props.commentAlignment },
  }));

  return (
    <ListView
      className="rounded-r next-modal py-1.5 w-[160px]"
      items={items}
      onItemClick={(item) => {
        props.onChangeCommentAlignment(item.data.type);
      }}
    />
  );
};
