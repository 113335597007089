import { cx } from '@flowus/common/cx';
import { PDFPresentationMode } from 'pdfjs-dist/lib/web/pdf_presentation_mode';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { bizTracker } from 'src/utils/biz-tracker';

import { PresentationModeState } from '../type';

const PRESENTATION_CLASS = 'presentationMode';

interface Props {
  uuid?: string;
  pdfViewer: PDFViewer;
  className?: string;
}

export const PresentationButton: FC<Props> = ({ pdfViewer, uuid, className }) => {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (pdfViewer.presentationModeState !== 3) return;

      if (event.metaKey || event.ctrlKey) {
        switch (event.keyCode) {
          case 38:
            pdfViewer.currentPageNumber = 1;
            break;
          case 40:
            pdfViewer.currentPageNumber = pdfViewer.pagesCount;
            break;
          default:
            break;
        }
        return;
      }

      let turnPage = 0;
      switch (event.keyCode) {
        case 38: // up arrow
        case 33: // pg up
          turnPage = -1;
          break;
        case 8: // backspace
          turnPage = -1;
          break;
        case 37: // left arrow
          turnPage = -1;
          break;
        case 40: // down arrow
        case 34: // pg down
          turnPage = 1;
          break;
        case 13: // enter key
        case 32: // spacebar
          turnPage = 1;
          break;
        case 39: // right arrow
          turnPage = 1;
          break;
        default:
          break;
      }

      if (turnPage !== 0) {
        if (turnPage > 0) {
          pdfViewer.nextPage();
        } else {
          pdfViewer.previousPage();
        }
      }
    };

    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [pdfViewer]);

  const fullScreen = () => {
    bizTracker.event('presentation_mode', { file_id: uuid });

    const pdfPresentationMode = new PDFPresentationMode({
      container: pdfViewer.container,
      pdfViewer,
      eventBus: pdfViewer.eventBus,
    });

    pdfViewer.eventBus.on('presentationmodechanged', ({ state }: any) => {
      pdfViewer.presentationModeState = state;
      if (state === PresentationModeState.FULLSCREEN) {
        pdfViewer.viewer?.classList.add(PRESENTATION_CLASS);
      } else {
        pdfViewer.viewer?.classList.remove(PRESENTATION_CLASS);
      }
    });

    pdfPresentationMode.request();
  };

  return (
    <Tooltip placement="bottom" className={cx('ml-4', className)} popup="演示">
      <button className="animate-hover p-1 flex justify-center items-center" onClick={fullScreen}>
        <Icon size="middle" name="IcMenuPresent" />
      </button>
    </Tooltip>
  );
};
