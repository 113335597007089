import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

export const Divider: FC<JSX.IntrinsicElements['span']> = (props) => {
  const { className, ...rest } = props;
  return <span className={cx('block border-t border-grey6', className)} {...rest} />;
};

export const SettingDivider: FC<{ className?: string }> = (props) => {
  const { className } = props;
  return (
    <div className={cx('h-2.5 flex items-center w-full flex-shrink-0', className)}>
      <div className={'w-full h-px bg-grey6'} />
    </div>
  );
};
