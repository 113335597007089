import { BlockType } from '@next-space/fe-api-idl';
import { isHotkey } from 'is-hotkey';
import { useMemo, useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { Tooltip } from 'src/common/components/tooltip';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { cache } from 'src/redux/store';
import type { PageScene } from 'src/views/main/scene-context';
import { FilePreview } from '.';
import { useFileImageBlockIds } from './hook';

export const ImageGallery: FC<{ uuid: string; scene?: PageScene; ownerBlockId?: string }> = ({
  uuid,
  scene,
  ownerBlockId,
}) => {
  const blockId = ownerBlockId ?? uuid;
  const pageId = useMemo(() => getOwnerPage(blockId), [blockId]);
  const fileImageBlockIds = useFileImageBlockIds({ uuid: blockId, pageId });

  const [index, setIndex] = useState(Math.max(fileImageBlockIds.indexOf(blockId), 0));

  const gotoPreImage = useCallback(() => {
    setIndex((idx) => {
      if (!fileImageBlockIds[idx - 1]) {
        message.warning('当前为第一张');
        return idx;
      }
      return idx - 1;
    });
  }, [fileImageBlockIds]);
  const gotoNextImage = useCallback(() => {
    setIndex((idx) => {
      if (!fileImageBlockIds[idx + 1]) {
        message.warning('当前为最后一张');
        return idx;
      }
      return idx + 1;
    });
  }, [fileImageBlockIds]);
  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (isHotkey('ArrowLeft')(e)) {
        gotoPreImage();
      } else if (isHotkey('ArrowRight')(e)) {
        gotoNextImage();
      }
    };
    const remove = globalListenerHelper.addEventListener('keydown', keyDown);
    return remove;
  }, [gotoNextImage, gotoPreImage]);

  const previewId = useMemo(() => {
    const indexId = fileImageBlockIds[index];
    const block = cache.blocks[`${indexId}`];
    if (block?.type === BlockType.REFERENCE) {
      return block?.data.ref?.uuid;
    }
    return fileImageBlockIds[index];
  }, [fileImageBlockIds, index]);

  return (
    <div className="file-preview">
      <FilePreview uuid={previewId ?? ''} pageScene={scene} forceImage={true} />
      {fileImageBlockIds.length !== 0 && (
        <>
          <Tooltip popup="上一张" className="cursor-pointer absolute left-0 pl-2 top-[50%]">
            <Icon
              name={'MIcBtnPre'}
              size="xxxlarge"
              onClick={(e) => {
                e.stopPropagation();
                gotoPreImage();
              }}
            />
          </Tooltip>
          <Tooltip popup="下一张" className="cursor-pointer absolute right-0 pr-2 top-[50%]">
            <Icon
              name={'MIcBtnNext'}
              size="xxxlarge"
              onClick={(e) => {
                e.stopPropagation();
                gotoNextImage();
              }}
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};
