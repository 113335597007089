export const PRIORITY_NORMAL = 10;
export const PRIORITY_PORTAL = 20;
export const PRIORITY_DIALOG = 30;
export type ListenPriority = number;
/**
 *
 * 这块代码解决的问题是：当若干个组件都需要全局监听事件的时候,可能会互相影响，这个类能通过加优先级的方式来决定顺序，监听方法返回true会阻断继续往下分发
 * 比如:
 * 1.当ab都需要全局监听事件的时候，在a菜单弹出子菜单b时，此时按键盘，应该先响应b的listener，并由b决定是否往下继续分发。
 * 2.图片resize的时候和全局框选的监听事件冲突了，这时候图片resize的监听优先级调高一点并返回true即可解决相互影响的问题。
 *
 * 内部使用了window.addEventListener方法监听
 *
 * 新增了priority属性，通过不同的priority来决定监听优先级
 *
 * priority分3种：
 * normal   为了操作页面上zIndex为0的或者看起来是0元素，即平面上的元素
 * portal   为了操作非模态对话框的元素，比如portal类型的元素
 * dialog   为了操作模态对话框的元素而设置的优先级，比如openModal弹出的对话框
 * normal < portal < dialog
 *
 * 如果满足不了需求，可以根据具体情况在属于自己范围的优先级上+n，比如PRIORITY_NORMAL+2，n不能大于10
 * addListener方法暂不支持复杂的参数，仅支持useCapture.
 *
 * 目前除了list-view组件的菜单(含二级菜单)用到了,还有其他地方也换成了globalListener
 */

interface PriorityListenerWrapper {
  priority: (() => number) | number;
  listener: GlobalEventListener<any>;
}
type GlobalEventListener<K extends keyof WindowEventMap> = (
  ev: WindowEventMap[K]
) => boolean | void;

class GlobalListenerHelper {
  private captureListenersMap = new Map<string, PriorityListenerWrapper[]>();
  private normalListenersMap = new Map<string, PriorityListenerWrapper[]>();
  /** 开始监听 */
  private startListenEvent<K extends keyof WindowEventMap>(type: K, capture?: boolean) {
    window.addEventListener(type, capture ? this.listenCapture : this.listenNormal, capture);
  }
  private listenNormal = (ev: Event) => {
    const listenersWrapper = this.normalListenersMap.get(ev.type);
    if (!listenersWrapper) return;
    for (const wrapper of listenersWrapper) {
      const handle = wrapper.listener(ev); // any good idea to know the event is already preventDefault or stopPropagation?
      if (handle) break;
    }
  };
  private listenCapture = (ev: Event) => {
    const listenersWrapper = this.captureListenersMap.get(ev.type);
    if (!listenersWrapper) return;
    for (const wrapper of listenersWrapper) {
      const handle = wrapper.listener(ev); // 返回true表示事件被吃掉了，不需要继续往下分发
      if (handle) break;
    }
  };
  /**
   *
   * @param type 监听的类型 ex. keydown
   * @param listener 监听器
   * @param priority 传递number就是静态优先级，传递方法就是动态优先级
   * @param capture
   * @returns 返回unregister方法
   */
  addEventListener<K extends keyof WindowEventMap>(
    type: K,
    listener: GlobalEventListener<K>,
    priority = PRIORITY_NORMAL,
    capture?: boolean
  ): () => void {
    const priorityListeners = capture ? this.captureListenersMap : this.normalListenersMap;
    let listeners = priorityListeners.get(type);
    if (!listeners) {
      listeners = [];
    }
    if (listeners.length === 0) {
      this.startListenEvent(type, capture);
    }
    listeners.push({
      priority,
      listener,
    });
    listeners.sort(_sortByPriority);
    priorityListeners.set(type, listeners);
    return () => {
      this.removeEventListener(type, listener, capture);
    };
  }

  removeEventListener<K extends keyof WindowEventMap>(
    type: K,
    listener: GlobalEventListener<K>,
    capture?: boolean
  ): void {
    const priorityListeners = capture ? this.captureListenersMap : this.normalListenersMap;
    // unregister
    const listeners = priorityListeners.get(type);
    const index = listeners?.findIndex((v) => v.listener === listener);

    if (index !== undefined && index >= 0) {
      listeners?.splice(index, 1);
    }
    if (listeners?.length === 0) {
      // 如果没有listener了就直接remove掉window注册的
      window.removeEventListener(type, capture ? this.listenCapture : this.listenNormal, capture);
    }
  }
}

/** 优先级高的排前面 */
function _sortByPriority(a: PriorityListenerWrapper, b: PriorityListenerWrapper) {
  const bPriority = typeof b.priority === 'function' ? b.priority() : b.priority;
  const aPriority = typeof a.priority === 'function' ? a.priority() : a.priority;
  return bPriority - aPriority;
}

const globalListenerHelper = new GlobalListenerHelper();
export { globalListenerHelper };
