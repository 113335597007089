import { cx } from '@flowus/common/cx';
import type { ComponentProps, CSSProperties } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { usePickBlock } from 'src/utils/pick-block';
import { useCoverImageUrl } from './use-cover-image-url';

interface BackgroundContainerProps {
  blockId: string;
  showCover?: boolean;
  fillBgColor?: boolean;
}

export const BackgroundContainer = forwardRef<
  HTMLDivElement,
  BackgroundContainerProps & Omit<ComponentProps<'div'>, 'style' | 'ref'>
>((props, ref) => {
  // eslint-disable-next-line no-unused-vars
  const { blockId, showCover, className, fillBgColor, ...rest } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const imageUrl = useCoverImageUrl(blockId, divRef);
  const block = usePickBlock(blockId, ['data'], ['cover', 'coverPos', 'localUrl', 'size']);
  const coverPos = block?.data.coverPos ?? 50;
  let style: CSSProperties = {};
  if (showCover) {
    style = {
      backgroundSize: 'cover',
      backgroundImage: `url(${imageUrl})`,
      backgroundPosition: `center ${coverPos}%`,
      backgroundRepeat: 'no-repeat',
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => divRef.current!, []);
  return (
    <div
      ref={divRef}
      style={style}
      className="w-full h-full relative rounded transition-all"
      {...rest}
      contentEditable={false}
    >
      <div
        className={cx('relative', className)}
        style={{
          background: showCover
            ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 90%)'
            : undefined,
        }}
      >
        {props.children}
      </div>
    </div>
  );
});
