import { emitter } from '@flowus/common/utils/emitter';
import type { AIMemberDTO, PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { createModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useAllUser } from 'src/hooks/page/use-subscription-data';
import { getCurrentSpace, useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { ADD_MARK_NAME, TRANSACTION_FIRE } from 'src/redux/actions';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch, getState } from 'src/redux/store';
import { SpacePlanType } from 'src/redux/types';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { getLimitConfig } from 'src/services/app/hook/subscription-data';
import { Regex } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useOpenSettingModal,
  useOpenUpgradeSpace,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import { validate } from 'uuid';
import { setPermission } from '../helper';
import type { ShareProps, User } from '../types';
import type { BatchGuestPermission } from './search-user-select';
import { BATCH_GUEST_PREFIX } from './search-user-select';

type Props = Pick<ShareProps, 'backToMainPanel'> & {
  /** type 为form 时，uuid 是viewId */
  uuid: string;
  searchType?: 'form' | 'aiMember';
};

export const useSearchUserModel = (props: Props) => {
  const { backToMainPanel, searchType, uuid } = props;
  const isForm = searchType === 'form';
  const isAIMember = searchType === 'aiMember';
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [role, setRole] = useState(PermissionRole.EDITOR);
  // 批量导入外部协作者
  const [batchGuestGroups, setBatchGuestGroups] = useState<BatchGuestPermission[]>([]);
  const currentSpace = useCurrentSpace();
  const spaceUsers = useCurrentSpaceUsers();
  const allUsers = useAllUser();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openModal = useOpenModal();
  const openSettingModal = useOpenSettingModal();
  const isPro = useIsProSpace();

  const permissions = useMemo(() => {
    const results: PermissionDTO[] = [];
    selectedUsers.forEach((u) => {
      // 处理批量外部协作者,把group替换会user
      if (u.type === PermissionType.GROUP && u.groupId?.startsWith(BATCH_GUEST_PREFIX)) {
        const group = batchGuestGroups.find((v) => v.id === u.groupId);
        group?.userIds.forEach((userId) => {
          results.push({ userId, type: PermissionType.USER, role });
        });
      } else {
        results.push({ ...u, role });
      }
    });
    return results;
  }, [batchGuestGroups, role, selectedUsers]);

  const selectUser: ShareProps['selectUser'] = (permission, role) => {
    setSelectedUsers((pre) => {
      if (role) {
        return pre.map((o) => {
          if (
            (permission.type === PermissionType.SPACE && o.type === permission.type) ||
            (permission.type === PermissionType.USER && o.userId === permission.userId) ||
            (permission.type === PermissionType.GROUP && o.groupId === permission.groupId)
          ) {
            return { ...o, role };
          }

          return o;
        });
      }

      if (
        permission.type === PermissionType.SPACE &&
        pre.some((o) => o.type === PermissionType.SPACE)
      ) {
        return pre.filter((o) => o.type !== PermissionType.SPACE);
      }

      if (
        permission.type === PermissionType.USER &&
        pre.some((o) => o.userId === permission.userId)
      ) {
        return pre.filter((o) => o.userId !== permission.userId);
      }

      if (
        permission.type === PermissionType.GROUP &&
        pre.some((o) => o.groupId === permission.groupId)
      ) {
        return pre.filter((o) => o.groupId !== permission.groupId);
      }
      return pre.concat({ ...permission, role: PermissionRole.EDITOR });
    });
  };

  const confirm = useCallback(async () => {
    if (isAIMember) {
      const oldMembers = $appUiStateCache.$aiMembers;
      const members: AIMemberDTO[] = [];
      selectedUsers.forEach((item) => {
        if (item.type === 'user' || item.type === 'group' || item.type === 'space') {
          const uuid =
            item.type === 'user'
              ? item.userId
              : item.type === 'group'
              ? item.groupId
              : currentSpace.uuid;
          if (uuid) {
            members.push({
              type: item.type,
              uuid,
            });
          }
        }
      });

      void request.infra.postSpaceAiMembers(currentSpace.uuid, { members });
      setAppUiState({ $aiMembers: oldMembers.concat(members) });
      backToMainPanel();
      return;
    }
    if (isForm) {
      const view = getState().collectionViews?.[uuid];
      if (!view) return;
      updateViewFormat(uuid, {
        formInviteUserPermissions: [
          ...(view.format.formInviteUserPermissions || []),
          ...permissions,
        ],
      });
      backToMainPanel();
      return;
    }
    const guestPermissions = permissions.filter(
      (permission) =>
        permission.type === PermissionType.USER &&
        permission.userId &&
        !spaceUsers[permission.userId]
    );
    // 把批量外部协作者的名字弄到space备注里
    let userRemarks: Record<string, string> = {};
    selectedUsers.forEach((u) => {
      // 处理批量外部协作者,把group替换会user
      if (u.type === PermissionType.GROUP && u.groupId?.startsWith(BATCH_GUEST_PREFIX)) {
        const group = batchGuestGroups.find((v) => v.id === u.groupId);
        if (!group) {
          return;
        }
        userRemarks = { ...userRemarks, ...group.markNames };
      }
    });

    if (Object.entries(userRemarks).length > 0) {
      await request.infra.addSpaceUserRemark(getCurrentSpace().uuid, userRemarks);
      // 本地更改一下space数据
      const markNameArray = Object.entries(userRemarks).map(([userId, markName]) => {
        return { userId, markName };
      });
      dispatch(
        ADD_MARK_NAME({
          uuid: getCurrentSpace().uuid,
          patch: markNameArray,
        })
      );
    }

    if (guestPermissions.length > 0) {
      // 免费版需判断人数
      if (!isPro) {
        const { results } = await request.infra.getSpaceGuests(currentSpace.uuid);

        const allGuests = new Set(guestPermissions.map((p) => p.userId));
        results.forEach(({ userId }) => allGuests.add(userId));
        const { mindMapMaxLimit } = getLimitConfig();
        if (allGuests.size > mindMapMaxLimit) {
          openModal.warning({
            title: '邀请失败',
            content: `外部协作者数量已达上限${mindMapMaxLimit}人，若需继续邀请， 可尝试升级空间`,
            confirmText: '升级空间',
            colorType: 'active',
            confirm: () => {
              if (currentSpace.planType === SpacePlanType.freePersonal) {
                openSettingModal({
                  defaultMenuType: SettingMenuType.upgrade,
                  from: OpenSettingFrom.extMember,
                });
              } else {
                openUpgradeSpace(OpenSettingFrom.extMember);
              }
            },
          });

          return;
        }
      }

      for (const permission of guestPermissions) {
        permission.isGuest = true;

        const user = allUsers[permission.userId as string];
        const isEmailCheck = __BUILD_IN__ && Regex.Email.test(user?.email || '');
        const isPhoneCheck = !__BUILD_IN__ && Regex.Mobile.test(user?.phone || '');

        if (user && !validate(user?.uuid) && (isEmailCheck || isPhoneCheck)) {
          const guest = await request.infra.temporary({ phone: user.phone, email: user.email });
          permission.userId = guest.user.uuid;
          dispatch(
            usersActions.updateSpaceUsers({
              users: {
                [guest.user.uuid]: { ...user, ...guest },
              },
            })
          );
        }
      }
    }

    backToMainPanel();

    permissions.forEach((permission) => setPermission(uuid, permission));

    dispatch(TRANSACTION_FIRE());

    bizTracker.event('invite_space_member');
  }, [
    allUsers,
    backToMainPanel,
    batchGuestGroups,
    currentSpace.planType,
    currentSpace.uuid,
    isAIMember,
    isForm,
    isPro,
    openModal,
    openSettingModal,
    openUpgradeSpace,
    permissions,
    selectedUsers,
    spaceUsers,
    uuid,
  ]);

  useEffect(() => {
    const hasGuest = selectedUsers.some(
      (user) =>
        user.groupId?.startsWith(BATCH_GUEST_PREFIX) || (user.userId && !spaceUsers[user.userId])
    );
    if (hasGuest && role === PermissionRole.EDITOR) {
      setRole(PermissionRole.WRITER);
    }
  }, [role, selectedUsers, spaceUsers]);

  useEffect(() => {
    const warn = () => {
      if (!selectedUsers.length) return;

      openModal.warning({
        title: <div className="w-80">是否发送协作邀请？</div>,
        content: '若取消，则当前邀请不会生效',
        confirmText: '发送邀请',
        colorType: 'active',
        confirm: () => {
          void confirm();
        },
      });
    };
    emitter.on('inviteConfirmModal', warn);

    return () => {
      emitter.off('inviteConfirmModal', warn);
    };
  }, [backToMainPanel, confirm, openModal, selectedUsers.length]);

  return {
    uuid,
    selectUser,
    selectedUsers,
    backToMainPanel,
    onConfirm: confirm,
    role,
    setRole,
    isForm,
    isAIMember,
    setBatchGuestGroups,
    batchGuestGroups,
  };
};

export const SearchUserModel = createModel(useSearchUserModel);
