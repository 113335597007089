import type { FC } from 'react';
import type { ShareProps } from '../types';
import { SearchUserModel } from './context';
import { SearchUserSelect } from './search-user-select';

export const SearchUser: FC<
  Pick<ShareProps, 'backToMainPanel'> & {
    /** type 为form 时，uuid 是viewId */
    uuid: string;
    searchType?: 'form' | 'aiMember';
  }
> = ({ backToMainPanel, uuid, searchType }) => {
  return (
    <SearchUserModel
      {...{
        backToMainPanel,
        uuid,
        searchType,
      }}
    >
      <SearchUserSelect />
    </SearchUserModel>
  );
};
