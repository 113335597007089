import { BlockStatus } from '@next-space/fe-api-idl';
import {
  CREATE_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW,
  LIST_BEFORE_COLLECTION_VIEW,
} from 'src/redux/actions/collection-view';
import type { NextCollectionView, NextWhere } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';

import { dispatch, getState } from '../../store';

export const add = (
  collectionView: Pick<NextCollectionView, 'title' | 'format' | 'pageSort' | 'type' | 'shareId'>,
  where: NextWhere,
  ignoreOp = false
) => {
  const uuid = uuidV4();
  const parent = getState().blocks[where.parentId];
  if (!parent) return;

  const currentTime = Date.now();
  const newCollectionView = {
    uuid,
    spaceId: parent.spaceId,
    status: BlockStatus.NORMAL,
    parentId: where.parentId,
    createdAt: currentTime,
    updatedAt: currentTime,
    version: 0,
    ...collectionView,
  };

  dispatch(
    CREATE_COLLECTION_VIEW({
      collectionView: newCollectionView,
      ignoreOp,
    })
  );
  if (where.first || where.before) {
    dispatch(
      LIST_BEFORE_COLLECTION_VIEW({
        uuid,
        parentId: where.parentId,
        before: where.before,
        ignoreOp,
      })
    );
  } else {
    dispatch(
      LIST_AFTER_COLLECTION_VIEW({
        uuid,
        parentId: where.parentId,
        after: where.after,
        ignoreOp,
      })
    );
  }

  return uuid;
};
