import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useGetFolder } from 'src/hooks/block/use-get-folder';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { FolderViewType, useBlockFolderListViewType } from 'src/services/app';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { useUploadFiles } from 'src/services/upload';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { PageOpenDiscussions } from 'src/views/comments/page-discussions';
import { DriveEmpty } from 'src/views/empty/page-drive-empty';
import { useSetPageVirtualListMode } from '../../page-doc/context';
import { FolderSelectAllIcon } from './common';
import { DriveTitle, DriveUploadFileItem, FileItem } from './component';
import { DriveContext } from './hook';

// #region 文件夹容器
interface Props {
  uuid: string;
  container: HTMLDivElement | null;
}
enum ItemsType {
  upload,
  file,
  folder,
}
export const DrivePage: FC<Props> = ({ uuid, container }) => {
  const blockFolderListViewType = useBlockFolderListViewType(uuid);
  const isTableView = blockFolderListViewType === FolderViewType.TABLE;
  const readonly = useReadonly(uuid);
  const { allowDownload } = usePermissions(uuid);
  const block = usePickBlock(uuid, []);
  const renderList = useGetFolder(uuid);
  const uploadFiles = useUploadFiles(uuid);
  const setIsVirtualListMode = useSetPageVirtualListMode();
  const haveItem = Boolean(renderList.files.length + renderList.folder.length + uploadFiles.length);
  const canDownload = (allowDownload && judgeSharePage()) || !readonly;
  const virtualRef = useRef<VirtuosoHandle | null>(null);
  const commentAlignment =
    useObservableBlock(uuid, (block) => block?.data.format?.commentAlignment) ?? 'top';

  const items = useMemo(() => {
    const result: { uuid: string; type: ItemsType }[] = [];
    renderList.folder.forEach((item) => {
      result.push({
        uuid: item.uuid,
        type: ItemsType.folder,
      });
    });

    uploadFiles
      .filter((i) => i.uploadId)
      .forEach((item) => {
        if (item.key) {
          result.push({ uuid: item.key, type: ItemsType.upload });
        }
      });

    renderList.files.forEach((item) => {
      result.push({
        uuid: item.uuid,
        type: ItemsType.folder,
      });
    });

    return result;
  }, [renderList.files, renderList.folder, uploadFiles]);

  useEffect(() => {
    const subNodes = items.map((v) => v.uuid);
    setIsVirtualListMode({
      isVirtualListMode: true,
      virtualRef,
      subNodes: subNodes || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  if (!block) return null;

  const listProps = {
    overscan: 200,
    customScrollParent: container as HTMLElement,
    totalCount: items.length,
    itemContent: (index: number) => {
      const item = items?.[index];
      if (!item) return null;
      if (item.type === ItemsType.upload) {
        return <DriveUploadFileItem uuid={item.uuid} listView={blockFolderListViewType} />;
      }
      return (
        <FileItem
          uuid={item.uuid}
          key={item.uuid}
          listView={blockFolderListViewType}
          canDownload={canDownload}
        />
      );
    },
  };

  return (
    <DriveContext.Provider value={{ isDriveIcon: true }}>
      <div className="px-24 sm:px-10 select-none flex flex-col flex-1" data-page-id={uuid}>
        <article className={'w-full h-full block-content flex-1'}>
          {haveItem && (
            <div className="flex items-center group relative">
              {canDownload && (
                <FolderSelectAllIcon
                  className={
                    'absolute -left-6 opacity-30 transition-opacity group-hover:opacity-100 cursor-pointer'
                  }
                  ids={[...renderList.files, ...renderList.folder].map((i) => i.uuid)}
                />
              )}
              <DriveTitle uuid={block.uuid} listView={blockFolderListViewType} />
            </div>
          )}
          {haveItem ? (
            <div>
              {isTableView ? (
                <Virtuoso {...listProps} ref={virtualRef} />
              ) : (
                <VirtuosoGrid ref={virtualRef} {...listProps} listClassName={'flex flex-wrap'} />
              )}
              {canDownload && <Selector />}
            </div>
          ) : (
            <DriveEmpty readonly={readonly} />
          )}
        </article>
        {commentAlignment === 'bottom' && <PageOpenDiscussions pageId={uuid} isBottom={true} />}
      </div>
    </DriveContext.Provider>
  );
};
// #endregion
