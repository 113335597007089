import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentUser } from 'src/hooks/user';
import { useTocIds } from './use-toc-id';

export const useTryPage = () => {
  const currentSpace = useCurrentSpace();
  const currentUser = useCurrentUser();
  const isGuest = useIsGuest();
  const tocIds = useTocIds(currentSpace.uuid, currentUser.uuid, isGuest);
  const lastTryId = localStorage.getItem('tryPageId');
  const allIds = tocIds.team.concat(tocIds.person);
  const index = allIds.findIndex((uuid) => uuid === lastTryId);
  const tryPageId = allIds[index + 1] ?? allIds[0] ?? '';
  return tryPageId;
};
