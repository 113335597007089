import { useCallback } from 'react';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useEditorModelKey } from 'src/editor/editor/uikit/editable/helper';
import { useCloseCreateBlockMenuList } from 'src/redux/managers/ui';
import { cache } from 'src/redux/store';
import { getAllEditorInfoFromDomContent } from 'src/utils/block-utils';
import { focusEditable } from 'src/utils/editor';
import { parseEditorKeyToBlockId } from 'src/utils/editor-key-to-id';
import { getEditorKey } from 'src/utils/editor-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { getOwnerPage } from '../block/use-get-owner-page';
import { useSendPresenceData } from '../user/use-send-presence-data';
import { getCaretInfo } from './helper';

export const useArrowUpKey = (uuid: string) => {
  const sendPresenceData = useSendPresenceData();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const isInRight = useIsInRight();
  const syncId = useSyncId();

  const handleArrowUp = (event: globalThis.KeyboardEvent) => {
    const editorKey = getEditorKey(uuid, syncId, isInRight);
    if (!editorKey) return;
    const caret = getCaretInfo(editorKey);
    if (!caret?.collapsed || !caret.isFirstLine) return;

    event.preventDefault();

    const blockId = parseEditorKeyToBlockId(uuid);
    const block = cache.blocks[blockId];
    if (!block) return;

    const pageId = getOwnerPage(block.uuid);
    if (!pageId) return;
    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const editableIndex = editableList.findIndex(
      (v) => v.blockId === block.uuid && v.syncId === syncId
    );
    const index = editableIndex - 1;
    if (index >= 0) {
      const preEditable = editableList[index];
      if (!preEditable) return;
      event.preventDefault();
      closeCreateBlockMenuList();
      focusEditable(preEditable.editorKey, caret.caretRect.x, 0);
      sendPresenceData(pageId, preEditable.blockId);
    } else {
      const pageEditorKey = getEditorKey(pageId, undefined, isInRight);
      focusEditable(pageEditorKey, caret.caretRect.x, 0);
    }
  };

  return useCallback(handleArrowUp, [
    closeCreateBlockMenuList,
    isInRight,
    sendPresenceData,
    syncId,
    uuid,
  ]);
};

export const useArrowDownKey = (uuid: string) => {
  const sendPresenceData = useSendPresenceData();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const isInRight = useIsInRight();
  const syncId = useSyncId();

  const handleArrowDown = (event: globalThis.KeyboardEvent) => {
    const editorKey = getEditorKey(uuid, syncId, isInRight);
    if (!editorKey) return;
    const caret = getCaretInfo(editorKey);
    if (!caret?.collapsed || !caret.isLastLine) return;

    const blockId = parseEditorKeyToBlockId(uuid);
    const block = cache.blocks[blockId];
    if (!block) return;

    const pageId = getOwnerPage(block.uuid);

    if (!pageId) return;
    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const editableIndex = editableList.findIndex(
      (v) => v.blockId === block.uuid && v.syncId === syncId
    );
    const index = editableIndex + 1;
    if (index < editableList.length) {
      const nextEditable = editableList[index];
      if (!nextEditable) return;
      event.preventDefault();
      closeCreateBlockMenuList();
      focusEditable(nextEditable.editorKey, caret.caretRect.x, 0);
      sendPresenceData(pageId, nextEditable.blockId);
    }
  };
  return useCallback(handleArrowDown, [
    closeCreateBlockMenuList,
    isInRight,
    sendPresenceData,
    syncId,
    uuid,
  ]);
};

export const useArrowRightKey = (uuid: string) => {
  const sendPresenceData = useSendPresenceData();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const isInRight = useIsInRight();
  const syncId = useSyncId();
  const editorKey = useEditorModelKey(uuid);
  const handleArrowRight = (event: globalThis.KeyboardEvent) => {
    const caret = getCaretInfo(editorKey);
    if (!caret?.collapsed || !caret.isLastChar) return;

    const blockId = parseEditorKeyToBlockId(uuid);
    const block = cache.blocks[blockId];
    if (!block) return;

    const pageId = getOwnerPage(block.uuid);
    if (!pageId) return;

    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const editableIndex = editableList.findIndex(
      (v) => v.blockId === block.uuid && v.syncId === syncId
    );
    const index = editableIndex + 1;
    if (index < editableList.length) {
      const nextEditable = editableList[index];
      if (!nextEditable) return;
      closeCreateBlockMenuList();
      event.preventDefault();
      focusEditable(nextEditable.editorKey, 0, 0);
      sendPresenceData(pageId, nextEditable.blockId);
    }
  };

  return useCallback(handleArrowRight, [
    closeCreateBlockMenuList,
    editorKey,
    isInRight,
    sendPresenceData,
    syncId,
    uuid,
  ]);
};

export const useArrowLeftKey = (uuid: string) => {
  const sendPresenceData = useSendPresenceData();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const isInRight = useIsInRight();
  const syncId = useSyncId();
  const editorKey = useEditorModelKey(uuid);
  const handleArrowLeft = (event: globalThis.KeyboardEvent) => {
    const caret = getCaretInfo(editorKey);
    if (!caret?.collapsed || !caret.isFirstChar) return;
    event.preventDefault();

    const blockId = parseEditorKeyToBlockId(uuid);
    const block = cache.blocks[blockId];
    if (!block) return;

    const pageId = getOwnerPage(block.uuid);
    if (!pageId) return;

    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const editableIndex = editableList.findIndex(
      (v) => v.blockId === block.uuid && v.syncId === syncId
    );
    const index = editableIndex - 1;
    if (index >= 0) {
      const prevEditable = editableList[index];
      if (!prevEditable) return;
      closeCreateBlockMenuList();
      event.preventDefault();
      focusEditable(prevEditable.editorKey, Infinity, Infinity);
      sendPresenceData(pageId, prevEditable.blockId);
      return;
    }

    const pageEditorKey = getEditorKey(pageId, undefined, isInRight);
    focusEditable(pageEditorKey, Infinity, Infinity);
  };

  return useCallback(handleArrowLeft, [
    closeCreateBlockMenuList,
    editorKey,
    isInRight,
    sendPresenceData,
    syncId,
    uuid,
  ]);
};
