import { cx } from '@flowus/common/cx';
import { useRef, type FC } from 'react';
import { COLOR_PICKER_DATA_TEXT_COLOR } from 'src/components/color-picker/default-data';
import {
  useChartFormat,
  useNumberChartColor,
  useNumberDesc,
  useNumberFormat,
  useNumberFormatCount,
  useUpdateChartFormatStyle,
} from 'src/hooks/chart';
import { getBlockTextColor } from '@flowus/common/block/color/get-block-color';
import { useIsDarkMode } from '@flowus/common';
import { useOpenModal } from '@flowus/common/next-modal';
import { NumberFormat } from 'src/bitable/table-view/widgets/number-format';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { useChartBlockId } from '../chart-config-context';
import { Select } from '../select';
import { Input } from '@flowus/common/components/input';
import { ChartLine } from '../../chart-line';

export const NumberChartStyle: FC = () => {
  const chartBlockId = useChartBlockId();
  const updateFormatStyle = useUpdateChartFormatStyle();
  const colorKey = useNumberChartColor(chartBlockId);
  const openModal = useOpenModal();
  const selectDivRef = useRef<HTMLDivElement>(null);
  const numberFormatCount = useNumberFormatCount(chartBlockId);
  const numberFormat = useNumberFormat(chartBlockId);
  const numberDesc = useNumberDesc(chartBlockId);
  // 为了rerender
  useIsDarkMode();
  const isNumberFormat = numberFormat === 'number';
  const found = NumberFormat.find((i) => i.en === numberFormat);

  return (
    <div>
      <div className="text-t2-medium">颜色</div>
      <div className="flex items-center mt-2 justify-between">
        {COLOR_PICKER_DATA_TEXT_COLOR.items.slice(1).map((v) => {
          const isSelected = v.colorkey === colorKey;
          const backgroundColor = getBlockTextColor(v.colorkey);
          return (
            <div
              key={v.colorkey}
              className={cx('animate-hover p-1', {
                'bg-black_006': isSelected,
              })}
              onClick={() => {
                updateFormatStyle(chartBlockId, {
                  numberColor: v.colorkey,
                });
              }}
            >
              <div
                className="cursor-pointer rounded-full w-5 h-5"
                style={{
                  backgroundColor,
                }}
              />
            </div>
          );
        })}
      </div>
      <ChartLine className="my-4" />
      <div className="text-t2-medium ">数值格式</div>
      <div className="text-t2 flex items-center space-x-4">
        <div className="shrink-0">数据格式</div>
        <Select
          title={found?.zh}
          ref={selectDivRef}
          className="w-full"
          placeHolder="请选择数据格式"
          onClick={(e) => {
            const width = selectDivRef.current?.getBoundingClientRect().width;
            const items = getNumberFormatItems(numberFormat);
            openModal.dropdown({
              popcorn: e.currentTarget,
              content: ({ onCloseModal }) => {
                return (
                  <ListView
                    style={{
                      width,
                    }}
                    className="next-modal"
                    items={items}
                    onItemClick={(item) => {
                      updateFormatStyle(chartBlockId, {
                        numberFormat: item.data.en,
                      });
                      onCloseModal();
                    }}
                  />
                );
              },
            });
          }}
        />
      </div>
      {isNumberFormat && (
        <div className="mt-3 text-t2 flex items-center space-x-4">
          <div className="shrink-0">小数位数</div>
          <Input
            type="number"
            inputType="number"
            className="text-t2 w-full h-7.5 bg-white1"
            value={numberFormatCount}
            focusWidthBorder={false}
            placeholder="请输入小数位数"
            onChange={(v) => {
              if (v > 10) {
                v = 10;
              }
              updateFormatStyle(chartBlockId, {
                numberFormatCount: v,
              });
            }}
          />
        </div>
      )}
      <ChartLine className="my-4" />
      <div className="text-t2-medium">数值说明</div>
      <Input
        className="text-t2 w-full h-7.5 bg-white1 mt-2.5"
        value={numberDesc}
        focusWidthBorder={false}
        placeholder="请输入说明内容"
        onChange={(v) => {
          updateFormatStyle(chartBlockId, {
            numberDesc: v,
          });
        }}
      />
    </div>
  );
};

const getNumberFormatItems = (currentNumberFormat: string) => {
  const items: ListItem<any>[] = [];

  NumberFormat.forEach((item, index) => {
    if (index === 7) {
      items.push({
        type: ListItemType.LINE,
      });
    }

    items.push({
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data: {
        title: item.zh,
        followTitle: true,
        selected: currentNumberFormat === item.en,
        selectColor: currentNumberFormat === item.en ? 'var(--active_color)' : undefined,
        desc: item.example,
        ...item,
      },
    });
  });
  return items;
};
