import type { DateFormat, TimeFormat } from '@next-space/fe-api-idl';
import type { ICursor, IEditorModel } from '@next-space/fe-inlined';
import { newContent, newElement, sliceContent } from '@next-space/fe-inlined';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { PersonValueView } from 'src/bitable/table-view/cell/person';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { LoadingIcon } from 'src/common/components/loading-icon';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import type { PickerDate, Reminder } from 'src/editor/editor/plugin/date-picker/types';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useGetAllSpaceUsers, useUsers } from 'src/hooks/page/use-subscription-data';
import { $currentUserCache } from 'src/services/user/current-user';
import { searchUsers } from 'src/utils/search-util';
import {
  INLINE_DATE_TAG,
  INLINE_LINK_PAGE_TAG,
  INLINE_PERSON_TAG,
} from '../../../editor/inline/const';
import { DateTimePicker } from '../../../editor/plugin/date-picker';
import { CategoryType } from './types';
import { useEditorKeyword } from './use-editor-keyword';
import { useSearchPage } from './use-search-page';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';

const CURRENT_DATE = 'CURRENT_DATE';
const CURRENT_DATETIME = 'CURRENT_DATETIME';
const CUSTOM_DATE = 'CUSTOM_DATE';
const InlineDatePickerModal = 'InlineDatePickerModal';

const useGetInitData = () => {
  const users = useUsers();

  useGetAllSpaceUsers();

  return useCallback(
    (params: { onlyDate?: boolean; onlyPeoPel?: boolean; keyword: string; fold?: boolean }) => {
      const { onlyDate, keyword = '', onlyPeoPel, fold } = params;

      const dateArray = [
        // 这个挪到外面会有嵌套error
        {
          type: ListItemType.BLOCK_TEXT,
          data: {
            title: '日期',
          },
        },
        {
          type: ListItemType.OPERATION,
          data: {
            title: dayjs().format(DATE_FORMAT),
            type: CURRENT_DATE,
            categoryType: CategoryType.date,
          },
        },
        {
          type: ListItemType.OPERATION,
          data: {
            title: dayjs().format(DATE_TIME_FORMAT),
            type: CURRENT_DATETIME,
            categoryType: CategoryType.date,
          },
        },
        {
          type: ListItemType.OPERATION,
          data: {
            title: '自定义日期',
            type: CUSTOM_DATE,
            categoryType: CategoryType.date,
          },
        },
      ];

      if (onlyDate) {
        return { date: dateArray, hasPeople: false, users: [] };
      }
      const usersItem = searchUsers(users, keyword)
        .map((item) => ({
          type: ListItemType.OPERATION,
          data: {
            categoryType: CategoryType.person,
            userId: item.uuid,
            content: <PersonValueView userId={item.uuid} className="text-t2 !mx-0 !px-0" />,
          },
        }))
        .sort((a, b) =>
          a.data.userId === $currentUserCache.uuid || b.data.userId === $currentUserCache.uuid
            ? -1
            : 1
        );

      const people = [];
      const maxCount = 5;

      if (fold && usersItem.length > maxCount && keyword) {
        people.push(...usersItem.slice(0, maxCount));
        people.push({
          type: ListItemType.OPERATION,
          data: {
            categoryType: CategoryType.foldUser,
            title: `查看其他${usersItem.length - maxCount}个成员`,
          },
        });
      } else if (keyword || !fold) {
        people.push(...usersItem);
      } else {
        people.push(...usersItem.slice(0, 3));
        if (usersItem.length > 3) {
          people.push({
            type: ListItemType.OPERATION,
            data: {
              categoryType: CategoryType.foldUser,
              title: `查看所有成员`,
            },
          });
        }
      }

      const userArray = [
        {
          type: ListItemType.BLOCK_TEXT,
          isHidden: people.length === 0,
          data: {
            title: '成员',
          },
        },
        ...people,
      ];

      if (onlyPeoPel) {
        return { users: userArray, hasPeople: people.length > 0 };
      }

      return { users: [...dateArray, ...userArray], hasPeople: people.length > 0 };
    },
    [users]
  );
};

export const InlineDatePanel: FC<{
  onCloseModal: ModalSchema.CloseModalType;
  blockId: string;
  left: number;
  right: number;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  onlyDate?: boolean;
  onGlobalCaptureKeydown?: (ev: KeyboardEvent) => void;
}> = ({ onCloseModal, blockId, left, right, onGlobalCaptureKeydown, onlyDate, getEditorModel }) => {
  const keyword = useEditorKeyword({
    left,
    right,
    blockId,
    onCloseModal,
    getEditorModel,
  });
  const [userFold, setUserFold] = useState(true);
  const updateTask = useUpdateTask();
  const getInitData = useGetInitData();
  const openMenuList = useOpenInlineDatePicker();

  const { pageList, setPageFold, loading } = useSearchPage({
    onlyDate,
    keyword,
    onChange() {
      setUserFold(true);
    },
  });

  const { users, hasPeople, date } = getInitData({
    onlyDate,
    onlyPeoPel: !!keyword,
    keyword,
    fold: userFold,
  });

  const items = useMemo(() => {
    return [
      ...(date ?? []),
      ...(hasPeople ? users : []),
      {
        isHidden: onlyDate || (!loading && pageList.length === 0),
        type: ListItemType.BLOCK_TEXT,
        data: {
          title: '页面',
          icon: loading && <LoadingIcon size="large" />,
        },
      },
      ...pageList,
    ];
  }, [date, hasPeople, onlyDate, pageList, loading, users]);

  if (!onlyDate && !hasPeople && !pageList.length && keyword) {
    return null;
  }

  return (
    <ListView
      virtualList
      autoResetActiveIndex={false}
      defaultActiveIndex={1}
      className="w-64 py-2 next-modal overflow-y-auto min-h-8"
      items={items}
      onItemClick={(item, _, event) => {
        const editorModel = getEditorModel(blockId);
        if (!editorModel) return;
        if (!editorModel.selection) return;
        const { focusOffset } = editorModel.selection;
        if ([CategoryType.foldPage, CategoryType.foldUser].includes(item.data.categoryType)) {
          event.preventDefault();
          if (item.data.categoryType === CategoryType.foldPage) {
            // 积分任务
            void updateTask(ActivityIds.CREATE_REF_PAGE, ActivitiesListType.advancedList);

            setPageFold(false);
          } else {
            setUserFold(false);
          }
          void editorModel.requestFocus();
          return;
        }
        onCloseModal({
          closeAfterCallBack: () => {
            if (item.data.categoryType === CategoryType.person) {
              editorModel.performChange((ctx) => {
                ctx
                  .shadow()
                  .select(left, focusOffset)
                  .replace(
                    newContent([
                      newElement(INLINE_PERSON_TAG, {
                        userId: item.data.userId,
                      }),
                    ])
                  );
              });
              void editorModel.requestFocus();
            } else if (item.data.categoryType === CategoryType.page) {
              editorModel.performChange((ctx) => {
                ctx
                  .shadow()
                  .select(left, focusOffset)
                  .replace(
                    newContent([
                      newElement(INLINE_LINK_PAGE_TAG, {
                        pageId: item.data.uuid,
                      }),
                    ])
                  );
              });
              void editorModel.requestFocus();
              // 积分任务
              void updateTask(
                ActivityIds.GUIDE_FIRST_TIME_LINK_PAGE,
                ActivitiesListType.advancedList
              );
            } else {
              editorModel.performChange((ctx) => {
                ctx
                  .shadow()
                  .select(left, focusOffset)
                  .replace(
                    newContent([
                      newElement(INLINE_DATE_TAG, {
                        datetime: Date.now(),
                        includeTime: item.data.type === CURRENT_DATETIME,
                      }),
                    ])
                  );
              });
              if (item.data.type === CUSTOM_DATE) {
                openMenuList(left, right, () => getEditorModel(blockId));
              }
              void editorModel.requestFocus();

              // 积分任务
              void updateTask(ActivityIds.CREATE_DATE_MENTION, ActivitiesListType.basicList, {
                step1: true,
              });
            }
          },
        });
      }}
      onGlobalCaptureKeydown={onGlobalCaptureKeydown}
    />
  );
};

const InlineDatePicker: FC<{
  getEditorModel: () => IEditorModel | undefined;
  cursorLeft: ICursor;
  cursorRight: ICursor;
}> = ({ getEditorModel, cursorLeft, cursorRight }) => {
  const closeModal = useCloseModal();

  const [pickDate, setState] = useState<PickerDate>(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const editorModel = getEditorModel()!;
    const content = sliceContent(editorModel.content, cursorLeft.offset, cursorLeft.offset + 1);
    const item = content.items[0];
    const setTimeTo12PM = (date: Date) => {
      return dayjs(date).hour(12).toDate();
    };
    if (item && item.type === 'element' && item.tag === INLINE_DATE_TAG) {
      return {
        from: new Date(item.props.datetime as number),
        includeTime: item.props.includeTime as boolean,
        dateFormat: item.props.dateFormat as DateFormat,
        timeFormat: item.props.timeFormat as TimeFormat,
        reminder: item.props.reminder as Reminder,
      };
    }
    return {
      from: setTimeTo12PM(new Date()),
      includeTime: false,
    };
  });

  return (
    <DateTimePicker
      dateInfo={pickDate}
      uiOption={{
        hasRemind: true,
      }}
      // closeDatePicker={() => closeModal(InlineDatePickerModal)}
      onChange={(info) => {
        setState(info);

        const editorModel = getEditorModel();
        if (!editorModel) return;
        editorModel.performChange((ctx) => {
          ctx
            .select(cursorLeft.offset, cursorLeft.offset + 1)
            .replace(
              newContent([
                newElement(INLINE_DATE_TAG, {
                  datetime: info.from.getTime(),
                  includeTime: !!info.includeTime,
                  dateFormat: info.dateFormat,
                  timeFormat: info.timeFormat,
                  reminder: info.reminder,
                }),
              ])
            )
            .collapse('end');
        });
      }}
      onClear={() => {
        const editorModel = getEditorModel();
        if (!editorModel) return;

        closeModal(InlineDatePickerModal);
        editorModel.performChange((ctx) => {
          ctx.shadow().select(cursorLeft.offset, cursorRight.offset).delete();
        });
        void editorModel.requestFocus();
      }}
    />
  );
};

export const useOpenInlineDatePicker = () => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const openDatePicker = useCallback(
    (left: number, right: number, getEditorModel: () => IEditorModel | undefined) => {
      const editorModel = getEditorModel();
      if (!editorModel) return;
      const { selection } = editorModel;
      if (!selection) return;
      if (!selection.isCollapsed) return;

      const cursorLeft = editorModel.createCursor(left, 'left');
      const cursorRight = editorModel.createCursor(right, 'left');

      const subscriptionSelection = editorModel.onSelectionChange.subscribe(() => {
        if (editorModel.selection) {
          const { focusOffset } = editorModel.selection;
          if (focusOffset <= cursorLeft.offset || cursorRight.offset < focusOffset) {
            closeModal(InlineDatePickerModal);
          }
        }
      });

      openModal.dropdown({
        modalId: InlineDatePickerModal,
        closeBeforeCallBack: () => {
          subscriptionSelection.unsubscribe();
          cursorLeft.release();
          cursorRight.release();
        },
        popcorn: {
          getBoundingClientRect() {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return editorModel.getBoundingClientRectOfRange(cursorLeft.offset, cursorLeft.offset)!;
          },
        },
        placement: 'bottom-start',
        offset: [0, 15],
        content() {
          return (
            <InlineDatePicker
              getEditorModel={getEditorModel}
              cursorLeft={cursorLeft}
              cursorRight={cursorRight}
            />
          );
        },
      });
    },
    [closeModal, openModal]
  );
  return openDatePicker;
};
