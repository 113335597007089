import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import type { ListItem } from 'src/common/components/list-view/types';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { BitableMenu } from 'src/editor/editor/uikit/ai-editor/bitable-menu';
import {
  AiBitableEntry,
  AIEditorFrom,
  AIEditorScene,
  AIEditType,
  AiMindMapEntry,
} from 'src/editor/editor/uikit/ai-editor/const';
import {
  TRANSLATE_LANGUAGE,
  getCustomAIPrompsWithEmpty,
} from 'src/editor/editor/uikit/ai-editor/menu-items';
import { MindMapMenu } from 'src/editor/editor/uikit/ai-editor/mindmap-menu';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useCreateBlockMenuListId, useSlashSelection } from 'src/services/app';

interface Props {
  hasContent?: boolean;
}
export const MoreAI: FC<Props> = ({ hasContent }) => {
  const slashId = useCreateBlockMenuListId();
  const openAIEditor = useOpenAIEditor();
  const selection = useSlashSelection();
  const getEditorModelById = useGetOrInitEditorModel();

  const popcorn = document.querySelector(`[data-block-id="${slashId}"]`);
  if (!popcorn) return null;

  const onItemClick = (item: any) => {
    const { editType } = item.data;
    if (editType === AIEditType.Bitable) {
      item.data.editType = AIEditType.Bitable_Other;
    } else if (editType === AIEditType.MindMap) {
      item.data.editType = AIEditType.MindMap_Other;
    }

    const editorModel = getEditorModelById(slashId);
    editorModel?.performChange((ctx) => {
      ctx.shadow().select(selection.left, selection.right).delete().release();
    });

    openAIEditor({
      popcorn,
      blockId: slashId,
      from: AIEditorFrom.ContextMenu,
      editorScene: hasContent ? AIEditorScene.PageHasContent : AIEditorScene.PageEmpty,
      editType,
      defaultCustomPrompt: item.data?.prompt,
    });
  };

  let items: ListItem<any>[] = [];
  if (hasContent) {
    items = [
      {
        type: ListItemType.BLOCK_ITEM,
        data: {
          editType: AIEditType.Summarize,
          title: '总结',
          icon: 'IcSummarize',
        },
      },
      {
        type: ListItemType.BLOCK_ITEM,
        data: {
          editType: AIEditType.Summary,
          icon: 'IcSummary',
          title: '摘要',
        },
      },
      {
        type: ListItemType.BLOCK_ITEM,
        data: {
          editType: AIEditType.Explain,
          icon: 'IcExplain',
          title: '解释',
        },
      },
    ];
  } else {
    const bitableAlreadyClick = localStorage.getItem(AiBitableEntry);
    const mindMapAlreadyClick = localStorage.getItem(AiMindMapEntry);
    const writeItems = getCustomAIPrompsWithEmpty(onItemClick);
    items = writeItems.map((item) => {
      let attribute = {};
      const writeType = item.data;
      if (writeType.editType === AIEditType.Bitable) {
        attribute = {
          textClassName: bitableAlreadyClick ? undefined : 'text-red',
          rightText: bitableAlreadyClick ? undefined : 'NEW',
          renderSubMenu: () => {
            return <BitableMenu onItemClick={onItemClick} />;
          },
        };
      } else if (writeType.editType === AIEditType.MindMap) {
        attribute = {
          textClassName: mindMapAlreadyClick ? undefined : 'text-red',
          rightText: mindMapAlreadyClick ? undefined : 'NEW',
          renderSubMenu: () => {
            return <MindMapMenu onItemClick={onItemClick} />;
          },
        };
      }
      return { type: ListItemType.OPERATION, data: { ...writeType, ...attribute } };
    });
  }

  return (
    <ListView
      keyDownPriority={PRIORITY_DIALOG + 1}
      className={listViewNormalClassName}
      items={items}
      onItemClick={onItemClick}
    />
  );
};

export const AITranslate = () => {
  const slashId = useCreateBlockMenuListId();
  const openAIEditor = useOpenAIEditor();
  const selection = useSlashSelection();
  const getEditorModelById = useGetOrInitEditorModel();

  const popcorn = document.querySelector(`[data-block-id="${slashId}"]`);
  if (!popcorn) return null;

  const items = TRANSLATE_LANGUAGE.map((language) => ({
    type: ListItemType.BLOCK_ITEM,
    data: language,
  }));

  return (
    <ListView
      keyDownPriority={PRIORITY_DIALOG + 1}
      className={listViewNormalClassName}
      items={items}
      onItemClick={({ data }) => {
        const { editType } = data;

        const editorModel = getEditorModelById(slashId);
        editorModel?.performChange((ctx) => {
          ctx.shadow().select(selection.left, selection.right).delete().release();
        });
        openAIEditor({
          popcorn,
          blockId: slashId,
          from: AIEditorFrom.ContextMenu,
          editorScene: AIEditorScene.PageHasContent,
          editType,
        });
      }}
    />
  );
};
