import { createCache, createSetState, createStore } from '@flowus/common/zustand/utils';
import produce from 'immer';
import { assign } from 'lodash-es';
import type { request } from 'src/common/request';
import type { AsyncReturnType } from 'type-fest';

type StateType = AsyncReturnType<typeof request.infra.getSpacesPublicData>['spaces'];

const initialState: StateType = {};
export const $publicSpaces = createStore<StateType>(() => initialState);
export const $publicSpacesCache = createCache($publicSpaces);
const $setPublicSpaces = createSetState($publicSpaces);

export const updatePublicSpaces = (value: StateType) => {
  $setPublicSpaces(
    produce((pre: StateType) => {
      return assign(pre, {
        ...value,
        setting: {
          ...value.setting,
          inlinePageIcon: value.inlinePageIcon,
        },
      });
    })
  );
};
