export enum EquationTemplateMenuEnum {
  symbols = 'symbols',
  math = 'math',
  physical = 'physical',
  chemistry = 'chemistry',
}

export enum EquationSymbolsEnum {
  math = 'math',
  func = 'func',
  arrow = 'arrow',
  greek = 'greek',
}

export const equationTemplateMenu = [
  {
    icon: 'IcMenuSymbol',
    title: '常用符号',
    isGroup: true,
    type: EquationTemplateMenuEnum.symbols,
    group: [
      {
        title: '数学',
        value: EquationSymbolsEnum.math,
      },
      {
        title: '函数',
        value: EquationSymbolsEnum.func,
      },
      {
        title: '箭头',
        value: EquationSymbolsEnum.arrow,
      },
      {
        title: '希腊字母',
        value: EquationSymbolsEnum.greek,
      },
    ],
  },
  {
    icon: 'IcMenuMath',
    title: '数学公式',
    isGroup: false,
    type: EquationTemplateMenuEnum.math,
    group: [],
  },
  {
    icon: 'IcMenuPhysics',
    title: '物理公式',
    isGroup: false,
    type: EquationTemplateMenuEnum.physical,
    group: [],
  },
  {
    icon: 'IcMenuChemistry',
    title: '化学公式',
    isGroup: false,
    type: EquationTemplateMenuEnum.chemistry,
    group: [],
  },
];

export const equationFormula = {
  symbols: {
    [EquationSymbolsEnum.math]: [
      {
        symbols: [
          {
            cmd: '\\times',
          },
          {
            cmd: '\\div',
          },
          {
            cmd: '\\pm',
          },
          {
            cmd: '\\mp',
          },
          {
            cmd: '\\cdot',
          },
          {
            cmd: '\\star',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\sqrt{ab}',
          },
          {
            cmd: '\\sqrt[n]{ab}',
          },
          {
            cmd: '\\log_{a}{b}',
          },
          {
            cmd: '\\lg{ab}',
          },
          {
            cmd: 'a^{b}',
          },
          {
            cmd: 'a_{b}',
          },
          {
            cmd: 'c_a^b',
          },
          {
            cmd: '\\widetilde{ab}',
          },
          {
            cmd: '\\widehat{ab}',
          },
          {
            cmd: '\\overleftarrow{ab}',
          },
          {
            cmd: '\\overrightarrow{ab}',
          },
          {
            cmd: '\\overbrace{ab}',
          },
          {
            cmd: '\\underbrace{ab}',
          },
          {
            cmd: '\\underline{ab}',
          },
          {
            cmd: '\\overline{ab}',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\neq',
          },
          {
            cmd: '\\leq',
          },
          {
            cmd: '\\geq',
          },
          {
            cmd: '\\sim',
          },
          {
            cmd: '\\approx',
          },
          {
            cmd: '\\cong',
          },
          {
            cmd: '\\equiv',
          },
          {
            cmd: '\\propto',
          },
          {
            cmd: '\\ll',
          },
          {
            cmd: '\\gg',
          },
          {
            cmd: '\\in',
          },
          {
            cmd: '\\subset',
          },
          {
            cmd: '\\subseteq',
          },
          {
            cmd: '\\prec',
          },
          {
            cmd: '\\preceq',
          },
          {
            cmd: '\\simeq',
          },
          {
            cmd: '\\asymp',
          },
          {
            cmd: '\\doteq',
          },
          {
            cmd: '\\succ',
          },
          {
            cmd: '\\succeq',
          },
          {
            cmd: '\\sqsubseteq',
          },
          {
            cmd: '\\sqsupseteq',
          },
          {
            cmd: '\\ni',
          },
          {
            cmd: '\\models',
          },
          {
            cmd: '\\vdash',
          },
          {
            cmd: '\\dashv',
          },
          {
            cmd: '\\perp',
          },
          {
            cmd: '\\mid',
          },
          {
            cmd: '\\parallel',
          },
          {
            cmd: '\\smile',
          },
          {
            cmd: '\\frown',
          },
          {
            cmd: '\\bowtie',
          },
          {
            cmd: '\\unlhd',
          },
          {
            cmd: '\\unrhd',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\hat{a}',
          },
          {
            cmd: '\\check{a}',
          },
          {
            cmd: '\\breve{a}',
          },
          {
            cmd: '\\acute{a}',
          },
          {
            cmd: '\\grave{a}',
          },
          {
            cmd: '\\tilde{a}',
          },
          {
            cmd: '\\bar{a}',
          },
          {
            cmd: '\\vec{a}',
          },
          {
            cmd: '\\dot{a}',
          },
          {
            cmd: '\\ddot{a}',
          },
        ],
      },
    ],
    [EquationSymbolsEnum.func]: [
      {
        symbols: [
          {
            cmd: '\\frac{ab}{cd}',
          },
          {
            cmd: '\\frac{\\partial a}{\\partial b}',
          },
          {
            cmd: '\\frac{\\text{d}x}{\\text{d}y}',
          },
          {
            cmd: '\\lim\\limits_{a \\rightarrow b}',
          },
          {
            cmd: '\\int_{a}^{b}',
          },
          {
            cmd: '\\oint_a^b',
          },
          {
            cmd: '\\prod\\limits_a^b',
          },
          {
            cmd: '\\coprod\\limits_a^b',
          },
          {
            cmd: '\\bigcap\\limits_a^b',
          },
          {
            cmd: '\\bigcup\\limits_a^b',
          },
          {
            cmd: '\\bigvee\\limits_a^b',
          },
          {
            cmd: '\\bigwedge\\limits_a^b',
          },
          {
            cmd: '\\bigsqcup\\limits_a^b',
          },
          {
            cmd: '\\sum\\limits_a^b',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\left(\\begin{array}{c}a\\\\ b\\end{array}\\right)',
          },
          {
            cmd: '\\begin{bmatrix}a & b \\\\c & d \\end{bmatrix}',
          },
          {
            cmd: '\\begin{cases}a & x = 0\\\\b & x > 0\\end{cases}',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\arccos',
          },
          {
            cmd: '\\arcsin',
          },
          {
            cmd: '\\arctan',
          },
          {
            cmd: '\\cos',
          },
          {
            cmd: '\\cosh',
          },
          {
            cmd: '\\cot',
          },
          {
            cmd: '\\coth',
          },
          {
            cmd: '\\csc',
          },
          {
            cmd: '\\sec',
          },
          {
            cmd: '\\sin',
          },
          {
            cmd: '\\sinh',
          },
          {
            cmd: '\\tan',
          },
          {
            cmd: '\\tanh',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\exp',
          },
          {
            cmd: '\\log',
          },
          {
            cmd: '\\ln',
          },
          {
            cmd: '\\max',
          },
          {
            cmd: '\\min',
          },
          {
            cmd: '\\sup',
          },
          {
            cmd: '\\inf',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\lim',
          },
          {
            cmd: '\\gcd',
          },
          {
            cmd: '\\hom',
          },
          {
            cmd: '\\ker',
          },
          {
            cmd: '\\det',
          },
          {
            cmd: '\\bmod',
          },
        ],
      },
    ],
    [EquationSymbolsEnum.arrow]: [
      {
        symbols: [
          {
            cmd: '()',
          },
          {
            cmd: '[]',
          },
          {
            cmd: '\\{\\}',
          },
          {
            cmd: '\\lfloor',
          },
          {
            cmd: '\\lceil',
          },
          {
            cmd: '\\rfloor',
          },
          {
            cmd: '\\rceil',
          },
          {
            cmd: '\\langle',
          },
          {
            cmd: '\\rangle',
          },
          {
            cmd: '\\backslash',
          },
          {
            cmd: '\\cap',
          },
          {
            cmd: '\\cup',
          },
          {
            cmd: '\\uplus',
          },
          {
            cmd: '\\sqcap',
          },
          {
            cmd: '\\sqcup',
          },
          {
            cmd: '\\vee',
          },
          {
            cmd: '\\wedge',
          },
          {
            cmd: '\\wr',
          },
        ],
      },
      {
        symbols: [
          {
            cmd: '\\leftarrow',
          },
          {
            cmd: '\\Leftarrow',
          },
          {
            cmd: '\\rightarrow',
          },
          {
            cmd: '\\Rightarrow',
          },
          {
            cmd: '\\leftrightarrow',
          },
          {
            cmd: '\\Leftrightarrow',
          },
          {
            cmd: '\\mapsto',
          },
          {
            cmd: '\\leftharpoonup',
          },
          {
            cmd: '\\leftharpoondown',
          },
          {
            cmd: '\\uparrow',
          },
          {
            cmd: '\\downarrow',
          },
          {
            cmd: '\\leftleftarrows',
          },
          {
            cmd: '\\rightrightarrows',
          },
          {
            cmd: '\\leftrightarrows',
          },
          {
            cmd: '\\rightleftarrows',
          },
        ],
      },
    ],
    [EquationSymbolsEnum.greek]: [
      {
        symbols: [
          {
            cmd: '\\alpha',
          },
          {
            cmd: '\\beta',
          },
          {
            cmd: '\\gamma',
          },
          {
            cmd: '\\delta',
          },
          {
            cmd: '\\epsilon',
          },
          {
            cmd: '\\zeta',
          },
          {
            cmd: '\\eta',
          },
          {
            cmd: '\\theta',
          },
          {
            cmd: '\\iota',
          },
          {
            cmd: '\\kappa',
          },
          {
            cmd: '\\lambda',
          },
          {
            cmd: '\\mu',
          },
          {
            cmd: '\\nu',
          },
          {
            cmd: '\\xi',
          },
          {
            cmd: '\\pi',
          },
          {
            cmd: '\\rho',
          },
          {
            cmd: '\\sigma',
          },
          {
            cmd: '\\tau',
          },
          {
            cmd: '\\upsilon',
          },
          {
            cmd: '\\phi',
          },
          {
            cmd: '\\chi',
          },
          {
            cmd: '\\psi',
          },
          {
            cmd: '\\omega',
          },
        ],
      },
    ],
  },
  disciplineFormula: {
    [EquationTemplateMenuEnum.math]: [
      {
        title: '傅里叶级数',
        symbols: [
          {
            cmd: 'f(x) = {{{a_0}} \\over 2} + \\sum\\limits_{n = 1}^\\infty {({a_n}\\cos {nx} + {b_n}\\sin {nx})}',
          },
        ],
      },
      {
        title: '泰勒展开式',
        symbols: [
          {
            cmd: 'e ^ { x } = 1 + \\frac { x } { 1 ! } + \\frac { x ^ { 2 } } { 2 ! } + \\frac { x ^ { 3 } } { 3 ! } + \\cdots , \\quad - \\infty < x < \\infty',
          },
        ],
      },
      {
        title: '高斯公式',
        symbols: [
          {
            cmd: '\\iiint _ { \\Omega } \\left( \\frac { \\partial {P} } { \\partial {x} } + \\frac { \\partial {Q} } { \\partial {y} } + \\frac { \\partial {R} }{ \\partial {z} } \\right) \\mathrm { d } V = \\oint _ { \\partial \\Omega } ( P \\cos \\alpha + Q \\cos \\beta + R \\cos \\gamma ) \\mathrm{ d} S',
          },
        ],
      },
      {
        title: '定积分',
        symbols: [
          {
            cmd: '\\lim\\limits_ { n \\rightarrow + \\infty } \\sum _ { i = 1 } ^ { n } f \\left[ a + \\frac { i } { n } ( b - a ) \\right] \\frac { b - a } { n } = \\int _ { a } ^ { b } f ( x ) dx',
          },
        ],
      },
      {
        title: '和的展开式',
        symbols: [
          {
            cmd: '( 1 + x ) ^ { n } = 1 + \\frac { n x } { 1 ! } + \\frac { n ( n - 1 ) x ^ { 2 } } { 2 ! } + \\cdots',
          },
        ],
      },
      {
        title: '三角恒等式1',
        symbols: [
          {
            cmd: '\\sin \\alpha \\pm \\sin \\beta = 2 \\sin \\frac { 1 } { 2 } ( \\alpha \\pm \\beta ) \\cos \\frac { 1 } { 2 } ( \\alpha \\mp \\beta )',
          },
        ],
      },
      {
        title: '三角恒等式2',
        symbols: [
          {
            cmd: '\\cos \\alpha + \\cos \\beta = 2 \\cos \\frac { 1 } { 2 } ( \\alpha + \\beta ) \\cos \\frac { 1 } { 2 } ( \\alpha - \\beta )',
          },
        ],
      },
      {
        title: '欧拉公式',
        symbols: [
          {
            cmd: '{e^{ix}} = \\cos {x} + i\\sin {x}',
          },
        ],
      },
      {
        title: '格林公式',
        symbols: [
          {
            cmd: '\\iint\\limits_D {({{\\partial Q} \\over {\\partial x}} - {{\\partial P} \\over {\\partial y}})dxdy = \\oint\\limits_L {Pdx + Qdy} }',
          },
        ],
      },
      {
        title: '贝努力方程',
        symbols: [
          {
            cmd: '{{dy} \\over {dx}} + P(x)y = Q(x){y^n}(n \\ne 0,1)',
          },
        ],
      },
      {
        title: '全微分方程',
        symbols: [
          {
            cmd: 'du(x,y) = P(x,y)dx + Q(x,y)dy = 0',
          },
        ],
      },
      {
        title: '非齐次方程',
        symbols: [
          {
            cmd: 'y = (\\int {Q(x){e^{\\int {P(x)dx} }}dx + C){e^{ - \\int {P(x)dx} }}}',
          },
        ],
      },
      {
        title: '柯西中值定理',
        symbols: [
          {
            cmd: "\\frac{{f(b) - f(a)}}{{F(b) - F(a)}} = \\frac{{f'(\\xi )}}{{F'(\\xi )}}",
          },
        ],
      },
      {
        title: '拉格朗日中值定理',
        symbols: [
          {
            cmd: "f(b) - f(a) = f'(\\xi )(b - a)",
          },
        ],
      },
      {
        title: '曲面面积',
        symbols: [
          {
            cmd: 'A = \\iint\\limits_D {\\sqrt {1 + {{\\left( {{{\\partial z} \\over {\\partial x}}} \\right)}^2} + {{\\left( {{{\\partial z} \\over {\\partial y}}} \\right)}^2}} dxdy}',
          },
        ],
      },
      {
        title: '重积分',
        symbols: [
          {
            cmd: "\\iint\\limits_D {f(x,y)dxdy} = \\iint\\limits_{D'} {f(r\\cos \\theta ,r\\sin \\theta )rdrd\\theta}",
          },
        ],
      },
      {
        title: '导数公式',
        symbols: [
          {
            cmd: "(\\arcsin x)' = \\frac{1}{{\\sqrt {1 - {x^2}} }}",
          },
        ],
      },
      {
        title: '三角函数积分',
        symbols: [
          {
            cmd: '\\int {tgxdx = - \\ln \\left| {\\cos x} \\right| + C}',
          },
        ],
      },
      {
        title: '二次曲面',
        symbols: [
          {
            cmd: '\\frac{{{x^2}}}{{{a^2}}} + \\frac{{{y^2}}}{{{b^2}}} - \\frac{{{z^2}}}{{{c^2}}} = 1',
          },
        ],
      },
      {
        title: '二阶微分',
        symbols: [
          {
            cmd: '{{{d^2}y} \\over {d{x^2}}} + P(x){{dy} \\over {dx}} + Q(x)y = f(x)',
          },
        ],
      },
      {
        title: '方向导数',
        symbols: [
          {
            cmd: '\\frac{{\\partial f}}{{\\partial l}} = \\frac{{\\partial f}}{{\\partial x}}\\cos \\phi + \\frac{{\\partial f}}{{\\partial y}}\\sin \\phi',
          },
        ],
      },
    ],
    [EquationTemplateMenuEnum.physical]: [
      {
        title: '牛顿第一运动定律',
        symbols: [
          {
            cmd: '\\sum \\vec{F}_{i}=\\frac{d \\vec{v}}{d t}=0',
          },
        ],
      },
      {
        title: '牛顿第二运动定律',
        symbols: [
          {
            cmd: '\\vec{F}=m \\vec{a}=m \\frac{d^{2} \\vec{r}}{d t^{2}}',
          },
        ],
      },
      {
        title: '牛顿第三运动定律',
        symbols: [
          {
            cmd: '\\vec{F}_{12}=-\\vec{F}_{21}',
          },
        ],
      },
      {
        title: '万有引力势能',
        symbols: [
          {
            cmd: '{E_p} = -\\frac{{GMm}}{r}',
          },
        ],
      },
      {
        title: '库仑定律',
        symbols: [
          {
            cmd: '\\vec{F}=k \\frac{Q q}{r^{2}} \\hat{r}',
          },
        ],
      },
      {
        title: '静电场环路定理',
        symbols: [
          {
            cmd: '\\oint_{L} \\vec{E} \\cdot d \\vec{I}=0',
          },
        ],
      },
      {
        title: '毕奥.萨伐尔定律',
        symbols: [
          {
            cmd: 'd \\vec{B}=\\frac{\\mu_{0}}{4 \\pi} \\frac{I d I \\times \\vec{r}}{r^{3}}=\\frac{\\mu_{0}}{4 \\pi} \\frac{I d I \\sin \\theta}{r^{2}}',
          },
        ],
      },
      {
        title: '安培定理',
        symbols: [
          {
            cmd: 'd \\vec{F}= Id \\vec{l} \\times \\vec{B}',
          },
        ],
      },
      {
        title: '法拉第电磁感应定律',
        symbols: [
          {
            cmd: 'E = n{{ \\Delta \\Phi } \\over {\\Delta {t} }}',
          },
        ],
      },
      {
        title: '高斯定理',
        symbols: [
          {
            cmd: '\\left.\\Phi_{e}=\\oint \\vec{E} \\cdot d \\vec{S}=\\frac{1}{\\varepsilon_{0}}\\right\\rangle q',
          },
        ],
      },
      {
        title: '电场的环路定理',
        symbols: [
          {
            cmd: '\\oint \\vec{E} \\cdot d \\vec{I}=-\\frac{d \\varphi_{B}}{d t}',
          },
        ],
      },
      {
        title: '安培环路定理',
        symbols: [
          {
            cmd: '\\oint \\vec{B} \\cdot d \\vec{I}=\\mu_{0} I+\\mu_{0} I_{d}',
          },
        ],
      },
      {
        title: '焦耳定律',
        symbols: [
          {
            cmd: 'Q = I ^ { 2 } R \\mathrm { t }',
          },
        ],
      },
      {
        title: '万有引力定律',
        symbols: [
          {
            cmd: 'F = G{{Mm} \\over {{r^2}}}',
          },
        ],
      },
      {
        title: '爱因斯坦光电效应方程',
        symbols: [
          {
            cmd: '{E_k} = hv - {W_0}',
          },
        ],
      },
      {
        title: '德布罗意波波长公式',
        symbols: [
          {
            cmd: '\\lambda = \\frac{{ \\frac{{{c^2}}}{v}}}{{ \\frac{{m{c^2}}}{h}}} = \\frac{h}{{mv}} = \\frac{h}{p}',
          },
        ],
      },
      {
        title: '海森堡不确定性原理',
        symbols: [
          {
            cmd: '\\Delta {x} \\Delta {p} \\ge \\frac{h}{{4 \\pi }}',
          },
        ],
      },
      {
        title: '狭义相对论长度',
        symbols: [
          {
            cmd: 'l = {l_0} \\sqrt {1 - {{( \\frac{v}{c})}^2}}',
          },
        ],
      },
      {
        title: '简谐振动',
        symbols: [
          {
            cmd: '{y_0} = A \\cos ( \\omega {t} + { \\varphi _0})',
          },
        ],
      },
      {
        title: '波形方程',
        symbols: [
          {
            cmd: 'y(t) = A \\cos ( \\frac{{2 \\pi {x}}}{ \\lambda } + \\varphi )',
          },
        ],
      },
      {
        title: '多普勒效应',
        symbols: [
          {
            cmd: "f' = \\left( { \\frac{{v \\pm {v_0}}}{{v \\pm {v_s}}}} \\right)f",
          },
        ],
      },
    ],
    [EquationTemplateMenuEnum.chemistry]: [
      {
        title: '铝和稀硫酸',
        symbols: [
          {
            cmd: '\\ce{2Al + 3{H_2}S{O_4} = A{l_2}{(S{O_4})_3} + 3{H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '铝和稀盐酸',
        symbols: [
          {
            cmd: '\\ce{2Al + 6HCl = 2AlC{l_3} + 3{H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '镁和稀硫酸',
        symbols: [
          {
            cmd: '\\ce{Mg + {H_2}S{O_4} = MgS{O_4} + {H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '镁和稀盐酸溶液',
        symbols: [
          {
            cmd: '\\ce{Mg + 2HCl = MgC{l_2} + {H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '铁和硫酸铜溶液',
        symbols: [
          {
            cmd: '\\ce{Fe + CuS{O_4} = FeS{O_4} + Cu}',
          },
        ],
      },
      {
        title: '铁和稀硫酸',
        symbols: [
          {
            cmd: '\\ce{Fe + {H_2}S{O_4} = FeS{O_4} + {H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '铁和稀盐酸',
        symbols: [
          {
            cmd: '\\ce{Fe + 2HCl = FeC{l_2} + {H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '锌和稀硫酸',
        symbols: [
          {
            cmd: '\\ce{Zn + {H_2}S{O_4} = ZnS{O_4} + {H_2} \\uparrow}',
          },
        ],
      },
      {
        title: '锌和稀盐酸',
        symbols: [
          {
            cmd: '\\ce{Zn + 2HCl = ZnC{l_2} + {H_2} \\uparrow}',
          },
        ],
      },
    ],
  },
};
