import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import { UNTITLED_NAMES } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';

/**
 * @returns 未命名文件夹｜未命名页面｜未命名文件
 */

export const getUntitledName = (type?: BlockType) => {
  if (type == null) {
    return '';
  }
  return UNTITLED_NAMES[type] ?? UNTITLED_NAMES[BlockType.PAGE] ?? '';
};

export const getBlockPageLikeTitle = (type?: BlockType, segment?: SegmentDTO[]) => {
  const text = segmentsToText(segment);
  if (text) {
    return text;
  }
  return getUntitledName(type);
};
