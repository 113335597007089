import { getBarFormatStyle, getPieFormatStyle } from '@flowus/chart/chart-util';
import type { ChartFormat } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { ColorKey } from 'src/colors';
import { updateBlock } from 'src/redux/managers/block/update';
import type { RootState } from 'src/redux/types';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';

export const useUpdateChartCollectionId = () => {
  const store = useStore();
  return useCallback(
    (chartId: string, collectionId: string, viewId: string) => {
      updateBlock(
        chartId,
        {
          data: {
            format: {
              chartFormat: {
                ref: {
                  uuid: collectionId,
                  viewId,
                },
              },
            },
          },
        },
        false,
        // @ts-ignore typecheck
        store
      );
    },
    [store]
  );
};
/** 更新chartformat字段 */
export const useUpdateChartFormat = () => {
  const store = useStore();
  return useCallback(
    (chartId: string, format: ChartFormat) => {
      const state: RootState = store.getState() as RootState;
      const chartBlock = state.blocks[chartId];
      updateBlock(
        chartId,
        {
          data: {
            format: {
              chartFormat: {
                ...chartBlock?.data.format?.chartFormat,
                ...format,
              },
            },
          },
        },
        false,
        // @ts-ignore typecheck
        store
      );
    },
    [store]
  );
};
/** 更新chartformat.style字段 */
export const useUpdateChartFormatStyle = () => {
  const store = useStore();
  return useCallback(
    (chartId: string, styleFormat: ChartFormat['style']) => {
      const state: RootState = store.getState() as RootState;
      const chartBlock = state.blocks[chartId];
      updateBlock(
        chartId,
        {
          data: {
            format: {
              chartFormat: {
                ...chartBlock?.data.format?.chartFormat,
                style: {
                  ...chartBlock?.data.format?.chartFormat?.style,
                  ...styleFormat,
                },
              },
            },
          },
        },
        false,
        // @ts-ignore typecheck
        store
      );
    },
    [store]
  );
};

export const useChartFormat = (id: string) => {
  return useObservableBlock(id, (b) => b?.data.format?.chartFormat);
};
export const useChartTheme = (id: string) => {
  return useObservableBlock(id, (b) => b?.data.format?.chartFormat?.style?.theme ?? 'theme0');
};

export const useNumberChartColor = (id: string) => {
  return useObservableBlock(
    id,
    (s) => s?.data.format?.chartFormat?.style?.numberColor ?? ColorKey.blue
  );
};
export const useNumberFormatCount = (id: string) => {
  return useObservableBlock(id, (s) => s?.data.format?.chartFormat?.style?.numberFormatCount ?? 0);
};
export const useNumberFormat = (id: string) => {
  return useObservableBlock(
    id,
    (s) => s?.data.format?.chartFormat?.style?.numberFormat ?? 'number'
  );
};
export const useNumberDesc = (id: string) => {
  return useObservableBlock(id, (s) => s?.data.format?.chartFormat?.style?.numberDesc ?? '');
};
/** 图例位置 */
export const useLegendAlignPosition = (id: string) => {
  return useObservableBlock(
    id,
    (s) => s?.data.format?.chartFormat?.style?.legendAlignPosition ?? 'top'
  );
};
/** 由于配置页比较简单，不需要考虑renrender，直接全部属性返回 */
export const useBarChartStyle = (id: string) => {
  return useObservableBlock(id, (s) => {
    return getBarFormatStyle(s?.data.format?.chartFormat);
  });
};
export const usePieChartStyle = (id: string) => {
  return useObservableBlock(id, (s) => {
    return getPieFormatStyle(s?.data.format?.chartFormat);
  });
};
