import { create } from 'zustand';
import type { PromptStore } from './types';

const initState: PromptStore = {
  editPrompts: [],
  emptyWithPrompts: [],
  records: {},
  hasUpdate: false,
};

export const usePromptStore = create<PromptStore>(() => initState);
