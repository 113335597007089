import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState, useRef } from 'react';

import { PageScrollRefContext } from '../../page-doc/context';
import { PageInfo } from '../page-info';
import { PageFeedList } from './page-feed-list';

export const enum PageFeedTabIndex {
  PAGE_INFO = 0,
  PAGE_FEED = 1,
}
let defaultIndex = PageFeedTabIndex.PAGE_INFO;
export const setPageFeedIndex = (index: PageFeedTabIndex) => {
  defaultIndex = index;
};
export const PageFeeds: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const showPageFeed = tabIndex === PageFeedTabIndex.PAGE_FEED;
  return (
    <PageScrollRefContext.Provider value={scrollRef}>
      <div className="h-full flex flex-col items-stretch">
        <div className="h-[50px] border-b border-b-grey6 text-t2-medium">
          <div className="h-full flex items-center mx-4 space-x-7">
            <span
              className={cx(
                'cursor-pointer',
                !showPageFeed && 'underline underline-offset-[19.5px]'
              )}
              onClick={() => {
                setTabIndex(PageFeedTabIndex.PAGE_INFO);
              }}
            >
              页面信息
            </span>
            <span
              className={cx(
                'cursor-pointer',
                showPageFeed && 'underline underline-offset-[19.5px]'
              )}
              onClick={() => {
                setTabIndex(PageFeedTabIndex.PAGE_FEED);
              }}
            >
              页面动态
            </span>
          </div>
        </div>
        <div className="flex-1 min-h-0 overflow-y-auto">
          {/* display为none可以避免重建页面时发送请求 */}
          <div
            style={{
              display: showPageFeed ? 'block' : 'none',
            }}
          >
            <PageFeedList />
          </div>
          <div
            style={{
              display: !showPageFeed ? 'block' : 'none',
            }}
          >
            <PageInfo />
          </div>
        </div>
      </div>
    </PageScrollRefContext.Provider>
  );
};
