import type { FC } from 'react';

export const OthersPreview: FC = () => {
  return (
    <div className="w-full h-full pointer-events-none flex flex-col items-center justify-center">
      <p className="text-[70px] leading-[80px] text-center">👀</p>
      <p className="text-white">当前格式不支持预览</p>
    </div>
  );
};
