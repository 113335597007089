import { getIntersectionRatio } from 'src/utils/collision';

import { NewCardPlaceholderId } from '../const';

export const createOverLay = (targetNode: HTMLElement) => {
  const rect = targetNode.getBoundingClientRect();
  const overlayContainer = document.createElement('div');
  overlayContainer.style.position = 'fixed';
  overlayContainer.style.width = `${rect.width}px`;
  overlayContainer.style.height = `${rect.height}px`;
  overlayContainer.style.top = `${rect.top}px`;
  overlayContainer.style.left = `${rect.left}px`;
  overlayContainer.style.opacity = '0.5';
  overlayContainer.style.zIndex = '100';
  const node = targetNode.cloneNode(true);
  overlayContainer.appendChild(node);
  document.body.appendChild(overlayContainer);
  return overlayContainer;
};

export const addIndicator = (
  container: HTMLDivElement,
  overlay: HTMLDivElement,
  draggedId: string
) => {
  let target: HTMLElement | undefined;

  const timelineRecordNodes = container.querySelectorAll(
    '.record-line-container [data-record-id]'
  ) as NodeListOf<HTMLElement>;

  let maxIntersectionRatio = 0;
  const overlayRect = overlay.getBoundingClientRect();
  let position: 'top' | 'bottom' | undefined;
  timelineRecordNodes.forEach((node) => {
    if (node.dataset.recordId === NewCardPlaceholderId) return;
    const rect = node.getBoundingClientRect();
    if (node.lastElementChild?.classList.contains('indicator')) {
      node.lastElementChild.remove();
    }

    const intersectionRatio = getIntersectionRatio(overlayRect, rect);
    if (intersectionRatio > maxIntersectionRatio) {
      maxIntersectionRatio = intersectionRatio;
      if (overlayRect.top < rect.top) {
        position = 'top';
      } else {
        position = 'bottom';
      }
      target = node;
    }
  });

  const indicator = document.createElement('div');
  indicator.classList.add('indicator');
  indicator.style.position = 'absolute';
  indicator.style.zIndex = '100';
  indicator.style.width = '100%';
  indicator.style.height = '4px';
  indicator.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
  if (position === 'top') {
    indicator.style.top = '-2px';
  } else {
    indicator.style.bottom = '-2px';
  }
  if (target && target.dataset.recordId !== draggedId) {
    target.appendChild(indicator);
  }

  return { target, position };
};
