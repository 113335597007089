import languageEncoding from 'detect-file-encoding-and-language';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { useIsMobileSize } from 'src/services/app/hook';
import type { FilePreviewProps } from '..';
import { loadResource } from '@flowus/common/utils/load-resource';
export const readTextFromBlob = async (blob: Blob) => {
  try {
    await loadResource('https://cdn.staticfile.net/jschardet/3.0.0/jschardet.min.js', 'script');
  } catch {
    //
  }
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      const base64Str = reader.result;
      if (typeof base64Str !== 'string') {
        resolve(base64Str);
        return;
      }
      const str = atob(base64Str.split(';base64,')[1] ?? '');
      let encoding = 'UTF-8';
      try {
        encoding = window.jschardet.detect(str).encoding;
      } catch (err) {
        try {
          // tmd失败了就换个库再来一次，再失败再找个库
          const ret = await languageEncoding(blob);
          if (ret.encoding) {
            encoding = ret.encoding;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }

      const textReader = new FileReader();
      textReader.readAsText(blob, encoding);
      textReader.onload = () => {
        resolve(textReader.result);
      };
    };
  });
};

const TxtPreviewCom: FC<FilePreviewProps> = ({ downloadUrl }) => {
  const isMobileSize = useIsMobileSize();
  const preRef = useRef<HTMLPreElement | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchText = async (textUrl: string) => {
    void fetch(textUrl)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        if (blob.type.startsWith('video')) {
          if (preRef.current) {
            preRef.current.innerText = '预览失败！';
          }
          setLoading(false);
          return;
        }

        void readTextFromBlob(blob).then((text) => {
          if (!preRef.current || typeof text !== 'string') {
            setLoading(false);
            return;
          }

          preRef.current.innerText = text;
          setLoading(false);
        });
      });
  };

  useEffect(() => {
    void fetchText(downloadUrl);
  }, [downloadUrl]);

  return (
    <div
      className="h-full overflow-auto"
      style={{
        width: isMobileSize ? 'calc(100vw - 40px)' : '860px',
      }}
    >
      <pre
        ref={preRef}
        className="pl-2 pt-2 text-t4 min-h-full min-w-full text-black bg-white1"
        style={{ width: 'max-content', height: 'max-height' }}
      />
      {loading && (
        <LoadingIcon className="absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto z-50" />
      )}
    </div>
  );
};

export const TxtPreview = React.memo(TxtPreviewCom);
