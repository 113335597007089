import { BlockType } from '@next-space/fe-api-idl';
import type { MouseEvent as ReactMouseEvent } from 'react';
import { ColorKey } from 'src/colors';
import type { NextBlock } from 'src/redux/types';
import { getLastMarkBlockInfo } from './mark-block-utils';

export const getMouseBlock = (
  event: ReactMouseEvent | MouseEvent | DragEvent,
  rootContainer: HTMLElement | null
) => {
  const y = event.clientY;
  const x = event.clientX;
  return getMouseBlockFromPosition(x, y, rootContainer);
};
// hover时，鼠标移动到block两侧外的有效宽度
export const BLOCK_HOVER_VALID_WIDTH = 40;

export const getMouseBlockFromPosition = (
  x: number,
  y: number,
  rootContainer: HTMLElement | null
) => {
  // 特殊处理，不需要board view的节点
  const nodeList = (rootContainer || document.body).querySelectorAll(
    `[data-block-id]:not(.card):not(.hide-sync-block-child):not([data-column-list]), [data-drop-in-child]`
  );

  let activeNode: HTMLElement | undefined;
  let ds = Infinity;
  for (const node of nodeList) {
    const rect = node.getBoundingClientRect();

    const mt = parseInt(getComputedStyle(node).getPropertyValue('margin-top'), 10);
    const mb = parseInt(getComputedStyle(node).getPropertyValue('margin-bottom'), 10);

    if (
      y > rect.top - mt &&
      y < rect.bottom + mb &&
      x > rect.left - BLOCK_HOVER_VALID_WIDTH &&
      x < rect.right + BLOCK_HOVER_VALID_WIDTH
    ) {
      const dx = (x - rect.left) ** 2;
      const dy = (y - (rect.top - mb)) ** 2;
      const diff = dx + dy;
      if (diff < ds) {
        ds = diff;
        activeNode = node as HTMLElement;
      }
    }
  }

  return {
    blockId: (activeNode?.closest('[data-block-id]') as HTMLElement | null)?.dataset.blockId,
    node: activeNode,
  };
};

export const getInitBlockParams = (type: BlockType): Partial<NextBlock> => {
  if (type === BlockType.MARK) {
    const blockInfo = getLastMarkBlockInfo();
    if (!blockInfo.textColor && !blockInfo.backgroundColor) {
      // 如果没有默认就给一个
      blockInfo.backgroundColor = ColorKey.grey;
    }
    return blockInfo;
  }
  return {};
};
