import { CollectionViewType, CollectionSchemaType } from '@next-space/fe-api-idl';
import { cloneDeep } from 'lodash-es';
import type { CollectionViewProperties } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { updateBlock, updateSchema } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { cache } from 'src/redux/store';
import { v4 as uuidV4 } from 'uuid';
import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';
import {
  composeBoardViewFormat,
  composeCalendarViewFormat,
  composeFormViewFormat,
  composeGalleryViewFormat,
  composeListViewFormat,
  composeTableViewFormat,
  composeTimelineViewFormat,
  getPropertiesFromSchema,
} from './use-add-collection-view';
import {
  addProperty,
  CollectionViewPropertyNames,
  getGroupOptions,
  getNewPropertyVisibleObj,
  makePropertyName,
} from './use-create-property';

export const useConvertCollectionView = () => {
  const transaction = useTransaction();
  const createGroupProperty = useCreateGroupProperty();
  const createDateProperty = useCreateDateProperty();

  const convertView = (viewId: string, viewTitle: string, viewType: CollectionViewType) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { view, collection, properties } = viewInfo;
    const schema = collection.data.schema ?? {};

    const newViewFormat = {
      ...view.format,
      // 收集表的分享需要被关闭
      formShared: false,
    };

    /* 新建视图时和当前展示的视图的属性顺序一致 */
    let currentViewProperties: CollectionViewProperties = [];
    currentViewProperties = cloneDeep(properties);
    if (viewType !== CollectionViewType.TABLE) {
      currentViewProperties.forEach((item) => {
        if (item.property !== 'title') {
          item.visible = false;
        }
      });
    } else {
      if (view?.type !== CollectionViewType.TABLE) {
        currentViewProperties.forEach((item) => (item.visible = true));
      }
    }

    if (viewType === CollectionViewType.TABLE) {
      let newTableProperties = newViewFormat.tableProperties;

      if (!newTableProperties) {
        newTableProperties = currentViewProperties;
      }

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle,
          format: composeTableViewFormat({
            tableProperties: newTableProperties,
            oldViewFormat: newViewFormat,
          }),
          type: viewType,
        });
      });
    }

    if (viewType === CollectionViewType.GALLERY) {
      let newGalleryProperties = newViewFormat.galleryProperties;

      if (!newGalleryProperties) {
        newGalleryProperties = currentViewProperties;
      }

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle,
          format: composeGalleryViewFormat({
            galleryProperties: newGalleryProperties,
            oldViewFormat: view.format,
          }),
          type: viewType,
        });
      });
    }

    if (viewType === CollectionViewType.LIST) {
      let newListProperties = newViewFormat.listProperties;

      if (!newListProperties) {
        newListProperties = currentViewProperties;
      }

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle,
          format: composeListViewFormat({
            listProperties: newListProperties,
            oldViewFormat: newViewFormat,
          }),
          type: viewType,
        });
      });
    }

    if (viewType === CollectionViewType.BOARD) {
      const groupProperty = newViewFormat.boardGroupBy?.property ?? '';
      const groupPropertySchema = schema[groupProperty];

      if (!groupPropertySchema || groupPropertySchema.type !== CollectionSchemaType.SELECT) {
        createGroupProperty(viewId, viewTitle);
      } else {
        transaction(() => {
          CollectionViewManager.update(viewId, {
            title: viewTitle,
            type: viewType,
          });
        });
      }
    }

    if (viewType === CollectionViewType.TIMELINE) {
      createDateProperty(viewId, { viewTitle, targetType: CollectionViewType.TIMELINE });
    }

    if (viewType === CollectionViewType.CALENDAR) {
      createDateProperty(viewId, { viewTitle, targetType: CollectionViewType.CALENDAR });
    }

    if (viewType === CollectionViewType.FORM) {
      let newFormProperties = newViewFormat.formProperties;

      if (!newFormProperties) {
        newFormProperties = getPropertiesFromSchema(schema, viewType);
      }

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle,
          format: composeFormViewFormat({
            formProperties: newFormProperties,
            oldViewFormat: newViewFormat,
          }),
          type: viewType,
        });
      });
      if (
        !cache.blocks[collection.uuid]?.views?.some(
          (it) => cache.collectionViews[it]?.type === CollectionViewType.TABLE
        )
      ) {
        CollectionViewManager.add(
          {
            title: '表格',
            format: composeTableViewFormat({
              tableProperties: currentViewProperties,
            }),
            pageSort: collection.subNodes,
            type: CollectionViewType.TABLE,
          },
          { parentId: collection.uuid }
        );
      }
    }
  };

  return useCallback(convertView, [createGroupProperty, createDateProperty, transaction]);
};

export const useCreateGroupProperty = () => {
  const transaction = useTransaction();

  const createGroupProperty = (viewId: string, viewTitle?: string) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { view, collection } = viewInfo;
    const schema = collection.data.schema ?? {};
    const viewFormat = { ...view.format };

    let newBoardProperties = viewFormat.boardProperties;
    if (!newBoardProperties) {
      // 如果之前存在过看板视图，则 boardProperties 不用再更新，已经在每次创建和删除 property 的时候维护好了。只在之前不存在 board 时创建
      newBoardProperties = getPropertiesFromSchema(schema, CollectionViewType.BOARD);
    }

    const propertyId = Object.keys(schema).find(
      (schemaId) => schema[schemaId]?.type === CollectionSchemaType.SELECT
    );

    if (propertyId) {
      // 当前 collection 中存在 select property
      const newViewFormat = composeBoardViewFormat({
        groupProperty: propertyId,
        options: schema[propertyId]?.options,
        boardProperties: newBoardProperties,
        oldViewFormat: viewFormat,
      });

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle ?? view.title,
          format: newViewFormat,
          type: CollectionViewType.BOARD,
        });
      });
    } else {
      // 当前 collection 中不存在 select property，新建 select property
      const newPropertyId = uuidV4();
      const options = getGroupOptions();

      const { visibleProperty, inVisibleProperty } = getNewPropertyVisibleObj(newPropertyId);

      // 构造 format
      const newViewFormat = composeBoardViewFormat({
        groupProperty: newPropertyId,
        boardProperties: newBoardProperties.concat(inVisibleProperty),
        options,
        oldViewFormat: viewFormat,
      });

      CollectionViewPropertyNames.forEach((name) => {
        if (name === 'boardProperties') return;
        const properties = newViewFormat[name];
        if (properties) {
          newViewFormat[name] = [...properties, visibleProperty];
        }
      });

      transaction(() => {
        updateSchema(collection.uuid, {
          [newPropertyId]: {
            name: makePropertyName(collection.data.schema, '分类'),
            type: CollectionSchemaType.SELECT,
            options,
          },
        });

        CollectionViewManager.update(viewId, {
          title: viewTitle ?? view.title,
          format: newViewFormat,
          type: CollectionViewType.BOARD,
        });

        // 为所有视图添加 property
        addProperty(collection.uuid, [newPropertyId], viewId);
      });
    }
  };

  return useCallback(createGroupProperty, [transaction]);
};

export const useCreateTimelineDate = () => {
  const transaction = useTransaction();

  const createTimelineDate = (viewId: string, viewTitle?: string) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { view, collection } = viewInfo;

    const schema = collection.data.schema ?? {};
    const viewFormat = { ...view.format };

    let newTimelineProperties = viewFormat.timelineProperties;
    if (!newTimelineProperties) {
      newTimelineProperties = getPropertiesFromSchema(schema, CollectionViewType.TIMELINE);
    }

    const datePropertyId = Object.keys(schema).find(
      (schemaId) => schema[schemaId]?.type === CollectionSchemaType.DATE
    );
    if (datePropertyId) {
      // 当前 collection 中存在 select property
      const newViewFormat = composeTimelineViewFormat({
        timelineProperties: newTimelineProperties,
        timelineBy: datePropertyId,
        timelineByEnd: datePropertyId,
      });

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle ?? view.title,
          format: newViewFormat,
          type: CollectionViewType.TIMELINE,
        });
      });
    } else {
      const newDatePropertyId = uuidV4();
      const { inVisibleProperty } = getNewPropertyVisibleObj(newDatePropertyId);

      // 构造 format
      const newViewFormat = composeTimelineViewFormat({
        timelineProperties: newTimelineProperties.concat(inVisibleProperty),
        timelineBy: newDatePropertyId,
        timelineByEnd: newDatePropertyId,
      });

      CollectionViewPropertyNames.forEach((name) => {
        if (name === 'timelineProperties') return;

        const properties = newViewFormat[name];
        if (properties) {
          newViewFormat[name] = [...properties, inVisibleProperty];
        }
      });

      transaction(() => {
        updateSchema(collection.uuid, {
          [newDatePropertyId]: {
            name: makePropertyName(collection.data.schema, '日期'),
            type: CollectionSchemaType.DATE,
          },
        });

        CollectionViewManager.update(viewId, {
          title: viewTitle ?? view.title,
          format: newViewFormat,
          type: CollectionViewType.TIMELINE,
        });

        // 为所有视图添加 property
        addProperty(collection.uuid, [newDatePropertyId], viewId);
      });
    }
  };

  return useCallback(createTimelineDate, [transaction]);
};

export const useCreateDateProperty = () => {
  const transaction = useTransaction();

  const createDateProperty = (
    viewId: string,
    opts?: { viewTitle?: string; targetType: CollectionViewType }
  ) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;
    const { view, collection } = viewInfo;

    const { viewTitle = view.title, targetType = view.type } = opts ?? {};

    const isTimeline = targetType === CollectionViewType.TIMELINE;
    const { calendarBy, calendarByEnd, timelineBy, timelineByEnd } = viewInfo;
    let startBy = isTimeline ? timelineBy : calendarBy;
    let endBy = isTimeline ? timelineByEnd : calendarByEnd;

    const schema = collection.data.schema ?? {};
    const viewFormat = { ...view.format, formShared: false };

    let newProperties = viewFormat.calendarProperties
      ? [...viewFormat.calendarProperties]
      : undefined;
    if (!newProperties) {
      newProperties = getPropertiesFromSchema(schema, CollectionViewType.CALENDAR);
    }

    const dateProperties = Object.entries(schema).filter(
      ([_, item]) => item.type === CollectionSchemaType.DATE
    );
    let startByValid: boolean | undefined;
    let endByValid: boolean | undefined;
    const unUseProperties = dateProperties.filter((item) => {
      if (item[0] === startBy) {
        startByValid = true;
        return false;
      }
      if (item[0] === endBy) {
        endByValid = true;
        return false;
      }
      return true;
    });

    startBy = startByValid ? startBy : unUseProperties[0]?.[0];
    endBy = endByValid ? endBy : unUseProperties[startByValid ? 0 : 1]?.[0];

    // 当前多维表数据中存在两个日期属性
    if (startBy && endBy) {
      const newViewFormat = isTimeline
        ? composeTimelineViewFormat({
            oldViewFormat: viewFormat,
            timelineProperties: newProperties,
            timelineBy: startBy,
            timelineByEnd: endBy,
          })
        : composeCalendarViewFormat({
            oldViewFormat: viewFormat,
            calendarProperties: newProperties,
            calendarBy: startBy,
            calendarByEnd: endBy,
          });

      transaction(() => {
        CollectionViewManager.update(viewId, {
          title: viewTitle || view.title,
          format: newViewFormat,
          type: targetType,
        });
      });
    } else {
      const newProperty: { property: string; visible: boolean }[] = [];
      const newSchema = { ...schema };
      if (!startBy) {
        startBy = uuidV4();
        const invisibleProperty = { property: startBy, visible: false };
        newProperty.push(invisibleProperty);
        newProperties.push(invisibleProperty);
        newSchema[startBy] = {
          name: makePropertyName(collection.data.schema, '开始时间'),
          type: CollectionSchemaType.DATE,
        };
      }
      if (!endBy) {
        endBy = uuidV4();
        const invisibleProperty = { property: endBy, visible: false };
        newProperty.push(invisibleProperty);
        newProperties.push(invisibleProperty);
        newSchema[endBy] = {
          name: makePropertyName(collection.data.schema, '结束时间'),
          type: CollectionSchemaType.DATE,
        };
      }

      // 构造 format
      const newViewFormat = isTimeline
        ? composeTimelineViewFormat({
            timelineProperties: newProperties,
            timelineBy: startBy,
            timelineByEnd: endBy,
          })
        : composeCalendarViewFormat({
            calendarProperties: newProperties,
            calendarBy: startBy,
            calendarByEnd: endBy,
          });

      CollectionViewPropertyNames.forEach((name) => {
        if (isTimeline && name === 'timelineProperties') return;
        if (!isTimeline && name === 'calendarProperties') return;

        const properties = newViewFormat[name];
        if (properties) {
          newViewFormat[name] = [...properties, ...newProperty];
        }
      });

      transaction(() => {
        updateBlock(collection.uuid, { data: { schema: newSchema } });

        CollectionViewManager.update(viewId, {
          title: viewTitle || view.title,
          format: newViewFormat,
          type: targetType,
        });

        // 为所有视图添加 property
        addProperty(
          collection.uuid,
          newProperty.map((item) => item.property),
          viewId
        );
      });
    }
  };

  return useCallback(createDateProperty, [transaction]);
};
