import type { Context } from '../../core/context';
import type { IPropertyListDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isIdentWithValue, parseFunctionArgs } from '../syntax/parser';
import type { Color } from '../types/color';
import { color, COLORS } from '../types/color';
import type { Length } from '../types/length';
import { isLength } from '../types/length';
import { ZERO_LENGTH } from '../types/length-percentage';

export type TextShadow = TextShadowItem[];
interface TextShadowItem {
  color: Color;
  offsetX: Length;
  offsetY: Length;
  blur: Length;
}

export const textShadow: IPropertyListDescriptor<TextShadow> = {
  name: 'text-shadow',
  initialValue: 'none',
  type: PropertyDescriptorParsingType.LIST,
  prefix: false,
  parse: (context: Context, tokens: CSSValue[]): TextShadow => {
    if (tokens.length === 1 && isIdentWithValue(tokens[0]!, 'none')) {
      return [];
    }

    return parseFunctionArgs(tokens).map((values: CSSValue[]) => {
      const shadow: TextShadowItem = {
        color: COLORS.TRANSPARENT!,
        offsetX: ZERO_LENGTH,
        offsetY: ZERO_LENGTH,
        blur: ZERO_LENGTH,
      };
      let c = 0;
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < values.length; i++) {
        const token = values[i]!;
        if (isLength(token)) {
          if (c === 0) {
            shadow.offsetX = token;
          } else if (c === 1) {
            shadow.offsetY = token;
          } else {
            shadow.blur = token;
          }
          c++;
        } else {
          shadow.color = color.parse(context, token);
        }
      }
      return shadow;
    });
  },
};
