import type { FC } from 'react';
import { memo } from 'react';
import { BlockRenderer } from './block';

// const HeightMap: Map<string, number | undefined> = new Map();

export const BlockItem: FC<{ uuid: string }> = memo(({ uuid }) => {
  // const wrapper = useRef<HTMLDivElement>(null);
  // const timer = useRef<NodeJS.Timeout>();

  // useEffect(() => {
  //   const wrapperNode = wrapper.current;
  //   if (!wrapperNode) return;

  //   if (timer.current) {
  //     clearTimeout(timer.current);
  //   }

  //   timer.current = setTimeout(() => {
  //     clearTimeout(timer.current);
  //     wrapperNode.style.minHeight = '';
  //   }, 300);

  //   const unObserver = globalResizeObserver.observe(wrapperNode, (entry) => {
  //     const { clientHeight } = entry.target;
  //     if (clientHeight) {
  //       HeightMap.set(uuid, clientHeight);
  //     }
  //   });

  //   return () => {
  //     unObserver();
  //     clearTimeout(timer.current);
  //   };
  // });

  // const initHeight = HeightMap.get(uuid) ?? undefined;

  return (
    // <div className="flex flex-col" ref={wrapper} style={{ minHeight: initHeight }}>
    <BlockRenderer key={uuid} root id={uuid} />
    // {/* </div> */}
  );
});
