import type { IEditorModel } from '@next-space/fe-inlined';
import { findContainerItemPath, newContent } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { CODE_TAG } from 'src/editor/editor/inline/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

interface Option {
  model?: IEditorModel;
}
export const useShortcutCode = (option?: Option) => {
  const { model: _model } = option || {};
  const getEditorModel = useGetOrInitEditorModel();

  return useCallback(
    (uuid: string) => {
      const model = _model || getEditorModel(uuid);

      if (!model) return;

      model.performChange((ctx) => {
        if (ctx.selection === null) return;

        const codePath = findContainerItemPath(
          model.content,
          ctx.selection.offset,
          ctx.selection.endOffset,
          (item) => item.type === 'element' && item.tag === CODE_TAG
        );

        if (codePath.length > 0) {
          ctx.unwrap(CODE_TAG, codePath);
          return;
        }

        if (ctx.selection.isCollapsed) {
          ctx
            .insert(
              newContent([
                {
                  type: 'element',
                  tag: CODE_TAG,
                  props: {},
                  content: newContent([]),
                },
              ])
            )
            .select(ctx.selection.focusOffset - 1);
        } else {
          ctx.wrap(CODE_TAG, {});
        }
      });
    },
    [_model, getEditorModel]
  );
};
