import en from './en.json';
import zhHans from './zh-hans.json';
import ru from './ru.json';

export const translationResources = {
  en: {
    translation: en,
    ...en,
  },
  zhHans: {
    translation: zhHans,
    ...zhHans,
  },
  ru: {
    translation: ru,
    ...ru,
  },
};

export const LanguageKey = 'next_lng';
