import { LoadingIcon } from '@flowus/common/components';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState, useEffect } from 'react';
import { sequence } from 'src/utils/async-utils';
import { urlFetcher } from 'src/utils/url-fetcher';

interface WareImageProps {
  blockId: string;
  ossName: string;
  className?: string;
  onClick?: () => void;
}
export const WareImage: FC<WareImageProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    if (url) return;
    void sequence(async () => {
      const res = await urlFetcher.fetchImageUrl({
        blockId: props.blockId,
        ossName: props.ossName,
      });
      if (res) {
        setUrl(res);
      }
      setLoading(false);
    }, 'oss');
  }, [props.blockId, props.ossName, url]);

  return (
    <div
      className={cx('flex justify-center items-center w-full h-[100px]', props.className)}
      onClick={props.onClick}
    >
      {loading && <LoadingIcon className="w-10 h-10" />}
      {!loading && <img className="rounded-t-md object-contain w-full h-full" src={url} />}
    </div>
  );
};
