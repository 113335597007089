import { cx } from '@flowus/common/cx';
import type { BlockSearchDTO, BlockType, IconData } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { Avatar } from '../../avatar';
import { PageNavigator } from '../../page-navigator';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

/**
 * 页面移动菜单item
 */
interface SearchDataScheme {
  uuid: string;
  title: string;
  icon?: IconData;
  avatarTitle?: string;
  type: BlockType | string;
  backgroundColor?: string;
  navs?: BlockSearchDTO['navs'];
  renderRight?: () => JSX.Element;
}
export const SearchItem: FC<ElementItemProps<SearchDataScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  const showNavs = dataScheme.navs && dataScheme.navs.length > 0;

  return (
    <div
      {...props.attribute}
      onClick={(e) => {
        props.onItemClick && props.onItemClick(props.listItem, props.index, e);
      }}
      className={cx(
        listViewItemDefaultClassName,
        'flex items-center rounded cursor-pointer active-bg animate-hover',
        showNavs ? 'h-[58px]' : 'h-10',
        { 'normal-bg': props.selected }
      )}
    >
      <div className="flex flex-col flex-1 text-ellipsis">
        <div className="flex flex-row items-center">
          {['SPACE', 'PRIVATE'].some((s) => s === dataScheme.type) ? (
            <Avatar
              className="!text-t3-medium m-0.5"
              icon={dataScheme.icon}
              name={dataScheme.avatarTitle || dataScheme.title}
              color={dataScheme.backgroundColor}
              style={{ width: '20px', height: '20px' }}
            />
          ) : (
            <IconTrigger
              blockId={dataScheme.uuid}
              iconSize={20}
              trigger={false}
              defaultIcon={<BlockDefaultIcon uuid={dataScheme.uuid} size="middle" />}
            />
          )}

          <span className="ml-2 text-black text-t2 text-ellipsis">{dataScheme.title}</span>
        </div>
        <div className="flex flex-col ml-7">
          {dataScheme.navs && dataScheme.navs.length > 0 && (
            <PageNavigator navs={dataScheme.navs} />
          )}
        </div>
      </div>

      {dataScheme.renderRight && dataScheme.renderRight()}
    </div>
  );
};
