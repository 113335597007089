import { LIST_AFTER_FAVORITE, LIST_BEFORE_FAVORITE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';

export const add = (uuid: string, where: NextWhere, ignoreOp = false) => {
  if (uuid === where.parentId || !where.parentId) return;
  if (where.first || where.before) {
    dispatch(
      LIST_BEFORE_FAVORITE({
        uuid,
        parentId: where.parentId,
        before: where.before,
        ignoreOp,
      })
    );
  } else {
    dispatch(
      LIST_AFTER_FAVORITE({
        uuid,
        parentId: where.parentId,
        after: where.after,
        ignoreOp,
      })
    );
  }
};
