import { OrderFolderType } from '@next-space/fe-api-idl';

export const getOrderFolderType = (sortType?: OrderFolderType) => {
  let reverse = false;
  sortType = sortType ?? OrderFolderType.TIME_REVERSED;

  switch (sortType) {
    case OrderFolderType.SIZE_REVERSED:
    case OrderFolderType.NAME_REVERSED:
    case OrderFolderType.TIME_REVERSED: {
      reverse = true;
      break;
    }
    default:
  }

  return { reverse, sortType };
};
