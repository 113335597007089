import { ListView } from 'src/common/components/list-view';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { ListItemType } from 'src/common/components/list-view/types';
import type { FC } from 'react';
import { useBitable } from '../context';
import { useOpenPageWay } from 'src/hooks/collection-view/use-collection-view';
import type { IconProp } from '@flowus/common';

export const openPageItems: { icon: IconProp['name']; title: string; openWay: string }[] = [
  {
    icon: 'IcBlockRightSidebarOpen',
    title: '右侧边栏打开',
    openWay: 'openInRight',
  },
  {
    icon: 'IcBlockFullPageOpen',
    title: '整页打开',
    openWay: 'openFullscreen',
  },
];
export const PageOpenWay: FC<{ closeSelf: () => void }> = ({ closeSelf }) => {
  const { viewId } = useBitable();
  const openWay = useOpenPageWay(viewId);

  const items = openPageItems.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        icon: item.icon,
        title: item.title,
        selected: item.openWay === openWay,
        openWay: item.openWay,
      },
    };
  });

  return (
    <div className="w-[220px] py-2 next-modal">
      <ListView
        className="overflow-auto max-h-120"
        items={items}
        onItemClick={(item) => {
          updateViewFormat(viewId, {
            openPageWay: item.data.openWay,
          });
          closeSelf();
        }}
      />
    </div>
  );
};
