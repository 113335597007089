import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Icon } from 'src/common/components/icon';
import { publicLink } from 'src/common/const';
import { useFetchActivities } from 'src/hooks/activities/use-fetch-activities';
import { fetchUserBalance } from 'src/services/user/user-balance';
import { bizTracker } from 'src/utils/biz-tracker';
import { SettingModalCommon } from '../common';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { ActivityList } from './activity-list';
import { MyPoint } from './my-point';
import { NewUserTaskList } from './new-user-task-list';

/** 活动中心 */
export const IntegralSetting: FC = () => {
  const closeSettingModal = useCloseSettingModal();
  const fetchActivities = useFetchActivities();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    void fetchUserBalance();
    void fetchActivities();
    bizTracker.event('activity_center_show');
  }, [fetchActivities]);

  return (
    <>
      <div ref={scrollRef} className={SettingModalCommon.commonStyles.container}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          useWindow={false}
          loadMore={() => {}}
          getScrollParent={() => scrollRef.current}
          hasMore={false}
        >
          <SettingModalCommon.Header
            title="活动中心"
            addonAfter={
              <a
                hidden={__BUILD_IN__}
                href={publicLink.integralRule}
                target="_blank"
                rel="noreferrer"
                className="flex items-center"
              >
                <Icon name={'IcInviteInfo'} size="middle" className="mr-1" />
                积分规则
              </a>
            }
          />
          {/* 我的积分 */}
          <MyPoint />
          {/* 新手任务 */}
          {!__BUILD_IN__ && <NewUserTaskList />}
          {/* 线上活动 */}
          {!__BUILD_IN__ && <ActivityList />}
        </InfiniteScroll>
      </div>
      <SettingModalCommon.FooterBar cancelText="关闭" cancel={() => closeSettingModal()} />
    </>
  );
};
