import type {
  CardSize,
  CollectionSchema,
  CollectionViewDTO,
  CollectionViewGroupBy,
  CollectionViewGroups,
  CollectionViewProperties,
  CoverAspectType,
  CoverType,
  FormLogicRule,
} from '@next-space/fe-api-idl';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { pick } from 'lodash-es';
import { useCallback } from 'react';
import { ColorKey } from 'src/colors';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { cache, getState } from 'src/redux/store';
import type { NextBlock, NextCollectionView } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';
import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';

interface PropertyPosition {
  property?: string;
  left?: boolean;
  first?: boolean;
}

export const makePropertyName = (
  schema: Record<string, CollectionSchema> | undefined,
  propertyName = '属性'
) => {
  const names = Object.values(schema ?? {}).map((item) => item.name);
  const exist = new Set(names);

  let index = 0;
  let name = propertyName;

  while (exist.has(name)) {
    index += 1;
    name = `${propertyName} ${index}`;
  }

  return name;
};
export const useCreateProperty = () => {
  const transaction = useTransaction();

  const createProperty = ({
    collectionId,
    viewId,
    position,
    propertyType = CollectionSchemaType.TEXT,
    isTimelineTable = false,
    visible = true,
    width,
    propertyName,
    partialSchema,
    newPropertyId = uuidV4(),
  }: {
    collectionId: string;
    viewId?: string;
    position?: PropertyPosition;
    propertyType?: CollectionSchemaType;
    isTimelineTable?: boolean;
    visible?: boolean;
    width?: number;
    propertyName?: string;
    partialSchema?: Partial<Omit<CollectionSchema, 'name' | 'type'>>;
    newPropertyId?: string;
  }) => {
    const { blocks, collectionViews } = cache;

    const collection = blocks[collectionId];
    if (!collection) return;

    const { visibleProperty, inVisibleProperty } = getNewPropertyVisibleObj(newPropertyId);

    let newViewFormat: NextCollectionView['format'] = {};
    if (viewId) {
      const view = collectionViews[viewId];
      if (!view) return;

      newViewFormat = pick(view.format, CollectionViewPropertyNames);

      const { properties = [], timelineTableProperties = [] } = getViewFormat(view);
      const oldProperties = isTimelineTable ? timelineTableProperties : properties;
      const newProperties = [...oldProperties];

      const createdProperty =
        visible && [CollectionViewType.TABLE, CollectionViewType.FORM].includes(view.type)
          ? visibleProperty
          : inVisibleProperty;
      createdProperty.width = width;
      if (position) {
        if (position.first) {
          newProperties.unshift(createdProperty);
        } else {
          // 涉及排序的属性添加只修改当前视图的属性顺序
          const index = newProperties.findIndex(
            (property) => property.property === position.property
          );
          if (index > -1) {
            newProperties.splice(position.left ? index : index + 1, 0, createdProperty);
          } else {
            newProperties.push(createdProperty);
          }
        }
      } else {
        newProperties.push(createdProperty);
      }

      const currentPropertiesName = isTimelineTable
        ? 'timelineTableProperties'
        : `${getViewFormatPrefix(view.type)}Properties`;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newViewFormat[currentPropertiesName] = newProperties;

      CollectionViewPropertyNames.forEach((name) => {
        if (name === currentPropertiesName) return;
        const properties = newViewFormat[name];
        if (properties) {
          if (visible && ['tableProperties', 'formProperties'].includes(name)) {
            newViewFormat[name] = position?.first
              ? [visibleProperty, ...properties]
              : [...properties, visibleProperty];
          } else {
            newViewFormat[name] = position?.first
              ? [inVisibleProperty, ...properties]
              : [...properties, inVisibleProperty];
          }
        }
      });
    }

    let newCollectionPageProperties = [...(collection.data.collectionPageProperties ?? [])];
    if (newCollectionPageProperties.length === 0) {
      newCollectionPageProperties = Object.keys(collection.data.schema ?? {}).map((property) => ({
        property,
        visible: true,
      }));
    }
    newCollectionPageProperties.push(visible ? visibleProperty : inVisibleProperty);

    transaction(() => {
      updateBlock(collectionId, {
        data: {
          collectionPageProperties: newCollectionPageProperties,
          schema: {
            [newPropertyId]: {
              name: makePropertyName(collection.data.schema, propertyName),
              type: propertyType,
              ...partialSchema,
            },
            ...collection.data.schema,
          },
        },
      });

      if (viewId) {
        CollectionViewManager.update(viewId, {
          format: newViewFormat,
        });
      }
      addProperty(collection.uuid, [newPropertyId], viewId, position, visible);
    });

    return newPropertyId;
  };

  return useCallback(createProperty, [transaction]);
};

export const getGroupOptions = () => {
  return [
    {
      id: uuidV4(),
      value: '待处理',
      color: ColorKey.red,
    },
    {
      id: uuidV4(),
      value: '进行中',
      color: ColorKey.yellow,
    },
    {
      id: uuidV4(),
      value: '已完成',
      color: ColorKey.green,
    },
  ];
};

export type PropertiesName =
  | 'tableProperties'
  | 'boardProperties'
  | 'galleryProperties'
  | 'listProperties'
  | 'timelineProperties'
  | 'timelineTableProperties'
  | 'calendarProperties'
  | 'formProperties';

export const CollectionViewPropertyNames: PropertiesName[] = [
  'tableProperties',
  'boardProperties',
  'galleryProperties',
  'listProperties',
  'timelineProperties',
  'timelineTableProperties',
  'calendarProperties',
  'formProperties',
];

export const getNewPropertyVisibleObj = (newPropertyId: string) => {
  return {
    visibleProperty: {
      property: newPropertyId,
      visible: true,
    } as CollectionViewProperties[number],
    inVisibleProperty: {
      property: newPropertyId,
      visible: false,
    } as CollectionViewProperties[number],
  };
};

/**
 * 除去指定视图后，为 collection 其它视图的视图属性字段添加属性
 * @param collectionId
 * @param newPropertyId
 * @param excludeView
 * @returns
 */
export const addProperty = (
  collectionId: string,
  newPropertyIds: string[],
  excludeView?: string,
  postion?: PropertyPosition,
  visible?: boolean
) => {
  const { blocks, collectionViews } = getState();

  const collection = blocks[collectionId];
  if (!collection) return;

  const newVisibleProperties: { property: string; visible: boolean }[] = [];
  const newInVisibleProperties: { property: string; visible: boolean }[] = [];
  newPropertyIds.forEach((propertyId) => {
    const { visibleProperty, inVisibleProperty } = getNewPropertyVisibleObj(propertyId);
    newVisibleProperties.push(visibleProperty);
    newInVisibleProperties.push(inVisibleProperty);
  });

  collection.views?.forEach((uuid) => {
    // 此视图的属性更新在调用此方法的外部更新
    if (uuid === excludeView) return;

    const view = collectionViews[uuid];
    if (!view) return;

    const viewFormat = pick(view.format, CollectionViewPropertyNames);
    CollectionViewPropertyNames.forEach((propertyName) => {
      const properties = viewFormat[propertyName];

      if (properties) {
        if (propertyName === 'tableProperties' && visible) {
          viewFormat[propertyName] = postion?.first
            ? [...newVisibleProperties, ...properties]
            : [...properties, ...newVisibleProperties];
        } else {
          viewFormat[propertyName] = postion?.first
            ? [...newInVisibleProperties, ...properties]
            : [...properties, ...newInVisibleProperties];
        }
      }
    });

    CollectionViewManager.update(uuid, {
      format: viewFormat,
    });
  });
};

export interface TableFormatValue {
  propertiesName:
    | 'tableProperties'
    | 'boardProperties'
    | 'galleryProperties'
    | 'listProperties'
    | 'timelineProperties'
    | 'calendarProperties'
    | 'formProperties';
  properties: CollectionViewProperties;
  timelineTableProperties?: CollectionViewProperties;
  groupBy?: CollectionViewGroupBy;
  subGroupBy?: CollectionViewGroupBy;
  groups?: CollectionViewGroups;
  subGroups?: CollectionViewGroups;
  cardSize?: CardSize;
  coverType?: CoverType;
  timelineBy?: string;
  timelineByEnd?: string;
  timelineShowTable?: boolean;
  calendarBy?: string;
  calendarByEnd?: string;
  view: CollectionViewDTO;
  formLogic?: FormLogicRule[];
  collection: NextBlock;
  coverAspectType?: CoverAspectType;
}

export const getViewFormatPrefix = (viewType: CollectionViewType) => {
  switch (viewType) {
    case CollectionViewType.BOARD:
      return CollectionViewType.BOARD;
    case CollectionViewType.LIST:
      return CollectionViewType.LIST;
    case CollectionViewType.GALLERY:
      return CollectionViewType.GALLERY;
    case CollectionViewType.TIMELINE:
      return CollectionViewType.TIMELINE;
    case CollectionViewType.CALENDAR:
      return CollectionViewType.CALENDAR;
    case CollectionViewType.FORM:
      return CollectionViewType.FORM;
    default:
      return CollectionViewType.TABLE;
  }
};
