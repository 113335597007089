import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent } from '@next-space/fe-inlined';
import type { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import type { useOpenDeleteSyncBlockDialog } from 'src/components/unsync';
import type { useClearSelectedCellValue } from 'src/hooks/block/use-clear-selected-cell-value';
import type { useCreatePropertyOption } from 'src/hooks/block/use-create-property-options';
import type { useDeleteSelectedTableCells } from 'src/hooks/block/use-delete-selected-table-calls';
import type { useUpdatePropertyValueWithoutTransaction } from 'src/hooks/block/use-update-property-value';
import type { useUpload } from 'src/hooks/space/use-upload';
import type { useTransaction } from 'src/hooks/use-transaction';
import type { NextBlock } from 'src/redux/types';
import type { useFetchPage } from '../use-fetch-page';
import type { useSelectedBlockHistory } from '../use-selected-block-history';

export const TEXT_PLAIN = 'text/plain';
export const TEXT_HTML = 'text/html';
export const TEXT_BLOCKS = 'text/next-space-blocks';
export const TEXT_CONTENT = 'text/next-space-content';
export const TEXT_CELLS = 'text/next-space-cells';
export const TEXT_PDF_ANNOTATION = 'text/next-space-pdf-annotation';

export interface BlockNode {
  id: string;
  viewId?: string;
  subTree: Record<string, NextBlock>;
}
export type CellContent = SegmentDTO[];
export interface RecordCells {
  cells: CellContent[]; // 一条记录多个cell内容，这里多定义了一个类型只是为了让你更好理解
}
export interface NextSpaceInfo {
  fromType?: Action.CUT | Action.COPY;
  userId?: string; // 由于有一些block的粘贴（比如同步块）不同帐户之间有不一样的行为
  spaceId?: string; // 跨空间复制需要做判断
  pageId?: string; // 同一个page粘贴不会提示变成同步块
  blocks?: BlockNode[];
  records?: RecordCells[];
  plainText?: string;
  hideSyncMenu?: boolean;
}

export interface NextSpaceText {
  content?: IContent;
}

export enum Action {
  CUT = 'cut',
  COPY = 'copy',
}

export interface CutCopyHook {
  transaction: ReturnType<typeof useTransaction>;
  selectedBlockHistory?: ReturnType<typeof useSelectedBlockHistory>['selectedBlockHistory'];
  clearSelectedCellValue?: ReturnType<typeof useClearSelectedCellValue>;
  closeModal?: ReturnType<typeof useCloseModal>;
  openDeleteSyncBlockDialog?: ReturnType<typeof useOpenDeleteSyncBlockDialog>;
  readonly?: Boolean;
  deleteSelectedTableCells?: ReturnType<typeof useDeleteSelectedTableCells>;
}

export interface PasteHook extends CutCopyHook {
  uploadFile?: ReturnType<typeof useUpload>;
  openModal?: ReturnType<typeof useOpenModal>;
  createPropertyOption?: ReturnType<typeof useCreatePropertyOption>;
  updatePropertyValueWithoutTransaction?: ReturnType<
    typeof useUpdatePropertyValueWithoutTransaction
  >;
  fetchPage?: ReturnType<typeof useFetchPage>;
  uploadFileSuccess?: () => void;
  pasteSyncBlock?: () => void;
}

export interface HandleDataType {
  blocks: Record<string, NextBlock>;
  blockPos: Record<string, string[]>;
  files: { file: File; blockId: string; parentId: string }[];
  images: { url: string; blockId: string; parentId: string }[];
  /** 用于在多维表的标题里粘贴时 */
  start: number;
}
