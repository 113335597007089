import { useMemo } from 'react';
import { UNNAMED_USER } from 'src/common/const';
import { useCurrentUser } from 'src/hooks/user';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { getCurrentSpace, useCurrentSpace } from '../space';

/**
 * 优先使用当前空间备注名，如果没有则用nickname
 */
export const useUserName = (userId: string, defaultName = UNNAMED_USER) => {
  const space = useCurrentSpace();
  const currentUser = useCurrentUser();

  const nickname = useObservableStore(
    (state) => {
      if (currentUser.uuid === userId) {
        return currentUser.nickname;
      }
      return state.users[userId]?.nickname;
    },
    [currentUser, userId]
  );

  return useMemo(
    () => (space.userRemark?.[userId] || nickname) ?? defaultName,
    [defaultName, nickname, space.userRemark, userId]
  );
};

export const getUserName = (userId: string, defaultName = UNNAMED_USER) => {
  const markName = getCurrentSpace().userRemark?.[userId];
  return (markName || cache.users[userId]?.nickname) ?? defaultName;
};
