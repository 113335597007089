import { getSessionOp, getSessionStorage, SessionKeys } from '@flowus/common/storage';
import { request } from 'src/common/request/request';

/** kol 活动 */
export const kolActivity = (newUser: boolean) => {
  if (__BUILD_IN__ || __PRIVATE__) return;
  // KOL 活动领取优惠券
  const inviteCode = getSessionOp.inviteCode.get();
  const sharePageId = getSessionStorage(SessionKeys.SHARE_INVITE_PAGE_ID);
  if (inviteCode) {
    void request.infra.claimExclusiveCoupon.raw({
      inviteCode: inviteCode.slice(0, 6),
      newUser,
      sharePageId,
    });
  }
};
