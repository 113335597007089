import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { PUBLIC_SHARE } from 'src/common/const';

import { CurrentSpaceSubject, GroupSubject, UserSubject } from '../subject';

export const PermissionChange: FC<{
  from?: PermissionDTO;
  to?: PermissionDTO;
}> = ({ from, to }) => {
  const permission = to ?? from;
  if (permission == null) {
    return null;
  }

  const changeLine = (() => {
    if (from !== undefined && to !== undefined) {
      return (
        <div className="flex items-center text-t2">
          <span>
            <RenderPermissionRole permission={from} />
          </span>
          <Icon name="IcDateEnd" size="middle" className="mx-2 text-grey3" />
          <span>
            <RenderPermissionRole permission={to} />
          </span>
        </div>
      );
    }
    if (from !== undefined && to === undefined) {
      return (
        <div className="text-t2">
          <span className="line-through text-grey4">
            <RenderPermissionRole permission={from} />
          </span>
        </div>
      );
    }
    if (to !== undefined && from === undefined) {
      return (
        <div className="text-t2">
          <span className="bg-active_color/10 text-active_color">
            <RenderPermissionRole permission={to} />
          </span>
        </div>
      );
    }
    return null;
  })();

  return (
    <div className="mt-2 px-1">
      <div className="text-t4 text-grey3">
        <RenderPermissionTarget permission={permission} />
      </div>
      {changeLine}
    </div>
  );
};

const RenderPermissionRole: FC<{ permission: PermissionDTO }> = ({ permission }) => {
  if (permission.type === PermissionType.ILLEGAL) return null;
  if (permission.type === PermissionType.PUBLIC) return <>可查看</>;
  if (permission.type === PermissionType.RESTRICTED) return <>未开启</>;
  switch (permission.role) {
    case PermissionRole.NONE:
      return <>无权限</>;
    case PermissionRole.READER:
      return <>只读</>;
    case PermissionRole.COMMENTER:
      return <>可评论</>;
    case PermissionRole.WRITER:
      return <>可编辑</>;
    case PermissionRole.EDITOR:
      return <>所有权限</>;
    default:
      return null;
  }
};

const RenderPermissionTarget: FC<{ permission: PermissionDTO }> = ({ permission }) => {
  switch (permission.type) {
    case PermissionType.PUBLIC:
    case PermissionType.RESTRICTED:
      return <>{PUBLIC_SHARE}</>;
    case PermissionType.ILLEGAL:
      return null;
    case PermissionType.SPACE:
      return <CurrentSpaceSubject />;
    case PermissionType.GROUP:
      return (
        <>
          「<GroupSubject groupId={permission.groupId} />
          」成员组
        </>
      );
    case PermissionType.USER:
      return <UserSubject userId={permission.userId} />;
    default:
      return null;
  }
};
