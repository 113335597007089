import { useEffect, useState } from 'react';
import { useFinalValue } from '../hooks';
import { setLocalStorage } from './local-storage';
import { sleep } from '../async';

/**
 * 可持久化LRUCache,主要用于持久化本地数据，通过capacity可限制持久化的数据量
 *
 * 实现细节:
 * put或者get调用时，对应的key和value会被认为是最近一次使用的数据，因此会被挪到或者插入到第一个。
 * 当数据个数超过限制时，会删掉最后一个
 *
 * bug:暂不支持保存map对象,有需求你再找我加就行了
 */
const DEFAULT_CAPACITY = 50;
// 由于map无法直接存到storage，因此需要先以数组形式存
interface SaveInfo<K, V> {
  items: [K, SaveValue<V>][];
  itemsKeys: K[];
}
interface LruInfo<K, V> {
  itemsKeys: K[];
  items: Map<K, SaveValue<V>>;
}
interface SaveValue<V> {
  value: V;
  expireTime?: number;
}
function saveInfoToLruInfo<K, V>(saveInfo: SaveInfo<K, V>) {
  return {
    itemsKeys: saveInfo.itemsKeys,
    items: new Map<K, SaveValue<V>>(saveInfo.items),
  };
}
type OnChangeListener = () => void;
export class LRUCache<K, V> {
  private storage: Storage | LocalForage;
  private saveKey: string;
  private capacity: number;
  private lruInfo: LruInfo<K, V>;
  private listeners: OnChangeListener[];
  /**
   * @saveKey 用于持久化保存的key
   * @storage 持久化storage
   */
  constructor(storage: Storage | LocalForage, saveKey: string, capacity?: number) {
    this.storage = storage;
    this.saveKey = saveKey;
    this.capacity = capacity ?? DEFAULT_CAPACITY;
    this.lruInfo = {
      itemsKeys: [],
      items: new Map<K, SaveValue<V>>(),
    };
    this.listeners = [];
    void sleep(0).then(async () => {
      const info = (await this.storage.getItem(`LRU::${this.saveKey}`)) as string;
      try {
        if (info) {
          const saveInfo: SaveInfo<K, V> = JSON.parse(info);
          const lruInfo = saveInfoToLruInfo(saveInfo);
          if (lruInfo.items.size === lruInfo.itemsKeys.length) {
            this.lruInfo = lruInfo;
          }
          this.tryClearData();
        }
        this.clearOldData();
      } catch (e) {
        // ignore
      }
    });
  }
  clearOldData() {
    // 因为后台接口之前有问题，这里直接清除掉老数据
    void this.storage.removeItem(`${this.saveKey}_count`);
    void this.storage.removeItem(`${this.saveKey}_keys`);
    void this.storage.removeItem(`${this.saveKey}_map`);
  }

  put(key: K, value: V, expireTime?: number) {
    this.lruInfo.items.set(key, { value, expireTime });
    const index = this.lruInfo.itemsKeys.indexOf(key);
    if (index >= 0) {
      // 如果存在就移动到第一个
      move(this.lruInfo.itemsKeys, index, 0);
    } else {
      // 插入到第一个
      this.lruInfo.itemsKeys.splice(0, 0, key);
    }
    this.tryClearData();
    if (!this.isBatch) {
      try {
        this.persistence();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      this.notifyChangeListener();
    }
  }
  isBatch = false;
  // 批量操作时减少写storage频率
  batch = (callback: () => void) => {
    this.isBatch = true;
    callback();
    this.isBatch = false;
    try {
      this.persistence();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  private tryClearData() {
    while (this.lruInfo.itemsKeys.length > this.capacity) {
      const deleteKeys: K[] = [];
      const time = Date.now();
      // 删掉过期的
      this.lruInfo.items.forEach((v, k) => {
        if (v.expireTime && v.expireTime < time) {
          deleteKeys.push(k);
        }
      });
      deleteKeys.forEach((k) => {
        this.delete(k);
      });

      if (deleteKeys.length === 0) {
        const popKey = this.lruInfo.itemsKeys.pop();
        if (popKey !== undefined) {
          this.lruInfo.items.delete(popKey);
        }
      }
    }
  }
  // 清空内容和storage里的值
  clear() {
    this.lruInfo.itemsKeys = [];
    this.lruInfo.items.clear();
    void this.storage.removeItem(`LRU::${this.saveKey}`);
    this.notifyChangeListener();
  }

  get(key: K) {
    const index = this.lruInfo.itemsKeys.indexOf(key);
    if (index === -1) return null;
    const v = this.lruInfo.items.get(key);
    if (v && v.expireTime && v.expireTime < Date.now()) {
      this.delete(key);
      return;
    }
    // 把这个item挪到最前面
    move(this.lruInfo.itemsKeys, index, 0);
    return v?.value;
  }
  delete(key: K) {
    const index = this.lruInfo.itemsKeys.indexOf(key);
    if (index === -1) return false;
    deleteItem(this.lruInfo.itemsKeys, index);
    const ret = this.lruInfo.items.delete(key);
    this.persistence();
    this.notifyChangeListener();
    return ret;
  }
  log() {
    // eslint-disable-next-line no-console
    console.log(this.lruInfo);
  }
  /**
   *持久化
   */
  private persistence() {
    const items = Array.from(this.lruInfo.items.entries());
    const obj: SaveInfo<K, V> = {
      items,
      itemsKeys: this.lruInfo.itemsKeys,
    };
    setLocalStorage(`LRU::${this.saveKey}`, JSON.stringify(obj), this.storage);
  }
  length() {
    return this.lruInfo.items.size;
  }
  // 获取有序的items,顺序是根据最近使用来排的
  getSortItems() {
    const items: {
      key: K;
      value: V;
    }[] = [];
    const lruItems = this.lruInfo.items;
    this.lruInfo.itemsKeys.forEach((key) => {
      const value = lruItems.get(key)?.value;
      if (value !== undefined) {
        items.push({
          key,
          value,
        });
      }
    });
    return items;
  }
  addChangeListener = (listener: OnChangeListener) => {
    this.listeners.push(listener);
  };
  removeChangeListener = (listener: OnChangeListener) => {
    const index = this.listeners.indexOf(listener);
    if (index !== -1) {
      deleteItem(this.listeners, index);
    }
  };

  private notifyChangeListener = () => {
    this.listeners.forEach((listener) => {
      listener();
    });
  };
}

const deleteItem = (array: any[], index: number) => {
  array.splice(index, 1);
};

const move = (array: any[], fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex) return;
  const values = array.splice(fromIndex, 1);
  array.splice(toIndex, 0, values[0]);
};

/** 为了监听cache动态变化
 * 这个hook只适合在单个组件用，如果cache是跨组件使用的话就不行了，跨组件使用需要自己在所有组件最顶层监听onChange
 * 这个hook代码 先保留*/
export function useLRUCache<K, V>(
  storage: Storage,
  saveKey: string,
  capacity?: number | undefined
) {
  const [_, setUpdate] = useState(0);
  const cache = useFinalValue(() => new LRUCache<K, V>(storage, saveKey, capacity));

  useEffect(() => {
    const onChange = () => {
      setUpdate((v) => v++);
    };
    cache.addChangeListener(onChange);
    return () => {
      cache.removeChangeListener(onChange);
    };
  }, [cache, setUpdate]);
  return cache;
}
