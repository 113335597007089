export const UNIT_HEIGHT = 12; // 15 min
export const PROP_HEIGHT = 8;
export const MIN_HEIGHT = 18;
export const PADDING_TOP = 10;

export const CARD_GAP = 5;

export const PADDING_LEFT = 90;

export enum CalendarViewType {
  DAY = 'day',
  MONTH = 'month',
}

export interface RenderDayCard {
  uuid: string;
  startTime: number;
  endTime: number;
  left?: number; // 当前所在行的排序
  recordCount?: number; // 当前所在行的元素
}

export const CalendarTypeMap = [
  { type: 'day', name: '日视图' },
  { type: 'month', name: '月视图' },
];
