import type { FC } from 'react';
import { Fragment, Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { useCheckComplete } from '../hook/use-check-complete';
import type { LoginProps, SwitchViewTypeFun, ViewState } from '../types';
import { ViewType } from '../types';
import { loginInjector } from '../utils/init';
import { getView } from './get-view';
import i18n from './web-buildin/language';

/**登录 */
export const Login: FC<LoginProps> = (props) => {
  const { complete = true, styleType = 'default' } = props;
  const [viewState, setViewState] = useState<ViewState>(
    props.defaultViewState ?? { type: ViewType.LOGIN }
  );
  const { onSuccess, onStep, onFooterChange, ...rest } = props;
  const onSuccessRef = useRef<LoginProps['onSuccess']>();
  onSuccessRef.current = props.onSuccess;
  const onFooterChangeRef = useRef<LoginProps['onFooterChange']>();
  onFooterChangeRef.current = props.onFooterChange;

  const switchViewType = useCallback<SwitchViewTypeFun>((state) => {
    setViewState((s) => {
      return { ...s, ...state };
    });
  }, []);

  const checkComplete = useCheckComplete(switchViewType);
  useEffect(() => {
    if (!props.autoLogin) return;
    void loginInjector.request.infra.getMyUserInfo.raw().then((res) => {
      if (res.code === 200) {
        if (!complete || checkComplete(res.data)) {
          onSuccessRef.current?.(res.data, { isAuto: true });
        }
      }
    });
  }, [checkComplete, complete, props.autoLogin]);

  useEffect(() => {
    props.onStep?.(viewState.type);
  }, [props, viewState.type]);

  const View = getView(viewState.type, styleType);
  const isBuildInPc = styleType === 'build-in-pc';
  const Tag = isBuildInPc ? I18nextProvider : Fragment;
  const attribute: any = {};
  if (isBuildInPc) {
    attribute['i18n'] = i18n;
  }

  if (typeof document !== 'undefined') {
    return (
      <Suspense fallback={<div></div>}>
        <Tag {...attribute}>
          <View
            {...rest}
            {...viewState.props}
            switchViewType={switchViewType}
            complete={complete}
            styleType={styleType}
            onSuccess={onSuccessRef.current}
            onFooterChange={onFooterChangeRef.current}
          />
        </Tag>
      </Suspense>
    );
  }
  return (
    <Tag i18n={i18n}>
      <View
        {...rest}
        {...viewState.props}
        switchViewType={switchViewType}
        complete={complete}
        styleType={styleType}
        onSuccess={onSuccessRef.current}
        onFooterChange={onFooterChangeRef.current}
      />
    </Tag>
  );
};

export default Login;
