import { assign, difference, keys, pick } from 'lodash-es';
import { toolbarBaseBtns } from 'src/editor/editor/uikit/editable-toolbar/type';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $currentUser, $currentUserCache } from 'src/services/user/current-user';

export function useCurrentUser() {
  return $currentUser((state) => state);
}

export const useCurrentUserSelector = $currentUser;

export function useCurrentUserId() {
  return $currentUser((state) => state.uuid);
}

export const getCurrentUser = () => {
  return $currentUserCache;
};

export const useCurrentSpaceUser = () => {
  const userId = $currentUser((state) => state.uuid);
  return useObservableStore(
    (state) => {
      // return Object.values(state.users).find((it) => it.uuid === userId)
      return state.users[userId];
    },
    [userId]
  );
};

export const useUserToolbarShortcut = () => {
  return $currentUser((state) => {
    const stateObj = state.setting.toolbarShortcut;
    const missingKeys = difference(keys(toolbarBaseBtns), keys(stateObj));
    return assign({}, stateObj, pick(toolbarBaseBtns, missingKeys));
  });
};
