import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import { selectBlockGroupNames } from '../collection-view/table-groups/select-block-group-values';
import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';

export const useRemoveGroup = () => {
  const transaction = useTransaction();

  const removeGroup = (viewId: string, groupName: string, isSubGroup?: boolean) => {
    const {
      view,
      collection,
      groups = [],
      subGroups = [],
      groupBy,
      subGroupBy,
    } = getViewFormat(viewId) ?? {};
    if (!collection || !view) return;

    const propertyId = isSubGroup ? subGroupBy?.property : groupBy?.property;
    const oldGroups = isSubGroup ? subGroups : groups;
    const oldGroupBy = isSubGroup ? subGroupBy : groupBy;
    const propertySchema = collection.data.schema?.[propertyId ?? ''];
    if (!propertySchema || !oldGroupBy || !propertyId) return;

    transaction(() => {
      const newGroups = oldGroups.filter((group) => group.value !== groupName);
      const isBoardColumn = view.type === CollectionViewType.BOARD && !isSubGroup;
      CollectionViewManager.update(viewId, {
        format: {
          [`${isBoardColumn ? 'boardGroups' : 'collectionGroups'}`]: newGroups,
        },
      });

      const ids: string[] = [];
      const { blocks, users } = getState();
      collection.subNodes.forEach((recordId) => {
        const values = selectBlockGroupNames({
          blocks,
          users,
          collectionId: collection.uuid,
          recordId,
          groupProperty: propertyId,
          groupBy: oldGroupBy,
        });

        if (values.includes(groupName)) {
          ids.push(recordId);
        }
      });
      if (ids.length > 0) {
        archiveBlock(ids);
      }

      if (
        propertySchema.type === CollectionSchemaType.SELECT ||
        propertySchema.type === CollectionSchemaType.MULTI_SELECT
      ) {
        const newOptions = propertySchema.options?.filter((option) => option.value !== groupName);
        updateBlock(collection.uuid, {
          data: {
            schema: {
              ...collection.data.schema,
              [propertyId]: {
                ...propertySchema,
                options: newOptions,
              },
            },
          },
        });
      }
    });
  };

  return useCallback(removeGroup, [transaction]);
};
