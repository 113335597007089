import { essos } from './essos';
import { macarons } from './macarons';
import { roma } from './roma';
import { shine } from './shine';
import { vintage } from './vintage';
import { walden } from './walden';
import { westeros } from './westeros';
import { wonderland } from './wonderland';

/**
 * theme is from https://echarts.apache.org/zh/theme-builder.html
 */
export const ChartTheme: Record<string, any> = {};

const theme0 = ['#478FF7', '#A6EB9A', '#5ECBCF', '#DCBE8C', '#7F90DA', '#59A076'];
const theme1 = ['#FF7875', '#FFC53D', '#BAE638', '#35CFC9', '#3FA9FF', '#F759AB'];
const theme2 = ['#8BF2BB', '#3FD6A2', '#8BF4F8', '#3FCAEA', '#F48AFE', '#A52ED9'];
const theme3 = ['#FFD591', '#5CDBD3', '#FFC53D', '#FF4C4F', '#597FF7', '#F759AB'];
const theme4 = ['#90BBFF', '#FFD390', '#FF9F90', '#90E3FF', '#EDA1EC', '#B49FFF'];
const theme5 = ['#004FB3', '#0A6ED9', '#1890FF', '#3FA9FF', '#6AC0FF', '#91D5FF'];
const theme6 = ['#AD4F01', '#D46B07', '#FA8C17', '#FFA940', '#FFC069', '#FFD591'];
const theme7 = ['#000000', '#232323', '#474747', '#6E6E6E', '#9D9D9D', '#D8D8D8'];
const newThemes = [theme0, theme1, theme2, theme3, theme4, theme5, theme6, theme7];
for (let i = 0; i < 8; i++) {
  const themeName = `theme${i}`;
  ChartTheme[`theme${i}`] = {
    themeName,
    theme: {
      color: newThemes[i],
    },
  };
}
ChartTheme['essos'] = essos;
ChartTheme['macarons'] = macarons;
ChartTheme['roma'] = roma;
ChartTheme['shine'] = shine;
ChartTheme['vintage'] = vintage;
ChartTheme['walden'] = walden;
ChartTheme['westeros'] = westeros;
ChartTheme['wonderland'] = wonderland;
