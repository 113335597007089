/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { VITE_CDN_HOST } from 'src/env';
import { Tooltip } from '../../../../tooltip';
import { getData, getSanitizedData, unifiedToNative } from '../../utils';
import { uncompress } from '../../utils/data';
import { EmojiDefaultProps } from '../../utils/shared-default-props';
import { EmojiPropTypes } from '../../utils/shared-props';

const _getData = (props) => {
  const { emoji, skin, set, data } = props;
  return getData(emoji, skin, set, data);
};

export const _getPosition = (props) => {
  const { sheet_x, sheet_y } = _getData(props),
    multiplyX = 100 / (props.sheetColumns - 1),
    multiplyY = 100 / (props.sheetRows - 1);

  return `${multiplyX * sheet_x}% ${multiplyY * sheet_y}%`;
};

const _getSanitizedData = (props) => {
  const { emoji, skin, set, data } = props;
  return getSanitizedData(emoji, skin, set, data);
};

const _handleClick = (e, props) => {
  if (!props.onClick) {
    return;
  }
  const { onClick } = props;
  const emoji = _getSanitizedData(props);

  onClick(emoji, e);
};

const _handleOver = (e, props) => {
  if (!props.onOver) {
    return;
  }
  const { onOver } = props,
    emoji = _getSanitizedData(props);

  onOver(emoji, e);
};

const _handleLeave = (e, props) => {
  if (!props.onLeave) {
    return;
  }
  const { onLeave } = props,
    emoji = _getSanitizedData(props);

  onLeave(emoji, e);
};

const _isNumeric = (value) => {
  return !isNaN(value - parseFloat(value));
};

const _convertStyleToCSS = (style) => {
  // eslint-disable-next-line no-undef
  const div = document.createElement('div');

  for (const key in style) {
    let value = style[key];

    if (_isNumeric(value)) {
      value += 'px';
    }

    div.style[key] = value;
  }

  return div.getAttribute('style');
};

export const NimbleEmoji = React.memo((props) => {
  if (props.data.compressed) {
    uncompress(props.data);
  }
  for (const k in NimbleEmoji.defaultProps) {
    if (props[k] === undefined && NimbleEmoji.defaultProps[k] !== undefined) {
      props[k] = NimbleEmoji.defaultProps[k];
    }
  }
  const data = _getData(props);
  if (!data) {
    if (props.fallback) {
      return props.fallback(data, props);
    }
    return null;
  }

  const { unified, custom, short_names, imageUrl, keywords } = data,
    nativeEmoji = unified && unifiedToNative(unified),
    label = [nativeEmoji].concat(short_names).filter(Boolean).join(', ');
  let style = {},
    { children } = props,
    className = 'emoji-mart-emoji',
    title = null;

  if (!unified && !custom) {
    if (props.fallback) {
      return props.fallback(data, props);
    }
    return null;
  }

  if (props.tooltip) {
    if (__BUILD_IN__) {
      // 匹配英文的
      title = data.name;
    } else {
      // eslint-disable-next-line prefer-destructuring
      title = keywords[0];
    }
  }
  if (props.className) {
    className += ` ${props.className}`;
  }
  const Tag = {
    name: 'span',
    props: { ...props.attribute },
  };
  if (props.native && unified) {
    className += ' emoji-mart-emoji-native';
    // style = { fontSize: props.size, lineHeight: '1em' };
    style = { fontSize: props.size };
    children = nativeEmoji;
    const size = parseFloat(props.size);
    if (props.forceSize) {
      style.display = 'inline-block';
      style.width = `${size + 8}px`;
      style.height = `${size + 8}px`;
      style.wordBreak = 'keep-all';
    }
  } else if (custom) {
    // 自定义的emoji，目前项目没有
    className += ' emoji-mart-emoji-custom';
    style = {
      width: props.size,
      height: props.size,
      display: 'inline-block',
    };
    if (data.spriteUrl) {
      style = {
        ...style,
        backgroundImage: `url(${data.spriteUrl})`,
        backgroundSize: `${100 * props.sheetColumns}% ${100 * props.sheetRows}%`,
        backgroundPosition: _getPosition(props),
      };
    } else {
      style = {
        ...style,
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      };
    }
  } else {
    // 兼容emoji，从mac选了一个google emoji集合里面没有的，切换到window还是需要展示对应的icon
    const setHasEmoji = data[`has_img_${props.set}`] === undefined || data[`has_img_${props.set}`];

    if (!setHasEmoji && !props.ignoreEmojiSet) {
      if (props.fallback) {
        return props.fallback(data, props);
      }
      return null;
    }
    const svgName = props.useSvg
      ? unified.toLowerCase().replaceAll('-', '_').replaceAll('_fe0f', '')
      : '';
    style = {
      margin: props.useSvg ? undefined : '4px',
      width: props.size,
      height: props.size,
      backgroundImage: props.useSvg
        ? `url(${VITE_CDN_HOST}emoji/google/u${svgName}.svg)`
        : `url(${props.backgroundImageFn(props.set, props.sheetSize)})`,
      backgroundSize: props.useSvg
        ? undefined
        : `${100 * props.sheetColumns}% ${100 * props.sheetRows}%`,
      backgroundPosition: props.useSvg ? undefined : _getPosition(props),
      backgroundRepeat: props.useSvg ? undefined : 'no-repeat',
    };
    Tag.name = 'img';
    Tag.props = {
      src: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
    };
  }

  if (props.onClick && props.useButton) {
    Tag.name = 'button';
    Tag.props = {
      type: 'button',
    };
  }

  if (props.html) {
    style = _convertStyleToCSS(style);
    return `<${Tag.name} style='${style}' aria-label='${label}' ${
      title ? `title='${title}'` : ''
    } class='${className}'>${children || ''}</${Tag.name}>`;
  }
  style = { ...style, ...props.style };

  const TagName = (
    // eslint-disable-next-line react/jsx-pascal-case
    <Tag.name
      onClick={(e) => _handleClick(e, props)}
      onMouseEnter={(e) => _handleOver(e, props)}
      onMouseLeave={(e) => _handleLeave(e, props)}
      aria-label={label}
      className={className}
      list-item-custom=""
      style={style}
      {...Tag.props}
    >
      {children}
    </Tag.name>
  );

  return title ? (
    <Tooltip popup={title} popupClass="px-1.5" className="inline-block">
      {TagName}
    </Tooltip>
  ) : (
    TagName
  );
});

NimbleEmoji.propTypes /* remove-proptypes */ = {
  ...EmojiPropTypes,
  data: PropTypes.object.isRequired,
};
NimbleEmoji.defaultProps = EmojiDefaultProps;
