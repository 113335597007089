import { useDebounceElementSize } from '@flowus/common/hooks';
import {
  CardSize,
  CollectionCardImageDir,
  CollectionSchemaType,
  CollectionViewType,
  CoverAspectType,
  CoverType,
  GroupSortType,
} from '@next-space/fe-api-idl';
import { useEffect, useState } from 'react';
import { getGroupSchemaType } from 'src/bitable/bitable-manager/group-list/helper';
import {
  DEFAULT_BITABLE_PAGE_SIZE,
  getPropertyWidth,
  MAX_BITABLE_PAGE_SIZE,
} from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { ZoomLevel } from 'src/bitable/timeline-view/const';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { fixCollectionId } from 'src/utils/fix-collection-id';
import { useHScrollRef } from 'src/views/main/page-doc/context';
import { useGroupPropertySchema } from './use-group-option';
import type { CollectionProperty } from './use-properties';
import { getCurrentUser } from '../user';
import { OpenPageMode } from 'src/views/main/setting-modal/general-setting/const';

export const useCollectionView = (viewId: string) => {
  return useObservableStore(({ collectionViews }) => collectionViews[viewId], [viewId]);
};

export const useCollectionViewFormat = (viewId: string) => {
  return useObservableStore(({ collectionViews }) => collectionViews[viewId]?.format, [viewId]);
};

export const useTableCellWrap = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return (
        (view?.format.tableWrap && view.type !== CollectionViewType.TIMELINE) ??
        // 看板视图默认打开，画廊默认关闭
        view?.type === CollectionViewType.BOARD
      );
    },
    [viewId]
  );
};
export const useShowingTablePageIcon = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      // @ts-ignore typecheck
      return view?.format.showPageIcon !== false; // 默认undefined或者true都显示，false才不显示
    },
    [viewId]
  );
};

export const useShowLunarCalendar = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      if (__BUILD_IN__) return false;
      const view = collectionViews[viewId];
      return view?.type === CollectionViewType.CALENDAR && view.format.showLunarCalendar === true;
    },
    [viewId]
  );
};

export const useCardImageDir = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      if (view?.type === CollectionViewType.BOARD) {
        if (
          (view.format.boardCoverType === CoverType.PAGE_COVER ||
            view.format.boardCoverType === CoverType.PAGE_CONTENT) &&
          view.format.boardImageDir === CollectionCardImageDir.VERTICAL
        ) {
          return CollectionCardImageDir.VERTICAL;
        }
        return CollectionCardImageDir.HORIZONTAL;
      }

      if (
        (view?.format.galleryCoverType === CoverType.PAGE_COVER ||
          view?.format.galleryCoverType === CoverType.PAGE_CONTENT) &&
        view.format.galleryImageDir === CollectionCardImageDir.VERTICAL
      ) {
        return CollectionCardImageDir.VERTICAL;
      }

      return CollectionCardImageDir.HORIZONTAL;
    },
    [viewId]
  );
};

export const useCardSize = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      if (view?.type === CollectionViewType.BOARD) {
        return view.format.boardCardSize ?? CardSize.MEDIUM;
      }
      return view?.format.galleryCardSize ?? CardSize.MEDIUM;
    },
    [viewId]
  );
};

export const useCoverAspectType = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      if (view?.type === CollectionViewType.BOARD) {
        return view.format.boardCoverAspect ?? CoverAspectType.COVER;
      }
      return view?.format.galleryCoverAspect ?? CoverAspectType.COVER;
    },
    [viewId]
  );
};

export const useShowPropertyName = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];

      if (view?.type === CollectionViewType.BOARD) {
        return view?.format.boardShowPropertyName ?? true;
      }
      return view?.format.galleryShowPropertyName ?? true;
    },
    [viewId]
  );
};

export const useCardColorSetting = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];

      if (view?.type === CollectionViewType.TIMELINE) {
        return view?.format.timelineColorSetting;
      }
      return view?.format.calendarColorSetting;
    },
    [viewId]
  );
};

export const useCoverSetting = (viewId: string) => {
  return useObservableStore(
    (state) => {
      return getCoverSetting(viewId, state);
    },
    [viewId]
  );
};

export const getCoverSetting = (
  viewId: string,
  state = {
    blocks: getState().blocks,
    collectionViews: getState().collectionViews,
  }
) => {
  const { blocks, collectionViews } = state;
  const view = collectionViews[viewId];

  let previewType: CoverType | undefined;
  let previewProperty: string | undefined;
  if (view?.type === CollectionViewType.BOARD) {
    previewType = view.format.boardCoverType ?? CoverType.NONE;
  } else {
    previewType = view?.format.galleryCoverType ?? CoverType.NONE;
  }

  if (previewType === CoverType.FILE_PROPERTY) {
    const collectionId = fixCollectionId(viewId);
    const schemas = blocks[collectionId ?? '']?.data.schema ?? {};
    let propertyId: string | undefined;
    if (view?.type === CollectionViewType.BOARD) {
      propertyId = view.format.boardCoverProperty;
    } else {
      propertyId = view?.format.galleryCoverProperty;
    }

    if (schemas[propertyId ?? '']?.type === CollectionSchemaType.FILE) {
      previewProperty = propertyId;
    } else {
      previewType = CoverType.NONE;
    }
  }

  return {
    previewType,
    previewProperty,
  };
};

export const useViewParentId = (viewId?: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      if (!viewId) return;
      return collectionViews[viewId]?.parentId;
    },
    [viewId]
  );
};

export const useZoomLevel = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      return (collectionViews[viewId]?.format.timelinePreference?.zoomLevel ??
        ZoomLevel.MONTH) as ZoomLevel;
    },
    [viewId]
  );
};

export const useCalendarType = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      return collectionViews[viewId]?.format.calendarType ?? 'month';
    },
    [viewId]
  );
};

export const useShowTimelineTable = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      return collectionViews[viewId]?.format.timelineShowTable !== false;
    },
    [viewId]
  );
};

export const useBitablePageSize = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const pageSize = collectionViews[viewId]?.format.loadLimit ?? DEFAULT_BITABLE_PAGE_SIZE;
      return Math.min(pageSize, MAX_BITABLE_PAGE_SIZE);
    },
    [viewId]
  );
};
export const useOpenPageWay = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      let openPageWay = collectionViews[viewId]?.format.openPageWay;
      if (openPageWay === undefined) {
        // 如果没设置过，就用原来的用户设置
        const { setting = {} } = getCurrentUser();
        const openRight = setting?.openPageMode === OpenPageMode.RIGHT_PAGE;
        openPageWay = openRight ? 'openInRight' : 'openFullscreen';
      }
      return openPageWay;
    },
    [viewId]
  );
};

export const useGroupBy = (viewId: string, isSubgroup?: boolean) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return isSubgroup || view?.type !== CollectionViewType.BOARD
        ? view?.format.collectionGroupBy
        : view.format.boardGroupBy;
    },
    [viewId, isSubgroup]
  );
};

export const useManualSortGroup = (isSubGroup?: boolean) => {
  const { collectionId, viewId } = useBitable();
  const groupBy = useGroupBy(viewId, isSubGroup);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);
  const schemaType = getGroupSchemaType(collectionId, groupBy?.property);

  return (
    groupSchema?.type === CollectionSchemaType.SELECT ||
    groupSchema?.type === CollectionSchemaType.MULTI_SELECT ||
    groupSchema?.type === CollectionSchemaType.CHECKBOX ||
    groupSchema?.type === CollectionSchemaType.PERSON ||
    groupSchema?.type === CollectionSchemaType.CREATED_BY ||
    groupSchema?.type === CollectionSchemaType.UPDATED_BY ||
    (schemaType === 'text' && groupBy?.sort === GroupSortType.MANUAL) ||
    schemaType === 'checkbox'
  );
};
/** 如果冻结所在列在屏幕范围外侧，则拿最接近屏幕内侧的一个列作为冻结列(参照notion) */
export const useFreezeColumnIndex = (
  viewId: string,
  properties: CollectionProperty[] | undefined
) => {
  const { embed } = useBitable();
  const tableScrollRef = useHScrollRef();
  const { width } = useDebounceElementSize(tableScrollRef?.current, {
    type: 'width',
    mode: 'debounce',
    wait: 1000,
    leading: false,
  });

  const freezeColumnIndex = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return view?.format.tableFreezeColumnIndex ?? -1;
    },
    [viewId]
  );
  const [containerWidth, setContainerWidth] = useState<number>(0);

  useEffect(() => {
    if (embed) {
      const containerWidth = tableScrollRef?.current?.getBoundingClientRect().width;
      containerWidth && setContainerWidth(containerWidth);
    } else {
      const nextSpacePageContainer = document.querySelector('.next-space-page');
      const containerWidth = nextSpacePageContainer?.getBoundingClientRect().width;
      // 执行到这里一般多维表页面， padding is px-24,total 24*4*2=192px
      containerWidth && setContainerWidth(containerWidth);
    }
  }, [embed, tableScrollRef, width]);

  if (!properties) {
    return freezeColumnIndex;
  }
  if (!containerWidth) {
    return freezeColumnIndex;
  }
  // 30 is checkbox width
  let totalWidth = 30;
  let columnIndex = -1;
  for (const property of properties) {
    const width = getPropertyWidth(property);
    if (totalWidth + width > containerWidth) {
      break;
    }
    columnIndex += 1;
    totalWidth += width;
  }
  return columnIndex < freezeColumnIndex ? columnIndex : freezeColumnIndex;
};
