import { cx } from '@flowus/common/cx';
import { IpcMessage } from '@flowus/shared';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { ipcInvoke, isFlowUsApp } from 'src/utils/electron-util';
import { useIsMaster } from 'src/utils/right-utils';
import { IconUiSizeBox } from '../icon-ui-size-box';
import { KeyboardShortcut } from '../keyboard-shortcut';

export const HistoryMenu: FC = () => {
  const isMaster = useIsMaster();
  const [status, setStatus] = useState({
    canGoBack: false,
    canGoForward: false,
  });

  const history = useHistory();

  useEffect(() => {
    void ipcInvoke(IpcMessage.CanHistoryBackAndForward).then(setStatus);
    const unListen = history.listen(() => {
      void ipcInvoke(IpcMessage.CanHistoryBackAndForward).then(setStatus);
    });
    return unListen;
  }, [history]);

  const goBack = () => {
    if (!status.canGoBack) return;
    window.history.back();
  };

  const goForward = () => {
    if (!status.canGoForward) return;
    window.history.forward();
  };

  if (!isFlowUsApp.check || !isMaster) {
    return null;
  }

  return (
    <div className="flex items-center">
      <IconUiSizeBox
        disabled={!status.canGoBack}
        size={26}
        className={cx('mr-1 animate-hover', !status.canGoBack && 'opacity-30 cursor-not-allowed')}
        onClick={goBack}
        popup={
          <div>
            后退
            <KeyboardShortcut className="ml-1" shortcut="[" />
          </div>
        }
      >
        <Icon size="xlarge" name="IcArrowBack" />
      </IconUiSizeBox>
      <IconUiSizeBox
        disabled={!status.canGoForward}
        size={26}
        className={cx('animate-hover', !status.canGoForward && 'opacity-30 cursor-not-allowed')}
        onClick={goForward}
        popup={
          <div>
            前进
            <KeyboardShortcut className="ml-1" shortcut="]" />
          </div>
        }
      >
        <Icon size="xlarge" name="IcArrowForward" />
      </IconUiSizeBox>
    </div>
  );
};
