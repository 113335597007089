/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { IContent, ISelection } from '@next-space/fe-inlined';
import { throttle } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS, UPDATE_BLOCK } from 'src/redux/actions';
import { updateBlock } from 'src/redux/managers/block/update';
import { historyVersion } from 'src/redux/middlewares/op-history';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';

import { syncDiscussions } from './use-throttle-update-block';

type Segments = NextBlock['data']['segments'];

export const useThrottleUpdateProperty = (
  tableId: string,
  recordId: string,
  propertyId: string,
  editorId: string
) => {
  const transaction = useTransaction();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const segmentsGetter = useCallback(function* (block: NextBlock) {
    yield* Object.values(block.data.collectionProperties ?? {});
  }, []);

  const prevStateRef = useRef<{
    segments: Segments;
    offset: number;
  }>();

  const historyVersionRef = useRef(historyVersion);

  const sync = useMemo(() => {
    return throttle(
      (offset: number) => {
        const prevState = prevStateRef.current;
        const currBlock = getState().blocks[recordId];

        if (!prevState || !currBlock) return;

        if (historyVersionRef.current < historyVersion) {
          historyVersionRef.current = historyVersion;
          return;
        }

        const segments = currBlock.data.collectionProperties?.[propertyId] ?? [];

        transaction(() => {
          dispatch(
            HISTORY_EFFECTS({
              init() {
                updateBlock(recordId, {
                  data: {
                    collectionProperties: {
                      ...currBlock.data.collectionProperties,
                      [propertyId]: segments,
                    },
                  },
                });
                syncDiscussions(recordId, segmentsGetter);
              },
              redo() {
                updateBlock(recordId, {
                  data: {
                    collectionProperties: {
                      ...currBlock.data.collectionProperties,
                      [propertyId]: segments,
                    },
                  },
                });
                dispatch(
                  uiActions.update({
                    selectedCells: [{ recordId, propertyId, viewId: tableId }],
                  })
                );
                syncDiscussions(recordId, segmentsGetter);
                const editorModel = getOrInitEditorModel(editorId, true);
                editorModel.performChange((ctx) => {
                  ctx.select(offset);
                });
                void editorModel.requestFocus();
              },
              undo() {
                updateBlock(recordId, {
                  data: {
                    collectionProperties: {
                      ...currBlock.data.collectionProperties,
                      [propertyId]: prevState.segments ?? [],
                    },
                  },
                });
                dispatch(
                  uiActions.update({
                    selectedCells: [{ recordId, propertyId, viewId: tableId }],
                  })
                );
                syncDiscussions(recordId, segmentsGetter);
                const editorModel = getOrInitEditorModel(editorId, true);
                editorModel.performChange((ctx) => {
                  ctx.select(prevState.offset);
                });
                void editorModel.requestFocus();
              },
            })
          );
        });

        prevStateRef.current = undefined;
      },
      500,
      { leading: false }
    );
  }, [recordId, propertyId, transaction, segmentsGetter, tableId, getOrInitEditorModel, editorId]);

  const handleChangeSegments = (
    segments: Segments,
    previousSnapshot?: [IContent, ISelection | null]
  ) => {
    const editorModel = getOrInitEditorModel(editorId, true);
    const offset = editorModel.selection?.offset ?? 0;

    historyVersionRef.current = historyVersion;

    const block = getState().blocks[recordId];
    if (!block) return;

    if (!prevStateRef.current) {
      if (previousSnapshot) {
        const [previousContent, previousSelection] = previousSnapshot;
        prevStateRef.current = {
          segments: convertContentToSegments(previousContent),
          offset: previousSelection?.offset ?? 0,
        };
      } else {
        const segments = block.data.collectionProperties?.[propertyId];
        prevStateRef.current = segments
          ? {
              segments,
              offset,
            }
          : {
              segments: undefined,
              offset: 0,
            };
      }
    }

    dispatch(
      UPDATE_BLOCK({
        ignoreOp: true,
        uuid: recordId,
        patch: {
          data: {
            collectionProperties: {
              ...block.data.collectionProperties,
              [propertyId]: segments ?? [],
            },
          },
        },
      })
    );
    syncDiscussions(recordId, segmentsGetter, transaction);

    sync(offset);
  };

  return useCallback(handleChangeSegments, [
    getOrInitEditorModel,
    editorId,
    recordId,
    propertyId,
    segmentsGetter,
    transaction,
    sync,
  ]);
};
