import type { FC } from 'react';
import type { AvatarType } from '@flowus/common/avatar';
import { Avatar as InterAvatar } from '@flowus/common/avatar';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { getImageCdnUrl } from '../../utils/url-utils';
import { IconWidget } from '../emoji-widget';

export const Avatar: FC<Omit<AvatarType, 'getImageCdnUrl'>> = (props) => {
  const isDark = useIsDarkMode();

  return (
    <InterAvatar
      {...props}
      isDark={isDark}
      getImageCdnUrl={getImageCdnUrl}
      IconWidget={<IconWidget />}
    />
  );
};
