import { useMindMapEngine } from '@flowus/mind-map';
import type { FC } from 'react';

export const LineRenderer: FC<{}> = () => {
  const engine = useMindMapEngine();

  return (
    <canvas
      className="absolute left-[-150px] top-[-150px] touch-none"
      // className="absolute left-0 top-0 touch-none"
      ref={(ref) => {
        if (ref) {
          engine.setCanvas(ref);
        }
      }}
    />
  );
};
