import { Role } from '@flowus/common';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import type { FC, RefObject } from 'react';
import { memo, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { map } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { deepEqual } from '@flowus/common/utils/tools';
import { EmbedContext } from 'src/hooks/block/use-block-locked';
import { useShortcutToolBar } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-toolbar';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useGetPageId } from 'src/utils/getPageId';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { ToolbarContent } from './content';
import { ToolbarModel } from './context';
import type { ToolbarProps } from './type';
import { toolbarId } from './type';

export const Toolbar: FC<ToolbarProps> = (props) => {
  const { uuid, model, containerRef, comments = false } = props;
  const pageId = useGetPageId();
  const pageScene = usePageScene();
  const isEmbedMindMap = useContext(EmbedContext);
  const { role } = usePermissions(pageId);
  const shortcutToolBar = useShortcutToolBar(uuid);
  const { queryResult } = shortcutToolBar;
  const { getRect } = queryResult;

  const readonly = useObservable(
    () => model.onReadonlyChange.pipe(map(() => model.readonly)),
    model.readonly,
    [model]
  );

  if (!getRect) return null;

  const showCommentBtn =
    comments &&
    Role.contains(role, PermissionRole.COMMENTER) &&
    // 分享页也可以评论
    (pageScene === PageScene.MAIN || pageScene === PageScene.SHARE);

  if ((readonly && !showCommentBtn) || isEmbedMindMap) return null;

  return (
    <Popper getRect={getRect} containerRef={containerRef}>
      <div
        className="flex items-center transition-all"
        id={toolbarId}
        onPointerDown={(e) => e.preventDefault()}
      >
        <ToolbarModel {...props} shortcutToolBar={shortcutToolBar}>
          <ToolbarContent />
        </ToolbarModel>
      </div>
    </Popper>
  );
};

interface PopperProps {
  getRect: () => DOMRect;
  containerRef: RefObject<HTMLElement>;
}

const Popper: FC<PopperProps> = memo((props) => {
  const { getRect, containerRef, children } = props;
  const popperRef = useRef<HTMLDivElement>(null);
  const instanceRef = useRef<Instance>();
  const rectRef = useRef(getRect);
  rectRef.current = getRect;

  useEffect(() => {
    if (instanceRef.current) {
      void instanceRef.current.update();
    } else {
      if (containerRef.current && popperRef.current) {
        instanceRef.current = createPopper(
          {
            getBoundingClientRect: () => rectRef.current(),
            contextElement: containerRef.current,
          },
          popperRef.current,
          {
            strategy: 'fixed',
            placement: 'top-start',
            modifiers: [
              { name: 'offset', enabled: true, options: { offset: [0, 10] } },
              { name: 'eventListeners', enabled: true },
              {
                name: 'computeStyles',
                options: {
                  gpuAcceleration: false,
                },
              },
              {
                name: 'preventOverflow',
                options: {
                  tether: false,
                  altAxis: true,
                },
              },
            ],
          }
        );
      }
    }
  }, [getRect, containerRef]);

  useEffect(() => {
    return () => {
      instanceRef.current?.destroy();
    };
  }, []);

  return ReactDOM.createPortal(
    <div
      ref={popperRef}
      className="next-modal border-none overflow-hidden animate__animated animate__fadeIn__pinter z-[8848] transition-all duration-100"
      style={{ animationDuration: '0.1s', animationDelay: '0.2s' }}
    >
      {children}
    </div>,
    document.body
  );
}, deepEqual);
