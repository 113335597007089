import { useCallback } from 'react';
import { Button } from 'src/common/components/button';
import { useOpenModal } from 'src/common/components/next-modal';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { createActivityModalAnchor } from './create-activity-modal-anchor';

export const useOpenFirstTaskModal = () => {
  const openModal = useOpenModal();
  const openSettingModal = useOpenSettingModal();

  return useCallback(() => {
    const activityModalAnchor = createActivityModalAnchor();

    openModal.dropdown({
      popcorn: activityModalAnchor.popcorn,
      modalId: 'openFirstTaskModal',
      placement: 'top-end',
      offset: [4, 10],
      autoClose: false,
      keyboard: false,
      closeAfterCallBack: activityModalAnchor.remove,
      content: ({ onCloseModal }) => (
        <div className="p-7 next-modal w-80">
          <div>
            <div className="text-h3 mb-2.5">成功解锁新任务！</div>
            <div className="text-t1-medium">
              你已解锁首个新用户专享任务，积分已到手，来看看还有那些任务吧。
            </div>
          </div>
          <div className="mt-7 flex items-center">
            <Button className="mr-2.5" onClick={() => onCloseModal()}>
              稍后了解
            </Button>
            <Button
              colorType="active"
              onClick={() => {
                onCloseModal();
                openSettingModal({
                  defaultMenuType: SettingMenuType.integral,
                  from: OpenSettingFrom.package,
                });
              }}
            >
              立即查看
            </Button>
          </div>
        </div>
      ),
    });
  }, [openModal, openSettingModal]);
};

export const useOpenStageTaskModal = () => {
  const openModal = useOpenModal();
  const openSettingModal = useOpenSettingModal();

  return useCallback(() => {
    const activityModalAnchor = createActivityModalAnchor();

    openModal.dropdown({
      popcorn: activityModalAnchor.popcorn,
      modalId: 'openStageTaskModal',
      placement: 'top-end',
      offset: [4, 10],
      closeAfterCallBack: activityModalAnchor.remove,
      content: ({ onCloseModal }) => (
        <div className="p-7 next-modal w-80">
          <div>
            <div className="text-h3 mb-2.5">完成阶段性任务！</div>
            <div className="text-t1-medium">你已完成阶段性新手任务，再接再厉哦</div>
          </div>
          <div className="mt-7 flex items-center">
            <Button
              colorType="active"
              onClick={() => {
                onCloseModal();
                openSettingModal({
                  defaultMenuType: SettingMenuType.integral,
                  from: OpenSettingFrom.package,
                });
              }}
            >
              查看剩余任务
            </Button>
          </div>
        </div>
      ),
    });
  }, [openModal, openSettingModal]);
};
