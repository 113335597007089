import { cx } from '@flowus/common/cx';
import type { BlockDTO } from '@next-space/fe-api-idl';
import type { Placement } from '@popperjs/core';
import { head, last } from 'lodash-es';
import type { FC } from 'react';
import { Fragment } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { usePermissions } from 'src/hooks/share/use-permissions';
import type { NextBlock } from 'src/redux/types';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';

import { ILLEGAL_TEXT } from '../../const';
import { Tooltip } from '../tooltip';
import { EllipsisSpan } from '@flowus/common/components/ellipsis-span';

/**
 * 回收站/移动到页面的面包屑
 */
export const PageNavigator: FC<{
  navs?: (NextBlock | BlockDTO)[];
  className?: string;
  placement?: Placement;
  untitled?: string;
  showTooltip?: boolean;
  showAll?: boolean;
}> = ({ navs = [], className, placement, untitled, showTooltip = true, showAll }) => {
  if (navs.length === 0) return null;

  if (showAll) {
    return (
      <div className={cx('flex text-t4 text-grey3', className)}>
        {navs.map((block, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <span>&nbsp;/&nbsp;</span>}
              <NavBlockName title={segmentsToText(block.data.segments)} uuid={block.uuid} />
            </Fragment>
          );
        })}
      </div>
    );
  }

  if (navs.length < 3) {
    return (
      <div className={cx('flex text-t4 text-grey3', className)}>
        {navs.map((block, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <span>&nbsp;/&nbsp;</span>}
              <EllipsisSpan>
                <NavBlockName title={segmentsToText(block.data.segments)} uuid={block.uuid} />
              </EllipsisSpan>
            </Fragment>
          );
        })}
      </div>
    );
  }

  const tooltipOverlayString = navs
    .slice(1, navs.length - 1)
    .map((item) => segmentsToText(item.data.segments) || getUntitledName(item.type) || untitled)
    .join(' / ');

  return (
    <div className={cx('flex text-t4 text-grey3', className)}>
      <EllipsisSpan>
        <NavBlockName title={segmentsToText(head(navs)?.data.segments)} uuid={head(navs)?.uuid} />
      </EllipsisSpan>
      {showTooltip ? (
        <Tooltip
          maxWidth={1000}
          className="flex-shrink-0"
          popup={tooltipOverlayString}
          placement={placement}
        >
          <span>&nbsp;/ ... /&nbsp;</span>
        </Tooltip>
      ) : (
        <span>&nbsp;/ ... /&nbsp;</span>
      )}
      <EllipsisSpan>
        <NavBlockName
          title={segmentsToText(last(navs)?.data.segments)}
          uuid={last(navs)?.uuid}
          untitled={untitled}
        />
      </EllipsisSpan>
    </div>
  );
};

// 由于nav中可能有被风控的文档，所以需要一个组件来判断到底应该显示什么名字
const NavBlockName: FC<{ uuid?: string; title?: string; untitled?: string }> = ({
  uuid = '',
  title,
  untitled,
}) => {
  const block = usePickBlock(uuid, ['data']);
  const { illegal } = usePermissions(uuid);

  return <div>{illegal ? ILLEGAL_TEXT : title || getUntitledName(block?.type) || untitled}</div>;
};
