import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { Icon } from 'src/common/components/icon';
import { useShowTimelineTable } from 'src/hooks/collection-view/use-collection-view';
import { $appUiStateCache, setAppUiState, useTableShowHiddenGroupById } from 'src/services/app';
import { useBitable } from './context';

interface ToggleHiddenGroup {
  hiddenGroupsLength: number;
  isTimelineTable?: boolean;
  hiddenGroups?: ReactNode;
}

export const ToggleHiddenGroup: FC<ToggleHiddenGroup> = (props) => {
  const { hiddenGroupsLength, hiddenGroups, isTimelineTable } = props;
  const { viewId, embed } = useBitable();
  const visible = useTableShowHiddenGroupById(viewId);
  const isShowTable = useShowTimelineTable(viewId);
  const { viewType } = useBitable();

  if (hiddenGroupsLength === 0) return null;

  const isTimeline = viewType === CollectionViewType.TIMELINE;

  return (
    <>
      {isTimeline && isShowTable && !isTimelineTable ? (
        <div className="h-[42px]" />
      ) : (
        <div className="mb-1">
          <button
            data-disable-select="true"
            className={cx(
              'sticky left-0 animate-hover-black3 text-grey3 h-[38px] pl-1 pr-2 rounded flex items-center',
              embed ? 'left-0' : 'left-24'
            )}
            onClick={() => {
              setAppUiState({
                $tableShowHiddenGroup: {
                  ...$appUiStateCache.$tableShowHiddenGroup,
                  [viewId]: !visible,
                },
              });
            }}
          >
            <Icon
              name="IcArrowShow"
              size="middle"
              className="mr-1 text-grey4 duration-200 transition-all"
              style={{
                transform: visible ? 'rotate(0deg)' : 'rotate(-90deg)',
              }}
            />
            <span className="text-t2 text-grey4">{hiddenGroupsLength} 隐藏分组</span>
          </button>
        </div>
      )}

      {visible && hiddenGroups}
    </>
  );
};
