import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createModel, useModelSelect } from 'src/common/create-model';
import { useFileViewLimit } from 'src/hooks/limit/file-down-limit';

export interface ImageItemType {
  src?: string;
  key: string;
}
export const useImageProvider = () => {
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState<ImageItemType[]>([]);
  const [originImages, setOriginImages] = useState<ImageItemType[]>([]);
  const [temporaryImage, setTemporaryImage] = useState<typeof images>([]);
  const temporaryImageCount = !!temporaryImage.length;
  const fileViewLimit = useFileViewLimit();

  const onClose = () => {
    setIndex(0);
    setVisible(false);
    setTemporaryImage([]);
    setImages([...originImages]);
  };

  const openImagePreview = useCallback(
    (opt: { uuid: string; src?: string }) => {
      void fileViewLimit().then(() => {
        const idx = images.findIndex((i) => i.key === opt.uuid);
        if (idx >= 0) {
          setIndex(idx);
        } else {
          setTemporaryImage([{ key: opt.uuid, src: opt.src }]);
          setIndex(0);
        }
        setVisible(true);
      });
    },
    [fileViewLimit, images]
  );

  const onLoad = (src: string, key: string) => {
    const setState = temporaryImageCount ? setTemporaryImage : setImages;
    setState((pre) => {
      const newState = cloneDeep(pre);
      const idx = newState.findIndex((i) => i.key === key);
      const cur = newState[idx];
      if (cur) {
        Object.assign(cur, { src });
      }
      return newState;
    });
  };

  useEffect(() => {
    if (visible) {
      document.body.classList.add('overscroll-x-contain');
    } else {
      document.body.classList.remove('overscroll-x-contain');
    }
  }, [visible]);

  const setImageList = (list: ImageItemType[]) => {
    if (temporaryImageCount) {
      setTemporaryImage(list);
    } else {
      setImages(list);
      setOriginImages(list);
    }
  };

  const renderImages = useMemo(() => {
    return temporaryImageCount ? temporaryImage : images;
  }, [images, temporaryImage, temporaryImageCount]);

  return {
    index,
    visible,
    images: renderImages,
    setImages: setImageList,
    onClose,
    setIndex,
    openImagePreview,
    onLoad,
  };
};

export const useOpenImagePreview = () => {
  return useModelSelect(ImageProviderContext, (model) => model.openImagePreview);
};

export const ImageProviderContext = createModel(useImageProvider);
