import { useCallback } from 'react';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';

/** 获取页面下的 subNodes */
export const useFetchBlockSubNode = () => {
  return useCallback(async (pageId) => {
    const res = await request.editor.getDoc.raw(pageId);

    if (res.code !== 200) {
      return res.code;
    }

    // 只更新 subNode 不更新自己
    const {
      blocks: { [pageId]: _, ...blocks },
    } = res.data;

    dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
  }, []);
};
