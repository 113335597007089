import { cx } from '@flowus/common/cx';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';

interface Props {
  onClick: () => void;
  className?: string;
  icon: IconName;
  text: string;
}

export const HoverButton = ({ onClick, className, icon, text }: Props) => (
  <div
    onClick={onClick}
    className={cx(
      'min-w-22.5 whitespace-nowrap cursor-pointer flex items-center mt-1 pl-[3px] pr-1.5 text-t2 text-grey3 rounded animate-hover mb-1 mr-2 h-8 opacity-0 group-hover:opacity-100 transition-opacity',
      className
    )}
  >
    <Icon size="middle" name={icon} style={{ display: 'flex' }} />
    <div className="ml-1" style={{ display: 'block' }}>
      {text}
    </div>
  </div>
);
