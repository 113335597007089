import { isFlowUsApp } from 'src/utils/electron-util';

type FileItem = File & { fullPath?: string };
export interface LocalFolder {
  blockId?: string;
  name: string;
  files: FileItem[];
  folders: LocalFolder[];
}
/**
 * author: xi wei
 * 如果用户上传的没有包含目录，那么返回的根目录name就是空串(算是special case)
 * 由于多选场景下，用户可能选择若干个文件+目录，所以我们区分一下不同case

 * 由于拖拽文件夹和点击上传文件夹返回的files字段不一样，所以下面的代码有一些hack
 * PS:除了作者本人，请别改这个文件
 */
export const parseFiles = (
  files: FileList | FileItem[],
  includeHideFile?: boolean // 是否包含隐藏文件，默认不包含
): LocalFolder | null => {
  if (!files[0]) return null;
  // 默认文件夹没有名字，如果用户选中单个文件夹才有名字(这块逻辑需要找作者问下前后背景，最好让作者本人去维护)
  const rootFolder: LocalFolder = { name: '', files: [], folders: [] };
  let lastRootFolderName = '';
  const rootFolderNameSet = new Set<string | number>();
  for (const file of files) {
    if (!includeHideFile) {
      // 过滤掉隐藏文件
      if (file.name.startsWith('.')) {
        continue;
      }
    }
    if (!isFileItem(file)) continue;
    if (file.webkitRelativePath) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      file.fullPath = file.webkitRelativePath ?? '';
    }
    // @ts-ignore compat
    if (!file.fullPath) {
      // @ts-ignore compat
      file.fullPath = isFlowUsApp.check ? '' : file.path ?? '';
    }

    if (file.fullPath === undefined) continue;
    const startIndex = file.fullPath.indexOf('/');
    const endIndex = file.fullPath.lastIndexOf('/');
    /** 确定上传的是不是单独的文件夹 */
    if (startIndex >= 0) {
      lastRootFolderName = file.fullPath.slice(0, startIndex);
      rootFolderNameSet.add(lastRootFolderName);
    } else {
      rootFolderNameSet.add(0); // 占位，如果rootFolderNameSet大于1，说明用户选中的不是单个文件夹
    }

    if (startIndex === endIndex) {
      // 相同说明这个文件在根目录
      rootFolder.files.push(file);
    } else {
      const path = file.fullPath.slice(0, endIndex);

      const folderNames = getFolderNames(path);
      folderNames.shift(); // remove root folder name if exist

      let folder = rootFolder;
      for (const folderName of folderNames) {
        // 根据层级创建目录,如果已经存在就不需要再创建了
        const subFolder = folder.folders.find((v) => v.name === folderName);
        if (!subFolder) {
          const tmpFolder = { name: folderName, files: [], folders: [] };
          folder.folders.push(tmpFolder);
          folder = tmpFolder;
        } else {
          folder = subFolder;
        }
      }
      folder.files.push(file);
    }
  }
  if (rootFolderNameSet.size === 1) {
    // 没有子目录，说明用户选中的全是根目录的文件
    rootFolder.name = lastRootFolderName;
  }

  return rootFolder;
};

const getFolderNames = (path: string) => {
  return path.split('/');
};
/** just a hack */
function isFileItem(item: any): item is FileItem {
  return true;
}
