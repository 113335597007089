import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo } from 'react';
import { useBitable } from 'src/bitable/context';
import { Checkbox } from 'src/components/check-box';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import type { CellViewProps } from './types';

export const CheckboxValue: FC<CellViewProps> = memo(({ recordId, propertyId, className }) => {
  const { collectionId, readonly } = useBitable();
  const checked = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);

        return formulaTool.getValue(recordId, propertyId) as boolean;
      }
      const text = segmentsToText(blocks[recordId]?.data.collectionProperties?.[propertyId]);
      return formatCheckBoxValue(text);
    },
    [collectionId, recordId, propertyId]
  );

  return (
    <div style={{ padding: 6 }}>
      <button
        disabled={readonly}
        className={cx('block text-left', className)}
        style={{ width: 24, height: 24 }}
      >
        <Checkbox size="auto" checked={checked} />
      </button>
    </div>
  );
});
