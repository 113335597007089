export const FileRegex = {
  image: /^(apng|avif|bmp|gif|ico|cur|jpg|jpeg|jfif|pjep|pjp|png|svg|webp|heic|heif|pic|tif)$/i,
  audio: /^(mp3|wma|wave|flac|aac|cda|midi|ape|aiff|ra|rm|rmx|vqf|amr|aiff|ogg|ac3|m4a|wav)$/i,
  video: /^(mp4|mov|flv|webm|m4v|rm|rmvb|wmv|avi|mpg|mpeg|flc|mkv|f4v|3gp|asf)$/i,
  txt: /^(txt|rtf|ts|tsx|js|jsx|html|xml|css|less|scss|cpp|py|c|cs|h|asp|sh|go|java|php|json|yml|md|log|m|ini|lua|sql|rs|rb)$/i,
  office: /^(doc|docx|xls|xlsx|ppt|pptx)$/i,
  pdf: /^(pdf)$/i,
  zip: /^(7z|rar|jar|zip|cab|ace|tar)$/i,
  ppt: /^(ppt|pptx)$/i,
  csv: /^(csv)$/i,
  epub: /^(epub)$/i,
};

export const isUnknownFile = (str = '') => {
  return Object.values(FileRegex).every((reg) => {
    return !reg.test(str);
  });
};

/** 已知图片格式不全 */
export const checkImagePreviewFormat = (str = '') => {
  const isImageExtName = FileRegex.image.test(str);
  const unknownFile = isUnknownFile(str);
  if (isImageExtName || unknownFile) {
    return true;
  }
  return false;
};
