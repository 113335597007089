import { createSetState, createStore } from '@flowus/common/zustand/utils';
import type { ActivitiesStateType } from './type';

const initState = {
  activityList: [],
  basicList: [],
  advancedList: [],
  otherList: {},
};

export const $activitiesState = createStore<ActivitiesStateType>(() => initState);

export const $setActivitiesState = createSetState($activitiesState);
