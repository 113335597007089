import { message } from '@flowus/common/components/message';
import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAddSubNode } from 'src/mind-map/hook/use-add-sub-node';
import { useCreateDocPage } from '../page';
import { useOpenPage } from '../page/use-open-page';
import { useTransaction } from '../use-transaction';

interface Params {
  parentId: string;
  openType?: 'push' | 'openPage';
}
export const useCreateMindMap = () => {
  const transaction = useTransaction();
  const addSubNode = useAddSubNode();
  const createPage = useCreateDocPage();
  const openPage = useOpenPage();
  const history = useHistory();

  return useCallback(
    (params: Params) => {
      const mindMapId = createPage(BlockType.MIND_MAPPING_PAGE, {
        parentId: params.parentId,
        last: true,
      });

      if (!mindMapId) {
        message.error('创建失败');
        return;
      }

      transaction(() => {
        addSubNode({ parentId: mindMapId, last: true }, mindMapId);
        addSubNode({ parentId: mindMapId, last: true }, mindMapId);
        addSubNode({ parentId: mindMapId, last: true }, mindMapId);
      });

      if (params.openType === 'openPage') {
        openPage(mindMapId);
      } else {
        history.push(`/${mindMapId}`);
      }
    },
    [addSubNode, createPage, history, openPage, transaction]
  );
};
