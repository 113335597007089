import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import type { DiscussionDTO } from '@next-space/fe-api-idl';
import type { IEditorModel } from '@next-space/fe-inlined';
import type { Placement } from '@popperjs/core';
import { useCallback, useEffect, useRef } from 'react';
import { DraftProvider, LocalStorageDraftManager } from 'src/common/components/draft-manager';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import { useReadonly } from 'src/hooks/page';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import type { RootState } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { Discussion } from './discussion';

// 用于“回复”评论 和 点击富文本中带评论文字
export const useOpenDiscussionsPopup = (filter: 'unresolved' | 'all' = 'unresolved') => {
  const openModal = useOpenModal();
  const draftManager = useFinalValue(() => new LocalStorageDraftManager());
  const pageId = useGetPageId();
  const readonly = useReadonly(pageId, false, true);
  return useCallback(
    (params: {
      editorModel?: IEditorModel;
      popcorn: ModalSchema.PopcornType;
      placement?: Placement;
      mask?: boolean;
      autoFocus?: boolean;
      selector: (state: {
        blocks: RootState['blocks'];
        comments: RootState['comments'];
        discussions: RootState['discussions'];
      }) => DiscussionDTO[];
    }) => {
      const { editorModel, popcorn, mask = true, placement = 'bottom', selector } = params;
      const discussions = selector(getState());
      if (discussions.length <= 0) return;

      setTimeout(() => {
        if (editorModel != null && !editorModel.isContentRendered) return;
        const Popup = (props2: { onCloseModal: () => void }) => {
          const props2Ref = useRef(props2);
          props2Ref.current = props2;
          useEffect(() => {
            if (editorModel == null) return;
            const subscription = editorModel.onChange.subscribe(() => {
              props2Ref.current.onCloseModal();
            });
            return () => subscription.unsubscribe();
          }, []);

          const discussions = useObservableStore(selector, []);
          const openDiscussions = discussions.filter((it) =>
            filter === 'unresolved' ? !it.resolved : true
          );
          const noDiscussions = openDiscussions.length === 0;
          useEffect(() => {
            if (noDiscussions) {
              props2Ref.current.onCloseModal();
            }
          }, [noDiscussions]);

          return (
            <DraftProvider manager={draftManager}>
              <div
                className={cx(
                  'next-modal w-125 max-h-[80vh] overflow-y-auto py-2',
                  discussions.length === 0 && 'hidden'
                )}
              >
                {discussions.map((discussion) => (
                  <Discussion
                    readonly={readonly}
                    autoFocus={params.autoFocus || judgeSharePage()}
                    allowCommentInSharePage={true}
                    key={discussion.uuid}
                    draftKey={discussion.uuid}
                    discussionId={discussion.uuid}
                    className="border-t first:border-t-0"
                  />
                ))}
              </div>
            </DraftProvider>
          );
        };

        openModal.dropdown({
          modalId: Modals.COMMENTS,
          mask,
          autoClose: true,
          popcorn,
          content: Popup,
          placement,
          modifiers: [{ name: 'preventOverflow', options: { padding: 15 } }],
          closeNoAnimate: true,
        });
      });
    },
    [draftManager, filter, openModal, readonly]
  );
};

// 带 tab 分组的，用于块 badge 弹窗
export const useOpenCategoriedDiscussionsPopup = () => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  const readonly = useReadonly(pageId, false, true);
  const draftManager = useFinalValue(() => new LocalStorageDraftManager());
  return useCallback(
    (params: {
      editorModel?: IEditorModel;
      popcorn: ModalSchema.PopcornType;
      placement?: Placement;
      mask?: boolean;
      autoFocus?: boolean;
      selector: (state: {
        blocks: RootState['blocks'];
        comments: RootState['comments'];
        discussions: RootState['discussions'];
      }) => DiscussionDTO[];
    }) => {
      const { editorModel, popcorn, mask = true, placement = 'bottom', selector } = params;
      const discussions = selector(getState());
      if (discussions.length <= 0) return;

      setTimeout(() => {
        const Popup = (props2: { onCloseModal: () => void }) => {
          const props2Ref = useRef(props2);
          props2Ref.current = props2;
          useEffect(() => {
            if (editorModel == null) return;
            const subscription = editorModel.onChange.subscribe(() => {
              props2Ref.current.onCloseModal();
            });
            return () => subscription.unsubscribe();
          }, []);

          const discussions = useObservableStore(selector, []);
          const resolvedDiscussions = discussions.filter((it) => it.resolved);
          const openDiscussions = discussions.filter((it) => !it.resolved);
          const noDiscussions = openDiscussions.length === 0;

          useEffect(() => {
            if (noDiscussions) {
              props2Ref.current.onCloseModal();
            }
          }, [noDiscussions]);

          return (
            <DraftProvider manager={draftManager}>
              <div
                className={cx(
                  'next-modal w-[500px] max-h-[80vh] overflow-y-auto',
                  discussions.length === 0 && 'hidden'
                )}
              >
                <Tab
                  key={`${openDiscussions.length > 0 ? 1 : 0}${
                    resolvedDiscussions.length > 0 ? 1 : 0
                  }`}
                >
                  {openDiscussions.length > 0 && (
                    <TabPane title={`评论（${openDiscussions.length}）`}>
                      <div className="px-4">
                        {openDiscussions.map((discussion) => (
                          <Discussion
                            readonly={readonly}
                            autoFocus={params.autoFocus}
                            allowCommentInSharePage={true}
                            key={discussion.uuid}
                            draftKey={discussion.uuid}
                            discussionId={discussion.uuid}
                            className="border-t first:border-t-0"
                          />
                        ))}
                      </div>
                    </TabPane>
                  )}
                  {resolvedDiscussions.length > 0 && (
                    <TabPane title={`已解决（${resolvedDiscussions.length}）`}>
                      <div className="px-4">
                        {resolvedDiscussions.map((discussion) => (
                          <Discussion
                            readonly={readonly}
                            autoFocus={params.autoFocus}
                            key={discussion.uuid}
                            discussionId={discussion.uuid}
                            className="border-t first:border-t-0"
                          />
                        ))}
                      </div>
                    </TabPane>
                  )}
                </Tab>
              </div>
            </DraftProvider>
          );
        };

        openModal.dropdown({
          mask,
          autoClose: true,
          popcorn,
          modifiers: [{ name: 'preventOverflow', options: { padding: 15 } }],
          content: Popup,
          placement,
          closeNoAnimate: true,
        });
      });
    },
    [draftManager, openModal, readonly]
  );
};
