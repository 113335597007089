import { deepEqual } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { useDrag } from '@use-gesture/react';
import { clamp } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import type { CollectionProperty } from 'src/bitable/bitable-manager/property-list';
import { getGroupUniqueId } from 'src/bitable/bitable-utils';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdateColumnWidth } from 'src/hooks/block/use-update-column-width';
import { setAppUiState, useIsNewProperty, useIsNewPropertyByIsSameGroup } from 'src/services/app';
import {
  DEFAULT_ROW_HEIGHT,
  getPropertyWidth,
  ICON_MAP,
  MAX_COL_WIDTH,
  MIN_COL_WIDTH,
} from '../../const';
import { PropertyManagePlace } from '../types';
import { useOpenPropertyWidget } from '../widgets/property';

interface Props {
  col: CollectionProperty;
  isGroup?: boolean;
  groupName?: string;
}

export const TableSortableItem: FC<Props> = memo((props) => {
  const { viewId, managerReadonly, viewType, relationEditor, isLocked } = useBitable();
  const { col, isGroup } = props;
  const updateWidth = useUpdateColumnWidth(viewId, col.property);
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const width = getPropertyWidth(col);

  const bind = useDrag(
    ({ offset: [mx] }) => {
      updateWidth(clamp(mx, MIN_COL_WIDTH, MAX_COL_WIDTH));
    },
    { from: width, preventDefault: true }
  );
  return (
    <div
      data-disable-select
      className={cx(
        'relative flex-shrink-0 h-full print:border-t print:border-b print:border-r transition-all ease-out',
        !isGroup && !isTimeline && !relationEditor && 'border-r'
      )}
      style={{ width }}
    >
      <Item {...props} />
      {!managerReadonly && !isLocked && (
        <div
          {...bind()}
          style={{ touchAction: 'none' }}
          className="absolute -right-0.5 z-10 cursor-col-resize top-0 w-1 duration-150 hover:bg-black h-full"
        />
      )}
    </div>
  );
}, deepEqual);

export const Item: FC<Props> = memo((props) => {
  const { col, groupName } = props;
  const { viewId, collectionId, viewType, readonly, managerReadonly, isLocked } = useBitable();
  const ref = useRef<HTMLButtonElement>(null);
  const { propertySchema } = usePropertySchema(collectionId, col.property);
  const openPropertyWidget = useOpenPropertyWidget();
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const isNewProperty = useIsNewPropertyByIsSameGroup(
    col.property,
    PropertyManagePlace.TABLE_HEADER,
    getGroupUniqueId(viewId, groupName)
  );

  useIsNewProperty(col.property, PropertyManagePlace.TABLE_HEADER);

  useEffect(() => {
    if (!ref.current) return;

    if (isNewProperty) {
      openPropertyWidget(col.property, ref.current);
      setAppUiState({ $newTableProperty: undefined });
    }
  }, [col.property, isNewProperty, openPropertyWidget]);

  if (!propertySchema) return null;

  const openPropSetting = (event: React.MouseEvent) => {
    if (allReadOnly || isLocked) return null;
    // idnumber 不响应(参照竞品)
    if (col.type === CollectionSchemaType.ID_NUMBER) return;
    event.preventDefault();
    openPropertyWidget(col.property, event.currentTarget);
  };

  const allReadOnly = readonly && managerReadonly;
  return (
    <button
      ref={ref}
      disabled={allReadOnly}
      className={cx('text-t2 flex items-center w-full h-full px-2', {
        'animate-hover rounded-none': !allReadOnly,
        'pt-[37px] print:pt-0': isTimeline && !groupName,
      })}
      onContextMenu={openPropSetting}
      data-disable-contextmenu
      onClick={openPropSetting}
    >
      <span
        className="flex items-center overflow-hidden text-grey3 whitespace-nowrap overflow-ellipsis"
        style={{ height: DEFAULT_ROW_HEIGHT }}
      >
        {propertySchema.type !== CollectionSchemaType.ID_NUMBER && (
          <Icon className="mr-1" size="middle" name={ICON_MAP[propertySchema.type]} />
        )}
        {propertySchema.name}
      </span>
    </button>
  );
}, deepEqual);
