import { debounce } from 'lodash-es';
import { useMemo, useRef } from 'react';

export const sleep = (durationInMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, durationInMs);
  });
};
export class Deferred<T = void> {
  resolve!: T extends undefined
    ? (value?: unknown | PromiseLike<unknown>) => void
    : (value: T | PromiseLike<T>) => void;

  reject!: (error: Error) => void;

  promise = new Promise<T>((_resolve, _reject) => {
    // @ts-ignore: ignore
    this.resolve = _resolve;
    this.reject = _reject;
  });
}

interface DebounceStatusProps {
  defaultStatus?: boolean;
  wait: number;
  leading?: boolean;
  trailing?: boolean;
  maxWait?: number;
}
export const useDebounceStatus = (props: DebounceStatusProps) => {
  const { defaultStatus, wait, leading = true, maxWait, trailing = true } = props;

  const status = useRef(!!defaultStatus);

  const changeStatus = useMemo(() => {
    const callback = (newStatus: boolean) => {
      status.current = newStatus;
    };
    if (wait) return debounce(callback, wait, { leading, maxWait, trailing });
    return callback;
  }, [leading, maxWait, trailing, wait]);

  return {
    changeStatus,
    status,
  };
};
