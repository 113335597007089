import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { newContent, newText, registerElementMeta } from '@next-space/fe-inlined';

import { SELECT_INLINE_TAG } from './const';
import type { InlinePlugin } from './inline-plugin';

const Select: IElementComponent = ({ htmlDataProps, tip, symbol }) => {
  return (
    <span>
      <span {...htmlDataProps} contentEditable={false}>
        {(symbol as string) ?? '@'}
      </span>
      <span data-tr40owj="" data-cursor-stop="">
        {'\u200b\u200b'}
      </span>
      {tip && <span className="text-grey4 ml-[1px] mr-[2px]">{tip as string}</span>}
    </span>
  );
};

const SELECT_INLINE_META: IElementMeta = {
  tag: SELECT_INLINE_TAG,
  hasContent: false,
  legalize: () => {
    return newContent([newText('@')]);
  },
};

registerElementMeta(SELECT_INLINE_META);

export const SelectInlinePlugin: InlinePlugin = {
  elementMeta: SELECT_INLINE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, Select);
  },
};
