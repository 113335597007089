/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { PageActivityDTO } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import { Button } from 'src/common/components/button';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useOpenDiscussionsPopup } from 'src/views/comments/comment-hooks';
import { CommentChange } from '../changes/comment-change';
import { PageSubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const CommentFeed = (props: { pageActivity: PageActivityDTO; alreadyRead?: boolean }) => {
  const { pageActivity, alreadyRead = true } = props;
  const discussion = useObservableStore(
    (state) => {
      if (pageActivity.discussionId == null) return undefined;
      return state.discussions[pageActivity.discussionId];
    },
    [pageActivity.discussionId]
  );
  const openCommentPopup = useOpenDiscussionsPopup('all');
  const changes = [];
  for (const edit of pageActivity.edits) {
    if (edit.type === 'commentCreated') {
      changes.push(
        <CommentChange
          key={changes.length}
          discussionId={edit.discussionId!}
          from={undefined}
          to={edit.commentData?.current}
        />
      );
    } else if (edit.type === 'commentChanged') {
      changes.push(
        <CommentChange
          key={changes.length}
          discussionId={edit.discussionId!}
          from={edit.commentData?.before}
          to={edit.commentData?.after}
        />
      );
    } else {
      changes.push(
        <CommentChange
          key={changes.length}
          discussionId={edit.discussionId!}
          from={edit.commentData?.current}
          to={undefined}
        />
      );
    }
  }

  if (discussion == null || changes.length <= 0) return null;
  return (
    <FeedTemplate
      edits={pageActivity.edits}
      action={__BUILD_IN__ ? 'commented in' : '发布了评论'}
      subject={<PageSubject pageId={pageActivity.parentId} />}
      subjectFirst={!__BUILD_IN__}
      timestamp={pageActivity.endTime}
      header={
        segmentsToText(discussion.context) === '' ? undefined : (
          <div className="my-1.5 ml-1 border-l-[3px] border-l-yellow pl-2 line-clamp-3">
            <RichText className="opacity-60" segments={discussion.context} />
          </div>
        )
      }
      badge={!alreadyRead && <div className="rounded-full w-2 h-2 bg-active_color" />}
      footer={
        discussion.status === BlockStatus.NORMAL &&
        // 产品要求已解决评论也能“回复”
        // !discussion.resolved &&
        cache.blocks[discussion.parentId]?.status === BlockStatus.NORMAL ? (
          <div className="ml-1 mt-3 mb-1.5">
            <Button
              onClick={(event) => {
                openCommentPopup({
                  popcorn: event.currentTarget,
                  selector: (state) => {
                    const discussion = state.discussions[pageActivity.discussionId ?? ''];
                    if (discussion == null) return [];
                    if (discussion.status !== BlockStatus.NORMAL) return [];
                    return [discussion];
                  },
                  autoFocus: true,
                });
              }}
            >
              回复
            </Button>
          </div>
        ) : undefined
      }
      changes={changes}
    ></FeedTemplate>
  );
};
