import {
  cacheDateTimeStamp,
  cacheDateStamp,
  cacheDateMomentStamp,
  cacheDateHourStamp,
} from 'src/services/global-cache';

export const MONTHS = '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_');
export const WEEKDAYS_SHORT = '日_一_二_三_四_五_六'.split('_');
export const WEEKDAYS_LONG = '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_');
export const ONE_MINUTES = 60 * 1000;
export const ONE_MOMENT = 15 * ONE_MINUTES;
export const ONE_HOURS = 4 * ONE_MOMENT;
export const ONE_DAY = 24 * ONE_HOURS;

export const getDateTimeStamp = (time: number) => {
  if (cacheDateTimeStamp.has(time)) {
    return cacheDateTimeStamp.get(time);
  }
  const result = new Date(new Date(time).toDateString()).getTime();
  cacheDateTimeStamp.set(time, result);
  return result;
};

export const getDateHourStamp = (time: number) => {
  if (cacheDateStamp.has(time)) {
    return cacheDateStamp.get(time);
  }
  const result = new Date(time).setHours(new Date(time).getHours(), 0, 0, 0);
  cacheDateStamp.set(time, result);
  return result;
};

export const getDateMomentStamp = (time: number) => {
  if (cacheDateMomentStamp.has(time)) {
    return cacheDateMomentStamp.get(time);
  }
  const hours = getDateHourStamp(time);
  const result = hours + Math.floor((time - hours) / ONE_MOMENT) * ONE_MOMENT;
  cacheDateMomentStamp.set(time, result);
  return result;
};

export const getMonday = (time: number = Date.now()) => {
  if (cacheDateHourStamp.has(time)) {
    return cacheDateHourStamp.get(time);
  }
  const result = getDateTimeStamp(time) - ((new Date(time).getDay() || 7) - 1) * ONE_DAY;
  cacheDateHourStamp.set(time, result);
  return result;
};

export const formatWeekText = (str: string) => {
  if (__BUILD_IN__) {
    return str;
  }
  return ['周', str].join('');
};
