import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { createActivityModalAnchor } from './create-activity-modal-anchor';

export const useOpenAllTaskModal = () => {
  const openModal = useOpenModal();

  return useCallback(() => {
    const activityModalAnchor = createActivityModalAnchor();

    openModal.dropdown({
      popcorn: activityModalAnchor.popcorn,
      modalId: 'openStageTaskModal',
      placement: 'top-end',
      offset: [4, 10],
      closeAfterCallBack: activityModalAnchor.remove,
      content: () => (
        <div className="p-7 next-modal w-80">
          <div>
            <div className="text-h3 mb-2.5">完成所有新手任务！</div>
            <div className="text-t1-medium">恭喜！你已完成所有新手任务！</div>
            <div className="text-t1-medium">后续使用过程中若遇到问题，随时点击我查看帮助哦~</div>
          </div>
          <div className="mt-7 flex items-center"></div>
        </div>
      ),
    });
  }, [openModal]);
};
