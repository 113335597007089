import type { OffsetOptions, Placement } from '@floating-ui/react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import type { CSSProperties, MutableRefObject } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { cx } from './cx';

export interface TooltipProps {
  popup?: React.ReactNode;
  content?: (closeSelf: () => void) => React.ReactNode;
  children: React.ReactNode;
  className?: string;
  popupClassName?: string;
  placement?: Placement;
  offset?: OffsetOptions;
  onClick?: React.MouseEventHandler;
  portalRoot?: HTMLElement | null | MutableRefObject<HTMLElement | null>;
  isPopover?: boolean;
  event?: 'click' | 'mousedown';
  duration?: number;
  delay?: Partial<{ open: number; close: number }>;
  flexCenter?: boolean;
  maxWidth?: number | string;
  style?: CSSProperties;
  id?: string;
  disabled?: boolean;
}
// export const Tooltip = (props: TooltipProps) => {
//   return (
//     <div className={props.className} onClick={props.onClick}>
//       {props.children}
//     </div>
//   );
// };

export const Tooltip = (props: TooltipProps) => {
  const { root } = useContext(TooltipPortalRootContext);
  const {
    offset: offsetOptions,
    placement = 'top',
    children,
    popup,
    popupClassName,
    className,
    onClick,
    isPopover,
    event,
    content,
    duration = 200,
    delay,
    flexCenter = true,
    maxWidth = 200,
    style,
    id,
    disabled,
    ...reset
  } = props;
  const portalRoot = props.portalRoot ?? root ?? document.body;

  const [open, setOpen] = useState(false);
  const [contentContainer, setContainer] = useState<HTMLDivElement | null>(null);

  const { context, refs, floatingStyles } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetOptions ?? 8),
      flip({
        crossAxis: placement?.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  });

  const { setReference, setFloating, domReference } = refs;

  const hover = useHover(context, { delay: delay ?? { open: 200, close: 150 }, enabled: !event });
  const click = useClick(context, { event, enabled: !!event });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click]);

  const { styles } = useTransitionStyles(context, {
    duration: {
      open: duration,
      close: 50,
    },
    initial: { opacity: 0.5 },
    close: { opacity: 0 },
  });

  useEffect(() => {
    if (!contentContainer || !domReference.current) return;

    const handleMask = (event: MouseEvent) => {
      const path = event.composedPath();
      const target = path[0] as HTMLElement;
      if (!contentContainer.contains(target) && !domReference.current?.contains(target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleMask);
    return () => {
      document.removeEventListener('click', handleMask);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentContainer]);

  const close = () => setOpen(false);

  return (
    <>
      <div
        ref={(node) => {
          setReference(node);
        }}
        className={cx(
          onClick && 'animate-click',
          flexCenter ? 'flex justify-center items-center' : 'text-left',
          className
        )}
        data-state={context.open ? 'open' : 'closed'}
        style={style}
        id={id}
        {...reset}
        {...getReferenceProps({ onClick })}
      >
        {children}
      </div>

      {open && !disabled && (popup || content) && (
        <FloatingPortal root={portalRoot}>
          <div
            ref={(node) => {
              setContainer(node);
              setFloating(node);
            }}
            style={floatingStyles}
            {...getFloatingProps()}
            className="z-[10000]"
          >
            <div
              style={{ ...styles, maxWidth }}
              className={cx(
                !isPopover &&
                  'bg-black2 px-2 py-1 text-t4-medium text-white next-modal !rounded whitespace-pre-line',
                popupClassName
              )}
            >
              {content ? content(close) : popup}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
};

export const TooltipPortalRootContext = createContext<{ root: null | HTMLElement }>({
  root: null,
});
