import { getFileNameInfo, getFormatImageUrl } from '@flowus/common';
import { ossImageSnapshotUrl } from '@flowus/common/url';
import type { FC } from 'react';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { compressImageSupport } from 'src/common/utils/url-utils';
import { useResource } from 'src/hooks/public/use-resource';
import type { IconImageProps } from './icon-image';
import { IconImage } from './icon-image';

export const OssIconImage: FC<
  Omit<IconImageProps, 'src'> & { blockId: string; ossName: string }
> = (props) => {
  const { imageUrl, loading } = useResource(props.blockId, {
    disableLocalUrl: true,
    isImage: true,
    ossName: props.ossName,
  });

  const { extName } = getFileNameInfo(props.ossName);

  let imgProcess = '';

  if (compressImageSupport.test(extName)) {
    imgProcess = ossImageSnapshotUrl('', Math.floor(256 * Math.ceil(window.devicePixelRatio)));
  }

  if (loading) {
    return <LoadingIcon className={props.className} size="middle" />;
  }

  return <IconImage {...props} src={getFormatImageUrl(`${imageUrl}${imgProcess}`, extName)} />;
};
