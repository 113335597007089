import type { DiscussionDTO } from '@next-space/fe-api-idl';
import { createAction } from '@reduxjs/toolkit';

export const CREATE_DISCUSSION = createAction<{
  discussion: DiscussionDTO;
  ignoreOp?: boolean;
}>('discussion/create');

export const UPDATE_DISCUSSION = createAction<{
  uuid: string;
  patch: Partial<DiscussionDTO>;
  ignoreOp?: boolean;
}>('discussion/update');

export const LIST_AFTER_DISCUSSION = createAction<{
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  after?: string;
  ignoreOp?: boolean;
}>('discussion/list-after');

export const LIST_REMOVE_DISCUSSION = createAction<{
  uuid: string;
  parentId: string;
  spaceId: string;
  ignoreOp?: boolean;
}>('discussion/list-remove');
