import type { FC } from 'react';
import { LoadingIcon } from 'src/common/components/loading-icon';

export const ImageUploading: FC<{ progress?: number }> = ({ progress = 0 }) => {
  if (progress < 0) return null;
  return (
    <div className="absolute top-0 right-0 z-10 flex justify-end w-full h-full bg-black1/70">
      {/* 背景 */}
      <div className="flex flex-row items-center w-[63px] h-7.5 px-1.5 mr-2.5 mt-2.5 rounded-sm bg-black/75 dark:text-black">
        <LoadingIcon size="middle" />
        <div className="text-white1 text-t4">{progress}%</div>
      </div>
    </div>
  );
};
