import { useEffect, useRef, useState } from 'react';
import { useStateRef } from '@flowus/common/hooks/react-utils';
import { LoadingIcon } from '../loading-icon';
import { sleep } from '@flowus/common/async';
import { useDeepCompareEffect } from 'ahooks';

interface Props<T> {
  /** 要渲染的数组 */
  items?: T[];
  /** 每组数量 */
  chunkSize?: number;
  /** 触发阈值 */
  maxSize?: number;
  /** 渲染列表 */
  renderItem?: (item: T) => JSX.Element;
  /** 开始和结束 */
  onProgress?: (status: 'start' | 'end') => void;
  count?: number;
}
export const LazyList = <T extends {}>(props: Props<T>) => {
  const { items = [], chunkSize = 10, maxSize = 100, renderItem, onProgress = () => {} } = props;
  const count = items.length;
  const [release, setRelease] = useState(maxSize >= count);
  const [loadCount, setLoadCount, loadCountRef] = useStateRef(release ? count : chunkSize);
  const init = useRef(false);

  useDeepCompareEffect(() => {
    const clear = () => {
      runRequestAnimationFrameTask(() => {
        setRelease(true);
        onProgress('end');
      });
    };

    if (release) return;

    if (init.current) {
      clear();
      return;
    }

    init.current = true;

    if (count > maxSize) {
      void Promise.resolve().then(() => {
        onProgress('start');
        const loop = () => {
          runRequestAnimationFrameTask(() => {
            setLoadCount((pre) => pre + chunkSize);
            if (loadCountRef.current + chunkSize >= count) {
              clear();
            } else {
              loop();
            }
          });
        };

        if (count > loadCountRef.current) {
          loop();
          return;
        }
        clear();
      });
    } else {
      clear();
    }

    return clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, items, release]);

  return (
    <>
      {<>{(release ? items : [...items].splice(0, loadCount)).map((item) => renderItem?.(item))}</>}
      <>
        {!release && (
          <div className={'text-t2 text-grey3 flex items-center justify-center h-5'}>
            <LoadingIcon size="middle" />
          </div>
        )}
      </>
      {/* {!release && <div style={{ height: loadCount * 30 }} />} */}
    </>
  );
};

export const runRequestAnimationFrameTask = (task: () => void) => {
  void sleep(30).finally(() => {
    task();
  });
};

export const useLazyList = <T extends {}>(props: Props<T>) => {
  const {
    items = [],
    count = items.length,
    chunkSize = 5,
    maxSize = 100,
    renderItem,
    onProgress = () => {},
  } = props;
  const [release, setRelease] = useState(maxSize >= count);
  const [loadCount, setLoadCount, loadCountRef] = useStateRef(release ? count : chunkSize);
  const init = useRef(false);

  useEffect(() => {
    const clear = () => {
      runRequestAnimationFrameTask(() => {
        setRelease(true);
        onProgress('end');
      });
    };

    if (release) return;

    if (init.current) {
      clear();
      return;
    }

    init.current = true;

    if (count > maxSize) {
      void Promise.resolve().then(() => {
        onProgress('start');
        const loop = () => {
          runRequestAnimationFrameTask(() => {
            setLoadCount((pre) => pre + chunkSize);
            if (loadCountRef.current + chunkSize >= count) {
              clear();
            } else {
              loop();
            }
          });
        };

        if (count > loadCountRef.current) {
          loop();
          return;
        }
        clear();
      });
    } else {
      clear();
    }

    return clear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, count, release]);

  return {
    loadCount,
    renderItem,
    release,
    items,
  };
};
