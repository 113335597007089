import type { Immutable } from 'immer';
import type { MindMapEngine } from '../mind-map-engine';
import type { CanvasStyle, Line, MindNode, MindNodeDragInfo, Rect, Where } from '../types';
import { getMindMapNextNode, getMindMapPreNode, intersectRect, walk } from '../util';
import type { LayoutProviderOption } from './base-layout-provider';
import { BaseLayoutProvider } from './base-layout-provider';
import { CANVAS_PADDING } from './canvas-graph';

/**标题在左边居中位置的布局类 */
/**
 * 概念：叶子节点是指没有子节点的节点（收缩状态也算叶子节点）
 * 核心规则：每个节点都是其子节点占据的区域高度/2
 *
 * 计算步骤：
 * 1.先定位中心点
 * 2.因为每个节点都是区域高度的中心位置，因此第一个子节点的top就是往上偏移区域高度的一半
 * 3.除第一个子节点，兄弟节点都是依赖于前一个节点进行定位
 * 4.最后调整位置
 */
export class MindMapLayoutProvider extends BaseLayoutProvider {
  constructor(engine: MindMapEngine, opt: LayoutProviderOption) {
    super(engine, opt);
  }

  layout = (nodeId: string) => {
    try {
      this.computedDir();
      this.computeNodeRegionValue(nodeId);
      this.updateViewPort();
      this.computedBaseValue();
      this.computedTopValue();
      this.adjustTopValue();
      this.fixRegionHeight();
      this.computeOffset();
      this.computeFixWidth();
      this.computeNodesByLevelOrder();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  };
  computedDir = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id, parentId, isRoot, _, index) => {
        const node = state.mindNodes[id];
        const parentNode = state.mindNodes[parentId];
        if (!node) return true;
        if (isRoot) return;
        if (parentNode) {
          // 非根节点
          // 三级及以下节点以上级为准
          if (parentNode.dir) {
            node.dir = parentNode.dir;
          } else {
            // 节点生长方向
            node.dir = index < parentNode.childrenIds.length / 2 ? 'right' : 'left';
          }
        }
        if (!node.expanded) {
          return true;
        }
      },
      undefined,
      true,
      0
    );
  };

  computedBaseValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id, parentId, isRoot, _) => {
        const node = state.mindNodes[id];
        const parentNode = state.mindNodes[parentId];
        if (!node) return true;
        if (isRoot) {
          node.left = node.regionWidth - node.rightRegionWidth;
          node.top = -node.height * 0.5 + node.regionHeight * 0.5;
        } else if (parentNode) {
          // 非根节点
          // 三级及以下节点以上级为准
          if (parentNode.dir) {
            node.dir = parentNode.dir;
          } else {
            //有些节点可能无法显示，这里需要过滤一下
            const validChildrenIds = parentNode.childrenIds.filter((cId) => state.mindNodes[cId]);
            const index = validChildrenIds.indexOf(id);
            // 节点生长方向
            node.dir = index < parentNode.childrenIds.length / 2 ? 'right' : 'left';
          }
          node.left =
            node.dir === 'right'
              ? parentNode.left + parentNode.width + this.opt.horizontalMargin
              : parentNode.left - node.width - this.opt.horizontalMargin;
        }
        if (!node.expanded) {
          return true;
        }
      },
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return;
        if (!node.expanded) {
          node.leftChildrenHeight = 0;
          node.rightChildrenHeight = 0;
          return;
        }
        // 理论上只有根节点是存在两个方向的子节点的，其他节点的子节点一定全都是同方向，但是为了逻辑统一，就不按特殊处理的方式来写了
        let leftLen = 0;
        let rightLen = 0;
        let leftChildrenHeight = 0;
        let rightChildrenHeight = 0;
        node.childrenIds.forEach((cId) => {
          const childNode = state.mindNodes[cId];
          if (!childNode) return;
          if (childNode.dir === 'left') {
            leftLen++;
            leftChildrenHeight += childNode.height;
          } else if (childNode.dir === 'right') {
            rightLen++;
            rightChildrenHeight += childNode.height;
          }
        });
        node.leftChildrenHeight = leftChildrenHeight + (leftLen + 1) * this.opt.verticalMargin;
        node.rightChildrenHeight = rightChildrenHeight + (rightLen + 1) * this.opt.verticalMargin;
      },
      true,
      0
    );
  };
  computedTopValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return true;
        if (!node.expanded) return true;
        if (node.childrenIds.length === 0) return;
        const baseTop = node.top + node.height / 2 + this.opt.verticalMargin;
        // 第一个子节点的top值 = 该节点中心的top值 - 子节点的高度之和的一半
        let leftTotalTop = baseTop - node.leftChildrenHeight / 2;
        let rightTotalTop = baseTop - node.rightChildrenHeight / 2;
        node.childrenIds.forEach((cId) => {
          const childNode = state.mindNodes[cId];
          if (!childNode) return;
          if (childNode.dir === 'left') {
            childNode.top = leftTotalTop;
            leftTotalTop += childNode.height + this.opt.verticalMargin;
          } else if (childNode.dir === 'right') {
            childNode.top = rightTotalTop;
            rightTotalTop += childNode.height + this.opt.verticalMargin;
          }
        });
      },
      undefined,
      true,
      0
    );
  };
  adjustTopValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (nodeId) => {
        const node = state.mindNodes[nodeId];
        if (!node) return true;
        if (!node.expanded) return true;
        // 判断子节点所占的高度之和是否大于该节点自身，大于则需要调整位置
        const base = this.opt.verticalMargin * 2 + node.height;
        const leftDifference = node.leftChildrenHeight - base;
        const rightDifference = node.rightChildrenHeight - base;
        if (leftDifference > 0 || rightDifference > 0) {
          this.updateBrothers(nodeId, leftDifference / 2, rightDifference / 2);
        }
      },
      undefined,
      true
    );
  };
  updateBrothers = (nodeId: string, leftAddHeight: number, rightAddHeight: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) return;
    const parentNode = this.engine.getMindNode(node.parentId);
    if (!parentNode) return;
    // 过滤出和自己同方向的节点
    const childrenListIds = parentNode.childrenIds.filter((cId) => {
      const childNode = state.mindNodes[cId];
      return childNode?.dir === node.dir;
    });
    const index = childrenListIds.indexOf(nodeId);

    childrenListIds.forEach((cId, _index) => {
      const childNode = state.mindNodes[cId];
      if (!childNode) return;
      let _offset = 0;
      const addHeight = childNode.dir === 'left' ? leftAddHeight : rightAddHeight;
      // 上面的节点往上移
      if (_index < index) {
        _offset = -addHeight;
      } else if (_index > index) {
        // 下面的节点往下移
        _offset = addHeight;
      }
      // 同步更新子节点的位置
      this.updateNodeRecursive(cId, 'top', _offset);
    });
    // 更新父节点的位置
    this.updateBrothers(node.parentId, leftAddHeight, rightAddHeight);
  };
  updateScrollerPos = (oldRegionWidth: number, oldRegionHeight: number) => {
    const rootNode = this.engine.getRootMindNode();
    const scrollY = (rootNode.regionHeight - oldRegionHeight) / 2;
    const scrollX = rootNode.regionWidth - oldRegionWidth;
    if (scrollY !== 0 || scrollX !== 0) {
      this.scroller?.scrollBy(scrollX, scrollY);
    }
  };

  protected getMindMapCenterPos = (): [number, number] | undefined => {
    if (!this.scroller) {
      return;
    }
    const root = this.engine.getRootMindNode();
    const { scale } = this.engine.getState();
    const scaleFactor = scale * 0.01;
    const rootCenterX = root.x + (root.width * scaleFactor) / 2;
    const rootCenterY = root.y + (root.height * scaleFactor) / 2;
    return [rootCenterX, rootCenterY];
  };

  getConnectToWhere = (dragInfo: MindNodeDragInfo) => {
    const mindMapState = this.engine.getState();
    const scaleFactor = mindMapState.scale * 0.01;
    const mindNode = mindMapState.mindNodes[dragInfo.id];
    if (!mindNode) return;
    const rootNode = mindMapState.mindNodes[mindMapState.rootId];
    if (!rootNode) return;

    const dragNode = mindMapState.mindNodes[dragInfo.id];
    if (!dragNode) return;
    const dragInfoX = dragInfo.left;
    const dragInfoY = dragInfo.top;
    //超出左侧边界
    if (
      dragInfoX + dragNode.width <
      rootNode.x +
        rootNode.width * scaleFactor -
        rootNode.leftRegionWidth * scaleFactor -
        CANVAS_PADDING * 0.5 * scaleFactor
    ) {
      return;
    }
    //超出右侧边界
    if (
      dragInfoX >
      rootNode.x + rootNode.rightRegionWidth * scaleFactor + CANVAS_PADDING * 0.5 * scaleFactor
    ) {
      return;
    }
    const rootTop =
      rootNode.y + (rootNode.height * 0.5 - rootNode.regionHeight * 0.5) * scaleFactor;
    //超出上边界
    if (dragInfoY < rootTop - CANVAS_PADDING * 0.5 * scaleFactor) {
      return;
    }
    //超出下边界
    if (dragInfoY > rootTop + (rootNode.regionHeight + CANVAS_PADDING * 0.5) * scaleFactor) {
      return;
    }
    if (dragInfoX > rootNode.x && dragInfoX < rootNode.x + rootNode.width * scaleFactor) {
      //中心点
      return;
    }
    const isInRight = dragInfoX > rootNode.x + rootNode.width * scaleFactor;
    let markNode: Immutable<MindNode> | undefined = undefined;
    const entries = Object.entries(mindMapState.mindNodes);

    let distance = Number.MAX_VALUE;
    for (const [_, node] of entries) {
      if (node.dir === 'left' && isInRight) continue;
      if (node.dir === 'right' && !isInRight) continue;
      let regionHeight = 0;
      switch (node.dir) {
        case 'left':
          regionHeight = node.leftRegionHeight;
          break;
        case 'right':
          regionHeight = node.rightRegionHeight;
          break;
        default:
          regionHeight = node.regionHeight;
      }
      //node区域高度
      const nodeRegionHeightRect: Rect = {
        x: node.dir === 'left' ? node.x + node.width : node.x,
        y: node.y + node.height * 0.5 - regionHeight * 0.5 * scaleFactor,
        width: node.width * scaleFactor,
        height: node.regionHeight * scaleFactor,
      };
      if (
        intersectRect(nodeRegionHeightRect, {
          x: dragInfoX,
          y: dragInfoY + (dragNode.height / 2) * scaleFactor,
          width: 1,
          height: 1,
        })
      ) {
        if (node.id === dragInfo.id) return { parentId: node.parentId } as Where;
        if (!node.parentId) return;
        const parentNode = mindMapState.mindNodes[node.parentId];
        if (!parentNode) return;
        return this.findPosition(dragInfo, parentNode, isInRight);
      }

      //拖拽光标在右边的时候
      if (isInRight && dragInfoX > node.x + node.width * scaleFactor) {
        const centerDragInfoY = dragInfoY + (dragNode.height / 2) * scaleFactor;
        const nodeEndX = node.x + node.width * scaleFactor;
        const nodeCenterY = node.y + (node.height / 2) * scaleFactor;
        const newDistance =
          Math.pow(centerDragInfoY - nodeCenterY, 2) + Math.pow(dragInfoX - nodeEndX, 2);
        if (newDistance < distance) {
          distance = newDistance;
          markNode = node;
        }
      } else if (!isInRight && dragInfoX + dragNode.width * scaleFactor < node.x) {
        //拖拽光标在左边的时候
        const dragEndX = dragInfoX + dragNode.width * scaleFactor;
        const centerDragInfoY = dragInfoY + (dragNode.height / 2) * scaleFactor;
        const nodeCenterY = node.y + (node.height / 2) * scaleFactor;
        const newDistance =
          Math.pow(centerDragInfoY - nodeCenterY, 2) + Math.pow(dragEndX - node.x, 2);
        if (newDistance < distance) {
          distance = newDistance;
          markNode = node;
        }
      }
    }
    if (!markNode) return;
    if (markNode.id === dragInfo.id) return;
    if (!markNode.expanded || markNode.childrenIds.length === 0) {
      return { parentId: markNode.id, first: true } as Where;
    }
    return this.findPosition(dragInfo, markNode, isInRight);
  };

  private findPosition = (
    dragInfo: MindNodeDragInfo,
    parentNode: Immutable<MindNode>,
    isInRight: boolean
  ) => {
    const mindMapState = this.engine.getState();
    const dragInfoY = dragInfo.top;
    //找具体位置,一个一个往下找
    let after: string | undefined;
    for (let i = 0; i < parentNode.childrenIds.length; i++) {
      const cId = parentNode.childrenIds[i];
      if (!cId) return;
      const childNode = mindMapState.mindNodes[cId];
      if (!childNode) return;
      if (isInRight && childNode.dir === 'left') continue;
      if (i === 0 && dragInfoY < childNode.y) {
        return { parentId: parentNode.id, first: true } as Where;
      }
      if (dragInfoY > childNode.y) {
        after = childNode.id;
      }
    }
    if (after && after !== dragInfo.id) {
      return { parentId: parentNode.id, after } as Where;
    }
  };

  updateMindNodeSize = (id: string, width: number, height: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[id];
    if (!node) {
      return;
    }
    node.width = Math.floor(width);
    node.height = Math.floor(height);
  };

  computeNodeRegionValue = (nodeId: string) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) return;

    // 理论上只有根节点是存在两个方向的子节点的，其他节点的子节点一定全都是同方向，但是为了逻辑统一，就不按特殊处理的方式来写了
    let leftLen = 0;
    let rightLen = 0;

    let leftRegionHeight = 0;
    let rightRegionHeight = 0;

    let leftMaxRegionWidth = 0;
    let rightMaxRegionWidth = 0;
    if (node.expanded) {
      node.childrenIds.forEach((cId) => {
        const childNode = state.mindNodes[cId];
        if (!childNode) return;
        this.computeNodeRegionValue(cId);
        if (childNode.dir === 'left') {
          leftLen++;
          leftRegionHeight += childNode.regionHeight;
          if (childNode.regionWidth > leftMaxRegionWidth) {
            leftMaxRegionWidth = childNode.regionWidth;
          }
        } else if (childNode.dir === 'right') {
          rightLen++;
          rightRegionHeight += childNode.regionHeight;
          if (childNode.regionWidth > rightMaxRegionWidth) {
            rightMaxRegionWidth = childNode.regionWidth;
          }
        }
      });
      leftRegionHeight += (leftLen - 1) * this.opt.verticalMargin;
      rightRegionHeight += (rightLen - 1) * this.opt.verticalMargin;
    }
    node.regionHeight = Math.max(node.height, Math.max(leftRegionHeight, rightRegionHeight));
    node.leftRegionHeight = Math.max(node.height, leftRegionHeight);
    node.rightRegionHeight = Math.max(node.height, rightRegionHeight);
    if (node.expanded && node.childrenIds.length > 0) {
      const horizontalMargin = node.parentId
        ? this.opt.horizontalMargin
        : this.opt.horizontalMargin * 2; //根结点左右两边都有
      node.regionWidth = node.width + horizontalMargin + leftMaxRegionWidth + rightMaxRegionWidth;
      node.leftRegionWidth = node.width + this.opt.horizontalMargin + leftMaxRegionWidth;
      node.rightRegionWidth = node.width + this.opt.horizontalMargin + rightMaxRegionWidth;
    } else {
      node.regionWidth = node.width;
    }
  };

  protected drawLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.strokeStyle = line.color;
    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    if (style) {
      Object.assign(ctx, style);
    }
    ctx.moveTo(line.startX, line.startY);
    const lineCenterX = line.startX + (line.endX - line.startX) / 2;
    ctx.lineTo(lineCenterX, line.startY);
    //是否往上
    const isTop = line.endY < line.startY;
    const { round } = this;
    if (Math.abs(line.endY - line.startY) > round) {
      ctx.lineTo(lineCenterX, isTop ? line.endY + round : line.endY - round);
      ctx.arcTo(
        lineCenterX,
        line.endY,
        lineCenterX + (line.dir === 'left' ? -round : round),
        line.endY,
        round
      );
      ctx.lineTo(line.endX, line.endY);
    } else {
      //两者中心点不够一个弧度高，因此用直线连上
      ctx.lineTo(line.endX, line.startY);
    }
  };

  protected getLine = (node: Immutable<MindNode>, childNode: Immutable<MindNode>) => {
    const color = this.getLineColor(childNode.id);
    let line: Line;
    if (childNode.dir === 'left') {
      line = {
        startX: node.left / this.scale,
        startY: (node.top + node.height / 2) / this.scale,
        endX: (childNode.left + childNode.width) / this.scale,
        endY: (childNode.top + childNode.height / 2) / this.scale,
        debug: `${node.id} - ${childNode.id}`,
        dir: 'left',
        isRoot: !node.parentId,
        color,
      };
    } else {
      line = {
        startX: (node.left + node.width) / this.scale,
        startY: (node.top + node.height / 2) / this.scale,
        endX: childNode.left / this.scale,
        endY: (childNode.top + childNode.height / 2) / this.scale,
        debug: `${node.id} - ${childNode.id}`,
        dir: 'right',
        isRoot: !node.parentId,
        color,
      };
    }
    return line;
  };

  protected getDragLine = (
    dragXInCanvas: number,
    dragYInCanvas: number,
    nodeId: string,
    parentId: string
  ) => {
    const node = this.engine.getMindNode(nodeId);
    if (!node) return;
    const parentNode = this.engine.getMindNode(parentId);
    if (!parentNode) return;
    const color = this.getLineColor(nodeId);

    let line: Line | undefined;
    const getLine = (dir: 'left' | 'right'): Line => {
      if (dir === 'left') {
        return {
          debug: '',
          startX: parentNode.left / this.scale,
          startY: (parentNode.top + parentNode.height / 2) / this.scale,
          endX: (dragXInCanvas + node.width) / this.scale,
          endY: dragYInCanvas + node.height / 2 / this.scale,
          dir: 'left',
          color,
        };
      }
      return {
        debug: '',
        startX: (parentNode.left + parentNode.width) / this.scale,
        startY: (parentNode.top + parentNode.height / 2) / this.scale,
        endX: dragXInCanvas / this.scale,
        endY: dragYInCanvas + node.height / 2 / this.scale,
        dir: 'right',
        color,
      };
    };
    if (parentNode.dir === 'right') {
      line = getLine('right');
    } else if (parentNode.dir === 'left') {
      line = getLine('left');
    } else if (parentNode.dir === undefined) {
      //parentNode是根结点
      const rootMindNode = this.engine.getRootMindNode();
      if (!this.engine.dragInfo) {
        return;
      }
      const dragX = this.engine.dragInfo.left;
      const dragNode = this.engine.getMindNode(this.engine.dragInfo.id);
      if (!dragNode) return;
      if (dragX > rootMindNode.x) {
        line = getLine('right');
      } else if (dragX < rootMindNode.x) {
        line = getLine('left');
      }
    }
    return line;
  };
  getDragNodeRect = (id: string) => {
    const node = this.engine.getMindNode(id ?? '');
    if (!node) return;
    if (node.dir === 'right' || node.dir === undefined) {
      const x = node.left;
      const y = node.top + node.height * 0.5 - node.regionHeight * 0.5;
      return { x, y, width: node.regionWidth, height: node.regionHeight };
    }
    const x = node.left + node.width - node.regionWidth;
    const y = node.top + node.height * 0.5 - node.regionHeight * 0.5;
    return { x, y, width: node.regionWidth, height: node.regionHeight };
  };

  getNextNodeIdByArrowKeyboard = (arrow: 'left' | 'right' | 'up' | 'down', currentId: string) => {
    const node = this.engine.getMindNode(currentId);
    if (!node) return;
    switch (arrow) {
      case 'left': {
        //root
        if (node.dir === undefined && node.childrenIds.length > 1) {
          const leftSideMiddleIndex = Math.round(node.childrenIds.length / 2 / 2);
          const leftSideMiddleId = node.childrenIds[node.childrenIds.length - leftSideMiddleIndex];
          return leftSideMiddleId;
        }
        return node.dir === 'right' ? this.getParentId(currentId) : this.getMiddleChild(currentId);
      }
      case 'right': {
        //root
        if (node.dir === undefined && node.childrenIds.length > 1) {
          const rightSideMiddleIndex = Math.floor(node.childrenIds.length / 2 / 2);
          const rightSideMiddleId = node.childrenIds[rightSideMiddleIndex];
          return rightSideMiddleId;
        }
        return node.dir === 'right' ? this.getMiddleChild(currentId) : this.getParentId(currentId);
      }
      case 'up': {
        const preNodeId = getMindMapPreNode(this.engine, currentId);
        if (!preNodeId) return;
        const preNode = this.engine.getMindNode(preNodeId);
        if (preNode?.dir === node.dir) return preNodeId;
        break;
      }
      case 'down': {
        const nextNodeId = getMindMapNextNode(this.engine, currentId);
        if (!nextNodeId) return;
        const nextNode = this.engine.getMindNode(nextNodeId);
        if (nextNode?.dir === node.dir) return nextNodeId;
        break;
      }
      default:
    }
  };
}
