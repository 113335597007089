import { BlockType } from '@next-space/fe-api-idl';
import React from 'react';
import { usePickBlock } from 'src/utils/pick-block';

import type { MindNodeElement } from './all-node-renderer';
import { ImageNode } from './image-node';
import { PageNode } from './page-node';

export const ReferenceNode: MindNodeElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['data'], ['ref', 'display']);

  if (!block) return null;
  let Element: MindNodeElement = () => <></>;
  switch (block.data.display) {
    case 'image': {
      Element = ImageNode;
      break;
    }
    case 'file': {
      Element = PageNode;
      break;
    }
    case 'linkPage': {
      Element = PageNode;
      break;
    }
    default:
  }
  if (
    block.type === BlockType.REFERENCE_COLLECTION ||
    block.type === BlockType.REFERENCE_COLLECTION_PAGE
  ) {
    Element = PageNode;
  }
  return (
    <Element id={block.data.ref?.uuid ?? ''} level={props.level} ownerBlockId={props.id}></Element>
  );
});
