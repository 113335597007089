import { AnnotationMode, PixelsPerInch } from 'pdfjs-dist/build/pdf';
import { getXfaHtmlForPrinting } from 'pdfjs-dist/lib/web/print_utils';

export class PrintService {
  private pdfDocument: any;
  private pagesOverview: any;
  private printContainer = document.createElement('div');
  private canvas = document.createElement('canvas');
  private cancelPrint = false;
  onProgress?: (pageNumber: number, total: number) => void;

  constructor(pdfDocument: any, pagesOverview: any) {
    this.pdfDocument = pdfDocument;
    this.pagesOverview = pagesOverview;

    this.printContainer.id = 'printContainer';
    document.body.appendChild(this.printContainer);
  }

  async print() {
    await this.renderAllPages();
    if (!this.cancelPrint) {
      window.print();
    }
  }

  private async renderAllPages() {
    const body = document.querySelector('body');
    if (body) {
      body.setAttribute('data-pdfjs-printing', 'true');
    }

    if (this.pdfDocument.isPureXfa) {
      getXfaHtmlForPrinting(this.printContainer, this.pdfDocument);
      return Promise.resolve();
    }
    const pageCount = this.pagesOverview.length;

    for (let pageNum = 1; pageNum <= pageCount; pageNum++) {
      if (this.cancelPrint) return;

      this.onProgress?.(pageNum, pageCount);

      await this.renderPage(pageNum);
      await this.convertPageToImage();
    }
  }

  private async renderPage(pageNumber: number) {
    const PRINT_UNITS = 150 / PixelsPerInch.PDF;
    const size = this.pagesOverview[pageNumber - 1];
    this.canvas.width = Math.floor(size.width * PRINT_UNITS);
    this.canvas.height = Math.floor(size.height * PRINT_UNITS);

    const ctx = this.canvas.getContext('2d');
    if (!ctx) return;
    ctx.save();
    ctx.fillStyle = 'rgb(255, 255, 255)';
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.restore();

    const pdfPage = await this.pdfDocument.getPage(pageNumber);
    const renderContext = {
      canvasContext: ctx,
      transform: [PRINT_UNITS, 0, 0, PRINT_UNITS, 0, 0],
      viewport: pdfPage.getViewport({ scale: 1, rotation: size.rotation }),
      intent: 'print',
      annotationMode: AnnotationMode.ENABLE_STORAGE,
      optionalContentConfigPromise: this.pdfDocument.getOptionalContentConfig(),
    };

    return pdfPage.render(renderContext).promise;
  }

  private convertPageToImage() {
    const img = document.createElement('img');
    if ('toBlob' in this.canvas) {
      this.canvas.toBlob((blob: any) => {
        img.src = URL.createObjectURL(blob);
      });
    } else {
      img.src = this.canvas.toDataURL();
    }

    const wrapper = document.createElement('div');
    wrapper.className = 'printedPage';
    wrapper.appendChild(img);
    this.printContainer?.appendChild(wrapper);

    return new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;
    });
  }

  abort() {
    this.cancelPrint = true;
    this.destroy();
  }

  destroy() {
    this.printContainer?.remove();

    const body = document.querySelector('body');
    if (body) {
      body.removeAttribute('data-pdfjs-printing');
    }
  }
}
