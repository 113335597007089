import { Compare } from '.';

/** 根据对象属性排序 */
interface AttributeSortParams<T> {
  key: keyof T;
  items: T[];
  reverse?: boolean;
  isNumber?: boolean;
}
export const attributeSort = <T>(params: AttributeSortParams<T>) => {
  const { key, items, reverse, isNumber } = params;
  const judgeNumber = (n: string) => /^[0-9]*$/.test(n);

  items.sort((a, b) => {
    const itemA = `${a[key]}`;
    const itemB = `${b[key]}`;

    if (isNumber || (judgeNumber(itemA) && judgeNumber(itemB))) {
      return Number(itemA) - Number(itemB);
    }
    return Compare.pinyin(itemA, itemB);
  });

  return reverse ? items.reverse() : items;
};
