import { debounce } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { useTransaction } from '../use-transaction';

export const useUpdateTableWidth = (tableId: string, propertyId: string) => {
  const transaction = useTransaction();

  const update = useCallback(
    (width: number, ignoreOp: boolean) => {
      const { blocks } = getState();
      const table = blocks[tableId];
      if (!table) return;

      const format = table.data.format ?? {};
      const columnFormat = format.tableBlockColumnFormat ?? {};
      const data = {
        format: {
          tableBlockColumnFormat: {
            ...columnFormat,
            [propertyId]: { ...columnFormat[propertyId], width },
          },
        },
      };

      if (ignoreOp) {
        updateBlock(tableId, { data }, true);
      } else {
        transaction(() => updateBlock(tableId, { data }));
      }
    },
    [propertyId, tableId, transaction]
  );

  const sync = useMemo(() => {
    return debounce((width) => {
      update(width, false);
    }, 300);
  }, [update]);

  return useCallback(
    (width: number) => {
      update(width, true);
      sync(width);
    },
    [sync, update]
  );
};
