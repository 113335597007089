import { useCallback } from 'react';
import { getFileNameInfo, getImageSizeInfo, getVideoSizeInfo } from 'src/utils/file';
import { FileRegex } from '@flowus/common/regex';

interface MediaInfo {
  width: number;
  height: number;
  size: number;
  url: string;
}

export const useGetMediaInfo = () => {
  return useCallback(async (file: File): Promise<MediaInfo | {}> => {
    return getMediaInfo(file);
  }, []);
};

export const getMediaInfo = async (file: File): Promise<MediaInfo | {}> => {
  try {
    const { extName } = getFileNameInfo(file.name);
    if (FileRegex.image.test(extName)) {
      // 获取图片的宽高
      const mediaInfo = await getImageSizeInfo(file);
      return mediaInfo;
    } else if (FileRegex.video.test(extName)) {
      // 获取视频的宽高
      const mediaInfo = await getVideoSizeInfo(file);
      return mediaInfo;
    }
  } catch {
    return {};
  }
  return {};
};
