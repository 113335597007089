export enum UpgradeSpacePlanType {
  // 达到空间容量上限
  'maxCapacity' = 'maxCapacity',
  // 暂时作废，免费用户直接购买容量包
  'payCapacity' = 'payCapacity',
  // 外部协作者上限
  'maxGuestCount' = 'maxGuestCount',
  // 单文件大小达到上限
  'maxFileSize' = 'maxFileSize',
  // 订阅页面的问问 AI
  'subscribeAiLimit' = 'subscribeAiLimit',
  // 拷贝次数达到上限
  'maxCopyLimit' = 'maxCopyLimit',
}
