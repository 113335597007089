import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { getFileNameInfo, getStringSplitKeepTail } from 'src/utils/file';

interface Props {
  fileName?: string;
  className?: string;
  addonAfter?: ReactNode;
}

/** FileName自动中间省略，并保留尾部extName，如需在文件名后拼接其他元素，传入children即可 */
export const FileNameUiKit: FC<Props> = ({ fileName, className, addonAfter }) => {
  const { name, extName } = getFileNameInfo(fileName);
  const [first, last] = getStringSplitKeepTail(name);
  return (
    <div className={cx('flex w-full whitespace-nowrap', className)}>
      {first ? (
        <>
          <span className="text-ellipsis sm:min-w-[2em]">{first}</span>
          <span>{last}</span>
          <span>{`${name ? '.' : ''}${extName}`}</span>
        </>
      ) : (
        <span className="text-ellipsis sm:min-w-[2em]">{fileName}</span>
      )}
      {addonAfter}
    </div>
  );
};
