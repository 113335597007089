import type { UserDTO } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { isBuildIn } from '../build-in';
import { Button } from '../components';
import { commonPublicLink } from '../const';
import { GlobalImages } from '../images';
import { VITE_PRODUCT_NAME } from './extension';
import { previewWxImage } from './wx';

interface SubscribeParams {
  targetId: string;
  subscribed: boolean;
  addSubscribe: (params: any) => Promise<any>;
  cancelSubscribe: (params: any) => Promise<any>;
  user?: UserDTO;
  openDialog: (info: {
    title: ReactNode;
    content: ReactNode;
    colorType?: any;
    minWidth?: number;
    showCancel?: boolean;
    showConfirm?: boolean;
    noCancel?: boolean;
    closeAfterCallBack?: () => void;
  }) => Promise<void> | { modalId: string };
  callback: (status: boolean) => void;
}
/**
 * not my code
 */
export const addSubscribe = async (
  subscribeParams: SubscribeParams,
  trackParams: {
    pageId: string;
    needPay: boolean;
    userId?: string;
    spaceId?: string;
    createdBy?: string;
    event: (name: string, params: any) => void;
  }
) => {
  const {
    targetId,
    subscribed,
    cancelSubscribe,
    addSubscribe: _addSubscribe,
    user,
    openDialog,
    callback,
  } = subscribeParams;
  const { pageId, needPay, userId, spaceId, createdBy, event } = trackParams;

  const eventValue = {
    user_id: userId,
    page_id: pageId,
    space_id: spaceId,
    created_user_id: createdBy,
    subscribe_type: needPay ? 'paid_subscribe' : 'free_subscribe',
  };

  event(subscribed ? 'subscribe' : 'unsubscribe', eventValue);
  if (subscribed) {
    await _addSubscribe(targetId);
    await openSubscribeWechatAccount(user, openDialog, () => callback(subscribed));
    if (isMobile) {
      callback(subscribed);
    }
  } else {
    await cancelSubscribe(targetId);
  }
};

export const openSubscribeWechatAccount = async (
  user: SubscribeParams['user'],
  openDialog: SubscribeParams['openDialog'],
  callback?: (status: boolean) => void
) => {
  // if (user?.isFollowWechat && user?.ext?.wechat) return;
  // if (user?.isFollowWechat) return;
  if (isBuildIn()) return;

  // const show = {
  //   follow: !user?.isFollowWechat,
  //   // wechat: !user?.ext?.wechat,
  // };

  await openDialog({
    colorType: 'active',
    minWidth: 350,
    showCancel: false,
    showConfirm: false,
    noCancel: true,
    closeAfterCallBack: () => {
      callback?.(true);
    },
    title: `关注 ${VITE_PRODUCT_NAME} 公众号，接收订阅推送消息`,
    content: (
      <div className="text-grey3 text-t3 text-center space-y-2.5 flex items-center justify-center flex-col pt-2">
        {/* {show.wechat && (
          <div className="space-y-4">
            <img
              onClick={() => {
                previewWxImage(GlobalImages.weAppQr);
              }}
              src={GlobalImages.weAppQr}
              className="w-32 rounded mx-auto"
              alt=""
            />
            <p>前往 {VITE_PRODUCT_NAME} 小程序，绑定微信</p>
          </div>
        )} */}
        <img
          onClick={() => {
            previewWxImage(GlobalImages.qr);
          }}
          src={GlobalImages.qr}
          className="w-32 rounded mx-auto"
        />

        <Button
          colorType="active"
          onClick={() => {
            window.open(commonPublicLink.subscribeCenter);
          }}
        >
          更多精选专栏
        </Button>
        <a
          className="text-active_color"
          href={commonPublicLink.mySubscribePage}
          target="_blank"
          rel="noreferrer"
        >
          我的订阅
        </a>
      </div>
    ),
  });
};
