import { Checkbox } from 'src/components/check-box';
import { Deferred } from '@flowus/common/async';
import { Button } from '@flowus/common/components/button';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { useUpdateUser } from 'src/hooks/user';

const WpsAuthId = v4();
interface Props {
  resolve: () => void;
  reject: () => void;
}
const WpsAuthDialog: FC<Props> = (props) => {
  const { resolve, reject } = props;
  const [check, setCheck] = useState(false);

  return (
    <div className="pt-10 pb-5 px-7 text-t3 space-y-2.5">
      <div className="text-t1-medium">检测到你已经登录FlowUs，可直接授权</div>
      <div>允许“WPS文档”应用进行以下操作</div>
      <div className="flex items-center cursor-not-allowed">
        <Checkbox checked />
        <span>访问你的个人资料等基础信息</span>
      </div>
      <div className="flex items-center animate-click" onClick={() => setCheck(!check)}>
        <Checkbox checked={check} />
        <span>在你的FlowUs空间读写文件数据</span>
      </div>
      <div className="pt-2.5 space-y-2.5">
        <Button disable={!check} size="auto" colorType="active" onClick={resolve}>
          授权
        </Button>
        <Button size="auto" onClick={reject}>
          取消
        </Button>
      </div>
    </div>
  );
};

export const useOpenWpsAuthDialog = () => {
  const openModal = useOpenModal();
  const updateUser = useUpdateUser();

  return useCallback(async () => {
    const defer = new Deferred();

    openModal.modal({
      modalId: WpsAuthId,
      content: () => (
        <WpsAuthDialog
          resolve={() => {
            void updateUser({ setting: { wpsAuth: true } });
            defer.resolve();
            openModal.closeModal(WpsAuthId);
          }}
          reject={() => {
            defer.reject(new Error());
            openModal.closeModal(WpsAuthId);
          }}
        />
      ),
    });

    await defer.promise;
  }, [openModal, updateUser]);
};
