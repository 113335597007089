import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { IExtensionApi, IText, ITextComponent } from '@next-space/fe-inlined';
import { decorations, DEFAULT_FORMAT } from '@next-space/fe-inlined';
import { findEditorElement } from '@next-space/fe-inlined/dom-utils';
import { css } from 'otion';
import { Fragment } from 'react';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import type { InlinePlugin } from './inline-plugin';

/**
 * 内置的富文本的插件
 */
export const BuildInPlugin: InlinePlugin = {
  elementMeta: { tag: 'buildin' },
  initialize(api: IExtensionApi) {
    api.setInputHook((model, content, type) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const editorElm = findEditorElement(model.editorKey)!;
      const event = new CustomEvent('inlinedinput', {
        bubbles: true,
        cancelable: true,
        detail: {
          content,
          type,
        },
      });
      editorElm.dispatchEvent(event);
      if (event.defaultPrevented) {
        return false;
      }
    });

    api.overrideTextComponent((Text) => {
      const MyText: ITextComponent = ({ format, className, ...props }) => {
        useIsDarkMode();
        const backgroundColor =
          format.backgroundColor !== null
            ? getBlockBackgroundColor(format.backgroundColor)
            : undefined;
        return (
          <Text
            {...props}
            format={{
              ...format,
              bold: false,
              color: null,
              backgroundColor: null,
              underline: false,
            }}
            className={cx(
              className,
              format.bold && 'text-bold',
              css({
                fontWeight: format.bold ? 600 : undefined,
                borderBottom: format.underline ? '1px solid' : undefined,
                color: format.color !== null ? getBlockTextColor(format.color) : undefined,
                background: backgroundColor,
                borderRadius: backgroundColor ? '2px' : undefined,
              })
            )}
          />
        );
      };
      return MyText;
    });
    api.overrideTextComponent((Text) => {
      const MyText: ITextComponent = ({
        content,
        format,
        length,
        baseOffset,
        markers = [],
        className,
      }) => {
        const text: IText = { type: 'text', content, format, length };
        const items = decorations(text, baseOffset, markers);
        return (
          <Fragment>
            {items.map(([text, markers], index) => {
              return (
                <Text
                  key={index}
                  content={text.content}
                  length={text.length}
                  format={text.format}
                  baseOffset={baseOffset}
                  className={cx(
                    className,
                    markers.map((it) => {
                      return (it.data as { className: string }).className;
                    })
                  )}
                />
              );
            })}
          </Fragment>
        );
      };
      return MyText;
    });
  },
};
/**
 * 禁掉内置的富文本效果，给page title使用
 */
export const NoBuildInPlugin: InlinePlugin = {
  elementMeta: { tag: 'buildin' },
  initialize(api: IExtensionApi) {
    api.setInputHook((model, content, type) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const editorElm = findEditorElement(model.editorKey)!;
      const event = new CustomEvent('inlinedinput', {
        bubbles: true,
        cancelable: true,
        detail: {
          content,
          type,
        },
      });
      editorElm.dispatchEvent(event);
      if (event.defaultPrevented) {
        return false;
      }
    });
    api.overrideTextComponent((Text) => {
      const MyText: ITextComponent = ({ content, length, baseOffset, className }) => {
        return (
          <Text
            content={content}
            length={length}
            format={DEFAULT_FORMAT}
            baseOffset={baseOffset}
            className={className}
          />
        );
      };
      return MyText;
    });
  },
};
