import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import type { AutoHeightTextAreaProps } from 'src/common/components/auto-height-text-area';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';

export const TextArea: FC<AutoHeightTextAreaProps> = (props) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    const textArea = ref.current;
    if (!textArea) return;
    textArea.setSelectionRange(textArea.value.length, textArea.value.length);
  }, []);

  return <AutoHeightTextArea fontClassName="whitespace-pre-wrap" ref={ref} {...props} />;
};
