import { IpcMessage } from '@flowus/shared';
import { useEffect, useState } from 'react';
import { LOCAL_KEY_OLD_VERSION } from 'src/common/const';
import { request } from 'src/common/request';
import { ipcInvoke } from 'src/utils/electron-util';

interface VersionDataType {
  pageId?: string;
  version?: string;
  open?: boolean;
  forceClearLocalAndReloadIds?: string[];
}

export const useAppVersion = () => {
  const [appVersion, setAppVersion] = useState<string>();

  useEffect(() => {
    void ipcInvoke(IpcMessage.GetAppVersion).then((v) => setAppVersion(v));
  }, []);

  return appVersion;
};

export const updateDesktopVersion = async () => {
  const lastLocalVersion = getLocalVersion();

  const versionRes = await getOnlineVersion();
  const onlineVersion: string = versionRes?.version ?? '';

  if (!onlineVersion) {
    return;
  }

  if (!lastLocalVersion.version) {
    void updateLocalVersion(onlineVersion, { open: false });
    return;
  }

  const lastLocal = getLargeVersion(lastLocalVersion.version);
  const online = getLargeVersion(onlineVersion);

  if (online === lastLocal) return;

  // 当前新版本大于本地旧版本弹窗
  void updateLocalVersion(onlineVersion, { open: lastLocal < online });
};

/** 更新本地version和服务器记录version */
export const updateLocalVersion = async (version: string, params: VersionDataType) => {
  const _version = version.replace(/\d+$/, '0');
  const res = await request.infra.getUserWebVersion.raw(_version);
  if (res.code === 200) {
    // 如果线上服务器缓存的上次访问版本，已经是新版。就拒绝弹窗。防止多个浏览器弹多次
    if (getLargeVersion(res.data.version) >= getLargeVersion(_version)) {
      params.open = false;
    }

    // 如果线上记录是更新的一个版本，那这次可能会覆盖线上。避免覆盖需要再请求一次
    if (getLargeVersion(res.data.version) > getLargeVersion(_version)) {
      void request.infra.getUserWebVersion.raw(res.data.version);
    }

    const newDate = {
      pageId: res.data.pageId,
      version,
      ...params,
    };

    return setLocalVersionDataValue(newDate);
  }

  // 更新失败
  return setLocalVersionDataValue({});
};

export const getLocalVersion = () => {
  const localData: VersionDataType = JSON.parse(
    localStorage.getItem(LOCAL_KEY_OLD_VERSION) || '{}'
  );
  return localData;
};

export const setLocalVersionDataValue = (params: VersionDataType) => {
  const localData = getLocalVersion();
  const newDate = {
    ...localData,
    ...params,
  };
  localStorage.setItem(LOCAL_KEY_OLD_VERSION, JSON.stringify(newDate));
  return newDate;
};

export const getLargeVersion = (version?: string) => {
  const arr = (version?.split('-')[0]?.split('.') ?? []).slice(0, 3);
  return Number(arr.join(''));
};

export const getOnlineVersion = async () => {
  if (__BUILD_IN__) return;
  // VITE_PREFIX
  const versionRes = await fetch('/', {
    method: 'GET',
  });
  const html = await versionRes.text();

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  // 查找指定的 meta 标签
  const metaTag = doc.querySelector('meta[name="updated_at"]');

  let version = '';

  if (metaTag) {
    const content = metaTag.getAttribute('content') || '';
    version = content;
  }

  return { version };
};

// export const getWindowsYml = async () => {
//   const versionRes = await fetch(DESKTOP_OSS_WINDOWS_YML, {
//     method: 'GET',
//   });
//   const res = await versionRes.text();
//   const arr = res.match(/(?<=version: )((?:\d\.?)+)/);

//   if (arr?.[1]) {
//     const [, version] = arr;
//     return version;
//   }
// };
