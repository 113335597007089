import { map } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { UploadStatus } from 'src/redux/types';
import { useUploadInfo } from 'src/services/upload';
import { $downloadManager } from 'src/services/download-manager';
import type { TransferTask } from './types';
import { TransferStatus } from './types';

/** 不同状态展示不同icon */
export const useTransferStatus = () => {
  const { succeeded, failed, transferring } = useAllTransferTask();
  if (transferring > 0) return TransferStatus.TRANSFERRING;
  if (failed > 0) return TransferStatus.SOME_FAIL;
  if (succeeded > 0) return TransferStatus.ALL_SUCCESS;
  return TransferStatus.NONE;
};

/** 获取所有传输任务 */
export const useAllTransferTask = () => {
  const {
    succeeded: uploadSucceeded,
    failed: uploadFailed,
    transferring: uploadTransferring,
    tasks: uploadTasks,
  } = useUploadTask();
  const {
    succeeded: downloadSucceeded,
    failed: downloadFailed,
    transferring: downloadTransferring,
    tasks: downloadTasks,
  } = useDownloadTask();
  const {
    succeeded: importSucceeded,
    failed: importFailed,
    transferring: importTransferring,
    tasks: importToTasks,
  } = useImportTask();
  const {
    succeeded: exportSucceeded,
    failed: exportFailed,
    transferring: exportTransferring,
    tasks: exportTasks,
  } = useExportTask();

  const allTask: TransferTask[] = [
    ...uploadTasks,
    ...downloadTasks,
    ...importToTasks,
    ...exportTasks,
  ].sort((a, b) => {
    if (!a.createdAt || !b.createdAt) {
      // console.error('some task has no createAt field!!');
      return 0;
    }
    return b.createdAt - a.createdAt;
  });
  return {
    succeeded: uploadSucceeded + downloadSucceeded + importSucceeded + exportSucceeded,
    transferring:
      uploadTransferring + downloadTransferring + importTransferring + exportTransferring,
    failed: uploadFailed + downloadFailed + importFailed + exportFailed,
    tasks: allTask,
  };
};

const useUploadTask = () => {
  const uploadInfo = useUploadInfo();
  const uploadInfoKeys = Object.keys(uploadInfo);
  let succeeded = 0;
  let transferring = 0;
  let failed = 0;
  const uploadTasks: TransferTask[] = [];
  uploadInfoKeys.forEach((k) => {
    const info = uploadInfo[k];
    if (!info) return;
    if (!info.createdAt) return;
    if (!info.inTransferList) return;
    switch (info.status) {
      case UploadStatus.failure:
        failed++;
        break;
      case UploadStatus.uploading:
        transferring++;
        break;
      case UploadStatus.success:
        succeeded++;
        break;
      default:
    }
    uploadTasks.push({ id: info.key, createdAt: info.createdAt, taskType: 'upload' });
  });
  return { transferring, succeeded, failed, tasks: uploadTasks };
};

const useDownloadTask = () => {
  const items = useObservable(
    () => $downloadManager.onChange.pipe(map(() => $downloadManager.tasks)),
    $downloadManager.tasks
  );
  const tasks = items.map((it): TransferTask => {
    return {
      id: it.id,
      taskType: 'download',
      createdAt: it.startTime * 1000,
    };
  });
  return {
    succeeded: items.filter((it) => it.state === 'completed').length,
    transferring: items.filter((it) => it.state === 'progressing').length,
    failed: items.filter((it) => it.state === 'interrupted').length,
    tasks,
  };
};

const useImportTask = () => {
  // TODO
  return { transferring: 0, succeeded: 0, failed: 0, tasks: [] };
};

const useExportTask = () => {
  return { transferring: 0, succeeded: 0, failed: 0, tasks: [] };
  // TODO
};
