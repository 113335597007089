import { Images } from '@flowus/login/images';
import { ScoreRuleUrl } from '@flowus/login/utils';
import type { FC } from 'react';
import { publicLink } from 'src/common/const';

interface Props {
  isJoinTeam?: boolean;
  showScoreRule?: boolean;
}
export const LoginFooter: FC<Props> = (props) => {
  if (__PRIVATE__) return null;
  return (
    <div className="my-3 tracking-wide text-center text-t4 h-md:text-t2 text-black">
      {props.showScoreRule && !__BUILD_IN__ ? (
        <div className="flex items-center justify-center w-full">
          <img src={Images.IcInviteInfo} className="w-5 h-5 mr-1" />
          <a className="underline" target="_blank" href={ScoreRuleUrl} rel="noreferrer">
            了解积分规则
          </a>
        </div>
      ) : (
        <>
          点击{props.isJoinTeam ? '加入团队' : '继续'}&nbsp;<span>代表你同意</span>&nbsp;
          <a className="underline" target="_blank" href={publicLink.userAgreement} rel="noreferrer">
            用户协议
          </a>
          &nbsp;<span>和</span>&nbsp;
          <a className="underline" target="_blank" href={publicLink.privacyPolicy} rel="noreferrer">
            隐私政策
          </a>
        </>
      )}
    </div>
  );
};
