import type { Placement } from '@popperjs/core';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { ImageInfo } from 'src/common/components/gallery';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { releaseLocalUrl } from 'src/common/utils/release-local-url';
import { useUpload } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import { MessageIds } from 'src/modals';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { IconPanel } from './icon-panel';

export const useOpenEmoji = (
  blockId: string,
  opts: {
    modifiers?: any[];
    offset?: number[];
    placement?: Placement;
    popcorn?: ModalSchema.PopcornType | null;
    hideRemoveBtn?: boolean;
    onSelectEmoji?: (emojiStr: string) => void; // 给着重块用的
  } = {}
) => {
  const optsRef = useRef(opts);
  optsRef.current = opts;
  const transaction = useTransaction();
  const openModal = useOpenModal();
  const upload = useUpload();
  const linkUrl = useObservableBlock(
    blockId,
    (block) => {
      if (block?.data.icon?.type === 'http') {
        return block.data.icon.value;
      }
    },
    [],
    { wait: 200 }
  );

  useEffect(() => {
    return () => {
      releaseLocalUrl(getState().blocks[blockId]);
    };
  });

  return useCallback(
    (event: MouseEvent) => {
      const opts = optsRef.current;
      event.preventDefault();
      event.stopPropagation();
      const popcorn = opts.popcorn || event.currentTarget;
      const rect = popcorn.getBoundingClientRect();

      const onSelectFile = (file: File) => {
        // 选择上传图片
        updateBlock(
          blockId,
          {
            data: {
              icon: {
                type: 'upload',
                value: '',
              },
              iconUrl: URL.createObjectURL(file),
            },
          },
          true // ignore op
        );
        message.loading({ key: MessageIds.UPLOAD_ICON, content: '上传中...' });
        void upload({
          file,
          type: 'file',
          searchType: 'icon',
          onComplete: (ret) => {
            message.closeMessage(MessageIds.UPLOAD_ICON);
            if (ret.success) {
              message.success('更换成功');
              // 上传成功之后更新字段
              transaction(() => {
                updateBlock(blockId, {
                  data: { icon: { type: 'upload', value: ret.ossName ?? '' } },
                });
              });
            }
          },
        });
      };

      const onSelectRecentlyImage = (imageInfo: ImageInfo) => {
        transaction(() => {
          updateBlock(blockId, {
            data: {
              icon: { type: 'upload', value: imageInfo.ossName },
              iconUrl: imageInfo.url, // 本地用得到
            },
          });
        });
      };

      openModal.dropdown({
        popcorn: {
          getBoundingClientRect: () => rect,
        },
        placement: opts.placement || 'bottom',
        modifiers: opts.modifiers,
        offset: opts.offset,
        content: ({ onCloseModal }) => (
          <IconPanel
            onSelectCustomIcon={(iconPath, isFromRandom) => {
              if (!isFromRandom) {
                onCloseModal();
              }
              transaction(() => {
                updateBlock(blockId, {
                  data: {
                    icon: {
                      type: 'icon',
                      value: iconPath,
                    },
                    iconUrl: '',
                  },
                });
              });
            }}
            onSelectEmoji={(emojiStr, isFromRandom) => {
              if (!isFromRandom) {
                onCloseModal();
              }
              opts.onSelectEmoji?.(emojiStr);
              transaction(() => {
                updateBlock(blockId, {
                  data: {
                    icon: {
                      type: 'emoji',
                      value: emojiStr,
                    },
                    iconUrl: '',
                  },
                });
              });
            }}
            onUploadFile={(file) => {
              if (file.size >= 20 * 1024 * 1024) {
                // TODO 我们后面可以压缩一下，不至于那么死板
                message.error('不支持超过20M的图标');
                return;
              }
              onCloseModal();
              onSelectFile(file);
            }}
            onSelectRecentlyImage={(imageInfo) => {
              onCloseModal();
              onSelectRecentlyImage(imageInfo);
            }}
            hideRemoveBtn={opts.hideRemoveBtn}
            onRemoveBtnClick={() => {
              transaction(() => {
                updateBlock(blockId, {
                  data: {
                    icon: {},
                    iconUrl: undefined,
                  },
                });
              });
              onCloseModal();
            }}
            inputValue={linkUrl}
            onInputUrl={(url) => {
              transaction(() => {
                updateBlock(blockId, {
                  data: {
                    icon: {
                      type: 'http',
                      value: url,
                    },
                    iconUrl: url,
                  },
                });
              });
              onCloseModal();
            }}
          />
        ),
      });
    },
    [blockId, linkUrl, openModal, transaction, upload]
  );
};
