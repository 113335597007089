import type {
  CollectionSchema,
  CollectionViewProperties,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { ONE_DAY } from 'src/bitable/timeline-view/const';
import { buildDateSegment } from 'src/editor/utils/segments';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_CLEAR } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';

import {
  composeBoardViewFormat,
  composeCalendarViewFormat,
  composeFormViewFormat,
  composeGalleryViewFormat,
  composeListViewFormat,
  composeTableViewFormat,
  composeTimelineViewFormat,
} from './use-add-collection-view';
import { getGroupOptions } from './use-create-property';

/** 文档P 转换成 多维表 */
export const useDocToBitable = () => {
  const { getState, dispatch } = useStore();
  const transaction = useTransaction();

  const docToBitable = (
    collectionId: string,
    type: CollectionViewType = CollectionViewType.TABLE
  ) => {
    transaction(() => {
      const newSelectPropertyId = uuidV4();
      const newStartDatePropertyId = uuidV4();
      const newEndDatePropertyId = uuidV4();
      const isTimeline = type === CollectionViewType.TIMELINE;
      const isCalendar = type === CollectionViewType.CALENDAR;
      const isForm = type === CollectionViewType.FORM;

      const options: CollectionSchema['options'] = getGroupOptions();
      const collection = getState().blocks[collectionId];
      if (!collection) return;

      // 将原有 subNode 移除
      collection.subNodes.forEach((id) => {
        removeBlock(id);
      });

      const collectionPageProperties: CollectionViewProperties = [];
      const schema = {
        title: {
          name: '标题',
          type: CollectionSchemaType.TITLE,
        },
        [newSelectPropertyId]: {
          name: '分类',
          type: CollectionSchemaType.SELECT,
          options,
        },
      };
      collectionPageProperties.push(
        { property: 'title', visible: true },
        { property: newSelectPropertyId, visible: true }
      );
      if (isTimeline || isCalendar) {
        schema[newStartDatePropertyId] = {
          name: '开始时间',
          type: CollectionSchemaType.DATE,
        };
        schema[newEndDatePropertyId] = {
          name: '结束时间',
          type: CollectionSchemaType.DATE,
        };
        collectionPageProperties.push(
          { property: newStartDatePropertyId, visible: true },
          { property: newEndDatePropertyId, visible: true }
        );
      }

      // 将当前页面更新成 COLLECTION_VIEW_PAGE
      updateBlock(collectionId, {
        type: BlockType.COLLECTION_VIEW_PAGE,
        local: true,
        data: { schema, collectionPageProperties },
      });

      // 构造表格内容
      let ids: string[] = [];
      if (!isCalendar && !isForm) {
        ids = [5, 3, 1].map((value) => {
          const collectionProperties: Record<string, SegmentDTO[]> = {
            [newSelectPropertyId]: [],
          };

          if (isTimeline) {
            const startDate = new Date(Date.now() - value * ONE_DAY);
            collectionProperties[newStartDatePropertyId] = [
              buildDateSegment({
                from: startDate,
              }),
            ];
            collectionProperties[newEndDatePropertyId] = [
              buildDateSegment({
                from: new Date(startDate.getTime() + 5 * ONE_DAY),
              }),
            ];
          }

          return addBlock(
            {
              type: BlockType.PAGE,
              data: {
                segments: [],
                collectionProperties,
              },
            },
            { parentId: collectionId },
            false,
            true
          );
        });
      }

      // 构造 format
      const initProperties = [
        { property: 'title', visible: true },
        {
          property: newSelectPropertyId,
          visible: type === CollectionViewType.TABLE,
        },
      ];
      if (isTimeline || isCalendar) {
        initProperties.push(
          {
            property: newStartDatePropertyId,
            visible: false,
          },
          {
            property: newEndDatePropertyId,
            visible: false,
          }
        );
      }
      let format: NextCollectionView['format'] = {};
      switch (type) {
        case CollectionViewType.TABLE:
          format = composeTableViewFormat({ tableProperties: initProperties });
          break;
        case CollectionViewType.BOARD:
          format = composeBoardViewFormat({
            groupProperty: newSelectPropertyId,
            boardProperties: initProperties,
            options,
          });
          break;
        case CollectionViewType.GALLERY:
          format = composeGalleryViewFormat({
            galleryProperties: initProperties,
          });
          break;
        case CollectionViewType.LIST:
          format = composeListViewFormat({
            listProperties: initProperties,
          });
          break;
        case CollectionViewType.TIMELINE:
          format = composeTimelineViewFormat({
            timelineProperties: initProperties,
            timelineBy: newStartDatePropertyId,
            timelineByEnd: newEndDatePropertyId,
          });
          break;
        case CollectionViewType.CALENDAR:
          format = composeCalendarViewFormat({
            calendarProperties: initProperties,
            calendarBy: newStartDatePropertyId,
            calendarByEnd: newEndDatePropertyId,
          });
          break;
        case CollectionViewType.FORM:
          format = composeFormViewFormat({
            formProperties: initProperties,
          });
          break;
        default:
          break;
      }

      CollectionViewManager.add(
        {
          title: '默认视图',
          format,
          pageSort: ids,
          type,
        },
        { parentId: collectionId }
      );

      if (isForm) {
        CollectionViewManager.add(
          {
            title: '表格',
            format: composeTableViewFormat({ tableProperties: initProperties }),
            pageSort: [],
            type: CollectionViewType.TABLE,
          },
          { parentId: collectionId }
        );
      }

      dispatch(HISTORY_CLEAR());
    });
  };

  return useCallback(docToBitable, [dispatch, getState, transaction]);
};
