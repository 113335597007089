const AutoScrollThreshold = 100;

interface ThresholdOffset {
  top?: number;
  bottom?: number;
}

interface AutoScrollY {
  y: number;
  container: HTMLElement;
  threshold?: number;
  offset?: ThresholdOffset;
}

export const getAutoScrollX = () => {
  const rafX: { id: number } = { id: 0 };

  const autoScrollX = (x: number, container: HTMLElement, threshold = AutoScrollThreshold) => {
    const rect = container.getBoundingClientRect();

    const step = () => {
      if (x - rect.left < threshold) {
        const delta = threshold - (x - rect.left);
        const speed = delta / 10;
        container.scrollLeft -= speed;
        rafX.id = requestAnimationFrame(step);
      }

      if (rect.right - x < threshold) {
        const delta = threshold - (rect.right - x);
        const speed = delta / 10;
        container.scrollLeft += speed;
        rafX.id = requestAnimationFrame(step);
      }
    };

    cancelAnimationFrame(rafX?.id);
    rafX.id = requestAnimationFrame(step);
  };

  return {
    rafX,
    autoScrollX,
  };
};

export const getAutoScrollY = () => {
  const rafY: { id: number } = { id: 0 };

  const autoScrollY = ({ y, container, threshold = AutoScrollThreshold, offset }: AutoScrollY) => {
    const rect = container.getBoundingClientRect();
    const thresholdTop = threshold + (offset?.top ?? 0);
    const thresholdBottom = threshold + (offset?.bottom ?? 0);

    const step = () => {
      if (y - rect.top < thresholdTop) {
        const delta = thresholdTop - (y - rect.top);
        const speed = delta / 10;
        container.scrollTop -= speed;
        rafY.id = requestAnimationFrame(step);
      }

      if (rect.bottom - y < thresholdBottom) {
        const delta = threshold - (rect.bottom - y);
        const speed = delta / 10;
        container.scrollTop += speed;
        rafY.id = requestAnimationFrame(step);
      }
    };

    cancelAnimationFrame(rafY.id);
    rafY.id = requestAnimationFrame(step);
  };

  return {
    rafY,
    autoScrollY,
  };
};

export const getAutoScroll = () => {
  const raf: { id: number } = { id: 0 };

  const autoScroll = (
    x: number,
    y: number,
    container: Element,
    threshold = AutoScrollThreshold
  ) => {
    const rect = container.getBoundingClientRect();

    const step = () => {
      let needRequestAnimationFrame = false;
      if (x - rect.left < threshold) {
        const speed = ((x - rect.left - threshold) * 10) / threshold;
        container.scrollLeft += speed;
        needRequestAnimationFrame = true;
      }
      if (rect.right - x < threshold) {
        const speed = ((threshold - (rect.right - x)) * 10) / threshold;
        container.scrollLeft += speed;
        needRequestAnimationFrame = true;
      }

      if (y - rect.top < threshold) {
        const speed = ((y - rect.top - threshold) * 10) / threshold;
        container.scrollTop += speed;
        needRequestAnimationFrame = true;
      }
      if (rect.bottom - y < threshold) {
        const speed = ((threshold - (rect.bottom - y)) * 10) / threshold;
        container.scrollTop += speed;
        needRequestAnimationFrame = true;
      }

      if (needRequestAnimationFrame) {
        raf.id = requestAnimationFrame(step);
      }
    };

    cancelAnimationFrame(raf?.id);
    raf.id = requestAnimationFrame(step);
  };

  return {
    raf,
    autoScroll,
  };
};
