import { SearchDocSortType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';

const SORT_TYPE = {
  [SearchDocSortType.RELEVANCE]: '按匹配度',
  [SearchDocSortType.LAST_EDITED_NEWEST]: '按创建时间 - 最近到最早',
  [SearchDocSortType.LAST_EDITED_OLDEST]: '按创建时间 - 最早到最近',
  [SearchDocSortType.CREATED_NEWEST]: '按最后编辑时间：最近到最早',
  [SearchDocSortType.CREATED_OLDEST]: '按最后编辑时间：最早到最近',
};

export interface SortSwitchProps {
  sortType: SearchDocSortType;
  onSwitchType: (type: SearchDocSortType) => void;
}
export const SortSwitch: FC<SortSwitchProps> = (props) => {
  const openModal = useOpenModal();
  const onClick = (e: React.MouseEvent) => {
    const items = Object.keys(SORT_TYPE).map((key) => {
      return {
        type: ListItemType.OPERATION,
        data: {
          sortType: key as SearchDocSortType,
          // @ts-ignore ts compile
          title: SORT_TYPE[key],
          hasArrow: props.sortType === key,
          arrow: <Icon size="normal" name="IcCheck02" />,
        },
      };
    });
    openModal.dropdown({
      popcorn: e.target as HTMLElement,
      placement: 'top',
      content: ({ onCloseModal }) => {
        return (
          <div className="next-modal w-[246px] py-1">
            <ListView
              items={items}
              onItemClick={(_, index) => {
                const item = items[index];
                if (item) {
                  props.onSwitchType(item.data.sortType);
                  onCloseModal();
                }
              }}
            />
          </div>
        );
      },
    });
  };
  return (
    <div
      className="ml-[2px] rounded-sm cursor-pointer px-1.5 py-[2px] flex text-t2-medium items-center text-grey3 text-center hover:bg-black_006"
      onClick={onClick}
    >
      <div>{SORT_TYPE[props.sortType]}</div>
      <Icon className="ml-1" name={'IcArrowDown01'} size="tiny"></Icon>
    </div>
  );
};
