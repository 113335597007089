import { getWindowDpr } from 'src/utils/dpr';

import type { Point } from '../type';
import { convertPathToRect } from './convert-path-to-rect';

export enum CropShape {
  RECTANGLE,
  POLYGON,
  ELLIPSE,
}

/**
 * imagedataToBlobUrl
 * @param imagedata
 * @param path css 尺寸
 * @returns
 */
export const imagedataToBlobUrl = async (imagedata: ImageData, path?: Point[]) => {
  const canvas = document.createElement('canvas');
  canvas.width = imagedata.width;
  canvas.height = imagedata.height;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx.putImageData(imagedata, 0, 0);

  const blobUrl = await new Promise<string | undefined>((resolve) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        resolve(undefined);
        return;
      }

      const blobUrl = URL.createObjectURL(blob);
      resolve(blobUrl);
    });
  });

  if (blobUrl && path) {
    const url = await cropImage(blobUrl, path);
    URL.revokeObjectURL(blobUrl);

    return url;
  }

  return blobUrl;
};

/**
 * imagedataToDataUrl
 * @param imagedata
 * @param path css 尺寸
 * @returns
 */
export const imagedataToDataUrl = async (
  imagedata: ImageData,
  path?: Point[],
  shape?: CropShape
) => {
  const canvas = document.createElement('canvas');

  canvas.width = imagedata.width;
  canvas.height = imagedata.height;

  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.putImageData(imagedata, 0, 0);

  const dataUrl = canvas.toDataURL();

  if (path) {
    const url = await cropImage(dataUrl, path, shape);
    return url;
  }

  return dataUrl;
};

const cropImage = async (src: string, path: Point[], shape = CropShape.POLYGON) => {
  const crop = (resolve: (value: string | undefined) => void) => {
    const img = new Image();

    const onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        resolve(undefined);
        return;
      }

      const { left, top } = convertPathToRect(path);
      const radio = getWindowDpr();
      path = path.map((point) => ({ x: (point.x - left) * radio, y: (point.y - top) * radio }));

      ctx.beginPath();
      if (shape === CropShape.ELLIPSE) {
        const [point1, point2] = path;
        if (!point1 || !point2) return;

        const { x, y } = point1;
        const { x: x2, y: y2 } = point2;

        const width = Math.abs(x2 - x);
        const height = Math.abs(y2 - y);

        const centerX = Math.min(x2, x) + width / 2;
        const centerY = Math.min(y2, y) + height / 2;

        ctx.ellipse(centerX, centerY, width / 2, height / 2, 0, 0, 2 * Math.PI);
      } else {
        path.forEach((point, index) => {
          if (index === 0) {
            ctx.moveTo(point.x, point.y);
          } else {
            ctx.lineTo(point.x, point.y);
          }
        });
      }

      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0);

      const dataUrl = canvas.toDataURL();
      resolve(dataUrl);
    };

    img.onload = onload;
    img.src = src;
  };

  const url = await new Promise<string | undefined>(crop);
  return url;
};
