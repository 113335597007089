import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import type { UIState } from 'src/redux/reducers/ui';
import { cache } from 'src/redux/store';
import { getOwnerPage } from './use-get-owner-page';
import { useUpdatePropertyValue } from './use-update-property-value';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

/**
 * 对选中的cell清空
 */
export const useClearSelectedCellValue = () => {
  const updatePropertyValue = useUpdatePropertyValue();
  return useCallback(
    (selectedCells: UIState['selectedCells']) => {
      // 跟cell.tsx的backspace保持一致逻辑
      const recordIds: Set<string> = new Set<string>();
      const propertyIds: Set<string> = new Set<string>();
      selectedCells.forEach((cell) => {
        recordIds.add(cell.recordId);
        propertyIds.add(cell.propertyId);
      });
      const focusedCell = selectedCells[0];
      if (!focusedCell) return;
      const collectionId = getOwnerPage(focusedCell.recordId);
      if (!collectionId) return;
      const cellType = cache.blocks[collectionId]?.data.schema?.[focusedCell.propertyId]?.type;
      const value =
        cache.blocks[focusedCell.recordId]?.data.collectionProperties?.[focusedCell.propertyId];
      if (cellType === CollectionSchemaType.CHECKBOX) {
        const text = segmentsToText(value);
        if (formatCheckBoxValue(text.toUpperCase())) {
          updatePropertyValue([...recordIds], [...propertyIds], 'NO');
        }
      } else {
        updatePropertyValue([...recordIds], [...propertyIds], '');
      }
    },
    [updatePropertyValue]
  );
};
