import { BlockType } from '@next-space/fe-api-idl';
import type { RootState } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { PAGE_TYPES } from 'src/utils/block-type-utils';

/** 当前文档里的目录block */
export const directoryDescendantsSelector = MemoizeByBlockVersion.create(
  (blocks: RootState['blocks'], id: string) => {
    const filterTypes = [...PAGE_TYPES, BlockType.TEMPLATE];
    const specifyType = [BlockType.HEADER, BlockType.TOGGLE_HEADER];

    // 防止可能出现某些循环依赖block造成死循环
    const mapHistory = new Set<string>();
    const result: RootState['blocks'] = {};
    const loop = (itemId: string) => {
      const cur = blocks[itemId];
      if (cur && !mapHistory.has(itemId) && (!filterTypes.includes(cur.type) || itemId === id)) {
        mapHistory.add(itemId);
        cur.subNodes.forEach((k) => {
          const block = blocks[k];
          if (block && specifyType.includes(block.type)) {
            result[k] = block;
          }
          if (block && block.data.ref?.uuid && block.type === BlockType.SYNC_REFERENCE) {
            loop(block.data.ref.uuid);
          } else {
            loop(k);
          }
        });
      }
    };

    loop(id);
    return result;
  },
  (_, id) => id
);
