import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';
import { EmptyBlocker } from 'src/editor/component/chart/empty-blocker';
import { ChartPreview } from 'src/editor/component/chart/chart-config-panel/chart-preview';
import { useContext, useEffect } from 'react';
import { useFetchPage, useReadonly } from 'src/hooks/page';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { Caption } from 'src/editor/component/caption';
import { ResizeElement } from 'src/editor/component/resize-element';
import { useResize } from 'src/hooks/block/use-resize';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { cx } from '@flowus/common/cx';
import { useRootImageAlignStyle } from 'src/hooks/block/use-align-style';
import { OverlayContainerContext, useDebounceElementSize } from '@flowus/common/hooks';
import { checkHasCollectionData } from 'src/utils/block-utils';
import { $searchParams } from '@flowus/common/flowus-utils/search-params';
import { useStore } from 'react-redux';
import { defaultStore, StoreContext } from 'src/services/store-context/context';
import { ChartPageToolbar } from 'src/editor/component/chart/chart-page-toolbar';

export const ChartBlockElement: BlockElement = ({ id, root }) => {
  const readonly = useReadonly(id);
  const fetchPage = useFetchPage();
  const collectionId = useObservableStore((state) => {
    const { blocks } = state;
    return blocks[id]?.data.format?.chartFormat?.ref?.uuid;
  }, []);
  const viewId = useObservableStore((state) => {
    const { blocks } = state;
    return blocks[id]?.data.format?.chartFormat?.ref?.viewId;
  }, []);
  const format = useObservableStore((state) => {
    const { blocks } = state;
    return blocks[id]?.data.format;
  }, []);
  const store = useStore();
  const container = useContext(OverlayContainerContext);
  const pageWidth = useDebounceElementSize(container?.current, { type: 'width' }).width;
  const rootImageStyle = useRootImageAlignStyle(id, pageWidth || Number.MAX_SAFE_INTEGER, !!root);
  const showBorder = format?.chartFormat?.border ?? true;
  const {
    renderSize,
    onRenderSize,
    changeSize,
    containerWidth,
    // containerRef,
    isDisable,
    defaultBlockFullWidth,
    defaultWidth,
    defaultHeight,
    widthBreakPoints,
  } = useResize({
    id,
    root,
    defaultHeight: format?.height ?? 360,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (collectionId && !checkHasCollectionData(collectionId)) {
        await fetchPage(collectionId);
      }
    };
    void fetchData();
  }, [collectionId, fetchPage, store, viewId]);

  return (
    <BlockDrop
      className="my-1"
      id={id}
      fullWidth={!collectionId}
      horizontal={root}
      style={rootImageStyle}
    >
      <EmptyBlocker id={id}>
        <ResizeElement
          outerLayoutStyle={
            $searchParams.print
              ? {
                  width: '90%',
                }
              : undefined
          }
          resizeWidth
          resizeHeight
          widthBreakPoints={widthBreakPoints}
          readonly={readonly || isDisable}
          defaultBlockFullWidth={defaultBlockFullWidth}
          minWidth={220}
          minHeight={200}
          maxWidth={containerWidth ? containerWidth : undefined}
          defaultWidth={defaultWidth || DEFAULT_PAGE_WIDTH}
          defaultHeight={defaultHeight}
          onChange={changeSize}
          // outerLayoutStyle={imageBoxAlignStyle}
          onRenderSize={onRenderSize}
          className={cx('max-w-full', isDisable && 'pointer-events-none')}
          customFooter={
            <Caption
              blockId={id}
              style={{ width: $searchParams.print ? '90%' : renderSize.width }}
            />
          }
        >
          <div
            className={cx('rounded w-full h-full', {
              border: showBorder,
            })}
          >
            {/* 为了让图表百分百使用redux，要不然有bug */}
            <StoreContext.Provider
              value={{
                isInit: false,
                $state: defaultStore,
                once: false,
              }}
            >
              <ChartPreview id={id} />
              <ChartPageToolbar
                blockId={id}
                className="z-[12] opacity-0 group-hover:opacity-100 next-modal absolute right-0 top-0"
              />
            </StoreContext.Provider>
          </div>
        </ResizeElement>
      </EmptyBlocker>
    </BlockDrop>
  );
};
