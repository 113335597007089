import { slice } from 'lodash-es';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';

// #region 成员列表折叠组件
interface FoldItemListProps<T> {
  items: T[];
  renderItemContent: (props: T) => ReactNode;
  foldLength?: number;
  moreContent?: string;
}
export const FoldItemList = <T extends {}>(props: FoldItemListProps<T>): ReactElement => {
  const { items, renderItemContent, foldLength = 50 } = props;
  const [isFold, setIsFold] = useState(false);

  if (items.length > foldLength && !isFold) {
    return (
      <>
        {slice(items, 0, foldLength).map((item) => renderItemContent(item))}
        <div
          className="text-grey4 text-t3 flex items-center h-9 cursor-pointer"
          onClick={() => setIsFold(true)}
        >
          <Icon name="IcArrowShow" size="middle" className="mr-2" />
          {['查看其他', items.length - foldLength, '位', props.moreContent ?? '成员'].join(' ')}
        </div>
      </>
    );
  }
  return (
    <>
      {items.map((item) => renderItemContent(item))}
      {items.length > foldLength && isFold && (
        <div
          className="text-grey4 text-t3 flex items-center h-9 cursor-pointer"
          onClick={() => setIsFold(false)}
        >
          <Icon name="IcArrowShow" size="middle" className="mr-2 rotate-180" />
          收起
        </div>
      )}
    </>
  );
};
