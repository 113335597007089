import { useMemo } from 'react';

export const usePosition = (getSize: (i: number) => number) => {
  return useMemo(() => {
    const cache: Record<number, number> = {};

    return (index: number) => {
      let pos = cache[index];
      if (typeof pos === 'number') {
        return pos;
      }
      pos = 0;
      let i = index;
      while (i > 0) {
        i -= 1;
        pos += getSize(i);
      }
      cache[index] = pos;
      return pos;
    };
  }, [getSize]);
};
