import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';

import { $appUiStateCache, setAppUiState } from 'src/services/app';
import type { PDFViewApplication } from '../service/pdf-viewer-application';

interface Props {
  className: string;
  application: PDFViewApplication;
}

export const AIButton: FC<Props> = ({ className, application }) => {
  const handleClick = () => {
    application.setAIShowSideBar(!$appUiStateCache.$showPDFSidebar);
    setAppUiState({ $showPDFSidebar: !$appUiStateCache.$showPDFSidebar });
  };

  if (__PRIVATE__) return null;

  return (
    <Tooltip placement="bottom" className={className} popup="辅助">
      <button onClick={handleClick} className={'animate-hover p-1 flex'}>
        <Icon size="middle" name="IcAi" />
      </button>
    </Tooltip>
  );
};
