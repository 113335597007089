import { useEffect, type FC, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import type { Wares } from './type';
import { WareImage } from './ware-image';
import { useFetchPage } from 'src/hooks/page';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import { PageScrollRefContext } from 'src/views/main/page-doc/context';
import { BlockEditor } from 'src/editor/editor/uikit/editor';
import { Button } from '@flowus/common/components';
import { useExchangeScore } from './use-exchange-score';

interface Props {
  wares: Wares;
  onClose: () => void;
}

export const WaresDetail: FC<Props> = (props) => {
  const fetchPage = useFetchPage();
  const scrollRef = useRef(null);
  const exchangeScore = useExchangeScore();

  useEffect(() => {
    void fetchPage(props.wares.recordId);
  }, [fetchPage, props.wares.recordId]);

  return (
    <>
      <div className="px-6 flex items-center justify-between">
        <div className="flex items-center py-2.5">
          <span className="text-t1-medium">{props.wares.name}</span>
        </div>
        <Icon
          className="animate-click"
          size="middle"
          name={'IcToastClose'}
          onClick={() => {
            props.onClose();
          }}
        />
      </div>
      <div className="h-px bg-grey6 w-full" />
      <div className="h-full overflow-auto">
        <div className="w-full flex justify-center">
          <WareImage
            className="mt-2.5 w-[540px] h-[240px]"
            blockId={props.wares.recordId}
            ossName={props.wares.ossName}
          />
        </div>
        <div className="px-7 my-5 max-h-[150px] flex-1 text-t2" ref={scrollRef}>
          <PageSceneContext.Provider value={PageScene.UPDATE_LOG}>
            <PageScrollRefContext.Provider value={scrollRef}>
              <BlockEditor uuid={props.wares.recordId} readonly={true} />
            </PageScrollRefContext.Provider>
          </PageSceneContext.Provider>
        </div>
      </div>
      <div className="px-6 py-4 border-t flex items-center justify-between">
        <div className="text-h3">{`${props.wares.score}积分`}</div>
        <Button
          colorType="active"
          className="h-10 w-[210px]"
          onClick={async () => {
            void exchangeScore(props.wares.waresId, props.onClose);
          }}
        >
          立即兑换
        </Button>
      </div>
    </>
  );
};
