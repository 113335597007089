import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { getState } from 'src/redux/store';
import { v4 } from 'uuid';

interface SelectRelationTypeProps {
  propertyId: string;
  closeSelf: () => void;
}
export const SelectRelationType: FC<SelectRelationTypeProps> = ({ propertyId, closeSelf }) => {
  const { collectionId } = useBitable();
  const [isSync, setIsSync] = useState(false);
  const updatePropertySchema = useUpdatePropertySchema();

  return (
    <div className="next-modal w-[450px] p-2 pb-7">
      <div
        className={cx('flex rounded p-3 cursor-pointer')}
        style={{
          background: isSync ? 'rgba(0,0,0,0.06)' : undefined,
        }}
        onClick={() => setIsSync(true)}
      >
        <Icon
          size="normal"
          name={__BUILD_IN__ ? 'MIcBinRelationSync' : 'MIcRelationSync'}
          className="!w-[150px] !h-[100px]"
        />
        <div className="ml-4 flex flex-col justify-center">
          <p className={cx('text-t2', isSync && 'font-medium')}>创建双向同步属性</p>
          <p className="mt-1 text-t4">
            <span className="font-bold">自动创建一个与当前属性双向同步的关联属性。</span>
            需要在一个多维表中建立“父记录”与“子记录”的双向关系时，请选择该选项。
          </p>
        </div>
      </div>

      <div
        className={cx('flex mt-1.5 rounded  p-3 cursor-pointer', !isSync && 'font-medium')}
        style={{
          background: !isSync ? 'rgba(0,0,0,0.06)' : undefined,
        }}
        onClick={() => setIsSync(false)}
      >
        <Icon
          size="normal"
          name={__BUILD_IN__ ? 'MIcBinRelationUnsync' : 'MIcRelationUnsync'}
          className="!w-[150px] !h-[100px]"
        />
        <div className="ml-4 flex flex-col justify-center">
          <p className={cx('text-t2', !isSync && 'font-medium')}>不创建双向同步属性</p>
          <p className="mt-1 text-t4">
            <span className="font-bold">只使用一个关联属性。</span>
            只需要建立当前记录与恰记录的单向关系时，请选择该选项。
          </p>
        </div>
      </div>

      <Button
        className="h-8 text-t2-medium mt-5"
        size="auto"
        colorType="active"
        onClick={() => {
          let newPropertyId = propertyId;
          if (isSync) {
            newPropertyId = v4();
          }

          const { blocks } = getState();
          closeSelf();
          updatePropertySchema(collectionId, propertyId, {
            type: CollectionSchemaType.RELATION,
            collectionId,
            spaceId: blocks[collectionId]?.spaceId,
            pairProperty: newPropertyId,
          });
        }}
      >
        创建关联
      </Button>
    </div>
  );
};
