import type { FC, PropsWithChildren } from 'react';

import type { IconName } from '../icon';

export interface TabPaneProps {
  title: string;
  icon?: IconName;
  dataTestId?: string;
}
export const TabPane: FC<PropsWithChildren<TabPaneProps>> = ({ children }) => {
  return <>{children}</>;
};
