import { BlockType, TextType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { segmentsToText, SUPPORT_DEEP } from 'src/editor/utils/editor';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { isLinkPageBlock } from 'src/utils/block-type-utils';
import { getMentionBlockShowInfo } from 'src/utils/get-mention-block-show-info';
import { getUntitledName } from 'src/utils/get-untitled-name';
import type { SegmentPlainTextProps } from './toc-item';

/**
 * 当文档标题包含引用页面时，引用页面的标题改变需要进行同步，segmentsToText 做不到保持同步的能力，该组件用于保持同步
 * 场景: 目录树，面包屑，页面块, 引用页面块
 * @param param0
 * @returns
 */

export const SegmentPlainText: FC<SegmentPlainTextProps> = ({ deep = 0, uuid }) => {
  const { segments, isLinkTable, blockType } = useObservableStore(
    ({ blocks }) => {
      const block = blocks[uuid];
      const isLinkPage = isLinkPageBlock(block);
      let segments = block?.data.segments;
      const blockType = block?.type;
      if (isLinkPage && block) {
        const reallyBlock = blocks[block.data.ref?.uuid ?? ''];
        segments = reallyBlock?.data.segments;
      }

      return {
        blockType,
        segments,
        isLinkTable:
          block?.type === BlockType.REFERENCE_COLLECTION ||
          block?.type === BlockType.REFERENCE_COLLECTION_PAGE,
      };
    },
    [uuid]
  );

  const segmentsText = segmentsToText(segments);

  if (!segmentsText) {
    return (
      <>
        {getUntitledName(blockType)}
        {isLinkTable && ' 的视图'}
      </>
    );
  }

  const text = segments?.map((item, index) => {
    if (item.type === TextType.LINK_PAGE && item.uuid) {
      if (deep > SUPPORT_DEEP) {
        return '';
      }
      return (
        <span key={`${deep}_${index}`}>
          @<SegmentPlainText uuid={item.uuid} deep={deep + 1} />
        </span>
      );
    } else if ([TextType.DATETIME, TextType.DATE].includes(item.type)) {
      return segmentsToText([item]);
    } else if (item.type === TextType.USER && item.uuid) {
      return `@${getUserName(item.uuid ?? '', '')}`;
    } else if (item.type === TextType.MENTION_BLOCK && item.uuid) {
      if (deep > SUPPORT_DEEP) return '@块引用';
      if (item.text) {
        return <span key={`${deep}_${index}`}>@{item.text}</span>;
      }

      const block = cache.blocks[item.uuid];
      if (!block) return '@块引用';

      if (block.type === BlockType.PDF_ANNOTATION) {
        return <span key={`${deep}_${index}`}>@{block.data.pdfAnnotation?.text ?? ''}</span>;
      }

      const { showPage } = getMentionBlockShowInfo(block?.type);
      const pageId = getOwnerPage(item.uuid);
      if (!pageId) return '@块引用';

      return (
        <span key={`${deep}_${index}`}>
          @<SegmentPlainText uuid={showPage ? pageId : item.uuid} deep={deep + 1} />
        </span>
      );
    }
    // enter需要改为空格（产品需求）
    return item.text?.replaceAll(/\s+/g, ' ');
  });

  return (
    <>
      {text}
      {isLinkTable && ' 的视图'}
    </>
  );
};
