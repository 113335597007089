import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

import { BiValueType } from './_abstract';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

export class BiCheckboxType extends BiValueType<boolean> {
  fromSegments(segments: SegmentDTO[] | undefined): boolean {
    if (segments === undefined) return false;
    return formatCheckBoxValue(segments.map((it) => it.text).join(''));
  }

  toSegments(value: boolean): SegmentDTO[] | undefined {
    if (value) {
      return [
        {
          type: TextType.TEXT,
          text: 'YES',
          enhancer: {},
        },
      ];
    }
    return undefined;
  }

  isNonNullEmpty(value: boolean): boolean {
    return value === false;
  }

  compare(value1: boolean, value2: boolean): number {
    if (value1 === value2) return 0;
    if (!value1) return -1;
    return 1;
  }
}

export const checkboxType = new BiCheckboxType();
