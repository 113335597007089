/* eslint-disable */

import builtins from './builtins.json';
const exports = {};
(function ($hx_exports, $global) { "use strict";
$hx_exports["formula"] = $hx_exports["formula"] || {};
var $estr = function() { return js_Boot.__string_rec(this,''); },$hxEnums = $hxEnums || {},$_;
function $extend(from, fields) {
	var proto = Object.create(from);
	for (var name in fields) proto[name] = fields[name];
	if( fields.toString !== Object.prototype.toString ) proto.toString = fields.toString;
	return proto;
}
var DateTools = function() { };
DateTools.__name__ = true;
DateTools.__format_get = function(d,e) {
	switch(e) {
	case "%":
		return "%";
	case "A":
		return DateTools.DAY_NAMES[d.getDay()];
	case "B":
		return DateTools.MONTH_NAMES[d.getMonth()];
	case "C":
		return StringTools.lpad(Std.string(d.getFullYear() / 100 | 0),"0",2);
	case "D":
		return DateTools.__format(d,"%m/%d/%y");
	case "F":
		return DateTools.__format(d,"%Y-%m-%d");
	case "I":case "l":
		var hour = d.getHours() % 12;
		return StringTools.lpad(Std.string(hour == 0 ? 12 : hour),e == "I" ? "0" : " ",2);
	case "M":
		return StringTools.lpad(Std.string(d.getMinutes()),"0",2);
	case "R":
		return DateTools.__format(d,"%H:%M");
	case "S":
		return StringTools.lpad(Std.string(d.getSeconds()),"0",2);
	case "T":
		return DateTools.__format(d,"%H:%M:%S");
	case "Y":
		return Std.string(d.getFullYear());
	case "a":
		return DateTools.DAY_SHORT_NAMES[d.getDay()];
	case "b":case "h":
		return DateTools.MONTH_SHORT_NAMES[d.getMonth()];
	case "d":
		return StringTools.lpad(Std.string(d.getDate()),"0",2);
	case "e":
		return Std.string(d.getDate());
	case "H":case "k":
		return StringTools.lpad(Std.string(d.getHours()),e == "H" ? "0" : " ",2);
	case "m":
		return StringTools.lpad(Std.string(d.getMonth() + 1),"0",2);
	case "n":
		return "\n";
	case "p":
		if(d.getHours() > 11) {
			return "PM";
		} else {
			return "AM";
		}
	case "r":
		return DateTools.__format(d,"%I:%M:%S %p");
	case "s":
		return Std.string(d.getTime() / 1000 | 0);
	case "t":
		return "\t";
	case "u":
		var t = d.getDay();
		if(t == 0) {
			return "7";
		} else if(t == null) {
			return "null";
		} else {
			return "" + t;
		}
	case "w":
		return Std.string(d.getDay());
	case "y":
		return StringTools.lpad(Std.string(d.getFullYear() % 100),"0",2);
	default:
		throw new haxe_exceptions_NotImplementedException("Date.format %" + e + "- not implemented yet.",null,{ fileName : "DateTools.hx", lineNumber : 101, className : "DateTools", methodName : "__format_get"});
	}
};
DateTools.__format = function(d,f) {
	var r_b = "";
	var p = 0;
	while(true) {
		var np = f.indexOf("%",p);
		if(np < 0) {
			break;
		}
		var len = np - p;
		r_b += len == null ? HxOverrides.substr(f,p,null) : HxOverrides.substr(f,p,len);
		r_b += Std.string(DateTools.__format_get(d,HxOverrides.substr(f,np + 1,1)));
		p = np + 2;
	}
	var len = f.length - p;
	r_b += len == null ? HxOverrides.substr(f,p,null) : HxOverrides.substr(f,p,len);
	return r_b;
};
DateTools.format = function(d,f) {
	return DateTools.__format(d,f);
};
var EReg = function(r,opt) {
	this.r = new RegExp(r,opt.split("u").join(""));
};
EReg.__name__ = true;
EReg.prototype = {
	match: function(s) {
		if(this.r.global) {
			this.r.lastIndex = 0;
		}
		this.r.m = this.r.exec(s);
		this.r.s = s;
		return this.r.m != null;
	}
	,matched: function(n) {
		if(this.r.m != null && n >= 0 && n < this.r.m.length) {
			return this.r.m[n];
		} else {
			throw haxe_Exception.thrown("EReg::matched");
		}
	}
	,matchedPos: function() {
		if(this.r.m == null) {
			throw haxe_Exception.thrown("No string matched");
		}
		return { pos : this.r.m.index, len : this.r.m[0].length};
	}
	,matchSub: function(s,pos,len) {
		if(len == null) {
			len = -1;
		}
		if(this.r.global) {
			this.r.lastIndex = pos;
			this.r.m = this.r.exec(len < 0 ? s : HxOverrides.substr(s,0,pos + len));
			var b = this.r.m != null;
			if(b) {
				this.r.s = s;
			}
			return b;
		} else {
			var b = this.match(len < 0 ? HxOverrides.substr(s,pos,null) : HxOverrides.substr(s,pos,len));
			if(b) {
				this.r.s = s;
				this.r.m.index += pos;
			}
			return b;
		}
	}
	,map: function(s,f) {
		var offset = 0;
		var buf_b = "";
		while(true) {
			if(offset >= s.length) {
				break;
			} else if(!this.matchSub(s,offset)) {
				buf_b += Std.string(HxOverrides.substr(s,offset,null));
				break;
			}
			var p = this.matchedPos();
			buf_b += Std.string(HxOverrides.substr(s,offset,p.pos - offset));
			buf_b += Std.string(f(this));
			if(p.len == 0) {
				buf_b += Std.string(HxOverrides.substr(s,p.pos,1));
				offset = p.pos + 1;
			} else {
				offset = p.pos + p.len;
			}
			if(!this.r.global) {
				break;
			}
		}
		if(!this.r.global && offset > 0 && offset < s.length) {
			buf_b += Std.string(HxOverrides.substr(s,offset,null));
		}
		return buf_b;
	}
};
var HxOverrides = function() { };
HxOverrides.__name__ = true;
HxOverrides.dateStr = function(date) {
	var m = date.getMonth() + 1;
	var d = date.getDate();
	var h = date.getHours();
	var mi = date.getMinutes();
	var s = date.getSeconds();
	return date.getFullYear() + "-" + (m < 10 ? "0" + m : "" + m) + "-" + (d < 10 ? "0" + d : "" + d) + " " + (h < 10 ? "0" + h : "" + h) + ":" + (mi < 10 ? "0" + mi : "" + mi) + ":" + (s < 10 ? "0" + s : "" + s);
};
HxOverrides.cca = function(s,index) {
	var x = s.charCodeAt(index);
	if(x != x) {
		return undefined;
	}
	return x;
};
HxOverrides.substr = function(s,pos,len) {
	if(len == null) {
		len = s.length;
	} else if(len < 0) {
		if(pos == 0) {
			len = s.length + len;
		} else {
			return "";
		}
	}
	return s.substr(pos,len);
};
HxOverrides.now = function() {
	return Date.now();
};
var Lambda = function() { };
Lambda.__name__ = true;
Lambda.fold = function(it,f,first) {
	var x = $getIterator(it);
	while(x.hasNext()) first = f(x.next(),first);
	return first;
};
Math.__name__ = true;
var Std = function() { };
Std.__name__ = true;
Std.string = function(s) {
	return js_Boot.__string_rec(s,"");
};
Std.parseInt = function(x) {
	if(x != null) {
		var _g = 0;
		var _g1 = x.length;
		while(_g < _g1) {
			var i = _g++;
			var c = x.charCodeAt(i);
			if(c <= 8 || c >= 14 && c != 32 && c != 45) {
				var nc = x.charCodeAt(i + 1);
				var v = parseInt(x,nc == 120 || nc == 88 ? 16 : 10);
				if(isNaN(v)) {
					return null;
				} else {
					return v;
				}
			}
		}
	}
	return null;
};
var StringTools = function() { };
StringTools.__name__ = true;
StringTools.isSpace = function(s,pos) {
	var c = HxOverrides.cca(s,pos);
	if(!(c > 8 && c < 14)) {
		return c == 32;
	} else {
		return true;
	}
};
StringTools.ltrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,r)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,r,l - r);
	} else {
		return s;
	}
};
StringTools.rtrim = function(s) {
	var l = s.length;
	var r = 0;
	while(r < l && StringTools.isSpace(s,l - r - 1)) ++r;
	if(r > 0) {
		return HxOverrides.substr(s,0,l - r);
	} else {
		return s;
	}
};
StringTools.trim = function(s) {
	return StringTools.ltrim(StringTools.rtrim(s));
};
StringTools.lpad = function(s,c,l) {
	if(c.length <= 0) {
		return s;
	}
	var buf_b = "";
	l -= s.length;
	while(buf_b.length < l) buf_b += c == null ? "null" : "" + c;
	buf_b += s == null ? "null" : "" + s;
	return buf_b;
};
var byte_ByteData = {};
byte_ByteData.get_length = function(this1) {
	return this1.length;
};
byte_ByteData.readByte = function(this1,i) {
	return this1.b[i];
};
byte_ByteData._new = function(data) {
	return data;
};
byte_ByteData.ofString = function(s) {
	return haxe_io_Bytes.ofString(s);
};
byte_ByteData.ofBytes = function(b) {
	return b;
};
byte_ByteData.readString = function(this1,pos,len) {
	return this1.getString(pos,len);
};
var datetime_DTPeriod = $hxEnums["datetime.DTPeriod"] = { __ename__:true,__constructs__:null
	,Year: ($_=function(n) { return {_hx_index:0,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Year",$_.__params__ = ["n"],$_)
	,Month: ($_=function(n) { return {_hx_index:1,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Month",$_.__params__ = ["n"],$_)
	,Day: ($_=function(n) { return {_hx_index:2,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Day",$_.__params__ = ["n"],$_)
	,Hour: ($_=function(n) { return {_hx_index:3,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Hour",$_.__params__ = ["n"],$_)
	,Minute: ($_=function(n) { return {_hx_index:4,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Minute",$_.__params__ = ["n"],$_)
	,Second: ($_=function(n) { return {_hx_index:5,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Second",$_.__params__ = ["n"],$_)
	,Week: ($_=function(n) { return {_hx_index:6,n:n,__enum__:"datetime.DTPeriod",toString:$estr}; },$_._hx_name="Week",$_.__params__ = ["n"],$_)
};
datetime_DTPeriod.__constructs__ = [datetime_DTPeriod.Year,datetime_DTPeriod.Month,datetime_DTPeriod.Day,datetime_DTPeriod.Hour,datetime_DTPeriod.Minute,datetime_DTPeriod.Second,datetime_DTPeriod.Week];
var datetime_DTSnap = $hxEnums["datetime.DTSnap"] = { __ename__:true,__constructs__:null
	,Year: ($_=function(direction) { return {_hx_index:0,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Year",$_.__params__ = ["direction"],$_)
	,Month: ($_=function(direction) { return {_hx_index:1,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Month",$_.__params__ = ["direction"],$_)
	,Day: ($_=function(direction) { return {_hx_index:2,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Day",$_.__params__ = ["direction"],$_)
	,Hour: ($_=function(direction) { return {_hx_index:3,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Hour",$_.__params__ = ["direction"],$_)
	,Minute: ($_=function(direction) { return {_hx_index:4,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Minute",$_.__params__ = ["direction"],$_)
	,Second: ($_=function(direction) { return {_hx_index:5,direction:direction,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Second",$_.__params__ = ["direction"],$_)
	,Week: ($_=function(direction,day) { return {_hx_index:6,direction:direction,day:day,__enum__:"datetime.DTSnap",toString:$estr}; },$_._hx_name="Week",$_.__params__ = ["direction","day"],$_)
};
datetime_DTSnap.__constructs__ = [datetime_DTSnap.Year,datetime_DTSnap.Month,datetime_DTSnap.Day,datetime_DTSnap.Hour,datetime_DTSnap.Minute,datetime_DTSnap.Second,datetime_DTSnap.Week];
var datetime_DateTime = {};
datetime_DateTime.now = function() {
	return Math.floor(new Date().getTime() / 1000) + 62135596800.0;
};
datetime_DateTime.local = function() {
	return Math.floor(new Date().getTime() / 1000) + 62135596800.0 - 62135596800.0 + datetime_DateTime.getLocalOffset() + 62135596800.0;
};
datetime_DateTime.make = function(year,month,day,hour,minute,second) {
	if(second == null) {
		second = 0;
	}
	if(minute == null) {
		minute = 0;
	}
	if(hour == null) {
		hour = 0;
	}
	if(day == null) {
		day = 1;
	}
	if(month == null) {
		month = 1;
	}
	if(year == null) {
		year = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(month,year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (day - 1) * 86400 + hour * 3600 + minute * 60 + second - 62135596800.0 + 62135596800.0;
};
datetime_DateTime.fromTime = function(time) {
	return time + 62135596800.0;
};
datetime_DateTime.fromString = function(str) {
	return datetime_utils_DateTimeUtils.fromString(str);
};
datetime_DateTime.fromDate = function(date) {
	return Math.floor(date.getTime() / 1000) + 62135596800.0;
};
datetime_DateTime.daysInMonth = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	return datetime_utils_DateTimeMonthUtils.days(month,isLeapYear);
};
datetime_DateTime.weeksInYear = function(year) {
	var start = datetime_utils_DateTimeUtils.yearToStamp(year) - 62135596800.0 + 62135596800.0;
	var weekDay = datetime_DateTime.getWeekDay(start);
	if(weekDay == 4 || weekDay == 3 && datetime_DateTime.isLeapYear(start)) {
		return 53;
	} else {
		return 52;
	}
};
datetime_DateTime.isLeap = function(year) {
	if(year % 4 == 0) {
		if(year % 100 == 0) {
			return year % 400 == 0;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
datetime_DateTime.getLocalOffset = function() {
	var now = new Date();
	var year = now.getFullYear();
	var month = now.getMonth() + 1;
	var day = now.getDate();
	var hour = now.getHours();
	var minute = now.getMinutes();
	var second = now.getSeconds();
	if(second == null) {
		second = 0;
	}
	if(minute == null) {
		minute = 0;
	}
	if(hour == null) {
		hour = 0;
	}
	if(day == null) {
		day = 1;
	}
	if(month == null) {
		month = 1;
	}
	if(year == null) {
		year = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(month,year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (day - 1) * 86400 + hour * 3600 + minute * 60 + second - 62135596800.0 + 62135596800.0 - 62135596800.0 - (now.getTime() / 1000 | 0) | 0;
};
datetime_DateTime._new = function(time) {
	return time + 62135596800.0;
};
datetime_DateTime.utc = function(this1) {
	return this1 - 62135596800.0 - datetime_DateTime.getLocalOffset() + 62135596800.0;
};
datetime_DateTime.getYear = function(this1) {
	var cquads = (this1 / 12622780800.0 | 0) * 12622780800.0;
	var centuries = ((this1 - cquads) / 3155673600.0 | 0) * 3155673600.0;
	if(centuries > 9467020800.) {
		centuries -= 3155673600.0;
	}
	var quads = ((this1 - cquads - centuries) / 126230400.0 | 0) * 126230400.0;
	var years = (this1 - cquads - centuries - quads) / 31536000 | 0;
	return (cquads / 12622780800.0 | 0) * 400 + (centuries / 3155673600.0 | 0) * 100 + (quads / 126230400.0 | 0) * 4 + (years == 4 ? years : years + 1);
};
datetime_DateTime.yearStart = function(this1) {
	var cquads = (this1 / 12622780800.0 | 0) * 12622780800.0;
	var centuries = ((this1 - cquads) / 3155673600.0 | 0) * 3155673600.0;
	if(centuries > 9467020800.) {
		centuries -= 3155673600.0;
	}
	var quads = ((this1 - cquads - centuries) / 126230400.0 | 0) * 126230400.0;
	var years = (this1 - cquads - centuries - quads) / 31536000 | 0;
	if(years == 4) {
		--years;
	}
	return cquads + centuries + quads + years * 31536000 - 62135596800.0;
};
datetime_DateTime.monthStart = function(this1,month) {
	if(month == null) {
		month = 0;
	}
	if(month == 0) {
		month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	}
	return datetime_DateTime.yearStart(this1) + datetime_utils_DateTimeMonthUtils.toSeconds(month,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getMonthStart = function(this1,month) {
	return datetime_DateTime.monthStart(this1,month) + 62135596800.0;
};
datetime_DateTime.isLeapYear = function(this1) {
	var year = datetime_DateTime.getYear(this1);
	if(year % 4 == 0) {
		if(year % 100 == 0) {
			return year % 400 == 0;
		} else {
			return true;
		}
	} else {
		return false;
	}
};
datetime_DateTime.getMonth = function(this1) {
	return datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getDay = function(this1) {
	return datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.daysInThisMonth = function(this1) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	return datetime_utils_DateTimeMonthUtils.days(month,month == 2 && datetime_DateTime.isLeapYear(this1));
};
datetime_DateTime.getYearDay = function(this1) {
	return ((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1;
};
datetime_DateTime.weeksInThisYear = function(this1) {
	return datetime_DateTime.weeksInYear(datetime_DateTime.getYear(this1));
};
datetime_DateTime.getWeekDay = function(this1,mondayBased) {
	if(mondayBased == null) {
		mondayBased = false;
	}
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var a = (14 - month) / 12 | 0;
	var y = datetime_DateTime.getYear(this1) - a;
	var weekDay = (7000 + (datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1)) + y + (y / 4 | 0) - (y / 100 | 0) + (y / 400 | 0) + (31 * (month + 12 * a - 2) / 12 | 0))) % 7;
	if(mondayBased && weekDay == 0) {
		return 7;
	} else {
		return weekDay;
	}
};
datetime_DateTime.getWeekDayNum = function(this1,weekDay,num) {
	if(num == null) {
		num = 1;
	}
	return datetime_utils_DateTimeUtils.getWeekDayNum(this1 - 62135596800.0 + 62135596800.0,weekDay,num) + 62135596800.0;
};
datetime_DateTime.getWeek = function(this1) {
	var week = (((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1 - datetime_DateTime.getWeekDay(this1,true) + 10) / 7 | 0;
	var year = datetime_DateTime.getYear(this1);
	if(week < 1) {
		return datetime_DateTime.weeksInYear(year - 1);
	} else if(week > 52 && week > datetime_DateTime.weeksInYear(year)) {
		return 1;
	} else {
		return week;
	}
};
datetime_DateTime.getHour = function(this1) {
	return (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
};
datetime_DateTime.getHour12 = function(this1) {
	var hour = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
	if(hour == 0) {
		return 12;
	} else if(hour > 12) {
		return hour - 12;
	} else {
		return hour;
	}
};
datetime_DateTime.getMinute = function(this1) {
	return (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
};
datetime_DateTime.getSecond = function(this1) {
	return this1 - Math.floor(this1 / 60) * 60 | 0;
};
datetime_DateTime.add = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeUtils.addYear(this1 - 62135596800.0 + 62135596800.0,period.n);
		break;
	case 1:
		time = datetime_utils_DateTimeUtils.addMonth(this1 - 62135596800.0 + 62135596800.0,period.n);
		break;
	case 2:
		time = this1 - 62135596800.0 + period.n * 86400;
		break;
	case 3:
		time = this1 - 62135596800.0 + period.n * 3600;
		break;
	case 4:
		time = this1 - 62135596800.0 + period.n * 60;
		break;
	case 5:
		time = this1 - 62135596800.0 + period.n;
		break;
	case 6:
		time = this1 - 62135596800.0 + period.n * 7 * 86400;
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.sub = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeUtils.addYear(this1 - 62135596800.0 + 62135596800.0,-period.n);
		break;
	case 1:
		time = datetime_utils_DateTimeUtils.addMonth(this1 - 62135596800.0 + 62135596800.0,-period.n);
		break;
	case 2:
		time = this1 - 62135596800.0 - period.n * 86400;
		break;
	case 3:
		time = this1 - 62135596800.0 - period.n * 3600;
		break;
	case 4:
		time = this1 - 62135596800.0 - period.n * 60;
		break;
	case 5:
		time = this1 - 62135596800.0 - period.n;
		break;
	case 6:
		time = this1 - 62135596800.0 - period.n * 7 * 86400;
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.snap = function(this1,period) {
	var time;
	switch(period._hx_index) {
	case 0:
		time = datetime_utils_DateTimeSnapUtils.snapYear(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 1:
		time = datetime_utils_DateTimeSnapUtils.snapMonth(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 2:
		time = datetime_utils_DateTimeSnapUtils.snapDay(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 3:
		time = datetime_utils_DateTimeSnapUtils.snapHour(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 4:
		time = datetime_utils_DateTimeSnapUtils.snapMinute(this1 - 62135596800.0 + 62135596800.0,period.direction);
		break;
	case 5:
		time = period.direction == 1 ? this1 - 62135596800.0 + 1 : this1 - 62135596800.0;
		break;
	case 6:
		time = datetime_utils_DateTimeSnapUtils.snapWeek(this1 - 62135596800.0 + 62135596800.0,period.direction,period.day);
		break;
	}
	return time + 62135596800.0;
};
datetime_DateTime.toString = function(this1) {
	var M = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var D = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
	var h = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
	var m = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
	var s = this1 - Math.floor(this1 / 60) * 60 | 0;
	return "" + datetime_DateTime.getYear(this1) + "-" + (M < 10 ? "0" + M : "" + M) + "-" + (D < 10 ? "0" + D : "" + D) + " " + (h < 10 ? "0" + h : "" + h) + ":" + (m < 10 ? "0" + m : "" + m) + ":" + (s < 10 ? "0" + s : "" + s);
};
datetime_DateTime.format = function(this1,format) {
	return datetime_utils_DateTimeUtils.strftime(this1 - 62135596800.0 + 62135596800.0,format);
};
datetime_DateTime.getTime = function(this1) {
	return this1 - 62135596800.0;
};
datetime_DateTime.getDate = function(this1) {
	return new Date((this1 - 62135596800.0) * 1000);
};
datetime_DateTime.gt = function(this1,dt) {
	return this1 - 62135596800.0 > dt - 62135596800.0;
};
datetime_DateTime.gte = function(this1,dt) {
	return this1 - 62135596800.0 >= dt - 62135596800.0;
};
datetime_DateTime.lt = function(this1,dt) {
	return this1 - 62135596800.0 < dt - 62135596800.0;
};
datetime_DateTime.lte = function(this1,dt) {
	return this1 - 62135596800.0 <= dt - 62135596800.0;
};
datetime_DateTime.eq = function(this1,dt) {
	return this1 - 62135596800.0 == dt - 62135596800.0;
};
datetime_DateTime.neq = function(this1,dt) {
	return this1 - 62135596800.0 != dt - 62135596800.0;
};
datetime_DateTime.mathPlus1 = function(this1,period) {
	return datetime_DateTime.add(this1,period);
};
datetime_DateTime.mathPlus2 = function(this1,period) {
	return datetime_DateTime.add(this1,period);
};
datetime_DateTime.mathPlus3 = function(this1,period) {
	this1 = datetime_DateTime.add(this1,period) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.mathMinus1 = function(this1,period) {
	return datetime_DateTime.sub(this1,period);
};
datetime_DateTime.mathMinus2 = function(this1,period) {
	this1 = datetime_DateTime.sub(this1,period) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.dtiCreate = function(this1,begin) {
	return datetime_DateTimeInterval.create(begin,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiMinus = function(this1,dti) {
	return datetime_DateTimeInterval.subFrom(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiPlus1 = function(this1,dti) {
	return datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiPlus2 = function(this1,dti) {
	return datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0);
};
datetime_DateTime.dtiMinus2 = function(this1,dti) {
	this1 = datetime_DateTimeInterval.subFrom(dti,this1 - 62135596800.0 + 62135596800.0) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
datetime_DateTime.dtiPlus3 = function(this1,dti) {
	this1 = datetime_DateTimeInterval.addTo(dti,this1 - 62135596800.0 + 62135596800.0) - 62135596800.0 + 62135596800.0;
	return this1 + 62135596800.0;
};
var datetime_DateTimeInterval = {};
datetime_DateTimeInterval.create = function(begin,end) {
	var dtic = new datetime_cores_DateTimeIntervalCore();
	dtic.begin = end - 62135596800.0 < begin - 62135596800.0 ? end : begin;
	dtic.end = end - 62135596800.0 < begin - 62135596800.0 ? begin : end;
	dtic.negative = end - 62135596800.0 < begin - 62135596800.0;
	return dtic;
};
datetime_DateTimeInterval._new = function(dtic) {
	return dtic;
};
datetime_DateTimeInterval.invert = function(this1) {
	this1.negative = !this1.negative;
	return this1;
};
datetime_DateTimeInterval.addTo = function(this1,dt) {
	return dt - 62135596800.0 + (this1.negative ? -1 : 1) * (this1.end - 62135596800.0 - (this1.begin - 62135596800.0)) + 62135596800.0;
};
datetime_DateTimeInterval.subFrom = function(this1,dt) {
	return dt - 62135596800.0 - (this1.negative ? -1 : 1) * (this1.end - 62135596800.0 - (this1.begin - 62135596800.0)) + 62135596800.0;
};
datetime_DateTimeInterval.toString = function(this1) {
	var years = this1.getYears();
	var months = this1.getMonths();
	var days = this1.getDays();
	var hours = this1.getHours();
	var minutes = this1.getMinutes();
	var seconds = this1.getSeconds();
	var parts = [];
	if(years != 0) {
		parts.push("" + years + "y");
	}
	if(months != 0) {
		parts.push("" + months + "m");
	}
	if(days != 0) {
		parts.push("" + days + "d");
	}
	if(hours != 0) {
		parts.push("" + hours + "hrs");
	}
	if(minutes != 0) {
		parts.push("" + minutes + "min");
	}
	if(seconds != 0) {
		parts.push("" + seconds + "sec");
	}
	return (this1.negative ? "-" : "") + "(" + (parts.length == 0 ? "0sec" : parts.join(", ")) + ")";
};
datetime_DateTimeInterval.sign = function(this1) {
	if(this1.negative) {
		return -1;
	} else {
		return 1;
	}
};
datetime_DateTimeInterval.format = function(this1,format) {
	return datetime_utils_DateTimeIntervalUtils.strftime(this1,format);
};
datetime_DateTimeInterval.formatPartial = function(this1,format) {
	return datetime_utils_DateTimeIntervalUtils.formatPartial(this1,format);
};
datetime_DateTimeInterval.eq = function(this1,dtic) {
	if(this1.negative == dtic.negative) {
		return this1.getTotalSeconds() == dtic.getTotalSeconds();
	} else {
		return false;
	}
};
datetime_DateTimeInterval.gt = function(this1,dtic) {
	if(this1.negative != dtic.negative) {
		return dtic.negative;
	}
	var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
	if(this1.negative) {
		return delta < 0;
	} else {
		return delta > 0;
	}
};
datetime_DateTimeInterval.gte = function(this1,dtic) {
	if(!(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds())) {
		if(this1.negative != dtic.negative) {
			return dtic.negative;
		} else {
			var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
			if(this1.negative) {
				return delta < 0;
			} else {
				return delta > 0;
			}
		}
	} else {
		return true;
	}
};
datetime_DateTimeInterval.lt = function(this1,dtic) {
	var tmp;
	if(!(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds())) {
		if(this1.negative != dtic.negative) {
			tmp = dtic.negative;
		} else {
			var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
			tmp = this1.negative ? delta < 0 : delta > 0;
		}
	} else {
		tmp = true;
	}
	return !tmp;
};
datetime_DateTimeInterval.lte = function(this1,dtic) {
	var tmp;
	if(this1.negative != dtic.negative) {
		tmp = dtic.negative;
	} else {
		var delta = this1.getTotalSeconds() - dtic.getTotalSeconds();
		tmp = this1.negative ? delta < 0 : delta > 0;
	}
	return !tmp;
};
datetime_DateTimeInterval.neq = function(this1,dtic) {
	return !(this1.negative == dtic.negative && this1.getTotalSeconds() == dtic.getTotalSeconds());
};
var datetime_cores_DateTimeIntervalCore = function() {
	this.seconds = -1;
	this.minutes = -1;
	this.hours = -1;
	this.days = -1;
	this.months = -1;
	this.years = -1;
	this.negative = false;
};
datetime_cores_DateTimeIntervalCore.__name__ = true;
datetime_cores_DateTimeIntervalCore.prototype = {
	getYears: function() {
		if(this.years < 0) {
			this.years = datetime_DateTime.getYear(this.end) - datetime_DateTime.getYear(this.begin);
			var this1 = this.begin;
			var m1 = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var m2 = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			if(m2 < m1) {
				this.years--;
			} else if(m1 == m2) {
				var this1 = this.begin;
				var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
				var this1 = this.end;
				var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
				if(d2 < d1) {
					this.years--;
				} else if(d1 == d2) {
					var this1 = this.begin;
					var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
					var this1 = this.end;
					var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
					if(h2 < h1) {
						this.years--;
					} else if(h2 == h1) {
						var this1 = this.begin;
						var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
						var this1 = this.end;
						var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
						if(m2 < m1) {
							this.years--;
						} else {
							var tmp;
							if(m2 == m1) {
								var this1 = this.end;
								var this2 = this.begin;
								tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
							} else {
								tmp = false;
							}
							if(tmp) {
								this.years--;
							}
						}
					}
				}
			}
		}
		return this.years;
	}
	,getMonths: function() {
		if(this.months < 0) {
			var this1 = this.begin;
			var monthBegin = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var monthEnd = datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			this.months = monthBegin <= monthEnd ? monthEnd - monthBegin : 12 - monthBegin + monthEnd;
			var this1 = this.begin;
			var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			if(d2 < d1) {
				this.months--;
			} else if(d1 == d2) {
				var this1 = this.begin;
				var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
				var this1 = this.end;
				var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
				if(h2 < h1) {
					this.months--;
				} else if(h2 == h1) {
					var this1 = this.begin;
					var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
					var this1 = this.end;
					var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
					if(m2 < m1) {
						this.months--;
					} else {
						var tmp;
						if(m2 == m1) {
							var this1 = this.end;
							var this2 = this.begin;
							tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
						} else {
							tmp = false;
						}
						if(tmp) {
							this.months--;
						}
					}
				}
			}
		}
		return this.months;
	}
	,getTotalMonths: function() {
		return this.getYears() * 12 + this.getMonths();
	}
	,getDays: function() {
		if(this.days < 0) {
			var this1 = this.begin;
			var dayBegin = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var this1 = this.end;
			var dayEnd = datetime_utils_DateTimeMonthUtils.getMonthDay(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1));
			var tmp;
			if(dayBegin <= dayEnd) {
				tmp = dayEnd - dayBegin;
			} else {
				var this1 = this.begin;
				tmp = datetime_utils_DateTimeMonthUtils.days(datetime_utils_DateTimeMonthUtils.getMonth(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(this1)),datetime_DateTime.isLeapYear(this.begin)) - dayBegin + dayEnd;
			}
			this.days = tmp;
			var this1 = this.begin;
			var h1 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			var this1 = this.end;
			var h2 = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			if(h2 < h1) {
				this.days--;
			} else if(h2 == h1) {
				var this1 = this.begin;
				var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
				var this1 = this.end;
				var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
				if(m2 < m1) {
					this.days--;
				} else {
					var tmp;
					if(m2 == m1) {
						var this1 = this.end;
						var this2 = this.begin;
						tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
					} else {
						tmp = false;
					}
					if(tmp) {
						this.days--;
					}
				}
			}
		}
		return this.days;
	}
	,getTotalDays: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 86400 | 0;
	}
	,getHours: function() {
		if(this.hours < 0) {
			var this1 = this.begin;
			var hourBegin = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			var this1 = this.end;
			var hourEnd = (this1 - Math.floor(this1 / 86400) * 86400) / 3600 | 0;
			this.hours = hourBegin <= hourEnd ? hourEnd - hourBegin : 24 - hourBegin + hourEnd;
			var this1 = this.begin;
			var m1 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			var this1 = this.end;
			var m2 = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			if(m2 < m1) {
				this.hours--;
			} else {
				var tmp;
				if(m2 == m1) {
					var this1 = this.end;
					var this2 = this.begin;
					tmp = (this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0);
				} else {
					tmp = false;
				}
				if(tmp) {
					this.hours--;
				}
			}
		}
		return this.hours;
	}
	,getTotalHours: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 3600 | 0;
	}
	,getMinutes: function() {
		if(this.minutes < 0) {
			var this1 = this.begin;
			var minuteBegin = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			var this1 = this.end;
			var minuteEnd = (this1 - Math.floor(this1 / 3600) * 3600) / 60 | 0;
			this.minutes = minuteBegin <= minuteEnd ? minuteEnd - minuteBegin : 60 - minuteBegin + minuteEnd;
			var this1 = this.end;
			var this2 = this.begin;
			if((this1 - Math.floor(this1 / 60) * 60 | 0) < (this2 - Math.floor(this2 / 60) * 60 | 0)) {
				this.minutes--;
			}
		}
		return this.minutes;
	}
	,getTotalMinutes: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 60 | 0;
	}
	,getSeconds: function() {
		if(this.seconds < 0) {
			var this1 = this.begin;
			var secondBegin = this1 - Math.floor(this1 / 60) * 60 | 0;
			var this1 = this.end;
			var secondEnd = this1 - Math.floor(this1 / 60) * 60 | 0;
			this.seconds = secondBegin <= secondEnd ? secondEnd - secondBegin : 60 - secondBegin + secondEnd;
		}
		return this.seconds;
	}
	,getTotalSeconds: function() {
		return this.end - 62135596800.0 - (this.begin - 62135596800.0);
	}
	,getTotalWeeks: function() {
		return (this.end - 62135596800.0 - (this.begin - 62135596800.0)) / 604800 | 0;
	}
};
var datetime_utils_DateTimeIntervalUtils = function() {
};
datetime_utils_DateTimeIntervalUtils.__name__ = true;
datetime_utils_DateTimeIntervalUtils.strftime = function(dti,format) {
	var prevPos = 0;
	var pos = format.indexOf("%");
	var str = "";
	while(pos >= 0) {
		str += format.substring(prevPos,pos);
		++pos;
		switch(format.charCodeAt(pos)) {
		case 37:
			str += "%";
			break;
		case 68:
			str += StringTools.lpad(dti.getDays() + "","0",2);
			break;
		case 72:
			str += StringTools.lpad(dti.getHours() + "","0",2);
			break;
		case 73:
			str += StringTools.lpad(dti.getMinutes() + "","0",2);
			break;
		case 77:
			str += StringTools.lpad(dti.getMonths() + "","0",2);
			break;
		case 82:
			str += dti.negative ? "-" : "+";
			break;
		case 83:
			str += StringTools.lpad(dti.getSeconds() + "","0",2);
			break;
		case 89:
			str += StringTools.lpad(dti.getYears() + "","0",2);
			break;
		case 97:
			str += dti.getTotalDays() + "";
			break;
		case 98:
			str += dti.getTotalMonths() + "";
			break;
		case 99:
			str += dti.getTotalHours() + "";
			break;
		case 100:
			str += dti.getDays() + "";
			break;
		case 101:
			str += dti.getTotalMinutes() + "";
			break;
		case 102:
			str += dti.getTotalSeconds() + "";
			break;
		case 104:
			str += dti.getHours() + "";
			break;
		case 105:
			str += dti.getMinutes() + "";
			break;
		case 109:
			str += dti.getMonths() + "";
			break;
		case 114:
			str += dti.negative ? "-" : "";
			break;
		case 115:
			str += dti.getSeconds() + "";
			break;
		case 121:
			str += dti.getYears() + "";
			break;
		}
		prevPos = pos + 1;
		pos = format.indexOf("%",pos + 1);
	}
	str += format.substring(prevPos);
	return str;
};
datetime_utils_DateTimeIntervalUtils.formatPartial = function(dti,format) {
	var result = [];
	var pos = 0;
	var str = "";
	var _g = 0;
	var _g1 = format.length;
	while(_g < _g1) {
		var f = _g++;
		pos = format[f].indexOf("%");
		if(pos >= 0) {
			switch(format[f].charCodeAt(pos + 1)) {
			case 68:
				if(dti.getDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getDays() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 72:
				if(dti.getHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getHours() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 73:
				if(dti.getMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getMinutes() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 77:
				if(dti.getMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getMonths() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 83:
				if(dti.getSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getSeconds() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 89:
				if(dti.getYears() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + StringTools.lpad(dti.getYears() + "","0",2) + format[f].substring(pos + 2);
				break;
			case 97:
				if(dti.getTotalDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalDays() + format[f].substring(pos + 2);
				break;
			case 98:
				if(dti.getTotalMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalMonths() + format[f].substring(pos + 2);
				break;
			case 99:
				if(dti.getTotalHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalHours() + format[f].substring(pos + 2);
				break;
			case 100:
				if(dti.getDays() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getDays() + format[f].substring(pos + 2);
				break;
			case 101:
				if(dti.getTotalMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalMinutes() + format[f].substring(pos + 2);
				break;
			case 102:
				if(dti.getTotalSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getTotalSeconds() + format[f].substring(pos + 2);
				break;
			case 104:
				if(dti.getHours() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getHours() + format[f].substring(pos + 2);
				break;
			case 105:
				if(dti.getMinutes() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getMinutes() + format[f].substring(pos + 2);
				break;
			case 109:
				if(dti.getMonths() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getMonths() + format[f].substring(pos + 2);
				break;
			case 115:
				if(dti.getSeconds() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getSeconds() + format[f].substring(pos + 2);
				break;
			case 121:
				if(dti.getYears() == 0) {
					continue;
				}
				str = format[f].substring(0,pos) + dti.getYears() + format[f].substring(pos + 2);
				break;
			default:
				continue;
			}
			result.push(str);
		}
	}
	return result;
};
var datetime_utils_DateTimeMonthUtils = function() {
};
datetime_utils_DateTimeMonthUtils.__name__ = true;
datetime_utils_DateTimeMonthUtils.days = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(month == 1) {
		return 31;
	} else if(month == 2 && isLeapYear) {
		return 29;
	} else if(month == 2) {
		return 28;
	} else if(month == 3) {
		return 31;
	} else if(month == 4) {
		return 30;
	} else if(month == 5) {
		return 31;
	} else if(month == 6) {
		return 30;
	} else if(month == 7) {
		return 31;
	} else if(month == 8) {
		return 31;
	} else if(month == 9) {
		return 30;
	} else if(month == 10) {
		return 31;
	} else if(month == 11) {
		return 30;
	} else {
		return 31;
	}
};
datetime_utils_DateTimeMonthUtils.getMonth = function(days,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(days < 32) {
		return 1;
	} else if(isLeapYear) {
		if(days < 61) {
			return 2;
		} else if(days < 92) {
			return 3;
		} else if(days < 122) {
			return 4;
		} else if(days < 153) {
			return 5;
		} else if(days < 183) {
			return 6;
		} else if(days < 214) {
			return 7;
		} else if(days < 245) {
			return 8;
		} else if(days < 275) {
			return 9;
		} else if(days < 306) {
			return 10;
		} else if(days < 336) {
			return 11;
		} else {
			return 12;
		}
	} else if(days < 60) {
		return 2;
	} else if(days < 91) {
		return 3;
	} else if(days < 121) {
		return 4;
	} else if(days < 152) {
		return 5;
	} else if(days < 182) {
		return 6;
	} else if(days < 213) {
		return 7;
	} else if(days < 244) {
		return 8;
	} else if(days < 274) {
		return 9;
	} else if(days < 305) {
		return 10;
	} else if(days < 335) {
		return 11;
	} else {
		return 12;
	}
};
datetime_utils_DateTimeMonthUtils.getMonthDay = function(days,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	if(days < 32) {
		return days;
	} else if(isLeapYear) {
		if(days < 61) {
			return days - 31;
		} else if(days < 92) {
			return days - 60;
		} else if(days < 122) {
			return days - 91;
		} else if(days < 153) {
			return days - 121;
		} else if(days < 183) {
			return days - 152;
		} else if(days < 214) {
			return days - 182;
		} else if(days < 245) {
			return days - 213;
		} else if(days < 275) {
			return days - 244;
		} else if(days < 306) {
			return days - 274;
		} else if(days < 336) {
			return days - 305;
		} else {
			return days - 335;
		}
	} else if(days < 60) {
		return days - 31;
	} else if(days < 91) {
		return days - 59;
	} else if(days < 121) {
		return days - 90;
	} else if(days < 152) {
		return days - 120;
	} else if(days < 182) {
		return days - 151;
	} else if(days < 213) {
		return days - 181;
	} else if(days < 244) {
		return days - 212;
	} else if(days < 274) {
		return days - 243;
	} else if(days < 305) {
		return days - 273;
	} else if(days < 335) {
		return days - 304;
	} else {
		return days - 334;
	}
};
datetime_utils_DateTimeMonthUtils.toSeconds = function(month,isLeapYear) {
	if(isLeapYear == null) {
		isLeapYear = false;
	}
	return 86400 * (month == 1 ? 0 : isLeapYear ? month == 2 ? 31 : month == 3 ? 60 : month == 4 ? 91 : month == 5 ? 121 : month == 6 ? 152 : month == 7 ? 182 : month == 8 ? 213 : month == 9 ? 244 : month == 10 ? 274 : month == 11 ? 305 : 335 : month == 2 ? 31 : month == 3 ? 59 : month == 4 ? 90 : month == 5 ? 120 : month == 6 ? 151 : month == 7 ? 181 : month == 8 ? 212 : month == 9 ? 243 : month == 10 ? 273 : month == 11 ? 304 : 334);
};
var datetime_utils_DateTimeSnapUtils = function() {
};
datetime_utils_DateTimeSnapUtils.__name__ = true;
datetime_utils_DateTimeSnapUtils.snapYear = function(dt,direction) {
	switch(direction) {
	case -1:
		return datetime_DateTime.yearStart(dt);
	case 0:
		var next = datetime_DateTime.yearStart(datetime_utils_DateTimeUtils.addYear(dt,1) + 62135596800.0);
		var previous = datetime_DateTime.yearStart(dt);
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
	case 1:
		return datetime_DateTime.yearStart(datetime_utils_DateTimeUtils.addYear(dt,1) + 62135596800.0);
	}
};
datetime_utils_DateTimeSnapUtils.snapMonth = function(dt,direction) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	var isLeap = datetime_DateTime.isLeapYear(dt);
	switch(direction) {
	case -1:
		return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap);
	case 0:
		var previous = datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap);
		var next = datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + datetime_utils_DateTimeMonthUtils.days(month,isLeap) * 86400;
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
	case 1:
		return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + datetime_utils_DateTimeMonthUtils.days(month,isLeap) * 86400;
	}
};
datetime_utils_DateTimeSnapUtils.snapDay = function(dt,direction) {
	var days = (dt - 62135596800.0) / 86400;
	switch(direction) {
	case -1:
		return Math.floor(days) * 86400;
	case 0:
		return Math.round(days) * 86400;
	case 1:
		return Math.ceil(days) * 86400;
	}
};
datetime_utils_DateTimeSnapUtils.snapHour = function(dt,direction) {
	var hours = (dt - 62135596800.0) / 3600;
	switch(direction) {
	case -1:
		return Math.floor(hours) * 3600;
	case 0:
		return Math.round(hours) * 3600;
	case 1:
		return Math.ceil(hours) * 3600;
	}
};
datetime_utils_DateTimeSnapUtils.snapMinute = function(dt,direction) {
	var minutes = (dt - 62135596800.0) / 60;
	switch(direction) {
	case -1:
		return Math.floor(minutes) * 60;
	case 0:
		return Math.round(minutes) * 60;
	case 1:
		return Math.ceil(minutes) * 60;
	}
};
datetime_utils_DateTimeSnapUtils.snapWeek = function(dt,direction,required) {
	var current = datetime_DateTime.getWeekDay(dt);
	var days = Math.floor((dt - 62135596800.0) / 86400);
	switch(direction) {
	case -1:
		return (days - (current >= required ? current - required : current + 7 - required)) * 86400;
	case 0:
		var previous = (days - (current >= required ? current - required : current + 7 - required)) * 86400;
		var next = (days + (required > current ? required - current : required + 7 - current)) * 86400;
		if(next - (dt - 62135596800.0) > dt - 62135596800.0 - previous) {
			return previous;
		} else {
			return next;
		}
	case 1:
		return (days + (required > current ? required - current : required + 7 - current)) * 86400;
	}
};
var datetime_utils_DateTimeUtils = function() {
};
datetime_utils_DateTimeUtils.__name__ = true;
datetime_utils_DateTimeUtils.fromString = function(str) {
	if(str.length == 10 || str.charCodeAt(10) == 32) {
		return datetime_utils_DateTimeUtils.parse(str);
	} else if(str.charCodeAt(10) == 84) {
		return datetime_utils_DateTimeUtils.fromIsoString(str);
	} else {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD` or `YYYY-MM-DDThh:mm:ss[.SSS]Z`");
	}
};
datetime_utils_DateTimeUtils.parse = function(str) {
	var ylength = str.indexOf("-");
	if(ylength < 1 || str.length - ylength != 6 && str.length - ylength != 15) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	if(str.length - ylength == 6) {
		str += " 00:00:00";
	}
	var year = Std.parseInt(HxOverrides.substr(str,0,ylength));
	var month = Std.parseInt(HxOverrides.substr(str,ylength + 1,2));
	var day = Std.parseInt(HxOverrides.substr(str,ylength + 4,2));
	var hour = Std.parseInt(HxOverrides.substr(str,ylength + 7,2));
	var minute = Std.parseInt(HxOverrides.substr(str,ylength + 10,2));
	var second = Std.parseInt(HxOverrides.substr(str,ylength + 13,2));
	if(year == null || month == null || day == null || hour == null || minute == null || second == null) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Should be either `YYYY-MM-DD hh:mm:ss` or `YYYY-MM-DD`");
	}
	var year1 = year;
	var month1 = month;
	var day1 = day;
	var hour1 = hour;
	var minute1 = minute;
	var second1 = second;
	if(second == null) {
		second1 = 0;
	}
	if(minute == null) {
		minute1 = 0;
	}
	if(hour == null) {
		hour1 = 0;
	}
	if(day == null) {
		day1 = 1;
	}
	if(month == null) {
		month1 = 1;
	}
	if(year == null) {
		year1 = 1970;
	}
	return datetime_utils_DateTimeUtils.yearToStamp(year1) + datetime_utils_DateTimeMonthUtils.toSeconds(month1,year1 % 4 == 0 && (year1 % 100 == 0 ? year1 % 400 == 0 : true)) + (day1 - 1) * 86400 + hour1 * 3600 + minute1 * 60 + second1 - 62135596800.0 + 62135596800.0;
};
datetime_utils_DateTimeUtils.fromIsoString = function(str) {
	if(str.charCodeAt(str.length - 1) != 90) {
		throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 UTC/Zulu string: Z not found.");
	}
	if(str.length > 20) {
		if(str.charCodeAt(19) != 46) {
			throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: Millisecond specification erroneous.");
		}
		if(str.charCodeAt(23) != 90) {
			throw haxe_Exception.thrown("`" + str + "` - incorrect date/time format. Not an ISO 8601 string: Timezone specification erroneous.");
		}
	}
	return datetime_utils_DateTimeUtils.parse(HxOverrides.substr(str,0,10) + " " + HxOverrides.substr(str,11,8));
};
datetime_utils_DateTimeUtils.clamp = function(value,min,max) {
	if(value < min) {
		return min;
	} else if(value > max) {
		return max;
	} else {
		return value;
	}
};
datetime_utils_DateTimeUtils.yearToStamp = function(year) {
	--year;
	var cquads = year / 400 | 0;
	var quads = (year - cquads * 400) / 4 | 0;
	return cquads * 12622780800.0 + quads * 126230400.0 - (quads / 25 | 0) * 86400 + (year - cquads * 400 - quads * 4) * 31536000;
};
datetime_utils_DateTimeUtils.addYear = function(dt,amount) {
	var year = datetime_DateTime.getYear(dt) + amount;
	return datetime_utils_DateTimeUtils.yearToStamp(year) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),year % 4 == 0 && (year % 100 == 0 ? year % 400 == 0 : true)) + (dt - 62135596800.0 - (datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)),datetime_DateTime.isLeapYear(dt)))) - 62135596800.0;
};
datetime_utils_DateTimeUtils.addMonth = function(dt,amount) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + amount;
	if(month > 12) {
		var years = month / 12 | 0;
		dt = datetime_utils_DateTimeUtils.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	} else if(month <= 0) {
		var years = (month / 12 | 0) - 1;
		dt = datetime_utils_DateTimeUtils.addYear(dt,years) + 62135596800.0;
		month -= years * 12;
	}
	var isLeap = datetime_DateTime.isLeapYear(dt);
	var value = datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	var max = datetime_utils_DateTimeMonthUtils.days(month,isLeap);
	return datetime_DateTime.yearStart(dt) + datetime_utils_DateTimeMonthUtils.toSeconds(month,isLeap) + ((value < 1 ? 1 : value > max ? max : value) - 1) * 86400 + ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) * 3600 + ((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) * 60 + (dt - Math.floor(dt / 60) * 60 | 0);
};
datetime_utils_DateTimeUtils.getWeekDayNum = function(dt,weekDay,num) {
	var month = datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt));
	if(num > 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month) - 1 + 62135596800.0,datetime_DTSnap.Week(1,weekDay)),datetime_DTPeriod.Week(num - 1)) - 62135596800.0;
	} else if(num < 0) {
		return datetime_DateTime.add(datetime_DateTime.snap(datetime_DateTime.monthStart(dt,month + 1) - 1 + 62135596800.0,datetime_DTSnap.Week(-1,weekDay)),datetime_DTPeriod.Week(num + 1)) - 62135596800.0;
	} else {
		return dt - 62135596800.0;
	}
};
datetime_utils_DateTimeUtils.strftime = function(dt,format) {
	var prevPos = 0;
	var pos = format.indexOf("%");
	var str = "";
	while(pos >= 0) {
		str += format.substring(prevPos,pos);
		++pos;
		switch(format.charCodeAt(pos)) {
		case 37:
			str += "%";
			break;
		case 67:
			str += StringTools.lpad((datetime_DateTime.getYear(dt) / 100 | 0) + "","0",2);
			break;
		case 68:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "/","0",3) + StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		case 70:
			str += datetime_DateTime.getYear(dt) + "-" + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "-","0",3) + StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 72:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + "","0",2);
			break;
		case 73:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + "","0",2);
			break;
		case 77:
			str += StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 80:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "am" : "pm";
			break;
		case 82:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + "","0",2);
			break;
		case 83:
			str += StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 84:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 86:
			str += StringTools.lpad(datetime_DateTime.getWeek(dt) + "","0",2);
			break;
		case 89:
			str += datetime_DateTime.getYear(dt) + "";
			break;
		case 100:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 101:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonthDay(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + ""," ",2);
			break;
		case 106:
			str += StringTools.lpad("" + (((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1),"0",3);
			break;
		case 107:
			str += StringTools.lpad(((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) + ""," ",2);
			break;
		case 108:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ""," ",2);
			break;
		case 109:
			str += StringTools.lpad(datetime_utils_DateTimeMonthUtils.getMonth(((dt - 62135596800.0 - datetime_DateTime.yearStart(dt)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(dt)) + "","0",2);
			break;
		case 112:
			str += ((dt - Math.floor(dt / 86400) * 86400) / 3600 | 0) < 12 ? "AM" : "PM";
			break;
		case 114:
			str += StringTools.lpad(datetime_DateTime.getHour12(dt) + ":","0",3) + StringTools.lpad(((dt - Math.floor(dt / 3600) * 3600) / 60 | 0) + ":","0",3) + StringTools.lpad((dt - Math.floor(dt / 60) * 60 | 0) + "","0",2);
			break;
		case 115:
			str += dt - 62135596800.0 + "";
			break;
		case 117:
			str += datetime_DateTime.getWeekDay(dt,true) + "";
			break;
		case 119:
			str += datetime_DateTime.getWeekDay(dt) + "";
			break;
		case 121:
			str += StringTools.lpad(HxOverrides.substr(datetime_DateTime.getYear(dt) + "",-2,null),"0",2);
			break;
		}
		prevPos = pos + 1;
		pos = format.indexOf("%",pos + 1);
	}
	str += format.substring(prevPos);
	return str;
};
var formula_TypeSlot = $hxEnums["formula.TypeSlot"] = { __ename__:true,__constructs__:null
	,TCon: ($_=function(type) { return {_hx_index:0,type:type,__enum__:"formula.TypeSlot",toString:$estr}; },$_._hx_name="TCon",$_.__params__ = ["type"],$_)
	,TVar: ($_=function(id) { return {_hx_index:1,id:id,__enum__:"formula.TypeSlot",toString:$estr}; },$_._hx_name="TVar",$_.__params__ = ["id"],$_)
};
formula_TypeSlot.__constructs__ = [formula_TypeSlot.TCon,formula_TypeSlot.TVar];
var formula_ValueType = $hxEnums["formula.ValueType"] = { __ename__:true,__constructs__:null
	,TNumber: {_hx_name:"TNumber",_hx_index:0,__enum__:"formula.ValueType",toString:$estr}
	,TString: {_hx_name:"TString",_hx_index:1,__enum__:"formula.ValueType",toString:$estr}
	,TBoolean: {_hx_name:"TBoolean",_hx_index:2,__enum__:"formula.ValueType",toString:$estr}
	,TDate: {_hx_name:"TDate",_hx_index:3,__enum__:"formula.ValueType",toString:$estr}
};
formula_ValueType.__constructs__ = [formula_ValueType.TNumber,formula_ValueType.TString,formula_ValueType.TBoolean,formula_ValueType.TDate];
var formula_Value = $hxEnums["formula.Value"] = { __ename__:true,__constructs__:null
	,VNumber: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VNumber",$_.__params__ = ["value"],$_)
	,VString: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VString",$_.__params__ = ["value"],$_)
	,VBoolean: ($_=function(value) { return {_hx_index:2,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VBoolean",$_.__params__ = ["value"],$_)
	,VDate: ($_=function(value) { return {_hx_index:3,value:value,__enum__:"formula.Value",toString:$estr}; },$_._hx_name="VDate",$_.__params__ = ["value"],$_)
};
formula_Value.__constructs__ = [formula_Value.VNumber,formula_Value.VString,formula_Value.VBoolean,formula_Value.VDate];
var formula_ValueTool = $hx_exports["formula"]["ValueTool"] = function() { };
formula_ValueTool.__name__ = true;
formula_ValueTool.typeOf = function(value) {
	switch(value._hx_index) {
	case 0:
		return formula_ValueType.TNumber;
	case 1:
		return formula_ValueType.TString;
	case 2:
		return formula_ValueType.TBoolean;
	case 3:
		return formula_ValueType.TDate;
	}
};
formula_ValueTool.number = function(value) {
	return formula_Value.VNumber(value);
};
formula_ValueTool.string = function(value) {
	return formula_Value.VString(value);
};
formula_ValueTool.boolean = function(value) {
	return formula_Value.VBoolean(value);
};
formula_ValueTool.date = function(value) {
	return formula_Value.VDate(value);
};
formula_ValueTool.fromDynamic = function(value) {
	if(((value) instanceof Date)) {
		return formula_Value.VDate(value);
	}
	if(typeof(value) == "string") {
		return formula_Value.VString(value);
	}
	if(typeof(value) == "boolean") {
		return formula_Value.VBoolean(value);
	}
	return formula_Value.VNumber(value);
};
formula_ValueTool.toDynamic = function(value) {
	switch(value._hx_index) {
	case 0:
		return value.value;
	case 1:
		return value.value;
	case 2:
		return value.value;
	case 3:
		return value.value;
	}
};
formula_ValueTool.asNumber = function(value) {
	if(value._hx_index == 0) {
		return value.value;
	} else {
		// throw new haxe_Exception("");
		return ''
	}
};
formula_ValueTool.asString = function(value) {
	if(value == null) {
		return "";
	} else if(value._hx_index == 1) {
		return value.value;
	} else {
		// throw new haxe_Exception("");
		return ''
	}
};
formula_ValueTool.asBoolean = function(value) {
	if(value == null) {
		return false;
	} else if(value._hx_index == 2) {
		return value.value;
	} else {
		// throw new haxe_Exception("");
		return ''
	}
};
formula_ValueTool.asDate = function(value) {
	if(value._hx_index == 3) {
		return value.value;
	} else {
		// throw new haxe_Exception("");
		return ''
	}
};
formula_ValueTool.formatFloat = function(f) {
	var s = f == null ? "null" : "" + f;
	var k = s.lastIndexOf(".");
	var numFracDigits = s.length - k - 1;
	if(k >= 0 && numFracDigits >= 10) {
		var q = Math.pow(10,Math.max(10,numFracDigits - 6));
		var s2 = Std.string(Math.round(f * q) / q);
		if(s.length - s2.length >= numFracDigits - 12 && s.length - s2.length >= 4) {
			return s2;
		}
	}
	return Std.string(Math.round(f * 1e12) / 1e12);
};
formula_ValueTool.toString = function(value) {
	if(value == null) {
		return "";
	} else {
		switch(value._hx_index) {
		case 0:
			var _g = value.value;
			if(isNaN(_g)) {
				return "";
			} else {
				return formula_ValueTool.formatFloat(_g);
			}
		case 1:
			return value.value;
		case 2:
			if(value.value) {
				return "true";
			} else {
				return "false";
			}
		case 3:
			return HxOverrides.dateStr(value.value);
		}
	}
};
formula_ValueTool.nameOfType = function(type) {
	switch(type._hx_index) {
	case 0:
		return "number";
	case 1:
		return "string";
	case 2:
		return "boolean";
	case 3:
		return "date";
	}
};
var haxe_Exception = function(message,previous,native) {
	Error.call(this,message);
	this.message = message;
	this.__previousException = previous;
	this.__nativeException = native != null ? native : this;
};
haxe_Exception.__name__ = true;
haxe_Exception.caught = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value;
	} else if(((value) instanceof Error)) {
		return new haxe_Exception(value.message,null,value);
	} else {
		return new haxe_ValueException(value,null,value);
	}
};
haxe_Exception.thrown = function(value) {
	if(((value) instanceof haxe_Exception)) {
		return value.get_native();
	} else if(((value) instanceof Error)) {
		return value;
	} else {
		var e = new haxe_ValueException(value);
		return e;
	}
};
haxe_Exception.__super__ = Error;
haxe_Exception.prototype = $extend(Error.prototype,{
	unwrap: function() {
		return this.__nativeException;
	}
	,toString: function() {
		return this.get_message();
	}
	,get_message: function() {
		return this.message;
	}
	,get_native: function() {
		return this.__nativeException;
	}
});
var formula_Compare = function() { };
formula_Compare.__name__ = true;
formula_Compare.compareNumber = function(v1,v2) {
	return v1 - v2;
};
formula_Compare.compareString = function(v1,v2) {
	var len = Math.min(v1.length,v2.length) | 0;
	var _g = 0;
	while(_g < len) {
		var i = _g++;
		var c1 = HxOverrides.cca(v1,i);
		var c2 = HxOverrides.cca(v2,i);
		if(c1 < c2) {
			return -1;
		}
		if(c1 > c2) {
			return 1;
		}
	}
	return v1.length - v2.length;
};
formula_Compare.compareBoolean = function(v1,v2) {
	if(v1) {
		if(v2) {
			return 0;
		} else {
			return 1;
		}
	} else if(v2) {
		return -1;
	} else {
		return 0;
	}
};
formula_Compare.compareDate = function(v1,v2) {
	return formula_Compare.compareNumber(v1.getTime(),v2.getTime());
};
var js_Boot = function() { };
js_Boot.__name__ = true;
js_Boot.__string_rec = function(o,s) {
	if(o == null) {
		return "null";
	}
	if(s.length >= 5) {
		return "<...>";
	}
	var t = typeof(o);
	if(t == "function" && (o.__name__ || o.__ename__)) {
		t = "object";
	}
	switch(t) {
	case "function":
		return "<function>";
	case "object":
		if(o.__enum__) {
			var e = $hxEnums[o.__enum__];
			var con = e.__constructs__[o._hx_index];
			var n = con._hx_name;
			if(con.__params__) {
				s = s + "\t";
				return n + "(" + ((function($this) {
					var $r;
					var _g = [];
					{
						var _g1 = 0;
						var _g2 = con.__params__;
						while(true) {
							if(!(_g1 < _g2.length)) {
								break;
							}
							var p = _g2[_g1];
							_g1 = _g1 + 1;
							_g.push(js_Boot.__string_rec(o[p],s));
						}
					}
					$r = _g;
					return $r;
				}(this))).join(",") + ")";
			} else {
				return n;
			}
		}
		if(((o) instanceof Array)) {
			var str = "[";
			s += "\t";
			var _g = 0;
			var _g1 = o.length;
			while(_g < _g1) {
				var i = _g++;
				str += (i > 0 ? "," : "") + js_Boot.__string_rec(o[i],s);
			}
			str += "]";
			return str;
		}
		var tostr;
		try {
			tostr = o.toString;
		} catch( _g ) {
			return "???";
		}
		if(tostr != null && tostr != Object.toString && typeof(tostr) == "function") {
			var s2 = o.toString();
			if(s2 != "[object Object]") {
				return s2;
			}
		}
		var str = "{\n";
		s += "\t";
		var hasp = o.hasOwnProperty != null;
		var k = null;
		for( k in o ) {
		if(hasp && !o.hasOwnProperty(k)) {
			continue;
		}
		if(k == "prototype" || k == "__class__" || k == "__super__" || k == "__interfaces__" || k == "__properties__") {
			continue;
		}
		if(str.length != 2) {
			str += ", \n";
		}
		str += s + k + " : " + js_Boot.__string_rec(o[k],s);
		}
		s = s.substring(1);
		str += "\n" + s + "}";
		return str;
	case "string":
		return o;
	default:
		return String(o);
	}
};
var haxe_exceptions_PosException = function(message,previous,pos) {
	haxe_Exception.call(this,message,previous);
	if(pos == null) {
		this.posInfos = { fileName : "(unknown)", lineNumber : 0, className : "(unknown)", methodName : "(unknown)"};
	} else {
		this.posInfos = pos;
	}
};
haxe_exceptions_PosException.__name__ = true;
haxe_exceptions_PosException.__super__ = haxe_Exception;
haxe_exceptions_PosException.prototype = $extend(haxe_Exception.prototype,{
	toString: function() {
		return "" + haxe_Exception.prototype.toString.call(this) + " in " + this.posInfos.className + "." + this.posInfos.methodName + " at " + this.posInfos.fileName + ":" + this.posInfos.lineNumber;
	}
});
var haxe_exceptions_NotImplementedException = function(message,previous,pos) {
	if(message == null) {
		message = "Not implemented";
	}
	haxe_exceptions_PosException.call(this,message,previous,pos);
};
haxe_exceptions_NotImplementedException.__name__ = true;
haxe_exceptions_NotImplementedException.__super__ = haxe_exceptions_PosException;
haxe_exceptions_NotImplementedException.prototype = $extend(haxe_exceptions_PosException.prototype,{
});
var formula_IntervalFix = function() { };
formula_IntervalFix.__name__ = true;
formula_IntervalFix.getTotalMonthsFix = function(interval) {
	var begin = interval.begin;
	var end = interval.end;
	var months = (datetime_DateTime.getYear(end) - datetime_DateTime.getYear(begin)) * 12 + (datetime_utils_DateTimeMonthUtils.getMonth(((end - 62135596800.0 - datetime_DateTime.yearStart(end)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(end)) - datetime_utils_DateTimeMonthUtils.getMonth(((begin - 62135596800.0 - datetime_DateTime.yearStart(begin)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(begin)));
	var d1 = datetime_utils_DateTimeMonthUtils.getMonthDay(((begin - 62135596800.0 - datetime_DateTime.yearStart(begin)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(begin));
	var d2 = datetime_utils_DateTimeMonthUtils.getMonthDay(((end - 62135596800.0 - datetime_DateTime.yearStart(end)) / 86400 | 0) + 1,datetime_DateTime.isLeapYear(end));
	if(d2 < d1) {
		--months;
	} else if(d1 == d2) {
		var h1 = (begin - Math.floor(begin / 86400) * 86400) / 3600 | 0;
		var h2 = (end - Math.floor(end / 86400) * 86400) / 3600 | 0;
		if(h2 < h1) {
			--months;
		} else if(h2 == h1) {
			var m1 = (begin - Math.floor(begin / 3600) * 3600) / 60 | 0;
			var m2 = (end - Math.floor(end / 3600) * 3600) / 60 | 0;
			if(m2 < m1) {
				--months;
			} else if(m2 == m1 && (end - Math.floor(end / 60) * 60 | 0) < (begin - Math.floor(begin / 60) * 60 | 0)) {
				--months;
			}
		}
	}
	return months;
};
formula_IntervalFix.getLocalWeek = function(date) {
	return datetime_DateTime.getWeek(date - 62135596800.0 + datetime_DateTime.getLocalOffset() + 62135596800.0);
};
var formula_Builtins = function() {
	this.constMap = new haxe_ds_StringMap();
	this.funcMap = new haxe_ds_StringMap();
	var numTy = formula_TypeSlot.TCon(formula_ValueType.TNumber);
	var strTy = formula_TypeSlot.TCon(formula_ValueType.TString);
	var boolTy = formula_TypeSlot.TCon(formula_ValueType.TBoolean);
	var dateTy = formula_TypeSlot.TCon(formula_ValueType.TDate);
	this.constMap.h["e"] = formula_Value.VNumber(2.718281828459045);
	this.constMap.h["pi"] = formula_Value.VNumber(3.141592653589793);
	this.constMap.h["true"] = formula_Value.VBoolean(true);
	this.constMap.h["false"] = formula_Value.VBoolean(false);
	this.funcMap.h["prop"] = { type : formula_Builtins.fnTy(null,[strTy]), 'eval' : null};
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(-formula_ValueTool.asNumber(args[0]));
	}};
	this.funcMap.h["unaryMinus"] = value;
	this.funcMap.h["unaryPlus"] = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return args[0];
	}};
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) + formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["add"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) - formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["subtract"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) * formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["multiply"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) / formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["divide"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asNumber(args[0]) % formula_ValueTool.asNumber(args[1]));
	}};
	this.funcMap.h["mod"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		var _g = formula_ValueTool.asNumber(args[0]);
		return formula_Value.VNumber(_g > 0 ? 1 : _g < 0 ? -1 : 0);
	}};
	this.funcMap.h["sign"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.abs(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["abs"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.floor(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["floor"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.ceil(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["ceil"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.round(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["round"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[numTy],numTy), 'eval' : function(args) {
		var result = new Array(args.length);
		var _g = 0;
		var _g1 = args.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asNumber(args[i]);
		}
		return formula_Value.VNumber(Lambda.fold(result.slice(1),Math.max,result[0]));
	}};
	this.funcMap.h["max"] = value;
	var value = { type : formula_Builtins.vaFnTy(numTy,[numTy],numTy), 'eval' : function(args) {
		var result = new Array(args.length);
		var _g = 0;
		var _g1 = args.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asNumber(args[i]);
		}
		return formula_Value.VNumber(Lambda.fold(result.slice(1),Math.min,result[0]));
	}};
	this.funcMap.h["min"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy,numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.pow(formula_ValueTool.asNumber(args[0]),formula_ValueTool.asNumber(args[1])));
	}};
	this.funcMap.h["pow"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.exp(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["exp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["ln"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])) * 1.4426950408889634);
	}};
	this.funcMap.h["log2"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.log(formula_ValueTool.asNumber(args[0])) * 0.4342944819032518);
	}};
	this.funcMap.h["log10"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.sqrt(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["sqrt"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[numTy]), 'eval' : function(args) {
		var arg = formula_ValueTool.asNumber(args[0]);
		return formula_Value.VNumber(arg < 0 ? -Math.pow(-arg,0.333333333333333315) : Math.pow(arg,0.333333333333333315));
	}};
	this.funcMap.h["cbrt"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,numTy,[formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		var _g = args[0];
		switch(_g._hx_index) {
		case 0:
			return formula_Value.VNumber(_g.value);
		case 1:
			var _g1 = _g.value;
			if(_g1 == "") {
				return null;
			} else {
				var num = parseFloat(StringTools.trim(_g1));
				if(isNaN(num)) {
					return null;
				} else {
					return formula_Value.VNumber(num);
				}
			}
		case 2:
			return formula_Value.VNumber(_g.value ? 1 : 0);
		case 3:
			return formula_Value.VNumber(_g.value.getTime());
		}
	}};
	this.funcMap.h["toNumber"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	this.funcMap.h["if"] = { type : formula_Builtins.genFnTy(_g,formula_TypeSlot.TVar(0),[boolTy,formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : null};
	this.funcMap.h["and"] = { type : formula_Builtins.fnTy(boolTy,[boolTy,boolTy]), 'eval' : null};
	this.funcMap.h["or"] = { type : formula_Builtins.fnTy(boolTy,[boolTy,boolTy]), 'eval' : null};
	var value = { type : formula_Builtins.fnTy(boolTy,[boolTy]), 'eval' : function(args) {
		return formula_Value.VBoolean(!formula_ValueTool.asBoolean(args[0]));
	}};
	this.funcMap.h["not"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		var _g = args[0];
		var value;
		if(_g == null) {
			value = true;
		} else {
			switch(_g._hx_index) {
			case 0:
				value = isNaN(_g.value);
				break;
			case 1:
				value = _g.value == "";
				break;
			case 2:
				value = _g.value == false;
				break;
			default:
				value = false;
			}
		}
		return formula_Value.VBoolean(value);
	}};
	this.funcMap.h["empty"] = value;
	var compare = function(v1,v2) {
		switch(v1._hx_index) {
		case 0:
			return formula_Compare.compareNumber(formula_ValueTool.asNumber(v1),formula_ValueTool.asNumber(v2));
		case 1:
			return formula_Compare.compareString(formula_ValueTool.asString(v1),formula_ValueTool.asString(v2));
		case 2:
			return formula_Compare.compareBoolean(formula_ValueTool.asBoolean(v1),formula_ValueTool.asBoolean(v2));
		case 3:
			return formula_Compare.compareDate(formula_ValueTool.asDate(v1),formula_ValueTool.asDate(v2));
		}
	};
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) == 0);
	}};
	this.funcMap.h["equal"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) != 0);
	}};
	this.funcMap.h["unequal"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) < 0);
	}};
	this.funcMap.h["smaller"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) <= 0);
	}};
	this.funcMap.h["smallerEq"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) > 0);
	}};
	this.funcMap.h["larger"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,boolTy,[formula_TypeSlot.TVar(0),formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VBoolean(compare(args[0],args[1]) >= 0);
	}};
	this.funcMap.h["largerEq"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[strTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asString(args[0]).length);
	}};
	this.funcMap.h["length"] = value;
	var value = { type : formula_Builtins.fnTy(strTy,[strTy,numTy],[numTy]), 'eval' : function(args) {
		var arg1 = formula_ValueTool.asString(args[0]);
		var arg2 = formula_ValueTool.asNumber(args[1]) | 0;
		if(arg2 < 0) {
			arg2 = Math.max(0,arg1.length + arg2) | 0;
		}
		if(args.length == 2) {
			return formula_Value.VString(arg1.substring(arg2,arg1.length));
		} else {
			var arg3 = formula_ValueTool.asNumber(args[2]) | 0;
			if(arg3 < 0) {
				arg3 = Math.max(0,arg1.length + arg3) | 0;
			}
			return formula_Value.VString(arg2 >= arg3 ? "" : arg1.substring(arg2,arg3));
		}
	}};
	this.funcMap.h["slice"] = value;
	var _g = new haxe_ds_IntMap();
	_g.h[0] = null;
	var value = { type : formula_Builtins.genFnTy(_g,strTy,[formula_TypeSlot.TVar(0)]), 'eval' : function(args) {
		return formula_Value.VString(formula_ValueTool.toString(args[0]));
	}};
	this.funcMap.h["format"] = value;
	var value = { type : formula_Builtins.vaFnTy(strTy,[],strTy), 'eval' : function(args) {
		if(args.length == 2) {
			return formula_Value.VString(formula_ValueTool.asString(args[0]) + formula_ValueTool.asString(args[1]));
		} else {
			var result = new Array(args.length);
			var _g = 0;
			var _g1 = args.length;
			while(_g < _g1) {
				var i = _g++;
				result[i] = formula_ValueTool.asString(args[i]);
			}
			return formula_Value.VString(result.join(""));
		}
	}};
	this.funcMap.h["concat"] = value;
	var value = { type : formula_Builtins.vaFnTy(strTy,[strTy],strTy), 'eval' : function(args) {
		var _this = args.slice(1);
		var result = new Array(_this.length);
		var _g = 0;
		var _g1 = _this.length;
		while(_g < _g1) {
			var i = _g++;
			result[i] = formula_ValueTool.asString(_this[i]);
		}
		return formula_Value.VString(result.join(formula_ValueTool.asString(args[0])));
	}};
	this.funcMap.h["join"] = value;
	var value = { type : formula_Builtins.fnTy(boolTy,[strTy,strTy]), 'eval' : function(args) {
		var arg0 = formula_ValueTool.asString(args[0]);
		var arg1 = formula_ValueTool.asString(args[1]);
		return formula_Value.VBoolean(arg0.length <= 0 ? false : arg0.indexOf(arg1) >= 0);
	}};
	this.funcMap.h["contains"] = value;
	var value = { type : formula_Builtins.fnTy(strTy,[strTy,strTy,strTy]), 'eval' : function(args) {
		var _this_r = new RegExp(formula_ValueTool.asString(args[1]),"".split("u").join(""));
		var s = formula_ValueTool.asString(args[0]);
		var by = formula_ValueTool.asString(args[2]);
		return formula_Value.VString(s.replace(_this_r,by));
	}};
	this.funcMap.h["replace"] = value;
	var value = { type : formula_Builtins.fnTy(strTy,[strTy,strTy,strTy]), 'eval' : function(args) {
		var _this_r = new RegExp(formula_ValueTool.asString(args[1]),"g".split("u").join(""));
		var s = formula_ValueTool.asString(args[0]);
		var by = formula_ValueTool.asString(args[2]);
		return formula_Value.VString(s.replace(_this_r,by));
	}};
	this.funcMap.h["replaceAll"] = value;
	var value = { type : formula_Builtins.fnTy(boolTy,[strTy,strTy]), 'eval' : function(args) {
		return formula_Value.VBoolean(new EReg(formula_ValueTool.asString(args[1]),"").match(formula_ValueTool.asString(args[0])));
	}};
	this.funcMap.h["test"] = value;
	this.funcMap.h["now"] = { type : formula_Builtins.fnTy(dateTy,[]), 'eval' : null};
	var pad = function(x,n) {
		var s = "0000" + (x == null ? "null" : "" + x);
		return s.substring(s.length - n);
	};
	var ord = function(x) {
		switch(x % 10) {
		case 1:
			if(x != 11) {
				return "" + x + "st";
			} else {
				return "" + x + "th";
			}
		case 2:
			if(x != 12) {
				return "" + x + "nd";
			} else {
				return "" + x + "th";
			}
		case 3:
			if(x != 13) {
				return "" + x + "rd";
			} else {
				return "" + x + "th";
			}
		default:
			return "" + x + "th";
		}
	};
	var tz = function(z,colon) {
		var absz = Math.abs(z);
		return "" + (z < 0 ? "-" : "+") + pad(Math.floor(absz / 60),2) + (colon ? ":" : "") + pad(absz % 60 | 0,2);
	};
	var formatDate = function(date,fmt) {
		return new EReg("YYYY|YY|Qo|Q|MMMM|MMM|MM|Mo|M|ww|wo|w|DDDD|DDDo|DDD|DD|Do|D|dddd|ddd|dd|d|HH|H|hh|h|mm|m|ss|s|A|a|ZZ|Z|SSS|SS|S|X|x","g").map(fmt,function(reg) {
			switch(reg.matched(0)) {
			case "A":
				if(date.getHours() < 12) {
					return "AM";
				} else {
					return "PM";
				}
			case "D":
				return Std.string(date.getDate());
			case "DD":
				return pad(date.getDate(),2);
			case "DDD":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return Std.string(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1);
			case "DDDD":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return pad(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1,3);
			case "DDDo":
				var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
				return ord(((this1 - 62135596800.0 - datetime_DateTime.yearStart(this1)) / 86400 | 0) + 1);
			case "Do":
				return ord(date.getDate());
			case "H":
				return Std.string(date.getHours());
			case "HH":
				return pad(date.getHours(),2);
			case "M":
				return Std.string(date.getMonth() + 1);
			case "MM":
				return pad(date.getMonth() + 1,2);
			case "MMM":
				return DateTools.format(date,"%b");
			case "MMMM":
				return DateTools.format(date,"%B");
			case "Mo":
				return ord(date.getMonth() + 1);
			case "Q":
				return Std.string(Math.floor(date.getMonth() / 3) + 1);
			case "Qo":
				return ord(Math.floor(date.getMonth() / 3) + 1);
			case "S":
				return Std.string(Math.floor(date.getTime() % 1000 / 100));
			case "SS":
				return Std.string(Math.floor(date.getTime() % 1000 / 10));
			case "SSS":
				return Std.string(date.getTime() % 1000);
			case "X":
				return Std.string(Math.floor(date.getTime() / 1000));
			case "XX":
				return Std.string(date.getTime());
			case "YY":
				return pad(date.getFullYear(),2);
			case "YYYY":
				return pad(date.getFullYear(),4);
			case "Z":
				return tz(-date.getTimezoneOffset(),true);
			case "ZZ":
				return tz(-date.getTimezoneOffset(),false);
			case "a":
				if(date.getHours() < 12) {
					return "am";
				} else {
					return "pm";
				}
			case "d":
				return Std.string(date.getDay());
			case "dd":
				return HxOverrides.substr(DateTools.format(date,"%a"),0,2);
			case "ddd":
				return DateTools.format(date,"%a");
			case "dddd":
				return DateTools.format(date,"%A");
			case "do":
				return ord(date.getDay());
			case "h":
				return Std.string(date.getHours() % 12);
			case "hh":
				return pad(date.getHours() % 12,2);
			case "m":
				return Std.string(date.getMinutes());
			case "mm":
				return pad(date.getMinutes(),2);
			case "s":
				return Std.string(date.getSeconds());
			case "ss":
				return pad(date.getSeconds(),2);
			case "w":
				return Std.string(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0));
			case "wo":
				return ord(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0));
			case "ww":
				return pad(formula_IntervalFix.getLocalWeek(Math.floor(date.getTime() / 1000) + 62135596800.0),2);
			default:
				throw new haxe_Exception("");
			}
		});
	};
	var value = { type : formula_Builtins.fnTy(strTy,[dateTy,strTy]), 'eval' : function(args) {
		return formula_Value.VString(formatDate(formula_ValueTool.asDate(args[0]),formula_ValueTool.asString(args[1])));
	}};
	this.funcMap.h["formatDate"] = value;
	var value = { type : formula_Builtins.fnTy(dateTy,[numTy]), 'eval' : function(args) {
		return formula_Value.VDate(new Date(formula_ValueTool.asNumber(args[0])));
	}};
	this.funcMap.h["fromTimestamp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(Math.round(formula_ValueTool.asDate(args[0]).getTime()));
	}};
	this.funcMap.h["timestamp"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getFullYear());
	}};
	this.funcMap.h["year"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getMonth() + 1);
	}};
	this.funcMap.h["month"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getDate());
	}};
	this.funcMap.h["date"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getDay());
	}};
	this.funcMap.h["day"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getHours());
	}};
	this.funcMap.h["hour"] = value;
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy]), 'eval' : function(args) {
		return formula_Value.VNumber(formula_ValueTool.asDate(args[0]).getMinutes());
	}};
	this.funcMap.h["minute"] = value;
	var dateAdd = function(date,amount,unit) {
		if(unit == "milliseconds") {
			var milliseconds = date.getTime() % 1000 + amount;
			var seconds = Math.floor(milliseconds / 1000);
			milliseconds -= seconds * 1000;
			return new Date((datetime_DateTime.add(Math.floor(date.getTime() / 1000) + 62135596800.0,datetime_DTPeriod.Second(seconds)) - 62135596800.0) * 1000 + milliseconds);
		}
		var milliseconds = date.getTime() % 1000;
		var this1 = Math.floor(date.getTime() / 1000) + 62135596800.0;
		var this11;
		switch(unit) {
		case "days":
			this11 = datetime_DTPeriod.Day(amount);
			break;
		case "hours":
			this11 = datetime_DTPeriod.Hour(amount);
			break;
		case "minutes":
			this11 = datetime_DTPeriod.Minute(amount);
			break;
		case "months":
			this11 = datetime_DTPeriod.Month(amount);
			break;
		case "quarters":
			this11 = datetime_DTPeriod.Month(amount * 3);
			break;
		case "seconds":
			this11 = datetime_DTPeriod.Second(amount);
			break;
		case "weeks":
			this11 = datetime_DTPeriod.Week(amount);
			break;
		case "years":
			this11 = datetime_DTPeriod.Year(amount);
			break;
		default:
			throw new haxe_Exception("");
		}
		return new Date((datetime_DateTime.add(this1,this11) - 62135596800.0) * 1000 + milliseconds);
	};
	var value = { type : formula_Builtins.fnTy(dateTy,[dateTy,numTy,strTy]), 'eval' : function(args) {
		return formula_Value.VDate(dateAdd(formula_ValueTool.asDate(args[0]),formula_ValueTool.asNumber(args[1]) | 0,formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateAdd"] = value;
	var value = { type : formula_Builtins.fnTy(dateTy,[dateTy,numTy,strTy]), 'eval' : function(args) {
		return formula_Value.VDate(dateAdd(formula_ValueTool.asDate(args[0]),-(formula_ValueTool.asNumber(args[1]) | 0),formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateSubtract"] = value;
	var dateBetween = function(date1,date2,unit) {
		var milliseconds = date1.getTime() % 1000 - date2.getTime() % 1000;
		var datetime1 = Math.floor(date1.getTime() / 1000) + 62135596800.0;
		if(milliseconds < 0) {
			milliseconds += 1000;
			datetime1 = datetime_DateTime.add(datetime1,datetime_DTPeriod.Second(-1));
		}
		var interval = datetime_DateTimeInterval.create(Math.floor(date2.getTime() / 1000) + 62135596800.0,datetime1);
		var dateBetween = interval.negative ? -1 : 1;
		var dateBetween1;
		switch(unit) {
		case "days":
			dateBetween1 = interval.getTotalDays();
			break;
		case "hours":
			dateBetween1 = interval.getTotalHours();
			break;
		case "milliseconds":
			dateBetween1 = interval.getTotalSeconds() * 1000 + milliseconds;
			break;
		case "minutes":
			dateBetween1 = interval.getTotalMinutes();
			break;
		case "months":
			dateBetween1 = formula_IntervalFix.getTotalMonthsFix(interval);
			break;
		case "quarters":
			dateBetween1 = formula_IntervalFix.getTotalMonthsFix(interval) / 3;
			break;
		case "seconds":
			dateBetween1 = interval.getTotalSeconds();
			break;
		case "weeks":
			dateBetween1 = interval.getTotalWeeks();
			break;
		case "years":
			dateBetween1 = interval.getYears();
			break;
		default:
			throw new haxe_Exception("");
		}
		return dateBetween * dateBetween1;
	};
	var value = { type : formula_Builtins.fnTy(numTy,[dateTy,dateTy,strTy]), 'eval' : function(args) {
		return formula_Value.VNumber(dateBetween(formula_ValueTool.asDate(args[0]),formula_ValueTool.asDate(args[1]),formula_ValueTool.asString(args[2])));
	}};
	this.funcMap.h["dateBetween"] = value;
	this.funcMap.h["id"] = { type : formula_Builtins.fnTy(strTy,[]), 'eval' : null};
};
formula_Builtins.__name__ = true;
formula_Builtins.fnTy = function(ret,params,optParams) {
	return { typeVars : null, params : params, optParams : optParams, variadic : null, ret : ret};
};
formula_Builtins.vaFnTy = function(ret,params,variadic) {
	return { typeVars : null, params : params, optParams : null, variadic : variadic, ret : ret};
};
formula_Builtins.genFnTy = function(typeVars,ret,params,optParams) {
	return { typeVars : typeVars, params : params, optParams : optParams, variadic : null, ret : ret};
};
formula_Builtins.prototype = {
	findFuncDef: function(name) {
		return this.funcMap.h[name];
	}
	,findConstDef: function(name) {
		return this.constMap.h[name];
	}
};
var formula_ExpectedKind = $hxEnums["formula.ExpectedKind"] = { __ename__:true,__constructs__:null
	,EkExpr: {_hx_name:"EkExpr",_hx_index:0,__enum__:"formula.ExpectedKind",toString:$estr}
	,EkUnknown: {_hx_name:"EkUnknown",_hx_index:1,__enum__:"formula.ExpectedKind",toString:$estr}
	,EkRparen: {_hx_name:"EkRparen",_hx_index:2,__enum__:"formula.ExpectedKind",toString:$estr}
};
formula_ExpectedKind.__constructs__ = [formula_ExpectedKind.EkExpr,formula_ExpectedKind.EkUnknown,formula_ExpectedKind.EkRparen];
var formula_Error = $hxEnums["formula.Error"] = { __ename__:true,__constructs__:null
	,UnclosedStringLiteral: ($_=function(pos) { return {_hx_index:0,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnclosedStringLiteral",$_.__params__ = ["pos"],$_)
	,InvalidStringLiteral: ($_=function(pos) { return {_hx_index:1,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="InvalidStringLiteral",$_.__params__ = ["pos"],$_)
	,UnexpectedChar: ($_=function(char,pos) { return {_hx_index:2,char:char,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnexpectedChar",$_.__params__ = ["char","pos"],$_)
	,UnexpectedToken: ($_=function(token,expected) { return {_hx_index:3,token:token,expected:expected,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnexpectedToken",$_.__params__ = ["token","expected"],$_)
	,UnbalancedParen: ($_=function(pos) { return {_hx_index:4,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnbalancedParen",$_.__params__ = ["pos"],$_)
	,UnknownError: ($_=function(pos) { return {_hx_index:5,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UnknownError",$_.__params__ = ["pos"],$_)
	,UndefinedConstant: ($_=function(name,pos) { return {_hx_index:6,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedConstant",$_.__params__ = ["name","pos"],$_)
	,FunctionAsConstant: ($_=function(name,pos) { return {_hx_index:7,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="FunctionAsConstant",$_.__params__ = ["name","pos"],$_)
	,UndefinedFunction: ($_=function(name,pos) { return {_hx_index:8,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedFunction",$_.__params__ = ["name","pos"],$_)
	,UndefinedProp: ($_=function(name,pos) { return {_hx_index:9,name:name,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="UndefinedProp",$_.__params__ = ["name","pos"],$_)
	,TypeMismatch: ($_=function(expr,types) { return {_hx_index:10,expr:expr,types:types,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TypeMismatch",$_.__params__ = ["expr","types"],$_)
	,TooManyArguments: ($_=function(actual,expected,pos) { return {_hx_index:11,actual:actual,expected:expected,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TooManyArguments",$_.__params__ = ["actual","expected","pos"],$_)
	,TooFewArguments: ($_=function(actual,expected,pos) { return {_hx_index:12,actual:actual,expected:expected,pos:pos,__enum__:"formula.Error",toString:$estr}; },$_._hx_name="TooFewArguments",$_.__params__ = ["actual","expected","pos"],$_)
};
formula_Error.__constructs__ = [formula_Error.UnclosedStringLiteral,formula_Error.InvalidStringLiteral,formula_Error.UnexpectedChar,formula_Error.UnexpectedToken,formula_Error.UnbalancedParen,formula_Error.UnknownError,formula_Error.UndefinedConstant,formula_Error.FunctionAsConstant,formula_Error.UndefinedFunction,formula_Error.UndefinedProp,formula_Error.TypeMismatch,formula_Error.TooManyArguments,formula_Error.TooFewArguments];
var formula_ErrorTool = $hx_exports["formula"]["ErrorTool"] = function() { };
formula_ErrorTool.__name__ = true;
formula_ErrorTool.getPosition = function(error) {
	switch(error._hx_index) {
	case 0:
		return error.pos;
	case 1:
		return error.pos;
	case 2:
		return error.pos;
	case 3:
		return error.token.pos;
	case 4:
		return error.pos;
	case 5:
		return error.pos;
	case 6:
		return error.pos;
	case 7:
		return error.pos;
	case 8:
		return error.pos;
	case 9:
		return error.pos;
	case 10:
		return error.expr.pos;
	case 11:
		return error.pos;
	case 12:
		return error.pos;
	}
};
formula_ErrorTool.reprType = function(type) {
	switch(type._hx_index) {
	case 0:
		return "数值";
	case 1:
		return "文本";
	case 2:
		return "布尔";
	case 3:
		return "日期";
	}
};
formula_ErrorTool.toString = function(error) {
	switch(error._hx_index) {
	case 0:
		return "缺少成对的引号";
	case 1:
		return "字符串语法错误";
	case 2:
		return "语法错误";
	case 3:
		return "语法错误";
	case 4:
		return "缺少成对的括号";
	case 5:
		return "语法错误";
	case 6:
		return "找不到常量`" + error.name + "`";
	case 7:
		return "`" + error.name + "`是一个函数而不是常量";
	case 8:
		return "找不到函数`" + error.name + "`";
	case 9:
		return "找不到属性`" + error.name + "`";
	case 10:
		var _g = error.types;
		var f = formula_ErrorTool.reprType;
		var result = new Array(_g.length);
		var _g1 = 0;
		var _g2 = _g.length;
		while(_g1 < _g2) {
			var i = _g1++;
			result[i] = f(_g[i]);
		}
		return "类型错误，期望" + result.join("或") + "类型";
	case 11:
		return "参数过多，期望" + error.expected + "个，传入" + error.actual + "个";
	case 12:
		return "参数过少，期望" + error.expected + "个，传入" + error.actual + "个";
	}
};
var formula_EvalError = $hxEnums["formula.EvalError"] = { __ename__:true,__constructs__:null
};
formula_EvalError.__constructs__ = [];
var formula_EvalContext = $hx_exports["formula"]["EvalContext"] = function() { };
formula_EvalContext.__name__ = true;
var formula_FormulaEvaluator = function(ctx) {
	this.ctx = ctx;
};
formula_FormulaEvaluator.__name__ = true;
formula_FormulaEvaluator.prototype = {
	'eval': function(expr,recordId) {
		var _gthis = this;
		var now = new Date();
		var $eval = null;
		$eval = function(expr) {
			while(true) {
				var _g = expr.desc;
				switch(_g._hx_index) {
				case 0:
					return formula_Value.VNumber(_g.value);
				case 1:
					return formula_Value.VString(_g.value);
				case 2:
					return formula_Builtins.singleton.findConstDef(_g.$const);
				case 3:
					var _g1 = _g.fn;
					var _g2 = _g.args;
					if(_g1 == "id") {
						return formula_Value.VString(recordId);
					} else if(_g1 == "prop") {
						return _gthis.ctx.readProp(recordId,formula_ExprTool.getStringValue(_g2[1]),expr.type);
					} else if(_g1 == "if") {
						var then_ = _g2[1];
						var else_ = _g2[2];
						if(formula_ValueTool.asBoolean($eval(_g2[0]))) {
							return $eval(then_);
						} else {
							return $eval(else_);
						}
					} else if(_g1 == "and") {
						var arg2 = _g2[1];
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g2[0])) && formula_ValueTool.asBoolean($eval(arg2)));
					} else if(_g1 == "or") {
						var arg21 = _g2[1];
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g2[0])) || formula_ValueTool.asBoolean($eval(arg21)));
					} else if(_g1 == "now") {
						return formula_Value.VDate(now);
					}
					var result = new Array(_g2.length);
					var _g3 = 0;
					var _g11 = _g2.length;
					while(_g3 < _g11) {
						var i = _g3++;
						result[i] = $eval(_g2[i]);
					}
					return formula_Builtins.singleton.findFuncDef(_g1).eval(result);
				case 4:
					expr = _g.exp;
					continue;
				case 5:
					var argValues = [$eval(_g.arg)];
					var func;
					switch(_g.op._hx_index) {
					case 0:
						func = "unaryPlus";
						break;
					case 1:
						func = "unaryMinus";
						break;
					case 2:
						func = "not";
						break;
					}
					return formula_Builtins.singleton.findFuncDef(func).eval(argValues);
				case 6:
					var _g4 = _g.arg1;
					var _g5 = _g.arg2;
					var argValues1 = [$eval(_g4),$eval(_g5)];
					var func1;
					switch(_g.op._hx_index) {
					case 0:
						func1 = _g4.type == formula_ValueType.TNumber ? "add" : "concat";
						break;
					case 1:
						func1 = "subtract";
						break;
					case 2:
						func1 = "multiply";
						break;
					case 3:
						func1 = "divide";
						break;
					case 4:
						func1 = "mod";
						break;
					case 5:
						func1 = "pow";
						break;
					case 6:
						func1 = "equal";
						break;
					case 7:
						func1 = "unequal";
						break;
					case 8:
						func1 = "smaller";
						break;
					case 9:
						func1 = "smallerEq";
						break;
					case 10:
						func1 = "larger";
						break;
					case 11:
						func1 = "largerEq";
						break;
					case 12:
						func1 = "and";
						break;
					case 13:
						func1 = "or";
						break;
					}
					if(func1 == "and") {
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g4)) && formula_ValueTool.asBoolean($eval(_g5)));
					} else if(func1 == "or") {
						return formula_Value.VBoolean(formula_ValueTool.asBoolean($eval(_g4)) || formula_ValueTool.asBoolean($eval(_g5)));
					}
					return formula_Builtins.singleton.findFuncDef(func1).eval(argValues1);
				case 7:
					if(formula_ValueTool.asBoolean($eval(_g.test))) {
						expr = _g.then;
						continue;
					} else {
						expr = _g.else_;
						continue;
					}
				}
			}
		};
		var eval1 = $eval;
		try {
			return eval1(expr);
		} catch( _g ) {
			return null;
		}
	}
};
var formula_UnOp = $hxEnums["formula.UnOp"] = { __ename__:true,__constructs__:null
	,OpUnaryPlus: {_hx_name:"OpUnaryPlus",_hx_index:0,__enum__:"formula.UnOp",toString:$estr}
	,OpUnaryMinus: {_hx_name:"OpUnaryMinus",_hx_index:1,__enum__:"formula.UnOp",toString:$estr}
	,OpNot: {_hx_name:"OpNot",_hx_index:2,__enum__:"formula.UnOp",toString:$estr}
};
formula_UnOp.__constructs__ = [formula_UnOp.OpUnaryPlus,formula_UnOp.OpUnaryMinus,formula_UnOp.OpNot];
var formula_BinOp = $hxEnums["formula.BinOp"] = { __ename__:true,__constructs__:null
	,OpAdd: {_hx_name:"OpAdd",_hx_index:0,__enum__:"formula.BinOp",toString:$estr}
	,OpSub: {_hx_name:"OpSub",_hx_index:1,__enum__:"formula.BinOp",toString:$estr}
	,OpMul: {_hx_name:"OpMul",_hx_index:2,__enum__:"formula.BinOp",toString:$estr}
	,OpDiv: {_hx_name:"OpDiv",_hx_index:3,__enum__:"formula.BinOp",toString:$estr}
	,OpMod: {_hx_name:"OpMod",_hx_index:4,__enum__:"formula.BinOp",toString:$estr}
	,OpPow: {_hx_name:"OpPow",_hx_index:5,__enum__:"formula.BinOp",toString:$estr}
	,OpEq: {_hx_name:"OpEq",_hx_index:6,__enum__:"formula.BinOp",toString:$estr}
	,OpNeq: {_hx_name:"OpNeq",_hx_index:7,__enum__:"formula.BinOp",toString:$estr}
	,OpLt: {_hx_name:"OpLt",_hx_index:8,__enum__:"formula.BinOp",toString:$estr}
	,OpLeq: {_hx_name:"OpLeq",_hx_index:9,__enum__:"formula.BinOp",toString:$estr}
	,OpGt: {_hx_name:"OpGt",_hx_index:10,__enum__:"formula.BinOp",toString:$estr}
	,OpGeq: {_hx_name:"OpGeq",_hx_index:11,__enum__:"formula.BinOp",toString:$estr}
	,OpAnd: {_hx_name:"OpAnd",_hx_index:12,__enum__:"formula.BinOp",toString:$estr}
	,OpOr: {_hx_name:"OpOr",_hx_index:13,__enum__:"formula.BinOp",toString:$estr}
};
formula_BinOp.__constructs__ = [formula_BinOp.OpAdd,formula_BinOp.OpSub,formula_BinOp.OpMul,formula_BinOp.OpDiv,formula_BinOp.OpMod,formula_BinOp.OpPow,formula_BinOp.OpEq,formula_BinOp.OpNeq,formula_BinOp.OpLt,formula_BinOp.OpLeq,formula_BinOp.OpGt,formula_BinOp.OpGeq,formula_BinOp.OpAnd,formula_BinOp.OpOr];
var formula_ExprDesc = $hxEnums["formula.ExprDesc"] = { __ename__:true,__constructs__:null
	,ENumber: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ENumber",$_.__params__ = ["value"],$_)
	,EString: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EString",$_.__params__ = ["value"],$_)
	,EConst: ($_=function($const) { return {_hx_index:2,$const:$const,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EConst",$_.__params__ = ["$const"],$_)
	,ECall: ($_=function(fn,fnPos,args) { return {_hx_index:3,fn:fn,fnPos:fnPos,args:args,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ECall",$_.__params__ = ["fn","fnPos","args"],$_)
	,EParen: ($_=function(exp) { return {_hx_index:4,exp:exp,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EParen",$_.__params__ = ["exp"],$_)
	,EUnary: ($_=function(op,opPos,arg) { return {_hx_index:5,op:op,opPos:opPos,arg:arg,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EUnary",$_.__params__ = ["op","opPos","arg"],$_)
	,EBinary: ($_=function(op,opPos,arg1,arg2) { return {_hx_index:6,op:op,opPos:opPos,arg1:arg1,arg2:arg2,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="EBinary",$_.__params__ = ["op","opPos","arg1","arg2"],$_)
	,ETernary: ($_=function(test,then,else_) { return {_hx_index:7,test:test,then:then,else_:else_,__enum__:"formula.ExprDesc",toString:$estr}; },$_._hx_name="ETernary",$_.__params__ = ["test","then","else_"],$_)
};
formula_ExprDesc.__constructs__ = [formula_ExprDesc.ENumber,formula_ExprDesc.EString,formula_ExprDesc.EConst,formula_ExprDesc.ECall,formula_ExprDesc.EParen,formula_ExprDesc.EUnary,formula_ExprDesc.EBinary,formula_ExprDesc.ETernary];
var formula_ExprTool = $hx_exports["formula"]["ExprTool"] = function() { };
formula_ExprTool.__name__ = true;
formula_ExprTool.getStringValue = function(expr) {
	var _g = expr.desc;
	if(_g._hx_index == 1) {
		return _g.value;
	} else {
		return null;
	}
};
formula_ExprTool.typeOf = function(expr) {
	return expr.type;
};
var formula_MyEvalContext = function() {
};
formula_MyEvalContext.__name__ = true;
formula_MyEvalContext.__super__ = formula_EvalContext;
formula_MyEvalContext.prototype = $extend(formula_EvalContext.prototype,{
	readProp: function(recordId,propId,type) {
		return formula_Value.VNumber(10.0);
	}
});
var formula_Formula = $hx_exports["formula"]["Formula"] = function() { };
formula_Formula.__name__ = true;
formula_Formula.main = function() {
};
formula_Formula.toServer = function(formula,schema) {
	var refProps = { };
	return { text : formula_Formula.regex.map(formula,function(regex) {
		var propName = JSON.parse(regex.matched(1));
		var propDef = schema.findPropDefByName(propName);
		refProps[propDef.id] = propName;
		var _this_r = new RegExp("\"(?:[^\\\\\"]|\\\\.)*\"","".split("u").join(""));
		var s = regex.matched(0);
		var by = JSON.stringify(propDef.id);
		return s.replace(_this_r,by);
	}), refProps : refProps};
};
formula_Formula.fromServer = function(formula,schema) {
	return formula_Formula.regex.map(formula.text == null ? "" : formula.text,function(regex) {
		var propId = JSON.parse(regex.matched(1));
		var propDef = schema.findPropDefById(propId);
		var propName = propDef == null ? formula.refProps[propId] : propDef.name;
		var _this_r = new RegExp("\"(?:[^\\\\\"]|\\\\.)*\"","".split("u").join(""));
		var s = regex.matched(0);
		var by = JSON.stringify(propName);
		return s.replace(_this_r,by);
	});
};
formula_Formula.parse = function(input,schema) {
	var parser = new formula_FormulaParser(new formula_FormulaTokenSource(input),schema);
	try {
		return formula_Result.Ok(parser.parse());
	} catch( _g ) {
		var _g1 = haxe_Exception.caught(_g);
		var _g2 = _g1.unwrap();
		if(((_g1) instanceof formula_ParseException)) {
			return formula_Result.Error(_g1.error);
		} else if(((_g2) instanceof hxparse_NoMatch)) {
			return formula_Result.Error(formula_Error.UnexpectedToken(_g2.token,formula_ExpectedKind.EkUnknown));
		} else if(((_g2) instanceof hxparse_Unexpected)) {
			return formula_Result.Error(formula_Error.UnexpectedToken(_g2.token,formula_ExpectedKind.EkUnknown));
		} else if(((_g2) instanceof hxparse_UnexpectedChar)) {
			var e = _g2;
			return formula_Result.Error(formula_Error.UnexpectedChar(e.char,e.pos));
		} else if(((_g2) instanceof hxparse_ParserError)) {
			return formula_Result.Error(formula_Error.UnknownError(_g2.pos));
		} else {
			throw _g;
		}
	}
};
formula_Formula.eval = function(expr,ctx,recordId) {
	return new formula_FormulaEvaluator(ctx).eval(expr,recordId);
};
formula_Formula.objc_parse = function(input,schemaConfig) {
	var schema = new formula_Schema();
	var _g = 0;
	while(_g < schemaConfig.length) {
		var prop = schemaConfig[_g];
		++_g;
		var tmp;
		switch(prop.type) {
		case "boolean":
			tmp = formula_ValueType.TBoolean;
			break;
		case "date":
			tmp = formula_ValueType.TDate;
			break;
		case "number":
			tmp = formula_ValueType.TNumber;
			break;
		case "string":
			tmp = formula_ValueType.TString;
			break;
		default:
			tmp = formula_ValueType.TString;
		}
		schema.defineProp(prop.id,prop.name,tmp);
	}
	var result = formula_Formula.parse(input,schema);
	return { ok : formula_ResultTool.isOk(result) ? formula_ResultTool.getOk(result) : null, error : formula_ResultTool.isError(result) ? formula_ErrorTool.toString(formula_ResultTool.getError(result)) : null};
};
formula_Formula.objc_eval = function(expr,recordId) {
	var value = formula_Formula.eval(expr,formula_ObjcEvalContext.singleton,recordId);
	var tmp = formula_ValueTool.toString(value);
	var tmp1;
	switch(value._hx_index) {
	case 0:
		tmp1 = "number";
		break;
	case 1:
		tmp1 = "string";
		break;
	case 2:
		tmp1 = "boolean";
		break;
	case 3:
		tmp1 = "date";
		break;
	}
	return { value : tmp, type : tmp1};
};
formula_Formula.objc_expr_type = function(expr) {
	switch(expr.type._hx_index) {
	case 0:
		return "number";
	case 1:
		return "string";
	case 2:
		return "boolean";
	case 3:
		return "date";
	}
};
var formula_ObjcEvalContext = function() {
};
formula_ObjcEvalContext.__name__ = true;
formula_ObjcEvalContext.__super__ = formula_EvalContext;
formula_ObjcEvalContext.prototype = $extend(formula_EvalContext.prototype,{
	readProp: function(recordId,propId,type) {
		var value = objc_read_prop(recordId, propId);
		if(typeof(value) == "string") {
			return formula_Value.VString(value);
		} else if(typeof(value) == "number") {
			return formula_Value.VNumber(value);
		} else if(typeof(value) == "boolean") {
			return formula_Value.VBoolean(value);
		} else if(((value) instanceof Date)) {
			return formula_Value.VDate(value);
		}
		return null;
	}
});
var formula_ParseException = function(error) {
	haxe_Exception.call(this,formula_ErrorTool.toString(error));
	this.error = error;
};
formula_ParseException.__name__ = true;
formula_ParseException.__super__ = haxe_Exception;
formula_ParseException.prototype = $extend(haxe_Exception.prototype,{
});
var formula_FormulaTokenSource = function(input,source) {
	if(source == null) {
		source = "(none)";
	}
	this.cursor = 0;
	this.input = input;
	this.source = source;
};
formula_FormulaTokenSource.__name__ = true;
formula_FormulaTokenSource.prototype = {
	token: function() {
		var _gthis = this;
		this.skip(new EReg("^[ \t\r\n]+",""));
		var pmin = this.cursor;
		var mk = function(desc) {
			return { tok : desc, pos : new hxparse_Position(_gthis.source,pmin,_gthis.cursor)};
		};
		if(this.cursor == this.input.length) {
			return mk(formula_TokenDesc.TkEof);
		}
		var reg = new EReg("(?:\"([^\"\\\\]|\\\\.)*\"?|(0|[1-9][0-9]*)([.][0-9]+)?([eE][\\\\+\\\\-]?0|[1-9][0-9]*)?|[A-Za-z_][A-Za-z0-9_]*|\\(|\\)|\\+|\\-|\\*|/|%|\\^|==|!=|<>|<=|<|>=|>|,|\\?|:)","");
		if(reg.match(this.input.substring(this.cursor))) {
			var m = reg.matched(0);
			this.cursor += reg.matchedPos().len;
			switch(m) {
			case "!=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpNeq));
			case "%":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpMod));
			case "(":
				return mk(formula_TokenDesc.TkLparen);
			case ")":
				return mk(formula_TokenDesc.TkRparen);
			case "*":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpMul));
			case "+":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpAdd));
			case ",":
				return mk(formula_TokenDesc.TkComma);
			case "-":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpSub));
			case "/":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpDiv));
			case ":":
				return mk(formula_TokenDesc.TkColon);
			case "<":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpLt));
			case "<=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpLeq));
			case "<>":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpNeq));
			case "==":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpEq));
			case ">":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpGt));
			case ">=":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpGeq));
			case "?":
				return mk(formula_TokenDesc.TkQuestion);
			case "^":
				return mk(formula_TokenDesc.TkBinOp(formula_BinOp.OpPow));
			default:
				var c = m.charAt(0);
				if(c == "\"") {
					if(m.length > 1 && m.charAt(m.length - 1) == "\"") {
						try {
							return mk(formula_TokenDesc.TkStr(JSON.parse(m)));
						} catch( _g ) {
							var quoteStart = this.cursor - reg.matchedPos().len;
							throw new formula_ParseException(formula_Error.InvalidStringLiteral(new hxparse_Position(this.source,quoteStart,this.cursor)));
						}
					} else {
						var quoteStart = this.cursor - reg.matchedPos().len;
						throw new formula_ParseException(formula_Error.UnclosedStringLiteral(new hxparse_Position(this.source,quoteStart,quoteStart + 1)));
					}
				} else if(c >= "0" && c <= "9") {
					return mk(formula_TokenDesc.TkNum(parseFloat(m)));
				} else {
					return mk(formula_TokenDesc.TkIdent(m));
				}
			}
		} else {
			throw new formula_ParseException(formula_Error.UnexpectedChar(this.input.charAt(this.cursor),new hxparse_Position(this.source,this.cursor,this.cursor + 1)));
		}
	}
	,skip: function(reg) {
		if(reg.match(this.input.substring(this.cursor))) {
			this.cursor += reg.matchedPos().len;
		}
	}
	,curPos: function() {
		return new hxparse_Position(this.source,this.cursor,this.cursor);
	}
};
var hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token = function(stream) {
	this.stream = stream;
};
hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.__name__ = true;
hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.prototype = {
	peek: function(n) {
		if(this.token == null) {
			this.token = new haxe_ds_GenericCell(this.stream.token(),null);
			--n;
		}
		var tok = this.token;
		while(n > 0) {
			if(tok.next == null) {
				tok.next = new haxe_ds_GenericCell(this.stream.token(),null);
			}
			tok = tok.next;
			--n;
		}
		return tok.elt;
	}
	,junk: function() {
		this.last = this.token.elt;
		this.token = this.token.next;
	}
	,curPos: function() {
		return this.stream.curPos();
	}
	,parseSeparated: function(separatorFunc,f) {
		var acc = [];
		while(true) {
			try {
				acc.push(f());
			} catch( _g ) {
				var _g1 = haxe_Exception.caught(_g).unwrap();
				if(((haxe_Exception.caught(_g1).unwrap()) instanceof hxparse_NoMatch)) {
					break;
				} else {
					throw haxe_Exception.thrown(_g1);
				}
			}
			if(separatorFunc(this.peek(0))) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				break;
			}
		}
		return acc;
	}
	,parseOptional: function(f) {
		try {
			return f();
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g).unwrap();
			if(((haxe_Exception.caught(_g1).unwrap()) instanceof hxparse_NoMatch)) {
				return null;
			} else {
				throw haxe_Exception.thrown(_g1);
			}
		}
	}
	,parseRepeat: function(f) {
		var acc = [];
		while(true) try {
			acc.push(f());
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g).unwrap();
			if(((haxe_Exception.caught(_g1).unwrap()) instanceof hxparse_NoMatch)) {
				return acc;
			} else {
				throw haxe_Exception.thrown(_g1);
			}
		}
	}
	,parseExpect: function(f) {
		try {
			return f();
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g).unwrap();
			if(((haxe_Exception.caught(_g1).unwrap()) instanceof hxparse_NoMatch)) {
				throw haxe_Exception.thrown(haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos())));
			} else {
				throw haxe_Exception.thrown(_g1);
			}
		}
	}
	,noMatch: function() {
		return new hxparse_NoMatch(this.stream.curPos(),this.peek(0));
	}
	,unexpected: function() {
		throw haxe_Exception.thrown(haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos())));
	}
};
var formula_FormulaParser = function(stream,schema) {
	hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.call(this,stream);
	this.schema = schema;
	this.typer = new formula_TypeChecker(schema);
};
formula_FormulaParser.__name__ = true;
formula_FormulaParser.punion = function(p1,p2) {
	return new hxparse_Position(p1.psource,p1.pmin < p2.pmin ? p1.pmin : p2.pmin,p1.pmax > p2.pmax ? p1.pmax : p2.pmax);
};
formula_FormulaParser.__super__ = hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token;
formula_FormulaParser.prototype = $extend(hxparse_Parser_$formula_$FormulaTokenSource_$formula_$Token.prototype,{
	mk: function(desc,pos) {
		return { desc : desc, pos : pos, type : null};
	}
	,mkUnary: function(op,opPos,e) {
		var _g = e.desc;
		switch(_g._hx_index) {
		case 6:
			var _g1 = _g.op;
			var _g2 = _g.arg1;
			if(op != formula_UnOp.OpNot || this.precedence(_g1).p >= 7) {
				return this.mk(formula_ExprDesc.EBinary(_g1,_g.opPos,this.mkUnary(op,opPos,_g2),_g.arg2),formula_FormulaParser.punion(opPos,_g2.pos));
			} else {
				return this.mk(formula_ExprDesc.EUnary(op,opPos,e),formula_FormulaParser.punion(opPos,e.pos));
			}
		case 7:
			return this.mk(formula_ExprDesc.ETernary(this.mkUnary(op,opPos,_g.test),_g.then,_g.else_),formula_FormulaParser.punion(opPos,e.pos));
		default:
			return this.mk(formula_ExprDesc.EUnary(op,opPos,e),formula_FormulaParser.punion(opPos,e.pos));
		}
	}
	,mkBinary: function(op,opPos,e,e2) {
		var _g = e2.desc;
		switch(_g._hx_index) {
		case 6:
			var _g1 = _g.op;
			var _g2 = _g.arg2;
			if(this.swap(op,_g1)) {
				var _e = this.mkBinary(op,opPos,e,_g.arg1);
				return this.mk(formula_ExprDesc.EBinary(_g1,_g.opPos,_e,_g2),formula_FormulaParser.punion(_e.pos,_g2.pos));
			} else {
				return this.mk(formula_ExprDesc.EBinary(op,opPos,e,e2),formula_FormulaParser.punion(e.pos,e2.pos));
			}
		case 7:
			var _g1 = _g.else_;
			var e1 = this.mkBinary(op,opPos,e,_g.test);
			return this.mk(formula_ExprDesc.ETernary(e1,_g.then,_g1),formula_FormulaParser.punion(e1.pos,_g1.pos));
		default:
			return this.mk(formula_ExprDesc.EBinary(op,opPos,e,e2),formula_FormulaParser.punion(e.pos,e2.pos));
		}
	}
	,psep: function(sep,f) {
		var acc = [];
		while(true) try {
			acc.push(f());
			if(this.peek(0).tok == sep) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				throw haxe_Exception.thrown(new hxparse_NoMatch(this.stream.curPos(),this.peek(0)));
			}
		} catch( _g ) {
			var _g1 = haxe_Exception.caught(_g);
			if(((_g1.unwrap()) instanceof hxparse_NoMatch)) {
				break;
			} else if(((_g1) instanceof formula_ParseException)) {
				break;
			} else {
				throw _g;
			}
		}
		return acc;
	}
	,precedence: function(op) {
		switch(op._hx_index) {
		case 0:case 1:
			return { p : 3, left : true};
		case 2:case 3:case 4:
			return { p : 2, left : true};
		case 5:
			return { p : 1, left : true};
		case 6:case 7:case 8:case 9:case 10:case 11:
			return { p : 6, left : true};
		case 12:case 13:
			return { p : 7, left : true};
		}
	}
	,swap: function(op1,op2) {
		var i1 = this.precedence(op1);
		var i2 = this.precedence(op2);
		if(i1.left) {
			return i1.p <= i2.p;
		} else {
			return false;
		}
	}
	,parse: function() {
		var e = this.expr();
		if(this.peek(0).tok._hx_index == 9) {
			this.last = this.token.elt;
			this.token = this.token.next;
		} else {
			throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
		}
		this.typer.infer(e);
		return e;
	}
	,expr: function() {
		var _g = this.peek(0);
		var _g1 = _g.pos;
		var _g2 = _g.tok;
		switch(_g2._hx_index) {
		case 0:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.exprNext(this.mk(formula_ExprDesc.ENumber(_g2.value),_g1));
		case 1:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.exprNext(this.mk(formula_ExprDesc.EString(_g2.value),_g1));
		case 2:
			this.last = this.token.elt;
			this.token = this.token.next;
			var e = this.expr();
			var _g = this.peek(0);
			if(_g.tok._hx_index == 3) {
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.exprNext(this.mk(formula_ExprDesc.EParen(e),formula_FormulaParser.punion(_g1,_g.pos)));
			} else {
				throw new formula_ParseException(formula_Error.UnbalancedParen(_g1));
			}
		case 4:
			var _g = _g2.name;
			if(_g == "not") {
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpNot,_g1,this.expr());
			} else {
				this.last = this.token.elt;
				this.token = this.token.next;
				var _g3 = this.peek(0);
				var _g4 = _g3.pos;
				if(_g3.tok._hx_index == 2) {
					this.last = this.token.elt;
					this.token = this.token.next;
					var args = this.psep(formula_TokenDesc.TkComma,$bind(this,this.expr));
					var _g3 = this.peek(0);
					if(_g3.tok._hx_index == 3) {
						this.last = this.token.elt;
						this.token = this.token.next;
						return this.exprNext(this.mk(formula_ExprDesc.ECall(_g,_g1,args),formula_FormulaParser.punion(_g1,_g3.pos)));
					} else {
						var pt = this.peek(0);
						if(pt.tok == formula_TokenDesc.TkEof) {
							throw new formula_ParseException(formula_Error.UnbalancedParen(_g4));
						} else {
							throw new formula_ParseException(formula_Error.UnexpectedToken(pt,formula_ExpectedKind.EkRparen));
						}
					}
				} else {
					return this.exprNext(this.mk(formula_ExprDesc.EConst(_g),_g1));
				}
			}
		case 8:
			switch(_g2.op._hx_index) {
			case 0:
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpUnaryPlus,_g1,this.expr());
			case 1:
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkUnary(formula_UnOp.OpUnaryMinus,_g1,this.expr());
			default:
				throw new formula_ParseException(formula_Error.UnexpectedToken(this.peek(0),formula_ExpectedKind.EkExpr));
			}
		default:
			throw new formula_ParseException(formula_Error.UnexpectedToken(this.peek(0),formula_ExpectedKind.EkExpr));
		}
	}
	,exprNext: function(e1) {
		var _g = this.peek(0);
		var _g1 = _g.pos;
		var _g2 = _g.tok;
		switch(_g2._hx_index) {
		case 4:
			switch(_g2.name) {
			case "and":
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkBinary(formula_BinOp.OpAnd,_g1,e1,this.expr());
			case "or":
				this.last = this.token.elt;
				this.token = this.token.next;
				return this.mkBinary(formula_BinOp.OpOr,_g1,e1,this.expr());
			default:
				return e1;
			}
		case 6:
			this.last = this.token.elt;
			this.token = this.token.next;
			var e2 = this.expr();
			if(this.peek(0).tok._hx_index == 7) {
				this.last = this.token.elt;
				this.token = this.token.next;
				var e3 = this.expr();
				return this.mk(formula_ExprDesc.ETernary(e1,e2,e3),formula_FormulaParser.punion(e1.pos,e3.pos));
			} else {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			}
		case 8:
			this.last = this.token.elt;
			this.token = this.token.next;
			return this.mkBinary(_g2.op,_g1,e1,this.expr());
		default:
			return e1;
		}
	}
});
var formula_Result = $hxEnums["formula.Result"] = { __ename__:true,__constructs__:null
	,Ok: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.Result",toString:$estr}; },$_._hx_name="Ok",$_.__params__ = ["value"],$_)
	,Error: ($_=function(error) { return {_hx_index:1,error:error,__enum__:"formula.Result",toString:$estr}; },$_._hx_name="Error",$_.__params__ = ["error"],$_)
};
formula_Result.__constructs__ = [formula_Result.Ok,formula_Result.Error];
var formula_ResultTool = $hx_exports["formula"]["ResultTool"] = function() { };
formula_ResultTool.__name__ = true;
formula_ResultTool.isOk = function(res) {
	switch(res._hx_index) {
	case 0:
		return true;
	case 1:
		return false;
	}
};
formula_ResultTool.isError = function(res) {
	switch(res._hx_index) {
	case 0:
		return false;
	case 1:
		return true;
	}
};
formula_ResultTool.getOk = function(res) {
	switch(res._hx_index) {
	case 0:
		return res.value;
	case 1:
		throw new haxe_Exception("Invalid argument");
	}
};
formula_ResultTool.getError = function(res) {
	switch(res._hx_index) {
	case 0:
		throw new haxe_Exception("Invalid argument");
	case 1:
		return res.error;
	}
};
var formula_Schema = $hx_exports["formula"]["Schema"] = function() {
	this.byNameMap = new haxe_ds_StringMap();
	this.byIdMap = new haxe_ds_StringMap();
};
formula_Schema.__name__ = true;
formula_Schema.prototype = {
	defineProp: function(id,name,type) {
		var def = { id : id, name : name, type : type};
		this.byIdMap.h[id] = def;
		this.byNameMap.h[name] = def;
	}
	,findPropDefById: function(id) {
		return this.byIdMap.h[id];
	}
	,findPropDefByName: function(name) {
		return this.byNameMap.h[name];
	}
};
var formula_TokenDesc = $hxEnums["formula.TokenDesc"] = { __ename__:true,__constructs__:null
	,TkNum: ($_=function(value) { return {_hx_index:0,value:value,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkNum",$_.__params__ = ["value"],$_)
	,TkStr: ($_=function(value) { return {_hx_index:1,value:value,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkStr",$_.__params__ = ["value"],$_)
	,TkLparen: {_hx_name:"TkLparen",_hx_index:2,__enum__:"formula.TokenDesc",toString:$estr}
	,TkRparen: {_hx_name:"TkRparen",_hx_index:3,__enum__:"formula.TokenDesc",toString:$estr}
	,TkIdent: ($_=function(name) { return {_hx_index:4,name:name,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkIdent",$_.__params__ = ["name"],$_)
	,TkComma: {_hx_name:"TkComma",_hx_index:5,__enum__:"formula.TokenDesc",toString:$estr}
	,TkQuestion: {_hx_name:"TkQuestion",_hx_index:6,__enum__:"formula.TokenDesc",toString:$estr}
	,TkColon: {_hx_name:"TkColon",_hx_index:7,__enum__:"formula.TokenDesc",toString:$estr}
	,TkBinOp: ($_=function(op) { return {_hx_index:8,op:op,__enum__:"formula.TokenDesc",toString:$estr}; },$_._hx_name="TkBinOp",$_.__params__ = ["op"],$_)
	,TkEof: {_hx_name:"TkEof",_hx_index:9,__enum__:"formula.TokenDesc",toString:$estr}
};
formula_TokenDesc.__constructs__ = [formula_TokenDesc.TkNum,formula_TokenDesc.TkStr,formula_TokenDesc.TkLparen,formula_TokenDesc.TkRparen,formula_TokenDesc.TkIdent,formula_TokenDesc.TkComma,formula_TokenDesc.TkQuestion,formula_TokenDesc.TkColon,formula_TokenDesc.TkBinOp,formula_TokenDesc.TkEof];
var formula_TypeChecker = function(schema) {
	this.schema = schema;
};
formula_TypeChecker.__name__ = true;
formula_TypeChecker.prototype = {
	inferResult: function(func,args,fnPos,pos) {
		var def = formula_Builtins.singleton.findFuncDef(func);
		if(def == null) {
			throw new formula_ParseException(formula_Error.UndefinedFunction(func,fnPos));
		}
		var fnTy = def.type;
		var tvars_h = { };
		var check = function(exp,typSlot) {
			switch(typSlot._hx_index) {
			case 0:
				var _g = typSlot.type;
				if(exp.type != _g) {
					throw new formula_ParseException(formula_Error.TypeMismatch(exp,[_g]));
				}
				break;
			case 1:
				var _g = typSlot.id;
				if(tvars_h.hasOwnProperty(_g)) {
					var type = tvars_h[_g];
					if(exp.type != type) {
						throw new formula_ParseException(formula_Error.TypeMismatch(exp,[type]));
					}
				} else {
					var types = fnTy.typeVars.h[_g];
					if(types != null && types.indexOf(exp.type) == -1) {
						throw new formula_ParseException(formula_Error.TypeMismatch(exp,types));
					}
					tvars_h[_g] = exp.type;
				}
				break;
			}
		};
		if(args.length < fnTy.params.length) {
			throw new formula_ParseException(formula_Error.TooFewArguments(args.length,fnTy.params.length,pos));
		}
		var optParamsLen = fnTy.optParams == null ? 0 : fnTy.optParams.length;
		if(args.length > fnTy.params.length + optParamsLen && fnTy.variadic == null) {
			throw new formula_ParseException(formula_Error.TooManyArguments(args.length,fnTy.params.length,pos));
		}
		var _this = fnTy.params;
		var _g_current = 0;
		while(_g_current < _this.length) {
			var _g1_value = _this[_g_current++];
			check(args[_g_current - 1],_g1_value);
		}
		if(fnTy.optParams != null && args.length > fnTy.params.length) {
			var _g = fnTy.params.length;
			var _g1 = fnTy.params.length + optParamsLen;
			while(_g < _g1) {
				var i = _g++;
				check(args[i],fnTy.optParams[i - fnTy.params.length]);
			}
		}
		if(fnTy.variadic != null && args.length > fnTy.params.length + optParamsLen) {
			var _g = fnTy.params.length + optParamsLen;
			var _g1 = args.length;
			while(_g < _g1) check(args[_g++],fnTy.variadic);
		}
		if(func == "prop") {
			var prop = args[0];
			var name = formula_ExprTool.getStringValue(prop);
			var propDef = this.schema.findPropDefByName(name);
			if(propDef == null) {
				throw new formula_ParseException(formula_Error.UndefinedProp(name,prop.pos));
			}
			args[1] = { desc : formula_ExprDesc.EString(propDef.id), pos : prop.pos, type : formula_ValueType.TString};
			return propDef.type;
		}
		var _g = fnTy.ret;
		switch(_g._hx_index) {
		case 0:
			return _g.type;
		case 1:
			return tvars_h[_g.id];
		}
	}
	,infer: function(exp) {
		if(exp.type != null) {
			return exp.type;
		}
		var _g = exp.desc;
		var tmp;
		switch(_g._hx_index) {
		case 0:
			tmp = formula_ValueType.TNumber;
			break;
		case 1:
			tmp = formula_ValueType.TString;
			break;
		case 2:
			var _g1 = _g.$const;
			var def = formula_Builtins.singleton.findConstDef(_g1);
			if(def == null) {
				if(formula_Builtins.singleton.findFuncDef(_g1) != null) {
					throw new formula_ParseException(formula_Error.FunctionAsConstant(_g1,exp.pos));
				}
				throw new formula_ParseException(formula_Error.UndefinedConstant(_g1,exp.pos));
			}
			switch(def._hx_index) {
			case 0:
				tmp = formula_ValueType.TNumber;
				break;
			case 1:
				tmp = formula_ValueType.TString;
				break;
			case 2:
				tmp = formula_ValueType.TBoolean;
				break;
			case 3:
				tmp = formula_ValueType.TDate;
				break;
			}
			break;
		case 3:
			var _g1 = _g.fn;
			var _g2 = _g.fnPos;
			var _g3 = _g.args;
			var _g4 = 0;
			while(_g4 < _g3.length) this.infer(_g3[_g4++]);
			tmp = this.inferResult(_g1,_g3,_g2,exp.pos);
			break;
		case 4:
			tmp = this.infer(_g.exp);
			break;
		case 5:
			var _g1 = _g.opPos;
			var _g2 = _g.arg;
			this.infer(_g2);
			var func;
			switch(_g.op._hx_index) {
			case 0:
				func = "unaryPlus";
				break;
			case 1:
				func = "unaryMinus";
				break;
			case 2:
				func = "not";
				break;
			}
			tmp = this.inferResult(func,[_g2],_g1,exp.pos);
			break;
		case 6:
			var _g1 = _g.opPos;
			var _g2 = _g.arg1;
			var _g3 = _g.arg2;
			this.infer(_g2);
			this.infer(_g3);
			var func;
			switch(_g.op._hx_index) {
			case 0:
				func = _g2.type == formula_ValueType.TNumber ? "add" : "concat";
				break;
			case 1:
				func = "subtract";
				break;
			case 2:
				func = "multiply";
				break;
			case 3:
				func = "divide";
				break;
			case 4:
				func = "mod";
				break;
			case 5:
				func = "pow";
				break;
			case 6:
				func = "equal";
				break;
			case 7:
				func = "unequal";
				break;
			case 8:
				func = "smaller";
				break;
			case 9:
				func = "smallerEq";
				break;
			case 10:
				func = "larger";
				break;
			case 11:
				func = "largerEq";
				break;
			case 12:
				func = "and";
				break;
			case 13:
				func = "or";
				break;
			}
			tmp = this.inferResult(func,[_g2,_g3],_g1,exp.pos);
			break;
		case 7:
			var _g1 = _g.test;
			var _g2 = _g.then;
			var _g3 = _g.else_;
			this.infer(_g1);
			this.infer(_g2);
			this.infer(_g3);
			tmp = this.inferResult("if",[_g1,_g2,_g3],null,exp.pos);
			break;
		}
		return exp.type = tmp;
	}
	,check: function() {
	}
};
var haxe_ValueException = function(value,previous,native) {
	haxe_Exception.call(this,String(value),previous,native);
	this.value = value;
};
haxe_ValueException.__name__ = true;
haxe_ValueException.__super__ = haxe_Exception;
haxe_ValueException.prototype = $extend(haxe_Exception.prototype,{
	unwrap: function() {
		return this.value;
	}
});
var haxe_ds_GenericCell = function(elt,next) {
	this.elt = elt;
	this.next = next;
};
haxe_ds_GenericCell.__name__ = true;
var haxe_ds_IntMap = function() {
	this.h = { };
};
haxe_ds_IntMap.__name__ = true;
var haxe_ds_StringMap = function() {
	this.h = Object.create(null);
};
haxe_ds_StringMap.__name__ = true;
var haxe_io_Bytes = function(data) {
	this.length = data.byteLength;
	this.b = new Uint8Array(data);
	this.b.bufferValue = data;
	data.hxBytes = this;
	data.bytes = this.b;
};
haxe_io_Bytes.__name__ = true;
haxe_io_Bytes.ofString = function(s,encoding) {
	if(encoding == haxe_io_Encoding.RawNative) {
		var buf = new Uint8Array(s.length << 1);
		var _g = 0;
		var _g1 = s.length;
		while(_g < _g1) {
			var i = _g++;
			var c = s.charCodeAt(i);
			buf[i << 1] = c & 255;
			buf[i << 1 | 1] = c >> 8;
		}
		return new haxe_io_Bytes(buf.buffer);
	}
	var a = [];
	var i = 0;
	while(i < s.length) {
		var c = s.charCodeAt(i++);
		if(55296 <= c && c <= 56319) {
			c = c - 55232 << 10 | s.charCodeAt(i++) & 1023;
		}
		if(c <= 127) {
			a.push(c);
		} else if(c <= 2047) {
			a.push(192 | c >> 6);
			a.push(128 | c & 63);
		} else if(c <= 65535) {
			a.push(224 | c >> 12);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		} else {
			a.push(240 | c >> 18);
			a.push(128 | c >> 12 & 63);
			a.push(128 | c >> 6 & 63);
			a.push(128 | c & 63);
		}
	}
	return new haxe_io_Bytes(new Uint8Array(a).buffer);
};
haxe_io_Bytes.prototype = {
	getString: function(pos,len,encoding) {
		if(pos < 0 || len < 0 || pos + len > this.length) {
			throw haxe_Exception.thrown(haxe_io_Error.OutsideBounds);
		}
		if(encoding == null) {
			encoding = haxe_io_Encoding.UTF8;
		}
		var s = "";
		var b = this.b;
		var i = pos;
		var max = pos + len;
		switch(encoding._hx_index) {
		case 0:
			while(i < max) {
				var c = b[i++];
				if(c < 128) {
					if(c == 0) {
						break;
					}
					s += String.fromCodePoint(c);
				} else if(c < 224) {
					var code = (c & 63) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code);
				} else if(c < 240) {
					var code1 = (c & 31) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(code1);
				} else {
					var u = (c & 15) << 18 | (b[i++] & 127) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
					s += String.fromCodePoint(u);
				}
			}
			break;
		case 1:
			while(i < max) {
				var c = b[i++] | b[i++] << 8;
				s += String.fromCodePoint(c);
			}
			break;
		}
		return s;
	}
};
var haxe_io_Encoding = $hxEnums["haxe.io.Encoding"] = { __ename__:true,__constructs__:null
	,UTF8: {_hx_name:"UTF8",_hx_index:0,__enum__:"haxe.io.Encoding",toString:$estr}
	,RawNative: {_hx_name:"RawNative",_hx_index:1,__enum__:"haxe.io.Encoding",toString:$estr}
};
haxe_io_Encoding.__constructs__ = [haxe_io_Encoding.UTF8,haxe_io_Encoding.RawNative];
var haxe_io_Error = $hxEnums["haxe.io.Error"] = { __ename__:true,__constructs__:null
	,Blocked: {_hx_name:"Blocked",_hx_index:0,__enum__:"haxe.io.Error",toString:$estr}
	,Overflow: {_hx_name:"Overflow",_hx_index:1,__enum__:"haxe.io.Error",toString:$estr}
	,OutsideBounds: {_hx_name:"OutsideBounds",_hx_index:2,__enum__:"haxe.io.Error",toString:$estr}
	,Custom: ($_=function(e) { return {_hx_index:3,e:e,__enum__:"haxe.io.Error",toString:$estr}; },$_._hx_name="Custom",$_.__params__ = ["e"],$_)
};
haxe_io_Error.__constructs__ = [haxe_io_Error.Blocked,haxe_io_Error.Overflow,haxe_io_Error.OutsideBounds,haxe_io_Error.Custom];
var haxe_iterators_ArrayIterator = function(array) {
	this.current = 0;
	this.array = array;
};
haxe_iterators_ArrayIterator.__name__ = true;
haxe_iterators_ArrayIterator.prototype = {
	hasNext: function() {
		return this.current < this.array.length;
	}
	,next: function() {
		return this.array[this.current++];
	}
};
var hxparse_ParserError = function(pos) {
	this.pos = pos;
};
hxparse_ParserError.__name__ = true;
hxparse_ParserError.prototype = {
	toString: function() {
		return "Parser error";
	}
};
var hxparse_NoMatch = function(pos,token) {
	hxparse_ParserError.call(this,pos);
	this.token = token;
};
hxparse_NoMatch.__name__ = true;
hxparse_NoMatch.__super__ = hxparse_ParserError;
hxparse_NoMatch.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "No match: " + Std.string(this.token);
	}
});
var hxparse_Parser = function(stream) {
	this.stream = stream;
};
hxparse_Parser.__name__ = true;
hxparse_Parser.prototype = {
	peek: function(n) {
		if(this.token == null) {
			this.token = new haxe_ds_GenericCell(this.stream.token(),null);
			--n;
		}
		var tok = this.token;
		while(n > 0) {
			if(tok.next == null) {
				tok.next = new haxe_ds_GenericCell(this.stream.token(),null);
			}
			tok = tok.next;
			--n;
		}
		return tok.elt;
	}
	,junk: function() {
		this.last = this.token.elt;
		this.token = this.token.next;
	}
	,curPos: function() {
		return this.stream.curPos();
	}
	,parseSeparated: function(separatorFunc,f) {
		var acc = [];
		while(true) {
			try {
				acc.push(f());
			} catch( _g ) {
				if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
					break;
				} else {
					throw _g;
				}
			}
			if(separatorFunc(this.peek(0))) {
				this.last = this.token.elt;
				this.token = this.token.next;
			} else {
				break;
			}
		}
		return acc;
	}
	,parseOptional: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return null;
			} else {
				throw _g;
			}
		}
	}
	,parseRepeat: function(f) {
		var acc = [];
		while(true) try {
			acc.push(f());
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				return acc;
			} else {
				throw _g;
			}
		}
	}
	,parseExpect: function(f) {
		try {
			return f();
		} catch( _g ) {
			if(((haxe_Exception.caught(_g).unwrap()) instanceof hxparse_NoMatch)) {
				throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
			} else {
				throw _g;
			}
		}
	}
	,noMatch: function() {
		return new hxparse_NoMatch(this.stream.curPos(),this.peek(0));
	}
	,unexpected: function() {
		throw haxe_Exception.thrown(new hxparse_Unexpected(this.peek(0),this.stream.curPos()));
	}
};
var hxparse_Position = function(source,min,max) {
	this.psource = source;
	this.pmin = min;
	this.pmax = max;
};
hxparse_Position.__name__ = true;
hxparse_Position.union = function(p1,p2) {
	return new hxparse_Position(p1.psource,p1.pmin < p2.pmin ? p1.pmin : p2.pmin,p1.pmax > p2.pmax ? p1.pmax : p2.pmax);
};
hxparse_Position.prototype = {
	toString: function() {
		return "" + this.psource + ":characters " + this.pmin + "-" + this.pmax;
	}
	,getLinePosition: function(input) {
		var lineMin = 1;
		var lineMax = 1;
		var posMin = 0;
		var posMax = 0;
		var cur = 0;
		while(cur < this.pmin) {
			if(input.b[cur] == 10) {
				++lineMin;
				posMin = cur + 1;
			}
			++cur;
		}
		lineMax = lineMin;
		posMax = posMin;
		posMin = cur - posMin;
		while(cur < this.pmax) {
			if(input.b[cur] == 10) {
				++lineMax;
				posMax = cur + 1;
			}
			++cur;
		}
		posMax = cur - posMax;
		return { lineMin : lineMin, lineMax : lineMax, posMin : posMin, posMax : posMax};
	}
	,format: function(input) {
		var linePos = this.getLinePosition(input);
		if(linePos.lineMin != linePos.lineMax) {
			return "" + this.psource + ":lines " + linePos.lineMin + "-" + linePos.lineMax;
		} else {
			return "" + this.psource + ":" + linePos.lineMin + ": characters " + linePos.posMin + "-" + linePos.posMax;
		}
	}
};
var hxparse_Unexpected = function(token,pos) {
	hxparse_ParserError.call(this,pos);
	this.token = token;
};
hxparse_Unexpected.__name__ = true;
hxparse_Unexpected.__super__ = hxparse_ParserError;
hxparse_Unexpected.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "Unexpected " + Std.string(this.token);
	}
});
var hxparse_UnexpectedChar = function(char,pos) {
	hxparse_ParserError.call(this,pos);
	this.char = char;
};
hxparse_UnexpectedChar.__name__ = true;
hxparse_UnexpectedChar.__super__ = hxparse_ParserError;
hxparse_UnexpectedChar.prototype = $extend(hxparse_ParserError.prototype,{
	toString: function() {
		return "Unexpected " + this.char;
	}
});
function $getIterator(o) { if( o instanceof Array ) return new haxe_iterators_ArrayIterator(o); else return o.iterator(); }
function $bind(o,m) { if( m == null ) return null; if( m.__id__ == null ) m.__id__ = $global.$haxeUID++; var f; if( o.hx__closures__ == null ) o.hx__closures__ = {}; else f = o.hx__closures__[m.__id__]; if( f == null ) { f = m.bind(o); o.hx__closures__[m.__id__] = f; } return f; }
$global.$haxeUID |= 0;
if(typeof(performance) != "undefined" ? typeof(performance.now) == "function" : false) {
	HxOverrides.now = performance.now.bind(performance);
}
if( String.fromCodePoint == null ) String.fromCodePoint = function(c) { return c < 0x10000 ? String.fromCharCode(c) : String.fromCharCode((c>>10)+0xD7C0)+String.fromCharCode((c&0x3FF)+0xDC00); }
String.__name__ = true;
Array.__name__ = true;
Date.__name__ = "Date";
js_Boot.__toStr = ({ }).toString;
DateTools.DAY_SHORT_NAMES = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
DateTools.DAY_NAMES = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
DateTools.MONTH_SHORT_NAMES = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
DateTools.MONTH_NAMES = ["January","February","March","April","May","June","July","August","September","October","November","December"];
datetime_DateTime.UNIX_EPOCH_DIFF = 62135596800.0;
datetime_DateTime.SECONDS_IN_MINUTE = 60;
datetime_DateTime.SECONDS_IN_HOUR = 3600;
datetime_DateTime.SECONDS_IN_DAY = 86400;
datetime_DateTime.SECONDS_IN_WEEK = 604800;
datetime_DateTime.SECONDS_IN_YEAR = 31536000;
datetime_DateTime.SECONDS_IN_LEAP_YEAR = 31622400;
datetime_DateTime.SECONDS_IN_3_YEARS = 94608000;
datetime_DateTime.SECONDS_IN_QUAD = 126230400.0;
datetime_DateTime.SECONDS_IN_HALF_QUAD = 63072000.0;
datetime_DateTime.SECONDS_IN_HALF_QUAD_LEAP = 63158400.0;
datetime_DateTime.SECONDS_IN_3_PART_QUAD = 94694400.0;
datetime_DateTime.SECONDS_IN_CQUAD = 12622780800.0;
datetime_DateTime.SECONDS_IN_CENTURY = 3155673600.0;
datetime_DateTime.SECONDS_IN_LEAP_CENTURY = 3155760000.0;
formula_ValueTool.numberType = formula_ValueType.TNumber;
formula_ValueTool.stringType = formula_ValueType.TString;
formula_ValueTool.booleanType = formula_ValueType.TBoolean;
formula_ValueTool.dateType = formula_ValueType.TDate;
formula_Builtins.singleton = new formula_Builtins();
formula_Formula.regex = new EReg("\\bprop\\s*\\(\\s*(\"(?:[^\\\\\"]|\\\\.)*\")\\s*\\)","g");
formula_ObjcEvalContext.singleton = new formula_ObjcEvalContext();
formula_Formula.main();
})(typeof exports != "undefined" ? exports : typeof window != "undefined" ? window : typeof self != "undefined" ? self : this, typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : this);

export const formula = exports.formula;
const builtinsEn = builtins.map((item) => {
  const list = item.list.map(obj => {
    return {
      ...obj,
      examples: obj.examplesEn,
      description: obj.descriptionEn
    }
  })
  return { ...item, list}
})
export { builtins, builtinsEn };
