import { LOCAL_LNG } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { formatCurrency, formatFloat, numberToPercent } from 'src/utils/number';
import { NumberFormat, useOpenNumberFormat } from '../../widgets/number-format';
import type { CellViewProps } from '../types';
import { Site } from '../types';
import { renderNumProgress } from './render-num-process';
import { CopyButton } from 'src/bitable/copy-button';

export const formatNumber = (num: number, numberFormat: string | undefined) => {
  if (Number.isNaN(num)) return;

  if (!numberFormat || numberFormat === 'number') {
    // 和notion对齐，保留12位小数精度
    return formatFloat(num);
  }
  if (numberFormat === 'integer') {
    return Math.round(num);
  }
  if (numberFormat === 'oneDecimal') {
    return Number(num).toFixed(1);
  }
  if (numberFormat === 'twoDecimal') {
    return Number(num).toFixed(2);
  }
  if (numberFormat === 'thousandth') {
    const [integer, decimal] = String(num).split('.');
    let result = formatCurrency(integer ?? '');
    if (decimal) {
      result = result.concat('.', decimal);
    }
    return result;
  }
  if (numberFormat === 'percentOneDecimal') {
    return numberToPercent(num, 1);
  }
  if (numberFormat === 'percent') {
    return numberToPercent(num);
  }

  const symbol = NumberFormat.find((format) => format.en === numberFormat)?.symbol ?? '';
  return symbol.concat('', formatCurrency(Number(num).toFixed(2)));
};

export const NumValue: FC<CellViewProps> = ({ className, site, recordId, propertyId }) => {
  const { collectionId, readonly } = useBitable();
  const openNumberFormat = useOpenNumberFormat();
  const propertySchema = useObservableBlock(
    collectionId,
    (block) => block?.data.schema?.[propertyId]
  );
  const { numberFormat, showAs } = propertySchema ?? {};
  const num = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);

        const value = formulaTool.getValue(recordId, propertyId) as number | null;
        return value == null ? NaN : value;
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      const text = segmentsToText(segments);
      let num = parseFloat(text);
      if (Number.isNaN(num)) {
        num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
      }
      return num;
    },
    [collectionId, recordId, propertyId]
  );

  const renderNumber = formatNumber(num, numberFormat);

  let rightValue: ReactNode;
  if (num !== undefined) {
    rightValue = renderNumProgress({
      num: Math.max(0, num),
      showAs,
      numberFormat,
      from: 'num',
    });
  }
  return (
    <div
      className={cx(
        'group-scope number-box w-full overflow-hidden p-2 leading-[20px] min-h-[36px]',
        site !== Site.FIELD && 'text-right',
        className
      )}
    >
      {typeof renderNumber !== 'undefined' ? (
        <div className={cx('items-center flex min-h-[20px]', site !== Site.FIELD && 'justify-end')}>
          {rightValue && !showAs?.showNumber ? undefined : renderNumber}
          {rightValue}
        </div>
      ) : (
        site === Site.FIELD && <span className="text-grey4">{LOCAL_LNG.isEmpty}</span>
      )}

      {(site === Site.CELL || site === Site.FIELD) && !readonly && (
        <button
          onClick={(event) => openNumberFormat(event, propertyId)}
          className={cx(
            'absolute top-1.5 flex items-center justify-center w-6 h-6 rounded cursor-pointer border-grey6 border bg-white1 animate-hover-opaque opacity-0',
            {
              'left-1': site === Site.CELL,
              'right-8': site === Site.FIELD,
            }
          )}
        >
          <Icon name="IcMenuNum" size="middle" className="text-grey3" />
        </button>
      )}
      <CopyButton
        text={`${renderNumber}`}
        site={site}
        className={site === Site.CELL ? 'left-8' : 'right-1'}
      />
    </div>
  );
};
