import { useCallback } from 'react';
import { request } from 'src/common/request';
import { updatePageMetas } from 'src/services/page-metas';

export const useRestoreToSnapshot = () => {
  return useCallback(async (pageId: string, snapshotId: string) => {
    await request.editor.restoreDocHistory(pageId, snapshotId);
    updatePageMetas({ [pageId]: { tag: 'CACHE_EXPIRED', timestamp: Date.now() } });
  }, []);
};
