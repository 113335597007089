import { getDateStrBySegment } from '@flowus/common';
import type { BlockDataDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { cache } from 'src/redux/store';
import { getMentionBlockShowInfo } from 'src/utils/get-mention-block-show-info';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { v4 as uuid4 } from 'uuid';

export const SUPPORT_DEEP = 1; // 目前仅支持2层处理，0是第一层

export const segmentsToText = (
  segments: SegmentDTO[] | null = [],
  deep = 0,
  includeAt = true
): string => {
  if (!segments) segments = [];
  return segments
    .map((item) => {
      if (item.type === TextType.LINK_PAGE && item.uuid) {
        if (deep > SUPPORT_DEEP) return '';
        const page = cache.blocks[item.uuid];
        if (page) {
          const symbol = includeAt ? '@' : '';
          return `${symbol}${
            segmentsToText(page.data.segments, deep + 1, includeAt) || getUntitledName(page.type)
          }`;
        }
        return '';
      } else if ([TextType.DATETIME, TextType.DATE].includes(item.type)) {
        const dateValue = getDateStrBySegment(item);
        return `@${dateValue}`;
      } else if (item.type === TextType.USER && item.uuid) {
        return `@${getUserName(item.uuid)}`;
      } else if (item.type === TextType.MENTION_BLOCK && item.uuid) {
        const symbol = includeAt ? '@' : '';
        if (deep > SUPPORT_DEEP) return `${symbol}块引用`;

        const block = cache.blocks[item.uuid];
        if (!block) return `${symbol}块引用`;

        if (block.type === BlockType.PDF_ANNOTATION) {
          return `${symbol}${block.data.pdfAnnotation?.text ?? ''}`;
        }
        if (item.text) return item.text;

        const { showPage } = getMentionBlockShowInfo(block.type);
        const pageId = getOwnerPage(item.uuid);
        if (!pageId) return `${symbol}块引用`;
        if (showPage) {
          const page = cache.blocks[item.uuid];
          if (page) {
            return `${symbol}${
              segmentsToText(page.data.segments, deep + 1) || getUntitledName(page.type)
            }`;
          }
        }
        return `${symbol}${
          segmentsToText(block.data.segments, deep + 1) || getUntitledName(block.type)
        }`;
      }
      return item.text;
    })
    .join('');
};

export const textToSegments = (text?: string | number): SegmentDTO[] => {
  if (text == null) return [];
  return [{ text: text.toString(), type: TextType.TEXT, enhancer: {} }];
};

export const isBlockNoText = (data?: Pick<BlockDataDTO, 'segments'>) => {
  if (!data || !data.segments || data.segments.length === 0) return true;
  return !segmentsToText(data.segments) && data.segments.length === 0;
};

export const newFileSegment = (props: {
  name: string;
  ossName?: string;
  size?: number;
  width?: number;
  height?: number;
}) => {
  const { name, ossName, size, width, height } = props;
  const segment: SegmentDTO = {
    uuid: uuid4(),
    type: TextType.URL,
    enhancer: {},
    fileStorageType: 'internal',
    text: name,
    url: ossName,
    size,
    width,
    height,
  };
  return segment;
};
