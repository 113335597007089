import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { deepEqual } from '@flowus/common/utils/tools';
import { boxBox } from 'intersects';
import isHotkey from 'is-hotkey';
import { Provider as JotaiProvider } from 'jotai';
import type { CSSProperties, FC } from 'react';
import { memo, useMemo } from 'react';
import { MOUSE_RIGHT } from 'src/common/const';
import { $rowId, ROW_SCOPE } from 'src/editor/editor/uikit/block-states';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { useEditorModelKey } from 'src/editor/editor/uikit/editable/helper';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import { useThrottleUpdateProperty } from 'src/hooks/block/use-throttle-update-property';
import { INLINE_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useReadonly } from 'src/hooks/page';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useTransaction } from 'src/hooks/use-transaction';
import { cleanSelectBlock } from 'src/hooks/utils/clean-select';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { SelectCell } from 'src/redux/types';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { blurDocument } from 'src/utils/dom';
import { focusEditable } from 'src/utils/editor';
import { usePickBlock } from 'src/utils/pick-block';
import { v4 as uuid4 } from 'uuid';
import { useSyncId } from '../sync-block-context';
import { DEFAULT_COL_WIDTH, getTableCellEditorKey } from './helper';
import { TableCellHandle } from './table-cell-handle';
import { useTableContext } from './table-context';

interface Props {
  propertyId: string;
  rowIndex: number;
  columnIndex: number;
  span: { rowSpan?: number; colSpan?: number };
}

export const TableCell: FC<Props> = memo(({ propertyId, rowIndex, columnIndex, span }) => {
  useIsDarkMode();
  const { recordId, tableId, indexRanges } = useTableContext();
  const editorId = useFinalValue(uuid4);
  const editorKey = useEditorModelKey(editorId);
  const syncId = useSyncId();
  const record = usePickBlock(recordId, ['data'], ['format']);
  const segments = usePropertySegments(recordId, propertyId);
  const col = useObservableBlock(tableId, (block) => {
    return block?.data.format?.tableBlockColumnFormat?.[propertyId];
  });
  const updatePropertyValue = useThrottleUpdateProperty(tableId, recordId, propertyId, editorKey);

  const isHeader = useObservableBlock(
    tableId,
    (table) => {
      const format = table?.data.format;
      const isRowHeader = format?.tableBlockRowHeader && rowIndex === 0;
      const isColumnHeader = format?.tableBlockColumnHeader && columnIndex === 0;
      return isRowHeader || isColumnHeader;
    },
    [rowIndex, columnIndex]
  );

  const isSelected = useObservableStore(
    ({ ui: { selectedCells } }) => {
      return selectedCells.some((o) => o.recordId === recordId && o.propertyId === propertyId);
    },
    [recordId, propertyId, tableId],
    { obsSelectCell: [{ recordId, propertyId, viewId: tableId }] }
  );

  const { isFocus, hidden } = useObservableStore(
    ({ simpleTable }) => {
      const { activityTableId, draggingStart, chosenCells, focus } = simpleTable;
      return {
        isFocus: activityTableId === tableId && focus,
        /** 拖拽时盖住拖拽中的单元格 */
        hidden:
          activityTableId === tableId &&
          draggingStart &&
          (chosenCells?.recordId === recordId || chosenCells?.propertyId === propertyId),
      };
    },
    [tableId],
    { obsSimpleTable: true }
  );

  const readonly = useReadonly(tableId);

  const width = useObservableBlock(
    tableId,
    (table) => {
      const subNodes = table?.subNodes ?? [];
      const format = table?.data.format ?? {};
      const tableFormat = format?.tableBlockColumnFormat ?? {};
      let _width = tableFormat[propertyId]?.width ?? DEFAULT_COL_WIDTH;

      const range = indexRanges.find(
        ([start, end]) =>
          start[0] === 0 &&
          end[0] === subNodes.length - 1 &&
          start[1] === columnIndex &&
          end[1] !== columnIndex
      );

      if (range) {
        const [start, end] = range;
        const startColumnIndex = start[1];
        const endColumnIndex = end[1];
        const columns = format.tableBlockColumnOrder ?? [];
        columns.slice(startColumnIndex + 1, endColumnIndex + 1).forEach((columnId) => {
          _width += tableFormat[columnId]?.width ?? DEFAULT_COL_WIDTH;
        });
      }

      return _width;
    },
    [columnIndex, propertyId],
    { waitMode: 'throttle' }
  );

  const transaction = useTransaction();

  const recordFormat = record?.data.format ?? {};
  const style = useMemo(() => {
    const _style: CSSProperties = { width };
    const columnTextColor = getBlockTextColor(col?.textColor);
    const rowTextColor = getBlockTextColor(recordFormat?.textColor);
    const colBackgroundColor = getBlockBackgroundColor(col?.backgroundColor);
    const rowBackgroundColor = getBlockBackgroundColor(recordFormat?.backgroundColor);
    if (columnTextColor) {
      _style.color = columnTextColor;
    } else if (colBackgroundColor) {
      _style.backgroundColor = colBackgroundColor;
    } else if (rowTextColor) {
      _style.color = rowTextColor;
    } else if (rowBackgroundColor) {
      _style.backgroundColor = rowBackgroundColor;
    }

    return _style;
  }, [
    col?.backgroundColor,
    col?.textColor,
    recordFormat?.backgroundColor,
    recordFormat?.textColor,
    width,
  ]);

  if (!record) return null;

  return (
    <td
      data-table-row-id={recordId}
      data-table-row-index={rowIndex}
      data-table-column-index={columnIndex}
      className={cx(
        'relative border border-grey6 break-words p-2 transition-all ease-out',
        isHeader ? 'text-t2-medium bg-grey8' : 'text-t2',
        isSelected && isFocus && 'focused-table-cell'
      )}
      style={style}
      {...span}
      onMouseDown={(event) => {
        event.stopPropagation();
        const isRightClick = event.button === MOUSE_RIGHT;
        if (isRightClick) {
          blurDocument(true);
          if (isSelected) {
            return;
          }
        } else {
          if ((event.target as HTMLElement).tagName === 'TD') {
            focusEditable(editorKey, event.clientX, event.clientY);
          }
        }

        dispatch(
          uiActions.update({ selectedCells: [{ recordId, propertyId, viewId: tableId, syncId }] })
        );
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            focus: !isRightClick,
            chosenCells: undefined,
            selecting: { tableId, columnIndex, rowIndex },
          })
        );

        const mouseup = () => {
          dispatch(simpleTableActions.update({ selecting: undefined }));

          document.removeEventListener('mouseup', mouseup);
        };

        document.addEventListener('mouseup', mouseup);
      }}
      onMouseEnter={() => {
        const { selecting } = cache.simpleTable;
        if (!selecting) return;
        if (selecting.tableId !== tableId) return;
        const table = cache.blocks[tableId];
        if (!table) return;

        blurDocument(true);

        const { subNodes, data } = table;
        const { tableBlockColumnOrder = [] } = data.format ?? {};

        type Pos = [number, number];

        const start: Pos = [selecting.rowIndex, selecting.columnIndex];
        const end: Pos = [rowIndex, columnIndex];

        let ranges = indexRanges;

        const loop = () => {
          for (const range of ranges) {
            const intersected = boxBox(
              Math.min(start[1], end[1]),
              Math.min(start[0], end[0]),
              Math.abs(end[1] - start[1]) + 1,
              Math.abs(end[0] - start[0]) + 1,
              range[0][1],
              range[0][0],
              Math.abs(range[0][1] - range[1][1]) + 1,
              Math.abs(range[0][0] - range[1][0]) + 1
            );

            if (intersected) {
              ranges = ranges.filter((_range) => _range !== range);
              start[0] = Math.min(range[0][0], start[0], rowIndex);
              start[1] = Math.min(range[0][1], start[1], columnIndex);
              end[0] = Math.max(range[1][0], end[0], selecting.rowIndex);
              end[1] = Math.max(range[1][1], end[1], selecting.columnIndex);
              loop();
            }
          }
        };

        if (indexRanges.length) {
          loop();
        }

        const getIds = (ids: string[], end: number, start: number) => {
          const first = Math.min(start, end);
          const last = Math.max(start, end);
          return ids.slice(first, last + 1);
        };
        const recordIds = getIds(subNodes, end[0], start[0]);
        const columnIds = getIds(tableBlockColumnOrder, end[1], start[1]);

        const selectedCells: SelectCell[] = [];
        for (const rId of recordIds) {
          for (const cId of columnIds) {
            // TODO: filter if cell is merged
            selectedCells.push({
              recordId: rId,
              propertyId: cId,
              viewId: tableId,
              syncId,
            });
          }
        }

        dispatch(uiActions.update({ selectedCells }));
        dispatch(simpleTableActions.update({ focus: false }));
      }}
      onKeyDown={(event) => {
        if (isHotkey(['Backspace', 'Delete'], { byKey: true })(event)) {
          event.stopPropagation();
          return;
        }

        if (isHotkey('Shift+Enter')(event)) {
          event.preventDefault();
          const editorModel = getEditorModelByEditorKey(editorKey);
          editorModel?.performChange((ctx) => {
            ctx.delete().insert('\n');
          });
          return;
        }

        if (isHotkey('Enter')(event)) {
          event.preventDefault();
          event.stopPropagation();

          const table = cache.blocks[tableId];
          if (!table) return;

          const { subNodes } = table;

          if (rowIndex + 1 >= subNodes.length) {
            transaction(() => {
              const newId = addBlock({}, { parentId: table.parentId, after: tableId });
              dispatch(
                HISTORY_EFFECTS({
                  init() {
                    cleanSelectBlock('cell');
                    setTimeout(() => focusEditable(newId, Infinity, Infinity));
                  },
                  redo() {
                    cleanSelectBlock('cell');
                  },
                  undo() {
                    dispatch(
                      uiActions.update({
                        selectedCells: [{ recordId, propertyId, viewId: tableId, syncId }],
                      })
                    );
                    focusEditable(editorKey, Infinity, Infinity);
                  },
                })
              );
            });
          } else {
            const rid = subNodes[rowIndex + 1];
            if (rid) {
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId: rid, propertyId, viewId: tableId, syncId }],
                })
              );
              const _editorKey = getTableCellEditorKey(tableId, rowIndex + 1, columnIndex);
              focusEditable(_editorKey, Infinity, Infinity);
            }
          }
        }
      }}
    >
      {hidden && (
        <div
          className={cx('w-full h-full bg-white1 absolute left-0 top-0', isHeader && '!bg-grey8')}
        ></div>
      )}

      {isSelected && !isFocus && (
        <div className="w-full h-full absolute left-0 top-0 bg-grey6/30 pointer-events-none"></div>
      )}

      <JotaiProvider scope={ROW_SCOPE} initialValues={[[$rowId, recordId]]}>
        <RichTextEdit
          realBlockId={recordId}
          readonly={readonly}
          uuid={editorId}
          className={'whitespace-break-spaces'}
          segments={segments}
          keydownOption={INLINE_KEYDOWN_OPTION}
          onChange={(segments, prevContent, prevSelection) => {
            updatePropertyValue(segments, [prevContent, prevSelection]);
          }}
          history={false}
          comments={true}
        />
      </JotaiProvider>

      {!readonly && (columnIndex === 0 || rowIndex === 0) && (
        <TableCellHandle rowIndex={rowIndex} columnIndex={columnIndex} propertyId={propertyId} />
      )}

      {!readonly && rowIndex === 0 && columnIndex === 0 && (
        <TableCellHandle
          isRowHandle
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          propertyId={propertyId}
        />
      )}
    </td>
  );
}, deepEqual);
