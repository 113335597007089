import { message } from 'src/common/components/message';

export const loadingMessage = (key: string, title: string) => {
  message.loading({
    key,
    content: (
      <div className="flex items-center space-x-3">
        <div>{title}</div>
        <div onClick={() => message.closeMessage(key)} className="text-active_color animate-click">
          关闭提示
        </div>
      </div>
    ),
  });
};
