import type { MindMapEngine } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import { LRUCache } from 'src/common/utils/lru-cache';
import { cache } from 'src/redux/store';

const mindEngineMap = new Map<string, MindMapEngine>();

export const getMindMapEngine = (rootId: string) => {
  return mindEngineMap.get(rootId);
};

export const putMindMapEngine = (rootId: string, engine: MindMapEngine) => {
  mindEngineMap.set(rootId, engine);
};

export const deleteMindMapEngine = (rootId: string) => {
  mindEngineMap.delete(rootId);
};

export const getMindMapEngineBySelectBlock = () => {
  const mindMapId = cache.ui.selectedBlocks[0]?.mindMapId;
  if (!mindMapId) return;
  const engine = getMindMapEngine(mindMapId);
  return engine;
};

export const supportAppendChild = (id: string) => {
  const block = cache.blocks[id];
  if (!block) return false;
  if (block.type === BlockType.FILE && block.data.display === 'image') {
    return true;
  }
  return [
    BlockType.TEXTAREA,
    BlockType.HEADER,
    BlockType.QUOTE,
    BlockType.TODO,
    BlockType.LIST,
    BlockType.ORDER_LIST,
    BlockType.FOLD_LIST,
    BlockType.TOGGLE_HEADER,
    BlockType.MARK,
    BlockType.MIND_MAPPING,
    BlockType.MIND_MAPPING_PAGE,
  ].includes(block.type);
};

export const canAppendAttachment = (id: string) => {
  const block = cache.blocks[id];
  if (!block) return false;
  if (block.type === BlockType.FILE && block.data.display === 'image') {
    return true;
  }
};
export const canAppendImage = (id: string) => {
  const block = cache.blocks[id];
  if (!block) return false;
  if (block.type === BlockType.FILE && block.data.display === 'image') {
    return true;
  }
};

export const saveMindMapScale = (engine: MindMapEngine, scale: number, isEmbed: boolean) => {
  scaleMap.put(getSaveKey(engine.getState().rootId, isEmbed), scale);
};

export const getMindMapScale = (mindMapId: string, isEmbed: boolean) => {
  return scaleMap.get(getSaveKey(mindMapId, isEmbed));
};
const getSaveKey = (mindMapId: string, isEmbed: boolean) => {
  return isEmbed ? `embed_${mindMapId}` : mindMapId;
};

export const getMindMapScrollInfo = (mindMapId: string, isEmbed: boolean) => {
  return scrollMap.get(getSaveKey(mindMapId, isEmbed));
};
export const saveMindMapScrollInfo = (
  mindMapId: string,
  isEmbed: boolean,
  scrollInfo: { left: number; top: number }
) => {
  scrollMap.put(getSaveKey(mindMapId, isEmbed), scrollInfo);
};
const scaleMap = new LRUCache<string, number>(localStorage, 'mindMap-scale', 30);
const scrollMap = new LRUCache<string, { left: number; top: number }>(
  localStorage,
  'mindMap-scroll',
  30
);
// duplicate,过两个版本删掉
new LRUCache<string, number>(localStorage, 'embed-mindMap-scale', 30).clear();
