import { FileRegex } from '../regex';
import { getFileNameInfo } from '../utils/oss-to-filename';

export const canPreViewImage = (ossName?: string) => {
  const { extName } = getFileNameInfo(ossName);

  if (!extName || !ossName) return;
  if (FileRegex.image.test(extName.toLocaleLowerCase())) {
    return ossName;
  }
  if (FileRegex.video.test(extName.toLocaleLowerCase())) {
    return ossName;
  }

  if (isOfficePreView(extName)) {
    const [prefix] = ossName.split('/');
    if (!prefix) return;
    const newOssName = ossName
      ?.replace(prefix, 'preview')
      .replace(extName.toLocaleLowerCase(), 'png');
    return newOssName;
  }
};

export const isOfficePreView = (extName: string) => {
  if (
    FileRegex.office.test(extName) ||
    FileRegex.txt.test(extName) ||
    FileRegex.pdf.test(extName) ||
    FileRegex.csv.test(extName)
  ) {
    return true;
  }
  return false;
};
