import { IpcMessage } from '@flowus/shared';
import { useEffect } from 'react';
import { ipcSendMessage, isFlowUsApp } from 'src/utils/electron-util';

export const useDoubleClick = () => {
  useEffect(() => {
    if (!isFlowUsApp.check) return;
    const doubleClick = (event: Event) => {
      const { className } = event.target as HTMLElement;
      if (className?.includes?.('drag-container')) {
        ipcSendMessage(IpcMessage.Zoom);
      }
    };

    document.addEventListener('dblclick', doubleClick, true);
    return () => {
      document.removeEventListener('dblclick', doubleClick, true);
    };
  }, []);
};
