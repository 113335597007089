import store from './store';

let frequently, initialized;
let defaults = {};

function init() {
  initialized = true;
  frequently = store.get('frequently_v1');
}

function add(emoji) {
  if (!initialized) init();
  //保存最近使用记录的时候需要把肤色也一起保存,所以从id改为colons
  const { colons } = emoji;

  frequently || (frequently = defaults);
  frequently[colons] || (frequently[colons] = 0);
  frequently[colons] += 1;
  store.set('last', colons);
  store.set('frequently_v1', frequently);
}

function get(perLine) {
  if (!initialized) init();
  if (!frequently) {
    //最近记录没有默认值
    defaults = {};
    const result = [];
    return result;
  }

  const quantity = perLine * 4;
  const frequentlyKeys = [];

  for (const key in frequently) {
    // eslint-disable-next-line no-prototype-builtins
    if (frequently.hasOwnProperty(key)) {
      frequentlyKeys.push(key);
    }
  }

  const sorted = frequentlyKeys.sort((a, b) => frequently[a] - frequently[b]).reverse();
  const sliced = sorted.slice(0, quantity);

  const last = store.get('last');

  if (last && sliced.indexOf(last) === -1) {
    sliced.pop();
    sliced.push(last);
  }
  return sliced;
}
const obj = {
  add,
  get,
};

export default obj;
