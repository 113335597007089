import { contains } from '../core/bitwise';
import { DISPLAY } from '../css/property-descriptors/display';
import { OVERFLOW } from '../css/property-descriptors/overflow';
import { POSITION } from '../css/property-descriptors/position';
import { createCounterText } from '../css/types/functions/counter';
import type { ElementContainer } from '../dom/element-container';
import { FLAGS } from '../dom/element-container';
import { LIElementContainer } from '../dom/elements/li-element-container';
import { OLElementContainer } from '../dom/elements/ol-element-container';
import { BoundCurves, calculateBorderBoxPath, calculatePaddingBoxPath } from './bound-curves';
import type { IElementEffect } from './effects';
import { ClipEffect, EffectTarget, isClipEffect, OpacityEffect, TransformEffect } from './effects';
import { equalPath } from './path';

export class StackingContext {
  element: ElementPaint;
  negativeZIndex: StackingContext[];
  zeroOrAutoZIndexOrTransformedOrOpacity: StackingContext[];
  positiveZIndex: StackingContext[];
  nonPositionedFloats: StackingContext[];
  nonPositionedInlineLevel: StackingContext[];
  inlineLevel: ElementPaint[];
  nonInlineLevel: ElementPaint[];

  constructor(container: ElementPaint) {
    this.element = container;
    this.inlineLevel = [];
    this.nonInlineLevel = [];
    this.negativeZIndex = [];
    this.zeroOrAutoZIndexOrTransformedOrOpacity = [];
    this.positiveZIndex = [];
    this.nonPositionedFloats = [];
    this.nonPositionedInlineLevel = [];
  }
}

export class ElementPaint {
  readonly effects: IElementEffect[] = [];
  readonly curves: BoundCurves;
  listValue?: string;

  constructor(readonly container: ElementContainer, readonly parent: ElementPaint | null) {
    this.curves = new BoundCurves(this.container);
    if (this.container.styles.opacity < 1) {
      this.effects.push(new OpacityEffect(this.container.styles.opacity));
    }

    if (this.container.styles.transform !== null) {
      const offsetX = this.container.bounds.left + this.container.styles.transformOrigin[0].number;
      const offsetY = this.container.bounds.top + this.container.styles.transformOrigin[1].number;
      const matrix = this.container.styles.transform;
      this.effects.push(new TransformEffect(offsetX, offsetY, matrix));
    }

    if (this.container.styles.overflowX !== OVERFLOW.VISIBLE) {
      const borderBox = calculateBorderBoxPath(this.curves);
      const paddingBox = calculatePaddingBoxPath(this.curves);

      if (equalPath(borderBox, paddingBox)) {
        this.effects.push(
          new ClipEffect(borderBox, EffectTarget.BACKGROUND_BORDERS | EffectTarget.CONTENT)
        );
      } else {
        this.effects.push(new ClipEffect(borderBox, EffectTarget.BACKGROUND_BORDERS));
        this.effects.push(new ClipEffect(paddingBox, EffectTarget.CONTENT));
      }
    }
  }

  getEffects(target: EffectTarget): IElementEffect[] {
    let inFlow = [POSITION.ABSOLUTE, POSITION.FIXED].indexOf(this.container.styles.position) === -1;
    let { parent } = this;
    const effects = this.effects.slice(0);
    while (parent) {
      const croplessEffects = parent.effects.filter((effect) => !isClipEffect(effect));
      if (inFlow || parent.container.styles.position !== POSITION.STATIC || !parent.parent) {
        effects.unshift(...croplessEffects);
        inFlow =
          [POSITION.ABSOLUTE, POSITION.FIXED].indexOf(parent.container.styles.position) === -1;
        if (parent.container.styles.overflowX !== OVERFLOW.VISIBLE) {
          const borderBox = calculateBorderBoxPath(parent.curves);
          const paddingBox = calculatePaddingBoxPath(parent.curves);
          if (!equalPath(borderBox, paddingBox)) {
            effects.unshift(
              new ClipEffect(paddingBox, EffectTarget.BACKGROUND_BORDERS | EffectTarget.CONTENT)
            );
          }
        }
      } else {
        effects.unshift(...croplessEffects);
      }

      parent = parent.parent;
    }

    return effects.filter((effect) => contains(effect.target, target));
  }
}

const parseStackTree = (
  parent: ElementPaint,
  stackingContext: StackingContext,
  realStackingContext: StackingContext,
  listItems: ElementPaint[]
) => {
  parent.container.elements.forEach((child) => {
    const treatAsRealStackingContext = contains(child.flags, FLAGS.CREATES_REAL_STACKING_CONTEXT);
    const createsStackingContext = contains(child.flags, FLAGS.CREATES_STACKING_CONTEXT);
    const paintContainer = new ElementPaint(child, parent);
    if (contains(child.styles.display, DISPLAY.LIST_ITEM)) {
      listItems.push(paintContainer);
    }

    const listOwnerItems = contains(child.flags, FLAGS.IS_LIST_OWNER) ? [] : listItems;

    if (treatAsRealStackingContext || createsStackingContext) {
      const parentStack =
        treatAsRealStackingContext || child.styles.isPositioned()
          ? realStackingContext
          : stackingContext;

      const stack = new StackingContext(paintContainer);

      if (child.styles.isPositioned() || child.styles.opacity < 1 || child.styles.isTransformed()) {
        const { order } = child.styles.zIndex;
        if (order < 0) {
          let index = 0;

          parentStack.negativeZIndex.some((current, i) => {
            if (order > current.element.container.styles.zIndex.order) {
              index = i;
              return false;
            } else if (index > 0) {
              return true;
            }

            return false;
          });
          parentStack.negativeZIndex.splice(index, 0, stack);
        } else if (order > 0) {
          let index = 0;
          parentStack.positiveZIndex.some((current, i) => {
            if (order >= current.element.container.styles.zIndex.order) {
              index = i + 1;
              return false;
            } else if (index > 0) {
              return true;
            }

            return false;
          });
          parentStack.positiveZIndex.splice(index, 0, stack);
        } else {
          parentStack.zeroOrAutoZIndexOrTransformedOrOpacity.push(stack);
        }
      } else {
        if (child.styles.isFloating()) {
          parentStack.nonPositionedFloats.push(stack);
        } else {
          parentStack.nonPositionedInlineLevel.push(stack);
        }
      }

      parseStackTree(
        paintContainer,
        stack,
        treatAsRealStackingContext ? stack : realStackingContext,
        listOwnerItems
      );
    } else {
      if (child.styles.isInlineLevel()) {
        stackingContext.inlineLevel.push(paintContainer);
      } else {
        stackingContext.nonInlineLevel.push(paintContainer);
      }

      parseStackTree(paintContainer, stackingContext, realStackingContext, listOwnerItems);
    }

    if (contains(child.flags, FLAGS.IS_LIST_OWNER)) {
      processListItems(child, listOwnerItems);
    }
  });
};

const processListItems = (owner: ElementContainer, elements: ElementPaint[]) => {
  let numbering = owner instanceof OLElementContainer ? owner.start : 1;
  const reversed = owner instanceof OLElementContainer ? owner.reversed : false;
  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < elements.length; i++) {
    const item = elements[i]!;
    if (
      item.container instanceof LIElementContainer &&
      typeof item.container.value === 'number' &&
      item.container.value !== 0
    ) {
      numbering = item.container.value;
    }

    item.listValue = createCounterText(numbering, item.container.styles.listStyleType, true);

    numbering += reversed ? -1 : 1;
  }
};

export const parseStackingContexts = (container: ElementContainer): StackingContext => {
  const paintContainer = new ElementPaint(container, null);
  const root = new StackingContext(paintContainer);
  const listItems: ElementPaint[] = [];
  parseStackTree(paintContainer, root, root, listItems);
  processListItems(paintContainer.container, listItems);
  return root;
};
