import type { BlockDTO, SearchBlockDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';

type SearchBody = Omit<
  Awaited<Parameters<typeof request.infra.searchDocs>[1]> & { space: string },
  'source'
> & { source: 'quickFind' | 'quickFindPublic' };

export const useGlobalSearch = () => {
  return useCallback(async (searchBody: SearchBody) => {
    const { space, ...rest } = searchBody;
    const res = await request.infra.searchDocs(space, rest);
    const blocks = res.recordMap?.blocks;
    if (!blocks) return { hasMore: false, results: [], total: res.total ?? 0 };
    const getNavs = (uuid: string) => {
      const navs = [];
      let curr = blocks[uuid];

      while (curr && curr.spaceId !== curr.uuid) {
        curr = blocks[curr.parentId];
        if (curr && isPageLike(curr.type)) {
          navs.unshift(curr);
        }
      }
      return navs;
    };
    dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
    const results: (SearchBlockDTO & { navs: BlockDTO[] })[] = [];
    res.results.forEach((item) => {
      if (item.pageId) {
        const navs = getNavs(item.pageId);
        results.push({
          ...item,
          navs,
        });
      }
    });

    return {
      hasMore: res.results.length === searchBody.perPage,
      results,
      total: res.total ?? 0,
    };
  }, []);
};
