import { sleep } from '@flowus/common/async';
import { enhanceImageCache } from '@flowus/common/hooks/file';
import { getImageBlobByExternalUrl, getProxyImageUrl } from '@flowus/common/proxy-url';
import { addImageWatermark, isOssUrl } from '@flowus/common/url';
import { useTrackedEffect } from 'ahooks';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import { PhotoProvider, PhotoSlider } from 'react-photo-view-kcsx';
import type { PhotoRenderParams } from 'react-photo-view-kcsx/dist/types';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { useModel } from 'src/common/create-model';
import { fastEqual } from '@flowus/common/utils/tools';
import * as Empty from 'src/components/empty';
import { useResource } from 'src/hooks/public/use-resource';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { setAppUiState, useBodyHeight, useBodyWidth } from 'src/services/app';
import { isFlowUsApp } from 'src/utils/electron-util';
import { useFileImageBlockIds } from '../file-preview/hook';
import { OthersPreview } from '../file-preview/other';
import { ImagesOverlayRender } from './components';
import { ImageProviderContext } from './provider';
import { useBlock } from 'src/hooks/block/use-block';
import { useOpenWatermark } from 'src/hooks/space/use-open-watermark';
import { useOpenImageWatermark } from 'src/hooks/space/use-open-image-watermark';

interface Props {
  uuid?: string;
}

export const ImagesProvider: FC<Props> = ({ children, uuid }) => {
  if (uuid) {
    return (
      <ImageProviderContext>
        <SliderContent uuid={uuid} />
        {children}
      </ImageProviderContext>
    );
  }
  return (
    <ImageProviderContext>
      <ProviderContent uuid={uuid}>{children}</ProviderContent>
    </ImageProviderContext>
  );
};

const ProviderContent: FC<Props> = ({ children }) => {
  return (
    <PhotoProvider
      minDragScale={0.5}
      onVisibleChange={(visible) => {
        setAppUiState({ $isOpenImagePreview: visible });
      }}
      maskOpacity={0.8}
      speed={() => 200}
      maskClosable
      loop={false}
      bannerVisible={false}
      photoClosable={false}
      loadingElement={<LoadingIcon />}
      overlayRender={(overlayProps) => {
        return <ImagesOverlayRender overlayProps={overlayProps} />;
      }}
    >
      {children}
    </PhotoProvider>
  );
};

const SliderContent: FC<{ uuid: string }> = memo(({ uuid }) => {
  const bodyWidth = useBodyWidth();
  const bodyHeight = useBodyHeight();
  const { index, visible, images, setImages, onClose, setIndex } = useModel(ImageProviderContext);
  const current = images[index];
  const fileImageBlockIds = useFileImageBlockIds({ uuid, pageId: uuid });

  useTrackedEffect(
    (_: any, pre: any, cur: any) => {
      const check = fastEqual(pre?.[0], cur?.[0]);
      if (!check) {
        const newArr = fileImageBlockIds.map((i) => ({ src: undefined, key: i }));
        setImages(newArr);
        const cur = newArr[index];
        if (!cur) {
          onClose();
        }
      }
    },
    [fileImageBlockIds, setImages]
  );

  const changeReferrerPolicy = () => {
    if (isFlowUsApp.check) return;
    const photoDoms = [
      ...document.getElementsByClassName('PhotoView__Photo'),
    ] as HTMLImageElement[];

    photoDoms.forEach((dom) => {
      const { src } = dom;

      if (!isOssUrl(src)) {
        dom.src = '';
        dom.referrerPolicy = 'no-referrer';
        dom.src = src;
      }
    });
  };

  useEffect(() => {
    if (!visible) return;
    void sleep(200).then(changeReferrerPolicy);
  }, [visible]);

  useEffect(() => {
    changeReferrerPolicy();
  }, [index]);

  return (
    <>
      <PhotoSlider
        minDragScale={0.5}
        index={index}
        visible={visible}
        images={images.map((item) => ({
          ...item,
          width: item.src ? undefined : bodyWidth,
          height: item.src ? undefined : bodyHeight,
          render: (attrs: PhotoRenderParams) => {
            let content = <></>;
            if (current?.key !== item.key) {
              content = <LoadingIcon />;
            }
            content = <LoadBlockImage {...attrs} uuid={item.key} />;
            return <CustomerRender {...attrs}>{content}</CustomerRender>;
          },
        }))}
        brokenElement={<OthersPreview />}
        onClose={onClose}
        maskOpacity={0.8}
        speed={() => 200}
        maskClosable
        loop={false}
        bannerVisible={false}
        onIndexChange={setIndex}
        loadingElement={<LoadingIcon />}
        overlayRender={(overlayProps) => {
          return (
            <ImagesOverlayRender
              hiddenImageToolbar={!current?.src}
              previewId={current?.key}
              overlayProps={overlayProps}
            />
          );
        }}
      />
    </>
  );
});

interface InitImageProps {
  uuid: string;
}

const LoadBlockImage: FC<InitImageProps> = (props) => {
  const { uuid } = props;
  const { illegal } = usePermissions(uuid);
  const { imageUrl: _imageUrl, loading } = useResource(illegal ? '' : uuid, { isImage: true });
  let imageUrl = _imageUrl;
  const block = useBlock(uuid);
  const { openImageWatermark, imageWatermarkText } = useOpenImageWatermark(block?.spaceId);
  if (openImageWatermark && imageWatermarkText) {
    imageUrl = addImageWatermark(_imageUrl, imageWatermarkText);
  }

  if (illegal) {
    return <Empty.NoPermission className="p-0" uuid={uuid} />;
  }

  return (
    <>
      <LoadingIcon />
      {!loading && imageUrl && <ImageProxy item={{ uuid, src: imageUrl }} />}
    </>
  );
};

const CustomerRender: FC<PhotoRenderParams> = (props) => {
  const { attrs, scale, children } = props;
  const elementSize = useBodyWidth();

  const width = Number(attrs.style?.width) ?? 0;
  const offset = (width - elementSize) / elementSize;
  const childScale = scale === 1 ? scale + offset : 1 + offset;

  return (
    <div {...attrs}>
      <div
        style={{ transform: `scale(${childScale})`, width: elementSize, transformOrigin: '0 0' }}
      >
        <div className="w-screen h-screen flex items-center justify-center">{children}</div>
      </div>
    </div>
  );
};

const ImageProxy: FC<{ item: { src: string; uuid: string } }> = (props) => {
  const { onLoad } = useModel(ImageProviderContext);
  const { item } = props;
  const [src, setSrc] = useState(item?.src);
  const isOssSrc = isOssUrl(src);

  useEffect(() => {
    const cacheUrl = enhanceImageCache.get(src);
    if (cacheUrl) {
      onLoad(cacheUrl, item.uuid);
    } else if (isOssSrc) {
      onLoad(item.src, item.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onError = () => {
    if (!item.src.startsWith('blob')) {
      const proxyUrl = getProxyImageUrl(item.src);
      void getImageBlobByExternalUrl(proxyUrl, {
        callback: (res) => {
          const proxyUrl = URL.createObjectURL(res.blob);
          onLoad(proxyUrl, item.uuid);
          setSrc(proxyUrl);
        },
        onError: () => {
          onLoad(item.src, item.uuid);
        },
      });
    }
  };

  return (
    <img
      style={{ width: 0, height: 0, opacity: 0 }}
      src={src}
      onLoad={() => {
        onLoad(item.src, item.uuid);
      }}
      referrerPolicy={isOssSrc ? 'strict-origin-when-cross-origin' : 'no-referrer'}
      loading="lazy"
      onError={onError}
    />
  );
};
