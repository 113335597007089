import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';

/** 添加评论 DOM */
export const AddCommentButton: FC<{ blockId: string }> = (props) => {
  const openCommentPopup = useOpenNewDiscussionPopup();
  return (
    <div
      data-disable-select
      onClick={() => {
        openCommentPopup({
          blockId: props.blockId,
        });
      }}
    >
      <Icon name="IcComment" size="small" className="pointer-events-none" />
    </div>
  );
};
