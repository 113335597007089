import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import type { IFormat } from '@next-space/fe-inlined';
import type React from 'react';
import { useComputeCommentStyles } from './comment';

export const useComputeTextStyle = (format: IFormat): React.CSSProperties => {
  const textDecoration: string[] = [];

  if (format.overline) {
    textDecoration.push('overline');
  }

  if (format.dashed) {
    textDecoration.push('dashed');
  }

  if (format.lineThrough) {
    textDecoration.push('line-through');
  }

  const commentStyles = useComputeCommentStyles(format);
  if (commentStyles.textDecoration != null) {
    textDecoration.push(commentStyles.textDecoration as string);
  }

  const styles: React.CSSProperties = {
    ...commentStyles,
    borderBottom: format.underline ? '1px solid' : undefined,
    fontWeight: format.bold ? '600' : undefined,
    fontStyle: format.italic ? 'italic' : undefined,
    color: format.color != null ? getBlockTextColor(format.color) : undefined,
  };

  if (textDecoration.length > 0) {
    styles.textDecoration = textDecoration.join(' ');
  }
  if (format.backgroundColor != null) {
    styles.background = getBlockBackgroundColor(format.backgroundColor);
    styles.borderRadius = '2px';
  }

  return styles;
};
