import type { FC, MouseEvent } from 'react';
import { SettingModalCommon, SettingProvider } from '../common';
import { Option } from '../option';
import { useOpenModal } from '@flowus/common/next-modal';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { keys } from 'lodash-es';
import { ShowInLinePageIconType } from '@next-space/fe-api-idl';
import { useModel } from '@flowus/common/create-model';

const OptionText = {
  [ShowInLinePageIconType.SHOW_ALL]: '显示所有图标',
  [ShowInLinePageIconType.SHOW_CUSTOM]: '仅显示自定义图标',
  [ShowInLinePageIconType.HIDE]: '隐藏图标',
};

export const PageIconSetting: FC = () => {
  const openModal = useOpenModal();
  const { spaceSettingState } = useModel(SettingProvider);
  const { inlinePageIcon, setInlinePageIcon } = spaceSettingState;

  const handleClick = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: (_) => (
        <ListView
          className={listViewNormalClassName}
          items={keys(OptionText).map((key) => ({
            type: ListItemType.OPERABLE_BLOCK_TEXT,
            data: {
              key,
              selected: key === inlinePageIcon,
              title: OptionText[key as ShowInLinePageIconType],
            },
          }))}
          onItemClick={(item) => {
            setInlinePageIcon(item.data.key);
            _.onCloseModal();
          }}
        />
      ),
    });
  };

  return (
    <Option
      title="行内页面展示图标"
      description="设置行内页面图标的显示和隐藏"
      rightPart={
        <SettingModalCommon.DropdownButton
          onClick={handleClick}
          content={OptionText[inlinePageIcon]}
        />
      }
    />
  );
};
