import { getMarkBlockBorderColor } from '@flowus/common/block/color/get-block-color';
import { ColorKey } from 'src/colors';
import { Callout } from 'src/editor/component/call-out';
import { saveLastMarkBlockInfo } from 'src/editor/utils/mark-block-utils';
import { useReadonly } from 'src/hooks/page';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

/**
 * 着重block创建的时候默认给一个grey背景
 * 且默认文字颜色key为空，如果文字颜色和背景颜色都为空的话，就显示一个外边框
 */
export const MarkElement: BlockElement = ({ id, root, children }) => {
  useIsDarkMode();
  const block = usePickBlock(id, ['textColor', 'backgroundColor']);
  const readonly = useReadonly(id);

  if (!block) return null;

  const borderColor = getBorderColor(block.textColor, block.backgroundColor);
  return (
    <BlockDrop
      id={id}
      className="border border-transparent my-1 rounded"
      horizontal={root}
      style={{
        // 如果用户设置了文字颜色，则需要加一个同样颜色的外边框
        borderColor,
      }}
    >
      <Callout
        id={id}
        readonly={readonly}
        className="pr-4 leading-8"
        onChange={(icon) => {
          saveLastMarkBlockInfo({ icon });
        }}
      >
        <BlockDrop dropInChild className="my-1 overflow-auto" bindId={false} id={id}>
          <Editable uuid={id} className="leading-6" placeholder="着重文字" />
        </BlockDrop>
        {children && <div className="-ml-0.5 mb-1">{children}</div>}
      </Callout>
    </BlockDrop>
  );
};

/**
 * 外边框颜色是基础色的20%
 */
export const getBorderColor = (textColor: string, backgroundColor: string) => {
  // 如果都没有值，默认会有个外边框
  if (!textColor && !backgroundColor) getMarkBlockBorderColor(textColor || ColorKey.grey);
  if (backgroundColor) return;

  return getMarkBlockBorderColor(textColor || ColorKey.grey);
};
