import type { IEditorModel } from '@next-space/fe-inlined';
import { useCallback, useRef } from 'react';
import type { Placement } from '@popperjs/core';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { Modals } from 'src/modals';
import { $appUiStateCache } from 'src/services/app';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { useIsInRight } from 'src/utils/right-utils';
import { AIEditor } from '.';
import type { AIEditorScene, AIEditType } from './const';

export const useOpenAIEditor = () => {
  const openModal = useOpenModal();
  const isInRight = useIsInRight();
  const { enableAI } = useEnableAI();
  const pageContainer = useRef(querySelectorFromMainContent(`.next-space-page`, isInRight));

  return useCallback(
    (options: {
      popcorn: ModalSchema.PopcornType;
      editorScene: AIEditorScene;
      blockId: string;
      model?: IEditorModel;
      offset?: number[];
      editType?: AIEditType;
      from: string;
      selection?: { start: number; end?: number };
      isBitable?: boolean;
      isMindMap?: boolean;
      isEmbedMindMap?: boolean; // 我也不想的
      isDescription?: boolean;
      showInput?: boolean;
      placement?: Placement;
      defaultCustomPrompt?: string;
    }) => {
      if (!enableAI) return;
      const {
        popcorn,
        editorScene,
        blockId,
        model,
        offset,
        editType,
        selection,
        from,
        isBitable,
        isMindMap,
        isDescription,
        showInput = true,
        defaultCustomPrompt,
        placement = 'bottom-start',
      } = options;

      openModal.dropdown({
        modalId: Modals.AI_EDITOR,
        popcorn,
        offset: offset ?? [0, 2],
        // 下面的代码导致弹窗后页面跳动一下位置
        // modifiers: [
        //   {
        //     name: 'checkHeight',
        //     enabled: true,
        //     phase: 'afterWrite',
        //     fn: ({ state }) => {
        //       const popperTop = state.rects.popper.y;
        //       if (!popperTop || !pageContainer.current) return state;

        //       const bodyHeight = $appUiStateCache.$bodyHeight / 2;

        //       if (popperTop > bodyHeight) {
        //         const scrollTop = pageContainer.current.scrollTop + popperTop - bodyHeight;
        //         pageContainer.current.scrollTop = scrollTop;
        //       }
        //       return state;
        //     },
        //   },
        // ],
        placement,
        disableEsc: true,
        autoClose: false,
        blockMaskClick: false,
        content({ onCloseModal, popper }) {
          return (
            <AIEditor
              defaultCustomPrompt={defaultCustomPrompt}
              showInput={showInput}
              popcorn={popcorn}
              isInRight={isInRight}
              model={model}
              blockId={blockId}
              popper={popper}
              closeSelf={onCloseModal}
              editorScene={editorScene}
              editType={editType}
              from={from}
              selection={selection}
              isBitable={isBitable}
              isMindMap={isMindMap}
              isDescription={isDescription}
            />
          );
        },
      });
    },
    [enableAI, isInRight, openModal]
  );
};
