import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { debounce, throttle } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import { updateViewFormat } from './use-update-collection-view';

export const useUpdateColumnWidth = (viewId: string, propertyId: string) => {
  const update = useCallback(
    (width: number, ignoreOp: boolean) => {
      const { collectionViews } = getState();
      const view = collectionViews[viewId];
      if (!view) return;

      const isTimeline = view.type === CollectionViewType.TIMELINE;
      const oldProperties = isTimeline
        ? view.format.timelineTableProperties ?? []
        : view.format.tableProperties ?? [];
      const columnIndex = oldProperties.findIndex((it) => it.property === propertyId);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const column = oldProperties[columnIndex]!;
      const newProperties = [...oldProperties];
      newProperties[columnIndex] = { ...column, width };

      const newFormat: CollectionViewDTO['format'] = {};
      if (isTimeline) {
        newFormat.timelineTableProperties = newProperties;
      } else {
        newFormat.tableProperties = newProperties;
      }

      if (ignoreOp) {
        CollectionViewManager.update(viewId, { format: newFormat }, true);
      } else {
        updateViewFormat(viewId, newFormat);
      }
    },
    [propertyId, viewId]
  );

  const sync = useMemo(() => {
    return debounce((width) => {
      update(width, false);
    }, 500);
  }, [update]);

  return useCallback(
    (width: number) => {
      update(width, true);
      sync(width);
    },
    [sync, update]
  );
};
