import {
  BlockStatus,
  BlockType,
  PermissionRole,
  PermissionType,
  TextType,
} from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { useGetAncestors } from 'src/hooks/block/use-get-ancestors';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useCurrentUser } from 'src/hooks/user';
import { cache } from 'src/redux/store';
import { isCollection } from 'src/utils/block-type-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { CancelLink } from '../cancel-link';
import { IconLink } from '../icon-link';

interface Props {
  pageId: string;
  index: number;
  uuid: string;
  callback?: () => void;
  isLocked?: boolean;
}

export const BackLink: FC<Props> = ({ pageId, uuid, index, callback, isLocked }) => {
  const currentUser = useCurrentUser();
  const getAncestors = useGetAncestors();
  const isTeamSpace = useIsTeamSpace();
  const openPage = useOpenPage();
  const block = usePickBlock(
    uuid,
    ['data'],
    ['ref', 'segments', 'caption', 'collectionProperties']
  );

  const parentIsCollection = useMemo(() => {
    if (!block) return false;
    const parent = cache.blocks[block.parentId];
    if (!parent) return false;
    return isCollection(parent.type);
  }, [block]);

  if (!block) return null;

  const isTableRow = block.type === BlockType.TABLE_ROW;
  const segments = block.type === BlockType.FILE ? block.data.caption : block.data.segments;
  return (
    <div
      onClick={(event) => {
        openPage(pageId, {
          hash: `#${isTableRow ? block.parentId : uuid}`,
          forceOpenInRight: event.altKey,
          forceOpenNewTab: event.ctrlKey || event.metaKey,
        });
      }}
      className="animate-hover-black3 py-1 group relative flex items-center justify-between mt-1 text-t2-medium ml-10"
    >
      <div className="flex w-full">
        <div className="flex flex-shrink-0 pl-2 justify-end text-right text-t2">{index + 1}.</div>
        {block.type === BlockType.REFERENCE ? (
          <IconLink uuid={block.data.ref?.uuid ?? ''} />
        ) : isTableRow || parentIsCollection ? (
          <div className="flex divide-x-[1px] divide-grey5 space-x-2 items-center h-full flex-wrap">
            {parentIsCollection && block.data.segments?.length && (
              <div className="text-t3 pointer-events-none flex items-center">
                {segmentsToText(block.data.segments)}
              </div>
            )}
            {Object.entries(block.data.collectionProperties ?? {}).map(([key, segments], i) => {
              const _segments = segments.filter((s) => {
                if (parentIsCollection) {
                  const parent = cache.blocks[block.parentId];
                  if (!parent) return null;
                  const haveColumn = parent.data.schema?.[key];
                  if (!haveColumn) return false;
                }
                if (s.type === TextType.LINK_PAGE && s.uuid) {
                  const page = cache.blocks[s.uuid];
                  return page ? page.status === BlockStatus.NORMAL : false;
                }
                return true;
              });
              return _segments.length ? (
                <RichText
                  key={i}
                  segments={_segments}
                  className="pl-2 text-t3 h-[18px] leading-[10px] text-grey3 pointer-events-none flex items-center whitespace-nowrap align-sub"
                />
              ) : null;
            })}
          </div>
        ) : (
          <RichText segments={segments} className="text-t3 line-clamp-2 pointer-events-none" />
        )}

        {getPermissions(uuid).permissions.every((o) => {
          const lastBlockId = last([...getAncestors(uuid)]);
          if (lastBlockId !== block.spaceId || !isTeamSpace) {
            return false;
          }
          if (o.type === PermissionType.SPACE || o.type === PermissionType.GROUP) {
            return o.role === PermissionRole.NONE;
          }
          if (o.type === PermissionType.USER) {
            return o.role !== PermissionRole.NONE && o.userId === currentUser.uuid;
          }
          return true;
        }) && (
          <span className="flex flex-shrink-0 items-center text-red text-t4-medium">
            <Icon name="IcLock" size="middle" />
            <span>仅我可见</span>
          </span>
        )}
      </div>

      {!isLocked && <CancelLink uuid={uuid} callback={callback} />}
    </div>
  );
};
