import produce from 'immer';
import { assign } from 'lodash-es';
import { $desktopState, $setDesktopState } from './state';
import type { DesktopStateType } from './type';

export const setDesktopState = (value: Partial<DesktopStateType>) => {
  $setDesktopState(produce((pre) => assign(pre, value)));
};

export const useIsFullscreen = () => {
  return $desktopState((state) => state.isFullscreen);
};

export const useIsUpdateReload = () => {
  return $desktopState((state) => state.isUpdateReload);
};

export const useIsUpdateAvailable = () => {
  return $desktopState((state) => state.isUpdateAvailable);
};
