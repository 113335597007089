import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo, useLayoutEffect, useRef } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { useVisible } from 'src/common/hooks/use-visible';
import { IframeLitePage } from 'src/components/page-preview/lite-page';
import { useTryFetchPageBlock } from 'src/components/page-preview/use-try-fetch-page-block';
import { ThumbPageCardDescContent } from 'src/editor/component/page-block';
import { segmentsToText } from 'src/editor/utils/editor';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScrollRefContext } from 'src/views/main/page-doc/context';
import { BackgroundContainer } from './background-container';
import type { PageProps } from './types';
import { useCoverImageUrl } from './use-cover-image-url';

export const LargeThumbPageCard: FC<PageProps> = memo((props) => {
  const { id, illegal, showCover, desc, isInColumn } = props;
  const hasCover = !!useCoverImageUrl(id) && showCover;
  const [visible, setRef] = useVisible<HTMLDivElement>();
  const loading = useTryFetchPageBlock(id, visible);
  const block = usePickBlock(id, ['data'], ['segments']);
  const divRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>();
  const { width } = useDebounceElementSize(container.current, { type: 'width' });
  const title = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);

  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '250px',
    };
  }

  useLayoutEffect(() => {
    if (!visible || loading) return;

    const footer = divRef.current?.querySelector('.footer');
    if (footer instanceof HTMLElement) {
      footer.hidden = true;
    }
    const pageContainer =
      divRef.current?.querySelector(`#${ID_DRIVE}`) ??
      divRef.current?.querySelector('.next-space-page');
    if (pageContainer instanceof HTMLElement) {
      pageContainer.childNodes.forEach((c) => {
        if (c instanceof HTMLElement) {
          c.setAttribute('style', `padding-left:12px;padding-right:12px`);
        }
      });
    }
  }, [loading, visible]);
  // 显示说明文字的ui跟不显示说明文字的ui布局不一样
  if (desc) {
    return (
      <BackgroundContainer
        blockId={id}
        ref={(ref) => {
          setRef(ref);
          if (ref != null) {
            container.current = ref;
          }
        }}
        showCover={showCover}
        {...attribute}
        className={cx('overflow-hidden flex relative w-[400px] h-[184px] animate-hover border', {
          'w-full': isInColumn,
        })}
        onClick={(e) => {
          const selection = window.getSelection();
          if (!selection) return;
          if (selection.isCollapsed) {
            props.onClick?.(e);
          }
        }}
      >
        <ThumbPageCardDescContent
          title={title}
          blockType={block?.type}
          desc={desc}
          hasCover={hasCover}
        />
        <div
          ref={divRef}
          className="self-end mr-4 px-2.5 bottom-0 w-[75%] min-w-[188px] h-[70%] border-t border-l border-r overflow-hidden bg-white1"
          style={{
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <PageScrollRefContext.Provider value={divRef}>
            <PageContent id={id} />
          </PageScrollRefContext.Provider>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <BackgroundContainer
      blockId={id}
      ref={(ref) => {
        setRef(ref);
        if (ref != null) {
          container.current = ref;
        }
      }}
      showCover={showCover}
      {...attribute}
      className={cx(
        'overflow-hidden flex flex-col relative w-[240px] h-[184px] animate-hover border',
        {
          'w-full': isInColumn,
        }
      )}
      onClick={(e) => {
        const selection = window.getSelection();
        if (!selection) return;
        if (selection.isCollapsed) {
          props.onClick?.(e);
        }
      }}
    >
      <div
        className={cx('text-ellipsis ml-4 mt-4 text-t1-bold text-black', {
          'text-white': hasCover,
        })}
      >
        {title || getUntitledName(block?.type)}
      </div>

      <div
        ref={divRef}
        className={cx(
          'mt-4 w-[75%] min-w-[188px] h-[70%] border-t border-l border-r overflow-hidden bg-white1',
          {
            'self-end mr-4': width && width > 250,
            'ml-[55px]': width && width < 250,
          }
        )}
        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <PageContent id={id} />
      </div>
    </BackgroundContainer>
  );
});

const PageContent: FC<{
  id: string;
}> = memo((props) => {
  const { id } = props;

  return (
    <div className={'zoom-03 w-[333%] overflow-hidden h-[500px] pointer-events-none'}>
      <IframeLitePage id={id} />
    </div>
  );
});
