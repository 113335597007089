import { isUrl } from '@flowus/common/embed-website';
import { $searchParams } from 'src/utils';
import { VITE_PREFIX } from 'src/env';
import { isFlowUsApp } from './electron-util';
import { judgeSharePage } from './getPageId';
import { decodeName } from '@flowus/common/utils';

/**
 * 统一处理 location.href 兼容electron
 * 登录相关直接访问一个链接而不是走路由。（否则前进后退导致各种问题）
 * 只有主页面才走路由，其它页面跳转都走这个方法，避免路由引起各种乱七八糟的问题
 */
export const locationToUrl = (path: string, extendSearchPath?: boolean) => {
  if (window.location.pathname !== path) {
    const query = new URLSearchParams(decodeName(location.search));
    const queryString = query.toString();
    const searchQuery = extendSearchPath && queryString ? `?${queryString}` : '';

    if (isFlowUsApp.check) {
      if (isUrl(path)) {
        const url = new URL(path);
        window.location.href = `${url.pathname}${url.search || ''}`;
      } else {
        window.location.href = `${path}${searchQuery}`;
      }
    } else {
      window.location.href = isUrl(path)
        ? `${path}${searchQuery}`
        : `${getLocationOrigin()}${path}`;
    }
  }
};

/** 统一处理 location.replace 兼容electron */
export const locationReplace = (path: string) => {
  if (isFlowUsApp.check) {
    if (isUrl(path)) {
      const url = new URL(path);
      window.location.replace(`${url.pathname}${url.search || ''}`);
    } else {
      window.location.replace(path);
    }
  } else {
    const url = isUrl(path) ? path : `${getLocationOrigin()}${path}`;
    window.location.replace(url);
  }
};

export const getLocationOrigin = () => (isFlowUsApp.check ? VITE_PREFIX : window.location.origin);

export const previewPrefix = () => (judgeSharePage() ? 'share' : 'preview');

export const isBitablePDFPreview = () => $searchParams.ossName && $searchParams.propertyId;
