import { BlockType } from '@next-space/fe-api-idl';
import type { IEditorModel } from '@next-space/fe-inlined';
import { newContent, newElement } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { INLINE_LINK_PAGE_TAG } from 'src/editor/editor/inline/const';
import { textToSegments } from 'src/editor/utils/editor';
import { useOpenNew } from 'src/hooks/block/use-open-new';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import type { NextBlock } from 'src/redux/types';
import { sequence } from 'src/utils/async-utils';

interface CreateInlinePageSegments {
  left: number;
  right: number;
  blockId: string;
  editorModel: IEditorModel;
  title?: string;
  setFakeFocus?: (status: boolean) => void;
}

export const useCreateInlinePageSegment = () => {
  const transaction = useTransaction();
  const openNew = useOpenNew();

  return useCallback(
    (props: CreateInlinePageSegments) => {
      const { setFakeFocus, left, blockId, editorModel, title = '' } = props;

      if (!editorModel.selection) return;
      const { endOffset } = editorModel.selection;

      const block: Partial<NextBlock> = { type: BlockType.PAGE };

      if (title) {
        block.data = { segments: textToSegments(title) };
      }

      transaction(
        () => {
          const pageId = addBlock(block, { parentId: blockId, inline: true });

          let offset = endOffset;

          if (!setFakeFocus && title) {
            offset = left + title.length + 1;
          }

          editorModel.performChange((ctx) => {
            ctx
              .shadow()
              .select(left, offset)
              .replace(
                newContent([
                  newElement(INLINE_LINK_PAGE_TAG, {
                    pageId,
                  }),
                ])
              );

            ctx.select(offset);
          });

          if (!title) {
            void sequence(() => {
              openNew(pageId);
            });
          }
        },
        { noThrottle: true }
      );
    },
    [openNew, transaction]
  );
};
