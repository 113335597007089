/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cx } from '@flowus/common/cx';
import { MindMapContext } from '@flowus/mind-map';
import type { DiscussionDTO } from '@next-space/fe-api-idl';
import { BlockStatus, BlockType } from '@next-space/fe-api-idl';
import { useContext } from 'react';
import { Icon } from 'src/common/components/icon';
import { isLikeFoldBlock } from 'src/redux/managers/ui/use-fold';
import { getState } from 'src/redux/store';
import type { RootState } from 'src/redux/types';
import { $appUiStateCache } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { isPageLike } from 'src/utils/block-type-utils';
import { useOpenCategoriedDiscussionsPopup } from './comment-hooks';

export const BlockDiscussionsBadge = (props: { blockId: string; className?: string }) => {
  const isInMindMap = !!useContext(MindMapContext);
  const iterDiscussions = function* (
    blocks: RootState['blocks'],
    blockId: string,
    forceCountSubNodes = false
  ): IterableIterator<string> {
    const block = blocks[blockId];
    const isPage = isPageLike(block?.type);
    // 本身是页面或者子级不是页面才算个数
    if (props.blockId === blockId || !isPage) {
      const discussions = block?.discussions ?? [];
      yield* discussions;
    }

    const isCollapsed =
      block && isLikeFoldBlock(block?.type) && !$appUiStateCache.$expandFoldRecord[block.uuid];
    const isTable = block?.type === BlockType.TABLE;
    const countSubNodes = forceCountSubNodes || isCollapsed || isTable;

    // 子页面不统计
    if (countSubNodes && !isPage) {
      for (const subNodeId of block?.subNodes ?? []) {
        yield* iterDiscussions(blocks, subNodeId, forceCountSubNodes || isCollapsed);
      }
    }
  };
  const numDiscussions = useObservableStore((state) => {
    let numOpen = 0;
    for (const discussionId of iterDiscussions(state.blocks, props.blockId)) {
      const discussion = state.discussions[discussionId];
      if (discussion != null && !discussion.resolved) {
        for (const commentId of discussion.comments) {
          const comment = state.comments[commentId];
          if (comment != null && comment.status === BlockStatus.NORMAL) {
            numOpen += 1;
          }
        }
      }
    }
    return numOpen;
  }, []);

  const openCommentPopup = useOpenCategoriedDiscussionsPopup();

  if (numDiscussions <= 0) return null;

  return (
    <div
      data-comment-block-id={props.blockId}
      className={cx(
        'whitespace-nowrap text-grey3 text-t4 flex items-center animate-hover px-1',
        props.className
      )}
      onClick={(event) => {
        const relDiv = event.currentTarget.closest('.relative')!;
        const discussionIds = [...iterDiscussions(getState().blocks, props.blockId)];
        if (discussionIds.length <= 0) return;

        openCommentPopup({
          placement: isInMindMap ? 'right' : 'bottom',
          popcorn: relDiv,
          selector: (state) => {
            const discussionIds = [...iterDiscussions(state.blocks, props.blockId)];
            return discussionIds
              .map((it) => state.discussions[it])
              .filter((it) => it != null) as DiscussionDTO[];
          },
        });
      }}
    >
      <Icon size="normal" name="IcMenuReply" className="w-5 h-5 mr-0.5" />
      {numDiscussions > 9 ? '9+' : numDiscussions}
    </div>
  );
};
