import { fastEqual } from '@flowus/common';
import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { cache, getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';

const ancestorsSelector = createSelector(
  (blocks: RootState['blocks'], _id: string) => blocks,
  (_state: RootState['blocks'], id: string) => id,
  (blocks, id) => {
    return getAncestors(id, blocks);
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
    },
  }
);

export const useGetAncestors = () => {
  const getAncestors = (id: string) => {
    return ancestorsSelector(getState().blocks, id);
  };

  return useCallback(getAncestors, []);
};

export const getAncestorsToPageLike = (id: string) => {
  let block = cache.blocks[id];
  const blocks: NextBlock[] = [];

  while (block) {
    blocks.push(block);
    if (isPageLike(block?.type) && block.uuid !== id) {
      return blocks;
    }
    block = cache.blocks[block.parentId];
  }

  return blocks;
};

/**
 *
 * @param ancestorId 面包屑截止至ancestorId
 */
export const getAncestors = (id: string, blocks = getState().blocks, ancestorId?: string) => {
  const result = new Set<string>();
  let current = blocks[id];
  const ids = new Set();
  while (current) {
    if (ids.has(current.uuid)) break;
    ids.add(current.uuid);
    result.add(current.uuid);
    if (ancestorId && current.uuid === ancestorId) {
      return result;
    }
    current = blocks[current.parentId];
  }
  return result;
};
