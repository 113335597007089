import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

import type { ScrollRef } from '../types';
import { usePosition } from '../use-position';

export interface Context {
  contentRef: ScrollRef<HTMLElement>;
  count: number;
  bufferSize?: number;
  scrollRef: ScrollRef | undefined;
  direction?: 'vertical' | 'horizontal';
  getItemSize(index: number): number;
  getItemKey(index: number): string;
  renderItem(index: number): ReactNode;
}

export const ListContext = createContext<Context | undefined>(undefined);

export const ListProvider: FC<Context> = (props) => {
  return <ListContext.Provider value={props}>{props.children}</ListContext.Provider>;
};

export const useList = () => {
  const ctx = useContext(ListContext);
  if (!ctx) throw new Error('useList 需要在 ListProvider 内使用');

  const { getItemSize, scrollRef, count, direction } = ctx;
  const getItemOffset = usePosition(getItemSize);

  const lastIdx = count - 1;
  const totalSize = getItemOffset(lastIdx) + getItemSize(lastIdx);

  const scrollTo = (index: number) => {
    const $scrollX = scrollRef?.current ?? window;
    const offset = getItemOffset(index);
    if (typeof $scrollX.scrollTo === 'function') {
      $scrollX.scrollTo({
        [direction === 'horizontal' ? 'left' : 'top']: offset,
        behavior: 'smooth',
      });
    } else if ($scrollX instanceof Element) {
      $scrollX[direction === 'horizontal' ? 'scrollLeft' : 'scrollTop'] = offset;
    }
  };

  return {
    ...ctx,
    getItemOffset,
    scrollTo,
    totalSize,
  };
};
