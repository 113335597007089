import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

export interface NetworkStatusService {
  readonly status: NetworkStatus;
  readonly online: boolean;
  readonly offline: boolean;
  readonly onStatusChange: Observable<void>;

  setStatus(status: NetworkStatus): void;
}

export type NetworkStatus = 'online' | 'offline';

class NetworkStatusServiceImpl implements NetworkStatusService {
  status: NetworkStatus = 'online';
  private statusChange$ = new Subject<void>();

  get online() {
    return this.status === 'online';
  }

  get offline() {
    return this.status === 'offline';
  }

  setStatus(status: NetworkStatus) {
    if (this.status !== status) {
      this.status = status;
      this.statusChange$.next();
    }
  }

  get onStatusChange() {
    return this.statusChange$.asObservable();
  }
}

export const $networkStatus: NetworkStatusService = new NetworkStatusServiceImpl();
