import {
  getDataBaseProgressBarBgColor,
  getDataBaseProgressBarColor,
} from '@flowus/common/block/color/get-block-color';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { NumberShowType } from '@next-space/fe-api-idl';
import { Bar } from 'src/common/components/bar';
import { LevelScore } from 'src/common/components/level-score';
import { Ring } from 'src/common/components/ring';
import { Tooltip } from 'src/common/components/tooltip';

export const renderNumProgress = (params: {
  num: number;
  showAs: CollectionSchema['showAs'];
  numberFormat: CollectionSchema['numberFormat'];
  from: 'property-value' | 'num' | 'rollup' | 'formula';
}) => {
  const { num, showAs = {}, numberFormat } = params;
  const { levelSymbol, type, color, maxValue } = showAs;
  if (!maxValue) return null;

  const popup = `${num} / ${maxValue}`;

  let processStyle = null;

  if (type === NumberShowType.BAR && color) {
    processStyle = (
      <Tooltip
        popup={popup}
        offset={[0, -3]}
        className="max-w-[300px] min-w-[80px] w-[60%] flex-shrink-0 ml-2 self-stretch flex items-center cursor-pointer"
      >
        <Bar
          height={5}
          className="w-full"
          percent={numberFormat?.includes('percent') ? num : num / maxValue}
          bgColor={getDataBaseProgressBarBgColor(color)}
          progressColor={getDataBaseProgressBarColor(color)}
        />
      </Tooltip>
    );
  } else if (type === NumberShowType.RING && color) {
    processStyle = (
      <Tooltip popup={popup} offset={[0, -3]} className="ml-2 flex-shrink-0 cursor-pointer">
        <Ring
          size={20}
          percent={numberFormat?.includes('percent') ? num : num / maxValue}
          bgColor={getDataBaseProgressBarBgColor(color)}
          ringColor={getDataBaseProgressBarColor(color)}
        />
      </Tooltip>
    );
  } else if (type === NumberShowType.LEVEL && levelSymbol) {
    processStyle = (
      <Tooltip popup={popup} className="ml-2 whitespace-nowrap cursor-pointer">
        <LevelScore
          levelSymbol={levelSymbol}
          totalScore={maxValue}
          score={numberFormat?.includes('percent') ? maxValue * num : num}
          className="flex-shrink-0"
        />
      </Tooltip>
    );
  }

  return processStyle;
};
