import type { AuthorizationCodeDTO, UserAuthorizationType } from '@next-space/fe-api-idl';
import { RegisterStatus } from '@next-space/fe-api-idl';
import { useState } from 'react';

import { AccountStatus } from '../types';
import { loginInjector } from '../utils/init';

/**微信/qq登录 */
export const useThirdPartLogin = (type: UserAuthorizationType) => {
  const [accountStatus, setAccountStatus] = useState<AccountStatus | undefined>();
  const [authorizationCode, setAuthorizationCode] = useState('');

  /**通过第三方登录返回的code到后台拿授权码，之后去注册/登录*/
  const tryLogin = async (code: string | undefined, authType: 'mobile' | 'web' | 'h5' | 'mini') => {
    if (!code) return;
    const res = await loginInjector.request.infra.wechatAuthorization({
      code,
      type: authType,
    });
    return updateAccountState(res);
  };

  const updateAccountState = async (params: AuthorizationCodeDTO) => {
    if (params.status === RegisterStatus.UN_REGISTERED && params.authorizationCode) {
      //跳到绑定手机页面注册
      setAuthorizationCode(params.authorizationCode);
      setAccountStatus(AccountStatus.NEED_BIND_PHONE);
    } else if (params.status === RegisterStatus.REGISTERED && params.authorizationCode) {
      //直接登录拿用户信息
      setAccountStatus(AccountStatus.CAN_LOGIN);
      const user = await loginInjector.request.infra.loginAuthorizationCode({
        type,
        code: params.authorizationCode,
      });
      return user;
    }
  };

  return {
    accountStatus,
    authorizationCode,
    tryLogin,
    updateAccountState,
  };
};
