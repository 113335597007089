import type { IEditorModel } from '@next-space/fe-inlined';
import {
  findItemPathInRange,
  getElementByPath,
  getItemLength,
  newContent,
  newText,
} from '@next-space/fe-inlined/utils';
import isHotkey from 'is-hotkey';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LinkWidget } from 'src/common/components/link-widget';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { LINK_TAG } from 'src/editor/editor/inline/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { normalizeHref } from 'src/utils/embed';

interface Option {
  model?: IEditorModel;
  setFakeFocus?: (status: boolean) => void;
}
export const useShortcutLink = (uuid: string, option?: Option) => {
  const getEditorModel = useGetOrInitEditorModel();
  const { model: _model, setFakeFocus } = option || {};
  const linkUrlRef = useRef('');
  const openModal = useOpenModal();

  return useCallback(
    (edit?: boolean) => {
      const model = _model || getEditorModel(uuid);
      if (!model) return;

      const selection = model.active ? model.selection : null;
      if (!selection) return;

      const linkPath = findItemPathInRange(
        model.content,
        selection.offset,
        selection.endOffset,
        (item, offset) => {
          if (item.type === 'element' && item.tag === LINK_TAG) {
            if (selection.isCollapsed) {
              return true;
            }
            const endOffset = offset + getItemLength(item);
            return selection.endOffset > offset && selection.offset < endOffset;
          }
          return false;
        }
      );
      const inLink =
        linkPath.length > 0
          ? (getElementByPath(model.content, linkPath)?.props.url as string)
          : null;

      linkUrlRef.current = inLink ?? '';

      const LinkModel: ModalSchema.Content = ({ onCloseModal }) => {
        const [linkUrl, setLinkUrl0] = useState(inLink ?? '');
        const setLinkUrl = (url: string) => {
          setLinkUrl0(url);
          linkUrlRef.current = url;
        };

        useEffect(() => {
          const handleKeydown = (event: KeyboardEvent) => {
            if (isHotkey('esc')(event)) {
              linkUrlRef.current = '';
            }
          };
          document.addEventListener('keydown', handleKeydown, { capture: true });
          setFakeFocus?.(true);
          return () => {
            setFakeFocus?.(false);
            document.removeEventListener('keydown', handleKeydown, { capture: true });
          };
        }, []);

        return (
          <LinkWidget
            inLink={edit === undefined ? inLink !== null : false}
            link={linkUrl}
            onLinkChange={setLinkUrl}
            onClearLink={() => {
              setLinkUrl('');
              model.performChange((ctx) => {
                const linkPath = findItemPathInRange(
                  model.content,
                  selection.offset,
                  selection.endOffset,
                  (item, offset) => {
                    if (item.type === 'element' && item.tag === LINK_TAG) {
                      if (selection.isCollapsed) {
                        return true;
                      }
                      const endOffset = offset + getItemLength(item);
                      return selection.endOffset > offset && selection.offset < endOffset;
                    }
                    return false;
                  }
                );
                ctx.select(selection.offset, selection.endOffset);
                if (linkPath.length > 0) {
                  ctx.unwrap(LINK_TAG, linkPath);
                }
              });

              // notion会重新focus; focus逻辑在closeAfterCallBack实现了

              // // 是否需要重新focus
              // if (isFocus) {
              //   void model.requestFocus();
              // }
            }}
            onCloseModal={onCloseModal}
          />
        );
      };
      openModal.dropdown({
        popcorn: {
          getBoundingClientRect: () => {
            const rect = model.getBoundingClientRectOfRange(selection.offset, selection.endOffset);
            return rect ?? new DOMRect(-9999, -9999, 0, 0);
          },
        },
        closeAfterCallBack: () => {
          void model.requestFocus();
        },
        closeBeforeCallBack: () => {
          const linkUrl = linkUrlRef.current;
          const href = normalizeHref(linkUrl);

          if (href === '') return;

          model.performChange((ctx) => {
            ctx.select(selection.offset, selection.endOffset);
            if (ctx.selection === null) return;

            if (ctx.selection.isCollapsed) {
              ctx.insert(
                newContent([
                  {
                    type: 'element',
                    tag: LINK_TAG,
                    props: { url: href },
                    content: newContent([newText(href, ctx.format)]),
                  },
                ])
              );
            } else {
              ctx.wrap(LINK_TAG, {
                url: href,
              });
            }
          });
        },
        placement: 'bottom',
        offset: [4, 4],
        content: LinkModel,
      });
    },
    [_model, getEditorModel, openModal, setFakeFocus, uuid]
  );
};
