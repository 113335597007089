import { deepEqual, emitter } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { CollectionViewType, TextType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent, ReactNode, RefObject } from 'react';
import { memo, useMemo, useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import { List } from 'src/common/components/virtual';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import {
  useCollectionView,
  useFreezeColumnIndex,
} from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useGetPageId } from 'src/utils/getPageId';
import { useHScrollRef } from 'src/views/main/page-doc/context';
import type { CollectionProperty } from '../../bitable-manager/property-list';
import { DEFAULT_ROW_HEIGHT, getPropertyWidth } from '../../const';
import { Cell } from '../cell';
import { Site } from '../cell/types';
import { FreezeContext } from './freeze-context';
import { RowHiddenCheckBox } from './hidden-checkbox';
import { RowContext } from './row-context';
import { useSubitemSetting, useSubitemStyle } from 'src/hooks/block/use-open-subitem';
import { Icon } from 'src/common/components/icon';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { ExpandNode } from 'src/bitable/expand-node';
import { useRelationMap } from 'src/bitable/relation-context';
import { useRecordExpand, useSetRecordExpand } from 'src/services/database/state';
import { $searchParams } from 'src/utils';

interface Props {
  index: number;
  recordId: string;
  groupProperty?: string;
  groupValue?: string;
  tableCellWrap?: boolean;
  properties: CollectionProperty[];
  onClick?: (recordId: string, event: MouseEvent) => void;
  settingIcon?: JSX.Element;
  level?: number;
  idnumberIndex?: number;
}
interface PropsInner extends Props {
  expandNode?: ReactNode;
  alpha?: boolean; // 如果子任务筛选子记录符合父记录不符合，则父记录需要显示为半透明
}
export const ListRow: FC<Props> = memo((props) => {
  const { level } = props;
  const { collectionId, viewId, readonly } = useBitable();
  const { parent2ChildMap, filteredRecordIdSet } = useRelationMap();
  const childRecords = parent2ChildMap?.get(props.recordId) ?? [];
  const subitemStyle = useSubitemStyle(viewId);
  const expand = useRecordExpand(collectionId, viewId, props.recordId) || !!$searchParams.print;
  const setExpand = useSetRecordExpand(collectionId, viewId, props.recordId);
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const subitemSetting = useSubitemSetting(collectionId);
  const isNested = subitemStyle === 'Nested';
  const view = useCollectionView(viewId);
  const isTimeLine = view?.type === CollectionViewType.TIMELINE;

  // 如果a的子记录是b，b的子记录是c，c的子记录设置为a的话，就会循环嵌套，这里做个限制，超过10层咱们就不给显示（有用户真的层数用到那么深给反馈的话咱们再改）
  if (level && level > 10) return null;
  const showExpandNode = level !== undefined && isNested;
  return (
    <>
      <ListRowInner
        {...props}
        alpha={filteredRecordIdSet?.has(props.recordId)}
        expandNode={
          showExpandNode &&
          (readonly && childRecords.length === 0 ? (
            <div
              className="w-6"
              style={{
                marginLeft: level * 20,
              }}
            />
          ) : (
            <ExpandNode
              childrenLen={childRecords.length}
              expand={expand}
              onExpand={(expand) => {
                setExpand(expand);
                isTimeLine &&
                  emitter.emit('timelineExpand', {
                    collectionId,
                    recordId: props.recordId,
                    expand,
                  });
              }}
              level={level}
            />
          ))
        }
      />
      {expand && showExpandNode && isNested && (
        <>
          {childRecords.map((childId, childIndex) => {
            return (
              <ListRow
                key={childId}
                {...props}
                recordId={childId}
                level={level + 1}
                data-relation-parent-id={props.recordId}
                idnumberIndex={childIndex}
              />
            );
          })}
          {!$searchParams.print && (
            <div
              onClick={() => {
                if (subitemSetting && subitemSetting?.parentPropertyId) {
                  void insertRecord({
                    viewId,
                    propertyValues: {
                      [subitemSetting.parentPropertyId]: [
                        {
                          type: TextType.LINK_PAGE,
                          uuid: props.recordId,
                          text: '',
                          enhancer: { code: false },
                        },
                      ],
                    },
                  });
                }
                // 创建新的subitem
              }}
              className={cx('flex items-center w-full animate-hover h-8 text-grey border-b', {
                hidden: readonly,
              })}
              style={{
                paddingLeft: 40 + level * 30,
              }}
            >
              <Icon name={'IcAdd'} size="small" />
              <div className="text-t2 ml-1 flex items-center text-ellipsis">创建子记录</div>
            </div>
          )}
        </>
      )}
    </>
  );
}, deepEqual);

const ListRowInner: FC<PropsInner> = memo((props) => {
  const {
    recordId,
    groupProperty,
    groupValue,
    tableCellWrap = false,
    properties,
    onClick,
    settingIcon,
    index,
    level,
    expandNode,
    alpha,
    idnumberIndex,
    ...rest
  } = props;
  const { viewId, viewType, relationEditor = false, embed, collectionId } = useBitable();
  const hScrollRef = useHScrollRef() as RefObject<HTMLElement>;
  const blockRef = useRef<HTMLDivElement>(null);
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const { illegal } = usePermissions(recordId);
  const readonly = useReadonly(recordId, !!embed);
  const collectionReadonly = useReadonly(collectionId, false);
  const pageId = useGetPageId();
  const freeColumnIndex = useFreezeColumnIndex(viewId, properties);
  const freezeProperties = useMemo(
    () => properties.slice(0, freeColumnIndex + 1),
    [freeColumnIndex, properties]
  );
  const restProperties = useMemo(
    () => properties.slice(freeColumnIndex + 1),
    [freeColumnIndex, properties]
  );
  const beforeAppendChild = useMemo(
    () => (
      <>
        {!isTimeline && !settingIcon && !collectionReadonly && (
          <RowHiddenCheckBox uuid={recordId} viewId={viewId} groupValue={groupValue} />
        )}
      </>
    ),
    [collectionReadonly, groupValue, isTimeline, recordId, settingIcon, viewId]
  );

  const contextValue = useMemo(() => {
    return { illegal, pageId, readonly: readonly || relationEditor, index };
  }, [illegal, index, pageId, readonly, relationEditor]);

  return (
    <BlockDrop
      id={recordId}
      blockRef={blockRef}
      groupValue={groupValue}
      viewId={viewId}
      className={cx(
        'relative group flex w-full min-w-max print:w-fit border-b',
        relationEditor && 'hover:bg-black_006 cursor-pointer pl-2',
        alpha && 'opacity-50'
      )}
      style={{
        minHeight: DEFAULT_ROW_HEIGHT,
        height: tableCellWrap ? undefined : DEFAULT_ROW_HEIGHT,
      }}
      onClick={(event) => {
        onClick?.(recordId, event);
      }}
      {...rest}
    >
      <RowContext.Provider value={contextValue}>
        {settingIcon}
        {freezeProperties.length === 0 && beforeAppendChild}
        {tableCellWrap ? (
          <>
            {freezeProperties.length > 0 && (
              <>
                <FreezeContext.Provider value={true}>
                  <div className="sticky-column flex sticky left-0 bg-white1 z-[10]">
                    {beforeAppendChild}
                    {freezeProperties.map((property, index) => {
                      const width = getPropertyWidth(property);
                      return (
                        <Cell
                          key={property.property}
                          style={{ width }}
                          site={Site.CELL}
                          recordId={recordId}
                          propertyId={property.property}
                          groupProperty={groupProperty}
                          groupValue={groupValue}
                          level={level}
                          expandNode={expandNode}
                          propertyIndex={index}
                          idnumberIndex={idnumberIndex}
                        />
                      );
                    })}
                    {/* <FreezeColumnLineTip /> */}
                  </div>
                </FreezeContext.Provider>
              </>
            )}
            {restProperties.map((property, index) => {
              const width = getPropertyWidth(property);

              return (
                <Cell
                  key={property.property}
                  style={{ width }}
                  site={Site.CELL}
                  recordId={recordId}
                  propertyId={property.property}
                  groupProperty={groupProperty}
                  groupValue={groupValue}
                  level={level}
                  expandNode={expandNode}
                  propertyIndex={freezeProperties.length + index}
                  idnumberIndex={idnumberIndex}
                />
              );
            })}
          </>
        ) : (
          <List
            beforeAppendChild={freeColumnIndex === -1 ? null : beforeAppendChild}
            stickyIndex={freeColumnIndex}
            virtualized={freeColumnIndex === -1} // 没有冻结列才用虚拟列表
            direction="horizontal"
            bufferSize={1}
            scrollRef={hScrollRef}
            count={properties.length}
            getItemSize={(index) => {
              const property = properties[index];
              return getPropertyWidth(property);
            }}
            getItemKey={(index) => properties[index]?.property ?? ''}
            renderItem={(index) => {
              const property = properties[index];
              if (!property) return;

              const width = getPropertyWidth(property);
              return (
                <Cell
                  key={property.property}
                  style={{ width }}
                  site={Site.CELL}
                  recordId={recordId}
                  propertyId={property.property}
                  groupProperty={groupProperty}
                  groupValue={groupValue}
                  isLastCell={index === properties.length - 1}
                  level={level}
                  expandNode={expandNode}
                  propertyIndex={index}
                  idnumberIndex={idnumberIndex}
                />
              );
            }}
          />
        )}

        {!isTimeline && (
          <div className="flex-1 flex-shrink-0 print:hidden">
            <div className="w-12" />
          </div>
        )}
      </RowContext.Provider>
    </BlockDrop>
  );
}, deepEqual);
