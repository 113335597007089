import { generateCustomIconUrlByPath } from '@flowus/common';
import type { IconData } from '@next-space/fe-api-idl';
import { debounce, split } from 'lodash-es';
import type { BaseEmoji } from 'src/common/components/emoji-widget/emoji-mart';
import { getEmojiDataFromNative } from 'src/common/components/emoji-widget/emoji-mart';
import { getAllEmojiData } from 'src/common/components/emoji-widget/emoji-mart/utils';
import { IS_APPLE, IS_SAFARI } from 'src/common/utils/environment';
import { VITE_CDN_HOST } from 'src/env';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { Images } from 'src/image';
import isURL from 'validator/lib/isURL';
import { sleep } from './async-utils';
import { querySelectorFromMainContent } from './dom';
import { isFlowUsApp } from './electron-util';
import { getPageId } from './getPageId';
import { urlFetcher } from './url-fetcher';

const setFavicon = function (url: string) {
  if (IS_SAFARI) return;
  const link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
  link.id = 'dynamic-favicon';
  link.rel = 'shortcut icon';
  link.href = url;
  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
};
const drawEmojiStr = (canvas: HTMLCanvasElement, emoji: string) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx.font = '64px serif';
  ctx.fillText(emoji, 0, 54);
  // 获取 emoji url
  const url = canvas.toDataURL();
  // 更新 favicon
  setFavicon(url);
};
const drawEmojiImage = (canvas: HTMLCanvasElement, emojiData: BaseEmoji) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  const image = new Image();
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = () => {
    // 57 is from shared-default-props.js
    const size = image.width / 57;
    ctx.drawImage(
      image,
      emojiData.sheet_x * size,
      emojiData.sheet_y * size,
      size,
      size,
      0,
      0,
      canvas.width,
      canvas.height
    );
    const url = canvas.toDataURL();
    // 更新 favicon
    setFavicon(url);
  };
  image.src = `${VITE_CDN_HOST}assets/google_emoji.5dd44719.png`;
};

/** 动态修改 */
export const setEmojiFavicon = function (emoji = '') {
  if (!emoji) {
    setFavicon(Images.favicon);
    return;
  }
  // 创建 canvas 标签
  const canvas = document.createElement('canvas');
  canvas.height = 64;
  canvas.width = 64;

  if (IS_APPLE) {
    drawEmojiStr(canvas, emoji);
    return;
  }
  const emojiData = getEmojiDataFromNative(emoji, 'google', getAllEmojiData());
  if (!emojiData) {
    drawEmojiStr(canvas, emoji);
  } else {
    drawEmojiImage(canvas, emojiData);
  }
};

/** 获取名字首位字 */
export const getAvatarText = (name: string) => {
  return split(name, '')[0];
};

const updateMetaDescription = (desc: string) => {
  // 找到 meta 标签
  const metaTags = document.querySelectorAll('meta[name="description"]');
  const keywordsTags = document.querySelectorAll('meta[name="keywords"]');
  const description = desc.replace(/\s+/g, '').substring(0, 120);
  const keywords = [
    ...new Set(
      desc
        .split('\n')
        .map((i) => i.replace(/\s+/g, ''))
        .filter(Boolean)
    ),
  ]
    .join(',')
    .substring(0, 120);

  if (keywordsTags.length) {
    keywordsTags.forEach((i) => i.remove());
  }
  const newKeywordsTag = document.createElement('meta');
  newKeywordsTag.setAttribute('name', 'keywords');
  newKeywordsTag.setAttribute('content', keywords);
  document.head.appendChild(newKeywordsTag);

  // 如果找到了,就更新它的内容
  if (metaTags.length) {
    metaTags.forEach((i) => i.remove());
  }
  const newMetaTag = document.createElement('meta');
  newMetaTag.setAttribute('name', 'description');
  newMetaTag.setAttribute('content', description);
  document.head.appendChild(newMetaTag);
};

/** 设置浏览器标题和icon */
export const setBrowserTitle = debounce(
  async (props: { title: string; icon?: IconData }) => {
    const { title, icon } = props;
    const type = icon?.type ?? 'emoji';
    const value = icon?.value;

    void sleep(1000).then(() => {
      const contentPage = querySelectorFromMainContent(
        `.next-space-page .block-content`
      ) as HTMLElement;
      contentPage?.innerText && updateMetaDescription(contentPage.innerText);
    });

    if (IS_SAFARI) {
      if (type === 'upload' || type === 'http' || type === 'icon') {
        document.title = title;
      } else {
        if (isFlowUsApp.check) {
          document.title = title;
        } else {
          document.title = `${value ? `${value} ` : ''}${title}`;
        }
      }
      return;
    }

    document.title = title;

    if ((type === 'upload' || type === 'http' || type === 'icon') && value) {
      let url = type === 'icon' ? generateCustomIconUrlByPath(getIsDarkMode(), value) : value;

      if (type === 'upload') {
        url = await urlFetcher.fetchDownloadUrl({
          ossName: value,
          blockId: getPageId(),
        });
      }

      if (isURL(url)) {
        setFavicon(url);
      } else {
        setFavicon(Images.favicon);
      }
    } else {
      setEmojiFavicon(value);
    }
  },
  500,
  {
    leading: true,
    trailing: true,
  }
);
