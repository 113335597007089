import { cx } from '@flowus/common/cx';
import { FileRegex } from '@flowus/common/regex';
import { getFileNameInfo } from '@flowus/common/utils/oss-to-filename';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { urlFetcher } from 'src/utils/url-fetcher';
import { Keyboard, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Feature, ImportantFeature } from './fetch-important-feature';

interface Props {
  importantFeature: ImportantFeature;
  onComplete: () => void;
}
export const FeaturePanel: FC<Props> = (props) => {
  const { importantFeature } = props;
  const [index, setIndex] = useState(0);

  const showPre = importantFeature.features.length > 1 && index > 0;
  const showNext = index < importantFeature.features.length - 1;
  const showComplete = index === importantFeature.features.length - 1;

  return (
    <div className="next-modal w-[320px] min-h-[480px] px-7 pb-4">
      <div className="text-t1-medium mt-7">{props.importantFeature.title}</div>
      <FeaturePreview
        feature={importantFeature.features[index]}
        blockId={importantFeature.blockId}
      />
      <div className={cx('flex justify-between space-x-4 mt-5', {})}>
        {showPre && (
          <Button
            className="w-full"
            colorType="secondary"
            onClick={() => {
              setIndex(index - 1);
            }}
          >
            上一步
          </Button>
        )}
        {showNext && (
          <Button
            className="w-full"
            colorType="secondary"
            onClick={() => {
              setIndex(index + 1);
            }}
          >
            下一步
          </Button>
        )}
        {showComplete && (
          <Button
            className="w-full"
            colorType="active"
            onClick={() => {
              props.onComplete();
            }}
          >
            知道了
          </Button>
        )}
      </div>
    </div>
  );
};

interface FeaturePreviewProps {
  feature?: Feature;
  blockId: string;
}
export const FeaturePreview: FC<FeaturePreviewProps> = (props) => {
  const [pointIndex, setPointIndex] = useState(0);

  if (!props.feature) return null;

  return (
    <>
      <RichText className="text-t2 text-grey2 mt-1" segments={props.feature.desc} />
      <Swiper
        navigation={{
          nextEl: '.swiper-next',
          prevEl: '.swiper-pre',
        }}
        mousewheel
        className="w-[260px] h-[260px] rounded-xl border border-black_006 mt-2.5"
        slidesPerView="auto"
        keyboard={{ enabled: true, onlyInViewport: true }}
        modules={[Keyboard, Mousewheel, Navigation]}
        onActiveIndexChange={(swiper) => setPointIndex(swiper.realIndex)}
      >
        {props.feature.medias.map((ossName) => {
          return (
            <SwiperSlide key={ossName}>
              <Preview blockId={props.blockId} ossName={ossName} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      {props.feature.medias.length > 1 && (
        <div className="flex justify-center">
          {props.feature.medias.map((_, index) => (
            <span
              key={index}
              className={cx(
                'h-2 w-2 rounded-full mx-1.5 mt-2.5',
                index === pointIndex ? 'bg-active_color' : 'bg-grey5'
              )}
            />
          ))}
        </div>
      )}
    </>
  );
};

const Preview: FC<{ blockId: string; ossName: string }> = ({ blockId, ossName }) => {
  const { extName } = getFileNameInfo(ossName);
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (url) return;
    void urlFetcher.fetchDownloadUrl({ blockId, ossName }).then((res) => {
      setUrl(res);
    });
  }, [blockId, ossName, url]);
  if (!url) {
    return <></>;
  }
  if (FileRegex.image.test(extName)) {
    return <img className="w-full h-full object-cover" src={url} />;
  }
  if (FileRegex.video.test(extName)) {
    return (
      <div className="w-full h-full flex items-center">
        <video className="h-full" src={url} controls={true} preload="metadata" />;
      </div>
    );
  }
  return null;
};
