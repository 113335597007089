import { BlockType } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { convertBlock } from 'src/redux/managers/block/convert';
import { cache, dispatch } from 'src/redux/store';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { sequence, sleep } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isCollection, isMindMap } from 'src/utils/block-type-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { useGetRightPageId } from 'src/utils/right-utils';
import { TocType } from 'src/views/main/aside/toc/types';
import type { From } from 'src/views/main/template/types';
import { ActivityIds } from '../activities/activity-ids';
import { ActivitiesListType } from '../activities/use-activity';
import { useUpdateTask } from '../activities/use-update-task';
import { useCheckCopySubNodes } from '../block/use-copy-sub-nodes-api';
import { isRecord } from '../block/use-is-record';
import { getSubPages, useCreateDocPage, useFetchPage } from '../page';
import { getCurrentSpaceId } from '../space/get-space';
import { useTransaction } from '../use-transaction';

export const useCopyTemplate = () => {
  const pageId = useGetPageId();
  const createPage = useCreateDocPage();
  const rightPageId = useGetRightPageId();
  const transaction = useTransaction();
  const checkCopySubNodes = useCheckCopySubNodes();

  return useCallback(
    async (
      uuid: string,
      opt: {
        from: From;
        _targetId?: string;
        addCopyLimit?: boolean;
      }
    ) => {
      const { from, _targetId, addCopyLimit = false } = opt;
      await checkCopySubNodes({
        ids: [uuid],
        spaceId: getCurrentSpaceId(),
        forceAwait: true,
        addCopyLimit,
      });

      /**
       * 如果是在快速访问打开则直接创建一个新页面。
       * 如果是空页面并且是打开了右侧页面的record页，则复制到rightPageId
       * 如果是空页面则直接复制到pageId
       * FIXME:如果左侧打开一个子页面并且选择【从模板库选择创建】，则会出现bug.
       */
      let targetId = _targetId ?? (rightPageId ? rightPageId : pageId);

      if (from === 'quickSetting' || from === 'templateBar') {
        const spaceId = getCurrentSpaceId();
        const lastPageId = last(getSubPages(cache.blocks, spaceId));
        const newPageId = createPage(
          BlockType.PAGE,
          { parentId: spaceId, after: lastPageId },
          TocType.PRIVATE
        );
        if (!newPageId) return;
        targetId = newPageId;
      }

      let blockType = cache.blocks[uuid]?.type;
      if (!cache.blocks[uuid]) {
        const res = await request.editor.getDoc(uuid);
        blockType = res.blocks[uuid]?.type;
      }
      // 特殊case，如果是record页，并且是使用多维表/脑图模板，则该多维表/脑图直接作为内嵌页面嵌入到record页
      const isCopyCollectionToRecord = isRecord(targetId) && isCollection(blockType);
      const isCopyMindMapPageToRecord = isRecord(targetId) && isMindMap(blockType);

      if (isCopyCollectionToRecord || isCopyMindMapPageToRecord || blockType === BlockType.FOLDER) {
        const uuid = addBlock({}, { parentId: targetId });
        dispatch(TRANSACTION_FIRE());
        targetId = uuid;
      }

      await sequence(async () => {
        return request.editor.copyTemplate({ uuid, targetId, addCopyLimit });
      });

      if (blockType !== undefined && isCopyCollectionToRecord) {
        transaction(
          () => {
            if (
              blockType !== undefined &&
              [BlockType.REFERENCE_COLLECTION_PAGE, BlockType.REFERENCE_COLLECTION].includes(
                blockType
              )
            ) {
              convertBlock([targetId], { type: BlockType.REFERENCE_COLLECTION });
            } else {
              convertBlock([targetId], { type: BlockType.COLLECTION_VIEW });
            }
          },
          { noThrottle: true }
        );
      }
      if (blockType !== undefined && isCopyMindMapPageToRecord) {
        transaction(
          () => {
            convertBlock([targetId], { type: BlockType.MIND_MAPPING });
          },
          { noThrottle: true }
        );
      }
      // copyTemplate这个接口对子页面不是同步的，调完直接跳到对应的页面会拉不到数据，需要等一等
      await sleep(800);
      return targetId;
    },
    [checkCopySubNodes, createPage, pageId, rightPageId, transaction]
  );
};

export const useHandleCopyTemplate = () => {
  const copyTemplate = useCopyTemplate();
  const updateTask = useUpdateTask();
  const fetchPage = useFetchPage();
  const history = useHistory();

  return useCallback(
    async (
      template: { id?: string; isCustom?: boolean; targetId?: string; addCopyLimit?: boolean },
      from: From
    ) => {
      checkIsMaximumCapacityDialog();
      if (!template.id) return;

      message.loading({
        key: 'templateLoading',
        content: '正在加载模板...',
        duration: 3000,
      });
      const pageId = await copyTemplate(template.id, {
        from,
        _targetId: template.targetId,
        addCopyLimit: template.addCopyLimit,
      });
      if (pageId) {
        bizTracker.event('copy_template', {
          is_from: template?.isCustom ? 'custom' : 'new_page',
          template_id: template.id,
        });
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_COPY_TEMPLATE, ActivitiesListType.basicList);
        await fetchPage(pageId, { force: true });
        if (from !== 'record') {
          // 如果是从record来的话就不需要跳转
          history.push(`/${pageId}`);
        }
        message.closeMessage('templateLoading');
        return pageId;
      }
    },
    [copyTemplate, fetchPage, history, updateTask]
  );
};
