import type { CollectionSchema } from '@next-space/fe-api-idl';
import { DATE_FORMAT, TIME_FORMAT } from 'src/common/const';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { sample } from 'lodash-es';
import { COLORS } from 'src/bitable/const';
import { segmentsToText } from 'src/editor/utils/editor';
import { updateBlock, updateSchema } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { v4 as uuid4 } from 'uuid';
import { transaction } from '../use-transaction';

export const useUpdatePropertySchema = () => {
  // 这种基础操作自带transaction的话限制太大，目前只能加个[needTransaction]参数凑合用着
  return updatePropertySchema;
};

export const updatePropertySchema = (
  collectionId: string,
  propertyId: string,
  patch: Partial<CollectionSchema>,
  options: {
    ignoreOp?: boolean;
    needTransaction?: boolean;
    isUpdateNumberFormat?: boolean;
  } = {
    needTransaction: true,
    ignoreOp: false,
  }
) => {
  const updatePropertySchemaInner = () => {
    const { blocks } = getState();
    const collection = blocks[collectionId];
    if (!collection) return;

    const schema = collection.data.schema ?? {};
    const propertySchema = schema[propertyId];
    if (!propertySchema) return;

    const newOptions = [...(patch.options ?? propertySchema.options ?? [])];
    const optionValueSet = new Set(newOptions.map((option) => option.value));

    // text 转换到 select 的时候将之前所有 blocks 中的所有值转换为 select 的选项
    if (
      patch.type === CollectionSchemaType.SELECT ||
      patch.type === CollectionSchemaType.MULTI_SELECT ||
      patch.type === CollectionSchemaType.DATE
    ) {
      collection.subNodes
        .map((id) => blocks[id])
        .forEach((recordBlock) => {
          if (!recordBlock) return;
          const value = recordBlock.data.collectionProperties?.[propertyId] ?? [];
          const text = segmentsToText(value);

          if (patch.type === CollectionSchemaType.DATE) {
            const date = dayjs(text);
            if (date.isValid()) {
              const onlyDatePart = date.hour() === 0 && date.minute() === 0 && date.second() === 0;
              updateBlock(
                recordBlock.uuid,
                {
                  data: {
                    collectionProperties: {
                      ...recordBlock.data.collectionProperties,
                      [propertyId]: [
                        {
                          type: onlyDatePart ? TextType.DATE : TextType.DATETIME,
                          enhancer: {},
                          text,
                          startDate: date.format(DATE_FORMAT),
                          startTime: !onlyDatePart ? date.format(TIME_FORMAT) : undefined,
                        },
                      ],
                    },
                  },
                },
                options.ignoreOp
              );
            }
          } else {
            text.split(',').forEach((value) => {
              value = value.trim();
              if (!value) return;
              if (optionValueSet.has(value)) return;

              optionValueSet.add(value);
              newOptions.push({
                id: uuid4(),
                color: sample(COLORS.map((item) => item.key)) ?? COLORS[0].key,
                value,
              });
            });
          }
        });
    }

    updateSchema(
      collectionId,
      {
        [propertyId]: {
          ...propertySchema,
          showAs: options.isUpdateNumberFormat ? undefined : propertySchema.showAs,
          ...patch,
          options: newOptions,
        },
      },
      options
    );
  };

  if (options?.needTransaction) {
    transaction(() => {
      updatePropertySchemaInner();
    });
  } else {
    updatePropertySchemaInner();
  }
};
