import { SearchParams } from '../const';

/** 尝试获取并保存推广码，注册的时候需要带上 */
export const tryGetPromotionChannel = (code?: string | null) => {
  const params = new URLSearchParams(location.search);
  code = code || params.get(SearchParams.promotionChannel);
  if (code) {
    localStorage.setItem(
      SearchParams.promotionChannel,
      JSON.stringify({
        promotionChannel: code,
        time: Date.now(),
      })
    );
  }
};

/**获取推广码 */
export const getPromotionChannel = () => {
  const promotionChannelStr = localStorage.getItem(SearchParams.promotionChannel);
  if (promotionChannelStr) {
    const promotionChannelObj = JSON.parse(promotionChannelStr);
    const time = promotionChannelObj.time as number;
    if (Date.now() - time <= 30 * 24 * 60 * 60 * 1000) {
      // 30天以内才算
      return promotionChannelObj.promotionChannel as string;
    }
    clearPromotionChannel();
  }
};

/** 注册后移除掉渠道号 */
export const clearPromotionChannel = () => {
  localStorage.removeItem(SearchParams.promotionChannel);
};
