import { getBlockTextColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { getOrderFolderType } from '@flowus/common/utils/get-folder-sort-type';
import { fileUploader } from '@flowus/upload';
import { OrderFolderType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { memo } from 'react';
import { ColorKey } from 'src/colors';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ProgressBar } from 'src/common/components/progress-bar';
import { Tooltip } from 'src/common/components/tooltip';
import { formatFileTimestamp } from 'src/common/utils/formatter';
import { useOpenPreview } from 'src/common/utils/open-preview';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { Checkbox } from 'src/components/check-box';
import { DriveDnd } from 'src/components/drive-dnd';
import { FileNameUiKit } from 'src/components/file-name-uikit';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { Share } from 'src/components/share';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useMultipleDownload } from 'src/hooks/drive/use-multiple-download';
import { useReadonly } from 'src/hooks/page';
import { UploadStatus } from 'src/redux/types';
import { FolderViewType } from 'src/services/app';
import { removeUploadInfoByKey, useUploadInfoById } from 'src/services/upload';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { getFileListPreViewIconSize } from '../common';
import { useChangeOrderFolder } from '../hook';
import { useDrive } from '../use-drive';

/** 分栏 */
export const listFileItemClass = 'grid grid-cols-11 gap-x-2 h-[46px] items-center text-t2';

// #region table样式的item
interface Props {
  uuid: string;
  canDownload: boolean;
}
export const TableFileItem: FC<Props> = (props) => {
  const { uuid, canDownload } = props;
  const openPreview = useOpenPreview();
  const block = usePickBlock(uuid, ['data', 'updatedAt', 'createdAt'], ['size', 'ossName']);
  const {
    selectCurrentBlock,
    openMenuList,
    handleClick,
    rename,
    setName,
    selectBlock,
    isDragging,
    showRename,
    illegal,
    name,
    readonly,
    isPage,
    fileName,
    isSelected,
    isPDF,
  } = useDrive({
    uuid,
  });
  const { enableAI } = useEnableAI();
  const multipleDownload = useMultipleDownload();
  if (!block) return null;
  const time = illegal ? '-' : formatFileTimestamp(block.updatedAt || block.createdAt || 0);
  const size = block.data.size && !illegal ? getReadableFileSizeString(block.data.size) : '-';

  return (
    <DriveDnd
      uuid={block.uuid}
      onContextMenu={openMenuList}
      onClick={handleClick}
      showDefaultBorder
      className={cx(
        'flex items-center group relative',
        illegal && 'opacity-30',
        !isDragging && !isSelected && 'hover:bg-active_color_10'
      )}
    >
      {!isPage && canDownload && (
        <Tooltip
          popup={isSelected ? '取消选中' : '选中'}
          className={cx(
            'transition-opacity opacity-0 absolute -left-6 z-10 group-hover:opacity-100',
            isSelected && 'opacity-100'
          )}
        >
          <Checkbox size="small" checkbox2 checked={!!isSelected} onClick={selectBlock} />
        </Tooltip>
      )}
      <div
        className={cx(
          listFileItemClass,
          'flex-1 grid grid-cols-11 gap-x-2 transition-none cursor-pointer relative'
        )}
      >
        <span className={'flex relative items-center col-span-7'}>
          <span className={'mr-2 flex items-center justify-center'}>
            <FilePreViewIcon
              ossName={block.data.ossName}
              className={getFileListPreViewIconSize(FolderViewType.TABLE).class}
              uuid={block.uuid}
              options={{
                videoPlayIconSize: 'small',
                resizeWidth: getFileListPreViewIconSize(FolderViewType.TABLE).size,
              }}
              defaultIcon={
                <IconTrigger
                  trigger={isPage}
                  blockId={block.uuid}
                  iconSize={18}
                  className="p-1"
                  defaultIcon={
                    <BlockDefaultIcon uuid={block.uuid} size="middle" className="flex-shrink-0" />
                  }
                />
              }
            />
          </span>
          {showRename ? (
            <Input
              selection={[0, name.length]}
              onEnter={rename}
              className="h-8 w-full max-w-[360px]"
              autoFocus
              placeholder={getUntitledName(block.type)}
              value={name}
              onBlur={rename}
              onChange={(value) => setName(value)}
            />
          ) : fileName && !isPage ? (
            <FileNameUiKit className="w-[calc(100%-100px)]" fileName={fileName} />
          ) : (
            <span className="text-ellipsis">{fileName || getUntitledName(block.type)}</span>
          )}
          {!isPage && canDownload && judgeSharePage() && (
            <div className="ml-auto flex items-center transition-opacity opacity-0 group-hover:opacity-100">
              <Tooltip popup="下载" className={'cursor-pointer animate-hover rounded'}>
                <Icon
                  name="IcDownload"
                  size="middle"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    void multipleDownload([uuid]);
                  }}
                />
              </Tooltip>
            </div>
          )}
          {!readonly && (
            <div className="ml-auto flex items-center transition-opacity opacity-0 group-hover:opacity-100">
              {!illegal && enableAI && isPDF && (
                <Tooltip
                  popup="PDF 助手"
                  className={'cursor-pointer animate-hover rounded mr-2'}
                  onClick={() => openPreview(uuid)}
                >
                  <Icon name="IcAi" size="middle" />
                </Tooltip>
              )}
              {!illegal && <Share pos="fileItem" uuid={block.uuid} className="mr-2" />}
              <Tooltip popup="更多" className={'cursor-pointer animate-hover rounded'}>
                <Icon
                  {...bindDataTestId(TestIds.driveFileMore)}
                  name="IcMore"
                  size="middle"
                  onClick={(e: MouseEvent<HTMLElement>) => {
                    selectCurrentBlock(e);
                    openMenuList(e);
                  }}
                />
              </Tooltip>
            </div>
          )}
        </span>
        <Tooltip placement="top-start" popup={time} className={'col-span-2 text-ellipsis'}>
          {time}
        </Tooltip>
        <span className={'text-ellipsis col-span-2'}>{size}</span>
      </div>
    </DriveDnd>
  );
};
// #endregion

// #region 上传文件占位列表
export const TableListStyleUploadItem: FC<{ uuid: string }> = ({ uuid }) => {
  const info = useUploadInfoById(uuid);

  if (!info) return null;

  const cancelUploadFile = (id: string) => {
    void fileUploader.abort(id);
    removeUploadInfoByKey(info.key);
  };

  const fileName: string = info.name ?? '';
  const fileInfo = getFileNameInfo(fileName);

  return (
    <div key={info.key} className={cx('relative p-px', listFileItemClass)}>
      <span className={'flex items-center col-span-7'}>
        <Icon
          name={getFileIcon(fileInfo.extName)}
          size="xxlarge"
          className="flex-shrink-0 mr-2 p-1"
        />
        <FileNameUiKit className="w-[calc(100%-100px)]" fileName={fileName} />
      </span>
      <span
        className={'col-span-2'}
        style={{
          color: getBlockTextColor(
            info.status === UploadStatus.uploading ? ColorKey.blue : ColorKey.red
          ),
        }}
      >
        {info.status === UploadStatus.uploading && `已上传${info.progress}%`}
        {info.status === UploadStatus.failure && '上传失败'}
      </span>
      <span className="col-span-2">{getReadableFileSizeString(info.size)}</span>
      <Icon
        size="middle"
        name="IcUploadCancel"
        className="absolute right-3 text-grey3"
        onClick={() => cancelUploadFile(info.uploadId ?? '')}
      />
      <ProgressBar
        progress={info.progress ?? -1}
        fail={info.status === UploadStatus.failure}
        className="absolute h-[3px] right-0 bottom-[-1px] w-[36%]"
      />
    </div>
  );
};
// #endregion

// #region 表头
export const TableFileListTitle: FC<{ uuid: string }> = memo(({ uuid }) => {
  const readonly = useReadonly(uuid);
  const block = usePickBlock(uuid, ['data'], ['format']);
  const changeOrderFolder = useChangeOrderFolder(uuid);
  if (!block) return null;
  const { sortType, reverse } = getOrderFolderType(block.data?.format?.orderFolder);

  const renderArrow = (show: boolean) => {
    return (
      <Icon
        size="middle"
        className={cx(reverse && 'rotate-180', !show && 'hidden')}
        name="IcArrowUp02"
      />
    );
  };

  return (
    <div className={cx(listFileItemClass, 'text-grey3 flex-1', readonly && 'pointer-events-none')}>
      <span
        onClick={() => changeOrderFolder('name')}
        className={'cursor-pointer items-center flex col-span-7'}
      >
        文件名
        {renderArrow([OrderFolderType.NAME, OrderFolderType.NAME_REVERSED].includes(sortType))}
      </span>
      <span
        onClick={() => changeOrderFolder('time')}
        className="col-span-2 text-ellipsis cursor-pointer items-center flex"
      >
        上次修改时间
        {renderArrow([OrderFolderType.TIME, OrderFolderType.TIME_REVERSED].includes(sortType))}
      </span>
      <span
        onClick={() => changeOrderFolder('size')}
        className="col-span-2 text-ellipsis cursor-pointer items-center flex"
      >
        文件大小
        {renderArrow([OrderFolderType.SIZE, OrderFolderType.SIZE_REVERSED].includes(sortType))}
      </span>
    </div>
  );
});

// #endregion
