export const numberToPercent = (value: number, fixed = 2) =>
  String((value * 100).toFixed(fixed)).concat('%');
export const percentToNumber = (percent: string) => {
  const num = percent.split('%')[0] ?? '';
  if (!num) return 0;

  const scale = Math.pow(10, (num.split('.')[1] ?? '').length);
  return (Number(num) * scale) / (100 * scale);
};
export const formatCurrency = (str: string | number) =>
  String(str).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const isPhoneNumber = (str: string | number) => /^[-0-9+() ]+$/.test(`${str}`);

// 将 0.1 + 0.2 的结果显示为 0.3
export const formatFloat = (f: number) => {
  const s = f.toString();
  const k = s.lastIndexOf('.');
  const numFracDigits = s.length - k - 1;
  if (k >= 0 && numFracDigits >= 10) {
    // 如果四舍五入后，小数部分大幅度变短，则大概率是 0.30000000000000004 这类情况
    const p = Math.max(10, numFracDigits - 6);
    const q = Math.pow(10, p);
    const s2 = `${Math.round(f * q) / q}`;
    if (s.length - s2.length >= numFracDigits - 12 && s.length - s2.length >= 4) {
      return s2;
    }
    // 否则最多保留 12 位小数
    return `${Math.round(f * 1e12) / 1e12}`;
  }

  return toNonExponential(Number(s));
};

// 小数部分不使用科学技术法
export const toNonExponential = (num: number) => {
  const m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  if (!m) return `${num}`;

  const decimalLength = Number(m[2]) || 0;
  if (Math.abs(decimalLength) > 20) {
    return `${num}`;
  }
  return num.toFixed(Math.max(0, (m[1] || '').length - decimalLength));
};

export const add = (num1: number, num2: number) => {
  const len = Math.max(
    (String(num1).split('.')[1] ?? '').length,
    (String(num2).split('.')[1] ?? '').length
  );
  const scale = Math.pow(10, len);
  return (num1 * scale + num2 * scale) / scale;
};
