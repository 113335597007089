import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import { debounce } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { judgeSharePage } from 'src/utils/getPageId';
import { getPageLayoutInfo, savePageLayoutInfo } from 'src/utils/page-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { PageScene, usePageScene } from 'src/views/main/scene-context';

export const goBackInfo = {
  lastGoBackPath: '',
  tmpDisable: false,
};
/** 记录页面的布局信息(仅记录scroll，rightId不管了)，只有回退历史(go back)的时候才还原回去，其他情况不还原，仅当前session有效 */
export const useSaveLayoutInfo = (pageId: string, scrollRef: React.RefObject<HTMLDivElement>) => {
  const location = useLocation();
  const isInRight = useIsInRight();
  const locationRef = useRef<typeof location>();
  locationRef.current = location;
  const history = useHistory();
  const pageScene = usePageScene();

  useEffect(() => {
    if (!scrollRef.current) return;
    if (judgeSharePage()) return;
    if ([pageScene && PageScene.PAGE_HISTORY, PageScene.TEMPLATE].includes(pageScene)) {
      // 走到这里说明可能是历史记录/模板中心的页面
      return;
    }
    let timer: Timeout;
    const onScroll = () => {
      savePageLayoutInfo(pageId, {
        scrollTop: scrollRef.current?.scrollTop ?? 0,
      });
    };
    const debounceOnScroll = debounce(onScroll, 300);
    scrollRef.current.addEventListener('scroll', debounceOnScroll);
    const cleanup = () => {
      timer && clearTimeout(timer);
      scrollRef.current?.removeEventListener('scroll', debounceOnScroll);
    };

    if (!goBackInfo.lastGoBackPath || goBackInfo.lastGoBackPath !== locationRef.current?.pathname) {
      return cleanup;
    }

    const layoutInfo = getPageLayoutInfo(pageId);
    if (layoutInfo) {
      timer = setTimeout(() => {
        if (!scrollRef.current) return;
        scrollRef.current.scrollTo({ top: layoutInfo.scrollTop });
      }, 100);
    }
    return cleanup;
  }, [history, isInRight, pageId, pageScene, scrollRef]);
};
