import { lightDataBaseProgressBar } from '@flowus/common/block/color/data';
import { PermissionRole } from '@next-space/fe-api-idl';
import { assign } from 'lodash-es';
import type { FC } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { PersonSpaceFreeCapacity } from 'src/common/const';
import { LocalStorageKey } from 'src/const/local-storage-state-key';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useCurrentUser } from 'src/hooks/user';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { useSpaceCapacity } from 'src/services/capacity';
import { toPercent } from 'src/utils';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { HeaderTips, useOnceAnyDay } from './header-tips-bar';
import { useOpenUpgradeCapacityModal } from './select-upgrade-plan/upgrade-capacity';

interface Props {
  type: 'setting' | 'aside' | 'header';
}
export const SpaceCapacity: FC<Props> = (props) => {
  const { type } = props;
  const isPropSpace = useIsProSpace();
  const currentSpace = useCurrentSpace();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openUpgradeCapacityModal = useOpenUpgradeCapacityModal();
  const spaceCapacity = useSpaceCapacity();
  const capacityProgress = Number(
    Math.min(
      toPercent(spaceCapacity?.currentCapacity ?? 0, spaceCapacity?.maxCapacity ?? 0),
      100
    ).toFixed(0)
  );
  const { blocksMaxLimit, blocksUsage, blocksIsMax } = useLimitConfig();
  const blocksProgress = Number(Math.min(toPercent(blocksUsage, blocksMaxLimit), 100).toFixed(0));
  const currentUser = useCurrentUser();
  const users = useCurrentSpaceUsers();
  const spaceUserRole = users[currentUser.uuid];
  const onceAnyDay = useOnceAnyDay(LocalStorageKey.SPACE_CAPACITY, {
    endDay: -1,
  });
  const { showOnce } = onceAnyDay;

  if (spaceUserRole?.spaceRole !== PermissionRole.EDITOR) {
    return null;
  }

  const capacityBgColor = `linear-gradient(to right, ${
    capacityProgress >= 80
      ? `${lightDataBaseProgressBar.yellow}`
      : `${lightDataBaseProgressBar.yellow}`
  } ${capacityProgress}%, var(--black-10) ${capacityProgress}%)`;

  const blockBgColor = `linear-gradient(to right, ${
    blocksProgress >= 80
      ? `${lightDataBaseProgressBar.yellow}`
      : `${lightDataBaseProgressBar.yellow}`
  } ${blocksProgress}%, var(--black-10) ${blocksProgress}%)`;

  const spacePlanName = getSpacePlanTypeName(currentSpace.planType);
  const maxCapacity = getReadableFileSizeString(spaceCapacity?.maxCapacity);
  const isMaxBlock = blocksProgress >= 80;
  const isMaxCapacity = capacityProgress >= 80;

  const dataDetails = {
    block: {
      popup: `当前空间计划为${spacePlanName}空间，总共可使用 ${blocksMaxLimit} 个块。一段文本、一个简单表格、一条分割线等等，都被称为“块”。\n\n${[
        '免费块已使用',
        `${blocksUsage}/${blocksMaxLimit}`,
      ].join(' ')}`,
      desc: '免费块',
      rightDesc: `${blocksUsage}块/${blocksMaxLimit}块`,
      progress: blocksProgress,
      backgroundImage: blockBgColor,
      hidden: !isMaxBlock || isPropSpace,
    },
    capacity: {
      popup: `当前空间计划为${spacePlanName}空间，总共可使用${maxCapacity}容量。回收站中的页面和文件也会占有空间容量。`,
      desc: isPropSpace ? '空间容量' : '免费空间容量',
      hidden: !isMaxCapacity,
      rightDesc: `${getReadableFileSizeString(spaceCapacity?.currentCapacity)}/${maxCapacity}`,
      progress: capacityProgress,
      backgroundImage: capacityBgColor,
    },
  };

  if (type === 'setting') {
    return (
      <>
        <div>
          总容量 {getReadableFileSizeString(spaceCapacity?.maxCapacity)}，
          <span className={capacityProgress >= 80 ? 'text-red' : ''}>
            已使用 {getReadableFileSizeString(spaceCapacity?.currentCapacity)} {capacityProgress}%
          </span>
        </div>
        <div className="h-9 flex items-center">
          <div
            style={{
              width: '100%',
              maxWidth: 400,
              height: 5,
              backgroundImage: capacityBgColor,
            }}
          />
        </div>
      </>
    );
  }

  if (type === 'header') {
    if (capacityProgress < 100) return null;

    if (!showOnce) {
      return null;
    }

    const textList = {
      title: `达到空间储存容量上限`,
      button: isPropSpace ? '购买容量' : '立即升级',
      bg: 'bg-yellow/10 text-black',
      popup: dataDetails.capacity.popup,
      desc: (
        <>
          当前{spacePlanName}容量上限为{maxCapacity}容量，
          {isPropSpace ? <>购买容量包</> : <>升级获得更多空间容量</>}
        </>
      ),
    };

    if (blocksIsMax) {
      assign(textList, {
        title: `达到空间块数上限`,
        button: '立即升级',
        bg: 'bg-yellow/10 text-black',
        popup: dataDetails.block.popup,
        desc: (
          <>
            {spacePlanName}仅提供 {blocksMaxLimit} 免费块， 升级获得更多块
          </>
        ),
      });
    }

    return (
      <HeaderTips.Bar className={textList.bg}>
        <div>
          <div className="text-t2-medium flex items-center space-x-1">
            <span>{textList.title}</span>
            <Tooltip className="text-grey3" popup={textList.popup}>
              <Icon size="small" name="IcQuestion" />
            </Tooltip>
          </div>
          <div className="text-grey3">{textList.desc}</div>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            className="ml-3"
            colorType="primary"
            onClick={() => {
              if (isPropSpace && isMaxCapacity) {
                openUpgradeCapacityModal({ isFrom: OpenSettingFrom.capacity_header });
              } else {
                openUpgradeSpace(OpenSettingFrom.capacity_header);
              }
            }}
          >
            {textList.button}
          </Button>
          <HeaderTips.OnceOneDay {...onceAnyDay} />
        </div>
      </HeaderTips.Bar>
    );
  }

  if (isPropSpace) return null;

  if (!isMaxCapacity && !isMaxBlock) {
    return (
      <div className="p-1">
        <div
          className="px-2 py-2 animate-click space-x-2 text-grey3 text-t2-medium flex items-center rounded bg-white2"
          onClick={() => openUpgradeSpace(OpenSettingFrom.cataFile)}
        >
          <Icon size="middle" name="IcUpgrade" />
          <div>
            <span>升级享受更好体验，</span>
            <span className="text-black underline underline-offset-2">去升级</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-1">
      <div className="space-y-2 bg-white2 p-2 rounded text-grey3 text-t4">
        {[dataDetails.block, dataDetails.capacity].map((item) => {
          if (item.hidden) return null;
          return (
            <Tooltip
              key={item.popup}
              className="w-full flex flex-col"
              placement="right"
              arrow
              popupClass="py-1 px-2"
              popup={item.popup}
            >
              <div className="flex items-center justify-between">
                <div className="whitespace-nowrap space-x-1">
                  <span className="text-black text-t2-medium">{item.desc}</span>
                  <Icon size="small" name="IcQuestion" />
                </div>
                <div className="text-right line-clamp-1">{item.rightDesc}</div>
              </div>
              <div className="flex items-center justify-center gap-2">
                <div
                  className="h-1 w-full rounded-full"
                  style={{ backgroundImage: item.backgroundImage }}
                />
                <div>{item.progress}%</div>
              </div>
            </Tooltip>
          );
        })}
        <div
          className="text-center text-t2-medium animate-click"
          onClick={() => openUpgradeSpace(OpenSettingFrom.cataFile)}
        >
          <span>升级获取更多容量，</span>
          <span className="text-black underline underline-offset-2">去升级</span>
        </div>
      </div>
    </div>
  );
};
