import { AnnotationType } from '../type';

export * from './convert-pdf-and-page';
export * from './convert-window-to-page';
export * from './filter-client-rects';
export * from './sort-annotation';

export const isImageAnnotation = (type: string) => {
  return (
    type === AnnotationType.RECTANGLE ||
    type === AnnotationType.ELLIPSE ||
    type === AnnotationType.POLYGON
  );
};
