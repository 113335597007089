import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useOpenUpgradeSpaceIfMindNodeLimit } from 'src/hooks/block/use-open-upgrade-space-if-mind-node-limit';
import { addBlock } from 'src/redux/managers/block/add';
import { cache } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';
import { isMindMap } from 'src/utils/block-type-utils';
import { getPreSiblingBlockId } from 'src/utils/block-utils';

/**
 * 添加子节点的逻辑可参考
 * https://flowus.cn/64a635e6-6776-4d5a-8782-abf8c98cb290
 */
export const useAddSubNode = () => {
  const maybeOpenUpgradeSpace = useOpenUpgradeSpaceIfMindNodeLimit();
  return useCallback(
    (where: NextWhere, mindMapId: string) => {
      const parent = cache.blocks[where.parentId];
      if (!parent) return;
      const maybeOpen = maybeOpenUpgradeSpace(mindMapId);
      if (maybeOpen) return;

      if (
        [
          BlockType.TEXTAREA,
          BlockType.ORDER_LIST,
          BlockType.LIST,
          BlockType.TODO,
          BlockType.FOLD_LIST,
          BlockType.TOGGLE_HEADER,
          BlockType.MIND_MAPPING, // 二级节点enter需要两个type判断
          BlockType.MIND_MAPPING_PAGE,
        ].includes(parent.type)
      ) {
        // 没有子节点情况下的策略：
        if (parent.subNodes.length === 0) {
          return addBlock(
            {
              type:
                isMindMap(parent.type) ||
                [BlockType.FOLD_LIST, BlockType.TOGGLE_HEADER].includes(parent.type)
                  ? BlockType.TEXTAREA
                  : parent.type,
              local: true,
            },
            where
          );
        }
        // 有子节点的情况下的策略：
        let siblingBlockId = parent.subNodes[0];
        if (where.last) {
          siblingBlockId = parent.subNodes[parent.subNodes.length - 1];
        } else if (where.before) {
          siblingBlockId = getPreSiblingBlockId(where.before);
        } else if (where.after) {
          siblingBlockId = where.after;
        }
        if (siblingBlockId) {
          const siblingBlock = cache.blocks[siblingBlockId];
          if (
            siblingBlock &&
            [BlockType.TEXTAREA, BlockType.ORDER_LIST, BlockType.LIST, BlockType.TODO].includes(
              siblingBlock.type
            )
          ) {
            return addBlock({ type: siblingBlock.type, local: true }, where);
          }
        }
        // 子节点也不符合策略的情况，则用默认的

        return addBlock({ type: BlockType.TEXTAREA, local: true }, where);
      }

      return addBlock({ type: BlockType.TEXTAREA, local: true }, where);
    },
    [maybeOpenUpgradeSpace]
  );
};
