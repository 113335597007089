import type { FC } from 'react';
import { createContext, useCallback, useContext, useRef } from 'react';

import type { ScrollRef } from '../types';
import { usePosition } from '../use-position';
import type { Props } from './grid';

export interface GridContext extends Props {
  contentRef: ScrollRef<HTMLDivElement>;
}

export const GridContext = createContext<GridContext | undefined>(undefined);

export const GridProvider: FC<Props> = (props) => {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <GridContext.Provider value={{ ...props, contentRef }}>{props.children}</GridContext.Provider>
  );
};

export const useGrid = () => {
  const ctx = useContext(GridContext);
  if (!ctx) throw new Error('useGrid 需要在 GridProvider 内使用');

  const { scrollXRef, scrollYRef, colCount, rowCount, getColWidth, getRowHeight } = ctx;

  const getLeft = usePosition(getColWidth);
  const getTop = usePosition(getRowHeight);
  const totalWidth = getLeft(colCount - 1) + getColWidth(colCount - 1);
  const totalHeight = getTop(rowCount - 1) + getRowHeight(rowCount - 1);

  const scrollTo = useCallback(
    (indices: { x?: number; y?: number }) => {
      const $scrollX = scrollXRef.current;
      if (typeof indices.x === 'number' && $scrollX) {
        const left = getLeft(indices.x);
        $scrollX.scrollTo({ left, behavior: 'smooth' });
      }
      const $scrollY = scrollYRef.current;
      if (typeof indices.y === 'number' && $scrollY) {
        const top = getLeft(indices.y);
        $scrollY.scrollTo({ top, behavior: 'smooth' });
      }
    },
    [getLeft, scrollXRef, scrollYRef]
  );

  return {
    ...ctx,
    totalWidth,
    totalHeight,
    getLeft,
    getTop,
    scrollTo,
  };
};
