import { updateBlock } from 'src/redux/managers/block/update';
import type { NextBlock } from 'src/redux/types';

// 由于url有可能过时，如果不刷新页面的话会用不了，因此在unMount的时候清掉localUrl

export const releaseLocalUrl = (block?: Partial<NextBlock>) => {
  if (block && block.uuid && block.data?.localUrl?.startsWith('blob')) {
    if (block.data.localUrl.startsWith('blob')) {
      URL.revokeObjectURL(block.data.localUrl);
    }
    updateBlock(
      block.uuid,
      {
        data: {
          localUrl: undefined,
        },
      },
      true
    );
  }
};
