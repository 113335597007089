import { getFileNameInfo, Role } from '@flowus/common';
import { ILLEGAL_TEXT } from '@flowus/common/const-title';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import type { MouseEvent } from 'react';
import { useCallback, useRef } from 'react';
import { message } from 'src/common/components/message';
import { useOpenPreview } from 'src/common/utils/open-preview';
import { getPreviewType } from 'src/components/file-preview/renderElements';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import { segmentsToText } from 'src/editor/utils/editor';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useDownloadFile } from 'src/hooks/drive/use-download';
import { useResource } from 'src/hooks/public/use-resource';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useSpaceDomainPath } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { ViewModel } from 'src/redux/types';
import { useIsMobileSize } from 'src/services/app';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import { useSelectFile } from '../file/empty-file';
import { useSelectFolder } from '../folder/empty-folder';
import type { ViewModeFcProps, ViewModelToolBarProps } from './type';
import { useNormalizePath } from 'src/hooks/page';

export const useViewModelController = (props: ViewModeFcProps) => {
  const { uuid, isFile, readonly, ownerBlockId } = props;
  const block = usePickBlock(uuid, ['data'], ['ref', 'viewMode']);
  const isMobileSize = useIsMobileSize();
  const ownerBlock = usePickBlock(ownerBlockId, ['data'], ['ref', 'viewMode']);
  const fileBlock = usePickBlock(uuid, ['data'], ['segments']);
  const isFail = Boolean(block?.data.ref?.uuid && !fileBlock);
  const openFilePreview = useOpenFilePreview();
  // const onChangeViewModel = useChangeViewMode(ownerBlockId || uuid);
  const openPreview = useOpenPreview();
  const selectFolder = useSelectFolder({ uuid });
  const selectFile = useSelectFile({ uuid });
  const downloadFile = useDownloadFile();
  const viewMode = (ownerBlock || block)?.data.viewMode || ViewModel.normal;
  const domain = useSpaceDomainPath(block?.spaceId);
  const isSharePage = judgeSharePage();
  const openLink = useNormalizePath(uuid);
  const fileName = segmentsToText(fileBlock?.data.segments);
  const { extName } = getFileNameInfo(fileName);
  const isPDF = extName.toLowerCase() === 'pdf';
  const previewType = getPreviewType(extName);
  const blockRef = useRef(null);
  const isFreeSpaceLimit = useIsFreeSpaceLimit(block?.spaceId);
  const { url: imagePreviewUrl } = useResource(uuid, { isImage: true });
  const openImagePreview = useOpenImagePreview();
  const { enableAI } = useEnableAI();

  // 被风控了
  const { role, illegal, allowDownload } = usePermissions(uuid);

  const openCommentPopup = useOpenNewDiscussionPopup();

  const onReplace = useCallback(
    (event: MouseEvent) => {
      const rect = event.currentTarget.getBoundingClientRect();
      if (isFile) {
        selectFile({ getBoundingClientRect: () => rect });
      } else {
        selectFolder({ getBoundingClientRect: () => rect });
      }
    },
    [isFile, selectFile, selectFolder]
  );

  const downLoad = useCallback(() => downloadFile(uuid), [downloadFile, uuid]);

  const showDownLoadButton = isFile && !isFail && (readonly ? allowDownload : true);

  const previewFile = (fileId: string) => {
    if (illegal && isSharePage) {
      message.error(ILLEGAL_TEXT);
      return;
    }
    if (previewType && previewType === 'pdf') {
      window.open(`/${isSharePage ? 'share/' : 'preview/'}${fileId}`);
    } else {
      if (!isFreeSpaceLimit && previewType === 'epub') {
        window.open(`/${isSharePage ? 'share/' : 'preview/'}${fileId}`);
      } else if (previewType === 'image') {
        openImagePreview({ uuid: fileId, src: imagePreviewUrl });
      } else {
        openFilePreview(fileId, ownerBlockId);
      }
    }
  };

  const onOpen = isFile ? () => previewFile(uuid) : undefined;

  let toolBarProps: ViewModelToolBarProps = { splitLine: [] };
  if (!illegal) {
    if (readonly) {
      if (!isFail) {
        toolBarProps = {
          openLink,
          onOpen,
          onDownLoad: showDownLoadButton ? downLoad : undefined,
          splitLine: [],
          readonly,
        };
      }
    } else {
      toolBarProps = {
        onPdfAI: isPDF && enableAI ? () => openPreview(uuid) : undefined,
        hiddenPreview: isFile && !previewType,
        onDownLoad: showDownLoadButton ? downLoad : undefined,
        viewMode,
        // onChangeViewModel,
        onReplace: !ownerBlockId && isFile ? onReplace : undefined,
        openLink,
        readonly,
        onOpen,
      };
    }
    if (
      !ownerBlockId &&
      Role.contains(role, PermissionRole.COMMENTER) &&
      // 文件夹没有评论入口
      block?.type !== BlockType.FOLDER
    ) {
      Object.assign(toolBarProps, {
        onAddComment: () => {
          openCommentPopup({ blockId: uuid });
        },
      });
    }
  }

  return {
    isMobileSize,
    illegal,
    isFail,
    toolBarProps,
    openFilePreview: previewFile,
    // onChangeViewModel,
    onReplace,
    downLoad,
    domain,
    openLink,
    blockRef,
    hasOverlay: Boolean(ownerBlockId),
  };
};
