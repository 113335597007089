import type { CSSProperties, FC } from 'react';
import { useRef } from 'react';
import { IS_APPLE } from '../../utils/environment';
import { PRIORITY_DIALOG } from '../../utils/global-listener-helper';
import { DirectionScroller } from '../direction-scroller';
import type { EmojiData } from './emoji-mart';
import { getAllEmojiData, getSanitizedData } from './emoji-mart/utils';
import { IconWidget } from './icon-widget';

export const EmojiSkin: FC<{
  emoji: EmojiData;
  onSelect: (emojiStr: EmojiData) => void;
  emojiSize?: number;
  style?: CSSProperties;
}> = (props) => {
  const emojiSet = IS_APPLE ? '' : 'google';
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={divRef} className="flex p-1 next-modal" style={props.style}>
      <DirectionScroller
        listenPriority={PRIORITY_DIALOG + 1}
        containerRef={divRef}
        itemCount={6}
        spanCount={6}
        type="grid"
      >
        {(activeIndex: number) => {
          return Array.from({ length: 6 }).map((_, i) => {
            const emojiData = getSanitizedData(props.emoji, i + 1, emojiSet, getAllEmojiData());
            return (
              <IconWidget
                className={activeIndex === i ? 'emoji-mart-emoji-active' : undefined}
                onClick={() => props.onSelect(emojiData)}
                key={i}
                emojiStr={emojiData.native}
                iconSize={props.emojiSize || 24}
              />
            );
          });
        }}
      </DirectionScroller>
    </div>
  );
};
