import type { FC } from 'react';
import { cx } from '../cx';

interface Props {
  text?: string;
  search: string;
  className?: string;
  ignoreUpperCase?: boolean; // 默认不区分大小写
  containerClassName?: string;
}

/** 只highLight第一个search字符串,简单需求简单做，需要复杂的高亮可以再重写一个 */
export const Highlight: FC<Props> = ({
  text,
  search,
  className,
  ignoreUpperCase = true,
  containerClassName,
}) => {
  if (!text) return null;
  const index = (ignoreUpperCase ? text.toUpperCase() : text).indexOf(
    ignoreUpperCase ? search.toUpperCase() : search
  );
  if (index === -1) return <>{text}</>;
  const preSearchText = text.slice(0, index);
  const middleText = text.slice(index, index + search.length);
  const afterSearchText = text.slice(index + search.length, text.length);

  return (
    <span className={containerClassName}>
      <span className={className}>{preSearchText}</span>
      <span className={cx(className, 'text-active_color')}>{middleText}</span>
      <span className={className}>{afterSearchText}</span>
    </span>
  );
};
