interface WatermarkSettings {
  watermark_id: string;
  watermark_prefix: string;
  watermark_txt: string;
  watermark_x: number;
  watermark_y: number;
  watermark_rows: number;
  watermark_cols: number;
  watermark_x_space: number;
  watermark_y_space: number;
  watermark_font: string;
  watermark_color: string;
  watermark_fontsize: string;
  watermark_alpha: number;
  watermark_width: number;
  watermark_height: number;
  watermark_angle: number;
  watermark_parent_width: number;
  watermark_parent_height: number;
  watermark_parent_node: string | null;
  monitor: boolean;
  print: boolean;
}

export const defaultSettings: WatermarkSettings = {
  watermark_id: 'wm_div_id',
  watermark_prefix: 'mask_div_id',
  watermark_txt: '测试水印',
  watermark_x: 20,
  watermark_y: 20,
  watermark_rows: 0,
  watermark_cols: 0,
  watermark_x_space: 50,
  watermark_y_space: 50,
  watermark_font: '微软雅黑',
  watermark_color: 'black',
  watermark_fontsize: '18px',
  watermark_alpha: 0.15,
  watermark_width: 100,
  watermark_height: 100,
  watermark_angle: 15,
  watermark_parent_width: 0,
  watermark_parent_height: 0,
  watermark_parent_node: null,
  monitor: true,
  print: false,
};

let forceRemove = false;
let globalSetting: Partial<WatermarkSettings>;
const hasObserver = MutationObserver !== undefined;
const option = {
  childList: true,
  attributes: true,
  subtree: true,
};

const watermarkDom = hasObserver ? new MutationObserver(domChangeCallback) : null;

function domChangeCallback(records: MutationRecord[]) {
  if (forceRemove) {
    forceRemove = false;
    return;
  }
  if (
    (globalSetting && records.length === 1) ||
    (records.length === 1 && records[0] && records[0].removedNodes.length >= 1)
  ) {
    loadMark(globalSetting);
  }
}

const loadMark = (settings: Partial<WatermarkSettings>) => {
  if (settings) {
    Object.assign(defaultSettings, settings);
  }

  const watermarkElement = document.getElementById(defaultSettings.watermark_id);
  if (watermarkElement && watermarkElement.parentNode) {
    watermarkElement.parentNode.removeChild(watermarkElement);
  }

  let watermarkParentElement = defaultSettings.watermark_parent_node
    ? document.getElementById(defaultSettings.watermark_parent_node)
    : null;

  if (settings.print) {
    watermarkParentElement =
      document.getElementById('next-space-page') ||
      document.querySelector('.block-content') ||
      document.body;
  }

  const watermarkHookElement = watermarkParentElement || document.body;

  const pageWidth = Math.max(watermarkHookElement.scrollWidth, watermarkHookElement.clientWidth);
  const pageHeight = Math.max(watermarkHookElement.scrollHeight, watermarkHookElement.clientHeight);

  const parentEle = watermarkHookElement;
  let pageOffsetTop = 0;
  let pageOffsetLeft = 0;
  if (settings.watermark_parent_width || settings.watermark_parent_height) {
    if (parentEle) {
      pageOffsetTop = parentEle.offsetTop || 0;
      pageOffsetLeft = parentEle.offsetLeft || 0;
      defaultSettings.watermark_x += pageOffsetLeft;
      defaultSettings.watermark_y += pageOffsetTop;
    }
  } else {
    if (parentEle) {
      pageOffsetTop = parentEle.offsetTop || 0;
      pageOffsetLeft = parentEle.offsetLeft || 0;
    }
  }

  let otdiv = document.getElementById(defaultSettings.watermark_id) as HTMLElement;
  let shadowRoot: ShadowRoot | HTMLElement | null = null;

  if (!otdiv) {
    otdiv = document.createElement('div');
    otdiv.id = defaultSettings.watermark_id;
    otdiv.setAttribute('style', 'pointer-events: none !important; display: block !important');
    if (typeof otdiv.attachShadow === 'function') {
      shadowRoot = otdiv.attachShadow({ mode: 'open' });
    } else {
      shadowRoot = otdiv;
    }
    const nodeList = watermarkHookElement.children;
    const index = Math.floor(Math.random() * (nodeList.length - 1));
    if (nodeList[index]) {
      watermarkHookElement.insertBefore(otdiv, nodeList[index]!);
    } else {
      watermarkHookElement.appendChild(otdiv);
    }
  } else if (otdiv.shadowRoot) {
    shadowRoot = otdiv.shadowRoot as ShadowRoot;
  }

  defaultSettings.watermark_cols = Math.floor(
    (pageWidth - defaultSettings.watermark_x) /
      (defaultSettings.watermark_width + defaultSettings.watermark_x_space)
  );
  const tempWatermarkXSpace = Math.floor(
    (pageWidth -
      defaultSettings.watermark_x -
      defaultSettings.watermark_width * defaultSettings.watermark_cols) /
      defaultSettings.watermark_cols
  );
  defaultSettings.watermark_x_space = tempWatermarkXSpace
    ? defaultSettings.watermark_x_space
    : tempWatermarkXSpace;

  defaultSettings.watermark_rows = Math.floor(
    (pageHeight - defaultSettings.watermark_y) /
      (defaultSettings.watermark_height + defaultSettings.watermark_y_space)
  );
  const tempWatermarkYSpace = Math.floor(
    (pageHeight -
      defaultSettings.watermark_y -
      defaultSettings.watermark_height * defaultSettings.watermark_rows) /
      defaultSettings.watermark_rows
  );
  defaultSettings.watermark_y_space = tempWatermarkYSpace
    ? defaultSettings.watermark_y_space
    : tempWatermarkYSpace;

  let allWatermarkWidth;
  let allWatermarkHeight;
  if (watermarkParentElement) {
    allWatermarkWidth =
      defaultSettings.watermark_x +
      defaultSettings.watermark_width * defaultSettings.watermark_cols +
      defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1);
    allWatermarkHeight =
      defaultSettings.watermark_y +
      defaultSettings.watermark_height * defaultSettings.watermark_rows +
      defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1);
  } else {
    allWatermarkWidth =
      pageOffsetLeft +
      defaultSettings.watermark_x +
      defaultSettings.watermark_width * defaultSettings.watermark_cols +
      defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1);
    allWatermarkHeight =
      pageOffsetTop +
      defaultSettings.watermark_y +
      defaultSettings.watermark_height * defaultSettings.watermark_rows +
      defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1);
  }

  let x: number;
  let y: number;
  for (let i = 0; i < defaultSettings.watermark_rows; i++) {
    if (watermarkParentElement) {
      y =
        pageOffsetTop +
        defaultSettings.watermark_y +
        (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
    } else {
      y =
        defaultSettings.watermark_y +
        (pageHeight - allWatermarkHeight) / 2 +
        (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
    }
    for (let j = 0; j < defaultSettings.watermark_cols; j++) {
      if (watermarkParentElement) {
        x =
          pageOffsetLeft +
          defaultSettings.watermark_x +
          (pageWidth - allWatermarkWidth) / 2 +
          (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
      } else {
        x =
          defaultSettings.watermark_x +
          (pageWidth - allWatermarkWidth) / 2 +
          (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
      }
      const maskDiv = document.createElement('div');
      const oText = document.createTextNode(defaultSettings.watermark_txt);
      maskDiv.appendChild(oText);
      maskDiv.id = defaultSettings.watermark_prefix + i + j;
      maskDiv.style.webkitTransform = `rotate(-${defaultSettings.watermark_angle}deg)`;
      //@ts-ignore ignore
      maskDiv.style.MozTransform = `rotate(-${defaultSettings.watermark_angle}deg)`;
      //@ts-ignore ignore
      maskDiv.style.msTransform = `rotate(-${defaultSettings.watermark_angle}deg)`;
      //@ts-ignore ignore
      maskDiv.style.OTransform = `rotate(-${defaultSettings.watermark_angle}deg)`;
      maskDiv.style.transform = `rotate(-${defaultSettings.watermark_angle}deg)`;
      maskDiv.style.visibility = '';
      maskDiv.style.position = 'absolute';
      maskDiv.style.left = `${x}px`;
      maskDiv.style.top = `${y}px`;
      maskDiv.style.overflow = 'hidden';
      maskDiv.style.zIndex = '9999999';
      maskDiv.style.opacity = defaultSettings.watermark_alpha?.toString();
      maskDiv.style.fontSize = defaultSettings.watermark_fontsize;
      maskDiv.style.fontFamily = defaultSettings.watermark_font;
      maskDiv.style.color = defaultSettings.watermark_color;
      maskDiv.style.textAlign = 'center';
      maskDiv.style.width = `${defaultSettings.watermark_width}px`;
      maskDiv.style.height = `${defaultSettings.watermark_height}px`;
      maskDiv.style.display = 'block';
      maskDiv.style.whiteSpace = 'nowrap';
      //@ts-ignore ignore
      maskDiv.style.msUserSelect = 'none';
      maskDiv.style.userSelect = 'none';
      (shadowRoot as HTMLElement).appendChild(maskDiv);
    }
  }

  const monitor = settings.monitor === undefined ? defaultSettings.monitor : settings.monitor;
  if (monitor && hasObserver) {
    watermarkDom?.observe(watermarkHookElement, option);
    watermarkDom?.observe(
      document.getElementById(defaultSettings.watermark_id)!.shadowRoot!,
      option
    );
  }
};

const removeMark = (watermarkId?: string) => {
  const watermarkElement = document.getElementById(watermarkId ?? defaultSettings.watermark_id);
  if (watermarkElement && watermarkElement.parentNode) {
    watermarkElement.parentNode.removeChild(watermarkElement);
  }
  watermarkDom?.disconnect();
};
/**
 * https://github.com/saucxs/watermark-dom/blob/master/watermark.js
 * 上面这个项目直接使用会报缺了key的错，这里直接翻译成ts
 */
export const watermark = {
  init(settings: Partial<WatermarkSettings>) {
    globalSetting = settings;
    loadMark(settings);
    window.addEventListener('load', () => {
      loadMark(settings);
    });
    window.addEventListener('resize', () => {
      loadMark(settings);
    });
  },
  load(settings: Partial<WatermarkSettings>) {
    globalSetting = settings;
    loadMark(settings);
  },
  remove(waterMarkId?: string) {
    forceRemove = true;
    removeMark(waterMarkId);
  },
};
