import { zip } from 'lodash-es';
import { addOssProcessParams } from '../url';

export function* iterWidths(opt: {
  maxWidth: number;
  minimalWidthHint?: number;
  mode?: 'half' | 'gradual';
}) {
  const { maxWidth, minimalWidthHint = 100, mode = 'half' } = opt;
  const minWidth = Math.max(100, minimalWidthHint);
  let width = maxWidth;

  do {
    yield Math.floor(width);
    if (mode === 'half') {
      width = width / 2;
    } else if (mode === 'gradual') {
      width = width - 70;
    }
  } while (width >= minWidth);
}

export function responsiveProps(widths: number[], urls: string[]) {
  return {
    srcSet: zip(widths, urls)
      .reverse()
      .map(([width, url]) => `${url ? url : 'about:blank'} ${width}w`)
      .join(', '),
  };
}

export const getSrcSetParams = (url: string, maxWidth?: number, sizes?: number) => {
  const widths = [...iterWidths({ maxWidth: maxWidth ?? 1920 })];
  return {
    ...responsiveProps(
      widths,
      widths.map((width) => addOssProcessParams(url, `/image/resize,w_${width}/quality,q_90`))
    ),
    sizes: sizes ? `${sizes?.toFixed(0)}px` : sizes,
  };
};
