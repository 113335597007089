import type { FC } from 'react';
import { useRef } from 'react';
import { useChartBlockId } from '../chart-config-context';
import { NumberChartStyle } from './number-chart-style';
import { useChartFormat } from 'src/hooks/chart';
import { ChartStyle } from './chart-style';

export const StyleConfig: FC = () => {
  const divRef = useRef<HTMLDivElement>(null);

  const chartBlockId = useChartBlockId();
  const format = useChartFormat(chartBlockId);

  return (
    <div ref={divRef} className="w-full px-4 py-3 ">
      {format?.chartMode?.type === 'Number' ? <NumberChartStyle /> : <ChartStyle />}
    </div>
  );
};
