import { BlockType } from '@next-space/fe-api-idl';
import type { NextBlock } from 'src/redux/types';
import { calculateListFormat } from 'src/utils/block-utils';

export function getOrderListNumber(uuid: string, blocks: Record<string, NextBlock>) {
  const formatInfo = calculateListFormat(uuid, blocks, BlockType.ORDER_LIST);
  if (!formatInfo) {
    return '1';
  }
  let result = `${formatInfo.listNo}`;
  const format = formatInfo.format || formatInfo.defaultFormat;
  if (format === 'roman') {
    result = romanize(formatInfo.listNo);
  }

  if (format === 'letters') {
    result = letters(formatInfo.listNo - 1);
  }

  return result;
}

export function getNextOrderListNumber(uuid: string, blocks: Record<string, NextBlock>) {
  const formatInfo = calculateListFormat(uuid, blocks, BlockType.ORDER_LIST);
  if (!formatInfo) {
    return '2';
  }
  let result = `${formatInfo.listNo + 1}`;
  const format = formatInfo.format || formatInfo.defaultFormat;
  if (format === 'roman') {
    result = romanize(formatInfo.listNo + 1);
  }

  if (format === 'letters') {
    result = letters(formatInfo.listNo);
  }

  return result;
}
const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const base = alphabet.length;
const letters = (num: number) => {
  const digits = [];

  do {
    const v = num % base;
    digits.push(v);
    num = Math.floor(num / base);
  } while (num-- > 0);

  const chars = [];
  while (digits.length) {
    const n = digits.pop();
    if (typeof n !== 'undefined') {
      chars.push(alphabet[n]);
    }
  }

  return chars.join('');
};

export const romanNumerals = [
  '',
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',
  'ix',
  '',
  'x',
  'xx',
  'xxx',
  'xl',
  'l',
  'lx',
  'lxx',
  'lxxx',
  'xc',
  '',
  'c',
  'cc',
  'ccc',
  'cd',
  'd',
  'dc',
  'dcc',
  'dccc',
  'cm',
];
const romanize = (num: number) => {
  const digits = num.toString().split('');
  let roman = '';
  [0, 1, 2].forEach((numeralSet) => {
    const currentDigit = Number(digits.pop());
    if (isNaN(currentDigit)) {
      return;
    }
    const numeralsIndex = currentDigit + numeralSet * 10;
    const numeral = romanNumerals[numeralsIndex];
    roman = numeral + roman;
  });
  const thousands = Number(digits.join(''));
  const thousandsNumerals = 'm'.repeat(thousands);
  return thousandsNumerals + roman;
};
