import isHotkey from 'is-hotkey';
import type {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  ReactNode,
  RefObject,
} from 'react';
import { useEffect, useRef, useState } from 'react';
import { sleep } from '../async';
import { cx } from '../cx';

export interface InputType {
  text: string;
  number: number;
}
export interface InputProps<T extends keyof InputType> {
  defaultValue?: string | number;
  visible?: boolean;
  autoFocus?: boolean;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  warning?: string;
  warningClassName?: string;
  inputRef?: RefObject<HTMLInputElement> | null | any;
  onEnter?: Function;
  onChange?: (value: InputType[T]) => void;
  disableFloat?: boolean;
  type?: T;
  value?: InputType[T] | string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
  style?: CSSProperties;
  showBorder?: boolean;
  focusHiddenPlaceholder?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  selection?: number[];
  readonly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputType?: HTMLInputTypeAttribute | undefined;
  onCompositionChange?: (isComposing: boolean) => void;
  delayFocus?: number;
  inputStyle?: CSSProperties;
  focusWidthBorder?: boolean;
}

/**
 * 输入框
 */
export const Input = <T extends keyof InputType = 'text'>(props: InputProps<T>) => {
  const {
    type = 'text',
    visible = true,
    showBorder = true,
    focusWidthBorder = true,
    value,
    onChange,
    placeholder,
    addonAfter,
    addonBefore,
    maxLength,
    autoFocus,
    warning,
    inputRef,
    onEnter,
    defaultValue,
    onBlur,
    onFocus,
    selection,
    inputClassName = '',
    style,
    readonly = false,
    onClick,
    onKeyDown,
    disableFloat = false,
    focusHiddenPlaceholder = false,
    warningClassName,
    delayFocus = 0,
  } = props;
  const [isFocus, setIsFocus] = useState(false);
  const isComposing = useRef(false);
  const currentInputRef = useRef<HTMLInputElement>(null);
  const isReady = useRef(false);
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    const changeValue = event.target.value;
    if (props.inputType === 'number' && changeValue.includes('-')) {
      return;
    }
    let value: any = changeValue;
    if (type === 'number' && disableFloat) {
      value = parseInt(value, 10);
    }

    if (Number.isNaN(value)) {
      value = '';
    }

    onChange(value as InputType[T]);
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current = currentInputRef.current;
    }
    if (selection?.[0] !== undefined && selection[1] !== undefined) {
      currentInputRef.current?.setSelectionRange(selection[0], selection[1]);
    }

    void sleep(delayFocus).then(() => {
      autoFocus && currentInputRef.current?.focus();
      isReady.current = true;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={style}
      className={cx(
        'text-t2 relative box-border flex items-center rounded border border-grey6 bg-grey9',
        {
          hidden: !visible,
          'border-red': warning,
          'focus-within-border': showBorder && !warning && focusWidthBorder,
          'border-none': !showBorder,
        },
        props.className
      )}
      onClick={() => {
        onClick?.();
        if (!readonly && !addonBefore && !addonAfter) {
          currentInputRef.current?.focus();
        }
      }}
    >
      {addonBefore && (
        <span className={'mr-1 flex h-full items-center justify-center whitespace-nowrap'}>
          {addonBefore}
        </span>
      )}
      <input
        ref={currentInputRef}
        spellCheck={false}
        className={cx(
          'w-full flex-grow cursor-text bg-transparent px-3 align-middle text-black placeholder-grey4 outline-none',
          inputClassName
        )}
        type={props.inputType ?? 'text'}
        onKeyDown={(event) => {
          if (onKeyDown) {
            onKeyDown(event);
          } else {
            event.stopPropagation();
            if (isHotkey('mod+s')(event)) {
              event.preventDefault();
            }
            if (isComposing.current) return;
            if (isHotkey('enter')(event) && onEnter) onEnter(event);
          }
        }}
        disabled={readonly}
        onFocus={() => {
          if (readonly) return;
          onFocus?.();
          setIsFocus(true);
        }}
        onBlur={() => {
          if (isReady.current) {
            onBlur?.();
            setIsFocus(false);
          }
        }}
        onClick={(event) => {
          currentInputRef.current?.focus();
          event.stopPropagation();
        }}
        autoFocus={autoFocus}
        maxLength={maxLength || -1}
        value={value}
        defaultValue={defaultValue}
        placeholder={focusHiddenPlaceholder && isFocus ? '' : placeholder}
        onInput={handleInputChange}
        onCompositionStart={() => {
          props.onCompositionChange?.(true);
          isComposing.current = true;
        }}
        onCompositionEnd={() => {
          props.onCompositionChange?.(false);
          isComposing.current = false;
        }}
        style={props.inputStyle}
      />
      {addonAfter && (
        <span className={'flex h-full items-center justify-center whitespace-nowrap mr-1'}>
          {addonAfter}
        </span>
      )}
      {warning && (
        <span
          className={cx('text-t4 absolute bottom-full left-2 mb-0.5 text-red', warningClassName)}
        >
          {warning}
        </span>
      )}
    </div>
  );
};

interface FormProps {
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  showBorder?: boolean;
  inputProps: any;
}
export const FormInput = (props: FormProps & JSX.IntrinsicElements['input']) => {
  const {
    inputProps,
    addonAfter,
    addonBefore,
    disabled,
    showBorder = true,
    className,
    ...reset
  } = props;
  return (
    <div
      className={cx(
        'flex items-center rounded relative box-border text-t1 border border-grey6 bg-grey9',
        {
          'transition-all focus-within:border-p6': showBorder,
          'border-none': !showBorder,
          'opacity-60': disabled,
        },
        className
      )}
    >
      {addonBefore && (
        <span className="flex items-center justify-center h-full mr-1 whitespace-nowrap">
          {addonBefore}
        </span>
      )}
      <input
        spellCheck={false}
        className={cx(
          'px-2.5 border-none align-middle cursor-text flex-grow text-black outline-none bg-transparent placeholder-grey4 w-full text-t2',
          { 'text-grey2': disabled }
        )}
        disabled={disabled}
        {...inputProps}
        {...reset}
      />
      {addonAfter && (
        <span className="flex items-center justify-center h-full pl-2 whitespace-nowrap">
          {addonAfter}
        </span>
      )}
    </div>
  );
};
