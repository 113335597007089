import { useMindMapEngine, useMindMapId } from '@flowus/mind-map';
import { throttle } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { ResizeStatus, useResizeListener } from '@flowus/common/hooks/use-resize-listener';
import {
  globalListenerHelper,
  PRIORITY_DIALOG,
  PRIORITY_NORMAL,
} from 'src/common/utils/global-listener-helper';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { $searchParams } from '@flowus/common/flowus-utils/search-params';

export const useSmoothScroll = (
  scrollerRef: React.RefObject<HTMLDivElement | null>,
  containerRef: React.RefObject<HTMLDivElement | null>
) => {
  const engine = useMindMapEngine();
  const mindMapId = useMindMapId();
  const pageScene = usePageScene();

  useEffect(() => {
    if (!scrollerRef.current) return;
    if (!containerRef.current) return;
    if (!mindMapId) return;
    const scroller = scrollerRef.current;
    const container = containerRef.current.querySelector('.node-drawer');
    if (!container) return;
    const { zoomin, zoomout } = engine;
    const scrollerMousewheelListener = (e: WheelEvent) => {
      // 这里禁止系统的缩放
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault();
        return false;
      }
    };
    const mousewheelListener = (e: WheelEvent) => {
      if (e.target instanceof Element) {
        const element = e.target.closest('.mind-page');
        if (!element) return;
        if (element instanceof HTMLElement) {
          if (element.dataset.mindPageId === mindMapId) {
            if (e.ctrlKey) {
              const oldScaleFactor = engine.getState().scale * 0.01;
              if (e.deltaY < 0) {
                // 放大
                zoomin(3);
              }
              if (e.deltaY > 0) {
                zoomout(3);
              }
              const newScaleFactor = engine.getState().scale * 0.01;
              if (newScaleFactor !== oldScaleFactor) {
                const rect = container.getBoundingClientRect();
                // 缩放之后scroll值也会有变动，参考https://my.oschina.net/codingDog/blog/2191431
                const x = e.clientX - ((e.clientX - rect.left) / oldScaleFactor) * newScaleFactor;
                const y = e.clientY - ((e.clientY - rect.top) / oldScaleFactor) * newScaleFactor;
                const scrollX = rect.left - x;
                const scrollY = rect.top - y;
                requestAnimationFrame(() => {
                  scroller.scrollBy(scrollX, scrollY);
                });
              }
            } else if (e.shiftKey) {
              requestAnimationFrame(() => {
                scroller.scrollBy(e.deltaY, 0);
              });
            } else {
              const codeBlock = e.target.closest('.CodeMirror-scroll');
              const katexBlock = e.target.closest('.katex-display');
              if (codeBlock || katexBlock) return;
              requestAnimationFrame(() => {
                scroller.scrollBy(e.deltaX, e.deltaY);
              });
            }
          }
          return true;
        }
      }
      return false;
    };
    const throttleMousewheelListener = throttle(mousewheelListener, 16);
    const priority = pageScene === PageScene.PAGE_HISTORY ? PRIORITY_DIALOG : PRIORITY_NORMAL;
    globalListenerHelper.addEventListener('wheel', throttleMousewheelListener, priority);
    scroller.addEventListener('wheel', scrollerMousewheelListener, {
      capture: true,
      passive: false,
    });
    return () => {
      globalListenerHelper.removeEventListener('wheel', throttleMousewheelListener);
      scroller.removeEventListener('wheel', scrollerMousewheelListener, {
        capture: true,
      });
    };
  }, [containerRef, engine, mindMapId, pageScene, scrollerRef]);
};

// 内嵌思维导图以及root节点使用
export const useSmoothScrollByEmbed = (
  containerElementRef: React.RefObject<HTMLDivElement | null>
) => {
  const pageScrollRef = usePageScrollRef();
  const engine = useMindMapEngine();
  const mindMapId = useMindMapId();
  const isContextMenuClickRef = useRef<boolean>();
  const cursor = useRef(pageScrollRef.current?.style.cursor);
  // 由于useResizeListener会吃掉mouseDown，为了保证container能focus得上，因此传递的是containerElementRef
  // 按住左键的拖拽
  useResizeListener(containerElementRef, {
    onResize: (result) => {
      const scroller = pageScrollRef.current;
      if (!scroller) return;
      switch (result.resizeStatus) {
        case ResizeStatus.START: {
          cursor.current = pageScrollRef.current?.style.cursor;
          isContextMenuClickRef.current = true;
          scroller.style.cursor = 'grabbing';
          containerElementRef.current?.focus({ preventScroll: true });
          break;
        }
        case ResizeStatus.RESIZING: {
          isContextMenuClickRef.current = false;
          scroller.scrollBy(-result.deltaX, -result.deltaY);
          break;
        }
        case ResizeStatus.END: {
          scroller.style.cursor = cursor.current ?? '';
          break;
        }
        default:
      }
    },
  });

  useEffect(() => {
    if (!pageScrollRef.current) return;
    if (!mindMapId) return;
    const scroller = pageScrollRef.current;
    const { zoomin, zoomout } = engine;
    const containerMousewheelListener = (e: WheelEvent) => {
      // 这里禁止系统的滚动
      if (e.ctrlKey || e.shiftKey) {
        e.preventDefault();
        return false;
      }
    };
    const mousewheelListener = (e: WheelEvent) => {
      if (e.target instanceof HTMLElement) {
        const element = e.target.closest('.embed-mind-page');
        if (!element) return;
        if (element instanceof HTMLElement) {
          if (element.dataset.mindPageId === mindMapId) {
            if (e.ctrlKey) {
              if (e.deltaY < 0) {
                // 放大
                zoomin(8);
              }
              if (e.deltaY > 0) {
                zoomout(8);
              }
            } else if (e.shiftKey) {
              requestAnimationFrame(() => {
                scroller.scrollBy(e.deltaY, 0);
              });
            }
          }
        }
      }
      return false;
    };
    const throttleMousewheelListener = throttle(mousewheelListener, 16);
    document.addEventListener('wheel', throttleMousewheelListener, true);
    scroller.addEventListener('wheel', containerMousewheelListener, {
      capture: true,
      passive: false,
    });
    return () => {
      document.removeEventListener('wheel', throttleMousewheelListener, true);
      scroller.removeEventListener('wheel', containerMousewheelListener, {
        capture: true,
      });
    };
  }, [containerElementRef, engine, mindMapId, pageScrollRef]);
};
