import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionType } from '@next-space/fe-api-idl';

export const getRolePermissions = (permissions: PermissionDTO[]) => {
  return permissions.filter(
    (permission) =>
      permission.type === PermissionType.SPACE ||
      permission.type === PermissionType.USER ||
      permission.type === PermissionType.GROUP
  );
};

export const getDiffPermissions = (permissions: PermissionDTO[]) => ({
  spacePermission: permissions.find((o) => o.type === PermissionType.SPACE),
  groupPermissions: permissions.filter((o) => o.type === PermissionType.GROUP),
  userPermissions: permissions.filter((o) => o.type === PermissionType.USER),
});
