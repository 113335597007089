import { BlockStatus } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { CREATE_COLLECTION_VIEW, LIST_AFTER_COLLECTION_VIEW } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { v4 as uuidV4 } from 'uuid';

import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';

export const useCopyCollectionView = () => {
  const transaction = useTransaction();

  const copyView = (viewId: string) => {
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { view } = viewInfo;

    const uuid = uuidV4();

    const currentTime = Date.now();

    const newCollectionView = {
      ...view,
      format: {
        ...view.format,
        // copy收集表视图时，需要关闭分享。并且把id置空
        formShared: false,
      },
      uuid,
      status: BlockStatus.NORMAL,
      createdAt: currentTime,
      updatedAt: currentTime,
      version: 0,
      title: view.title ? `副本 ${view.title}` : '',
      shareId: undefined,
    };

    return transaction(() => {
      dispatch(
        CREATE_COLLECTION_VIEW({
          collectionView: newCollectionView,
        })
      );

      dispatch(
        LIST_AFTER_COLLECTION_VIEW({
          uuid,
          parentId: view.parentId, // 引用多维表或者普通多维表
          after: viewId,
        })
      );

      return uuid;
    });
  };

  return useCallback(copyView, [transaction]);
};
