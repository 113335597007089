import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';

/**
 * Divider 组件没有 children
 */
export const DividerBlockElement: BlockElement = ({ id, root, children }) => {
  return (
    <>
      <BlockDrop id={id} horizontal={root}>
        <div className="flex items-center h-5 leading-5">
          <div className="h-px w-full bg-grey6" />
        </div>
      </BlockDrop>
      {children}
    </>
  );
};
