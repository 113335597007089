import type { formula } from '@flowus/formula';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import { antiCycleSet_getRollupValue_0, getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import {
  antiCycleSet_getRollupValue_1,
  calculateAggregationValue,
} from '../../footer/calculate-aggregation-value';
import { getColCellValues } from '../../footer/get-col-values';
import { getAvailableAggregations } from '../../footer/menu-item';
import { getRelationRecords } from '../relation/get-relation-records';

interface RollupValues {
  allCellValues: (string | string[] | undefined)[]; // 每条记录指定属性的值的集合，在筛选条件有 any/every 时会用到
  originValues: string[]; // 原始值(显示原始唯一值时进行了过滤)，在展示统计排序筛选时都会使用
  aggregationValue?: string; // 汇总显示统计值时的统计值
  dateStartTimestamp?: string; // 汇总显示日期范围时的开始时间
  dateEndTimestamp?: string; // 汇总显示日期范围时的结束时间
  targetProperty: string;
  targetPropertySchema: CollectionSchema;
  relationCollection: NextBlock; // 对应关联属性关联的表 block
  relationRecords: string[]; // 对应关联属性中所有的关联记录 ids
  isUniqueValue?: boolean; // 是否显示唯一值
  formulaType?: formula.ValueType | null; // 当汇总的属性是公式，表示汇总出来的值的类型
  isEmpty: boolean; // rollup 值是否为空 => 筛选时用
  originValueToStringFunc: (value: string) => string;
}

export const getRollupValue = MemoizeByBlockVersion.create(
  (recordId: string, propertyId: string, state: RootState = getState()) => {
    const { blocks } = state;

    const block = blocks[recordId];
    const collection = blocks[block?.parentId ?? ''];
    const propertySchema = collection?.data.schema?.[propertyId];

    const { relationProperty, targetProperty, aggregation } = propertySchema ?? {};

    if (!relationProperty || !targetProperty) return undefined; // 汇总属性设置无效

    const { relationRecords, relationCollection } = getRelationRecords(
      recordId,
      relationProperty,
      state.blocks
    );

    if (!relationCollection) return undefined; // 关联表不存在

    const targetPropertySchema = relationCollection.data.schema?.[targetProperty];
    if (!targetPropertySchema) return undefined; // 关联表上的属性不存在
    const targetPropertyType = targetPropertySchema.type;

    const formulaTool = getFormulaTool(relationCollection.uuid);

    const { allCellValues, notEmptyCellValues } = getColCellValues({
      blocks: relationRecords.map((uuid) => blocks[uuid]),
      propertyId: targetProperty,
      schema: targetPropertySchema,
      formulaTool,
    });

    const allValues = notEmptyCellValues.flat(); // 对于人员，多选，文件这种多值属性，将所有值平铺出来。显示原始值时展示在汇总属性中的值
    const uniqueValues = [...new Set(allValues)]; //  过滤掉 allValues 中重复值值后剩下的所有的唯一值。显示唯一值时展示的值
    const isUniqueValue = aggregation === AggregationAction.SHOW_UNIQUE;
    const originValues = isUniqueValue ? uniqueValues : allValues;
    const isFormula = targetProperty && targetPropertyType === CollectionSchemaType.FORMULA;

    const result: RollupValues = {
      allCellValues,
      originValues,
      targetProperty,
      targetPropertySchema,
      relationCollection,
      relationRecords,
      isUniqueValue,
      formulaType: isFormula ? formulaTool.getType(targetProperty) : undefined,
      isEmpty: originValues.length === 0,
      originValueToStringFunc: (value) => value,
    };

    if (
      targetPropertyType === CollectionSchemaType.SELECT ||
      targetPropertyType === CollectionSchemaType.MULTI_SELECT
    ) {
      result.originValueToStringFunc = (value) => {
        return (targetPropertySchema.options ?? []).find((it) => it.id === value)?.value ?? '';
      };
    } else if (
      targetPropertyType === CollectionSchemaType.PERSON ||
      targetPropertyType === CollectionSchemaType.CREATED_BY ||
      targetPropertyType === CollectionSchemaType.UPDATED_BY
    ) {
      result.originValueToStringFunc = (value) => {
        return getUserName(state.users[value]?.uuid ?? '');
      };
    } else if (targetPropertyType === CollectionSchemaType.RELATION) {
      result.originValueToStringFunc = (value) => {
        return segmentsToText(state.blocks[value]?.data.segments);
      };
    }

    if (
      aggregation &&
      aggregation !== AggregationAction.SHOW_ORIGIN &&
      aggregation !== AggregationAction.SHOW_UNIQUE
    ) {
      const allAggregations = getAvailableAggregations(relationCollection.uuid, targetProperty);
      const isValidAggregation = allAggregations.find(
        (item) => item.type === aggregation && item.type !== AggregationAction.NONE
      );

      if (isValidAggregation) {
        const aggregationValue = calculateAggregationValue(
          relationRecords.map((uuid) => blocks[uuid]),
          targetProperty,
          aggregation,
          relationCollection.uuid,
          formulaTool
        );

        if (aggregationValue) {
          result.dateStartTimestamp = aggregationValue.dateStartTimestamp;
          result.dateEndTimestamp = aggregationValue.dateEndTimestamp;
          result.aggregationValue =
            typeof aggregationValue.result !== 'undefined'
              ? String(aggregationValue.result)
              : undefined;
          result.isEmpty = !result.aggregationValue;
        } else {
          result.isEmpty = true;
        }
      }
    }

    return result;
  },
  (collectionId, propertyId) => [collectionId, propertyId]
);

// 比较挫的方式解开循环依赖
antiCycleSet_getRollupValue_0(getRollupValue);
antiCycleSet_getRollupValue_1(getRollupValue);
