import { useCallback } from 'react';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { ActivityIds } from 'src/hooks/activities/activity-ids';

import { NoviceGuideModal } from './novice-guidance';

/** 打开新手指引 */

export const useOpenNoticeGuide = () => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();

  return useCallback(
    (closeAfterCallBack?: () => void) => {
      openModal.modal({
        modalId: ActivityIds.GUIDE_FIRST_TIME_CREATED_SPACE,
        className: 'shadow-light',
        autoClose: false,
        keyboard: false,
        content: () => (
          // 完成引导之后再弹出清空模板弹窗
          <NoviceGuideModal
            onCloseModal={() => {
              closeModal(ActivityIds.GUIDE_FIRST_TIME_CREATED_SPACE);
              closeAfterCallBack?.();
            }}
          />
        ),
      });
    },
    [closeModal, openModal]
  );
};
