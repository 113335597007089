import type { Context } from '../../core/context';
import type { IPropertyListDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isIdentToken } from '../syntax/parser';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum BACKGROUND_CLIP {
  BORDER_BOX = 0,
  PADDING_BOX = 1,
  CONTENT_BOX = 2,
}

export type BackgroundClip = BACKGROUND_CLIP[];

export const backgroundClip: IPropertyListDescriptor<BackgroundClip> = {
  name: 'background-clip',
  initialValue: 'border-box',
  prefix: false,
  type: PropertyDescriptorParsingType.LIST,
  parse: (_context: Context, tokens: CSSValue[]): BackgroundClip => {
    return tokens.map((token) => {
      if (isIdentToken(token)) {
        // eslint-disable-next-line default-case
        switch (token.value) {
          case 'padding-box':
            return BACKGROUND_CLIP.PADDING_BOX;
          case 'content-box':
            return BACKGROUND_CLIP.CONTENT_BOX;
        }
      }
      return BACKGROUND_CLIP.BORDER_BOX;
    });
  },
};
