import type { FC } from 'react';
import { useLayoutEffect, useRef } from 'react';
import { useBlockStyle } from 'src/hooks/block/use-block-style';
import { usePickBlock } from 'src/utils/pick-block';
import { getBlockRenderer } from '../_renderers';

interface Props {
  renderIds: string[];
}

export const ImageBlockRender: FC<Props> = ({ renderIds }) => {
  return (
    <>
      {renderIds.map((id) => (
        <ImageBlockElement key={id} uuid={id}></ImageBlockElement>
      ))}
    </>
  );
};

export const ImageBlockElement: FC<{ uuid: string }> = ({ uuid }) => {
  const blockStyle = useBlockStyle(uuid);
  const block = usePickBlock(uuid, ['subNodes']);
  const divRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const el = divRef.current?.querySelector('[data-block-id]');
    if (el) {
      el.setAttribute('style', `margin-top:0px;margin-bottom:0px`);
    }
  }, []);

  const Element = getBlockRenderer(block?.type);

  if (!block) return null;

  return (
    <div ref={divRef} style={blockStyle} className="flex w-full h-full pointer-events-none">
      <Element id={uuid} />
    </div>
  );
};
