import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { BlockType, CardSize, CollectionViewType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import type { NextBlock, NextCollectionView } from 'src/redux/types';
import type { TableFormatValue } from './use-create-property';
import { DEFAULT_COL_WIDTH } from 'src/bitable/const';

export function getViewFormat(
  viewIdOrView: NextCollectionView,
  blocks?: Record<string, NextBlock>,
  collectionViews?: Record<string, CollectionViewDTO>
): TableFormatValue;
export function getViewFormat(
  viewIdOrView: string,
  blocks?: Record<string, NextBlock>,
  collectionViews?: Record<string, CollectionViewDTO>
): TableFormatValue | undefined;
export function getViewFormat(
  viewIdOrView: string | NextCollectionView,
  blocks = getState().blocks,
  collectionViews = getState().collectionViews
): TableFormatValue | undefined {
  let view: NextCollectionView | undefined;

  if (typeof viewIdOrView === 'string') {
    view = collectionViews[viewIdOrView];
  } else {
    view = viewIdOrView;
  }

  let collection = blocks[view?.parentId ?? ''];
  if (
    collection &&
    (collection.type === BlockType.REFERENCE_COLLECTION ||
      collection.type === BlockType.REFERENCE_COLLECTION_PAGE)
  ) {
    collection = blocks[collection.data.ref?.uuid ?? ''];
  }

  if (!view || !collection) {
    return;
  }

  const baseResult = { view, collection };
  switch (view.type) {
    case CollectionViewType.BOARD: {
      return {
        ...baseResult,
        propertiesName: 'boardProperties',
        groupBy: view.format.boardGroupBy,
        subGroupBy: view.format.collectionGroupBy,
        groups: view.format.boardGroups,
        subGroups: view.format.collectionGroups,
        cardSize: view.format.boardCardSize ?? CardSize.MEDIUM,
        coverType: view.format.boardCoverType,
        properties: view.format.boardProperties ?? [],
        coverAspectType: view.format.boardCoverAspect,
      };
    }
    case CollectionViewType.GALLERY: {
      return {
        ...baseResult,
        propertiesName: 'galleryProperties',
        groupBy: view.format.collectionGroupBy,
        groups: view.format.collectionGroups,
        cardSize: view.format.galleryCardSize ?? CardSize.MEDIUM,
        coverType: view.format.galleryCoverType,
        properties: view.format.galleryProperties ?? [],
        coverAspectType: view.format.galleryCoverAspect,
      };
    }
    case CollectionViewType.LIST: {
      return {
        ...baseResult,
        propertiesName: 'listProperties',
        groupBy: view.format.collectionGroupBy,
        groups: view.format.collectionGroups,
        properties: view.format.listProperties ?? [],
      };
    }
    case CollectionViewType.TIMELINE: {
      return {
        ...baseResult,
        propertiesName: 'timelineProperties',
        groupBy: view.format.collectionGroupBy,
        groups: view.format.collectionGroups,
        properties: view.format.timelineProperties ?? [],
        timelineTableProperties: view.format.timelineTableProperties ?? [],
        timelineBy: view.format.timelineBy,
        timelineByEnd: view.format.timelineByEnd,
        timelineShowTable: view.format.timelineShowTable !== false,
      };
    }
    case CollectionViewType.CALENDAR: {
      return {
        ...baseResult,
        propertiesName: 'calendarProperties',
        properties: view.format.calendarProperties ?? [],
        calendarBy: view.format.calendarBy,
        calendarByEnd: view.format.calendarByEnd,
      };
    }
    case CollectionViewType.FORM: {
      return {
        ...baseResult,
        propertiesName: 'formProperties',
        properties: view.format.formProperties ?? [],
        formLogic: view.format.formLogic ?? [],
      };
    }
    default: {
      // 不知道为啥有时候tableproperties会漏数据(是移动端那边新建的视图导致的)，这里wordaround一下
      const schemas = collection.data.schema;
      const { tableProperties = [] } = view.format;
      const sortedIds = new Set(tableProperties.map((v) => v.property));
      const finalTableProperties = [...tableProperties];
      if (schemas) {
        Object.entries(schemas).forEach(([property]) => {
          if (sortedIds.has(property)) return;

          const newProperty = {
            property,
            visible: true,
            width: DEFAULT_COL_WIDTH,
          };

          sortedIds.add(property);
          // 有时候用户返回id属性会娜到后面，怀疑是这里的问题。
          finalTableProperties.unshift(newProperty);
        });
      }

      return {
        ...baseResult,
        propertiesName: 'tableProperties',
        groupBy: view.format.collectionGroupBy,
        groups: view.format.collectionGroups,
        properties: finalTableProperties,
      };
    }
  }
}
