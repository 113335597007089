import type { DrawStyle, Point } from '../type';

export const drawEllipse = (
  canvas: HTMLCanvasElement,
  point1: Point,
  point2: Point,
  drawStyle?: DrawStyle
) => {
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const { x, y } = point1;
  const { x: x2, y: y2 } = point2;

  const width = Math.abs(x2 - x);
  const height = Math.abs(y2 - y);

  const centerX = Math.min(x2, x) + width / 2;
  const centerY = Math.min(y2, y) + height / 2;

  if (drawStyle?.fillStyle) {
    ctx.fillStyle = drawStyle.fillStyle;
  }
  if (drawStyle?.lineWidth) {
    ctx.lineWidth = drawStyle.lineWidth;
  }
  ctx.beginPath();
  ctx.ellipse(centerX, centerY, width / 2, height / 2, 0, 0, 2 * Math.PI);
  ctx.fill();
};
