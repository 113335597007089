import React from 'react';
import data from '../../data/all.json';
// import data from '../../data/custom-icon.json';
import { PickerDefaultProps } from '../../utils/shared-default-props';
import { PickerPropTypes } from '../../utils/shared-props';
import NimblePicker from './nimble-picker';

export default class Picker extends React.PureComponent {
  render() {
    return <NimblePicker {...this.props} {...this.state} />;
  }
}

Picker.propTypes /* remove-proptypes */ = PickerPropTypes;
Picker.defaultProps = { ...PickerDefaultProps, data };
