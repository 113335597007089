import type { IEditorModel } from '@next-space/fe-inlined';
import { newContent, newElement } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { useEffect } from 'react';

import { INLINE_EQUATION_TAG } from '../../../editor/inline/const';

export const InlineEquationPanel: FC<{
  onCloseModal: () => void;
  blockId: string;
  left: number;
  right: number;
  symbol?: string;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
}> = ({ onCloseModal, blockId, left, right, symbol = '', getEditorModel }) => {
  const editorModel = getEditorModel(blockId);

  useEffect(() => {
    if (!editorModel) return;
    const { selection } = editorModel;
    if (!selection) return;
    const cursorLeft = editorModel.createCursor(left, 'left');
    const cursorRight = editorModel.createCursor(right, 'right');

    editorModel.performChange((ctx) => {
      ctx
        .shadow()
        .select(left, right)
        .replace(newContent([newElement(INLINE_EQUATION_TAG, { open: true })]))
        .collapse('end');
    });

    onCloseModal();

    cursorLeft.release();
    cursorRight.release();
  }, [blockId, editorModel, left, onCloseModal, right, symbol]);

  if (!editorModel) return null;

  return null;
};
