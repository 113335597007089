import type { ComponentProps } from 'react';
import type { ReadonlyProp } from 'src/redux/types';

import { BlockEmptyView } from '../block-empty-view';

interface Props extends ReadonlyProp, Pick<ComponentProps<'div'>, 'onClick'> {
  defaultOpenPanel: boolean;
}
export const EmptyAudio = (props: Props) => {
  return (
    <BlockEmptyView
      defaultOpenPanel={props.defaultOpenPanel}
      readonly={props.readonly}
      iconProp={{
        name: 'IcUploadAudio',
        size: 'normal',
      }}
      text="添加音频"
      onClick={props.onClick}
      className={props.readonly ? 'cursor-default' : 'cursor-pointer'}
    />
  );
};
