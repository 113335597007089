import { values } from 'lodash-es';
import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { commentsActions } from 'src/redux/reducers/comments';
import { discussionsActions } from 'src/redux/reducers/discussions';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';

/** 获取block，并加到blocks里 */
export const useFetchBlock = () => {
  const dispatch = useDispatch();
  const store = useStore();
  return useCallback(
    async function fetchBlock(
      pageId,
      opt?: {
        force?: boolean;
      }
    ) {
      const res = await request.editor.getDoc.raw(pageId);

      if (res.code === 200) {
        const { blocks, collectionViews, discussions, comments } = res.data;

        if (collectionViews) {
          dispatch(collectionViewsActions.update(collectionViews));
        }
        if (discussions) {
          dispatch(discussionsActions.update(discussions));
        }
        if (comments) {
          dispatch(commentsActions.update(comments));
        }

        dispatch(
          blocksActions.update({
            blocks: blocks as Record<string, NextBlock>,
            force: opt?.force,
          })
        );

        values(blocks).forEach((block) => {
          if (isPageLike(block.type)) {
            if (block.data.ref?.uuid && !store.getState().blocks[block.data.ref.uuid]) {
              void fetchBlock(block.data.ref?.uuid);
            }
          }
        });
      }

      return res;
    },
    [dispatch, store]
  );
};
