import type { BezierCurve } from './bezier-curve';
import type { Vector } from './vector';
export const enum PathType {
  VECTOR = 0,
  BEZIER_CURVE = 1,
}

export interface IPath {
  type: PathType;
  add(deltaX: number, deltaY: number): IPath;
}

export const equalPath = (a: Path[], b: Path[]): boolean => {
  if (a.length === b.length) {
    return a.some((v, i) => v === b[i]);
  }

  return false;
};

export const transformPath = (
  path: Path[],
  deltaX: number,
  deltaY: number,
  deltaW: number,
  deltaH: number
): Path[] => {
  return path.map((point, index) => {
    // eslint-disable-next-line default-case
    switch (index) {
      case 0:
        return point.add(deltaX, deltaY);
      case 1:
        return point.add(deltaX + deltaW, deltaY);
      case 2:
        return point.add(deltaX + deltaW, deltaY + deltaH);
      case 3:
        return point.add(deltaX, deltaY + deltaH);
    }
    return point;
  });
};

export type Path = Vector | BezierCurve;
