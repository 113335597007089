import type { CollectionViewFormProperties } from '@next-space/fe-api-idl';
import { cloneDeep } from 'lodash-es';
import { useCallback } from 'react';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { getState } from 'src/redux/store';
import type { PatchState, PickArray } from 'src/utils/type-utils';

/** 修改收集表每个题目的信息 */
export const useUpdateFormProperties = (viewId: string, propertyId: string) => {
  /** 修改每道题的属性 */
  const handleChangeFormProperties: PatchState<PickArray<CollectionViewFormProperties>> = (
    key,
    value
  ) => {
    const view = getState().collectionViews?.[viewId];
    if (!view) return;

    const newFormProperties = cloneDeep(view.format.formProperties);
    const formPropertiesIndex = newFormProperties?.findIndex((i) => i.property === propertyId);
    if (formPropertiesIndex !== undefined && formPropertiesIndex !== -1 && newFormProperties) {
      const formProperties = newFormProperties[formPropertiesIndex];
      if (formProperties) {
        const newParams = { ...formProperties, [key]: value };
        newFormProperties[formPropertiesIndex] = newParams;
      }
    }

    updateViewFormat(viewId, { formProperties: newFormProperties });
  };

  return useCallback(handleChangeFormProperties, [propertyId, viewId]);
};
