import type { UserDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';

import type { SwitchViewTypeFun } from '../types';
import { ViewType } from '../types';

/**检测并跳转到完善信息页 */
export const useCheckComplete = (switchViewType: SwitchViewTypeFun) => {
  const checkComplete = useCallback(
    (user: UserDTO) => {
      if (!user.nickname || user.occupation === undefined || user.occupation === 0) {
        switchViewType({
          type: ViewType.COMPLETE,
          props: {
            userId: user.uuid,
          },
        });
        return;
      }

      return true;
    },
    [switchViewType]
  );
  return checkComplete;
};
