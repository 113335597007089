import type { PageActivityEditDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { InlinePage } from 'src/editor/editor/inline/inline-page';

import { PageSubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const PageLockedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  return (
    <FeedTemplate
      edits={[edit]}
      action="锁定了"
      subject={<PageSubject pageId={edit.navigableBlockId} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const PageUnlockedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  return (
    <FeedTemplate
      edits={[edit]}
      action="解锁了"
      subject={<PageSubject pageId={edit.navigableBlockId} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const PageCreatedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  return (
    <FeedTemplate
      edits={[edit]}
      action="创建了"
      subject={<PageSubject pageId={edit.navigableBlockId} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const PageDeletedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  return (
    <FeedTemplate
      edits={[edit]}
      action="删除了"
      subject={<InlinePage uuid={edit.navigableBlockId} interactable />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const PageRestoredFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  return (
    <FeedTemplate
      edits={[edit]}
      action="恢复了"
      subject={<PageSubject pageId={edit.navigableBlockId} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};
