import { judgeSharePage } from 'src/utils/getPageId';
import { useSpaceSecurityState } from './use-space-security-state';
import { $publicSpaces } from 'src/services/public-space';
import { $spacesState } from 'src/services/spaces/spaces';

/**
 * 只有团队版开启了并且在分享页才有效
 */
export const useOpenImageWatermark = (spaceId?: string) => {
  const openImageWatermark =
    useSpaceSecurityState('openImageWatermark', spaceId) && judgeSharePage();
  const imageWatermarkText = useSpaceSecurityState('imageWatermarkText', spaceId);
  return { openImageWatermark, imageWatermarkText };
};
export const getImageWatermark = (spaceId?: string) => {
  if (!spaceId) {
    return { openImageWatermark: false, imageWatermarkText: '' };
  }
  const space = $spacesState.getState()[spaceId];
  if (space) {
    return {
      openImageWatermark: space?.setting?.openImageWatermark,
      imageWatermarkText: space?.setting?.imageWatermarkText,
    };
  }
  const pubSpace = $publicSpaces.getState()[spaceId];
  if (pubSpace) {
    return {
      openImageWatermark: pubSpace.openImageWatermark,
      imageWatermarkText: pubSpace.imageWatermarkText,
    };
  }
  return { openImageWatermark: false, imageWatermarkText: '' };
};
