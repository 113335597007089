import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import { IconTrigger } from 'src/components/icon-trigger';
import type { ReadonlyProp } from 'src/redux/types';

export const Callout: FC<
  {
    id: string;
    onChange?(emoji: string): void;
    style?: CSSProperties;
    className?: string;
    iconClassName?: string;
    childrenClassName?: string;
  } & ReadonlyProp
> = (props) => {
  return (
    <div className={cx('flex items-start py-3 px-1.5', props.className)} style={props.style}>
      <IconTrigger
        blockId={props.id}
        className={cx('mt-1', props.iconClassName)}
        hideRemoveBtn={true}
        trigger={!props.readonly}
        iconSize={22}
        defaultEmoji={'💡'}
        onSelectEmoji={(emoji) => {
          if (props.onChange) props.onChange(emoji);
        }}
      />
      <div className={cx('w-[calc(100%-36px)] ml-2', props.childrenClassName)}>
        {props.children}
      </div>
    </div>
  );
};
