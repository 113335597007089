import { useCallback } from 'react';
import { $appUiStateCache } from 'src/services/app';
import { judgeSubscribePage, useGetPageId } from 'src/utils/getPageId';
import { getRightPageId, useIsInRight, useMasterHistory } from 'src/utils/right-utils';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { OpenPageMode } from 'src/views/main/setting-modal/general-setting/const';
import { getCurrentUser } from '../user';
import { useOpenInRight } from './use-open-in-right';
import { useNormalizePathFun } from './use-normalize-path';

export const useOpenPage = () => {
  const history = useMasterHistory();
  const openInRight = useOpenInRight();
  const pageId = useGetPageId();
  const pageScene = usePageScene();
  const isInRight = useIsInRight();
  const normalizePath = useNormalizePathFun();

  const openPage = (
    uuid: string,
    options: {
      illegal?: boolean;
      onOpenInRight?: () => void;
      openWay?: 'openInRight' | 'openFullscreen';
      forceOpenInRight?: boolean;
      forceOpenNewTab?: boolean; // ctrl+click need open new tab
      hash?: `#${string}`;
    } = {}
  ) => {
    const rightPageId = getRightPageId();
    const { setting = {} } = getCurrentUser();
    const {
      hash = '',
      // illegal,
      onOpenInRight,
      forceOpenInRight,
      forceOpenNewTab,
      openWay,
    } = options;

    // 打开下面这行会导致入口进不去，连申诉机会都没有
    // if (illegal) return;

    const path = normalizePath(uuid);
    if (forceOpenNewTab) {
      window.open(`${path}${hash}`);
      // if (judgeSharePage()) {
      //   const path = normalizePath(uuid);
      //   window.open(`${path}${hash}`);
      // } else {
      //   window.open(`${getLocationOrigin()}/${uuid}${hash}`);
      // }
      return;
    }

    if (pageScene === PageScene.FORM_ENG_PAGE) {
      window.open(`${path}${hash}`);
      return;
    }
    // 分享页支持右侧打开
    // if (judgeSharePage()) {
    //   history.push(`${path}${hash}`);
    //   return;
    // }
    if (judgeSubscribePage()) {
      history.push(`${path}${hash}`);
      return;
    }

    if ($appUiStateCache.$isMobileSize) {
      history.push(`${path}${hash}`);
      return;
    }

    // 如果是在右侧页面里打开，就只更改右侧页面的路由
    if (isInRight) {
      openInRight(uuid, { hash });
      return;
    }

    const openMasterPage = () => {
      if (rightPageId) {
        history.push({
          ...history.location,
          pathname: `/${uuid}`,
          search: `?right-page-id=${rightPageId}`,
        });
        return;
      }

      history.push(`${path}${hash}`);
    };

    if (pageId === uuid || uuid === rightPageId) {
      if (pageId === uuid) {
        openMasterPage();
      } else {
        openInRight(uuid, { hash });
      }
      return;
    }

    if (forceOpenInRight || openWay === 'openInRight') {
      openInRight(uuid, { hash });
      onOpenInRight?.();
      return;
    }
    if (openWay === 'openFullscreen') {
      openMasterPage();
      return;
    }
    if (setting.openPageMode === OpenPageMode.RIGHT_PAGE) {
      openInRight(uuid, { hash });
      onOpenInRight?.();
      return;
    }

    openMasterPage();
  };

  return useCallback(openPage, [history, isInRight, normalizePath, openInRight, pageId, pageScene]);
};
