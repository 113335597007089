import { useEffect } from 'react';
import { request } from 'src/common/request';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { useGetSyncService } from 'src/services/sync';

export const useSyncCollectionView = (viewId: string | undefined) => {
  const sync = useGetSyncService();
  useEffect(() => {
    if (!viewId) return;
    return sync.subscribeCollectionView(viewId, async () => {
      const view = await request.editor.getCollectionView(viewId);
      CollectionViewManager.update(viewId, view, true);
    });
  }, [viewId, sync]);
};
