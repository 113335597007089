import { getFormatImageUrl } from '@flowus/common';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { request } from 'src/common/request';
import { PreviewElement } from 'src/components/file-preview/renderElements';
import { PagePreview } from 'src/components/page-preview';
import { segmentsToText } from 'src/editor/utils/editor';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useResource } from 'src/hooks/public/use-resource';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { getFileNameInfo } from 'src/utils/file';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';

interface PagePreviewWrapperProps {
  uuid: string;
  ownerBlockId?: string;
  onCloseOutsideModel?: () => void;
}
/** 专门给文件用的，因为文件的预览不是页面预览而是内容预览，这里有特殊处理 */
export const PagePreviewWrapper: FC<PagePreviewWrapperProps> = (props) => {
  const { uuid, ownerBlockId, onCloseOutsideModel } = props;
  const title = useObservableBlock(uuid, (block) => segmentsToText(block?.data.segments));
  const { extName } = getFileNameInfo(title);
  const { url } = useResource(uuid);
  const previewUrl = useMemo(() => {
    return getFormatImageUrl(url, extName);
  }, [extName, url]);

  // 引用文件需要通过接口找到块所属页面
  const pageId = useObservableStore(
    (state) => {
      return getOwnerPage(uuid, state);
    },
    [uuid]
  );

  useEffect(() => {
    if (pageId) return;
    const spaceId = cache.blocks[uuid]?.spaceId;
    if (!spaceId) return;
    void request.editor
      .getAncestorBlocks({
        spaceId,
        blockIds: [uuid],
      })
      .then((res) => {
        dispatch(blocksActions.update({ blocks: res.blocks as Record<string, NextBlock> }));
      });
  }, [pageId, uuid]);

  if (!pageId && ownerBlockId) return null;
  if (!ownerBlockId) {
    // 非引用文件，直接预览
    return (
      <PagePreview
        hideOpenRightBtn={true}
        pageId={uuid}
        onCloseOutsideModel={onCloseOutsideModel}
        className="min-h-[300px]"
      >
        <div className="overflow-y-auto h-full">
          <PageSceneContext.Provider value={PageScene.PAGE_LITE_PREVIEW}>
            <PreviewElement
              uuid={uuid}
              downloadUrl={previewUrl}
              useSimpleImg
              embed
              style={{ width: '100%', height: 'inherit' }}
            />
          </PageSceneContext.Provider>
        </div>
      </PagePreview>
    );
  }

  return (
    <PagePreview
      pageId={pageId ?? ''}
      isLink={true}
      blockId={uuid}
      onCloseOutsideModel={onCloseOutsideModel}
    />
  );
};
