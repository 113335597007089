import colors from '@flowus/tailwind-config/colors.json';
import twFont from '@flowus/tailwind-config/tw-font.json';
import type _twTheme from '@flowus/tailwind-config/tw-theme.json';
import type { Argument } from 'classnames';
import classnames from 'classnames';
import { extendTailwindMerge } from 'tailwind-merge';

// @ts-ignore twTheme
const twTheme: Record<keyof typeof _twTheme, string[]> = {};

// @ts-ignore twTheme
Object.keys(twTheme).forEach((item: keyof typeof _twTheme) => {
  const value = Object.keys(twTheme[item]);
  twTheme[item] = value;
});

const customTwMerge = extendTailwindMerge({
  theme: {
    ...twTheme,
    colors: Object.keys(colors),
  },
  classGroups: {
    text: Object.keys(twFont).map((i) => i.replace('.', '')),
    fontFace: ['text-mono'],
  },
});

/** className作为component props对外提供覆盖能力的时候用得上 */
export const cx = (...args: Argument[]) => customTwMerge(classnames(args));
