import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';

import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';
import {
  addProperty,
  CollectionViewPropertyNames,
  getNewPropertyVisibleObj,
  getViewFormatPrefix,
  makePropertyName,
} from './use-create-property';

export const useCopyProperty = () => {
  const transaction = useTransaction();
  const { getState } = useStore();

  return useCallback(
    (collectionId: string, viewId: string, propertyId: string, isTimelineTable = false) => {
      const { blocks, collectionViews } = getState();

      const collection = blocks[collectionId];
      if (!collection) return;

      const propertyInfo = collection.data.schema?.[propertyId];
      if (!propertyInfo) return;

      const newPropertyId = uuidV4();
      const { visibleProperty, inVisibleProperty } = getNewPropertyVisibleObj(newPropertyId);

      let viewFormat: NextCollectionView['format'] = {};
      if (viewId) {
        const view = collectionViews[viewId];
        if (!view) return;
        const isForm = view.type === CollectionViewType.FORM;
        const { properties = [], timelineTableProperties = [] } = getViewFormat(view);
        let oldProperties = properties;
        if (isTimelineTable) {
          oldProperties = timelineTableProperties;
        }

        if (isForm && view.format.formProperties) {
          oldProperties = view.format.formProperties;
        }

        const index = oldProperties.findIndex((property) => property.property === propertyId);
        const newProperties = [...oldProperties];
        const copyProperty = {
          property: newPropertyId,
          visible: oldProperties[index]?.visible ?? true,
        };

        if (index > -1) {
          newProperties.splice(index + 1, 0, copyProperty);
        } else {
          newProperties.push(copyProperty);
        }

        const currentPropertiesName = isTimelineTable
          ? 'timelineTableProperties'
          : `${getViewFormatPrefix(view.type)}Properties`;
        viewFormat = { ...view.format };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        viewFormat[currentPropertiesName] = newProperties;

        CollectionViewPropertyNames.forEach((name) => {
          if (name === currentPropertiesName) return;

          const properties = viewFormat[name];
          if (properties) {
            if (name === 'tableProperties') {
              viewFormat[name] = [...properties, visibleProperty];
            } else {
              viewFormat[name] = [...properties, inVisibleProperty];
            }
          }
        });
      }

      let newCollectionPageProperties = [...(collection.data.collectionPageProperties ?? [])];
      if (newCollectionPageProperties.length === 0) {
        newCollectionPageProperties = Object.keys(collection.data.schema ?? {}).map((property) => ({
          property,
          visible: true,
        }));
      }
      const propertyIndex = newCollectionPageProperties.findIndex(
        ({ property }) => property === propertyId
      );
      newCollectionPageProperties.splice(propertyIndex + 1, 0, visibleProperty);

      transaction(() => {
        updateBlock(collection.uuid, {
          data: {
            collectionPageProperties: newCollectionPageProperties,
            schema: {
              ...collection.data.schema,
              [newPropertyId]: {
                ...propertyInfo,
                name: makePropertyName(collection.data.schema, '副本 '.concat(propertyInfo.name)),
              },
            },
          },
        });

        if (viewId) {
          CollectionViewManager.update(viewId, {
            format: viewFormat,
          });
        }

        addProperty(collection.uuid, [newPropertyId], viewId);
      });

      return newPropertyId;
    },
    [getState, transaction]
  );
};
