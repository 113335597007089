import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { sample } from 'lodash-es';
import { useCallback } from 'react';
import { COLORS } from 'src/bitable/const';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { v4 as uuidV4 } from 'uuid';

export const useAddGroup = () => {
  const transaction = useTransaction();

  const addGroup = (viewId: string, newGroupName: string, isSubGroup?: boolean) => {
    transaction(() => {
      const {
        groups = [],
        subGroups = [],
        subGroupBy,
        groupBy,
        view,
        collection,
      } = getViewFormat(viewId) ?? {};
      if (!view || !collection) return;

      const propertyId = isSubGroup ? subGroupBy?.property : groupBy?.property;
      const oldGroups = isSubGroup ? subGroups : groups;
      const propertySchema = collection.data.schema?.[propertyId ?? ''];
      if (!propertySchema || !propertyId) return;

      const newGroups = oldGroups.concat({
        property: propertyId,
        propertyType: propertySchema.type,
        value: newGroupName,
        visible: true,
      });

      const isBoardColumn = view.type === CollectionViewType.BOARD && !isSubGroup;
      CollectionViewManager.update(viewId, {
        format: {
          [isBoardColumn ? 'boardGroups' : 'collectionGroups']: newGroups,
        },
      });

      if (
        propertySchema.type === CollectionSchemaType.SELECT ||
        propertySchema.type === CollectionSchemaType.MULTI_SELECT
      ) {
        const oldOptions = propertySchema.options ?? [];
        const usedColor = new Set(oldOptions.map((item) => item.color));
        let pool = COLORS.map((item) => item.key);

        if (usedColor.size !== pool.length) {
          pool = pool.filter((colorKey) => !usedColor.has(colorKey));
        }

        const newOption = {
          id: uuidV4(),
          color: sample(pool) ?? COLORS[0].key,
          value: newGroupName,
        };

        const newOptions = oldOptions.concat(newOption);
        updateBlock(collection.uuid, {
          data: {
            schema: {
              ...collection.data.schema,
              [propertyId]: {
                ...propertySchema,
                options: newOptions,
              },
            },
          },
        });
      }
    });
  };

  return useCallback(addGroup, [transaction]);
};
