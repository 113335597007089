import { useEffect } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';

export const useDoneLocalStatus = (props: { uuid: string; callback?: () => void }) => {
  const { uuid, callback } = props;

  useEffect(() => {
    if (cache.blocks[uuid]?.local) {
      callback && callback();
      // 第一次展示图片/文件/video/audio需要依赖local变量弹出对话框,用完就设置回false
      updateBlock(uuid, { local: false }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);
};
