import { createContext } from 'react';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';

/** 页面是否锁住了，这个锁仅针对当前页面，跟权限无任何瓜葛 */
export const useBlockLocked = (uuid?: string) => {
  return useObservableBlock(uuid, (block) => {
    if (!uuid) return false;
    return block?.data.format?.locked;
  });
};
/** 由于locked跟share页面类似但略有不同（访问locked页面会进历史访问记录,还有一些其他的操作），因此有些逻辑要额外判断 */
export const LockedContext = createContext(false);
/** 只读 */
export const ReadonlyContext = createContext(false);
// 思维导图专用
export const EmbedContext = createContext(false);
