import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { getMindMapEngine } from 'src/mind-map/utils/mind-engine-utils';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { getDynamicPageId } from 'src/utils/getPageId';
import { ExportPngSetting } from './export-png-setting';
import { ExportPreview } from './export-preview';
import { captureSmallSnapshot, updateFlowUsWatermark } from './snapshot-utls';
import type { ExportOption } from './types';
import { WATERMARK } from './types';
import { useSpaceSecurityState } from 'src/hooks/space/use-space-security-state';
import { getCurrentUser } from 'src/hooks/user';
import { useSelectCount } from 'src/mind-map/component/collapse-count';

interface Props {
  onCloseModal?: () => void;
  id: string;
}

/**
 * TODO:如果导出ui都用这个的话，需要判断导出页面类型，不同类型显示不同参数
 * 目前仅思维导图在用
 *
 * 为了减少截图次数，第一次会先截一个背景透明，水印白色的图，显示在左侧（为了勾选透明背景后可以显示出来，所以必须这么截）。
 */
export const ExportPanel: FC<Props> = (props) => {
  const [snapshot, setSnapshot] = useState<string>();
  const isRight = useMemo(() => getDynamicPageId('right') === props.id, [props.id]);
  const nodeCount = useSelectCount(props.id, props.id);

  const [canvas, setCanvas] = useState<HTMLCanvasElement>();
  const [exportOption, setExportOption] = useState<ExportOption>(() => {
    return {
      type: 'png',
      transparent: false,
      watermark: WATERMARK,
      isDarkMode: getIsDarkMode(),
      alreadySnapShot: false,
    };
  });
  const openWatermark = useSpaceSecurityState('openWatermark');

  useEffect(() => {
    // 截图需要100%缩放比例
    const engine = getMindMapEngine(props.id);
    if (!engine) return;
    const { scale } = engine.getSnapShot();
    // 对于大于300个节点的思维导图，需要缩小到80%截图才能避免截图失败(canvas的问题)
    const newScale = nodeCount > 300 ? 80 : 100;
    engine?.zoom(newScale);
    return () => {
      engine.zoom(scale);
    };
  }, [nodeCount, props.id]);

  useEffect(() => {
    if (exportOption.type === 'png' && exportOption.alreadySnapShot) {
      return;
    }
    const container = querySelectorFromMainContent('.next-space-page,.mind-page', isRight);
    if (container instanceof HTMLElement) {
      const engine = getMindMapEngine(props.id);
      if (!engine) return;
      setTimeout(() => {
        void captureSmallSnapshot(container, {
          ...exportOption,
          transparent: true,
        }).then((canvas) => {
          setCanvas(canvas);
          setSnapshot(canvas?.toDataURL('image/png'));
          setExportOption((v) => {
            return { ...v, alreadySnapShot: true };
          });
        });
      }, 1000);
    }
  }, [exportOption, isRight, props.id]);

  useEffect(() => {
    const user = getCurrentUser();
    const tag = openWatermark ? user.nickname + user.phone.slice(-4) : '';
    switch (exportOption.type) {
      case 'png': {
        if (!exportOption.alreadySnapShot || !canvas) return;
        const container = querySelectorFromMainContent('.next-space-page,.mind-page', isRight);
        const engine = getMindMapEngine(props.id);
        if (!engine) return;
        if (container instanceof HTMLElement) {
          updateFlowUsWatermark(canvas, container, {
            transparent: exportOption.transparent,
            watermark: exportOption.watermark,
            isDarkMode: getIsDarkMode(),
            repeatWatermark: tag,
          });
          setSnapshot(canvas?.toDataURL('image/png'));
        }
        break;
      }
      default:
    }
  }, [
    canvas,
    exportOption.alreadySnapShot,
    exportOption.transparent,
    exportOption.type,
    exportOption.watermark,
    isRight,
    openWatermark,
    props.id,
  ]);

  return (
    <div className="w-[600px] py-7 pb-5 px-7">
      <div className="text-h2">导出页面</div>
      <div className="flex mt-7">
        <ExportPreview
          snapshot={snapshot}
          transparent={exportOption.transparent}
          isWidthMax={Boolean(canvas && canvas.width > canvas.height)}
        />
        <ExportPngSetting
          exportOption={exportOption}
          onExportOptionChange={(opt) => {
            setExportOption((v) => {
              return { ...v, ...opt };
            });
          }}
          className="ml-7 mt-2 w-full"
          onCancel={() => {
            props.onCloseModal?.();
          }}
          onExport={() => {
            if (!canvas || !snapshot) return;
            const b = cache.blocks[props.id];
            if (!b) return;
            // 下面代码把预览图改成导出需要的png
            props.onCloseModal?.();
            bizTracker.event('export_page', {
              export_type: 'PNG',
              page_type: b.type,
            });
            const container = querySelectorFromMainContent(
              '.next-space-page,.mind-page',
              isRight
            ) as HTMLElement;
            if (!container) return;
            // 如果是透明背景可直接下载，如果不是的话需要先把背景画上
            const link = document.createElement('a');
            const title = segmentsToText(b?.data.segments) || getUntitledName(b?.type);
            const fileName = `${title}.png`;
            // 去掉预览图的水印
            exportOption.watermark &&
              updateFlowUsWatermark(canvas, container, {
                transparent: exportOption.transparent,
                watermark: '',
                isDarkMode: getIsDarkMode(),
                repeatWatermark: '',
              });

            const bgCanvas = document.createElement('canvas');
            // 增大实际图片周围padding
            const padding = 240;
            bgCanvas.width = canvas.width + padding;
            bgCanvas.height = canvas.height + padding;
            const ctx = bgCanvas.getContext('2d');
            if (!ctx) return;
            ctx.save();
            ctx.fillStyle = exportOption.transparent
              ? 'transparent'
              : getIsDarkMode()
              ? 'black'
              : 'white';
            ctx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);
            const image = new Image();
            image.setAttribute('crossOrigin', 'anonymous');
            image.onload = () => {
              ctx.drawImage(
                image,
                0,
                0,
                canvas.width,
                canvas.height,
                padding / 2,
                padding / 2,
                canvas.width,
                canvas.height
              );
              ctx.stroke();
              if (exportOption.watermark) {
                ctx.font = '28px normal';
                ctx.textAlign = 'left';
                ctx.fillStyle = getIsDarkMode() || exportOption.transparent ? 'white' : 'grey';
                const textWidth = ctx.measureText(exportOption.watermark).width;
                ctx.fillText(
                  exportOption.watermark,
                  (bgCanvas.width - textWidth) * 0.5,
                  bgCanvas.height - padding * 0.3
                );
              }
              ctx.restore();

              const url = bgCanvas.toDataURL('image/png');
              link.download = fileName;
              link.href = url;
              link.click();
            };
            image.src = canvas?.toDataURL('image/png');
          }}
        />
      </div>
    </div>
  );
};
