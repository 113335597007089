class GlobalResizeObserver {
  private static instance: GlobalResizeObserver;
  private resizeObserver: ResizeObserver;
  private readonly elements: WeakMap<Element, Set<Function>>;
  private readonly elementsLastEntry: WeakMap<Element, ResizeObserverEntry>;

  private constructor() {
    this.elements = new WeakMap();
    this.elementsLastEntry = new WeakMap();
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { target } = entry;
        const callbacks = this.elements.get(target);
        if (!callbacks) return;
        this.elementsLastEntry.set(target, entry);
        callbacks.forEach((callback) => callback(entry));
      });
    });
  }

  static getInstance() {
    if (!GlobalResizeObserver.instance) {
      GlobalResizeObserver.instance = new GlobalResizeObserver();
    }
    return GlobalResizeObserver.instance;
  }

  observe(element: Element, callback: (entry: ResizeObserverEntry) => void) {
    if (!this.elements.has(element)) {
      this.elements.set(element, new Set([callback]));
      this.resizeObserver.observe(element);
    } else {
      const callbacks = this.elements.get(element);
      callbacks?.add(callback);
      const lastEntry = this.elementsLastEntry.get(element);
      if (lastEntry) {
        callback(lastEntry);
      }
    }
    return () => this.unobserve(element, callback);
  }

  unobserve(element: Element, callback: Function) {
    if (!this.elements.has(element)) return;
    const callbacks = this.elements.get(element);
    callbacks?.delete(callback);
    if (callbacks?.size === 0) {
      this.elements.delete(element);
      this.elementsLastEntry.delete(element);
      this.resizeObserver.unobserve(element);
    }
  }
}

export const globalResizeObserver = GlobalResizeObserver.getInstance();
