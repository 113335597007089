import type { Immutable } from 'immer';
import type { MindMapEngine } from '../mind-map-engine';
import type { CanvasStyle, Line, MindNode, MindNodeDragInfo, Rect, Where } from '../types';
import { getMindMapNextNode, getMindMapPreNode, intersectRect, walk } from '../util';
import type { LayoutProviderOption } from './base-layout-provider';
import { BaseLayoutProvider } from './base-layout-provider';
import { CANVAS_PADDING } from './canvas-graph';

/**标题在左边居中位置的布局类 */
/**
 * 概念：叶子节点是指没有子节点的节点（收缩状态也算叶子节点）
 * 核心规则：每个节点都是其子节点占据的区域高度/2
 *
 * 计算步骤：
 * 0.先计算节点的区域宽高
 * 1.定位中心点的left,top
 * 2.遍历节点，根据父节点坐标，算出自己的坐标
 * 3.调整坐标
 */
export class LogicDiagramLeftLayoutProvider extends BaseLayoutProvider {
  constructor(engine: MindMapEngine, opt: LayoutProviderOption) {
    super(engine, opt);
  }

  layout = (nodeId: string) => {
    try {
      this.computeNodeRegionValue(nodeId);
      this.updateViewPort();
      this.computedBaseValue();
      this.computedTopValue();
      this.adjustTopValue();
      this.fixRegionHeight();
      this.computeOffset();
      this.computeFixWidth();
      this.computeNodesByLevelOrder();
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  };

  computedBaseValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id, parentId, isRoot) => {
        const node = state.mindNodes[id];
        const parentNode = state.mindNodes[parentId];
        if (!node) return;
        if (isRoot) {
          node.left = 0;
          node.top = -node.height * 0.5 + node.regionHeight * 0.5;
        } else if (parentNode) {
          node.left = parentNode.left + parentNode.width + this.opt.horizontalMargin;
        }
        if (!node.expanded) {
          return true;
        }
      },
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return;
        if (!node.expanded) {
          node.childrenHeight = 0;
          return true;
        }
        let validChildrenCount = 0;

        //计算children占据的区域高度
        node.childrenHeight =
          node.childrenIds.reduce((h, cId) => {
            const c = state.mindNodes[cId];
            if (!c) return h;
            validChildrenCount++;
            return h + c.height;
          }, 0) +
          (validChildrenCount + 1) * this.opt.verticalMargin;
      },
      true,
      0
    );
  };
  computedTopValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return false;
        if (!node.expanded) return true;
        if (node.childrenIds.length === 0) return;
        const top = node.top + node.height / 2 - node.childrenHeight / 2;
        let totalTop = top + this.opt.verticalMargin;
        node.childrenIds.forEach((cId) => {
          const childNode = state.mindNodes[cId];
          if (childNode) {
            childNode.top = totalTop;
            totalTop += childNode.height + this.opt.verticalMargin;
          }
        });
      },
      undefined,
      true,
      0
    );
  };
  adjustTopValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (nodeId) => {
        const node = state.mindNodes[nodeId];
        if (!node) return false;
        if (!node.expanded) return true;
        const difference = node.childrenHeight - this.opt.verticalMargin * 2 - node.height;
        if (difference > 0) {
          this.updateBrothers(nodeId, difference / 2);
        }
      },
      undefined,
      true
    );
  };
  updateBrothers = (nodeId: string, addHeight: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) return;
    const parentNode = this.engine.getMindNode(node.parentId);
    if (!parentNode) return;
    const index = parentNode.childrenIds.findIndex((cid) => cid === nodeId);
    parentNode.childrenIds.forEach((cId, cIndex) => {
      if (cId === nodeId) return;
      const childNode = state.mindNodes[cId];
      if (!childNode) return;
      let offset = 0;
      // 上面的节点往上移
      if (cIndex < index) {
        offset = -addHeight;
      } else if (cIndex > index) {
        // 下面的节点往下移
        offset = addHeight;
      }
      this.updateNodeRecursive(cId, 'top', offset);
    });
    // 更新父节点的位置
    this.updateBrothers(node.parentId, addHeight);
  };
  protected getMindMapCenterPos = (): [number, number] | undefined => {
    if (!this.scroller) {
      return;
    }
    const root = this.engine.getRootMindNode();
    const { scale } = this.engine.getState();
    const scaleFactor = scale * 0.01;
    const rect = this.scroller.getBoundingClientRect();
    let rootCenterX = root.x + (root.width * scaleFactor) / 2;
    const rootCenterY = root.y + (root.height * scaleFactor) / 2;
    if (root.regionWidth * scaleFactor < rect.width) {
      rootCenterX = root.x + (root.regionWidth * scaleFactor) / 2;
    }
    return [rootCenterX, rootCenterY];
  };

  updateScrollerPos = (_: number, oldRegionHeight: number) => {
    const rootNode = this.engine.getRootMindNode();
    const scrollY = (rootNode.regionHeight - oldRegionHeight) / 2;
    if (scrollY !== 0) {
      this.scroller?.scrollBy(0, scrollY);
    }
  };

  getConnectToWhere = (dragInfo: MindNodeDragInfo) => {
    const mindMapState = this.engine.getState();
    const scaleFactor = mindMapState.scale * 0.01;
    const mindNode = mindMapState.mindNodes[dragInfo.id];
    if (!mindNode) return;
    const rootNode = mindMapState.mindNodes[mindMapState.rootId];
    if (!rootNode) return;

    const dragInfoX = dragInfo.left;
    const dragInfoY = dragInfo.top;
    //超出左侧边界
    if (dragInfoX < rootNode.x + rootNode.width * scaleFactor) {
      return;
    }
    //超出右侧边界
    if (dragInfoX > rootNode.x + (rootNode.regionWidth + CANVAS_PADDING * 0.5) * scaleFactor) {
      return;
    }
    const rootTop =
      rootNode.y + (rootNode.height * 0.5 - rootNode.regionHeight * 0.5) * scaleFactor;
    //超出上边界
    if (dragInfoY < rootTop - CANVAS_PADDING * 0.5 * scaleFactor) {
      return;
    }
    //超出下边界
    if (dragInfoY > rootTop + (rootNode.regionHeight + CANVAS_PADDING * 0.5) * scaleFactor) {
      return;
    }
    let markNode: Immutable<MindNode> | undefined = undefined;
    const entries = Object.entries(mindMapState.mindNodes);
    const dragNode = mindMapState.mindNodes[dragInfo.id];
    if (!dragNode) return;
    let distance = Number.MAX_VALUE;
    for (const [_, node] of entries) {
      //node区域高度
      const nodeRegionHeightRect: Rect = {
        x: node.x,
        y: node.y + node.height * 0.5 * scaleFactor - node.regionHeight * 0.5 * scaleFactor,
        width: node.width * scaleFactor,
        height: node.regionHeight * scaleFactor,
      };
      if (
        intersectRect(nodeRegionHeightRect, {
          x: dragInfoX,
          y: dragInfoY + (dragNode.height / 2) * scaleFactor,
          width: 1,
          height: 1,
        })
      ) {
        if (node.id === dragInfo.id) return { parentId: node.parentId } as Where;
        if (!node.parentId) return;
        const parentNode = mindMapState.mindNodes[node.parentId];
        if (!parentNode) return;
        return this.findPosition(dragInfo, parentNode);
      }
      //记录一下符合条件的
      if (dragInfoX > node.x + node.width * scaleFactor) {
        const centerDragInfoY = dragInfoY + (dragNode.height / 2) * scaleFactor;
        const nodeEndX = node.x + node.width * scaleFactor;
        const nodeCenterY = node.y + (node.height / 2) * scaleFactor;
        const newDistance =
          Math.pow(centerDragInfoY - nodeCenterY, 2) + Math.pow(dragInfoX - nodeEndX, 2);
        if (newDistance < distance) {
          distance = newDistance;
          markNode = node;
        }
      }
    }
    if (!markNode) return;
    if (markNode.id === dragInfo.id) return;
    if (!markNode.expanded || markNode.childrenIds.length === 0) {
      return { parentId: markNode.id, first: true } as Where;
    }
    return this.findPosition(dragInfo, markNode);
  };
  protected findPosition = (dragInfo: MindNodeDragInfo, parentNode: Immutable<MindNode>) => {
    const mindMapState = this.engine.getState();
    const dragInfoY = dragInfo.top;
    //找具体位置,一个一个往下找
    let after: string | undefined;
    for (let i = 0; i < parentNode.childrenIds.length; i++) {
      const cId = parentNode.childrenIds[i];
      if (!cId) return;
      const childNode = mindMapState.mindNodes[cId];
      if (!childNode) return;
      if (i === 0 && dragInfoY < childNode.y) {
        return { parentId: parentNode.id, first: true } as Where;
      }
      if (dragInfoY > childNode.y) {
        after = childNode.id;
      }
    }
    if (after && after !== dragInfo.id) {
      return { parentId: parentNode.id, after } as Where;
    }
  };

  protected computeNodeRegionValue = (nodeId: string) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) {
      //PAGE
      return;
    }

    const { height, width } = node;
    let childrenHeight = 0;
    let maxRegionWidth = 0;

    if (node.expanded) {
      node.childrenIds.forEach((cId) => {
        this.computeNodeRegionValue(cId);
        const c = state.mindNodes[cId];
        if (!c) return;
        childrenHeight += c.regionHeight;
        if (c.regionWidth > maxRegionWidth) {
          maxRegionWidth = c.regionWidth;
        }
      });
      if (node.childrenIds.length > 0) {
        childrenHeight += this.opt.verticalMargin * (node.childrenIds.length - 1);
      }
    } else {
      childrenHeight = 0;
    }

    node.regionHeight = Math.max(height, childrenHeight);
    if (node.expanded && node.childrenIds.length > 0) {
      node.regionWidth = width + this.opt.horizontalMargin + maxRegionWidth;
    } else {
      node.regionWidth = width;
    }
  };
  protected getDragNodeRect = (id: string) => {
    const node = this.engine.getMindNode(id ?? '');
    if (!node) return;
    const x = node.left;
    const y = node.top + node.height * 0.5 - node.regionHeight * 0.5;
    return { x, y, width: node.regionWidth, height: node.regionHeight };
  };

  protected getLine = (node: Immutable<MindNode>, childNode: Immutable<MindNode>) => {
    const color = this.getLineColor(childNode.id);
    return {
      startX: (node.left + node.width) / this.scale,
      startY: (node.top + node.height / 2) / this.scale,
      endX: childNode.left / this.scale,
      endY: (childNode.top + childNode.height / 2) / this.scale,
      debug: `${node.id} - ${childNode.id}`,
      isRoot: !node.parentId,
      color,
    };
  };
  protected getDragLine = (
    dragXInCanvas: number,
    dragYInCanvas: number,
    nodeId: string,
    parentId: string
  ) => {
    const node = this.engine.getMindNode(nodeId);
    if (!node) return;
    const parentNode = this.engine.getMindNode(parentId);
    if (!parentNode) return;
    const color = this.getLineColor(nodeId);
    const line: Line = {
      debug: '',
      startX: (parentNode.left + parentNode.width) / this.scale,
      startY: (parentNode.top + parentNode.height / 2) / this.scale,
      endX: dragXInCanvas / this.scale,
      endY: dragYInCanvas + node.height / 2 / this.scale,
      color,
    };
    return line;
  };

  protected drawLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    ctx.strokeStyle = line.color;
    if (style) {
      Object.assign(ctx, style);
    }
    ctx.moveTo(line.startX, line.startY);
    const lineCenterX = line.startX + (line.endX - line.startX) / 2;
    ctx.lineTo(lineCenterX, line.startY);
    //是否往上
    const isTop = line.endY < line.startY;
    const { round } = this;
    if (Math.abs(line.endY - line.startY) > round) {
      ctx.lineTo(lineCenterX, isTop ? line.endY + round : line.endY - round);
      ctx.arcTo(lineCenterX, line.endY, lineCenterX + round, line.endY, round);
      ctx.lineTo(line.endX, line.endY);
    } else {
      //两者中心点不够一个弧度高，因此用直线连上
      ctx.lineTo(line.endX, line.startY);
    }
  };

  getNextNodeIdByArrowKeyboard = (arrow: 'left' | 'right' | 'up' | 'down', currentId: string) => {
    switch (arrow) {
      case 'left': {
        return this.getParentId(currentId);
      }
      case 'right': {
        return this.getMiddleChild(currentId);
      }
      case 'up': {
        return getMindMapPreNode(this.engine, currentId);
      }
      case 'down': {
        return getMindMapNextNode(this.engine, currentId);
      }
      default:
    }
  };
}
