import { getBizTrackerPlatform } from '@flowus/common/get-tracker-platform';
import type { SpaceDTO } from '@next-space/fe-api-idl';
import { mapValues } from 'lodash-es';
import { batch } from 'react-redux';
import { message } from 'src/common/components/message';
import { CURRENT_SPACE_VIEW_ID, USER_ID } from 'src/common/const';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { updatePublicSpaces } from 'src/services/public-space';
import { updateSpaceViews } from 'src/services/spaces/space-views';
import { updateSpaces } from 'src/services/spaces/spaces';
import { $currentUserCache, updateCurrentUser } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { setLocalStorage } from 'src/utils/local-storage';

export const TYPE_USER = 1;
export const TYPE_SPACES = 1 << 1;
export const TYPE_SPACE_VIEWS = 1 << 1;
/**
 * 获取用户相关设置信息，以用户为主，可从用户信息查看到所有空间相关信息，新增了spaceView概念。
 * 前端本地存储只需要userId找用户信息，记录spaceViewId拿到spaceId，最终通过spaceId找到对应的space信息
 * @fetchType 根据需要获取想要的信息并更新，有些数据不需要获取并更新的，就不更新，避免数据老更新导致重新渲染
 *
 * 几个store存储的思路:
 * 1.spaceView以及spaces在store里的数据不会做删除操作(做不做都可以，不做就绝对不会删错).
 * 2.空间列表的展示全靠user.spaceViews来控制，所以上面提到的数据即便没及时同步删除也不会有影响
 * 3.切换空间，最终其实是切换本地存储的spaceViewId
 */
export const fetchUserRoot = async (
  fetchType = TYPE_USER | TYPE_SPACES | TYPE_SPACE_VIEWS,
  keepCurrentSpaceViewId?: boolean,
  onlyUpdateUser?: boolean
) => {
  const userId = localStorage.getItem(USER_ID);
  if (!userId) return;
  const resp = await request.infra.getUserRoot.raw(userId);
  if (resp.code !== 200) {
    if (onlyUpdateUser) {
      message.error(resp.msg);
    }
    // @ts-ignore 没权限
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (resp.code === 1403) {
      // 可能本地数据有问题，本地userId跟token不对导致的，需要删掉本地userId
      localStorage.removeItem(USER_ID);
    }
    return;
  }
  const res = resp.data;
  const { users, spaces, spaceViews } = res;
  let publicSpaces: Record<string, SpaceDTO> | undefined = undefined;
  if (spaceViews) {
    const spaceIds = Object.values(spaceViews)
      .filter((spaceView) => !(spaces && spaces[spaceView.spaceId]))
      .map((spaceView) => spaceView.spaceId);

    if (spaceIds.length) {
      const publicData = await request.infra.getSpacesPublicData({ spaceIds });
      publicSpaces = publicData.spaces as Record<string, SpaceDTO>;
      updateSpaces(publicSpaces);
      updatePublicSpaces(publicData.spaces);
    }
  }
  // 假如开2个浏览器窗口ab，a切换了空间，b窗口的store里的currentSpaceViewId是不变的.
  // 当用户点击左上角空间列表的时候，会调用这个方法，但其实并不需要把store的spaceViewId换成localStorage的，一旦换了这个窗口也跟着切换了空间(现象是点击左上角，自动从a空间头像切换到b空间)
  // 如果你看得一头雾水，可以把keepCurrentSpaceViewId设置为false，然后重复上面的操作就知道了
  const currentBrowserSpaceViewId = $currentUserCache.currentSpaceViewId;
  let currentSpaceViewId = keepCurrentSpaceViewId
    ? currentBrowserSpaceViewId
    : localStorage.getItem(CURRENT_SPACE_VIEW_ID);

  const user = users?.[userId];
  if (!user) return;
  const spaceView = spaceViews?.[currentSpaceViewId ?? ''];
  const space = spaces?.[spaceView?.spaceId ?? ''] || publicSpaces?.[spaceView?.spaceId ?? ''];
  const foundSpace = Boolean(space);
  if (!foundSpace && user.spaceViews.length > 0) {
    // 如果都找不到就拿一个能用的空间作为默认空间
    const findExistOneSpaceViewId = () => {
      return user.spaceViews.find((id) => {
        const space = spaces?.[spaceViews?.[id]?.spaceId ?? ''];
        // 如果找不到就再去协作空间找
        const publicSpace = publicSpaces?.[spaceViews?.[id]?.spaceId ?? ''];
        return Boolean(space || publicSpace);
      });
    };
    currentSpaceViewId = findExistOneSpaceViewId() ?? '';
    currentSpaceViewId && setLocalStorage(CURRENT_SPACE_VIEW_ID, currentSpaceViewId);
  }

  batch(() => {
    if (spaces && hasFetchType(fetchType, TYPE_SPACES)) {
      updateSpaces(spaces);

      const blocks = {
        ...mapValues(spaces, (value) => {
          return {
            spaceId: value.uuid,
            type: 'SPACE',
            data: { icon: value.icon },
            permissions: [],
            ...value,
          };
        }),
      } as unknown as Record<string, NextBlock>;
      dispatch(blocksActions.update({ blocks }));
    }
    if (res.spaceViews && hasFetchType(fetchType, TYPE_SPACE_VIEWS)) {
      updateSpaceViews(res.spaceViews);
    }
    if (hasFetchType(fetchType, TYPE_USER)) {
      updateCurrentUser({ currentSpaceViewId: currentSpaceViewId ?? '' });
      bizTracker.addCommonParams({
        promotion_channel: user.promotionChannel,
        ...getBizTrackerPlatform(),
      });
    }
  });
  return res;
};

const hasFetchType = (fetchType: number, flag: number) => {
  return (fetchType & flag) !== 0;
};
