/* eslint-disable no-prototype-builtins */

import { MediaQuery } from '@flowus/common';
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { DirectionScroller } from '../../../../direction-scroller';
import * as icons from '../../svgs';
import { deepMerge, getData, getSanitizedData } from '../../utils';
import { uncompress } from '../../utils/data';
import emojiIndex from '../../utils/emoji-index/emoji-index';
import frequently from '../../utils/frequently';
import { PickerDefaultProps } from '../../utils/shared-default-props';
import { PickerPropTypes } from '../../utils/shared-props';
import store from '../../utils/store';
import Anchors from '../anchors';
import Category from '../category';
import Preview from '../preview';
import Search from '../search';

const I18N = {
  search: '搜索 Emoji',
  searchIcon: '搜索 Icons',
  clear: 'Clear', // Accessible label on "clear" button
  notfound: 'No Emoji Found',
  skintext: 'Choose your default skin tone',
  categories: {
    search: '搜索结果',
    recent: '最近使用',
    people: '表情与人物',
    nature: '动物与自然',
    foods: '食物与饮料',
    activity: '活动',
    places: '旅行与地点',
    objects: '物件',
    symbols: '符号',
    flags: '旗帜',
    custom: '自定义',
  },
  categorieslabel: 'Emoji 分类', // Accessible title for the list of categories
  skintones: {
    1: 'Default Skin Tone',
    2: 'Light Skin Tone',
    3: 'Medium-Light Skin Tone',
    4: 'Medium Skin Tone',
    5: 'Medium-Dark Skin Tone',
    6: 'Dark Skin Tone',
  },
};

const DEFAULT_SHOW_CATEGORY_COUNT = 3;
export default class NimblePicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.CUSTOM = [];

    this.RECENT_CATEGORY = { id: 'recent', name: 'Recent', emojis: null, anchor: false };
    this.SEARCH_CATEGORY = {
      id: 'search',
      name: 'Search',
      emojis: null,
      anchor: false,
    };
    if (props.data.compressed) {
      uncompress(props.data);
    }

    this.data = props.data;
    this.i18n = deepMerge(I18N, props.i18n);
    this.icons = deepMerge(icons, props.icons);
    //优化,逐渐加载emoji
    this.state = { renderCategoryCount: DEFAULT_SHOW_CATEGORY_COUNT };

    this.categories = [];
    let allCategories = [].concat(this.data.categories);
    this.scrollRef = createRef();
    if (props.custom.length > 0) {
      const customCategories = {};
      let customCategoriesCreated = 0;

      props.custom.forEach((emoji) => {
        if (!customCategories[emoji.customCategory]) {
          customCategories[emoji.customCategory] = {
            id: emoji.customCategory ? `custom-${emoji.customCategory}` : 'custom',
            name: emoji.customCategory || 'Custom',
            emojis: [],
            anchor: customCategoriesCreated === 0,
          };

          customCategoriesCreated++;
        }

        const category = customCategories[emoji.customCategory];

        const customEmoji = {
          ...emoji,
          // `<Category />` expects emoji to have an `id`.
          id: emoji.short_names[0],
          custom: true,
        };

        category.emojis.push(customEmoji);
        this.CUSTOM.push(customEmoji);
      });

      allCategories = allCategories.concat(
        Object.keys(customCategories).map((key) => customCategories[key])
      );
    }

    if (props.include !== undefined) {
      allCategories.sort((a, b) => {
        if (props.include.indexOf(a.id) > props.include.indexOf(b.id)) {
          return 1;
        }

        return -1;
      });
    }

    for (let categoryIndex = 0; categoryIndex < allCategories.length; categoryIndex++) {
      const category = allCategories[categoryIndex];
      const isIncluded =
        props.include && props.include.length ? props.include.indexOf(category.id) > -1 : true;
      const isExcluded =
        props.exclude && props.exclude.length ? props.exclude.indexOf(category.id) > -1 : false;
      if (!isIncluded || isExcluded) {
        continue;
      }

      if (props.emojisToShowFilter) {
        const newEmojis = [];

        const { emojis } = category;
        for (let emojiIndex = 0; emojiIndex < emojis.length; emojiIndex++) {
          const emoji = emojis[emojiIndex];
          if (props.emojisToShowFilter(this.data.emojis[emoji] || emoji)) {
            newEmojis.push(emoji);
          }
        }

        if (newEmojis.length) {
          const newCategory = {
            emojis: newEmojis,
            name: category.name,
            id: category.id,
          };

          this.categories.push(newCategory);
        }
      } else {
        this.categories.push(category);
      }
    }

    const includeRecent =
      props.include && props.include.length
        ? props.include.indexOf(this.RECENT_CATEGORY.id) > -1
        : true;
    const excludeRecent =
      props.exclude && props.exclude.length
        ? props.exclude.indexOf(this.RECENT_CATEGORY.id) > -1
        : false;
    //如果有才展示最近使用分类
    const hasFrequently = frequently.get(this.props.perLine).length > 0;
    if (includeRecent && !excludeRecent && hasFrequently) {
      this.categories.unshift(this.RECENT_CATEGORY);
    }

    if (this.categories[0]) {
      this.categories[0].first = true;
    }

    this.categories.unshift(this.SEARCH_CATEGORY);

    this.setAnchorsRef = this.setAnchorsRef.bind(this);
    this.handleAnchorClick = this.handleAnchorClick.bind(this);
    this.setSearchRef = this.setSearchRef.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setScrollRef = this.setScrollRef.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollPaint = this.handleScrollPaint.bind(this);
    this.handleEmojiOver = this.handleEmojiOver.bind(this);
    this.handleEmojiLeave = this.handleEmojiLeave.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.handleEmojiSelect = this.handleEmojiSelect.bind(this);
    this.setPreviewRef = this.setPreviewRef.bind(this);
    this.handleSkinChange = this.handleSkinChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleDarkMatchMediaChange = this.handleDarkMatchMediaChange.bind(this);
    this.getAllCategoryBaseIndex = this.getAllCategoryBaseIndex.bind(this);
    this.emojiProps = {
      native: props.native,
      skin: props.skin,
      size: props.emojiSize,
      set: props.set,
      sheetSize: props.sheetSize,
      sheetColumns: props.sheetColumns,
      sheetRows: props.sheetRows,
      forceSize: props.native,
      tooltip: props.emojiTooltip,
      backgroundImageFn: props.backgroundImageFn,
      useButton: props.useButton,
      fallback: props.fallback,
      customColorKey: props.customColorKey,
      onOver: this.handleEmojiOver,
      onLeave: this.handleEmojiLeave,
      onClick: this.handleEmojiClick(false),
    };
    this.emojiPropsFromRecently = { ...this.emojiProps, onClick: this.handleEmojiClick(true) }; //第一个是常用}
    if (this.props.keyword) {
      this.lastKeyword = this.props.keyword;
      const search = this.props.useCustomIcon ? emojiIndex.customSearch : emojiIndex.search;
      this.handleSearch(
        search(this.props.keyword, {
          emojisToShowFilter: this.props.emojisToShowFilter,
          maxResults: this.props.maxResults || 75, //75 is from Search
          include: this.props.include,
          exclude: this.props.exclude,
          custom: this.CUSTOM,
        })
      );
    }
  }

  componentDidUpdate() {
    if (this.lastKeyword !== this.props.keyword) {
      this.lastKeyword = this.props.keyword;
      if (!this.props.keyword) {
        this.handleSearch(null);
      } else {
        const search = this.props.useCustomIcon ? emojiIndex.customSearch : emojiIndex.search;
        this.handleSearch(
          search(this.props.keyword, {
            emojisToShowFilter: this.props.emojisToShowFilter,
            maxResults: this.props.maxResults || 75, //75 is from Search
            include: this.props.include,
            exclude: this.props.exclude,
            custom: this.CUSTOM,
          })
        );
      }
    }
    this.updateCategoriesSize();
    this.handleScroll();
  }

  componentWillUnmount() {
    this.SEARCH_CATEGORY.emojis = null;

    clearTimeout(this.leaveTimeout);
    if (this.addRenderCategoryCountTimer) {
      clearTimeout(this.addRenderCategoryCountTimer);
    }

    if (this.darkMatchMedia) {
      this.darkMatchMedia.offChange(this.handleDarkMatchMediaChange);
    }
  }

  testStickyPosition() {
    const stickyTestElement = document.createElement('div');

    const prefixes = ['', '-webkit-', '-ms-', '-moz-', '-o-'];

    prefixes.forEach((prefix) => (stickyTestElement.style.position = `${prefix}sticky`));

    this.hasStickyPosition = !!stickyTestElement.style.position.length;
  }

  getPreferredTheme() {
    if (this.props.theme !== 'auto') return this.props.theme;
    if (this.state.theme) return this.state.theme;
    if (typeof matchMedia !== 'function') return PickerDefaultProps.theme;

    if (!this.darkMatchMedia) {
      this.darkMatchMedia = new MediaQuery('(prefers-color-scheme: dark)');
      this.darkMatchMedia.onChange(this.handleDarkMatchMediaChange);
    }

    if (this.darkMatchMedia.media.match(/^not/)) {
      return PickerDefaultProps.theme;
    }
    return this.darkMatchMedia.matches ? 'dark' : 'light';
  }

  handleDarkMatchMediaChange() {
    this.setState({ theme: this.darkMatchMedia.matches ? 'dark' : 'light' });
  }

  handleEmojiOver(emoji) {
    const { preview } = this;
    if (!preview) {
      return;
    }

    // Use Array.prototype.find() when it is more widely supported.
    // eslint-disable-next-line prefer-destructuring
    const emojiData = this.CUSTOM.filter((customEmoji) => customEmoji.id === emoji.id)[0];
    for (const key in emojiData) {
      if (emojiData.hasOwnProperty(key)) {
        emoji[key] = emojiData[key];
      }
    }

    preview.setState({ emoji });
    clearTimeout(this.leaveTimeout);
  }

  handleEmojiLeave() {
    const { preview } = this;
    if (!preview) {
      return;
    }

    this.leaveTimeout = setTimeout(() => {
      preview.setState({ emoji: null });
    }, 16);
  }

  handleEmojiClick(fromRecently) {
    return (emoji, e) => {
      this.props.onClick(emoji, e);
      this.handleEmojiSelect(emoji, e, fromRecently);
    };
  }

  handleEmojiSelect(emoji, e, fromRecently) {
    //是否自己处理记录,如果自己处理的话，内部就不记录下来了
    const handleRecord = this.props.onSelect(emoji, e, fromRecently);
    if (!this.props.recent && !handleRecord) frequently.add(emoji);

    const component = this.categoryRefs['category-1'];
    if (component) {
      const { maxMargin } = component;
      if (this.props.enableFrequentEmojiSort) {
        component.forceUpdate();
      }

      requestAnimationFrame(() => {
        if (!this.scroll) return;
        component.memoizeSize();
        if (maxMargin === component.maxMargin) return;

        this.updateCategoriesSize();
        this.handleScrollPaint();

        if (this.SEARCH_CATEGORY.emojis) {
          component.updateDisplay('none');
        }
      });
    }
  }

  handleScroll() {
    if (!this.waitingForPaint) {
      this.waitingForPaint = true;
      requestAnimationFrame(this.handleScrollPaint);
    }
  }

  handleScrollPaint() {
    this.waitingForPaint = false;

    if (!this.scroll) {
      return;
    }

    let activeCategory = null;

    if (this.SEARCH_CATEGORY.emojis) {
      activeCategory = this.SEARCH_CATEGORY;
    } else {
      const target = this.scroll,
        scrollTop = target?.scrollTop,
        scrollingDown = scrollTop > (this.scrollTop || 0);
      let minTop = 0;
      this.scrollTop = scrollTop;

      for (let i = 0, l = this.categories.length; i < l; i++) {
        const ii = scrollingDown ? this.categories.length - 1 - i : i,
          category = this.categories[ii],
          component = this.categoryRefs[`category-${ii}`];

        if (component) {
          const active = component.handleScroll(scrollTop);

          if (!minTop || component.top < minTop) {
            if (component.top > 0) {
              minTop = component.top;
            }
          }

          if (active && !activeCategory) {
            activeCategory = category;
          }
        }
      }

      if (scrollTop < minTop) {
        // eslint-disable-next-line prefer-destructuring
        activeCategory = this.categories.filter((category) => !(category.anchor === false))[0];
      } else if (scrollTop + this.clientHeight >= this.scrollHeight) {
        activeCategory = this.categories[this.categories.length - 1];
      }
    }

    if (activeCategory) {
      const { anchors } = this,
        { id } = activeCategory;

      if (anchors && anchors.state.selected !== id) {
        anchors.setState({ selected: id });
      }
    }
  }

  handleSearch(emojis) {
    this.SEARCH_CATEGORY.emojis = emojis;
    if (emojis) {
      //如果是搜索，就先不加载后面的emoji,后面forceUpdate
      clearTimeout(this.addRenderCategoryCountTimer);
    }
    if (!this.categoryRefs) return;

    for (let i = 0, l = this.categories.length; i < l; i++) {
      const component = this.categoryRefs[`category-${i}`];

      if (component && component.props.name !== 'Search') {
        const display = emojis ? 'none' : 'inherit';
        component.updateDisplay(display);
      }
    }

    this.forceUpdate();
    if (this.scroll) {
      this.scroll.scrollTop = 0;
    }
    this.handleScroll();
  }

  handleAnchorClick(category, i) {
    const component = this.categoryRefs[`category-${i}`],
      { scroll } = this;
    let scrollToComponent = null;
    scrollToComponent = () => {
      if (component) {
        let { top } = component;

        if (category.first) {
          top = 0;
        } else {
          top += 1;
        }

        scroll.scrollTop = top;
      }
    };

    if (this.SEARCH_CATEGORY.emojis) {
      this.handleSearch(null);
      if (this.search) {
        this.search.clear();
      }

      requestAnimationFrame(scrollToComponent);
    } else {
      scrollToComponent();
    }
  }

  handleSkinChange(skin) {
    const newState = { skin },
      { onSkinChange } = this.props;

    this.setState(newState);
    store.update(newState);

    onSkinChange(skin);
  }

  handleKeyDown(e) {
    let handled = false;
    switch (e.keyCode) {
      case 13:
        // it means 'enter'
        // eslint-disable-next-line no-case-declarations
        let emoji = null;
        if (
          this.SEARCH_CATEGORY.emojis &&
          this.SEARCH_CATEGORY.emojis.length &&
          (emoji = getSanitizedData(
            this.SEARCH_CATEGORY.emojis[0],
            this.state.skin,
            this.props.set,
            this.props.data
          ))
        ) {
          this.handleEmojiSelect(emoji);
          handled = true;
        }
        break;
      default:
    }

    if (handled) {
      e.preventDefault();
    }
  }

  updateCategoriesSize() {
    for (let i = 0, l = this.categories.length; i < l; i++) {
      const component = this.categoryRefs[`category-${i}`];
      if (component) component.memoizeSize();
    }

    if (this.scroll) {
      const target = this.scroll;
      this.scrollHeight = target.scrollHeight;
      this.clientHeight = target.clientHeight;
    }
  }

  getCategories() {
    //如果在搜索，则之前显示多少就显示多少
    if (this.SEARCH_CATEGORY.emojis) {
      return this.categories.slice(0, this.state.renderCategoryCount);
    }
    const firstLoad = this.state.renderCategoryCount === DEFAULT_SHOW_CATEGORY_COUNT;

    //由于一次性加载太卡，这里改为先加载第一屏，然后再加载其他
    try {
      if (this.state.renderCategoryCount < this.categories.length) {
        const nextCount = Math.min(this.state.renderCategoryCount + 1, this.categories.length);
        this.addRenderCategoryCountTimer = setTimeout(
          () => {
            if (this.SEARCH_CATEGORY.emojis) {
              //如果已经在搜索了就暂时不用刷新,否则会有bug
              return;
            }
            this.setState({ renderCategoryCount: nextCount });
          },
          firstLoad ? 500 : 200
        );
        return this.categories.slice(0, this.state.renderCategoryCount);
      }
    } catch (err) {}
    return this.categories;
  }

  setAnchorsRef(c) {
    this.anchors = c;
  }

  setSearchRef(c) {
    this.search = c;
  }

  setPreviewRef(c) {
    this.preview = c;
  }

  setScrollRef(c) {
    this.scroll = c;
    this.scrollRef.current = c;
  }

  setCategoryRef(name, c) {
    if (!this.categoryRefs) {
      this.categoryRefs = {};
    }

    this.categoryRefs[name] = c;
    if (c && this.SEARCH_CATEGORY.emojis && c.props.name !== 'Search') {
      c.updateDisplay('none');
    }
  }
  /**next space feat, support direction key down */
  getAllCategoryBaseIndex(categories) {
    let nextCategoryBaseIndex = 0;
    const allCategoriesIndex = {};
    let totalCount = 0;
    if (this.SEARCH_CATEGORY.emojis) {
      allCategoriesIndex[0] = 0;
      return [allCategoriesIndex, this.SEARCH_CATEGORY.emojis.length];
    }
    for (let index = 0; index < categories.length; index++) {
      const item = categories[index];
      if (item.name === 'Recent') {
        const { custom, recent, perLine } = this.props;
        const frequentlyUsed = recent || frequently.get(perLine);
        if (frequentlyUsed.length) {
          const frequentlyUsedCount = frequentlyUsed
            .map((id) => {
              // eslint-disable-next-line prefer-destructuring
              const emoji = custom.filter((e) => e.id === id)[0];
              if (emoji) {
                return emoji;
              }
              return id;
            })
            .filter((id) => !!getData(id, null, null, this.data)).length;
          allCategoriesIndex[index] = nextCategoryBaseIndex;
          nextCategoryBaseIndex += frequentlyUsedCount;
          totalCount += frequentlyUsedCount;
        }
      } else {
        //其它分类
        if (item.emojis) {
          allCategoriesIndex[index] = nextCategoryBaseIndex;
          nextCategoryBaseIndex += item.emojis.length;
          totalCount += item.emojis.length;
        }
      }
    }
    return [allCategoriesIndex, totalCount];
  }

  render() {
    const {
      perLine,
      set,
      sheetSize,
      sheetColumns,
      sheetRows,
      style,
      title,
      emoji,
      color,
      native,
      backgroundImageFn,
      emojisToShowFilter,
      showPreview,
      showSkinTones,
      include,
      exclude,
      recent,
      autoFocus,
      skinEmoji,
      notFound,
      notFoundEmoji,
      customColorKey,
    } = this.props;
    //11是padding left/right
    // const width = perLine * (emojiSize + 8) + 11 * 2 + measureScrollbar();
    const theme = this.getPreferredTheme();
    const skin = this.props.skin || this.state.skin || store.get('skin') || this.props.defaultSkin;
    const categories = this.getCategories();
    const [allCategoriesBaseIndex, totalCount] = this.getAllCategoryBaseIndex(categories);
    const that = this;
    const hideAnchor = this.props.hideSearchInput && this.SEARCH_CATEGORY.emojis !== null;
    this.emojiProps = { ...this.emojiProps, customColorKey };
    this.emojiPropsFromRecently = { ...this.emojiPropsFromRecently, customColorKey };
    return (
      <section
        style={{ ...style }}
        className={`emoji-mart emoji-mart-${theme} relative`}
        aria-label={title}
        onKeyDown={this.handleKeyDown}
      >
        {this.props.customHeader}
        {!this.props.hideSearchInput && (
          <div
            style={{
              //由于内部不想跟tailwinds绑定太深，这里一律用原始css
              display: 'flex',
              alignItems: 'center',
              marginTop: '15px',
            }}
          >
            <Search
              useCustomIcon={this.props.useCustomIcon}
              ref={this.setSearchRef}
              onSearch={this.handleSearch}
              data={this.data}
              i18n={this.i18n}
              emojisToShowFilter={emojisToShowFilter}
              include={include}
              exclude={exclude}
              custom={this.CUSTOM}
              autoFocus={autoFocus}
            />
            {this.props.searchBtn}
          </div>
        )}

        <div
          ref={this.setScrollRef}
          className={
            this.props.hideSearchInput
              ? 'emoji-mart-scroll-inline overflow-auto'
              : 'emoji-mart-scroll overflow-auto'
          }
          onScroll={this.handleScroll}
        >
          <div
            style={{
              marginTop: '10px',
            }}
          />
          <DirectionScroller
            type="grid"
            customSelector={hideAnchor ? `[aria-label="${I18N.categories.search}"]` : ''}
            spanCount={perLine}
            itemCount={totalCount}
            customItem={true}
            defaultIndex={-1}
            containerRef={this.scrollRef}
          >
            {(activeIndex) => {
              return categories.map((category, i) => {
                return (
                  <Category
                    lazyLoad={category.id === 'people'} //为了快速展现给用户看到，需要延迟加载
                    ref={this.setCategoryRef.bind(that, `category-${i}`)}
                    activeIndex={activeIndex}
                    key={category.name}
                    id={category.id}
                    name={category.name}
                    emojis={category.emojis}
                    perLine={perLine}
                    native={native}
                    hasStickyPosition={this.hasStickyPosition}
                    data={this.data}
                    i18n={this.i18n}
                    categoryBaseIndex={allCategoriesBaseIndex[i]}
                    recent={category.id === this.RECENT_CATEGORY.id ? recent : undefined}
                    custom={category.id === this.RECENT_CATEGORY.id ? this.CUSTOM : undefined}
                    emojiProps={i === 1 ? this.emojiPropsFromRecently : this.emojiProps}
                    notFound={notFound}
                    notFoundEmoji={notFoundEmoji}
                    useCustomIcon={this.props.useCustomIcon}
                  />
                );
              });
            }}
          </DirectionScroller>
          {!hideAnchor && <div className="h-[47px]" />}
        </div>
        {!hideAnchor && (
          <div className="emoji-mart-bar absolute w-full bottom-0 z-10">
            <Anchors
              ref={this.setAnchorsRef}
              data={this.data}
              i18n={this.i18n}
              color={color}
              categories={this.categories}
              onAnchorClick={this.handleAnchorClick}
              icons={this.icons}
            />
          </div>
        )}
        {(showPreview || showSkinTones) && (
          <div className="emoji-mart-bar">
            <Preview
              ref={this.setPreviewRef}
              data={this.data}
              title={title}
              emoji={emoji}
              showSkinTones={showSkinTones}
              showPreview={showPreview}
              emojiProps={{
                native,
                size: 38,
                skin,
                set,
                sheetSize,
                sheetColumns,
                sheetRows,
                backgroundImageFn,
              }}
              skinsProps={{
                skin,
                onChange: this.handleSkinChange,
                skinEmoji,
              }}
              i18n={this.i18n}
            />
          </div>
        )}
      </section>
    );
  }
}

NimblePicker.propTypes /* remove-proptypes */ = {
  ...PickerPropTypes,
  data: PropTypes.object.isRequired,
};
NimblePicker.defaultProps = { ...PickerDefaultProps };
