import type { SegmentDTO } from '@next-space/fe-api-idl';
import { values } from 'lodash-es';
import { useCallback } from 'react';
import { getState } from 'src/redux/store';
import { getSpaceCapacity } from 'src/services/capacity';
import { getCurrentSpaceId } from '../space/get-space';
import { useOpenCapacityDialog } from '../space/use-open-capacity-dialog';

export const useCheckCopyRecord = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  return useCallback(
    (uuids: string[]) => {
      const { blocks } = getState();
      let totalSize = 0;
      uuids.forEach((curBlockId) => {
        const block = blocks[curBlockId];
        if (block) {
          values(block.data.collectionProperties).forEach((seg: SegmentDTO[]) => {
            seg.forEach((i) => {
              totalSize = +(i?.size ?? 0);
            });
          });
        }
      }, 0);

      if (totalSize > 0) {
        const capacityInfo = getSpaceCapacity(getCurrentSpaceId(), { isDatabase: true });
        if (capacityInfo.currentCapacity + totalSize > capacityInfo.maxCapacity) {
          openCapacityDialog({
            title: '复制块失败',
            maxCapacity: capacityInfo.maxCapacity,
            isNovice: capacityInfo.isNovice,
          });
          return { status: false, totalSize };
        }
      }
      return { status: true, totalSize };
    },
    [openCapacityDialog]
  );
};
