import { isNumber } from 'lodash-es';
import { useCallback } from 'react';
import { checkViewPath } from 'src/common/utils/url-utils';
import { $currentUserCache } from 'src/services/user/current-user';
import { $searchParams, ViewPath } from 'src/utils';
import { locationReplace, locationToUrl } from 'src/utils/location-utils';
import { isNeedCreateSpace } from '../space';

/**
 * fetch用户之后调用
 */
export const useJumpAfterFetchUser = () => {
  return useCallback(() => {
    if (!$currentUserCache.uuid) {
      if (checkViewPath(ViewPath.login)) {
        return false;
      }
      locationToUrl(ViewPath.login, true);
      return true;
    } else if (
      !$searchParams.print &&
      (!$currentUserCache.nickname || !$currentUserCache.occupation)
    ) {
      if (checkViewPath(ViewPath.login)) {
        return false;
      }
      // 如果没有nickname或者职业,就跳去完善信息
      locationToUrl(ViewPath.login);
      return true;
    } else if (isNeedCreateSpace()) {
      if (checkViewPath(ViewPath.create)) {
        return false;
      }
      // 如果没有创建过空间，就滚去创建
      locationToUrl(ViewPath.create);
      return true;
    }

    if ($searchParams.from) {
      locationReplace($searchParams.from);
      return true;
    }

    return false;
  }, []);
};
