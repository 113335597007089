import type { RecordsQueryDTO } from '@next-space/fe-api-idl';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { ViewIconMap, ViewNameMap } from 'src/bitable/bitable-manager/const';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { isBlockNoText } from 'src/editor/utils/editor';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_UNDO } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { convertBlock } from 'src/redux/managers/block/convert';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { cache, dispatch, getState } from 'src/redux/store';

export const useOpenCopyView = () => {
  const openModal = useOpenModal();
  const copyViewFun = useCopyView();

  return useCallback(
    async ({
      popcorn,
      collectionId,
      blockId,
      isCopyLink,
    }: {
      collectionId: string;
      blockId: string;
      popcorn: ModalSchema.PopcornType;
      isCopyLink?: boolean;
    }) => {
      const { blocks } = getState();
      const collection = blocks[collectionId];

      const requests: RecordsQueryDTO['requests'] = [];
      (collection?.views ?? []).forEach((uuid) => {
        requests.push({ id: uuid, table: 'collectionView' });
      });
      const { collectionViews } = await request.editor.queryRecords({ requests });
      if (collectionViews) {
        dispatch(collectionViewsActions.update(collectionViews));
      }

      openModal.dropdown({
        popcorn,
        placement: 'bottom-start',
        content: ({ onCloseModal }) => {
          const { blocks, collectionViews } = getState();
          const collection = blocks[collectionId];
          const block = blocks[blockId];
          if (!collection || !block) return null;

          const items: ListItem<any>[] = [];
          (collection.views ?? []).forEach((viewId) => {
            const view = collectionViews[viewId];
            if (!view) return;
            if (view.type === CollectionViewType.FORM) return;

            items.push({
              type: ListItemType.OPERATION,
              data: {
                viewId,
                title: view.title || ViewNameMap[view.type],
                icon: <Icon name={ViewIconMap[view.type]} size="middle" />,
              },
            });
          });

          return (
            <div className="next-modal w-52 py-1.5">
              <p className="text-t2 h-10 flex items-center pl-4">复制多维表视图</p>
              <ListView
                items={items}
                onItemClick={async (item) => {
                  await copyViewFun({
                    collectionId,
                    blockId,
                    isCopyLink,
                    viewId: item.data.viewId,
                  });
                  onCloseModal();
                }}
              />
            </div>
          );
        },
      });
    },
    [copyViewFun, openModal]
  );
};
export const useCopyView = () => {
  const transaction = useTransaction();

  return useCallback(
    async ({
      collectionId,
      blockId,
      isCopyLink,
      viewId,
    }: {
      collectionId: string;
      blockId: string;
      isCopyLink?: boolean;
      viewId: string;
    }) => {
      const collection = cache.blocks[collectionId];

      const requests: RecordsQueryDTO['requests'] = [];
      (collection?.views ?? []).forEach((uuid) => {
        requests.push({ id: uuid, table: 'collectionView' });
      });
      const { collectionViews } = await request.editor.queryRecords({ requests });
      if (collectionViews) {
        dispatch(collectionViewsActions.update(collectionViews));
      }
      const block = cache.blocks[blockId];
      if (!collection || !block) return null;

      const refBlockData = {
        type: BlockType.REFERENCE_COLLECTION,
        data: { ref: { uuid: collectionId } },
      };
      let blockNoText = isBlockNoText(block.data);

      if (isCopyLink) {
        dispatch(HISTORY_UNDO());
        const { blocks } = getState();
        const block = blocks[blockId];
        if (block) {
          blockNoText = isBlockNoText(block.data);
        }
      }

      if (blockNoText && block.type === BlockType.TEXTAREA) {
        transaction(() => {
          convertBlock([blockId], refBlockData);
          copyView(blockId, viewId);
        });
      } else {
        transaction(() => {
          const newBlockId = addBlock(refBlockData, {
            parentId: block.parentId,
            after: blockId,
          });
          copyView(newBlockId, viewId);
        });
      }
    },
    [transaction]
  );
};

const copyView = (parentId: string, viewId: string) => {
  const { collectionViews } = getState();

  const view = collectionViews[viewId];
  if (!view) return;

  CollectionViewManager.add(
    {
      type: view.type,
      pageSort: view.pageSort,
      format: { ...view.format, formShared: false },
      title: view.title,
      shareId: undefined,
    },
    { parentId, last: true }
  );
};
