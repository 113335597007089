import type { FC } from 'react';
import { memo } from 'react';
import { FolderViewType } from 'src/services/app';
import {
  TableFileItem,
  TableFileListTitle,
  TableListStyleUploadItem,
} from './list-style/table-file';
import {
  ThumbnailFileItem,
  ThumbnailFileTitle,
  ThumbnailStyleUploadItem,
} from './list-style/thumbnail-file';

// #region 文件
interface FileItemProps {
  uuid: string;
  listView?: FolderViewType;
  canDownload: boolean;
}
export const FileItem: FC<FileItemProps> = memo((props) => {
  const { uuid, listView, canDownload } = props;
  switch (listView) {
    case FolderViewType.PREVIEW:
    case FolderViewType.THUMBNAIL:
      return (
        <ThumbnailFileItem
          isPreView={listView === FolderViewType.PREVIEW}
          uuid={uuid}
          canDownload={canDownload}
        />
      );
    case FolderViewType.TABLE:
    default:
      return <TableFileItem uuid={uuid} canDownload={canDownload} />;
  }
});
// #endregion

// #region 上传占位
export const DriveUploadFileItem: FC<{
  listView?: FolderViewType;
  uuid: string;
}> = memo(({ listView, uuid }) => {
  switch (listView) {
    case FolderViewType.PREVIEW:
    case FolderViewType.THUMBNAIL:
      return (
        <ThumbnailStyleUploadItem uuid={uuid} isPreView={listView === FolderViewType.PREVIEW} />
      );
    case FolderViewType.TABLE:
    default:
      return <TableListStyleUploadItem uuid={uuid} />;
  }
});
// #endregion

// #region 内容排版
export const DriveTitle: FC<{
  listView?: FolderViewType;
  uuid: string;
}> = memo(({ listView, uuid }) => {
  switch (listView) {
    case FolderViewType.PREVIEW:
    case FolderViewType.THUMBNAIL:
      return <ThumbnailFileTitle uuid={uuid} />;
    case FolderViewType.TABLE:
    default:
      return <TableFileListTitle uuid={uuid} />;
  }
});
// #endregion
