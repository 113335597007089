import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { dispatch, getState } from 'src/redux/store';
import { sliceSegments } from 'src/utils/segment-utils';

export const useMinusKey = (uuid: string) => {
  const transaction = useTransaction();
  const getEditorModel = useGetOrInitEditorModel();
  const focusEditableAt = useFocusEditableByBlockId();

  const handleMinusKey = (event: globalThis.Event, symbolSize: number) => {
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    const editorModel = getEditorModel(uuid);
    const selection = editorModel?.selection;
    if (!selection) return;
    if (!selection.isCollapsed || selection.offset !== symbolSize) return;

    const [firstSegment] = sliceSegments(block.data.segments, 0, symbolSize);
    if (!firstSegment) return;
    const trigger = firstSegment.text;

    if (!['--', '**', '－－', '＊＊'].includes(trigger)) return;

    event.preventDefault();

    const afterSegments = sliceSegments(block.data.segments, symbolSize);
    const symbol = [...new Set(trigger.split(''))].join();

    // 分别派发 2 次事件, 为了可以撤销回 '---' 的状态
    updateBlock(
      uuid,
      {
        data: {
          segments: [
            {
              ...firstSegment,
              text: [...new Array(3)].map(() => symbol).join(''),
            },
            ...afterSegments,
          ],
        },
      },
      true
    );

    transaction(() => {
      updateBlock(block.uuid, {
        data: { segments: afterSegments },
      });
      addBlock({ type: BlockType.DIVIDER }, { parentId: block.parentId, before: block.uuid });
      dispatch(
        HISTORY_EFFECTS({
          init() {
            focusEditableAt(uuid, 0);
          },
          redo() {
            focusEditableAt(uuid, 0);
          },
          undo() {
            focusEditableAt(uuid, 3);
          },
        })
      );
    });
  };

  return useCallback(handleMinusKey, [focusEditableAt, getEditorModel, transaction, uuid]);
};
