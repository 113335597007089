import type { ContentGravity } from '@next-space/fe-api-idl/lib/models/ContentGravity';
import { useCallback } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';

/** 块对齐，里面不要包transaction */
export const useAlignBlock = () => {
  const alignBlock = useCallback((blockId: string, gravity: ContentGravity) => {
    let data = cache.blocks[blockId]?.data;
    if (!data) {
      data = {};
    }
    updateBlock(blockId, {
      data: {
        ...data,
        format: {
          contentGravity: gravity,
        },
      },
    });
  }, []);
  const alignBlockCaption = useCallback((blockId: string, gravity: ContentGravity) => {
    let data = cache.blocks[blockId]?.data;
    if (!data) {
      data = {};
    }
    updateBlock(blockId, {
      data: {
        ...data,
        format: {
          captionGravity: gravity,
        },
      },
    });
  }, []);
  return { alignBlock, alignBlockCaption };
};
