import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { fetchUserBalance } from 'src/services/user/user-balance';

export const useExchangeScore = () => {
  const openModal = useOpenModal();
  return useCallback(
    async (itemId: string, confirm?: () => void) => {
      const success = await request.infra.integralExchange({
        itemId,
      });
      if (success) {
        void fetchUserBalance();
        openModal.warning({
          confirm,
          content: <div className="text-h4">兑换成功</div>,
          colorType: 'active',
          noCancel: true,
        });
      }
      return success;
    },
    [openModal]
  );
};
