import ClipboardJS from 'clipboard';
import { useEffect, useRef } from 'react';
import { v4 } from 'uuid';
import { message } from '../components/message';

/**
 * 复制文案需要放到 dom 上，data-clipboard-text="xxx"
 */
export const useClipboard = (props?: { noMessage?: boolean; id?: string; track?: () => void }) => {
  const { noMessage, id, track } = props || {};
  const copyId = useRef(id ?? `copy-${v4()}`);

  useEffect(() => {
    const clipboard = new ClipboardJS(`#${copyId.current}`);
    clipboard.on('success', () => {
      if (!noMessage) message.success('复制成功');
      track?.();
    });
    clipboard.on('error', () => {
      if (!noMessage) message.error('复制失败');
    });
    return () => {
      clipboard.destroy();
    };
  }, [noMessage, track]);

  return copyId.current;
};
