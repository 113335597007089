import { getTablePropertyValue } from 'src/hooks/collection-view/get-property-value';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { cacheGetCalendarRenderTime } from 'src/services/global-cache';

export const getCalendarRenderTime = (params: {
  recordId: string;
  calendarBy: string;
  calendarByEnd: string;
  blocks?: Record<string, NextBlock>;
}) => {
  const { recordId, calendarBy, calendarByEnd, blocks = getState().blocks } = params;
  const block = blocks[recordId];
  const cacheKey = JSON.stringify({ ...block, recordId, calendarBy, calendarByEnd });
  if (cacheGetCalendarRenderTime.has(cacheKey)) {
    return cacheGetCalendarRenderTime.get(cacheKey);
  }

  const rawStartTime = getTablePropertyValue(block, calendarBy) as number | undefined;
  const rawEndTime = getTablePropertyValue(block, calendarByEnd) as number | undefined;

  const renderStartTime = rawStartTime || rawEndTime;
  let renderEndTime = rawEndTime;
  if (rawStartTime && rawEndTime) {
    renderEndTime = rawEndTime >= rawStartTime ? rawEndTime : rawStartTime;
  }
  if (!rawEndTime) {
    renderEndTime = rawStartTime;
  }

  const result = { rawStartTime, renderStartTime, rawEndTime, renderEndTime };
  cacheGetCalendarRenderTime.set(cacheKey, result);
  return result;
};
