import { fastEqual } from '@flowus/common';
import { useSelector as reduxSelector, useStore } from 'react-redux';
import type { RootState } from 'src/redux/types';
import { useContextSelector } from 'use-context-selector';
import { StoreContext } from './context';

interface Options<T> {
  equalityFn?: typeof fastEqual;
  once?: boolean;
  wait?: number;
  waitMode?: 'debounce' | 'throttle' | 'none';
  dep?: any[];
}

// const debugMap = new Map<string, number>();
export const useFineSelector = <T>(f: ($state: RootState) => T, opt?: Options<T>) => {
  const { equalityFn = fastEqual } = opt || {};
  // const zustandSelector = useContextSelector(StoreContext, (state) => state.$state);
  // const isInit = useContextSelector(StoreContext, (state) => state.isInit);
  // const isChartPreview = usePageScene() === PageScene.PAGE_CHART; // 这个StoreContext有bug，只要当前页面没有render就一直无法回调
  const once = useContextSelector(StoreContext, (state) => state.once);
  const store = useStore();

  if (once || opt?.once) {
    return (() => {
      return f(store.getState());
    })();
  }

  // if (isInit && !isChartPreview) {
  //   return zustandSelector(() => {
  //     return f(store.getState());
  //   }, equalityFn);
  // }

  return reduxSelector(f, equalityFn);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  // return useCustomObserver(f, opt);
};

export const useFuSelector = useFineSelector;

// const useCustomObserver = <T>(selector: ($state: RootState) => T, opt?: Options<T>) => {
//   const { equalityFn = fastEqual, wait, waitMode, dep = [] } = opt || {};
//   const stores = useContext(MobXProviderContext) as RootState;

//   const stateRef = useRef(selector(stores));
//   const jsonStateRef = useRef(fastJsonStableStringify(stateRef));
//   const [_, forceUpdate] = useState(0);

//   useDeepCompareEffect(() => {
//     const run = () => {
//       // console.log('update');

//       const newState = selector(stores);
//       // console.time('check');
//       const newJsonState = fastJsonStableStringify(newState);
//       const check = equalityFn(jsonStateRef.current, newJsonState);
//       // const check = fastEqual(selectedStateRef.current, newSelectedState);
//       // console.timeEnd('check');

//       if (check) {
//         return;
//       }

//       stateRef.current = newState;
//       jsonStateRef.current = newJsonState;
//       forceUpdate((pre) => pre++);
//     };

//     const _fn =
//       waitMode === 'throttle'
//         ? throttle(run, wait)
//         : waitMode === 'debounce'
//         ? debounce(run, wait)
//         : run;

//     const disposer = autorun(_fn);

//     return () => {
//       disposer();
//       // @ts-ignore cancel
//       _fn?.cancel?.();
//     };
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dep, selector]);

//   return stateRef.current;
// };
