import { cx } from '@flowus/common/cx';
import { memo, type FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { getPreviewType } from 'src/components/file-preview/renderElements';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useResize } from 'src/hooks/block/use-resize';
import { getSegmentsFileNameInfo } from 'src/utils/file';
import { calcImageMaxWidthZoomSize } from 'src/utils/image-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { Caption } from '../caption';
import { ResizeElement } from '../resize-element';
import { useViewModelController } from './hook';
import { ViewModelToolBar } from './toolbar';
import type { ViewModeFcProps } from './type';

interface PreviewProps extends ViewModeFcProps {
  smallHeder?: boolean;
}
export const ViewModelPreView: FC<PreviewProps> = memo((props) => {
  const {
    root,
    title,
    context,
    iconName,
    children,
    readonly,
    viewModelToolBarData,
    className,
    smallHeder,
    isFile,
    uuid,
    ownerBlockId,
  } = props;
  const block = usePickBlock(uuid, ['data']);
  const fileViewType = getPreviewType(getSegmentsFileNameInfo(block?.data.segments).extName);
  const isVideo = fileViewType === 'video';
  const isImage = fileViewType === 'image';
  const isPdf = fileViewType === 'pdf' || fileViewType === 'epub';
  const isEpub = fileViewType === 'epub';
  const { isFail, toolBarProps, hasOverlay } = useViewModelController(props);
  const {
    renderSize,
    onRenderSize,
    widthBreakPoints,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultHeight,
    defaultWidth,
    pageContentWidth,
  } = useResize({
    root,
    id: uuid,
    defaultWidth: 500,
    defaultHeight: 140,
  });

  const calcSize = calcImageMaxWidthZoomSize({
    width: defaultWidth,
    maxWidth: pageContentWidth,
    height: defaultHeight,
  });

  return (
    <BlockDrop
      blockRef={containerRef}
      id={ownerBlockId ?? uuid}
      horizontal={root}
      className={cx('my-1', isFail && 'opacity-50', className)}
    >
      <div
        className={cx(
          'rounded border border-black/10 !cursor-default flex flex-col max-w-full group',
          isDisable && 'pointer-events-none'
        )}
      >
        <div
          className={cx(
            'h-[60px] flex justify-between pl-2 py-3 pr-3.5 items-center',
            children && 'border-b border-black/10',
            smallHeder && 'py-2'
          )}
        >
          <div className="flex text-ellipsis">
            <IconTrigger
              hasOverlay={hasOverlay}
              trigger={!isFile}
              iconSize={20}
              className="mr-2 mt-0.5 flex flex-shrink-0 w-6 h-6"
              blockId={uuid}
              defaultIcon={<Icon size="middle" name={iconName} />}
            />
            <div className={cx('flex flex-col', smallHeder && 'flex-row items-center')}>
              <div className="mr-2 text-t1">{title}</div>
              <div className="text-t4 text-grey3">{context}</div>
            </div>
          </div>
          <ViewModelToolBar
            className="hidden group-hover:flex"
            small={smallHeder}
            splitLine={[1, 2]}
            {...toolBarProps}
            {...viewModelToolBarData}
          />
          <ViewModelToolBar
            className="flex group-hover:hidden"
            small={smallHeder}
            {...viewModelToolBarData}
            {...(isFile
              ? {
                  onReplace: toolBarProps.onReplace,
                  onDownLoad: toolBarProps.onDownLoad,
                }
              : { openLink: toolBarProps.openLink })}
          />
        </div>
        <ResizeElement
          widthBreakPoints={widthBreakPoints}
          resizeHeight={!isVideo}
          readonly={readonly}
          fullHeight={isVideo}
          defaultHeight={isImage ? calcSize.width : defaultHeight}
          resizeWidth={false}
          defaultBlockFullWidth={true}
          maxWidth={containerWidth}
          defaultWidth={containerWidth}
          onChange={changeSize}
          minHeight={130}
          childrenClassName="flex flex-col overflow-hidden"
          onRenderSize={onRenderSize}
          className={cx((isPdf || isEpub) && 'pb-3')}
        >
          {children}
        </ResizeElement>
      </div>
      <Caption blockId={uuid} style={{ width: renderSize.width }} />
    </BlockDrop>
  );
});
