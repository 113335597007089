import data from '../../data/all.json';
import customData from '../../data/custom-icon.json';
import NimbleEmojiIndex from './nimble-emoji-index';

const emojiIndex = new NimbleEmojiIndex(data);
const customEmojiIndex = new NimbleEmojiIndex(customData);
const { emojis, emoticons } = emojiIndex;

function search(...args) {
  return emojiIndex.search(...args);
}
function customSearch(...args) {
  return customEmojiIndex.search(...args);
}
const obj = {
  search,
  customSearch,
  emojis,
  emoticons,
};

export default obj;
