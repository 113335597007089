import { generateCustomIconUrlByPath } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { Placement } from '@popperjs/core';
import type { FC, MouseEvent } from 'react';
import { Suspense } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';
import type { ModalSchema } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useReadonly } from 'src/hooks/page';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { usePickBlock } from 'src/utils/pick-block';
import { IconImage } from './icon-image';
import { OssIconImage } from './oss-icon-image';
import { useOpenEmoji } from './use-open-emoji';

interface IconTriggerProps {
  blockId: string;
  iconSize?: number;
  hideRemoveBtn?: boolean;
  defaultIcon?: JSX.Element; // 如果emojiStr和iconUrl都为空，则使用默认的
  modifiers?: any[];
  offset?: number[];
  className?: string;
  tooltipClassName?: string;
  placement?: Placement;
  popcorn?: ModalSchema.PopcornType | null;
  defaultEmoji?: string; // 给着重块用的，如果啥都没有就用这个默认的
  onSelectEmoji?: (emojiStr: string) => void; // 给着重块用的
  trigger?: boolean; // 为false时点击不会弹出panel，仅展示icon
  hasOverlay?: boolean; // 是否有引用页面的箭头覆盖
}

/**
 * 业务组件，点击icon后会弹起选择图标的ui组件,上传/更新icon相关的操作都在这里做
 * 目录中引用页面都有 箭头，多维表引用页面打卡时，collection 有箭头，ref block 无箭头
 */
export const IconTrigger: FC<IconTriggerProps> = (props) => {
  const { illegal } = usePermissions(props.blockId);
  const readonly = useReadonly(props.blockId);

  const fallback = (
    <IconWidget
      hasOverlay={props.hasOverlay}
      disableHover={true}
      className={props.className}
      iconSize={props.iconSize}
      emojiStr={props.defaultEmoji}
      defaultIcon={props.defaultIcon}
    />
  );
  const noTrigger = props.trigger === false || readonly;

  if (illegal) {
    return fallback;
  }

  return (
    <Suspense fallback={fallback}>
      {noTrigger ? (
        <IconNoTrigger {...props} className={cx(props.className, props.tooltipClassName)} />
      ) : (
        <IconTrigger2 {...props} />
      )}
    </Suspense>
  );
};

const IconTrigger2: FC<IconTriggerProps> = (props) => {
  const openEmoji = useOpenEmoji(props.blockId, props);
  return (
    <Tooltip popup="更换图标" className={cx('inline-flex flex-shrink-0', props.tooltipClassName)}>
      <IconNoTrigger {...props} onClick={openEmoji} />
    </Tooltip>
  );
};

const IconNoTrigger: FC<
  IconTriggerProps & {
    onClick?: (event: MouseEvent) => void;
  }
> = (props) => {
  const isDarkMode = useIsDarkMode();
  const block = usePickBlock(props.blockId, ['data'], ['icon'], { wait: 200 });
  const icon = block?.data.icon;
  const noTrigger = props.onClick == null;

  if (icon?.type === 'http' && icon.value != null) {
    return (
      <IconImage
        onClick={props.onClick}
        trigger={!noTrigger}
        src={icon.value}
        className={props.className}
        emojiSize={props.iconSize}
        hasOverlay={props.hasOverlay}
      />
    );
  } else if (icon?.type === 'upload' && icon.value != null) {
    return (
      <OssIconImage
        onClick={props.onClick}
        trigger={!noTrigger}
        blockId={props.blockId}
        ossName={icon.value}
        className={props.className}
        emojiSize={props.iconSize}
        hasOverlay={props.hasOverlay}
      />
    );
  } else if (icon?.type === 'icon' && !!icon.value) {
    return (
      <IconImage
        onClick={props.onClick}
        trigger={!noTrigger}
        src={generateCustomIconUrlByPath(isDarkMode, icon.value)}
        className={props.className}
        emojiSize={props.iconSize}
        hasOverlay={props.hasOverlay}
      />
    );
  }

  return (
    <IconWidget
      onClick={props.onClick}
      hasOverlay={props.hasOverlay}
      disableHover={noTrigger}
      className={props.className}
      iconSize={props.iconSize}
      emojiStr={(icon?.type === 'emoji' ? icon.value : '') || props.defaultEmoji}
      defaultIcon={props.defaultIcon}
    />
  );
};
