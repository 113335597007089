import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
interface Props {
  className?: string;
  onClick: () => void;
}
/** 添加备注 */
export const MarkNameEditButton: FC<Props> = (props) => {
  return (
    <div className={cx('flex flex-row', props.className)} onClick={props.onClick}>
      <Icon name={'IcBtnEdit'} size="middle" />
      <div className="ml-0.5 text-t3 text-grey3">添加备注</div>
    </div>
  );
};
