/** 为了解循环依赖的问题 */
import { $spaceViewsCache } from 'src/services/spaces/space-views';
import { $spacesCache } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';

export const getCurrentSpaceId = () => {
  return getSpace($currentUserCache.currentSpaceViewId)?.uuid ?? '';
};

const getSpace = (spaceViewId: string) => {
  return $spacesCache[$spaceViewsCache[spaceViewId]?.spaceId ?? ''];
};
