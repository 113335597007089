import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React from 'react';
import { Icon } from 'src/common/components/icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { getListFormat } from 'src/editor/editor/plugin/bullet-list';
import { getBorderColor } from 'src/editor/editor/plugin/mark';
import { getOrderListNumber } from 'src/editor/editor/plugin/order-list-helper';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { getState } from 'src/redux/store';
import { usePickBlock } from 'src/utils/pick-block';

interface Props {
  uuid: string;
  children?: React.ReactNode;
}

export const Header: FC<Props> = ({ uuid }) => {
  const block = usePickBlock(uuid, ['data'], ['segments', 'level']);

  if (!block) return null;

  const { segments, level } = block.data;

  return (
    <RichText
      segments={segments}
      className={cx('w-full font-bold', {
        'text-[24px]': level === 1,
        'text-[19px]': level === 2,
        'text-[16px]': level === 3,
        'text-[14px]': level === 4,
      })}
    />
  );
};

export const TextAreaElement: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);

  if (!block) return null;

  const { segments } = block.data;
  return (
    <>
      <RichText segments={segments} className="text-t4 py-0.5 whitespace-break-spaces" />
      {children && <BlockChildren>{children}</BlockChildren>}
    </>
  );
};

export const Mark: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['textColor', 'backgroundColor', 'data'], ['segments']);

  if (!block) return null;

  const { segments } = block.data;
  const borderColor = getBorderColor(block.textColor, block.backgroundColor);

  return (
    <div style={{ borderColor }}>
      <div className="flex pl-1 py-0.5">
        <IconTrigger
          blockId={uuid}
          hideRemoveBtn={true}
          trigger={false}
          iconSize={18}
          defaultEmoji={'💡'}
        />

        <RichText segments={segments} className="text-t4 w-full ml-2 overflow-hidden" />
      </div>
      {children && <BlockChildren>{children}</BlockChildren>}
    </div>
  );
};

export const Todo: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['data'], ['checked', 'segments']);

  if (!block) return null;

  const { checked, segments } = block.data;

  return (
    <>
      <div className="flex text-t4 py-0.5">
        <Icon
          size="middle"
          name={checked ? 'IcCheckbox2Check' : 'IcCheckbox2'}
          className={cx('mt-0.5 mr-1.5 w-[18px] h-[18px]', checked ? 'text-active_color' : '')}
        />
        <RichText
          className={cx('flex-1 mr-4', checked ? 'opacity-50' : 'no-underline', {
            'line-through': checked,
          })}
          segments={segments}
        />
      </div>
      {children && <BlockChildren>{children}</BlockChildren>}
    </>
  );
};

export const List: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);

  if (!block) return null;

  const { segments } = block.data;
  const listFormat = getListFormat(uuid, getState().blocks);

  return (
    <>
      <div className="flex text-t4 flex-1 py-0.5">
        <div
          className="pseudoBefore pt-0.5 w-6 flex justify-center"
          style={{ '--pseudoBefore--content': `"${listFormat}"` } as any}
        />
        <RichText className="self-start flex-1" segments={segments} />
      </div>
      {children && <BlockChildren>{children}</BlockChildren>}
    </>
  );
};

export const OrderList: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);

  if (!block) return null;

  const { segments } = block.data;
  const listNo = getOrderListNumber(uuid, getState().blocks);

  return (
    <>
      <div className="flex text-t4 flex-1 py-0.5">
        <div className="min-w-[24px] mr-1 text-center inline-block dot">{listNo}</div>
        <RichText className="self-start flex-1" segments={segments} />
      </div>
      {children && <BlockChildren>{children}</BlockChildren>}
    </>
  );
};

export const FoldList: FC<Props> = ({ uuid }) => {
  const block = usePickBlock(uuid, ['subNodes', 'data'], ['segments']);
  if (!block) return null;

  const { segments } = block.data;
  return (
    <div className="flex text-t4 ignore-focus-click flex-1 overflow-hidden py-0.5">
      <span className="flex">
        <Icon
          size="middle"
          name="IcToggleBlack"
          className={cx('transform duration-150 cursor-pointer', {
            'opacity-30': block.subNodes.length === 0,
          })}
        />
      </span>
      <RichText className="self-start mr-4 flex-1 w-0" segments={segments} />
    </div>
  );
};

export const Quote: FC<Props> = ({ uuid, children }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);

  if (!block) return null;

  const { segments } = block.data;
  return (
    <div className="border-l-[3px] border-solid border-current py-0.5">
      <div className="pl-2">
        <RichText className="w-full text-t4 px-0.5" segments={segments} />
      </div>
      {children && <BlockChildren>{children}</BlockChildren>}
    </div>
  );
};

export const Other: FC<Props> = ({ uuid }) => {
  const block = usePickBlock(uuid, ['data'], ['ref', 'display']);
  const blockType = block?.type;
  if (!block) return null;

  switch (blockType) {
    case BlockType.REFERENCE_COLLECTION_PAGE:
    case BlockType.PAGE: {
      let id = uuid;
      if (blockType === BlockType.REFERENCE_COLLECTION_PAGE) {
        id = block.data.ref?.uuid ?? '';
      }

      return <InlinePage uuid={id} className="flex text-t4 p-0.5" autoIconSize />;
    }

    case BlockType.REFERENCE: {
      if (block.data.display === 'linkPage' && block.data.ref?.uuid) {
        return (
          <InlinePage
            uuid={block.data.ref?.uuid}
            isLinkPage={true}
            className="flex text-t4 py-0.5"
            autoIconSize
          />
        );
      }
      break;
    }
    default:
  }

  return null;
};

export const BlockChildren: FC<{ children: React.ReactNode }> = (props) => {
  if (!props.children) return null;

  return <div className="relative pl-6">{props.children}</div>;
};

export const getBlockRender = (type?: BlockType) => {
  switch (type) {
    case BlockType.TEXTAREA:
      return TextAreaElement;
    case BlockType.HEADER:
      return Header;
    case BlockType.MARK:
      return Mark;
    case BlockType.TODO:
      return Todo;
    case BlockType.LIST:
      return List;
    case BlockType.ORDER_LIST:
      return OrderList;
    case BlockType.FOLD_LIST:
    case BlockType.TOGGLE_HEADER:
      return FoldList;
    case BlockType.QUOTE:
      return Quote;
    default:
      return Other;
  }
};
