// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck fucking the i18n
import { cx } from '../../utils';
import type { ComponentProps, FC } from 'react';
import React from 'react';

export interface ButtonProps extends Pick<ComponentProps<'button'>, 'onClick' | 'style'> {
  autoFocus?: boolean;
  disable?: boolean;
  className?: string;
  colorType?: 'primary' | 'secondary' | 'red' | 'white' | 'active';
  size?: 'middle' | 'large' | 'auto';
}

const ColorType = {
  primary: 'bg-black2 border-black text-white-base',
  secondary: 'bg-white dark:bg-transparent text-black border-grey6',
  red: 'bg-white1 text-red border-red',
  white: 'bg-transparent text-black border-white',
  active: 'bg-active_color text-white border-transparent',
};

const ColorHover = {
  primary: 'hover:opacity-90',
  secondary: 'hover:brightness-90 dark:hover:bg-black_003',
  red: 'hover:bg-red/10',
  white: 'hover:bg-black_003',
  active: 'hover:brightness-90 text-t2-bold dark:hover:brightness-110',
};

/**
 * 通用button，默认是nextSpace设计样式，默认width: 100%
 * 自定义宽度需要填写className，自定义text样式需要textStyle
 */
export const Button: FC<ButtonProps> = React.memo((props) => {
  const {
    colorType = 'secondary',
    disable,
    onClick,
    autoFocus = false,
    className,
    children,
    ...args
  } = props;
  return (
    <button
      {...args}
      onClick={onClick}
      autoFocus={autoFocus}
      disabled={disable}
      className={cx(
        'flex justify-center items-center rounded-md border outline-none',
        ColorType[colorType],
        disable ? 'opacity-30 cursor-not-allowed' : ColorHover[colorType],
        className
      )}
    >
      {children}
    </button>
  );
});
