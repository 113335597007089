export interface HeaderItem {
  parentId: string;
  uuid: string;
  level: number;
  /** 层级 */
  deep: number;
  paddingLeft: number;
  text?: string;
  closed: boolean;
  active: boolean;
  subNodes: string[];
  subCount: number;
  title: string;
}

export const DIRECTORY_MAX_WIDTH = 400;
export const DIRECTORY_MIN_WIDTH = 200;
export const DIRECTORY_LITE_WIDTH = 54;
export const DIRECTORY_DEFAULT_WIDTH = 240;
