import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { newContent, newText, normalizeFormat, registerElementMeta } from '@next-space/fe-inlined';
import { getUserName, useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { getState } from 'src/redux/store';
import { INLINE_PERSON_TAG } from './const';
import type { InlinePlugin } from './inline-plugin';
import { useComputeTextStyle } from './utils';

const INLINE_PERSON_META: IElementMeta = {
  tag: INLINE_PERSON_TAG,
  hasContent: false,
  legalize: (element) => {
    const userId = element.props.userId as string;
    const user = getState().users[userId];

    return newContent([newText(` ${getUserName(user?.uuid ?? '')} `)]);
  },
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};
registerElementMeta(INLINE_PERSON_META);

const InlinePerson: IElementComponent = ({
  htmlDataProps,
  textFormat: textFormat0,
  userId: userId0,
}) => {
  const userId = userId0 as string;
  const textFormat = normalizeFormat(textFormat0 as any);
  const user = useUser(userId);
  const textStyle = useComputeTextStyle(textFormat);
  const userName = useUserName(user?.uuid ?? '');
  return (
    <span {...htmlDataProps} contentEditable={false} className="px-1 py-[3px]" style={textStyle}>
      <span className={textStyle.color || 'text-grey4'}>@</span>
      <span className={textStyle.color || 'text-grey3'}>{userName}</span>
    </span>
  );
};

export const PersonInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_PERSON_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlinePerson);
  },
};
