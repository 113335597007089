import { cx } from '@flowus/common/cx';
import type { IElementComponent } from '@next-space/fe-inlined';
import { registerElementMeta } from '@next-space/fe-inlined';
import { lookupEditorElement } from '@next-space/fe-inlined/dom-utils';
import type { MouseEvent } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useShortcutLink } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-link';
import { useShortcutToolBar } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-toolbar';
import { useReadonly } from 'src/hooks/page';
import { $searchParams } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { normalizeHref } from 'src/utils/embed';
import { useGetPageId } from 'src/utils/getPageId';
import { urlFetcher } from 'src/utils/url-fetcher';
import { EditableContext } from '../uikit/editable-context';
import { getEditorModelByEditorKey } from '../uikit/editable-models';
import { LINK_META } from './const';
import type { InlinePlugin } from './inline-plugin';

const getEditorModel = (element: HTMLElement | null) => {
  const editorElement = lookupEditorElement(element);
  if (!editorElement) return;
  const editorKey = editorElement.getAttribute('data-editor');
  if (!editorKey) return;
  return getEditorModelByEditorKey(editorKey);
};

registerElementMeta(LINK_META);
const Link: IElementComponent = ({
  url: href0,
  fileStorageType: fileStorageType0,
  children,
  htmlDataProps,
  baseOffset,
}) => {
  const blockRef = useRef<HTMLAnchorElement>(null);
  const pageId = useGetPageId();
  const href = href0 as string | undefined;
  const fileStorageType = fileStorageType0 as 'internal' | 'external' | undefined;
  const draggedRef = useRef<{ downX: number; downY: number; upX: number; upY: number }>();
  const { interactable } = useContext(EditableContext);
  const [linkUrl, setLinkUrl] = useState($searchParams.print ? normalizeHref(href ?? '') : '');
  const editorModel = getEditorModel(blockRef.current);
  const { setFakeFocus } = useShortcutToolBar(editorModel?.editorKey || '');
  const shortcutLink = useShortcutLink(editorModel?.editorKey || '', { setFakeFocus });
  const readonly = useReadonly(editorModel?.editorKey);

  useEffect(() => {
    void (async () => {
      let url = href ?? '';
      if (fileStorageType === 'internal') {
        url = await urlFetcher.fetchDownloadUrl({ blockId: pageId, ossName: url });
      } else {
        url = normalizeHref(url);
      }
      setLinkUrl(url);
    })();
  }, [fileStorageType, href, pageId]);

  const clickCopy = async () => {
    if (!linkUrl) return;
    void writeTextInClipboard(linkUrl);
  };

  const clickEditor = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const dataLen = Number(htmlDataProps['data-length'] || 0);
    if (!editorModel) return;
    editorModel.performChange(async (ctx) => {
      ctx.select(baseOffset, baseOffset + dataLen);
      await editorModel.requestFocus();
      shortcutLink(true);
    });
  };

  return (
    <a
      {...htmlDataProps}
      href={linkUrl}
      ref={blockRef}
      target="_blank"
      rel="noreferrer"
      className={cx(
        'underline underline-offset-4 text-active_color',
        interactable ? 'cursor-pointer' : 'cursor-text'
      )}
      onClick={async (event) => {
        event.preventDefault();
        if (!interactable) return;
        const info = draggedRef.current;
        if (
          !info ||
          Math.max(Math.abs(info.upX - info.downX), Math.abs(info.upY - info.downY)) >= 5
        ) {
          return;
        }
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noreferrer');
        link.setAttribute('href', linkUrl);
        link.click();
      }}
      onPointerDown={(event) => {
        draggedRef.current = {
          downX: event.clientX,
          downY: event.clientY,
          upX: event.clientX,
          upY: event.clientY,
        };
      }}
      onPointerUp={(event) => {
        if (!draggedRef.current) return;
        draggedRef.current.upX = event.clientX;
        draggedRef.current.upY = event.clientY;
      }}
    >
      <Tooltip
        disabled={readonly}
        theme="none"
        maxWidth={'none'}
        interactive
        className="inline break-words"
        placement="bottom-start"
        interactiveDebounce={0}
        popupClass="next-modal !shadow-light-sm h-7 !flex items-center text-t4 line-clamp-none allow-text-selection"
        popupClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        popup={
          <div className="flex items-center space-x-1 px-2">
            <Icon name="IcGlobeShare" size="small" className="flex-shrink-0 text-grey3" />
            <div className="text-ellipsis text-grey3 flex-shrink-0 max-w-[220px]">{linkUrl}</div>
            <span className="flex-shrink-0 flex items-center space-x-2">
              <Tooltip popup="复制链接">
                <Icon
                  className="text-grey3 cursor-pointer disable-select"
                  name="IcCopy"
                  size="small"
                  onClick={clickCopy}
                />
              </Tooltip>
              <Tooltip popup="编辑">
                <Icon
                  className="text-grey3 cursor-pointer disable-select"
                  name="IcBtnEdit"
                  size="small"
                  onClick={clickEditor}
                />
              </Tooltip>
            </span>
          </div>
        }
      >
        {children}
      </Tooltip>
    </a>
  );
};

export const LinkInlinePlugin: InlinePlugin = {
  elementMeta: LINK_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, Link);
  },
};
