export const requestFromNet = (url: string, requestInit?: RequestInit) => {
  const headers: HeadersInit = {};
  if (typeof localStorage !== 'undefined') {
    headers['x-platform'] = 'web-cookie';
  }
  requestInit = {
    ...requestInit,
    headers: { ...requestInit?.headers, ...headers },
  };
  const finalUrl = url.startsWith('http') ? url : location.origin + url;
  return fetch(finalUrl, requestInit)
    .then((res) => res.json())
    .then((res) => res);
};
