export const sendClickEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new Event('click', {
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
};

export const sendDoubleClickEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new Event('dblclick', {
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
};
export const sendMouseOverEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new MouseEvent('mouseover', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
};

export const sendMouseEnterEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new MouseEvent('mouseenter', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
};
export const sendMouseOutEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new MouseEvent('mouseout', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  element.dispatchEvent(event);
};
export const sendMouseLeaveEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new MouseEvent('mouseleave', {
    view: window,
    bubbles: true,
    cancelable: true,
    altKey: true, // treat
  });
  element.dispatchEvent(event);
};
export const sendMouseBlurEvent = (element?: Element | null) => {
  if (!element) return;
  const event = new MouseEvent('blur', {
    view: window,
    bubbles: true,
    cancelable: true,
    altKey: true, // treat
  });
  element.dispatchEvent(event);
};
