import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React, { useEffect, useRef } from 'react';
import { Bookmark } from 'src/editor/component/bookmark';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import type { NextBlock } from 'src/redux/types';
import { useTextareaPlaceHolder } from 'src/services/app';
import { getLinkInfo } from 'src/utils/embed';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

export const BookmarkNode: MindNodeElement = React.memo((props) => {
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(props.id);
  const updateSegments = useThrottleUpdateSegments(props.id, 'caption');
  const block = usePickBlock(props.id, ['data', 'local']);
  const transaction = useTransaction();
  const readOnly = useReadonly(props.id);
  const containerRef = useRef<HTMLDivElement>(null);
  useMonitorFocused(containerRef, props.id, 'caption');

  useEffect(() => {
    if (!block?.data.link) return;
    // if (
    //   block.data.linkInfo ||
    //   block.data.cover ||
    //   block.data.description ||
    //   block.data.icon?.value ||
    //   block.data.headers
    // ) {
    //   return;
    // }

    void getLinkInfo(block.data.link).then((info) => {
      if (!info) return;
      const data: NextBlock['data'] = {
        link: info.link,
        linkInfo: textToSegments(info.title),
        cover: info.cover,
        icon: {
          type: 'http',
          value: info.icon,
        },
        description: textToSegments(info.description),
      };
      if (readOnly) {
        updateBlock(
          block.uuid,
          {
            data,
          },
          true
        );
      } else {
        transaction(() => {
          updateBlock(block.uuid, { data });
        });
      }
    });
  }, [block?.data, block?.uuid, readOnly, transaction]);

  if (!block) return null;

  return (
    <NodeWrapper
      id={props.id}
      level={props.level}
      ref={containerRef}
      className="min-w-[480px] px-0"
    >
      <div className="w-full">
        <MindMapRichText
          placeholder={placeHolder}
          uuid={props.id}
          alwaysShowPlaceholder={alwaysShowPlaceHolder}
          className="whitespace-pre-wrap break-words mx-2"
          segments={block.data.caption}
          segmentType="caption"
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
        />
        <Bookmark
          readonly={readOnly}
          title={segmentsToText(block.data.linkInfo)}
          cover={block.data.cover}
          description={segmentsToText(block.data.description)}
          icon={block.data.icon?.value}
          url={block.data.link}
          onUrlInput={(url) => {
            void getLinkInfo(url).then((info) => {
              transaction(() => {
                updateBlock(block.uuid, {
                  data: {
                    link: info?.link ?? url,
                    linkInfo: textToSegments(info?.title),
                    cover: info?.cover,
                    icon: {
                      type: 'http',
                      value: info?.icon,
                    },
                    description: textToSegments(info?.description),
                    headers: info?.headerMap,
                  },
                });
              });
            });
          }}
          defaultOpenInputPanel={Boolean(block.local)}
          loading={false}
        />
      </div>
    </NodeWrapper>
  );
});
