import type { FC, ReactNode } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';
import { LOCAL_KEY_CREATE_FORM } from 'src/common/const';
import { PRODUCT_NAME } from 'src/const/title';
import { useOnlyExternalSpace } from 'src/hooks/space/use-external-space';
import { ViewPath } from 'src/utils';
import { setLocalStorage } from 'src/utils/local-storage';
import { getLocationOrigin } from 'src/utils/location-utils';

export const FormErrorPageView: FC<{ icon: string; description: ReactNode }> = ({
  icon,
  description,
  children,
}) => {
  const onlyExternalSpace = useOnlyExternalSpace();
  return (
    <div className="flex flex-col h-[480px]">
      <div className="flex flex-col items-center justify-center flex-1">
        <IconWidget emojiStr={icon} disableHover iconSize={70} />
        <div className="text-t1-medium mt-2.5">{description}</div>
        {children}
      </div>
      <a
        hidden={__PRIVATE__}
        href={onlyExternalSpace ? `${getLocationOrigin()}${ViewPath.create}` : location.origin}
        onClick={() => setLocalStorage(LOCAL_KEY_CREATE_FORM, 'true')}
        className="inline-flex items-center justify-center border-b border-black mx-auto flex-shrink-0 mb-10 cursor-pointer"
        rel="noreferrer"
      >
        <IconWidget emojiStr="👉" disableHover iconSize={14} />
        &nbsp;使用 {PRODUCT_NAME} 免费创建收集表&nbsp;
        <IconWidget emojiStr="👈" disableHover iconSize={14} />
      </a>
    </div>
  );
};
