import { cx } from '@flowus/common/cx';
import type { FC, MutableRefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useSize } from 'src/common/utils/use-size';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { useGetPageId } from 'src/utils/getPageId';
import { useFocusTable } from './hook';
import type { TableProps } from './types';

export const TableMenu: FC<TableProps> = ({ tableRef, tableId }) => {
  const hidden = !useFocusTable(tableId);
  const { width = -9999 } = useSize(tableRef.current, 'width');
  const [containerWidth, setContainerWidth] = useState(0);
  const transaction = useTransaction();
  const openModal = useOpenModal();
  const tableMenuRef = useRef() as MutableRefObject<HTMLDivElement>;
  const pageId = useGetPageId();
  const pageFixedWidth = useObservableBlock(pageId, (block) => block?.data.pageFixedWidth);

  useEffect(() => {
    const fn = () => {
      const container = document.querySelector(`[data-block-id="${tableId}"]`);

      setContainerWidth(container?.clientWidth ?? -9999);
    };

    // 不加 100，开关页面宽度时取到的宽度总是页面变化之前的宽度
    setTimeout(fn, 100);

    window.addEventListener('resize', fn);

    return () => {
      window.removeEventListener('resize', fn);
    };
  }, [tableId, pageFixedWidth]);

  const left = (width > containerWidth ? containerWidth : width) - 88;

  return (
    <div
      data-no-cancel-selected
      className={cx(
        'absolute z-20 flex -top-8 h-8 items-center next-modal transition-opacity',
        hidden ? 'opacity-0 pointer-events-none' : 'opacity-100'
      )}
      style={{ left }}
    >
      <Tooltip
        popup="自适应宽度"
        offset={[0, 8]}
        onClick={(event) => {
          event.stopPropagation();
          if (hidden) return;
          const format = cache.blocks[tableId]?.data.format ?? {};
          const { tableBlockColumnFormat = {}, tableBlockColumnOrder = [] } = format;
          const rowNums = tableBlockColumnOrder.length;

          bizTracker.event('simpletable_width');

          transaction(() => {
            updateBlock(tableId, {
              data: {
                format: {
                  tableBlockColumnFormat: tableBlockColumnOrder.reduce((tableFormat, recordId) => {
                    tableFormat[recordId] = {
                      ...tableBlockColumnFormat[recordId],
                      width: (containerWidth - 28) / rowNums,
                    };
                    return tableFormat;
                  }, {} as typeof tableBlockColumnFormat),
                },
              },
            });
          });
        }}
        className="flex w-9 items-center justify-center border-r border-black_006 animate-click"
      >
        <Icon name="IcTableFitWidth" size="middle" />
      </Tooltip>
      <div
        ref={tableMenuRef}
        onClick={(event) => {
          event.stopPropagation();
          if (hidden) return;
          openModal.dropdown({
            modalId: Modals.TABLE_HEADER_MENU_LIST,
            popcorn: tableMenuRef.current,
            placement: 'bottom',
            offset: [0, 15],
            mask: false,
            content() {
              return <TableMenuList tableId={tableId} />;
            },
          });
        }}
        className="flex w-[50px] items-center justify-center animate-click"
      >
        <Icon name="MIcTableHeader" size="middle" />
        <Icon name="IcArrowDown01" size="xxxsmall" className="text-grey4 ml-1.5" />
      </div>
    </div>
  );
};

const TableMenuList = ({ tableId }: { tableId: string }) => {
  const format = useObservableBlock(tableId, (block) => block?.data?.format ?? {});
  const transaction = useTransaction();

  const items = [
    {
      type: ListItemType.TEXT_SWITCH,
      data: {
        title: '标题行',
        switch: format.tableBlockRowHeader,
        icon: 'IcTableHeaderRow',
        noCancelSelected: true,
        onSwitch: (status: boolean) => {
          bizTracker.event('simpletable_titlerow', { openclose: status ? 'open' : 'close' });
          transaction(() => {
            updateBlock(tableId, {
              data: { format: { tableBlockRowHeader: status } },
            });
          });
        },
      },
    },
    {
      type: ListItemType.TEXT_SWITCH,
      data: {
        title: '标题列',
        switch: format.tableBlockColumnHeader,
        icon: 'IcTableHeaderColumn',
        noCancelSelected: true,
        onSwitch: (status: boolean) => {
          bizTracker.event('simpletable_titlecolumn', { openclose: status ? 'open' : 'close' });
          transaction(() => {
            updateBlock(tableId, {
              data: { format: { tableBlockColumnHeader: status } },
            });
          });
        },
      },
    },
  ];

  return <ListView className="py-[5px] rounded-r w-60 next-modal" items={items} />;
};
