import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import type { IconName, IconSizeStyle } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import type { ComponentProp } from 'src/common/components/icon/types';

interface Props extends ComponentProp {
  checked?: boolean;
  className?: string;
  size?: keyof typeof IconSizeStyle;
  name?: IconName;
  checkbox2?: boolean;
}

export const Checkbox: FC<Props> = (props: Props) => {
  const { checked, className, size = 'middle', name, checkbox2, ...reset } = props;

  return (
    <Icon
      size={size}
      className={cx('todo', checked ? 'text-active_color' : 'dark:text-white', className)}
      name={
        name ??
        (checked
          ? checkbox2
            ? 'IcCheckbox2Check'
            : 'IcCheckboxCheck'
          : checkbox2
          ? 'IcCheckbox2'
          : 'IcCheckbox')
      }
      {...reset}
    />
  );
};
