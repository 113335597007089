import { cache } from 'src/redux/store';
import type { DropInfoState } from 'src/services/app/hook/use-drop-info';
import { getDropInfo, setDropInfo } from 'src/services/app/hook/use-drop-info';
import type { CursorArea } from './types';
import { Aside, DRIVE, MainPanel, RightPanel } from './types';

export const ID_TOC_CONTAINER = 'toc-container';
export const ID_ASIDE_PRIVATE = 'aside-private';
export const ID_ASIDE_SPACE = 'aside-space';
export const ID_ASIDE_FAVORITE = 'aside-favorite';
export const ID_ASIDE_SHARE = 'aside-share';
export const ID_HOVER_MENU = 'hoverMenu';
export const ID_HOVER_MENU_CONTAINER = 'hoverMenuContainer';
export const ID_RECORD_HOVER_MENU = 'recordHoverMenu';
export const ID_DRIVE = 'drive';

export const TOC_PRIVATE = 'TOC_PRIVATE';

const getRectById = (id: string) => {
  return document.getElementById(id)?.getBoundingClientRect();
};

const yIsInRectById = (y: number, id: string) => {
  const rect = getRectById(id);
  return rect && y >= rect.top && y <= rect.bottom;
};

export const cursorIsInAside = (x: number) => {
  return x >= 0 && x < (getRectById(ID_TOC_CONTAINER)?.right ?? -1);
};

export const isTocArea = (cursorArea: CursorArea) =>
  cursorArea === Aside.FAVORITE ||
  cursorArea === Aside.SPACE ||
  cursorArea === Aside.SHARE ||
  cursorArea === Aside.PRIVATE;

export const isAsideArea = (cursorArea: CursorArea) =>
  isTocArea(cursorArea) || cursorArea === Aside.BOTTOM;

export const cursorInRightPanelArea = (cursorArea: CursorArea) =>
  cursorArea === RightPanel.BOTTOM ||
  cursorArea === RightPanel.LEFT ||
  cursorArea === RightPanel.MIDDLE ||
  cursorArea === RightPanel.RIGHT;

export const isLeftRight = (cursorArea: CursorArea) =>
  cursorArea === MainPanel.LEFT ||
  cursorArea === MainPanel.RIGHT ||
  cursorArea === RightPanel.LEFT ||
  cursorArea === RightPanel.RIGHT;

export const isInRightPanel = (x: number) => {
  const rightPanelLeft =
    (document.querySelector('.right-panel') as HTMLElement | null)?.getBoundingClientRect().left ??
    0;
  return rightPanelLeft > 0 && x >= rightPanelLeft;
};

export const getPanel = (isRight: boolean): 'main-panel' | 'right-panel' => {
  return `${isRight ? 'right' : 'main'}-panel`;
};

export const getCursorArea = (x: number, y: number, isRight: boolean): CursorArea => {
  const DEFAULT = isRight ? RightPanel.MIDDLE : MainPanel.MIDDLE;

  if (cursorIsInAside(x)) {
    const privateTocRect = getRectById(ID_ASIDE_PRIVATE);
    if (privateTocRect && y > privateTocRect.bottom) {
      return Aside.BOTTOM;
    }

    if (yIsInRectById(y, ID_ASIDE_FAVORITE)) {
      return Aside.FAVORITE;
    }

    if (yIsInRectById(y, ID_ASIDE_SPACE)) {
      return Aside.SPACE;
    }

    if (yIsInRectById(y, ID_ASIDE_SHARE)) {
      return Aside.SHARE;
    }

    if (yIsInRectById(y, ID_ASIDE_PRIVATE)) {
      return Aside.PRIVATE;
    }
  } else {
    const drive = getRectById(ID_DRIVE);
    if (drive) {
      return DRIVE.ALL;
    }

    const pageFooter = document.querySelector(
      `.${getPanel(isRight)} .page-footer`
    ) as HTMLElement | null;
    const pageFootRect = pageFooter?.getBoundingClientRect();
    if (pageFootRect && y > pageFootRect.top) {
      return isRight ? RightPanel.BOTTOM : MainPanel.BOTTOM;
    }

    const content = document.querySelector(
      `.${getPanel(isRight)} .block-content`
    )?.firstElementChild;
    const leftGap = content?.getBoundingClientRect().left ?? 0;
    const rightGap = content?.getBoundingClientRect().right ?? 0;

    const RANGE = 200;

    if (x < leftGap) {
      if (x < leftGap - RANGE) return DEFAULT;
      return isRight ? RightPanel.LEFT : MainPanel.LEFT;
    } else if (x > rightGap) {
      if (x > rightGap + RANGE) return DEFAULT;
      return isRight ? RightPanel.RIGHT : MainPanel.RIGHT;
    }
  }

  return DEFAULT;
};

export const _setDropInfo = (dropInfo?: DropInfoState) => {
  // 为了保留 dragToc 的信息
  const _tocInfo = getDropInfo()?.tocInfo || {};
  setDropInfo(dropInfo ? { ...dropInfo, tocInfo: { ..._tocInfo, ...dropInfo.tocInfo } } : {});
};

// 判断 dropId 是不是 dragging 的块们的子
export const checkIsSub = (id: string, dropId?: string): boolean => {
  return (
    !dropId ||
    id === dropId ||
    !!cache.blocks[id]?.subNodes.some((uuid) => checkIsSub(uuid, dropId))
  );
};
