import type { CSSProperties, FC } from 'react';
import React from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';

export const TextWithIcon: FC<{
  name: IconName;
  text: string;
  className?: string;
  style?: CSSProperties;
  iconConfig?: {
    className?: string;
    style?: CSSProperties;
  };
  textConfig?: {
    className?: string;
    style?: CSSProperties;
  };
  onClick?: (e: React.MouseEvent) => void;
}> = (props) => {
  return (
    <div
      className={`flex flex-row items-center ${props.className}`}
      style={props.style}
      onClick={props.onClick}
    >
      <Icon size="middle" name={props.name} />
      <div className={`ml-2 ${props.textConfig?.className}`} style={props.textConfig?.style}>
        {props.text}
      </div>
    </div>
  );
};
