import { Deferred } from './async';
import { isOssUrl } from './url';
import PQueue from 'p-queue';

const queue = new PQueue({ concurrency: 5 });

export const guessFileName = (url: string) => {
  const queryIndex = url.indexOf('?');
  if (queryIndex > 0) {
    url = url.substring(0, queryIndex);
  }
  if (!url.endsWith('/')) {
    const index = url.lastIndexOf('/') + 1;
    if (index > 0) {
      return url.substring(index);
    }
  }
  return '未知文件名';
};

const loadProxyUrlTasks: Record<string, Promise<Response>> = {};
/** 代理下载 */
export const loadProxyUrl = (proxyUrl: string) => {
  let task = loadProxyUrlTasks[proxyUrl];
  if (!task) {
    const isOss = isOssUrl(proxyUrl);
    task = (async () => {
      const res = await fetch(proxyUrl, {
        method: 'GET',
        referrerPolicy: isOss ? 'strict-origin-when-cross-origin' : 'no-referrer',
      });
      return res;
    })();
  }
  return task;
};

export const getProxyImageUrl = (src: string) => {
  const isHttp = window.location.origin.startsWith('http');
  const proxyUrl = `${
    isHttp ? window.location.origin : 'https://flowus.cn'
  }/api/download/img?url=${encodeURIComponent(src)}`;
  return proxyUrl;
};

interface DownloadFileParams {
  fileName: string;
  blob: Blob;
  url: string;
}

const downloadImageToBlobTasks: Record<string, Promise<DownloadFileParams | undefined>> = {};
export const downloadImageToBlob = async (url: string) => {
  let task = downloadImageToBlobTasks[url];

  if (!task) {
    task = (async () => {
      const isOss = isOssUrl(url);
      const res = await fetch(url, {
        method: 'GET',
        referrerPolicy: isOss ? 'strict-origin-when-cross-origin' : 'no-referrer',
      });

      if (res.status === 200) {
        const fileName = guessFileName(url);
        const blob = await res.blob();
        return { fileName, blob, url };
      }
    })();
  }

  return task;
};

export const getBlobFormatHeic = async (blob: Blob, tryHeic = false) => {
  let resultBlob = blob;

  if (tryHeic) {
    try {
      const heic2anyImport = await import('heic2any');
      const heic2any = heic2anyImport.default;
      const conversionResult = await heic2any({ blob });
      if (Array.isArray(conversionResult)) {
        const [first] = conversionResult;
        if (first) {
          resultBlob = first;
        }
      } else {
        resultBlob = conversionResult;
      }
    } catch {
      // ignore
      return blob;
    }
  }
  return resultBlob;
};

const downLoadImageMap = new Map<string, DownloadFileParams>();
export const getImageBlobByExternalUrl = (
  url: string,
  opt: {
    callback: (params: DownloadFileParams) => void;
    onError?: () => void;
    tryHeic?: boolean;
  }
) => {
  const cacheKey = `${url}-${opt.tryHeic}`;
  const defer = new Deferred();

  const onSuccess = (blockInfo: DownloadFileParams) => {
    downLoadImageMap.set(cacheKey, blockInfo);
    opt.callback(blockInfo);
  };

  const apiLoadImage = () => {
    void queue.add(async () => {
      const res = await loadProxyUrl(getProxyImageUrl(url));
      if (res.status === 200) {
        void res
          .blob()
          .then(async (blob) => {
            const resultBlob = await getBlobFormatHeic(blob, opt.tryHeic);
            const blockInfo = {
              fileName: guessFileName(url),
              blob: resultBlob,
              url,
            };

            onSuccess(blockInfo);
            defer.resolve();
          })
          .catch(() => {
            opt.onError?.();
            defer.resolve();
          });
      } else {
        opt.onError?.();
        defer.resolve();
      }
    });
  };

  if (downLoadImageMap.has(cacheKey)) {
    const blockInfo = downLoadImageMap.get(cacheKey);
    if (blockInfo) {
      onSuccess(blockInfo);
      return;
    }
  }
  void queue.add(() =>
    downloadImageToBlob(url)
      .then(async (blockInfo) => {
        if (blockInfo) {
          const resultBlob = await getBlobFormatHeic(blockInfo.blob, opt.tryHeic);
          const newBlockInfo = {
            ...blockInfo,
            blob: resultBlob,
          };
          onSuccess(newBlockInfo);
          defer.resolve();
        } else {
          apiLoadImage();
        }
      })
      .catch(() => {
        apiLoadImage();
      })
  );

  return defer.promise;
};
