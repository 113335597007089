import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { ICON_MAP } from '../../../const';

export const SelectRelation: FC<{
  collectionId: string;
  propertyId: string;
  closeSelf: () => void;
}> = ({ collectionId, propertyId, closeSelf }) => {
  const updatePropertySchema = useUpdatePropertySchema();
  const allRelations = useObservableStore(
    ({ blocks }) => {
      const collection = blocks[collectionId];
      const allRelations: (CollectionSchema & { propertyId: string })[] = [];
      Object.entries(collection?.data.schema ?? {}).forEach(([propertyId, schema]) => {
        if (schema.type === CollectionSchemaType.RELATION) {
          allRelations.push({
            ...schema,
            propertyId,
          });
        }
      });

      return allRelations;
    },
    [collectionId]
  );

  const items = allRelations.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        icon: ICON_MAP[CollectionSchemaType.RELATION],
        title: item.name,
        propertyId: item.propertyId,
      },
    };
  });

  const handleClick = (relationProperty: string) => {
    const { blocks } = getState();
    const propertySchema = (blocks[collectionId]?.data.schema ?? {})[propertyId] ?? {};
    updatePropertySchema(collectionId, propertyId, {
      ...propertySchema,
      relationProperty,
      targetProperty: 'title',
      targetPropertyType: CollectionSchemaType.TITLE,
      aggregation: undefined,
      showAs: undefined,
    });
    closeSelf();
  };

  return (
    <div className="flex flex-col w-[300px] next-modal-scroll py-2">
      {items.length ? (
        <ListView items={items} onItemClick={(item) => handleClick(item.data.propertyId)} />
      ) : (
        <div className="text-t4 px-2 text-grey3">无法进行汇总，请添加关联列</div>
      )}
    </div>
  );
};
