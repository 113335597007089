import type {
  CollectionFilterGroup,
  CollectionSchema,
  CollectionViewDTO,
} from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { cloneDeep, pick } from 'lodash-es';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { useTransaction } from '../use-transaction';
import { CollectionViewPropertyNames } from './use-create-property';

export const useRemoveProperty = () => {
  const { getState } = useStore();
  const transaction = useTransaction();

  const removeProperty = (collectionId: string, propertyId: string) => {
    const { blocks, collectionViews } = getState();

    const collection = blocks[collectionId];
    if (!collection) return;

    transaction(() => {
      const collectionSchema = collection.data.schema ?? {};

      const propertySchema = collectionSchema[propertyId];
      const needDeleteProperty = new Set([propertyId]);
      if (
        propertySchema?.type === CollectionSchemaType.RELATION &&
        propertySchema.collectionId === collectionId &&
        propertySchema.pairProperty &&
        collectionSchema[propertySchema.pairProperty]?.pairProperty === propertyId
      ) {
        needDeleteProperty.add(propertySchema.pairProperty);
      }

      const newSchema: Record<string, CollectionSchema> = {};
      Object.entries(collectionSchema).forEach(([key, schema]) => {
        if (!needDeleteProperty.has(key)) {
          newSchema[key] = schema;
        }
      });

      updateBlock(collectionId, {
        data: {
          schema: newSchema,
          collectionPageProperties: collection.data.collectionPageProperties?.filter(
            ({ property }) => !needDeleteProperty.has(property)
          ),
        },
      });

      collection.views?.forEach((uuid) => {
        const view = collectionViews[uuid];
        if (!view) return;

        const viewFormat: CollectionViewDTO['format'] = pick(
          view.format,
          CollectionViewPropertyNames
        );

        viewFormat.sorters = view.format.sorters?.filter(
          (sorter) => !needDeleteProperty.has(sorter.property)
        );

        const loop = (filter: CollectionFilterGroup | undefined) => {
          if (!filter) return filter;

          filter.filters = filter.filters.filter((item) => {
            if (item.type === 'filter') {
              return !needDeleteProperty.has(item.property);
            }

            const result = loop(item);
            if (result && result.filters.length > 0) {
              return true;
            }

            return false;
          });

          return filter;
        };
        viewFormat.filter = loop(cloneDeep(view.format.filter));

        CollectionViewPropertyNames.forEach((name) => {
          const properties = viewFormat[name];
          if (properties) {
            viewFormat[name] = properties.filter((item) => !needDeleteProperty.has(item.property));
          }
        });

        CollectionViewManager.update(uuid, {
          format: viewFormat,
        });
      });
    });
  };

  return useCallback(removeProperty, [getState, transaction]);
};
