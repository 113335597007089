import { cx } from '@flowus/common/cx';
import { PayType } from '@flowus/common/utils/pay-info';
import type { PreOrderDTO } from '@next-space/fe-api-idl';
import QRCode from 'qrcode';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { Modals } from 'src/modals';
import { refreshSpaceData } from 'src/services/app/hook/refresh-space';
import { confetti } from 'src/utils/confetti';
import type { PatchState } from 'src/utils/type-utils';

/** 支付二维码 */
export const PayQrModal: FC<{
  orderId: string;
  qrCodeUrl: string;
  qaCodeUrlIsImage?: boolean;
  payType: PayType;
  priceTitle: string;
  changePayType?: () => void;
  onFinish?: () => Promise<void>;
  rePayOrder: () => Promise<PreOrderDTO>;
}> = (props) => {
  const { rePayOrder, priceTitle, payType, changePayType, onFinish, qaCodeUrlIsImage } = props;
  const isAliPay = payType === PayType.alipay;
  const intervalTimer = useRef<NodeJS.Timeout>();
  const qrTimer = useRef<NodeJS.Timeout>();
  const openModal = useOpenModal();
  const waitApi = useRef(false);

  const [state, setState] = useState({
    loading: false,
    qrFailure: false,
    qrCodeImageUrl: '',
    orderId: props.orderId || '',
    initRefresh: false,
  });

  const closeModal = () => {
    openModal.closeModal(Modals.PAY_MODAL);
  };

  // 重新下单
  const rePay = async () => {
    patchState('loading', true);
    qrTimer.current && clearTimeout(qrTimer.current);
    intervalTimer.current && clearInterval(intervalTimer.current);

    const createOrder = await rePayOrder();

    if (!createOrder.orderId) {
      message.warning('刷新失败，请重新下单');
      closeModal();
      return;
    }

    const qr = await QRCode.toDataURL(`${createOrder.qrCodeUrl}`, {
      errorCorrectionLevel: 'H',
    });

    if (intervalTimer.current) {
      intervalTimer.current = setInterval(() => void refreshOrder(`${createOrder.orderId}`), 3000);
    }

    if (qrTimer.current && !qaCodeUrlIsImage) {
      // 倒计时10分钟后二维码失效
      qrTimer.current = setTimeout(() => {
        patchState('qrFailure', true);
        intervalTimer.current && clearInterval(intervalTimer.current);
        qrTimer.current && clearTimeout(qrTimer.current);
      }, 10 * 60 * 1000);
    }

    setState({
      qrCodeImageUrl: qaCodeUrlIsImage ? `${createOrder.qrCodeUrl}` : qr,
      orderId: createOrder.orderId,
      qrFailure: false,
      loading: false,
      initRefresh: false,
    });
  };

  const patchState: PatchState<typeof state> = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  // 刷新订单
  const refreshOrder = async (orderId: string) => {
    if (waitApi.current) {
      return;
    }
    waitApi.current = true;
    if (orderId) {
      const res = await request.infra.getOrderStatus.raw(orderId);
      if (res.code === 200) {
        switch (res.data.status) {
          // 已经支付
          case 20:
            intervalTimer.current && clearInterval(intervalTimer.current);
            qrTimer.current && clearTimeout(qrTimer.current);
            message.success('订单支付成功');
            closeModal();
            await onFinish?.();
            void confetti.fireworks();
            void refreshSpaceData();
            break;
          // 已经取消
          case 30:
            patchState('qrFailure', true);
            message.error('订单失效，请重新下单');
            intervalTimer.current && clearInterval(intervalTimer.current);
            qrTimer.current && clearTimeout(qrTimer.current);
            break;
          default:
        }
      } else {
        intervalTimer.current && clearInterval(intervalTimer.current);
        qrTimer.current && clearTimeout(qrTimer.current);
      }
      patchState('loading', false);
    } else {
      closeModal();
    }
    // eslint-disable-next-line require-atomic-updates
    waitApi.current = false;
  };

  const handleClickRefreshOrder = () => {
    patchState('loading', true);
    patchState('initRefresh', true);
    void refreshOrder(state.orderId);
  };

  useEffect(() => {
    intervalTimer.current = setInterval(() => {
      void refreshOrder(props.orderId);
    }, 3000);

    // 倒计时10分钟后二维码失效
    qrTimer.current = setTimeout(() => {
      patchState('qrFailure', true);
      intervalTimer.current && clearInterval(intervalTimer.current);
      qrTimer.current && clearTimeout(qrTimer.current);
    }, 10 * 60 * 1000);
    if (props.qrCodeUrl) {
      if (qaCodeUrlIsImage) {
        patchState('qrCodeImageUrl', props.qrCodeUrl);
      } else {
        void QRCode.toDataURL(props.qrCodeUrl, { errorCorrectionLevel: 'H' }).then((res) => {
          patchState('qrCodeImageUrl', res);
        });
      }
    }

    return () => {
      // TODO 如果不想关闭弹窗后结束定时器，就不要clearInterval
      // intervalTimer.current && clearInterval(intervalTimer.current);
      qrTimer.current && clearTimeout(qrTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center justify-center py-7">
      <div className="text-h2">
        支付金额：<span className="text-active_color">{priceTitle}</span>
      </div>
      <div className="text-h3 mb-5 flex items-center py-4 font-normal">
        {isAliPay ? (
          <Icon name="IcAlipayPay" size="large" className={'mr-2 text-[#1677FF]'} />
        ) : (
          <Icon name="IcWechatPay" size="large" className={'mr-2 text-[#07C160]'} />
        )}
        请用
        {isAliPay ? '支付宝' : '微信'}
        扫码完成支付
      </div>
      <div className="relative mx-20 mb-5 h-60 w-60">
        {state.qrFailure && (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-black/75">
            <span className="flex cursor-pointer items-center text-white" onClick={rePay}>
              <Icon name="IcMenuRelink" className="mr-2 text-white" size="normal" />
              刷新
            </span>
          </div>
        )}
        <img src={state.qrCodeImageUrl} alt={state.orderId} className="h-full w-full" />
      </div>
      <div className="text-t2-medium flex h-10 items-center justify-center text-grey3">
        {state.qrFailure
          ? '二维码失效，请点击刷新'
          : state.loading
          ? '正在核实订单中...'
          : state.initRefresh
          ? '未查到付款记录'
          : '支付后未跳转？'}
        {(!state.loading || state.qrFailure) && (
          <div className={cx('cursor-pointer underline')} onClick={handleClickRefreshOrder}>
            点我刷新
          </div>
        )}
      </div>
      {changePayType && (
        <div className="my-4 py-2.5">
          <Button
            onClick={() => {
              intervalTimer.current && clearInterval(intervalTimer.current);
              changePayType();
            }}
            className="text-t2-medium"
          >
            更换支付方式
          </Button>
        </div>
      )}
    </div>
  );
};
