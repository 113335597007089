import { pinyin as PinYin, PINYIN_DICT, STYLE_NORMAL } from '@flowus/pinyin';

export type CompareFunc<T> = (a: T, b: T) => number;

export function deriveEqualFunc<T>(cmp: CompareFunc<T>) {
  return (a: T, b: T) => {
    return cmp(a, b) === 0;
  };
}

export function number(a: number, b: number) {
  return a - b;
}

export function string(a: string, b: string) {
  return a.localeCompare(b);
}

export function reversed<T>(cmp: CompareFunc<T>, yesOrNo = true) {
  return yesOrNo
    ? (a: T, b: T) => {
        return -cmp(a, b);
      }
    : cmp;
}

export function nullsFirst<T>(cmp: CompareFunc<T>) {
  return (a: T | null | undefined, b: T | null | undefined) => {
    if (a == null && b == null) return 0;
    if (a == null) return -1;
    if (b == null) return 1;
    return cmp(a, b);
  };
}

export function nullsLast<T>(cmp: CompareFunc<T>) {
  return (a: T | null | undefined, b: T | null | undefined) => {
    if (a == null && b == null) return 0;
    if (a == null) return 1;
    if (b == null) return -1;
    return cmp(a, b);
  };
}

export function combine<T>(...cmps: CompareFunc<T>[]) {
  return (a: T, b: T) => {
    for (const cmp of cmps) {
      const r = cmp(a, b);
      if (r !== 0) return r;
    }
    return 0;
  };
}

export function array<T>(cmp: CompareFunc<T> = poly) {
  return (a: T[], b: T[]) => {
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;
    for (let i = 0; i < a.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const r = cmp(a[i]!, b[i]!);
      if (r !== 0) return r;
    }
    return 0;
  };
}

export function infArray<T>(cmp: CompareFunc<T | undefined> = poly) {
  return (a: T[], b: T[]) => {
    const length = Math.max(a.length, b.length);
    for (let i = 0; i < length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const r = cmp(a[i]!, b[i]);
      if (r !== 0) return r;
    }
    return 0;
  };
}

export function iterable<T>(cmp: CompareFunc<T> = poly) {
  return (a: Iterable<T>, b: Iterable<T>) => {
    const iterA = a[Symbol.iterator]();
    const iterB = b[Symbol.iterator]();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
    while (true) {
      const rA = iterA.next();
      const rB = iterB.next();
      if (rA.done && rB.done) {
        return 0;
      }
      if (rA.done) {
        return -1;
      }
      if (rB.done) {
        return 1;
      }

      const r = cmp(rA.value, rB.value);
      if (r !== 0) return r;
    }
  };
}

export function poly(a: any, b: any): number {
  if (Array.isArray(a) && Array.isArray(b)) {
    return array(poly)(a, b);
  }
  if (a === b) return 0;
  return a < b ? -1 : 1;
}

export function by<P, Q>(map: (p: P) => Q, cmp: CompareFunc<Q> = poly) {
  return (a: P, b: P) => {
    return cmp(map(a), map(b));
  };
}

// #region pinyin
enum CharType {
  NUMBER,
  LETTER,
  HAN,
  OTHER,
}

function* iterKey(text: string) {
  const len = text.length;
  for (let i = 0; i < len; i++) {
    const c = text.charAt(i);
    if (/^[0-9]$/.test(c)) {
      yield [CharType.NUMBER, c];
    } else if (/^[a-zA-Z]$/.test(c)) {
      yield [CharType.LETTER, [c.toLowerCase(), c]];
    } else if (PINYIN_DICT[c.charCodeAt(0)]) {
      const [py] = PinYin.single_pinyin(c, { style: STYLE_NORMAL });
      yield [CharType.HAN, [py.charAt(0), py, c]];
    } else {
      yield [CharType.OTHER, c.charCodeAt(0)];
    }
  }
}

export const pinyin = (t1: string, t2: string) => {
  return iterable(poly)(iterKey(t1), iterKey(t2));
};
// #endregion
