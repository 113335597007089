import { cx } from '@flowus/common/cx';
import { PayType } from '@flowus/common/utils/pay-info';
import type { FC } from 'react';
import { memo } from 'react';
import { publicLink } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { getDateUnit } from 'src/components/pay-cycle-button';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { SelectUpgradePlanProps } from '../common';
import { UpgradeCouponList, UpgradeLabel, UpgradePaddingUiKit } from '../common';
import { UpgradeComponents } from '../upgrade-components';
import { UpgradePersonalPlanContext, useUpgradePersonalPlanContext } from './hook';

// #region 壳子
export const UpgradePersonalPlan: FC<SelectUpgradePlanProps> = (props) => {
  return (
    <UpgradePersonalPlanContext {...props}>
      {__BUILD_IN__ ? <UpgradePersonalContentBuildIn /> : <UpgradePersonalContent />}
    </UpgradePersonalPlanContext>
  );
};
// #endregion

// #region Flowus
// #region 核心
export const UpgradePersonalContent: FC = () => {
  const {
    lastDate,
    curPlan,
    curPlanIsYear,
    isStuDiscountCash,
    couponList,
    isCycle,
    patchState,
    close,
    state,
  } = useUpgradePersonalPlanContext();

  return (
    <>
      {/* 用户信息 */}
      <UpgradeComponents.Header close={close} />
      <UpgradeComponents.BODY className="mt-2.5">
        {/* 标题 */}
        <Title />
        {/* 套餐列表 */}
        <PlanList />
        {/* 购买后的失效日期 */}
        <UpgradeComponents.PlanEndTime endTime={lastDate}>
          {isCycle && (
            <>
              ，次{curPlanIsYear ? '年' : '月'} {getCurrencySymbols(curPlan?.currentPriceUnit)}
              {/* @ts-ignore idl */}
              {curPlan?.renewPrice} 续费，随时取消。
            </>
          )}
          {isStuDiscountCash && <>，教育优惠专享价格</>}
        </UpgradeComponents.PlanEndTime>
        {/* 联合优惠活动 */}
        <UpgradeComponents.UnionCouponDesc
          className="mx-8 my-2.5"
          coupon={couponList.find((i) => i.id === state.selectCoupon)}
        />
        {/* 优惠券 */}
        <UpgradeCouponList
          onSelectId={(id) => patchState('selectCoupon', id)}
          couponList={couponList}
          selectId={state.selectCoupon}
          className={'px-8 my-2.5'}
        />
        {/* 支付方式 */}
        <PayList />
      </UpgradeComponents.BODY>
      {/* 付费 */}
      <PayBar />
    </>
  );
};
// #endregion

// #region 标题区域
const Title: FC = memo(() => {
  const { currentSpace, selectPlanSpaceType, upgradePlanName } = useUpgradePersonalPlanContext();
  // 续费
  const isRenew = currentSpace.planType === selectPlanSpaceType;
  /** 标题 */
  const title = [isRenew ? '续费' : '升级到', `“${upgradePlanName}”`].join('');
  /** 副标题 */
  const subTitle = [
    isRenew ? '' : '升级',
    upgradePlanName,
    '，单文件大小提升到5G，以及更多高级功能。',
  ];

  return (
    <>
      <UpgradePaddingUiKit height={54} className={cx('text-h2', __BUILD_IN__ ? 'px-0' : 'px-8')}>
        {title}
      </UpgradePaddingUiKit>
      {!__BUILD_IN__ && (
        <UpgradePaddingUiKit height={36} className="text-grey3 text-t3 px-8">
          {subTitle}
        </UpgradePaddingUiKit>
      )}
    </>
  );
});
// #endregion

// #region 套餐列表
const PlanList: FC = memo(() => {
  const { planList, couponList, calculateQuote, patchState, state } = useModel(
    UpgradePersonalPlanContext
  );

  return (
    <UpgradeComponents.SelectPlan
      className={__BUILD_IN__ ? 'px-0' : ''}
      items={planList}
      itemClick={(id) => {
        patchState('planId', id);
        patchState('selectCoupon', undefined);
      }}
      selectId={state.planId}
      itemClassName="w-[180px]"
      itemRender={(item) => {
        const coupon = couponList.find((i) => i.id === state.selectCoupon);
        const label = state.planId === item.id ? coupon?.label : undefined;
        const originalPrice = item.currentPrice || item.originalPrice;
        const originalPriceUnit = item.currentPriceUnit || item.originalPriceUnit;

        const cur = calculateQuote[item.id];
        const price = cur ? cur?.amount : originalPrice;
        const priceUnit = cur ? cur?.amountUnit : originalPriceUnit;

        return (
          <>
            {label && (
              <UpgradeLabel bgColor={state.selectPayType === PayType.irpay ? 'black' : ''}>
                {label}
              </UpgradeLabel>
            )}
            <div className="mb-2.5 text-t2-medium">{item.name}</div>
            <div className="text-h3 space-x-1">
              <span className="text-black">
                {getCurrencySymbols(priceUnit)}
                {price}
              </span>
              <span
                className="text-grey3 line-through text-t2 whitespace-nowrap"
                hidden={!price || !item.originalPrice || price >= item.originalPrice}
              >
                {getCurrencySymbols(item.originalPriceUnit)}
                {item.originalPrice}
              </span>
            </div>
          </>
        );
      }}
    />
  );
});
// #endregion

// #region 支付方式
const PayList: FC = memo(() => {
  const { state, patchState, isCycle } = useUpgradePersonalPlanContext();

  return (
    <UpgradeComponents.PayMethod
      payMethod={state.selectPayType}
      switchPayMethod={(t) => patchState('selectPayType', t)}
      disablePayMethod={isCycle ? [PayType.wxpay] : []}
    />
  );
});
// #endregion

// #region 底部价格区域
const PayBar: FC = memo(() => {
  const { curPlanQuote, payButtonDisable, clickPay, isCycle } = useModel(
    UpgradePersonalPlanContext
  );

  return (
    <UpgradeComponents.PayBar
      price={curPlanQuote?.amount}
      priceUnit={curPlanQuote?.amountUnit}
      disablePay={payButtonDisable}
      onPay={clickPay}
      protocols={isCycle ? [{ link: publicLink.cyclePlan, title: '《自动续费服务协议》' }] : []}
    />
  );
});
// #endregion

const OrderInfo: FC = memo(() => {
  const { upgradePlanName, curPlan, currentCalculateQuote, state } =
    useUpgradePersonalPlanContext();

  const price = `${getCurrencySymbols(curPlan?.currentPriceUnit)}${curPlan?.currentPrice}`;
  const dateUnit = getDateUnit(curPlan?.monthNum);

  return (
    <UpgradeComponents.OrderInfo
      planName={upgradePlanName}
      payType={state.selectPayType}
      className="space-y-2"
      price={currentCalculateQuote?.amount}
      priceUnit={currentCalculateQuote?.amountUnit}
      info={[`${price}/成员/${dateUnit} ✖️ 1 空间成员`]}
      desc={`Billed ${getDateUnit(curPlan?.monthNum, { ly: true })}`}
    />
  );
});

// #endregion

// #region BuildIn
export const UpgradePersonalContentBuildIn: FC = () => {
  const {
    lastDate,
    curPlan,
    curPlanIsYear,
    isStuDiscountCash,
    couponList,
    isCycle,
    close,
    state,
    loading,
    clickPay,
    patchState,
  } = useUpgradePersonalPlanContext();
  const unit = getCurrencySymbols(curPlan?.currentPriceUnit);

  return (
    <div className="flex flex-col">
      {/* 用户信息 */}
      <UpgradeComponents.Header close={close} />
      <UpgradeComponents.BODY className={cx('pt-2.5 px-8 pb-0', loading && 'animate-pulse')}>
        <div className="grid grid-cols-2 gap-x-5 pb-5">
          <div>
            {/* 标题 */}
            <Title />
            {/* 套餐列表 */}
            <PlanList />
            {/* 购买后的失效日期 */}
            <UpgradeComponents.PlanEndTime className="px-0" endTime={lastDate}>
              {isCycle && (
                <>
                  {[
                    `，${curPlanIsYear ? '次年' : '次月'}`,
                    unit,
                    curPlan?.renewPrice,
                    '续费，随时取消。',
                  ].join('')}
                </>
              )}
              {isStuDiscountCash && <>，教育优惠专享价格</>}
            </UpgradeComponents.PlanEndTime>
            {/* 联合优惠活动 */}
            <UpgradeComponents.UnionCouponDesc
              className="mx-8 my-2.5"
              coupon={couponList.find((i) => i.id === state.selectCoupon)}
            />
          </div>
          <div className="space-y-2 bg-grey9 px-6 pb-4">
            <UpgradeComponents.SelectPay
              active={state.selectPayType}
              onChange={(key) => patchState('selectPayType', key)}
            />
            <OrderInfo />
          </div>
        </div>
        <UpgradeComponents.StripePayBar
          protocols={[
            {
              title: 'Marketing Program Participant Terms',
              link: publicLink.marketingProgramParticipantTerms,
            },
          ]}
          onClick={clickPay}
        />
      </UpgradeComponents.BODY>
    </div>
  );
};
// #endregion
