import { Mermaid } from '@flowus/common';
import { sleep } from '@flowus/common/async';
import { CodePreviewFormat } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { checkTargetByHtml } from '@flowus/common/dom';
import { codeLanguageCanView, codePreviewOptions } from '@flowus/common/mermaid/helper';
import html2canvas from '@flowus/html2canvas';
import type { FC, MouseEvent } from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { colors } from 'src/colors';
import { message } from 'src/common/components/message';
import { Select } from 'src/common/components/select';
import { LabelSelectView } from 'src/common/components/select-view';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { ViewModel } from 'src/redux/types';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { usePickBlock } from 'src/utils/pick-block';
import { segmentsToText } from '../../../utils/editor';

const cacheMap = new Map<string, string>();
interface MermaidProps {
  blockId: string;
}
export const MermaidElement: FC<MermaidProps> = memo((props) => {
  const openImagePreview = useOpenImagePreview();
  const ref = useRef<HTMLDivElement>(null);
  const { blockId } = props;
  const block = usePickBlock(blockId, ['data'], ['format', 'viewMode']);
  const language = block?.data.format?.language ?? '';
  const viewMode = block?.data.viewMode ?? ViewModel.card;
  const segmentText = useObservableBlock(blockId, (block) => segmentsToText(block?.data.segments));
  const canView = codeLanguageCanView(language, viewMode);
  const [status, setStatus] = useState(false);

  const handleClick = useCallback(
    async (event: MouseEvent<HTMLDivElement>) => {
      const checkClickTagA = checkTargetByHtml(
        event.target as HTMLElement,
        (dom) => {
          return dom.tagName === 'a';
        },
        'svg'
      );

      if (checkClickTagA) {
        return;
      }

      const dom = ref.current;
      if (!dom || !status) return;
      const isDarkMode = getIsDarkMode();
      const cacheKey = `mermaid-${isDarkMode}-${segmentText}`;
      let _url = '';
      if (cacheMap.has(cacheKey)) {
        _url = cacheMap.get(cacheKey) as string;
      } else {
        message.loading({ content: '正在生成图片', key: cacheKey });
        await sleep(300);
        const canvas = await html2canvas(dom, {
          useCORS: true,
          backgroundColor: isDarkMode ? colors.dark.grey8 : colors.grey8,
          scale: 5,
        });
        _url = canvas.toDataURL('image/png');
        cacheMap.set(cacheKey, _url);
        message.closeMessage(cacheKey);
      }
      openImagePreview({
        uuid: `mermaid-${blockId}`,
        src: _url,
      });
    },
    [blockId, openImagePreview, segmentText, status]
  );

  if (!canView.preview) {
    return null;
  }

  return (
    <div
      ref={ref}
      hidden={!segmentText}
      className={cx('p-4', !canView.code && 'pt-0', status && 'animate-click')}
      onClick={handleClick}
    >
      <Mermaid onRender={setStatus} text={segmentText} />
    </div>
  );
});

export const CodePreviewSelect: FC<{ blockId: string }> = memo((props) => {
  const { blockId } = props;
  const block = usePickBlock(blockId, ['data'], ['format', 'viewMode']);
  const codePreviewView = block?.data.format?.codePreviewFormat ?? CodePreviewFormat.splitView;
  const transaction = useTransaction();

  const onChange = (value: typeof codePreviewView) => {
    transaction(() => {
      updateBlock(blockId, { data: { format: { codePreviewFormat: value } } });
    });
  };

  return (
    <>
      <div className={'h-4 w-px bg-grey4 mx-2.5'} />
      <Select
        className="h-full"
        dropdownClassName="w-32"
        SelectView={LabelSelectView}
        readonly={false}
        options={codePreviewOptions}
        value={codePreviewView}
        onChange={onChange}
      />
    </>
  );
});
