import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import type { FC, RefObject } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_ROW_HEIGHT } from 'src/bitable/const';
import { Avatar } from 'src/common/components/avatar';
import { useSize } from 'src/common/utils/use-size';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useCurrentUserSelector } from 'src/hooks/user';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { cache } from 'src/redux/store';
import type { MemberState } from 'src/redux/types';
import { useMembersStates } from 'src/services/app';
import { isCollection } from 'src/utils/block-type-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { emitter } from '@flowus/common/utils/emitter';
import { useGetPageId } from 'src/utils/getPageId';
import { useIsInRight } from 'src/utils/right-utils';

export const TEN_MINUTES = 10 * 60 * 1000;
const AVATAR_SIZE = 24;
const HOVER_MENU_SIZE = 40; // +号和6个点的宽度
type ShowType = 'page' | 'board' | 'table';

export const MembersCoordinate: FC<{ type?: ShowType }> = React.memo(({ type = 'page' }) => {
  const pageId = useGetPageId();
  const [_, update] = useState(0);
  const anchorRef = useRef<HTMLDivElement>(null);
  const membersStates = useMembersStates();
  const filterStates = useCurrentUserSelector((user) => {
    const now = Date.now();
    return Object.entries(membersStates)
      .filter(([_, item]) => {
        return (
          // 过滤自己，10分钟前的
          now - item.activityTime < TEN_MINUTES && item.userId !== user.uuid && item.present
        );
      })
      .map(([_, item]) => {
        return omit(item, 'activityTime');
      })
      .sort((a, b) => a.userId.localeCompare(b.userId));
  });

  useEffect(() => {
    const interval = setInterval(() => {
      update(Date.now());
    }, TEN_MINUTES);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // 成员在blocks上
  const membersInBlocks = useMemo(() => {
    const result: Record<string, Pick<MemberState, 'userId' | 'pageId' | 'blockId'>[]> = {};
    filterStates.forEach((item) => {
      if (pageId === item.pageId && item.blockId) {
        /* 正常显示在block左侧 */
        if (!result[item.blockId]) {
          result[item.blockId] = [];
        }

        result[item.blockId]?.push(item);
      }
    });
    return result;
  }, [filterStates, pageId]);

  const membersInBlockView = useMemo(() => {
    const result = [];
    for (const [_, memberStates] of Object.entries(membersInBlocks)) {
      result.push(
        ...memberStates.map((item, index) => (
          <Member
            blockId={item.blockId}
            position={index}
            key={`in-block-${item.userId}`}
            userId={item.userId}
            showType={type}
            anchorRef={anchorRef}
          />
        ))
      );
    }
    return result;
  }, [membersInBlocks, type]);

  /** 当多维表打开右侧panel的时候，左边需要显示头像，右边编辑的时候也需要显示头像 */
  const membersInTable = useMemo(() => {
    const result: Record<string, Pick<MemberState, 'userId' | 'pageId' | 'blockId'>[]> = {};
    filterStates.forEach((item) => {
      if (item.blockId) {
        const parentPageId = getOwnerPage(item.pageId);
        const parentPage = cache.blocks[parentPageId ?? ''];
        if (
          parentPageId &&
          pageId === parentPageId &&
          (parentPage?.type === BlockType.COLLECTION_VIEW ||
            parentPage?.type === BlockType.COLLECTION_VIEW_PAGE)
        ) {
          /* 正常显示在block左侧 */
          if (!result[parentPageId]) {
            result[parentPageId] = [];
          }

          result[parentPageId]?.push({
            userId: item.userId,
            pageId: parentPageId,
            blockId: item.pageId,
          });
        }
      }
    });
    return result;
  }, [filterStates, pageId]);

  const membersInTableView = useMemo(() => {
    const result = [];
    for (const [_, memberStates] of Object.entries(membersInTable)) {
      result.push(
        ...memberStates.map((item, index) => (
          <Member
            blockId={item.blockId}
            position={index}
            key={`in-block-${item.userId}`}
            userId={item.userId}
            showType={type}
            anchorRef={anchorRef}
          />
        ))
      );
    }
    return result;
  }, [membersInTable, type]);
  return (
    <>
      {/* 正常显示在block左侧 */}
      {membersInBlockView}
      {membersInTableView}

      <div className="hidden" ref={anchorRef}></div>
    </>
  );
});
interface MemberProps {
  blockId: string;
  position: number;
  userId: string;
  showType: ShowType;
  anchorRef: RefObject<HTMLDivElement>;
}
const Member: FC<MemberProps> = React.memo(({ blockId, position, userId, showType, anchorRef }) => {
  const [hoverId, setHoverId] = useState<string>();
  const isInRight = useIsInRight();
  const user = useUser(userId);
  const userName = useUserName(userId);
  const [coordinateStyle, setCoordinateStyle] = useState<any>();
  const blockListContainer = querySelectorFromMainContent('.block-list', isInRight);
  const size = useSize(blockListContainer, 'height');

  const computeCoordinate = useCallback(
    (showHoverMenu = blockId === hoverId) => {
      const styleInfo = computeStyle(
        anchorRef,
        blockId,
        showHoverMenu,
        showType,
        isInRight,
        position
      );
      setCoordinateStyle(styleInfo?.style);
      return styleInfo;
    },
    [anchorRef, blockId, hoverId, isInRight, position, showType]
  );

  useEffect(() => {
    const con = (hoverId?: string) => {
      setHoverId(hoverId);
      computeCoordinate(blockId === hoverId);
    };
    emitter.on('hoverId', con);
    return () => {
      emitter.off('hoverId', con);
    };
  }, [blockId, computeCoordinate]);

  useEffect(() => {
    const styleInfo = computeCoordinate();
    /**
     * 快速输入再按enter会快速创建block，这时候block还没同步，
     * 但位置信息却同步了，会导致因为找不到该block而style返回undefined。
     * 这里只能稍微延迟更新位置信息
     */
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (!styleInfo) {
      timer = setTimeout(() => {
        computeCoordinate();
      }, 1000);
    }
    let mutationObserver: MutationObserver | undefined;
    if (!styleInfo?.blockListContainer) {
      setCoordinateStyle(undefined);
    } else {
      // 监听这个节点所在的blockList container，如果节点有变化(增删或者位置变了)就会有回调
      mutationObserver = new MutationObserver((mutations, _) => {
        let hasChange = false;
        mutations.forEach((mutation) => {
          switch (mutation.type) {
            case 'childList': {
              mutation.addedNodes.forEach((node) => {
                if (hasChange) return;
                hasChange = hasDataSet(node, blockId, true);
              });
              mutation.removedNodes.forEach((node) => {
                if (hasChange) return;
                hasChange = hasDataSet(node, blockId, true);
              });
              break;
            }
            case 'attributes': {
              if (hasChange) return;
              hasChange = hasDataSet(mutation.target, blockId);
              break;
            }
            default:
          }
        });
        if (hasChange) {
          computeCoordinate();
        }
      });
      mutationObserver.observe(styleInfo.blockListContainer, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
    };
  }, [anchorRef, blockId, isInRight, position, showType, size.height, computeCoordinate]);

  if (!user) return null;
  if (!coordinateStyle) return null;
  if (!blockId) return null;
  return (
    <div
      className={cx('absolute flex transition')}
      style={coordinateStyle}
      data-user-id={user.uuid}
    >
      <Avatar
        className="rounded-full text-white"
        name={userName}
        color={user.backgroundColor}
        icon={{ type: 'upload', value: user.avatar }}
        imgClassName="rounded-full"
      />
    </div>
  );
});

const hasDataSet = (node: Node, blockId: string, includeChild?: boolean) => {
  if (node instanceof HTMLElement) {
    const hasBlockId = (node as any).dataset?.blockId === blockId;
    if (hasBlockId) return true;
    if (includeChild) {
      const blockNode = (node as HTMLElement).querySelector(`[data-block-id="${blockId}"]`) as
        | HTMLElement
        | undefined;
      if (blockNode) return true;
    }
  }
  return false;
};

/**
 * 这里的位置算法都是源自hoverMenu
 */
const computeStyle = (
  anchorRef: RefObject<HTMLDivElement>,
  blockId: string,
  showHoverMenu: boolean,
  type: ShowType,
  isInRight: boolean,
  position: number
) => {
  let firstBlockNode;
  let blockListContainer;
  if (type === 'board' || type === 'table') {
    // board特殊处理
    blockListContainer =
      anchorRef.current?.parentElement?.parentElement?.querySelector('.block-list');
    firstBlockNode =
      anchorRef.current?.parentElement?.parentElement?.querySelector('[data-block-id]');
  } else {
    blockListContainer = querySelectorFromMainContent('.block-list', isInRight);
    if (!blockListContainer) return;
    firstBlockNode = blockListContainer.querySelector('[data-block-id]');
  }
  if (!blockListContainer) return;
  const blockNode = blockListContainer.querySelector(`[data-block-id="${blockId}"]`) as
    | HTMLElement
    | undefined;
  if (!blockNode) return;

  if (!firstBlockNode) return;

  if (type === 'page') {
    const block = cache.blocks[blockId];

    if (!block) return;
    const isRecord = isCollection(cache.blocks[block.parentId]?.type);
    // 由于头像需要跟hoverMenu保持一致的位置，所以只能用它们算位置的代码，待优化
    const blockContentContainer = querySelectorFromMainContent(
      '.block-content',
      isInRight
    ) as HTMLDivElement | null;
    if (!blockContentContainer) return;
    // hoverMenu的算法
    if (blockNode.firstElementChild) {
      const rect = blockNode.firstElementChild.getBoundingClientRect();
      const lineHeight = parseInt(
        getComputedStyle(blockNode.firstElementChild).getPropertyValue('line-height'),
        10
      );
      const paddingTop = parseInt(
        getComputedStyle(blockNode.firstElementChild).getPropertyValue('padding-top'),
        10
      );
      const parent = blockContentContainer.getBoundingClientRect();
      const offsetTop = rect.top - parent.top - AVATAR_SIZE / 2;
      // 假如有n个头像，需要重叠，所以有offsetLeft
      const offsetLeft = rect.left - parent.left - 10 * position - AVATAR_SIZE;
      const parentIsQuote = cache.blocks[block.parentId]?.type === BlockType.QUOTE;
      const style = {
        top: offsetTop + paddingTop + lineHeight / 2, // 因为头像跟hoverMenu高度不一样,所以需要瞎算一通
        // 2是头像距离右边的宽度
        left: offsetLeft - (showHoverMenu ? HOVER_MENU_SIZE + 2 : 2) - (parentIsQuote ? 20 : 0),
      };

      if (isRecord) {
        style.top = offsetTop + DEFAULT_ROW_HEIGHT / 2;
      }
      if (block.type === BlockType.COLLECTION_VIEW) {
        style.top = offsetTop + 15;
      }
      return {
        blockListContainer,
        style: {
          transform: `translate(${style.left}px, ${style.top}px)`,
        },
      };
    }
  } else {
    const offsetLeft = -10 * position - AVATAR_SIZE;
    const offsetTop =
      blockNode.getBoundingClientRect().top -
      firstBlockNode.getBoundingClientRect().top -
      AVATAR_SIZE / 2;
    const style = {
      top: offsetTop + DEFAULT_ROW_HEIGHT / 2,
      left: offsetLeft - (showHoverMenu ? HOVER_MENU_SIZE + 2 : 2), // 2是头像距离右边的宽度
    };
    return {
      blockListContainer,
      style: {
        left: '0px',
        top: '0px',
        transform: `translate(${style.left}px, ${style.top}px)`,
      },
    };
  }
};
