export const elementScrollTo = (element: Element, top: number, bufferTop?: number) => {
  if (typeof element.scrollTo === 'function') {
    if (bufferTop) {
      element.scrollTo({ top: bufferTop, behavior: 'auto' });
    }

    element.scrollTo({ top, behavior: 'smooth' });
  } else {
    element.scrollTop = top;
  }
};
