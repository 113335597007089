import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import type { ModalSchema } from 'src/common/components/next-modal';
import { publicLink } from 'src/common/const';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { ActivitiesListType, useActivity } from 'src/hooks/activities/use-activity';
import { Keyboard, Mousewheel, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

/** 新手指引弹窗 */
export const NoviceGuideModal: FC<ModalSchema.CloseModal> = ({ onCloseModal }) => {
  const updateTask = useUpdateTask();
  const activity = useActivity(ActivityIds.GUIDE_FIRST_TIME_CREATED_SPACE);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOverView, setIsOverView] = useState(false);
  const media = activity?.resourceList || [];

  useEffect(() => {
    if (currentIndex === media.length - 1) {
      setIsOverView(true);
    }
  }, [currentIndex, media.length]);

  const doneActivity = () => {
    onCloseModal();
    if (isOverView) {
      void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_SPACE, ActivitiesListType.basicList, {
        completed: true,
      });
    }
  };

  return (
    <div className="bg-grey9 pb-8 rounded-md">
      <div className="flex justify-between items-center h-20 px-7">
        <a
          href={publicLink.integralRule}
          target="_blank"
          rel="noreferrer"
          className="text-t2 text-grey3 flex items-center"
        >
          <Icon name="IcInviteInfo" className="mr-1" size="middle" />
          积分规则
        </a>
        <span className="text-h2">
          观看完成可领积分&nbsp;<span className="text-active_color">+{activity?.score}</span>
        </span>
        <span className="text-t2 cursor-pointer" onClick={() => doneActivity()}>
          关闭
        </span>
      </div>
      <div className="px-20 relative">
        <Icon
          name="MIcBtnPre"
          size="auto"
          className={cx(
            'swiper-pre absolute top-1/2 z-10 w-16 h-16 animate-click left-11',
            currentIndex === 0 && 'opacity-30 pointer-events-none'
          )}
        />
        <Icon
          name="MIcBtnNext"
          size="auto"
          className={cx(
            'swiper-next absolute top-1/2 z-10 w-16 h-16 animate-click right-11',
            media.length - 1 === currentIndex && 'opacity-30 pointer-events-none'
          )}
        />
        <Swiper
          navigation={{
            nextEl: '.swiper-next',
            prevEl: '.swiper-pre',
          }}
          mousewheel
          className="max-h-[560px] h-[60vh] w-[896px] mb-2.5 rounded-xl border border-black_006"
          slidesPerView="auto"
          keyboard={{ enabled: true, onlyInViewport: true }}
          modules={[Keyboard, Mousewheel, Navigation]}
          onActiveIndexChange={(swiper) => setCurrentIndex(swiper.realIndex)}
        >
          {media.map((item) => (
            <SwiperSlide
              key={item.content}
              className="bg-no-repeat bg-contain bg-center"
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            />
          ))}
        </Swiper>
        <div className="mb-4 flex justify-center">
          {media.map((_, index) => (
            <span
              key={index}
              className={cx(
                'h-2 w-2 rounded-full mx-1',
                index === currentIndex ? 'bg-active_color' : 'bg-grey5'
              )}
            />
          ))}
        </div>
      </div>
      <div className="px-20 text-xl min-h-[56px] flex items-center justify-center">
        {media[currentIndex]?.content}
      </div>
      <Button
        disable={!isOverView}
        onClick={() => doneActivity()}
        colorType="active"
        size="large"
        className="w-96 mt-6 mx-auto"
      >
        完成
      </Button>
    </div>
  );
};
