/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import type { NextBlock } from '@flowus/common/block/type';
import type { CommonOptionsType } from './types';
import { useObservableStore } from './use-obs-store';

// #region useObservableBlock
export function useObservableBlock(id?: string): NextBlock | undefined;
export function useObservableBlock<T>(id?: string, selector?: (b?: NextBlock) => T): T;
export function useObservableBlock<T>(id?: string, selector?: (b?: NextBlock) => T, dep?: any[]): T;
export function useObservableBlock<T>(
  id?: string,
  selector?: (b?: NextBlock) => T,
  dep?: any[],
  opt?: CommonOptionsType
): T;
export function useObservableBlock<T>(
  id = '',
  selector?: (b?: NextBlock) => T,
  dep?: any[],
  opt?: CommonOptionsType
) {
  return useObservableStore(
    (state) => {
      if (selector) {
        return selector(state.blocks[id]);
      }
      return state.blocks[id];
    },
    [id, dep],
    opt
  );
}
// #endregion
