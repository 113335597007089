import type { CSSProperties } from 'react';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsFreeSpaceLimit } from '../space/use-is-pro-space';
import { PageFontFamily } from '@flowus/common/const';

/** 获取页面字体以及布局信息 */
export const usePageLayout = (pageId: string) => {
  const page = usePickBlock(pageId, ['data'], ['format']);
  const format = page?.data.format;
  const spaceId = page?.spaceId;
  const isFreeSpaceLimit = useIsFreeSpaceLimit(spaceId);
  const pageLayout = format?.pageLayout ?? 'default';
  const fontFormat = format?.fontFormat ?? 'default';
  // 如果以前付费过但后面没续费，则还是用默认的字体
  if (isFreeSpaceLimit) {
    return {
      pageLayout: 'default' as typeof pageLayout,
      fontFormat: 'default' as typeof fontFormat,
    };
  }
  return { pageLayout, fontFormat };
};

export const usePageLayoutStyle = (pageId: string) => {
  const { fontFormat, pageLayout } = usePageLayout(pageId);
  const pageLayoutClassName: string[] = [fontFormat];
  const fontStyle: CSSProperties = {};
  if (pageLayout === 'compact') {
    pageLayoutClassName.push('line-compact');
  } else if (pageLayout === 'loose') {
    pageLayoutClassName.push('line-loose');
  }
  if (fontFormat === 'lxgwWenKai') {
    fontStyle.fontFamily = PageFontFamily.lxgwWenKai;
  } else if (fontFormat === 'sourceHanSerif') {
    fontStyle.fontFamily = PageFontFamily.sourceHanSerif;
  } else if (fontFormat === 'loraVariable') {
    fontStyle.fontFamily = PageFontFamily.lora;
  } else if (fontFormat === 'dmMono') {
    fontStyle.fontFamily = PageFontFamily.DMMono;
  }

  return { fontStyle, pageLayoutClassName: pageLayoutClassName.join(' ') };
};
