import { BlockStatus } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { batch } from 'react-redux';
import { request } from 'src/common/request';
import { DESTROY_BLOCK } from 'src/redux/actions';
import { cleanupColumn } from 'src/redux/managers/block/cleanup-column';
import { updateBlock } from 'src/redux/managers/block/update';
import * as FavoriteManager from 'src/redux/managers/favorite';
import { dispatch, getState } from 'src/redux/store';
import type { PageMeta } from 'src/services/page-metas';
import { updatePageMetas } from 'src/services/page-metas';
import { $currentUserCache } from 'src/services/user/current-user';
import { isPageLike } from 'src/utils/block-type-utils';
import { useCurrentSpace } from '../space';
import { useTransaction } from '../use-transaction';
import { isFavoritePage } from '../user';

// 彻底删除
export const useDeletePageCompletely = () => {
  const transaction = useTransaction();
  const currentSpace = useCurrentSpace();

  const deletePage = async (uuids: string | string[]) => {
    const { blocks } = getState();

    const ids = Array.isArray(uuids) ? uuids : [uuids];
    const [first] = ids;
    if (!first) return;

    const objs: Record<string, PageMeta> = {};
    batch(() => {
      transaction(() => {
        ids.forEach(
          (id) => {
            objs[id] = { tag: 'NOT_FOUND' };
            const loop = (subId: string) => {
              const subBlock = blocks[subId];
              updateBlock(subId, { status: BlockStatus.DELETED_COMPLETE }, true);
              // 如果是子页面，更新 pageMeta
              if (subBlock && isPageLike(subBlock.type)) {
                updatePageMetas({ [subId]: { tag: 'NOT_FOUND' } });
              }
              // 如果子页面是收藏页面，就要取消收藏
              if (isFavoritePage(subId)) {
                transaction(() => {
                  FavoriteManager.remove(subId, {
                    parentId: $currentUserCache.currentSpaceViewId,
                  });
                });
              }
              blocks[subId]?.subNodes.forEach(loop);
            };
            loop(id);
            const block = blocks[id];
            if (!block) return;
            cleanupColumn(block.uuid, block.parentId);
          },
          { noThrottle: true }
        );
      });
    });

    await request.infra.clearTrash(currentSpace.uuid, { blockIds: ids });

    updatePageMetas(objs);
  };

  return useCallback(deletePage, [currentSpace.uuid, transaction]);
};

// 本地移除，目前用于权限变更(根据上面的代码改的)
export const useRemoveLocalBlock = () => {
  return useCallback((uuid: string) => {
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;
    const loop = (subId: string) => {
      dispatch(DESTROY_BLOCK({ uuid: subId }));
      blocks[subId]?.subNodes.forEach(loop);
    };
    loop(uuid);
  }, []);
};
