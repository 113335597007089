import { getTagTextColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { css } from 'otion';
import type { FC, ReactNode } from 'react';
import type { MetaColor } from 'src/bitable/const';
import { COLORS } from 'src/bitable/const';
import { Icon } from 'src/common/components/icon';
import { ColorImage } from 'src/components/color-picker';

interface ColorSelectorProps {
  value?: string;
  bgColorFn?: (colorKey: string) => string | undefined;
  selectedIcon?: ReactNode;
  onSelect: (color: string) => void;
  colors?: MetaColor[];
  hideHeader?: boolean;
  header?: string;
  className?: string;
}

export const ColorSelector: FC<ColorSelectorProps> = ({
  onSelect,
  value,
  bgColorFn,
  selectedIcon,
  colors = COLORS,
  hideHeader,
  header = '颜色',
  className,
}) => {
  return (
    <>
      {!hideHeader ? (
        <div className="text-t2 text-grey3 pl-3.5 mt-2">{header}</div>
      ) : (
        <div className="h-2" />
      )}
      <div className={cx('grid gap-[3px] px-2 mt-1 grid-cols-5', className)}>
        {colors.map((color) => {
          const selected = value === color.key;

          const icon = selected
            ? selectedIcon || (
                <Icon
                  size="normal"
                  name="IcCheck02"
                  className={cx('text-[18px]', css({ color: getTagTextColor(color.key) }))}
                />
              )
            : undefined;

          return (
            <ColorImage
              selected={selected}
              icon={icon}
              hideIcon={!selected}
              isBgColor
              bgColor={bgColorFn ? bgColorFn(color.key) : undefined}
              key={color.key}
              colorkey={color.key}
              desc={color.name}
              onClick={() => onSelect(color.key)}
            />
          );
        })}
      </div>
    </>
  );
};
