import { cx } from '@flowus/common/cx';
import { ILLEGAL_TEXT } from '@flowus/common/const-title';
import { MindMapContext } from '@flowus/mind-map';
import type { FC } from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { useNormalizePath } from 'src/hooks/page';
import { $searchParams } from 'src/utils';
import type { PageProps } from './types';

export const DefaultPage: FC<PageProps> = (props) => {
  const { id, ownerBlockId, illegal, className } = props;
  const isInMinMap = !!useContext(MindMapContext);
  const path = useNormalizePath(id);

  const content = (
    <div className="flex text-ellipsis">
      <div className="relative w-full text-ellipsis">
        {illegal ? ILLEGAL_TEXT : props.title}
        <span className="absolute bottom-px left-0 h-px bg-grey5 w-full" />
      </div>
    </div>
  );

  return (
    <div
      className={cx('group relative py-1 flex items-center w-full px-0.5 text-t1-medium', {
        'max-w-[380px] pr-4': isInMinMap,
        'animate-hover': props.onClick,
        className,
      })}
      contentEditable={false}
      onClick={props.onClick}
    >
      <IconTrigger
        trigger={!ownerBlockId} // feat:引用page不支持更改图标
        iconSize={20}
        className="mr-1"
        tooltipClassName="flex-shrink-0"
        hasOverlay={Boolean(ownerBlockId)}
        blockId={id}
        offset={[100, 0]}
        defaultIcon={<BlockDefaultIcon uuid={id} />}
      />
      {/* <span className="mt-px w-full text-ellipsis text-t1-medium underline decoration-grey5 underline-offset-[5px]">
        {illegal ? ILLEGAL_TEXT : props.title}
      </span> */}
      {$searchParams.print ? <NavLink to={path}>{content}</NavLink> : content}
    </div>
  );
};
