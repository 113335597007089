import { useCallback, useRef } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { DateTimePickerExt } from 'src/editor/editor/plugin/date-picker/date-time-picker-ext';

export interface DateRange {
  from: Date;
  to?: Date;
}
export const useOpenDateModal = () => {
  const openModal = useOpenModal();
  // 关闭的时候回调onSelectRangeTime，这里用一个ref把时间范围存起来
  const dateRangeRef = useRef<DateRange>();
  return useCallback(
    (e: React.MouseEvent, onSelectRangeTime: (date: DateRange) => void, date?: DateRange) => {
      openModal.dropdown({
        popcorn: e.target as HTMLElement,
        placement: 'bottom',
        closeBeforeCallBack: () => {
          if (dateRangeRef.current) {
            onSelectRangeTime(dateRangeRef.current);
          }
        },
        content: ({ onCloseModal }) => {
          // 当天0点
          const now = new Date();
          now.setHours(0, 0, 0, 0);
          return (
            <DateTimePickerExt
              includeTime={false}
              showTimeSwitch={false}
              date={date ?? { from: now }}
              selectRangeDate={true}
              closeDatePicker={() => {
                onCloseModal();
              }}
              onChange={(date, _) => {
                dateRangeRef.current = date;
              }}
              onClear={() => {}}
            />
          );
        },
      });
    },
    [openModal]
  );
};
