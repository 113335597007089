import { type CSSProperties, type FC } from 'react';
import { useChartBlockId } from './chart-config-context';
import { useChartFormat, useUpdateChartFormat } from 'src/hooks/chart';
import { chartTypeData } from './data';
import { Tooltip } from '@flowus/common/tooltip';
import { GroupSortType } from '@next-space/fe-api-idl';
import { defaultYAxisProperty } from './y-axis-field';
/** 选择图表类型 */
interface Props {
  style: CSSProperties;
  onClose: () => void;
}
export const SelectChartTypePanel: FC<Props> = (props) => {
  const chartBlockId = useChartBlockId();
  const format = useChartFormat(chartBlockId);
  const updateChartFormat = useUpdateChartFormat();

  return (
    <div
      className="next-modal overflow-auto text-grey4 text-t4 p-4 h-[350px] space-y-2"
      style={props.style}
    >
      {chartTypeData.map((v, index) => {
        return (
          <div key={index}>
            <div>{v.name}</div>
            <div className="flex items-center space-x-3">
              {v.info.map((i, index) => {
                return (
                  <Tooltip key={index} popup={i.tip} delay={{ open: 300 }}>
                    <div
                      className="border w-20 h-12 animate-hover"
                      onClick={() => {
                        props.onClose();
                        if (i.chartMode?.type === 'Number') {
                          updateChartFormat(chartBlockId, {
                            chartMode: i.chartMode,
                            xAxisPropertyId: 'title',
                            yAxisPropertyIds: [defaultYAxisProperty],
                            groupAggregationPropertyId: '',
                          });
                        } else if (i.chartMode?.type === 'Pie') {
                          // 如果超过一个字段就需要重置
                          const moreYPropertyIds = (format?.yAxisPropertyIds?.length ?? 0) > 1;
                          updateChartFormat(chartBlockId, {
                            chartMode: i.chartMode,
                            groupAggregationPropertyId: '',
                            statistics: moreYPropertyIds ? 'property' : 'record',
                            [moreYPropertyIds ? 'yAxisPropertyIds' : '']: [{ propertyId: 'title' }],
                            orderRule: GroupSortType.ASCENDING,
                          });
                        } else {
                          updateChartFormat(chartBlockId, {
                            chartMode: i.chartMode,
                          });
                        }
                      }}
                    >
                      <img className="w-full h-full py-1.5" src={i.image}></img>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
