import { Compare } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { DiscussionDTO } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import { useState } from 'react';
import { useReadonly } from 'src/hooks/page';
import { useAllowCommentInSharePage } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { lookupPageId } from 'src/utils/lookup-page-id';
import { NeedJumpContext } from './context';
import { Discussion, ResolvedDiscussion } from './discussion';

export const enum CommentTabIndex {
  UN_RESOLVE_COMMENT = 0,
  RESOLVE_COMMENT = 1,
}
let defaultIndex = CommentTabIndex.UN_RESOLVE_COMMENT;
// 偷个懒吧
export const setCommentListIndex = (index: CommentTabIndex) => {
  defaultIndex = index;
};
export const resolveIndex = 0;
export const PageResolvedDiscussions = (props: { pageId: string }) => {
  const { pageId } = props;
  const readonly = useReadonly(pageId, false, true);
  const [tabIndex, setTabIndex] = useState(defaultIndex);
  const allowCommentInSharePage = useAllowCommentInSharePage();

  const { unresolvedDiscussions, resolvedDiscussions } = useObservableStore(
    (state) => {
      const filterDiscussions: DiscussionDTO[] = [];
      for (const id in state.discussions) {
        const it = state.discussions[id];
        if (
          it != null &&
          it.status === BlockStatus.NORMAL &&
          lookupPageId(it.parentId, state) === pageId
        ) {
          filterDiscussions.push(it);
        }
      }

      const getResolvedDiscussions = (status: boolean) => {
        const discussions = filterDiscussions.filter((it) => (status ? it.resolved : !it.resolved));
        discussions.sort(Compare.reversed(Compare.by((it) => it.createdAt)));
        return discussions;
      };

      return {
        resolvedDiscussions: getResolvedDiscussions(true),
        unresolvedDiscussions: getResolvedDiscussions(false),
      };
    },
    [pageId],
    { wait: 500, allDiscussions: true }
  );

  const showDiscussions =
    tabIndex === CommentTabIndex.UN_RESOLVE_COMMENT ? unresolvedDiscussions : resolvedDiscussions;
  const showUnResolveDiscussion = tabIndex === CommentTabIndex.UN_RESOLVE_COMMENT;

  return (
    <NeedJumpContext.Provider value={true}>
      <div className="h-full flex flex-col items-stretch">
        <div className="h-[50px] border-b border-b-grey6 text-t2-medium">
          <div className="h-full flex items-center space-x-4 mx-4">
            <span
              className={cx(
                'cursor-pointer',
                showUnResolveDiscussion && 'underline underline-offset-[19.5px]'
              )}
              onClick={() => {
                setTabIndex(CommentTabIndex.UN_RESOLVE_COMMENT);
              }}
            >
              当前评论区
            </span>
            <span
              className={cx(
                'cursor-pointer',
                !showUnResolveDiscussion && 'underline underline-offset-[19.5px]'
              )}
              onClick={() => {
                setTabIndex(CommentTabIndex.RESOLVE_COMMENT);
              }}
            >
              已解决评论
            </span>
          </div>
        </div>

        <div className="flex-1 min-h-0 overflow-y-auto">
          {showDiscussions.length === 0 ? (
            <div className="h-full flex items-center justify-center">
              <div className="text-center">
                <div className="text-[70px] leading-[98px]">👀</div>
                <div className="text-t1 text-grey3 leading-[22px]">
                  {showUnResolveDiscussion ? '没有未解决评论' : '没有已解决评论'}
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              {showDiscussions.map((it) => {
                if (showUnResolveDiscussion) {
                  return (
                    <Discussion
                      showReplyEditor={false}
                      className="px-4"
                      readonly={readonly}
                      key={it.uuid}
                      allowCommentInSharePage={allowCommentInSharePage}
                      draftKey={`PageOpenDiscussion:${it.uuid}`}
                      discussionId={it.uuid}
                    />
                  );
                }
                return (
                  <ResolvedDiscussion
                    hideBlockContext={true}
                    key={it.uuid}
                    discussionId={it.uuid}
                    className="p-[15px] border-b"
                    readonly={readonly}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </NeedJumpContext.Provider>
  );
};
