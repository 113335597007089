import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';

import { BiValueType } from './_abstract';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'src/common/const';

export interface BiDateValue {
  timestamp: number;
  noTimePart: boolean;
}

export class BiDateType extends BiValueType<BiDateValue> {
  fromSegments(segments: SegmentDTO[] | undefined): BiDateValue | undefined {
    if (segments === undefined) return undefined;
    const dateSeg = segments.find(
      (it) => it.type === TextType.DATE || it.type === TextType.DATETIME
    );
    if (dateSeg === undefined) return undefined;
    if (dateSeg.type === TextType.DATE) {
      return {
        timestamp: dayjs(`${dateSeg.startDate}`, DATE_FORMAT).startOf('day').valueOf(),
        noTimePart: true,
      };
    }
    return {
      timestamp: dayjs(`${dateSeg.startDate} ${dateSeg.startTime}`, DATE_TIME_FORMAT)
        .startOf('minute')
        .valueOf(),
      noTimePart: false,
    };
  }

  toSegments(value: BiDateValue): SegmentDTO[] | undefined {
    if (value.noTimePart) {
      return [
        {
          type: TextType.DATE,
          enhancer: {},
          text: '@',
          startDate: dayjs(value.timestamp).format(DATE_FORMAT),
        },
      ];
    }
    return [
      {
        type: TextType.DATETIME,
        enhancer: {},
        text: '@',
        startDate: dayjs(value.timestamp).format(DATE_FORMAT),
        startTime: dayjs(value.timestamp).format(TIME_FORMAT),
      },
    ];
  }

  isNonNullEmpty(_value: BiDateValue): boolean {
    return false;
  }

  compare(value1: BiDateValue, value2: BiDateValue): number {
    const r = value1.timestamp - value2.timestamp;
    if (r === 0) {
      if (value1.noTimePart === value2.noTimePart) return 0;
      if (value1.noTimePart) return -1;
      return 1;
    }
    return r;
  }
}

export const dateType = new BiDateType();
