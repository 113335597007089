import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { useBlock } from 'src/hooks/block/use-block';
import { useReadonly } from 'src/hooks/page';
import { urlFetcher } from 'src/utils/url-fetcher';
import { ColorMap } from '../../buttons/color-button';
import type { PDFAnnotation } from '../../type';
import { AnnotationType } from '../../type';
import { findFirstPoint, isImageAnnotation } from '../../utils';
import { ExportSetting } from './export-setting';
import { SelectColor } from './select-color';
import { useAnnotations } from './use-annotations';

interface Props {
  uuid: string;
  pdfViewer: PDFViewer;
}

export const AnnotationList: FC<Props> = ({ uuid, pdfViewer }) => {
  const openModal = useOpenModal();
  const [filterColor, setFilterColor] = useState<string>();
  const annotationMap = useAnnotations(uuid);
  const readonly = useReadonly(uuid);

  const isEmptyAnnotation = Object.keys(annotationMap ?? {}).length === 0;
  const openFilter = (event: React.MouseEvent) => {
    const colorSet = new Set<string>();
    Object.values(annotationMap ?? {})
      .flat()
      .forEach((annotation) => {
        colorSet.add(annotation.color);
      });

    const items = [
      {
        type: ListItemType.OPERATION,
        data: {
          title: '所有标注',
          onClick: () => setFilterColor(undefined),
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '按颜色筛选',
          hasArrow: true,
          disable: isEmptyAnnotation,
          renderSubMenu: () => {
            return (
              <SelectColor
                filterColor={filterColor}
                setFilterColor={setFilterColor}
                colorOptions={ColorMap.filter((color) => colorSet.has(color.value))}
              />
            );
          },
        },
      },
    ];

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[160px] py-[5px] bg-white4"
            items={items}
            onItemClick={() => onCloseModal()}
          />
        );
      },
    });
  };

  const openMore = (event: React.MouseEvent) => {
    const items = [
      {
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcExportPage',
          title: '导出标注',
          label: (
            <span className="bg-grey6 text-t4 text-grey3 ml-1 rounded-sm px-1.5 py-0.5">限免</span>
          ),
          onClick: () => {
            openModal.modal({
              content: ({ onCloseModal }) => (
                <ExportSetting onCloseModal={onCloseModal} uuid={uuid} />
              ),
            });
          },
        },
      },
    ];

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[180px] py-[5px] bg-white4"
            items={items}
            onItemClick={() => onCloseModal()}
          />
        );
      },
    });
  };

  if (!annotationMap) return null;

  const pageNumbers = Object.keys(annotationMap ?? {}).sort((a, b) => Number(a) - Number(b));

  const category = ColorMap.find((item) => item.value === filterColor)?.name ?? '所有标注';

  return (
    <div className="px-3">
      <div className="text-grey3">
        <div className="flex justify-between items-center">
          <button className="text-t2 h-9 animate-hover flex items-center p-1" onClick={openFilter}>
            {category}
            <Icon name="IcArrowDown01" className="ml-1.5" size="xxxsmall" />
          </button>
          {!readonly && (
            <button className="p-1 animate-hover flex" onClick={openMore}>
              <Icon name="IcMore" size="middle" />
            </button>
          )}
        </div>
      </div>

      {isEmptyAnnotation && <div className="text-center mt-2 text-grey3 text-t2">当前暂无标注</div>}

      {pageNumbers.map((page) => {
        const annotations = annotationMap[page];
        if (!annotations) return null;

        return (
          <React.Fragment key={page}>
            {annotations.map((annotation) => {
              if (annotation.type === AnnotationType.NONE) return null;
              if (annotation.type === AnnotationType.HIGHLIGHT && !annotation.text) return null;

              if (filterColor && annotation.color !== filterColor) return null;
              const { uuid } = annotation;

              return <Annotation uuid={uuid} pdfViewer={pdfViewer} key={uuid} />;
            })}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Annotation: FC<{ uuid: string; pdfViewer: PDFViewer }> = ({ uuid, pdfViewer }) => {
  const block = useBlock(uuid);
  const { pdfAnnotation, localUrl } = block?.data ?? {};
  const { height, width, pageNumber, color, ossName, text, type } = pdfAnnotation ?? {};
  const [imageUrl, setImageUrl] = useState(localUrl ?? ossName);
  const isImage = type && isImageAnnotation(type);
  const containerRef = useRef<HTMLDivElement>(null);
  const isVisible = useRef(false);

  useEffect(() => {
    if (localUrl) {
      setImageUrl(localUrl);
    } else if (!imageUrl) {
      if (ossName && isVisible.current) {
        void urlFetcher
          .fetchDownloadUrl({
            blockId: uuid,
            ossName,
          })
          .then((imageUrl) => {
            setImageUrl(imageUrl);
          });
      }
    }
  }, [localUrl, ossName, uuid, imageUrl]);

  useEffect(() => {
    if (!isImage || !containerRef.current) return;
    if (localUrl) return;

    if (isVisible.current) return;

    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry?.isIntersecting) {
          isVisible.current = true;
          observer.disconnect();

          if (ossName) {
            const imageUrl = await urlFetcher.fetchDownloadUrl({ blockId: uuid, ossName });
            setImageUrl(imageUrl);
          }
        }
      },
      {
        rootMargin: '200px',
      }
    );

    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [isImage, localUrl, ossName, uuid]);

  const handleAnchor = (annotation: PDFAnnotation) => {
    const { pageNumber, point } = findFirstPoint(annotation) ?? {};
    if (!point || !pageNumber) return;

    pdfViewer.scrollPageIntoView({
      pageNumber,
      destArray: [null, { name: 'XYZ' }, point.x, point.y + 5, null],
    });
  };

  if (!pdfAnnotation) return null;

  return (
    <div
      ref={containerRef}
      className="animate-hover p-2 hover:bg-active_color_10"
      onClick={() => handleAnchor(pdfAnnotation as unknown as PDFAnnotation)}
    >
      <p className="text-t2-medium">页面{pageNumber}</p>
      <div
        className={cx('my-2 cursor-pointer rounded', isImage ? 'pl-1.5' : 'p-2 pl-3.5')}
        style={{ background: `linear-gradient(to right, ${color} 6px, var(--white1) 6px)` }}
      >
        {isImage ? (
          (imageUrl?.startsWith('http') || imageUrl?.startsWith('data:')) && (
            <img src={imageUrl} width={width} height={height} className="max-w-[calc(100%-6px)]" />
          )
        ) : (
          <p className="text-t2 line-clamp-3 text-ellipsis whitespace-pre-wrap">{text}</p>
        )}
      </div>
    </div>
  );
};
