import type { FC } from 'react';
import * as Sentry from '@sentry/react';
import { useCallback, useState } from 'react';
import { Button } from 'src/common/components/button';
import { useOpenModal } from 'src/common/components/next-modal';
import { getActivity } from 'src/hooks/activities/use-activity';
import { createActivityModalAnchor } from '../activities/create-activity-modal-anchor';
import { FeatGuideTaskName } from 'src/hooks/activities/activity-ids';
import { useOpenOneTaskModal } from './task-progress';
import { publicLink } from 'src/common/const';
import { Images } from 'src/image';

interface Props {
  onCloseModal: () => void;
  resolve: (value: unknown) => void;
  taskId: string;
}

enum Stage {
  One,
  Two,
  Three,
}

export const FeatGuideStep: FC<Props> = ({ resolve, onCloseModal, taskId }) => {
  const [stage, setStage] = useState(Stage.One);

  const activity = getActivity(taskId);
  const [currentIndex, setIndex] = useState(0);
  if (!activity) return null;

  const resourceList = activity.resourceList ?? [];
  const handleNext = () => {
    if (currentIndex < resourceList.length - 1) {
      setIndex(currentIndex + 1);
    } else {
      setStage(Stage.Three);
    }
  };

  if (stage === Stage.One) {
    return (
      <div className="next-modal w-[360px] rounded p-[30px]">
        <div className="text-t1-medium">
          是否一起学习如何更好的使用
          <br />
          {FeatGuideTaskName[taskId]} 功能吗？
        </div>
        <div className="flex items-center mt-[30px]">
          <Button
            colorType="black"
            onClick={() => {
              onCloseModal();
              resolve(0);
            }}
            className="border-grey5"
          >
            自我探索
          </Button>
          <Button colorType="active" className="ml-2.5" onClick={() => setStage(Stage.Two)}>
            马上行动
          </Button>
        </div>
      </div>
    );
  }

  if (stage === Stage.Two) {
    const imgInfo = activity.resourceList?.[currentIndex];
    return (
      <div className="next-modal w-[320px] rounded p-[30px]">
        <p className="text-t1-medium">{FeatGuideTaskName[taskId]}</p>
        <p className="text-t2 mt-1">{imgInfo?.content}</p>
        <img src={imgInfo?.imgUrl} className="mt-2.5" alt="" />

        <div className="flex items-center mt-[30px]">
          <Button
            colorType="black"
            onClick={() => setIndex(currentIndex - 1)}
            disable={currentIndex === 0}
            className="border-grey5"
          >
            上一步
          </Button>
          <Button colorType="active" className="ml-2.5" onClick={handleNext}>
            {currentIndex === resourceList.length - 1 ? '完成' : '下一步'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="next-modal w-[320px] rounded p-[30px]">
      <p className="text-t1-medium">想要了解更多FlowUs使用方法？</p>
      <p className="text-t2 mt-1">前往「帮助中心」get更多实用小妙招</p>
      <img src={Images.activityHelp} className="mt-2.5" alt="" />

      <div className="flex items-center mt-[30px]">
        <Button colorType="black" onClick={onCloseModal} className="border-grey5">
          稍后了解
        </Button>
        <Button
          colorType="active"
          className="ml-2.5"
          onClick={() => {
            onCloseModal();
            window.open(publicLink.helpCenter);
          }}
        >
          马上出发
        </Button>
      </div>
    </div>
  );
};

export const useOpenFeatGuide = () => {
  const openModal = useOpenModal();
  const openOneTaskModal = useOpenOneTaskModal();

  return useCallback(
    (taskId: string, isFirstTask?: boolean) => {
      return new Promise((resolve) => {
        const activityModalAnchor = createActivityModalAnchor();

        openModal.dropdown({
          popcorn: activityModalAnchor.popcorn,
          placement: 'top-start',
          offset: [-50, 0],
          closeAfterCallBack: async () => {
            activityModalAnchor.remove();

            if (isFirstTask) {
              await openOneTaskModal(taskId);
            }
            resolve(0);
          },
          content: ({ onCloseModal }) => {
            return <FeatGuideStep onCloseModal={onCloseModal} taskId={taskId} resolve={resolve} />;
          },
        });
      }).catch((err) => {
        Sentry.captureException(err);
      });
    },
    [openModal, openOneTaskModal]
  );
};
