import type { PageActivityEditDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';

import { CollectionPropertySubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const CollectionPropertyFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  if (!edit.collectionPropertyData) return null;
  const action = (() => {
    switch (edit.type) {
      case 'collectionPropertyCreated':
        return '创建了属性';
      case 'collectionPropertyChanged':
        return '编辑了属性';
      case 'collectionPropertyDeleted':
        return '删除了属性';
      default:
        throw new Error('Assertion failed');
    }
  })();
  return (
    <FeedTemplate
      edits={[edit]}
      action={action}
      subject={
        <CollectionPropertySubject
          className="p-1 text-t2-medium"
          schema={edit.collectionPropertyData}
          onClick={() => {}}
        />
      }
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};
