import type { useOpenModal } from 'src/common/components/next-modal';
import { bizTracker } from './biz-tracker';

// https://work.weixin.qq.com/kfid/kfc800fc7ef472f8b03
/** 打开微信二维码 */
export const openQiMoor = (model: ReturnType<typeof useOpenModal>) => {
  bizTracker.event('support_clk');
  model.modal({
    content: () => {
      return (
        <div className="p-12 pt-10 flex flex-col items-center">
          <div className="text-h4">请用微信手机端扫码咨询客服</div>
          <img
            className="mt-6 p-3 bg-white-base"
            width="230px"
            height="230px"
            src="https://work.weixin.qq.com/kf/kefu/qrcode?kfcode=kfcdde5f9fac82bd755"
          />
        </div>
      );
    },
  });

  // try {
  //   if (window.qimoChatClick) {
  //     window.qimoChatClick();
  //   } else {
  //     const head = document.querySelector('head');
  //     const qiMoorScript = document.createElement('script');
  //     qiMoorScript.type = 'text/javascript';
  //     qiMoorScript.async = true;
  //     qiMoorScript.src =
  //       'https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=29065810-5665-11ec-9024-1b3601704596&autoShow=false&language=ZHCN';
  //     head?.append(qiMoorScript);

  //     setTimeout(() => {
  //       window.qimoChatClick();
  //     }, 1000);
  //   }
  // } catch {
  //   //
  // }
};
