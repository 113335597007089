import React from 'react';
import { RawPage } from 'src/editor/editor/raw/raw-page';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';

export const PageNode: MindNodeElement = React.memo((props) => {
  const blockId = props.ownerBlockId ?? props.id;
  const block = usePickBlock(props.id, []);

  if (!block) return null;
  return (
    <NodeWrapper id={blockId} className="py-0 bg-transparent" level={props.level}>
      <RawPage id={props.id} ownerBlockId={props.ownerBlockId} />
    </NodeWrapper>
  );
});
