import type { SpaceViewDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { UPDATE_SPACE_VIEW } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';

/** 顾名思义 */
export const useUpdateSpaceView = () => {
  return useCallback((spaceViewId: string, patch: Partial<Omit<SpaceViewDTO, 'uuid'>>) => {
    dispatch(
      UPDATE_SPACE_VIEW({
        uuid: spaceViewId,
        patch,
      })
    );
  }, []);
};
