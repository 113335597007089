import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent, ReactNode } from 'react';
import { Tooltip } from 'src/common/components/tooltip';

interface Props {
  size?: number;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  popup?: ReactNode;
  disabled?: boolean;
}
export const IconUiSizeBox: FC<Props> = ({
  children,
  size = 20,
  className,
  onClick,
  popup,
  disabled,
}) => {
  return (
    <Tooltip
      disabled={disabled}
      popup={popup}
      className={cx(`flex items-center justify-center`, className)}
      onClick={onClick}
      style={{
        width: size,
        height: size,
      }}
    >
      {children}
    </Tooltip>
  );
};
