/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { cx } from '@flowus/common/cx';
import { deepEqual } from '@flowus/common/utils/tools';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, VFC } from 'react';
import { memo, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { OVERSCAN } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { useBitableLoadMoreContext } from 'src/bitable/hooks';
import { BitableLoadMore } from 'src/bitable/load-more';
import { MembersCoordinate } from 'src/editor/editor/uikit/members-coordinate';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useFilterEnabled } from 'src/hooks/collection-view/use-filter-enabled';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useCollectionSearchById } from 'src/services/app';
import { usePageScrollRef, useScrollRef } from 'src/views/main/page-doc/context';
import { AddRecord } from '../../add-record';
import type { CollectionProperty } from '../../bitable-manager/property-list';
import { ListRow } from './list-row';
import { useOpenSubItem, useSubitemStyle } from 'src/hooks/block/use-open-subitem';

interface Props {
  tableGroup?: TableGroupData;
  withoutValidGroup?: boolean;
  embed?: boolean;
  properties: CollectionProperty[];
}

const EmptyBitable: VFC<{ filterEnabled: boolean; groupValue?: string }> = ({
  filterEnabled,
  groupValue,
}) => {
  const { viewId } = useBitable();
  const insertRecord = useInsertRecordUI();
  const searchKeyword = useCollectionSearchById(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);

  const handleClick = () => {
    if (filterEnabled && searchKeyword) {
      void insertRecord({
        viewId,
        where: { last: true },
      });
    }
  };

  return (
    <div
      data-is-empty
      data-group-value={groupValue}
      data-view-id={viewId}
      onClick={handleClick}
      className={cx(
        'group text-t2 relative flex h-[37px] items-center border-b pl-2 text-grey4',
        filterEnabled && searchKeyword && ' cursor-pointer'
      )}
    >
      {hoveringEmpty && (
        <div className="absolute top-0 left-0 h-1 w-full bg-black/30 opacity-0 group-hover:opacity-100"></div>
      )}
      {filterEnabled
        ? searchKeyword
          ? '无记录，点击创建新记录 ，或调整视图筛选设置'
          : '没有找到符合筛选条件的记录'
        : '表格内容为空'}
    </div>
  );
};

export const Body: FC<Props> = memo((props) => {
  const { tableCellWrap } = useBitable();

  if (tableCellWrap) {
    return <DnyHeightList {...props} />;
  }

  return <FixedHeightList {...props} />;
}, deepEqual);

const DnyHeightList = memo<Props>(({ tableGroup, withoutValidGroup, embed, properties }) => {
  const { recordIds } = useBitableLoadMoreContext();
  const { viewId, readonly, collectionId } = useBitable();
  const isFilterEnabled = useFilterEnabled(viewId);
  const scrollContainer = useScrollRef();
  const [container, setContainer] = useState(scrollContainer?.current);
  const openSubitem = useOpenSubItem(collectionId);

  useEffect(() => {
    setContainer(scrollContainer?.current);
  }, [scrollContainer]);

  if (!container) return null;
  return (
    <div className="block-list relative" data-view-id={viewId} data-group-value={tableGroup?.value}>
      <Virtuoso
        customScrollParent={container}
        overscan={OVERSCAN}
        increaseViewportBy={OVERSCAN}
        computeItemKey={(index) => index}
        totalCount={recordIds.length}
        itemContent={(index) => {
          const recordId = recordIds[index];
          if (!recordId) return null;
          return (
            <ListRow
              index={index}
              recordId={recordId}
              tableCellWrap={true}
              groupProperty={tableGroup?.groupProperty}
              groupValue={tableGroup?.value}
              properties={properties}
              level={openSubitem ? 0 : undefined}
              idnumberIndex={index}
            />
          );
        }}
      />

      {recordIds.length === 0 && (
        <EmptyBitable filterEnabled={isFilterEnabled} groupValue={tableGroup?.value} />
      )}

      <BitableLoadMore />

      <AddRecord
        where={{
          after: !withoutValidGroup ? recordIds[recordIds.length - 1] : undefined,
        }}
        groupName={tableGroup?.value}
      />

      {!readonly && !embed && <MembersCoordinate type="table" />}
    </div>
  );
}, deepEqual);

const FixedHeightList = memo<Props>(({ tableGroup, withoutValidGroup, embed, properties }) => {
  const { recordIds } = useBitableLoadMoreContext();
  const { collectionId, viewId, readonly, viewType } = useBitable();
  const isEmpty = recordIds.length === 0;
  const isFilterEnabled = useFilterEnabled(viewId);

  const scrollContainer = useScrollRef();
  const pageScroll = usePageScrollRef();
  const [container, setContainer] = useState(scrollContainer?.current);
  const openSubitem = useOpenSubItem(collectionId);
  const subitemStyle = useSubitemStyle(viewId);
  // 如果是嵌套就必须让tableCellWrap为true
  const tableCellWrap = subitemStyle === 'Nested' && openSubitem;

  useEffect(() => {
    setContainer(
      viewType === CollectionViewType.TIMELINE ? pageScroll?.current : scrollContainer?.current
    );
  }, [pageScroll, scrollContainer, viewType]);

  if (!container) return null;

  return (
    <div className="block-list relative" data-view-id={viewId} data-group-value={tableGroup?.value}>
      {/* {!readonly && <FreezeColumnLine />} */}
      <Virtuoso
        customScrollParent={container}
        overscan={OVERSCAN}
        increaseViewportBy={OVERSCAN}
        computeItemKey={(index) => index}
        fixedItemHeight={tableCellWrap ? undefined : 37}
        totalCount={recordIds.length}
        itemContent={(index) => {
          const recordId = recordIds[index];
          if (!recordId) return null;
          return (
            <ListRow
              index={index}
              key={recordId}
              recordId={recordId}
              tableCellWrap={tableCellWrap}
              groupProperty={tableGroup?.groupProperty}
              groupValue={tableGroup?.value}
              properties={properties}
              level={openSubitem ? 0 : undefined}
            />
          );
        }}
      />

      {!readonly && !embed && <MembersCoordinate type="table" />}

      {isEmpty && <EmptyBitable filterEnabled={isFilterEnabled} />}

      <BitableLoadMore />

      <AddRecord
        where={{
          after: !withoutValidGroup ? recordIds[recordIds.length - 1] : undefined,
        }}
        groupName={tableGroup?.value}
      />
    </div>
  );
}, deepEqual);
