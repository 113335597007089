import { useOpenModal } from '@flowus/common/next-modal';
import { FileRegex } from '@flowus/common/regex';
import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useFileViewLimit } from 'src/hooks/limit/file-down-limit';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import { getFileNameInfo } from 'src/utils/file';
import { usePageScene } from 'src/views/main/scene-context';
import { FilePreview } from '.';
import { ImageGallery } from './image-gallery';
import { useOpenImagePreview } from '../images-provider/provider';

export const useOpenFilePreview = () => {
  const openModal = useOpenModal();
  const scene = usePageScene();
  const fileViewLimit = useFileViewLimit();
  const openImagePreview = useOpenImagePreview();

  return useCallback(
    (uuid: string, ownerBlockId?: string) => {
      const { blocks } = getState();
      const block = blocks[uuid];
      if (!block) return;
      const isImage =
        FileRegex.image.test(getFileNameInfo(block.data.ossName).extName) ||
        block.data.display === 'image' ||
        block.type === BlockType.PDF_ANNOTATION;
      if (isImage) {
        openImagePreview({ uuid: ownerBlockId ?? uuid });
        return;
      }
      void fileViewLimit().then(() => {
        openModal.modal({
          modalId: Modals.FILE_PREVIEW,
          noAnimation: true,
          content: () =>
            isImage ? (
              <ImageGallery uuid={uuid} ownerBlockId={ownerBlockId} scene={scene} />
            ) : (
              <FilePreview uuid={uuid} pageScene={scene} />
            ),
        });
      });
    },
    [fileViewLimit, openImagePreview, openModal, scene]
  );
};
