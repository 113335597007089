import { useCallback } from 'react';
import { useStore } from 'react-redux';
import type { Option } from 'src/bitable/table-view/types';
import { useUpdatePropertySchema } from './use-update-property-schema';

export const useCreatePropertyOption = () => {
  const { getState } = useStore();

  const updatePropertySchema = useUpdatePropertySchema();

  const createPropertyOption = (
    collectionId: string,
    property: string,
    option: Option,
    needTransaction = true
  ) => {
    const collection = getState().blocks[collectionId];
    if (!collection) return;

    const propertySchema = collection.data.schema?.[property];
    if (!propertySchema) return;

    const oldOptions = propertySchema.options ?? [];
    const newOptions = oldOptions.concat({ ...option });

    updatePropertySchema(
      collectionId,
      property,
      {
        options: newOptions,
      },
      {
        needTransaction,
      }
    );
  };

  return useCallback(createPropertyOption, [getState, updatePropertySchema]);
};
