import isHotkey from 'is-hotkey';
import { useCallback } from 'react';
import { LAST_USED_COLOR_INFO } from 'src/common/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { selectedBlocksToIds } from 'src/utils/select-block-util';

/** 使用上次颜色值 */
export const useLastColor = () => {
  const transaction = useTransaction();
  const getEditorModal = useGetOrInitEditorModel();
  return useCallback(
    (event: KeyboardEvent) => {
      if (isHotkey('mod+shift+h')(event)) {
        const localColorInfo = localStorage.getItem(LAST_USED_COLOR_INFO);
        if (localColorInfo && localColorInfo !== 'undefined') {
          event.preventDefault();
          const colorInfo = JSON.parse(localColorInfo);
          const editorId = document.activeElement
            ?.closest('[data-editor]')
            ?.getAttribute('data-editor');

          if (editorId) {
            const model = getEditorModal(editorId);
            if (model) {
              model.performChange((ctx) => {
                if (colorInfo.isBgColor) {
                  ctx.applyFormat({
                    color: undefined,
                    backgroundColor: colorInfo.colorkey,
                  });
                } else {
                  ctx.applyFormat({
                    backgroundColor: undefined,
                    color: colorInfo.colorkey,
                  });
                }
              });
            }
            return;
          }

          // 多选块
          const ids = selectedBlocksToIds(cache.ui.selectedBlocks);
          transaction(() => {
            // 如果第一个是reset，所有都是reset，保持一致
            let reset = false;
            ids.forEach((id, index) => {
              if (index === 0) {
                if (colorInfo.isBgColor) {
                  reset = colorInfo.colorkey === cache.blocks[id]?.backgroundColor;
                } else {
                  reset = colorInfo.colorkey === cache.blocks[id]?.textColor;
                }
              }
              if (reset) {
                updateBlock(id, {
                  backgroundColor: undefined,
                  textColor: undefined,
                });
                return;
              }
              updateBlock(id, {
                backgroundColor: colorInfo.isBgColor ? colorInfo.colorkey : undefined,
                textColor: !colorInfo.isBgColor ? colorInfo.colorkey : undefined,
              });
            });
          });
          dispatch(uiActions.updateSelectBlocks([]));
        }
      }
    },
    [transaction]
  );
};
