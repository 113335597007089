import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import { useEffect, useMemo, useRef } from 'react';
import { useReadonly } from 'src/hooks/page';
import { useCtrlDragScroll } from 'src/hooks/utils/use-ctrl-drag-scroll';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { StoreContextProvider } from 'src/services/store-context/provider';
import type { BlockElement } from '../../core/type';
import { BlockDrop } from '../dnd/block-drop';
import { DragBar } from './drag-bar';
import { DragLines } from './drag-lines';
import { useKeys } from './helper';
import { useFocusTable, useIndexRanges } from './hook';
import { Table } from './table';
import { TableMenu } from './table-menu';
import { TableSelectionLayout } from './table-selection-layout';

/**
 * NOTE: 这里的逻辑有变动的话记得把TableNode一块改，谢谢
 */
export const TableBlockElement: BlockElement = ({ id, root, children }) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const readonly = useReadonly(id);
  const { isDragging, isDraggingBar } = useObservableStore(
    ({ simpleTable }) => ({
      isDragging: !!simpleTable.draggingStart,
      isDraggingBar: !!simpleTable.draggingBarType,
    }),
    [],
    { obsSimpleTable: true }
  );

  const scrollXRef = useRef(null);
  const isFocusTable = useFocusTable(id);

  useCtrlDragScroll(scrollXRef);
  useKeys(id);

  useEffect(() => {
    const root = document.getElementById('root');
    if (isDragging) {
      root?.classList.add('dragging');
    } else {
      root?.classList.remove('dragging');
    }
  }, [isDragging]);

  const indexRanges = useIndexRanges(id);

  const props = useMemo(
    () => ({
      tableRef,
      tableId: id,
      indexRanges,
    }),
    [id, indexRanges]
  );

  const { wait, waitMode } = useObservableStore(
    ({ simpleTable }) => {
      const { activityTableId } = simpleTable;
      if (activityTableId === id || isFocusTable) {
        return { wait: 50, waitMode: 'throttle' } as const;
      }
      return { wait: 500, waitMode: 'debounce' } as const;
    },
    [id],
    { obsSimpleTable: true }
  );

  return (
    <>
      <BlockDrop id={id} horizontal={root}>
        <StoreContextProvider wait={wait} waitMode={waitMode}>
          <div
            className={cx(
              'overflow-x-auto overflow-y-hidden relative',
              root && '-ml-[104px] pl-24 sm:-ml-12 sm:pl-12'
            )}
            ref={scrollXRef}
            onScroll={() => emitter.emit('removeHoverMenu')}
          >
            <Table {...props} />

            {!readonly && (
              <>
                <TableSelectionLayout root={root} {...props} />
                <DragLines {...props} />
                {!isDragging && (
                  <>
                    <DragBar type="row" {...props} />
                    <DragBar type="column" {...props} />
                    <DragBar type="corner" {...props} />
                  </>
                )}
              </>
            )}
          </div>
          {!readonly && !isDraggingBar && <TableMenu {...props} />}
        </StoreContextProvider>
      </BlockDrop>
      {children}
    </>
  );
};
