import type { Point } from '../type';

interface LineStyle {
  lineWidth?: number;
  strokeStyle?: string | CanvasGradient | CanvasPattern;
  fillStyle?: string | CanvasGradient | CanvasPattern;
  lineCap?: CanvasLineCap;
  lineJoin?: CanvasLineJoin;
  joinShape?: 'round' | 'rectangle';
  closePath?: boolean;
  isFill?: boolean;
}

export const drawLine = (ctx: CanvasRenderingContext2D, paths: Point[], lineStyle?: LineStyle) => {
  if (lineStyle) {
    if (lineStyle?.lineWidth) {
      ctx.lineWidth = lineStyle?.lineWidth;
    }
    if (lineStyle?.strokeStyle) {
      ctx.strokeStyle = lineStyle?.strokeStyle;
    }
    if (lineStyle?.fillStyle) {
      ctx.fillStyle = lineStyle?.fillStyle;
    }
    if (lineStyle?.lineCap) {
      ctx.lineCap = lineStyle?.lineCap;
    }
    if (lineStyle?.lineJoin) {
      ctx.lineJoin = lineStyle?.lineJoin;
    }
  }

  const drawCirclePoint = (point: Point) => {
    ctx.beginPath();
    ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
    ctx.fill();
  };
  if (lineStyle?.joinShape === 'round') {
    paths.forEach(drawCirclePoint);
    ctx.lineJoin = 'round';
  }

  ctx.beginPath();
  paths.forEach((point, index) => {
    if (index === 0) {
      ctx.moveTo(point.x, point.y);
    } else {
      ctx.lineTo(point.x, point.y);
    }
  });

  if (lineStyle?.closePath) {
    ctx.closePath();
  }

  if (lineStyle?.isFill) {
    ctx.fill();
  } else {
    ctx.stroke();
  }
};
