/** 打开菜单弹窗 hook */
import { BlockType } from '@next-space/fe-api-idl';
import { useCallback, useContext } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import type { EditorKeyOption } from 'src/hooks/editor/use-focus-by-id';
import { useCloseBlockMenuList } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { SegmentType } from 'src/redux/types';
import { setAppUiState } from 'src/services/app';
import { getEditorKeyFromBlockNode } from 'src/utils/editor-utils';
import { focusEditableAtByEditorKey } from 'src/utils/focus-by-editor-key';
import { IsInRightContext } from 'src/utils/right-utils';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import type { Placement } from 'tippy.js';
import { BlockMenuList } from '.';
import { makeEditorKey } from '../../editable/helper';

interface OpenRightMenu {
  blockId: string;
  blockNode: HTMLElement; // 这个可能是假的
  menuPosition?: HTMLElement;
  menuNode?: HTMLElement;
  noSelect?: boolean;
  placement?: Placement;
  offset?: [number, number];
}

export const useOpenBlockRightMenuList = () => {
  const rightHistory = useContext(IsInRightContext);
  const closeBlockMenuList = useCloseBlockMenuList();
  const openModal = useOpenModal();

  return useCallback(
    (props: OpenRightMenu) => {
      const { blockId, menuPosition, blockNode, noSelect, offset = [0, 22] } = props;
      const popcorn = elementToGetBoundingClientRect(menuPosition || blockNode);
      const { blocks } = cache;
      const block = blocks[blockId];
      const isCollection =
        block?.type === BlockType.COLLECTION_VIEW || block?.type === BlockType.REFERENCE_COLLECTION;
      const placement = isCollection ? 'left-start' : 'left';
      const { syncId, viewId } = blockNode.dataset;
      const editorKey =
        getEditorKeyFromBlockNode(blockNode) || makeEditorKey(blockId, undefined, syncId);
      const focusEditableAt = (
        // 从外面把方法传进来需要改的比较多，这里用focusEditableAtByEditorKey代替
        id: string | undefined,
        at: number,
        type?: SegmentType,
        option?: EditorKeyOption
      ) => {
        if (!id) return;
        cache.blocks[id];
        const _editorKey = makeEditorKey(id, undefined, option?.syncId ?? syncId);
        focusEditableAtByEditorKey(_editorKey, at, type);
      };
      openModal.dropdown({
        modalId: blockId,
        popcorn,
        placement,
        offset,
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: [placement, 'right'],
            },
          },
        ],
        closeBeforeCallBack: closeBlockMenuList,
        content: ({ onCloseModal }) => (
          <IsInRightContext.Provider value={rightHistory}>
            <BlockMenuList
              focusEditableAt={focusEditableAt}
              blockId={blockId}
              syncId={syncId}
              viewId={viewId}
              editorKey={editorKey}
              popcorn={popcorn}
              onCloseModal={onCloseModal}
            />
          </IsInRightContext.Provider>
        ),
      });

      const { selectedBlocks } = cache.ui;
      if (!noSelect) {
        const { viewId, groupValue, syncId } = blockNode.dataset;
        if (
          !selectedBlocks.find((item) => {
            const isSelected = item.blockId === blockId && item.viewId === viewId;
            if (!isSelected) return false;

            if (!item.groupValues || !groupValue) return true;
            return Object.keys(item.groupValues).includes(groupValue);
          })
        ) {
          dispatch(
            uiActions.updateSelectBlocks([
              {
                blockId,
                viewId,
                syncId,
                groupValues: groupValue ? { [groupValue]: [] } : undefined,
              },
            ])
          );
        }
        setAppUiState({ $focusedInSyncBlockId: '' });
      }
      setAppUiState({ $blockMenuListId: blockId });
    },
    [closeBlockMenuList, openModal, rightHistory]
  );
};
