import pinyin, { STYLE_FIRST_LETTER, STYLE_NORMAL } from '@flowus/pinyin';

// 首字母
export const getInitials = (str = '') => {
  return pinyin(str, {
    style: STYLE_FIRST_LETTER,
  }).join('');
};

// 拼音
export const getPinyin = (str = '', heteronym = false) => {
  return pinyin(str, {
    style: STYLE_NORMAL,
    heteronym,
  }).join('');
};
