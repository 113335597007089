import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

export const QuoteBlockElement: BlockElement = ({ id, root, children }) => {
  const block = usePickBlock(id, ['textColor']);

  if (!block) return null;

  return (
    <BlockDrop id={id} className="py-1 my-px w-full pr-2" horizontal={root}>
      <div className="pl-2 border-l-4 border-solid border-current">
        <BlockDrop dropInChild bindId={false} id={id}>
          <Editable uuid={id} placeholder="引述文字" className="w-full text-t1 px-0.5" />
        </BlockDrop>
        {children}
      </div>
    </BlockDrop>
  );
};
