import { sleep } from '@flowus/common/async';
import { BlockStatus, BlockType, CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import { first, last, orderBy } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { getRandomEmoji } from 'src/common/components/emoji-widget/emoji-mart/utils';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { publicLink } from 'src/common/const';
import { request } from 'src/common/request';
import { HelperIconBox } from 'src/components/helper-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import type { SearchItem } from 'src/components/search';
import { SearchContent } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useDocToBitable } from 'src/hooks/block/use-doc-to-bitable';
import { useCreateDocPage } from 'src/hooks/page/use-create-page';
import { useFetchBlock } from 'src/hooks/page/use-fetch-block';
import { getIsGuest } from 'src/hooks/share/use-permission-utils';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useAllSpaces } from 'src/hooks/space';
import { getCurrentSpaceId, getSpaceView } from 'src/hooks/space/get-space';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import { useAsyncTransaction, useTransaction } from 'src/hooks/use-transaction';
import { useCurrentUser, useUpdateUser } from 'src/hooks/user';
import { getSpaceViewIdBySpaceId } from 'src/hooks/user/switch-space-view-id';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { updateBlock } from 'src/redux/managers/block/update';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { $spaceViewsState } from 'src/services/spaces/space-views';
import { $spacesCache } from 'src/services/spaces/spaces';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { v4 } from 'uuid';
import { TocType } from '../../aside/toc/types';
import { SettingModalCommon } from '../common';
import { Option } from '../option';
import { Role } from '@flowus/common';

const SEARCH_SPACE_MODAL = v4();
const SEARCH_PAGE_MODAL = v4();
const CREATE_PAGE_DIALOG = v4();
const saveBlockType = (type?: BlockType) =>
  Boolean(
    type !== undefined &&
      [BlockType.PAGE, BlockType.COLLECTION_VIEW_PAGE, BlockType.COLLECTION_VIEW].includes(type)
  );

export const ClipperSetting: FC = () => {
  const searchPageDomRef = useRef<HTMLDivElement>(null);
  const fetchBlock = useFetchBlock();
  const asyncTransaction = useAsyncTransaction();
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const allSpaces = useAllSpaces();
  const fetchMe = useFetchMe();
  const updateUser = useUpdateUser();

  const getDefaultSpaceView = () => {
    const views = orderBy(
      Object.values($spaceViewsState.getState()).filter(
        (i) => !getIsGuest($spacesCache[i.spaceId])
      ),
      ['createBy'],
      ['desc']
    );

    const spaceViewHasClipperPage = views.filter((i) => i.setting.clipperPage);

    // 需求是这样定的 🤷🏻
    // 有设置过的话取最后一个
    if (spaceViewHasClipperPage.length > 0) {
      return last(spaceViewHasClipperPage);
    }

    // 没有取第一个
    return first(views);
  };

  const [pageId, setPageId] = useState(() => {
    if (currentUser.clipperLocation) {
      return currentUser.clipperLocation;
    }
    return getDefaultSpaceView()?.setting.clipperPage;
  });

  const clipperPageBlock = usePickBlock(pageId, ['data'], ['segments']);
  const [selectSpaceId, setSelectSpaceId] = useState(
    clipperPageBlock?.spaceId || getDefaultSpaceView()?.spaceId || getCurrentSpaceId()
  );
  const clipperSpace = allSpaces.find((i) => i.uuid === selectSpaceId);
  const spaceViewId = getSpaceViewIdBySpaceId(selectSpaceId);
  const updateSpaceView = useUpdateSpaceView();

  const savePath = (id: string) => {
    setPageId(id);
    asyncTransaction(async () => {
      updateSpaceView(spaceViewId, {
        setting: {
          clipperPage: id,
        },
      });
      await updateUser({ clipperLocation: id });
      await fetchMe();
      message.success('保存成功');
    });
  };

  const switchSelectSpace = async (id: string) => {
    setSelectSpaceId(id);
    const _spaceViewId = getSpaceViewIdBySpaceId(id);
    const _pageId = getSpaceView(_spaceViewId)?.setting.clipperPage;
    setPageId(_pageId);

    // 保存成为最后一次设置的路径
    if (_pageId && _pageId !== currentUser.clipperLocation) {
      await updateUser({ clipperLocation: _pageId });
      message.success('保存成功');
    } else if (!_pageId) {
      await sleep(200);
      searchPageDomRef?.current?.click?.();
    }
  };

  const openSearchSpace = (event: MouseEvent) => {
    openModal.dropdown({
      modalId: SEARCH_SPACE_MODAL,
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [0, 8],
      content: () => <SelectClipperSpace onItemClick={switchSelectSpace} />,
    });
  };

  const openSearchPage = (event: MouseEvent) => {
    if (!selectSpaceId) return;
    openModal.dropdown({
      modalId: SEARCH_PAGE_MODAL,
      popcorn: event.currentTarget,
      placement: 'bottom-end',
      offset: [0, 8],
      content: () => (
        <SelectClipperPage
          spaceId={selectSpaceId}
          onItemClick={(id) => {
            savePath(id);
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (!pageId) return;
    const block = cache.blocks[pageId];
    if (block) return;
    void fetchBlock(pageId);
  }, [fetchBlock, pageId]);

  useEffect(() => {
    if (clipperPageBlock?.spaceId) {
      setSelectSpaceId(clipperPageBlock.spaceId);
    }
  }, [clipperPageBlock?.spaceId]);

  return (
    <>
      <SettingModalCommon.Item leftChild={'设置剪藏保存位置'} />
      <SettingModalCommon.Item
        leftChild={'选择空间'}
        rightChild={
          <SettingModalCommon.DropdownButton
            onClick={openSearchSpace}
            content={
              clipperSpace ? (
                <div className="flex items-center">
                  <IconTrigger iconSize={18} blockId={clipperSpace.uuid} trigger={false} />
                  <span className="ml-2">{clipperSpace.title}</span>
                </div>
              ) : (
                <div className="text-grey3">请选择</div>
              )
            }
          />
        }
      />
      <SettingModalCommon.Item
        leftChild={'添加到'}
        rightChild={
          <SettingModalCommon.DropdownButton
            onClick={openSearchPage}
            disabled={!clipperSpace}
            content={
              <div ref={searchPageDomRef}>
                {clipperSpace ? (
                  clipperPageBlock ? (
                    <div className="flex items-center">
                      <IconTrigger iconSize={18} blockId={clipperPageBlock.uuid} trigger={false} />
                      <span className="ml-2">
                        {segmentsToText(clipperPageBlock?.data.segments) ||
                          getUntitledName(clipperPageBlock.type)}
                      </span>
                    </div>
                  ) : (
                    <div className="text-grey3">新建我的剪藏</div>
                  )
                ) : (
                  <div className="text-grey3">请先选择空间</div>
                )}
              </div>
            }
          />
        }
      />
      <WeChatClipperHelper />
    </>
  );
};

// #region 设置剪藏页面
const SelectClipperPage: FC<{
  spaceId: string;
  onItemClick: (id: string) => void;
}> = (props) => {
  const openModal = useOpenModal();
  const { onItemClick, spaceId } = props;
  const [defaultValue, setDefaultValue] = useState<SearchItem[]>([]);

  const onSelect = (blockId: string) => {
    const { role } = getPermissions(blockId);
    const block = cache.blocks[blockId];
    if (!block) {
      return;
    }

    if (!saveBlockType(block?.type)) {
      message.error('只能选择页面或收藏多维表');
      return;
    }

    if (!Role.contains(role, PermissionRole.WRITER)) {
      message.error('对该页面无可编辑权限');
      return;
    }

    if (!block.data.format?.isCutStoreContainer) {
      updateBlock(blockId, {
        data: { format: { isCutStoreContainer: true } },
      });
    }

    onItemClick(blockId);
    openModal.closeModal(SEARCH_PAGE_MODAL);
  };

  const onOpenCreateTable = () => {
    openModal.modal({
      modalId: CREATE_PAGE_DIALOG,
      content: () => <CreatePageDialog spaceId={spaceId} onSelect={onSelect} />,
    });
  };

  useEffect(() => {
    void request.editor.querySpaceVisitsHistory(spaceId, 0).then((res) => {
      if (res?.recordMap?.blocks) {
        dispatch(
          blocksActions.update({ blocks: res.recordMap.blocks as Record<string, NextBlock> })
        );
        const items: SearchItem[] = [];
        res.results?.forEach(({ uuid }) => {
          const cur = res.recordMap?.blocks?.[`${uuid}`] as NextBlock;
          if (cur && cur.status === BlockStatus.NORMAL) {
            items.push(covertNextBlockToSearchItem(cur) as SearchItem);
          }
        });

        setDefaultValue(
          (items ?? []).filter((item): item is SearchItem => saveBlockType(item?.type))
        );
      }
    });
  }, [spaceId]);

  return (
    <SearchContent
      className="next-modal py-2.5 relative"
      searchType="page"
      defaultValue={defaultValue}
      onSelect={({ uuid }) => onSelect(uuid)}
      placeholder={'搜索页面'}
      spaceId={spaceId}
      footer={
        <div
          className="absolute bottom-0 w-full flex items-center h-10 px-4 bg-white2 border-t border-grey6 text-t2 text-grey3 cursor-pointer rounded-tl-none rounded-tr-none"
          onClick={onOpenCreateTable}
        >
          <Icon size="middle" name="IcAdd" className="mr-2" />
          新建收藏列表
        </div>
      }
    />
  );
};

/** 创建页面弹窗 */
const CreatePageDialog: FC<{
  onSelect: (id: string) => void;
  spaceId: string;
}> = (props) => {
  const openModal = useOpenModal();
  const createDocPage = useCreateDocPage();
  const docToBitable = useDocToBitable();
  const transaction = useTransaction();
  const [value, setValue] = useState('我的剪藏');
  const { spaceId, onSelect } = props;

  const handleClick = () => {
    transaction(() => {
      const uuid = createDocPage(
        BlockType.PAGE,
        {
          parentId: spaceId,
          last: true,
        },
        TocType.PRIVATE
      );
      if (!uuid) {
        message.error('没有权限，创建失败');
        return;
      }
      const emojiStr = getRandomEmoji(true);
      updateBlock(uuid, {
        data: {
          icon: {
            type: 'emoji',
            value: emojiStr,
          },
          segments: textToSegments(value),
          format: {
            isCutStoreContainer: true,
          },
        },
      });
      docToBitable(uuid, CollectionViewType.GALLERY);
      onSelect(uuid);
    });
    openModal.closeModal(CREATE_PAGE_DIALOG);
  };

  return (
    <div className="next-modal p-2.5 w-80">
      <Input
        autoFocus
        value={value}
        onChange={setValue}
        className="h-8 my-3 mx-1"
        placeholder="请输入页面名"
      />
      <div className="flex pt-1 justify-end">
        <Button colorType="secondary" onClick={() => openModal.closeModal(CREATE_PAGE_DIALOG)}>
          取消
        </Button>
        <Button className="ml-2.5" colorType="active" onClick={handleClick}>
          创建
        </Button>
      </div>
    </div>
  );
};
// #endregion

// #region 设置剪藏空间
const SelectClipperSpace: FC<{ onItemClick: (id: string) => void }> = (props) => {
  const { onItemClick } = props;
  const closeModal = useCloseModal();
  const [keyword, setKeyword] = useState('');
  const allSpaces = useAllSpaces();

  const items = useMemo(() => {
    return allSpaces.map((item) => ({
      type: ListItemType.SEARCH,
      data: {
        ...item,
        type: 'SPACE',
        title: item.title,
        icon: item.icon,
        backgroundColor: item.backgroundColor,
      },
    }));
  }, [allSpaces]);

  const handleClick = (item: typeof items[0]['data']) => {
    const isGuest = getIsGuest(item);
    if (isGuest) {
      message.error('对该空间无可编辑权限');
      return;
    }
    onItemClick(item.uuid);
    closeModal(SEARCH_SPACE_MODAL);
  };

  return (
    <ListView
      items={items}
      className="w-64 next-modal"
      scrollClassName="overflow-y-auto max-h-64 pb-2"
      onItemClick={(item) => {
        handleClick(item.data);
      }}
      customHeader={
        <Input
          className="h-8 m-2.5"
          placeholder="搜索空间"
          autoFocus
          value={keyword}
          onChange={setKeyword}
        />
      }
    />
  );
};
// #endregion

// #region 剪藏使用指南
const WeChatClipperHelper: FC<{}> = () => {
  return (
    <Option
      title="使用微信剪藏"
      description="通过微信剪藏助手，发送信息到FlowUs"
      rightPart={
        <HelperIconBox
          link={publicLink.weChatClipper}
          linkContainer
          className="text-active_color border-b border-active_color"
          iconClassName="hidden"
        >
          如何使用剪藏？
        </HelperIconBox>
      }
    />
  );
};
// #endregion
