import { useCallback } from 'react';
import { setAppModalState } from 'src/services/modal';
import type { OpenSettingFrom, SettingModelProps } from './type';
import { SettingMenuType } from './type';

export const openSettingModal = (props: SettingModelProps) => {
  setAppModalState('$settingState', { open: true, ...props });
};

/** 打开设置弹窗 */
export const useOpenSettingModal = () => {
  return openSettingModal;
};

/** 点击升级空间 */
export const useOpenUpgradeSpace = () => {
  const openSettingModal = useOpenSettingModal();

  return useCallback(
    (from: OpenSettingFrom) => {
      openSettingModal({
        defaultMenuType: SettingMenuType.upgrade,
        from,
      });
    },
    [openSettingModal]
  );
};

/** 关闭设置弹窗 */
export const useCloseSettingModal = () => {
  return useCallback(() => {
    setAppModalState('$settingState', { open: false });
  }, []);
};
