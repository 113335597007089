import { iterWidths } from '@flowus/common/utils/image';
import { BlockType } from '@next-space/fe-api-idl';
import { throttle } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useIsSelecting } from 'src/services/app';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';
import { usePageContentWidth, usePageWidth } from 'src/views/main/page-doc/context';
import { useReadonly } from '../page';
import { useIsDragging } from '../page/use-dnd/hooks';
import { useDebounceElementSize } from '../public/use-debounce-element-size';

/** Hook简介
 * 搭配 ResizeElement 使用，会自动计算很多东西
 * block 宽高都定义在了 block.data.format 中，用于展示
 * block.data.width 是文件原本的宽高尺寸，比如图片
 * root 是全屏宽度的开关，全屏是指超出文档内容区域的宽度的最大 Main 区域宽度
 * fullPageContentWidth 是指图片相关的 block 上传之后会以文档区域作为最大宽度
 */
interface ResizeProps {
  /** blockId */
  id: string;
  /** 是否为满屏宽度 */
  root?: boolean;
  /** 默认宽度，没有设置过宽度时使用 */
  defaultWidth?: number;
  /** 默认高度，没有设置过高度时使用 */
  defaultHeight?: number;
  /** 充满屏幕宽度 */
  defaultBlockFullWidth?: boolean;
  /** 没有设置宽度，并且内容又大于内容区域宽度，是否追随内容区域最大宽度。link notion*/
  fullPageContentWidth?: boolean;
  /** 自定义同步接口截流时间 */
  throttleMilliseconds?: number;
  /** 可以修改宽度 */
  resizeWidth?: boolean;
}
interface ChangeSizeParams {
  width: number;
  height?: number;
  blockFullWidth: boolean;
}
/** 搭配ResizeElement使用 */
export const useResize = (props: ResizeProps) => {
  const {
    id,
    root,
    defaultWidth = DEFAULT_PAGE_WIDTH,
    defaultHeight,
    defaultBlockFullWidth,
    fullPageContentWidth,
    throttleMilliseconds = 500,
    resizeWidth = true,
  } = props;
  const transaction = useTransaction();
  const pageId = useGetPageId();
  const pageBlock = usePickBlock(pageId, ['data'], ['directoryMenu']);
  /** block样式数据 */
  const block = usePickBlock(id, ['data'], ['format', 'width', 'height', 'link']);
  /** 是否只读 */
  const readonly = useReadonly(id);
  /** 最新的resize Element宽高 */
  const [renderSize, setRenderSize] = useState({ width: 0, height: 0 });
  /** 是否设置过format */
  const notSettingFormat = !block?.data.format?.width;
  /** 外层盒子的ref */
  const containerRef = useRef<HTMLDivElement>(null);
  /** 是否有元素处于拖拽状态 */
  const isDragging = useIsDragging();
  /** 是否正在框选 */
  const isSelecting = useIsSelecting();
  /** page的Block信息 */
  const directoryMenu = pageBlock?.data.directoryMenu;
  /** 是否在侧边栏 */
  const isInRight = useIsInRight();
  /** 节流同步到redux */
  const transactionThrottle = useRef(
    throttle(
      (format) => transaction(() => updateBlock(id, { data: { format } })),
      throttleMilliseconds
    )
  );

  // 给fullPageContentWidth用，避免拉伸的时候闪烁一下。
  const pageContentWidth = usePageContentWidth();
  const pageWidth = usePageWidth();
  const pageMaxWidth = directoryMenu || isInRight ? pageContentWidth : pageWidth;

  // 最大宽度是不是用页面宽度
  const pageWidthIsMaxWidth = root || !containerRef.current || !resizeWidth || isInRight;

  let parentElement;
  if (pageWidthIsMaxWidth) {
    parentElement = null;
  } else {
    parentElement = containerRef.current;
  }

  // 可铺满的容器宽度
  const parentSize = useDebounceElementSize(parentElement, { type: 'width', wait: 100 });

  const containerWidth = pageWidthIsMaxWidth ? pageMaxWidth : parentSize.width ?? 0;

  /** 节流同步到redux */
  const changeSize = useCallback(
    (resize: ChangeSizeParams) => {
      if (readonly) return;

      // 如果是root，就是页面宽度。非root就是父级宽度
      const maxContainerWidth = root
        ? resize.width === pageMaxWidth
        : resize.width === containerWidth;

      // 先判断是否已经撑满了容器
      const blockPageWidth = fullPageContentWidth && maxContainerWidth;

      const format = {
        ...(block?.data.format || {}),
        ...resize,
        blockPageWidth,
      };

      // 判断，如果是顶层，并且没有开标题目录
      // 如果容器宽度是撑满了整个page的宽度，就依page宽度
      if (root && !directoryMenu) {
        format.blockFullWidth = resize.width === pageWidth;
        if (format.blockFullWidth) {
          format.blockPageWidth = false;
        }
      }

      // 避免两个开关同时为true。优先取容器宽度，而不是页面宽度
      if (format.blockFullWidth && format.blockPageWidth) {
        format.blockFullWidth = false;
      }

      transactionThrottle.current(format);
    },
    [
      block?.data.format,
      containerWidth,
      directoryMenu,
      fullPageContentWidth,
      pageMaxWidth,
      pageWidth,
      readonly,
      root,
    ]
  );

  // 是否开启自适应宽度，适应到Page宽度
  const fullContent = Boolean(
    fullPageContentWidth &&
      (block?.data.format?.blockPageWidth ||
        (notSettingFormat && Number(block?.data.width) >= pageContentWidth))
  );
  const result = useMemo(
    () => ({
      defaultWidth: fullContent
        ? pageContentWidth
        : block?.data.format?.width ||
          block?.data.width ||
          // 如果是link的并且没有设置宽高的话就undefined, 默认多大就是多大
          (block?.data.link || block?.type === BlockType.FILE
            ? undefined
            : pageContentWidth || defaultWidth),
      blockFullWidth: defaultBlockFullWidth || block?.data.format?.blockFullWidth,
    }),
    [
      block?.data.format?.blockFullWidth,
      block?.data.format?.width,
      block?.data.link,
      block?.data.width,
      block?.type,
      defaultBlockFullWidth,
      defaultWidth,
      fullContent,
      pageContentWidth,
    ]
  );

  const widthBreakPoints = useMemo(
    () => [
      containerWidth,
      pageContentWidth,
      DEFAULT_PAGE_WIDTH,
      ...iterWidths({ maxWidth: containerWidth, mode: 'gradual' }),
    ],
    [containerWidth, pageContentWidth]
  );

  return {
    /** 真实的渲染宽高 */
    renderSize,
    /** 渲染的出来的宽高 */
    onRenderSize: setRenderSize,
    /** 外层盒子的ref，一般需要给到BlockDrop的ref */
    containerRef,
    /** change回调，需要给ResizeElement的onChange */
    changeSize,
    /** 外层盒子的宽度，一般是设置到maxWidth上 */
    containerWidth,
    /** 是否处于全状态，超过文档宽度 */
    defaultBlockFullWidth: result.blockFullWidth,
    /** 默认宽度 */
    defaultWidth: result.defaultWidth,
    /** 默认高度 */
    defaultHeight: block?.data.format?.height || block?.data.height || defaultHeight,
    /** 是否处于禁用状态 */
    isDisable: isDragging || isSelecting,
    /** 吸附节点 */
    widthBreakPoints,
    /** 页面内容区域宽度 */
    pageContentWidth,
  };
};
