import { canPreViewImage } from '@flowus/common';
import { getTagBackgroundColor, getTagTextColor } from '@flowus/common/block/color/get-block-color';
import { LOCAL_LNG } from '@flowus/common/const';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';
import { Checkbox } from 'src/components/check-box';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { UserAvatar } from 'src/components/user-avatar';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { formatCurrency, formatFloat, numberToPercent } from 'src/utils/number';
import { ALIYUN_IMGPROCESS_SIZE_LIMIT } from '../table-view/cell/files';
import { BiCheckboxType } from './values/checkbox';
import type { BiDateValue } from './values/date';
import { BiDateType } from './values/date';
import { BiFilesType } from './values/files';
import { BiMultiSelectType } from './values/multi-select';
import { BiNumberType } from './values/number';
import { BiRelationType } from './values/relation';
import { BiSelectType } from './values/select';
import { BiTextType } from './values/text';
import { BiUsersType } from './values/users';
import type { BiValueType } from './values/_abstract';

export function renderTextValue(_type: BiTextType, value: SegmentDTO[] | undefined) {
  return <RichText segments={value} />;
}

export function renderNumberValue(type: BiNumberType, value: number) {
  switch (type.format) {
    case 'integer':
      return Math.round(value);
    case 'twoDecimal':
      return Number(value).toFixed(2);
    case 'thousandth': {
      const [integer, decimal] = String(value).split('.');
      let result = formatCurrency(integer ?? '');
      if (decimal) {
        result = result.concat('.', decimal);
      }
      return result;
    }
    case 'percent':
      return numberToPercent(value);
    default:
      return formatFloat(value);
  }
}

export function renderCheckboxValue(_type: BiCheckboxType, value: boolean) {
  return <Checkbox size="large" checked={Boolean(value)} className="align-top" />;
}

export function renderDateValue(_type: BiDateType, { timestamp, noTimePart }: BiDateValue) {
  return noTimePart
    ? dayjs(timestamp).format(DATE_FORMAT)
    : dayjs(timestamp).format(DATE_TIME_FORMAT);
}

function renderFilesValue(_type: BiFilesType, value: SegmentDTO[]) {
  return (
    <div className="flex items-center">
      {value.map((it, index) => {
        const canPreView = canPreViewImage(it.url);
        const tagItem = (
          <span
            key={index}
            className="rounded-sm text-ellipsis px-1.5 text-grey3 bg-white/50 ml-1.5 first:ml-0"
          >
            {it.text}
          </span>
        );
        if (canPreView && it.uuid && (!it.size || it.size <= ALIYUN_IMGPROCESS_SIZE_LIMIT)) {
          return (
            <FilePreViewIcon
              uuid={it.uuid}
              key={index}
              ossName={it.url}
              defaultIcon={tagItem}
              className={
                'h-5 min-w-[20px] min-h-[20px] cursor-pointer rounded-sm overflow-hidden ml-1.5 first:ml-0'
              }
              options={{ resizeWidth: 40 }}
            />
          );
        }
        return tagItem;
      })}
    </div>
  );
}

export function renderSelectValue(type: BiSelectType, value: number) {
  const option = type.options[value];
  if (option == null) return null;

  const bgColor = getTagBackgroundColor(option.color);
  const textColor = getTagTextColor(option.color);
  return (
    <span
      className="inline-block rounded-sm text-ellipsis px-1.5"
      style={{
        color: textColor,
        backgroundColor: bgColor,
      }}
    >
      {option.value}
    </span>
  );
}

export function renderMultiSelectValue(type: BiMultiSelectType, value: number[]) {
  return value.map((it) => {
    const option = type.options[it];
    if (option == null) return null;

    const bgColor = getTagBackgroundColor(option.color);
    const textColor = getTagTextColor(option.color);
    return (
      <span
        key={it}
        className="inline-block rounded-sm text-ellipsis px-1.5 ml-1.5 first:ml-0"
        style={{
          color: textColor,
          backgroundColor: bgColor,
        }}
      >
        {option.value}
      </span>
    );
  });
}

function User(props: { userId: string }) {
  const user = useUser(props.userId);
  const userName = useUserName(props.userId);
  return (
    <span className="inline-flex align-top py-1 ml-1.5 first:ml-0">
      <UserAvatar user={user} />
      <span className="ml-1.5">{userName}</span>
    </span>
  );
}

export function renderUsersValue(type: BiUsersType, value: string[]) {
  return (
    <>
      {value.map((it) => (
        <User key={it} userId={it} />
      ))}
    </>
  );
}

export function renderRelationValue(type: BiRelationType, value: string[]) {
  return (
    <>
      {value.map((it) => (
        <InlinePage key={it} uuid={it} />
      ))}
    </>
  );
}

export function renderPropValue(type: BiValueType<any>, value: any) {
  if (value === undefined) return <span className="text-grey4">{LOCAL_LNG.isEmpty}</span>;

  if (type instanceof BiTextType) {
    return renderTextValue(type, value);
  }
  if (type instanceof BiNumberType) {
    return renderNumberValue(type, value);
  }
  if (type instanceof BiCheckboxType) {
    return renderCheckboxValue(type, value);
  }
  if (type instanceof BiDateType) {
    return renderDateValue(type, value);
  }
  if (type instanceof BiFilesType) {
    return renderFilesValue(type, value);
  }
  if (type instanceof BiSelectType) {
    return renderSelectValue(type, value);
  }
  if (type instanceof BiMultiSelectType) {
    return renderMultiSelectValue(type, value);
  }
  if (type instanceof BiUsersType) {
    return renderUsersValue(type, value);
  }
  if (type instanceof BiRelationType) {
    return renderRelationValue(type, value);
  }
  return null;
}
