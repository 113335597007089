import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import type { IconName, IconSizeStyle } from '../../icon';
import { Icon } from '../../icon';
import { Switch } from '../../switch';
import { Tooltip } from '../../tooltip';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

/**
 * 带switch按钮的text item
 */
interface TextSwitchDataScheme {
  title: string;
  icon?: IconName;
  switch: boolean;
  disabled?: boolean;
  onSwitch: (open: boolean) => void;
  noCancelSelected?: boolean;
  context?: ReactNode;
  iconSize?: keyof typeof IconSizeStyle;
}

export const TextSwitchItem: FC<ElementItemProps<TextSwitchDataScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  return (
    <div
      {...props.attribute}
      className={cx(
        listViewItemDefaultClassName,
        'flex items-center justify-between rounded cursor-pointer active-bg animate-hover',
        { 'normal-bg': props.selected }
      )}
      onClick={() => dataScheme.onSwitch(!dataScheme.switch)}
      data-no-cancel-selected={dataScheme.noCancelSelected}
    >
      <Tooltip
        className={cx('flex items-center justify-between h-full w-full')}
        placement="right"
        theme="none"
        offset={[0, 16]}
        popup={dataScheme.context}
      >
        <div className="flex items-center">
          {dataScheme.icon && (
            <Icon name={dataScheme.icon} className="mr-2" size={dataScheme.iconSize ?? 'middle'} />
          )}
          <span className="text-black text-t2">{dataScheme.title}</span>
        </div>
        <Switch
          open={dataScheme.switch}
          onSwitch={dataScheme.onSwitch}
          disabled={dataScheme.disabled}
        />
      </Tooltip>
    </div>
  );
};
