import type { CSSProperties, MouseEvent, ReactElement, ReactNode } from 'react';

export enum ListItemType {
  OPERATION = 'operation',
  SEARCH = 'search',
  SEARCH_FILE = 'search_file',
  LINE = 'line',
  HISTORY = 'history',
  TEXT_SWITCH = 'textSwitch',
  /** 主要用于 / 的那个菜单 */
  BLOCK_ITEM = 'blockItem',
  /** grey3 颜色的文字 */
  BLOCK_TEXT = 'blockText',
  OPERABLE_BLOCK_TEXT = 'operableBlockText',
  /** 多用于 hovermenu 的转换块 */
  BLOCK_TRANSFORM = 'blockTransform',
  /** 带有title + des */
  TEXT_DESCRIPTION = 'textDescription',
  CUSTOM_RENDER = 'custom_render',
}

// #region interface
export interface ListViewProps {
  items: ListItem[];
  className?: string;
  /** 当items变化时，是否自动重置activeIndex，默认为true */
  autoResetActiveIndex?: boolean;
  /** 滚动列表容器的className */
  scrollClassName?: string;
  scrollContainerStyle?: Pick<CSSProperties, 'top' | 'paddingBottom'>;
  /** 启用虚拟列表，默认最大高度是50vh */
  virtualList?: Boolean;
  /** 虚拟列表的最大高度 */
  virtualListHeight?: string | number;
  customHeader?: ReactNode;
  customFooter?: ReactNode;
  loadingView?: ReactNode;
  hasMore?: boolean;
  loadMore?: (pageNo: number) => void;
  onItemClick?: (item: ListItem, index: number, event: MouseEvent) => void;
  keyDownPriority?: number;
  temporarySelectedIndex?: number; // 临时设置的被选中的index，之后还是会跟随方向键变动,默认是-1
  onGlobalCaptureKeydown?: (ev: KeyboardEvent) => void; // listView为了实现方向键控制，必须使用globalEventListener全局监听，内部会优先处理自己的事件，如果自己不处理了才回调这个方法
  // 下面两个props是把listView的activeIndex变成受控状态,即外层自己保存activeIndex。
  // 有些activeIndex的特殊情况下逻辑由使用者自己控制
  activeIndex?: number;
  onSelectedActiveIndex?: (activeIndex: number) => void;
  defaultActiveIndex?: number;
  style?: CSSProperties;
  /** 是否开启键盘事件 */
  enableKeyDown?: boolean;
}

/**
 * listitem的数据格式
 */
export interface ListItem<T = any> {
  type: string;
  data?: T;
  disableClick?: boolean;
  isHidden?: boolean;
  key?: string;
  render?: () => ReactElement;
  attribute?: any;
  // 自定义渲染时是否需要hover背景,default true
  disableCustomHoverBg?: boolean;
}

/**
 * ListItem 的 component props
 */
export interface ElementItemProps<DS = any> {
  listItem: ListItem<DS>;
  index: number;
  onItemClick?: (item: ListItem, index: number, event: MouseEvent) => void;
  selected?: boolean;
  attribute?: any; // 给item的属性
  onMouseEnter?: () => void;
}
// #endregion
