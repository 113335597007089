import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { NextModalProvider, useOpenModal } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { Modals } from 'src/modals';
import { BitableContext } from '../context';
import { ShareFormView } from './share-form-view';

/** 打开预览收集表 */
export const useOpenPreViewForm = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params: { viewId: string; collectionId: string }) => {
      const { viewId, collectionId } = params;
      const context: BitableContext = {
        viewId,
        collectionId,
        viewParentId: collectionId,
        readonly: true,
        managerReadonly: true,
        changeView: () => {},
        isColumnListChildren: false,
        isFoldListChildren: false,
        viewType: CollectionViewType.FORM,
      };
      openModal.modal({
        modalId: Modals.PREVIEW_FORM,
        className: 'form-scroll-container w-[90vw] h-[90vh] pb-10 overflow-y-auto',
        content: () => (
          <BitableContext.Provider value={context}>
            <NextModalProvider>
              <EditableNamespace.Provider value={UUID_NAMESPACE.FORM_PREVIEW}>
                <ShareFormView />
              </EditableNamespace.Provider>
            </NextModalProvider>
          </BitableContext.Provider>
        ),
      });
    },
    [openModal]
  );
};
