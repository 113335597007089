/**
 * 页面空状态
 */
import { encryptionPhone } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { UserDTO } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { PAGE_ALREADY_DELETE } from 'src/common/const';
import { request } from 'src/common/request';
import { useRestoreRole } from 'src/hooks/block/use-restore-role';
import { useSpaces } from 'src/hooks/space';
import { useCurrentUser } from 'src/hooks/user';
import { ViewPath } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { setBrowserTitle } from 'src/utils/emoji-favicon';
import { useGetPageId } from 'src/utils/getPageId';
import { useIsInRight } from 'src/utils/right-utils';
import { useOpenAudit, useShowAudit } from '../share/share-main/share-main-footer';
import { useOpenLogin } from './open-login';
import { Placeholder } from './placeholder';
import { Images } from 'src/image';

/** 页面未开启分享 */
export const NotShared: FC = () => {
  const openLogin = useOpenLogin();
  const currentUser = useCurrentUser();
  const spaces = useSpaces();
  const isLogin = Boolean(currentUser.uuid);
  const pageId = useGetPageId();
  const [pageInfo, setPageInfo] =
    useState<Partial<Awaited<ReturnType<typeof request.editor.getDocPublicData>>>>();
  const showSpaceInfo = isLogin;
  const restoreRole = useRestoreRole();

  useEffect(() => {
    if (pageId) {
      void request.editor.getDocPublicData
        .raw(pageId)
        .then((res) => {
          if (res.code === 200) {
            setPageInfo(res.data);
          }
        })
        .catch((e) => e);
    }
  }, [pageId]);

  const onCopy = () => {
    void writeTextInClipboard(window.location.href.replace('/share', ''));
  };

  const getNamePhone = (user?: UserDTO) =>
    `${user?.nickname}（${encryptionPhone(user?.phone || user?.email)}）`;

  return (
    <>
      <div
        className={cx(
          showSpaceInfo ? 'flex flex-col items-center justify-center h-full' : 'login-wrapper'
        )}
      >
        <div className="text-center w-full">
          <p className="text-[70px] leading-[80px]">✋</p>
          {showSpaceInfo ? (
            <>
              <p className="text-h0">{PAGE_ALREADY_DELETE}</p>
              {pageInfo && (
                <>
                  <p className="text-t2 text-grey3 py-4">本页面来自空间</p>
                  <div className="flex justify-center items-center mx-auto">
                    <Avatar
                      className="flex-shrink-0 mr-3 w-[26px] h-[26px]"
                      icon={pageInfo.spaceIcon}
                      name={pageInfo.spaceTitle}
                      color={pageInfo.spaceBackgroundColor}
                    />
                    <span className="text-t2-medium text-ellipsis">{pageInfo.spaceTitle}</span>
                  </div>
                  <div className="mt-9 rounded border border-grey6 bg-grey9 p-3 width-600 mx-auto sm:w-10/12">
                    {pageInfo.spaceId && spaces[pageInfo.spaceId] ? (
                      <div className="text-left">
                        <p>请发送页面链接给以下成员开通权限：</p>
                        <p>{getNamePhone(pageInfo.ownerUser)}</p>
                        <div className="flex justify-between items-center mt-12">
                          <div className="text-t2 text-grey3">
                            当前登录账号：{getNamePhone(currentUser)}
                          </div>
                          <div className="flex items-center space-x-2">
                            <Button
                              hidden={!pageInfo?.allowResetPagePermission}
                              onClick={() => restoreRole(pageId)}
                            >
                              恢复访问权限
                            </Button>
                            <Button onClick={onCopy} colorType="primary">
                              复制链接
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="text-center">
                        <p>你不是空间成员或访客，无法访问。</p>
                        <p>请联系管理员添加。</p>
                        <div className="mt-8 text-t2 text-grey3">
                          当前登录账号：{getNamePhone(currentUser)}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <p className="text-t2 text-grey3 my-2">当前页面未开启分享</p>
          )}
          {!isLogin && (
            <>
              <p className="mb-4 text-t3 text-grey4">如你拥有访问权限，请尝试登录后查看</p>
              <Button
                colorType="primary"
                size="large"
                className="mt-2 w-32 mx-auto"
                onClick={() => openLogin({ defaultTitle: pageInfo?.spaceTitle })}
              >
                登录
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

/** 页面禁止访问 */
export const NoPermission: FC<{ uuid: string; className?: string }> = ({ uuid, className }) => {
  const openAudit = useOpenAudit(uuid);
  const showAudit = useShowAudit(uuid);

  return (
    <Placeholder icon="🚫" title="当前页面违反平台分享条例，已禁止访问" className={className}>
      {showAudit && (
        <Button size="large" onClick={openAudit} colorType="primary" className="mt-3">
          我要申诉
        </Button>
      )}
    </Placeholder>
  );
};

/** 页面未找到 */
export const NotFound: FC<{
  title?: string;
  description?: string;
  showBackButton?: boolean;
  children?: ReactNode;
  icon?: string;
}> = ({ title, description, showBackButton = true, children, icon }) => {
  const history = useHistory();
  const backSpace = () => {
    history.replace(ViewPath.main);
  };
  const isInRight = useIsInRight();

  useEffect(() => {
    if (!isInRight) {
      void setBrowserTitle({
        title: '访问的页面不存在',
        icon: { type: 'emoji', value: icon ?? '🌚' },
      });
    }
  }, [icon, isInRight]);

  return (
    <div className="cursor-default login-wrapper w-full" style={{ height: 'calc(100% - 144px)' }}>
      {icon ? (
        <p className="text-[70px]">{icon}</p>
      ) : (
        // 设计让改的
        <img className="w-[130px] dark:contrast-0" src={Images.pageEmpty} />
      )}

      <p className="my-8 text-grey3 text-t2">{title ?? '访问的页面不存在'}</p>
      {description ? (
        <p className="text-t2 text-grey3">{description}</p>
      ) : showBackButton ? (
        <Button colorType="primary" onClick={backSpace}>
          返回到空间
        </Button>
      ) : null}
      {children}
    </div>
  );
};
