import type { FC, ReactNode } from 'react';

interface Props {
  visible: boolean;
  placeholder?: ReactNode;
}
export const VisiblePlaceholder: FC<Props> = (props) => {
  const { visible, placeholder, children } = props;
  if (visible) {
    return <>{children}</>;
  }
  return <>{placeholder}</>;
};
