import { mapValues } from 'lodash-es';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { updateSpaces } from 'src/services/spaces/spaces';
import { ViewPath } from 'src/utils';
import { locationToUrl } from 'src/utils/location-utils';
import { useFetchSpaceCapacity } from '../drive/use-fetch-space-capacity';
import { getAllSpaces } from '../space';
import { getCurrentSpaceId } from '../space/get-space';
import { switchSpaceViewIdBySpaceId } from '../user/switch-space-view-id';

/**
 * 获取 space 跟页面
 * 用于显示左侧菜单
 */

export const useFetchRootPages = () => {
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const fetchSpaceRootPages = async (
    spaceId: string,
    opt?: {
      notJumpLocation?: boolean;
    }
  ) => {
    const res = await request.infra.getSpaceRoot.raw(spaceId);
    if (res.code === 200) {
      const { blocks, spaces, collectionViews, users } = res.data;
      // space 也看作 block
      const blockState = {
        ...mapValues(spaces, (value) => {
          return {
            spaceId: value.uuid,
            type: 'SPACE', // 将 space 也看作 block
            data: { icon: value.icon },
            permissions: [],
            ...value,
          };
        }),
        ...blocks,
      } as Record<string, NextBlock>;

      dispatch(blocksActions.update({ blocks: blockState }));
      if (collectionViews) {
        dispatch(collectionViewsActions.update(collectionViews));
      }
      if (users && spaces) {
        updateSpaces(spaces);
        const space = spaces[spaceId];
        if (space?.permissions) {
          dispatch(usersActions.updateSpaceUsers({ users, space }));
        }
      }
      void fetchSpaceCapacity(spaceId);
      // @ts-ignore ignore
    } else if (!opt?.notJumpLocation && (res.code === 1403 || res.code === 3005)) {
      message.error('空间不存在');
      // 无权限访问，被踢掉了，或者空间被删除了，或者一个窗口退出登录，另一个窗口不知道而请求
      const firstSpace = getAllSpaces().filter((space) => space.uuid !== getCurrentSpaceId())[0];
      if (firstSpace) {
        switchSpaceViewIdBySpaceId(firstSpace.uuid);
        locationToUrl(ViewPath.main);
      } else {
        locationToUrl(ViewPath.create);
      }
    }
  };

  return useCallback(fetchSpaceRootPages, [fetchSpaceCapacity]);
};
