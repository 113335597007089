import type { IElementComponent } from '@next-space/fe-inlined';
import { registerElementMeta } from '@next-space/fe-inlined';
import { CODE_META } from './const';
import type { InlinePlugin } from './inline-plugin';

const Code: IElementComponent = ({ children, htmlDataProps }) => {
  return (
    <span
      className="rounded-sm text-[0.8125em] p-1 mx-[2px] code-inline-color code-font-family"
      {...htmlDataProps}
    >
      {children}
    </span>
  );
};

registerElementMeta(CODE_META);

export const CodeInlinePlugin: InlinePlugin = {
  elementMeta: CODE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, Code);
  },
};

export const NoCodeInlinePlugin: InlinePlugin = {
  elementMeta: CODE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, ({ children, htmlDataProps }) => {
      return <span {...htmlDataProps}>{children}</span>;
    });
  },
};
