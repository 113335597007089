import type {
  IconData,
  PermissionDTO,
  PermissionRole,
  PermissionType,
} from '@next-space/fe-api-idl';

export type User = PermissionDTO & { index?: number; name?: string };
export interface ShareProps {
  uuid: string;
  backToMainPanel: () => void;
  onChangeTab: (panel: Panel) => void;
  onChange?: (role: PermissionRole, permission: User) => void;
  selectUser?: (user: User, role?: PermissionRole) => void;
  selectedUsers?: User[];
}

export interface UserPermission {
  id?: string;
  index?: number;
  type: PermissionType;
  role?: PermissionRole;
  backgroundColor?: string;
  name?: string;
  icon?: IconData;
  phone?: string;
  userNames?: string[];
  usersNum?: number;
  isGuest?: boolean;
  isGuestInSpace?: boolean;
}

export enum Panel {
  SEARCH_USER,
  ALL_USERS,
  PASSWORD,
  /** 付费订阅设置 */
  PAY,
}
