import { formula } from '@flowus/formula';
import type { CollectionSchema, UserDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';
import type { FormulaTool } from 'src/hooks/block/use-formula-tool';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { formatFloat } from 'src/utils/number';

import { getDatePropertyFromBlock, readUrlFromSegments } from '../cell/helpers';
import { getRelationRecords } from '../cell/relation/get-relation-records';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

export const InvalidAggregationValue = undefined;

interface Params {
  blocks: (NextBlock | undefined)[];
  users?: Record<string, UserDTO>;
  propertyId: string;
  schema: CollectionSchema;
  formulaTool: FormulaTool;
}

export const getColCellValues = ({
  blocks,
  propertyId,
  schema,
  formulaTool,
  users = getState().users,
}: Params) => {
  const allCellValues = blocks.map((block) => {
    if (!block) return undefined;
    switch (schema.type) {
      case CollectionSchemaType.CREATED_AT:
        return dayjs(block.createdAt).format(DATE_TIME_FORMAT); // 字符串用于比较时间长短

      case CollectionSchemaType.UPDATED_AT:
        return dayjs(block.updatedAt).format(DATE_TIME_FORMAT);

      case CollectionSchemaType.CREATED_BY:
        return block.createdBy;

      case CollectionSchemaType.UPDATED_BY:
        return block.updatedBy;

      case CollectionSchemaType.PERSON: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        const result = segments
          .filter((item) => item.type === TextType.USER && users[item.uuid ?? ''])
          .map((item) => item.uuid)
          .filter((uuid): uuid is string => !!uuid);

        return result;
      }

      case CollectionSchemaType.DATE: {
        const date = getDatePropertyFromBlock(block, propertyId);
        if (date) {
          return date.dateString;
        }
        return date;
      }

      case CollectionSchemaType.TITLE: {
        return segmentsToText(block.data.segments);
      }

      case CollectionSchemaType.NUMBER: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        let num = parseFloat(value);
        if (Number.isNaN(num)) {
          num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }

        if (!Number.isNaN(num)) return String(num);
        return undefined;
      }

      case CollectionSchemaType.FILE: {
        const segments = block.data.collectionProperties?.[propertyId] ?? [];
        const result = segments
          .filter((item) => item.type === TextType.URL && item.fileStorageType === 'internal')
          .map((segment) => segment.url)
          .filter((url): url is string => !!url); // 使用 url 才能计算唯一值

        return result;
      }

      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL: {
        const segments = block.data.collectionProperties?.[propertyId];
        return readUrlFromSegments(segments);
      }

      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        const text = segmentsToText(block.data.collectionProperties?.[propertyId]).trim();
        const tags = text
          .split(/\s*,\s*/g)
          .map((item) => schema.options?.find((option) => option.value === item)?.id)
          .filter((item): item is string => !!item);

        return schema.type === CollectionSchemaType.SELECT ? tags[0] : tags;
      }

      case CollectionSchemaType.FORMULA: {
        const type = formulaTool.getType(propertyId);
        const value = formulaTool.getValue(block.uuid, propertyId);
        const isBooleanType = type === formula.ValueTool.booleanType;
        if (isBooleanType) {
          return value ? 'YES' : 'NO';
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (value == null) {
          return undefined;
        }
        if (type === formula.ValueTool.dateType) {
          return dayjs(value as Date).format(DATE_TIME_FORMAT);
        }
        if (type === formula.ValueTool.numberType) {
          return formatFloat(value as number);
        }
        return `${value}`;
      }

      case CollectionSchemaType.RELATION: {
        const result = getRelationRecords(block.uuid, propertyId).relationRecords;
        return result;
      }

      case CollectionSchemaType.CHECKBOX: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        return formatCheckBoxValue(value) ? 'YES' : 'NO';
      }

      default: {
        const value = segmentsToText(block.data.collectionProperties?.[propertyId]);
        return value.trim();
      }
    }
  });

  const notEmptyCellValues = allCellValues.filter(
    (value): value is string | string[] =>
      (typeof value === 'string' && value !== '') || (Array.isArray(value) && value.length > 0)
  );

  return {
    allCellValues,
    notEmptyCellValues,
  };
};
