import type { RootState } from 'src/redux/types';
import { useCallback } from 'react';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { copyNormalizedValue } from 'src/bitable/table-view/cell/person';
import { getUserName } from '../user/use-remark-name';
import { request } from 'src/common/request';
import type { Store } from 'redux';
import { usersActions } from 'src/redux/reducers/users';
import { debounce } from 'lodash-es';

/** 多维表有些列属性是人员，需要把人员的信息拉下来 */
export const useTryFetchChartPerson = () => {
  return useCallback(
    async (option: {
      collectionId: string;
      viewId?: string;
      recordIds: string[];
      store: Store;
      callback: () => void;
    }) => {
      const { collectionId, viewId, store, recordIds, callback } = option;
      const state = store.getState() as RootState;
      const collection = state.blocks[collectionId];
      if (!collection) return;
      let subNodes = recordIds;
      if (!viewId) {
        subNodes = collection?.subNodes ?? [];
      }
      const schemas = collection?.data.schema;
      if (!schemas) return;
      const propertyIdToSchema: Map<string, CollectionSchema> = new Map();
      Object.keys(schemas).forEach((k) => {
        const schema = schemas[k];
        if (schema && isPersonSchemaType(schema.type)) {
          propertyIdToSchema.set(k, schema);
        }
      });
      const userIdSet = new Set<string>();
      propertyIdToSchema.forEach((schema, propertyId) => {
        subNodes.forEach((v) => {
          const subBlock = state.blocks[v];
          if (!subBlock) {
            return;
          }
          if (schema.type === CollectionSchemaType.PERSON) {
            const segments = subBlock.data.collectionProperties?.[propertyId];
            copyNormalizedValue(segments).forEach((user) => {
              const userName = getUserName(user.uuid ?? '', '');
              if (!userName) {
                user.uuid && userIdSet.add(user.uuid);
              }
            });
          } else if (schema.type === CollectionSchemaType.CREATED_BY) {
            if (subBlock.createdBy) {
              const userName = getUserName(subBlock.createdBy ?? '', '');
              if (!userName) {
                userIdSet.add(subBlock.createdBy);
              }
            }
          } else if (schema.type === CollectionSchemaType.UPDATED_BY) {
            if (subBlock.updatedBy) {
              const userName = getUserName(subBlock.updatedBy ?? '');
              if (!userName) {
                userIdSet.add(subBlock.updatedBy);
              }
            }
          }
        });
      });
      const userIds = [...userIdSet];
      if (userIds.length === 0) return;
      await scheduleFetchUser(store, userIds, callback);
    },
    []
  );
};
let peddingUserIds: string[] = [];
let callbacks: Function[] = [];
const scheduleFetchUser = async (store: Store, userIds: string[], callback: () => void) => {
  peddingUserIds.push(...userIds);
  callbacks.push(callback);
  const ids = [...new Set(peddingUserIds)];
  peddingUserIds = ids;
  return fetchUser(store, peddingUserIds);
};
const fetchUser = debounce(async (store: Store, userIds: string[]) => {
  peddingUserIds = [];
  const _callbacks = callbacks;
  callbacks = [];
  const info = await request.editor.queryRecords({
    requests: [...userIds].map((id) => {
      return {
        id,
        table: 'user',
      };
    }),
  });

  if (info.users) {
    store.dispatch(usersActions.updateSpaceUsers({ users: info.users }));
    _callbacks.forEach((f) => f());
    return true;
  }
}, 300);

const isPersonSchemaType = (type: CollectionSchemaType | undefined) => {
  return (
    type === CollectionSchemaType.PERSON ||
    type === CollectionSchemaType.CREATED_BY ||
    type === CollectionSchemaType.UPDATED_BY
  );
};
