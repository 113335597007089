class MermaidTask {
  private queue: {
    text: string;
    id: string;
    onError: (str: string) => void;
    onSuccess: (str: string) => void;
  }[] = [];
  private isRunning = false;

  private run = async () => {
    if (this.isRunning) {
      return;
    }
    this.isRunning = true;
    while (this.queue.length) {
      const task = this.queue.shift();
      if (!task) return;
      const { text, id, onError, onSuccess } = task;
      try {
        const { mermaid } = window as any;
        const res = await mermaid?.mermaidAPI.render(id, text);
        onSuccess(res.svg);
      } catch (err) {
        onError(err as string);
      }
    }
    this.isRunning = false;
  };

  public render = (params: typeof this.queue[0]) => {
    this.queue.push(params);
    void this.run();
  };
}

export const mermaidTask = new MermaidTask();
