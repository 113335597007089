import type { CollectionSchema } from '@next-space/fe-api-idl';
import { BlockStatus, BlockType, CollectionSchemaType } from '@next-space/fe-api-idl';
import { cache, dispatch } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';
import { v4 as uuid } from 'uuid';
import { getUpdatedAtAndUpdatedBy } from 'src/hooks/user/get-updated-user';
import type { NextBlock } from '@flowus/common/block/type';
import { blocksActions } from 'src/redux/reducers/blocks';
import { urlFetcher } from 'src/utils/url-fetcher';

/** 获取一个块内所有的图片块id */
export const getImageBlocks = (blockId: string) => {
  const block = cache.blocks[blockId];
  if (block?.type === BlockType.COLLECTION_VIEW_PAGE) {
    return getCollectionImageIds(blockId);
  }
  return getPageImageIds(blockId);
};

const getCollectionImageIds = (blockId: string) => {
  const block = cache.blocks[blockId];
  if (!block) return [];
  if (!block.data.schema) return [];
  const ossNames: string[] = [];
  const fileSchema: { id: string; schema: CollectionSchema }[] = [];
  Object.keys(block.data.schema).forEach((key) => {
    const schema = block.data.schema?.[key];
    // 找出所有file类型的列
    if (schema?.type === CollectionSchemaType.FILE) {
      fileSchema.push({ id: key, schema });
    }
  });
  // 保存所有ossName
  block.subNodes.forEach((v) => {
    const child = cache.blocks[v];
    fileSchema.forEach((schema) => {
      const property = child?.data.collectionProperties?.[schema.id];
      property?.forEach((v) => {
        v.url && ossNames.push(v.url);
      });
    });
  });
  // NOTE: 为了不改原来的逻辑，这里直接根据ossnames的数量，构造一些假的图片块,给后面的组件使用
  const fakeBlocks: Record<string, NextBlock> = {};
  const ids: string[] = [];
  ossNames.forEach((ossname) => {
    const fakeBlock = createFakeImageBlock(ossname, {
      spaceId: block.spaceId,
      parentId: block.uuid,
    });
    fakeBlocks[fakeBlock.uuid] = fakeBlock;
    ids.push(fakeBlock.uuid);
  });
  dispatch(blocksActions.update({ blocks: fakeBlocks }));
  // 这里主动拉一下数据缓存起来，因为后面的组件用的是fakeid，拉不到图片
  ossNames.forEach((ossName) => {
    void urlFetcher.fetchImageUrl({ blockId: block.uuid, ossName });
  });
  return ids;
};
const createFakeImageBlock = (
  ossName: string,
  options: {
    spaceId: string;
    parentId: string;
  }
) => {
  const newBlock: NextBlock = {
    uuid: uuid(),
    spaceId: options.spaceId,
    parentId: options.parentId,
    type: BlockType.IMAGE,
    textColor: '',
    backgroundColor: '',
    status: BlockStatus.NORMAL,
    version: 0,
    subNodes: [],
    permissions: [],
    createdAt: Date.now(),
    createdBy: '',
    data: {
      ossName,
    },
    ...getUpdatedAtAndUpdatedBy(),
  };
  return newBlock;
};

const getPageImageIds = (blockId: string) => {
  const block = cache.blocks[blockId];
  const imagesId: string[] = [];
  if (block?.type === BlockType.FILE && block.data.display === 'image' && block.data.ossName) {
    imagesId.push(blockId);
  } else if (
    block?.type === BlockType.EXTERNAL_FILE &&
    block.data.display === 'image' &&
    block.data.ossName
  ) {
    imagesId.push(blockId);
  } else if (block?.type === BlockType.REFERENCE && block.data.display === 'image') {
    imagesId.push(blockId);
  }
  block?.subNodes.forEach((subId) => {
    const subBlock = cache.blocks[subId];
    if (isPageLike(subBlock?.type)) {
      return;
    }
    const subImagesIds = getImageBlocks(subId);
    imagesId.push(...subImagesIds);
  });
  return imagesId;
};
