import { BlockType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import { getAncestors } from './use-get-ancestors';

export const useIsFoldListChildren = (uuid: string, stopAncestorId?: string): boolean => {
  const { blocks } = getState();
  const ancestors = getAncestors(uuid, blocks, stopAncestorId);

  const hasFoldList = [...ancestors].some((ancestor) =>
    [BlockType.FOLD_LIST, BlockType.TOGGLE_HEADER].includes(blocks[ancestor]?.type ?? 0)
  );

  return hasFoldList;
};
