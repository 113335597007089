import { useRef, useState, type FC } from 'react';
import { MAX_COL_WIDTH, MIN_COL_WIDTH, getPropertyWidth } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { useFreezeColumnIndex } from 'src/hooks/collection-view/use-collection-view';
import type { CollectionProperty } from 'src/hooks/collection-view/use-properties';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useReadonly } from 'src/hooks/page';
import { HIDDEN_CHECK_BOX_WDITH } from './hidden-checkbox';
import { Tooltip } from '@flowus/common/tooltip';
import { ResizeStatus, useResizeListener } from '@flowus/common/hooks/use-resize-listener';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { cx } from '@flowus/common/cx';
import { useDrag } from '@use-gesture/react';
import { useUpdateColumnWidth } from 'src/hooks/block/use-update-column-width';
import { clamp } from 'lodash-es';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { cache } from 'src/redux/store';

const SHADOW_WIDTH = 2;
/** 冻结列分割线 */
export const FreezeColumnLine: FC = () => {
  const { viewId } = useBitable();
  // 拖拽的时候会估算出即将落到哪个列上，会显示一根蓝线
  const [properties = []] = useProperties(viewId, {
    visible: true,
    includeIdNumber: true,
  });
  const columnIndex = useFreezeColumnIndex(viewId, properties);

  if (columnIndex === -1) return null;
  return <Line properties={properties} columnIndex={columnIndex} />;
};

const Line: FC<{ properties: CollectionProperty[]; columnIndex: number }> = (props) => {
  const { properties, columnIndex } = props;
  const { viewId, collectionId, embed } = useBitable();
  const readonly = useReadonly(collectionId, false);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const absoluteDivRef = useRef<HTMLDivElement>(null);
  const tipDivRef = useRef<HTMLDivElement>(null);
  // 拖拽的时候会估算出即将落到哪个列上，会显示一根蓝线
  const [targetColumnIndex, setTargetColumnIndex] = useState(-1);
  const isMoveColumnLine = targetColumnIndex !== -1;
  const [draging, setDraging] = useState(false);
  const pageId = useGetPageId();

  let totalWidth = readonly ? 0 : HIDDEN_CHECK_BOX_WDITH;
  let targeColumnTotaltWidth = readonly ? 0 : HIDDEN_CHECK_BOX_WDITH;
  // 下面的计算只有几行数据，不需要优化
  for (let i = 0; i <= columnIndex; i++) {
    const property = properties[i];
    const width = getPropertyWidth(property);
    totalWidth += width;
  }
  for (let i = 0; i <= targetColumnIndex; i++) {
    const property = properties[i];
    const width = getPropertyWidth(property);
    targeColumnTotaltWidth += width;
  }
  const freezeProperty = properties[columnIndex];
  const updateWidth = useUpdateColumnWidth(viewId, freezeProperty?.property ?? '');
  const bind = useDrag(
    ({ offset: [mx] }) => {
      updateWidth(clamp(mx, MIN_COL_WIDTH, MAX_COL_WIDTH));
    },
    { from: freezeProperty?.width, preventDefault: true }
  );
  const calculateColumnIndex = () => {
    const style = absoluteDivRef.current?.style;
    let computeColumnIndex = 0;
    if (style) {
      const rightX = parseInt(style.right, 10);
      const adjustWidth = totalWidth - rightX;
      let calcWidth = readonly ? 0 : HIDDEN_CHECK_BOX_WDITH;
      for (let i = 0; i <= properties.length; i++) {
        const property = properties[i];
        const width = getPropertyWidth(property);
        if (calcWidth + width / 2 < adjustWidth) {
          calcWidth += width;
        } else {
          computeColumnIndex = i - 1;
          break;
        }
      }
    }
    return computeColumnIndex;
  };
  const attribute = readonly ? {} : bind();
  // 假如算出来的跟原来的一样，则不显示原来那根线
  const hideRawLine = columnIndex === targetColumnIndex;
  useResizeListener(resizeDivRef, {
    type: 'all',
    onResize(result) {
      switch (result.resizeStatus) {
        case ResizeStatus.START: {
          if (readonly) return;
          setDraging(true);
          setTargetColumnIndex(columnIndex);

          const scrollContainer = absoluteDivRef.current?.closest('.will-change-scroll');
          if (!scrollContainer) return;
          // 96是block-content的parent的padding
          const padding = embed && cache.blocks[pageId]?.data?.pageFixedWidth ? 0 : 96;
          if (scrollContainer.scrollLeft <= padding) return;
          if (scrollContainer) {
            scrollContainer.scroll({
              left: padding,
            });
          }
          break;
        }
        case ResizeStatus.RESIZING: {
          if (readonly) return;
          if (!absoluteDivRef.current) return;
          const { style } = absoluteDivRef.current;
          const computeColumnIndex = calculateColumnIndex();
          // 算出预计落在哪一列
          if (computeColumnIndex !== -1 && targetColumnIndex !== computeColumnIndex) {
            setTargetColumnIndex(computeColumnIndex);
          }
          // 拖拽的ui位置
          if (style) {
            let rightX = parseInt(style.right, 10);
            rightX -= result.deltaX;
            style.right = `${rightX}px`;
          }
          const tipStyle = tipDivRef.current?.style;
          if (tipStyle) {
            const { top } = absoluteDivRef.current.getBoundingClientRect();
            const computeY = result.downY - top + result.moveY;
            tipStyle.top = `${computeY}px`;

            let rightX = parseInt(tipStyle.right, 10);
            rightX -= result.deltaX;
            tipStyle.right = `${rightX}px`;
          }
          break;
        }
        case ResizeStatus.END: {
          if (readonly) return;
          setDraging(false);
          // 算出具体冻结哪一列
          const computeColumnIndex = calculateColumnIndex();
          const style = absoluteDivRef.current?.style;
          const tipStyle = tipDivRef.current?.style;
          // reset
          if (style) {
            style.right = `${0}px`;
          }
          if (tipStyle) {
            tipStyle.right = `${0}px`;
          }
          // reset
          setTargetColumnIndex(-1);
          updateViewFormat(viewId, {
            // -1表示解除冻结
            tableFreezeColumnIndex: computeColumnIndex,
          });
          break;
        }
        default:
      }
    },
  });

  return (
    <div
      ref={containerDivRef}
      // 37是header占据的高度，73是底部占据的地方，还有一些border占据的高度，加起来113px
      // className={cx('flex top-0 absolute w-full h-[calc(100%-113px)]', {
      className={cx('flex top-0 absolute w-full h-[calc(100%-73px)]', {
        'pointer-events-none': !isMoveColumnLine, // 不要改
        'cursor-col-resize': isMoveColumnLine,
      })}
    >
      <div className="flex relative w-full">
        <div
          className="flex sticky left-0 w-full h-full z-[12] "
          style={{
            width: totalWidth + SHADOW_WIDTH,
          }}
        >
          {/* 占据左侧位置 */}
          <div
            className="relative "
            style={{
              width: totalWidth,
            }}
          />
          <Tooltip
            popup={isMoveColumnLine || readonly ? '' : '按住拖动调整冻结列'}
            delay={{ open: 500 }}
          >
            {/* 这个div主要是为了扩大拖拽的范围 */}
            <div
              ref={resizeDivRef}
              data-disable-select
              className={cx('group pointer-events-auto absolute h-full', {
                'cursor-col-resize': !readonly,
              })}
              style={{
                right: '1px',
                width: '5px',
              }}
            >
              <div
                className={cx('absolute mt-[39px] bg-grey6 ', {
                  hidden: hideRawLine,
                  'group-hover:bg-grey6': isMoveColumnLine, // 如果move的话就恢复原状
                  'group-hover:bg-black/50': !readonly,
                })}
                style={{
                  right: '1px',
                  width: SHADOW_WIDTH,
                  height: judgeSharePage() ? '100%' : 'calc(100% - 39px)',
                }}
              />
            </div>
          </Tooltip>
          {isMoveColumnLine && (
            <div
              ref={absoluteDivRef}
              className="absolute h-full bg-black/50"
              style={{
                right: '2px',
                width: SHADOW_WIDTH,
              }}
            />
          )}
          {/* 由于还要支持拖拽，这里只能搞个拖拽的线，原本那个会被挡住 */}
          <div
            {...attribute}
            style={{ touchAction: 'none' }}
            className={cx('absolute pointer-events-auto  bg-grey6 h-[39px] w-0.5 right-0.5 ', {
              hidden: hideRawLine,
              'hover:bg-black hover:right-px hover:w-1 cursor-col-resize': !readonly,
            })}
          />
          {/* 这是蓝线 */}
          {draging && isMoveColumnLine && (
            <>
              <div
                // className="w-0.5 absolute bg-active_color/80  h-[calc(100%+39px)]"
                className="w-0.5 absolute bg-active_color/80  h-[calc(100%)] "
                style={{
                  left: `${targeColumnTotaltWidth - SHADOW_WIDTH}px`,
                }}
              />
              <div
                ref={tipDivRef}
                className="absolute px-2 text-t4-medium py-1 bg-black2 rounded whitespace-pre-wrap text-white"
                style={{
                  right: '-100px',
                  top: '0px',
                }}
              >{`冻结第${targetColumnIndex + 1}列`}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
