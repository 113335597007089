import type { DragEndEvent } from '@dnd-kit/core';
import isHotkey from 'is-hotkey';
import type { FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import { SortableList } from 'src/common/components/sortable-list';

import type { Option } from '../../types';
import { OptionItem } from './option-item';

interface Props {
  options: Option[];
  readonly?: boolean;
  selectOption?: React.MutableRefObject<number | undefined>;
  onSelect(option: Option, event: MouseEvent): void;
  onMore(option: Option, event: MouseEvent): void;
  onSort(oldId: string, newId: string): void;
  onFocus(option?: Option): void;
}

export const Options: FC<Props> = ({
  readonly,
  options,
  onSelect,
  onMore,
  onSort,
  onFocus,
  selectOption,
}) => {
  const [focusIndex, setFocusIndex] = useState(-1);

  useEffect(() => {
    const focusOption = options[focusIndex];
    onFocus(focusOption);
  }, [focusIndex, onFocus, options]);

  // 键盘上下选择的时候需要控制 scroll list 滚动
  useEffect(() => {
    const scrollInToView = (index: number) => {
      const id = options[index]?.id;
      if (!id) return;
      document.querySelector(`[data-option-id="${id}"]`)?.scrollIntoView({
        block: 'nearest',
      });
    };

    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('ArrowUp')(event)) {
        event.stopPropagation();
        event.preventDefault();
        setFocusIndex((prev) => {
          const index = Math.max(0, prev - 1);
          scrollInToView(index);
          selectOption && (selectOption.current = index);
          return index;
        });
      }
      if (isHotkey('ArrowDown')(event)) {
        event.stopPropagation();
        event.preventDefault();
        setFocusIndex((prev) => {
          const index = Math.min(options.length - 1, prev + 1);
          scrollInToView(index);
          selectOption && (selectOption.current = index);
          return index;
        });
      }
    };

    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
  }, [options, selectOption]);

  /** 更新 options 排序 */
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over) return;
    onSort(active.id.toString(), over.id.toString());
  };

  return (
    <SortableList
      virtual
      disabled={readonly}
      items={options}
      onChange={(_, event) => handleDragEnd(event)}
      renderItemContent={({ item }) => {
        return (
          <OptionItem
            readonly={readonly}
            active={item.id === options[focusIndex]?.id}
            onMore={(event) => onMore(item, event)}
            onSelect={(event) => onSelect(item, event)}
            option={item}
          />
        );
      }}
    />
  );
};
