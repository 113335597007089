import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IFormat, IPerformChangeContext } from '@next-space/fe-inlined';
import { findContainerItemPath } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { ColorKey } from 'src/colors';
import { CODE_TAG } from 'src/editor/editor/inline/const';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { HISTORY_EFFECTS, UPDATE_BLOCK } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import type { SegmentType } from 'src/redux/types';
import { useTransaction } from '../use-transaction';
import { getHitTextBySymbol } from './helper';
import { performChangeShortcutEquation } from './toolbar-shortcut/use-shortcut-equation';
import { useMinusKey } from './use-minus-key';

export const useMarkDownKey = (uuid: string, segmentType: SegmentType) => {
  const transaction = useTransaction();
  const focusEditableAt = useFocusEditableByBlockId();
  const getEditorModel = useGetOrInitEditorModel();
  const minusKey = useMinusKey(uuid);

  const markDownKey = async (event: CustomEvent) => {
    const { content: eventKey } = event.detail;
    const model = getEditorModel(uuid);
    if (!model) return;

    const deferRemoveFormat = (key: keyof IFormat) => {
      setTimeout(() => {
        model.performChange((ctx) => {
          const { selection } = model;
          if (!selection) return;
          const right = selection.offset;
          ctx.select(right, right).setFormat({ [key]: false });
        });
      });
    };

    const patchSegmentAndFocusHistory = (option: {
      intFocus: number;
      redoFocus: number;
      undoFocus: number;
      segments: SegmentDTO[];
    }) => {
      const { redoFocus, intFocus, undoFocus, segments } = option;
      transaction(() => {
        dispatch(UPDATE_BLOCK({ uuid, patch: { data: { [segmentType]: segments } } }));
        dispatch(
          HISTORY_EFFECTS({
            init() {
              focusEditableAt(uuid, intFocus);
            },
            redo() {
              focusEditableAt(uuid, redoFocus);
            },
            undo() {
              focusEditableAt(uuid, undoFocus);
            },
          })
        );
      });
    };

    // 下划线 <u> | 高亮 ==
    if (['>', '='].includes(eventKey)) {
      const isUnderline = eventKey === '>';
      const isHighlight = eventKey === '=';
      const markDownKeyEnum: Record<string, string> = {
        '=': '==',
        '>': '<u>',
      };
      const symbol = markDownKeyEnum[eventKey] ?? `${eventKey}`;

      const { hitText, lastSameIndex } = getHitTextBySymbol({
        symbol,
        model,
      });

      const { selection } = model;
      if (!selection) return;

      const { focusOffset } = selection;

      if (!hitText) return;

      patchSegmentAndFocusHistory({
        intFocus: focusOffset,
        redoFocus: focusOffset,
        undoFocus: focusOffset + symbol.length,
        segments: convertContentToSegments(model.content),
      });

      model.performChange((ctx) => {
        const left = lastSameIndex;
        const right = selection.offset;

        const shadowContext = ctx.shadow().select(left, right);
        const format = { ...shadowContext.format };

        // 下划线
        if (isUnderline) {
          shadowContext.applyFormat({ underline: !format.underline });
          ctx.select(right, right).setFormat({ underline: false });
          deferRemoveFormat('underline');
        }

        // 高亮
        if (isHighlight) {
          shadowContext.applyFormat({
            color: undefined,
            backgroundColor: format.backgroundColor ? undefined : ColorKey.yellow,
          });

          ctx.select(right, right).setFormat({
            color: undefined,
            backgroundColor: ColorKey.yellow,
          });
          deferRemoveFormat('color');
        }

        shadowContext
          .select(right - symbol.length, right)
          .delete()
          .select(left - symbol.length, left)
          .delete()
          .release();
      });

      patchSegmentAndFocusHistory({
        intFocus: focusOffset - symbol.length,
        redoFocus: focusOffset - symbol.length,
        undoFocus: focusOffset + symbol.length,
        segments: convertContentToSegments(model.content),
      });
      return;
    }

    const subscribe = model.onAfterInput.subscribe(() => {
      subscribe.unsubscribe();

      const { selection } = model;
      if (!selection) return;

      const { isCollapsed, focusOffset } = selection;
      if (!isCollapsed || focusOffset < 2) return;

      // 加粗
      const boldSymbols = ['*', '_'];
      if (boldSymbols.includes(eventKey)) {
        const { hitText, lastSameIndex } = getHitTextBySymbol({
          symbol: `${eventKey}${eventKey}`,
          model,
        });

        if (hitText) {
          const oldSegments = convertContentToSegments(model.content);
          patchSegmentAndFocusHistory({
            intFocus: focusOffset - 4,
            redoFocus: focusOffset,
            undoFocus: focusOffset - 1,
            segments: oldSegments,
          });

          model.performChange((ctx) => {
            const left = lastSameIndex;
            const right = selection.offset;

            const shadowContext = ctx.shadow().select(left, right);
            const format = { ...shadowContext.format };

            shadowContext.applyFormat({ bold: !format.bold });
            ctx.select(right, right).setFormat({ bold: false });
            deferRemoveFormat('bold');

            shadowContext
              .select(right - 2, right)
              .delete()
              .select(left - 2, left)
              .delete()
              .release();
          });

          patchSegmentAndFocusHistory({
            intFocus: focusOffset - 2,
            redoFocus: focusOffset - 2,
            undoFocus: focusOffset,
            segments: convertContentToSegments(model.content),
          });

          return;
        }
      }

      // 输入 * 有两个case ，一个是加粗，一个是分割线，上面if判断加粗，此处判断分割线
      if (['*'].includes(eventKey)) {
        minusKey(event, 3);
      }

      // ··· 行内代码
      const convertInlineCode = (options: {
        shadowContext: IPerformChangeContext & {
          release(): void;
        };
        left: number;
        right: number;
        size: number;
      }) => {
        const { shadowContext, left, right, size } = options;
        const codePath = findContainerItemPath(
          model.content,
          left + size,
          right - size,
          (item) => item.type === 'element' && item.tag === CODE_TAG
        );

        if (codePath.length > 0) {
          shadowContext.unwrap(CODE_TAG, codePath);
        } else {
          shadowContext.select(left, right - size);
          shadowContext.wrap(CODE_TAG, {});
        }

        if (shadowContext.selection) {
          shadowContext
            .shadow()
            .select(shadowContext.selection.offset - size, shadowContext.selection.offset)
            .delete()
            .release();
          shadowContext
            .shadow()
            .select(shadowContext.selection.endOffset, shadowContext.selection.endOffset + size)
            .delete()
            .release();
          shadowContext.release();
        }
      };

      if (['`', '·'].includes(eventKey)) {
        const isInlineCodeSymbol = getHitTextBySymbol({
          symbol: `${eventKey}${eventKey}${eventKey}`,
          model,
        });
        if (isInlineCodeSymbol.hitText) {
          const oldSegments = convertContentToSegments(model.content);
          patchSegmentAndFocusHistory({
            intFocus: focusOffset - 3,
            redoFocus: focusOffset - 3,
            undoFocus: focusOffset,
            segments: oldSegments,
          });

          model.performChange((ctx) => {
            const left = isInlineCodeSymbol.lastSameIndex;
            const right = selection.offset;

            const shadowContext = ctx.shadow().select(left, right);

            convertInlineCode({
              shadowContext,
              left,
              right,
              size: 3,
            });
          });

          patchSegmentAndFocusHistory({
            intFocus: focusOffset - 3,
            redoFocus: focusOffset - 3,
            undoFocus: focusOffset,
            segments: convertContentToSegments(model.content),
          });

          return;
        }
      }

      const singleSymbolMatch = getHitTextBySymbol({
        symbol: eventKey,
        model,
      });
      if (singleSymbolMatch.hitText) {
        const oldSegments = convertContentToSegments(model.content);
        patchSegmentAndFocusHistory({
          intFocus: focusOffset - 2,
          redoFocus: focusOffset,
          undoFocus: focusOffset - 1,
          segments: oldSegments,
        });

        model.performChange((ctx) => {
          const left = singleSymbolMatch.lastSameIndex;
          const right = selection.offset;

          const shadowContext = ctx.shadow().select(left, right);
          const format = { ...shadowContext.format };

          switch (eventKey) {
            // 公式
            case '$': {
              shadowContext
                .select(right - 1, right)
                .delete()
                .select(left - 1, left)
                .delete()
                .release();

              const equationContext = ctx.select(left - 1, right - 2);
              performChangeShortcutEquation(model, { ctx, selection: equationContext.selection });
              return;
            }
            // 斜体
            case '*':
            case '_': {
              shadowContext.applyFormat({ italic: !format.italic });
              ctx.select(right, right).setFormat({ italic: false });
              deferRemoveFormat('italic');
              break;
            }

            // 中划线
            case '~':
            case '～': {
              shadowContext.applyFormat({ lineThrough: !format.lineThrough });
              ctx.select(right, right).setFormat({ lineThrough: false });
              deferRemoveFormat('lineThrough');
              break;
            }

            // 代码块
            case '`':
            case '·': {
              convertInlineCode({
                shadowContext,
                left,
                right,
                size: 1,
              });
              return;
            }

            default:
          }

          shadowContext
            .select(right - 1, right)
            .delete()
            .select(left - 1, left)
            .delete()
            .release();
        });

        const segments = convertContentToSegments(model.content);
        let gap = 2;
        if (eventKey === '`' || eventKey === '·') {
          gap = 0; // 生成代码块后，光标应该定位到代码块后面，不在代码块内
        }
        patchSegmentAndFocusHistory({
          intFocus: focusOffset - gap,
          redoFocus: focusOffset - gap,
          undoFocus: focusOffset,
          segments,
        });
      }
    });
  };

  return useCallback(markDownKey, [
    focusEditableAt,
    getEditorModel,
    minusKey,
    segmentType,
    transaction,
    uuid,
  ]);
};
