import {
  getBlockBackgroundColor,
  getBlockTextColor,
  getMarkBlockBackgroundColor,
} from '@flowus/common/block/color/get-block-color';
import { BlockType } from '@next-space/fe-api-idl';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useIsDarkMode } from '../public/use-theme';

export const useBlockStyle = (uuid: string): Pick<CSSProperties, 'backgroundColor' | 'color'> => {
  const isDark = useIsDarkMode();
  const blockStyle = useObservableStore(
    ({ blocks }) => {
      const block = blocks[uuid];
      if (!block) return;

      const parent = blocks[block.parentId];
      if (
        parent?.type === BlockType.COLLECTION_VIEW ||
        parent?.type === BlockType.COLLECTION_VIEW_PAGE
      ) {
        return;
      }

      return {
        type: block.type,
        textColor: block.textColor,
        backgroundColor: block.backgroundColor,
      };
    },
    [uuid],
    { wait: 500, waitMode: 'debounce', ignoreDeep: true }
  );

  return useMemo(() => {
    if (blockStyle === undefined) return {};
    let backgroundColor = getBlockBackgroundColor(blockStyle.backgroundColor);
    if (blockStyle.type === BlockType.MARK) {
      backgroundColor = getMarkBlockBackgroundColor(blockStyle.backgroundColor);
    }
    return {
      color: getBlockTextColor(blockStyle.textColor),
      backgroundColor,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockStyle, isDark]);
};
