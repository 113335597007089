import type { FC } from 'react';
import { Images } from 'src/image';

export const Empty: FC<{ className?: string }> = (props) => {
  return (
    <div className={props.className}>
      <div className="text-h2">使用模板，高效创作</div>
      <div className="text-grey3 text-t2 mt-2.5">
        将经常使用的页面转换为模板，避免重复编辑。你还可以分享给团队成员，提高团队效率。
      </div>
      <div className="w-full max-w-4xl">
        <img src={Images.templatesEmpty} className="mt-7 " />
      </div>
    </div>
  );
};
