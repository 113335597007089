import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import { memo, useEffect, useRef, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { WEEKDAYS_SHORT, formatWeekText } from 'src/utils/date-utils';
import { ONE_HOURS, ONE_MINUTES, ONE_MOMENT, UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { formatDate, getMonthDays, isWeekOrMonth } from '../utils/get-timeline-dates';
import { TimelineToday } from './today';
import { TIME_FORMAT } from 'src/common/const';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';

export const TimelineDates = memo(() => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const timer = useRef<Timeout>();
  const { containerWidth, timelineDates, datesContainer, zoomLevel } = useTimeline();
  const unitWidth = UnitWidthMap[zoomLevel];

  useEffect(() => {
    if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
      const callback = () => {
        timer.current = setTimeout(callback, ONE_MINUTES);
        setCurrentTime(Date.now());
      };

      timer.current = setTimeout(callback, ONE_MINUTES);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [zoomLevel]);

  const renderDate = (date: number) => {
    let renderDate = date;
    if (zoomLevel !== ZoomLevel.DAY && zoomLevel !== ZoomLevel.HOUR) {
      renderDate = date + 12 * ONE_HOURS;
    }

    let width = 0;
    let text: string | number = '';

    if (isWeekOrMonth(zoomLevel)) {
      if (zoomLevel === ZoomLevel.WEEK || zoomLevel === ZoomLevel.BIWEEK) {
        text = formatWeekText(
          `${WEEKDAYS_SHORT[formatDate(renderDate).day]}  ${formatDate(renderDate).date}`
        );
      } else {
        text = formatDate(renderDate).date;
      }
      width = unitWidth;
    } else if (zoomLevel === ZoomLevel.YEAR) {
      text = `${formatDate(renderDate).month}月`;
      width = unitWidth * (getMonthDays(renderDate as number) as number);
    } else if (zoomLevel === ZoomLevel.QUARTER) {
      text = `${formatDate(renderDate).date}`;
      width = unitWidth * 7;
    } else if (zoomLevel === ZoomLevel.DAY) {
      text =
        Math.abs(currentTime - renderDate) < ONE_HOURS
          ? ''
          : dayjs(new Date(renderDate)).format('HH:00');
      width = unitWidth * 4;
    } else if (zoomLevel === ZoomLevel.HOUR) {
      text =
        Math.abs(currentTime - renderDate) < ONE_MOMENT
          ? ''
          : dayjs(new Date(renderDate)).format(TIME_FORMAT);
      width = unitWidth;
    }

    return (
      <div
        key={renderDate}
        className={cx(
          'inline-flex h-full items-center',
          zoomLevel !== ZoomLevel.DAY &&
            zoomLevel !== ZoomLevel.HOUR &&
            zoomLevel !== ZoomLevel.QUARTER &&
            'justify-center'
        )}
        style={{ width }}
      >
        {zoomLevel === ZoomLevel.DAY ||
        zoomLevel === ZoomLevel.HOUR ||
        zoomLevel === ZoomLevel.QUARTER ? (
          <span className="-translate-x-1/2"> {text}</span>
        ) : (
          text
        )}
      </div>
    );
  };

  return (
    <div
      ref={datesContainer}
      className="text-t2-medium relative z-30 h-[38px] border-b bg-white1 py-1.5 font-medium text-grey4"
      style={{ width: containerWidth }}
    >
      {timelineDates.map((date) => {
        return renderDate(date);
      })}
      <TimelineToday />
    </div>
  );
}, shallowEqual);
