import { BlockType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';

import { getAncestors } from './use-get-ancestors';

export const useIsMarkListChildren = (uuid: string, stopAncestorId?: string): boolean => {
  const { blocks } = getState();
  const ancestors = getAncestors(uuid, blocks, stopAncestorId);

  return [...ancestors].some((ancestor) => blocks[ancestor]?.type === BlockType.MARK);
};
