import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { isPageLike } from 'src/utils/block-type-utils';
import { getRefBlock } from 'src/utils/block-utils';

export const useMentionLinkPage = (uuid: string) => {
  return useObservableStore(
    ({ blocks }) => {
      const set: Set<string> = new Set();
      const block = blocks[uuid];
      if (!block) return [];

      const handleSegment = (segments?: SegmentDTO[]) => {
        segments?.forEach((segment) => {
          if (segment.type === TextType.LINK_PAGE && segment.uuid) {
            set.add(segment.uuid);
          }
        });
      };

      const loop = (id: string) => {
        const _block = blocks[id];
        if (!_block) return;
        if (_block.type === BlockType.REFERENCE) {
          const refBlock = getRefBlock(id, blocks);
          if (refBlock) {
            set.add(refBlock.uuid);
          }
        }
        const { segments, caption, collectionProperties = {} } = _block.data;
        handleSegment(segments);
        handleSegment(caption);
        if (collectionProperties !== null) {
          Object.keys(collectionProperties).forEach((key) =>
            handleSegment(collectionProperties[key])
          );
        }
        if (isPageLike(_block.type)) return;
        _block.subNodes.forEach(loop);
      };

      block.subNodes.forEach(loop);
      handleSegment(block.data.segments);

      return [...set];
    },
    [uuid],
    { wait: 2000, waitMode: 'debounce' }
  );
};
