import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { getViewFormat } from './get-view-format';

interface Params {
  viewId: string;
  groupName: string | undefined;
  visible: boolean;
  isSubGroup?: boolean;
}
export const useUpdateGroupVisible = () => {
  const updateGroupVisible = ({ viewId, groupName, visible, isSubGroup }: Params) => {
    const { view, groups, subGroups, subGroupBy, groupBy } = getViewFormat(viewId) ?? {};
    const isBoard = view?.type === CollectionViewType.BOARD;

    const newGroups = (isSubGroup ? subGroups : groups)?.map((group) => {
      if ((!group.value && !groupName) || group.value === groupName) {
        return {
          ...group,
          visible,
          emptyHidden:
            visible && (isSubGroup ? subGroupBy?.hideEmptyGroups : groupBy?.hideEmptyGroups)
              ? false
              : group.emptyHidden,
        };
      }
      return group;
    });

    const isBoardColumn = isBoard && !isSubGroup;
    updateViewFormat(viewId, {
      [`${isBoardColumn ? 'boardGroups' : 'collectionGroups'}`]: newGroups,
    });
  };

  return useCallback(updateGroupVisible, []);
};
