import type { ModalSchema } from '@flowus/common/next-modal';
import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { ListItemType } from 'src/common/components/list-view/types';
import { getAllProperties } from 'src/utils/collection-util';
import { ListView } from 'src/common/components/list-view';
import { cx } from '@flowus/common/cx';
import type { GroupLevel } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { PRIORITY_DIALOG } from '@flowus/common/hooks';
import { GroupLevelMap } from 'src/bitable/bitable-manager/group-list/const';
import { uuid4 } from '@sentry/utils';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import { formulaIsValid } from 'src/bitable/bitable-manager/group-list/helper';
import { getState } from 'src/redux/store';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';

const modalId = uuid4();

/** 选择多维表属性字段 */
export const useSelectProperty = () => {
  const store = useStore();
  const openModal = useOpenModal();
  return useCallback(
    (
      collectionId: string,
      option: {
        popcorn: ModalSchema.PopcornType;
        callback: (propertyId: string, groupLevel?: GroupLevel) => void;
        excludeIds?: string[]; // 如果不需要的话select列表会去掉
        className?: string;
        offset?: number[];
      }
    ) => {
      let allProperties = getAllProperties(store, collectionId, option.excludeIds) ?? [];
      allProperties = allProperties.filter((v) => v.type !== CollectionSchemaType.ROLLUP);
      const listItems = allProperties.map((item) => {
        // 公式需要判断一下具体类型
        if (item.type === CollectionSchemaType.FORMULA) {
          const { propertySchema } = getPropertySchema(collectionId, item.value);
          if (propertySchema) {
            let schemaType = propertySchema.type;
            if (
              propertySchema.type === CollectionSchemaType.FORMULA &&
              formulaIsValid(collectionId, item.value, propertySchema.formula)
            ) {
              const tableSchema = getState().blocks[collectionId]?.data.schema;
              if (tableSchema) {
                const formulaTool = getFormulaTool(collectionId);
                schemaType = formulaTool.getTypeAsCollectionSchemaType(item.value);
                item.type = schemaType;
              }
            }
          }
        }
        const isDateProperty =
          item.type &&
          [
            CollectionSchemaType.DATE,
            CollectionSchemaType.CREATED_AT,
            CollectionSchemaType.UPDATED_AT,
          ].includes(item.type);
        // 日期还可以选择相对或者按日周月年来区分
        if (isDateProperty) {
          return {
            type: ListItemType.OPERATION,
            data: {
              title: item.title,
              icon: item.icon,
              propertyId: item.value,
              renderSubMenu: () => {
                const groupLevels = GroupLevelMap['date'];
                const subItems = groupLevels.map((v) => {
                  return {
                    type: ListItemType.OPERATION,
                    data: {
                      title: v.name,
                      onClick: () => {
                        option.callback(item.value, v.type);
                        openModal.closeModal(modalId);
                      },
                    },
                  };
                });

                return (
                  <ListView
                    className="next-modal py-0.5"
                    items={subItems}
                    keyDownPriority={PRIORITY_DIALOG + 1}
                  />
                );
              },
            },
          };
        }

        return {
          type: ListItemType.OPERATION,
          data: {
            title: item.title,
            icon: item.icon,
            propertyId: item.value,
            onClick: () => {
              option.callback(item.value);
            },
          },
        };
      });
      openModal.dropdown({
        modalId,
        placement: 'bottom',
        offset: [0, 0],
        popcorn: option.popcorn,
        content: ({ onCloseModal }) => {
          return (
            <ListView
              className={cx(
                'next-modal min-w-[100px] max-h-[40vh] overflow-auto py-0.5',
                option.className
              )}
              items={listItems}
              onItemClick={() => {
                onCloseModal();
              }}
            />
          );
        },
      });
    },
    [openModal, store]
  );
};
