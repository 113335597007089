import { BlockType } from '@next-space/fe-api-idl';
import type { NextBlock } from 'src/redux/types';
export { isPageLike } from '@flowus/common/block/permissions';
export { PAGE_TYPES } from '@flowus/common/block/type';

type EditableType =
  | BlockType.TEXTAREA
  | BlockType.HEADER
  | BlockType.TODO
  | BlockType.LIST
  | BlockType.ORDER_LIST
  | BlockType.FOLD_LIST
  | BlockType.TOGGLE_HEADER
  | BlockType.MARK
  | BlockType.QUOTE
  | BlockType.CODE;

export const isEditableType = (type?: unknown): type is EditableType => {
  if (type == null) return false;
  return [
    BlockType.TEXTAREA,
    BlockType.HEADER,
    BlockType.TODO,
    BlockType.LIST,
    BlockType.ORDER_LIST,
    BlockType.FOLD_LIST,
    BlockType.TOGGLE_HEADER,
    BlockType.MARK,
    BlockType.QUOTE,
    BlockType.CODE,
  ].includes(type as BlockType);
};

export const isTextareaBlockType = (type?: unknown): type is BlockType.TEXTAREA => {
  if (type == null) return false;
  return type === BlockType.TEXTAREA;
};

export const isIndentbleType = (block?: NextBlock): boolean => {
  if (!block) return false;
  // 由于思维导图支持图片块下加子节点，因此文档也需要保持一致
  if (block.type === BlockType.FILE && block.data.display === 'image') return true;

  return [
    BlockType.TEXTAREA,
    BlockType.LIST,
    BlockType.ORDER_LIST,
    BlockType.FOLD_LIST,
    BlockType.TOGGLE_HEADER,
    BlockType.TEMPLATE,
    BlockType.TODO,
    BlockType.MARK,
    BlockType.QUOTE,
    BlockType.HEADER,
    BlockType.MIND_MAPPING,
  ].includes(block.type as BlockType);
};

export const isCollection = (type?: BlockType) => {
  return (
    type === BlockType.COLLECTION_VIEW_PAGE ||
    type === BlockType.COLLECTION_VIEW ||
    type === BlockType.REFERENCE_COLLECTION ||
    type === BlockType.REFERENCE_COLLECTION_PAGE
  );
};

/** 是否支持添加说明块 */
export const supportCaption = (block?: Pick<NextBlock, 'data' | 'type'>) => {
  if (!block) return false;
  // https://flowus.cn/b14175a9-375e-4b3c-acc8-27b5b0902172
  if (
    (block.type === BlockType.FILE && block.data.ossName) ||
    (block.type === BlockType.EXTERNAL_FILE && block.data.link) ||
    (block.type === BlockType.REFERENCE && block.data.display === 'image')
  ) {
    return true;
  }
  if ((block.type === BlockType.EMBED || block.type === BlockType.BOOKMARK) && block.data.link) {
    return true;
  }
  if (block.type === BlockType.CODE) {
    return true;
  }
  if (block.type === BlockType.CHART && block.data.format?.chartFormat?.ref?.uuid) return true;
};

export const showCaption = (block?: Pick<NextBlock, 'data' | 'type'>) => {
  return supportCaption(block) && block?.data.showEmptyCaption;
};

export const isLinkPageBlock = (block?: Pick<NextBlock, 'type' | 'data'>) => {
  if (!block) return false;
  return (
    (block.type === BlockType.REFERENCE && block.data.display === 'linkPage') ||
    block.type === BlockType.REFERENCE_COLLECTION ||
    block.type === BlockType.REFERENCE_COLLECTION_PAGE
  );
};

export const isLinkTableBlock = (block?: Pick<NextBlock, 'type'>) => {
  if (!block) return false;
  return (
    block.type === BlockType.REFERENCE_COLLECTION ||
    block.type === BlockType.REFERENCE_COLLECTION_PAGE
  );
};
export const isChartBlock = (block?: Pick<NextBlock, 'type'>) => {
  if (!block) return false;
  return block.type === BlockType.CHART;
};

export const isMindMap = (type?: BlockType) => {
  return type === BlockType.MIND_MAPPING || type === BlockType.MIND_MAPPING_PAGE;
};

export const isImageBlock = (block?: NextBlock) => {
  if (!block) return false;
  const isImageBlock =
    block.type === BlockType.FILE &&
    block.data.display === 'image' &&
    (block.data.link || block.data.ossName);

  return isImageBlock;
};
