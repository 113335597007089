import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect } from 'react';
import { AIEditorFrom, AIEditorScene } from 'src/editor/editor/uikit/ai-editor/const';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { DESCRIPTION_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useReadonly } from 'src/hooks/page';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { focusEditable } from 'src/utils/editor';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';

interface Props {
  uuid: string;
  showDescription: boolean;
}

export const PageDescription: FC<Props> = (props) => {
  const { uuid, showDescription } = props;
  const mockId = `${uuid}_mock`; // 跟CodeEditor类似的原因需要mock id
  const block = usePickBlock(uuid, ['data'], ['description', 'showEmptyDescription']);
  const readonly = useReadonly(props.uuid);
  const { enableAI } = useEnableAI();
  const openAIEditor = useOpenAIEditor();
  const isInRight = useIsInRight();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const hasDescription = useObservableBlock(uuid, (block) => {
    return segmentsToText(block?.data.description).length > 0;
  });
  const updateSegments = useThrottleUpdateSegments(uuid, 'description');
  useEffect(() => {
    if (block?.data.showEmptyDescription) {
      void focusEditable(mockId, 0, 0);
    }
  }, [block?.data.showEmptyDescription, mockId]);

  if ($searchParams.print) return null;
  if (!showDescription) return null;

  if (block?.data.showEmptyDescription || hasDescription) {
    return (
      <RichTextEdit
        keydownOption={DESCRIPTION_KEYDOWN_OPTION}
        uuid={mockId}
        data-disable-select="true"
        className={'text-t2 break-words whitespace-pre-wrap page-description'}
        placeholder={'在此添加说明'}
        alwaysShowPlaceholder={true}
        onKeyDown={(event) => {
          if (enableAI && isHotkey('Space')(event)) {
            if (!segmentsToText(block?.data.description ?? [])) {
              const popcorn = querySelectorFromMainContent(`.page-description`, isInRight);
              if (popcorn) {
                void openAIEditor({
                  popcorn,
                  blockId: uuid,
                  from: AIEditorFrom.ContextMenu,
                  isBitable: true,
                  editorScene: AIEditorScene.BlockSelected,
                  isDescription: true,
                });
                return;
              }
            }
          }

          if (isHotkey(['Enter', 'Shift+Enter'])(event)) {
            event.preventDefault();
            const editorModel = getOrInitEditorModel(mockId, false);
            if (!editorModel) return;
            const { selection } = editorModel;

            if (!selection?.isCollapsed) return;
            editorModel.performChange((ctx) => {
              ctx.delete().insert('\n');
            });
          }
        }}
        readonly={readonly}
        segments={block?.data.description}
        segmentType="description"
        onChange={(segments, prevContent, prevSelection) => {
          updateSegments(segments, [prevContent, prevSelection]);
        }}
        history={false}
      />
    );
  }
  return null;
};
