import { EmbedType } from '@flowus/common/embed-website';
import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import { forIn, keys, values } from 'lodash-es';
import memoize from 'micro-memoize';
import type { FC } from 'react';
import { ListItemType } from 'src/common/components/list-view';
import { DEFAULT_COLOR_PICKER_DATA } from 'src/components/color-picker/default-data';
import { DEFAULT_CODE_LANGUAGE } from 'src/editor/editor/plugin/code/const';
import { AIEditType } from 'src/editor/editor/uikit/ai-editor/const';
import { getIsDarkMode } from 'src/hooks/public/use-theme';
import { ossImagesPath } from 'src/image';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { ViewModel } from 'src/redux/types';
import { getInitials, getPinyin } from 'src/utils/pinyin';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import { AITranslate, MoreAI } from './ai-sub-menu';
import { MenuCategoryPinyin } from './const';
import { getEmbedThirdParty } from './embeds-data';
import { InlineContentType, MenuCategory, MenuListActionType } from './types';
import { PRODUCT_AI_TITLE } from 'src/const/title';
import { getLastAlignGravity } from '../align-panel';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { textToSegments } from 'src/editor/utils/editor';
import { FeatureFlags } from 'src/feature-flags';

export interface BlockMenuTypes {
  type: ListItemType;
  isHidden?: boolean;
  data: {
    type?: BlockType;
    actionType: MenuListActionType;
    icon?: string;
    title?: string;
    context?: string;
    keywords: string[];
    py?: string;
    quick?: string;
    initials?: string;
    params?: {
      data?: {
        level?: number;
        display?: string;
        format?: any;
      };
      backgroundColor?: string;
      textColor?: string;
      local?: boolean;
    };
  };
}

export const createMenuDataContext: FC<{ title: string; image?: string }> = (props) => {
  const { title, image } = props;
  return (
    <div className="p-2 bg-grey1 dark:bg-grey6 text-t4-medium text-white rounded w-40">
      {image && (
        <img
          src={`${ossImagesPath}/block-description/${image}.png`}
          alt={title}
          className="mb-2 w-full bg-white rounded-sm"
        />
      )}
      <span>{title}</span>
    </div>
  );
};

/** 获取BlockMenu */
const buildBlockMenuList = (isDark: boolean) => {
  // #region 普通block列表，拆出来给turninto复用
  const basicBlock = {
    textarea: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TEXTAREA,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockText',
        title: '正文',
        context: createMenuDataContext({ title: '主要文本撰写', image: 'textarea' }),
        keywords: ['text'],
      },
    },
    todo: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TODO,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockTodo',
        title: '待办列表',
        context: createMenuDataContext({ title: '管理待办事项', image: 'todo' }),
        keywords: ['todo'],
      },
    },
    subPage: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.PAGE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockPage',
        title: '页面',
        context: createMenuDataContext({ title: '当前页面中创建子级页面', image: 'page' }),
        keywords: ['page'],
      },
    },
    linkPage: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.REFERENCE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertLinktopage',
        title: '引用页面',
        keywords: ['linkPage'],
        context: createMenuDataContext({ title: '引用已有页面/多维表/文件夹', image: 'link_page' }),
        params: {
          data: { display: 'linkPage' },
        },
      },
    },
    h1: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockH1',
        quick: 'h1',
        title: '标题1',
        context: createMenuDataContext({ title: '创建一级标题', image: 'h1' }),
        keywords: ['h1', 'heading1'],
        params: {
          data: { level: 1 },
        },
      },
    },
    h2: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockH2',
        title: '标题2',
        quick: 'h2',
        context: createMenuDataContext({ title: '创建二级标题', image: 'h2' }),
        keywords: ['h2', 'heading2'],
        params: {
          data: { level: 2 },
        },
      },
    },
    h3: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockH3',
        title: '标题3',
        quick: 'h3',
        context: createMenuDataContext({ title: '创建三级标题', image: 'h3' }),
        keywords: ['h3', 'heading3'],
        params: {
          data: { level: 3 },
        },
      },
    },
    h4: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockH4',
        title: '标题4',
        quick: 'h4',
        context: createMenuDataContext({ title: '创建四级标题', image: 'h4' }),
        keywords: ['h4', 'heading4'],
        params: {
          data: { level: 4 },
        },
      },
    },
    table: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TABLE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertTable',
        context: createMenuDataContext({ title: '支持纯文本/行内元素', image: 'table' }),
        title: '简单表格',
        keywords: ['simple table', 'table'],
      },
    },
    list: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.LIST,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockBullrted',
        title: '项目列表',
        context: createMenuDataContext({ title: '显示项目符号的列表', image: 'list' }),
        keywords: ['list', 'bulleted list'],
      },
    },
    orderList: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.ORDER_LIST,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockNum',
        title: '编号列表',
        context: createMenuDataContext({ title: '显示编号的列表', image: 'order_list' }),
        keywords: ['numbered list'],
      },
    },
    foldList: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.FOLD_LIST,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockToggle',
        title: '折叠列表',
        context: createMenuDataContext({ title: '可折叠隐藏/展开显示', image: 'fold_list' }),
        keywords: ['toggle list'],
      },
    },
    divider: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.DIVIDER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertDivider',
        title: '分割线',
        context: createMenuDataContext({ title: '进行区域划分', image: 'divider' }),
        keywords: ['divider'],
      },
    },
    quote: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.QUOTE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertQuote',
        title: '引述文字',
        context: createMenuDataContext({ title: '可批量移入块', image: 'quote' }),
        keywords: ['quote'],
      },
    },
    mark: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.MARK,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertCallout',
        title: '着重文字',
        py: 'zhuozhongwenzi',
        context: createMenuDataContext({ title: '可更换emoji/批量移入块', image: 'mark' }),
        keywords: ['call out mark'],
      },
    },
    code: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.CODE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockCode',
        title: '代码片段',
        context: createMenuDataContext({ title: '创建代码片段块', image: 'code' }),
        keywords: ['code block'],
        params: {
          local: true,
          data: {
            format: {
              language: getLastCodeLanguage() || DEFAULT_CODE_LANGUAGE,
            },
          },
        },
      },
    },
    equation: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.EQUATION,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertEquation',
        title: '数学公式',
        context: createMenuDataContext({ title: 'LaTex数学公式块', image: 'equation' }),
        keywords: ['math equation latex'],
        params: {
          local: true,
        },
      },
    },
    mindMap: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.MIND_MAPPING,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertMindMap',
        title: '思维导图',
        context: createMenuDataContext({ title: '创建思维导图', image: 'mind-map' }),
        keywords: ['embed mindmap brainmap naotu nt'],
        params: {
          local: true,
        },
      },
    },
    mindMapPage: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.MIND_MAPPING_PAGE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertMindMap',
        title: '思维导图页面',
        context: createMenuDataContext({ title: '创建思维导图页面', image: 'mind-map' }),
        keywords: ['mindmap brainmap naotu nt'],
        params: {
          local: true,
        },
      },
    },
    toggleHeader1: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TOGGLE_HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockToggleH1',
        title: '折叠标题1',
        context: createMenuDataContext({ title: '创建折叠标题1', image: 'toggle-h1' }),
        keywords: ['toggle heading h1'],
        params: {
          data: { level: 1 },
        },
      },
    },

    toggleHeader2: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TOGGLE_HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockToggleH2',
        title: '折叠标题2',
        context: createMenuDataContext({ title: '创建折叠标题2', image: 'toggle-h2' }),
        keywords: ['toggle heading h2'],
        params: {
          data: { level: 2 },
        },
      },
    },
    toggleHeader3: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TOGGLE_HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockToggleH3',
        title: '折叠标题3',
        context: createMenuDataContext({ title: '创建折叠标题3', image: 'toggle-h3' }),
        keywords: ['toggle heading h3'],
        params: {
          data: { level: 3 },
        },
      },
    },
    toggleHeader4: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TOGGLE_HEADER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockToggleH4',
        title: '折叠标题4',
        context: createMenuDataContext({ title: '创建折叠标题4', image: 'toggle-h4' }),
        keywords: ['toggle heading h4'],
        params: {
          data: { level: 4 },
        },
      },
    },
  };
  // #endregion

  const aiAssistantWithEmptyDoc = {
    writing: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcAi',
        title: PRODUCT_AI_TITLE,
        keywords: ['Ask AI to write...'],
      },
    },
    brainstorm: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        aiEditType: AIEditType.Brainstorm,
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcContinueWriting',
        title: '头脑风暴',
        keywords: ['brainstorm ideas'],
      },
    },
    blog: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        aiEditType: AIEditType.Blog,
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcContinueWriting',
        title: '博客文章',
        keywords: ['blog posts'],
      },
    },
    more: {
      type: ListItemType.OPERATION,
      data: {
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcMore',
        title: '更多',
        keywords: ['more'],
        hasArrow: true,
        renderSubMenu: () => {
          return <MoreAI />;
        },
      },
    },
  };

  const aiAssistantWithNotEmptyDoc = {
    writing: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcAi',
        title: PRODUCT_AI_TITLE,
        keywords: ['Ask AI to write...'],
      },
    },
    continueWriting: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        aiEditType: AIEditType.ContinueWrite,
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcContinueWriting',
        title: '继续写作',
        keywords: ['Continue writing'],
      },
    },
    translate: {
      type: ListItemType.OPERATION,
      data: {
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcTranslate',
        title: '翻译',
        keywords: ['Translate'],
        hasArrow: true,
        renderSubMenu: () => {
          return <AITranslate />;
        },
      },
      attribute: {
        'data-prevent-event': true,
      },
    },
    more: {
      type: ListItemType.OPERATION,
      data: {
        actionType: MenuListActionType.AI_EDITOR,
        icon: 'IcMore',
        title: '更多',
        keywords: ['more'],
        hasArrow: true,
        renderSubMenu: () => {
          return <MoreAI hasContent={true} />;
        },
      },
      attribute: {
        'data-prevent-event': true,
      },
    },
  };

  // #region 高级块
  const advanced = {
    template: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.TEMPLATE,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertBlockTemplateButton',
        title: '模板按钮',
        py: 'mubananniu',
        context: createMenuDataContext({ title: '快速创建常用块副本', image: 'template' }),
        keywords: ['template button'],
      },
    },
    sync: {
      type: ListItemType.BLOCK_ITEM,
      data: {
        type: BlockType.SYNC_CONTAINER,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertSyncedBlock',
        title: '同步块',
        context: createMenuDataContext({ title: '跨页面同步块内容', image: 'sync' }),
        keywords: ['sync'],
      },
    },
    ad: {
      type: ListItemType.BLOCK_ITEM,
      isHidden: false,
      data: {
        type: BlockType.AD,
        actionType: MenuListActionType.CREATE,
        icon: 'MIcInsertSyncedBlock',
        title: '广告块',
        keywords: ['ad'],
      },
    },
    chart: {
      type: ListItemType.BLOCK_ITEM,
      isHidden: !FeatureFlags.CHART,
      data: {
        type: BlockType.CHART,
        actionType: MenuListActionType.CREATE,
        icon: 'IcChartLine',
        title: '数据图表',
        keywords: ['chart'],
        params: {
          data: { segments: textToSegments('图表') },
          local: true,
        },
      },
    },
  };
  // #endregion

  // #region 转换为列表
  const turninto: Record<string, typeof basicBlock> = {};
  Object.keys(basicBlock).forEach((key) => {
    if (!['divider', 'linkPage', 'table', 'mindMapPage'].includes(key)) {
      // @ts-ignore line
      const item = basicBlock[key];
      turninto[key] = {
        ...item,
        data: {
          ...item.data,
          actionType: MenuListActionType.TURN_INTO,
        },
      };
    }
  });
  // #endregion

  // #region 文字颜色 背景颜色 上次使用
  const [textColorMenuList, bgColorMenuList] = DEFAULT_COLOR_PICKER_DATA.map((color) =>
    color.items.map((item) => {
      const isText = color.title === '文字颜色';

      return {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.COLOR,
          // 展示icon
          ...(isText
            ? { textColor: item.colorkey || 'default' }
            : { backgroundColor: item.colorkey || 'default' }),
          // 标题
          title: item.desc,
          // 关键词
          keywords: [
            item.colorkey ? `${isText ? '' : 'bg '}${item.colorkey.split('_')[0]}` : 'default',
            // 英文关键词
            item.colorkey
              ? `${isText ? '' : 'background '}${item.colorkey.split('_')[0]}`
              : 'default',
          ],
          // 给到block的字段属性
          params: {
            ...(isText
              ? { backgroundColor: '', textColor: item.colorkey || '' }
              : { backgroundColor: item.colorkey || '', textColor: '' }),
          },
        },
      };
    })
  );
  // #endregion

  // 最后一次使用的颜色
  const lastUsedColor = {
    type: ListItemType.BLOCK_ITEM,
    data: {
      actionType: MenuListActionType.COLOR,
      title: '上次使用颜色',
    },
  };
  const BlockMenu = {
    aiAssistantWithEmptyDoc,
    aiAssistantWithNotEmptyDoc,
    basicBlock,
    media: {
      image: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FILE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockImage',
          title: '图片',
          context: createMenuDataContext({ title: '上传或插入图片链接', image: 'image' }),
          keywords: ['image', 'picture'],
          params: {
            data: {
              display: 'image',
              format: {
                contentGravity: getLastAlignGravity(),
              },
            },
            local: true,
          },
        },
      },
      video: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FILE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockVideo',
          title: '视频',
          context: createMenuDataContext({ title: '上传或插入视频链接', image: 'video' }),
          keywords: ['video'],
          params: {
            data: {
              display: 'video',
            },
            local: true,
          },
        },
      },
      audio: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FILE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockAudio',
          title: '音频',
          context: createMenuDataContext({ title: '上传或插入音频链接', image: 'audio' }),
          keywords: ['audio'],
          params: {
            data: {
              display: 'audio',
            },
            local: true,
          },
        },
      },
      file: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FILE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockFile',
          title: '文件',
          context: createMenuDataContext({ title: '上传或插入文件链接', image: 'file' }),
          keywords: ['file'],
          params: {
            local: true,
            data: {
              display: 'file',
            },
          },
        },
      },
      folder: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FOLDER,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockFolder',
          title: '文件夹',
          context: createMenuDataContext({
            title: '当前页面中创建子级文件夹页面',
            image: 'folder',
          }),
          keywords: ['folder'],
          params: { local: true },
        },
      },
      bookmark: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.BOOKMARK,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockBookmark',
          title: '网页书签',
          context: createMenuDataContext({ title: '书签形式插入在线网页', image: 'bookmark' }),
          keywords: ['web bookmark'],
          params: {
            local: true,
          },
        },
      },
    },
    inlineElement: {
      date: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.DATE,
          icon: 'MIcInsertBlockDate',
          title: '行内日期',
          isInline: true,
          context: createMenuDataContext({ title: '插入行内日期', image: 'inline_date' }),
          keywords: ['inline date inline time'],
        },
      },
      link_page: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.LINK_PAGE,
          icon: 'MIcInsertPageInline',
          title: '行内引用页面',
          isInline: true,
          context: createMenuDataContext({
            title: '行内形式引用已有页面',
            image: 'inline_link_page',
          }),
          keywords: ['inline linkPage'],
        },
      },
      page: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.PAGE,
          icon: 'MIcInsertSubpage',
          title: '行内页面',
          isInline: true,
          context: createMenuDataContext({
            title: '行内形式页面',
            image: 'inline_page',
          }),
          keywords: ['inline page', 'hangneiyemian', 'hnym', 'hnys hnym', 'hnys inline Page'],
        },
      },
      equation: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.EQUATION,
          icon: 'MIcInlineEquation',
          title: '行内公式',
          isInline: true,
          context: createMenuDataContext({ title: '行内形式插入公式', image: 'inline_equation' }),
          keywords: ['inline equation'],
        },
      },
      person: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.PEOPLE,
          icon: 'MIcMentionPerson',
          title: '人员',
          isInline: true,
          context: createMenuDataContext({ title: '提及空间内成员', image: 'inline_person' }),
          keywords: ['inline person'],
        },
      },
      emoji: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.CREATE,
          type: InlineContentType.EMOJI,
          image: new URL(
            `${ossImagesPath}/embeds/${isDark ? 'dark' : 'light'}/ic_insert_emoji.png`,
            import.meta.url
          ).href,
          title: 'Emoji',
          isInline: true,
          context: createMenuDataContext({ title: '插入行内 Emoji', image: 'inline_emoji' }),
          keywords: ['inline emoji'],
        },
      },
    },
    database: {
      embedBitable: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertDatabase',
          title: '表格',
          context: createMenuDataContext({ title: '嵌入表格视图多维表', image: 'embed_bitable' }),
          keywords: ['database', 'table'],
          tableType: CollectionViewType.TABLE,
        },
      },
      bitablePage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockDatabase',
          title: '表格页面',
          context: createMenuDataContext({
            title: '插入表格视图多维表子页面',
            image: 'bitable_page',
          }),
          keywords: ['database page', 'table page'],
          tableType: CollectionViewType.TABLE,
        },
      },
      embedBitableBoard: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBoard',
          title: '看板',
          context: createMenuDataContext({
            title: '嵌入看板视图多维表',
            image: 'embed_bitable_board',
          }),
          keywords: ['database board', 'database board'],
          tableType: CollectionViewType.BOARD,
        },
      },
      bitableBoardPage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockBoard',
          title: '看板页面',
          context: createMenuDataContext({
            title: '插入看板视图多维表子页面',
            image: 'bitable_board_page',
          }),
          keywords: ['database board page', 'database board page'],
          tableType: CollectionViewType.BOARD,
        },
      },
      embedBitableGallery: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertGallery',
          title: '画廊',
          context: createMenuDataContext({
            title: '嵌入画廊视图多维表',
            image: 'embed_bitable_gallery',
          }),
          keywords: ['database gallery', 'database gallery'],
          tableType: CollectionViewType.GALLERY,
        },
      },
      bitableGalleryPage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockGallery',
          title: '画廊页面',
          context: createMenuDataContext({
            title: '插入画廊视图多维表子页面',
            image: 'bitable_gallery_page',
          }),
          keywords: ['database gallery page', 'database gallery page'],
          tableType: CollectionViewType.GALLERY,
        },
      },
      embedBitableList: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertList',
          title: '目录',
          context: createMenuDataContext({
            title: '嵌入目录视图多维表',
            image: 'embed_bitable_list',
          }),
          keywords: ['database list', 'database list'],
          tableType: CollectionViewType.LIST,
        },
      },
      bitableListPage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockList',
          title: '目录页面',
          context: createMenuDataContext({
            title: '插入目录视图多维表子页面',
            image: 'bitable_list_page',
          }),
          keywords: ['database list page', 'database list page'],
          tableType: CollectionViewType.LIST,
        },
      },
      embedBitableTimeline: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertTimeline',
          title: '时间轴',
          context: createMenuDataContext({
            title: '嵌入时间轴视图多维表',
            image: 'embed_bitable_timeline',
          }),
          keywords: ['database timeline', 'database timeline'],
          tableType: CollectionViewType.TIMELINE,
        },
      },
      bitableTimelinePage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockTimeline',
          title: '时间轴页面',
          context: createMenuDataContext({
            title: '插入时间轴视图多维表子页面',
            image: 'bitable_timeline_page',
          }),
          keywords: ['database timeline page', 'database timeline page'],
          tableType: CollectionViewType.TIMELINE,
        },
      },
      embedBitableForm: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockForm',
          title: '收集表',
          context: createMenuDataContext({
            title: '嵌入收集表视图多维表',
            image: 'embed_bitable_form',
          }),
          keywords: ['database form', 'database form'],
          tableType: CollectionViewType.FORM,
        },
      },
      bitableFormPage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertForm',
          title: '收集表页面',
          context: createMenuDataContext({
            title: '插入收集表视图多维表子页面',
            image: 'bitable_form_page',
          }),
          keywords: ['database form page', 'database form page'],
          tableType: CollectionViewType.FORM,
        },
      },
      embedBitableCalendar: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertCalendar',
          title: '日历',
          context: createMenuDataContext({
            title: '嵌入日历视图多维表',
            image: 'embed_bitable_calendar',
          }),
          keywords: ['database calendar', 'database calendar'],
          tableType: CollectionViewType.CALENDAR,
        },
      },
      bitableCalendarPage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLLECTION_VIEW_PAGE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockCalendar',
          title: '日历页面',
          context: createMenuDataContext({
            title: '插入日历视图多维表子页面',
            image: 'bitable_calendar_page',
          }),
          keywords: ['database calendar page', 'database calendar page'],
          tableType: CollectionViewType.CALENDAR,
        },
      },
      linkBitable: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.REFERENCE_COLLECTION,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertPageInline',
          title: '引用多维表',
          context: createMenuDataContext({ title: '嵌入已有多维表', image: 'link_bitable' }),
          keywords: ['linked database', 'link table'],
        },
      },
    },
    embed: {
      webpage: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.EMBED,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockWeb',
          title: '嵌入网页',
          context: createMenuDataContext({ title: '直接嵌入在线网页', image: 'embed_webpage' }),
          keywords: ['embed web'],
          params: {
            data: {
              embedType: EmbedType.webpage,
            },
            local: true,
          },
        },
      },
      file: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FILE,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertPdf',
          title: '嵌入文件',
          context: createMenuDataContext({ title: '直接嵌入可预览文件', image: 'embed_file' }),
          keywords: ['embed file'],
          params: {
            data: {
              display: 'file',
              viewMode: ViewModel.preview,
            },
            local: true,
          },
        },
      },
      folder: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.FOLDER,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertFolder',
          title: '嵌入文件夹',
          context: createMenuDataContext({ title: '直接嵌入可预览文件夹', image: 'embed_folder' }),
          keywords: ['embed folder'],
          params: {
            data: { viewMode: ViewModel.preview },
            local: true,
          },
        },
      },
      ...getEmbedThirdParty(),
    },
    advanced,
    layout: {
      leftColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockLeft',
          title: '在左侧插入分栏',
          params: {
            left: true,
          },
        },
      },
      rightColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertBlockRight',
          title: '在右侧插入分栏',
          params: {
            left: false,
          },
        },
      },
      twoColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertDownTwo',
          quick: '2',
          title: '两列分栏',
          params: {
            columnCount: 2,
          },
        },
      },
      threeColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertDownThree',
          quick: '3',
          title: '三列分栏',
          params: {
            columnCount: 3,
          },
        },
      },
      fourColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertDownFour',
          quick: '4',
          title: '四列分栏',
          params: {
            columnCount: 4,
          },
        },
      },
      fiveColumn: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertDownFive',
          quick: '5',
          title: '五列分栏',
          params: {
            columnCount: 5,
          },
        },
      },
    },
    turninto,
    action: {
      delete: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.DELETE,
          icon: 'IcTrash',
          keywords: ['delete'],
          title: '删除',
          iconSize: 'small',
        },
      },
      copy: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.COPY,
          icon: 'IcDuplicate',
          keywords: ['copy'],
          title: '拷贝副本',
          iconSize: 'small',
        },
      },
      moveTo: {
        type: ListItemType.BLOCK_ITEM,
        data: {
          actionType: MenuListActionType.MOVETO,
          icon: 'IcMove',
          keywords: ['move'],
          title: '移动到',
          iconSize: 'small',
        },
      },
    },
    lastUsedColor: { lastUsedColor },
    color: textColorMenuList,
    colorBackground: bgColorMenuList,
  };

  // #region BlockMenu内容转拼音
  // @ts-ignore next line
  forIn(BlockMenu, (menuType, blockType: keyof typeof BlockMenu) => {
    forIn(menuType, (value) => {
      // 首字母
      // @ts-ignore: next line
      value.data.initials = value.data.initials ?? getInitials(value.data.title);
      // 拼音
      // @ts-ignore: next line
      value.data.py = value.data.py ?? getPinyin(value.data.title);

      // @ts-ignore: for tracker
      value.data.category = MenuCategory[blockType];
    });
  });
  // #endregion

  return BlockMenu;
};

const buildMemoBlockMenuList = memoize(buildBlockMenuList);

/** 优化block list的搜索列表数据 */
const buildMenuListMemo = memoize((isDark: boolean) => {
  const menuList = buildBlockMenuList(isDark);
  // @ts-ignore type
  keys(menuList).forEach((blockType: keyof typeof menuList) => {
    const menus = menuList[blockType];
    const category = MenuCategory[blockType];
    const categoryInfo = MenuCategoryPinyin[blockType];
    keys(menus).forEach((menuKey) => {
      // @ts-ignore type
      const menu = menuList[blockType][menuKey];
      if (menu?.data) {
        // @ts-ignore type
        menuList[blockType][menuKey].data.keywords = [
          ...new Set([
            menu.data.quick,
            menu.data.initials,
            ...(menu.data.keywords || []),
            menu.data.py,
            menu.data.title,
            // 分类首字母+标题首字母
            `${categoryInfo.initials} ${menu.data.initials}`,
            // 分类+关键词
            ...(menu.data.keywords || []).map((k: string) => `${categoryInfo.initials} ${k}`),
          ]),
        ].filter(Boolean) as string[];

        // 关键词词组
        // @ts-ignore type
        menuList[blockType][menuKey].data.thesaurus = [
          `${blockType} ${menu.data.title}`,
          `${blockType} ${menu.data.quick}`,
          // 块分组类型
          blockType,
          // 关键词
          ...(menu.data.keywords || []),
          categoryInfo.pinyin,
          // 分类+块标题
          `${category} ${menu.data.title}`,
          `${category} ${menu.data.quick}`,
        ].map((s) => stringToLowerCaseAndRemoveSpace(s));
      }
    });
  });
  return menuList;
});

export const getMemoBlockMenuList = () => {
  const isDark = getIsDarkMode();
  const blockMenu = buildMenuListMemo(isDark);
  return blockMenu;
};

export const getBlockMenuList = () => {
  const isDark = getIsDarkMode();
  const blockMenu = buildMemoBlockMenuList(isDark);
  return blockMenu;
};

export const cacheBlockMenu: Record<string, BlockMenuTypes['data'] | undefined> = {};

export const getBlockMenu = (
  blockType?: BlockType,
  params?: {
    level?: number;
    display?: string;
  }
) => {
  const key = `${blockType}-${params?.level}-${params?.display}`;

  if (cacheBlockMenu[key]) {
    return cacheBlockMenu[key];
  }

  if (blockType === undefined) return;
  let result: BlockMenuTypes['data'] | undefined;
  const blockMenuList = getBlockMenuList();
  values(blockMenuList).some((menus) => {
    menus = values(menus);
    const menuIndex = menus.findIndex((item: BlockMenuTypes) => {
      if (item.data.type === blockType) {
        if (
          blockType !== BlockType.HEADER &&
          blockType !== BlockType.FILE &&
          blockType !== BlockType.TOGGLE_HEADER
        ) {
          return true;
        }
        if (params?.level && params.level === item.data.params?.data?.level) {
          return true;
        }
        if (params?.display && params.display === item.data.params?.data?.display) {
          return true;
        }
      }
      return false;
    });
    const menu = menus[menuIndex];
    if (menu) {
      result = menu.data;
      return true;
    }
    return false;
  });

  cacheBlockMenu[key] = result;

  return result;
};

// #region 埋点用，把block换成对应的文案
export const covertBlockToTitle = (uuid: string) => {
  const block = getState().blocks[uuid];
  const BlockMenu = getBlockMenuList();
  if (!block) return '';
  switch (block.type) {
    case BlockType.REFERENCE: {
      if (block.data.display === 'linkPage') {
        return BlockMenu.basicBlock.linkPage.data.title;
      }
      return '引用块';
    }
    case BlockType.FILE: {
      switch (block.data.display) {
        case 'audio':
          return BlockMenu.media.audio.data.title;
        case 'video':
          return BlockMenu.media.video.data.title;
        case 'image':
          return BlockMenu.media.image.data.title;
        case 'file':
          return block.data.viewMode === ViewModel.preview
            ? BlockMenu.embed.file.data.title
            : BlockMenu.media.file.data.title;
        default:
          return '';
      }
    }
    case BlockType.HEADER: {
      return `标题${block.data.level ?? 1}`;
    }
    case BlockType.TOGGLE_HEADER: {
      return `折叠标题${block.data.level ?? 1}`;
    }
    case BlockType.CHART: {
      return '图表';
    }
    default:
  }
  const menus = [
    BlockMenu.basicBlock,
    BlockMenu.media,
    BlockMenu.database,
    BlockMenu.embed,
    BlockMenu.advanced,
  ];
  let ret;
  for (const menu of menus) {
    ret = covertBlockToTitleFromMenu(block, menu);
    if (ret) return ret;
  }
  if (block.type === BlockType.SYNC_REFERENCE) {
    return '影子同步块';
  } else if (block.type === BlockType.COLUMN_LIST) {
    return '分栏';
  } else if (block.type === BlockType.COLUMN) {
    return '分栏';
  }
  return '';
};

const covertBlockToTitleFromMenu = (
  block: NextBlock,
  obj: {
    [key: string]: { data: { type: BlockType; title: string } };
  }
) => {
  for (const [_, item] of Object.entries(obj)) {
    if (block.type === item.data.type) {
      return item.data.title;
    }
  }
  return '';
};
// #endregion

// #region 生成分组标题
export const CreateBlockTextMenu = (title: string, data?: any) => ({
  type: ListItemType.BLOCK_TEXT,
  data: {
    title,
    py: getPinyin(title),
    initials: getInitials(title),
    ...data,
  },
});
// #endregion
