/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BlockType } from '@next-space/fe-api-idl';
import { isAnyOf } from '@reduxjs/toolkit';
import * as _ from 'lodash-es';
import type { AnyAction, Middleware } from 'redux';
import { lookupPageId } from 'src/utils/lookup-page-id';
import {
  DESTROY_BLOCK,
  LIST_AFTER_BLOCK,
  LIST_BEFORE_BLOCK,
  LIST_REMOVE_BLOCK,
  REMOVE_BLOCK_PERMISSION,
  SET_BLOCK_PERMISSION,
  UPDATE_BLOCK,
} from '../actions/block';
import { blocksActions } from '../reducers/blocks';
import type { NextBlock } from '../types';
import type { CacheInstanceKey } from './cache-lift';
import {
  shareInvalidateAll,
  tocInvalidate,
  tocInvalidateAll,
  tocInvalidateParent,
} from './cache-lift';

export const cacheMiddleware: Middleware<
  {},
  {
    blocks: Record<string, NextBlock>;
    cache?: CacheInstanceKey;
  }
> = ({ getState }) => {
  return (next) => (action: AnyAction) => {
    if (isAnyOf(LIST_AFTER_BLOCK, LIST_BEFORE_BLOCK)(action)) {
      shareInvalidateAll();
      if (action.payload.parentId != null) {
        const pageId = lookupPageId(action.payload.parentId, getState() as any);
        if (pageId != null) {
          tocInvalidate(pageId);
          tocInvalidateParent(action.payload.parentId, getState);
        }
      }
    } else if (isAnyOf(UPDATE_BLOCK)(action)) {
      if (action.payload.patch.type === BlockType.PAGE) {
        shareInvalidateAll();
        tocInvalidateParent(action.payload.uuid, getState);
      }
      if (_.has(action.payload.patch, 'subNodes')) {
        shareInvalidateAll();
        tocInvalidate(action.payload.uuid);
      }
      if (_.has(action.payload.patch, 'permissions') || _.has(action.payload.patch, 'parentId')) {
        shareInvalidateAll();
        tocInvalidateParent(action.payload.uuid, getState);
      }
    } else if (isAnyOf(SET_BLOCK_PERMISSION, REMOVE_BLOCK_PERMISSION)(action)) {
      shareInvalidateAll();
      const parentId = getState().blocks[action.payload.uuid]?.parentId;
      if (parentId != null) {
        tocInvalidate(parentId);
      }
    } else if (isAnyOf(DESTROY_BLOCK, LIST_REMOVE_BLOCK)(action)) {
      shareInvalidateAll();
      tocInvalidateParent(action.payload.uuid, getState);
    } else if (isAnyOf(blocksActions.update, blocksActions.clear)(action)) {
      shareInvalidateAll();
      tocInvalidateAll();
    }

    next(action);
  };
};
