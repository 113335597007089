import { fetchCollection, getPropertyValue } from 'src/common/utils/fetch-collection';
import type { Wares } from './type';

const COLUMN_TITLE_NAME = '商品名称';
const COLUMN_URL_NAME = '商品图片';
const COLUMN_SCORE_NAME = '积分数量';
const COLUMN_RELEASE = '是否上架';
const COLUMN_WARES_ID = '关联商品类型ID';

const waresShopId =
  __HOST_LOCAL__ || __HOST_TEST__
    ? 'cac479bc-9a3e-4525-80e0-c13da831ec14'
    : '3795336f-c9d4-4396-b3b4-e7fa5be57a5f';

export const fetchWares = async () => {
  return fetchCollection(waresShopId, {
    filter: (b, _, propertyMapping) => {
      return getPropertyValue(b, propertyMapping, COLUMN_RELEASE).toUpperCase() === 'YES';
    },
    convertToTargets: (blocks, _, propertyMapping) => {
      return blocks.map((b) => {
        const item: Wares = {
          recordId: b.uuid,
          waresId: getPropertyValue(b, propertyMapping, COLUMN_WARES_ID),
          name: getPropertyValue(b, propertyMapping, COLUMN_TITLE_NAME),
          ossName: getPropertyValue(b, propertyMapping, COLUMN_URL_NAME),
          score: getPropertyValue(b, propertyMapping, COLUMN_SCORE_NAME),
        };
        return item;
      });
    },
  });
};
