/**
 * 应用入口
 */
import { commonInit } from '@flowus/common/build-in';
import { buildinAIConfig, initGoogleFireBase } from '@flowus/common/google-firebase';
import { refreshSessionParams } from '@flowus/common/storage/activity';
import { initSceneCss } from '@flowus/common/style';
import { tryGetPromotionChannel } from '@flowus/login/hook/promotion-channel';
import localforage from 'localforage';
import 'url-search-params-polyfill'; // 好多用户的qq浏览器不支持urlSearchParams,polyfill一下
import {
  catchWarnings,
  checkAuthCallback,
  copy2Share,
  initInviteCode,
  // guest2Share,
  mountApp,
  toProduct,
  toPublicHome,
} from './bootstrap';
import { VITE_CDN_HOST } from './env';
import './style/index.css';
import { getToken } from './utils/get-next-auth';
import { initLogin } from './utils/init-login';
import { enhanceScrollbar } from '@flowus/common/utils/toggle-body-scrollbar';
import { normalizeUrl } from '@flowus/common/url';

initSceneCss();

commonInit({ isBuildIn: __BUILD_IN__, cdnHost: VITE_CDN_HOST });

initLogin();

const localKey = __BUILD_IN__ ? 'buildin' : 'flowus';

localforage.config({
  version: 1,
  name: localKey,
  storeName: localKey,
  size: 4980736,
});

const setup = async () => {
  if (normalizeUrl()) return;
  /** 初始化 Google 埋点 - BuildIn */
  if (__HOST_PRODUCTION__ && __BUILD_IN__) {
    initGoogleFireBase(buildinAIConfig);
  }
  /**
   * 样式增强
   */
  enhanceScrollbar();

  /**
   * 开发环境
   */
  catchWarnings();

  /** 获取推广渠道 */
  tryGetPromotionChannel();

  /** 邀请码初始化 */
  initInviteCode();

  /**
   * 路由跳转
   */

  if (toPublicHome()) {
    mountApp();
    return;
  }

  const isAuthCallback = checkAuthCallback();
  if (isAuthCallback) return;

  const isLogin = getToken();
  let jump;

  if (!isLogin) {
    jump = copy2Share();
    if (jump) return;
    // jump = await guest2Share();
    // if (jump) return; //firefox下如果不return，会继续走下面的流程，最终因为没登录会跳转到登录页
    jump = await toProduct();
    if (jump) return;
  }

  refreshSessionParams();

  /**
   * 挂载视图
   */
  mountApp();
};

void setup();
