import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { PRODUCT_ALL_NAME } from 'src/const/title';
import { Logo } from 'src/views/share/logo';

/** 收集表的share页面容器，包括顶部header和底部footer */
export const FormContainer: FC<{
  header?: ReactNode;
  report?: () => void;
  className?: string;
  hiddenFooter?: boolean;
  headerClassName?: string;
  containerClassName?: string;
}> = ({
  children,
  header,
  report,
  className,
  hiddenFooter,
  headerClassName,
  containerClassName,
}) => {
  return (
    <div className={cx('relative flex flex-col items-center', containerClassName)}>
      <div className={cx('w-full flex-shrink-0 h-[320px]', headerClassName)}>{header}</div>
      <div
        className={cx(
          'px-20 -mt-[150px] next-modal shadow-light w-full relative z-10 max-w-[960px] mb-10',
          className
        )}
      >
        {children}
      </div>
      <div
        hidden={__PRIVATE__}
        className={cx(
          'flex items-center w-full max-w-[1420px] border-t border-grey6 h-24 flex-shrink-0 mt-auto',
          hiddenFooter && 'border-none hidden'
        )}
      >
        {!hiddenFooter && (
          <div className="flex items-center justify-between w-full max-w-[960px] mx-auto">
            <span className="text-t2 flex items-center">
              <Logo
                description={
                  <>
                    <span className="ml-2">{PRODUCT_ALL_NAME}</span>
                    <span className="text-grey3">&nbsp;提供技术支持&nbsp;</span>
                  </>
                }
              />
            </span>
            {report ? (
              <span className="text-t2 cursor-pointer" onClick={report}>
                举报违法信息
              </span>
            ) : (
              <span />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
