import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo } from 'react';
import { Icon } from 'src/common/components/icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { Caption } from '../caption';
import { useViewModelController } from './hook';
import { ViewModelToolBar } from './toolbar';
import type { ViewModeFcProps } from './type';

export const ViewModelCardView: FC<ViewModeFcProps> = memo((props) => {
  const {
    root,
    uuid,
    title,
    context,
    className,
    isFile,
    viewModelToolBarData,
    iconName,
    ownerBlockId,
  } = props;
  const { isFail, toolBarProps, domain, openFilePreview, blockRef, isMobileSize, hasOverlay } =
    useViewModelController(props);
  const openPage = useOpenPage();

  const FileHeader = (
    <>
      <IconTrigger
        hasOverlay={hasOverlay}
        blockId={uuid}
        trigger={!isFile}
        iconSize={40}
        defaultIcon={<Icon size="xxxlarge" name={iconName} />}
      />
      <div className="flex flex-col justify-start max-full">
        {title && <div className="w-full text-t1">{title}</div>}
        {context && <div className="text-t4 text-grey3">{context}</div>}
      </div>
    </>
  );

  return (
    <BlockDrop
      blockRef={blockRef}
      id={ownerBlockId ?? uuid}
      horizontal={root}
      className={cx(isFail && 'opacity-50', className)}
      data-draggable
    >
      <div className="h-[90px] border border-black/10 rounded !cursor-default animate-hover-black3 group flex items-center justify-between pl-3 pr-3.5 overflow-x-auto">
        {isFile ? (
          <div
            className="preview-file flex items-center h-full text-ellipsis py-3 flex-1 cursor-pointer overflow-hidden w-full"
            onClick={() => openFilePreview(uuid)}
          >
            {FileHeader}
          </div>
        ) : (
          <div
            onClick={(event) => {
              openPage(uuid, {
                forceOpenInRight: event.altKey,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              });
            }}
            className="flex w-full text-ellipsis py-3 flex-1 space-x-2"
          >
            {FileHeader}
          </div>
        )}
        <>
          <ViewModelToolBar
            className={cx(isMobileSize ? 'hidden' : 'hidden group-hover:flex')}
            splitLine={[1, 2]}
            {...toolBarProps}
            {...viewModelToolBarData}
          />
          <ViewModelToolBar
            className={cx(isMobileSize ? 'flex' : 'flex group-hover:hidden')}
            {...viewModelToolBarData}
            viewMode={toolBarProps.viewMode}
            onDownLoad={toolBarProps.onDownLoad}
            openLink={toolBarProps.openLink}
            onOpen={toolBarProps.onOpen}
          />
        </>
      </div>
      <Caption blockId={uuid} />
    </BlockDrop>
  );
});
