import { deepEqual } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { DiscussionDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import type { IContent, IEditorModel, ISelection } from '@next-space/fe-inlined';
import {
  DEFAULT_FORMAT,
  Editor,
  readFormatAtOffset,
  readSelectionFromDOM,
} from '@next-space/fe-inlined';
import { useUnmount } from 'ahooks';
import isHotkey from 'is-hotkey';
import { useAtomCallback } from 'jotai/utils';
import { css } from 'otion';
import type { FC, KeyboardEvent } from 'react';
import React, { memo, useContext, useEffect, useMemo, useRef } from 'react';
import { fromEvent } from 'rxjs';
import { useCloseModal } from 'src/common/components/next-modal';
import { diffSegments } from 'src/components/rich-text-diff';
import {
  convertContentToUnWrapSegments,
  convertSegmentsToContent,
} from 'src/editor/utils/segments';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useEditorInput, useEditorKeydown } from 'src/hooks/editor';
import type { KeydownOption } from 'src/hooks/editor/config';
import { DEFAULT_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useReadonly } from 'src/hooks/page';
import { useSendPresenceData } from 'src/hooks/user/use-send-presence-data';
import { Modals } from 'src/modals';
import { useCloseCreateBlockMenuList } from 'src/redux/managers/ui';
import type { SegmentType } from 'src/redux/types';
import { $appUiStateCache, useAllowCommentInSharePage } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { focusTracker, useTrackFocus } from 'src/utils/focus-tracker';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import {
  SYNCED_REVISION_REF_SYMBOL,
  SYNCED_SEGMENTS_REF_SYMBOL,
  syncSegmentsToEditorModel,
} from 'src/utils/sync-segments-to-editor-model';
import { antiCycleSet_RichTextEdit as antiCycleSet_RichTextEdit0 } from 'src/views/comments/comment-editor';
import { useOpenDiscussionsPopup } from 'src/views/comments/comment-hooks';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { blockDiscussionIdsSelector, getAssociatedDiscussions } from '../../inline/comment';
import { wrapEmoji } from '../../inline/emoji-utils';
import type { InlinePlugin } from '../../inline/inline-plugin';
import { useUndo } from '../../inline/undo';
import { $blockId, BLOCK_SCOPE } from '../block-states';
import { EditableContext } from '../editable-context';
import { EDITOR_MODEL_OF_ELEMENT } from '../editable-models';
import { Toolbar } from '../editable-toolbar';
import {
  deleteEditorModel,
  DiffAgainstTo,
  makeEditorModelConfig,
  setEditorModel,
  useEditorModelKey,
} from './helper';
import { useGetOrInitEditorModel } from './hook';
import { DEFAULT_PLUGINS } from './plugins';

export interface RichTextEditProps {
  uuid: string; // 必须填
  realBlockId?: string; // 真实的 blockId，大多时候用 uuid 就够了，多维表编辑的时候如果都用 recordId 会导致覆盖，所以 uuid 有时是临时 id
  // 用于动态，动态里面基本每个block可能出现N次。所以不能再根据block.uuid来了
  editorModel?: IEditorModel;
  plugins?: InlinePlugin[];
  placeholder?: string;
  placeholderColor?: string;
  onKeyDown?(event: KeyboardEvent): void;
  onKeyDownCapture?(event: KeyboardEvent): void;
  className?: string;
  alwaysShowPlaceholder?: boolean;
  readonly?: boolean;
  interactable?: boolean;
  segments: SegmentDTO[] | undefined;
  onChange?: (
    segments: SegmentDTO[],
    prevContent: IContent,
    prevSelection: ISelection | null
  ) => void;
  toolbar?: boolean;
  comments?: boolean;
  autoFocus?: boolean;
  autoFocusCaretToEnd?: boolean;
  segmentType?: SegmentType;
  keydownOption?: KeydownOption;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  onFocus?: React.FocusEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  // 用于在开始输入中文的时候关闭评论浮层
  onCompositionStart?: React.CompositionEventHandler<HTMLDivElement>;
  history?: { count: number } | false;
  disabledKeydownOption?: boolean;
}

export const RichTextEdit: FC<RichTextEditProps> = memo((props) => {
  const {
    uuid,
    realBlockId,
    editorModel: editorModel0,
    className,
    plugins,
    placeholder,
    placeholderColor,
    alwaysShowPlaceholder = true,
    onKeyDown,
    onKeyDownCapture,
    readonly = false,
    interactable = true,
    segments,
    onChange,
    toolbar = true,
    comments = false,
    autoFocus = false,
    autoFocusCaretToEnd = false,
    segmentType = 'segments',
    keydownOption = DEFAULT_KEYDOWN_OPTION,
    onBlur,
    onFocus,
    onClick,
    onCompositionStart,
    history = { count: 20 },
    disabledKeydownOption,
    ...rest
  } = props;
  const isShared = judgeSharePage();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const pageId = useGetPageId();
  const editorInput = useEditorInput(uuid, segmentType, keydownOption);
  const editorModel = editorModel0 ?? getOrInitEditorModel(uuid, true, plugins);
  const sendPresenceData = useSendPresenceData();
  const { undo, redo } = useUndo(editorModel, !readonly && history ? history.count : 0);
  const editorModelKey = useEditorModelKey(uuid);
  const editorKeydown = useEditorKeydown(uuid, segmentType, keydownOption);
  const openCommentPopup = useOpenDiscussionsPopup();
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    editorModel.setReadonly(readonly);
  }, [editorModel, readonly]);

  const syncedSegmentsRef = useRef<SegmentDTO[] | undefined>();
  const syncedRevisionRef = useRef(NaN);

  // 注册/注销 editorModel
  useEffect(() => {
    (editorModel as any)[SYNCED_SEGMENTS_REF_SYMBOL] = syncedSegmentsRef;
    (editorModel as any)[SYNCED_REVISION_REF_SYMBOL] = syncedRevisionRef;
    setEditorModel(editorModelKey, editorModel);
    return () => {
      deleteEditorModel(editorModelKey);
    };
  }, [editorModelKey, editorModel]);

  useTrackFocus(editorModelKey, editorModel);

  const autoFocusRef = useRef({
    autoFocus,
    autoFocusCaretToEnd,
  });

  // 更新 segment 给 Editor
  useEffect(() => {
    syncSegmentsToEditorModel(editorModel, segments);
  }, [uuid, editorModel, segments]);

  // 监听 Editor Change, 同步到数据层∏
  useEffect(() => {
    if (editorModel.readonly) return;
    const subscription = editorModel.onChange.subscribe(
      ({ previousContent, previousSelection }) => {
        const { content } = editorModel;
        if (content === previousContent) return;
        const { unwrapSegments, segments } = convertContentToUnWrapSegments(content, true);
        const { wrapSegments, hasNewEmoji } = wrapEmoji(segments);
        // 如果有新的emoji插入，需要重新loadContent(这里处理的是粘贴过来的emoji，手动输入的在editInput的时候已经处理了)
        if (hasNewEmoji) {
          let focusOffset = editorModel.selection?.focusOffset;
          if (previousSelection) {
            focusOffset = previousSelection.focusOffset;
          }
          editorModel.performChange((ctx) => {
            ctx.load(convertSegmentsToContent(wrapSegments));
            ctx.select(focusOffset, focusOffset);
          });
          return;
        }
        if (syncedRevisionRef.current !== editorModel.revision) {
          syncedSegmentsRef.current = unwrapSegments;
          onChange?.(unwrapSegments, previousContent, previousSelection);
          syncedRevisionRef.current = editorModel.revision;
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [editorModel, onChange]);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;
    (element as any)[EDITOR_MODEL_OF_ELEMENT] = editorModel;
  }, [editorModel]);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;
    const inputCB = (e: any) => {
      editorInput(e);
    };
    (element as any).addEventListener('inlinedinput', inputCB);
    return () => {
      (element as any).removeEventListener('inlinedinput', inputCB);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInput]);

  useEffect(() => {
    const { autoFocus, autoFocusCaretToEnd } = autoFocusRef.current;
    setTimeout(() => {
      // 导图双击进入编辑自动focus时，页面会跳动，需要setTimeout一下能避免这个问题
      if (autoFocus) {
        if (autoFocusCaretToEnd) {
          editorModel.performChange((ctx) => {
            ctx.select().collapse('end');
          });
        }
        void editorModel.requestFocus();
      }
    }, 0);
  }, [editorModel]);

  useEffect(() => {
    const formatSubscription = editorModel.onSelectionChange.subscribe(() => {
      let format = DEFAULT_FORMAT;
      if (editorModel.selection != null) {
        const format2 = readFormatAtOffset(editorModel.content, editorModel.selection.endOffset);
        format = { ...format2, customs: null };
      }
      editorModel.performChange((ctx) => {
        ctx.setFormat(format);
      });
    });

    return () => {
      formatSubscription.unsubscribe();
    };
  }, [editorModel]);

  useEffect(() => {
    if (!readonly) return;
    // 只读模式下编辑器没有焦点无法响应评论快捷键，这里注册一个全局的
    const subscription = fromEvent<KeyboardEvent>(document, 'keydown').subscribe((event) => {
      if (!isHotkey('mod+shift+m')(event)) return;
      const selection = readSelectionFromDOM();
      if (selection == null) return;
      if (selection[0] !== editorModel.editorKey) return;
      (event as any).nativeEvent = event;
      editorKeydown(event as any);
    });
    return () => subscription.unsubscribe();
  }, [editorKeydown, editorModel.editorKey, readonly]);

  const handleClick = useAtomCallback((get, _set, event: React.MouseEvent<HTMLDivElement>) => {
    !readonly && sendPresenceData(pageId, uuid);
    onClick?.(event);
    if (!comments) return;

    // 弹出评论弹窗
    const offset = editorModel.hitTest(event.clientX, event.clientY);
    if (offset == null) return;
    if (editorModel.selection != null && !editorModel.selection.isCollapsed) return;

    const format = readFormatAtOffset(editorModel.content, offset);
    const associatedDiscussionIds = getAssociatedDiscussions(format);
    const blockId = get($blockId);
    const blockDiscussionIds = blockDiscussionIdsSelector(blockId, { noTable: true });
    const discussionIds = [...associatedDiscussionIds, ...blockDiscussionIds];

    openCommentPopup({
      editorModel,
      popcorn: {
        getBoundingClientRect() {
          const rect = editorModel.getBoundingClientRectOfRange(offset, offset);
          return rect ?? new DOMRect(-9999, -9999, 0, 0);
        },
      },
      selector: (state) => {
        return discussionIds
          .map((it) => state.discussions[it])
          .filter(
            (it) => it != null && it.status === BlockStatus.NORMAL && !it.resolved
          ) as DiscussionDTO[];
      },
    });
  }, BLOCK_SCOPE);
  const showCommentInShare = allowCommentInSharePage && isShared && comments;
  const showCommentInNormal = !isShared && (!readonly || comments);
  return (
    <EditableContext.Provider value={{ readonly, interactable }}>
      <Editor
        emptyClassName={cx(
          'biz-placeholder-shown',
          css({
            selectors: {
              [alwaysShowPlaceholder ? '&:after' : '&:focus-within:after']: {
                content: JSON.stringify(placeholder),
                pointerEvents: 'none',
                color: placeholderColor ?? 'var(--grey4)',
              },
              '& > br': {
                display: 'none',
              },
            },
          })
        )}
        data-real-block-id={realBlockId ?? uuid}
        className={cx(
          'break-words whitespace-pre-wrap',
          className,
          css({ outline: '0 !important' })
        )}
        model={editorModel}
        ref={containerRef}
        onBlur={onBlur}
        onFocus={onFocus}
        spellCheck={__BUILD_IN__}
        onClick={handleClick}
        onCompositionStart={onCompositionStart}
        onKeyDownCapture={onKeyDownCapture}
        onKeyDown={(event) => {
          if (readonly) return;
          onKeyDown?.(event);
          // 如果被onKeyDown拦截了就不继续往下执行
          if (event.isDefaultPrevented()) return;

          if (history) {
            if (isHotkey('mod+z')(event)) {
              undo();
              event.preventDefault();
              event.stopPropagation();
            } else if (isHotkey('mod+shift+z')(event)) {
              redo();
              event.preventDefault();
              event.stopPropagation();
            }
          }

          if (!disabledKeydownOption) {
            editorKeydown(event);
          }
        }}
        {...rest}
      />
      {toolbar && (showCommentInShare || showCommentInNormal) && (
        <Toolbar
          uuid={uuid}
          containerRef={containerRef}
          model={editorModel}
          comments={comments}
          segmentType={segmentType}
        />
      )}
    </EditableContext.Provider>
  );
}, deepEqual);

interface EditableProps {
  uuid: string;
  placeholder?: string;
  onKeyDown?(event: KeyboardEvent): void;
  className?: string;
  alwaysShowPlaceholder?: boolean;
  toolbar?: boolean;
  disabledKeydownOption?: boolean;
  segmentType?: SegmentType;
  keydownOption?: KeydownOption;
  comments?: boolean;
}

export const Editable = memo<EditableProps>((props) => {
  return <EditableContent {...props} />;
});

const EditableContent = (props: EditableProps) => {
  const {
    onKeyDown,
    placeholder,
    uuid,
    className,
    alwaysShowPlaceholder = true,
    toolbar,
    disabledKeydownOption,
    segmentType = 'segments',
    keydownOption,
    comments = true,
  } = props;
  const block = usePickBlock(uuid, ['data'], [segmentType]);
  const readonly = useReadonly(uuid);
  const updateSegments = useThrottleUpdateSegments(uuid, segmentType);
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const closeModal = useCloseModal();

  // 如果在动态内，则每个组件创建一个 editorModel
  const editorModelRef = useRef<IEditorModel>();
  const scene = usePageScene();
  const editorModel = useMemo(() => {
    if (scene === PageScene.PAGE_FEEDS) {
      if (editorModelRef.current === undefined) {
        const EditorModel = makeEditorModelConfig(DEFAULT_PLUGINS);
        editorModelRef.current = new EditorModel();
        return editorModelRef.current;
      }
    }
    return getOrInitEditorModel(uuid, true);
  }, [getOrInitEditorModel, scene, uuid]);

  const diffAgainstTo = useContext(DiffAgainstTo);

  const isPrint = $searchParams.print;

  useEffect(() => {
    if (focusTracker.activeEditorKey === editorModel.editorKey) {
      if (focusTracker.selection) {
        editorModel.performChange((ctx) => {
          if (focusTracker.selection) {
            ctx.select(focusTracker.selection.offset, focusTracker.selection.endOffset);
          }
        });
        focusTracker.selection = null;
      }
      void editorModel.requestFocus();
    }
  }, [uuid, editorModel]);

  useUnmount(() => {
    if ($appUiStateCache.$createBlockMenuListId === uuid) {
      closeCreateBlockMenuList();
    }
  });

  let segments = block?.data[segmentType];

  if (diffAgainstTo) {
    // 用于动态中显示diff
    if (readonly) {
      if (diffAgainstTo === 'deleted') {
        segments = diffSegments(segments, undefined);
      } else if (diffAgainstTo === 'created') {
        segments = diffSegments(undefined, segments);
      } else {
        segments = diffSegments(diffAgainstTo.data[segmentType], segments);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('diffAgainstTo must company with readonly');
    }
  }
  return (
    <RichTextEdit
      keydownOption={keydownOption}
      uuid={uuid}
      editorModel={editorModel}
      toolbar={toolbar}
      comments={comments}
      disabledKeydownOption={disabledKeydownOption}
      className={cx(className, 'break-words')}
      placeholder={isPrint ? '' : placeholder}
      alwaysShowPlaceholder={isPrint ? false : alwaysShowPlaceholder}
      onKeyDown={onKeyDown}
      readonly={readonly}
      segments={segments}
      segmentType={segmentType}
      onCompositionStart={() => {
        closeModal(Modals.COMMENTS);
      }}
      onChange={(segments, prevContent, prevSelection) => {
        // REMARK: 评论是readonly状态，但是需要修改内容
        // 如果有依赖下面return的代码，需要调整一下这里的逻辑。针对评论临时放行
        // if (readonly) {
        //   return;
        // }

        if (scene === PageScene.PAGE_LITE_PREVIEW) return;
        updateSegments(segments, [prevContent, prevSelection]);
      }}
      history={false}
    />
  );
};

Editable.displayName = 'Editable';

antiCycleSet_RichTextEdit0(RichTextEdit);
