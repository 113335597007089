import { $currentUserCache } from 'src/services/user/current-user';

export enum CreatePageWidthMode {
  FIXED = 'fixed',
  AUTO = 'auto',
}

export const getPageWidthFixed = () => {
  return (
    ($currentUserCache.setting?.pageWidthMode || CreatePageWidthMode.FIXED) ===
    CreatePageWidthMode.FIXED
  );
};
