/* eslint-disable */
let NAMESPACE = 'emoji-mart';

const isLocalStorageSupported = typeof window !== 'undefined' && 'localStorage' in window;

let getter;
let setter;

function setHandlers(handlers) {
  handlers || (handlers = {});

  getter = handlers.getter;
  setter = handlers.setter;
}

function setNamespace(namespace) {
  NAMESPACE = namespace;
}

function update(state) {
  for (const key in state) {
    const value = state[key];
    set(key, value);
  }
}

function set(key, value) {
  if (setter) {
    setter(key, value);
  } else {
    if (!isLocalStorageSupported) return;
    try {
      window.localStorage[`${NAMESPACE}.${key}`] = JSON.stringify(value);
    } catch (e) {
      //
    }
  }
}

function get(key) {
  if (getter) {
    return getter(key);
  }
  if (!isLocalStorageSupported) return;
  try {
    const value = window.localStorage[`${NAMESPACE}.${key}`];

    if (value) {
      return JSON.parse(value);
    }
  } catch (e) {
    //
  }
}
const obj = {
  update,
  set,
  get,
  setNamespace,
  setHandlers,
};

export default obj;
