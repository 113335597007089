export const NewCardPlaceholderId = 'NewRecordPlaceholderId';
export const ONE_MINUTES = 60 * 1000;
export const ONE_MOMENT = 15 * ONE_MINUTES;
export const ONE_HOURS = 4 * ONE_MOMENT;
export const ONE_DAY = 24 * ONE_HOURS;
export const RecordLineHeight = 37;
export const PAGE_MANAGER_HEIGHT = 44;
export const EMBED_PAGE_MANAGER_HEIGHT = 32;
export const ScrollBufferLength = 300;
export const CorrectOffsetY = -2;

export enum DateDir {
  PREV = 'prev',
  NEXT = 'next',
}

export enum ZoomLevel {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  BIWEEK = 'biweek',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export const InitDateRangeLength = {
  // 新建记录初始单位长度
  [ZoomLevel.HOUR]: 4,
  [ZoomLevel.DAY]: 12,
  [ZoomLevel.MONTH]: 5,
  [ZoomLevel.WEEK]: 1,
  [ZoomLevel.BIWEEK]: 5,
  [ZoomLevel.QUARTER]: 14,
  [ZoomLevel.YEAR]: 28,
};

export const StepLengthMap = {
  [ZoomLevel.HOUR]: 60, // 2 * 60 * 80 = 9600
  [ZoomLevel.DAY]: 60, // 2* 60 * 20 * 4 = 9600
  [ZoomLevel.MONTH]: 100,
  [ZoomLevel.BIWEEK]: 50,
  [ZoomLevel.WEEK]: 25,
  [ZoomLevel.QUARTER]: 25,
  [ZoomLevel.YEAR]: 25,
};

export const UnitWidthMap: Record<ZoomLevel, number> = {
  // 一个时刻 15 min 的长度
  [ZoomLevel.HOUR]: 80,
  [ZoomLevel.DAY]: 20,
  // 一天的长度
  [ZoomLevel.WEEK]: 180,
  [ZoomLevel.BIWEEK]: 80,
  [ZoomLevel.MONTH]: 40,
  [ZoomLevel.QUARTER]: 10,
  [ZoomLevel.YEAR]: 5,
} as const;

export const ZoomLevels = [
  {
    name: '小时',
    level: ZoomLevel.HOUR,
  },
  {
    name: '天',
    level: ZoomLevel.DAY,
  },
  {
    name: '周',
    level: ZoomLevel.WEEK,
  },
  {
    name: '双周',
    level: ZoomLevel.BIWEEK,
  },
  {
    name: '月',
    level: ZoomLevel.MONTH,
  },
  {
    name: '季',
    level: ZoomLevel.QUARTER,
  },
  {
    name: '年',
    level: ZoomLevel.YEAR,
  },
];

export enum Direction {
  left = 'left',
  right = 'right',
}

export const DirectionMap = [Direction.left, Direction.right];
