import { message } from '@flowus/common/components/message';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Switch } from 'src/common/components/switch';
import type { request } from 'src/common/request';
import { downloadFileNoBlank } from 'src/common/utils/download-utils';
import { loadingMessage } from 'src/editor/editor/uikit/loading-message';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { isCollection, isPageLike } from 'src/utils/block-type-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { enqueueTask } from 'src/utils/tasks';
import { useCurrentSpace } from '../../../../hooks/space/use-current-space';
import { Option, Select } from './components';
import { dataBaseRanges, pdfFormats, scales, types, layouts } from './config';

interface Props {
  onCloseModal: () => void;
  uuid?: string;
}

export const ExportFile: FC<Props> = ({ uuid, onCloseModal }) => {
  const pageId = useGetPageId();
  const blockId = uuid || pageId;
  const currentSpace = useCurrentSpace();
  const hasCollection = useObservableStore(
    ({ blocks }) => {
      const page = blocks[blockId];
      if (!page) return false;
      if (isCollection(page.type)) return true;
      const loop = (id: string) => {
        const block = blocks[id];
        if (block) {
          if ([BlockType.COLLECTION_VIEW, BlockType.REFERENCE_COLLECTION].includes(block.type)) {
            return true;
          }
          if (isPageLike(block.type)) {
            return false;
          }
          const { subNodes } = block;
          return subNodes.some(loop);
        }
      };
      return !!page.subNodes.some(loop);
    },
    [blockId]
  );

  const [exportOptions, setExportOptions] = useState<
    Parameters<typeof request.infra.enqueueTask>[0]['request']['exportOptions']
  >({
    type: 'pdf',
    pdfFormat: 'a4',
    dataBaseRange: 'current',
    scale: 1,
    landscape: false,
  });

  const exportFile = async () => {
    bizTracker.event('export_page', {
      export_type: exportOptions?.type,
      page_type: cache.blocks[blockId]?.type,
    });

    const taskId = await enqueueTask(
      {
        eventName: 'export',
        request: {
          blockId,
          spaceId: currentSpace.uuid,
          exportOptions,
        },
      },
      {
        success({ url }) {
          url && downloadFileNoBlank(url);
          message.closeMessage(taskId);
        },
        fail() {
          message.closeMessage(taskId);
        },
      }
    );

    onCloseModal();

    loadingMessage(taskId, '正在导出，请稍候...');
  };

  return (
    <div className="w-[360px] px-8 py-6">
      <Option title="导出格式">
        <Select
          items={types}
          value={exportOptions?.type}
          onClick={(type) => {
            if (type === 'markdown') {
              setExportOptions(() => ({
                type,
                includeContents: true,
                includeSubPages: true,
                flattenFileTree: false,
              }));
            } else {
              setExportOptions(() => ({
                type,
                pdfFormat: 'a4',
                dataBaseRange: 'current',
                scale: 1,
              }));
            }
          }}
        />
      </Option>

      {exportOptions?.type === 'pdf' && (
        <>
          <Option title="页面尺寸">
            <Select
              items={pdfFormats}
              value={exportOptions?.pdfFormat}
              onClick={(pdfFormat) => setExportOptions((pre) => ({ ...pre, pdfFormat }))}
            />
          </Option>

          {hasCollection && (
            <Option title="多维表导出范围">
              <Select
                items={dataBaseRanges}
                value={exportOptions?.dataBaseRange}
                onClick={(dataBaseRange) => setExportOptions((pre) => ({ ...pre, dataBaseRange }))}
              />
            </Option>
          )}

          <Option title="显示比例">
            <Select
              items={scales}
              value={exportOptions?.scale}
              onClick={(scale) => setExportOptions((pre) => ({ ...pre, scale }))}
            />
          </Option>

          <Option title="排版">
            <Select
              items={layouts}
              value={exportOptions?.landscape}
              onClick={(landscape) => setExportOptions((pre) => ({ ...pre, landscape }))}
            />
          </Option>
        </>
      )}

      {exportOptions?.type === 'markdown' && (
        <>
          <Option title="导出附件">
            <Switch
              open={!!exportOptions.includeContents}
              onSwitch={(includeContents) =>
                setExportOptions((pre) => ({
                  ...pre,
                  includeContents,
                }))
              }
            />
          </Option>
          <Option title="导出子页面">
            <Switch
              open={!!exportOptions.includeSubPages}
              onSwitch={(includeSubPages) =>
                setExportOptions((pre) => ({
                  ...pre,
                  includeSubPages,
                }))
              }
            />
          </Option>
          <Option title="按目录导出">
            <Switch
              open={!exportOptions.flattenFileTree}
              onSwitch={(open) =>
                setExportOptions((pre) => ({
                  ...pre,
                  flattenFileTree: !open,
                }))
              }
            />
          </Option>
        </>
      )}

      <div className="flex items-center justify-end mt-6">
        <Button onClick={onCloseModal} className="mr-4 text-t2-medium">
          取消
        </Button>

        <Button colorType="active" className="text-t2-medium text-white" onClick={exportFile}>
          导出
        </Button>
      </div>
    </div>
  );
};
