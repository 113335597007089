import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

export const Cover: FC<{
  isCustomTemplate?: boolean;
  imageUrl: string;
  onClick: () => void;
  templateId: string;
  className?: string;
}> = (props) => {
  if (props.isCustomTemplate) {
    return (
      <div
        className={cx(
          'absolute w-full h-full top-0 lef-0 flex items-center justify-center cursor-pointer'
        )}
        onClick={() => props.onClick()}
      >
        <img className="w-[100px]" src={props.imageUrl} />
      </div>
    );
  }
  return (
    <img
      className={cx(
        'cursor-pointer w-full h-full rounded scale-[1.1] object-cover',
        props.className
      )}
      src={props.imageUrl}
      onClick={() => {
        props.onClick();
      }}
      style={{
        // 图需加位置偏移掉左上角三个点
        objectPosition: '0px -10px',
      }}
    />
  );
};
