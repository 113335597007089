export enum OpenSettingFrom {
  /** 支付成功 */
  pay = 'pay',
  /** 成员分组 */
  group = 'group',
  /** 版本对比 */
  package = 'package',
  /** 文件付费 */
  file = 'file',
  /** 页面历史 */
  pageHistory = 'page_history',
  /** 外部协作者 */
  extMember = 'ext_member',
  /** 空间设置-容量 */
  spaceSetFile = 'spaceset_file',
  /** 空间设置-块限制 */
  spaceSetBlock = 'spaceset_block',
  /** 目录-容量 */
  cataFile = 'cata_file',
  /** 目录-块限制 */
  cataBlock = 'cata_block',
  /** 模板数到上限 */
  temp_max = 'temp_max',
  /** 公开主页 */
  homepage = 'homepage',
  /** 订单记录 */
  order = 'order',
  /** 人员上限 */
  members = 'members',
  /** 批量下载 */
  bulk_download = 'bulk_download',
  /** 引用建议 */
  link_suggest = 'link_suggest',
  /** 脑图节点上线 */
  mind_node_limit = 'mindmap_number',
  /** 周年庆 */
  anniversary = 'anniversary',
  /** 导入成员 */
  importMember = 'import_member',
  /** 自定义布局 */
  custom_font_layout = 'font_layout',
  /** 信息板 */
  message_board = 'message_board',
  /** 空间信息-计划 */
  dashboard_plan = 'dashboard_plan',
  /** 空间信息-容量 */
  dashboard_file = 'dashboard_file',
  /** 空间信息-成员 */
  dashboard_member = 'dashboard_member',
  /** 消息通知-收件箱 */
  notification_inbox = 'notification_inbox',
  /** 活动弹窗 */
  activity = 'activity',
  /** 导出思维导图水印 */
  export_watermark = 'export_watermark',
  /** header顶部容量提示 */
  capacity_header = 'capacity_header',
  /** 收件箱 */
  inbox = 'inbox',
  /** 完成任务相关弹窗 */
  new_user_task = 'new_user_task',
  /** 消息通知弹窗 */
  notification_normal = 'notification_normal',
  /** 提示 */
  tip = 'tip',
  /** 卡片样式 */
  page_card = 'page_card',
  /** 分享seo */
  share_seo = 'share_seo',
  /** 预览epub */
  view_epub = 'epub',
  /** kol 分销活动 */
  kol_sales = 'KOLsales',
  /** ai 次数 */
  ai = 'ai',
  /** wps 文件编辑 */
  wps = 'wps',
  /** 空间 AI 面板 */
  dashboard_ai = 'dashboard_ai',
  /** ai 上限 */
  ai_max = 'ai_max',
  /** 分享链接有效期 */
  link_valid = 'link_valid',
  /** 空间安全选项 */
  space_security = 'space_security',
  /** 空间外部协作者上限 */
  guest_max = 'guest_max',
  /** 订阅问问 */
  dialog_subscribe_ai = 'dialog_subscribe_ai',
  /** 空间问问 */
  dialog_space_ai = 'dialog_space_ai',
  // ai权益
  dialog_ai_rights = 'dialog_ai_rights',
  // 购买容量包
  dialog_capacity_package = 'dialog_capacity_package',
  // 拷贝时空间容量上限
  dialog_capacity_limit = 'dialog_capacity_limit',
  // 拷贝次数上限
  dialog_copy_limit = 'dialog_copy_limit',
  // 移动到需要升级时候，要切换空间升级
  switch_space = 'switch_space',
  h5 = 'h5',
}

/** 设置弹窗展示标签 */
export enum SettingMenuType {
  account,
  integral,
  space,
  spaceInfo,
  members,
  upgrade,
  line,
  order,
  general,
  notificationsAndSetting,
  sharePage,
  profit,
}

export type SettingModelProps = Partial<{
  /** 默认选中哪个标签 */
  defaultMenuType: SettingMenuType;
  /** 埋点相关，从何处打开 */
  from: OpenSettingFrom;
  /** 是否显示 ai 付费面板 */
  defaultUpgradeAiPanel: boolean;
}>;
