import type { SegmentDTO } from '@next-space/fe-api-idl';

export const sliceSegments = (
  segments: SegmentDTO[] | undefined,
  start: number,
  end = Infinity
) => {
  if (!segments) {
    return [];
  }
  const result: SegmentDTO[] = [];

  let offset = 0;
  for (const segment of segments) {
    const a = offset;
    const b = offset + segment.text.length;

    let { text } = segment;
    offset += text.length;

    if (b <= start) {
      continue;
    }
    if (a >= end) {
      break;
    }

    let i = 0;
    let j = text.length;

    if (start > a) {
      i = start - a;
    }
    if (b > end) {
      j = text.length - (b - end);
    }
    text = text.slice(i, j);
    result.push({
      ...segment,
      text,
    });
  }

  return result;
};
