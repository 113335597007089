import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import { urlFetcher } from 'src/utils/url-fetcher';
import { useFileDownLimit } from '../limit/file-down-limit';
import { getFileNameInfo } from '@flowus/common';
import { getPreviewType } from 'src/components/file-preview/renderElements';
import { getImageWatermark } from '../space/use-open-image-watermark';
import { addImageWatermark } from '@flowus/common/url';

export const useDownloadFile = () => {
  const fileDownLimit = useFileDownLimit();
  return useCallback(
    async (id: string) => {
      const { blocks } = getState();
      const block = blocks[id];
      if (!block) return;
      let downloadUrl: string | undefined;
      let fileName = `${Date.now()}`;
      if (block.type === BlockType.EXTERNAL_FILE) {
        downloadUrl = block.data.link;
        fileName = block.data.link || fileName;
      } else {
        fileName = segmentsToText(block.data.segments) || fileName;
        downloadUrl = await urlFetcher.fetchDownloadUrl({
          blockId: block.uuid,
          ossName: block.data.ossName ?? '',
          fileName,
        });
        const { extName } = getFileNameInfo(fileName);
        if (getPreviewType(extName) === 'image') {
          const { openImageWatermark, imageWatermarkText } = getImageWatermark(block.spaceId);
          if (openImageWatermark && imageWatermarkText) {
            downloadUrl = addImageWatermark(downloadUrl, imageWatermarkText);
          }
        }
      }

      await fileDownLimit();
      downloadUrl && void downloadUrlFile(downloadUrl, fileName);
    },
    [fileDownLimit]
  );
};
