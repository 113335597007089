import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import * as Empty from 'src/components/empty';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { usePickBlock } from 'src/utils/pick-block';
import { PageBitable } from 'src/views/main/page-bitable';
import { PageChart } from 'src/views/main/page-chart';
import { PageDoc } from 'src/views/main/page-doc';
import { PageDrive } from 'src/views/main/page-drive';
import { PageMind } from 'src/views/main/page-mind';

export const PageContent: FC<{ uuid: string }> = ({ uuid }) => {
  const block = usePickBlock(uuid, []);
  const { illegal } = usePermissions(uuid);

  if (illegal) {
    return <Empty.NoPermission uuid={uuid} />;
  }

  let content = null;
  switch (block?.type) {
    case BlockType.PAGE: {
      content = <PageDoc uuid={uuid} />;
      break;
    }
    case BlockType.REFERENCE_COLLECTION:
    case BlockType.REFERENCE_COLLECTION_PAGE:
    case BlockType.COLLECTION_VIEW:
    case BlockType.COLLECTION_VIEW_PAGE: {
      content = <PageBitable uuid={uuid} />;
      break;
    }
    case BlockType.FOLDER: {
      content = <PageDrive uuid={uuid} />;
      break;
    }
    case BlockType.MIND_MAPPING:
    case BlockType.MIND_MAPPING_PAGE: {
      content = <PageMind uuid={uuid} />;
      break;
    }
    case BlockType.CHART_PAGRE:
    case BlockType.CHART: {
      content = <PageChart uuid={uuid} />;
      break;
    }
    default:
      // 拿不到时，返回找不到页面
      if (uuid) {
        content = <Empty.NotFound showBackButton={false} />;
      } else {
        content = <PageDoc uuid={uuid} />;
      }
      break;
  }

  return content;
};
