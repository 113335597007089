import { isIndentbleType } from 'src/utils/block-type-utils';

import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';

export const indentBlock = (ids: string[]) => {
  const { blocks } = getState();

  const id = ids[0];

  if (!id) return;

  const block = blocks[id];
  if (!block) return;

  const parent = blocks[block.parentId];
  if (!parent) return;

  const index = parent.subNodes.indexOf(id);
  if (index < 1) return;

  const prevId = parent.subNodes[index - 1];
  if (!prevId) return;

  const prevBlock = blocks[prevId];
  if (!prevBlock) return;

  if (!isIndentbleType(prevBlock)) return;

  ids.forEach((uuid) => {
    dispatch(LIST_REMOVE_BLOCK({ uuid }));
    dispatch(LIST_AFTER_BLOCK({ uuid, parentId: prevId }));
  });
};
