// 此文件的存在出于解决循环依赖的目的
import { deepEqual } from '@flowus/common/utils/tools';
import { BlockType } from '@next-space/fe-api-idl';
import { Provider as JotaiProvider } from 'jotai';
import { memo, useMemo } from 'react';
import { LazyList } from 'src/common/components/lazy-list';
import { VisibleRender } from 'src/common/components/visible-render';
import type { RenderMode } from 'src/common/components/visible-render/types';
import { $searchParams } from 'src/utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useSetPageLazyListLoading } from 'src/views/main/page-doc/context';
import { getBlockRenderer } from '../_renderers';
import { $blockId, BLOCK_SCOPE } from './block-states';

interface Props {
  id: string;
  root?: boolean;
  type?: BlockType;
  subNodes?: string[];
  visibleRender?: RenderMode;
}

const BlockRenderer0 = memo(({ id, root = false, type, subNodes = [], visibleRender }: Props) => {
  const Element = getBlockRenderer(type);

  const subChild = useMemo(() => {
    if (!subNodes.length) return undefined;
    return subNodes.map((i) => <BlockRenderer id={i} key={i} visibleRender={visibleRender} />);
  }, [subNodes, visibleRender]);

  return (
    <JotaiProvider key={`${id}-${root}`} scope={BLOCK_SCOPE} initialValues={[[$blockId, id]]}>
      <Element id={id} root={root}>
        {subChild}
      </Element>
    </JotaiProvider>
  );
}, deepEqual);

const whiteList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.HEADER,
  BlockType.QUOTE,
  BlockType.PAGE,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.LINK_PAGE,
  BlockType.MARK,
  BlockType.MIND_MAPPING,
  BlockType.MIND_MAPPING_PAGE,
  BlockType.TOGGLE_HEADER,
];

const FORCE_BLANK_TYPES = [BlockType.MIND_MAPPING, BlockType.PAGE];

export const BlockRenderer = (props: Props) => {
  const { id, visibleRender, root } = props;

  const block = usePickBlock(id, ['subNodes'], [], { enableAuditTime: false });
  const blockType = block?.type;
  const subNodes = block?.subNodes;
  const isForce = blockType && FORCE_BLANK_TYPES.includes(blockType);

  const subNodeHasOtherBlock = useMemo(() => {
    // 也是只针对 root 节点做优化，避免太多监听节点
    if (!root) return false;

    // 没有block
    if (blockType === undefined) return false;

    if (isForce) return true;

    if (!visibleRender) return false;

    const isSharePage = judgeSharePage();
    if (isSharePage) return false;

    // 当前block不在白名单
    const isWhiteBlock = whiteList.includes(blockType);
    if (!isWhiteBlock) return false;

    // 没有子节点
    // if (!subNodes?.length) return true;

    return true;
  }, [blockType, isForce, root, visibleRender]);

  if (blockType === undefined) return null;

  if (subNodeHasOtherBlock) {
    return (
      <VisibleRender key={`visible-${id}`} blockId={id} renderMode={visibleRender}>
        <BlockRenderer0 {...props} subNodes={subNodes} type={blockType} />
      </VisibleRender>
    );
  }

  return <BlockRenderer0 key={id} {...props} subNodes={subNodes} type={blockType} />;
};

interface LazySubNodeListProps {
  subNodes?: string[];
  visibleRender?: RenderMode;
  root?: boolean;
}
export const LazySubNodeList = memo((props: LazySubNodeListProps) => {
  const setPageLazyListLoading = useSetPageLazyListLoading();
  const { subNodes = [], visibleRender } = props;

  return (
    <LazyList
      maxSize={$searchParams.print ? Infinity : undefined}
      items={subNodes}
      onProgress={(status) => {
        setPageLazyListLoading(status === 'start');
      }}
      renderItem={(item) => (
        <BlockRenderer key={item} id={item} root visibleRender={visibleRender} />
      )}
    />
  );
}, deepEqual);
