import isHotkey from 'is-hotkey';
import classnames from 'classnames';
import type {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  ReactNode,
  RefObject,
} from 'react';
import { useEffect, useRef } from 'react';

export interface InputType {
  text: string;
  number: number;
}
export interface InputProps<T extends keyof InputType> {
  defaultValue?: string;
  visible?: boolean;
  autoFocus?: boolean;
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  warning?: string;
  inputRef?: RefObject<HTMLInputElement> | null | any;
  onEnter?: Function;
  onChange?: (value: InputType[T]) => void;
  type?: T;
  value?: InputType[T] | string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
  style?: CSSProperties;
  showBorder?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  selection?: number[];
  readonly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputType?: HTMLInputTypeAttribute | undefined;
}

/**
 * 输入框
 */
export const Input = <T extends keyof InputType = 'text'>(props: InputProps<T>) => {
  const {
    type = 'text',
    visible = true,
    showBorder = true,
    value,
    onChange,
    placeholder,
    addonAfter,
    addonBefore,
    maxLength,
    autoFocus,
    warning,
    inputRef,
    onEnter,
    defaultValue,
    onBlur,
    onFocus,
    selection,
    style,
    readonly = false,
    onClick,
    onKeyDown,
  } = props;
  const isComposing = useRef(false);
  const currentInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;
    const changeValue = event.target.value;
    let value;
    const checkNum = /^\d+$/.test(changeValue);
    if (type === 'text') {
      value = changeValue;
    } else if (type === 'number') {
      if (checkNum || !changeValue) {
        value = changeValue;
      } else {
        return;
      }
    }

    onChange(value as InputType[T]);
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current = currentInputRef.current;
    }
    if (selection?.[0] !== undefined && selection[1] !== undefined) {
      currentInputRef.current?.setSelectionRange(selection[0], selection[1]);
    }
    autoFocus && currentInputRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={style}
      className={classnames(
        'flex items-center rounded relative box-border bg-grey8 text-t2 border border-grey6',
        {
          hidden: !visible,
          'border-red': warning,
          'focus-within-border': showBorder && !warning,
          'border-none': !showBorder,
        },
        props.className
      )}
      onClick={() => {
        if (!readonly && !addonBefore && !addonAfter) {
          currentInputRef.current?.focus();
        }
      }}
    >
      <input
        ref={currentInputRef}
        spellCheck={false}
        className={classnames(
          'align-middle cursor-text text-t1 flex-grow text-black outline-none bg-transparent placeholder-grey4 w-full',
          props.inputClassName
        )}
        type={props.inputType ?? 'text'}
        onKeyDown={(event) => {
          if (onKeyDown) {
            onKeyDown(event);
          } else {
            event.stopPropagation();
            if (isComposing.current) return;
            if (isHotkey('enter')(event) && onEnter) onEnter(event);
          }
        }}
        disabled={readonly}
        onFocus={() => {
          if (readonly) return;
          onFocus?.();
        }}
        onBlur={() => onBlur?.()}
        onClick={(event) => {
          onClick?.();
          currentInputRef.current?.focus();
          event.stopPropagation();
        }}
        autoFocus={autoFocus}
        maxLength={maxLength || -1}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onInput={handleInputChange}
        onCompositionStart={() => (isComposing.current = true)}
        onCompositionEnd={() => (isComposing.current = false)}
      />
      {addonAfter && (
        <span className="flex items-center justify-center h-full pl-2 whitespace-nowrap">
          {addonAfter}
        </span>
      )}
      {warning && (
        <span className="absolute -bottom-5 mb-0.5 text-red left-2 text-t4">{warning}</span>
      )}
    </div>
  );
};
