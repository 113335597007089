import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import { useLocalStorageState } from 'ahooks';
import { useAtom } from 'jotai';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import { batch } from 'react-redux';
import { getRandomEmoji } from 'src/common/components/emoji-widget/emoji-mart/utils';
import { request } from 'src/common/request';
import { deepEqual } from '@flowus/common/utils/tools';
import { IconTrigger } from 'src/components/icon-trigger';
import { segmentsToText } from 'src/editor/utils/editor';
import { useIsRecord } from 'src/hooks/block/use-is-record';
import { useReadonly } from 'src/hooks/page';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpace } from 'src/hooks/space';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { SpacePlanType } from 'src/redux/types';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isCollection } from 'src/utils/block-type-utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { PageOpenDiscussions } from 'src/views/comments/page-discussions';
import { $showTempDiscussion } from 'src/views/comments/page-states';
import { PAGE_SCOPE } from '../page-states';
import { HeaderCover } from './header-cover';
import { HoverButton } from './hover-button';
import { PageDescription } from './page-description';
import { PageTitle } from './page-title';

interface Props {
  uuid: string;
  className?: string;
  children?: ReactNode;
  hiddenCover?: boolean;
}

export const calcPageHeaderCoverHeight = (height?: number) => {
  return (height ?? 1024) * 0.29;
};

export const PageHeader: FC<Props> = memo(({ uuid, className, children, hiddenCover }) => {
  const block = usePickBlock(uuid, ['data'], ['icon', 'cover', 'showEmptyDescription', 'format']);
  const isRecord = useIsRecord(uuid);
  const commentAlignment = block?.data.format?.commentAlignment ?? 'top';
  const space = getCurrentSpace();
  const defaultShowComments =
    (space.planType === SpacePlanType.team || space.planType === SpacePlanType.freeTeam) &&
    isRecord;
  const transaction = useTransaction();
  const hasDescription = useObservableBlock(uuid, (block) => {
    return segmentsToText(block?.data.description).length > 0;
  });
  const [showDescription, setShowDescription] = useLocalStorageState<boolean | undefined>(
    `collection:${uuid}:showDescription`,
    undefined
  );
  const { role } = usePermissions(uuid);
  const readonly = useReadonly(uuid);
  const [showTempDiscussion, setShowTempDiscussion] = useAtom($showTempDiscussion, PAGE_SCOPE);

  const numOpenDiscussions = useObservableStore(
    (state) => {
      const discussions = (state.blocks[uuid]?.discussions ?? [])
        .map((it) => state.discussions[it])
        .filter((it) => it != null && !it.resolved);
      return discussions.length;
    },
    [uuid]
  );

  const hasImage = Boolean(block?.data.cover) && !hiddenCover;

  const windowSize = useDebounceElementSize(hasImage ? document.body : undefined, {
    type: 'height',
  });

  if (!block) return null;

  const hasIcon = Boolean(block.data.icon?.value && block.data.icon.type);
  const isImgIcon = !(block.data.icon?.type === 'emoji' || block.data.icon?.type === 'icon');
  const containerHeight = calcPageHeaderCoverHeight(windowSize.height);

  const emojiHeight = hasIcon ? 80 : $searchParams.print ? 0 : 56;
  const coverHeight = hasIcon ? containerHeight - (isImgIcon ? 65 : 45) : containerHeight + 10;
  const imageHeight = hasImage ? coverHeight : emojiHeight;

  const buttons: ReactNode[] = [];
  if (!hasIcon && !readonly) {
    buttons.push(
      <HoverButton
        key="addIcon"
        text="添加图标"
        icon="MIcEmoji"
        className="text-grey4 font-normal"
        onClick={() => {
          const icon = getRandomEmoji(true);
          transaction(() => {
            updateBlock(uuid, {
              data: {
                icon: {
                  type: 'emoji',
                  value: icon,
                },
              },
            });
          });
        }}
      />
    );
  }

  if (!hasImage && !readonly) {
    buttons.push(
      <HoverButton
        key="addCover"
        text="添加封面"
        icon="IcImage"
        className="text-grey4 font-normal"
        onClick={async () => {
          const url = await request.editor.getCoverImage();
          transaction(() => {
            updateBlock(uuid, { data: { cover: url, coverPos: 50 } });
          });
        }}
      />
    );
  }

  if (isCollection(block.type) && (!readonly || hasDescription)) {
    // 如果本地记录了是显示说明并且确实有说明内容的时候  或者本地变量为true的时候就要展示隐藏说明
    // 由于不希望localStorage记录越来越多，如果undefined说明默认展示
    const showHideDescText =
      ((showDescription || showDescription === undefined) && hasDescription) ||
      block.data.showEmptyDescription;

    buttons.push(
      <HoverButton
        key="addDesc"
        text={showHideDescText ? '隐藏说明' : hasDescription ? '显示说明' : '添加说明'}
        icon="MIcDescription"
        className="text-grey4 font-normal"
        onClick={() => {
          if (showHideDescText) {
            batch(() => {
              setShowDescription(false);
              updateBlock(uuid, { data: { showEmptyDescription: false } }, true);
            });
          } else if (hasDescription) {
            // 默认值true，设置undefined内部会调用storage.removeItem(key);
            setShowDescription(undefined);
          } else {
            setShowDescription(undefined);
            bizTracker.event('add_description');
            updateBlock(uuid, { data: { showEmptyDescription: true } }, true);
          }
        }}
      />
    );
  }

  if (
    !judgeSharePage() &&
    Role.contains(role, PermissionRole.COMMENTER) &&
    numOpenDiscussions <= 0 &&
    // 记录页默认展示评论列表，所以不显示按钮
    !defaultShowComments &&
    !showTempDiscussion
  ) {
    buttons.push(
      <HoverButton
        key="addComment"
        text="添加评论"
        icon="IcComment"
        className="text-grey4 font-normal"
        onClick={() => {
          void setShowTempDiscussion(true);
        }}
      />
    );
  }

  return (
    <div className={cx('page-header line-default', className)}>
      <div className="group">
        {hasImage && <HeaderCover uuid={uuid} />}
        <div className="relative">
          {!hiddenCover && <div className="transition-all" style={{ height: imageHeight }} />}
          {hasIcon && (
            <IconTrigger
              blockId={uuid}
              className={'inline-block relative z-10'}
              iconSize={isImgIcon ? 130 : 78}
            />
          )}
          <span className="flex h-8 group-hover:visible">{buttons}</span>
        </div>
      </div>
      <div>
        <PageTitle key={uuid} uuid={uuid} />
        <PageDescription
          uuid={uuid}
          showDescription={showDescription || showDescription === undefined}
        />
        {children}
        {commentAlignment === 'top' && <PageOpenDiscussions pageId={uuid} />}
      </div>
    </div>
  );
}, deepEqual);
