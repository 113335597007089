import { useOpenModal } from '@flowus/common/next-modal';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { ICON_MAP } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { transaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';

export const SubitemSetting: FC = () => {
  const { collectionId } = useBitable();
  const subItem = useObservableBlock(collectionId, (b) => {
    return b?.data.format?.openSubItem;
  });
  const openModal = useOpenModal();
  const { propertySchema } = usePropertySchema(collectionId, subItem?.parentPropertyId ?? '');
  if (!propertySchema) return null;

  const openSelectrelationProperty = (e: React.MouseEvent) => {
    const schema = cache.blocks[collectionId]?.data.schema;
    if (!schema) return;

    const alreadyAdd = new Set<string>();
    const items: ListItem[] = [];
    const createItem = (schema: CollectionSchema, propertyId: string): ListItem => {
      return {
        type: ListItemType.OPERATION,
        data: {
          icon: ICON_MAP[schema.type],
          title: schema.name,
          selected: subItem?.parentPropertyId === propertyId,
          onClick: () => {
            if (propertyId === subItem?.parentPropertyId) return;
            transaction(() => {
              updateBlock(collectionId, {
                data: {
                  format: {
                    openSubItem: { parentPropertyId: propertyId },
                  },
                },
              });
            });
          },
        },
      };
    };
    Object.entries(schema)
      .filter(
        ([_, sch]) =>
          sch.type === CollectionSchemaType.RELATION && sch.collectionId === collectionId
      )
      .forEach(([key, v]) => {
        if (alreadyAdd.has(key)) return;
        items.push(createItem(v, key));
      });
    openModal.dropdown({
      placement: 'right',
      offset: [0, -40],
      popcorn: e.currentTarget,
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="py-1 next-modal w-[160px]"
            items={items}
            onItemClick={() => onCloseModal()}
          />
        );
      },
    });
  };
  return (
    <div className="next-modal px-4 py-2 w-[360px]">
      <div className="my-2 text-t2 text-grey2">
        选择表示父项的关系属性。我们将“父记录”作为默认值。
      </div>
      <div className="h-px w-full bg-grey7" />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <Icon className="mr-1" size="middle" name={ICON_MAP[propertySchema.type]} />
          <div className="text-t2 ">属性</div>
        </div>
        <div
          className="animate-hover flex items-center px-2 h-8"
          onClick={openSelectrelationProperty}
        >
          <div className="text-t2 text-grey3">{propertySchema.name}</div>
          <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
        </div>
      </div>
    </div>
  );
};
