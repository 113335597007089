import { fileTypeFromBuffer } from 'file-type';
import { MB } from '@flowus/shared/unit';

// 检测XSS的正则表达式
const xssPatterns = [
  /<script.*?>.*?<\/script>/is, // 检测<script>标签
  /javascript:/i, // 检测javascript:伪协议
  /onload=["'].*?["']/i, // 检测onload事件
  /onerror=["'].*?["']/i, // 检测onerror事件
];

// 检查文件内容是否包含XSS攻击脚本
function containsXSS(content: string) {
  return xssPatterns.some((pattern) => pattern.test(content));
}

// 检查文件是否可能包含XSS攻击
export async function checkFile(file: File): Promise<{
  error: boolean;
  msg: string;
}> {
  return new Promise((resolve) => {
    try {
      // 大于 1MB 一般就不是脚本了
      if (file.size >= 1 * MB) {
        resolve({ error: false, msg: 'done' });
        return;
      }

      const fileReader = new FileReader();

      // eslint-disable-next-line require-atomic-updates
      fileReader.onload = async () => {
        const arrayBuffer = fileReader.result;
        const type = await fileTypeFromBuffer(arrayBuffer as ArrayBuffer);
        // 如果文件是SVG或者文件内容包含SVG标识
        if (
          (type && (type.mime as string).includes('xml')) ||
          /svg|png|jpg|jpeg|webp|xml/.test(arrayBuffer?.toString() || '')
        ) {
          const fileContent = new TextDecoder().decode(arrayBuffer as ArrayBuffer);
          if (containsXSS(fileContent)) {
            resolve({
              error: true,
              msg: `Danger: File ${file.name} contains potential XSS attack scripts.`,
            });
          }

          resolve({
            error: false,
            msg: `File ${file.name} does not contain XSS attack scripts.`,
          });
        }

        resolve({
          error: false,
          msg: `File ${file.name} is not an SVG file and does not contain XSS attack scripts.`,
        });
      };
      fileReader.readAsArrayBuffer(file);
    } catch (error: any) {
      resolve({
        error: false,
        msg: `An error occurred while checking the file: ${error?.message}`,
      });
    }
  });
}
