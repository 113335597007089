import { cx } from '@flowus/common/cx';
import type { FC, ReactNode, RefObject } from 'react';
import { useCallback } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useOnceAnyDay } from 'src/components/header-tips-bar';
import type { LocalStorageKey } from 'src/const/local-storage-state-key';
import { v4 } from 'uuid';

const modalId = v4();
interface Props {
  localKey: LocalStorageKey;
  endDay: number;
}
export const useOpenButtonTips = (props: Props) => {
  const { localKey, endDay } = props;
  const openModal = useOpenModal();
  const onceOneDay = useOnceAnyDay(localKey, { endDay });

  return useCallback(
    (
      ref: RefObject<HTMLElement>,
      opt: {
        content: ReactNode;
        autoClose?: boolean;
      }
    ) => {
      if (!ref.current) return;
      if (!onceOneDay.showOnce) {
        return;
      }

      onceOneDay.close();

      openModal.dropdown({
        modalId,
        mask: false,
        autoClose: opt.autoClose,
        popcorn: ref.current,
        placement: 'bottom',
        offset: [0, 8],
        arrow: true,

        content: (_) => (
          <div className="next-modal py-1 pl-2 pr-1 text-white text-t3-medium max-w-[270px] relative z-10 bg-black1 flex items-center justify-center">
            <div>{opt.content}</div>
            <Icon
              onClick={() => {
                _.onCloseModal();
              }}
              name="IcToastClose"
              size="middle"
              className="animate-click"
            />
          </div>
        ),
      });
    },
    [onceOneDay, openModal]
  );
};

export const TooltipButton: FC<{
  title: string;
  text: string;
  icon: IconName;
  onClick: () => void;
}> = ({ title, text, onClick, icon }) => (
  <Tooltip popup={<Popup title={title} text={text} />}>
    <button
      className="flex items-center justify-center w-5 h-5 rounded outline-none animate-hover"
      onClick={onClick}
    >
      <Icon name={icon} size="middle" />
    </button>
  </Tooltip>
);

export const Popup: FC<{ title: string; text?: string; className?: string }> = ({
  title,
  text,
  className,
}) => (
  <div className={cx('p-1', className)}>
    <div className="text-t4-medium text-white">{title}</div>
    {text && <div className="text-t4-medium text-gray-300">{text}</div>}
  </div>
);

export const UNFOLD_DESC = {
  'page-feeds': '关闭页面动态',
  'page-feeds-right': '关闭页面动态',
  'page-resolved-discussions': '关闭已解决评论',
  'page-resolved-discussions-right': '关闭已解决评论',
  'service-center': '关闭帮助中心',
  'service-center-right': '关闭帮助中心',
};
