import { useMount } from 'ahooks';
import { debounce } from 'lodash-es';
import type { ReactNode, RefObject } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { cx } from '../cx';
import { PcIcon } from '../icon-svg/pc-icon';
import { LoadingIcon } from './loading-icon';

type ArrayItemType<T extends any[]> = T extends (infer U)[] ? U : never;

export interface ScrollLoadListProps<T, U> {
  container: RefObject<HTMLDivElement>;
  getApi: (params: { pageIndex: number; pageSize: number }) => Promise<{ list: T; more: boolean }>;
  renderItem: (item: U, index: number) => ReactNode;
  placeholder?: ReactNode;
  loadingIcon?: ReactNode;
  className?: string;
}

export const ScrollLoadList = <T extends any[], U extends ArrayItemType<T>>(
  props: ScrollLoadListProps<T, U>
) => {
  const { loadingIcon, className } = props;
  const {
    handleAttachBottomDebounce,
    loadList,
    loading,
    more,
    placeholder,
    container,
    renderItem,
  } = useScrollLoadList(props);

  return (
    <InfiniteScroll
      initialLoad={false}
      useWindow={false}
      loadMore={handleAttachBottomDebounce}
      getScrollParent={() => container.current}
      hasMore={more}
      className={className}
    >
      {loadList.map(renderItem)}
      {more && (
        <div className="text-center text-grey3 my-4">
          {loadingIcon ?? <LoadingIcon size="middle" />}
        </div>
      )}
      {!loadList.length && !more && !loading && (
        <div className="text-center text-grey3 my-4">
          <div className="ml-1 text-t2">{placeholder ?? '暂无内容'}</div>
        </div>
      )}
    </InfiniteScroll>
  );
};

interface ScrollLoadTableProps<U> {
  titleList: { title: string }[];
  dateList: {
    id: string;
    className?: string;
    render: (item: U, index: number) => ReactNode;
  }[];
  modalHeader?: {
    title: string;
    closeModal: () => void;
  };
}

export const ScrollLoadTable = <T extends any[], U extends ArrayItemType<T>>(
  props: ScrollLoadTableProps<U> & Omit<ScrollLoadListProps<T, U>, 'container' | 'renderItem'>
) => {
  const { getApi, className, dateList, titleList, modalHeader, placeholder } = props;
  const container = useRef<HTMLDivElement>(null);
  const gridTemplateColumns = `repeat(${titleList.length}, minmax(0, 1fr))`;

  return (
    <>
      {modalHeader && (
        <div className="py-2.5 px-5 text-t2 border-b flex items-center justify-between">
          <span className="text-t2-medium">{modalHeader.title}</span>
          <span>
            <PcIcon
              className="animate-hover"
              name={'IcToastClose'}
              size="middle"
              onClick={modalHeader.closeModal}
            />
          </span>
        </div>
      )}

      <>
        <div className="grid text-t2 gap-2.5 px-5 py-2.5 border-b" style={{ gridTemplateColumns }}>
          {titleList.map((item) => (
            <div className={'pr-5'}>{item.title}</div>
          ))}
        </div>
        <div className={cx('max-h-120 overflow-auto p-5', className)}>
          <ScrollLoadList
            placeholder={placeholder}
            container={container}
            className="space-y-5"
            getApi={getApi}
            renderItem={(item, index) => {
              return (
                <div className="grid text-t2 gap-2.5" style={{ gridTemplateColumns }}>
                  {dateList.map((data) => (
                    <div key={data.id as string} className={cx('pr-5', item.className)}>
                      {data.render(item, index)}
                    </div>
                  ))}
                </div>
              );
            }}
          />
        </div>
      </>
    </>
  );
};

export const useScrollLoadList = <T extends any[], U extends ArrayItemType<T>>(
  props: ScrollLoadListProps<T, U>
) => {
  const { placeholder, container, getApi, renderItem } = props;
  const [loadList, setLoadList] = useState<U[]>([]);
  const [loading, setLoading] = useState(true);
  const [more, setMore] = useState(true);
  const currentPageIndex = useRef(0);

  const patchApi = useCallback(
    async (pageIndex: number, pageSize = 30) => {
      const res = await getApi({ pageIndex, pageSize });
      if (pageIndex <= 1) {
        setLoadList(res.list);
      } else {
        setLoadList((pre) => [...pre, ...res.list]);
      }
      setMore(res.more);
    },
    [getApi]
  );

  const handleAttachBottom = useCallback(async () => {
    if (!more) return;
    setLoading(true);
    currentPageIndex.current += 1;
    await patchApi(currentPageIndex.current);
    setLoading(false);
  }, [more, patchApi]);

  const handleAttachBottomDebounce = useMemo(
    () => debounce(handleAttachBottom, 1000),
    [handleAttachBottom]
  );

  useMount(() => {
    void handleAttachBottom();
  });

  return {
    handleAttachBottomDebounce,
    loadList,
    loading,
    more,
    placeholder,
    container,
    renderItem,
  };
};
