import type { SpaceDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { IconPanel } from 'src/components/icon-trigger/icon-panel';
import { useCurrentSpace } from 'src/hooks/space';
import { useUpload } from 'src/hooks/space/use-upload';
import { MessageIds } from 'src/modals';

interface Props {
  icon: SpaceDTO['icon'];
  setIcon: (icon: SpaceDTO['icon']) => void;
}

export const SpaceAvatar: FC<Props> = ({ icon, setIcon }) => {
  const currentSpace = useCurrentSpace();
  const openModal = useOpenModal();
  const upload = useUpload();

  const openEmoji = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom',
        content: ({ onCloseModal }) => (
          <IconPanel
            showRecently={false}
            onSelectCustomIcon={(imagePath: string, isFromRandom?: boolean) => {
              if (!isFromRandom) {
                onCloseModal();
              }
              setIcon({
                type: 'icon',
                value: imagePath,
              });
            }}
            onSelectEmoji={(emojiStr, isFromRandom) => {
              if (!isFromRandom) {
                onCloseModal();
              }
              setIcon({
                type: 'emoji',
                value: emojiStr,
              });
            }}
            onUploadFile={(file) => {
              onCloseModal();
              message.loading({ key: MessageIds.UPLOAD_ICON, content: '头像上传中...' });
              void upload({
                file,
                type: 'public',
                searchType: 'spaceIcon',
                onComplete: (ret) => {
                  message.closeMessage(MessageIds.UPLOAD_ICON);
                  if (ret.success) {
                    message.success('上传完成');
                    setIcon({
                      type: 'upload',
                      value: ret.ossName,
                    });
                  }
                },
              });
            }}
            onRemoveBtnClick={() => {
              setIcon({});
              onCloseModal();
            }}
          />
        ),
      });
    },
    [openModal, setIcon, upload]
  );

  return (
    <>
      <div className="mt-4 flex flex-row items-center justify-between">
        <div className="mb-3.5 rounded-md border border-black_006 p-1">
          <Avatar
            name={currentSpace.title}
            color={currentSpace.backgroundColor}
            icon={icon}
            iconSize={50}
            className="!h-[50px] w-[50px]"
            style={{ fontSize: '30px' }}
          />
        </div>
        <div className="flex space-x-5">
          {icon && <Button onClick={() => setIcon({})}>移除空间头像</Button>}
          <Button onClick={openEmoji}>上传空间头像</Button>
        </div>
      </div>
    </>
  );
};
