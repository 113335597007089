import { BlockType } from '@next-space/fe-api-idl';
import React, { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { SearchMenu } from 'src/components/search';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getDefaultSearchList } from 'src/utils/block-utils';

export const useOpenSearchPageModal = () => {
  const openModal = useOpenModal();
  return useCallback(
    (e: React.MouseEvent, existPageIds: string[], onSelectPageId: (uuid: string) => void) => {
      const defaultItems = getDefaultSearchList({
        ancestorId: getCurrentSpaceId(),
        filterTypes: [BlockType.FILE],
      });
      openModal.dropdown({
        popcorn: e.target as HTMLElement,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <SearchMenu
              filterItems={existPageIds}
              defaultValue={defaultItems}
              onSelect={(target) => {
                onSelectPageId(target.uuid);
                onCloseModal();
              }}
            />
          );
        },
      });
    },
    [openModal]
  );
};
