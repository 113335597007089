import { useCallback } from 'react';
import { useOpenInlineContentMenu } from 'src/editor/component/menu-list/inline-content-menu';
import type { InlineContentType } from 'src/editor/component/menu-list/types';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

export const useInputAtSymbol = (uuid: string) => {
  const openInlineContentMenu = useOpenInlineContentMenu();
  const getEditorModel = useGetOrInitEditorModel();
  const atKey = useCallback(
    (
      event: CustomEvent,
      inlineContentType: InlineContentType,
      symbol: string,
      keyword?: string
    ) => {
      const editorModel = getEditorModel(uuid);
      if (!editorModel) return;
      const { selection } = editorModel;
      if (!selection) return;
      if (!selection.isCollapsed) return;
      // 行内第一个字符输入@时，focusOffset还没变化，为0,因此left直接为0 (symbol长度-1)
      // 输入[[时候，focusOffset最后为1，因此left需要减 1 (symbol长度-1)
      const left = selection.focusOffset - (symbol.length - 1);
      const open = openInlineContentMenu(
        left,
        selection.focusOffset,
        uuid,
        inlineContentType,
        getEditorModel,
        symbol,
        keyword
      );
      if (open) {
        event.preventDefault();
      }
    },
    [getEditorModel, openInlineContentMenu, uuid]
  );
  return atKey;
};
