import { pick } from 'lodash-es';

import type { OP, PDFAnnotation } from '../type';
import { OperationType } from '../type';
import type { PDFViewApplication } from './pdf-viewer-application';

// {op: 'add', uuid: '11'} => {op: 'delete', uuid: '11'}
// {op: 'delete', uuid: '11'} => {op: 'add', uuid: '11'}
// {op: 'update', uuid: '11', patch: {}} => {op: 'update', uuid: '11', patch: {}}

// 必须知道 old，只有 old 才能计算出反向 op，计算反向 op 是一个复杂的过程

// use action not patch
export class HistoryStack {
  delay = 1000; // 1s 以内发生的 action 识别为一个
  maxStack = 100; // 历史记录最多存放 100 个
  userOnly = false;
  lastRecorded = 0; // 上次入栈时间

  redoStack: OP[] = [];
  undoStack: OP[] = [];

  constructor(public pdfApplication: PDFViewApplication) {}

  // 存的时候计算，会有一定的计算量，但不多。如果在撤销重做的时候计算，会有一定的缓存占据空间。
  record(op: OP, currentPDFAnnotation?: PDFAnnotation) {
    const inverseOp = this.inverse(op, currentPDFAnnotation);
    if (inverseOp) {
      this.undoStack.push(inverseOp);
      this.pdfApplication?.onHistoryChange?.(this);
    }
  }

  inverse(op: OP, currentPDFAnnotation?: PDFAnnotation) {
    switch (op.type) {
      case OperationType.ADD:
        return { type: OperationType.DELETE, uuid: op.uuid };

      case OperationType.DELETE:
        return { type: OperationType.ADD, uuid: op.uuid };

      case OperationType.UPDATE: {
        if (!op.patch) return;
        const newPatch = pick(currentPDFAnnotation, Object.keys(op.patch));
        return { type: OperationType.UPDATE, uuid: op.uuid, patch: newPatch };
      }
      default:
        break;
    }
  }

  redo() {
    const op = this.redoStack.pop();
    if (!op) return;

    const inverseOp = this.inverse(op, this.pdfApplication.annotations[op?.uuid]);
    if (inverseOp) {
      this.undoStack.push(inverseOp);
      if (this.undoStack.length > this.maxStack) {
        this.undoStack.shift();
      }
    }

    this.pdfApplication?.onHistoryChange?.(this);
    this.pdfApplication.applyOP(op);
  }

  undo() {
    const op = this.undoStack.pop();
    if (!op) return;

    const inverseOp = this.inverse(op, this.pdfApplication.annotations[op?.uuid ?? '']);
    if (inverseOp) {
      this.redoStack.push(inverseOp);
      if (this.redoStack.length > this.maxStack) {
        this.redoStack.shift();
      }
    }

    this.pdfApplication?.onHistoryChange?.(this);
    this.pdfApplication.applyOP(op);
  }

  clear() {
    this.redoStack = [];
    this.undoStack = [];
  }

  // 使用 change 可以优化 redo undo: https://github.com/quilljs/quill/blob/develop/modules/history.js
}
