import type { ActivityDetailDTO } from '@next-space/fe-api-idl';
import { assign, keyBy } from 'lodash-es';
import { $activitiesState, $setActivitiesState } from './state';
import type { ActivitiesStateType, ActivityDetail } from './type';

export const getActivityState = (): Readonly<ActivitiesStateType> => {
  return $activitiesState.getState();
};

export const useActivities = () => {
  return $activitiesState((state) => state);
};

export const useActivitiesOtherList = () => {
  return $activitiesState((state) => state.otherList);
};

export const updateActivitiesState = (params: {
  activityList: ActivityDetail[];
  basicList?: ActivityDetail[];
  advancedList?: ActivityDetail[];
  otherList?: ActivityDetail[];
}) => {
  const { activityList, basicList = [], advancedList = [], otherList = [] } = params;
  if (__BUILD_IN__) return;
  $setActivitiesState((pre) => {
    return { ...pre, activityList, basicList, advancedList, otherList: keyBy(otherList, 'uuid') };
  });
};

export const updateActivityState = (params: {
  uuid: string;
  patch: Partial<ActivityDetailDTO>;
}) => {
  const { uuid, patch } = params;
  assign($activitiesState.getState().otherList[uuid], patch);
};

export const useParentTask = (parentId: string) => {
  return $activitiesState((state) => state.otherList[parentId]);
};

export const useActivityList = () => {
  return $activitiesState((state) => state.activityList);
};
