import type { ChangeEvent, FC } from 'react';

interface Props {
  title: string;
  /** 选择本地folder */
  onSelectLocalFolder?: (files: File[]) => void;
}
export const UploadLocalFolder: FC<Props> = (props) => {
  const processFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    const files: File[] = [];
    for (const file of fileList) {
      files.push(file);
    }
    // const rootFolder = parseFiles(fileList);
    props.onSelectLocalFolder?.(files);
  };

  return (
    <div className="flex justify-center items-center h-[62px]">
      <label className="w-[300px] h-8">
        <div className="flex justify-center items-center rounded text-t4-medium active:opacity-50 text-align-center h-8 px-3.5 bg-active_color text-white hover:bg-active_color/90">
          {props.title}
        </div>
        <input
          type="file"
          className="hidden"
          // @ts-ignore 非标准属性但浏览器基本都支持
          webkitdirectory=""
          mozdirectory=""
          onChange={processFile}
        />
      </label>
    </div>
  );
};
