import { cx } from '@flowus/common/cx';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { usePickBlock } from 'src/utils/pick-block';
import { Latex } from '../../component/latex';
import { segmentsToText, textToSegments } from '../../utils/editor';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';

/** 公式 */
export const EquationBlockElement: BlockElement = ({ id, root }) => {
  const block = usePickBlock(id, ['data', 'local'], ['segments']);
  const transaction = useTransaction();
  const readonly = useReadonly(id);
  useDoneLocalStatus({ uuid: id });

  if (!block) return null;
  const text = segmentsToText(block.data.segments);

  return (
    <BlockDrop id={id} horizontal={root} className={cx('my-1', { 'animate-hover': !readonly })}>
      <Latex
        readonly={readonly}
        content={text}
        onChange={(content) => {
          const segments = textToSegments(content);
          transaction(() => {
            updateBlock(id, { data: { segments } });
          });
        }}
        defaultOpenPanel={Boolean(block.local)}
      />
    </BlockDrop>
  );
};
