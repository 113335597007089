import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { buildDateSegment, buildPersonSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getDateFromGroupName } from 'src/hooks/collection-view/table-groups/date-number-groupname';
import { getState } from 'src/redux/store';

import { realGroupName } from './table-groups';

export const getValuesFromGroupValue = (params: {
  viewId: string;
  groupValue?: string;
  subGroupValue?: string;
  oldGroupValues?: Record<string, string[]>;
  blockId?: string;
  isNewRecord?: boolean;
}) => {
  const propertyValues: Record<string, SegmentDTO[]> = {};

  const { viewId, groupValue, subGroupValue, blockId, oldGroupValues, isNewRecord } = params;
  if (!groupValue && !subGroupValue) return propertyValues;

  const { collection, view, subGroupBy, groupBy } = getViewFormat(viewId) ?? {};
  if (!collection || !view) return propertyValues;

  const isSameGroupProperty = subGroupBy?.property === groupBy?.property;

  [
    {
      groupValue,
      propertyId: groupBy?.property,
      schemaType: collection.data.schema?.[groupBy?.property ?? '']?.type,
      oldGroupValue: Object.keys(oldGroupValues ?? {}),
      groupBy,
    },
    {
      groupValue: subGroupValue,
      propertyId: subGroupBy?.property,
      schemaType: collection.data.schema?.[subGroupBy?.property ?? '']?.type,
      oldGroupValue: oldGroupValues
        ? [...new Set(Object.values(oldGroupValues).flat().filter(Boolean))]
        : undefined,
      groupBy: subGroupBy,
    },
  ].forEach(({ groupValue, propertyId, schemaType, groupBy, oldGroupValue }) => {
    if (!groupValue || !propertyId || !schemaType) return;

    if (!realGroupName(groupValue)) {
      if (schemaType === CollectionSchemaType.TITLE) {
        propertyValues.title = propertyValues.title ?? [];
      } else {
        propertyValues[propertyId] = propertyValues[propertyId] ?? [];
      }
      return;
    }

    groupValue = realGroupName(groupValue);
    if (!groupValue) return;

    switch (schemaType) {
      case CollectionSchemaType.TITLE: {
        propertyValues.title = textToSegments(groupValue);
        break;
      }

      case CollectionSchemaType.NUMBER: {
        if (groupValue === 'out_of_range') {
          propertyValues[propertyId] = textToSegments(groupBy?.end ?? 1000);
        } else {
          const firstNumber = groupValue.split('->')[0];
          if (firstNumber) {
            propertyValues[propertyId] = textToSegments(firstNumber);
          }
        }

        break;
      }

      case CollectionSchemaType.MULTI_SELECT: {
        if (isNewRecord && isSameGroupProperty) {
          const str = segmentsToText(propertyValues[propertyId]);
          if (!str) {
            propertyValues[propertyId] = textToSegments(`${groupValue}`);
          } else {
            if (str !== groupValue) {
              propertyValues[propertyId] = textToSegments(`${str},${groupValue}`);
            }
          }
          break;
        }

        const { blocks } = getState();
        const block = blocks[blockId ?? ''];

        if (block) {
          const segments = block.data.collectionProperties?.[propertyId];
          const newPropertyValue = segmentsToText(segments)
            .split(',')
            .map((text) => (oldGroupValue?.includes(text) ? '' : text))
            .filter((v) => !!v);

          let str = '';
          if (newPropertyValue.includes(groupValue)) {
            str = newPropertyValue.join(',');
          } else {
            str = newPropertyValue.concat(groupValue).join(',');
          }

          propertyValues[propertyId] = textToSegments(str);
          break;
        }

        propertyValues[propertyId] = textToSegments(groupValue);
        break;
      }

      case CollectionSchemaType.TEXT:
      case CollectionSchemaType.CHECKBOX:
      case CollectionSchemaType.SELECT: {
        propertyValues[propertyId] = textToSegments(groupValue);
        break;
      }

      case CollectionSchemaType.DATE: {
        const date = getDateFromGroupName(groupValue);
        propertyValues[propertyId] = [buildDateSegment({ from: dayjs(date).toDate() })];
        break;
      }

      case CollectionSchemaType.PERSON: {
        const { blocks } = getState();
        const block = blocks[blockId ?? ''];

        if (block) {
          const segments = block.data.collectionProperties?.[propertyId] ?? [];

          let hasGroupValue = false;
          const newSegments = segments.filter((item) => {
            if (item.type === TextType.USER) {
              if (item.uuid === groupValue) {
                hasGroupValue = true;
                return true;
              }
              return !oldGroupValue?.includes(item.uuid ?? '');
            }

            return false;
          });

          if (!hasGroupValue) {
            newSegments.push(buildPersonSegment(groupValue));
          }

          propertyValues[propertyId] = newSegments;
          break;
        }

        propertyValues[propertyId] = [buildPersonSegment(groupValue)];
        break;
      }

      case CollectionSchemaType.URL:
      case CollectionSchemaType.EMAIL:
      case CollectionSchemaType.PHONE: {
        propertyValues[propertyId] = [
          { type: TextType.URL, url: groupValue, text: groupValue, enhancer: {} },
        ];
        break;
      }

      case CollectionSchemaType.RELATION: {
        propertyValues[propertyId] = [
          { type: TextType.LINK_PAGE, uuid: groupValue, text: '', enhancer: { code: false } },
        ];
        break;
      }

      default:
        break;
    }
  });

  return propertyValues;
};
