import { assign } from 'lodash-es';
import type { MutableRefObject } from 'react';
import { useEffect } from 'react';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { setAppUiState, useIsCtrlKeyDown } from 'src/services/app';

export const useCtrlDragScroll = (
  ref: MutableRefObject<HTMLElement | null>,
  opt?: {
    enable?: boolean;
  }
) => {
  const { enable = true } = opt || {};
  const isCtrlKeyDown = useIsCtrlKeyDown();

  useEffect(() => {
    const $ref = ref.current;
    if (!$ref || !enable || !isCtrlKeyDown) return;
    let isDragging = false;
    let startX = 0;
    let startY = 0;
    let scrollLeft = 0;
    let scrollTop = 0;
    const temporary = !$ref.className.includes('select-none');

    const mask = document.createElement('div');
    mask.className = 'absolute top-0 left-0 z-20 cursor-grab';
    mask.id = 'drag-scroll-mask';

    assign(mask.style, { width: `${$ref.scrollWidth}px`, height: `${$ref.scrollHeight}px` });

    $ref.appendChild(mask);

    const dragEnd = () => {
      globalListenerHelper.removeEventListener('mouseup', handleMouseUp);
      $ref.removeEventListener('mousemove', handleMouseMove);
      if (temporary) {
        $ref.classList.remove('select-none');
      }
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.stopPropagation();

      // 二次判断是否按住了ctrl
      if (!(e.metaKey || e.ctrlKey || e.altKey)) {
        dragEnd();
        setAppUiState({ $isCtrlKeyDown: false });
        return;
      }

      isDragging = true;
      startX = e.pageX - $ref.offsetLeft;
      startY = e.pageY - $ref.offsetTop;
      scrollLeft = $ref.scrollLeft;
      scrollTop = $ref.scrollTop;
      $ref.addEventListener('mousemove', handleMouseMove);
      globalListenerHelper.addEventListener('mouseup', handleMouseUp);
      mask.classList.replace('cursor-grab', 'cursor-grabbing');
      if (temporary) {
        $ref.classList.add('select-none');
      }
    };

    const handleMouseUp = () => {
      mask.classList.replace('cursor-grabbing', 'cursor-grab');
      if (!isCtrlKeyDown) {
        mask.remove();
      }
      dragEnd();
      isDragging = false;
      startX = 0;
      startY = 0;
      scrollLeft = 0;
      scrollTop = 0;
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      const x = e.pageX - $ref.offsetLeft;
      const y = e.pageY - $ref.offsetTop;
      const walkX = x - startX;
      const walkY = y - startY;
      assign($ref, {
        scrollLeft: scrollLeft - walkX,
        scrollTop: scrollTop - walkY,
      });
    };

    $ref.addEventListener('mousedown', handleMouseDown);

    return () => {
      dragEnd();
      $ref.removeEventListener('mousedown', handleMouseDown);
      mask.remove();
    };
  }, [enable, isCtrlKeyDown, ref]);
};
