import { BlockType } from '@next-space/fe-api-idl';
import { flatMap } from 'lodash-es';
import { useCallback } from 'react';
import { getState } from 'src/redux/store';
import { setExpandPageRecord } from 'src/services/app';
import { TocType } from 'src/views/main/aside/toc/types';
import { getAncestors } from './use-get-ancestors';

const isBread = (type: BlockType) => {
  return [
    BlockType.FOLDER,
    BlockType.PAGE,
    BlockType.COLLECTION_VIEW_PAGE,
    BlockType.COLLECTION_VIEW,
    BlockType.REFERENCE_COLLECTION,
    BlockType.REFERENCE_COLLECTION_PAGE,
    BlockType.MIND_MAPPING,
    BlockType.MIND_MAPPING_PAGE,
  ].includes(type);
};

export const useGetBreadcrumb = () => {
  return useCallback((uuid: string, includeItself?: boolean, ancestorId?: string) => {
    const { blocks } = getState();
    const blockIdSet = getAncestors(uuid, blocks, ancestorId);
    const navs = [];

    const block = blocks[uuid];

    if (block) {
      for (const id of blockIdSet) {
        if (!includeItself && id === uuid) {
          continue;
        }
        const _block = blocks[id];
        if (!_block) break;
        if (isBread(_block.type)) {
          navs.unshift(_block);
        }
      }
    }

    return navs;
  }, []);
};
/**
 * 展开pageId对应的toc(目录树)
 * 只有首次进入页面才需要展开，其它时候切换页面不需要再调用这个展开方法.
 * 否则会有bug: https://www.notion.so/6affaec14afe4c9da2799e0b20d91e1b?v=8d77fd0316fb4e6e87be1fa8991cedcf&p=9a6729acca2f4dcbb063f40396d55203
 */
export const useExpandToc = () => {
  const getBreadcrumb = useGetBreadcrumb();
  return useCallback(
    (pageId: string, opt?: { includeItself?: boolean }) => {
      const breadcrumb = getBreadcrumb(pageId);
      const ids = flatMap(
        breadcrumb
          .map(({ uuid }) => uuid)
          .map((uuid) => [
            `${TocType.SPACE}_${uuid}`,
            `${TocType.PRIVATE}_${uuid}`,
            `${TocType.FAVORITE}_${uuid}`,
            `${TocType.SHARE}_${uuid}`,
          ])
      );
      if (opt?.includeItself) {
        ids.push(
          ...[
            `${TocType.SPACE}_${pageId}`,
            `${TocType.PRIVATE}_${pageId}`,
            `${TocType.FAVORITE}_${pageId}`,
            `${TocType.SHARE}_${pageId}`,
          ]
        );
      }

      setExpandPageRecord(ids, true);
    },
    [getBreadcrumb]
  );
};
