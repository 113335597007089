import { cx } from '@flowus/common/cx';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { toNonExponential } from 'src/utils/number';
import { TextArea } from './text-area';
import { genericTextEditor } from './text-editor';
import { Site } from './types';

const formatNumber = (text: string) => {
  let num = parseFloat(text);
  if (Number.isNaN(num)) {
    num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
  }
  return Number.isNaN(num) ? '' : `${toNonExponential(num)}`;
};

export const NumEditor = genericTextEditor<string>({
  useValue: ({ recordId, propertyId }) => {
    const value = useObservableStore(
      ({ blocks }) => {
        const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
        return formatNumber(segmentsToText(segments));
      },
      [recordId, propertyId]
    );
    return value;
  },
  useSaveValue: ({ recordId, propertyId }) => {
    const updatePropertyValue = useUpdatePropertyValue();
    return (value) => {
      updatePropertyValue(recordId, propertyId, textToSegments(formatNumber(value)));
    };
  },
  ValueEditor: ({ site, value, onChange }) => {
    return (
      <TextArea
        autoFocus
        singleLine
        value={value}
        className={cx({ 'text-right': site !== Site.FIELD })}
        fontClassName={'whitespace-nowrap'}
        onChange={(event) => {
          onChange(event.target.value);
        }}
      />
    );
  },
});
