/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import { memo, useEffect, useRef } from 'react';
import { useStore } from 'react-redux';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { uiActions } from 'src/redux/reducers/ui';
import { getAutoScrollY } from 'src/utils/auto-scroll';
import { handleLastMouseEventInCells } from 'src/utils/cell-utils';
import { useScrollRef } from 'src/views/main/page-doc/context';

const DragCycle0 = (props: { className?: string }) => {
  const elmRef = useRef<HTMLDivElement>(null);
  const store = useStore();
  const updatePropertyValue = useUpdatePropertyValue();
  const scrollContainerRef = useScrollRef();
  useEffect(() => {
    const elm = elmRef.current;
    if (elm == null) return;
    const { autoScrollY, rafY } = getAutoScrollY();
    const down = (event: PointerEvent) => {
      if (event.button !== 0) return;
      event.preventDefault();
      event.stopPropagation();

      const { pointerId } = event;
      const move = (event: PointerEvent) => {
        if (event.pointerId !== pointerId) return;
        event.preventDefault();
        event.stopPropagation();
        handleLastMouseEventInCells(event, { onlyYAxis: true, pointerCaptured: true });
        autoScrollY({ y: event.clientY, container: scrollContainerRef!.current! });
      };
      const up = (event: PointerEvent) => {
        if (event.pointerId !== pointerId) return;
        cancelAnimationFrame(rafY.id);
        event.preventDefault();
        event.stopPropagation();
        document.removeEventListener('pointermove', move);
        document.removeEventListener('pointerup', up);
        const { selectedCells } = store.getState().ui;
        if (selectedCells.length <= 1) return;
        const focusCell = selectedCells[0]!;
        const uuids = selectedCells
          .slice(1)
          .map((it) => it.recordId)
          .filter((it) => {
            const { role } = getPermissions(it);
            return Role.contains(role, PermissionRole.WRITER);
          });
        const { propertyId } = focusCell;
        const block = store.getState().blocks[focusCell.recordId];
        if (block == null) return;
        const value =
          propertyId === 'title'
            ? block.data.segments
            : block.data.collectionProperties?.[propertyId];
        updatePropertyValue(uuids, propertyId, value);
        if (uuids.length !== selectedCells.length) {
          store.dispatch(
            uiActions.update({
              selectedCells: selectedCells.filter(
                (it, index) => index === 0 || uuids.includes(it.recordId)
              ),
            })
          );
        }
      };
      document.addEventListener('pointermove', move);
      document.addEventListener('pointerup', up);
    };
    elm.addEventListener('pointerdown', down);
    return () => {
      cancelAnimationFrame(rafY.id);
      elm.removeEventListener('pointerdown', down);
    };
  }, [scrollContainerRef, store, updatePropertyValue]);
  return (
    <div
      ref={elmRef}
      className={cx('w-2 h-2 border border-blue_cobalt bg-blue cursor-ns-resize', props.className)}
      onClick={(event) => {
        event.stopPropagation();
      }}
    ></div>
  );
};

export const DragCycle = memo(DragCycle0);
