import type { BlockDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';

import type { BiRecordResolver, BiSchemaResolver, BiUserResolver } from './utils/resolvers';
import type { BiValueType } from './values/_abstract';
import { checkboxType } from './values/checkbox';
import { dateType } from './values/date';
import { filesType } from './values/files';
import { BiMultiSelectType } from './values/multi-select';
import { BiNumberType } from './values/number';
import { BiRelationType } from './values/relation';
import { BiSelectType } from './values/select';
import { textType } from './values/text';
import { undefType } from './values/undef';
import { BiUserType } from './values/user';
import { BiUsersType } from './values/users';

export interface BiPropKit<T = unknown> {
  valueType: BiValueType<T>;
  readValue(record: BlockDTO): T;
}

export interface BiResolver extends BiSchemaResolver, BiUserResolver, BiRecordResolver {}

export function getPropValueType(
  collId: string,
  propId: string,
  resolver: BiResolver
): BiValueType {
  const schema = resolver.findSchema(collId, propId);
  if (schema === undefined) return undefType;

  switch (schema.type) {
    case CollectionSchemaType.TITLE:
    case CollectionSchemaType.TEXT:
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.URL:
    case CollectionSchemaType.PHONE:
      return textType;
    case CollectionSchemaType.NUMBER:
      return new BiNumberType(schema.numberFormat);
    case CollectionSchemaType.SELECT:
      return new BiSelectType(schema.options ?? []);
    case CollectionSchemaType.MULTI_SELECT:
      return new BiMultiSelectType(schema.options ?? []);
    case CollectionSchemaType.CHECKBOX:
      return checkboxType;
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
    case CollectionSchemaType.DATE:
      return dateType;
    case CollectionSchemaType.UPDATED_BY:
    case CollectionSchemaType.CREATED_BY:
      return new BiUserType(resolver);
    case CollectionSchemaType.PERSON:
      return new BiUsersType(resolver);
    case CollectionSchemaType.FILE:
      return filesType;
    case CollectionSchemaType.RELATION:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return new BiRelationType(schema.collectionId!, resolver);
    case CollectionSchemaType.FORMULA:
    case CollectionSchemaType.ROLLUP:
      // TODO
      return undefType;
    default:
      return undefType;
  }
}

export interface BiValueTypeAndReader<T> {
  valueType: BiValueType<T>;
  valueReader: (recordId: string) => T;
}

export function readPropValue<T>(
  collId: string,
  recordId: string,
  propId: string,
  valueType: BiValueType<T>,
  resolver: BiResolver
): T | undefined {
  const schema = resolver.findSchema(collId, propId);
  if (schema == null) return undefined;

  const record = resolver.findRecord(collId, recordId);
  if (record === undefined) return undefined;

  switch (schema.type) {
    case CollectionSchemaType.TITLE:
      return valueType.fromSegments(record.data.segments);
    case CollectionSchemaType.CREATED_AT:
      return { timestamp: record.createdAt, noTimePart: false } as unknown as T;
    case CollectionSchemaType.UPDATED_AT:
      return { timestamp: record.updatedAt, noTimePart: false } as unknown as T;
    case CollectionSchemaType.CREATED_BY:
      return record.createdBy as unknown as T;
    case CollectionSchemaType.UPDATED_BY:
      return record.updatedBy as unknown as T;
    case CollectionSchemaType.FORMULA:
    case CollectionSchemaType.ROLLUP:
      // TODO
      return undefined;
    default:
      return valueType.fromSegments(record.data.collectionProperties?.[propId]);
  }
}

export type CollProp = [collId: string, propId: string];

export function getPropDeps(
  collId: string,
  propId: string,
  resolver: BiSchemaResolver
): CollProp[] {
  const schema = resolver.findSchema(collId, propId);
  if (schema?.type === CollectionSchemaType.FORMULA) {
    return Object.keys(schema.formula?.refProps ?? {})
      .filter((propId) => {
        return resolver.findSchema(collId, propId) != null;
      })
      .map((propId) => [collId, propId]);
  }
  if (schema?.type === CollectionSchemaType.ROLLUP) {
    const result: CollProp[] = [];
    if (schema.relationProperty != null) {
      result.push([collId, schema.relationProperty]);
      const relationPropSchema = resolver.findSchema(collId, schema.relationProperty);
      if (relationPropSchema?.collectionId != null) {
        const relCollId = relationPropSchema.collectionId;
        if (schema.targetProperty != null) {
          const targetPropSchema = resolver.findSchema(relCollId, schema.targetProperty);
          if (targetPropSchema?.type === schema.targetPropertyType) {
            result.push([relCollId, schema.targetProperty]);
          }
        }
      }
    }
    return result;
  }
  return [];
}

export function checkPropRecur(
  collId: string,
  propId: string,
  collId2: string,
  propId2: string,
  resolver: BiSchemaResolver
): boolean {
  const visited = new Set<string>();
  const check = (collId2: string, propId2: string): boolean => {
    if (visited.has(`${collId2}/${propId2}`)) return false;
    if (collId2 === collId && propId2 === propId) return true;
    visited.add(`${collId2}/${propId2}`);
    for (const dep of getPropDeps(collId2, propId2, resolver)) {
      if (check(dep[0], dep[1])) return true;
    }
    return false;
  };
  return check(collId2, propId2);
}
