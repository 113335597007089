import { message } from 'src/common/components/message';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { Modals } from 'src/modals';
import { emitter } from '@flowus/common/utils/emitter';
import { getLimitConfig } from '../app/hook/subscription-data';

export const checkIsMaximumCapacityDialog = (spaceId = getCurrentSpaceId()) => {
  const isCurrentSpace = spaceId === getCurrentSpaceId();
  const capacity = getLimitConfig(spaceId);

  if (capacity.blocksIsMax) {
    if (isCurrentSpace) {
      emitter.emit('modalId', { modalId: Modals.MAXIMUM_CAPACITY_DIALOG, spaceId });
    } else {
      message.error('目标空间容量已达到上限');
    }
    throw new Error('blocks 数量达到最高限制');
  }
};
