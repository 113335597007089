import { BlockStatus } from '@next-space/fe-api-idl';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

/** 根据 父/自己 获取 block 删除状态 */
export const useBlockStatus = (uuid: string) => {
  return useObservableStore(
    (state) => {
      let block = state.blocks[uuid];
      const ids = new Set();
      while (block) {
        if (ids.has(block.uuid)) break;
        ids.add(block.uuid);
        if (block.status === BlockStatus.DELETED) {
          return true;
        }
        block = state.blocks[block.parentId];
      }
      return false;
    },
    [uuid]
  );
};

export const isDeleted = (uuid: string, blocks = cache.blocks) => {
  let node = blocks[uuid];
  while (node && node.parentId !== node.spaceId) {
    if (node.status === BlockStatus.DELETED) {
      return true;
    }
    node = blocks[node.parentId];
  }
};
