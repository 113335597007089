import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState } from 'react';

import type { IconName } from './icon';
import { Input } from './input';
import { ListItemType, ListView } from './list-view';
import type { SelectViewProps } from './select-view';
import { SelectView } from './select-view';

export interface Option<T extends string | number> {
  value: T;
  title: string;
  shortTitle?: string;
  icon?: IconName;
}

interface SelectProps<T extends string | number> {
  className?: string;
  dropdownClassName?: string;
  value: T | undefined;
  options: Option<T>[];
  onChange?: (value: T) => void;
  readonly?: boolean;
  placeholder?: string;
  enableSearch?: boolean;
  SelectView?: FC<
    SelectViewProps<
      T,
      {
        className?: string;
        defaultValue?: T;
        options: SelectProps<T>['options'];
        enableSearch?: boolean;
      }
    >
  >;
}

export function Select<T extends string | number>({
  className,
  dropdownClassName,
  value,
  placeholder,
  options,
  onChange,
  readonly,
  enableSearch,
  SelectView: SelectViewComponent = SelectView,
}: SelectProps<T>) {
  const option = options.find((it) => it.value === value);
  return (
    <SelectViewComponent
      className={className}
      value={value}
      icon={option?.icon}
      title={option?.shortTitle ?? option?.title}
      placeholder={placeholder}
      onChange={onChange}
      readonly={readonly}
      Dropdown={Dropdown}
      dropdownProps={{
        className: dropdownClassName,
        defaultValue: value,
        options,
        enableSearch,
      }}
    />
  );
}

interface DropdownProps<T extends string | number> {
  defaultValue?: T;
  className?: string;
  options: SelectProps<T>['options'];
  enableSearch?: boolean;
  onCloseModal: () => void;
  onChange?: (value: T) => void;
}

export function Dropdown<T extends string | number>({
  className,
  defaultValue,
  options,
  enableSearch,
  onChange,
  onCloseModal,
}: DropdownProps<T>) {
  const [search0, setSearch] = useState('');
  const search = search0.trim().toLowerCase();
  const items = options
    .filter((option) => {
      if (!search) return true;
      return option.title.toLowerCase().includes(search);
    })
    .map((option) => {
      return {
        type: ListItemType.OPERATION,
        data: option,
      };
    });
  return (
    <div className={cx('next-modal py-2', className)}>
      {enableSearch && (
        <Input
          delayFocus={50}
          autoFocus
          className="mx-2.5 mt-[2px] mb-[6px] h-8"
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
        />
      )}
      <ListView
        className="max-h-[50vh] overflow-y-auto"
        items={items}
        defaultActiveIndex={items.findIndex((it) => it.data.value === defaultValue)}
        onItemClick={(item) => {
          onChange?.(item.data.value);
          onCloseModal();
        }}
      />
    </div>
  );
}
