import { ListView } from 'src/common/components/list-view';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { ListItemType } from 'src/common/components/list-view/types';
import type { FC } from 'react';
import { useBitable } from '../context';
import { useBitablePageSize } from 'src/hooks/collection-view/use-collection-view';
import { MAX_BITABLE_PAGE_SIZE } from '../const';

export const PageSizeLimit: FC<{ closeSelf: () => void }> = ({ closeSelf }) => {
  const { viewId } = useBitable();
  const pageSize = useBitablePageSize(viewId);

  const items = [10, 20, 30, 50, 100, MAX_BITABLE_PAGE_SIZE].map((item) => {
    return {
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data: {
        title: item === MAX_BITABLE_PAGE_SIZE ? '不限' : `${item}条`,
        selected: pageSize === item,
        pageSize: item,
      },
    };
  });

  return (
    <div className="w-[220px] py-2 next-modal">
      <ListView
        className="overflow-auto max-h-120"
        items={items}
        onItemClick={(item) => {
          updateViewFormat(viewId, {
            loadLimit: item.data.pageSize,
          });
          closeSelf();
        }}
      />
    </div>
  );
};
