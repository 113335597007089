import type { FC } from 'react';
import { VisibleRender } from 'src/common/components/visible-render';
import { publicLink } from 'src/common/const';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { EmptyLink } from './components/empty-link';
import { IconLink } from './components/icon-link';
import { Tip } from './components/tip';
import type { LinkPages } from './types';

interface Props {
  linkPages: LinkPages;
}

export const MentionPage: FC<Props> = ({ linkPages }) => {
  return (
    <div>
      {!__BUILD_IN__ && (
        <Tip
          type="LINK_TABS_MENTION"
          link={publicLink.quotePage}
          describe="💡 当前页面中引用了其他页面，将在此展示记录"
          tip="如何引用页面"
        />
      )}

      {linkPages.length ? (
        <VisibleRender rootMargin="100px 0px">
          {linkPages.map((id, i) => (
            <MentionLink key={id} uuid={id} index={i} />
          ))}
        </VisibleRender>
      ) : (
        <EmptyLink>当前页面暂未引用其他页面</EmptyLink>
      )}
    </div>
  );
};

interface SubProps {
  uuid: string;
  index: number;
}

const MentionLink: FC<SubProps> = ({ uuid, index }) => {
  const openPage = useOpenPage();

  return (
    <div
      onClick={(event) => {
        openPage(uuid, {
          forceOpenInRight: event.altKey,
          forceOpenNewTab: event.ctrlKey || event.metaKey,
        });
      }}
      className="group flex items-center animate-hover py-1 pl-2"
    >
      <div className="flex-shrink-0 text-t2 text-right">{index + 1}.</div>
      <IconLink uuid={uuid} />
    </div>
  );
};
