import type { PDFAnnotation } from '../type';
import { AnnotationType } from '../type';

export const sortAnnotations = (annotations: PDFAnnotation[]) => {
  return annotations.sort((a, b) => {
    const A = findFirstPoint(a);
    const B = findFirstPoint(b);
    if (!A || !B?.point) return 1;
    if (!B || !A?.point) return -1;

    if (A.pageNumber !== B.pageNumber) {
      return A.pageNumber - B.pageNumber;
    }

    return B.point.y - A.point.y;
  });
};

export const findFirstPoint = (annotation: PDFAnnotation) => {
  const { type, pageNumber, pdfRects } = annotation;
  let { path } = annotation;

  if (
    type === AnnotationType.RECTANGLE ||
    type === AnnotationType.HIGHLIGHT ||
    type === AnnotationType.UNDERLINE ||
    type === AnnotationType.STRIKETHROUGH
  ) {
    if (!pdfRects) return;

    const pageNumbers = Object.keys(pdfRects);
    pageNumbers.sort((a, b) => Number(a) - Number(b));

    const firstPageNumber = pageNumbers[0];
    if (!firstPageNumber) return;

    const sortedPdfRects = [...(pdfRects[firstPageNumber] ?? [])]?.sort(
      (A, B) => Math.max(B.y, B.yMax) - Math.max(A.y, A.yMax)
    );

    const firstRect = sortedPdfRects?.[0];
    if (!firstRect) return;

    return { pageNumber: Number(firstPageNumber), point: { x: firstRect.x, y: firstRect.y } };
  }

  if (type === AnnotationType.ELLIPSE) {
    const rect = pdfRects?.[pageNumber]?.[0];
    if (!rect) return;
    path = [
      { x: rect.x, y: rect.y },
      { x: rect.xMax, y: rect.yMax },
    ];
  }

  if (!path) return;

  const newPath = [...path].sort((a, b) => {
    if (a.y !== b.y) return b.y - a.y; // pdf 的坐标 Y 轴是向上的
    return a.x - b.x;
  });

  return { pageNumber, point: newPath[0] };
};
