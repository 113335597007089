import { debounce } from 'lodash-es';
import type { ReactElement, ReactNode } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { eatIndex } from '../eat-index';
import { isWindow } from '../is-window';
import { useList } from './context';

export const Content = forwardRef<HTMLDivElement, { children?: ReactNode }>(({ children }, ref) => {
  const { totalSize, direction } = useList();

  return (
    <div
      ref={ref}
      style={direction === 'horizontal' ? { width: totalSize } : { height: totalSize }}
      className="relative block-list"
    >
      {children}
      {useItems()}
    </div>
  );
});

const useItems = () => {
  const { getItemOffset, getItemSize, getItemKey, renderItem, direction } = useList();
  const [start, end] = useRange();
  const items: ReactElement[] = [];

  for (let i = start; i < end; i++) {
    const offset = getItemOffset(i);
    const size = getItemSize(i);

    items.push(
      <div
        className={
          direction === 'horizontal' ? 'h-full print:min-h-fit' : 'min-w-full print:min-w-fit'
        }
        key={getItemKey(i)}
        style={
          direction === 'horizontal'
            ? {
                position: 'absolute',
                top: 0,
                left: offset,
                width: size,
              }
            : {
                position: 'absolute',
                top: offset,
                left: 0,
                height: size,
              }
        }
      >
        {renderItem(i)}
      </div>
    );
  }

  return items;
};

const useRange = (): [number, number] => {
  const { contentRef, scrollRef, count, bufferSize = 5, getItemSize, direction } = useList();

  const [state, setState] = useState<[number, number]>([0, bufferSize]);

  useEffect(() => {
    const $content = contentRef.current;
    const $scroll = scrollRef?.current ?? window;

    if (!$content) return; // guard

    let offset = 0;
    let viewportSize: number;
    let getScrollOffset: () => number;

    const prepare = debounce(
      () => {
        const leftOrTop = direction === 'horizontal' ? 'left' : 'top';
        if (isWindow($scroll)) {
          // 相对于 html 滚动
          offset = $content.getBoundingClientRect()[leftOrTop];
          viewportSize = direction === 'horizontal' ? window.innerWidth : window.innerHeight;
          getScrollOffset = () => window[direction === 'horizontal' ? 'scrollX' : 'scrollY'];
        } else {
          // 相对于 $scroll 元素滚动
          offset =
            $content.getBoundingClientRect()[leftOrTop] -
            $scroll.getBoundingClientRect()[leftOrTop] +
            $scroll[direction === 'horizontal' ? 'scrollLeft' : 'scrollTop'];
          viewportSize =
            $scroll.getBoundingClientRect()[direction === 'horizontal' ? 'width' : 'height'];
          getScrollOffset = () => $scroll[direction === 'horizontal' ? 'scrollLeft' : 'scrollTop'];
        }
      },
      300,
      { leading: true, trailing: false }
    );

    const handleScroll = () => {
      prepare();

      /** 滚动出视口的距离 */
      const overflowSize = getScrollOffset() - offset;

      let start = eatIndex(overflowSize, getItemSize);
      let end = eatIndex(viewportSize, getItemSize, start);

      // 加上缓冲空间
      start = Math.max(start - 1 - bufferSize, 0);
      end = Math.min(end + bufferSize, count);
      setState([start, end]);
    };

    $scroll.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    handleScroll();

    // teardown
    return () => {
      $scroll.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [scrollRef, contentRef, count, getItemSize, bufferSize, direction]);

  const [start, end] = state;

  return [start, Math.min(end, count)];
};
