import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import React, { useMemo } from 'react';
import { IS_APPLE } from '../../utils/environment';
import { Icon } from '../icon';
import { Emoji, getEmojiDataFromNative } from './emoji-mart';
import { getAllEmojiData } from './emoji-mart/utils';

/**
 * 一般情况下请使用IconTrigger组件，这个组件是给一些特殊case用的,比如外链的组件
 */
export const IconWidget: FC<
  JSX.IntrinsicElements['div'] & {
    iconSize?: number;
    emojiStr?: string;
    defaultIcon?: React.ReactNode;
    disableHover?: boolean;
    hasOverlay?: boolean; // 引用页面的箭头
    inline?: boolean; // 专门给文档inline元素用的
    style?: CSSProperties;
  }
> = (props) => {
  const {
    iconSize,
    emojiStr,
    defaultIcon,
    disableHover: _disableHover,
    className,
    hasOverlay,
    inline,
    style,
    ...rest
  } = props;

  const emojiData = useMemo(() => {
    if (emojiStr) {
      return getEmojiDataFromNative(emojiStr, IS_APPLE ? 'apple' : 'google', getAllEmojiData());
    }
    return null;
  }, [emojiStr]);

  const renderIcon = (emojiStr?: string, defaultIcon?: React.ReactNode) => {
    if (emojiStr) {
      return (
        <span className="emoji" style={{ fontSize: '1em', lineHeight: '1em' }}>
          {emojiStr && emojiStr.length > 0 ? emojiStr : defaultIcon}
        </span>
      );
    }
    return defaultIcon;
  };
  if (inline) {
    return emojiData ? (
      <Emoji
        className={className}
        attribute={rest}
        ignoreEmojiSet={true}
        useSvg={true}
        emoji={emojiData}
        size={'1em'}
        skin={emojiData.skin ?? 1}
        native={IS_APPLE}
        set={IS_APPLE ? 'apple' : 'google'}
        style={style}
      />
    ) : (
      <span className="emoji inline" {...rest} style={style}>
        {emojiStr}
      </span>
    );
  }

  const fontSize = Number.isNaN(iconSize) ? undefined : iconSize;
  return (
    <span
      {...rest}
      className={cx(
        'emoji-container relative whitespace-nowrap',
        props.disableHover ? 'pointer-events-none' : 'cursor-pointer animate-hover',
        className
      )}
      style={{ fontSize, minWidth: fontSize }}
    >
      {emojiData ? (
        <Emoji
          ignoreEmojiSet={true}
          useSvg={true}
          emoji={emojiData}
          size={'1em'}
          skin={emojiData.skin ?? 1}
          native={IS_APPLE}
          set={IS_APPLE ? 'apple' : 'google'}
        />
      ) : (
        renderIcon(emojiStr, defaultIcon)
      )}
      {hasOverlay && (
        <Icon
          size="normal"
          className="absolute right-[-1px] bottom-[0]"
          name="MIcBadgePagelink"
          style={{ fontSize: '0.65em', color: 'black' }}
        />
      )}
    </span>
  );
};
