import { DEFAULT_PAGE_WIDTH } from 'src/common/const';

/** 计算最大等比缩放 */
export const calcImageMaxWidthZoomSize = ({
  width = 0,
  height = 0,
  maxWidth = DEFAULT_PAGE_WIDTH,
  maxHeight,
}: {
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
}) => {
  if (width > maxWidth) {
    height = (height * maxWidth) / width;
    width = maxWidth;
  }

  if (maxHeight) {
    if (height > maxHeight) {
      height = (height * maxHeight) / width;
    }
  }
  return { width, height };
};

export const imageElementToBlob = (imageElement: HTMLImageElement) => {
  return new Promise<Blob | undefined>((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      const { width, height } = imageElement;
      canvas.width = width;
      canvas.height = height;
      context.drawImage(imageElement, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) resolve(blob);
          else resolve(undefined);
        },
        'image/png',
        1
      );
    }
  });
};
