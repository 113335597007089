import type { TocType } from 'src/views/main/aside/toc/types';

export enum Aside {
  SPACE = 'aside-space',
  FAVORITE = 'aside-favorite',
  SHARE = 'aside-share',
  PRIVATE = 'aside-private',
  BOTTOM = 'aside-bottom',
}

export enum MainPanel {
  MIDDLE = 'main-panel-middle',
  LEFT = 'main-panel-left',
  RIGHT = 'main-panel-right',
  BOTTOM = 'main-panel-bottom',
}

export enum RightPanel {
  MIDDLE = 'right-panel-middle',
  LEFT = 'right-panel-left',
  RIGHT = 'right-panel-right',
  BOTTOM = 'right-panel-bottom',
}

export enum DRIVE {
  ALL = 'drive-all',
}

export type CursorArea = Aside | MainPanel | RightPanel | DRIVE | undefined;

export type Position = 'top' | 'bottom' | 'left' | 'right' | 'middle';

export interface TocInfo {
  dragToc?: string;
  dragTocType?: TocType;
  dragTocDeep?: number;
  dropTocType?: TocType;
  dropTocDeep?: number;
}

export interface DropInfo {
  dropId?: string;
  position?: Position;
}
