import { BlockType } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import type { NextBlock } from 'src/redux/types';

export const dataNeedForCopy = (block: NextBlock) => {
  return omit(block, [
    'uuid',
    'updatedAt',
    'deletedAt',
    'createdBy',
    'createdAt',
    'subNodes',
    'parentId',
    'spaceId',
    'version',
    'permissions',
    'views',
  ]);
};

/** 菜单复制同步块 */
export const copySyncBlock = (block: NextBlock, syncBlockId: string) => {
  if (block.type === BlockType.SYNC_CONTAINER) {
    block.type = BlockType.SYNC_REFERENCE;
    block.data.ref = {
      uuid: syncBlockId,
    };
    block.subNodes = [];
  }
};
