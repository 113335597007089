import { sleep } from '@flowus/common/async';
import Cookies from 'js-cookie';
import localforage from 'localforage';
import { useCallback } from 'react';
import { clearCurrentUser } from 'src/services/user/current-user';

/** 本地有很多持久化store数据，在这清理 */
export const clearLocalForage = async () => {
  await localforage.clear();
  localStorage.clear();
};

/**
 *
 * @returns 清理用户信息
 */
export const useClearUser = () => {
  return useCallback(async () => {
    sessionStorage.clear();
    await clearLocalForage();
    await sleep(500);
    Cookies.remove('next_auth');
    document.cookie = '';
    clearCurrentUser();
  }, []);
};
