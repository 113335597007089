import type { CommentDTO } from '@next-space/fe-api-idl';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { CREATE_COMMENT, UPDATE_COMMENT } from '../actions/comments';

type State = Record<string, CommentDTO>;

const initialState: State = {};

export const commentsSlice = createSlice({
  initialState,
  name: 'comments',
  reducers: {
    update(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
    clear() {
      return {};
    },
  },
  extraReducers(builder) {
    builder.addCase(CREATE_COMMENT, (state, { payload }) => {
      state[payload.comment.uuid] = payload.comment;
    });
    builder.addCase(UPDATE_COMMENT, (state, { payload }) => {
      const view = state[payload.uuid];
      if (view) {
        Object.assign(view, payload.patch);
      }
    });
  },
});

export const commentsReducer = commentsSlice.reducer;
export const commentsActions = commentsSlice.actions;
