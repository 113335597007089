import { cx } from '@flowus/common/cx';
import type { ActivityDetailDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { Icon } from 'src/common/components/icon';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { useUpdateActivity } from 'src/hooks/activities/use-update-activity';
import { useActivityList } from 'src/services/activities/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { createAnchorDomClick } from 'src/utils/dom';

/** 线上活动 */
export const ActivityList: FC = () => {
  const updateActivity = useUpdateActivity();
  const activityList = useActivityList();

  if (activityList.length === 0) return null;

  const openActivity = (item: ActivityDetailDTO) => {
    if (!item.uuid) return;
    bizTracker.event('online_activity_click', { activity_id: item.uuid });
    createAnchorDomClick(item.link);
    void updateActivity(item.uuid, { onlyViewed: true });
  };

  const onShare = (item: ActivityDetailDTO) => {
    if (!item.link) return;
    void writeTextInClipboard(item.link, { message: '已复制链接' });
  };

  const renderTag = (context: ReactNode, className = '') => {
    return (
      <span
        className={cx(
          'absolute -top-px -left-px text-t4 bg-black-base text-white px-2.5 py-0.5 flex items-center justify-center rounded-tl rounded-br',
          className
        )}
      >
        {context}
      </span>
    );
  };

  return (
    <>
      <div className="flex mt-2.5 items-center text-t2 h-9 top-0">线上活动</div>
      {activityList.map((item) => {
        const isAbandoned = item.status === 'abandoned';
        const isCompleted = item.status === 'completed';
        const activeColor = !item.viewed && !isAbandoned;

        let tag = <></>;
        if (isAbandoned) {
          tag = renderTag('已下线');
        } else if (activeColor) {
          tag = renderTag('NEW', 'top-0 left-0');
        } else if (isCompleted) {
          tag = renderTag('已完成');
        }
        return (
          <div
            key={item.uuid}
            className={cx('flex h-[110px] mb-2.5 items-center w-full cursor-pointer p-1.5')}
            onClick={() => openActivity(item)}
          >
            <div
              className={cx('flex items-center border w-full rounded relative p-1.5', {
                'border-active_color/20 bg-active_color/10': activeColor,
                'border-black/10': ['completed', 'abandoned'].includes(`${item.status}`),
              })}
            >
              <div
                className="h-[98px] mr-4 bg-grey6 w-[160px] bg-cover bg-center"
                style={{
                  backgroundImage: `url(${item.resourceList?.[0]?.imgUrl})`,
                }}
              >
                {tag}
              </div>
              <div className="flex items-center justify-between w-[calc(100%-160px-22px)]">
                <div
                  className={cx(
                    'overflow-hidden flex flex-col w-full',
                    isAbandoned && 'opacity-60'
                  )}
                >
                  <div className="text-t2-medium text-ellipsis mb-1.5">{item.name}</div>
                  <div className="text-t2 text-grey3 text-ellipsis mb-2">{item.synopsis}</div>
                  <div className="text-t4 text-grey3 mt-auto flex items-center">
                    <Icon name="IcTitleTime" size="middle" className="mr-0.5" />
                    {item.type === 'standing' ? (
                      '长期活动'
                    ) : (
                      <>
                        {item.startTime && dayjs(item.startTime).format('MM月DD日')}
                        {item.endTime && ` - ${dayjs(item.endTime).format('MM月DD日')}`}
                      </>
                    )}
                  </div>
                </div>
                <IconUiSizeBox className="self-start" size={20}>
                  {item.link && !isAbandoned && (
                    <Icon
                      name="IcFormShare"
                      size="middle"
                      className={cx(
                        'cursor-pointer',
                        activeColor ? 'text-active_color' : 'text-grey3'
                      )}
                      onClick={(e) => {
                        e.stopPropagation();
                        onShare(item);
                      }}
                    />
                  )}
                </IconUiSizeBox>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};
