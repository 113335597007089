import { OfficialIcon } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { DiscussionDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { last } from 'lodash-es';
import { useContext } from 'react';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { TimeAgo } from 'src/components/time-ago';
import { UserAvatar } from 'src/components/user-avatar';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { getAncestorsToPageLike } from 'src/hooks/block/use-get-ancestors';
import { useIsRoleUser } from 'src/hooks/public/use-is-role-user';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { judgeSharePage } from 'src/utils/getPageId';
import { useJumpTop } from '../main/page-doc/use-jump-top';
import { NeedJumpContext } from './context';
import { usePickBlock } from 'src/utils/pick-block';

export const CommentItem = (props: {
  discussion: DiscussionDTO;
  commentId: string;
  className?: string;
  hideBlockContext?: boolean;
}) => {
  const { commentId, discussion, hideBlockContext } = props;
  const comment = useObservableStore((state) => state.comments[props.commentId], [props.commentId]);
  const user = useUser(comment?.createdBy);
  const userName = useUserName(comment?.createdBy ?? '');
  const needJump = useContext(NeedJumpContext);
  const jumpTop = useJumpTop();
  const block = usePickBlock(discussion.parentId, ['data'], ['segments']);
  const ancestor = last(getAncestorsToPageLike(discussion.parentId).map((i) => i.uuid));
  const isRoleUser = useIsRoleUser(ancestor, comment?.createdBy);
  const doJump = () => {
    if (!needJump) return;
    void jumpTop(discussion.parentId);
  };

  if (comment == null) return null;
  // @ts-ignore type
  const { illegal } = comment;
  const owner = comment.createdBy === $currentUserCache.uuid;
  return (
    <div className={cx('flex', props.className)} data-comment-id={commentId}>
      <UserAvatar user={user} className="text-t2 w-6 h-6" />
      <div className={'flex-1 ml-2'}>
        <div className="mb-1.5">
          <Tooltip
            popup={isRoleUser ? '空间成员' : ''}
            className="text-t2-medium inline-flex items-center"
          >
            {userName}
            {isRoleUser && <OfficialIcon className="text-active_color w-3 h-3 ml-0.5" />}
          </Tooltip>
          <span className="ml-1.5 items-center text-t4 text-grey4">
            <Tooltip popup={dayjs(comment.createdAt).format(DATE_TIME_FORMAT)} className="inline">
              <TimeAgo timestamp={comment.createdAt ?? Date.now()} />
            </Tooltip>
            {comment.updatedAt - comment.createdAt > 60 * 1000 ? '（编辑过）' : null}
            {judgeSharePage() && comment.ipLocation && (
              <span className="ml-1.5">{`来自${comment.ipLocation}`}</span>
            )}
          </span>
        </div>
        {illegal && (
          <div>
            <span className="text-t2 text-grey4">该评论涉嫌违反社区分享协作政策，已屏蔽显示。</span>
            {owner && (
              <span className="cursor-pointer text-t2 text-active_color" onClick={() => {}}>
                我要申诉
              </span>
            )}
          </div>
        )}
        <>
          {discussion.comments[0] === commentId && (
            <div
              className={cx(
                'my-0.5 border-l-[3px] border-l-yellow pl-2 line-clamp-3 !rounded-none',
                needJump && 'animate-hover'
              )}
              onClick={doJump}
            >
              <RichText
                className="whitespace-pre-wrap opacity-60"
                segments={
                  hideBlockContext
                    ? discussion.context
                    : discussion.context?.length > 0
                    ? discussion.context
                    : block?.data.segments
                }
              />
            </div>
          )}
          <div
            className={cx(
              'comment-text border rounded border-transparent',
              needJump && 'animate-hover'
            )}
            onClick={doJump}
          >
            <RichText className="whitespace-pre-wrap" segments={comment.text} />
          </div>
        </>
      </div>
    </div>
  );
};
