import type { CommentDTO } from '@next-space/fe-api-idl';
import { createAction } from '@reduxjs/toolkit';

export const CREATE_COMMENT = createAction<{
  comment: CommentDTO;
  ignoreOp?: boolean;
}>('comment/create');

export const UPDATE_COMMENT = createAction<{
  uuid: string;
  patch: Partial<CommentDTO>;
  ignoreOp?: boolean;
}>('comment/update');

export const LIST_AFTER_COMMENT = createAction<{
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  after?: string;
  ignoreOp?: boolean;
}>('comment/list-after');

export const LIST_REMOVE_COMMENT = createAction<{
  uuid: string;
  parentId: string;
  spaceId: string;
  ignoreOp?: boolean;
}>('comment/list-remove');
