import { $spaceViewsCache, useSpaceViewsSelector } from 'src/services/spaces/space-views';
import { $currentUserCache } from 'src/services/user/current-user';
import { useCurrentSpaceViewId } from '../space';

export const isFavoritePage = (uuid: string) => {
  return $spaceViewsCache[$currentUserCache.currentSpaceViewId]?.favoritePages.includes(uuid);
};

export const useIsFavoritePage = (uuid: string) => {
  const currentSpaceViewId = useCurrentSpaceViewId();

  return useSpaceViewsSelector((state) => {
    return state[currentSpaceViewId]?.favoritePages.includes(uuid);
  });
};
