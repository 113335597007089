import { Switch } from '@flowus/common/components';
import type { FC } from 'react';
import React from 'react';

import { Option } from '../option';
import { SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';

export const FormNotificationSetting: FC = () => {
  const {
    spaceSettingState: { disableFormNotification, setDisableFormNotification },
  } = useModel(SettingProvider);
  return (
    <Option
      title="分享页通知提醒"
      description="当分享页面有新增订阅或收集表提交表单时，工作空间中将收到通知"
      rightPart={
        <Switch
          open={!disableFormNotification}
          onSwitch={(status) => {
            setDisableFormNotification(!status);
          }}
        />
      }
    />
  );
};
