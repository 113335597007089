import type { FC } from 'react';
import { useMemo } from 'react';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { cache, dispatch } from 'src/redux/store';
import { usePickBlock } from 'src/utils/pick-block';
import { TableContext } from './table-context';
import { TableRow } from './table-row';
import type { TableIndexRanges, TableSpans } from './types';

interface Props {
  tableId: string;
  indexRanges: TableIndexRanges;
}

// 暂时留着给思维导图那用的
export const TableBody: FC<Props> = ({ tableId, indexRanges }) => {
  const table = usePickBlock(tableId, ['subNodes', 'data']);

  const spans = useMemo(() => {
    if (!table) return {};

    const { data, subNodes } = table;
    const { format = {} } = data;
    const { tableBlockColumnOrder = [], tableBlockRanges = [] } = format;

    // 根据 tableBlockRanges 把跨行跨列的单元格的 colSpan 和 rowSpan 设置为 0
    return tableBlockRanges.reduce((result, { start, end }) => {
      if (!start || !end) return result;
      const [startRowId, startColumnId] = start;
      const [endRowId, endColumnId] = end;
      if (!startRowId || !startColumnId || !endRowId || !endColumnId) return result;
      const startRowIndex = subNodes.indexOf(startRowId);
      const startColumnIndex = tableBlockColumnOrder.indexOf(startColumnId);
      const endRowIndex = subNodes.indexOf(endRowId);
      const endColumnIndex = tableBlockColumnOrder.indexOf(endColumnId);

      result[startRowId] = {
        ...(result[startRowId] ?? {}),
        [startColumnId]: {
          colSpan: endColumnIndex - startColumnIndex + 1,
          rowSpan: endRowIndex - startRowIndex + 1,
        },
      };

      const rowIds = subNodes.slice(startRowIndex, endRowIndex + 1);
      const columnIds = tableBlockColumnOrder.slice(startColumnIndex, endColumnIndex + 1);

      for (const rowId of rowIds) {
        for (const columnId of columnIds) {
          if (rowId === startRowId && columnId === startColumnId) continue;
          result[rowId] = { ...(result[rowId] ?? {}), [columnId]: { colSpan: 0, rowSpan: 0 } };
        }
      }

      return result;
    }, {} as TableSpans);
  }, [table]);

  if (!table) return null;

  return (
    <tbody
      className="w-max"
      onMouseMove={(event) => {
        const { hoveringCell, draggingStart } = cache.simpleTable;
        if (draggingStart) return;
        const target = event.target as HTMLElement;
        const td = target.closest('td');
        if (td) {
          const rowIndex = Number(td.dataset.tableRowIndex ?? 0);
          const columnIndex = Number(td.dataset.tableColumnIndex ?? 0);
          if (
            !hoveringCell ||
            hoveringCell.rowIndex !== rowIndex ||
            hoveringCell.columnIndex !== columnIndex
          ) {
            dispatch(
              simpleTableActions.update({
                activityTableId: tableId,
                hoveringCell: { rowIndex, columnIndex },
              })
            );
          }
        }
      }}
      onMouseLeave={() => {
        const { draggingStart } = cache.simpleTable;
        if (draggingStart) return;
        dispatch(simpleTableActions.update({ hoveringCell: undefined }));
      }}
    >
      {table.subNodes.map(
        (recordId, rowIndex) =>
          cache.blocks[recordId] && (
            <TableContext.Provider key={recordId} value={{ tableId, recordId, spans, indexRanges }}>
              <TableRow rowIndex={rowIndex} />
            </TableContext.Provider>
          )
      )}
    </tbody>
  );
};
