import { useResource } from 'src/hooks/public/use-resource';
import { useUploadInfoById } from 'src/services/upload';
import { $searchParams } from 'src/utils';
import { usePickBlock } from 'src/utils/pick-block';
import { Audio } from '../../component/audio';
import type { BlockElement } from '../core/type';
import { PrintMedium } from './print';

export const AudioBlockElement: BlockElement = ({ id, root, ownerBlockId }) => {
  const block = usePickBlock(id, ['data', 'local'], ['link']);
  const uploadInfo = useUploadInfoById(id);
  const { url } = useResource(id);

  const isPrint = $searchParams.print;

  return isPrint ? (
    <PrintMedium uuid={id} />
  ) : (
    <Audio
      ownerBlockId={ownerBlockId}
      externalLink={Boolean(block?.data.link)}
      root={root}
      id={id}
      progress={uploadInfo?.progress ?? -1}
      url={url}
      defaultOpenPanel={Boolean(block?.local)}
    />
  );
};
