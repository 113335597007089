/**
 * 由于有重试功能，这里尝试upload的params参数存起来，重试成功后再回调
 * 其他情况比如导入导出，我不熟，应该也可以使用
 */

const paramsMap = new Map<string, any>();

export const addParams = (key: string, params: any) => {
  paramsMap.set(key, params);
};

export const removeParams = (key: string) => {
  paramsMap.delete(key);
};
export const getParams = (key: string | undefined) => {
  if (!key) return;
  return paramsMap.get(key);
};
