import type { AIChatDTO, AIMemberDTO, SpacePDFHistoryDTO } from '@next-space/fe-api-idl';
import type { PropertyManagePlace, RecordCreatedFrom } from 'src/bitable/table-view/types';
import type { request } from 'src/common/request';
import type { LocalFolderViewType } from 'src/hooks/drive/use-folder';
import type { MemberState } from 'src/redux/types';

export type NextAIChatDTO = Omit<AIChatDTO, 'links'> & {
  originQuote?: {
    pageNumber: number;
    content: string;
  }[];
  error?: string;
  links: {
    type: string;
    content: string;
    pageNumber?: number;
    coords?: number[];
  }[];
};

export enum SettingThemeMode {
  system = 'system',
  light = 'light',
  dark = 'dark',
}

export enum FolderViewType {
  TABLE,
  THUMBNAIL,
  PREVIEW,
}

export interface AICommand {
  uuid: string;
  name: string;
  parentId?: string;
  type?: string;
  placeholder?: string;
  icon?: string;
  scene?: string;
  params?: {};
}

export interface AppUiStateType {
  /** 当前空间 id */
  $currentSpaceId: string;
  /** 是否是dark模式 */
  $isDarkMode: boolean;
  /** 初始化项目 */
  $firstLoadPage: boolean;
  /** body是否处于mobile宽度模式 */
  $isMobileSize: boolean;
  /** 是否在编辑状态 */
  $isEditingPage: boolean;
  /** 今天 */
  $today: number;
  /** 第一次加载完成 */
  $pageRootLoaded: boolean;
  /** 创建块菜单的blockId */
  $createBlockMenuListId: string;
  /** 块菜单的blockId */
  $blockMenuListId: string;
  /** 当前选择的主题 */
  $theme: SettingThemeMode;
  /** 左侧目录是否处于收起状态 */
  $isCollapsed: boolean;
  /** 分栏block是否在resize中 */
  $columnResizing: boolean;
  /** menu-list keywords */
  $menuListKeywords: string;
  /** 鼠标focus在哪个panel上(监听用户鼠标点击为主，不能监听mouse move) */
  $isFocusInRightPanel: boolean;
  /** 控制某个textarea block的placeHolder展示，特殊情境下使用  */
  $textareaPlaceHolder?: { blockId: string; alwaysShow: boolean; placeHolder: string };
  /** slash 起点位置到末端 */
  $slashSelection: { left?: number; right?: number };
  /** 某些情况下需要ignore slash */
  $ignoreSlash: boolean;
  /** 光标在哪个同步块上[为了实现光标focus在同步块时，它的子块需要展示blockId,因此子块要监听] */
  $focusedInSyncBlockId: string;
  /** 右下角息宝是否显示小红点 */
  $serviceCenterRedBadge: boolean;
  /** 思维导图 */
  $pressedSpace: boolean;
  /** 脑图编辑节点时记录编辑时的块，为了完成【编辑时保持最小宽不变，blur之后才去掉最小宽度】这个效果*/
  $editingBlockId?: string;
  /** 脑图里面多选拖拽时以哪个为主*/
  $focusBlockId?: string;
  /** 致命错误，需要刷新页面 */
  $fatalError?: string;
  /** 只在访问公开主页时会使用 */
  $publicHomePage?: string;
  /** 是否正在框选 block 状态 */
  $isSelecting: boolean;
  /**
   * 是否展开页面,key是uuid+deep层级
   * 因为新增了引用页面,在2个page互相引用的情况下存在套娃的现象，单纯用uuid会有死循环
   * */
  $expandPageRecord: Record<string, boolean>;
  /**
   * 是否展开折叠列表
   * @hook useFold
   * @fn patchFold
   */
  $expandFoldRecord: Record<string, boolean>;
  /** 在分享状态下，文件命中风控 **/
  $fileIllegal: boolean;
  /** 当前是否有尚未同步的编辑内容 */
  $dirty: boolean;
  /** 同步本地编辑持续失败，无法恢复，需要刷新页面 */
  $syncUpFault: {
    backup: boolean;
    noPermission: boolean;
  };
  /** 团队成员在线状态以及位置，目前只是显示所在位置，后面是否在线等可以考虑在MemberState上进行扩展 */
  $membersStates: Record<string, MemberState>;
  /** 新创建的 bitable record id */
  $newCreatedRecord?: { viewId: string; id: string; from?: RecordCreatedFrom };

  $collectionSearch: Record<string, string | undefined>;
  $folderListViewTypeList: LocalFolderViewType;
  /** 其他人分享的给你的非根页面 和 自己的根页面 */
  $sharedPages: string[];
  /** 不同空间的订阅信息(非分享页订阅，只是获取空间信息，名字有歧义) */
  $subscriptionData: Record<
    string,
    Awaited<ReturnType<typeof request.infra.getSpaceSubscriptionData>>
  >;
  /** 通知魔数，该字段发生变化即表示可能有新的通知，需要刷新 */
  $notificationsMagic: Record<string, never>;
  /** 外部协作者 */
  $guestsList: {
    userId: string;
    guestPageIds: string[];
  }[];
  /** 是否在展示toolbar */
  $toolbarInfo?: {
    // 由于只有同步块内用到，因此只有在同步块内才会更新这个值
    showing: boolean;
    syncId?: string;
    uuid: string;
  };
  $tableShowHiddenGroup?: Record<string, boolean>;
  $tableGroupFoldStatus?: Record<string, Record<string, boolean>>;
  /** 新创建的 bitable 属性 */
  $newTableProperty?: { propertyId: string; from: PropertyManagePlace; groupInfo?: string };

  /** 是否允许分享页评论(有评论权限且点击右上角评论按钮的时候，这个值会设置为true) */
  $allowCommentInSharePage?: boolean;
  /** emoji弹窗消失后不能再继续弹 */
  $allowEmojiPanel?: boolean;

  $bodyWidth: number;
  $bodyHeight: number;
  /** 是否在全屏浏览图片 */
  $isOpenImagePreview: boolean;

  /** 是否有最新版，已准备好更新 */
  $readyUpdate: boolean;
  /** 是否在按住 ctrl */
  $isCtrlKeyDown: boolean;
  /** 是否展示传输列表 */
  $showTransferList: boolean;
  /** ai 指令配置 */
  $aiCommands: AICommand[];
  /** ai members */
  $aiMembers: AIMemberDTO[];
  /** pdf 是否显示是 AI sideBar */
  $showPDFSidebar: boolean;
  /** pdf 引用的文本信息 */
  $pdfQuotes: [page: number, text: string, rect?: number[]][];
  /** pdf 单个页面的列表 */
  $pdfAIContents: NextAIChatDTO[];
  /** pdf 列表是否展示 */
  $pdfHistorySidebar: boolean;
  /** pdf ai 列表 */
  $pdfAIHistory: SpacePDFHistoryDTO[];
  /** 上一次刷新的时间 */
  $lastRefreshTime: number;
  /** 订阅页面*/
  $subscribePages?: string[];
  /** 有无新增激活码 */
  $exChangeCodeRedBadge?: boolean;
}
