import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { request } from 'src/common/request';
import { useCurrentUserId } from 'src/hooks/user';
import { Images } from 'src/image';

export const WareRecord: FC = () => {
  const openModal = useOpenModal();
  const currentUserId = useCurrentUserId();

  const [recordInfo] = useApiState({
    getApi: () => request.infra.exchangeRecordList(1, 10, currentUserId),
  });
  return (
    <div className="w-[600px] h-[400px] overflow-hidden">
      <div className="px-7 flex items-center justify-between">
        <div className="flex items-center h-[50px]">
          <span className="text-t2-medium">兑换记录</span>
        </div>
        <div className="flex items-center">
          <Icon
            className="cursor-pointer ml-5"
            size="middle"
            name={'IcClose'}
            onClick={() => {
              Images.defaultWare;
              openModal.closeModal(Modals.WARE_RECORD);
            }}
          />
        </div>
      </div>
      <div className="h-px bg-grey6 w-[full]" />
      <div className="px-7 h-[calc(100%-50px)] overflow-auto py-5 space-y-5">
        {recordInfo?.list.map((v, index) => {
          return (
            <div key={index} className="relative rounded border border-grey7 p-4 flex items-center">
              <img src={Images.defaultWare} className="rounded w-20 h-20 object-cover" />
              <div className="ml-5 space-y-0.5">
                <div className="text-t1-medium">{v.itemName}</div>
                <div className="text-t4 text-grey4">{`订单编号: ${v.id}`}</div>
                {v.createdAt && (
                  <div className="text-t4 text-grey4">{`订单日期: ${dayjs(v.createdAt).format(
                    'YYYY-MM-DD'
                  )}`}</div>
                )}
                <div className="text-t4 text-grey4">{`实付: ${v.total}`}</div>
              </div>
              <div className="absolute right-4 top-4 text-active_color">
                {v.status === 1 ? '兑换成功' : '兑换失败'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
