import type { CollectionSchema } from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';
import { AggregationActionUiMap } from './helper';
import { LOCAL_LNG } from '@flowus/common/const';
import { cache } from 'src/redux/store';

export enum AggregationActionCategory {
  NONE = 'none', // 确保字符串一样
  COUNT = 'count',
  PERCENT = 'percent',
  MORE_OPTION = 'more_option',
  DATE = 'date',
}
export interface AggregationActionItem {
  type: AggregationAction;
  title: string;
  // 给分组菜单统计用的
  schemas?: (CollectionSchema & { propertyId: string })[];
}
export interface AggregationActionCategoryItem {
  type: AggregationActionCategory;
  title: string;
  subItems?: AggregationActionItem[];
}

const commonActionCategories: AggregationActionCategoryItem[] = [
  { type: AggregationActionCategory.NONE, title: AggregationActionUiMap.none },
  { type: AggregationActionCategory.COUNT, title: LOCAL_LNG.count },
  { type: AggregationActionCategory.PERCENT, title: '百分比' },
];
const dateActionCategories = [
  ...commonActionCategories,
  { type: AggregationActionCategory.DATE, title: '日期' },
];
const moreOptionActionCategories = [
  ...commonActionCategories,
  { type: AggregationActionCategory.MORE_OPTION, title: '更多选择' },
];

const countCommonActionItems: AggregationActionItem[] = [
  { type: AggregationAction.COUNT_ALL, title: AggregationActionUiMap.countAll },
  { type: AggregationAction.COUNT_VALUE, title: AggregationActionUiMap.countValue },
  { type: AggregationAction.COUNT_UNIQUE_VALUE, title: AggregationActionUiMap.countUniqueValue },
  { type: AggregationAction.COUNT_EMPTY, title: AggregationActionUiMap.countEmpty },
  { type: AggregationAction.COUNT_NOT_EMPTY, title: AggregationActionUiMap.countNotEmpty },
];
const countCommonActionItemsWithCheckbox: AggregationActionItem[] = [
  // 不要改这里，对象要一致，分组统计的菜单显示需要用到set来进行排除相同项
  countCommonActionItems[0]!,
  { type: AggregationAction.CHECKED, title: AggregationActionUiMap.checked },
  { type: AggregationAction.UNCHECKED, title: AggregationActionUiMap.unchecked },
];

const percentCommonActionItems: AggregationActionItem[] = [
  { type: AggregationAction.PERCENT_EMPTY, title: AggregationActionUiMap.percentEmpty },
  { type: AggregationAction.PERCENT_NOT_EMPTY, title: AggregationActionUiMap.percentNotEmpty },
];
/** checkbox专属 */
const percentCommonActionItemsWithCheckbox: AggregationActionItem[] = [
  { type: AggregationAction.PERCENT_CHECKED, title: AggregationActionUiMap.percentChecked },
  { type: AggregationAction.PERCENT_UNCHECKED, title: AggregationActionUiMap.percentUnchecked },
];
/** 数字专属 */
const moreOptionCommonActionItems: AggregationActionItem[] = [
  { type: AggregationAction.SUM, title: AggregationActionUiMap.sum },
  { type: AggregationAction.AVERAGE, title: AggregationActionUiMap.average },
  { type: AggregationAction.MEDIAN, title: AggregationActionUiMap.median },
  { type: AggregationAction.MIN, title: AggregationActionUiMap.min },
  { type: AggregationAction.MAX, title: AggregationActionUiMap.max },
  { type: AggregationAction.RANGE, title: AggregationActionUiMap.range },
];
/** 日期专属 */
const dateCommonActionItems: AggregationActionItem[] = [
  { type: AggregationAction.EARLIEST_DATE, title: AggregationActionUiMap.earliestDate },
  { type: AggregationAction.LATEST_DATE, title: AggregationActionUiMap.latestDate },
  { type: AggregationAction.DATE_RANGE, title: AggregationActionUiMap.dateRange },
];
const getAggregationActionCategories = (type: CollectionSchemaType) => {
  switch (type) {
    case CollectionSchemaType.NUMBER:
      return [...moreOptionActionCategories];
    case CollectionSchemaType.DATE:
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
      return [...dateActionCategories];
    default:
      return [...commonActionCategories];
  }
};

const getSubItems = (categoryType: AggregationActionCategory, type: CollectionSchemaType) => {
  switch (categoryType) {
    case AggregationActionCategory.COUNT: {
      return getAggregationActionCountItems(type);
    }
    case AggregationActionCategory.PERCENT: {
      return getAggregationActionPercentItems(type);
    }
    case AggregationActionCategory.MORE_OPTION: {
      return getMoreOptionAggregationActionItems(type);
    }
    case AggregationActionCategory.DATE:
      return getDateAggregationActionItems(type);
    default:
  }
};

const getAggregationActionCountItems = (type: CollectionSchemaType) => {
  switch (type) {
    case CollectionSchemaType.CHECKBOX:
      return countCommonActionItemsWithCheckbox;
    default:
      return countCommonActionItems;
  }
};
const getAggregationActionPercentItems = (type: CollectionSchemaType) => {
  switch (type) {
    case CollectionSchemaType.CHECKBOX:
      return percentCommonActionItemsWithCheckbox;
    default:
      return percentCommonActionItems;
  }
};
/** 获取数字属性特有统计 */
const getMoreOptionAggregationActionItems = (type: CollectionSchemaType) => {
  switch (type) {
    case CollectionSchemaType.NUMBER:
      return moreOptionCommonActionItems;
    default:
      return [];
  }
};
/** 日期属性特有统计 */
const getDateAggregationActionItems = (type: CollectionSchemaType) => {
  switch (type) {
    case CollectionSchemaType.DATE:
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
      return dateCommonActionItems;
    default:
      return [];
  }
};

export const getFooterAggregationActionCategoriesMenu = (type: CollectionSchemaType) => {
  const categories = getAggregationActionCategories(type);
  categories.forEach((c) => {
    // 莫改，这里的对象要复用，每次重置
    const subItems = getSubItems(c.type, type)?.map((v) => {
      return { ...v, schemas: [] };
    });
    if (subItems) {
      // 解构，因为这个对象需要新的，使用的时候会改变它
      c.subItems = [...subItems];
    }
  });
  return categories;
};
