import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { Icon } from '../../icon';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

interface OperationDataScheme {
  title: ReactNode;
  description: ReactNode;
  selected?: boolean;
  onClick?: () => void;
}
export const TextDescription: FC<ElementItemProps<OperationDataScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;

  return (
    <div
      {...props.attribute}
      onClick={(e) => {
        dataScheme.onClick?.();
        props.onItemClick?.(props.listItem, props.index, e);
      }}
      className={cx(
        listViewItemDefaultClassName,
        'h-auto flex items-center justify-between py-2.5 rounded cursor-pointer active-bg animate-hover',
        { 'normal-bg': props.selected }
      )}
    >
      <div className="flex flex-col">
        <div className="text-black text-t2">{dataScheme.title}</div>
        {dataScheme.description && (
          <div className="text-t4 text-grey3">{dataScheme.description}</div>
        )}
      </div>
      {dataScheme.selected && <Icon name="IcCheck02" size="middle" />}
    </div>
  );
};
