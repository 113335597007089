import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { PersonList } from 'src/bitable/table-view/cell/person-editor';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { useUsers } from 'src/hooks/page/use-subscription-data';

export const useOpenPeopleModal = () => {
  const openModal = useOpenModal();
  return useCallback(
    (e: React.MouseEvent, existPeople: string[], onSelectPerson: (uuid: string) => void) => {
      openModal.dropdown({
        popcorn: e.target as HTMLElement,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <PersonListPanel
              existPeople={existPeople}
              onSelectPerson={(uuid) => {
                onSelectPerson(uuid);
                onCloseModal();
              }}
            />
          );
        },
      });
    },
    [openModal]
  );
};
interface PersonListPanelProps {
  existPeople: string[];
  onSelectPerson: (uuid: string) => void;
}
const PersonListPanel: FC<PersonListPanelProps> = (props) => {
  const [search, setSearch] = useState('');
  const allUsers = useUsers();
  const users = allUsers.filter((user) => {
    return !props.existPeople.includes(user.uuid);
  });
  return (
    <div className="w-[260px] py-2 next-modal">
      <PersonList
        customHeader={
          <Input
            autoFocus
            className="h-8 mx-2.5 mb-2.5"
            placeholder="搜索成员"
            onChange={(value) => {
              setSearch(value);
            }}
          />
        }
        className="py-1.5"
        search={search}
        users={users}
        onSelectPerson={props.onSelectPerson}
      />
    </div>
  );
};
