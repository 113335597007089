import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import { CollectionViewType, PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import {
  CorrectOffsetY,
  EMBED_PAGE_MANAGER_HEIGHT,
  PAGE_MANAGER_HEIGHT,
} from 'src/bitable/timeline-view/const';
import { Icon } from 'src/common/components/icon';
import { SortableItem, SortableList } from 'src/common/components/sortable-list';
import { Tooltip } from 'src/common/components/tooltip';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { reSetDropInfo } from 'src/services/app/hook/use-drop-info';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import type { CollectionProperty } from '../../bitable-manager/property-list';
import { TableClassName } from '../../const';
import { AddCol } from './add-col';
import { TableSortableItem } from './sortable-item';
import { HiddenCheckBox } from '../body/hidden-checkbox';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useIsAllSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { idsToSelectedBlocks } from 'src/utils/select-block-util';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { cache, dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { Role } from '@flowus/common';
import { useFreezeColumnIndex } from 'src/hooks/collection-view/use-collection-view';
import { FreezeContext } from '../body/freeze-context';

interface HeaderProps {
  groupName?: string;
  isGroup?: boolean;
  className?: string;
  properties: CollectionProperty[];
  recordIds?: string[];
}
export const Header: FC<HeaderProps> = memo(
  ({ isGroup, groupName, properties, className, recordIds }) => {
    const { viewId, embed, relationEditor, viewType, isLocked, managerReadonly, collectionId } =
      useBitable();
    const isTimeline = viewType === CollectionViewType.TIMELINE;
    const headerRef = useRef<HTMLDivElement | null>(null);
    const readonly = useReadonly(collectionId, false);
    const scene = usePageScene();
    const syncId = useSyncId();
    const isAllSelected = useIsAllSelected(collectionId, viewId, recordIds ?? []);
    const selectCount = useObservableStore(
      (store) => {
        if (!recordIds) return 0;
        const recordIdSet = new Set(recordIds);
        return store.ui.selectedBlocks.filter((v) => {
          return recordIdSet.has(v.blockId) && v.viewId === viewId;
        }).length;
      },
      [recordIds],
      {
        obsSelectBlocks: [
          {
            blockId: collectionId,
            viewId,
            syncId,
          },
        ],
      }
    );
    const freezeColumnIndex = useFreezeColumnIndex(viewId, properties);
    const freezeProperties = properties.slice(0, freezeColumnIndex + 1);
    const restProperties = properties.slice(freezeColumnIndex + 1);

    useEffect(() => {
      if (!embed) return;

      const headerNode = headerRef.current;
      const embedTable = headerNode?.parentElement;
      const nextSpacePage = headerNode?.closest('.next-space-page');
      if (!headerNode || !embedTable || !nextSpacePage) return;
      const footerNode = embedTable.querySelector(`.${TableClassName.tableFooter}`);
      const handleScroll = () => {
        const embedTableRect = embedTable.getBoundingClientRect();
        const nextSpacePageRect = nextSpacePage.getBoundingClientRect();

        if (embedTableRect.top < nextSpacePageRect.top) {
          let translateY = Math.abs(nextSpacePageRect.top - embedTableRect.top);

          const footerRect = footerNode?.getBoundingClientRect();
          if (footerRect && footerRect.y - nextSpacePageRect.y < headerNode.clientHeight) {
            translateY =
              translateY - (headerNode.clientHeight - (footerRect.top - nextSpacePageRect.top));
          }

          headerNode.style.transform = `translate3d(0,${Math.round(
            translateY + EMBED_PAGE_MANAGER_HEIGHT + CorrectOffsetY
          )}px,0)`;
        } else {
          headerNode.style.transform = 'none';
        }
      };

      nextSpacePage.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);
      return () => {
        nextSpacePage.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }, [embed]);

    let top: number | undefined = 0;
    if (!relationEditor) {
      if (embed) {
        top = undefined;
      } else {
        top = (isGroup ? 38 + PAGE_MANAGER_HEIGHT : PAGE_MANAGER_HEIGHT) - 1;
        if (scene === PageScene.PAGE_LITE_PREVIEW) {
          top = top - 11;
        }
      }
    }
    const beforeAppendChild = (
      <>
        {!isTimeline ? (
          recordIds && !readonly ? (
            <HiddenCheckBox
              isGroup={isGroup}
              readonly={readonly}
              alwaysShow={selectCount > 0}
              checked={isAllSelected}
              onCheck={(checked) => {
                const filterIds = recordIds.filter((recordId) => {
                  const permission = getPermissions(recordId);
                  return Role.contains(permission.role, PermissionRole.WRITER);
                });
                const recordIdSet = new Set(filterIds);
                if (checked) {
                  const selectedBlocks = idsToSelectedBlocks(filterIds, viewId, syncId);
                  const rest = cache.ui.selectedBlocks.filter((v) => !recordIdSet.has(v.blockId));
                  selectedBlocks.push(...rest);
                  dispatch(uiActions.updateSelectBlocks(selectedBlocks));
                } else {
                  const selectedBlocks = cache.ui.selectedBlocks.filter(
                    (v) => !recordIdSet.has(v.blockId)
                  );
                  dispatch(uiActions.updateSelectBlocks(selectedBlocks));
                }
              }}
            />
          ) : null
        ) : null}
      </>
    );
    return (
      <div
        ref={headerRef}
        style={{ top }}
        className={cx(
          'sticky z-[11] w-full min-w-max border-b transition-none print:border-none bg-white1 flex-shrink-0',
          className,
          TableClassName.tableHeader,
          !isGroup && !relationEditor && 'border-t'
        )}
      >
        <SortableList
          disabled={managerReadonly || isLocked}
          className="flex"
          appendChild={!isTimeline ? <AddCol groupName={groupName} /> : null}
          // modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
          // 去掉了restrictToParentElement，会导致冻结列无法拖拽到非冻结列
          modifiers={[restrictToHorizontalAxis]}
          strategy={horizontalListSortingStrategy}
          onDragStart={() => reSetDropInfo()}
          items={properties.map((item) => ({ id: item.property, ...item }))}
          renderItemContent={({ item }) => (
            // 这个只有拖拽的时候用得到，因为冻结列需要自定义(customRenderItems)
            <TableSortableItem col={item} groupName={groupName} isGroup={isGroup} />
          )}
          customRenderItems={() => {
            return (
              <>
                {freezeProperties.length === 0 && beforeAppendChild}
                {freezeProperties.length > 0 && (
                  <FreezeContext.Provider value={true}>
                    <div className="flex sticky left-0 z-[10] bg-white1">
                      {beforeAppendChild}
                      {freezeProperties.map((item) => {
                        return (
                          <SortableItem
                            key={item.property}
                            renderItemContent={({ item }) => {
                              return (
                                <TableSortableItem
                                  col={item}
                                  groupName={groupName}
                                  isGroup={isGroup}
                                />
                              );
                            }}
                            item={{ id: item.property, ...item }}
                            disabled={managerReadonly || isLocked}
                            onlyHandleDraggable={false}
                          />
                        );
                      })}
                    </div>
                  </FreezeContext.Provider>
                )}
                {restProperties.map((item) => {
                  return (
                    <SortableItem
                      key={item.property}
                      renderItemContent={({ item }) => {
                        return (
                          <TableSortableItem col={item} groupName={groupName} isGroup={isGroup} />
                        );
                      }}
                      item={{ id: item.property, ...item }}
                      disabled={managerReadonly || isLocked}
                      onlyHandleDraggable={false}
                    />
                  );
                })}
              </>
            );
          }}
          onChange={(_, { active, over }) => {
            reSetDropInfo();
            if (!over) return;

            const { properties = [], timelineTableProperties = [] } = getViewFormat(viewId) ?? {};
            const currentProperties = isTimeline ? timelineTableProperties : properties;

            if (active.id !== over.id) {
              const oldIndex = currentProperties.findIndex((item) => item.property === active.id);
              const newIndex = currentProperties.findIndex((item) => item.property === over.id);
              const sorted = arrayMove(currentProperties, oldIndex, newIndex);
              updateViewFormat(viewId, {
                [isTimeline ? 'timelineTableProperties' : 'tableProperties']: sorted,
              });
            }
          }}
        />

        {isTimeline && !groupName && !managerReadonly && !isLocked && (
          <Tooltip
            popup="收起表格"
            className="animate-hover absolute right-2 top-2 z-10 rotate-180 text-grey3"
            onClick={(event) => {
              event.stopPropagation();
              updateViewFormat(viewId, { timelineShowTable: false });
            }}
          >
            <Icon name="IcUnfold" size="middle" />
          </Tooltip>
        )}
      </div>
    );
  }
);
