import { Uuid } from '@flowus/common';
import type { CommentDTO, DiscussionDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { BlockStatus, BlockType } from '@next-space/fe-api-idl';
import * as _ from 'lodash-es';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { removeDiscussionFromSegments } from 'src/editor/utils/segments';
import { useCurrentUser } from 'src/hooks/user';
import { HISTORY_EFFECTS, UPDATE_BLOCK } from 'src/redux/actions';
import {
  CREATE_COMMENT,
  LIST_AFTER_COMMENT,
  LIST_REMOVE_COMMENT,
  UPDATE_COMMENT,
} from 'src/redux/actions/comments';
import {
  CREATE_DISCUSSION,
  LIST_AFTER_DISCUSSION,
  LIST_REMOVE_DISCUSSION,
  UPDATE_DISCUSSION,
} from 'src/redux/actions/discussion';
import { getState } from 'src/redux/store';

export const useCommentOperations = () => {
  // const store = useStore();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  return useMemo(() => {
    const setDiscussionResolved = (discussionId: string, resolved: boolean) => {
      const prevState = getState().discussions[discussionId]?.resolved ?? false;
      dispatch(
        HISTORY_EFFECTS({
          init() {
            dispatch(
              UPDATE_DISCUSSION({
                uuid: discussionId,
                patch: {
                  resolved,
                },
              })
            );
          },
          undo() {
            dispatch(
              UPDATE_DISCUSSION({
                uuid: discussionId,
                patch: {
                  resolved: prevState,
                },
              })
            );
          },
          redo() {
            dispatch(
              UPDATE_DISCUSSION({
                uuid: discussionId,
                patch: {
                  resolved,
                },
              })
            );
          },
        })
      );
    };
    return {
      addDiscussion(config: { blockId: string; context?: SegmentDTO[] | undefined }) {
        const uuid = Uuid.v4();
        const currentTime = Date.now();
        const spaceId = getState().blocks[config.blockId]?.spaceId ?? '';
        const discussion: DiscussionDTO = {
          uuid,
          spaceId,
          parentId: config.blockId,
          status: BlockStatus.NORMAL,
          createdAt: currentTime,
          updatedAt: currentTime,
          createdBy: currentUser.uuid,
          updatedBy: currentUser.uuid,
          resolved: false,
          version: 0,
          comments: [],
          context: config.context ?? [],
        };
        dispatch(CREATE_DISCUSSION({ discussion }));
        dispatch(LIST_AFTER_DISCUSSION({ uuid, parentId: config.blockId }));
        return uuid;
      },
      resolveDiscussion(config: { discussionId: string }) {
        setDiscussionResolved(config.discussionId, true);
      },
      reopenDiscussion(config: { discussionId: string }) {
        setDiscussionResolved(config.discussionId, false);
      },
      appendComment(config: { discussionId: string; text: SegmentDTO[] | undefined }) {
        const uuid = Uuid.v4();
        const currentTime = Date.now();
        const spaceId = getState().discussions[config.discussionId]?.spaceId ?? '';
        const comment: CommentDTO = {
          uuid,
          spaceId,
          parentId: config.discussionId,
          version: 0,
          status: BlockStatus.NORMAL,
          createdAt: currentTime,
          createdBy: currentUser.uuid,
          updatedAt: currentTime,
          updatedBy: currentUser.uuid,
          text: config.text ?? [],
          subNodes: [],
        };
        dispatch(CREATE_COMMENT({ comment }));
        dispatch(LIST_AFTER_COMMENT({ uuid, parentId: config.discussionId }));
        return uuid;
      },
      editComment(config: { commentId: string; newText: SegmentDTO[] | undefined }) {
        dispatch(
          UPDATE_COMMENT({
            uuid: config.commentId,
            patch: { text: config.newText, updatedAt: Date.now(), updatedBy: currentUser.uuid },
          })
        );
      },
      deleteComment(config: { commentId: string }) {
        const comment = getState().comments[config.commentId];
        if (comment == null) return;
        const discussionId = comment.parentId;
        dispatch(
          LIST_REMOVE_COMMENT({
            uuid: comment.uuid,
            parentId: comment.parentId,
            spaceId: comment.spaceId,
          })
        );
        dispatch(UPDATE_COMMENT({ uuid: comment.uuid, patch: { status: BlockStatus.DELETED } }));
        const discussion = getState().discussions[discussionId];
        if (discussion == null) return;
        if (discussion.comments.length === 0) {
          dispatch(
            LIST_REMOVE_DISCUSSION({
              uuid: discussion.uuid,
              parentId: discussion.parentId,
              spaceId: discussion.spaceId,
            })
          );
          dispatch(
            UPDATE_DISCUSSION({
              uuid: discussion.uuid,
              patch: { status: BlockStatus.DELETED },
            })
          );

          const block = getState().blocks[discussion.parentId];
          if (block != null) {
            // 富文本字段可能在block下面的别的属性里
            // 这里依赖于 immerjs 能返回同样的 segments 如果没做修改。（不确定这个假设是否对）
            const updateTextProp = (path: string) => {
              const segments = removeDiscussionFromSegments(_.get(block, path), discussion.uuid);
              if (segments !== block.data.segments) {
                const patch = {};
                _.set(patch, path, segments);
                dispatch(
                  UPDATE_BLOCK({
                    uuid: block.uuid,
                    patch,
                  })
                );
              }
            };
            updateTextProp('data.segments');
            updateTextProp('data.caption');
            updateTextProp('data.description');
            if (block.type === BlockType.TABLE_ROW) {
              for (const key of Object.keys(block.data.collectionProperties ?? {})) {
                updateTextProp(`data.collectionProperties.${key}`);
              }
            }
          }
        }
      },
    };
  }, [dispatch, currentUser.uuid]);
};
