import { useMindMapSelector } from '@flowus/mind-map';
import { useMemo } from 'react';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getDefaultNodeClassName } from '../utils/mind-node-util';

export const useMindNodeClassName = (id: string, level: number, canComment?: boolean) => {
  const isDragging = useIsDragging();
  const isSelected = useObservableStore(
    (state) => {
      return (
        state.ui.selectedBlocks.findIndex((selectBlock) => selectBlock.blockId === id) !== -1 &&
        !isDragging
      );
    },
    [id],
    { obsSelectBlocks: [{ blockId: id }] }
  );
  const showBorder = useMindMapSelector((state) => state.dropInfo?.parentId === id);
  const defaultClassName = useMemo(
    () => getDefaultNodeClassName(level, isSelected || showBorder, false, canComment),
    [canComment, isSelected, level, showBorder]
  );
  return defaultClassName;
};
