import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import { useBitable } from 'src/bitable/context';
import { getDatePropertyFromBlock } from 'src/bitable/table-view/cell/helpers';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getSortedRecordIds } from 'src/hooks/collection-view/use-get-sorted-records';
import type { NextBlock } from 'src/redux/types';
import { useCollectionSearchById } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';
import type { RenderDayCard } from '../const';
import { useGetPageId } from 'src/utils/getPageId';

export const getCalendarByType = (viewId: string) => {
  const viewFormat = getViewFormat(viewId);
  if (!viewFormat) {
    return {
      calendarByType: undefined,
      calendarByEndType: undefined,
    };
  }
  const { view, collection } = viewFormat;
  const { calendarBy = '', calendarByEnd = '' } = view.format;

  const schema = collection?.data.schema ?? {};
  return {
    calendarByType: schema[calendarBy]?.type,
    calendarByEndType: schema[calendarByEnd]?.type,
  };
};

export const useCategoryRecord = (base: number) => {
  const { viewId, collectionId } = useBitable();
  const pageId = useGetPageId();

  // 监听 search 值
  const keywords = useCollectionSearchById(viewId);
  const renderCards = useObservableStore(
    (state) => {
      const { blocks, collectionViews } = state;
      const collection = blocks[collectionId];
      const renderCards: { allDayRecord: RenderDayCard[]; timeRangeRecord: RenderDayCard[] } = {
        allDayRecord: [],
        timeRangeRecord: [],
      };

      const view = collectionViews[viewId];
      if (!view || !collection) return renderCards;

      const { calendarBy = '', calendarByEnd = '' } = view.format;
      const { calendarByType, calendarByEndType } = getCalendarByType(viewId);

      let { sortedRecordIds } = getSortedRecordIds({ viewId, pageId });
      sortedRecordIds = sortedRecordIds
        .map((uuid) => blocks[uuid])
        .filter((item): item is NextBlock => !!item)
        .sort((a, b) => (a.createdAt ?? 0) - (b?.createdAt ?? 0))
        .map((item) => item.uuid);

      sortedRecordIds.forEach((recordId: string) => {
        const block = blocks[recordId];
        if (!block) return;

        let rawStartTime: number | undefined = 0;
        let rawEndTime: number | undefined = 0;
        let startHasTime = true;
        let endHasTime = true;

        if (
          calendarByType === CollectionSchemaType.CREATED_AT ||
          calendarByType === CollectionSchemaType.UPDATED_AT
        ) {
          startHasTime = true;
          rawStartTime =
            calendarByType === CollectionSchemaType.CREATED_AT ? block.createdAt : block.updatedAt;
        } else {
          const { textType, timestamp } = getDatePropertyFromBlock(block, calendarBy) ?? {};
          startHasTime = textType === TextType.DATETIME;
          rawStartTime = timestamp;
        }
        if (
          calendarByEndType === CollectionSchemaType.CREATED_AT ||
          calendarByEndType === CollectionSchemaType.UPDATED_AT
        ) {
          endHasTime = true;
          rawEndTime =
            calendarByEndType === CollectionSchemaType.CREATED_AT
              ? block.createdAt
              : block.updatedAt;
        } else {
          const { textType, timestamp } = getDatePropertyFromBlock(block, calendarByEnd) ?? {};
          endHasTime = textType === TextType.DATETIME;
          rawEndTime = timestamp;
        }

        // 保证这两个类型存在
        if (!calendarByType || !calendarByEndType) return;
        // 两个时间都不存在，则不展示
        if (!rawStartTime && !rawStartTime) return;
        // 如果开始或者结束时间不存在，则使用存在的时间
        if (!rawStartTime) {
          rawStartTime = rawEndTime;
          startHasTime = endHasTime;
        }
        if (!rawEndTime) {
          rawEndTime = rawStartTime;
          endHasTime = startHasTime;
        }
        // 保证开始结束时间都存在
        if (!rawStartTime) return;
        if (!rawEndTime) return;
        // 如果结束时间小于开始时间，则结束时间取开始时间
        if (rawEndTime < rawStartTime) {
          rawEndTime = rawStartTime;
          endHasTime = startHasTime;
        }

        const startDate = getDateTimeStamp(rawStartTime);
        const endDate = getDateTimeStamp(rawEndTime);

        if (
          startDate === base ||
          endDate === base ||
          (startDate < base && rawEndTime >= base + ONE_DAY) // 横跨
        ) {
          if (startDate === endDate) {
            if (endHasTime && !startHasTime) {
              rawStartTime = rawEndTime;
              startHasTime = endHasTime;
            }
          }

          // const oneMinuteHeight = UNIT_HEIGHT / 15;
          // const minMinute = (MIN_HEIGHT / oneMinuteHeight) * ONE_MINUTES;
          // if (rawEndTime - rawStartTime < minMinute) {
          //   rawEndTime += minMinute;
          // }

          const startTime = rawStartTime;
          const endTime = rawEndTime;

          const card: RenderDayCard = {
            uuid: recordId,
            startTime,
            endTime,
          };

          if ((startDate === base && startHasTime) || (endDate === base && endHasTime)) {
            renderCards.timeRangeRecord.push(card);
            return;
          }

          renderCards.allDayRecord.push(card);
        }
      });

      return renderCards;
    },
    [collectionId, viewId, keywords]
  );

  const { timeRangeRecord } = renderCards;

  timeRangeRecord.sort((a, b) => (a.startTime || a.endTime || 0) - (b.startTime || b.endTime || 0));
  const cols: RenderDayCard[][] = [];
  timeRangeRecord.forEach((renderCard, index) => {
    if (index === 0) {
      cols.push([renderCard]);
      return;
    }

    for (const col of cols) {
      const inter = col.find((i) => intersect(i, renderCard));
      if (!inter) {
        col.push(renderCard);
        return;
      }
    }

    cols.push([renderCard]);
  });

  cols.forEach((col, index) => {
    col.forEach((renderCard) => {
      renderCard.left = index;

      const rightCols = cols.slice(index + 1);
      let recordCount = index + 1;

      for (const col of rightCols) {
        const inter = col.find((i) => intersect(renderCard, i));
        if (inter) {
          recordCount++;
        } else {
          if (index + 1 !== recordCount) {
            const i = rightCols.findIndex((item) => item === col);
            recordCount += rightCols.length - i;
            break;
          } else {
            continue;
          }
        }
      }

      renderCard.recordCount = recordCount;
    });
  });

  return renderCards;
};

const intersect = (a: RenderDayCard, b: RenderDayCard) => {
  const { startTime: startTimeA, endTime: endTimeA } = a;
  const { startTime: startTimeB, endTime: endTimeB } = b;
  if (!startTimeA || !startTimeB || !endTimeA || !endTimeB) return;

  if (startTimeA < startTimeB) {
    return startTimeB >= startTimeA && startTimeB < endTimeA;
  }
  return startTimeA >= startTimeB && startTimeA < endTimeB;
};
