import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import { ONE_MOMENT } from 'src/utils/date-utils';
import { UNIT_HEIGHT } from '../const';
import { TIME_FORMAT } from 'src/common/const';

export const DayViewBackground = () => {
  const baseDate = dayjs().startOf('year').valueOf();
  return (
    <div className="pointer-events-none">
      {new Array(24).fill(1).map((_, index) => {
        return (
          <div
            key={index}
            style={{ height: UNIT_HEIGHT * 4 }}
            className={cx('relative border-t', index === 23 ? 'border-b' : '')}
          >
            <span className="absolute -ml-[30px] text-t4-medium text-grey3 -translate-x-full -translate-y-1/2">
              {dayjs(baseDate + index * ONE_MOMENT * 4).format(TIME_FORMAT)}
            </span>

            {index === 23 && (
              <span className="absolute bottom-0  -ml-[30px] -translate-x-full translate-y-1/2 text-t4-medium text-grey3">
                {dayjs(baseDate + (index + 1) * ONE_MOMENT * 4).format(TIME_FORMAT)}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};
