import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';

interface Props {
  icon?: string;
  className?: string;
  title?: ReactNode;
  description?: ReactNode;
}
export const Placeholder: FC<Props> = (props) => {
  const { icon, title, description, children, className } = props;
  return (
    <div className={cx(`login-wrapper`, className)}>
      {icon && (
        <p className="text-[70px] leading-[80px] mb-2">
          <IconWidget disableHover={true} emojiStr={icon} />
        </p>
      )}
      {title && <div className="text-h4 mb-2">{title}</div>}
      {description && <div className="text-t2 mb-5 text-grey3">{description}</div>}
      {children}
    </div>
  );
};
