import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { fixCollectionId } from 'src/utils/fix-collection-id';

const isSelectOrMultiSelect = (type?: CollectionSchemaType) => {
  return type === CollectionSchemaType.SELECT || type === CollectionSchemaType.MULTI_SELECT;
};

export const useGroupOption = (viewId: string, groupName?: string, isSubGroup = false) => {
  return useObservableStore(
    ({ collectionViews, blocks }) => {
      const groupSchema = getGroupPropertySchema(viewId, isSubGroup, blocks, collectionViews);
      if (!groupSchema) return;

      if (isSelectOrMultiSelect(groupSchema.type)) {
        return groupSchema.options?.find((option) => option.value === groupName);
      }
    },
    [viewId, groupName, isSubGroup]
  );
};

export const useGroupPropertySchema = (viewId: string, isSubGroup = false) => {
  return useObservableStore(
    ({ collectionViews, blocks }) => {
      return getGroupPropertySchema(viewId, isSubGroup, blocks, collectionViews);
    },
    [viewId, isSubGroup]
  );
};

export const getGroupPropertySchema = (
  viewId: string,
  isSubGroup = false,
  blocks: Record<string, NextBlock> = getState().blocks,
  collectionViews: Record<string, CollectionViewDTO> = getState().collectionViews
) => {
  const view = collectionViews[viewId];
  if (!view) return;

  const collectionId = fixCollectionId(viewId);
  if (!collectionId) return;

  const collection = blocks[collectionId];
  const isBoard = view.type === CollectionViewType.BOARD;
  const groupSchema = {
    ...collection?.data.schema?.[view.format.boardGroupBy?.property ?? ''],
    propertyId: view.format.boardGroupBy?.property,
  };
  const subGroupSchema = {
    ...collection?.data.schema?.[view.format.collectionGroupBy?.property ?? ''],
    propertyId: view.format.collectionGroupBy?.property,
  };

  if (isBoard && isSubGroup) {
    return subGroupSchema;
  }

  return isBoard ? groupSchema : subGroupSchema;
};
