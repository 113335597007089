import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';

interface Props {
  defaultValue?: string;
  placeholder?: string;
  onInputUrl: (url: string) => void;
  validate?: (url: string) => string;
}

const validateUrl = (url: string) => {
  if (!url.trim()) {
    message.warning('链接不能为空');
    return false;
  }
  return true;
};

export const InputExternalLink: FC<Props> = (props) => {
  const { validate = validateUrl } = props;
  const [content, setContent] = useState(props.defaultValue ?? '');
  return (
    <div className="flex flex-col items-center">
      <Input
        autoFocus={!props.defaultValue}
        defaultValue={props.defaultValue}
        className="h-8 rounded-sm mt-4 w-[calc(100%-20px)]"
        placeholder={props.placeholder}
        onChange={setContent}
        onEnter={() => {
          if (content) {
            if (validate(content)) {
              props.onInputUrl(content);
            }
          }
        }}
      />
      <Button
        disable={!content}
        size="large"
        colorType="active"
        className="mt-4 text-t4-medium w-[300px] mb-4"
        onClick={() => {
          if (validate(content)) {
            props.onInputUrl(content);
          }
        }}
        style={{
          transform: 'translateX(50%) translate(-150px)',
        }}
      >
        提交
      </Button>
    </div>
  );
};
