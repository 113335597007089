import { cx } from '@flowus/common/cx';
import type { BlockDTO, CollectionSchema, CollectionViewDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import { combineReducers, legacy_createStore } from '@reduxjs/toolkit';
import { css } from 'otion';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Provider as ReduxProvider, useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import { ViewIconMap, ViewNameMap } from 'src/bitable/bitable-manager/const';
import { ICON_MAP } from 'src/bitable/const';
import { renderPropValue } from 'src/bitable/v2/renderers';
import type { BiValueType } from 'src/bitable/v2/values/_abstract';
import { Icon } from 'src/common/components/icon';
import { NextModalProvider } from 'src/common/components/next-modal';
import { UNNAMED_GROUP, UUID_NAMESPACE } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { BlockRenderer } from 'src/editor/editor/uikit/block';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { DiffAgainstTo } from 'src/editor/editor/uikit/editable/helper';
import { useCurrentSpace } from 'src/hooks/space';
import { useUserName } from 'src/hooks/user/use-remark-name';
import * as reducers from 'src/redux/reducers';
import { PageScene, PageSceneContext } from '../../scene-context';

export const CollectionViewSubject: FC<{ className?: string; view: CollectionViewDTO }> = ({
  className,
  view,
}) => {
  return (
    <Link to={`/${view.parentId}#${view.uuid}`}>
      <span className={cx('text-t2-medium p-1 animate-hover', className)}>
        <Icon
          className="mr-1 align-middle relative bottom-[1px]"
          name={ViewIconMap[view.type]}
          size="middle"
        />
        <span className="max-w-[100px] text-ellipsis">{view.title || ViewNameMap[view.type]}</span>
      </span>
    </Link>
  );
};

export const PageSubject: FC<{ pageId: string }> = ({ pageId }) => {
  return <InlinePage uuid={pageId} interactable showUnderLine={false} />;
};

export const CollectionPropertySubject: FC<{
  className?: string;
  schema: CollectionSchema;
  onClick?: () => void;
}> = ({ className, schema, onClick }) => {
  return (
    <span className={cx(onClick && 'animate-hover', className)}>
      <Icon
        name={ICON_MAP[schema.type]}
        size="middle"
        className="mr-1 align-middle relative bottom-[1px]"
      />
      <span>{schema.name}</span>
    </span>
  );
};

export const Author: FC<{ userId: string }> = ({ userId }) => {
  const userName = useUserName(userId, '');
  return <>{userName}</>;
};

export const CurrentSpaceSubject: FC = () => {
  const space = useCurrentSpace();
  return <>{space.title}</>;
};

export const UserSubject: FC<{ userId?: string }> = ({ userId }) => {
  const userName = useUserName(userId ?? '');
  return <span>{userName}</span>;
};

export const GroupSubject: FC<{ groupId?: string }> = ({ groupId }) => {
  const space = useCurrentSpace();
  const group = space.permissionGroups?.find((it) => it.id === groupId);
  return <span>{group?.name || UNNAMED_GROUP}</span>;
};

export const BlockSubject: FC<{
  block: BlockDTO;
  diffAgainstTo?: BlockDTO | 'created' | 'deleted' | undefined;
}> = ({ block, diffAgainstTo }) => {
  if (
    block.type === BlockType.REFERENCE ||
    block.type === BlockType.REFERENCE_COLLECTION ||
    block.type === BlockType.REFERENCE_COLLECTION_PAGE
  ) {
    if (block.data.ref?.uuid == null) return null;
    return (
      <div className="py-1">
        <InlinePage uuid={block.data.ref.uuid} showUnderLine={false} />
      </div>
    );
  }

  if (
    block.type === BlockType.PAGE ||
    block.type === BlockType.FOLDER ||
    block.type === BlockType.COLLECTION_VIEW ||
    block.type === BlockType.COLLECTION_VIEW_PAGE
  ) {
    return (
      <div className="py-1">
        <InlinePage uuid={block.uuid} showUnderLine={false} />
      </div>
    );
  }

  if (block.type === BlockType.TABLE) {
    return (
      <div className="py-1 flex items-center">
        <Icon name="IcMenuTable" size="middle" className="mr-1" />
        <div className="text-t2">简单表格</div>
      </div>
    );
  }

  if (block.type === BlockType.SYNC_CONTAINER || block.type === BlockType.SYNC_REFERENCE) {
    return (
      <div className="py-1.5 flex items-center border border-grey6 rounded-sm px-2">
        <div className="text-t2 text-grey3">同步块</div>
      </div>
    );
  }

  return <BlockSubject2 block={block} diffAgainstTo={diffAgainstTo} />;
};

// 为了复用 renderers
export const BlockSubject2: FC<{
  block: BlockDTO;
  diffAgainstTo?: BlockDTO | 'created' | 'deleted' | undefined;
}> = ({ block, diffAgainstTo }) => {
  const outerStore = useStore();
  const store = useMemo(() => {
    return createFeedsStore(block, outerStore);
  }, [block, outerStore]);
  return (
    <ReduxProvider store={store}>
      <PageSceneContext.Provider value={PageScene.PAGE_FEEDS}>
        <EditableNamespace.Provider value={UUID_NAMESPACE.PAGE_FEEDS}>
          <NextModalProvider>
            <DiffAgainstTo.Provider value={diffAgainstTo}>
              <BlockRenderer id={block.uuid} />
            </DiffAgainstTo.Provider>
          </NextModalProvider>
        </EditableNamespace.Provider>
      </PageSceneContext.Provider>
    </ReduxProvider>
  );
};

const createFeedsStore = (block: BlockDTO, outerStore: ReturnType<typeof useStore>) => {
  const reducer = combineReducers(reducers);
  const blocks = new Proxy(
    {},
    {
      get(target, uuid, receiver) {
        if (uuid === block.uuid) {
          return block;
        }
        return Reflect.get(outerStore.getState().blocks, uuid, receiver);
      },
    }
  );
  const users = new Proxy(
    {},
    {
      get(target, uuid, receiver) {
        return Reflect.get(outerStore.getState().users, uuid, receiver);
      },
    }
  );
  const store = legacy_createStore(reducer, { blocks, users });
  outerStore.subscribe(() => {
    store.replaceReducer(reducer);
  });
  return store;
};

export const IconSubject: FC<{ block: BlockDTO }> = ({ block }) => {
  const outerStore = useStore();
  const store = useMemo(() => {
    return createFeedsStore(block, outerStore);
  }, [block, outerStore]);
  return (
    <ReduxProvider store={store}>
      <PageSceneContext.Provider value={PageScene.PAGE_FEEDS}>
        <EditableNamespace.Provider value={UUID_NAMESPACE.PAGE_FEEDS}>
          <NextModalProvider>
            <IconTrigger
              className={css({ fontSize: '20px', lineHeight: '1' })}
              blockId={block.uuid}
              trigger={false}
              defaultIcon={<BlockDefaultIcon uuid={block.uuid} size="middle" />}
            />
          </NextModalProvider>
        </EditableNamespace.Provider>
      </PageSceneContext.Provider>
    </ReduxProvider>
  );
};

export function PropertyValueSubject<T>(props: { type: BiValueType<T>; value: T }) {
  return (
    <div className="text-t2 leading-6 flex items-center">
      {renderPropValue(props.type, props.value)}
    </div>
  );
}
