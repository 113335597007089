import CryptoJS from 'crypto-js';
import { SingleInput } from '@flowus/common';
import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { UserAvatar } from 'src/components/user-avatar';
import { useCurrentUser, useLogout } from 'src/hooks/user';
import { setScreenLock } from 'src/services/screen-lock';
import { Logo } from './share/logo';
import { message } from '@flowus/common/components/message';

const MaxSize = 6;
export const ScreenLock: FC = () => {
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const logout = useLogout();

  return (
    <div className="relative flex flex-col items-center justify-center h-screen space-y-5 bg-white2">
      <UserAvatar user={currentUser} className="w-40 h-40" />
      <div className="space-y-2">
        <SingleInput
          className="h-8"
          size={MaxSize}
          placeholder="输入 PIN 码解锁"
          showSwitchIcon={false}
          success={(e) => {
            if (CryptoJS.MD5(e).toString() === currentUser.setting.pin) {
              setScreenLock({ enable: false });
            } else {
              message.error('PIN 码不正确');
            }
          }}
        />
        <Tooltip
          placement="right"
          popup="可通过重新登录进入"
          onClick={() => {
            openModal.warning({
              title: '忘记 PIN 码，可重新登录解锁',
              confirmText: '重新登录',
              confirm() {
                void logout();
              },
            });
          }}
          className="flex items-center justify-end text-t4 text-grey3 animate-click space-x-0.5"
        >
          <span>忘记密码</span>
          <Icon name="IcInviteHelp" size="small" />
        </Tooltip>
      </div>
      <Logo className="absolute bottom-10" />
    </div>
  );
};
