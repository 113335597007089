import { BlockType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';

export const fixCollectionId = (viewId: string) => {
  const { blocks, collectionViews } = getState();
  const view = collectionViews[viewId];
  if (!view) {
    return;
  }

  const block = blocks[view.parentId];
  if (!block) {
    return;
  }

  if (block.type === BlockType.COLLECTION_VIEW_PAGE || block.type === BlockType.COLLECTION_VIEW) {
    return block.uuid;
  }

  if (
    block.type === BlockType.REFERENCE_COLLECTION ||
    block.type === BlockType.REFERENCE_COLLECTION_PAGE
  ) {
    return block.data.ref?.uuid;
  }
};
