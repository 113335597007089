export const createCode = () => {
  const getRandom = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
  /*随机生成4位验证码*/
  /*step1:将所有字母，数字装入一个数组备用*/
  let str = '';
  // 3.循环四次
  for (let i = 0; i < 4; i++) {
    // 1.得到相应字符的ASCII码值
    const ascii = getRandom(48, 122);
    if ((ascii > 57 && ascii < 65) || (ascii > 90 && ascii < 97)) {
      i--;
      continue;
    }
    // 2.通过ASCII码值得到相应的字符串：String.fromCharCode(ASCII值)
    const c = String.fromCharCode(ascii);
    // 4.拼接在一起
    str += c;
  }
  // 5.返回结果
  return str;
};
