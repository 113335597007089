import { BlockType } from '@next-space/fe-api-idl';

/** 在导图下不展示children的块 */
export const HideChildrenBlockList = [
  BlockType.TABLE,
  BlockType.PAGE,
  BlockType.FOLDER,
  BlockType.COLLECTION_VIEW_PAGE,
  BlockType.COLLECTION_VIEW,
  BlockType.REFERENCE_COLLECTION,
  BlockType.REFERENCE_COLLECTION_PAGE,
  BlockType.MIND_MAPPING,
  BlockType.MIND_MAPPING_PAGE,
];

/** 可以添加附件/图片的节点 */
export const CanAppendAttachmentBlockList = [
  BlockType.TEXTAREA,
  BlockType.HEADER,
  BlockType.QUOTE,
  BlockType.TODO,
  BlockType.LIST,
  BlockType.ORDER_LIST,
  BlockType.FOLD_LIST,
  BlockType.TOGGLE_HEADER,
  BlockType.MARK,
  BlockType.FILE,
];

export const HasRichTextBlockList = [
  BlockType.TEXTAREA,
  BlockType.HEADER,
  BlockType.QUOTE,
  BlockType.TODO,
  BlockType.MARK,
  BlockType.ORDER_LIST,
  BlockType.LIST,
];
