import { isOssUrl } from '@flowus/common';
import { getBlankImage, useEnhanceImage } from '@flowus/common/hooks/file';
import { omit } from 'lodash-es';
import type { CSSProperties, FC, HTMLAttributes, ReactNode } from 'react';
import { Img } from 'react-image';
import { $searchParams } from 'src/utils';

export interface EnhanceImageProps extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  alt?: string;
  src: string;
  style?: CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  loader?: ReactNode;
  unloader?: (props: EnhanceImageProps) => ReactNode;
}

/**
 * 如果图片加载不了，就会走服务器代理（like notion)
 * 这个组件只适合给书签使用
 */
export const EnhanceImage: FC<EnhanceImageProps> = (props) => {
  const { loader, unloader, className: _className, ...rest } = props;
  const { imgUrl, onError, isProxyUrl, proxyLoading } = useEnhanceImage(props);
  const checkOss = isOssUrl(imgUrl) && imgUrl !== getBlankImage();
  const className = proxyLoading
    ? `${_className} rounded brightness-50 animate__animated animate__infinite animate__flash animate__slower`
    : _className;

  const omitArr: string[] = ['ownerBlockId', 'onError', 'src'];
  if (isProxyUrl.current || !checkOss) {
    omitArr.push(...['sizes', 'srcSet']);
  }

  const imageProps = {
    ...omit(rest, omitArr),
    className,
    referrerPolicy: checkOss ? 'strict-origin-when-cross-origin' : 'no-referrer',
    crossOrigin: checkOss ? 'anonymous' : undefined,
    src: proxyLoading ? getBlankImage() : imgUrl,
    onError,
  } as const;

  if ($searchParams.print) {
    return <img data-image {...imageProps} />;
  }

  if (loader && isProxyUrl.current && !proxyLoading) {
    return (
      <Img
        data-image
        {...imageProps}
        loader={<>{loader}</>}
        unloader={<>{unloader?.(props)}</>}
        loading="lazy"
      />
    );
  }

  return <img data-image {...imageProps} loading="lazy" />;
};
