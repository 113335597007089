import type { FC } from 'react';
import { Switch } from 'src/common/components/switch';

import { Option } from '../option';
import { SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';

export const LinkPage: FC = () => {
  const {
    spaceSettingState: { showBackLink, setShowBackLink },
  } = useModel(SettingProvider);
  return (
    <Option
      title="引用记录显示设置"
      description="当前页面被其他页面引用时，显示引用记录"
      rightPart={<Switch open={showBackLink} onSwitch={setShowBackLink} />}
    />
  );
};
