import type { IconName } from 'src/common/components/icon';

/**
 * @NONE 无任何传输任务
 * @TRANSFERRING 传输中
 * @SOME_FAIL 全部传输后有失败的
 * @ALL_SUCCESS 全部传输成功的
 * 不同状态,显示的状态icon不一样
 */
export enum TransferStatus {
  NONE,
  TRANSFERRING,
  SOME_FAIL,
  ALL_SUCCESS,
}

export const transferStatusIcons = {
  [TransferStatus.ALL_SUCCESS]: { name: 'IcSuccess' as IconName, color: '#3BD65D' },
  [TransferStatus.SOME_FAIL]: { name: 'IcError' as IconName, color: '#EB5757' },
  [TransferStatus.TRANSFERRING]: { name: 'IcLoading' as IconName, color: '#18A0FB' },
  [TransferStatus.NONE]: { name: '' as IconName, color: '#18A0FB' },
};
// 目前没啥用
export interface ExportTask {
  id: string;
  createdAt?: number;
  taskType: 'export';
}
// 目前没啥用
export interface ImportTask {
  id: string;
  createdAt?: number;
  taskType: 'import';
}
export interface UploadTask {
  id: string;
  createdAt?: number;
  taskType: 'upload';
}

// 只有 electron 有下载任务
export interface DownloadTask {
  id: string;
  createdAt?: number;
  taskType: 'download';
}
/** 传输任务,一定要有一个createdAt来排序 */
export type TransferTask = UploadTask | DownloadTask | ExportTask | ImportTask;
