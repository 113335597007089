import type { BlockDataDTO, CollectionSchema, SegmentDTO } from '@next-space/fe-api-idl';
import {
  BlockStatus,
  BlockType,
  CollectionSchemaType,
  PermissionRole,
  PermissionType,
  TextType,
} from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { batch } from 'react-redux';
import { message } from 'src/common/components/message';
import { removeAllDiscussionsFromSegments } from 'src/editor/utils/segments';
import { useTransaction } from 'src/hooks/use-transaction';
import { CREATE_BLOCK, LIST_AFTER_BLOCK, TRANSACTION_FIRE, UPDATE_BLOCK } from 'src/redux/actions';
import { removeBlock } from 'src/redux/managers/block/remove';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { cache, dispatch, getState } from 'src/redux/store';
import type { NextBlock, SelectBlock } from 'src/redux/types';
import { $appUiStateCache } from 'src/services/app';
import { getSpaceCapacity } from 'src/services/capacity';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { sequence } from 'src/utils/async-utils';
import { isPageLike } from 'src/utils/block-type-utils';
import { copySyncBlock } from 'src/utils/copy-utils';
import { idsToSelectedBlocks } from 'src/utils/select-block-util';
import { v4 as uuidV4 } from 'uuid';
import { getCurrentSpaceId } from '../space/get-space';
import { useOpenCapacityDialog } from '../space/use-open-capacity-dialog';
import { useCheckCopySubNodes, useCopySubNodesApi } from './use-copy-sub-nodes-api';

interface NewBlock {
  block: NextBlock;
  after?: string;
  uuid: string;
}

interface Options {
  effects?: (ids: SelectBlock[]) => void;
  parent?: string;
  after?: string;
  /** copy时是否添加前缀，例如 副本 */
  prefixName?: boolean;
  afterCopy?: () => void;
}

export const useCopyBlock = () => {
  const transaction = useTransaction();
  const checkCopyBlocks = useCheckCopyBlocks();
  const copySubNodesApi = useCopySubNodesApi();
  const checkCopySubNodes = useCheckCopySubNodes();

  const copyBlock = async (uuids: string[], options?: Options) => {
    checkIsMaximumCapacityDialog();
    const { effects, afterCopy } = options || {};

    const total: NewBlock[] = [];
    const seedUuids = new Set(uuids);

    await checkCopySubNodes({
      spaceId: getCurrentSpaceId(),
      ids: [...seedUuids],
    });

    if (!uuids.length) return total;

    if (!checkCopyBlocks(uuids)) {
      return total;
    }

    transaction(
      () => {
        batch(() => {
          total.push(...copyBlocks(uuids, options));

          const idMap = new Map();
          const simpleTables: NextBlock[] = [];
          for (const { uuid, block } of total) {
            idMap.set(uuid, block.uuid);
            if (block.type === BlockType.TABLE) {
              simpleTables.push(block);
            }
          }
          if (simpleTables.length) {
            simpleTables.forEach((block) => {
              const ranges = block.data.format?.tableBlockRanges;
              if (ranges) {
                const newRange = ranges.map((range) => {
                  const { start, end } = range;
                  return {
                    start: [idMap.get(start?.[0]), start?.[1]],
                    end: [idMap.get(end?.[0]), end?.[1]],
                  };
                });
                dispatch(
                  UPDATE_BLOCK({
                    uuid: block.uuid,
                    patch: { data: { format: { tableBlockRanges: newRange } } },
                  })
                );
              }
            });
          }

          const ids = total.filter((o) => uuids.includes(o.uuid)).map((o) => o.block.uuid);
          effects?.(idsToSelectedBlocks(ids));
        });
      },
      { noThrottle: true }
    );

    void sequence(async () => {
      const list = total
        .filter(({ block }) => block.type !== BlockType.COLLECTION_VIEW && isPageLike(block.type))
        .map(({ uuid, block }) => ({
          uuid,
          targetId: block.uuid,
          isDescendant: !seedUuids.has(uuid),
        }));
      // 如果有 page 块调复制接口
      if (list.length) {
        for (const item of list) {
          // 用 version: -1 标记此块需要等到服务器copy完之后才能更新version
          dispatch(UPDATE_BLOCK({ uuid: item.targetId, patch: { version: -1 } }));
        }
        const res = await copySubNodesApi({ list });

        afterCopy?.();

        // 如果复制接口失败需把其他创建的块也删除
        if (res.code !== 200) {
          total.forEach(({ block }) => removeBlock(block.uuid));
          dispatch(TRANSACTION_FIRE());
          message.error(res.msg);
        }
      }
    });

    return total;
  };

  return useCallback(copyBlock, [checkCopyBlocks, checkCopySubNodes, copySubNodesApi, transaction]);
};

export const copyBlocks = (uuids: string[], options?: Options) => {
  const { after, parent, prefixName = true } = options || {};
  const { blocks, collectionViews } = getState();

  const total: NewBlock[] = [];
  const pos = after || (uuids[uuids.length - 1] as string);
  const posBlock = blocks[pos];

  if (!posBlock) return total;

  uuids.reduce((pre, uuid) => {
    const block = blocks[uuid];
    if (!block) return pre;

    const newBlocks = copyNewBlocks(parent || posBlock.parentId, uuid, pre, prefixName);

    // pageSort 排序
    const newRecord = newBlocks[0];
    if (!newRecord || sortPage(uuid, pre, newRecord) === pre) return pre;

    newBlocks.forEach((b, i) => {
      if (i === 0) pre = b.block.uuid;
      total.push(b);

      if (b.block.parentId === b.block.spaceId) {
        const { permissions } = b.block;
        if (permissions.some((o) => o.type === PermissionType.SPACE)) {
          b.block.permissions = [
            {
              type: PermissionType.SPACE,
              role: PermissionRole.EDITOR,
            },
          ];
        } else {
          b.block.permissions = [
            {
              type: PermissionType.USER,
              role: PermissionRole.EDITOR,
              userId: $currentUserCache.uuid,
            },
          ];
        }
      } else {
        b.block.permissions = [];
      }

      if (
        b.block.type === BlockType.COLLECTION_VIEW ||
        b.block.type === BlockType.REFERENCE_COLLECTION
      ) {
        dispatch(CREATE_BLOCK({ block: { ...b.block, views: [] }, local: false }));

        const { views } = b.block;
        if (Array.isArray(views)) {
          views.forEach((viewId) => {
            const collectionView = collectionViews[viewId];
            if (!collectionView) return;
            // eslint-disable-next-line no-unused-vars
            const { pageSort, spaceId, parentId, uuid, shareId, ...rest } = collectionView;

            CollectionViewManager.add(
              {
                ...rest,
                shareId: shareId ? uuidV4() : undefined,
                pageSort: pageSort
                  .map((sortId) => {
                    const _block = newBlocks.find((o) => o.uuid === sortId);
                    return _block?.block.uuid;
                  })
                  .filter(Boolean) as string[],
              },
              {
                parentId: b.block.uuid,
                last: true,
              }
            );
          });
        }
      } else {
        // 拷贝时处理关联属性
        const { blocks } = getState();
        const originBlock = blocks[b.uuid];
        const originParent = blocks[originBlock?.parentId ?? ''];
        if (originParent?.type === BlockType.COLLECTION_VIEW) {
          const relationsPropertyIds: string[] = [];
          Object.entries(originParent.data.schema ?? {}).forEach(([key, propertySchema]) => {
            if (
              propertySchema.type === CollectionSchemaType.RELATION &&
              propertySchema.collectionId === originParent.uuid // 指向自己的关联属性拷贝时需修改关联记录的 id
            ) {
              relationsPropertyIds.push(key);
            }
          });

          const newCollectionProperties = { ...b.block.data.collectionProperties };
          relationsPropertyIds.forEach((propertyId) => {
            const oldSegments = newCollectionProperties[propertyId];
            if (!oldSegments) return;

            const newSegment: SegmentDTO[] = [];
            oldSegments.forEach((segment) => {
              if (
                segment.type === TextType.LINK_PAGE &&
                segment.uuid &&
                originParent.subNodes.includes(segment.uuid)
              ) {
                newSegment.push({
                  ...segment,
                  uuid: newBlocks.find((item) => item.uuid === segment.uuid)?.block.uuid,
                });
              } else {
                newSegment.push(segment);
              }
            });

            newCollectionProperties[propertyId] = newSegment;
          });

          b.block.data.collectionProperties = newCollectionProperties;
        }

        dispatch(CREATE_BLOCK({ block: b.block, local: false }));
      }

      if (b.block.isTemplate) {
        // NOTE: 这里要用 `getState().blocks` 因为可能 `b.block.parentId` 也是一个刚 copy 出来的 block。
        const parent = getState().blocks[b.block.parentId];
        if (parent != null) {
          const oldTemplatePages = parent.templatePages ?? [];
          const index = b.after ? oldTemplatePages.indexOf(b.after) + 1 : oldTemplatePages.length;
          const templatePages = oldTemplatePages.slice(0);
          templatePages.splice(index, 0, b.block.uuid);
          dispatch(
            UPDATE_BLOCK({
              uuid: parent.uuid,
              patch: {
                templatePages,
              },
            })
          );
        }
      } else if (b.after !== 'IS_INLINE_PAGE') {
        dispatch(
          LIST_AFTER_BLOCK({ uuid: b.block.uuid, parentId: b.block.parentId, after: b.after })
        );
      }
    });

    return pre;
  }, pos);

  return total;
};

const sortPage = (uuid: string, pre: string, newRecord: NewBlock) => {
  const { blocks, collectionViews } = cache;
  const block = blocks[uuid];
  if (!block) return pre;
  // 如果是模板记录页面，则不参与记录排序
  if (block.isTemplate) return;

  if (
    blocks[block.parentId]?.type === BlockType.COLLECTION_VIEW_PAGE ||
    blocks[block.parentId]?.type === BlockType.COLLECTION_VIEW
  ) {
    const blockNode = document.querySelector(`[data-block-id="${uuid}"]`);
    const viewId = (blockNode as HTMLElement).dataset.viewId ?? '';
    if (viewId) {
      const collection = blocks[block.parentId];
      if (!collection) return pre;

      const view = collectionViews[viewId];
      if (!view) return pre;

      const pageSort = [...view.pageSort];
      const index = view.pageSort.indexOf(pre);
      if (index > -1) {
        pageSort.splice(index + 1, 0, newRecord.block.uuid);
      } else {
        const sortedSet = new Set(pageSort);
        for (const id of collection.subNodes) {
          if (!sortedSet.has(id)) {
            pageSort.push(id);
          }
          if (id === pre) {
            pageSort.push(newRecord.block.uuid);
            break;
          }
        }
      }

      CollectionViewManager.update(viewId, {
        pageSort,
      });
    }
  }
};

const copyNewBlocks = (posParentId: string, uuid: string, after: string, prefixName: boolean) => {
  const { blocks } = getState();
  const user = $currentUserCache;
  const newBlocks: NewBlock[] = [];

  const idsMap: Record<string, string> = {};
  const loop = (parentId: string, fromId: string, after?: string) => {
    // 获取拷贝信息
    const from = blocks[fromId];
    if (!from) return;

    const newId = uuidV4();
    idsMap[fromId] = newId;

    // 处理 [页面] 添加 "副本" 字样
    const data = { ...from.data };
    // NOTE: 产品需求，不拷贝评论
    data.segments = removeAllDiscussionsFromSegments(data.segments);
    data.caption = removeAllDiscussionsFromSegments(data.caption);

    // 如果没设置过这个，需要设置默认值为true，弥补之前的漏缺
    if (typeof data?.pageFixedWidth !== 'boolean') {
      Object.assign(data, {
        pageFixedWidth: true,
      });
    }

    // inline bitable 里不加 `副本 `
    const fromParent = blocks[from.parentId];
    if (
      fromParent?.type !== BlockType.REFERENCE_COLLECTION &&
      fromParent?.type !== BlockType.REFERENCE_COLLECTION_PAGE &&
      (fromParent?.type !== BlockType.COLLECTION_VIEW || from.isTemplate) &&
      prefixName &&
      (isPageLike(from.type) || from.type === BlockType.FILE)
    ) {
      if (data.segments && data.segments.length > 0) {
        data.segments = [
          {
            text: '副本',
            type: TextType.TEXT,
            enhancer: {},
          },
          ...data.segments,
        ];
      }
    }

    if (from.type === BlockType.COLLECTION_VIEW || from.type === BlockType.COLLECTION_VIEW_PAGE) {
      const schema = data.schema ?? {};
      const newSchema: Record<string, CollectionSchema> = {};
      Object.entries(schema).forEach(([key, propertySchema]) => {
        if (propertySchema.type === CollectionSchemaType.RELATION) {
          if (propertySchema.collectionId === from.uuid) {
            newSchema[key] = { ...propertySchema, collectionId: newId }; // 指向自己的关联属性拷贝时需修改 collectionId)
          } else {
            newSchema[key] = { ...propertySchema, pairProperty: uuidV4() };
          }
        } else {
          newSchema[key] = { ...propertySchema };
        }
      });
      data.schema = newSchema;
    }

    const newBlock: NextBlock = {
      uuid: newId,
      parentId,
      version: 0,
      spaceId: from.spaceId,
      type: from.type,
      textColor: from.textColor,
      backgroundColor: from.backgroundColor,
      data,
      isTemplate: from.isTemplate,
      templatePages: undefined,
      status: BlockStatus.NORMAL,
      subNodes: [],
      createdBy: from.createdBy,
      createdAt: from.createdAt,
      // 仅调整副本的根节点的编辑人和编辑时间属性
      updatedBy: from.uuid === uuid ? user.uuid : from.updatedBy,
      updatedAt: from.uuid === uuid ? Date.now() : from.updatedAt,
      permissions: from.permissions,
      // NOTE: 产品需求，不拷贝评论
      discussions: undefined,
    };

    if (from.type === BlockType.COLLECTION_VIEW || from.type === BlockType.REFERENCE_COLLECTION) {
      newBlock.views = from.views; // 用于遍历创建新视图
    }
    // 防止copy文件夹后出现0项文件的bug以及复制page的时候subnode不存在会显示空的icon问题，所以subNodes也直接赋值过去。
    // 内嵌思维导图也一样,需要立刻复制
    if (
      from.type === BlockType.FOLDER ||
      from.type === BlockType.PAGE ||
      from.type === BlockType.MIND_MAPPING
    ) {
      newBlock.subNodes = from.subNodes;
      /** 内嵌思维导图subNodes需要本地创建新的块代替，否则更改原来的导图节点会导致新复制的会更新(数据仅本地有问题) */
      if (from.type === BlockType.MIND_MAPPING) {
        const newSubNodes = from.subNodes.map((subId) => {
          const newSubId = uuidV4();
          const subBlock = cache.blocks[subId];
          if (subBlock) {
            dispatch(CREATE_BLOCK({ block: { ...subBlock, uuid: newSubId }, local: true }));
            return newSubId;
          }
          return subId;
        });
        newBlock.subNodes = newSubNodes;
      }
    }
    // 复制同步块会变成影子块
    if (newBlock.type === BlockType.SYNC_CONTAINER) {
      copySyncBlock(newBlock, from.uuid);
    }

    // 记录需要新创建的 block
    newBlocks.push({
      block: newBlock,
      uuid: fromId,
      after,
    });
    // 继承折叠属性（用于折叠块/思维导图）
    if ($appUiStateCache.$expandFoldRecord[uuid] !== undefined) {
      patchExpand([newId], !!$appUiStateCache.$expandFoldRecord[uuid]);
    }
    if (from.type !== BlockType.COLLECTION_VIEW && isPageLike(from.type)) {
      return newId;
    }

    if (from.type === BlockType.SYNC_CONTAINER) {
      // 同步块的subNode不需要复制
      return newId;
    }

    if (from.templatePages != null) {
      for (const pageId of from.templatePages) {
        loop(newId, pageId);
      }
    }

    const inlinePages = collectInlinePage(fromId, data);

    const { subNodes } = from;
    let prevId: string | undefined;
    for (const curr of subNodes) {
      prefixName = false;
      prevId = loop(newId, curr, prevId);

      for (const _data of inlinePages) {
        if (_data.uuid === curr) {
          if (_data.type === 'segments' || _data.type === 'caption') {
            const segments = newBlock.data[_data.type];
            newBlock.data[_data.type] = segments?.map((o) =>
              o.uuid === _data.uuid ? { ...o, uuid: prevId } : o
            );
            inlinePages.delete(_data);
          }
        }
      }
    }

    handleInlinePage(inlinePages, newBlock, newBlocks);

    return newId;
  };

  loop(posParentId, uuid, after);

  newBlocks.forEach((item) => {
    if (item.block.type === BlockType.TABLE) {
      // 表格中有合并的处理
      const ranges = item.block.data.format?.tableBlockRanges ?? [];
      if (ranges && ranges.length > 0) {
        const newRange: {
          start?: string[] | undefined;
          end?: string[] | undefined;
        }[] = [];
        ranges.forEach((item) => {
          const { start, end } = item;
          if (!start || !end) return;

          const [rowId, startColumnId] = start;
          const [endRowId, endColumnId] = end;
          if (!rowId || !endRowId || !startColumnId || !endColumnId) return;

          newRange.push({
            start: [idsMap[rowId] ?? '', startColumnId],
            end: [idsMap[endRowId] ?? '', endColumnId],
          });
        });

        item.block.data = {
          ...item.block.data,
          format: {
            ...item.block.data.format,
            tableBlockRanges: newRange,
          },
        };
      }
    }
  });

  return newBlocks;
};

const useCheckCopyBlocks = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  return useCallback(
    (uuids: string[]) => {
      const { blocks } = getState();
      const totalSize = uuids.reduce((total, curBlockId) => {
        const block = blocks[curBlockId];
        if (block && block.type === BlockType.FILE && block.data.ossName) {
          return total + (block.data.size ?? 0);
        }
        return total;
      }, 0);

      if (totalSize > 0) {
        const capacityInfo = getSpaceCapacity(getCurrentSpaceId());
        if (capacityInfo.currentCapacity + totalSize > capacityInfo.maxCapacity) {
          openCapacityDialog({
            title: '复制块失败',
            maxCapacity: capacityInfo.maxCapacity,
            isNovice: capacityInfo.isNovice,
          });
          return false;
        }
      }
      return true;
    },
    [openCapacityDialog]
  );
};

interface InlinePage {
  type: keyof Pick<BlockDataDTO, 'segments' | 'caption' | 'collectionProperties'>;
  index: number;
  propertyId?: string;
  uuid: string;
}

const collectInlinePage = (fromId: string, data: BlockDataDTO) => {
  const pageSet: Set<InlinePage> = new Set();
  const pageIdSet: Set<string> = new Set();

  const getInlinePage = (
    type: InlinePage['type'],
    segments?: SegmentDTO[],
    propertyId?: string
  ) => {
    if (!segments) return;
    segments.forEach((segment, index) => {
      if (segment.type === TextType.LINK_PAGE && segment.uuid) {
        const _block = cache.blocks[segment.uuid];
        if (_block?.parentId === fromId) {
          const data: InlinePage = {
            type,
            index,
            uuid: segment.uuid,
            propertyId,
          };
          if (!pageIdSet.has(data.uuid)) {
            pageIdSet.add(data.uuid);
            pageSet.add(data);
          }
        }
      }
    });
  };

  getInlinePage('segments', data.segments);
  getInlinePage('caption', data.caption);
  Object.keys(data.collectionProperties ?? {}).forEach((key) =>
    getInlinePage('collectionProperties', data.collectionProperties?.[key], key)
  );

  return pageSet;
};

const handleInlinePage = (
  inlinePages: Set<InlinePage>,
  newBlock: NextBlock,
  newBlocks: NewBlock[]
) => {
  if (inlinePages.size > 0) {
    for (const data of inlinePages) {
      const _block = cache.blocks[data.uuid];
      if (_block) {
        const newPage = {
          ..._block,
          uuid: uuidV4(),
          parentId: newBlock.uuid,
          version: 0,
        };
        if (data.type === 'segments' || data.type === 'caption') {
          const segments = newBlock.data[data.type];
          newBlock.data[data.type] = segments?.map((o) =>
            o.uuid === data.uuid ? { ...o, uuid: newPage.uuid } : o
          );
        }
        if (data.type === 'collectionProperties' && data.propertyId) {
          const collectionProperties = newBlock.data[data.type];
          if (collectionProperties) {
            const segments = collectionProperties[data.propertyId];
            collectionProperties[data.propertyId] =
              segments?.map((o) => (o.uuid === data.uuid ? { ...o, uuid: newPage.uuid } : o)) ?? [];
          }
        }
        newBlocks.push({ block: newPage, uuid: data.uuid, after: 'IS_INLINE_PAGE' });
      }
    }
  }
};
