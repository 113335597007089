import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Input } from 'src/common/components/input';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { getState } from 'src/redux/store';
import { usePickBlock } from 'src/utils/pick-block';

interface FormatProps {
  zh: string;
  en: string;
  pingyin: string;
  symbol?: string;
  example?: string;
}

export const NumberFormat: FormatProps[] = [
  { zh: '数字', en: 'number', pingyin: 'shuzi', example: '123' },
  { zh: '整数', en: 'integer', pingyin: 'zhengshu', example: '123' },
  { zh: '一位小数', en: 'oneDecimal', pingyin: 'yiweixiaoshu', example: '1.0' },
  { zh: '两位小数', en: 'twoDecimal', pingyin: 'liangweixiaoshu', example: '1.00' },
  { zh: '千分位', en: 'thousandth', pingyin: 'qianfenwei', example: '1,000' },
  {
    zh: '百分数(一位小数)',
    en: 'percentOneDecimal',
    pingyin: 'baifenshuyiweixiaoshu',
    example: '1.0%',
  },
  { zh: '百分数(两位小数)', en: 'percent', pingyin: 'baifenshuliangweixiaoshu', example: '1.00%' },
  { zh: '人民币', en: 'rmb', pingyin: 'renminbi', symbol: 'CN¥', example: 'CN¥100.00' },
  { zh: '美元', en: 'dollar', pingyin: 'meiyuan', symbol: 'US$', example: 'US$100.00' },
  { zh: '日元', en: 'yen', pingyin: 'riyuan', symbol: 'JP¥', example: 'JP¥100.00' },
  { zh: '欧元', en: 'euro', pingyin: 'ouyuan', symbol: '€', example: '€100.00' },
  { zh: '卢布', en: 'rub', pingyin: 'lubu', symbol: '₽', example: '₽100.00' },
];

export const useOpenNumberFormat = () => {
  const { readonly } = useBitable();
  const openModal = useOpenModal();

  const openNumberFormat = (event: React.MouseEvent, propertyId: string) => {
    event.stopPropagation();
    if (readonly) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => (
        <SelectNumberFormat closeModal={onCloseModal} propertyId={propertyId} />
      ),
    });
  };

  return useCallback(openNumberFormat, [openModal, readonly]);
};
interface Props {
  closeModal?: () => void;
  propertyId: string;
}
export const SelectNumberFormat: FC<Props> = (props) => {
  const { closeModal, propertyId } = props;
  const { collectionId } = useBitable();
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const updatePropertySchema = useUpdatePropertySchema();

  const collection = usePickBlock(collectionId, ['data'], ['schema']);
  const { numberFormat } = collection?.data.schema?.[propertyId] ?? {};

  const items: ListItem<any>[] = [];
  const regExp = new RegExp(keyword, 'i');

  NumberFormat.forEach((item, index) => {
    const str = item.en + item.zh + item.pingyin;
    if (index === 7) {
      items.push({
        type: ListItemType.LINE,
      });
    }

    if (str.search(regExp) > -1) {
      items.push({
        type: ListItemType.OPERABLE_BLOCK_TEXT,
        data: {
          title: item.zh,
          followTitle: true,
          selected: numberFormat === item.en,
          desc: item.example,
          ...item,
        },
      });
    }
  });

  return (
    <div className="w-[220px] py-2 next-modal">
      <Input
        autoFocus
        inputRef={inputRef}
        placeholder="搜索格式"
        className="h-8 flex-1 border-grey6 rounded-sm m-2"
        inputClassName="px-2"
        onEnter={() => closeModal?.()}
        onChange={(text) => setKeyword(text)}
      />
      <ListView
        className="overflow-auto max-h-120"
        items={items}
        onItemClick={(item) => {
          closeModal?.();
          const { blocks } = getState();
          const { numberFormat } = blocks[collectionId]?.data.schema?.[propertyId] ?? {};

          if (numberFormat === item.data.title) return;
          updatePropertySchema(
            collectionId,
            propertyId,
            {
              numberFormat: item.data.en,
            },
            {
              needTransaction: true,
              isUpdateNumberFormat: true,
            }
          );
        }}
      />
    </div>
  );
};
