import { BlockType } from '@next-space/fe-api-idl';
import { contentToString, splitContent } from '@next-space/fe-inlined';
import { dropRight, last } from 'lodash-es';
import { useCallback } from 'react';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { findLanguage } from 'src/editor/editor/plugin/code/codemirror-utils';
import { DEFAULT_CODE_LANGUAGE } from 'src/editor/editor/plugin/code/const';
import { getNextOrderListNumber, romanNumerals } from 'src/editor/editor/plugin/order-list-helper';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { getInitBlockParams } from 'src/editor/utils/block';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { convertBlock } from 'src/redux/managers/block/convert';
import { removeBlock } from 'src/redux/managers/block/remove';
import { updateBlock } from 'src/redux/managers/block/update';
import { dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { getLastCodeLanguage, getPreBlock } from 'src/utils/block-utils';
import { v4 as uuid4 } from 'uuid';
import { useTransaction } from '../use-transaction';
import { AIEditorFrom, AIEditorScene } from 'src/editor/editor/uikit/ai-editor/const';
import { checkPageIsEmpty } from 'src/editor/component/menu-list/page-is-empty';
import { useEnableAI } from '../block/use-enable-AI';

// 因为 useSpaceKey 在 editable 组件中调用了两次，导致 AI 弹窗两次，这里屏蔽其中一处入口
export const useSpaceKey = (uuid: string, disableAI?: boolean) => {
  const transaction = useTransaction();
  const getEditorModel = useGetOrInitEditorModel();
  const focusEditableAt = useFocusEditableByBlockId();
  const openAIEditor = useOpenAIEditor();
  const { enableAI } = useEnableAI();
  const spaceKey = (event: Event, input?: string) => {
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    const editorModel = getEditorModel(uuid);
    if (editorModel === undefined) return;

    const { content, selection } = editorModel;
    if (!selection || !selection.isCollapsed) return;

    const { offset } = selection;

    const [left, right] = splitContent(content, offset);
    const beforeSegments = convertContentToSegments(left);
    const afterSegments = convertContentToSegments(right);
    const trigger = contentToString(left);

    const switchType = (type: BlockType, data?: Partial<NextBlock['data']>) => {
      if (type === block.type) {
        if (type !== BlockType.HEADER) return;
        if (data?.level === block.data.level) return;
      }

      event.preventDefault();

      const segment = last(beforeSegments);
      if (!segment) return;

      // 派发 2 个事务, 以满足撤销逻辑
      updateBlock(block.uuid, {
        data: {
          segments: [
            ...dropRight(beforeSegments),
            {
              ...segment,
              text: `${segment.text}${input}`,
            },
            ...afterSegments,
          ],
        },
      });

      let initParams: Partial<NextBlock> = {};

      if (type === BlockType.MARK) {
        if (!block.textColor && !block.backgroundColor) {
          // 产品提的: 着重 block 的 feature
          // 如果原来的 block 没有颜色值，就用上一次着重 block 用过的
          initParams = getInitBlockParams(BlockType.MARK);
        }
      }

      // 公式块需要打开
      if ([BlockType.EQUATION].includes(type)) {
        initParams.local = true;
      }

      transaction(() => {
        convertBlock([block.uuid], {
          type,
          ...initParams,
          data: {
            ...initParams.data,
            ...data,
            segments: afterSegments,
          },
        });
        dispatch(
          HISTORY_EFFECTS({
            init() {
              focusEditableAt(block.uuid, 0);
            },
            undo() {
              focusEditableAt(block.uuid, offset + 1);
            },
            redo() {
              focusEditableAt(block.uuid, 0);
            },
          })
        );
      });
    };

    if (trigger.match(/^#{1,4}$/)) {
      const level = trigger.length;
      if (block.type !== BlockType.HEADER || block.data.level !== level) {
        switchType(
          block.type === BlockType.FOLD_LIST ? BlockType.TOGGLE_HEADER : BlockType.HEADER,
          { level }
        );
        return;
      }
    }

    if (['-', '*'].includes(trigger)) {
      switchType(BlockType.LIST);
      return;
    }

    if (['+'].includes(trigger)) {
      if (block.type === BlockType.HEADER) {
        switchType(BlockType.TOGGLE_HEADER, {
          level: block.data.level,
        });
      } else {
        switchType(BlockType.FOLD_LIST);
      }
      return;
    }

    if (['>', '》', '｜', '|', `'`, `"`, `“`].includes(trigger)) {
      switchType(BlockType.QUOTE);
      return;
    }

    if (['!!', '！！'].includes(trigger)) {
      switchType(BlockType.MARK);
      return;
    }

    if (['￥￥', '$$'].includes(trigger)) {
      switchType(BlockType.EQUATION);
      return;
    }

    const verification = /^(```|···)/.test(trigger);
    if (verification && !afterSegments.length && !/(\s|\d)/.test(trigger.slice(3))) {
      const inputLanguage = findLanguage(trigger.slice(3).trim());
      const newBlockId = uuid4();
      // NOTE: 由于代码块的EditorModel是Mock的，
      // 代码块和富文本不能用同样的uuid，这里不能直接使用switchType
      transaction(() => {
        addBlock(
          {
            uuid: newBlockId,
            type: BlockType.CODE,
            data: {
              format: {
                language: inputLanguage || getLastCodeLanguage() || DEFAULT_CODE_LANGUAGE,
              },
            },
          },
          { parentId: block.parentId, after: block.uuid }
        );
        removeBlock(block.uuid);
        dispatch(
          HISTORY_EFFECTS({
            init() {
              setTimeout(() => focusEditableAt(`${newBlockId}_mock`, 0), 200);
            },
            undo() {
              focusEditableAt(block.uuid, offset);
            },
            redo() {
              setTimeout(() => focusEditableAt(`${newBlockId}_mock`, 0), 200);
            },
          })
        );
      });
      return;
    }
    const isNumberTrigger = Boolean(trigger.match(/^[\d]+(\.|。)$/));
    const isLetterTrigger = !isNumberTrigger && Boolean(trigger.match(/^[A-Za-z]+(\.|。)$/));
    // fix bug: https://flowus.cn/dfd6fe3c-3bd6-449c-ae0d-c30c9be0a0a4
    const isRomanTrigger =
      (trigger.lastIndexOf('.') || trigger.lastIndexOf('。')) &&
      romanNumerals.includes(trigger.substring(0, trigger.length - 1));
    const orderListFormat = isLetterTrigger ? 'letters' : isRomanTrigger ? 'roman' : undefined;
    if (isLetterTrigger || isNumberTrigger) {
      const parent = blocks[block.parentId];
      if (!parent) return;
      const preBlock = getPreBlock(uuid);
      // 如果前一个节点不是序号block，如果是字母a.或者数字的话可以直接创建一个(b. c.不允许创建)
      const isOrderListBlock = preBlock?.type === BlockType.ORDER_LIST;
      if (!isOrderListBlock) {
        if (isNumberTrigger || trigger === 'a.' || trigger === 'a。' || isRomanTrigger) {
          // 当前块是HEADER时,输入1. a.不转化块
          if (block.type === BlockType.HEADER) return;

          const listStartIndex = isLetterTrigger ? 1 : parseInt(trigger, 10);
          switchType(BlockType.ORDER_LIST, {
            format: {
              listStartIndex,
              orderListFormat,
            },
          });
        }
      } else {
        const listNo = getNextOrderListNumber(preBlock.uuid, blocks);
        if (trigger === `${listNo}.` || trigger === `${listNo}。`) {
          // 跟notion的差异: 如果1.在非首位，则直接创建一个新的序号block，跟用/创建效果一样
          switchType(BlockType.ORDER_LIST, {
            format: { orderListFormat: preBlock.data.format?.orderListFormat || orderListFormat },
          });
        }
      }
      return;
    }

    const str = contentToString(content);
    if (enableAI && !disableAI && !str.trim()) {
      event.preventDefault();
      const pageIsEmpty = checkPageIsEmpty(block.parentId, uuid);
      const popcorn = (editorModel as any).editorElement;
      if (!popcorn) return;

      openAIEditor({
        popcorn,
        blockId: uuid,
        editorScene: pageIsEmpty ? AIEditorScene.PageEmpty : AIEditorScene.PageHasContent,
        selection: { start: selection.offset ?? 0 },
        from: AIEditorFrom.ContextMenu,
      });
    }
  };

  return useCallback(spaceKey, [
    disableAI,
    enableAI,
    focusEditableAt,
    getEditorModel,
    openAIEditor,
    transaction,
    uuid,
  ]);
};
