import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import { memo, useMemo, useRef } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useLastWidthBeforeEditing } from '../hook/use-last-width-before-editing';
import { useMindNodePadding } from '../hook/use-mind-node-style';
import { getDefaultPlaceHolder } from '../utils/mind-node-util';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

export const QuoteNode: MindNodeElement = memo((props) => {
  const block = usePickBlock(props.id, ['data'], ['segments']);
  const placeHolder = getDefaultPlaceHolder(props.level);
  const updateSegments = useThrottleUpdateSegments(props.id);

  const isEmpty = useMemo(() => {
    return !segmentsToText(block?.data.segments);
  }, [block?.data.segments]);

  const containerRef = useRef<HTMLDivElement>(null);
  const lastWidth = useLastWidthBeforeEditing(props.id, containerRef, isEmpty, props.level);
  const { paddingLeft, paddingRight } = useMindNodePadding(props.id, props.level);

  if (!block) return null;

  return (
    <NodeWrapper
      id={props.id}
      ref={containerRef}
      level={props.level}
      style={{
        paddingLeft: `${paddingLeft}px`,
        minWidth: lastWidth,
      }}
    >
      <div className="pl-2 border-l-4 border-solid border-current">
        <MindMapRichText
          placeholder={placeHolder}
          uuid={props.id}
          className="whitespace-pre-wrap break-words"
          segments={block.data.segments}
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
          style={{
            paddingRight: `${paddingRight}px`,
          }}
        />
      </div>
    </NodeWrapper>
  );
});
