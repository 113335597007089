import { cx } from '@flowus/common/cx';
import { ResizeStatus, useResizeListener } from '@flowus/common/hooks/use-resize-listener';
import { useSize } from 'ahooks';
// import { useSize } from 'ahooks';
import type { FC } from 'react';
import { useRef } from 'react';

interface DragProgressbarProps {
  scale: number;
  onZoom: (scale: number) => void;
  /** step is not working */
  range: { min: number; max: number; step?: number };
  className?: string;
}

export const DragProgressbar: FC<DragProgressbarProps> = ({ scale, onZoom, range, className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);
  const size = useSize(progressRef);
  const progressWidth = size?.width ?? 0;
  const progress = (scale * progressWidth) / range.max;

  useResizeListener(ref, {
    onResize: (result) => {
      if (progressWidth === 0) return;
      if (result.resizeStatus === ResizeStatus.RESIZING) {
        let scale = ((result.deltaX + progress) * range.max) / progressWidth;
        if (scale > range.max) {
          return;
        }
        if (scale < range.min) {
          return;
        }
        scale = Math.round(scale);
        onZoom(scale);
      }
    },
    type: 'x',
  });

  return (
    <div
      ref={progressRef}
      className={cx('w-full flex relative items-center cursor-pointer p-2', className)}
      data-ignore-double-click
      onClick={(e) => {
        const rect = progressRef.current?.getBoundingClientRect();
        if (!rect) return;
        let scale = (range.max * (e.clientX - rect.left)) / rect.width;
        if (scale > range.max) {
          return;
        }
        if (scale < range.min) {
          return;
        }
        scale = Math.round(scale);
        onZoom(scale);
      }}
    >
      {/* background */}
      <div className="w-full h-[3px] rounded absolute bg-grey7 " />
      {/* progress */}
      <div
        className="h-[3px] rounded absolute bg-active_color "
        style={{
          width: progress,
        }}
      />
      <div
        ref={ref}
        className="absolute bg-white w-[18px] h-[18px] rounded-full cursor-pointer"
        style={{
          left: progress - 4,
          border: '1px solid rgba(0, 0, 0, 0.1)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      ></div>
    </div>
  );
};
