export class MediaQuery {
  private queryList?: MediaQueryList;

  constructor(query = '(prefers-color-scheme: dark)') {
    this.queryList = window.matchMedia(query);
  }

  get media() {
    return this.queryList?.media ?? '';
  }

  get matches() {
    return !!this.queryList?.matches;
  }

  onChange = (callback: (event: MediaQueryListEvent) => void) => {
    if (typeof this.queryList?.addEventListener === 'function') {
      this.queryList.addEventListener('change', callback);
    } else if (typeof this.queryList?.addListener === 'function') {
      this.queryList.addListener(callback);
    }

    return () => this.offChange(callback);
  };

  offChange = (callback: (event: MediaQueryListEvent) => void) => {
    if (typeof this.queryList?.removeEventListener === 'function') {
      this.queryList.removeEventListener('change', callback);
    } else if (typeof this.queryList?.removeListener === 'function') {
      this.queryList.removeListener(callback);
    }
  };
}
