import { useCallback } from 'react';
import type { DatabaseInfo } from './type';
import { getState } from 'src/redux/store';
import * as _ from 'lodash-es';
import { getFilterConditions } from '../bitable-manager/config-table-filter';
import {
  fixGet,
  getAvailableOperators,
  isFilterValid,
  pruneFilter,
} from '../table-view/body/filters';
import type { CollectionFilter } from '@next-space/fe-api-idl/lib/models/CollectionFilter';
import type { CollectionFilterGroup } from '@next-space/fe-api-idl/lib/models/CollectionFilterGroup';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';

export const useUpdateFilter = () => {
  return useCallback(
    (
      databaseInfo: DatabaseInfo,
      propertyId: string,
      managerReadonly: boolean | undefined,
      path: (string | number)[],
      group?: boolean
    ) => {
      const { viewId, collectionId } = databaseInfo;
      const view = getState().collectionViews?.[viewId];
      const collection = getState().blocks?.[collectionId];
      if (!view) return;
      const schema = collection?.data.schema;
      if (!schema) return;
      if (!view.format.filter) {
        return;
      }
      let candidateProperty = propertyId;
      const filters = getFilterConditions(view.format.filter.filters, schema);
      if (filters.length > 0) {
        const lastFilter = filters[filters.length - 1];
        if (lastFilter?.type === 'filter' && isFilterValid(lastFilter, schema)) {
          candidateProperty = lastFilter.property;
        }
      }
      const avaiableOperators = getAvailableOperators(collectionId, candidateProperty);
      const filter0: CollectionFilter = {
        type: 'filter',
        property: candidateProperty,
        propertyType: schema[candidateProperty]?.type,
        operator: avaiableOperators[0]?.value ?? 'isNotEmpty',
        value: '',
      };
      const filter: CollectionFilter | CollectionFilterGroup = group
        ? {
            type: 'group',
            operator: 'and',
            filters: [filter0],
          }
        : filter0;

      const newFilter = _.cloneDeep(view.format.filter);
      const filterGroup = fixGet(newFilter, path) as CollectionFilterGroup | undefined;
      filterGroup?.filters.push(filter);
      if (newFilter) {
        updateViewFormat(viewId, { filter: pruneFilter(newFilter, schema) }, managerReadonly);
      }
    },
    []
  );
};
