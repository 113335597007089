import { last } from 'lodash-es';

/** 通过ossName获取到FileName */
export const ossNameToFileNameInfo = (ossName = '') => {
  const fileName = last(ossName.split('/'));
  return {
    fileName,
    ...getFileNameInfo(fileName),
  };
};

/** 获取文件名和后缀名 */
export const getFileNameInfo = (fileName = '') => {
  const index = fileName.lastIndexOf('.');
  const name = fileName.substring(0, index);
  let extName = fileName.substring(index + 1);
  const extNameMatch = extName.match(/\w+/);

  if (extNameMatch?.[0]) {
    extName = extNameMatch[0] as string;
  }

  return {
    name,
    extName,
  };
};
