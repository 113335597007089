import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import { useDrag } from '@use-gesture/react';
import { useScroll, useThrottleEffect } from 'ahooks';
import { findLast, first, last, omit } from 'lodash-es';
import type { FC, MutableRefObject } from 'react';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useIsExistModalId, useOpenModal } from 'src/common/components/next-modal';
import { TransitionBox } from 'src/common/components/transition-box';
import { segmentsToText } from 'src/editor/utils/editor';
import { useReadonly } from 'src/hooks/page';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { StoreContextProvider } from 'src/services/store-context/provider';
import {
  getElementToBodyDistance,
  getElementToElementDistance,
  querySelectorFromMainContent,
} from 'src/utils/dom';
import { getLocalObject, setLocalStorage } from 'src/utils/local-storage';
import { useIsInRight } from 'src/utils/right-utils';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { useIsLiteSize, usePageHeight, useScrollRef } from '../context';
import { useJumpTop } from '../use-jump-top';
import { elementScrollTo } from './helper';
import { RenderItem } from './item';
import { More } from './more';
import { directoryDescendantsSelector } from './selector';
import type { HeaderItem } from './type';
import { DIRECTORY_DEFAULT_WIDTH, DIRECTORY_MAX_WIDTH, DIRECTORY_MIN_WIDTH } from './type';

const PADDING_NUM = 15;
const DIRECTORY_MENU_WIDTHS = 'DIRECTORY_MENU_WIDTHS';
const DIRECTORY_MENU_CLOSED = 'DIRECTORY_MENU_CLOSED';
const DIRECTORY_MENU_MORE_BTN_ID = 'DIRECTORY_MENU_MORE_BTN_ID';

interface Props {
  uuid: string;
}

type DirectoryMenuContentProps = Props & {
  isLiteSize: boolean;
  fixed?: boolean;
};

/** 目录 */
const DirectoryMenuContent: FC<DirectoryMenuContentProps> = memo((props) => {
  const { uuid, isLiteSize, fixed } = props;
  const [headerBlockDomList, setHeaderBlockDomList] = useState<{ id: string; top: number }[]>([]);
  const isInRight = useIsInRight();
  const pageHeaderHeight = useDebounceElementSize(
    querySelectorFromMainContent('.page-header', isInRight),
    { type: 'height' }
  );
  const scrollContainer = useScrollRef();
  const pageHeight = usePageHeight();
  const pageScroll = useScroll(scrollContainer);
  // 手动点击目录的时候，不让目录树自滚动
  const animateScroll = useRef(false);
  const jumpTop0 = useJumpTop();
  const moreBtnRef = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();
  const [closed, setClosed] = useState<string[]>(getLocalObject(DIRECTORY_MENU_CLOSED)[uuid] ?? []);
  const isReadonly = useReadonly(uuid);

  const jumpTop = useCallback(
    (blockId: string) => {
      animateScroll.current = true;

      return jumpTop0(blockId, {
        callback: (type) => {
          if (type === 'animateScrollStart') {
            animateScroll.current = true;
          }

          if (type === 'animateScrollEnd') {
            animateScroll.current = false;
          }

          if (type === 'animateStart' || type === 'animateScrollStart') {
            setActiveBlock(blockId);
          }
        },
      });
    },
    [jumpTop0]
  );

  // 当前文档中的header block
  const headerBlocks = useObservableStore(
    (state) => directoryDescendantsSelector(state.blocks, uuid),
    [uuid],
    { wait: 500, waitMode: 'debounce', ignoreOtherData: false }
  );

  // 高亮目录
  const [activeBlock, setActiveBlock] = useState('');
  // 当前容器
  const directoryScroll = useRef() as MutableRefObject<HTMLDivElement>;

  // 目录列表
  const menuTree = useMemo(() => {
    const result: HeaderItem[] = [];

    Object.values(headerBlocks).forEach((item) => {
      let paddingLeft = 0;
      let deep = 0;
      let parentId = '';

      const level = item.data.level ?? 1;
      /** 上一个heading */
      const beforeItem = last(result);

      if (beforeItem) {
        // 等于或小于上一个，就持平或收缩
        if (level >= beforeItem.level) {
          if (level === beforeItem.level) {
            parentId = beforeItem.parentId;
            paddingLeft = beforeItem.paddingLeft;
            deep = beforeItem.deep;
          } else {
            parentId = beforeItem.uuid;
            paddingLeft = beforeItem.paddingLeft + PADDING_NUM;
            deep = beforeItem.deep + 1;
          }
          // 大于上一个的时候，就找之前有没有同样等级的，和同样等级的收缩持平
        } else {
          const greater = findLast(result, (i) => i.level < level);
          const equal = findLast(result, (i) => i.level === level);
          const preItem = greater || equal;

          if (preItem) {
            if (preItem.level < level) {
              paddingLeft = preItem.paddingLeft + PADDING_NUM;
              deep = preItem.deep + 1;
              parentId = preItem.uuid;
            } else {
              paddingLeft = preItem.paddingLeft;
              deep = preItem.deep;
              parentId = preItem.parentId;
            }
          }
        }
      }
      let title = '标题';
      if (item.type === BlockType.TOGGLE_HEADER) {
        title = '折叠标题';
      }
      result.push({
        parentId,
        uuid: item.uuid,
        level,
        deep,
        paddingLeft,
        text: segmentsToText(item.data.segments),
        subNodes: [],
        active: false,
        closed: false,
        subCount: 0,
        title,
      });
    });

    // 统计子节点
    result.forEach((item) => {
      const set = new Set();

      const loop = (pId: string) => {
        if (set.has(pId)) return;
        set.add(pId);

        result.forEach((_item) => {
          if (_item.parentId === pId) {
            item.subNodes.push(_item.uuid);
          }
        });

        item.subNodes.forEach(loop);
      };

      loop(item.uuid);
    });

    return result;
  }, [headerBlocks]);

  const refreshDomList = useCallback(() => {
    const result: typeof headerBlockDomList = [];
    menuTree.forEach((item) => {
      const n = querySelectorFromMainContent(
        `.next-space-page-content [data-block-id="${item.uuid}"]`,
        isInRight
      ) as HTMLElement;
      if (!n) return;
      const top = getElementToBodyDistance(n)?.top;
      if (!top) return;
      result.push({ id: item.uuid, top });
    });
    result.sort((a, b) => a.top - b.top);
    setHeaderBlockDomList(result);
  }, [isInRight, menuTree]);

  useThrottleEffect(
    () => {
      refreshDomList();
    },
    [pageHeight, menuTree, isInRight, pageScroll],
    { wait: 1000 }
  );

  // 监听滚动，高亮右侧目录
  useThrottleEffect(
    () => {
      // 手动跳转的动画还在进行中，不需要自动滚动
      if (animateScroll.current || !pageScroll?.top) return;
      const pageScrollTop = pageScroll.top + 100;
      const firstChild = first(headerBlockDomList);

      if (firstChild && firstChild.top > pageScrollTop && firstChild.id !== activeBlock) {
        setActiveBlock(firstChild.id);
        directoryScrollTo(firstChild.id);
        return;
      }

      for (let i = 0; i < headerBlockDomList.length; i++) {
        const item = headerBlockDomList[i];
        const nextItem = headerBlockDomList[i + 1];
        if (item && item.top <= pageScrollTop) {
          if ((!nextItem || nextItem.top > pageScrollTop) && item.id !== activeBlock) {
            setActiveBlock(item.id);
            let newActiveBlock = item.id;
            menuTree.forEach((item) => {
              if (closed.includes(item.uuid)) {
                item.subNodes.forEach((subId) => {
                  if (subId === newActiveBlock) {
                    newActiveBlock = item.uuid;
                  }
                });
              }
            });
            directoryScrollTo(newActiveBlock);
            return;
          }
        }
      }
    },
    [pageScroll?.top, headerBlockDomList, pageHeight, closed, menuTree, activeBlock, isInRight],
    { wait: 500, trailing: false, leading: true }
  );

  const directoryScrollTo = (blockId: string) => {
    const node = querySelectorFromMainContent(
      `.directory-scroll-container [data-menu-id="${blockId}"]`,
      isInRight
    ) as HTMLElement;

    const directoryScrollContainer = querySelectorFromMainContent(
      '.directory-scroll-container',
      isInRight
    ) as HTMLElement;

    elementScrollTo(
      directoryScroll.current,
      getElementToElementDistance(node, directoryScrollContainer).top - 54
    );
  };

  const isShowMenuTree = menuTree.length !== 0;

  /** 过滤掉折叠的目录，添加 折叠 和 高亮 属性 */
  const menuList = useMemo(() => {
    if (!closed.length) return menuTree.map((o) => ({ ...o, active: o.uuid === activeBlock }));
    let newActiveBlock = activeBlock;
    const hideList = new Set<string>();
    menuTree.forEach((item) => {
      if (closed.includes(item.uuid)) {
        item.subNodes.forEach((subId) => {
          if (subId === newActiveBlock) {
            newActiveBlock = item.uuid;
          }
          hideList.add(subId);
        });
      }
    });
    return menuTree
      .filter((item) => !hideList.has(item.uuid))
      .map((o) => ({ ...o, closed: closed.includes(o.uuid), active: o.uuid === newActiveBlock }));
  }, [menuTree, closed, activeBlock]);

  const expandAll = useCallback(() => {
    setClosed([]);
    const { [uuid]: _, ...rest } = getLocalObject(DIRECTORY_MENU_CLOSED);
    setLocalStorage(DIRECTORY_MENU_CLOSED, JSON.stringify(rest));
  }, [uuid]);

  const closeAll = useCallback(() => {
    const _closed = menuList.filter((o) => o.subNodes.length).map((o) => o.uuid);
    setClosed(_closed);
    setLocalStorage(
      DIRECTORY_MENU_CLOSED,
      JSON.stringify({ ...getLocalObject(DIRECTORY_MENU_CLOSED), [uuid]: _closed })
    );
  }, [menuList, uuid]);

  const onClickMore = useCallback(() => {
    if (!moreBtnRef.current) return;

    openModal.dropdown({
      modalId: DIRECTORY_MENU_MORE_BTN_ID,
      popcorn: elementToGetBoundingClientRect(moreBtnRef.current),
      placement: 'bottom-end',
      offset: [0, 4],
      mask: false,
      content({ onCloseModal }) {
        return (
          <More
            uuid={uuid}
            onCloseModal={onCloseModal}
            closeAll={closeAll}
            expandAll={expandAll}
            isInRight={isInRight}
            isReadonly={isReadonly}
          />
        );
      },
    });
  }, [closeAll, expandAll, isInRight, isReadonly, openModal, uuid]);

  const onToggle = useCallback(
    (id: string) => {
      setClosed((prev) => {
        const set = new Set(prev);
        if (set.has(id)) {
          set.delete(id);
        } else {
          set.add(id);
        }
        const _closed = [...set];
        setLocalStorage(
          DIRECTORY_MENU_CLOSED,
          JSON.stringify({ ...getLocalObject(DIRECTORY_MENU_CLOSED), [uuid]: _closed })
        );
        return _closed;
      });
    },
    [uuid]
  );

  if (fixed && isLiteSize && menuList.length === 0) return null;

  return (
    <>
      {!isLiteSize && (
        <div className="group flex items-center justify-between px-2 pl-3 py-1">
          <div className="text-t2-medium text-grey3 py-2">目录</div>
          <div
            ref={moreBtnRef}
            onClick={onClickMore}
            className="p-1 animate-hover opacity-0 group-hover:opacity-100"
          >
            <Icon size="middle" name="IcMore" className="text-grey3" />
          </div>
        </div>
      )}
      <div
        ref={directoryScroll}
        className={cx(
          'text-t2 transition-all overflow-x-hidden overflow-y-auto block overscroll-y-contain px-2 space-y-1',
          isLiteSize && 'hidden-scrollbar'
        )}
        style={{
          paddingBottom: pageHeaderHeight.height + 96,
          maxHeight: (scrollContainer?.current?.getBoundingClientRect().height ?? 0) - 44 - 24,
        }}
      >
        {isShowMenuTree
          ? menuList.map((item) => (
              <RenderItem
                onToggle={onToggle}
                isLiteSize={isLiteSize}
                // 不要subnodes，会memo失效
                {...omit(item, 'subNodes')}
                subCount={item.subNodes.length}
                key={item.uuid}
                jumpTop={jumpTop}
              />
            ))
          : !isLiteSize && (
              <div className="flex items-center whitespace-nowrap h-10 pointer-events-none text-grey4 text-t2-medium">
                请添加标题生成目录
              </div>
            )}
      </div>
    </>
  );
});

export const DirectoryMenu: FC<Props> = memo((props: Props) => {
  const { uuid } = props;
  const isLiteSize = useIsLiteSize();
  const isDragging = useIsDragging();
  const [width, setWidth] = useState(
    getLocalObject(DIRECTORY_MENU_WIDTHS)[uuid] ?? DIRECTORY_DEFAULT_WIDTH
  );
  const isOpen = useIsExistModalId(DIRECTORY_MENU_MORE_BTN_ID);

  const startRef = useRef(0);

  const renderNormalContent = useMemo(() => {
    return <DirectoryMenuContent {...props} isLiteSize={false} fixed />;
  }, [props]);

  const renderLiteContent = useMemo(() => {
    if (!isLiteSize) return null;
    return <DirectoryMenuContent {...props} isLiteSize />;
  }, [isLiteSize, props]);

  const moveResize = (_width: number) => {
    setWidth(_width);
    setLocalStorage(
      DIRECTORY_MENU_WIDTHS,
      JSON.stringify({ ...getLocalObject(DIRECTORY_MENU_WIDTHS), [uuid]: _width })
    );
  };

  const bind = useDrag(
    ({ offset: [mx] }) => {
      // 最宽 400px，最窄 200px
      const _width = Math.floor(
        Math.max(Math.min(2 * startRef.current - mx, DIRECTORY_MAX_WIDTH), DIRECTORY_MIN_WIDTH)
      );
      moveResize(_width);
    },
    { from: width, preventDefault: true }
  );

  return (
    <StoreContextProvider wait={800} waitMode="debounce">
      <div className="select-none cursor-default">
        <TransitionBox
          config={{ duration: 100 }}
          className={cx('line-default ml-10 mt-6 h-full', {
            '!pl-3 pr-0 ml-0': isLiteSize,
            'flex-shrink-0 max-w-[calc(100%-40px)] min-w-[200px]': !isLiteSize,
          })}
          style={{ width: isLiteSize ? 'auto' : width }}
        >
          <div className="h-full relative z-50 group">
            <div
              className={cx(
                'directory-scroll-container sticky left-0 top-0',
                !isLiteSize && 'pb-2.5 pl-3.5'
              )}
            >
              {!isLiteSize && (
                <div
                  {...bind()}
                  onMouseEnter={() => {
                    startRef.current = width;
                  }}
                  onClick={() => {
                    startRef.current = width;
                  }}
                  onDoubleClick={() => {
                    moveResize(DIRECTORY_DEFAULT_WIDTH);
                  }}
                  className="touch-none group absolute top-0 -left-1 w-3 h-full flex justify-center hover:cursor-col-resize"
                >
                  <div className="w-[3px] h-full bg-grey9 group-hover:bg-grey6" />
                </div>
              )}
              {isLiteSize ? renderLiteContent : renderNormalContent}
              {isLiteSize && !isDragging && (
                <div
                  className={cx(
                    'bg-aside w-52 rounded duration-200 transition-all absolute top-0 right-0 translate-x-[200%] group-hover:pointer-events-auto pointer-events-none group-hover:translate-x-0 group-hover:shadow-modal group-hover:delay-150 delay-300',
                    isOpen && 'translate-x-0'
                  )}
                >
                  {renderNormalContent}
                </div>
              )}
            </div>
          </div>
        </TransitionBox>
      </div>
    </StoreContextProvider>
  );
});
