import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import type { FC } from 'react';
import { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { SortableList } from 'src/common/components/sortable-list';
import { v4 as uuid } from 'uuid';
import { PromptItem } from './prompt-item';
import { usePromptStore } from './store';
import type { PromptCategoryType } from './types';

interface Props {
  onChangePrompt: (promptId: string) => void;
}
/** aside */
export const PromptEditorAside: FC<Props> = (props) => {
  const editPromptsArr = usePromptStore((s) => s.editPrompts);
  const emptyPromptsArr = usePromptStore((s) => s.emptyWithPrompts);
  return (
    <div className="flex flex-col text-t2 p-2 w-[30%] ">
      <div className="mt-2 flex-1 overflow-auto space-y-2">
        <PromptGroup
          categoryType="editPrompts"
          title={'编辑/选中块 AI指令'}
          promptIds={editPromptsArr}
          onChangePrompt={props.onChangePrompt}
        />
        <PromptGroup
          categoryType="emptyWithPrompts"
          title={'起草 AI指令'}
          promptIds={emptyPromptsArr}
          onChangePrompt={props.onChangePrompt}
        />
      </div>
    </div>
  );
};

interface PromptGroupProps extends Pick<Props, 'onChangePrompt'> {
  promptIds: string[];
  title: string;
  categoryType: PromptCategoryType;
}
const PromptGroup: FC<PromptGroupProps> = (props) => {
  const openModal = useOpenModal();
  const [expanded, setExpanded] = useState(true);
  const prompts = props.promptIds.map((v) => {
    return { id: v, value: v };
  });
  return (
    <div>
      <div
        className="group px-2 pb-2 text-t1-medium flex items-center justify-between animate-click"
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div>{props.title}</div>
        <Tooltip popup="添加指令">
          <Icon
            size="middle"
            name={'IcAdd'}
            onClick={(e) => {
              e.stopPropagation();
              const id = uuid();
              const store = usePromptStore.getState();
              openModal.warning({
                colorType: 'active',
                cancelColorType: 'active',
                confirmText: '添加自定义指令',
                cancelText: '添加自定义指令目录',
                confirm: () => {
                  usePromptStore.setState({
                    [props.categoryType]: [id, ...store[props.categoryType]],
                    records: { ...store.records, [id]: { name: '', id, type: 'Custom' } },
                    curentPromptId: id,
                    hasUpdate: true,
                  });
                },
                cancel: () => {
                  usePromptStore.setState({
                    [props.categoryType]: [id, ...store[props.categoryType]],
                    records: { ...store.records, [id]: { name: '', id, subNodes: [] } },
                    hasUpdate: true,
                  });
                },
              });
            }}
          />
        </Tooltip>
      </div>
      {expanded && (
        <SortableList
          onlyHandleDraggable
          onChange={(items) => {
            const result = items.map((v) => v.value);
            usePromptStore.setState({
              [props.categoryType]: result,
              hasUpdate: true,
            });
          }}
          items={prompts}
          renderItemContent={({ item, renderDragHandle }) => {
            return (
              <PromptItem
                categoryType={props.categoryType}
                promptId={item.id}
                onChangePrompt={props.onChangePrompt}
                renderHandle={() => {
                  return renderDragHandle({
                    className: 'cursor-pointer flex items-center justify-center w-5 h-5 text-grey4',
                    size: 'middle',
                  });
                }}
              />
            );
          }}
        />
      )}
    </div>
  );
};
