import { useFitCenter, useMindMapEngine, useMindMapScale } from '@flowus/mind-map';
import type { FC } from 'react';
import { useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useResizeListener, ResizeStatus } from '@flowus/common/hooks/use-resize-listener';
import { bizTracker } from 'src/utils/biz-tracker';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { DragProgressbar } from './drag-progressbar';
import { min } from 'date-fns';

export const ProgressBar: FC = () => {
  const scale = useMindMapScale();
  const engine = useMindMapEngine();
  const fitCenter = useFitCenter();
  return (
    <div className="flex items-center h-8 px-1" data-ignore-double-click>
      <Tooltip popup={`缩小画布${ShortcutSystemSymbol}+-`}>
        <Icon
          name={'IcPreviewZoomOut'}
          size="middle"
          onClick={() => {
            engine.zoomout();
            bizTracker.event('zoomout', { from: 1 });
          }}
        />
      </Tooltip>
      <div className="w-[110px]">
        <DragProgressbar
          range={{ min: 0, max: 300 }}
          scale={scale}
          onZoom={(scale) => {
            engine.zoom(scale);
          }}
        />
      </div>
      <Tooltip popup={`放大画布${ShortcutSystemSymbol}++`}>
        <Icon
          name={'IcPreviewZoomIn'}
          size="middle"
          onClick={() => {
            engine.zoomin();
            bizTracker.event('zoomin', { from: 1 });
          }}
          className="ml-3 "
        />
      </Tooltip>
      <Tooltip popup="定位到中心节点">
        <Icon
          name={'IcMenuAnchor'}
          size="middle"
          className="ml-3"
          onClick={() => {
            fitCenter();
          }}
        />
      </Tooltip>
    </div>
  );
};

// interface DragProgressbarProps {
//   scale: number;
//   onZoom: (scale: number) => void;
// }

// const MAX_SCALE = 300;
// const PROGRESS_MAX_WIDTH = 110;
// export const DragProgressbar: FC<DragProgressbarProps> = ({ scale, onZoom }) => {
//   const ref = useRef<HTMLDivElement>(null);
//   const progressRef = useRef<HTMLDivElement>(null);
//   const progress = (scale * PROGRESS_MAX_WIDTH) / MAX_SCALE;

//   useResizeListener(ref, {
//     onResize: (result) => {
//       if (result.resizeStatus === ResizeStatus.RESIZING) {
//         const scale = ((result.deltaX + progress) * MAX_SCALE) / PROGRESS_MAX_WIDTH;
//         onZoom(scale);
//       }
//     },
//     type: 'x',
//   });

//   return (
//     <div
//       ref={progressRef}
//       className="flex relative items-center ml-3 cursor-pointer p-2"
//       data-ignore-double-click
//       style={{
//         width: PROGRESS_MAX_WIDTH,
//       }}
//       onClick={(e) => {
//         const rect = progressRef.current?.getBoundingClientRect();
//         if (!rect) return;
//         const scale = (MAX_SCALE * (e.clientX - rect.left)) / rect.width;
//         onZoom(scale);
//       }}
//     >
//       {/* background */}
//       <div className="w-full h-[3px] rounded absolute bg-grey7 " />
//       {/* progress */}
//       <div
//         className="h-[3px] rounded absolute bg-active_color "
//         style={{
//           width: progress,
//         }}
//       />
//       <div
//         ref={ref}
//         className="absolute bg-white w-[18px] h-[18px] rounded-full cursor-pointer"
//         style={{
//           left: progress - 9,
//           border: '1px solid rgba(0, 0, 0, 0.1)',
//           boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
//         }}
//       ></div>
//     </div>
//   );
// };
