import type { FC } from 'react';

interface RowProps {
  leftNode: React.ReactNode | string;
  rightNode: React.ReactNode | string;
}
export const Row: FC<RowProps> = (props) => {
  return (
    <div className="w-full h-9 flex items-center justify-between">
      <span className="text-t3 text-grey3">{props.leftNode}</span>
      <span className="text-t3">{props.rightNode}</span>
    </div>
  );
};
