import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';
import { setAppModalState, useSettingState } from 'src/services/modal/hook';
import { SettingModal } from 'src/views/main/setting-modal';
import { SettingProvider } from 'src/views/main/setting-modal/common';
import type { SettingModelProps } from 'src/views/main/setting-modal/type';

export const GlobalModal: FC = (props) => {
  const openModal = useOpenModal();
  const settingState = useSettingState();

  const openSetting = useCallback(
    (params: SettingModelProps) => {
      openModal.modal({
        modalId: Modals.SETTING,
        autoClose: false,
        forceUpdate: true,
        containerClassName: '!overflow-hidden',
        disableDefaultStyle: true,
        closeAfterCallBack: () => {
          setAppModalState('$settingState', { open: false });
        },
        content: () => (
          <SettingProvider {...params}>
            <SettingModal />
          </SettingProvider>
        ),
      });
    },
    [openModal]
  );

  useEffect(() => {
    if (settingState.open) {
      openSetting(settingState);
    } else {
      openModal.closeModal(Modals.SETTING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingState]);

  return <>{props.children}</>;
};
