import type { SpaceDTO, UserDTO } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import { useEffect } from 'react';
import { request } from 'src/common/request';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { useCurrentSpace } from '../space';

const pendingUserIds = new Set<string>();
let toQueryUserIds = new Set<string>();

const fetchUsers = debounce(async (space: SpaceDTO) => {
  const userIds = toQueryUserIds;
  toQueryUserIds = new Set();
  const info = await request.editor.queryRecords({
    requests: [...userIds].map((id) => {
      return {
        id,
        table: 'user',
      };
    }),
  });

  if (info.users) {
    // space member already in store, therefore this must not space member.
    dispatch(usersActions.updateSpaceUsers({ users: info.users, space }));
    for (const user of Object.values(info.users)) {
      pendingUserIds.delete(user.uuid);
    }
  }
}, 32);

const scheduleFetchUser = (userId: string, space: SpaceDTO) => {
  if (!pendingUserIds.has(userId)) {
    toQueryUserIds.add(userId);
    pendingUserIds.add(userId);
    void fetchUsers(space);
  }
};

export const useUser = (user?: string | UserDTO) => {
  const userData = useObservableStore(
    (state) => {
      if (user == null) return undefined;
      if (typeof user === 'object') {
        return user;
      }
      return state.users[user];
    },
    [user],
    { allUser: true }
  );

  const space = useCurrentSpace();
  useEffect(() => {
    if (user == null || user === 'me' || typeof user === 'object' || !user) return;
    if (!userData) {
      scheduleFetchUser(user, space);
    }
  }, [user, userData, space]);
  return userData;
};
