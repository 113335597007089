import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { useTransaction } from 'src/hooks/use-transaction';
import { multiUpdateBlock } from 'src/redux/managers/block/update';
import { usePickBlock } from 'src/utils/pick-block';
import { listFormat, orderListFormat } from './const';

interface Props {
  blockId: string;
  onCloseModal: () => void;
}

export const FormatList: FC<Props> = ({ onCloseModal, blockId }) => {
  const block = usePickBlock(blockId, ['data'], ['format']);
  const transaction = useTransaction();

  if (!block) return null;

  const isList = block.type === BlockType.LIST;

  const map = (list: typeof listFormat) =>
    list.map((data) => ({
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data,
    }));

  return (
    <ListView
      className={cx(listViewNormalClassName, 'w-[200px]')}
      items={isList ? map(listFormat) : map(orderListFormat)}
      onItemClick={(res) => {
        const { value } = res.data;
        transaction(() => {
          multiUpdateBlock({
            data: {
              format: {
                [isList ? 'listFormat' : 'orderListFormat']: value,
              },
            },
          });
        });
        onCloseModal();
      }}
      keyDownPriority={PRIORITY_DIALOG + 1}
    />
  );
};
