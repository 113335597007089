import { cx } from '@flowus/common/cx';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useReadonly } from 'src/hooks/page/use-read-only';

export enum PasswordReason {
  NEED_PASSWORD = 1,
  INCORRECT_PASSWORD = 2,
}

interface Props {
  reason: PasswordReason;
  onPassword: (password: string, remainPassword: boolean) => void;
  onCancel: () => void;
  uuid?: string;
}

export const PasswordModal: FC<Props> = ({ reason, onPassword, onCancel, uuid }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const readonly = useReadonly(uuid);
  const [remain, setRemainPassword] = useState(true);

  return (
    <div className="flex flex-col next-modal px-[30px] w-[400px] pb-[30px]">
      <p className="text-t1-medium mt-[30px] text-center">请输入 PDF 访问密码</p>
      <input
        autoFocus
        type="text"
        ref={inputRef}
        className={cx(
          'border border-black/5 text-t2 rounded px-3 py-1.5 mt-5 bg-grey8',
          reason === PasswordReason.INCORRECT_PASSWORD && 'border-red'
        )}
        onKeyDown={(event) => {
          if (isHotkey('enter')(event)) {
            onPassword((event.target as HTMLInputElement).value, remain);
          }
        }}
      />

      {reason === PasswordReason.INCORRECT_PASSWORD && (
        <span className="text-red text-t4 mt-1">密码输入错误</span>
      )}

      {!readonly && (
        <button
          className="flex text-t4 h-8 items-center"
          onClick={() => setRemainPassword(!remain)}
        >
          <Icon
            size="large"
            name={remain ? 'IcCheckboxCheck' : 'IcCheckbox'}
            className="mr-1 text-active_color"
          />
          记住密码
        </button>
      )}

      <div className="flex justify-end mt-2">
        <Button onClick={onCancel}>取消</Button>
        <Button
          colorType="active"
          className="ml-2.5"
          onClick={() => {
            if (!inputRef.current) return;
            onPassword(inputRef.current.value, remain);
          }}
        >
          确定
        </Button>
      </div>
    </div>
  );
};
