import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';

export const RowTitle: FC<{
  title: string;
  expand?: boolean;
  onExpand: (expand: boolean) => void;
  showHelpIcon?: boolean;
  onClickHelpIcon?: () => void;
}> = (props) => {
  return (
    <div className="w-full h-9 flex items-center justify-between">
      <div className="flex items-center">
        <span className="text-t3">{props.title}</span>
        {props.showHelpIcon && !__BUILD_IN__ && (
          <Icon
            className="cursor-pointer ml-1"
            name={'IcInviteHelp'}
            size="middle"
            onClick={props.onClickHelpIcon}
          />
        )}
      </div>
      <Icon
        size="normal"
        className="select-none"
        name={props.expand ? 'IcArrowHide' : 'IcArrowShow'}
        onClick={() => {
          props.onExpand(!props.expand);
        }}
      />
    </div>
  );
};
