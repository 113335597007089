import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import { mapValues } from 'lodash-es';
import type { FC } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { segmentsToText } from 'src/editor/utils/editor';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { getRefBlock } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';

interface Props {
  uuid: string;
  callback?: () => void;
}

export const CancelLink: FC<Props> = ({ uuid, callback }) => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  const transaction = useTransaction();
  const block = usePickBlock(uuid, ['data'], ['collectionProperties']);

  if (!block) return null;

  const handleCancel = (pId: string, id: string) => {
    const block = cache.blocks[id];
    if (!block) return;
    if (block.type === BlockType.REFERENCE) {
      const refBlock = getRefBlock(id);
      if (!refBlock) return;
      updateBlock(id, { type: BlockType.TEXTAREA, data: refBlock.data });
    } else {
      const handleSegment = (segment: SegmentDTO) => {
        if (segment.type === TextType.LINK_PAGE && segment.uuid && segment.uuid === pId) {
          const refBlock = cache.blocks[segment.uuid];
          if (!refBlock) return segment;
          const text = segmentsToText(refBlock.data.segments);
          return { text, type: TextType.TEXT, enhancer: segment.enhancer };
        }
        return segment;
      };
      const segments = block.data.segments?.map(handleSegment);
      const caption = block.data.caption?.map(handleSegment);
      const collectionProperties = mapValues(block.data.collectionProperties, (o) =>
        o.map(handleSegment)
      );
      updateBlock(id, { data: { ...block.data, segments, caption, collectionProperties } });
    }
  };

  return (
    <div
      className="absolute right-0 top-1 bg-white2 border transition-opacity flex-shrink-0 text-grey3 rounded-xl text-t3 px-2 mr-3 animate-click opacity-0 group-hover:opacity-100"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        openModal.warning({
          title: '确定取消该引用？',
          content: '取消后，原引用页面位置将变为纯文字',
          confirm: () => {
            transaction(() => {
              handleCancel(pageId, uuid);
            });

            callback?.();
          },
        });
      }}
    >
      取消引用
    </div>
  );
};
