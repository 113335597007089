import type { FC } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { FloatToolbar } from 'src/editor/component/float-toolbar';
import { PageFormatPanel } from 'src/editor/component/page-format-panel';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { ToolbarButton } from '../uikit/editable-toolbar/button';

interface PageToolbarProps {
  blockId: string;
  illegal?: boolean;
}
export const PageToolbar: FC<PageToolbarProps> = (props) => {
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const cardFormat = useObservableBlock(props.blockId, (block) => block?.data.format?.cardFormat);
  const block = usePickBlock(props.blockId, ['data']);
  if (!block) return null;
  const showType = cardFormat?.showType ?? 'default';
  return (
    <FloatToolbar
      showType={showType}
      customButton={
        showType !== 'default' && !props.illegal ? (
          <ToolbarButton
            title={'样式设置'}
            className="px-0 mr-0.5"
            boxClassName="px-0 py-0"
            icon="IcBtnEdit"
            onClick={(e) => {
              openModal.dropdown({
                placement: 'bottom',
                popcorn: elementToGetBoundingClientRect(e.currentTarget),
                content: ({ onCloseModal }) => {
                  return <PageFormatPanel blockId={block.uuid} onClose={onCloseModal} />;
                },
              });
            }}
          />
        ) : undefined
      }
      onClick={(showType) => {
        if (showType === cardFormat?.showType) return;
        bizTracker.event('page_card_style', {
          style_type: {
            showType,
            cardStyle: block?.data.format?.cardFormat?.cardStyle ?? 'regular',
            isShowCover: cardFormat?.isShowCover,
            isShowIcon: cardFormat?.isShowIcon,
            cardCaption: Boolean(cardFormat?.cardCaption),
          },
        });
        transaction(() => {
          updateBlock(props.blockId, {
            data: {
              format: {
                cardFormat: {
                  ...block?.data.format?.cardFormat,
                  showType,
                },
              },
            },
          });
        });
      }}
    />
  );
};
