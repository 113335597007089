import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getPermissions } from '../share/use-permissions';

export const useIsRecord = (uuid: string) => {
  return useObservableStore((state) => isRecord(uuid, state.blocks), [uuid], {
    wait: 1000,
    waitMode: 'debounce',
    ignoreDeep: true,
  });
};

export const isRecord = (uuid: string, blocks = cache.blocks) => {
  const block = blocks[uuid];
  if (!block) return false;

  const type = blocks[block.parentId]?.type;

  return type === BlockType.COLLECTION_VIEW_PAGE || type === BlockType.COLLECTION_VIEW;
};

/**
 * 获取页面可用的模板记录页面
 */
export const useAvailableTemplatePages = (uuid: string) => {
  return useObservableStore(
    ({ blocks }) => {
      const block = blocks[uuid];
      if (!block) {
        return {
          isTemplate: false,
          collectionId: null,
          templatePages: [],
        };
      }

      const parent = blocks[block.parentId];
      return {
        isTemplate: block.isTemplate ?? false,
        collectionId: parent?.uuid,
        templatePages: (parent?.templatePages ?? []).filter((it) => {
          const { role } = getPermissions(it);
          return role !== PermissionRole.NONE;
        }),
      };
    },
    [uuid]
  );
};
