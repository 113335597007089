export const dataURItoBlob = (dataURI: string) => {
  const [a, b] = dataURI.split(',');
  if (!a || !b) return;

  const byteString = atob(b);
  const mimeString = a.split(':')[1]?.split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
};
