import { useCallback } from 'react';
import { cache } from 'src/redux/store';
import type { NextBlock, SelectBlock } from 'src/redux/types';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { isCollection, isMindMap } from 'src/utils/block-type-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { useSelectedBlockHistory } from '../page/use-selected-block-history';
import { useCopyBlock } from './use-copy-block';
import { useCopyRecord } from './use-copy-record';
import { getOwnerPage } from './use-get-owner-page';
import { useOpenUpgradeSpaceIfMindNodeLimit } from './use-open-upgrade-space-if-mind-node-limit';

export const useCopyBlockRecord = () => {
  const copyRecord = useCopyRecord();
  const copyBlock = useCopyBlock();
  const { selectedBlockHistory } = useSelectedBlockHistory();
  const maybeOpenUpgradeSpace = useOpenUpgradeSpaceIfMindNodeLimit();

  const copyBlockRecord = async (
    selectedBlocks: SelectBlock[],
    posBlockId?: string,
    syncId?: string,
    options?: {
      effects?: (selectBlock: SelectBlock[]) => void;
    }
  ) => {
    checkIsMaximumCapacityDialog();
    let parentBlock: NextBlock | undefined;
    let resBlocks: SelectBlock[] = [];
    const selectBlockId = selectedBlocks[0]?.blockId;
    if (selectBlockId) {
      const pageId = getOwnerPage(selectBlockId);
      if (pageId && isMindMap(cache.blocks[pageId]?.type)) {
        const maybeOpen = maybeOpenUpgradeSpace(pageId);
        if (maybeOpen) return;
      }
    }

    // 如果所选块都是同一个 collection 下的，调用 copyRecord
    if (
      selectedBlocks.every((selectBlock) => {
        const { blocks } = cache;
        const _block = blocks[selectBlock.blockId];
        if (_block) {
          const _parent = blocks[_block.parentId];
          if (isCollection(_parent?.type)) {
            // 其实框选按道理讲 只能块选同一个 collection 里的块
            if (parentBlock && parentBlock.uuid !== _parent?.uuid) {
              return false;
            }
            parentBlock = _parent;
            return true;
          }
        }
        return false;
      })
    ) {
      if (parentBlock) {
        const viewId = selectedBlocks[0]?.viewId;
        if (viewId) {
          copyRecord(viewId, selectedBlocks, {
            effects: (newBlocks) => {
              selectedBlockHistory(newBlocks);
              options?.effects?.(newBlocks);
              resBlocks = newBlocks;
            },
          });
        }
      }
    } else {
      await copyBlock(selectedBlocksToIds(selectedBlocks), {
        effects: (newBlocks) => {
          newBlocks.forEach((selectedBlock) => (selectedBlock.syncId = syncId));
          selectedBlockHistory(newBlocks);
          options?.effects?.(newBlocks);
          resBlocks = newBlocks;
        },
        after: posBlockId,
      });
    }
    return resBlocks;
  };

  return useCallback(copyBlockRecord, [
    copyBlock,
    copyRecord,
    maybeOpenUpgradeSpace,
    selectedBlockHistory,
  ]);
};
