import { useMindMapEngine } from '@flowus/mind-map';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { judgeSharePage } from 'src/utils/getPageId';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { useToggleAllNode } from './use-toggle-node';
import { MoreNodeMenu } from '../component/more-node-menu';

/** 思维导图画布右键菜单 */
export const useOpenMindMapContextMenu = () => {
  const openModal = useOpenModal();

  return useCallback(
    (e: MouseEvent | React.MouseEvent) => {
      const node = (e.target as HTMLElement).closest('[data-block-id]');
      if (node instanceof HTMLDivElement && !judgeSharePage()) {
        if (node.dataset.blockId && cache.ui.selectedBlocks.length === 0) {
          const { blockId } = node.dataset;
          if (blockId) {
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId,
                  mindMapId: node.dataset.mindMapId,
                },
              ])
            );
          }
        }
      }
      if (e.currentTarget instanceof HTMLElement) {
        const rect = e.currentTarget.getBoundingClientRect();
        openModal.dropdown({
          placement: 'bottom-start',
          popcorn: {
            getBoundingClientRect: () => {
              return {
                // 整个画布太大定位错误，需要定到对应的位置
                ...rect,
                left: e.clientX,
                top: e.clientY,
                bottom: e.clientY + 1,
                right: e.clientX + 1,
                width: 1,
                height: 1,
              };
            },
          },
          content: ({ onCloseModal }) => {
            return cache.ui.selectedBlocks.length > 0 && !judgeSharePage() ? (
              <MoreNodeMenu
                showBg
                onItemClick={() => {
                  onCloseModal();
                }}
              />
            ) : (
              <ContextMenu
                id={''}
                onItemClick={() => {
                  onCloseModal();
                }}
              />
            );
          },
        });
      }
    },
    [openModal]
  );
};

interface ContextMenuProps {
  id: string;
  onItemClick: () => void;
}
const ContextMenu: FC<ContextMenuProps> = (props) => {
  const toggleAllNode = useToggleAllNode();
  const engine = useMindMapEngine();

  const items: ListItem[] = [
    {
      type: ListItemType.OPERATION,
      data: {
        title: '画布放大',
        onClick: () => {
          engine.zoomin();
          bizTracker.event('zoomin', { from: 2 });
        },
        rightText: `${ShortcutSystemSymbol}++`,
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '画布缩小',
        onClick: () => {
          engine.zoomout();
          bizTracker.event('zoomout', { from: 2 });
        },
        rightText: `${ShortcutSystemSymbol}+-`,
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '重置缩放',
        onClick: () => {
          engine.zoom(100);
        },
        rightText: `${ShortcutSystemSymbol}+O`,
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '定位到中心节点',
        onClick: () => {
          engine.fitCenter();
        },
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '展开/折叠所有节点',
        onClick: () => {
          toggleAllNode();
          bizTracker.event('node_fold', { from: 2 });
        },
        rightText: `${ShortcutSystemSymbol}+Shift+.`,
      },
    },
  ];
  return (
    <ListView
      className="next-modal w-[260px] rounded py-2"
      items={items}
      onItemClick={() => {
        props.onItemClick();
      }}
    />
  );
};
