import type { SpaceDTO } from '@next-space/fe-api-idl';
import { createAction } from '@reduxjs/toolkit';

export const CREATE_SPACE = createAction<{
  space: SpaceDTO;
}>('space/create');

export const UPDATE_SPACE = createAction<{
  uuid: string;
  patch: Partial<Omit<SpaceDTO, 'uuid'>>;
}>('space/update');

/** 添加用户备注 */
export const ADD_MARK_NAME = createAction<{
  uuid: string;
  patch: { userId: string; markName: string }[];
}>('space/mark-ame');

/** 自定义模板相关的action */
export const LIST_BEFORE_TEMPLATE = createAction<{
  /** 所属spaceId */
  parentId: string;
  /** 移动内容 */
  uuid: string;
  /** 锚点 ID */
  before?: string;
  ignoreOp?: boolean;
}>('template/list-before');

export const LIST_AFTER_TEMPLATE = createAction<{
  /** 所属spaceId */
  parentId: string;
  /** 移动内容 */
  uuid: string;
  /** 锚点 ID */
  after?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('template/list-after');

export const LIST_REMOVE_TEMPLATE = createAction<{
  /** 所属spaceId */
  parentId: string;
  /** 模板id */
  uuid: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('template/list-remove');
