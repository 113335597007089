/**
 * 要支持传输列表里的文件预览，由于多维表跟文档的上传后预览需要的参数不一致
 * 一个需要blockId，另一个需要recordId和propertyId，所以这里先暂时存一个url算了，超过时间后打开就直接跳到对应的页面
 * */

const previewUrlMap = new Map<string, { url: string; expireTime: number }>();

export const addPreviewUrl = (key: string, info: { url: string; expireTime: number }) => {
  previewUrlMap.set(key, info);
};
export const getPreviewUrl = (key: string | undefined) => {
  if (!key) return;
  const info = previewUrlMap.get(key);
  if (!info) return;
  if (info.expireTime > Date.now()) {
    return info.url;
  }
  // 过期的删掉
  removePreviewUrl(key);
};

export const removePreviewUrl = (key: string) => {
  previewUrlMap.delete(key);
};
