import isHotkey from 'is-hotkey';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { bizTracker } from 'src/utils/biz-tracker';

interface Props {
  uuid?: string;
  pdfViewer: PDFViewer;
  className?: string;
}

export const Search: FC<Props> = ({ pdfViewer, uuid, className }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const countRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);
  const [pos, setPos] = useState<{ top: number; left: number } | undefined>();

  const [setting] = useState({
    highlightAll: false,
    caseSensitive: false,
    entireWord: false,
  });

  useEffect(() => {
    const updatefindmatchescount = ({ matchesCount }: any) => {
      if (!countRef.current) return;

      const { current, total } = matchesCount;
      countRef.current.innerHTML = `第 ${current} 项，共匹配 ${total} 项`;
    };

    const updatefindcontrolstate = (rest: any) => {
      if (!countRef.current) return;

      const { state, previous, matchesCount } = rest;
      if (state === 1) {
        countRef.current.innerHTML = `未找到`;
      } else if (state === 2) {
        const { total } = matchesCount;
        countRef.current.innerHTML = `第 ${previous ? total : 1} 项，共匹配 ${total} 项`;
      } else if (state === 0) {
        const { current, total } = matchesCount;
        countRef.current.innerHTML = `第 ${current} 项，共匹配 ${total} 项`;
      }
    };

    pdfViewer.eventBus.on('updatefindmatchescount', updatefindmatchescount);
    pdfViewer.eventBus.on('updatefindcontrolstate', updatefindcontrolstate);
  }, [pdfViewer.eventBus]);

  const dispatchEvent = (type: string, findPrev = false, options = setting) => {
    if (!inputRef.current) return;
    const query = inputRef.current.value.trim();

    pdfViewer.eventBus.dispatch('find', {
      type,
      query,
      caseSensitive: options.caseSensitive,
      entireWord: options.entireWord,
      highlightAll: true,
      findPrevious: findPrev,
    });
  };

  const previousMatch = () => {
    if (!inputRef.current) return;
    dispatchEvent('again', true);
  };

  const nextMatch = async () => {
    if (!inputRef.current) return;
    dispatchEvent('again');
  };

  const toggle = (event: React.MouseEvent) => {
    if (show) {
      pdfViewer.eventBus.dispatch('findbarclose', {});
    } else {
      bizTracker.event('pdf_search', { file_id: uuid });
    }

    const rect = event.currentTarget.getBoundingClientRect();
    setPos({ top: rect.bottom + 10, left: rect.left });
    setShow(!show);
  };

  return (
    <>
      <div className={className}>
        <Tooltip placement="bottom" popup="搜索">
          <button className="animate-hover p-1 flex" onClick={toggle}>
            <Icon size="middle" name={'IcSearch'} />
          </button>
        </Tooltip>

        {show && (
          <div
            className="w-[360px] px-1.5 pb-1.5 items-center fixed pt-3 next-modal bg-white4 -translate-x-2/3 z-50"
            style={{ top: pos?.top, left: pos?.left }}
          >
            <div className="flex justify-between">
              <div className="text-t3 text-grey3" ref={countRef}>
                搜索
              </div>
              <button className="flex animate-hover" onClick={toggle}>
                <Icon size="middle" name={'IcToastClose'} className="text-grey4" />
              </button>
            </div>
            <div className="mt-4">
              <div className="flex items-center bg-grey9 border border-grey6 rounded-sm h-7 pl-2">
                <Icon
                  name={'IcSearch'}
                  size="normal"
                  className="mr-1 w-[18px] h-[18px] text-grey4"
                />

                <input
                  type="text"
                  className="text-t2 text-black bg-transparent flex-1"
                  placeholder="搜索文本"
                  ref={inputRef}
                  autoFocus
                  onChange={() => dispatchEvent('')}
                  onKeyDown={(event) => {
                    if (isHotkey('enter')(event)) {
                      dispatchEvent('again', event.shiftKey);
                    }
                  }}
                />

                <div className="px-2.5 flex items-center h-full text-black bg-white4 border-l">
                  <button onClick={previousMatch} className="flex items-center">
                    <Icon size="middle" name={'IcArrowDateBack'} />
                  </button>
                  <span className="w-[1px] mx-2.5 bg-grey6 h-4" />
                  <button onClick={nextMatch} className="flex items-center">
                    <Icon size="middle" name={'IcArrowDateNext'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
