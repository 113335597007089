import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { IconProp } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import type { ReadonlyProp } from 'src/redux/types';
import { judgeSharePage } from 'src/utils/getPageId';

type DivProps = JSX.IntrinsicElements['div'];
/**
 * image/file/folder/embed/equation empty状态下的组件
 */
export const BlockEmptyView: FC<
  {
    text: string;
    iconProp: IconProp;
    iconElement?: ReactNode;
    loading?: boolean;
    defaultOpenPanel?: boolean;
  } & DivProps &
    ReadonlyProp
> = (props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const { text, iconProp, loading, defaultOpenPanel, iconElement, readonly, onClick, ...rest } =
    props;
  const [open] = useState(defaultOpenPanel);

  useEffect(() => {
    if (open && !readonly) {
      divRef.current?.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // 页面锁是后面添加的，分享页面下空快不展示，但锁状态下的空块是展示的，这里判断一下
  if (judgeSharePage()) return null;
  return (
    <div
      ref={divRef}
      onClick={readonly ? undefined : onClick}
      {...rest}
      className={cx(
        'flex items-center w-full h-12.5 py-2.5 pl-3 rounded bg-grey8 cursor-pointer',
        props.className
      )}
    >
      {iconElement ? (
        iconElement
      ) : (
        <Icon {...iconProp} size="large" className={cx('w-6 h-6 text-grey4', iconProp.className)} />
      )}
      <div className={cx('ml-1.5 text-t2', loading ? 'text-grey3' : 'text-grey4')}>{text}</div>
    </div>
  );
};
