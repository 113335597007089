import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React from 'react';

/** 给序号列表，todo块的符号用的,主要是换行的是要保持符号(checkbox/序号等)在左上角 */
export const BlockSymbol: FC<{
  editorNode: React.ReactNode;
  symbol: React.ReactNode;
  symbolClassName?: string;
}> = (props) => {
  return (
    <div className="flex flex-1">
      <div className={cx('mr-1 flex items-start justify-center mt-[1.5px]', props.symbolClassName)}>
        {props.symbol}
      </div>
      {props.editorNode}
    </div>
  );
};
