import type { SpaceDTO } from '@next-space/fe-api-idl';
import type { StoreUsers } from 'src/redux/reducers/users';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { getCurrentSpace, useCurrentSpace } from './use-current-space';

const getSpaceUsers = (space: SpaceDTO, users: StoreUsers) => {
  const spaceUsers: StoreUsers = {};
  space.permissions?.forEach((permission) => {
    if (permission.type === 'user' && permission.userId) {
      const user = users[permission.userId];
      if (user) {
        spaceUsers[permission.userId] = user;
      }
    }
  });
  return spaceUsers;
};

/** 获取当前空间内有权限的用户 */
export const useCurrentSpaceUsers = () => {
  const currentSpace = useCurrentSpace();
  return useObservableStore((state) => getSpaceUsers(currentSpace, state.users), [currentSpace]);
};

export const useCurrentSpaceUserSize = () => {
  const currentSpace = useCurrentSpace();

  return useObservableStore((state) => {
    const spaceUsers = getSpaceUsers(currentSpace, state.users);
    return Object.keys(spaceUsers).length;
  }, []);
};

export const getCurrentSpaceUsers = () => {
  const currentSpace = getCurrentSpace();
  return getSpaceUsers(currentSpace, cache.users);
};
