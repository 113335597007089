import { cx } from '@flowus/common/cx';
import React, { memo, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { bindDataTestId } from 'src/utils/qa-utils';
import { v4 as uuid } from 'uuid';
import type { Direction } from '../../hooks';
import { useControlSelectListElement } from '../../hooks';
import { PRIORITY_DIALOG } from '../../utils/global-listener-helper';
import { VirtuosoList } from '../virtuoso-list';
import { disableSelectedItems, supportItems } from './helper';
import './register';
import type { ListItem, ListViewProps } from './types';
export { ListItemType } from './types';

export const ListView = memo((props: ListViewProps) => {
  const {
    loadMore = () => {},
    keyDownPriority = PRIORITY_DIALOG,
    onGlobalCaptureKeydown,
    defaultActiveIndex = -1,
    activeIndex,
    onSelectedActiveIndex,
    autoResetActiveIndex,
    enableKeyDown = true,
  } = props;
  const items = useMemo(() => props.items.filter((i) => !i.isHidden), [props.items]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const id = useRef(uuid());
  const virtuoso = useRef(null);

  const findElement = (index: number) => {
    return containerRef.current?.querySelector(`[data-list-item="${id.current}-${index}"]`) ?? null;
  };
  const findNextIndex = (currentIndex: number, direction: Direction) => {
    return findNextSupportSelectedItem(items, currentIndex, direction);
  };

  const [selectedIndex, setSelectedIndex] = useControlSelectListElement(
    items.length,
    findElement,
    findNextIndex,
    keyDownPriority,
    {
      scrollContainer: containerRef,
      defaultActiveIndex,
      activeIndex,
      onGlobalCaptureKeydown,
      onSelectedActiveIndex,
      virtuoso,
      autoResetActiveIndex,
      enableKeyDown,
    }
  );

  const renderItem = (item: ListItem<any>, index: number) => {
    if (item.isHidden) {
      return null;
    }
    const attributes = {
      // 多维表看板新建记录时录入页面或者日期时避免失焦点。后续可改进
      onMouseDown: (event: React.MouseEvent) => event.preventDefault(),
      'data-list-item': `${id.current}-${index}`,
      ...bindDataTestId(item.data?.['dataTestId'] || item.data?.title),
      ...item.attribute,
      onMouseEnter: () => {
        if (enableKeyDown) {
          setSelectedIndex(index);
        }
      },
    };

    if (item.render) {
      const renderElement = item.render();
      return (
        <renderElement.type
          {...{
            ...attributes,
            key: item.key ?? index,
            ...renderElement.props,
            className: cx(
              renderElement.props.className,
              index === selectedIndex && !item.disableCustomHoverBg && 'normal-bg rounded'
            ),
          }}
        />
      );
    }

    const ItemElement = supportItems[item.type];
    if (ItemElement === undefined) {
      return <div>{`不支持该类型(${item.type}),请先注册`}</div>;
    }

    return (
      <ItemElement
        attribute={attributes}
        key={item.key ?? index}
        listItem={item}
        index={index}
        onItemClick={item.disableClick ? undefined : props.onItemClick}
        selected={index === selectedIndex}
      />
    );
  };

  return (
    <div
      className={cx('max-h-[90vh] overflow-y-auto', props.className)}
      ref={containerRef}
      data-no-cancel-selected
      style={props.style}
    >
      {props.customHeader}
      {props.virtualList ? (
        <>
          {props.loadingView}
          <VirtuosoList
            className={props.scrollClassName}
            virtuosoRef={virtuoso}
            items={items}
            renderItem={renderItem}
            maxHeight={props.virtualListHeight}
            computeItemKey={(index) => items[index]?.key ?? `${index}`}
          />
        </>
      ) : (
        // InfiniteScroll这个控件需要在外层设置top属性
        <div
          ref={scrollRef}
          className={cx(
            props.scrollClassName,
            props.loadMore && 'absolute bottom-0 w-full overflow-auto flex-1'
          )}
          style={{ ...props.scrollContainerStyle }}
        >
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            useWindow={false}
            loadMore={loadMore}
            getScrollParent={() => scrollRef.current}
            hasMore={props.hasMore}
          >
            {props.loadingView}
            {items.map((item, index) => renderItem(item, index))}
          </InfiniteScroll>
        </div>
      )}
      {props.customFooter}
    </div>
  );
});

/** 循环查找 */
function findNextSupportSelectedItem(
  items: ListItem[],
  currentIndex: number,
  direction: Direction
): number {
  if (direction === 'left' || direction === 'right') return -1;
  const isUp = direction === 'up';
  const nextIndex = isUp ? currentIndex - 1 : currentIndex + 1;
  const item = items[nextIndex];
  if (!item) {
    // 如果找不到说明nextIndex不在范围内，超标了，直接循环到下一个index
    return findNextSupportSelectedItem(items, isUp ? items.length : -1, direction);
  }
  if (item.isHidden) {
    return findNextSupportSelectedItem(items, nextIndex, direction);
  }

  if (disableSelectedItems.has(item.type)) {
    // 如果不支持active就带着nextIndex找下一个
    return findNextSupportSelectedItem(items, nextIndex, direction);
  }
  // 如果支持就直接返回index
  return nextIndex;
}
