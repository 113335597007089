import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import type { CollectionSchemaType, FormLogicRule } from '@next-space/fe-api-idl';
import { CollectionViewType, CoverType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { FILTER_PROPERTIES_FORM, ICON_MAP } from 'src/bitable/const';
import { useOpenPropertyWidget } from 'src/bitable/table-view/widgets/property';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { Switch } from 'src/common/components/switch';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useUpdatePropertyVisible } from 'src/hooks/block/use-update-property-visible';
import {
  getCoverSetting,
  useCollectionView,
  useCoverSetting,
} from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { getState } from 'src/redux/store';
import type { NextCollectionView } from 'src/redux/types';
import { setAppUiState, useIsNewProperty } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { useBitable } from '../context';
import { checkPropsChangeCauseNewFormLogic } from '../form-view/form-logic-utils';
import { PropertyManagePlace } from '../table-view/types';
import { ViewIconMap, ViewNameMap } from './const';
import type { SettingProps } from './type';

export interface CollectionProperty {
  property: string;
  visible: boolean;
  width?: number;
  name: string;
  type: CollectionSchemaType;
}

export const PropertyList: FC<SettingProps> = ({ isFromViewSetting }) => {
  const { collectionId, viewId, readonly, isLocked, viewType } = useBitable();
  const [isTimelineTable, setTimelineTable] = useState(false);

  const view = useCollectionView(viewId);
  const [properties = [], timelineTableProperties = []] = useProperties(viewId);

  const createProperty = useCreateProperty();
  const openModal = useOpenModal();

  if (!view || !viewType) return null;

  const renderProperties =
    viewType === CollectionViewType.FORM
      ? properties.filter((it) => !FILTER_PROPERTIES_FORM.includes(it.type))
      : isTimelineTable
      ? timelineTableProperties
      : properties;
  const isAllVisible = renderProperties.every((item) => item.visible === true);

  const handleChange = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const {
      properties = [],
      timelineTableProperties = [],
      formLogic = [],
    } = getViewFormat(viewId) ?? {};
    const oldProperties = isTimelineTable ? timelineTableProperties : properties;

    let newFormLogic: FormLogicRule[] | undefined;
    if (active.id !== over.id) {
      const oldIndex = oldProperties.findIndex((item) => item.property === active.id);
      const newIndex = oldProperties.findIndex((item) => item.property === over.id);
      const sorted = arrayMove(oldProperties, oldIndex, newIndex);
      // 表单视图下，将排在后面的问题拖拽到前面，如果破坏了跳题逻辑，需要弹窗提示
      if (viewType === CollectionViewType.FORM) {
        newFormLogic = checkPropsChangeCauseNewFormLogic(
          collectionId,
          viewId,
          sorted.map((it) => it.property),
          formLogic
        );
        if (newFormLogic != null) {
          const confirmed = await new Promise((resolve) => {
            openModal.warning({
              title: <>确认调整题目顺序吗？</>,
              content: <>会导致部分跳题逻辑失效，将自动删除失效逻辑。</>,
              confirm() {
                resolve(true);
              },
              cancel() {
                resolve(false);
              },
            });
          });
          if (!confirmed) {
            return;
          }
        }
      }
      updateViewFormat(viewId, {
        [isTimelineTable ? 'timelineTableProperties' : `${viewType}Properties`]: sorted,
        ...(newFormLogic ? { formLogic: newFormLogic } : {}),
      });
    }
  };

  const toggleAll = async () => {
    if (isLocked) return;

    const newVisible = !isAllVisible;
    const { previewType } = getCoverSetting(viewId);

    const {
      properties = [],
      timelineTableProperties = [],
      formLogic = [],
    } = getViewFormat(viewId) ?? {};
    const oldProperties = isTimelineTable ? timelineTableProperties : properties;
    const newProperties = oldProperties.map((item) => {
      if (
        view.type !== CollectionViewType.FORM &&
        item.property === 'title' &&
        !isTimelineTable &&
        (viewType === CollectionViewType.TABLE ||
          viewType === CollectionViewType.LIST ||
          viewType === CollectionViewType.TIMELINE ||
          viewType === CollectionViewType.CALENDAR ||
          previewType === CoverType.NONE)
      ) {
        return item;
      }

      return { ...item, visible: newVisible };
    });

    const newFormatPatch: Partial<NextCollectionView['format']> = {
      [isTimelineTable ? 'timelineTableProperties' : `${viewType}Properties`]: newProperties,
    };
    if (isTimelineTable) {
      newFormatPatch.timelineShowTable = newVisible;
    }

    if (viewType === CollectionViewType.FORM && !newVisible) {
      const newFormLogic = checkPropsChangeCauseNewFormLogic(
        collectionId,
        viewId,
        newProperties.filter((it) => it.visible).map((it) => it.property),
        formLogic
      );
      if (newFormLogic != null) {
        const confirmed = await new Promise((resolve) => {
          openModal.warning({
            title: <>确认隐藏题目吗？</>,
            content: <>会导致部分跳题逻辑失效，将自动删除失效逻辑。</>,
            confirm() {
              resolve(true);
            },
            cancel() {
              resolve(false);
            },
          });
        });
        if (!confirmed) {
          return;
        }
        newFormatPatch.formLogic = newFormLogic;
      }
    }

    updateViewFormat(viewId, newFormatPatch);
  };

  const handleCreateProperty = () => {
    bizTracker.event('bitable_property_create', { from_scene: 'menu' });
    const newPropertyId = createProperty({
      collectionId,
      viewId,
      visible: true,
      isTimelineTable,
    });
    if (newPropertyId) {
      setAppUiState({
        $newTableProperty: {
          propertyId: newPropertyId,
          from: PropertyManagePlace.PROPERTY_LIST,
        },
      });
    }
  };

  return (
    <div className={cx('pt-[5px]', isFromViewSetting ? 'w-full' : 'next-modal-scroll w-[400px]')}>
      <div className="flex h-10 items-center justify-between px-4">
        <div className="text-t2 flex items-center">
          <Icon className="mr-1" name={ViewIconMap[viewType]} size="middle" />
          <span className="mr-2 font-medium">{ViewNameMap[viewType]}</span>
          <span className="max-w-[140px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {view.title}
          </span>
        </div>

        {[
          CollectionViewType.TABLE,
          CollectionViewType.LIST,
          CollectionViewType.CALENDAR,
          CollectionViewType.TIMELINE,
          CollectionViewType.FORM,
        ].includes(viewType) && (
          <button
            className={cx('text-t2 text-active_color', isLocked && 'cursor-not-allowed opacity-20')}
            onClick={toggleAll}
          >
            {isAllVisible ? '全部隐藏' : '全部显示'}
          </button>
        )}
      </div>

      {[CollectionViewType.BOARD, CollectionViewType.GALLERY].includes(viewType) && (
        <>
          {/* <CardControl /> */}

          {/* <Divider className="mx-4 my-1" /> */}

          <div className="flex h-10 items-center justify-between px-4">
            <span className="text-t2 text-grey3">
              {__BUILD_IN__ ? (
                <>
                  {'显_示'} {ViewNameMap[viewType]}
                </>
              ) : (
                <>在{ViewNameMap[viewType]}中显示</>
              )}
            </span>
            <button
              className={cx(
                'text-t2 text-active_color',
                isLocked && 'cursor-not-allowed opacity-20'
              )}
              onClick={toggleAll}
            >
              {isAllVisible ? '全部隐藏' : '全部显示'}
            </button>
          </div>
        </>
      )}

      {viewType === CollectionViewType.TIMELINE && (
        <>
          <div className="border-b border-grey6 py-1.5 px-2">
            <button
              onClick={() => setTimelineTable(false)}
              className={cx('mr-2 rounded-sm py-1.5 px-4', !isTimelineTable && 'bg-black_006')}
            >
              时间轴
            </button>
            <button
              onClick={() => setTimelineTable(true)}
              className={cx('rounded-sm py-1.5 px-4', isTimelineTable && 'bg-black_006')}
            >
              表格
            </button>
          </div>
        </>
      )}

      <SortableList
        disabled={isLocked}
        items={renderProperties.map((property) => ({ id: property.property, ...property }))}
        onChange={(_, event) => handleChange(event)}
        renderItemContent={({ item }) => (
          <PropertyItem {...item} isTimelineTable={isTimelineTable} />
        )}
      />

      {!readonly && !isLocked && (
        <>
          <Divider />
          <button
            className="animate-hover flex h-10 w-full items-center px-3 text-grey3"
            onClick={handleCreateProperty}
          >
            <Icon className="mr-1" name="IcAdd" size="middle" />
            <span className="text-t2">创建属性</span>
          </button>
        </>
      )}
    </div>
  );
};

interface Props {
  property: string;
  visible: boolean;
  name: string;
  dragOverlay?: boolean;
  type: CollectionSchemaType;
  isTimelineTable?: boolean;
}

export const PropertyItem: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const { property, visible, name, type, dragOverlay, isTimelineTable } = props;
  const { viewId, viewType, collectionId, isLocked } = useBitable();
  const updateVisible = useUpdatePropertyVisible();
  const { previewType } = useCoverSetting(viewId);
  const openPropertyWidget = useOpenPropertyWidget();
  const propertyRef = useRef<HTMLDivElement | null>(null);
  const isNewProperty = useIsNewProperty(property, PropertyManagePlace.PROPERTY_LIST);

  const handleSwitch = async (visible: boolean) => {
    if (isLocked) return;

    if (viewType === CollectionViewType.FORM && !visible) {
      const view = getState().collectionViews[viewId];
      const oldProps = view?.format.formProperties ?? [];
      const formLogic = view?.format.formLogic ?? [];
      const newFormLogic = checkPropsChangeCauseNewFormLogic(
        collectionId,
        viewId,
        oldProps
          .filter((it) => (it.property === property ? visible : it.visible))
          .map((it) => it.property),
        formLogic
      );
      if (newFormLogic != null) {
        const confirmed = await new Promise((resolve) => {
          openModal.warning({
            title: <>确认隐藏题目吗？</>,
            content: <>会导致部分跳题逻辑失效，将自动删除失效逻辑。</>,
            confirm() {
              resolve(true);
            },
            cancel() {
              resolve(false);
            },
          });
        });
        if (!confirmed) {
          return;
        }
        updateViewFormat(viewId, { formLogic });
      }
    }

    updateVisible(collectionId, viewId, property, visible, isTimelineTable);
  };

  useEffect(() => {
    if (!propertyRef.current) return;

    if (isNewProperty) {
      openPropertyWidget(
        property,
        propertyRef.current,
        isTimelineTable
          ? PropertyManagePlace.PROPERTY_LIST_TIMELINE_TABLE
          : PropertyManagePlace.PROPERTY_LIST
      );
      setAppUiState({ $newTableProperty: undefined });
    }
  }, [isNewProperty, isTimelineTable, openPropertyWidget, property]);

  const isTitle = property === 'title';
  const disable =
    viewType !== CollectionViewType.FORM &&
    isTitle &&
    !isTimelineTable &&
    (viewType === CollectionViewType.TABLE ||
      viewType === CollectionViewType.LIST ||
      viewType === CollectionViewType.TIMELINE ||
      viewType === CollectionViewType.CALENDAR ||
      previewType === CoverType.NONE);

  const handleClick = (event: React.MouseEvent) => {
    if (isLocked) return;
    openPropertyWidget(
      property,
      event.currentTarget,
      isTimelineTable
        ? PropertyManagePlace.PROPERTY_LIST_TIMELINE_TABLE
        : PropertyManagePlace.PROPERTY_LIST
    );
  };

  return (
    <div
      ref={propertyRef}
      className={cx(
        'text-t2 animate-hover flex h-8 cursor-pointer items-center justify-between px-3',
        dragOverlay && 'cursor-[grabbing]'
      )}
      onClick={handleClick}
    >
      <div className="flex flex-1 items-center overflow-hidden">
        <div className="mr-1 flex h-5 w-5 items-center justify-center">
          <Icon name="IcMenu" className="text-grey4" size="normal" />
        </div>
        <Icon className="mr-1" size="middle" name={ICON_MAP[type]} />
        <span className="flex-1 overflow-hidden overflow-ellipsis whitespace-nowrap pr-2">
          {name}
        </span>
      </div>
      <Switch open={visible} disabled={disable || isLocked} onSwitch={handleSwitch} />
    </div>
  );
};
