import { getFileNameInfo, getFormatImageUrl } from '@flowus/common';
import { fixUrl } from '@flowus/common/embed-website';
import { ossImageSnapshotUrl } from '@flowus/common/url';
import { VITE_OSS_CDN_HOST } from 'src/env';
import { ViewPath } from 'src/utils';
import { validate } from 'uuid';

export const compressImageSupport = /^(jpg|jpeg|png|bmp|webp|tiff)$/i;

/**  */
export const getThumbnailsUrl = (
  imageUrl: string,
  opt?: { ossName?: string; resize?: number }
): string => {
  const { ossName, resize = 200 } = opt || {};
  const { extName } = getFileNameInfo(ossName);
  const noThumbnails = !compressImageSupport.test(extName);

  return noThumbnails
    ? imageUrl
    : getFormatImageUrl(ossImageSnapshotUrl(imageUrl, Math.floor(resize)), extName);
};

/**
 * 把url简化，比如http://www.baidu.com/xxoo/jjyy ==> www.baidu.com
 */
export function formatUrl(text: string) {
  try {
    const url = new URL(fixUrl(text));
    return url.hostname;
  } catch (e) {
    return text;
  }
}

export function getImageCdnUrl(ossName?: string) {
  if (!ossName) return '';
  /**
   * 由于oss压缩图片只支持特定几种格式，所以这里做个简单的判断
   */
  const index = ossName.lastIndexOf('.');
  const extName = ossName.substring(index + 1);
  let imgProcess = '';
  if (compressImageSupport.test(extName.toLocaleLowerCase())) {
    imgProcess = `x-oss-process=image/resize,w_${Math.floor(
      500 * Math.ceil(window.devicePixelRatio)
    )}/quality,q_100/`;
  }

  return `${VITE_OSS_CDN_HOST}${encodeURIComponent(ossName)}?${imgProcess}`;
}
export const parseCustomDomain = (pathname: string) => {
  if (
    checkViewPath(ViewPath.share, 'startsWith', pathname) ||
    checkViewPath(ViewPath.form, 'startsWith', pathname)
  ) {
    return;
  }
  const uuid = pathname.slice(-36);
  if (!validate(uuid)) return;
  // flowus.cn/domain/xxx
  // flowus.cn/domain/share/xxx
  const paths = pathname.split('/');
  if (paths.length >= 3 && paths.length <= 4) {
    return paths[1];
  }
};

export const checkViewPath = (
  _viewPath: ViewPath,
  mode: 'includes' | 'startsWith' | 'endsWith' = 'includes',
  pathname = location.pathname
) => {
  if (!pathname.endsWith('/')) {
    pathname = `${pathname}/`;
  }

  if (mode === 'startsWith') {
    return pathname.startsWith(_viewPath);
  }
  if (mode === 'endsWith') {
    return pathname.endsWith(_viewPath);
  }

  return pathname.includes(_viewPath);
};

export const changeDomain = (newDomain: string, location: Location) => {
  const { pathname } = location;
  if (pathname === `/${newDomain}`) return;
  const customDomain = parseCustomDomain(pathname);
  if (customDomain !== newDomain) {
    const customDomainIndex = pathname.indexOf(customDomain ?? '');
    const restPath = location.pathname.slice(customDomainIndex + (customDomain?.length ?? 0));
    return newDomain ? `/${newDomain}${restPath}` : `${restPath}`;
  }
};

const DOMAIN_NUM = ['test', 'staging', 'dev', 'alpha'].includes(import.meta.env.VITE_ENV) ? 4 : 3;

const isValidIP = (ip: string) => {
  const reg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  return reg.test(ip);
};

export const getDomain = () => {
  const domains = window.location.hostname.split('.');
  const [domain = ''] = domains;

  if (__PRIVATE__) return '';

  if (domains.length === DOMAIN_NUM && domain !== 'www') {
    if (isValidIP(window.location.hostname)) return;

    if (/flowus|allflow/.test(domain)) return;

    return domain;
  }
};

export const getUrlWithoutDomain = () => {
  const { hostname, protocol, port } = window.location;

  const domains = hostname.split('.');

  if (!__PRIVATE__) {
    if (domains.length === DOMAIN_NUM) {
      domains.shift();
    }
  }

  return `${protocol}//${domains.join('.')}${port ? `:${port}` : ''}`;
};
