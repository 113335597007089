import { fastEqual } from '@flowus/common/utils/tools';
import { PermissionRole } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { batch, useStore } from 'react-redux';
import type { Option } from 'src/bitable/table-view/types';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { UPDATE_BLOCK } from 'src/redux/actions';
import { getPermissions } from '../share/use-permissions';
import { useTransaction } from '../use-transaction';
import type { RootState } from 'src/redux/types';

export const useUpdatePropertyOption = () => {
  const { getState, dispatch } = useStore();
  const transaction = useTransaction();

  const updatePropertyOption = (
    collectionId: string,
    propertyId: string,
    option: Partial<Option> & { id: string }
  ) => {
    transaction(() => {
      const { blocks } = getState() as RootState;
      const collection = blocks[collectionId];
      if (!collection) return;

      const { [propertyId]: property } = collection.data.schema ?? {};
      if (!property) return;

      const options = property.options ?? [];
      const oldOption = options.find((item) => item.id === option.id);
      if (!oldOption) return;

      const newOption = { ...oldOption, ...option };
      if (fastEqual(newOption, oldOption)) return;

      const newOptions = options.map((item) => (item.id === option.id ? newOption : item));

      // update collection schema
      dispatch(
        UPDATE_BLOCK({
          uuid: collectionId,
          path: ['data', 'schema'],
          patch: {
            data: {
              schema: {
                [propertyId]: {
                  ...property,
                  options: newOptions,
                },
              },
            },
          },
        })
      );

      if (fastEqual(newOption, oldOption)) return;

      batch(() => {
        collection.subNodes.forEach((id: string) => {
          const record = blocks[id];
          if (!record) return;

          const { role } = getPermissions(id);
          if (role === PermissionRole.READER) return;

          const value = segmentsToText(record.data.collectionProperties?.[propertyId]);
          if (!value) return;

          let dirty = false as boolean;

          const newValue = value
            .split(',')
            .map((text) => {
              if (!text) return '';
              if (text === oldOption.value) {
                dirty = true;
                return newOption.value;
              }
              return text;
            })
            .filter((v) => !!v)
            .join(',');

          if (dirty) {
            dispatch(
              UPDATE_BLOCK({
                uuid: id,
                path: ['data', 'collectionProperties'],
                patch: {
                  data: {
                    collectionProperties: {
                      [propertyId]: textToSegments(newValue),
                    },
                  },
                },
              })
            );
          }
        });
      });
    });
  };

  return useCallback(updatePropertyOption, [dispatch, getState, transaction]);
};
