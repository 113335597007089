import type { createRequest } from '@next-space/fe-api-idl';

interface UploadInjector {
  request: {
    infra: ReturnType<typeof createRequest>['infra'];
    editor: ReturnType<typeof createRequest>['editor'];
  };
  ossCallbackHost: string;
  onError?: (error: any) => void;
}

export let uploadInjector: UploadInjector = {
  request: {
    infra: {} as any,
    editor: {} as any,
  },
  ossCallbackHost: 'http://dev.allflow.cn',
  onError: () => {},
};

export const uploadInit = (injector: UploadInjector) => {
  uploadInjector = injector;
};
