import { useCallback } from 'react';
import { $appUiStateCache } from 'src/services/app';
import { useIsInRight, useMasterHistory } from 'src/utils/right-utils';
import { useOpenInRight } from '../page/use-open-in-right';
import { useCurrentUser } from '../user';

// 与 useOpenPage 方法很相似，目前主要用在打开新建页面
export const useOpenNew = () => {
  const history = useMasterHistory();
  const isInRight = useIsInRight();
  const openInRight = useOpenInRight();
  const { setting = {} } = useCurrentUser();

  const openNew = (uuid?: string, jump?: boolean) => {
    if (!uuid) return;

    if (!jump && !isInRight && !$appUiStateCache.$isMobileSize && setting.useRightPageByNew) {
      openInRight(uuid);
      return;
    }

    history.push(`/${uuid}`);
  };

  return useCallback(openNew, [history, isInRight, openInRight, setting.useRightPageByNew]);
};
