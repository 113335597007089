import { cx } from '@flowus/common/cx';
import { Tooltip } from '@flowus/common/tooltip';
import { type FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { useRelationMap } from 'src/bitable/relation-context';
import { Icon } from 'src/common/components/icon';
import { useSubitemStyle } from 'src/hooks/block/use-open-subitem';
import { useOpenPageWay } from 'src/hooks/collection-view/use-collection-view';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { SegmentPlainText } from 'src/views/main/aside/toc/helper';

interface Props {
  recordId: string;
  className?: string;
}
/** 子任务平铺列表样式ui */
export const SubitemParent: FC<Props> = (props) => {
  const { viewId } = useBitable();
  const { child2ParentMap } = useRelationMap();
  const parentRecords = child2ParentMap?.get(props.recordId) ?? [];
  const subitemStyle = useSubitemStyle(viewId);
  const openPage = useOpenPage();
  const openWay = useOpenPageWay(viewId);
  if (parentRecords.length === 0) return null;
  if (subitemStyle === 'Flattenlist') {
    return (
      <Tooltip
        popup="按住Alt点击可右侧打开"
        delay={{ open: 1000 }}
        flexCenter={false}
        className={cx('px-2 mx-1', props.className)}
      >
        <div
          className="text-grey4 flex items-center text-t4-medium animate-hover"
          onClick={(event) => {
            event.stopPropagation();
            openPage(parentRecords[0]!, {
              forceOpenInRight: event.altKey,
              openWay,
              forceOpenNewTab: event.ctrlKey || event.metaKey,
            });
          }}
        >
          <div className="flex items-center">
            <Icon
              name={'IcMove'}
              size="xxsmall"
              style={{
                transform: 'rotate(-90deg)',
              }}
            />
            {/* 只显示第一个，notion也是这样 */}
            <SegmentPlainText uuid={parentRecords[0]!} />
          </div>
        </div>
      </Tooltip>
    );
  }
  return null;
};
