import { intersection } from 'lodash-es';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useBlockStyle } from 'src/hooks/block/use-block-style';
import { usePickBlock } from 'src/utils/pick-block';
import { getBlockRender } from './block-element';

interface Props {
  uuid: string;
  root?: boolean;
  renderIds: string[];
}

export const BlockRender: FC<Props> = ({ uuid, renderIds }) => {
  const block = usePickBlock(uuid, ['subNodes']);
  const blockStyle = useBlockStyle(uuid);

  const Element = getBlockRender(block?.type);
  const ids = intersection(block?.subNodes, renderIds);

  const subChild = useMemo(() => {
    return ids.map((uuid) => <BlockRender uuid={uuid} key={uuid} renderIds={renderIds} />);
  }, [ids, renderIds]);

  if (!block) return null;

  return (
    <div style={blockStyle} className="pointer-events-none">
      <Element uuid={uuid}>{subChild}</Element>
    </div>
  );
};
