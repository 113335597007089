// 使用时注意 PREFIX 不带 /
// VITE_PREFIX = https://flowus.cn
// VITE_CDN_HOST = https://cdn.flowus.cn/

export const {
  VITE_PREFIX = 'https://flowus.cn',
  VITE_CDN_HOST,
  VITE_STRIPE_PUBLIC_KEY,
  VITE_OSS_CDN_HOST,
  VITE_SUBSCRIBE_ID,
  VITE_NOTION_CLIENT_ID,
} = import.meta.env;

export const PROXY_TARGET = __PROXY_TARGET__;

export const isBuildIn = () => {
  try {
    return __BUILD_IN__;
  } catch {
    return import.meta.env.VITE_PRODUCT === 'buildin';
  }
};
