import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * T是接口返回的对象
 * R是需要转换的对象
 *
 * 流程是searchController会调用adapter的search拿到结果T[]，然后再调用covert转换成业务需要的结果R[]，最终会给业务使用
 * 这个hook是跟InfiniteScroll搭配使用的，为了更简单的实现通用的加载更多功能
 * PS:判断是否可以加载更多，不是用total判断的，是根据拉回来的数据是否小于pageSize来的判断的
 * (这个判断逻辑下，当最后一次的返回页数刚好是pageSize的时候，会认为还有数据需要加载，所以下拉会多加载一次，无伤大雅，暂时先这么着吧)
 */
export interface SearchAdapter<T, R> {
  search: (pageNumber: number, keyword: string, pageSize: number) => Promise<T[]>;
  convert: (result: T[]) => Promise<R[]>;

  // getDefaultList?: () => R[] | undefined;
  // 每次请求个数，默认是10
  getPerPage?: () => number;
  // 默认是false，搜索关键字为空则会使用getDefaultList的值，没有就用空数组。
  // 如果为true，则搜索的时候关键字为'',这个是给网盘图片使用的
  canSearchWithEmpty?: boolean;
  defaultList?: R[];
}

const DEFAULT_PAGE_SIZE = 10;

function getPerPageSize(adapter: SearchAdapter<any, any>) {
  return adapter.getPerPage ? adapter.getPerPage() : DEFAULT_PAGE_SIZE;
}

export function useSearchController<T, R>(adapter: SearchAdapter<T, R>) {
  const hasMore = useRef(false);
  const firstTime = useRef(true);
  const [resultList, setResultList] = useState<R[]>([]);
  const [loading, setLoading] = useState(false);
  const search = useCallback(
    async (pageNumber: number, keyword: string) => {
      if (!keyword && !adapter.canSearchWithEmpty) {
        setResultList(adapter.defaultList ?? []);
        return;
      }
      setLoading(true);
      const res = await adapter
        .search(pageNumber, keyword.trim(), getPerPageSize(adapter))
        .catch(() => {
          hasMore.current = false;
          firstTime.current = false;
          setLoading(false);
        });
      firstTime.current = false;
      if (!res) return;
      const finalResult = await adapter.convert(res).catch(() => {
        hasMore.current = false;
        setLoading(false);
      });
      if (!finalResult) return;

      setLoading(false);
      hasMore.current = finalResult.length >= getPerPageSize(adapter);
      setResultList((preState) => {
        let newPageList: R[] = [];
        if (pageNumber === 1) {
          newPageList = [...finalResult];
        } else {
          newPageList = [...preState, ...finalResult];
        }
        return newPageList;
      });
    },
    [adapter]
  );

  useEffect(() => {
    setResultList(adapter.defaultList ?? []);
  }, [adapter]);

  return {
    firstTime: firstTime.current,
    resultList,
    loading,
    hasMore: !loading && hasMore.current,
    search,
  };
}
