import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { $searchParams } from 'src/utils';
import { getLocationOrigin, previewPrefix } from 'src/utils/location-utils';
import { Caption } from '../caption';
import { useViewModelController } from './hook';
import { ViewModelToolBar } from './toolbar';
import type { ViewModeFcProps } from './type';

const fileContentClass = 'pr-10 cursor-pointer text-ellipsis flex w-full';

// #region normal
export const ViewModelNormal: FC<ViewModeFcProps> = (props) => {
  const { root, uuid, title, context, iconName, isFile, viewModelToolBarData, ownerBlockId } =
    props;

  const { illegal, isFail, toolBarProps, openFilePreview, blockRef, isMobileSize, hasOverlay } =
    useViewModelController(props);

  const openPage = useOpenPage();

  const FileContent = (
    <>
      <IconTrigger
        hasOverlay={hasOverlay}
        trigger={!isFile}
        className="mr-1 self-center"
        blockId={uuid}
        iconSize={18}
        defaultIcon={<Icon size="middle" name={iconName} />}
      />
      <div className="flex max-w-full">
        <div className="relative w-full text-t1-medium text-ellipsis">
          {title}
          <span className="absolute bottom-px left-0 h-px bg-grey5 w-full" />
        </div>
        <span className="ml-2 text-t4 self-center mt-1 text-grey3 flex-shrink-0">{context}</span>
      </div>
    </>
  );

  return (
    <BlockDrop
      blockRef={blockRef}
      id={ownerBlockId ?? uuid}
      horizontal={root}
      className={cx('my-px', isFail && 'opacity-50 text-grey3', illegal && 'opacity-30')}
      data-draggable
    >
      <div className="pl-0.5 line-default animate-hover group leading-9 flex justify-between items-center h-9 overflow-x-auto">
        {isFile ? (
          $searchParams.print ? (
            <a
              href={`${getLocationOrigin()}/${previewPrefix()}/${uuid}`}
              className={fileContentClass}
            >
              {FileContent}
            </a>
          ) : (
            <div
              className={cx('preview-file', fileContentClass)}
              onClick={() => openFilePreview(uuid)}
            >
              {FileContent}
            </div>
          )
        ) : (
          <div
            onClick={(event) => {
              openPage(uuid, {
                forceOpenInRight: event.altKey,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              });
            }}
            className={fileContentClass}
          >
            {FileContent}
          </div>
        )}
        {!isMobileSize && (
          <ViewModelToolBar
            splitLine={[1]}
            {...toolBarProps}
            {...viewModelToolBarData}
            className="hidden group-hover:flex"
          />
        )}
      </div>
      <Caption blockId={uuid} />
    </BlockDrop>
  );
};
// #endregion
