// 测试环境模板的数据地址 https://test.allflow.cn/4ebb17ef-d747-471d-9c28-4363d4522749
// 下面是表头对应的字段。
export const TEMPLATE_CATEGORY = '模板类别';
export const ONE_STAGE_CATEGORY = '一级分类';
export const TWO_STAGE_CATEGORY = '二级分类';
export const THREE_STAGE_CATEGORY = '三级分类';
export const TEMPLATE_HOME_PAGE_TOP = '首页置顶';
export const TEMPLATE_NAME = '模板名称';
export const TEMPLATE_LINK = '模板链接';
export const TEMPLATE_SOURCE = '模板来源';
export const TEMPLATE_SOURCE_IMAGE = '模板来源配图';
export const TEMPLATE_IMAGE = '模板介绍配图';
export const TEMPLATE_RELEASE = '上线';
export const TEMPLATE_DESC = '模板介绍';
export const TEMPLATE_URL = '模板链接';
export const TEMPLATE_RECOMMEND_TAG = '推荐标签';
export const TEMPLATE_EMPTY_RECOMMEND_TAG = '空白推荐';
