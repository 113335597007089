import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';
import { globalListenerHelper } from './global-listener-helper';

export enum ResizeStatus {
  START = 1,
  RESIZING = 2,
  END = 3,
}
/** 监听x/y或者两者的变化 */
export type ResizeType = 'x' | 'y' | 'all';
/**
 * resize返回的结果
 */
export interface ResizeResult {
  resizeStatus: ResizeStatus;
  downX: number;
  downY: number;
  moveX: number;
  moveY: number;
  deltaX: number;
  deltaY: number;
}

export enum ButtonType {
  leftClick = 'leftClick',
  rightCLick = 'rightCLick',
  All = 'All', // 监听左右按键
}
interface Options {
  onResize: (result: ResizeResult) => boolean | void;
  type?: ResizeType;
  moveSlop?: number;
  buttonType?: ButtonType;
  dispatchEvent?: boolean; // 是否继续分发事件
  compactMouseUp?: boolean; //兼容一下其他网页的mouseup不回调，仅插件使用
}
/**
 * 这个文件有bug的话请务必交给liangxiwei处理，这类逻辑细节超过一个人改动的话，会导致后面无法维护
 * @param elementRef
 * @param option
 */
export const useResizeListener = (elementRef: RefObject<HTMLElement | null>, option: Options) => {
  const {
    type = 'all',
    moveSlop = 1,
    buttonType = ButtonType.leftClick,
    onResize,
    dispatchEvent = false,
    compactMouseUp = false,
  } = option;

  const resizeListenerRef = useRef(onResize);
  resizeListenerRef.current = onResize;
  const onDownRef = useRef(false);
  const isTriggerMove = useRef(false);
  useEffect(() => {
    const element = elementRef.current;
    if (!element) {
      return;
    }
    let downX = 0;
    let downY = 0;
    let lastMoveX = 0;
    let lastMoveY = 0;
    let resizeResult: ResizeResult | undefined;
    const mouseDown = (e: MouseEvent) => {
      if (!resizeListenerRef.current) return;
      // 0:normal click  2:right click  doc:https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
      switch (buttonType) {
        case ButtonType.leftClick: {
          if (e.button !== 0) {
            return;
          }
          break;
        }
        case ButtonType.rightCLick: {
          if (e.button !== 2) return;
          break;
        }
        case ButtonType.All: {
          if (e.button !== 0 && e.button !== 2) return;
          break;
        }
        default:
      }
      if (!dispatchEvent) {
        e.stopPropagation();
        e.preventDefault();
      }

      onDownRef.current = true;
      downX = e.clientX;
      downY = e.clientY;
      resizeResult = {
        resizeStatus: ResizeStatus.START,
        downX,
        downY,
        moveX: 0,
        moveY: 0,
        deltaX: 0,
        deltaY: 0,
      };
      const handle = resizeListenerRef.current(resizeResult);
      if (handle) {
        onDownRef.current = false;
        resizeResult = undefined;
        lastMoveX = 0;
        lastMoveY = 0;
        downX = 0;
        downY = 0;
      }
    };
    const mouseMove = (e: MouseEvent) => {
      if (!resizeListenerRef.current || !onDownRef.current || !resizeResult) return;
      e.stopPropagation();
      const moveX = e.clientX - downX;
      const moveY = e.clientY - downY;
      const deltaX = moveX - lastMoveX;
      const deltaY = moveY - lastMoveY;
      let needUpdateLastMovement = false;
      switch (type) {
        case 'x': {
          needUpdateLastMovement = Math.abs(deltaX) > moveSlop;
          break;
        }
        case 'y': {
          needUpdateLastMovement = Math.abs(deltaY) > moveSlop;
          break;
        }
        case 'all': {
          needUpdateLastMovement = Math.abs(deltaX) > moveSlop || Math.abs(deltaY) > moveSlop;
          break;
        }
        default: // do nothing
      }
      if (needUpdateLastMovement) {
        isTriggerMove.current = true;
        resizeResult.moveX = moveX;
        resizeResult.moveY = moveY;
        resizeResult.deltaX = deltaX;
        resizeResult.deltaY = deltaY;
        resizeResult.resizeStatus = ResizeStatus.RESIZING;
        lastMoveX = moveX;
        lastMoveY = moveY;
        resizeListenerRef.current(resizeResult);
      }
    };
    const mouseUp = (e: MouseEvent) => {
      if (!resizeListenerRef.current || !onDownRef.current || !resizeResult) return;

      onDownRef.current = false;
      const moveX = e.clientX - downX;
      const moveY = e.clientY - downY;
      const deltaX = moveX - lastMoveX;
      const deltaY = moveY - lastMoveY;

      resizeResult.moveX = moveX;
      resizeResult.moveY = moveY;
      resizeResult.deltaX = deltaX;
      resizeResult.deltaY = deltaY;
      resizeResult.resizeStatus = ResizeStatus.END;
      resizeListenerRef.current(resizeResult);
      // reset all field
      resizeResult = undefined;
      lastMoveX = 0;
      lastMoveY = 0;
      downX = 0;
      downY = 0;
      if (isTriggerMove.current) {
        e.stopPropagation();
        isTriggerMove.current = false;
      }
    };
    element.addEventListener('mousedown', mouseDown, true);
    globalListenerHelper.addEventListener('mousemove', mouseMove);
    globalListenerHelper.addEventListener('mouseup', mouseUp);
    //有写网页兼容性又问题，mouseup不回调，这里回调一下
    if (compactMouseUp) {
      globalListenerHelper.addEventListener('click', mouseUp);
    }
    return () => {
      if (compactMouseUp) {
        globalListenerHelper.removeEventListener('click', mouseUp);
      }
      globalListenerHelper.removeEventListener('mouseup', mouseUp);
      globalListenerHelper.removeEventListener('mousemove', mouseMove);
      element.removeEventListener('mousedown', mouseDown, true);
    };
  }, [buttonType, compactMouseUp, dispatchEvent, elementRef, moveSlop, type]);
};
