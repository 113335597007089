import type { PDFFormat } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';

import { useTransaction } from '../use-transaction';

/**
 * 创建新记录, 默认不排序
 * @returns
 */

export const useUpdatePDFFormat = () => {
  const transaction = useTransaction();

  const updatePDFFormat = (blockId: string, patch: Partial<PDFFormat>, ignoreOP = false) => {
    const update = () => {
      const { blocks } = getState();
      const block = blocks[blockId];
      if (!block) return;

      updateBlock(blockId, {
        data: {
          format: {
            pdfFormat: {
              ...block.data.format?.pdfFormat,
              ...patch,
            },
          },
        },
      });
    };

    if (ignoreOP) {
      update();
    } else {
      transaction(update);
    }
  };

  return useCallback(updatePDFFormat, [transaction]);
};
