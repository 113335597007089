import { cx } from '@flowus/common/cx';
import { latexRender } from '@flowus/common/latex';
import isHotkey from 'is-hotkey';
import { useEffect, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Button } from 'src/common/components/button';
import { insertString } from 'src/utils/string-util';
import { EquationTemplate } from './equation-template';

interface InputPanelProps {
  singleLine?: boolean;
  content: string;
  onChange: (newLatex: string) => void;
  onSubmit: (newLatex: string) => void;
}
export const InputPanel = (props: InputPanelProps) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [tmpContent, setTmpContent] = useState<string>(props.content);
  const [error, setError] = useState('');
  const cursor = useRef({
    left: 0,
    right: 0,
  });

  const updateCursor = (left?: number, right?: number) => {
    cursor.current = {
      left: left ?? cursor.current.left,
      right: right ?? cursor.current.right,
    };
  };

  useEffect(() => {
    if (!ref.current) return;
    ref.current.setSelectionRange(ref.current.value.length, ref.current.value.length);
    updateCursor(0, ref.current.value.length);
  }, []);

  useEffect(() => {
    const { errorMsg } = latexRender(tmpContent);
    setError(errorMsg);
  }, [tmpContent]);

  const handleClick = (str: string) => {
    const newStr = insertString(
      tmpContent,
      str,
      cursor.current.left,
      cursor.current.right - cursor.current.left
    );
    setTmpContent(newStr);
    props.onChange(newStr);
    const newIndex = cursor.current.left + str.length;
    ref.current?.focus();
    updateCursor(newIndex, newIndex);
    setTimeout(() => {
      ref.current?.setSelectionRange(newIndex, newIndex);
    }, 0);
  };

  const SubBtn = (
    <Button
      disable={!!error}
      colorType="active"
      onClick={() => props.onSubmit(tmpContent)}
      className={cx('flex-shrink-0 self-start', error && 'opacity-90')}
    >
      提交
    </Button>
  );

  return (
    <div className="latex-input-panel flex flex-col width-600 max-h-[450px] next-modal">
      <div className={'flex py-3 px-3.5'}>
        <div className="flex w-full flex-col justify-center overflow-y-hidden h-full">
          <AutoHeightTextArea
            onBlurCapture={(e) => {
              updateCursor(e.currentTarget.selectionStart, e.currentTarget.selectionEnd);
              e.currentTarget.focus();
              e.currentTarget.setSelectionRange(cursor.current.left, cursor.current.right);
            }}
            ref={ref}
            autoFocus
            autoSelectAll
            value={tmpContent}
            shiftEnter
            onChange={(event) => {
              setTmpContent(event.target.value);
              props.onChange(event.target.value);
            }}
            placeholder="使用 LaTeX 语法输入文本，例 E = MC^2"
            className="placeholder-grey4 overflow-auto max-h-[200px] pr-1 "
            boxClassName={cx('w-full', props.singleLine ? '' : 'min-h-[70px]')}
            fontClassName="whitespace-pre-wrap "
            onKeyDown={(event) => {
              updateCursor(event.currentTarget.selectionStart, event.currentTarget.selectionEnd);
              if (isHotkey('shift+enter')(event)) return;
              if (isHotkey('enter')(event)) {
                event.preventDefault();
                // if (result.error) return;
                props.onSubmit(tmpContent);
              } else if (isHotkey('mod+z')(event)) {
                event.stopPropagation(); // 为了不触发全局监听
              } else if (isHotkey(['ArrowLeft', 'shift+ArrowLeft'])(event)) {
                event.stopPropagation();
              } else if (isHotkey(['ArrowRight', 'shift+ArrowRight'])(event)) {
                event.stopPropagation(); // 左右键不知道被谁监听了还吃掉了，这个问题很早的版本就有了
              }
            }}
          />
          {error && <div className="flex items-center h-9 text-red text-t4">{error}</div>}
        </div>
        {SubBtn}
      </div>
      <EquationTemplate onClick={handleClick} />
    </div>
  );
};
