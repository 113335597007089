import { Uuid } from '@flowus/common';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { useFinalValue } from '@flowus/common/hooks/react-utils';

import { CommentEditor } from './comment-editor';

export const PopupCommentEditor = (props: {
  initialText?: SegmentDTO[] | undefined;
  onComment?: (value: SegmentDTO[] | undefined) => void;
}) => {
  const uuid = useFinalValue(() => Uuid.v4());
  return (
    <div className="next-modal w-[500px] p-1">
      <CommentEditor
        autoFocus
        draftKey={uuid}
        noSaveDraft
        showAvatar={false}
        initialText={props.initialText}
        onComment={props.onComment}
      />
    </div>
  );
};
