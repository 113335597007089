import type { FC } from 'react';
import { createContext, useContext, useRef } from 'react';
import { ReadonlyContext } from 'src/hooks/block/use-block-locked';
import type { Toc } from 'src/views/main/aside/toc';
import type { SearchItem } from '../search';

interface Props {
  onSelect(pageInfo: SearchItem): void;
}

export const TocList: FC<Props> = (props) => {
  const onSelectRef = useRef(props.onSelect);
  onSelectRef.current = props.onSelect;
  return (
    <TocListContext.Provider value={onSelectRef.current}>
      <ReadonlyContext.Provider value={true}>
        <TocComponent className="h-[300px] pt-0" />
      </ReadonlyContext.Provider>
    </TocListContext.Provider>
  );
};

let TocComponent: typeof Toc;

// fix recycle
export const set_TocComponent = (component: any) => {
  TocComponent = component;
};

const TocListContext = createContext<Props['onSelect'] | undefined>(undefined);
export const useTocListOnSelect = () => {
  return useContext(TocListContext);
};
