import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { deepEqualLoose } from '@flowus/common/utils/tools';
import { RichTextDiff } from 'src/components/rich-text-diff';
import { normalizeSegments } from 'src/editor/utils/segments';

export const TableRowChange: FC<{
  from: BlockDTO | undefined;
  to: BlockDTO | undefined;
  onClick?: () => void;
}> = ({ from, to, onClick }) => {
  const propsA = from?.data.collectionProperties ?? {};
  const propsB = to?.data.collectionProperties ?? {};
  const propSet = new Set([...Object.keys(propsA), ...Object.keys(propsB)]);
  const changes: ReactNode[] = [];
  for (const prop of propSet) {
    const segmentsA = propsA[prop];
    const segmentsB = propsB[prop];
    if (!deepEqualLoose(normalizeSegments(segmentsA), normalizeSegments(segmentsB))) {
      changes.push(
        <div key={changes.length} className="mx-1 mt-1.5 max-w-full border border-grey6 p-2">
          <RichTextDiff segmentsA={segmentsA} segmentsB={segmentsB} className="!break-words" />
        </div>
      );
    }
  }

  if (changes.length === 0) return null;

  return (
    <div className="mt-1 px-1 animate-hover flex flex-wrap" onClick={onClick}>
      {changes}
    </div>
  );
};
