import { Context } from '../../core/context';
import {
  IPropertyIdentValueDescriptor,
  PropertyDescriptorParsingType,
} from '../IPropertyDescriptor';
export const enum FLOAT {
  NONE = 0,
  LEFT = 1,
  RIGHT = 2,
  INLINE_START = 3,
  INLINE_END = 4,
}

export const float: IPropertyIdentValueDescriptor<FLOAT> = {
  name: 'float',
  initialValue: 'none',
  prefix: false,
  type: PropertyDescriptorParsingType.IDENT_VALUE,
  parse: (_context: Context, float: string) => {
    switch (float) {
      case 'left':
        return FLOAT.LEFT;
      case 'right':
        return FLOAT.RIGHT;
      case 'inline-start':
        return FLOAT.INLINE_START;
      case 'inline-end':
        return FLOAT.INLINE_END;
    }
    return FLOAT.NONE;
  },
};
