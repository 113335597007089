import type { IEditorModel } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { map, throttleTime } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { ToolbarButton, tooltipShortcut } from 'src/editor/editor/uikit/editable-toolbar/button';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useShortcutCode } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-code';
import { useShortcutEquation } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-equation';
import { useShortcutFormat } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-format';
import { useShortcutToolBar } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-toolbar';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';

interface Props {
  uuid: string;
  model: IEditorModel;
}
/** modify from editor-toolbar */
export const MindNodeEditorToolbar: FC<Props> = (props) => {
  const { uuid, model } = props;
  const getEditorModal = useGetOrInitEditorModel();
  const shortcutEquation = useShortcutEquation({});
  const { queryResult } = useShortcutToolBar(uuid);
  const shortcutFormat = useShortcutFormat();
  const shortcutCode = useShortcutCode({});
  const { inCode, inEquation, disableEquation } = queryResult;
  const format = useObservable(
    () =>
      model.onFormatChange.pipe(
        throttleTime(20),
        map(() => model.format)
      ),
    model.format,
    [model]
  );
  // 这里是处理多个块被选中的情况
  const selectAll = (blockId: string) => {
    const modal = getEditorModal(blockId);
    modal?.performChange((ctx) => {
      ctx.select(0, modal.content.length);
    });
  };

  return (
    <div
      className="flex items-center next-modal"
      data-no-cancel-selected
      onPointerDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ToolbarButton
        title={tooltipShortcut('加粗', `B`)}
        icon="IcBold"
        highlight={format.bold}
        onClick={(event) => {
          bizTracker.event('rtf_choose', { rtf_name: 'bold' });
          bizTracker.event('node_richtext');
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutFormat(selectedBlock.blockId, 'bold');
          });
        }}
      />
      <ToolbarButton
        title={tooltipShortcut('斜体', `I`)}
        icon="IcItalic"
        highlight={format.italic}
        onClick={(event) => {
          bizTracker.event('rtf_choose', { rtf_name: 'italic' });
          bizTracker.event('node_richtext');
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutFormat(selectedBlock.blockId, 'italic');
          });
        }}
      />
      <ToolbarButton
        title={tooltipShortcut('下划线', `U`)}
        icon="IcUnderline"
        highlight={format.underline}
        onClick={(event) => {
          bizTracker.event('rtf_choose', { rtf_name: 'underline' });
          bizTracker.event('node_richtext');
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutFormat(selectedBlock.blockId, 'underline');
          });
        }}
      />
      <ToolbarButton
        title={tooltipShortcut('中划线', `Shift+S`)}
        icon="IcDelete"
        highlight={format.lineThrough}
        onClick={(event) => {
          bizTracker.event('rtf_choose', { rtf_name: 'deleteline' });
          bizTracker.event('node_richtext');
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutFormat(selectedBlock.blockId, 'lineThrough');
          });
        }}
      />
      <ToolbarButton
        title={tooltipShortcut('代码', `E`)}
        icon="IcCode"
        highlight={inCode}
        onClick={(event) => {
          bizTracker.event('rtf_choose', { rtf_name: 'code' });
          bizTracker.event('node_richtext');
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutCode(selectedBlock.blockId);
          });
        }}
      />
      <ToolbarButton
        title={tooltipShortcut('公式', `Shift+X`)}
        disabled={disableEquation}
        icon="IcEquation"
        boxClassName="border-r border-grey6"
        highlight={inEquation}
        onClick={(event) => {
          const { selection } = model;
          if (selection === null) return;
          bizTracker.event('node_richtext');
          bizTracker.event('rtf_choose', { rtf_name: 'equation' });
          event.stopPropagation();
          cache.ui.selectedBlocks.forEach((selectedBlock) => {
            selectAll(selectedBlock.blockId);
            shortcutEquation(selectedBlock.blockId);
          });
        }}
      />
      {/* <ToolbarButton
        title="链接"
        label="Link"
        boxClassName="border-r border-grey6"
        disabled={disableLink}
        arrow
        labelBorder
        highlight={inLink !== null}
        onClick={(event) => {
          const { selection } = model;
          if (selection === null) return;
          // 阻止pageDoc/index.ts里的handleClick
          event.stopPropagation();
          event.preventDefault();
          bizTracker.event('rtf_choose', { rtf_name: '链接' });
          shortcutLink();
        }}
      /> */}
    </div>
  );
};
