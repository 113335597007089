import type { fastEqual } from '@flowus/common';
import type { RootState } from 'src/redux/types';

export const LEADING = true;
export const TRAILING = true;
export const DEBOUNCE_WAIT_NUM = 20;
export const THROTTLE_WAIT_NUM = 30;

export interface CommonOptionsType {
  waitMode?: 'debounce' | 'throttle' | 'none';
  useRedux?: boolean;
  wait?: number;
  useRx?: boolean;
  equal?: typeof fastEqual;
  allUser?: boolean;
  allDiscussions?: boolean;
  hasSelectBlock?: boolean;
  /** 是否启用监听，在明确 case 的情况下可以减少很多不必要的操作 */
  enable?: boolean;
  /** 是否只监听 permission 变化 */
  onlyPermissions?: boolean;
  /** 是否开启调度等待，默认开启 */
  enableAuditTime?: boolean;
  /** 是否忽略深层次的数据变更，只判断一级数据 */
  ignoreDeep?: boolean;
  /** 不检测额外数据,比如 created 信息，version */
  ignoreOtherData?: boolean;
  /** 默认使用原生对象，不实用 proxy 对象，但这个行为就需要自己手动传入 deps 监听 id */
  enableProxyState?: boolean;
}

export interface ObservableStoreProps {
  dep: any[];
  opt?: CommonOptionsType & {
    obsSelectBlocks?: {
      blockId?: string;
      viewId?: string;
      syncId?: string;
      all?: boolean;
    }[];
    obsSelectCell?: {
      recordId?: string;
      propertyId?: string;
      viewId?: string;
      groupValue?: string;
      all?: boolean;
    }[];
    /** 是否监听简单表格的操作 */
    obsSimpleTable?: boolean;
    log?: boolean;
    /** @param default true */
    trailing?: boolean;
    /** @param default true */
    leading?: boolean;
  };
}

export interface ObservableStore extends RootState {}

export type ObservableStoreSelector<T> = (store: ObservableStore) => T;
