import { setPermission as _setPermission } from '@flowus/common/block/permissions';
import { URL_SPLIT_TEXT } from '@flowus/common/const';
import { SearchParams } from '@flowus/login/const';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { useSpaceDomainPath } from 'src/hooks/space';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import type { User } from './types';
import { useCurrentUser } from 'src/hooks/user';

export const setPermission = (uuid: string, item: User) => {
  _setPermission(uuid, item, {
    removeBlockPermission,
    setBlockPermission,
  });

  // const permission: PermissionDTO = {
  //   role: item.role,
  //   type: item.type,
  // };

  // if (item.type === PermissionType.GROUP) {
  //   permission.groupId = item.groupId;
  // }

  // if (item.type === PermissionType.USER) {
  //   if (item.isGuest) {
  //     permission.isGuest = item.isGuest;
  //   }
  //   permission.userId = item.userId;
  // }

  // if (item.role === PermissionRole.NONE) {
  //   removeBlockPermission(uuid, permission);
  // } else {
  //   setBlockPermission(uuid, permission);
  // }
};

export const isSamePermission = (a: PermissionDTO, b: PermissionDTO) => {
  return (
    (a.type === PermissionType.SPACE && a.type === b.type) ||
    (a.type === PermissionType.GROUP && a.groupId === b.groupId) ||
    (a.type === PermissionType.USER && a.userId === b.userId)
  );
};

export const useGetSharePageUrl = (uuid: string) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const domainPath = useSpaceDomainPath(block?.spaceId);
  const currentUser = useCurrentUser();

  return useCallback(
    (params?: { embed?: boolean; inviteCode?: string; password?: string }) => {
      const { embed, inviteCode = currentUser.inviteCode, password } = params ?? {};

      const urlObj = new URL(`${getLocationOrigin()}${domainPath}/share/${uuid}`);
      const text = `\n${URL_SPLIT_TEXT}${segmentsToText(block?.data.segments)}`;

      if (embed) {
        urlObj.searchParams.set(SearchParams.embed, 'true');
      }

      if (inviteCode) {
        urlObj.searchParams.set(SearchParams.inviteCode, inviteCode);
      }

      if (password) {
        urlObj.searchParams.set(SearchParams.password, password);
      }

      const url = urlObj.href;

      return {
        url,
        text,
        textAndUrl: `${url}${text}`,
      };
    },
    [block?.data.segments, currentUser.inviteCode, domainPath, uuid]
  );
};
