import type { FC } from 'react';
import { useEffect, useLayoutEffect } from 'react';
import type { CombineViewProps } from '../../types';
import { AccountStatus, ViewType } from '../../types';
import { useThirdPartLogin } from '../../hook/use-third-part-login';
import { UserAuthorizationType } from '@next-space/fe-api-idl';
import { useCheckComplete } from '../../hook/use-check-complete';
import { randomCode } from '../../utils';
import { Images } from '../../images';
import { loginInjector } from '../../utils/init';

/**微信登录文档: https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html */
export const BindWeChat: FC<CombineViewProps> = (props) => {
  const { accountStatus, authorizationCode, tryLogin } = useThirdPartLogin(
    UserAuthorizationType.WECHAT
  );
  const checkComplete = useCheckComplete(props.switchViewType);
  const { switchViewType } = props;

  useShowWechatQCode('wechat_login_container');

  useEffect(() => {
    switch (accountStatus) {
      case AccountStatus.NEED_BIND_PHONE: {
        if (authorizationCode) {
          switchViewType({
            type: ViewType.BIND_PHONE,
            props: {
              authorization: {
                code: authorizationCode,
                type: UserAuthorizationType.WECHAT,
              },
            },
          });
        }
        break;
      }
      default:
    }
  }, [authorizationCode, props, accountStatus, switchViewType]);

  useEffect(() => {
    const message = async (e: MessageEvent) => {
      try {
        //收到iframe地址变动就要获取微信的code去请求了
        const params = e.data;
        if (params?.state === randomCode) {
          if (props.onAuth) {
            //外部监听了内部就不做处理
            props.onAuth(params.code);
            return;
          }
          const user = await tryLogin(params.code, 'web');
          if (!user) return;
          if (!props.complete || checkComplete(user)) {
            props.onSuccess?.(user);
          }
        }
      } catch (e) {
        //ignore
      }
    };
    window.addEventListener('message', message);
    return () => {
      window.removeEventListener('message', message);
    };
  }, [checkComplete, props, switchViewType, tryLogin]);

  return (
    <div className="next-modal flex w-full flex-col items-center pb-8">
      {!props.onAuth && (
        <img
          src={Images.IcTitleClose}
          onClick={() => {
            switchViewType({
              type: ViewType.LOGIN,
            });
          }}
          className="mr-4 mt-4 h-5 w-5 self-end cursor-pointer dark:invert"
        />
      )}
      <img src={Images.wechatLogoLogin} className="mt-6" />
      <div id="wechat_login_container" className="mt-6" />
    </div>
  );
};

const useShowWechatQCode = (containerId: string) => {
  useLayoutEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined = undefined;
    timer = setInterval(() => {
      // @ts-ignore ignore
      if (typeof window.WxLogin === 'function') {
        timer && clearInterval(timer);
        // @ts-ignore ignore
        window.WxLogin({
          self_redirect: true,
          id: containerId,
          appid: loginInjector.wechatId,
          scope: 'snsapi_login',
          redirect_uri: `${location.origin}/auth-callback`,
          state: randomCode,
          style: '',
          href: '',
        });
      }
    }, 50);
  }, [containerId]);
};
if (typeof document !== 'undefined') {
  const element = document.getElementById('wechat_login_js');
  if (!element) {
    const script = document.createElement('script');
    script.id = 'wechat_login_js';
    script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    document.body.appendChild(script);
  }
}
