import type { FC } from 'react';
import { memo } from 'react';
import { antiCycleSet_BitableManager } from 'src/views/main/page-bitable';
import { EmbedManager } from './embed-manager';
import { PageManager } from './page-manager';

interface Props {
  className?: string;
  embed?: boolean;
}

export const BitableManager0: FC<Props> = ({ className, embed }) => {
  if (embed) {
    return <EmbedManager className={className} />;
  }
  return <PageManager className={className} />;
};

export const BitableManager = memo(BitableManager0);

antiCycleSet_BitableManager(BitableManager);
