import Color from 'color';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { getWindowDpr } from 'src/utils/dpr';

import type { PDFAnnotation, Point, Rect } from '../type';
import { AnnotationType } from '../type';
import { convertPageRectToPDFRect, convertPDFRectToPageRect } from '../utils';
import { convertPathToRect } from '../utils/convert-path-to-rect';
import { cropRectArea } from '../utils/crop-rect-area';
import { drawEllipse } from '../utils/draw-ellipse';
import { drawLine } from '../utils/draw-line';
import { CropShape } from '../utils/imagedata-to-image';
import { addResize } from '../utils/resize';

export class AnnotationRender {
  containers: Record<string, HTMLDivElement> = {};

  constructor(
    public pdfViewer: PDFViewer,
    public annotation: PDFAnnotation,
    public getAnnotationType: () => AnnotationType | undefined,
    public onUpdateAnnotation: (annotation: PDFAnnotation, needUpdateUI?: boolean) => void,
    public onDeleteAnnotation: (annotation: PDFAnnotation, needUpdateUI?: boolean) => void,
    public onContextMenu?: (event: MouseEvent, annotation: PDFAnnotation) => void
  ) {
    let allPageNumbers = [annotation.pageNumber];
    if (annotation.type === AnnotationType.HIGHLIGHT) {
      allPageNumbers = Object.keys(annotation.pdfRects ?? {}).map(Number);
    }

    allPageNumbers.forEach((page) => {
      const container = document.createElement('div');
      this.containers[page] = container;

      container.addEventListener('click', this.handleClick);
      container.addEventListener('contextmenu', (event) => {
        this.onContextMenu?.(event, this.annotation);
      });

      const annotationContainer = this.getAnnotationLayer(page);
      if (!annotationContainer) return;

      annotationContainer.appendChild(container);
    });

    this.renderAnnotation(annotation);
  }

  remove = () => {
    Object.values(this.containers).forEach((container) => {
      container.removeEventListener('click', this.handleClick);
      container.remove();
    });
  };

  update = (annotation: PDFAnnotation) => {
    this.annotation = annotation;

    Object.values(this.containers).forEach((container) => {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    });

    this.renderAnnotation(annotation);
  };

  handleClick = () => {
    if (this.getAnnotationType() === AnnotationType.ERASER) {
      this.onDeleteAnnotation(this.annotation, false);
      this.remove();
    }
  };

  renderAnnotation = (annotation: PDFAnnotation) => {
    const { type } = annotation;

    if (
      type === AnnotationType.RECTANGLE ||
      type === AnnotationType.HIGHLIGHT ||
      type === AnnotationType.UNDERLINE ||
      type === AnnotationType.STRIKETHROUGH
    ) {
      this.renderRect();
    } else if (type === AnnotationType.POLYGON || type === AnnotationType.ELLIPSE) {
      this.renderShape();
    }
  };

  renderRect = () => {
    const { type, pdfRects, color } = this.annotation;
    if (!pdfRects) return;

    Object.keys(pdfRects).forEach((pageNumber) => {
      const { viewport, div } = this.pdfViewer.getPageView(Number(pageNumber) - 1);
      const rects = pdfRects[pageNumber];
      if (!rects) return;
      const pageRects = convertPDFRectToPageRect(rects, viewport);

      pageRects.forEach((rect) => {
        const node = document.createElement('div');
        node.style.cssText = `
          position: absolute;
          left: ${rect.width > 0 ? rect.x : rect.x - Math.abs(rect.width)}px;
          width: ${Math.abs(rect.width)}px;
          background: ${Color(color).alpha(0.4).toString()};
        `;

        if (type === AnnotationType.UNDERLINE) {
          node.style.top = `${rect.y + rect.height}px`;
          node.style.height = '2px';
        } else if (type === AnnotationType.STRIKETHROUGH) {
          node.style.top = `${rect.y + rect.height / 2}px`;
          node.style.height = '2px';
        } else {
          node.style.top = `${rect.height > 0 ? rect.y : rect.y - Math.abs(rect.height)}px`;
          node.style.height = `${Math.abs(rect.height)}px`;
          node.style.background = `${Color(color).alpha(0.4).toString()}`;

          if (type === AnnotationType.RECTANGLE) {
            const onResizeEnd = async (rect: Rect) => {
              const blobUrl = await this.getBlobUrl(Number(pageNumber), rect);

              const newAnnotation: PDFAnnotation = {
                ...this.annotation,
                url: blobUrl,
                width: rect.width,
                height: rect.height,
                pdfRects: { [pageNumber]: convertPageRectToPDFRect([rect], viewport) },
              };

              this.annotation = newAnnotation;
              this.onUpdateAnnotation(newAnnotation, false);
            };

            addResize({ source: node, onResizeEnd, container: div });
          }
        }

        this.containers[pageNumber]?.appendChild(node);
      });
    });
  };

  renderShape = () => {
    const { pageNumber, pdfRects, type, color } = this.annotation;
    let { path } = this.annotation;

    const isEllipse = type === AnnotationType.ELLIPSE;
    const isPolygon = type === AnnotationType.POLYGON;

    if (isEllipse) {
      const rect = pdfRects?.[pageNumber]?.[0];
      if (!rect) return;
      path = [
        { x: rect.x, y: rect.y },
        { x: rect.xMax, y: rect.yMax },
      ];
    }
    if (!path) return;

    const { viewport, div } = this.pdfViewer.getPageView(pageNumber - 1);
    const canvas = document.createElement('canvas');

    const pagePaths = path.map((point) => {
      const [pdfX, pdfY] = viewport.convertToViewportPoint(point.x, point.y);
      return { x: pdfX, y: pdfY };
    });

    const containerRect = convertPathToRect(pagePaths);

    const radio = getWindowDpr();
    canvas.width = containerRect.width * radio;
    canvas.height = containerRect.height * radio;
    canvas.style.width = `${containerRect.width}px`;
    canvas.style.height = `${containerRect.height}px`;

    const container = this.containers[pageNumber];
    if (!container) return;
    container.style.cssText = `
      position: absolute;
      left: ${containerRect.left}px;
      top: ${containerRect.top}px;
      width: ${containerRect.width}px;
      height: ${containerRect.height}px;
    `;
    container.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.scale(radio, radio);
    ctx.fillStyle = Color(color).alpha(0.4).toString();

    const pathPercent: Point[] = [];
    const drawPaths = pagePaths.map((point) => {
      // css 点
      const drawPoint = { x: point.x - containerRect.left, y: point.y - containerRect.top };
      pathPercent.push({
        x: drawPoint.x / containerRect.width,
        y: drawPoint.y / containerRect.height,
      });

      return drawPoint;
    });

    if (isPolygon) {
      drawLine(ctx, drawPaths, { closePath: true, isFill: true });
    } else {
      const [point1, point2] = drawPaths;
      if (!point1 || !point2) return;
      drawEllipse(canvas, point1, point2);
    }

    const lastPagePath = pagePaths;
    const onResizeEnd = async (newRect: Rect) => {
      const { pageNumber, color } = this.annotation;
      const radio = getWindowDpr();

      const newDrawPaths: Point[] = [];
      const newPagePaths = lastPagePath
        .map((_, index) => {
          const percent = pathPercent[index];
          if (!percent) return undefined;

          newDrawPaths.push({
            x: percent.x * newRect.width,
            y: percent.y * newRect.height,
          });

          return {
            x: newRect.x + percent.x * newRect.width,
            y: newRect.y + percent.y * newRect.height,
          };
        })
        .filter((item): item is Point => !!item);

      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = newRect.width * radio;
      canvas.height = newRect.height * radio;
      canvas.style.width = `${newRect.width}px`;
      canvas.style.height = `${newRect.height}px`;

      ctx.fillStyle = Color(color).alpha(0.4).toString();

      ctx.scale(radio, radio);
      if (isPolygon) {
        drawLine(ctx, newDrawPaths, { closePath: true, isFill: true });
      } else {
        const [point1, point2] = newDrawPaths;
        if (!point1 || !point2) return;
        drawEllipse(canvas, point1, point2);
      }

      const blobUrl = await this.getBlobUrl(
        pageNumber,
        newRect,
        newPagePaths,
        isPolygon ? CropShape.POLYGON : CropShape.ELLIPSE
      );
      const pdfPath = newPagePaths.map((point) => {
        const [x, y] = viewport.convertToPdfPoint(point.x, point.y);
        return { x, y };
      });

      const newAnnotation: PDFAnnotation = {
        ...this.annotation,
        url: blobUrl,
        width: newRect.width,
        height: newRect.height,
      };
      if (isEllipse) {
        const firstPoint = pdfPath[0];
        const secondPoint = pdfPath[1];
        if (!firstPoint || !secondPoint) return;
        const leftTop = {
          x: Math.min(firstPoint.x, secondPoint.x),
          y: Math.max(firstPoint.y, secondPoint.y),
        };
        const rightBottom = {
          x: Math.max(firstPoint.x, secondPoint.x),
          y: Math.min(firstPoint.y, secondPoint.y),
        };

        newAnnotation.pdfRects = {
          [String(pageNumber)]: [
            { x: leftTop.x, y: leftTop.y, xMax: rightBottom.x, yMax: rightBottom.y },
          ],
        };
      } else {
        newAnnotation.path = pdfPath;
      }

      this.annotation = newAnnotation;
      this.onUpdateAnnotation(newAnnotation, false);
    };

    const onResizing = (newRect: Rect) => {
      canvas.width = newRect.width * radio;
      canvas.height = newRect.height * radio;
      canvas.style.width = `${newRect.width}px`;
      canvas.style.height = `${newRect.height}px`;

      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.scale(radio, radio);
      ctx.fillStyle = Color(color).alpha(0.4).toString();

      const newDrawPaths: Point[] = [];
      lastPagePath.forEach((_, index) => {
        const percent = pathPercent[index];
        if (!percent) return;

        newDrawPaths.push({
          x: percent.x * newRect.width,
          y: percent.y * newRect.height,
        });
      });

      if (isPolygon) {
        drawLine(ctx, newDrawPaths, { closePath: true, isFill: true });
      } else {
        const [point1, point2] = newDrawPaths;
        if (!point1 || !point2) return;
        drawEllipse(canvas, point1, point2);
      }
    };

    addResize({ source: container, onResizeEnd, onResizing, container: div });
  };

  /**
   * getBlobUrl
   * @param pageNumber 页码
   * @param pageRect 相对页面的 css 尺寸
   * @param path 相对页面的 path 坐标
   * @returns
   */
  getBlobUrl = async (pageNumber: number, pageRect: Rect, path?: Point[], shape?: CropShape) => {
    if (!this.pdfViewer) return;

    const { canvas } = this.pdfViewer.getPageView(pageNumber - 1);
    return cropRectArea(canvas, pageRect, path, shape);
  };

  getAnnotationLayer = (pageNumber: number) => {
    const { div } = this.pdfViewer.getPageView(pageNumber - 1);

    let annotationContainer = div.querySelector(
      '.next-space-annotationLayer'
    ) as HTMLElement | null;
    if (!annotationContainer) {
      annotationContainer = document.createElement('div');
      annotationContainer.className = 'next-space-annotationLayer';
      div.appendChild(annotationContainer);
    }

    return annotationContainer;
  };
}
