import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useOpenModal } from 'src/common/components/next-modal';
import { numberToPercent } from 'src/utils/number';

const PageScaleMap = [
  { name: '自动缩放', value: 'auto' },
  { name: '实际大小', value: 'page-actual' },
  { name: '适合页面', value: 'page-fit' },
  { name: '适合页宽', value: 'page-width' },
  { name: '50%', value: '0.5' },
  { name: '75%', value: '0.75' },
  { name: '100%', value: '1' },
  { name: '125%', value: '1.25' },
  { name: '150%', value: '1.5' },
  { name: '200%', value: '2' },
  { name: '300%', value: '3' },
  { name: '400%', value: '4' },
];

interface Props {
  uuid?: string;
  closeModal: () => void;
  scale: string;
  pdfViewer: PDFViewer;
  setScale: React.Dispatch<React.SetStateAction<string>>;
}

export const ScaleSetting: FC<Props> = ({ closeModal, pdfViewer, scale }) => {
  const items = PageScaleMap.map((item) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        title: item.name,
        value: item.value,
        arrow: <Icon name="IcCheck02" size="normal" />,
        hasArrow: item.value === scale,
      },
    };
  });

  const handleClick = ({ data }: ListItem<any>) => {
    pdfViewer.currentScaleValue = data.value;
    closeModal();
  };

  return (
    <ListView
      className="next-modal w-[160px] py-[5px] bg-white4"
      items={items}
      onItemClick={handleClick}
    />
  );
};

interface SideBarButtonProps {
  className?: string;
  pdfViewer: PDFViewer;
}
export const ScaleButton: FC<SideBarButtonProps> = ({ className, pdfViewer }) => {
  const openModal = useOpenModal();
  const [scale, setScale] = useState(pdfViewer.currentScaleValue);
  const scaleRef = useRef(scale);
  scaleRef.current = scale;

  useEffect(() => {
    pdfViewer.eventBus.on('scalechanging', () => {
      pdfViewer.update();
      if (scaleRef.current !== pdfViewer.currentScaleValue) {
        setScale(String(pdfViewer.currentScaleValue));
      }
    });
  }, [pdfViewer]);

  const openSetting = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <ScaleSetting
          pdfViewer={pdfViewer}
          closeModal={onCloseModal}
          scale={scale}
          setScale={setScale}
        />
      ),
    });
  };

  let scaleName = '';
  if (
    scale === 'auto' ||
    scale === 'page-actual' ||
    scale === 'page-fit' ||
    scale === 'page-width'
  ) {
    scaleName = PageScaleMap.find((item) => item.value === scale)?.name ?? '';
  } else {
    scaleName = numberToPercent(Number(scale), 0);
  }

  return (
    <button
      className={cx('flex items-center w-20 p-1 animate-hover', className)}
      onClick={openSetting}
    >
      <span className="flex-1 mr-1 text-t2 text-ellipsis">{scaleName}</span>
      <Icon size="xxsmall" name="IcArrowDown01" />
    </button>
  );
};
