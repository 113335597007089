/* eslint-disable react-hooks/rules-of-hooks */
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { pickBlock } from './pick';
import type { UsePickBlockType } from './type';

export const usePickBlock: UsePickBlockType = (uuid, blockFields = [], dataFields, opt) => {
  return useObservableBlock(uuid, (block) => pickBlock(block, blockFields, dataFields), [uuid], {
    ignoreDeep: !blockFields?.length,
    ...opt,
  });
};
