import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { isSamePermission, setPermission } from 'src/components/share/helper';
import { removeBlockPermission } from 'src/redux/managers/block/update-permission';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { getRolePermissions } from 'src/utils/permission-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { getCurrentSpace, useCurrentSpace } from '../space';
import { ROLE_WEIGHT, usePermissions } from './use-permissions';

export const useRestorePermission = (uuid: string) => {
  const block = usePickBlock(uuid, ['permissions']);
  const hasBlock = Boolean(block);
  const { permissions } = usePermissions(block?.parentId ?? '');

  /** 调用的地方如果没用 transaction 的话，需手动 dispatch(TRANSACTION_FIRE()) */
  return useCallback(() => {
    if (!hasBlock) {
      return;
    }

    const smallerPermissions = getRolePermissions(block?.permissions ?? []).filter(
      (blockPermission) =>
        permissions.some((p) => {
          if (
            isSamePermission(blockPermission, p) &&
            p.role &&
            blockPermission.role &&
            ROLE_WEIGHT[blockPermission.role] < ROLE_WEIGHT[p.role]
          ) {
            return true;
          }

          return false;
        })
    );

    // 恢复继承时把比父级权限小的清除
    smallerPermissions.forEach((p) => setPermission(uuid, { ...p, role: PermissionRole.NONE }));

    removeBlockPermission(uuid, { type: PermissionType.RESTRICTED });
    removeBlockPermission(uuid, { type: PermissionType.PUBLIC });
  }, [block?.permissions, hasBlock, permissions, uuid]);
};

export const useParentIsOk = (uuid: string) => {
  const block = usePickBlock(uuid, []);
  const { role: ParentRole } = usePermissions(block?.parentId ?? '');
  const isGuest = useIsGuest();

  if (!block) {
    return false;
  }

  if (block.parentId === block.spaceId) {
    return !isGuest;
  }

  return ParentRole === PermissionRole.EDITOR || ParentRole === PermissionRole.WRITER;
};

export const useCanMove = (uuid: string) => {
  const { role, isRestricted } = usePermissions(uuid);
  const parentIsOk = useParentIsOk(uuid);
  const isTemplate = useObservableBlock(uuid, (block) => {
    return block?.isTemplate ?? false;
  });

  const blockIsOk = isRestricted
    ? role === PermissionRole.WRITER || role === PermissionRole.EDITOR
    : role === PermissionRole.EDITOR;

  return parentIsOk && blockIsOk && !isTemplate;
};

export const useIsGuest = () => {
  const currentSpace = useCurrentSpace();
  return getIsGuest(currentSpace);
};

export const getIsGuest = (space = getCurrentSpace()) => {
  // 暂时先通过有没有 permissions 来判断是否为外部访客
  return !space?.permissions;
};

/** 是否可编辑 */
export const getRolePermissionRole = (role: PermissionRole) => {
  return {
    none: role === PermissionRole.NONE,
    commenter: role === PermissionRole.COMMENTER,
    reader: role === PermissionRole.READER,
    editor: role === PermissionRole.EDITOR || role === PermissionRole.WRITER,
    writer: role === PermissionRole.WRITER,
  };
};
