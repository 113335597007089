import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import type {
  IContent,
  IEditorModel,
  IPerformChangeContext,
  ISelection,
} from '@next-space/fe-inlined';
import { contentToString, newContent, newSelection, newText } from '@next-space/fe-inlined';
import { lookupEditorElement } from '@next-space/fe-inlined/dom-utils';
import type { Editor } from 'codemirror';
import CodeMirror from 'codemirror';
// code块依赖
import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/edit/matchbrackets.js';
import 'codemirror/addon/edit/matchtags.js';
// eslint-disable-next-line import/no-unresolved
import 'codemirror/addon/mode/loadmode.js';
import 'codemirror/addon/search/match-highlighter.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/meta';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { fromEvent } from 'rxjs';
import { message } from 'src/common/components/message';
import { MAX_WORDS_NUM } from 'src/const/limit-config';
import { getCaretInfo } from 'src/hooks/editor/helper';
import { useArrowDownKey, useArrowUpKey } from 'src/hooks/editor/use-arrow-key';
import { useReadonly } from 'src/hooks/page';
import { TEXT_PLAIN } from 'src/hooks/page/use-copy-listener/types';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useUpdatedRef } from '@flowus/common/hooks/react-utils';
import {
  SYNCED_REVISION_REF_SYMBOL,
  SYNCED_SEGMENTS_REF_SYMBOL,
} from 'src/utils/sync-segments-to-editor-model';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { segmentsToText } from '../../../utils/editor';
import { deleteEditorModel, useEditorModelKey } from '../../uikit/editable/helper';
import { useGetOrInitEditorModel } from '../../uikit/editable/hook';
import { findModeByLanguageExtension, LOAD_MODE_OPTIONS } from './codemirror-utils';

export interface EditorProps {
  id: string;
  language: string;
  wordWrap?: boolean;
  maxHeight?: number;
  padding?: string;
  noScrollbars?: boolean;
  segments: SegmentDTO[] | undefined;
  onChange?: (segments: SegmentDTO[], prevSnapshot: [IContent, ISelection | null]) => void;
  className?: string;
}

const IS_MOCKED = new WeakSet<IEditorModel>();

export const CodeEditor: FC<EditorProps> = (props) => {
  const isDarkMode = useIsDarkMode();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const ref = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>();
  const propsRef = useUpdatedRef(props);
  const suppressChangeEvent = useRef(false);
  const scene = usePageScene();
  // 由于caption的实现目前依赖于原来的富文本，而全局的EditorModelMap是使用blockId进行存储的，
  // 如果codeBlock使用blockId存储的话，caption就无法使用blockId作为标记了.
  // 暂时先用mockId替代。
  // NOTE:由于很多地方的实现依赖于EditorModelMap，要是codeBlock本身是个富文本的话，caption的富文本需要fake一个新的id出来
  const editorModelKey = useEditorModelKey(`${props.id}_mock`);
  const mockId = editorModelKey;

  const syncedSegmentsRef = useRef<SegmentDTO[] | undefined>();
  const syncedRevisionRef = useRef(NaN);
  const readOnly = useReadonly(props.id);

  useEffect(() => {
    const div = ref.current;
    if (!div) return;
    const editor = CodeMirror(div, {
      value: segmentsToText(propsRef.current.segments),
      ...(props.maxHeight != null ? {} : { viewportMargin: Infinity }),
      ...(props.noScrollbars ?? false ? { scrollbarStyle: 'null' } : {}),
      lineNumbers: true,
      // 禁用 undo redo
      keyMap: 'modified',
      theme: isDarkMode ? 'ayu-dark' : 'default',
      autoRefresh: true,
      highlightSelectionMatches: true,
      matchTags: { bothTags: true },
      matchBrackets: { strict: true },
      // inputStyle: 'contenteditable',
      extraKeys: {
        Tab(cm) {
          cm.indentSelection('add');
          // if (cm.somethingSelected()) {
          // cm.indentSelection('add');
          // return;
          // }
          // 拦截tab转空格 https://github.com/codemirror/codemirror5/issues/988
          // 下面这个方法看api发现的
          // cm.execCommand('insertSoftTab');
        },
        'Shift-Tab'(cm) {
          cm.indentSelection('subtract');
        },
      },
    });

    editorRef.current = editor;

    // MOCK EditorModel
    const model = getOrInitEditorModel(editorModelKey, true);
    if (!IS_MOCKED.has(model)) {
      Object.defineProperties(model, {
        content: {
          get() {
            return newContent([newText(editor.getValue())]);
          },
        },
        revision: {
          get() {
            return editor.changeGeneration();
          },
        },
        selection: {
          get() {
            const anchor = editor.getDoc().indexFromPos(editor.getCursor('anchor'));
            const head = editor.getDoc().indexFromPos(editor.getCursor('head'));
            return newSelection(anchor, head);
          },
        },
        getBoundingClientRectOfRange: {
          value: (start: number, end: number) => {
            const startRect = editor.cursorCoords(editor.getDoc().posFromIndex(start), 'window');
            const endRect = editor.cursorCoords(editor.getDoc().posFromIndex(end), 'window');
            const left = Math.min(startRect.left, endRect.left);
            const top = Math.min(startRect.top, endRect.top);
            const right = Math.max(startRect.left, endRect.left);
            const bottom = Math.max(startRect.bottom, endRect.bottom);
            return new DOMRect(left, top, right - left, bottom - top);
          },
        },
        hitTest: {
          value: (x: number, y: number) => {
            const pos = editor.coordsChar({ left: x, top: y }, 'window');
            const offset = editor.getDoc().indexFromPos(pos);
            return offset;
          },
        },
        scrollCaretIntoViewIfNeeded: {
          value: () => {},
        },
        performChange: {
          value: (callback: (ctx: IPerformChangeContext) => void) => {
            callback({
              load(content: IContent) {
                suppressChangeEvent.current = true;
                editor.setValue(contentToString(content));
                suppressChangeEvent.current = false;
              },
              select(offset = 0, endOffset = offset, backward = false) {
                const anchor = editor.getDoc().posFromIndex(backward ? endOffset : offset);
                const focus = editor.getDoc().posFromIndex(backward ? offset : endOffset);
                editor.setSelection(anchor, focus, { scroll: false });
              },
              delete() {
                editor.replaceSelection('');
              },
              insert(content: string | IContent) {
                if (typeof content === 'string') {
                  editor.replaceSelection(content);
                } else {
                  editor.replaceSelection(contentToString(content));
                }
              },
            } as IPerformChangeContext);
          },
        },
        requestFocus: {
          value: () => {
            editor.focus();
          },
        },
      });
      IS_MOCKED.add(model);
    }

    syncedRevisionRef.current = editor.changeGeneration();
    (model as any)[SYNCED_SEGMENTS_REF_SYMBOL] = syncedSegmentsRef;
    (model as any)[SYNCED_REVISION_REF_SYMBOL] = syncedRevisionRef;

    let generation = editor.changeGeneration();
    let beforeChangeSnapshot: [IContent, ISelection | null] = [model.content, model.selection];
    editor.on('beforeChange', () => {
      if (suppressChangeEvent.current) return;
      if (editor.isClean(generation)) {
        beforeChangeSnapshot = [model.content, model.selection];
      }
    });
    editor.on('changes', (editor) => {
      // if (suppressChangeEvent.current) return;
      if (scene === PageScene.PAGE_LITE_PREVIEW) return;
      generation = editor.changeGeneration();

      // if (syncedRevisionRef.current !== model.revision) {
      const segments = [
        {
          type: TextType.TEXT,
          enhancer: {},
          text: editor.getValue(),
        },
      ];
      syncedSegmentsRef.current = segments;
      propsRef.current.onChange?.(segments, beforeChangeSnapshot);
      syncedRevisionRef.current = model.revision;
      // }
    });
    return () => {
      deleteEditorModel(editorModelKey);
      const range = new Range();
      range.selectNodeContents(div);
      range.deleteContents();
    };
  }, [
    getOrInitEditorModel,
    mockId,
    editorModelKey,
    propsRef,
    props.maxHeight,
    props.noScrollbars,
    isDarkMode,
    scene,
  ]);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.setOption('readOnly', readOnly);
  }, [readOnly]);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;

    // NOTE: 目前不支持文本级别协同，增加判断以避免自己的onChange触发setValue导致重置光标和选区
    const value = segmentsToText(props.segments);
    if (value !== editor.getValue()) {
      suppressChangeEvent.current = true;
      editor.setValue(value);
      suppressChangeEvent.current = false;
    }
    syncedSegmentsRef.current = props.segments;
  }, [props.segments]);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;
    const info =
      CodeMirror.findModeByExtension(props.language?.toLocaleLowerCase()) ||
      findModeByLanguageExtension(props.language);
    if (!info) return;

    editor.setOption('mode', info.mime ?? info.mode);
    // @ts-ignore 不知道为什么CI上过不了，本地去掉ts-ignore是没问题的
    CodeMirror.autoLoadMode(editor, info.mode, LOAD_MODE_OPTIONS);
  }, [props.language]);

  useEffect(() => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.setOption('lineWrapping', props.wordWrap ?? false);
  }, [props.wordWrap]);

  useEffect(() => {
    const subscription1 = fromEvent(document, '_clearTextSelection').subscribe(() => {
      const editor = editorRef.current;
      if (!editor) return;
      editor.setSelection({ line: 0, ch: 0 }, undefined, {
        scroll: false,
      });
      // NOTE: HACK. end text selecting
      if (typeof editor.state.selectingText === 'function') {
        editor.state.selectingText();
      }
    });
    const subscription2 = fromEvent(document, 'selectionchange').subscribe(() => {
      const editor = editorRef.current;
      if (!editor) return;
      const sel = document.getSelection();
      if (!sel) return;
      if (lookupEditorElement(sel.focusNode) != null) {
        editor.setSelection({ line: 0, ch: 0 }, undefined, { scroll: false });
      }
    });
    return () => {
      subscription1.unsubscribe();
      subscription2.unsubscribe();
    };
  }, []);

  const arrowUpKey = useArrowUpKey(mockId);
  const arrowDownKey = useArrowDownKey(mockId);
  // const arrowLeftKey = useArrowLeftKey(mockId);
  // const arrowRightKey = useArrowRightKey(mockId);
  // const backspaceKey = useBackspaceKey(mockId, 'segments');

  return (
    <div
      ref={ref}
      data-disable-select
      data-editor={mockId}
      className={cx(
        props.className,
        'w-full code-mirror',
        css({
          selectors: {
            '& div.CodeMirror-gutters': {
              background: 'transparent !important',
            },
            '& div.CodeMirror': {
              minHeight: '24px',
              height: '100%',
              maxHeight: props.maxHeight ? `${props.maxHeight}px` : undefined,
              background: 'transparent',
              fontSize: '14px',
              color: 'var(--black)',
              padding: props.padding ? props.padding : props.noScrollbars ? '8px' : '24px',
            },
            '& .CodeMirror-cursor': {
              borderLeftWidth: '1px !important',
              display: readOnly ? 'none !important' : undefined,
            },
            '& .CodeMirror-selected': {
              background: 'var(--selection) !important',
            },
            '& .CodeMirror-focused .CodeMirror-selected': {
              background: 'var(--selection) !important',
            },
            '& .CodeMirror-line::selection, & .CodeMirror-line > span::selection, & .CodeMirror-line > span > span::selection':
              {
                background: 'var(--selection) !important',
              },
            '& .CodeMirror pre.CodeMirror-line, & .CodeMirror pre.CodeMirror-line-like': {
              fontFamily:
                'SFMono-Regular, Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace',
            },
          },
        })
      )}
      onPasteCapture={(e) => {
        const data = e.clipboardData.getData(TEXT_PLAIN);
        if (data.length > MAX_WORDS_NUM) {
          e.preventDefault();
          message.warning('内容过大。以防数据丢失，请改用文件形式上传');
        }
      }}
      onKeyDownCapture={(event) => {
        const editor = editorRef.current;
        if (!editor) return;

        if (isHotkey('mod+a')(event)) {
          const model = getOrInitEditorModel(mockId, false);
          if (!model) return;
          const { selection } = model;
          if (!selection) return;

          if (selection.length === model.content.length) {
            return;
          }
          event.stopPropagation();
          editor.execCommand('selectAll');
        }
        // if (isHotkey('ArrowUp')(event)) {
        //   if (getCaretInfo(mockId)?.isFirstLine) {
        //     arrowUpKey(event.nativeEvent);
        //   }
        // } else if (isHotkey('ArrowDown')(event)) {
        //   if (getCaretInfo(mockId)?.isLastLine) {
        //     arrowDownKey(event.nativeEvent);
        //   }
        // } else if (isHotkey('ArrowLeft')(event)) {
        //   arrowLeftKey(event.nativeEvent);
        // } else if (isHotkey('ArrowRight')(event)) {
        //   arrowRightKey(event.nativeEvent);
        // } else if (isHotkey('Backspace')(event)) {
        //   backspaceKey(event.nativeEvent);
        // }

        // 下面这两个方法有用，别禁
        if (isHotkey('ArrowUp')(event)) {
          if (getCaretInfo(mockId)?.isFirstLine) {
            arrowUpKey(event.nativeEvent);
          }
        } else if (isHotkey('ArrowDown')(event)) {
          if (getCaretInfo(mockId)?.isLastLine) {
            arrowDownKey(event.nativeEvent);
          }
        }
      }}
    ></div>
  );
};
