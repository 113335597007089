import type { PageActivityDTO } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';

import { PermissionChange } from '../changes/permission-change';
import { PageSubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const PermissionFeed: FC<{ pageActivity: PageActivityDTO }> = ({ pageActivity }) => {
  const changes = pageActivity.edits.map((edit, index) => {
    const [from, to] = (() => {
      switch (edit.type) {
        case 'permissionCreated':
          return [undefined, edit.permissionData?.current];
        case 'permissionChanged':
          return [edit.permissionData?.before, edit.permissionData?.after];
        case 'permissionDeleted':
          return [edit.permissionData?.current, undefined];
        default:
          throw new Error('Assertion failed');
      }
    })();
    return <PermissionChange key={index} from={from} to={to} />;
  });
  return (
    <FeedTemplate
      edits={pageActivity.edits}
      action="修改了权限"
      subject={<PageSubject pageId={pageActivity.parentId} />}
      timestamp={pageActivity.endTime}
      changes={changes}
    ></FeedTemplate>
  );
};

export const PermissionRestrictionFeed: FC<{ pageActivity: PageActivityDTO }> = ({
  pageActivity,
}) => {
  const changes: ReactNode[] = [];
  for (const edit of pageActivity.edits) {
    for (const change of edit.permissionData?.calculatedChanges ?? []) {
      changes.push(
        <PermissionChange key={changes.length} from={change.before} to={change.after} />
      );
    }
  }
  return (
    <FeedTemplate
      edits={pageActivity.edits}
      action={
        pageActivity.type === 'permissionRestrictionCreated'
          ? '修改权限为不再继承上级页面权限'
          : '恢复了继承上级页面权限'
      }
      timestamp={pageActivity.endTime}
      changes={changes}
    ></FeedTemplate>
  );
};
