import type { IpcMessage } from '@flowus/shared';
import type { IpcRendererEvent } from 'electron';
import { getIpcRenderer, isFlowUsApp } from '@flowus/common/flowus-utils/check-is-desktop-app';

export { getIpcRenderer, isFlowUsApp };

export const ipcInvoke = (key: IpcMessage, ...args: any[]): Promise<any> => {
  return new Promise((resolve) => {
    const flowusIpc = getIpcRenderer();
    void flowusIpc?.invoke(key, ...args).then(resolve);
  });
};

export const ipcOnMessage = (
  key: IpcMessage,
  callback: (e: IpcRendererEvent, ...args: any[]) => void
) => {
  const flowusIpc = getIpcRenderer();
  flowusIpc?.on(key, callback);
};

export const ipcSendMessage = (key: IpcMessage, ...args: any[]) => {
  const flowusIpc = getIpcRenderer();
  flowusIpc?.send(key, ...args);
};
