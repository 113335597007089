import { PayType } from '@flowus/common/utils/pay-info';
import type { PlanCouponDto, PlanDTO } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import dayjs from 'dayjs';
import { first } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { createModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { useOpenBuildInPay } from 'src/components/stripe';
import { useFetchSpaceCapacity } from 'src/hooks/drive/use-fetch-space-capacity';
import { useCurrentSpace } from 'src/hooks/space';
import { Modals } from 'src/modals';
import { refreshSpaceData } from 'src/services/app/hook/refresh-space';
import { fetchAiCapacity } from 'src/services/capacity';
import { useUserBalance } from 'src/services/user/user-balance';
import { sleep } from 'src/utils/async-utils';
import { confetti } from 'src/utils/confetti';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useCloseSettingModal,
  useOpenSettingModal,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import type { AsyncReturnType } from 'type-fest';
import { v4 } from 'uuid';
import { orderBizTracker } from '../common';
import { PayQrModal } from '../pay-qr-modal';

export interface UpgradeSupportParams {
  planId?: string;
  isFrom?: OpenSettingFrom;
}

export interface UpgradeSupportProps extends UpgradeSupportParams {
  upgradeType: 'ai' | 'capacity';
}

export const useUpgradeSupport = (props: UpgradeSupportProps) => {
  const { isFrom = OpenSettingFrom.dashboard_file, upgradeType } = props;
  const { integralInfo } = useUserBalance();
  const isUpgradeAi = upgradeType === 'ai';

  // const isUpgradeCapacity = upgradeType === 'capacity';
  const openSettingModal = useOpenSettingModal();
  const closeSettingModal = useCloseSettingModal();
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const currentSpace = useCurrentSpace();
  const openModal = useOpenModal();
  const [payMethod, setPayMethod] = useState<PayType>(
    __BUILD_IN__ ? PayType.stripe : PayType.alipay
  );
  const openBuildInPay = useOpenBuildInPay();
  const [planId, setPlanId] = useState<string>(props.planId ?? '');
  const [planList, setPlanList] = useState<PlanDTO[]>([]);
  const getCacheQuote = useGetCacheQuote();
  /** 后端计算的价格 */
  const [calculateQuote, setCalculateQuote] = useState<
    Record<string, AsyncReturnType<typeof request.infra.getQuote> & { planId: string }>
  >({});
  const [couponId, setCouponId] = useState<string>('');
  const [couponList, setCouponList] = useState<PlanCouponDto[]>([]);
  const [loading, setLoading] = useState(true);
  const [orderStateKey, setOrderStateKey] = useState('');

  /** 当前套餐的价格 */
  const curCalculateQuote = calculateQuote[`${planId}${couponId}`];

  const currentPlan = planList.find((i) => i.id === planId);

  /** 关闭弹窗 */
  const close = () => {
    openModal.closeModal(Modals.UPGRADE_SUPPORT);
  };

  /** 更新优惠券列表 */
  const patchCouponList = async () => {
    if (!planId) return;
    const couponListRes = await request.infra.getCouponList(planId, undefined, currentSpace.uuid);
    setCouponList(couponListRes.list ?? []);
    const firstCoupon = first(
      couponListRes.list?.filter(
        (i) => (i.integralRecord ?? 0) <= (integralInfo?.currentScore ?? 0)
      )
    );
    if (!firstCoupon) {
      selectCoupon('');
      return;
    }
    if (!couponListRes.list?.some((it) => it.id === couponId)) {
      selectCoupon(firstCoupon.id);
    }
  };

  /** 选择套餐 */
  const onSelectPlan = (id: string) => {
    setLoading(true);
    setPlanId(id);
  };

  /** 计算价格 */
  const patchQuote = async () => {
    if (planId && currentSpace.uuid) {
      const res = await getCacheQuote({
        spaceId: currentSpace.uuid,
        planId,
        couponId,
        date: 0,
        people: 0,
      });
      if (planId) {
        setCalculateQuote((pre) => ({ ...pre, [`${planId}${couponId}`]: { ...res, planId } }));
        setOrderStateKey(v4());
      }
    }
    setLoading(false);
  };

  /** 自动计算价格 */
  useDebounceEffect(
    () => {
      void patchQuote();
    },
    [planId, couponId],
    { wait: 100 }
  );

  // 发送订单埋点，抽出来是因为有三个地方使用
  const sendOrderBizTracker = (bizType: 'order_finish' | 'pay_order') => {
    const curPlan = planList.find((i) => i.id === planId);
    orderBizTracker({
      bizType: `${bizType}_${isUpgradeAi ? 'ai' : 'file'}`,
      order_channel: payMethod === PayType.alipay ? 'zhifubao' : 'weixin',
      is_from: isFrom,
      order_amount: curCalculateQuote?.amount,
      order_capacity: curPlan?.amount,
      space_type: currentSpace.planType,
    });
  };

  /** 更新优惠券列表 */
  useEffect(() => {
    void patchCouponList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  /** 套餐到期时间 */
  const lastDate = useMemo(() => {
    if (!curCalculateQuote?.desiredExpireTime) return '';
    const lastDay = dayjs(curCalculateQuote?.desiredExpireTime || Date.now());
    return lastDay.format('YYYY年MM月DD日');
  }, [curCalculateQuote?.desiredExpireTime]);

  const getClientSecret = () => {
    const payFn = __BUILD_IN__
      ? request.infra.createPreOrderV2
      : isUpgradeAi
      ? request.infra.createAiUsageOrder
      : request.infra.createCapacityOrder;

    return payFn({
      spaceId: currentSpace.uuid,
      planId,
      couponId,
      payType: payMethod,
    });
  };

  /** 下单 */
  const onPay = async () => {
    message.loading('正在创建订单');
    setLoading(true);

    sendOrderBizTracker('pay_order');
    const order = await getClientSecret();

    setLoading(false);
    if (!order.orderId) {
      return message.error('下单失败，请重试');
    }

    if (__BUILD_IN__) {
      openBuildInPay(order);
      return;
    }

    openModal.modal({
      modalId: Modals.PAY_MODAL,
      content: () => (
        <PayQrModal
          rePayOrder={getClientSecret}
          onFinish={async () => {
            sendOrderBizTracker('order_finish');
            close();
            closeSettingModal();
            if (isUpgradeAi) {
              await fetchAiCapacity();
            } else {
              await fetchSpaceCapacity();
            }
            await sleep(500);
            openSettingModal({
              defaultMenuType: SettingMenuType.upgrade,
              from: OpenSettingFrom.pay,
            });
          }}
          payType={payMethod}
          priceTitle={`${getCurrencySymbols(curCalculateQuote?.amountUnit)}${order.amount}`}
          orderId={`${order.orderId}`}
          qrCodeUrl={`${order.qrCodeUrl}`}
        />
      ),
    });
  };

  /** 选择优惠券 */
  const selectCoupon = (id = '') => {
    setCouponId(id);
  };

  /** 套餐列表 */
  useEffect(() => {
    const api = isUpgradeAi ? request.infra.aiUsagePlanList : request.infra.capacityPlanList;

    void api(currentSpace.uuid).then((res) => {
      const list = res.list ?? [];
      const [first] = list;
      if (first) {
        setPlanList(list);
        if (!planId || !list.map((i) => i.id).includes(planId)) {
          setPlanId(first.id);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace.uuid]);

  return {
    currentPlan,
    orderStateKey,
    getClientSecret,
    couponId,
    selectCoupon,
    onPay,
    close,
    payMethod,
    setPayMethod,
    planId,
    onSelectPlan,
    planList,
    couponList,
    curCalculateQuote,
    loading,
    lastDate,
  };
};

export const useGetCacheQuote = () => {
  const apiCache = useRef(new Map<string, AsyncReturnType<typeof request.infra.getQuote>>());

  return useCallback(async (apiParams: Parameters<typeof request.infra.getQuote>[0]) => {
    const cacheKey = JSON.stringify(apiParams);
    const cacheValue = apiCache.current.get(cacheKey);
    if (cacheValue) {
      return cacheValue;
    }
    const res = await request.infra.getQuote(apiParams);
    apiCache.current.set(cacheKey, res);
    return res;
  }, []);
};

export const createUpgradeSupportModel = () => createModel(useUpgradeSupport);

export const getPlanList = __BUILD_IN__
  ? request.infra.overseaPlanList
  : request.infra.getNewPlanList;

export const useStripeSuccess = () => {
  return useCallback(async () => {
    message.success('订单支付成功');
    void confetti.fireworks();
    await sleep(3000);
    await refreshSpaceData();
  }, []);
};
