import { message } from '@flowus/common/components/message';
import { useState, type CSSProperties, type FC } from 'react';
import type { ImageInfo } from 'src/common/components/gallery';
import { request } from 'src/common/request';
import { RecentlyUploadPanel } from 'src/components/recently-upload-panel';
import { UploadLocalFile } from 'src/editor/component/upload-panel/upload-local-file';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';

interface Props {
  onSelectImage?: (imageInfo: ImageInfo) => void;
  onUploadFile?: (file: File) => void;
  fileType: 'icon' | 'cover';
  showRecently?: boolean;
  style?: CSSProperties;
  tip?: string;
}
/**
 * emoji/cover上传页面组件
 */
export const ImageUploadPanel: FC<Props> = (props) => {
  const { showRecently = true, fileType } = props;
  const [forceRender, setForceRender] = useState(Date.now());

  const cleanUploadHistory = async () => {
    await request.editor.clearSpaceRecentlyImages(getCurrentSpaceId(), { imgType: fileType });
    message.success('已清空');
    setForceRender(Date.now());
  };

  return (
    <div className="pb-1">
      <UploadLocalFile
        singleFile={true}
        title="请选择一张图片"
        inputAttribute={{ accept: 'image/*' }}
        onSelect={(files) => {
          const file = files[0];
          file && props.onUploadFile?.(file);
        }}
        style={{
          height: '37px',
          marginTop: '15px',
        }}
      />
      <span className="text-t4 text-grey3 flex justify-center mt-2">{props.tip}</span>
      {showRecently && (
        <div className="px-3 py-1 text-t2 text-grey3 mt-3 flex justify-between">
          <span>最近上传</span>
          <span className="animate-hover px-1 animate-click" onClick={cleanUploadHistory}>
            清空记录
          </span>
        </div>
      )}
      {showRecently && (
        <RecentlyUploadPanel
          key={forceRender}
          onSelect={props.onSelectImage}
          fileType={props.fileType}
          style={props.style}
        />
      )}
    </div>
  );
};
