import type { BlockType } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ListItemType } from 'src/common/components/list-view';
import { request } from 'src/common/request';
import { useSearchBlock } from 'src/hooks/drive/use-search-block';
import { useCurrentSpace } from 'src/hooks/space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { getDynamicPageId } from 'src/utils/getPageId';
import { segmentsToText } from '../../../utils/editor';

const PAGE_ITEM_COUNT = 3;

enum CategoryType {
  date = 1,
  person,
  page,
  search,
  searchPerson,
  foldPage,
  foldUser,
}

interface Props {
  onlyDate?: boolean;
  keyword: string;
  onChange?: () => void;
  filterTypes?: BlockType[];
}

export const useSearchPage = ({ onlyDate, keyword, onChange, filterTypes }: Props) => {
  const [pageFold, setPageFold] = useState(true);
  const [loading, setLoading] = useState(false);
  const pageId = getDynamicPageId();
  const space = useCurrentSpace();
  const searchBlock = useSearchBlock();
  const [searchPages, setSearchPages] = useState<any[]>([]);
  // 防止输入过快，搜索接口缓慢，导致加载了上一个搜索词的结果列表
  const loadKeyword = useRef(keyword);

  // 更新页面的列表
  const patchSearchItems = (pages: typeof searchPages) => {
    if (filterTypes) {
      setSearchPages(pages.filter((page) => !filterTypes.includes(page.data.type)));
    } else {
      setSearchPages(pages);
    }
    setPageFold(true);
    setLoading(false);
  };

  // 默认展示最近打开的页面
  const findVisitsHistory = useRef(
    debounce(async () => {
      const res = await request.editor.querySpaceVisitsHistory(space.uuid, 0);
      if (typeof res !== 'object') return; // amazing
      if (!res.recordMap || !res.results) return;
      const { blocks } = res.recordMap;
      if (!blocks) return;
      dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
      const getNavs = (uuid: string) => {
        const navs = [];
        let curr = blocks[uuid];
        while (curr && curr.spaceId !== curr.uuid) {
          curr = blocks[curr.parentId];
          if (curr && isPageLike(curr.type)) {
            navs.unshift(curr);
          }
        }
        return navs;
      };
      // 等加载出最近访问页面再更新listView
      const newItems = [];
      let count = 0;
      for (const { uuid } of res.results) {
        if (count >= PAGE_ITEM_COUNT) break;
        if (!uuid || uuid === pageId) continue;
        const block = cache.blocks[uuid];
        if (!block) continue;
        if (block.status !== BlockStatus.NORMAL) continue;
        count++;
        newItems.push({
          type: ListItemType.SEARCH,
          data: {
            uuid: block.uuid,
            title: segmentsToText(block.data.segments) || getUntitledName(block.type),
            icon: block.data.icon,
            navs: getNavs(uuid),
            type: block.type,
            backgroundColor: block.backgroundColor,
            categoryType: CategoryType.page,
          },
        });
      }
      if (loadKeyword.current) return;
      patchSearchItems(newItems);
    }, 200)
  );

  // 用关键词搜索页面
  const quickSearch = useRef(
    debounce(async (search: string) => {
      const { resultBlocks } = await searchBlock({
        spaceId: space.uuid,
        pageNo: 1,
        perPage: 20,
        search,
      });
      const newItems = resultBlocks.map(({ block, navs }) => ({
        type: ListItemType.SEARCH,
        data: {
          uuid: block.uuid,
          title: segmentsToText(block.data.segments) || getUntitledName(block.type),
          icon: block.data.icon,
          navs,
          type: block.type,
          backgroundColor: block.backgroundColor,
          categoryType: CategoryType.page,
        },
      }));
      if (loadKeyword.current !== search) return;
      patchSearchItems(newItems);
    }, 200)
  );

  useEffect(() => {
    if (onlyDate) return;

    setLoading(true);

    loadKeyword.current = keyword;
    if (keyword) {
      void quickSearch.current(keyword);
    } else {
      void findVisitsHistory.current();
    }

    setPageFold(true);
    onChange?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlyDate, keyword]);

  const pageList = useMemo(() => {
    const maxCount = 5;
    if (pageFold && searchPages.length > maxCount) {
      return [
        ...searchPages.slice(0, maxCount),
        {
          type: ListItemType.OPERATION,
          data: {
            categoryType: CategoryType.foldPage,
            title: `查看其他${searchPages.length - maxCount}个页面`,
          },
        },
      ];
    }
    return searchPages;
  }, [pageFold, searchPages]);

  return {
    setPageFold,
    pageList,
    loading,
  };
};
