import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

import type { CellEditorProps, CellViewProps } from '../types';
import { Tags } from './tags';
import { TagsEditor } from './tags-editor';

export const SelectValue: FC<CellViewProps> = ({ className, ...props }) => {
  return (
    <Tags
      className={cx('p-[5px] w-full', className)}
      {...props}
      itemClassName="max-w-[calc(100%-6px)] m-[3px]"
    />
  );
};

export const MultiSelectValue: FC<CellViewProps> = ({ className, ...props }) => {
  return (
    <Tags
      className={cx('p-[5px] w-full', className)}
      {...props}
      multiple
      itemClassName="max-w-[calc(100%-6px)] m-[3px]"
    />
  );
};

export const SelectEditor: FC<CellEditorProps> = (props) => {
  return <TagsEditor {...props} />;
};

export const MultiSelectEditor: FC<CellEditorProps> = (props) => {
  return <TagsEditor multiple {...props} />;
};
