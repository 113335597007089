import { cx } from '@flowus/common/cx';
import { LatexComponent } from '@flowus/common/latex';
import type { FC } from 'react';
import { useState } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';

import {
  equationFormula,
  EquationSymbolsEnum,
  equationTemplateMenu,
  EquationTemplateMenuEnum,
} from './equation-data';

interface Props {
  onClick: (str: string) => void;
}
export const EquationTemplate: FC<Props> = (props) => {
  const { onClick } = props;
  const [menu, setMenu] = useState<typeof equationTemplateMenu[0]>(
    equationTemplateMenu[0] as typeof equationTemplateMenu[0]
  );
  const [menuGroupType, setMenuGroupType] = useState<EquationSymbolsEnum>(EquationSymbolsEnum.math);

  const clickMenu = (item: typeof equationTemplateMenu[0]) => {
    setMenu(item);
  };

  const clickMenuGroupType = (type: EquationSymbolsEnum) => setMenuGroupType(type);
  const isSymbol = menu.type === EquationTemplateMenuEnum.symbols;
  const formulaData =
    menu.type === EquationTemplateMenuEnum.symbols
      ? equationFormula.symbols[menuGroupType]
      : equationFormula.disciplineFormula[menu.type];

  return (
    <div className="border-t border-grey6 flex">
      <div className="w-36 p-1.5 border-r border-grey6">
        {equationTemplateMenu.map((item) => (
          <div
            key={item.type}
            onClick={() => clickMenu(item)}
            className={cx(
              'py-2 relative flex animate-hover my-1 text-t2 pl-2 space-x-1',
              menu.type === item.type && 'bg-black_006'
            )}
          >
            <Icon size="middle" name={item.icon as IconName} />
            <span>{item.title}</span>
          </div>
        ))}
      </div>
      <div key={menu.type} className="w-full h-[192px] overflow-y-auto relative pb-2">
        {menu.isGroup && (
          <div className="border-b border-grey6 h-10 flex w-full p-1.5 sticky top-0 z-10 bg-white2 text-t2">
            {menu.group.map((item) => (
              <div
                key={item.value}
                onClick={() => clickMenuGroupType(item.value)}
                className={cx(
                  'flex items-center flex-1 justify-center animate-hover mx-0.5',
                  menuGroupType === item.value && 'bg-black_006'
                )}
              >
                {item.title}
              </div>
            ))}
          </div>
        )}
        <div>
          {formulaData.map((item: any, index: number) => (
            <div key={index}>
              {item.title && <div className="text-t2 text-grey3 py-2 px-4">{item.title}</div>}
              <div className={cx('px-3 text-t2', isSymbol && 'flex flex-wrap mb-1')}>
                {item.symbols.map((symbol: { cmd: string }, i: number) => (
                  <LatexComponent
                    onClick={() => onClick(symbol.cmd)}
                    key={`${i}-${item.type}`}
                    text={symbol.cmd}
                    className={cx(
                      'animate-hover px-3 overflow-x-auto block',
                      isSymbol && 'mt-1 flex items-center min-w-[15px] justify-center'
                    )}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
