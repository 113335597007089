import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { useRelationMap } from 'src/bitable/relation-context';
import { Icon } from 'src/common/components/icon';
import { useSubitemStyle } from 'src/hooks/block/use-open-subitem';
interface Props {
  className?: string;
  recordId: string;
}
/**
 * 子任务仅父记录样式
 */
export const Subitem: FC<Props> = (props) => {
  const { viewId } = useBitable();
  const { recordId } = props;
  const { parent2ChildMap } = useRelationMap();
  const subitems = parent2ChildMap?.get(recordId) ?? [];
  const subitemStyle = useSubitemStyle(viewId);
  if (subitems.length === 0) return null;
  if (subitemStyle === 'ParentOnly') {
    return (
      <div className={cx('text-grey4 flex items-center text-t4-medium px-2', props.className)}>
        <Icon
          name={'IcMove'}
          size="xsmall"
          style={{
            // icon高度太高导致无法跟文本很好的对齐，这里用css凑合对齐一下
            transform: 'translateY(-1px) scaleY(-1)',
          }}
        />
        <div className="ml-0.5">{subitems.length}</div>
      </div>
    );
  }
  return null;
};
