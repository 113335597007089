import { cx } from '@flowus/common/cx';
import type { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { getUntitledName } from 'src/utils/get-untitled-name';

interface ThumbPageCardDescContentProps {
  title?: string;
  desc?: string;
  hasCover?: boolean;
  blockType?: BlockType;
  small?: boolean;
}
export const ThumbPageCardDescContent: FC<ThumbPageCardDescContentProps> = (props) => {
  const { blockType, desc, hasCover, title, small } = props;
  return (
    <div className={cx('w-[40%] overflow-hidden', small ? 'mt-3 ml-3 pr-3' : 'mt-6 ml-4 pr-2')}>
      <div
        className={cx('text-ellipsis text-t1-bold text-black break-words', {
          'text-white': hasCover,
        })}
      >
        {title || getUntitledName(blockType)}
      </div>
      <div
        className={cx('mt-1 text-t4 break-words text-black/60', {
          'text-white': hasCover,
          'line-clamp-2': small,
        })}
      >
        {desc}
      </div>
    </div>
  );
};

export const LargeDefaultCardStyleClassName = 'text-t4 text-black/60 break-words mt-1 leading-5';
