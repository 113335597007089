import { createContext, useContext } from 'react';

import type { ZoomLevel } from './const';

interface TimelineContext {
  containerWidth: number;
  zoomLevel: ZoomLevel;
  timelineDates: number[];
  stepLength: React.MutableRefObject<number>;
  setTimelineDates: React.Dispatch<React.SetStateAction<number[]>>;
  timelineDatesRef: React.MutableRefObject<number[]>;
  container: React.MutableRefObject<HTMLDivElement | null>;
  scrollLeft: React.MutableRefObject<number | undefined>;
  draggingRecordId: React.MutableRefObject<string | undefined>;
  datesContainer: React.MutableRefObject<HTMLDivElement | null>;
}

export const TimelineContext = createContext<TimelineContext | undefined>(undefined);

export const useTimeline = () => {
  const context = useContext(TimelineContext);
  if (!context) {
    throw new Error('useBitable');
  }
  return context;
};
