/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { cx } from '@flowus/common/cx';
import type {
  CollectionViewFormProperties,
  FormLogicRule,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import { CollectionSchemaType, FormPermission } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import type { SortableListRenderDragHandle } from 'src/common/components/sortable-list';
import { SortableList } from 'src/common/components/sortable-list';
import { Tooltip } from 'src/common/components/tooltip';
import { SPECIAL_SPACE_ID, UNTITLED_FORM } from 'src/common/const';
import { useOpenAudit, useShowAudit } from 'src/components/share/share-main/share-main-footer';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { updateCollectionView, updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { FROM_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { usePageLayoutStyle } from 'src/hooks/page/use-page-layout';
import { cache } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { removeDuplicateWith } from 'src/utils/array-util';
import { getElementToElementDistance } from 'src/utils/dom';
import { isShareLink, useGetPageId } from 'src/utils/getPageId';
import type { PickArray } from 'src/utils/type-utils';
import type { CollectionProperty } from '../bitable-manager/property-list';
import { FILTER_PROPERTIES_FORM } from '../const';
import { buildFilterFunc } from '../table-view/body/filters';
import { FormComponent } from './form-component';
import { checkPropsChangeCauseNewFormLogic, preprocessFormLogic } from './form-logic-utils';
import { FormSlideBar } from './form-slide-bar';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';

// #region 收集表外层
export type HandleChangeSubmitDataType = (
  property: string,
  value: SegmentDTO[] | undefined,
  opt?: {
    /** 某些情况下，持续更新state，可能会拿不到最新的SubmitData值 */
    concatSegment?: boolean;
  }
) => void;

export type SubmitDataType = Record<string, SegmentDTO[]>;
interface FromProps {
  onSubmit?: (submitData: SubmitDataType) => void;
  shareOrPreview?: boolean;
  className?: string;
}

export const FormViewContent: FC<FromProps> = ({ onSubmit, shareOrPreview = false, className }) => {
  const openModal = useOpenModal();
  const { viewId, collectionId, readonly: _readonly, isLocked } = useBitable();
  const isSharePage = isShareLink();
  const [isDrag, setIsDrag] = useState<string | null>(null);
  const collectionView = useCollectionView(viewId);
  const formIllegal = collectionView?.format.formIllegal;
  const readonly = Boolean(_readonly || formIllegal || isSharePage || isLocked);
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema ?? {};
  });
  const [properties = []] = useProperties(viewId, { visible: true });
  const [submitData, setSubmitData] = useState<SubmitDataType>({});

  /** 由于收集表上传的文件是不属于block的，又需要预览展示文件，只好使用本地路径来浏览文件本身 */
  const [uploadFileList, setUploadFileList] = useState<
    Record<string, { id: string; url: string }[]>
  >({});
  const { fontStyle } = usePageLayoutStyle(collectionId);
  const formLogic = collectionView?.format.formLogic ?? [];
  const needPagination = shareOrPreview;

  // 当前分享权限能否展示成员选项
  const showPerson = useMemo(
    () =>
      collectionView?.format.formPermission &&
      [FormPermission.WORKSPACE].includes(collectionView.format.formPermission),
    [collectionView?.format.formPermission]
  );

  /** 增加上传的文件列表 */
  const pushUploadFileList = (property: string, id: string, url: string) => {
    setUploadFileList((pre) => ({
      ...pre,
      [property]: [...(pre[property] || []), { id, url }],
    }));
  };

  /** 渲染题目列表 */
  const visibleProperties = properties.filter((it) => {
    if (!showPerson && readonly && it.type === CollectionSchemaType.PERSON) {
      return false;
    }
    if (FILTER_PROPERTIES_FORM.includes(it.type)) {
      return false;
    }
    return true;
  });

  // 去除失效的跳题逻辑
  const visibleFormLogic = preprocessFormLogic(
    schema,
    visibleProperties.map((it) => it.property),
    formLogic
  );

  // 分页开始属性，设置跳题逻辑之后，展示的时候需要分页
  const [startProperty, setStartProperty] = useState<string | null>(null);
  const [startIndex, setStartIndex] = useState(0);
  const findStepProperties = (startProperty: string | null) => {
    if (!needPagination) return properties;
    if (visibleFormLogic.length <= 0) return properties;
    const startIndex =
      startProperty === null ? 0 : properties.findIndex((it) => it.property === startProperty);
    const findEndIndex = (startIndex: number) => {
      for (let index = startIndex; index < properties.length; index++) {
        if (visibleFormLogic.some((it) => it.condition.property === properties[index]!.property)) {
          return index;
        }
      }
      return -1;
    };
    const endIndex = findEndIndex(startIndex);
    if (endIndex !== -1) {
      return properties.slice(startIndex, endIndex + 1);
    }
    return properties.slice(startIndex);
  };
  const currentProperties = findStepProperties(startProperty);

  const renderProperties = visibleProperties
    .filter((it) => currentProperties.some((p) => p.property === it.property))
    .map((i) => ({
      id: i.property,
      data: i,
      submitData: submitData[i.property],
      formProperty: collectionView?.format.formProperties?.find((it) => it.property === i.property),
    }));

  /** 修改题目顺序 */
  const changeSortProperties = async (items: typeof renderProperties) => {
    const newProperties = items.map((i) => i.data);

    // 表单视图下，将排在后面的问题拖拽到前面，如果破坏了跳题逻辑，需要弹窗提示
    const newFormLogic = checkPropsChangeCauseNewFormLogic(
      collectionId,
      viewId,
      newProperties.map((it) => it.property),
      formLogic
    );
    if (newFormLogic != null) {
      const confirmed = await new Promise((resolve) => {
        openModal.warning({
          title: <>确认调整题目顺序吗？</>,
          content: <>会导致部分跳题逻辑失效，将自动删除失效逻辑。</>,
          confirm() {
            resolve(true);
          },
          cancel() {
            resolve(false);
          },
        });
      });
      if (!confirmed) {
        return;
      }
    }

    const view = cache.collectionViews[viewId];
    if (!view) return;
    updateViewFormat(viewId, {
      [`${view.type}Properties`]: removeDuplicateWith(
        [...newProperties, ...(view.format.formProperties ?? [])],
        'property'
      ),
      ...(newFormLogic ? { formLogic: newFormLogic } : {}),
    });
  };

  /** 更新填写信息 */
  const handleChangeSubmitData: HandleChangeSubmitDataType = (property, value, opt) => {
    const { concatSegment = false } = opt || {};
    if (concatSegment) {
      setSubmitData((pre) => ({
        ...pre,
        [property]: [...(pre[property] || []), ...(value || [])],
      }));
    } else {
      setSubmitData((pre) => ({ ...pre, [property]: value || [] }));
    }
  };

  /** 更新收集表说明 */
  const handleChangeDescription = (value: SegmentDTO[]) => {
    updateViewFormat(viewId, { formDescription: value });
  };

  /** 更新收集表 title */
  const handleChangeCollectionViewTitle = (value: string) => {
    updateCollectionView(viewId, { title: value.replace(/[\r\n]/g, '') });
  };

  /** 提交收集表校验数据 */
  const validateForm = () => {
    const validate = renderProperties.every((item) => {
      const toElementPosition = () => {
        const element = document.querySelector(
          `.form-scroll-container [data-form-property="${item.formProperty?.property}"]`
        ) as HTMLElement;
        const scrollContainer = document.querySelector('.form-scroll-container') as HTMLElement;
        const { top } = getElementToElementDistance(element, scrollContainer);
        element.classList.add('animate__flash', 'animate__animated');
        setTimeout(() => {
          element.classList.remove('animate__flash', 'animate__animated');
        }, 2000);
        scrollContainer.scrollTo({
          top: top - 200,
          behavior: 'smooth',
        });
      };
      const value = segmentsToText(item.submitData);
      if (item.formProperty?.required) {
        // 勾选框必须勾选了才算已填
        if (item.data.type === CollectionSchemaType.CHECKBOX && !formatCheckBoxValue(value)) {
          toElementPosition();
          message.error(`请填写必填项`);
          return false;
        } else if (!item.submitData || item.submitData.length === 0 || !value) {
          toElementPosition();
          message.error(`请填写必填项`);
          return false;
        }
      }

      return true;
    });

    if (validate) {
      if (needPagination) {
        const lastProperty = renderProperties[renderProperties.length - 1]!.formProperty?.property;
        const rules = formLogic.filter((it) => it.condition.property === lastProperty);
        const evalForGotoProperty = (rules: FormLogicRule[]) => {
          const lastIndex = visibleProperties.findIndex((it) => it.property === lastProperty);
          if (lastIndex === -1 || lastIndex === visibleProperties.length - 1) {
            return SPECIAL_SPACE_ID;
          }
          for (const rule of rules) {
            const filter = buildFilterFunc(rule.condition, {
              collectionId,
              schemas: schema,
            });
            if (
              filter({
                data: { segments: submitData['title'], collectionProperties: submitData },
              } as NextBlock)
            ) {
              return rule.gotoProperty;
            }
          }
          return visibleProperties[lastIndex + 1]?.property ?? SPECIAL_SPACE_ID;
        };
        const gotoProperty = evalForGotoProperty(rules);
        if (gotoProperty === SPECIAL_SPACE_ID) {
          onSubmit?.(submitData);
        } else {
          setStartProperty(gotoProperty);
          setStartIndex((startIndex) => startIndex + renderProperties.length);
        }
      } else {
        onSubmit?.(submitData);
      }
    }
  };

  if (!collectionView) return null;

  return (
    <div
      data-disable-select
      className={cx('line-default flex flex-col justify-between h-full', className)}
      style={fontStyle}
    >
      <div>
        <div className="text-h1 text-center w-full pt-10 pb-2">
          <AutoHeightTextArea
            readonly={readonly}
            placeholder={UNTITLED_FORM}
            fontClassName="text-center"
            maxLength={1000}
            defaultValue={collectionView.title}
            onChange={(event) => handleChangeCollectionViewTitle(event.currentTarget.value)}
          />
        </div>
        {collectionView.format.formShowDescription &&
          ((readonly && collectionView.format.formDescription) || !readonly) && (
            <div className="py-2 text-center">
              <RichTextEdit
                keydownOption={FROM_KEYDOWN_OPTION}
                readonly={readonly}
                uuid={viewId}
                className={'text-t1'}
                placeholder={readonly ? '' : '请输入收集表说明'}
                segments={
                  typeof collectionView.format.formDescription === 'string'
                    ? textToSegments(collectionView.format.formDescription)
                    : collectionView.format.formDescription
                }
                onChange={(segments) => {
                  if (segmentsToText(segments).length <= 1000) {
                    handleChangeDescription(segments);
                  }
                }}
              />
            </div>
          )}
        <div className="h-5" />
        <SortableList
          disabled={readonly}
          onDragStart={(event) => setIsDrag(event.active.id.toString())}
          onDragEnd={() => setIsDrag(null)}
          items={renderProperties}
          onChange={changeSortProperties}
          modifiers={[restrictToVerticalAxis]}
          renderItemContent={({ item, renderDragHandle }) => {
            return (
              <PropertyElement
                {...item.data}
                hiddenElement={isDrag === item.id}
                readonly={readonly}
                formProperties={item.formProperty}
                renderDragHandle={renderDragHandle}
                index={
                  startIndex +
                  renderProperties.findIndex((it) => it.data.property === item.data.property)
                }
                handleChangeSubmitData={handleChangeSubmitData}
                submitData={item.submitData}
                uploadFileList={uploadFileList}
                pushUploadFileList={pushUploadFileList}
              />
            );
          }}
        />
        {!readonly && (
          <div className="pb-10">
            <FormComponent.CreateFormProperty viewId={viewId} collectionId={collectionId} />
          </div>
        )}
      </div>
      {readonly && !isSharePage && !formIllegal ? (
        <Button
          colorType="active"
          size="auto"
          className="h-12 w-44 self-end my-10"
          onClick={validateForm}
        >
          提交
        </Button>
      ) : (
        <div className="h-20" />
      )}
    </div>
  );
};
// #endregion

// #region 编辑收集表组件
interface PropertyElementProps {
  formProperties?: PickArray<CollectionViewFormProperties>;
  index: number;
  renderDragHandle: SortableListRenderDragHandle;
  handleChangeSubmitData: HandleChangeSubmitDataType;
  submitData?: SegmentDTO[];
  uploadFileList: Record<string, { id: string; url: string }[]>;
  pushUploadFileList: (property: string, id: string, url: string) => void;
  readonly: boolean;
  hiddenElement: boolean;
}
const PropertyElement: FC<CollectionProperty & PropertyElementProps> = (props) => {
  const {
    readonly,
    handleChangeSubmitData,
    property,
    uploadFileList,
    submitData,
    type,
    name,
    pushUploadFileList,
    renderDragHandle,
    index,
    formProperties,
    hiddenElement,
  } = props;

  const { collectionId, viewId } = useBitable();
  const collectionView = useCollectionView(viewId);
  const { formPermission, formSerialNumber } = collectionView?.format || {};
  const showPerson = formPermission && [FormPermission.WORKSPACE].includes(formPermission);
  const { propertySchema: schema } = usePropertySchema(collectionId, property);
  const isSharePage = isShareLink();

  const params = {
    readonly,
    schema,
    collectionId,
    submitData,
    key: property,
    property,
    handleChangeSubmitData,
    spaceId: collectionView?.spaceId,
    viewId,
    type,
  };

  const titleAfterAndBefore: {
    after?: ReactNode;
    before?: ReactNode;
  } = {};

  let element = <FormComponent.FormatTextarea {...params} />;

  switch (type) {
    case CollectionSchemaType.CHECKBOX:
      element = <FormComponent.CheckBox {...params} />;
      break;
    case CollectionSchemaType.DATE:
      element = <FormComponent.DateElement {...params} />;
      break;
    case CollectionSchemaType.FILE:
      element = (
        <FormComponent.File
          {...params}
          uploadFileList={uploadFileList[property]}
          pushUploadFileList={pushUploadFileList}
        />
      );
      break;
    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT:
      titleAfterAndBefore.after = (
        <span className="text-grey3 mx-1 w-full">
          {type === CollectionSchemaType.SELECT ? '【单选】' : '【多选】'}
        </span>
      );
      element = <FormComponent.Select {...params} type={type} />;
      break;
    case CollectionSchemaType.PERSON:
      if (!showPerson) {
        titleAfterAndBefore.after = (
          <Tooltip
            hideOnClick={false}
            className="w-3.5 pt-px"
            maxWidth={300}
            onClick={(e) => e.stopPropagation()}
            popup={'非当前空间成员无法填写成员属性'}
          >
            <Icon name="IcBadgeDanger" size="xsmall" className="ml-0.5 mt-1.5" />
          </Tooltip>
        );
      }
      element = <FormComponent.Person {...params} />;
      break;
    case CollectionSchemaType.NUMBER:
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.URL:
    case CollectionSchemaType.PHONE:
      element = <FormComponent.FormatTextarea {...params} type={type} />;
      break;
    default:
  }
  const description =
    typeof formProperties?.description === 'string'
      ? textToSegments(formProperties?.description)
      : formProperties?.description;

  return (
    <FormComponent.Container
      readonly={hiddenElement || readonly}
      titleAfter={titleAfterAndBefore.after}
      titleBefore={titleAfterAndBefore.before}
      renderDragHandle={renderDragHandle}
      collectionId={collectionId}
      index={index + 1}
      property={property}
      formSerialNumber={formSerialNumber}
      title={name}
      required={formProperties?.required}
      showDescription={formProperties?.showDescription && !hiddenElement}
      description={description}
      viewId={viewId}
    >
      {!hiddenElement &&
        (isSharePage ? (
          <div className="pointer-events-none cursor-default">{element}</div>
        ) : (
          element
        ))}
    </FormComponent.Container>
  );
};
// #endregion

export const FormView: FC<CollectionViewProps> = () => {
  const { viewId, collectionId, readonly } = useBitable();
  const collectionView = useCollectionView(viewId);
  const openAudit = useOpenAudit(viewId, { blockType: 'form' });
  const formIllegal = collectionView?.format.formIllegal;
  const showAudit = useShowAudit(collectionId);

  return (
    <>
      {formIllegal && (
        <div className="flex items-center justify-between bg-red text-white h-14 text-t2 px-4">
          <span />
          <span>当前页面违反平台分享协作条例，已禁止使用</span>
          {showAudit && (
            <Button colorType="white" onClick={openAudit}>
              申诉
            </Button>
          )}
        </div>
      )}
      <div className="flex mx-auto mt-5 max-w-[1050px]">
        <div className="next-modal px-20 w-full">
          <FormViewContent />
        </div>
        {!readonly && <FormSlideBar />}
      </div>
    </>
  );
};
