import { BlockType } from '@next-space/fe-api-idl';
import { CheckMatchAIBlockTypes } from 'src/editor/editor/uikit/ai-editor/get-block-ids-content';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';

export const checkPageIsEmpty = (
  pageId: string,
  currentBlockId: string,
  blocks = getState().blocks
): boolean => {
  const collection = blocks[pageId];
  if (!collection) return false;

  const loop = (subNodes: string[]): boolean => {
    for (const blockId of subNodes) {
      const block = blocks[blockId];
      if (!block) continue;

      const blockType = block.type;
      if (!CheckMatchAIBlockTypes.has(blockType)) continue;

      switch (block.type) {
        case BlockType.TEXTAREA:
        case BlockType.TODO:
        case BlockType.HEADER:
        case BlockType.MARK:
        case BlockType.QUOTE:
        case BlockType.LIST:
        case BlockType.ORDER_LIST:
        case BlockType.FOLD_LIST: {
          const text = segmentsToText(block.data.segments).trim();
          if (text) {
            if (blockId === currentBlockId && /^\/\w*/.test(text)) {
              // 当前块以 / 开头时不算有内容
              continue;
            } else {
              return false;
            }
          }
          break;
        }
        case BlockType.COLUMN:
        case BlockType.COLUMN_LIST: {
          return loop(block.subNodes ?? []);
        }
        default:
          break;
      }
    }

    return true;
  };

  return loop(collection.subNodes);
};
