import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import { useSelectFile } from 'src/editor/component/file/empty-file';
import { ImageMoreMenu } from 'src/editor/component/image/more-menu';
import { UploadError } from 'src/editor/component/image/upload-error';
import { ResizeElement } from 'src/editor/component/resize-element';
import { ImageElement, useImageParams } from 'src/editor/editor/plugin/image';
import { useCaptionStyle } from 'src/hooks/block/use-align-style';
import { useResize } from 'src/hooks/block/use-resize';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useDownloadFile } from 'src/hooks/drive/use-download';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsSelected } from 'src/redux/managers/ui';
import { UploadStatus } from 'src/redux/types';
import { useTextareaPlaceHolder } from 'src/services/app';
import { useUploadInfoById } from 'src/services/upload';
import { $searchParams } from 'src/utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import isURL from 'validator/lib/isURL';
import { NodeWrapper } from '../component/node-wrapper';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

/** 大部分代码抄自imageElement */
export const ImageNode: MindNodeElement = React.memo((props) => {
  const blockId = props.ownerBlockId ?? props.id;
  const block = usePickBlock(
    props.id,
    ['data'],
    ['caption', 'ossName', 'format', 'width', 'height', 'size']
  );
  const openImagePreview = useOpenImagePreview();
  const isSelected = useIsSelected(props.id);
  const selectFile = useSelectFile({ uuid: props.id });
  const uploadInfo = useUploadInfoById(blockId);
  const readonly = useReadonly(blockId);
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(props.id);
  const downloadFile = useDownloadFile();
  const pageId = useGetPageId();
  const openCommentPopup = useOpenNewDiscussionPopup();

  const { role } = usePermissions(pageId);
  const {
    onRenderSize,
    changeSize,
    containerRef,
    isDisable,
    defaultWidth,
    defaultHeight,
    defaultBlockFullWidth,
    widthBreakPoints,
    containerWidth,
  } = useResize({
    id: blockId,
    root: false,
    fullPageContentWidth: false,
  });
  const { formatUrl, loading, loadingCalcSize, srcSetParams, url, imageLink } = useImageParams(
    props.id,
    {
      containerWidth,
      defaultWidth,
    }
  );
  useMonitorFocused(containerRef, props.id, 'caption');
  const captionStyle = useCaptionStyle(props.id);
  const showError =
    uploadInfo?.status === UploadStatus.failure ||
    (!isURL(formatUrl) && !formatUrl.startsWith('blob') && !formatUrl.endsWith('=') && formatUrl);

  const updateSegments = useThrottleUpdateSegments(props.id, 'caption');

  if (!block) return null;

  const openSelectFile = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    selectFile({ getBoundingClientRect: () => rect }, 'bottom', [0, -(rect.height * 0.6)]);
  };

  const onReplace = () => {
    if (!containerRef.current) return;
    openSelectFile(containerRef.current);
  };

  return (
    <NodeWrapper id={blockId} level={props.level} ref={containerRef}>
      <div className="w-min px-3">
        {(block.data.format?.isShowCaption ?? true) && (
          <MindMapRichText
            placeholder={placeHolder}
            uuid={props.id}
            alwaysShowPlaceholder={alwaysShowPlaceHolder}
            className="whitespace-pre-wrap break-words"
            segments={block.data.caption}
            segmentType="caption"
            onChange={(
              segments: SegmentDTO[],
              prevContent: IContent,
              prevSelection: ISelection | null
            ) => {
              updateSegments(segments, [prevContent, prevSelection]);
            }}
            style={captionStyle}
          />
        )}
        {showError ? (
          <UploadError
            onClick={(e) => {
              openSelectFile(e.currentTarget);
            }}
          />
        ) : (
          <ResizeElement
            fullHeight
            resizeWidth
            readonly={readonly || isDisable}
            defaultWidth={defaultWidth ?? 200}
            defaultHeight={defaultHeight}
            defaultBlockFullWidth={defaultBlockFullWidth}
            maxWidth={500}
            maxHeight={200}
            widthBreakPoints={widthBreakPoints}
            minHeight={36}
            minWidth={36}
            onChange={changeSize}
            onRenderSize={onRenderSize}
            className={cx(
              'max-[520px]',
              isDisable && 'pointer-events-none',
              $searchParams.print && 'my-2.5'
            )}
          >
            {loading ? (
              <LoadingContainer
                style={{
                  height: loadingCalcSize.height,
                  width: block.data.format?.blockFullWidth ? '100%' : loadingCalcSize.width,
                  maxWidth: '100%',
                }}
              />
            ) : (
              <ImageElement
                className={cx('w-full ', {
                  'preview-file cursor-[zoom-in]': isSelected || readonly,
                })}
                onClick={(e) => {
                  if (isSelected || readonly) {
                    openImagePreview({ uuid: props.id, src: (e.target as HTMLImageElement).src });
                  }
                }}
                blockId={props.id}
                srcSetParams={srcSetParams}
                formatUrl={formatUrl}
                url={url}
                maxWidth={containerWidth}
                imageLink={imageLink}
              />
            )}
            {isSelected && !readonly && !props.ownerBlockId && (
              <ImageMoreMenu
                blockId={blockId}
                onReplace={onReplace}
                onDownload={() => downloadFile(props.id)}
                onAddComment={
                  Role.contains(role, PermissionRole.COMMENTER)
                    ? () => {
                        openCommentPopup({ blockId: props.id });
                      }
                    : undefined
                }
                externalLink={false}
              />
            )}
          </ResizeElement>
        )}
      </div>
    </NodeWrapper>
  );
});
