import type { BlockDTO, BlockType, IconData } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { ListView } from 'src/common/components/list-view';
import { ListItemType } from 'src/common/components/list-view/types';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { request } from 'src/common/request';
import { segmentsToText } from 'src/editor/utils/editor';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';
import { getUntitledName } from 'src/utils/get-untitled-name';
import type { SearchItem } from '../search';

interface Props {
  onSelect?(pageInfo: SearchItem): void;
}
const MAX_RECENTLY = 10;
export const RecentlyList: FC<Props> = ({ onSelect }) => {
  const [loading, setLoading] = useState(true);
  const [searchItems, setSearchItems] = useState<
    {
      type: ListItemType;
      data: {
        uuid: string;
        title: string;
        icon: IconData | undefined;
        type: BlockType;
        backgroundColor: string | undefined;
      };
    }[]
  >([]);

  useEffect(() => {
    const loadRecentlyHistory = async () => {
      const response = await request.editor.querySpaceVisitsHistory(getCurrentSpaceId(), 0);
      setLoading(false);
      if (typeof response !== 'object') return;

      const { results, recordMap } = response;
      if (!results) return;
      if (!recordMap) return;

      const { blocks } = recordMap;
      if (!blocks) return;

      dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));

      const getItem = (uuid?: string) => {
        if (uuid) {
          const block = blocks[uuid];
          if (isPageLike(block?.type)) {
            return block;
          }
        }
      };
      const recent: BlockDTO[] = [];
      let count = 0;
      // 只显示MAX_RECENTLY个数的最近浏览记录
      for (let i = 0; count < MAX_RECENTLY && i < results.length; i++) {
        const uuid = results[i]?.uuid;
        const item = getItem(uuid);
        if (item && recent.length < MAX_RECENTLY) {
          count++;
          recent.push(item);
        }
      }
      const items = recent.map((block) => {
        return {
          type: ListItemType.SEARCH,
          data: {
            uuid: block.uuid,
            title: segmentsToText(block.data.segments) || getUntitledName(block.type),
            icon: block.data?.icon,
            type: block.type,
            backgroundColor: block.backgroundColor,
          },
        };
      });
      setSearchItems(items);
    };
    void loadRecentlyHistory();
  }, []);

  const handleMenuItemClick = (_: unknown, index: number) => {
    const searchItem = searchItems[index];
    if (searchItem) {
      // @ts-ignore:  I know what I do
      onSelect?.(searchItem.data);
    }
  };

  return (
    <div className={'relative overflow-y-auto h-[300px]'}>
      <ListView
        items={searchItems}
        hasMore={false}
        scrollContainerStyle={{ top: '52px' }}
        onItemClick={handleMenuItemClick}
        customFooter={
          <>
            {loading && (
              <div className="relative flex flex-row items-center justify-center h-10">
                <LoadingIcon size="middle" />
                <div className="flex items-center ml-2 text-center text-t2 text-grey4">
                  正在加载...
                </div>
              </div>
            )}
            {!loading && searchItems.length === 0 && (
              <div className="text-grey4 text-center mt-[100px]">还没有最近浏览记录</div>
            )}
          </>
        }
      />
    </div>
  );
};
