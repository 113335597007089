import { cx } from '@flowus/common/cx';
import isHotkey from 'is-hotkey';
import type { ChangeEvent, CSSProperties, FC, FocusEvent, KeyboardEvent } from 'react';
import { forwardRef, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export type AutoHeightTextAreaProps = Omit<JSX.IntrinsicElements['textarea'], 'ref'> & {
  readonly?: boolean;
  singleLine?: boolean;
  fontClassName?: string;
  boxClassName?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;
  autoFocus?: boolean;
  maxLength?: number;
  onClick?: (MouseEvent: MouseEvent) => void;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onKeyDown?(event: KeyboardEvent<HTMLTextAreaElement>): void;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  placeholder?: string;
  autoSelectAll?: boolean;
  /** 是否支持shiftEnter 默认禁用 */
  shiftEnter?: boolean;
};

interface InputProps {
  singleLine?: boolean;
  [key: string]: unknown;
}

const Input: FC<InputProps> = forwardRef(({ singleLine, ...props }, ref) => {
  if (singleLine) {
    return <input {...props} ref={ref as any} />;
  }
  return <textarea {...props} ref={ref as any} />;
});

export const AutoHeightTextArea = forwardRef<HTMLTextAreaElement, AutoHeightTextAreaProps>(
  (props, ref) => {
    const {
      className,
      fontClassName,
      boxClassName,
      singleLine,
      style,
      onKeyDown,
      autoSelectAll,
      shiftEnter,
      readonly,
      disabled,
      ...rest
    } = props;
    useEffect(() => {
      // @ts-ignore ref current
      const element = ref?.current as HTMLTextAreaElement;
      if (autoSelectAll) {
        setTimeout(() => element.select());
      }
    }, [autoSelectAll, ref]);

    const inputProps = {
      spellCheck: false,
      ref,
      disabled: disabled || readonly,
      style: {
        ...style,
        height: typeof style?.height === 'string' ? Number(style?.height) : style?.height,
        fontWeight: 'inherit',
      },
      className: cx(
        'w-full h-full bg-transparent resize-none block overflow-hidden appearance-none outline-none placeholder-grey4 overscroll-none',
        (disabled || readonly) && 'pointer-events-none',
        className,
        fontClassName
      ),
      ...rest,
      onKeyDown: (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (isHotkey(['Enter', 'Shift+Enter'])(event)) {
          if (!shiftEnter) {
            event.preventDefault();
          }
        }
        onKeyDown?.(event);
      },
    };

    if (!singleLine) {
      return (
        <div className={cx('relative', boxClassName)}>
          <TextareaAutosize {...inputProps} />
          {props.children}
        </div>
      );
    }

    return (
      <div className={cx('relative', boxClassName)}>
        <Input {...inputProps} singleLine />
        {props.children}
      </div>
    );
  }
);
