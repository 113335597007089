import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { Placement } from '@popperjs/core';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { useCopyBlockRecord } from 'src/hooks/block/use-copy-block-record';
import { useFetchSpaceCapacity } from 'src/hooks/drive/use-fetch-space-capacity';
import { useCurrentSpace } from 'src/hooks/space';
import { useAsyncTransaction, useTransaction } from 'src/hooks/use-transaction';
import { UPDATE_BLOCK } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { moveBlock } from 'src/redux/managers/block/move';
import { removeBlock } from 'src/redux/managers/block/remove';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { cache, dispatch } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { setExpandPageRecord } from 'src/services/app';
import { $currentUserCache } from 'src/services/user/current-user';
import { sleep } from 'src/utils/async-utils';
import { isCollection, isPageLike } from 'src/utils/block-type-utils';
import { dataNeedForCopy } from 'src/utils/copy-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { idsToSelectedBlocks, selectedBlocksToIds } from 'src/utils/select-block-util';
import { v4 } from 'uuid';
import { MovePanel } from './move-panel';
import { useCheckCopySubNodes } from 'src/hooks/block/use-copy-sub-nodes-api';

const MODAL_ID = v4();
export interface MoveToProps {
  popcorn?: ModalSchema.PopcornType;
  uuid: string;
  placement?: Placement;
  offset?: number[];
  copyToSpace?: string;
  callback?: (id: string) => void;
  placeholder?: string;
  mode?: 'dropdown' | 'modal';
  copyAndMove?: {
    syncId?: string;
    viewId?: string;
  };
}
/**
 * 移动根页面：**默认菜单仅显示除自己外的根页面；若该页面为唯一根页面时菜单默认为空。
 * 移动子页面：**默认菜单仅显示除该子页面所属根页面外的其他根页面，以及空间路径；若该子页面所属根页面为唯一根页面时，菜单默认仅显示空间路径。
 */
export const useOpenMoveTo = () => {
  const openModal = useOpenModal();

  const moveTo = (params: MoveToProps) => {
    const { popcorn, placement = 'right-start', offset = [20, 0], mode = 'dropdown' } = params;

    if (mode === 'dropdown' && popcorn) {
      openModal.dropdown({
        popcorn,
        placement,
        offset,
        modalId: MODAL_ID,
        content: () => <MoveTo {...params} />,
      });
    }
    if (mode === 'modal') {
      openModal.modal({
        modalId: MODAL_ID,
        content: () => <MoveTo {...params} />,
      });
    }
  };

  return moveTo;
};

const MoveTo = (props: Omit<MoveToProps, 'popcorn' | 'offset' | 'placement' | 'mode'>) => {
  const { uuid, copyAndMove, callback, copyToSpace, placeholder } = props;

  const openModal = useOpenModal();
  const copyBlockRecord = useCopyBlockRecord();
  const transaction = useTransaction();
  const asyncTransaction = useAsyncTransaction();
  const currentSpace = useCurrentSpace();
  const pageId = useGetPageId();
  const history = useHistory();
  const checkCopySubNodes = useCheckCopySubNodes();

  const copyIds = async (params: {
    ids: string[];
    syncId?: string;
    posBlockId: string;
    viewId?: string;
    effects?: (ids: SelectBlock[]) => void;
  }) => {
    const fakeBlock = addBlock({ type: BlockType.TEXTAREA }, { parentId: params.posBlockId });
    removeBlock(fakeBlock);
    const newBlocks = await copyBlockRecord(
      idsToSelectedBlocks(params.ids, params.viewId, params.syncId),
      fakeBlock,
      params.syncId,
      { effects: params.effects }
    );
    return selectedBlocksToIds(newBlocks ?? []);
  };

  const closeModal = () => {
    openModal.closeModal(MODAL_ID);
  };

  const { blocks, ui } = cache;
  const { selectedBlocks } = ui;
  const block = blocks[uuid];

  if (!block) return null;

  const ids = selectedBlocks.length ? selectedBlocksToIds(selectedBlocks) : [block.uuid];

  return (
    <MovePanel
      placeholder={placeholder}
      uuid={uuid}
      onSelect={async (target) => {
        // @ts-ignore: 移动到空间页面
        if (target.type === 'SPACE' && target.uuid !== currentSpace.uuid) {
          closeModal();

          openModal.loading({
            modalId: 'move-to-space',
            title: '正在移动页面，请稍等... ',
          });

          try {
            // 移动到其他空间
            const handleCopyToOtherSpace = async (moveIds: string[]) => {
              try {
                await checkCopySubNodes({
                  spaceId: target.uuid,
                  forceAwait: true,
                  ids: moveIds,
                  isMoveOtherSpace: true,
                });
                const result = await request.editor.docsMoveToSpace({
                  spaceId: target.uuid,
                  list: moveIds,
                });
                openModal.closeModal('move-to-space');

                if (uuid === pageId) {
                  const first = result.moveList[0];
                  if (first) {
                    history.push(`/${first.targetId}`);
                  }
                }
              } catch (err) {
                openModal.closeModal('move-to-space');
              }
            };

            if (copyAndMove) {
              asyncTransaction(async () => {
                await copyIds({
                  ids,
                  posBlockId: block.uuid,
                  syncId: copyAndMove.syncId,
                  viewId: copyAndMove.viewId,
                  effects: async (newBlocks) => {
                    await sleep(500);
                    void handleCopyToOtherSpace(selectedBlocksToIds(newBlocks));
                    message.success('已完成拷贝副本');
                  },
                });
              });
            } else {
              void handleCopyToOtherSpace(ids);
            }
          } catch {
            openModal.closeModal('move-to-space');
          }

          return;
        }

        // 用户搜索到的不管符不符合都要展示给用户知道,所以对不符合移动的操作做一下处理
        if (ids.includes(target.uuid)) {
          message.warning(`不能移动自己所在页面`);
          return;
        }

        if (target.navs) {
          const found = target.navs.some((v) => ids.some((id) => id === v.uuid));
          if (found) {
            message.warning(`不能移动自己的子页面`);
            return;
          }
        }

        const targetBlock = cache.blocks[target.uuid];
        if (!targetBlock) return;

        if (
          isCollection(targetBlock.type) &&
          ids.some((id) => cache.blocks[id]?.type !== BlockType.PAGE)
        ) {
          message.warning(`无法移动至多维表页面`);
          return;
        }
        if (
          targetBlock.type === BlockType.FOLDER &&
          ids.some((id) => {
            const type = cache.blocks[id]?.type;
            if (type === BlockType.FILE) {
              return !cache.blocks[id]?.data.ossName;
            }

            return !isPageLike(type);
          })
        ) {
          message.warning(`无法移动至文件夹页面`);
          return;
        }

        closeModal();

        setExpandPageRecord((target.navs || []).map((o) => o.uuid).concat(target.uuid), true);

        // 最终的移动是这个fn
        const _moveBlock = (moveIds: string[]) => {
          if (copyToSpace) {
            const rest = dataNeedForCopy(block);
            addBlock(rest, { parentId: target.uuid, last: true });
          } else {
            // @ts-ignore: 移动到空间页面
            if (target.type === 'SPACE') {
              moveIds.forEach((id) => {
                const _block = cache.blocks[id];
                if (
                  _block &&
                  !_block.permissions.some(
                    (p) => p.type === PermissionType.SPACE && p.role !== PermissionRole.NONE
                  )
                ) {
                  setBlockPermission(uuid, {
                    type: PermissionType.SPACE,
                    role: PermissionRole.EDITOR,
                    userId: $currentUserCache.uuid,
                  });
                }
              });
            }

            // @ts-ignore: 移动到个人页面
            if (target.type === 'PRIVATE') {
              moveIds.forEach((id) => {
                removeBlockPermission(id, {
                  type: PermissionType.SPACE,
                });
                setBlockPermission(id, {
                  type: PermissionType.USER,
                  role: PermissionRole.EDITOR,
                  userId: $currentUserCache.uuid,
                });
              });
            }

            moveBlock(moveIds, {
              parentId: target.uuid,
              last: true,
            });
          }
          dispatch(
            UPDATE_BLOCK({
              uuid: block.uuid,
              patch: {
                updatedAt: Date.now(),
              },
              ignoreOp: true,
            })
          );
        };

        // 如果是拷贝到（拷贝+移动）需要特殊处理，在copy之后执行移动，确保是一次操作，可以一键撤回
        if (copyAndMove) {
          await copyIds({
            ids,
            posBlockId: target.uuid,
            syncId: copyAndMove.syncId,
            viewId: copyAndMove.viewId,
            effects: (newBlocks) => {
              const newIds = selectedBlocksToIds(newBlocks);
              _moveBlock(newIds);
              message.success('已完成拷贝副本');
            },
          });
        } else {
          // 只是移动的话，直接transaction就可以
          transaction(() => {
            _moveBlock(ids);
          });
        }

        callback?.(target.uuid);
      }}
      // 暂时先屏蔽
      // footer={
      //   <div className="absolute bottom-0 w-full h-10 bg-white2 border-t border-grey6">
      //     <div
      //       className="flex items-center justify-center h-full animate-hover text-grey3"
      //       onClick={() => {
      //         moveToNew(block, {
      //           copyToSpace,
      //           callback,
      //           copyAndMove,
      //         });
      //         closeModal();
      //       }}
      //     >
      //       <Icon name="IcAddSmallBold" size="small" />
      //       <div className="ml-1.5 text-t2">创建新页面</div>
      //     </div>
      //   </div>
      // }
      source="move"
    />
  );
};
