import { v4 as uuid } from 'uuid';

interface Task<R> {
  run: () => Promise<R>;
  onSuccess?: (result: R) => void;
  onFailed?: (err: any) => void;
}
type Task0<R> = Task<R> & { taskId: string };

/**任务队列，可控制任务的并发数量 */
export class TaskPool {
  private pendingTask: Task0<any>[] = [];
  private runningTaskSize = 0;
  private poolSize: number;
  constructor(poolSize: number) {
    this.poolSize = poolSize;
  }
  public enqueueTask<R>(task: Task<R>) {
    const taskId = uuid();
    this.pendingTask.push({ ...task, taskId });
    this.notifyTaskChange();
    return taskId;
  }
  public removeTask(taskId: string) {
    const index = this.pendingTask.findIndex((t) => t.taskId === taskId);
    if (index !== -1) {
      this.pendingTask.splice(index, 1);
      return true;
    }
  }
  private notifyTaskChange() {
    if (this.pendingTask.length === 0) return;
    const canRunTaskSize = this.poolSize - this.runningTaskSize;
    for (let i = 0; this.pendingTask.length > 0 && i < canRunTaskSize; i++) {
      const taskInfo = this.pendingTask.shift();
      if (!taskInfo) return;
      //执行任务
      this.runningTaskSize++;
      void taskInfo
        .run()
        .then(taskInfo.onSuccess)
        .catch(taskInfo.onFailed)
        .finally(() => {
          this.runningTaskSize--;
          this.notifyTaskChange();
        });
    }
  }
}
