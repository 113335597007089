import { CollectionSchemaType, BlockType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import { FeatureFlags } from 'src/feature-flags';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getTablePropertyValueForChart } from 'src/hooks/collection-view/get-property-value';
import {
  convertText,
  convertTextForMindMap,
} from 'src/hooks/page/use-copy-listener/on-cut-copy/convert-text';
import { cache, getState } from 'src/redux/store';
import { isMindMap } from 'src/utils/block-type-utils';

export const CheckMatchAIBlockTypes = new Set([
  BlockType.TEXTAREA,
  BlockType.HEADER,
  BlockType.TODO,
  BlockType.LIST,
  BlockType.ORDER_LIST,
  BlockType.FOLD_LIST,
  BlockType.MARK,
  BlockType.QUOTE,
  BlockType.COLUMN_LIST,
  BlockType.COLUMN,
  BlockType.TABLE,
  BlockType.CODE,
  BlockType.EQUATION,
  BlockType.DIVIDER,
]);
if (FeatureFlags.AI_MIND_MAP) {
  CheckMatchAIBlockTypes.add(BlockType.MIND_MAPPING);
}

export const getBlockIdsContent = (ids: string[]): string => {
  const { blocks } = getState();
  const newIds = ids.filter((uuid) => {
    const block = blocks[uuid];
    if (block) {
      return CheckMatchAIBlockTypes.has(block.type);
    }
    return false;
  });
  // 思维导图特殊对待
  if (newIds.length === 1 && isMindMap(blocks[ids[0] ?? '']?.type)) {
    return convertTextForMindMap(ids[0] ?? '');
  }

  return convertText(newIds);
};

export const getBitableContent = (uuid: string) => {
  const { blocks } = cache;
  const collection = cache.blocks[uuid];
  if (!collection) return '';

  const viewId = localStorage.getItem(`viewId:${collection.uuid}`) ?? '';
  const { view, properties } = getViewFormat(viewId) ?? {};
  if (!view || !properties) return '';
  let str = '';

  let splitLine = '';
  const schema = collection.data.schema ?? {};
  properties.forEach(({ property }, index) => {
    if (index === 0) {
      str += '\n| ';
      splitLine += '\n| ';
    }
    str += `${schema[property]?.name} |`;
    splitLine += `--- |`;
  });

  str += splitLine;

  collection.subNodes.forEach((uuid) => {
    const block = blocks[uuid];
    const propertyData = block?.data.collectionProperties;
    if (!propertyData) return;

    properties.forEach((item, index) => {
      const type = schema[item.property]?.type;
      if (!type) return;
      const value = getTablePropertyValueForChart(block, item.property, undefined, cache);
      if (value) {
        let text = value.join('');
        if (index === 0) str += '\n| ';
        text = text.startsWith('@') ? text.slice(1) : text;
        str += `${text} |`;
      } else {
        str += `-- |`;
      }
    });
  });

  return str.trim();
};

export const ExcludePropertyType = new Set([
  CollectionSchemaType.CREATED_AT,
  CollectionSchemaType.CREATED_BY,
  CollectionSchemaType.UPDATED_AT,
  CollectionSchemaType.UPDATED_BY,
  CollectionSchemaType.RELATION,
  CollectionSchemaType.FORMULA,
  CollectionSchemaType.ROLLUP,
  CollectionSchemaType.PERSON,
  CollectionSchemaType.CHECKBOX,
]);
