import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { cx } from '@flowus/common/cx';
import type { CollectionSchema, CollectionViewProperties } from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType, PermissionRole } from '@next-space/fe-api-idl';
import { css } from 'otion';
import type { CSSProperties, FC, MouseEvent } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from 'react-redux';
import { ICON_MAP, READONLY_PROPERTIES } from 'src/bitable/const';
import { BitableContext } from 'src/bitable/context';
import { getCellValueComponent } from 'src/bitable/table-view/cell';
import { getCellEditorComponent } from 'src/bitable/table-view/cell/editor';
import { Site } from 'src/bitable/table-view/cell/types';
import { PropertyManagePlace } from 'src/bitable/table-view/types';
import { useOpenPropertyWidget } from 'src/bitable/table-view/widgets/property';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { NextModalProvider, useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { ImagesProvider } from 'src/components/images-provider';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useReadonly } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { setAppUiState, useIsNewProperty } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { OverlayContainerContext } from '@flowus/common/hooks/react-utils';
import { LOCAL_LNG } from '@flowus/common/const';

interface Props {
  uuid: string;
  className?: string;
}

interface PropertyItem {
  schema: CollectionSchema;
  property: string;
  visible: boolean;
}

const useProperties = (collectionId?: string) => {
  const transaction = useTransaction();
  const readonly = useReadonly(collectionId);

  const result = useObservableStore(
    ({ blocks }) => {
      if (!collectionId) return;

      const collection = blocks[collectionId];
      if (!collection) return;

      const { schema: schemas, collectionPageProperties = [] } = collection.data;
      if (!schemas) return;

      const newProperties: CollectionViewProperties = [];
      const sorted: PropertyItem[] = [];
      const sortedIds = new Set<string>();

      let needUpdateProperties = false;

      collectionPageProperties.forEach((item) => {
        const schema = schemas?.[item.property];
        if (!schema) {
          needUpdateProperties = true;
          return;
        }

        if (!sortedIds.has(item.property)) {
          newProperties.push({ ...item });
          sortedIds.add(item.property);
          sorted.push({ ...item, schema });
        }
      });

      Object.entries(schemas).forEach(([property, schema]) => {
        if (sortedIds.has(property)) return;

        needUpdateProperties = true;
        const newProperty = { property, visible: true };

        sortedIds.add(property);
        newProperties.push(newProperty);
        sorted.push({ ...newProperty, schema });
      });

      return { needUpdateProperties, newProperties, sorted };
    },
    [collectionId]
  );

  useEffect(() => {
    if (!result?.needUpdateProperties || !result.newProperties || readonly) return;

    if (result.needUpdateProperties) {
      transaction(() => {
        if (!collectionId) return;
        updateBlock(collectionId, {
          data: { collectionPageProperties: result.newProperties },
        });
      });
    }
  }, [collectionId, readonly, result?.needUpdateProperties, result?.newProperties, transaction]);

  return result?.sorted;
};

export const PageProperties: FC<Props> = ({ uuid }) => {
  const readonly = useReadonly(uuid);
  const transaction = useTransaction();
  const [showHide, setShowHide] = useState(false);
  const createProperty = useCreateProperty();

  const context = useObservableStore(
    useCallback(
      ({ blocks }) => {
        const record = blocks[uuid];
        if (!record) return;
        const collection = blocks[record.parentId];
        if (!collection) return;

        if (
          collection.type !== BlockType.COLLECTION_VIEW &&
          collection.type !== BlockType.COLLECTION_VIEW_PAGE
        ) {
          return;
        }

        return {
          viewId: '',
          collectionId: collection.uuid,
          parentRole: getPermissions(collection.uuid, { ...getState(), blocks }).role,
        };
      },
      [uuid]
    ),
    [uuid]
  );
  const collectionIsLocked = useBlockLocked(context?.collectionId);
  const collectionReadonly = useReadonly(context?.collectionId);

  const properties = useProperties(context?.collectionId);

  if (!context || !properties) return null;

  const handleCreateProperty = () => {
    bizTracker.event('bitable_property_create', { from_scene: 'page' });

    const newPropertyId = createProperty({
      collectionId: context.collectionId,
    });
    if (newPropertyId) {
      setAppUiState({
        $newTableProperty: {
          propertyId: newPropertyId,
          from: PropertyManagePlace.PAGE_PROPERTIES,
        },
      });
    }
  };

  const filtered = properties.filter((item) => {
    if (item.schema.type === CollectionSchemaType.ID_NUMBER) return false;
    if (item.property === 'title') return false;
    if (showHide) return true;
    return item.visible;
  });

  const hideCount = properties.filter((item) => !item.visible).length;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      const oldIndex = properties.findIndex((item) => item.property === active.id);
      const newIndex = properties.findIndex((item) => item.property === over.id);
      const newItems = arrayMove(properties, oldIndex, newIndex);

      transaction(() => {
        updateBlock(context.collectionId, {
          data: {
            collectionPageProperties: newItems.map(({ property, visible }) => ({
              property,
              visible,
            })),
          },
        });
      });
    }
  };
  return (
    <BitableContext.Provider
      value={{
        readonly,
        collectionId: context.collectionId,
        viewId: '',
        viewParentId: '',
        changeView() {},
      }}
    >
      <NextModalProvider>
        <SortableList
          disabled={collectionIsLocked || collectionReadonly}
          items={filtered.map((item) => ({ id: item.property, ...item }))}
          onChange={(_, event) => handleDragEnd(event)}
          renderItemContent={({ item }) => {
            return (
              <SortablePropertyRow
                key={item.id}
                recordId={uuid}
                propertyId={item.id}
                schema={item.schema}
                disabled={collectionIsLocked || collectionReadonly}
              />
            );
          }}
        />

        {!readonly &&
          !collectionIsLocked &&
          (hideCount === 0 || showHide) &&
          context.parentRole !== PermissionRole.READER && (
            <button
              data-disable-select="true"
              onClick={handleCreateProperty}
              className={cx(
                'text-grey3 h-[36px] pl-1 rounded flex items-center duration-150 animate-hover w-[188px]'
              )}
            >
              <Icon name="IcAdd" className="mr-1" size="middle" />
              <span className="text-t2">添加属性</span>
            </button>
          )}

        {hideCount > 0 && (
          <button
            data-disable-select="true"
            className="animate-hover text-grey3 min-h-[36px] pl-1 pr-2 rounded flex items-center w-[188px]"
            onClick={() => setShowHide(!showHide)}
          >
            <Icon
              name="IcArrowDateBack"
              size="middle"
              className="mr-1"
              style={{
                transform: showHide ? 'rotate(90deg)' : 'rotate(-90deg)',
              }}
            />
            <span className="text-t2 ">
              {showHide ? `隐藏 ${hideCount} 个属性` : `${hideCount} 个隐藏属性`}
            </span>
          </button>
        )}

        <hr className="mt-2 mb-2 border-grey6" />
      </NextModalProvider>
    </BitableContext.Provider>
  );
};

interface RowProps {
  recordId: string;
  schema: CollectionSchema;
  propertyId: string;
  disabled?: boolean;
}

const SortablePropertyRow: FC<RowProps> = memo(({ schema, propertyId, recordId, disabled }) => {
  const openPropertyWidget = useOpenPropertyWidget();
  const propertyRef = useRef<HTMLButtonElement | null>(null);
  const isNewProperty = useIsNewProperty(propertyId, PropertyManagePlace.PAGE_PROPERTIES);

  useEffect(() => {
    if (!propertyRef.current) return;

    if (isNewProperty) {
      openPropertyWidget(propertyId, propertyRef.current, PropertyManagePlace.PAGE_PROPERTIES);
      setAppUiState({ $newTableProperty: undefined });
    }
  }, [isNewProperty, openPropertyWidget, propertyId]);

  return (
    <li data-disable-select="true" className="flex group relative">
      <div
        className=""
        onClick={(event) => {
          if (disabled) return;
          openPropertyWidget(propertyId, event.currentTarget, PropertyManagePlace.PAGE_PROPERTIES);
        }}
      >
        {!disabled && (
          <button
            className={cx(
              'absolute -left-5 top-1.5 opacity-0 w-5 h-6 flex items-center justify-center rounded text-grey4  animate-hover hover:text group-hover:opacity-100'
            )}
          >
            <Icon name="IcMenu" size="small" className="pointer-events-none" />
          </button>
        )}
        <button
          ref={propertyRef}
          className={cx('flex pl-1 self-start items-center min-h-[36px] text-left rounded', {
            'animate-hover': !disabled,
          })}
        >
          <Icon className="mr-1 text-grey3" size="middle" name={ICON_MAP[schema.type]} />
          <div className="flex-shrink-0 mr-1 overflow-hidden text-t2 text-grey3 w-36 overflow-ellipsis whitespace-nowrap">
            {schema.name}
          </div>
        </button>
      </div>

      <div className="flex-1 relative overflow-hidden animate-hover">
        <Property schema={schema} recordId={recordId} propertyId={propertyId} />
      </div>
    </li>
  );
});

interface PropertyProps {
  schema: CollectionSchema;
  recordId: string;
  propertyId: string;
}

const Property: FC<PropertyProps> = ({ schema, recordId, propertyId }) => {
  const openModal = useOpenModal();
  const { getState } = useStore();
  const readonly = useReadonly(recordId);
  const updatePropertyValue = useUpdatePropertyValue();
  const CellValueComponent = getCellValueComponent(schema.type);
  const CellEditorComponent = getCellEditorComponent(schema.type);
  const containerRef = useRef<HTMLDivElement>(null);

  const showEditor = (event: MouseEvent) => {
    if (
      readonly &&
      schema.type !== CollectionSchemaType.FILE &&
      schema.type !== CollectionSchemaType.RELATION
    ) {
      return;
    }

    if (schema.type === CollectionSchemaType.RELATION && getState().blocks[recordId]?.isTemplate) {
      message.warning('模板中暂不支持设置关联属性！');
      return;
    }

    if (READONLY_PROPERTIES.includes(schema.type) || schema.type === CollectionSchemaType.ROLLUP) {
      return;
    }
    if (schema.type === CollectionSchemaType.CHECKBOX) {
      const value = getState().blocks[recordId]?.data.collectionProperties?.[propertyId];
      if (formatCheckBoxValue(segmentsToText(value))) {
        updatePropertyValue(recordId, propertyId, textToSegments('NO'));
      } else {
        updatePropertyValue(recordId, propertyId, textToSegments('YES'));
      }
      return;
    }

    const modalStyle: CSSProperties = {};

    const isFixedWidthEditor =
      schema.type === CollectionSchemaType.DATE ||
      schema.type === CollectionSchemaType.FILE ||
      schema.type === CollectionSchemaType.FORMULA;

    const rect = event.currentTarget.getBoundingClientRect();
    if (!isFixedWidthEditor) {
      modalStyle.width = rect.width;
    }

    const offset = rect.height;
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      offset: [0, -offset],
      content({ onCloseModal }) {
        return (
          <div ref={containerRef} className="next-modal" style={modalStyle}>
            <OverlayContainerContext.Provider value={containerRef}>
              <CellEditorComponent
                site={Site.FIELD}
                recordId={recordId}
                propertyId={propertyId}
                onClose={onCloseModal}
                onUpdate={() => {}}
                width={rect.width}
              />
            </OverlayContainerContext.Provider>
          </div>
        );
      },
    });
  };

  return (
    <div
      className={cx(
        'text-t2 relative text-left whitespace-pre-wrap break-all min-w-[260px] min-h-[36px]',
        css({
          selectors: {
            ':empty:before': {
              content: `'${LOCAL_LNG.isEmpty}'`,
              display: 'block',
              fontSize: '14px',
              color: 'var(--grey4)',
              padding: '8px',
              lineHeight: '20px',
            },
          },
        })
      )}
      onClick={showEditor}
    >
      <ImagesProvider>
        <CellValueComponent site={Site.FIELD} recordId={recordId} propertyId={propertyId} />
      </ImagesProvider>
    </div>
  );
};
