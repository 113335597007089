import { useUpdatePermission } from '@flowus/common/block/use-update-permission';
import {
  removeBlockPermission,
  setBlockPermission,
} from 'src/redux/managers/block/update-permission';
import { usePickBlock } from 'src/utils/pick-block';
import { usePermissions } from '../share/use-permissions';

import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import { PermissionType } from '@next-space/fe-api-idl';
import { setPermission } from 'src/components/share/helper';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenAskUpgradeSpace } from 'src/views/main/setting-modal/use-open-ask-upgrade-space';
import { openSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { useIsFreeSpaceLimit } from '../space/use-is-pro-space';
import { useReadonly } from './use-read-only';

export const usePublicShare = (uuid: string) => {
  const openModal = useOpenModal();
  const block = usePickBlock(uuid, []);
  const permissionsState = usePermissions(uuid, { wait: 50 });
  const readonly = useReadonly(uuid);
  const parentPermission = usePermissions(block?.parentId);
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();

  const openParentRoleLimit = () => {
    openModal.warning({
      title: '修改后将不再继承上级页面的分享及其相关权限，确认修改？',
      confirm() {
        permissionsState.permissions.forEach((o) => setPermission(uuid, o));
        setBlockPermission(uuid, { type: PermissionType.RESTRICTED });
        dispatch(TRANSACTION_FIRE());
      },
    });
  };

  return useUpdatePermission({
    uuid,
    parentPermission,
    permissionsState,
    removeBlockPermission,
    setBlockPermission,
    setPermission,
    trackerEvent: bizTracker.event,
    onError: (msg, type) => {
      if (type === 'parentRoleLimit') {
        openParentRoleLimit();
        return;
      }

      if (type === 'proLimit') {
        openAskUpgradeSpace(() => {
          openSettingModal({
            defaultMenuType: SettingMenuType.upgrade,
            from: OpenSettingFrom.share_seo,
          });
        });
        return;
      }

      message.error(msg);
    },
    openParentRoleLimit,
    isFreeSpaceLimit,
    parentId: block?.parentId,
    readonly,
    spaceId: block?.spaceId,
    checkBreakRole() {
      return new Promise(() => {});
    },
  });
};
