import { getEmbedWebsiteUrl, isUrl } from '@flowus/common/embed-website';
import { shouldEncodeUrl } from '@flowus/common/url';
import { request } from 'src/common/request';
import { LRUCache } from 'src/common/utils/lru-cache';
import { isFlowUsApp } from './electron-util';
import { decodeName } from '@flowus/common/utils';
import type { AsyncReturnType } from 'type-fest';
import { SEVEN_DAY, THREE_DAY } from '@flowus/common/const';

const linkInfoCache = new LRUCache<
  string,
  {
    title: string;
    link: string;
    description?: string | undefined;
    icon?: string | undefined;
    cover?: string | undefined;
    headerMap?: Record<string, string>;
  }
>(localStorage, 'linkInfo', 50);

/** 缓存接口数据 & 获取网页响应头数据 */
export const getLinkInfo = async (
  url: string,
  tran = false
): Promise<
  | (AsyncReturnType<typeof getEmbedWebsiteUrl> & AsyncReturnType<typeof request.editor.linkInfo>)
  | undefined
> => {
  if (tran) {
    const embedRes = await getEmbedWebsiteUrl(url);
    if (embedRes.link) {
      url = embedRes.link;
      if (embedRes.isDone) {
        return embedRes as any;
      }
    }
  }

  if (url.includes(' ')) {
    const [first] = url.split(' ');
    if (first) {
      url = first;
    }
  }

  const cache = linkInfoCache.get(url);
  if (cache?.title) return cache;

  if (shouldEncodeUrl(url)) {
    url = encodeURI(url);
  }

  try {
    const res = await request.editor.linkInfo.raw({
      url,
      headers: ['X-Frame-Options'],
    });
    if (res.code === 200) {
      res.data.link = decodeName(res.data.link);
      if (res.data.cover && res.data.icon) {
        linkInfoCache.put(url, res.data, Date.now() + SEVEN_DAY);
      } else {
        // 如果拿不到封面上啥的，三天后调接口的话继续尝试一下
        linkInfoCache.put(url, res.data, Date.now() + THREE_DAY);
      }
      return res.data;
    }
  } catch {
    //
  }
};

export const normalizeHref = (url: string) => {
  if (isUrl(url)) {
    return url;
  }
  if (/^\w+:?\/\//.test(url)) {
    return url;
  }
  try {
    const urlObj = new URL(`${isFlowUsApp.check ? 'https://' : location.protocol}${url}`);
    let newUrl = urlObj.toString();
    if (!url.endsWith('/')) {
      newUrl = newUrl.replace(/\/$/g, url.includes('/') ? '/' : '');
    }
    return newUrl;
  } catch {
    return url;
  }
};

/** 固定的书签网页域名 */
export const isBookmarkLink = (url: string) => {
  if (/mp\.weixin\.qq\.com/.test(url)) {
    return true;
  }
  return false;
};
