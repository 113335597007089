import { useCallback } from 'react';
import { request } from 'src/common/request';
import { useOpenFeatGuide } from 'src/components/new-user-task/feat-guide';
import {
  TaskProgressType,
  useOpenOneTaskModal,
  useOpenTaskProgress,
} from 'src/components/new-user-task/task-progress';
import { useOpenIntegralAnimation } from 'src/components/new-user-task/use-open-score-animation';
import { getActivityState } from 'src/services/activities/hook';
import { FeatGuideTask, TwoStepTask } from './activity-ids';
import { newUserTaskStorage } from './task-storage';
import type { ActivitiesListType } from './use-activity';
import { getActivity } from './use-activity';
import { useFetchActivities } from './use-fetch-activities';

/** 新手基础任务，调用默认视为完成接口 */
export const useUpdateTask = () => {
  const fetchActivities = useFetchActivities();
  const openFeatGuide = useOpenFeatGuide();
  const openTaskProgress = useOpenTaskProgress();
  const openIntegralAnimation = useOpenIntegralAnimation();
  const openOneTaskModal = useOpenOneTaskModal();

  return useCallback(
    async (
      uuid: string,
      listType: ActivitiesListType,
      params?: {
        isFirstTask?: boolean;
        completed?: boolean;
        createdBy?: string;
        step1?: boolean;
        step2?: boolean;
      }
    ) => {
      const activity = getActivity(uuid);
      // 没有活动|下线|已完成
      if (!activity || ['abandoned', 'completed'].includes(activity.status || '')) return;

      if (TwoStepTask.has(uuid)) {
        const result = newUserTaskStorage.get(uuid) ?? {};
        if (params?.step1) result.step1 = true;
        if (params?.step2) result.step2 = true;
        newUserTaskStorage.put(uuid, result);

        if (!result?.step1 || !result?.step2) return;
      }

      const res = await request.infra.completedActivity.raw(uuid, {
        completed: params?.completed ?? true,
        viewed: true,
        createdBy: params?.createdBy,
      });

      if (res.code === 200) {
        await openIntegralAnimation(uuid);

        if (FeatGuideTask.has(uuid)) {
          await openFeatGuide(uuid, params?.isFirstTask);
        }

        if (params?.isFirstTask && !FeatGuideTask.has(uuid)) {
          await openOneTaskModal(uuid);
        }

        await fetchActivities({ openCompleteModal: true, completeListType: listType });
        const allActivity = getActivityState();
        if (
          allActivity.basicList.every((item) => item.status === 'completed') &&
          allActivity.basicList.find((item) => item.uuid === uuid) &&
          allActivity.advancedList.find((item) => item.status !== 'completed')
        ) {
          openTaskProgress(TaskProgressType.FINISH_BASIC_TASK);
        }
      } else {
        void fetchActivities();
      }
    },
    [fetchActivities, openFeatGuide, openIntegralAnimation, openOneTaskModal, openTaskProgress]
  );
};

// test api 省的挨个完成任务
// [
//   // '8f8ddded-5d80-0c15-b5b5-9952c3b922a8',
//   '596f293a-5f5d-0536-9c07-aa7b1e5b970d',
//   '2cd872ab-3a29-6d2d-7b42-ed2f1d81d82a',
//   '4e8e03f5-d2a6-4c12-b05b-321c1a57e72d',
//   '3bad6862-9134-47e4-8b3a-9dd2dc093274',
//   'deb678d3-6742-cdfc-1e61-adc1f153600e',
//   'd2661165-b02a-f819-bff6-c6619db0e33a',
//   '8c148290-db9a-e791-0430-3cd3e776fa8a',
//   '1d767026-0143-98fb-8e54-ed607f91d7a1',
//   '18d24e67-b984-2b59-4e1b-9ee5d24839e3',
//   'a648640a-a3d9-4335-86d6-eaa03d79b859',
//   // 'd453314a-36f6-1603-8116-28ca9efdd08d',
//   '9f888320-ae4c-f2b0-a0dd-028153e0e4b3',
//   '746d9e52-3fa2-7676-91a8-f7e457dd9bfb',
//   '946607c0-beac-041f-fd76-45c5ef2591eb',
//   '99e2b29e-0429-fe53-0b38-776dd4b6c867',
//   'bd44fb8f-646d-4187-40f1-c8daa9ccb1ad',
//   '55db4312-3dfd-79fd-0e7c-e7ef4c977648',
//   'b640bd28-e14c-4f50-7e52-b2d3b2c58653',
//   '193a8c4b-cec3-74de-16b0-2e35556be323',
// ].forEach(async (i) => {
//   await request.infra.completedActivity.raw(i, {
//     completed: params?.completed ?? true,
//     viewed: true,
//     createdBy: params?.createdBy,
//   });
// });
