import { useFinalValue } from '@flowus/common/hooks/react-utils';
import type { FC } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { AiBitableEntry, AiMindMapEntry } from './const';
import { AI_WRITE_DATABASE_ITEMS } from './menu-items';

interface Props {
  onItemClick: (item: any) => void;
}

export const BitableMenu: FC<Props> = (props) => {
  const bitableAlreadyClick = useFinalValue(() => {
    return localStorage.getItem(AiBitableEntry);
  });

  return (
    <ListView
      className={listViewNormalClassName}
      items={AI_WRITE_DATABASE_ITEMS.map((v) => {
        return {
          type: ListItemType.BLOCK_ITEM,
          data: {
            ...v,
            textClassName: !bitableAlreadyClick ? 'text-red' : undefined,
            rightText: !bitableAlreadyClick ? 'NEW' : undefined,
          },
        };
      })}
      keyDownPriority={PRIORITY_DIALOG + 2}
      onItemClick={(item) => {
        localStorage.setItem(AiMindMapEntry, JSON.stringify(true));
        props.onItemClick(item);
      }}
    />
  );
};
