import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { Footer } from 'src/components/footer';
import { LinkPageTabs } from 'src/components/link-page-tabs';
import { AiBlankArea } from 'src/editor/editor/uikit/blank-area';
import { useLocationBlock } from 'src/hooks/block/use-location-block';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { usePageLayoutStyle } from 'src/hooks/page/use-page-layout';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { DrivePage } from './drive-content/drive-page';
import { PageDriveHeader } from './drive-content/header';

interface Props {
  uuid: string;
}

export const PageDrive: FC<Props> = ({ uuid, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState(ref.current);
  const readonly = useReadonly(uuid);
  const { fontStyle } = usePageLayoutStyle(uuid);
  useLocationBlock();

  useEffect(() => {
    setContainer(ref.current);
  }, []);

  return (
    <div
      className={cx('flex flex-col flex-1 overflow-x-hidden next-space-page')}
      ref={ref}
      style={fontStyle}
    >
      <div id={ID_DRIVE} className="flex relative flex-col flex-1">
        <PageDriveHeader uuid={uuid} />
        <DrivePage uuid={uuid} container={container} />
        <AiBlankArea />
        <div className="px-24 sm:px-10 mt-8">
          <LinkPageTabs uuid={uuid} />
        </div>
        {children}
        {readonly && <Footer className="mt-auto" />}
      </div>
    </div>
  );
};
