export enum LocalStorageKey {
  /** 空间容量警告提示条 */
  SPACE_CAPACITY = 'SPACE_CAPACITY',
  /** 左侧目录的宽度 */
  MAIN_LEFT_WIDTH = 'MAIN_LEFT_WIDTH',
  /** 右侧页面的宽度 */
  RIGHT_PAGE_WIDTH = 'flowus.right.width',
  /** 只展示一次的HEADER TIPS */
  HEADER_TIPS = 'HEADER_TIPS',
  /** 限速提示 */
  SPEED_LIMIT = 'SPEED_LIMIT',
  /** 评论提示 */
  COMMENT_TIPS = 'COMMENT_TIPS',
  /** 文件预览 */
  FILE_VIEW_LIMIT = 'FILE_VIEW_LIMIT',
  /** 引用记录 */
  LINK_RECORD = 'LINK_RECORD',
  /** 模板分享提示 */
  SHARE_TEMPLATE_TOP = 'SHARE_TEMPLATE_TOP',
  /** 订阅提示 */
  SUBSCRIBE_TIP = 'SUBSCRIBE_TIP',
  /** 移动了订阅中心提示 */
  MOVE_SUBSCRIBE_TIPS = 'MOVE_SUBSCRIBE_TIPS',
  /** 隐藏订阅推荐 */
  SUBSCRIBE_HIDDEN = 'SUBSCRIBE_HIDDEN',
}
