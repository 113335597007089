export const ConditionalRender = ({
  Component1,
  Component2,
}: {
  Component1: React.ComponentType;
  Component2: React.ComponentType;
}) => {
  const element1 = <Component1 />;

  // 判断 Component1 是否返回 null
  const shouldRenderComponent2 = element1.type && element1.type() === null;

  return shouldRenderComponent2 ? <Component2 /> : element1;
};
