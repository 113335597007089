import { contentToString, sliceContent } from '@next-space/fe-inlined';
import type { VirtualElement } from '@popperjs/core';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { CreateBlockMenuList } from 'src/editor/component/menu-list';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { isBlockNoText } from 'src/editor/utils/editor';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { getCaretRange, getEditable } from 'src/hooks/editor/helper';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { updateBlock } from 'src/redux/managers/block/update';
import { useCloseCreateBlockMenuList, useSetCreateBlockMenuListId } from 'src/redux/managers/ui';
import { getState } from 'src/redux/store';
import { getEditorKeyFromElement } from 'src/utils/editor-utils';
import { focusEditableAtByEditorKey } from 'src/utils/focus-by-editor-key';
import { isSlashSymbol } from 'src/utils/slash-symbol';
import { $appUiStateCache, setAppUiState } from '../../services/app';

interface OptionProps {
  isOpenMenuList?: boolean;
  syncId?: string;
}
export const useOpenBlockMenuList = () => {
  const openCreateBlockMenuList = useOpenCreateBlockMenuList();
  return useCallback(
    (blockId: string, option?: OptionProps) => {
      const { isOpenMenuList, syncId } = option || {};
      const blockElement = getEditable(blockId, syncId);
      if (blockElement) {
        const rect = blockElement.getBoundingClientRect();
        const editorKey = getEditorKeyFromElement(blockElement);
        if (editorKey) {
          focusEditableAtByEditorKey(editorKey, 0);
        }
        if (isOpenMenuList) {
          openCreateBlockMenuList({
            popcorn: { getBoundingClientRect: () => rect },
            blockId,
            syncId,
          });
        }
      }
    },
    [openCreateBlockMenuList]
  );
};

interface OpenCreateBlockMenuListProps {
  popcorn: VirtualElement;
  blockId: string;
  offset?: number[];
  slash?: boolean;
  syncId?: string;
}
export const useOpenCreateBlockMenuList = () => {
  const openModal = useOpenModal();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const setCreateBlockMenuListId = useSetCreateBlockMenuListId();
  const focusEditableAt = useFocusEditableByBlockId();
  const getEditorModel = useGetOrInitEditorModel();

  return useCallback(
    (params: OpenCreateBlockMenuListProps) => {
      const { popcorn, blockId, offset = [-4, 8], slash, syncId } = params;
      const block = getState().blocks[blockId];
      if (!block || $appUiStateCache.$createBlockMenuListId) return;
      const caret = getCaretRange();
      if (!caret) return;

      const editorModel = getEditorModel(blockId);
      if (!editorModel) return;

      const { selection } = editorModel;
      if (!selection) return;
      if (!selection.isCollapsed) return;

      // 非 slash 需要留一个空白
      const cursorRight = editorModel.createCursor(selection.focusOffset, 'right');
      const cursorLeft = editorModel.createCursor(selection.focusOffset, 'left');

      const subscriptionSelection = editorModel.onSelectionChange.subscribe(() => {
        if (editorModel.selection) {
          const { focusOffset } = editorModel.selection;
          if (focusOffset < cursorLeft.offset || cursorRight.offset < focusOffset) {
            closeCreateBlockMenuList();
            return;
          }
          setAppUiState({
            $slashSelection: {
              left: cursorLeft.offset,
              right: cursorRight.offset,
            },
          });
        }
      });

      const subscription = editorModel.onContentChange.subscribe(() => {
        const { content } = editorModel;
        try {
          const piece = sliceContent(content, cursorLeft.offset, cursorRight.offset);
          const text = contentToString(piece);

          if (slash) {
            if (!text || !isSlashSymbol(text[0] ?? '')) {
              closeCreateBlockMenuList();
            } else {
              setAppUiState({ $menuListKeywords: text });
            }
          } else {
            setAppUiState({ $menuListKeywords: text });
          }
        } catch {
          closeCreateBlockMenuList();
        }
      });

      openModal.dropdown({
        popcorn,
        placement: 'bottom-start',
        mask: false,
        modalId: blockId,
        offset,
        closeBeforeCallBack: () => {
          cursorLeft.release();
          cursorRight.release();
          subscription.unsubscribe();
          subscriptionSelection.unsubscribe();
          closeCreateBlockMenuList();
        },
        content: ({ onCloseModal }) => (
          <CreateBlockMenuList
            focusEditableAt={focusEditableAt}
            blockNoText={isBlockNoText(block.data)}
            slash={slash}
            syncId={syncId}
            getEditorModel={getEditorModel}
            popcorn={popcorn}
            blockId={blockId}
            cursorLeft={cursorLeft}
            cursorRight={cursorRight}
            onCloseModal={onCloseModal}
            selectItem={() => {
              editorModel.performChange((ctx) => {
                ctx.shadow().select(cursorLeft.offset, cursorRight.offset).delete().release();
              });
              updateBlock(blockId, {
                data: { segments: convertContentToSegments(editorModel.content) },
              });
            }}
          />
        ),
      });
      setCreateBlockMenuListId(blockId);
    },
    [closeCreateBlockMenuList, focusEditableAt, getEditorModel, openModal, setCreateBlockMenuListId]
  );
};
