import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useAddCollectionView } from 'src/hooks/block/use-add-collection-view';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { cache, getState } from 'src/redux/store';
import { ItemType, ViewSettingPanel } from '../bitable-manager/view-setting-panel';
import { useBitable } from '../context';
import { useOpenFormEndPageSetting } from './form-end-page/editor-end-page';
import { useOpenPreViewForm } from './use-open-preview-form';
import { useOpenShareFormSetting } from './use-open-share-form-setting';

export const FormSlideBar: FC = () => {
  const { viewId, collectionId, viewParentId, changeView } = useBitable();
  const openModal = useOpenModal();
  const view = useCollectionView(viewId);
  const openShareFormSetting = useOpenShareFormSetting();
  const addCollectionView = useAddCollectionView();
  const openPreViewForm = useOpenPreViewForm();
  const openFormEndPageSetting = useOpenFormEndPageSetting();

  /** 下面这段代码是从跳题逻辑那拷贝过来的 */
  const viewTitleRef = useRef(view?.title ?? '');
  useEffect(() => {
    viewTitleRef.current = view?.title ?? '';
  }, [view?.title]);

  const viewIdRef = useRef(viewId);
  viewIdRef.current = viewId;
  const showViewSetting = (e: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: e.currentTarget,
      placement: 'bottom',
      modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
      closeBeforeCallBack() {
        const newTitle = viewTitleRef.current;
        const view = getState().collectionViews?.[viewIdRef.current];
        if (newTitle !== view?.title) {
          updateCollectionView(viewIdRef.current, { title: newTitle });
        }
      },
      content: ({ onCloseModal }) => (
        <ViewSettingPanel
          viewTitleRef={viewTitleRef}
          onCloseModal={onCloseModal}
          defaultItemType={ItemType.FORM_LOGIC}
        />
      ),
    });
  };

  return (
    <div className="py-2 next-modal w-28 ml-3 h-min px-1 space-y-1">
      <Button
        icon="IcSkipQuestion"
        text="逻辑"
        onClick={(e) => {
          showViewSetting(e);
        }}
      />
      <Button
        icon="IcEndPage"
        text="结束页"
        onClick={() => {
          openFormEndPageSetting({ viewId, collectionId });
        }}
      />
      <Button
        icon="IcMenuShow"
        text="预览"
        onClick={() => {
          openPreViewForm({ viewId, collectionId });
        }}
      />
      <Button
        icon="IcFormShare"
        text="分享"
        onClick={(event) => {
          openShareFormSetting({
            popcorn: event.currentTarget,
            viewId,
            collectionId,
            placement: 'bottom',
          });
        }}
      />
      <Button
        icon="IcTable"
        text="数据"
        onClick={() => {
          // copy from 查看收集表数据option code
          const firstTable = cache.blocks[collectionId]?.views?.find(
            (it) => cache.collectionViews[it]?.type === CollectionViewType.TABLE
          );
          if (firstTable) {
            changeView(firstTable);
          } else {
            const newTableId = addCollectionView(viewParentId, '表格', CollectionViewType.TABLE);
            if (newTableId) {
              changeView(newTableId);
            } else {
              message.error('切换失败');
            }
          }
        }}
      />
    </div>
  );
};

interface ButtonProps {
  onClick: (event: React.MouseEvent) => void;
  text: string;
  icon: IconName;
}
const Button: FC<ButtonProps> = (props) => {
  return (
    <div
      className="flex flex-col items-center py-2 px-1 animate-hover space-y-1 text-t2"
      onClick={props.onClick}
    >
      <Icon name={props.icon} size="middle" />
      <span>{props.text}</span>
    </div>
  );
};
