import type { SpaceSettingDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Option } from '../option';
import { SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';
import { useCurrentSpace, useUpdateSpace } from 'src/hooks/space';
import { transaction } from 'src/hooks/use-transaction';

interface CommentSettingProps {
  commentAlignment: SpaceSettingDTO['commentAlignment'];
  onChangeCommentAlignment: (alignment: SpaceSettingDTO['commentAlignment']) => void;
}

export const CommentSetting: FC = () => {
  const {
    spaceSettingState: { commentAlignment },
  } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  const updateSpace = useUpdateSpace();
  const openModal = useOpenModal();

  const handleClick = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => (
        <CommentSettingList
          commentAlignment={commentAlignment}
          onChangeCommentAlignment={(v) => {
            onCloseModal();
            transaction(() => {
              updateSpace(currentSpace.uuid, {
                setting: {
                  ...currentSpace.setting,
                  commentAlignment: v,
                },
              });
            });
          }}
        />
      ),
    });
  };

  return (
    <Option
      title="页面评论位置"
      description="创建新页面时，评论默认位置"
      rightPart={
        <button className="text-t2 text-black flex items-center" onClick={handleClick}>
          {commentAlignment === 'top' ? '页面顶部' : '页面底部'}
          <Icon name="IcArrowDown01" size="xxxsmall" className="ml-1" />
        </button>
      }
    />
  );
};
const CommentSettingList: FC<CommentSettingProps> = (props) => {
  const items = [
    { type: 'top', title: '页面顶部' },
    { type: 'bottom', title: '页面底部' },
  ].map((item) => ({
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: { ...item, selected: item.type === props.commentAlignment },
  }));

  return (
    <ListView
      className="rounded-r next-modal py-1.5 w-[160px]"
      items={items}
      onItemClick={(item) => {
        props.onChangeCommentAlignment(item.data.type);
      }}
    />
  );
};
