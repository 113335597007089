/* eslint-disable no-console */
import { ifvisible } from 'ifvisible.js';
import * as _ from 'lodash-es';
import { noop, throttle } from 'lodash-es';
import { createRef } from 'react';
import { SENDER_ID } from 'src/common/request';
import { store } from 'src/redux/store';
import { sleep } from 'src/utils/async-utils';
import { isAbortError } from 'src/utils/errors';
import { getToken } from 'src/utils/get-next-auth';
import { tryParseJSON } from 'src/utils/json-utils';
import * as MapUtils from 'src/utils/map-utils';
import { useGetRefValue } from '@flowus/common/hooks/react-utils';
import * as UUID from 'uuid';
import { $networkStatus } from './network-status';
import { $currentUserCache } from './user/current-user';

const WS_URL = import.meta.env.VITE_WS_URL || `wss://${location.host}/ws/webSocket/`;
const IDLE_DURATION_IN_SECONDS = 300;

const DEFAULT_HEARTBEAT_INTERVAL_IN_MS = 25000;
const DEFAULT_HEARTBEAT_TIMEOUT_IN_MS = 20000;
const MAX_RETRY_INTERVAL_IN_MS = 300000;

const WS_CLOSE_CODE_HEARTBEAT_TIMEOUT = 4001;
const WS_CLOSE_CODE_USER_INACTIVITY = 4002;

class WebsocketClosedError extends Error {
  code: number;

  constructor(code: number) {
    super(`WebSocket closed with code: ${code}`);
    this.code = code;
  }
}

interface SyncClientOptions {
  heartbeatIntervalInMs: number;
  heartbeatTimeoutInMs: number;
  maxRetryIntervalInMs: number;
}
type RequestType = '/subscribe' | '/unsubscribe' | '/send';

interface SyncRequestPacket<T extends RequestNormalData | RequestPresenceData = any> {
  requestId: string;
  type: RequestType;
  data: T;
}

interface RequestNormalData {
  channel: string;
}
interface RequestPresenceData {
  channel: string;
  userId: string;
  body: {
    present: boolean;
    activityTime: number;
    blockId?: string; // present 为 false 时，不需要 blockId
  };
  senderId: string;
}
type RequestData = RequestPresenceData | RequestNormalData;

interface SyncResponsePacket {
  requestId: string;
  type: 'response';
  status: number;
  data: {
    channel: string;
  };
}

export interface SyncBlockNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'block';
  data: {
    action: 'create' | 'update' | 'version';
    type: number;
    id: string;
    version: number;
    pageId: string;
    spaceId: string;
  };
  time: number;
  senderId: string;
}
export interface SyncCollectionViewNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'collectionView';
  data: {
    action: 'create' | 'update' | 'version';
    type: number;
    id: string;
    version: number;
    pageId: string;
    spaceId: string;
  };
  time: number;
  senderId: string;
}

export interface SyncSpaceNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'space';
  data: {
    action: 'update' | 'version';
    id: string;
    version: number;
  };
  time: number;
  senderId: string;
}
export interface SyncSpaceViewNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'spaceView';
  data: {
    action: 'update';
    id: string;
    version: number;
  };
  time: number;
  senderId: string;
}
export interface SyncPresencesNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'presence'; // 实际上没有这个表，这里只是为了分发消息才加的，否则代码改动较大,需要作者重构：不依赖table字段进行消息分发.
  data: {
    userId: string;
    present: boolean;
    activityTime: number;
    blockId: string;
    id: never;
    action: never;
  };
  time: number;
  senderId: string;
}
export interface SyncNotificationNotificationPacket {
  type: 'notification';
  channel: string;
  table: 'notification';
  data: {
    spaceId: string;
    version: number;
    id: never;
    action: never;
  };
  time: number;
  senderId: string;
}

export type SyncNotificationPacket =
  | SyncSpaceNotificationPacket
  | SyncBlockNotificationPacket
  | SyncCollectionViewNotificationPacket
  | SyncSpaceViewNotificationPacket
  | SyncPresencesNotificationPacket
  | SyncNotificationNotificationPacket;

// packet === undefined 表示一次recover通知（即从上次ws断开之后的恢复）
type SyncObserver = (packet?: SyncNotificationPacket) => void;
export class SyncService {
  private userIdAndTokenReady!: Promise<void>;
  private setUserIdAndTokenReady!: (userId: string, token: string) => void;
  private userId!: string;
  private token!: string;
  private disposed = false;
  private disposer?: () => void;
  private options!: SyncClientOptions;
  private pageObservers = new Map<string, SyncObserver[]>();
  private spaceViewObservers = new Map<string, SyncObserver[]>();
  private collectionViewObservers = new Map<string, SyncObserver[]>();
  private spacePageObservers = new Map<string, Map<string | null, SyncObserver[]>>();
  private presencePageObservers = new Map<string, SyncObserver[]>();
  private sharedPagesObservers = new Map<string, SyncObserver[]>();
  private notificationsObservers: SyncObserver[] = [];
  private scheduleCommitObservers: () => void = noop;
  private scheduleCommitRequest: () => void = noop;
  private retryCount = 0;
  private requestData: RequestData[] = [];

  constructor(options: Partial<SyncClientOptions> = {}) {
    this.userIdAndTokenReady = new Promise((resolve) => {
      this.setUserIdAndTokenReady = (userId, token) => {
        this.userId = userId;
        this.token = token;
        resolve();
      };
    });
    this.options = {
      ...options,
      heartbeatIntervalInMs: DEFAULT_HEARTBEAT_INTERVAL_IN_MS,
      heartbeatTimeoutInMs: DEFAULT_HEARTBEAT_TIMEOUT_IN_MS,
      maxRetryIntervalInMs: MAX_RETRY_INTERVAL_IN_MS,
    };
    ifvisible.wakeup();
    ifvisible.setIdleDuration(IDLE_DURATION_IN_SECONDS);
    void this.lifecycle();
  }

  setUserIdAndToken(userId: string, token: string) {
    this.setUserIdAndTokenReady(userId, token);
  }

  dispose() {
    this.disposed = true;
    this.disposer?.();
    this.disposer = undefined;
  }

  private async lifecycle() {
    await this.userIdAndTokenReady;

    const waitForReadyToStart = async () => {
      if (!ifvisible.now() || !$networkStatus.online) {
        await new Promise<void>((resolve) => {
          const check = () => {
            if (ifvisible.now() && $networkStatus.online) {
              subscription.unsubscribe();
              ifvisible.off('wakeup', check);
              resolve();
            }
          };
          const subscription = $networkStatus.onStatusChange.subscribe(check);
          ifvisible.on('wakeup', check);
        });
      }
    };

    let recover = false;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
    while (!this.disposed) {
      try {
        await this.startNewSession(recover);
      } catch (error) {
        if (error instanceof WebsocketClosedError && error.code === WS_CLOSE_CODE_USER_INACTIVITY) {
          // Silent the WS_CLOSE_CODE_USER_INACTIVITY error.
        } else {
          console.error(error);
        }
      }
      recover = true;

      if (!ifvisible.now() || !$networkStatus.online) {
        await waitForReadyToStart();
      } else {
        const retryInterval = Math.min(
          1000 * Math.pow(2, this.retryCount),
          this.options.maxRetryIntervalInMs
        );
        this.retryCount += 1;
        await sleep(retryInterval);
        await waitForReadyToStart();
      }
    }
  }

  private async ping(ws: WebSocket) {
    ws.send('PING');
    await new Promise<void>((resolve, reject) => {
      const cleanup = () => {
        clearTimeout(timerId);
        ws.removeEventListener('message', onMessageHandler);
        ws.removeEventListener('close', cleanup);
      };
      const onMessageHandler = (event: WebSocketEventMap['message']) => {
        if (event.data === 'PONG') {
          cleanup();
          resolve();
        }
      };
      ws.addEventListener('message', onMessageHandler);
      ws.addEventListener('close', cleanup);
      const timerId = setTimeout(() => {
        cleanup();
        reject(new Error('Timeout'));
      }, this.options.heartbeatTimeoutInMs);
    });
  }

  private async request<T extends RequestType>(
    ws: WebSocket,
    type: T,
    data: RequestData
  ): Promise<{ channel: string }> {
    const requestId = UUID.v4();
    const wirePacket = JSON.stringify({
      requestId,
      type,
      data,
    } as SyncRequestPacket);
    ws.send(wirePacket);
    return new Promise((resolve, reject) => {
      const cleanup = () => {
        ws.removeEventListener('message', onMessageHandler);
        ws.removeEventListener('close', cleanup);
      };
      const onMessageHandler = (event: WebSocketEventMap['message']) => {
        // NOTE: If there are many pending requests. Here may `tryParseJSON` same `event.data` unnecessary more times.
        // Since `subscribe` and `unsubscribe` requests only need be called one or two times.
        // Thus, we decided to keep simplicy and don't do micro optimize here.
        const packet = tryParseJSON(event.data);
        if (this.isResponsePacket(packet) && packet.requestId === requestId) {
          cleanup();
          if (packet.status === 200) {
            resolve(packet.data);
          } else {
            reject(new Error(`Response with status: ${packet.status}`));
          }
        }
      };
      ws.addEventListener('message', onMessageHandler);
      ws.addEventListener('close', cleanup);
    });
  }

  private isResponsePacket(data: any): data is SyncResponsePacket {
    return typeof data === 'object' && data !== null && data.type === 'response';
  }

  private isNotificationPacket(data: any): data is SyncNotificationPacket {
    return typeof data === 'object' && data !== null && data.type === 'notification';
  }

  private setupObservers(ws: WebSocket) {
    const subscribedSpaceIds = new Set<string>();
    const subscribedPageIds = new Set<string>();
    const subscribedSpaceViewIds = new Set<string>();
    const subscribedPresencesIds = new Set<string>();
    const subscribedSharedPagesSpaceIds = new Set<string>();
    let subscribedNotifications = false;

    // NOTE:
    // collectionViews 不需要订阅，父节点页面订阅了就会收到 collectionView 的通知

    this.scheduleCommitObservers = throttle(async () => {
      if (ws.readyState !== WebSocket.OPEN) {
        return;
      }

      const promises: Promise<unknown>[] = [];

      for (const spaceId of this.spacePageObservers.keys()) {
        if (!subscribedSpaceIds.has(spaceId)) {
          subscribedSpaceIds.add(spaceId);
          promises.push(this.request(ws, '/subscribe', { channel: `/spaces/${spaceId}` }));
        }
      }
      for (const spaceId of subscribedSpaceIds) {
        if (!this.spacePageObservers.has(spaceId)) {
          promises.push(
            this.request(ws, '/unsubscribe', { channel: `/spaces/${spaceId}` }).then(() => {
              subscribedSpaceIds.delete(spaceId);
              this.scheduleCommitObservers();
            })
          );
        }
      }

      for (const pageId of this.pageObservers.keys()) {
        if (!subscribedPageIds.has(pageId)) {
          subscribedPageIds.add(pageId);
          promises.push(this.request(ws, '/subscribe', { channel: `/pages/${pageId}` }));
        }
      }
      for (const pageId of subscribedPageIds) {
        if (!this.pageObservers.has(pageId)) {
          promises.push(
            this.request(ws, '/unsubscribe', { channel: `/pages/${pageId}` }).then(() => {
              subscribedPageIds.delete(pageId);
              this.scheduleCommitObservers();
            })
          );
        }
      }

      // 快速访问的同步
      for (const spaceViewId of this.spaceViewObservers.keys()) {
        if (!subscribedSpaceViewIds.has(spaceViewId)) {
          subscribedSpaceViewIds.add(spaceViewId);
          promises.push(this.request(ws, '/subscribe', { channel: `/spaceViews/${spaceViewId}` }));
        }
      }
      for (const spaceViewId of subscribedSpaceViewIds) {
        if (!this.spaceViewObservers.has(spaceViewId)) {
          promises.push(
            this.request(ws, '/unsubscribe', { channel: `/spaceViews/${spaceViewId}` }).then(() => {
              subscribedSpaceViewIds.delete(spaceViewId);
              this.scheduleCommitObservers();
            })
          );
        }
      }

      // 团队成员头位置像同步
      for (const pageId of this.presencePageObservers.keys()) {
        if (!subscribedPresencesIds.has(pageId)) {
          subscribedPresencesIds.add(pageId);
          promises.push(this.request(ws, '/subscribe', { channel: `/presences/${pageId}` }));
        }
      }
      for (const pageId of subscribedPresencesIds) {
        if (!this.presencePageObservers.has(pageId)) {
          promises.push(
            this.request(ws, '/unsubscribe', {
              channel: `/presences/${pageId}`,
            }).then(() => {
              // unSubscribe由于删除记录是异步的，因此会调用多次
              subscribedPresencesIds.delete(pageId);
              this.scheduleCommitObservers();
            })
          );
        }
      }

      // “共享页面”分区的通知
      for (const spaceId of this.sharedPagesObservers.keys()) {
        if (!subscribedSharedPagesSpaceIds.has(spaceId)) {
          subscribedSharedPagesSpaceIds.add(spaceId);
          promises.push(
            this.request(ws, '/subscribe', {
              channel: `/spaces/${spaceId}/userSharedPages/${this.userId}`,
            })
          );
        }
      }
      for (const spaceId of subscribedSharedPagesSpaceIds) {
        if (!this.sharedPagesObservers.has(spaceId)) {
          promises.push(
            this.request(ws, '/unsubscribe', {
              channel: `/spaces/${spaceId}/userSharedPages/${this.userId}`,
            }).then(() => {
              subscribedSharedPagesSpaceIds.delete(spaceId);
              this.scheduleCommitObservers();
            })
          );
        }
      }

      // 站内消息
      if (this.notificationsObservers.length > 0) {
        if (!subscribedNotifications) {
          subscribedNotifications = true;
          promises.push(
            this.request(ws, '/subscribe', {
              channel: `/users/${this.userId}/notification`,
            })
          );
        }
      }
      if (this.notificationsObservers.length === 0) {
        if (subscribedNotifications) {
          promises.push(
            this.request(ws, '/unsubscribe', {
              channel: `/users/${this.userId}/notification`,
            }).then(() => {
              subscribedNotifications = false;
              this.scheduleCommitObservers();
            })
          );
        }
      }

      await Promise.all(promises);
    }, 500);

    const onMessageHandler = (event: WebSocketEventMap['message']) => {
      const packet = tryParseJSON(event.data);
      if (this.isNotificationPacket(packet)) {
        this.dispatchNotification(packet);
      }
    };

    ws.addEventListener('message', onMessageHandler);
    this.scheduleCommitObservers();

    return () => {
      this.scheduleCommitObservers = noop;
      ws.removeEventListener('message', onMessageHandler);
    };
  }

  private setupHeartbeat(ws: WebSocket) {
    let heartbeatTimer: any;

    heartbeatTimer = setInterval(async () => {
      try {
        await this.ping(ws);
      } catch (error) {
        if (!isAbortError(error)) {
          ws.close(WS_CLOSE_CODE_HEARTBEAT_TIMEOUT);
        }
      }
    }, this.options.heartbeatIntervalInMs);

    return () => {
      clearInterval(heartbeatTimer);
      heartbeatTimer = undefined;
    };
  }

  private setupRequestSender(ws: WebSocket) {
    // 为什么ws不是存在当前对象上，现在想拿到ws，只能先初始化，然后放到闭包上使用
    this.scheduleCommitRequest = throttle(async () => {
      if (ws.readyState !== WebSocket.OPEN) {
        return;
      }

      const promises: Promise<unknown>[] = [];
      const requestData = this.requestData.slice(0);
      this.requestData = [];
      for (const data of requestData) {
        promises.push(this.request(ws, '/send', data));
      }
      await Promise.all(promises);
    }, 0);
    this.scheduleCommitRequest();
    return () => {
      this.scheduleCommitRequest = noop;
    };
  }

  private setupRecover(ws: WebSocket) {
    let pastOffline = false;
    const handleOffline = () => {
      pastOffline = true;
    };
    const handleOnline = () => {
      if (pastOffline) {
        this.throttledDispatchRecover();
      }
    };
    const handleIdle = () => {
      ws.close(WS_CLOSE_CODE_USER_INACTIVITY);
    };
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);
    ifvisible.on('idle', handleIdle);
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
      ifvisible.off('idle', handleIdle);
    };
  }

  private async startNewSession(recover = false) {
    const ws = new WebSocket(WS_URL + this.token);
    this.disposer = () => {
      ws.close();
    };

    await new Promise<void>((resolve) => {
      const onOpenHandler = () => {
        ws.removeEventListener('open', onOpenHandler);
        resolve();
      };
      ws.addEventListener('open', onOpenHandler);
    });

    await this.ping(ws);
    this.retryCount = 0;

    if (recover) {
      this.throttledDispatchRecover();
    }

    await new Promise((_resolve, reject) => {
      const disposers: (() => void)[] = [];

      const onCloseHandler = (event: WebSocketEventMap['close']) => {
        ws.removeEventListener('close', onCloseHandler);

        let disposer = disposers.pop();
        while (disposer) {
          disposer();
          disposer = disposers.pop();
        }

        if (event.code === WS_CLOSE_CODE_HEARTBEAT_TIMEOUT) {
          reject(new Error('Heartbeat timeout'));
        } else {
          reject(new WebsocketClosedError(event.code));
        }
      };

      ws.addEventListener('close', onCloseHandler);

      disposers.push(this.setupObservers(ws));
      disposers.push(this.setupHeartbeat(ws));
      disposers.push(this.setupRecover(ws));
      disposers.push(this.setupRequestSender(ws));
    });
  }

  private throttledDispatchRecover = _.throttle(this.dispatchRecover.bind(this), 1500, {
    leading: false,
    trailing: true,
  });

  private dispatchRecover() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const iterObserversMap = function* (map: Map<string | null, SyncObserver[]>) {
      for (const observers of map.values()) {
        yield* observers;
      }
    };
    const iterAllObservers = function* () {
      yield* iterObserversMap(self.pageObservers);
      yield* iterObserversMap(self.spaceViewObservers);
      yield* iterObserversMap(self.collectionViewObservers);
      for (const [, observersMap] of self.spacePageObservers) {
        yield* iterObserversMap(observersMap);
      }
      yield* iterObserversMap(self.presencePageObservers);
      yield* iterObserversMap(self.sharedPagesObservers);
      yield* self.notificationsObservers;
    };
    for (const observer of iterAllObservers()) {
      observer();
    }
  }

  private dispatchNotification(packet: SyncNotificationPacket) {
    if (import.meta.env.VITE_DISABLE_SYNC) {
      return;
    }

    if (!packet.data as unknown) {
      return;
    }

    if (packet.senderId === SENDER_ID) {
      return;
    }

    const dispatch = (observers: SyncObserver[]) => {
      for (const observer of observers) {
        observer(packet);
      }
    };

    if (packet.data.action === 'version') {
      return;
    }

    if (packet.table === 'space') {
      const spaceObservers =
        MapUtils.getWithSetDefaultIfNeeded(
          this.spacePageObservers,
          packet.data.id,
          () => new Map()
        ).get(null) ?? [];
      dispatch(spaceObservers);
    } else if (packet.table === 'collectionView') {
      const collectionViewObservers = this.collectionViewObservers.get(packet.data.id) ?? [];
      dispatch(collectionViewObservers);
    } else if (packet.table === 'block') {
      const pageObservers = this.pageObservers.get(packet.data.pageId) ?? [];
      dispatch(pageObservers);
      const childPageObservers = [
        ...(MapUtils.getWithSetDefaultIfNeeded(
          this.spacePageObservers,
          packet.data.spaceId,
          () => new Map()
        ).get(packet.data.pageId) ?? []),
        ...(MapUtils.getWithSetDefaultIfNeeded(
          this.spacePageObservers,
          packet.data.spaceId,
          () => new Map()
        ).get(null) ?? []),
      ];
      dispatch(childPageObservers);
    } else if (packet.table === 'spaceView') {
      const spaceViewObservers = this.spaceViewObservers.get(packet.data.id) ?? [];
      dispatch(spaceViewObservers);
    } else if (packet.channel.endsWith('/notification')) {
      const { notificationsObservers } = this;
      dispatch(notificationsObservers);
    } else {
      if (/presences/.test(packet.channel)) {
        // 上面的Observers取出来要依赖于packet.data.id,但现在这个packet就没有id这个字段
        const pageId = packet.channel.substring('/presences/'.length, packet.channel.length);
        const presencePageObservers = this.presencePageObservers.get(pageId) ?? [];
        dispatch(presencePageObservers);
      }

      const match = packet.channel.match(/\/spaces\/([^/]*)\/userSharedPages\//);
      if (match && match[1]) {
        const spaceId = match[1];
        const presencePageObservers = this.presencePageObservers.get(spaceId) ?? [];
        dispatch(presencePageObservers);
      }
    }
  }

  subscribePageBlocks(pageId: string, observer: SyncObserver) {
    this.addToObservers(this.pageObservers, pageId, observer);
    return () => {
      this.removeFromObservers(this.pageObservers, pageId, observer);
    };
  }

  subscribeSpaceView(spaceViewId: string, observer: SyncObserver) {
    this.addToObservers(this.spaceViewObservers, spaceViewId, observer);
    return () => {
      this.removeFromObservers(this.spaceViewObservers, spaceViewId, observer);
    };
  }

  subscribeCollectionView(collectionViewId: string, observer: SyncObserver) {
    this.addToObservers(this.collectionViewObservers, collectionViewId, observer);
    return () => {
      this.removeFromObservers(this.collectionViewObservers, collectionViewId, observer);
    };
  }

  // `pageId: null` meant subscribe root pages change
  subscribeChildPages(spaceId: string, pageId: string | null, observer: SyncObserver) {
    const map = MapUtils.getWithSetDefaultIfNeeded(
      this.spacePageObservers,
      spaceId,
      () => new Map()
    );
    MapUtils.add(map, pageId, observer);
    this.scheduleCommitObservers();
    return () => {
      const map = MapUtils.getWithSetDefaultIfNeeded(
        this.spacePageObservers,
        spaceId,
        () => new Map()
      );
      MapUtils.remove(map, pageId, observer);
      if (map.size === 0) {
        this.spacePageObservers.delete(spaceId);
      }
      this.scheduleCommitObservers();
    };
  }

  subscribePresencePages(pageId: string, observer: SyncObserver) {
    this.addToObservers(this.presencePageObservers, pageId, observer);
    return () => {
      this.removeFromObservers(this.presencePageObservers, pageId, observer);
    };
  }
  private lastPresentData: (RequestPresenceData & { pageId: string }) | undefined;
  /** 头像位置 */
  sendPresenceData(userId: string, pageId: string, body: RequestPresenceData['body']) {
    if (this.lastPresentData) {
      // 5分钟内在同一个页面编辑同一个block，就不再重复发消息了
      if (
        this.lastPresentData.pageId === pageId &&
        this.lastPresentData.body.blockId === body.blockId &&
        body.activityTime - this.lastPresentData.body.activityTime < 5 * 60 * 1000 &&
        body.present === this.lastPresentData.body.present
      ) {
        return;
      }
    }
    const data: RequestPresenceData = {
      channel: `/presences/${pageId}`,
      userId,
      body,
      senderId: SENDER_ID,
    };
    this.lastPresentData = { ...data, pageId };
    this.sendRequestData(data);
  }

  // 订阅“共享页面”分区频道的通知
  subscribeSharedPages(spaceId: string, observer: SyncObserver) {
    this.addToObservers(this.sharedPagesObservers, spaceId, observer);
    return () => {
      this.removeFromObservers(this.sharedPagesObservers, spaceId, observer);
    };
  }

  subscribeNotifications(observer: SyncObserver) {
    this.notificationsObservers.push(observer);
    return () => {
      const index = this.notificationsObservers.indexOf(observer);
      if (index >= 0) {
        this.notificationsObservers.splice(index, 1);
      }
    };
  }

  private sendRequestData(data: RequestData) {
    this.requestData.push(data);
    this.scheduleCommitRequest();
  }

  private addToObservers(
    observers: Map<string, SyncObserver[]>,
    objectId: string,
    observer: SyncObserver
  ) {
    MapUtils.add(observers, objectId, observer);
    this.scheduleCommitObservers();
  }

  private removeFromObservers(
    observers: Map<string, SyncObserver[]>,
    objectId: string,
    observer: SyncObserver
  ) {
    MapUtils.remove(observers, objectId, observer);
    this.scheduleCommitObservers();
  }
}

const syncRef = createRef<SyncService>();

export const useGetSyncService = () => {
  // TODO: Renew service if user logout then login.
  return useGetRefValue(syncRef, () => {
    const sync = new SyncService();
    const unsubscribe = store.subscribe(() => {
      const userId = $currentUserCache.uuid;
      const token = getToken();
      if (userId && token?.startsWith('ey')) {
        unsubscribe();
        sync.setUserIdAndToken(userId, token);
      }
    });
    return sync;
  });
};
