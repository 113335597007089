import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import { cleanSelectBlock } from 'src/hooks/utils/clean-select';
import { bizTracker } from 'src/utils/biz-tracker';

interface Props {
  groupName: string;
  isSubGroup?: boolean;
  before?: string;
  className?: string;
}

export const CreateRecord: FC<Props> = ({ className, groupName, isSubGroup, before }) => {
  const insertRecord = useInsertRecordUI();
  const { viewId, viewType } = useBitable();

  const createRecord = () => {
    cleanSelectBlock();
    bizTracker.event('bitable_record_create', {
      from_scene:
        viewType === CollectionViewType.BOARD && !isSubGroup ? 'kanban_header' : 'group_header',
    });

    const propertyValues = getValuesFromGroupValue({
      viewId,
      groupValue: !isSubGroup ? groupName : undefined,
      subGroupValue: isSubGroup ? groupName : undefined,
      isNewRecord: true,
    });

    void insertRecord({
      viewId,
      where: { before },
      propertyValues,
    });
  };

  return (
    <Tooltip
      popup="创建新页面"
      className={cx('flex items-center pr-1 transition duration-300', className)}
    >
      <button
        onClick={createRecord}
        className="animate-hover flex cursor-pointer items-center rounded-sm"
      >
        <Icon name="IcAddBoard" size="middle" />
      </button>
    </Tooltip>
  );
};
