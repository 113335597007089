import type { ChartFormat, CollectionViewDTO } from '@next-space/fe-api-idl';

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { getState } from 'src/redux/store';
import * as reducers from 'src/redux/reducers';
import type { LocalUser } from 'src/redux/reducers/users';
import type { NextBlock } from 'src/redux/types';
import { Images } from 'src/image';

interface ChartType {
  name: string;
  info: {
    tip: string;
    chartMode: ChartFormat['chartMode'];
    image: string;
  }[];
}
export const chartTypeData: ChartType[] = [
  {
    name: '统计数字',
    info: [
      {
        image: Images.chartNumber,
        tip: '统计数字',
        chartMode: {
          type: 'Number',
          display: 'base',
        },
      },
    ],
  },
  {
    name: '柱状图',
    info: [
      {
        image: Images.chartBarBase,
        tip: '基础柱状图',
        chartMode: {
          type: 'Bar',
          display: 'base',
        },
      },
      {
        image: Images.chartBarStack,
        tip: '堆叠柱状图',
        chartMode: {
          type: 'Bar',
          display: 'stack',
        },
      },
      {
        image: Images.chartBarPercent,
        tip: '百分比堆叠柱状图',
        chartMode: {
          type: 'Bar',
          display: 'percent',
        },
      },
    ],
  },
  {
    name: '折线图',
    info: [
      {
        image: Images.chartLineBase,
        tip: '基础折线图',
        chartMode: {
          type: 'Line',
          display: 'base',
        },
      },
      {
        image: Images.chartLineSmooth,
        tip: '平滑折线图',
        chartMode: {
          type: 'Line',
          display: 'smooth',
        },
      },
      {
        image: Images.chartLineStep,
        tip: '阶梯折线图',
        chartMode: {
          type: 'Line',
          display: 'step',
        },
      },
    ],
  },
  {
    name: '条形图',
    info: [
      {
        image: Images.chartHorizontalbarBase,
        tip: '基础条形图',
        chartMode: {
          type: 'HorizontalBar',
          display: 'base',
        },
      },
      {
        image: Images.chartHorizontalbarStack,
        tip: '堆叠条形图',
        chartMode: {
          type: 'HorizontalBar',
          display: 'stack',
        },
      },
      {
        image: Images.chartHorizontalbarPercent,
        tip: '百分比堆叠条形图',
        chartMode: {
          type: 'HorizontalBar',
          display: 'percent',
        },
      },
    ],
  },
  {
    name: '饼状图',
    info: [
      {
        image: Images.chartPieBase,
        tip: '饼状图',
        chartMode: {
          type: 'Pie',
          display: 'base',
        },
      },
    ],
  },
];
/** 无效字段 */
export const invalidProperty = { type: undefined, title: '无效字段', icon: undefined };

export const createPreviewStore = (chartId: string) => {
  const reducer = combineReducers(reducers);
  const collectionViews: Record<string, CollectionViewDTO> = {};
  const users: Record<string, LocalUser> = {};
  const blocks: Record<string, NextBlock> = {};

  const globalStore = getState();
  const chartBlock = globalStore.blocks[chartId];
  chartBlock && (blocks[chartId] = chartBlock);
  const collectionId = chartBlock?.data.format?.chartFormat?.ref?.uuid;
  Object.assign(users, globalStore.users);
  if (collectionId) {
    // 如果配置了多维表，就把多维表的数据塞进来
    const collection = globalStore.blocks[collectionId];
    if (collection) {
      blocks[collectionId] = collection;
      collection.subNodes.forEach((v) => {
        const sub = globalStore.blocks[v];
        sub && (blocks[v] = sub);
      });
    }
    let parent = globalStore.blocks[collection?.parentId ?? ''];
    // 权限相关需要用到
    while (parent) {
      blocks[parent.uuid] = parent;
      // @ts-ignore space check
      if (parent.type === 'SPACE') break;
      parent = globalStore.blocks[parent?.parentId ?? ''];
    }
    collection?.views?.forEach((v) => {
      const cv = globalStore.collectionViews[v];
      cv && (collectionViews[v] = cv);
    });
  }

  const store = configureStore({
    reducer,
    preloadedState: {
      blocks,
      users,
      collectionViews,
    },
  });
  return store;
};
