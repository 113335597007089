import { useOpenModal } from '@flowus/common/next-modal';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import {
  AggregationAction,
  CollectionSchemaType,
  CollectionViewType,
} from '@next-space/fe-api-idl';
import type { CSSProperties, FC } from 'react';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useBitable } from '../context';
import { getAvailableAggregations } from '../table-view/footer/menu-item';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import type { NextBlock } from 'src/redux/types';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { calculateAggregationValue } from '../table-view/footer/calculate-aggregation-value';
import { formatNumber } from '../table-view/cell/num';
import { AggregationActionUiMap, isNumberAggregation } from '../table-view/footer/helper';
import { formula } from '@flowus/formula';
import { uniqueId } from 'lodash-es';
import { buildGroupAggregationListItem } from '../table-view/footer/group-menu-helper';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { ListView } from 'src/common/components/list-view';
import { Tooltip } from '@flowus/common/tooltip';

interface Props {
  recordIds: string[];
  style?: CSSProperties;
  groupbyFieldName?: 'collectionGroupBy' | 'boardGroupBy';
}

export const GroupAggregation: FC<Props> = (props) => {
  const { recordIds, groupbyFieldName = 'collectionGroupBy' } = props;
  const openModal = useOpenModal();
  const { collectionId, viewId, readonly } = useBitable();
  const collectionView = useCollectionView(viewId);
  const groupBy = collectionView?.format?.[groupbyFieldName];
  const groupAggregation = useObservableStore(() => {
    if (!groupBy) return AggregationAction.COUNT_ALL;
    const propertyId = groupBy?.aggregationPeopertyId;
    if (!propertyId) return AggregationAction.COUNT_ALL;
    const action = groupBy.aggregation;

    const allAggregations = getAvailableAggregations(collectionId, propertyId);
    const validAggregation = allAggregations.find(
      (item) => item.type === action && action !== AggregationAction.NONE
    );

    return validAggregation?.type ?? AggregationAction.COUNT_ALL;
  }, [viewId, collectionView]);
  const prefix = useObservableStore(
    (state) => {
      if (!groupBy) return;
      const propertyId = groupBy?.aggregationPeopertyId;
      if (!propertyId) return '';
      const collection = state.blocks[collectionId];
      const propertySchema = collection?.data.schema?.[propertyId];
      return propertySchema?.name ?? '';
    },
    [collectionId, viewId, collectionView, groupAggregation]
  );
  const aggregationValue = useObservableStore(
    (state) => {
      if (!groupBy) return;
      const propertyId = groupBy?.aggregationPeopertyId;
      if (!propertyId) return recordIds.length;
      const { propertySchema, targetPropertySchema } = getPropertySchema(collectionId, propertyId);
      if (!groupAggregation || !propertySchema) return;
      const { aggregation, type: propertySchemaType } = propertySchema ?? {};

      const { blocks } = state;
      const formulaTool = getFormulaTool(collectionId);
      const aggregationValue = calculateAggregationValue(
        recordIds.map((uuid) => blocks[uuid]).filter((item): item is NextBlock => !!item),
        propertyId,
        groupAggregation,
        collectionId,
        formulaTool
      );

      const result = aggregationValue?.result;
      const decorateNumber = (schema: CollectionSchema | undefined, value?: string | number) => {
        if (
          schema?.type === CollectionSchemaType.NUMBER &&
          schema.numberFormat &&
          typeof value !== 'undefined'
        ) {
          return formatNumber(Number(value), schema.numberFormat);
        }

        if (schema?.type === CollectionSchemaType.FORMULA) {
          const type = formulaTool.getType(propertyId);
          if (
            type === formula.ValueTool.numberType &&
            schema.numberFormat &&
            typeof value !== 'undefined'
          ) {
            return formatNumber(Number(value), schema.numberFormat);
          }
        }

        return value;
      };

      if (propertySchemaType === CollectionSchemaType.ROLLUP) {
        if (isNumberAggregation(groupAggregation) && isNumberAggregation(aggregation)) {
          return decorateNumber(targetPropertySchema, result);
        }
        return result;
      }

      if (isNumberAggregation(groupAggregation)) {
        return decorateNumber(propertySchema, result);
      }

      return result;
    },
    [viewId, recordIds, groupAggregation, collectionView],
    {
      ignoreOtherData: false,
    }
  );
  const handleAggregationClick = (e: React.MouseEvent) => {
    if (readonly) return;
    const modalId = uniqueId();
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const listItems = buildGroupAggregationListItem(
      collectionId,
      {
        propertyId: groupBy?.aggregationPeopertyId,
        aggregation: groupBy?.aggregation ?? AggregationAction.COUNT_ALL,
      },
      (aggregation, schema) => {
        updateViewFormat(viewId, {
          // 如果是board子分组就用subGroupBy,逻辑真__绕
          [groupbyFieldName]: {
            ...groupBy,
            aggregationPeopertyId: schema?.propertyId,
            aggregation,
          },
        });
        openModal.closeModal(modalId);
      }
    );
    if (!listItems) return;
    openModal.dropdown({
      modalId,
      popcorn: e.currentTarget,
      placement: 'bottom',
      content: () => {
        return <ListView className="w-[200px] py-2 next-modal" items={listItems} />;
      },
    });
  };
  return (
    <Tooltip popup={`${prefix} ${AggregationActionUiMap[groupAggregation]}`}>
      <span
        className="text-t2 mx-1.5 text-grey4 animate-hover px-1.5"
        style={props.style}
        onClick={handleAggregationClick}
      >
        {aggregationValue}
      </span>
    </Tooltip>
  );
};
