import produce from 'immer';
import { assign } from 'lodash-es';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { $otherSpaceState, $setOtherSpaceState } from './state';

export const useGetOtherSpacePublicDataAndPatch = () => {
  return useCallback(async (spaceId: string) => {
    const spaces = $otherSpaceState.getState();
    if (spaces[spaceId]) {
      return spaces[spaceId];
    }

    const res = await request.infra.getSpacesPublicData({
      spaceIds: [spaceId],
    });

    const space = res.spaces[spaceId];

    if (space) {
      $setOtherSpaceState(produce((pre) => assign(pre, { [spaceId]: space })));
    }

    return space;
  }, []);
};
