import type { IEditorModel, ISelection } from '@next-space/fe-inlined';
import { useEffect } from 'react';

export const focusTracker = {
  activeEditorKey: null as string | null,
  selection: null as ISelection | null,
};

export const useTrackFocus = (editorKey: string, editorModel: IEditorModel) => {
  useEffect(() => {
    const subscription = editorModel.onActiveChange.subscribe(() => {
      if (editorModel.active) {
        focusTracker.activeEditorKey = editorKey;
      } else {
        if (focusTracker.activeEditorKey === editorKey) {
          focusTracker.activeEditorKey = null;
        }
      }
    });
    return () => {
      if (focusTracker.activeEditorKey === editorKey) {
        focusTracker.selection = editorModel.selection;
      }
      subscription.unsubscribe();
    };
  }, [editorKey, editorModel]);
};
