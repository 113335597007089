import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { useEffect } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useReadonly } from '../page';
import { getPermissions } from '../share/use-permissions';
import { transaction } from '../use-transaction';

export const useCheckCollectionRecord = (collectionId: string) => {
  const readonly = useReadonly(collectionId);

  useEffect(() => {
    if (readonly) return;
    const collection = cache.blocks[collectionId];
    if (!collection) return;

    const notPageBlock = collection.subNodes.filter((uuid) => {
      const block = cache.blocks[uuid];
      return block && block.type !== BlockType.PAGE;
    });

    if (notPageBlock.length > 0) {
      transaction(() => {
        notPageBlock.forEach((uuid) => {
          const { role } = getPermissions(uuid);
          if (role === PermissionRole.EDITOR) {
            updateBlock(uuid, { type: BlockType.PAGE });
          }
        });
      });
    }
  }, [collectionId, readonly]);
};
