import type { CSSProperties, FC, Key, PropsWithChildren } from 'react';
import React, { Children, useEffect, useRef, useState } from 'react';
import type { TabPaneProps } from './tab-panel';
import { cx } from '../../cx';

export interface TabProps {
  defaultActiveIndex?: number;
  className?: string;
  headerClassName?: string;
  childrenClassName?: string;
  active?: number;
  /**
   * 自定义button，在最后面
   */
  customButtons?: () => React.ReactNode | undefined;
  onChangeTab?: (index: number) => void;
  displayMode?: 'singleNode' | 'preview' | 'lazyLoad'; // 单节点显示,全节点显示,延迟加载
  style?: CSSProperties;
  border?: boolean; // default true
  showHeader?: boolean; // default true
}
interface TabPaneInfo extends TabPaneProps {
  key: Key | null;
  node: React.ReactElement<TabPaneProps>;
}
function parseTabPaneList(children: React.ReactNode): TabPaneInfo[] {
  const info: TabPaneInfo[] = [];
  Children.toArray(children).forEach((node) => {
    if (React.isValidElement(node)) {
      info.push({
        key: node.key,
        ...node.props,
        node,
      });
    }
  });
  return info;
}
/**
 * tab切换,使用时需要用className设置宽度,非受控
 */
export const Tab: FC<PropsWithChildren<TabProps>> = ({
  className,
  childrenClassName,
  children,
  defaultActiveIndex = 0,
  customButtons,
  onChangeTab,
  active,
  displayMode = 'lazyLoad',
  style,
  border = true,
  showHeader = true,
  headerClassName = '',
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const divRef = useRef<HTMLDivElement>(null);
  const loadIndexSet = useRef(new Set([activeIndex]));
  useEffect(() => {
    if (active !== undefined) {
      setActiveIndex(active);
    }
  }, [active]);
  const tabInfoList = parseTabPaneList(children);

  loadIndexSet.current.add(activeIndex); // 记录选中的index,表示需要加载
  return (
    <div className={className} style={style} data-no-cancel-selected>
      {showHeader && (
        <div
          ref={divRef}
          className={cx(
            'h-[50px] flex py-2.5 justify-between items-center',
            {
              'border-b border-grey6 ': border,
            },
            headerClassName
          )}
        >
          <span className="flex items-center">
            {tabInfoList.map((item, index) => {
              return (
                <span
                  key={item.key ?? index}
                  onClick={() => {
                    setActiveIndex(index);
                    onChangeTab?.(index);
                  }}
                  className={cx(
                    'text-t2 py-1 px-1.5 mx-1 rounded-sm flex-shrink-0',
                    activeIndex === index
                      ? 'bg-black_006 cursor-pointer text-t2-medium'
                      : 'animate-hover',
                    childrenClassName
                  )}
                >
                  {item.icon && item.icon}
                  {item.title}
                </span>
              );
            })}
          </span>
          {customButtons?.()}
        </div>
      )}
      {displayMode === 'singleNode' && tabInfoList[activeIndex]?.node}
      {displayMode !== 'singleNode' &&
        tabInfoList.map((item, index) => {
          const loaded = loadIndexSet.current.has(index) || displayMode === 'preview';
          return loaded ? (
            <div
              key={item.key || index}
              className={cx({ hidden: index !== activeIndex }, childrenClassName)}
            >
              {item.node}
            </div>
          ) : null;
        })}
    </div>
  );
};
