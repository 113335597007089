import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useModel } from 'src/common/create-model';
import { deepEqual } from '@flowus/common/utils/tools';
import { ToolbarModel } from './context';
import { RenderAiShortcut, ToolbarBaseButton } from './items';
import { ToolbarBtnType } from './type';
import { toolbarShortcutToArray } from './utils';

export const ToolbarContent: FC = memo(() => {
  const { readonly, userToolbarShortcut } = useModel(ToolbarModel);

  const renders = useMemo(() => {
    const arr = [];

    if (readonly) {
      arr.push({
        id: ToolbarBaseButton.Comment.info.id,
        border: false,
        render: ToolbarBaseButton.Comment.Render,
      });
      return arr;
    }

    const fixedBtn = toolbarShortcutToArray(userToolbarShortcut).filter((b) => b.fixed);
    fixedBtn.forEach((item) => {
      const baseBtn = ToolbarBaseButton[item.id as keyof typeof ToolbarBaseButton];

      if (baseBtn) {
        arr.push({
          id: baseBtn.info.id,
          border: baseBtn.info.border,
          render: baseBtn.Render,
        });
      } else {
        // 不是基操就是自定义
        arr.push({
          id: item.id,
          render: RenderAiShortcut,
        });
      }
    });

    arr.push({
      id: ToolbarBaseButton.More.info.id,
      border: ToolbarBaseButton.More.info.border,
      render: ToolbarBaseButton.More.Render,
    });
    return arr;
  }, [readonly, userToolbarShortcut]);

  const moreBtnBorder = renders.length > 1 && renders[renders.length - 2]?.border;

  return (
    <>
      {renders.map((item) => (
        <item.render
          key={item.id}
          id={item.id}
          showBorder={moreBtnBorder && item.id === ToolbarBtnType.More}
        />
      ))}
    </>
  );
}, deepEqual);
