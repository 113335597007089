/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { clamp } from 'lodash-es';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';

/**
 * 聚焦 block 内的富文本编辑区域
 *
 * 特殊逻辑
 * - x=0,y=0 聚焦在第一个文字
 * - x=Infinity,y=Infinity 聚焦在最后一个文字
 * @param editorKey editorKey
 * @param x caret 坐标
 * @param y caret 坐标
 */
export const focusEditable = (editorKey: string | undefined | null, x: number, y: number) => {
  requestAnimationFrame(async () => {
    if (!editorKey) return;
    let editorModel = getEditorModelByEditorKey(editorKey);
    if (!editorModel) {
      editorModel = getEditorModelByEditorKey(`${editorKey}_mock`);
    }
    if (!editorModel) return;
    const offset = await (async () => {
      if (x === 0 && y === 0) return 0;
      if (y === Infinity) return editorModel.content.length;

      editorModel.performChange((ctx) => {
        ctx.select(y === Infinity ? editorModel.content.length : 0);
      });
      await editorModel.scrollCaretIntoViewIfNeeded();
      await new Promise((resolve) => setTimeout(resolve, 0));

      const firstRect = editorModel.getBoundingClientRectOfRange(0, 0);
      const lastRect = editorModel.getBoundingClientRectOfRange(
        editorModel.content.length,
        editorModel.content.length
      );
      if (!firstRect || !lastRect) return;

      x = clamp(x, firstRect.left + 1, lastRect.right - 1);
      y = clamp(y, firstRect.top + 1, lastRect.bottom - 1);
      return editorModel.hitTest(x, y);
    })();

    if (offset != null) {
      editorModel.performChange((ctx) => {
        ctx.select(offset);
      });
      await editorModel.requestFocus();
    }
  });
};
