import type { Context } from '../../core/context';
import type { IPropertyIdentValueDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum TEXT_TRANSFORM {
  NONE = 0,
  LOWERCASE = 1,
  UPPERCASE = 2,
  CAPITALIZE = 3,
}

export const textTransform: IPropertyIdentValueDescriptor<TEXT_TRANSFORM> = {
  name: 'text-transform',
  initialValue: 'none',
  prefix: false,
  type: PropertyDescriptorParsingType.IDENT_VALUE,
  parse: (_context: Context, textTransform: string) => {
    // eslint-disable-next-line default-case
    switch (textTransform) {
      case 'uppercase':
        return TEXT_TRANSFORM.UPPERCASE;
      case 'lowercase':
        return TEXT_TRANSFORM.LOWERCASE;
      case 'capitalize':
        return TEXT_TRANSFORM.CAPITALIZE;
    }

    return TEXT_TRANSFORM.NONE;
  },
};
