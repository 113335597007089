import { isFilterEnabled } from 'src/bitable/table-view/body/filters';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getViewFormat } from '../block/get-view-format';

export const useFilterEnabled = (viewId: string) => {
  return useObservableStore(
    (state) => {
      const viewInfo = getViewFormat(viewId, state.blocks, state.collectionViews);
      if (!viewInfo) return false;

      const { view, collection } = viewInfo;
      return isFilterEnabled(view.format.filter, collection.data.schema);
    },
    [viewId]
  );
};
