import { VITE_PREFIX } from '@flowus/common/const';
import { Login } from '@flowus/login/component/login';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { getDomain } from 'src/common/utils/url-utils';
import { isBuildIn } from 'src/env';
import { useGetPageId } from 'src/utils/getPageId';
import { LoginFooter } from '../login-uikit/footer';
import { SearchParams } from 'src/utils';

export const useOpenLogin = () => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();

  const openLogin = (params?: { defaultTitle?: string }) => {
    const promotionChannel = new URLSearchParams(location.search).get(
      SearchParams.promotionChannel
    );
    openModal.modal({
      content: () => (
        <div className="overflow-y-auto w-[450px] px-14 py-[30px]">
          <Login
            promotionChannel={promotionChannel ?? undefined}
            hideThirdPartLogin={__PRIVATE__}
            inviteCodeRequired={__PRIVATE__}
            defaultTitle={params?.defaultTitle}
            onSuccess={() => {
              if (getDomain()) {
                location.href = `${VITE_PREFIX}/${pageId}`;
              } else {
                location.reload();
              }
            }}
            styleType={isBuildIn() ? 'build-in-pc' : 'default'}
          />
          <div className="h-5" />
          <LoginFooter />
        </div>
      ),
    });
  };
  return useCallback(openLogin, [openModal, pageId]);
};
