import { getCurrentSpace, useCurrentSpace } from 'src/hooks/space';
import { SpacePlanType } from 'src/redux/types';
import { getSpaceBySpaceId } from './get-space';
import { useSpace } from './use-current-space';

export const PRO_SPACE_PLAN: string[] = [
  SpacePlanType.team,
  SpacePlanType.personal,
  SpacePlanType.smallTeam,
];

export const TEAM_SPACE_PLAN: string[] = [
  // SpacePlanType.freeTeam,
  SpacePlanType.team,
  SpacePlanType.smallTeam,
];

/** 如果是在分享页调用，需要传具体的spaceId，未登录用户的useCurrentSpace是不对的 */
export const useIsProSpace = (spaceId?: string) => {
  const currentSpace = useCurrentSpace();
  const space = useSpace(spaceId ?? currentSpace.uuid);

  const isProSpace =
    space.planType === SpacePlanType.team ||
    space.planType === SpacePlanType.personal ||
    space.planType === SpacePlanType.smallTeam;

  return isProSpace;
};

/** 付费团队空间 */
export const useIsTeamProSpace = (spaceId?: string) => {
  const currentSpace = useCurrentSpace();
  const space = useSpace(spaceId ?? currentSpace.uuid);

  const isProSpace =
    space.planType === SpacePlanType.team || space.planType === SpacePlanType.personal;

  return isProSpace;
};

export const getIsProSpace = (spaceId?: string) => {
  const currentSpace = spaceId ? getSpaceBySpaceId(spaceId) : getCurrentSpace();
  if (currentSpace === undefined) return false;

  const isProSpace =
    currentSpace.planType === SpacePlanType.team ||
    currentSpace.planType === SpacePlanType.personal ||
    currentSpace.planType === SpacePlanType.smallTeam;

  return isProSpace;
};

export const getIsFreeSpaceLimit = (spaceId?: string) => {
  const currentSpace = spaceId ? getSpaceBySpaceId(spaceId) : getCurrentSpace();
  return currentSpace.planType === SpacePlanType.freePersonal;
};

/** 空间版本是否受到限制 */
export const useIsFreeSpaceLimit = (spaceId?: string) => {
  const currentSpace = useCurrentSpace();
  const space = useSpace(spaceId ?? currentSpace.uuid);
  return space.planType === SpacePlanType.freePersonal;
};

export const useIsShowAd = (spaceId?: string) => {
  const currentSpace = useCurrentSpace();
  spaceId = spaceId ?? currentSpace.uuid;
  const isFreeSpaceLimit = useIsFreeSpaceLimit(spaceId);
  const space = useSpace(spaceId);
  const { isShowAd = true } = space;

  if (__PRIVATE__) return false;
  if (isFreeSpaceLimit) {
    return true;
  }

  return isShowAd;
};
