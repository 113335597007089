import { ContentGravity } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useMemo } from 'react';
import { ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { useAlignBlock } from 'src/hooks/block/use-align-block';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { covertBlockToTitle } from '../menu-list/menu-item';

interface Props {
  blockIds: string[];
  isCaption?: boolean;
  closeModal: () => void;
}
const ALIGIN_IMAGE_KEY = 'ALIGIN_IMAGE';

const saveAlignGravity = (gravity: ContentGravity) => {
  localStorage.setItem(ALIGIN_IMAGE_KEY, gravity);
};
export const getLastAlignGravity = () => {
  return localStorage.getItem(ALIGIN_IMAGE_KEY) ?? ContentGravity.CENTER;
};

/**
 * 块对齐，块说明要跟随。
 */
export const AlignPanel: FC<Props> = (props) => {
  const block = usePickBlock(props.blockIds[0], ['data'], ['display', 'format']);
  const transaction = useTransaction();
  const { alignBlock, alignBlockCaption } = useAlignBlock();
  const isShowCaption = block?.data.format?.isShowCaption ?? true;

  const items = useMemo(() => {
    const items: ListItem[] = [
      {
        type: ListItemType.OPERATION,
        data: {
          title: '左对齐',
          rightText: 'L',
          onClick: () => {
            bizTracker.event(props.isCaption ? 'caption_align' : 'block_align', {
              align_type: 'left',
              block_name: props.isCaption ? undefined : covertBlockToTitle(block?.uuid ?? ''),
            });
            saveAlignGravity(ContentGravity.LEFT);
            transaction(() => {
              props.blockIds.forEach((id) => {
                if (!props.isCaption) {
                  alignBlock(id, ContentGravity.LEFT);
                }
                alignBlockCaption(id, ContentGravity.LEFT);
              });
              props.closeModal();
            });
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '居中对齐',
          rightText: 'C',
          onClick: () => {
            bizTracker.event(props.isCaption ? 'caption_align' : 'block_align', {
              align_type: 'center',
              block_name: props.isCaption ? undefined : covertBlockToTitle(block?.uuid ?? ''),
            });
            transaction(() => {
              saveAlignGravity(ContentGravity.CENTER);
              props.blockIds.forEach((id) => {
                if (!props.isCaption) {
                  alignBlock(id, ContentGravity.CENTER);
                }
                alignBlockCaption(id, ContentGravity.CENTER);
              });
              props.closeModal();
            });
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        data: {
          title: '右对齐',
          rightText: 'R',
          onClick: () => {
            bizTracker.event(props.isCaption ? 'caption_align' : 'block_align', {
              align_type: 'right',
              block_name: props.isCaption ? undefined : covertBlockToTitle(block?.uuid ?? ''),
            });
            transaction(() => {
              saveAlignGravity(ContentGravity.RIGHT);
              props.blockIds.forEach((id) => {
                if (!props.isCaption) {
                  alignBlock(id, ContentGravity.RIGHT);
                }
                alignBlockCaption(id, ContentGravity.RIGHT);
              });
              props.closeModal();
            });
          },
        },
      },
    ];
    if (props.isCaption && props.blockIds.length === 1) {
      items.unshift(
        ...[
          {
            type: ListItemType.TEXT_SWITCH,
            data: {
              switch: isShowCaption,
              title: '显示说明',
              onSwitch: (status: boolean) => {
                transaction(() => {
                  const blockId = props.blockIds[0] ?? '';
                  const data = cache.blocks[blockId]?.data;
                  bizTracker.event('block_caption', {
                    block_name: covertBlockToTitle(block?.uuid ?? ''),
                    switch: status ? 1 : 0,
                  });
                  updateBlock(blockId, {
                    data: {
                      ...data,
                      format: {
                        isShowCaption: status,
                      },
                    },
                  });
                });
                props.closeModal();
              },
            },
          },
          {
            type: ListItemType.LINE,
            data: {},
            disableClick: true,
          },
        ]
      );
    }
    return items;
  }, [props, block?.uuid, transaction, alignBlockCaption, alignBlock, isShowCaption]);

  return (
    <ListView
      className={listViewNormalClassName}
      items={items}
      keyDownPriority={PRIORITY_DIALOG + 1}
    />
  );
};
