import { BuildInPlugin, NoBuildInPlugin } from '../../inline/build-in';
import { CodeInlinePlugin, NoCodeInlinePlugin } from '../../inline/code';
import { CommentInlinePlugin } from '../../inline/comment';
import { DateInlinePlugin } from '../../inline/date';
import { EmojiInlinePlugin } from '../../inline/emoji';
import { EquationInlinePlugin, NoEquationInlinePlugin } from '../../inline/equation';
import { LinkInlinePlugin } from '../../inline/link';
import { LinkBlockInlinePlugin, LinkPageInlinePlugin } from '../../inline/link-page';
import { PersonInlinePlugin } from '../../inline/person';
import { SelectInlinePlugin } from '../../inline/select-line';
import { antiCycleSet_DEFAULT_PLUGINS } from './hook';

export const DEFAULT_PLUGINS = [
  BuildInPlugin,
  CodeInlinePlugin,
  DateInlinePlugin,
  PersonInlinePlugin,
  LinkInlinePlugin,
  LinkPageInlinePlugin,
  LinkBlockInlinePlugin,
  SelectInlinePlugin,
  EquationInlinePlugin,
  EmojiInlinePlugin,
  CommentInlinePlugin,
];

export const TITLE_EDITOR_PLUGINS = [
  NoBuildInPlugin,
  NoCodeInlinePlugin,
  DateInlinePlugin,
  PersonInlinePlugin,
  LinkInlinePlugin,
  LinkPageInlinePlugin,
  SelectInlinePlugin,
  EquationInlinePlugin,
  EmojiInlinePlugin,
  LinkBlockInlinePlugin,
  NoEquationInlinePlugin,
];

antiCycleSet_DEFAULT_PLUGINS(DEFAULT_PLUGINS);
