import type { IEditorModel } from '@next-space/fe-inlined';

export const EDITOR_MODEL_OF_ELEMENT = Symbol('Editor Model');

/** 不对外暴露，只给editable使用。
 * 这个是基于editorKey存储的，想使用editorModel需要使用useGetOrInitEditorModel */
export const _EditorModelMap = new Map<string, IEditorModel>();
// export const _EditorModelUsage = new Map<string, number>();

// 避免循环依赖，提取到单独的文件
/** 只有useGetOrInitEditorModel不能用的情况下才使用这个方法 */
/**
 * @NOTE 求求你们尽量不要用这个方法，除非你100%确定你在干嘛。
 *              右侧打开的页面用这个会有不少问题！！（editorKey在右侧并不是blockId,如果直接传blockId会找不到）
 * @param editorKey
 * @returns
 */
export const getEditorModelByEditorKey = (editorKey: string) => {
  return (
    _EditorModelMap.get(editorKey) ??
    ((document.querySelector(`[data-editor="${editorKey}"]`) as any)?.[
      EDITOR_MODEL_OF_ELEMENT
    ] as IEditorModel)
  );
};
