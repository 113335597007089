import { cx } from '@flowus/common/cx';
import { MindMappingLineStyle, MindMappingType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { Switch } from 'src/common/components/switch';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { useAlignSiblingNode } from '../hook/use-align-sibling-node';
import { useMindMapStyle } from '../hook/use-mind-map-style';

interface Props {
  mindMapId: string;
}
/** 导图样式选择 */
export const MindMapStylePanel: FC<Props> = (props) => {
  const { mindMapStyle, mindMapLineStyle } = useMindMapStyle(props.mindMapId);
  const alignSiblingNode = useAlignSiblingNode(props.mindMapId);
  const transaction = useTransaction();
  const readonly = useReadonly(props.mindMapId);
  // 更换结构
  const onChangeMindMapStyle = useCallback(
    (newMindMapStyle: MindMappingType) => {
      if (mindMapStyle === newMindMapStyle) return;
      const block = cache.blocks[props.mindMapId];
      if (!block) return;
      const update = (ignoreOp: boolean) => {
        updateBlock(
          props.mindMapId,
          {
            data: {
              format: {
                mindMappingFormat: {
                  ...block.data.format?.mindMappingFormat,
                  type: newMindMapStyle,
                },
              },
            },
          },
          ignoreOp
        );
        bizTracker.event('switch_layout');
      };
      if (readonly) {
        update(true);
      } else {
        transaction(() => {
          update(false);
        });
      }
    },
    [mindMapStyle, props.mindMapId, readonly, transaction]
  );
  // 更换线的类型
  const onChangeMindMapLineStyle = useCallback(
    (newLineStyle: MindMappingLineStyle) => {
      if (mindMapLineStyle === newLineStyle) return;
      const block = cache.blocks[props.mindMapId];
      if (!block) return;
      const update = (ignoreOp: boolean) => {
        updateBlock(
          props.mindMapId,
          {
            data: {
              format: {
                mindMappingFormat: {
                  ...block.data.format?.mindMappingFormat,
                  lineStyle: newLineStyle,
                },
              },
            },
          },
          ignoreOp
        );
        bizTracker.event('switch_line');
      };
      if (readonly) {
        update(true);
      } else {
        transaction(() => {
          update(false);
        });
      }
    },
    [mindMapLineStyle, props.mindMapId, readonly, transaction]
  );

  const onSwitchAlignSiblingNode = useCallback(
    (open: boolean) => {
      if (open === alignSiblingNode) return;
      const block = cache.blocks[props.mindMapId];
      if (!block) return;
      bizTracker.event('node_align', { is_align: open });
      const update = (ignoreOp: boolean) => {
        updateBlock(
          props.mindMapId,
          {
            data: {
              format: {
                mindMappingFormat: {
                  ...block.data.format?.mindMappingFormat,
                  siblingNodeAlignment: open,
                },
              },
            },
          },
          ignoreOp
        );
        bizTracker.event('switch_layout');
      };
      if (readonly) {
        update(true);
      } else {
        transaction(() => {
          update(false);
        });
      }
    },
    [alignSiblingNode, props.mindMapId, readonly, transaction]
  );

  return (
    <div className="px-4 pt-3 pb-2 w-60" data-ignore-double-click>
      <div className="text-t2 text-grey3">结构</div>
      <div className="flex items-center mt-3 justify-between">
        <BackgroundIcon
          name="IcSkeleton1"
          className="p-1"
          active={mindMapStyle === MindMappingType.LOGIC_DIAGRAM_LEFT}
          onClick={() => {
            onChangeMindMapStyle(MindMappingType.LOGIC_DIAGRAM_LEFT);
          }}
        />
        <BackgroundIcon
          name="IcSkeleton2"
          className="py-1 px-1.5"
          active={mindMapStyle === MindMappingType.LOGIC_DIAGRAM_RIGHT}
          onClick={() => {
            onChangeMindMapStyle(MindMappingType.LOGIC_DIAGRAM_RIGHT);
          }}
        />
        <BackgroundIcon
          name="IcSkeleton3"
          className="p-1 px-1.5"
          active={mindMapStyle === MindMappingType.MIND_MAPPING}
          onClick={() => {
            onChangeMindMapStyle(MindMappingType.MIND_MAPPING);
          }}
        />
        <BackgroundIcon
          name="IcSkeleton4"
          className="p-1 px-1.5"
          active={mindMapStyle === MindMappingType.ORGANIZATION_STRUCTURE}
          onClick={() => {
            onChangeMindMapStyle(MindMappingType.ORGANIZATION_STRUCTURE);
          }}
        />
      </div>
      <div className="text-t2 text-grey3 mt-3">分支线</div>
      <div className="flex items-center justify-between mt-3">
        <BackgroundIcon
          name="IcBranch1"
          className="py-1.5 px-8"
          active={mindMapLineStyle === MindMappingLineStyle.NORMAL}
          onClick={() => {
            onChangeMindMapLineStyle(MindMappingLineStyle.NORMAL);
          }}
        />
        <BackgroundIcon
          name="IcBranch2"
          className="py-1.5 px-8"
          active={mindMapLineStyle === MindMappingLineStyle.CUBIC_BEZIER}
          onClick={() => {
            onChangeMindMapLineStyle(MindMappingLineStyle.CUBIC_BEZIER);
          }}
        />
      </div>
      <div className="text-t2 text-grey3 mt-3">节点宽度</div>
      <div className="flex items-center justify-between h-10 mt-2">
        <span className="mr-1 text-t2">同级节点对齐</span>
        <Switch
          open={alignSiblingNode}
          onSwitch={(open) => {
            onSwitchAlignSiblingNode(open);
          }}
        />
      </div>
    </div>
  );
};

interface BackgroundIcon {
  name: IconName;
  active?: boolean;
  className?: string;
  onClick: () => void;
}
const BackgroundIcon: FC<BackgroundIcon> = (props) => {
  return (
    <div
      className={cx(
        'hover:animate-hover rounded',
        {
          'text-active_color bg-active_color_10': props.active,
        },
        props.className
      )}
      onClick={props.onClick}
    >
      <Icon name={props.name} size="xxlarge" />
    </div>
  );
};
