import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import type { NextBlock } from 'src/redux/types';
import { segmentsToDateValue } from '../../bitable-utils';

export interface DateSegmentInfo {
  dateString: string;
  timestamp: number;
  textType: TextType.DATE | TextType.DATETIME;
}

export const getDatePropertyFromBlock = (
  block: NextBlock | undefined,
  propertyId: string
): DateSegmentInfo | undefined => {
  if (!block) return;
  const segment = block.data.collectionProperties?.[propertyId]?.find((item) => {
    return item.type === TextType.DATE || item.type === TextType.DATETIME;
  });
  if (!segment) return;

  return segmentsToDateValue(segment);
};

export const readUrlFromSegments = (segments: SegmentDTO[] | undefined) => {
  const segment = segments?.find((item) => {
    return item.type === TextType.URL;
  });

  return segment?.url || segment?.text || segmentsToText(segments);
};
