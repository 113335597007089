import { BlockType } from '@next-space/fe-api-idl';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { getBlockRenderer } from '../_renderers';
import { AudioBlockElement } from './audio';
import { FileBlockElement } from './file';
import { FolderBlockElement } from './folder';
import { ImageBlockElement } from './image';
import { PageBlockElement } from './page';
import { VideoBlockElement } from './video';

/**
 * 目前引用block仅仅支持image和file,需要支持其它block的话还需要调整一下
 * 引用block的由来
 * https://www.notion.so/block-408a822161ab4cf09df41f359adf9e63
 */
export const ReferenceBlockElement: BlockElement = ({ id, root }) => {
  const block = usePickBlock(id, ['data'], ['ref', 'display']);
  const referenceBlock = usePickBlock(block?.data.ref?.uuid, []);
  useDoneLocalStatus({ uuid: id });

  if (!block) return null;

  let Element: BlockElement = () => <></>;

  // 2021-11.23 目前引用块只有页面引用块，display仅有linkPage类型，下面其它逻辑都是为了兼容老数据，后面会考虑删掉
  switch (block.data.display) {
    case 'image': {
      Element = ImageBlockElement;
      break;
    }
    case 'file': {
      Element = FileBlockElement;
      break;
    }
    case 'folder': {
      Element = FolderBlockElement;
      break;
    }
    case 'video': {
      Element = VideoBlockElement;
      break;
    }
    case 'audio': {
      Element = AudioBlockElement;
      break;
    }
    case 'linkPage': {
      if (referenceBlock?.type === BlockType.FOLDER) {
        Element = FolderBlockElement;
      } else {
        Element = PageBlockElement;
      }
      break;
    }
    default:
      if (block.data.ref?.uuid && referenceBlock) {
        Element = getBlockRenderer(referenceBlock.type);
      }
  }
  return <Element id={block.data.ref?.uuid ?? ''} root={root} ownerBlockId={id} />;
};
