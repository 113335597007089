import type { Dispatch, MutableRefObject, RefObject, SetStateAction } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

export const useGetRefValue = <T>(ref: React.MutableRefObject<T | null>, init: () => T): T => {
  if (ref.current === null) {
    ref.current = init();
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ref.current!;
};

const magic = {};

export const useSignal = <T>(value: T, equal: (a: T, b: T) => boolean = Object.is) => {
  const subject = useFinalValue(() => new BehaviorSubject(value));
  if (!equal(value, subject.value)) {
    subject.next(value);
  }
  return subject;
};

export const useFinalValue = <T>(init: () => T): T => {
  const ref = useRef<T>(magic as T);
  if (ref.current === magic) {
    ref.current = init();
  }
  return ref.current;
};

export function useScheduleUpdate() {
  const [, setMagic] = useState({});
  const scheduleRef = useRef(false);
  scheduleRef.current = false;
  return useCallback((callback?: () => void) => {
    if (scheduleRef.current) return;
    scheduleRef.current = true;
    void Promise.resolve().then(() => {
      setMagic(() => {
        callback?.();
        return {};
      });
    });
  }, []);
}

// export function useStateRef<T>(initialValue: T, scheduleUpdate?: () => void) {
//   const ref = useRef<T>(initialValue);
//   return useMemo(() => {
//     return {
//       get val() {
//         return ref.current;
//       },
//       set val(value: T) {
//         ref.current = value;
//         scheduleUpdate?.();
//       },
//     };
//   }, [scheduleUpdate]);
// }

export function useUpdatedRef<T>(value: T): RefObject<T> & { current: T } {
  const ref = useRef() as MutableRefObject<T>;
  ref.current = value;
  return ref;
}

export function useStableCallback<A extends any[], B>(callback: (...args: A) => B) {
  const ref = useRef(callback);
  ref.current = callback;
  return useCallback((...args: A): B => {
    return ref.current(...args);
  }, []);
}

type AsyncResult<T> = ['fulfilled', T] | ['rejected', any] | ['pending'];

export function useAsyncComputation<T>(
  callback: (signal: AbortSignal) => Promise<T>,
  deps?: React.DependencyList
): AsyncResult<T> {
  const [result, setResult] = useState<AsyncResult<T>>(['pending']);

  useEffect(() => {
    let closed = false;
    const controller = new AbortController();
    setResult(['pending']);
    callback(controller.signal)
      .then((data) => {
        setResult(['fulfilled', data]);
      })
      .catch((reason) => {
        setResult(['rejected', reason]);
      })
      .finally(() => {
        closed = true;
      });
    return () => {
      if (!closed) {
        controller.abort();
      }
    };
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
  return result;
}

export const OverlayContainerContext = React.createContext<RefObject<HTMLElement> | undefined>(
  undefined
);

// #region useStateRef
const isFunction = <S>(setStateAction: SetStateAction<S>): setStateAction is (prevState: S) => S =>
  typeof setStateAction === 'function';

interface ReadOnlyRefObject<T> {
  readonly current: T;
}

type UseStateRefValue<T> = [T, Dispatch<SetStateAction<T>>, ReadOnlyRefObject<T>];
interface UseStateRef {
  <S>(initialState: S | (() => S)): UseStateRefValue<S>;
  <S = undefined>(): UseStateRefValue<S>;
}

export const useStateRef: UseStateRef = <S>(initialState?: S | (() => S)) => {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);

  const dispatch: typeof setState = useCallback((setStateAction: any) => {
    ref.current = isFunction(setStateAction) ? setStateAction(ref.current) : setStateAction;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref] as UseStateRefValue<S>;
};
// #endregion
