import type { SpaceDTO } from '@next-space/fe-api-idl';
import { $appUiStateCache } from 'src/services/app';
import { $publicSpacesCache } from 'src/services/public-space';
import { $spaceViewsCache } from 'src/services/spaces/space-views';
import { $spacesCache } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';

/**
 * 获取spaceId,内部是通过currentSpaceViewId来获取
 */
export const getCurrentSpaceId = () => {
  const { $currentSpaceId } = $appUiStateCache;
  return getSpaceBySpaceViewId($currentUserCache.currentSpaceViewId)?.uuid ?? $currentSpaceId;
};

export const getCurrentSpaceView = () => {
  return getSpaceView($currentUserCache.currentSpaceViewId);
};

export const getSpaceBySpaceViewId = (spaceViewId: string) => {
  const spaceId = $spaceViewsCache[spaceViewId]?.spaceId ?? '';
  return getSpaceBySpaceId(spaceId);
};
export const getSpaceBySpaceId = (spaceId: string) => {
  return $spacesCache[spaceId] ?? ($publicSpacesCache[spaceId] as SpaceDTO);
};

export const getSpaceView = (spaceViewId: string) => {
  return $spaceViewsCache[spaceViewId];
};
