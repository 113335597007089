import { createAction } from '@reduxjs/toolkit';

import type { NextCollectionView } from '../types';

export const CREATE_COLLECTION_VIEW = createAction<{
  collectionView: NextCollectionView;
  ignoreOp?: boolean;
}>('collection-view/create');

export const UPDATE_COLLECTION_VIEW = createAction<{
  uuid: string;
  patch: Partial<NextCollectionView>;
  ignoreOp?: boolean;
}>('collection-view/update');

export const LIST_AFTER_COLLECTION_VIEW = createAction<{
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  after?: string;
  ignoreOp?: boolean;
}>('collection-view/list-after');

export const LIST_BEFORE_COLLECTION_VIEW = createAction<{
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  before?: string;
  ignoreOp?: boolean;
}>('collection-view/list-before');

export const LIST_REMOVE_COLLECTION_VIEW = createAction<{
  uuid: string;
  parentId: string;
  ignoreOp?: boolean;
}>('collection-view/list-remove');

export const LIST_AFTER_COLLECTION_VIEW_PAGESORT = createAction<{
  uuid: string;
  viewId: string;
  after?: string;
}>('collection-view/list-after-pagesort');

export const LIST_BEFORE_COLLECTION_VIEW_PAGESORT = createAction<{
  uuid: string;
  viewId: string;
  before?: string;
}>('collection-view/list-before-pagesort');

export const LIST_REMOVE_COLLECTION_VIEW_PAGESORT = createAction<{
  uuid: string;
  viewId: string;
}>('collection-view/list-remove-pagesort');
