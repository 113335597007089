import { cx } from '@flowus/common/cx';
import React, { memo } from 'react';
import { shallowEqual } from 'react-redux';

import { UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { formatDate, getUnitLength } from '../utils/get-timeline-dates';
import { IndicateLine } from './today';

export const TimelineWeekend = memo(() => {
  const { containerWidth, timelineDates, zoomLevel } = useTimeline();
  const unitWidth = UnitWidthMap[zoomLevel];

  return (
    <div className="absolute top-0 h-full" style={{ width: containerWidth }}>
      {timelineDates.map((date) => {
        const left = getUnitLength(date, timelineDates[0] as number, zoomLevel) * unitWidth;

        if (
          zoomLevel === ZoomLevel.HOUR ||
          zoomLevel === ZoomLevel.DAY ||
          zoomLevel === ZoomLevel.QUARTER ||
          zoomLevel === ZoomLevel.YEAR
        ) {
          return <div key={date} style={{ left }} className="absolute h-full bg-grey6 w-[1px]" />;
        }

        if (zoomLevel === ZoomLevel.WEEK) {
          return (
            <div
              key={date}
              style={{ left, width: unitWidth }}
              className={cx(
                'absolute h-full border-l border-grey6',
                (formatDate(date).day === 6 || formatDate(date).day === 0) &&
                  'bg-grey8 dark:bg-grey8/5'
              )}
            />
          );
        }

        if (formatDate(date).day === 6) {
          return (
            <div
              key={date}
              style={{ left, width: unitWidth * 2 }}
              className="absolute h-full bg-grey8 dark:bg-white3 flex-shrink-0 flex flex-col items-center justify-center"
            />
          );
        }

        return null;
      })}
      <IndicateLine />
    </div>
  );
}, shallowEqual);
