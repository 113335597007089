import type { IEditorModel } from '@next-space/fe-inlined';
import { contentToString, sliceContent } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

/** 根据 uuid 获取 contenteditable 元素 */
export const getEditable = (uuid: string, syncId?: string) => {
  if (syncId) {
    return document.querySelector(
      `[data-sync-id="${syncId}"][data-block-id="${uuid}"] [contenteditable]`
    );
  }

  return document.querySelector(`[data-block-id="${uuid}"] [contenteditable]`);
};

/** 获取当前 caret rect */
export const getCaretRange = () => {
  const selection = window.getSelection();
  if (selection.rangeCount < 1) {
    return;
  }
  return selection.getRangeAt(0);
};

/** 计算 caret 在当前 uuid editable 的位置信息 */
export const getCaretInfo = (editorKey: string, _model?: IEditorModel) => {
  const model = _model ?? getEditorModelByEditorKey(editorKey);
  if (!model) return;
  const { selection } = model;
  if (!selection) return;

  let startOffset = selection.offset;
  let { endOffset } = selection;
  if (selection.isCollapsed && model.content.length > 0) {
    if (endOffset === model.content.length) {
      startOffset -= 1;
    } else if (
      !/^[\r\n]/.test(contentToString(sliceContent(model.content, startOffset, startOffset + 1)))
    ) {
      endOffset += 1;
    }
  }

  const caretRect =
    model.getBoundingClientRectOfRange(startOffset, endOffset) ||
    // eslint-disable-next-line no-console
    (console.warn('editorModel.getBoundingClientRectOfRange returns null'), new DOMRect());
  if (model.content.length === 0) {
    return {
      caretRect,
      collapsed: selection.isCollapsed,
      isFirstLine: true,
      isLastLine: true,
      isFirstChar: true,
      isLastChar: true,
    };
  }

  const firstRect = model.getBoundingClientRectOfRange(0, 1);
  const lastRect = model.getBoundingClientRectOfRange(
    model.content.length - 1,
    model.content.length
  );

  const contentStr = contentToString(model.content);
  const firstBr = contentStr.indexOf('\n');
  const lastBr = contentStr.lastIndexOf('\n');

  // Chrome 下 range.getBoundingClientRect 在 \n 边界上有bug，所以用 firstBr 和 lastBr 校验一下
  const isFirstLine =
    caretRect.top === firstRect?.top && (startOffset <= firstBr || firstBr === -1);
  const isLastLine = caretRect.bottom === lastRect?.bottom && (endOffset > lastBr || lastBr === -1);
  const isFirstChar = isFirstLine && selection.focusOffset <= 0;
  const isLastChar = isLastLine && selection.focusOffset >= model.content.length;

  return {
    caretRect,
    collapsed: selection.isCollapsed,
    isFirstLine,
    isLastLine,
    isFirstChar,
    isLastChar,
  };
};

/** 根据字符和匹配长度，来获取被匹配的文字 */
export const getHitTextBySymbol = (params: { symbol: string; model: IEditorModel }) => {
  const { symbol, model } = params;
  const size = symbol.length;
  const { selection } = model;
  const { focusOffset } = selection || {};

  if (!selection || !focusOffset) return {};

  // 找到离节点最近的一个同符号的位置
  const lastSameIndex = findLastIndex(model, selection.offset - size, symbol);

  if (lastSameIndex <= 0) {
    return {};
  }

  // 匹配前面symbol之前一位的字符
  const textLeft =
    lastSameIndex >= size + 1 && lastSameIndex !== -1
      ? getString(model, lastSameIndex - (size + 1), lastSameIndex - size)
      : '';
  try {
    const textRight = getString(model, lastSameIndex, focusOffset - size);
    const hitText = getString(model, lastSameIndex - size, focusOffset);

    // 根据根据size和symbol生成匹配规则
    const startAndEnd = hitText.startsWith(symbol) && hitText.endsWith(symbol);
    const isNotInContent =
      textRight &&
      textRight !== ' ' &&
      !textRight.includes([...new Set(symbol.split(''))].join(''));

    if (!textLeft || textLeft === ' ' || !/^[\x00-\x7F]*$/.test(textLeft)) {
      if (startAndEnd && isNotInContent) {
        return {
          hitText,
          lastSameIndex,
        };
      }
    }
    return { lastSameIndex };
  } catch {
    return {};
  }
};
const getString = (model: IEditorModel, startOffset: number, endOffset: number) => {
  return contentToString(sliceContent(model.content, startOffset, endOffset));
};
const findLastIndex = (model: IEditorModel, offset: number, str: string) => {
  while (offset > 0 && offset - str.length > 0) {
    const s = getString(model, offset - str.length, offset);
    if (str === s) break;
    offset--;
  }
  return offset;
};
// ----------------------------------------------------------------

/** 判断是否可以被认为是Mark down语法 */
export const useIsMarkDownKey = (uuid: string) => {
  const getEditorModel = useGetOrInitEditorModel();

  return useCallback(
    (eventKey: string): Promise<'underline' | 'highlight' | false> => {
      return new Promise((resolve) => {
        const model = getEditorModel(uuid);
        if (!model) {
          resolve(false);
          return;
        }

        if ((model.selection?.focusOffset ?? 0) < 2) {
          resolve(false);
        }

        const subscribe = model.onAfterInput.subscribe(() => {
          subscribe.unsubscribe();
          if (eventKey === '>') {
            const { hitText } = getHitTextBySymbol({
              model,
              symbol: '<u>',
            });
            if (hitText) {
              resolve('underline');
            }
          }

          if (eventKey === '=') {
            const { hitText } = getHitTextBySymbol({
              model,
              symbol: '==',
            });

            if (hitText) {
              resolve('highlight');
            }
          }

          resolve(false);
        });
      });
    },
    [getEditorModel, uuid]
  );
};
