import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import type { FC } from 'react';

import { WEEKDAYS_SHORT, formatWeekText } from 'src/utils/date-utils';
import { UnitWidthMap, ZoomLevel } from './const';
import { useTimeline } from './context';
import { formatDate, getUnitLength, getUnitTime, isWeekOrMonth } from './utils/get-timeline-dates';
import { SHORT_DATE_FORMAT } from 'src/common/const';

interface IndicatorProps {
  unitLength: number; // 结束时间和开始时间之间的单位长度
  startTime: number;
}

export const Indicator: FC<IndicatorProps> = ({ startTime, unitLength }) => {
  const { timelineDates, zoomLevel } = useTimeline();

  const endTime = startTime + unitLength * getUnitTime(zoomLevel);

  const unitWidth = UnitWidthMap[zoomLevel];
  const left =
    getUnitLength(startTime, timelineDates[0] as number, zoomLevel, {
      needDateStart: true,
      abs: false,
    }) * UnitWidthMap[zoomLevel];

  // day 和 hour 结束时间都是一个时刻，不用向后扩展，不用加 1
  const minWidth =
    zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR
      ? unitLength * unitWidth
      : (unitLength + 1) * unitWidth;

  const renderContent = () => {
    if ((zoomLevel === ZoomLevel.YEAR || zoomLevel === ZoomLevel.QUARTER) && startTime && endTime) {
      const start = dayjs(startTime).format('YYYY-MM');
      const end = dayjs(endTime).format('YYYY-MM');

      if (start === end && minWidth < 90) {
        return `${dayjs(startTime).format(SHORT_DATE_FORMAT)} - ${dayjs(endTime).format('DD')}`;
      }

      return (
        <>
          <span>{dayjs(startTime).format(SHORT_DATE_FORMAT)}</span>
          <span>{dayjs(endTime).format(SHORT_DATE_FORMAT)}</span>
        </>
      );
    } else if (isWeekOrMonth(zoomLevel)) {
      const width = unitWidth;
      let startDate: string | number = formatDate(startTime).date;
      let endDate: string | number = formatDate(endTime).date;

      if (zoomLevel === ZoomLevel.WEEK || zoomLevel === ZoomLevel.BIWEEK) {
        startDate = formatWeekText(
          `${WEEKDAYS_SHORT[formatDate(startTime).day]}  ${formatDate(startTime).date}`
        );
        endDate = formatWeekText(
          `${WEEKDAYS_SHORT[formatDate(endTime).day]}  ${formatDate(endTime).date}`
        );
      }

      return (
        <>
          <div
            className={'inline-flex h-full items-center justify-center text-black'}
            style={{ width }}
          >
            {startDate}
          </div>
          {endDate !== startDate && (
            <div
              className={'inline-flex h-full items-center justify-center text-black'}
              style={{ width }}
            >
              {endDate}
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div
      className={cx(
        'absolute top-1.5 flex h-6 justify-between rounded-xl',
        zoomLevel === ZoomLevel.YEAR || zoomLevel === ZoomLevel.QUARTER
          ? 'bg-grey9 px-1 text-black '
          : 'bg-black_003'
      )}
      style={{
        left,
        minWidth,
      }}
    >
      {renderContent()}
    </div>
  );
};
