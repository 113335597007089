/**
 * 获取最接近视口上方元素的 index
 * @param offset 超出部分的尺寸
 * @param getSize 根据 index 获取尺寸的方法
 * @returns 最接近视口上方元素的 index
 */
export const eatIndex = (offset: number, getSize: (index: number) => number, startIndex = 0) => {
  let acc = 0;
  let index = startIndex;
  while (acc < offset) {
    acc += getSize(index);
    index += 1;
  }
  return index;
};
