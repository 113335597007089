import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { emitter } from '@flowus/common/utils/emitter';

interface Outline {
  title: string;
  bold: boolean;
  italic: boolean;
  color: Uint8ClampedArray;
  dest: string | any[] | null;
  url: string | null;
  unsafeUrl: string | undefined;
  newWindow: boolean | undefined;
  count: number | undefined;
  items: Outline[];
}

export const PDFOutline: FC<{ pdfViewer: PDFViewer }> = ({ pdfViewer }) => {
  const [outlines, setOutline] = useState<Outline[]>([]);
  const [showOutline, setShowOutline] = useState(true);

  useEffect(() => {
    if (!pdfViewer.pdfDocument) return;

    pdfViewer.pdfDocument
      .getOutline()
      .then((outlines) => {
        if (outlines) {
          setOutline(outlines);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [pdfViewer.pdfDocument]);

  useEffect(() => {
    emitter.on('showOutline', (visible) => {
      setShowOutline(visible);
    });
  }, []);

  if (!showOutline) return null;

  return (
    <div className="select-none px-2">
      <OutlineTree outlines={outlines} deep={0} pdfViewer={pdfViewer} />
    </div>
  );
};

const OutlineTree: FC<{ outlines: Outline[]; deep: number; pdfViewer: PDFViewer }> = ({
  outlines,
  deep,
  pdfViewer,
}) => {
  return (
    <div>
      {outlines.map((item) => {
        return (
          <OutlineTreeItem key={item.title} outline={item} pdfViewer={pdfViewer} deep={deep} />
        );
      })}
    </div>
  );
};

const OutlineTreeItem: FC<{ outline: Outline; pdfViewer: PDFViewer; deep: number }> = ({
  outline,
  pdfViewer,
  deep,
}) => {
  const [expand, setExpand] = useState(false);

  const { title, items, dest } = outline;

  const jump = () => {
    if (!dest) return;
    void pdfViewer.linkService.goToDestination(dest);
  };

  const hasChild = items.length > 0;

  const icon = (
    <Icon
      size="middle"
      name={hasChild ? 'IcNaviFold' : 'IcNaviDot'}
      className={cx(expand && 'rotate-90')}
      onClick={(event) => {
        event.stopPropagation();
        setExpand(!expand);
      }}
    />
  );

  return (
    <>
      <div
        className="flex cursor-pointer items-center pl-1 hover:bg-active_color_10 p-2 rounded"
        style={{ paddingLeft: `${deep * 16}px` }}
        onClick={jump}
      >
        {icon}
        <p className="flex-1 text-t2-medium text-ellipsis">{title}</p>
      </div>

      {hasChild && expand && <OutlineTree outlines={items} deep={deep + 1} pdfViewer={pdfViewer} />}
    </>
  );
};
