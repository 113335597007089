export enum DomainStatus {
  INIT = 1,
  CAN_USE = 2,
  CAN_NOT_USE = 3,
  LEN_NOT_RIGHT = 4,
  CHECKING = 5,
}

export const domainErrorMsg = {
  [DomainStatus.INIT]: '',
  [DomainStatus.CAN_USE]: '',
  [DomainStatus.CAN_NOT_USE]: '域名不可用，请更换',
  [DomainStatus.LEN_NOT_RIGHT]: '域名不能超过30字符，至少4个字符',
  [DomainStatus.CHECKING]: '',
};
