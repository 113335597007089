import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useTransaction } from 'src/hooks/use-transaction';
import * as CollectionViewManager from 'src/redux/managers/collection-view';

export const useUpdateDateTime = () => {
  const transaction = useTransaction();
  const { getState } = useStore();

  const updateDateTime = (
    viewId: string,
    dateType: 'startTime' | 'endTime',
    propertyId: string
  ) => {
    const { collectionViews } = getState();
    const view = collectionViews[viewId];
    if (!view) return;

    const isTimeline = view.type === CollectionViewType.TIMELINE;
    const newViewFormat: CollectionViewDTO['format'] = {};
    if (dateType === 'startTime') {
      newViewFormat[isTimeline ? 'timelineBy' : 'calendarBy'] = propertyId;
    }
    if (dateType === 'endTime') {
      newViewFormat[isTimeline ? 'timelineByEnd' : 'calendarByEnd'] = propertyId;
    }
    transaction(() => {
      CollectionViewManager.update(viewId, {
        format: newViewFormat,
      });
    });
  };
  return useCallback(updateDateTime, [getState, transaction]);
};
