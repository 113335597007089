import { MindMappingLineStyle, MindMappingType } from '@next-space/fe-api-idl';
import type { Immutable } from 'immer';
import { createDraft, enablePatches, finishDraft } from 'immer';
import type { Patch, WritableDraft } from 'immer/dist/types/types-external';
import { unstable_batchedUpdates } from 'react-dom';
import { BaseLayoutProvider } from './layout/base-layout-provider';
import { CanvasGraph } from './layout/canvas-graph';
import { LogicDiagramLeftLayoutProvider } from './layout/logic-diagram-left-layout-provider';
import { LogicDiagramRightLayoutProvider } from './layout/logic-diagram-right-layout-provider';
import { MindMapLayoutProvider } from './layout/mind-map-layout-provider';
import { OrganizationLayoutProvider } from './layout/organization-layout-provider';
import type { MindMapState, MindNode, MindNodeDragInfo, ScaleListener } from './types';

enablePatches();
interface Option {
  /**节点之间的水平margin */
  horizontalMargin?: number;
  /**节点之间的垂直margin */
  verticalMargin?: number;
  /**整体线的颜色 */
  lineColor?: string;
  lineWidth?: number;
  getMindNodeHeight?: (id: string) => number;
  getAllElement: () => NodeListOf<Element> | undefined;
  getCanvasPosition: (instance: MindMapEngine, x: number, y: number) => [number, number];
  supportAppendChild: (id: string) => boolean;
  getLineColor?: (colorKey: string, id: string) => string | undefined;
}

const MAX_SCALE = 300;
const MIN_SCALE = 10;
const DEFAULT_VERTICAL_MARGIN = 8;
const DEFAULT_HORIZONTAL_MARGIN = 60;
export const OUT_SIDE_SCREEN = -9999;
const SCALE_LEVEL = [10, 25, 50, 75, 100, 120, 150, 200, 300];

/**
 * 脑图节点坐标的计算规则:
 * 1.计算所有节点的宽高
 * 2.计算所有节点占据的区域高（包含子节点）
 * 3.深度遍历，计算所有叶子节点的top，并保存每个节点的第一个叶子节点(叶子节点是指无children的节点)
 * 4.基于叶子节点的top，计算非叶子节点的坐标
 */
export class MindMapEngine {
  private state: MindMapState = {
    mindNodes: {},
    rootId: '',
    dropInfo: undefined,
    scale: 100,
    offsetX: 0,
    offsetY: 0,
    hoverId: '',
    direction: MindMappingType.LOGIC_DIAGRAM_LEFT,
    loading: true,
    lineStyle: MindMappingLineStyle.NORMAL,
    fixWidthRecord: {}, //这个是否有必要写到core上,让用户自己处理似乎也可以，不过会稍微复杂点?
  };

  option: Option;
  dragInfo: MindNodeDragInfo | undefined;
  private layoutProvider: BaseLayoutProvider | undefined;
  graph: CanvasGraph;
  //叠起收缩,缓存宽高，减少抖动
  cacheSize = {} as Record<string, { width: number; height: number; reallyWidth: number }>;
  scaleListeners: ScaleListener[] = [];
  alignSiblingNode: boolean; //是否对齐同级节点
  //记录每个层级的顺序，用于支持方向键跨级寻找，上下方向键(如果是组织布局，则用于支持左右方向键)
  nodesByLevelOrder: Record<number, string[]> = {};
  nodesByLevelMap: Record<string, number> = {};

  constructor(option: Option) {
    this.option = option;
    this.graph = new CanvasGraph(this);
    this.layoutProvider = new LogicDiagramLeftLayoutProvider(this, {
      verticalMargin: this.option.verticalMargin ?? DEFAULT_VERTICAL_MARGIN,
      horizontalMargin: this.option.horizontalMargin ?? DEFAULT_HORIZONTAL_MARGIN,
    });
    this.alignSiblingNode = false;
  }

  setRootId = (rootId: string) => {
    if (this.state.rootId !== rootId) {
      this.performChange((state) => {
        state.rootId = rootId;
      });
    }
  };

  performChange = (callback: (state: WritableDraft<MindMapState>) => void) => {
    this.beginDraft();
    callback(this.getDraftState());
    this.endDraft();
  };

  tmpAddMindNodes: MindMapState['mindNodes'] | undefined = undefined;
  tmpUpdateMindNodes: Record<string, Partial<MindNode> & Pick<MindNode, 'id'>> | undefined =
    undefined;

  addMindNode = (id: string, parentId?: string) => {
    if (!this.tmpAddMindNodes) {
      this.tmpAddMindNodes = {};
    }
    const tmpMindNode = {
      id,
      left: OUT_SIDE_SCREEN,
      top: OUT_SIDE_SCREEN,
      parentId: parentId ?? '',
      childrenIds: [],
      regionHeight: 0,
      regionWidth: 0,
      childrenHeight: 0,
      childrenWidth: 0,
      leftChildrenHeight: 0,
      leftRegionHeight: 0,
      rightRegionHeight: 0,
      rightChildrenHeight: 0,
      leftRegionWidth: 0,
      rightRegionWidth: 0,
      expanded: true,
      x: 0,
      y: 0,
      width: parentId === this.state.rootId ? 116 : 100, //二级节点默认宽度116，其他节点默认宽度100
      height: 36,
      reallyWidth: 0, //二级节点默认宽度116，其他节点默认宽度100
    };
    const cache = this.cacheSize[id];
    if (cache) {
      tmpMindNode.width = cache.width;
      tmpMindNode.height = cache.height;
      tmpMindNode.reallyWidth = cache.reallyWidth;
    }
    this.tmpAddMindNodes[id] = tmpMindNode;

    const update = () => {
      this.performChange((state) => {
        if (this.tmpAddMindNodes) {
          for (const id of Object.keys(this.tmpAddMindNodes)) {
            const newNode = this.tmpAddMindNodes[id];
            if (newNode) {
              state.mindNodes[id] = newNode;
            }
          }
          this.tmpAddMindNodes = undefined;
          this.layout();
        }
      });
    };
    //由于可能有大量节点需要展示，初始化的时候这里会调用多次，因此用一个timer把节点都存起来，一次性更新（加快加载速度，每次更新会很卡）
    this.addMindNodeTimer && clearTimeout(this.addMindNodeTimer);
    this.addMindNodeTimer = setTimeout(update, 10);
    const { loading } = this.getState();
    if (!loading) return;
    this.loadingTimer && clearTimeout(this.loadingTimer);
    this.loadingTimer = setTimeout(this.setAlreadyDone, 200);
  };
  private setAlreadyDone = () => {
    this.performChange((state) => {
      state.loading = false;
    });
    this.loadingTimer = undefined;
  };

  loadingTimer: ReturnType<typeof setTimeout> | undefined;
  addMindNodeTimer: ReturnType<typeof setTimeout> | undefined;
  updateMindNodeTimer: ReturnType<typeof setTimeout> | undefined;

  updateMindNode = (mindNode: Partial<MindNode> & Pick<MindNode, 'id'>) => {
    let updateTempMindNode = false;
    if (this.tmpAddMindNodes) {
      const oldMindNode = this.tmpAddMindNodes[mindNode.id];
      if (oldMindNode) {
        Object.assign(oldMindNode, mindNode);
        updateTempMindNode = true;
      }
      if (this.loadingTimer) {
        clearTimeout(this.loadingTimer);
        this.loadingTimer = setTimeout(this.setAlreadyDone, 200);
      }
    }
    //如果不是在addMindNode过程的话，直接刷新就行，主要是对addMindNode的优化才这么多逻辑判断
    if (!updateTempMindNode) {
      //再次优化,由于全部折叠/展开会导致updateMindNode频繁调用，因此，跟addMindNode一样搞个timer把节点信息存起来再一次新刷新
      //NOTE: 不确定这个优化会不会带来其他问题，看测试反馈吧
      const update = () => {
        this.performChange((state) => {
          if (this.tmpUpdateMindNodes) {
            for (const id of Object.keys(this.tmpUpdateMindNodes)) {
              const oldMindNode = state.mindNodes[id];
              const updateNode = this.tmpUpdateMindNodes[id];
              if (oldMindNode && updateNode) {
                Object.assign(oldMindNode, updateNode);
              }
            }
            this.tmpUpdateMindNodes = undefined;
            this.layout();
          }
        });
      };
      if (!this.tmpUpdateMindNodes) {
        this.tmpUpdateMindNodes = {};
      }
      if (this.tmpUpdateMindNodes) {
        const old = this.tmpUpdateMindNodes[mindNode.id];
        if (old) {
          Object.assign(old, mindNode);
        } else {
          this.tmpUpdateMindNodes[mindNode.id] = mindNode;
        }
      }
      this.updateMindNodeTimer && clearTimeout(this.updateMindNodeTimer);
      this.updateMindNodeTimer = setTimeout(update, 10);
    }

    const { loading, mindNodes } = this.getState();

    this.cacheSize[mindNode.id] = {
      width: mindNode.width ?? mindNodes[mindNode.id]?.width ?? 0,
      height: mindNode.height ?? mindNodes[mindNode.id]?.width ?? 0,
      reallyWidth: mindNode.reallyWidth ?? mindNodes[mindNode.id]?.reallyWidth ?? 0,
    };
    if (!loading) return;
    this.loadingTimer && clearTimeout(this.loadingTimer);
    this.loadingTimer = setTimeout(this.setAlreadyDone, 200);
  };

  setLineColor = (color: string) => {
    this.option.lineColor = color;
    this.graph.layout();
  };

  deleteMindNode = (id: string) => {
    this.performChange((state) => {
      delete state.mindNodes[id];
    });
  };

  setCanvas = (canvasElement: HTMLCanvasElement) => {
    this.graph?.setCanvas(canvasElement);
  };
  //拖拽时的引导线是在最上层的，因此需要一个canvas铺在上层
  setDragCanvas = (canvasElement: HTMLCanvasElement) => {
    this.graph?.setDragCanvas(canvasElement);
  };

  setContainer = (container: HTMLElement) => {
    this.layoutProvider?.setContainer(container);
  };

  setScroller = (scroller: HTMLDivElement) => {
    this.layoutProvider?.setScroller(scroller);
  };
  destroy = () => {};

  setSize = (width: number, height: number) => {
    this.performChange(() => {
      this.layoutProvider?.setSize(width, height);
      this.layout();
    });
    // this.fitCenter();
  };
  getSize = () => {
    return [this.layoutProvider?.width, this.layoutProvider?.height];
  };

  updateDragInfo = (dragInfo: MindNodeDragInfo | undefined) => {
    this.performChange((state) => {
      this.computeNodesCoordinate();
      this.dragInfo = dragInfo;
      state.dropInfo = this.getDropPosition(dragInfo);
    });
  };

  setHoverId = (hoverId: string | undefined) => {
    if (this.state.hoverId !== hoverId) {
      this.performChange((state) => {
        state.hoverId = hoverId ?? '';
      });
    }
  };
  setAlignSiblingNode = (align: boolean) => {
    this.alignSiblingNode = align;
    this.performChange((state) => {
      state.fixWidthRecord = {};
      this.layout();
    });
  };

  clearDropInfo = () => {
    this.dragInfo = undefined;
    this.performChange((state) => {
      state.dropInfo = undefined;
    });
  };
  /**从dom获取位置x,y */
  computeNodesCoordinate = () => {
    this.performChange(() => {
      this.layoutProvider?.computeNodesCoordinate();
    });
  };

  getDropPosition = (dragInfo: MindNodeDragInfo | undefined) => {
    return this.layoutProvider?.getDropPosition(dragInfo);
  };

  setLineStyle = (lineStyle: MindMappingLineStyle) => {
    this.performChange((state) => {
      state.lineStyle = lineStyle;
    });
  };

  setDirection = (direction: MindMappingType, forceLayout = false) => {
    if (this.state.direction !== direction) {
      let newProvider: BaseLayoutProvider | undefined = undefined;
      switch (direction) {
        case MindMappingType.LOGIC_DIAGRAM_LEFT:
          newProvider = new LogicDiagramLeftLayoutProvider(this, {
            verticalMargin: this.option.verticalMargin ?? DEFAULT_VERTICAL_MARGIN,
            horizontalMargin: this.option.horizontalMargin ?? DEFAULT_HORIZONTAL_MARGIN,
          });
          break;
        case MindMappingType.LOGIC_DIAGRAM_RIGHT:
          newProvider = new LogicDiagramRightLayoutProvider(this, {
            verticalMargin: this.option.verticalMargin ?? DEFAULT_VERTICAL_MARGIN,
            horizontalMargin: this.option.horizontalMargin ?? DEFAULT_HORIZONTAL_MARGIN,
          });
          break;
        case MindMappingType.MIND_MAPPING:
          newProvider = new MindMapLayoutProvider(this, {
            verticalMargin: this.option.verticalMargin ?? DEFAULT_VERTICAL_MARGIN,
            horizontalMargin: this.option.horizontalMargin ?? DEFAULT_HORIZONTAL_MARGIN,
          });
          break;

        case MindMappingType.ORGANIZATION_STRUCTURE:
          newProvider = new OrganizationLayoutProvider(this, {
            verticalMargin: this.option.verticalMargin ?? DEFAULT_VERTICAL_MARGIN,
            horizontalMargin: this.option.horizontalMargin ?? DEFAULT_HORIZONTAL_MARGIN,
          });
          break;
        default:
      }
      if (this.layoutProvider && newProvider) {
        BaseLayoutProvider.copyValueToNewProvider(newProvider, this.layoutProvider);
      }
      if (newProvider) {
        this.layoutProvider = newProvider;
        if (forceLayout) {
          this.performChange((state) => {
            state.direction = direction;
            this.layout();
          });
          void Promise.resolve().then(() => {
            try {
              this.fitCenter();
            } catch {
              //ignore
            }
          });
        }
      }
    }
  };
  /**根据方向键获取下一个id,参考了fei shu */
  getNextNodeIdByArrowKeyboard = (arrow: 'left' | 'right' | 'up' | 'down', currentId: string) => {
    return this.layoutProvider?.getNextNodeIdByArrowKeyboard(arrow, currentId);
  };

  getMindNode = (id: string): Immutable<MindNode> | undefined => {
    if (this.draftState) return this.draftState.mindNodes[id];
    return this.state.mindNodes[id];
  };
  getRootMindNode = (): Immutable<MindNode> => {
    const rootId = this.draftState ? this.draftState.rootId : this.state.rootId;
    const root = this.getMindNode(rootId);
    if (!root) throw Error('root not found');
    return root;
  };
  getDirection = () => {
    if (this.draftState) return this.draftState.direction;
    return this.state.direction;
  };

  layout = () => {
    this.performChange((state) => {
      let oldRegionWidth = 0;
      let oldRegionHeight = 0;
      const rootNode = state.mindNodes[state.rootId];
      if (rootNode) {
        oldRegionWidth = rootNode.regionWidth;
        oldRegionHeight = rootNode.regionHeight;
      }

      this._layout();
      if (rootNode) {
        this.layoutProvider?.updateScrollerPos(oldRegionWidth, oldRegionHeight);
      }
    });
  };
  /**这个方法在scale时能减少计算量 */
  updateScale = () => {
    this.performChange((state) => {
      this.layoutProvider?.updateViewPort();
      this.layoutProvider?.computeOffset();
      let oldRegionWidth = 0;
      let oldRegionHeight = 0;
      const rootNode = state.mindNodes[state.rootId];
      if (rootNode) {
        oldRegionWidth = rootNode.regionWidth;
        oldRegionHeight = rootNode.regionHeight;
      }
      this.layoutProvider?.updateViewPort();
      if (rootNode) {
        this.layoutProvider?.updateScrollerPos(oldRegionWidth, oldRegionHeight);
      }
    });
  };

  fitCenter = () => {
    this.layoutProvider?.fitCenter();
  };

  private _layout = () => {
    this.computeNodesCoordinate();
    this.layoutProvider?.layout(this.state.rootId);
  };

  toggleAllMindNode = (ids: string[], expanded: boolean) => {
    const loop = (state: WritableDraft<MindMapState>, id: string) => {
      const node = state.mindNodes[id];
      if (!node) return;
      node.expanded = expanded;
      node.childrenIds.forEach((id) => loop(state, id));
    };
    this.performChange((state) => {
      ids.forEach((id) => {
        loop(state, id);
      });
      this.layout();
    });
  };

  updateMindNodeSize = (id: string, width: number, height: number) => {
    this.beginDraft();
    this.layoutProvider?.updateMindNodeSize(id, width, height);
    this.layout();
    this.endDraft();
  };

  getState = (): Immutable<MindMapState> => {
    return this.state;
  };

  draftState: WritableDraft<MindMapState> | undefined;
  /**
   * 先使用beginDraft,
   * 中间通过getDraftState获取副本进行修改，
   * 最后调用endDraft更新state
   */
  getDraftState = () => {
    if (this.draftState) return this.draftState;
    throw Error('用法不对,请查看getDraftState注释');
  };

  getLineColor = (nodeId: string) => {
    return this.layoutProvider?.getLineColor(nodeId);
  };

  private callCount = 0;
  beginDraft = () => {
    this.callCount++;
    if (this.draftState) {
      return this.draftState;
    }
    this.draftState = createDraft(this.state);
  };

  endDraft = () => {
    this.callCount--;
    if (!this.draftState) {
      throw Error('用法不对,请查看getDraftState注释');
    }
    let patches: Patch[] = [];
    if (this.callCount === 0) {
      this.state = finishDraft(this.draftState, (p) => {
        patches = p;
      });
      this.draftState = undefined;
      if (patches.length === 0) {
        return;
      }
      this.notifyChange();
      //a little bit optimize
      if (
        patches.length === 1 &&
        patches.some((p) => DO_NOT_NEED_LINE_REDRAW.includes(p.path[0]?.toString() ?? ''))
      ) {
        return;
      }
      this.graph.layout();
    }
  };
  private findSuitableScale = (isZoomIn: boolean) => {
    const { scale } = this.getState();
    let preScale = scale;
    for (const scaleLevel of SCALE_LEVEL) {
      if (isZoomIn) {
        if (scaleLevel > scale) {
          if (isZoomIn) {
            return scaleLevel;
          }
          return preScale;
        }
      } else {
        if (scaleLevel >= scale) {
          if (isZoomIn) {
            return scaleLevel;
          }
          return preScale;
        }
      }
      preScale = scaleLevel;
    }
    return scale;
  };

  zoomin = (delta = -1) => {
    this.performChange((state) => {
      if (delta === -1) {
        const nextScale = this.findSuitableScale(true);
        state.scale = nextScale;
      } else {
        state.scale = Math.min(state.scale + delta, MAX_SCALE);
      }
      this.updateScale();
      // this.layout();
      this.notifyScaleListeners(state.scale);
    });
  };
  zoomout = (delta = -1) => {
    this.performChange((state) => {
      if (delta === -1) {
        const nextScale = this.findSuitableScale(false);
        state.scale = nextScale;
      } else {
        state.scale = Math.max(Math.round(state.scale) - delta, MIN_SCALE);
      }
      this.updateScale();
      // this.layout();
      this.notifyScaleListeners(state.scale);
    });
  };
  zoom = (scale: number) => {
    this.performChange((state) => {
      state.scale = Math.max(MIN_SCALE, Math.min(Math.round(scale), MAX_SCALE));
      this.updateScale();
      // this.layout();
      this.notifyScaleListeners(state.scale);
    });
  };
  dragLines = () => {
    this.layoutProvider?.drawLines();
  };

  notifyChange = () => {
    unstable_batchedUpdates(() => {
      this.callbacks.forEach((callback) => callback());
    });
  };
  private callbacks: Set<Function> = new Set<Function>();

  subscribe = (onStoreChange: () => void) => {
    this.callbacks.add(onStoreChange);

    return () => {
      this.callbacks.delete(onStoreChange);
    };
  };
  getSnapShot = (): MindMapState => {
    return this.state;
  };
  addScaleListener = (listener: ScaleListener) => {
    this.scaleListeners.push(listener);
    return () => this.removeScaleListener(listener);
  };
  removeScaleListener = (listener: ScaleListener) => {
    this.scaleListeners = this.scaleListeners.filter((l) => l !== listener);
  };
  private notifyScaleListeners = (scale: number) => {
    this.scaleListeners.forEach((listener) => listener.onScaleChange(this, scale));
  };
}

const DO_NOT_NEED_LINE_REDRAW = ['hoverId'];
