import { cx } from '@flowus/common/cx';
import { TextType } from '@next-space/fe-api-idl';
import { useMemo, type FC } from 'react';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { useReadonly } from 'src/hooks/page';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { type CellViewProps } from './types';
import { segmentsToText } from 'src/editor/utils/editor';
import { CopyButton } from 'src/bitable/copy-button';

export const RichTextValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const readonly = useReadonly(recordId);
  const isTitle = propertyId === 'title';
  const segments = useObservableBlock(
    recordId,
    (block) => (isTitle ? block?.data.segments : block?.data.collectionProperties?.[propertyId]),
    [propertyId]
  );
  const text = useMemo(() => segmentsToText(segments), [segments]);
  if (
    !segments ||
    segments.length === 0 ||
    (segments.length === 1 && segments[0]?.type === TextType.TEXT && segments[0].text === '')
  ) {
    return null;
  }

  return (
    <div className="p-2 w-full group-scope">
      <RichText
        className={cx('leading-[20px]', className)}
        plugins={isTitle ? TITLE_EDITOR_PLUGINS : undefined}
        segments={segments}
        interactable={readonly}
      />
      <CopyButton text={text} site={site} />
    </div>
  );
};
