import { useEffect, useMemo, useReducer, useRef, createContext as _createContext } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import type { Fn, Provider, ProviderValue } from './types';

const $ALL = Symbol.for('all');

export function createModel<Hook extends Fn>(useHook: Hook) {
  type Props = Hook extends (props: infer P) => any ? P : {};
  type Model = ReturnType<Hook>;

  const Context = createContext<ProviderValue<Model> | undefined>(undefined);

  const Provider: Provider<Props, Model> = ({ children, ...props }) => {
    const $model = useHook(props);
    const model = useMemo(() => $model, []); // eslint-disable-line react-hooks/exhaustive-deps
    const obMap = useMemo(() => new Map<string | symbol, Set<Fn>>(), []);
    const value = useMemo(() => {
      const observe = (key: string | symbol, listener: Fn) => {
        let set = obMap.get(key);
        if (!set) {
          set = new Set();
          obMap.set(key, set);
        }
        set.add(listener);
      };

      const unobserve = (listener: Fn) => obMap.forEach((set) => set.delete(listener));

      const observeAll = (listener: Fn) => {
        observe($ALL, listener);
      };

      return {
        observe,
        unobserve,
        observeAll,
        model,
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const listeners = new Set<Fn>(obMap.get($ALL));
      keys($model).forEach((key) => {
        const newValue = Reflect.get($model, key);
        const oldValue = Reflect.get(model, key);
        // === 不能判断 +0 和 -0 等情况
        // 因此使用 Object.is
        if (!Object.is(oldValue, newValue)) {
          Reflect.set(model, key, newValue);
          obMap.get(key)?.forEach((item) => listeners.add(item));
        }
      });
      listeners.forEach((item) => item());
    }, [$model, model, obMap]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  Provider.Context = Context;
  Provider.displayName = `Model(${useHook.name})`;

  return Provider;
}

/**
 * 使用 redux 或 jotail 代替
 */
export function useModel<P extends Provider>(Provider: P) {
  type Model = P extends Provider<any, infer M> ? M : unknown;

  const context = useContext(Provider.Context);
  if (!context) {
    throw new Error(`useModel 需要在 ${Provider.displayName} 内使用`);
  }

  const { model, observe, unobserve } = context;
  const [, forceUpdate] = useReducer((s) => s + 1, 0);

  // 非渲染周期内的访问不应该被 observe, 例如点击事件里访问 model
  // 通过父组件的 useEffect 是子组件 render 后执行的特点做一个锁
  const enable = useRef(true);
  enable.current = true;
  useEffect(() => {
    enable.current = false;
  });

  useEffect(() => {
    return () => unobserve(forceUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<Model>(
    () => {
      return new Proxy(model, {
        get(target, key) {
          if (enable.current) {
            observe(key, forceUpdate);
          }
          return Reflect.get(target, key);
        },
        set(_, key) {
          // if (__HOST_LOCAL__) {
          //   // eslint-disable-next-line no-console
          //   console.warn(`不要副作用修改 model["${key.toString()}"]`);
          // }
          return false;
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}

export function useModelSelect<
  P extends Provider,
  M extends P extends Provider<any, infer M> ? M : unknown,
  S extends Fn<[M]>,
  E extends (prev: M, next: M) => boolean
>(Provider: P, select: S, equalityFn?: E) {
  const context = useContextSelector(Provider.Context, (state) => state);
  if (!context) {
    throw new Error(`useModelSelect 需要在 ${Provider.displayName} 内使用`);
  }
  const { model, observeAll, unobserve } = context;
  const [state, dispatch] = useReducer((prev) => {
    const next = select(model);
    if (equalityFn?.(prev, next)) {
      return prev;
    }
    return next;
  }, select(model));
  useEffect(() => {
    observeAll(dispatch);
    return () => {
      unobserve(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return state as ReturnType<S>;
}

function keys(obj: object) {
  return [...Object.getOwnPropertyNames(obj), ...Object.getOwnPropertySymbols(obj)];
}

/**
 * @deprecated
 * 使用 redux 或 jotail 代替
 */
export function shallowEqual(objA: any, objB: any) {
  if (Object.is(objA, objB)) return true;

  if (objA == null || typeof objA !== 'object' || objB == null || typeof objB !== 'object') {
    return false;
  }

  const keysA = keys(objA);
  const keysB = keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    const a = keysA[i]!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const b = keysB[i]!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (!Object.is(objA[a], objB[b])) {
      return false;
    }
  }

  return true;
}

export function useIsInContextModel<P extends Provider>(Provider: P) {
  const context = useContext(Provider.Context);
  return !!context;
}
