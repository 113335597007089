import { Highlight } from '@flowus/common/components/hight-light';
import { cx } from '@flowus/common/cx';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { colors } from 'src/colors';
import { Avatar } from 'src/common/components/avatar';
import { UNNAMED_GROUP, UNNAMED_USER } from 'src/common/const';
import type { ShareProps, UserPermission } from 'src/components/share/types';
import { useAllUser } from 'src/hooks/page/use-subscription-data';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { getUserName } from 'src/hooks/user/use-remark-name';
import type { LocalUser } from 'src/redux/reducers/users';
import { useGuestsList } from 'src/services/app/hook';
import { searchUsers } from 'src/utils/search-util';

interface Props extends Pick<ShareProps, 'onChange' | 'selectUser'> {
  filterUserNoInSpace?: boolean;
  searchText?: string;
  showRight?: boolean;
  isForm?: boolean;
  activeIndex?: number;
  permissions: (PermissionDTO & { index?: number })[];
  className?: string;
  showType?: 'onlyGuest' | 'onlySpaceUser' | 'all';
}

export const PageUserList: FC<Props> = ({
  filterUserNoInSpace,
  searchText = '',
  activeIndex,
  permissions,
  showRight,
  selectUser,
  showType,
  className,
}) => {
  const currentSpace = useCurrentSpace();
  const users = useAllUser();
  const guestsList = useGuestsList();
  const spaceUsers = useCurrentSpaceUsers();

  const process = useCallback(
    (permission: PermissionDTO & { index?: number }) => {
      const item: UserPermission = {
        index: permission.index ?? 0,
        type: permission.type,
        role: permission.role,
        isGuest: permission.isGuest,
      };

      if (permission.type === PermissionType.SPACE) {
        item.id = 'space';
        item.backgroundColor = currentSpace.backgroundColor;
        item.name = currentSpace.title;
        item.usersNum = currentSpace.permissions?.length ?? 0;
        item.icon = currentSpace.icon;
        if (searchText) {
          const usersArray = Object.entries(users).map(([_, u]) => u);
          item.userNames = searchUsers(usersArray, searchText).map((u) => getUserName(u.uuid));
        }
      } else if (permission.type === PermissionType.USER && permission.userId) {
        const user = users[permission.userId];
        if (!user) return null;
        // 不是外部协作者 并且 空间成员里没有的 可能是被移除的成员 所以不展示
        if (filterUserNoInSpace && !spaceUsers[user.uuid] && !permission.isGuest) return null;
        item.id = user.uuid;
        item.name = getUserName(user.uuid);
        item.backgroundColor = user.backgroundColor;
        item.icon = { type: 'upload', value: user.avatar };
        item.phone = user.phone;
        item.isGuest = permission.isGuest || (!!searchText && !spaceUsers[user.uuid]);
        item.isGuestInSpace = guestsList.some((g) => g.userId === user.uuid);
      } else if (permission.type === PermissionType.GROUP && permission.groupId) {
        const group = currentSpace.permissionGroups?.find(
          (group) => group.id === permission.groupId
        );
        if (!group) return null;

        item.id = group.id;
        item.name = group.name || UNNAMED_GROUP;
        item.usersNum = group.userIds.length;
        item.icon = group.icon;
        if (searchText) {
          const usersArray = group.userIds.map((id) => users[id]).filter((u) => !!u) as LocalUser[];
          item.userNames = searchUsers(usersArray, searchText).map((u) => getUserName(u.uuid));
        }
      }
      const isGuest =
        permission.type === PermissionType.USER && Boolean(permission.userId) && permission.isGuest;
      switch (showType) {
        case 'all':
          return item;
        case 'onlySpaceUser':
          return isGuest ? null : item;
        case 'onlyGuest':
          return isGuest ? item : null;
        default:
      }
      return item;
    },
    [
      showType,
      currentSpace.backgroundColor,
      currentSpace.title,
      currentSpace.permissions?.length,
      currentSpace.icon,
      currentSpace.permissionGroups,
      searchText,
      users,
      filterUserNoInSpace,
      spaceUsers,
      guestsList,
    ]
  );

  return (
    <div className={cx('flex items-center flex-wrap', className)}>
      {permissions.map((permission) => {
        const item = process(permission);
        if (!item) return null;
        const searchedUsersNum = item.userNames?.length ?? 0;

        const fullRounded = permission.type === PermissionType.USER ? 'rounded-full' : '';

        const hasRole =
          !item.isGuest && !!searchText && item.role && item.role !== PermissionRole.NONE;

        return (
          <div
            className={cx(
              'relative flex items-center justify-between h-10 rounded px-1',
              !hasRole && selectUser && 'cursor-pointer animate-click',
              item.index === activeIndex && 'normal-bg'
            )}
            onClick={() =>
              !hasRole && selectUser && selectUser({ ...permission, name: item.name || item.phone })
            }
            key={item.id}
          >
            {hasRole && (
              <div className="absolute inset-0 bg-white1 opacity-70 cursor-not-allowed"></div>
            )}

            <div className={cx('flex items-center w-full', hasRole && item.role && 'w-60')}>
              <Avatar
                color={item.backgroundColor || colors.grey}
                name={item.name || (item.isGuest ? '客' : UNNAMED_USER)}
                icon={item.icon}
                className={`mr-2 w-[20px] h-[20px] text-white !text-t1 ${fullRounded}`}
                imgClassName={fullRounded}
              />
              <div>
                <div className="flex text-t2">
                  <div className="flex items-center max-w-[160px] text-ellipsis">
                    <div>{item.name || (item.isGuest ? item.phone : UNNAMED_USER)}</div>
                  </div>
                  {item.usersNum !== undefined && (
                    <div className="flex-shrink-0">{`（${item.usersNum} 人）`}</div>
                  )}
                </div>

                {!!searchedUsersNum && (
                  <span className="text-t4 text-grey3 line-clamp-1">
                    <span className="mr-1">包含:</span>
                    {item.userNames?.map((userName, i) => (
                      <span
                        className={cx(i !== searchedUsersNum - 1 && 'mr-1')}
                        key={`${userName}-${i}`}
                      >
                        <Highlight text={userName} search={searchText} />
                        {i !== searchedUsersNum - 1 && ','}
                      </span>
                    ))}
                  </span>
                )}
              </div>
            </div>

            {!showRight && item.isGuest && !item.isGuestInSpace && (
              <div className="text-t4 text-grey3 flex-shrink-0">未加入该空间</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
