import { atom } from 'jotai';
import { createContext, useContext } from 'react';
import type { Template } from 'src/hooks/template/types';

import type { From } from './types';

export const TemplateContext = createContext<From>('quickSetting');
export const useFrom = () => {
  return useContext(TemplateContext);
};

interface SearchResult {
  keyword?: string;
  hasResult?: boolean;
  templates?: Template[];
}

export const searchResultState = atom<SearchResult>({
  hasResult: false,
  templates: [],
});
