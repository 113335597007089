export enum ActivityIds {
  // ActivitiesListType.basicList
  /** 首次创建空间引导 */
  GUIDE_FIRST_TIME_CREATED_SPACE = 'a648640a-a3d9-4335-86d6-eaa03d79b859',
  /** 首次创建页面 */
  GUIDE_FIRST_TIME_CREATED_PAGE = 'd453314a-36f6-1603-8116-28ca9efdd08d',
  /** 首次创建多维表 */
  GUIDE_FIRST_TIME_CREATED_COLLECTION = '9f888320-ae4c-f2b0-a0dd-028153e0e4b3',
  /** 首次创建文件夹 */
  GUIDE_FIRST_TIME_CREATED_FOLDER = '746d9e52-3fa2-7676-91a8-f7e457dd9bfb',
  /** 首次创建简单表格 */
  GUIDE_FIRST_TIME_CREATED_TABLE = '946607c0-beac-041f-fd76-45c5ef2591eb',
  /** 首次上传文件 */
  GUIDE_FIRST_TIME_UPLOAD = '99e2b29e-0429-fe53-0b38-776dd4b6c867',
  /** 首次上传封面图 */
  GUIDE_FIRST_TIME_UPLOAD_COVER = 'bd44fb8f-646d-4187-40f1-c8daa9ccb1ad',
  /** 首次创建分栏 */
  GUIDE_FIRST_TIME_CREATED_COLUMN_LIST = '55db4312-3dfd-79fd-0e7c-e7ef4c977648',
  /** 首次app拖拽 */
  GUIDE_FIRST_TIME_APP_DRAG = 'b640bd28-e14c-4f50-7e52-b2d3b2c58653',
  /** 首次复制模板 */
  GUIDE_FIRST_TIME_COPY_TEMPLATE = '193a8c4b-cec3-74de-16b0-2e35556be323',

  /** 首次使用快捷方式‘/’创建块 */
  GUIDE_FIRST_TIME_SHORTCUT_CREATED = '8f8ddded-5d80-0c15-b5b5-9952c3b922a8',
  /** 首次使用引用页面 */
  GUIDE_FIRST_TIME_LINK_PAGE = '596f293a-5f5d-0536-9c07-aa7b1e5b970d',
  /** 首次看板视图预览 */
  GUIDE_FIRST_TIME_BOARD_VIEW = '2cd872ab-3a29-6d2d-7b42-ed2f1d81d82a',
  /** 首次时间轴视图预览 */
  GUIDE_FIRST_TIME_TIMELINE_VIEW = '4e8e03f5-d2a6-4c12-b05b-321c1a57e72d',
  /** 首次日历视图预览 */
  GUIDE_FIRST_TIME_CALENDAR_VIEW = '3bad6862-9134-47e4-8b3a-9dd2dc093274',
  /** 首次收集表视图预览 */
  GUIDE_FIRST_TIME_COLLECTION_VIEW = 'deb678d3-6742-cdfc-1e61-adc1f153600e',
  /** 首次创建同步块 */
  GUIDE_FIRST_TIME_CREATED_SYNC = 'd2661165-b02a-f819-bff6-c6619db0e33a',
  /** 首次创建模板按钮 */
  GUIDE_FIRST_TIME_CREATED_TEMPLATE = '8c148290-db9a-e791-0430-3cd3e776fa8a',
  /** 首次开启分享 */
  GUIDE_FIRST_TIME_SHARE = '1d767026-0143-98fb-8e54-ed607f91d7a1',
  /** 首次在app上将页面生成长图并分享 */
  GUIDE_FIRST_TIME_APP_PIC_SHARE = '18d24e67-b984-2b59-4e1b-9ee5d24839e3',

  /** 限时收集表活动 */
  LIMITED_TIME_COLLECTION_TABLE = '7869de9c-2f3b-402c-3568-7ab59edbffca',
  /** 限时分享转存活动 */
  LIMITED_TIME_SHARE_COPY = 'd6b61f9c-4d11-b62a-fff1-7e742c28c5fd',

  STANDING_IMPORT = '6a7a0241-65f5-41f5-b7f2-cafc3cb8409e',

  /* 新手入门 */
  NEW_USER_START = 'b7c9dd44-0881-41ec-a759-7094ff22b2c8', // 新手入门
  START_FROM_HERE = 'cd87ff03-0ee4-4f7a-8e91-45ccb8290202', // 从这里开始
  EXPLORE_MORE_FEATURE = '38c7a0bb-d4c8-43b7-bbd5-bc83f1f96ec8', // 探索高阶功能
  USE_FLOWUS_EVERYWHERE = '2414889b-2926-407a-9f05-04c0b8f9fb67', // 随时随地使用 FlowUs
  CREATE_PAGE = 'f799091c-afcf-47ab-a7b6-e2d5bb990dcc', // 创建页面
  CREATE_AND_DRAG_BLOC = '0c9f0d66-eee8-472d-a681-84248923ea60', // 创建并拖动块
  WRITE_AND_EDIT = '3115bbf6-e96f-41b4-93b0-a859738346ef', // 写作和编辑
  CREATE_FILE_BLOCK = '8f56d945-2a36-42df-8348-9deca0381124', // 图像、文件、音视频
  CREATE_FOLDER_BLOCK = '37a1ee22-aebc-4d2d-9089-ea3ffe2fae09', // 创建文件夹
  CREATE_BITABLE_BLOCK = 'f18babc4-a068-41be-a10f-c17a6982a605', // 创建多维表
  CREATE_BITABLE_VIEW = 'be2e0d41-ac83-47a5-9884-197d88d8ec1d', // 多维表视图
  CREATE_SYNC_BLOCK = '2ed6a364-8c33-4a6d-bd94-385029ee562a', // 双向链接、同步块
  COOPERATION_AND_SHARE = '3556aa6b-f53a-4ac3-9338-b74cffe8bb2f', // 协作与公开分享
  EXPORT_PAGE = 'fba1d4b7-e7d6-41a6-89a5-f45ab9af6c90', // 导出页面
  LEARN_WORKSPACE = 'b06459f2-938b-4042-b992-05a8c2c548e7', // 认识你的工作区
  WRITE_BLOG = '6bf4d8f3-7213-4d87-bdd3-5da503caa38b', // 写一篇博客（笔记）
  PLAN = 'ea5b4fcd-2ef9-4e85-a908-a2178910812d', // 记一段变化（规划）
  WIKI = 'd76cde1a-735f-4555-a859-9fee6befef23', // 学一门手艺（WIKI）
  CREATE_SITE = 'a9958a95-afc6-4c35-9e85-c61b3fde6935', // 你的数字工作室（建站）
  LOGIN_IN_MAC_OR_WINDOWS = 'f1b4c928-e762-469c-9e90-8ffcf723a120', // Mac & Windows
  LOGIN_IN_ANDROID_OR_IOS = 'b7c58a5f-b2ab-4379-8171-979e979a50e5', // Android & iOS
  LOGIN_IN_FLOWUS = '7c01f540-49ce-40bd-bdc4-6f02a5a6ed5f', // 用浏览器打开 FlowUs
  USE_WECHAT_CLIP = 'be5f2ab7-6ab2-4c41-a7ac-04dbb028e1b1', // 用微信剪藏到 FlowUs

  /* 新版新手任务 */
  /* 基础任务 */
  CREATE_NEW_PAGE = '97bef546-5ea4-45c1-a0ea-f1b095dfbfea', // 创建一个新页面
  CONVERT_TEXT_BLOCK = '67d716a5-4197-4410-903c-8eaeff17518c', // 「转换」一个文本块类型
  CREATE_TODO_BLOCK = 'f65334a1-152e-4f94-bf97-91f0d3774007', // 快速创建一个「待办事项」
  CREATE_HEADER_BLOCK = 'a2307b14-b4b0-48eb-b052-35c3c257ba89', // 创建「标题块」并设置「页面目录」
  CREATE_QUOTE_BLOCK = '646cd033-46eb-4cba-9078-253528040465', // 「引述」一段印象最深的文字
  CREATE_MARK_BLOCK = '17ade11e-3e17-4cce-ba68-255178caeadb', // 用「着重文字」突出重点
  CREATE_IMAGE_BLOCK = 'c8b745a7-f48f-46a9-8e98-e5e1229f303b', // 输入「/图片」插入图片并添加说明
  CREATE_SIMPLE_TABLE = '61956fe0-7c03-41c8-be9c-636ebcb46a23', // 创建两列三行「简单表格」
  CREATE_BITABLE_EXPLORE = '3d65d673-c124-4d01-8ca8-20c4d9f0a862', // 创建一个多维表探索数据库
  CREATE_DATE_MENTION = '861dccfa-7154-4b02-b368-b2ca3cc6e72c', // 输入「@日期」添加提醒
  MOBILE_SORT_BLOCK = '2ff9c2aa-cdbe-46a5-998d-b2c3d728be44', // 移动端 App长按拖拽「块」调换排序
  CREATE_COLUMNS_BLOCK = '0968ccb9-e1a2-4a58-a96c-baf1f5b1c523', // Web｜PC端  拖拽块实现分栏排版
  CREATE_FOLDER_AND_UPLOAD = 'a1de73e3-56ab-4f16-a07a-4733e23dff38', // 创建「文件夹」页面并上传文件
  COPY_PAGE_FROM_TEMPLATE = '7f32f8fb-8b3c-4b02-b58f-6cdad6466200', // 访问「模板库」并一键拷贝

  /* 进阶任务 */
  CREATE_WEB_BOOKMARK = '09428d57-ef33-4381-80da-a619a4d3c943', // 「剪藏」一篇文章到FlowUs
  CREATE_LINK = '1640a973-431d-4907-9fef-60e71570c695', // 选中文本快速插入「超链接」
  CREATE_REF_PAGE = '711ed60d-f524-4c57-a318-a4860acb5e42', // 输入[[ 或 【【 快速创建「引用页面」
  CREATE_MIND_MAP = 'bc9fe5b5-d903-4d17-89b1-ec343fcc0945', // 创建一个「思维导图」理清思绪
  CREATE_GALLERY_VIEW = '4567bedd-c092-4d37-9db2-6d81dd4a82d7', // 创建「画廊视图」并开启封面
  CREATE_TIMELINE_VIEW = '9c7fbb62-878e-4573-a320-91e5a0388925', // 创建「时间轴视图」管理进程
  CREATE_CALENDAR_VIEW = 'a02a9b57-4745-41e0-b3e7-7f4b214bd194', // 创建「日历视图」进行打卡
  CREATE_FORM_VIEW = '4ac0096b-e2d5-47cd-a0b0-d255985fd32b', // 创建「收集表视图」网罗多面信息
  ADD_TO_FAVORITE = 'e0ee5c10-0fd5-4c86-b2e2-d514b755eae9', // 将常查阅的页面添加到「快速访问」
  CREATE_SYNC_BLOCK_NEW = 'bb67a765-a7f0-442f-9263-377eea2c8082', // 创建「同步块」并粘贴到其他地方
  CREATE_TEMPLATE_BUTTON = 'f1a64622-9365-4700-98e2-84ec192d9210', // 添加「模板按钮」常用块快速应用
  OPEN_SHARE = 'aa1c3568-5018-41be-be93-d655747e79c9', // 一键开启页面「分享」
  CONVERT_TO_TEMPLATE = '7c82410b-8f63-4d0b-a9f0-7be9ed6030bc', // 将常用页面保存为「我的模板」
  MOBILE_SHARE_LONG_PAGE_IMAGE = 'dae39919-242f-44e0-ac97-cff567711c4c', // 移动端App上将页面生成长图并分享
  INVITE_REGISTER = 'd8c323b3-b5b7-4ec4-a219-218f6b80fe08', // 邀请一位好友注册获惊喜积分
}

export const TwoStepTask: Set<string> = new Set([
  ActivityIds.CREATE_HEADER_BLOCK,
  ActivityIds.CREATE_IMAGE_BLOCK,
  ActivityIds.CREATE_FOLDER_AND_UPLOAD,
  ActivityIds.CREATE_GALLERY_VIEW,
  ActivityIds.CREATE_SYNC_BLOCK_NEW,
  ActivityIds.CREATE_DATE_MENTION,
]);
export const FeatGuideTask: Set<string> = new Set([
  ActivityIds.CREATE_HEADER_BLOCK,
  ActivityIds.CREATE_IMAGE_BLOCK,
  ActivityIds.CREATE_BITABLE_EXPLORE,
  ActivityIds.CREATE_GALLERY_VIEW,
  ActivityIds.CREATE_TIMELINE_VIEW,
  ActivityIds.CREATE_CALENDAR_VIEW,
  ActivityIds.CREATE_FORM_VIEW,
  ActivityIds.CREATE_SYNC_BLOCK_NEW,
  ActivityIds.CREATE_TEMPLATE_BUTTON,
]);

export const FeatGuideTaskName: Record<string, string> = {
  [ActivityIds.CREATE_HEADER_BLOCK]: '标题',
  [ActivityIds.CREATE_IMAGE_BLOCK]: '图片',
  [ActivityIds.CREATE_BITABLE_EXPLORE]: '多维表',
  [ActivityIds.CREATE_GALLERY_VIEW]: '画廊视图',
  [ActivityIds.CREATE_TIMELINE_VIEW]: '时间轴视图',
  [ActivityIds.CREATE_CALENDAR_VIEW]: '日历视图',
  [ActivityIds.CREATE_FORM_VIEW]: '表单视图',
  [ActivityIds.CREATE_SYNC_BLOCK_NEW]: '同步块',
  [ActivityIds.CREATE_TEMPLATE_BUTTON]: '模板按钮',
};

export const BasicTask = [
  'a648640a-a3d9-4335-86d6-eaa03d79b859',
  'd453314a-36f6-1603-8116-28ca9efdd08d',
  '9f888320-ae4c-f2b0-a0dd-028153e0e4b3',
  '746d9e52-3fa2-7676-91a8-f7e457dd9bfb',
  '946607c0-beac-041f-fd76-45c5ef2591eb',
  '99e2b29e-0429-fe53-0b38-776dd4b6c867',
  'bd44fb8f-646d-4187-40f1-c8daa9ccb1ad',
  '55db4312-3dfd-79fd-0e7c-e7ef4c977648',
  'b640bd28-e14c-4f50-7e52-b2d3b2c58653',
  '193a8c4b-cec3-74de-16b0-2e35556be323',
];

export const AdvancedTask = [
  '8f8ddded-5d80-0c15-b5b5-9952c3b922a8',
  '596f293a-5f5d-0536-9c07-aa7b1e5b970d',
  '2cd872ab-3a29-6d2d-7b42-ed2f1d81d82a',
  '4e8e03f5-d2a6-4c12-b05b-321c1a57e72d',
  '3bad6862-9134-47e4-8b3a-9dd2dc093274',
  'deb678d3-6742-cdfc-1e61-adc1f153600e',
  'd2661165-b02a-f819-bff6-c6619db0e33a',
  '8c148290-db9a-e791-0430-3cd3e776fa8a',
  '1d767026-0143-98fb-8e54-ed607f91d7a1',
  '18d24e67-b984-2b59-4e1b-9ee5d24839e3',
];
