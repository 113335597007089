import type { FC } from 'react';
import { Button } from 'src/common/components/button';
import { useModel } from 'src/common/create-model';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { SettingModalCommon, SettingProvider } from '../common';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { CommentSetting } from './comment-setting';
import { DateSetting } from './date-setting';
import { FormNotificationSetting } from './form-notification-setting';
import { LinkPage } from './link-page';
import { PageAd } from './page-ad';
import { PageIconSetting } from './page-icon-setting';
import { SpaceAvatar } from './space-avatar';
import { SpaceDomain } from './space-domain';
import { SpaceHome } from './space-home';
import { SpaceName } from './space-name';
import { SpaceSecurity } from './space-security';
import { TemplateRecommendSetting } from './template-recommend-setting';
// import { SpaceSecurity } from './space-security';

/** 空间设置 */
export const SpaceSetting: FC = () => {
  const closeSettingModal = useCloseSettingModal();
  const { spaceSettingState } = useModel(SettingProvider);
  const {
    saveSpaceSetting,
    leavingSpace,
    handleDeleteSpace,
    isLastEditorUser,
    icon,
    setIcon,
    spaceEditor,
    isGuest,
    freeTeam,
  } = spaceSettingState;

  if (!spaceEditor) {
    return (
      <>
        <div className={SettingModalCommon.commonStyles.container}>
          <SettingModalCommon.Header title="空间设置" />

          <div className="text-t3 text-grey3 mt-2">
            {isGuest
              ? '当前为「外部协作者」身份，如需查看更多空间设置，可联系空间管理员将你升级为「空间成员」'
              : '当前为「空间成员」身份，如需查看更多空间设置，可联系空间管理员将你升级为「空间管理员」'}
          </div>

          <Button onClick={leavingSpace} className="text-t2-medium mt-5 ml-auto">
            离开空间
          </Button>
        </div>
        <SettingModalCommon.FooterBar cancel={() => closeSettingModal()} cancelText="关闭" />
      </>
    );
  }

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        <SettingModalCommon.Header title="空间设置" name="空间头像" />
        <SpaceAvatar icon={icon} setIcon={setIcon} />

        <SpaceName />

        {!__PRIVATE__ && <SpaceDomain />}

        {!__PRIVATE__ && <SpaceHome />}

        <DateSetting />
        <FormNotificationSetting />

        {!__PRIVATE__ && <PageAd />}

        <LinkPage />

        {/* <SettingCapacity /> */}
        <CommentSetting />
        <SpaceSecurity />

        <TemplateRecommendSetting />
        <PageIconSetting />

        {/* {!__PRIVATE__ && <FlowUsAISetting />} */}

        <div className="mt-4 flex justify-end">
          {freeTeam && !isLastEditorUser && (
            <Button
              className="mr-5"
              {...bindDataTestId(TestIds.settingSpaceDelete)}
              onClick={leavingSpace}
            >
              离开空间
            </Button>
          )}
          <Button
            {...bindDataTestId(TestIds.settingSpaceDelete)}
            colorType="red"
            className="mb-10"
            onClick={handleDeleteSpace}
          >
            删除空间
          </Button>
        </div>
      </div>

      <SettingModalCommon.FooterBar cancel={() => closeSettingModal()} confirm={saveSpaceSetting} />
    </>
  );
};
