import { useSpacesSelector } from 'src/services/spaces/spaces';

/** 是否拥有外部空间 */
export const useHasExternalSpace = () => {
  return useSpacesSelector((state) => {
    return Object.values(state).some((it) => it.permissions);
  });
};

/** 是否只有外部空间，没有自己的空间 */
export const useOnlyExternalSpace = () => {
  return useSpacesSelector((state) => {
    return Object.values(state).every((it) => !it.permissions);
  });
};
