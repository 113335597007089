import { LRUCache } from 'src/common/utils/lru-cache';

interface LayoutInfo {
  scrollTop: number;
  rightId?: string;
}
const layoutInfoCache = new LRUCache<string, LayoutInfo>(sessionStorage, 'page_layout', 30);

/** 存储页面位置信息，仅当前session存储 */
export const savePageLayoutInfo = (pageId: string, layoutInfo: LayoutInfo) => {
  layoutInfoCache.put(pageId, layoutInfo);
};

export const getPageLayoutInfo = (pageId: string) => {
  return layoutInfoCache.get(pageId);
};
