import { Compare } from '@flowus/common';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

import { BiArrayValueType } from './_abstract';

export class BiFilesType extends BiArrayValueType<SegmentDTO> {
  fromSegments(segments: SegmentDTO[] | undefined): SegmentDTO[] | undefined {
    if (segments === undefined) return undefined;
    const result = segments.filter(
      (it) => it.type === TextType.URL && it.fileStorageType === 'internal'
    );
    if (result.length === 0) return undefined;
    return result;
  }

  toSegments(value: SegmentDTO[]): SegmentDTO[] | undefined {
    return value;
  }

  compareElement(value1: SegmentDTO, value2: SegmentDTO): number {
    return Compare.pinyin(value1.text, value2.text);
  }
}

export const filesType = new BiFilesType();
