import type { Context } from '../../core/context';
import type { IPropertyIdentValueDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum BORDER_STYLE {
  NONE = 0,
  SOLID = 1,
  DASHED = 2,
  DOTTED = 3,
  DOUBLE = 4,
}

const borderStyleForSide = (side: string): IPropertyIdentValueDescriptor<BORDER_STYLE> => ({
  name: `border-${side}-style`,
  initialValue: 'solid',
  prefix: false,
  type: PropertyDescriptorParsingType.IDENT_VALUE,
  parse: (_context: Context, style: string): BORDER_STYLE => {
    // eslint-disable-next-line default-case
    switch (style) {
      case 'none':
        return BORDER_STYLE.NONE;
      case 'dashed':
        return BORDER_STYLE.DASHED;
      case 'dotted':
        return BORDER_STYLE.DOTTED;
      case 'double':
        return BORDER_STYLE.DOUBLE;
    }
    return BORDER_STYLE.SOLID;
  },
});

export const borderTopStyle: IPropertyIdentValueDescriptor<BORDER_STYLE> =
  borderStyleForSide('top');
export const borderRightStyle: IPropertyIdentValueDescriptor<BORDER_STYLE> =
  borderStyleForSide('right');
export const borderBottomStyle: IPropertyIdentValueDescriptor<BORDER_STYLE> =
  borderStyleForSide('bottom');
export const borderLeftStyle: IPropertyIdentValueDescriptor<BORDER_STYLE> =
  borderStyleForSide('left');
