import { BlockType } from '@next-space/fe-api-idl';
import { AdBlockElement } from './plugin/ad';
import { BookmarkBlockElement } from './plugin/bookmark';
import { BulletListBlockElement } from './plugin/bullet-list';
import { CodeBlockElement } from './plugin/code';
import { ColumnItemElement } from './plugin/column-item';
import { ColumnListElement } from './plugin/column-list';
import { DividerBlockElement } from './plugin/divider';
import { EmbedBlockElement } from './plugin/embed';
import { EmbedBitable } from './plugin/embed-bitable';
import { EmbedMindMap } from './plugin/embed-mind-map';
import { EquationBlockElement } from './plugin/equation';
import { FileBlockElement } from './plugin/file';
import { FoldListElement } from './plugin/fold-list';
import { FolderBlockElement } from './plugin/folder';
import { HeaderElement } from './plugin/header';
import { ImageBlockElement } from './plugin/image';
import { MarkElement } from './plugin/mark';
import { OrderListBlockElement } from './plugin/order-list';
import { PageBlockElement } from './plugin/page';
import { QuoteBlockElement } from './plugin/quote';
import { ReferenceBlockElement } from './plugin/reference';
import { SyncBlockElement } from './plugin/sync-block';
import { SyncReferenceBlock } from './plugin/sync-reference-block';
import { TableBlockElement } from './plugin/table';
import { TemplateElement } from './plugin/template';
import { TextareaBlockElement } from './plugin/textarea';
import { TodoBlockElement } from './plugin/todo';
import { ToggleHeaderElement } from './plugin/toggle-header';
import { _setRenderer } from './_renderers';
import { ChartBlockElement } from './plugin/chart';

_setRenderer(BlockType.FILE, FileBlockElement);
_setRenderer(BlockType.FOLD_LIST, FoldListElement);
_setRenderer(BlockType.TOGGLE_HEADER, ToggleHeaderElement);
_setRenderer(BlockType.TEMPLATE, TemplateElement);
_setRenderer(BlockType.HEADER, HeaderElement);
_setRenderer(BlockType.IMAGE, ImageBlockElement);
_setRenderer(BlockType.COLUMN_LIST, ColumnListElement);
_setRenderer(BlockType.COLUMN, ColumnItemElement);
_setRenderer(BlockType.MARK, MarkElement);
_setRenderer(BlockType.ORDER_LIST, OrderListBlockElement);

_setRenderer(BlockType.REFERENCE_COLLECTION_PAGE, PageBlockElement);
_setRenderer(BlockType.COLLECTION_VIEW_PAGE, PageBlockElement);
_setRenderer(BlockType.PAGE, PageBlockElement);

_setRenderer(BlockType.COLLECTION_VIEW, EmbedBitable);
_setRenderer(BlockType.REFERENCE_COLLECTION, EmbedBitable);

_setRenderer(BlockType.QUOTE, QuoteBlockElement);
_setRenderer(BlockType.TEXTAREA, TextareaBlockElement);
_setRenderer(BlockType.DIVIDER, DividerBlockElement);
_setRenderer(BlockType.TODO, TodoBlockElement);
_setRenderer(BlockType.LIST, BulletListBlockElement);
_setRenderer(BlockType.FOLDER, FolderBlockElement);
_setRenderer(BlockType.REFERENCE, ReferenceBlockElement);
_setRenderer(BlockType.BOOKMARK, BookmarkBlockElement);
_setRenderer(BlockType.EMBED, EmbedBlockElement);
_setRenderer(BlockType.EQUATION, EquationBlockElement);
_setRenderer(BlockType.CODE, CodeBlockElement);
_setRenderer(BlockType.EXTERNAL_FILE, FileBlockElement);
_setRenderer(BlockType.TABLE, TableBlockElement);
_setRenderer(BlockType.SYNC_CONTAINER, SyncBlockElement);
_setRenderer(BlockType.SYNC_REFERENCE, SyncReferenceBlock);
_setRenderer(BlockType.MIND_MAPPING_PAGE, PageBlockElement);
_setRenderer(BlockType.MIND_MAPPING, EmbedMindMap);
_setRenderer(BlockType.AD, AdBlockElement);
_setRenderer(BlockType.CHART, ChartBlockElement);
// _setRenderer(BlockType.CHART_PAGRE, ChartBlockElement);
