import type { BlockDataDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { BlockStatus, BlockType, TextType } from '@next-space/fe-api-idl';
import type { toMdast } from 'hast-util-to-mdast';
import { last } from 'lodash-es';
import type { Content, ListContent, PhrasingContent, Root, TableCell, TableRow } from 'mdast';
import { ColorKey } from 'src/colors';
import { findLanguage } from 'src/editor/editor/plugin/code/codemirror-utils';
import { textToSegments } from 'src/editor/utils/editor';
import type { NextBlock } from 'src/redux/types';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { fromMarkdown } from 'src/utils/ast-util';
import { v4 as uuidV4 } from 'uuid';
import { DEFAULT_CODE_LANGUAGE } from '../../plugin/code/const';

export const md2Blocks = (
  plain: string,
  props?: { pageId: string; spaceId: string }
): Record<string, NextBlock> => {
  const mdast = fromMarkdown(plain) as ReturnType<typeof toMdast>;
  const { spaceId = '', pageId = '' } = props ?? {};

  const blocks: Record<string, NextBlock> = {};
  const blockPos: Record<string, string[]> = {};
  const images: { url: string; blockId: string; parentId: string }[] = [];
  // let listStart = 1;

  const createBlock = (
    type: BlockType,
    parentId: string,
    data: BlockDataDTO & { backgroundColor?: string; localUrl?: string } = {}
  ) => {
    const uuid = uuidV4();
    const newBlock = {
      uuid,
      type,
      spaceId,
      parentId,
      textColor: '',
      backgroundColor: data.backgroundColor || '',
      permissions: [],
      subNodes: [],
      data,
      status: BlockStatus.NORMAL,
      version: 0,
    };
    blocks[uuid] = newBlock;
    blockPos[parentId] = (blockPos[parentId] || []).concat(uuid);
    return uuid;
  };

  const loop = (node: Content, uuid?: string, parentId: string = pageId, isFirstList?: boolean) => {
    if (node.type === 'paragraph') {
      if (!isFirstList || !uuid) {
        uuid = createBlock(BlockType.TEXTAREA, parentId);
      }
      node.children.forEach((o) => phrasingLoop(o, uuid as string));
    }

    if (node.type === 'thematicBreak') {
      createBlock(BlockType.DIVIDER, parentId);
    }

    if (node.type === 'heading') {
      uuid = createBlock(BlockType.HEADER, parentId, { level: node.depth });
      node.children.forEach((o) => phrasingLoop(o, uuid as string));
    }

    if (node.type === 'html') {
      // notion 的着重文字特殊处理
      if (/^<aside>/.test(node.value)) {
        const icon = node.value.substring(8, 10) || '';
        uuid = createBlock(BlockType.MARK, parentId, {
          backgroundColor: ColorKey.grey,
          icon: { type: 'emoji', value: icon },
        });
        const markTree = fromMarkdown(node.value.substring(10));
        markTree.children.forEach((o) => loop(o, uuid, parentId));
      } else {
        createBlock(BlockType.TEXTAREA, parentId, {
          segments: [
            {
              type: TextType.TEXT,
              text: node.value,
              enhancer: {},
            },
          ],
        });
      }
    }

    if (node.type === 'blockquote') {
      uuid = createBlock(BlockType.QUOTE, parentId);
      const [firstChild, ...rest] = node.children;
      if (firstChild && firstChild.type === 'paragraph') {
        firstChild.children.forEach((o) => phrasingLoop(o, uuid as string));
        rest.forEach((o) => loop(o, uuid));
      } else {
        node.children.forEach((o) => loop(o, uuid));
      }
    }

    if (node.type === 'list') {
      // if (typeof node.start === 'number') {
      // listStart = node.start;
      // }
      node.children.forEach((o) => listLoop(o, uuid || parentId, node.ordered));
    }

    if (node.type === 'code') {
      const lastCodeLanguage = getLastCodeLanguage();
      createBlock(BlockType.CODE, parentId, {
        format: {
          language: findLanguage(node.lang || lastCodeLanguage || DEFAULT_CODE_LANGUAGE),
        },
        segments: [
          {
            type: TextType.TEXT,
            text: node.value,
            enhancer: {},
          },
        ],
      });
    }

    if (node.type === 'table') {
      if (!uuid) {
        const row = node.children[0] as TableRow;
        const properties = row.children.map(() => uuidV4());
        uuid = createBlock(BlockType.TABLE, parentId, {
          format: {
            tableBlockColumnOrder: properties,
            tableBlockRowHeader: true,
          },
        });
        node.children.forEach((o) => tableRowLoop(o, uuid as string, properties));
      }
    }

    if (node.type === 'math') {
      createBlock(BlockType.EQUATION, parentId, {
        segments: [
          {
            type: TextType.TEXT,
            text: node.value,
            enhancer: {},
          },
        ],
      });
    }
  };

  const tableRowLoop = (node: TableRow, parentId: string, properties: string[]) => {
    const uuid = createBlock(BlockType.TABLE_ROW, parentId);

    node.children.forEach((o, i) => tableCellLoop(o, uuid, properties[i] as string));
  };

  const tableCellLoop = (node: TableCell, uuid: string, propertyId: string) => {
    node.children.forEach((o) => phrasingLoop(o, uuid, undefined, undefined, propertyId));
  };

  const listLoop = (node: ListContent, parentId: string, ordered?: boolean | null) => {
    let uuid: string;
    if (ordered) {
      uuid = createBlock(BlockType.ORDER_LIST, parentId);
    } else {
      if (node.checked === null) {
        uuid = createBlock(BlockType.LIST, parentId);
      } else {
        uuid = createBlock(BlockType.TODO, parentId, {
          checked: node.checked,
        });
      }
    }
    node.children.forEach((o, i) => loop(o, uuid, uuid, i === 0));
  };

  const phrasingLoop = (
    node: PhrasingContent,
    uuid: string,
    enhancer: SegmentDTO['enhancer'] = {},
    url?: string,
    propertyId?: string
  ) => {
    const block = blocks[uuid];
    if (!block) return;

    const setSegment = (segment: SegmentDTO) => {
      if (!segment.text) return;

      if (propertyId) {
        const { collectionProperties } = block.data;
        if (collectionProperties) {
          collectionProperties[propertyId] = (collectionProperties[propertyId] || []).concat(
            segment
          );
        } else {
          block.data.collectionProperties = {
            [propertyId]: [segment],
          };
        }
      } else {
        block.data.segments = (block.data.segments || []).concat(segment);
      }
    };

    const getSegment = () => {
      if (propertyId) {
        const { collectionProperties } = block.data;
        if (collectionProperties) {
          return collectionProperties[propertyId];
        }
      } else {
        return block.data.segments;
      }
    };

    if (node.type === 'text') {
      const segment: SegmentDTO = {
        type: TextType.TEXT,
        text: node.value,
        enhancer,
      };

      if (url) {
        segment.type = TextType.URL;
        segment.url = url;
      }

      setSegment(segment);
    }

    if (node.type === 'break') {
      const segment = last(getSegment());
      if (segment?.text) {
        segment.text += '\n';
      }
    }

    if (node.type === 'strong') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, bold: true }, url, propertyId)
      );
    }

    if (node.type === 'emphasis') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, italic: true }, url, propertyId)
      );
    }

    if (node.type === 'delete') {
      node.children.forEach((o) =>
        phrasingLoop(o, uuid, { ...enhancer, lineThrough: true }, url, propertyId)
      );
    }

    if (node.type === 'inlineCode') {
      const segment: SegmentDTO = {
        type: TextType.TEXT,
        text: node.value,
        enhancer: { ...enhancer, code: true },
      };
      if (url) {
        segment.type = TextType.URL;
        segment.url = url;
      }
      setSegment(segment);
    }

    if (node.type === 'inlineMath') {
      const segment: SegmentDTO = {
        type: TextType.EQUATION,
        text: node.value,
        enhancer,
      };
      setSegment(segment);
    }

    if (node.type === 'link') {
      if (node.children.length) {
        node.children.forEach((o) => phrasingLoop(o, uuid, enhancer, node.url, propertyId));
      } else {
        const segment: SegmentDTO = {
          type: TextType.URL,
          text: node.url,
          url: node.url,
          enhancer,
        };
        setSegment(segment);
      }
    }

    if (node.type === 'html') {
      if (node.value === '<br />') {
        const segment = last(getSegment());
        if (segment?.text) {
          segment.text += '\n';
        }
      } else {
        const segment: SegmentDTO = {
          type: TextType.TEXT,
          text: node.value,
          enhancer: {},
        };
        setSegment(segment);
      }
    }
  };

  if (Array.isArray((mdast as Root).children)) {
    (mdast as Root).children.forEach((o) => loop(o));
  } else {
    loop(mdast as Content);
  }

  for (const block of Object.values(blocks)) {
    if (block.type === BlockType.TEXTAREA) {
      const { segments } = block.data;
      if (segments?.length === 1) {
        const [segment] = segments;
        if (segment) {
          const { type, text } = segment;
          if (type === TextType.TEXT) {
            const match = text.match(/^!\[(.*)\]\((.*)\)$/);
            if (match) {
              block.type = BlockType.FILE;
              block.data = {
                segments: textToSegments(match[1]),
                size: 0,
                extName: 'png',
                display: 'image',
              };
              images.push({ parentId: block.parentId, blockId: block.uuid, url: match[2] ?? '' });
            }
          }
        }
      }
    }
  }

  return blocks;
};
