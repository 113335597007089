import Cookies from 'js-cookie';
import { useFinalValue } from './react-utils';

export const LanguageKey = 'next_lng';

export const LanguageList = [
  {
    title: 'English',
    value: 'en',
  },
  {
    title: 'Русский',
    value: 'ru',
  },
  {
    title: '繁體中文',
    value: 'tc',
  },
  {
    title: '简体中文',
    value: 'zh',
  },
];

export enum Language {
  en = 'en',
  zhHans = 'zhHans',
  zhHant = 'zhHant',
  ja = 'ja',
  ko = 'ko',
  fr = 'fr',
  de = 'de',
  es = 'es',
  ru = 'ru',
}

export const useLanguage = () => {
  const currentLanguage = useFinalValue(() => {
    const lng = getCurrentLanguage();
    return LanguageList.find((i) => i.value === lng) || LanguageList[0]!;
  });

  const changeLanguage = (value: string) => {
    Cookies.set(LanguageKey, value, {
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
  };

  return { currentLanguage, changeLanguage };
};

export const getCurrentLanguage = () => {
  // 获取浏览器设置的首选语言
  // @ts-ignore 旧浏览器
  // const userLanguage = navigator.language || navigator.userLanguage;

  // 处理语言字符串，提取主要语言部分
  const primaryLanguage = String(Cookies.get(LanguageKey));

  return primaryLanguage.trim().toLocaleLowerCase();
};

export const adaptLanguage = (lng: string) => {
  if (['zh-hk', 'zh-mo', 'zh-tw', 'zh-cht', 'zh-hant'].includes(lng.toLowerCase())) {
    //繁体中文
    return Language.zhHant;
  }
  //简体中文
  if (['zh', 'zh-cn'].includes(lng.toLowerCase())) return Language.zhHans;
  return lng;
};
