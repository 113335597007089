import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { CSSProperties, FC } from 'react';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';

export const HeaderTip: FC<{ id: string; className?: string }> = ({ id, className }) => {
  const level = useObservableBlock(id, (block) => {
    if (!block) return;
    if ([BlockType.HEADER, BlockType.TOGGLE_HEADER].includes(block.type)) {
      return block.data.level ?? 1; // 1是默认值，从HeaderElement代码上找来的
    }
  });
  if (!level) return null;
  const style: CSSProperties = {};
  switch (level) {
    case 1:
      style.height = '10px';
      break;
    case 2:
      style.height = '8px';
      break;
    case 3:
      style.height = '8px';
      break;

    default:
  }
  return <div className={cx('text-grey4 pointer-events-none', className)}>{`H${level}`}</div>;
};
