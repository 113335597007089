import type { NextBlock, Share } from '@flowus/common/block/type';
import Immut from 'immutable';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';

export type CacheInstanceKey = object;

export const TOC_CACHES = {
  cache: Immut.Map<string, string[]>(),
  getCache: () => TOC_CACHES.cache,
  getToc: (key: string) => TOC_CACHES.cache.get(key),
  setToc: (key: string, value: string[]) => {
    // TOC_CACHES.cache = TOC_CACHES.cache.set(key, value);
  },
  deleteToc: (key: string) => {
    TOC_CACHES.cache = TOC_CACHES.cache.delete(key);
  },
  clear: () => {
    TOC_CACHES.cache = TOC_CACHES.cache.clear();
  },
};

export const SHARE_CACHES = {
  cache: Immut.Map<string, Share>(),
  getCache: () => SHARE_CACHES.cache,
  getShare: (key: string) => SHARE_CACHES.cache.get(key),
  deleteShare: (key: string) => {
    SHARE_CACHES.cache = SHARE_CACHES.cache.delete(key);
  },
  setShare: (key: string, value: Share) => {
    SHARE_CACHES.cache = SHARE_CACHES.cache.set(key, value);
  },
  clear: () => {
    SHARE_CACHES.cache = SHARE_CACHES.cache.clear();
  },
};

type GetStateType = () => {
  blocks: Record<string, NextBlock>;
  cache?: CacheInstanceKey;
};

// tocCache never changes.
export const tocInvalidate = (pageId: string) => {
  TOC_CACHES.deleteToc(pageId);
};

export const tocInvalidateAll = () => {
  TOC_CACHES.clear();
};

export const tocInvalidateParent = (uuid: string, getState: GetStateType) => {
  const pageId = getOwnerPage(uuid, { blocks: getState().blocks });
  if (pageId != null) {
    tocInvalidate(pageId);
  }
};

export const shareInvalidateAll = () => {
  SHARE_CACHES.clear();
};
