import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, GroupLevel } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { useBitable } from 'src/bitable/context';
import { PersonValueView } from 'src/bitable/table-view/cell/person';
import { TagItem } from 'src/bitable/table-view/cell/select/tag-item';
import { useOpenModal } from 'src/common/components/next-modal';
import { Checkbox } from 'src/components/check-box';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { realGroupName } from 'src/hooks/collection-view/table-groups';
import { useGroupBy, useOpenPageWay } from 'src/hooks/collection-view/use-collection-view';
import { useGroupOption, useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';
import { useOpenPage } from 'src/hooks/page/use-open-page';

import { getGroupSchemaType } from '../bitable-manager/group-list/helper';
import { getGroupLevel } from '../bitable-manager/group-list/utils';
import { ONE_DAY } from '../timeline-view/const';
import { GroupEditor } from './group-editor';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

export enum GroupNameScene {
  GROUP_HEADER = 'group_header',
  GROUP_LIST = 'group_list',
  HIDDEN_GROUP = 'hidden_group',
}

interface Props {
  groupName: string;
  isSubGroup?: boolean;
  scene: GroupNameScene;
}

export const GroupName: FC<Props> = (props) => {
  const { isSubGroup, groupName, scene } = props;
  const { viewId, collectionId, managerReadonly, readonly, isLocked } = useBitable();
  const openWay = useOpenPageWay(viewId);
  const openPage = useOpenPage();

  const openModal = useOpenModal();
  const groupBy = useGroupBy(viewId, isSubGroup);
  const option = useGroupOption(viewId, groupName, isSubGroup);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);

  if (!realGroupName(groupName)) {
    return (
      <>
        <span className="text-t2 mr-0.5">无</span>
        <span className="text-t2-bold text-ellipsis rounded-sm">{groupSchema?.name}</span>
      </>
    );
  }

  let content: ReactNode | undefined;
  const schemaType = groupSchema?.type;

  switch (schemaType) {
    case CollectionSchemaType.CHECKBOX: {
      const checked = formatCheckBoxValue(groupName);
      content = <Checkbox size="large" checked={checked} />;
      break;
    }

    case CollectionSchemaType.SELECT:
    case CollectionSchemaType.MULTI_SELECT: {
      if (option) {
        content = <TagItem color={option.color} value={option.value} label={option.value} />;
      }
      break;
    }

    case CollectionSchemaType.CREATED_BY:
    case CollectionSchemaType.UPDATED_BY:
    case CollectionSchemaType.PERSON:
      content = <PersonValueView size={'default'} userId={groupName} />;
      break;

    case CollectionSchemaType.RELATION:
      content = <InlinePage uuid={groupName} className="mr-2 whitespace-nowrap p-0 leading-none" />;
      break;

    case CollectionSchemaType.NUMBER: {
      if (groupName === 'out_of_range') {
        content = '超出范围';
      } else {
        const range = groupName.split(',');
        content = `${range[0]} 到 ${range[1]}`;
      }
      break;
    }

    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
    case CollectionSchemaType.DATE: {
      const groupLevel = getGroupLevel('date', groupBy?.groupLevel);
      content = getDateShowContent(groupName, groupLevel);
      break;
    }

    case CollectionSchemaType.TITLE:
    case CollectionSchemaType.TEXT:
    case CollectionSchemaType.PHONE:
    case CollectionSchemaType.URL:
    case CollectionSchemaType.EMAIL: {
      content = groupName;
      break;
    }

    case CollectionSchemaType.FORMULA: {
      const formulaTool = getFormulaTool(collectionId);

      const schemaType = formulaTool.getTypeAsCollectionSchemaType(groupBy?.property ?? '');

      let groupLevel = groupBy?.groupLevel;
      if (schemaType === 'text' || schemaType === 'date') {
        groupLevel = getGroupLevel(schemaType, groupLevel);
      }

      if (schemaType === CollectionSchemaType.NUMBER) {
        if (groupName === 'out_of_range') {
          content = '超出范围';
        } else {
          const range = groupName.split(',');
          content = `${range[0]} 到 ${range[1]}`;
        }
      } else if (schemaType === CollectionSchemaType.DATE) {
        content = getDateShowContent(groupName, groupLevel);
      } else if (schemaType === CollectionSchemaType.CHECKBOX) {
        const checked = formatCheckBoxValue(groupName);
        content = <Checkbox size="large" checked={checked} />;
      } else {
        content = groupName;
      }

      break;
    }

    default:
      break;
  }

  const handleClick = (event: React.MouseEvent) => {
    if (managerReadonly || readonly || isLocked) return;
    if (scene === GroupNameScene.GROUP_LIST) return;

    if (groupSchema?.type === CollectionSchemaType.FORMULA) return;
    if (groupSchema?.type === CollectionSchemaType.RELATION) {
      openPage(groupName, {
        forceOpenInRight: event.altKey,
        openWay,
        forceOpenNewTab: event.ctrlKey || event.metaKey,
      });
      return;
    }

    const schemaType = getGroupSchemaType(collectionId, groupBy?.property);
    if (
      (schemaType === 'text' && groupBy?.groupLevel === GroupLevel.EXACT) ||
      schemaType === CollectionSchemaType.SELECT ||
      schemaType === CollectionSchemaType.MULTI_SELECT ||
      schemaType === CollectionSchemaType.PERSON
    ) {
      openModal.dropdown({
        popcorn: event.currentTarget,
        placement: 'bottom',
        content: ({ onCloseModal }) => (
          <GroupEditor isSubGroup={isSubGroup} closeModal={onCloseModal} groupName={groupName} />
        ),
      });
    }
  };

  const groupType = getGroupSchemaType(collectionId, groupBy?.property);
  const canClick =
    (groupType === 'text' &&
      schemaType !== CollectionSchemaType.FORMULA &&
      groupBy?.groupLevel === GroupLevel.EXACT) ||
    schemaType === CollectionSchemaType.RELATION ||
    schemaType === CollectionSchemaType.SELECT ||
    schemaType === CollectionSchemaType.MULTI_SELECT ||
    schemaType === CollectionSchemaType.PERSON;

  if (schemaType === CollectionSchemaType.CHECKBOX) {
    return <>{content}</>;
  }

  return (
    <div
      onClick={handleClick}
      className={cx(
        'text-t2 text-ellipsis',
        scene !== GroupNameScene.GROUP_HEADER && 'w-full',
        scene !== GroupNameScene.GROUP_LIST && 'p-1',
        canClick && scene !== GroupNameScene.GROUP_LIST && 'animate-hover cursor-pointer'
      )}
    >
      {content}
    </div>
  );
};

const DateRelativeMap: Record<string, string> = {
  today: '今天',
  tomorrow: '明天',
  yesterday: '昨天',
  next_7_day: '未来 7 天',
  last_7_day: '过去 7 天',
  last_30_day: '过去 30 天',
  next_30_day: '未来 30 天',
  after_next_30_day: '未来 30 天后',
  before_last_30_day: '过去 30 天前',
};

export const getDateShowContent = (groupName: string, groupLevel?: GroupLevel) => {
  let content = '';

  if (groupLevel === GroupLevel.RELATIVE) {
    content = DateRelativeMap[groupName] ?? '';
  } else if (groupLevel === GroupLevel.DAY) {
    content = dayjs(groupName).format('YYYY年MM月DD日');
  } else if (groupLevel === GroupLevel.WEEK) {
    const lastDay = dayjs(groupName).valueOf() + 6 * ONE_DAY;
    content = [
      dayjs(groupName).format('YYYY年MM月DD日'),
      dayjs(lastDay).format('YYYY年MM月DD日'),
    ].join(' - ');
  } else if (groupLevel === GroupLevel.MONTH) {
    content = dayjs(groupName).format('YYYY年MM月');
  } else if (groupLevel === GroupLevel.YEAR) {
    content = dayjs(groupName).format('YYYY年');
  }

  return content;
};
