import { createAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash-es';

import type { HistoryEffects } from '../middlewares/effectsmap';
import { effectsMap } from '../middlewares/effectsmap';
import type { RootState } from '../types';

/** 开始跟踪历史操作 */
export const HISTORY_START = createAction<{ state?: RootState }>('history/start');

/** 结束跟踪历史操作 */
export const HISTORY_END = createAction('history/end');

/** 撤销 */
export const HISTORY_UNDO = createAction('history/undo');

/** 重做 */
export const HISTORY_REDO = createAction('history/redo');

export const HISTORY_EFFECTS = createAction('history/effects', (effects: HistoryEffects) => {
  const id = uniqueId('history/effects');
  effectsMap.set(id, effects);
  return { payload: id };
});

export const HISTORY_CLEAR = createAction('history/clear');
