/** 获取hover的element节点 */
import { throttle } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { getMouseBlock } from 'src/editor/utils/block';
import { ID_HOVER_MENU } from 'src/hooks/page/use-dnd/helper';
import { useIsCtrlKeyDown, useIsSelecting } from 'src/services/app';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { emitter } from '@flowus/common/utils/emitter';
import { useIsInRight } from 'src/utils/right-utils';
import type { HoverNode } from './type';
import { checkTargetByHtml } from '@flowus/common/dom';

export const useHoverNode = () => {
  const isSelecting = useIsSelecting();
  const isCtrlKeyDown = useIsCtrlKeyDown();
  const [hoverNode, setHoverNode] = useState<HoverNode>();
  const isInRight = useIsInRight();

  const hideHover = useCallback(() => {
    emitter.emit('hoverId');
    setHoverNode(undefined);
  }, []);

  /** BITABLE滚动的时候，隐藏menu */
  useEffect(() => {
    emitter.on('removeHoverMenu', hideHover);
    return () => {
      emitter.off('removeHoverMenu', hideHover);
    };
  }, [hideHover]);

  useEffect(() => {
    const blockContentContainer = querySelectorFromMainContent(
      '.block-content',
      isInRight
    ) as HTMLDivElement | null;

    const hoverContent = querySelectorFromMainContent(
      '.next-space-page',
      isInRight
    ) as HTMLDivElement | null;

    if (!blockContentContainer || isSelecting || isCtrlKeyDown || !hoverContent) {
      hideHover();
      return;
    }

    const mouseMove = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        const check = checkTargetByHtml(event.target, (e) => {
          return e.className.includes('keep-hover-node');
        });
        if (check) return;
      }
      const { blockId, node } = getMouseBlock(event, blockContentContainer);
      let hoverId = blockId;
      let blockNode = node;

      if (node?.dataset.hideHoverMenu) return;

      // 着重块 与 引述块 支持直接 drop 到子, hover 到上面时需往上找有 blockId 的块来确定 hoverMenu
      if (node?.dataset.dropInChild) {
        blockNode = node.closest('[data-block-id]') as HTMLElement | undefined;
      } else if (node?.tagName === 'TD') {
        blockNode = node.parentElement?.closest('[data-block-id]') as HTMLElement | undefined;
        hoverId = blockNode?.dataset.blockId;
      } else if (node?.dataset.mindMapId) {
        // 内嵌思维导图不能hover上去
        return;
      }
      if (hoverId && blockNode?.firstElementChild) {
        if (blockNode.id === ID_HOVER_MENU) return;
        const rect = blockNode.firstElementChild.getBoundingClientRect();
        const style = getComputedStyle(blockNode.firstElementChild);
        const lineHeight = parseInt(style.getPropertyValue('line-height'), 10);
        const paddingTop = parseInt(style.getPropertyValue('padding-top'), 10);

        const parent = blockContentContainer.getBoundingClientRect();
        let offsetLeft = rect.left - parent.left;
        let offsetTop = rect.top - parent.top;

        // table 的 firstElementChild 有 padding
        const paddingLeft = parseInt(style.getPropertyValue('padding-left'), 10);
        if (paddingLeft > 90) {
          offsetLeft += paddingLeft - 2;
          offsetTop += 20;
        }
        if (hoverNode?.id !== hoverId) {
          emitter.emit('hoverId', hoverId);
          setHoverNode({
            id: hoverId,
            node: blockNode,
            lineHeight,
            paddingTop,
            offsetTop,
            offsetLeft,
          });
        }
      } else {
        hideHover();
      }
    };

    const move = throttle(mouseMove, 100);

    hoverContent.addEventListener('mousemove', move);
    hoverContent.addEventListener('keydown', hideHover);

    return () => {
      // mutationObserver.disconnect();
      hoverContent.removeEventListener('mousemove', move);
      hoverContent.removeEventListener('keydown', hideHover);
    };
  }, [hoverNode?.id, hoverNode?.offsetTop, isSelecting, isInRight, hideHover, isCtrlKeyDown]);

  return hoverNode;
};
