import type { IEditorModel } from '@next-space/fe-inlined/types';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useCallback } from 'react';
import { useIsExistModalId, useOpenModal } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';
import { useCloseCreateBlockMenuList } from 'src/redux/managers/ui';
import { setAppUiState } from 'src/services/app';
import { InlineDatePanel } from './inline-panel/inline-date-panel';
import { InlineEmojiPanel } from './inline-panel/inline-emoji-panel';
import { InlineEquationPanel } from './inline-panel/inline-equation-panel';
import { InlineLinkPagePanel } from './inline-panel/inline-link-page-panel';
import { InlinePersonPanel } from './inline-panel/inline-person-panel';
import { useCreateInlinePageSegment } from './inline-panel/use-create-inline-page';
import { InlineContentType } from './types';

export const InlineBlockMenu: FC<{
  onCloseModal: () => void;
  blockId: string;
  inlineType: InlineContentType;
  left: number;
  right: number;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  onGlobalCaptureKeydown?: (ev: KeyboardEvent) => void;
  symbol?: string; // 快捷键
  keyword?: string; // 搜索关键字
}> = ({
  onCloseModal,
  blockId,
  inlineType,
  left,
  right,
  onGlobalCaptureKeydown,
  symbol,
  getEditorModel,
  keyword,
}) => {
  switch (inlineType) {
    case InlineContentType.DATE: {
      return (
        <InlineDatePanel
          getEditorModel={getEditorModel}
          onlyDate={!symbol}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          right={right}
          onGlobalCaptureKeydown={onGlobalCaptureKeydown}
        />
      );
    }
    case InlineContentType.LINK_PAGE: {
      return (
        <InlineLinkPagePanel
          getEditorModel={getEditorModel}
          symbol={symbol}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          right={right}
        />
      );
    }
    case InlineContentType.EQUATION: {
      return (
        <InlineEquationPanel
          getEditorModel={getEditorModel}
          symbol={symbol}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          right={right}
        />
      );
    }
    case InlineContentType.PEOPLE: {
      return (
        <InlinePersonPanel
          getEditorModel={getEditorModel}
          symbol={symbol}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          right={right}
        />
      );
    }
    case InlineContentType.EMOJI: {
      return (
        <InlineEmojiPanel
          getEditorModel={getEditorModel}
          symbol={symbol}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          search={keyword}
          right={right}
        />
      );
    }
    default:
      return (
        <InlineDatePanel
          getEditorModel={getEditorModel}
          onCloseModal={onCloseModal}
          blockId={blockId}
          left={left}
          right={right}
          onGlobalCaptureKeydown={onGlobalCaptureKeydown}
        />
      );
  }
};

export const useOpenInlineContentMenu = () => {
  const openModal = useOpenModal();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();
  const isExistModal = useIsExistModalId(Modals.INLINE_PANEL);
  const createInlinePageSegment = useCreateInlinePageSegment();

  const openInlineMenu = (
    left: number,
    right: number,
    uuid: string,
    inlineType: InlineContentType,
    getEditorModel: (blockId: string) => IEditorModel | undefined,
    symbol?: string,
    keyword?: string
  ) => {
    const editorModel = getEditorModel(uuid);
    if (!editorModel) return;

    const { selection } = editorModel;
    if (!selection) return;
    if (!selection.isCollapsed) return;

    if (isExistModal) {
      // 不要重复弹，这些关键字也是可以赢到搜索上
      return;
    }

    closeCreateBlockMenuList();

    if (inlineType === InlineContentType.PAGE) {
      createInlinePageSegment({
        editorModel,
        blockId: uuid,
        left,
        right,
      });

      return;
    }

    setAppUiState({ $ignoreSlash: true });

    void editorModel.requestFocus(); // slash用鼠标点击行内引用页面块后需要focus光标才能输入搜索

    openModal.dropdown({
      modalId: Modals.INLINE_PANEL,
      popcorn: {
        getBoundingClientRect() {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return editorModel.getBoundingClientRectOfRange(left, left)!;
        },
      },
      closeAfterCallBack: () => {
        setAppUiState({ $ignoreSlash: false });
        if (inlineType === InlineContentType.EMOJI) {
          setAppUiState({ $allowEmojiPanel: false });
        }
      },
      placement: 'bottom-start',
      offset: [0, 10],
      content: ({ onCloseModal }) => {
        return (
          <InlineBlockMenu
            symbol={symbol}
            getEditorModel={getEditorModel}
            onCloseModal={onCloseModal}
            blockId={uuid}
            inlineType={inlineType}
            left={left}
            right={right}
            keyword={keyword}
            onGlobalCaptureKeydown={(event) => {
              if (isHotkey('enter')(event) || isHotkey('shift+enter')(event)) {
                event.stopImmediatePropagation();
                onCloseModal();
              }
            }}
          />
        );
      },
    });
    return true;
  };

  return useCallback(openInlineMenu, [
    closeCreateBlockMenuList,
    createInlinePageSegment,
    isExistModal,
    openModal,
  ]);
};
