import type { Context } from '../../core/context';
import type { IPropertyListDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { isIdentToken } from '../syntax/parser';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum TEXT_DECORATION_LINE {
  NONE = 0,
  UNDERLINE = 1,
  OVERLINE = 2,
  LINE_THROUGH = 3,
  BLINK = 4,
}

export type TextDecorationLine = TEXT_DECORATION_LINE[];

export const textDecorationLine: IPropertyListDescriptor<TextDecorationLine> = {
  name: 'text-decoration-line',
  initialValue: 'none',
  prefix: false,
  type: PropertyDescriptorParsingType.LIST,
  parse: (_context: Context, tokens: CSSValue[]): TextDecorationLine => {
    return tokens
      .filter(isIdentToken)
      .map((token) => {
        // eslint-disable-next-line default-case
        switch (token.value) {
          case 'underline':
            return TEXT_DECORATION_LINE.UNDERLINE;
          case 'overline':
            return TEXT_DECORATION_LINE.OVERLINE;
          case 'line-through':
            return TEXT_DECORATION_LINE.LINE_THROUGH;
          case 'none':
            return TEXT_DECORATION_LINE.BLINK;
        }
        return TEXT_DECORATION_LINE.NONE;
      })
      .filter((line) => line !== TEXT_DECORATION_LINE.NONE);
  },
};
