import { useMemo, type FC } from 'react';
import { usePageMeta } from 'src/hooks/page/use-page-meta';
import { usePickBlock } from 'src/utils/pick-block';
import { ChartPageToolbar } from 'src/editor/component/chart/chart-page-toolbar';
import { ChartTitle } from 'src/editor/component/chart/chart-title';
import { useReadonly } from 'src/hooks/page';
import { useOpenModal } from '@flowus/common/next-modal';
import { ChartConfigPanel } from 'src/editor/component/chart/chart-config-panel';
import { Modals } from '@flowus/shared';
import { checkDataIntegrity } from '@flowus/chart/flowus-chart-util';
import type { BlockDTO } from '@next-space/fe-api-idl';
import { useBlock } from 'src/hooks/block/use-block';

export const ErrorBlocker: FC<{ id: string }> = ({ id, children }) => {
  const block = usePickBlock(id, ['data'], ['format']);
  const readonly = useReadonly(id);
  const pageMeta = usePageMeta(block?.data.format?.chartFormat?.ref?.uuid ?? '');
  const openModal = useOpenModal();
  const collection = useBlock(block?.data.format?.chartFormat?.ref?.uuid);
  const format = block?.data.format?.chartFormat;
  const isDataIntegrity = useMemo(() => {
    if (!format) return true;
    return checkDataIntegrity(collection as BlockDTO, format);
  }, [collection, format]);

  if (pageMeta?.tag === 'NO_PERMISSION') {
    return (
      <div className="w-full h-full relative">
        <ChartTitle id={id} className="z-[11] text-h4 ml-4 mt-2" type="segments" />
        <div className="w-full h-full flex justify-center items-center text-grey">无访问权限</div>
      </div>
    );
  } else if (pageMeta?.tag === 'NOT_FOUND' || !isDataIntegrity) {
    return (
      <div className="w-full h-full relative">
        <ChartTitle id={id} className="z-[11] text-h4 ml-4 mt-2" type="segments" />
        <div className="w-full h-full flex flex-col justify-center items-center ">
          <div className="text-grey">数据源失效</div>
          {!readonly && (
            <div
              className="text-active_color animate-click"
              onClick={() => {
                openModal.modal({
                  autoClose: false,
                  modalId: Modals.CHART_PANEL_ID,
                  className: 'rounded-md',
                  content: () => {
                    return <ChartConfigPanel id={id} />;
                  },
                });
              }}
            >
              重新配置
            </div>
          )}
        </div>
        <ChartPageToolbar
          blockId={id}
          className="z-[12] opacity-0 group-hover:opacity-100 next-modal absolute right-0 top-0"
        />
      </div>
    );
  }
  return <>{children}</>;
};
