import Color from 'color';
import { clamp, throttle } from 'lodash-es';
import { v4 as uuid4 } from 'uuid';

import type { PDFAnnotation, PDFRect, Point, Rect } from '../type';
import { AnnotationType } from '../type';
import { convertPageRectToPDFRect, convertWindowToPage, optimizeClientRects } from '../utils';
import { cropRectArea } from '../utils/crop-rect-area';
import { highlightRange } from '../utils/get-rects-from-range';
import type { CropShape } from '../utils/imagedata-to-image';
import type { PDFViewApplication } from './pdf-viewer-application';

export class RectAnnotationEditor {
  constructor(
    public textDiv: HTMLDivElement,
    public pageNumber: number,
    public application: PDFViewApplication
  ) {
    this.bindEvent();
  }

  getPageView = (pageNumber: number) => {
    if (!this.application.pdfViewer) return;
    return this.application.pdfViewer.getPageView(pageNumber - 1);
  };

  bindEvent = () => {
    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 0) return;
      const annotationType = this.application.getAnnotationType();

      if (annotationType === AnnotationType.RECTANGLE) {
        this.addAreaAnnotation(this.pageNumber, event);
      } else if (
        annotationType === AnnotationType.HIGHLIGHT ||
        annotationType === AnnotationType.UNDERLINE ||
        annotationType === AnnotationType.STRIKETHROUGH ||
        this.application.getAIShowSideBar()
      ) {
        this.addHighlight(event, this.pageNumber);
      }
    };

    this.textDiv.addEventListener('mousedown', handleMouseDown);
  };

  addHighlight = (event: MouseEvent, pageNumber: number) => {
    const pageView = this.getPageView(pageNumber);
    if (!pageView) return;

    // 给上下两页也填充 padding 使得选择顺滑
    [pageNumber - 1, pageNumber + 1].forEach((number) => {
      const pageView = this.getPageView(number);
      if (!pageView) return;
      pageView.textLayer?.textLayerRenderTask?.expandTextDivs(true);
    });

    const mouseDownPos = {
      x: event.clientX,
      y: event.clientY,
    };
    let isMoving = false;
    const { div } = pageView;

    const handleMouseMove = (event: MouseEvent) => {
      if (
        !isMoving &&
        (Math.abs(event.clientX - mouseDownPos.x) > 5 ||
          Math.abs(event.clientY - mouseDownPos.y) > 5)
      ) {
        isMoving = true;
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      if (!isMoving) return;

      [pageNumber - 1, pageNumber + 1].forEach((number) => {
        const pageView = this.getPageView(number);
        if (!pageView) return;
        pageView.textLayer?.textLayerRenderTask?.expandTextDivs(false);
      });

      const selection = window.getSelection();
      if (!selection || selection.isCollapsed) return;
      const range = selection.getRangeAt(0);
      if (!range) return;
      const text = selection.toString().replace(/\n/g, '');

      const pageRect = div.getBoundingClientRect();
      const rects = optimizeClientRects(highlightRange(range), pageRect.width);

      const findPageNumber = (rect: DOMRect, pageNumber: number): number | undefined => {
        const pageView = this.getPageView(pageNumber);
        if (!pageView) return;

        const { div } = pageView;
        const pageRect = div.getBoundingClientRect();

        if (
          rect.x >= pageRect.left &&
          rect.x <= pageRect.right &&
          rect.y >= pageRect.top &&
          rect.y <= pageRect.bottom
        ) {
          return pageNumber;
        }

        if (rect.y <= pageRect.top || rect.x <= pageRect.left) {
          return findPageNumber(rect, pageNumber - 1);
        }

        return findPageNumber(rect, pageNumber + 1);
      };

      const pdfCoordinates: Record<string, PDFRect[]> = {};
      rects.forEach((rect) => {
        const page = findPageNumber(rect, pageNumber);

        if (typeof page !== 'undefined') {
          const pageView = this.getPageView(page);
          if (!pageView) return;

          const { viewport, div } = pageView;
          const pageRect = div.getBoundingClientRect();
          const pageCoordinate = convertWindowToPage(rect, pageRect);

          const [x, y] = viewport.convertToPdfPoint(pageCoordinate.x, pageCoordinate.y);
          const [xMax, yMax] = viewport.convertToPdfPoint(
            pageCoordinate.x + pageCoordinate.width,
            pageCoordinate.y + pageCoordinate.height
          );
          pdfCoordinates[page] = [...(pdfCoordinates[page] ?? []), { x, y, xMax, yMax }];
        }
      });

      const annotationType = this.application.getAnnotationType();
      if (!annotationType || annotationType === AnnotationType.NONE) {
        if (this.application.getAIShowSideBar()) {
          this.application.selectionRange = { pdfRects: pdfCoordinates, text };
        }
        return;
      }

      const annotation: PDFAnnotation = {
        text,
        type: annotationType,
        pageNumber,
        pdfRects: pdfCoordinates,
        uuid: uuid4(),
        color: this.application.getAnnotationColor(),
      };

      this.application.addAnnotation?.(annotation);

      setTimeout(() => {
        window.getSelection()?.removeAllRanges();
      }, 0);
    };

    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
  };

  addAreaAnnotation = (pageNumber: number, event: MouseEvent) => {
    event.preventDefault();

    const pageView = this.getPageView(pageNumber);
    if (!pageView) return;

    const { viewport, div } = pageView;

    const mouseDownPos = { x: event.clientX, y: event.clientY };

    let isMoving = false;
    const selectArea = document.createElement('div');
    selectArea.style.position = 'fixed';
    selectArea.style.zIndex = '99999';
    selectArea.style.background = `${Color(this.application.getAnnotationColor()).alpha(0.4)}`;
    const pageRect = div.getBoundingClientRect();

    const handleMouseMove = (event: MouseEvent) => {
      if (!isMoving) {
        isMoving = true;
        document.body.appendChild(selectArea);
      }

      const left = clamp(Math.min(event.clientX, mouseDownPos.x), pageRect.left, pageRect.right);
      const top = clamp(Math.min(event.clientY, mouseDownPos.y), pageRect.top, pageRect.bottom);
      const width = Math.abs(clamp(event.clientX, pageRect.left, pageRect.right) - mouseDownPos.x);
      const height = Math.abs(clamp(event.clientY, pageRect.top, pageRect.bottom) - mouseDownPos.y);
      selectArea.style.left = `${left}px`;
      selectArea.style.top = `${top}px`;
      selectArea.style.width = `${width}px`;
      selectArea.style.height = `${height}px`;
    };

    const moveEvent = throttle(handleMouseMove, 60);

    const handleMouseUp = async () => {
      document.removeEventListener('mousemove', moveEvent);
      document.removeEventListener('mouseup', handleMouseUp);

      const selectAreaRect = selectArea.getBoundingClientRect();
      selectArea.remove();
      if (selectAreaRect.width < 5 || selectAreaRect.height < 5) return;

      const pageRect = div.getBoundingClientRect();
      const pageCoordinate = convertWindowToPage(selectAreaRect, pageRect);
      const pdfCoordinates = convertPageRectToPDFRect([pageCoordinate], viewport);

      const blobUrl = await this.getBlobUrl(pageNumber, pageCoordinate);
      const annotation: PDFAnnotation = {
        url: blobUrl,
        width: pageCoordinate.width,
        height: pageCoordinate.height,
        type: AnnotationType.RECTANGLE,
        pdfRects: { [pageNumber]: pdfCoordinates },
        pageNumber,
        uuid: uuid4(),
        color: this.application.getAnnotationColor(),
      };

      this.application.addAnnotation?.(annotation);
    };

    document.addEventListener('mousemove', moveEvent);
    document.addEventListener('mouseup', handleMouseUp);
  };

  getBlobUrl = async (pageNumber: number, pageRect: Rect, path?: Point[], shape?: CropShape) => {
    const pageView = this.getPageView(pageNumber);
    if (!pageView) return;

    return cropRectArea(pageView.canvas, pageRect, path, shape);
  };
}
