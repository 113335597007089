import { BlockStatus, BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useTransaction } from 'src/hooks/use-transaction';
import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK, UPDATE_BLOCK } from 'src/redux/actions';
import { dispatch, getState } from 'src/redux/store';

import { useCurrentUser } from '../user';

export const useRestorePage = () => {
  const transaction = useTransaction();
  const currentUser = useCurrentUser();

  const restorePage = async (uuid: string) => {
    const { blocks } = getState();
    const block = blocks[uuid];

    if (!block) {
      return;
    }

    transaction(() => {
      // 更新删除状态
      dispatch(
        UPDATE_BLOCK({
          uuid,
          patch: { updatedAt: Date.now(), status: BlockStatus.NORMAL },
        })
      );

      if (block.status !== BlockStatus.DELETED) {
        // 非已删除状态时才从原来的位置移除
        dispatch(LIST_REMOVE_BLOCK({ uuid, parentId: block.parentId }));
      }

      const parent = blocks[block.parentId];
      let lookup = parent;
      let restoreToRoot = !lookup;

      while (lookup) {
        if (lookup.status === BlockStatus.DELETED) {
          restoreToRoot = true;
          break;
        }

        // @ts-ignore: 未遍历到根可能是中间有彻底删除了的
        const IS_NOT_ROOT = lookup.type !== 'SPACE';

        lookup = blocks[lookup.parentId];

        if (!lookup && IS_NOT_ROOT) {
          restoreToRoot = true;
        }
      }

      if (!restoreToRoot) {
        if (
          parent?.type === BlockType.COLLECTION_VIEW_PAGE &&
          [BlockType.FOLDER, BlockType.COLLECTION_VIEW_PAGE].includes(block.type)
        ) {
          restoreToRoot = true;
        }
      }

      // 父页面被删除, 则移动到跟目录
      if (restoreToRoot) {
        // 如果页面是内嵌多维表, 则转成多维表页面
        if (block.type === BlockType.COLLECTION_VIEW) {
          dispatch(
            UPDATE_BLOCK({
              uuid,
              patch: { type: BlockType.COLLECTION_VIEW_PAGE },
            })
          );
        }
        if (block.type === BlockType.MIND_MAPPING) {
          dispatch(
            UPDATE_BLOCK({
              uuid,
              patch: { type: BlockType.MIND_MAPPING_PAGE },
            })
          );
        }
        // 如果页面是模板记录页面，则转为普通页面
        if (block.isTemplate) {
          dispatch(
            UPDATE_BLOCK({
              uuid,
              patch: { isTemplate: false },
            })
          );
        }
        dispatch(
          UPDATE_BLOCK({
            uuid,
            patch: {
              permissions: [
                {
                  type: PermissionType.USER,
                  userId: currentUser.uuid,
                  role: PermissionRole.EDITOR,
                },
              ],
            },
          })
        );
        dispatch(LIST_AFTER_BLOCK({ uuid, parentId: block.spaceId }));
      } else {
        if (block.isTemplate && parent != null) {
          // 如果页面是模板记录页面，恢复到 `templatePages` 字段下面
          const oldTemplatePages = parent.templatePages ?? [];
          dispatch(
            UPDATE_BLOCK({
              uuid: parent.uuid,
              patch: {
                templatePages: [...oldTemplatePages, block.uuid],
              },
            })
          );
        } else if (parent?.type === BlockType.COLLECTION_VIEW_PAGE) {
          // 如果父节点是多维表, 恢复成一条记录
          const collectionId = parent.uuid;
          dispatch(LIST_AFTER_BLOCK({ uuid, parentId: collectionId }));
        } else {
          // 移动到原来的父页面最后
          dispatch(LIST_AFTER_BLOCK({ uuid, parentId: block.parentId }));
        }
      }
    });
  };

  return useCallback(restorePage, [currentUser.uuid, transaction]);
};
