import type { SegmentDTO } from '@next-space/fe-api-idl';
import {
  CollectionSchemaType,
  CollectionViewType,
  PermissionRole,
  TextType,
} from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import { selectBlockGroupNames } from '../collection-view/table-groups/select-block-group-values';
import { getPermissions } from '../share/use-permissions';
import { useTransaction } from '../use-transaction';
import { getViewFormat } from './get-view-format';

export const useUpdateGroup = () => {
  const transaction = useTransaction();

  const updateGroup = (
    viewId: string,
    oldGroupName: string,
    newGroupName: string,
    isSubGroup?: boolean
  ) => {
    transaction(() => {
      const {
        groups = [],
        subGroups = [],
        groupBy,
        subGroupBy,
        view,
        collection,
      } = getViewFormat(viewId) ?? {};
      if (!view || !collection) return;

      const propertyId = isSubGroup ? subGroupBy?.property : groupBy?.property;
      const oldGroupBy = isSubGroup ? subGroupBy : groupBy;
      const oldGroups = isSubGroup ? subGroups : groups;
      if (!propertyId) return;

      const newGroups = oldGroups.map((group) => {
        return {
          ...group,
          value: group.value === oldGroupName ? newGroupName : group.value,
        };
      });

      // 更新 view format
      const isBoardColumn = view.type === CollectionViewType.BOARD && !isSubGroup;
      CollectionViewManager.update(viewId, {
        format: {
          [isBoardColumn ? 'boardGroups' : 'collectionGroups']: newGroups,
        },
      });

      // 单选 多选
      const propertySchema = collection.data.schema?.[propertyId];

      // 更新所有 record
      const { blocks, users } = getState();
      collection.subNodes.forEach((recordId) => {
        const block = blocks[recordId];
        if (!block) return;

        const { role } = getPermissions(recordId);
        if (role === PermissionRole.READER) return;

        if (!propertySchema || !oldGroupBy) return null;

        const values = selectBlockGroupNames({
          blocks,
          users,
          collectionId: collection.uuid,
          recordId,
          groupProperty: propertyId,
          groupBy: oldGroupBy,
        });

        let newSegments: SegmentDTO[] = [];
        if (values.includes(oldGroupName)) {
          switch (propertySchema.type) {
            case CollectionSchemaType.TITLE: {
              newSegments = textToSegments(newGroupName);
              break;
            }
            case CollectionSchemaType.EMAIL:
            case CollectionSchemaType.PHONE:
            case CollectionSchemaType.URL: {
              newSegments = [
                { type: TextType.URL, url: newGroupName, text: newGroupName, enhancer: {} },
              ];
              break;
            }
            case CollectionSchemaType.PERSON: {
              const segments = block.data.collectionProperties?.[propertyId] ?? [];
              newSegments = segments.map((segment) => {
                return {
                  ...segment,
                  uuid: segment.uuid === oldGroupName ? newGroupName : segment.uuid,
                };
              });
              break;
            }
            case CollectionSchemaType.MULTI_SELECT:
            case CollectionSchemaType.SELECT: {
              const segments = block.data.collectionProperties?.[propertyId] ?? [];
              const newPropertyValue = segmentsToText(segments)
                .split(',')
                .map((text) => (text === oldGroupName ? newGroupName : text))
                .filter((v) => !!v)
                .join(',');

              newSegments = textToSegments(newPropertyValue);
              break;
            }
            default:
              newSegments = textToSegments(newGroupName);
              break;
          }

          if (propertySchema.type === CollectionSchemaType.TITLE) {
            updateBlock(recordId, {
              data: {
                segments: newSegments,
              },
            });
          } else {
            updateBlock(recordId, {
              data: {
                collectionProperties: {
                  ...block.data.collectionProperties,
                  [propertyId]: newSegments,
                },
              },
            });
          }
        }
      });

      if (
        propertySchema?.type === CollectionSchemaType.MULTI_SELECT ||
        propertySchema?.type === CollectionSchemaType.SELECT
      ) {
        const newOptions = propertySchema.options?.map((option) => {
          return {
            ...option,
            value: option.value === oldGroupName ? newGroupName : option.value,
          };
        });

        updateBlock(collection.uuid, {
          data: {
            schema: {
              ...collection.data.schema,
              [propertyId]: {
                ...propertySchema,
                options: newOptions,
              },
            },
          },
        });
      }
    });
  };

  return useCallback(updateGroup, [transaction]);
};
