import { globalResizeObserver } from '@flowus/common/utils/global-resize-observer';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash-es';
import type { RefObject } from 'react';
import { useEffect } from 'react';
import {
  $isLiteSize,
  $pageContentWidth,
  $pageHeight,
  $pageWidth,
} from 'src/views/main/page-doc/context';
import { PAGE_SCOPE } from 'src/views/main/page-states';

interface Size {
  width: number;
  height: number;
}
export const useObserverSize = (
  ref: RefObject<HTMLDivElement>,
  _callback: (size: Size) => void,
  opt?: {
    wait?: number;
  }
) => {
  useEffect(() => {
    if (!ref.current) return;
    const { width, height } = ref.current.getBoundingClientRect();
    _callback({ width, height });

    const callback = debounce((entry: ResizeObserverEntry) => {
      const { offsetWidth, offsetHeight } = entry.target as HTMLElement;
      _callback({ width: offsetWidth, height: offsetHeight });
    }, opt?.wait ?? 200);

    const unobserve = globalResizeObserver.observe(ref.current, callback);

    return unobserve;
  }, [_callback, opt?.wait, ref]);
};

export const useObserverPageSize = (scrollRef: RefObject<HTMLDivElement>) => {
  const setPageWidth = useSetAtom($pageWidth, PAGE_SCOPE);
  const setPageHeight = useSetAtom($pageHeight, PAGE_SCOPE);
  const setIsLiteSize = useSetAtom($isLiteSize, PAGE_SCOPE);

  useObserverSize(scrollRef, (size) => {
    setPageWidth(size.width);
    setPageHeight(size.height);
  });

  useObserverSize(
    scrollRef,
    (size) => {
      setIsLiteSize(size.width <= 860);
    },
    { wait: 0 }
  );
};

export const useObserverPageContentSize = (scrollRef: RefObject<HTMLDivElement>) => {
  const setPageContentWidth = useSetAtom($pageContentWidth, PAGE_SCOPE);

  useObserverSize(scrollRef, (size) => {
    setPageContentWidth(size.width);
  });
};
