import type { CollectionSchemaType } from '@next-space/fe-api-idl/lib/models/CollectionSchemaType';

export interface Option {
  id: string;
  value: string;
  color: string;
}

export interface Col {
  // visible?: boolean;
  // width?: number;
  type: CollectionSchemaType;
  // name: string;
  // property: string;
  // options?: Option[];
  property: string;
  visible: boolean;
  width?: number;
}

export enum PropertyManagePlace {
  PROPERTY_LIST,
  PROPERTY_LIST_TIMELINE_TABLE,
  TABLE_HEADER,
  PAGE_PROPERTIES,
  FORM_PROPERTIES,
}

export enum RecordCreatedFrom {
  TIMELINE_TABLE = 'TIMELINE_TABLE',
  TIMELINE = 'TIMELINE',
}
