import { cx } from '@flowus/common/cx';
import { getFormatUrl } from '@flowus/common/url';
import { last } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { LoadingContainer } from 'src/common/components/loading-container';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { PUBLIC_SHARE } from 'src/common/const';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useFetchPage } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { ViewPath } from 'src/utils';
import { Deferred } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isFormLink, isShareLink } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { HeaderCover } from 'src/views/main/page-doc/header-cover';
import { FormContainer } from '../form-container';
import { PreviewEndPage } from './preview-end-page';

interface EditorFormEndPageProps extends ModalSchema.CloseModal {
  collectionId: string;
  viewId: string;
}
export const EditorFormEndPage: FC<EditorFormEndPageProps> = (props) => {
  const { collectionId, viewId, onCloseModal } = props;
  const view = useCollectionView(viewId);
  const fetchPage = useFetchPage();
  const block = usePickBlock(collectionId, ['data'], ['cover', 'coverPos']);
  const [link, setLink] = useState(view?.format.formEndPage ?? '');
  const [previewId, setPreviewId] = useState('');

  const [loading, setLoading] = useState(true);

  const checkUrl = async () => {
    const deferred = new Deferred<string>();
    // 校验链接，并跳转到下一步预览
    const url = getFormatUrl(link);
    const id = last(url?.pathname.split(ViewPath.share))?.slice(0, 36);
    if (isFormLink(link)) {
      message.error('暂不支持收集表链接');
      return;
    }
    if (!isShareLink(link) || !id) {
      message.error('链接不正确，请填写公开分享链接');
      return;
    }
    // 链接未开启分享
    await fetchPage(id, {
      callback: (res) => {
        if (res.code === 200) {
          if (getPermissions(id).shared) {
            deferred.resolve(id);
            return;
          }
        }
        message.error('链接未开启分享');
        deferred.resolve('');
      },
    }).catch(() => {
      message.error('链接未开启分享');
      deferred.resolve('');
    });
    return deferred.promise;
  };

  const submitLink = async () => {
    if (!link) return;
    const id = await checkUrl();
    if (id) {
      setPreviewId(id);
    }
  };

  const saveLink = async () => {
    if (link) {
      const id = await checkUrl();
      if (id) {
        bizTracker.event('end_page');
        updateViewFormat(viewId, { formEndPage: link });
      }
    } else {
      updateViewFormat(viewId, { formEndPage: link });
    }

    onCloseModal();
  };

  const resetEndPage = () => {
    if (view?.format.formEndPage) {
      updateViewFormat(viewId, { formEndPage: undefined });
      message.success('设置成功');
    }
    setPreviewId('');
    setLink('');
  };

  useEffect(() => {
    if (view?.format.formEndPage) {
      void submitLink().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!block) return null;
  if (loading) return <LoadingContainer />;

  const settingLink = (
    <div className="pt-14 px-24 mx-auto h-full flex flex-col text-left">
      <div className="text-h3">支持在结束页嵌入分享页面，用于提交后的信息展示</div>
      <div className="mt-10 text-t2">{`1. 自行撰写内容  2.开启“${PUBLIC_SHARE}”并粘贴至下方`}</div>
      <Input
        autoFocus
        value={link}
        onChange={setLink}
        className="h-8 w-full mt-7"
        placeholder="粘贴分享链接"
        addonAfter={
          <div
            onClick={submitLink}
            className={cx(
              'w-24 h-full flex items-center justify-center border-l bg-white2 rounded-l-none',
              !link && 'cursor-not-allowed',
              link && 'animate-hover animate-click'
            )}
          >
            预览
          </div>
        }
      />
    </div>
  );

  return (
    <div className="flex flex-col h-full relative">
      {/* Header */}
      <div className="w-full flex items-center justify-between h-14 px-4 text-t1-medium bg-white2 flex-shrink-0 sticky top-0 left-0 z-20">
        <div className="text-t2-medium">结束页设计</div>
        <div className="flex">
          <Button colorType="base_white" className="mr-2" onClick={() => onCloseModal()}>
            取消
          </Button>
          <Button colorType="active" onClick={saveLink}>
            完成
          </Button>
        </div>
      </div>
      <div className="flex absolute top-16 right-4 z-50">
        {link && (
          <Button colorType="base_white" className="mr-4" onClick={resetEndPage}>
            恢复默认
          </Button>
        )}
        {previewId && (
          <Button colorType="base_white" onClick={() => setPreviewId('')}>
            修改链接
          </Button>
        )}
      </div>
      <FormContainer
        className={'h-[60vh] flex px-0'}
        containerClassName={'pb-20'}
        header={
          <HeaderCover
            uuid={block.uuid}
            ossName={block.data.cover || 'cover/flowus2'}
            hiddenEditorButton
            height={320}
          />
        }
        hiddenFooter
      >
        {previewId ? <PreviewEndPage uuid={previewId} /> : settingLink}
      </FormContainer>
    </div>
  );
};

export const useOpenFormEndPageSetting = () => {
  const openModal = useOpenModal();

  const openFormEndPageSetting = (params: { collectionId: string; viewId: string }) => {
    openModal.modal({
      className: 'w-[95vw] h-[95vh]',
      content: (_) => (
        <EditorFormEndPage
          viewId={params.viewId}
          collectionId={params.collectionId}
          onCloseModal={_.onCloseModal}
        />
      ),
    });
  };
  return useCallback(openFormEndPageSetting, [openModal]);
};
