import { FloatingOverlay } from '@floating-ui/react-dom-interactions';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import type { ModalSchema } from '.';
import { useNextModalConfig } from '.';
import { cx } from '../cx';
import { globalListenerHelper, PRIORITY_DIALOG } from '../hooks/global-listener-helper';
import { useCloseModal } from './hooks/use-close-modal';
import './style.css';

// #region modal
export const RenderModal: FC<ModalSchema.RenderModal> = (props) => {
  const {
    content: Content,
    modalId,
    className,
    center = true,
    autoClose = true,
    keyboard = true,
    containerClassName,
    closeAfterCallBack,
    closeBeforeCallBackForClickOutSide, // 点击对话框外面以及esc时会回调
    noAnimation = false,
    disableDefaultStyle,
  } = props;
  const config = useNextModalConfig();
  const closeModal = useCloseModal();
  const onCloseModal = useCallback<ModalSchema.CloseModalType>(
    (opts) => {
      if (modalId) closeModal(modalId, opts);
    },
    [closeModal, modalId]
  );

  const onEscDown = useCallback(
    (event: KeyboardEvent) => {
      if (isHotkey('esc')(event)) {
        event.stopPropagation();
        event.preventDefault();
        if (!autoClose) return;
        closeBeforeCallBackForClickOutSide?.();
        onCloseModal();
        closeAfterCallBack?.();
      }
    },
    [autoClose, closeBeforeCallBackForClickOutSide, onCloseModal, closeAfterCallBack]
  );

  useEffect(() => {
    if (keyboard) {
      globalListenerHelper.addEventListener('keydown', onEscDown, PRIORITY_DIALOG, true);
    }
    return () => {
      if (keyboard) {
        globalListenerHelper.removeEventListener('keydown', onEscDown, true);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FloatingOverlay
      id="modalContent"
      className={cx(
        'next-modal-modal fixed inset-0 flex justify-center w-screen h-screen mask animate__animated animate__fadeIn bg-black/20 dark:bg-black-base/50',
        center ? 'items-center' : 'items-start pt-[calc(25vh)]',
        config?.maskClassName,
        containerClassName
      )}
      data-modal-id={modalId}
      style={{ animationDuration: '0.2s' }}
      onContextMenuCapture={(e) => e.preventDefault()}
      onClick={(event) => {
        event.stopPropagation();
        if (!autoClose) return;
        closeBeforeCallBackForClickOutSide?.();
        onCloseModal();
        closeAfterCallBack?.();
      }}
    >
      <div
        className={cx(
          !disableDefaultStyle && 'next-modal-scroll',
          !noAnimation && !config?.noAnimation && 'enlarge',
          config?.modalClassName,
          className
        )}
        onClick={(e) => e.stopPropagation()}
        style={{ animationDuration: '0.2s' }}
      >
        <Content onCloseModal={onCloseModal} />
      </div>
    </FloatingOverlay>
  );
};
// #endregion
