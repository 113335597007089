import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { deepEqual } from '@flowus/common/utils/tools';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import type { HeaderItem } from './type';

interface ItemProps {
  jumpTop: (blockId: string) => void;
  active: boolean;
  isLiteSize: boolean;
  onToggle: (uuid: string) => void;
}

// 不要subnodes，会memo失效
export const RenderItem: FC<ItemProps & Omit<HeaderItem, 'subNodes'>> = memo((props) => {
  return props.isLiteSize ? <LiteItem {...props} /> : <DefaultItem {...props} />;
}, deepEqual);

const RenderContent: FC<{ className?: string } & Pick<HeaderItem, 'title' | 'level' | 'uuid'>> =
  memo((props) => {
    // 不需要use，因为props.text会更新下过来
    const block = cache.blocks[props.uuid];
    const segments = block?.data.segments;
    if (segments && segmentsToText(segments)) {
      return (
        <RichText
          className={props.className}
          segments={segments}
          interactable={false}
          plugins={TITLE_EDITOR_PLUGINS}
        />
      );
    }

    return (
      <>
        {props.title}
        {props.level}
      </>
    );
  }, deepEqual);

// 设计图：https://www.figma.com/file/w7uywk3fqYQ656YNUoneeo/Web_Visual_%E6%96%87%E6%A1%A3%2F%E5%85%A8%E5%B1%80%E9%A1%B5%E9%9D%A2?node-id=28298%3A178174
/** 普通侧边栏 */
const DefaultItem: FC<ItemProps & Omit<HeaderItem, 'subNodes'>> = memo((props) => {
  const { onToggle, jumpTop, active, text, uuid, paddingLeft, deep, subCount, closed } = props;

  return (
    <div className="flex items-center" style={{ paddingLeft }}>
      <div
        onClick={() => onToggle(uuid)}
        className={cx('flex items-center', subCount ? 'animate-hover animate-click' : 'opacity-0')}
      >
        <Icon
          name="IcNaviFold"
          size="middle"
          className={cx('text-grey3 transition-all', { 'rotate-90': !closed })}
        />
      </div>

      <Tooltip
        offset={[0, paddingLeft + 32]}
        placement="right"
        popup={<RenderContent {...props} />}
        data-menu-id={uuid}
        onClick={() => {
          bizTracker.event('float_catalog_clickcatalog');
          jumpTop(uuid);
        }}
        className={cx(
          'flex items-center py-1.5 rounded cursor-pointer animate-hover animate-click',
          active && 'bg-black_006'
        )}
        style={{ width: `calc(100% - ${20}px)` }}
      >
        <div
          className={cx('w-full min-w-0 px-1.5 text-ellipsis', {
            'text-grey4': !text,
            'text-t1-medium': deep === 0,
            'text-t2-medium': deep === 2 || deep === 1,
            'text-t2': deep === 3,
          })}
        >
          <RenderContent {...props} className="text-ellipsis" />
        </div>
      </Tooltip>
    </div>
  );
}, deepEqual);

/** 精简版侧边栏 */
const LiteItem: FC<ItemProps & Omit<HeaderItem, 'subNodes'>> = memo((props) => {
  const { uuid, active, jumpTop } = props;

  return (
    <div data-menu-id={uuid}>
      <div
        data-menu-id={uuid}
        className="w-[30px] h-[30px] flex items-center justify-center animate-hover rounded"
        data-id={uuid}
        onClick={() => {
          bizTracker.event('float_catalog_clickcatalog');
          jumpTop(uuid);
        }}
      >
        <div
          className={cx(
            'w-2.5 h-2.5 cursor-pointer rounded-full',
            active ? 'bg-black' : 'bg-grey4'
          )}
        />
      </div>
    </div>
  );
}, deepEqual);
