import { LOCAL_KEY_FOLDER_VIEW_TYPE_LIST } from 'src/common/const';
import { FolderViewType } from 'src/services/app';
import { setAppUiState } from 'src/services/app/hook';
import { setLocalStorage } from 'src/utils/local-storage';
import { getLocalFolderViewTypeList } from './get-local-folder-view-type-list';

export type LocalFolderViewType = Record<string, FolderViewType>;

export const useChangeFolderViewType = (uuid: string) => {
  return (type: FolderViewType) => {
    const localList = getLocalFolderViewTypeList();

    if (type === FolderViewType.TABLE) {
      delete localList[uuid];
    } else {
      localList[uuid] = type;
    }

    setLocalStorage(LOCAL_KEY_FOLDER_VIEW_TYPE_LIST, JSON.stringify(localList));
    setAppUiState({ $folderListViewTypeList: localList });
  };
};
