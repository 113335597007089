import { CodePreviewFormat } from '../const';

export const codePreviewEnum = {
  [CodePreviewFormat.splitView]: '默认',
  [CodePreviewFormat.code]: '仅代码',
  [CodePreviewFormat.preview]: '仅预览',
};

const whiteList = ['mermaid'];

export const codePreviewOptions = Object.entries(CodePreviewFormat).map(([_, value]) => ({
  title: codePreviewEnum[value],
  value,
}));

export const codeLanguageCanView = (
  language = '',
  viewMode: string = CodePreviewFormat.splitView
) => {
  let button = true;
  let preview = true;
  let code = true;

  if (viewMode === CodePreviewFormat.code) {
    preview = false;
  }

  if (viewMode === CodePreviewFormat.preview) {
    code = false;
  }

  if (!whiteList.includes(language.toLocaleLowerCase())) {
    button = false;
    preview = false;
  }

  return {
    button,
    preview,
    code,
  };
};
