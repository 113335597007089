import React, { useMemo } from 'react';
import { Latex } from 'src/editor/component/latex';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';

export const EquationNode: MindNodeElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['data', 'local'], ['segments']);
  const transaction = useTransaction();
  const readonly = useReadonly(props.id);
  const text = useMemo(() => segmentsToText(block?.data.segments), [block?.data.segments]);
  useDoneLocalStatus({ uuid: props.id });

  if (!block) return null;

  return (
    <NodeWrapper id={props.id} className="min-w-[112px] px-2" level={props.level}>
      <Latex
        readonly={readonly}
        content={text}
        onChange={(content) => {
          const segments = textToSegments(content);
          transaction(() => {
            updateBlock(props.id, { data: { segments } });
          });
        }}
        defaultOpenPanel={Boolean(block.local)}
      />
    </NodeWrapper>
  );
});
