import { useCallback } from 'react';
import { cache } from 'src/redux/store';
import { SpacePlanType } from 'src/redux/types';
import { useGetSyncService } from 'src/services/sync';
import { $currentUserCache } from 'src/services/user/current-user';
import { judgeSharePage } from 'src/utils/getPageId';
import { getCurrentSpace } from '../space';

export const useSendPresenceData = () => {
  const sync = useGetSyncService();
  return useCallback(
    (pageId: string, blockId?: string, isPresent = true) => {
      if (judgeSharePage()) return;
      const { planType } = getCurrentSpace();
      // 个人版的就不发头像位置了
      if (planType === SpacePlanType.freePersonal || planType === SpacePlanType.personal) return;
      const now = Date.now();
      if (!isPresent) {
        sync.sendPresenceData($currentUserCache.uuid, pageId, {
          present: false,
          blockId,
          activityTime: now,
        });
        return;
      }
      if (!blockId) {
        sync.sendPresenceData($currentUserCache.uuid, pageId, {
          present: true,
          blockId: '',
          activityTime: now,
        });
        // 当前在线但没编辑任何block
        return;
      }

      if (!cache.blocks[blockId]) {
        // 多维表title cell editor出现后再点击的时候，blockId是fake出来的，因此不需要发位置信息
        return;
      }

      sync.sendPresenceData($currentUserCache.uuid, pageId, {
        present: true,
        blockId,
        activityTime: now,
      });
    },
    [sync]
  );
};
