import { fastEqual } from '@flowus/common/utils/tools';
import { debounce, throttle } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector as reduxSelector, useStore } from 'react-redux';
import type { RootState } from 'src/redux/types';
import { createContext } from 'use-context-selector';
import { create } from 'zustand';
import { useFineSelector as fineSelector } from './use-fine-context-selector';

export const initStoreState: RootState = {
  blocks: {},
  collectionViews: {},
  comments: {},
  discussions: {},
  simpleTable: {},
  ui: {
    selectedBlocks: [],
    selectedCells: [],
  },
  users: {},
  cache: {},
};

export const defaultStore = create<RootState>(() => initStoreState);

export const StoreContext = createContext({ $state: defaultStore, isInit: false, once: false });

export interface StoreContextProps {
  wait?: number;
  waitMode?: 'throttle' | 'debounce';
  extendsStore?: boolean;
  getWait?: () => { wait: number; waitMode: 'throttle' | 'debounce' };
  once?: boolean;
}

export const useStoreContext = (props: StoreContextProps) => {
  const reduxStore = useStore();

  const createProxyState = useCallback(() => {
    const __state = new Proxy({} as RootState, {
      get: (_, key) => {
        return reduxStore.getState()[key as keyof RootState];
      },
    });
    return __state;
  }, [reduxStore]);

  const { wait = 0, waitMode = 'throttle', extendsStore } = props;

  const state = useRef(create<RootState>(() => createProxyState()));

  const selector = useMemo(() => {
    const updateStore = () => {
      const _state = createProxyState();
      state.current.setState(_state, true);
    };

    if (!wait) {
      return updateStore;
    }

    const waitFn = waitMode === 'throttle' ? throttle : debounce;

    return waitFn(updateStore, wait, {
      leading: true,
      trailing: true,
    });
  }, [createProxyState, wait, waitMode]);

  if (extendsStore) {
    fineSelector(selector, { equalityFn: fastEqual });
  } else {
    reduxSelector(selector, fastEqual);
  }

  return { state: state.current, isInit: true };
};
