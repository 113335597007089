import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { SearchLoading } from 'src/common/components/list-view/search-loading';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { segmentsToText } from 'src/editor/utils/editor';
import type { SearchInfo } from 'src/hooks/drive/use-search-block';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import type { FileType, SearchItem, SearchType } from '../search';
import { useSearchUI } from '../search/use-search-ui';

interface Props {
  onSelect(pageInfo: SearchItem): void;
  keyword: string;
  defaultValue?: SearchItem[];
  placeholder?: string;
  searchType?: SearchType;
  source?: SearchInfo['source'];
  parentId?: string;
  canSearchWithEmpty?: boolean;
  filterItems?: string[];
  fileType?: FileType;
  spaceId?: string;
}
/** 搜索结果 */
export const ResultList: FC<Props> = (props) => {
  const { filterItems = [] } = props;
  const { resultList, loading, hasMore, loadMore, onInputChange, firstTime } = useSearchUI({
    defaultList: props.defaultValue,
    type: props.searchType,
    source: props.source,
    parentId: props.parentId,
    canSearchWithEmpty: props.canSearchWithEmpty,
    fileType: props.fileType,
    spaceId: props.spaceId,
  });

  useEffect(() => {
    onInputChange(props.keyword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.keyword]);

  useEffect(() => {
    if (props.canSearchWithEmpty) {
      onInputChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.canSearchWithEmpty]);

  const handleMenuItemClick = (_: unknown, index: number) => {
    const pageInfo = resultList[index];
    if (pageInfo) {
      props.onSelect?.(pageInfo);
    }
  };

  const items = useMemo(() => {
    return resultList
      .map((searchItem: SearchItem) => ({
        type: ListItemType.SEARCH,
        data: {
          uuid: searchItem.uuid,
          title:
            segmentsToText(searchItem.data.segments) ||
            searchItem.title ||
            getUntitledName(searchItem.type),
          avatarTitle: searchItem.avatarTitle,
          icon: searchItem.data.icon,
          navs: searchItem.navs,
          type: searchItem.type,
          backgroundColor: searchItem.backgroundColor,
          ...bindDataTestId(TestIds.moveToItem),
        },
      }))
      .filter((item) => {
        return !filterItems.includes(item.data.uuid);
      });
  }, [filterItems, resultList]);

  return (
    <div className={'relative h-[365px]'}>
      <ListView
        items={items}
        hasMore={hasMore}
        loadMore={loadMore}
        scrollContainerStyle={{ top: '8px' }}
        onItemClick={handleMenuItemClick}
        loadingView={loading && <SearchLoading />}
        customFooter={
          <>
            {loading && hasMore && (
              <div className="relative flex flex-row items-center justify-center h-10">
                <LoadingIcon size="middle" />
                <div className="flex items-center ml-2 text-center text-t2 text-grey4">
                  正在加载...
                </div>
              </div>
            )}
            {!firstTime && !loading && items.length === 0 && (
              <div className="relative flex justify-center items-center h-10 text-t2 text-grey4">
                没有找到相关结果
              </div>
            )}
          </>
        }
      />
    </div>
  );
};
