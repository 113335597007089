import { useMindMapEngine } from '@flowus/mind-map';
import type { FC } from 'react';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';

/** 拖拽时用得到 */
export const DragRenderer: FC<{}> = () => {
  const engine = useMindMapEngine();
  const isDragging = useIsDragging();
  if (!isDragging) return null;

  return (
    <canvas
      className="absolute left-[-150px] top-[-150px] touch-none"
      ref={(ref) => {
        if (ref) {
          engine.setDragCanvas(ref);
        }
      }}
    />
  );
};
