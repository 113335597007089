import { Role, emitter, getFileNameInfo } from '@flowus/common';
import { AIChatIpc } from '@flowus/shared/ai-chat-ipc';
import { BlockStatus, BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { useEffect } from 'react';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { textToSegments } from 'src/editor/utils/editor';
import { useCreateDocPage } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceView } from 'src/hooks/space/get-space';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import { transaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { TocType } from 'src/views/main/aside/toc/types';
import { useSetDrawerOpenState } from 'src/views/main/drawer/shared';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';

export const useIframeMessage = () => {
  const setDrawState = useSetDrawerOpenState();
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openPage = useOpenPage();
  const createPage = useCreateDocPage();
  const updateSpaceView = useUpdateSpaceView();

  useEffect(() => {
    const onMessage = (event: MessageEvent<any>) => {
      switch (event.data.type) {
        case AIChatIpc.CLOSE_CHAT_PANEL:
          void setDrawState(false);
          break;
        case AIChatIpc.OPEN_BLOCK_PAGE:
          openPage(event.data.data.uuid, {
            forceOpenNewTab: true,
          });
          break;
        case AIChatIpc.OPEN_UPGRADE_AI:
          openUpgradeAiModal({
            isFrom: OpenSettingFrom.ai_max,
          });
          break;
        case AIChatIpc.OPEN_UPGRADE_SPACE:
          void openUpgradeSpace(OpenSettingFrom.ai_max);
          break;
        case AIChatIpc.UPLOAD_FILE: {
          // eslint-disable-next-line no-case-declarations
          const { fileName, fileSize, ossName, fileId } = event.data.value;
          const { extName } = getFileNameInfo(fileName);
          const space = getCurrentSpace();
          const spaceView = getCurrentSpaceView();

          let folderId: string | undefined = spaceView?.setting.aiFolderId ?? '';
          const folder = cache.blocks[folderId];
          const folderExist = folder && folder.status === BlockStatus.NORMAL;
          if (
            !folderExist ||
            !Role.contains(getPermissions(folderId).role, PermissionRole.WRITER)
          ) {
            folderId = createPage(
              BlockType.FOLDER,
              { parentId: space.uuid, last: true },
              TocType.PRIVATE,
              textToSegments('空间问问-上传文件夹(请勿删除)')
            );
            if (!folderId) return;
            if (spaceView) {
              transaction(() => {
                updateSpaceView(spaceView.uuid, {
                  setting: {
                    // @ts-ignore typecheck
                    aiFolderId: folderId,
                  },
                });
              });
            }
          }

          transaction(() => {
            addBlock(
              {
                uuid: fileId,
                type: BlockType.FILE,
                data: {
                  extName,
                  ossName,
                  segments: textToSegments(fileName),
                  size: fileSize,
                },
              },
              {
                parentId: folderId ?? '',
                first: true,
              }
            );
          });
          break;
        }
        case AIChatIpc.CHAT_PANEL_WIDTH: {
          emitter.emit('aiPageWidth', event.data.value);
          break;
        }
        case AIChatIpc.SEND_QUESTION: {
          bizTracker.event('send_ai_question', event.data.value);
          break;
        }
        default:
          break;
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [createPage, openPage, openUpgradeAiModal, openUpgradeSpace, setDrawState, updateSpaceView]);
};
