import { useObservableBlock } from 'src/services/rxjs-redux/hook';

/** 思维导图是否对齐同级节点 */
export const useAlignSiblingNode = (mindMapId: string) => {
  return useObservableBlock(
    mindMapId,
    (state) => {
      const format = state?.data.format?.mindMappingFormat;
      return !!format?.siblingNodeAlignment;
    },
    [],
    { wait: 0 }
  );
};
