import dayjs from 'dayjs';
import type { FC } from 'react';
import React from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCalendarType } from 'src/hooks/collection-view/use-collection-view';
import { ONE_DAY, WEEKDAYS_SHORT, formatWeekText, getDateTimeStamp } from 'src/utils/date-utils';
import { CalendarTypeMap } from '../const';

export const Header: FC<{
  currentDate: number;
  setToday: React.Dispatch<React.SetStateAction<number>>;
}> = ({ currentDate, setToday }) => {
  const { isLocked, managerReadonly, viewId } = useBitable();
  const calendarType = useCalendarType(viewId);
  const openModal = useOpenModal();

  const handleClick = (event: React.MouseEvent) => {
    if (isLocked || managerReadonly) return;

    const items = CalendarTypeMap.map((item) => ({
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data: { title: item.name, ...item, selected: item.type === calendarType },
    }));

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-36 py-2"
            items={items}
            onItemClick={({ data }) => {
              updateViewFormat(viewId, { calendarType: data.type });
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  return (
    <div className="text-t2-medium pl-2 border-t">
      <div className="flex items-center py-1 h-[38px]">
        <p className="mr-auto">{dayjs(currentDate).format('YYYY年MM月')}</p>

        <div className="ml-auto flex items-center">
          <button
            onClick={handleClick}
            className="text-t2 flex h-full items-center px-3 text-grey3"
          >
            {CalendarTypeMap.find((item) => item.type === calendarType)?.name}
            {!managerReadonly && <Icon size="xxxsmall" name="IcArrowDown01" className="ml-1" />}
          </button>

          <Tooltip
            className="rounded-sm animate-hover"
            onClick={() => setToday(currentDate - ONE_DAY)}
          >
            <Icon name="IcArrowDateBack" size="middle" />
          </Tooltip>
          <span
            className="bg-white1 px-2 cursor-pointer"
            onClick={() => setToday(getDateTimeStamp(Date.now()))}
          >
            今天
          </span>
          <Tooltip
            className="rounded-sm animate-hover"
            onClick={() => setToday(currentDate + ONE_DAY)}
          >
            <Icon name="IcArrowDateNext" size="middle" />
          </Tooltip>
        </div>
      </div>

      <div className="flex h-[38px] text-t4-medium text-grey3 items-center">
        {formatWeekText(WEEKDAYS_SHORT[dayjs(currentDate).day()] ?? '')}
      </div>
    </div>
  );
};
