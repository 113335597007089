import { useMemo, useRef, type FC } from 'react';
import { Select } from '../select';
import { cx } from '@flowus/common/cx';
import { ListView } from 'src/common/components/list-view';
import { useCloseModal, useOpenModal } from '@flowus/common/next-modal';
import { useChartBlockId } from '../chart-config-context';
import { ChartTheme } from '@flowus/chart';
import { Modals } from 'src/modals';
import {
  useBarChartStyle,
  useChartFormat,
  useChartTheme,
  useLegendAlignPosition,
  useUpdateChartFormatStyle,
} from 'src/hooks/chart';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { Icon } from 'src/common/components/icon';
import type { LegendAlignPosition } from './const';
import { LegendAlignPositionData } from './const';
import { BarStyle } from './bar-style';
import { PieStyle } from './pie-style';

export const ChartStyle: FC = () => {
  const selectDivRef = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();
  const themeList = useThemeList();
  const chartBlockId = useChartBlockId();
  const themeKey = useChartTheme(chartBlockId);
  const format = useChartFormat(chartBlockId);
  // 如果不是扇形，那么就是其他非number类型的
  const isPie = format?.chartMode?.type === 'Pie';
  const positionList = useLegendAlignPositionList();
  const { legendAlignPosition } = useBarChartStyle(chartBlockId);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const theme = ChartTheme[themeKey]!;
  return (
    <>
      <div className="text-t2-medium">主题色</div>
      <Select
        ref={selectDivRef}
        title={<ThemeColor colors={theme?.theme.color.slice(0, 6) ?? []} />}
        className="mt-2"
        placeHolder="请选择数据源"
        onClick={(e) => {
          const width = selectDivRef.current?.getBoundingClientRect().width;
          openModal.dropdown({
            modalId: Modals.CHART_THEME,
            popcorn: e.currentTarget,
            content: () => {
              return (
                <div style={{ width }} className="next-modal max-h-[300px] overflow-auto">
                  <ListView items={themeList} />
                </div>
              );
            },
          });
        }}
      />
      <div className="text-t2-medium mt-4">图例位置</div>
      <Select
        title={LegendAlignPositionData[legendAlignPosition]}
        className="mt-2"
        placeHolder="请选择数据源"
        onClick={(e) => {
          const width = selectDivRef.current?.getBoundingClientRect().width;
          openModal.dropdown({
            modalId: Modals.CHART_THEME,
            popcorn: e.currentTarget,
            content: ({ onCloseModal }) => {
              return (
                <div style={{ width }} className="next-modal max-h-[300px] overflow-hidden">
                  <ListView
                    items={positionList}
                    onItemClick={() => {
                      onCloseModal();
                    }}
                  />
                </div>
              );
            },
          });
        }}
      />
      {isPie && <PieStyle />}
      {!isPie && <BarStyle />}
    </>
  );
};
const useLegendAlignPositionList = () => {
  const chartBlockId = useChartBlockId();
  const legendAlignPosition = useLegendAlignPosition(chartBlockId);
  const updateChartFormatStyle = useUpdateChartFormatStyle();
  const items = Object.keys(LegendAlignPositionData).map((v) => {
    const title = LegendAlignPositionData[v as LegendAlignPosition];
    const item: ListItem = {
      type: ListItemType.OPERATION,
      data: {
        title,
        selected: legendAlignPosition === v,
        selectColor: 'var(--active_color)',
        onClick: () => {
          updateChartFormatStyle(chartBlockId, {
            legendAlignPosition: v as LegendAlignPosition,
          });
        },
      },
    };
    return item;
  });
  return items;
};

const useThemeList = () => {
  const closeModal = useCloseModal();
  const chartBlockId = useChartBlockId();
  const themeKey = useChartTheme(chartBlockId);
  const updateChartFormatStyle = useUpdateChartFormatStyle();
  return useMemo(() => {
    const items = Object.keys(ChartTheme).map((key) => {
      const theme = ChartTheme[key];
      const isSelected = themeKey === key;
      const colors = theme?.theme.color.slice(0, 6) ?? [];
      const item: ListItem = {
        type: ListItemType.CUSTOM_RENDER,
        render: () => {
          return (
            <div
              className="cursor-pointer justify-between flex items-center w-full py-2 px-4"
              onClick={() => {
                updateChartFormatStyle(chartBlockId, {
                  theme: key,
                });
                closeModal(Modals.CHART_THEME);
              }}
            >
              <ThemeColor colors={colors} />
              {isSelected && <Icon name="IcCheck02" size="small" className="text-active_color" />}
            </div>
          );
        },
      };
      return item;
    });
    return items;
  }, [chartBlockId, closeModal, themeKey, updateChartFormatStyle]);
};

const ThemeColor: FC<{ colors: string[]; backgroundColor?: string }> = ({
  colors,
  backgroundColor,
}) => {
  return (
    <div
      className="flex items-center w-full"
      style={{
        backgroundColor,
      }}
    >
      {colors.map((c, index) => {
        return (
          <div
            key={index}
            className={cx('w-7 h-4', {
              'rounded-l-l': index === 0,
              'rounded-r': index === colors.length - 1,
            })}
            style={{
              backgroundColor: c,
            }}
          />
        );
      })}
    </div>
  );
};
