import type { ICursor, IEditorModel } from '@next-space/fe-inlined';
import { contentToString, newContent, newElement, sliceContent } from '@next-space/fe-inlined';
import { useThrottle } from 'ahooks';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EmojiPicker, EmojiSkin } from 'src/common/components/emoji-widget';
import type { EmojiData } from 'src/common/components/emoji-widget/emoji-mart';
import { emojiIndex } from 'src/common/components/emoji-widget/emoji-mart';
import { useOpenModal } from 'src/common/components/next-modal';
import { INLINE_EMOJI_TAG } from '../../../editor/inline/const';

const NO_RESULT_KEYWORD_LEN = 4;
// 如果输入的字符超过这个数字且没有搜索结果就关掉emoji panel
const NO_RESULT_CLOSE_KEYWORD_LEN = 8;
/**
 * 行内emoji的实现：
 * 富文本新增一个element，但这个element只在本地使用，最后会在convertSegmentsToContent的时候转成普通的text类型的segment
 */
export const InlineEmojiPanel: FC<{
  onCloseModal: () => void;
  blockId: string;
  left: number;
  right: number;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  search?: string;
  symbol?: string;
}> = ({ onCloseModal, blockId, left, right, symbol = '', getEditorModel, search }) => {
  const [keyword, setKeyword] = useState(search);
  const throttledValue = useThrottle(keyword ?? '', { wait: 800 });

  const openModal = useOpenModal();
  // const leftRef = useRef<ICursor | undefined>(null);
  const rightRef = useRef<ICursor | undefined>(undefined);
  // 没有搜索结果时记录一下keyword的长度，跟块菜单显示逻辑一样
  const [noResultKeywordLen, setNoResultKeywordLen] = useState<number>(0);
  useEffect(() => {
    const editorModel = getEditorModel(blockId);
    if (!editorModel) return;
    const { selection } = editorModel;
    if (!selection) return;
    if (!selection.isCollapsed) return;
    const cursorRight = editorModel.createCursor(right, 'right');
    rightRef.current = cursorRight;
    if (search) {
      editorModel.performChange((ctx) => {
        ctx.select(left, right).replace(search).collapse('end');
      });
    } else if (!symbol) {
      editorModel.performChange((ctx) => {
        ctx.select(left, right).replace(':').collapse('end');
      });
    }
    const cursorLeft = editorModel.createCursor(left + 1 - symbol.length, 'left');
    const leftEdge = left;
    const subscriptionSelection = editorModel.onContentChange.subscribe(() => {
      if (editorModel.selection) {
        const { focusOffset } = editorModel.selection;
        if (focusOffset <= leftEdge) {
          onCloseModal();
          return;
        }
      }
      const content = sliceContent(editorModel.content, cursorLeft.offset, cursorRight.offset);
      const text = contentToString(content);
      setKeyword(text);
    });
    return () => {
      subscriptionSelection.unsubscribe();
      cursorRight.release();
      cursorLeft.release();
    };
  }, [blockId, getEditorModel, left, onCloseModal, right, search, symbol]);
  const onSelect = useCallback(
    (emoji: EmojiData, event?: React.MouseEvent<HTMLElement>, fromFrequently?: boolean) => {
      if (!fromFrequently && event && emoji.skin) {
        openModal.dropdown({
          popcorn: event.currentTarget,
          placement: 'bottom',
          content: ({ onCloseModal: close }) => (
            <EmojiSkin
              emoji={emoji}
              onSelect={(emojiData) => {
                close();
                onCloseModal();
                const editorModel = getEditorModel(blockId);
                editorModel?.performChange((ctx) => {
                  ctx
                    .select(left - symbol.length, rightRef.current?.offset)
                    .replace(newContent([newElement(INLINE_EMOJI_TAG, { text: emojiData.native })]))
                    .collapse('end');
                });
                void editorModel?.requestFocus();
              }}
            />
          ),
        });
        return true;
      }
      onCloseModal();
      const editorModel = getEditorModel(blockId);
      editorModel?.performChange((ctx) => {
        ctx
          .select(left - symbol.length, rightRef.current?.offset)
          .replace(newContent([newElement(INLINE_EMOJI_TAG, { text: emoji.native })]))
          .collapse('end');
      });
      void editorModel?.requestFocus();
    },
    [blockId, getEditorModel, left, onCloseModal, openModal, symbol.length]
  );
  useEffect(() => {
    if (!throttledValue) return;
    const result = emojiIndex.search(throttledValue);
    if (!result?.length) {
      const len = throttledValue.replace(/[^\x00-\xff]/g, '**').length;
      if (len > NO_RESULT_KEYWORD_LEN && len < NO_RESULT_CLOSE_KEYWORD_LEN) {
        setNoResultKeywordLen(len);
      } else if (len >= NO_RESULT_CLOSE_KEYWORD_LEN) {
        // 如果没有结果然后字符超过4个(中文2个)就不再展示无结果页面
        onCloseModal();
      }
    } else {
      setNoResultKeywordLen(0);
    }
  }, [onCloseModal, throttledValue]);

  if (noResultKeywordLen) return null;

  return (
    <div className="next-modal w-[418px]">
      <EmojiPicker
        useButton={true}
        onSelect={onSelect}
        keyword={throttledValue}
        hideSearchInput={true}
      />
    </div>
  );
};
