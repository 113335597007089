import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import * as Empty from 'src/components/empty';
import { segmentsToText } from 'src/editor/utils/editor';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getFileNameInfo } from 'src/utils/file';
import { FileRegex } from '@flowus/common/regex';
import { usePickBlock } from 'src/utils/pick-block';
import { AudioPreview } from './audio';
import { EPUBView } from './epub';
import { ImagePreview } from './image';
import { OfficePreview } from './office';
import { OthersPreview } from './other';
import { PdfPreview } from './pdf';
import { TxtPreview } from './txt';
import { VideoPreview } from './video';
import { $searchParams } from 'src/utils';
import { isBitablePDFPreview } from 'src/utils/location-utils';
import { useOpenImageWatermark } from 'src/hooks/space/use-open-image-watermark';
import { addImageWatermark } from '@flowus/common/url';

export const getPreviewComponent = (extName = '') => {
  if (FileRegex.image.test(extName)) {
    return ImagePreview;
  } else if (FileRegex.office.test(extName)) {
    return OfficePreview;
  } else if (FileRegex.txt.test(extName)) {
    return TxtPreview;
  } else if (FileRegex.pdf.test(extName)) {
    return PdfPreview;
  } else if (FileRegex.video.test(extName)) {
    return VideoPreview;
  } else if (FileRegex.audio.test(extName)) {
    return AudioPreview;
  } else if (FileRegex.epub.test(extName)) {
    return EPUBView;
  }
  return OthersPreview;
};

export const getPreviewType = (extName = '') => {
  if (FileRegex.image.test(extName)) {
    return 'image';
  } else if (FileRegex.office.test(extName)) {
    return 'office';
  } else if (FileRegex.txt.test(extName)) {
    return 'txt';
  } else if (FileRegex.pdf.test(extName)) {
    return 'pdf';
  } else if (FileRegex.video.test(extName)) {
    return 'video';
  } else if (FileRegex.audio.test(extName)) {
    return 'audio';
  } else if (FileRegex.epub.test(extName)) {
    return 'epub';
  }

  return null;
};

export const PreviewElement: FC<{
  uuid: string;
  downloadUrl: string;
  useSimpleImg?: boolean;
  embed?: boolean;
  style?: React.CSSProperties;
  forceImage?: boolean;
}> = ({ uuid, downloadUrl, useSimpleImg, embed, style, forceImage }) => {
  const block = usePickBlock(uuid, ['data'], ['segments', 'collectionProperties']);
  const { openImageWatermark, imageWatermarkText } = useOpenImageWatermark(block?.spaceId);

  // 被风控了
  const { illegal } = usePermissions(uuid);
  if (illegal) {
    return <Empty.NoPermission uuid={uuid} />;
  }

  if (!block || !downloadUrl) return null;

  const isPage = block.type === BlockType.PAGE;
  let fileName = segmentsToText(block.data.segments);
  if (isPage && $searchParams.propertyId && block) {
    const items = block.data.collectionProperties?.[$searchParams.propertyId] ?? [];
    fileName = items.find((item) => item.url === $searchParams.ossName)?.text ?? '';
  }

  const isAnnotation = block.type === BlockType.PDF_ANNOTATION;
  const { extName } = getFileNameInfo(fileName);
  const Element = forceImage || isAnnotation ? ImagePreview : getPreviewComponent(extName);
  if (getPreviewType(extName) === 'image' && openImageWatermark && imageWatermarkText) {
    downloadUrl = addImageWatermark(downloadUrl, imageWatermarkText);
  }
  if (useSimpleImg && Element === ImagePreview) {
    return (
      <img
        style={style}
        src={downloadUrl}
        className="h-full max-h-full w-full max-w-full object-contain"
        alt="图片预览"
      />
    );
  }

  return (
    <Element
      uuid={isBitablePDFPreview() ? undefined : uuid}
      recordId={isBitablePDFPreview() ? uuid : undefined}
      propertyId={$searchParams.propertyId}
      downloadUrl={downloadUrl}
      embed={embed}
      style={style}
    />
  );
};
