export const sleep = (durationInMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, durationInMs);
  });
};

const tasks: Record<string, Promise<void>> = {};
export const sequence = <T extends () => void>(fn: T, type = 'normal') => {
  if (!tasks[type]) {
    tasks[type] = Promise.resolve();
  }
  const task = tasks[type]?.then(fn, fn);
  if (task) {
    tasks[type] = task;
  }
  type Result = ReturnType<T>;
  return task as Result extends Promise<unknown> ? Result : Promise<Result>;
};

export class Deferred<T = void> {
  resolve!: T extends undefined
    ? (value?: unknown | PromiseLike<unknown>) => void
    : (value: T | PromiseLike<T>) => void;

  reject!: (error: Error) => void;

  promise = new Promise<T>((_resolve, _reject) => {
    // @ts-ignore: ignore
    this.resolve = _resolve;
    this.reject = _reject;
  });
}

export function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
