import type { BlockDTO } from '@next-space/fe-api-idl';
import type { IConfig, IEditorModel } from '@next-space/fe-inlined';
import { configure } from '@next-space/fe-inlined';
import { createContext, useContext } from 'react';
import type { InlinePlugin } from '../../inline/inline-plugin';
import { useSyncId } from '../../plugin/sync-block-context';
import { EditableNamespace } from '../editable-context';
import { _EditorModelMap } from '../editable-models';

export const makeEditorKey = (uuid: string, namespace?: string, syncId?: string | null) => {
  const keys = [];
  if (namespace) {
    keys.push(namespace);
    keys.push('::');
  }
  if (syncId) {
    keys.push(syncId);
    keys.push('::');
  }

  keys.push(uuid);
  return keys.join('');
};

export const makeEditorModelConfig = (plugins: InlinePlugin[]) => {
  const config: IConfig = {
    extensions: [
      {
        id: 'editable',
        initialize: (api) => {
          plugins.forEach((plugin) => {
            plugin.initialize(api);
          });
        },
      },
    ],
  };
  return configure(config);
};

export const setEditorModel = (key: string, model: IEditorModel) => {
  _EditorModelMap.set(key, model);
};

export const deleteEditorModel = (key: string) => {
  _EditorModelMap.delete(key);
};

export const DiffAgainstTo = createContext<BlockDTO | 'created' | 'deleted' | undefined>(undefined);

export const useEditorModelKey = (uuid: string) => {
  const syncId = useSyncId();
  const namespace = useContext(EditableNamespace);
  return makeEditorKey(uuid, namespace, syncId);
};
