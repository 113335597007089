import { cx } from '@flowus/common/cx';
import { LatexComponent } from '@flowus/common/latex';
import type { MouseEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import type { ReadonlyProp } from 'src/redux/types';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { BlockEmptyView } from '../block-empty-view';
import { InputPanel } from './input-panel';

interface LatexProps extends ReadonlyProp {
  content: string;
  isInline?: boolean;
  defaultOpenPanel?: boolean;
  onChange?: (content: string) => void;
  onSubmit?: (content: string) => void;
  onCancel?: () => void;
  containerClassName?: string;
}

export const Latex = (props: LatexProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();
  const [error, setError] = useState(false);
  // const isInMindMap = !!useContext(MindMapContext);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    let confirm = false;
    openModal.dropdown({
      popcorn: elementToGetBoundingClientRect(e.currentTarget),
      placement: 'bottom',
      offset: props.isInline ? [0, 8] : [0, 0],
      // 关闭动画会导致 focusEditableAt 失效
      closeNoAnimate: true,
      closeAfterCallBack: () => {
        if (!confirm) {
          props.onCancel?.();
        }
      },
      content: ({ onCloseModal }) => {
        return (
          <InputPanel
            singleLine={props.isInline}
            content={props.content}
            onChange={(value) => {
              props.onChange?.(value);
            }}
            onSubmit={(value) => {
              props.onChange?.(value);
              props.onSubmit?.(value);
              confirm = true;
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  useEffect(() => {
    props.defaultOpenPanel && divRef.current?.click();
  }, [props.defaultOpenPanel]);

  const emptyView = useMemo(
    () =>
      props.isInline ? (
        <span className="text-t4 text-grey3 bg-grey6 p-1 cursor-pointer rounded inline-flex items-center">
          <Icon name="IcEquation" size="small" />
          新公式
        </span>
      ) : (
        <BlockEmptyView
          readonly={props.readonly}
          text={`添加数学公式`}
          className={props.readonly ? 'cursor-default' : 'cursor-pointer'}
          iconProp={{ name: 'IcUploadEquation', size: 'normal' }}
        />
      ),
    [props.isInline, props.readonly]
  );

  const mathJaxFc = (
    <>
      <LatexComponent
        className={cx('w-full leading-[initial]', error && 'hidden', {
          'overflow-auto': !props.isInline,
        })}
        inline={props.isInline}
        text={props.content}
        onRender={(errorMsg) => setError(!!errorMsg)}
      />
      {error && (
        <span
          className={cx(
            'bg-red/30 w-full text-ellipsis',
            props.isInline ? 'bg-red/30' : 'flex items-center text-t1 overflow-x-auto h-[34px]'
          )}
        >
          公式解析错误: {props.content}
        </span>
      )}
    </>
  );

  if (props.isInline) {
    return (
      <span
        ref={divRef}
        className="flex line-default"
        onMouseDown={(e) => {
          // 简单表格内会被吃掉事件
          e.stopPropagation();
        }}
        onClick={props.readonly ? undefined : onClick}
      >
        {props.content ? mathJaxFc : emptyView}
      </span>
    );
  }

  return (
    <div
      ref={divRef}
      onClick={props.readonly ? undefined : onClick}
      className={cx(
        'line-default group relative h-full w-full',
        !props.readonly && 'cursor-pointer'
      )}
    >
      {props.content ? <div className="overflow-auto">{mathJaxFc}</div> : emptyView}
      {/* 思维导图里的公式块滚动后，【复制公式】 */}
      <Tooltip
        className="disable-select flex items-center absolute top-0 right-0 z-10 cursor-pointer bg-black/75 opacity-0 group-hover:opacity-100 text-white1 transition-opacity text-t4 rounded-sm animate-click"
        popup="复制公式"
        onClick={(e) => {
          e.stopPropagation();
          void writeTextInClipboard(props.content, { message: '已复制公式' });
        }}
      >
        <Icon name="IcCopy" size="middle" />
      </Tooltip>
    </div>
  );
};
