import type { FC } from 'react';
import { ListView } from 'src/common/components/list-view';
import { ListItemType } from 'src/common/components/list-view/types';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { elementScrollTo } from './helper';

interface Prop {
  uuid: string;
  onCloseModal: () => void;
  closeAll: () => void;
  expandAll: () => void;
  isInRight: boolean;
  isReadonly: boolean;
}

export const More: FC<Prop> = ({
  uuid,
  closeAll,
  expandAll,
  onCloseModal,
  isInRight,
  isReadonly,
}) => {
  const transaction = useTransaction();

  const items = [
    {
      type: ListItemType.OPERATION,
      data: {
        title: '全部折叠',
        onClick() {
          closeAll();
          onCloseModal();
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '全部展开',
        onClick() {
          expandAll();
          onCloseModal();
        },
      },
    },
    { type: ListItemType.LINE },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '跳到页首',
        onClick() {
          const pageContainer = querySelectorFromMainContent(`.next-space-page`, isInRight);
          if (!pageContainer) return;
          let bufferTop = 0;
          if (pageContainer.scrollTop > 50) {
            bufferTop = 50;
          }
          elementScrollTo(pageContainer, 0, bufferTop);
          onCloseModal();
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '跳到页尾',
        onClick() {
          const pageContainer = querySelectorFromMainContent(`.next-space-page`, isInRight);
          if (!pageContainer) return;
          let bufferTop = 0;
          const top = pageContainer.scrollHeight - pageContainer.clientHeight;
          const diff = top - pageContainer.scrollTop;
          if (diff > 50) {
            bufferTop = top - 50;
          }
          elementScrollTo(pageContainer, top, bufferTop);
          onCloseModal();
        },
      },
    },
  ];

  if (!isReadonly) {
    items.push({ type: ListItemType.LINE });

    items.push({
      type: ListItemType.OPERATION,
      data: {
        title: '关闭目录',
        onClick() {
          transaction(() => {
            updateBlock(uuid, { data: { directoryMenu: false } });
          });
          onCloseModal();
        },
      },
    });
  }

  return <ListView className="py-1 rounded w-48 next-modal" items={items} />;
};
