import { isBuildIn, getCdnHost } from '../build-in';

export const coverBackgroundStyle = {
  background: 'linear-gradient(0deg, var(--grey8), var(--grey8)), var(--grey9)',
};

export const imageGalleryStyle = {
  background: 'linear-gradient(0deg, var(--white1), var(--white1)), var(--grey8)',
  boxShadow: '0px 4px 9.6px rgba(0, 0, 0, 0.12)',
  border: '1px solid var(--white2)',
};

export const imageGalleryClassNames = {
  id1: 'absolute rounded-sm left-2 top-3 w-[128px] h-[96px] rotate-[-13deg] z-[3]',
  id2: 'absolute rounded-sm left-[76px] top-[20px] w-[128px] h-[96px] rotate-[-16deg] z-[2]',
  id3: 'absolute rounded-sm left-[136px] top-[-21px] w-[128px] h-[96px] rotate-[-9.30deg] z-[1]',
};

// 默认字体，某些组件不需要改变字体，需要使用这个来覆盖自定义字体
export const DEFAULT_FONT_FAMILY = isBuildIn()
  ? `ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"`
  : `my-quote ui-sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol'`;

export const initSceneCss = () => {
  const style = document.createElement('style');

  let css = ``;

  if (isBuildIn()) {
    css = `
    html {
      font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    }

    :root {
      --loadingIcon: url('https://cdn.buildin.ai/assets/loading.svg');
      --eraser-url: url('https://cdn.buildin.ai/assets/eraser.svg');
      --pen-url: url('https://cdn.buildin.ai/assets/pen.svg');
    }

    /* --------- lora ---------- */
    /* cyrillic-ext */
    @font-face {
      font-family: 'b-lora';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuxJMkq18ndeYxZ2JTg.woff2)
        format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
      font-family: 'b-lora';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuxJFkq18ndeYxZ2JTg.woff2)
        format('woff2');
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* vietnamese */
    @font-face {
      font-family: 'b-lora';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuxJOkq18ndeYxZ2JTg.woff2)
        format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: 'b-lora';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuxJPkq18ndeYxZ2JTg.woff2)
        format('woff2');
      unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'b-lora';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/lora/v32/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkq18ndeYxZ0.woff2)
        format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
    }
    /* --------- lora ---------- */

    /* --------- DMMono ---------- */
    /* cyrillic-ext */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_SeW4AJi8SJQtQ4Y.woff2)
        format('woff2');
      unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_QOW4AJi8SJQtQ4Y.woff2)
        format('woff2');
      unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_R-W4AJi8SJQtQ4Y.woff2)
        format('woff2');
      unicode-range: U+0370-03FF;
    }
    /* vietnamese */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_S-W4AJi8SJQtQ4Y.woff2)
        format('woff2');
      unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0,
        U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_SuW4AJi8SJQtQ4Y.woff2)
        format('woff2');
      unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB,
        U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
      font-family: 'b-DMMono';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url(https://fonts.gstatic.com/s/robotomono/v23/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4AJi8SJQt.woff2)
        format('woff2');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
    }
    /* --------- DMMono ---------- */
    `;
  } else {
    css = `
    @import url('https://npm.elemecdn.com/lxgw-wenkai-webfont/lxgwwenkai-regular.css');
    @import url('${getCdnHost()}emoji/source-han-serif-regular/style.css');

    html {
      font-family: 'my-quote', 'PingFang SC', 'Microsoft YaHei', ui-sans-serif, -apple-system,
      BlinkMacSystemFont, 'Segoe UI', Helvetica, Helvetica, 'Apple Color Emoji', Arial, sans-serif,
      'Segoe UI Emoji', 'Segoe UI Symbol';
    }

    :root {
      --loadingIcon: url('${getCdnHost()}assets/loading.svg');
      --eraser-url: url('${getCdnHost()}assets/eraser.svg');
      --pen-url: url('${getCdnHost()}assets/pen.svg');
    }
    `;
  }
  style.innerHTML = css;
  document.head.appendChild(style);
};
