import { VolcanoEngineTracker } from 'src/common/track';
import { BuildInTracker } from 'src/common/track/buildin';
import type { Tracker, TrackParams } from 'src/common/track/types/tracker';

export class BizTracker {
  private stayTimeMap = new Map<string, number>();
  private tracker: Tracker;
  constructor(boundFunName = 'collect') {
    this.tracker =
      __BUILD_IN__ || __PRIVATE__
        ? new BuildInTracker()
        : new VolcanoEngineTracker(
            parseInt(import.meta.env.VITE_VOLCENGINE_TRACK_ID, 10),
            __HOST_LOCAL__ || __HOST_TEST__,
            boundFunName
          );
    // 埋点初始化
  }
  login = (userId: string, params?: TrackParams, flush?: boolean) => {
    this.tracker.login(userId, params, flush);
    return this;
  };
  /**
   * 来自文档的说明:用户唯一标识。 没有设置 user_unique_id 时，则设置为 web_id。
   * 业务方自行设置 * 设置为以下值时，会被忽略。 【'null', 'undefined', '0', '', 'None'】
   *
   * 这个方法会将用户id设置为‘’，按上面文档说的设置为空串时会被忽略，所以不需要调用
   */
  logout = () => {
    this.tracker.logout();
    return this;
  };
  /** 添加公共参数，每个埋点都会自带 */
  setCommonParams = (extParams: TrackParams, flush?: boolean) => {
    this.tracker.setCommonParams(extParams, flush);
    return this;
  };
  addCommonParams = (appendExtParams: TrackParams, flush?: boolean) => {
    this.tracker.setCommonParams(appendExtParams, flush);
    return this;
  };
  /**
   * @param eventName 事件名
   * @param params 参数
   * @param flush 是否立刻上传,一般在location.href之前才用得上
   */
  event = (eventName: string, params: Record<string, unknown> = {}, flush?: boolean) => {
    this.tracker.trackEvent(eventName, params, flush);
  };
  /**
   * pageEnter和pageLeave是配套使用的，里面会自动算停留时间
   * 建议在useEffect使用，返回的unmount方法上用pageLeave
   */
  pageEnter = (eventName: string) => {
    this.stayTimeMap.set(eventName, Date.now());
  };
  pageLeave = (eventName: string, params = {}, flush?: boolean) => {
    const lastTime = this.stayTimeMap.get(eventName);
    if (!lastTime) return;
    const time = (Date.now() - lastTime) / 1000;
    this.tracker.trackEvent(eventName, { ...params, time: time.toFixed(1) }, flush);
  };
  /** 添加用户属性 */
  addUserAttribute = (data: TrackParams, flush?: boolean) => {
    this.tracker.addUserAttribute(data, flush);
  };
}
/** 这个是给web端用的，h5的为避免数据混淆，建议另外new一个新的
 */
export const bizTracker = new BizTracker('webCollectEvent');
