import { Caption } from 'src/editor/component/caption';
import { getLocationOrigin } from 'src/utils/location-utils';

interface Props {
  uuid: string;
  url?: string;
}

export const PrintMedium = ({ uuid, url }: Props) => {
  const _url = url || `${getLocationOrigin()}/preview/${uuid}`;

  return (
    <>
      <a href={_url} className="block my-2 break-words border w-full underline rounded p-8">
        {_url}
      </a>
      <Caption blockId={uuid} />
    </>
  );
};
