import type { UserDTO } from '@next-space/fe-api-idl';
import { UNNAMED_USER } from 'src/common/const';
import { getCurrentSpace } from 'src/hooks/space';
import { getInitials, getPinyin } from './pinyin';

const pinyinCache = new Map<string, string>();
// 首字母
const initialsCache = new Map<string, string>();

/**
 * 搜索用户的尽量用这个方法
 */
export function searchUsers<T extends Pick<UserDTO, 'uuid' | 'nickname' | 'phone' | 'email'>>(
  users: T[],
  keyword: string,
  opt?: { includePhoneNumber: boolean; includeEmail: boolean }
) {
  return users.filter((user) => {
    return searchUser(user, keyword, opt);
  });
}
/** 如果该用户符合搜索条件测则返回true */
export function searchUser<T extends Pick<UserDTO, 'uuid' | 'nickname' | 'phone' | 'email'>>(
  user: T,
  keyword: string,
  opt?: { includePhoneNumber: boolean; includeEmail: boolean }
) {
  const { includePhoneNumber = true, includeEmail = true } = opt ?? {};
  const _keyword = keyword.replace(/\s+/g, '').toLowerCase();
  let nicknamePinyin = getPinyinFromCache(user.nickname || UNNAMED_USER);

  if (includePhoneNumber && user.phone) {
    nicknamePinyin += `${user.phone}`;
  }
  if (includeEmail && user.email) {
    nicknamePinyin += `${user.email}`;
  }

  const nicknameInitials = getInitialsFromCache(user.nickname);
  const nicknameThesaurus = `${user.nickname
    .replace(/\s+/g, '')
    .toLowerCase()} ${nicknamePinyin} ${nicknameInitials}`;

  const markName = getCurrentSpace().userRemark?.[user.uuid];
  let markNameThesaurus = '';
  if (markName) {
    const markNamePinyin = getPinyinFromCache(markName);
    const markNameInitials = getInitialsFromCache(markName);
    markNameThesaurus = `${markName
      .replace(/\s+/g, '')
      .toLowerCase()} ${markNamePinyin} ${markNameInitials}`;
  }
  return nicknameThesaurus.includes(_keyword) || markNameThesaurus.includes(_keyword);
}

const getPinyinFromCache = (key: string) => {
  if (!key) return '';
  let pinyin = pinyinCache.get(key);
  if (!pinyin) {
    pinyin = getPinyin(key);
    pinyinCache.set(key, pinyin);
  }
  return pinyin;
};
const getInitialsFromCache = (key: string) => {
  if (!key) return '';
  let initials = initialsCache.get(key);
  if (!initials) {
    initials = getInitials(key);
    initialsCache.set(key, initials);
  }
  return initials;
};
