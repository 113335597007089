import { ChangeBindPhone, VerifyPasswordCode, VerifyPhoneCode } from '@flowus/login';
import { VerifyEmailCode } from '@flowus/login/component/web-buildin/verify-code/email';
import { UserAuthorizationType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useCurrentUser, useLogout } from 'src/hooks/user';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { SettingModalCommon } from '../common';
import { Button } from '@flowus/common/components';

/** 换绑手机号 */
export const AccountPhone: FC = () => {
  const currentUser = useCurrentUser();
  const openChangePhoneOrEmailDialog = useOpenChangePhoneOrEmailDialog();

  return (
    <SettingModalCommon.Item
      leftChild={
        <div className="space-y-0.5">
          <div>手机号</div>
          <div className="text-t4 text-grey3">{currentUser.phone}</div>
        </div>
      }
      rightChild={<Button onClick={() => openChangePhoneOrEmailDialog('phone')}>换绑手机号</Button>}
    />
  );
};

export const useOpenChangePhoneOrEmailDialog = () => {
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();
  const fetchMe = useFetchMe();
  const logout = useLogout();

  return useCallback(
    (type: 'phone' | 'email') => {
      const isPhone = type === 'phone';
      const openChangePhoneDialog = () => {
        openModal.warning({
          title: isPhone ? '确认换绑当前手机号？' : '确认换绑当前邮箱？',
          confirmText: '确认',
          cancelText: '取消',
          minWidth: 400,
          content: (
            <div className="mb-10">
              <div>
                {isPhone
                  ? '换绑后，将无法继续通过以下手机号进行验证登录'
                  : '换绑后，将无法继续通过以下邮箱进行验证登录'}
              </div>
              <div>{isPhone ? currentUser.phone : currentUser.email}</div>
            </div>
          ),
          confirm: openSelectVerification,
        });
      };

      const openSelectVerification = () => {
        openModal.warning({
          title: '身份验证',
          confirmText: isPhone ? '通过原手机号验证' : '通过原邮箱验证',
          cancelText: '通过登录密码验证',
          colorType: 'active',
          minWidth: 400,
          content: (
            <div className="mb-10">
              <div className="mb-1.5">为保障账号安全，请选择验证方式</div>
              <div className="text-grey3 text-t4">无法接收验证码、忘记密码？请联系人工客服处理</div>
            </div>
          ),
          confirm: () => {
            openVerifyCode();
          },
          cancel: () => {
            openVerifyPasswordCode();
          },
        });
      };

      const openVerifyCode = () => {
        openModal.modal({
          content: (_) =>
            isPhone ? (
              <VerifyPhoneCode
                phone={currentUser.phone}
                onSuccess={(code) => {
                  _.onCloseModal();
                  openBind(code, UserAuthorizationType.PHONE);
                }}
              />
            ) : (
              <VerifyEmailCode
                email={currentUser.email}
                onSuccess={(code) => {
                  _.onCloseModal();
                  openBind(code, UserAuthorizationType.EMAIL);
                }}
              />
            ),
        });
      };

      const openVerifyPasswordCode = () => {
        openModal.modal({
          content: (_) => (
            <VerifyPasswordCode
              userId={currentUser.uuid}
              onSuccess={(code) => {
                _.onCloseModal();
                openBind(
                  code,
                  isPhone ? UserAuthorizationType.PHONE : UserAuthorizationType.PASSWORD
                );
              }}
            />
          ),
        });
      };

      const openBind = (code: string, type: UserAuthorizationType) => {
        openModal.modal({
          content: (_) => (
            <ChangeBindPhone
              type={type}
              isEmail={!isPhone}
              authorizationCode={code}
              userId={currentUser.uuid}
              onSuccess={async () => {
                _.onCloseModal();
                await fetchMe();
                message.success('登录已失效，请重新登录');
                void logout();
              }}
            />
          ),
        });
      };

      // 开始
      openChangePhoneDialog();
    },
    [currentUser.email, currentUser.phone, currentUser.uuid, fetchMe, logout, openModal]
  );
};
