import { useCallback } from 'react';
import { useTableContext } from 'src/editor/editor/plugin/table/table-context';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';

export const useShortcutComment = (uuid: string) => {
  const { recordId } = useTableContext();
  const openCommentPopup = useOpenNewDiscussionPopup();
  const getOrInitEditorModel = useGetOrInitEditorModel();

  return useCallback(() => {
    const model = getOrInitEditorModel(uuid);
    openCommentPopup({
      blockId: recordId || uuid,
      editorModel: model,
      withSelection: !model?.selection?.isCollapsed,
    });
  }, [getOrInitEditorModel, openCommentPopup, recordId, uuid]);
};
