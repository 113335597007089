import type { BlockDataDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import { isUndefined } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { v4 as uuidV4 } from 'uuid';
import type { TableIndexRanges } from './types';

export const useCopyTableItem = () => {
  const transaction = useTransaction();

  return useCallback(() => {
    const { chosenCells } = cache.simpleTable;
    const { tableId, recordId, propertyId } = chosenCells ?? {};

    if (!tableId) return;

    const table = cache.blocks[tableId];
    if (!table) return null;
    const { subNodes } = table;

    if (!isUndefined(recordId)) {
      transaction(() => {
        const block = cache.blocks[recordId];
        if (!block) return;
        addBlock(
          {
            type: BlockType.TABLE_ROW,
            textColor: block.textColor,
            backgroundColor: block.backgroundColor,
            data: block.data,
          },
          { parentId: tableId, after: recordId }
        );
      });
    }

    if (!isUndefined(propertyId)) {
      transaction(() => {
        const format = table.data.format ?? {};
        const columns = format.tableBlockColumnOrder ?? [];
        const columnId = uuidV4();
        const columnIndex = columns.indexOf(propertyId);
        const newFormat: BlockDataDTO['format'] = {
          ...format,
          tableBlockColumnOrder: [
            ...columns.slice(0, columnIndex + 1),
            columnId,
            ...columns.slice(columnIndex + 1),
          ],
        };
        const tableFormat = format.tableBlockColumnFormat ?? {};
        const columnFormat = tableFormat[propertyId];
        if (columnFormat) {
          newFormat.tableBlockColumnFormat = {
            ...tableFormat,
            [columnId]: columnFormat,
          };
        }
        updateBlock(tableId, { data: { format: newFormat } });
        subNodes.forEach((uuid) => {
          const record = cache.blocks[uuid];
          const property = record?.data.collectionProperties?.[propertyId];
          if (property) {
            updateBlock(uuid, {
              data: {
                collectionProperties: {
                  ...record.data.collectionProperties,
                  [columnId]: property,
                },
              },
            });
          }
        });
      });
    }
  }, [transaction]);
};

export const useTableInfo = (tableId: string) => {
  const {
    subNodes = [],
    tableBlockColumnOrder = [],
    tableBlockRanges = [],
  } = useObservableBlock(tableId, (table) => {
    if (!table) return {};
    const { format } = table.data;
    if (!format) return {};
    return {
      subNodes: table.subNodes,
      tableBlockRanges: format.tableBlockRanges,
      tableBlockColumnOrder: format.tableBlockColumnOrder,
    };
  });

  return {
    subNodes,
    tableBlockRanges,
    tableBlockColumnOrder,
  };
};

/** 把 tableBlockRanges 的 uuid 换算成 index */
export const useIndexRanges = (tableId: string) => {
  const { subNodes, tableBlockColumnOrder, tableBlockRanges } = useTableInfo(tableId);

  return useMemo(() => {
    return tableBlockRanges.reduce((result, { start, end }) => {
      if (!start || !end) return result;
      const [startRowId, startColumnId] = start;
      const [endRowId, endColumnId] = end;
      if (!startRowId || !startColumnId || !endRowId || !endColumnId) return result;
      const startRowIndex = subNodes.indexOf(startRowId);
      const startColumnIndex = tableBlockColumnOrder.indexOf(startColumnId);
      const endRowIndex = subNodes.indexOf(endRowId);
      const endColumnIndex = tableBlockColumnOrder.indexOf(endColumnId);
      result.push([
        [startRowIndex, startColumnIndex],
        [endRowIndex, endColumnIndex],
      ]);
      return result;
    }, [] as TableIndexRanges);
  }, [subNodes, tableBlockColumnOrder, tableBlockRanges]);
};

export const useFocusTable = (tableId: string) => {
  const isFocus = useObservableStore(
    ({ ui, blocks }) => {
      const table = blocks[tableId];
      if (!table) return true;
      const selectedCell = ui.selectedCells[0];
      const order = table.data.format?.tableBlockColumnOrder ?? [];
      const { subNodes } = table;
      return (
        subNodes.some((recordId) => recordId === selectedCell?.recordId) &&
        order.some((propertyId) => propertyId === selectedCell?.propertyId)
      );
    },
    [tableId],
    {
      obsSimpleTable: true,
      obsSelectCell: [{ all: true }],
    }
  );

  return isFocus;
};
