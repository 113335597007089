import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { INLINE_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { v4 as uuid4 } from 'uuid';
import { useCellEditor } from './hooks';
import type { CellEditorProps } from './types';

export const RichTextEditor: FC<CellEditorProps> = ({
  recordId,
  recordIds,
  propertyId,
  onUpdate,
  onClose,
}) => {
  const isTitle = propertyId === 'title';
  const editorId = useFinalValue(uuid4);
  const getEditorModel = useGetOrInitEditorModel();
  const initialValue = useObservableBlock(
    recordId,
    (block) => (isTitle ? block?.data.segments : block?.data.collectionProperties?.[propertyId]),
    [propertyId]
  );

  const [value, setValue] = useState(initialValue);
  const updatePropertyValue = useUpdatePropertyValue();
  const dirtyRef = useRef(false);
  const onSave = () => {
    if (dirtyRef.current) {
      updatePropertyValue(recordIds ? recordIds : recordId, propertyId, value);
    }
  };

  useCellEditor({
    onUpdate,
    onClose,
    onSave,
  });

  return (
    <div
      className="min-w-full p-2.5 leading-[20px] overflow-auto max-h-[80vh]"
      onKeyDown={(event) => {
        if (isHotkey('shift+enter')(event)) {
          event.preventDefault();

          const editorModel = getEditorModel(editorId, false);
          editorModel?.performChange((ctx) => {
            ctx.delete().insert('\n');
          });
          return;
        }
        if (isHotkey('enter')(event)) {
          event.preventDefault();
          onSave();
          onClose();
        } else if (isHotkey('esc')(event)) {
          onClose();
        }
      }}
    >
      <RichTextEdit
        uuid={editorId}
        realBlockId={recordId}
        plugins={isTitle ? TITLE_EDITOR_PLUGINS : undefined}
        toolbar={!isTitle}
        autoFocus
        autoFocusCaretToEnd
        className={'text-t2 whitespace-pre-wrap'}
        segments={value}
        keydownOption={INLINE_KEYDOWN_OPTION}
        onChange={(segments) => {
          dirtyRef.current = true;
          setValue(segments);
        }}
      />
    </div>
  );
};
