import type { SpaceDTO } from '@next-space/fe-api-idl';

import { $spaceViewsCache } from 'src/services/spaces/space-views';
import { $spacesCache, useSpacesSelector } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';
import { removeDuplicateWith } from 'src/utils/array-util';
import { useCurrentUserSelector } from '../user';

export const useSpaces = () => {
  return useSpacesSelector((state) => state);
};

/**
 * 获取所有空间
 */
export const getAllSpaces = () => {
  const spaces: SpaceDTO[] = [];
  $currentUserCache.spaceViews.forEach((id) => {
    const space = $spacesCache[$spaceViewsCache[id]?.spaceId ?? ''];
    space && spaces.push(space);
  });
  return removeDuplicateWith(spaces, 'uuid');
};

export const useAllSpaces = () => {
  const spaceViewIds = useCurrentUserSelector((state) => state.spaceViews);

  return useSpacesSelector((spaces) => {
    const _spaces: SpaceDTO[] = [];
    spaceViewIds.forEach((id) => {
      const space = spaces[$spaceViewsCache[id]?.spaceId ?? ''];
      space && _spaces.push(space);
    });
    return removeDuplicateWith(_spaces, 'uuid');
  });
};
