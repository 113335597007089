/** 支付类型 微信 支付宝 积分 */
export enum PayType {
  wxpay = 'wxpay',
  wxjsapi = 'wxjsapi',
  alipay = 'alipay',
  irpay = 'irpay',
  offline = 'offline',
  /** 海外支付 */
  webpay = 'webpay',
  /**h5访问web项目下需要用这个type(场景：h5升级弹窗支付) */
  alipayH5 = 'alipayH5',
  wxpayH5 = 'wxpayH5',
  minipay = 'minipay',
  stripe = 'stripe',
  sbpRu = 'sbp_ru',
  cardRu = 'card_ru',
  yoomoneyRu = 'yoomoney_ru',
  sberPayRu = 'sberpay_ru',
}
