import { getState } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';

/**
 * 获取 uuid 所属 page
 */
export const getOwnerPage = (
  uuid: string,
  state = {
    blocks: getState().blocks,
  }
) => {
  const { blocks } = state ?? getState();
  const block = blocks[uuid];
  if (!block) return;

  let curr = blocks[block.parentId];
  while (
    curr &&
    curr.spaceId !== curr.uuid &&
    curr.uuid !== curr.parentId &&
    curr.uuid !== blocks[curr.parentId]?.parentId
  ) {
    if (isPageLike(curr.type)) {
      return curr.uuid;
    }
    curr = blocks[curr.parentId];
  }
};
