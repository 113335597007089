import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';
import { readDateFromDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import type { DateSegmentInfo } from './table-view/cell/date';

/** 通过segment获取date */
export const segmentsToDateValue = (segment?: SegmentDTO): DateSegmentInfo | undefined => {
  if (!segment) return;

  const date = dayjs(readDateFromDateSegment(segment));
  if (isNaN(date.toDate().getTime())) return;

  if (segment.type === TextType.DATE) {
    return {
      dateString: date.format(DATE_FORMAT),
      timestamp: date.valueOf(),
      textType: TextType.DATE,
    };
  }
  if (segment.type === TextType.DATETIME) {
    return {
      dateString: date.format(DATE_TIME_FORMAT),
      timestamp: date.valueOf(),
      textType: TextType.DATETIME,
    };
  }
};

export const getGroupUniqueId = (viewId: string, groupName = 'ungroup', isSubGroup = false) => {
  const { groupBy, subGroupBy } = getViewFormat(viewId) ?? {};
  return `viewId:${viewId};groupProperty:${
    isSubGroup ? subGroupBy?.property : groupBy?.property
  };groupName:${groupName}`;
};
