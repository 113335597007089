import { fastEqual } from '@flowus/common/utils/tools';
import type { ObservableDataValueType } from './map';
import { globalRxTimer } from './timer';

// export class ObservableObject {
//   private observableMap: Map<string, BehaviorSubject<ObservableDataValueType>>;
//   private patchSubject: Map<string, any>;
//   constructor(observableMap = new Map<string, BehaviorSubject<ObservableDataValueType>>()) {
//     this.observableMap = observableMap;
//     this.patchSubject = new Map();
//   }

//   nextQueue = throttle(() => {
//     this.patchSubject.forEach((value, id) => {
//       this.patchSubject.delete(id);
//       const obs = this.observableMap.get(id);
//       if (obs) {
//         obs.next(value);
//       }
//     });
//   }, 1);

//   patch(id: string, value?: ObservableDataValueType) {
//     globalRxTimer.run();

//     const newValue = value ?? (typeof id === 'symbol' ? id : Symbol(id));
//     if (this.observableMap.has(id)) {
//       // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
//       const obs = this.observableMap.get(id)!;
//       const oldValue = obs.getValue();
//       // 对比新旧值是否有变化，无变化不执行 patch，但此处可能有 BUG，建议不要轻易传递 value，让他自然 patch
//       if (!fastEqual(oldValue, newValue)) {
//         // this.patchSubject.delete(id);
//         // this.patchSubject.set(id, newValue);
//         // this.nextQueue();
//         obs.next(newValue);
//       }
//       return obs;
//     }

//     const subject = new BehaviorSubject<ObservableDataValueType>(newValue);
//     this.observableMap.set(id, subject);
//     return subject;
//   }

//   onlyPatch(id: string, value?: ObservableDataValueType) {
//     if (this.observableMap.has(id)) {
//       return this.patch(id, value);
//     }
//   }

//   deleteObs(id: string) {
//     const obs = this.observableMap.get(id);
//     if (obs) {
//       obs.unsubscribe();
//       this.observableMap.delete(id);
//     }
//   }

//   get(id: string) {
//     const obs = this.observableMap.get(id);
//     if (obs) {
//       return obs;
//     }

//     // 如果要监听框选的时候，就得开始建立 obs 联系
//     if (isSelectPrefixId(id)) {
//       return this.patch(id);
//     }

//     if (isPermissionKey(id)) {
//       return this.patch(id);
//     }

//     return this.patch(id);
//   }

//   clear() {
//     this.observableMap.forEach((obs) => obs.unsubscribe());
//     this.observableMap.clear();
//   }

//   getMap() {
//     return this.observableMap;
//   }

//   has(id: string) {
//     return this.observableMap.has(id);
//   }
// }

export interface PatchOptions {
  force?: boolean;
}

export class ObservableObject {
  private observableGroupMap: {
    normal: Map<string, ObservableDataValueType>;
    select: Map<string, ObservableDataValueType>;
  };
  private queue: Set<ObservableDataValueType>;

  constructor() {
    this.observableGroupMap = {
      normal: new Map(),
      select: new Map(),
    };
    this.queue = new Set();
  }

  // nextQueue = throttle(() => {
  //   this.queue.forEach((item) => item.subscribe.forEach((fn) => fn()));
  //   this.queue.clear();
  // }, 0);

  getObsGroup(id: string) {
    const obsGroup = isSelectPrefixId(id)
      ? this.observableGroupMap.select
      : this.observableGroupMap.normal;
    return obsGroup;
  }

  patch(id: string, value?: any, opt?: PatchOptions) {
    globalRxTimer.run();

    const newValue = value ?? (typeof id === 'symbol' ? id : Symbol(id));
    const obsGroup = this.getObsGroup(id);

    if (obsGroup.has(id)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const obs = obsGroup.get(id)!;
      if (!fastEqual(obs.value, newValue) || opt?.force) {
        obs.value = newValue;
        obs.subscribe.forEach((fn) => fn());
        // this.queue.add(obs);
        // this.nextQueue();
      }
      return obs;
    }
    const newObs: ObservableDataValueType = { value: newValue, subscribe: new Set() };
    obsGroup.set(id, newObs);
    return newObs;
  }

  onlyPatch(id: string, value?: any, opt?: PatchOptions) {
    if (this.getObsGroup(id).has(id)) {
      return this.patch(id, value, opt);
    }
  }

  deleteObs(id: string) {
    const obs = this.getObsGroup(id).get(id);
    if (obs) {
      obs.subscribe.clear();
      this.getObsGroup(id).delete(id);
      globalRxTimer.run();
    }
  }

  get(id: string) {
    const obs = this.getObsGroup(id).get(id);
    if (obs) {
      return obs;
    }

    // 如果要监听框选的时候，就得开始建立 obs 联系
    if (isSelectPrefixId(id)) {
      return this.patch(id);
    }

    if (isPermissionKey(id)) {
      return this.patch(id);
    }

    return this.patch(id);
  }

  clear() {
    this.observableGroupMap.normal.forEach((item) => item.subscribe.clear());
    this.observableGroupMap.select.forEach((item) => item.subscribe.clear());
    this.observableGroupMap.select.clear();
    this.observableGroupMap.normal.clear();
  }

  getMap() {
    return this.observableGroupMap;
  }

  has(id: string) {
    return this.getObsGroup(id).has(id);
  }
}

// export class ObservableObject extends EventEmitter {
//   private valueMap: Map<string, ObservableDataValueType>;
//   private valueMapCount: Map<string, number>;
//   private patchQueue: Set<string>;

//   constructor() {
//     super();
//     this.valueMap = new Map();
//     this.valueMapCount = new Map();
//     this.patchQueue = new Set();
//   }

//   nextQueue = throttle(() => {
//     this.patchQueue.forEach((id) => {
//       this.emit(id);
//       this.patchQueue.delete(id);
//     });
//   }, 1);

//   patch(id: string, value?: ObservableDataValueType) {
//     const newValue = value ?? (typeof id === 'symbol' ? id : Symbol(id));

//     if (this.valueMap.has(id)) {
//       const oldValue = this.valueMap.get(id);
//       if (!fastEqual(oldValue, newValue)) {
//         this.patchQueue.delete(id);
//         this.valueMap.set(id, newValue);
//         // this.emit(id);
//         this.patchQueue.add(id);
//         this.nextQueue();
//       }
//     } else {
//       this.valueMap.set(id, newValue);
//     }

//     return this.createObservable(id);
//   }

//   onlyPatch(id: string, value?: ObservableDataValueType) {
//     if (this.valueMap.has(id)) {
//       return this.patch(id, value);
//     }
//   }

//   deleteObs(id: string): void {
//     this.valueMap.delete(id);
//     this.removeAllListeners(id);
//   }

//   get(id: string) {
//     if (!this.valueMap.has(id)) {
//       if (isSelectPrefixId(id) || isPermissionKey(id)) {
//         return this.patch(id);
//       }
//     }
//     return this.patch(id);
//   }

//   clear(): void {
//     this.valueMap.clear();
//     this.valueMapCount.clear();
//     this.removeAllListeners();
//   }

//   getMap() {
//     return {
//       values: this.valueMap,
//       countMap: this.valueMapCount,
//     };
//   }

//   has(id: string): boolean {
//     return this.valueMap.has(id);
//   }

//   private createObservable(id: string) {
//     return {
//       getValue: () => this.valueMap.get(id),
//       subscribe: (listener: (id: string) => void) => {
//         const wrappedListener = () => listener(id);

//         this.on(id, wrappedListener);
//         this.valueMapCount.set(id, (this.valueMapCount.get(id) ?? 0) + 1);

//         return () => {
//           const count = (this.valueMapCount.get(id) ?? 0) - 1;
//           this.valueMapCount.set(id, count);
//           this.off(id, wrappedListener);
//           if (count <= 0) {
//             this.removeAllListeners(id);
//             this.valueMap.delete(id);
//             this.valueMapCount.delete(id);
//           }
//         };
//       },
//     };
//   }
// }

// #region select
export const prefixSelectBlock = 'select=block';
export const prefixSelectCell = 'select=cell';
export const resetSelectValue = false;
export const isSelectPrefixId = (id = '') => {
  if (typeof id === 'string') {
    return id?.startsWith?.(prefixSelectBlock) || id?.startsWith?.(prefixSelectCell);
  }
  return false;
};

// #region selectBlock
export const getSelectedBlocksKey = (item: {
  blockId?: string;
  syncId?: string;
  viewId?: string;
  all?: boolean;
}) => {
  if (item.viewId && item.blockId && item.syncId) {
    return `${prefixSelectBlock}&blockId=${item.blockId}&syncId=${item.syncId}&viewId=${item.viewId}`;
  }
  if (item.blockId) {
    return getSelectedBlocksKeyByBlock(item.blockId);
  }
  if (item.syncId) {
    return getSelectedBlocksKeyBySync(item.syncId);
  }
  if (item.viewId) {
    return getSelectedBlocksKeyByView(item.viewId);
  }
  if (item.all) {
    return getSelectedBlocksKeyByAll();
  }
  return `${prefixSelectBlock}`;
};

export const getSelectedBlocksKeyBySync = (syncId: string) => {
  return `${prefixSelectBlock}&syncId=${syncId}`;
};

export const getSelectedBlocksKeyByBlock = (blockId: string) => {
  return `${prefixSelectBlock}&blockId=${blockId}`;
};

export const getSelectedBlocksKeyByView = (viewId: string) => {
  return `${prefixSelectBlock}&viewId=${viewId}`;
};

export const getSelectedBlocksKeyByAll = () => {
  return `${prefixSelectBlock}&all`;
};
// #endregion

// #region selectCell
export const getSelectedCellKey = (item: {
  recordId?: string;
  viewId?: string;
  propertyId?: string;
  all?: boolean;
}) => {
  if (item.propertyId && item.recordId && item.viewId) {
    return `${prefixSelectCell}&recordId=${item.recordId}&viewId=${item.viewId}&propertyId=${item.propertyId}`;
  }

  if (item.recordId) {
    return `${prefixSelectCell}&recordId=${item.recordId}`;
  }

  if (item.viewId) {
    return `${prefixSelectCell}&viewId=${item.viewId}`;
  }

  if (item.propertyId) {
    return `${prefixSelectCell}&propertyId=${item.propertyId}`;
  }

  if (item.all) {
    return `${prefixSelectCell}&all`;
  }

  return prefixSelectCell;
};
// #endregion

// #endregion

// #region simpleTable
export const prefixSimpleTable = '&simpleTable';
export const isSimpleTableKey = (id = '') => {
  if (typeof id === 'string') {
    return id.startsWith(prefixSimpleTable);
  }
  return false;
};
// #endregion

// #region permission 专用
export const prefixPermission = '&update=permission';
export const getPermissionKey = (id?: string) => {
  if (id) {
    return `${prefixPermission}&id=${id}`;
  }
  return prefixPermission;
};

export const isPermissionKey = (id?: string) => {
  if (typeof id === 'string') {
    return id?.includes(prefixPermission);
  }
};
// #endregion

// #region ignoreDeep
export const prefixIgnoreDeep = '&update=ignoreDeep';
export const getIgnoreDeepKey = (id?: string) => {
  if (id) {
    return `${prefixIgnoreDeep}&id=${id}`;
  }
  return prefixIgnoreDeep;
};
// #endregion

// #region ignoreOtherData
export const prefixIgnoreOtherData = '&update=ignoreOtherData';
export const getIgnoreOtherData = (id?: string) => {
  if (id) {
    return `${prefixIgnoreOtherData}&id=${id}`;
  }
  return prefixIgnoreOtherData;
};
// #endregion

// #region 其他特殊场景的细节事件
export enum RxPatchKey {
  /** 监听 users 变化 */
  ALL_USERS = 'ALL_USERS',
  /** 监听 select block 数据长度，有或无 */
  HAS_SELECT_BLOCK = 'HAS_SELECT_BLOCK',
  /** 监听 select cell 数据长度，有或无 */
  HAS_SELECT_CELL = 'HAS_SELECT_CELL',
  /** 监听所有评论 */
  ALL_DISCUSSIONS = 'ALL_DISCUSSIONS',
}

// #endregion

// #region utils
export const omitComplexTypes = <T extends {}>(obj: T): T => {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        value === null ||
        value instanceof Date ||
        value instanceof Error ||
        (typeof value !== 'object' && typeof value !== 'function')
      ) {
        // @ts-ignore type
        result[key] = value;
      }
    }
  }
  return result as T;
};

// #endregion
