import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { AppealResult } from 'src/components/share/appeal-result';
import { ShareAudit } from 'src/components/share/share-audit';
import { useGetPageId } from 'src/utils/getPageId';

import { BuildInEmail, FlowUsEmail } from '@flowus/common/const';
import { getCurrentUser } from '../user';

export const useReportIllegal = (
  uuid?: string,
  opt?: {
    blockType?: 'block' | 'form';
  }
) => {
  const openModal = useOpenModal();
  const openReportIllegal = useOpenReportIllegal(uuid, opt);

  return useCallback(async () => {
    if (__BUILD_IN__) {
      openModal.warning({
        title: '举报',
        colorType: 'active',
        content: (
          <div>
            {'快速举报邮箱：'}
            <a
              className="animate-click underline underline-offset-4"
              href={`mailto:${BuildInEmail}`}
            >
              {BuildInEmail}
            </a>
          </div>
        ),
      });
      return;
    }

    openModal.warning({
      title: '举报/申诉',
      minWidth: 350,
      content: (
        <div className="space-y-2">
          <div>若页面涉嫌诈骗，可通过邮件及时与我们联系</div>
          <div>
            快速举报邮箱：
            <a
              className="animate-click underline underline-offset-4"
              href={`mailto:${FlowUsEmail}`}
            >
              {FlowUsEmail}
            </a>
          </div>
        </div>
      ),
      colorType: 'active',
      confirmText: '在线举报/申诉',
      confirm: openReportIllegal,
    });
  }, [openModal, openReportIllegal]);
};

const useOpenReportIllegal = (
  uuid?: string,
  opt?: {
    blockType?: 'block' | 'form';
  }
) => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();

  return useCallback(async () => {
    // https://dev.allflow.cn/swagger/#/editor/editor.shareCheck
    const ShareCheckType = {
      report: 'complain', // 举报
      justice: 'appeal', // 申诉
    };

    const canReport = getCurrentUser().uuid
      ? await request.editor.shareCheck(uuid || pageId, ShareCheckType.report)
      : true;

    if (canReport === false) {
      openModal.modal({
        content: ({ onCloseModal: onClose }) => (
          <AppealResult success={false} type="report" onCloseModal={onClose} />
        ),
      });
    } else {
      openModal.modal({
        content: ({ onCloseModal }) => (
          <ShareAudit
            blockType={opt?.blockType}
            type="report"
            uuid={uuid || pageId}
            onCloseModal={onCloseModal}
          />
        ),
      });
    }
  }, [openModal, opt?.blockType, pageId, uuid]);
};
