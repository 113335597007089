import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import type { AsyncReturnType } from 'type-fest';

const $authentication = atom<AsyncReturnType<typeof request.infra.isAuthentication> | undefined>(
  undefined
);

export const useAuthentication = () => useAtomValue($authentication);

export const useRefreshAuthentication = () => {
  const setAuthentication = useSetAtom($authentication);

  return useCallback(async () => {
    await request.infra.isAuthentication().then((res) => setAuthentication(res));
  }, [setAuthentication]);
};
