import { isHotkey } from 'is-hotkey';
import type { MutableRefObject, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { useIsMobileSize } from 'src/services/app/hook';
import {
  sendClickEvent,
  sendMouseBlurEvent,
  sendMouseEnterEvent,
  sendMouseLeaveEvent,
} from '../utils/event-helper';
import type { ListenPriority } from '../utils/global-listener-helper';
import { globalListenerHelper, PRIORITY_DIALOG } from '../utils/global-listener-helper';

export type Direction = 'up' | 'down' | 'left' | 'right';

/**
 *
 * 全局监听方向键控制组件的元素active相关的hook，用于列表/宫格的item项
 * 对方向键的事件处理(preventDefault/stopImmediatePropagation)是根据项目定制的,目前不需要对外提供自定义处理
 * @param itemsCount item的总个数
 * @param findElement 根据index寻找对应item的element元素
 * @param findNextIndex 根据index和direction寻找下一个index
 *
 * @options
 * @param priority 根据具体情况传对应的全局event处理优先级
 * @param scrollContainer 滚动容器，没有就不传
 * @param onGlobalCaptureKeydown 如果内部不处理就会回调这个全局的keydown
 * @param activeIndex activeIndex 如果用于受控组件，需要传递这个activeIndex
 * @param onSelectActiveIndex activeIndex变更回调，如果用于受控组件，需要传递这个方法
 * @param defaultActiveIndex default值
 */
export const useControlSelectListElement = (
  itemsCount: number,
  findElement: (index: number) => Element | null | undefined,
  findNextIndex: (currentIndex: number, direction: Direction) => number,
  priority: ListenPriority = PRIORITY_DIALOG,
  options: {
    scrollContainer?: RefObject<HTMLElement | null>;
    temporarySelectedIndex?: number;
    onGlobalCaptureKeydown?: (ev: KeyboardEvent) => void;
    defaultActiveIndex?: number;
    activeIndex?: number;
    onSelectedActiveIndex?: (activeIndex: number) => void;
    virtuoso?: MutableRefObject<VirtuosoHandle | null>;
    autoResetActiveIndex?: boolean;
    enableKeyDown?: boolean;
  } = {}
): [number, (n: number) => void] => {
  const isMobileSize = useIsMobileSize();
  const autoResetActiveIndex = options.autoResetActiveIndex ?? true;
  const defaultActiveIndex = options.defaultActiveIndex ?? -1; // 如果没设置临时index就是-1，即默认不选中
  const [selectedIndex, setSelectedIndex] = useState(defaultActiveIndex);
  const selectedIndexRef = useRef(defaultActiveIndex);
  // 如果是受控，则使用受控的index
  selectedIndexRef.current = options.activeIndex ?? selectedIndex;

  // 如果直接使用参数传递过来的方法的话,useEffect的deps需要加方法依赖，如果外部不想用callback的话，内部就需要通过ref把方法记录下来
  const findElementRef = useRef(findElement);
  const findNextIndexRef = useRef(findNextIndex);
  const onGlobalCaptureKeydownRef = useRef(options.onGlobalCaptureKeydown);
  const onSelectedActiveIndexRef = useRef(options.onSelectedActiveIndex);
  findElementRef.current = findElement;
  findNextIndexRef.current = findNextIndex;
  onGlobalCaptureKeydownRef.current = options.onGlobalCaptureKeydown;
  onSelectedActiveIndexRef.current = options.onSelectedActiveIndex;

  useEffect(() => {
    const enableKeyDown = options.enableKeyDown ?? true;
    const updateIndex = (currentIndex: number) => {
      if (!enableKeyDown) return;
      if (currentIndex < 0) return;
      onSelectedActiveIndexRef.current?.(currentIndex);
      // 如果selectIndex有变动更新，并且看看是不是需要让item滚动到屏幕可见
      if (options.scrollContainer && options.scrollContainer.current) {
        const elItem = findElementRef.current(currentIndex);
        // 虚拟列表指定位置
        if (options?.virtuoso?.current && !elItem) {
          options?.virtuoso?.current.scrollToIndex({
            index: currentIndex,
            align: 'center',
            behavior: 'auto',
          });
          if (!isMobileSize) {
            options.scrollContainer.current.style.pointerEvents = 'none';
          }
          return;
        }
        // eslint-disable-next-line no-console
        if (!elItem) console.warn(`could not found item element, index[${currentIndex}]`);
        if (elItem) {
          const elementRect = elItem.getBoundingClientRect();
          const containerRect = options.scrollContainer.current.getBoundingClientRect();
          if (elementRect.top + elementRect.height > containerRect.top + containerRect.height) {
            // 如果超过下方的边缘，就往下滚
            elItem.scrollIntoView({ behavior: 'auto', block: 'end' });
          } else if (elementRect.top < containerRect.top) {
            // 如果超过上方的边缘，就往上滚咯
            elItem.scrollIntoView({ behavior: 'auto', block: 'start' });
          }
          if (!isMobileSize) {
            options.scrollContainer.current.style.pointerEvents = 'none';
          }
        }
      }
    };

    let isEnter = false;
    const captureListener = (ev: KeyboardEvent) => {
      if (!enableKeyDown) return;
      const activeElement = document.activeElement as HTMLElement;
      let handle = false;
      if (activeElement) {
        let inPopup = false;
        const isIgnoreControlList = activeElement.closest('.ignore-control-list');
        if (isIgnoreControlList) return;

        if (['INPUT', 'TEXTAREA', 'BUTTON'].includes(activeElement.tagName)) {
          // 获取当前活动的元素
          let activeEl = activeElement;
          // 循环遍历上层元素
          while (activeEl.parentElement) {
            if (activeEl.parentElement.getAttribute('data-modal-id')) {
              inPopup = true;
              break;
            }
            activeEl = activeEl.parentElement;
          }

          // 非弹窗，在弹窗里就正常运作
          if (!inPopup) {
            return handle;
          }
        }

        // 有可能是在警告对话窗口的情况，就禁止方向键了
        if (inPopup && ['BUTTON'].includes(activeElement.tagName)) {
          return handle;
        }

        // if (['DIV'].includes(activeElement.tagName) && activeElement.contentEditable === 'true') {
        //   return handle;
        // }
      }

      let currentIndex = selectedIndexRef.current;
      switch (true) {
        case isHotkey('ArrowUp')(ev): {
          if (itemsCount === 0) break;
          ev.preventDefault();
          ev.stopImmediatePropagation();
          currentIndex = findNextIndexRef.current(currentIndex, 'up');
          handle = true;
          break;
        }
        case isHotkey('ArrowDown')(ev): {
          if (itemsCount === 0) break;
          ev.preventDefault();
          ev.stopImmediatePropagation();
          currentIndex = findNextIndexRef.current(currentIndex, 'down');
          handle = true;
          break;
        }
        case isHotkey('Enter')(ev): {
          // ########## 防止连续敲Enter ############
          if (isEnter) return;
          isEnter = true;
          setTimeout(() => (isEnter = false), 300);
          // ######################################

          if (itemsCount === 0) break;
          if (currentIndex === -1) break;

          ev.preventDefault();
          ev.stopImmediatePropagation();
          const elItem = findElementRef.current(currentIndex);
          // eslint-disable-next-line no-console
          if (!elItem) console.warn(`could not found item element, index[${currentIndex}]`);
          sendClickEvent(elItem);
          handle = true;
          break;
        }
        case isHotkey('ArrowLeft')(ev): {
          if (itemsCount === 0) break;
          const index = findNextIndexRef.current(currentIndex, 'left');
          if (index === -1) {
            // 如果返回-1表示不处理，走默认的逻辑，二级目录会通过mouseout event自动消失
            const elItem = findElementRef.current(currentIndex);
            sendMouseLeaveEvent(elItem);
            sendMouseBlurEvent(elItem);
            const preventEvent = elItem?.closest('[data-prevent-event]');
            if (preventEvent) {
              ev.preventDefault();
            }
          } else {
            currentIndex = index;
            ev.preventDefault();
            ev.stopImmediatePropagation();
            handle = true;
          }
          break;
        }
        case isHotkey('ArrowRight')(ev): {
          if (itemsCount === 0) break;
          const index = findNextIndexRef.current(currentIndex, 'right');
          if (index === -1) {
            // 如果返回-1表示不处理，走默认的逻辑，二级目录会通过mouseout event自动消失
            const elItem = findElementRef.current(currentIndex);
            sendMouseEnterEvent(elItem);
            const preventEvent = elItem?.closest('[data-prevent-event]');
            if (preventEvent) {
              ev.preventDefault();
            }
          } else {
            currentIndex = index;
            ev.preventDefault();
            ev.stopImmediatePropagation();
            handle = true;
          }
          break;
        }
        default:
      }
      if (currentIndex !== selectedIndexRef.current) {
        updateIndex(currentIndex);
        setSelectedIndex(currentIndex);
      }
      if (!handle) {
        // 如果自己都没处理再回调外面
        onGlobalCaptureKeydownRef.current?.(ev);
      }
      return handle;
    };

    const unregisterCapture = globalListenerHelper.addEventListener(
      'keydown',
      captureListener,
      priority,
      true
    );

    // tooltip的提示如果是listView,并且没展示的话不应该吃掉事件
    setTimeout(() => {
      // tippy展示有延迟，需要等一会才能展示出来
      const rect = options.scrollContainer?.current?.getBoundingClientRect();
      if (!rect) {
        unregisterCapture();
        return;
      }
      const { height, width } = rect;
      if (height === undefined || width === undefined) {
        unregisterCapture();
        return;
      }
      if (height === 0 || width === 0) {
        unregisterCapture();
        return;
      }

      if (selectedIndexRef.current < itemsCount) {
        updateIndex(selectedIndexRef.current);
      } else {
        setSelectedIndex(0);
      }
    }, 100);

    return () => {
      unregisterCapture();
    };
  }, [
    itemsCount,
    priority,
    options.scrollContainer,
    isMobileSize,
    options?.virtuoso,
    options.enableKeyDown,
  ]);

  // 使用方向键后会让鼠标指针消失，首次使用也会让鼠标指针消失(对标notion like)
  useEffect(() => {
    if (isMobileSize && options.scrollContainer?.current) {
      options.scrollContainer.current.style.pointerEvents = '';
      options.scrollContainer.current.style.cursor = '';
      return;
    }

    if (options.scrollContainer?.current) {
      options.scrollContainer.current.style.pointerEvents = 'none';
      options.scrollContainer.current.style.cursor = 'none';
    }

    const mouseMove = () => {
      // 移动了一下鼠标就需要设置回之前的属性
      if (options.scrollContainer?.current) {
        options.scrollContainer.current.style.pointerEvents = '';
        options.scrollContainer.current.style.cursor = '';
      }
    };
    globalListenerHelper.addEventListener('mousemove', mouseMove, priority);

    return () => {
      globalListenerHelper.removeEventListener('mousemove', mouseMove);
    };
  }, [isMobileSize, options.scrollContainer, priority]);

  useEffect(() => {
    if (autoResetActiveIndex) {
      setSelectedIndex(defaultActiveIndex);
    }
  }, [defaultActiveIndex, itemsCount, autoResetActiveIndex]);

  return [selectedIndexRef.current, setSelectedIndex];
};
