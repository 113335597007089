import { deepEqual } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { memo, useMemo } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { Checkbox } from 'src/components/check-box';
import { useChangeFolderViewType } from 'src/hooks/drive/use-folder';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import { FolderViewType, useBlockFolderListViewType } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

// #region 展示模式Icon
interface Props {
  uuid: string;
  className?: string;
}
export const ListViewTypeIcon: FC<Props> = ({ uuid, className }) => {
  const blockFolderListViewType = useBlockFolderListViewType(uuid);
  const openModal = useOpenModal();
  const folderViewType = [
    {
      icon: 'IcEditProperty',
      type: FolderViewType.TABLE,
      title: '列表模式',
    },
    {
      icon: 'IcFolderGrid',
      type: FolderViewType.THUMBNAIL,
      title: '缩略图模式',
    },
    {
      icon: 'IcFolderGridLarge',
      type: FolderViewType.PREVIEW,
      title: '大图模式',
    },
  ];
  const currentViewType = folderViewType.find((i) => i.type === blockFolderListViewType);
  const changeFolderViewType = useChangeFolderViewType(uuid);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-44 py-2.5"
          onItemClick={() => onCloseModal()}
          items={folderViewType.map((item) => ({
            type: ListItemType.OPERATION,
            data: {
              icon: item.icon,
              title: item.title,
              selected: item.type === blockFolderListViewType,
              onClick: () => changeFolderViewType(item.type),
            },
          }))}
        />
      ),
    });
  };

  return (
    <div
      className={cx('text-t2 flex items-center h-full cursor-pointer text-black', className)}
      onClick={handleClick}
    >
      {currentViewType?.icon && (
        <>
          <Icon name={currentViewType.icon as IconName} size="middle" className="mr-1" />
          <Icon name="IcArrowDown01" size="xxxsmall" />
        </>
      )}
      {/* <span>{currentViewType?.title}</span> */}
    </div>
  );
};
// #endregion

// #region 顶部多选Icon
enum SelectMultiple {
  ALL,
  PIECE,
  NONE,
}
export const FolderSelectAllIcon = memo(
  ({ ids, className }: { ids: string[]; className?: string }) => {
    const selectedBlocks = useObservableStore((state) => state.ui.selectedBlocks, [], {
      obsSelectBlocks: ids.map((i) => ({ blockId: i })),
    });

    const selectMultiple = useMemo(() => {
      const selectIds = selectedBlocks.map((i) => i.blockId);
      const havSelect = ids.some((i) => selectIds.includes(i));
      const allSelect = ids.every((i) => selectIds.includes(i));

      if (allSelect && havSelect) {
        return SelectMultiple.ALL;
      }

      if (havSelect) {
        return SelectMultiple.PIECE;
      }

      return SelectMultiple.NONE;
    }, [selectedBlocks, ids]);

    const handleClick = (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      if (selectMultiple === SelectMultiple.ALL) {
        // 取消全选
        dispatch(uiActions.updateSelectBlocks([]));
      } else {
        // 全选
        dispatch(uiActions.updateSelectBlocks(ids.map((i) => ({ blockId: i }))));
      }
    };

    const checkBoxIconNames = {
      [SelectMultiple.ALL]: {
        title: '取消全选',
        name: 'IcCheckbox2Check',
      },
      [SelectMultiple.PIECE]: {
        title: '部分选中',
        name: 'IcSelectedPart',
      },
      [SelectMultiple.NONE]: {
        title: '全选',
        name: 'IcCheckbox2',
      },
    };

    return (
      <Tooltip
        data-no-cancel-selected
        popup={checkBoxIconNames[selectMultiple].title}
        onClick={handleClick}
        className={cx(className, selectedBlocks.length && 'opacity-100')}
      >
        <Checkbox
          checkbox2
          checked={[SelectMultiple.ALL, SelectMultiple.PIECE].includes(selectMultiple)}
          name={checkBoxIconNames[selectMultiple].name as IconName}
        />
      </Tooltip>
    );
  },
  deepEqual
);
// #endregion

// #region 不同列表类型显示预览的尺寸
export const getFileListPreViewIconSize = (viewType: FolderViewType) => {
  const classList: Record<FolderViewType, { class: string; size: number }> = {
    [FolderViewType.PREVIEW]: {
      class: 'w-[110px] h-[110px]',
      size: 220,
    },
    [FolderViewType.THUMBNAIL]: {
      class: 'w-[50px] h-[50px]',
      size: 100,
    },
    [FolderViewType.TABLE]: {
      class: 'w-[28px] h-[28px] p-1',
      size: 56,
    },
  };
  return classList[viewType];
};
// #endregion
