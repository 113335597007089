import { LoadingIcon } from '@flowus/common/components/loading-icon';
import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { useBitableLoadMoreContext } from './hooks';

export const BitableLoadMore: FC = () => {
  const { viewType } = useBitable();
  const { loadMore, showMore, autoLoad } = useBitableLoadMoreContext();

  return (
    <>
      {autoLoad && <AutoLoad />}
      {showMore && (
        <div
          onClick={() => loadMore()}
          className={cx(
            'w-full h-9 flex items-center text-t2 text-grey4 pl-1 cursor-pointer animate-hover-black3',
            (viewType === CollectionViewType.TABLE || viewType === CollectionViewType.LIST) &&
              'border-b'
          )}
        >
          <Icon name="IcMore" size="middle" className="mr-1" />
          展开更多
        </div>
      )}
    </>
  );
};

const AutoLoad: FC = () => {
  const { loadMore } = useBitableLoadMoreContext();

  const { ref, inView } = useInView({
    rootMargin: '0px 0px',
    fallbackInView: true,
    trackVisibility: false,
    onChange(status) {
      actionLoad(status);
    },
  });

  const actionLoad = (status: boolean) => {
    if (status) {
      loadMore(true);
    }
  };

  useEffect(() => {
    const timer = !inView
      ? ''
      : setInterval(() => {
          if (inView) {
            loadMore(true);
          } else {
            clearInterval(timer);
          }
        }, 1000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <div ref={ref} className="w-full p-2.5">
      <LoadingIcon size="middle" />
    </div>
  );
};
