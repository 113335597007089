export enum AIChatIpc {
  /** 关闭 FlowUs AI */
  CLOSE_CHAT_PANEL = 'CLOSE_CHAT_PANEL',
  /** 打开购买 AI */
  OPEN_UPGRADE_AI = 'OPEN_UPGRADE_AI',
  /** 打开购买 SPACE */
  OPEN_UPGRADE_SPACE = 'OPEN_UPGRADE_SPACE',
  /** 打开 page */
  OPEN_BLOCK_PAGE = 'OPEN_BLOCK_PAGE',
  /**上传了一个文件，需要绑定到aiFolderId */
  UPLOAD_FILE = 'UPLOAD_FILE',
  /**改变panel宽度 */
  CHAT_PANEL_WIDTH = 'CHAT_PANEL_WIDTH',
  SEND_QUESTION = 'SEND_QUESTION',
}
