import { cx } from '@flowus/common/cx';
import { omit, take, takeRight } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request';
import { covertToHumanReadableTime } from 'src/common/utils/formatter';
import { useCurrentUserId } from 'src/hooks/user';
import { getUserName, useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { cache } from 'src/redux/store';
import type { MemberState } from 'src/redux/types';
import { $appUiStateCache, setAppUiState, useMembersStates } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { $currentUserCache } from 'src/services/user/current-user';
import { useGetPageId } from 'src/utils/getPageId';
import { TEN_MINUTES } from './members-coordinate';

type MemberInfo = Pick<MemberState, 'userId' | 'pageId'> & {
  present: boolean;
  lastVisitTime: number;
};

const SHOW_MEMBER_COUNT = 5;
/**
 * page访问记录
 *
 * 由于各自场景需求:
 * state.ui.membersStates里面包含了在线以及离线的人员
 * 最近访问记录包含了最近访问的人员以及是否在线状态
 *
 * 主要逻辑是拿上面两个数据做重复筛选
 */
const MemberVisitHistory0: FC<{ className?: string }> = ({ className }) => {
  const pageId = useGetPageId();
  const [visitedStates, setVisitStates] = useState<Record<string, MemberInfo>>({});
  const [forceUpdate, update] = useState(0);
  const membersStates = useMembersStates();
  const currentUserId = useCurrentUserId();

  const membersCoordinate: Record<string, MemberInfo> = useObservableStore(() => {
    return Object.entries(membersStates)
      .filter(([_, item]) => {
        return item.pageId === pageId && item.userId !== currentUserId;
      })
      .reduce((pre, [userId, cur]) => {
        pre[userId] = {
          ...omit(cur, 'activityTime', 'blockId'),
          lastVisitTime: cur.activityTime,
        };
        return pre;
      }, {} as Record<string, MemberInfo>);
  }, [membersStates, currentUserId, pageId]);

  useEffect(() => {
    if (cache.blocks[pageId]?.local || !pageId) {
      return;
    }
    // 每次访问新页面都要清空之前的数据
    setAppUiState({ $membersStates: {} });
    void request.editor.getDocVisits.raw(pageId).then((res) => {
      if (res.code === 200) {
        const now = Date.now();
        const visitedMemberState: Record<string, MemberInfo> = res.data.visits
          .filter((item) => {
            return item.userId !== $currentUserCache.uuid;
          })
          .reduce((pre, cur) => {
            pre[cur.userId] = {
              userId: cur.userId,
              pageId,
              present: cur.present && now - cur.visitedAt < TEN_MINUTES,
              lastVisitTime: cur.visitedAt,
            };
            return pre;
          }, {} as Record<string, MemberInfo>);
        setVisitStates(visitedMemberState);
      }
    });
  }, [pageId]);

  useEffect(() => {
    const needUpdateState: Record<string, MemberInfo> = {};
    for (const [userId, visitedState] of Object.entries(visitedStates)) {
      const memberState = membersCoordinate[userId];
      if (!memberState) continue;
      if (
        memberState.userId === userId &&
        memberState.pageId === visitedState.pageId &&
        memberState.lastVisitTime > visitedState.lastVisitTime
      ) {
        // 如果socket返回的在线信息时间上更新，则覆盖一进页面接口返回的数据
        needUpdateState[userId] = memberState;
      }
    }
    if (Object.entries(needUpdateState).length > 0) {
      setVisitStates({ ...visitedStates, ...needUpdateState });
    }
  }, [membersCoordinate, visitedStates]);

  useEffect(() => {
    const interval = setInterval(() => {
      update(Date.now());
    }, TEN_MINUTES);
    return () => {
      clearInterval(interval);
    };
  }, [update]);

  const allMemberInfo = useMemo(() => {
    if (!pageId) [];
    const map: Record<string, MemberInfo> = {};
    const now = Date.now();

    // 如果lastVisitTime跟现在比超过了10分钟，则不算在线(用户直接关掉浏览器窗口会来不及发离线信息导致更新不上，所以这里加这么个判断)
    const updatePresentState = (memberInfo?: MemberInfo) => {
      if (!memberInfo) return;
      if (memberInfo.present && memberInfo.lastVisitTime + TEN_MINUTES < now) {
        memberInfo.present = false;
      }
    };
    for (const [_, memberInfo] of Object.entries(visitedStates)) {
      map[memberInfo.userId] = { ...memberInfo };
      updatePresentState(map[memberInfo.userId]);
    }
    for (const [_, memberInfo] of Object.entries(membersCoordinate)) {
      // 在线的会覆盖访问记录的
      map[memberInfo.userId] = { ...memberInfo };
      updatePresentState(map[memberInfo.userId]);
    }

    const allMemberInfo = Object.entries(map)
      .map(([_, item]) => {
        return item;
      })
      .sort((a, b) => {
        if (a.present && b.present) {
          return b.lastVisitTime - a.lastVisitTime;
        }
        if (a.present) {
          return -1;
        }
        if (b.present) {
          return 1;
        }
        return b.lastVisitTime - a.lastVisitTime;
      });

    allMemberInfo.unshift({
      userId: $currentUserCache.uuid,
      pageId,
      present: true,
      lastVisitTime: Date.now(),
    });
    return allMemberInfo;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersCoordinate, pageId, visitedStates, forceUpdate]);
  if (allMemberInfo.length <= 1) return null;

  const showMemberStates = take(allMemberInfo, SHOW_MEMBER_COUNT);
  const restCount = allMemberInfo.length - showMemberStates.length;
  // 5个以外的
  const restMemberStates = takeRight(allMemberInfo, restCount);

  return (
    <div className={cx('flex items-center relative transition-all h-9', className)}>
      <>
        {showMemberStates.map((item, index) => {
          return <MemberAvatar memberInfo={item} key={index} index={index}></MemberAvatar>;
        })}
        {}
      </>
      {restCount > 0 && (
        <Tooltip
          hideOnClick={false}
          theme="members-tooltip"
          maxWidth={260}
          interactive={true}
          className="ml-1"
          placement="bottom"
          popup={<TipList memberInfos={restMemberStates} />}
        >
          <div className="cursor-pointer text-t2 text-grey4">{`+${restCount}`}</div>
        </Tooltip>
      )}
    </div>
  );
};

export const MemberVisitHistory = memo(MemberVisitHistory0);

interface MemberAvatarProps {
  index: number;
  memberInfo: MemberInfo;
}
const MemberAvatar: FC<MemberAvatarProps> = (props) => {
  const user = useUser(props.memberInfo.userId);
  const hasBlockId = useMembersStates()[props.memberInfo.userId]?.blockId;

  const username = useUserName(props.memberInfo.userId);
  if (!user) return <div className="w-6 h-6 -ml-2 rounded-full text-white shadow-sm"></div>;
  // 除了自己其他在线并且有光标的都支持hover放大
  const canScale = props.index > 0 && props.memberInfo.present && hasBlockId;

  return (
    <Tooltip
      style={{
        zIndex: SHOW_MEMBER_COUNT - props.index,
      }}
      className="-ml-1.5"
      placement="bottom"
      onClick={() => {
        const memberStates = $appUiStateCache.$membersStates[user.uuid];
        if (memberStates) {
          jumpPosition(memberStates.blockId);
        }
      }}
      popup={
        <Tip
          userId={props.memberInfo.userId}
          lastVisitTime={props.memberInfo.lastVisitTime}
          username={username}
          type={props.index === 0 ? 'self' : props.memberInfo.present ? 'present' : 'absent'}
        />
      }
    >
      <div className="bg-white1 rounded-full inline-block">
        <Avatar
          className={cx('rounded-full text-white shadow-sm transition', {
            'hover:scale-[1.3]': canScale,
            'opacity-30': !props.memberInfo.present,
            'cursor-pointer':
              props.index !== 0 &&
              props.memberInfo.present &&
              $appUiStateCache.$membersStates[user.uuid]?.blockId,
            'w-[26px] h-[26px]': user.avatar,
          })}
          name={username}
          color={user.backgroundColor}
          icon={{ type: 'upload', value: user.avatar }}
          imgClassName="rounded-full"
          iconSize={26}
        />
      </div>
    </Tooltip>
  );
};

interface TipProps {
  userId: string;
  username: string;
  type: 'self' | 'present' | 'absent';
  lastVisitTime?: number;
}
const Tip: FC<TipProps> = (props) => {
  const isInBlock = Boolean($appUiStateCache.$membersStates[props.userId]?.blockId);
  return (
    <>
      <div className="text-t4-medium">{props.username}</div>
      {props.type === 'present' && isInBlock && (
        <div className="text-t4-medium text-grey4">点击查看TA在看什么</div>
      )}
      {props.type === 'present' && !isInBlock && (
        <div className="text-t4-medium text-grey4">正在浏览</div>
      )}
      {props.type === 'absent' && props.lastVisitTime && (
        <div className="text-t4-medium text-grey4">
          {covertToHumanReadableTime(Date.now() - props.lastVisitTime)}看过
        </div>
      )}
    </>
  );
};

interface TipListProps {
  memberInfos: MemberInfo[];
}
const jumpPosition = (blockId: string) => {
  const blockListContainer = document.querySelector('.block-list');
  if (!blockListContainer) return;
  const blockNode = blockListContainer.querySelector(`[data-block-id="${blockId}"]`);
  blockNode?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
const TipList: FC<TipListProps> = (props) => {
  const presentList = props.memberInfos
    .filter((item) => item.present)
    .sort((a, _b) => ($appUiStateCache.$membersStates[a.userId]?.blockId ? -1 : 1));
  const absentList = props.memberInfos.filter((item) => !item.present);
  return (
    <div className="w-60 p-2 space-y-2 text-ellipsis">
      {presentList.length > 0 && <div className="text-t4-medium text-grey4">正在浏览</div>}
      {presentList.map((item) => {
        const memberStates = $appUiStateCache.$membersStates[item.userId];
        const canScale = item.present && memberStates?.blockId;
        const user = cache.users[item.userId];
        const userName = getUserName(item.userId);
        if (!user) return null;
        return (
          <div
            className={cx('flex items-center justify-between', {
              'cursor-pointer': canScale,
            })}
            key={item.userId}
            onClick={() => {
              if (memberStates) {
                jumpPosition(memberStates.blockId);
              }
            }}
          >
            <div className="flex items-center">
              <Avatar
                className={cx('rounded-full text-white')}
                name={userName}
                color={user.backgroundColor}
                icon={{ type: 'upload', value: user.avatar }}
                imgClassName="rounded-full"
              />
              <div className="ml-2 text-t4-medium text-ellipsis max-w-[150px]">{userName}</div>
            </div>
          </div>
        );
      })}
      {absentList.length > 0 && <div className="text-t4-medium text-grey4">上次浏览</div>}
      {absentList.map((item) => {
        const user = cache.users[item.userId];
        const userName = getUserName(item.userId);
        if (!user) return null;
        return (
          <div className="flex items-center justify-between" key={item.userId}>
            <div className="flex items-center ">
              <Avatar
                className={cx('rounded-full text-white')}
                name={userName}
                color={user.backgroundColor}
                icon={{ type: 'upload', value: user.avatar }}
                imgClassName="rounded-full"
              />
              <div className="ml-2 text-t4-medium text-ellipsis max-w-[150px]">{userName}</div>
            </div>
            {item.lastVisitTime && (
              <div className="text-t4-medium text-grey4">
                {covertToHumanReadableTime(Date.now() - item.lastVisitTime)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
