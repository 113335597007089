import { CollectionSchemaType } from '@next-space/fe-api-idl';
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import dayjs from 'dayjs';
import { updatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { cache } from 'src/redux/store';

const isEmptyString = (str: string) => str.trim().length === 0;

const OPTIONS = {
  [CollectionSchemaType.TEXT]: (text) => isEmptyString(text),
  [CollectionSchemaType.NUMBER]: (text) => !isNaN(Number(text)),
  [CollectionSchemaType.CHECKBOX]: (text) =>
    ['yes', 'no', '☑', '☐'].includes(text?.toLocaleLowerCase()),
  [CollectionSchemaType.URL]: (text) => isEmptyString(text) || (!isEmail(text) && isUrl(text)),
  [CollectionSchemaType.EMAIL]: (text) => isEmptyString(text) || isEmail(text),
  [CollectionSchemaType.DATE]: (text) =>
    // dayjs('1') 或 dayjs('1,2') 也会认为是日期，所以这里先粗暴的用长度判断了一下
    isEmptyString(text) || (text.length >= 6 && dayjs(text).isValid()),
} as Record<string, (text: string) => boolean>;

export const getSchemaType = (column: (string | undefined)[]) => {
  for (const key of Object.keys(OPTIONS)) {
    if (column.every((str) => OPTIONS[key]?.(str ?? ''))) {
      return key as CollectionSchemaType;
    }
  }

  if (column.some((text) => text?.includes(','))) {
    return CollectionSchemaType.MULTI_SELECT;
  }

  return CollectionSchemaType.TEXT;
};

export const inferBitableSchema = (uuid: string, allLine: string[], isTempPage: boolean) => {
  const collection = cache.blocks[uuid];
  if (!collection) return;

  let recordLines: string[] = [];
  const allLineLen = allLine.length;
  const subNodeLen = collection.subNodes.length;
  if (allLine[allLineLen - 1] === '') {
    recordLines = allLine.slice(allLineLen - subNodeLen - 1, -1);
  } else {
    recordLines = allLine.slice(allLineLen - subNodeLen);
  }

  const recordCells: string[][] = recordLines.map((line) => {
    const cells = line
      .split('|')
      .filter((str) => !!str.trim())
      .map((str) => str.trim());
    return cells;
  });

  const viewId = collection.views?.[0] ?? '';
  const collectionView = cache.collectionViews[viewId];
  if (collectionView) {
    const properties = collectionView.format.tableProperties;
    if (properties) {
      const newSchema: Record<string, CollectionSchemaType> = {};
      properties.forEach((item, index) => {
        if (index === 0) return;

        const column = recordCells.map((arr) => arr[index]);
        const type = getSchemaType(column);

        newSchema[item.property] = type;
      });

      const schema = collection.data.schema ?? {};
      Object.keys(newSchema).forEach((uuid) => {
        if (schema[uuid]?.type !== newSchema[uuid]) {
          updatePropertySchema(
            collection.uuid,
            uuid,
            { type: newSchema[uuid] },
            { needTransaction: !isTempPage, ignoreOp: isTempPage }
          );
        }
      });
    }
  }
};
