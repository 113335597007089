import type { SegmentDTO } from '@next-space/fe-api-idl';

import { segmentsToText, textToSegments } from '../utils/segments-utils';
import { BiValueType } from './_abstract';

export class BiSelectType extends BiValueType<number> {
  private optionValueToIndex: Map<string, number>;

  constructor(public options: { id: string; value: string; color: string }[]) {
    super();
    this.optionValueToIndex = new Map(options.map(({ value }, index) => [value, index]));
  }

  fromSegments(segments: SegmentDTO[] | undefined): number | undefined {
    const text = segmentsToText(segments);
    const tags = text.split(/\s*,\s*/g);
    for (const tag of tags) {
      const index = this.optionValueToIndex.get(tag);
      if (index !== undefined) {
        return index;
      }
    }
    return undefined;
  }

  toSegments(value: number): SegmentDTO[] | undefined {
    const option = this.options[value];
    if (option === undefined) return undefined;
    return textToSegments(option.value);
  }

  isNonNullEmpty(value: number): boolean {
    return value < 0 || value >= this.options.length;
  }

  compare(value1: number, value2: number): number {
    return value1 - value2;
  }
}
