import { ImportType } from '@next-space/fe-api-idl';
import { publicLink } from 'src/common/const';
import type { ImportBtnType } from './types';

export const IMPORT_MODAL_ID = 'import-modal';
export const NOTION_MODAL_ID = 'notion-modal';

export const btnConfig: ImportBtnType = {
  [ImportType.MARKDOWN]: {
    icon: 'MIcImportMarkdown',
    title: 'Markdown',
    description: '支持 ZIP RAR',
    accept: '.md,.zip,.rar',
  },
  [ImportType.CSV]: {
    icon: 'MIcImportCsv',
    title: 'CSV',
    description: '',
    accept: '.csv',
  },
  [ImportType.HTML]: {
    icon: 'MIcImportHtml',
    title: 'HTML',
    description: '',
    accept: '.html,.zip,.rar',
  },
  [ImportType.WORD]: {
    icon: 'MIcImportWord',
    title: 'Word',
    description: '支持 DOCX',
    accept: '.docx,.zip,.rar',
  },
  [ImportType.NOTION]: {
    icon: 'MIcImportNotion',
    title: 'Notion',
    description: '通过 Notion API 导入',
    accept: '',
    help: publicLink.importFromNotion,
  },
};
