import type { SpaceDTO } from '@next-space/fe-api-idl';
import { BlockStatus, DaySetting, ShowInLinePageIconType } from '@next-space/fe-api-idl';
import { getDomain } from 'src/common/utils/url-utils';
import { $publicSpaces } from 'src/services/public-space';
import { useSpaceViewsSelector } from 'src/services/spaces/space-views';
import { useSpacesSelector } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';
import { useCurrentUserSelector } from '../user';
import { getSpaceBySpaceViewId } from './get-space';
import { useAppUiState } from 'src/services/app';

const defaultSpace: SpaceDTO = {
  uuid: '',
  setting: {},
  domain: '',
  isShowAd: true,
  publicHomePage: '',
  customTemplates: [],
  title: '',
  icon: {},
  backgroundColor: '',
  subNodes: [],
  version: 0,
  status: BlockStatus.NORMAL,
};

export const useSpaceView = (id = '') => {
  return useSpaceViewsSelector((state) => state[id]);
};

/**
 * 从spaceViewId找起
 */
export const useCurrentSpace = () => {
  const spaceView = useCurrentSpaceView();
  const $currentSpaceId = useAppUiState((state) => state.$currentSpaceId);

  return useSpacesSelector((spaces) => {
    if (!spaceView && !$currentSpaceId) return defaultSpace;
    const curSpace = { ...(spaces[spaceView?.spaceId ?? $currentSpaceId] ?? defaultSpace) };
    if (!curSpace.setting) {
      curSpace.setting = {};
    }
    return curSpace;
  });
};

export const useCurrentSpaceDaySetting = () => {
  const spaceView = useCurrentSpaceView();

  return useSpacesSelector((state) => {
    let currentSpace: SpaceDTO | undefined = undefined;

    if (!spaceView) {
      currentSpace = defaultSpace;
    } else {
      currentSpace = state[spaceView.spaceId];
    }

    return currentSpace?.setting?.daySetting ?? DaySetting.MON;
  });
};

export const useCurrentSpaceViewId = () => {
  return useCurrentUserSelector((user) => user.currentSpaceViewId);
};

export const useCurrentSpaceView = () => {
  const currentSpaceViewId = useCurrentSpaceViewId();
  return useSpaceView(currentSpaceViewId);
};

export const useSpace = (spaceId?: string) => {
  const publicSpace = usePublicSpace(spaceId);
  return useSpacesSelector((state) => {
    if (spaceId) {
      const space = state[spaceId];
      if (space) {
        return space;
      }
      if (publicSpace?.uuid === spaceId) {
        return publicSpace;
      }
    }
    return defaultSpace;
  });
};

export const useSelectSpace = (spaceId = '') => {
  return useSpacesSelector((state) => {
    const space = state[spaceId];
    if (space) {
      return space;
    }
  });
};

export const getCurrentSpace = () => {
  return getSpaceBySpaceViewId($currentUserCache.currentSpaceViewId) ?? defaultSpace;
};

/**
 * 自定义域名
 * 目前只有在NavLink组件上加上domain(为了显示左下角的链接地址)
 * 其它地方如history.push/replace等调用太多，在history.block里面做了个跳转处理，把地址换上带domain带地址
 */
export const useCurrentSpaceDomain = () => {
  const spaceView = useCurrentSpaceView();

  return useSpacesSelector((state) => {
    if (!spaceView) return;
    return state[spaceView.spaceId]?.domain;
  });
};

export const useSpaceDomainPath = (spaceId?: string) => {
  return useSpacesSelector((state) => {
    if (!spaceId) return '';
    if (getDomain()) return '';
    const domain = state[spaceId]?.domain ?? '';
    return domain ? `/${domain}` : '';
  });
};

export const useSpaceDomain = (spaceId?: string) => {
  return useSpacesSelector((state) => {
    if (!spaceId) return '';
    return state[spaceId]?.domain ?? '';
  });
};

export const usePublicSpace = (spaceId = '') => {
  return $publicSpaces((state) => state[spaceId]);
};

export const useSpaceInlinePageIcon = () => {
  const currentSpace = useCurrentSpace();
  return (currentSpace.setting.inlinePageIcon ??
    ShowInLinePageIconType.SHOW_ALL) as ShowInLinePageIconType;
};
