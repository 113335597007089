import { normalizeWheelEventDirection } from 'pdfjs-dist/lib/web/ui_utils';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';

let zoomDisabledTimeout: null | NodeJS.Timeout = null;

const setZoomDisabledTimeout = () => {
  if (zoomDisabledTimeout) {
    clearTimeout(zoomDisabledTimeout);
  }
  zoomDisabledTimeout = setTimeout(() => {
    zoomDisabledTimeout = null;
  }, 1000);
};

export class WebViewerWheel {
  public wheelUnusedTicks = 0;

  constructor(public pdfViewer: PDFViewer, public container: HTMLDivElement) {
    this.container.addEventListener('wheel', this.webViewerWheel, { passive: false });
  }

  destroy = () => {
    this.container.removeEventListener('wheel', this.webViewerWheel);
  };

  accumulateWheelTicks = (ticks: number) => {
    if ((this.wheelUnusedTicks > 0 && ticks < 0) || (this.wheelUnusedTicks < 0 && ticks > 0)) {
      this.wheelUnusedTicks = 0;
    }
    this.wheelUnusedTicks += ticks;
    const wholeTicks =
      Math.sign(this.wheelUnusedTicks) * Math.floor(Math.abs(this.wheelUnusedTicks));
    this.wheelUnusedTicks -= wholeTicks;
    return wholeTicks;
  };

  webViewerWheel = (evt: any) => {
    // https://github.com/mozilla/pdf.js/blob/master/web/app.js#L2685
    if (!this.pdfViewer) return;

    if (this.pdfViewer.isInPresentationMode) {
      return;
    }

    // 在触控板中，双指方向不一致的时候会为 true
    if (evt.ctrlKey || evt.metaKey) {
      evt.preventDefault();
      if (zoomDisabledTimeout || document.visibilityState === 'hidden') {
        return;
      }

      const { deltaMode } = evt;
      const delta = normalizeWheelEventDirection(evt);
      const previousScale = this.pdfViewer.currentScale;

      let ticks = 0;
      if (deltaMode === WheelEvent.DOM_DELTA_LINE || deltaMode === WheelEvent.DOM_DELTA_PAGE) {
        if (Math.abs(delta) >= 1) {
          ticks = Math.sign(delta);
        } else {
          ticks = this.accumulateWheelTicks(delta);
        }
      } else {
        const PIXELS_PER_LINE_SCALE = 30;
        ticks = this.accumulateWheelTicks(delta / PIXELS_PER_LINE_SCALE);
      }

      if (ticks < 0) {
        this.pdfViewer.decreaseScale(-ticks);
      } else if (ticks > 0) {
        if (this.pdfViewer.currentScale >= 4) return;
        this.pdfViewer.increaseScale(ticks);
      }

      const { currentScale } = this.pdfViewer;
      if (previousScale !== currentScale) {
        const scaleCorrectionFactor = currentScale / previousScale - 1;
        const rect = this.pdfViewer.container.getBoundingClientRect();
        const dx = evt.clientX - rect.left;
        const dy = evt.clientY - rect.top;
        this.pdfViewer.container.scrollLeft += dx * scaleCorrectionFactor;
        this.pdfViewer.container.scrollTop += dy * scaleCorrectionFactor;
      }
    } else {
      setZoomDisabledTimeout();
    }
  };
}
