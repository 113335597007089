import type { SearchFilterDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { PersonValueView } from 'src/bitable/table-view/cell/person';
import { Icon } from 'src/common/components/icon';
import { Switch } from 'src/common/components/switch';
import { DATE_FORMAT } from 'src/common/const';
import { TextWithIcon } from 'src/editor/component/text-with-icon/text-with-icon';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { $currentUserCache } from 'src/services/user/current-user';
import { useGetPageId } from 'src/utils/getPageId';
import type { DateRange } from './use-open-date-modal';
import { useOpenDateModal } from './use-open-date-modal';
import { useOpenPeopleModal } from './use-open-person-modal';
import { useOpenSearchPageModal } from './use-open-search-page-modal';

const A_WEEK_AGO_TIME = 7 * 24 * 60 * 60 * 1000;
const itemClassName = 'rounded pl-2 h-10 cursor-pointer hover:bg-black_006';
type SetPartialStateAction<S> = (prevState: S) => Partial<S> | undefined;
interface Props {
  onFilterChange: (filter: SearchFilterDTO) => void;
  defaultFilter: SearchFilterDTO;
}
/**
 * 由于交互比较多，建议先自己玩几遍 搜索过滤的每一个选项，摸清规律再看代码
 */
export const FilterPanel: FC<Props> = (props) => {
  const pageId = useGetPageId();
  const [filter, setFilter] = useState<SearchFilterDTO>(props.defaultFilter);
  // 允许更新一部分filter,也许用immer更好
  const updateFilter = useCallback(
    (action: SetPartialStateAction<SearchFilterDTO>) => {
      setFilter((s) => {
        const patch = action(s);
        if (!patch) return s;

        const newFilter = { ...s, ...patch };
        // any good idea to notify outside?
        props.onFilterChange(newFilter);
        return newFilter;
      });
    },
    [props]
  );
  const switchOnlyTitle = () => {
    updateFilter((s) => {
      return { isTitleOnly: !s.isTitleOnly };
    });
  };
  const openSearchPageModal = useOpenSearchPageModal();
  const openPeopleModal = useOpenPeopleModal();
  const openDateModal = useOpenDateModal();
  /** 自定义筛选 */
  const clickSearchPage = useCallback(
    (e: React.MouseEvent) => {
      openSearchPageModal(e, [], (uuid) => {
        updateFilter((s) => {
          const index = s.ancestors?.indexOf(uuid) ?? -1;
          if (index === -1) {
            return { ancestors: [...(s.ancestors ?? []), uuid] };
          }
        });
      });
    },
    [openSearchPageModal, updateFilter]
  );
  const clickCreatedByPeople = useCallback(
    (e: React.MouseEvent) => {
      openPeopleModal(e, filter.createdBy ?? [], (uuid) => {
        updateFilter((s) => {
          const index = s.createdBy?.indexOf(uuid) ?? -1;
          if (index === -1) {
            return { createdBy: [...(s.createdBy ?? []), uuid] };
          }
        });
      });
    },
    [filter.createdBy, openPeopleModal, updateFilter]
  );
  const clickCreatedByTime = useCallback(
    (e: React.MouseEvent) => {
      openDateModal(e, (dateRange) => {
        updateFilter(() => {
          return {
            createdTime: {
              start: dateRange.from.getTime(),
              end: dateRange.to?.getTime(),
            },
          };
        });
      });
    },
    [openDateModal, updateFilter]
  );
  const clickLastEditByTime = useCallback(
    (e: React.MouseEvent) => {
      openDateModal(e, (dateRange) => {
        updateFilter(() => {
          return {
            lastEditedTime: {
              start: dateRange.from.getTime(),
              end: dateRange.to?.getTime(),
            },
          };
        });
      });
    },
    [openDateModal, updateFilter]
  );
  /** 快速筛选 */
  const clickMyCreated = useCallback(() => {
    updateFilter((s) => {
      return { createdBy: [...(s.createdBy ?? []), $currentUserCache.uuid] };
    });
  }, [updateFilter]);

  const clickLastWeekEdited = useCallback(() => {
    const lastWeek = new Date(Date.now() - A_WEEK_AGO_TIME);
    lastWeek.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    updateFilter(() => {
      return {
        lastEditedTime: {
          start: lastWeek.getTime(),
          end: today.getTime(),
        },
      };
    });
  }, [updateFilter]);

  const clickCurrentPage = useCallback(() => {
    updateFilter((s) => {
      return { ...s, ancestors: [...(s.ancestors ?? []), pageId] };
    });
  }, [pageId, updateFilter]);

  const { ancestors, createdBy, createdTime, lastEditedTime } = filter;

  // 是否显示快速筛选相关选项
  const showCreateByMeFilter = !createdBy?.includes($currentUserCache.uuid);
  const showInCurrentPageFilter = !ancestors?.includes(pageId);
  const showLastWeekEditFilter = useMemo(() => {
    const lastWeek = new Date(Date.now() - A_WEEK_AGO_TIME);
    lastWeek.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return lastEditedTime?.start !== lastWeek.getTime() || lastEditedTime.end !== today.getTime();
  }, [lastEditedTime]);
  const showQuickFilterText =
    showCreateByMeFilter || showInCurrentPageFilter || showLastWeekEditFilter;

  // 是否显示自定义筛选相关选项
  const showPageFilter = !ancestors?.length;
  const showPeopleFilter = !createdBy?.length;
  const showCreateTimeFilter = !createdTime;
  const showLastEditTimeFilter = !lastEditedTime;
  const showCustomFilterText =
    showPageFilter || showPeopleFilter || showCreateTimeFilter || showLastEditTimeFilter;

  // 是否显示filter的属性

  return (
    <div className="bg-grey9 px-2 text-t2 text-grey3 w-[220px] py-2.5 overflow-y-scroll flex-shrink-0">
      <div
        className={`flex justify-between items-center h-10 ${itemClassName}`}
        onClick={switchOnlyTitle}
      >
        <TextWithIcon name={'IcTitle'} text="只搜索标题" />
        <Switch bgClassName="mr-2" open={!!filter.isTitleOnly} onSwitch={switchOnlyTitle} />
      </div>
      <FilterProperties filter={filter} updateFilter={updateFilter}></FilterProperties>
      {showQuickFilterText && (
        <div className="flex items-center mt-2.5 ml-2 h-9 select-none">快速筛选</div>
      )}
      {showCreateByMeFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcUser'}
          text="我创建的页面"
          onClick={clickMyCreated}
        />
      )}
      {showLastWeekEditFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcHistory'}
          text="上周编辑过的页面"
          onClick={clickLastWeekEdited}
        />
      )}
      {showInCurrentPageFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcSearch'}
          text="在当前页面内搜索"
          onClick={clickCurrentPage}
        />
      )}
      {showCustomFilterText && (
        <div className=" flex items-center mt-2.5 ml-2 h-9 select-none">自定义筛选</div>
      )}
      {showPageFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcSearch'}
          text="在指定页面内搜索"
          onClick={clickSearchPage}
        />
      )}
      {showPeopleFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcUser'}
          text="创建人"
          onClick={clickCreatedByPeople}
        />
      )}
      {showCreateTimeFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcHistory'}
          text="创建时间"
          onClick={clickCreatedByTime}
        />
      )}
      {showLastEditTimeFilter && (
        <TextWithIcon
          className={itemClassName}
          name={'IcHistory'}
          text="最后编辑时间"
          onClick={clickLastEditByTime}
        />
      )}
    </div>
  );
};
interface FilterPropertiesProp {
  updateFilter: (action: SetPartialStateAction<SearchFilterDTO>) => void;
  filter: SearchFilterDTO;
}
const FilterProperties: FC<FilterPropertiesProp> = (props) => {
  const { updateFilter, filter } = props;
  const openPeopleModal = useOpenPeopleModal();
  const openDateModal = useOpenDateModal();
  const openSearchPageModal = useOpenSearchPageModal();

  const selectLastEditTime = (e: React.MouseEvent) => {
    let oldDateRange: DateRange | undefined = undefined;
    if (filter.lastEditedTime && filter.lastEditedTime.start && filter.lastEditedTime.end) {
      oldDateRange = {
        from: new Date(filter.lastEditedTime.start),
        to: new Date(filter.lastEditedTime.end),
      };
    }

    openDateModal(
      e,
      (dateRange) => {
        updateFilter(() => {
          return {
            lastEditedTime: {
              start: dateRange.from.getTime(),
              end: dateRange.to?.getTime(),
            },
          };
        });
      },
      oldDateRange
    );
  };
  const selectCreateTime = (e: React.MouseEvent) => {
    let oldDateRange: DateRange | undefined = undefined;
    if (filter.createdTime && filter.createdTime.start && filter.createdTime.end) {
      oldDateRange = {
        from: new Date(filter.createdTime.start),
        to: new Date(filter.createdTime.end),
      };
    }

    openDateModal(
      e,
      (dateRange) => {
        updateFilter(() => {
          return {
            createdTime: {
              start: dateRange.from.getTime(),
              end: dateRange.to?.getTime(),
            },
          };
        });
      },
      oldDateRange
    );
  };
  const selectPeople = (e: React.MouseEvent, oldUserId?: string) => {
    openPeopleModal(e, filter.createdBy ?? [], (uuid) => {
      updateFilter((s) => {
        if (!s.createdBy) return s;
        if (oldUserId) {
          const index = s.createdBy.indexOf(oldUserId);
          if (index !== -1) {
            const newCreateBy = s.createdBy.slice(0);
            newCreateBy.splice(index, 1);
            newCreateBy.splice(index, 0, uuid);
            return { createdBy: newCreateBy };
          }
        } else {
          return { createdBy: [...s.createdBy, uuid] };
        }
        return s;
      });
    });
  };
  const removePeople = (userId: string) => {
    updateFilter((s) => {
      if (!s.createdBy) return s;
      const index = s.createdBy.indexOf(userId);
      if (index !== -1) {
        const newCreateBy = s.createdBy.slice(0);
        newCreateBy.splice(index, 1);
        return { createdBy: newCreateBy };
      }
      return s;
    });
  };
  const selectPage = (e: React.MouseEvent, oldPageId?: string) => {
    openSearchPageModal(e, filter.ancestors ?? [], (uuid) => {
      updateFilter((s) => {
        if (!s.ancestors) return s;
        if (oldPageId) {
          const index = s.ancestors.indexOf(oldPageId);
          if (index !== -1) {
            const newAncestors = s.ancestors.slice(0);
            newAncestors.splice(index, 1);
            newAncestors.splice(index, 0, uuid);
            return { ancestors: newAncestors };
          }
        } else {
          return { ancestors: [...s.ancestors, uuid] };
        }
        return s;
      });
    });
  };
  const removePage = (uuid: string) => {
    updateFilter((s) => {
      if (!s.ancestors) return s;
      const index = s.ancestors.indexOf(uuid);
      if (index !== -1) {
        const newAncestors = s.ancestors.slice(0);
        newAncestors.splice(index, 1);
        return { ancestors: newAncestors };
      }
      return s;
    });
  };
  return (
    <div>
      <div>
        {filter.createdBy?.map((uuid, index, array) => {
          return (
            <div key={index}>
              {index === 0 && (
                <div className="flex items-center mt-2.5 ml-2 h-9 select-none">创建人</div>
              )}
              <div
                className="cursor-pointer rounded flex justify-between items-center pr-2 hover:bg-black_006"
                onClick={(e) => {
                  selectPeople(e, uuid);
                }}
              >
                <PersonValueView className="my-px px-2 py-1.5" userId={uuid} />
                <Icon
                  size="normal"
                  onClick={(e) => {
                    e.stopPropagation();
                    removePeople(uuid);
                  }}
                  name={'IcUploadCancel'}
                  className="text-grey4"
                  style={{ width: '18px', height: '18px' }}
                />
              </div>
              {index === array.length - 1 && (
                <TextWithIcon
                  className={`${itemClassName} text-grey4`}
                  name={'IcAddBoard'}
                  text="添加人员"
                  onClick={(e) => {
                    selectPeople(e);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      {filter.createdTime && (
        <div>
          <div className="flex items-center mt-2.5 ml-2 h-9 select-none text-center">创建时间</div>
          <div
            className="flex justify-between items-center cursor-pointer rounded hover:bg-black_006 pb-2"
            onClick={(e) => {
              selectCreateTime(e);
            }}
          >
            <div>
              <TextWithIcon
                className={`pl-2 h-10 text-black`}
                name={'IcHistory'}
                text={dayjs(filter.createdTime.start).format(DATE_FORMAT)}
              />
              <div className="ml-9 text-black">
                - {dayjs(filter.createdTime.end).format(DATE_FORMAT)}
              </div>
            </div>
            <Icon
              size="normal"
              onClick={(e) => {
                e.stopPropagation();
                updateFilter(() => {
                  return { createdTime: undefined };
                });
              }}
              name={'IcUploadCancel'}
              className="text-grey4 mr-2"
              style={{ width: '18px', height: '18px' }}
            />
          </div>
        </div>
      )}
      {filter.lastEditedTime && (
        <div>
          <div className="flex items-center mt-2.5 ml-2 h-9 select-none text-center">最后编辑</div>
          <div
            className="flex justify-between items-center cursor-pointer rounded hover:bg-black_006 pb-2"
            onClick={(e) => {
              selectLastEditTime(e);
            }}
          >
            <div>
              <TextWithIcon
                className={`pl-2 h-10 text-black`}
                name={'IcHistory'}
                text={dayjs(filter.lastEditedTime.start).format(DATE_FORMAT)}
              />
              <div className="ml-9 text-black">
                - {dayjs(filter.lastEditedTime.end).format(DATE_FORMAT)}
              </div>
            </div>
            <Icon
              size="normal"
              onClick={(e) => {
                e.stopPropagation();
                updateFilter(() => {
                  return { lastEditedTime: undefined };
                });
              }}
              name={'IcUploadCancel'}
              className="text-grey4 mr-2"
              style={{ width: '18px', height: '18px' }}
            />
          </div>
        </div>
      )}
      <div>
        {filter.ancestors?.map((uuid, index, array) => {
          return (
            <div key={index}>
              {index === 0 && (
                <div className="flex items-center mt-2.5 ml-2 h-9 select-none">页面内搜索</div>
              )}
              <div
                className="cursor-pointer rounded py-1 pl-1 flex justify-between items-center hover:bg-black_006"
                onClick={(e) => {
                  selectPage(e, uuid);
                }}
              >
                <InlinePage
                  uuid={uuid}
                  interactable={false}
                  className="text-ellipsis pointer-events-none"
                  showUnderLine={false}
                />
                <Icon
                  size="normal"
                  onClick={(e) => {
                    e.stopPropagation();
                    removePage(uuid);
                  }}
                  name={'IcUploadCancel'}
                  className="text-grey4 mr-2"
                  style={{ width: '18px', height: '18px' }}
                />
              </div>
              {index === array.length - 1 && (
                <TextWithIcon
                  className={`${itemClassName} text-grey4`}
                  name={'IcAddBoard'}
                  text="添加页面"
                  onClick={(e) => {
                    selectPage(e);
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
