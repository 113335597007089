import type { AIMemberDTO, SpaceDTO, UserDTO } from '@next-space/fe-api-idl';
import { isPrivate } from '../build-in';
import { useEffect } from 'react';
import { $setAiStore } from './service/ai-store';

interface Props {
  space?: SpaceDTO;
  user?: UserDTO;
  members: AIMemberDTO[];
}
export const useEnableAIByCommon = (props: Props) => {
  const { space, user, members } = props;

  const { permissionGroups = [] } = space ?? {};
  const groupsMap = new Map<string, Set<string>>();
  permissionGroups.forEach((group) => {
    groupsMap.set(group.id, new Set(group.userIds));
  });
  const isAllowed = true;
  // const isPersonal = space?.planType?.toLowerCase().includes('personal');
  // let isAllowed = false;
  // if (isPersonal) {
  //   isAllowed = true;
  // } else {
  //   for (const item of members) {
  //     if (
  //       item.type === 'space' ||
  //       (item.type === 'user' && user?.uuid === item.uuid) ||
  //       (user?.uuid && item.type === 'group' && groupsMap.get(item.uuid)?.has(user?.uuid))
  //     ) {
  //       isAllowed = true;
  //       break;
  //     }
  //   }
  // }

  // const spaceAiOpen = space?.setting?.enableAI ?? true;
  // NOTE 空间ai全部默认可用,有次数限制会报错弹升级窗口
  const spaceAiOpen = true;

  return {
    enableAI: spaceAiOpen && isAllowed && !isPrivate(),
    spaceAiOpen,
    isAllowed,
  };
};

export const useFetchAiConfig = (spaceId: string, getApi: () => Promise<AIMemberDTO[]>) => {
  useEffect(() => {
    if (spaceId) {
      // 检测空间成员是否有AI权限
      void getApi().then((data) => {
        $setAiStore({ aiMembers: data });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);
};
