import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useDeletePageCompletely } from 'src/hooks/page';
import { getState } from 'src/redux/store';
import { ViewPath } from 'src/utils';
import { getFileClass } from 'src/utils/file-type';
import { useGetPageId } from 'src/utils/getPageId';
import { useCloseRight } from 'src/utils/right-utils';

/** 打开彻底删除确认框 */
export const useOpenCompleteDelete = (uuid: string) => {
  const openModal = useOpenModal();
  const completeDelete = useDeletePageCompletely();
  const pageId = useGetPageId();
  const closeRight = useCloseRight();
  const history = useHistory();

  return useCallback(
    (confirm?: () => void) => {
      const block = getState().blocks[uuid];
      if (!block) return;

      openModal.warning({
        title: <>确定彻底删除该{getFileClass(block.type)}?</>,
        confirm: async () => {
          await completeDelete(block.uuid);
          message.success('已彻底删除');
          if (confirm) {
            confirm();
          } else if (pageId === block.uuid) {
            if (
              [BlockType.PAGE, BlockType.COLLECTION_VIEW_PAGE, BlockType.FOLDER].includes(
                block.type
              )
            ) {
              closeRight();
            } else {
              history.push(ViewPath.main);
            }
          }
        },
      });
    },
    [completeDelete, openModal, uuid, pageId, history, closeRight]
  );
};
