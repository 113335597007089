import type { FC, MouseEvent } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useCurrentSpace, useCurrentSpaceView } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import { useTransaction } from 'src/hooks/use-transaction';
import { SettingModalCommon } from '../common';
import { Option } from '../option';
import { OpenSettingFrom } from '../type';
import { useOpenAskUpgradeSpace } from '../use-open-ask-upgrade-space';
import { useOpenUpgradeSpace } from '../use-open-setting-modal';
import { createPageWidthFont, createPageWidthLayout } from './const';
import { PageFontFamily } from '@flowus/common/const';
import { ProIcon } from '../../header/pro_icon';

const fontLabel: any = {
  default: '默认字体',
  lxgwWenKai: '霞鹜文楷',
  sourceHanSerif: '思源宋体',
  loraVariable: 'Lora',
  dmMono: 'DM Mono',
};
const layoutLabel = {
  default: '默认布局',
  compact: '紧密布局',
  loose: '宽松布局',
};

/** 对当前空间且仅对自己有效，因此字段是存到spaceView上的 */
export const PageLayoutSetting: FC<{}> = () => {
  useIsDarkMode();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openModal = useOpenModal();
  const spaceView = useCurrentSpaceView();
  const updateSpaceView = useUpdateSpaceView();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const space = useCurrentSpace();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  // 已经不是专业/团队版用户的话就用默认的
  const fontFormat = !isFreeSpaceLimit ? spaceView?.setting.fontFormat ?? 'default' : 'default';
  const pageLayout = !isFreeSpaceLimit ? spaceView?.setting.pageLayout ?? 'default' : 'default';
  const transaction = useTransaction();

  const onClickFontComponent = (_fontFormat: typeof fontFormat) => {
    if (
      isFreeSpaceLimit &&
      ['lxgwWenKai', 'sourceHanSerif', 'loraVariable', 'dmMono'].includes(_fontFormat)
    ) {
      openAskUpgradeSpace(() => {
        openUpgradeSpace(OpenSettingFrom.custom_font_layout);
      });
      return;
    }
    transaction(() => {
      spaceView &&
        updateSpaceView(spaceView.uuid, {
          setting: {
            fontFormat: _fontFormat,
          },
        });
    });
  };
  const onClickLayoutComponent = (_pageLayout: typeof pageLayout) => {
    if (isFreeSpaceLimit && (_pageLayout === 'compact' || _pageLayout === 'loose')) {
      openAskUpgradeSpace(() => {
        openUpgradeSpace(OpenSettingFrom.custom_font_layout);
      });
      return;
    }
    transaction(() => {
      spaceView &&
        updateSpaceView(spaceView.uuid, {
          setting: {
            pageLayout: _pageLayout,
          },
        });
    });
  };
  const openFontSetting = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-36 py-2.5"
          items={createPageWidthFont.map((i, index) => ({
            type: ListItemType.OPERATION,
            data: {
              title: i.title,
              value: i.value,
              selected: fontFormat === i.value,
              rightText: isFreeSpaceLimit && index !== 0 ? <ProIcon absolute={false} /> : '',
              className:
                index === 1
                  ? `font-[${__BUILD_IN__ ? PageFontFamily.lora : PageFontFamily.lxgwWenKai}]`
                  : index === 2
                  ? `font-[${__BUILD_IN__ ? PageFontFamily.DMMono : PageFontFamily.sourceHanSerif}]`
                  : '',
            },
          }))}
          onItemClick={(_, index) => {
            const item = createPageWidthFont[index];
            item && onClickFontComponent(item.value as typeof fontFormat);
            onCloseModal();
          }}
        />
      ),
    });
  };
  const openLayoutSetting = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="next-modal w-36 py-2.5"
          items={createPageWidthLayout.map((i, index) => ({
            type: ListItemType.OPERATION,
            data: {
              title: i.title,
              value: i.value,
              selected: pageLayout === i.value,
              rightText: isFreeSpaceLimit && index !== 1 ? <ProIcon absolute={false} /> : '',
            },
          }))}
          onItemClick={(_, index) => {
            const item = createPageWidthLayout[index];
            item && onClickLayoutComponent(item.value as typeof pageLayout);
            onCloseModal();
          }}
        />
      ),
    });
  };
  let label = '';
  if (space.planType === 'freePersonal') {
    label = '个人专业版';
  } else if (space.planType === 'freeTeam') {
    label = '团队版';
  }
  return (
    <>
      <SettingModalCommon.SettingDivider />
      <div className="flex items-center mt-5">
        <div className="text-t2 ">新建页面字体及布局设置</div>
        {label && (
          <div className="px-1.5 py-0.5 text-center ml-2 rounded text-t2 text-grey2 bg-grey/20">
            {label}
          </div>
        )}
      </div>
      <div className="text-t3 mt-1 text-grey3">
        新建文档/多维表页面会默认为指定样式，仅在当前空间账号下生效
      </div>
      <Option
        showSplitLine={false}
        title="字体样式"
        rightPart={
          <SettingModalCommon.DropdownButton
            onClick={openFontSetting}
            content={fontLabel[fontFormat ?? 'default']}
          />
        }
      />
      <Option
        className="mt-0"
        showSplitLine={false}
        title="布局样式"
        rightPart={
          <SettingModalCommon.DropdownButton
            onClick={openLayoutSetting}
            content={layoutLabel[pageLayout ?? 'default']}
          />
        }
      />
    </>
  );
};
