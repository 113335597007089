import { keys, values } from 'lodash-es';
import { createContext, useCallback, useContext } from 'react';
import { useObservable } from '../../hooks/use-observable';
import { fastEqual } from '../../utils/tools';
import type { ModalSchema } from '../type';
import { NextModalContext } from './state';

export const useNextModalObs = () => {
  const obs$ = useContext(NextModalContext);
  return obs$.state;
};

export const useNextModalConfig = () => {
  const obs$ = useContext(NextModalContext);
  return obs$.config;
};

export const useGetOpenModalList = () => {
  const obs$ = useNextModalObs();
  return useObservable(obs$, values, [], []);
};

export const useGetOpenModalListId = () => {
  const obs$ = useNextModalObs();
  return useObservable(obs$, keys, [], []);
};

export const useIsExistModalId = (modalId: string) => {
  const obs$ = useNextModalObs();
  return useObservable(obs$, (nodes) => Boolean(nodes[modalId]), [modalId], false);
};

export const useGetIsExistModalId = () => {
  const obs$ = useNextModalObs();

  return useCallback(
    (modalId: string) => {
      return Boolean(obs$.getValue()[modalId]);
    },
    [obs$]
  );
};

export const useSetNextModalState = () => {
  const obs$ = useNextModalObs();

  return useCallback(
    (selector: (preState: ModalSchema.NodesType) => ModalSchema.NodesType) => {
      const preState = obs$.getValue();
      const newState = selector(preState);
      if (!fastEqual(preState, newState)) {
        obs$.next(newState);
      }
    },
    [obs$]
  );
};

export const ModalContext = createContext(false);
/** 判断当前组件是否在nextModal弹窗里面 */
export const useInModalContext = () => {
  return useContext(ModalContext);
};
