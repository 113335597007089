import type { FC } from 'react';
import { useState } from 'react';
import { Button } from 'src/common/components/button';
import { PRODUCT_NAME } from 'src/const/title';

import { CheckboxText } from './checkbox-text';

interface Props {
  onNext: (selectedReasons: boolean[], otherReason?: string) => void;
  onCancel: () => void;
}
const MAX_TEXTAREA_LENGTH = 100;
const REASONS = ['产品功能复杂，很难上手', '部分使用需求未被满足', '产品体验不佳', '安全/隐私问题'];

export const LogoffReason: FC<Props> = (props) => {
  const [reasons, setReasons] = useState<boolean[]>([false]);
  const [otherReason, setOtherReason] = useState<string>('');
  const isNoReason = reasons.every((hasReason) => !hasReason && otherReason.length === 0);
  return (
    <div className="flex flex-col w-[400px] px-[30px] overflow-y-auto">
      <div className="text-center text-t1-medium mt-10">注销服务</div>
      <div className="mt-5 mb-1.5 text-t2">
        亲爱的用户，请留下你的注销原因，{PRODUCT_NAME} 团队将持续改进服务。
      </div>
      {REASONS.map((reason, index) => {
        return (
          <CheckboxText
            className="mt-3 "
            key={index}
            onCheck={(check) => {
              const newReasons = reasons.slice(0);
              newReasons[index] = check;
              setReasons(newReasons);
            }}
            content={reason}
          />
        );
      })}
      <div className="relative my-2 h-[112px]">
        <textarea
          placeholder="其他(选填)"
          className="py-2 px-3 w-full bg-grey9 rounded border border-grey6 focus-within-border text-t2 resize-none"
          rows={4}
          maxLength={MAX_TEXTAREA_LENGTH}
          onChange={(e) => setOtherReason(e.target.value.trim())}
        ></textarea>
        <div className="absolute right-3 bottom-2">
          {otherReason.length} <span className="text-grey3">{`/ ${MAX_TEXTAREA_LENGTH}`}</span>
        </div>
      </div>
      <Button
        className="mb-4"
        disable={isNoReason}
        colorType="active"
        onClick={() => {
          props.onNext(reasons, otherReason);
        }}
      >
        继续
      </Button>
      <Button onClick={props.onCancel} className="mb-5">
        取消
      </Button>
    </div>
  );
};
