import { useCallback } from 'react';
import { qs } from 'src/utils/querystring';
import { useMasterHistory } from 'src/utils/right-utils';
import type { JsonValue } from 'type-fest';

export const useOpenInRight = () => {
  const history = useMasterHistory();
  return useCallback(
    (pageId: string, params: { state?: JsonValue; hash?: string } = {}) => {
      history.push({
        ...history.location,
        search: qs.stringify({ 'right-page-id': pageId }),
        ...params,
      });
    },
    [history]
  );
};
