import { cx } from '@flowus/common/cx';
import { setLocalStorage } from '@flowus/common/utils/local-storage';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'src/common/components/avatar';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { PDF_HISTORY_SIDEBAR } from 'src/common/const';
import { DesktopComponents } from 'src/components/desktop';
import { useCurrentSpace } from 'src/hooks/space';
import { setAppUiState, usePDFHistorySideBar } from 'src/services/app';

export const TogglePdfAISidebar: FC<{ showTitle?: boolean; isSidebar?: boolean }> = ({
  isSidebar = false,
  showTitle = true,
}) => {
  const space = useCurrentSpace();
  const history = useHistory();
  const pdfHistorySidebar = usePDFHistorySideBar();

  return (
    <>
      <DesktopComponents.PreviewPDFAsideHeaderPlaceholder />
      <Avatar
        name={space.title}
        color={space.backgroundColor}
        icon={space.icon}
        onClick={() => history.push('/')}
        className="w-[26px] h-[26px] text-t4 animate-click"
        style={{ fontSize: '18px' }}
      />
      {showTitle && (
        <span
          className="text-t2-medium ml-2 animate-click text-ellipsis"
          onClick={() => history.push('/')}
        >
          {space.title}
        </span>
      )}
      <Tooltip
        popup={pdfHistorySidebar ? '收起侧边栏' : '收起侧边栏'}
        className={cx(
          'flex items-center justify-center animate-hover w-6 h-6 flex-shrink-0 ml-2 cursor-pointer',
          isSidebar && ' ml-auto',
          !showTitle && 'mr-2'
        )}
        onClick={() => {
          setAppUiState({ $pdfHistorySidebar: !pdfHistorySidebar });
          setLocalStorage(PDF_HISTORY_SIDEBAR, JSON.stringify(!pdfHistorySidebar));
        }}
      >
        <Icon
          name={pdfHistorySidebar ? 'IcFold' : 'IcUnfold'}
          size="middle"
          className=" text-grey3"
        />
      </Tooltip>
    </>
  );
};
