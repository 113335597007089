import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useFileDownLimit } from 'src/hooks/limit/file-down-limit';
import { bizTracker } from 'src/utils/biz-tracker';
import { numberToPercent } from 'src/utils/number';

import { PrintService } from '../service/pdf-print';

interface Props {
  uuid?: string;
  pdfViewer: PDFViewer;
  className?: string;
}

const PDF_PRINT_MODAL = 'PDF-PRINT';

export const PrintButton: FC<Props> = ({ pdfViewer, className, uuid }) => {
  const printService = useRef<PrintService>();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const fileDownLimit = useFileDownLimit();

  useEffect(() => {
    const handleAfterPrint = () => {
      closeModal(PDF_PRINT_MODAL);
      printService.current?.destroy();
    };

    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [closeModal]);

  const print = async () => {
    bizTracker.event('pdf_print', { file_id: uuid });
    if (!pdfViewer.pageViewsReady) {
      window.alert('PDF 尚未载入完成！');
      return;
    }
    await fileDownLimit();
    openModal.modal({
      keyboard: false,
      modalId: PDF_PRINT_MODAL,
      content: ({ onCloseModal }) => (
        <div className="flex flex-col next-modal px-[30px] w-[400px] pb-[30px]">
          <p className="text-t1-medium mt-[30px] text-center">正在准备打印文档...</p>

          <p className="text-t2 text-active_color" ref={textRef}>
            打印准备中
          </p>
          <div className="rounded-sm h-1 bg-grey5 mt-2 relative">
            <div ref={progressRef} className="rounded-sm h-1 bg-active_color w-0"></div>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              onClick={() => {
                printService.current?.abort();
                onCloseModal();
              }}
            >
              取消
            </Button>
          </div>
        </div>
      ),
    });

    const pagesOverview = pdfViewer.getPagesOverview();
    const print = (printService.current = new PrintService(pdfViewer.pdfDocument, pagesOverview));
    print.onProgress = (pageNumber, total) => {
      const progress = pageNumber / total;

      if (progress > 0.99) {
        // 单页 pdf 的时候 modal 可能还没有被创建，所以需要在 afterprint 的时机再关一次
        closeModal(PDF_PRINT_MODAL);
        return;
      }

      if (!textRef.current || !progressRef.current) return;
      textRef.current.innerText = `打印准备中${numberToPercent(Number(progress.toFixed(2)))}`;
      const parentWidth = progressRef.current.parentElement?.clientWidth;
      if (!parentWidth) return;
      progressRef.current.style.width = `${parentWidth * progress}px`;
    };

    void print.print();
  };

  return (
    <Tooltip placement="bottom" className={className} popup="打印">
      <button onClick={print} className={'animate-hover p-1 flex'}>
        <Icon size="middle" name="IcMenuPrint" />
      </button>
    </Tooltip>
  );
};
