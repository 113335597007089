import { useCallback } from 'react';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { useIsInRight } from 'src/utils/right-utils';

export const useQueryBlockNode = () => {
  const isInRight = useIsInRight();
  return useCallback(
    (blockId: string) => {
      const container = querySelectorFromMainContent(
        '.block-content',
        isInRight
      ) as HTMLDivElement | null;

      return container?.querySelector(
        `[data-block-id="${blockId}"]:not([data-toc-type])`
      ) as HTMLElement | null;
    },
    [isInRight]
  );
};
