import { CaptchaSendType, PhoneCheckStatus, UserAuthorizationType } from '@next-space/fe-api-idl';
import { useCallback, useMemo, useState } from 'react';
import type { ViewProps } from '../types';
import { AccountStatus } from '../types';
import { getBizTrackerPlatform, md5Sum, Regex, sleep } from '../utils';
import { loginInjector } from '../utils/init';
// import '../yidun-captcha';
export const useLogin = (props?: ViewProps) => {
  const [accountStatus, setAccountStatus] = useState<AccountStatus | undefined>(
    props?.accountStatus
  );
  const [account, _setAccount] = useState(props?.account ?? '');
  const [accountError, setAccountError] = useState('');
  /**验证码 */
  const [verifyCode, _setVerifyCode] = useState('');
  const [verifyCodeError, setVerifyCodeError] = useState('');
  /**密码 */
  const [password, _setPassword] = useState(props?.password ?? '');
  const [passwordError, setPasswordError] = useState('');
  /**邮箱登录的授权码 */
  const [authorization, setAuthorization] = useState<
    | {
        code: string;
        type: UserAuthorizationType;
      }
    | undefined
  >(props?.authorization);
  const [promotionChannel, setPromotionChannel] = useState<string>();

  const [inviteCode, _setInviteCode] = useState<string>(props?.newInviteCode ?? '');

  const [yidunCaptcha, setYidunCaptcha] = useState<string>('tmp');

  const isToLogin = accountStatus === AccountStatus.CAN_LOGIN;
  const isToRegister = accountStatus === AccountStatus.CAN_SIGN;

  const setVerifyCode = useCallback((value: string) => {
    _setVerifyCode(value.trim());
    setVerifyCodeError('');
  }, []);

  const setPassword = useCallback((value: string) => {
    _setPassword(value.trim());
    setPasswordError('');
  }, []);

  const setAccount = useCallback(
    (value: string) => {
      _setAccount(value.trim());
      setAccountError('');
      setVerifyCode('');
      setAccountStatus((s) => {
        //如果当前是绑定手机状态，则不需要更改状态
        if (s === AccountStatus.NEED_BIND_PHONE) return s;
        //如果手机号改变了，则图形验证码也需要重新验证一次
        setYidunCaptcha('tmp');
        return undefined;
      });
    },
    [setVerifyCode]
  );

  const setInviteCode = useCallback((value: string) => {
    _setInviteCode(value);
  }, []);

  /**检查用户是否有注册 */
  const checkAccountRegisterStatus = async () => {
    if (!checkAccount()) return;
    const updateState = (accountStatus: PhoneCheckStatus) => {
      if (accountStatus === PhoneCheckStatus.CAN_LOGIN) {
        setAccountStatus(AccountStatus.CAN_LOGIN);
      } else {
        setAccountStatus(AccountStatus.CAN_SIGN);
      }
    };
    if (Regex.Email.test(account)) {
      const res = await loginInjector.request.infra.checkEmail({
        email: account,
      });
      updateState(res.status);
      return res;
    } else if (Regex.Mobile.test(account)) {
      const res = await loginInjector.request.infra.checkPhone(account);
      updateState(res.status);
      return res;
    }
    setAccountError('账号格式不正确');
  };
  const checkAccount = () => {
    if (!Regex.Email.test(account) && !Regex.Mobile.test(account)) {
      setAccountError('账号格式不正确');
      return false;
    }
    return true;
  };

  const checkVerifyCode = () => {
    if (!Regex.VerifyCode.test(verifyCode)) {
      setVerifyCodeError('验证码错误');
      return false;
    }
    if (props?.inviteCodeRequired) {
      if (!AccountStatus.CAN_LOGIN && !inviteCode) {
        setVerifyCodeError('邀请码不能为空');
        return false;
      }
    }
    return true;
  };

  const checkPassword = () => {
    if (!password || !Regex.Password.test(password)) {
      setPasswordError('密码格式有误');
      return false;
    }
    return true;
  };
  const isPhoneNumber = useCallback(() => {
    return Regex.Mobile.test(account);
  }, [account]);

  const isEmail = () => {
    return Regex.Email.test(account);
  };
  /**发送手机/邮箱验证码，类型较多，内部状态不好判断，统一由调用方自己判断填写 */
  const sendVerifyCode = async (type: CaptchaSendType) => {
    if (!checkAccount()) return;
    const isPhone = isPhoneNumber();
    if (isPhone) {
      const res = await loginInjector.request.infra.createPhoneCaptcha.raw(account, {
        type,
      });

      if (res.code !== 200) {
        setVerifyCodeError(res.msg);
        return;
      }
      loginInjector.message.success('验证码已发送');
    } else {
      const res = await loginInjector.request.infra.emailCaptcha.raw({
        email: account,
        type,
      });
      if (res.code !== 200) {
        setVerifyCodeError(res.msg);
        return;
      }
      loginInjector.message.success('验证码已发送');
    }
    return true;
  };
  const tryRegister = async () => {
    if (!checkVerifyCode()) return;
    if (accountStatus === AccountStatus.CAN_SIGN && !checkPassword()) {
      return;
    }
    //如果是手机或者已经拿到验证码了就直接注册
    if (isPhoneNumber()) {
      return register();
    }
    //如果是邮箱注册则跳到绑定手机页面
    if (isEmail()) {
      const res = await loginInjector.request.infra.emailAuthorization({
        email: account,
        captcha: verifyCode,
        type: CaptchaSendType.SIGNUP,
      });
      setAuthorization({
        code: res.authorizationCode ?? '',
        type: UserAuthorizationType.EMAIL,
      });
      setAccountStatus(AccountStatus.NEED_BIND_PHONE);
    }
  };
  /**返回user表示最后一步，需要回调success */
  const register = async () => {
    if (!checkVerifyCode()) return;
    const encryptPassword = password ? md5Sum(password) : undefined;
    const res = await loginInjector.request.infra.usersRegister.raw({
      type: authorization?.type ?? UserAuthorizationType.PHONE,
      phone: account,
      captcha: verifyCode,
      //@ts-ignore check
      password: encryptPassword,
      //@ts-ignore check
      inviteCode: inviteCode ?? undefined,
      //@ts-ignore check
      authorizationCode: authorization?.code,
      //@ts-ignore check
      promotionChannel,
      validate: yidunCaptcha,
    });

    if (res.code === 200) {
      if (promotionChannel) {
        void loginInjector.request.infra.behaviorReport.raw({
          topic: 'activity',
          business: {
            type: 'enter',
            activeFrom: 'new_activation',
          },
        });
      }

      loginInjector.event(
        'user_register',
        {
          is_success: true,
          activation_channel: inviteCode ? '邀请' : '其它',
          promotion_channel: promotionChannel,
          ...getBizTrackerPlatform(),
        },
        true
      );
      loginInjector.event(
        'config',
        {
          user_unique_id: res.data.uuid,
        },
        true
      );
      loginInjector.afterLogin();
      await sleep(800);
      return res.data;
    }

    loginInjector.event('user_register', { is_success: false, fail_reason: res.code });
    if (res.code === 1009) {
      //已经被注册过
      setAccountStatus(AccountStatus.BIND_FAIL);
      return;
    }
    //@ts-ignore code
    if (res.code === 1003) {
      loginInjector.message.error('恶意注册已拦截，如有疑问请联系客服');
      return;
    }
    await sleep(800);
    loginInjector.message.error(res.msg);
  };

  const loginByVerifyCode = async () => {
    if (!checkAccount()) return;
    if (!checkVerifyCode()) return;
    const res = await loginInjector.request.infra.loginCaptcha.raw({
      account,
      captcha: verifyCode,
    });
    if (res.code !== 200) {
      setVerifyCodeError(res.msg);
      return;
    }
    loginInjector.event(
      'config',
      {
        user_unique_id: res.data.uuid,
      },
      true
    );
    loginInjector.afterLogin();
    return res.data;
  };

  const loginByPassword = async () => {
    if (accountStatus === undefined) {
      const res = await checkAccountRegisterStatus();
      if (res?.status === PhoneCheckStatus.CAN_SIGN) return;
    }
    if (!Regex.Email.test(account) && !Regex.Mobile.test(account)) {
      setAccountError('账号格式不正确');
      return;
    }
    if (!Regex.Password.test(password)) {
      setPasswordError('账号或密码错误');
      return;
    }

    const res = await loginInjector.request.infra.loginPassword.raw({
      account,
      password: md5Sum(password),
    });
    if (res.code === 200) {
      loginInjector.event(
        'config',
        {
          user_unique_id: res.data.uuid,
        },
        true
      );
      loginInjector.afterLogin();
      return res.data;
    }
    setPasswordError(res.msg);
  };

  /**重置密码,返回user表示成功了，要跳到主应用 */
  const resetPassword = async () => {
    if (!checkAccount()) return;
    if (!checkVerifyCode()) return;
    if (!checkPassword()) return;
    const isPhone = isPhoneNumber();
    if (accountStatus === AccountStatus.CAN_SIGN) {
      //如果还没注册就直接注册一个,如果是邮箱就会拿授权码去绑定手机
      const user = await tryRegister();
      if (user) return user;
      //如果没返回用户信息那就是邮箱验证，需要去绑定手机号码
      !isPhone && setAccountStatus(AccountStatus.NEED_BIND_PHONE);
    } else {
      //设置密码
      const encryptPassword = md5Sum(password);
      if (isPhone) {
        await loginInjector.request.infra.resetUserPasswordByPhone(account, {
          password: encryptPassword,
          captcha: verifyCode,
        });
      } else {
        await loginInjector.request.infra.resetUserPasswordByEmail({
          email: account,
          password: encryptPassword,
          captcha: verifyCode,
        });
      }
      //设置完密码之后需要立刻登录
      const user = await loginInjector.request.infra.loginPassword({
        account,
        password: encryptPassword,
      });
      loginInjector.event(
        'config',
        {
          user_unique_id: user.uuid,
        },
        true
      );
      loginInjector.afterLogin();
      return user;
    }
  };

  const next = () => {
    switch (accountStatus) {
      case AccountStatus.CAN_LOGIN: {
        return loginByVerifyCode();
      }
      case AccountStatus.CAN_SIGN: {
        return tryRegister();
      }
      case AccountStatus.NEED_BIND_PHONE: {
        return register();
      }
      case undefined:
        void checkAccountRegisterStatus();
        break;
      default:
    }
  };

  const yidunCheck = isPhoneNumber() || isEmail();

  const canSendVerifyCode = useMemo(() => {
    if (isToRegister) {
      if (props?.inviteCodeRequired) {
        return !!inviteCode;
      }
    }
    return yidunCheck;
  }, [inviteCode, isToRegister, props?.inviteCodeRequired, yidunCheck]);

  return {
    canSendVerifyCode,
    isToLogin,
    isToRegister,
    accountStatus,
    account,
    accountError,
    verifyCode,
    verifyCodeError,
    password,
    passwordError,
    inviteCode,
    authorization,
    yidunCaptcha,
    setVerifyCodeError,
    setVerifyCode,
    setAccountStatus,
    next,
    setAccount,
    sendVerifyCode,
    setInviteCode,
    setPassword,
    loginByPassword,
    setAuthorization,
    resetPassword,
    setPromotionChannel,
    isPhoneNumber,
    checkAccount,
    checkVerifyCode,
    setPasswordError,
    setYidunCaptcha,
  };
};
