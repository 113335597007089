import { cx } from '@flowus/common/cx';
import { UserAuthorizationType } from '@next-space/fe-api-idl';
import { split } from 'lodash-es';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useModel } from 'src/common/create-model';
import { SettingPassword, SettingPasswordType } from 'src/components/login-uikit/setting-password';
import { useUpload } from 'src/hooks/space/use-upload';
import { useCurrentUser, useLogout, useUpdateUser } from 'src/hooks/user';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { MessageIds } from 'src/modals';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { useOpenLogoffDialog } from 'src/views/logoff';
import { SettingModalCommon, SettingProvider } from '../common';
import { useOpenBindAccountDialog } from '../use-open-bind-account-dialog';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { AccountPhone } from './account-phone';
import { Authentication } from './authentication';
import { useOpenAccountPin } from './account-pin';
import { AccountEmail } from './email';

/** 帐户设置 */
export const AccountSetting: FC = () => {
  const logout = useLogout();
  const closeSettingModal = useCloseSettingModal();
  const currentUser = useCurrentUser();
  const updateUser = useUpdateUser();
  const [userName, setUserName] = useState(currentUser.nickname);
  const [avatar, setAvatar] = useState(currentUser.avatar || '');
  const { setClickMaskClose } = useModel(SettingProvider);
  const upload = useUpload();
  const openLogoffDialog = useOpenLogoffDialog();
  const openModal = useOpenModal();
  const openBindAccountDialog = useOpenBindAccountDialog();
  const openAccountPin = useOpenAccountPin();
  const fetchMe = useFetchMe();

  const saveUserName = async () => {
    const nickname = userName.trim();

    if (split(nickname, '').length >= 2 && split(nickname, '').length <= 20) {
      await updateUser({ nickname, avatar });
      closeSettingModal();
      message.success('保存成功');
    } else {
      message.error('昵称长度应为2-20个字符');
    }
  };

  const processFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const file = fileList[0]!;
    message.loading({ key: MessageIds.UPLOAD_ICON, content: '上传中...' });
    void upload({
      file,
      type: 'public',
      searchType: 'avatar',
      onComplete: (ret) => {
        message.closeMessage(MessageIds.UPLOAD_ICON);
        if (ret.success) {
          e.target.value = '';
          message.success('上传完成');
          setAvatar(ret.ossName);
        }
      },
    });
  };

  const openSettingPassword = () => {
    openModal.modal({
      content({ onCloseModal }) {
        return (
          <SettingPassword
            phone={currentUser.phone || currentUser.email}
            type={
              currentUser.password
                ? SettingPasswordType.BY_OLD_PASSWORD
                : SettingPasswordType.BY_ACCOUNT
            }
            onSuccess={async () => {
              await fetchMe();
              message.success('设置成功');
              onCloseModal();
              message.success('登录已失效，请重新登录');
              void logout();
            }}
          />
        );
      },
    });
  };

  useEffect(() => {
    setClickMaskClose(currentUser.nickname === userName && avatar === (currentUser.avatar || ''));
  }, [userName, currentUser.nickname, setClickMaskClose, currentUser.avatar, avatar]);

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        {/* 头像 */}
        <SettingModalCommon.Header title="账号设置" name="头像" />
        <div className="mt-4 flex flex-row items-center justify-between">
          <Avatar
            name={currentUser.nickname}
            color={currentUser.backgroundColor}
            icon={{ type: 'upload', value: avatar }}
            className="rounded-full mb-3.5 text-white"
            imgClassName="rounded-full"
            iconSize={60}
            style={{ fontSize: '30px' }}
          />
          <div className="flex flex-row space-x-5">
            <Button className={cx({ hidden: !avatar })} onClick={() => setAvatar('')}>
              移除头像
            </Button>
            <Button className="px-0">
              <label className="cursor-pointer py-1.5 px-4">
                上传头像
                <input className="hidden" type="file" accept="image/*" onChange={processFile} />
              </label>
            </Button>
          </div>
        </div>
        <SettingModalCommon.SettingDivider className="mb-2.5" />

        {/* 昵称 */}
        <div className={SettingModalCommon.commonStyles.name}>昵称</div>
        <Input
          maxLength={20}
          className={SettingModalCommon.commonStyles.input}
          value={userName}
          inputClassName="h-8"
          placeholder="请输入你的昵称，长度需在2-20个字符之间"
          {...bindDataTestId(TestIds.settingAccountInput)}
          onEnter={() => saveUserName()}
          onChange={(value) => setUserName(`${value}`)}
        />
        <SettingModalCommon.SettingDivider />
        {!__PRIVATE__ && (
          <>
            <AccountEmail />
            <SettingModalCommon.SettingDivider />
          </>
        )}
        {/* 手机号 */}
        {!__BUILD_IN__ && (
          <>
            <AccountPhone />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        {/* 学生认证 */}
        {!(__BUILD_IN__ || __PRIVATE__) && (
          <>
            <Authentication />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        {/* 密码 */}
        <>
          <SettingModalCommon.Item
            leftChild={'登录密码'}
            rightChild={
              <Button onClick={openSettingPassword}>
                {currentUser.password ? '修改密码' : '设置密码'}
              </Button>
            }
          />
          <SettingModalCommon.SettingDivider />
        </>

        {/* PIN */}
        <>
          <SettingModalCommon.Item
            leftChild={
              <div className="space-y-0.5">
                <div>PIN</div>
                <div className="text-t4 text-grey3">锁屏 PIN 码</div>
              </div>
            }
            rightChild={
              <Button onClick={openAccountPin}>
                {currentUser.setting?.pin ? '修改 PIN 码' : '添加 PIN 码'}
              </Button>
            }
          />
          <SettingModalCommon.SettingDivider />
        </>

        {/* 邮箱 */}
        {!__BUILD_IN__ && (
          <>
            <SettingModalCommon.Item
              leftChild={
                <div className="space-y-0.5">
                  <div key="email">邮箱</div>
                  {currentUser.ext?.email && (
                    <div className="text-t4 text-grey3">{currentUser.ext.email.id}</div>
                  )}
                </div>
              }
              rightChild={
                <Button
                  onClick={(e) => {
                    openBindAccountDialog(e, UserAuthorizationType.EMAIL);
                  }}
                >
                  {currentUser.ext?.email ? '解除绑定' : '绑定邮箱'}
                </Button>
              }
            />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        {/* 微信 */}
        {!(__BUILD_IN__ || __PRIVATE__) && (
          <>
            <SettingModalCommon.Item
              leftChild={
                <div className="space-y-0.5">
                  <div>微信</div>
                  {currentUser.ext?.wechat && (
                    <div className="text-t4 text-grey3">{currentUser.ext?.wechat?.nickname}</div>
                  )}
                </div>
              }
              rightChild={
                <Button
                  onClick={(e) => {
                    openBindAccountDialog(e, UserAuthorizationType.WECHAT);
                  }}
                >
                  {currentUser.ext?.wechat ? '解除绑定' : '绑定微信'}
                </Button>
              }
            />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        {/* QQ */}
        {!(__BUILD_IN__ || __PRIVATE__) && (
          <>
            <SettingModalCommon.Item
              leftChild={
                <div className="space-y-0.5">
                  <div>QQ</div>
                  {currentUser.ext?.qq?.nickname && (
                    <div className="text-t4 text-grey3">{currentUser.ext.qq.nickname}</div>
                  )}
                </div>
              }
              rightChild={
                <Button
                  onClick={(e) => {
                    openBindAccountDialog(e, UserAuthorizationType.QQ);
                  }}
                >
                  {currentUser.ext?.qq ? '解除绑定' : '绑定QQ'}
                </Button>
              }
            />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        {/* APPLE ID */}
        {!__PRIVATE__ && (
          <>
            <SettingModalCommon.Item
              leftChild={
                <div className="space-y-0.5">
                  <div>Apple ID</div>
                  {currentUser.ext?.apple && (
                    <div className="text-t4 text-grey3">
                      {currentUser.ext?.apple.nickname ||
                        currentUser.ext?.apple.email ||
                        currentUser.ext?.apple.id}
                    </div>
                  )}
                </div>
              }
              rightChild={
                <Button
                  onClick={(e) => {
                    openBindAccountDialog(e, UserAuthorizationType.APPLE);
                  }}
                >
                  {currentUser.ext?.apple ? '解除绑定' : '绑定Apple ID'}
                </Button>
              }
            />
            <SettingModalCommon.SettingDivider />
          </>
        )}

        <SettingModalCommon.Item
          leftChild={
            <div className="space-y-0.5">
              <div>注销帐户</div>
              <div className="text-t4 text-grey3">
                退出所有空间，仅有自己的空间将会被彻底删除。不可恢复
              </div>
            </div>
          }
          rightChild={
            <Button
              colorType="red"
              onClick={() => {
                void openLogoffDialog();
              }}
            >
              注销帐户
            </Button>
          }
        />
      </div>
      <SettingModalCommon.FooterBar cancel={() => closeSettingModal()} confirm={saveUserName} />
    </>
  );
};
