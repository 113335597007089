import { getViewFormat } from 'src/hooks/block/get-view-format';
import { UPDATE_COLLECTION_VIEW } from 'src/redux/actions/collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { dispatch } from '../../store';
import { updateBlock } from '../block/update';
import { judgeSharePage } from 'src/utils/getPageId';
import { LRUCache } from '@flowus/common/utils/lru-cache';
import type { CollectionFilterGroup } from '@next-space/fe-api-idl';
import localforage from 'localforage';

export const update = (uuid: string, patch: Partial<NextCollectionView>, ignoreOp?: boolean) => {
  const viewInfo = getViewFormat(uuid);
  if (!viewInfo) return;
  const { view } = viewInfo;

  if (!ignoreOp) {
    // 触发collection的更新updateAt和updateBy
    updateBlock(view.parentId, {});
  }
  tryCacheFilter(uuid, ignoreOp, patch.format?.filter);

  dispatch(
    UPDATE_COLLECTION_VIEW({
      uuid,
      patch: {
        ...patch,
        updatedAt: Date.now(),
      },
      ignoreOp,
    })
  );
};

const filterCache = new LRUCache<string, CollectionFilterGroup>(localforage, 'filters', 100);

const tryCacheFilter = (
  uuid: string,
  ignoreOp: boolean | undefined,
  filter: CollectionFilterGroup | undefined
) => {
  // 分享页把filter给存一下
  if (filter) {
    if (ignoreOp) {
      filterCache.put(uuid, filter);
    } else {
      // 如果线上修改过，则本地缓存就删一下。
      filterCache.delete(uuid);
    }
  }
};
export const getFilter = (viewId: string) => {
  return filterCache.get(viewId) || undefined;
};
