const AutoScrollThreshold = 100;

export let rafX: number;
export let rafY: number;

export const autoScrollX = (x: number, container: HTMLElement, threhold = AutoScrollThreshold) => {
  const rect = container.getBoundingClientRect();

  const step = () => {
    if (x - rect.left < threhold) {
      const delta = threhold - (x - rect.left);
      const speed = delta / 10;
      container.scrollLeft -= speed;
      rafX = requestAnimationFrame(step);
    }

    if (rect.right - x < threhold) {
      const delta = threhold - (rect.right - x);
      const speed = delta / 10;
      container.scrollLeft += speed;
      rafX = requestAnimationFrame(step);
    }
  };

  cancelAnimationFrame(rafX);
  rafX = requestAnimationFrame(step);
};

interface ThresholdOffset {
  top?: number;
  bottom?: number;
}

interface AutoScrollY {
  y: number;
  container: HTMLElement;
  threshold?: number;
  offset?: ThresholdOffset;
}

export const autoScrollY = ({
  y,
  container,
  threshold = AutoScrollThreshold,
  offset,
}: AutoScrollY) => {
  const rect = container.getBoundingClientRect();
  const thresholdTop = threshold + (offset?.top ?? 0);
  const thresholdBottom = threshold + (offset?.bottom ?? 0);
  const step = () => {
    if (y - rect.top < thresholdTop) {
      const delta = thresholdTop - (y - rect.top);
      const speed = delta / 10;
      container.scrollTop -= speed;
      rafY = requestAnimationFrame(step);
    }

    if (rect.bottom - y < thresholdBottom) {
      const delta = threshold - (rect.bottom - y);
      const speed = delta / 10;
      container.scrollTop += speed;
      rafY = requestAnimationFrame(step);
    }
  };

  cancelAnimationFrame(rafY);
  rafY = requestAnimationFrame(step);
};
