import { isDateStringValid } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { TimeFormat } from '@next-space/fe-api-idl';
import { DateFormat } from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input } from 'src/common/components/input';
import { DATE_FORMAT } from 'src/common/const';
import { globalListenerHelper, PRIORITY_PORTAL } from 'src/common/utils/global-listener-helper';
import { formatDateString } from 'src/utils/date-time-check';

interface Props {
  date: Date;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  includeTime: boolean;
  onChange: (date: Date) => void;
  autoFocus?: boolean;
}

// 为了兼容多种时间输入格式
const allTimeFormat = ['h:mm', 'H:mm', 'hh:mm', 'HH:mm'];
allTimeFormat.push(...allTimeFormat.map((v) => v.concat(' A')));

/** 日期输入框 */
export const DateInput: FC<Props> = (props) => {
  const { includeTime, date, dateFormat, timeFormat, autoFocus, onChange } = props;
  const showFullDate = dateFormat === DateFormat.LL || dateFormat === DateFormat.RELATIVE;
  const { dateStr, timeStr } = useMemo(() => {
    const day = dayjs(date);
    return {
      dateStr: day.format(showFullDate ? DATE_FORMAT : dateFormat),
      timeStr: day.format(timeFormat),
    };
  }, [date, dateFormat, showFullDate, timeFormat]);
  // 输入框输入之后需要校验通过才能通知外面，因此不能直接用props的dateStr作为value，优先使用tmpDateStr
  const [tmpDateStr, setTmpDateStr] = useState(dateStr);
  const [tmpTimeStr, setTmpTimeStr] = useState(timeStr);
  const timeChangeRef = useRef(false);
  const dateInputContainerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const dateErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const timeErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const dateInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const timeInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const [focus, setFocus] = useState(false);
  const [dateValid, setDateValid] = useState(false);
  const [timeValid, setTimeValid] = useState(false);
  const inputError = !dateValid || !timeValid;
  const onChangeRef = useRef<typeof onChange>();
  onChangeRef.current = onChange;

  useEffect(() => {
    // 如果date有变更就需要更新最新的值
    setTmpDateStr(dateStr);
  }, [date, dateStr]);

  useEffect(() => {
    // 如果date有变更就需要更新最新的值
    setTmpTimeStr(timeStr);
  }, [date, timeStr]);

  useEffect(() => {
    // 如果值有变动就重置error状态
    setDateValid(true);
    setTimeValid(true);
  }, [date]);

  /** 如果数据有变更返回true，报错返回false */
  const handleDateChange = useCallback(() => {
    // 默认输入框输入的都是对的，报错再处理
    const finalDateFormat = showFullDate ? DATE_FORMAT : dateFormat;
    let newDateStr = dateInputRef.current.value.trim();
    // 兼容YYYY-MM-DD这种日期
    newDateStr = newDateStr.replaceAll('-', '/');
    newDateStr = formatDateString(newDateStr) ?? newDateStr;
    const isDateValid = isDateStringValid(newDateStr, finalDateFormat);
    setDateValid(isDateValid);
    if (!isDateValid) return false;
    let time = '';
    let finalTimeFormat = undefined;
    if (includeTime) {
      const newTimeStr = timeInputRef.current.value.trim();
      // 兼容不同时间的输入格式
      for (const format of allTimeFormat) {
        const isValid = isDateStringValid(newTimeStr, format);
        if (isValid) {
          finalTimeFormat = format;
          break;
        }
      }
      const isTimeValid = !!finalTimeFormat;
      setTimeValid(isTimeValid);
      time = newTimeStr;
      if (!isTimeValid) return false;
    }
    let newDate: Date;
    if (includeTime) {
      newDate = dayjs(`${newDateStr} ${time}`, `${finalDateFormat} ${finalTimeFormat}`).toDate();
    } else {
      newDate = dayjs(newDateStr, finalDateFormat).toDate();
      if (dateStr === newDateStr) return;
    }
    // 走到这里说明格式校验通过
    onChangeRef.current?.(newDate);
    return true;
  }, [dateFormat, dateStr, includeTime, showFullDate]);

  useEffect(() => {
    return () => {
      if (timeChangeRef.current) {
        handleDateChange();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('enter')(event)) {
        const handle = handleDateChange();
        if (!handle) {
          // 报错了的话就拦截这个事件,拦截它外面就不会因为enter而关掉对话框
          return true;
        }
      }
    };

    globalListenerHelper.addEventListener('keydown', handleKeyDown, PRIORITY_PORTAL + 1, true);
    return () => {
      globalListenerHelper.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleDateChange]);

  useEffect(() => {
    if (!autoFocus) return;
    const timer = setTimeout(() => {
      clearTimeout(timer);
      dateInputRef.current.focus();
    });
  }, [autoFocus]);

  // 报错tip
  useEffect(() => {
    const instances: Instance[] = [];
    if (!dateValid) {
      instances.push(
        createPopper(dateInputContainerRef.current, dateErrorRef.current, {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!timeValid) {
      instances.push(
        createPopper(dateInputContainerRef.current, timeErrorRef.current, {
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }

    return () => {
      instances.forEach((popperInstance: Instance) => popperInstance.destroy());
    };
  }, [dateValid, timeValid]);

  return (
    <div className="flex flex-col px-2.5">
      <div
        ref={dateInputContainerRef}
        className={cx(`flex bg-grey9 border-grey6 rounded-sm py-[6px] h-8`, {
          'input-error': inputError,
          'input-focus': focus && !inputError,
        })}
      >
        <Input
          inputRef={dateInputRef}
          showBorder={false}
          className={`border-none ${includeTime ? 'w-1/2' : 'w-full'}`}
          style={{ backgroundColor: 'transparent' }}
          value={tmpDateStr}
          onChange={setTmpDateStr}
          onFocus={() => setFocus(true)}
          onEnter={handleDateChange}
          onBlur={() => {
            setFocus(false);
            handleDateChange();
          }}
        />
        {includeTime && (
          <Input
            inputRef={timeInputRef}
            showBorder={false}
            className="w-1/2 px-3 border-none rounded-none border-grey6"
            style={{ borderLeft: '1px solid #E5E5E5', backgroundColor: 'transparent' }}
            onFocus={() => setFocus(true)}
            onEnter={handleDateChange}
            onBlur={() => {
              setFocus(false);
              handleDateChange();
            }}
            onChange={(v) => {
              timeChangeRef.current = true;
              setTmpTimeStr(v);
            }}
            value={tmpTimeStr}
          />
        )}
      </div>

      {!dateValid && (
        <div
          ref={dateErrorRef}
          className="px-2 py-1 text-white bg-black rounded-sm bg-opacity-70 text-t4"
        >
          <span>无效日期</span>
        </div>
      )}
      {!timeValid && (
        <div
          ref={timeErrorRef}
          className="px-2 py-1 text-white bg-black rounded-sm bg-opacity-70 text-t4"
        >
          <span>无效时间</span>
        </div>
      )}
    </div>
  );
};
