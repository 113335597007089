import type { FC } from 'react';
import { animated, useSpring } from 'react-spring';

/** 有过度动画的数字 */
export const TransitionNumber: FC<{ value?: number }> = ({ value = 0 }) => {
  const props = useSpring({ number: value || 0 });
  const x = String(value).indexOf('.') + 1; // 小数点的位置
  const y = String(value).length - x; // 小数的位数

  return (
    <animated.span>
      {props.number.to((num) => {
        if (x === 0) return num.toFixed(0);
        if (y > 0) return num.toFixed(y);
      })}
    </animated.span>
  );
};
