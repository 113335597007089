import { DaySetting } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React, { useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Option } from '../option';
import { useModel } from 'src/common/create-model';
import { SettingProvider } from '../common';

interface Props {
  startDay: DaySetting;
  setStartDay: React.Dispatch<React.SetStateAction<DaySetting>>;
}
export const DateSetting: FC = () => {
  const {
    spaceSettingState: { startDay, setStartDay },
  } = useModel(SettingProvider);
  const openModal = useOpenModal();

  const handleClick = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: () => <DaySettingList startDay={startDay} setStartDay={setStartDay} />,
    });
  };

  return (
    <Option
      title="日期设置"
      description="设置每周起始日，仅影响日历显示方式"
      rightPart={
        <button className="text-t2 text-black flex items-center" onClick={handleClick}>
          {startDay === DaySetting.MON ? '周一' : '周日'}
          <Icon name="IcArrowDown01" size="xxxsmall" className="ml-1" />
        </button>
      }
    />
  );
};

const DaySettingList: FC<Props> = ({ startDay, setStartDay }) => {
  const [day, setDay] = useState(startDay);

  const items = [
    { type: DaySetting.MON, title: '周一' },
    { type: DaySetting.SUN, title: '周日' },
  ].map((item) => ({
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: { ...item, selected: item.type === day },
  }));

  return (
    <ListView
      className="rounded-r next-modal py-1.5 w-[120px]"
      items={items}
      onItemClick={(item) => {
        setDay(item.data.type);
        setStartDay(item.data.type);
      }}
    />
  );
};
