import type { SegmentDTO, TextDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import type { IContent, IContentItem, IElement, IFormat } from '@next-space/fe-inlined';
import { concatContents, newContent, newElement, newText } from '@next-space/fe-inlined';
import dayjs from 'dayjs';
import produce from 'immer';
import { shallowEqual } from 'react-redux';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'src/common/const';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { cache } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';
import { getAssociatedDiscussions, newDiscussionCustom } from '../editor/inline/comment';
import {
  CODE_TAG,
  INLINE_DATE_TAG,
  INLINE_EMOJI_TAG,
  INLINE_EQUATION_TAG,
  INLINE_LINK_PAGE_TAG,
  INLINE_LINK_SUGGEST_TAG,
  INLINE_MENTION_BLOCK_TAG,
  INLINE_PERSON_TAG,
  LINK_TAG,
  SELECT_INLINE_TAG,
} from '../editor/inline/const';
import { unwrapEmoji, wrapEmoji } from '../editor/inline/emoji-utils';
import type { PickerDate } from '../editor/plugin/date-picker/types';
import { segmentsToText } from './editor';

export const normalizeSegments = (segments: SegmentDTO[] | undefined) => {
  if (segments === undefined) return undefined;
  segments = segments.filter((it) => !(it.type === TextType.TEXT && it.text === ''));
  if (segments.length === 0) return undefined;
  return segments;
};

export const readDateFromDateSegment = (dateSegment: SegmentDTO) => {
  let date: Date;
  if (dateSegment.type === TextType.DATETIME) {
    const day = dayjs(
      `${dateSegment.startDate} ${dateSegment.startTime}`,
      DATE_TIME_FORMAT
    ).startOf('minute');

    date = day.isValid() ? day.toDate() : dayjs().toDate();
  } else if (dateSegment.type === TextType.DATE) {
    const day = dayjs(`${dateSegment.startDate}`, DATE_FORMAT).startOf('day');
    date = day.isValid() ? day.toDate() : dayjs().toDate();
  } else {
    date = dayjs().toDate();
  }
  return date;
};

export const convertSegmentsToTimestamp = (segments: SegmentDTO[] | undefined) => {
  const dateSegment = (segments ?? []).find(
    (it) => it.type === TextType.DATE || (it.type as number) === TextType.DATETIME
  );
  return dateSegment && readDateFromDateSegment(dateSegment).getTime();
};

export const convertSegmentsToNumber = (segments: SegmentDTO[] | undefined) => {
  const text = segmentsToText(segments);
  let num = parseFloat(text);
  if (Number.isNaN(num)) {
    num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
  }
  return isNaN(num) ? undefined : num;
};

export const buildDateSegment = (
  date: PickerDate,
  enhancer: TextDTO['enhancer'] = {}
): SegmentDTO => {
  // 行内元素转换成page块时，text要保留
  let dateStr = undefined;
  if (dayjs(date.from).isValid()) {
    dateStr = dayjs(date.from).format(DATE_FORMAT);
  }

  return {
    type: date.includeTime ? TextType.DATETIME : TextType.DATE,
    enhancer,
    text: '‣',
    startDate: dateStr,
    startTime: dayjs(date.from).format(TIME_FORMAT),
    dateFormat: date.dateFormat,
    timeFormat: date.timeFormat,
    reminder: date.reminder,
  };
};

export const personSegmentsToUserIds = (segments: SegmentDTO[] | undefined) => {
  const userIds = (segments ?? [])
    .filter((it) => it.type === TextType.USER && it.uuid != null)
    .map((it) => it.uuid) as string[];
  if (userIds.length === 0) return undefined;
  return userIds;
};

export const buildPersonSegment = (
  userId: string,
  enhancer: TextDTO['enhancer'] = {}
): SegmentDTO => {
  // 行内元素转换成page块时，text要保留
  return {
    type: TextType.USER,
    enhancer,
    text: '',
    uuid: userId,
  };
};

export const buildEquationSegment = (
  text: string,
  enhancer: TextDTO['enhancer'] = {}
): SegmentDTO => {
  return {
    type: TextType.EQUATION,
    enhancer,
    text: text ?? '',
  };
};

export const readFormatFromSegment = (segment: SegmentDTO): Partial<IFormat> => {
  const { enhancer, discussions } = segment;
  const customs = {};
  for (const discussion of discussions ?? []) {
    const custom = newDiscussionCustom(discussion);
    Object.assign(customs, custom);
  }
  return {
    bold: enhancer?.bold,
    backgroundColor: enhancer?.backgroundColor,
    italic: enhancer?.italic,
    lineThrough: enhancer?.lineThrough,
    color: enhancer?.textColor || '',
    underline: enhancer?.underline,
    customs,
  };
};

const optBoolean = (value: boolean | undefined): true | undefined => {
  return value === true ? true : undefined;
};

const optArray = <T>(value: T[]): T[] | undefined => {
  return value.length === 0 ? undefined : value;
};

export const formatToEnhancer = (format: Partial<IFormat>): TextDTO['enhancer'] => {
  return {
    bold: optBoolean(format.bold),
    italic: optBoolean(format.italic),
    underline: optBoolean(format.underline),
    lineThrough: optBoolean(format.lineThrough),
    textColor: format.color || undefined,
    backgroundColor: format.backgroundColor ?? undefined,
  };
};

export const convertContentToSegments = (content: IContent) => {
  const segmentsInfo = convertContentToUnWrapSegments(content, true);
  return segmentsInfo.unwrapSegments;
};

export function convertContentToUnWrapSegments(content: IContent, isUnwrapEmoji: boolean) {
  const segments: SegmentDTO[] = [];
  const pushText = (text: string) => {
    const lastSegment = segments[segments.length - 1] ?? null;
    if (lastSegment === null) {
      segments.push({
        type: TextType.TEXT,
        text,
        enhancer: {},
      });
    } else {
      lastSegment.text += text;
    }
  };
  const process = (content: IContent, context: IElement[]) => {
    const findContextItem = (tag: string) => {
      return context.find((it) => it.tag === tag);
    };
    for (const item of content.items) {
      if (item.type === 'element') {
        if (item.tag === SELECT_INLINE_TAG) {
          // 正在选择中的行内元素
          pushText('@');
        } else if (item.tag === INLINE_DATE_TAG) {
          segments.push({
            ...buildDateSegment(
              {
                from: new Date(item.props.datetime as number),
                dateFormat: item.props.dateFormat as any,
                timeFormat: item.props.timeFormat as any,
                includeTime: item.props.includeTime as boolean,
                reminder: item.props.reminder as any,
              },
              {
                ...formatToEnhancer((item.props.textFormat ?? {}) as any),
                code: optBoolean(findContextItem(CODE_TAG) !== undefined),
              }
            ),
            discussions: optArray(
              getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
            ),
          });
        } else if (item.tag === INLINE_LINK_PAGE_TAG) {
          // 行内引用页面转换为page块时，segments不需要保留对应的text
          segments.push({
            type: TextType.LINK_PAGE,
            enhancer: {
              ...formatToEnhancer((item.props.textFormat ?? {}) as any),
              code: optBoolean(findContextItem(CODE_TAG) !== undefined),
            },
            text: '',
            uuid: item.props.pageId as string,
            discussions: optArray(
              getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
            ),
          });
        } else if (item.tag === INLINE_MENTION_BLOCK_TAG) {
          segments.push({
            type: TextType.MENTION_BLOCK,
            enhancer: {
              ...formatToEnhancer((item.props.textFormat ?? {}) as any),
              code: optBoolean(findContextItem(CODE_TAG) !== undefined),
            },
            text: (item.props.text ?? '') as string,
            uuid: item.props.blockId as string,
            discussions: optArray(
              getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
            ),
          });
        } else if (item.tag === INLINE_PERSON_TAG) {
          segments.push({
            ...buildPersonSegment(item.props.userId as string, {
              ...formatToEnhancer((item.props.textFormat ?? {}) as any),
              code: optBoolean(findContextItem(CODE_TAG) !== undefined),
            }),
            discussions: optArray(
              getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
            ),
          });
        } else if (item.tag === INLINE_EQUATION_TAG) {
          // 如果行内公式Text没有值，则不添加行内公式segment
          if (item.props.text) {
            segments.push({
              ...buildEquationSegment(item.props.text as string, {
                ...formatToEnhancer((item.props.textFormat ?? {}) as any),
                code: optBoolean(findContextItem(CODE_TAG) !== undefined),
              }),
              discussions: optArray(
                getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
              ),
            });
          }
        }
        if (item.tag === INLINE_EMOJI_TAG) {
          segments.push({
            type: TextType.EMOJI,
            text: (item.props.text ?? '') as string,
            enhancer: {
              ...formatToEnhancer((item.props.textFormat ?? {}) as any),
              code: optBoolean(findContextItem(CODE_TAG) !== undefined),
            },
            discussions: optArray(
              getAssociatedDiscussions((item.props.textFormat ?? {}) as IFormat)
            ),
          });
        } else if (item.content !== null) {
          process(item.content, [...context, item]);
        }
      } else {
        const linkData = findContextItem(LINK_TAG);
        const segment: SegmentDTO = {
          type: linkData !== undefined ? TextType.URL : TextType.TEXT,
          text: item.content ?? '',
          enhancer: {
            ...formatToEnhancer(item.format),
            code: optBoolean(findContextItem(CODE_TAG) !== undefined),
          },
          discussions: optArray(getAssociatedDiscussions(item.format)),
          ...(linkData?.props ?? {}),
        };
        segments.push(segment);
      }
    }
  };
  process(content, []);
  if (isUnwrapEmoji) {
    const unwrapSegments = unwrapEmoji(segments);
    return { unwrapSegments, segments };
  }
  return { unwrapSegments: [], segments };
}

const segmentsToContentCache = new WeakMap<SegmentDTO[], IContent>();
const emptyContent = newContent([]);

export const convertSegmentsToContent = (segments: SegmentDTO[] | null | undefined) => {
  if (segments == null) return emptyContent;
  if (segmentsToContentCache.has(segments)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return segmentsToContentCache.get(segments)!;
  }

  const items: IContentItem[] = [];

  let lastItem: IContentItem | null = null;
  const pushItem = (item: IContentItem) => {
    if (
      lastItem !== null &&
      lastItem.type === 'element' &&
      lastItem.content !== null &&
      lastItem.tag === LINK_TAG &&
      item.type === 'element' &&
      item.content !== null &&
      item.tag === LINK_TAG &&
      shallowEqual(item.props, lastItem.props)
    ) {
      lastItem.content = concatContents(lastItem.content, item.content);
    } else {
      items.push(item);
      lastItem = item;
    }
  };
  const { wrapSegments } = wrapEmoji(segments);
  for (const segment of wrapSegments) {
    let item: IContentItem;
    if (segment.type === TextType.DATE || (segment.type as number) === 6) {
      const date = readDateFromDateSegment(segment);
      item = newElement(INLINE_DATE_TAG, {
        datetime: date.getTime(),
        includeTime: (segment.type as number) === 6,
        dateFormat: segment.dateFormat,
        timeFormat: segment.timeFormat,
        textFormat: readFormatFromSegment(segment),
        reminder: segment.reminder,
      });
    } else if (segment.type === TextType.USER) {
      item = newElement(INLINE_PERSON_TAG, {
        userId: segment.uuid,
        textFormat: readFormatFromSegment(segment),
      });
    } else if (segment.type === TextType.LINK_PAGE) {
      item = newElement(INLINE_LINK_PAGE_TAG, {
        pageId: segment.uuid,
        textFormat: readFormatFromSegment(segment),
      });
    } else if (segment.type === TextType.MENTION_BLOCK) {
      const pageId = getOwnerPage(segment.uuid ?? '');
      const blockIsPage = isPageLike(cache.blocks[segment.uuid ?? '']?.type);
      if (blockIsPage) {
        // 有可能所引用的块被转为页面块，如果是的话就要LINK_PAGE显示一致
        item = newElement(INLINE_LINK_PAGE_TAG, {
          pageId: segment.uuid,
          textFormat: readFormatFromSegment(segment),
        });
      } else {
        item = newElement(INLINE_MENTION_BLOCK_TAG, {
          pageId,
          blockId: segment.uuid,
          textFormat: readFormatFromSegment(segment),
          text: segment.text ?? '',
        });
      }
    } else if (segment.type === TextType.EQUATION) {
      item = newElement(INLINE_EQUATION_TAG, {
        text: segment.text ?? '',
        textFormat: readFormatFromSegment(segment),
      });
    } else if (segment.type === TextType.EMOJI) {
      item = newElement(INLINE_EMOJI_TAG, {
        text: segment.text ?? '',
        textFormat: readFormatFromSegment(segment),
      });
    } else if (segment.type === ('suggest' as any)) {
      item = newElement(INLINE_LINK_SUGGEST_TAG, {
        pageId: segment.uuid,
        textFormat: readFormatFromSegment(segment),
      });
    } else {
      const format = readFormatFromSegment(segment);
      try {
        // 对不合法的 unicode 字符串做容错处理
        item = newText(segment.text, format);
      } catch {
        item = newText('\uFFFD', format);
      }
      if (segment.type === TextType.URL) {
        item = newElement(
          LINK_TAG,
          {
            url: segment.url,
            fileStorageType: segment.fileStorageType,
            width: segment.width,
            height: segment.height,
            textFormat: readFormatFromSegment(segment),
          },
          newContent([item])
        );
      }
    }
    if (segment?.enhancer?.code) {
      item = newElement(CODE_TAG, {}, newContent([item]));
    }
    pushItem(item);
  }

  const content = newContent(items);
  segmentsToContentCache.set(segments, content);
  return content;
};

export const discussionsOfSegments = function* (segments: SegmentDTO[] | undefined) {
  if (segments === undefined) return;
  for (const segment of segments) {
    if (segment.discussions !== undefined) {
      yield* segment.discussions;
    }
  }
};

export const removeDiscussionFromSegments = function (
  segments: SegmentDTO[] | undefined,
  discussionId: string
) {
  if (segments == null) return segments;
  return produce(segments, (draft) => {
    for (const segment of draft) {
      if (segment.discussions != null) {
        const index = segment.discussions.indexOf(discussionId);
        if (index >= 0) {
          segment.discussions.splice(index);
        }
      }
    }
  });
};

export const removeAllDiscussionsFromSegments = function (segments: SegmentDTO[] | undefined) {
  if (segments == null) return segments;
  return produce(segments, (draft) => {
    for (const segment of draft) {
      if (segment.discussions != null) {
        segment.discussions = undefined;
      }
    }
  });
};
