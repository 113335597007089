import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import type { IEditorModel } from '@next-space/fe-inlined';
import { sliceContent } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { DraftProvider, LocalStorageDraftManager } from 'src/common/components/draft-manager';
import {
  ModalSchema,
  useCloseModal,
  useIsExistModalId,
  useOpenModal,
} from 'src/common/components/next-modal';
import { newDiscussionCustom } from 'src/editor/editor/inline/comment';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useQueryBlockNode } from 'src/hooks/block/use-query-block-node';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import { useGetPageId } from 'src/utils/getPageId';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { useCommentOperations } from './comment-operations';
import { PopupCommentEditor } from './popup-comment-editor';

export const useOpenNewDiscussionPopup = () => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const queryBlockNode = useQueryBlockNode();
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const isAlreadyOpen = useIsExistModalId(Modals.CONTEXT_DISCUSSIONS);
  const draftManager = useFinalValue(() => new LocalStorageDraftManager());
  const pageId = useGetPageId();
  const readonly = useReadonly(pageId, false, true);
  return useCallback(
    (params: {
      blockId: string;
      editorModel?: IEditorModel;
      withSelection?: boolean;
      popcorn?: ModalSchema.PopcornType;
    }) => {
      if (isAlreadyOpen) {
        return;
      }
      if (readonly) {
        return;
      }
      const { blockId } = params;
      const block = getState().blocks[blockId];
      let context: SegmentDTO[] = block?.data.segments ?? [];
      let model: IEditorModel | null = null;
      if (params.withSelection) {
        const modelKey = block?.type === BlockType.CODE ? `${blockId}_mock` : blockId;
        const _model = params.editorModel ?? getOrInitEditorModel(modelKey);

        if (_model == null) {
          return;
        }
        model = _model;
        context = params.withSelection
          ? convertContentToSegments(
              sliceContent(
                model.content,
                model.selection?.offset ?? 0,
                model.selection?.endOffset ?? model.content.length
              )
            )
          : convertContentToSegments(model.content);

        if (segmentsToText(context) === '') {
          return;
        }
      }
      let confirmed = false;

      const Popup = (props: { onCloseModal: () => void }) => {
        const transaction = useTransaction();
        const commentOps = useCommentOperations();
        return (
          <DraftProvider manager={draftManager}>
            <PopupCommentEditor
              onComment={(text) => {
                props.onCloseModal();

                if (params.withSelection) {
                  const discussionId = transaction(() => {
                    const discussionId = commentOps.addDiscussion({
                      blockId,
                      context,
                    });
                    commentOps.appendComment({
                      discussionId,
                      text,
                    });
                    return discussionId;
                  });
                  model?.performChange((ctx) => {
                    const ctx2 = params.withSelection
                      ? ctx.select(model?.selection?.offset, model?.selection?.endOffset)
                      : ctx.select();
                    ctx2.applyFormat({ customs: { ...newDiscussionCustom(discussionId) } });
                  });
                } else {
                  transaction(() => {
                    const discussionId = commentOps.addDiscussion({
                      blockId,
                    });
                    commentOps.appendComment({
                      discussionId,
                      text,
                    });
                    return discussionId;
                  });
                }
              }}
            />
          </DraftProvider>
        );
      };

      const closeBeforeCallBack = () => {
        if (draftManager.hasUnsavedDrafts()) {
          if (confirmed) {
            return;
          }
          openModal.warning({
            title: '放弃保存这条评论？',
            confirm: () => {
              confirmed = true;
              draftManager.clearUnsavedDrafts();
              closeModal(Modals.CONTEXT_DISCUSSIONS);
            },
          });
          return 'prevent';
        }
      };

      if (params.withSelection) {
        if (!model) {
          return;
        }
        const { selection } = model;
        if (selection == null) {
          return;
        }
        openModal.dropdown({
          modalId: Modals.CONTEXT_DISCUSSIONS,
          popcorn: {
            getBoundingClientRect() {
              const rect = model?.getBoundingClientRectOfRange(
                selection.offset,
                selection.endOffset
              );
              return rect ?? new DOMRect(-9999, -9999, 0, 0);
            },
          },
          content: Popup,
          placement: 'bottom',
          closeBeforeCallBack,
        });
      } else {
        const blockNode = queryBlockNode(blockId);
        if (blockNode == null) {
          return;
        }
        openModal.dropdown({
          modalId: Modals.CONTEXT_DISCUSSIONS,
          popcorn: params.popcorn ?? blockNode,
          content: Popup,
          placement: 'bottom',
          closeBeforeCallBack,
        });
      }
    },
    [
      closeModal,
      draftManager,
      getOrInitEditorModel,
      isAlreadyOpen,
      openModal,
      queryBlockNode,
      readonly,
    ]
  );
};
