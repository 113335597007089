import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { getGroupUniqueId } from 'src/bitable/bitable-utils';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { setAppUiState } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { DEFAULT_ROW_HEIGHT } from '../../const';
import { PropertyManagePlace } from '../types';

interface AddColProps {
  groupName?: string;
}

export const AddCol: FC<AddColProps> = ({ groupName }) => {
  const { readonly, managerReadonly, collectionId, viewId, relationEditor, isLocked } =
    useBitable();
  const createProperty = useCreateProperty();

  const handleClick = () => {
    if (readonly || managerReadonly || isLocked) return;
    bizTracker.event('bitable_property_create', { from_scene: 'view' });

    const newPropertyId = createProperty({
      collectionId,
      viewId,
    });
    if (newPropertyId) {
      setAppUiState({
        $newTableProperty: {
          propertyId: newPropertyId,
          from: PropertyManagePlace.TABLE_HEADER,
          groupInfo: getGroupUniqueId(viewId, groupName),
        },
      });
    }
  };

  return (
    <div className="flex-1 print:hidden">
      <button
        disabled={readonly}
        onClick={handleClick}
        style={{ height: DEFAULT_ROW_HEIGHT }}
        className={cx(
          'flex flex-1 flex-shrink-0 items-center duration-150 select-none w-12 min-w-full',
          { 'animate-hover rounded-none': !readonly }
        )}
      >
        {readonly || managerReadonly || relationEditor || isLocked ? null : (
          <Icon size="middle" name="IcAddBoard" className="text-grey4 w-12" />
        )}
      </button>
    </div>
  );
};
