import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';

type DrawerRight =
  | 'page-feeds-right'
  | 'page-resolved-discussions-right'
  | 'service-center-right'
  | 'ai-chat-right';
type DrawerMain = 'page-feeds' | 'page-resolved-discussions' | 'service-center' | 'ai-chat';

export const drawerOpenState = atom(false as DrawerMain | DrawerRight | false);

export const supportSpaceChatState = atom(false);

const $showChatPanelState = atom(true);

export const isRightDrawer = (drawerState: DrawerMain | DrawerRight | boolean) =>
  typeof drawerState === 'string' && drawerState.includes('right');

export const useShowQAChat = () => {
  const [showChatPanelState, setShowChatPanelState] = useAtom($showChatPanelState);
  const supportChat = useAtomValue(supportSpaceChatState);
  const showChatPanel = showChatPanelState && supportChat;

  return { showChatPanel, setShowChatPanelState, supportChat };
};

export const useDrawerOpenState = () => {
  return useAtom(drawerOpenState);
};

export const useSetDrawerOpenState = () => {
  return useSetAtom(drawerOpenState);
};

export const useDrawerOpenStateValue = () => {
  return useAtomValue(drawerOpenState);
};
