import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { selectCount } from 'src/mind-map/component/collapse-count';
import { cache } from 'src/redux/store';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { useIsProSpace } from '../space/use-is-pro-space';

/**
 * 返回true表示会弹窗
 */
export const useOpenUpgradeSpaceIfMindNodeLimit = () => {
  const isPro = useIsProSpace();
  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const { mindMapMaxLimit } = useLimitConfig();

  return useCallback(
    (
      blockId: string,
      content = `已达到导图节点上限${mindMapMaxLimit},升级空间立即解锁无限节点`,
      maxCount = mindMapMaxLimit
    ) => {
      const count = selectCount(cache, blockId, blockId);
      if (count >= maxCount && !isPro) {
        // 免费版本的要弹窗
        openModal.warning({
          title: '',
          content,
          confirmText: '升级空间',
          cancelText: '我再想想',
          colorType: 'active',
          confirm: () => {
            openUpgradeSpace(OpenSettingFrom.mind_node_limit);
          },
        });
        return true;
      }
    },
    [isPro, mindMapMaxLimit, openModal, openUpgradeSpace]
  );
};
