import { cx } from '@flowus/common/cx';
import { calendarUtil } from '@flowus/common/lunar';
import { DaySetting } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React, { memo, useEffect, useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { deepEqual } from '@flowus/common/utils/tools';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useShowLunarCalendar } from 'src/hooks/collection-view/use-collection-view';
import { useIsMobileSize, useToday } from 'src/services/app/hook';
import { getDateTimeStamp, ONE_DAY, ONE_HOURS } from 'src/utils/date-utils';
import { useCalender } from './context';
import { WeekCards } from './week-cards';

export const Week: FC<{
  dates: number[];
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>;
}> = memo(({ dates, setCurrentMonth }) => {
  const { container, calenderHeader } = useCalender();
  const isMobileSize = useIsMobileSize();
  const weekRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const containerNode = container.current?.closest('[data-page-id]');
    const weekNode = weekRef.current;
    const calenderHeaderNode = calenderHeader.current;
    if (!containerNode || !weekNode || !calenderHeaderNode) return;

    const handleScroll = () => {
      const weekRect = weekNode.getBoundingClientRect();
      const headerRect = calenderHeaderNode.getBoundingClientRect();
      if (weekRect.top < headerRect.bottom && weekRect.bottom > headerRect.bottom) {
        setCurrentMonth((oldMonth) => {
          const lastDate = dates[dates.length - 1];
          if (!lastDate) return oldMonth;
          const newMonth = lastDate + ONE_DAY;
          if (newMonth !== oldMonth) {
            return getDateTimeStamp(new Date(newMonth).setDate(1));
          }
          return oldMonth;
        });
      }
    };

    containerNode.addEventListener('scroll', handleScroll);
    return () => containerNode.removeEventListener('scroll', handleScroll);
  }, [calenderHeader, container, dates, setCurrentMonth]);

  return (
    <div
      className={cx(
        'flex w-full relative calender-week',
        isMobileSize ? 'min-h-[64px]' : 'min-h-[124px]'
      )}
      ref={weekRef}
    >
      {dates.map((date) => {
        return <WeekDate key={date} date={date} />;
      })}
      <WeekCards
        weekFirstDay={dates[0] as number}
        weekLastDay={dates[dates.length - 1] as number}
        weekRef={weekRef}
      />
    </div>
  );
}, deepEqual);

export const WeekDate: FC<{ date: number }> = memo(({ date }) => {
  date += 12 * ONE_HOURS;

  const { startDay } = useCalender();
  const { viewId, readonly } = useBitable();
  const showLunarCalendar = useShowLunarCalendar(viewId);
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const isMobileSize = useIsMobileSize();
  const isToday = useToday() === getDateTimeStamp(date);

  const dateObj = new Date(date);
  const dateText = dateObj.getDate();
  const isSunday = dateObj.getDay() === 0;
  const isMonday = dateObj.getDay() === 1;
  const monthText = dateObj.getMonth() + 1;
  const dateInfo = calendarUtil.solar2lunar(dateObj.getFullYear(), monthText, dateText);

  const createRecord = () => {
    if (readonly) return;

    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { calendarBy, calendarByEnd } = viewInfo;
    if (calendarBy && calendarByEnd) {
      void insertRecord({
        viewId,
        where: { last: true },
        propertyValues: {
          [calendarBy]: [buildDateSegment({ from: new Date(date) })],
          [calendarByEnd]: [buildDateSegment({ from: new Date(date) })],
        },
      });
    }
  };
  return (
    <div
      onDoubleClick={createRecord}
      data-date={date}
      className={cx(
        'min-h-full border-r border-b border-grey6 calender-date flex-1 text-right flex justify-between group relative flex-shrink-0 text-t2 text-grey3',
        startDay === DaySetting.MON ? isMonday && 'border-l' : isSunday && 'border-l'
      )}
    >
      <div className="h-10 pt-2 px-2 flex justify-between w-full">
        {!readonly && (
          <Tooltip
            popup="创建新页面"
            placement="top"
            className="absolute top-1.5 left-1.5 opacity-0 group-hover:opacity-100 cursor-pointer duration-150
            text-black text-sm w-6 h-6 bg-white1 border rounded flex items-center justify-center text-center"
            onClick={createRecord}
          >
            <Icon name="IcBtnNew" size="middle" className="text-grey3" />
          </Tooltip>
        )}

        <div className={cx('flex w-full', showLunarCalendar ? 'justify-between' : 'justify-end')}>
          {showLunarCalendar && (
            <span className="text-t3">
              {!isMobileSize && dateInfo !== -1 && (
                <>
                  {dateInfo.lunarFestival || dateInfo.Term
                    ? dateInfo.lunarFestival || dateInfo.Term
                    : dateInfo.lDay === 1
                    ? dateInfo.IMonthCn
                    : dateInfo.IDayCn}
                </>
              )}
            </span>
          )}

          <span
            className={cx(
              'ml-1',
              isToday &&
                'calendar-today w-6 h-6 bg-red inline-block text-center text-white rounded-full'
            )}
          >
            {dateText === 1 ? `${monthText}月${dateText}日` : dateText}
          </span>
        </div>
      </div>
    </div>
  );
});
