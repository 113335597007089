import type { ModalSchema } from '@flowus/common/next-modal';
import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LinkWidget } from 'src/common/components/link-widget';
import { transaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';

export const useOpenImageLinkDialog = () => {
  const openModal = useOpenModal();
  return useCallback(
    (blockId: string, popcorn: ModalSchema.PopcornType, forceEditLink?: boolean) => {
      const block = cache.blocks[blockId];
      if (!block) return;
      openModal.dropdown({
        popcorn,
        content: ({ onCloseModal }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [link, setLink] = useState<string | undefined>(block.data.openLink);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [inLink, _] = useState<boolean>(!!block.data.openLink);
          return (
            <LinkWidget
              inLink={forceEditLink === undefined ? inLink : false}
              link={link ?? ''}
              onSetLink={(linkUrl) => {
                transaction(() => {
                  // if (!linkRef.current) return;
                  updateBlock(block.uuid, {
                    data: {
                      openLink: linkUrl,
                    },
                  });
                });
              }}
              onLinkChange={(value) => {
                setLink(value);
              }}
              onClearLink={() => {
                transaction(() => {
                  // if (!linkRef.current) return;
                  updateBlock(block.uuid, {
                    data: {
                      openLink: '',
                    },
                  });
                });
              }}
              onCloseModal={onCloseModal}
            />
          );
        },
      });
    },
    [openModal]
  );
};
