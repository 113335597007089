import { cx } from '@flowus/common/cx';
import { isString, isUndefined, throttle } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { useSize } from 'src/common/utils/use-size';
import { useTransaction } from 'src/hooks/use-transaction';
import { moveBlock } from 'src/redux/managers/block/move';
import { updateBlock } from 'src/redux/managers/block/update';
import type { LinePosition } from 'src/redux/reducers/simple-table';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { cache, dispatch } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { DEFAULT_COL_WIDTH } from './helper';
import type { TableProps } from './types';

const INIT = { left: 0, width: 0, height: 0, top: 0 };
const MARGIN_LEFT = 12;
const MARGIN_TOP = 12;
const EMPTY: { recordId?: string; propertyId?: string } = {};
const HEIGHT_CORRECTED = 1;

export const TableSelectionLayout: FC<TableProps & { root?: boolean }> = ({
  root,
  tableRef,
  tableId,
  indexRanges,
}) => {
  const { recordId, propertyId } = useObservableStore(
    ({ simpleTable }) => {
      const { chosenCells } = simpleTable;
      if (!chosenCells) return EMPTY;
      if (tableId !== chosenCells.tableId) return EMPTY;
      return chosenCells;
    },
    [tableId],
    { obsSimpleTable: true }
  );
  const [style, setStyle] = useState(INIT);
  const { width = -9999 } = useSize(tableRef.current, 'width');
  const isDragging = useObservableStore(
    ({ simpleTable }) => {
      const { chosenCells, draggingStart } = simpleTable;
      return tableId === chosenCells?.tableId && !!draggingStart;
    },
    [tableId],
    { obsSimpleTable: true }
  );
  const initRef = useRef(INIT);
  const gapListRef = useRef<number[]>([]);
  const positionRef = useRef<LinePosition>();
  const startRef = useRef<{ initX: number; initY: number } | undefined>();
  const transaction = useTransaction();

  const PADDING_LEFT = root ? 96 : 0;
  const GAP = PADDING_LEFT + MARGIN_LEFT;
  const isRow = isString(recordId);
  const isColumn = isString(propertyId);

  const init = useCallback(() => {
    const table = tableRef.current;
    if (!table) return;

    const selectedCellHandle = table.querySelector('.table-selector');
    if (!selectedCellHandle) return;

    const td = selectedCellHandle.closest('td');
    if (!td) return;

    let paddingLeft = MARGIN_LEFT;
    const parentElement = table.closest('[data-virtuoso-scroller="true"]')?.parentElement;
    if (parentElement) {
      paddingLeft += parseInt(getComputedStyle(parentElement).getPropertyValue('padding-left'), 10);
    }

    const rect = td.getBoundingClientRect();

    const init = {
      top: isRow ? td.offsetTop - table.offsetTop + 11 : 12,
      left: td.offsetLeft - 1 + paddingLeft,
      width: isRow ? table.clientWidth - 0.2 : rect.width + 1,
      height: isRow ? rect.height + HEIGHT_CORRECTED : table.clientHeight,
    };
    setStyle(init);
    initRef.current = init;

    if (isColumn) {
      const block = cache.blocks[tableId];
      const format = block?.data.format ?? {};
      const widths = (format.tableBlockColumnOrder ?? []).map(
        (id) => format.tableBlockColumnFormat?.[id]?.width ?? DEFAULT_COL_WIDTH
      );
      let sum = (widths[0] ?? 0) + PADDING_LEFT + 9;
      gapListRef.current = widths.map((width, index) => {
        if (index) {
          sum += width;
        }
        return sum;
      });
    }

    if (isRow) {
      const cells = table.querySelectorAll(`tr`);
      const heights = Array.from(cells).map((cell) => {
        const { height } = cell.getBoundingClientRect();
        return height;
      });
      let sum = (heights[0] ?? 0) + MARGIN_TOP;
      gapListRef.current = heights.map((height, index) => {
        if (index) {
          sum += height;
        }
        return sum;
      });
    }
  }, [tableRef, isRow, isColumn, tableId, PADDING_LEFT]);

  useEffect(() => {
    init();
  }, [init, width, recordId, propertyId, isDragging]);

  useEffect(() => {
    const mousedown = (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest('.table-handle')) return;
      startRef.current = {
        initX: event.pageX,
        initY: event.pageY,
      };
    };

    const mouseup = () => {
      startRef.current = undefined;

      // 兼容 safari https://stackoverflow.com/questions/47295211/safari-wrong-cursor-when-dragging
      document.onselectstart = function () {
        return true;
      };

      const done = () => {
        positionRef.current = undefined;
        dispatch(
          simpleTableActions.update({
            draggingStart: undefined,
            dropInfo: undefined,
          })
        );
      };

      const { blocks, simpleTable } = cache;
      const { chosenCells, dropInfo } = simpleTable;

      if (!chosenCells || tableId !== chosenCells.tableId) return;

      const table = blocks[tableId];
      if (table && dropInfo) {
        if (isString(recordId) && !isUndefined(dropInfo.recordId)) {
          if (recordId !== dropInfo.recordId) {
            const rowIndex = table.subNodes.findIndex((id) => id === recordId);
            const isInRange = indexRanges.some(
              (range) =>
                range[0][0] <= rowIndex && rowIndex <= range[1][0] && range[0][0] !== range[1][0]
            );
            if (isInRange) {
              message.warning('有合并的单元格不支持移动');
              done();
              return;
            }

            const position = positionRef.current;
            const where: NextWhere = { parentId: tableId };
            if (position === 'top') {
              where.before = dropInfo.recordId;
            }
            if (position === 'bottom') {
              where.after = dropInfo.recordId;
            }
            transaction(() => {
              moveBlock([recordId], where);
            });
          }
        }

        if (isString(propertyId) && !isUndefined(dropInfo.propertyId)) {
          const { format } = table.data;
          const columnOrder = format?.tableBlockColumnOrder ?? [];
          if (propertyId !== dropInfo.propertyId) {
            const columnIndex = columnOrder.findIndex((id) => id === propertyId);
            const isInRange = indexRanges.some(
              (range) =>
                range[0][1] <= columnIndex &&
                columnIndex <= range[1][1] &&
                range[0][1] !== range[1][1]
            );
            if (isInRange) {
              message.warning('有合并的单元格不支持移动');
              done();
              return;
            }

            const order: string[] = columnOrder.filter((uuid) => uuid !== propertyId);
            const position = positionRef.current;
            const index = order.indexOf(dropInfo.propertyId);

            if (position === 'left') {
              order.splice(index, 0, propertyId);
            }
            if (position === 'right') {
              order.splice(index + 1, 0, propertyId);
            }

            transaction(() => {
              updateBlock(tableId, {
                data: {
                  format: {
                    tableBlockColumnOrder: order,
                  },
                },
              });
            });
          }
        }
      }

      done();
    };

    const _mousemove = (event: MouseEvent) => {
      if (!propertyId && !recordId) return;
      if (!startRef.current) return;

      if (
        Math.abs(event.pageX - startRef.current.initX) > 5 ||
        Math.abs(event.pageY - startRef.current.initY) > 5
      ) {
        dispatch(simpleTableActions.update({ draggingStart: startRef.current }));
      }

      const { chosenCells, draggingStart } = cache.simpleTable;
      if (!chosenCells || !draggingStart) return;
      if (tableId !== chosenCells.tableId) return;

      const { x, y } = event;
      const { initX = -999, initY = -999 } = draggingStart;

      const {
        top = 0,
        left = 0,
        right = 0,
        bottom = 0,
        height = 0,
        width = 0,
      } = tableRef.current?.getBoundingClientRect() ?? {};

      if (isString(chosenCells.recordId)) {
        positionRef.current = initY > y ? 'top' : 'bottom';
        setStyle((pre) => {
          const diff = y - top - pre.height / 2 + MARGIN_TOP;

          if (y > bottom - pre.height) {
            return {
              ...pre,
              top: Math.min(diff, height - pre.height + MARGIN_TOP),
            };
          }

          return {
            ...pre,
            top: Math.max(diff, MARGIN_TOP),
          };
        });
      }

      if (isString(chosenCells.propertyId)) {
        positionRef.current = initX > x ? 'left' : 'right';
        setStyle((pre) => {
          const diff = x - left + GAP - pre.width / 2;

          if (x > right - pre.width) {
            return { ...pre, left: Math.min(diff, width + GAP - pre.width) };
          }

          return {
            ...pre,
            left: Math.max(diff, GAP),
          };
        });
      }

      if (Math.abs(x - initX) < 20 && Math.abs(y - initY) < 15) {
        positionRef.current = undefined;
      }
    };

    const mousemove = throttle(_mousemove, 16);

    document.addEventListener('mousedown', mousedown, true);
    document.addEventListener('mousemove', mousemove);
    document.addEventListener('mouseup', mouseup, true);

    return () => {
      document.removeEventListener('mousedown', mousedown, true);
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mouseup', mouseup, true);
    };
  }, [GAP, indexRanges, init, isDragging, propertyId, recordId, tableId, tableRef, transaction]);

  useEffect(() => {
    const position = positionRef.current;
    if (!position) {
      dispatch(simpleTableActions.update({ dropInfo: undefined }));
      return;
    }

    const { draggingStart, chosenCells, dropInfo } = cache.simpleTable;
    if (!isDragging || !draggingStart || !chosenCells) return;
    const { tableId } = chosenCells;
    const table = cache.blocks[tableId];
    if (!table) return;

    let index = -1;

    if (isRow) {
      const { top } = style;

      gapListRef.current.some((y, i) => {
        if (position === 'top' ? top < y : top + style.height - HEIGHT_CORRECTED <= y) {
          index = i;
          return true;
        }
        return false;
      });

      const rowIndex = table.subNodes.indexOf(dropInfo?.recordId ?? '');
      const isInRange = indexRanges.some((range) => {
        const startIndex = range[0][0];
        const endIndex = range[1][0];
        const afterStart = position === 'top' ? startIndex < index : startIndex <= index;
        const beforeEnd = position === 'top' ? index <= endIndex : index < endIndex;
        return afterStart && beforeEnd;
      });

      if (isInRange) {
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            dropInfo: undefined,
          })
        );
      } else if (rowIndex !== index || dropInfo?.position !== position) {
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            dropInfo: {
              propertyId: undefined,
              recordId: table.subNodes[index],
              position,
            },
          })
        );
      }
    }

    if (isColumn) {
      const { left } = style;

      gapListRef.current.some((x, i) => {
        if (position === 'left' ? left < x : left + style.width <= x) {
          index = i;
          return true;
        }
        return false;
      });

      const { format } = table.data;
      const columns = format?.tableBlockColumnOrder ?? [];
      const columnIndex = columns.indexOf(dropInfo?.propertyId ?? '');
      const isInRange = indexRanges.some((range) => {
        const startIndex = range[0][1];
        const endIndex = range[1][1];
        const afterStart = position === 'left' ? startIndex < index : startIndex <= index;
        const beforeEnd = position === 'left' ? index <= endIndex : index < endIndex;
        return afterStart && beforeEnd;
      });

      if (isInRange) {
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            dropInfo: undefined,
          })
        );
      } else if (columnIndex !== index || dropInfo?.position !== position) {
        dispatch(
          simpleTableActions.update({
            activityTableId: tableId,
            dropInfo: {
              propertyId: columns[index],
              recordId: undefined,
              position,
            },
          })
        );
      }
    }
  }, [isDragging, style, tableId, indexRanges, isRow, isColumn]);

  const previewHtml = useMemo(() => {
    if (!isDragging) return '';

    let html = '';

    const table = tableRef.current?.cloneNode(true);

    if (table) {
      const div = document.createElement('div');
      div.appendChild(table);
      const btns = div.firstElementChild?.querySelectorAll('.shadow-button');
      btns?.forEach((btn) => btn.remove());
      html += div.innerHTML;
    }

    return html;
  }, [isDragging, tableRef]);

  if (!recordId && !propertyId) return null;

  return (
    <div
      className={cx(
        'absolute pointer-events-none border',
        isDragging ? 'border-none' : 'border-black dark:border-grey3'
      )}
      style={style}
    >
      {isDragging && (
        <div
          className={cx(
            'absolute z-10 table-handle shadow-button bg-black dark:bg-grey3',
            isRow ? 'top-1/2 -left-[9px] -mt-[13px]' : '-top-[13px] left-1/2 -ml-2 rotate-90'
          )}
        >
          <Icon name="IcMenu" className="text-white" size="xsmall" />
        </div>
      )}
      {previewHtml && (
        <div
          className="overflow-hidden border border-black dark:border-grey3 shadow-md"
          style={{
            width: initRef.current.width,
            height: initRef.current.height,
            backgroundColor: 'var(--grey9)',
          }}
        >
          <div
            style={{
              transform: `translateX(-${
                isRow ? 2 : initRef.current.left - PADDING_LEFT - 10
              }px) translateY(-${isRow ? initRef.current.top - 10 : 2}px)`,
            }}
            dangerouslySetInnerHTML={{ __html: previewHtml }}
          />
        </div>
      )}
    </div>
  );
};
