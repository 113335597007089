import { cx } from '@flowus/common/cx';
import type { OrderDetailDTO } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'src/common/components/button';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_TIME_FORMAT, publicLink } from 'src/common/const';
import { request } from 'src/common/request';
import { orderBizTracker } from 'src/components/select-upgrade-plan/common';
import { PayOfflineModal } from 'src/components/select-upgrade-plan/pay-offline-modal';
import { useCurrentSpace } from 'src/hooks/space';
import { getIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { removeDuplicateWith } from 'src/utils/array-util';
import { getCurrencySymbols } from 'src/utils/currency-format';
import type { AsyncReturnType } from 'type-fest';
import { SettingModalCommon } from './common';
import { OpenSettingFrom } from './type';
import { useCloseSettingModal } from './use-open-setting-modal';

export const OrderSetting: FC = () => {
  const closeSettingModal = useCloseSettingModal();
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const scrollRef = useRef<HTMLDivElement>(null);
  const pageIndex = useRef(0);

  const [state, setState] = useState<AsyncReturnType<typeof request.infra.getOrderList>>();
  const paddingOrder = state?.pendingList?.[0];

  const loadMore = async (fetch = false) => {
    if (!fetch) {
      pageIndex.current++;
    }

    const res = await request.infra.getOrderList(pageIndex.current, 20, currentSpace.uuid);

    setState((pre) => {
      return {
        ...res,
        list: removeDuplicateWith([...(pre?.list || []), ...(res.list || [])], 'id'),
      };
    });
  };

  const onFinish = (order_new: 'yes' | 'no') => {
    void loadMore(true);
    orderBizTracker({
      bizType: 'order_finish',
      order_amount: paddingOrder?.total,
      order_channel: 'card',
      is_from: OpenSettingFrom.order,
      order_new,
    });
  };

  const openTeamOfflineRePay = (order: OrderDetailDTO) => {
    const order_new = getIsProSpace() ? 'yes' : 'no';

    openModal.modal({
      content: ({ onCloseModal }) => (
        <PayOfflineModal
          orderId={order.id || ''}
          onFinish={() => {
            onFinish(order_new);
            onCloseModal();
          }}
          onCloseModal={onCloseModal}
          price={order.total || 0}
          endTime={order.expirationTime ?? 0}
          remark={order.remark}
          customFooter={
            <Button className="w-32 text-t2-medium" onClick={() => onCloseModal()}>
              确定
            </Button>
          }
        />
      ),
    });
  };

  // const openRePay = (order: OrderDetailDTO) => {
  //   const order_new = getIsProSpace() ? 'yes' : 'no';

  // 抽出来，方便给qr组件刷新下单
  // const rePayOrder = () => {
  //   return request.infra.createPreOrder({
  //     payType: state.selectPayType,
  //     planId: `${state.planId}`,
  //     spaceId: currentSpace.uuid,
  //     couponId: state.selectCoupon,
  //   });
  // };

  // openModal.modal({
  //   content: ({ onCloseModal }) => (
  //     <PayQrModal
  //       rePayOrder={rePayOrder}
  //       onFinish={() => {
  //         onFinish(order_new);
  //         onCloseModal();
  //       }}
  //       payType={order.payType}
  //       priceTitle={`${getCurrencySymbols()}${order?.total}`}
  //       onCloseModal={onCloseModal}
  //       orderId={`${order.id}`}
  //       qrCodeUrl={`${order.qr}`}
  //     />
  //   ),
  // });
  // };

  const onCancel = (id: string) => {
    openModal.warning({
      title: '确认取消订单吗？',
      content: '取消后，你也可以重斯下单',
      confirmText: '取消订单',
      cancelText: '我再想想',
      confirm: async () => {
        message.loading({ key: 'cancelOrder', content: '正在取消订单' });
        await request.infra.cancelOrder(id);
        void loadMore(true);
        message.closeMessage('cancelOrder');
        message.success('取消成功');
      },
    });
  };

  useEffect(() => {
    void loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={cx(SettingModalCommon.commonStyles.container, 'overflow-y-hidden')}>
        <SettingModalCommon.Header
          title="订单"
          addonAfter={
            <div
              hidden={__BUILD_IN__}
              className="flex items-center justify-center text-t2 text-black"
            >
              <a href={publicLink.applyInvoice} target="_blank" rel="noopener noreferrer">
                开发票
              </a>
            </div>
          }
        />
        {paddingOrder && (
          <div className="flex items-center justify-between h-14 bg-active_color/10 text-active_color px-4">
            <div>
              {`有一笔订单（${getCurrencySymbols(paddingOrder.currencyUnit)}${
                paddingOrder.total
              }）待支付`}
            </div>
            <div className="flex items-center justify-center">
              <Button
                colorType="secondary"
                onClick={() => paddingOrder.id && onCancel(paddingOrder.id)}
                className="text-active_color border-active_color mr-5 animate-hover"
              >
                取消订单
              </Button>
              <Button colorType="active" onClick={() => openTeamOfflineRePay(paddingOrder)}>
                查看订单
              </Button>
            </div>
          </div>
        )}
        <div className="flex flex-col justify-between items-center mt-5 overflow-y-hidden overscroll-x-auto flex-1">
          <div className="grid grid-cols-9 gap-x-2 w-full py-2">
            <div className="col-span-3">订单号</div>
            <div className="col-span-2">订单内容</div>
            <div className="col-span-1">金额</div>
            <div className="col-span-1">操作人</div>
            <div className="col-span-2">订单时间</div>
            {/* <div className="col-span-1">操作</div> */}
          </div>
          <div className="w-full h-2.5 border-b border-grey6" />
          <div ref={scrollRef} className="overflow-y-auto w-full flex-1 flex-shrink-0">
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              useWindow={false}
              loadMore={() => loadMore()}
              getScrollParent={() => scrollRef.current}
              hasMore={state?.more}
            >
              {state?.list?.length ? (
                <>
                  {state.list.map((item) => (
                    <div className="grid grid-cols-9 gap-x-2 w-full py-2" key={item.id}>
                      <div className="col-span-3 break-words">{item.id}</div>
                      <div className="break-words col-span-2">
                        <div>{item.description}</div>
                        {item.subDesc && <div className="text-t4 text-grey3">{item.subDesc}</div>}
                      </div>
                      <div className="break-words col-span-1">
                        {getCurrencySymbols(item.currencyUnit)}
                        {item.total}
                      </div>
                      <Tooltip
                        placement="top-start"
                        className="col-span-1 text-ellipsis"
                        popup={item.userName}
                      >
                        {item.userName}
                      </Tooltip>
                      <div className="break-words col-span-2">
                        {item.createdAt
                          ? dayjs(new Date(item.createdAt)).format(DATE_TIME_FORMAT)
                          : ''}
                      </div>
                      {/* <div className="col-span-1 flex flex-nowrap whitespace-nowrap">
                        <span
                          className="text-active_color cursor-pointer mr-2"
                          onClick={() => item.id && openRePay(item.id)}
                        >
                          支付
                        </span>
                        <span
                          className="text-red/80 cursor-pointer"
                          onClick={() => item.id && onCancel(item.id)}
                        >
                          取消
                        </span>
                      </div> */}
                    </div>
                  ))}
                </>
              ) : (
                <div className="text-t4 text-center text-grey3 mt-3">暂无订单</div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      <SettingModalCommon.FooterBar cancel={() => closeSettingModal()} cancelText="关闭" />
    </>
  );
};
