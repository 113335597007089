import type { FC } from 'react';
import type { FileType, SearchItem } from 'src/components/search';
import { SearchContent } from 'src/components/search';

interface Props {
  fileType?: FileType;
  onSelectPageInfo?: (pageInfo: SearchItem) => void;
  placeholder?: string;
}
/** 搜索引用文件 */
export const SearchLinkFile: FC<Props> = (props) => {
  const onSelect = (pageInfo: SearchItem) => {
    props?.onSelectPageInfo?.(pageInfo);
  };

  return (
    <SearchContent
      className="py-2.5 relative"
      searchType="file"
      placeholder={props.placeholder || '搜索文件'}
      onSelect={onSelect}
      fileType={props.fileType}
    />
  );
};
