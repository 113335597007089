import { USER_ID } from '@flowus/login/utils';
import { useCallback } from 'react';
import { bizTracker } from 'src/utils/biz-tracker';
import { setLocalStorage } from 'src/utils/local-storage';
import { kolActivity } from '../activities/common';
import { fetchUserRoot, TYPE_SPACES, TYPE_SPACE_VIEWS, TYPE_USER } from './fetch-user-root';
import { useFetchMe } from './use-fetch-me';
import { useJumpAfterFetchUser } from './use-jump-after-fetch-user';

/**
 * 自动登录并拉取用户和空间信息，如果没登录的话就跳到登录页
 */
export const useAutoLogin = () => {
  const jumpAfterFetchUser = useJumpAfterFetchUser();
  const fetchMe = useFetchMe();
  return useCallback(
    async (
      /** 某些页面不需要主动跳转登录页，只需要自动登录 */
      onlyUpdateUser = false
    ) => {
      const userRes = await fetchMe();
      if (userRes.code === 200) {
        kolActivity(false);

        if (userRes.data.uuid) {
          setLocalStorage(USER_ID, userRes.data.uuid);
          bizTracker.login(userRes.data.uuid); // 先绑定一下userId，因为更早之前有别的埋点
        }
        const data = await fetchUserRoot(
          TYPE_USER | TYPE_SPACES | TYPE_SPACE_VIEWS,
          false,
          onlyUpdateUser
        );

        if (!onlyUpdateUser) {
          jumpAfterFetchUser();
        }
        return data;
      }
      if (!onlyUpdateUser) {
        jumpAfterFetchUser();
      }
    },

    [fetchMe, jumpAfterFetchUser]
  );
};
