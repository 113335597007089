import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';

import { Tooltip } from '../tooltip';

export interface GalleryItemProps {
  onClick: () => void;
  url?: string;
  description?: string;
  illegal?: boolean;
  node?: ReactNode;
}

export const GalleryItem: FC<GalleryItemProps> = (props) => {
  const { url, description, onClick, illegal, node } = props;

  if (illegal) {
    return (
      <div className="flex relative h-20 mb-2 overflow-hidden text-t4 text-grey3 bg-grey6 p-2">
        图片无法查看
      </div>
    );
  }

  return (
    <Tooltip
      equalWidth
      popupClass="min-w-[50px] whitespace-pre-wrap w-full break-words line-clamp-none"
      key={description}
      placement="bottom"
      popup={description}
      offset={[-1, 8]}
      className={cx(
        'relative overflow-hidden cursor-pointer flex flex-col mb-2',
        node ? 'h-[104px]' : 'h-20'
      )}
    >
      <img
        loading="lazy"
        alt={description}
        src={url}
        onClick={onClick}
        className="object-cover w-full h-20 transition rounded animate-click hover:brightness-[0.8]"
      />
      {node}
    </Tooltip>
  );
};
