import { cx } from '@flowus/common/cx';
import { coverBackgroundStyle } from '@flowus/common/style';
import type { FC } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { LargeDefaultCardStyleClassName } from 'src/editor/component/page-block';
import { HeaderCover } from 'src/views/main/page-doc/header-cover';
import { RichText } from '../uikit/editable/rich-text';
import type { PageProps } from './types';
import { useFetchFirstTextBlock } from './use-fetch-first-text-block';
/** 大卡片模式中 默认样式 */
export const LargeDefaultCard: FC<PageProps> = (props) => {
  const { id, ownerBlockId, illegal, showIcon, showCover, desc, isInColumn } = props;
  const firstTextBlockSegments = useFetchFirstTextBlock(props.id, !desc);
  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '256px',
    };
  }

  return (
    <div
      {...attribute}
      className={cx('overflow-hidden flex flex-col relative w-[256px] h-[184px] animate-hover', {
        'w-full': isInColumn,
      })}
      onClick={props.onClick}
    >
      {showCover && (
        <HeaderCover
          uuid={props.id}
          height={92}
          hiddenEditorButton={true}
          className="flex-shrink-0 w-full relative rounded-t"
        />
      )}
      {!showCover && (
        <div
          className="flex-shrink-0 w-full relative rounded-t border-l border-r border-t h-[92px]"
          style={coverBackgroundStyle}
        />
      )}

      {showIcon && (
        <IconTrigger
          tooltipClassName="w-fit"
          trigger={!ownerBlockId}
          iconSize={40}
          className={'absolute left-2.5 top-[66px] w-10 h-10'}
          hasOverlay={Boolean(ownerBlockId)}
          blockId={id}
          offset={[100, 0]}
          defaultIcon={<BlockDefaultIcon uuid={id} />}
        />
      )}
      <div className="border-l border-r border-b rounded-b h-full pb-1 px-4 overflow-auto">
        <div className={'text-ellipsis text-t1 break-words flex-shrink-0 mt-3'}>
          {illegal ? ILLEGAL_TEXT : props.title}
        </div>
        {!illegal && desc && (
          <div className={LargeDefaultCardStyleClassName} style={{ color: props.descColor }}>
            {desc}
          </div>
        )}
        {!illegal && !desc && firstTextBlockSegments && (
          <RichText className={LargeDefaultCardStyleClassName} segments={firstTextBlockSegments} />
        )}
      </div>
    </div>
  );
};
