import { SpacePlanType } from '@flowus/shared';
import type { FC } from 'react';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import type { FileType, SearchItem } from 'src/components/search';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useOpenUpgradePlan } from 'src/views/main/setting-modal/use-open-upgrade-plan';
import type { Resource } from '../../editor/plugin/types';
import { InputExternalLink } from '../block-empty-view/input-external-link';
import { SearchLinkFile } from './search-link-file';
import { UploadLocalFile } from './upload-local-file';
import { UploadLocalFolder } from './upload-local-folder';

interface Props {
  title: string;
  linkTitle?: string;
  linkPlaceHolder?: string;
  /** input标签的props，只有上传文件的时候有用 */
  inputAccept?: string;
  className?: string;
  /** 这两个方法不要合并,免得把逻辑搞混 */
  onSelectFile?: (file: Resource) => void;
  onSelectFolder?: (file: File[]) => void;
  onSelectPageInfo?: (pageInfo: SearchItem) => void;
  /** 默认上传是上传文件,如果为true则是上传文件夹 */
  uploadFolder?: boolean;
  /** 是否隐藏外链入口 */
  hideLink?: boolean;
  /** 隐藏引用块 */
  hideLinkBlock?: boolean;
  fileType?: FileType;
  searchLinkFilePlaceholder?: string;
}
export const UploadPanel: FC<Props> = (props) => {
  const isProSpace = useIsProSpace();
  const isTeamSpace = useIsTeamSpace();
  const openUpgradePlan = useOpenUpgradePlan();

  return (
    <Tab className="next-modal w-[534px] sm:w-[370px]">
      <TabPane title="上传">
        {/* 个人免费和小组免费 */}
        {!isProSpace && (
          <div className="text-t2 p-4 flex items-center justify-between w-full">
            <span>{isTeamSpace ? '小组试用版' : '个人免费版'}空间：单个文件大小不超过2MB</span>
            <span
              className="text-active_color animate-click"
              onClick={() =>
                openUpgradePlan(isTeamSpace ? SpacePlanType.team : SpacePlanType.personal)
              }
            >
              升级上传更大文件
            </span>
          </div>
        )}
        {props.uploadFolder ? (
          <UploadLocalFolder title={props.title} onSelectLocalFolder={props.onSelectFolder} />
        ) : (
          <UploadLocalFile
            inputAttribute={{ accept: props.inputAccept }}
            title={props.title}
            onSelect={props.onSelectFile}
          />
        )}
      </TabPane>
      {!props.hideLink && (
        <TabPane title={props.linkTitle ?? '链接'}>
          <InputExternalLink
            placeholder={props.linkPlaceHolder}
            onInputUrl={(value) => {
              props.onSelectFile?.(value);
            }}
          />
        </TabPane>
      )}
      {!props.hideLinkBlock && (
        <TabPane title={'引用文件'}>
          <SearchLinkFile
            fileType={props.fileType}
            placeholder={props.searchLinkFilePlaceholder}
            onSelectPageInfo={props.onSelectPageInfo}
          />
        </TabPane>
      )}
    </Tab>
  );
};
