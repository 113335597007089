import { getTagBackgroundColor, getTagTextColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { useIsDarkMode } from 'src/hooks/public/use-theme';

interface Props {
  className?: string;
  color?: string;
  label?: string;
  value?: string;
  showClose?: boolean;
  onClick?: (event: MouseEvent) => void;
  onDelete?: () => void;
}

export const TagItem: FC<Props> = ({
  className,
  color,
  label,
  value,
  showClose,
  onClick,
  onDelete,
}) => {
  // 监听darkMode变化
  useIsDarkMode();
  const bgColor = getTagBackgroundColor(color);
  const textColor = getTagTextColor(color);
  return (
    <span
      className={cx(
        'h-5 inline-flex items-center rounded-sm pl-[6px] overflow-hidden text-t2 leading-[20px]',
        { 'pr-[6px]': !showClose },
        className
      )}
      style={{ color: textColor, backgroundColor: bgColor }}
      onClick={onClick}
    >
      <span className="overflow-ellipsis overflow-x-hidden whitespace-nowrap">
        {label ?? value}
      </span>

      {showClose && (
        <button className="opacity-80 flex-shrink-0 w-5 h-5 inline-block" onClick={onDelete}>
          <Icon name="IcToastClose" className="w-full h-full" size="auto" />
        </button>
      )}
    </span>
  );
};
