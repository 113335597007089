import { atom, useAtomValue, useSetAtom } from 'jotai';
import type { RefObject } from 'react';
import { createContext, useContext } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { PAGE_SCOPE } from '../page-states';

export const PageScrollRefContext = createContext<RefObject<HTMLDivElement | null> | undefined>(
  undefined
);

export const ScrollRefContext = createContext<RefObject<HTMLDivElement | null> | undefined>(
  undefined
);

export const HScrollRefContext = createContext<RefObject<HTMLDivElement | null> | undefined>(
  undefined
);

export const usePageScrollRef = () => {
  const ref = useContext(PageScrollRefContext);
  if (!ref) throw new Error('no usePageScrollRef');
  return ref;
};

export const useScrollRef = () => {
  const ref1 = useContext(PageScrollRefContext);
  const ref = useContext(ScrollRefContext);
  return ref ?? ref1;
};

export const useHScrollRef = () => {
  const ref1 = useScrollRef();
  const ref = useContext(HScrollRefContext);
  return ref ?? ref1;
};

export const $isLiteSize = atom(false);
export const useIsLiteSize = () => useAtomValue($isLiteSize, PAGE_SCOPE);

export const $pageWidth = atom(0);
export const usePageWidth = () => useAtomValue($pageWidth, PAGE_SCOPE);

export const $pageContentWidth = atom(0);
export const usePageContentWidth = () => useAtomValue($pageContentWidth, PAGE_SCOPE);

export const $pageHeight = atom(0);
export const usePageHeight = () => useAtomValue($pageHeight, PAGE_SCOPE);

export const $pageLazyListLoading = atom(false);
export const usePageLazyListLoading = () => useAtomValue($pageLazyListLoading, PAGE_SCOPE);
export const useSetPageLazyListLoading = () => useSetAtom($pageLazyListLoading, PAGE_SCOPE);

export const $pageVirtualListMode = atom<{
  isVirtualListMode: boolean;
  virtualRef?: RefObject<VirtuosoHandle>;
  subNodes: string[];
}>({
  isVirtualListMode: false,
  virtualRef: undefined,
  subNodes: [],
});
export const usePageVirtualListMode = () => useAtomValue($pageVirtualListMode, PAGE_SCOPE);
export const useSetPageVirtualListMode = () => useSetAtom($pageVirtualListMode, PAGE_SCOPE);
