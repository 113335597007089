import { useCallback } from 'react';

import { useMindMapEngine } from '../context';

export const useAddMindNode = () => {
  const engine = useMindMapEngine();
  return useCallback(
    (id: string, parentId?: string) => {
      engine.addMindNode(id, parentId);
    },
    [engine]
  );
};
