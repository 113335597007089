import { CURRENT_SPACE_VIEW_ID } from 'src/common/const';
import { setAppUiState } from 'src/services/app';
import { $spaceViewsState } from 'src/services/spaces/space-views';
import { updateCurrentUser } from 'src/services/user/current-user';
import { setLocalStorage } from 'src/utils/local-storage';

/**
 *切换spaceViewId
 */
export const switchSpaceViewId = (spaceViewId: string) => {
  setLocalStorage(CURRENT_SPACE_VIEW_ID, spaceViewId);
  updateCurrentUser({ currentSpaceViewId: spaceViewId });
};
/**
 * 通过spaceId切换spaceViewId
 */
export const switchSpaceViewIdBySpaceId = (spaceId: string, resetRootLoadedFlag = false) => {
  // 通过spaceId找到spaceViewId
  const spaceViewId = getSpaceViewIdBySpaceId(spaceId);
  if (spaceViewId) {
    switchSpaceViewId(spaceViewId);
  }
  if (resetRootLoadedFlag) {
    // 切换空间，首页跳转需要依赖于pageRootLoaded这个flag
    setAppUiState({ $sharedPages: [] });
    setAppUiState({ $pageRootLoaded: false });
  }
};
/**
 * 通过spaceId找到对应的id
 */
export const getSpaceViewIdBySpaceId = (spaceId: string) => {
  for (const [id, spaceView] of Object.entries($spaceViewsState.getState())) {
    if (spaceView.spaceId === spaceId) {
      return id;
    }
  }
  return '';
};
