import type { Bounds } from '../css/layout/bounds';
import { getAbsoluteValue } from '../css/types/length-percentage';
import type { ElementContainer } from '../dom/element-container';

export const paddingBox = (element: ElementContainer): Bounds => {
  const { bounds } = element;
  const { styles } = element;
  return bounds.add(
    styles.borderLeftWidth,
    styles.borderTopWidth,
    -(styles.borderRightWidth + styles.borderLeftWidth),
    -(styles.borderTopWidth + styles.borderBottomWidth)
  );
};

export const contentBox = (element: ElementContainer): Bounds => {
  const { styles } = element;
  const { bounds } = element;

  const paddingLeft = getAbsoluteValue(styles.paddingLeft, bounds.width);
  const paddingRight = getAbsoluteValue(styles.paddingRight, bounds.width);
  const paddingTop = getAbsoluteValue(styles.paddingTop, bounds.width);
  const paddingBottom = getAbsoluteValue(styles.paddingBottom, bounds.width);

  return bounds.add(
    paddingLeft + styles.borderLeftWidth,
    paddingTop + styles.borderTopWidth,
    -(styles.borderRightWidth + styles.borderLeftWidth + paddingLeft + paddingRight),
    -(styles.borderTopWidth + styles.borderBottomWidth + paddingTop + paddingBottom)
  );
};
