import { ListItemType } from 'src/common/components/list-view';

export const scales = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((value) => ({
  type: ListItemType.OPERABLE_BLOCK_TEXT,
  data: {
    title: `${value * 100}%`,
    value,
  },
}));

export const types = [
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: 'PDF',
      value: 'pdf',
    },
  },
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: 'Markdown & CSV',
      value: 'markdown',
    },
  },
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: 'Word',
      value: 'word',
    },
  },
];

export const pdfFormats = [
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: 'A4',
      value: 'a4',
    },
  },
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: 'A3',
      value: 'a3',
    },
  },
];

export const dataBaseRanges = [
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: '当前视图范围',
      value: 'current',
    },
  },
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: '全部',
      value: 'all',
    },
  },
];

export const layouts = [
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: '竖向',
      value: false,
    },
  },
  {
    type: ListItemType.OPERABLE_BLOCK_TEXT,
    data: {
      title: '横向',
      value: true,
    },
  },
];
