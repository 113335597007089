import { useSpacesSelector } from 'src/services/spaces/spaces';
import { isDeleted } from '../block/use-block-status';
import { useCurrentSpaceView } from './use-current-space';
import { getIsFreeSpaceLimit } from './use-is-pro-space';

const FREE_CUSTOM_TEMPLATES = 5;
/**
 * 查看自定义模板数量是是否超出限制
 */
export const useTemplatesLimit = () => {
  const spaceView = useCurrentSpaceView();
  return useSpacesSelector((state) => {
    const spaceId = spaceView?.spaceId;

    if (!spaceId) return;
    const space = state[spaceId];
    if (!space) return;
    const isFreeSpaceLimit = getIsFreeSpaceLimit(spaceId);
    if (!isFreeSpaceLimit) {
      return;
    }
    // 自定模板的总数
    const total = space.customTemplates?.filter((uuid) => {
      return !isDeleted(uuid);
    }).length;
    return FREE_CUSTOM_TEMPLATES <= total;
  });
};
