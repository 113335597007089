import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { v4 as uuidV4 } from 'uuid';
import { querySelectorFromMainContent } from './dom';

// #region editorKey相关的函数

/** 只有dom渲染后才能获取 */
export const getEditorKey = (blockId?: string, syncId?: string, isInRight?: boolean) => {
  if (!blockId) return;
  let element: Element | null = null;
  if (syncId) {
    element = querySelectorFromMainContent(
      `[data-block-id='${blockId}'][data-sync-id='${syncId}'] [data-editor]`,
      isInRight
    );
  } else {
    element = querySelectorFromMainContent(`[data-block-id='${blockId}'] [data-editor]`, isInRight);
  }
  return element?.getAttribute('data-editor') || blockId;
};
/**
 * 从dom节点往上找最近的editor
 */
export const getEditorKeyFromElement = (el?: Element | null) => {
  const editorNode = el?.closest('[data-editor]');
  return el?.getAttribute('data-editor') || editorNode?.getAttribute('data-editor');
};
/**
 * 从dom节点往下找最近的editor
 */
export const getEditorKeyFromBlockNode = (el?: Element | null) => {
  if (!el?.querySelector) return;
  const childNode = el.querySelector('[data-editor]');
  return el.getAttribute('data-editor') || childNode?.getAttribute('data-editor');
};

export const getSyncIdFromElement = (el?: Element | null) => {
  const editorNode = el?.closest('[data-sync-id]');
  const syncId = el?.getAttribute('data-sync-id') || editorNode?.getAttribute('data-sync-id');
  if (syncId) return syncId;
};

// #endregion

export const getBlockNodeFromEditorKey = (editorKey?: string | null) => {
  if (!editorKey) return;
  const node = document.querySelector(`[data-block-id] [data-editor='${editorKey}']`);
  if (!node) return;
  const blockNode = node.closest('[data-block-id]');
  if (blockNode instanceof HTMLElement) return blockNode;
};

export const useEditorTmpId = () => {
  const editorId = useFinalValue(uuidV4);
  return editorId;
};

export const getRowIdFromEditorKey = (id: string) => {
  const target = document.querySelector(`[data-editor="${id}"]`);

  if (target) {
    const tableRow = target.closest('[data-table-row-id]');

    if (tableRow) {
      return (tableRow as HTMLElement).dataset.tableRowId as string;
    }

    const modal = target.closest('[data-modal-id]');

    if (modal) {
      const { modalId } = (modal as HTMLElement).dataset;

      if (modalId?.startsWith('cell-')) {
        return modalId.slice(5, 41);
      }
    }
  }

  return id;
};
