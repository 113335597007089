import { sleep } from '@flowus/common/async';
import { BlockType } from '@next-space/fe-api-idl';
import { first, last } from 'lodash-es';
import { covertBlockToTitle } from 'src/editor/component/menu-list/menu-item';
import { isBlockNoText } from 'src/editor/utils/editor';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useOpenBlockMenuList } from 'src/hooks/public/use-create-block-menu-list';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { isCollection } from 'src/utils/block-type-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';
import { useInsertRecordUI } from '../use-insert-record-ui';

interface OptionProps {
  syncId?: string;
  isOpenMenuList?: boolean;
  newBlock?: boolean;
  pos?: 'after' | 'before';
}
export const useCreateBlockAndOpenMenu = () => {
  const openBlockMenuList = useOpenBlockMenuList();
  const insertRecord = useInsertRecordUI();
  const isInRight = useIsInRight();
  const transaction = useTransaction();
  const { selectedBlockHistory } = useSelectedBlockHistory();

  return (uuid: string, option?: OptionProps) => {
    const { isOpenMenuList = true, newBlock, syncId, pos = 'after' } = option || {};
    const block = cache.blocks[uuid];
    const isRecord = block && isCollection(cache.blocks[block.parentId]?.type);

    if (!block) return;
    if (block.type === BlockType.TEXTAREA && isBlockNoText(block.data) && !newBlock) {
      bizTracker.event('block_add');
      void sleep(100).then(() => openBlockMenuList(block.uuid, { isOpenMenuList, syncId }));
      selectedBlockHistory([]);
      return;
    }

    if (isRecord) {
      const blockNode = querySelectorFromMainContent(`[data-block-id="${uuid}"]`, isInRight);
      const { viewId, groupValue } = (blockNode as HTMLElement).dataset;
      if (viewId) {
        const isAfter = pos === 'after';
        const propertyValues = getValuesFromGroupValue({ viewId, groupValue });
        void insertRecord({
          viewId,
          where: isAfter ? { after: block.uuid } : { before: block.uuid },
          propertyValues,
        });
        selectedBlockHistory([]);
      }
      return;
    }

    transaction(() => {
      const newBlockId = addBlock({}, { parentId: block.parentId, [pos]: block.uuid });
      if (newBlockId) {
        bizTracker.event('block_add');
        void sleep(100).then(() => openBlockMenuList(newBlockId, { isOpenMenuList, syncId }));
        selectedBlockHistory([]);
      }
    });
  };
};

/** 快捷键插入块 */
export const useShortcutKeyInsertBlock = () => {
  const createBlockAndOpenMenu = useCreateBlockAndOpenMenu();
  return (option?: { syncId?: string; pos?: 'after' | 'before' }) => {
    const { pos } = option || {};

    const block = pos === 'before' ? first(cache.ui.selectedBlocks) : last(cache.ui.selectedBlocks);

    if (!block) return;

    bizTracker.event(pos === 'before' ? 'insert_up' : 'insert_below', {
      block_name: covertBlockToTitle(block.blockId),
    });

    createBlockAndOpenMenu(block.blockId, {
      newBlock: true,
      pos,
      syncId: block.syncId,
    });
  };
};

export const useIsFoldList = (uuid?: string) => {
  const block = usePickBlock(uuid, ['subNodes']);
  /** 判断是否为需要折叠列表，隐藏+号菜单 */
  const isFoldList =
    block &&
    block.subNodes.length > 0 &&
    [BlockType.LIST, BlockType.ORDER_LIST, BlockType.TODO].includes(block.type);
  return isFoldList;
};
