import { useEffect, useState } from 'react';

export const useScrollerChange = (
  scroller: HTMLElement | null | React.RefObject<HTMLElement | null>
) => {
  const [scrollInfo, setScrollInfo] = useState(() => {
    if (!scroller) return;
    if ('current' in scroller) {
      return { left: scroller?.current?.scrollLeft, top: scroller?.current?.scrollTop };
    }
    return { left: scroller?.scrollLeft, top: scroller?.scrollTop };
  });
  useEffect(() => {
    if (!scroller) return;
    let element: HTMLElement | null;
    if ('current' in scroller) {
      element = scroller.current;
    } else {
      element = scroller;
    }
    if (!scroller) return;
    const onScroll = () => {
      setScrollInfo({
        left: element?.scrollLeft,
        top: element?.scrollTop,
      });
    };
    element?.addEventListener('scroll', onScroll);
    return () => {
      element?.removeEventListener('scroll', onScroll);
    };
  }, [scroller]);
  return scrollInfo;
};
