import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { Tooltip } from 'src/common/components/tooltip';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { addColumn } from 'src/redux/managers/block/add-column';
import { getState } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';

interface Props {
  blockId: string;
  onCloseModal: () => void;
}
export const ColumnPanel: FC<Props> = (props) => {
  const { blockId, onCloseModal } = props;
  const transaction = useTransaction();

  const [insertColumnCount, setInsertColumnCount] = useState(0);
  const parentId = useObservableBlock(blockId, (block) => block?.parentId);

  const handleClick = () => {
    onCloseModal();
    const { blocks } = getState();
    const parent = blocks[blocks[blockId]?.parentId ?? ''];
    let columnId = '';
    if (parent?.type === BlockType.COLUMN) {
      columnId = parent.uuid;
    }
    if (columnId) {
      // 这里的逻辑不需要再创建分栏块，因为本身就在分栏块上，只需要添加column即可
      const column = blocks[columnId];
      if (column) {
        transaction(() => {
          const newIds: string[] = [];
          for (let i = 0; i < insertColumnCount; i++) {
            const newId = addBlock(
              { type: BlockType.TEXTAREA },
              { parentId: column.uuid } // 这个是临时的
            );
            newId && newIds.push(newId);
          }
          if (newIds.length) {
            newIds.forEach((id) => {
              addColumn([id], column.uuid, 'right');
            });
          }
        });
      }
    } else {
      const newIds: string[] = [];
      transaction(() => {
        for (let i = 0; i < insertColumnCount; i++) {
          const newId = addBlock(
            { type: BlockType.TEXTAREA },
            { parentId: parentId ?? '', after: blockId }
          );
          newId && newIds.push(newId);
        }
        if (newIds.length) {
          let columnBlockId: string | undefined = blockId;
          newIds.forEach((id) => {
            columnBlockId = addColumn([id], columnBlockId ?? blockId, 'right');
          });
        }
      });
    }
  };
  return (
    <div
      className="next-modal flex h-14 py-2.5 px-4 space-x-[5px] content-between"
      onMouseLeave={() => {
        setInsertColumnCount(0);
      }}
    >
      <Tooltip popup="一列分栏">
        <div
          className={cx('cursor-pointer w-5 h-full bg-grey5 opacity-30', {
            'opacity-100': insertColumnCount >= 1,
          })}
          onMouseEnter={() => {
            setInsertColumnCount(1);
          }}
          onClick={handleClick}
        />
      </Tooltip>
      <Tooltip popup="两列分栏">
        <div
          className={cx('cursor-pointer w-5 h-full bg-grey5 opacity-30', {
            'opacity-100': insertColumnCount >= 2,
          })}
          onMouseEnter={() => {
            setInsertColumnCount(2);
          }}
          onClick={handleClick}
        />
      </Tooltip>
      <Tooltip popup="三列分栏">
        <div
          className={cx('cursor-pointer w-5 h-full bg-grey5 opacity-30', {
            'opacity-100': insertColumnCount >= 3,
          })}
          onMouseEnter={() => {
            setInsertColumnCount(3);
          }}
          onClick={handleClick}
        />
      </Tooltip>
      <Tooltip popup="四列分栏">
        <div
          className={cx('cursor-pointer w-5 h-full bg-grey5 opacity-30', {
            'opacity-100': insertColumnCount >= 4,
          })}
          onMouseEnter={() => {
            setInsertColumnCount(4);
          }}
          onClick={handleClick}
        />
      </Tooltip>
    </div>
  );
};
