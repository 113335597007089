import { BlockType } from '@next-space/fe-api-idl';
import type { ColorInfo } from 'src/components/color-picker';
import { ColorPicker } from 'src/components/color-picker';
import { DEFAULT_COLOR_PICKER_DATA } from 'src/components/color-picker/default-data';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { saveLastMarkBlockInfo } from '../../utils/mark-block-utils';
import { covertBlockToTitle } from '../menu-list/menu-item';

interface Props {
  items?: typeof DEFAULT_COLOR_PICKER_DATA;
  blockId: string;
  onCloseModal: () => void;
  onColorSelect?: (colorInfo: ColorInfo) => void;
  disabledHasRecently?: boolean;
}

export const ColorPickerModule = (props: Props) => {
  const { onCloseModal, blockId, items, disabledHasRecently, onColorSelect } = props;
  const block = usePickBlock(blockId, []);
  const { selectedBlockHistory, selectedBlock } = useSelectedBlockHistory();

  const transaction = useTransaction();

  return (
    <ColorPicker
      disabledHasRecently={disabledHasRecently}
      items={items || DEFAULT_COLOR_PICKER_DATA}
      onColorSelect={(colorInfo) => {
        onColorSelect?.(colorInfo);
        const ids = selectedBlock.length ? selectedBlocksToIds(selectedBlock) : [blockId];
        bizTracker.event('color', {
          block_name: covertBlockToTitle(blockId),
          is_background: colorInfo.isBgColor,
        });
        transaction(() => {
          ids.forEach((id) => {
            updateBlock(id, {
              backgroundColor: colorInfo.isBgColor ? colorInfo.colorkey : '',
              textColor: colorInfo.isBgColor ? '' : colorInfo.colorkey,
            });
          });
          selectedBlockHistory([]);
        });
        onCloseModal();
        if (block?.type === BlockType.MARK) {
          saveLastMarkBlockInfo({
            backgroundColor: colorInfo.isBgColor ? colorInfo.colorkey : '',
            textColor: colorInfo.isBgColor ? '' : colorInfo.colorkey,
          });
        }
      }}
    />
  );
};
