import { useEffect, useRef } from 'react';
import { useFocusEditableByMouseEvent } from 'src/hooks/editor/use-focus-by-id';
import type { SegmentType } from 'src/redux/types';

/**
 * 监听focus状态并且在未focus双击时focus到对应的位置上,没有这个方法，双击会变成选中文本
 */
export const useMonitorFocused = (
  divRef: React.RefObject<HTMLElement>,
  blockId: string,
  type?: SegmentType
) => {
  const focusRef = useRef(false);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const focusEditable = useFocusEditableByMouseEvent();
  useEffect(() => {
    const divContainer = divRef.current;
    if (!divContainer) return;
    const clearTimer = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
    };
    const focusin = () => {
      clearTimer();
      // 要延迟一下，等doubleClick执行之后再设置这个值
      timeoutRef.current = setTimeout(() => {
        focusRef.current = true;
      }, 500);
    };
    const focusout = () => {
      clearTimer();
      focusRef.current = false;
    };
    const doubleClick = (e: MouseEvent) => {
      if (focusRef.current) return; // focus状态下不需要再次focus
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      focusEditable(blockId, e, type);
    };

    divContainer.addEventListener('dblclick', doubleClick);
    divContainer.addEventListener('focusin', focusin);
    divContainer.addEventListener('focusout', focusout);

    return () => {
      divContainer.removeEventListener('focusin', focusin);
      divContainer.removeEventListener('focusout', focusout);
      divContainer.removeEventListener('dblclick', doubleClick);
    };
  }, [blockId, divRef, focusEditable, type]);
};
