import { Role } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import {
  useAddMindNode,
  useDeleteMindNode,
  useMindMapEngine,
  useMindMapId,
  useMindMapNode,
} from '@flowus/mind-map';
import { PermissionRole } from '@next-space/fe-api-idl';
import { useSize, useUnmount } from 'ahooks';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { message } from 'src/common/components/message';
import { useReadonly } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { useAllowCommentInSharePage, usePressedSpace } from 'src/services/app';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { supportComments } from 'src/utils/block-utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { useIsInFixWidthContext } from '../fix-width-context-provider';
import { useMindNodeClassName } from '../hook/use-mind-node-class-name';
import { useMindNodeFixWidth } from '../hook/use-mind-node-fix-width';
import { useMindNodeStyle } from '../hook/use-mind-node-style';
import { useUpdateMindNode } from '../hook/use-update-mind-node';
import type { NodeViewProps } from '../types';
import { NodePoint } from './node-point';
import { NoBindIdContext } from 'src/editor/editor/raw/no-bind-id-context';

// border宽,因为useSize拿到的宽高是不把border算进去的，因此这里要自己加一下
const BORDER_WIDTH = 4;

const NodeWrapper0 = forwardRef<HTMLElement, NodeViewProps>((props, ref) => {
  const { id, level, className, style, children, onDoubleClick, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const { width, height } = useSize(containerRef) || {};
  const mindNode = useMindMapNode(id, ['id', 'left', 'top', 'themeColor']);
  const engine = useMindMapEngine();
  const mindMapId = useMindMapId();
  const addMindNode = useAddMindNode();
  const updateMindNode = useUpdateMindNode();
  const readOnly = useReadonly(id);
  const mindNodeStyle = useMindNodeStyle(id, props.level);
  const isPressedSpace = usePressedSpace();
  const canBindId = useContext(NoBindIdContext);

  const deleteMindNode = useDeleteMindNode();
  const isSelected = useIsSelected(id);
  const block = usePickBlock(id, ['subNodes']);
  const nodeThemeColor = useObservableBlock(
    id,
    (block) => block?.data.format?.mindMappingFormat?.nodeThemeColor
  );
  const isInMain = usePageScene() === PageScene.MAIN;
  const fixWidth = useMindNodeFixWidth(id, level);

  const supportComment = supportComments(block);
  const roleCanComment = Role.contains(getPermissions(block?.uuid).role, PermissionRole.COMMENTER);
  const isShare = judgeSharePage();
  const allowCommentInSharePage = useAllowCommentInSharePage();
  const canCommentInNormal = !isShare && supportComment && roleCanComment && isInMain;
  const canCommentInShare = isShare && supportComment && roleCanComment && allowCommentInSharePage;

  const canComment = canCommentInNormal || canCommentInShare;
  const mindNodeClassName = useMindNodeClassName(id, level, canComment);
  const isInFixWidthContext = useIsInFixWidthContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => containerRef.current!, []);
  useEffect(() => {
    if (isInFixWidthContext) return;
    addMindNode(id, mindMapId === id ? '' : cache.blocks[id]?.parentId);
  }, [addMindNode, mindMapId, id, isInFixWidthContext]);

  const finalWidth = width ? width + BORDER_WIDTH : undefined;
  const finalHeight = height ? height + BORDER_WIDTH : undefined;
  const isRoot = level === 0;
  useEffect(() => {
    if (isInFixWidthContext) return;
    if (!block) return;
    if (!finalWidth || !finalHeight) return;
    updateMindNode(block, finalWidth, finalHeight, isRoot, nodeThemeColor);
  }, [updateMindNode, block, finalWidth, finalHeight, isInFixWidthContext, isRoot, nodeThemeColor]);
  useEffect(() => {
    if (!isInFixWidthContext) return;
    const width = finalWidth;
    if (!width) return;
    engine.updateMindNode({
      id,
      reallyWidth: width,
    });
  }, [engine, finalWidth, id, isInFixWidthContext]);

  useUnmount(() => {
    if (block?.type !== cache.blocks[id]?.type) {
      // 如果是块转换就不需要删除节点信息,否则因为新建的节点在屏幕以外，又处于focus状态，scroller会滚动到最左上角
      return;
    }
    if (isInFixWidthContext) return;
    deleteMindNode(id);
  });
  return (
    <>
      <div
        data-draggable={!readOnly && level !== 0 && !isPressedSpace}
        data-block-id={canBindId ? (isInFixWidthContext ? undefined : id) : undefined}
        ref={containerRef}
        data-no-cancel-selected
        data-mind-map-id={mindMapId}
        className={cx('node-wrapper absolute max-w-[520px]', mindNodeClassName, className)}
        style={{
          left: mindNode?.left ?? '-9999px',
          top: mindNode?.top ?? '-9999px',
          ...style,
          ...mindNodeStyle,
          width: fixWidth, // 如果是在FixContextProvider里的节点,这里fixWidth为undefined
        }}
        onDoubleClick={(e) => {
          if (readOnly) return;
          if (isPressedSpace) {
            message.warning({
              content: '当前处于拖拽模式，无法进行编辑，可在左下角工具栏处切换回编辑模式',
              duration: 3000,
            });
          }
          onDoubleClick?.(e);
        }}
        onMouseDownCapture={(e) => {
          if (readOnly && !canComment) return;
          if (!isSelected || cache.ui.selectedBlocks.length > 1) {
            // 没选中块之前不需要focus到富文本，所以把事件吃掉,但要继续往上传递，不要stopPropagation，否则拖拽事件会收不到事件
            e.preventDefault();
          }
        }}
        onClickCapture={(e) => {
          if (readOnly && !canComment) return;
          if (e.target instanceof Element) {
            if (!e.metaKey && e.target.closest('.disable-select')) {
              return;
            }
            // 必须点击在当前container下
            if (!e.target.closest('.node-wrapper')) {
              return;
            }
          }
          if (isPressedSpace) {
            e.preventDefault();
            e.stopPropagation();
            return;
          }
          if (!isSelected || cache.ui.selectedBlocks.length > 1) {
            // 选中之前吃掉事件
            e.preventDefault();
            e.stopPropagation();
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
            // ctrl+click多选
            if (e.metaKey) {
              const selectedBlock = cache.ui.selectedBlocks.find((s) => s.blockId === id);
              if (selectedBlock) {
                dispatch(uiActions.removeSelectBlock(selectedBlock));
              } else {
                dispatch(uiActions.addSelectBlock({ blockId: id, mindMapId }));
              }
              return;
            }
            // 单选
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: id,
                  mindMapId,
                },
              ])
            );
          }
        }}
        {...rest}
      >
        <div className="relative flex items-center w-full overflow-auto">
          {children}
          <BlockDiscussionsBadge className="disable-select ml-1 mr-2" blockId={id} />
        </div>
      </div>
      <NodePoint width={finalWidth} height={finalHeight} id={id} readonly={readOnly} />
    </>
  );
});

export const NodeWrapper = React.memo(NodeWrapper0);
