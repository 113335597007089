import type { FC } from 'react';
import { cx } from '../cx';

interface Props {
  open: boolean;
  disabled?: boolean;
  onSwitch?(open: boolean): void;
  bgClassName?: string;
  switchClassName?: string;
  fixPosition?: string;
  customColor?: string;
}

export const Switch: FC<Props> = (props) => {
  return (
    <button
      disabled={props.disabled}
      onClick={(event) => {
        event.stopPropagation();
        props.onSwitch?.(!props.open);
      }}
      className={cx(
        {
          'opacity-20': props.disabled,
          'cursor-not-allowed': props.disabled,
        },
        'relative flex-shrink-0 w-[30px] h-[18px] flex items-center rounded-full transition-all px-0.5',
        props.open ? props.customColor ?? 'bg-active_color' : 'bg-grey4',
        props.bgClassName
      )}
    >
      <span
        className={cx(
          'absolute bg-white w-3.5 h-3.5 rounded-full transition-all shadow-modal',
          props.switchClassName
        )}
        style={{
          transition: 'inherit',
          transform: props.open ? 'translateX(100%)' : 'translateX(0)',
          marginLeft: props.open ? props.fixPosition ?? '-2px' : '0',
        }}
      />
    </button>
  );
};
