import { completeGlobalModalQueue } from '@flowus/common/activity-queue';
import { useOnceAnyDay } from '@flowus/common/hooks';
import type { FC } from 'react';
import { useCallback, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { currentVersion, publicLink } from 'src/common/const';
import { PRODUCT_ALL_NAME } from 'src/const/title';
import type { BlockEditor as BlockEditor0 } from 'src/editor/editor/uikit/editor';
import { useFetchPage } from 'src/hooks/page';
import {
  getLargeVersion,
  getLocalVersion,
  getOnlineVersion,
  setLocalVersionDataValue,
  updateLocalVersion,
} from 'src/hooks/public/use-version';
import { Modals } from 'src/modals';
import { isFlowUsApp } from 'src/utils/electron-util';
import { PageScrollRefContext } from 'src/views/main/page-doc/context';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import { FeaturePanel } from '../service-center/feature-panel';
import { fetchImportantFeature } from '../service-center/fetch-important-feature';
import { createActivityModalAnchor } from './create-activity-modal-anchor';

let BlockEditor: typeof BlockEditor0;

export const antiCycleSet_BlockEditor = (BlockEditor0: typeof BlockEditor) => {
  BlockEditor = BlockEditor0;
};

const UpdateLogModal: FC<ModalSchema.CloseModal & { blockId: string; version?: string }> = ({
  onCloseModal,
  blockId,
  version,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div className="next-modal flex max-h-120 w-96 flex-col p-7 pb-5">
      <div className="text-h2 h-10 px-2.5">版本更新</div>
      <div className="text-t2 mb-5 px-2.5 text-grey3">
        {PRODUCT_ALL_NAME} {version}
      </div>
      <div className="max-h-full flex-1 overflow-y-auto" ref={scrollRef}>
        <PageSceneContext.Provider value={PageScene.UPDATE_LOG}>
          <PageScrollRefContext.Provider value={scrollRef}>
            <BlockEditor uuid={blockId} readonly={true} />
          </PageScrollRefContext.Provider>
        </PageSceneContext.Provider>
      </div>
      <div className="flex h-10 items-center justify-end">
        <a
          href={publicLink.updateLog}
          target="_blank"
          rel="noreferrer"
          className="text-t2 flex items-center text-grey4"
          onClick={() => onCloseModal()}
        >
          了解更多产品进展 <Icon name="IcArrowDateNext" className="ml-1" size="middle" />
        </a>
      </div>
    </div>
  );
};

export const useOpenImportantFeatureDialog = () => {
  const openModal = useOpenModal();
  const openStatus = useOnceAnyDay(`fetchImportantFeature-${currentVersion}-1`, {
    endDay: -1,
  });

  return useCallback(async () => {
    if (!openStatus.showOnce) return;
    const activityModalAnchor = createActivityModalAnchor();
    const result = await fetchImportantFeature();

    if (result) {
      openStatus.close();
      openModal.dropdown({
        modalId: Modals.IMPORTANT_FEATURE,
        offset: [0, 10],
        popcorn: activityModalAnchor.popcorn,
        closeAfterCallBack: () => {
          activityModalAnchor.remove();
        },
        content: ({ onCloseModal }) => {
          return (
            <FeaturePanel
              importantFeature={result}
              onComplete={() => {
                onCloseModal();
              }}
            />
          );
        },
      });
      return true;
    }
  }, [openModal, openStatus]);
};

export const useOpenUpdateLogModal = () => {
  const openModal = useOpenModal();
  const fetchPage = useFetchPage();

  return useCallback(async () => {
    if (!__HOST_PRODUCTION__) return;
    let localVersionData = getLocalVersion();

    // 记录下本地是初次访问，没pageId，更新后直接忽略
    const ignoreVersion = !localVersionData.pageId;

    // 如果是非open，需要判断下上次访问的是不是旧版。以便于再打开提示弹窗
    // electron不适用
    if (!localVersionData.open && !isFlowUsApp.check) {
      const versionRes = await getOnlineVersion();
      const onlineVersion: string = versionRes?.version ?? '';
      const local = getLargeVersion(localVersionData.version);
      const online = getLargeVersion(onlineVersion);
      if (online <= local) {
        return;
      }
      // 如果是第一次访问，ignoreVersion为true，取反后open就为false
      // eslint-disable-next-line require-atomic-updates
      localVersionData = await updateLocalVersion(onlineVersion, { open: !ignoreVersion });
      if (!localVersionData.pageId || ignoreVersion) {
        return;
      }
    }

    if (!localVersionData.open) {
      return;
    }

    await fetchPage(localVersionData.pageId || '');
    const activityModalAnchor = createActivityModalAnchor();

    setLocalVersionDataValue({ open: false });

    openModal.dropdown({
      modalId: Modals.UPDATE_LOG,
      popcorn: activityModalAnchor.popcorn,
      placement: 'top-start',
      offset: [0, 10],
      closeAfterCallBack: () => {
        activityModalAnchor.remove();
        completeGlobalModalQueue(Modals.UPDATE_LOG);
      },
      content: (_) => (
        <UpdateLogModal
          blockId={`${localVersionData.pageId}`}
          version={localVersionData.version}
          onCloseModal={_.onCloseModal}
        />
      ),
    });

    return true;
  }, [fetchPage, openModal]);
};
