import { useCallback, useEffect, useState } from 'react';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { setLocalStorage } from 'src/utils/local-storage';

export const useCollectionViewId = (viewParentId: string, embed = false) => {
  const localKey = `viewId:${viewParentId}`;

  const views = useObservableStore(
    ({ blocks, collectionViews }) => {
      return blocks[viewParentId]?.views?.filter((viewId) => {
        const view = collectionViews[viewId];
        return view && view.status !== -1;
      });
    },
    [viewParentId],
    {
      useRedux: true,
    }
  );

  const getViewId = useCallback(() => {
    const hashId = location.hash.slice(1).trim();
    const storageId = localStorage.getItem(localKey)?.trim();
    let initViewId = '';
    if (embed) {
      initViewId = storageId ?? '';
    } else {
      if (hashId) {
        initViewId = hashId;
      } else {
        initViewId = storageId ?? '';
      }
    }

    if (!(initViewId && views?.includes(initViewId))) {
      const firstViewId = views?.[0];
      if (firstViewId) {
        initViewId = firstViewId;
      }
    }

    return views?.includes(initViewId) ? initViewId : '';
  }, [embed, localKey, views]);

  const [viewId, setViewId] = useState<string>(() => {
    return getViewId();
  });

  useEffect(() => {
    setLocalStorage(localKey, viewId);

    if (!embed) {
      history.replaceState(null, '', `#${viewId}`);
    }

    const newViewId = getViewId();
    if (newViewId !== viewId) {
      setViewId(newViewId);
    }
  }, [embed, getViewId, localKey, viewId]);

  return [viewId, setViewId] as const;
};
