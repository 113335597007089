import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import { useMemo, type FC } from 'react';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useToggleNode } from '../hook/use-toggle-node';
import { useMindMapEngine, useMindMapId } from '@flowus/mind-map';
import { useStore } from 'react-redux';

export const ExpandNodeList: FC = () => {
  const openModal = useOpenModal();
  const toggleNode = useToggleNode();
  const mindMapId = useMindMapId();
  const engine = useMindMapEngine();
  const store = useStore();
  const items = useMemo(() => {
    return Array.from({ length: 6 }).map((_, index) => {
      const count = index + 1;
      const item: ListItem = {
        type: ListItemType.OPERATION,
        data: {
          title: `展开到 ${count} 层节点`,
          onClick: () => {
            const { blocks } = store.getState();
            // 如果是展开第一层，就收起第二层，展开第一层
            // 如果是展开第二层，就收起第三层，展开第一第二层
            // 如果是展开第n层，就收起第n+1层，展开第1-n层
            const loop = (id: string, level: number) => {
              const supportAddChildren = engine.option.supportAppendChild(id);
              if (level <= index) {
                if (supportAddChildren && blocks[id]?.subNodes.length) {
                  toggleNode(id, true, false);
                  blocks[id]?.subNodes.forEach((subNodeId) => {
                    loop(subNodeId, level + 1);
                  });
                }
              } else {
                // 如果是大于n层,就收起
                if (supportAddChildren && blocks[id]?.subNodes.length) {
                  toggleNode(id, false, false);
                }
              }
            };
            loop(mindMapId, 0);
            openModal.closeModal(Modals.EXPAND_NODE);
          },
        },
      };
      return item;
    });
  }, [engine, mindMapId, openModal, store, toggleNode]);

  return <ListView className="next-modal py-2" items={items} />;
};
