export const isInOtherBrowsers = () => {
  return /micromessenger|qqtheme|weibo/i.test(navigator.userAgent);
};

export const isInWeapp = () => {
  return (
    (!!navigator.userAgent.match(/micromessenger/i) &&
      !!navigator.userAgent.match(/miniprogram/i)) ||
    (window as any).__wxjs_environment === 'miniprogram'
  );
};
