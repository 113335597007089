export interface BindDataTestId {
  dataTestId?: string;
}

/** 给QA提供自动化测试属性（仅在测试环境出现
 * @param id 测试id
 * @returns 属性
 */
export const bindDataTestId = (id?: string) => {
  return {
    'data-test-id': id,
  };
};
export const tName = (className: string) => {
  if (import.meta.env.VITE_ENV !== 'production') return className;
  return '';
};

export const TestName = {
  openSwitchSpace: 't-open-switch-name',
};

/** 测试id */
export enum TestIds {
  // other
  menuDown = 'menu-down',
  menuMore = 'menu-more',
  // doc
  DocTitle = 'doc-title',
  // aside
  asideHeader = 'aside-header',
  asideTocHeaderCreateByPrivate = 'aside-toc-header-create-by-private',
  asideTocHeaderCreateBySpace = 'aside-toc-header-create-by-space',
  asideDriveHeaderCreate = 'aside-drive-header-create',
  asideItemCreate = 'aside-item-create-by-private',
  asideItemCreateByPrivate = 'aside-item-create-by-private',
  asideItemCreateBySpace = 'aside-item-create-by-space',
  asideItemToc = 'aside-item-toc',
  asideItemFolder = 'aside-item-folder',
  asideItemMore = 'aside-item-more',
  asideSpaceItem = 'aside-space-item',
  asideSpaceCreate = 'aside-space-create',
  asideSpaceSetting = 'aside-space-setting',
  // 回收站
  trashItemRestore = 'trash-item-restore',
  trashItemDelete = 'trash-item-delete',
  trashItem = 'trash-item',
  trashButton = 'trash-button',
  // delete bar
  deleteBarRestore = 'delete-bar-restore',
  deleteBarDelete = 'delete-bar-delete',
  // page-header
  pageHeaderMore = 'page-header-more',
  // move to
  moveToInput = 'move-to-input',
  moveToItem = 'move-to-item',
  // empty
  emptyCreateDoc = 'empty-create-doc',
  // setting
  settingAccountInput = 'setting-account-input',
  settingSpaceInput = 'setting-space-input',
  settingSpaceDelete = 'setting-space-delete',
  settingSave = 'setting-save',
  settingCancel = 'setting-cancel',
  // create space
  createSpaceCancel = 'create-space-cancel',
  createSpaceInput = 'create-space-input',
  createSpaceSave = 'create-space-save',
  warningModalConfirm = 'warning-modal-confirm',
  warningModalCancel = 'warning-modal-cancel',
  // drive
  driveHeaderUpload = 'drive-header-upload',
  driveHeaderFolder = 'drive-header-folder',
  driveTitle = 'drive-title',
  driveFileItem = 'drive-file-item',
  driveFileItemInput = 'drive-file-item-input',
  driveFileShare = 'drive-file-share',
  driveFileMore = 'drive-file-more',
}
