import { BlockEmptyView } from 'src/editor/component/block-empty-view';
import { judgeSharePage } from 'src/utils/getPageId';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';

export const AdBlockElement: BlockElement = ({ id, root }) => {
  const isShare = judgeSharePage();
  if (isShare) return null;
  return (
    <BlockDrop id={id} className="py-1 my-px w-full pr-2" horizontal={root}>
      <BlockEmptyView
        readonly={true}
        onClick={() => {}}
        iconProp={{ name: 'IcUploadBookmark', size: 'normal' }}
        text={'广告块(需开启分享，且仅在android端的微信/qq浏览器内展示)'}
        className="cursor-default"
      />
    </BlockDrop>
  );
};
