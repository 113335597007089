import type { KeyMap } from 'codemirror';
import CodeMirror from 'codemirror';
import { DEFAULT_CODE_LANGUAGE } from './const';
import { getLastCodeLanguage } from 'src/utils/block-utils';

const keyMaps = (CodeMirror as any).keyMap as { [key: string]: KeyMap };
const keyMap = {
  ...keyMaps['default'],
};
delete keyMap['Ctrl-Z'];
delete keyMap['Ctrl-Y'];
delete keyMap['Ctrl-D'];
delete keyMap['Cmd-Z'];
delete keyMap['Cmd-Y'];
delete keyMap['Cmd-D'];
keyMaps['modified'] = keyMap;

// NOTE: Vite import.meta.glob does not support modules inside node_modules
// So we have to hardcode those modes

const loadMode = async (modeName: string): Promise<void> => {
  switch (modeName) {
    case 'apl':
      // @ts-ignore no types
      return import('codemirror/mode/apl/apl');
    case 'asciiarmor':
      // @ts-ignore no types
      return import('codemirror/mode/asciiarmor/asciiarmor');
    case 'asn.1':
      // @ts-ignore no types
      return import('codemirror/mode/asn.1/asn.1');
    case 'asterisk':
      // @ts-ignore no types
      return import('codemirror/mode/asterisk/asterisk');
    case 'brainfuck':
      // @ts-ignore no types
      return import('codemirror/mode/brainfuck/brainfuck');
    case 'clike':
      // @ts-ignore no types
      return import('codemirror/mode/clike/clike');
    case 'cobol':
      // @ts-ignore no types
      return import('codemirror/mode/cobol/cobol');
    case 'clojure':
      // @ts-ignore no types
      return import('codemirror/mode/clojure/clojure');
    case 'css':
      // @ts-ignore no types
      return import('codemirror/mode/css/css');
    case 'cmake':
      // @ts-ignore no types
      return import('codemirror/mode/cmake/cmake');
    case 'coffeescript':
      // @ts-ignore no types
      return import('codemirror/mode/coffeescript/coffeescript');
    case 'commonlisp':
      // @ts-ignore no types
      return import('codemirror/mode/commonlisp/commonlisp');
    case 'cypher':
      // @ts-ignore no types
      return import('codemirror/mode/cypher/cypher');
    case 'python':
      // @ts-ignore no types
      return import('codemirror/mode/python/python');
    case 'crystal':
      // @ts-ignore no types
      return import('codemirror/mode/crystal/crystal');
    case 'sql':
      // @ts-ignore no types
      return import('codemirror/mode/sql/sql');
    case 'd':
      // @ts-ignore no types
      return import('codemirror/mode/d/d');
    case 'dart':
      // @ts-ignore no types
      return import('codemirror/mode/dart/dart');
    case 'diff':
      // @ts-ignore no types
      return import('codemirror/mode/diff/diff');
    case 'django':
      // @ts-ignore no types
      return import('codemirror/mode/django/django');
    case 'dockerfile':
      // @ts-ignore no types
      return import('codemirror/mode/dockerfile/dockerfile');
    case 'dtd':
      // @ts-ignore no types
      return import('codemirror/mode/dtd/dtd');
    case 'dylan':
      // @ts-ignore no types
      return import('codemirror/mode/dylan/dylan');
    case 'ebnf':
      // @ts-ignore no types
      return import('codemirror/mode/ebnf/ebnf');
    case 'ecl':
      // @ts-ignore no types
      return import('codemirror/mode/ecl/ecl');
    case 'eiffel':
      // @ts-ignore no types
      return import('codemirror/mode/eiffel/eiffel');
    case 'elm':
      // @ts-ignore no types
      return import('codemirror/mode/elm/elm');
    case 'htmlembedded':
      // @ts-ignore no types
      return import('codemirror/mode/htmlembedded/htmlembedded');
    case 'erlang':
      // @ts-ignore no types
      return import('codemirror/mode/erlang/erlang');
    case 'factor':
      // @ts-ignore no types
      return import('codemirror/mode/factor/factor');
    case 'fcl':
      // @ts-ignore no types
      return import('codemirror/mode/fcl/fcl');
    case 'forth':
      // @ts-ignore no types
      return import('codemirror/mode/forth/forth');
    case 'fortran':
      // @ts-ignore no types
      return import('codemirror/mode/fortran/fortran');
    case 'mllike':
      // @ts-ignore no types
      return import('codemirror/mode/mllike/mllike');
    case 'gas':
      // @ts-ignore no types
      return import('codemirror/mode/gas/gas');
    case 'gherkin':
      // @ts-ignore no types
      return import('codemirror/mode/gherkin/gherkin');
    case 'gfm':
      // @ts-ignore no types
      return import('codemirror/mode/gfm/gfm');
    case 'go':
      // @ts-ignore no types
      return import('codemirror/mode/go/go');
    case 'groovy':
      // @ts-ignore no types
      return import('codemirror/mode/groovy/groovy');
    case 'haml':
      // @ts-ignore no types
      return import('codemirror/mode/haml/haml');
    case 'haskell':
      // @ts-ignore no types
      return import('codemirror/mode/haskell/haskell');
    case 'haskell-literate':
      // @ts-ignore no types
      return import('codemirror/mode/haskell-literate/haskell-literate');
    case 'haxe':
      // @ts-ignore no types
      return import('codemirror/mode/haxe/haxe');
    case 'htmlmixed':
      // @ts-ignore no types
      return import('codemirror/mode/htmlmixed/htmlmixed');
    case 'http':
      // @ts-ignore no types
      return import('codemirror/mode/http/http');
    case 'idl':
      // @ts-ignore no types
      return import('codemirror/mode/idl/idl');
    case 'pug':
      // @ts-ignore no types
      return import('codemirror/mode/pug/pug');
    case 'javascript':
      // @ts-ignore no types
      return import('codemirror/mode/javascript/javascript');
    case 'jsx':
      // @ts-ignore no types
      return import('codemirror/mode/jsx/jsx');
    case 'jinja2':
      // @ts-ignore no types
      return import('codemirror/mode/jinja2/jinja2');
    case 'julia':
      // @ts-ignore no types
      return import('codemirror/mode/julia/julia');
    case 'livescript':
      // @ts-ignore no types
      return import('codemirror/mode/livescript/livescript');
    case 'lua':
      // @ts-ignore no types
      return import('codemirror/mode/lua/lua');
    case 'markdown':
      // @ts-ignore no types
      return import('codemirror/mode/markdown/markdown');
    case 'mirc':
      // @ts-ignore no types
      return import('codemirror/mode/mirc/mirc');
    case 'mathematica':
      // @ts-ignore no types
      return import('codemirror/mode/mathematica/mathematica');
    case 'modelica':
      // @ts-ignore no types
      return import('codemirror/mode/modelica/modelica');
    case 'mumps':
      // @ts-ignore no types
      return import('codemirror/mode/mumps/mumps');
    case 'mbox':
      // @ts-ignore no types
      return import('codemirror/mode/mbox/mbox');
    case 'nginx':
      // @ts-ignore no types
      return import('codemirror/mode/nginx/nginx');
    case 'nsis':
      // @ts-ignore no types
      return import('codemirror/mode/nsis/nsis');
    case 'ntriples':
      // @ts-ignore no types
      return import('codemirror/mode/ntriples/ntriples');
    case 'octave':
      // @ts-ignore no types
      return import('codemirror/mode/octave/octave');
    case 'oz':
      // @ts-ignore no types
      return import('codemirror/mode/oz/oz');
    case 'pascal':
      // @ts-ignore no types
      return import('codemirror/mode/pascal/pascal');
    case 'pegjs':
      // @ts-ignore no types
      return import('codemirror/mode/pegjs/pegjs');
    case 'perl':
      // @ts-ignore no types
      return import('codemirror/mode/perl/perl');
    case 'php':
      // @ts-ignore no types
      return import('codemirror/mode/php/php');
    case 'pig':
      // @ts-ignore no types
      return import('codemirror/mode/pig/pig');
    case 'null':
      return;
    case 'powershell':
      // @ts-ignore no types
      return import('codemirror/mode/powershell/powershell');
    case 'ini':
    case 'properties':
      // @ts-ignore no types
      return import('codemirror/mode/properties/properties');
    case 'protobuf':
      // @ts-ignore no types
      return import('codemirror/mode/protobuf/protobuf');
    case 'puppet':
      // @ts-ignore no types
      return import('codemirror/mode/puppet/puppet');
    case 'q':
      // @ts-ignore no types
      return import('codemirror/mode/q/q');
    case 'r':
      // @ts-ignore no types
      return import('codemirror/mode/r/r');
    case 'rst':
      // @ts-ignore no types
      return import('codemirror/mode/rst/rst');
    case 'rpm':
      // @ts-ignore no types
      return import('codemirror/mode/rpm/rpm');
    case 'ruby':
      // @ts-ignore no types
      return import('codemirror/mode/ruby/ruby');
    case 'rust':
      // @ts-ignore no types
      return import('codemirror/mode/rust/rust');
    case 'sas':
      // @ts-ignore no types
      return import('codemirror/mode/sas/sas');
    case 'sass':
      // @ts-ignore no types
      return import('codemirror/mode/sass/sass');
    case 'scheme':
      // @ts-ignore no types
      return import('codemirror/mode/scheme/scheme');
    case 'shell':
      // @ts-ignore no types
      return import('codemirror/mode/shell/shell');
    case 'sieve':
      // @ts-ignore no types
      return import('codemirror/mode/sieve/sieve');
    case 'slim':
      // @ts-ignore no types
      return import('codemirror/mode/slim/slim');
    case 'smalltalk':
      // @ts-ignore no types
      return import('codemirror/mode/smalltalk/smalltalk');
    case 'smarty':
      // @ts-ignore no types
      return import('codemirror/mode/smarty/smarty');
    case 'solr':
      // @ts-ignore no types
      return import('codemirror/mode/solr/solr');
    case 'soy':
      // @ts-ignore no types
      return import('codemirror/mode/soy/soy');
    case 'sparql':
      // @ts-ignore no types
      return import('codemirror/mode/sparql/sparql');
    case 'spreadsheet':
      // @ts-ignore no types
      return import('codemirror/mode/spreadsheet/spreadsheet');
    case 'stylus':
      // @ts-ignore no types
      return import('codemirror/mode/stylus/stylus');
    case 'swift':
      // @ts-ignore no types
      return import('codemirror/mode/swift/swift');
    case 'stex':
      // @ts-ignore no types
      return import('codemirror/mode/stex/stex');
    case 'verilog':
      // @ts-ignore no types
      return import('codemirror/mode/verilog/verilog');
    case 'tcl':
      // @ts-ignore no types
      return import('codemirror/mode/tcl/tcl');
    case 'textile':
      // @ts-ignore no types
      return import('codemirror/mode/textile/textile');
    case 'tiddlywiki':
      // @ts-ignore no types
      return import('codemirror/mode/tiddlywiki/tiddlywiki');
    case 'tiki':
      // @ts-ignore no types
      return import('codemirror/mode/tiki/tiki');
    case 'toml':
      // @ts-ignore no types
      return import('codemirror/mode/toml/toml');
    case 'tornado':
      // @ts-ignore no types
      return import('codemirror/mode/tornado/tornado');
    case 'troff':
      // @ts-ignore no types
      return import('codemirror/mode/troff/troff');
    case 'ttcn':
      // @ts-ignore no types
      return import('codemirror/mode/ttcn/ttcn');
    case 'ttcn-cfg':
      // @ts-ignore no types
      return import('codemirror/mode/ttcn-cfg/ttcn-cfg');
    case 'turtle':
      // @ts-ignore no types
      return import('codemirror/mode/turtle/turtle');
    case 'twig':
      // @ts-ignore no types
      return import('codemirror/mode/twig/twig');
    case 'webidl':
      // @ts-ignore no types
      return import('codemirror/mode/webidl/webidl');
    case 'vb':
      // @ts-ignore no types
      return import('codemirror/mode/vb/vb');
    case 'vbscript':
      // @ts-ignore no types
      return import('codemirror/mode/vbscript/vbscript');
    case 'velocity':
      // @ts-ignore no types
      return import('codemirror/mode/velocity/velocity');
    case 'vhdl':
      // @ts-ignore no types
      return import('codemirror/mode/vhdl/vhdl');
    case 'vue':
      // @ts-ignore no types
      return import('codemirror/mode/vue/vue');
    case 'xml':
      // @ts-ignore no types
      return import('codemirror/mode/xml/xml');
    case 'xquery':
      // @ts-ignore no types
      return import('codemirror/mode/xquery/xquery');
    case 'yacas':
      // @ts-ignore no types
      return import('codemirror/mode/yacas/yacas');
    case 'yaml':
      // @ts-ignore no types
      return import('codemirror/mode/yaml/yaml');
    case 'z80':
      // @ts-ignore no types
      return import('codemirror/mode/z80/z80');
    case 'mscgen':
      // @ts-ignore no types
      return import('codemirror/mode/mscgen/mscgen');
    case 'wast':
      // @ts-ignore no types
      return import('codemirror/mode/wast/wast');
    case 'makefile':
      // MAKE FILE 会死循环，暂时先用这个代替
      // @ts-ignore no types
      return import('codemirror/mode/clojure/clojure');
    case 'mermaid':
      // @ts-ignore no types
      return import('codemirror/mode/ruby/ruby');
    case 'solidity':
      // @ts-ignore no types
      return import('./solidity.js');
    case 'elixir':
      // @ts-ignore no types
      return import('./elixir.js');
    default:
      throw new Error();
  }
};

export const LOAD_MODE_OPTIONS = {
  path: (modeName: string) => modeName,
  loadMode: (modeName: string, cont: () => void) => {
    void loadMode(modeName).then(cont);
  },
};

export const modeWhitelist = [
  'C',
  'C#',
  'C++',
  'Clojure',
  'CMake',
  'Closure Stylesheets (GSS)',
  'CoffeeScript',
  'Common Lisp',
  'Crystal',
  'CSS',
  'D',
  'Dart',
  'Django',
  'Dockerfile',
  'diff',
  'EBNF',
  'Elm',
  'Erlang',
  'elixir',
  'Fortran',
  'F#',
  'Gherkin',
  'Go',
  'Ini',
  'Shell',
  'Groovy',
  'HAML',
  'Haskell',
  'Haxe',
  'HTML',
  'HTTP',
  'Java',
  'JavaScript',
  'JSON',
  'Julia',
  'Kotlin',
  'LESS',
  'LiveScript',
  'Lua',
  'Markdown',
  'Mathematica',
  'Matlab',
  'MakeFile',
  'Mermaid',
  'Nginx',
  'NSIS',
  'Objective-C',
  'Objective-C++',
  'OCaml',
  'Pascal',
  'Perl',
  'PHP',
  'Plain Text',
  'PowerShell',
  'Properties files',
  'ProtoBuf',
  'Puppet',
  'Python',
  'Q',
  'R',
  'RPM Spec',
  'Ruby',
  'Rust',
  'React',
  'SAS',
  'Scala',
  'Scheme',
  'SCSS',
  'Smalltalk',
  'Stylus',
  'SQL',
  'Solidity',
  'Swift',
  'LaTeX',
  'sTeX',
  'Tcl',
  'Toml',
  'Twig',
  'TypeScript',
  'VB.NET',
  'VBScript',
  'Verilog',
  'VHDL',
  'Vue',
  'XML',
  'XQuery',
  'YAML',
];

export function findLanguage(keyword0: string) {
  const keyword = keyword0.toLowerCase().trim();
  const infos = CodeMirror.modeInfo.filter((it) => modeWhitelist.includes(it.name));

  return (
    (infos.find((it) => it.alias?.includes(keyword) ?? false)?.name ||
      modeWhitelist.find((it) => it.toLocaleLowerCase() === keyword)) ??
    (getLastCodeLanguage() || DEFAULT_CODE_LANGUAGE)
  );
}

export const findModeByLanguageExtension = (name: string) => {
  switch (name) {
    case 'React':
      return CodeMirror.findModeByExtension('tsx');
    case 'TypeScript':
      return CodeMirror.findModeByExtension('ts');
    case 'Matlab':
      return CodeMirror.findModeByName('octave');
    case 'MakeFile':
      return { mime: undefined, mode: 'makefile' };
    case 'Mermaid':
      return { mime: undefined, mode: 'ruby' };
    case 'Solidity':
      return { mime: undefined, mode: 'solidity' };
    case 'Ini':
      return { mime: undefined, mode: 'ini' };
    case 'elixir':
      return { mime: undefined, mode: 'elixir' };
    default:
      return CodeMirror.findModeByName(name.toLocaleLowerCase());
  }
};
