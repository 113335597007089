import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import type { CellViewProps } from './types';

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  className?: string;
}

export const TextValueView: FC<Props> = ({ children, className, noWrap, ...rest }) => {
  return (
    <div {...rest} className={cx(className, noWrap ? 'whitespace-nowrap' : 'break-words')}>
      {children}
    </div>
  );
};

export const TextValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  let isFormula = false;
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        isFormula = true;
        const formulaTool = getFormulaTool(collectionId, state);
        return formulaTool.getValue(recordId, propertyId) as string;
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  if (isFormula && text && /^https?/.test(text.trim())) {
    // 响应飞哥的号召，将公式输出位 url 格式的文本展示为链接
    return (
      <div className={cx('p-2 leading-5 break-words')}>
        <a target="_blank" className="border-b border-grey5" href={text}>
          {text}
        </a>
      </div>
    );
  }

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};
