import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { Divider } from 'src/common/components/divider';
import { Avatar } from 'src/common/components/avatar';
import type { FC } from 'react';
import type { NextAIChatDTO } from 'src/services/app';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { MarkdownRender } from '@flowus/common/markdown-render';
import type { PDFViewApplication } from '../service/pdf-viewer-application';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { useUser } from 'src/hooks/user/use-user';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useCurrentUser } from 'src/hooks/user';
import { askAi } from './prompt-input';
import { emitter } from '@flowus/common/utils/emitter';
import { PDF_AI_SOURCE_COVER } from '../type';
import { useCurrentSpace } from 'src/hooks/space';

interface Props {
  ossName: string;
  uuid?: string;
  recordId?: string;
  propertyId?: string;
  application: PDFViewApplication;
  editable: boolean;
  container: React.RefObject<HTMLDivElement>;
}

export const ChatItem: FC<Props & { chatInfo: NextAIChatDTO }> = ({
  chatInfo,
  recordId,
  uuid: blockId,
  propertyId,
  ossName,
  editable,
  application,
  container,
}) => {
  const { uuid, ai, user, error, quote, createdBy, links = [], originQuote = [] } = chatInfo;
  const openModal = useOpenModal();
  const userData = useCurrentUser();
  const userInfo = useUser(createdBy);
  const userName = useUserName(createdBy, userInfo?.nickname);
  const currentSpace = useCurrentSpace();

  const deleteChat = () => {
    openModal.warning({
      title: '确定删除该会话？',
      confirm: async () => {
        void request.ai.deleteAiChatFile({ uuid }).then(() => {
          setAppUiState({
            $pdfAIContents: $appUiStateCache.$pdfAIContents.filter((item) => item.uuid !== uuid),
          });
          message.success('已删除');
        });
      },
    });
  };

  const copyChatInfo = () => {
    let str = '';
    if (quote) str = `${str + quote}\n\n`;
    if (user) str = `${str + user}\n\n`;
    if (ai) str = `${str + ai}\n\n`;
    if (links) {
      links.forEach((item) => {
        if (item.content) {
          str = `${str + item.content}\n\n`;
        }
      });
    }

    void writeTextInClipboard(str);
  };

  const handleLink = (pageNumber: number, coords: number[] = []) => {
    if (!pageNumber) return;
    if (!application.pdfViewer) return;

    const nodes = document.querySelectorAll(`.${PDF_AI_SOURCE_COVER}`);
    nodes.forEach((node) => node.remove());

    let destArray = undefined;
    const [x1 = 56.69292, _, __, y2 = 765.5412] = coords; // 左下右上
    if (x1 && y2) {
      destArray = [null, { name: 'XYZ' }, x1, y2 + 15, null];

      const { viewport, textLayer } = application.pdfViewer.getPageView(pageNumber - 1);

      if (textLayer && viewport) {
        renderContentCover(viewport, textLayer.textLayerDiv, coords);
      } else {
        const fn = (data: any) => {
          const { source } = data;
          const { textLayerDiv, viewport, pageNumber: page } = source;
          if (pageNumber !== page) return;
          emitter.off('pdfRenderPage', fn);

          if (!textLayerDiv || !viewport) return;
          renderContentCover(viewport, textLayerDiv, coords);
        };

        emitter.on('pdfRenderPage', fn);
      }
    }

    application.pdfViewer.scrollPageIntoView({
      pageNumber,
      destArray,
    });
  };

  const handleQuestion = async (question: string) => {
    if (!editable) return;

    blockId = blockId ?? recordId;
    if (!blockId) return;
    const params = {
      quote: [],
      question,
      ossName,
      blockId,
      propertyId,
      spaceId: currentSpace.uuid,
    };

    void askAi(userData.uuid, params, container);
  };

  const retry = () => {
    blockId = blockId ?? recordId;
    if (!blockId) return;

    const params = {
      quote: originQuote,
      question: user,
      ossName,
      blockId,
      propertyId,
      spaceId: currentSpace.uuid,
    };
    void askAi(createdBy, params, container, uuid);
  };

  const isQuestion = links.find((item) => item.type === 'question');

  return (
    <div className="flex flex-col group relative ">
      {user && (
        <div className="flex items-start py-2 w-fit">
          <Avatar
            className={'!text-t2-medium mr-2 rounded-full text-white mt-1.5'}
            imgClassName="rounded-full"
            name={userName || '客'}
            color={userInfo?.backgroundColor || undefined}
            icon={userInfo?.avatar ? { type: 'upload', value: userInfo.avatar } : undefined}
            iconSize={24}
          />

          <div className="flex-1 bg-active_color/10 rounded">
            {quote && (
              <div className="px-2 mt-2 text-t2 rounded flex relative text-grey3 max-h-[300px] overflow-auto">
                <span className="whitespace-pre-wrap ml-1 h-fit border-l-4 border-grey4 pl-2">
                  {quote}
                </span>
              </div>
            )}

            <div className="p-2 text-t2 rounded">{user}</div>
          </div>
        </div>
      )}

      <div className="flex items-start py-2 w-fit">
        <div className="w-6 h-6 mr-2 rounded-full flex items-center justify-center mt-1.5 flex-shrink-0">
          <Icon size="small" name="IcAi" />
        </div>

        <div className="flex-1 break-words">
          <div className="bg-grey7 p-2 text-t2 rounded min-h-[36px]">
            {!error && (
              <>
                {ai ? (
                  <MarkdownRender content={ai} />
                ) : (
                  <span className="relative top-0.5 w-0.5 h-4 bg-black cursor-text blink inline-block ml-0.5" />
                )}
              </>
            )}
            {error}
          </div>
          {error && (
            <div
              className="ml-auto text-t3 text-grey3 mt-2 flex items-center cursor-pointer"
              onClick={retry}
            >
              <Icon size="middle" name="IcTryAgain" className="mr-1" />
              重试
            </div>
          )}

          {isQuestion && (
            <>
              {links.map((item, index) => {
                const { content } = item;
                if (!content) return null;
                return (
                  <div
                    className="bg-grey7 p-2 text-t2 mt-2 rounded w-fit text-active_color cursor-pointer"
                    key={content}
                    onClick={() => handleQuestion(content)}
                  >
                    问题{index + 1}：{content}
                  </div>
                );
              })}
            </>
          )}

          {!isQuestion && links?.length > 0 && (
            <div className="bg-grey8 p-2 text-t2 mt-2 w-fit rounded">
              {links.map((item) => {
                return (
                  <span
                    className="text-grey3 underline cursor-pointer ml-2 first:ml-0"
                    key={item.pageNumber + (item.coords ?? []).toString()}
                    onClick={() => {
                      if (item.pageNumber) {
                        handleLink(item.pageNumber, item.coords ?? []);
                      }
                    }}
                  >
                    页码 {item.pageNumber}
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>

      <Divider className="my-1" />

      {editable && (
        <>
          <div className="absolute -top-3 right-0 border border-black/10 transition-opacity duration-250 rounded opacity-0 group-hover:opacity-100 flex bg-white2 items-center h-[26px]">
            <span
              className="px-1 border-r border-black/10 cursor-pointer h-full flex items-center animate-hover-black3"
              onClick={copyChatInfo}
            >
              <Icon name="IcCopy" size="middle" />
            </span>
            <span
              className="px-1 border-r border-black/10 cursor-pointer h-full flex items-center animate-hover-black3"
              onClick={deleteChat}
            >
              <Icon name="IcTrash" size="middle" />
            </span>
          </div>
        </>
      )}
    </div>
  );
};

const renderContentCover = (viewport: any, textLayerDiv: any, coords: number[]) => {
  if (coords.length !== 4) return;
  const [x1 = 56.69292, y1 = 159.4032, x2 = 535.59992, y2 = 765.5412] = coords; // 左下右上

  const coordsStr = coords.toString();
  let node = textLayerDiv.parentElement.querySelector(`[data-coords="${coordsStr}"]`);
  if (node) return;

  const point1 = viewport.convertToViewportPoint(x1, y1);
  const point2 = viewport.convertToViewportPoint(x2, y2);

  node = document.createElement('div');
  node.classList.add(PDF_AI_SOURCE_COVER);
  node.setAttribute('data-coords', coordsStr);
  textLayerDiv.parentElement.appendChild(node);
  node.style.cssText = `
    position: absolute;
    left: ${point1[0]}px;
    top : ${point2[1]}px;
    width: ${point2[0] - point1[0]}px;
    height: ${point1[1] - point2[1]}px;
    background: rgba(24, 160, 251, 0.10);
  `;
};
