import type { PermissionDTO } from '@next-space/fe-api-idl';
import { createAction } from '@reduxjs/toolkit';
import type { NextBlock } from '../types';

/** 创建 block */
export const CREATE_BLOCK = createAction<{ block: NextBlock; ignoreOp?: boolean; local?: boolean }>(
  'block/create'
);

/** 更新 block */
export const UPDATE_BLOCK = createAction<{
  uuid: string;
  patch: Partial<Omit<NextBlock, 'uuid'>>;
  path?: string[];
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('block/update');

export const LIST_BEFORE_BLOCK = createAction<{
  /** 移动内容 */
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  before?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;

  ignoreUpdateTime?: boolean;
}>('block/list-before');

export const LIST_AFTER_BLOCK = createAction<{
  /** 移动内容 */
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  after?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;

  ignoreUpdateTime?: boolean;
}>('block/list-after');

export const LIST_REMOVE_BLOCK = createAction<{
  /** 要从父节点删除的元素 */
  uuid: string;
  /** 容器 ID */
  parentId?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('block/list-remove');

/** 彻底删除 */
export const DESTROY_BLOCK = createAction<{
  uuid: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('block/destroy');

/** 设置权限 */
export const SET_BLOCK_PERMISSION = createAction<{
  uuid: string;
  permission: PermissionDTO;
}>('block/set-permission');

/** 移除权限 */
export const REMOVE_BLOCK_PERMISSION = createAction<{
  uuid: string;
  permission: PermissionDTO;
}>('block/remove-permission');
