import { UserAuthorizationType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { $currentUserCache } from 'src/services/user/current-user';

const MAPPING_NAMES = {
  [UserAuthorizationType.WECHAT]: '微信',
  [UserAuthorizationType.QQ]: 'QQ',
  [UserAuthorizationType.APPLE]: '苹果账号',
  [UserAuthorizationType.EMAIL]: '邮箱',
  [UserAuthorizationType.PHONE]: '手机',
  [UserAuthorizationType.PASSWORD]: '密码',
};

export const useOpenUnbindDialog = () => {
  const openModal = useOpenModal();
  const fetchMe = useFetchMe();
  return useCallback(
    (type: UserAuthorizationType) => {
      openModal.warning({
        title: '是否确认解除绑定?',
        content: `将无法继续使用${MAPPING_NAMES[type]}登录当前FlowUs账号`,
        confirmText: '确认',
        confirm: async () => {
          await request.infra.unbindAccount($currentUserCache.uuid, {
            type,
          });
          await fetchMe();
          message.success('解绑成功');
        },
      });
    },
    [fetchMe, openModal]
  );
};
