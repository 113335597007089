import { useMemo } from 'react';
import { RenderMode } from 'src/common/components/visible-render/types';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { BlockRenderer } from '../uikit/block';
import { SyncBlockElement } from './sync-block';
import { useSyncId } from './sync-block-context';

/** 为了更容易看得懂，特意syncReferenceBlock独立出来,这是影子块 */
export const SyncReferenceBlock: BlockElement = ({ id, root }) => {
  const syncReferenceBlock = usePickBlock(id, ['data'], ['ref']);
  const syncBlock = usePickBlock(syncReferenceBlock?.data.ref?.uuid, ['subNodes']);
  const isInSyncBlock = !!useSyncId();
  const subChild = useMemo(() => {
    return syncBlock?.subNodes.map((i) => (
      <BlockRenderer visibleRender={RenderMode.innerHtml} id={i} key={i} />
    ));
  }, [syncBlock?.subNodes]);
  if (!syncReferenceBlock || !syncBlock) return null;

  if (isInSyncBlock) return null; // 同步块不能嵌套

  return (
    <SyncBlockElement id={syncBlock.uuid} root={root} ownerBlockId={id}>
      {subChild}
    </SyncBlockElement>
  );
};
