import { segmentsToText } from '@flowus/common/subscribe/util';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { cx } from '@flowus/common/cx';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { useOpenModal } from '@flowus/common/next-modal';
import { transaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { textToSegments } from 'src/editor/utils/editor';
import { useReadonly } from 'src/hooks/page';
import { useNumberDesc, useUpdateChartFormatStyle } from 'src/hooks/chart';
import isHotkey from 'is-hotkey';

interface Props {
  id: string;
  className?: string;
  /** 标题或者描述 */
  type: 'segments' | 'desc';
}
export const ChartTitle: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const segments = useObservableBlock(props.id, (b) => b?.data.segments);
  const numberDesc = useNumberDesc(props.id);
  const isTitle = props.type === 'segments';

  const divRef = useRef<HTMLDivElement>(null);
  const readonly = useReadonly(props.id);
  const title = useMemo(() => {
    return segmentsToText(segments);
  }, [segments]);
  if (isTitle && !title) return null;
  if (!isTitle && !numberDesc) return null;
  return (
    <div
      ref={divRef}
      className={cx('text-left px-5 z-20  text-ellipsis w-[96%]', props.className)}
      onClick={(e) => {
        if (readonly) return;
        openModal.dropdown({
          placement: 'bottom-start',
          offset: [-4, -30],
          popcorn: e.currentTarget,
          content: ({ onCloseModal }) => {
            const width = divRef.current?.getBoundingClientRect().width || 300;
            return (
              <EditTitle
                onClose={onCloseModal}
                id={props.id}
                title={isTitle ? title : numberDesc}
                width={width}
                type={props.type}
              />
            );
          },
        });
      }}
    >
      {isTitle ? title : numberDesc}
    </div>
  );
};

const EditTitle: FC<{
  id: string;
  title: string;
  width: number;
  onClose: () => void;
  type?: 'segments' | 'desc';
}> = (props) => {
  const updateChartFormatStyle = useUpdateChartFormatStyle();
  const isTitle = props.type === 'segments';
  const [title, setTitle] = useState(props.title);
  const titleRef = useRef(title);
  titleRef.current = title;
  useEffect(() => {
    return () => {
      transaction(() => {
        if (props.type === 'segments') {
          updateBlock(props.id, {
            data: {
              segments: textToSegments(titleRef.current),
            },
          });
        } else {
          updateChartFormatStyle(props.id, {
            numberDesc: titleRef.current,
          });
        }
      });
    };
  }, [props.id, props.type, updateChartFormatStyle]);
  return (
    <input
      style={{
        width: props.width,
      }}
      autoFocus
      className={cx('px-2  h-8 next-modal', {
        'text-h4': isTitle,
        'text-t2 text-center': !isTitle,
      })}
      value={title}
      onChange={(e) => {
        setTitle(e.currentTarget.value);
      }}
      onKeyDown={(e) => {
        if (isHotkey('Enter')(e)) {
          props.onClose();
        }
      }}
    />
  );
};
