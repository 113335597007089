import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { getState } from 'src/redux/store';
import { UsersPermissionsList } from '../users-permission-list';
import { useSharePanel } from './components/use-share-panel';
import type { ShareProps, User } from './types';

export const ShareUserPermissionList: FC<Pick<ShareProps, 'uuid' | 'backToMainPanel'>> = ({
  uuid,
  backToMainPanel,
}) => {
  const sharePanel = useSharePanel(uuid);
  if (!sharePanel) return null;
  const { role, permissions, onChange } = sharePanel;

  return (
    <UsersPermissionsList
      back={backToMainPanel}
      permissions={permissions}
      role={role}
      title="所有协作者"
      onChange={onChange}
    />
  );
};

export const FormShareUserPermissionList: FC<{
  uuid: string;
  backToMainPanel?: () => void;
  num?: number;
  onlyUserList?: boolean;
}> = ({ uuid, backToMainPanel, num = 0, onlyUserList }) => {
  const collectionView = useCollectionView(uuid);
  const { formInviteUserPermissions = [] } = collectionView?.format || {};

  const onChange = (_: PermissionRole, permission: User) => {
    const view = getState().collectionViews?.[uuid];
    if (!view) return;

    updateViewFormat(uuid, {
      formInviteUserPermissions: view.format.formInviteUserPermissions?.filter(
        (it) => it.groupId !== permission.groupId || it.userId !== permission.userId
      ),
    });
  };

  return (
    <UsersPermissionsList
      isForm
      onlyUserList={onlyUserList}
      back={backToMainPanel}
      permissions={[...formInviteUserPermissions].splice(0, num)}
      role={PermissionRole.EDITOR}
      title="所有填写者"
      onChange={onChange}
    />
  );
};
