import { LOCAL_KEY_THEME_MODE } from 'src/common/const';
import { SettingThemeMode } from 'src/services/app/type';

export const getLocalStorageThemeMode = (): SettingThemeMode => {
  const localThemeMode = localStorage.getItem(LOCAL_KEY_THEME_MODE) as SettingThemeMode;
  if (localThemeMode) {
    const theme = SettingThemeMode[localThemeMode] as SettingThemeMode;
    return theme;
  }
  return SettingThemeMode.system;
};
