import { canPreViewImage } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import {
  CollectionCardImageDir,
  CollectionViewType,
  CoverAspectType,
  CoverType,
  TextType,
} from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import {
  useCardImageDir,
  useCardSize,
  useCoverAspectType,
  useCoverSetting,
} from 'src/hooks/collection-view/use-collection-view';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { usePickBlock } from 'src/utils/pick-block';
import { HeaderCover } from 'src/views/main/page-doc/header-cover';
import { getCardSize, getImageRadio } from '../../const';
import { useBitable } from '../../context';
import { ALIYUN_IMGPROCESS_SIZE_LIMIT } from '../../table-view/cell/files';
import { PageContent } from './page-content';

interface Props {
  recordId: string;
}

export const CardCover: FC<Props> = ({ recordId }) => {
  const { viewId, viewType } = useBitable();
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const containerWidth = useDebounceElementSize(imageContainerRef.current)?.width;
  const block = usePickBlock(recordId, ['data']);
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const { previewType, previewProperty } = useCoverSetting(viewId);
  const coverAspectType = useCoverAspectType(viewId);
  const isGallery = viewType === CollectionViewType.GALLERY;
  const isBoard = viewType === CollectionViewType.BOARD;

  const isShowCoverImage =
    (isBoard && previewType === CoverType.PAGE_COVER && block?.data.cover) ||
    (isGallery && previewType === CoverType.PAGE_COVER);

  const [showCover, setShowCover] = useState(false);
  useEffect(() => {
    if (showCover) return;

    const container = imageContainerRef.current;
    if (!container) return;

    if (isGallery) {
      container.style.height = `${container.clientWidth * getImageRadio(imageDir)}px`;
    }
    setShowCover(true);
  }, [imageDir, isGallery, showCover]);

  const renderCover = () => {
    if (previewProperty) {
      return <FilePropertyAsCover recordId={recordId} propertyId={previewProperty} />;
    }

    if (isShowCoverImage) {
      return (
        <HeaderCover
          className="relative rounded-t border-b border-black_006"
          uuid={recordId}
          fitImage={coverAspectType === CoverAspectType.CONTAIN}
          width={imageContainerRef.current?.clientWidth}
          isGalleryCard={isGallery}
          isVertical={imageDir === CollectionCardImageDir.VERTICAL}
          isCardCover
        />
      );
    }

    if (previewType === CoverType.PAGE_CONTENT) {
      return <PageContent recordId={recordId} />;
    }
  };

  const width = getCardSize(cardSize, imageDir);
  return (
    <div
      ref={imageContainerRef}
      style={{
        height: isGallery ? (containerWidth ?? width) * getImageRadio(imageDir) : undefined,
      }}
      className="flex items-center overflow-hidden"
    >
      {showCover && renderCover()}
    </div>
  );
};

const FilePropertyAsCover: FC<{
  recordId: string;
  propertyId: string;
}> = ({ recordId, propertyId }) => {
  const { viewId, viewType } = useBitable();
  const coverAspectType = useCoverAspectType(viewId);
  const segments = usePropertySegments(recordId, propertyId) ?? [];
  const [hide, setHide] = useState(false);
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const imageSegment = segments.find((it) => {
    return (
      it.type === TextType.URL &&
      it.fileStorageType === 'internal' &&
      (!it.size || it.size <= ALIYUN_IMGPROCESS_SIZE_LIMIT)
    );
  });

  if (!imageSegment) return null;

  const preViewOssName = canPreViewImage(imageSegment.url);

  if (!preViewOssName || hide) return null;

  const width = getCardSize(cardSize, imageDir);
  const isGallery = viewType === CollectionViewType.GALLERY;
  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <FilePreViewIcon
        className={cx(
          'object-center border-b border-black_006',
          coverAspectType === CoverAspectType.CONTAIN
            ? 'object-contain h-full w-auto'
            : 'object-cover w-full h-full'
        )}
        uuid={recordId}
        ossName={imageSegment.url}
        defaultIcon={<LoadingContainer className="text-grey3" description="加载中..." />}
        options={{
          resizeWidth: Math.round((isGallery ? width * 1.2 : width) * window.devicePixelRatio),
          hideVideoIcon: true,
          onError: () => setHide(true),
        }}
      />
    </div>
  );
};
