import { cx } from '@flowus/common/cx';
import { useDrag } from '@use-gesture/react';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useUpdateTableWidth } from 'src/hooks/block/use-update-table-width';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { DEFAULT_COL_WIDTH } from './helper';
import type { TableProps } from './types';

export const DragLines: FC<TableProps> = ({ tableId, tableRef }) => {
  const { columns, subNodes, widths } = useObservableBlock(
    tableId,
    (table) => {
      if (!table) {
        return {
          columns: [],
          subNodes: [],
          widths: [],
        };
      }

      const _columns = table?.data.format?.tableBlockColumnOrder ?? [];
      const _widths = _columns.map(
        (id) => table.data.format?.tableBlockColumnFormat?.[id]?.width ?? DEFAULT_COL_WIDTH
      );

      return {
        columns: _columns,
        subNodes: table?.subNodes ?? [],
        widths: _widths,
      };
    },
    [],
    { waitMode: 'throttle' }
  );

  return (
    <div
      className="absolute top-0 translate-x-2 translate-y-3 pointer-events-none"
      style={{ height: 'calc(100% - 30px)' }}
    >
      <div className="absolute top-0 w-full h-full">
        {subNodes.map((recordId, index) => {
          return <RowLine key={index} recordId={recordId} tableRef={tableRef} tableId={tableId} />;
        })}
      </div>
      <div className="flex h-full">
        {widths.map((width, index) => {
          const propertyId = columns[index];
          if (!propertyId) return null;
          return <ColumnLine key={index} width={width} propertyId={propertyId} tableId={tableId} />;
        })}
      </div>
    </div>
  );
};

interface ColumnProps {
  width: number;
  tableId: string;
  propertyId: string;
}

const MIN_COL_WIDTH = 38;

const ColumnLine: FC<ColumnProps> = ({ width, tableId, propertyId }) => {
  const { isSelecting, isDragging, droppingPosition } = useObservableStore(
    ({ simpleTable }) => {
      const { chosenCells, dropInfo } = simpleTable;
      const _droppingPosition =
        chosenCells?.tableId === tableId &&
        chosenCells.propertyId !== propertyId &&
        dropInfo?.propertyId === propertyId
          ? dropInfo?.position
          : '';
      return {
        isSelecting: !!simpleTable.selecting,
        isDragging: !!simpleTable.draggingStart,
        droppingPosition: _droppingPosition,
      };
    },
    [tableId, propertyId],
    { obsSimpleTable: true }
  );

  const updateWidth = useUpdateTableWidth(tableId, propertyId);

  const bind = useDrag(
    ({ offset: [mx] }) => {
      const width = Math.floor(Math.max(mx, MIN_COL_WIDTH));
      updateWidth(width);
    },
    { from: width, preventDefault: true }
  );

  if (isSelecting) return null;

  return (
    <div
      className="relative top-0 left-1 transition-all ease-out"
      style={{ width, height: 'calc(100% - 10px)' }}
    >
      {droppingPosition ? (
        <div
          className={cx(
            'absolute bg-black dark:bg-grey3 h-full w-[3px]',
            droppingPosition === 'left' && '-translate-x-px -translate-y-px left-0 top-0',
            droppingPosition === 'right' && 'translate-x-px -translate-y-px right-0 top-0'
          )}
        ></div>
      ) : (
        <div
          {...bind()}
          className={cx(
            'absolute top-px z-10 opacity-0 transition-opacity delay-150 h-full -right-[5px] w-[9px] pointer-events-auto',
            !isDragging && 'hover:cursor-col-resize hover:opacity-100'
          )}
          style={{ touchAction: 'none' }}
        >
          <div className="absolute left-[3px] top-0 w-[3px] -mt-px bg-black dark:bg-grey6 h-full"></div>
        </div>
      )}
    </div>
  );
};

interface RowProps {
  recordId: string;
}

const RowLine: FC<Omit<TableProps, 'indexRanges'> & RowProps> = ({
  recordId,
  tableId,
  tableRef,
}) => {
  const { isDragging, droppingPosition } = useObservableStore(
    ({ simpleTable }) => {
      const { chosenCells, dropInfo } = simpleTable;
      const _droppingPosition =
        chosenCells?.tableId === tableId &&
        chosenCells.recordId !== recordId &&
        dropInfo?.recordId === recordId
          ? dropInfo?.position
          : '';
      return {
        droppingPosition: _droppingPosition,
        isDragging: !!simpleTable.draggingStart,
      };
    },
    [tableId, recordId],
    { obsSimpleTable: true }
  );

  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!isDragging) return;
    const table = tableRef.current;
    if (!table) return;
    const td = table.querySelector(`[data-table-row-id="${recordId}"]`);
    setHeight(td?.parentElement?.clientHeight ?? 0);
  }, [recordId, isDragging, tableRef]);

  if (!isDragging) return null;

  return (
    <div className="relative top-0 left-1" style={{ height }}>
      <div
        hidden={!droppingPosition}
        className={cx(
          'absolute bg-black dark:bg-grey3 h-[3px] w-full',
          droppingPosition === 'top' && 'translate-x-px -translate-y-px top-0 left-0',
          droppingPosition === 'bottom' && 'translate-x-px translate-y-px bottom-0 left-0'
        )}
      ></div>
    </div>
  );
};
