export const getCurrencySymbols = (type?: number | string): string => {
  switch (type) {
    case 0:
      return '积分';
    case 1:
      return '¥';
    case 2:
      return '$';
    case 3:
      return '€';
    default:
      return '¥';
  }
};
