import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import React from 'react';
import { ossImagesPath } from 'src/image';
import { numberToPercent } from 'src/utils/number';

interface Props {
  levelSymbol?: string;
  totalScore?: number;
  score?: number;
  className?: string;
}

export const LevelScore: FC<Props> = (props) => {
  const { levelSymbol = '⭐️', totalScore = 5, score = 0, className } = props;
  const symbols: ReactNode[] = [];

  Array.from({ length: totalScore }).forEach((_, index) => {
    symbols.push(<React.Fragment key={index}>{SymbolMap[levelSymbol]}</React.Fragment>);
  });

  const reallyScore = Math.min(score, totalScore);
  return (
    <div className={cx('relative', className)}>
      <div
        style={{ clipPath: `inset(0 ${numberToPercent(1 - reallyScore / totalScore)} 0 0)` }}
        className="min-w-max flex"
      >
        {symbols}
      </div>
      <div
        style={{ clipPath: `inset(0 0 0 ${numberToPercent(reallyScore / totalScore)})` }}
        className="absolute inset-0 bg-white1 opacity-20 min-w-max flex"
      >
        {symbols}
      </div>
    </div>
  );
};

const SymbolMap: Record<string, ReactNode> = {
  '⭐️': <img src={`${ossImagesPath}/score/1.png`} className="w-4 h-4" alt="⭐️" />,
  '❤️': <img src={`${ossImagesPath}/score/2.png`} className="w-4 h-4" alt="❤️" />,
  '💡': <img src={`${ossImagesPath}/score/3.png`} className="w-4 h-4" alt="💡" />,
  '🔥': <img src={`${ossImagesPath}/score/4.png`} className="w-4 h-4" alt="🔥" />,
  '👍': <img src={`${ossImagesPath}/score/5.png`} className="w-4 h-4" alt="👍" />,
  '😀': <img src={`${ossImagesPath}/score/6.png`} className="w-4 h-4" alt="😀" />,
  '☘️': <img src={`${ossImagesPath}/score/7.png`} className="w-4 h-4" alt="☘️" />,
  '🟡️': <img src={`${ossImagesPath}/score/8.png`} className="w-4 h-4" alt="🟡️" />,
  '🔷': <img src={`${ossImagesPath}/score/9.png`} className="w-4 h-4" alt="🔷" />,
  '⬛': <img src={`${ossImagesPath}/score/10.png`} className="w-4 h-4" alt="⬛" />,
};
