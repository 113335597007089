import { cx } from '@flowus/common/cx';
import { DaySetting } from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import { useDebounceEffect } from 'ahooks';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { Switch } from 'src/common/components/switch';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'src/common/const';
import { useCurrentSpaceDaySetting } from 'src/hooks/space';
import { formatDateString, isValidTimeString } from 'src/utils/date-time-check';
import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG } from 'src/utils/date-utils';
import './ext.css';
import './style.css';

const getDateTimeString = (date: string, time: string) => date.concat(' ', time);

const NavbarElement: FC<{
  onPreviousClick: (callback?: () => void) => void;
  onNextClick: (callback?: () => void) => void;
}> = ({ onPreviousClick, onNextClick }) => {
  return (
    <div className="absolute right-4 flex justify-between top-5">
      <Tooltip className="mr-2 rounded-sm animate-hover">
        <Icon name="IcArrowDateBack" size="middle" onClick={() => onPreviousClick()} />
      </Tooltip>
      <Tooltip className="rounded-sm animate-hover">
        <Icon name="IcArrowDateNext" size="middle" onClick={() => onNextClick()} />
      </Tooltip>
    </div>
  );
};

const CaptionElement: FC<{ date: Date }> = ({ date }) => (
  <div className="table-caption mt-2.5 h-10">
    <span className="flex items-center h-full text-black text-t2-bold">
      {dayjs(date).format('YYYY年MM月')}
    </span>
  </div>
);
/**
 *
 * 这个组件是基于DateTimePicker修改的，支持选择时间范围。
 * 理论上这个组件应该替换掉DateTimePicker,但实际上因为ui和逻辑有差异，且研发时间有限，没做单个时间选取的兼容测试
 * 建议：除非时间组件ui上有较大变动需要重构才考虑合并。
 * TODO:重构输入框相关的代码
 * 由于这个组件是在dropdown上使用的，没法做成受控组件，props的值都是一次性不会变更的,因而导致逻辑有点怪
 */
export const DateTimePickerExt: FC<{
  date: { from: Date; to?: Date };
  selectRangeDate?: boolean;
  includeTime?: boolean; // 是否包含具体时间，默认为true
  showTimeSwitch?: boolean; // 是否展示时间点开关
  onChange?: (date: { from: Date; to?: Date }, includeTime: boolean) => void;
  onClear?: () => void;
  noShadow?: boolean;
  closeDatePicker?: () => void;
}> = ({
  closeDatePicker,
  selectRangeDate = false,
  includeTime = true,
  showTimeSwitch = true,
  ...props
}) => {
  const daySetting = useCurrentSpaceDaySetting();

  const startDateInputRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endDateInputRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const startDateErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endDateErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const startTimeErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endTimeErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const fromDateInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const toDateInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const [fromInputFocus, setFromInputFocus] = useState(false);
  const [toInputFocus, setToInputFocus] = useState(false);
  const [date, setDate] = useState(props.date);

  const [fromInputDate, setFromInputDate] = useState({
    startDate: dayjs(date.from).format(DATE_FORMAT),
    startTime: dayjs(date.from).format(TIME_FORMAT),
    validStartDate: true,
    validStartTime: true,
  });
  const [toInputDate, setToInputDate] = useState({
    startDate: date.to ? dayjs(date.to).format(DATE_FORMAT) : '',
    startTime: date.to ? dayjs(date.to).format(TIME_FORMAT) : '',
    validStartDate: true,
    validStartTime: true,
  });
  const [isIncludeTime, setIsIncludeTime] = useState(includeTime);

  const updateFromInputDate = useCallback((patch) => {
    setFromInputDate((preState) => ({ ...preState, ...patch }));
  }, []);
  const updateToInputDate = useCallback(
    (patch) => setToInputDate((preState) => ({ ...preState, ...patch })),
    []
  );
  useDebounceEffect(
    () => {
      if (date.to) {
        props.onChange?.(date, isIncludeTime);
      }
    },
    [date],
    { wait: 300 }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      fromDateInputRef.current.focus();
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('enter')(event)) {
        const startDate = formatDateString(fromInputDate.startDate);
        const isValid = isValidTimeString(fromInputDate.startTime);
        if (!!startDate && isValid) {
          closeDatePicker?.();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [fromInputDate.startDate, fromInputDate.startTime, closeDatePicker]);

  const handleTimeSwitch = (open: boolean) => {
    const newDate = {
      from: dayjs(
        getDateTimeString(dayjs(date.from).format(DATE_FORMAT), '12:00'),
        DATE_TIME_FORMAT
      ).toDate(),
      to:
        date.to ??
        dayjs(
          getDateTimeString(dayjs(date.to).format(DATE_FORMAT), '12:00'),
          DATE_TIME_FORMAT
        ).toDate(),
    };
    setDate(newDate);
    updateFromInputDate({
      startTime: '12:00',
      validStartTime: true,
    });
    updateToInputDate({
      startTime: '12:00',
      validStartTime: true,
    });
    setIsIncludeTime(open);
  };

  const handleDayClick = useCallback(
    (day: Date, m, e) => {
      e.stopPropagation();
      day = new Date(day); // 这里要new一个新的才能在后面重新设置时间
      const isSelectFirstDay = isSelectingFirstDay(date.from, date.to, day);
      day.setHours(isIncludeTime ? 12 : 0, 0, 0, 0);
      const startDate = dayjs(day).format(DATE_FORMAT);
      // 如果包含时间，就设置为12点(原有逻辑)，如果不包含就设置为0点(搜索需要)
      if (isSelectFirstDay) {
        setDate({
          from: day,
        });
        updateFromInputDate({
          startDate,
          startTime: isIncludeTime ? '12:00' : '00:00',
          validStartDate: true,
          validStartTime: true,
        });
      } else {
        day.setHours(23, 59, 0, 0);
        // 截止到一天的23:59
        setDate((s) => {
          return { ...s, to: day };
        });
        updateToInputDate({
          startDate,
          startTime: isIncludeTime ? '12:00' : '00:00',
          validStartDate: true,
          validStartTime: true,
        });
      }
    },
    [date.from, date.to, isIncludeTime, updateFromInputDate, updateToInputDate]
  );

  useEffect(() => {
    const instances: Instance[] = [];
    if (!fromInputDate.validStartDate) {
      instances.push(
        createPopper(startDateInputRef.current, startDateErrorRef.current, {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!toInputDate.validStartDate) {
      instances.push(
        createPopper(endDateInputRef.current, endDateErrorRef.current, {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!fromInputDate.validStartTime) {
      instances.push(
        createPopper(startDateInputRef.current, startTimeErrorRef.current, {
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!toInputDate.validStartTime) {
      instances.push(
        createPopper(endDateInputRef.current, endTimeErrorRef.current, {
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }

    return () => {
      instances.forEach((poperInstance: Instance) => poperInstance.destroy());
    };
  }, [
    fromInputDate.validStartDate,
    fromInputDate.validStartTime,
    toInputDate.validStartDate,
    toInputDate.validStartTime,
  ]);
  // copy from date-timer-picker demo
  const isSelectingFirstDay = (from: Date | undefined, to: Date | undefined, day: Date) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  const handleDateInputEnter = (isFromInput: boolean, focus: boolean) => {
    const startDate = formatDateString(
      isFromInput ? fromInputDate.startDate : toInputDate.startDate
    );
    if (focus) {
      isFromInput
        ? updateFromInputDate({ validStartDate: !!startDate })
        : updateToInputDate({ validStartDate: !!startDate });
    }
    if (startDate) {
      isFromInput
        ? updateFromInputDate({
            startDate,
          })
        : updateToInputDate({
            startDate,
          });
      const newDate = new Date(
        getDateTimeString(startDate, isFromInput ? fromInputDate.startTime : toInputDate.startTime)
      );
      setDate((s) => {
        if (isFromInput) return { ...s, from: newDate };
        return { ...s, to: newDate };
      });
    }
  };

  const handleTimeInputEnter = (isFromInput: boolean, focus?: boolean) => {
    const isValid = isValidTimeString(
      isFromInput ? fromInputDate.startTime : toInputDate.startTime
    );
    if (!focus) {
      isFromInput
        ? updateFromInputDate({ validStartTime: isValid })
        : updateToInputDate({ validStartTime: isValid });
    }

    if (isValid) {
      if (isFromInput) {
        setDate((s) => {
          return {
            ...s,
            from: dayjs(
              getDateTimeString(fromInputDate.startDate, fromInputDate.startTime),
              DATE_TIME_FORMAT
            ).toDate(),
          };
        });
      } else {
        setDate((s) => {
          return {
            ...s,
            to: dayjs(
              getDateTimeString(toInputDate.startDate, toInputDate.startTime),
              DATE_TIME_FORMAT
            ).toDate(),
          };
        });
      }
    }
  };

  const fromInputError = !fromInputDate.validStartDate || !fromInputDate.validStartTime;
  const toInputError = !toInputDate.validStartDate || !toInputDate.validStartTime;
  return (
    <div
      className={cx('w-[282px] bg-white2 pt-[10px] pb-[5px]', { 'next-modal': !props.noShadow })}
    >
      <div className="flex flex-col px-2.5">
        {/* 开始时间 */}
        <div
          ref={startDateInputRef}
          className={cx(`flex bg-grey9 rounded-sm py-1.5 h-8 border border-transparent`, {
            'input-error': fromInputError,
            'input-focus': fromInputFocus && !fromInputError,
          })}
        >
          <Input
            inputRef={fromDateInputRef}
            showBorder={false}
            className={`border-none ${selectRangeDate ? 'w-1/2' : 'w-full'}`}
            style={{ backgroundColor: 'transparent' }}
            onChange={(value) => updateFromInputDate({ startDate: value })}
            value={fromInputDate.startDate}
            onFocus={() => setFromInputFocus(true)}
            onEnter={() => {
              handleDateInputEnter(true, true);
            }}
            onBlur={() => {
              setFromInputFocus(false);
              handleDateInputEnter(true, false);
            }}
          />
          {isIncludeTime && (
            <Input
              showBorder={false}
              className="w-1/2 px-3 rounded-none"
              style={{ borderLeft: '1px solid var(--grey6)', backgroundColor: 'transparent' }}
              onChange={(value) => updateFromInputDate({ startTime: value })}
              onFocus={() => setFromInputFocus(true)}
              onEnter={() => {
                handleTimeInputEnter(true, true);
              }}
              onBlur={() => {
                setFromInputFocus(false);
                handleTimeInputEnter(true, false);
              }}
              value={fromInputDate.startTime}
            />
          )}
        </div>

        {!fromInputDate.validStartDate && (
          <div
            ref={startDateErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效日期</span>
          </div>
        )}
        {!fromInputDate.validStartTime && (
          <div
            ref={startTimeErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效时间</span>
          </div>
        )}
        {/* 结束时间 */}
        <div
          ref={endDateInputRef}
          className={cx(`mt-1 flex bg-grey9 rounded-sm py-1.5 h-8 border border-transparent`, {
            'input-error': toInputError,
            'input-focus': toInputFocus && !toInputError,
          })}
        >
          <Input
            inputRef={toDateInputRef}
            showBorder={false}
            className={`border-none ${selectRangeDate ? 'w-1/2' : 'w-full'}`}
            style={{ backgroundColor: 'transparent' }}
            onChange={(value) => updateToInputDate({ startDate: value })}
            value={toInputDate.startDate}
            onFocus={() => setToInputFocus(true)}
            onEnter={() => {
              handleDateInputEnter(false, true);
            }}
            onBlur={() => {
              setToInputFocus(false);
              handleDateInputEnter(false, false);
            }}
          />
          {isIncludeTime && (
            <Input
              showBorder={false}
              className="w-1/2 px-3 border-none rounded-none border-grey6"
              style={{ borderLeft: '1px solid var(--grey6)', backgroundColor: 'transparent' }}
              onChange={(value) => updateToInputDate({ startTime: value })}
              onFocus={() => setToInputFocus(true)}
              onEnter={() => {
                handleTimeInputEnter(false, true);
              }}
              onBlur={() => {
                setToInputFocus(false);
                handleTimeInputEnter(false, false);
              }}
              value={toInputDate.startTime}
            />
          )}
        </div>

        {!toInputDate.validStartDate && (
          <div
            ref={endDateErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效日期</span>
          </div>
        )}
        {!toInputDate.validStartTime && (
          <div
            ref={endTimeErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效时间</span>
          </div>
        )}
      </div>

      <DayPicker
        className="time-picker flex justify-center w-full"
        fixedWeeks
        selectedDays={[date.from, { from: date.from, to: date.to }]}
        firstDayOfWeek={daySetting === DaySetting.MON ? 1 : 0}
        navbarElement={NavbarElement}
        captionElement={CaptionElement}
        locale="zh-cn"
        month={date.from}
        months={MONTHS}
        onDayClick={handleDayClick}
        weekdaysShort={WEEKDAYS_SHORT}
        weekdaysLong={WEEKDAYS_LONG}
      />
      {showTimeSwitch && (
        <>
          <div className="w-[252px] h-px bg-grey6 my-1 mx-[15px] mt-3" />
          <div>
            <div className="flex items-center justify-between h-10 px-4">
              <span className="text-t2">时间点</span>
              <Switch open={isIncludeTime} onSwitch={handleTimeSwitch} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
