import { useContext } from 'react';
import { BitableContext } from 'src/bitable/context';
import { useInModalContext } from 'src/common/components/next-modal';
import { useCurrentUser } from 'src/hooks/user';
import { PageScene, usePageScene } from 'src/views/main/scene-context';

/**
 * 如果已经弹出过了，则在里面不需要再弹
 */
export const useCanShowPagePreview = () => {
  const pageScene = usePageScene();
  const inModalContext = useInModalContext();
  const inBitable = !!useContext(BitableContext);
  const isInMainOrShare =
    pageScene === PageScene.MAIN || pageScene === PageScene.SHARE || pageScene === undefined;
  const user = useCurrentUser();
  const previewDialog = user.setting?.previewDialog ?? true;

  return isInMainOrShare && !inBitable && previewDialog && !inModalContext;
};
