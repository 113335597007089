import type { PageActivityEditDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';

import { CollectionViewSubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const CollectionViewCreatedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  if (edit.collectionViewData?.current == null) return null;
  return (
    <FeedTemplate
      edits={[edit]}
      action="新增了视图"
      subject={<CollectionViewSubject view={edit.collectionViewData.current} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const CollectionViewChangedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  if (edit.collectionViewData?.after == null) return null;
  return (
    <FeedTemplate
      edits={[edit]}
      action="编辑了视图"
      subject={<CollectionViewSubject view={edit.collectionViewData.after} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};

export const CollectionViewDeletedFeed: FC<{ edit: PageActivityEditDTO }> = ({ edit }) => {
  if (edit.collectionViewData?.current == null) return null;
  return (
    <FeedTemplate
      edits={[edit]}
      action="删除了视图"
      subject={<CollectionViewSubject view={edit.collectionViewData.current} />}
      timestamp={edit.timestamp}
      changes={[]}
    />
  );
};
