import type { SegmentDTO } from '@next-space/fe-api-idl';

import { segmentsToText, textToSegments } from '../utils/segments-utils';
import { BiValueType } from './_abstract';

export class BiNumberType extends BiValueType<number> {
  constructor(public format?: string) {
    super();
  }

  fromSegments(segments: SegmentDTO[] | undefined): number | undefined {
    return parseNumber(segmentsToText(segments));
  }

  toSegments(value: number): SegmentDTO[] | undefined {
    if (this.isNonNullEmpty(value)) return undefined;
    return textToSegments(`${value}`);
  }

  isNonNullEmpty(value: number): boolean {
    return isNaN(value);
  }

  compare(value1: number, value2: number): number {
    return value1 - value2;
  }
}

function parseNumber(text: string): number | undefined {
  if (text === '') return undefined;
  let num = parseFloat(text);
  if (Number.isNaN(num)) {
    num = parseFloat(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
  }
  if (isNaN(num)) return undefined;
  return num;
}
