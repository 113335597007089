import produce from 'immer';
import { assign } from 'lodash-es';
import { $pageActivities, $setPageActivities } from './state';
import type { PageActivitiesType } from './type';

export const usePageActivity = (pageActivityId?: string) => {
  const activity = $pageActivities((state) => {
    if (!pageActivityId) return undefined;
    return state[pageActivityId];
  });
  return activity;
};

export const updatePageActivities = (params: Partial<PageActivitiesType>) => {
  $setPageActivities(produce((pre) => assign(pre, params)));
};
