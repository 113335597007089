/* eslint-env node */

export enum IpcMessage {
  /** 输出log */
  Log = 'flowus:log',
  /** 检查更新 */
  CheckForUpdates = 'flowus:check-for-updates',
  /** 无需更新 */
  UpdateNotAvailable = 'flowus:update-not-available',
  /** 需更新 */
  UpdateAvailable = 'flowus:update-available',
  /** 获取浏览器是否可以前进后退 */
  CanHistoryBackAndForward = 'flowus:can-history-back-and-forward',
  /** 快捷键打开设置 */
  OnOpenSetting = 'flowus:on-open-setting',
  /** 重新加载刷新当前页面 */
  Reload = 'flowus:reload',
  /** 双击缩放 */
  Zoom = 'flowus:zoom',
  /** 是否为全屏状态 */
  IsFullScreen = 'flowus:is-full-screen',
  /** 全屏状态发生变化 */
  OnFullScreenToggle = 'flowus:on-full-screen-toggle',
  /** 获取electron version */
  GetAppVersion = 'flowus:get-app-version',
  /** 打开搜索 */
  OnFind = 'flowus:on-find',
  /** 获取webContents */
  GetWebContents = 'flowus:get-web-contents',
  /** 下载文件 */
  DownloadUrl = 'flowus:download-url',
  /** electron dl 下载通信 */
  DownloadMessage = 'flowus:download-message',
  /** web 下载至本地 */
  WebDownloaded = 'flowus:web-downloaded',
  /** 拷贝文字 */
  CopyText = 'flowus:copy-text',
  /** 拷贝图片 */
  CopyImage = 'flowus:copy-image',
  /** 更新重启 */
  UpdateReload = 'flowus:update-reload',
  /** 更新下载完成 */
  UpdateDownloadComplete = 'flowus:update-download-complete',
  /** 更新失败 */
  UpdateError = 'flowus:update-error',
  /** 热更新就绪 */
  HotUpdateComplete = 'flowus:hot-update-complete',
  /** 热更新下载 */
  HotUpdateDowning = 'flowus:hot-update-downing',
  /** 无热更新 */
  NotHotUpdate = 'flowus:no-hot-update',
  /** 通用同步信息工具 */
  Message = 'flowus:message',
  /** 清空缓存并重启 */
  ClearCacheAndReload = 'flowus:clear-cache-and-reload',
}

/** 页面常用modal的id，open时传入指定的modalId，关闭时调用useCloseModal传入id来关闭 */
export enum Modals {
  AI_PAGE = 'AI_PAGE',
  ORDER_RECORD_MODAL = 'ORDER_RECORD_MODAL',
  REWARD_TASK = 'REWARD_TASK',
  /** 小组邀请活动 */
  ACQUISITION_TEAM_DIALOG = 'acquisition-team-dialog',
  /** 创建空间后的提示 */
  CREATE_SPACE_AFTER = 'CREATE_SPACE_AFTER',
  STRIPE_CHECKOUT = 'STRIPE_CHECKOUT',
  PAY_MODAL = 'PAY_MODAL',
  AD_ERROR = 'AD_ERROR',
  /** 日常优惠活动 */
  HOT_SPOT = 'HOT_SPOT',
  /** AI 公测活动 */
  AI_NOVICE = 'AI_NOVICE',
  /** 更新弹窗 */
  UPDATE_LOG = 'UPDATE_LOG',
  /** 重点更新 */
  IMPORTANT_FEATURE = 'IMPORTANT_FEATURE',
  MAXIMUM_CAPACITY_DIALOG = 'MAXIMUM_CAPACITY_DIALOG',
  AI_EDITOR = 'AI_EDITOR',
  CHECK_SPACE = 'CHECK_SPACE',
  SERVICE_CENTER = 'SERVICE_CENTER',
  TRASH = 'TRASH',
  SETTING = 'SETTING',
  DRIVE_FILE_CONTEXTMENU = 'DRIVE_FILE_CONTEXTMENU',
  FILE_PREVIEW = 'FILE_PREVIEW',
  PAGE_HEADER_MENU_LIST = 'PAGE_HEADER_MENU_LIST',
  BITABLE_PROPERTY_WIDGET = 'BITABLE_PROPERTY_WIDGET',
  DRIVE_UPLOAD_MENU = 'DRIVE_UPLOAD_MENU',
  TEMPLATE_PREVIEW = 'TEMPLATE_PREVIEW',
  TEMPLATE_CENTER = 'TEMPLATE_CENTER',
  MESSAGE_NOTIFICATION = 'MESSAGE_NOTIFICATION',
  HEADER_COVER_IMAGE = 'HEADER_COVER_IMAGE',
  /** 创建页面+提示 */
  CREATE_PAGE_TIP = 'CREATE_PAGE_TIP',
  /** KOL 活动 */
  KOL_SALES = 'KOL_SALES',
  /** 评论弹窗 */
  COMMENTS = 'COMMENTS_AT_CARET',
  /** 选择升级计划弹窗 */
  SELECT_UPDATE_PLAN = 'SELECT_UPDATE_PLAN',
  /** 单个文件上限弹窗 */
  SINGLE_CAPACITY = 'SINGLE_CAPACITY',
  QUICK_SEARCH = 'QUICK_SEARCH',
  /** 简单表格菜单 */
  TABLE_HEADER_MENU_LIST = 'TABLE_HEADER_MENU_LIST',
  /** 收集表预览 */
  PREVIEW_FORM = 'PREVIEW_FORM',
  /** 表格右上角 更多 弹窗 */
  BITABLE_OPTION_MENU = 'BITABLE_OPTION_MENU',
  /** 表格模板记录 */
  BITABLE_TEMPLATE_RECORDS = 'BITABLE_TEMPLATE_RECORDS',
  /** inline元素的panel */
  INLINE_PANEL = 'INLINE_PANEL',
  /** 清空模板的弹窗 */
  CLEAR_TEMPLATE = 'CLEAR_TEMPLATE',
  /** 页面历史弹窗 */
  DOC_HISTORY_MODAL_ID = 'DOC_HISTORY_MODAL_ID',
  /** 划选评论光标弹窗或者快捷键 */
  CONTEXT_DISCUSSIONS = 'CONTEXT_DISCUSSIONS',
  /** 文档高性能模式弹窗 */
  HIGH_PERFORMANCE_MODE = 'HIGH_PERFORMANCE_MODE',
  /** 同步本地编辑内容失败 */
  SYNC_UP_FAULT = 'SYNC_UP_FAULT',
  /** 同步块菜单 */
  SYNC_BLACK_LINK = 'SYNC_BLACK_LINK',
  /** 升级额外权益弹窗 */
  UPGRADE_SUPPORT = 'UPGRADE_SUPPORT',
  /** 升级额外权益订单列表 */
  ORDER_LIST_SUPPORT = 'ORDER_LIST_SUPPORT',
  /** 容量订单列表 */
  CAPACITY_ORDER_LIST_ID = 'CAPACITY_ORDER_LIST_ID',
  /** 传输列表 id */
  TRANSFER_LIST_ID = 'TRANSFER_LIST_ID',
  /** Toolbar */
  TOOLBAR_ID = 'TOOLBAR_ID',
  /** ExchangeCode */
  EXCHANGE_CODE_ID = 'EXCHANGE_CODE_ID',
  /**积分商城 */
  SCORE_SHOP = 'SCORE_SHOP',
  /**积分兑换记录 */
  WARE_RECORD = 'WARE_RECORD',
  /** Mini Price Modal */
  MINI_PRICE_PLAN_MODAL = 'MINI_PRICE_PLAN_MODAL',
  /**图表设置id */
  CHART_PANEL_ID = 'CHART_PANEL_ID',
  CHART_THEME = 'CHART_THEME',
  EXPAND_NODE = 'EXPAND_NODE',
  SHARE_POSTER = 'SHARE_POSTER',
}

export enum MessageIds {
  UPLOAD_ICON = 'UPLOAD_ICON',
  NO_PERMISSION_SYNC_WARNING_ID = 'NO_PERMISSION_SYNC_WARNING_ID',
}

export enum OnceAnyDayIds {
  SettingProfitRedPoint = 'SettingProfitRedPoint',
}

export enum SpacePlanType {
  /** 个人专业版 */
  personal = 'personal',
  /** 个人免费版 */
  freePersonal = 'freePersonal',
  /** 团队版 */
  team = 'team',
  /** 小组试用版 */
  freeTeam = 'freeTeam',
  /** 小组版 */
  smallTeam = 'smallTeam',
}

export enum WallesIpc {
  DARK_MODE = 'dark-mode',
  PAGE_ID = 'page-id',
}

export enum AppIpc {
  OPEN_PAGE = 'go_to_page',
  OPEN_PAY_SUBSCRIPTION_PAGE = 'open_pay_subscription_page',
  GET_WPS_TOKEN = 'get_wps_token',
  GET_GLOBAL_CONFIG = 'get_global_config',
  UPDATE_GLOBAL_CONFIG = 'update_global_config',
}
