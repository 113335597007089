import type { Immutable } from 'immer';

import type { MindMapEngine } from './mind-map-engine';
import type { MindMapState, MindNode, Rect } from './types';

export const intersectRect = (a: Rect, b: Rect) => {
  return (
    a.x <= b.x + b.width && b.x <= a.x + a.width && a.y <= b.y + b.height && b.y <= a.y + a.height
  );
};

export function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius = 5,
  fill = false,
  stroke = true
) {
  const rectRadius = { tl: radius, tr: radius, br: radius, bl: radius };
  ctx.beginPath();
  ctx.moveTo(x + rectRadius.tl, y);
  ctx.lineTo(x + width - rectRadius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + rectRadius.tr);
  ctx.lineTo(x + width, y + height - rectRadius.br);
  ctx.quadraticCurveTo(x + width, y + height, x + width - rectRadius.br, y + height);
  ctx.lineTo(x + rectRadius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - rectRadius.bl);
  ctx.lineTo(x, y + rectRadius.tl);
  ctx.quadraticCurveTo(x, y, x + rectRadius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

/**寻找前一个节点，如果找不到同级的前一个节点 */
export const getMindMapPreNode = (engine: MindMapEngine, id: string) => {
  const level = engine.nodesByLevelMap[id];
  if (level === undefined) return;
  const nodes = engine.nodesByLevelOrder[level];
  const index = nodes?.indexOf(id);
  if (index === undefined || index === 0) return;
  return nodes?.[index - 1];
};
//如果是当前尾节点就找父的后一个兄弟的第一个节点
export const getMindMapNextNode = (engine: MindMapEngine, id: string) => {
  const level = engine.nodesByLevelMap[id];
  if (level === undefined) return;
  const nodes = engine.nodesByLevelOrder[level];
  if (!nodes) return;
  const index = nodes?.indexOf(id);
  if (index === undefined || index === nodes?.length - 1) return;
  return nodes?.[index + 1];
};
/**寻找前一个节点的id */
export const getMindMapPreNodeId = (engine: MindMapEngine, id: string) => {
  const node = engine.getMindNode(id);
  if (!node) return;
  const parentNode = engine.getMindNode(node.parentId);
  if (!parentNode) return;
  if (!parentNode.expanded) return;
  const index = parentNode.childrenIds.indexOf(id);
  if (index > 0) return parentNode.childrenIds[index - 1];
};

/**寻找前一个节点的id */
export const getMindMapNextNodeId = (engine: MindMapEngine, id: string) => {
  const node = engine.getMindNode(id);
  if (!node) return;
  const parentNode = engine.getMindNode(node.parentId);
  if (!parentNode) return;
  if (!parentNode.expanded) return;
  const index = parentNode.childrenIds.indexOf(id);
  if (index < parentNode.childrenIds.length - 1) return parentNode.childrenIds[index + 1];
};
//获取最后一个叶子节点
export const getMindMapLastLeaf = (state: Immutable<MindMapState>) => {
  const getLastLeaf = (id: string): Immutable<MindNode> | undefined => {
    const node = state.mindNodes[id];
    if (!node) return;
    if (!node.expanded) return node;
    if (node.childrenIds.length === 0) return node;
    const lastNodeId = node.childrenIds[node.childrenIds.length - 1];
    if (!lastNodeId) return;
    return getLastLeaf(lastNodeId);
  };
  return getLastLeaf(state.rootId);
};

type BeforeCallback = (
  rootId: string,
  parentId: string,
  isRoot: boolean,
  layerIndex: number,
  index: number
) => boolean | undefined;

type AfterCallback = (
  rootId: string,
  parentId: string,
  isRoot: boolean,
  layerIndex: number,
  index: number
) => void;

export const walk = (
  engine: MindMapEngine,
  nodeId: string,
  parentId: string,
  beforeCallback?: BeforeCallback,
  afterCallback?: AfterCallback,
  isRoot?: boolean,
  layerIndex = 0,
  index = 0
) => {
  let stop: boolean | undefined = false;
  if (beforeCallback) {
    stop = beforeCallback(nodeId, parentId, !!isRoot, layerIndex, index);
  }
  const node = engine.getMindNode(nodeId);
  if (!node) return;
  if (!stop && node.childrenIds.length > 0) {
    const _layerIndex = layerIndex + 1;
    node.childrenIds.forEach((cId, nodeIndex) => {
      walk(engine, cId, nodeId, beforeCallback, afterCallback, false, _layerIndex, nodeIndex);
    });
  }
  afterCallback && afterCallback(nodeId, parentId, !!isRoot, layerIndex, index);
};
