import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { useModel } from 'src/common/create-model';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsFreeSpaceLimit } from 'src/hooks/space/use-is-pro-space';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { SettingModalCommon, SettingProvider } from './common';
import type { OpenSettingFrom } from './type';
import { SettingMenuType } from './type';
import { useOpenAskUpgradeSpace } from './use-open-ask-upgrade-space';

interface Props {
  title: ReactNode;
  from?: OpenSettingFrom;
  showSpacePlan?: boolean;
  description?: ReactNode;
  rightPart?: ReactNode;
  showSplitLine?: boolean;
  className?: string; // 有用
  titleClassName?: string;
  upgradeTip?: string;
  showUpgradeDialog?: boolean;
}

export const Option: FC<Props> = ({
  title,
  description,
  showSpacePlan,
  from,
  rightPart,
  showSplitLine = true,
  className,
  titleClassName,
  upgradeTip,
  showUpgradeDialog,
}) => {
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const { setCurrentSettingMenu, setIsFrom } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();
  const openAskUpgradeSpace = useOpenAskUpgradeSpace();
  const showDialog = showUpgradeDialog ?? isFreeSpaceLimit;

  return (
    <div className={cx('mt-2.5', className)}>
      {showSplitLine && <SettingModalCommon.SettingDivider />}

      {title && (
        <div className="flex items-center justify-between py-2">
          <div className="flex items-center">
            <div className={cx('text-t2 text-black', titleClassName)}>{title}</div>
            {showSpacePlan && showDialog && (
              <div
                onClick={() => {
                  if (from) {
                    setIsFrom(from);
                  }
                  openAskUpgradeSpace(
                    () => {
                      setCurrentSettingMenu(SettingMenuType.upgrade);
                    },
                    { title: upgradeTip }
                  );
                }}
                className="bg-grey6 text-t4 text-grey3 animate-hover ml-2 rounded-sm px-1.5 py-0.5"
              >
                {getSpacePlanTypeName(currentSpace.planType)}
              </div>
            )}
          </div>

          {rightPart}
        </div>
      )}

      {description && <div className="text-t3 text-grey3">{description}</div>}
    </div>
  );
};
