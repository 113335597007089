import type { VirtualElement } from '@popperjs/core';

/** 查元素是有符合条件，往上查找一直到某tagName,默认是html */
export const checkTargetByHtml = (
  target: HTMLElement,
  checkTarget: (t: HTMLElement) => boolean,
  topTagName = 'HTML'
) => {
  if (checkTarget(target)) {
    return true;
  }
  let t = target as HTMLElement;
  while (t.tagName !== topTagName) {
    const check = checkTarget(t);
    if (check || !t.parentNode) return true;
    t = t.parentNode as HTMLElement;
  }
  return false;
};

export const isHTML = (element?: Element | VirtualElement) => {
  return Boolean((element as HTMLElement)?.tagName);
};
