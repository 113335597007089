import type { FC } from 'react';
import { PageScene, usePageScene } from 'src/views/main/scene-context';

export const BlockChildren: FC<JSX.IntrinsicElements['div'] & { blockId: string }> = (props) => {
  const scene = usePageScene();
  if (!props.children || scene === PageScene.PAGE_FEEDS) return null;

  return (
    <div className="group-scope relative pl-6 block-child-line-box" style={props.style}>
      {/*
       * group-hover 和 group-scope-hover 不都写上第一次 yarn dev 的时候会不起作用
       * 起作用后删除 group-hover 也正常了，不过重新 yarn dev 又不行了
       * 所以这里两个都先写上了
       */}
      <span className="block-child-line absolute left-3 w-px opacity-100 group-hover:opacity-0 group-scope-hover:opacity-0 bg-grey6 h-full transition-opacity duration-300 ease-linear dark:bg-white-base/10" />
      {props.children}
    </div>
  );
};
