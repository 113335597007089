import { cx } from '@flowus/common/cx';
import { MindMapContext } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import {
  listViewNormalClassName,
  listViewNormalClassNameNotModal,
} from 'src/common/components/list-view/helper';
import type { ModalSchema } from 'src/common/components/next-modal';
import { PRIORITY_DIALOG } from 'src/common/utils/global-listener-helper';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useOpenUpgradeSpaceIfMindNodeLimit } from 'src/hooks/block/use-open-upgrade-space-if-mind-node-limit';
import type { FocusEditableFun } from 'src/hooks/editor/use-focus-by-id';
import { useSelectedBlockHistory } from 'src/hooks/page/use-selected-block-history';
import { useRestorePermission } from 'src/hooks/share/use-permission-utils';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { convertBlock } from 'src/redux/managers/block/convert';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { getLimitConfig } from 'src/services/app/hook/subscription-data';
import { bizTracker } from 'src/utils/biz-tracker';
import { hasSyncBlock, isInColumnList } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { MindNodeTurnWhiteList, TurnBlockList } from './const';
import {
  covertBlockToTitle,
  createMenuDataContext,
  getBlockMenu,
  getBlockMenuList,
} from './menu-item';
import { MenuListActionType } from './types';

export const TransformBlockList: FC<{
  blockId: string;
  syncId?: string;
  selectItem?: () => void;
  focusEditableAt?: FocusEditableFun;
  onCloseModal?: ModalSchema.CloseModal['onCloseModal'];
  showBg?: boolean;
}> = ({ onCloseModal, selectItem, blockId, syncId, showBg = true }) => {
  const updateTask = useUpdateTask();
  const block = usePickBlock(blockId, ['data'], ['level', 'display']);
  const pageId = useGetPageId();
  const BlockMenu = getBlockMenuList();
  const transaction = useTransaction();
  const { selectedBlockHistory, selectedBlock } = useSelectedBlockHistory();
  const restorePermission = useRestorePermission(blockId);
  const openUpgradeSpaceIfNeed = useOpenUpgradeSpaceIfMindNodeLimit();
  const { isRestricted } = usePermissions(blockId);
  const isInMindMap = !!useContext(MindMapContext);

  const submit = async (type: BlockType, params: any = {}) => {
    transaction(() => {
      const sb = selectedBlock.length ? selectedBlock : [{ blockId, syncId }];
      isRestricted && restorePermission();
      const { mindMapMaxLimit } = getLimitConfig();
      if (
        isInMindMap &&
        openUpgradeSpaceIfNeed(
          blockId,
          `需要转换的节点数超过${mindMapMaxLimit},升级空间立即解锁无限节点`,
          mindMapMaxLimit + 1
        )
      ) {
        return;
      }
      const ids = selectedBlocksToIds(sb);
      convertBlock(ids, { type, ...params, local: undefined });
      if ([BlockType.TOGGLE_HEADER, BlockType.FOLD_LIST].includes(type)) {
        patchExpand(ids, true);
      }
      selectedBlockHistory(sb);
      // 积分任务
      if (block?.type === BlockType.TEXTAREA) {
        void updateTask(ActivityIds.CONVERT_TEXT_BLOCK, ActivitiesListType.basicList);
      }
      if (block?.type === BlockType.HEADER) {
        void updateTask(ActivityIds.CREATE_HEADER_BLOCK, ActivitiesListType.basicList, {
          step1: true,
        });
      }
      if (type === BlockType.TODO) {
        void updateTask(ActivityIds.CREATE_TODO_BLOCK, ActivitiesListType.basicList);
      }
      if (type === BlockType.QUOTE) {
        void updateTask(ActivityIds.CREATE_QUOTE_BLOCK, ActivitiesListType.basicList);
      }
      if (type === BlockType.MARK) {
        void updateTask(ActivityIds.CREATE_MARK_BLOCK, ActivitiesListType.basicList);
      }
      if (type === BlockType.SYNC_CONTAINER) {
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_SYNC, ActivitiesListType.advancedList);
        void updateTask(ActivityIds.CREATE_SYNC_BLOCK_NEW, ActivitiesListType.advancedList, {
          step1: true,
        });
      }
      // 积分任务
      if (type === BlockType.PAGE) {
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_CREATED_PAGE, ActivitiesListType.basicList);
      }
    });
    if (selectItem) {
      selectItem();
    }
    onCloseModal?.();
  };

  const items = useMemo(() => {
    if (!block) return [];
    const whiteList = isInMindMap ? MindNodeTurnWhiteList : TurnBlockList;
    const arr = Object.values(BlockMenu.basicBlock)
      .filter((o) => whiteList.includes(o.data.type))
      .map((item) => {
        const menu = getBlockMenu(block.type, {
          level: block.data.level,
          display: block.data.display,
        });
        return {
          ...item,
          type: ListItemType.BLOCK_TRANSFORM,
          data: {
            ...item.data,
            selected: menu?.title === item.data.title,
          },
        };
      });

    if (!isInMindMap && !syncId && !hasSyncBlock(blockId)) {
      const syncItem = BlockMenu.advanced.sync;
      const result = {
        ...syncItem,
        type: ListItemType.BLOCK_TRANSFORM,
      };
      // @ts-ignore type check
      arr.push(result);
    }
    if (
      !isInMindMap &&
      cache.ui.selectedBlocks.length > 1 &&
      cache.ui.selectedBlocks.length <= 5 &&
      cache.ui.selectedBlocks.some((sb) => {
        return !isInColumnList(sb.blockId, pageId);
      })
    ) {
      const columnItem = {
        type: ListItemType.BLOCK_TRANSFORM,
        data: {
          type: BlockType.COLUMN_LIST,
          actionType: MenuListActionType.CREATE,
          icon: 'MIcInsertColumns',
          title: '分栏',
          context: createMenuDataContext({ title: '将选中的块转为分栏排版', image: 'column' }),
          keywords: ['sync'],
        },
      };
      // @ts-ignore type check
      arr.push(columnItem);
    }

    return arr;
  }, [BlockMenu.advanced.sync, BlockMenu.basicBlock, block, blockId, isInMindMap, pageId, syncId]);

  if (!block) return null;

  return (
    <ListView
      className={cx(
        showBg ? listViewNormalClassName : listViewNormalClassNameNotModal,
        'w-[200px]'
      )}
      items={items}
      onItemClick={(res) => {
        const { type, params } = res.data;
        void submit(type, params);
        bizTracker.event('blockmenu_turn_choose', { block_name: covertBlockToTitle(blockId) });
      }}
      keyDownPriority={PRIORITY_DIALOG + 1}
    />
  );
};
// #endregion
