import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { getDomain } from 'src/common/utils/url-utils';
import { cache } from 'src/redux/store';
import { $spacesState } from 'src/services/spaces/spaces';
import { ViewPath } from 'src/utils';
import { isPublicHomePage, judgeSharePage, judgeSubscribePage } from 'src/utils/getPageId';
import { useIsInRight } from 'src/utils/right-utils';

export const useNormalizePath = (uuid: string, toScene?: ViewPath) => {
  const _useNormalizePath = useNormalizePathFun();
  return _useNormalizePath(uuid, toScene);
};
export const useNormalizePathFun = () => {
  const isInRight = useIsInRight();
  return useCallback(
    (uuid: string, toScene?: ViewPath) => {
      const isShare = judgeSharePage();
      const isSubscribe = judgeSubscribePage();
      const spaceId = cache.blocks[uuid]?.spaceId;
      const domain = $spacesState.getState()[spaceId ?? '']?.domain;
      let path = isShare
        ? isSubscribe && !isPublicHomePage()
          ? `${ViewPath.subscribe}${uuid}`
          : `${ViewPath.share}${uuid}`
        : `/${uuid}`;
      // 订阅页面地址没有domain
      let domainPath = !isSubscribe && domain && !getDomain() ? `/${domain}` : '';

      if (cache.blocks[uuid]?.type === BlockType.FILE && !isShare) {
        path = `/preview${path}`;
        domainPath = '';
      }
      if (!isInRight) {
        path = `${domainPath}${path}`;
      }
      if (toScene) {
        path = `${toScene}${uuid}`;
      }
      return path;
    },
    [isInRight]
  );
};
