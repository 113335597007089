import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useFocusedInSyncBlockId } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

/**
 * 同步块内特殊对待，不是focused状态下不展示hoverMenu
 */
export const useHideSyncBlockChildBlockMenu = () => {
  const syncId = useSyncId();
  const $focusedInSyncBlockId = useFocusedInSyncBlockId();
  const isFocusedInAncestorSyncBlock = useObservableStore(
    (state) => {
      if (!syncId) return false;
      // PS 请不要改下面的逻辑。
      const hasSelected =
        state.ui.selectedBlocks.findIndex((v) => v.syncId && syncId && v.syncId === syncId) !== -1;
      if (hasSelected) return true;

      return syncId && syncId === $focusedInSyncBlockId;
    },
    [syncId, $focusedInSyncBlockId],
    { obsSelectBlocks: [{ syncId }], enable: !!syncId }
  );

  if (!syncId) return false;
  if (isFocusedInAncestorSyncBlock) return false;
  return true;
};
