import { getFormatImageUrl } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCloseModal } from 'src/common/components/next-modal';
import * as Empty from 'src/components/empty';
import { segmentsToText } from 'src/editor/utils/editor';
import { useBlockStatus } from 'src/hooks/block/use-block-status';
import { useFetchPage } from 'src/hooks/page';
import { usePageMeta } from 'src/hooks/page/use-page-meta';
import { useResource } from 'src/hooks/public/use-resource';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { Modals } from 'src/modals';
import { $searchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getFileNameInfo } from 'src/utils/file';
import { isBitablePDFPreview } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import type { PageScene } from 'src/views/main/scene-context';
import { SharePasswordPage } from 'src/views/share/share-password';
import { DeleteBar } from '../delete-bar';
import { Footer } from '../footer';
import { PreviewHeader } from './header';
import { $filePreviewModel, FILE_PREVIEW_SCOPE } from './hook';
import { PreviewElement } from './renderElements';

export interface FilePreviewProps {
  downloadUrl: string;
  uuid?: string;
  // blockId?: string;
  propertyId?: string;
  embed?: boolean;
  style?: React.CSSProperties;
}

export const FilePreview: FC<{
  uuid: string;
  isPreviewPage?: boolean;
  pageScene?: PageScene;
  forceImage?: boolean; // 强制使用图片块的预览
  hideHeader?: boolean;
}> = ({ uuid, isPreviewPage, hideHeader, pageScene, forceImage }) => {
  const history = useHistory();
  const fetchPage = useFetchPage();
  const closeModal = useCloseModal();

  // 被风控了
  const { illegal } = usePermissions(uuid);
  const pageMeta = usePageMeta(uuid);
  const { role } = usePermissions(uuid);
  const isDeleted = useBlockStatus(uuid);
  const [loading, setLoading] = useState(true);
  const { url, imageUrl } = useResource(illegal || $searchParams.propertyId ? '' : uuid, {
    isImage: forceImage,
  });

  const block = usePickBlock(uuid, ['data'], ['segments', 'display', 'collectionProperties']);

  let fileName = segmentsToText(block?.data.segments);
  if ($searchParams.propertyId && block) {
    const items = block.data.collectionProperties?.[$searchParams.propertyId] ?? [];
    fileName = items.find((item) => item.url === $searchParams.ossName)?.text ?? '';
  }

  const { extName } = getFileNameInfo(fileName);

  const closeFilePreview = () => closeModal(Modals.FILE_PREVIEW);

  useEffect(() => {
    if (isPreviewPage) {
      void fetchPage(uuid).then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [fetchPage, isPreviewPage, uuid]);

  useEffect(() => {
    if (fileName) {
      bizTracker.event('file_preview', {
        file_format: extName,
      });
    }
  }, [extName, fileName]);

  const [downloadUrl, setDownloadUrl] = useState<string>();
  useEffect(() => {
    if (!isBitablePDFPreview()) return;
    if (!$searchParams.propertyId) return;

    const data = block?.data.collectionProperties?.[$searchParams.propertyId] ?? [];
    const pdfData = data.find((item) => item.url === $searchParams.ossName);

    if (!pdfData) return;

    void urlFetcher
      .fetchDownloadUrl({
        blockId: uuid,
        ossName: pdfData.url ?? '',
        fileName: pdfData.text,
      })
      .then((res) => {
        const formatUrl = getFormatImageUrl(res, extName);
        setDownloadUrl(formatUrl);
      });
  }, [block?.data.collectionProperties, extName, uuid]);

  if (illegal) {
    return <Empty.NoPermission uuid={uuid} />;
  }

  if (pageMeta?.tag === 'NEED_PASSWORD') {
    return <SharePasswordPage uuid={uuid} />;
  }

  if (loading) return null;

  if (!block) {
    if (isPreviewPage) {
      if (pageMeta?.tag === 'NOT_FOUND') {
        return (
          <>
            <Empty.NotFound showBackButton={true} />
          </>
        );
      }

      if (pageMeta?.tag === 'NO_PERMISSION' || role === PermissionRole.NONE) {
        return (
          <>
            <Empty.NotShared />
            <Footer />
          </>
        );
      }
    }
    return null;
  }

  if (
    block.type !== BlockType.FILE &&
    block.type !== BlockType.EXTERNAL_FILE &&
    block.type !== BlockType.PDF_ANNOTATION &&
    !isBitablePDFPreview()
  ) {
    history.push(`/${uuid}`);
    return null;
  }

  const isPDF = extName.toLowerCase() === 'pdf';
  const resourceUrl = isBitablePDFPreview() ? downloadUrl : imageUrl;

  if (!resourceUrl) return null;

  return (
    <JotaiProvider
      scope={FILE_PREVIEW_SCOPE}
      initialValues={[[$filePreviewModel, { uuid, editor: false }]]}
    >
      <div
        className={cx('fixed top-0 bottom-0 left-0 right-0', {
          'bg-black-base/80': !hideHeader,
          'flex-1 overflow-hidden relative': isPreviewPage,
        })}
      >
        {!isPDF && !hideHeader && (
          <PreviewHeader
            onClose={closeFilePreview}
            isPreviewPage={isPreviewPage}
            uuid={uuid}
            downloadUrl={url}
            pageScene={pageScene}
          />
        )}

        {isDeleted && (
          <div className={cx('absolute z-50 w-full top-[55px] h-14', isPreviewPage && 'top-0')}>
            <DeleteBar uuid={uuid} onDelete={closeFilePreview} />
          </div>
        )}

        <div
          className="file-preview absolute bottom-0 flex items-center justify-center w-full overflow-y-hidden text-white"
          style={{ top: isPDF ? (isDeleted ? 55 : 0) : isDeleted ? 111 : 55 }}
          onClick={(event) => event.target === event.currentTarget && closeFilePreview()}
        >
          <PreviewElement
            uuid={uuid}
            downloadUrl={resourceUrl}
            forceImage={forceImage || block.data.display === 'image'}
          />
        </div>
      </div>
    </JotaiProvider>
  );
};
