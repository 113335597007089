import type { Context } from '../../core/context';
import type { IPropertyIdentValueDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum LIST_STYLE_TYPE {
  NONE = -1,
  DISC = 0,
  CIRCLE = 1,
  SQUARE = 2,
  DECIMAL = 3,
  CJK_DECIMAL = 4,
  DECIMAL_LEADING_ZERO = 5,
  LOWER_ROMAN = 6,
  UPPER_ROMAN = 7,
  LOWER_GREEK = 8,
  LOWER_ALPHA = 9,
  UPPER_ALPHA = 10,
  ARABIC_INDIC = 11,
  ARMENIAN = 12,
  BENGALI = 13,
  CAMBODIAN = 14,
  CJK_EARTHLY_BRANCH = 15,
  CJK_HEAVENLY_STEM = 16,
  CJK_IDEOGRAPHIC = 17,
  DEVANAGARI = 18,
  ETHIOPIC_NUMERIC = 19,
  GEORGIAN = 20,
  GUJARATI = 21,
  GURMUKHI = 22,
  HEBREW = 22,
  HIRAGANA = 23,
  HIRAGANA_IROHA = 24,
  JAPANESE_FORMAL = 25,
  JAPANESE_INFORMAL = 26,
  KANNADA = 27,
  KATAKANA = 28,
  KATAKANA_IROHA = 29,
  KHMER = 30,
  KOREAN_HANGUL_FORMAL = 31,
  KOREAN_HANJA_FORMAL = 32,
  KOREAN_HANJA_INFORMAL = 33,
  LAO = 34,
  LOWER_ARMENIAN = 35,
  MALAYALAM = 36,
  MONGOLIAN = 37,
  MYANMAR = 38,
  ORIYA = 39,
  PERSIAN = 40,
  SIMP_CHINESE_FORMAL = 41,
  SIMP_CHINESE_INFORMAL = 42,
  TAMIL = 43,
  TELUGU = 44,
  THAI = 45,
  TIBETAN = 46,
  TRAD_CHINESE_FORMAL = 47,
  TRAD_CHINESE_INFORMAL = 48,
  UPPER_ARMENIAN = 49,
  DISCLOSURE_OPEN = 50,
  DISCLOSURE_CLOSED = 51,
}

export const listStyleType: IPropertyIdentValueDescriptor<LIST_STYLE_TYPE> = {
  name: 'list-style-type',
  initialValue: 'none',
  prefix: false,
  type: PropertyDescriptorParsingType.IDENT_VALUE,
  parse: (_context: Context, type: string) => {
    switch (type) {
      case 'disc':
        return LIST_STYLE_TYPE.DISC;
      case 'circle':
        return LIST_STYLE_TYPE.CIRCLE;
      case 'square':
        return LIST_STYLE_TYPE.SQUARE;
      case 'decimal':
        return LIST_STYLE_TYPE.DECIMAL;
      case 'cjk-decimal':
        return LIST_STYLE_TYPE.CJK_DECIMAL;
      case 'decimal-leading-zero':
        return LIST_STYLE_TYPE.DECIMAL_LEADING_ZERO;
      case 'lower-roman':
        return LIST_STYLE_TYPE.LOWER_ROMAN;
      case 'upper-roman':
        return LIST_STYLE_TYPE.UPPER_ROMAN;
      case 'lower-greek':
        return LIST_STYLE_TYPE.LOWER_GREEK;
      case 'lower-alpha':
        return LIST_STYLE_TYPE.LOWER_ALPHA;
      case 'upper-alpha':
        return LIST_STYLE_TYPE.UPPER_ALPHA;
      case 'arabic-indic':
        return LIST_STYLE_TYPE.ARABIC_INDIC;
      case 'armenian':
        return LIST_STYLE_TYPE.ARMENIAN;
      case 'bengali':
        return LIST_STYLE_TYPE.BENGALI;
      case 'cambodian':
        return LIST_STYLE_TYPE.CAMBODIAN;
      case 'cjk-earthly-branch':
        return LIST_STYLE_TYPE.CJK_EARTHLY_BRANCH;
      case 'cjk-heavenly-stem':
        return LIST_STYLE_TYPE.CJK_HEAVENLY_STEM;
      case 'cjk-ideographic':
        return LIST_STYLE_TYPE.CJK_IDEOGRAPHIC;
      case 'devanagari':
        return LIST_STYLE_TYPE.DEVANAGARI;
      case 'ethiopic-numeric':
        return LIST_STYLE_TYPE.ETHIOPIC_NUMERIC;
      case 'georgian':
        return LIST_STYLE_TYPE.GEORGIAN;
      case 'gujarati':
        return LIST_STYLE_TYPE.GUJARATI;
      case 'gurmukhi':
        return LIST_STYLE_TYPE.GURMUKHI;
      case 'hebrew':
        return LIST_STYLE_TYPE.HEBREW;
      case 'hiragana':
        return LIST_STYLE_TYPE.HIRAGANA;
      case 'hiragana-iroha':
        return LIST_STYLE_TYPE.HIRAGANA_IROHA;
      case 'japanese-formal':
        return LIST_STYLE_TYPE.JAPANESE_FORMAL;
      case 'japanese-informal':
        return LIST_STYLE_TYPE.JAPANESE_INFORMAL;
      case 'kannada':
        return LIST_STYLE_TYPE.KANNADA;
      case 'katakana':
        return LIST_STYLE_TYPE.KATAKANA;
      case 'katakana-iroha':
        return LIST_STYLE_TYPE.KATAKANA_IROHA;
      case 'khmer':
        return LIST_STYLE_TYPE.KHMER;
      case 'korean-hangul-formal':
        return LIST_STYLE_TYPE.KOREAN_HANGUL_FORMAL;
      case 'korean-hanja-formal':
        return LIST_STYLE_TYPE.KOREAN_HANJA_FORMAL;
      case 'korean-hanja-informal':
        return LIST_STYLE_TYPE.KOREAN_HANJA_INFORMAL;
      case 'lao':
        return LIST_STYLE_TYPE.LAO;
      case 'lower-armenian':
        return LIST_STYLE_TYPE.LOWER_ARMENIAN;
      case 'malayalam':
        return LIST_STYLE_TYPE.MALAYALAM;
      case 'mongolian':
        return LIST_STYLE_TYPE.MONGOLIAN;
      case 'myanmar':
        return LIST_STYLE_TYPE.MYANMAR;
      case 'oriya':
        return LIST_STYLE_TYPE.ORIYA;
      case 'persian':
        return LIST_STYLE_TYPE.PERSIAN;
      case 'simp-chinese-formal':
        return LIST_STYLE_TYPE.SIMP_CHINESE_FORMAL;
      case 'simp-chinese-informal':
        return LIST_STYLE_TYPE.SIMP_CHINESE_INFORMAL;
      case 'tamil':
        return LIST_STYLE_TYPE.TAMIL;
      case 'telugu':
        return LIST_STYLE_TYPE.TELUGU;
      case 'thai':
        return LIST_STYLE_TYPE.THAI;
      case 'tibetan':
        return LIST_STYLE_TYPE.TIBETAN;
      case 'trad-chinese-formal':
        return LIST_STYLE_TYPE.TRAD_CHINESE_FORMAL;
      case 'trad-chinese-informal':
        return LIST_STYLE_TYPE.TRAD_CHINESE_INFORMAL;
      case 'upper-armenian':
        return LIST_STYLE_TYPE.UPPER_ARMENIAN;
      case 'disclosure-open':
        return LIST_STYLE_TYPE.DISCLOSURE_OPEN;
      case 'disclosure-closed':
        return LIST_STYLE_TYPE.DISCLOSURE_CLOSED;
      case 'none':
      default:
        return LIST_STYLE_TYPE.NONE;
    }
  },
};
