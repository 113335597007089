import type { Rect } from '../type';

export const convertWindowToPage = (windowRect: DOMRect, pageRect: DOMRect): Rect => {
  return {
    x: windowRect.left - pageRect.left,
    y: windowRect.top - pageRect.top,
    width: windowRect.width,
    height: windowRect.height,
  };
};
