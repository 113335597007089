import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useState } from 'react';
import { useBitable } from '../context';
import { FormLogicSetting } from '../form-view/form-logic-setting';
import { ConfigTableFilter } from './config-table-filter';
import { ConfigTableSort } from './config-table-sort';
import { GroupList } from './group-list';
import { PropertyList } from './property-list';
import type { SettingProps } from './type';
import { ViewSetting } from './view-setting';
import { SubItemMenu } from './subitem-menu';

export enum ItemType {
  VIEW = 'view',
  PROPERTY = 'property',
  FILTER = 'filter',
  SORT = 'sort',
  GROUP = 'group',
  SUB_GROUP = 'sub_group',
  FORM_LOGIC = 'form_logic',
  SUBITEM = 'subitem',
}

const Items = [
  { name: '视图', type: ItemType.VIEW, Component: ViewSetting },
  { name: '属性', type: ItemType.PROPERTY, Component: PropertyList },
  { name: '筛选', type: ItemType.FILTER, Component: ConfigTableFilter },
  { name: '排序', type: ItemType.SORT, Component: ConfigTableSort },
  { name: '分组', type: ItemType.GROUP, Component: GroupList },
  { name: '子记录', type: ItemType.SUBITEM, Component: SubItemMenu },
];

export const ViewSettingPanel: FC<SettingProps & { defaultItemType?: ItemType }> = (props) => {
  const { viewType } = useBitable();
  const [currentType, setCurrentType] = useState<ItemType>(props.defaultItemType ?? ItemType.VIEW);

  let items = [...Items];
  if (viewType === CollectionViewType.FORM) {
    items = Items.filter((item) => item.type === ItemType.VIEW || item.type === ItemType.PROPERTY);
    items.push({ name: '跳题逻辑', type: ItemType.FORM_LOGIC, Component: FormLogicSetting });
  } else if (viewType === CollectionViewType.BOARD) {
    items.push({ name: '子分组', type: ItemType.SUB_GROUP, Component: GroupList });
  } else if (viewType === CollectionViewType.CALENDAR) {
    items = Items.filter((item) => item.type !== ItemType.GROUP);
  }

  const Component = items.find((item) => item.type === currentType)?.Component;
  if (!Component) return null;

  return (
    <div className={'next-modal-scroll pt-[5px] min-w-[400px]'}>
      <div className="border-b border-grey6 py-1.5 px-2 flex">
        {items.map((item) => {
          return (
            <button
              key={item.name}
              onClick={() => setCurrentType(item.type)}
              className={cx(
                'mr-2 rounded-sm py-1.5 px-2 animate-hover-black3 text-t2',
                item.type === currentType && 'bg-black_006'
              )}
            >
              {item.name}
            </button>
          );
        })}
      </div>

      <Component
        {...props}
        isSubGroup={currentType === ItemType.SUB_GROUP}
        isFromViewSetting={true}
      />
    </div>
  );
};
