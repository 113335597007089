import { ossImageSnapshotUrl } from '@flowus/common/url';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { getFileNameInfo } from 'src/utils/file';
import { compressImageSupport } from '../../utils/url-utils';
import { message } from '../message';
import { GalleryItem } from './gallery-item';

export interface ImageInfo {
  uuid?: string;
  url?: string;
  key?: string;
  ossName?: string;
  fileName: string;
  extName?: string;
  illegal?: boolean;
}

export interface GalleryProps {
  imageList: ImageInfo[];
  onSelect: (index: number) => void;
  hasMore?: boolean;
  customFooter?: ReactNode;
  loadMore?: (pageNo: number) => void;
  style?: CSSProperties;
}
export const Gallery: FC<GalleryProps> = (props) => {
  const { imageList, hasMore, onSelect, customFooter, loadMore = () => {}, style } = props;
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={scrollRef} className="px-3 py-1 overflow-y-auto" style={style}>
      <InfiniteScroll
        pageStart={1}
        initialLoad={true}
        useWindow={false}
        loadMore={loadMore}
        getScrollParent={() => scrollRef.current}
        hasMore={hasMore}
        className="grid grid-cols-4 gap-2"
      >
        {imageList.map((item, index) => {
          let { url } = item;

          if (url && url.includes('?token=')) {
            const [fileName] = url.split('?token=');
            const { extName } = getFileNameInfo(fileName);
            if (compressImageSupport.test(extName)) {
              url = ossImageSnapshotUrl(url);
            }
          }

          return (
            <GalleryItem
              key={`${item.url}${index}`}
              description={item.fileName}
              url={url}
              illegal={item.illegal}
              onClick={() => {
                if (item.illegal) {
                  message.error('图片无法使用');
                } else {
                  onSelect(index);
                }
              }}
            />
          );
        })}
      </InfiniteScroll>
      {customFooter}
    </div>
  );
};
