import { TextArea } from './text-area';
import type { ValueEditorProps } from './text-editor';

export const PlainTextValueEditor: ValueEditorProps<string> = ({ value, onChange }) => {
  return (
    <TextArea
      autoFocus
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};
