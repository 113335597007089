import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import type { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import type { SelectBlock } from 'src/redux/types';

export const getDefaultPlaceHolder = (level: number) => {
  switch (level) {
    case 0:
      return '中心节点';
    default:
      return '输入文字';
  }
};

export const getDefaultNodeClassName = (
  level: number,
  isSelected: boolean,
  isEdit = false,
  canComment = false
) => {
  let className = '';
  switch (level) {
    case 0: {
      className = ` ${
        isEdit ? 'text-t0' : 'text-t0'
      } rounded-lg focus-within:text-t0 text-black bg-black text-white1 border-2 border-solid py-4 border-black10`;
      break;
    }

    case 1: {
      className = ` ${
        isEdit ? 'text-t0-1' : 'text-t0-1'
      } rounded-md focus-within:text-t0-1 text-black bg-grey5 py-2 border-2 border-solid border-transparent `;
      break;
    }
    default:
      className = ` ${
        isEdit ? 'text-t1' : 'text-t1'
      } rounded-md focus-within:text-t1 text-black bg-grey8 py-0.5 border-2 border-solid border-transparent`;
  }
  if (isSelected) {
    className += ' border-active_color';
  } else if (canComment) {
    className += ' hover:border-active_color/30';
  }
  className += ' cursor-default focus-within:cursor-text';
  return className;
};

export const isMindNodeFocused = () => {
  if (document.activeElement instanceof HTMLElement) {
    return document.activeElement.isContentEditable === true;
  }
  return false;
};

/** fix cycle */
export const findEditorModel = (
  selectBlock: SelectBlock | undefined,
  getEditorModel: ReturnType<typeof useGetOrInitEditorModel>
) => {
  if (!selectBlock) return;
  const { editorKey } = selectBlock;
  if (editorKey) {
    return getEditorModelByEditorKey(editorKey);
  }
  return getEditorModel(selectBlock.blockId);
};
