import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { numberToPercent } from 'src/utils/number';

export const Bar: FC<{
  width?: number;
  height?: number;
  percent?: number;
  bgColor?: string;
  progressColor?: string;
  className?: string;
}> = ({ width, height = 3, percent = 0.25, bgColor, progressColor, className }) => {
  return (
    <div
      className={cx('rounded-full overflow-hidden', className)}
      style={{ backgroundColor: bgColor, height, width }}
    >
      <div
        className="h-full rounded-full"
        style={{ backgroundColor: progressColor, width: numberToPercent(percent) }}
      />
    </div>
  );
};
