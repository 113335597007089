import type { FC } from 'react';
import { getUrlWithoutDomain } from 'src/common/utils/url-utils';
import { PRODUCT_NAME } from 'src/const/title';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useCurrentUser } from 'src/hooks/user';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { locationToUrl } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';

export const BackSpaceButton: FC = () => {
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  // const openButtonTips = useOpenButtonTips();
  // const { role } = usePermissions(pageId);
  // const openModal = useOpenModal();
  const isSharePage = judgeSharePage();
  const currentUser = useCurrentUser();
  // const space = useSpace(block?.spaceId);
  // const isShowAd = useIsShowAd(block?.spaceId);
  const isProSpace = useIsProSpace(block?.spaceId);
  // const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const show = Boolean(
  //     !currentUser.uuid &&
  //       isShowAd &&
  //       isSharePage &&
  //       space.uuid &&
  //       !Role.contains(role, PermissionRole.COMMENTER)
  //   );
  //   if (show && ref.current) {
  //     if (isProSpace) return;

  //     openButtonTips(ref, {
  //       content: '创建自己的空间，分享好看的页面 快来 FlowUs，提升学习、办公效能',
  //     });
  //   }
  // }, [
  //   currentUser.uuid,
  //   isProSpace,
  //   isSharePage,
  //   isShowAd,
  //   openButtonTips,
  //   openModal,
  //   role,
  //   space.uuid,
  // ]);

  if (!isSharePage) return <></>;

  return (
    <>
      <span
        hidden={!currentUser.uuid && isProSpace}
        className="w-px mx-2 h-3.5 bg-grey6 flex-shrink-0"
      />
      <div
        hidden={!currentUser.uuid && isProSpace}
        className="cursor-pointer text-t2-medium flex-shrink-0 bg-white dark:bg-transparent text-black"
        onClick={() => locationToUrl(getUrlWithoutDomain())}
      >
        <div>{currentUser.uuid ? '返回空间' : `前往 ${PRODUCT_NAME}`}</div>
      </div>
    </>
  );
};
