import { getOrderFolderType } from '@flowus/common/utils/get-folder-sort-type';
import { BlockType, OrderFolderType } from '@next-space/fe-api-idl';
import { createContext, useContext } from 'use-context-selector';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';

export const useChangeOrderFolder = (uuid: string) => {
  const block = usePickBlock(uuid, ['data'], ['format']);
  const readonly = useReadonly(uuid);
  const transition = useTransaction();
  const { sortType } = getOrderFolderType(block?.data?.format?.orderFolder);

  return (key: 'name' | 'time' | 'size') => {
    if (readonly) return;
    let newSort = sortType;
    switch (key) {
      case 'size':
        bizTracker.event('sort_file_size');
        if (sortType === OrderFolderType.SIZE) {
          newSort = OrderFolderType.SIZE_REVERSED;
        } else if (newSort === OrderFolderType.SIZE_REVERSED) {
          newSort = OrderFolderType.SIZE;
        } else {
          newSort = OrderFolderType.SIZE_REVERSED;
        }
        break;
      case 'time':
        bizTracker.event('sort_update_time');
        if (sortType === OrderFolderType.TIME) {
          newSort = OrderFolderType.TIME_REVERSED;
        } else if (newSort === OrderFolderType.TIME_REVERSED) {
          newSort = OrderFolderType.TIME;
        } else {
          newSort = OrderFolderType.TIME_REVERSED;
        }
        break;
      default:
      case 'name':
        bizTracker.event('sort_file_name');
        if (sortType === OrderFolderType.NAME) {
          newSort = OrderFolderType.NAME_REVERSED;
        } else if (newSort === OrderFolderType.NAME_REVERSED) {
          newSort = OrderFolderType.NAME;
        } else {
          newSort = OrderFolderType.NAME_REVERSED;
        }
        break;
    }

    transition(() => {
      updateBlock(uuid, { data: { format: { orderFolder: newSort } } });
    });
  };
};

export const getSelectBlockHasNoFile = () => {
  return !cache.ui.selectedBlocks.every((i) => {
    const type = cache.blocks[i.blockId]?.type;
    return type && [BlockType.FILE, BlockType.FOLDER].includes(type);
  });
};

export const DriveContext = createContext({ isDriveIcon: false });
export const useDriveContext = () => useContext(DriveContext);
