import { cx } from '@flowus/common/cx';
import type { ActivationCodeDTO } from '@next-space/fe-api-idl';
import { ActivationCodeChannelType, ActivationCodeStatusType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import produce from 'immer';
import { assign } from 'lodash-es';
import type { FC, FormEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_FORMAT } from 'src/common/const';
import { createModel, useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { UpgradePaddingUiKit } from 'src/components/select-upgrade-plan/common';
import { UpgradeComponents } from 'src/components/select-upgrade-plan/upgrade-components';
import { updateExChangeCodeRedBadge } from 'src/hooks/activities/use-has-new-activity';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { Modals } from 'src/modals';
import { refreshSpaceData } from 'src/services/app/hook/refresh-space';
import { writeTextInClipboard } from 'src/utils/clipboard';

// #region hooks
const useWelfareCodeSpaceList = () => {
  const [welfareCodeData, setWelfareCodeData] = useState<ActivationCodeDTO[]>([]);

  const refreshWelfare = async () => {
    const spaceId = getCurrentSpaceId();
    const res = await request.infra.getWelfareSpaceCardList(spaceId);
    setWelfareCodeData(res);
  };

  useEffect(() => {
    void refreshWelfare();
  }, []);

  const unusedWelfareCodeData = welfareCodeData.filter(
    (item) => item.status === ActivationCodeStatusType.UNUSED
  );

  const groupWelfareCodeData = useMemo(() => {
    const result: {
      time: number;
      channel: ActivationCodeChannelType;
      data: ActivationCodeDTO[];
    }[] = [];

    // 将对象数组按照时间和 channel 属性分组，并将每个分组中的对象数组转换为 { time, data } 的格式
    welfareCodeData
      .filter((item) => item.status !== ActivationCodeStatusType.UNUSED)
      .forEach((item) => {
        // 将时间字符串转换为时间戳
        const time = dayjs(item.redeemedAt).startOf('days').valueOf();
        // 查找结果数组中是否存在当前时间和 channel 的分组
        const group = result.find((g) => g.time === time && g.channel === item.channel);
        if (group) {
          // 如果存在，则将当前对象的数据部分添加到分组的 data 数组中
          group.data.push(item);
        } else {
          // 如果不存在，则创建一个新的分组，并将其添加到结果数组中
          result.push({ time, channel: item.channel, data: [item] });
        }
      });

    // 将结果数组按照时间倒序排列
    result.sort((a, b) => b.time - a.time);

    return result;
  }, [welfareCodeData]);

  return { welfareCodeData, unusedWelfareCodeData, groupWelfareCodeData, refreshWelfare };
};
// #endregion

// #region WelfareCodeCard
interface WelfareCodeCardProps {
  className?: string;
  item: ActivationCodeDTO;
}

const statusName = {
  [ActivationCodeStatusType.MISMATCH]: '空间不匹配',
  [ActivationCodeStatusType.UNUSED]: '未使用',
  [ActivationCodeStatusType.USED]: '已兑换',
  [ActivationCodeStatusType.EXPIRED]: '已过期',
  [ActivationCodeStatusType.PRESENT]: '已送出',
  [ActivationCodeStatusType.USED_COUNT_LIMIT]: '超出可兑换人数',
  [ActivationCodeStatusType.SINGLE_USER_USED_COUNT_LIMIT]: '超出领取次数',
};

const channelName = {
  [ActivationCodeChannelType.SPACE]: '空间福利',
  [ActivationCodeChannelType.SYSTEM]: '系统奖励',
};

const WelfareCodeCard: FC<WelfareCodeCardProps> = (props) => {
  const { className, item } = props;

  const disabled = item.status !== ActivationCodeStatusType.UNUSED;

  const onCopy = () => {
    void writeTextInClipboard(item.code);
  };

  return (
    <div
      className={cx(
        'text-t4 p-4 border border-grey6 text-grey3 rounded',
        className,
        disabled && 'cursor-not-allowed text-grey4'
      )}
      style={{
        background:
          'linear-gradient(360deg, rgba(24, 160, 251, 0.1) 0%, rgba(24, 160, 251, 0) 100%)',
      }}
    >
      <div className="text-t2-medium">{item.name}</div>
      <div className="my-1 flex items-center">
        {item.code}
        <Icon name="IcCopy" className="ml-1 animate-click" onClick={onCopy} size="middle" />
      </div>
      <div className="flex justify-between">
        <span>失效日期：{dayjs(item.expirationAt).format(DATE_FORMAT)}</span>
        <span>{statusName[item.status]}</span>
      </div>
    </div>
  );
};
// #endregion

// #region Welfare
const useWelfareCode = () => {
  const openModal = useOpenModal();
  const [form, setForm] = useState({
    input: '',
  });
  const { unusedWelfareCodeData, refreshWelfare } = useWelfareCodeSpaceList();

  useEffect(() => {
    void updateExChangeCodeRedBadge();
  }, []);

  const patchState = (value: Partial<typeof form>) => {
    setForm(produce((state) => assign(state, value)));
  };

  const close = () => {
    openModal.closeModal(Modals.EXCHANGE_CODE_ID);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const loadingKey = 'putWelfareCode';
    if (!form.input || message.exists(loadingKey)) return;
    message.loading({ key: loadingKey, content: '激活中...' });
    const res = await request.infra.putWelfareCode.raw(form.input, {
      spaceId: getCurrentSpaceId(),
    });
    if (res.code === 200 && res.data) {
      await refreshSpaceData();
      await refreshWelfare();
      message.success('激活成功');
    } else {
      // @ts-ignore msg is string
      message.error(res.msg ?? '激活失败');
    }
    message.closeMessage(loadingKey);
  };

  return { close, onSubmit, form, patchState, unusedWelfareCodeData };
};

const WelfareCodeInput = () => {
  const { onSubmit, form, patchState } = useModel(WelfareCodeModel);

  return (
    <UpgradePaddingUiKit className="py-2.5">
      <form className="contents" onSubmit={onSubmit}>
        <Input
          autoFocus
          className="text-t2 h-8 w-full rounded-r-none"
          placeholder="请输入激活码"
          value={form.input}
          onChange={(value) => patchState({ input: value })}
        />
        <Button className="h-8 rounded-l-none" colorType="active">
          立即激活
        </Button>
      </form>
    </UpgradePaddingUiKit>
  );
};

const WelfareCodeDialog: FC = () => {
  const { close, unusedWelfareCodeData } = useModel(WelfareCodeModel);
  const openExchangeCodeHistory = useOpenWelfareHistory();

  return (
    <div className="w-125 max-h-dialog">
      <UpgradeComponents.Header close={close} />
      <UpgradeComponents.BODY className="px-8 pt-2.5">
        <UpgradePaddingUiKit height={54} className="text-h2">
          兑换空间激活码
        </UpgradePaddingUiKit>
        <UpgradePaddingUiKit height={36} className="text-grey3 text-t3">
          相同空间当月可使用 1 张空间激活码
        </UpgradePaddingUiKit>
        <WelfareCodeInput />
        <UpgradePaddingUiKit height={54} className="text-h2 justify-between">
          <span>我的激活码</span>
          <span
            className="text-grey3 text-t2 animate-click underline underline-offset-4"
            onClick={openExchangeCodeHistory}
          >
            不可用的激活码
          </span>
        </UpgradePaddingUiKit>
        <div className="space-y-3">
          {!unusedWelfareCodeData.length && (
            <div className="text-center text-grey3 text-t2">暂无可用激活码</div>
          )}
          {unusedWelfareCodeData.map((item) => (
            <WelfareCodeCard key={item.code} item={item} />
          ))}
        </div>
      </UpgradeComponents.BODY>
    </div>
  );
};

const WelfareCodeModel = createModel(useWelfareCode);
export const useOpenWelfareCode = () => {
  const openModal = useOpenModal();

  return useCallback(() => {
    openModal.modal({
      modalId: Modals.EXCHANGE_CODE_ID,
      content: () => (
        <WelfareCodeModel>
          <WelfareCodeDialog />
        </WelfareCodeModel>
      ),
    });
  }, [openModal]);
};
// #endregion

// #region WelfareCodeHistory
const WelfareHistory: FC = () => {
  const { groupWelfareCodeData } = useWelfareCodeSpaceList();

  const renderList = (group: typeof groupWelfareCodeData[0]) => {
    return (
      <>
        <UpgradePaddingUiKit
          height={40}
          className="sticky top-0 bg-white2 flex justify-between text-t2"
        >
          <span>{dayjs(group.time).format(DATE_FORMAT)}</span>
          <span className="text-grey3">{channelName[group.channel]}</span>
        </UpgradePaddingUiKit>
        <div className="space-y-3">
          {group.data.map((item) => (
            <WelfareCodeCard key={item.code} item={item} />
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="w-[500px]">
      <UpgradePaddingUiKit height={50} className="text-h4 border-b px-8">
        卡券记录
      </UpgradePaddingUiKit>
      <UpgradeComponents.BODY className="px-8">
        {groupWelfareCodeData.map((group) => renderList(group))}
        {!groupWelfareCodeData.length && (
          <div className="text-t2 text-center text-grey3 mt-4">暂无卡券记录</div>
        )}
      </UpgradeComponents.BODY>
    </div>
  );
};

export const useOpenWelfareHistory = () => {
  const openModal = useOpenModal();
  return useCallback(() => {
    openModal.modal({
      content: () => <WelfareHistory />,
    });
  }, [openModal]);
};

// #endregion
