import { FileRegex } from '@flowus/common/regex';
import { BlockType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getFileNameInfo } from 'src/utils/file';

const ValidBlockType = [
  BlockType.TEXTAREA,
  BlockType.HEADER,
  BlockType.TODO,
  BlockType.LIST,
  BlockType.ORDER_LIST,
  BlockType.FOLD_LIST,
  BlockType.TOGGLE_HEADER,
  BlockType.MARK,
  BlockType.QUOTE,
  BlockType.IMAGE,
  BlockType.FILE,
  BlockType.PAGE,
  BlockType.REFERENCE,
  BlockType.COLUMN_LIST,
  BlockType.COLUMN,
  BlockType.SYNC_CONTAINER,
  BlockType.SYNC_REFERENCE,
  BlockType.EXTERNAL_FILE,
];
const imageIdMap = new Map<string, string>();
// 临时设置一个imageId,要不然需要修改的逻辑太多了
export const setTempImageBlockId = (pageId: string, imageBlockId: string) => {
  imageIdMap.set(pageId, imageBlockId);
};
export const getTempImageBlockId = (pageId: string) => {
  return imageIdMap.get(pageId);
};
export const getBlockIds = (
  subNodes: string[],
  result: { ids: string[]; imageBlockId?: string },
  blocks = getState().blocks
) => {
  const validType = new Set(ValidBlockType);
  for (const uuid of subNodes) {
    if (result.imageBlockId) return;

    const block = blocks[uuid];
    if (!block) continue;
    const blockType = block.type;
    if (!validType.has(blockType)) continue;

    switch (blockType) {
      case BlockType.TODO:
      case BlockType.MARK:
      case BlockType.HEADER:
      case BlockType.TEXTAREA:
      case BlockType.LIST:
      case BlockType.ORDER_LIST:
      case BlockType.FOLD_LIST:
      case BlockType.TOGGLE_HEADER:
      case BlockType.QUOTE: {
        if (segmentsToText(block.data.segments).trim()) {
          result.ids.push(uuid);
          if (blockType !== BlockType.FOLD_LIST && blockType !== BlockType.TOGGLE_HEADER) {
            getBlockIds(block.subNodes ?? [], result, blocks);
          }
        }

        break;
      }

      case BlockType.COLUMN_LIST:
      case BlockType.SYNC_CONTAINER:
      case BlockType.COLUMN: {
        getBlockIds(block.subNodes ?? [], result, blocks);
        break;
      }

      case BlockType.SYNC_REFERENCE: {
        const refBlock = blocks[block.data.ref?.uuid ?? ''];
        if (refBlock) {
          getBlockIds(refBlock.subNodes ?? [], result, blocks);
        }
        break;
      }

      case BlockType.EXTERNAL_FILE: {
        if (block.data.link) {
          result.imageBlockId = uuid;
        }
        break;
      }

      case BlockType.FILE: {
        const { extName } = getFileNameInfo(segmentsToText(block.data.segments));
        if ((block.data.ossName || block.data.localUrl) && FileRegex.image.test(extName)) {
          result.imageBlockId = uuid;
        }
        break;
      }

      case BlockType.PAGE:
      case BlockType.REFERENCE_COLLECTION_PAGE: {
        result.ids.push(uuid);
        break;
      }
      case BlockType.REFERENCE: {
        if (block.data.display === 'linkPage') {
          result.ids.push(uuid);
        }
        break;
      }

      default:
    }
  }
  result.ids = result.ids.slice(0, 6);
};

export const usePageContent = (recordId: string) => {
  const result = useObservableStore(
    ({ blocks }) => {
      const block = blocks[recordId];
      const subNodes = block?.subNodes ?? [];

      const result: { ids: string[]; imageBlockId?: string } = { ids: [], imageBlockId: undefined };
      getBlockIds(subNodes, result, blocks);

      return result;
    },
    [recordId]
  );

  return result;
};
