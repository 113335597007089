import PropTypes from 'prop-types';
import React from 'react';
import { getData } from '../utils';
import frequently from '../utils/frequently';
import { NimbleEmoji } from './emoji/nimble-emoji';

export default class Category extends React.Component {
  constructor(props) {
    super(props);
    this.data = props.data;
    this.lazyLoad = props.lazyLoad;
    this.setContainerRef = this.setContainerRef.bind(this);
    this.setLabelRef = this.setLabelRef.bind(this);
    this.firstRender = this.lazyLoad;
  }

  componentDidMount() {
    if (this.firstRender) {
      this.firstRenderTimeout = setTimeout(() => {
        //因为重写了shouldComponentUpdate，所以内部state变化了也不会rerender
        this.firstRender = false;
        this.forceUpdate();
      }, 300);
    }
    this.margin = 0;
    this.minMargin = 0;

    this.memoizeSize();
  }
  componentWillUnmount() {
    clearTimeout(this.firstRenderTimeout);
  }

  shouldComponentUpdate(nextProps) {
    const {
        name,
        perLine,
        native,
        hasStickyPosition,
        emojis,
        emojiProps,
        categoryBaseIndex,
        activeIndex,
      } = this.props,
      { skin, size, set, customColorKey } = emojiProps,
      {
        perLine: nextPerLine,
        native: nextNative,
        hasStickyPosition: nextHasStickyPosition,
        emojis: nextEmojis,
        emojiProps: nextEmojiProps,
        categoryBaseIndex: nextCategoryBaseIndex,
        activeIndex: nextActiveIndex,
      } = nextProps,
      {
        skin: nextSkin,
        size: nextSize,
        set: nextSet,
        customColorKey: nextCustomColorKey,
      } = nextEmojiProps;
    let shouldUpdate = false;
    if (name === 'Recent' && perLine !== nextPerLine) {
      shouldUpdate = true;
    }

    if (name === 'Search') {
      shouldUpdate = !(emojis === nextEmojis);
    }

    if (
      skin !== nextSkin ||
      size !== nextSize ||
      native !== nextNative ||
      set !== nextSet ||
      customColorKey !== nextCustomColorKey ||
      hasStickyPosition !== nextHasStickyPosition ||
      categoryBaseIndex !== nextCategoryBaseIndex
    ) {
      shouldUpdate = true;
    }
    const allEmojis = this.getEmojis();
    if (allEmojis) {
      if (
        //perf: 如果activeIndex刚好在所在分类，才需要更新分类，要是所有分类都更新的话会很卡
        activeIndex !== nextActiveIndex &&
        ((nextActiveIndex >= nextCategoryBaseIndex &&
          nextActiveIndex < allEmojis.length + nextCategoryBaseIndex) ||
          (activeIndex >= nextCategoryBaseIndex &&
            activeIndex < allEmojis.length + nextCategoryBaseIndex))
      ) {
        shouldUpdate = true;
      }
    }
    return shouldUpdate;
  }

  memoizeSize() {
    if (!this.container) {
      // probably this is a test environment, e.g. jest
      this.top = 0;
      this.maxMargin = 0;
      return;
    }
    const parent = this.container.parentElement;
    const { top, height } = this.container.getBoundingClientRect();
    const { top: parentTop } = parent.getBoundingClientRect();
    const { height: labelHeight } = this.label.getBoundingClientRect();

    this.top = top - parentTop + parent.scrollTop;
    if (height === 0) {
      this.maxMargin = 0;
    } else {
      this.maxMargin = height - labelHeight;
    }
  }

  handleScroll(scrollTop) {
    let margin = scrollTop - this.top;
    margin = margin < this.minMargin ? this.minMargin : margin;
    margin = margin > this.maxMargin ? this.maxMargin : margin;

    if (margin === this.margin) return;

    if (!this.props.hasStickyPosition) {
      this.label.style.top = `${margin}px`;
    }

    this.margin = margin;
    return true;
  }

  getEmojis() {
    const { name, recent, perLine } = this.props;
    let { emojis } = this.props;

    if (name === 'Recent') {
      const { custom } = this.props;
      const frequentlyUsed = recent || frequently.get(perLine);
      if (frequentlyUsed.length) {
        emojis = frequentlyUsed
          .map((id) => {
            // eslint-disable-next-line prefer-destructuring
            const emoji = custom.filter((e) => e.id === id)[0];
            if (emoji) {
              return emoji;
            }

            return id;
          })
          .filter((id) => !!getData(id, null, null, this.data));
      }

      if (emojis.length === 0 && frequentlyUsed.length > 0) {
        return null;
      }
    }

    if (emojis) {
      if (this.firstRender) {
        //just a hack, 第一次只加载一个屏幕的emoji个数.
        const frequentlyLen = frequently.get(perLine).length;
        //第一次render展示的个数
        const firstShowCount = 8 * perLine - frequentlyLen;
        if (emojis.length > firstShowCount) {
          emojis = emojis.slice(0, firstShowCount);
        }
      } else {
        emojis = emojis.slice(0);
      }
    }

    return emojis;
  }

  updateDisplay(display) {
    const emojis = this.getEmojis();

    if (!emojis || !this.container) {
      return;
    }

    this.container.style.display = display;
  }

  setContainerRef(c) {
    this.container = c;
  }

  setLabelRef(c) {
    this.label = c;
  }

  render() {
    const { id, name, hasStickyPosition, emojiProps, i18n, categoryBaseIndex, activeIndex } =
        this.props,
      emojis = this.getEmojis();
    let labelStyles = {},
      labelSpanStyles = {},
      containerStyles = {};

    if (!emojis) {
      containerStyles = {
        display: 'none',
      };
    }

    if (!hasStickyPosition) {
      labelStyles = {
        height: 28,
      };

      labelSpanStyles = {
        position: 'absolute',
      };
    }

    const label = i18n.categories[id] || name;
    return (
      <section
        ref={this.setContainerRef}
        className="emoji-mart-category"
        aria-label={label}
        style={containerStyles}
      >
        <div
          style={labelStyles}
          data-name={name}
          className="flex items-center px-4 bg-white2 emoji-mart-category-label text-t2 h-9"
        >
          <span
            className={`flex items-center text-grey3 w-full h-full ${
              emojis && emojis.length === 0 ? 'hidden' : ''
            }`}
            style={labelSpanStyles}
            ref={this.setLabelRef}
            aria-hidden={true /* already labeled by the section aria-label */}
          >
            {label}
          </span>
          {emojis && !emojis.length && (
            <div className="flex justify-center text-t2 text-grey4 w-full">无相关 Emoji</div>
          )}
        </div>

        <div className="pl-3 emoji-mart-category-list mb-2.5">
          {emojis &&
            emojis.map((emoji, index) => {
              // <li key={(emoji.short_names && emoji.short_names.join('_')) || emoji}>
              return (
                <NimbleEmoji
                  emoji={emoji}
                  key={
                    (emoji.short_names && emoji.short_names.join('_')) ||
                    emoji.name + index ||
                    emoji
                  }
                  data={this.data}
                  {...emojiProps}
                  className={
                    categoryBaseIndex + index === activeIndex
                      ? 'emoji-mart-emoji-active'
                      : undefined
                  }
                />
              );
              // </li>
            })}
        </div>
      </section>
    );
  }
}

Category.propTypes /* remove-proptypes */ = {
  emojis: PropTypes.array,
  hasStickyPosition: PropTypes.bool,
  name: PropTypes.string.isRequired,
  native: PropTypes.bool.isRequired,
  perLine: PropTypes.number.isRequired,
  emojiProps: PropTypes.object.isRequired,
  recent: PropTypes.arrayOf(PropTypes.string),
  notFound: PropTypes.func,
  notFoundEmoji: PropTypes.string.isRequired,
  categoryBaseIndex: PropTypes.number,
  activeIndex: PropTypes.number,
};

Category.defaultProps = {
  emojis: [],
  hasStickyPosition: true,
};
