import { BlockType } from '@next-space/fe-api-idl';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { useGetPageId } from 'src/utils/getPageId';

export const useIsEmbed = () => {
  const pageId = useGetPageId();
  const isEmbed = useObservableBlock(pageId, (state) => {
    return state?.type === BlockType.PAGE;
  });
  return isEmbed;
};
