/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getWithSetDefaultIfNeeded = <K, V>(map: Map<K, V>, key: K, f: () => V): V => {
  if (map.has(key)) {
    return map.get(key)!;
  }
  const value = f();
  map.set(key, value);
  return value;
};

export const add = <K, V>(map: Map<K, V[]>, key: K, value: V) => {
  const array = getWithSetDefaultIfNeeded(map, key, () => []);
  if (array.includes(value)) {
    return false;
  }
  array.push(value);
  return true;
};

export const remove = <K, V>(map: Map<K, V[]>, key: K, value: V) => {
  const array = getWithSetDefaultIfNeeded(map, key, () => []);
  const index = array.indexOf(value);
  if (index === -1) {
    return false;
  }
  array.splice(index, 1);
  if (array.length === 0) {
    map.delete(key);
  }
  return true;
};
