import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useBitable } from './context';
import { CollectionViewType } from '@next-space/fe-api-idl';

interface ExpandNodeProps {
  level: number;
  expand: boolean;
  onExpand: (expand: boolean) => void;
  className?: string;
  childrenLen: number;
}
export const ExpandNode: FC<ExpandNodeProps> = (props) => {
  const { expand, level, onExpand, childrenLen } = props;
  const { readonly, viewType } = useBitable();
  const isList = viewType === CollectionViewType.LIST;
  if (readonly && childrenLen === 0) {
    return (
      <div
        className="w-6"
        style={{
          marginLeft: level * 20,
        }}
      />
    );
  }
  return (
    <Icon
      data-no-cancel-selected
      data-disable-select
      className={cx(
        'animate-hover',
        !expand && childrenLen === 0 && 'text-grey',
        childrenLen === 0 &&
          `${
            isList ? 'group-scope-hover:opacity-100' : 'group-hover:opacity-100'
          } opacity-0 text-grey`
      )}
      style={{
        marginLeft: level * 20,
      }}
      name={expand ? 'IcToggleBlackUnfold' : 'IcToggleBlack'}
      size="xlarge"
      onClick={(e) => {
        e.stopPropagation();
        onExpand(!expand);
      }}
    />
  );
};
