import { createCache, createSetState, createStore } from '@flowus/common/zustand/utils';
import produce from 'immer';
import { assign } from 'lodash-es';
import type { request } from 'src/common/request';

export type PageMeta =
  | { tag: 'NEED_PAY'; timestamp?: never }
  | { tag: 'CACHE_EXPIRED'; timestamp: number }
  | { tag: 'NOT_FOUND'; timestamp?: never }
  | { tag: 'NEED_PASSWORD'; timestamp?: never }
  | {
      tag: 'NO_PERMISSION';
      timestamp?: never;
      docPublicData?: Awaited<ReturnType<typeof request.editor.getDocPublicData>>;
    };

type StateType = Record<string, PageMeta | undefined>;

const initialState: StateType = {};

export const $pageMetas = createStore<StateType>(() => initialState);
export const $pageMetasCache = createCache($pageMetas);
const $setPageMetas = createSetState($pageMetas);

export const updatePageMetas = (value: StateType) => {
  $setPageMetas(produce((pre: StateType) => assign(pre, value)));
};
