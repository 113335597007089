import i18next from 'i18next';
import { getCurrentLanguage, Language } from '@flowus/common/hooks/use-language';
import { LanguageKey, translationResources } from '../../locale';

const adaptLanguage = (lng: string) => {
  if (['zh-hk', 'zh-mo', 'zh-tw', 'zh-cht', 'zh-hant'].includes(lng.toLowerCase())) {
    //繁体中文
    return Language.zhHant;
  }
  //简体中文
  if (['zh', 'zh-cn'].includes(lng.toLowerCase())) return Language.zhHans;
  return lng;
};

const i18n = i18next.createInstance(
  {
    fallbackLng: Language.en,
    lng: getCurrentLanguage(),
    // debug: true,//做翻译的时候再打开对照
    resources: translationResources,
    returnObjects: true,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
      cookieOptions: {
        maxAge: 31536000000,
      },
      lookupCookie: LanguageKey,
      convertDetectedLanguage: (lng: string) => {
        return adaptLanguage(lng);
      },
    },
  },
  () => {} //傻逼作者，非要填一个callback，要不然就无法init
);
export default i18n;
