import { ViewPath } from 'src/utils';
import { locationToUrl } from 'src/utils/location-utils';
import { isNeedCreateSpace } from './use-create-space';

/**
 * 根据空间列表来判断跳到哪
 * 如果空间列表为空就跳到【创建空间页面】并返回true
 */
export const jumpAfterFetchSpaces = (extendSearchPath?: boolean) => {
  if (isNeedCreateSpace()) {
    locationToUrl(ViewPath.create, extendSearchPath);
    return true;
  }
  return false;
};
