import { VITE_CDN_HOST } from 'src/env';
import type { PDFRect, SelectionRange } from '../type';
import { convertPageRectToPDFRect, convertPDFRectToPageRect } from '../utils';
import { getMinWrapperRects } from '../utils/get-rects-from-range';

export function renderSelectionBorder(
  selectRange: SelectionRange,
  pdfViewer: any,
  clickAIButton?: (page: number, str: string, rect?: PDFRect) => void
) {
  const { pdfRects, text } = selectRange;

  const nodes = document.querySelectorAll('.pdf-selection-box');
  nodes.forEach((item) => item.remove());

  let minPageNumber = Infinity;
  let minRect: PDFRect[] = [];
  Object.keys(pdfRects).forEach((pageNumber) => {
    const { viewport, div } = pdfViewer.getPageView(Number(pageNumber) - 1);
    const rects = pdfRects[pageNumber];
    if (!rects) return;
    const pageRects = convertPDFRectToPageRect(rects, viewport);

    const node = document.createElement('div');
    node.classList.add('pdf-selection-box');
    div.appendChild(node);

    const pos = getMinWrapperRects(pageRects);
    if (Number(pageNumber) < minPageNumber) {
      minPageNumber = Number(pageNumber);
      minRect = convertPageRectToPDFRect(
        [{ x: pos.left, y: pos.top, width: pos.width, height: pos.height }],
        viewport
      );
    }

    node.style.cssText = `
      position: absolute;
      left: ${pos.left}px;
      top : ${pos.top}px;
      width: ${pos.width}px;
      height: ${pos.height}px;
      border: 2px solid var(--active_color);
      pointer-events: none;
    `;

    // TODO VITE_CDN_HOST
    node.innerHTML = `<div style="position: absolute; bottom: -10px; transform: translateY(100%); right: 20px; border: 1px solid rgba(0, 0, 0, 0.06); box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.16); border-radius: 4px; padding: 12px; background: white; cursor: pointer; pointer-events: auto;">
      <img src="${VITE_CDN_HOST}assets/pdf_ai_icon.svg" style="width: 20px; height: 20px;">
    </div>`;

    node.firstChild?.addEventListener('click', () =>
      clickAIButton?.(minPageNumber, text, minRect[0])
    );
  });
}
