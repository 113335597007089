import { useMemo } from 'react';
import { request } from 'src/common/request';
import { useActivityList } from 'src/services/activities/hook';
import { setAppUiState } from 'src/services/app/hook';

/** 这个日期之后开始，有新活动没浏览的情况下就要显示红点 */
const expireTime = new Date('2023-04-10').getTime();

/** 是否有新的未查看的活动 */
export const useHasNewActivity = () => {
  const activityList = useActivityList();
  return useMemo(() => {
    const todayTime = new Date().getTime();
    return activityList.some((v) => {
      const isAbandoned = v.status === 'abandoned';
      if (!v.viewed && !isAbandoned) {
        // 如果是以前的活动则不显示红点
        if (v.startTime && v.startTime < expireTime) {
          return false;
        }
        // 如果活动下线了也不显示红点
        if (v.endTime && v.endTime < todayTime) {
          return false;
        }

        return true;
      }
      return false;
    });
  }, [activityList]);
};

const LOCAL_KEY = 'getNewWelfareCodeCountTime';

/** 更新兑换码红点 */
export const updateExChangeCodeRedBadge = () => {
  if (__BUILD_IN__) return;
  const localTime = localStorage.getItem(LOCAL_KEY);
  const time = localTime ? Number(localTime) : Date.now();
  void request.infra.getNewWelfareCodeCount(time).then((res) => {
    const { unread = 0 } = res;
    setAppUiState({ $exChangeCodeRedBadge: !!unread });
    localStorage.setItem(LOCAL_KEY, String(Date.now()));
  });
};
