import { useCallback } from 'react';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { HelperIconBox, helperLink } from 'src/components/helper-icon';
import { PRODUCT_NAME } from 'src/const/title';
import { useOpenDeleteSpace } from 'src/hooks/space';
import { getSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { getCurrentUser, useClearUser, useCurrentUser } from 'src/hooks/user';
import { $currentUserCache } from 'src/services/user/current-user';
import { ViewPath } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { locationToUrl } from 'src/utils/location-utils';
import { CaptchaPanel } from './captcha-panel';
import { LogoffReason } from './logoff-reason';

export interface LogoffReasonInfo {
  reasons: string[];
  otherReason?: string | undefined;
}

/** 注销流程，一个个对话框轮流弹 */
export const useOpenLogoffDialog = () => {
  const openMustKnowDialog = useOpenMustKnowDialog();
  const openLogoutAccount = useOpenLogoutAccount();
  const openLogoffServiceDialog = useOpenLogoffServiceDialog();
  const openLogoffReasonDialog = useOpenLogoffReasonDialog();
  const openReceiveCaptchaDialog = useOpenReceiveCaptchaDialog();
  const openConfirmDialog = useOpenConfirmDialog();
  const clearUser = useClearUser();

  const open = async () => {
    try {
      const { nickname } = getCurrentUser();
      await openLogoutAccount();
      await openConfirmDialog(`删除${nickname}帐户和所有工作区数据`);
      if (!__BUILD_IN__) {
        await openMustKnowDialog();
        await openLogoffServiceDialog();
      }
      const reasonInfo = await openLogoffReasonDialog();
      await openReceiveCaptchaDialog(reasonInfo);
      const marks: string[] = [];
      reasonInfo.reasons.forEach((r, index) => {
        if (r) marks.push(index.toString());
      });
      reasonInfo.otherReason && marks.push(reasonInfo.otherReason);
      bizTracker.event('close_account', {
        is_success: true,
        close_reason: marks.join(';'),
      });
      message.success('注销成功');
      setTimeout(async () => {
        await clearUser();
        locationToUrl(ViewPath.login);
      }, 1000);
    } catch (error) {
      // do nothing
    }
  };
  return useCallback(open, [
    clearUser,
    openConfirmDialog,
    openLogoffReasonDialog,
    openLogoffServiceDialog,
    openLogoutAccount,
    openMustKnowDialog,
    openReceiveCaptchaDialog,
  ]);
};

/** 注销警告 */
const useOpenLogoutAccount = () => {
  const openModal = useOpenModal();
  const openDeleteSpace = useOpenDeleteSpace();
  const currentUser = useCurrentUser();
  const { editor } = getSpaceRolePermission(currentUser.uuid);

  return useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      const { nickname } = getCurrentUser();
      openModal.warning({
        colorType: 'red',
        maxWidth: 400,
        title: `即将注销您的 ${PRODUCT_NAME} 帐户`,
        content: editor ? (
          <>
            <span>注意即将销毁帐户下所有空间数据，如果只是删除空间请点击</span>
            <span
              className="text-red underline animate-click underline-offset-4 mx-1"
              onClick={openDeleteSpace}
            >
              删除空间
            </span>
          </>
        ) : (
          '注意即将销毁帐户下所有空间数据，如果只是删除空间请前往「空间设置」继续操作'
        ),
        confirmText: `继续注销 @${nickname}`,
        confirm: resolve,
        cancelText: '取消',
        cancel: () => {
          reject(new Error('cancel'));
        },
      });
    });
  }, [editor, openDeleteSpace, openModal]);
};

/** 注销警告 */
export const useOpenConfirmDialog = () => {
  const openModal = useOpenModal();
  return useCallback(
    (text: string) => {
      return new Promise<void>((resolve, reject) => {
        let value = '';
        openModal.warning({
          colorType: 'red',
          maxWidth: 400,
          title: `永久删除您的整个帐户？`,
          className: 'select-none',
          content: (
            <>
              <div className="text-t2 text-grey3">
                此操作无法撤消。这将永久删除您的整个帐户。所有私人工作区都将被删除，您将从所有共享工作区中删除。
              </div>
              <div className="py-5 text-t2-bold">{`请输入：${text}`}</div>
              <Input className="h-10" autoFocus placeholder={text} onChange={(v) => (value = v)} />
            </>
          ),
          confirmText: `继续`,
          confirm: () => {
            if (value.toLocaleLowerCase() === text.toLocaleLowerCase()) {
              resolve();
            } else {
              message.error('输入错误');
              throw new Error('confirm');
            }
          },
          cancelText: '取消',
          cancel: () => {
            reject(new Error('cancel'));
          },
        });
      });
    },
    [openModal]
  );
};

/** 注销须知 */
const useOpenMustKnowDialog = () => {
  const openModal = useOpenModal();
  return useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      openModal.warning({
        colorType: 'active',
        maxWidth: 400,
        title: __BUILD_IN__ ? (
          '注销须知'
        ) : (
          <HelperIconBox popup="查看注销帐户帮助" link={helperLink.cancelAccount}>
            注销须知
          </HelperIconBox>
        ),
        content: (
          <div className="flex flex-col items-start text-left">
            <div>亲爱的 {PRODUCT_NAME} 用户，我们对你的注销深表遗憾。</div>
            <div>
              在你提交注销申请生效前，请确保你已知悉以下内容，{PRODUCT_NAME} 团队有权进行验证:
            </div>
            <ul
              className="ml-4 mt-5"
              style={{
                listStyle: 'disc',
              }}
            >
              <li>该帐户下所有权益视为默认放弃，包含积分、已购买的订阅付费等</li>
              <li>
                若该帐户存在纠纷，如帐户封禁、投诉举报、诉讼等，{PRODUCT_NAME} 有权配合后续追诉
              </li>
            </ul>
          </div>
        ),
        confirmText: '我已知晓',
        confirm: () => {
          resolve();
        },
        cancelText: '取消',
        cancel: () => {
          reject(new Error('cancel'));
        },
      });
    });
  }, [openModal]);
};

/** 注销服务对话框 */
const useOpenLogoffServiceDialog = () => {
  const openModal = useOpenModal();
  return useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      openModal.warning({
        maxWidth: 400,
        colorType: 'active',
        title: '注销服务',
        content: (
          <div className="text-left">
            <div>请确保你已妥善处理空间及数据，注销后将导致:</div>
            <ul
              className="ml-4 mt-5"
              style={{
                listStyle: 'disc',
              }}
            >
              <li>
                自动退出所有多人空间（包含已邀请空间成员及外部协作者的团队空间/个人空间），已有数据不会失效
              </li>
              <li>仅含自己的空间，将永久删除空间所有数据</li>
            </ul>
          </div>
        ),
        confirmText: '继续',
        confirm: () => {
          resolve();
        },
        cancelText: '取消',
        cancel: () => {
          reject(new Error('cancel'));
        },
      });
    });
  }, [openModal]);
};

/** 注销服务填理由 */
const useOpenLogoffReasonDialog = () => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  return useCallback(() => {
    return new Promise<LogoffReasonInfo>((resolve, reject) => {
      const { modalId } = openModal.modal({
        autoClose: false,
        keyboard: false,
        content: () => (
          <LogoffReason
            onCancel={() => {
              reject(new Error('cancel'));
              closeModal(modalId);
            }}
            onNext={(reasons, otherReason) => {
              const selectReason: string[] = [];
              reasons.forEach((r, index) => {
                if (r) selectReason.push(index.toString());
              });
              resolve({ reasons: selectReason, otherReason });
              closeModal(modalId);
            }}
          />
        ),
      });
    });
  }, [closeModal, openModal]);
};

const useOpenReceiveCaptchaDialog = () => {
  const openModal = useOpenModal();
  const closeModal = useCloseModal();

  return useCallback(
    (reasonInfo: LogoffReasonInfo) => {
      return new Promise<void>((resolve, reject) => {
        const { modalId } = openModal.modal({
          autoClose: false,
          keyboard: false,
          content: () => {
            return (
              <CaptchaPanel
                reasonInfo={reasonInfo}
                account={$currentUserCache.phone ?? $currentUserCache.email}
                onConfirm={() => {
                  closeModal(modalId);
                  resolve();
                }}
                cancel={() => {
                  closeModal(modalId);
                  reject(new Error('cancel'));
                }}
              />
            );
          },
        });
      });
    },
    [closeModal, openModal]
  );
};
