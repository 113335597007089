import type { FC, MouseEvent } from 'react';
import type { PcIconName } from '../icon-svg';
import { PcIcon } from '../icon-svg';
import { Tooltip } from './tooltip';

interface Props {
  renderList: {
    title: string;
    click?: (e: MouseEvent) => void;
    icon: PcIconName | string;
    hidden?: boolean;
  }[];
}

export const MoreButtonList: FC<Props> = (props) => {
  const { renderList } = props;

  return (
    <div className="flex bg-black-base/90 rounded z-10 absolute top-2.5 right-3 opacity-0 group-hover:opacity-100 text-white space-x-1 py-1 px-1.5">
      {renderList
        .filter((i) => !i.hidden)
        .map((item) => (
          <Tooltip popup={item.title} key={item.title}>
            <div
              className="p-0.5 flex justify-center items-center animate-click"
              onClick={item.click}
            >
              <PcIcon size="small" name={item.icon as PcIconName} />
            </div>
          </Tooltip>
        ))}
    </div>
  );
};
