import React, { forwardRef, memo } from 'react';
import { shallowEqual } from 'react-redux';

import { ONE_DAY, UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { getDateTimeStamp, getUnitLength, isWeekOrMonth } from '../utils/get-timeline-dates';

export const TimelineMonths = memo(
  forwardRef<HTMLDivElement>((_, ref) => {
    const { containerWidth, timelineDates, zoomLevel } = useTimeline();
    const unitWidth = UnitWidthMap[zoomLevel];

    const renderContent = (date: number) => {
      let shouldRender = false;
      let text = '';
      let left = 0;
      if (isWeekOrMonth(zoomLevel)) {
        shouldRender = new Date(date).getDate() === 1;
        text = `${new Date(date).getMonth() + 1}月`;
        left = getUnitLength(date, timelineDates[0] as number, zoomLevel) * unitWidth;
      } else if (zoomLevel === ZoomLevel.YEAR) {
        const yearFirstDate = new Date(date).setMonth(0, 1);
        shouldRender = yearFirstDate === date;
        text = `${new Date(date).getFullYear()}年`;
        left = getUnitLength(date, timelineDates[0] as number, zoomLevel) * unitWidth;
      } else if (zoomLevel === ZoomLevel.QUARTER) {
        if (
          new Date(date).getMonth() !== new Date(date + 6 * ONE_DAY).getMonth() ||
          new Date(date).getDay() === 0
        ) {
          shouldRender = true;
          text = `${new Date(date + 6 * ONE_DAY).getMonth() + 1}月`;
          left =
            (getUnitLength(date, timelineDates[0] as number, zoomLevel) +
              (7 - new Date(date + 6 * ONE_DAY).getDate())) *
            unitWidth;
        }
      } else if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
        shouldRender = getDateTimeStamp(date) === date;
        text = `${new Date(date).getMonth() + 1}月${new Date(date).getDate()}日`;
        left = getUnitLength(date, timelineDates[0] as number, zoomLevel) * unitWidth;
      }

      if (shouldRender) {
        return (
          <div
            key={date}
            style={{ left }}
            className="absolute h-full inline-flex items-center justify-center flex-shrink-0"
          >
            {text}
          </div>
        );
      }

      return null;
    };
    return (
      <div
        ref={ref}
        className="timeline-month relative z-30 h-[38px] bg-white1 text-t2-medium border-t transition-none"
        style={{ width: containerWidth }}
      >
        {timelineDates.map(renderContent)}
      </div>
    );
  }),
  shallowEqual
);
