import { BlockType } from '@next-space/fe-api-idl';

export const getFileClass = (type: BlockType) => {
  let fileType = '';
  if (type === BlockType.FOLDER) {
    fileType = '文件夹';
  } else if (type === BlockType.FILE) {
    fileType = '文件';
  } else {
    fileType = '页面';
  }
  return fileType;
};

export const VIDEO_FORMAT = 'video/*,.mpg,.3gp,.asf,.avi,.f4v,.flv,.mkv,.rmvb,.wmv';
export const AUDIO_FORMAT = 'audio/*,.ogg,.m4a,.amr,.ac3,.wma,.ape';
