import type { createRequest } from '@next-space/fe-api-idl';
import localforage from 'localforage';
import { Deferred } from '../async';
import { guessFileName } from '../proxy-url';
import { LRUCache } from '../utils/lru-cache';

type UrlType = 'image' | 'download';
const SAVE_KEY = 'oss_images';
const MAX_QUEUE = 10;
const ONE_MIN = 1000 * 60;
const ONE_HOUR = ONE_MIN * 60;
export const EXPIRE_TIME = ONE_HOUR * 5;
interface OSSInfo {
  url: string;
  expireTime: number;
}

interface FetchUrlParams {
  blockId: string;
  ossName: string;
  download?: string;
  shareId?: string;
  isPublic?: boolean;
}

interface Options {
  shareId?: string;
  isShare?: boolean;
  onExpire?: () => void;
}

interface Props {
  request: any;
}

/**
 * 通过oss name拿到对应的图片url/下载地址
 */
export class UrlFetcher {
  private lruCache;
  private fetchers: Record<string, Deferred<string>> = {};
  private request: ReturnType<typeof createRequest>;
  private taskParams: Record<string, FetchUrlParams> = {};
  private timer: ReturnType<typeof setTimeout> | undefined = undefined;
  constructor(props: Props) {
    const { request } = props;
    this.lruCache = new LRUCache<string, OSSInfo>(localforage, SAVE_KEY, 1000);
    this.request = request;
  }

  private async fetchUrl(
    type: UrlType,
    blockId: string,
    ossName: string,
    fileName?: string,
    opt?: Options
  ) {
    const { shareId, isShare } = opt || {};
    if (!ossName) return '';

    const cacheKey = this.getCacheKey(type, ossName, fileName ?? guessFileName(ossName));
    this.tryClearCache(cacheKey);

    const ret = this.lruCache.get(cacheKey);
    if (ret) {
      return ret.url;
    }

    const cacheFetcher = this.fetchers[cacheKey];
    if (cacheFetcher) {
      return cacheFetcher.promise;
    }

    //添加到队列里，如果对列超过10个，就直接发送，如果没超过10个，就delay一下再发送
    this.taskParams[cacheKey] = {
      blockId,
      ossName,
      download: fileName,
      shareId,
      isPublic: Boolean(isShare),
    };
    const deferred = new Deferred<string>();
    this.fetchers[cacheKey] = deferred;
    const fetchAllFromParams = async () => {
      const taskParamsEntries = Object.entries(this.taskParams);
      const cacheKeys = taskParamsEntries.map((v) => v[0]);
      const params = taskParamsEntries.map((v) => v[1]);
      //清空
      this.taskParams = {};
      const res = await this.request.infra.createUrls.raw({
        batch: params,
      });

      if (res.code === 200) {
        res.data.forEach((item, index) => {
          const key = cacheKeys[index];
          if (!key) return;
          try {
            if (!item) return;
            if (typeof item !== 'string') {
              const _item: any = item;
              const url: string = _item?.compressedUrl || _item.url;
              if (url) {
                this.lruCache.put(key, {
                  url,
                  //现在的有效时长是6小时
                  expireTime: Date.now() + EXPIRE_TIME,
                });
              }
            } else {
              //impossible
              if (item.startsWith('http')) {
                this.lruCache.put(key, {
                  url: item,
                  expireTime: Date.now() + EXPIRE_TIME,
                });
              }
            }
          } finally {
            if (!item) {
              this.fetchers[key]?.resolve('');
            } else {
              if (typeof item !== 'string') {
                this.fetchers[key]?.resolve(item.url);
              }
            }
            delete this.fetchers[key];
          }
        });
      } else {
        this.fetchers = {};
      }
    };
    clearTimeout(this.timer);
    if (Object.keys(this.taskParams).length >= MAX_QUEUE) {
      void fetchAllFromParams();
    } else {
      this.timer = setTimeout(() => {
        void fetchAllFromParams();
      }, 800);
    }

    return deferred.promise;
  }

  putCache(ossName: string, url: string, _: number) {
    const cacheKey1 = this.getCacheKey('image', ossName);
    const time = Date.now() + 30 * 60 * 1000;
    this.lruCache.put(cacheKey1, {
      url,
      expireTime: time,
    });
    const cacheKey2 = this.getCacheKey('download', ossName);
    this.lruCache.put(cacheKey2, {
      url,
      expireTime: time,
    });
  }

  /**
   * 获取图片地址,一般情况下推荐你使用useResource来获取地址
   */
  async fetchImageUrl(params: {
    blockId: string;
    ossName: string;
    fileName?: string;
    opt?: Options;
  }) {
    return this.fetchUrl('image', params.blockId, params.ossName, params.fileName, params.opt);
  }

  /**
   * 获取下载地址
   */
  async fetchDownloadUrl(params: {
    blockId: string;
    ossName: string;
    fileName?: string;
    opt?: Options;
  }) {
    return this.fetchUrl('download', params.blockId, params.ossName, params.fileName, params.opt);
  }

  private getCacheKey(type: UrlType, ossName: string, fileName?: string) {
    return `${type}${ossName}${fileName}`.replaceAll('/', '_').replaceAll('.', '_');
  }

  /**
   * 清理过期的url
   */
  private tryClearCache(key: string, callback?: (key: string) => void) {
    const info = this.lruCache.get(key);
    if (info && info.expireTime <= Date.now()) {
      this.lruCache.delete(key);
      callback?.(key);
    }

    // 如果过期时长超过6小时，则可能是用户系统时间调整导致的错误，需求fix
    if (info && info.expireTime > Date.now() + ONE_HOUR * 6) {
      this.lruCache.delete(key);
      callback?.(key);
    }
  }
}
