import type { FC } from 'react';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useGuestsList } from 'src/services/app';
import { SettingModalCommon } from '../common';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { Guests } from './guest';
import { Members } from './member';
import { MemberGroups } from './member-group';

/** 空间成员 */
export const MembersSetting: FC = () => {
  const closeSettingModal = useCloseSettingModal();
  const users = useCurrentSpaceUsers();
  const spaceUser = Object.values(users);
  const guests = useGuestsList();
  const isTeamSpace = useIsTeamSpace();

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        <Tab tabBarClassName="px-0">
          {isTeamSpace && (
            <TabPane title={[`成员`, spaceUser.length ? `（${spaceUser.length}）` : ''].join('')}>
              <Members />
            </TabPane>
          )}
          {isTeamSpace && (
            <TabPane title="成员组">
              <MemberGroups />
            </TabPane>
          )}
          <TabPane title={`外部协作者（${guests.length}）`}>
            <Guests />
          </TabPane>
        </Tab>
      </div>
      <SettingModalCommon.FooterBar cancelText="关闭" cancel={() => closeSettingModal()} />
    </>
  );
};
