import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React, { useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { useSize } from 'src/common/utils/use-size';
import { useUpdateSpaceView } from 'src/hooks/space/use-update-space-view';
import type { Template } from 'src/hooks/template/types';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { $currentUserCache } from 'src/services/user/current-user';
import type { TemplateCenter as TemplateCenter0 } from '../template';
import { TemplateContext } from '../template/template-context';
import { TemplateItem } from '../template/template-item';
import { ResourceCenter } from '../resource-center';

let TemplateCenter: typeof TemplateCenter0;
export const antiCycleSet_TemplateCenter = (TemplateCenter0: typeof TemplateCenter) => {
  TemplateCenter = TemplateCenter0;
};

export const RecommendTemplate: FC<{
  keyword?: string;
  searchResult: Template[];
  setDisableRecommend: React.Dispatch<React.SetStateAction<boolean>>;
  targetId: string;
}> = ({ searchResult, setDisableRecommend, keyword }) => {
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const container = useRef<HTMLDivElement | null>(null);
  const { width } = useSize(container);
  const updateSpaceView = useUpdateSpaceView();

  const openTemplateCenter = () => {
    openModal.modal({
      modalId: Modals.TEMPLATE_CENTER,
      content: () => <ResourceCenter onlyShowTab="template" from="titleSearch" keyword={keyword} />,
    });
  };

  const openMenu = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'top-end',
      offset: [0, 10],
      content: ({ onCloseModal }) => {
        return (
          <div className="border rounded-sm bg-white1 text-grey3 text-t4 flex whitespace-nowrap">
            <button
              className="border-r px-1.5"
              onClick={() => {
                transaction(() => {
                  updateSpaceView($currentUserCache.currentSpaceViewId, {
                    setting: { closeTemplateRecommend: true },
                  });
                });

                onCloseModal();
              }}
            >
              永久关闭
            </button>
            <button
              className="px-1.5"
              onClick={() => {
                setDisableRecommend(true);
                onCloseModal();
              }}
            >
              本次关闭
            </button>
          </div>
        );
      },
    });
  };

  return (
    <TemplateContext.Provider value={'titleSearchFooter'}>
      <div className="pt-4 bg-grey9 absolute bottom-0 w-full z-[100]">
        <div className="flex justify-between pr-8">
          <p className="text-t1 py-2 pl-[30px]">为你找到一些模板，试试看？</p>
          <div className="text-grey3 flex items-center">
            <button className="underline mr-5" onClick={openTemplateCenter}>
              查看更多相关模板
            </button>
            <Icon name="IcUploadCancel" onClick={openMenu} className="text-grey4" size="middle" />
          </div>
        </div>

        <div className="pl-8 pr-4 overflow-auto flex flex-nowrap" ref={container}>
          {searchResult.map((item) => {
            return (
              <TemplateItem
                key={item.pageId}
                template={item}
                className={cx('flex-shrink-0 mr-4', (width ?? 0) > 800 ? 'w-[225px]' : 'w-[180px]')}
                onPreviewOpen={() => {
                  setDisableRecommend(true);
                }}
              />
            );
          })}
        </div>
      </div>
    </TemplateContext.Provider>
  );
};
