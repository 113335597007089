import { CountDown } from '@flowus/common/components/count-down';
import { URL_SPLIT_TEXT } from '@flowus/common/const';
import { getFormatUrl } from '@flowus/common/url';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'src/common/components/button';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useFetchPage } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { createAnchorDomClick } from 'src/utils/dom';
import { extractPageIdFromPathname } from 'src/utils/getPageId';
import { FormErrorPageView } from '../form-error-page-view';
import { PreviewEndPage } from './preview-end-page';

interface Props {
  formOnlyOneSubmit?: boolean;
  resubmit?: () => void;
  viewId: string;
}
export const FormEndPage: FC<Props> = (props) => {
  const fetchPage = useFetchPage();
  const { formOnlyOneSubmit, resubmit, viewId } = props;
  const view = useCollectionView(viewId);
  const [showCountDown, setShowCountDown] = useState<'padding' | 'show' | 'hidden'>('padding');
  const [showEndPage, setShowEndPage] = useState(false);

  const previewId = useMemo(() => {
    let formatUrl = view?.format.formEndPage;
    if (formatUrl?.includes(URL_SPLIT_TEXT)) {
      formatUrl = formatUrl.split(URL_SPLIT_TEXT)?.[0];
    }

    if (formatUrl?.includes(' ')) {
      formatUrl = formatUrl.split(' ')?.[0];
    }

    if (!formatUrl) return '';
    const url = getFormatUrl(formatUrl);
    const id = extractPageIdFromPathname(url?.pathname || '');
    return id;
  }, [view?.format.formEndPage]);

  const countDownFinish = () => {
    setShowEndPage(true);
  };

  const checkUrl = async () => {
    if (!previewId) {
      setShowCountDown('hidden');
      return;
    }

    await fetchPage(previewId, {
      callback: (res) => {
        if (res.code === 200) {
          if (getPermissions(previewId).shared) {
            setShowCountDown('show');
          } else {
            setShowCountDown('hidden');
          }
        } else {
          setShowCountDown('hidden');
        }
      },
    }).catch(() => {
      setShowCountDown('hidden');
    });
  };

  const openUrl = () => {
    createAnchorDomClick(view?.format.formEndPage);
  };

  useEffect(() => {
    void checkUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showEndPage) {
    return (
      <>
        <div className="flex justify-end absolute -top-10 z-[100] w-full">
          {!formOnlyOneSubmit ? (
            <Button className="text-t2" colorType="base_white" onClick={resubmit}>
              返回再次填写
            </Button>
          ) : (
            <div />
          )}
          <Button className="text-t2 ml-4" colorType="base_white" onClick={openUrl}>
            查看原页面
          </Button>
        </div>
        <div className="flex max-h-[60vh] min-h-[58vh]">
          <PreviewEndPage uuid={previewId} />
        </div>
      </>
    );
  }

  return (
    <FormErrorPageView icon="😀" description="你已提交成功">
      {showCountDown === 'hidden' && !formOnlyOneSubmit && (
        <Button
          className="mt-10 w-44"
          colorType="active"
          size="middle"
          onClick={() => resubmit?.()}
        >
          再填写一次
        </Button>
      )}
      {showCountDown === 'show' && (
        <div className="text-t1 text-grey3 mt-3 select-none">
          即将自动跳转至信息展示页 <CountDown time={3} onFinish={countDownFinish} />
          <span className="ml-2 animate-click text-active_color" onClick={countDownFinish}>
            立即跳转
          </span>
        </div>
      )}
    </FormErrorPageView>
  );
};
