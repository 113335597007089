import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import {
  getBoardGroupBackgroundColor,
  getBoardGroupNumberTextColor,
  getBoardGroupTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { realGroupName } from 'src/hooks/collection-view/table-groups';
import { useCardImageDir, useCardSize } from 'src/hooks/collection-view/use-collection-view';
import { useEnableBoardColor } from 'src/hooks/collection-view/use-enable-board-color';
import { useGroupOption } from 'src/hooks/collection-view/use-group-option';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { getCardSize } from '../const';
import { useBitable } from '../context';
import { CreateRecord } from '../group-header/create-record';
import { GroupName, GroupNameScene } from '../group-header/group-name';
import { GroupMore } from '../group-more';
import { GroupAggregation } from '../group-header/group-aggregation';

interface GroupHeaderProps {
  groupName: string;
  recordIds: string[];
  listeners?: DraggableSyntheticListeners;
  isSubGroup?: boolean;
}

export const BoardGroupHeader: FC<GroupHeaderProps> = ({
  recordIds,
  groupName,
  listeners,
  isSubGroup,
}) => {
  useIsDarkMode();
  const { viewId, readonly, managerReadonly } = useBitable();
  const isEnableBoardColor = useEnableBoardColor(viewId);
  const option = useGroupOption(viewId, realGroupName(groupName));
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);

  const colorStyle = {
    color: isEnableBoardColor ? getBoardGroupTextColor(option?.color) : undefined,
  };
  const numberColorStyle = {
    color: isEnableBoardColor ? getBoardGroupNumberTextColor(option?.color) : undefined,
  };
  const style = {
    width: getCardSize(cardSize, imageDir) + 10,
    backgroundColor: isEnableBoardColor ? getBoardGroupBackgroundColor(option?.color) : undefined,
  };

  return (
    <div
      {...listeners}
      data-disable-select
      style={style}
      className={cx(
        //  sticky top-0  TODO: 需要 UI 调一下背景色
        'z-10 flex h-[50px] items-center px-[5px] pt-2.5 pb-2',
        isSubGroup ? 'mr-2.5 rounded-md' : 'rounded-t-md',
        isEnableBoardColor && 'bg-grey/5',
        isSubGroup && 'group bg-grey/5'
      )}
    >
      <div
        className="flex items-center text-ellipsis pl-1"
        style={{ maxWidth: 'calc(100% - 100px)' }}
      >
        <GroupName groupName={groupName} scene={GroupNameScene.GROUP_HEADER} />
      </div>

      <GroupAggregation
        groupbyFieldName="boardGroupBy"
        recordIds={recordIds}
        style={numberColorStyle}
      />
      <div
        className="hover-button ml-auto flex items-center text-grey4 opacity-0 transition-all duration-200 group-hover:opacity-100"
        style={colorStyle}
      >
        <GroupMore className="mr-1" groupName={groupName} />

        {readonly || managerReadonly ? null : (
          <CreateRecord groupName={groupName} before={recordIds[0]} />
        )}
      </div>
    </div>
  );
};
