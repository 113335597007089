import { cx } from '@flowus/common/cx';
import { SpacePlanType } from '@flowus/shared';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { useCallback, useMemo, useRef } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { ScrollLoadList } from 'src/components/scroll-load-list';
import { useOpenUpgradeCapacityModal } from 'src/components/select-upgrade-plan/upgrade-capacity';
import { SpaceCapacity } from 'src/components/space-capacity';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useCurrentSpace } from 'src/hooks/space';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { Modals } from 'src/modals';
import { useSpaceCapacity } from 'src/services/capacity';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { SettingModalCommon, SettingProvider } from '../common';
import { Option } from '../option';
import { OpenSettingFrom, SettingMenuType } from '../type';
import { useOpenSpaceFileList } from './space-file-list';

interface Props {}
export const SpaceInfoCapacity: FC<Props> = () => {
  const openUpgradeCapacityModal = useOpenUpgradeCapacityModal();
  const currentSpacePlanInfo = useCurrentSpacePlanInfo();
  const spaceCapacity = useSpaceCapacity();
  const { setCurrentSettingMenu, setIsFrom } = useModel(SettingProvider);
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const isFreePersonal = currentSpace.planType === SpacePlanType.freePersonal;
  const isProSpace = useIsProSpace();
  const isTeamSpace = useIsTeamSpace();
  const openSpaceFileList = useOpenSpaceFileList();

  const handleClick = () => {
    setCurrentSettingMenu(SettingMenuType.upgrade);
    setIsFrom(OpenSettingFrom.dashboard_file);
  };

  const openCapacityOrderList = useCallback(() => {
    openModal.modal({
      modalId: Modals.CAPACITY_ORDER_LIST_ID,
      content: () => <CapacityOrderList />,
    });
  }, [openModal]);

  // 容量组成信息
  const capacityList = useMemo(() => {
    const spacePlanEndAt = currentSpacePlanInfo.spacePlan?.endAt
      ? dayjs(currentSpacePlanInfo.spacePlan.endAt).format(DATE_FORMAT)
      : undefined;

    const list: {
      title: ReactNode;
      size: number;
      rightPart: ReactNode;
      desc?: ReactNode;
    }[] = [
      {
        title: `基础容量`,
        size: spaceCapacity.baseCapacity,
        rightPart: spacePlanEndAt || '免费容量',
      },
    ];

    if (!__PRIVATE__) {
      if (isTeamSpace && spaceCapacity.peopleCapacity) {
        list.push({
          title: '席位容量',
          size: spaceCapacity.peopleCapacity,
          rightPart: spacePlanEndAt,
        });
      }
      if (isProSpace) {
        list.push({
          title: '补充容量',
          size: spaceCapacity.buyCapacity + spaceCapacity.giveCapacity,
          desc: (
            <span
              className="ml-1 text-active_color animate-click"
              onClick={() => openUpgradeCapacityModal()}
            >
              购买容量包
            </span>
          ),
          rightPart: (
            <div
              className="flex items-center justify-between animate-click"
              onClick={openCapacityOrderList}
            >
              <span className="text-red mr-1" hidden={!spaceCapacity.expirationCapacity}>
                {getReadableFileSizeString(spaceCapacity.expirationCapacity)} 即将到期
              </span>
              <span className="w-4" hidden={!!spaceCapacity.expirationCapacity} />
              <Icon name="IcArrowUnfold" size="xxxsmall" />
            </div>
          ),
        });
      }
    }

    return list;
  }, [
    currentSpacePlanInfo.spacePlan?.endAt,
    isProSpace,
    isTeamSpace,
    openCapacityOrderList,
    openUpgradeCapacityModal,
    spaceCapacity.baseCapacity,
    spaceCapacity.buyCapacity,
    spaceCapacity.expirationCapacity,
    spaceCapacity.giveCapacity,
    spaceCapacity.peopleCapacity,
  ]);

  // 底部补充提示组件
  const capacityTips = useMemo(() => {
    let contentText = ``;
    const maxCapacityString = getReadableFileSizeString(spaceCapacity.maxCapacity);

    if (spaceCapacity.isNovice) {
      contentText = `新手期限制容量 ${maxCapacityString}，完成入门任务解锁免费容量 2GB`;
    }

    if (!isProSpace && isTeamSpace) {
      contentText = `小组版试用期间限制容量 ${maxCapacityString}，升级到小组正式版解锁全部容量 300GB`;
    }

    if (contentText) {
      return (
        <div className="bg-active_color_10 text-active_color h-9 px-4 text-t3 flex items-center mt-2.5">
          {contentText}
        </div>
      );
    }

    return null;
  }, [isProSpace, isTeamSpace, spaceCapacity.isNovice, spaceCapacity.maxCapacity]);

  return (
    <>
      <Option
        title={
          <div className="flex items-center space-x-2">
            <span>空间储存容量</span>
            <div
              className="flex items-center space-x-0.5 text-grey3 animate-click"
              onClick={openSpaceFileList}
            >
              <span>查看</span>
              <Icon name="IcArrowDown01" size="tiny" className="-rotate-90" />
            </div>
          </div>
        }
        description={<SpaceCapacity type="setting" />}
        rightPart={!isProSpace && <Button onClick={handleClick}>升级扩容</Button>}
      />
      {!(__BUILD_IN__ || __PRIVATE__) && (
        <div className="px-4 py-2 rounded bg-grey9 select-none">
          {capacityList.map((item, i) => (
            <div key={i} className="h-7 flex items-center justify-between text-grey3">
              <div>
                {item.title} {getReadableFileSizeString(item.size)} {item.desc}
              </div>
              <div>{item.rightPart}</div>
            </div>
          ))}
        </div>
      )}
      {capacityTips}
    </>
  );
};

/** 容量补充套餐列表 */
const CapacityOrderList: FC = () => {
  const openModal = useOpenModal();
  const openUpgradeCapacityModal = useOpenUpgradeCapacityModal();
  const container = useRef<HTMLDivElement>(null);
  const currentSpace = useCurrentSpace();
  const spaceCapacity = useSpaceCapacity();

  const handleOpenUpgradeCapacity = (id?: string) => {
    openModal.closeModal(Modals.CAPACITY_ORDER_LIST_ID);
    openUpgradeCapacityModal({ planId: id });
  };

  const getApi = async (params: { pageIndex: number; pageSize: number }) => {
    const res = await request.infra.capacityList(
      params.pageIndex,
      params.pageSize,
      currentSpace.uuid
    );

    return { list: res.list, more: res.list.length === params.pageSize };
  };

  const hasGiveCapacity = spaceCapacity.giveCapacity > 0;

  return (
    <div className="w-125 py-1">
      {/* title */}
      <div className="h-12 flex items-center justify-between px-7">
        <span className="text-h4">补充容量</span>
        <span
          className="text-t2 animate-click text-active_color"
          onClick={() => handleOpenUpgradeCapacity()}
        >
          购买容量包
        </span>
      </div>
      <SettingModalCommon.SettingDivider />
      <div className="px-7 text-t2">
        {/* 表头 */}
        <div className="grid grid-cols-6 h-10 items-center">
          <span className="col-span-2">容量套餐</span>
          <span className="col-span-2">使用状态</span>
          <span className="col-span-2">到期时间</span>
        </div>
        <SettingModalCommon.SettingDivider />
        <div ref={container} className="max-h-80 overflow-y-auto">
          {hasGiveCapacity && (
            <>
              <div className={'grid grid-cols-6 h-10 items-center'}>
                <span className="col-span-2">
                  {getReadableFileSizeString(spaceCapacity.giveCapacity)}&nbsp;
                </span>
                <span className="col-span-2">使用中</span>
                <span className="col-span-2">2023/12/31 23:59</span>
              </div>
            </>
          )}
          <ScrollLoadList
            container={container}
            getApi={getApi}
            placeholder={hasGiveCapacity ? '' : '无容量包记录'}
            renderItem={(item) => (
              <div
                key={item.id}
                className={cx(
                  'grid grid-cols-6 h-10 items-center',
                  item.status !== 20 && 'opacity-30'
                )}
              >
                <span className="col-span-2">
                  {item.planPriceUnit}GB&nbsp;
                  <span
                    className="text-active_color animate-click ml-1"
                    hidden={item.status !== 20}
                    onClick={() => handleOpenUpgradeCapacity(item.planId)}
                  >
                    续费
                  </span>
                </span>
                <span className="col-span-2">
                  {item.status === 20 || (item.expirationTime && item.expirationTime < Date.now())
                    ? '使用中'
                    : '已过期'}
                </span>
                <span className="col-span-2">
                  {dayjs(item.expirationTime).format(DATE_TIME_FORMAT)}
                </span>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};
