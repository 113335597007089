import { message } from '@flowus/common/components/message';
import { VITE_PREFIX } from '@flowus/common/const';
import type { UploadParams } from '@flowus/upload';
import { UploadErrorCode } from '@flowus/upload';
import type { UploadType } from '@flowus/upload/types';
import type { SpaceDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { useOpenUpgradeSpacePlan } from 'src/components/upgrade-space-plan';
import { UpgradeSpacePlanType } from 'src/components/upgrade-space-plan/types';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { PROXY_TARGET } from 'src/env';
import { Modals } from 'src/modals';
import { UploadStatus } from 'src/redux/types';
import { getSpaceCapacity } from 'src/services/capacity';
import { useGetOtherSpacePublicDataAndPatch } from 'src/services/other-space/hook';
import { updateUploadInfo } from 'src/services/upload';
import { SearchParams, ViewPath } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import {
  useOpenSettingModal,
  useOpenUpgradeSpace,
} from 'src/views/main/setting-modal/use-open-setting-modal';
import { v4 } from 'uuid';
import { getCurrentUser } from '../user';
import { switchSpaceViewIdBySpaceId } from '../user/switch-space-view-id';
import { useIsLogin } from '../user/use-is-login';
import { getCurrentSpace } from './use-current-space';
import { getSpaceRolePermission } from './use-get-space-role-permission';
import { PRO_SPACE_PLAN, TEAM_SPACE_PLAN } from './use-is-pro-space';
import { OpenDialogEnum } from './use-open-dialog-from-location';

interface Params {
  title: string;
  maxCapacity: number;
  singleFile?: boolean;
  planType?: SpaceDTO['planType'];
  readonly?: boolean;
  isNovice?: boolean;
  copyLimit?: boolean;
  /** 分享转存需要这个 */
  spaceId?: string;
  isMoveOtherSpace?: boolean;
}
/** 打开容量报错弹窗 */
export const useOpenCapacityDialog = () => {
  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const openSettingModal = useOpenSettingModal();
  const isLogin = useIsLogin();
  const openUpgradeSpacePlan = useOpenUpgradeSpacePlan();

  return useCallback(
    (params: Params) => {
      const {
        title,
        maxCapacity,
        singleFile,
        planType = getCurrentSpace().planType ?? '',
        readonly,
        // isNovice,
        spaceId,
        copyLimit,
        isMoveOtherSpace,
      } = params;
      const spaceEditor = getSpaceRolePermission(getCurrentUser().uuid).editor;
      const isProSpace = PRO_SPACE_PLAN.includes(planType);
      const isTeamSpace = TEAM_SPACE_PLAN.includes(planType);
      const isShare = judgeSharePage();
      const capacity = getReadableFileSizeString(maxCapacity, 0);
      const upgradeIsOk = !isShare && isLogin && !readonly && spaceEditor;
      const spaceName = `当前为${getSpacePlanTypeName(planType)}空间`;
      const singleFileContent = `${spaceName}，单文件大小上限为${capacity}。`;
      const maxFileContent = `${spaceName}，总容量为${capacity}，如需提升上限，请升级扩容。`;

      const content = singleFile ? singleFileContent : maxFileContent;

      const confirmText = upgradeIsOk ? '升级扩容' : '知道了';

      bizTracker.event('single_files_show');

      if (!isTeamSpace && !isProSpace) {
        // 是否为单文件限制条件
        // 是不是整体容量超了
        const failType = (() => {
          if (copyLimit) {
            return UpgradeSpacePlanType.maxCopyLimit;
          }
          if (singleFile) {
            if (isShare) {
              message.warning('拷贝失败，拷贝的页面包含超过2M 的文件');
            }
            return UpgradeSpacePlanType.maxFileSize;
          }
          if (isShare) {
            message.warning('拷贝失败，空间储存容量已达上限。');
          }
          return UpgradeSpacePlanType.maxCapacity;
        })();

        if (failType) {
          if (!isMoveOtherSpace) {
            // message.error('拷贝失败，请联系空间管理员升级空间');
          } else {
            showMoveTip(failType);
          }
          if (isShare) {
            const path = splicePathSearchParams(ViewPath.main, [
              {
                key: SearchParams.spaceId,
                value: spaceId,
              },
              {
                key: SearchParams.openDialog,
                value: failType,
              },
            ]);

            setTimeout(() => {
              location.href = `${VITE_PREFIX}${path}`;
            }, 1500);

            return;
          }

          openUpgradeSpacePlan({ type: failType, spaceId });
          return;
        }
      }

      const commonParams = {
        modalId: Modals.SINGLE_CAPACITY,
        title: (
          <div className="mb-5">
            <p className="text-t1-medium mb-2.5">{title}</p>
            <p className="text-t2">{content}</p>
          </div>
        ),
        colorType: 'active' as ModalSchema.RenderWarning['colorType'],
        confirmText,
        confirm: () => {
          if (!upgradeIsOk) return;
          if (judgeSharePage() && spaceId) {
            switchSpaceViewIdBySpaceId(spaceId);
            location.href = `${PROXY_TARGET}`;
            return;
          }
          if (isMoveOtherSpace) {
            // 跳到对应的空间再打开升级
            message.success('正在切换空间，稍等片刻...');
            // 切换空间再升级
            const params = new URLSearchParams();
            params.set(SearchParams.spaceId, spaceId ?? '');
            params.set(
              SearchParams.openDialog,
              isProSpace ? OpenDialogEnum.space_info : OpenDialogEnum.openUpgradeSpace
            );
            window.location.href = `${window.location.origin}?${params.toString()}`;
            return;
          }
          if (isProSpace) {
            openSettingModal({
              from: OpenSettingFrom.file,
              defaultMenuType: SettingMenuType.spaceInfo,
            });
          } else {
            openUpgradeSpace(OpenSettingFrom.file);
          }
        },
      };

      openModal.warning({ ...commonParams });
    },
    [isLogin, openModal, openSettingModal, openUpgradeSpace, openUpgradeSpacePlan]
  );
};
const showMoveTip = (type: UpgradeSpacePlanType) => {
  switch (type) {
    case UpgradeSpacePlanType.maxCapacity: {
      message.warning('移动失败，空间储存容量已达上限');
      break;
    }
    case UpgradeSpacePlanType.maxFileSize: {
      message.warning('移动失败，移动的页面包含>2M的文件');
      break;
    }

    default:
  }
};

/** 检查容量是否足够 */
export const useUploadCheckCapacity = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  const getOtherSpacePublicDataAndPatch = useGetOtherSpacePublicDataAndPatch();

  return useCallback(
    async (params: {
      spaceId: string;
      isDatabase?: boolean;
      fileSize: number;
      onComplete: UploadParams['onComplete'];
      inTransferList?: boolean;
      key?: string;
      blockId?: string;
      pageId?: string;
      fileName: string;
      type: UploadType;
    }) => {
      const uploadId = v4();
      const {
        spaceId,
        isDatabase,
        fileSize,
        onComplete,
        key,
        fileName,
        inTransferList,
        blockId,
        pageId,
        type,
      } = params;

      if (type !== 'file') {
        return true;
      }

      const { singleFileMaxSize, maxCapacity, currentCapacity, isNovice } = getSpaceCapacity(
        spaceId,
        { isDatabase }
      );

      let { planType } = getCurrentSpace();

      // 如果不是当前空间，再做判断获取空间的逻辑
      if (spaceId !== getCurrentSpace().uuid) {
        const spacesPublic = await getOtherSpacePublicDataAndPatch(spaceId);
        planType = spacesPublic?.planType;
      }

      const singleFileOverflow = fileSize > singleFileMaxSize;
      const maxCapacityOverflow = currentCapacity + fileSize > maxCapacity;
      if (maxCapacityOverflow || singleFileOverflow) {
        if (maxCapacityOverflow) {
          openCapacityDialog({
            title: '有文件上传失败',
            maxCapacity,
            planType,
            isNovice,
          });
        } else if (singleFileOverflow) {
          openCapacityDialog({
            title: '有文件上传失败',
            maxCapacity: singleFileMaxSize,
            singleFile: true,
            planType,
            isNovice,
          });
        }
        updateUploadInfo({
          name: fileName,
          size: fileSize,
          key: key ?? '',
          inTransferList,
          progress: -1,
          blockId,
          pageId,
          status: UploadStatus.failure,
          uploadId,
        });
        onComplete?.({
          success: false,
          errCode: UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT,
          errMsg: '',
        });
        return false;
      }
      return true;
    },
    [getOtherSpacePublicDataAndPatch, openCapacityDialog]
  );
};
