import type { FC, ReactNode } from 'react';
import { Fragment, useState } from 'react';

export interface TabPanelProps {
  width?: number;
  main: (onChangeTab: (id: string) => void) => ReactNode;
  tabs: {
    id: string;
    content: (back: () => void) => ReactNode;
  }[];
}

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { width = 370, tabs, main } = props;
  const offset = -width;
  const [x, setX] = useState(0);
  const [panel, setPanel] = useState<string>(tabs[0]?.id || '');

  const onChangeTab = (panel: string) => {
    setPanel(panel);
    setX(offset);
  };

  return (
    <div className="sm:mx-3 sm:w-[calc(100vw-24px)] next-modal overflow-hidden" style={{ width }}>
      <div
        className="flex transition-transform ease-in-out"
        style={{ transform: `translateX(${x}px)` }}
      >
        <Tab width={width} active={x === 0}>
          {main(onChangeTab)}
        </Tab>
        <Tab width={width} active={x === offset}>
          {tabs.map((item) => {
            if (item.id !== panel) return null;
            return <Fragment key={item.id}>{item.content(() => setX(0))}</Fragment>;
          })}
        </Tab>
      </div>
    </div>
  );
};

interface Props {
  active: boolean;
  width: number;
}

const Tab: FC<Props> = ({ active, children, width }) => {
  return (
    <div className="flex-shrink-0" style={{ width }}>
      {active && children}
    </div>
  );
};
