import { CardColorSource, PermissionRole } from '@next-space/fe-api-idl';
import { sample } from 'lodash-es';
import { useEffect } from 'react';
import { ColorKey } from 'src/colors';
import { useCardColorSetting } from 'src/hooks/collection-view/use-collection-view';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { usePickBlock } from 'src/utils/pick-block';
import { COLORS } from '../const';

export const useCheckRecordColor = (viewId: string, recordId: string) => {
  const block = usePickBlock(recordId, ['data'], ['collectionCardColor']);
  const { type } = useCardColorSetting(viewId) ?? {};
  const transaction = useTransaction();

  useEffect(() => {
    if (!block) return;

    if (type === CardColorSource.CUSTOM) {
      const color = block.data.collectionCardColor;
      const colorKeys = COLORS.map((color) => color.key);

      if (!color || !(colorKeys as string[]).includes(color)) {
        const { role } = getPermissions(recordId);

        if (role === PermissionRole.WRITER || role === PermissionRole.EDITOR) {
          const collectionCardColor = sample(colorKeys) ?? ColorKey.grey;
          transaction(() => {
            updateBlock(recordId, { data: { collectionCardColor } });
          });
        }
      }
    }
  }, [block, recordId, transaction, type, viewId]);
};
