import { useObservableStore } from 'src/services/rxjs-redux/hook';
import type { PDFAnnotation } from '../../type';
import { sortAnnotations } from '../../utils';

export const useAnnotations = (uuid: string) => {
  const annotationMap = useObservableStore(
    ({ blocks }) => {
      const block = blocks[uuid];
      if (!block) return;

      const annotationMap: Record<string, PDFAnnotation[]> = {};

      block?.subNodes.forEach((uuid) => {
        const block = blocks[uuid];
        if (!block) return;

        const { pdfAnnotation, localUrl } = block.data;
        if (!pdfAnnotation) return;

        const annotation = { ...pdfAnnotation, localUrl, uuid } as unknown as PDFAnnotation;

        const { pageNumber } = pdfAnnotation;
        annotationMap[pageNumber] = [...(annotationMap[pageNumber] ?? []), annotation];
      });

      Object.keys(annotationMap).forEach((key) => {
        const annotations = annotationMap[key];
        if (!annotations) return;

        annotationMap[key] = sortAnnotations(annotations);
      });

      return annotationMap;
    },
    [uuid]
  );

  return annotationMap;
};
