import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { computeBlockWordLength, computeSegmentsWordLength } from 'src/utils/block-utils';

export const usePageTotalCount = (pageId: string) => {
  return useObservableStore(
    (state) => {
      const { blocks } = state;

      const page = blocks[pageId];

      if (!page) {
        return {
          wordCount: 0,
          blockCount: 0,
        };
      }

      const titleWord = computeSegmentsWordLength(page.data.segments);

      const result = page.subNodes.reduce(
        (pre, cur) => {
          const block = blocks[cur];
          if (!block) return pre;
          const compute = computeBlockWordLength(cur, blocks, {});
          pre.blockCount += compute.blocksNum;
          pre.wordCount += compute.word;
          return pre;
        },
        {
          wordCount: 0,
          blockCount: 0,
        }
      );

      return {
        // 页面标题字数+subnode字数
        wordCount: titleWord + result.wordCount,
        blockCount: result.blockCount,
      };
    },
    [pageId],
    {
      wait: 3000,
    }
  );
};
