import { useState, useEffect, useRef, forwardRef, useImperativeHandle, type FC } from 'react';
import type { EChartsType } from 'echarts';
import { init, registerTheme } from 'echarts';
import { throttle } from 'lodash-es';
import { globalResizeObserver } from '@flowus/common';
import { ChartTheme } from './theme';

interface Props {
  className?: string;
  theme?: string;
}
Object.keys(ChartTheme).forEach((k) => {
  registerTheme(k, ChartTheme[k]!.theme);
});
/**echart简单封装 */
export const ChartView = forwardRef<EChartsType, Props>((props, ref) => {
  const divRef = useRef<HTMLDivElement>(null);
  const echartsRef = useRef<EChartsType>();
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    //如果宽高变了就需要resize
    if (!divRef.current) return;

    const resize = throttle(() => {
      echartsRef.current?.resize();
    }, 100);
    const unObserve = globalResizeObserver.observe(divRef.current, () => {
      resize();
    });
    resize();
    return () => {
      unObserve();
    };
  }, []);

  useImperativeHandle(
    ref,
    () => echartsRef.current!,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [update]
  );
  useEffect(() => {
    const instance = init(divRef.current, props.theme);
    echartsRef.current = instance;
    setUpdate((v) => ++v);
    return () => {
      instance.dispose();
    };
  }, [props.theme, setUpdate]);
  return <div id="chart-container" ref={divRef} className="w-full h-full text-left" />;
});
