import type { DaySetting } from '@next-space/fe-api-idl';
import { createContext, useContext } from 'react';

interface CalenderContext {
  startDay: DaySetting;
  embed: boolean;
  calenderDates: number[];
  calenderDatesRef: React.MutableRefObject<number[]>;
  setCalenderDates: React.Dispatch<React.SetStateAction<number[]>>;
  container: React.MutableRefObject<HTMLDivElement | null>;
  calenderHeader: React.MutableRefObject<HTMLDivElement | null>;
}

export const CalenderContext = createContext<CalenderContext | undefined>(undefined);

export const useCalender = () => {
  const context = useContext(CalenderContext);
  if (!context) {
    throw new Error('useBitable');
  }
  return context;
};
