import { BlockType } from '@next-space/fe-api-idl';
import type { ElementType } from 'react';
import { usePickBlock } from 'src/utils/pick-block';
import { BookmarkNode } from './bookmark-node';
import { BulletListNode } from './bullet-list-node';
import { CalloutNode } from './callout-node';
import { CodeNode } from './code-node';
import { EmbedNode } from './embed-node';
import { EquationNode } from './equation-node';
import { FileNode } from './file-node';
import { FreezeNode } from './freeze-node';
import { ImageNode } from './image-node';
import { OrderListNode } from './order-list-node';
import { PageNode } from './page-node';
import { QuoteNode } from './quote-node';
import { ReferenceNode } from './reference-node';
import { RootNode } from './root-node';
import { TableNode } from './table-node';
import { TextNode } from './text-node';
import { TodoNode } from './todo-node';

export type MindNodeElement = ElementType<
  JSX.IntrinsicElements['div'] & { id: string; level: number; ownerBlockId?: string }
>;

const renderers = new Map<BlockType, MindNodeElement>();

let defaultElement: MindNodeElement;
export const setDefaultRenderer = (element: MindNodeElement) => {
  defaultElement = element;
};
export const setRenderer = (type: BlockType, element: MindNodeElement) => {
  renderers.set(type, element);
};

const UnSupported: MindNodeElement = (props) => {
  const block = usePickBlock(props.id, []);
  // 非开发环境不显示未支持
  if (!__HOST_LOCAL__) return null;

  return (
    <div className="text-grey4 p-2">
      未支持的块类型 <sup>[{block?.type}]</sup>
    </div>
  );
};
const Nothing = [BlockType.DIVIDER, BlockType.PDF_ANNOTATION];

export const getNodeElement = (type: BlockType | undefined, level: number) => {
  if (type === undefined) return UnSupported;
  if (level === 0) return RootNode;
  if (Nothing.includes(type)) return null;
  const element = renderers.get(type);
  if (!element) return defaultElement;
  return element;
};

setDefaultRenderer(TextNode);
setRenderer(BlockType.FILE, FileNode);
setRenderer(BlockType.TABLE, TableNode);
setRenderer(BlockType.ORDER_LIST, OrderListNode);
setRenderer(BlockType.QUOTE, QuoteNode);
setRenderer(BlockType.LIST, BulletListNode);
setRenderer(BlockType.MARK, CalloutNode);
setRenderer(BlockType.BOOKMARK, BookmarkNode);
setRenderer(BlockType.EQUATION, EquationNode);
setRenderer(BlockType.CODE, CodeNode);
setRenderer(BlockType.PAGE, PageNode);
setRenderer(BlockType.COLLECTION_VIEW_PAGE, PageNode);
setRenderer(BlockType.COLLECTION_VIEW, PageNode);
setRenderer(BlockType.REFERENCE_COLLECTION_PAGE, ReferenceNode);
setRenderer(BlockType.MIND_MAPPING, PageNode);
setRenderer(BlockType.MIND_MAPPING_PAGE, PageNode);
setRenderer(BlockType.FOLDER, PageNode);
setRenderer(BlockType.REFERENCE, ReferenceNode);
setRenderer(BlockType.REFERENCE_COLLECTION, ReferenceNode);
setRenderer(BlockType.EXTERNAL_FILE, ImageNode);
setRenderer(BlockType.EMBED, EmbedNode);
setRenderer(BlockType.TODO, TodoNode);
// freeze
setRenderer(BlockType.SYNC_REFERENCE, FreezeNode);
setRenderer(BlockType.SYNC_CONTAINER, FreezeNode);
setRenderer(BlockType.COLUMN, FreezeNode);
setRenderer(BlockType.COLUMN_LIST, FreezeNode);
