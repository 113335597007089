import Color from 'color';
import download from 'downloadjs';
import { degrees, PDFDocument, rgb } from 'pdf-lib';
import { message } from 'src/common/components/message';

import type { PDFAnnotation } from '../type';
import { AnnotationType } from '../type';

export const downloadPdf = async (
  url: string,
  name: string,
  pagesRotation: number,
  allAnnotations: Record<string, PDFAnnotation>
) => {
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  try {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();

    Object.values(allAnnotations).forEach(({ pageNumber, type, pdfRects, color, path }) => {
      const page = pages[Number(pageNumber) - 1];
      if (!page) return;

      const [red, green, blue] = Color(color).rgb().array();
      if (
        typeof red === 'undefined' ||
        typeof green === 'undefined' ||
        typeof blue === 'undefined'
      ) {
        return;
      }

      const renderColor = rgb(red / 255, green / 255, blue / 255);

      if (
        type === AnnotationType.HIGHLIGHT ||
        type === AnnotationType.UNDERLINE ||
        type === AnnotationType.RECTANGLE ||
        type === AnnotationType.STRIKETHROUGH
      ) {
        if (!pdfRects) return;
        Object.keys(pdfRects).forEach((pageNumber) => {
          const page = pages[Number(pageNumber) - 1];
          const allRects = pdfRects[pageNumber];
          if (!page || !allRects) return;

          allRects.forEach((rect) => {
            page.drawRectangle({
              x: rect.x,
              y: rect.y,
              width: rect.xMax - rect.x,
              height: rect.yMax - rect.y,
              color: renderColor,
              opacity: 0.4,
              borderWidth: 0,
              borderOpacity: 0,
            });
          });
        });
      } else if (type === AnnotationType.ELLIPSE) {
        const rect = pdfRects?.[pageNumber]?.[0];
        if (!rect) return;
        path = [
          { x: rect.x, y: rect.y },
          { x: rect.xMax, y: rect.yMax },
        ];
        if (!path) return;

        const [point1, point2] = path;
        if (!point1 || !point2) return;

        const width = Math.abs(point1.x - point2.x);
        const height = Math.abs(point1.y - point2.y);

        page.drawEllipse({
          x: Math.min(point1.x, point2.x) + width / 2,
          y: Math.min(point1.y, point2.y) + height / 2,
          xScale: width / 2,
          yScale: height / 2,
          color: renderColor,
          opacity: 0.4,
          borderOpacity: 0,
        });
      } else {
        if (!path) return;

        const pageHeight = page.getHeight();
        let svgPath = '';
        path.forEach((point, index) => {
          if (index === 0) {
            svgPath += `M ${point.x},${pageHeight - point.y}`;
          } else {
            svgPath += ` L ${point.x},${pageHeight - point.y}`;
          }
        });

        page.moveTo(0, page.getHeight());
        page.drawSvgPath(svgPath, { color: renderColor, opacity: 0.4 });
      }
    });

    if (pagesRotation !== 0) {
      pages.forEach((page) => {
        page.setRotation(degrees(pagesRotation));
      });
    }

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, name, 'application/pdf');
  } catch (error) {
    message.warning('下载失败，暂不支持加密文档！');
  }
};
