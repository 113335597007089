import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { getDateTimeStamp, ONE_MINUTES, ONE_MOMENT } from 'src/utils/date-utils';

import { PADDING_TOP, UNIT_HEIGHT } from './const';
import { TIME_FORMAT } from 'src/common/const';

interface Props {
  date: number;
  unitHeight?: number;
}

const getTopValue = (unitHeight: number) => {
  const minutes = new Date().setSeconds(0, 0) - getDateTimeStamp(Date.now());
  return (minutes / ONE_MOMENT) * unitHeight + PADDING_TOP;
};

export const TodayCursor: FC<Props> = ({ date, unitHeight = UNIT_HEIGHT }) => {
  const [top, setTop] = useState(getTopValue(unitHeight));
  const timer = useRef<Timeout>();
  const cursorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const today = getDateTimeStamp(Date.now());

  useEffect(() => {
    timer.current = setInterval(() => {
      setTop(getTopValue(unitHeight));
    }, ONE_MINUTES);

    return () => {
      if (timer.current) clearInterval(timer.current);
    };
  }, [unitHeight]);

  useEffect(() => {
    const cursor = cursorRef.current;
    const container = containerRef.current;
    if (!cursor || !container) return;

    cursor.style.left = '-5px';
  }, [date, today]);

  if (date !== today) return null;

  return (
    <div
      className="absolute right-0 border-t border-red bg-white"
      style={{ top, left: 1 }}
      ref={containerRef}
    >
      <span className="absolute -ml-[30px] -translate-x-full -translate-y-1/2 text-xs text-red bg-white1">
        {dayjs(new Date()).format(TIME_FORMAT)}
      </span>
      <span
        ref={cursorRef}
        className="absolute overflow-hidden rounded-full bg-red p-1 text-red"
        style={{ top: -4 }}
      />
    </div>
  );
};
