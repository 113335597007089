import type { BlockDTO, CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { getRecordBlocks } from 'src/common/utils/fetch-collection';
import { segmentsToText } from 'src/editor/utils/editor';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import {
  ONE_STAGE_CATEGORY,
  TEMPLATE_DESC,
  TEMPLATE_IMAGE,
  TEMPLATE_NAME,
  TEMPLATE_RELEASE,
  TEMPLATE_SOURCE,
  TEMPLATE_SOURCE_IMAGE,
  TEMPLATE_URL,
  THREE_STAGE_CATEGORY,
  TWO_STAGE_CATEGORY,
} from './const';
import type { Template, TemplateCategory } from './types';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

/**
 * 模板地址可参考https://test.allflow.cn/cb5d5742-96ca-4e1e-918f-8ac2485a1302
 * 实现细节：把多维表的数据拉下来，再转换成模板中心需要的数据
 */
export const useFetchTemplates = () => {
  return useCallback(async () => {
    const res = await request.editor.getDoc.raw(import.meta.env.VITE_TEMPLATE_ID);
    if (res.code !== 200) {
      message.error('模板中心加载失败[101]');
      return;
    }

    const collection = res.data.blocks[import.meta.env.VITE_TEMPLATE_ID];
    if (!collection || !collection.data.schema) {
      message.error('模板中心加载失败[102]');
      return;
    }

    const viewId = collection.views?.[0];
    if (!viewId) {
      message.error('模板中心加载失败[103]');
      return;
    }

    // 更新到redux上
    const { blocks, collectionViews } = res.data;
    if (collectionViews) {
      dispatch(collectionViewsActions.update(collectionViews));
    }
    dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));

    // 只有勾选了上线的才展示
    const recordBlocks = getRecordBlocks(res.data, import.meta.env.VITE_TEMPLATE_ID, viewId).filter(
      (item) => {
        return formatCheckBoxValue(getPropertyValue(item, TEMPLATE_RELEASE));
      }
    );

    return covertToTemplateCategories(recordBlocks);
  }, []);
};

const KOL_USER_COLLECTION_ID = '7eb46e2d-2747-4dd7-8e60-491687c92f93';
const COLUMN_SHOW_AD = '展示广告';
const COLUMN_USER_ID = '用户id';

export const kolUserIds = new Set<string>();

export const fetchKolUserIds = async () => {
  if (__PRIVATE__ || __BUILD_IN__) {
    return;
  }
  const res = await request.editor.getDoc.raw(KOL_USER_COLLECTION_ID);
  if (res.code !== 200) {
    // eslint-disable-next-line no-console
    console.warn('广告表格加载失败[101]');
    return;
  }
  const { blocks } = res.data;
  dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));

  const collection = res.data.blocks[KOL_USER_COLLECTION_ID];
  if (!collection || !collection.data.schema) {
    // eslint-disable-next-line no-console
    console.warn('广告表格加载失败[102]');
    return;
  }

  const viewId = collection.views?.[0];
  if (!viewId) {
    // eslint-disable-next-line no-console
    console.warn('广告表格加载失败[103]');
    return;
  }
  const recordBlocks = getRecordBlocks(res.data, KOL_USER_COLLECTION_ID, viewId).filter((item) => {
    return formatCheckBoxValue(getPropertyValue(item, COLUMN_SHOW_AD));
  });
  kolUserIds.clear();
  recordBlocks.forEach((block) => {
    const userId = getPropertyValue(block, COLUMN_USER_ID).trim();
    kolUserIds.add(userId);
  });
  return kolUserIds;
};

// type ReturnTypeInPromise<T extends (...args: any) => any> = T extends (
//   ...args: any
// ) => Promise<infer R>
//   ? R
//   : any;

export const getPropertyValue = (block: string | BlockDTO | NextBlock, propertyName: string) => {
  let blockData: BlockDTO | NextBlock | undefined = undefined;
  if (typeof block === 'string') {
    const { blocks } = getState();
    blockData = blocks[block];
  } else {
    blockData = block;
  }

  if (!blockData) return '';

  const propertyMapping = getPropertyMapping(blockData.parentId);
  const property = propertyMapping[propertyName];
  const id = property?.id;
  if (!id) return '';

  switch (property.schema.type) {
    case CollectionSchemaType.TITLE:
      return segmentsToText(blockData.data.segments);

    case CollectionSchemaType.FILE:
    case CollectionSchemaType.URL: {
      const segments = blockData.data.collectionProperties?.[id];
      return readUrlFromSegments(segments);
    }
    case CollectionSchemaType.CHECKBOX: {
      return formatCheckBoxValue(segmentsToText(blockData.data.collectionProperties?.[id]))
        ? 'YES'
        : 'NO';
    }

    default:
      return segmentsToText(blockData.data.collectionProperties?.[id]);
  }
};

export const covertToTemplateCategories = (items: BlockDTO[]) => {
  const categories: TemplateCategory[] = [];
  const categoryObj: Record<string, TemplateCategory> = {};

  items.forEach((block) => {
    const oneStateName = getPropertyValue(block, ONE_STAGE_CATEGORY);
    if (!oneStateName) return;

    const twoStateName = getPropertyValue(block, TWO_STAGE_CATEGORY);
    if (!twoStateName) return;

    const threeStateName = getPropertyValue(block, THREE_STAGE_CATEGORY);
    const template = getTemplateData(block);
    if (!template) return;

    let firstCategory = categoryObj[oneStateName];
    if (!firstCategory) {
      firstCategory = {
        name: oneStateName,
        subGroups: [],
      };
      categories.push(firstCategory);
      categoryObj[oneStateName] = firstCategory;
    }

    const secondKey = oneStateName + twoStateName;
    let secondaryCategory = categoryObj[secondKey];
    if (!secondaryCategory) {
      secondaryCategory = {
        name: twoStateName,
        subGroups: [],
      };
      firstCategory.subGroups?.push(secondaryCategory);
      categoryObj[secondKey] = secondaryCategory;
    }

    if (!threeStateName) {
      secondaryCategory.templates = [...(secondaryCategory.templates ?? [])].concat(template);
      return;
    }

    const thirdKey = oneStateName + twoStateName + threeStateName;
    let thirdCategory = categoryObj[thirdKey];
    if (!thirdCategory) {
      thirdCategory = {
        name: threeStateName,
        templates: [],
      };
      secondaryCategory.subGroups?.push(thirdCategory);
      categoryObj[thirdKey] = thirdCategory;
    }
    thirdCategory.templates = [...(thirdCategory.templates ?? [])].concat(template);
  });

  const { blocks } = getState();
  const propertyMapping = getPropertyMapping(import.meta.env.VITE_TEMPLATE_ID);
  const collection = blocks[import.meta.env.VITE_TEMPLATE_ID];
  if (!collection) return;
  const { schema: firstSchema } = propertyMapping[ONE_STAGE_CATEGORY] ?? {};
  const { schema: secondSchema } = propertyMapping[TWO_STAGE_CATEGORY] ?? {};
  const { schema: thirdSchema } = propertyMapping[THREE_STAGE_CATEGORY] ?? {};
  if (!firstSchema?.options || !secondSchema?.options || !thirdSchema?.options) return;

  const categoryValueMap: Record<string, number> = {};
  firstSchema.options.forEach(({ value }, index) => (categoryValueMap[`1${value}`] = index));
  secondSchema.options.forEach(({ value }, index) => (categoryValueMap[`2${value}`] = index));
  thirdSchema.options.forEach(({ value }, index) => (categoryValueMap[`3${value}`] = index));

  const loop = (categories: TemplateCategory[], level = 1) => {
    categories.forEach((item) => {
      if (item.subGroups) {
        loop(item.subGroups ?? [], level + 1);
      }
    });

    categories.sort((a, b) => {
      return (categoryValueMap[level + a.name] ?? 0) - (categoryValueMap[level + b.name] ?? 0);
    });
  };

  loop(categories);

  return categories;
};

export const getTemplateData = (item: BlockDTO | NextBlock | string): Template | undefined => {
  let blockData: BlockDTO | NextBlock | undefined = undefined;
  if (typeof item === 'string') {
    const { blocks } = getState();
    blockData = blocks[item];
  } else {
    blockData = item;
  }

  if (!blockData) return;

  const url = getPropertyValue(blockData, TEMPLATE_URL);
  const { pathname } = new URL(url);
  const id = pathname.trim().substring(pathname.lastIndexOf('/') + 1);

  return {
    id,
    pageId: blockData.uuid,
    name: getPropertyValue(blockData, TEMPLATE_NAME),
    source: getPropertyValue(blockData, TEMPLATE_SOURCE),
    sourceImage: getPropertyValue(blockData, TEMPLATE_SOURCE_IMAGE),
    desc: getPropertyValue(blockData, TEMPLATE_DESC),
    image: getPropertyValue(blockData, TEMPLATE_IMAGE),
  };
};

const getPropertyMapping = (uuid: string) => {
  const propertyMapping: Record<string, { id: string; schema: CollectionSchema }> = {};

  const { blocks } = getState();
  const collection = blocks[uuid];
  if (!collection) return propertyMapping;

  Object.entries(collection.data.schema ?? {}).forEach(([id, schema]) => {
    propertyMapping[schema.name] = { id, schema };
  });

  return propertyMapping;
};
