import { useLayoutEffect } from 'react';
import { useFetchPage } from 'src/hooks/page';
import { cache } from 'src/redux/store';
import { $doneLoadPageCache } from 'src/services/app-support';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { isPageLike } from 'src/utils/block-type-utils';
import { getFirstTextBlockId } from 'src/utils/block-utils';
import { usePickBlock } from 'src/utils/pick-block';

export const useFetchFirstTextBlock = (pageId: string, needFetch: boolean) => {
  const fetchPage = useFetchPage();
  const firstTextBlockId = useObservableStore(
    (state) => {
      return getFirstTextBlockId(pageId, state.blocks);
    },
    [pageId]
  );

  const firstTextBlockSegments = usePickBlock(firstTextBlockId, ['data'])?.data.segments;

  useLayoutEffect(() => {
    // 如果已经有数据就不再拉了，减少请求(可能会更新不及时，但问题不大)
    if (!checkAlreadyFetch(pageId) && needFetch) {
      void fetchPage(pageId);
    }
  }, [fetchPage, pageId, needFetch]);
  return firstTextBlockSegments;
};

// 检查这个id内容是否已经有了，有的话不再拉数据
const checkAlreadyFetch = (id: string) => {
  const check = $doneLoadPageCache[id];

  if (check) {
    return true;
  }

  const block = cache.blocks[id];
  return block?.subNodes.some((id) => {
    const subBlock = cache.blocks[id];
    if (subBlock && !isPageLike(subBlock?.type)) return true;
    return false;
  });
};
