import { getActivityState } from 'src/services/activities/hook';
import { $activitiesState } from 'src/services/activities/state';
import type { ActivityDetail } from 'src/services/activities/type';

export enum ActivitiesListType {
  'activityList' = 'activityList',
  'advancedList' = 'advancedList',
  'basicList' = 'basicList',
}

export const useActivity = (activityId: string) => {
  return $activitiesState((state) => {
    return state.basicList
      .concat(state.advancedList)
      .concat(state.activityList)
      .find((it) => it.uuid === activityId);
  });
};

export const getActivity = (activityId: string) => {
  const allActivity = getActivityState();
  return allActivity.basicList
    .concat(allActivity.advancedList)
    .concat(allActivity.activityList)
    .find((it) => it.uuid === activityId);
};

export const useNewUserTask = () => {
  return $activitiesState(getNewUserTaskInfo);
};

export const getNewUserTaskInfo = (activities: {
  activityList: ActivityDetail[];
  basicList: ActivityDetail[];
  advancedList: ActivityDetail[];
  otherList: Record<string, ActivityDetail>;
}) => {
  const { basicList, advancedList } = activities;

  const completedBasicTask = basicList.filter((item) => item.status === 'completed');
  const completedAdvancedTask = advancedList.filter((item) => item.status === 'completed');
  const completedTaskLength = completedBasicTask.length + completedAdvancedTask.length;
  const allTaskLength = basicList.length + advancedList.length;

  return {
    basicList,
    advancedList,
    completedTaskLength,
    allTaskLength,
    progress: completedTaskLength / allTaskLength,
  };
};
