import { BlockType } from '@next-space/fe-api-idl';
import { newContent, newElement } from '@next-space/fe-inlined';
import type { FC } from 'react';
import { useMemo } from 'react';
import { message } from 'src/common/components/message';
import { SearchMenu } from 'src/components/search';
import { INLINE_LINK_PAGE_TAG } from 'src/editor/editor/inline/const';
import { textToSegments } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import type { NextBlock } from 'src/redux/types';
import { getDefaultSearchList } from 'src/utils/block-utils';
import type { InlinePanelProps } from '../types';

const filterTypes = [BlockType.FOLDER, BlockType.FILE];

export const AddNewPageIn: FC<InlinePanelProps & { title?: string }> = ({
  onCloseModal,
  blockId,
  left,
  title,
  setFakeFocus,
  getEditorModel,
}) => {
  const updateTask = useUpdateTask();
  const transaction = useTransaction();

  const defaultItems = useMemo(() => {
    return getDefaultSearchList({
      ancestorId: getCurrentSpaceId(),
      filterTypes,
    });
  }, []);

  return (
    <SearchMenu
      defaultValue={defaultItems}
      onSelect={(target) => {
        setFakeFocus?.(false);

        if (filterTypes.includes(target.type)) {
          message.warning('不能在文件夹内创建页面');
          onCloseModal();
          return;
        }

        const editorModel = getEditorModel(blockId);
        if (!editorModel) return;

        const block: Partial<NextBlock> = { type: BlockType.PAGE };

        if (title) {
          block.data = { segments: textToSegments(title) };
        }

        transaction(
          () => {
            // TODO: 如果 在页面的最后一个块里，并且选择的 target 是当前页面，应该直接创建 行内块
            const pageId = addBlock(block, { parentId: target.uuid });

            editorModel.performChange((ctx) => {
              if (!editorModel.selection) return;
              ctx
                .shadow()
                .select(left, editorModel.selection.focusOffset)
                .replace(newContent([newElement(INLINE_LINK_PAGE_TAG, { pageId })]));
            });

            void editorModel.requestFocus();
          },
          { noThrottle: true }
        );

        onCloseModal();

        // 积分任务
        void updateTask(ActivityIds.GUIDE_FIRST_TIME_LINK_PAGE, ActivitiesListType.advancedList);
        void updateTask(ActivityIds.CREATE_REF_PAGE, ActivitiesListType.advancedList);
      }}
    />
  );
};
