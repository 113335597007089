import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { TransitionBox } from 'src/common/components/transition-box';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useCaptionStyle } from 'src/hooks/block/use-align-style';
import { CAPTION_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { usePickBlock } from 'src/utils/pick-block';
import { Editable } from '../../editor/uikit/editable';
import { segmentsToText } from '../../utils/editor';

interface Props {
  blockId: string;
  style?: CSSProperties;
  className?: string;
  placeholder?: string;
}
/** 说明块(不是block)  这块逻辑是当点击到别处的时候，如果caption为空则让其消失，不能简单的用blur实现，会有bug*/
export const Caption: FC<Props> = memo((props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const block = usePickBlock(props.blockId, ['data'], ['showEmptyCaption', 'caption', 'format']);

  // 开关
  const showSwitch = block?.data.format?.isShowCaption ?? true;
  const showCaption =
    !!(block?.data.showEmptyCaption || Boolean(segmentsToText(block?.data.caption))) && showSwitch;

  const captionGravityStyle = useCaptionStyle(props.blockId);

  const getEditorModel = useGetOrInitEditorModel();

  useEffect(() => {
    const hasCaptionAndShow = Boolean(
      segmentsToText(cache.blocks[props.blockId]?.data.caption) &&
        (cache.blocks[props.blockId]?.data.format?.isShowCaption ?? true)
    );
    if (hasCaptionAndShow) {
      updateBlock(
        props.blockId,
        {
          data: {
            showEmptyCaption: true,
          },
        },
        true
      );
    }
  }, [props.blockId]);

  useEffect(() => {
    const onClick = () => {
      const editorModel = getEditorModel(props.blockId);
      if (!editorModel) return;
      const hasCaption = Boolean(segmentsToText(cache.blocks[props.blockId]?.data.caption));
      if (!editorModel.hasFocus && !hasCaption) {
        updateBlock(
          props.blockId,
          {
            data: {
              showEmptyCaption: false,
            },
          },
          true
        );
      }
    };
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [getEditorModel, props.blockId]);

  if (!showCaption || !block) return null;
  return (
    <TransitionBox
      domRef={divRef}
      className={cx(
        'cursor-text relative rounded w-full my-px flex items-center min-h-[36px] max-w-full',
        props.className
      )}
      data-ignore-draggable
      style={{ ...props.style, ...captionGravityStyle }}
    >
      <Editable
        uuid={block.uuid}
        className="text-t2 text-grey3 w-full"
        placeholder={props.placeholder ?? '添加说明'}
        segmentType="caption"
        keydownOption={CAPTION_KEYDOWN_OPTION}
      />
    </TransitionBox>
  );
});
