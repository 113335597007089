import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { transaction } from '../use-transaction';

export const updateCollectionView = (viewId: string, patch: Partial<CollectionViewDTO>) => {
  transaction(() => {
    CollectionViewManager.update(viewId, patch);
  });
};

export const updateViewFormat = (
  viewId: string,
  patch: Partial<CollectionViewDTO['format']>,
  ignoreOp = false
) => {
  // TODO：cloneDeep 基本上可以用 immer.produce 替代
  transaction(() => {
    CollectionViewManager.update(viewId, { format: patch }, ignoreOp);
  });
};
