import { segmentsToText } from 'src/editor/utils/editor';
import type { NextBlock } from 'src/redux/types';
import { getUntitledName } from 'src/utils/get-untitled-name';

import type { SearchItem } from './index';

export const covertNextBlockToSearchItem = (nextBlock?: NextBlock): SearchItem | null => {
  if (!nextBlock) {
    return null;
  }
  const { type, uuid, data, backgroundColor } = nextBlock;

  return {
    backgroundColor,
    type,
    uuid,
    data,
    title: segmentsToText(nextBlock.data.segments ?? []) || getUntitledName(type),
    parentId: nextBlock.parentId,
  };
};
