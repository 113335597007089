import './style.css';

import { useCalendarType } from 'src/hooks/collection-view/use-collection-view';

import { useBitable } from '../context';
import { CalendarDayView } from './day-view';
import { CalendarMonthView } from './month-view';
import type { FC } from 'react';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';

export const CalendarView: FC<CollectionViewProps> = () => {
  const { viewId } = useBitable();
  const calendarType = useCalendarType(viewId);

  if (calendarType === 'month') {
    return <CalendarMonthView />;
  }
  return <CalendarDayView />;
};
