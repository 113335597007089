import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { useSpace } from 'src/hooks/space';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { Images } from 'src/image';
import { ViewPath } from 'src/utils';
import { isFlowUsApp } from 'src/utils/electron-util';
import { isEmbedTemplate, judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { getLocationOrigin, locationToUrl } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';

interface LogoProps {
  logoImage?: string;
  className?: string;
  description?: ReactNode;
  showSpace?: boolean;
}

export const Logo: FC<LogoProps> = ({
  logoImage,
  className = '',
  description,
  showSpace = true,
}) => {
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  const space = useSpace(block?.spaceId);
  const isSharePage = judgeSharePage();
  const isProSpace = useIsProSpace(block?.spaceId);

  const isDarkMode = useIsDarkMode();
  if (!logoImage) {
    logoImage = isDarkMode ? Images.logoWhiteWide : Images.logoBlackWide;
  }

  const content = useMemo(() => {
    if (isProSpace && isSharePage && space.uuid && showSpace) {
      return (
        <div className="flex text-t2">
          <Avatar
            name={space.title}
            color={space.backgroundColor}
            icon={space.icon}
            iconSize={18}
          />
        </div>
      );
    }

    return (
      <img
        src={logoImage}
        alt="logo"
        className={cx('h-[18px]', {
          'h-7': __BUILD_IN__,
          'w-10 h-full': __PRIVATE__,
        })}
      />
    );
  }, [
    isProSpace,
    isSharePage,
    logoImage,
    showSpace,
    space.backgroundColor,
    space.icon,
    space.title,
    space.uuid,
  ]);

  return (
    <a
      href={isEmbedTemplate || isFlowUsApp.check ? 'javascript:void(0);' : getLocationOrigin()}
      className={cx('flex-shrink-0 flex items-center mr-2 animate-click', className)}
      onClick={() => {
        if (!isFlowUsApp.check) return;
        locationToUrl(ViewPath.main);
      }}
    >
      {content}
      {description}
    </a>
  );
};
