import type { FC } from 'react';

/** loading展示 */
export const SearchLoading: FC<{}> = () => {
  return (
    <div className="text-t2 text-grey4 flex justify-center w-full h-10 items-center">
      正在搜索...
    </div>
  );
};
