import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { first, last } from 'lodash-es';
import type { MouseEvent } from 'react';
import { Button } from 'src/common/components/button';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { COLOR_PICKER_DATA_TEXT_COLOR } from 'src/components/color-picker/default-data';
import { useCopyBlock } from 'src/hooks/block/use-copy-block';
import { ARROW_KEY_OPTION } from 'src/hooks/editor/config';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useShortcutKey } from 'src/hooks/editor/use-shortcut-key';
import { useReadonly } from 'src/hooks/page';
import { addBlock } from 'src/redux/managers/block/add';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { $searchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { v4 as uuidV4 } from 'uuid';
import { ColorPickerModule } from '../../component/color-picker-module';
import { segmentsToText } from '../../utils/editor';
import type { BlockElement } from '../core/type';
import { BlockBefore } from '../uikit/block-before';
import { Editable, RichTextEdit } from '../uikit/editable';
import { useOpenBlockRightMenuList } from '../uikit/hover-menu/block-menu-list/hook';
import { BlockDrop } from './dnd/block-drop';

export const TemplateElement: BlockElement = ({ id, root, children }) => {
  const [expand, setExpand] = useExpand(id);
  const shortcutKey = useShortcutKey(id);
  const block = usePickBlock(id, ['subNodes', 'data'], ['segments']);
  const copyBlock = useCopyBlock();
  const openModal = useOpenModal();
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  const hasChildren = Array.isArray(children) && children.length > 0;
  const readonly = useReadonly(id);
  const focusEditableAt = useFocusEditableByBlockId();
  const editorId = useFinalValue(uuidV4);

  if (!block || $searchParams.print) return null;

  const toggle = (status = !expand) => setExpand(status, false);

  const openColorPicker = (event: MouseEvent<HTMLDivElement>) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ColorPickerModule
          items={[COLOR_PICKER_DATA_TEXT_COLOR]}
          blockId={id}
          onCloseModal={onCloseModal}
          disabledHasRecently
          onColorSelect={(colorInfo) => {
            bizTracker.event('tempbutton_changecolor', { color: colorInfo.desc });
          }}
        />
      ),
    });
  };

  const handleClick = async (event: MouseEvent) => {
    event.stopPropagation();
    if (!block.subNodes.length || readonly) return;
    const parentBlock = cache.blocks[block.parentId];
    const blockIndex = parentBlock?.subNodes.findIndex((subId) => subId === id) ?? 0;
    const beforeBlockId = parentBlock?.subNodes[blockIndex - 1];
    // 当前块是最后一个，并且前面还有块。就往前插入
    const appendBefore =
      last(parentBlock?.subNodes) === id && first(parentBlock?.subNodes) !== id && beforeBlockId;

    const newBlocks = await copyBlock(block.subNodes, {
      after: appendBefore ? beforeBlockId : id,
      prefixName: false,
    });

    const firstBlock = newBlocks[0]?.block;
    if (firstBlock) {
      focusEditableAt(firstBlock.uuid, segmentsToText(firstBlock.data.segments).length);
    }
    bizTracker.event('click_tempbutton');
  };

  const emptyCreateBlock = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const newBlockId = addBlock({ type: BlockType.TEXTAREA }, { parentId: id });
    focusEditableAt(newBlockId, 0);
  };

  return (
    <BlockDrop id={id} className="my-px" horizontal={root}>
      <div
        className={cx(
          'block-has-icon-container py-1.5 animate-hover items-center px-0.5 group pr-2',
          readonly && 'cursor-default'
        )}
        onClick={handleClick}
      >
        <BlockBefore className="mr-1.5">
          <Icon size="middle" name="IcAddBoard" />
        </BlockBefore>
        <span className="text-ellipsis w-full text-t1 pr-1">
          <RichTextEdit
            uuid={editorId}
            className="text-ellipsis"
            segments={block.data.segments}
            readonly
            placeholder="请填写模板按钮名称"
          />
        </span>
        {!readonly && (
          <span
            className="flex items-center group-hover:opacity-100 opacity-0 transition-opacity"
            onClick={(e) => e.stopPropagation()}
          >
            <Tooltip popup="编辑" className="animate-hover" onClick={() => toggle(true)}>
              <Icon name="MIcListSeetings" size="middle" />
            </Tooltip>
            <Tooltip
              popup="菜单"
              className="ml-2 animate-hover text-grey3"
              onClick={(event) => {
                openBlockRightMenuList({
                  blockId: id,
                  offset: [8, 0],
                  blockNode: event.currentTarget as HTMLElement,
                });
              }}
            >
              <Icon name="IcMore" size="middle" />
            </Tooltip>
          </span>
        )}
      </div>
      {/* 编辑模板内容 */}
      {expand && (
        <div className="border border-grey6 rounded text-t2 bg-white1">
          <div className="h-12 flex items-center justify-between px-3 text-black">
            <span>编辑模板按钮</span>
            <Button className="text-t2-medium" colorType="active" onClick={() => toggle()}>
              关闭
            </Button>
          </div>
          <Divider className="my-2.5" />
          <div>
            <div className="h-12 px-3 text-grey3 flex items-center justify-between">
              <span>模板按钮名称</span>
              <span className="flex items-center cursor-pointer" onClick={openColorPicker}>
                <Icon name="IcColor" size="middle" className="mr-1" />
                颜色
              </span>
            </div>
            <div className="mx-3 bg-grey8 rounded flex items-center py-1.5">
              <Editable
                uuid={id}
                toolbar={false}
                comments={false}
                className="break-normal overflow-y-auto cursor-text px-2 w-full min-h-[24px]"
                placeholder="请填写模板按钮名称"
                keydownOption={ARROW_KEY_OPTION}
                onKeyDown={(event) => {
                  if (isHotkey('mod+a')(event)) {
                    shortcutKey(event.nativeEvent);
                  }
                }}
              />
            </div>
            <Divider className="my-2.5" />
            <div className="h-12 px-3 text-grey3 flex items-center">模板内容</div>
            <div className="px-3 pb-5">
              <div className="bg-grey8 rounded p-1.5 text-black">
                {hasChildren ? (
                  children
                ) : (
                  <BlockDrop dropInChild bindId={false} id={id}>
                    <div className="flex items-center text-black" onClick={emptyCreateBlock}>
                      创建文字或’/‘命令，也可以拖拽块至此处
                    </div>
                  </BlockDrop>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </BlockDrop>
  );
};
