import { cx } from '@flowus/common/cx';
import {
  useMindMapEngine,
  useMindMapId,
  useMindMapNode,
  useMindMapSelector,
} from '@flowus/mind-map';
import type { MindMappingFormat } from '@next-space/fe-api-idl';
import { MindMappingType } from '@next-space/fe-api-idl';
import type { CSSProperties, FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useTransaction } from 'src/hooks/use-transaction';
import { useIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { bizTracker } from 'src/utils/biz-tracker';
import { useIsInFixWidthContext } from '../fix-width-context-provider';
import { useAddSubNode } from '../hook/use-add-sub-node';
import { useIsEmbed } from '../hook/use-is-embed';
import { useMindMapStyle } from '../hook/use-mind-map-style';
import { useToggleNode } from '../hook/use-toggle-node';
import { useSelectCount } from './collapse-count';
// 1个数字的宽度
const NUMBER_WIDTH = 5;
const numberWidthMap = new Map<number, number>();
for (let i = 0; i < 8; i++) {
  numberWidthMap.set(i, i * NUMBER_WIDTH);
}

const ICON_SIZE = 20; // icon大小
const LINE_LENGTH = 4; // 线的长度
interface Props {
  id: string;
  width?: number;
  height?: number;
  readonly: boolean;
}
// 节点
export const NodePoint: FC<Props> = (props) => {
  const isSelected = useIsSelected(props.id);
  const isEmbed = useIsEmbed();
  const engine = useMindMapEngine();
  const mindNode = useMindMapNode(props.id, ['childrenIds', 'expanded', 'dir']);
  const isDragging = useIsDragging();
  const inFixWidthContext = useIsInFixWidthContext();
  const showAddNodeBtn = useObservableStore(
    (state) => {
      const supportAddChildren = engine.option.supportAppendChild(props.id);
      return state.ui.selectedBlocks.length === 1 && !isDragging && supportAddChildren;
    },
    [],
    { hasSelectBlock: true }
  );

  const mindMapId = useMindMapId();
  const { mindMapStyle } = useMindMapStyle(mindMapId);
  const isHover = useMindMapSelector((state) => state.hoverId === props.id);
  const toggle = useToggleNode();
  const transaction = useTransaction();
  const addSubNode = useAddSubNode();
  const count = useSelectCount(props.id, mindMapId);
  const { width, height } = props;
  if (!mindNode || inFixWidthContext) return null;
  if (!width || !height) return null;

  const showHover = !isSelected && isHover && mindNode?.childrenIds.length !== 0;
  let content: React.ReactNode | undefined;
  let showCount = false;
  if (isSelected && showAddNodeBtn && !props.readonly) {
    content = (
      <Icon
        name={'IcAddMindmap'}
        className="rounded-full cursor-pointer bg-white1"
        size="middle"
        onClick={() => {
          if (!mindNode.expanded) {
            toggle(props.id);
          }
          transaction(() => {
            const newId = addSubNode(
              {
                parentId: props.id,
                last: true,
              },
              mindMapId
            );
            newId &&
              dispatch(
                uiActions.updateSelectBlocks([
                  {
                    blockId: newId,
                    mindMapId,
                  },
                ])
              );
          });
        }}
      />
    );
  } else if (!mindNode.expanded && mindNode.childrenIds.length > 0) {
    showCount = true;
    content = (
      <div
        className={cx(
          'text-center px-1.5 text-t2-medium text-black rounded-[10px] border-[1.6px] border-black',
          {
            'cursor-pointer': !isEmbed,
          }
        )}
        onClick={() => {
          if (isEmbed) return;
          toggle(props.id);
          bizTracker.event('node_fold', { from: 3 });
        }}
      >
        {count}
      </div>
    );
  } else if (showHover) {
    let deg = '0deg';
    if (mindMapStyle === MindMappingType.LOGIC_DIAGRAM_RIGHT) {
      deg = '180deg';
    } else if (mindMapStyle === MindMappingType.MIND_MAPPING) {
      if (mindNode.dir === 'right' || mindNode.dir === undefined) {
        deg = '0deg';
      } else {
        deg = '180deg';
      }
    } else if (mindMapStyle === MindMappingType.ORGANIZATION_STRUCTURE) {
      deg = '90deg';
    }
    content = (
      <Icon
        name={'IcNodeFold'}
        className={cx('rounded-full bg-white1')}
        size="middle"
        style={{
          transform: `rotate(${deg})`,
        }}
        onClick={() => {
          toggle(props.id);
          bizTracker.event('node_fold', { from: 3 });
        }}
      />
    );
  }
  if (!content) return null;
  if (isEmbed && !showCount) return null;
  const ContainerElement = getNodePointContainerElement(mindMapStyle);
  if (!ContainerElement) return null;
  return (
    <ContainerElement
      id={props.id}
      width={props.width}
      height={props.height}
      showCount={showCount}
      count={count}
    >
      {content}
    </ContainerElement>
  );
};
const getNodePointContainerElement = (mindMapStyle: MindMappingFormat['type']) => {
  switch (mindMapStyle) {
    case MindMappingType.LOGIC_DIAGRAM_LEFT: {
      return NodePointLogicDiagramLeft;
    }
    case MindMappingType.LOGIC_DIAGRAM_RIGHT: {
      return NodePointLogicDiagramRight;
    }
    case MindMappingType.MIND_MAPPING: {
      return NodePointDiagramMindMapping;
    }
    case MindMappingType.ORGANIZATION_STRUCTURE: {
      return NodePointOrganizationStructure;
    }
    default:
      return null;
  }
};

interface NodePointProps {
  id: string;
  width?: number;
  height?: number;
  showCount?: boolean;
  count?: number;
}
const NodePointLogicDiagramLeft: FC<NodePointProps> = (props) => {
  const { containerStyle } = useNodePointStyle(props);
  return (
    <div data-no-cancel-selected className="absolute" style={containerStyle}>
      <div className="bg-black h-[3px] w-[4px]"></div>
      {props.children}
    </div>
  );
};
const NodePointLogicDiagramRight: FC<NodePointProps> = (props) => {
  const { containerStyle } = useNodePointStyle(props);
  return (
    <div data-no-cancel-selected className="absolute" style={containerStyle}>
      {props.children}
      <div className="bg-black h-[3px] w-[4px]"></div>
    </div>
  );
};
const NodePointDiagramMindMapping: FC<NodePointProps> = (props) => {
  const mindNode = useMindMapNode(props.id, ['dir']);
  if (mindNode?.dir === undefined) {
    return <NodePointLogicDiagramLeft {...props} />;
  }
  if (mindNode.dir === 'right' || mindNode.dir === undefined) {
    return <NodePointLogicDiagramLeft {...props} />;
  }
  return <NodePointLogicDiagramRight {...props} />;
};
const NodePointOrganizationStructure: FC<NodePointProps> = (props) => {
  const { containerStyle } = useNodePointStyle(props);
  return (
    <div data-no-cancel-selected className="absolute" style={containerStyle}>
      <div className="bg-black h-[4px] w-[3px]"></div>
      {props.children}
    </div>
  );
};

const useNodePointStyle = (props: NodePointProps) => {
  const { id: nodeId, width, height } = props;
  const mindMapId = useMindMapId();
  const mindNode = useMindMapNode(nodeId, ['left', 'top', 'dir', 'width', 'height']);
  const { mindMapStyle } = useMindMapStyle(mindMapId);
  const style: CSSProperties = {};
  if (!mindNode) return { containerStyle: style };
  if (!width || !height) return { containerStyle: style };
  switch (mindMapStyle) {
    case MindMappingType.LOGIC_DIAGRAM_LEFT: {
      style.display = 'flex';
      style.alignItems = 'center';
      style.left = mindNode.left + width;
      style.top = mindNode.top;
      style.height = height;
      break;
    }
    case MindMappingType.LOGIC_DIAGRAM_RIGHT: {
      const numberCount = getDigitCount(props.count ?? 0);
      const wordWith = props.showCount ? numberWidthMap.get(numberCount) ?? 0 : 0; // 数字宽度
      style.display = 'flex';
      style.alignItems = 'center';
      style.left = mindNode.left - ICON_SIZE - LINE_LENGTH - wordWith;
      style.top = mindNode.top;
      style.height = height;
      break;
    }
    case MindMappingType.MIND_MAPPING: {
      const numberCount = getDigitCount(props.count ?? 0);
      const wordWith = props.showCount ? numberWidthMap.get(numberCount) ?? 0 : 0; // 数字宽度
      style.display = 'flex';
      style.alignItems = 'center';
      style.top = mindNode.top;
      style.height = height;
      if (mindNode.dir === 'right' || mindNode.dir === undefined) {
        style.left = mindNode.left + width;
      } else {
        style.left = mindNode.left - ICON_SIZE - LINE_LENGTH - wordWith;
      }
      break;
    }
    case MindMappingType.ORGANIZATION_STRUCTURE: {
      style.display = 'flex';
      style.alignItems = 'center';
      style.flexDirection = 'column';
      style.left = mindNode.left + mindNode.width * 0.5 - ICON_SIZE / 2;
      style.top = mindNode.top + mindNode.height;
      style.height = height;
      break;
    }
    default:
  }
  return { containerStyle: style };
};

function getDigitCount(num: number): number {
  return Math.floor(Math.log10(Math.abs(num))) + 1;
}
