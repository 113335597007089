import type { BlockSearchDTO } from '@next-space/fe-api-idl';
import { useDebounceFn } from 'ahooks';
import { useCallback, useEffect, useRef } from 'react';
import type { SearchAdapter } from 'src/common/hooks/use-search-controller';
import { useSearchController } from 'src/common/hooks/use-search-controller';
import { segmentsToText } from 'src/editor/utils/editor';
import type { SearchInfo } from 'src/hooks/drive/use-search-block';
import { useSearchBlock } from 'src/hooks/drive/use-search-block';
import { getFileNameInfo } from 'src/utils/file';

import type { FileType, SearchItem, SearchType } from '.';

interface Props {
  defaultList?: SearchItem[];
  type?: SearchType;
  fileType?: FileType;
  source?: SearchInfo['source'];
  parentId?: string;
  canSearchWithEmpty?: boolean;
  getPerPage?: () => number;
  spaceId?: string;
}

// @hexiang 参数构造为对象
export const useSearchUI = (props: Props) => {
  const {
    defaultList,
    type,
    fileType,
    source,
    parentId,
    canSearchWithEmpty = false,
    getPerPage,
    spaceId,
  } = props;
  const searchBlock = useSearchBlock();
  const pageNumberRef = useRef(1);

  const searchAdapterRef = useRef<SearchAdapter<BlockSearchDTO, SearchItem>>({
    search: async (pageNo: number, keyword: string, pageSize: number) => {
      const res = await searchBlock({
        pageNo,
        search: keyword,
        perPage: pageSize,
        type,
        fileType,
        source,
        parentId,
        spaceId,
      });
      pageNumberRef.current = res.newPageNo;
      return res.resultBlocks;
    },
    async convert(result) {
      return result.map((v) => {
        return {
          uuid: v.block.uuid,
          title: segmentsToText(v.block.data.segments),
          type: v.block.type,
          navs: v.navs,
          data: {
            icon: v.block.data.icon,
            ossName: v.block.data.ossName,
            extName: getFileNameInfo(segmentsToText(v.block.data.segments)).extName,
            size: v.block.data.size,
          },
          coverUrl: v.coverUrl,
          parentId: v.block.parentId,
        };
      });
    },
    // getDefaultList: () => {
    //   return defaultList;
    // },
    defaultList,
    getPerPage,
    canSearchWithEmpty,
  });

  const { search, resultList, loading, hasMore, firstTime } = useSearchController(
    searchAdapterRef.current
  );

  const keywordRef = useRef('');

  const { run: onInputChange } = useDebounceFn(
    (value: string) => {
      // 搜索关键字改变了，这两个需要重置
      keywordRef.current = value;
      pageNumberRef.current = 1;
      void search(1, keywordRef.current);
    },
    { wait: 800 }
  );

  const loadMore = useCallback(() => {
    const nextPageNumber = pageNumberRef.current + 1;
    void search(pageNumberRef.current + 1, keywordRef.current);
    pageNumberRef.current = nextPageNumber;
  }, [search]);

  useEffect(() => {
    searchAdapterRef.current.defaultList = defaultList;
    void search(pageNumberRef.current, keywordRef.current);
  }, [canSearchWithEmpty, defaultList, search]);

  return {
    firstTime,
    resultList,
    loading,
    hasMore,
    loadMore,
    onInputChange,
    keyword: keywordRef.current,
  };
};
