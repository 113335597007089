import { cx } from '@flowus/common/cx';
import { CollectionViewType, CoverAspectType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { CardContentWidthMap, getCardSize, getImageRadio } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { ImageElement, useImageParams } from 'src/editor/editor/plugin/image';
import {
  useCardImageDir,
  useCardSize,
  useCoverAspectType,
} from 'src/hooks/collection-view/use-collection-view';
import { getState } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { BlockRender } from './block-render';
import { getTempImageBlockId, usePageContent } from './use-page-content';

interface Props {
  recordId: string;
}

export const PageContent: FC<Props> = ({ recordId }) => {
  const { ids = [], imageBlockId = getTempImageBlockId(recordId) } = usePageContent(recordId);
  const { viewId, viewType } = useBitable();
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const subLens = useObservableBlock(recordId, (block) => block?.subNodes.length);

  if (!subLens) return null;

  if (imageBlockId) {
    return <PageImage uuid={imageBlockId} />;
  }

  const isGallery = viewType === CollectionViewType.GALLERY;
  const style = {
    width: isGallery ? undefined : getCardSize(cardSize, imageDir),
    height: isGallery ? undefined : CardContentWidthMap[cardSize],
  };
  if (ids.length > 0) {
    return (
      <div
        className="w-full h-full p-2 overflow-hidden opacity-60 bg-grey7 border-b border-black_006"
        style={style}
      >
        {ids.map((id, index) => {
          const { blocks } = getState();
          if (ids.slice(0, index).includes(blocks[id]?.parentId ?? '')) {
            return null;
          }
          return <BlockRender root uuid={id} key={id + index} renderIds={ids} />;
        })}
      </div>
    );
  }

  return null;
};

const PageImage: FC<{ uuid: string }> = ({ uuid }) => {
  const { viewId, viewType } = useBitable();
  const cardSize = useCardSize(viewId);
  const coverAspectType = useCoverAspectType(viewId);
  const imageDir = useCardImageDir(viewId);

  const isGallery = viewType === CollectionViewType.GALLERY;
  const width = getCardSize(cardSize, imageDir);
  const style = {
    width: isGallery ? undefined : width,
    height: isGallery ? undefined : width * getImageRadio(imageDir),
  };

  const { url, formatUrl, srcSetParams } = useImageParams(uuid, {
    containerWidth: width,
    defaultWidth: width,
  });

  const imageObject =
    coverAspectType === CoverAspectType.CONTAIN ? 'object-contain' : 'object-cover';

  return (
    <ImageElement
      srcSetParams={srcSetParams}
      blockId={uuid}
      style={style}
      className={cx(
        'w-full h-full flex items-center justify-center border-b border-black/[0.06]',
        imageObject
      )}
      url={url}
      formatUrl={formatUrl}
    />
  );
};
