import { sleep } from '@flowus/common/async';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { $currentUserCache } from 'src/services/user/current-user';
import { SearchParams, ViewPath } from 'src/utils';
import type { LocationSearchParams } from 'src/utils/history-utils';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { locationToUrl } from 'src/utils/location-utils';

export const useCheckLogin = () => {
  return useCallback((searchParams?: LocationSearchParams) => {
    const isLogin = !!$currentUserCache.uuid;
    if (!isLogin) {
      const params: LocationSearchParams = [
        {
          key: SearchParams.callbackPath,
          value: location.pathname,
        },
      ];

      if (searchParams) {
        if (Array.isArray(searchParams)) {
          params.concat(searchParams);
        } else {
          params.push(searchParams);
        }
      }

      message.warning('请先登录/注册');
      // 等消息完全展示后再跳转
      void sleep(500).then(() => {
        locationToUrl(splicePathSearchParams(ViewPath.login, params));
      });
    }

    return isLogin;
  }, []);
};
