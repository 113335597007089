import React from 'react';

// NOTE：优化用途，提供一些expensive的值给cell。避免每个cell都计算一次
interface RowContextValue {
  pageId: string;
  illegal: boolean;
  readonly: boolean;
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const RowContext = React.createContext<RowContextValue>(null!);
