import { BlockType } from '@next-space/fe-api-idl';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { patchExpand } from 'src/redux/managers/ui/use-fold';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { $appUiStateCache } from 'src/services/app';
import { getBlockIdFromLocationHash } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePageLazyListLoading, usePageVirtualListMode } from 'src/views/main/page-doc/context';
import { useJumpTop } from 'src/views/main/page-doc/use-jump-top';
import { getAncestors } from './use-get-ancestors';
import { useGetDescendants } from './use-get-descendants';

/** 通过hash定位到block位置 */
export const useLocationBlock = (props?: {
  container?: React.RefObject<HTMLDivElement | null>;
  pageId?: string;
}) => {
  const location = useLocation();
  const pageId = useGetPageId();
  const getDescendants = useGetDescendants();
  const pageLazyListLoading = usePageLazyListLoading();
  const jumpTop = useJumpTop();
  const pageVirtualListMode = usePageVirtualListMode();

  const _locationBlock = useCallback(() => {
    if (pageLazyListLoading) return;
    const blockId = getBlockIdFromLocationHash(location.hash);
    if (blockId) {
      locationBlock(props?.pageId ?? pageId, blockId, getDescendants, props?.container, jumpTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    location.hash,
    pageId,
    pageLazyListLoading,
    props?.container,
    props?.pageId,
    pageVirtualListMode.isVirtualListMode,
  ]);

  useEffect(() => {
    _locationBlock();
  }, [_locationBlock]);

  return _locationBlock;
};
let timer: NodeJS.Timeout;
/** 定位到blockId对应的位置 */
export const locationBlock = (
  pageId: string,
  blockId: string,
  getDescendants: ReturnType<typeof useGetDescendants>,
  container?: React.RefObject<HTMLDivElement | null>,
  jumpTop?: ReturnType<typeof useJumpTop>
) => {
  // 尝试打开所有祖先的折叠列表
  const tryOpenFolderListBlock = (id: string) => {
    const ancestors = getAncestors(id);
    ancestors.delete(id);
    // 如果有折叠的祖先block，则展开
    const folderIds = [...ancestors].filter((id) => {
      const block = cache.blocks[id];
      const expand = $appUiStateCache.$expandFoldRecord[id];
      return block && !expand;
    });
    if (folderIds.length) {
      patchExpand(folderIds, true, false);
      return true;
    }
  };

  if (blockId) {
    timer && clearTimeout(timer);
    const open = tryOpenFolderListBlock(blockId);
    // 需要等打开后才能定位,所以set timeout一下
    timer = setTimeout(
      () => {
        const node = (container?.current ?? document).querySelector(`[data-block-id='${blockId}']`);
        if (!node) {
          void jumpTop?.(blockId);
        }
        if (node instanceof HTMLElement) {
          setTimeout(
            () => {
              node.scrollIntoView({
                behavior: 'auto',
                block: 'center',
              });
            },
            // 如果是思维导图，需要等渲染完成之后才能scrollIntoView
            node.dataset.mindMapId ? 500 : 0
          );

          const selectedBlocks: SelectBlock[] = [];
          const { syncId } = node.dataset;
          selectedBlocks.push({
            blockId,
            syncId,
          });
          const checkIfIsInSyncBlock = () => {
            if (!syncId) return;
            let syncBlock = cache.blocks[syncId];
            if (!syncBlock) return;
            if (syncBlock.type === BlockType.SYNC_REFERENCE) {
              syncBlock = cache.blocks[syncBlock.data.ref?.uuid ?? ''];
            }
            if (!syncBlock) return;
            // 如果是在同步块内就要找出当前页面所有同步块的block并高亮
            const descendants = getDescendants(pageId, []);
            descendants.forEach((id) => {
              if (id === syncId) return; // 已经加过了
              const childBlock = cache.blocks[id];
              if (!childBlock) return;

              if (childBlock.type === BlockType.SYNC_CONTAINER) {
                if (syncBlock?.uuid === id) {
                  selectedBlocks.push({
                    blockId,
                    syncId: id,
                  });
                }
              } else if (childBlock.type === BlockType.SYNC_REFERENCE) {
                const childSyncBlock = cache.blocks[childBlock.data.ref?.uuid ?? ''];
                if (!childSyncBlock) return;
                if (childSyncBlock.uuid === syncBlock?.uuid) {
                  selectedBlocks.push({
                    blockId,
                    syncId: childBlock.uuid,
                  });
                }
              }
            });
          };
          checkIfIsInSyncBlock();
          dispatch(
            uiActions.update({
              selectedBlocks,
            })
          );
        }
      },
      open ? 150 : 0
    );
  }
};
