import type { Matrix } from '../css/property-descriptors/transform';
import type { Path } from './path';

export const enum EffectType {
  TRANSFORM = 0,
  CLIP = 1,
  OPACITY = 2,
}

export const enum EffectTarget {
  BACKGROUND_BORDERS = 1 << 1,
  CONTENT = 1 << 2,
}

export interface IElementEffect {
  readonly type: EffectType;
  readonly target: number;
}

export class TransformEffect implements IElementEffect {
  readonly type: EffectType = EffectType.TRANSFORM;
  readonly target: number = EffectTarget.BACKGROUND_BORDERS | EffectTarget.CONTENT;

  constructor(readonly offsetX: number, readonly offsetY: number, readonly matrix: Matrix) {}
}

export class ClipEffect implements IElementEffect {
  readonly type: EffectType = EffectType.CLIP;

  constructor(readonly path: Path[], readonly target: EffectTarget) {}
}

export class OpacityEffect implements IElementEffect {
  readonly type: EffectType = EffectType.OPACITY;
  readonly target: number = EffectTarget.BACKGROUND_BORDERS | EffectTarget.CONTENT;

  constructor(readonly opacity: number) {}
}

export const isTransformEffect = (effect: IElementEffect): effect is TransformEffect =>
  effect.type === EffectType.TRANSFORM;
export const isClipEffect = (effect: IElementEffect): effect is ClipEffect =>
  effect.type === EffectType.CLIP;
export const isOpacityEffect = (effect: IElementEffect): effect is OpacityEffect =>
  effect.type === EffectType.OPACITY;
