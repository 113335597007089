import { fastEqual } from '@flowus/common';
import fastJsonStableStringify from 'fast-json-stable-stringify';
import memoize from 'micro-memoize';
import { v4 } from 'uuid';
import { $localState } from '../app-support/local-state';

export const MEMOIZE_BLOCK_VERSION = {
  cacheVersion: v4(),
  cache: new Map(),
  clear: () => {
    MEMOIZE_BLOCK_VERSION.cache.clear();
    MEMOIZE_BLOCK_VERSION.cacheVersion = v4();
  },
};

export class MemoizeByBlockVersion<T extends (...args: any[]) => any> {
  private cache = new Map<string, ReturnType<T>>();
  private getCacheKey?: (...args: Parameters<T>) => any;
  private opt = {
    enable: true,
  };

  constructor(
    private fn: T,
    getCacheKey?: (...args: Parameters<T>) => any,
    opt?: { enable?: boolean }
  ) {
    this.getCacheKey = getCacheKey;
    if (opt?.enable) {
      this.opt.enable = opt.enable;
    }
  }

  public getMemoizedFunction(): T {
    const _memoizedFn = memoize(
      (...args: Parameters<T>): ReturnType<T> => {
        return this.fn(...args);
      },
      { isEqual: fastEqual }
    );

    const memoizedFn = (...args: Parameters<T>): ReturnType<T> => {
      if ($localState.getState().beta && this.opt.enable) {
        // 是否开启了 beta 模式
        const cacheKey = this.getCacheKey
          ? fastJsonStableStringify([MEMOIZE_BLOCK_VERSION.cacheVersion, this.getCacheKey(...args)])
          : fastJsonStableStringify([MEMOIZE_BLOCK_VERSION.cacheVersion, ...args]);

        if (this.cache.has(cacheKey)) {
          return this.cache.get(cacheKey) as ReturnType<T>;
        }

        const result = _memoizedFn(...args);
        this.cache.set(cacheKey, result);
        return result;
      }
      return _memoizedFn(...args);
    };

    return memoizedFn as T;
  }

  // 静态方法，用于创建带有缓存功能的函数
  public static create<T extends (...args: any[]) => any>(
    fn: T,
    getCacheKey?: (...args: Parameters<T>) => any,
    opt?: {
      enable?: boolean;
    }
  ): T {
    const memoize = new MemoizeByBlockVersion(fn, getCacheKey, opt);
    return memoize.getMemoizedFunction();
  }
}
