export const roma = {
  version: 1,
  themeName: 'roma',
  theme: {
    seriesCnt: '4',
    // backgroundColor: 'rgba(0,0,0,0)',
    titleColor: '#333333',
    subtitleColor: '#aaaaaa',
    textColorShow: false,
    textColor: '#333',
    markTextColor: '#eeeeee',
    color: [
      '#e01f54',
      '#001852',
      '#f5e8c8',
      '#b8d2c7',
      '#c6b38e',
      '#a4d8c2',
      '#f3d999',
      '#d3758f',
      '#dcc392',
      '#2e4783',
      '#82b6e9',
      '#ff6347',
      '#a092f1',
      '#0a915d',
      '#eaf889',
      '#6699FF',
      '#ff6666',
      '#3cb371',
      '#d5b158',
      '#38b6b6',
    ],
    borderColor: '#ccc',
    borderWidth: 0,
    visualMapColor: ['#e01f54', '#e7dbc3'],
    legendTextColor: '#333333',
    kColor: '#e01f54',
    kColor0: '#001852',
    kBorderColor: '#f5e8c8',
    kBorderColor0: '#b8d2c7',
    kBorderWidth: 1,
    lineWidth: 2,
    symbolSize: 4,
    symbol: 'emptyCircle',
    symbolBorderWidth: 1,
    lineSmooth: false,
    graphLineWidth: 1,
    graphLineColor: '#aaaaaa',
    mapLabelColor: '#000000',
    mapLabelColorE: 'rgb(100,0,0)',
    mapBorderColor: '#444444',
    mapBorderColorE: '#444',
    mapBorderWidth: 0.5,
    mapBorderWidthE: 1,
    mapAreaColor: '#eeeeee',
    mapAreaColorE: 'rgba(255,215,0,0.8)',
    axes: [
      {
        type: 'all',
        name: '通用坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'category',
        name: '类目坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: false,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'value',
        name: '数值坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'log',
        name: '对数坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
      {
        type: 'time',
        name: '时间坐标轴',
        axisLineShow: true,
        axisLineColor: '#333',
        axisTickShow: true,
        axisTickColor: '#333',
        axisLabelShow: true,
        axisLabelColor: '#333',
        splitLineShow: true,
        splitLineColor: ['#ccc'],
        splitAreaShow: false,
        splitAreaColor: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)'],
      },
    ],
    axisSeperateSetting: true,
    toolboxColor: '#999999',
    toolboxEmphasisColor: '#666666',
    tooltipAxisColor: '#cccccc',
    tooltipAxisWidth: 1,
    timelineLineColor: '#293c55',
    timelineLineWidth: 1,
    timelineItemColor: '#293c55',
    timelineItemColorE: '#a9334c',
    timelineCheckColor: '#e43c59',
    timelineCheckBorderColor: '#c23531',
    timelineItemBorderWidth: 1,
    timelineControlColor: '#293c55',
    timelineControlBorderColor: '#293c55',
    timelineControlBorderWidth: 0.5,
    timelineLabelColor: '#293c55',
    datazoomBackgroundColor: 'rgba(47,69,84,0)',
    datazoomDataColor: 'rgba(47,69,84,0.3)',
    datazoomFillColor: 'rgba(167,183,204,0.4)',
    datazoomHandleColor: '#a7b7cc',
    datazoomHandleWidth: '100',
    datazoomLabelColor: '#333333',
  },
};
