import type { CollectionViewGroupBy } from '@next-space/fe-api-idl';
import { CollectionSchemaType, GroupLevel, GroupSortType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import { GroupLevelMap, GroupSortMap } from './const';
import { formulaIsValid } from './helper';

export const getNumberGroupLevel = (groupBy?: CollectionViewGroupBy) => {
  const start = parseFloat(String(groupBy?.start)) || 0;
  const end = parseFloat(String(groupBy?.end)) || 1000;
  const size = parseFloat(String(groupBy?.size)) || 100;
  return { start, end, size };
};

export const getGroupSort = (type: 'date' | 'number' | 'text', sort?: GroupSortType) => {
  if (!sort || !GroupSortMap[type].find((item) => item.type === sort)) {
    sort = GroupSortType.ASCENDING;
  }

  return sort;
};

export const getGroupLevel = (type: 'text' | 'date', groupLevel?: GroupLevel) => {
  if (!groupLevel || !GroupLevelMap[type].find((item) => item.type === groupLevel)) {
    groupLevel = type === 'date' ? GroupLevel.RELATIVE : GroupLevel.EXACT;
  }

  return groupLevel;
};

export const getValidPropertyIds = (
  collectionId: string,
  state = {
    blocks: getState().blocks,
  }
) => {
  const collection = state.blocks[collectionId];
  if (!collection) return [];

  const schemas = collection.data.schema ?? {};
  const validProperties = Object.keys(schemas).filter((schemaId) => {
    const schema = schemas[schemaId];
    const schemaType = schema?.type;

    if (schemaType === CollectionSchemaType.FORMULA) {
      return formulaIsValid(collectionId, schemaId, schema?.formula);
    }

    return schemaType !== CollectionSchemaType.ROLLUP && schemaType !== CollectionSchemaType.FILE;
  });

  return validProperties;
};
