import type { FC } from 'react';
import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { message } from 'src/common/components/message';
import type { FilePreviewProps } from '.';

export const AudioPreviewCom: FC<FilePreviewProps> = ({ downloadUrl }) => {
  const [errorMsg, setErrorMsg] = useState('');
  // return (

  //   <audio controls src={downloadUrl}>
  //     当前浏览器不支持<code>audio</code> 元素。
  //   </audio>
  // );
  return (
    <AudioPlayer
      autoPlay={false}
      preload="none"
      autoPlayAfterSrcChange={false}
      src={downloadUrl}
      onError={(e) => {
        const { error } = e.target as any;
        if (!error || !downloadUrl) {
          return;
        }
        switch (error.code) {
          case MediaError.MEDIA_ERR_DECODE:
            setErrorMsg('视频解码时发生错误');
            break;
          case MediaError.MEDIA_ERR_NETWORK:
            setErrorMsg('加载失败');
            break;
          case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
            setErrorMsg(`浏览器不支持播放该音频源`);
            break;
          default:
        }
      }}
      onPlayError={(err) => {
        if (errorMsg) {
          message.warning({
            key: errorMsg,
            content: errorMsg,
          });
        } else {
          message.warning({
            key: err.message,
            content: err.message,
          });
        }
      }}
    />
  );
};

export const AudioPreview = React.memo(AudioPreviewCom);
