import { AIEditType } from './const';

export const LogMap: Record<string, string> = {
  [AIEditType.CPlus]: 'Rewriting',
  [AIEditType.Java]: 'Rewriting',
  [AIEditType.JavaScript]: 'Rewriting',
  [AIEditType.TypeScript]: 'Rewriting',
  [AIEditType.Python]: 'Rewriting',
  [AIEditType.OtherCodeLanguage]: 'Rewriting',

  [AIEditType.Comment]: 'commenting',
  [AIEditType.BugFix]: 'bugFixer',

  [AIEditType.Professional]: 'changeTone',
  [AIEditType.Casual]: 'changeTone',
  [AIEditType.Straightforward]: 'changeTone',
  [AIEditType.Confident]: 'changeTone',
  [AIEditType.Friendly]: 'changeTone',

  [AIEditType.Longer]: 'longer',
  [AIEditType.Simpler]: 'shorter',

  [AIEditType.Improve]: 'improve',

  [AIEditType.Chinese]: 'translate',
  [AIEditType.ClassicalChinese]: 'translate',
  [AIEditType.English]: 'translate',
  [AIEditType.Korean]: 'translate',
  [AIEditType.Japanese]: 'translate',
  [AIEditType.French]: 'translate',
  [AIEditType.German]: 'translate',
  [AIEditType.OtherLanguage]: 'translate',

  [AIEditType.Summary]: 'summary',
  [AIEditType.Summarize]: 'summarize',

  [AIEditType.ContinueWrite]: 'continue',

  [AIEditType.Blog]: 'blog',
  [AIEditType.Brainstorm]: 'brainstorm',
  [AIEditType.Outline]: 'outline',
  [AIEditType.Story]: 'story',
  [AIEditType.DailyNotes]: 'daily',
  [AIEditType.ProsAndConsList]: 'prosConsList',
  [AIEditType.ActionItems]: 'findActionItems',
  [AIEditType.Explain]: 'explain',
};
