import _ from 'lodash-es';
import { cache } from 'src/redux/store';
import type {
  AggregationActionCategory,
  AggregationActionCategoryItem,
  AggregationActionItem,
} from './menu-helper';
import { getAvailableAggregationsForMenu } from './menu-item';
import type { CollectionSchema } from '@next-space/fe-api-idl';
import { AggregationAction } from '@next-space/fe-api-idl';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { ListView } from 'src/common/components/list-view';

/** 分组的时候算出所有可显示的菜单项,这个菜单的显示逻辑跟多维表footer不同 */
export const getGroupAggregationActionCategoriesMenu = (collectionId: string) => {
  const schema = cache.blocks[collectionId]?.data.schema;
  if (!schema) return;
  const categorySet = new Set<AggregationActionCategory>();
  const categoryItemSet = new Set<AggregationActionCategoryItem>();
  const subItemsRecord: Record<AggregationActionCategory, AggregationActionItem[] | undefined> =
    {} as any;

  const filterItems = (
    items: AggregationActionCategoryItem[],
    schema: CollectionSchema & { propertyId: string }
  ) => {
    items.forEach((v) => {
      if (!categorySet.has(v.type)) {
        categorySet.add(v.type);
        // subItems最后才整合
        categoryItemSet.add({ ...v, subItems: [] });
      }
      const subItems = subItemsRecord[v.type] ?? [];
      subItemsRecord[v.type] = subItems;
      v.subItems?.forEach((v) => {
        if (!v.schemas) {
          v.schemas = [];
        }
        v.schemas.push(schema);
        subItems?.push(v);
      });
    });
  };

  const titleCategories = getAvailableAggregationsForMenu(collectionId, 'title');
  // 去掉第一个none
  titleCategories.shift();
  filterItems(titleCategories, { ...schema['title']!, propertyId: 'title' });
  Object.entries(schema).forEach(([propertyId, s]) => {
    if (propertyId === 'title') return;
    const categories = getAvailableAggregationsForMenu(collectionId, propertyId);
    // 去掉第一个none
    categories.shift();
    filterItems(categories, { ...s, propertyId });
  });

  // 整合所有菜单
  const categoryItems: AggregationActionCategoryItem[] = [];
  const itemSet: Record<string, AggregationActionItem | undefined> = {} as any;
  categoryItemSet.forEach((item) => {
    const subItems = subItemsRecord[item.type];
    if (subItems) {
      // 使用 reduce 来去重并合并 subItems
      const mergedItems = _.reduce(
        subItems,
        (acc, item) => {
          const existing = itemSet[item.title + item.type];
          if (existing) {
            if (item.schemas) {
              existing.schemas?.push(...item.schemas);
            }
          } else {
            itemSet[item.title + item.type] = item;
            // 如果不存在，直接添加到结果数组
            acc.push(item);
          }
          return acc;
        },
        [] as AggregationActionItem[]
      );
      item.subItems = mergedItems;
    }
    categoryItems.push(item);
  });
  return categoryItems;
};

export const buildGroupAggregationListItem = (
  collectionId: string,
  selected: { aggregation: AggregationAction; propertyId?: string },
  onSelect: (
    aggregation: AggregationAction,
    schema?: CollectionSchema & { propertyId: string }
  ) => void
) => {
  const categories = getGroupAggregationActionCategoriesMenu(collectionId);
  const listItems = categories?.map((item) => {
    const secondItem: ListItem = {
      type: ListItemType.OPERATION,
      data: {
        title: item.title,
        hasArrow: true,
        renderSubMenu: () => {
          const subListItems =
            item.subItems?.map((v) => {
              const isCountAll = v.type === AggregationAction.COUNT_ALL;
              const subListItem = {
                type: ListItemType.OPERATION,
                data: {
                  ...v,
                  selected: isCountAll && selected.aggregation === AggregationAction.COUNT_ALL,
                  hasArrow: !isCountAll,
                  onClick: () => {
                    if (isCountAll) {
                      onSelect(v.type);
                    }
                  },
                  // 三级菜单
                  renderSubMenu: isCountAll
                    ? undefined
                    : () => {
                        const thirdItems =
                          v.schemas?.map((v2) => {
                            const item = {
                              type: ListItemType.OPERATION,
                              data: {
                                selected:
                                  selected.aggregation === v.type &&
                                  selected.propertyId === v2.propertyId,
                                title: v2.name,
                                onClick: () => {
                                  onSelect(v.type, v2);
                                },
                              },
                            };
                            return item;
                          }) ?? [];
                        return (
                          <ListView className="w-[200px] py-2 next-modal" items={thirdItems} />
                        );
                      },
                },
              };
              return subListItem;
            }) ?? [];
          return <ListView className="w-[200px] py-2 next-modal" items={subListItems} />;
        },
      },
    };
    return secondItem;
  });
  return listItems;
};
