import type { IEditorModel } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { InlineLinkPagePanel } from 'src/editor/component/menu-list/inline-panel/inline-link-page-panel';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

interface Option {
  model: IEditorModel;
  setFakeFocus: (status: boolean) => void;
}

export const useShortcutInlinePage = (uuid: string, option: Option) => {
  const getEditorModel = useGetOrInitEditorModel();
  const { model: _model, setFakeFocus } = option;
  const openModal = useOpenModal();

  return useCallback(() => {
    const model = _model || getEditorModel(uuid);
    if (!model) return;

    const selection = model.active ? model.selection : null;
    if (!selection) return;

    const left = selection.offset;
    const right = selection.endOffset;

    openModal.dropdown({
      popcorn: {
        getBoundingClientRect: () => {
          const rect = model.getBoundingClientRectOfRange(left, right);
          return rect ?? new DOMRect(-9999, -9999, 0, 0);
        },
      },
      closeAfterCallBack: () => {
        setFakeFocus(false);
      },
      placement: 'bottom',
      offset: [4, 4],
      content: ({ onCloseModal }) => (
        <InlineLinkPagePanel
          getEditorModel={getEditorModel}
          symbol="[["
          onCloseModal={onCloseModal}
          blockId={uuid}
          left={left}
          right={right}
          setFakeFocus={setFakeFocus}
        />
      ),
    });
  }, [_model, getEditorModel, openModal, setFakeFocus, uuid]);
};
