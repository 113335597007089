import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { Preview } from '@next-space/fe-inlined';
import { useMemo } from 'react';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { convertSegmentsToContent } from 'src/editor/utils/segments';
import type { InlinePlugin } from '../../inline/inline-plugin';
import { EditableContext } from '../editable-context';
import { makeEditorModelConfig } from './helper';
import { DEFAULT_PLUGINS } from './plugins';

export function RichText(props: {
  className?: string;
  segments: SegmentDTO[] | undefined;
  plugins?: InlinePlugin[];
  placeholder?: string;
  placeholderColor?: string;
  interactable?: boolean;
}) {
  const {
    className,
    segments,
    interactable = true,
    plugins = DEFAULT_PLUGINS,
    placeholder,
    placeholderColor,
  } = props;
  const EditorModel = useMemo(() => {
    return makeEditorModelConfig(plugins);
  }, [plugins]);

  let content = convertSegmentsToContent(segments);
  const text = segmentsToText(segments);
  if (!text) {
    content = convertSegmentsToContent(textToSegments(placeholder));
  }

  return (
    <div
      className={cx(className, 'break-words')}
      style={{
        color: !text ? placeholderColor ?? 'var(--grey4)' : undefined,
      }}
    >
      <EditableContext.Provider value={{ readonly: true, interactable }}>
        <Preview content={content} EditorModel={EditorModel} />
      </EditableContext.Provider>
    </div>
  );
}
