import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

interface Props {
  size?: number;
  background?: string;
  ringWidth?: number;
  className?: string;
  bgColor?: string;
  ringColorClassName?: string;
  ringColor?: string;
  percent: number;
}
export const Ring: FC<Props> = (props) => {
  const { percent, size = 20, ringWidth = 3, className, ringColorClassName, ringColor } = props;

  const fixedPercent = Math.min(1, percent);

  return (
    <div
      className={cx('relative rounded-full border-solid', className)}
      style={{
        width: size,
        height: size,
        borderWidth: ringWidth,
        // borderColor: bgColor,
      }}
    >
      <div
        className="absolute inset-0"
        style={{
          width: size,
          height: size,
          top: -ringWidth,
          left: -ringWidth,
          clipPath: fixedPercent < 0.5 ? 'inset(0 0 0 50%)' : 'unset',
        }}
      >
        <div
          // 右边的环
          className={cx('absolute w-full h-full rounded-full border-solid', ringColorClassName)}
          style={{
            borderWidth: ringWidth,
            borderColor: ringColor,
            clipPath: 'inset(0 50% 0 0)',
            transform: `rotate(${fixedPercent <= 0.5 ? (fixedPercent / 0.5) * 180 : 180}deg)`,
          }}
        />

        {fixedPercent > 0.5 && (
          <div
            // 左边的环
            className={cx('absolute w-full h-full rounded-full border-solid', ringColorClassName)}
            style={{
              borderWidth: ringWidth,
              clipPath: 'inset(0 0 0 50%)',
              transform: `rotate(${((fixedPercent - 0.5) / 0.5) * 180}deg)`,
              borderColor: ringColor,
            }}
          />
        )}
      </div>
    </div>
  );
};
