import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Dropdown } from '@flowus/common/next-modal/dropdown';

export interface Option {
  id: string;
  name: string;
}
interface Props {
  name: string;
  className?: string;
  optionClassName?: string;
  selectedIndex: number;
  options: Option[];
  onSelect: (option: Option, index: number) => void;
  disable?: boolean;
}
export const OptionItem: FC<Props> = (props) => {
  const [visible, setVisible] = useState(false);
  const optionsView = useMemo(
    () => (
      <div className={cx('next-modal min-w-[160px] py-1', props.optionClassName)}>
        {props.options.map((option, index) => (
          <SelectItem
            selected={props.selectedIndex === index}
            key={option.id}
            {...option}
            onClick={() => {
              setVisible(false);
              props.onSelect(option, index);
            }}
          />
        ))}
      </div>
    ),
    [props]
  );
  const onChange = useCallback((visible: boolean) => {
    setVisible(visible);
  }, []);

  return (
    <div
      className={cx(
        'flex h-10 items-center justify-between',
        props.disable ? 'opacity-30' : '',
        props.className
      )}
    >
      <span className="text-t2 flex-shrink-0">{props.name}</span>
      <Dropdown disable={props.disable} visible={visible} onChange={onChange} content={optionsView}>
        <div
          className={cx(
            'ml-2 flex items-center text-ellipsis',
            props.disable ? '' : 'cursor-pointer'
          )}
        >
          <span className={'text-t2 mr-2 text-ellipsis text-grey3'}>
            {props.options[props.selectedIndex]?.name}
          </span>
          <Icon name="IcArrowDown01" size="xxxsmall" />
        </div>
      </Dropdown>
    </div>
  );
};

const SelectItem: FC<Option & { selected: boolean; onClick: () => void }> = (props) => {
  return (
    <div
      className="flex justify-between items-center px-4 h-9 text-t2 cursor-pointer"
      onClick={props.onClick}
    >
      <div>{props.name}</div>
      {props.selected && <Icon size="normal" name="IcCheck02" />}
    </div>
  );
};
