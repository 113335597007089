
      const script = document.createElement('script');
      script.src = 'https://lib.baomitu.com/mermaid/10.6.1/mermaid.min.js';
      script.async = true;
      //加载失败就加载我们自己的
      script.onerror = () => {
        const fallbackScript = document.createElement('script');
        fallbackScript.src = 'https://cdn2.flowus.cn/emoji/mermaid.min.js'; // 备用地址
        fallbackScript.async = true;
        document.head.append(fallbackScript);
      };
      document.head.append(script);
    