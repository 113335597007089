import { MindMapContext } from '@flowus/mind-map';
import isHotkey from 'is-hotkey';
import { useCallback, useContext } from 'react';
import { ColorKey } from 'src/colors';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { getEditorKeyFromElement } from 'src/utils/editor-utils';

const allColorKeys = new Map<number, { isBgColor?: boolean; colorKey: ColorKey | '' }>([
  [0, { colorKey: '' }],
  [1, { colorKey: ColorKey.grey }],
  [2, { colorKey: ColorKey.grey, isBgColor: true }],
  [3, { colorKey: ColorKey.brown }],
  [4, { colorKey: ColorKey.brown, isBgColor: true }],
  [5, { colorKey: ColorKey.orange }],
  [6, { colorKey: ColorKey.orange, isBgColor: true }],
  [7, { colorKey: ColorKey.yellow }],
  [8, { colorKey: ColorKey.yellow, isBgColor: true }],
  [9, { colorKey: ColorKey.red }],
]);
const changeColorInfo = Array.from(Array(10)).map((_, i) => {
  const colorInfo = allColorKeys.get(i);
  return {
    key: `mod+${i}`,
    isBgColor: colorInfo?.isBgColor,
    colorKey: colorInfo?.colorKey,
  };
});

export const useChangeColorKey = () => {
  const transaction = useTransaction();
  const isInMindMap = !!useContext(MindMapContext);
  return useCallback(
    (event: KeyboardEvent) => {
      for (const colorInfo of changeColorInfo) {
        if (isHotkey(colorInfo.key)(event)) {
          event.preventDefault();
          transaction(() => {
            const editorKey = getEditorKeyFromElement(document.activeElement);
            if (isInMindMap && editorKey) {
              // 看看是不是选中了文本，如果是就先渲染选中的文本
              const model = editorKey ? getEditorModelByEditorKey(editorKey) : undefined;
              if (model && model.selection && !model.selection.isCollapsed) {
                model.performChange((ctx) => {
                  ctx.applyFormat({
                    color: colorInfo.isBgColor ? '' : colorInfo.colorKey,
                    backgroundColor: colorInfo.isBgColor ? colorInfo.colorKey : '',
                  });
                });
                return;
              }
            }
            if (cache.ui.selectedBlocks.length > 0) {
              cache.ui.selectedBlocks.forEach((selectedBlock) => {
                updateBlock(selectedBlock.blockId, {
                  backgroundColor: colorInfo.isBgColor ? colorInfo.colorKey : '',
                  textColor: colorInfo.isBgColor ? '' : colorInfo.colorKey,
                });
              });
              return;
            }

            if (editorKey) {
              const model = getEditorModelByEditorKey(editorKey);
              if (model) {
                model.performChange((ctx) => {
                  ctx.applyFormat({
                    color: colorInfo.isBgColor ? '' : colorInfo.colorKey,
                    backgroundColor: colorInfo.isBgColor ? colorInfo.colorKey : '',
                  });
                });
              }
            }
          });
        }
      }
    },
    [isInMindMap, transaction]
  );
};
