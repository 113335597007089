import { cx } from '@flowus/common/cx';
import type { MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { useFoldMenuState } from 'src/editor/component/fold-menu';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { $searchParams } from 'src/utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { BlockBefore } from '../uikit/block-before';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

// hover menu定位需要
const h1 = '54px';
const h2 = '50px';
const h3 = '36px';
const h4 = '32px';

export const ToggleHeaderElement: BlockElement = ({ id, root, children }) => {
  const { expand, toggle } = useFoldMenuState(id);
  const block = usePickBlock(id, ['data', 'subNodes']);
  const focusEditableAt = useFocusEditableByBlockId();
  const transaction = useTransaction();
  const readonly = useReadonly(id);
  if (!block) return null;

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    transaction(() => {
      const newBlockId = addBlock({}, { parentId: block.uuid, after: block.uuid });
      focusEditableAt(newBlockId, 0);
    });
  };
  const { level = 1 } = block.data;
  const isExpand = $searchParams.print || expand;
  let lineHeight = h1;
  switch (level) {
    case 2:
      lineHeight = h2;
      break;
    case 3:
      lineHeight = h3;
      break;
    case 4:
      lineHeight = h4;
      break;
    default:
  }

  return (
    <BlockDrop TagName={`h${level}` as any} id={id} className="my-px" horizontal={root}>
      <div
        className={cx(
          'block-has-icon-container ignore-focus-click flex-1 overflow-hidden',
          readonly && 'animate-click select-auto'
        )}
        onClick={() => readonly && toggle()}
        style={{ lineHeight }}
      >
        <BlockBefore
          data-no-cancel-selected
          className={cx({
            'mt-4': level === 1,
            'mt-3.5': level === 2,
            'mt-1.5': level === 3,
            'mt-1': level === 4,
          })}
        >
          <span className="flex animate-hover">
            <Icon
              size="large"
              name="IcToggleBlack"
              onClick={toggle}
              className={cx('transition duration-150 cursor-pointer', {
                'rotate-90': isExpand,
                'opacity-30': block.subNodes.length === 0,
              })}
            />
          </span>
        </BlockBefore>
        <Editable
          className={cx('self-start mr-4 flex-1 w-0', {
            'text-h1 py-1.5': level === 1,
            'text-h2 py-2': level === 2,
            'text-h3 py-0.5': level === 3,
            'text-h4 py-0.5': level === 4,
          })}
          uuid={id}
          placeholder={`折叠标题${level}`}
        />
      </div>
      {isExpand && (
        <BlockChildren blockId={id}>
          {block.subNodes.length ? (
            children
          ) : judgeSharePage() ? null : (
            <div
              className="flex items-center w-full animate-hover cursor-pointer h-9 text-t2 p-1 my-px text-grey3 print:hidden"
              onClick={handleClick}
            >
              内容为空，点击编辑
            </div>
          )}
        </BlockChildren>
      )}
    </BlockDrop>
  );
};
