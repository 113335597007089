import { type FC } from 'react';
import { useOpenModal } from '@flowus/common/next-modal';
import { WaresList } from './wares-list';
import { WaresDetail } from './wares-detail';

/** 积分商城 */
export const ScoreShop: FC = () => {
  const openModal = useOpenModal();
  return (
    <div className="w-4xl h-[70vh] px-6">
      <WaresList
        onSelectWares={(wares) => {
          openModal.modal({
            content: ({ onCloseModal }) => {
              return <WaresDetail wares={wares} onClose={onCloseModal} />;
            },
          });
        }}
      />
    </div>
  );
};
