import { useCallback, useEffect, useRef } from 'react';

/**
 * from https://codesandbox.io/s/708ys?file=/src/App.js
 */
export const useDetectIframeClick = (onClick: () => void) => {
  const isMouseOver = useRef(false);
  const onClickRef = useRef<Function>();
  onClickRef.current = onClick;
  useEffect(() => {
    const blur = () => {
      if (isMouseOver.current) {
        onClickRef.current?.();
      }
    };
    window.addEventListener('blur', blur);
    return () => {
      window.removeEventListener('blur', blur);
    };
  }, []);
  const onMouseOver = useCallback(() => {
    isMouseOver.current = true;
  }, []);
  const onMouseOut = useCallback(() => {
    window.focus(); // Make sure to set focus back to page
    isMouseOver.current = false;
  }, []);
  return { onMouseOver, onMouseOut };
};
