import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { EmojiPicker, EmojiSkin } from 'src/common/components/emoji-widget';
import { frequently } from 'src/common/components/emoji-widget/emoji-mart';
import { getRandomEmoji } from 'src/common/components/emoji-widget/emoji-mart/utils';
import type { ImageInfo } from 'src/common/components/gallery';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tab } from 'src/common/components/tab/tab';
import { TabPane } from 'src/common/components/tab/tab-panel';
import { InputExternalLink } from 'src/editor/component/block-empty-view/input-external-link';
import { ImageUploadPanel } from './image-upload-panel';

const INDEX_EMOJI = 0; // 上传emoji index
/**
 * 这是一个emoji 扩展panel，包含上传功能
 */
export const IconPanel: FC<{
  onSelectEmoji?: (emojiStr: string, isFromRandom?: boolean) => void;
  onSelectCustomIcon?: (imagePath: string, isFromRandom?: boolean) => void;
  onUploadFile?: (file: File) => void; // 用户选择了上传图片
  onInputUrl?: (url: string) => void;
  onSelectRecentlyImage?: (image: ImageInfo) => void; // 用户选择最近
  hideRemoveBtn?: boolean;
  onRemoveBtnClick?: () => void;
  showRecently?: boolean;
  hideRandom?: boolean;
  inputValue?: string;
}> = (props) => {
  const openModal = useOpenModal();
  const isFirstRandom = useRef(true);
  const [tabIndex, setTabIndex] = useState(0);
  const { showRecently = true } = props;
  return (
    <Tab
      onChangeTab={setTabIndex}
      className={cx('next-modal h-[426px] w-[414px]', {
        'overflow-scroll': tabIndex === 2,
      })}
      customButtons={() => {
        return (
          <div className="mr-2 flex">
            {tabIndex === INDEX_EMOJI && (
              <div
                className="animate-hover cursor-pointer items-center rounded py-1.5 px-1 flex-shrink-0 space-x-1"
                style={{ display: props.hideRandom ? 'none' : 'flex' }}
                onClick={() => {
                  const emojiStr = getRandomEmoji(isFirstRandom.current);
                  isFirstRandom.current = false;
                  props.onSelectEmoji && props.onSelectEmoji(emojiStr, true);
                }}
              >
                <Icon size="middle" name="MIcEmojiRandom"></Icon>
                <div className="text-t2 text-grey3">随机选择</div>
              </div>
            )}
            <div
              className="animate-hover ml-1 cursor-pointer items-center rounded py-1.5 px-1 flex-shrink-0 space-x-1"
              style={{ display: props.hideRemoveBtn ? 'none' : 'flex' }}
              onClick={props.onRemoveBtnClick}
            >
              <Icon size="middle" name="MIcClear"></Icon>
              <div className="text-t2 text-grey3">清除</div>
            </div>
          </div>
        );
      }}
    >
      <TabPane title="Emoji">
        <EmojiPicker
          useButton={true}
          onSelect={(emoji, event, fromFrequently) => {
            if (!fromFrequently && event && emoji.skin) {
              openModal.dropdown({
                popcorn: event.currentTarget,
                placement: 'bottom',
                content: ({ onCloseModal }) => (
                  <EmojiSkin
                    emoji={emoji}
                    onSelect={(emojiData) => {
                      if (props.onSelectEmoji) {
                        onCloseModal();
                        props.onSelectEmoji(emojiData.native);
                        frequently.add(emojiData); // 把选中的肤色emoji添加到常用列表
                      }
                    }}
                  />
                ),
              });
              return true;
            }
            if (props.onSelectEmoji) {
              emoji.native && props.onSelectEmoji(emoji.native);
            }
          }}
        />
      </TabPane>
      <TabPane title="Icons">
        <EmojiPicker
          emojiSize={20}
          useCustomIcon={true}
          useButton={true}
          onSelect={(emoji, event, fromFrequently) => {
            if (!fromFrequently && event && emoji.skin) {
              openModal.dropdown({
                popcorn: event.currentTarget,
                placement: 'bottom',
                content: ({ onCloseModal }) => (
                  <EmojiSkin
                    emoji={emoji}
                    onSelect={(emojiData) => {
                      if (props.onSelectEmoji) {
                        onCloseModal();
                        props.onSelectEmoji(emojiData.native);
                        frequently.add(emojiData); // 把选中的肤色emoji添加到常用列表
                      }
                    }}
                  />
                ),
              });
              return true;
            }
            if (props.onSelectEmoji && !emoji.imgPath) {
              props.onSelectEmoji(emoji.native);
            }
            if (props.onSelectCustomIcon) {
              emoji.imgPath && props.onSelectCustomIcon(emoji.imgPath);
            }
          }}
        />
      </TabPane>
      <TabPane title="上传">
        <ImageUploadPanel
          tip="建议上传 280 x 280 像素的图片"
          showRecently={showRecently}
          onSelectImage={props.onSelectRecentlyImage}
          fileType="icon"
          onUploadFile={props.onUploadFile}
          style={{
            height: '254px',
          }}
        />
      </TabPane>
      <TabPane title="链接">
        <InputExternalLink
          defaultValue={props.inputValue}
          placeholder="输入图标链接"
          onInputUrl={(value) => props.onInputUrl?.(value)}
        />
      </TabPane>
    </Tab>
  );
};
