import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Icon } from 'src/common/components/icon';

interface Props {
  placeHolder?: string;
  title?: React.ReactNode;
  className?: string;
  onClick: (e: React.MouseEvent) => void;
  // default true
  showBorder?: boolean;
}
export const Select = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { showBorder = true } = props;
  const divRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => divRef.current!,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div
      ref={divRef}
      className={cx(
        {
          'cursor-pointer': props.title,
        },
        props.className
      )}
      onClick={props.onClick}
    >
      <div
        className={cx('flex items-center justify-between w-full pl-2 pr-1 py-1 rounded text-t2', {
          'text-grey4': !props.title && props.placeHolder,
          border: showBorder,
        })}
      >
        <span>{props.title || props.placeHolder}</span>
        <Icon name={'IcArrowShow'} size="middle" className="text-grey2" />
      </div>
    </div>
  );
});
