import { useOpenModal } from '@flowus/common/next-modal';
import type { CollectionViewDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import React from 'react';
import { useBitable } from 'src/bitable/context';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import {
  isNestedStyleViewType,
  useOpenSubItem,
  useSubitemSetting,
  useSubitemStyle,
} from 'src/hooks/block/use-open-subitem';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { transaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import { SubitemSetting } from './subitem-setting';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import { useRemoveProperty } from 'src/hooks/block/use-remove-property';
import { Button } from '@flowus/common/components';
import { Images } from 'src/image';
import { v4 as uuidV4 } from 'uuid';
import { useUpdatePropertyVisible } from 'src/hooks/block/use-update-property-visible';
import type { SettingProps } from '../type';

export const SubItemMenu: FC<SettingProps> = (props) => {
  const { collectionId } = useBitable();
  const isOpenSubItem = useOpenSubItem(collectionId);
  if (isOpenSubItem) {
    return <Menu />;
  }
  return <NotOpen {...props} />;
};
const itemStyleValue: Record<
  Exclude<CollectionViewDTO['format']['subItemstyle'], undefined>,
  string
> = {
  Nested: '逐层嵌套',
  Card: '卡片属性',
  ParentOnly: '仅父记录',
  Flattenlist: '扁平列表',
};

const Menu: FC = () => {
  const { viewId, collectionId } = useBitable();
  const itemStyle = useSubitemStyle(viewId);
  const openModal = useOpenModal();
  const subitemSetting = useSubitemSetting(collectionId);
  const removeProperty = useRemoveProperty();
  const openSelectItemStyle = (e: React.MouseEvent) => {
    const viewType = cache.collectionViews[viewId]?.type;
    const items = Object.entries(itemStyleValue)
      .filter(([key]) => {
        // 如果不是表格、目录、时间轴视图 则不显示逐层嵌套
        if (key === 'Nested' && !isNestedStyleViewType(viewType)) {
          return false;
        }
        // 如果是表格、目录、时间轴视图 则不显示card属性样式
        if (key === 'Card' && isNestedStyleViewType(viewType)) {
          return false;
        }
        return true;
      })
      .map(([key, value]) => {
        const item: ListItem = {
          type: ListItemType.OPERATION,
          data: {
            title: value,
            selected: key === itemStyle,
            onClick: () => {
              updateViewFormat(viewId, {
                subItemstyle: key as any,
              });
            },
          },
        };
        return item;
      });
    openModal.dropdown({
      popcorn: e.currentTarget,
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-[200px] py-1"
            items={items}
            onItemClick={() => onCloseModal()}
          />
        );
      },
    });
  };
  const openSubitemSetting = (e: React.MouseEvent) => {
    openModal.dropdown({
      offset: [0, -40],
      popcorn: e.currentTarget,
      content: () => {
        return <SubitemSetting />;
      },
    });
  };
  const openCloseSubitemModal = () => {
    if (!subitemSetting) return;
    if (subitemSetting.parentPropertyId) {
      const { propertySchema: parentSchama } = getPropertySchema(
        collectionId,
        subitemSetting.parentPropertyId
      );
      openModal.warning({
        title: '是否确认关闭子记录？',
        content: `关闭子记录功能后会同时删除表中的 ${parentSchama?.name} 属性`,
        confirmText: '关闭子记录并删除属性',
        confirm: () => {
          if (subitemSetting.childPropertyId) {
            removeProperty(collectionId, subitemSetting.childPropertyId);
          }
          if (subitemSetting.parentPropertyId) {
            removeProperty(collectionId, subitemSetting.parentPropertyId);
          }
        },
      });
    }
  };

  return (
    <div className="text-t2">
      <div className="flex h-10 w-full items-center justify-between px-4">
        <span className="">子记录样式</span>
        <button className="flex items-center" onClick={openSelectItemStyle}>
          {itemStyleValue[itemStyle]}
          <Icon name="IcArrowDown01" className="ml-2" size="xxxsmall" />
        </button>
      </div>
      <div
        className="animate-hover flex h-10 w-full items-center justify-between px-4"
        onClick={openSubitemSetting}
      >
        <span>子记录属性</span>
      </div>
      <div
        className="border-t px-4 w-full h-12 animate-hover flex items-center rounded-none"
        onClick={openCloseSubitemModal}
      >
        <Icon name={'IcTrash'} size="small" />
        <div className="ml-1">关闭子记录</div>
      </div>
    </div>
  );
};

const NotOpen: FC<SettingProps> = (props) => {
  const { viewId, collectionId } = useBitable();
  const createProperty = useCreateProperty();

  const openSubitemFeature = () => {
    const parentPropertyId = uuidV4();
    const returnId = createProperty({
      newPropertyId: parentPropertyId,
      collectionId,
      viewId,
      propertyType: CollectionSchemaType.RELATION,
      visible: false,
      propertyName: '父记录',
      partialSchema: {
        collectionId,
        pairProperty: parentPropertyId,
      },
    });
    if (!returnId) return;
    if (parentPropertyId) {
      transaction(() => {
        updateBlock(collectionId, {
          data: {
            format: {
              openSubItem: {
                parentPropertyId,
              },
            },
          },
        });
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn('something wrong');
    }
    props.onCloseModal?.();
  };

  return (
    <div className="px-4 space-y-4 pb-4">
      <div className="text-t2 text-grey2 flex items-center h-10">
        使用子记录功能可以对记录进行分解，大事化小
      </div>
      <img className="w-[360px]" src={Images.subitemFeature}></img>
      <Button className="w-full" colorType="active" onClick={openSubitemFeature}>
        开启子记录功能
      </Button>
    </div>
  );
};
