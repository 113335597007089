import { cx } from '@flowus/common/cx';
import type { BlockSearchDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import type { IconName } from '../../icon';
import { Icon } from '../../icon';
import { PageNavigator } from '../../page-navigator';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

/**
 * 网盘文件夹搜索item
 */
interface SearchDriveScheme {
  uuid: string;
  title: string;
  iconName: IconName;
  navs?: BlockSearchDTO['navs'];
}
export const SearchFileItem: FC<ElementItemProps<SearchDriveScheme>> = (props) => {
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;
  const showNavs = dataScheme.navs && dataScheme.navs.length > 0;

  return (
    <div
      {...props.attribute}
      onClick={(e) => {
        props.onItemClick && props.onItemClick(props.listItem, props.index, e);
      }}
      className={cx(
        listViewItemDefaultClassName,
        'flex flex-col justify-center rounded cursor-pointer active-bg animate-hover',
        showNavs ? 'h-[58px]' : 'h-10',
        { 'normal-bg': props.selected }
      )}
    >
      <div className="flex flex-row items-center">
        <Icon name={dataScheme.iconName} size="middle" />
        <span className="ml-2 text-black text-t2 text-ellipsis">{dataScheme.title}</span>
      </div>
      <div className="flex flex-col ml-7">
        {dataScheme.navs && dataScheme.navs.length > 0 && <PageNavigator navs={dataScheme.navs} />}
      </div>
    </div>
  );
};
