import { memo, useMemo } from 'react';
import { covertBlockToTitle } from 'src/editor/component/menu-list/menu-item';
import { textToSegments } from 'src/editor/utils/editor';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

export const FreezeNode: MindNodeElement = memo((props) => {
  const blockNameSegments = useMemo(() => textToSegments(covertBlockToTitle(props.id)), [props.id]);
  return (
    <NodeWrapper id={props.id} level={props.level}>
      <MindMapRichText
        uuid={props.id}
        className="whitespace-pre-wrap break-words"
        segments={blockNameSegments}
        readonly={true}
      />
    </NodeWrapper>
  );
});
