import type { HTMLAttributes, MouseEvent, PointerEvent } from 'react';
import { useCallback, useRef } from 'react';

interface MoveProps {
  onMoveStart?: () => void;
  onMove?: (e: { deltaX: number; deltaY: number }) => void;
  onMoveEnd?: () => void;
  moveContainer?: Element | null;
}

export const useMove = (props: MoveProps): { moveProps: HTMLAttributes<HTMLElement> } => {
  const lastMovePos = useRef<{
    deltaX: number;
    deltaY: number;
  } | null>(null);
  const { onMoveStart, onMove, onMoveEnd, moveContainer } = props;

  const handleMove = useCallback(
    (event: MouseEvent) => {
      onMove?.({
        deltaX: event.clientX - (lastMovePos.current?.deltaX || 0),
        deltaY: event.clientY - (lastMovePos.current?.deltaY || 0),
      });

      lastMovePos.current = {
        deltaX: event.clientX,
        deltaY: event.clientY,
      };
    },
    [onMove]
  );

  const stopMove = useCallback(() => {
    onMoveEnd?.();
    // @ts-ignore next line
    (moveContainer ?? document).removeEventListener('pointermove', handleMove);
    document.removeEventListener('pointerup', stopMove);
  }, [handleMove, moveContainer, onMoveEnd]);

  const onPointerDown = useCallback(
    (e: PointerEvent<HTMLElement>) => {
      onMoveStart?.();
      e.stopPropagation();
      e.preventDefault();
      lastMovePos.current = {
        deltaX: e.nativeEvent.clientX,
        deltaY: e.nativeEvent.clientY,
      };
      // @ts-ignore next line
      (moveContainer ?? document).addEventListener('pointermove', handleMove);
      document.addEventListener('pointerup', stopMove);
    },
    [onMoveStart, moveContainer, handleMove, stopMove]
  );

  return {
    moveProps: {
      onPointerDown,
    },
  };
};
