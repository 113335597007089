import type { Context } from '../../core/context';
import type { IPropertyListDescriptor } from '../IPropertyDescriptor';
import { PropertyDescriptorParsingType } from '../IPropertyDescriptor';
import type { CSSValue } from '../syntax/parser';
import { parseFunctionArgs } from '../syntax/parser';
import type { LengthPercentageTuple } from '../types/length-percentage';
import { isLengthPercentage, parseLengthPercentageTuple } from '../types/length-percentage';
export type BackgroundPosition = BackgroundImagePosition[];

export type BackgroundImagePosition = LengthPercentageTuple;

export const backgroundPosition: IPropertyListDescriptor<BackgroundPosition> = {
  name: 'background-position',
  initialValue: '0% 0%',
  type: PropertyDescriptorParsingType.LIST,
  prefix: false,
  parse: (_context: Context, tokens: CSSValue[]): BackgroundPosition => {
    return parseFunctionArgs(tokens)
      .map((values: CSSValue[]) => values.filter(isLengthPercentage))
      .map(parseLengthPercentageTuple);
  },
};
