import { attributeSort } from '@flowus/common/file-sort';
import { getOrderFolderType } from '@flowus/common/utils/get-folder-sort-type';
import { BlockType, OrderFolderType } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import { useMemo } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { usePickBlock } from 'src/utils/pick-block';

export const useGetFolder = (id: string) => {
  const block = usePickBlock(id, ['data'], ['format']);
  const childBlocks = useObservableStore(
    ({ blocks }) => {
      const block = blocks[id];
      if (!block) return [];
      const _childBlocks: NextBlock[] = [];
      block.subNodes.forEach((childId) => {
        const child = blocks[childId];
        if (child) {
          _childBlocks.push(child);
        }
      });
      return _childBlocks;
    },
    [id],
    { wait: 200, waitMode: 'debounce' }
  );

  const renderList = useMemo(() => {
    const sortFileList = (list: NextBlock[]): NextBlock[] => {
      const { sortType, reverse } = getOrderFolderType(block?.data?.format?.orderFolder);

      const items = list.map((i) => ({
        ...i,
        sortTitle: segmentsToText(i.data.segments),
        sortSize: BlockType.FOLDER === i.type ? i.subNodes.length : i.data.size,
      }));

      let sortKey: keyof typeof items[number] = 'updatedAt';

      switch (sortType) {
        case OrderFolderType.SIZE:
        case OrderFolderType.SIZE_REVERSED: {
          sortKey = 'sortSize';
          break;
        }
        case OrderFolderType.NAME:
        case OrderFolderType.NAME_REVERSED: {
          sortKey = 'sortTitle';
          break;
        }
        default:
        case OrderFolderType.TIME:
        case OrderFolderType.TIME_REVERSED: {
          sortKey = 'updatedAt';
          break;
        }
      }

      const isNumber = ['updatedAt', 'sortSize'].includes(sortKey);

      const sortItem = attributeSort({
        items,
        key: sortKey,
        reverse,
        isNumber,
      });

      return sortItem.map((i) => omit(i, ['sortTitle', 'sortSize']));
    };

    const files = sortFileList(childBlocks.filter((i) => i.type !== BlockType.FOLDER));
    const folder = sortFileList(childBlocks.filter((i) => i.type === BlockType.FOLDER));
    return { folder, files };
  }, [block?.data?.format, childBlocks]);

  return renderList;
};
