import type { FC } from 'react';

interface Props {
  readonly?: boolean;
}

export const DriveEmpty: FC<Props> = (props) => {
  const { readonly } = props;

  return (
    <div className="cursor-default login-wrapper">
      <div className="text-[70px] leading-[80px]">☕️</div>
      <div className="text-grey4 text-t1">当前文件夹为空</div>
      {!readonly && (
        <div className="flex items-center justify-center h-10">
          <span className="text-grey3 text-t2">拖拽文件到此处或点击“上传”按钮</span>
        </div>
      )}
    </div>
  );
};
