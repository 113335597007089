import { cx } from '@flowus/common/cx';
import type { MouseEvent } from 'react';
import { Icon } from 'src/common/components/icon';
import { useFoldMenuState } from 'src/editor/component/fold-menu';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { $searchParams } from 'src/utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { BlockBefore } from '../uikit/block-before';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

export const FoldListElement: BlockElement = ({ id, root, children }) => {
  const { expand, toggle } = useFoldMenuState(id);
  const block = usePickBlock(id, ['subNodes']);
  const focusEditableAt = useFocusEditableByBlockId();
  const transaction = useTransaction();
  const readonly = useReadonly(id);

  if (!block) return null;

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    transaction(() => {
      const newBlockId = addBlock({}, { parentId: block.uuid, after: block.uuid });
      focusEditableAt(newBlockId, 0);
    });
  };

  const isExpand = $searchParams.print || expand;

  return (
    <BlockDrop id={id} className="my-px" horizontal={root}>
      <div
        className={cx(
          'block-has-icon-container ignore-focus-click flex-1 overflow-hidden',
          readonly && 'animate-click select-auto'
        )}
        onClick={() => {
          if (readonly) {
            toggle();
          }
        }}
      >
        <BlockBefore data-no-cancel-selected className="block-before-icon-mt">
          <span className="flex animate-hover">
            <Icon
              size="large"
              name="IcToggleBlack"
              onClick={toggle}
              className={cx('transition duration-150 cursor-pointer', {
                'rotate-90': isExpand,
                'opacity-30': block.subNodes.length === 0,
              })}
            />
          </span>
        </BlockBefore>
        <Editable className="self-start mr-4 flex-1 w-0" uuid={id} placeholder="折叠列表" />
      </div>
      {isExpand && (
        <BlockChildren blockId={id}>
          {block.subNodes.length ? (
            children
          ) : judgeSharePage() ? null : (
            <div
              className="flex items-center w-full animate-hover cursor-pointer h-9 text-t2 p-1 my-px text-grey3 print:hidden"
              onClick={handleClick}
            >
              内容为空，点击编辑
            </div>
          )}
        </BlockChildren>
      )}
    </BlockDrop>
  );
};
