import { Button } from '@flowus/common/components/button';
import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useClipboard } from '@flowus/common/hooks/use-clipboard';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import type { FC } from 'react';
import { Input } from 'src/common/components/input';
import { publicLink } from 'src/common/const';
import { request } from 'src/common/request/request';
import { HelperIconBox } from 'src/components/helper-icon';
import { useCurrentUser } from 'src/hooks/user';
import { SearchParams, ViewPath } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getLocationOrigin } from 'src/utils/location-utils';
import { SettingModalCommon } from '../common';
import { OrderRecordModal } from './order-record-modal';
import { Icon } from 'src/common/components/icon';

enum CopyType {
  link,
  code,
}

interface Props {}

/** 邀请好友 */
export const InvitationInfo: FC<Props> = () => {
  const currentUser = useCurrentUser();
  const openModal = useOpenModal();
  const linkCopyId = useClipboard();
  const codeCopyId = useClipboard();
  const [userOrder] = useApiState({
    // index, size, type
    getApi: () => request.infra.distributionAlliancePayRecords(1, 1, 1),
  });
  const [pageOrder] = useApiState({
    getApi: () => request.infra.distributionAlliancePayRecords(1, 1, 0),
  });

  const link = `${getLocationOrigin()}${ViewPath.login}?${SearchParams.inviteCode}=${
    currentUser.inviteCode
  }`;

  const handleCopy = (type: CopyType) => {
    bizTracker.event(type === CopyType.link ? 'invite_copylink' : 'invite_copycode');
  };

  const openRecordModal = (type: 'user' | 'page') => {
    openModal.modal({
      modalId: Modals.ORDER_RECORD_MODAL,
      content: () => <OrderRecordModal type={type} />,
    });
  };

  return (
    <div className="space-y-2.5">
      <div className="py-4 border-b flex items-center justify-between">
        <div>空间会员佣金</div>
        <div
          className="space-x-1 flex items-center animate-click"
          onClick={() => openRecordModal('user')}
        >
          <span>
            累计空间会员佣金奖励：<span className="text-t2-medium">{userOrder?.total} 单</span>
          </span>
          <SettingModalCommon.Arrow />
        </div>
      </div>
      <div className="relative rounded border px-4 pt-10 pb-5">
        <span className="absolute top-0 left-0 text-t3 bg-active_color text-white px-1.5 py-px rounded-sm">
          方式一
        </span>
        <div className="mb-2.5">
          新用户通过您的邀请、协作链接注册，或者通过拷贝您的页面注册，可获赠专属会员优惠及赠送时长券，对方使用此券升级为空间会员，您则获得20%的佣金奖励。
        </div>
        <Input
          value={`${location.origin}${ViewPath.login}?${SearchParams.inviteCode}=${currentUser.inviteCode}`}
          inputClassName="h-8 text-black"
          className="overflow-hidden"
          readonly
          addonAfter={
            <>
              <div className="h-8 bg-grey6 w-px" />
              <Button
                id={linkCopyId}
                className="border-none rounded-none"
                onClick={() => handleCopy(CopyType.link)}
                data-clipboard-text={link}
              >
                复制链接
              </Button>
              <div className="h-8 bg-grey6 w-px" />
              <Button
                id={codeCopyId}
                className="border-none rounded-none"
                onClick={() => handleCopy(CopyType.code)}
                data-clipboard-text={currentUser.inviteCode}
              >
                复制邀请码
              </Button>
            </>
          }
        />
      </div>
      <div className="relative rounded border px-4 pt-10 pb-5">
        <span className="absolute top-0 left-0 text-t3 bg-black-base text-white px-1.5 py-px rounded-sm">
          方式二
        </span>
        <div className="mb-2.5">
          新用户通过访问您分享的任意文档页面，均可获赠专属会员优惠及赠送时长券，对方使用此券升级为空间会员，您则获得20%的佣金奖励。
        </div>
        <HelperIconBox
          className="text-grey3"
          beforeIcon
          linkContainer
          link={publicLink.helperShare}
        >
          如何分享页面
        </HelperIconBox>
      </div>
      <div className="py-4 border-b flex items-center justify-between">
        <div>付费订阅佣金</div>
        <div
          className="space-x-1 flex items-center animate-click"
          onClick={() => openRecordModal('page')}
        >
          <span>
            累计邀请付费订阅佣金奖励：
            <span className="text-t2-medium">{pageOrder?.total} 单</span>
          </span>
          <Icon className="text-grey3" name="IcArrowDateNext" size="small" />
        </div>
      </div>
      <div className="relative rounded border px-4 pt-10 pb-5">
        <span className="absolute top-0 left-0 text-t3 bg-active_color text-white px-1.5 py-px rounded-sm">
          方式一
        </span>
        <div className="mb-2.5 space-x-2 justify-between">
          <span>
            好友支付您开通了「分享有赏」的付费订阅文档，您可享受订阅金额的20%-50%的佣金奖励。
          </span>
          <a
            href={publicLink.subscribeCenter}
            target="_blank"
            className="text-active_color animate-click"
          >
            去知识库分享
          </a>
        </div>
        <HelperIconBox
          className="text-grey3 inline-flex items-center"
          beforeIcon
          linkContainer
          link={publicLink.rebateRule}
        >
          分享有赏是什么？
        </HelperIconBox>
      </div>
    </div>
  );
};
