import type { Immutable } from 'immer';
import type { MindMapEngine } from '../mind-map-engine';
import type { CanvasStyle, Line, MindNode, MindNodeDragInfo, Rect, Where } from '../types';
import { getMindMapNextNode, getMindMapPreNode, intersectRect, walk } from '../util';
import type { LayoutProviderOption } from './base-layout-provider';
import { CANVAS_PADDING } from './canvas-graph';
import { LogicDiagramLeftLayoutProvider } from './logic-diagram-left-layout-provider';

/**
 * 右边往左发展的脑图
 */
export class LogicDiagramRightLayoutProvider extends LogicDiagramLeftLayoutProvider {
  constructor(engine: MindMapEngine, opt: LayoutProviderOption) {
    super(engine, opt);
  }

  computedBaseValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id, parentId, isRoot) => {
        const node = state.mindNodes[id];
        const parentNode = state.mindNodes[parentId];
        if (!node) return true;
        if (isRoot) {
          node.left = node.regionWidth - node.width;
          node.top = -node.height * 0.5 + node.regionHeight * 0.5;
        } else if (parentNode) {
          node.left = parentNode.left - node.width - this.opt.horizontalMargin;
        }
        if (!node.expanded) {
          return true;
        }
      },
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return;
        if (!node.expanded) {
          node.childrenHeight = 0;
          return true;
        }
        let validChildrenCount = 0;
        //计算children占据的区域高度
        node.childrenHeight =
          node.childrenIds.reduce((h, cId) => {
            const c = state.mindNodes[cId];
            if (!c) return h;
            validChildrenCount++;
            return h + c.height;
          }, 0) +
          (validChildrenCount + 1) * this.opt.verticalMargin;
      },
      true,
      0
    );
  };
  updateScrollerPos = (oldRegionWidth: number, oldRegionHeight: number) => {
    const rootNode = this.engine.getRootMindNode();
    const scrollY = (rootNode.regionHeight - oldRegionHeight) / 2;
    const scrollX = rootNode.regionWidth - oldRegionWidth;
    if (scrollY !== 0 || scrollX !== 0) {
      this.scroller?.scrollBy(scrollX, scrollY);
    }
  };

  protected getMindMapCenterPos = (): [number, number] | undefined => {
    if (!this.scroller) {
      return;
    }
    const root = this.engine.getRootMindNode();
    const { scale } = this.engine.getState();
    const scaleFactor = scale * 0.01;
    const rect = this.scroller.getBoundingClientRect();
    let rootCenterX = root.x + (root.width * scaleFactor) / 2;
    const rootCenterY = root.y + (root.height * scaleFactor) / 2;
    if (root.regionWidth * scaleFactor < rect.width) {
      rootCenterX = root.x + root.width * scaleFactor - (root.regionWidth * scaleFactor) / 2;
    }
    return [rootCenterX, rootCenterY];
  };

  getConnectToWhere = (dragInfo: MindNodeDragInfo) => {
    const mindMapState = this.engine.getState();
    const scaleFactor = mindMapState.scale * 0.01;
    const mindNode = mindMapState.mindNodes[dragInfo.id];
    if (!mindNode) return;
    const rootNode = mindMapState.mindNodes[mindMapState.rootId];
    if (!rootNode) return;

    const dragInfoX = dragInfo.left;
    const dragInfoY = dragInfo.top;
    const dragNode = mindMapState.mindNodes[dragInfo.id];
    if (!dragNode) return;
    //超出左侧边界
    if (
      dragInfoX + dragNode.width * scaleFactor <
      rootNode.x +
        rootNode.width * scaleFactor -
        rootNode.regionWidth * scaleFactor -
        CANVAS_PADDING * 0.5
    ) {
      return;
    }
    //超出右侧边界
    if (dragInfoX > rootNode.x) {
      return;
    }
    const rootTop =
      rootNode.y + (rootNode.height * 0.5 - rootNode.regionHeight * 0.5) * scaleFactor;
    //超出上边界
    if (dragInfoY < rootTop - CANVAS_PADDING * 0.5 * scaleFactor) {
      return;
    }
    //超出下边界
    if (dragInfoY > rootTop + (rootNode.regionHeight + CANVAS_PADDING * 0.5) * scaleFactor) {
      return;
    }
    let markNode: Immutable<MindNode> | undefined = undefined;
    const entries = Object.entries(mindMapState.mindNodes);

    let distance = Number.MAX_VALUE;
    for (const [_, node] of entries) {
      //node区域高度
      const nodeRegionHeightRect: Rect = {
        x: node.x + node.width,
        y: node.y + node.height * 0.5 * scaleFactor - node.regionHeight * 0.5 * scaleFactor,
        width: node.width * scaleFactor,
        height: node.regionHeight * scaleFactor,
      };
      if (
        intersectRect(nodeRegionHeightRect, {
          x: dragInfoX,
          y: dragInfoY + (mindNode.height / 2) * scaleFactor,
          width: 1,
          height: 1,
        })
      ) {
        if (node.id === dragInfo.id) return { parentId: node.parentId } as Where;
        if (!node.parentId) return;
        const parentNode = mindMapState.mindNodes[node.parentId];
        if (!parentNode) return;
        return this.findPosition(dragInfo, parentNode);
      }
      //记录一下符合条件的
      const dragEndX = dragInfoX + dragNode.width * scaleFactor;
      if (dragEndX < node.x) {
        const centerDragInfoY = dragInfoY + (dragNode.height / 2) * scaleFactor;
        const nodeCenterY = node.y + (node.height / 2) * scaleFactor;
        const newDistance =
          Math.pow(centerDragInfoY - nodeCenterY, 2) + Math.pow(dragEndX - node.x, 2);
        if (newDistance < distance) {
          distance = newDistance;
          markNode = node;
        }
      }
    }
    if (!markNode) return;
    if (markNode.id === dragInfo.id) return;
    if (!markNode.expanded || markNode.childrenIds.length === 0) {
      return { parentId: markNode.id, first: true } as Where;
    }

    return this.findPosition(dragInfo, markNode);
  };

  protected getDragNodeRect = (id: string) => {
    const node = this.engine.getMindNode(id ?? '');
    if (!node) return;
    const x = node.left + node.width - node.regionWidth;
    const y = node.top + node.height * 0.5 - node.regionHeight * 0.5;
    return { x, y, width: node.regionWidth, height: node.regionHeight };
  };

  protected getLine = (node: Immutable<MindNode>, childNode: Immutable<MindNode>) => {
    const color = this.getLineColor(childNode.id);
    return {
      startX: node.left / this.scale,
      startY: (node.top + node.height / 2) / this.scale,
      endX: (childNode.left + childNode.width) / this.scale,
      endY: (childNode.top + childNode.height / 2) / this.scale,
      debug: `${node.id} - ${childNode.id}`,
      isRoot: !node.parentId,
      color,
    };
  };

  protected getDragLine = (
    dragXInCanvas: number,
    dragYInCanvas: number,
    nodeId: string,
    parentId: string
  ) => {
    const node = this.engine.getMindNode(nodeId);
    if (!node) return;
    const parentNode = this.engine.getMindNode(parentId);
    if (!parentNode) return;
    const color = this.getLineColor(nodeId);
    const line: Line = {
      debug: '',
      startX: parentNode.left / this.scale,
      startY: (parentNode.top + parentNode.height / 2) / this.scale,
      endX: (dragXInCanvas + node.width) / this.scale,
      endY: dragYInCanvas + node.height / 2 / this.scale,
      isRoot: !node.parentId,
      color,
    };
    return line;
  };

  protected drawLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.strokeStyle = line.color;
    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    if (style) {
      Object.assign(ctx, style);
    }
    ctx.moveTo(line.startX, line.startY);
    const lineCenterX = line.startX + (line.endX - line.startX) / 2;
    ctx.lineTo(lineCenterX, line.startY);
    //是否往上
    const isTop = line.endY < line.startY;
    const { round } = this;
    if (Math.abs(line.endY - line.startY) > round) {
      ctx.lineTo(lineCenterX, isTop ? line.endY + round : line.endY - round);
      ctx.arcTo(lineCenterX, line.endY, lineCenterX - round, line.endY, round);
      ctx.lineTo(line.endX, line.endY);
    } else {
      //两者中心点不够一个弧度高，因此用直线连上
      ctx.lineTo(line.endX, line.startY);
    }
  };
  getNextNodeIdByArrowKeyboard = (arrow: 'left' | 'right' | 'up' | 'down', currentId: string) => {
    switch (arrow) {
      case 'left': {
        return this.getMiddleChild(currentId);
      }
      case 'right': {
        return this.getParentId(currentId);
      }
      case 'up': {
        return getMindMapPreNode(this.engine, currentId);
      }
      case 'down': {
        return getMindMapNextNode(this.engine, currentId);
      }
      default:
    }
  };
}
