import type { FC } from 'react';
import { Highlight } from './hight-light';

interface Props {
  text?: string;
  search: string;
  className?: string;
  ignoreUpperCase?: boolean; // 默认不区分大小写
  containerClassName?: string;
}
// plus版，当有一长串文本时，只显示一行，需要把高亮关键字前后部分显示
export const HighlightPlus: FC<Props & { limitLen: number }> = (props) => {
  const { limitLen, text, search, ignoreUpperCase = true, ...rest } = props;
  if (!text) return null;
  const index = (ignoreUpperCase ? text.toUpperCase() : text).indexOf(
    ignoreUpperCase ? search.toUpperCase() : search
  );
  if (index === -1) return <>{text}</>;
  const preSearchIndex = Math.max(0, index - limitLen / 2);
  const afterSearchIndex = Math.min(preSearchIndex + limitLen, text.length);

  return (
    <Highlight
      text={text.slice(preSearchIndex, afterSearchIndex)}
      search={search}
      ignoreUpperCase={ignoreUpperCase}
      {...rest}
    />
  );
};
