import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { shallowEqual } from 'react-redux';
import { useBitable } from 'src/bitable/context';
import { useCheckRecordColor } from 'src/bitable/hooks/use-check-card-color';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getTablePropertyValue } from 'src/hooks/collection-view/get-property-value';
import { getValuesFromGroupValue } from 'src/hooks/collection-view/get-values-from-groupvalue';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { usePickBlock } from 'src/utils/pick-block';
import { RecordCreatedFrom } from '../../table-view/types';
import { InitDateRangeLength, NewCardPlaceholderId, UnitWidthMap, ZoomLevel } from '../const';
import { useTimeline } from '../context';
import { Indicator } from '../indicator';
import { getUnitTime } from '../utils/get-timeline-dates';
import { IndicatorArrow } from './indicator-arrow';
import { PlaceholderCard } from './placeholder-card';
import { RecordCard } from './record-card';
import { useRelationMap } from 'src/bitable/relation-context';
import { emitter } from '@flowus/common';
import { useSubitemSetting, useSubitemStyle } from 'src/hooks/block/use-open-subitem';
import { TextType } from '@next-space/fe-api-idl';
import { useRecordExpand, useSetRecordExpand } from 'src/services/database/state';
import { $searchParams } from 'src/utils';

interface Props {
  recordId: string;
  lastRecordId?: string;
  groupName?: string;
  level?: number;
  relationParentId?: string;
}
export const RecordLine: FC<Props> = memo((props) => {
  const { level } = props;
  const { collectionId, viewId } = useBitable();
  const expand = useRecordExpand(collectionId, viewId, props.recordId) || !!$searchParams.print;
  const setExpand = useSetRecordExpand(collectionId, viewId, props.recordId);
  const { parent2ChildMap } = useRelationMap();
  const childIds = parent2ChildMap?.get(props.recordId) ?? [];

  useEffect(() => {
    const timelineExpand = (data: any) => {
      if (data.recordId === props.recordId) {
        setExpand(data.expand);
      }
    };
    emitter.on('timelineExpand', timelineExpand);
    return () => {
      emitter.off('timelineExpand', timelineExpand);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.recordId]);

  const subitemStyle = useSubitemStyle(viewId);
  const isNested = subitemStyle === 'Nested';
  const showExpandNode = level !== undefined && isNested;

  if (level && level > 10) return null;
  return (
    <>
      <RecordLineInner {...props} />
      {expand && showExpandNode && (
        <>
          {childIds.map((v) => {
            return <RecordLine {...props} recordId={v} key={v} />;
          })}
          <RecordLineInner
            {...props}
            recordId={NewCardPlaceholderId}
            key={NewCardPlaceholderId}
            relationParentId={props.recordId}
          />
          {/* 占位 */}
        </>
      )}
    </>
  );
}, shallowEqual);

export const RecordLineInner: FC<Props> = memo((props) => {
  const { recordId, groupName, lastRecordId, relationParentId } = props;
  const { collectionId, viewId } = useBitable();
  const transaction = useTransaction();
  const readonly = useReadonly(recordId, false);
  const { container, datesContainer, draggingRecordId, zoomLevel, timelineDatesRef } =
    useTimeline();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const block = usePickBlock(recordId, ['data'], ['collectionProperties']);
  const { illegal } = usePermissions(recordId);
  const insertRecord = useInsertRecordUI();
  const [movingStartDate, setMovingStartDate] = useState<number>();
  const subitemSetting = useSubitemSetting(collectionId);

  const { timelineBy, timelineByEnd, recordStartTime, recordEndTime } = useObservableStore(
    ({ collectionViews, blocks }) => {
      const block = blocks[recordId];
      const view = collectionViews[viewId];
      const timelineBy = view?.format.timelineBy ?? '';
      const timelineByEnd = view?.format.timelineByEnd ?? '';
      return {
        timelineBy,
        timelineByEnd,
        recordStartTime: getTablePropertyValue(block, timelineBy) as number | undefined,
        recordEndTime: getTablePropertyValue(block, timelineByEnd) as number | undefined,
      };
    },
    [recordId, viewId]
  );
  useCheckRecordColor(viewId, recordId);

  const initDateRangeLength = timelineBy === timelineByEnd ? 1 : InitDateRangeLength[zoomLevel];

  const handleMouseMove = (event: React.MouseEvent) => {
    if (recordStartTime || recordEndTime) return;
    if (illegal) return;
    if (recordId !== NewCardPlaceholderId && readonly) return;

    if (draggingRecordId.current) return;
    const datesNode = datesContainer.current;
    if (!container.current || !datesNode) return;

    const rect = container.current.getBoundingClientRect();

    const leftDistance = container.current.scrollLeft + event.clientX - rect.x;
    const unitLength = Math.floor(leftDistance / UnitWidthMap[zoomLevel]);
    setMovingStartDate(
      (timelineDatesRef.current[0] as number) + unitLength * getUnitTime(zoomLevel)
    );
  };

  const handleMouseLeave = () => {
    setMovingStartDate(undefined);
  };

  const handleMouseClick = () => {
    if (illegal) return;
    if (recordId !== NewCardPlaceholderId && readonly) return;

    if (movingStartDate) {
      const startTime = movingStartDate;
      const endTime =
        startTime +
        (isDayOrHour ? initDateRangeLength : initDateRangeLength - 1) * getUnitTime(zoomLevel);

      const propertyValues = {
        [timelineBy]: [buildDateSegment({ from: new Date(startTime), includeTime: isDayOrHour })],
        [timelineByEnd]: [buildDateSegment({ from: new Date(endTime), includeTime: isDayOrHour })],
      };

      if (recordId === NewCardPlaceholderId) {
        let otherPropertyValues = {};
        if (relationParentId && subitemSetting?.parentPropertyId) {
          otherPropertyValues = {
            [subitemSetting.parentPropertyId]: [
              {
                type: TextType.LINK_PAGE,
                uuid: relationParentId,
                text: '',
                enhancer: { code: false },
              },
            ],
          };
        }
        void insertRecord({
          viewId,
          where: { after: lastRecordId },
          propertyValues: {
            ...getValuesFromGroupValue({ viewId, groupValue: groupName, isNewRecord: true }),
            ...propertyValues,
            ...otherPropertyValues,
          },
          from: RecordCreatedFrom.TIMELINE,
        });
      } else if (block) {
        transaction(() => {
          updateBlock(recordId, {
            data: {
              collectionProperties: {
                ...(block.data.collectionProperties ?? {}),
                ...propertyValues,
              },
            },
          });
        });
      }

      setMovingStartDate(undefined);
    }
  };

  const hasValidDateRange = recordStartTime || recordEndTime;
  const isDayOrHour = zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR;

  return (
    <div
      data-disable-select
      data-record-id={recordId}
      data-group-value={groupName}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      className={cx(
        'relative flex h-[37px] w-full hover:bg-green-100',
        !hasValidDateRange && 'cursor-pointer',
        recordId === NewCardPlaceholderId && 'h-8'
      )}
    >
      <PlaceholderCard
        recordId={recordId}
        initDateRangeLength={initDateRangeLength}
        movingStartDate={movingStartDate}
        relationParentId={relationParentId}
      />

      {datesContainer.current &&
        movingStartDate &&
        createPortal(
          <Indicator
            startTime={movingStartDate}
            unitLength={isDayOrHour ? initDateRangeLength : initDateRangeLength - 1}
          />,
          datesContainer.current
        )}

      {hasValidDateRange && (
        <>
          <IndicatorArrow recordId={recordId} card={cardRef} />
          <RecordCard recordId={recordId} card={cardRef} />
        </>
      )}
    </div>
  );
}, shallowEqual);
