import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { flatten, isArray, values } from 'lodash-es';
import { useCallback } from 'react';
import { READONLY_PROPERTIES } from 'src/bitable/const';
import { textToSegments } from 'src/editor/utils/editor';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { getSpaceCapacity } from 'src/services/capacity';
import { getCurrentSpaceId } from '../space/get-space';
import { useOpenCapacityDialog } from '../space/use-open-capacity-dialog';
import { getOwnerPage } from './use-get-owner-page';

/**
 * for bitable
 * 更新 block property
 * 因为一个个更新太慢，这个方法支持多条记录更新多个属性的多个值（如果data只有一个的话就所有属性都填充这个data）
 */
export const useUpdatePropertyValue = () => {
  const transaction = useTransaction();
  const updatePropertyValueWithoutTransaction = useUpdatePropertyValueWithoutTransaction();
  const updateProperty = (
    uuids: string | string[],
    newPropertyIds: string | string[],
    data: string | SegmentDTO[] | SegmentDTO[][] | undefined
  ) => {
    transaction(() => {
      updatePropertyValueWithoutTransaction(uuids, newPropertyIds, data);
    });
  };
  return useCallback(updateProperty, [transaction, updatePropertyValueWithoutTransaction]);
};

export const useUpdatePropertyValueWithoutTransaction = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  const checkTotalSize = useCheckTotalSize();
  const updateProperty = (
    uuids: string | string[],
    newPropertyIds: string | string[],
    data: string | SegmentDTO[] | SegmentDTO[][] | undefined
  ) => {
    uuids = isArray(uuids) ? uuids : [uuids];
    const updatePropertyValue = (uuid: string, newPropertiesIds: string[]) => {
      // //先转成最终格式,为了方便计算，data最终也会转成跟newPropertyIds个数一样的value
      let value: SegmentDTO[][] = [];
      if (typeof data === 'string') {
        const segments = textToSegments(data);
        value = Array.from({ length: newPropertiesIds.length }, () => segments);
      } else if (Array.isArray(data)) {
        if (Array.isArray(data[0])) {
          value = data as SegmentDTO[][];
        } else {
          value = Array.from({ length: newPropertiesIds.length }, () => data as SegmentDTO[]);
        }
      } else {
        // undefined
        value = Array.from({ length: newPropertiesIds.length }, () => []);
      }
      // 更新的value必然与property个数相同
      if (value.length !== newPropertiesIds.length) {
        // eslint-disable-next-line no-console
        __HOST_LOCAL__ && console.warn('更新值个数与属性个数不一致');
        return;
      }

      const block = getState().blocks[uuid];
      const collectionId = getOwnerPage(uuid);
      if (!collectionId) return;
      const collection = getState().blocks[collectionId];
      if (!block || !collection) return;
      const newProperties = newPropertiesIds.reduce((pre, cur, index) => {
        const cellType = collection.data.schema?.[cur]?.type;
        if (cellType === CollectionSchemaType.TITLE) {
          updateBlock(uuid, {
            data: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              segments: value[index]!,
            },
          });
          return pre;
        }
        // 不更新readonly的属性，否则会有问题(会发送无用的op导致最近修改人变更)
        if (cellType && READONLY_PROPERTIES.includes(cellType)) return pre;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        pre[cur] = value[index]!;
        return pre;
      }, {} as Record<string, SegmentDTO[]>);

      if (Object.entries(newProperties).length > 0) {
        const properties = block.data.collectionProperties;
        const total = checkTotalSize(newProperties);
        const capacityInfo = getSpaceCapacity(getCurrentSpaceId(), { isDatabase: true });
        if (
          total > 0 &&
          total * uuids.length + capacityInfo.currentCapacity > capacityInfo.maxCapacity
        ) {
          openCapacityDialog({
            title: '复制块失败',
            maxCapacity: capacityInfo.maxCapacity,
            isNovice: capacityInfo.isNovice,
          });
          return;
        }

        updateBlock(uuid, {
          data: {
            collectionProperties: {
              ...properties,
              ...newProperties,
            },
          },
        });
      }
    };
    uuids.forEach((id) => {
      if (Array.isArray(newPropertyIds)) {
        updatePropertyValue(id, newPropertyIds);
      } else {
        updatePropertyValue(id, [newPropertyIds]);
      }
    });
  };
  return useCallback(updateProperty, [checkTotalSize, openCapacityDialog]);
};

const useCheckTotalSize = () => {
  return useCallback((property: Record<string, SegmentDTO[]>) => {
    let total = 0;
    flatten(values(property)).forEach((i) => {
      total += i.size || 0;
    });
    return total;
  }, []);
};
