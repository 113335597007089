import { Tab, TabPane } from '@flowus/common/components';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import { useState, type FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { antiCycleSet_ResourceCenter } from 'src/components/new-user-task/create-new-page-guide';
import { Images } from 'src/image';
import { TemplateCenter } from '../template';
import { TemplateSearch } from '../template/header';
import type { From } from '../template/types';

interface Props {
  keyword?: string;
  from: From;
  activeTab?: 'template';
  onlyShowTab?: 'template';
  targetId?: string;
}
export const ResourceCenter: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const { onlyShowTab } = props;
  const [currentCategory, setCurrentCategory] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  const closeBtn = (
    <Icon
      name="IcBannerClose"
      size="middle"
      className="animate-hover animate-click"
      onClick={() => openModal.closeModal(Modals.TEMPLATE_CENTER)}
    />
  );

  return (
    <div>
      <Tab
        onChangeTab={(index) => {
          setActiveIndex(index);
        }}
        childrenClassName="h-full"
        headerClassName="px-2"
        active={activeIndex}
        className="w-[90vw] h-[90vh]"
        customButtons={() => {
          return (
            <div className="flex items-center space-x-1">
              <TemplateSearch keyword={props.keyword} currentCategory={currentCategory} />
              {closeBtn}
            </div>
          );
        }}
      >
        {(onlyShowTab === undefined || onlyShowTab === 'template') && (
          <TabPane
            title={
              <div className="flex items-center space-x-1">
                <img src={Images.templateEmpty} className="w-5 h-5" alt="模板icon" />
                <h2>模板库</h2>
              </div>
            }
          >
            <TemplateCenter
              targetId={props.targetId}
              from={props.from}
              onCategoryChange={(category) => {
                setCurrentCategory(category);
              }}
            />
          </TabPane>
        )}
      </Tab>
    </div>
  );
};

antiCycleSet_ResourceCenter(ResourceCenter);
