import type {
  CollectionSchema,
  CollectionViewGroupBy,
  CollectionViewGroups,
  UserDTO,
} from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { uniqBy } from 'lodash-es';
import { fastEqual } from '@flowus/common/utils/tools';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import type { NextBlock } from 'src/redux/types';
import { EMPTY_GROUP_NAME } from './const';
import { dateGroupSort, numberGroupSort, relationGroupSort, textGroupSort } from './group-sorter';
import { selectBlockGroupNames } from './select-block-group-values';

export const checkGroup = (
  sortedRecordIds: string[],
  blocks: Record<string, NextBlock>,
  users: Record<string, UserDTO>,
  collectionId: string,
  oldGroups: CollectionViewGroups,
  groupSchema: CollectionSchema,
  groupBy: CollectionViewGroupBy
) => {
  const groupProperty = groupBy.property ?? '';
  const schemaType = groupSchema.type;

  let newViewGroups: CollectionViewGroups = [...oldGroups];
  // 类型转换或重新设置分组属性后需要重新生成组
  if (oldGroups.length === 0 || oldGroups[0]?.propertyType !== schemaType) {
    newViewGroups = [];
  }

  let sortType: 'text' | 'number' | 'date' | 'relation' | undefined = undefined;
  if (schemaType === CollectionSchemaType.FORMULA) {
    const formulaTool = getFormulaTool(collectionId);

    const resultType = formulaTool.getTypeAsCollectionSchemaType(groupProperty);
    const oldResultType = newViewGroups[0]?.resultType;
    // 计算结果不一致需要重新分组
    if (resultType !== oldResultType) {
      newViewGroups = [];
    }

    newViewGroups = updateGroups(
      sortedRecordIds,
      blocks,
      users,
      collectionId,
      oldGroups,
      groupSchema,
      groupBy,
      newViewGroups,
      resultType
    );

    if (resultType === CollectionSchemaType.TEXT) sortType = 'text';
    if (resultType === CollectionSchemaType.NUMBER) sortType = 'number';
    if (resultType === CollectionSchemaType.DATE) sortType = 'date';
  } else {
    newViewGroups = updateGroups(
      sortedRecordIds,
      blocks,
      users,
      collectionId,
      oldGroups,
      groupSchema,
      groupBy,
      newViewGroups
    );

    if (schemaType === CollectionSchemaType.RELATION) sortType = 'relation';
    if (schemaType === CollectionSchemaType.NUMBER) sortType = 'number';
    if (
      schemaType === CollectionSchemaType.CREATED_AT ||
      schemaType === CollectionSchemaType.UPDATED_AT ||
      schemaType === CollectionSchemaType.DATE
    ) {
      sortType = 'date';
    }
    if (
      schemaType === CollectionSchemaType.TITLE ||
      schemaType === CollectionSchemaType.TEXT ||
      schemaType === CollectionSchemaType.PHONE ||
      schemaType === CollectionSchemaType.URL ||
      schemaType === CollectionSchemaType.EMAIL
    ) {
      sortType = 'text';
    }
  }
  // 如果排序后再计算的话needUpdateGroups的话，可能会导致不断的发tx
  // 更新的逻辑蛋疼了，在useEffect上更新，这意味着稍有差错就会不断的发tx
  const noSortGroup = [...newViewGroups];
  if (sortType === 'text') {
    textGroupSort(newViewGroups, groupBy.sort);
  } else if (sortType === 'number') {
    numberGroupSort(newViewGroups, groupBy.sort);
  } else if (sortType === 'date') {
    dateGroupSort(newViewGroups, groupBy.sort);
  } else if (sortType === 'relation') {
    relationGroupSort(newViewGroups, groupBy.sort);
  }
  return { needUpdateGroups: !fastEqual(oldGroups, noSortGroup), newViewGroups };
};

const updateGroups = (
  sortedRecordIds: string[],
  blocks: Record<string, NextBlock>,
  users: Record<string, UserDTO>,
  collectionId: string,
  oldGroups: CollectionViewGroups,
  groupSchema: CollectionSchema,
  groupBy: CollectionViewGroupBy,
  groups: CollectionViewGroups,
  resultType?: string
) => {
  const isInitGroups = groups.length === 0;
  const groupProperty = groupBy.property ?? '';
  const schemaType = groupSchema.type;

  let groupValues: string[] = [];

  if (
    schemaType === CollectionSchemaType.SELECT ||
    schemaType === CollectionSchemaType.MULTI_SELECT
  ) {
    groupValues = groupSchema.options?.map((option) => option.value) ?? [];
    groups = groups.filter((group) => !group.value || groupValues.includes(group.value));
  } else if (
    schemaType === CollectionSchemaType.CHECKBOX ||
    (schemaType === CollectionSchemaType.FORMULA && resultType === CollectionSchemaType.CHECKBOX)
  ) {
    groupValues = ['YES', 'NO'];
  } else {
    sortedRecordIds.forEach((recordId) => {
      const values = selectBlockGroupNames({
        blocks,
        users,
        collectionId,
        recordId,
        groupProperty,
        groupBy,
      });
      groupValues.push(...values);
    });
  }

  const oldValueSet = new Set<string>();
  oldGroups.forEach((item) => item.value && oldValueSet.add(item.value));

  new Set(groupValues).forEach((value) => {
    if (!oldValueSet.has(value) && value !== EMPTY_GROUP_NAME) {
      const group: CollectionViewGroups[number] = {
        property: groupProperty,
        propertyType: schemaType,
        visible: true,
        value,
      };
      if (resultType) {
        group.resultType = resultType;
      }
      groups.push(group);
    }
  });

  if (isInitGroups && !groups.find((group) => !group.value)) {
    if (
      schemaType === CollectionSchemaType.TITLE ||
      schemaType === CollectionSchemaType.TEXT ||
      schemaType === CollectionSchemaType.PHONE ||
      schemaType === CollectionSchemaType.URL ||
      schemaType === CollectionSchemaType.EMAIL ||
      schemaType === CollectionSchemaType.DATE ||
      schemaType === CollectionSchemaType.PERSON ||
      schemaType === CollectionSchemaType.SELECT ||
      schemaType === CollectionSchemaType.MULTI_SELECT ||
      schemaType === CollectionSchemaType.RELATION ||
      schemaType === CollectionSchemaType.NUMBER ||
      (schemaType === CollectionSchemaType.FORMULA && resultType !== CollectionSchemaType.CHECKBOX)
    ) {
      groups.unshift({
        property: groupProperty,
        propertyType: schemaType,
        visible: true,
        resultType,
      });
    }
  }

  groups = uniqBy(groups.filter(Boolean), (group) => {
    if (!group.value) return undefined;
    return group.value;
  });

  return groups;
};
