import { cx } from '@flowus/common/cx';
import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import {
  newContent,
  newElement,
  newText,
  normalizeFormat,
  registerElementMeta,
} from '@next-space/fe-inlined';
import { lookupEditorElement } from '@next-space/fe-inlined/dom-utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';

import { Latex } from '../../component/latex';
import { EditableContext } from '../uikit/editable-context';
import { getEditorModelByEditorKey } from '../uikit/editable-models';
import { INLINE_EQUATION_TAG } from './const';
import type { InlinePlugin } from './inline-plugin';
import { useComputeTextStyle } from './utils';

const INLINE_EQUATION_META: IElementMeta = {
  tag: INLINE_EQUATION_TAG,
  hasContent: false,
  legalize: (element) => {
    const text = element.props.text as string;
    return newContent([newText(`${text}`)]);
  },
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
  toString: (element) => {
    return element.props.text as string;
  },
};

registerElementMeta(INLINE_EQUATION_META);

/**
 * 行内公式，三个重要的参数
 * @text 公式信息
 * @open 是否自动打开公式输入框
 */
export const InlineEquation: IElementComponent = (props) => {
  const { htmlDataProps, textFormat: textFormat0, open, text = '', baseOffset, noStyle } = props;
  const focusEditableAt = useFocusEditableByBlockId();
  const blockRef = useRef<HTMLLIElement>(null);
  const [state, setState] = useState(text);
  const textFormat = normalizeFormat(textFormat0 as any);
  const textStyle = useComputeTextStyle(textFormat);
  const { readonly, interactable } = useContext(EditableContext);

  useEffect(() => setState(text), [text]);

  const getEditorModel = () => {
    const editorElement = lookupEditorElement(blockRef.current);
    if (!editorElement) return;
    const editorKey = editorElement.getAttribute('data-editor');
    if (!editorKey) return;
    return getEditorModelByEditorKey(editorKey);
  };

  const onChange = (value?: string) => {
    const editorModel = getEditorModel();
    if (!editorModel) return;
    const { selection } = editorModel;
    if (!selection) return;

    const cursorLeft = editorModel.createCursor(baseOffset, 'left');
    const cursorRight = editorModel.createCursor(baseOffset + 1, 'left');

    const cleanup = () => {
      cursorLeft.release();
      cursorRight.release();
    };

    if (!stringToLowerCaseAndRemoveSpace(value)) {
      editorModel.performChange((ctx) => {
        ctx.shadow().select(cursorLeft.offset, cursorRight.offset).delete();
        void editorModel.requestFocus();
      });
    } else {
      editorModel.performChange((ctx) => {
        ctx
          .select(cursorLeft.offset, cursorRight.offset)
          .replace(
            newContent([newElement(INLINE_EQUATION_TAG, { text: value, textFormat: textFormat0 })])
          );
      });
      focusEditableAt(editorModel.editorKey, cursorRight.offset);
    }
    cleanup();
  };

  return (
    <span
      className={cx(
        'max-w-full inline-block py-0.5',
        (readonly || !interactable) && 'pointer-events-none'
      )}
      {...htmlDataProps}
      style={noStyle ? {} : textStyle}
      contentEditable={false}
      ref={blockRef}
    >
      <Latex
        isInline
        defaultOpenPanel={!!open}
        content={state as string}
        onChange={(value) => {
          setState(value);
        }}
        onSubmit={onChange}
        onCancel={() => {
          setState(text as string);
          if (!stringToLowerCaseAndRemoveSpace(text as string)) {
            onChange('');
          } else {
            const editorModel = getEditorModel();
            if (editorModel) {
              focusEditableAt(editorModel.editorKey, baseOffset + 1);
            }
          }
        }}
      />
    </span>
  );
};

export const EquationInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_EQUATION_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlineEquation);
  },
};

export const NoEquationInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_EQUATION_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, (props) => {
      return <InlineEquation {...props} noStyle />;
    });
  },
};
