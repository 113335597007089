import type { Context } from '../../../core/context';
import type { CSSValue } from '../../syntax/parser';
import { parseFunctionArgs } from '../../syntax/parser';
import { TokenType } from '../../syntax/tokenizer';
import { angle as angleType, deg, isAngle, parseNamedSide } from '../angle';
import type {
  CSSLinearGradientImage,
  GradientCorner,
  UnprocessedGradientColorStop,
} from '../image';
import { CSSImageType } from '../image';
import { parseColorStop } from './gradient';

export const prefixLinearGradient = (
  context: Context,
  tokens: CSSValue[]
): CSSLinearGradientImage => {
  let angle: number | GradientCorner = deg(180);
  const stops: UnprocessedGradientColorStop[] = [];

  parseFunctionArgs(tokens).forEach((arg, i) => {
    if (i === 0) {
      const firstToken = arg[0];
      if (
        firstToken?.type === TokenType.IDENT_TOKEN &&
        ['top', 'left', 'right', 'bottom'].indexOf(firstToken.value) !== -1
      ) {
        angle = parseNamedSide(arg);
        return;
      } else if (isAngle(firstToken)) {
        angle = (angleType.parse(context, firstToken!) + deg(270)) % deg(360);
        return;
      }
    }
    const colorStop = parseColorStop(context, arg);
    stops.push(colorStop);
  });

  return {
    angle,
    stops,
    type: CSSImageType.LINEAR_GRADIENT,
  };
};
