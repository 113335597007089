import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import type { getPermissions } from './permissions';

interface Props {
  uuid: string;
  parentId?: string;
  spaceId?: string;
  parentPermission: ReturnType<typeof getPermissions>;
  permissionsState: ReturnType<typeof getPermissions>;
  isProSpace?: boolean;
  readonly?: boolean;
  isFreeSpaceLimit?: boolean;
  removeBlockPermission(uuid: string, permission: PermissionDTO): Promise<void> | void;
  setBlockPermission(uuid: string, permission: PermissionDTO): Promise<void> | void;
  trackerEvent(event: string, params: any): void;
  checkBreakRole(): Promise<void>;
  setPermission(id: string, permission: PermissionDTO): void;
  onError(msg: string, type: 'parentRoleLimit' | 'proLimit' | 'conflict' | 'roleLimit'): void;
  openParentRoleLimit?(): void;
}

type RoleType = keyof Pick<
  PermissionDTO,
  | 'allowDuplicate'
  | 'allowDownload'
  | 'allowSubscribe'
  | 'allowShowBreadcrumb'
  | 'allowShowAIChat'
  | 'allowSeo'
  | 'sharePageDistributionRatio'
  | 'allowSelectionCopy'
>;

export const useUpdatePermission = (props: Props) => {
  const {
    uuid,
    parentPermission,
    spaceId,
    permissionsState,
    readonly,
    isFreeSpaceLimit,
    setBlockPermission,
    removeBlockPermission,
    trackerEvent,
    checkBreakRole,
    setPermission,
    onError,
    openParentRoleLimit,
  } = props;
  const {
    publicRole,
    permissions,
    shared,
    role,
    isRestricted,
    parentId,
    allowShowSidebar,
    password,
    allowDuplicate,
    allowSeo,
    illegal,
    allowDownload,
    allowSubscribe,
    allowSelectionCopy,
    allowShowBreadcrumb,
    accessFee,
    allowShowAIChat,
    linkValidDate,
    openPreview,
    sharePageDistributionRatio = 0,
    previewBlockCount,
    accessFeeValidDays,
    originPrice,
  } = permissionsState;
  const isRoot = parentId === spaceId;
  const isEditor = role === PermissionRole.EDITOR;
  const canEditor = !(!isEditor || (!isRestricted && !!parentId));
  const canSeo = !(!!password || !canEditor) && spaceId;
  const isOpenShare = illegal ? false : shared;
  const isCommenter = publicRole === PermissionRole.COMMENTER;

  const publicPermission = permissions.find((p) => p.type === PermissionType.PUBLIC);
  const defaultSetPermissionValue = {
    type: PermissionType.PUBLIC,
    role: publicPermission?.role ?? PermissionRole.READER,
    allowDownload,
    allowDuplicate,
    allowSeo,
    shareShowSidebar: allowShowSidebar,
    allowSubscribe,
    password,
    accessFee,
    allowShowAIChat,
    linkValidDate,
    allowShowBreadcrumb,
    openPreview,
    sharePageDistributionRatio,
    previewBlockCount,
    accessFeeValidDays,
    originPrice,
    allowSelectionCopy,
  };

  const parentRoleLimit = () => {
    onError('继承上级页面的设置，不支持单独关闭', 'parentRoleLimit');
  };

  const checkParentRoleLimit = !isEditor || (!isRestricted && !isRoot && parentPermission.shared);

  const switchPublicShare = async () => {
    if (readonly || !isEditor) return;

    if (!isRestricted && shared && parentId) {
      await checkBreakRole();
      trackerEvent('share_switch', { is_open: false });
      // 非顶层(即有 parent)时添加受限
      if (parentId) {
        permissions.forEach((o) => setPermission(uuid, o));
        await setBlockPermission(uuid, { type: PermissionType.RESTRICTED });
      }
      await removeBlockPermission(uuid, { type: PermissionType.PUBLIC });
    } else {
      trackerEvent('share_switch', { is_open: !shared });

      if (shared) {
        await removeBlockPermission(uuid, { type: PermissionType.PUBLIC });
      } else {
        await setBlockPermission(uuid, {
          role: PermissionRole.READER,
          type: PermissionType.PUBLIC,
        });
      }
    }
  };

  const setAllowSubscribe = async () => {
    if (checkParentRoleLimit) {
      parentRoleLimit();
    } else if (accessFee && accessFee > 0) {
      onError('付费分享不可关闭订阅设置', 'conflict');
    } else {
      void set().setRole('allowSubscribe');
    }
  };

  const setLinkValidTime = async (timeStamp?: number) => {
    if (!isEditor) return;
    if (checkParentRoleLimit) {
      parentRoleLimit();
      return;
    }

    if (publicPermission && shared && (isRestricted || !parentId)) {
      await setBlockPermission(uuid, {
        ...defaultSetPermissionValue,
        linkValidDate: timeStamp,
      });
    }
  };

  const setShareShowSidebar = async () => {
    if (!isEditor) return;
    if (checkParentRoleLimit) {
      parentRoleLimit();
      return;
    }

    if (publicPermission && shared && (isRestricted || !parentId)) {
      await setBlockPermission(uuid, {
        ...defaultSetPermissionValue,
        shareShowSidebar: !allowShowSidebar,
      });
    }
  };
  const setShareSeo = async () => {
    if (isFreeSpaceLimit) {
      onError('付费空间专享', 'proLimit');
      return;
    }

    if (!isEditor) {
      onError('只有编辑者可以设置', 'roleLimit');
      return;
    }

    // 如果设置了密码，需要把seo开关关掉，并且item disable掉，两者不能共存
    if (password) {
      onError('设置了密码，不能设置SEO', 'conflict');
      return;
    }

    await setRole('allowSeo').then((status) => {
      if (status) {
        trackerEvent('share_seo', {
          is_open: !allowSeo,
        });
      }
    });
  };

  async function setRole<T>(type: RoleType, value?: T) {
    if (!isEditor) return;
    const flag = permissionsState[type];
    if (checkParentRoleLimit) {
      parentRoleLimit();
      return;
    }

    track(type, !flag, trackerEvent);

    const publicPermission = permissions.find((p) => p.type === PermissionType.PUBLIC);

    if (publicPermission && shared && (isRestricted || !parentId)) {
      await setBlockPermission(uuid, {
        ...defaultSetPermissionValue,
        [type]: value ?? !flag,
      });
    }
    return true;
  }
  const setPartialPermission = async (part: Partial<PermissionDTO>) => {
    await setBlockPermission(uuid, {
      ...defaultSetPermissionValue,
      ...part,
    });
  };

  const setCommon = async (role: PermissionRole) => {
    if (!isEditor) return;
    if (!isRestricted && parentId) {
      parentRoleLimit();
      return;
    }

    const publicPermission = permissions.find((p) => p.type === PermissionType.PUBLIC);
    // 按需求，继承的不可以操作 允许复制 按钮, 受限的可以
    if (publicPermission && shared && (isRestricted || !parentId)) {
      await setBlockPermission(uuid, {
        ...defaultSetPermissionValue,
        role,
      });
    }
  };

  const set = () => ({
    switchPublicShare,
    setShareShowSidebar,
    setShareSeo,
    setRole,
    setCommon,
    setLinkValidTime,
    setPartialPermission,
    setAllowSubscribe,
  });

  return {
    set,
    defaultSetPermissionValue,
    permissionsState,
    roleStatus: {
      isCommenter,
      isEditor,
      canEditor,
      canSeo,
      isOpenShare,
      isRestricted: checkParentRoleLimit,
    },
    openParentRoleLimit,
  };
};

const track = (
  type: RoleType,
  value: boolean,
  trackerEvent: (event: string, params: any) => void
) => {
  switch (type) {
    case 'allowDuplicate': {
      trackerEvent('allow_copy', {
        is_open: value,
      });
      break;
    }
    default:
  }
};
