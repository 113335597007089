import { assign, omit } from 'lodash-es';
import { useCallback } from 'react';
import { USER_ID } from 'src/common/const';
import { request } from 'src/common/request';
import type { UserInfo } from 'src/services/user/current-user';
import { $currentUserCache, updateCurrentUser } from 'src/services/user/current-user';
import { setLocalStorage } from 'src/utils/local-storage';

/**
 * 更新用户信息
 */
export const useUpdateUser = () => {
  return useCallback(async (patch: Partial<UserInfo>, uploadServer = true) => {
    updateCurrentUser(patch);
    const user = $currentUserCache;
    user.uuid && setLocalStorage(USER_ID, user.uuid);
    if (uploadServer) {
      const rest = omit(patch, ['spaceViews']);
      const setting = assign(user.setting, rest.setting);
      const res = await request.infra.updateUser(user.uuid, {
        ...rest,
        setting,
      });
      return res;
    }
  }, []);
};
