import { Role } from '@flowus/common';
import { isPageLike } from '@flowus/common/block/permissions';
import { cx } from '@flowus/common/cx';
import { Tooltip } from '@flowus/common/tooltip';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenUpgradeSpacePlan } from 'src/components/upgrade-space-plan';
import { useBlock } from 'src/hooks/block/use-block';
import { useCurrentSpace } from 'src/hooks/space';
import { getSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useSpaceSecurityState } from 'src/hooks/space/use-space-security-state';
import { useCurrentUser } from 'src/hooks/user/use-current-user';
import { useGuestsList } from 'src/services/app/hook';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { ViewPath } from 'src/utils';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { OpenSettingFrom, SettingMenuType } from 'src/views/main/setting-modal/type';
import { useOpenSettingModal } from 'src/views/main/setting-modal/use-open-setting-modal';
import { SearchBar } from '../components/search-bar';
import { useSharePanel } from '../components/use-share-panel';
import { UserList } from '../components/user-list';
import { useOpenSynergyInvitation } from '../search-user/search-user-select';
import type { ShareProps } from '../types';
import { Panel } from '../types';
import { UpgradeSpacePlanType } from 'src/components/upgrade-space-plan/types';

const NUMBERS = 5;

export const ShareMainInvite: FC<Pick<ShareProps, 'uuid' | 'onChangeTab'>> = ({
  uuid,
  onChangeTab,
}) => {
  const guests = useGuestsList();
  const isProSpace = useIsProSpace();
  const { isMaxGuests, guestMaxLimitDesc } = useLimitConfig();
  const block = useBlock(uuid);
  const sharePanel = useSharePanel(uuid);
  const openSynergyInvitation = useOpenSynergyInvitation();
  const abandonedGuest = useSpaceSecurityState('abandonedGuest');
  const currentSpace = useCurrentSpace();
  const spacePlanName = getSpacePlanTypeName(currentSpace.planType);
  const openSettingModal = useOpenSettingModal();
  const currentUser = useCurrentUser();
  const spaceEditor = getSpaceRolePermission(currentUser.uuid).editor;
  const openUpgradeSpacePlan = useOpenUpgradeSpacePlan();

  const copyPageLink = async () => {
    if (isPageLike(block?.type)) {
      void writeTextInClipboard(`${getLocationOrigin()}/${uuid}`);
    } else {
      void writeTextInClipboard(`${getLocationOrigin()}${ViewPath.preview}${uuid}`);
    }
  };

  if (!sharePanel) return null;

  const { role, permissions, onChange } = sharePanel;

  const popup = (
    <div className="p-1 space-y-1">
      <div>
        当前空间计划为{spacePlanName}空间，总共可邀请 {guestMaxLimitDesc} 名外部协作者。
        外部协作者适用于跨团队项目合作、客户沟通等场景，您可以邀请客户、合作方、甲方等参与查看和编辑特定页面。
      </div>
      <div
        className="text-active_color animate-click text-right"
        onClick={() => {
          openSettingModal({
            defaultMenuType: SettingMenuType.members,
            from: OpenSettingFrom.guest_max,
          });
        }}
      >
        查看我的空间成员
      </div>
    </div>
  );

  return (
    <>
      <div className="mx-4 text-t2">
        {!isProSpace && (
          <div className="flex items-center justify-between w-full pt-2.5 pb-1">
            <div className="flex items-center space-x-1">
              <span>
                空间已邀请外部协作者
                <span className={cx('px-1', isMaxGuests && 'text-red')}>
                  {guests.length}/{guestMaxLimitDesc}
                </span>
                人
              </span>
              <Tooltip popup={popup}>
                <Icon className="text-grey3" size="small" name="IcQuestion" />
              </Tooltip>
            </div>
            <span
              hidden={!spaceEditor}
              className="text-t4 text-active_color animate-click"
              onClick={() => {
                openUpgradeSpacePlan({ type: UpgradeSpacePlanType.maxGuestCount });
              }}
            >
              升级获取更多席位
            </span>
          </div>
        )}

        <SearchBar
          uuid={uuid}
          onClick={() => onChangeTab(Panel.SEARCH_USER)}
          className="invite-bar my-2"
        />

        <UserList
          showRight
          filterUserNoInSpace
          onChange={role === PermissionRole.EDITOR ? onChange : undefined}
          permissions={permissions.slice(0, NUMBERS)}
        />

        {permissions.length > NUMBERS && (
          <div
            onClick={() => onChangeTab(Panel.ALL_USERS)}
            className="flex items-center animate-click animate-hover p-1"
          >
            <Icon size="middle" className="text-grey4" name="IcShareAnyone" />
            <div className="text-t3 text-grey4 pl-4">{`查看当前页面所有协作者（${permissions.length} 个）`}</div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-between pt-1 pb-2.5 px-4 text-t4 text-grey3">
        <div />
        <div className="flex items-center space-x-2 text-active_color text-t4">
          {Role.contains(role, PermissionRole.EDITOR) && !abandonedGuest && (
            <div
              className="flex space-x-1 items-center animate-click"
              onClick={openSynergyInvitation}
            >
              <Icon size="small" name="IcMenuPerson" />
              <span>链接邀请协作</span>
            </div>
          )}
          <div className="flex space-x-1 items-center animate-click" onClick={copyPageLink}>
            <Icon size="small" name="IcLink" />
            <span>复制页面链接</span>
          </div>
        </div>
      </div>
    </>
  );
};
