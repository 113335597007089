import { fastEqual } from '@flowus/common';
import type { CollectionViewDTO, CollectionViewGroups, UserDTO } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { createSelector } from 'reselect';
import { getValidPropertyIds } from 'src/bitable/bitable-manager/group-list/utils';
import type { NextBlock } from 'src/redux/types';
import { getViewFormat } from '../../block/get-view-format';
import { getTablePropertyValue } from '../get-property-value';
import { checkGroup } from './check-group';
import { EMPTY_GROUP_NAME } from './const';
import { selectBlockGroupNames } from './select-block-group-values';

export interface TableGroupData {
  value: string;
  visible: boolean;
  emptyHidden?: boolean;
  recordIds: string[];
  groupProperty: string;
}

export type SubTableGroupData = TableGroupData & { groups: TableGroupData[] };

export interface BiTableGroups {
  withoutValidGroup: boolean;
  needUpdateGroups?: boolean;
  needUpdateSubGroups?: boolean;
  newViewGroups?: CollectionViewGroups;
  newViewSubGroups?: CollectionViewGroups;

  groups?: TableGroupData[];
  visibleGroups: TableGroupData[];
  hiddenGroups: TableGroupData[];

  hasValidSubGroup?: boolean;
  subGroups?: SubTableGroupData[];
  visibleSubGroups?: SubTableGroupData[];
  hiddenSubGroups?: SubTableGroupData[];

  groupProperty?: string;
  subGroupProperty?: string;
  sortedRecordIds: string[];
}

interface State {
  blocks: Record<string, NextBlock>;
  collectionViews: Record<string, CollectionViewDTO>;
  users: Record<string, UserDTO>;
  viewId: string;
  sortedRecordIds: string[];
}

export const selectCollectionGroups = createSelector(
  (state: State) => state.blocks,
  (state: State) => state.collectionViews,
  (state: State) => state.users,
  (state: State) => state.viewId,
  (state: State) => state.sortedRecordIds,
  (blocks, collectionViews, users, viewId, sortedRecordIds) => {
    const viewInfo = getViewFormat(viewId, blocks, collectionViews);
    if (!viewInfo) return;

    const {
      view,
      collection,
      groups: oldGroups = [],
      subGroups: oldSubGroups = [],
      groupBy,
      subGroupBy,
      timelineShowTable,
      timelineBy,
      timelineByEnd,
    } = viewInfo;
    const groupProperty = groupBy?.property ?? '';
    const subGroupProperty = subGroupBy?.property ?? '';
    const validPropertyIds = getValidPropertyIds(collection.uuid);

    if (view.type === CollectionViewType.TIMELINE && !timelineShowTable) {
      sortedRecordIds = sortedRecordIds.filter((uuid) => {
        if (!timelineBy || !timelineByEnd) return false;

        const block = blocks[uuid];
        const recordStartTime = getTablePropertyValue(block, timelineBy) as number | undefined;
        const recordEndTime = getTablePropertyValue(block, timelineByEnd) as number | undefined;
        return Boolean(recordStartTime || recordEndTime);
      });
    }

    const groupSchema = collection.data.schema?.[groupProperty];
    const subGroupSchema = collection.data.schema?.[subGroupProperty];

    if (!groupSchema || !validPropertyIds.includes(groupProperty) || !groupBy) {
      return {
        sortedRecordIds,
        visibleGroups: [],
        hiddenGroups: [],
        withoutValidGroup: true,
      };
    }

    let hasValidSubGroup = false;
    if (view.type === CollectionViewType.BOARD) {
      if (validPropertyIds.includes(subGroupProperty)) {
        hasValidSubGroup = true;
      }
    }

    const { needUpdateGroups, newViewGroups } = checkGroup(
      sortedRecordIds,
      blocks,
      users,
      collection.uuid,
      oldGroups,
      groupSchema,
      groupBy
    );
    const groupsMap = new Map<string, { group: TableGroupData; index: number }>();
    const groups: TableGroupData[] = newViewGroups.map((group, index) => {
      const initGroup = initGroupData(group, groupProperty);
      groupsMap.set(initGroup.value, { group: initGroup, index });
      return initGroup;
    });

    let needUpdateSubGroups = false;
    let newViewSubGroups: CollectionViewGroups = [];
    let subGroupsMap = new Map<string, SubTableGroupData>();
    let subGroups: SubTableGroupData[] = [];
    if (hasValidSubGroup && subGroupSchema && subGroupBy) {
      const result = checkGroup(
        sortedRecordIds,
        blocks,
        users,
        collection.uuid,
        oldSubGroups,
        subGroupSchema,
        subGroupBy
      );

      needUpdateSubGroups = result.needUpdateGroups;
      newViewSubGroups = result.newViewGroups;

      subGroupsMap = new Map<string, SubTableGroupData>();
      subGroups = newViewSubGroups.map((subGroup) => {
        const initGroup = {
          ...initGroupData(subGroup, subGroupProperty),
          groups: newViewGroups.map((group) => initGroupData(group, groupProperty)),
        };
        subGroupsMap.set(initGroup.value, initGroup);
        return initGroup;
      });
    }

    sortedRecordIds.forEach((recordId: string) => {
      const block = blocks[recordId];
      if (!block) return;

      const values = selectBlockGroupNames({
        blocks,
        users,
        collectionId: collection.uuid,
        recordId,
        groupProperty,
        groupBy,
      });

      const groupPos: number[] = [];
      values.forEach((tag: string) => {
        const result = groupsMap.get(tag);
        if (result) {
          groupPos.push(result.index);
          result.group.recordIds.push(recordId);
        }
      });

      if (hasValidSubGroup && subGroupSchema && subGroupBy) {
        const values = selectBlockGroupNames({
          blocks,
          users,
          collectionId: collection.uuid,
          recordId,
          groupProperty: subGroupProperty,
          groupBy: subGroupBy,
        });

        values.forEach((tag: string) => {
          const subGroup = subGroupsMap.get(tag);
          if (subGroup) {
            groupPos.forEach((pos: number) => {
              subGroup.recordIds.push(recordId);
              subGroup.groups[pos]?.recordIds.push(recordId);
            });
          }
        });
      }
    });

    const visibleGroups: TableGroupData[] = [];
    const hiddenGroups: TableGroupData[] = [];
    groups.forEach((group) => {
      if (!group.visible) {
        hiddenGroups.push(group);
      } else if (
        groupBy.hideEmptyGroups &&
        group.recordIds.length === 0 &&
        group.emptyHidden !== false
      ) {
        group.visible = false;
        hiddenGroups.push(group);
      } else {
        visibleGroups.push(group);
      }
    });

    const visibleSubGroups: SubTableGroupData[] = [];
    const hiddenSubGroups: SubTableGroupData[] = [];
    subGroups.forEach((subgroup) => {
      subgroup.groups.forEach((item, index) => {
        item.visible = groups[index]?.visible ?? item.visible;
      });
      if (!subgroup.visible) {
        hiddenSubGroups.push(subgroup);
      } else if (
        subGroupBy?.hideEmptyGroups &&
        subgroup.recordIds.length === 0 &&
        subgroup.emptyHidden !== false
      ) {
        subgroup.visible = false;
        hiddenSubGroups.push(subgroup);
      } else {
        visibleSubGroups.push(subgroup);
      }
    });

    return {
      withoutValidGroup: false,
      needUpdateGroups,
      needUpdateSubGroups,
      newViewGroups,
      newViewSubGroups,
      groups,
      visibleGroups,
      hiddenGroups,
      hasValidSubGroup,
      subGroups,
      visibleSubGroups,
      hiddenSubGroups,
      subGroupProperty,
      groupProperty,
      sortedRecordIds,
    };
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
    },
  }
);

const initGroupData = (group: CollectionViewGroups[number], groupProperty: string) => {
  return {
    value: group.value ?? EMPTY_GROUP_NAME,
    visible: group.visible,
    emptyHidden: group.emptyHidden,
    recordIds: [],
    groupProperty,
  };
};
