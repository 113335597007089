import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';

export const cleanSelectBlock = (type: 'all' | 'block' | 'cell' = 'all') => {
  if (type === 'all') {
    dispatch(uiActions.update({ selectedBlocks: [], selectedCells: [] }));
  }
  if (type === 'block') {
    dispatch(uiActions.update({ selectedBlocks: [] }));
  }
  if (type === 'cell') {
    dispatch(uiActions.update({ selectedCells: [] }));
  }
};
