import { cx } from '@flowus/common/cx';
import { type FC } from 'react';
import { Checkbox } from 'src/components/check-box';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useReadonly } from 'src/hooks/page';
import { useIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import './hidden-checkbox.css';
import { judgeSharePage } from 'src/utils/getPageId';
import { Tooltip } from '@flowus/common/tooltip';

interface Props {
  uuid: string;
  viewId: string;
  groupValue?: string;
  className?: string;
}
export const HIDDEN_CHECK_BOX_WDITH = 30;
export const RowHiddenCheckBox: FC<Props> = (props) => {
  const { uuid, viewId, groupValue } = props;
  const readonly = useReadonly(uuid, false);
  const isSelected = useIsSelected(uuid, viewId, groupValue);
  const syncId = useSyncId();
  return (
    <HiddenCheckBox
      {...props}
      checked={!!isSelected}
      readonly={readonly}
      onCheck={(checked) => {
        const selectBlockAction = checked ? uiActions.addSelectBlock : uiActions.removeSelectBlock;
        dispatch(
          selectBlockAction({
            blockId: uuid,
            syncId,
            viewId,
          })
        );
      }}
    />
  );
};
export const HiddenCheckBox: FC<{
  isGroup?: boolean;
  readonly?: boolean;
  alwaysShow?: boolean;
  className?: string;
  checked: boolean;
  onCheck: (checked: boolean) => void;
}> = (props) => {
  const { readonly, checked } = props;
  const isShare = judgeSharePage();
  if (isShare) return null;
  return (
    <div
      className={cx('', {
        'border-r': !props.isGroup,
      })}
      style={{ padding: 6, width: HIDDEN_CHECK_BOX_WDITH }}
    >
      <Tooltip popup={readonly ? '该记录无编辑权限' : ''}>
        <button
          disabled={readonly}
          className={cx('flex items-center cursor-default', props.className, {
            hiddenCheckBox: !checked && !props.alwaysShow,
          })}
          style={{ width: 24, height: 24 }}
        >
          {/* 占位的 */}
          <div className="hidden h-5" />
          <Checkbox
            onClick={() => {
              props.onCheck(!checked);
            }}
            className="opacity-90"
            size="middle"
            checked={checked}
          />
        </button>
      </Tooltip>
    </div>
  );
};
