import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useLayoutEffect, useRef } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { useVisible } from 'src/common/hooks/use-visible';
import { LitePage } from 'src/components/page-preview/lite-page';
import { useTryFetchPageBlock } from 'src/components/page-preview/use-try-fetch-page-block';
import { ThumbPageCardDescContent } from 'src/editor/component/page-block';
import { segmentsToText } from 'src/editor/utils/editor';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { BackgroundContainer } from './background-container';
import { NoBindIdContext } from './no-bind-id-context';
import type { PageProps } from './types';
import { useCoverImageUrl } from './use-cover-image-url';

export const SmallThumbPageCard: FC<PageProps> = (props) => {
  const { id, illegal, showCover, desc, isInColumn } = props;
  const hasCover = !!useCoverImageUrl(id) && showCover;
  const [visible, setRef] = useVisible<HTMLDivElement>();
  const loading = useTryFetchPageBlock(id, visible);
  const block = usePickBlock(id, ['data'], ['segments']);
  const divRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>();
  const { width } = useDebounceElementSize(container.current, { type: 'width' });
  const title = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);

  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '250px',
    };
  }

  useLayoutEffect(() => {
    if (!visible || loading) return;

    const footer = divRef.current?.querySelector('.footer');
    if (footer instanceof HTMLElement) {
      footer.hidden = true;
    }
    const pageContainer =
      divRef.current?.querySelector(`#${ID_DRIVE}`) ??
      divRef.current?.querySelector('.next-space-page');
    if (pageContainer instanceof HTMLElement) {
      pageContainer.childNodes.forEach((c) => {
        if (c instanceof HTMLElement) {
          c.setAttribute('style', `padding-left:12px;padding-right:12px`);
        }
      });
    }
  }, [loading, visible]);
  // 显示说明文字的ui跟不显示说明文字的ui布局不一样
  if (desc) {
    return (
      <BackgroundContainer
        blockId={id}
        ref={(ref) => {
          setRef(ref);
          if (ref != null) {
            container.current = ref;
          }
        }}
        showCover={showCover}
        {...attribute}
        className={cx('overflow-hidden flex relative w-full h-[90px] animate-hover border', {
          'w-full': isInColumn,
        })}
        onClick={(e) => {
          const selection = window.getSelection();
          if (!selection) return;
          if (selection.isCollapsed) {
            props.onClick?.(e);
          }
        }}
      >
        <ThumbPageCardDescContent
          small
          title={title}
          blockType={block?.type}
          desc={desc}
          hasCover={hasCover}
        />
        <div
          ref={divRef}
          className="self-end mr-4 px-2.5 bottom-0 w-[75%] min-w-[188px] h-[70%] border-t border-l border-r overflow-hidden bg-white1 pt-2"
          style={{
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <NoBindIdContext.Provider value={false}>
            <div className="h-full zoom-03 pointer-events-none">
              <div
                className={cx('text-ellipsis cursor-text ml-4 text-h0 text-black', {
                  'text-grey3': !title,
                })}
              >
                {title || getUntitledName(block?.type)}
              </div>
              <LitePage pageId={id} loading={loading} />
            </div>
          </NoBindIdContext.Provider>
        </div>
      </BackgroundContainer>
    );
  }

  return (
    <BackgroundContainer
      blockId={id}
      ref={(ref) => {
        setRef(ref);
        if (ref != null) {
          container.current = ref;
        }
      }}
      showCover={showCover}
      {...attribute}
      className={cx('overflow-hidden flex flex-col relative w-full h-[90px] animate-hover border', {
        'w-full': isInColumn,
      })}
      onClick={(e) => {
        const selection = window.getSelection();
        if (!selection) return;
        if (selection.isCollapsed) {
          props.onClick?.(e);
        }
      }}
    >
      <div
        className={cx('ml-4 mt-3 text-t1-bold text-black whitespace-pre-wrap', {
          'text-white': hasCover,
        })}
      >
        {title || getUntitledName(block?.type)}
      </div>
      <div
        ref={divRef}
        className={cx(
          'w-[75%] min-w-[188px] h-[130px] border-t border-l border-r overflow-hidden bg-white1 pt-2',
          {
            'self-end mr-4': width && width > 250,
            'ml-[55px]': width && width < 250,
          }
        )}
        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
      >
        <NoBindIdContext.Provider value={false}>
          <div className="h-full zoom-03 pointer-events-none">
            <div
              className={cx('cursor-text ml-4 text-h0 text-black break-words', {
                'text-grey3': !title,
              })}
            >
              {title || getUntitledName(block?.type)}
            </div>
            <LitePage pageId={id} loading={loading} />
          </div>
        </NoBindIdContext.Provider>
      </div>
    </BackgroundContainer>
  );
};
