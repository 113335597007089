import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import _confetti from 'canvas-confetti';

import { sleep } from './async-utils';

function randomInRange(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const confetti = {
  bothSides: async () => {
    const start = () => {
      const count = 200;

      const fire = (particleRatio: number, opts?: _confetti.Options) => {
        void _confetti(
          Object.assign({ y: 0.7, x: 0 }, opts, {
            zIndex: 9999,
            particleCount: Math.floor(count * particleRatio),
          })
        );
      };

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });

      fire(0.2, {
        spread: 60,
      });

      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });

      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });

      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    };
    await sleep(500);
    start();
    await sleep(1000);
    start();
    await sleep(1000);
    start();
  },
  fireworks: () => {
    const duration = 8 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 99999 };

    const intervalTimer: Timeout = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(intervalTimer);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      void _confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      void _confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  },
};
