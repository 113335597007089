import type { FC } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { HOST_NAME } from 'src/common/const';
import { useModel } from 'src/common/create-model';
import { useCurrentSpace } from 'src/hooks/space';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { SettingProvider } from '../common';
import { SettingMenuType } from '../type';

export const SpaceInfoAvatar: FC = () => {
  const { setCurrentSettingMenu } = useModel(SettingProvider);
  const currentSpace = useCurrentSpace();

  return (
    <div className="flex h-20 items-center">
      <div className="rounded-md border border-black_006 p-1">
        <Avatar
          name={currentSpace.title}
          color={currentSpace.backgroundColor}
          icon={currentSpace.icon}
          iconSize={50}
          className="h-[50px] w-[50px]"
          style={{ fontSize: '30px' }}
        />
      </div>
      <div className="ml-4 flex-1 flex flex-col justify-center">
        <div className="text-t1-medium">{currentSpace.title}</div>
        <div className="text-t3 mt-1">
          {!currentSpace.domain ? (
            <>&nbsp;</>
          ) : (
            <>
              {HOST_NAME}/{currentSpace.domain}
            </>
          )}
        </div>
      </div>
      <Button
        className="self-center mr-4"
        onClick={() => {
          void writeTextInClipboard(currentSpace.uuid, { message: '已复制空间 ID' });
        }}
      >
        复制空间 ID
      </Button>
      <Button
        className="self-center"
        onClick={() => {
          setCurrentSettingMenu(SettingMenuType.space);
        }}
      >
        信息设置
      </Button>
    </div>
  );
};
