import { BlockType } from '@next-space/fe-api-idl';
import { LIST_REMOVE_COLLECTION_VIEW_PAGESORT } from 'src/redux/actions';
import { dispatch, getState } from 'src/redux/store';

export const removePageSortRecord = (uuid: string) => {
  const { blocks, collectionViews } = getState();

  const block = blocks[uuid];

  if (!block) return;

  const collection = blocks[block.parentId];

  if (!collection) return;

  // 如果父节点是 collection, 则需要更新相关  collectionViews 的 pageSort
  if (
    collection.type === BlockType.COLLECTION_VIEW ||
    collection.type === BlockType.COLLECTION_VIEW_PAGE
  ) {
    // 更新 pageSort
    collection.views?.forEach((viewId) => {
      const view = collectionViews[viewId];
      if (!view) return;
      dispatch(LIST_REMOVE_COLLECTION_VIEW_PAGESORT({ viewId, uuid }));
    });
  }
};
