import { BlockType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { useEffect } from 'react';
import { getCaretInfo } from 'src/hooks/editor/helper';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { addBlock } from 'src/redux/managers/block/add';
import { simpleTableActions } from 'src/redux/reducers/simple-table';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { blurDocument } from 'src/utils/dom';
import { focusEditable } from 'src/utils/editor';
import { useGetOrInitEditorModel } from '../../uikit/editable/hook';
import { useSyncId } from '../sync-block-context';

export const DEFAULT_COL_WIDTH = 120;

export const cancelSelectedCells = () => {
  dispatch(simpleTableActions.update({ activityTableId: undefined, chosenCells: undefined }));
};

export const getTableCellEditorKey = (tableId: string, rowIndex: number, columnIndex: number) => {
  const table = document.querySelector(`[data-block-id="${tableId}"`);
  const cell = table?.querySelector(
    `[data-table-row-index="${rowIndex}"][data-table-column-index="${columnIndex}"]`
  );
  const editorKey = (cell?.querySelector(`[contenteditable]`) as HTMLElement | null)?.dataset
    .editor;
  return editorKey;
};

export const getIndexInSelectedCells =
  (arr: string[], key: 'propertyId' | 'recordId') => (reverse?: boolean) => {
    const _arr = arr.slice();
    if (reverse) {
      _arr.reverse();
    }

    let index = 0;
    _arr.some((id, i) => {
      if (cache.ui.selectedCells.some((o) => o[key] === id)) {
        index = i;
        return true;
      }
      return false;
    });

    if (reverse) {
      return _arr.length - 1 - index;
    }
    return index;
  };

export const useKeys = (tableId: string) => {
  const transaction = useTransaction();
  const syncId = useSyncId();
  const getEditorModel = useGetOrInitEditorModel();

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    const delay = (fn: () => void) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(fn);
    };

    const keydown = (event: KeyboardEvent) => {
      const table = cache.blocks[tableId];
      if (!table) return;
      const { selectedCells } = cache.ui;
      if (!selectedCells.length) return;
      if (!selectedCells.some(({ viewId }) => viewId === tableId)) return;
      const { recordId, propertyId } = selectedCells[0] ?? {};
      if (!recordId || !propertyId) return;

      if (
        isHotkey(
          [
            'Esc',
            'Tab',
            'Shift+Tab',
            'ArrowUp',
            'ArrowDown',
            'ArrowLeft',
            'ArrowRight',
            'Enter',
            'Shift+ArrowUp',
            'Shift+ArrowDown',
            'Shift+ArrowLeft',
            'Shift+ArrowRight',
          ],
          event
        )
      ) {
        const { focus } = cache.simpleTable;

        const { subNodes } = table;
        const columns = table.data.format?.tableBlockColumnOrder ?? [];
        const rowIndex = subNodes.findIndex((uuid) => uuid === recordId);
        const columnIndex = columns.findIndex((uuid) => uuid === propertyId);
        const editorKey = getTableCellEditorKey(tableId, rowIndex, columnIndex) ?? '';

        const getColumnIndexInSelectedCells = getIndexInSelectedCells(columns, 'propertyId');
        const getRowIndexInSelectedCells = getIndexInSelectedCells(subNodes, 'recordId');

        const getSet = () => {
          const rowSet = new Set<string>();
          const columnSet = new Set<string>();
          cache.ui.selectedCells.forEach((o) => {
            rowSet.add(o.recordId);
            columnSet.add(o.propertyId);
          });

          return { rowSet, columnSet };
        };

        const isInSelectedCells = (rid: string, pid: string) => {
          return cache.ui.selectedCells.some(
            (o) =>
              o.viewId === tableId &&
              o.syncId === syncId &&
              o.recordId === rid &&
              o.propertyId === pid
          );
        };

        if (isHotkey('Esc')(event)) {
          if (document.querySelector(`[data-modal-id="${Modals.INLINE_PANEL}"]`)) {
            return;
          }
          event.stopImmediatePropagation();
          if (focus) {
            dispatch(simpleTableActions.update({ focus: false }));
          } else {
            dispatch(uiActions.updateSelectBlocks([{ blockId: tableId }]));
          }
          blurDocument(true);
          return;
        }

        if (isHotkey('Enter')(event)) {
          if (!focus) {
            const firstRowIndex = getRowIndexInSelectedCells();
            const firstRowId = subNodes[firstRowIndex];
            const firstColumnIndex = getColumnIndexInSelectedCells();
            const firstColumnId = columns[firstColumnIndex];
            if (firstRowId && firstColumnId) {
              dispatch(
                uiActions.update({
                  selectedCells: [
                    {
                      recordId: firstRowId,
                      propertyId: firstColumnId,
                      viewId: tableId,
                      syncId,
                    },
                  ],
                })
              );
              dispatch(simpleTableActions.update({ focus: true }));
              focusEditable(
                getTableCellEditorKey(tableId, firstRowIndex, firstColumnIndex),
                Infinity,
                Infinity
              );
            }
          }
          return;
        }

        if (isHotkey('ArrowUp')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.collapsed || !caret.isFirstLine) return;
          }

          const firstRowIndex = getRowIndexInSelectedCells();
          const firstColumnIndex = getColumnIndexInSelectedCells();

          const nextRowIndex = Math.max(firstRowIndex - 1, 0);
          const rid = subNodes[nextRowIndex];
          const pid = columns[firstColumnIndex];

          if (rid && pid) {
            dispatch(
              uiActions.update({
                selectedCells: [{ recordId: rid, propertyId: pid, viewId: tableId, syncId }],
              })
            );
          }

          delay(() => {
            if (!focus) return;
            focusEditable(
              getTableCellEditorKey(tableId, nextRowIndex, firstColumnIndex),
              Infinity,
              Infinity
            );
          });

          return;
        }

        if (isHotkey('Shift+ArrowUp')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.isFirstLine) return;

            event.preventDefault();
            dispatch(simpleTableActions.update({ focus: false }));
            blurDocument(true);
          }

          const lastIndex = getRowIndexInSelectedCells(true);
          if (lastIndex > rowIndex) {
            const rid = subNodes[lastIndex];
            if (rid) {
              dispatch(
                uiActions.update({
                  selectedCells: cache.ui.selectedCells.filter((o) => o.recordId !== rid),
                })
              );
            }
          } else {
            const firstIndex = getRowIndexInSelectedCells();
            const rid = subNodes[Math.max(firstIndex - 1, 0)];

            if (rid && !isInSelectedCells(rid, propertyId)) {
              const { columnSet } = getSet();
              dispatch(
                uiActions.update({
                  selectedCells: [
                    ...cache.ui.selectedCells,
                    ...[...columnSet].map((pid) => ({
                      recordId: rid,
                      propertyId: pid,
                      viewId: tableId,
                      syncId,
                    })),
                  ],
                })
              );
            }
          }
        }

        if (isHotkey('ArrowDown')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.collapsed || !caret.isLastLine) return;
          }

          const lastRowIndex = getRowIndexInSelectedCells(true);
          const lastColumnIndex = getColumnIndexInSelectedCells(true);

          const nextRowIndex = Math.min(lastRowIndex + 1, subNodes.length - 1);
          const rid = subNodes[nextRowIndex];
          const pid = columns[lastColumnIndex];

          if (rid && pid) {
            dispatch(
              uiActions.update({
                selectedCells: [{ recordId: rid, propertyId: pid, viewId: tableId, syncId }],
              })
            );
          }

          delay(() => {
            if (!focus) return;
            focusEditable(getTableCellEditorKey(tableId, nextRowIndex, lastColumnIndex), 0, 0);
          });

          return;
        }

        if (isHotkey('Shift+ArrowDown')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.isLastLine) return;

            event.preventDefault();
            dispatch(simpleTableActions.update({ focus: false }));
            blurDocument(true);
          }

          const firstIndex = getRowIndexInSelectedCells();
          if (firstIndex < rowIndex) {
            const rid = subNodes[firstIndex];
            if (rid) {
              dispatch(
                uiActions.update({
                  selectedCells: cache.ui.selectedCells.filter((o) => o.recordId !== rid),
                })
              );
            }
          } else {
            const lastIndex = getRowIndexInSelectedCells(true);
            const rid = subNodes[Math.min(lastIndex + 1, subNodes.length - 1)];

            if (rid && !isInSelectedCells(rid, propertyId)) {
              const { columnSet } = getSet();
              dispatch(
                uiActions.update({
                  selectedCells: [
                    ...cache.ui.selectedCells,
                    ...[...columnSet].map((pid) => ({
                      recordId: rid,
                      propertyId: pid,
                      viewId: tableId,
                      syncId,
                    })),
                  ],
                })
              );
            }
          }
        }

        const gotoNextCell = (blur?: boolean) => {
          let nextRowIndex = rowIndex;
          let nextColumnIndex = columnIndex;

          if (columnIndex + 1 >= columns.length) {
            if (rowIndex + 1 >= subNodes.length) return;

            nextRowIndex = rowIndex + 1;
            const rid = subNodes[nextRowIndex];
            nextColumnIndex = 0;
            const pid = columns[nextColumnIndex];

            if (rid && pid) {
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId: rid, propertyId: pid, viewId: tableId, syncId }],
                })
              );
            }
          } else {
            nextColumnIndex = columnIndex + 1;
            const pid = columns[nextColumnIndex];
            if (pid) {
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId, propertyId: pid, viewId: tableId, syncId }],
                })
              );
            }
          }

          const _editorKey = getTableCellEditorKey(tableId, nextRowIndex, nextColumnIndex);

          if (blur) {
            delay(() => blurDocument(true));
            dispatch(simpleTableActions.update({ focus: false }));
          } else {
            if (!focus) return;
            delay(() => {
              focusEditable(_editorKey, 0, 0);
            });
          }
        };

        if (isHotkey('Tab')(event)) {
          if (columnIndex + 1 >= columns.length && rowIndex + 1 >= subNodes.length) {
            const rid = subNodes[subNodes.length - 1];
            let rowId = '';
            transaction(() => {
              rowId = addBlock({ type: BlockType.TABLE_ROW }, { parentId: tableId, after: rid });
            });
            const pid = columns[0];
            if (pid) {
              event.preventDefault();
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId: rowId, propertyId: pid, viewId: tableId, syncId }],
                })
              );
              dispatch(simpleTableActions.update({ focus: false }));
            }
            return;
          }
          gotoNextCell(true);
        }

        if (isHotkey('ArrowRight')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.collapsed || !caret.isLastChar) return;
          }
          gotoNextCell();
        }

        if (isHotkey('Shift+ArrowRight')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.isLastChar) return;

            event.preventDefault();
            dispatch(simpleTableActions.update({ focus: false }));
            blurDocument(true);
          }

          const firstIndex = getColumnIndexInSelectedCells();

          if (firstIndex < columnIndex) {
            const pid = columns[firstIndex];
            if (pid) {
              dispatch(
                uiActions.update({
                  selectedCells: cache.ui.selectedCells.filter((o) => o.propertyId !== pid),
                })
              );
            }
          } else {
            const lastIndex = getColumnIndexInSelectedCells(true);
            const pid = columns[Math.min(lastIndex + 1, columns.length - 1)];

            if (pid && !isInSelectedCells(recordId, pid)) {
              const { rowSet } = getSet();
              dispatch(
                uiActions.update({
                  selectedCells: [
                    ...cache.ui.selectedCells,
                    ...[...rowSet].map((rid) => ({
                      recordId: rid,
                      propertyId: pid,
                      viewId: tableId,
                      syncId,
                    })),
                  ],
                })
              );
            }
          }
        }

        const gotoPrevCell = (blur?: boolean) => {
          let nextRowIndex = rowIndex;
          let nextColumnIndex = columnIndex;

          if (columnIndex - 1 < 0) {
            if (rowIndex - 1 < 0) return;
            nextRowIndex = rowIndex - 1;
            const rid = subNodes[nextRowIndex];
            nextColumnIndex = columns.length - 1;
            const pid = columns[nextColumnIndex];
            if (rid && pid) {
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId: rid, propertyId: pid, viewId: tableId, syncId }],
                })
              );
            }
          } else {
            nextColumnIndex = columnIndex - 1;
            const pid = columns[nextColumnIndex];
            if (pid) {
              dispatch(
                uiActions.update({
                  selectedCells: [{ recordId, propertyId: pid, viewId: tableId, syncId }],
                })
              );
            }
          }

          if (blur) {
            delay(() => blurDocument(true));
            dispatch(simpleTableActions.update({ focus: false }));
          } else {
            if (!focus) return;
            delay(() => {
              focusEditable(
                getTableCellEditorKey(tableId, nextRowIndex, nextColumnIndex),
                Infinity,
                Infinity
              );
            });
          }
        };

        if (isHotkey('Shift+Tab')(event)) {
          gotoPrevCell(true);
        }

        if (isHotkey('ArrowLeft')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.collapsed || !caret.isFirstChar) return;
          }
          gotoPrevCell();
        }

        if (isHotkey('Shift+ArrowLeft')(event)) {
          if (focus) {
            const caret = getCaretInfo(editorKey);
            if (!caret?.isFirstChar) return;

            event.preventDefault();
            dispatch(simpleTableActions.update({ focus: false }));
            blurDocument(true);
          }

          const lastIndex = getColumnIndexInSelectedCells(true);

          if (lastIndex > columnIndex) {
            const pid = columns[lastIndex];
            if (pid) {
              dispatch(
                uiActions.update({
                  selectedCells: cache.ui.selectedCells.filter((o) => o.propertyId !== pid),
                })
              );
            }
          } else {
            const firstIndex = getColumnIndexInSelectedCells();
            const pid = columns[Math.max(firstIndex - 1, 0)];

            if (pid && !isInSelectedCells(recordId, pid)) {
              const { rowSet } = getSet();
              dispatch(
                uiActions.update({
                  selectedCells: [
                    ...cache.ui.selectedCells,
                    ...[...rowSet].map((rid) => ({
                      recordId: rid,
                      propertyId: pid,
                      viewId: tableId,
                      syncId,
                    })),
                  ],
                })
              );
            }
          }
        }
      }
    };

    document.addEventListener('keydown', keydown, true);

    return () => {
      document.removeEventListener('keydown', keydown, true);
    };
  }, [getEditorModel, syncId, tableId, transaction]);
};

// eslint-disable-next-line
const INPUT_KEYS = [
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyE',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyJ',
  'KeyK',
  'KeyL',
  'KeyM',
  'KeyN',
  'KeyO',
  'KeyP',
  'KeyQ',
  'KeyR',
  'KeyS',
  'KeyT',
  'KeyU',
  'KeyV',
  'KeyW',
  'KeyX',
  'KeyY',
  'KeyZ',
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'BracketLeft',
  'BracketRight',
  'Backslash',
  'Semicolon',
  'Quote',
  'Comma',
  'Period',
  'Slash',
  'Space',
];
