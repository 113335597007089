import { arrayMove } from '@dnd-kit/sortable';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Divider } from 'src/common/components/divider';
import { useOpenModal } from 'src/common/components/next-modal';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCreatePropertyOption } from 'src/hooks/block/use-create-property-options';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useCollectionId } from 'src/hooks/collection-view/use-collection-id';
import { useViewParentId } from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { pickRandomValue } from 'src/utils/pick-random-value';
import { v4 as uuidV4 } from 'uuid';
import { COLORS } from '../../../const';
import type { Option } from '../../types';
import { useCellEditor } from '../hooks';
import type { CellEditorProps } from '../types';
import { OptionMore } from './option-more';
import { Options } from './options';
import { TagItem } from './tag-item';
import { Tags } from './tags';

interface Props extends CellEditorProps {
  multiple?: boolean;
}

export const TagsEditor: FC<Props> = ({
  multiple,
  site,
  recordId,
  viewId,
  recordIds,
  propertyId,
  onUpdate,
  onClose,
}) => {
  const collectionId = useCollectionId(recordId) ?? '';
  const selectOption = useRef<number | undefined>();
  const collectionReadonly = useReadonly(collectionId);
  const viewParentId = useViewParentId(viewId) ?? collectionId;
  const isLocked = useBlockLocked(viewParentId);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { propertySchema: schema } = usePropertySchema(collectionId, propertyId);
  const initialValue = useObservableBlock(
    recordId,
    (block) => {
      const segments = block?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments)
        .split(',')
        .map((text) => text.trim())
        .filter((item) => schema?.options?.some((option) => option.value === item))
        .join(',');
    },
    [propertyId]
  );
  const openModal = useOpenModal();
  const updatePropertyValue = useUpdatePropertyValue();
  const updatePropertySchema = useUpdatePropertySchema();
  const createPropertyOption = useCreatePropertyOption();
  const [tagsText, setTagsText] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');
  const [focusOption, setFocusOption] = useState<Option>();
  const isEditingOneRecord = !recordIds || recordIds.length === 1;

  const newOptionColor = useMemo(() => {
    const pool = COLORS.map((item) => item.key);
    return pickRandomValue(pool, schema?.options?.map((item) => item.color) ?? []) ?? COLORS[0].key;
  }, [schema?.options]);

  useCellEditor({
    onUpdate,
    onClose,
    onSave: () => {},
  });

  const saveValue = (value: string) => {
    setTagsText(value);
    updatePropertyValue(recordIds ?? recordId, propertyId, textToSegments(value));
  };

  const addTag = (tagValue: string) => {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
    if (!tagValue) return;

    const tags = tagsText.split(/\s*,\s*/g).filter((it) => it);
    if (tags.includes(tagValue) && isEditingOneRecord) return;
    tags.push(tagValue);
    const newValue = tags.join(',');
    saveValue(newValue);
  };

  const setTag = (tagValue: string) => {
    if (tagValue === tagsText && isEditingOneRecord) return;
    saveValue(tagValue);
  };

  const deleteLastTag = () => {
    const tags = tagsText.split(/\s*,\s*/g).filter((it) => it);
    tags.pop();
    saveValue(tags.join(','));
  };

  const handleSelectOption = (option: Option) => {
    if (!option.value) return;

    setInputValue('');
    if (multiple) {
      addTag(option.value);
      inputRef.current?.focus();
    } else {
      setTag(option.value);
      onClose();
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    if (isLocked || collectionReadonly) return;
    if (isHotkey('Esc')(event)) {
      onClose();
      return;
    }

    if (isHotkey('Enter')(event)) {
      if (typeof selectOption.current !== 'undefined') {
        const filterOptions =
          schema?.options?.filter((item) => item.value?.includes(inputValue)) ?? [];
        const option = filterOptions[selectOption.current];
        if (option) {
          handleSelectOption(option);
          return;
        }
      }

      if (showNewTag) {
        handleCreateNewTag();
      } else if (focusOption?.value) {
        if (multiple) {
          addTag(focusOption.value);
        } else {
          setTag(focusOption.value);
          onClose();
        }
      }
      return;
    }

    if (!inputValue && isHotkey('Backspace')(event)) {
      deleteLastTag();
    }
  };

  const handleSort = (oldId: string, newId: string) => {
    if (!schema?.options) return;
    const oldIndex = schema.options.findIndex((item) => item.id === oldId);
    const newIndex = schema.options.findIndex((item) => item.id === newId);
    const sortedOptions = arrayMove(schema.options, oldIndex, newIndex);
    updatePropertySchema(collectionId, propertyId, {
      options: sortedOptions,
    });
  };

  const handleCreateNewTag = () => {
    const id = uuidV4();
    const value = inputValue.replace(/,/g, '').trim();
    createPropertyOption(collectionId, propertyId, {
      id,
      value,
      color: newOptionColor,
    });

    if (multiple) {
      addTag(value);
      setInputValue('');
      inputRef.current?.focus();
    } else {
      setTag(value);
      onClose();
    }
  };

  const handleFocus = (option: Option) => {
    setFocusOption(option);
  };

  const closeBeforeCallbackRef = useRef<() => void | 'prevent'>();
  const showMore = (option: Option, event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'right',
      content({ onCloseModal }) {
        return (
          <OptionMore
            option={option}
            collectionId={collectionId}
            propertyId={propertyId}
            closeModal={onCloseModal}
            closeBeforeCallbackRef={closeBeforeCallbackRef}
          />
        );
      },
      closeBeforeCallBack: () => closeBeforeCallbackRef.current?.(),
    });
  };

  const filterOptions = schema?.options?.filter((item) => item.value?.includes(inputValue)) ?? [];

  const showNewTag =
    inputValue && schema?.options?.every((item) => item.value !== inputValue.trim());

  const readonly = collectionReadonly || isLocked;
  return (
    <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
      <div className="p-2 items-center flex flex-wrap min-h-[42px]">
        <Tags
          site={site}
          recordId={recordId}
          propertyId={propertyId}
          multiple={multiple}
          editable
          className="w-full"
          itemClassName="m-[3px] max-w-[calc(100%-6px)]"
          onChange={(value) => {
            saveValue(value);
            inputRef.current?.focus();
          }}
        />
        {!collectionReadonly && (
          <AutoHeightTextArea
            ref={inputRef}
            autoFocus
            singleLine
            placeholder="查找或创建选项"
            fontClassName={css({ lineHeight: '24px', fontSize: 14, whiteSpace: 'nowrap' })}
            className={css({
              width: '100%',
              selectors: { '&::placeholder': { color: 'var(--grey4)' } },
            })}
            boxClassName={css({
              flexBasis: 'auto',
              flexGrow: 1,
              flexShrink: 0,
              minWidth: 120,
              maxWidth: '100%',
            })}
            value={inputValue}
            onKeyDown={handleInputKeyDown}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
          />
        )}
      </div>

      <Divider />

      <div className={'p-2 text-t2 text-grey3'}>
        {!inputValue && filterOptions.length === 0 ? '暂无选项' : '选择或创建一个选项'}
      </div>

      <Options
        options={filterOptions}
        onSelect={handleSelectOption}
        onMore={showMore}
        onSort={handleSort}
        onFocus={handleFocus}
        readonly={readonly}
        selectOption={selectOption}
      />

      {showNewTag && !readonly && (
        <div
          onClick={handleCreateNewTag}
          className="p-2 flex items-center cursor-pointer animate-hover"
        >
          <span className="flex-shrink-0 text-grey3 text-t2 mr-2">创建选项</span>
          <TagItem color={newOptionColor} label={inputValue} value={inputValue} />
        </div>
      )}
    </div>
  );
};
