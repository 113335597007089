import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { EnhanceImage } from 'src/common/components/enhance-image';
import { LoadingContainer } from 'src/common/components/loading-container';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { formatUrl } from 'src/common/utils/url-utils';
import type { ReadonlyProp } from 'src/redux/types';
import { EmptyBookmark } from './empty-bookmark';

interface Props extends ReadonlyProp {
  title?: string;
  description?: string;
  cover?: string;
  icon?: string; // icon url
  url?: string;
  onUrlInput?: (url: string) => void;
  defaultOpenInputPanel: boolean; // 是否打开输入默认的panel
  loading: boolean;
}

export const Bookmark: FC<Props> = (props) => {
  if (!props.url) {
    return (
      <EmptyBookmark
        readonly={props.readonly}
        defaultOpenPanel={props.defaultOpenInputPanel}
        onButtonClick={props.onUrlInput}
      />
    );
  }
  const hasTitle = Boolean(props.title);
  return (
    <a
      className="flex flex-row justify-between animate-hover border rounded pl-4 h-[89px] overflow-hidden"
      href={props.url}
      target="_blank"
      rel="noreferrer"
    >
      {props.loading ? (
        <LoadingContainer />
      ) : (
        <>
          <div className={cx('flex flex-col py-[15px] justify-center w-full')}>
            <div className="flex flex-col">
              <div className={`text-ellipsis ${hasTitle ? 'text-t2' : 'text-t2-bold'}`}>
                {props.title || formatUrl(props.url)}
              </div>
              {props.description && (
                <div
                  className="mt-2 overflow-hidden text-t4 text-grey3 overflow-ellipsis"
                  style={{
                    // https://stackoverflow.com/questions/5269713/css-ellipsis-on-second-line
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {props.description}
                </div>
              )}
            </div>
            <div className="flex flex-row items-center mt-2">
              {props.icon && (
                <img
                  referrerPolicy="no-referrer"
                  loading="lazy"
                  className="w-4 h-4 mr-[6px]"
                  src={props.icon}
                />
              )}
              <span className={'text-t4 text-ellipsis'}>{props.url}</span>
            </div>
          </div>
          {props.cover && (
            <EnhanceImage
              loader={<LoadingIcon size="middle" />}
              src={props.cover}
              className="shrink-0 w-[calc(33.333%)] h-full self-center rounded-r-[3px] object-cover"
            />
          )}
        </>
      )}
    </a>
  );
};
