import { useMemo } from 'react';
import { usePickBlock } from 'src/utils/pick-block';
import { useOfficialSpace } from './use-official-space-share';

export const useIsRoleUser = (uuid = '', userId = '') => {
  const parentPage = usePickBlock(uuid, ['permissions']);
  const isOfficialSpace = useOfficialSpace(uuid);
  const isRoleUser = useMemo(() => {
    return (
      isOfficialSpace &&
      parentPage?.permissions.some((item) => item.type === 'user' && item.userId === userId)
    );
  }, [isOfficialSpace, parentPage?.permissions, userId]);
  return isRoleUser;
};
