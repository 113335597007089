import type { PDFRect, Rect } from '../type';

export const convertPageRectToPDFRect = (rects: Rect[], viewport: any): PDFRect[] => {
  return rects.map((rect) => {
    const [x, y] = viewport.convertToPdfPoint(rect.x, rect.y);
    const [xMax, yMax] = viewport.convertToPdfPoint(rect.x + rect.width, rect.y + rect.height);
    return { x, y, xMax, yMax };
  });
};

export const convertPDFRectToPageRect = (rects: PDFRect[], viewport: any): Rect[] => {
  return rects.map((rect) => {
    const [x, y, xMax, yMax] = viewport.convertToViewportRectangle([
      rect.x,
      rect.y,
      rect.xMax,
      rect.yMax,
    ]);
    return { x, y, width: xMax - x, height: yMax - y };
  });
};
