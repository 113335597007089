import { useLayoutEffect, useState } from 'react';
import { useFetchPage } from 'src/hooks/page';
import { cache } from 'src/redux/store';
import { isPageLike } from 'src/utils/block-type-utils';

/** 如果有数据就不会再fetch,避免重复拉接口 */
export const useTryFetchPageBlock = (pageId: string, visible?: boolean) => {
  const fetchPage = useFetchPage();
  const [loading, setLoading] = useState(() => {
    return !checkAlreadyFetch(pageId);
  });
  useLayoutEffect(() => {
    const init = async () => {
      // 如果已经有数据就不再拉了，减少请求(可能会更新不及时，但问题不大)
      if (loading && visible) {
        await fetchPage(pageId, { prefetch: true });
        setLoading(false);
      }
    };
    void init();
  }, [fetchPage, loading, pageId, visible]);
  return loading;
};

// 检查这个id内容是否已经有了，有的话不再拉数据
const checkAlreadyFetch = (id: string) => {
  const block = cache.blocks[id];
  const totalLoadBlockCount =
    block?.subNodes.reduce((pre, cur) => {
      const subBlock = cache.blocks[cur];
      if (subBlock && !isPageLike(subBlock?.type)) {
        return pre + 1;
      }
      return pre;
    }, 0) ?? 0;
  const loadPercent = (totalLoadBlockCount * 1.0) / (block?.subNodes.length ?? 1);
  // 如果加载的块有30%，我们认为这个页面已经拉过数据了
  return loadPercent > 0.3;
};
