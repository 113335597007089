import type { PermissionRole, SpaceDTO, UserDTO } from '@next-space/fe-api-idl';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { UNNAMED_USER } from 'src/common/const';
import { getPinyin } from 'src/utils/pinyin';

export type LocalUser = UserDTO & {
  /** @deprecated 这个参数能不用就不用，要搜索可以用searchUsers方法，用不了也要创建条件用*/
  pinyin: string;
  spaceRole?: PermissionRole;
};
export type StoreUsers = Record<string, LocalUser>;

/**
 * 这里存储这个空间所有用户的信息,不跟当前用户混在一起.
 * 如果你仅需要当前空间里的成员,请使用 useCurrentSpaceUsers。不然会拿到一些访客的信息
 */
const initialState = {} as StoreUsers;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    update(state, action: PayloadAction<Partial<typeof initialState>>) {
      Object.assign(state, action.payload);
    },
    clear() {
      return {};
    },
    /** 拼装用户权限和pinyin */
    updateSpaceUsers(
      state,
      action: PayloadAction<{ users: Record<string, UserDTO>; space?: SpaceDTO }>
    ) {
      const { users, space } = action.payload;
      const usersWithSpaceRole = Object.entries(users).reduce((newUsers, [uuid, user]) => {
        const markName = space?.userRemark?.[uuid] ?? '';
        newUsers[uuid] = {
          ...user,
          pinyin: `${getPinyin(user.nickname || UNNAMED_USER, true)}${user.phone}${getPinyin(
            markName
          )}`,
          spaceRole: space?.permissions?.find(({ userId }) => userId === uuid)?.role,
        };
        return newUsers;
      }, {} as StoreUsers);
      Object.assign(state, usersWithSpaceRole);
    },
    removeUser(state, action: PayloadAction<string>) {
      delete state[action.payload];
    },
  },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
