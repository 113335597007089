import { PermissionType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import type { StoreUsers } from 'src/redux/reducers/users';
import { getPinyin } from 'src/utils/pinyin';
import { searchUsers } from 'src/utils/search-util';
import { stringToLowerCaseAndRemoveSpace } from 'src/utils/string-util';
import { getCurrentSpace } from './use-current-space';
import { useCurrentSpaceUsers } from './use-current-space-users';

/** 通过关键词搜索成员，包括拼音和全汉字 */
export const useSearchSpaceMembers = () => {
  const currentSpaceUsers = useCurrentSpaceUsers();

  return useCallback(
    (options: {
      keywords?: string;
      filterIds?: string[];
      includeGroup?: boolean;
      users?: StoreUsers;
    }) => {
      const { keywords = '', filterIds = [], includeGroup, users = currentSpaceUsers } = options;
      const usersArray = Object.values(users).map((v) => v);
      // filter一些uuid
      const restUsers = usersArray.filter((v) => !filterIds.includes(v.uuid));
      const result = searchUsers(restUsers, keywords).map((v) => {
        return {
          ...v,
          type: PermissionType.USER,
        };
      });

      if (includeGroup) {
        const memberGroup = getCurrentSpace()
          .permissionGroups?.filter((v) => {
            const pinyin = getPinyin(v.name);
            const thesaurus = stringToLowerCaseAndRemoveSpace(`${v.name}${pinyin}`);
            return thesaurus.includes(stringToLowerCaseAndRemoveSpace(keywords));
          })
          .map((v) => {
            return {
              uuid: v.id,
              nickname: v.name,
              iconData: v.icon,
              type: PermissionType.GROUP,
            };
          });
        // @ts-ignore 增加成员组改动最小的实现
        memberGroup && result.unshift(...memberGroup);
        const { uuid, icon } = getCurrentSpace();
        result.unshift({
          uuid,
          nickname: '全体成员',
          // @ts-ignore 增加全体成员
          iconData: icon,
          type: PermissionType.SPACE,
        });
      }
      return result;
    },
    [currentSpaceUsers]
  );
};
