import { BlockType, ContentGravity } from '@next-space/fe-api-idl';
import type { CSSProperties } from 'react';
import { useMemo } from 'react';
import { isInColumnList } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';

export const useAlign = (uuid: string) => {
  const block = usePickBlock(uuid, ['data'], ['display', 'format']);
  const blockType = block?.type;
  const display = block?.data.display;
  const pageId = useGetPageId();

  const inColumnList = useMemo(() => isInColumnList(uuid, pageId), [pageId, uuid]);
  const isExternalImage = blockType === BlockType.EXTERNAL_FILE && display === 'image';
  const isRefImage = blockType === BlockType.REFERENCE && display === 'image';
  const isChart = blockType === BlockType.CHART && block?.data.format?.chartFormat?.ref?.uuid;
  const isImage =
    isRefImage || isExternalImage || (blockType === BlockType.FILE && display === 'image');
  let defaultGravity = ContentGravity.LEFT;
  // 图片/图表默认居中
  if ((isImage || isChart) && !inColumnList) {
    defaultGravity = ContentGravity.CENTER;
  }
  const contentGravity = block?.data.format?.contentGravity ?? defaultGravity;
  const captionGravity = block?.data.format?.captionGravity ?? defaultGravity;
  // 由于块成组的关系，一个块设置了居中，它的子块为了保持居左，需要设置对应的text-align
  return { contentGravity, captionGravity };
};

export const useCaptionStyle = (uuid: string) => {
  const { captionGravity } = useAlign(uuid);

  if (!captionGravity) {
    return {} as CSSProperties;
  }
  return {
    textAlign: textAlignMap[captionGravity],
  } as CSSProperties;
};

/** NOTE 非root下用这个方法 */
export const useImageAlignStyle = (uuid: string) => {
  const { contentGravity } = useAlign(uuid);
  // const pageId = useGetPageId();
  // const inRoot = useBlocksContextSelector((state) => state.blocks[uuid]?.parentId === pageId);
  if (!contentGravity) {
    return {} as CSSProperties;
  }

  return {
    alignItems: imageAlignMap[contentGravity],
  } as CSSProperties;
};

export const useRootImageAlignStyle = (uuid: string, pageWidth: number, inRoot: boolean) => {
  const { contentGravity } = useAlign(uuid);

  const block = usePickBlock(uuid, ['data'], ['format', 'width']);
  const imageWidth = block?.data.format?.width ?? block?.data.width ?? 0;

  if (!inRoot || !contentGravity) {
    return {} as CSSProperties;
  }

  if (pageWidth < 0) {
    return {} as CSSProperties;
  }
  if (imageWidth >= pageWidth) {
    // 如果图片过宽，就不能再设置位置，否则ui会有问题
    return {} as CSSProperties;
  }

  return {
    alignSelf: imageAlignSelfMap[contentGravity],
  } as CSSProperties;
};

/**
 * 文本对齐方式
 * 由于图片在分栏和不在分栏中ui布局不一样，导致无法统一在固定的层级上添加style，
 * 因此对于图片块，会另外处理
 */
export const useTextAlignStyle = (uuid: string) => {
  const { contentGravity } = useAlign(uuid);
  const blockType = usePickBlock(uuid, [])?.type;

  if (!contentGravity || blockType === BlockType.FILE) {
    return {} as CSSProperties;
  }
  // text
  return {
    textAlign: textAlignMap[contentGravity],
  } as CSSProperties;
};

const textAlignMap = {
  [ContentGravity.LEFT]: 'left',
  [ContentGravity.RIGHT]: 'right',
  [ContentGravity.CENTER]: 'center',
};
const imageAlignSelfMap = {
  [ContentGravity.LEFT]: 'self-start',
  [ContentGravity.RIGHT]: 'self-end',
  [ContentGravity.CENTER]: 'center',
};
const imageAlignMap = {
  [ContentGravity.LEFT]: 'start',
  [ContentGravity.RIGHT]: 'end',
  [ContentGravity.CENTER]: 'center',
};
