import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import type { CellViewProps } from './types';
import { TextValueView } from './text';
import { useTableViewIndex } from 'src/bitable/hooks/use-table-view-index';
import { useBitable } from 'src/bitable/context';

export const IdNumberValue: FC<CellViewProps> = ({ className, recordId, idnumberIndex }) => {
  const { viewId } = useBitable();
  let index = useTableViewIndex(viewId, recordId);
  if (idnumberIndex !== undefined) {
    index = idnumberIndex;
  }
  return (
    <TextValueView className={cx('w-full cursor-pointer p-2 leading-5 text-grey3', className)}>
      {index + 1}
    </TextValueView>
  );
};
