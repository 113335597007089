import { downloadImageToBlob } from '@flowus/common/proxy-url';
import type {
  CollectionSchema,
  CollectionViewProperties,
  SegmentDTO,
} from '@next-space/fe-api-idl';
import {
  BlockType,
  CollectionSchemaType,
  CollectionViewType,
  TextType,
} from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import { last } from 'lodash-es';
import { guessFileName } from 'src/common/utils/download-utils';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { makePropertyName } from 'src/hooks/block/use-create-property';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import type { UploadParamsExt } from 'src/hooks/space/use-upload';
import { transaction } from 'src/hooks/use-transaction';
import { getMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { addBlock } from 'src/redux/managers/block/add';
import { moveBlock } from 'src/redux/managers/block/move';
import { updateBlock } from 'src/redux/managers/block/update';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { cache } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { $currentUserCache } from 'src/services/user/current-user';
import { isMindMap } from 'src/utils/block-type-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { AIEditorScene, AIEditType, CODE_INIT_LINE_NUM } from './const';
import { inferBitableSchema } from './infer-bitable-schema';
import { md2Blocks } from './md2blocks';
import { getNodeType } from './utils';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { DEFAULT_CODE_LANGUAGE } from '../../plugin/code/const';

export let tablePropertyIds: string[] = [];
// export let codeStartLineNum = CODE_INIT_LINE_NUM; // 代码开始的行号，默认是 -1

export const handleStrPiece = (params: {
  str: string;
  pageId: string;
  scene: AIEditorScene;
  tempPageId: string;
  currentBlockId: React.MutableRefObject<string>;
  mindNodeTypes: React.MutableRefObject<string[]>;
  allLine: React.MutableRefObject<string[]>;

  popper?: React.MutableRefObject<Instance | null>;
  isInRight: boolean;
  resultContainer: React.MutableRefObject<HTMLDivElement | null>;
  modalContainer: React.MutableRefObject<HTMLDivElement | null>;

  uploadFile: (params: UploadParamsExt) => Promise<string>;

  useCollection?: boolean;
  useMindMap?: boolean;
  isEndWrap: boolean;
  codeStartLineNum: React.MutableRefObject<number>;
  editTypeRef: React.MutableRefObject<AIEditType | undefined>;
}) => {
  const {
    str,
    allLine,
    pageId,
    scene,
    tempPageId,
    currentBlockId,
    mindNodeTypes,
    popper,
    isInRight,
    resultContainer,
    modalContainer,
    uploadFile,
    useCollection = false,
    useMindMap = false,
    isEndWrap,
    codeStartLineNum,
    editTypeRef,
  } = params;

  if (!str) return;
  if (str === '\n') {
    const currResult = allLine.current.join('');

    // 换行前面是空白符的时候过滤换行
    if (currResult.trim().length === 0) {
      allLine.current = [''];
      return;
    }

    // 重复的换行只保留一个
    if (currResult.endsWith('\n')) return;
  }

  if (allLine.current.length === 0) {
    allLine.current.push(str);
  } else {
    allLine.current[allLine.current.length - 1] = (last(allLine.current) ?? '') + str;
  }

  let isTempPage =
    scene === AIEditorScene.TextSelected ||
    scene === AIEditorScene.BlockSelected ||
    scene === AIEditorScene.TextSelectedAndWroteByAI ||
    scene === AIEditorScene.BlockSelectedAndWroteByAI;

  // 特殊需求，思维导图块续写直接更新，不需要有交互窗口
  if (useMindMap && editTypeRef.current === AIEditType.ContinueWrite) {
    isTempPage = false;
  }
  const parentId = isTempPage ? tempPageId : pageId;

  const currentLine = last(allLine.current) ?? '';
  const trimStartLine = currentLine.trimStart();

  // 假定：｜ 开头的就是表格
  const isTableLine = trimStartLine.startsWith('|');
  // 假定：``` 开头的就是 code
  if ((trimStartLine === '`' || trimStartLine === '``') && str !== '\n') return;
  const isCodeLine = trimStartLine.startsWith('```') && !trimStartLine.startsWith('```markdown');
  // 假定：![ 开头的就是图片
  if (trimStartLine === '!' && str !== '\n') return;
  const isImageLine = trimStartLine.startsWith('![');

  const currBlock = cache.blocks[currentBlockId.current];
  const currBlockIsTable =
    currBlock?.type === BlockType.TABLE ||
    (useCollection && currBlock?.type === BlockType.COLLECTION_VIEW);
  const currBlockIsCode = currBlock?.type === BlockType.CODE;
  if (currBlockIsTable && (!isTableLine || (isTableLine && isEndWrap))) {
    if (currBlock?.type === BlockType.COLLECTION_VIEW) {
      void Promise.resolve().then(() => {
        inferBitableSchema(currBlock.uuid, [...allLine.current], isTempPage);
      });
    }
  }

  // code 和 image 都能知道结束条件，所以在换行结束 image 或者 code 的时候，可以创建下一个新 block
  // table 无法知道当前行是否是 table 的最后一行，所以 table 之后的 block 需要在 table 之后的新的一行内容开始的时候新建，这也是唯一在 str !== '\n' 的时候也会创建新 block 的情况

  const updateModalPos = () => {
    updatePos({
      popper,
      isInRight,
      isShowInTempPage: isTempPage,
      resultContainer,
      editContainerRef: modalContainer,
      currentBlockId,
      useMindMap,
    });
  };

  if (str === '\n') {
    // 如果当前是在 Code 中，则将当前行加入 code 的 text 中
    // code 的处理需要在 table 之前，因为 table 可能在 code 里面
    if (codeStartLineNum.current !== CODE_INIT_LINE_NUM) {
      // 如果是第一个 ```, 解析 language
      if (allLine.current.length - 1 === codeStartLineNum.current) {
        const blocks = md2Blocks(currentLine);
        const format = Object.values(blocks)[0]?.data.format;
        if (format) {
          updateBlock(currentBlockId.current, { data: { format, isByAI: true } }, isTempPage);
        }

        allLine.current.push(''); // 需要新行
        return;
      }
      // 如果是第二个 ```
      if (isCodeLine && allLine.current.length - 1 !== codeStartLineNum.current) {
        allLine.current.push('');
        codeStartLineNum.current = CODE_INIT_LINE_NUM;
        !isTempPage && submitCurrBlock(scene, currentBlockId);
        currentBlockId.current = addBlock(
          { type: BlockType.TEXTAREA, data: { segments: [], isByAI: true } },
          { parentId, after: currentBlockId.current },
          isTempPage
        );
        return;
      }

      let allCodeStr = '';
      for (let i = allLine.current.length - 1; i > codeStartLineNum.current; i--) {
        const curLine = allLine.current[i] ?? '';
        allCodeStr = curLine + allCodeStr;
      }

      updateBlock(
        currentBlockId.current,
        {
          data: {
            segments: [{ text: allCodeStr, type: TextType.TEXT, enhancer: {} }],
            isByAI: true,
          },
        },
        isTempPage
      );
      updateModalPos();

      allLine.current.push(''); // 需要新行
      return;
    }

    // 处理表格行
    if (isTableLine) {
      if (!currBlockIsTable) {
        // 这里用来创建表格
        const lastLine = allLine.current[allLine.current.length - 2];
        if (lastLine && lastLine.trimStart().startsWith('|')) {
          const blocks = md2Blocks(`${lastLine}${currentLine}`);

          const values = Object.values(blocks);
          let tableBlock: NextBlock | undefined;
          let RowBlock: NextBlock | undefined;
          values.forEach((item) => {
            if (item.type === BlockType.TABLE) {
              tableBlock = item;
            } else {
              RowBlock = item;
            }
          });

          if (tableBlock) {
            if (useCollection) {
              // 转为多维表 开始
              const { tableBlockColumnOrder: propertyIds, tableBlockRowHeader } =
                tableBlock?.data.format ?? {};
              if (!propertyIds) return;

              tablePropertyIds = propertyIds;

              const firstRowBlock = RowBlock;
              if (!firstRowBlock) return;

              const schema: Record<string, CollectionSchema> = {};
              const properties: CollectionViewProperties = [];

              propertyIds.forEach((uuid, index) => {
                let name = '';
                if (tableBlockRowHeader) {
                  const segments = firstRowBlock.data.collectionProperties?.[uuid];
                  name = segmentsToText(segments);
                }

                if (index === 0) {
                  schema['title'] = {
                    name: name || '标题',
                    type: CollectionSchemaType.TITLE,
                  };
                } else {
                  schema[uuid] = {
                    name: makePropertyName(schema, name || undefined),
                    type: CollectionSchemaType.TEXT,
                  };
                }

                properties.push({ property: index === 0 ? 'title' : uuid, visible: true });
              });

              CollectionViewManager.add(
                {
                  title: '默认视图',
                  format: { tableProperties: properties, tableWrap: true },
                  pageSort: [],
                  type: CollectionViewType.TABLE,
                },
                { parentId: currentBlockId.current },
                isTempPage
              );

              updateBlock(
                currentBlockId.current,
                {
                  type: BlockType.COLLECTION_VIEW,
                  data: { schema, isByAI: true },
                },
                isTempPage
              );
            } else {
              updateBlock(
                currentBlockId.current,
                {
                  type: BlockType.TABLE,
                  data: { ...tableBlock.data, isByAI: true },
                },
                isTempPage
              );

              if (RowBlock) {
                addBlock(
                  { type: BlockType.TABLE_ROW, data: { ...RowBlock.data, isByAI: true } },
                  { parentId: currentBlockId.current, first: true },
                  isTempPage
                );
              }
            }

            updateModalPos();
          }
        }
      } else {
        // 这里用来添加行
        // 换行的时候手动解析 row 的数据，添加 row
        const cells = currentLine
          .split('|')
          .filter((str) => !!str.trim())
          .map((str) => str.trim());

        const columns = useCollection
          ? tablePropertyIds
          : currBlock?.data.format?.tableBlockColumnOrder;
        if (!columns) return;

        const collectionProperties: Record<string, SegmentDTO[]> = {};
        columns.forEach((uuid, index) => {
          if (useCollection && index === 0) return;

          collectionProperties[uuid] = [
            { text: cells[index] ?? '', type: TextType.TEXT, enhancer: {} },
          ];
        });

        if (useCollection) {
          addBlock(
            {
              type: BlockType.PAGE,
              data: {
                collectionProperties,
                segments: textToSegments(cells[0]),
                isByAI: true,
              },
            },
            { parentId: currentBlockId.current, last: true },
            isTempPage
          );
        } else {
          addBlock(
            {
              type: BlockType.TABLE_ROW,
              data: { collectionProperties, pageFixedWidth: true, format: {}, isByAI: true },
            },
            { parentId: currentBlockId.current, last: true },
            isTempPage
          );
        }

        updateModalPos();
      }

      allLine.current.push('');
      return;
    }

    // 如果当前是图片行
    if (isImageLine) {
      const imageUrl = currentLine.match(/(?<=\]\()https?:\/\/.*(?=\))/)?.[0] ?? '';
      if (imageUrl) {
        // 图片行结束，更新文本块为图片块
        updateBlock(
          currentBlockId.current,
          {
            type: BlockType.FILE,
            data: { segments: [], display: 'image' },
          },
          isTempPage
        );
        const imageBlockId = currentBlockId.current;

        void fetch(imageUrl).then(
          (res) =>
            res.blob().then((blob) => {
              const fileName = guessFileName(imageUrl);
              void handleUploadImage(
                uploadFile,
                imageBlockId,
                fileName,
                blob,
                updateModalPos,
                isTempPage
              );
            }),
          () => {
            void downloadImageToBlob(imageUrl)
              .then((blobInfo) => {
                if (blobInfo) {
                  void handleUploadImage(
                    uploadFile,
                    imageBlockId,
                    blobInfo.fileName,
                    blobInfo.blob,
                    updateModalPos,
                    isTempPage
                  );
                }
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
              });
          }
        );
      } else {
        updateBlock(
          currentBlockId.current,
          {
            data: {
              segments: [{ type: TextType.TEXT, enhancer: {}, text: currentLine }],
              isByAI: true,
            },
          },
          isTempPage
        );
      }

      // 图片行结束，创建普通文本块，用来放下一行文本信息
      currentBlockId.current = addBlock(
        { type: BlockType.TEXTAREA, data: { segments: [], isByAI: true } },
        { parentId, after: currentBlockId.current },
        isTempPage
      );

      updateModalPos();

      allLine.current.push('');
      return;
    }

    // 普通 block 走下面的逻辑
    allLine.current.push('');
    !isTempPage && submitCurrBlock(scene, currentBlockId, useMindMap);
    if (useMindMap) {
      // 如果是思维导图，需要特殊处理下，添加到当前节点的子节点上
      currentBlockId.current = addBlock(
        { type: BlockType.TEXTAREA, hidden: true, data: { segments: [], isByAI: true } },
        { parentId: currentBlockId.current, last: true },
        isTempPage
      );
      return;
    }
    // 当遇到换行时需要创建块
    currentBlockId.current = addBlock(
      { type: BlockType.TEXTAREA, data: { segments: [], isByAI: true } },
      { parentId, after: currentBlockId.current },
      isTempPage
    );
  } else {
    // 如果当前行是表格行，或者属于 code 中的内容，忽略此次数据
    if (codeStartLineNum.current !== CODE_INIT_LINE_NUM || isTableLine) {
      return;
    }

    // 如果当前块是表格块，但当前行不是表格行，说明表格数据已经解析完毕，此时应该新建 block。这也是唯一在 str !== '\n' 的时候新建 block 的情况
    if (currBlockIsTable && !isTableLine) {
      // 新块可能是代码块
      if (isCodeLine) {
        codeStartLineNum.current = allLine.current.length - 1;
      }

      currentBlockId.current = addBlock(
        {
          type: isCodeLine ? BlockType.CODE : BlockType.TEXTAREA,
          data: {
            segments: [{ text: isCodeLine ? '' : str, type: TextType.TEXT, enhancer: {} }],
            isByAI: true,
          },
        },
        { parentId, after: currentBlockId.current },
        isTempPage
      );

      return;
    }

    // 如果是 code 行(```)，那么设置代码开始行号，如果当前 block 不是 code，则更新当前 block 为 code
    if (isCodeLine) {
      codeStartLineNum.current = allLine.current.length - 1;

      if (!currBlockIsCode) {
        updateBlock(
          currentBlockId.current,
          {
            type: BlockType.CODE,
            data: {
              segments: [{ text: '', type: TextType.TEXT, enhancer: {} }],
              isByAI: true,
              format: {
                language: getLastCodeLanguage() ?? DEFAULT_CODE_LANGUAGE,
              },
            },
          },
          isTempPage
        );
      }
      return;
    }

    if (isImageLine) return;

    // 常规行内 markdown 解析
    let lineText = currentLine;
    // 四个空格开头的避免解析为 code
    const isLikeCode = currentLine.startsWith('    ');
    if (isLikeCode) {
      lineText = currentLine.trimStart();
    }
    const moveDesc = (sourceId: string, parentId: string) => {
      const source = segmentsToText(cache.blocks[sourceId]?.data.segments);
      const parent = segmentsToText(cache.blocks[parentId]?.data.segments);
      console.log(`把【${source}】 移动到 【${parent}】下面`);
    };
    const moveMindMapNode = (block: NextBlock) => {
      if (block.data.level === 1) {
        // 有可能是续写返回的标题，需要过虑掉（我也不知道为什么AI那么傻逼）
        return;
      }
      // 判断添加到哪，是子节点，兄弟节点，还是父节点尾部
      const index = mindNodeTypes.current.findIndex((v) => v === getNodeType(block));
      const curBlock = cache.blocks[currentBlockId.current];
      let lastBlockId = currentBlockId.current;
      if (curBlock?.hidden && !isMindMap(curBlock.type)) {
        // 新加的，需要找回上一个
        lastBlockId = curBlock.parentId;
      }
      if (!lastBlockId) {
        console.error('lastBlockId is not found');
        return;
      }
      if (index === -1) {
        // 新增子节点
        moveBlock([currentBlockId.current], {
          parentId: lastBlockId,
          ignoreOp: isTempPage,
        });
        // moveDesc(currentBlockId.current, lastBlockId);
        mindNodeTypes.current.push(getNodeType(block));
      } else if (index === mindNodeTypes.current.length - 1) {
        // 新增兄弟节点
        moveBlock([currentBlockId.current], {
          parentId: cache.blocks[lastBlockId]?.parentId ?? '',
          after: lastBlockId,
          ignoreOp: isTempPage,
        });
        // moveDesc(currentBlockId.current, cache.blocks[lastBlockId]?.parentId ?? '');
      } else {
        // 定位到父节点上
        const endIndex = mindNodeTypes.current.length - 1;
        mindNodeTypes.current = mindNodeTypes.current.slice(0, index + 1);
        let count = endIndex - index;
        if (count === 0) {
          // eslint-disable-next-line no-console
          console.error('有错误！！');
        }
        let currentId: string = lastBlockId;
        let parentId = cache.blocks[lastBlockId]?.parentId;
        while (count > 0) {
          const parentBlock = cache.blocks[parentId ?? ''];
          parentId = parentBlock?.parentId;
          count--;
        }
        const parentBlockNew = cache.blocks[parentId ?? ''];
        currentId = parentBlockNew?.subNodes[parentBlockNew?.subNodes.length - 1] ?? '';
        // 添加到定位节点的最后一个子节点上
        moveBlock([currentBlockId.current], {
          parentId: cache.blocks[currentId]?.parentId ?? '',
          after: currentId,
          ignoreOp: isTempPage,
        });
        // moveDesc(currentBlockId.current, cache.blocks[currentId]?.parentId ?? '');
      }
    };

    const blocks = md2Blocks(lineText);
    if (blocks) {
      const block = Object.values(blocks)[0];
      if (block) {
        // 如果当前是思维导图，即便这一次没更新完(数据不完整)，下一次更新也会保持思维导图的类型不变
        const currentMindMap = isMindMap(cache.blocks[currentBlockId.current]?.type);
        if (currentMindMap || (useMindMap && mindNodeTypes.current.length === 0)) {
          updateBlock(
            currentBlockId.current,
            {
              type: BlockType.MIND_MAPPING,
              data: { ...block.data, isByAI: true },
            },
            isTempPage
          );
          mindNodeTypes.current.push(getNodeType(block));
        } else {
          if (useMindMap) {
            // 把节点移动到对的位置
            moveMindMapNode(block);
          }
          // 不进 op，只做展示
          updateBlock(
            currentBlockId.current,
            {
              hidden: false,
              type: block.type,
              data: {
                ...block.data,
                isByAI: true,
                segments: isLikeCode
                  ? [
                      {
                        text: new Array(currentLine.length - lineText.length).fill(' ').join(''),
                        type: TextType.TEXT,
                        enhancer: {},
                      },
                      ...(block.data.segments ?? []),
                    ]
                  : block.data.segments,
              },
            },
            true
          );
        }
      }
    }
  }

  updateModalPos();
};

const submitCurrBlock = (
  scene: AIEditorScene,
  currentBlockId: React.MutableRefObject<string>,
  force?: boolean
) => {
  // 当数据接收完或者被中止的时候，提交当前 block 的 op
  if (
    scene === AIEditorScene.PageEmpty ||
    scene === AIEditorScene.PageHasContent ||
    scene === AIEditorScene.PageContentWroteByAI ||
    force
  ) {
    transaction(() => {
      const currBlock = cache.blocks[currentBlockId.current];
      if (currBlock) {
        updateBlock(currentBlockId.current, {
          type: currBlock.type,
          data: { ...currBlock.data, isByAI: true },
        });
      }
    });
  }
};

const handleUploadImage = (
  uploadFile: (params: UploadParamsExt) => Promise<string>,
  blockId: string,
  fileName: string,
  blob: Blob,
  callback: () => void,
  ignoreOp?: boolean
) => {
  const file = new File([blob], fileName);

  void uploadFile({
    key: blockId,
    userId: $currentUserCache.uuid,
    spaceId: getCurrentSpaceId(),
    file,
    type: 'file',
    onComplete: async (ret: any) => {
      if (ret.success) {
        const mediaInfo = await getMediaInfo(file);
        const { ossName } = ret;
        updateBlock(
          blockId,
          {
            type: BlockType.FILE,
            data: {
              ossName,
              ...mediaInfo,
              segments: textToSegments(fileName),
              size: blob.size,
              isByAI: true,
            },
          },
          ignoreOp
        );
        // dispatch(TRANSACTION_FIRE());
        callback?.();
      }
    },
  });
};

const updatePos = (params: {
  popper?: React.MutableRefObject<Instance | null>;
  isInRight: boolean;
  isShowInTempPage: boolean;
  resultContainer: React.MutableRefObject<HTMLDivElement | null>;
  editContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  currentBlockId: React.MutableRefObject<string>;
  useMindMap?: boolean;
}) => {
  const {
    isInRight,
    isShowInTempPage,
    resultContainer,
    editContainerRef,
    currentBlockId,
    popper,
    useMindMap,
  } = params;

  setTimeout(() => {
    if (isShowInTempPage) {
      const container = resultContainer.current;
      if (!container) return;

      if (container.clientHeight > window.innerHeight * 0.38) {
        container.style.paddingBottom = '200px';
      } else {
        container.style.paddingBottom = '0';
      }

      if (container.scrollHeight - container.scrollTop - 200 > container.clientHeight) {
        container.scrollTop = container.scrollTop + container.clientHeight / 2;
      }
      return;
    }

    const instance = popper?.current;
    if (!instance) return;

    let blockId = currentBlockId.current;
    if (useMindMap) {
      blockId = getOwnerPage(currentBlockId.current) ?? '';
    }
    const node = querySelectorFromMainContent(
      `[data-block-id="${blockId}"]`,
      isInRight
    ) as HTMLElement | null;
    if (!node) return;

    if (instance.state.elements.reference !== node) {
      instance.state.elements.reference = node;
    }

    const editorContainer = editContainerRef.current;
    const pageContainer = querySelectorFromMainContent(`.next-space-page`, isInRight);
    if (!editorContainer) return;
    if (!pageContainer) return;

    const { reference } = instance.state.elements;
    if (!reference) return;

    const editorHeight = editorContainer.clientHeight + 10;
    const rect = reference.getBoundingClientRect();
    const pageContainerRect = pageContainer.getBoundingClientRect();
    const offsetContainer = rect.bottom - pageContainerRect.top;

    if (pageContainer.clientHeight - offsetContainer < editorHeight) {
      const offsetContentContainer = offsetContainer + pageContainer.scrollTop;
      const newScrollTop = offsetContentContainer - (pageContainer.clientHeight - editorHeight);
      pageContainer.scrollTo(0, newScrollTop + pageContainer.clientHeight / 2);
    }

    void instance.update();
  });
};
