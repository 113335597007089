import { BlockType } from '@next-space/fe-api-idl';
import { batch } from 'react-redux';
import { request } from 'src/common/request';
import { TurnWhiteList } from 'src/editor/component/menu-list/const';
import { DEFAULT_CODE_LANGUAGE } from 'src/editor/editor/plugin/code/const';
import { getInitBlockParams } from 'src/editor/utils/block';
import { getPageWidthFixed } from 'src/hooks/public/get-page-width-fixed';
import { getCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceView } from 'src/hooks/space/get-space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { $currentUserCache } from 'src/services/user/current-user';
import { getLastCodeLanguage } from 'src/utils/block-utils';
import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK, UPDATE_BLOCK } from '../../actions';
import { cache, dispatch, getState } from '../../store';
import type { NextBlock } from '../../types';
import { addBlock } from './add';
import { addNewColumns } from './add-column';
import { antiCycleSet_convertBlock_0, moveBlock } from './move';

interface Patch {
  type: BlockType;
  backgroundColor?: string;
  textColor?: string;
  local?: boolean;
  data?: Partial<NextBlock['data']>;
}

export const convertBlock = (uuids: string[], patch: Patch) => {
  const { blocks } = getState();
  // 同步块特殊处理
  if (patch.type === BlockType.SYNC_CONTAINER) {
    const parentId = blocks[uuids[0] ?? '']?.parentId;
    parentId && covertToSyncBlock(uuids, parentId);
    return;
  }
  // 多个块转为分栏特殊处理: 在第一个选中块下创建n分栏，再把块move进去
  if (patch.type === BlockType.COLUMN_LIST) {
    if (uuids.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const columnListId = addNewColumns(uuids[0]!, uuids.length, {
        createEmptyTextBlock: false,
        autoTrim: false,
        convertBlock,
      });
      cache.blocks[columnListId ?? '']?.subNodes.forEach((columnId, index) => {
        const selectBlockId = uuids[index];
        if (selectBlockId) {
          moveBlock([selectBlockId], {
            parentId: columnId,
          });
        }
      });
      return;
    }
  }

  batch(() => {
    uuids.forEach(async (uuid) => {
      const { type, ...params } = patch;
      const block = blocks[uuid];
      if (!block) return;

      // 如果是文件块转引用，可以通过
      const isFileToRef = block.type === BlockType.FILE && type === BlockType.REFERENCE;
      if (!TurnWhiteList.includes(block.type) && !isFileToRef) return;
      if (type === block.type) {
        if (type === BlockType.HEADER || type === BlockType.TOGGLE_HEADER) {
          if (block.data.level === patch.data?.level) return;
          // else fallthrough
        } else if (type !== BlockType.REFERENCE && type !== BlockType.FILE) return;
      }

      if (type === BlockType.MARK) {
        if (!block.data.icon) {
          params.data = { ...params.data, icon: { type: 'emoji', value: '💡' } };
        }
        if (!block.textColor && !block.backgroundColor) {
          // 如果原来的 block 没有颜色值，就用上一次 着重block 用过的，着重block 的 feature, 产品提的
          const initParams = getInitBlockParams(BlockType.MARK);
          const { data, ...rest } = initParams;
          Object.assign(params, rest);
          params.data = { ...params.data, ...data };
        }
      }

      if (type === BlockType.CODE) {
        const lastCodeLanguage = getLastCodeLanguage();
        if (!params.data) {
          params.data = {};
        }
        if (params.data) {
          params.data.format = {
            ...params.data?.format,
            language: lastCodeLanguage ?? DEFAULT_CODE_LANGUAGE,
          };
        }
      }
      if (type === BlockType.MIND_MAPPING || type === BlockType.MIND_MAPPING_PAGE) {
        if (!params.data) {
          params.data = {};
        }
        params.data.format = {
          ...params.data.format,
          mindMappingFormat: {
            ...params.data.format?.mindMappingFormat,
            oldBlockType: block.type,
          },
        };
      }

      if (type === BlockType.PAGE) {
        if (!params.data) {
          params.data = {};
        }
        if (!params.data.format) {
          params.data.format = {};
        }
        if (typeof block.data.pageFixedWidth !== 'boolean') {
          Object.assign(params.data, {
            pageFixedWidth: getPageWidthFixed(),
            directoryMenu: $currentUserCache.setting?.directoryMenu,
          });
        }
        Object.assign(params.data.format, {
          pageLayout: getCurrentSpaceView()?.setting.pageLayout,
          fontFormat: getCurrentSpaceView()?.setting.fontFormat,
          commentAlignment: getCurrentSpace().setting?.commentAlignment,
        });
      }

      dispatch(UPDATE_BLOCK({ uuid, patch: { type, ...params } }));
      if ([BlockType.EQUATION, BlockType.CODE].includes(type)) {
        block.subNodes.reduce((prevId, item) => {
          dispatch(LIST_REMOVE_BLOCK({ uuid: item }));
          dispatch(
            LIST_AFTER_BLOCK({
              uuid: item,
              parentId: block.parentId,
              after: prevId,
            })
          );
          return item;
        }, uuid);
      }

      if (block.type === BlockType.PAGE) {
        // 页面块转其他块时，请求后更新本地没有的块
        const res = await request.editor.getDoc(block.uuid);
        const newBlocks = Object.entries(res.blocks).reduce((o, [uuid, _block]) => {
          if (!blocks[uuid]) {
            o[uuid] = _block;
          }
          return o;
        }, {} as any);
        dispatch(blocksActions.update({ blocks: newBlocks }));
      }
    });
  });
};

/** 把ids的block转为同步块 */
export const covertToSyncBlock = (ids: string[], parentId: string) => {
  const id = ids[0];
  if (!id) {
    return;
  }
  // 先新创建一个sync block，查到ids第一个
  // 再把ids的move到syncblock的subnodes下
  const syncBlockId = addBlock(
    {
      type: BlockType.SYNC_CONTAINER,
    },
    {
      parentId,
      before: id,
    }
  );
  moveBlock(ids, { parentId: syncBlockId });
  return syncBlockId;
};

antiCycleSet_convertBlock_0(convertBlock);
