import { TextType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';

import { MONTHS } from 'src/utils/date-utils';
import { DateDir, ONE_DAY, ONE_HOURS, ONE_MOMENT, UnitWidthMap, ZoomLevel } from '../const';

/**
 * 该方法根据给定的基准时间计算一个时间范围
 * @param base 计算时间范围的基准时间
 * @param length 时间范围的大小
 * @param isBackward 是否基于基准时间向后取时间
 * @param includeBase 是否包含基准时间
 * @returns
 */
export const getDateRange = ({
  base = Date.now(),
  dir = DateDir.NEXT,
  length = 100,
  includeBase = false,
  zoomLevel = ZoomLevel.MONTH,
}: DatesParams): number[] => {
  const dates: number[] = [];
  const isNext = dir === DateDir.NEXT;

  if (isWeekOrMonth(zoomLevel) || zoomLevel === ZoomLevel.QUARTER) {
    let baseDate = getDateTimeStamp(base);
    if (zoomLevel === ZoomLevel.QUARTER) {
      baseDate -= (new Date(base).getDay() - 1) * ONE_DAY;
    }
    const dateStep = zoomLevel === ZoomLevel.QUARTER ? ONE_DAY * 7 : ONE_DAY;

    const newLength = includeBase ? length : length + 1;
    for (let i = 0; i < newLength; i++) {
      if (!includeBase && i === 0) continue;

      if (isNext) {
        const time = baseDate + i * dateStep;
        dates.push(time);
      } else {
        const time = baseDate - i * dateStep;
        dates.unshift(time);
      }
    }
  } else if (zoomLevel === ZoomLevel.YEAR) {
    const baseDate = new Date(base);
    const monthFirstDate = getDateTimeStamp(baseDate.setDate(1));
    if (includeBase) {
      dates.push(monthFirstDate);
    } else {
      const month = new Date(monthFirstDate).getMonth();
      dates.push(new Date(monthFirstDate).setMonth(isNext ? month + 1 : month - 1, 1));
    }

    for (let i = 0; i < length - 1; i++) {
      if (isNext) {
        const lastDate = dates[dates.length - 1] as number;
        const month = new Date(lastDate).getMonth();
        dates.push(new Date(lastDate).setMonth(month + 1, 1));
      } else {
        const firstDate = dates[0] as number;
        const month = new Date(firstDate).getMonth();
        dates.unshift(new Date(firstDate).setMonth(month - 1, 1));
      }
    }
  } else if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
    const baseTime =
      zoomLevel === ZoomLevel.DAY ? getDateHourStamp(base) : getDateMomentStamp(base);
    const dateStep = zoomLevel === ZoomLevel.DAY ? ONE_HOURS : ONE_MOMENT;

    const newLength = includeBase ? length : length + 1;
    for (let i = 0; i < newLength; i++) {
      if (!includeBase && i === 0) continue;

      if (isNext) {
        const time = baseTime + i * dateStep;
        dates.push(time);
      } else {
        const time = baseTime - i * dateStep;
        dates.unshift(time);
      }
    }
  }

  return dates;
};

interface DatesParams {
  base?: number;
  dir?: DateDir;
  length?: number;
  includeBase?: boolean;
  zoomLevel?: ZoomLevel;
}

export const getTimelineDateRange = ({ zoomLevel, length, base }: DatesParams) => {
  return [
    ...getDateRange({ base, dir: DateDir.PREV, zoomLevel, length }),
    ...getDateRange({ base, dir: DateDir.NEXT, zoomLevel, length, includeBase: true }),
  ];
};

export const getDateTimeStamp = (time: number) => {
  return new Date(new Date(time).toDateString()).getTime();
};

export const getDateHourStamp = (time: number) => {
  return new Date(time).setHours(new Date(time).getHours(), 0, 0, 0);
};

export const getDateMomentStamp = (time: number) => {
  const hours = getDateHourStamp(time);
  return hours + Math.floor((time - hours) / ONE_MOMENT) * ONE_MOMENT;
};

export const computeDateRangeLength = (endDate: number, startDate: number) => {
  return Math.max(Math.abs(getDateTimeStamp(endDate) - getDateTimeStamp(startDate)), 0) / ONE_DAY;
};

export const formatDate = (time: number) => {
  const date = new Date(time);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
    day: date.getDay(),
    monthDesc: MONTHS[date.getMonth()],
    time,
  };
};

export const isToday = (time: number) => getDateTimeStamp(time) === getDateTimeStamp(Date.now());

export const updateDate = (oldDate: number, newDate: number, zoomLevel?: ZoomLevel) => {
  const date = new Date(newDate);

  if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
    return new Date(newDate);
  }

  return dayjs(oldDate)
    .year(date.getFullYear())
    .month(date.getMonth())
    .date(date.getDate())
    .toDate();
};

export const getUnitLength = (
  firstDate: number,
  secondDate: number,
  zoomLevel: ZoomLevel,
  options?: { needDateStart?: boolean; abs?: boolean }
) => {
  const { needDateStart = false, abs = true } = options ?? {};
  let result = 0;

  if (zoomLevel === ZoomLevel.HOUR || zoomLevel === ZoomLevel.DAY) {
    const offset = needDateStart
      ? getDateMomentStamp(firstDate) - getDateMomentStamp(secondDate)
      : firstDate - secondDate;
    result = offset / ONE_MOMENT;
  } else {
    const offset = needDateStart
      ? getDateTimeStamp(firstDate) - getDateTimeStamp(secondDate)
      : firstDate - secondDate;
    result = offset / ONE_DAY;
  }

  return abs ? Math.abs(result) : result;
};

export const solarMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const getMonthDays = (timeStamp: number) => {
  const date = new Date(timeStamp);
  const year = date.getFullYear();
  const month = date.getMonth();

  if (month === 1) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28;
  }
  return solarMonth[month];
};

export const isWeekOrMonth = (level: ZoomLevel) => {
  return level === ZoomLevel.MONTH || level === ZoomLevel.WEEK || level === ZoomLevel.BIWEEK;
};

export const getUnitTime = (zoomLevel: ZoomLevel) => {
  if (zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR) {
    return ONE_MOMENT;
  }
  return ONE_DAY;
};

export const isDateTime = (zoomLevel: ZoomLevel, dateType?: TextType.DATETIME | TextType.DATE) => {
  return (
    dateType === TextType.DATETIME || zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR
  );
};

export const getContainerWidth = (zoomLevel: ZoomLevel, dates: number[]) => {
  const unitWidth = UnitWidthMap[zoomLevel];
  let width = dates.length * unitWidth;

  if (zoomLevel === ZoomLevel.YEAR) {
    const lastMonth = dates[dates.length - 1] as number;
    const lastMonthDate = getMonthLastDate(lastMonth);
    width = (getUnitLength(lastMonthDate, dates[0] as number, zoomLevel) + 1) * unitWidth;
  } else if (zoomLevel === ZoomLevel.QUARTER) {
    width = 7 * dates.length * unitWidth;
  } else if (zoomLevel === ZoomLevel.DAY) {
    width = 4 * dates.length * unitWidth;
  }

  return width;
};

export const getMonthLastDate = (date: number) => {
  return new Date(date).setMonth(new Date(date).getMonth() + 1, 0);
};

export const unitIsMoment = (zoomLevel: ZoomLevel) => {
  return zoomLevel === ZoomLevel.DAY || zoomLevel === ZoomLevel.HOUR;
};
