import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { UPDATE_BLOCK } from 'src/redux/actions';
import { useTransaction } from '../use-transaction';

export const useRemovePropertyOption = () => {
  const { getState, dispatch } = useStore();
  const transaction = useTransaction();

  const removePropertyOption = (collectionId: string, propertyId: string, optionId: string) => {
    const { blocks } = getState();
    const collection = blocks[collectionId];
    if (!collection) return;

    const { [propertyId]: property, ...restProperty } = collection.data.schema ?? {};

    if (!property) return;

    const newOptions = property.options?.filter((item) => item.id !== optionId);

    transaction(() => {
      dispatch(
        UPDATE_BLOCK({
          uuid: collectionId,
          patch: {
            data: {
              schema: {
                ...restProperty,
                [propertyId]: {
                  ...property,
                  options: newOptions,
                },
              },
            },
          },
        })
      );
    });
  };

  return useCallback(removePropertyOption, [dispatch, getState, transaction]);
};
