import { throttle } from 'lodash-es';
import type { ObservableDataValueType } from './map';
import { observableStoreOp } from './map';

// 清空没地方监听的 obs
export const globalRxTimer = {
  run: throttle(() => {
    const map = observableStoreOp.getMap();
    const clean = (item: ObservableDataValueType, key: string) => {
      const observed = item.subscribe.size;
      if (!observed) {
        observableStoreOp.deleteObs(key);
      }
    };
    map.normal.forEach(clean);
    map.select.forEach(clean);
  }, 3000),
};

// 清空没地方监听的 obs
// 暂时没用了
// export const globalRxTimer = {
//   run: debounce(() => {
//     const map = observableStoreOp.getMap();
//     map.values.forEach((_, key) => {
//       const count = map.countMap.get(key);
//       if (!count) {
//         map.values.delete(key);
//         map.countMap.delete(key);
//       }
//     });
//   }, 3000),
// };
