import { useMindMapSelector } from '@flowus/mind-map';
import { useEffect, useState } from 'react';
import { useFocused } from 'src/hooks/utils/use-focused';
import { setAppUiState, useIsEditBlock } from 'src/services/app';

/** 编辑状态下需要保持上一次的宽度（产品要求，跟飞书一致） */
export const useLastWidthBeforeEditing = (
  id: string,
  divRef: React.RefObject<HTMLElement>,
  isEmpty: boolean,
  level: number
) => {
  const [lastWidth, setLastWidth] = useState<number | undefined>(undefined);
  const isEditing = useIsEditBlock(id);
  const scale = useMindMapSelector((state) => {
    if (isEditing) return state.scale;
    return 1;
  });

  useFocused(divRef, (focused) => {
    if (focused && isEditing) return;
    setAppUiState({ $editingBlockId: focused ? id : undefined });
  });

  useEffect(() => {
    if (!isEditing) {
      setLastWidth(undefined);
      return;
    }
    const scaleFactor = scale * 0.01;
    const width = divRef.current?.getBoundingClientRect().width;
    width && setLastWidth(width / scaleFactor);
  }, [divRef, isEditing, scale]);

  if (lastWidth) {
    return lastWidth;
  }
  return isEmpty ? (level > 1 ? '100px' : '112px') : undefined;
};
