import { arrayMove } from '@dnd-kit/sortable';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC, KeyboardEvent, MouseEvent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Divider } from 'src/common/components/divider';
import { useOpenModal } from 'src/common/components/next-modal';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useCreatePropertyOption } from 'src/hooks/block/use-create-property-options';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useViewParentId } from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { pickRandomValue } from 'src/utils/pick-random-value';
import { v4 as uuidV4 } from 'uuid';
import { COLORS } from '../../../const';
import type { Option } from '../../types';
import { OptionMore } from './option-more';
import { Options } from './options';

interface OptionRenderProps {
  viewId: string;
  collectionId: string;
  propertyId: string;
  onClose?: () => void;
}

export const OptionRender: FC<OptionRenderProps> = ({
  viewId,
  collectionId,
  propertyId,
  onClose,
}) => {
  const collectionReadonly = useReadonly(collectionId);
  const { propertySchema: schema } = usePropertySchema(collectionId, propertyId);
  const updatePropertySchema = useUpdatePropertySchema();
  const createPropertyOption = useCreatePropertyOption();
  const openModal = useOpenModal();
  const viewParentId = useViewParentId(viewId) ?? collectionId;
  const isLocked = useBlockLocked(viewParentId);

  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const newOptionColor = useMemo(() => {
    const pool = COLORS.map((item) => item.key);
    return pickRandomValue(pool, schema?.options?.map((item) => item.color) ?? []) ?? COLORS[0].key;
  }, [schema?.options]);

  const handleCreateNewTag = () => {
    const id = uuidV4();
    const value = inputValue.replace(/,/g, '').trim();
    if (!value) return;
    if (schema?.options?.find((item) => item.value === value)) {
      openModal.warning({
        title: <>此属性已存在同名的选项</>,
        noCancel: true,
        confirm() {
          inputRef.current?.focus();
        },
      });
      return;
    }

    setInputValue('');
    createPropertyOption(collectionId, propertyId, {
      id,
      value,
      color: newOptionColor,
    });
  };

  const handleInputKeyDown = (event: KeyboardEvent) => {
    if (isLocked || collectionReadonly) return;
    if (isHotkey('Esc')(event)) {
      onClose?.();
      return;
    }
    if (isHotkey('Enter')(event)) {
      handleCreateNewTag();
    }
  };

  const closeBeforeCallbackRef = useRef<() => void | 'prevent'>();
  const showMore = (option: Option, event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'right',
      content({ onCloseModal }) {
        return (
          <OptionMore
            option={option}
            collectionId={collectionId}
            propertyId={propertyId}
            closeModal={onCloseModal}
            closeBeforeCallbackRef={closeBeforeCallbackRef}
          />
        );
      },
      closeBeforeCallBack: () => closeBeforeCallbackRef.current?.(),
      closeAfterCallBack() {
        inputRef.current?.focus();
      },
    });
  };

  const handleSort = (oldId: string, newId: string) => {
    if (!schema?.options) return;
    const oldIndex = schema.options.findIndex((item) => item.id === oldId);
    const newIndex = schema.options.findIndex((item) => item.id === newId);
    const sortedOptions = arrayMove(schema.options, oldIndex, newIndex);
    updatePropertySchema(collectionId, propertyId, { options: sortedOptions });
  };

  const handleFocus = () => {};

  const readonly = collectionReadonly || isLocked;

  return (
    <div className="next-modal-scroll max-h-80vh w-[240px] pt-1.5">
      <AutoHeightTextArea
        ref={inputRef}
        autoFocus
        singleLine
        placeholder="创建选项"
        fontClassName={css({ lineHeight: '24px', fontSize: 14, whiteSpace: 'nowrap' })}
        className={css({
          width: '100%',
          selectors: { '&::placeholder': { color: 'var(--grey4)' } },
        })}
        boxClassName="min-w-[120px] max-w-full flex-grow-1 flex-shrink-0 p-2"
        value={inputValue}
        onKeyDown={handleInputKeyDown}
        onChange={(event) => setInputValue(event.target.value)}
      />

      <Divider className="my-1" />

      <Options
        options={schema?.options ?? []}
        onSelect={() => {}}
        onMore={showMore}
        onSort={handleSort}
        onFocus={handleFocus}
        readonly={readonly}
      />
    </div>
  );
};
