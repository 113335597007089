import type { SegmentDTO } from '@next-space/fe-api-idl';
import { v4 } from 'uuid';
import { pickRandomValue } from 'src/utils/pick-random-value';
import { BlockType, CollectionSchemaType } from '@next-space/fe-api-idl';
import type { IContentItem, IEditorModel } from '@next-space/fe-inlined';
import { contentToString, newContent, newText, sliceContent } from '@next-space/fe-inlined';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { readFormatFromSegment } from 'src/editor/utils/segments';
import { updatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { transaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { AIEditorScene, AIEditType } from './const';
import { COLORS } from 'src/bitable/const';
import {
  ExcludePropertyType,
  getBitableContent,
  getBlockIdsContent,
} from './get-block-ids-content';
import { getEditTypeMapPrompt, translateEditType } from './menu-items';
import { isMindMap } from 'src/utils/block-type-utils';
import { convertTextForMindMap } from 'src/hooks/page/use-copy-listener/on-cut-copy/convert-text';
import { removeBlock } from 'src/redux/managers/block/remove';
import { checkMindMap } from './utils';

export const handleEditTypeEvent = (params: {
  pageId: string;
  blockId: string;
  prompt: string;
  tempPageId: React.MutableRefObject<string>;
  allLine: React.MutableRefObject<string[]>;
  archiveResult: React.MutableRefObject<string[]>;
  selectedText: React.MutableRefObject<string>;
  selectedBlockIds: React.MutableRefObject<string[]>;
  editTypeRef: React.MutableRefObject<AIEditType | undefined>;

  model?: IEditorModel;
  selection?: { start: number; end?: number };

  editType: AIEditType;
  scene: AIEditorScene;

  fetchAIResult: (body?: Record<string, string> | undefined) => Promise<void>;
  setPlaceHolder: (value: React.SetStateAction<string | undefined>) => void;
  setPrompt?: (value: React.SetStateAction<string | undefined>) => void;
  getSelectedContent: () => string;
  removeTempPage: () => void;
  closeSelf: () => void;
  getEditorModel: (blockId: string) => IEditorModel | undefined;

  isBitable?: boolean;
  isMindMapPage?: boolean;
  isDescription?: boolean;
}) => {
  const {
    pageId,
    blockId,
    tempPageId,
    allLine,
    archiveResult,
    selectedText,
    selectedBlockIds,
    editTypeRef,
    editType,
    scene,
    fetchAIResult,
    setPlaceHolder,
    getSelectedContent,
    removeTempPage,
    closeSelf,
    selection,
    model,
    isBitable,
    isMindMapPage,
    isDescription,
    prompt,
    setPrompt,
    getEditorModel,
  } = params;
  const lastEditType = editTypeRef.current;
  if (editType !== AIEditType.TryAgain) {
    editTypeRef.current = editType;
  }

  if (scene === AIEditorScene.PageEmpty) {
    setPlaceHolder(getEditTypeMapPrompt(editType)?.prompt);
    setPrompt?.(getEditTypeMapPrompt(editType)?.prompt);
    if (editType === AIEditType.Custom && prompt) {
      setPlaceHolder(prompt);
    }
    return;
  }

  const bitableContent = (() => {
    let content = '';

    if (
      isDescription &&
      (scene === AIEditorScene.TextSelected || scene === AIEditorScene.TextSelectedAndWroteByAI)
    ) {
      content = selectedText.current;
    } else {
      content = getBitableContent(blockId);
    }

    return content;
  })();

  const { blocks } = getState();
  const pageBlock = blocks[pageId];
  if (!pageBlock) return;

  switch (editType) {
    case AIEditType.Custom: {
      let content = '';
      const b = cache.blocks[blockId];
      if (isBitable) {
        content = bitableContent;
      } else if (b?.type === BlockType.MIND_MAPPING) {
        content = getBlockIdsContent([blockId]);
      } else {
        content =
          scene === AIEditorScene.PageHasContent
            ? getBlockIdsContent(pageBlock.subNodes)
            : selectedText.current;
      }
      void fetchAIResult({
        type: 'custom',
        selectedText: content,
      });
      break;
    }
    case AIEditType.ContinueWrite: {
      let previousText = '';
      let restText = '';

      if (scene === AIEditorScene.PageHasContent) {
        const model = getEditorModel(blockId);
        if (!model) return;
        if (isMindMap(cache.blocks[blockId]?.type)) {
          const content = convertTextForMindMap(blockId);
          previousText = content;
          // previousText = fixPrompt(content, AIEditType.MindMap_Outline);
        } else {
          const index = pageBlock.subNodes.indexOf(blockId); // TODO：分栏会有问题
          previousText =
            getBlockIdsContent(pageBlock.subNodes.slice(0, index)) +
            contentToString(sliceContent(model.content, 0, selection?.start ?? 0));
          restText =
            contentToString(
              sliceContent(model.content, selection?.start ?? 0, model.content.length)
            ) + getBlockIdsContent(pageBlock.subNodes.slice(index + 1));
        }
      }

      // 已经写了一部分内容
      if (
        scene === AIEditorScene.PageContentWroteByAI ||
        scene === AIEditorScene.BlockSelectedAndWroteByAI ||
        scene === AIEditorScene.TextSelectedAndWroteByAI
      ) {
        previousText = allLine.current.join('');
        if (!previousText.trim()) {
          // 如果上一次的返回结果为空，那么就用上上次的返回结果
          previousText = archiveResult.current.join('');
        }
      }

      if (scene === AIEditorScene.BlockSelected) {
        if (isBitable) {
          previousText = bitableContent;
        } else if (isMindMap(cache.blocks[blockId]?.type)) {
          const content = convertTextForMindMap(blockId);
          previousText = content;
        } else {
          const index = pageBlock.subNodes.indexOf(blockId); // TODO：分栏会有问题
          previousText = getBlockIdsContent(
            pageBlock.subNodes.slice(0, index + selectedBlockIds.current.length)
          );
          restText = getBlockIdsContent(
            pageBlock.subNodes.slice(index + selectedBlockIds.current.length)
          );
        }
      }

      if (scene === AIEditorScene.TextSelected) {
        if (isDescription) {
          const blockStr = segmentsToText(blocks[blockId]?.data.description);
          previousText = blockStr.slice(0, selection?.end ?? 0);
          restText = `${blockStr.slice(selection?.end ?? 0)}`;
        } else {
          const index = pageBlock.subNodes.indexOf(blockId); // TODO：分栏会有问题
          const blockStr = segmentsToText(blocks[blockId]?.data.segments);
          previousText =
            getBlockIdsContent(pageBlock.subNodes.slice(0, index)) +
            blockStr.slice(0, selection?.end ?? 0);
          restText = `${blockStr.slice(selection?.end ?? 0)}\n\n${getBlockIdsContent(
            pageBlock.subNodes.slice(index + 1)
          )}`;
        }
      }

      void fetchAIResult({
        type: 'continue',
        previousText,
        restText,
      });
      break;
    }
    case AIEditType.Summarize: {
      let content = '';
      const b = cache.blocks[blockId];
      if (isBitable) {
        content = bitableContent;
      } else if (b?.type === BlockType.MIND_MAPPING) {
        content = getBlockIdsContent([blockId]);
      } else {
        content =
          scene === AIEditorScene.PageHasContent
            ? getBlockIdsContent(pageBlock.subNodes)
            : selectedText.current;
      }
      void fetchAIResult({
        type: 'summarize',
        selectedText: content,
      });
      break;
    }
    case AIEditType.Improve: {
      // 改进 选中的块
      if (scene === AIEditorScene.BlockSelected || scene === AIEditorScene.TextSelected) {
        void fetchAIResult({
          type: 'improve',
          selectedText: selectedText.current,
        });
      }
      break;
    }
    case AIEditType.OtherLanguage: {
      setPlaceHolder('把当前语言翻译成：');
      break;
    }

    case AIEditType.Comment:
    case AIEditType.BugFix: {
      void fetchAIResult({
        type: editType,
        selectedText: getSelectedContent(),
      });
      break;
    }
    case AIEditType.CPlus:
    case AIEditType.Java:
    case AIEditType.TypeScript:
    case AIEditType.Python:
    case AIEditType.JavaScript: {
      void fetchAIResult({
        type: 'rewriting',
        language: editType,
        selectedText: getSelectedContent(),
      });
      break;
    }
    case AIEditType.OtherCodeLanguage: {
      setPlaceHolder('把当前代码改写成：');
      break;
    }

    case AIEditType.Summary:
    case AIEditType.Explain:
    case AIEditType.Chinese:
    case AIEditType.ClassicalChinese:
    case AIEditType.English:
    case AIEditType.Korean:
    case AIEditType.Japanese:
    case AIEditType.French:
    case AIEditType.German: {
      if (editType === AIEditType.Summary || editType === AIEditType.Explain) {
        void fetchAIResult({
          type: editType,
          selectedText: getSelectedContent(),
        });
      } else {
        void fetchAIResult({
          type: 'translate',
          language: editType,
          selectedText: getSelectedContent(),
        });
      }
      break;
    }
    case AIEditType.Simpler:
    case AIEditType.Longer:
    case AIEditType.Professional:
    case AIEditType.Casual:
    case AIEditType.Straightforward:
    case AIEditType.Confident:
    case AIEditType.Friendly: {
      void fetchAIResult({
        type: editType,
        selectedText: selectedText.current,
      });
      break;
    }
    case AIEditType.Close: {
      removeTempPage();
      closeSelf();
      break;
    }
    case AIEditType.Replace: {
      if (scene === AIEditorScene.BlockSelectedAndWroteByAI) {
        if (isBitable) {
          const { blocks } = cache;
          const collection = blocks[blockId];
          if (!collection) return;

          const tempPage = blocks[tempPageId.current];
          if (!tempPage) return;

          if (lastEditType && translateEditType.has(lastEditType)) {
            const newTableId = tempPage.subNodes.find((item) => {
              const block = blocks[item];
              return block?.type === BlockType.COLLECTION_VIEW;
            });

            if (newTableId) {
              const lines = getBitableContent(newTableId).split('\n');
              lines.splice(1, 1);

              const localKey = `viewId:${blockId}`;
              const viewId = localStorage.getItem(localKey)?.trim();
              const collectionView = cache.collectionViews[viewId ?? ''];

              const schemas = collection.data.schema ?? {};
              if (collectionView) {
                const properties = collectionView.format.tableProperties;
                if (properties) {
                  const titles = (lines[0] ?? '')
                    .split('|')
                    .filter((str) => !!str.trim())
                    .map((str) => str.trim());

                  const keyIndexMap: Record<string, number> = {};

                  transaction(() => {
                    properties.forEach((item, index) => {
                      const { property } = item;
                      keyIndexMap[property] = index;
                      updatePropertySchema(
                        blockId,
                        property,
                        { name: titles[index] ?? '' },
                        { needTransaction: false }
                      );
                    });

                    const titleIndex = keyIndexMap['title'];
                    collection.subNodes.forEach((uuid, index) => {
                      const block = blocks[uuid];
                      if (!block || block.hidden) return;

                      const line = lines[index + 1] ?? '';
                      const cells = line
                        .split('|')
                        .filter((str) => !!str.trim())
                        .map((str) => str.trim());

                      const collectionProperties = block.data.collectionProperties ?? {};
                      const newCollectionProperties: Record<string, SegmentDTO[]> = {
                        ...collectionProperties,
                      };
                      Object.keys(collectionProperties).forEach((key) => {
                        const schema = schemas[key];
                        if (!schema) return;
                        if (ExcludePropertyType.has(schema.type)) return;

                        const index = keyIndexMap[key];
                        if (typeof index === 'undefined') return;

                        let cellValue = cells[index];
                        if (!cellValue) return;

                        if (
                          schema.type === CollectionSchemaType.SELECT ||
                          schema.type === CollectionSchemaType.MULTI_SELECT
                        ) {
                          const cellValues = cellValue
                            .split(',')
                            .filter(Boolean)
                            .map((str) => str.trim());
                          cellValue = cellValues.join(',');

                          const collection = cache.blocks[blockId];
                          const options = collection?.data.schema?.[key]?.options ?? [];
                          const values = options.map((item) => item.value) ?? [];

                          const newOptions = cellValues
                            .filter((item) => !values.includes(item))
                            .map((val) => {
                              const pool = COLORS.map((item) => item.key);
                              const newOptionColor =
                                pickRandomValue(pool, options.map((item) => item.color) ?? []) ??
                                COLORS[0].key;

                              return {
                                id: v4(),
                                value: val,
                                color: newOptionColor,
                              };
                            });

                          updatePropertySchema(
                            blockId,
                            key,
                            { options: options.concat(newOptions) },
                            { needTransaction: false }
                          );
                        }

                        newCollectionProperties[key] = textToSegments(cellValue);
                      });

                      const titleSegment =
                        typeof titleIndex !== 'undefined'
                          ? textToSegments(cells[titleIndex] ?? '')
                          : block.data?.segments ?? undefined;

                      updateBlock(uuid, {
                        data: {
                          segments: titleSegment,
                          collectionProperties: newCollectionProperties,
                        },
                      });
                    });
                  });
                }
              }
            }

            removeTempPage();
            closeSelf();
            break;
          }

          const text = getBlockIdsContent(tempPage.subNodes);
          transaction(() => {
            updateBlock(blockId, {
              data: { description: textToSegments(text), isByAI: true },
            });
          });

          removeTempPage();
          closeSelf();
          break;
        } else if (lastEditType && translateEditType.has(lastEditType)) {
          // 如果是翻译root节点的话，就不能用插入后再删除这种骚操作了，只能直接替换
          if (isMindMapPage && blockId === pageId) {
            const tempPage = cache.blocks[tempPageId.current];
            if (!tempPage) return;
            // 翻译的块
            const targetId = tempPage.subNodes[0];
            if (!targetId) return;
            const targetBlock = cache.blocks[targetId];
            if (!targetBlock) return;
            transaction(() => {
              updateBlock(blockId, {
                data: targetBlock.data,
              });
            });
            closeSelf();
          }
        }

        transaction(() => {
          const selectedBlocks: SelectBlock[] = [];
          let lastBlockId = blockId;

          const tempPage = cache.blocks[tempPageId.current];
          if (!tempPage) return;

          tempPage.subNodes.forEach((uuid) => {
            const block = cache.blocks[uuid];
            if (!block) return;

            lastBlockId = addBlock(
              {
                type: block.type,
                data: block.data,
              },
              { parentId: pageId, after: lastBlockId }
            );

            if (block.subNodes.length > 0) {
              // 所有有子块的数据类型都需要重新创建
              let lastNodeId = '';
              block.subNodes.forEach((uuid) => {
                const nodeBlock = cache.blocks[uuid];
                if (!nodeBlock) return;

                lastNodeId = addBlock(
                  {
                    type: nodeBlock.type,
                    data: nodeBlock.data,
                  },
                  { parentId: lastBlockId, after: lastNodeId }
                );
              });
            }

            selectedBlocks.push({ blockId: lastBlockId });
          });

          selectedBlockIds.current.forEach((uuid) => removeBlock(uuid));

          removeTempPage();

          dispatch(uiActions.update({ selectedBlocks }));

          closeSelf();
        });
      }
      if (scene === AIEditorScene.TextSelectedAndWroteByAI) {
        if (!model) return;

        const tempPage = cache.blocks[tempPageId.current];
        if (!tempPage) return;

        const selectedBlocks: SelectBlock[] = [];
        let lastBlockId = blockId;
        tempPage.subNodes.forEach((uuid, index) => {
          const block = cache.blocks[uuid];
          if (!block) return;

          if (index === 0 && block.type === BlockType.TEXTAREA) {
            const arr: IContentItem[] = (block.data.segments ?? []).map((segment) => {
              const format = readFormatFromSegment(segment);

              try {
                // 对不合法的 unicode 字符串做容错处理
                return newText(segment.text, format);
              } catch {
                return newText('\uFFFD', format);
              }
            });

            model.performChange((ctx) => {
              ctx
                .shadow()
                .select(selection?.start ?? 0, selection?.end ?? 0)
                .replace(newContent(arr));
            });
            void model.requestFocus();
            return;
          }

          if (
            (block.type === BlockType.TEXTAREA && segmentsToText(block.data.segments) !== '') ||
            block.type !== BlockType.TEXTAREA
          ) {
            lastBlockId = addBlock(
              {
                type: block.type,
                data: block.data,
              },
              { parentId: pageId, after: lastBlockId }
            );

            if (block.subNodes.length > 0) {
              // 所有有子块的数据类型都需要重新创建
              let lastNodeId = '';
              block.subNodes.forEach((uuid) => {
                const nodeBlock = cache.blocks[uuid];
                if (!nodeBlock) return;

                lastNodeId = addBlock(
                  {
                    type: nodeBlock.type,
                    data: nodeBlock.data,
                  },
                  { parentId: lastBlockId, after: lastNodeId }
                );
              });
            }

            selectedBlocks.push({ blockId: lastBlockId });
          }
        });

        removeTempPage();

        if (selectedBlocks.length > 0) {
          dispatch(uiActions.update({ selectedBlocks }));
        }

        closeSelf();
      }
      break;
    }
    case AIEditType.Insert: {
      transaction(() => {
        let lastBlockId = blockId;
        if (!isBitable && scene === AIEditorScene.BlockSelectedAndWroteByAI) {
          if (!isMindMapPage) {
            const subNodes = cache.blocks[pageId]?.subNodes ?? [];
            let maxIndex = 0;
            selectedBlockIds.current.forEach((uuid) => {
              maxIndex = Math.max(subNodes.indexOf(uuid), maxIndex);
            });
            lastBlockId = subNodes[maxIndex] ?? blockId;
          }
        }

        const tempPage = cache.blocks[tempPageId.current];
        if (!tempPage) return;
        const parentId = isMindMapPage ? cache.blocks[lastBlockId]?.parentId : pageId;
        if (!parentId) return;
        const selectedBlocks: SelectBlock[] = [];

        // 如果是翻译，且当前选中的是内嵌导图块，则把第一个块转为思维导图
        if (
          tempPage.subNodes.length === 1 &&
          cache.ui.selectedBlocks.length === 1 &&
          lastEditType &&
          translateEditType.has(lastEditType)
        ) {
          const { isMindMapPage } = checkMindMap(cache.ui.selectedBlocks[0]?.blockId ?? '');
          const subId = tempPage.subNodes[0];
          if (isMindMapPage && subId) {
            updateBlock(
              subId,
              {
                type: BlockType.MIND_MAPPING,
              },
              true
            );
          }
        }

        const loop = (id: string, parentId: string, after?: string) => {
          const b = cache.blocks[id];
          if (!b) return '';
          const lastBlockId = addBlock(
            {
              type: b.type,
              data: b.data,
            },
            {
              parentId,
              after,
            }
          );
          selectedBlocks.push({ blockId: lastBlockId });
          let lastId: string | undefined = undefined;
          b.subNodes.forEach((cId) => {
            lastId = loop(cId, lastBlockId, lastId);
          });
          return lastBlockId;
        };
        tempPage.subNodes.forEach((uuid) => {
          lastBlockId = loop(uuid, parentId, lastBlockId);
        });

        removeTempPage();
        dispatch(uiActions.update({ selectedBlocks }));
        closeSelf();
      });

      break;
    }
    case AIEditType.InsertToBitableDesc: {
      transaction(() => {
        const { blocks } = cache;

        const collection = blocks[blockId];
        if (!collection) return;

        const desc = collection.data.description ?? [];
        const tempPage = blocks[tempPageId.current];
        if (!tempPage) return;

        const text = getBlockIdsContent(tempPage.subNodes);
        updateBlock(blockId, {
          data: { description: [...desc, ...textToSegments(text)], isByAI: true },
        });

        removeTempPage();
        closeSelf();
      });
      break;
    }
    case AIEditType.Discard: {
      removeTempPage();
      closeSelf();
      break;
    }
    case AIEditType.TryAgain: {
      removeTempPage();
      if (editTypeRef.current) {
        handleEditTypeEvent({ ...params, editType: editTypeRef.current });
      }
      break;
    }
    default:
      break;
  }
};
