import { cx } from '@flowus/common/cx';
import { DEFAULT_FONT_FAMILY } from '@flowus/common/style';
import { fetchKnowledgeInfo } from '@flowus/common/subscribe';
import type { KnowLedgeInfo } from '@flowus/common/subscribe/type';
import { useEffect, useState, type FC } from 'react';
import { promotionChannel, publicLink } from 'src/common/const';
import { PRODUCT_ALL_NAME } from 'src/const/title';
import { usePageMeta } from 'src/hooks/page/use-page-meta';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { useIsOwner } from 'src/hooks/share/use-owner';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useReportIllegal } from 'src/hooks/share/use-report-illegal';
import { useIsShowAd } from 'src/hooks/space/use-is-pro-space';
import { useFileIllegal } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { isEmbedTemplate, judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Footer: FC<{ logo?: boolean; className?: string; addSpace?: boolean }> = (props) => {
  const pageId = useGetPageId();

  const isOwner = useIsOwner();
  const block = usePickBlock(pageId, []);
  const pageMeta = usePageMeta(pageId);
  const isShowAd = useIsShowAd(block?.spaceId);
  // const isProSpace = useIsProSpace(block?.spaceId);
  const fileIllegal = useFileIllegal();
  const pageScene = usePageScene();
  // 被风控了
  const { illegal } = usePermissions(pageId);
  // 特殊空间
  const officialSpaceShare = useOfficialSpaceShare(pageId);
  const isHiddenReport =
    isOwner ||
    pageMeta?.tag === 'NO_PERMISSION' ||
    pageMeta?.tag === 'NOT_FOUND' ||
    fileIllegal ||
    officialSpaceShare;
  if (!judgeSharePage() || officialSpaceShare || pageScene === PageScene.FORM_ENG_PAGE) {
    return <div className="footer h-[15vh] flex-shrink-0" />;
  }

  if (isEmbedTemplate) return null;

  const showAd = !illegal && isShowAd;

  const showReport = !isHiddenReport;

  if (!showAd && !showReport) return <div className="mt-20" />;

  if ($searchParams.blog) return null;

  if (__PRIVATE__) return null;

  return <FooterWrapper {...props} showReport showAd={showAd} />;
};

const FooterWrapper: FC<{
  className?: string;
  addSpace?: boolean;
  showReport: boolean;
  showAd: boolean;
}> = (props) => {
  const { showAd } = props;
  const report = useReportIllegal();
  const [info, setInfo] = useState<KnowLedgeInfo[]>();
  useEffect(() => {
    // 偷个懒
    const collectionId =
      __HOST_STAGING__ || __HOST_PRODUCTION__
        ? '6c5b0932-dcf8-42f1-95e4-bfa1ed94f5c4'
        : '0e8ebc84-ba5c-4606-898f-f06d64e8f602';
    void fetchKnowledgeInfo(collectionId).then((res) => {
      setInfo(res);
    });
    if (Math.random() < 0.01) {
      bizTracker.event('bottom_ad_show');
    }
  }, []);

  return (
    <footer
      className={cx(
        'footer flex flex-col items-center w-full max-w-[1420px] mx-auto flex-shrink-0 bg-white1 mt-20 line-default justify-center border-t',
        props.className
      )}
      style={{
        fontFamily: DEFAULT_FONT_FAMILY,
      }}
    >
      <div className="flex w-full max-w-4xl flex-col items-center justify-center px-10">
        {showAd && info && info?.length > 0 && (
          <>
            <Swiper
              loop
              autoplay={{ delay: 8000 }}
              slidesPerView="auto"
              watchSlidesProgress
              modules={[Autoplay]}
              className="max-w-[960px] mt-2.5"
            >
              {info?.map((v, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    className="flex items-center justify-center transform-gpu"
                  >
                    <a
                      href={v.link}
                      onClick={() => {
                        bizTracker.event('web_bottom_bar');
                      }}
                    >
                      {v.image.startsWith('http') ? (
                        <img
                          className="inline h-full max-h-[150px] w-full rounded-[10px]"
                          src={v.image}
                          alt={promotionChannel}
                        />
                      ) : (
                        <OssImage
                          blockId={v.id}
                          ossName={v.image}
                          className="inline h-full max-h-[150px] w-full rounded-[10px]"
                        />
                      )}
                      {/* <img
                        className="inline h-full max-h-[150px] w-full rounded-[10px]"
                        src={`${ossImagesPath}/footer/footer-${item}.png`}
                        alt={promotionChannel}
                      /> */}
                    </a>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
        <div
          className={cx(
            'text-t3 text-grey3 my-5 flex justify-end w-full',
            showAd && 'justify-between'
          )}
        >
          <div hidden={!showAd}>
            本页面由
            <a href={publicLink.learnMore} className="mx-1 text-black">
              {PRODUCT_ALL_NAME}
            </a>
            提供技术支持
          </div>
          <div>
            {props.showReport && (
              <div className="flex whitespace-nowrap flex-shrink-0">
                如内容涉及违法违规或侵权，点击
                <div className="ml-0.5 animate-click underline underline-offset-3" onClick={report}>
                  投诉/举报
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.addSpace && <div className="h-[120px] w-full"></div>}
    </footer>
  );
};

interface Props {
  blockId: string;
  ossName: string;
  className?: string;
}
const OssImage: FC<Props> = (props) => {
  const { blockId, ossName, className } = props;
  const [url, setUrl] = useState('');
  useEffect(() => {
    void urlFetcher
      .fetchImageUrl({
        blockId,
        ossName,
      })
      .then((res) => {
        setUrl(res);
      });
  }, [blockId, ossName]);
  if (!url) return null;

  return <img src={url} className={className}></img>;
};
