import { cx } from '@flowus/common/cx';
import type { SegmentDTO, UserDTO } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Divider } from 'src/common/components/divider';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { buildPersonSegment } from 'src/editor/utils/segments';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useReadonly } from 'src/hooks/page';
import { useGetAllSpaceUsers, useUsers } from 'src/hooks/page/use-subscription-data';
import { searchUsers } from 'src/utils/search-util';
import { useCellEditor } from './hooks';
import { copyNormalizedValue, PersonTag, PersonValueView } from './person';
import type { CellEditorProps } from './types';

export const PersonEditor: FC<CellEditorProps> = ({
  recordIds,
  recordId,
  propertyId,
  onUpdate,
  onClose,
}) => {
  const readonly = useReadonly(recordId, false);
  const value = usePropertySegments(recordId, propertyId);

  useGetAllSpaceUsers();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [search, setSearch] = useState('');
  const updatePropertyValue = useUpdatePropertyValue();

  useCellEditor({
    onUpdate,
    onClose,
    onSave: () => {},
  });

  const saveValue = (value: SegmentDTO[] | undefined) => {
    updatePropertyValue(recordIds ?? recordId, propertyId, value);
  };

  const addPerson = (uuid: string) => {
    const newValue = copyNormalizedValue(value);
    if (newValue.findIndex((it) => it.uuid === uuid) === -1) {
      newValue.push(buildPersonSegment(uuid));
      saveValue(newValue);
    }
  };

  const deleteLastPerson = () => {
    const newValue = copyNormalizedValue(value);
    if (newValue.length > 0) {
      newValue.pop();
      saveValue(newValue);
    }
  };

  const deletePerson = (uuid: string) => {
    const newValue = copyNormalizedValue(value);
    const index = newValue.findIndex((it) => it.uuid === uuid);
    if (index >= 0) {
      newValue.splice(index, 1);
      saveValue(newValue);
      inputRef.current?.focus();
    }
  };

  const handleSelectPerson = (uuid: string) => {
    setSearch('');
    addPerson(uuid);
    inputRef.current?.focus();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (isHotkey('Esc')(event)) {
      onClose();
      return;
    }

    if (!search && isHotkey('Backspace')(event)) {
      deleteLastPerson();
    }
  };

  const users = useUsers();

  return (
    <>
      <div className="flex min-h-[42px] min-w-[260px] max-w-[480px] flex-wrap items-center px-2">
        {copyNormalizedValue(value).map((it) => {
          return (
            <PersonTag
              key={it.uuid}
              userId={it.uuid ?? ''}
              showClose
              onClose={() => {
                it.uuid && deletePerson(it.uuid);
              }}
            />
          );
        })}
        {!readonly && (
          <AutoHeightTextArea
            ref={inputRef}
            autoFocus
            singleLine
            placeholder="搜索成员"
            fontClassName={css({ lineHeight: '24px', fontSize: 14, whiteSpace: 'nowrap' })}
            className={css({
              width: '100%',
              selectors: { '&::placeholder': { color: 'var(--grey4)' } },
            })}
            boxClassName={css({
              flexBasis: 'auto',
              flexGrow: 1,
              flexShrink: 0,
              minWidth: 120,
              maxWidth: '100%',
            })}
            value={search}
            onKeyDown={handleInputKeyDown}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        )}
      </div>
      <Divider />
      <PersonList
        className="py-1.5"
        search={search}
        users={users}
        onSelectPerson={handleSelectPerson}
      />
    </>
  );
};

export const PersonList: FC<{
  className?: string;
  value?: string;
  search: string;
  users: UserDTO[];
  onSelectPerson?: (uuid: string) => void;
  customHeader?: React.ReactNode;
}> = ({ className, users, value, search, onSelectPerson, customHeader }) => {
  const items = useMemo(() => {
    return searchUsers(users, search).map((user) => {
      return {
        type: ListItemType.OPERATION,
        data: {
          content: <PersonValueView className="!px-0" userId={user.uuid} />,
          value: user.uuid,
          onClick: () => {
            onSelectPerson?.(user.uuid);
          },
        },
      };
    });
  }, [onSelectPerson, search, users]);

  return (
    <ListView
      customHeader={customHeader}
      customFooter={
        <>
          {items.length <= 0 && (
            <div className="text-t2 relative flex h-10 items-center justify-center text-grey4">
              没有找到相关结果
            </div>
          )}
        </>
      }
      className={cx('text-t2 max-h-[50vh] overflow-y-auto', className)}
      defaultActiveIndex={items.findIndex((item) => item.data.value === value)}
      items={items}
    />
  );
};
