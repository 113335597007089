import type { FC } from 'react';
import { PreviewPageContent } from 'src/components/preview-page-content';
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { PageScene } from 'src/views/main/scene-context';

interface Props {
  id: string;
  preview?: boolean;
}
export const ChartPreview: FC<Props> = (props) => {
  const collectionId = useObservableBlock(props.id, (s) => s?.data.format?.chartFormat?.ref?.uuid);
  if (!collectionId) {
    return (
      <div className="w-full h-full flex items-center justify-center text-grey4">请选择数据源</div>
    );
  }
  return (
    <PreviewPageContent
      docId={props.id}
      pageScene={props.preview ? PageScene.PAGE_CHART : PageScene.MAIN}
    />
  );
};
