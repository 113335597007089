import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import { Editor, sliceContent } from '@next-space/fe-inlined';
import isHotkey from 'is-hotkey';
import { throttle } from 'lodash-es';
import { css } from 'otion';
import type { FC } from 'react';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request';
import { IconTrigger } from 'src/components/icon-trigger';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import {
  deleteEditorModel,
  setEditorModel,
  useEditorModelKey,
} from 'src/editor/editor/uikit/editable/helper';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { segmentsToText } from 'src/editor/utils/editor';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useEditorInput, useEditorKeydown } from 'src/hooks/editor';
import { INLINE_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { getCaretInfo } from 'src/hooks/editor/helper';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { getCurrentSpace, useCurrentSpace, useCurrentSpaceView } from 'src/hooks/space';
import type { Template } from 'src/hooks/template/types';
import { getTemplateData } from 'src/hooks/template/use-fetch-templates';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { getAllEditorInfoFromDomContent } from 'src/utils/block-utils';
import { focusEditable } from 'src/utils/editor';
import { focusTracker, useTrackFocus } from 'src/utils/focus-tracker';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { useIsInRight } from 'src/utils/right-utils';
import {
  SYNCED_REVISION_REF_SYMBOL,
  SYNCED_SEGMENTS_REF_SYMBOL,
  syncSegmentsToEditorModel,
} from 'src/utils/sync-segments-to-editor-model';
import { RecommendTemplate } from './recommend-template';
import { isBuildIn } from 'src/env';
import { NoBindIdContext } from 'src/editor/editor/raw/no-bind-id-context';

export const PageTitle: FC<{ uuid: string }> = memo(({ uuid }) => {
  const isPrint = $searchParams.print;
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const updateTask = useUpdateTask();
  const block = usePickBlock(uuid, ['local', 'data']);
  const blockType = block?.type;
  const { data, local } = block || {};
  const editorModelKey = useEditorModelKey(uuid);
  const editorModel = getOrInitEditorModel(uuid, true, TITLE_EDITOR_PLUGINS);
  const isInRight = useIsInRight();
  const spaceView = useCurrentSpaceView();
  const { planType } = useCurrentSpace();
  const openPage = useOpenPage();
  const syncedSegmentsRef = useRef<SegmentDTO[] | undefined>();
  const syncedRevisionRef = useRef(NaN);
  const canBindId = useContext(NoBindIdContext);

  const transaction = useTransaction();
  const updateSegments = useThrottleUpdateSegments(uuid);
  const readonly = useReadonly(uuid);
  const editorInput = useEditorInput(uuid, 'segments', INLINE_KEYDOWN_OPTION);
  const editorKeydown = useEditorKeydown(uuid, 'segments', INLINE_KEYDOWN_OPTION);
  const isLinkTable =
    blockType === BlockType.REFERENCE_COLLECTION ||
    blockType === BlockType.REFERENCE_COLLECTION_PAGE;
  const isDrive = blockType === BlockType.FOLDER;
  const isBitable =
    blockType === BlockType.COLLECTION_VIEW_PAGE ||
    blockType === BlockType.COLLECTION_VIEW ||
    blockType === BlockType.REFERENCE_COLLECTION ||
    blockType === BlockType.REFERENCE_COLLECTION_PAGE;
  const collectionHasIcon = useObservableBlock(data?.ref?.uuid, (collection) => {
    if (
      blockType === BlockType.REFERENCE_COLLECTION ||
      blockType === BlockType.REFERENCE_COLLECTION_PAGE
    ) {
      if (!collection) return false;
      return Boolean(collection.data.icon?.value?.trim() && collection.data.icon.type?.trim());
    }
    return false;
  });
  const focusEditableAt = useFocusEditableByBlockId();

  const isGuest = useIsGuest();
  const closeTemplateRecommend =
    (planType !== 'personal' && planType !== 'freePersonal') ||
    isGuest ||
    !!spaceView?.setting.closeTemplateRecommend;
  const hasSubNodes = useObservableBlock(uuid, (block) => (block?.subNodes ?? []).length > 0);
  const [searchResult, setSearchResult] = useState<Template[]>([]);
  const isNewPage = cache.blocks[uuid]?.createdAt === cache.blocks[uuid]?.updatedAt;
  const [disableRecommend, setDisableRecommend] = useState<boolean>(
    !(local || isNewPage) || isDrive
  );

  useEffect(() => {
    if (hasSubNodes) {
      setDisableRecommend(true);
      setSearchResult([]);
    }
  }, [hasSubNodes]);

  useEffect(() => {
    editorModel.setReadonly(readonly || isLinkTable);
  }, [editorModel, isLinkTable, readonly]);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;
    (element as any).addEventListener('inlinedinput', editorInput);
    return () => {
      (element as any).removeEventListener('inlinedinput', editorInput);
    };
  }, [editorInput]);
  // 注册/注销 editorModel
  useEffect(() => {
    (editorModel as any)[SYNCED_SEGMENTS_REF_SYMBOL] = syncedSegmentsRef;
    (editorModel as any)[SYNCED_REVISION_REF_SYMBOL] = syncedRevisionRef;
    setEditorModel(editorModelKey, editorModel);
    return () => {
      deleteEditorModel(editorModelKey);
    };
  }, [editorModelKey, editorModel]);

  useTrackFocus(editorModelKey, editorModel);

  useEffect(() => {
    if (focusTracker.activeEditorKey === editorModelKey) {
      void editorModel.requestFocus();
    }
  }, [editorModelKey, editorModel]);

  // 更新 segment 给 Editor
  useEffect(() => {
    let segments = data?.segments;
    if (
      blockType === BlockType.REFERENCE_COLLECTION ||
      blockType === BlockType.REFERENCE_COLLECTION_PAGE
    ) {
      segments = getState().blocks[data?.ref?.uuid ?? '']?.data.segments;
    }
    syncSegmentsToEditorModel(editorModel, segments);
  }, [uuid, editorModel, data?.segments, data?.ref?.uuid, blockType]);

  useEffect(() => {
    let segments = data?.segments;
    if (
      blockType === BlockType.REFERENCE_COLLECTION ||
      blockType === BlockType.REFERENCE_COLLECTION_PAGE
    ) {
      segments = getState().blocks[data?.ref?.uuid ?? '']?.data.segments;
    }

    if (!segments || segments.length === 0) {
      void editorModel.requestFocus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    throttle(async (segments: SegmentDTO[]) => {
      if (isBuildIn()) return;
      const title = segmentsToText(segments);
      if (!title) {
        setSearchResult([]);
        return;
      }
      const { uuid: spaceId } = getCurrentSpace();
      const res = await request.editor.searchTemplates(title, spaceId, 'docTitle');
      const { recommendResult, recordMap, searchResult, hasSearchResult } = res;
      dispatch(blocksActions.update({ blocks: recordMap.blocks as Record<string, NextBlock> }));

      const { my = [], official = [] } = searchResult ?? {};
      const ids = hasSearchResult ? [...new Set(my.concat(official))] : recommendResult ?? [];
      const templates = ids
        .map((uuid) => getTemplateData(uuid))
        .filter((item): item is Template => !!item);

      setSearchResult(templates);
    }, 600),
    []
  );

  // 监听 Editor Change, 同步到数据层
  useEffect(() => {
    if (readonly) return;
    const subscription = editorModel.onChange.subscribe(
      async ({ previousContent, previousSelection }) => {
        const { content } = editorModel;
        if (content === previousContent) return;
        const segments = convertContentToSegments(content);

        if (syncedRevisionRef.current !== editorModel.revision) {
          syncedSegmentsRef.current = segments;
          updateSegments(segments, [previousContent, previousSelection]);
          syncedRevisionRef.current = editorModel.revision;
        }

        if (!closeTemplateRecommend && !disableRecommend && !__PRIVATE__) {
          void search(segments);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [editorModel, updateSegments, readonly, disableRecommend, closeTemplateRecommend, search]);

  useEffect(() => {
    if (!disableRecommend && !closeTemplateRecommend && blockType === BlockType.PAGE) {
      const pageNode = containerRef.current?.closest('.next-space-page') as HTMLElement | null;
      if (!pageNode) return;
      pageNode.style.paddingBottom = '400px';
    }
  }, [blockType, closeTemplateRecommend, disableRecommend]);

  const editorContent = useMemo(
    () => (
      <Editor
        className={cx('break-words', css({ outline: '0 !important' }))}
        emptyClassName={css(
          isPrint
            ? {}
            : {
                selectors: {
                  '&::after': {
                    content: JSON.stringify(getUntitledName(blockType)),
                    pointerEvents: 'none',
                    color: 'var(--grey5)',
                  },
                  '& > br': {
                    display: 'none',
                  },
                },
              }
        )}
        ref={containerRef}
        model={editorModel}
        spellCheck={false}
        onKeyDown={(event) => {
          if (
            readonly ||
            isBitable ||
            isDrive ||
            isLinkTable ||
            isHotkey(['ArrowDown', 'ArrowRight', 'Enter', 'Shift+Enter', 'mod+a'])(event)
          ) {
            return;
          }
          editorKeydown(event);
        }}
      />
    ),
    [blockType, editorKeydown, editorModel, isBitable, isDrive, isLinkTable, isPrint, readonly]
  );

  if (!data) return null;

  const scrollContainer =
    (containerRef.current?.closest('.next-space-page') as HTMLElement | null) || document.body;

  return (
    <header
      {...bindDataTestId(TestIds.DocTitle)}
      data-block-id={canBindId ? uuid : undefined}
      data-disable-select
      className={cx(
        // relative 不要去掉，用来给jumpTop的高亮定位的
        'relative text-h0 whitespace-pre-wrap',
        isLinkTable && 'flex items-center'
      )}
      onKeyDown={(event) => {
        if (isHotkey('mod+a')(event)) {
          event.stopPropagation();
          return;
        }

        if (isHotkey(['Enter', 'Shift+Enter'])(event)) {
          checkIsMaximumCapacityDialog();
          const model = getEditorModelByEditorKey(editorModelKey);
          if (!model) return;
          const { selection } = model;

          if (!selection?.isCollapsed) return;

          if (event.shiftKey) {
            editorModel.performChange((ctx) => {
              ctx.delete().insert('\n');
            });
            return;
          }

          // 多维表页面标题回车不要新增块
          if (isBitable || isDrive) {
            return;
          }

          const { offset } = selection;
          const beforeSegments = convertContentToSegments(sliceContent(model.content, 0, offset));
          const afterSegments = convertContentToSegments(
            sliceContent(model.content, offset, model.content.length)
          );

          // 积分任务
          const blockLen = cache.blocks[uuid]?.subNodes.length;
          if (blockLen === 0 || !blockLen) {
            void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList);
          }

          transaction(() => {
            updateBlock(uuid, {
              data: { segments: beforeSegments },
            });

            const newId = addBlock(
              { data: { segments: afterSegments } },
              { parentId: uuid, first: true }
            );

            dispatch(
              HISTORY_EFFECTS({
                init() {
                  focusEditableAt(newId, 0);
                },
                undo() {
                  focusEditableAt(uuid, offset);
                },
                redo() {
                  focusEditableAt(newId, 0);
                },
              })
            );
          });
          return;
        }

        if (isBitable || isDrive) {
          return;
        }

        if (isHotkey('ArrowRight')(event)) {
          const caret = getCaretInfo(uuid, editorModel);
          if (!caret?.collapsed || !caret.isLastChar) return false;

          const [editable] = getAllEditorInfoFromDomContent(isInRight);
          if (editable) {
            focusEditableAt(editable.blockId, 0);
            return;
          }

          transaction(() => {
            const newId = addBlock({}, { parentId: uuid, first: true });
            dispatch(
              HISTORY_EFFECTS({
                init() {
                  focusEditableAt(newId, 0);
                },
                undo() {
                  focusEditableAt(uuid, 0);
                },
                redo() {
                  focusEditableAt(newId, 0);
                },
              })
            );
          });
        }

        if (isHotkey('ArrowDown')(event)) {
          const caret = getCaretInfo(uuid, editorModel);

          if (!caret?.collapsed || !caret.isLastLine) return false;

          const [editable] = getAllEditorInfoFromDomContent(isInRight);
          if (editable) {
            focusEditable(editable.editorKey, caret.caretRect.x, 0);
            // return;
          }
          // 似乎是没用了，这个时间会被list view吃掉
          // transaction(() => {
          //   const newId = addBlock({}, { parentId: uuid, first: true });
          //   dispatch(
          //     HISTORY_EFFECTS({
          //       init() {
          //         focusEditableAt(newId, 0);
          //       },
          //       undo() {
          //         focusEditableAt(uuid, 0);
          //       },
          //       redo() {
          //         focusEditableAt(newId, 0);
          //       },
          //     })
          //   );
          // });
        }
      }}
    >
      {isLinkTable && (
        <Tooltip
          popup="点击访问原始多维表"
          className="animate-hover"
          onClick={(event) => {
            if (data?.ref?.uuid) {
              openPage(data?.ref?.uuid, {
                forceOpenInRight: event.altKey,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              });
            }
          }}
        >
          <IconTrigger
            trigger={false}
            className="mr-1"
            tooltipClassName="flex-shrink-0"
            hasOverlay={collectionHasIcon}
            blockId={data?.ref?.uuid ?? ''}
            offset={[100, 0]}
            defaultIcon={<Icon size="normal" name="IcPagelink" />}
          />
        </Tooltip>
      )}

      {/* {isInRight ? (
        <EditableNamespace.Provider value={UUID_NAMESPACE.RIGHT_PAGE}>
          {editorContent}
        </EditableNamespace.Provider>
      ) : (
        editorContent
      )} */}
      {editorContent}

      {searchResult.length > 0 &&
        !disableRecommend &&
        !closeTemplateRecommend &&
        createPortal(
          <RecommendTemplate
            targetId={uuid}
            searchResult={searchResult}
            setDisableRecommend={setDisableRecommend}
            keyword={segmentsToText(data?.segments)}
          />,
          scrollContainer
        )}
    </header>
  );
});
