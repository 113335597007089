import type { IContent, IEditorModel, ISelection } from '@next-space/fe-inlined';
import { useEffect } from 'react';
import { useFinalValue } from '@flowus/common/hooks/react-utils';

export function useUndo(model: IEditorModel, maxCount = 20) {
  const undoMan = useFinalValue(() => {
    let undoStack: [IContent, ISelection | null][] = [];
    let redoStack: [IContent, ISelection | null][] = [];
    const push = (content: IContent, selection: ISelection | null) => {
      if (maxCount === 0) return;
      undoStack.push([content, selection]);
      undoStack = undoStack.slice(-maxCount);
      redoStack = [];
    };
    const undo = () => {
      if (maxCount === 0) return;
      model.performChange((ctx) => {
        const nextState = undoStack.pop();
        if (nextState) {
          const state: [IContent, ISelection | null] = [model.content, model.selection];
          const [content, selection] = nextState;
          ctx.load(content);
          if (selection) {
            ctx.select(selection.offset, selection.endOffset, selection.isBackward);
          }
          redoStack.push(state);
        }
      }, 'undo');
    };
    const redo = () => {
      if (maxCount === 0) return;
      model.performChange((ctx) => {
        const nextState = redoStack.pop();
        if (nextState) {
          const state: [IContent, ISelection | null] = [model.content, model.selection];
          const [content, selection] = nextState;
          ctx.load(content);
          if (selection) {
            ctx.select(selection.offset, selection.endOffset, selection.isBackward);
          }
          undoStack.push(state);
        }
      }, 'undo');
    };
    return { push, undo, redo };
  });
  useEffect(() => {
    let changing = false;
    let timer = 0;
    const subscription = model.onChange.subscribe(({ key, previousContent, previousSelection }) => {
      if (key === 'undo') return;
      if (!changing) {
        undoMan.push(previousContent, previousSelection);
      }
      changing = true;
      window.clearTimeout(timer);
      timer = window.setTimeout(() => {
        changing = false;
      }, 500);
    });
    return () => subscription.unsubscribe();
  }, [model, undoMan]);
  return {
    undo: undoMan.undo,
    redo: undoMan.redo,
  };
}
