import type { SegmentDTO } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { segmentsToText } from '../utils/editor';

export const InputContent: FC<{
  content: SegmentDTO[] | string;
  onTextChange: (text: string) => void;
  onEnter: (e: React.KeyboardEvent) => void;
  tip?: string;
}> = (props) => {
  const { content } = props;
  const ref = useRef<HTMLTextAreaElement>(null);
  const value = useMemo(() => {
    if (typeof content === 'string') return content;
    return segmentsToText(content);
  }, [content]);

  useEffect(() => {
    ref.current?.select();
  }, []);

  return (
    <div className="w-[524px] px-5 py-5 next-modal">
      <textarea
        ref={ref}
        // autoFocus
        className={
          'text-t1 whitespace-pre-wrap h-[112px] w-full bg-transparent outline-none resize-none'
        }
        defaultValue={value}
        onChange={(e) => {
          props.onTextChange(e.target.value);
        }}
        onKeyDown={(e) => {
          if (isHotkey('Enter')(e)) {
            props.onEnter(e);
          }
        }}
      />
      {props.tip && (
        <div className="flex items-center justify-end mt-1">
          <Icon name="IcShareDanger" size="small" />
          <span className="text-t4 text-grey4">{props.tip}</span>
        </div>
      )}
    </div>
  );
};
