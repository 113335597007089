import { Deferred } from '../async';

export const enum SessionKeys {
  /** 密码网页 */
  PASSWORD = 'psw',
  /** 邀请人，全局可用 */
  INVITE_CODE = 'invite-code',
  /** 分享人 */
  SHARE_INVITE_CODE = 'share-invite-code',
  /** 来自哪个分享页面 */
  SHARE_INVITE_PAGE_ID = 'share-invite-page-id',
  /** 活动-创建空间 */
  FROM_CREATE_SPACE = 'from-create-space',
  /** 微信支付回调 */
  WECHAT_PAY_INFO = 'wechat-pay-info',
}

export const getSessionStorage = (id: SessionKeys) => {
  const result = sessionStorage.getItem(id);
  if (result === null) return;
  return result;
};

export const setSessionStorage = (id: SessionKeys, value: unknown) => {
  sessionStorage.setItem(id, `${value}`);
};

export const removeSessionStorage = (id: SessionKeys) => {
  sessionStorage.removeItem(id);
};

export const cleanupIndexedDb = async () => {
  const defer = new Deferred();
  void indexedDB.databases().then((databases) => {
    databases.forEach((database) => {
      if (database.name) {
        indexedDB.deleteDatabase(database.name);
      }
    });
    defer.resolve();
  });
  return defer.promise;
};

export const getSessionOp = {
  inviteCode: {
    get: () => getSessionStorage(SessionKeys.INVITE_CODE),
    set: (code: string) => setSessionStorage(SessionKeys.INVITE_CODE, code),
  },
};
