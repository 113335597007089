import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { cache } from 'src/redux/store';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { getDynamicPageId, useGetPageId } from 'src/utils/getPageId';

export const useOpenUnSyncDialog = () => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  return useCallback(
    (uuid: string, confirm: () => void) => {
      const block = cache.blocks[uuid];
      if (!block) return;
      const isRight = pageId === getDynamicPageId('right');
      const node = querySelectorFromMainContent(`[data-block-id="${uuid}"]`, isRight);
      if (node instanceof HTMLElement) {
        openModal.warning({
          title: <div className="w-[338px]">确认取消同步?</div>,
          content:
            block.type === BlockType.SYNC_CONTAINER
              ? `取消后,其他${node.dataset.syncCount}个页面的同步块将无法再同步内容`
              : ' ',
          confirmText: '取消同步',
          cancelText: '我再想想',
          confirm,
        });
      }
    },
    [openModal, pageId]
  );
};

/** 只有源同步块才需要弹 */
export const useOpenDeleteSyncBlockDialog = () => {
  const openModal = useOpenModal();
  return useCallback(
    (onlyOne: boolean, confirm: () => void) => {
      const text = onlyOne ? '1' : '多';
      const content = `当前同步块有${text}个页面正在同步,删除后其他同步块也将一并删除`;
      openModal.warning({
        title: <div className="w-[338px]">确定删除吗?</div>,
        content,
        confirmText: '全部删除',
        cancelText: '取消',
        confirm,
      });
    },
    [openModal]
  );
};
