import type { BlockDTO, CollectionViewDTO } from '@next-space/fe-api-idl';
import type { SubscribeInfo, SubscribeCategory } from './type';
import { allBlocks } from './allBlocks';
import { getPropertyMapping, getPropertyValue, segmentsToText } from './util';
import { requestFromNet } from './request-from-net';

//下面是表头对应的字段。
const SUBSCRIBE_NAME = '页面标题';
const SUBSCRIBE_RECOMMEND_IMAGE = '推荐图片';
const SUBSCRIBE_URL = '页面链接';
const SUBSCRIBE_PAY_LINK = '付费链接';
const ONE_STAGE_CATEGORY = '一级分类';
const SUBSCRIBE_IS_RECOMMEND = '是否推荐';
const SUBSCRIBE_TAG = '专栏内容定位';
const SUBSCRIBE_RELEASE = '是否上线';

interface RawData {
  blocks: Record<string, BlockDTO>;
  collectionViews: Record<string, CollectionViewDTO>;
}

const subscribeId =
  process.env.SUBSCRIBE_ID?.split('|')[0] ?? '6301204f-7892-47c8-9a6a-d34f5c6a8855';
// 线上调试

let cache: ReturnType<typeof _fetchSubscribeInfo> | undefined = undefined;
export const fetchSubscribeInfo = async (update?: (data: RawData) => void, prefixUrl?: string) => {
  if (cache) return cache;
  const res = _fetchSubscribeInfo(update, prefixUrl);
  cache = res;
  return cache;
};

export const getAllPageIds = (values: SubscribeCategory[] | undefined) => {
  if (!values) return [];
  const allIds: string[] = [];
  values.forEach((v) => {
    if (v.subscribeInfo) {
      const ids = v.subscribeInfo.map((s) => s.id).filter((id) => id) as string[];
      allIds.push(...ids);
    }
  });
  return allIds;
};
// const subscribeId = '6301204f-7892-47c8-9a6a-d34f5c6a8855';
const _fetchSubscribeInfo = async (update?: (data: RawData) => void, prefixUrl?: string) => {
  const { code, data } = await requestFromNet(`${prefixUrl ?? ''}/docs/${subscribeId}`);

  if (code !== 200) return;

  const { blocks, collectionViews } = data as RawData;
  allBlocks.blocks = { ...allBlocks.blocks, ...blocks };
  const collection = blocks[subscribeId ?? ''];
  if (!collection) return;

  const viewId = collection.views?.[0];
  if (!viewId) return;

  const view = collectionViews[viewId];
  const pageSort = new Set(view?.pageSort);
  const subNodesSet = new Set(collection.subNodes);
  view?.pageSort.forEach((uuid) => {
    if (!subNodesSet.has(uuid)) pageSort.delete(uuid);
  });

  for (const blockId of collection.subNodes) {
    if (!pageSort.has(blockId)) pageSort.add(blockId);
  }

  const newPageSort = [...pageSort];
  const recordBlocks = newPageSort
    .map((uuid) => blocks[uuid])
    .filter((item): item is BlockDTO => !!item)
    .filter((item) => {
      return getPropertyValue(item, SUBSCRIBE_RELEASE).toLocaleUpperCase() === 'YES';
    });

  return covertToSubscribeCategories(recordBlocks);
};

const covertToSubscribeCategories = (items: BlockDTO[]) => {
  const categories: SubscribeCategory[] = [];
  const categoryObj: Record<string, SubscribeCategory> = {};

  items.forEach((block) => {
    const oneStateName = getPropertyValue(block, ONE_STAGE_CATEGORY);
    if (!oneStateName) return;

    const template = getSubscribeData(block);
    if (!template) return;

    let firstCategory = categoryObj[oneStateName];
    if (!firstCategory) {
      firstCategory = {
        name: oneStateName,
        subscribeInfo: [],
      };
      categories.push(firstCategory);
      categoryObj[oneStateName] = firstCategory;
    }
    categoryObj[oneStateName]?.subscribeInfo?.push(template);
  });

  const propertyMapping = getPropertyMapping(subscribeId);
  const collection = allBlocks.blocks[subscribeId];
  if (!collection) return;
  const { schema: firstSchema } = propertyMapping[ONE_STAGE_CATEGORY] ?? {};
  if (!firstSchema?.options) return;

  const categoryValueMap: Record<string, number> = {};
  firstSchema.options.forEach(({ value }, index) => (categoryValueMap[`1${value}`] = index));

  const loop = (categories: SubscribeCategory[], level = 1) => {
    categories.sort((a, b) => {
      return (categoryValueMap[level + a.name] ?? 0) - (categoryValueMap[level + b.name] ?? 0);
    });
  };

  loop(categories);
  return categories;
};

export const getSubscribeData = (item: BlockDTO | string): SubscribeInfo | undefined => {
  let blockData: BlockDTO | undefined = undefined;
  if (typeof item === 'string') {
    blockData = allBlocks.blocks[item];
  } else {
    blockData = item;
  }

  if (!blockData) return;

  const url = getPropertyValue(blockData, SUBSCRIBE_URL);
  let id = '';
  try {
    const { pathname } = new URL(url);
    id = pathname.trim().substring(pathname.lastIndexOf('/') + 1);
  } catch (err) {
    //
  }
  //付费页面
  const payUrl = getPropertyValue(blockData, SUBSCRIBE_PAY_LINK);
  let payDocId = '';
  try {
    const { pathname } = new URL(payUrl);
    payDocId = pathname.trim().substring(pathname.lastIndexOf('/') + 1);
  } catch (err) {
    payDocId = '';
  }

  return {
    id: id || '',
    url,
    payLink: getPropertyValue(blockData, SUBSCRIBE_PAY_LINK),
    recordId: blockData.uuid,
    title: getPropertyValue(blockData, SUBSCRIBE_NAME) || segmentsToText(blockData.data.segments),
    sourceImage: getPropertyValue(blockData, SUBSCRIBE_RECOMMEND_IMAGE),
    isRecommend: getPropertyValue(blockData, SUBSCRIBE_IS_RECOMMEND).toUpperCase() === 'YES',
    payDocId,
    firstCategoryName: getPropertyValue(blockData, ONE_STAGE_CATEGORY),
    tag: getPropertyValue(blockData, SUBSCRIBE_TAG),
  };
};
