import {
  useMindMapEngine,
  useMindMapId,
  useMindMapNode,
  useMindMapSelector,
} from '@flowus/mind-map';
import type { FC } from 'react';
import { ListView } from 'src/common/components/list-view';
import {
  listViewNormalClassName,
  listViewNormalClassNameNotModal,
} from 'src/common/components/list-view/helper';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { useCopyBlockRecord } from 'src/hooks/block/use-copy-block-record';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { moveBlock } from 'src/redux/managers/block/move';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { bizTracker } from 'src/utils/biz-tracker';
import { isLinkPageBlock, isLinkTableBlock, isPageLike } from 'src/utils/block-type-utils';
import { filterDescendantId, getRefBlock } from 'src/utils/block-utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';
import { useAddSubNode } from '../hook/use-add-sub-node';
import { useToggleNode } from '../hook/use-toggle-node';

interface Props {
  onItemClick?: () => void;
  showBg?: boolean;
}
export const MoreNodeMenu: FC<Props> = (props) => {
  const { showBg } = props;
  const engine = useMindMapEngine();
  const mindMapId = useMindMapId();
  const transaction = useTransaction();
  const toggleNode = useToggleNode();
  const copyBlockRecord = useCopyBlockRecord();
  const addSubNode = useAddSubNode();
  const selectBlocks = useObservableStore((state) => state.ui.selectedBlocks, [], {
    obsSelectBlocks: [{ all: true }],
  });
  const blockId = selectBlocks[0]?.blockId ?? '';
  const lastBlockId = selectBlocks[selectBlocks.length - 1]?.blockId ?? '';
  const mindNode = useMindMapNode(blockId);
  const isRoot = useMindMapSelector((state) => state.rootId === blockId);
  const isMultiSelected = selectBlocks.length > 1;
  const expandedInfo = useObservableStore(
    (state) => {
      const engineState = engine.getState();
      for (const selectedBlock of state.ui.selectedBlocks) {
        const mindNode = engineState.mindNodes[selectedBlock.blockId];
        if (mindNode && mindNode.childrenIds.length > 0) {
          return { expanded: mindNode.expanded };
        }
      }
    },
    [],
    { obsSelectBlocks: [{ all: true }] }
  );

  const block = cache.blocks[selectBlocks[0]?.blockId ?? ''];
  const userName = useUserName(block?.updatedBy ?? '');
  if (!mindNode) return null;

  if (!block) return null;
  const isPage = isPageLike(block.type) || isLinkPageBlock(block) || isLinkTableBlock(block);

  const items: ListItem[] = [
    {
      type: ListItemType.OPERATION,
      isHidden: isRoot || isMultiSelected,
      data: {
        title: '插入同级节点',
        onClick: () => {
          transaction(() => {
            const newId = addSubNode(
              {
                parentId: cache.blocks[blockId]?.parentId ?? '',
                after: blockId,
              },
              mindMapId
            );
            newId &&
              dispatch(
                uiActions.updateSelectBlocks([
                  {
                    blockId: newId,
                    mindMapId,
                  },
                ])
              );
          });
          bizTracker.event('node_new', { from: 2 });
        },
        rightText: 'Enter',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !engine.option.supportAppendChild(blockId) || isMultiSelected,
      data: {
        title: '插入子节点',
        onClick: () => {
          transaction(() => {
            const newId = addSubNode({ parentId: blockId, last: true }, mindMapId);
            if (!newId) return;
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: newId,
                  mindMapId,
                },
              ])
            );
          });
          toggleNode(blockId, true);
          bizTracker.event('node_new', { from: 2 });
        },
        rightText: 'Tab',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isRoot || isMultiSelected,
      data: {
        title: '插入父节点',
        onClick: () => {
          // 先加一个节点在当前节点同级的后面，再把当前节点移动到新节点subnode下
          transaction(() => {
            const newBlockId = addSubNode(
              {
                parentId: cache.blocks[blockId]?.parentId ?? '',
                after: blockId,
              },
              mindMapId
            );
            if (!newBlockId) return;
            moveBlock([blockId], {
              parentId: newBlockId,
              last: true,
            });
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: newBlockId,
                  mindMapId,
                },
              ])
            );
            bizTracker.event('node_new', { from: 2 });
          });
        },
        rightText: `Shift+Tab`,
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isPage || selectBlocks.length > 1,
      data: {
        title: '拷贝块访问链接',
        onClick: () => {
          // 同步块内的块链接粘贴后不能转为同步块
          const pageId = getOwnerPage(block.uuid);
          void writeTextInClipboard(`${getLocationOrigin()}/${pageId}#${block.uuid}`);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !isPage || selectBlocks.length > 1,
      data: {
        title: '拷贝页面访问链接',
        onClick: () => {
          const isLinkPage = isLinkPageBlock(block);
          const reallyBlockId = isLinkPage ? getRefBlock(blockId)?.uuid : blockId;
          bizTracker.event('copy_page_link');
          void writeTextInClipboard(`${getLocationOrigin()}/${reallyBlockId}`);
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isRoot,
      data: {
        title: '拷贝副本',
        onClick: () => {
          void copyBlockRecord(selectBlocks, lastBlockId);
        },
        rightText: `${ShortcutSystemSymbol}+D`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isRoot,
      data: {
        title: '复制',
        onClick: () => {
          document.execCommand('copy');
        },
        rightText: `${ShortcutSystemSymbol}+c`,
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isRoot,
      data: {
        title: '剪切',
        onClick: () => {
          document.execCommand('cut');
        },
        rightText: `${ShortcutSystemSymbol}+x`,
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        title: '删除',
        onClick: () => {
          transaction(() => {
            archiveBlock(selectedBlocksToIds(filterDescendantId(selectBlocks)));
          });
        },
        rightText: 'Del',
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: isMultiSelected,
      data: {
        title: '定位到中心节点',
        onClick: () => {
          engine.fitCenter();
        },
      },
    },
    {
      type: ListItemType.LINE,
      isHidden: mindNode?.childrenIds.length === 0,
      data: {},
      disableClick: true,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !expandedInfo,
      data: {
        title: expandedInfo?.expanded ? '折叠子节点' : '展开子节点',
        onClick: () => {
          const engineState = engine.getState();
          selectBlocks.forEach((s) => {
            const node = engineState.mindNodes[s.blockId];
            if (node && node.childrenIds.length > 0) {
              toggleNode(s.blockId, !expandedInfo?.expanded);
            }
          });
          bizTracker.event('node_fold', { from: 2 });
        },
        rightText: `${ShortcutSystemSymbol}+.`,
      },
    },
    {
      type: ListItemType.LINE,
      data: {},
      disableClick: true,
      isHidden: isMultiSelected || judgeSharePage(),
    },
  ];

  const firstNotHiddenItem = items.find((i) => !i.isHidden);
  if (firstNotHiddenItem?.type === ListItemType.LINE) {
    firstNotHiddenItem.isHidden = true;
  }
  return (
    <div data-no-cancel-selected>
      <ListView
        className={showBg ? listViewNormalClassName : listViewNormalClassNameNotModal}
        items={items}
        onItemClick={props.onItemClick}
        customFooter={
          !isMultiSelected &&
          !judgeSharePage() && (
            <UpdateUserInfo
              showWordCount={false}
              name={userName}
              updatedAt={block.updatedAt}
              isByAI={block.data.isByAI}
            />
          )
        }
      ></ListView>
    </div>
  );
};
