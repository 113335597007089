import { create } from 'zustand';
import type { SubitemExpandState } from './type';
import { useCallback } from 'react';

const initState: SubitemExpandState = {};
export const useSubitemExpand = create<SubitemExpandState>(() => initState);

export const useRecordExpand = (collectionId: string, viewId: string, recordId: string) => {
  return useSubitemExpand((state) => {
    return state[collectionId + viewId]?.[recordId] ?? false;
  });
};
export const useSetRecordExpand = (collectionId: string, viewId: string, recordId: string) => {
  return useCallback(
    (expand: boolean) => {
      return setRecordExpand(collectionId, viewId, recordId, expand);
    },
    [collectionId, recordId, viewId]
  );
};

export const setRecordExpand = (
  collectionId: string,
  viewId: string,
  recordId: String,
  expand: boolean
) => {
  const state = useSubitemExpand.getState();
  const collectionRecord = state[collectionId + viewId];
  useSubitemExpand.setState({
    ...state,
    [collectionId + viewId]: {
      ...collectionRecord,
      [`${recordId}`]: expand,
    },
  });
};
