import type { UserDTO } from '@next-space/fe-api-idl';
import { request } from 'src/common/request';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import type { Guest } from 'src/utils/import-guest-util';

/** 批量生成临时userId并且把字段更新到相关redux上 */
export const temporaryBatchAndUpdateUsers = async (guests: Guest[]) => {
  const userIds: string[] = [];
  const markNames: Record<string, string> = {};
  const contactNameMap = new Map<string, string>();
  const allContact: string[] = [];
  for (const guest of guests) {
    contactNameMap.set(guest.contact, guest.name);
    allContact.push(guest.contact);
  }
  const { users, relations } = await request.infra.temporaryBatch(allContact);
  if (!relations) {
    return { userIds, markNames };
  }

  const usersRecord = users.reduce((pre, cur) => {
    pre[cur.uuid] = cur;
    return pre;
  }, {} as Record<string, UserDTO>);

  dispatch(
    usersActions.updateSpaceUsers({
      users: usersRecord,
    })
  );
  // 由于原逻辑的user无法存储批量邀请需要的数据，
  // 因此增加batchGuest的逻辑，user里存的主要是id,name，batchGuest是来寻找对应id的相关信息的
  for (const relation of relations) {
    userIds.push(relation.uuid);
    markNames[relation.uuid] =
      contactNameMap.get(__BUILD_IN__ ? relation.email : relation.phone) ?? '';
  }
  return { userIds, markNames };
};
