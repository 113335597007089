import type { FC } from 'react';
import { Option } from '../option';
import { Switch } from 'src/common/components/switch';
import { useCurrentUser, useUpdateUser } from 'src/hooks/user';

export const NotificationSetting: FC = () => {
  const user = useCurrentUser();
  const updateUser = useUpdateUser();

  if (!user) return null;
  return (
    <>
      <div className="text-t2">通知偏好</div>
      <Option
        showSplitLine={false}
        title="协作通知"
        description="开启后，可以收到空间成员的实时协作通知"
        rightPart={
          <Switch
            open={!user.setting.closeCollaborativeNotification ?? true}
            onSwitch={(status) => {
              void updateUser({
                setting: {
                  closeCollaborativeNotification: !status,
                },
              });
            }}
          />
        }
      />
      <Option
        showSplitLine={false}
        title="分享通知"
        description="开启后，可以收到分享页面的实时动态推送"
        rightPart={
          <Switch
            open={!user.setting.closeShareNotification ?? true}
            onSwitch={(status) => {
              void updateUser({
                setting: {
                  closeShareNotification: !status,
                },
              });
            }}
          />
        }
      />
      <Option
        showSplitLine={false}
        title="订阅通知"
        description="开启后，可以收到个人订阅页面的动态推送"
        rightPart={
          <Switch
            open={!user.setting.closeSubscribeNotification ?? true}
            onSwitch={(status) => {
              void updateUser(
                {
                  setting: {
                    closeSubscribeNotification: !status,
                  },
                },
                true
              );
            }}
          />
        }
      />
      <Option
        showSplitLine={false}
        title="收件箱通知"
        description="开启后，可以收到端内重要功能更新提醒与限时优惠活动推送通知"
        rightPart={
          <Switch
            open={!user.setting.closeInboxNotification ?? true}
            onSwitch={(status) => {
              void updateUser({
                setting: {
                  closeInboxNotification: !status,
                },
              });
            }}
          />
        }
      />
    </>
  );
};
