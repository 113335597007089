import type { IEditorModel } from '@next-space/fe-inlined';

export interface InlinePanelProps {
  onCloseModal: () => void;
  blockId: string;
  left: number;
  right: number;
  getEditorModel: (blockId: string) => IEditorModel | undefined;
  symbol?: string;
  setFakeFocus?: (status: boolean) => void;
}

export enum CategoryType {
  date = 1,
  person,
  page,
  search,
  searchPerson,
  foldPage,
  foldUser,
}
