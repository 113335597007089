import { getState } from 'src/redux/store';
import { isEditableType, isImageBlock, isPageLike } from 'src/utils/block-type-utils';

/** 文字数量虚拟列表 */
export const TEXT_THRESHOLD = 500;
/** 虚拟列表阈值 */
export const COUNT_THRESHOLD = 300;
/** 简易渲染Inner HTML */
export const VISIBLE_RENDER_THRESHOLD = 300;
/** 简易渲染空白DIV */
export const SUPER_VISIBLE_RENDER_THRESHOLD = 1000;

/** 低配置模式下的阈值 */
export const LOW_CONFIG_TEXT_THRESHOLD = 100;
export const LOW_CONFIG_COUNT_THRESHOLD = 100;
export const LOW_CONFIG_VISIBLE_RENDER_THRESHOLD = 100;
export const LOW_CONFIG_SUPER_VISIBLE_RENDER_THRESHOLD = 500;

export const getBlockLength = (_subNodes: string[] = []) => {
  const res = { count: 0, textCount: 0, imageCount: 0 };

  const { blocks } = getState();
  const loop = (subNodes: string[]) => {
    subNodes.forEach((uuid) => {
      const block = blocks[uuid];
      if (!block) return;
      res.count++;
      if (isEditableType(block.type)) {
        res.textCount++;
      }
      if (isImageBlock(block)) {
        res.imageCount++;
      }
      if (!isPageLike(block.type)) {
        loop(block.subNodes);
      }
    });
  };

  loop(_subNodes);

  return res;
};

const defaultConfig = {
  TEXT_THRESHOLD,
  COUNT_THRESHOLD,
  VISIBLE_RENDER_THRESHOLD,
  SUPER_VISIBLE_RENDER_THRESHOLD,
};

const lowConfig = {
  TEXT_THRESHOLD: LOW_CONFIG_TEXT_THRESHOLD,
  COUNT_THRESHOLD: LOW_CONFIG_COUNT_THRESHOLD,
  VISIBLE_RENDER_THRESHOLD: LOW_CONFIG_VISIBLE_RENDER_THRESHOLD,
  SUPER_VISIBLE_RENDER_THRESHOLD: LOW_CONFIG_SUPER_VISIBLE_RENDER_THRESHOLD,
};

export const getVisibleThresholdConfig = (isLowConfig: boolean) => {
  return isLowConfig ? lowConfig : defaultConfig;
};
