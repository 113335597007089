import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { request } from 'src/common/request';
import { useOpenTransfer } from 'src/hooks/public/use-open-transfer';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { Option } from '../option';
import { IncomeRecord } from './income-record';
import { Tooltip } from '@flowus/common/tooltip';

/**
 * 空间收益
 */
export const SpaceIncome: FC = () => {
  const openTransfer = useOpenTransfer();
  const [cashInfo, setCashInfo] =
    useState<Awaited<ReturnType<typeof request.infra.getTotalInWallet>>>();
  const openModal = useOpenModal();

  useEffect(() => {
    const spaceId = getCurrentSpaceId();
    void request.infra.getTotalInWallet(spaceId).then(setCashInfo);
  }, []);

  if (!cashInfo) return null;
  return (
    <div>
      <Option
        title={
          <div className="flex items-center space-x-2">
            <span>空间收益统计</span>
            <div
              className="flex items-center space-x-0.5 text-grey3 animate-click"
              onClick={() => {
                openModal.modal({
                  content: () => <IncomeRecord />,
                });
              }}
            >
              <span>查看收支记录</span>
              <Icon name="IcArrowDown01" size="tiny" className="-rotate-90" />
            </div>
          </div>
        }
        description={
          <div className="w-[360px]">
            <div className="text-t2 px-4 py-2 bg-grey9 rounded space-y-2">
              <div className="text-t2-medium flex space-x-2">
                <span>可提现</span>
                <span>{cashInfo.canWithdrawAmount}</span>
              </div>
              <Tooltip popup="5-7个工作日到账" className="inline-flex space-x-2 cursor-help">
                <span className="underline underline-offset-4">待结算</span>
                <span>{cashInfo.inProgressAmount}</span>
              </Tooltip>
              <div className="flex space-x-2">
                <span>总收入</span>
                <span>{cashInfo.totalAmount}</span>
              </div>
              <div className="text-t5-medium">
                <span>满10元可申请提现，每日限申请1次</span>
                <a
                  className="ml-2 text-t5 underline"
                  href="https://flowus.cn/share/b4260aa6-d685-4e88-8b9b-0d15c21d60e2"
                  target="_blank"
                >
                  了解费用详细说明
                </a>
              </div>
            </div>
          </div>
        }
        rightPart={
          <Button
            colorType="active"
            onClick={() => openTransfer({ type: 'space', spaceId: getCurrentSpaceId() })}
          >
            立即提现
          </Button>
        }
      />
    </div>
  );
};
