import { cx } from '@flowus/common/cx';
import type { CSSProperties } from 'react';
import { forwardRef, useMemo } from 'react';
import { Images } from 'src/image';
import { $searchParams } from 'src/utils';
import type { IconProp } from './icon';
import { LoadingIcon } from './loading-icon';

interface LoadingContainerProps extends Omit<IconProp, 'name' | 'size'> {
  fixed?: boolean;
  style?: CSSProperties;
  description?: string;
  size?: 'max' | 'middle' | 'large';
}

export const LoadingContainer = forwardRef<HTMLDivElement, LoadingContainerProps>(
  ({ fixed, style, className, description, size = 'max' }, ref) => {
    const loading = useMemo(() => {
      if (__CUSTOM_LOADING__) {
        return <img className={cx('h-20', className)} src={Images.loadingGif} />;
      }
      return <LoadingIcon size={size} />;
    }, [className, size]);

    return (
      <div
        ref={ref}
        className={cx(
          'flex items-center justify-center h-full w-full overflow-hidden',
          fixed && 'fixed top-0 right-0 z-50 bg-white1 w-screen h-screen',
          $searchParams.blog && 'flex-1',
          $searchParams.blog === 'walles' && '!bg-[#0d0c0f]',
          className
        )}
        style={style}
      >
        <div className="flex flex-col items-center">
          {loading}
          {description && <div className="text-t2 mt-2.5">{description}</div>}
        </div>
      </div>
    );
  }
);
