import { validate } from 'uuid';
import { getSessionOp, SessionKeys, setSessionStorage } from './index';
import { SearchParams } from '@flowus/const-config';
import { MAX_INVITE_CODE_SIZE } from '../const';

export const getPageIdFromLocation = () => {
  const { pathname } = location;
  const lastPath = pathname.split('/');
  const id = lastPath.find(validate);
  return id;
};

// KOL 分销活动
export const refreshSessionParams = (inviteCode?: string | null, pageId?: string) => {
  const params = new URLSearchParams(location.search);
  pageId = pageId || getPageIdFromLocation();
  inviteCode =
    inviteCode ||
    params.get(SearchParams.inviteCodeByWeChat) ||
    params.get(SearchParams.inviteCode);

  if (inviteCode && inviteCode.length < MAX_INVITE_CODE_SIZE) {
    getSessionOp.inviteCode.set(inviteCode);
  }

  if (pageId) setSessionStorage(SessionKeys.SHARE_INVITE_PAGE_ID, pageId);
};
