import { Compare } from '@flowus/common';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

import { segmentsToText } from '../utils/segments-utils';
import { BiValueType } from './_abstract';

export class BiTextType extends BiValueType<SegmentDTO[] | undefined> {
  fromSegments(segments: SegmentDTO[] | undefined): SegmentDTO[] | undefined {
    if (segments === undefined) return undefined;
    if (segments.length === 0) return undefined;
    if (segments.every((it) => it.type === TextType.TEXT && it.text === '')) return undefined;
    return segments;
  }

  toSegments(value: SegmentDTO[] | undefined): SegmentDTO[] | undefined {
    return value;
  }

  isNonNullEmpty(value: SegmentDTO[] | undefined): boolean {
    return segmentsToText(value) === '';
  }

  compare(value1: SegmentDTO[] | undefined, value2: SegmentDTO[] | undefined): number {
    return Compare.pinyin(segmentsToText(value1), segmentsToText(value2));
  }
}

export const textType = new BiTextType();
