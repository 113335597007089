import Color from 'color';
import isHotkey from 'is-hotkey';
import { clamp } from 'lodash-es';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { cssToCanvas } from 'src/utils/css-to-canvas';
import { getWindowDpr } from 'src/utils/dpr';
import { v4 as uuid4 } from 'uuid';

import type { PDFAnnotation, Point, Rect } from '../type';
import { AnnotationType } from '../type';
import { convertPathToRect } from '../utils/convert-path-to-rect';
import { cropRectArea } from '../utils/crop-rect-area';
import { drawEllipse } from '../utils/draw-ellipse';
import { drawLine } from '../utils/draw-line';
import { CropShape } from '../utils/imagedata-to-image';

export class ShapeEditor {
  public pathStack: { redo: Point[][]; undo: Point[][] } = { redo: [], undo: [] };
  private drawingPoints: Point[] = [];
  private isPolygonDrawing = false;
  private editorLayer = document.createElement('div');
  public canvas = document.createElement('canvas');
  public ctx: CanvasRenderingContext2D | null | undefined;
  public color = '#000';
  public annotationType?: AnnotationType;
  public removeEscEvent?: () => void;

  constructor(
    public pdfViewer: PDFViewer,
    public pageNumber: number,
    public onAddAnnotation: (annotation: PDFAnnotation) => void,
    options?: {
      annotationType?: AnnotationType;
      color?: string;
    }
  ) {
    this.color = options?.color ?? '#000';
    this.annotationType = options?.annotationType;

    const pageView = this.getPageView(pageNumber);
    const pageDiv = pageView.div;

    this.editorLayer.className = 'annotationEditorLayer';
    pageDiv.appendChild(this.editorLayer);

    this.editorLayer.appendChild(this.canvas);

    this.canvas.width = Math.floor(cssToCanvas(this.canvas.clientWidth));
    this.canvas.height = Math.floor(cssToCanvas(this.canvas.clientHeight));

    this.ctx = this.canvas.getContext('2d');
    const radio = getWindowDpr();
    if (!this.ctx) return;
    this.ctx.scale(radio, radio);
    this.ctx.strokeStyle = this.color;
    this.ctx.fillStyle = Color(this.color).alpha(0.4).toString();

    this.bindEvent();

    this.pdfViewer.eventBus.on('annotationlayerrendered', this.onAnnotationLayerRendered);
  }

  onAnnotationLayerRendered = (event: any) => {
    // 旋转，消失后再次 render, 或者缩放后重新 render。需要将 editorLayer 再次挂上同时更新 ctx
    if (event.pageNumber !== this.pageNumber) return;

    const pageDiv = event.source.div;
    pageDiv.appendChild(this.editorLayer);

    this.canvas.width = Math.floor(cssToCanvas(this.canvas.clientWidth));
    this.canvas.height = Math.floor(cssToCanvas(this.canvas.clientHeight));

    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) return;
    const radio = getWindowDpr();
    this.ctx.scale(radio, radio);
    this.ctx.fillStyle = Color(this.color).alpha(0.4).toString();
    this.ctx.strokeStyle = this.color;
  };

  getPageView = (pageNumber: number) => {
    return this.pdfViewer.getPageView(pageNumber - 1);
  };

  bindEvent = () => {
    if (!this.ctx) return;

    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 0) return;

      event.preventDefault();

      if (this.annotationType === AnnotationType.POLYGON) {
        this.drawPolygon(event);
      } else if (this.annotationType === AnnotationType.ELLIPSE) {
        this.drawCircle(event);
      } else if (this.annotationType === AnnotationType.PENCIL) {
        this.drawFreeLine(event);
      }
    };

    this.removeEscEvent = this.bindEscEvent();
    this.canvas.addEventListener('mousedown', handleMouseDown);
  };

  bindEscEvent = () => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isHotkey('esc')(event)) {
        if (this.annotationType === AnnotationType.POLYGON) {
          event.stopPropagation();
          void this.closePolygonPath();
        }

        this.drawingPoints = [];
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  };

  drawPolygon = (event: MouseEvent) => {
    if (!this.isPolygonDrawing) {
      this.isPolygonDrawing = true;
      const handleMouseMove = (event: MouseEvent) => {
        if (!this.isPolygonDrawing) {
          document.removeEventListener('keydown', handleKeydown, true);
          document.removeEventListener('mousemove', handleMouseMove);
          return;
        }

        this.drawingPoints = this.drawingPoints.filter((point) => !point.isTemp);

        const point = this.getPoint(event);
        const firstPoint = this.drawingPoints[0];
        if (
          this.drawingPoints.length >= 3 &&
          firstPoint &&
          Math.abs(firstPoint.x - point.x) < 8 &&
          Math.abs(firstPoint.y - point.y) < 8
        ) {
          this.drawingPoints.push({ ...firstPoint, isTemp: true });
        } else {
          this.drawingPoints.push({ ...point, isTemp: true });
        }

        this.clearBoard();
        if (!this.ctx) return;
        if (this.drawingPoints.length === 1) return;
        drawLine(this.ctx, this.drawingPoints, { lineCap: 'round', joinShape: 'round' });
      };

      const handleKeydown = (event: KeyboardEvent) => {
        if (!this.isPolygonDrawing) return;

        if (isHotkey('mod+z')(event)) {
          event.preventDefault();
          event.stopPropagation();

          const lastPath = this.pathStack.undo.pop();
          if (lastPath) {
            this.pathStack.redo.push([...this.drawingPoints.filter((point) => !point.isTemp)]);
            const tempPoint = this.drawingPoints.find((point) => point.isTemp);
            this.drawingPoints = tempPoint ? lastPath.concat(tempPoint) : lastPath;
            if (this.ctx) {
              this.clearBoard();
              if (this.drawingPoints.length === 1) return;
              drawLine(this.ctx, this.drawingPoints, { lineCap: 'round', joinShape: 'round' });
            }
          }
        }

        if (isHotkey('mod+shift+z')(event)) {
          event.preventDefault();
          event.stopPropagation();
          const lastPath = this.pathStack.redo.pop();
          if (lastPath) {
            this.pathStack.undo.push([...this.drawingPoints.filter((point) => !point.isTemp)]);
            const tempPoint = this.drawingPoints.find((point) => point.isTemp);
            this.drawingPoints = tempPoint ? lastPath.concat(tempPoint) : lastPath;
            if (this.ctx) {
              this.clearBoard();
              if (this.drawingPoints.length === 1) return;
              drawLine(this.ctx, this.drawingPoints, { lineCap: 'round', joinShape: 'round' });
            }
          }
        }
      };

      document.addEventListener('keydown', handleKeydown, true);
      document.addEventListener('mousemove', handleMouseMove);
    }

    if (this.drawingPoints.length >= 4) {
      const firstPoint = this.drawingPoints[0];
      const lastPoint = this.drawingPoints[this.drawingPoints.length - 1];
      if (firstPoint && lastPoint && firstPoint.x === lastPoint.x && firstPoint.y === lastPoint.y) {
        void this.closePolygonPath();
        return;
      }
    }

    const point = this.getPoint(event);
    if (!this.ctx) return;
    this.ctx.beginPath();
    this.ctx.arc(point.x, point.y, 5, 0, 2 * Math.PI);
    this.ctx.fill();
    this.pathStack.undo.push([...this.drawingPoints.filter((point) => !point.isTemp)]);
    this.drawingPoints.push(point);
  };

  drawCircle = (event: MouseEvent) => {
    let shiftPressed = false;

    this.drawingPoints.push(this.getPoint(event));

    const updatePoint = (point1: Point, point2: Point) => {
      const width = point2.x - point1.x;
      const height = point2.y - point1.y;
      const max = Math.max(Math.abs(width), Math.abs(height));
      return {
        x: width > 0 ? point1.x + max : point1.x - max,
        y: height > 0 ? point1.y + max : point1.y - max,
      };
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!this.ctx) return;

      if (this.drawingPoints.length > 1) {
        this.drawingPoints.pop();
      }
      this.drawingPoints.push(this.getPoint(event));

      this.clearBoard();

      const [point1, point2] = this.drawingPoints;
      if (!point1 || !point2) return;
      drawEllipse(this.canvas, point1, shiftPressed ? updatePoint(point1, point2) : point2);
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.shiftKey) {
        shiftPressed = true;

        this.clearBoard();

        const [point1, point2] = this.drawingPoints;
        if (!point1 || !point2) return;
        drawEllipse(this.canvas, point1, updatePoint(point1, point2));
      }
    };

    const handleKeyup = () => {
      shiftPressed = false;

      this.clearBoard();

      const [point1, point2] = this.drawingPoints;
      if (!point1 || !point2) return;
      drawEllipse(this.canvas, point1, point2);
    };

    const handleMouseUp = async () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyup);

      if (!this.ctx) return;

      if (this.drawingPoints.length > 1) {
        if (shiftPressed) {
          const [point1, point2] = this.drawingPoints;
          if (!point1 || !point2) return;
          this.drawingPoints[1] = updatePoint(point1, point2);
        }
        const pagePaths = this.drawingPoints;
        const rect = convertPathToRect(pagePaths);

        const pageView = this.getPageView(this.pageNumber);

        const pdfPath = pagePaths.map((point) => {
          const [x, y] = pageView.viewport.convertToPdfPoint(point.x, point.y);
          return { x, y };
        });

        const firstPoint = pdfPath[0];
        const secondPoint = pdfPath[1];
        if (!firstPoint || !secondPoint) return;
        const leftTop = {
          x: Math.min(firstPoint.x, secondPoint.x),
          y: Math.max(firstPoint.y, secondPoint.y),
        };
        const rightBottom = {
          x: Math.max(firstPoint.x, secondPoint.x),
          y: Math.min(firstPoint.y, secondPoint.y),
        };
        const pdfRect = { x: leftTop.x, y: leftTop.y, xMax: rightBottom.x, yMax: rightBottom.y };

        const annotation: PDFAnnotation = {
          width: rect.width,
          height: rect.height,
          type: AnnotationType.ELLIPSE,
          // path: pdfPath,
          pdfRects: { [String(this.pageNumber)]: [pdfRect] },
          pageNumber: this.pageNumber,
          color: this.color,
          uuid: uuid4(),
        };

        const blobUrl = await this.getBlobUrl(
          this.pageNumber,
          { ...rect, x: rect.left, y: rect.top },
          pagePaths,
          CropShape.ELLIPSE
        );
        annotation.url = blobUrl;

        this.onAddAnnotation(annotation);
      }

      this.clearBoard();
      this.drawingPoints = [];
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyup);
  };

  drawFreeLine = (event: MouseEvent) => {
    let lastPoint = this.getPoint(event);

    const handleMouseMove = (event: MouseEvent) => {
      if (!this.ctx) return;

      const newPoint = this.getPoint(event);
      drawLine(this.ctx, [lastPoint, newPoint], { lineCap: 'round', lineJoin: 'round' });

      lastPoint = newPoint;
      this.drawingPoints.push(newPoint);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  closePolygonPath = async () => {
    if (!this.ctx) return;

    this.isPolygonDrawing = false;
    this.pathStack = { redo: [], undo: [] };

    this.drawingPoints.pop();

    if (this.drawingPoints.length > 2) {
      const pagePaths = this.drawingPoints;
      const rect = convertPathToRect(pagePaths);

      const pageView = this.getPageView(this.pageNumber);

      const pdfPath = pagePaths.map((point) => {
        const [x, y] = pageView.viewport.convertToPdfPoint(point.x, point.y);
        return { x, y };
      });
      const annotation: PDFAnnotation = {
        width: rect.width,
        height: rect.height,
        type: AnnotationType.POLYGON,
        path: pdfPath,
        pageNumber: this.pageNumber,
        color: this.color,
        uuid: uuid4(),
      };
      const blobUrl = await this.getBlobUrl(
        this.pageNumber,
        { ...rect, x: rect.left, y: rect.top },
        pagePaths
      );
      annotation.url = blobUrl;

      this.onAddAnnotation(annotation);
    }

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawingPoints = [];
  };

  getBlobUrl = async (pageNumber: number, pageRect: Rect, path?: Point[], shape?: CropShape) => {
    const pageView = this.getPageView(pageNumber);
    return cropRectArea(pageView.canvas, pageRect, path, shape);
  };

  getPoint = (event: MouseEvent) => {
    const canvasRect = this.canvas.getBoundingClientRect();
    const clientX = clamp(event.clientX, canvasRect.left, canvasRect.right);
    const clientY = clamp(event.clientY, canvasRect.top, canvasRect.bottom);
    const point = { x: clientX - canvasRect.x, y: clientY - canvasRect.y };
    return point;
  };

  setColor = (color: string) => {
    if (this.color === color) return;

    if (!this.ctx) return;
    this.color = color;
    this.ctx.strokeStyle = color;
    this.ctx.fillStyle = Color(color).alpha(0.4).toString();
  };

  clearBoard = () => {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  };

  disable = () => {
    if (this.annotationType === AnnotationType.POLYGON) {
      void this.closePolygonPath();
    }

    this.drawingPoints = [];
    this.annotationType = AnnotationType.NONE;
    this.isPolygonDrawing = false;
    this.clearBoard();
    this.removeEscEvent?.();

    this.editorLayer.style.pointerEvents = 'none';
  };

  enable = (annotationType: AnnotationType, color: string) => {
    this.setColor(color);

    if (annotationType === this.annotationType) return;

    if (this.annotationType === AnnotationType.POLYGON) {
      void this.closePolygonPath();
    }

    if (this.annotationType !== annotationType) {
      this.drawingPoints = [];
    }

    this.bindEscEvent();
    this.annotationType = annotationType;
    this.editorLayer.style.pointerEvents = 'auto';
  };
}
