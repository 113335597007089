import type { Immutable } from 'immer';
import type { MindMapEngine } from '../mind-map-engine';
import type { CanvasStyle, Line, MindNode, MindNodeDragInfo, Rect, Where } from '../types';
import { getMindMapNextNode, getMindMapPreNode, intersectRect, walk } from '../util';
import type { LayoutProviderOption } from './base-layout-provider';
import { BaseLayoutProvider } from './base-layout-provider';
import { CANVAS_PADDING } from './canvas-graph';

/**标题在左边居中位置的布局类 */
/**
 * 概念：叶子节点是指没有子节点的节点（收缩状态也算叶子节点）
 * 核心规则：每个节点都是其子节点占据的区域高度/2
 *
 * 计算步骤：
 * 1.先定位中心点
 * 2.因为每个节点都是区域高度的中心位置，因此第一个子节点的top就是往上偏移区域高度的一半
 * 3.除第一个子节点，兄弟节点都是依赖于前一个节点进行定位
 * 4.最后调整位置
 */
export class OrganizationLayoutProvider extends BaseLayoutProvider {
  constructor(engine: MindMapEngine, opt: LayoutProviderOption) {
    super(engine, opt);
  }

  layout = (nodeId: string) => {
    try {
      this.computeNodeRegionValue(nodeId);
      this.updateViewPort();
      this.computedBaseValue();
      this.computedLeftValue();
      this.adjustLeftValue();
      this.fixRegionHeight();
      this.computeOffset();
      this.computeFixWidth();
      this.computeNodesByLevelOrder();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    }
  };

  /**修复边缘的节点位置 */
  fixRegionHeight = () => {
    const state = this.engine.getDraftState();
    let left = 0;
    const rootNode = state.mindNodes[state.rootId];
    if (!rootNode) return;
    walk(
      this.engine,
      state.rootId,
      '',
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return false;
        if (node.left < left) {
          left = node.left;
        }
      },
      (id) => {
        if (left !== 0) {
          const node = state.mindNodes[id];
          if (!node) return false;
          node.left += Math.abs(left);
        }
      },
      true,
      0
    );
    if (left !== 0) {
      rootNode.regionWidth = rootNode.regionWidth + Math.abs(left);
      this.updateViewPort();
    }
  };
  computedBaseValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id, parentId, isRoot) => {
        const node = state.mindNodes[id];
        const parentNode = state.mindNodes[parentId];
        if (!node) return true;
        if (isRoot) {
          node.left = 0;
          node.top = 0;
        } else if (parentNode) {
          // 非根节点
          // 定位到父节点下方
          node.top = parentNode.top + parentNode.height + this.opt.horizontalMargin;
        }
        if (!node.expanded) {
          return true;
        }
      },
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return;
        if (!node.expanded) {
          node.childrenWidth = 0;
          return;
        }
        let validChildrenCount = 0;
        //计算children占据的区域高度
        node.childrenWidth =
          node.childrenIds.reduce((h, cId) => {
            const c = state.mindNodes[cId];
            if (!c) return h;
            validChildrenCount++;
            return h + c.width;
          }, 0) +
          (validChildrenCount + 1) * this.opt.verticalMargin;
      },
      true,
      0
    );
  };
  //遍历节点树计算节点的left
  computedLeftValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return true;
        if (!node.expanded) return;
        if (node.childrenIds.length === 0) return;
        const left = node.left + node.width * 0.5 - node.childrenWidth * 0.5;
        let totalLeft = left + this.opt.verticalMargin;
        node.childrenIds.forEach((cId) => {
          const childNode = state.mindNodes[cId];
          if (childNode) {
            childNode.left = totalLeft;
            totalLeft += childNode.width + this.opt.verticalMargin;
          }
        });
      },
      undefined,
      true,
      0
    );
  };
  adjustLeftValue = () => {
    const state = this.engine.getDraftState();
    walk(
      this.engine,
      state.rootId,
      '',
      (nodeId) => {
        const node = state.mindNodes[nodeId];
        if (!node) return true;
        if (!node.expanded) return true;
        // 判断子节点所占的宽度之和是否大于该节点自身，大于则需要调整位置
        const difference = node.childrenWidth - this.opt.verticalMargin * 2 - node.width;
        if (difference > 0) {
          this.updateBrothers(nodeId, difference / 2);
        }
      },
      undefined,
      true
    );
  };
  updateBrothers = (nodeId: string, addWidth: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) return;
    const parentNode = this.engine.getMindNode(node.parentId);
    if (!parentNode) return;

    const index = parentNode.childrenIds.indexOf(nodeId);

    parentNode.childrenIds.forEach((cId, cIndex) => {
      if (cId === nodeId) return;
      const childNode = state.mindNodes[cId];
      if (!childNode) return;
      let offset = 0;
      // 上面的节点往上移
      if (cIndex < index) {
        offset = -addWidth;
      } else if (cIndex > index) {
        // 下面的节点往下移
        offset = addWidth;
      }
      this.updateNodeRecursive(cId, 'left', offset);
    });
    // 更新父节点的位置
    this.updateBrothers(node.parentId, addWidth);
  };

  updateScrollerPos = (oldRegionWidth: number, _: number) => {
    const rootNode = this.engine.getRootMindNode();
    const scrollX = (rootNode.regionWidth - oldRegionWidth) / 2;
    if (scrollX !== 0) {
      this.scroller?.scrollBy(scrollX, 0);
    }
  };

  protected getMindMapCenterPos = (): [number, number] | undefined => {
    if (!this.scroller) {
      return;
    }
    const root = this.engine.getRootMindNode();
    const { scale } = this.engine.getState();
    const scaleFactor = scale * 0.01;
    const rect = this.scroller.getBoundingClientRect();
    const rootCenterX = root.x + (root.width * scaleFactor) / 2;
    let rootCenterY = root.y + (root.height * scaleFactor) / 2;
    if (root.regionHeight * scaleFactor < rect.height) {
      rootCenterY = root.y + (root.regionHeight * scaleFactor) / 2;
    }
    return [rootCenterX, rootCenterY];
  };

  getConnectToWhere = (dragInfo: MindNodeDragInfo) => {
    const mindMapState = this.engine.getState();
    const scaleFactor = mindMapState.scale * 0.01;
    const mindNode = mindMapState.mindNodes[dragInfo.id];
    if (!mindNode) return;
    const rootNode = mindMapState.mindNodes[mindMapState.rootId];
    if (!rootNode) return;
    const dragNode = mindMapState.mindNodes[dragInfo.id];
    if (!dragNode) return;

    const dragInfoX = dragInfo.left;
    const dragInfoY = dragInfo.top;
    //超出左侧边界
    if (
      dragInfoX + dragNode.width * scaleFactor <
      rootNode.x +
        rootNode.width * 0.5 * scaleFactor -
        rootNode.regionWidth * 0.5 * scaleFactor -
        CANVAS_PADDING * 0.5 * scaleFactor
    ) {
      return;
    }
    //超出右侧边界
    if (
      dragInfoX + dragNode.width * scaleFactor >
      rootNode.x +
        rootNode.width * 0.5 * scaleFactor +
        rootNode.regionWidth * 0.5 * scaleFactor +
        CANVAS_PADDING * 0.5 * scaleFactor
    ) {
      return;
    }
    //超出上边界
    if (dragInfoY < rootNode.y - CANVAS_PADDING * 0.5 * scaleFactor) {
      return;
    }
    //超出下边界
    if (
      dragInfoY >
      rootNode.y + rootNode.regionHeight * scaleFactor + CANVAS_PADDING * 0.5 * scaleFactor
    ) {
      return;
    }
    let markNode: Immutable<MindNode> | undefined = undefined;
    let distance = Number.MAX_VALUE;
    const entries = Object.entries(mindMapState.mindNodes);
    for (const [_, node] of entries) {
      //node区域高度
      const nodeRegionHeightRect: Rect = {
        x: node.x + node.width * 0.5 * scaleFactor - node.regionWidth * 0.5 * scaleFactor,
        y: node.y + node.height,
        width: node.regionWidth * scaleFactor,
        height: node.height * scaleFactor,
      };
      if (
        intersectRect(nodeRegionHeightRect, {
          x: dragInfoX,
          y: dragInfoY,
          width: 1,
          height: 1,
        })
      ) {
        if (node.id === dragInfo.id) return { parentId: node.parentId } as Where;
        if (node.id === dragInfo.id) return;
        if (dragInfoX > node.x) {
          return { parentId: node.parentId, after: node.id } as Where;
        }
        return { parentId: node.parentId, before: node.id } as Where;
      }

      //记录一下符合条件的
      if (dragInfoY > node.y + node.height * scaleFactor) {
        const centerDragInfoX = dragInfoX + (dragNode.width / 2) * scaleFactor;
        const nodeCenterX = node.x + (node.width / 2) * scaleFactor;
        const nodeEndY = node.y + node.height * scaleFactor;
        const newDistance =
          Math.pow(dragInfoY - nodeEndY, 2) + Math.pow(centerDragInfoX - nodeCenterX, 2);
        if (newDistance < distance) {
          distance = newDistance;
          markNode = node;
        }
      }
    }
    if (!markNode) return;
    if (markNode.id === dragInfo.id) return;
    if (!markNode.expanded || markNode.childrenIds.length === 0) {
      return { parentId: markNode.id, first: true } as Where;
    }
    //找具体位置
    let after: string | undefined;
    for (const cId of markNode.childrenIds) {
      const childNode = mindMapState.mindNodes[cId];
      if (!childNode) return;
      if (dragInfoX < childNode.x) {
        return { parentId: markNode.id, first: true } as Where;
      }
      if (dragInfoX > childNode.x) {
        after = childNode.id;
      }
    }
    if (after && after !== dragInfo.id) {
      return { parentId: markNode.id, after } as Where;
    }
  };

  updateMindNodeSize = (id: string, width: number, height: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[id];
    if (!node) {
      return;
    }
    node.width = Math.floor(width);
    node.height = Math.floor(height);
  };
  protected computeNodeRegionValue = (nodeId: string) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[nodeId];
    if (!node) return;

    const { height, width } = node;
    let maxRegionHeight = 0;
    let childrenWidth = 0;

    if (node.expanded) {
      node.childrenIds.forEach((cId) => {
        this.computeNodeRegionValue(cId);
        const c = state.mindNodes[cId];
        if (!c) return;

        childrenWidth += c.regionWidth;
        if (c.regionHeight > maxRegionHeight) {
          maxRegionHeight = c.regionHeight;
        }
      });
      if (node.childrenIds.length > 0) {
        childrenWidth += this.opt.verticalMargin * (node.childrenIds.length - 1);
      }
    }

    node.regionWidth = Math.max(width, childrenWidth);
    if (node.expanded && node.childrenIds.length > 0) {
      node.regionHeight = height + this.opt.horizontalMargin + maxRegionHeight;
    } else {
      node.regionHeight = height;
    }
  };

  protected getLine = (node: Immutable<MindNode>, childNode: Immutable<MindNode>) => {
    const color = this.getLineColor(childNode.id);
    return {
      startX: (node.left + node.width / 2) / this.scale,
      startY: (node.top + node.height) / this.scale,
      endX: (childNode.left + childNode.width / 2) / this.scale,
      endY: childNode.top / this.scale,
      debug: `${node.id} - ${childNode.id}`,
      isRoot: !node.parentId,
      color,
    };
  };

  protected getDragNodeRect = (id: string) => {
    const node = this.engine.getMindNode(id ?? '');
    if (!node) return;
    const x = node.left + node.width / 2 - node.regionWidth / 2;
    const y = node.top;
    return { x, y, width: node.regionWidth, height: node.regionHeight };
  };

  protected getDragLine = (
    dragXInCanvas: number,
    dragYInCanvas: number,
    nodeId: string,
    parentId: string
  ) => {
    const node = this.engine.getMindNode(nodeId);
    if (!node) return;
    const parentNode = this.engine.getMindNode(parentId);
    if (!parentNode) return;
    const color = this.getLineColor(nodeId);
    const line: Line = {
      debug: '',
      startX: (parentNode.left + parentNode.width * 0.5) / this.scale,
      startY: (parentNode.top + parentNode.height) / this.scale,
      endX: (dragXInCanvas + node.width * 0.5) / this.scale,
      endY: dragYInCanvas / this.scale,
      color,
    };
    return line;
  };
  protected drawBezierLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.strokeStyle = line.color;
    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    if (style) {
      Object.assign(ctx, style);
    }
    const height = (line.endY - line.startY) / 2;
    const cx1 = line.startX;
    const cy1 = line.startY + height;
    const cx2 = line.endX;
    const cy2 = cy1;
    ctx.moveTo(line.startX, line.startY);
    ctx.bezierCurveTo(cx1, cy1, cx2, cy2, line.endX, line.endY);
  };

  protected drawLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.strokeStyle = line.color;

    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    if (style) {
      Object.assign(ctx, style);
    }
    ctx.moveTo(line.startX, line.startY);
    const lineCenterY = line.startY + (line.endY - line.startY) / 2;
    ctx.lineTo(line.startX, lineCenterY);
    //是否左
    const isLeft = line.startX < line.endX;
    const { round } = this;
    if (Math.abs(line.startX - line.endX) > round) {
      const x = isLeft ? line.endX - round : line.endX + round;
      ctx.lineTo(x, lineCenterY);
      ctx.arcTo(line.endX, lineCenterY, line.endX, lineCenterY + round, round);
      ctx.lineTo(line.endX, line.endY);
    } else {
      //两者中心点不够一个弧度高，因此用直线连上
      ctx.lineTo(line.startX, line.endY);
    }
  };
  getNextNodeIdByArrowKeyboard = (arrow: 'left' | 'right' | 'up' | 'down', currentId: string) => {
    switch (arrow) {
      case 'left': {
        return getMindMapPreNode(this.engine, currentId);
      }
      case 'right': {
        return getMindMapNextNode(this.engine, currentId);
      }
      case 'up': {
        return this.getParentId(currentId);
      }
      case 'down': {
        return this.getMiddleChild(currentId);
      }
      default:
    }
  };
}
