// @ts-ignore env
export const { VITE_PRODUCT_NAME, VITE_PRODUCT, VITE_IS_WEBSITE } = import.meta.env ?? {};
export const ExtensionName = VITE_PRODUCT_NAME ?? 'Walles.AI';
export const isFlowUs = VITE_PRODUCT === 'flowus';
export const isWebSite = !!VITE_IS_WEBSITE;

interface Options {
  type: 'CREATE_TAB';
  data: {
    url: string;
  };
}

export const checkExtension = () => {
  return !!document.querySelector(`meta[name="${ExtensionName}"]`);
};

export const extensionPostMessage = (opt: Options) => {
  window.parent.postMessage(
    {
      type: ExtensionName,
      data: opt,
    },
    '*'
  );
};
