import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';

import { BlockSubject } from '../subject';

export const BlockDeleted: FC<{ block: BlockDTO; onClick?: () => void }> = ({ block, onClick }) => {
  return (
    <div className="mt-1 ml-1 animate-hover" onClick={onClick}>
      <div className="text-t4 text-grey3">已删除</div>
      <div className="opacity-30 pointer-events-none">
        <BlockSubject block={block} />
      </div>
    </div>
  );
};

export const BlockCreated: FC<{ block: BlockDTO; onClick?: () => void }> = ({ block, onClick }) => {
  return (
    <div className="mt-1 ml-1 animate-hover bg-active_color_10" onClick={onClick}>
      <div className="opacity-80 pointer-events-none">
        <BlockSubject block={block} />
      </div>
    </div>
  );
};
