import { BlockType } from '@next-space/fe-api-idl';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK, UPDATE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';

type CleanAction =
  | { uuid: string; type: 'remove' }
  | {
      uuid: string;
      type: 'move';
      target: string;
      after: string;
    };

export const cleanupColumn = (uuid: string, oldParentId: string) => {
  const { blocks } = getState();
  const block = blocks[uuid];
  if (!block) return;

  const oldParent = blocks[oldParentId];
  if (!oldParent || oldParent.type !== BlockType.COLUMN) return;

  // 防止出现 space 清空 subnode 情况
  const spaceId = getCurrentSpaceId();
  if ([block.uuid, oldParent.parentId].includes(spaceId)) return;

  const grandPa = blocks[oldParent.parentId];
  if (!grandPa) return;

  // 收集移动后的清理 col 的操作
  const cleanupActions: CleanAction[] = [];

  const restCols = grandPa.subNodes.filter((colId) => {
    const col = blocks[colId];
    if (col?.subNodes.length === 0) {
      cleanupActions.push({
        uuid: colId,
        type: 'remove',
      });
      return false;
    }
    return true;
  });

  if (restCols.length === 1) {
    const [lonelyColId] = restCols;
    if (!lonelyColId) return;
    const lonelyCol = blocks[lonelyColId];
    lonelyCol?.subNodes.forEach((item) => {
      const index = lonelyCol.subNodes.indexOf(item);
      const after = lonelyCol.subNodes[index - 1] ?? grandPa.uuid;
      cleanupActions.push({
        type: 'move',
        uuid: item,
        target: grandPa.parentId,
        after,
      });
    });
    cleanupActions.push({ type: 'remove', uuid: grandPa.uuid });
    cleanupActions.push({ type: 'remove', uuid: lonelyColId });
  }

  cleanupActions.forEach((item) => {
    dispatch(LIST_REMOVE_BLOCK({ uuid: item.uuid }));
    if (item.type === 'remove') {
      dispatch(
        UPDATE_BLOCK({
          uuid: item.uuid,
          patch: { status: -1 },
        })
      );
    }
    if (item.type === 'move') {
      dispatch(LIST_AFTER_BLOCK({ uuid: item.uuid, parentId: item.target, after: item.after }));
    }
  });
};
