import { useCallback } from 'react';
import * as CollectionViewManager from 'src/redux/managers/collection-view';

import { useTransaction } from '../use-transaction';

export const useRemoveCollectionView = () => {
  const transaction = useTransaction();
  const removeCollectionView = (viewId: string) => {
    transaction(() => {
      CollectionViewManager.remove(viewId);
    });
  };

  return useCallback(removeCollectionView, [transaction]);
};
