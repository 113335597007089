/* eslint-disable default-case */
import { contains } from '../../core/bitwise';
import type { Context } from '../../core/context';
import type { CSSParsedDeclaration } from '../../css';
import { Bounds } from '../../css/layout/bounds';
import { segmentGraphemes, TextBounds } from '../../css/layout/text';
import { BACKGROUND_CLIP } from '../../css/property-descriptors/background-clip';
import { BORDER_STYLE } from '../../css/property-descriptors/border-style';
import { DIRECTION } from '../../css/property-descriptors/direction';
import { DISPLAY } from '../../css/property-descriptors/display';
import { computeLineHeight } from '../../css/property-descriptors/line-height';
import { LIST_STYLE_TYPE } from '../../css/property-descriptors/list-style-type';
import { PAINT_ORDER_LAYER } from '../../css/property-descriptors/paint-order';
import { TEXT_ALIGN } from '../../css/property-descriptors/text-align';
import { TEXT_DECORATION_LINE } from '../../css/property-descriptors/text-decoration-line';
import type { TextShadow } from '../../css/property-descriptors/text-shadow';
import { isDimensionToken } from '../../css/syntax/parser';
import { TokenType } from '../../css/syntax/tokenizer';
import type { Color } from '../../css/types/color';
import { asString, isTransparent } from '../../css/types/color';
import {
  calculateGradientDirection,
  calculateRadius,
  processColorStops,
} from '../../css/types/functions/gradient';
import type {
  CSSURLImage,
  GradientColorStop,
  UnprocessedGradientColorStop,
} from '../../css/types/image';
import { CSSImageType, isLinearGradient, isRadialGradient } from '../../css/types/image';
import { FIFTY_PERCENT, getAbsoluteValue } from '../../css/types/length-percentage';
import type { ElementContainer } from '../../dom/element-container';
import { FLAGS } from '../../dom/element-container';
import { SelectElementContainer } from '../../dom/elements/select-element-container';
import { TextareaElementContainer } from '../../dom/elements/textarea-element-container';
import type { ReplacedElementContainer } from '../../dom/replaced-elements';
import { CanvasElementContainer } from '../../dom/replaced-elements/canvas-element-container';
import { IFrameElementContainer } from '../../dom/replaced-elements/iframe-element-container';
import { ImageElementContainer } from '../../dom/replaced-elements/image-element-container';
import {
  CHECKBOX,
  InputElementContainer,
  INPUT_COLOR,
  RADIO,
} from '../../dom/replaced-elements/input-element-container';
import { SVGElementContainer } from '../../dom/replaced-elements/svg-element-container';
import type { TextContainer } from '../../dom/text-container';
import { calculateBackgroundRendering, getBackgroundValueForIndex } from '../background';
import type { BezierCurve } from '../bezier-curve';
import { isBezierCurve } from '../bezier-curve';
import {
  parsePathForBorder,
  parsePathForBorderDoubleInner,
  parsePathForBorderDoubleOuter,
  parsePathForBorderStroke,
} from '../border';
import type { BoundCurves } from '../bound-curves';
import {
  calculateBorderBoxPath,
  calculateContentBoxPath,
  calculatePaddingBoxPath,
} from '../bound-curves';
import { contentBox } from '../box-sizing';
import type { IElementEffect } from '../effects';
import { EffectTarget, isClipEffect, isOpacityEffect, isTransformEffect } from '../effects';
import { FontMetrics } from '../font-metrics';
import type { Path } from '../path';
import { transformPath } from '../path';
import { Renderer } from '../renderer';
import type { ElementPaint, StackingContext } from '../stacking-context';
import { parseStackingContexts } from '../stacking-context';
import { Vector } from '../vector';

export type RenderConfigurations = RenderOptions & {
  backgroundColor: Color | null;
};

export interface RenderOptions {
  scale: number;
  canvas?: HTMLCanvasElement;
  x: number;
  y: number;
  width: number;
  height: number;
}

const MASK_OFFSET = 10000;

export class CanvasRenderer extends Renderer {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  private readonly _activeEffects: IElementEffect[] = [];
  private readonly fontMetrics: FontMetrics;

  constructor(context: Context, options: RenderConfigurations) {
    super(context, options);
    this.canvas = options.canvas ? options.canvas : document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    if (!options.canvas) {
      this.canvas.width = Math.floor(options.width * options.scale);
      this.canvas.height = Math.floor(options.height * options.scale);
      this.canvas.style.width = `${options.width}px`;
      this.canvas.style.height = `${options.height}px`;
    }
    this.fontMetrics = new FontMetrics(document);
    this.ctx.scale(this.options.scale, this.options.scale);
    this.ctx.translate(-options.x, -options.y);
    this.ctx.textBaseline = 'bottom';
    this._activeEffects = [];
    this.context.logger.debug(
      `Canvas renderer initialized (${options.width}x${options.height}) with scale ${options.scale}`
    );
  }

  applyEffects(effects: IElementEffect[]): void {
    while (this._activeEffects.length) {
      this.popEffect();
    }

    effects.forEach((effect) => this.applyEffect(effect));
  }

  applyEffect(effect: IElementEffect): void {
    this.ctx.save();
    if (isOpacityEffect(effect)) {
      this.ctx.globalAlpha = effect.opacity;
    }

    if (isTransformEffect(effect)) {
      this.ctx.translate(effect.offsetX, effect.offsetY);
      this.ctx.transform(
        effect.matrix[0],
        effect.matrix[1],
        effect.matrix[2],
        effect.matrix[3],
        effect.matrix[4],
        effect.matrix[5]
      );
      this.ctx.translate(-effect.offsetX, -effect.offsetY);
    }

    if (isClipEffect(effect)) {
      this.path(effect.path);
      this.ctx.clip();
    }

    this._activeEffects.push(effect);
  }

  popEffect(): void {
    this._activeEffects.pop();
    this.ctx.restore();
  }

  async renderStack(stack: StackingContext): Promise<void> {
    const { styles } = stack.element.container;
    if (styles.isVisible()) {
      await this.renderStackContent(stack);
    }
  }

  async renderNode(paint: ElementPaint): Promise<void> {
    if (contains(paint.container.flags, FLAGS.DEBUG_RENDER)) {
      debugger;
    }

    if (paint.container.styles.isVisible()) {
      await this.renderNodeBackgroundAndBorders(paint);
      await this.renderNodeContent(paint);
    }
  }

  renderTextWithLetterSpacing(text: TextBounds, letterSpacing: number, baseline: number): void {
    if (letterSpacing === 0) {
      this.ctx.fillText(text.text, text.bounds.left, text.bounds.top + baseline);
    } else {
      const letters = segmentGraphemes(text.text);
      letters.reduce((left, letter) => {
        this.ctx.fillText(letter, left, text.bounds.top + baseline);

        return left + this.ctx.measureText(letter).width;
      }, text.bounds.left);
    }
  }

  private createFontStyle(styles: CSSParsedDeclaration): string[] {
    const fontVariant = styles.fontVariant
      .filter((variant) => variant === 'normal' || variant === 'small-caps')
      .join('');
    const fontFamily = fixIOSSystemFonts(styles.fontFamily).join(', ');
    const fontSize = isDimensionToken(styles.fontSize)
      ? `${styles.fontSize.number}${styles.fontSize.unit}`
      : `${styles.fontSize.number}px`;

    return [
      [styles.fontStyle, fontVariant, styles.fontWeight, fontSize, fontFamily].join(' '),
      fontFamily,
      fontSize,
    ];
  }

  async renderTextNode(text: TextContainer, styles: CSSParsedDeclaration): Promise<void> {
    const [font, fontFamily, fontSize] = this.createFontStyle(styles);

    this.ctx.font = font!;

    this.ctx.direction = styles.direction === DIRECTION.RTL ? 'rtl' : 'ltr';
    this.ctx.textAlign = 'left';
    this.ctx.textBaseline = 'alphabetic';
    let { baseline, middle } = this.fontMetrics.getMetrics(fontFamily!, fontSize!);
    baseline = baseline - 8;
    const { paintOrder } = styles;

    text.textBounds.forEach((text) => {
      paintOrder.forEach((paintOrderLayer) => {
        switch (paintOrderLayer) {
          case PAINT_ORDER_LAYER.FILL: {
            this.ctx.fillStyle = asString(styles.color);
            this.renderTextWithLetterSpacing(text, styles.letterSpacing, baseline);
            const textShadows: TextShadow = styles.textShadow;

            if (textShadows.length && text.text.trim().length) {
              textShadows
                .slice(0)
                .reverse()
                .forEach((textShadow) => {
                  this.ctx.shadowColor = asString(textShadow.color);
                  this.ctx.shadowOffsetX = textShadow.offsetX.number * this.options.scale;
                  this.ctx.shadowOffsetY = textShadow.offsetY.number * this.options.scale;
                  this.ctx.shadowBlur = textShadow.blur.number;

                  this.renderTextWithLetterSpacing(text, styles.letterSpacing, baseline);
                });

              this.ctx.shadowColor = '';
              this.ctx.shadowOffsetX = 0;
              this.ctx.shadowOffsetY = 0;
              this.ctx.shadowBlur = 0;
            }

            if (styles.textDecorationLine.length) {
              this.ctx.fillStyle = asString(styles.textDecorationColor || styles.color);
              styles.textDecorationLine.forEach((textDecorationLine) => {
                switch (textDecorationLine) {
                  case TEXT_DECORATION_LINE.UNDERLINE:
                    // Draws a line at the baseline of the font
                    // TODO As some browsers display the line as more than 1px if the font-size is big,
                    // need to take that into account both in position and size
                    this.ctx.fillRect(
                      text.bounds.left,
                      Math.round(text.bounds.top + baseline),
                      text.bounds.width,
                      1
                    );

                    break;
                  case TEXT_DECORATION_LINE.OVERLINE:
                    this.ctx.fillRect(
                      text.bounds.left,
                      Math.round(text.bounds.top),
                      text.bounds.width,
                      1
                    );
                    break;
                  case TEXT_DECORATION_LINE.LINE_THROUGH:
                    // TODO try and find exact position for line-through
                    this.ctx.fillRect(
                      text.bounds.left,
                      Math.ceil(text.bounds.top + middle),
                      text.bounds.width,
                      1
                    );
                    break;
                }
              });
            }
            break;
          }
          case PAINT_ORDER_LAYER.STROKE:
            if (styles.webkitTextStrokeWidth && text.text.trim().length) {
              this.ctx.strokeStyle = asString(styles.webkitTextStrokeColor);
              this.ctx.lineWidth = styles.webkitTextStrokeWidth;
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              this.ctx.lineJoin = (window as any).chrome ? 'miter' : 'round';
              this.ctx.strokeText(text.text, text.bounds.left, text.bounds.top + baseline);
            }
            this.ctx.strokeStyle = '';
            this.ctx.lineWidth = 0;
            this.ctx.lineJoin = 'miter';
            break;
        }
      });
    });
  }

  renderReplacedElement(
    container: ReplacedElementContainer,
    curves: BoundCurves,
    image: HTMLImageElement | HTMLCanvasElement
  ): void {
    if (image && container.intrinsicWidth > 0 && container.intrinsicHeight > 0) {
      const box = contentBox(container);
      const path = calculatePaddingBoxPath(curves);
      this.path(path);
      this.ctx.save();
      this.ctx.clip();
      this.ctx.drawImage(
        image,
        0,
        0,
        container.intrinsicWidth,
        container.intrinsicHeight,
        box.left,
        box.top,
        box.width,
        box.height
      );
      this.ctx.restore();
    }
  }

  async renderNodeContent(paint: ElementPaint): Promise<void> {
    this.applyEffects(paint.getEffects(EffectTarget.CONTENT));
    const { container } = paint;
    const { curves } = paint;
    const { styles } = container;
    for (const child of container.textNodes) {
      await this.renderTextNode(child, styles);
    }

    if (container instanceof ImageElementContainer) {
      try {
        const image = await this.context.cache.match(container.src);
        this.renderReplacedElement(container, curves, image);
      } catch (e) {
        this.context.logger.error(`Error loading image ${container.src}`);
      }
    }

    if (container instanceof CanvasElementContainer) {
      this.renderReplacedElement(container, curves, container.canvas);
    }

    if (container instanceof SVGElementContainer) {
      try {
        const image = await this.context.cache.match(container.svg);
        this.renderReplacedElement(container, curves, image);
      } catch (e) {
        this.context.logger.error(`Error loading svg ${container.svg.substring(0, 255)}`);
      }
    }
    if (container instanceof IFrameElementContainer && container.tree) {
      const iframeRenderer = new CanvasRenderer(this.context, {
        scale: this.options.scale,
        backgroundColor: container.backgroundColor,
        x: 0,
        y: 0,
        width: container.width,
        height: container.height,
      });

      const canvas = await iframeRenderer.render(container.tree);
      if (container.width && container.height) {
        this.ctx.drawImage(
          canvas,
          0,
          0,
          container.width,
          container.height,
          container.bounds.left,
          container.bounds.top,
          container.bounds.width,
          container.bounds.height
        );
      }
    }

    if (container instanceof InputElementContainer) {
      const size = Math.min(container.bounds.width, container.bounds.height);

      if (container.type === CHECKBOX) {
        if (container.checked) {
          this.ctx.save();
          this.path([
            new Vector(container.bounds.left + size * 0.39363, container.bounds.top + size * 0.79),
            new Vector(container.bounds.left + size * 0.16, container.bounds.top + size * 0.5549),
            new Vector(
              container.bounds.left + size * 0.27347,
              container.bounds.top + size * 0.44071
            ),
            new Vector(
              container.bounds.left + size * 0.39694,
              container.bounds.top + size * 0.5649
            ),
            new Vector(container.bounds.left + size * 0.72983, container.bounds.top + size * 0.23),
            new Vector(container.bounds.left + size * 0.84, container.bounds.top + size * 0.34085),
            new Vector(container.bounds.left + size * 0.39363, container.bounds.top + size * 0.79),
          ]);

          this.ctx.fillStyle = asString(INPUT_COLOR);
          this.ctx.fill();
          this.ctx.restore();
        }
      } else if (container.type === RADIO) {
        if (container.checked) {
          this.ctx.save();
          this.ctx.beginPath();
          this.ctx.arc(
            container.bounds.left + size / 2,
            container.bounds.top + size / 2,
            size / 4,
            0,
            Math.PI * 2,
            true
          );
          this.ctx.fillStyle = asString(INPUT_COLOR);
          this.ctx.fill();
          this.ctx.restore();
        }
      }
    }

    if (isTextInputElement(container) && container.value.length) {
      const [fontFamily, fontSize] = this.createFontStyle(styles);
      const { baseline } = this.fontMetrics.getMetrics(fontFamily!, fontSize!);

      this.ctx.font = fontFamily!;
      this.ctx.fillStyle = asString(styles.color);

      this.ctx.textBaseline = 'alphabetic';
      this.ctx.textAlign = canvasTextAlign(container.styles.textAlign);

      const bounds = contentBox(container);

      let x = 0;

      switch (container.styles.textAlign) {
        case TEXT_ALIGN.CENTER:
          x += bounds.width / 2;
          break;
        case TEXT_ALIGN.RIGHT:
          x += bounds.width;
          break;
      }

      const textBounds = bounds.add(x, 0, 0, -bounds.height / 2 + 1);

      this.ctx.save();
      this.path([
        new Vector(bounds.left, bounds.top),
        new Vector(bounds.left + bounds.width, bounds.top),
        new Vector(bounds.left + bounds.width, bounds.top + bounds.height),
        new Vector(bounds.left, bounds.top + bounds.height),
      ]);

      this.ctx.clip();
      this.renderTextWithLetterSpacing(
        new TextBounds(container.value, textBounds),
        styles.letterSpacing,
        baseline
      );
      this.ctx.restore();
      this.ctx.textBaseline = 'alphabetic';
      this.ctx.textAlign = 'left';
    }

    if (contains(container.styles.display, DISPLAY.LIST_ITEM)) {
      if (container.styles.listStyleImage !== null) {
        const img = container.styles.listStyleImage;
        if (img.type === CSSImageType.URL) {
          let image;
          const { url } = img as CSSURLImage;
          try {
            image = await this.context.cache.match(url);
            this.ctx.drawImage(
              image,
              container.bounds.left - (image.width + 10),
              container.bounds.top
            );
          } catch (e) {
            this.context.logger.error(`Error loading list-style-image ${url}`);
          }
        }
      } else if (paint.listValue && container.styles.listStyleType !== LIST_STYLE_TYPE.NONE) {
        const [fontFamily] = this.createFontStyle(styles);

        this.ctx.font = fontFamily!;
        this.ctx.fillStyle = asString(styles.color);

        this.ctx.textBaseline = 'middle';
        this.ctx.textAlign = 'right';
        const bounds = new Bounds(
          container.bounds.left,
          container.bounds.top +
            getAbsoluteValue(container.styles.paddingTop, container.bounds.width),
          container.bounds.width,
          computeLineHeight(styles.lineHeight, styles.fontSize.number) / 2 + 1
        );

        this.renderTextWithLetterSpacing(
          new TextBounds(paint.listValue, bounds),
          styles.letterSpacing,
          computeLineHeight(styles.lineHeight, styles.fontSize.number) / 2 + 2
        );
        this.ctx.textBaseline = 'bottom';
        this.ctx.textAlign = 'left';
      }
    }
  }

  async renderStackContent(stack: StackingContext): Promise<void> {
    if (contains(stack.element.container.flags, FLAGS.DEBUG_RENDER)) {
      debugger;
    }
    // https://www.w3.org/TR/css-position-3/#painting-order
    // 1. the background and borders of the element forming the stacking context.
    await this.renderNodeBackgroundAndBorders(stack.element);
    // 2. the child stacking contexts with negative stack levels (most negative first).
    for (const child of stack.negativeZIndex) {
      await this.renderStack(child);
    }
    // 3. For all its in-flow, non-positioned, block-level descendants in tree order:
    await this.renderNodeContent(stack.element);

    for (const child of stack.nonInlineLevel) {
      await this.renderNode(child);
    }
    // 4. All non-positioned floating descendants, in tree order. For each one of these,
    // treat the element as if it created a new stacking context, but any positioned descendants and descendants
    // which actually create a new stacking context should be considered part of the parent stacking context,
    // not this new one.
    for (const child of stack.nonPositionedFloats) {
      await this.renderStack(child);
    }
    // 5. the in-flow, inline-level, non-positioned descendants, including inline tables and inline blocks.
    for (const child of stack.nonPositionedInlineLevel) {
      await this.renderStack(child);
    }
    for (const child of stack.inlineLevel) {
      await this.renderNode(child);
    }
    // 6. All positioned, opacity or transform descendants, in tree order that fall into the following categories:
    //  All positioned descendants with 'z-index: auto' or 'z-index: 0', in tree order.
    //  For those with 'z-index: auto', treat the element as if it created a new stacking context,
    //  but any positioned descendants and descendants which actually create a new stacking context should be
    //  considered part of the parent stacking context, not this new one. For those with 'z-index: 0',
    //  treat the stacking context generated atomically.
    //
    //  All opacity descendants with opacity less than 1
    //
    //  All transform descendants with transform other than none
    for (const child of stack.zeroOrAutoZIndexOrTransformedOrOpacity) {
      await this.renderStack(child);
    }
    // 7. Stacking contexts formed by positioned descendants with z-indices greater than or equal to 1 in z-index
    // order (smallest first) then tree order.
    for (const child of stack.positiveZIndex) {
      await this.renderStack(child);
    }
  }

  mask(paths: Path[]): void {
    this.ctx.beginPath();
    this.ctx.moveTo(0, 0);
    this.ctx.lineTo(this.canvas.width, 0);
    this.ctx.lineTo(this.canvas.width, this.canvas.height);
    this.ctx.lineTo(0, this.canvas.height);
    this.ctx.lineTo(0, 0);
    this.formatPath(paths.slice(0).reverse());
    this.ctx.closePath();
  }

  path(paths: Path[]): void {
    this.ctx.beginPath();
    this.formatPath(paths);
    this.ctx.closePath();
  }

  formatPath(paths: Path[]): void {
    paths.forEach((point, index) => {
      const start: Vector = isBezierCurve(point) ? point.start : point;
      if (index === 0) {
        this.ctx.moveTo(start.x, start.y);
      } else {
        this.ctx.lineTo(start.x, start.y);
      }

      if (isBezierCurve(point)) {
        this.ctx.bezierCurveTo(
          point.startControl.x,
          point.startControl.y,
          point.endControl.x,
          point.endControl.y,
          point.end.x,
          point.end.y
        );
      }
    });
  }

  renderRepeat(
    path: Path[],
    pattern: CanvasPattern | CanvasGradient,
    offsetX: number,
    offsetY: number
  ): void {
    this.path(path);
    this.ctx.fillStyle = pattern;
    this.ctx.translate(offsetX, offsetY);
    this.ctx.fill();
    this.ctx.translate(-offsetX, -offsetY);
  }

  resizeImage(
    image: HTMLImageElement,
    width: number,
    height: number
  ): HTMLCanvasElement | HTMLImageElement {
    if (image.width === width && image.height === height) {
      return image;
    }

    const ownerDocument = this.canvas.ownerDocument ?? document;
    const canvas = ownerDocument.createElement('canvas');
    canvas.width = Math.max(1, width);
    canvas.height = Math.max(1, height);
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, width, height);
    return canvas;
  }

  async renderBackgroundImage(container: ElementContainer): Promise<void> {
    let index = container.styles.backgroundImage.length - 1;
    for (const backgroundImage of container.styles.backgroundImage.slice(0).reverse()) {
      if (backgroundImage.type === CSSImageType.URL) {
        let image;
        const { url } = backgroundImage as CSSURLImage;
        try {
          image = await this.context.cache.match(url);
        } catch (e) {
          this.context.logger.error(`Error loading background-image ${url}`);
        }

        if (image) {
          const [path, x, y, width, height] = calculateBackgroundRendering(container, index, [
            image.width,
            image.height,
            image.width / image.height,
          ]);
          const pattern = this.ctx.createPattern(
            this.resizeImage(image, width, height),
            'repeat'
          ) as CanvasPattern;
          this.renderRepeat(path, pattern, x, y);
        }
      } else if (isLinearGradient(backgroundImage)) {
        //NOTE: 修复了思维导图文本高亮时，导出图片ui issue
        const bounds = container.textNodes.map((v) => v.textBounds);
        let all: UnprocessedGradientColorStop[] = [];
        if (bounds.length > 0) {
          // let totalWidth = 0;
          // let height = 0;
          // const widthArray: number[] = [];
          // bounds.forEach((bs) => {
          //   bs.forEach((b) => {
          //     totalWidth += b.bounds.width;
          //     widthArray.push(b.bounds.width);
          //     height = b.bounds.height;
          //   });
          // });
          // let firstColor: Color | undefined;
          // let secondColor: Color | undefined;
          // //FIXME: workaround
          // if (backgroundImage.stops.length === 2) {
          //   firstColor = backgroundImage.stops[0]?.color;
          //   secondColor = backgroundImage.stops[1]?.color;
          // }
          // if (secondColor && firstColor) {
          //   const baseColor = (secondColor - firstColor) / totalWidth;
          //   let preTotalWidth = 0;
          //   all = widthArray.map((width) => {
          //     const [lineLength] = calculateGradientDirection(backgroundImage.angle, width, height);
          //     preTotalWidth += width;
          //     const color = Math.round(firstColor + baseColor * preTotalWidth);
          //     return {
          //       color,
          //       stop: {
          //         flags: 4,
          //         type: 16,
          //         number: lineLength,
          //       },
          //     };
          //   });
          //   all.unshift({
          //     color: firstColor,
          //     stop: {
          //       flags: 4,
          //       type: 16,
          //       number: 0,
          //     },
          //   });
          // }

          bounds.forEach((bs) => {
            bs.forEach((b, index) => {
              const x = b.bounds.left;
              const y = b.bounds.top;
              const width = b.bounds.width;
              const height = b.bounds.height;
              const canvas = document.createElement('canvas');
              canvas.width = width;
              canvas.height = height;
              const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
              const gradient = ctx.createLinearGradient(x, y, x + width, y + height);
              const [lineLength, x0, x1, y0, y1] = calculateGradientDirection(
                backgroundImage.angle,
                width,
                height
              );
              //FIXME 搞不了，太复杂了，没时间去试验
              processColorStops(backgroundImage.stops, lineLength).forEach((colorStop) => {
                return gradient.addColorStop(colorStop.stop, asString(colorStop.color));
              });

              ctx.fillStyle = gradient;
              ctx.fillRect(0, 0, width, height);
              if (width > 0 && height > 0) {
                const path = [
                  new Vector(x, y),
                  new Vector(x + width, y),
                  new Vector(x + width, y + height),
                  new Vector(x, y + height),
                ];
                const pattern = this.ctx.createPattern(canvas, 'repeat') as CanvasPattern;
                this.renderRepeat(path, pattern, x, y);
              }
            });
          });
        } else {
          const [path, x, y, width, height] = calculateBackgroundRendering(container, index, [
            null,
            null,
            null,
          ]);
          const [lineLength, x0, x1, y0, y1] = calculateGradientDirection(
            backgroundImage.angle,
            width,
            height
          );

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
          const gradient = ctx.createLinearGradient(x0, y0, x1, y1);

          processColorStops(backgroundImage.stops, lineLength).forEach((colorStop) =>
            gradient.addColorStop(colorStop.stop, asString(colorStop.color))
          );

          ctx.fillStyle = gradient;
          ctx.fillRect(0, 0, width, height);
          if (width > 0 && height > 0) {
            const pattern = this.ctx.createPattern(canvas, 'repeat') as CanvasPattern;
            this.renderRepeat(path, pattern, x, y);
          }
        }
      } else if (isRadialGradient(backgroundImage)) {
        const [path, left, top, width, height] = calculateBackgroundRendering(container, index, [
          null,
          null,
          null,
        ]);
        const position =
          backgroundImage.position.length === 0 ? [FIFTY_PERCENT] : backgroundImage.position;
        const x = getAbsoluteValue(position[0]!, width);
        const y = getAbsoluteValue(position[position.length - 1]!, height);

        const [rx, ry] = calculateRadius(backgroundImage, x, y, width, height);
        if (rx > 0 && ry > 0) {
          const radialGradient = this.ctx.createRadialGradient(
            left + x,
            top + y,
            0,
            left + x,
            top + y,
            rx
          );

          processColorStops(backgroundImage.stops, rx * 2).forEach((colorStop) =>
            radialGradient.addColorStop(colorStop.stop, asString(colorStop.color))
          );

          this.path(path);
          this.ctx.fillStyle = radialGradient;
          if (rx !== ry) {
            // transforms for elliptical radial gradient
            const midX = container.bounds.left + 0.5 * container.bounds.width;
            const midY = container.bounds.top + 0.5 * container.bounds.height;
            const f = ry / rx;
            const invF = 1 / f;

            this.ctx.save();
            this.ctx.translate(midX, midY);
            this.ctx.transform(1, 0, 0, f, 0, 0);
            this.ctx.translate(-midX, -midY);

            this.ctx.fillRect(left, invF * (top - midY) + midY, width, height * invF);
            this.ctx.restore();
          } else {
            this.ctx.fill();
          }
        }
      }
      index--;
    }
  }

  async renderSolidBorder(color: Color, side: number, curvePoints: BoundCurves): Promise<void> {
    this.path(parsePathForBorder(curvePoints, side));
    this.ctx.fillStyle = asString(color);
    this.ctx.fill();
  }

  async renderDoubleBorder(
    color: Color,
    width: number,
    side: number,
    curvePoints: BoundCurves
  ): Promise<void> {
    if (width < 3) {
      await this.renderSolidBorder(color, side, curvePoints);
      return;
    }

    const outerPaths = parsePathForBorderDoubleOuter(curvePoints, side);
    this.path(outerPaths);
    this.ctx.fillStyle = asString(color);
    this.ctx.fill();
    const innerPaths = parsePathForBorderDoubleInner(curvePoints, side);
    this.path(innerPaths);
    this.ctx.fill();
  }

  async renderNodeBackgroundAndBorders(paint: ElementPaint): Promise<void> {
    this.applyEffects(paint.getEffects(EffectTarget.BACKGROUND_BORDERS));
    const { styles } = paint.container;
    const hasBackground = !isTransparent(styles.backgroundColor) || styles.backgroundImage.length;

    const borders = [
      { style: styles.borderTopStyle, color: styles.borderTopColor, width: styles.borderTopWidth },
      {
        style: styles.borderRightStyle,
        color: styles.borderRightColor,
        width: styles.borderRightWidth,
      },
      {
        style: styles.borderBottomStyle,
        color: styles.borderBottomColor,
        width: styles.borderBottomWidth,
      },
      {
        style: styles.borderLeftStyle,
        color: styles.borderLeftColor,
        width: styles.borderLeftWidth,
      },
    ];

    const backgroundPaintingArea = calculateBackgroundCurvedPaintingArea(
      getBackgroundValueForIndex(styles.backgroundClip, 0),
      paint.curves
    );

    if (hasBackground || styles.boxShadow.length) {
      this.ctx.save();
      this.path(backgroundPaintingArea);
      this.ctx.clip();

      if (!isTransparent(styles.backgroundColor)) {
        this.ctx.fillStyle = asString(styles.backgroundColor);
        // this.ctx.fill();
        if (styles.display === DISPLAY.INLINE) {
          for (const textNode of paint.container.textNodes) {
            for (const textBound of textNode.textBounds) {
              this.ctx.fillRect(
                textBound.bounds.left,
                textBound.bounds.top,
                textBound.bounds.width,
                textBound.bounds.height
              );
            }
          }
        } else {
          this.ctx.fill();
        }
      }

      await this.renderBackgroundImage(paint.container);

      this.ctx.restore();

      styles.boxShadow
        .slice(0)
        .reverse()
        .forEach((shadow) => {
          this.ctx.save();
          const borderBoxArea = calculateBorderBoxPath(paint.curves);
          const maskOffset = shadow.inset ? 0 : MASK_OFFSET;
          const shadowPaintingArea = transformPath(
            borderBoxArea,
            -maskOffset + (shadow.inset ? 1 : -1) * shadow.spread.number,
            (shadow.inset ? 1 : -1) * shadow.spread.number,
            shadow.spread.number * (shadow.inset ? -2 : 2),
            shadow.spread.number * (shadow.inset ? -2 : 2)
          );

          if (shadow.inset) {
            this.path(borderBoxArea);
            this.ctx.clip();
            this.mask(shadowPaintingArea);
          } else {
            this.mask(borderBoxArea);
            this.ctx.clip();
            this.path(shadowPaintingArea);
          }

          this.ctx.shadowOffsetX = shadow.offsetX.number + maskOffset;
          this.ctx.shadowOffsetY = shadow.offsetY.number;
          this.ctx.shadowColor = asString(shadow.color);
          this.ctx.shadowBlur = shadow.blur.number;
          this.ctx.fillStyle = shadow.inset ? asString(shadow.color) : 'rgba(0,0,0,1)';

          this.ctx.fill();
          this.ctx.restore();
        });
    }

    let side = 0;
    for (const border of borders) {
      if (border.style !== BORDER_STYLE.NONE && !isTransparent(border.color) && border.width > 0) {
        if (border.style === BORDER_STYLE.DASHED) {
          await this.renderDashedDottedBorder(
            border.color,
            border.width,
            side,
            paint.curves,
            BORDER_STYLE.DASHED
          );
        } else if (border.style === BORDER_STYLE.DOTTED) {
          await this.renderDashedDottedBorder(
            border.color,
            border.width,
            side,
            paint.curves,
            BORDER_STYLE.DOTTED
          );
        } else if (border.style === BORDER_STYLE.DOUBLE) {
          await this.renderDoubleBorder(border.color, border.width, side, paint.curves);
        } else {
          await this.renderSolidBorder(border.color, side, paint.curves);
        }
      }
      side++;
    }
  }

  async renderDashedDottedBorder(
    color: Color,
    width: number,
    side: number,
    curvePoints: BoundCurves,
    style: BORDER_STYLE
  ): Promise<void> {
    this.ctx.save();

    const strokePaths = parsePathForBorderStroke(curvePoints, side);
    const boxPaths = parsePathForBorder(curvePoints, side);

    if (style === BORDER_STYLE.DASHED) {
      this.path(boxPaths);
      this.ctx.clip();
    }

    let startX, startY, endX, endY;
    if (isBezierCurve(boxPaths[0]!)) {
      startX = (boxPaths[0] as BezierCurve).start.x;
      startY = (boxPaths[0] as BezierCurve).start.y;
    } else {
      startX = (boxPaths[0] as Vector).x;
      startY = (boxPaths[0] as Vector).y;
    }
    if (isBezierCurve(boxPaths[1]!)) {
      endX = (boxPaths[1] as BezierCurve).end.x;
      endY = (boxPaths[1] as BezierCurve).end.y;
    } else {
      endX = (boxPaths[1] as Vector).x;
      endY = (boxPaths[1] as Vector).y;
    }

    let length;
    if (side === 0 || side === 2) {
      length = Math.abs(startX - endX);
    } else {
      length = Math.abs(startY - endY);
    }

    this.ctx.beginPath();
    if (style === BORDER_STYLE.DOTTED) {
      this.formatPath(strokePaths);
    } else {
      this.formatPath(boxPaths.slice(0, 2));
    }

    let dashLength = width < 3 ? width * 3 : width * 2;
    let spaceLength = width < 3 ? width * 2 : width;
    if (style === BORDER_STYLE.DOTTED) {
      dashLength = width;
      spaceLength = width;
    }

    let useLineDash = true;
    if (length <= dashLength * 2) {
      useLineDash = false;
    } else if (length <= dashLength * 2 + spaceLength) {
      const multiplier = length / (2 * dashLength + spaceLength);
      dashLength *= multiplier;
      spaceLength *= multiplier;
    } else {
      const numberOfDashes = Math.floor((length + spaceLength) / (dashLength + spaceLength));
      const minSpace = (length - numberOfDashes * dashLength) / (numberOfDashes - 1);
      const maxSpace = (length - (numberOfDashes + 1) * dashLength) / numberOfDashes;
      spaceLength =
        maxSpace <= 0 || Math.abs(spaceLength - minSpace) < Math.abs(spaceLength - maxSpace)
          ? minSpace
          : maxSpace;
    }

    if (useLineDash) {
      if (style === BORDER_STYLE.DOTTED) {
        this.ctx.setLineDash([0, dashLength + spaceLength]);
      } else {
        this.ctx.setLineDash([dashLength, spaceLength]);
      }
    }

    if (style === BORDER_STYLE.DOTTED) {
      this.ctx.lineCap = 'round';
      this.ctx.lineWidth = width;
    } else {
      this.ctx.lineWidth = width * 2 + 1.1;
    }
    this.ctx.strokeStyle = asString(color);
    this.ctx.stroke();
    this.ctx.setLineDash([]);

    // dashed round edge gap
    if (style === BORDER_STYLE.DASHED) {
      if (isBezierCurve(boxPaths[0]!)) {
        const path1 = boxPaths[3] as BezierCurve;
        const path2 = boxPaths[0] as BezierCurve;
        this.ctx.beginPath();
        this.formatPath([
          new Vector(path1.end.x, path1.end.y),
          new Vector(path2.start.x, path2.start.y),
        ]);
        this.ctx.stroke();
      }
      if (isBezierCurve(boxPaths[1]!)) {
        const path1 = boxPaths[1] as BezierCurve;
        const path2 = boxPaths[2] as BezierCurve;
        this.ctx.beginPath();
        this.formatPath([
          new Vector(path1.end.x, path1.end.y),
          new Vector(path2.start.x, path2.start.y),
        ]);
        this.ctx.stroke();
      }
    }

    this.ctx.restore();
  }

  async render(element: ElementContainer): Promise<HTMLCanvasElement> {
    if (this.options.backgroundColor) {
      this.ctx.fillStyle = asString(this.options.backgroundColor);
      this.ctx.fillRect(this.options.x, this.options.y, this.options.width, this.options.height);
    }

    const stack = parseStackingContexts(element);

    await this.renderStack(stack);
    this.applyEffects([]);
    return this.canvas;
  }
}

const isTextInputElement = (
  container: ElementContainer
): container is InputElementContainer | TextareaElementContainer | SelectElementContainer => {
  if (container instanceof TextareaElementContainer) {
    return true;
  } else if (container instanceof SelectElementContainer) {
    return true;
  } else if (
    container instanceof InputElementContainer &&
    container.type !== RADIO &&
    container.type !== CHECKBOX
  ) {
    return true;
  }
  return false;
};

const calculateBackgroundCurvedPaintingArea = (
  clip: BACKGROUND_CLIP,
  curves: BoundCurves
): Path[] => {
  switch (clip) {
    case BACKGROUND_CLIP.BORDER_BOX:
      return calculateBorderBoxPath(curves);
    case BACKGROUND_CLIP.CONTENT_BOX:
      return calculateContentBoxPath(curves);
    case BACKGROUND_CLIP.PADDING_BOX:
    default:
      return calculatePaddingBoxPath(curves);
  }
};

const canvasTextAlign = (textAlign: TEXT_ALIGN): CanvasTextAlign => {
  switch (textAlign) {
    case TEXT_ALIGN.CENTER:
      return 'center';
    case TEXT_ALIGN.RIGHT:
      return 'right';
    case TEXT_ALIGN.LEFT:
    default:
      return 'left';
  }
};

// see https://github.com/niklasvh/html2canvas/pull/2645
const iOSBrokenFonts = ['-apple-system', 'system-ui'];

const fixIOSSystemFonts = (fontFamilies: string[]): string[] => {
  return /iPhone OS 15_(0|1)/.test(window.navigator.userAgent)
    ? fontFamilies.filter((fontFamily) => iOSBrokenFonts.indexOf(fontFamily) === -1)
    : fontFamilies;
};
