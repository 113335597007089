import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { memo } from 'react';
import { $blockId, BLOCK_SCOPE } from 'src/editor/editor/uikit/block-states';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { usePickBlock } from 'src/utils/pick-block';
import { getNodeElement } from './node/all-node-renderer';
import { HideChildrenBlockList } from './utils/const';

interface Props {
  id: string;
  level: number;
}

export const MindNodeRenderer: FC<Props> = memo((props) => {
  // 有个 bug,切勿关闭useRedux || bugfix@kcsx 2024-7-16
  // https://flowus.cn/2cd8679c-ff8a-46e4-a1c0-65cc8d3288ae
  const block = usePickBlock(props.id, ['subNodes', 'data', 'hidden'], []);
  const Element = getNodeElement(block?.type, props.level);
  const [expand] = useExpand(props.id, { defaultOpen: true });
  if (!block) return null;
  if (!Element) return null;
  if (block.hidden) return null;
  return (
    // 为了解决这个bug才加的Provider，https://flowus.cn/37632f03-1f78-4983-b130-fac372f8ecf0
    // inline page判断是不是引用页面的逻辑我不太敢改，只能按它内部逻辑加上这个。
    <JotaiProvider scope={BLOCK_SCOPE} initialValues={[[$blockId, props.id]]}>
      <Element id={props.id} level={props.level}></Element>
      {expand &&
        !(HideChildrenBlockList.includes(block.type) && props.level !== 0) &&
        block.subNodes.map((subId) => {
          return <MindNodeRenderer id={subId} key={subId} level={props.level + 1} />;
        })}
    </JotaiProvider>
  );
});
