import { debounce, throttle } from 'lodash-es';
import { useEffect } from 'react';
import { deepEqual } from '../utils';
import { globalResizeObserver } from '../utils/global-resize-observer';
import { useStateRef } from './react-utils';

interface Options {
  wait?: number;
  type?: 'width' | 'height' | 'all';
  mode?: 'debounce' | 'throttle';
  enable?: boolean;
  leading?: boolean;
  trailing?: boolean;
}

export const useDebounceElementSize = (element?: Element | null, opt?: Options) => {
  const {
    wait = 500,
    type = 'all',
    mode = 'debounce',
    enable = true,
    leading = false,
    trailing = true,
  } = opt || {};
  const [size, setSize, sizeRef] = useStateRef({
    width: element?.getBoundingClientRect().width || 0,
    height: element?.getBoundingClientRect().height || 0,
  });

  useEffect(() => {
    if (!element) return;
    const fn = mode === 'debounce' ? debounce : throttle;
    const resize = fn(
      (entry: ResizeObserverEntry) => {
        const rect = entry.target.getBoundingClientRect();
        if (type === 'all') {
          setSize({
            width: rect.width,
            height: rect.height,
          });
        } else {
          const newValue = {
            ...sizeRef.current,
            [type]: rect[type],
          };

          if (!deepEqual(sizeRef.current, newValue)) {
            setSize(newValue);
          }
        }
      },
      wait,
      {
        leading,
        trailing,
      }
    );

    const unobserve = globalResizeObserver.observe(element, resize);

    if (!enable) {
      unobserve();
    }

    return () => {
      unobserve();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, mode, type, wait, enable, leading, trailing]);

  return size;
};
