import { useAiMembers } from 'src/services/app';
import { useCurrentSpace } from '../space';
import { useCurrentUser } from '../user';
import { useEnableAIByCommon } from '@flowus/common/block/use-enable-ai';

export const useEnableAI = () => {
  const currentSpace = useCurrentSpace();
  const user = useCurrentUser();
  const members = useAiMembers();

  return useEnableAIByCommon({ members, space: currentSpace, user });
};
