import { BindWeChat } from '@flowus/login/component/web/bind-wechat';
import { useBindAccount } from '@flowus/login/hook/use-bind-account';
import { UserAuthorizationType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { BindEmail } from 'src/components/login-uikit/bind-email';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { $currentUserCache } from 'src/services/user/current-user';
import { isFlowUsApp } from 'src/utils/electron-util';
import { useOpenUnbindDialog } from './use-open-unbind-dialog';
import { lazyLoad } from '@flowus/login/utils';

const { WechatLoginElectron } = lazyLoad(
  () => import('@flowus/login/component/web/wechat-login-electron')
);

const WebChat = isFlowUsApp.check ? WechatLoginElectron : BindWeChat;

export const useOpenBindAccountDialog = () => {
  const openModal = useOpenModal();
  const openUnbindDialog = useOpenUnbindDialog();
  const fetchMe = useFetchMe();
  const { bindWechat } = useBindAccount();
  return useCallback(
    (e: React.MouseEvent<HTMLElement>, type: UserAuthorizationType) => {
      const user = $currentUserCache;
      if (
        (type === UserAuthorizationType.EMAIL && user.ext?.email) ||
        (type === UserAuthorizationType.WECHAT && user.ext?.wechat) ||
        (type === UserAuthorizationType.QQ && user.ext?.qq) ||
        (type === UserAuthorizationType.APPLE && user.ext?.apple)
      ) {
        // 解除绑定
        openUnbindDialog(type);
        return;
      }
      const bindSuccess = async () => {
        await fetchMe();
        message.success('绑定成功');
      };
      if (type === UserAuthorizationType.EMAIL) {
        openModal.modal({
          content: ({ onCloseModal }) => {
            return (
              <BindEmail
                onSuccess={async () => {
                  void bindSuccess();
                  onCloseModal();
                }}
              />
            );
          },
        });
      } else if (type === UserAuthorizationType.WECHAT) {
        openModal.modal({
          content: ({ onCloseModal }) => {
            return (
              <div className="w-[400px]">
                <WebChat
                  onAuth={async (code: string) => {
                    const res = await bindWechat($currentUserCache.uuid, code);
                    if (res.code === 200) {
                      void bindSuccess();
                      // @ts-ignore ignore
                      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    } else if (res.code === 1009) {
                      message.error('该微信已注册');
                    } else {
                      message.error(res.msg);
                    }
                    onCloseModal();
                  }}
                  switchViewType={() => {}}
                />
              </div>
            );
          },
        });
      } else if (type === UserAuthorizationType.QQ || type === UserAuthorizationType.APPLE) {
        message.warning('请在移动端进行绑定');
      }
    },
    [bindWechat, fetchMe, openModal, openUnbindDialog]
  );
};
