import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { useApiState } from '@flowus/common/hooks/use-api-state';
import { fetchWares } from './fetch-wares';
import { useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import { WareImage } from './ware-image';
import { Button } from '@flowus/common/components';
import type { Wares } from './type';
import { useExchangeScore } from './use-exchange-score';
import { Tooltip } from '@flowus/common/tooltip';
import { useUserBalance } from 'src/services/user/user-balance';
import { WareRecord } from './ware-record';

interface Props {
  onSelectWares: (wares: Wares) => void;
}
export const WaresList: FC<Props> = (props) => {
  const openModal = useOpenModal();
  const { integralInfo } = useUserBalance();
  const exchangeScore = useExchangeScore();
  const [wares] = useApiState({
    getApi: fetchWares,
    defaultValue: [],
  });

  return (
    <div className="h-full overflow-hidden">
      <div className="flex items-center justify-between border-b">
        <div className="flex items-center py-2.5 space-x-1">
          <span className="text-t1-medium">{`积分兑换商城（当前积分:${integralInfo?.currentScore}）`}</span>
          <Tooltip popup="兑换后到我的卡券,或者升级付费时选取对应的优惠券">
            <Icon className="text-grey4" size="middle" name={'IcInviteHelp'} />
            <span className="text-t3 text-grey4">兑换方式</span>
          </Tooltip>
        </div>
        <div className="flex items-center space-x-1 text-t2">
          <span
            className="animate-click text-active_color"
            onClick={() => {
              openModal.modal({
                modalId: Modals.WARE_RECORD,
                content: () => <WareRecord />,
              });
            }}
          >
            兑换记录
          </span>
          <Icon
            className="animate-click"
            size="middle"
            name={'IcToastClose'}
            onClick={() => {
              openModal.closeModal(Modals.SCORE_SHOP);
            }}
          />
        </div>
      </div>
      <div className="grid gap-5 grid-cols-3 items-start justify-center overflow-auto h-full pt-5 pb-20">
        {wares?.map((v, index) => {
          return (
            <div
              className="animate-hover border border-grey7 rounded-md"
              key={index}
              onClick={() => {
                props.onSelectWares(v);
              }}
            >
              <WareImage blockId={v.recordId} ossName={v.ossName} className="h-[200px]" />
              <div className="py-2.5">
                <Tooltip popup={v.name}>
                  <div className="w-full text-ellipsis mt-2.5 text-t3-medium px-2.5">{v.name}</div>
                </Tooltip>
                <div className="mt-2 flex items-center justify-between px-2.5">
                  <div className="flex items-center">
                    <span className="text-t1-medium">{v.score}</span>
                    <span className="text-t4">积分</span>
                  </div>
                  <Button
                    className="h-6"
                    colorType="active"
                    onClick={(e) => {
                      e.stopPropagation();
                      void exchangeScore(v.waresId);
                    }}
                  >
                    兑换
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
