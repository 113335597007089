import { cx } from '@flowus/common/cx';
import { upperFirst } from 'lodash-es';
import type { FC, MouseEvent, ReactNode } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import type { ViewModeType } from 'src/redux/types';
import { ViewModel } from 'src/redux/types';

interface SelectViewBarProps {
  value?: ViewModeType;
  addonAfter?: ReactNode;
  onChange?: (status: ViewModeType) => void;
  small?: boolean;
  hiddenPreview?: boolean;
}
export const SelectViewBar: FC<SelectViewBarProps> = (props) => {
  const { value = ViewModel.normal, onChange, small, hiddenPreview } = props;

  const handleClick = (event: MouseEvent, status: ViewModeType) => {
    event.preventDefault();
    if (value === status) return;
    onChange?.(status);
  };

  const renderList = hiddenPreview
    ? [ViewModel.normal, ViewModel.card]
    : [ViewModel.normal, ViewModel.card, ViewModel.preview];

  const ButtonPopup = {
    [ViewModel.normal]: '标题视图',
    [ViewModel.card]: '卡片视图',
    [ViewModel.preview]: '预览视图',
  };

  return (
    <div className="flex items-center">
      {renderList.map((item) => (
        <IconUiSizeBox
          popup={ButtonPopup[item]}
          key={item}
          size={small ? 20 : 30}
          className={cx(
            'rounded animate-hover',
            value === item && 'bg-black_006',
            small && 'mx-0.5'
          )}
          onClick={(event) => handleClick(event, item)}
        >
          <Icon name={`IcMenuView${upperFirst(item)}` as IconName} size="middle" />
        </IconUiSizeBox>
      ))}
    </div>
  );
};
