import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React, { useMemo, useRef } from 'react';
import { Callout } from 'src/editor/component/call-out';
import { segmentsToText } from 'src/editor/utils/editor';
import { saveLastMarkBlockInfo } from 'src/editor/utils/mark-block-utils';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useLastWidthBeforeEditing } from '../hook/use-last-width-before-editing';
import { useMindNodePadding } from '../hook/use-mind-node-style';
import { getDefaultPlaceHolder } from '../utils/mind-node-util';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

/** 着重节点 */
export const CalloutNode: MindNodeElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['data'], ['segments']);
  const placeHolder = getDefaultPlaceHolder(props.level);
  const updateSegments = useThrottleUpdateSegments(props.id);
  const readonly = useReadonly(props.id);

  const isEmpty = useMemo(() => {
    return !segmentsToText(block?.data.segments);
  }, [block?.data.segments]);

  const containerRef = useRef<HTMLDivElement>(null);
  const lastWidth = useLastWidthBeforeEditing(props.id, containerRef, isEmpty, props.level);
  const { paddingRight } = useMindNodePadding(props.id, props.level);

  if (!block) return null;

  return (
    <NodeWrapper
      id={props.id}
      ref={containerRef}
      className="flex items-center"
      level={props.level}
      style={{
        paddingLeft: `4px`,
        minWidth: lastWidth,
      }}
    >
      <Callout
        childrenClassName="w-full"
        id={props.id}
        readonly={readonly}
        className="min-h-2.5 py-0"
        iconClassName="mt-0.5 disable-select"
        onChange={(icon) => {
          saveLastMarkBlockInfo({ icon });
        }}
      >
        <MindMapRichText
          placeholder={placeHolder}
          uuid={props.id}
          className="whitespace-pre-wrap break-words self-start"
          segments={block.data.segments}
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
          style={{
            paddingRight: `${paddingRight}px`,
          }}
        />
      </Callout>
    </NodeWrapper>
  );
});
