import type { CollectionViewDTO, CoverType } from '@next-space/fe-api-idl';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useStore } from 'react-redux';
import { useTransaction } from 'src/hooks/use-transaction';
import * as CollectionViewManager from 'src/redux/managers/collection-view';

/**
 * for bitable
 * 更新 cover type
 */
export const useUpdateCoverType = () => {
  const transaction = useTransaction();
  const { getState } = useStore();

  const updateCardSize = (viewId: string, value: CoverType, property: string | undefined) => {
    transaction(() => {
      const { collectionViews } = getState();
      const view = collectionViews[viewId];
      if (!view) return;

      const newFormat: CollectionViewDTO['format'] = {};

      // 确保 title 是可见的
      switch (view.type) {
        case CollectionViewType.BOARD: {
          newFormat.boardCoverType = value;
          newFormat.boardCoverProperty = property;
          break;
        }

        case CollectionViewType.GALLERY: {
          newFormat.galleryCoverType = value;
          newFormat.galleryCoverProperty = property;
          break;
        }
        default:
      }

      CollectionViewManager.update(viewId, {
        format: newFormat,
      });
    });
  };
  return useCallback(updateCardSize, [getState, transaction]);
};
