/**官网的登陆还要使用登陆模块的代码，login是独立的，不能引用其他packages的代码 */
export enum SearchParams {
  callbackPath = 'from',
  inviteCode = 'code',
  inviteTeam = 'team',
  page = 'page',
  inviteCodeByWeChat = 'invite_code',
  cooperate = 'cooperate',
  /** 创建团队空间内测码，已废弃 */
  teamApplyCode = 'teamApplyCode',
  promotionChannel = 'promotionChannel',
  /** 页面密码 */
  password = 'psw',
  // 快速注册，走的手机号
  quickRegister = 'quick-register',
  /** space_id*/
  spaceId = 'space_id',
  /** 需要打开的弹窗，比如新手任务，升级空间弹窗 */
  openDialog = 'open',
  /** 激活码 */
  exChangeCode = 'exChangeCode',
  /** block id */
  id = 'id',
  /** ossName */
  oss = 'oss',
  /** schemaId */
  schemaId = 'sid',
  /** 嵌入网页 */
  embed = 'embed',
  /**是否是订阅页面 */
  subscribe = 'subscribe',
  aiType = 'ai_type',
  //搜索用得到
  searchAncestorId = 'search_ancestor_id',
  //默认prompt
  defaultPrompt = 'default_prompt',
  openAI = 'open_ai',
  pageId = 'page_id',
  hint = 'hint',
}

export const INVITE_CODE_REQUIRED_TEXT = '请输入邀请码（必填）';
export const INVITE_CODE_TEXT = '请输入邀请码领取额外积分（选填）';

export const { VITE_PRODUCT_NAME } = import.meta.env;
