import type { SelectBlock } from 'src/redux/types';
/** 互相转换 */
export const selectedBlocksToIds = (selectBlocks: SelectBlock[]) => {
  return selectBlocks.map((v) => v.blockId);
};

export const idsToSelectedBlocks = (
  ids: string[] | string,
  viewId?: string,
  syncId?: string
): SelectBlock[] => {
  if (Array.isArray(ids)) {
    return ids.map((v) => {
      return { blockId: v, viewId, syncId };
    });
  }
  return [{ blockId: ids, viewId, syncId }];
};
