import { BlockType, SearchDocSortType } from '@next-space/fe-api-idl';
import { request } from 'src/common/request';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';

let cache: ReturnType<typeof request.infra.searchDocs> | undefined;
let lastUpdateTime = Date.now();
let lastSpaceId = '';
const setCache = (res: typeof cache, spaceId: string) => {
  cache = res;
  lastUpdateTime = Date.now();
  lastSpaceId = spaceId;
};
/**
 * 获取最近编辑过的多维表(10分钟缓存)
 */
export const getRecentlyUpdateCollection = async () => {
  const now = Date.now();
  const spaceId = getCurrentSpaceId();
  const interval = now - lastUpdateTime;
  if (interval > 10 * 60 * 1000 || spaceId !== lastSpaceId) {
    cache = undefined;
  } else if (cache) {
    return cache;
  }
  const res = request.infra.searchDocs(spaceId, {
    page: 1,
    perPage: 10,
    source: 'quickFind',
    query: '',
    sort: SearchDocSortType.LAST_EDITED_NEWEST,
    filters: {
      isTitleOnly: true,
      types: [BlockType.COLLECTION_VIEW, BlockType.COLLECTION_VIEW_PAGE],
    },
  });
  setCache(res, spaceId);
  return res;
};
