import { BlockType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import { Images } from 'src/image';
import { cache } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useSpacesSelector } from 'src/services/spaces/spaces';
import { $currentUserCache } from 'src/services/user/current-user';
import { getRefBlock } from 'src/utils/block-utils';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { isDeleted } from '../block/use-block-status';
import { useCurrentSpaceView } from '../space';
import type { Template, TemplateCategory, TemplateGroup } from './types';

export const useCustomTemplatesCategory = () => {
  const spaceView = useCurrentSpaceView();

  const customTemplates = useSpacesSelector((state) => {
    const spaceId = spaceView?.spaceId;
    if (spaceId) {
      return state[spaceId]?.customTemplates;
    }
  });
  const myTemplatesGroup: TemplateGroup = {
    name: '我创建的',
    templates: [],
  };
  const otherTemplatesGroup: TemplateGroup = {
    name: '分享给我的',
    templates: [],
  };

  customTemplates?.forEach((templateId) => {
    const template = getTemplateFromId(templateId);
    if (!template) return;

    const block = cache.blocks[templateId];
    let refBlock: NextBlock | undefined;
    if (
      block?.type === BlockType.REFERENCE_COLLECTION ||
      block?.type === BlockType.REFERENCE_COLLECTION_PAGE
    ) {
      refBlock = getRefBlock(templateId);
    }
    if (!block) return;

    if (
      (refBlock?.data.createdTemplateBy ?? block.data.createdTemplateBy) === $currentUserCache.uuid
    ) {
      myTemplatesGroup.templates.push(template);
    } else {
      otherTemplatesGroup.templates.push(template);
    }
  });

  const category: TemplateCategory = {
    name: '我的模板',
    subGroups: [myTemplatesGroup, otherTemplatesGroup],
  };

  return category;
};

export const getTemplateFromId = (templateId: string) => {
  if (isDeleted(templateId)) return;

  const block = cache.blocks[templateId];
  let refBlock: NextBlock | undefined;
  if (
    block?.type === BlockType.REFERENCE_COLLECTION ||
    block?.type === BlockType.REFERENCE_COLLECTION_PAGE
  ) {
    refBlock = getRefBlock(templateId);
  }

  if (!block) return;

  const template: Template = {
    id: templateId,
    pageId: templateId,
    name:
      segmentsToText(refBlock?.data.segments ?? block.data.segments) ||
      getUntitledName(refBlock?.type || block.type),
    source: '',
    icon: refBlock?.data.icon ?? block.data.icon,
    desc: '',
    image: Images.templateEmpty,
    isCustom: true,
    userId: refBlock?.data.createdTemplateBy ?? block.data.createdTemplateBy,
  };

  return template;
};
