import { PermissionRole } from '@next-space/fe-api-idl';

// Partial order
export const contains = (role1?: PermissionRole, role2?: PermissionRole) => {
  if (!role1 || !role2) return false;

  switch (role2) {
    case PermissionRole.NONE:
      return true;
    case PermissionRole.READER:
      return [
        PermissionRole.READER,
        PermissionRole.COMMENTER,
        PermissionRole.WRITER,
        PermissionRole.EDITOR,
      ].includes(role1);
    case PermissionRole.COMMENTER:
      return [PermissionRole.COMMENTER, PermissionRole.WRITER, PermissionRole.EDITOR].includes(
        role1
      );
    case PermissionRole.WRITER:
      return [PermissionRole.WRITER, PermissionRole.EDITOR].includes(role1);
    case PermissionRole.EDITOR:
      return role1 === PermissionRole.EDITOR;
    default:
      throw new Error('Unhandled role case');
  }
};
