import { DaySetting } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useBitable } from 'src/bitable/context';
import { EMBED_PAGE_MANAGER_HEIGHT, PAGE_MANAGER_HEIGHT } from 'src/bitable/timeline-view/const';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCalendarType } from 'src/hooks/collection-view/use-collection-view';
import { CalendarTypeMap } from '../const';
import { useCalender } from './context';
import { getCalenderDates, getOneMonthDates } from './utils/compute-calendar-date';
import { highlightCurrentDate } from './utils/dom';
import { WEEKDAYS_SHORT } from 'src/utils/date-utils';

export const Header: FC<{
  currentMonth: number;
  setCurrentMonth: React.Dispatch<React.SetStateAction<number>>;
}> = ({ currentMonth, setCurrentMonth }) => {
  const { calenderHeader, setCalenderDates, container, calenderDates, embed, startDay } =
    useCalender();

  const { isLocked, managerReadonly, viewId } = useBitable();
  const calendarType = useCalendarType(viewId);
  const openModal = useOpenModal();

  useEffect(() => {
    const containerNode = container.current;
    if (!containerNode) return;

    highlightCurrentDate(currentMonth, calenderDates, containerNode);
  }, [calenderDates, container, currentMonth]);

  const handleJump = (target: 'pre' | 'next' | 'today') => {
    let firstDate = Date.now();
    if (target === 'today') {
      const today = new Date();
      firstDate = today.setDate(2); // 当月第二天
    } else {
      const currentMonthObj = new Date(currentMonth);
      const month = currentMonthObj.getMonth();
      const newMonth = target === 'pre' ? month - 1 : month + 1;
      firstDate = currentMonthObj.setMonth(newMonth, 1);
    }

    setCurrentMonth(firstDate);
    if (embed) {
      setCalenderDates(getOneMonthDates(startDay, firstDate));
    } else {
      const containerNode = container.current;
      const pageNode = containerNode?.closest('[data-page-id]');
      if (!pageNode) return;

      setCalenderDates(getCalenderDates(startDay, 'init', firstDate));
      pageNode.scrollTop = 0;
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    if (isLocked || managerReadonly) return;

    const items = CalendarTypeMap.map((item) => ({
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data: { title: item.name, ...item, selected: item.type === calendarType },
    }));

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="next-modal w-36 py-2"
            items={items}
            onItemClick={({ data }) => {
              updateViewFormat(viewId, { calendarType: data.type });
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  const currentMonthObj = new Date(currentMonth);
  return (
    <div
      className="sticky w-full flex-shrink-0 justify-between border-b border-t bg-white1 z-10 text-t2-medium"
      style={{ top: embed ? EMBED_PAGE_MANAGER_HEIGHT : PAGE_MANAGER_HEIGHT }}
      ref={calenderHeader}
    >
      <div className="flex items-center h-[38px] pl-4">
        <div className="h-full flex bg-white1">
          {currentMonth && (
            <span className="h-full inline-flex items-center">
              {__BUILD_IN__ ? (
                <>
                  {currentMonthObj.getFullYear()}-{currentMonthObj.getMonth() + 1}
                </>
              ) : (
                <>
                  {currentMonthObj.getFullYear()}年{currentMonthObj.getMonth() + 1}月
                </>
              )}
            </span>
          )}
        </div>

        <div className="ml-auto flex items-center">
          <button
            onClick={handleClick}
            className="text-t2 flex h-full items-center px-3 text-grey3"
          >
            {CalendarTypeMap.find((item) => item.type === calendarType)?.name}
            {!managerReadonly && <Icon size="xxxsmall" name="IcArrowDown01" className="ml-1" />}
          </button>

          <Tooltip className="rounded-sm animate-hover" onClick={() => handleJump('pre')}>
            <Icon name="IcArrowDateBack" size="middle" />
          </Tooltip>
          <span className="bg-white1 px-2 cursor-pointer" onClick={() => handleJump('today')}>
            今天
          </span>
          <Tooltip className="rounded-sm animate-hover" onClick={() => handleJump('next')}>
            <Icon name="IcArrowDateNext" size="middle" />
          </Tooltip>
        </div>
      </div>
      <div className="flex h-[38px]">
        {(startDay === DaySetting.MON ? '一_二_三_四_五_六_日'.split('_') : WEEKDAYS_SHORT).map(
          (week) => (
            <div key={week} className="flex-1 flex items-center justify-center text-grey3">
              {__BUILD_IN__ ? week : `周${week}`}
            </div>
          )
        )}
      </div>
    </div>
  );
};
