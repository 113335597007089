import { useEffect } from 'react';

/** 移除loading */
export const useSplash = () => {
  useEffect(() => {
    const splash = document.querySelector('#splash');
    if (splash) {
      setTimeout(() => {
        splash.remove();
      }, 300);
    }
  }, []);
};
