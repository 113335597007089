import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';

/**
 * 由于view类型较多，IdNumber的属性显示是依赖于tableview显示的(参照了notion)
 * 所以这里写个hook获取在tableview下每个recoreId的index
 */
export const useTableViewIndex = (viewId: string, recordId: string) => {
  const { tableGroups } = useBiTableGroupsAndProvideRecordWalker(viewId);
  if (!tableGroups) return 0;
  const { withoutValidGroup, sortedRecordIds, visibleGroups, hiddenGroups } = tableGroups;

  if (withoutValidGroup) {
    return sortedRecordIds.indexOf(recordId);
  }
  let found = visibleGroups.find((v) => v.recordIds.indexOf(recordId) >= 0);
  if (found) {
    return found.recordIds.indexOf(recordId);
  }
  found = hiddenGroups.find((v) => v.recordIds.indexOf(recordId) >= 0);
  if (found) {
    return found.recordIds.indexOf(recordId);
  }
  return 0;
};
