import { BlockType } from '@next-space/fe-api-idl';
import type { useCopyBlockRecord } from 'src/hooks/block/use-copy-block-record';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { moveBlock } from 'src/redux/managers/block/move';
import { cache } from 'src/redux/store';
import { getPreSiblingBlockId } from './block-utils';
import { idsToSelectedBlocks } from './select-block-util';

/** 把同步块转成普通块，【把subNode提出来】 */
export const covertSyncBlockToNormalBlock = (syncBlockId: string) => {
  const preSiblingBlockId = getPreSiblingBlockId(syncBlockId);
  let syncBlock = cache.blocks[syncBlockId];
  if (!syncBlock) {
    return;
  }
  if (syncBlock.type === BlockType.SYNC_REFERENCE) {
    syncBlock = cache.blocks[syncBlock.data.ref?.uuid ?? ''];
  }
  if (!syncBlock) {
    return;
  }
  // 如果有preSiblingBlockId就插刀preSiblingBlockId后面，如果没有就插到第一个
  moveBlock(syncBlock.subNodes, {
    parentId: syncBlock.parentId,
    after: preSiblingBlockId,
    first: !preSiblingBlockId,
  });
  archiveBlock(syncBlockId);
};
/** 对影子同步块unSync， 需要把源同步块的subnode复制到外面 */
export const convertSyncReferenceBlockToNormalBlock = async (
  syncReferenceBlockId: string,
  copyBlock: ReturnType<typeof useCopyBlockRecord>
) => {
  let block = cache.blocks[syncReferenceBlockId];
  if (!block) {
    return;
  }
  if (block.type === BlockType.SYNC_REFERENCE) {
    block = cache.blocks[block.data.ref?.uuid ?? ''];
    if (!block) {
      return;
    }
    // 把subnode复制到影子块后面，再删除影子同步块本身
    await copyBlock(
      idsToSelectedBlocks(block.subNodes, undefined, syncReferenceBlockId),
      syncReferenceBlockId
    );
    archiveBlock(syncReferenceBlockId);
  }
};
