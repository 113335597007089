import type { CSSProperties, FC } from 'react';
import { useEffect, useRef } from 'react';
import { EmptyView } from 'src/common/components/empty-view';
import type { ImageInfo } from 'src/common/components/gallery';
import { Gallery } from 'src/common/components/gallery';
import { LoadingContainer } from 'src/common/components/loading-container';
import { LoadingIcon } from 'src/common/components/loading-icon';
import type { SearchAdapter } from 'src/common/hooks/use-search-controller';
import { useSearchController } from 'src/common/hooks/use-search-controller';
import { request } from 'src/common/request';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';

interface QueryResult {
  url: string;
  ossName: string;
  description?: string;
}

/**
 * 最近上传icon/cover 文档
 * https://www.notion.so/icon-cover-dec950caf4984f38b0436e7950be0830
 */
export const RecentlyUploadPanel: FC<{
  onSelect?: (imageInfo: ImageInfo) => void;
  fileType: 'icon' | 'cover';
  style?: CSSProperties;
}> = (props) => {
  const searchAdapterRef = useRef<SearchAdapter<QueryResult, ImageInfo>>({
    async search(pageNo: number, _keyword: string, pageSize: number) {
      const res = await request.editor.querySpaceRecentlyImages(
        getCurrentSpaceId(),
        props.fileType,
        pageNo,
        pageSize
      );
      return res.list ?? [];
    },
    async convert(result: QueryResult[]) {
      return result.map((v) => {
        return { url: v.url, ossName: v.ossName, fileName: v.description ?? '' };
      });
    },
    canSearchWithEmpty: true,
  });
  const { firstTime, resultList, loading, hasMore, search } = useSearchController(
    searchAdapterRef.current
  );
  const loadMore = (pageNo: number) => {
    void search(pageNo, '');
  };

  useEffect(() => {
    void search(1, '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (firstTime) return <LoadingContainer style={props.style} />;
  return (
    <Gallery
      onSelect={(index) => {
        const imageInfo = resultList[index];
        if (imageInfo && imageInfo.ossName) {
          props.onSelect?.(imageInfo);
        }
      }}
      imageList={resultList}
      hasMore={hasMore}
      loadMore={loadMore}
      style={props.style}
      customFooter={
        <>
          {resultList.length === 0 && <EmptyView desc="最近未上传过图片" style={props.style} />}
          {loading && hasMore && (
            <div className="relative flex flex-col items-center justify-center">
              <LoadingIcon size="middle" />
              <div className="flex items-center ml-2 text-center text-t2 text-grey4">
                加载更多...
              </div>
            </div>
          )}
        </>
      }
    />
  );
};
