import type { FC } from 'react';
import { UUID_NAMESPACE } from 'src/common/const';
import { PreviewPageContent } from 'src/components/preview-page-content';
import { PageScene } from 'src/views/main/scene-context';

export const PreviewEndPage: FC<{ uuid: string }> = ({ uuid }) => {
  return (
    <PreviewPageContent
      docId={uuid}
      pageScene={PageScene.FORM_ENG_PAGE}
      nameSpace={UUID_NAMESPACE.FORM_END_PAGE}
    />
  );
};
