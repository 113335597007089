import type { RefObject } from 'react';
import { useEffect, useRef } from 'react';
import {
  ButtonType,
  ResizeStatus,
  useResizeListener,
} from '@flowus/common/hooks/use-resize-listener';
import { usePressedSpace } from 'src/services/app';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { useOpenMindMapContextMenu } from './use-open-mind-map-context-menu';

// 只处理右键点击画布，右键拖拽的情况，右键点击节点由全局的useMouseDown的逻辑处理
export const useMindMapMouseDown = (scrollerRef: RefObject<HTMLElement | null>) => {
  const openMindMapContextMenu = useOpenMindMapContextMenu();
  const isContextMenuClickRef = useRef<boolean>();
  const isPressedSpace = usePressedSpace();
  const pageScene = usePageScene();
  // 右键拖拽
  useResizeListener(scrollerRef, {
    onResize: (result) => {
      const container = scrollerRef.current;
      if (!container) return;
      switch (result.resizeStatus) {
        case ResizeStatus.START:
          isContextMenuClickRef.current = true;
          container.style.cursor = 'grab';
          break;
        case ResizeStatus.RESIZING:
          isContextMenuClickRef.current = false;
          container.scrollBy(-result.deltaX, -result.deltaY);
          break;
        case ResizeStatus.END: {
          if (!isPressedSpace) {
            container.style.cursor = '';
          }
          break;
        }
        default:
      }
    },
    buttonType: isPressedSpace ? ButtonType.leftClick : ButtonType.rightCLick,
    dispatchEvent: true,
  });

  useEffect(() => {
    const container = scrollerRef.current;
    if (!container) return;
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    // 画布右键
    const onMouseUp = (event: MouseEvent) => {
      if (event.button !== 2) return;
      if (!isContextMenuClickRef.current) return;
      if (pageScene === PageScene.PAGE_HISTORY) return;
      event.preventDefault();
      // const node = (event.target as HTMLElement).closest('[data-block-id]');
      // if (node) return;
      openMindMapContextMenu(event);
    };
    container.addEventListener('contextmenu', handleContextMenu, true);
    container.addEventListener('mouseup', onMouseUp, true);
    return () => {
      container.removeEventListener('mouseup', onMouseUp, true);
      container.removeEventListener('contextmenu', handleContextMenu, true);
    };
  }, [scrollerRef, openMindMapContextMenu, pageScene]);
};
