import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePublicSpace } from '../space';

/** 判断当前空间是不是特殊空间-share */
export const useOfficialSpaceShare = (uuid: string) => {
  const spaceId = useObservableBlock(uuid, (block) => block?.spaceId);
  const space = usePublicSpace(spaceId);

  return judgeSharePage() && space?.isOfficialSpace;
};

/** 判断当前空间是不是特殊空间 */
export const useOfficialSpace = (uuid = '') => {
  const spaceId = useObservableBlock(uuid, (block) => block?.spaceId);
  const space = usePublicSpace(spaceId);

  return space?.isOfficialSpace;
};
