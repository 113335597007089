import { debounce } from 'lodash-es';
import type { FC } from 'react';
import { useMemo, useState } from 'react';
import type { ModalSchema } from '.';
import { Button } from '../components/button';
import { CountDown } from '../components/count-down';
import { RenderModal } from './modal';
import { cx } from '../cx';

// #region warning
export const RenderWarning: FC<ModalSchema.RenderWarning> = (props) => {
  const {
    title,
    content,
    confirm: _confirm,
    cancel,
    confirmText,
    cancelText,
    noCancel = false,
    showConfirm = true,
    countTime,
    modalId,
    keyboard,
    colorType = 'red',
    maxWidth,
    minWidth = 300,
    footer,
    cancelColorType,
    className,
    closeAfterCallBack,
    ...reset
  } = props;
  const [isCountDown, setIsCountDown] = useState(Boolean(countTime));

  const onFinish = () => setIsCountDown(false);

  const confirm = useMemo(() => {
    return debounce(() => _confirm?.(), 100, {
      leading: true,
      trailing: false,
    });
  }, [_confirm]);

  return (
    <RenderModal
      {...reset}
      closeAfterCallBack={closeAfterCallBack}
      keyboard={keyboard}
      center
      modalId={modalId}
      className="bg-white3"
      content={({ onCloseModal }) => (
        <div
          className={cx(
            'flex flex-col justify-center items-center px-7 py-6 max-w-xs text-center',
            className
          )}
          style={{ maxWidth, minWidth }}
        >
          {title && (
            <div className={`text-t1-medium whitespace-pre-line ${content ? '' : 'mb-8'}`}>
              {title}
            </div>
          )}
          {content && (
            <div className="my-2.5 text-t2 w-full whitespace-pre-line break-words max-h-[50vh] overflow-y-auto">
              {content}
            </div>
          )}
          {(showConfirm || !noCancel) && (
            <div className="flex flex-col w-full text-t3 space-y-2.5">
              {showConfirm && (
                <Button
                  className="text-t3-medium"
                  colorType={colorType}
                  autoFocus
                  disable={isCountDown}
                  onClick={() => {
                    if (!isCountDown) {
                      confirm();
                      onCloseModal();
                      closeAfterCallBack?.();
                    }
                  }}
                >
                  {isCountDown ? (
                    <CountDown time={countTime} onFinish={onFinish} />
                  ) : (
                    confirmText || '确定'
                  )}
                </Button>
              )}
              {!noCancel && (
                <Button
                  colorType={cancelColorType}
                  onClick={() => {
                    cancel?.();
                    onCloseModal();
                    closeAfterCallBack?.();
                  }}
                >
                  {cancelText || '取消'}
                </Button>
              )}
            </div>
          )}
          {footer}
        </div>
      )}
    />
  );
};
// #endregion
