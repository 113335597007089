import { globalResizeObserver } from '@flowus/common/utils/global-resize-observer';
import type { MutableRefObject } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

type TargetValue<T> = T | undefined | null;
type TargetType = HTMLElement | Element | Window | Document;

export type BasicTarget<T extends TargetType = Element> =
  | (() => TargetValue<T>)
  | TargetValue<T>
  | MutableRefObject<TargetValue<T>>;
interface Size {
  width?: number;
  height?: number;
}
type Option = 'width' | 'height' | 'all';
type Result<T extends Option> = Pick<Size, T extends 'all' ? 'width' | 'height' : T>;
/**
 * ahooks的useSize是监听宽高的，很多情况下我们是不需要监听高度变化的，
 * 这会导致大量使用到useSize的组件重新渲染，因此重写一个
 */
export function useSize<T extends Option>(
  target: BasicTarget,
  option?: T,
  includeBorder?: boolean,
  id?: string
): Result<T> {
  const [size, setSize] = useState<Size>({
    width:
      option === 'width' || option === 'all' ? getTargetElement(target)?.clientWidth : undefined,
    height:
      option === 'height' || option === 'all' ? getTargetElement(target)?.clientHeight : undefined,
  });
  const sizeRef = useRef<Size>({});

  const el = getTargetElement(target);

  useLayoutEffect(() => {
    if (!el) return;
    const unobserve = globalResizeObserver.observe(el, (entry) => {
      const { clientWidth, clientHeight, offsetHeight, offsetWidth } = entry.target as HTMLElement;
      const width = includeBorder ? offsetWidth : clientWidth;
      const height = includeBorder ? offsetHeight : clientHeight;
      if (!option) {
        if (sizeRef.current.width !== width || sizeRef.current.height !== width) {
          setSize({
            width,
            height,
          });
        }
        sizeRef.current.width = width;
        sizeRef.current.height = height;
        return;
      }
      switch (option) {
        case 'width':
          if (sizeRef.current.width !== width) {
            setSize({
              width,
            });
          }
          break;
        case 'height':
          if (sizeRef.current.height !== width) {
            setSize({
              height,
            });
          }
          break;
        case 'all':
          if (sizeRef.current.width !== width || sizeRef.current.height !== height) {
            setSize({
              width,
              height,
            });
          }
          break;
        default:
      }
      sizeRef.current.width = width;
      sizeRef.current.height = height;
    });

    return unobserve;
  }, [id, includeBorder, option, el]);

  return { ...size };
}

export const observerDomSize = (
  element: HTMLElement,
  callback?: (params: { width: number; height: number }) => void,
  includeBorder?: boolean
) => {
  const unobserve = globalResizeObserver.observe(element, (entry) => {
    const { clientWidth, clientHeight, offsetHeight, offsetWidth } = entry.target as HTMLElement;
    const width = includeBorder ? offsetWidth : clientWidth;
    const height = includeBorder ? offsetHeight : clientHeight;
    callback?.({ width, height });
  });

  return unobserve;
};

/**
 * callback,不触发渲染
 */
export function useSizeCallback<T extends Option>(
  target: BasicTarget,
  callback: (result: Result<T>) => void,
  option?: T
) {
  const callbackRef = useRef<typeof callback>(callback);
  const sizeRef = useRef<Size>({});

  const el = getTargetElement(target);
  useEffect(() => {
    if (!el) return;
    const unobserve = globalResizeObserver.observe(el, (entry) => {
      const { clientWidth, clientHeight } = entry.target; // 这行代码会算上滚动条的，导致滚动条出现隐藏都会回调
      let change = false;
      if (!option) {
        if (sizeRef.current.width !== clientWidth || sizeRef.current.height !== clientHeight) {
          change = true;
        }
        return;
      }
      switch (option) {
        case 'width':
          if (sizeRef.current.width !== clientWidth) {
            change = true;
          }
          break;
        case 'height':
          if (sizeRef.current.height !== clientHeight) {
            change = true;
          }
          break;
        case 'all':
          if (sizeRef.current.width !== clientWidth || sizeRef.current.height !== clientHeight) {
            change = true;
          }
          break;
        default:
      }
      sizeRef.current.width = clientWidth;
      sizeRef.current.height = clientHeight;
      if (change) {
        callbackRef.current({ ...sizeRef.current });
      }
    });

    return unobserve;
  }, [option, el]);
}

export function getTargetElement<T extends TargetType>(target: BasicTarget<T>, defaultElement?: T) {
  if (!target) {
    return defaultElement;
  }

  let targetElement: TargetValue<T>;

  if (typeof target === 'function') {
    targetElement = target();
  } else if ('current' in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }

  return targetElement;
}
