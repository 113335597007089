import { useCallback } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';

export const useShortcutFormat = () => {
  const getEditorModel = useGetOrInitEditorModel();
  return useCallback(
    (uuid: string, type: 'bold' | 'italic' | 'underline' | 'lineThrough') => {
      const editorModel = getEditorModel(uuid);

      if (!editorModel) return;
      const { format } = editorModel;

      switch (type) {
        case 'bold':
          editorModel.performChange((ctx) => ctx.applyFormat({ bold: !format.bold }));
          break;
        case 'italic':
          editorModel.performChange((ctx) => ctx.applyFormat({ italic: !format.italic }));
          break;
        case 'underline':
          editorModel.performChange((ctx) => ctx.applyFormat({ underline: !format.underline }));
          break;
        case 'lineThrough':
          editorModel.performChange((ctx) => ctx.applyFormat({ lineThrough: !format.lineThrough }));
          break;
        default:
      }
    },
    [getEditorModel]
  );
};
