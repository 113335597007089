import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Input } from 'src/common/components/input';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { combineFullName, getSegmentsFileNameInfo } from 'src/utils/file';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';

export const useOpenRename = () => {
  const openModal = useOpenModal();

  return useCallback(
    (props: { popcorn: ModalSchema.PopcornType; blockId: string }) => {
      const { popcorn, blockId } = props;

      openModal.dropdown({
        popcorn,
        placement: 'bottom-start',
        content: ({ onCloseModal }) => {
          return <ChangeTitle close={onCloseModal} blockId={blockId} />;
        },
      });
    },
    [openModal]
  );
};

const ChangeTitle: FC<{ blockId: string; close(): void }> = ({ blockId, close }) => {
  const block = usePickBlock(blockId, ['data'], ['segments']);
  const isFileBlock = block?.type === BlockType.FILE;
  const transaction = useTransaction();
  const value = isFileBlock
    ? getSegmentsFileNameInfo(block.data.segments).name
    : segmentsToText(block?.data.segments);

  const onChange = (title: string) => {
    transaction(() => {
      if (isFileBlock) {
        const fileInfo = getSegmentsFileNameInfo(block.data.segments);
        if (!title) return;
        updateBlock(blockId, {
          data: {
            segments: textToSegments(combineFullName(title, fileInfo.extName)),
          },
        });
      } else {
        updateBlock(blockId, { data: { segments: textToSegments(title) } });
      }
    });
  };

  if (!block) return null;

  return (
    <div className="next-modal flex items-center p-1 rounded-t" style={{ width: '365px' }}>
      <Input
        autoFocus
        showBorder={false}
        defaultValue={value}
        onEnter={close}
        onChange={onChange}
        placeholder={getUntitledName(block.type)}
        className="w-full h-8 bg-grey8"
      />
    </div>
  );
};
