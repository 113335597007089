import { commonInjector } from '../build-in';

/** icon保存到后台的是一个path，前端通过拼接字符串生成地址 */
export const getCustomIconSavePath = (name: string, color: string) => {
  return `/${color}/${name}.svg`;
};
/** 获取cdn地址,给emoji组件用 */
export const generateCustomIconUrl = (name: string, isDarkMode: boolean, color: string) => {
  const savePath = getCustomIconSavePath(name, color);
  return generateCustomIconUrlByPath(isDarkMode, savePath);
};
/** 获取cdn地址，给已选好的icon用的 */
export const generateCustomIconUrlByPath = (isDarkMode: boolean, imgPath: string) => {
  const url = `${commonInjector.cdnHost}assets/byte-icon/${
    isDarkMode ? 'dark' : 'light'
  }${imgPath}`;
  return url;
};
