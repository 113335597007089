import { encryptionPhone } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { FC, MouseEvent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { useModel } from 'src/common/create-model';
import { request } from 'src/common/request';
import { UserAvatar } from 'src/components/user-avatar';
import { segmentsToText } from 'src/editor/utils/editor';
import { getAncestors } from 'src/hooks/block/use-get-ancestors';
import { useFetchRootPages } from 'src/hooks/page';
import { useCurrentSpace } from 'src/hooks/space';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useGetSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { getIsTeamSpace } from 'src/hooks/space/use-is-team-space';
import { useCurrentUser } from 'src/hooks/user';
import { ADD_MARK_NAME } from 'src/redux/actions';
import type { LocalUser } from 'src/redux/reducers/users';
import { cache, dispatch } from 'src/redux/store';
import { SpacePlanType } from 'src/redux/types';
import { $appUiStateCache, setAppUiState, useGuestsList } from 'src/services/app';
import { getLimitConfig, useLimitConfig } from 'src/services/app/hook/subscription-data';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { searchUsers } from 'src/utils/search-util';
import { SettingModalCommon, SettingProvider } from '../common';
import { OpenSettingFrom, SettingMenuType } from '../type';
import { FoldItemList } from './fold-item-list';
import { MarkNameEditButton } from './mark-name-edit-button';

export const Guests: FC = () => {
  const openModal = useOpenModal();
  const currentSpace = useCurrentSpace();
  const { guestMaxLimit, guestMaxLimitDesc } = useLimitConfig();
  const currentUser = useCurrentUser();
  const fetchSpaceRootPages = useFetchRootPages();
  const getSpaceRolePermission = useGetSpaceRolePermission();
  const spaceEditor = getSpaceRolePermission(currentUser.uuid).editor;
  const isFree =
    currentSpace.planType === SpacePlanType.freeTeam ||
    currentSpace.planType === SpacePlanType.freePersonal;
  const isTeamSpace = getIsTeamSpace(currentSpace.planType);
  const planName = getSpacePlanTypeName(currentSpace.planType);
  const guestsList = useGuestsList();
  const guests = useObservableStore(
    ({ users }) => guestsList.map((guest) => users[guest.userId]).filter(Boolean),
    [guestsList]
  ) as LocalUser[];
  const [searchKeywords, setSearchKeywords] = useState('');
  const userList = useMemo(() => {
    return searchUsers(guests, searchKeywords);
  }, [guests, searchKeywords]);
  const { setCurrentSettingMenu, checkoutUpgraded } = useModel(SettingProvider);
  const space = useCurrentSpace();

  const spaceUsers = useCurrentSpaceUsers();
  const isPro = useIsProSpace();
  // 有editorMarkNameId的时候表示正在编辑备注
  const [editorMarkNameId, setEditorMarkNameId] = useState<string>();
  // 记录一下备注名，blur的时候保存
  const lastEditMarkNameRef = useRef<string>();

  /** 用户权限修改弹窗 */
  const openOption = (event: MouseEvent<HTMLDivElement>, userId: string) => {
    const clickUser = guestsList.find((guest) => guest.userId === userId);
    if (!clickUser) {
      return;
    }

    openModal.dropdown({
      placement: 'bottom',
      offset: [0, 4],
      popcorn: event.currentTarget,
      content: ({ onCloseModal }) => (
        <ListView
          className="py-2 w-[270px] next-modal"
          onItemClick={({ type, data }) => {
            if (type === ListItemType.SEARCH) {
              window.open(`/${data.uuid}`);
            }
          }}
          items={
            [
              isTeamSpace
                ? {
                    type: ListItemType.TEXT_DESCRIPTION,
                    data: {
                      title: (
                        <div className="flex items-center">
                          <Icon name="MIcSettingsMembers" size="middle" />
                          <div className="ml-2">升级为空间成员</div>
                        </div>
                      ),
                      onClick: async () => {
                        if (
                          !isPro &&
                          Object.keys(spaceUsers).length >= getLimitConfig().membersMaxLimit
                        ) {
                          checkoutUpgraded(OpenSettingFrom.extMember);
                          return;
                        }
                        await request.infra.addSpaceMember(currentSpace.uuid, { userId });
                        void fetchSpaceRootPages(currentSpace.uuid);

                        setAppUiState({
                          $guestsList: $appUiStateCache.$guestsList.filter(
                            (guest) => guest.userId !== userId
                          ),
                        });
                        message.success('已成功升级为空间成员');
                        onCloseModal();
                      },
                    },
                  }
                : null,
              isTeamSpace ? { type: ListItemType.LINE } : null,
              {
                type: ListItemType.TEXT_DESCRIPTION,
                data: {
                  title: (
                    <div className="flex items-center">
                      <Icon name="IcMenuLogout" size="middle" className="text-red" />
                      <div className="ml-2 text-red">移除</div>
                    </div>
                  ),
                  onClick: async () => {
                    await request.infra.removeSpaceGuest(currentSpace.uuid, { userId });
                    setAppUiState({
                      $guestsList: $appUiStateCache.$guestsList.filter(
                        (guest) => guest.userId !== userId
                      ),
                    });
                    message.success('已成功移除');
                    onCloseModal();
                  },
                },
              },
              { type: ListItemType.LINE },
              ...clickUser.guestPageIds.map((pageId) => {
                const page = cache.blocks[pageId];
                if (!page) return null;
                const navs = getAncestors(pageId);
                return {
                  type: ListItemType.SEARCH,
                  data: {
                    uuid: page.uuid,
                    title: segmentsToText(page.data.segments) || getUntitledName(page.type),
                    icon: page.data.icon,
                    navs,
                    type: page.type,
                    backgroundColor: page.backgroundColor,
                  },
                };
              }),
            ].filter(Boolean) as ListItem[]
          }
        />
      ),
    });
  };

  return (
    <div className="mt-3.5">
      {spaceEditor && (
        <>
          <div className={'my-2 text-t4 text-grey3 space-x-2'}>
            <span>{`当前空间计划为${planName}空间，总共可邀请${guestMaxLimitDesc}名外部协作者`}</span>
            <span
              hidden={!isFree}
              className="text-active_color animate-click"
              onClick={() => {
                if (currentSpace.planType === SpacePlanType.freePersonal) {
                  setCurrentSettingMenu(SettingMenuType.upgrade);
                } else {
                  checkoutUpgraded(OpenSettingFrom.extMember);
                }
              }}
            >
              升级获取更多席位
            </span>
          </div>

          <SettingModalCommon.SettingDivider className="my-2.5" />
        </>
      )}

      {userList.length || searchKeywords ? (
        <div className="flex top-0 shrink-0 items-center justify-between mt-2.5 h-9 text-t2">
          <div>人员信息</div>
          <Input
            className="pl-3 pr-1.5 w-full max-w-[300px] h-8 rounded-full"
            inputClassName="px-0"
            value={searchKeywords}
            onChange={setSearchKeywords}
            placeholder="搜索人员、手机号"
            addonBefore={<Icon size="middle" name="IcSearch" className="text-grey4" />}
            addonAfter={
              searchKeywords && (
                <Icon
                  size="middle"
                  name="IcUploadCancel"
                  onClick={() => setSearchKeywords('')}
                  className="text-grey4 cursor-pointer"
                />
              )
            }
          />
        </div>
      ) : (
        <div>
          <div className="text-[70px] leading-[80px]">☕️</div>
          <div className="my-4 text-t2">当前空间未邀请外部协作者</div>
          <div className="text-grey3">
            <div>如何邀请外部协作者？</div>
            <div className="mt-1 space-y-1">
              <div>1. 进入需要协作的页面</div>
              <div>2. 点击页面右上角「分享」</div>
              <div>3. 在输入框内输入手机号并设置权限</div>
              <div>4. 完成邀请</div>
            </div>
          </div>
        </div>
      )}

      <FoldItemList
        foldLength={50}
        items={userList}
        renderItemContent={(user) => {
          const userEditorRole = user.uuid === currentUser.uuid || spaceEditor;
          const guest = guestsList.find((g) => g.userId === user.uuid);
          const markName = space.userRemark?.[user.uuid];
          const newUser = { ...user };
          let userName = user.nickname || '客';
          const hasName = !!user.nickname || !!markName;
          if (markName) {
            userName = `${markName} ${user.nickname ? `(${user.nickname})` : ''}`;
          }
          return (
            <div className="group flex shrink-0 items-center h-[58px]" key={newUser.uuid}>
              <UserAvatar
                forceNickname={userName}
                user={newUser}
                className="mr-2 w-[30px] h-[30px] !text-t1"
              />
              <div className="flex flex-col grow">
                {editorMarkNameId === user.uuid ? ( // 编辑备注
                  <Input
                    autoFocus={true}
                    maxLength={20}
                    className="pl-3 pr-1.5 w-full max-w-[300px] h-8 rounded-sm"
                    inputClassName="px-0"
                    defaultValue={markName}
                    onChange={(v) => {
                      lastEditMarkNameRef.current = v;
                    }}
                    placeholder="请输入备注"
                    onEnter={(e: React.KeyboardEvent) => {
                      if (e.target instanceof HTMLElement) {
                        e.target.blur();
                      }
                    }}
                    onBlur={async () => {
                      const markName = lastEditMarkNameRef.current;
                      lastEditMarkNameRef.current = undefined;
                      setEditorMarkNameId(undefined);
                      if (markName !== undefined) {
                        await request.infra.addSpaceUserRemark(space.uuid, {
                          [user.uuid]: markName,
                        });
                        // 本地更改一下space数据
                        dispatch(
                          ADD_MARK_NAME({
                            uuid: space.uuid,
                            patch: [
                              {
                                userId: user.uuid,
                                markName,
                              },
                            ],
                          })
                        );
                      }
                    }}
                  />
                ) : (
                  <>
                    <div className="flex items-center">
                      <span className="mb-0.5 text-t2">{hasName ? userName : newUser.phone}</span>
                      {userEditorRole && (
                        <MarkNameEditButton
                          className="ml-1 cursor-pointer transition-opacity group-hover:opacity-100 opacity-0"
                          onClick={() => {
                            setEditorMarkNameId(user.uuid);
                          }}
                        />
                      )}
                    </div>
                    {hasName && (
                      <span className="text-grey3 text-t4">
                        {encryptionPhone(user.phone || user.email)}
                      </span>
                    )}
                  </>
                )}
              </div>
              <div
                className={cx(
                  'self-center flex items-center text-t2',
                  userEditorRole && 'cursor-pointer'
                )}
                onClick={(e) => userEditorRole && openOption(e, newUser.uuid)}
              >
                {`${guest?.guestPageIds.length ?? 0}个协作页面`}
                {userEditorRole && <Icon name="IcArrowDown01" className="ml-1" size="xxxsmall" />}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
