import { omit } from 'lodash-es';
import type { FC } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { usePickBlock } from 'src/utils/pick-block';
import { useScrollRef } from 'src/views/main/page-doc/context';
import type { VisibleRenderPropsType } from './types';
import { RenderMode } from './types';

export const VisibleRender: FC<VisibleRenderPropsType> = (props) => {
  const { blockId, updateByBlock, ...reset } = props;
  const { visible, setVisible, params, ref, content } = useVisibleRender(props);

  return (
    <>
      {updateByBlock && (
        <VisibleByBlock blockId={blockId} visible={visible} setVisible={setVisible} />
      )}
      <div
        {...omit(reset, [
          'children',
          'placeHolder',
          'renderMode',
          'rootMargin',
          'container',
          'delay',
          'trackVisibility',
        ])}
        {...params}
        ref={ref}
      >
        {content}
      </div>
    </>
  );
};

const useVisibleRender = (props: VisibleRenderPropsType) => {
  const {
    children,
    renderMode = RenderMode.innerHtml,
    rootMargin,
    blockId,
    trackVisibility = true,
    delay = 150,
  } = props;
  const scrollRef = useScrollRef();
  const isInnerHtmlMode = renderMode === RenderMode.innerHtml;
  const isBlankMode = renderMode === RenderMode.blank;
  const [visible, setVisible] = useState(false);
  const inner = useRef<{
    visible: boolean;
    html: string;
    height?: number;
    width?: number;
  }>({
    visible: false,
    html: '',
    height: 34,
  });

  const { ref, inView, entry } = useInView({
    root: scrollRef?.current,
    rootMargin: rootMargin || '2000px 0px',
    fallbackInView: true,
    trackVisibility,
    delay,
  });

  useEffect(() => {
    if (!entry) return;
    if (inView) {
      inner.current = {
        visible: true,
        html: '',
        height: entry.boundingClientRect.height,
        width: entry.boundingClientRect.width,
      };
      setVisible(true);
    } else {
      if (entry.target.contains?.(document.activeElement)) {
        return;
      }
      inner.current = {
        visible: false,
        html: isBlankMode ? '' : entry.target.innerHTML,
        height: entry.boundingClientRect.height,
        width: entry.boundingClientRect.width,
      };
      setVisible(false);
    }
  }, [entry, inView, isBlankMode]);

  let params = {};
  let content;

  if (!visible) {
    if (isBlankMode) {
      params = {
        style: { height: inner.current.height },
        'data-block-id': blockId,
      };
    }

    if (isInnerHtmlMode) {
      params = {
        dangerouslySetInnerHTML: {
          __html: inner.current.html,
        },
        ...(inner.current.html ? {} : { 'data-block-id': blockId }),
      };
    }
  } else {
    content = <>{children}</>;
  }

  return { params, content, visible, setVisible, ref };
};

interface VisibleByBlockProps {
  blockId?: string;
  visible: boolean;
  setVisible: (s: boolean) => void;
}
const VisibleByBlock = (props: VisibleByBlockProps) => {
  const { blockId, visible, setVisible } = props;
  const block = usePickBlock(blockId, ['status', 'subNodes', 'data'], ['segments', 'format']);

  // 如果block更新，马上切回去
  useLayoutEffect(() => {
    if (!visible) {
      setVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);
  return <></>;
};
