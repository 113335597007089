import { useCallback } from 'react';
import { isFlowUsApp } from 'src/utils/electron-util';
import { getLocationOrigin, previewPrefix } from 'src/utils/location-utils';

export const useOpenPreview = () => {
  return useCallback((id?: string) => {
    const path = `/${previewPrefix()}${id ? `/${id}` : ''}`;

    if (isFlowUsApp.check) {
      window.open(path);
    } else {
      window.open(`${getLocationOrigin()}${path}`, '__target');
    }
  }, []);
};
