export const LOCAL_KEY_THEME_MODE = 'theme_mode';
import { useState, useEffect } from 'react';
import { GlobalImages } from '../images';
import { MediaQuery } from '../media';

export enum SettingThemeMode {
  system = 'system',
  light = 'light',
  dark = 'dark',
}

export const getIsDarkMode = () => {
  try {
    return document.body.className.includes('dark');
  } catch {
    return false;
  }
};

export const useIsDarkMode = () => {
  const [isDark, setIsDark] = useState(getIsDarkMode());

  useEffect(() => {
    const dom = document.body;
    const refresh = () => setIsDark(getIsDarkMode());
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          refresh();
        }
      });
    });

    refresh();

    observer.observe(dom, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return isDark;
};

export const themeDarkOption = {
  add: () => {
    document.body.classList.add('dark');
    GlobalImages.reload(true);
  },
  remove: () => {
    document.body.classList.remove('dark');
    GlobalImages.reload(false);
  },
};

export const getLocalStorageThemeMode = (): SettingThemeMode => {
  const localThemeMode = localStorage.getItem(LOCAL_KEY_THEME_MODE) as SettingThemeMode;
  if (localThemeMode) {
    const theme = SettingThemeMode[localThemeMode] as SettingThemeMode;
    return theme;
  }
  return SettingThemeMode.system;
};

/** 监听系统是否为暗黑模式 */
export const useListenerSystemDarkMode = () => {
  const [systemDark, setSystemDark] = useState(false);

  useEffect(() => {
    const media = new MediaQuery('(prefers-color-scheme: dark)');

    setSystemDark(media.matches);

    const callback = (e: MediaQueryListEvent) => setSystemDark(e.matches);

    return media.onChange(callback);
  }, []);
  return systemDark;
};
