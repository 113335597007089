import type { WpsFileTokenDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useLayoutEffect, useRef } from 'react';
import _WebOfficeSDK from './lib';
import type { IWebOfficeSDK, IWps } from './lib';
import { ossNameToFileNameInfo } from '../utils/oss-to-filename';

const WebOfficeSDK = _WebOfficeSDK as IWebOfficeSDK;

interface Props {
  getToken: () => Promise<WpsFileTokenDTO>;
  appId: string;
  className?: string;
  mountRoot?: boolean;
}

export const WpsComponent: FC<Props> = (props) => {
  const { getToken, appId, className, mountRoot } = props;
  const dom = useRef<HTMLDivElement>(null);
  const instance = useRef<IWps>();

  const refreshToken = async () => {
    const res = await getToken();
    const obj = {
      ...res,
      timeout: 1000 * 30 * 10,
      hasRefreshTokenConfig: true,
    };
    return obj;
  };

  useLayoutEffect(() => {
    void refreshToken().then((res) => {
      if (!dom.current) return;
      const officeType =
        supportWpsFilePreview(ossNameToFileNameInfo(res.ossName).extName) ??
        WebOfficeSDK.OfficeType.Writer;

      instance.current = WebOfficeSDK.init({
        appId,
        officeType,
        fileId: res.fileId,
        token: res.token,
        attrAllow: ['clipboard-read', 'clipboard-write'],
        refreshToken,
        // 是否挂到最顶层
        mount: mountRoot ? undefined : dom.current,
        isListenResize: true,
      });
    });

    return () => {
      void instance.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  return <div ref={dom} className={className} />;
};

const supportPreview = {
  excel: ['xls', 'xlt', 'et', 'xlsx', 'xltx', 'csv', 'xlsm', 'xltm', 'ett'],
  ppt: ['ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'pps', 'potx', 'potm', 'dpt', 'dps', 'pot'],
  word: [
    'doc',
    'dot',
    'wps',
    'wpt',
    'docx',
    'dotx',
    'docm',
    'dotm',
    'rtf',
    'txt',
    'xml',
    'mhtml',
    'mht',
    'html',
    'htm',
    'uof',
  ],
};

const supportEditor = {
  excel: ['xls', 'xlsx'],
  ppt: ['ppt', 'pptx'],
  word: ['doc', 'docx'],
};

export const supportWpsFilePreview = (name = '') => {
  if (supportPreview.word.includes(name)) {
    return WebOfficeSDK.OfficeType.Writer;
  }

  if (supportPreview.excel.includes(name)) {
    return WebOfficeSDK.OfficeType.Spreadsheet;
  }

  if (supportPreview.ppt.includes(name)) {
    return WebOfficeSDK.OfficeType.Presentation;
  }
};

export const supportWpsFileEditor = (name = '') => {
  if (supportEditor.word.includes(name)) {
    return WebOfficeSDK.OfficeType.Writer;
  }

  if (supportEditor.excel.includes(name)) {
    return WebOfficeSDK.OfficeType.Spreadsheet;
  }

  if (supportEditor.ppt.includes(name)) {
    return WebOfficeSDK.OfficeType.Presentation;
  }
};
