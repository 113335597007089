import { v4 as uuid } from 'uuid';
import { uploadInjector } from './init';
import { TaskPool } from './task';
import type { UploadParams, UploadType } from './types';
import { UploadErrorCode } from './types';

const TASK_POOL_SIZE = 3;

interface UploadingTask {
  xhr: XMLHttpRequest;
  taskId: string;
  params:
    | UploadParams
    | (Omit<UploadParams, 'userId'> & { viewId: string; singleFileMaxCapacity: number });
}

export class AWSUploader {
  private taskPools: Record<UploadType, TaskPool | undefined> = {} as any;

  private uploadIdMappingTaskId = new Map<string, UploadingTask>();

  private getTaskPool(type: UploadType) {
    let taskPool = this.taskPools[type];
    if (!taskPool) {
      taskPool = new TaskPool(TASK_POOL_SIZE);
      this.taskPools[type] = taskPool;
    }
    return taskPool;
  }

  upload(params: UploadParams) {
    const taskPool = this.getTaskPool(params.type);
    const uploadId = uuid();

    const xhr = new XMLHttpRequest();
    const taskId = taskPool.enqueueTask<any>({
      run: async () => {
        const res = await uploadInjector.request.infra.getS3FileUploadInfo.raw({
          spaceId: params.spaceId,
          type: params.type,
          mimeType: params.file.type.trim() ? params.file.type : 'application/octet-stream',
          fileName: params.file.name,
          size: params.file.size,
          recentlyImgType: params.searchType ?? undefined,
        });

        if (res.code === 200) {
          params.onCapacitySuccess?.();
          //开始上传就需要把task队列关联去掉
          this.uploadIdMappingTaskId.delete(uploadId);

          return this.uploadRequest(res.data.s3Key, res.data.uploadUrl, xhr, params);
        }

        //@ts-ignore disable warning
        if (res.code === 2103) {
          //空间容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT,
            //@ts-ignore disable warning
            errMsg: res.msg,
          });
          return;
        }
        //@ts-ignore disable warning
        if (res.code === 2104) {
          //单文件容量超限
          params.onComplete?.({
            success: false,
            errCode: UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT,
            //@ts-ignore disable warning
            errMsg: res.msg,
          });
          return;
        }
        //@ts-ignore disable warning
        if (res !== 200) {
          //@ts-ignore disable warning
          throw Error(res.msg);
        }
      },
      onFailed: (err: Error) => {
        // eslint-disable-next-line no-console
        console.warn(err);
        params.onComplete?.({
          success: false,
          errCode: UploadErrorCode.UPLOAD_FAILED,
          errMsg: `上传失败[${err.message}]`,
        });
      },
      onSuccess: (res) => {
        res && params.onComplete?.(res);
      },
    });
    this.uploadIdMappingTaskId.set(uploadId, { taskId, params, xhr });
    return uploadId;
  }

  /**
   * 收集表未登录情况下沿用之前的上传逻辑，为了减少代码的复杂度，特意分开处理，请不要合并这两个上传方法的代码，免得不好维护
   * 由于没有取消的场景，因此取消相关逻辑没有添加上去
   * @param singleFileMaxCapacity 单文件容量大小
   * @returns
   */
  uploadWithoutLogin(
    params: Omit<UploadParams, 'userId'> & { viewId: string; singleFileMaxCapacity: number }
  ) {
    const taskPool = this.getTaskPool(params.type);
    const uploadId = uuid();

    const xhr = new XMLHttpRequest();
    const taskId = taskPool.enqueueTask({
      run: async () => {
        const res = await uploadInjector.request.infra.getS3UnsignedInfo.raw({
          spaceId: params.spaceId,
          viewId: params.viewId,
          fileName: params.file.name,
          fileType: params.type,
          size: params.file.size,
          mimeType: params.file.type.trim() ? params.file.type : 'application/octet-stream',
        });
        //检查容量
        if (res.code === 200) {
          if (res.data.currentCapacity + params.file.size > res.data.maxCapacity) {
            //空间容量超限
            params.onComplete?.({
              success: false,
              errCode: UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT,
              //@ts-ignore disable warning
              errMsg: res.msg || '空间容量超限，请联系对方升级空间',
            });
            return;
          }
          if (params.file.size > params.singleFileMaxCapacity) {
            //单文件容量超限
            params.onComplete?.({
              success: false,
              errCode: UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT,
              //@ts-ignore disable warning
              errMsg: res.msg || '文件大小超限，请联系对方升级空间',
            });
            return;
          }
        }
        if (res.code === 200) {
          params.onCapacitySuccess?.();

          return this.uploadRequest(res.data.s3Key, res.data.uploadUrl, xhr, params);
        }

        //@ts-ignore disable warning
        if (res !== 200) {
          //@ts-ignore disable warning
          throw Error(res.msg);
        }
      },
      onFailed: (err: any) => {
        params.onComplete?.({
          success: false,
          errCode: UploadErrorCode.UPLOAD_FAILED,
          errMsg: `上传失败[${JSON.stringify(err)}]`,
        });
      },
      onSuccess: (res: any) => {
        res && params.onComplete?.(res);
      },
    });

    this.uploadIdMappingTaskId.set(uploadId, { taskId, params, xhr });
    return uploadId;
  }

  private uploadRequest = (
    s3Key: string,
    uploadUrl: string,
    xhr: XMLHttpRequest,
    params:
      | UploadParams
      | (Omit<UploadParams, 'userId'> & { viewId: string; singleFileMaxCapacity: number })
  ) => {
    return new Promise((resolve) => {
      xhr.open('put', uploadUrl);
      xhr.setRequestHeader(
        'Content-type',
        params.file.type.trim() ? params.file.type : 'application/octet-stream'
      );

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          params.onProgress?.(Math.floor((event.loaded / event.total) * 100));
        }
      };

      xhr.onerror = () => {
        resolve({ success: false, errCode: UploadErrorCode.UPLOAD_FAILED, errMsg: '上传失败' });
      };
      xhr.onabort = () => {
        resolve({ success: false, errCode: UploadErrorCode.UPLOAD_FAILED, errMsg: '已取消' });
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve({ success: true, ossName: s3Key });
        } else {
          resolve({ success: false, errCode: UploadErrorCode.UPLOAD_FAILED, errMsg: '上传失败' });
        }
      };
      xhr.send(new Blob([params.file]));
    }).catch((e) => {
      console.log(e);
      return { success: false, errCode: UploadErrorCode.UPLOAD_FAILED, errMsg: '上传失败' };
      // throw new Error(e);
    });
  };

  abort(uploadId: string) {
    const task = this.uploadIdMappingTaskId.get(uploadId);
    task?.xhr.abort();

    if (task) {
      const removed = this.getTaskPool(task.params.type).removeTask(task.taskId);
      removed &&
        task.params.onComplete({
          success: false,
          errCode: UploadErrorCode.UPLOAD_CANCEL,
          errMsg: '已取消',
        });
    }
  }
}
