import {
  LIST_REMOVE_COLLECTION_VIEW,
  UPDATE_COLLECTION_VIEW,
} from 'src/redux/actions/collection-view';

import { dispatch, getState } from '../../store';

export const remove = (uuid: string) => {
  const { collectionViews } = getState();
  const view = collectionViews[uuid];
  if (!view) return;

  dispatch(UPDATE_COLLECTION_VIEW({ uuid, patch: { status: -1 } }));
  dispatch(LIST_REMOVE_COLLECTION_VIEW({ uuid, parentId: view.parentId })); // 引用多维表或者普通多维表
};
