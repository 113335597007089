import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useCellEditor } from './hooks';
import { PlainTextValueEditor } from './plain-text-value-editor';
import type { CellEditorProps, Site } from './types';

export type ValueEditorProps<T> = FC<{ site: Site; value: T; onChange: (value: T) => void }>;

interface GenericTextEditorParams<T> {
  useValue: (props: { recordId: string; propertyId: string }) => T;
  useSaveValue: (props: { recordId: string | string[]; propertyId: string }) => (value: T) => void;
  ValueEditor: ValueEditorProps<T>;
}

export function genericTextEditor<T>({
  useValue,
  useSaveValue,
  ValueEditor,
}: GenericTextEditorParams<T>): FC<CellEditorProps> {
  const GenericTextEditor: FC<CellEditorProps> = ({
    site,
    recordId,
    recordIds,
    propertyId,
    onUpdate,
    onClose,
  }) => {
    const initialValue = useValue({ recordId, propertyId });
    const [value, setValue] = useState(initialValue);
    const saveValue = useSaveValue({ recordId: recordIds ?? recordId, propertyId });
    const dirtyRef = useRef(false);

    const onSave = () => {
      if (dirtyRef.current) {
        saveValue(value);
      }
    };

    useCellEditor({
      onUpdate,
      onClose,
      onSave,
    });

    return (
      <div
        className="px-2 text-t2 py-[9px] min-w-full leading-[20px]"
        onKeyDown={(event) => {
          if (isHotkey('shift+enter')(event)) {
            return;
          }
          if (isHotkey('enter')(event)) {
            event.preventDefault();
            onSave();
            onClose();
          } else if (isHotkey('esc')(event)) {
            onClose();
          }
        }}
      >
        <ValueEditor
          site={site}
          value={value}
          onChange={(value) => {
            dirtyRef.current = true;
            setValue(value);
          }}
        />
      </div>
    );
  };
  return GenericTextEditor;
}

export const TextEditor = genericTextEditor<string>({
  useValue: ({ recordId, propertyId }) => {
    const value = useObservableStore(
      ({ blocks }) => {
        const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
        return segmentsToText(segments);
      },
      [recordId, propertyId]
    );
    return value;
  },
  useSaveValue: ({ recordId, propertyId }) => {
    const updatePropertyValue = useUpdatePropertyValue();
    return (value) => {
      updatePropertyValue(recordId, propertyId, textToSegments(value));
    };
  },
  ValueEditor: PlainTextValueEditor,
});
