import { fastEqual } from '@flowus/common/utils/tools';
import { createSetState, createStore } from '@flowus/common/zustand/utils';
import { request } from 'src/common/request';
import { getCurrentUser, useCurrentUser } from 'src/hooks/user';
import type { AsyncReturnType } from 'type-fest';

interface UserInfo {
  integralInfo?: AsyncReturnType<typeof request.infra.creditBalanceStatistics>;
}

type UserInfoType = Record<string, UserInfo>;

const $userBalance = createStore<UserInfoType>(() => ({}));
const $setUserBalance = createSetState($userBalance);

const setSpacePlanInfo = (userId: string, value: Partial<UserInfo>) => {
  $setUserBalance((pre) => {
    if (!pre[userId]) {
      pre[userId] = {};
    }
    pre[userId] = value;
    return pre;
  });
};

export const useUserBalance = () => {
  const currentUser = useCurrentUser();

  return $userBalance((state) => {
    return state[currentUser.uuid] || {};
  }, fastEqual);
};

export const fetchUserBalance = async () => {
  if (__PRIVATE__) return;
  const currentUser = getCurrentUser();
  const res = await request.infra.creditBalanceStatistics();
  setSpacePlanInfo(currentUser.uuid, {
    integralInfo: res,
  });
};
