import type { SegmentDTO } from '@next-space/fe-api-idl';
import { camelCase, upperFirst } from 'lodash-es';
import type { IconName } from 'src/common/components/icon';
import { segmentsToText } from 'src/editor/utils/editor';
import { FileRegex } from '@flowus/common/regex';
import { getFileNameInfo } from '@flowus/common';

export { getFileNameInfo };

/** segment转text再取文件名信息  */
export const getSegmentsFileNameInfo = (segments: SegmentDTO[] | null = []) => {
  return getFileNameInfo(segmentsToText(segments));
};

export const getDisplay = (ext = '') => {
  if (FileRegex.audio.test(ext)) return 'audio';
  if (FileRegex.video.test(ext)) return 'video';
  if (FileRegex.image.test(ext)) return 'image';
  return 'file';
};

/** 文字分割成两段
 @param str 文字
 @param num 尾部保留字数
 */
export const getStringSplitKeepTail = (str = '', num = 4) => {
  if (str.length <= num) {
    return [str, ''];
  }
  const subStr1 = str.substring(0, str.length - num);
  const subStr2 = str.substring(str.length - num);
  return [subStr1, subStr2];
};

/** 获取文件icon */
export const getFileIcon = (type = ''): IconName => {
  let icon = 'IcBlockFile';
  if (!type) return `IcBlockFile`;
  type = type.toLocaleLowerCase();

  if (FileRegex.image.test(type)) {
    icon = 'list_image';
  } else if (FileRegex.audio.test(type)) {
    icon = 'voice';
  } else if (FileRegex.video.test(type)) {
    icon = 'video';
  } else if (FileRegex.zip.test(type)) {
    icon = 'zip';
  } else if (FileRegex.ppt.test(type)) {
    icon = 'ppt';
  } else if (FileRegex.txt.test(type)) {
    switch (type) {
      case 'md':
      case 'html':
        icon = type;
        break;
      default:
        icon = 'txt';
    }
  } else {
    switch (type) {
      case 'pdf':
      case 'ppt':
      case 'apk':
      case 'torrent':
        icon = type;
        break;
      case 'xls':
      case 'xlsx':
        icon = 'excel';
        break;
      case 'doc':
      case 'docx':
        icon = 'word';
        break;
      default:
    }
  }
  if (icon === 'IcBlockFile') return 'IcBlockFile';
  return `MIc${upperFirst(camelCase(icon))}` as IconName;
};

/** 由于名字跟后缀是分开存储的，因此需要合并名字 */
export const combineFullName = (fileName?: string, extName?: string) => {
  return extName ? `${fileName}.${extName}` : fileName ?? '';
};

/** 获取图片宽高大小，仅支持file文件源 */
export const getImageSizeInfo = (
  file: File
): Promise<{ width: number; height: number; size: number; url: string }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => {
      resolve({ width: image.width, height: image.height, size: file.size, url: image.src });
    };
    image.onerror = () => {
      reject(new Error('图片解析失败'));
    };
    image.src = URL.createObjectURL(file);
  });
};

/** 获取图片宽高大小，仅支持src */
export const getImageSrcSizeInfo = (src = ''): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = () => {
      reject(new Error('图片解析失败'));
    };
    image.src = src;
  });
};

/** 获取视频宽高和大小，仅支持file文件源 */
export const getVideoSizeInfo = (
  file: File
): Promise<{ width: number; height: number; size: number; url: string }> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        size: file.size,
        url: video.src,
      });
    };
    video.onerror = () => {
      reject(new Error('视频解析失败'));
    };
    video.src = URL.createObjectURL(file);
  });
};

/** Image标签转base64地址 */
export const ImageToBase64 = (image: HTMLImageElement) => {
  const canvas = document.createElement('canvas'); // 创建canvas DOM元素
  const ctx = canvas.getContext('2d');
  image.crossOrigin = 'anonymous';
  canvas.height = image.naturalHeight; // 指定画板的高度,自定义
  canvas.width = image.naturalWidth; // 指定画板的宽度，自定义
  ctx?.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight); // 参数可自定义
  const dataURL = canvas.toDataURL();
  return dataURL;
};
