import { cx } from '@flowus/common/cx';
import type { ComponentProps } from 'react';

export const BlockBefore = (props: ComponentProps<'div'>) => {
  const { className, onClick, children, ...reset } = props;
  if (!children) return null;
  return (
    <div
      className={cx('flex items-start justify-center w-6 mr-1', className)}
      onClick={onClick}
      {...reset}
    >
      {children}
    </div>
  );
};
