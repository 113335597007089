import type { PageMeta } from 'src/services/page-metas';
import { $pageMetas } from 'src/services/page-metas';

export const usePageMeta = (pageId: string) => {
  return $pageMetas((state) => state[pageId] as PageMeta | undefined);
};

export const useIsNeedPay = (pageId: string) => {
  return usePageMeta(pageId)?.tag === 'NEED_PAY';
};
