import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { useCloseModal } from 'src/common/components/next-modal';
import { Modals } from 'src/modals';
import { getWindowWidth } from 'src/utils/window-size';
import type { FilePreviewProps } from '.';

const imageCache: any = new Map<string, boolean>();
const PaddingHorizontal = 40;
const PaddingVertical = 0;

interface ImageSize {
  width: number;
  height: number;
}

export const ImagePreview: FC<FilePreviewProps> = ({ downloadUrl: imageSrc }) => {
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const imageZoomRef = useRef<HTMLDivElement>(null);
  const initScale = useRef<any>();
  const imageBoxRectRef = useRef<any>();
  const imageResizeTimer = useRef<NodeJS.Timeout>();
  const [isShowImageResize, setIsShowImageResize] = useState(false);
  const [transform, setTransform] = useState('');
  const closeModal = useCloseModal();

  const getFitSizes = useCallback((width: number, height: number): ImageSize => {
    let maxHeight = imageBoxRectRef.current.height - PaddingVertical * 2;
    let maxWidth = imageBoxRectRef.current.width - PaddingHorizontal * 2;

    maxHeight = Math.min(maxHeight, height);
    maxWidth = Math.min(maxWidth, width);

    const maxRatio = maxWidth / maxHeight;
    const srcRatio = width / height;

    if (maxRatio > srcRatio) {
      return {
        width: (width * maxHeight) / height,
        height: maxHeight,
      };
    }

    return {
      width: maxWidth,
      height: (height * maxWidth) / width,
    };
  }, []);

  const loadImage = useCallback(async (): Promise<void> => {
    return new Promise((resolve) => {
      if (imageCache.has(imageSrc)) {
        resolve();
        return;
      }
      const inMemoryImage = new Image();

      inMemoryImage.onload = () => {
        imageCache.set(imageSrc, {
          width: inMemoryImage.width,
          height: inMemoryImage.height,
        });
        resolve();
      };
      inMemoryImage.src = imageSrc;
    });
  }, [imageSrc]);

  const setImageTransform = useCallback(
    ({ zoom = 1 }) => {
      const { width, height } = imageCache.get(imageSrc);
      const { width: targetWidth } = getFitSizes(width, height);

      let x = 0;
      const windowWidth = getWindowWidth();
      if (width > windowWidth) {
        x += (windowWidth - width) / 2;
      }
      const scaleFactor = zoom * (targetWidth / width);

      initScale.current = {
        x,
        scaleFactor,
        maxScaleFactor: (imageBoxRectRef.current.width - PaddingHorizontal * 2) / width,
      };
      setTransform(`translate3d(${x}px,${0}px,0) scale3d(${scaleFactor},${scaleFactor},1)`);
    },
    [getFitSizes, imageSrc]
  );

  const setImagePosition = useCallback(async () => {
    await loadImage();
    setImageTransform({});
  }, [setImageTransform, loadImage]);

  const zoom = useCallback(
    (zoomDir: 'in' | 'out') => {
      const transformArr = imageRef.current?.style.transform.split('scale3d(');
      if (!transformArr) return;

      const scaleFactor = transformArr[1]?.split(',')[0];
      let newScaleFactor = Number(scaleFactor);
      if (zoomDir === 'in') {
        newScaleFactor = Math.max(Number(scaleFactor) * 0.8, initScale.current.scaleFactor / 3);
      } else {
        newScaleFactor = Math.min(Number(scaleFactor) * 1.2, initScale.current.maxScaleFactor);
      }

      let y = 0;
      if (imageCache.get(imageSrc).height * newScaleFactor + 16 > imageBoxRectRef.current.height) {
        y =
          (imageCache.get(imageSrc).height * newScaleFactor + 16 - imageBoxRectRef.current.height) /
          2;
      }

      setTransform(
        `translate3d(${initScale.current.x}px,${y}px,0) scale3d(${newScaleFactor},${newScaleFactor},1)`
      );
    },
    [imageSrc]
  );

  useEffect(() => {
    imageBoxRectRef.current = imageContainerRef.current?.getBoundingClientRect() as DOMRect;

    void setImagePosition();
    return () => clearResizeTimer();
  }, [setImagePosition]);

  const clearResizeTimer = () => {
    if (imageResizeTimer.current) {
      clearTimeout(imageResizeTimer.current);
    }
  };

  const controlResizeButtonVisible: React.MouseEventHandler<HTMLDivElement> | undefined = (
    event
  ) => {
    if (imageZoomRef.current?.contains(event.target as HTMLDivElement)) {
      clearResizeTimer();
      return;
    }

    const setHideResizeTimer = () => {
      imageResizeTimer.current = setTimeout(() => {
        clearResizeTimer();
        setIsShowImageResize(false);
      }, 1000);
    };

    if (!isShowImageResize) {
      setIsShowImageResize(true);
      setHideResizeTimer();
    } else {
      clearResizeTimer();
      setHideResizeTimer();
    }
  };

  return (
    <div
      className="absolute top-2 bottom-2 left-0 w-full overflow-y-auto py-2"
      ref={imageContainerRef}
      onMouseMove={controlResizeButtonVisible}
      onClick={(event) => {
        if (!imageZoomRef.current?.contains(event.target as HTMLElement)) {
          closeModal(Modals.FILE_PREVIEW);
        }
      }}
    >
      {!transform ? (
        <LoadingIcon className="absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto" />
      ) : (
        <>
          <div
            ref={imageZoomRef}
            className={cx(
              'fixed z-50 flex items-center px-1 py-1 -translate-x-1/2 bg-black-base/90 left-1/2 bottom-1 rounded transition-opacity duration-150',
              isShowImageResize ? 'opacity-100' : 'opacity-0'
            )}
          >
            <button
              onClick={() => zoom('in')}
              className="flex items-center justify-center p-2 mr-1 rounded animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
            >
              <Icon name="MIcZoomIn" size="large" />
            </button>
            <button
              onClick={() => zoom('out')}
              className="flex items-center justify-center p-2 rounded animate-hover hover:bg-white1/10 dark:hover:bg-grey6"
            >
              <Icon name="MIcZoomOut" size="large" />
            </button>
          </div>
          <img
            className="absolute top-0 bottom-0 left-0 right-0 mx-auto my-auto max-w-none"
            src={imageSrc}
            alt="大图预览"
            style={{ transform }}
            ref={imageRef}
          />
        </>
      )}
    </div>
  );
};
