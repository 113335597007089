import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { usePermissions } from 'src/hooks/share/use-permissions';

interface Props {
  uuid: string;
  onClick: () => void;
  className?: string;
}

export const SearchBar: FC<Props> = ({ uuid, onClick, className = '' }) => {
  const { role } = usePermissions(uuid);

  const isEditor = role === PermissionRole.EDITOR;

  return (
    <div
      onClick={isEditor ? onClick : undefined}
      className={cx(className, isEditor && 'animate-click', !isEditor && 'cursor-not-allowed')}
    >
      <Input
        readonly
        inputClassName={'h-8 text-grey4 px-0 pointer-events-none'}
        addonBefore={<Icon name="IcSearch" size="middle" className="ml-3 mr-1 text-grey4" />}
        placeholder={'搜索人员、组、手机号、邮箱'}
        addonAfter={
          <span
            className={cx(
              'flex justify-center items-center w-8 h-8 rounded-r-sm border-l border-grey6',
              isEditor ? 'animate-click' : 'cursor-not-allowed'
            )}
          >
            <Icon name="IcAddBoard" className="text-grey3" size="middle" />
          </span>
        }
      />
    </div>
  );
};
