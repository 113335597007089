import { cx } from '@flowus/common/cx';
import { OverlayContainerContext } from '@flowus/common/hooks/react-utils';
import { deepEqual } from '@flowus/common/utils/tools';
import type { FC, MutableRefObject } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { Virtuoso } from 'react-virtuoso';
import { antiCycleSet_BlockEditor as antiCycleSet_BlockEditor0 } from 'src/components/activities/update-log-modal';
import { antiCycleSet_PageContent } from 'src/components/page-preview/lite-page';
import { PageContent } from 'src/components/page-preview/page-content';
import { antiCycleSet_PageContent1 } from 'src/components/preview-page-content';
import { useLocationBlock } from 'src/hooks/block/use-location-block';
import { useObserverPageContentSize } from 'src/hooks/page/use-observer-page-size';
import { usePageTotalCount } from 'src/hooks/page/use-page-word-count';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentUserSelector } from 'src/hooks/user';
import type { ReadonlyProp } from 'src/redux/types';
import { useIsEnableRedux } from 'src/services/rxjs-redux/common';
import { $searchParams } from 'src/utils';
import { isShareLink, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { antiCycleSet_BlockEditor as antiCycleSet_BlockEditor1 } from 'src/views/main/page-doc';
import {
  usePageScrollRef,
  usePageVirtualListMode,
  useSetPageLazyListLoading,
  useSetPageVirtualListMode,
} from 'src/views/main/page-doc/context';
import '../all-renderers';
import { antiCycleSet_BlockEditor as antiCycleSet_BlockEditor2 } from './ai-editor';
import { LazySubNodeList } from './block';
// import { getBlockLength, getVisibleThresholdConfig } from './get-block-length';
import { HoverMenu } from './hover-menu';
import { MembersCoordinate } from './members-coordinate';
import { PagePlaceholderContent } from './page-placeholder-content';
import { Selector } from './selector';
import { BlockItem } from './visual-block-item';

interface Props extends ReadonlyProp {
  uuid: string;
  className?: string;
  visibleRender?: boolean;
}

export const BlockEditor0: FC<Props> = memo(({ uuid, className, readonly }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useObserverPageContentSize(containerRef);
  const virtualRef = useRef<VirtuosoHandle | null>(null);
  const { isVirtualListMode } = usePageVirtualListMode();
  const setIsVirtualListMode = useSetPageVirtualListMode();
  const subNodes = usePickBlock(uuid, ['subNodes'], [], { enableAuditTime: false })?.subNodes;
  const isSharePage = isShareLink();
  // const isEnableRedux = useIsEnableRedux();

  const { allowDuplicate } = usePermissions(uuid);
  const enableHighPerformance = useCurrentUserSelector(
    (user) => user.setting?.enableHighPerformance
  );
  const pageId = useGetPageId();
  const { blockCount } = usePageTotalCount(pageId);
  const setPageLazyListLoading = useSetPageLazyListLoading();

  const scroll = usePageScrollRef();
  const [container, setContainer] = useState<HTMLDivElement | null>(scroll.current);
  useLocationBlock({ container: scroll });

  // 高性能模式滚动容器
  useEffect(() => {
    setContainer(scroll.current as HTMLDivElement | null);
  }, [scroll]);

  // 是否开启性能模式
  useEffect(() => {
    setPageLazyListLoading(false);
    if ($searchParams.print) return;
    if (blockCount < 500) return;
    if (isSharePage) {
      return;
    }
    if (enableHighPerformance || blockCount >= 5000) {
      setIsVirtualListMode({
        isVirtualListMode: true,
        virtualRef,
        subNodes: subNodes || [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!container) return null;

  return (
    <div
      data-page-id={uuid}
      className={cx(
        'next-space-page-content block-list pt-5',
        !$searchParams.print && 'relative flex flex-col flex-shrink-0',
        className
      )}
      ref={containerRef}
    >
      <OverlayContainerContext.Provider value={containerRef}>
        {isVirtualListMode ? (
          <VirtuosoBox subNodes={subNodes} virtualRef={virtualRef} container={container} />
        ) : (
          <LazySubNodeList
            // 非 beta 模式，或者是打印模式，忽略此开关
            // visibleRender={isEnableRedux || $searchParams.print ? undefined : RenderMode.innerHtml}
            root
            key={uuid}
            subNodes={subNodes}
          />
        )}
        <HoverMenu readonly={readonly} />
        {!readonly && <MembersCoordinate type="page" />}
        {(!readonly || allowDuplicate) && <Selector />}
        {!readonly && !subNodes?.length && !$searchParams.print && (
          <PagePlaceholderContent uuid={uuid} />
        )}
      </OverlayContainerContext.Provider>
    </div>
  );
}, deepEqual);

export const BlockEditor = memo(BlockEditor0, deepEqual);
antiCycleSet_BlockEditor0(BlockEditor);
antiCycleSet_BlockEditor1(BlockEditor);
antiCycleSet_BlockEditor2(BlockEditor);
antiCycleSet_PageContent(PageContent);
antiCycleSet_PageContent1(PageContent);

const VirtuosoBox: FC<{
  virtualRef: MutableRefObject<VirtuosoHandle | null>;
  container: HTMLDivElement;
  subNodes?: string[];
}> = memo((props) => {
  const { virtualRef, subNodes = [], container } = props;
  const isEnableRedux = useIsEnableRedux();

  const renderItemContent = useCallback((_, uuid) => {
    return <BlockItem uuid={uuid} />;
  }, []);

  const renderItem = useCallback(({ children, ...props }) => {
    return <div {...props}>{children}</div>;
  }, []);

  return (
    <Virtuoso
      ref={virtualRef}
      customScrollParent={container}
      overscan={1000}
      increaseViewportBy={1000}
      data={subNodes}
      computeItemKey={(_, data) => data}
      defaultItemHeight={100}
      // redux 的状态下，不启用下面这个，table 后文字无法输入
      components={isEnableRedux ? {} : { Item: memo(renderItem) }}
      itemContent={renderItemContent}
    />
  );
});
