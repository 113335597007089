import { sleep } from '@flowus/common/async';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { request } from 'src/common/request/request';

export const useRestoreRole = () => {
  const openModal = useOpenModal();

  return useCallback(
    (pageId: string) => {
      openModal.warning({
        title: '确定重置页面权限吗？',
        content: (
          <div className="text-left text-t2 space-y-2">
            <p>此操作可以恢复无权限访问的数据</p>
            <p>页面位置可能会发生移动</p>
          </div>
        ),
        confirmText: '确认',
        confirm: async () => {
          await request.editor.resetPagePermission(pageId);
          message.success('成功');
          await sleep(1000);
          location.reload();
        },
      });
    },
    [openModal]
  );
};
