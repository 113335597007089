import type { SpaceDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { UPDATE_SPACE } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';

export const useUpdateSpace = () => {
  return useCallback(
    (
      uuid: string,
      patch: Partial<
        Pick<
          SpaceDTO,
          | 'title'
          | 'icon'
          | 'permissionGroups'
          | 'domain'
          | 'publicHomePage'
          | 'isShowAd'
          | 'setting'
        >
      >
    ) => {
      dispatch(
        UPDATE_SPACE({
          uuid,
          patch,
        })
      );
    },
    []
  );
};
