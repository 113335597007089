import { ossVideoSnapshotUrl } from '@flowus/common/url';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import type { VideoJSProps } from './_video';

export const VideoJS = (props: VideoJSProps) => {
  const light = ossVideoSnapshotUrl(props.videoUrl);
  const showLight = __BUILD_IN__ ? false : light;
  const [showVideo, setShowVideo] = useState(!showLight);

  return (
    <>
      {showVideo ? (
        <ReactPlayer
          loading="lazy"
          width="100%"
          height="100%"
          controls
          url={props.videoUrl}
          stopOnUnmount
          style={props.style}
          fallback={<LoadingContainer />}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        />
      ) : (
        <div
          onClick={() => setShowVideo(true)}
          className="relative flex items-center justify-center cursor-pointer"
        >
          <Icon className="absolute z-10 text-grey3" name="IcMenuNext" size="max" />
          <img src={light} />
        </div>
      )}
    </>
  );
};
