import { cx } from '@flowus/common/cx';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import _ from 'lodash-es';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Select } from 'src/common/components/select';
import { SortableList } from 'src/common/components/sortable-list';
import { Switch } from 'src/common/components/switch';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useObservableBlock, useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { ICON_MAP } from '../const';
import { useBitable } from '../context';
import { ViewIconMap } from './const';
import type { SettingProps } from './type';

interface Sorter {
  property: string;
  direction: 'asc' | 'desc';
  disable?: boolean;
}

export const ConfigTableSort: FC<SettingProps> = ({ isFromViewSetting }) => {
  const { viewId, collectionId, isLocked, managerReadonly } = useBitable();
  const view = useCollectionView(viewId);

  const schema = useObservableBlock(collectionId, (collection) => {
    return collection?.data.schema ?? {};
  });

  const sorters = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return view?.format.sorters ?? [];
    },
    [viewId]
  );

  const items = sorters
    .filter((it) => {
      return schema[it.property]?.type !== CollectionSchemaType.ID_NUMBER;
    })
    .map((it) => {
      return {
        ...it,
        id: it.property,
      };
    });

  const nextCreateProperty = Object.keys(schema).find((it) =>
    sorters.every((sorter) => sorter.property !== it)
  );

  if (!view) return null;

  const handleCreateSorter = () => {
    if (isLocked) return;
    if (!nextCreateProperty) return;

    bizTracker.event('bitable_view_sort_manage', { manage_type: 'create' });

    const newSorters = _.cloneDeep(view.format.sorters) ?? [];
    newSorters.push({
      property: nextCreateProperty,
      direction: 'asc',
      disable: false,
    });
    updateViewFormat(viewId, { sorters: newSorters }, managerReadonly);
  };

  const handleSorted = (newItems: (Sorter & { id: string })[]) => {
    updateViewFormat(
      viewId,
      {
        sorters: newItems.map((it) => ({
          property: it.property,
          direction: it.direction,
          disable: it.disable,
        })),
      },
      managerReadonly
    );
  };

  const setSorterProperty = (property: string, newProperty: string) => {
    const newSorters = _.cloneDeep(view.format.sorters) ?? [];
    const sorter = newSorters?.find((it) => it.property === property);
    if (sorter) {
      sorter.property = newProperty;
    }
    updateViewFormat(viewId, { sorters: newSorters }, managerReadonly);
  };

  const setSorterDirection = (property: string, direction: string) => {
    const newSorters = _.cloneDeep(view.format.sorters) ?? [];
    const sorter = newSorters?.find((it) => it.property === property);
    if (sorter) {
      sorter.direction = direction as 'asc' | 'desc';
    }
    updateViewFormat(viewId, { sorters: newSorters }, managerReadonly);
  };

  const removeSorter = (property: string) => {
    const newSorters = _.cloneDeep(view.format.sorters) ?? [];
    updateViewFormat(
      viewId,
      { sorters: newSorters.filter((it) => it.property !== property) },
      managerReadonly
    );
  };

  const disableAllSortersOrNot = (disable: boolean) => {
    const newSorters = _.cloneDeep(view.format.sorters) ?? [];
    if (newSorters) {
      for (const sorter of newSorters) {
        sorter.disable = disable;
      }
    }
    updateViewFormat(viewId, { sorters: newSorters }, managerReadonly);
  };
  const disableAllSorters = () => {
    bizTracker.event('bitable_view_sort_manage', { manage_type: 'on' });
    disableAllSortersOrNot(true);
  };
  const enableAllSorters = () => {
    bizTracker.event('bitable_view_sort_manage', { manage_type: 'off' });
    disableAllSortersOrNot(false);
  };

  const enabled = sorters.some((it) => !it.disable);
  const hasSortCondition = sorters.length > 0;

  return (
    <div className={cx(isFromViewSetting ? 'w-full' : 'next-modal-scroll w-[400px]')}>
      {hasSortCondition && (
        <div className="flex justify-between h-8 m-2 mx-4">
          <div className="flex items-center cursor-default text-t2">
            <Icon className="mr-1" name={ViewIconMap[view.type]} size="middle" />
            <span className="mr-2 font-medium">排序设置</span>
          </div>
          <div className="flex items-center text-t2">
            <span className="mr-1">{enabled ? '已生效' : '已停用'}</span>
            <Switch
              open={enabled}
              disabled={isLocked}
              onSwitch={() => {
                if (isLocked) return;
                if (enabled) {
                  disableAllSorters();
                } else {
                  enableAllSorters();
                }
              }}
            />
          </div>
        </div>
      )}

      <SortableList
        disabled={isLocked}
        className={cx(items.length > 0 && 'm-2')}
        items={items}
        renderItemContent={({ item, renderDragHandle }) => {
          return (
            <div className="flex items-center h-10 px-2 text-t2">
              {renderDragHandle({
                className:
                  'cursor-pointer flex items-center justify-center w-5 h-5 mr-1 text-grey4',
              })}
              <div className="flex items-center flex-1">
                <PropertySelect
                  className="max-w-[200px]"
                  value={item.property}
                  onChange={(property) => {
                    setSorterProperty(item.property, property);
                  }}
                />
                <OrderingSelect
                  className="ml-2"
                  property={item.property}
                  value={item.direction}
                  onChange={(direction) => {
                    setSorterDirection(item.property, direction);
                  }}
                />
              </div>
              {!isLocked && (
                <Icon
                  size="large"
                  className="cursor-pointer text-grey3 hover:text-grey1"
                  name="IcToastClose"
                  onClick={() => {
                    bizTracker.event('bitable_view_sort_manage', { manage_type: 'delete' });
                    removeSorter(item.property);
                  }}
                />
              )}
            </div>
          );
        }}
        onChange={handleSorted}
      />
      {/* TODO: Add tooltip when disabled */}
      <button
        className={cx(
          'flex items-center w-full h-10 p-2 animate-hover pl-4 text-grey3',
          hasSortCondition && 'border-t border-grey6',
          isLocked && 'cursor-not-allowed'
        )}
        disabled={!nextCreateProperty}
        onClick={handleCreateSorter}
      >
        <Icon className="mr-1" name="IcAdd" size="middle" />
        <span className="text-t2">创建排序条件</span>
      </button>
    </div>
  );
};

const PropertySelect: FC<{
  className?: string;
  value: string;
  onChange?: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { collectionId, viewId, isLocked } = useBitable();
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema ?? {};
  });
  const sorters = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return view?.format.sorters ?? [];
    },
    [viewId]
  );
  return (
    <Select
      readonly={isLocked}
      className={className}
      dropdownClassName="max-w-[260px]"
      value={value}
      onChange={onChange}
      options={Object.keys(schema)
        .filter((key) => {
          if (schema[key]?.type === CollectionSchemaType.ID_NUMBER) return false;
          return key === value || sorters.every((sorter) => sorter.property !== key);
        })
        .map((key) => {
          const type = schema[key]?.type;
          return {
            value: key,
            title: schema[key]?.name ?? '',
            icon: type && ICON_MAP[type],
          };
        })}
    />
  );
};

const OrderingSelect: FC<{
  className?: string;
  property: string;
  value: string;
  onChange?: (value: string) => void;
}> = ({ className, value, onChange, property }) => {
  const { isLocked, collectionId } = useBitable();
  const schema = useObservableBlock(collectionId, (block) => {
    return block?.data.schema ?? {};
  });

  const isSelect =
    schema[property]?.type === CollectionSchemaType.SELECT ||
    schema[property]?.type === CollectionSchemaType.MULTI_SELECT;

  return (
    <Select
      readonly={isLocked}
      className={className}
      value={value}
      onChange={onChange}
      options={[
        { value: 'asc', title: isSelect ? '选项正序' : '升序' },
        { value: 'desc', title: isSelect ? '选项倒序' : '降序' },
      ]}
    />
  );
};
