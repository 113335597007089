import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { cx } from '../cx';

interface Props {
  size: number;
  className?: string;
  success: (s: string) => void;
  onError?: () => void;
  onChange?: (str: string) => void;
  placeholder?: string;
  showSwitchIcon?: boolean;
}
export const SingleInput: FC<Props> = (props) => {
  const {
    size,
    success,
    className,
    onChange,
    onError,
    placeholder = '请输入密码 区分大小写',
    showSwitchIcon = true,
  } = props;
  const [state, setState] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  // 监听是否已经填写完，触发success
  useEffect(() => {
    if (state.length === size) {
      if (/^[a-zA-Z0-9]+$/.test(state)) {
        success(state);
      } else {
        onError?.();
      }
    }
  }, [onError, size, state, success]);

  return (
    <div className="relative flex justify-center items-center w-full">
      <input
        autoFocus
        type="text"
        maxLength={size}
        autoCorrect="off"
        autoCapitalize="off"
        placeholder={placeholder}
        className={cx(
          'transition-all w-full text-t2 bg-grey9 border border-grey6 focus:border-black h-9 px-2.5 rounded placeholder-grey4 pr-6',
          showPassword ? '' : 'textSecurity',
          className
        )}
        onInput={(event) => {
          const inputValue = event.currentTarget.value.replaceAll(`'`, '');
          setState(inputValue);
          onChange?.(inputValue);
        }}
      />
      {showSwitchIcon && (
        <span
          className="absolute right-2 animate-click"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? '😶' : '😶‍🌫️'}
        </span>
      )}
    </div>
  );
};
