import { cx } from '@flowus/common/cx';
import type { IconData, SegmentDTO, UserDTO } from '@next-space/fe-api-idl';
import { PermissionType, TextType } from '@next-space/fe-api-idl';
import { css } from 'otion';
import type { FC } from 'react';
import { useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { Avatar } from 'src/common/components/avatar';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { SelectView } from 'src/common/components/select-view';
import { usePropertySegments } from 'src/hooks/block/use-property-segments';
import { useUsers } from 'src/hooks/page/use-subscription-data';
import { useCurrentSpace } from 'src/hooks/space';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import type { LocalUser } from 'src/redux/reducers/users';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { searchUsers } from 'src/utils/search-util';
import type { CellViewProps } from './types';
import { Site } from './types';
import { LOCAL_LNG } from '@flowus/common/const';

type AvatarSize = 'default' | 'small' | 'xsmall';

export const AllSpaceUserView: FC<{
  className?: string;
  name?: string;
  backgroundColor?: string;
  spaceName?: string;
  icon?: IconData;
  size?: AvatarSize;
}> = ({ name, icon, size = 'default', spaceName, className, backgroundColor }) => {
  return (
    <div
      className={cx('flex items-center overflow-hidden whitespace-nowrap leading-5', className)}
      title={name}
    >
      <Avatar
        className={cx(
          '!text-t2-medium mr-2 rounded-md text-white',
          size === 'small' && '!h-5 !w-5',
          size === 'xsmall' && '!text-t4-medium !h-4 !w-4'
        )}
        name={spaceName || '未'}
        icon={icon}
        color={backgroundColor}
        iconSize={size === 'small' ? 20 : 24}
      />
      <span className="text-ellipsis">{name}</span>
    </div>
  );
};
export const MemberGroupView: FC<{
  className?: string;
  name?: string;
  icon?: IconData;
  size?: AvatarSize;
}> = ({ name, icon, size = 'default', className }) => {
  return (
    <div
      className={cx('flex items-center overflow-hidden whitespace-nowrap leading-5', className)}
      title={name}
    >
      <Avatar
        className={cx(
          '!text-t2-medium mr-2 rounded-md text-white',
          size === 'small' && '!h-5 !w-5',
          size === 'xsmall' && '!text-t4-medium !h-4 !w-4'
        )}
        name={name || '未'}
        icon={icon}
        iconSize={size === 'small' ? 20 : 24}
      />
      <span className="text-ellipsis">{name}</span>
    </div>
  );
};

export const PersonValueView: FC<{
  userId: string;
  className?: string;
  size?: AvatarSize;
}> = ({ userId, className, size = 'default' }) => {
  const user = useUser(userId);
  const userName = useUserName(userId, user?.nickname);
  return (
    <div
      className={cx('flex items-center overflow-hidden whitespace-nowrap leading-5', className)}
      title={userName || user?.phone}
    >
      <Avatar
        className={cx(
          '!text-t2-medium mr-2 rounded-full text-white',
          { '!h-5 !w-5': size === 'small' },
          { '!text-t4-medium h-4 w-4': size === 'xsmall' }
        )}
        imgClassName="rounded-full"
        name={userName || '客'}
        color={user?.backgroundColor || undefined}
        icon={user?.avatar ? { type: 'upload', value: user.avatar } : undefined}
        iconSize={size === 'small' ? 20 : 24}
      />
      <span className="text-ellipsis">{userName || user?.phone}</span>
    </div>
  );
};

export const UpdatedByValue: FC<CellViewProps> = ({ recordId, className, site }) => {
  const userId = useObservableBlock(recordId, (block) => block?.updatedBy);
  if (userId == null) return null;
  return (
    <PersonValueView
      className={cx(
        'my-px',
        className,
        (site === Site.FIELD || site === Site.CELL) && 'px-2 py-1.5'
      )}
      size={isNotTableView(site) ? 'xsmall' : 'default'}
      userId={userId}
    />
  );
};

export const CreatedByValue: FC<CellViewProps> = ({ recordId, className, site }) => {
  const userId = useObservableBlock(recordId, (block) => block?.createdBy);
  if (userId == null) return null;
  return (
    <PersonValueView
      className={cx(
        className,
        'my-px',
        (site === Site.FIELD || site === Site.CELL) && 'px-2 py-1.5'
      )}
      size={isNotTableView(site) ? 'xsmall' : 'default'}
      userId={userId}
    />
  );
};

const isNotTableView = (site: Site | undefined) => {
  return site === Site.CARD || site === Site.LIST || site === Site.CALENDAR;
};

export const PersonTag: FC<{
  userId: string;
  showClose?: boolean;
  onClose?: () => void;
  site?: Site;
  type?: PermissionType;
}> = ({ userId, showClose, onClose, site, type = PermissionType.USER }) => {
  const maybeSpace = useCurrentSpace();
  const maybeGroup = maybeSpace.permissionGroups?.find((v) => v.id === userId);
  return (
    <div
      className={cx(
        'text-t2 flex min-w-0 flex-nowrap items-center',
        (site === Site.CARD || site === Site.LIST || site === Site.CALENDAR) && 'mr-2'
      )}
    >
      {type === PermissionType.USER ? (
        <PersonValueView
          userId={userId}
          size={isNotTableView(site) ? 'xsmall' : 'default'}
          className={cx(
            'my-px',
            isNotTableView(site) ? '' : 'px-2 py-1.5',
            (site === Site.CARD || site === Site.LIST || site === Site.CALENDAR) && 'text-t4'
          )}
        />
      ) : type === PermissionType.GROUP ? (
        <MemberGroupView
          name={maybeGroup?.name}
          icon={maybeGroup?.icon}
          className={cx(
            'my-px',
            isNotTableView(site) ? '' : 'px-2 py-1.5',
            (site === Site.CARD || site === Site.LIST || site === Site.CALENDAR) && 'text-t4'
          )}
        />
      ) : (
        <AllSpaceUserView
          name={'全体成员'}
          icon={maybeSpace.icon}
          backgroundColor={maybeSpace.backgroundColor}
          className={cx(
            'my-px',
            isNotTableView(site) ? '' : 'px-2 py-1.5',
            (site === Site.CARD || site === Site.LIST || site === Site.CALENDAR) && 'text-t4'
          )}
        />
      )}
      {showClose && (
        <Icon
          size="middle"
          className="text-brown ml-[-8px] cursor-pointer opacity-50"
          onClick={onClose}
          name={'IcToastClose'}
        />
      )}
    </div>
  );
};

export const copyNormalizedValue = (value: SegmentDTO[] | undefined) => {
  if (!value) return [];
  const users = new Map<string, SegmentDTO>();
  value.forEach((it) => {
    if (it.type === TextType.USER) {
      it.uuid && users.set(it.uuid, it);
    }
  });
  return [...users.values()];
};

export const PersonValue: FC<CellViewProps> = ({ recordId, propertyId, site, className }) => {
  const segments = usePropertySegments(recordId, propertyId);
  const users = copyNormalizedValue(segments);

  return (
    <div
      className={cx(
        'flex',
        site === Site.LIST || site === Site.CALENDAR ? '' : 'flex-wrap',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '8px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        className
      )}
    >
      {users.map((it) => {
        return <PersonTag key={it.uuid} userId={it.uuid ?? ''} site={site} />;
      })}
    </div>
  );
};

export const PersonInput: FC<{
  className?: string;
  value?: string;
  onChange: (value: string) => void;
}> = ({ className, value, onChange }) => {
  const { isLocked } = useBitable();
  const users = useUsers();
  return (
    <SelectView
      readonly={isLocked}
      className={className}
      value={value}
      content={
        value === 'me' ? '我' : value && <PersonValueView className="!px-0" userId={value} />
      }
      onChange={onChange}
      Dropdown={PeopleDropdown}
      dropdownProps={{
        value,
        users,
      }}
    />
  );
};

export const PeopleDropdownContent: FC<{
  value?: string;
  users: UserDTO[];
  noMe?: boolean;
  onCloseModal: () => void;
  onChange?: (userId: string) => void;
}> = ({ noMe = false, users, value, onCloseModal, onChange }) => {
  const [search, setSearch] = useState('');
  const items = [
    ...(noMe
      ? []
      : [
          {
            type: ListItemType.OPERATION,
            data: {
              content: <span>我</span>,
              value: 'me',
              thesaurus: `我 wo`,
              onClick: () => {
                onChange?.('me');
                onCloseModal();
              },
            },
          },
        ]),
    ...searchUsers(users, search).map((user) => {
      return {
        type: ListItemType.OPERATION,
        data: {
          content: <PersonValueView className="!px-0" userId={user.uuid} />,
          value: user.uuid,
          onClick: () => {
            onChange?.(user.uuid);
            onCloseModal();
          },
        },
      };
    }),
  ];

  return (
    <>
      <Input
        autoFocus
        className="mx-2.5 mt-[2px] mb-[6px] h-8"
        value={search}
        onChange={(value) => {
          setSearch(value);
        }}
      />
      <ListView
        className="text-t2 max-h-[50vh] overflow-y-auto"
        defaultActiveIndex={items.findIndex((item) => item.data.value === value)}
        items={items}
      />
    </>
  );
};

const PeopleDropdown: FC<{
  value?: string;
  users: LocalUser[];
  onCloseModal: () => void;
  onChange?: (userId: string) => void;
}> = ({ ...props }) => {
  return (
    <div className="next-modal py-2">
      <PeopleDropdownContent {...props} />
    </div>
  );
};
