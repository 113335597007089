import { cx } from '@flowus/common/cx';
import type { ResizeResult } from '@flowus/common/hooks/use-resize-listener';
import { useResizeListener, ResizeStatus } from '@flowus/common/hooks/use-resize-listener';
import type { CSSProperties, FC } from 'react';
import React, { Children, isValidElement, useMemo, useRef } from 'react';
import { batch } from 'react-redux';
import { useSize } from 'src/common/utils/use-size';
import { useReadonly } from 'src/hooks/page';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';
import type { NextBlock, ReadonlyProp } from 'src/redux/types';
import { setAppUiState, useIsMobileSize, useIsSelecting } from 'src/services/app/hook';
import { useDropInfoPositionById } from 'src/services/app/hook/use-drop-info';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { pickBlock, usePickBlock } from 'src/utils/pick-block';
import { useIsInRight } from 'src/utils/right-utils';
import type { BlockElement } from '../core/type';
import { useClickBlankAreaCreateBlock } from '../uikit/blank-area';
import { BlockDrop } from './dnd/block-drop';

/** columnItem之间的间隔 */
const COLUMN_ITEM_SPACE = 46;
/** 可拖拽最小区域 */
const COLUMN_ITEM_MIN_WIDTH = 70;
/** type === BlockType.COLUMN_LIST */
export const ColumnListElement: BlockElement = React.memo((props) => {
  const block = usePickBlock(props.id, ['subNodes']);
  const isDragging = useIsDragging();
  const isSelecting = useIsSelecting();
  const children = Children.toArray(props.children);
  const transaction = useTransaction();
  const readonly = useReadonly(props.id);
  const isInRight = useIsInRight();
  const handleMouseDown = useClickBlankAreaCreateBlock();

  // 这里是为了监听分栏比例变化
  const columnItemBlock: Pick<NextBlock, 'uuid' | 'data'>[] = useObservableStore(
    (state) => {
      const block = state.blocks[props.id];
      if (!block) return [];
      const subBlocks: Pick<NextBlock, 'uuid' | 'data'>[] = [];
      block.subNodes.forEach((id) => {
        const columnItemBlock = state.blocks[id];
        if (columnItemBlock) {
          const partialBlock = pickBlock(columnItemBlock, ['data'], ['columnRatio']);
          if (partialBlock) {
            subBlocks.push(partialBlock);
          }
        }
      });
      return subBlocks;
    },
    [props.id]
  );

  const [totalRatio, ratios] = useMemo(() => {
    const ratios: number[] = [];
    let totalRatio = 0;
    columnItemBlock.forEach((block) => {
      const ratio = block.data.columnRatio || 1 / columnItemBlock.length;
      totalRatio += ratio;
      ratios.push(ratio);
    });
    return [totalRatio, ratios];
  }, [columnItemBlock]);

  if (__HOST_LOCAL__ && block && children.length !== columnItemBlock.length) {
    // eslint-disable-next-line no-console
    console.error(`分栏 block 的 subPages 与 children 长度对不上号`);
  }

  /**
   * 策略计算规则：https://www.notion.so/c5852844e4bf49468bc33f21251ba3cc
   * 默认的分栏系数是1，展示的时候根据总分栏系数占比来分配，不需要保证新加的必须占1/n(n为分栏总数)

   * 当拖动的时候只会改变2个columnItem的宽度，总宽度不变。
   * 根据各自宽度的占比算出改变后的比例，最后转换成规则里面的约定系数，更新对应的2个column block即可
   */
  const computeRatio = (index: number, result: ResizeResult) => {
    const columnListContainer = querySelectorFromMainContent(
      `.column-list-${props.id}`,
      isInRight
    ) as HTMLDivElement | null;
    if (!columnListContainer) return;

    const getMinWidth = (e: Element) => {
      let ret = COLUMN_ITEM_MIN_WIDTH;
      const list = e.querySelectorAll('[data-column-item-min-width]');
      for (const node of list) {
        if (node instanceof HTMLElement) {
          if (node.dataset.columnItemMinWidth) {
            const minWidth = parseFloat(node.dataset.columnItemMinWidth);
            if (minWidth > ret) {
              ret = minWidth;
            }
          }
        }
      }
      return ret;
    };

    // 拿到column的宽度和算出改变后的宽度
    const columnNodes: Element[] = [];
    const columnNodesMinWidth: number[] = [];
    columnListContainer.childNodes.forEach((v) => {
      if (v instanceof Element && v.classList.contains('column-block')) {
        columnNodes.push(v);
        columnNodesMinWidth.push(getMinWidth(v));
      }
    });

    const columnListWidth =
      columnListContainer.getBoundingClientRect().width -
      COLUMN_ITEM_SPACE * (columnNodes.length - 1);
    const currentColumnItem = columnNodes[index];
    const currentColumnItemMinWidth = columnNodesMinWidth[index] ?? 0;
    const currentColumnId = columnItemBlock[index]?.uuid;

    const nextColumnItem = columnNodes[index + 1];
    const nextColumnItemMinWidth = columnNodesMinWidth[index + 1] ?? 0;
    const nextColumnId = columnItemBlock[index + 1]?.uuid;
    if (!currentColumnItem || !currentColumnId || !nextColumnItem || !nextColumnId) return;

    const currentColumnWidth =
      parseFloat(getComputedStyle(currentColumnItem).width) + result.deltaX;
    const nextColumnWidth = parseFloat(getComputedStyle(nextColumnItem).width) - result.deltaX;
    if (
      currentColumnWidth < currentColumnItemMinWidth ||
      nextColumnWidth < nextColumnItemMinWidth
    ) {
      return;
    }
    // 算出这两个item在总宽度上分别占的宽度比例，再除以分栏个数就是规则里面说到的系数
    const currentColumnRatio = (currentColumnWidth / columnListWidth) * columnItemBlock.length;
    const nextColumnRatio = (nextColumnWidth / columnListWidth) * columnItemBlock.length;
    switch (result.resizeStatus) {
      case ResizeStatus.START: {
        setAppUiState({ $columnResizing: true });
        break;
      }
      case ResizeStatus.RESIZING: {
        batch(() => {
          // 本地更新而已，ignore op
          updateBlock(currentColumnId, { data: { columnRatio: currentColumnRatio } }, true);
          updateBlock(nextColumnId, { data: { columnRatio: nextColumnRatio } }, true);
        });
        break;
      }
      case ResizeStatus.END: {
        setAppUiState({ $columnResizing: false });
        // 如果mouseup的话，就发op更新
        transaction(() => {
          updateBlock(currentColumnId, { data: { columnRatio: currentColumnRatio } });
          updateBlock(nextColumnId, { data: { columnRatio: nextColumnRatio } });
        });
        break;
      }
      default:
    }
  };

  const isMobileSize = useIsMobileSize();

  const maxLength = useObservableStore(
    ({ blocks }) => {
      const columns = blocks[props.id]?.subNodes;
      return Math.max(...(columns?.map((uuid) => blocks[uuid]?.subNodes.length || 0) ?? []));
    },
    [props.id],
    { wait: 0 }
  );

  const columnChildren = children.flatMap((child, index, arr) => {
    if (!isValidElement(child)) return null;
    // 通过占比系数，算出每个columnItem占的比例
    const itemRatio = ratios[index] ?? 1;
    const columnItemStyle: CSSProperties = {
      // 算出每个columnItem实际的宽度, COLUMN_ITEM_SPACE是column item之间的间隔
      width: isMobileSize
        ? '100%'
        : `calc((100% - ${(children.length - 1) * COLUMN_ITEM_SPACE}px) * ${
            itemRatio / totalRatio
          })`,
    };
    const childId = child.props.id;
    const node = (
      <div key={childId} className="flex flex-col column-block my-[0.5px]" style={columnItemStyle}>
        {child}

        <div
          className="flex-1"
          onClick={() => {
            if (readonly) return;
            const columnNum = cache.blocks[childId]?.subNodes.length ?? 0;
            if (columnNum < maxLength) {
              handleMouseDown(childId);
            }
          }}
        ></div>
      </div>
    );
    const middle = (
      <ColDrop
        readonly={readonly}
        className="col-drop relative w-[46px]"
        position="right"
        key={`${childId}-right`}
        id={childId}
        supportResize={!isDragging && !isSelecting}
        onResize={(result) => {
          computeRatio(index, result);
        }}
      />
    );

    if (!isDragging) {
      if (index === arr.length - 1) {
        return node;
      }
      return [node, middle];
    }

    if (index === 0) {
      return [
        <ColDrop
          readonly={readonly}
          className="absolute w-20 inset-y-2 -left-20"
          position="left"
          key={`${childId}-left`}
          id={childId}
        />,
        node,
        middle,
      ];
    }

    if (index === arr.length - 1) {
      return [
        node,
        <ColDrop
          readonly={readonly}
          className="absolute w-20 inset-y-2 -right-20"
          position="right"
          key={`${childId}-right`}
          id={childId}
        />,
      ];
    }

    return [node, middle];
  });

  return (
    <BlockDrop
      horizontal
      hideHoverMenu
      data-column-list
      id={props.id}
      className={cx(`flex py-1.5 column-list-${props.id}`, isMobileSize ? 'flex-col' : '')}
    >
      {columnChildren}
    </BlockDrop>
  );
});

interface SpaceProps extends ReadonlyProp {
  id: string;
  position: 'left' | 'right';
  className: string;
  supportResize?: boolean;
  onResize?: (result: ResizeResult) => void;
}

const ColDrop: FC<SpaceProps> = ({
  id,
  position,
  className,
  supportResize,
  onResize,
  readonly,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const overPosition = useDropInfoPositionById(id);
  const { height = 0 } = useSize(resizeDivRef, 'height');
  const top = `-${(height - (containerRef.current?.clientHeight ?? 0)) * 0.5}px`;
  useResizeListener(resizeDivRef, {
    onResize: (result) => {
      if (result.resizeStatus === ResizeStatus.START) {
        resizeDivRef.current?.setAttribute('style', `opacity:1;top: ${top}`);
        document.body.classList.add('select-none');
      } else if (result.resizeStatus === ResizeStatus.END) {
        resizeDivRef.current?.removeAttribute('style');
        resizeDivRef.current?.setAttribute('style', `top: ${top}`);
        document.body.classList.remove('select-none');
      }
      onResize?.(result);
    },
    type: 'x',
  });
  return (
    <div data-column-id={id} ref={containerRef} className={className}>
      {overPosition === position && (
        <div
          data-disable-select
          className={cx(
            'absolute bg-black/30 h-full w-1',
            position === 'left' ? 'right-2' : 'left-2'
          )}
        />
      )}
      {!readonly && (
        <div
          ref={resizeDivRef}
          className={cx(
            'absolute flex justify-center w-[46px] min-h-[56px] h-full cursor-[col-resize] opacity-0 transition-opacity hover:opacity-100',
            { hidden: !supportResize || overPosition }
          )}
          style={{
            // 居中
            top: `-${(height - (containerRef.current?.clientHeight ?? 0)) * 0.5}px`,
          }}
        >
          <div className={'bg-black/30 h-full w-1 left-[calc(50%-2px)]'} />
        </div>
      )}
    </div>
  );
};
