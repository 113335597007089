/** 图例位置 */
export type LegendAlignPosition = 'top' | 'bottom' | 'left' | 'right' | 'none';

export const LegendAlignPositionData: Record<LegendAlignPosition, string> = {
  top: '顶部',
  bottom: '底部',
  left: '左侧',
  right: '右侧',
  none: '不显示',
};
