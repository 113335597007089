import type { DiscussionDTO } from '@next-space/fe-api-idl';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { LIST_AFTER_COMMENT, LIST_REMOVE_COMMENT } from '../actions/comments';
import { CREATE_DISCUSSION, UPDATE_DISCUSSION } from '../actions/discussion';

type State = Record<string, DiscussionDTO>;

const initialState: State = {};

export const discussionsSlice = createSlice({
  initialState,
  name: 'discussions',
  reducers: {
    update(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
    clear() {
      return {};
    },
  },
  extraReducers(builder) {
    builder.addCase(CREATE_DISCUSSION, (state, { payload }) => {
      state[payload.discussion.uuid] = payload.discussion;
    });
    builder.addCase(UPDATE_DISCUSSION, (state, { payload }) => {
      const view = state[payload.uuid];
      if (view) {
        Object.assign(view, payload.patch);
      }
    });
    builder.addCase(LIST_AFTER_COMMENT, (state, { payload }) => {
      const { uuid, after, parentId } = payload;
      const block = state[parentId];
      if (!block) return;

      if (!block.comments) {
        block.comments = [uuid];
      } else {
        if (after) {
          const i = block.comments.indexOf(after);
          if (i < 0) {
            block.comments.push(uuid);
          } else {
            block.comments.splice(i + 1, 0, uuid);
          }
        } else {
          block.comments.push(uuid);
        }
      }
    });

    builder.addCase(LIST_REMOVE_COMMENT, (state, { payload }) => {
      const { uuid, parentId } = payload;
      const block = state[parentId];
      if (!block) return;
      block.comments = block.comments?.filter((item) => item !== uuid);
    });
  },
});

export const discussionsReducer = discussionsSlice.reducer;
export const discussionsActions = discussionsSlice.actions;
