import { GroupLevel, GroupSortType } from '@next-space/fe-api-idl';

export const GroupSortMap: Record<
  'text' | 'date' | 'number',
  { type: GroupSortType; name: string }[]
> = {
  text: [
    {
      type: GroupSortType.MANUAL,
      name: '手动排序',
    },
    {
      type: GroupSortType.ASCENDING,
      name: '升序',
    },
    {
      type: GroupSortType.DESCENDING,
      name: '降序',
    },
  ],
  date: [
    {
      type: GroupSortType.ASCENDING,
      name: '时间升序',
    },
    {
      type: GroupSortType.DESCENDING,
      name: '时间倒序',
    },
  ],
  number: [
    {
      type: GroupSortType.ASCENDING,
      name: '升序',
    },
    {
      type: GroupSortType.DESCENDING,
      name: '降序',
    },
  ],
};

export const GroupLevelMap: Record<'text' | 'date', { type: GroupLevel; name: string }[]> = {
  date: [
    { type: GroupLevel.RELATIVE, name: '相对日期' },
    { type: GroupLevel.DAY, name: '天' },
    { type: GroupLevel.WEEK, name: '周' },
    { type: GroupLevel.MONTH, name: '月' },
    { type: GroupLevel.YEAR, name: '年' },
  ],
  text: [
    { type: GroupLevel.EXACT, name: '精确' },
    { type: GroupLevel.ALPHABET_PREFIX, name: '模糊前缀' },
  ],
};
