import type { FC } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useUpload } from 'src/hooks/space/use-upload';
import { v4 as uuidV4 } from 'uuid';

interface UploadIconProps {
  accept?: string;
  iconName: IconName;
  onSuccess: (file: File, ossName: string) => void;
  disabled?: boolean;
  blockId?: string;
}

export const UploadIcon: FC<UploadIconProps> = (props) => {
  const { accept, iconName, onSuccess, blockId } = props;

  const openModal = useOpenModal();
  const upload = useUpload();
  const closeModal = useCloseModal();
  return (
    <label className="flex items-center">
      <Icon name={iconName} size="middle" className={props.disabled ? '' : 'cursor-pointer'} />
      <input
        disabled={props.disabled}
        type="file"
        accept={accept}
        className="hidden"
        onChange={async (e) => {
          const fileList = e.target.files;
          if (!fileList) return;
          const file = fileList[0];
          if (!file) return;
          const messageLoadId = uuidV4();
          void upload({
            key: blockId,
            inTransferList: true,
            file,
            blockId,
            type: 'file',
            spaceId: getCurrentSpaceId(),
            onProgress(progress) {
              openModal.loading({
                modalId: messageLoadId,
                title: `正在上传 ${progress}%`,
              });
            },
            onComplete(ret) {
              closeModal(messageLoadId);
              if (ret.success) {
                onSuccess(file, ret.ossName);
              }
            },
          });
        }}
      />
    </label>
  );
};
