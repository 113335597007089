import type { BlockDTO, CollectionViewDTO } from '@next-space/fe-api-idl';
import type { KnowLedgeInfo as KnowledgeInfo } from './type';
import { allBlocks } from './allBlocks';
import { getPropertyValue } from './util';
import { requestFromNet } from './request-from-net';

//下面是表头对应的字段。
const KNOWLEDGE_URL = '专栏链接';
const KNOWLEDGE_RECOMMEND_IMAGE = '配图';
const KNOWLEDGE_RELEASE = '上架';
const KNOWLEDGE_IMAGE_URL = '图片链接';

interface RawData {
  blocks: Record<string, BlockDTO>;
  collectionViews: Record<string, CollectionViewDTO>;
}

const cacheMap: Record<string, ReturnType<typeof _fetchKnowledgeInfo> | undefined> = {};

export const fetchKnowledgeInfo = async (
  collectionId: string,
  prefixUrl?: string,
  update?: (data: RawData) => void
) => {
  if (cacheMap[collectionId]) return cacheMap[collectionId];
  const res = _fetchKnowledgeInfo(collectionId, update, prefixUrl);
  cacheMap[collectionId] = res;
  return res;
};

const _fetchKnowledgeInfo = async (
  collectionId: string,
  update?: (data: RawData) => void,
  prefixUrl?: string
) => {
  const { code, data } = await requestFromNet(`${prefixUrl ?? ''}/api/docs/${collectionId}`);

  if (code !== 200) return;

  const { blocks, collectionViews } = data as RawData;
  allBlocks.blocks = { ...allBlocks.blocks, ...blocks };
  const collection = blocks[collectionId ?? ''];
  if (!collection) return;

  const viewId = collection.views?.[0];
  if (!viewId) return;

  const view = collectionViews[viewId];
  const pageSort = new Set(view?.pageSort);
  const subNodesSet = new Set(collection.subNodes);
  view?.pageSort.forEach((uuid) => {
    if (!subNodesSet.has(uuid)) pageSort.delete(uuid);
  });

  for (const blockId of collection.subNodes) {
    if (!pageSort.has(blockId)) pageSort.add(blockId);
  }

  const newPageSort = [...pageSort];
  const recordBlocks = newPageSort
    .map((uuid) => blocks[uuid])
    .filter((item): item is BlockDTO => !!item)
    .filter((item) => {
      return getPropertyValue(item, KNOWLEDGE_RELEASE).toLocaleUpperCase() === 'YES';
    });

  return covertToKnowledgeInfos(collectionId, recordBlocks);
};

const covertToKnowledgeInfos = (collectionId: string, items: BlockDTO[]) => {
  const allKnowledgeInfo: KnowledgeInfo[] = [];
  items.forEach((block) => {
    const knowledgeInfo = getKnowledgeData(block);
    if (!knowledgeInfo) return;
    allKnowledgeInfo.push(knowledgeInfo);
  });

  const collection = allBlocks.blocks[collectionId];
  if (!collection) return;

  return allKnowledgeInfo;
};

export const getKnowledgeData = (item: BlockDTO | string): KnowledgeInfo | undefined => {
  let blockData: BlockDTO | undefined = undefined;
  if (typeof item === 'string') {
    blockData = allBlocks.blocks[item];
  } else {
    blockData = item;
  }

  if (!blockData) return;

  const url = getPropertyValue(blockData, KNOWLEDGE_URL);
  const imageUrl = getPropertyValue(blockData, KNOWLEDGE_IMAGE_URL);
  const image = getPropertyValue(blockData, KNOWLEDGE_RECOMMEND_IMAGE);

  return {
    id: blockData.uuid,
    link: url,
    image: imageUrl || image,
  };
};
