import { getCustomIconSavePath, loadResource } from '@flowus/common';
import { getIconsColor } from '@flowus/common/block/color/get-block-color';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import type { ColorKey } from 'src/colors';
import { VITE_CDN_HOST } from 'src/env';
import { useVisible } from '../../hooks/use-visible';
import { Tooltip } from '../tooltip';
import type { EmojiData } from './emoji-mart';
import { getSanitizedData } from './emoji-mart/utils';
import type { EmojiProps } from './emoji-mart/utils/shared-props';
interface Props {
  emojiProps: EmojiProps;
  emoji: EmojiData;
  color?: ColorKey | string;
}

export const ByteIcon: FC<Props> = (props) => {
  const { emojiProps, emoji } = props;
  const color = props.color ?? 'black';
  const [visible, ref] = useVisible();
  const colorValue = useMemo(() => getIconsColor(color), [color]);
  // const url = generateCustomIconUrl(emoji.name, isDarkMode, color);
  const savePath = getCustomIconSavePath(emoji.name, color);

  useEffect(() => {
    void loadResource(`${VITE_CDN_HOST}emoji/byte-icon-sprite.js`, 'script');
  }, []);

  /** copy from NimbleEmoji start*/
  const _getSanitizedData = () => {
    const { emoji, data } = emojiProps;
    if (!data) return;
    return getSanitizedData(emoji, undefined, undefined, data);
  };

  const _handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!emojiProps.onClick) {
      return;
    }
    const { onClick } = emojiProps;
    const emoji = _getSanitizedData();
    if (emoji) {
      emoji.imgPath = savePath;
      emoji && onClick(emoji, e);
    }
  };

  const _handleOver = (e: React.MouseEvent<HTMLElement>) => {
    if (!emojiProps.onOver) {
      return;
    }
    const { onOver } = emojiProps;
    const emoji = _getSanitizedData();
    if (emoji) {
      emoji.imgPath = savePath;
      emoji && onOver(emoji, e);
    }
  };

  const _handleLeave = (e: React.MouseEvent<HTMLElement>) => {
    if (!emojiProps.onLeave) {
      return;
    }
    const { onLeave } = emojiProps;
    const emoji = _getSanitizedData();
    if (emoji) {
      emoji.imgPath = savePath;
      emoji && onLeave(emoji, e);
    }
  };
  /** copy from NimbleEmoji end*/
  return (
    <div
      ref={ref}
      className="inline-flex items-center justify-center shrink-0 emoji-mart-emoji text-center"
      list-item-custom=""
      style={{
        width: parseFloat(`${emojiProps.size}`) + 12,
        height: parseFloat(`${emojiProps.size}`) + 12,
      }}
    >
      {!visible && ( // invisible
        <div
          style={{
            width: emojiProps.size,
            height: emojiProps.size,
          }}
        ></div>
      )}
      {visible && (
        <Tooltip popup={__BUILD_IN__ ? emoji.name : emoji.keywords?.[0]}>
          <span
            aria-label={emoji.name}
            onClick={_handleClick}
            onMouseEnter={_handleOver}
            onMouseLeave={_handleLeave}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              style={{
                color: colorValue,
                width: emojiProps.size,
                height: emojiProps.size,
              }}
            >
              <use xlinkHref={`#${emoji.name}`}></use>
            </svg>
          </span>
        </Tooltip>
      )}
    </div>
  );
};
