import { deepEqual } from '@flowus/common/utils/tools';
import isHotkey from 'is-hotkey';
import type { FC, ReactNode } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useBitable } from 'src/bitable/context';
import { ILLEGAL_TEXT, UNTITLED } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { HoverMenu } from 'src/editor/editor/uikit/hover-menu';
import { MembersCoordinate } from 'src/editor/editor/uikit/members-coordinate';
import { Selector } from 'src/editor/editor/uikit/selector';
import { convertContentToSegments } from 'src/editor/utils/segments';
import { useBlock } from 'src/hooks/block/use-block';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import {
  useOpenPageWay,
  useShowingTablePageIcon,
} from 'src/hooks/collection-view/use-collection-view';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { setAppUiState, useNewCreatedRecord, useTableGroupIsUnFolded } from 'src/services/app';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { AddRecord } from '../add-record';
import { PropertyValues } from '../board-view/card/property-values';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext, useBitableLoadMoreContext } from '../hooks';
import { useUpdateGroups } from '../hooks/ues-check-group';
import { BitableLoadMore } from '../load-more';
import { useInitGroupFoldStatus } from '../table-view';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { isEnableVirtuoso, OVERSCAN } from '../const';
import {
  useOpenSubItem,
  useSubitemSetting,
  useSubitemStyle,
} from 'src/hooks/block/use-open-subitem';
import { ExpandNode } from '../expand-node';
import { Icon } from 'src/common/components/icon';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { TextType } from '@next-space/fe-api-idl';
import { Subitem } from '../table-view/cell/subitem';
import { SubitemParent } from '../table-view/cell/subitem-parent';
import { omit } from 'lodash-es';
import { cx } from '@flowus/common/cx';
import { RelationContext, useRelationMap } from '../relation-context';
import { useReadonly } from 'src/hooks/page';
import { Tooltip } from '@flowus/common/tooltip';
import { useRecordExpand, useSetRecordExpand } from 'src/services/database/state';
import { $searchParams } from 'src/utils';

export const TableListView: FC = () => {
  const { viewId } = useBitable();
  const { tableGroups, child2ParentMap, parent2ChildMap, filteredRecordIdSet } =
    useBiTableGroupsAndProvideRecordWalker(viewId);
  const contextValue = useMemo(() => {
    return { child2ParentMap, parent2ChildMap, filteredRecordIdSet };
  }, [child2ParentMap, filteredRecordIdSet, parent2ChildMap]);

  return (
    <RelationContext.Provider value={contextValue}>
      <BitableLoadMoreContext recordIds={tableGroups?.sortedRecordIds || []}>
        <TableListBody />
      </BitableLoadMoreContext>
    </RelationContext.Provider>
  );
};

const TableListBody: FC = () => {
  const { viewId, readonly, embed, collectionId } = useBitable();
  const { tableGroups } = useBiTableGroupsAndProvideRecordWalker(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);
  const { recordIds: renderIds } = useBitableLoadMoreContext();
  const openSubitem = useOpenSubItem(collectionId);
  const subitemStyle = useSubitemStyle(viewId);
  const tableCellWrap = openSubitem && subitemStyle === 'Nested';
  useInitGroupFoldStatus();
  useUpdateGroups(tableGroups);

  const HoverMenu0 = useMemo(
    () => (!embed ? <HoverMenu readonly={readonly} /> : <></>),
    [embed, readonly]
  );

  const pageRef = usePageScrollRef();
  const [container, setContainer] = useState(pageRef?.current);
  useEffect(() => {
    setContainer(pageRef?.current);
  }, [container, pageRef]);

  if (!container) return null;
  if (!tableGroups) return null;

  const { withoutValidGroup, sortedRecordIds, visibleGroups, hiddenGroups } = tableGroups;

  if (withoutValidGroup) {
    return (
      <div
        data-is-empty={sortedRecordIds.length === 0 ? true : null}
        data-view-id={viewId}
        className="block-list group relative border-t border-grey6 sm:w-max min-w-[500px]"
      >
        <Virtuoso
          customScrollParent={container}
          overscan={OVERSCAN}
          increaseViewportBy={OVERSCAN}
          computeItemKey={(index) => index}
          fixedItemHeight={tableCellWrap ? undefined : 38}
          totalCount={renderIds.length}
          itemContent={(index) => {
            const recordId = renderIds[index];
            if (!recordId) return null;
            return <RecordItem uuid={recordId} level={openSubitem ? 0 : undefined} />;
          }}
        />

        {HoverMenu0}
        {!readonly && !embed && <Selector type="collection" />}
        {!readonly && !embed && <MembersCoordinate />}

        {hoveringEmpty && (
          <div className="absolute top-0 left-0 h-1 w-full bg-black/30 opacity-0 group-hover:opacity-100"></div>
        )}

        <BitableLoadMore />

        <AddRecord />
      </div>
    );
  }

  return (
    <div className="block-list relative sm:w-max min-w-[500px]">
      {isEnableVirtuoso(visibleGroups.length) ? (
        <Virtuoso
          customScrollParent={container}
          overscan={OVERSCAN}
          increaseViewportBy={OVERSCAN}
          computeItemKey={(index) => index}
          totalCount={visibleGroups.length}
          itemContent={(index) => {
            const tableGroup = visibleGroups[index];
            if (!tableGroup) return null;
            return (
              <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                <ListGroup key={tableGroup.value} tableGroup={tableGroup} />;
              </BitableLoadMoreContext>
            );
          }}
        />
      ) : (
        <>
          {visibleGroups.map((tableGroup) => (
            <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
              <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
            </BitableLoadMoreContext>
          ))}
        </>
      )}

      <ToggleHiddenGroup
        hiddenGroupsLength={hiddenGroups.length}
        hiddenGroups={
          isEnableVirtuoso(hiddenGroups.length) ? (
            <Virtuoso
              customScrollParent={container}
              overscan={OVERSCAN}
              increaseViewportBy={OVERSCAN}
              computeItemKey={(index) => index}
              totalCount={hiddenGroups.length}
              itemContent={(index) => {
                const tableGroup = hiddenGroups[index];
                if (!tableGroup) return null;
                return (
                  <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                    <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
                  </BitableLoadMoreContext>
                );
              }}
            />
          ) : (
            <>
              {hiddenGroups.map((tableGroup) => (
                <BitableLoadMoreContext recordIds={tableGroup.recordIds}>
                  <ListGroup key={tableGroup.value} tableGroup={tableGroup} />
                </BitableLoadMoreContext>
              ))}
            </>
          )
        }
      />

      {HoverMenu0}
      {!readonly && !embed && <Selector type="collection" />}
      {!readonly && !embed && <MembersCoordinate />}

      <AddGroup />
    </div>
  );
};

interface ListGroupProps {
  tableGroup: TableGroupData;
}
const ListGroup: FC<ListGroupProps> = memo(({ tableGroup }) => {
  const { viewId, collectionId } = useBitable();
  const { recordIds, value, groupProperty } = tableGroup;
  const hoveringEmpty = useHoveringEmpty(viewId);
  const isUnFolded = useTableGroupIsUnFolded(viewId, value);
  const { recordIds: renderIds } = useBitableLoadMoreContext();
  const openSubitem = useOpenSubItem(collectionId);
  const subitemStyle = useSubitemStyle(viewId);
  /** 由于多维表不支持单个属性设置自适应行高，导致subitem跟虚拟列表的逻辑有冲突，只能默认使用自适应行高 */
  const tableCellWrap = openSubitem && subitemStyle === 'Nested';

  const pageRef = usePageScrollRef();
  if (!pageRef.current) return null;

  return (
    <div
      data-is-empty={recordIds.length === 0 ? true : null}
      data-view-id={viewId}
      data-group-value={value}
      data-group-property={groupProperty}
      className={'group relative pb-5'}
    >
      <GroupHeader tableGroup={tableGroup} />

      {hoveringEmpty && (
        <div className="absolute top-10 left-0 h-1 w-full bg-black/30 opacity-0 group-hover:opacity-100"></div>
      )}

      {isUnFolded && (
        <>
          <Virtuoso
            customScrollParent={pageRef.current}
            overscan={OVERSCAN}
            increaseViewportBy={OVERSCAN}
            fixedItemHeight={tableCellWrap ? undefined : 38}
            computeItemKey={(index) => index}
            totalCount={renderIds.length}
            itemContent={(index) => {
              const recordId = renderIds[index];
              if (!recordId) return null;
              return (
                <RecordItem
                  key={recordId}
                  groupValue={value}
                  uuid={recordId}
                  level={openSubitem ? 0 : undefined}
                />
              );
            }}
          />

          <BitableLoadMore />

          <AddRecord
            where={{ after: recordIds[recordIds.length - 1] }}
            groupName={tableGroup.value}
          />
        </>
      )}
    </div>
  );
}, deepEqual);

interface RecordItemProps {
  uuid: string;
  groupValue?: string;
  level?: number;
  expandNode?: ReactNode;
  alpha?: boolean;
}

const RecordItem: FC<RecordItemProps> = ({ uuid, groupValue, level, ...rest }) => {
  const { viewId, collectionId, readonly } = useBitable();

  const expand = useRecordExpand(collectionId, viewId, uuid) || !!$searchParams.print;
  const setExpand = useSetRecordExpand(collectionId, viewId, uuid);
  const { parent2ChildMap, filteredRecordIdSet } = useRelationMap();

  const subitemStyle = useSubitemStyle(viewId);
  const childRecords = parent2ChildMap?.get(uuid) ?? [];
  const subitemSetting = useSubitemSetting(collectionId);
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });

  const isNested = subitemStyle === 'Nested';
  // 如果a的子记录是b，b的子记录是c，c的子记录设置为a的话，就会循环嵌套，这里做个限制，超过15层咱们就不给显示（有用户真的层数用到那么深给反馈的话咱们再改）
  if (level && level > 15) return null;
  const showExpandNode = level !== undefined && isNested;
  return (
    <>
      <RecordItemInner
        uuid={uuid}
        alpha={filteredRecordIdSet?.has(uuid)}
        groupValue={groupValue}
        level={level}
        expandNode={
          showExpandNode && (
            <ExpandNode
              childrenLen={childRecords.length}
              expand={expand}
              onExpand={setExpand}
              level={level}
            />
          )
        }
        {...rest}
      />
      {expand && showExpandNode && isNested && (
        <div>
          {childRecords.map((childId) => {
            return (
              <RecordItem
                uuid={childId}
                level={level + 1}
                groupValue={groupValue}
                data-relation-parent-id={uuid}
              />
            );
          })}
          {!$searchParams.print && (
            <div
              onClick={() => {
                if (subitemSetting && subitemSetting?.parentPropertyId) {
                  void insertRecord({
                    viewId,
                    propertyValues: {
                      [subitemSetting.parentPropertyId]: [
                        {
                          type: TextType.LINK_PAGE,
                          uuid,
                          text: '',
                          enhancer: { code: false },
                        },
                      ],
                    },
                  });
                }
                // 创建新的subitem
              }}
              className={cx('flex items-center w-full animate-hover h-8 text-grey border-b', {
                hidden: readonly,
              })}
              style={{
                paddingLeft: 40 + level * 30,
              }}
            >
              <Icon name={'IcAdd'} size="small" />
              <div className="text-t2 ml-1 flex items-center">创建子记录</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
const RecordItemInner: FC<RecordItemProps> = ({ uuid, groupValue, expandNode, alpha, ...rest }) => {
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const { illegal } = usePermissions(uuid);
  const { viewId } = useBitable();
  const openPage = useOpenPage();
  const openWay = useOpenPageWay(viewId);

  if (!block) return null;

  return (
    <div className="py-px">
      <BlockDrop
        id={uuid}
        viewId={viewId}
        groupValue={groupValue}
        onClick={(event) =>
          openPage(uuid, {
            illegal,
            forceOpenInRight: event.altKey,
            openWay,
            forceOpenNewTab: event.ctrlKey || event.metaKey,
          })
        }
        className={cx(
          'group animate-hover-black3 flex h-9 cursor-pointer items-center justify-between rounded pr-1',
          alpha && 'opacity-50'
        )}
        {...omit(rest, 'level')}
      >
        {illegal && (
          <div className="flex min-w-[240px] items-center whitespace-nowrap opacity-30">
            <IconTrigger
              className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
              blockId={uuid}
              trigger={false}
              iconSize={20}
              defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
            />
            <span className="text-t2 text-ellipsis border-b border-grey6 align-middle">
              {ILLEGAL_TEXT}
            </span>
          </div>
        )}

        {!illegal && (
          <>
            <ListTitle recordId={uuid} expandNode={expandNode} />
            <PropertyValues recordId={uuid} />
          </>
        )}
      </BlockDrop>
    </div>
  );
};

const ListTitle: FC<{ recordId: string; expandNode?: ReactNode }> = ({ recordId, expandNode }) => {
  const { viewId, readonly, managerReadonly } = useBitable();
  const recordReadonly = useReadonly(recordId);
  const updateValue = useUpdatePropertyValue();
  const block = useBlock(recordId);
  const newCreateRecord = useNewCreatedRecord();
  const isNewCreateRecordId = recordId === newCreateRecord?.id && viewId === newCreateRecord.viewId;
  const isShowPageIcon = useShowingTablePageIcon(viewId);
  const openPage = useOpenPage();

  const [showEditor, setShowEditor] = useState(isNewCreateRecordId);
  const getEditorModelByBlockId = useGetOrInitEditorModel();

  const openEditInput = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isNewCreateRecordId) {
      openPage(recordId, {
        forceOpenInRight: true,
      });
    } else {
      setAppUiState({ $newCreatedRecord: { id: recordId, viewId } });
    }
  };

  useEffect(() => {
    setShowEditor(isNewCreateRecordId);
    const onClick = () => {
      setAppUiState({ $newCreatedRecord: undefined });
    };
    if (isNewCreateRecordId) {
      document.addEventListener('click', onClick);
    }
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isNewCreateRecordId]);

  const canEdit = !(readonly || managerReadonly || recordReadonly);
  if (!block) return null;

  return (
    <div
      className={cx(
        'group-scope flex min-w-[240px] items-center whitespace-nowrap max-w-[50%]',
        showEditor && 'cursor-text'
      )}
      onClick={(e) => {
        if (showEditor) {
          e.stopPropagation();
        }
      }}
    >
      {expandNode}
      {isShowPageIcon && (
        <IconTrigger
          tooltipClassName="align-middle"
          className="mr-1 inline-block h-5 w-5 min-w-[20px] p-0 align-middle"
          blockId={recordId}
          trigger={showEditor}
          iconSize={20}
          defaultIcon={<BlockDefaultIcon uuid={recordId} size="middle" />}
        />
      )}
      <span className="text-t2 text-ellipsis border-b border-grey6 align-middle">
        {showEditor ? (
          <RichTextEdit
            uuid={recordId}
            className="text-t2-medium inline break-words align-middle"
            plugins={TITLE_EDITOR_PLUGINS}
            keydownOption={{ bracketRightKey: false }}
            placeholder={UNTITLED}
            autoFocus={true}
            autoFocusCaretToEnd={true}
            toolbar={false}
            segments={block.data.segments}
            interactable={isNewCreateRecordId}
            // onChange={(segment) => updateValue(recordId, 'title', segment)}
            onBlur={() => {
              const editorModel = getEditorModelByBlockId(recordId);
              if (editorModel) {
                updateValue(recordId, 'title', convertContentToSegments(editorModel.content));
              }
            }}
            onKeyDown={(event) => {
              if (isHotkey('mod+a')(event)) {
                event.stopPropagation();
                return;
              }
              if (isHotkey('enter')(event) || isHotkey('esc')(event)) {
                (event.currentTarget as HTMLDivElement).blur();
              }
            }}
          />
        ) : (
          <RichText
            className="text-t2-medium inline break-words align-middle"
            plugins={TITLE_EDITOR_PLUGINS}
            segments={block?.data.segments}
            placeholder={UNTITLED}
            interactable={false}
          />
        )}
      </span>
      {canEdit && (
        <Tooltip popup={showEditor ? '右侧边栏打开' : '编辑'}>
          <button
            onClick={openEditInput}
            className="animate-hover-opaque ml-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded border border-grey6 bg-white1 group-scope-hover:opacity-100 opacity-0"
          >
            <Icon
              name={isNewCreateRecordId ? 'IcBlockRightSidebarOpen' : 'IcEdit'}
              size="small"
              className="text-grey3"
            />
          </button>
        </Tooltip>
      )}
      <Subitem recordId={recordId} />
      <SubitemParent recordId={recordId} />

      <BlockDiscussionsBadge
        className="inline-flex align-middle pointer-events-none"
        blockId={recordId}
      />
    </div>
  );
};
