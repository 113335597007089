import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import type { BlockDTO, SegmentDTO } from '@next-space/fe-api-idl';
import { currentVersion } from 'src/common/const';
import {
  fetchCollection,
  getPropertySegments,
  getPropertyValue,
  getPropertyValueWithFiles,
} from 'src/common/utils/fetch-collection';
import { isFlowUsApp } from 'src/utils/electron-util';

const COLUMN_TITLE_NAME = '标题';
const COLUMN_PUBLISH_NAME = '上线';
const COLUMN_PLATFORM = '端';
const COLUMN_VERSION = '版本号';
const COLUMN_FEATURE_1 = '步骤1文字';
const COLUMN_FEATURE_MEDIA_1 = '步骤1附件';
const COLUMN_FEATURE_2 = '步骤2文字';
const COLUMN_FEATURE_MEDIA_2 = '步骤2附件';
const COLUMN_FEATURE_3 = '步骤3文字';
const COLUMN_FEATURE_MEDIA_3 = '步骤3附件';

const ALL_COLUMN_FEATURES = [
  {
    columnDesc: COLUMN_FEATURE_1,
    columnMedia: COLUMN_FEATURE_MEDIA_1,
  },
  {
    columnDesc: COLUMN_FEATURE_2,
    columnMedia: COLUMN_FEATURE_MEDIA_2,
  },
  {
    columnDesc: COLUMN_FEATURE_3,
    columnMedia: COLUMN_FEATURE_MEDIA_3,
  },
];

export interface Feature {
  desc: SegmentDTO[];
  medias: string[];
}
export interface ImportantFeature {
  blockId: string;
  title: string;
  features: Feature[];
}

// const KEY = 'fetchImportantFeature';
export const fetchImportantFeature = async () => {
  const importantFeatures = await fetchCollection<ImportantFeature>(
    import.meta.env.VITE_IMPORTANT_FEATURE_COLLECTION_ID ?? '19ab9b59-322d-4639-96c3-51f6ac6da37e',
    {
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.warn('err: ', err);
      },
      onDataUpdate: () => {
        // saveCallTime(KEY);
      },
      convertToTargets: (blocks: BlockDTO[], _, propertyMapping) => {
        return blocks.map((b) => {
          const item: ImportantFeature = {
            blockId: b.uuid,
            title: getPropertyValue(b, propertyMapping, COLUMN_TITLE_NAME),
            features: [],
          };
          ALL_COLUMN_FEATURES.forEach((c) => {
            const feature = getPropertySegments(b, propertyMapping, c.columnDesc);
            if (feature) {
              const medias = getPropertyValueWithFiles(b, propertyMapping, c.columnMedia);
              if (medias.length > 0) {
                item.features.push({
                  desc: feature,
                  medias,
                });
              }
            }
          });
          return item;
        });
      },
      filter: (item, _, propertyMapping) => {
        const publish = formatCheckBoxValue(
          getPropertyValue(item, propertyMapping, COLUMN_PUBLISH_NAME)
        );
        if (!publish) return false;
        const version = getPropertyValue(item, propertyMapping, COLUMN_VERSION);
        if (version !== currentVersion) {
          return false;
        }
        const platform = getPropertyValue(item, propertyMapping, COLUMN_PLATFORM);
        return platform.includes(isFlowUsApp.check ? 'PC' : 'Web');
      },
    }
  );
  if (importantFeatures?.length === 1) {
    return importantFeatures[0];
  }
};
