import { createContext, useContext } from 'react';

export const EditableContext = createContext({
  interactable: true,
  readonly: false,
});

export const EditableNamespace = createContext<string | undefined>(undefined);

export const useNameSpace = () => {
  return useContext(EditableNamespace);
};
