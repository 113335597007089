import { useOpenModal } from '@flowus/common/next-modal';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { useCallback } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { SHARE_LINK, UNTITLED } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { usePayInfo } from 'src/hooks/page/use-pay-info';
import { useCurrentUser } from 'src/hooks/user/use-current-user';
import { useIsLogin } from 'src/hooks/user/use-is-login';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { usePickBlock } from 'src/utils/pick-block';
import { useOpenLogin } from '../empty/open-login';
import { useOpenWeappQR } from '../weapp-qr';
import { useGetSharePageUrl } from './helper';
import { useOpenShareLinkQr } from './share-link-qr';
import { useOpenSharePoster } from './share-poster';
import { ShareDialog } from './share-dialog';

interface ShareButtonsProps {
  uuid: string;
  url: string;
  onCopyLink?: () => void;
  onCopyEmbedLink?: () => void;
}
export const ShareButtons: FC<ShareButtonsProps> = (props) => {
  const { url, onCopyLink, uuid, onCopyEmbedLink } = props;
  const block = usePickBlock(uuid, ['data'], ['segments']);
  const collectionView = useCollectionView(uuid);
  const openWeappQR = useOpenWeappQR();
  const openShareLinkQr = useOpenShareLinkQr();
  const openSharePoster = useOpenSharePoster();

  let title = `分享了${segmentsToText(block?.data.segments) ?? UNTITLED}`;
  if (collectionView?.type === CollectionViewType.FORM) {
    title = '邀请你填写收集表';
  }

  return (
    <div className="px-4 border-t border-grey6 text-t2 bg-grey9 flex items-center  h-12">
      <span className="mr-4 text-black">分享方式</span>
      <Tooltip popup={SHARE_LINK} className="animate-click mr-4">
        <Icon name="IcLink" size="middle" onClick={onCopyLink} />
      </Tooltip>
      {onCopyEmbedLink && (
        <Tooltip popup={'内嵌模式分享'} className="animate-click mr-4">
          <Icon name="IcGlobeShare" size="middle" onClick={onCopyEmbedLink} />
        </Tooltip>
      )}
      {!(__BUILD_IN__ || __PRIVATE__) && (
        <Tooltip
          popup="小程序分享"
          onClick={() => openWeappQR({ url })}
          className="animate-click mr-4"
        >
          <Icon name="IcMenuMiniProgramQrcode" size="middle" />
        </Tooltip>
      )}
      {!__BUILD_IN__ && (
        <Tooltip
          popup="生成海报，可保存图片"
          className="animate-click mr-4"
          onClick={() => openSharePoster({ url, pageId: uuid })}
        >
          <Icon name="IcImage" size="middle" />
        </Tooltip>
      )}
      <Tooltip
        popup="二维码分享"
        className="animate-click"
        onClick={(e) => openShareLinkQr(e, { url, title })}
      >
        <Icon name="IcQrCode" size="middle" />
      </Tooltip>
    </div>
  );
};

export const useOpenShareLinkList = (uuid: string) => {
  const openLogin = useOpenLogin();
  const isLogin = useIsLogin();
  const openModal = useOpenModal();

  return useCallback(
    async (event: MouseEvent) => {
      if (!isLogin) {
        openLogin();
        return;
      }

      openModal.dropdown({
        popcorn: event?.currentTarget,
        placement: 'bottom',
        content: () => {
          return <ShareDialog pageId={uuid} />;
        },
      });
    },
    [isLogin, openLogin, openModal, uuid]
  );
};
