import { BlockType } from '@next-space/fe-api-idl';
import { mapValues } from 'lodash-es';
import { getInitials, getPinyin } from 'src/utils/pinyin';
import { MenuCategory } from './types';

export const MenuCategoryPinyin = mapValues(MenuCategory, (value) => ({
  pinyin: getPinyin(value),
  initials: getInitials(value),
}));

export const listFormat = [
  { title: '圆点', value: 'disc' },
  { title: '圆圈', value: 'circle' },
  { title: '方块', value: 'square' },
];

export const orderListFormat = [
  { title: '数字', value: 'numbers' },
  { title: '字母', value: 'letters' },
  { title: '罗马', value: 'roman' },
];

// 这些块类型都是可以转换为 Page 的
export const TurnToPageWhiteList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.HEADER,
  BlockType.FOLD_LIST,
  BlockType.TOGGLE_HEADER,
  BlockType.QUOTE,
  BlockType.MARK,
  BlockType.EQUATION,
  BlockType.PAGE,
  BlockType.CODE,
];

export const TurnWhiteList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.HEADER,
  BlockType.FOLD_LIST,
  BlockType.TOGGLE_HEADER,
  BlockType.QUOTE,
  BlockType.MARK,
  BlockType.EQUATION,
  BlockType.PAGE,
  BlockType.CODE,
  BlockType.MIND_MAPPING,
  BlockType.MIND_MAPPING_PAGE, // 支持页面转内容
  BlockType.FILE,
  BlockType.FOLDER,
];

/** 这是单独给转换为列表显示用的,上面那个是给各种转换逻辑判断用的*/
export const TurnBlockList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.HEADER,
  BlockType.FOLD_LIST,
  BlockType.TOGGLE_HEADER,
  BlockType.QUOTE,
  BlockType.MARK,
  BlockType.EQUATION,
  BlockType.PAGE,
  BlockType.CODE,
  BlockType.MIND_MAPPING,
  BlockType.FOLDER,
];

/** 思维导图节点转换 */
export const MindNodeTurnWhiteList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.QUOTE,
  BlockType.MARK,
  BlockType.EQUATION,
  BlockType.PAGE,
  BlockType.CODE,
  BlockType.FOLDER,
];

/** 支持 ai 的 block */
export const AIBlockList = [
  BlockType.TEXTAREA,
  BlockType.TODO,
  BlockType.HEADER,
  BlockType.ORDER_LIST,
  BlockType.LIST,
  BlockType.FOLD_LIST,
  BlockType.QUOTE,
  BlockType.MARK,
];
