export const computeTextWidth = (text: string, computedStyle: Partial<CSSStyleDeclaration>) => {
  // 创建临时的 <span> 元素
  const span = document.createElement('span');

  // 设置临时元素的样式，包括字体相关属性
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'nowrap';
  if (computedStyle.fontSize) {
    span.style.fontSize = computedStyle.fontSize;
  }
  if (computedStyle.fontFamily) {
    span.style.fontFamily = computedStyle.fontFamily;
  }
  if (computedStyle.fontWeight) {
    span.style.fontWeight = computedStyle.fontWeight;
  }
  if (computedStyle.fontStyle) {
    span.style.fontStyle = computedStyle.fontStyle;
  }
  if (computedStyle.textTransform) {
    span.style.textTransform = computedStyle.textTransform;
  }
  if (computedStyle.letterSpacing) {
    span.style.letterSpacing = computedStyle.letterSpacing;
  }
  if (computedStyle.wordSpacing) {
    span.style.wordSpacing = computedStyle.wordSpacing;
  }
  if (computedStyle.textDecoration) {
    span.style.textDecoration = computedStyle.textDecoration;
  }
  // 将文本节点的内容设置为临时元素的文本内容
  span.textContent = text;

  // 将临时元素添加到文档中
  document.body.appendChild(span);

  // 获取临时元素的宽度
  const width = span.offsetWidth;

  try {
    // 从文档中移除临时元素
    document.body.removeChild(span);
  } catch {
    //
  }
  return width;
};
