import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { updateBlock } from 'src/redux/managers/block/update';
import { dispatch, getState } from 'src/redux/store';
import { sliceSegments } from 'src/utils/segment-utils';

export const useBracketRightKey = (uuid: string) => {
  const transaction = useTransaction();
  const getEditorModel = useGetOrInitEditorModel();
  const focusEditableAt = useFocusEditableByBlockId();
  const handleBracket = (event?: globalThis.KeyboardEvent) => {
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    const selection = getEditorModel(uuid)?.selection;
    if (!selection) return;
    if (!selection.isCollapsed || selection.offset !== 1) return;

    const [firstSegment] = sliceSegments(block.data.segments, 0, 1);
    if (!firstSegment) return;
    const trigger = firstSegment.text;

    if (trigger !== '[' && trigger !== '【') return;

    event?.preventDefault();

    const afterSegments = sliceSegments(block.data.segments, 1);

    // NOTE: event为undefined表明由inlined input event而不是keydown触发。
    if (event) {
      // 配合撤销行为, 发送两次事务
      updateBlock(
        block.uuid,
        {
          data: {
            segments: [
              {
                ...firstSegment,
                text: `${trigger}${event.key}`,
              },
              ...afterSegments,
            ],
          },
        },
        true
      );
    }

    transaction(() => {
      updateBlock(block.uuid, {
        type: BlockType.TODO,
        data: { segments: afterSegments },
      });

      dispatch(
        HISTORY_EFFECTS({
          init() {
            focusEditableAt(block.uuid, 0);
          },
          redo() {
            focusEditableAt(block.uuid, 0);
          },
          undo() {
            focusEditableAt(block.uuid, 2);
          },
        })
      );
    });
  };

  return useCallback(handleBracket, [focusEditableAt, getEditorModel, transaction, uuid]);
};
