import type { FC } from 'react';
import { useEffect, useState } from 'react';

interface CountDownType {
  time?: number;
  prefix?: string;
  className?: string;
  onFinish?: (boolean: true) => void;
  onChange?: (time: number) => void;
}

export const CountDown: FC<CountDownType> = ({
  time = 0,
  prefix = '',
  onFinish,
  onChange,
  className = '',
}) => {
  const [countDown, setCountDown] = useState(time);

  useEffect(() => {
    if (countDown === 0 && onFinish) onFinish(true);
  }, [countDown, onFinish]);

  useEffect(() => {
    if (onChange) onChange(countDown);
  }, [countDown, onChange]);

  useEffect(() => {
    setCountDown(countDown);
    const timer = setInterval(() => {
      setCountDown((preState) => {
        if (preState < 1) {
          clearInterval(timer);
          return 0;
        }
        return preState - 1;
      });
    }, 1000);
    return () => {
      setCountDown(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span className={className}>
      {`${prefix} `}
      {countDown}s
    </span>
  );
};
