import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { ColorKey } from 'src/colors';

/** 文字颜色 */
export const COLOR_PICKER_DATA_TEXT_COLOR = {
  title: '文字颜色',
  items: [
    {
      color: getBlockBackgroundColor(ColorKey.white),
      desc: '默认',
      isBgColor: true,
      colorkey: '',
    },
    { color: getBlockTextColor(ColorKey.black), desc: '黑色', colorkey: ColorKey.black },
    { color: getBlockTextColor(ColorKey.grey), desc: '灰色', colorkey: ColorKey.grey },
    { color: getBlockTextColor(ColorKey.brown), desc: '棕色', colorkey: ColorKey.brown },
    { color: getBlockTextColor(ColorKey.orange), desc: '橘色', colorkey: ColorKey.orange },
    { color: getBlockTextColor(ColorKey.pink), desc: '粉色', colorkey: ColorKey.pink },
    { color: getBlockTextColor(ColorKey.yellow), desc: '黄色', colorkey: ColorKey.yellow },
    { color: getBlockTextColor(ColorKey.green), desc: '绿色', colorkey: ColorKey.green },
    { color: getBlockTextColor(ColorKey.blue), desc: '蓝色', colorkey: ColorKey.blue },
    { color: getBlockTextColor(ColorKey.purple), desc: '紫色', colorkey: ColorKey.purple },
    { color: getBlockTextColor(ColorKey.red), desc: '红色', colorkey: ColorKey.red },
  ],
};

/** 背景颜色 */
export const COLOR_PICKER_DATA_BACKGROUND_COLOR = {
  title: '背景颜色',
  items: [
    {
      color: getBlockBackgroundColor(ColorKey.white),
      desc: '默认',
      isBgColor: true,
      colorkey: '',
    },
    {
      color: getBlockBackgroundColor(ColorKey.grey),
      desc: '灰色',
      isBgColor: true,
      colorkey: ColorKey.grey,
    },
    {
      color: getBlockBackgroundColor(ColorKey.brown),
      desc: '棕色',
      isBgColor: true,
      colorkey: ColorKey.brown,
    },
    {
      color: getBlockBackgroundColor(ColorKey.orange),
      desc: '橘色',
      isBgColor: true,
      colorkey: ColorKey.orange,
    },
    {
      color: getBlockBackgroundColor(ColorKey.pink),
      desc: '粉色',
      isBgColor: true,
      colorkey: ColorKey.pink,
    },
    {
      color: getBlockBackgroundColor(ColorKey.yellow),
      desc: '黄色',
      isBgColor: true,
      colorkey: ColorKey.yellow,
    },
    {
      color: getBlockBackgroundColor(ColorKey.green),
      desc: '绿色',
      isBgColor: true,
      colorkey: ColorKey.green,
    },
    {
      color: getBlockBackgroundColor(ColorKey.blue),
      desc: '蓝色',
      isBgColor: true,
      colorkey: ColorKey.blue,
    },
    {
      color: getBlockBackgroundColor(ColorKey.purple),
      desc: '紫色',
      isBgColor: true,
      colorkey: ColorKey.purple,
    },
    {
      color: getBlockBackgroundColor(ColorKey.red),
      desc: '红色',
      isBgColor: true,
      colorkey: ColorKey.red,
    },
  ],
};

/** 渐变背景 */
const GRADIENT_COLOR = [
  {
    color: getBlockBackgroundColor(ColorKey.yellow_green),
    desc: '黄至绿',
    isBgColor: true,
    colorkey: ColorKey.yellow_green,
  },
  {
    color: getBlockBackgroundColor(ColorKey.green_blue),
    desc: '绿至蓝',
    isBgColor: true,
    colorkey: ColorKey.green_blue,
  },
  {
    color: getBlockBackgroundColor(ColorKey.blue_purple),
    desc: '蓝至紫',
    isBgColor: true,
    colorkey: ColorKey.blue_purple,
  },
  {
    color: getBlockBackgroundColor(ColorKey.purple_red),
    desc: '紫至红',
    isBgColor: true,
    colorkey: ColorKey.purple_red,
  },
  {
    color: getBlockBackgroundColor(ColorKey.red_orange),
    desc: '红至橘',
    isBgColor: true,
    colorkey: ColorKey.red_orange,
  },
];

/** 荧光背景色 */
const HAS_LIGHT_BACKGROUND_COLOR = [
  {
    color: getBlockBackgroundColor(ColorKey.light_yellow),
    desc: '荧光黄',
    isBgColor: true,
    colorkey: ColorKey.light_yellow,
  },
  {
    color: getBlockBackgroundColor(ColorKey.light_green),
    desc: '荧光绿',
    isBgColor: true,
    colorkey: ColorKey.light_green,
  },
  {
    color: getBlockBackgroundColor(ColorKey.light_blue),
    desc: '荧光蓝',
    isBgColor: true,
    colorkey: ColorKey.light_blue,
  },
  {
    color: getBlockBackgroundColor(ColorKey.light_purple),
    desc: '荧光紫',
    isBgColor: true,
    colorkey: ColorKey.light_purple,
  },
  {
    color: getBlockBackgroundColor(ColorKey.light_red),
    desc: '荧光红',
    isBgColor: true,
    colorkey: ColorKey.light_red,
  },
];

/** 基础色 + 荧光色 */
const COLOR_PICKER_DATA_HAS_LIGHT_BACKGROUND_COLOR = {
  ...COLOR_PICKER_DATA_BACKGROUND_COLOR,
  items: [...COLOR_PICKER_DATA_BACKGROUND_COLOR.items, ...HAS_LIGHT_BACKGROUND_COLOR],
};

const COLOR_PICKER_DATA_HAS_LIGHT_AND_GRADIENT = {
  ...COLOR_PICKER_DATA_BACKGROUND_COLOR,
  items: [
    ...COLOR_PICKER_DATA_BACKGROUND_COLOR.items,
    ...HAS_LIGHT_BACKGROUND_COLOR,
    ...GRADIENT_COLOR,
  ],
};

export const DEFAULT_COLOR_PICKER_DATA_NOT_LIGHT = [
  COLOR_PICKER_DATA_TEXT_COLOR,
  COLOR_PICKER_DATA_BACKGROUND_COLOR,
];

/**
 * 颜色值以figma 桌面端页面右侧的颜色为主
 */
export const DEFAULT_COLOR_PICKER_DATA = [
  COLOR_PICKER_DATA_TEXT_COLOR,
  COLOR_PICKER_DATA_HAS_LIGHT_BACKGROUND_COLOR,
];

/** 行内文字颜色 */
export const INLINE_COLOR_PICKER_DATA_TEXT_COLOR = [
  COLOR_PICKER_DATA_TEXT_COLOR,
  COLOR_PICKER_DATA_HAS_LIGHT_AND_GRADIENT,
];
