import { useOpenModal } from '@flowus/common/next-modal';
import type { IntegralRecordDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { request } from 'src/common/request';
import { formatDotTimestamp } from 'src/common/utils/formatter';

/** 积分历史 */
const IntegralHistory: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  /** 积分邀请 */
  const [integralList, setIntegralList] = useState<{
    total: number;
    list: IntegralRecordDTO[];
  }>({
    total: 0,
    list: [],
  });

  useEffect(() => {
    void loadIntegralList(1);
  }, []);

  const loadIntegralList = async (page: number) => {
    const res = await request.infra.creditIncomes(page, 20);
    setIntegralList((pre) => {
      return {
        total: res.total,
        list: page === 1 ? res.list : [...pre.list, ...res.list],
      };
    });
  };

  return (
    <div ref={scrollRef} className="flex flex-col overflow-y-auto flex-1 min-w-[500px] h-[600px]">
      <InfiniteScroll
        className="flex flex-col px-5"
        pageStart={1}
        initialLoad={false}
        useWindow={false}
        loadMore={loadIntegralList}
        getScrollParent={() => scrollRef.current}
        hasMore={integralList.total > integralList.list.length}
      >
        <div className="flex mt-2.5 items-center text-t2 h-9">积分记录</div>
        {integralList.list.map((item) => (
          <div key={item.id} className="flex w-full justify-between items-center">
            <div className="flex flex-col justify-center h-14">
              <div className="text-t2">{item.title}</div>
              {item.createdAt && (
                <div className="text-grey3 text-t4">{formatDotTimestamp(item.createdAt)}</div>
              )}
            </div>
            <div className="text-t2 flex flex-col items-end">
              <span>{item.score ? (item.score < 0 ? item.score : `+${item.score}`) : ''}</span>
              {item.score === 0 && <span className="text-t4 text-grey3">积分达到上限</span>}
            </div>
          </div>
        ))}
        {!integralList.list.length && (
          <div className="text-t2 text-grey4 mt-2">暂无记录，快去邀请好友吧～</div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export const useOpenIntegralHistory = () => {
  const openModal = useOpenModal();

  return useCallback(() => {
    openModal.modal({
      content: IntegralHistory,
    });
  }, [openModal]);
};
