import type { AggregationAction } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { getState } from 'src/redux/store';
import { useTransaction } from '../use-transaction';

export const useUpdatePropertyAggregation = () => {
  const transaction = useTransaction();
  const updatePropertyVisible = (viewId: string, propertyId: string, action: AggregationAction) => {
    const { collectionViews } = getState();

    const view = collectionViews[viewId];
    if (!view) return;

    if (action === view.format.aggregations?.[propertyId]?.action) {
      return;
    }

    const aggregations = {
      ...view.format.aggregations,
      [propertyId]: { action },
    };

    transaction(() => {
      CollectionViewManager.update(viewId, {
        format: {
          aggregations,
        },
      });
    });
  };

  return useCallback(updatePropertyVisible, [transaction]);
};
