import type { Placement } from '@popperjs/core';
import { useCallback } from 'react';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useUploadDriveFiles } from 'src/hooks/space/use-upload';
import { useTransaction } from 'src/hooks/use-transaction';
import type { ReadonlyProp } from 'src/redux/types';
import { $currentUserCache } from 'src/services/user/current-user';
import { BlockEmptyView } from '../block-empty-view';
import { UploadPanel } from '../upload-panel';

interface EmptyFolderProps extends ReadonlyProp {
  ownerBlockId?: string;
  uuid: string;
  defaultOpenPanel: boolean;
}
export function EmptyFolder(props: EmptyFolderProps) {
  const { defaultOpenPanel, readonly, ownerBlockId, uuid } = props;
  const selectFolder = useSelectFolder({ ownerBlockId, uuid });

  return (
    <BlockEmptyView
      defaultOpenPanel={defaultOpenPanel}
      readonly={readonly}
      iconProp={{ name: 'IcUploadFolder', size: 'normal' }}
      text="添加文件夹"
      onClick={(event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        selectFolder({ getBoundingClientRect: () => rect });
      }}
      className={readonly ? 'cursor-default' : 'cursor-pointer'}
    />
  );
}

interface SelectFolderProps {
  ownerBlockId?: string;
  uuid: string;
}
export const useSelectFolder = (props: SelectFolderProps) => {
  const { uuid } = props;
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const uploadDrive = useUploadDriveFiles();

  /** 选择本地folder */
  const onSelectLocalFolder = useCallback(
    async (files: File[]) => {
      transaction(() => {
        void uploadDrive({
          ownerPageId: uuid,
          files,
          spaceId: getCurrentSpaceId(),
          userId: $currentUserCache.uuid,
          type: 'file',
          onComplete() {},
          inTransferList: true,
        });
      });
    },
    [transaction, uploadDrive, uuid]
  );

  return useCallback(
    (popcorn: ModalSchema.PopcornType, placement?: Placement, offset?: number[]) => {
      openModal.dropdown({
        popcorn,
        placement: placement ?? 'bottom',
        offset: offset ?? [],
        content: ({ onCloseModal }) => (
          <UploadPanel
            hideLinkBlock
            title="请选择一个文件夹"
            uploadFolder
            onSelectFolder={(files) => {
              onCloseModal();
              void onSelectLocalFolder(files);
            }}
          />
        ),
      });
    },
    [onSelectLocalFolder, openModal]
  );
};
