import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

export const usePropertySchema = (collectionId: string, propertyId: string) => {
  return useObservableStore(
    ({ blocks }) => {
      return getPropertySchema(collectionId, propertyId, blocks);
    },
    [collectionId, propertyId]
  );
};

export const getPropertySchema = MemoizeByBlockVersion.create(
  (
    collectionId: string,
    propertyId: string,
    blocks: Record<string, NextBlock> = getState().blocks
  ) => {
    const collection = blocks[collectionId];
    const propertySchema = collection?.data.schema?.[propertyId];

    let relationPropertySchema, targetPropertySchema;
    if (propertySchema?.type === CollectionSchemaType.ROLLUP) {
      relationPropertySchema = collection?.data.schema?.[propertySchema.relationProperty ?? ''];
      const relationCollection = blocks[relationPropertySchema?.collectionId ?? ''];
      targetPropertySchema = relationCollection?.data.schema?.[propertySchema.targetProperty ?? ''];
    }

    return {
      propertySchema,
      relationPropertySchema,
      targetPropertySchema,
    };
  },
  (collectionId, propertyId) => [collectionId, propertyId]
);
