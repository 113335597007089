import { useEffect, useMemo } from 'react';
import { useVisible } from 'src/common/hooks/use-visible';
import { VisiblePlaceholder } from 'src/components/visible-placeholder';
import { useDoneLocalStatus } from 'src/hooks/block/use-local-status';
import { useReadonly } from 'src/hooks/page';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import type { NextBlock } from 'src/redux/types';
import { getLinkInfo } from 'src/utils/embed';
import { usePickBlock } from 'src/utils/pick-block';
import { Bookmark } from '../../component/bookmark';
import { Caption } from '../../component/caption';
import { segmentsToText, textToSegments } from '../../utils/editor';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';
import { useSyncId } from './sync-block-context';

export const BookmarkBlockElement: BlockElement = ({ id, root }) => {
  const syncId = useSyncId();
  const block = usePickBlock(
    id,
    ['data', 'local'],
    ['link', 'icon', 'description', 'cover', 'linkInfo', 'headers']
  );
  const transaction = useTransaction();
  const readOnly = useReadonly(id);
  const [visible, ref] = useVisible();
  useDoneLocalStatus({ uuid: id });

  useEffect(() => {
    if (!block?.data.link || !visible) return;
    // if (
    //   segmentsToText(block.data.linkInfo) ||
    //   block.data.cover ||
    //   segmentsToText(block.data.description) ||
    //   block.data.icon?.value ||
    //   block.data.headers
    // ) {
    //   return;
    // }

    void getLinkInfo(block.data.link).then((info) => {
      if (!info) return;
      const data: NextBlock['data'] = {
        link: info.link,
        linkInfo: textToSegments(info.title),
        cover: info.cover,
        icon: {
          type: 'http',
          value: info.icon,
        },
        description: textToSegments(info.description),
      };
      if (readOnly) {
        updateBlock(
          block.uuid,
          {
            data: {
              ...block.data,
              ...data,
            },
          },
          true
        );
      } else {
        transaction(() => {
          updateBlock(block.uuid, { data });
        });
      }
    });
  }, [block, readOnly, transaction, visible]);

  const { title, description } = useMemo(() => {
    return {
      title: segmentsToText(block?.data.linkInfo),
      description: segmentsToText(block?.data.description),
    };
  }, [block?.data.description, block?.data.linkInfo]);

  if (!block) return null;

  return (
    // 用于拖拽到书签左右2侧,为什么外围要加一个div，因为书签设置背景之后，书签说明不会包含进去
    <div
      className="relative"
      data-block-id={id}
      data-horizontal={root}
      data-sync-id={syncId}
      ref={ref}
    >
      <VisiblePlaceholder
        visible={!block.data.link || visible}
        placeholder={
          <div className="h-[89px] my-1 text-t4 text-grey3">
            <p>{title}</p>
            <p>{description}</p>
            <p>{block.data.link}</p>
          </div>
        }
      >
        <BlockDrop id={id} className="my-1 rounded line-default" horizontal={root}>
          <Bookmark
            readonly={readOnly}
            title={title}
            cover={block.data.cover}
            description={description}
            icon={block.data.icon?.value}
            url={block.data.link}
            onUrlInput={(url) => {
              void getLinkInfo(url).then((info) => {
                transaction(() => {
                  updateBlock(block.uuid, {
                    data: {
                      link: info?.link ?? url,
                      linkInfo: textToSegments(info?.title),
                      cover: info?.cover,
                      icon: {
                        type: 'http',
                        value: info?.icon,
                      },
                      description: textToSegments(info?.description),
                      headers: info?.headerMap,
                    },
                  });
                });
              });
            }}
            defaultOpenInputPanel={Boolean(block.local)}
            loading={false}
          />
        </BlockDrop>
      </VisiblePlaceholder>
      <Caption blockId={id} />
    </div>
  );
};
