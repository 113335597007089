import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useFetchRootPages } from 'src/hooks/page';
import { useCurrentSpace } from 'src/hooks/space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { useFetchMe } from 'src/hooks/user/use-fetch-me';
import { AccountSetting } from './account-setting';
import { SettingAside } from './aside';
import { useSetting } from './common';
import { GeneralSetting } from './general-setting';
import { IntegralSetting } from './integral-setting';
import { MembersSetting } from './members-setting';
import { OrderSetting } from './order-setting';
import { ProfitSetting } from './profit-setting';
import { SpaceSharePages } from './share-pages-setting';
import { SpaceInfoSetting } from './space-info-setting';
import { SpaceSetting } from './space-setting';
import { SettingMenuType } from './type';
import { UpgradeSetting } from './upgrade-setting';
import { useCloseSettingModal } from './use-open-setting-modal';

/** 设置中心 */
export const SettingModal: FC = () => {
  const fetchMe = useFetchMe();
  const closeSettingModal = useCloseSettingModal();
  const { clickMaskClose, currentSettingMenu } = useSetting();
  const currentSpace = useCurrentSpace();

  const fetchSpaceRootPages = useFetchRootPages();
  // 避免 defaultMenuType 有值时先闪现下 account tab.
  const [ready, setReady] = useState(false);

  const clickMask = useCallback(() => {
    if (clickMaskClose) {
      closeSettingModal();
    }
  }, [clickMaskClose, closeSettingModal]);

  // onEsc
  useEffect(() => {
    const onEsc = (event: KeyboardEvent) => {
      if (isHotkey('esc')(event)) {
        event.stopPropagation();
        event.preventDefault();
        clickMask();
      }
    };
    document.addEventListener('keydown', onEsc);

    return () => {
      document.removeEventListener('keydown', onEsc);
    };
  }, [clickMask]);

  /** 刷新space members 更新isFrom */
  useEffect(() => {
    void fetchSpaceRootPages(currentSpace.uuid);

    if (currentSpace.permissions) {
      void getSpaceGuests(currentSpace.uuid);
    }

    setReady(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 防止没同步最新的user信息
  useEffect(() => {
    void fetchMe();
  }, [fetchMe]);

  // closeSettingModal
  useEffect(() => {
    return closeSettingModal;
  }, [closeSettingModal]);

  if (!ready) return null;

  return (
    <div
      className="flex justify-center items-center w-screen h-screen"
      onClick={clickMask}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div
        className="flex overflow-y-auto w-[85vw] h-[85vh] min-h-[300px] max-w-[1200px] max-h-[700px] next-modal"
        onClick={(e) => e.stopPropagation()}
      >
        {/* menuList */}
        <SettingAside />
        {/* content */}
        <div className="flex relative flex-col pt-2.5 w-full h-full text-t2 overflow-x-hidden min-w-[550px]">
          {currentSettingMenu === SettingMenuType.account && <AccountSetting />}
          {currentSettingMenu === SettingMenuType.general && <GeneralSetting />}
          {currentSettingMenu === SettingMenuType.integral && <IntegralSetting />}
          {currentSettingMenu === SettingMenuType.profit && <ProfitSetting />}

          {currentSettingMenu === SettingMenuType.spaceInfo && <SpaceInfoSetting />}
          {currentSettingMenu === SettingMenuType.space && <SpaceSetting />}
          {currentSettingMenu === SettingMenuType.members && <MembersSetting />}
          {currentSettingMenu === SettingMenuType.upgrade && <UpgradeSetting />}
          {currentSettingMenu === SettingMenuType.sharePage && <SpaceSharePages />}
          {currentSettingMenu === SettingMenuType.order && <OrderSetting />}
        </div>
      </div>
    </div>
  );
};
