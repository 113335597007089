import { cx } from '@flowus/common/cx';
import { CollectionViewType, CoverType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { UNTITLED } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import {
  useCoverSetting,
  useShowingTablePageIcon,
} from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { setAppUiState, useNewCreatedRecord } from 'src/services/app';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { useBitable } from '../../context';

interface Props {
  recordId: string;
}

export const CardTitle: FC<Props> = ({ recordId }) => {
  const { viewId, viewType, tableCellWrap } = useBitable();
  const updateValue = useUpdatePropertyValue();
  const block = usePickBlock(recordId, ['subNodes', 'data'], ['segments', 'icon']);
  const [properties = []] = useProperties(viewId);
  const { previewType } = useCoverSetting(viewId);
  const noPreview = previewType === CoverType.NONE;
  const isGallery = viewType === CollectionViewType.GALLERY;
  const newCreateRecord = useNewCreatedRecord();
  const isNewCreateRecordId = recordId === newCreateRecord?.id && viewId === newCreateRecord.viewId;
  const showIcon = (block?.subNodes ?? []).length > 0 || Boolean(block?.data.icon?.type);
  const isShowPageIcon = useShowingTablePageIcon(viewId);

  const [showEditor, setShowEditor] = useState(false);

  useEffect(() => {
    setShowEditor(isNewCreateRecordId);
    const onClick = () => {
      setAppUiState({ $newCreatedRecord: undefined });
    };
    if (isNewCreateRecordId) {
      document.addEventListener('click', onClick);
    }
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [isNewCreateRecordId]);

  const titleVisible = properties.find((property) => property.property === 'title')?.visible;
  if (!titleVisible) return null;

  if (!block) return null;
  return (
    <div
      className={cx(
        'page-title space-x-1',
        isGallery ? 'my-1.5 px-3' : 'my-1 px-2',
        showEditor && 'cursor-text'
      )}
      onClick={(e) => {
        if (showEditor) {
          e.stopPropagation();
        }
      }}
    >
      {((showIcon && !isNewCreateRecordId) || (isGallery && noPreview) || showEditor) &&
        isShowPageIcon && (
          <IconTrigger
            className="inline-block p-0 align-middle"
            tooltipClassName="align-middle"
            blockId={recordId}
            trigger={showEditor}
            iconSize={16}
            defaultIcon={<BlockDefaultIcon uuid={recordId} size="small" />}
          />
        )}

      {showEditor ? (
        <RichTextEdit
          uuid={recordId}
          className="text-t2-medium inline whitespace-pre-wrap break-words align-middle"
          plugins={TITLE_EDITOR_PLUGINS}
          keydownOption={{ bracketRightKey: false }}
          placeholder={UNTITLED}
          autoFocus={true}
          toolbar={false}
          autoFocusCaretToEnd={true}
          segments={block.data.segments}
          interactable={isNewCreateRecordId}
          onChange={(segment) => updateValue(recordId, 'title', segment)}
          onKeyDown={(event) => {
            if (isHotkey('mod+a')(event)) {
              event.stopPropagation();
              return;
            }
            if (isHotkey('enter')(event) || isHotkey('esc')(event)) {
              setAppUiState({ $newCreatedRecord: undefined });
            }
          }}
        />
      ) : (
        <RichText
          className={cx('text-t2-medium inline break-words align-middle', {
            'text-ellipsis': !tableCellWrap,
            'whitespace-pre-wrap': tableCellWrap,
          })}
          plugins={TITLE_EDITOR_PLUGINS}
          segments={block?.data.segments}
          placeholder={UNTITLED}
          interactable={false}
        />
      )}

      <BlockDiscussionsBadge
        className="inline-flex align-middle pointer-events-none"
        blockId={recordId}
      />
    </div>
  );
};
