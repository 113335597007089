import { getFormatUrl } from '@flowus/common/url';
import type { Location } from 'history';
import { useCallback } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { checkViewPath, parseCustomDomain } from 'src/common/utils/url-utils';
import { $appUiStateCache } from 'src/services/app';
import { validate } from 'uuid';
import { SearchParams, ViewPath } from '.';
import { qs } from './querystring';
import { fixLocation, parseRightPageId, useMasterHistory } from './right-utils';

// 官网模板预览页
export const isEmbedTemplate =
  (() => {
    try {
      return window !== top && top?.location.href.startsWith(`${location.origin}/template`);
    } catch (error) {
      return false;
    }
  })() ?? false;

export const getPreviewTemplateId = () => (top as any).getPreviewTemplateId?.();
/**
 * 这个方式只适合用于判断当前页面是否是share页，页面只读状态 应该使用useReadOnly。除非你确定你的逻辑只需要判断是不是在分享页,否则别用.
 * deprecated的原因是为了提醒使用者注意 --> 模板中心的页面预览也是只读模式，但有些逻辑用下面这个方法来判断是否只读，会导致模板中心的预览页面也能编辑。
 */
export const isPublicHomePage = () => $appUiStateCache.$publicHomePage;
export const judgeSharePage = () => {
  try {
    if (isPublicHomePage()) {
      return true;
    }
    return checkViewPath(ViewPath.share) || checkViewPath(ViewPath.form);
  } catch {
    return false;
  }
};

export const judgeSubscribePage = () => {
  if (isPublicHomePage()) {
    return true;
  }
  return checkViewPath(ViewPath.subscribe);
};

export const isPreviewPage = () => {
  return checkViewPath(ViewPath.preview);
};

export function extractPageIdFromPathname(pathname: string) {
  const publicHomePage = $appUiStateCache.$publicHomePage;
  if (publicHomePage && window.location.pathname === '/') {
    return publicHomePage;
  }

  const customDomain = parseCustomDomain(pathname);
  let path = '';
  // 不要使用judgeSharePage和isPreviewPage来判断
  if (checkViewPath(ViewPath.preview, undefined, pathname)) {
    path = `${ViewPath.preview}:pageId`;
  } else if (checkViewPath(ViewPath.subscribe, undefined, pathname)) {
    path = `${ViewPath.subscribe}:pageId`;
  } else if (checkViewPath(ViewPath.share, undefined, pathname)) {
    path = customDomain ? `/${customDomain}${ViewPath.share}:pageId` : `${ViewPath.share}:pageId`;
  } else if (checkViewPath(ViewPath.form, undefined, pathname)) {
    path = `${ViewPath.form}:pageId`;
  } else {
    path = customDomain ? `/${customDomain}/:pageId` : '/:pageId';
  }

  const match = matchPath<{ pageId?: string }>(pathname, {
    path,
    sensitive: true,
  });

  if (match) {
    const pageId = match.params.pageId?.substring(0, 36);
    if (pageId && validate(pageId)) {
      return pageId;
    }
  }

  return '';
}

function extractPageIdFromLocation(location: Location) {
  location = fixLocation(location);
  return extractPageIdFromPathname(location.pathname);
}

export const useGetPageId = () => {
  const location = useLocation();
  const _location = fixLocation(location);
  return extractPageIdFromPathname(_location.pathname);
};

/**
 * 获取主显示区页面id
 */
export const useGetMasterPageId = () => {
  const history = useMasterHistory();
  let { location } = history;
  location = fixLocation(location);
  return extractPageIdFromLocation(location);
};

/**
 * PS:推荐你使用useGetPageId,这个方法在大部分场景下才是最佳选择，在右侧的元素里调用会获取rightId
 * 但由于依赖于hook，在一些不能使用hook的情况下，才需要使用下面这个方法。(或者说有一些用户交互后才需要获取pageid的可以使用这个方法)

 * 在多维表情况下，打开右侧panel的时候使用这个方法获取的pageId是动态的。
 * 如果不传参数的话，依赖于鼠标最后点击的位置来决定在左侧还是右侧，假如最后鼠标点击在右侧，则获取右侧pageId
 */
export const getDynamicPageId = (pos?: 'left' | 'right') => {
  const publicHomePage = $appUiStateCache.$publicHomePage;
  if (publicHomePage) {
    return publicHomePage;
  }

  const leftPageId = window.location.pathname.slice(-36);
  if (pos === 'left') return leftPageId;

  const rightPageId = parseRightPageId(window.location.search) ?? '';
  if (pos === 'right') return rightPageId;

  return $appUiStateCache.$isFocusInRightPanel ? rightPageId : leftPageId;
};

export const parsePageId = (pathname: string) => {
  return extractPageIdFromPathname(pathname);
};

export const getPageId = () => {
  return parsePageId(location.pathname);
};

export const useSwitchLeftRight = () => {
  const history = useMasterHistory();
  return useCallback(() => {
    const loc = history.location;
    const rightPageId = parseRightPageId(loc.search);
    const { pathname } = history.location;
    const pageId = parsePageId(loc.pathname);
    const newPathname = pathname.replace(pageId, rightPageId ?? '');
    history.push({
      pathname: newPathname,
      search: qs.stringify({
        'right-page-id': pageId,
      }),
      hash: location.hash,
    });
  }, [history]);
};

export const isShareLink = (url = location.href) =>
  getFormatUrl(url)?.pathname.includes(ViewPath.share);

export const isFormLink = (url: string) => {
  return getFormatUrl(url)?.pathname.includes(ViewPath.form);
};

export const getSearchParamsFromPageId = (url = location.href) => {
  try {
    const { searchParams } = new URL(url);
    const pageUrl = searchParams.get(SearchParams.callbackPath);
    if (pageUrl) {
      return parsePageId(new URL(pageUrl).pathname);
    }
  } catch {
    return undefined;
  }
};
