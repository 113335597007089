import type { NextBlock } from '@flowus/common/block/type';
import { pick } from 'lodash-es';
import type { PartialBlock, RequireField } from './type';

/**
 * 为了解决useBlock导致的组件重复渲染的问题而写的hook，只pick自己关心的数据，返回一个新的对象
 * 默认会把一些基本不变更的字段(见RequireField)返回，减少使用负担.
 * pickBlock在useSelector里面，需要配合deepEqual使用
 */
export function pickBlock<
  Field extends keyof Omit<NextBlock, RequireField>, // 可以输入除了RequireField之外的其它类型
  DataField extends keyof NextBlock['data']
>(
  block?: NextBlock,
  blockProps?: Field[],
  dataProps?: DataField[]
  // 返回只有U和RequireField字段的类型
): PartialBlock<Field, DataField> | undefined {
  if (!block || !blockProps) return undefined;
  const partialBlock = pick(block, ['uuid', 'spaceId', 'parentId', 'type', ...blockProps]);
  if (dataProps) {
    const partialData = pick(block.data, dataProps);
    // @ts-ignore compile error
    return { ...partialBlock, data: partialData };
  }
  // @ts-ignore compile error
  return partialBlock;
}
