import type { SpaceViewDTO } from '@next-space/fe-api-idl';
import { createAction } from '@reduxjs/toolkit';

export const CREATE_SPACE_VIEW = createAction<{
  spaceView: SpaceViewDTO;
}>('space-view/create');

export const UPDATE_SPACE_VIEW = createAction<{
  uuid: string;
  patch: Partial<Omit<SpaceViewDTO, 'uuid'>>;
}>('space-view/update');

export const DELETE_SPACE_VIEW = createAction<{ uuid: string }>('space-view/delete');

export const LIST_BEFORE_FAVORITE = createAction<{
  /** 所属spaceViewID */
  parentId: string;
  /** 移动内容 */
  uuid: string;
  /** 锚点 ID */
  before?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('favorite/list-before');

export const LIST_AFTER_FAVORITE = createAction<{
  /** 移动内容 */
  uuid: string;
  /** 容器 ID */
  parentId: string;
  /** 锚点 ID */
  after?: string;
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('favorite/list-after');

export const LIST_REMOVE_FAVORITE = createAction<{
  parentId: string; // 所属spaceViewID
  uuid: string; // 收藏页的id
  /** ignoreOp=true 则不会发送 op 给服务端 */
  ignoreOp?: boolean;
}>('favorite/list-remove');
