export function isInChina() {
  // 获取当前时区偏移（以分钟为单位）
  const offsetMinutes = new Date().getTimezoneOffset();

  // 将偏移转换为小时
  const offsetHours = -offsetMinutes / 60;

  // 中国标准时间（CST）是 UTC+8
  return offsetHours === 8; //东八区
}
