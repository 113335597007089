import { BlockType } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { getDynamicPageId } from 'src/utils/getPageId';

import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';

export const outdentBlock = (ids: string[]) => {
  const { blocks } = getState();

  const firstId = ids[0];
  const lastId = last(ids);

  if (!firstId || !lastId) return;

  const firstBlock = blocks[firstId];
  if (!firstBlock) return;
  const lastBlock = blocks[lastId];
  if (!lastBlock) return;

  const parent = blocks[firstBlock.parentId];
  if (
    !parent ||
    getDynamicPageId() === parent.uuid ||
    parent.type === BlockType.COLUMN ||
    parent.type === BlockType.SYNC_CONTAINER
  ) {
    return;
  }

  const index = parent.subNodes.indexOf(lastId);

  if (index > -1) {
    const afterSiblings = parent.subNodes.slice(index + 1);

    afterSiblings.forEach((uuid) => {
      dispatch(LIST_REMOVE_BLOCK({ uuid }));
      dispatch(
        LIST_AFTER_BLOCK({
          uuid,
          parentId: lastId,
        })
      );
    });
  }

  ids.reduce((prev, uuid) => {
    dispatch(LIST_REMOVE_BLOCK({ uuid }));
    dispatch(
      LIST_AFTER_BLOCK({
        uuid,
        parentId: parent.parentId,
        after: prev,
      })
    );
    return uuid;
  }, parent.uuid);
};
