import { useCallback } from 'react';
import { useCurrentUser, useUpdateUser } from 'src/hooks/user';
import type { UserInfo } from 'src/services/user/current-user';

/** 更新用户setting */
export const useUpdateUserSetting = () => {
  const updateUser = useUpdateUser();
  const currentUser = useCurrentUser();

  return useCallback(
    async (params: UserInfo['setting']) => {
      const setting = currentUser.setting ?? {};
      await updateUser({ setting: { ...setting, ...params } });
    },
    [currentUser.setting, updateUser]
  );
};
