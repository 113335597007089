import { DateFormat } from '@next-space/fe-api-idl';
import type { Reminder } from './types';
import { remindDayOptions, remindTimeOptions } from './types';

export const findMatchReminderIndex = (reminder: Reminder | undefined, includeTime: boolean) => {
  if (!reminder) return 0;
  const remindOptions = includeTime ? remindTimeOptions : remindDayOptions;
  let index = remindOptions.findIndex(
    (o) => o.value === reminder.value && o.unit === reminder.unit
  );
  if (index !== -1) return index;
  // 如果用户切换了includeTime选项，则需要找到匹配到的项，比如提前5分钟，关闭includeTime之后需要 匹配当天（上午9:00）
  if (reminder.unit === 'minute' || reminder.unit === 'hour') return 1;
  index = remindOptions.findIndex((v) => reminder.unit === v.unit && reminder.value === v.value);
  if (index !== -1) return index;
  return 0;
};
// 怀疑是之前某一端的数据不对,导致有少数用户的dateFormat不对
export const getValidDateFormat = (dateFormat: DateFormat) => {
  if (
    dateFormat === 'LL' ||
    dateFormat === 'YYYY/MM/DD' ||
    dateFormat === 'DD/MM/YYYY' ||
    dateFormat === 'MM/DD/YYYY' ||
    dateFormat === 'relative'
  ) {
    return dateFormat;
  }
  return DateFormat.YYYY_MM_DD;
};
