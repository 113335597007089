import { Button } from '@flowus/common/components';
import { ScrollLoadTable } from '@flowus/common/components/scroll-load-list';
import { cx } from '@flowus/common/cx';
import { useCloseModal, useOpenModal } from '@flowus/common/next-modal';
import { Modals } from '@flowus/shared';
import dayjs from 'dayjs';
import { type FC } from 'react';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request/request';
import { useOpenTransfer } from 'src/hooks/public/use-open-transfer';
import { useWallet } from 'src/hooks/user/use-wallet';
import { SettingModalCommon } from '../common';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { InvitationInfo } from './invitation-info';
import { OrderRecordModal } from './order-record-modal';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';

export const ProfitSetting: FC = () => {
  const closeSettingModal = useCloseSettingModal();

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        <SettingModalCommon.Header title="邀请返佣" />
        <MyProfitAccount />
        <SettingModalCommon.SettingDivider />
        <InvitationInfo />
      </div>
      <SettingModalCommon.FooterBar cancelText="关闭" cancel={() => closeSettingModal()} />
    </>
  );
};

const MyProfitAccount: FC = () => {
  const openModal = useOpenModal();
  const openTransfer = useOpenTransfer();
  const [wallet] = useWallet();

  const openTotalRecordModal = () => {
    openModal.modal({
      modalId: Modals.ORDER_RECORD_MODAL,
      content: () => <OrderRecordModal type="all" />,
    });
  };

  const openDrawingModal = () => {
    openModal.modal({
      modalId: Modals.ORDER_RECORD_MODAL,
      content: () => <DrawingModal />,
    });
  };

  return (
    <div className="text-t2 py-4 space-y-2.5">
      <div className="flex items-center justify-between">
        <div className="space-y-2 animate-click" onClick={openTotalRecordModal}>
          <div className="space-x-1 flex items-center">
            <span>累计获得佣金</span>
            <SettingModalCommon.Arrow />
          </div>
          <span className="space-x-1">
            <span>¥</span>
            <span className="text-h2">{wallet?.totalAmount.toFixed(2)}</span>
          </span>
        </div>
        <div>
          <Button onClick={() => openTransfer({ type: 'user', spaceId: getCurrentSpaceId() })}>
            提现
          </Button>
        </div>
      </div>
      <div className="flex items-center space-x-24">
        <div className="flex flex-col space-y-1">
          <span>可提现金额</span>
          <span className="space-x-1">
            <span>¥</span>
            <span className="text-h3">{wallet?.canWithdrawAmount.toFixed(2)}</span>
          </span>
        </div>
        <Tooltip popup="5-7个工作日到账" className="cursor-help flex flex-col space-y-1">
          <span className="border-b border-dashed border-grey3">入账中金额</span>
          <span className="space-x-1">
            <span>¥</span>
            <span className="text-h3">{wallet?.inProgressAmount.toFixed(2)}</span>
          </span>
        </Tooltip>
        <div className="flex flex-col space-y-1 animate-click" onClick={openDrawingModal}>
          <span>
            <span>已提现金额</span>
            <SettingModalCommon.Arrow />
          </span>
          <span className="space-x-1">
            <span>¥</span>
            <span className="text-h3">{wallet?.alreadyWithdrawAmount.toFixed(2)}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

/** 提现记录 */
const DrawingModal: FC = () => {
  const closeModal = useCloseModal();

  const getTransferListApi = async (params: { pageIndex: number; pageSize: number }) => {
    const res = await request.infra.withdrawForVipOrderList(params.pageIndex, params.pageSize);

    return {
      more: res.more,
      list: res.list,
    };
  };

  return (
    <ScrollLoadTable
      className="min-w-[500px]"
      modalHeader={{
        title: '提现记录',
        closeModal: () => closeModal(Modals.ORDER_RECORD_MODAL),
      }}
      getApi={getTransferListApi}
      titleList={[{ title: '金额' }, { title: '提现状态' }, { title: '时间' }]}
      dateList={[
        {
          id: 'total',
          render: (item) => `¥${item.total}`,
        },
        {
          id: 'status',
          render: (item) => {
            const fail = item.status === 2;
            return <span className={cx(fail && 'text-red')}>{fail ? '提现失败' : '提现成功'}</span>;
          },
        },
        {
          id: 'createdAt',
          render: (item) => {
            return dayjs(item.createdAt).format('YYYY-MM-DD HH:mm');
          },
        },
      ]}
    />
  );
};
