import type { SegmentDTO } from '@next-space/fe-api-idl/lib/models/SegmentDTO';
import { TextType } from '@next-space/fe-api-idl/lib/models/TextType';
import type { SubscribeInfo } from './type';
import type { BlockDTO, CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { allBlocks } from './allBlocks';

export const segmentsToText = (segments: SegmentDTO[] | null = []): string => {
  if (!segments) segments = [];
  return segments.map((item) => item.text).join('');
};

export const textToSegments = (text?: string | number): SegmentDTO[] => {
  if (text == null) return [];
  return [{ text: text.toString(), type: TextType.TEXT, enhancer: {} }];
};

export const readUrlFromSegments = (segments: SegmentDTO[] | undefined) => {
  const segment = segments?.find((item) => item.type === TextType.URL);
  return segment?.url ?? segmentsToText(segments);
};

export interface SearchFilter {
  distributionParnter?: boolean;
  /**是否免费订阅 */
  free?: boolean;
  tag?: string;
  keyword?: string;
}

export const searchSubscribeInfo = (subscribeInfos: SubscribeInfo[], filter: SearchFilter) => {
  if (!filter) {
    return subscribeInfos;
  }
  const ret = subscribeInfos.filter((info) => {
    if (filter.distributionParnter) {
      const isDistributionParnter = !!(info.accessFee && info.distributionRatio);
      if (!isDistributionParnter) return false;
    }
    if (filter.free) {
      if (info.accessFee) return false;
    }
    if (filter.tag && filter.tag !== '全部') {
      if (info.tag !== filter.tag) return false;
    }
    if (filter.keyword) {
      const kw = filter.keyword.toUpperCase();
      const isLike =
        info.userNickname?.toUpperCase()?.includes(kw) ||
        info.blockTitle?.toUpperCase()?.includes(kw) ||
        info.tag?.toUpperCase().includes(kw);
      if (!isLike) return false;
    }

    return true;
  });
  return ret;
};

export const getSubscribeTags = (subscribeInfos: SubscribeInfo[]) => {
  const tags = subscribeInfos.map((v) => v.tag ?? '').filter((v) => v);
  const set = new Set(tags);
  moveValueToLast(set, '其他');
  return ['全部', ...set];
};
const moveValueToLast = (set: Set<string>, value: string) => {
  if (set.has(value)) {
    set.delete(value);
    set.add(value);
  }
};

export const getPropertyMapping = (uuid: string) => {
  const propertyMapping: Record<string, { id: string; schema: CollectionSchema }> = {};

  const collection = allBlocks.blocks[uuid];
  if (!collection) return propertyMapping;

  Object.entries(collection.data.schema ?? {}).forEach(([id, schema]) => {
    const key = schema.name.split('（')[0] ?? schema.name;
    propertyMapping[key] = { id, schema };
  });

  return propertyMapping;
};

export const getPropertyValue = (block: string | BlockDTO, propertyName: string) => {
  let blockData: BlockDTO | undefined = undefined;
  if (typeof block === 'string') {
    blockData = allBlocks.blocks[block];
  } else {
    blockData = block;
  }

  if (!blockData) return '';

  const propertyMapping = getPropertyMapping(blockData.parentId);
  const property = propertyMapping[propertyName];
  const id = property?.id;
  if (!id) return '';

  switch (property.schema.type) {
    case CollectionSchemaType.TITLE:
      return segmentsToText(blockData.data.segments);

    case CollectionSchemaType.FILE:
    case CollectionSchemaType.URL: {
      const segments = blockData.data.collectionProperties?.[id];
      return readUrlFromSegments(segments);
    }

    default:
      return segmentsToText(blockData.data.collectionProperties?.[id]);
  }
};
