import { useEffect, useRef } from 'react';

export const useFocused = (
  divRef: React.RefObject<HTMLElement>,
  callback: (focused: boolean) => void
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const divContainer = divRef.current;
    if (!divContainer) return;
    const focusin = () => {
      callbackRef.current(true);
    };
    const focusout = () => {
      callbackRef.current(false);
    };

    divContainer.addEventListener('focusin', focusin);
    divContainer.addEventListener('focusout', focusout);

    return () => {
      divContainer.removeEventListener('focusin', focusin);
      divContainer.removeEventListener('focusout', focusout);
    };
  }, [divRef]);
};
