import { useMindMapSelector } from '@flowus/mind-map';
import { useIsInFixWidthContext } from '../fix-width-context-provider';

export const useMindNodeFixWidth = (id: string, level: number) => {
  const isInFixWidthContext = useIsInFixWidthContext();
  const fixWidth = useMindMapSelector((state) => {
    if (state.loading || isInFixWidthContext) return;
    const record = state.fixWidthRecord[level];
    if (!record) return;
    if (record.id === id) return;
    return record.fixWidth;
  });
  if (isInFixWidthContext) {
    return;
  }

  return fixWidth || undefined; // 如果是0的话会返回undefined
};
