import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { DATE_FORMAT } from 'src/common/const';
import { request } from 'src/common/request';
import { PRODUCT_AI_TITLE } from 'src/const/title';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useIsAiPlan, useSpaceCapacity } from 'src/services/capacity';
import type { AsyncReturnType } from 'type-fest';

/** ai配额历史 */
export const FlowusAIHistory: FC = () => {
  const { aiRestUsage, usage, gpt3_5, pdf } = useSpaceCapacity();
  const scrollRef = useRef<HTMLDivElement>(null);
  const isAiPlan = useIsAiPlan();
  const isProSpace = useIsProSpace();

  /** 积分邀请 */
  const [integralList, setIntegralList] = useState<
    AsyncReturnType<typeof request.infra.getSpaceAiDetail>
  >([]);

  useEffect(() => {
    void loadIntegralList(1);
  }, []);

  const loadIntegralList = async (page: number) => {
    const spaceId = getCurrentSpaceId();
    const res = await request.infra.getSpaceAiDetail(spaceId, page, 20);
    setIntegralList(res);
  };

  const monthUsed = gpt3_5.monthUsed + pdf.monthUsed;
  const gpt3Rest = isAiPlan
    ? gpt3_5.monthLimit - gpt3_5.monthUsed
    : gpt3_5.dayLimit - gpt3_5.todayUsed;
  const pdfRest = isAiPlan ? pdf.dayLimit - pdf.todayUsed : pdf.monthLimit - pdf.monthUsed;
  return (
    <div className="py-[5px] max-h-[60vh] min-w-[500px] flex flex-col">
      <div className="flex items-center justify-between h-[50px] px-7 flex-shrink-0">
        <div className="text-h4">{PRODUCT_AI_TITLE} 用量详情</div>
        <p className="text-t2 text-grey3">
          已使用 {__BUILD_IN__ ? monthUsed : usage}次/
          {__BUILD_IN__ ? gpt3Rest + pdfRest : isProSpace ? '无限' : aiRestUsage}次
        </p>
      </div>
      <div className="bg-grey6 h-px my-1 w-full flex-shrink-0" />
      <div ref={scrollRef} className="overflow-y-auto h-full">
        <InfiniteScroll
          className="flex flex-col px-7 space-y-2"
          pageStart={1}
          initialLoad={false}
          useWindow={false}
          loadMore={loadIntegralList}
          getScrollParent={() => scrollRef.current}
          // hasMore={integralList.total > integralList.list.length}
        >
          <div className="text-t2 h-10 flex items-center">
            <span className="w-40">记录类型</span>
            <span className="w-28">请求量（次）</span>
            <span className="w-28">使用量</span>
            <span className="w-40">生效时间</span>
            <span className="w-40">记录时间</span>
          </div>
          <div className="bg-grey6 h-px my-1 space-y-2" />

          {integralList.map((item) => (
            <div key={item.updatedAt} className="flex w-full justify-between items-center h-10">
              <div className="text-t2 w-40 flex flex-col">
                {item.title}
                {(item.endAt ?? 0) > 0 && (
                  <span className="text-t4 text-grey3 w-[140px]">
                    有效期至 {dayjs(item.endAt).format(DATE_FORMAT)}
                  </span>
                )}
              </div>
              <div className="text-t2 w-28">{item.count}</div>
              <div className="text-t2 w-28">
                {(item.usage ?? 0) > 0 && '+'}
                {item.usage}
              </div>
              {item.createdAt && (
                <div className="text-t2 w-40">
                  {dayjs(item.createdAt).format('YYYY/MM/DD HH:mm')}
                </div>
              )}
              {item.updatedAt && (
                <div className="text-t2 w-40">
                  {dayjs(item.updatedAt).format('YYYY/MM/DD HH:mm')}
                </div>
              )}
            </div>
          ))}
          {!integralList.length && <div className="text-t2 text-grey4 mt-2">暂无记录</div>}
        </InfiniteScroll>
      </div>
    </div>
  );
};
