import type { UploadResult } from './oss/types';

/**
 * @public 表示不需要授权，就能外部访问，不需要计算空间容量（用户头像上传、空间头像上传）
 * @temp 临时文件，不需要计算空间容量（导入文件上传）
 * @file 表示需要授权，才能外部访问，需要计算容量（空间内文件上传）
 * @private 不需要计算容量，只有内部才能访问（申述、举报举证图片和用户认证上传）
 */
export type UploadType = 'public' | 'temp' | 'file' | 'private';

/**登录后上传需要的参数(后台说要做校验用) */
export interface UploadParams {
  file: File;
  spaceId: string;
  userId: string;
  type: UploadType;
  /** 新手期上传多维表有区分大小限制 */
  isDatabase?: boolean;
  /**只有传递了这个参数，最近上传可以搜得到对应的数据 */
  searchType?: 'icon' | 'avatar' | 'cover' | 'spaceIcon';
  onCapacitySuccess?: () => void;
  onProgress?: (progress: number) => void;
  onComplete: (result: UploadResult, blockId?: string) => void;
  /**是否在传输列表中，只有文档块内上传，文件夹页面上传以及多维表媒体属性上传才需要设置 */
  inTransferList?: boolean;
  /**传输列表需要跳转 */
  blockId?: string;
  pageId?: string;
}

export enum UploadErrorCode {
  /**找不到上传任务,内部错 */
  CODE_NOT_FOUND_TASK = 1000,
  UPLOAD_FAILED = 1001,
  /**获取token失败,错误信息由后台接口返回 */
  GET_TOKEN_FAILED = 1002,
  /**上传取消 */
  UPLOAD_CANCEL = 1003,
  /**总容量超了 */
  UPLOAD_MAX_CAPACITY_LIMIT = 1004,
  /**单文件大小超了 */
  UPLOAD_SINGLE_FILE_CAPACITY_LIMIT = 1005,
}
