import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';

/**
 * 为当前月的日期数字改颜色，dom 操作用来优化。每个 DateNode 都监听 currentMonth 会导致性能太差
 * @param currentMonth
 * @param calenderDates
 * @param containerNode
 */
export const highlightCurrentDate = (
  currentMonth: number,
  calenderDates: number[],
  containerNode: HTMLDivElement
) => {
  const newMonthFirstDate = getDateTimeStamp(new Date(currentMonth).setDate(1));
  const newMonthLastDate = getDateTimeStamp(
    new Date(currentMonth).setMonth(new Date(currentMonth).getMonth() + 1, 0)
  );

  const currentMonthNodes = containerNode.querySelectorAll(
    '.calender-date.current-month-date'
  ) as NodeListOf<HTMLElement>;
  currentMonthNodes.forEach((node) => {
    node.classList.remove('current-month-date');
  });

  const calenderTodoNodes = containerNode.querySelectorAll(
    '.calender-date'
  ) as NodeListOf<HTMLElement>;

  const min = newMonthFirstDate + ONE_DAY / 2;
  const max = newMonthLastDate + ONE_DAY / 2;

  Array.from(calenderTodoNodes).forEach((node) => {
    const date = parseInt(node.dataset.date ?? '', 10);
    if (date >= min && date <= max) {
      node.classList.add('current-month-date');
    }
  });
};

/**
 * 拖拽时给当前碰撞到的目标 date 加高亮
 * @param x 坐标
 * @param y 坐标
 * @returns
 */
export const updateHoverHighlight = (x: number, y: number, containerNode: HTMLElement) => {
  const currentHoverDate = containerNode.querySelector('.calender-date.drag-hover-highlight');
  currentHoverDate?.classList.remove('drag-hover-highlight');
  const calenderTodoNodes = containerNode.querySelectorAll(
    '.calender-date'
  ) as NodeListOf<HTMLElement>;
  let targetIndex: number | undefined;
  let targetNode: HTMLElement | undefined;
  Array.from(calenderTodoNodes).forEach((node, index) => {
    const rect = node.getBoundingClientRect();
    const isTarget = x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;

    if (isTarget) {
      targetIndex = index;
      targetNode = node;
      node.classList.add('drag-hover-highlight');
    }
  });

  return { targetIndex, targetNode };
};
