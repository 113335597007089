import { cx } from '@flowus/common/cx';
import type { FC } from 'react';

interface Props {
  progress: number;
  fail?: boolean;
}
export const ProgressBar: FC<JSX.IntrinsicElements['span'] & Props> = ({
  progress,
  fail,
  ...rest
}) => {
  return (
    <span {...rest}>
      <div
        className={cx(
          'h-full opacity-80 bg-active_color transition-all rounded-full',
          fail && 'bg-grey3 opacity-100'
        )}
        style={{ width: `${progress}%` }}
      />
    </span>
  );
};
