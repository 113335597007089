const resourceMap: Record<string, Promise<void>> = {};

export const loadResource = (url: string, tagName: 'script' | 'link', async = false) => {
  if (resourceMap[url]) return resourceMap[url];

  resourceMap[url] = new Promise((resolve, reject) => {
    let tag: HTMLScriptElement | HTMLLinkElement;
    if (tagName === 'script') {
      tag = document.createElement(tagName) as HTMLScriptElement;
      tag.async = async;
      tag.src = url;
    } else {
      tag = document.createElement(tagName) as HTMLLinkElement;
      tag.href = url;
      tag.rel = 'stylesheet';
      tag.type = 'text/css';
    }

    tag.onload = () => resolve();
    tag.onerror = (event) => {
      delete resourceMap[url];
      reject(event);
    };
    document.head.insertBefore(tag, document.head.firstChild);
  });

  return resourceMap[url];
};
/**由于css无法async加载，有些第三方平台的css加载不出来就会一直卡在loading处一分多钟 */
export const loadCSSWithXHR = (url: string, timeout: number) => {
  if (resourceMap[url]) return resourceMap[url];
  resourceMap[url] = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.timeout = timeout;

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const style = document.createElement('style');
        style.textContent = xhr.responseText;
        document.head.appendChild(style);
        resolve();
      } else {
        delete resourceMap[url];
        reject(new Error(`Failed to load CSS: ${url}`));
      }
    };

    xhr.onerror = () => {
      delete resourceMap[url];
      reject(new Error(`Error loading CSS: ${url}`));
    };

    xhr.ontimeout = () => {
      delete resourceMap[url];
      reject(new Error(`Timeout loading CSS: ${url}`));
    };

    xhr.send();
  });
  return resourceMap[url];
};
