import { Compare } from '@flowus/common';
import { formula } from '@flowus/formula';
import type { BlockDTO, CollectionSchema, SegmentDTO, UserDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import type { BiResolver } from 'src/bitable/v2';
import { getPropValueType, readPropValue } from 'src/bitable/v2';
import { BiCheckboxType } from 'src/bitable/v2/values/checkbox';
import { UNTITLED } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';
import type { FormulaTool } from 'src/hooks/block/use-formula-tool';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import { getUserName } from 'src/hooks/user/use-remark-name';
import { getState } from 'src/redux/store';
import type { NextBlock, RootState } from 'src/redux/types';
import { percentToNumber } from 'src/utils/number';
import { getRelationRecords } from '../cell/relation/get-relation-records';
import { getRollupValue } from '../cell/rollup/get-rollup-value';
import { isDateAggregation, isPercentAggregation, isShowOriginalValue } from '../footer/helper';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

interface CollectionSorter {
  property: string;
  direction: 'asc' | 'desc';
  [Key: string]: unknown;
}

const fileSegmentsToComparable = (segments: SegmentDTO[] | undefined) => {
  const fileSegments = (segments ?? []).filter(
    (it) => it.type === TextType.URL && it.fileStorageType === 'internal'
  );
  return fileSegments.length > 0 ? fileSegments.map((it) => it.text) : null;
};

export const titleGetter = (block: NextBlock) => block.data.segments;
export const createdAtGetter = (block: NextBlock) => block.createdAt;
export const updatedAtGetter = (block: NextBlock) => block.updatedAt;
export const createdByGetter = (block: NextBlock) => block.createdBy;
export const updatedByGetter = (block: NextBlock) => block.updatedBy;
export const genericGetter = (property: string) => (block: NextBlock) =>
  block.data.collectionProperties?.[property];

export const relationGetter = (property: string) => (block: NextBlock) => {
  const { blocks } = getState();
  const { relationRecords } = getRelationRecords(block.uuid, property, blocks);

  let result = '';
  relationRecords.forEach((uuid: string) => {
    const block = blocks[uuid];
    if (!block) return;
    result += segmentsToText(block.data.segments) || UNTITLED;
  });

  return !result ? null : result;
};

export function buildCompareFunc(
  sorters: CollectionSorter[],
  opts: {
    collectionId: string;
    schemas: Record<string, CollectionSchema> | undefined;
    ranking: Map<string, number>;
    users: { [id: string]: UserDTO };
    formulaTool: FormulaTool;
    state?: RootState;
  }
) {
  const blockCmps: Compare.CompareFunc<string>[] = [];
  const { collectionId, schemas, ranking, formulaTool, state = getState() } = opts;

  const formulaGetter = (propId: string) => (block: string) => {
    const value = formulaTool.getValue(block, propId);
    const type = formulaTool.getType(propId);
    if (type === formula.ValueTool.stringType && value === '') {
      return null;
    } else if (type === formula.ValueTool.booleanType && value == null) {
      return false;
    }

    return typeof value === 'number' && isNaN(value) ? null : value;
  };

  const resolver: BiResolver = {
    findSchema(collId, propId) {
      return state.blocks[collId]?.data.schema?.[propId];
    },
    findUser(id) {
      return state.users[id];
    },
    findRecord(_collId, recordId) {
      return state.blocks[recordId] as BlockDTO | undefined;
    },
  };

  const buildCompareFuncForSorter = ({ property, direction }: CollectionSorter) => {
    const valueType = getPropValueType(collectionId, property, resolver);
    const { type, aggregation, targetProperty } = schemas?.[property] ?? {};
    // eslint-disable-next-line default-case
    switch (type ?? CollectionSchemaType.TEXT) {
      case CollectionSchemaType.FORMULA: {
        const type = formulaTool.getType(property);
        const cmp =
          type === formula.ValueTool.stringType
            ? Compare.pinyin
            : type === formula.ValueTool.booleanType
            ? Compare.by((it) => (it ? 1 : 0), Compare.number)
            : type === formula.ValueTool.numberType
            ? Compare.number
            : type === formula.ValueTool.dateType
            ? Compare.by((it: Date) => it.getTime(), Compare.number)
            : Compare.poly;
        return Compare.by(
          formulaGetter(property) as (record: string) => any,
          Compare.nullsLast(Compare.reversed(cmp as Compare.CompareFunc<any>, direction === 'desc'))
        );
      }
      case CollectionSchemaType.ROLLUP: {
        if (isShowOriginalValue(aggregation)) {
          const { relationPropertySchema, targetPropertySchema } = getPropertySchema(
            collectionId,
            property
          );
          const targetPropertyType = targetPropertySchema?.type ?? CollectionSchemaType.TEXT;
          if (targetPropertyType === CollectionSchemaType.FORMULA) {
            const formulaTool = getFormulaTool(relationPropertySchema?.collectionId ?? '');

            const valueType = formulaTool.getType(targetProperty ?? '');

            if (
              valueType === formula.ValueTool.numberType ||
              valueType === formula.ValueTool.booleanType
            ) {
              const numberArrGetter = (property: string) => (block: NextBlock) => {
                const { originValues = [] } = getRollupValue(block.uuid, property) ?? {};
                let value: number[] = [];

                if (valueType === formula.ValueTool.booleanType) {
                  value = originValues.map((text: string) => {
                    if (typeof text === 'string' && formatCheckBoxValue(text)) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  value = originValues.map((item) => Number(item));
                }

                return value.length > 0 ? value : null;
              };

              return Compare.by(
                numberArrGetter(property),
                Compare.nullsLast(
                  Compare.reversed(
                    Compare.infArray(Compare.nullsLast(Compare.number)),
                    direction === 'desc'
                  )
                )
              );
            } else if (
              valueType === formula.ValueTool.stringType ||
              valueType === formula.ValueTool.dateType
            ) {
              const stringArrGetter = (property: string) => (block: string) => {
                const { originValues = [] } = getRollupValue(block, property) ?? {};
                return originValues.length > 0 ? originValues : null;
              };

              return Compare.by(
                stringArrGetter(property),
                Compare.nullsLast(
                  Compare.reversed(
                    Compare.infArray(Compare.nullsLast(Compare.string)),
                    direction === 'desc'
                  )
                )
              );
            }

            return () => 0;
          } else if (
            targetPropertyType === CollectionSchemaType.CHECKBOX ||
            targetPropertyType === CollectionSchemaType.NUMBER ||
            targetPropertyType === CollectionSchemaType.SELECT ||
            targetPropertyType === CollectionSchemaType.MULTI_SELECT
          ) {
            const isNumber = targetPropertyType === CollectionSchemaType.NUMBER;
            const isCheckbox = targetPropertyType === CollectionSchemaType.CHECKBOX;

            const numberArrGetter = (property: string) => (block: string) => {
              const { originValues = [], targetPropertySchema } =
                getRollupValue(block, property) ?? {};

              let value: number[] = [];

              if (isCheckbox) {
                value = originValues.map((text: string) => {
                  if (typeof text === 'string' && formatCheckBoxValue(text)) {
                    return 1;
                  }
                  return 0;
                });
              } else if (isNumber) {
                value = originValues.map((item) => Number(item));
              } else {
                const options = targetPropertySchema?.options ?? [];
                const availableTags = options.map((it) => it.id);
                value = originValues
                  .map((item) => (typeof item === 'undefined' ? -1 : availableTags.indexOf(item)))
                  .filter((item) => item !== -1);
              }

              return value.length > 0 ? value : null;
            };

            return Compare.by(
              numberArrGetter(property),
              Compare.nullsLast(
                Compare.reversed(
                  Compare.infArray(Compare.nullsLast(Compare.number)),
                  direction === 'desc'
                )
              )
            );
          } else if (targetPropertyType === CollectionSchemaType.FILE) {
            const getter = (property: string) => (block: string) => {
              const { relationRecords } = getRollupValue(block, property) ?? {};

              const { blocks } = getState();
              const allFileNames: string[] = [];
              relationRecords?.forEach((uuid) => {
                const segments = blocks[uuid]?.data.collectionProperties?.[targetProperty ?? ''];
                const fileName = fileSegmentsToComparable(segments);
                if (fileName) {
                  allFileNames.push(...fileName);
                }
              });
              return allFileNames.length > 0 ? allFileNames : null;
            };

            return Compare.by(
              getter(property),
              Compare.nullsLast(
                Compare.reversed(Compare.array(Compare.pinyin), direction === 'desc')
              )
            );
          } else if (
            targetPropertyType === CollectionSchemaType.TITLE ||
            targetPropertyType === CollectionSchemaType.RELATION
          ) {
            const stringGetter = (property: string) => (block: string) => {
              const { originValues = [], relationRecords = [] } =
                getRollupValue(block, property) ?? {};

              const isTitle = targetPropertyType === CollectionSchemaType.TITLE;
              const { blocks } = getState();
              const values = (isTitle ? relationRecords : originValues).map((uuid: string) => {
                return segmentsToText(blocks[uuid]?.data.segments) || UNTITLED;
              });
              const titleString = values.join('').trim();
              return titleString ? titleString : null;
            };

            return Compare.by(
              stringGetter(property),
              Compare.nullsLast(Compare.reversed(Compare.pinyin, direction === 'desc'))
            );
          }

          const stringArrGetter = (property: string) => (block: string) => {
            const { originValues = [] } = getRollupValue(block, property) ?? {};
            let value: string[] = originValues;

            if (
              targetPropertyType === CollectionSchemaType.PERSON ||
              targetPropertyType === CollectionSchemaType.CREATED_BY ||
              targetPropertyType === CollectionSchemaType.UPDATED_BY
            ) {
              value = originValues
                .map((uuid) => getUserName(uuid))
                .filter((item): item is string => !!item);
            }

            return value.length > 0 ? value : null;
          };

          return Compare.by(
            stringArrGetter(property),
            Compare.nullsLast(
              Compare.reversed(
                Compare.infArray(Compare.nullsLast(Compare.pinyin)),
                direction === 'desc'
              )
            )
          );
        }

        if (isDateAggregation(aggregation)) {
          const getter = (property: string) => (block: string) => {
            const { dateStartTimestamp } = getRollupValue(block, property) ?? {};
            return dateStartTimestamp ? dateStartTimestamp : null;
          };

          return Compare.by(
            getter(property),
            Compare.nullsLast(Compare.reversed(Compare.string, direction === 'desc'))
          );
        }

        const getter = (property: string) => (block: string) => {
          const { aggregationValue } = getRollupValue(block, property) ?? {};
          let value: number | null;

          if (isPercentAggregation(aggregation)) {
            value = aggregationValue ? percentToNumber(aggregationValue) : null;
          } else {
            value = aggregationValue ? Number(aggregationValue) : null;
          }

          return value;
        };

        return Compare.by(
          getter(property),
          Compare.nullsLast(Compare.reversed(Compare.number, direction === 'desc'))
        );
      }
      default:
        return (record1: string, record2: string) => {
          const value1 = readPropValue(collectionId, record1, property, valueType, resolver);
          const value2 = readPropValue(collectionId, record2, property, valueType, resolver);

          if (!(valueType instanceof BiCheckboxType)) {
            if (valueType.isEmpty(value1) && valueType.isEmpty(value2)) {
              return 0;
            }
            if (valueType.isEmpty(value1)) return 1;
            if (valueType.isEmpty(value2)) return -1;
          }
          const sign = direction === 'desc' ? -1 : 1;
          return sign * valueType.compare(value1, value2);
        };
    }
  };

  for (const sorter of sorters) {
    // @ts-ignore TODO yuxiang
    blockCmps.push(buildCompareFuncForSorter(sorter));
  }

  blockCmps.push(Compare.by((block) => ranking.get(block) ?? Infinity));
  blockCmps.push(Compare.by((block) => block));

  return Compare.combine(...blockCmps);
}
