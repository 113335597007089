import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { LocalStorageKey } from 'src/const/local-storage-state-key';
import { useReadonly } from 'src/hooks/page';
import { useCurrentSpace } from 'src/hooks/space';
import { useCtrlDragScroll } from 'src/hooks/utils/use-ctrl-drag-scroll';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { isCollection } from 'src/utils/block-type-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { useMentionLinkPage } from './hook';
import { LinkCurrentPage } from './link-current-page';
import { MentionPage } from './mention-page';
import type { TabState } from './types';

const tabs = {
  current: '引用当前页面',
  mention: '提及的页面引用',
  // suggest: '引用建议',
};

type TabKey = keyof typeof tabs;

export const LinkPageTabs: FC<{ uuid: string }> = ({ uuid }) => {
  const block = usePickBlock(uuid, []);
  const [tab, setTab] = useState<TabKey>('current');
  const [state, setState] = useState<TabState>({
    init: true,
    currentLoading: false,
    currentNum: -1,
  });
  const [expand, setExpand] = useExpand(LocalStorageKey.LINK_RECORD);
  const readonly = useReadonly(uuid, false);
  // const handleMouseDown = useClickBlankAreaCreateBlock();
  const currentSpace = useCurrentSpace();
  const ref = useRef(null);
  useCtrlDragScroll(ref);

  const linkPages = useMentionLinkPage(uuid);
  if (readonly) return null;

  const { isShowBacklink } = currentSpace.setting ?? {};
  if (typeof isShowBacklink === 'boolean' && !isShowBacklink) return null;

  if (!block) return null;

  const isFolder = block.type === BlockType.FOLDER;
  const isDatabase = isCollection(block.type);

  return (
    <div
      hidden={!state.currentLoading && state.currentNum + linkPages.length === 0}
      className={cx('line-default mb-16', isDatabase && 'mb-5', state.init && 'invisible')}
    >
      <div className="flex items-center pb-2 border-b overflow-x-auto">
        <Icon
          name="IcArrowHide"
          size="middle"
          className={cx('animate-hover transition-all', !expand ? 'rotate-90' : 'rotate-180')}
          onClick={() => setExpand(!expand)}
        />
        <div className="flex text-t2 flex-shrink-0 flex-wrap">
          {(Object.keys(tabs) as TabKey[])
            .filter((key) => (!isFolder && !isDatabase) || key === 'current')
            .map((key) => (
              <div
                key={key}
                className={cx(
                  'py-1 px-2 animate-hover flex items-center justify-center',
                  key !== tab && 'text-grey3'
                )}
                onClick={() => {
                  if (!expand) {
                    setExpand(!expand);
                  }
                  setTab(key);
                }}
              >
                <div>{tabs[key]}</div>
                <div className="ml-1 flex justify-center">
                  <Num tab={key} state={state} mentionNum={linkPages.length} />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div hidden={!expand} ref={ref} className="relative pb-2 pt-3 line-clamp-2">
        {tab === 'current' && <LinkCurrentPage setState={setState} uuid={uuid} />}
        {tab === 'mention' && <MentionPage linkPages={linkPages} />}
        {/* {tab === 'suggest' && <LinkSuggest uuid={uuid} />} */}
      </div>
    </div>
  );
};

const Num: FC<{ state: TabState; tab: TabKey; mentionNum: number }> = ({
  state,
  tab,
  mentionNum,
}) => {
  if (tab === 'current' && state.currentLoading) {
    return <LoadingIcon size="small" />;
  }

  if (tab === 'current') {
    return <div>{state.currentNum}</div>;
  }

  if (tab === 'mention') {
    return <div>{mentionNum}</div>;
  }

  return null;
};
