import { isElectron } from 'react-device-detect';

export const isFlowUsApp = new Proxy(
  {
    check: false,
  },
  {
    get: () => {
      const status = !!(isElectron && getIpcRenderer());
      return status;
    },
  }
);

export const getIpcRenderer = () => {
  // @ts-ignore flowUsIpcRenderer
  const { flowUsIpcRenderer } = window;
  if (flowUsIpcRenderer) {
    const flowusIpc = flowUsIpcRenderer;
    return flowusIpc;
  }
  return flowUsIpcRenderer;
};
