import { MoreButtonList } from '@flowus/common/components/more-button-list';
import type { FC, MouseEvent } from 'react';
import { useMemo } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { useOpenBlockRightMenuList } from 'src/editor/editor/uikit/hover-menu/block-menu-list/hook';
import { useOpenImageLinkDialog } from 'src/editor/editor/uikit/hover-menu/block-menu-list/use-open-image-link-dialog';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import type { ReadonlyProp } from 'src/redux/types';
import { AlignPanel } from '../align-panel';

export interface MoreMenuProps extends ReadonlyProp {
  externalLink?: boolean;
  hideDownload?: boolean;
  showAlign?: boolean;
  blockId: string;
  link?: string;
  onReplace: () => void;
  onDownload: () => void;
  onAddComment?: () => void;
}
/**
 * 更多菜单
 */
export const ImageMoreMenu: FC<MoreMenuProps> = (props) => {
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  const openModal = useOpenModal();
  const openImageLinkDialog = useOpenImageLinkDialog();

  const renderList = useMemo(
    () => [
      {
        title: '对齐方式',
        icon: 'IcAlignLeft',
        hidden: !props.showAlign,
        click: (event: MouseEvent) => {
          dispatch(uiActions.updateSelectBlocks([{ blockId: props.blockId }]));
          openModal.dropdown({
            placement: 'top',
            popcorn: event.currentTarget,
            content: ({ onCloseModal }) => (
              <AlignPanel blockIds={[props.blockId]} closeModal={onCloseModal} />
            ),
          });
        },
      },
      {
        title: '编辑超链接',
        icon: 'IcLink',
        click: (event: MouseEvent) => {
          openImageLinkDialog(props.blockId, event.currentTarget);
        },
      },
      {
        title: props.externalLink ? '打开链接' : '菜单',
        icon: props.externalLink ? 'IcGlobeShare' : 'IcMore',
        click: (event: MouseEvent) => {
          if (props.externalLink) {
            window.open(props.link);
          } else {
            const blockNode = document.querySelector(`[data-block-id="${props.blockId}"]`);
            openBlockRightMenuList({
              menuPosition: event.currentTarget as HTMLElement,
              blockId: props.blockId,
              blockNode: blockNode as HTMLElement,
              placement: 'right-end',
              offset: [0, 8],
            });
          }
        },
      },
      {
        title: '添加评论',
        icon: 'IcMenuReply',
        click: props.onAddComment,
        hidden: !props.onAddComment,
      },
    ],
    [
      openBlockRightMenuList,
      openImageLinkDialog,
      openModal,
      props.blockId,
      props.externalLink,
      props.link,
      props.onAddComment,
      props.showAlign,
    ]
  );

  return <MoreButtonList renderList={renderList} />;
};
