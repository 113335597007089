import { v4 } from 'uuid';

export const closeAIEditorWarningModalId = v4();
export const AI_WARNING = 'AI_WARNING';
export const AiBitableEntry = 'aiBitableEntry';
export const AiMindMapEntry = 'aiMindMapEntry';
export const AiBitableEdit = 'aiBitableEdit';
export const CODE_INIT_LINE_NUM = -Infinity;

export enum AIEditorScene {
  PageEmpty = 'PageEmpty',
  PageHasContent = 'PageHasContent',
  PageContentWroteByAI = 'PageContentWroteWithAi',
  TextSelected = 'TextSelected',
  TextSelectedAndWroteByAI = 'TextSelectedWithWrote',

  BlockSelected = 'BlockSelected',
  BlockSelectedAndWroteByAI = 'BlockSelectedAndWroteByAI',
}
export enum AIEditorFrom {
  BlockMenu = 'block_menu',
  ContextMenu = 'context_menu',
  RtfMenu = 'rtf_menu',
  EmptyAi = 'empty_ai',
  bitable = 'bitable',
  mindMap = 'mindMap',
}

export enum AIEditType {
  // 写文章
  Brainstorm = 'Brainstorm', // 头脑风暴
  Blog = 'Blog', // 写文章
  Outline = 'Outline', // 写大纲
  Story = 'Story', // 写故事
  DailyNotes = 'DailyNotes', // 日常小记
  ProsAndConsList = 'ProsAndConsList', // 优缺点
  ActionItems = 'ActionItems', // 执行步骤
  Explain = 'explain', // 解释一下

  // 多维表起草
  Bitable = 'bitTable', // 多维表起草
  Bitable_Plan = 'plan', // 写计划
  Bitable_List = 'list', // 多维表列表
  Bitable_Task_Manage = 'task', // 任务管理
  Bitable_Other = 'other', // 多维表其它内容

  // 思维导图起草
  MindMap = 'mindMap', //  思维导图起草
  MindMap_Outline = 'mindOutline', // 写大纲
  MindMap_Plan = 'mindPlan', // 写计划
  MindMap_Other = 'mindOther', // 思维导图其它内容

  // 翻译
  Chinese = 'simplified chinese',
  ClassicalChinese = 'classical chinese',
  English = 'english',
  Korean = 'korean',
  Japanese = 'japanese',
  French = 'french',
  German = 'german',
  OtherLanguage = 'OtherLanguage',

  ContinueWrite = 'ContinueWrite',
  Summarize = 'Summarize',
  TryAgain = 'TryAgain',

  Close = 'Close',
  Improve = 'Improve',

  Insert = 'Insert',
  InsertToBitableDesc = 'InsertToBitableDesc',
  Replace = 'Replace',

  Discard = 'Discard',

  Summary = 'keySummary', // 摘要
  Simpler = 'makeShorter', // 更短
  Longer = 'makeLonger', // 更长

  Professional = 'professional',
  Casual = 'casual',
  Straightforward = 'straightforward',
  Confident = 'confident',
  Friendly = 'friendly',

  Comment = 'commenting',
  BugFix = 'bugFix',

  CPlus = 'C++',
  Java = 'Java',
  JavaScript = 'JavaScript',
  TypeScript = 'TypeScript',
  Python = 'Python',
  OtherCodeLanguage = 'OtherCodeLanguage',
  Custom = 'Custom',
}

export const NeedWaitingResultEditType = new Set([
  AIEditType.ContinueWrite,
  AIEditType.Summarize,
  AIEditType.Chinese,
  AIEditType.ClassicalChinese,
  AIEditType.English,
  AIEditType.Korean,
  AIEditType.Japanese,
  AIEditType.French,
  AIEditType.German,
]);

export const isAiBitable = new Set([
  AIEditType.Bitable_Plan,
  AIEditType.Bitable_List,
  AIEditType.Bitable_Task_Manage,
  AIEditType.Bitable_Other,
]);

export const isAiMindMap = new Set([
  AIEditType.MindMap_Plan,
  AIEditType.MindMap_Outline,
  AIEditType.MindMap_Other,
]);
