import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash-es';
import {
  CREATE_COLLECTION_VIEW,
  LIST_AFTER_COLLECTION_VIEW_PAGESORT,
  LIST_BEFORE_COLLECTION_VIEW_PAGESORT,
  LIST_REMOVE_COLLECTION_VIEW_PAGESORT,
  UPDATE_COLLECTION_VIEW,
} from '../actions/collection-view';
import type { NextCollectionView } from '../types';

type State = Record<string, NextCollectionView>;

const initialState: State = {};

export const collectionViewsSlice = createSlice({
  initialState,
  name: 'collectionViews',
  reducers: {
    update(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
    clear() {
      return {};
    },
  },
  extraReducers(builder) {
    builder.addCase(CREATE_COLLECTION_VIEW, (state, { payload }) => {
      state[payload.collectionView.uuid] = payload.collectionView;
    });
    builder.addCase(UPDATE_COLLECTION_VIEW, (state, { payload }) => {
      const view = state[payload.uuid];
      if (view) {
        if (payload.patch.format) {
          Object.assign(view.format, payload.patch.format);
        }

        const args = omit(payload.patch, 'format');
        if (Object.keys(args).length > 0) {
          Object.assign(view, args);
        }
      }
    });
    builder.addCase(LIST_REMOVE_COLLECTION_VIEW_PAGESORT, (state, { payload }) => {
      const { uuid, viewId } = payload;
      const view = state[viewId];
      if (!view) return;
      view.pageSort = view.pageSort.filter((item) => item !== uuid);
    });
    builder.addCase(LIST_BEFORE_COLLECTION_VIEW_PAGESORT, (state, { payload }) => {
      const { uuid, viewId, before } = payload;
      const view = state[viewId];
      if (!view) return;

      if (before) {
        const i = view.pageSort.indexOf(before);
        if (i < 0) {
          view.pageSort.unshift(uuid);
        } else {
          view.pageSort.splice(i, 0, uuid);
        }
      } else {
        view.pageSort.unshift(uuid);
      }
    });
    builder.addCase(LIST_AFTER_COLLECTION_VIEW_PAGESORT, (state, { payload }) => {
      const { uuid, after, viewId } = payload;
      const view = state[viewId];
      if (!view) return;

      if (after) {
        const i = view.pageSort.indexOf(after);
        if (i < 0) {
          view.pageSort.push(uuid);
        } else {
          view.pageSort.splice(i + 1, 0, uuid);
        }
      } else {
        view.pageSort.push(uuid);
      }
    });
  },
});

export const collectionViewsReducer = collectionViewsSlice.reducer;
export const collectionViewsActions = collectionViewsSlice.actions;
