/**
 * Blocks
 */
import { BlockStatus, BlockType, PermissionType } from '@next-space/fe-api-idl';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { assign, get, set } from 'lodash-es';
import {
  CREATE_BLOCK,
  DESTROY_BLOCK,
  LIST_AFTER_BLOCK,
  LIST_BEFORE_BLOCK,
  LIST_REMOVE_BLOCK,
  REMOVE_BLOCK_PERMISSION,
  SET_BLOCK_PERMISSION,
  UPDATE_BLOCK,
} from '../actions';
import {
  LIST_AFTER_COLLECTION_VIEW,
  LIST_BEFORE_COLLECTION_VIEW,
  LIST_REMOVE_COLLECTION_VIEW,
} from '../actions/collection-view';
import { LIST_AFTER_DISCUSSION, LIST_REMOVE_DISCUSSION } from '../actions/discussion';
import type { NextBlock } from '../types';

type State = Record<string, NextBlock>;

const initialState: State = {};

export const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    update(state, action: PayloadAction<{ blocks: Record<string, NextBlock>; force?: boolean }>) {
      for (const blockId of Object.keys(action.payload.blocks ?? {})) {
        const oldBlock = state[blockId];
        const newBlock0 = action.payload.blocks?.[blockId];
        if (!newBlock0) continue;
        const newBlock = { ...newBlock0, data: { ...newBlock0.data } };

        // 防止远端旧数据覆盖本地新数据
        if (!action.payload.force && oldBlock != null && newBlock.version <= oldBlock.version) {
          continue;
        }
        // 每次更新map的时候，需要把本地的数据赋值给新的
        if (oldBlock?.type !== BlockType.PDF_ANNOTATION) {
          newBlock.data.localUrl = oldBlock?.data.localUrl;
        }
        newBlock.data.iconUrl = oldBlock?.data.iconUrl;
        newBlock.data.showEmptyCaption = oldBlock?.data.showEmptyCaption;
        state[blockId] = newBlock;
      }
    },
    clear() {
      return {};
    },
    incVersion(state, action: PayloadAction<string[]>) {
      action.payload.forEach((id) => {
        const block = state[id];
        // version: -1 表示此块需要等到服务器copy完加载之后才能更新version
        if (block && block.version !== -1) {
          block.version += 1;
        }
      });
    },
  },
  extraReducers(builder) {
    builder.addCase(CREATE_BLOCK, (state, { payload }) => {
      // 本地创建这两类block不需要再次fetchPage一下
      if (payload.block.type === BlockType.PAGE || payload.block.type === BlockType.FOLDER) {
        payload.block = {
          ...payload.block,
          local: payload.local ?? true,
        };
      }

      state[payload.block.uuid] = payload.block;
    });

    builder.addCase(UPDATE_BLOCK, (state, { payload }) => {
      const { uuid, patch, path } = payload;

      const block = state[uuid];
      if (!block) return;

      delete block.local;

      if (path) {
        const value = get(patch, path);
        set(block, path, assign(get(block, path), value));
        return;
      }

      const { data = {}, ...props } = patch;
      const { format: _format, ...rest } = data;

      assign(block, props);
      assign(block.data, rest);
      if (!block.data.format) {
        block.data.format = {};
      }
      assign(block.data.format, data?.format);
    });

    builder.addCase(LIST_BEFORE_BLOCK, (state, { payload }) => {
      const { uuid, parentId: target, before, ignoreUpdateTime } = payload;

      const container = state[target];
      const block = state[uuid];
      if (!block) return;

      // 修改节点的 parentId
      block.parentId = target;
      block.status = BlockStatus.NORMAL;
      if (!ignoreUpdateTime) {
        block.updatedAt = Date.now();
      }

      if (!container) return;

      if (before) {
        const i = container.subNodes.indexOf(before);
        if (i < 0) {
          container.subNodes.unshift(uuid);
        } else {
          container.subNodes.splice(i, 0, uuid);
        }
      } else {
        container.subNodes.unshift(uuid);
      }
    });

    builder.addCase(LIST_AFTER_BLOCK, (state, { payload }) => {
      const { uuid, parentId: target, after, ignoreUpdateTime } = payload;

      const container = state[target];
      const block = state[uuid];
      if (!block) return;

      // 修改节点的 parentId
      block.parentId = target;
      block.status = BlockStatus.NORMAL;
      if (!ignoreUpdateTime) {
        block.updatedAt = Date.now();
      }

      if (!container) return;

      if (after) {
        const i = container.subNodes.indexOf(after);
        if (i < 0) {
          container.subNodes.push(uuid);
        } else {
          container.subNodes.splice(i + 1, 0, uuid);
        }
      } else {
        container.subNodes.push(uuid);
      }
    });

    builder.addCase(LIST_REMOVE_BLOCK, (state, { payload }) => {
      const { uuid } = payload;

      const block = state[uuid];
      if (!block) return;
      const container = state[block.parentId];
      if (!container) return;
      container.subNodes = container.subNodes.filter((item) => item !== uuid);
    });

    builder.addCase(DESTROY_BLOCK, (state, { payload }) => {
      const { uuid } = payload;

      delete state[uuid];
    });

    builder.addCase(SET_BLOCK_PERMISSION, (state, { payload }) => {
      const { uuid, permission } = payload;

      const block = state[uuid];
      if (!block) return;

      if (
        permission.type === PermissionType.PUBLIC ||
        permission.type === PermissionType.RESTRICTED
      ) {
        block.permissions = block.permissions.filter((o) => o.type !== permission.type);
        block.permissions.push(permission);
      }

      if (permission.type === PermissionType.SPACE) {
        if (block.permissions.some((o) => o.type === PermissionType.SPACE)) {
          block.permissions = block.permissions.map((o) =>
            o.type === PermissionType.SPACE ? permission : o
          );
        } else {
          block.permissions.push(permission);
        }
      }

      if (permission.type === PermissionType.GROUP) {
        if (block.permissions.some((o) => o.groupId === permission.groupId)) {
          block.permissions = block.permissions.map((o) =>
            o.groupId === permission.groupId ? permission : o
          );
        } else {
          block.permissions.push(permission);
        }
      }

      if (permission.type === PermissionType.USER) {
        if (block.permissions.some((o) => o.userId === permission.userId)) {
          block.permissions = block.permissions.map((o) =>
            o.userId === permission.userId ? permission : o
          );
        } else {
          block.permissions.push(permission);
        }
      }
    });

    builder.addCase(REMOVE_BLOCK_PERMISSION, (state, { payload }) => {
      const { uuid, permission } = payload;

      const block = state[uuid];
      if (!block) return;

      if (
        permission.type === PermissionType.SPACE ||
        permission.type === PermissionType.PUBLIC ||
        permission.type === PermissionType.RESTRICTED
      ) {
        block.permissions = block.permissions.filter((o) => o.type !== permission.type);
      }

      if (permission.type === PermissionType.GROUP) {
        block.permissions = block.permissions.filter((o) => o.groupId !== permission.groupId);
      }

      if (permission.type === PermissionType.USER) {
        block.permissions = block.permissions.filter((o) => o.userId !== permission.userId);
      }
    });

    builder.addCase(LIST_AFTER_COLLECTION_VIEW, (state, { payload }) => {
      const { uuid, after, parentId } = payload;

      const block = state[parentId];
      if (!block) return;

      if (!block.views) {
        block.views = [uuid];
      } else {
        if (after) {
          const i = block.views.indexOf(after);
          if (i < 0) {
            block.views.push(uuid);
          } else {
            block.views.splice(i + 1, 0, uuid);
          }
        } else {
          block.views.push(uuid);
        }
      }
    });

    builder.addCase(LIST_BEFORE_COLLECTION_VIEW, (state, { payload }) => {
      const { uuid, before, parentId } = payload;

      const block = state[parentId];
      if (!block) return;

      if (!block.views) {
        block.views = [uuid];
      }
      if (before) {
        const i = block.views.indexOf(before);
        if (i < 0) {
          block.views.push(uuid);
        } else {
          block.views.splice(i, 0, uuid);
        }
      } else {
        block.views.unshift(uuid);
      }
    });

    builder.addCase(LIST_REMOVE_COLLECTION_VIEW, (state, { payload }) => {
      const { uuid, parentId } = payload;

      const block = state[parentId];
      if (!block) return;
      block.views = block.views?.filter((item) => item !== uuid);
    });

    builder.addCase(LIST_AFTER_DISCUSSION, (state, { payload }) => {
      const { uuid, after, parentId } = payload;

      const block = state[parentId];
      if (!block) return;

      if (!block.discussions) {
        block.discussions = [uuid];
      } else {
        if (after) {
          const i = block.discussions.indexOf(after);
          if (i < 0) {
            block.discussions.push(uuid);
          } else {
            block.discussions.splice(i + 1, 0, uuid);
          }
        } else {
          block.discussions.push(uuid);
        }
      }
    });

    builder.addCase(LIST_REMOVE_DISCUSSION, (state, { payload }) => {
      const { uuid, parentId } = payload;

      const block = state[parentId];
      if (!block) return;
      block.discussions = block.discussions?.filter((item) => item !== uuid);
    });
  },
});

export const blocksReducer = blocksSlice.reducer;
export const blocksActions = blocksSlice.actions;
