import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent, ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useTocListOnSelect } from 'src/components/move-to/toc-list';
import { useCreateDocPage } from 'src/hooks/page';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { bizTracker } from 'src/utils/biz-tracker';
import { setLocalStorage } from 'src/utils/local-storage';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { PREFIX_TOC_GROUP_HIDDEN, TocType, TocTypeName } from './types';

interface Props {
  title: string;
  showCreateButton?: boolean;
  type?: TocType;
  id?: string;
  tooltipDesc?: string;
  addonAfter?: ReactNode;
}

export const TocGroupHeader: FC<Props> = ({
  id,
  title,
  children,
  type = TocType.PRIVATE,
  showCreateButton = true,
  tooltipDesc,
  addonAfter,
}) => {
  const currentSpaceId = getCurrentSpaceId();
  const isInMoveUi = !!useTocListOnSelect();
  const key = `${PREFIX_TOC_GROUP_HIDDEN}:${currentSpaceId}:${type}`;
  const [hideGroup, setHideGroup] = useState<boolean>(() => {
    // 本地保留展开还是收起
    let hide = false;
    if (isInMoveUi) return hide; // 移动到不用记这个状态
    try {
      const item = localStorage.getItem(key);
      if (item) {
        hide = JSON.parse(item);
      }
    } catch {
      //
    }
    return hide;
  });
  const createPage = useCreateDocPage();
  const history = useHistory();

  const createToc = (event: MouseEvent) => {
    event.stopPropagation();
    if (type === TocType.PRIVATE || type === TocType.SPACE) {
      if (hideGroup) {
        setHideGroup(false);
      }
      bizTracker.event('page_new', { group_category: type });
      const id = createPage(BlockType.PAGE, { parentId: currentSpaceId, first: true }, type);
      history.push(`/${id}`);
    }
  };

  // const

  return (
    <div id={id}>
      <div className="z-10 sticky bg-aside flex items-center justify-between top-0 py-0.5 text-t4-medium text-grey3">
        <Tooltip
          offset={[2, 4]}
          placement="right"
          maxWidth={500}
          popup={<Popup title={hideGroup ? '点击展开' : '点击收起'} text={tooltipDesc} />}
        >
          <div
            className="px-1.5 ml-3 rounded-sm cursor-pointer animate-hover"
            onClick={() => {
              if (!isInMoveUi) {
                setLocalStorage(key, JSON.stringify(!hideGroup));
              }
              setHideGroup(!hideGroup);
            }}
          >
            {title}
          </div>
        </Tooltip>

        <div>
          {(type === TocType.PRIVATE || type === TocType.SPACE) && showCreateButton && (
            <>
              <Tooltip
                popup={<Popup title="创建新页面" text={tooltipDesc} />}
                placement="right"
                className={cx(
                  'transition-all flex items-center pr-3 opacity-0 add-page',
                  TocTypeName[type]
                )}
              >
                <button
                  {...bindDataTestId(
                    type === TocType.PRIVATE
                      ? TestIds.asideTocHeaderCreateByPrivate
                      : TestIds.asideTocHeaderCreateBySpace
                  )}
                  onClick={createToc}
                  className="rounded-sm cursor-pointer animate-hover"
                >
                  <Icon size="small" name="IcAdd" className="block" />
                </button>
              </Tooltip>
            </>
          )}
          {addonAfter}
        </div>
      </div>

      <TocGroupContext.Provider value={type}>
        {!hideGroup && <>{children}</>}
      </TocGroupContext.Provider>
    </div>
  );
};

const Popup: FC<{ title: string; text?: string }> = ({ title, text }) => (
  <div className="p-1">
    <div>{title}</div>
    {text && <div className="text-gray-300">{text}</div>}
  </div>
);

const TocGroupContext = createContext<TocType>(TocType.PRIVATE);
export const useTocGroupType = () => useContext(TocGroupContext);
