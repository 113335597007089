import { BlockType, CollectionViewType } from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import type { FC } from 'react';
import dayjs from 'dayjs';
import { Icon } from 'src/common/components/icon';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { segmentsToText } from 'src/editor/utils/editor';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getTablePropertyValue } from 'src/hooks/collection-view/get-property-value';
import { useOpenInRight } from 'src/hooks/page/use-open-in-right';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useBitable } from '../context';
import { ViewIconMap } from './const';

export const useNoDateRecord = () => {
  const { viewId, collectionId } = useBitable();
  const noDatesCard = useObservableStore(
    ({ blocks, collectionViews }) => {
      const view = collectionViews[viewId];
      if (!view || view.type !== CollectionViewType.CALENDAR) return;
      const subNodes = blocks[collectionId]?.subNodes;
      if (!subNodes) return;

      return subNodes.filter((recordId: string) => {
        const block = blocks[recordId];
        if (block?.type !== BlockType.PAGE) return false;

        const calendarBy = view.format.calendarBy ?? '';
        const calendarByEnd = view.format.calendarByEnd ?? '';

        const rawStartTime = getTablePropertyValue(block, calendarBy);
        const rawEndTime = getTablePropertyValue(block, calendarByEnd);

        return !rawStartTime && !rawEndTime;
      });
    },
    [viewId, collectionId]
  );

  return noDatesCard;
};

export const NoDateRecords: FC<{
  closeModal: () => void;
  popper?: React.MutableRefObject<Instance | null>;
}> = ({ closeModal, popper }) => {
  const { viewId } = useBitable();
  const transaction = useTransaction();
  const noDateCard = useNoDateRecord();
  const openInRight = useOpenInRight();

  const items = (() => {
    if (!noDateCard) return [];
    const { blocks } = getState();
    return noDateCard.map((uuid) => {
      const block = blocks[uuid];
      return {
        type: ListItemType.SEARCH,
        data: {
          uuid,
          title: segmentsToText(block?.data.segments) || getUntitledName(BlockType.PAGE),
          icon: block?.data.icon,
          type: BlockType.PAGE,
          renderRight: () => {
            return (
              <div
                className="text-t2 animate-hover p-1"
                onClick={(event) => {
                  event.stopPropagation();
                  openInRight(uuid);
                  const timer = setTimeout(() => {
                    // TODO： 用 eventemmiter 监听右边栏打开后执行
                    popper?.current?.forceUpdate();
                    clearTimeout(timer);
                  }, 300);
                }}
              >
                查看
              </div>
            );
          },
        },
      };
    });
  })();

  const handleClick = (item: ListItem<any>) => {
    const { uuid } = item.data;
    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;
    const { calendarBy, calendarByEnd } = viewInfo;
    if (!calendarBy || !calendarByEnd) return;

    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    if (noDateCard?.length === 1) {
      closeModal();
    }
    const timer = setTimeout(() => {
      clearTimeout(timer);
      transaction(() => {
        updateBlock(uuid, {
          data: {
            collectionProperties: {
              ...block.data.collectionProperties,
              [calendarBy]: [buildDateSegment({ from: new Date() })],
              [calendarByEnd]: [buildDateSegment({ from: new Date() })],
            },
          },
        });
      });
    }, 300);
  };

  const today = new Date();
  return (
    <div className="w-[400px] next-modal py-[5px]">
      <div className="h-10 px-4 flex justify-between items-center">
        <div className="flex items-center text-t2">
          <Icon className="mr-1" name={ViewIconMap[CollectionViewType.CALENDAR]} size="middle" />
          <span className="mr-2">{noDateCard?.length ?? 0} 条记录没有填写日期时间</span>
        </div>
      </div>
      <div className="h-10 px-4 flex items-center text-grey3 text-sm">
        {'点击将以下记录添加到日历'} ({dayjs(today).format('MM月DD日')})
      </div>

      <ListView items={items} onItemClick={(item) => handleClick(item)} />
    </div>
  );
};
