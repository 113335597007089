import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import { diff_match_patch } from 'diff-match-patch';
import type { FC } from 'react';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';

const dmp = new diff_match_patch();

function concatSegments(segmentsA: SegmentDTO[] | undefined, segmentsB: SegmentDTO[] | undefined) {
  if (segmentsA === undefined) return segmentsB;
  if (segmentsB === undefined) return segmentsA;
  // FIXME: Too naive
  // TODO: coalesce
  return [...segmentsA, ...segmentsB];
}

export function diffSegments(
  segmentsA: SegmentDTO[] | undefined,
  segmentsB: SegmentDTO[] | undefined
) {
  const markInserted = (segments: SegmentDTO[] | undefined) => {
    if (segments === undefined) return undefined;
    return segments.map((it) => {
      return {
        ...it,
        enhancer: {
          ...it.enhancer,
          backgroundColor: 'var(--active_color-10)',
          textColor: 'var(--active_color-80)',
        },
      };
    });
  };
  const markDeleted = (segments: SegmentDTO[] | undefined) => {
    if (segments === undefined) return undefined;
    return segments.map((it) => {
      return { ...it, enhancer: { ...it.enhancer, lineThrough: true, textColor: '#B3B3B3' } };
    });
  };
  const convertSegmentsToText = (segments: SegmentDTO[] | undefined) => {
    return (segments ?? []).reduce((result, segment) => {
      if (segment.type === TextType.TEXT) {
        result += segment.text;
      } else {
        result += ' ';
      }
      return result;
    }, '');
  };
  const convertTextToSegments = (
    text: string,
    baseSegments: SegmentDTO[] | undefined,
    index: number
  ): SegmentDTO[] | undefined => {
    const result: SegmentDTO[] = [];
    let cursor = 0;
    const textStart = index;
    const textEnd = index + text.length;
    for (const segment of baseSegments ?? []) {
      if (segment.type === TextType.TEXT) {
        const segmentStart = cursor;
        const segmentEnd = cursor + segment.text.length;
        cursor = segmentEnd;
        const intersectionStart = Math.max(segmentStart, textStart);
        const intersectionEnd = Math.min(segmentEnd, textEnd);
        if (intersectionEnd - intersectionStart > 0) {
          result.push({
            ...segment,
            text: text.slice(intersectionStart - textStart, intersectionEnd - textStart),
          });
        }
      } else {
        const segmentStart = cursor;
        const segmentEnd = cursor + 1;
        cursor = segmentEnd;
        const intersectionStart = Math.max(segmentStart, textStart);
        const intersectionEnd = Math.min(segmentEnd, textEnd);
        if (intersectionEnd - intersectionStart > 0) {
          result.push(segment);
        }
      }
    }
    return result;
  };
  const textA = convertSegmentsToText(segmentsA);
  const textB = convertSegmentsToText(segmentsB);
  let resultSegments: SegmentDTO[] | undefined;
  try {
    const diff = dmp.diff_main(textA, textB);
    let indexA = 0;
    let indexB = 0;
    for (const edit of diff) {
      const [type, text] = edit;
      if (type < 0) {
        let segments = convertTextToSegments(text, segmentsA, indexA);
        segments = markDeleted(segments);
        resultSegments = concatSegments(resultSegments, segments);
        indexA += text.length;
      } else if (type > 0) {
        let segments = convertTextToSegments(text, segmentsB, indexB);
        segments = markInserted(segments);
        resultSegments = concatSegments(resultSegments, segments);
        indexB += text.length;
      } else {
        const segments = convertTextToSegments(text, segmentsB, indexB);
        resultSegments = concatSegments(resultSegments, segments);
        indexA += text.length;
        indexB += text.length;
      }
    }
  } catch {
    //
  }
  return resultSegments;
}

export const RichTextDiff: FC<{
  className?: string;
  isTitle?: boolean;
  segmentsA: SegmentDTO[] | undefined;
  segmentsB: SegmentDTO[] | undefined;
}> = ({ className, isTitle = false, segmentsA, segmentsB }) => {
  const segments = diffSegments(segmentsA, segmentsB);
  return (
    <RichText
      className={cx('leading-[20px] text-t2', className)}
      plugins={isTitle ? TITLE_EDITOR_PLUGINS : undefined}
      segments={segments}
      interactable={false}
    />
  );
};
