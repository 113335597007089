import type { FC } from 'react';

import type { ElementItemProps } from '../types';

/**
 * 页面移动菜单item
 */
interface LineDataScheme {
  color?: string;
  padding?: number; // 这些属性暂时用不上，需要的时候再补上
}
export const LineItemHeight = 5;

export const LineItem: FC<ElementItemProps<LineDataScheme>> = (_) => {
  return (
    <div className="flex items-center mx-4 my-1">
      <div className="w-full h-px bg-grey6" />
    </div>
  );
};
