/**
 * 可以清除的内容：
 * viewId: 多维表页面当前视图
 * inline-bitable: 内嵌多维表当前视图  => 现在已经替换为 viewId: 页面形式和内嵌形式同步视图展示
 * viewId:[uuid];groupProperty:[propertyId];groupName:[groupName]: 多维表分组展开隐藏
 * expandFoldList: 折叠列表
 * LRU::oss_images: 模板图片
 * LRU::linkInfo
 */

import localforage from 'localforage';
import type { JsonValue } from 'type-fest';
import type { StateStorage } from 'zustand/middleware';

let recursionCount = 0;
const recursionCountLimit = 10;

const RemainKeys = ['userId', 'lastViewPage', 'currentSpaceViewId'];

export const setLocalStorage = (
  key: string,
  value: string,
  storage: LocalForage | Storage = localStorage
) => {
  recursionCount = 0;
  void setItem(key, value, storage);
};

const setItem = async (
  key: string,
  value: string,
  storage: LocalForage | Storage = localStorage
) => {
  try {
    await storage.setItem(key, value);
  } catch (error) {
    if (!(error instanceof Error)) return;
    if (!error.message.toLowerCase().includes('quota')) return;

    if (recursionCount > recursionCountLimit) {
      const remainItems: Record<string, string> = {};

      RemainKeys.forEach(async (key) => {
        const value = (await storage.getItem(key)) as string;
        if (value) {
          remainItems[key] = value;
        }
      });

      await storage.clear();

      Object.entries(remainItems).forEach(([key, value]) => {
        void storage.setItem(key, value);
      });

      location.reload();

      return;
    }

    recursionCount++;

    if (recursionCount === 0) {
      purgeManaged('expired');
    } else if (recursionCount >= 1) {
      purgeManaged('safe-all');
    }

    clear();

    void setItem(key, value, storage);
  }
};

const clear = (length = 10, storage = localStorage) => {
  let count = 0;
  for (let i = 0; i < storage.length; i++) {
    if (count >= length) return;

    const keyValue = storage.key(i);
    if (!keyValue) continue;

    if (
      keyValue.startsWith('viewId:') ||
      keyValue.startsWith('inline-bitable:') ||
      keyValue.startsWith('LRU::')
    ) {
      storage.removeItem(keyValue);
      count++;
    }
  }
};

const purgeManaged = (type: 'expired' | 'safe-all' | 'all' = 'safe-all') => {
  const toPurge = new Set<string>();
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key != null && key.startsWith('Managed:')) {
      const itemStr = localStorage.getItem(key);
      if (type === 'all' || itemStr == null) {
        toPurge.add(key);
      } else {
        try {
          const item = JSON.parse(itemStr);
          const { expiredAt, safeToPurge } = item;
          if (
            (type === 'safe-all' && safeToPurge) ||
            (type === 'expired' && Date.now() >= expiredAt)
          ) {
            toPurge.add(key);
          }
        } catch {
          toPurge.add(key);
        }
      }
    }
  }
  for (const key of toPurge) {
    localStorage.removeItem(key);
  }
};

export const getManaged = <T extends JsonValue>(key: string): T | undefined => {
  const managedKey = `Managed:${key}`;
  const itemStr = localStorage.getItem(managedKey);
  if (itemStr == null) return undefined;
  try {
    const item = JSON.parse(itemStr);
    const { data, expiredAt } = item;
    if (Date.now() >= expiredAt) {
      localStorage.removeItem(managedKey);
      return undefined;
    }
    return data as T;
  } catch {
    return undefined;
  }
};

export const removeManaged = (key: string) => {
  const managedKey = `Managed:${key}`;
  localStorage.removeItem(managedKey);
};

export const setManaged = (
  key: string,
  data: JsonValue,
  options?: { expiredAt?: number; safeToPurge?: boolean }
) => {
  const expiredAt = options?.expiredAt ?? null;
  const safeToPurge = options?.safeToPurge ?? true;
  const managedKey = `Managed:${key}`;
  const item = { data, expiredAt, safeToPurge };
  const itemStr = JSON.stringify(item);
  try {
    if (safeToPurge) {
      localStorage.setItem(managedKey, itemStr);
    } else {
      setLocalStorage(managedKey, itemStr);
    }
    return true;
  } catch {
    return false;
  }
};

export const getLocalObject = (key: string, isArray = false) => {
  try {
    return JSON.parse(localStorage.getItem(key) ?? (isArray ? '[]' : '{}'));
  } catch {
    return isArray ? [] : {};
  }
};

export const createLocalforageStorage = () => {
  const storage: StateStorage & {
    getAllKeys: () => Promise<string[]>;
  } = {
    getItem: async (name: string) => {
      const value: string | null = (await localforage.getItem(name)) || null;
      return value;
    },
    setItem: async (name: string, value: string) => {
      await localforage.setItem(name, value);
    },
    removeItem: async (name: string) => {
      await localforage.removeItem(name);
    },
    getAllKeys: async () => {
      const keys = await localforage.keys();
      return keys;
    },
  };
  return storage;
};
