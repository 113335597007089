/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockStatus, BlockType, TextType } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import * as _ from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { convertContentToSegments, discussionsOfSegments } from 'src/editor/utils/segments';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS, UPDATE_BLOCK } from 'src/redux/actions';
import { LIST_AFTER_DISCUSSION, LIST_REMOVE_DISCUSSION } from 'src/redux/actions/discussion';
import { updateBlock } from 'src/redux/managers/block/update';
import { historyVersion } from 'src/redux/middlewares/op-history';
import { dispatch, getState } from 'src/redux/store';
import type { NextBlock, SegmentType } from 'src/redux/types';

type Segments = NextBlock['data']['segments'];

const segmentsToOffset = new Map<string, number>();

export const clearSegmentOffset = () => {
  segmentsToOffset.clear();
};

export const useThrottleUpdateSegments = (uuid: string, type?: SegmentType) => {
  const getOrInitEditorModel = useGetOrInitEditorModel();
  const focusEditableAt = useFocusEditableByBlockId();
  const segmentType = type ?? 'segments';
  const transaction = useTransaction();
  const segmentsGetter = useCallback(
    function* (block: NextBlock) {
      yield segmentType === 'caption'
        ? block.data.caption
        : segmentType === 'description'
        ? block.data.description
        : block.data.segments;
    },
    [segmentType]
  );

  const prevStateRef = useRef<{
    segments: Segments;
    offset: number;
  }>();

  const historyVersionRef = useRef(historyVersion);

  const sync = useMemo(() => {
    return _.throttle(
      (key: string) => {
        const prevState = prevStateRef.current;
        const currBlock = getState().blocks[uuid];

        if (!prevState || !currBlock) return;

        if (historyVersionRef.current < historyVersion) {
          historyVersionRef.current = historyVersion;
          return;
        }

        const currSegments = currBlock.data[segmentType] ?? [];

        const currOffset = segmentsToOffset.get(key) ?? prevState.offset;

        const diffInlinePages = collectDiffInlinePages(
          uuid,
          currSegments,
          prevState.segments ?? []
        );

        transaction(() => {
          dispatch(
            HISTORY_EFFECTS({
              init() {
                updateBlock(uuid, {
                  data: { [segmentType]: currSegments },
                });
                diffInlinePages.forEach((id) => {
                  changeBlockStatus(id, BlockStatus.DELETED);
                });
                syncDiscussions(uuid, segmentsGetter);
              },
              redo() {
                updateBlock(uuid, {
                  data: { [segmentType]: currSegments },
                });
                diffInlinePages.forEach((id) => {
                  changeBlockStatus(id, BlockStatus.DELETED);
                });
                syncDiscussions(uuid, segmentsGetter);
                focusEditableAt(uuid, currOffset, type);
              },
              undo() {
                updateBlock(uuid, {
                  data: { [segmentType]: prevState.segments },
                });
                diffInlinePages.forEach((id) => {
                  changeBlockStatus(id, BlockStatus.NORMAL);
                });
                syncDiscussions(uuid, segmentsGetter);
                focusEditableAt(uuid, prevState.offset, type);
              },
            })
          );
        });

        prevStateRef.current = undefined;
      },
      500,
      { leading: false }
    );
  }, [focusEditableAt, segmentsGetter, segmentType, transaction, type, uuid]);

  const handleChangeSegments = useMemo(() => {
    return (segments: Segments, previousSnapshot?: [IContent, ISelection | null]) => {
      const isCodeBlock =
        segmentType === 'segments' && getState().blocks[uuid]?.type === BlockType.CODE;
      const editorModel = getOrInitEditorModel(isCodeBlock ? `${uuid}_mock` : uuid, true);
      const key = JSON.stringify({ segments, uuid, time: Date.now() });

      historyVersionRef.current = historyVersion;

      if (!prevStateRef.current) {
        const block = getState().blocks[uuid];
        if (!block) return;

        if (previousSnapshot) {
          const [previousContent, previousSelection] = previousSnapshot;
          prevStateRef.current = {
            segments: convertContentToSegments(previousContent),
            offset: previousSelection?.offset ?? 0,
          };
        } else {
          const segments = block.data[segmentType];
          prevStateRef.current = segments
            ? {
                segments,
                offset: block.data[segmentType] ? segmentsToOffset.get(key) ?? 0 : 0,
              }
            : {
                segments: undefined,
                offset: 0,
              };
        }
      }
      const block = getState().blocks[uuid]!;
      const temp = block.data.isByAI
        ? { isByAI: segmentsToText(segments).trim().length === 0 ? false : true }
        : {};
      dispatch(
        UPDATE_BLOCK({
          ignoreOp: true,
          uuid,
          patch: { data: { [segmentType]: segments, ...temp } },
        })
      );

      syncDiscussions(uuid, segmentsGetter, transaction);

      segmentsToOffset.set(key, editorModel.selection?.offset ?? 0);

      sync(key);
    };
  }, [getOrInitEditorModel, segmentType, segmentsGetter, sync, transaction, uuid]);

  return handleChangeSegments;
};

export const syncDiscussions = (
  blockId: string,
  segmentsGetter: (block: NextBlock) => Generator<SegmentDTO[] | undefined>,
  transaction?: ReturnType<typeof useTransaction>
) => {
  const currBlock = getState().blocks[blockId];
  if (currBlock == null) return;
  const oldDiscussions = (currBlock.discussions ?? []).filter((it) => {
    const discussion = getState().discussions[it];
    return segmentsToText(discussion?.context) !== '';
  });
  const newDiscussions = [];
  for (const segments of segmentsGetter(currBlock)) {
    newDiscussions.push(...discussionsOfSegments(segments));
  }
  const removed = _.difference(oldDiscussions, newDiscussions);
  const added = _.difference(newDiscussions, oldDiscussions);
  const sync = () => {
    if (removed.length > 0) {
      for (const discussionId of removed) {
        dispatch(
          LIST_REMOVE_DISCUSSION({
            uuid: discussionId,
            parentId: blockId,
            spaceId: currBlock.spaceId,
          })
        );
      }
    }
    if (added.length > 0) {
      for (const discussionId of added) {
        dispatch(LIST_AFTER_DISCUSSION({ uuid: discussionId, parentId: blockId }));
      }
    }
  };
  if (removed.length > 0 || added.length > 0) {
    if (transaction) {
      transaction(sync);
    } else {
      sync();
    }
  }
};

const collectDiffInlinePages = (uuid: string, curr: SegmentDTO[], old: SegmentDTO[]) => {
  const getInlinePages = (segments: SegmentDTO[]) => {
    return new Set(
      segments
        .map((segment) => {
          if (segment.type === TextType.LINK_PAGE && segment.uuid) {
            const block = getState().blocks[segment.uuid];
            if (block && block.parentId === uuid) {
              return segment.uuid;
            }
          }
          return null;
        })
        .filter(Boolean) as string[]
    );
  };

  const oldSet = getInlinePages(old);
  const currSet = getInlinePages(curr);
  const diff = [];

  for (const id of oldSet) {
    if (!currSet.has(id)) {
      diff.push(id);
    }
  }

  return diff;
};

const changeBlockStatus = (uuid: string, status: BlockStatus) => {
  dispatch(
    UPDATE_BLOCK({
      uuid,
      patch: {
        status,
      },
    })
  );
};
