import isHotkey from 'is-hotkey';
import { useEffect } from 'react';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { $appUiStateCache, setAppUiState } from 'src/services/app';

export const useListenerPageKeydown = () => {
  useEffect(() => {
    let timer = setTimeout(() => {}, 0);

    const clearTimer = () => {
      setAppUiState({ $isCtrlKeyDown: false });
      clearTimeout(timer);
    };

    const handleKeyup = (event?: Event) => {
      event?.preventDefault();
      clearTimer();
      globalListenerHelper.removeEventListener('keyup', handleKeyup);
    };

    const handleKeydown = (event: KeyboardEvent) => {
      // 处理 Ctrl 键
      if (isHotkey(['mod', 'ctrl'], { byKey: true })(event)) {
        if ($appUiStateCache.$isCtrlKeyDown) return;
        clearTimer();
        timer = setTimeout(() => {
          event.preventDefault();
          setAppUiState({ $isCtrlKeyDown: true });
        }, 300);
        globalListenerHelper.addEventListener('keyup', handleKeyup);
      } else {
        clearTimer();
        handleKeyup();
      }
    };

    globalListenerHelper.addEventListener('keydown', handleKeydown);
    globalListenerHelper.addEventListener('blur', handleKeyup);

    return () => {
      clearTimeout(timer);
      setAppUiState({ $isCtrlKeyDown: false });
      globalListenerHelper.removeEventListener('keydown', handleKeydown);
      globalListenerHelper.removeEventListener('keyup', handleKeyup);
      globalListenerHelper.removeEventListener('blur', handleKeyup);
    };
  }, []);
};
