import fastJsonStableStringify from 'fast-json-stable-stringify';
import { OPEN_DEBUG, OPEN_RX } from 'src/feature-flags_';
import { PageScene, useOpenRx, usePageScene } from 'src/views/main/scene-context';

export const useIsEnableRedux = () => {
  const pageScene = usePageScene();
  const openRx = useOpenRx();

  const isUseRedux =
    pageScene &&
    [PageScene.PAGE_HISTORY, PageScene.PAGE_FEEDS, PageScene.PAGE_CHART].includes(pageScene);

  // 暂时拿掉，看表现情况
  // PageScene.PAGE_CHART

  // 以后可能会有更多case，所以不合并判断

  // 禁用了，就不用考虑。直接关
  if (!OPEN_RX) {
    return true;
  }

  // 某些情况下必须得使用 redux
  if (isUseRedux) {
    return true;
  }

  // 如果 context 使用了 redux。或者用户主动开启了 beta 模式。就走 rx
  if (openRx) {
    return false;
  }

  // 默认情况下不走rx
  return true;
};

export const debugSelector = (selector?: Function, other?: any) => {
  if (OPEN_DEBUG) {
    const cacheKey = `${other ? fastJsonStableStringify(other) : ''}${selector}`;
    // @ts-ignore debug
    const debugMap: any = window?.debug?.map
      ? // @ts-ignore debug
        new Map(window.debug.map)
      : new Map<string, number>();
    if (debugMap.has(cacheKey)) {
      debugMap.set(cacheKey, debugMap.get(cacheKey)! + 1);
    } else {
      debugMap.set(cacheKey, 1);
    }
    // @ts-ignore debug
    window.debug = {
      map: [...debugMap].sort((a, b) => b[1] - a[1]),
      debugMapTotal: [...debugMap].reduce((cur, pre) => (cur += pre[1]), 0),
    };
  }
};

if (OPEN_DEBUG) {
  setInterval(() => {
    // @ts-ignore debug
    console.log(window.debug);
  }, 1000);

  // @ts-ignore debug
  window.cleanup = () => {
    // @ts-ignore debug
    window.debug = {
      map: [],
      debugMapTotal: 0,
    };
  };
}
