import html2canvas from '@flowus/html2canvas';
import type { ExportOption } from './types';

const WATER_MARK_HEIGHT = 60;

// /** 这个方法是思维导图专用，如果其他页面要用一定要再写一份 */
// export const captureSnapshot = async (container: HTMLElement, option: ExportOption) => {
//   if (option.type === 'png') {
//     if (container.firstChild instanceof HTMLElement) {
//       const containerChildRect = container.firstChild.getBoundingClientRect();
//       const containerRect = container.getBoundingClientRect();
//       const nodeDrawer = container.querySelector('.node-drawer');
//       if (!nodeDrawer) return;
//       const nodeDrawerRect = nodeDrawer.getBoundingClientRect();
//       const mindMapMaxWidth =
//         containerRect.width > nodeDrawerRect.width ? containerRect.width : nodeDrawerRect.width;
//       const mindMapMaxHeight =
//         containerRect.height > nodeDrawerRect.height ? containerRect.height : nodeDrawerRect.height;
//       const offsetX = (containerChildRect.width - mindMapMaxWidth) / 2;
//       const offsetY = (containerChildRect.height - mindMapMaxHeight) / 2;
//       const canvas = await html2canvas(container.firstChild, {
//         backgroundColor: option.transparent ? null : undefined,
//         useCORS: true,
//         x: offsetX,
//         y: offsetY,
//         width: mindMapMaxWidth,
//         height: mindMapMaxHeight + WATER_MARK_HEIGHT,
//         scale: window.devicePixelRatio,
//         ignoreElements: (element) => {
//           if (element.classList.contains('hidden-textarea')) return true;
//           return false;
//         },
//       });
//       return canvas;
//     }
//   }
// };
// 预览用的
export const captureSmallSnapshot = async (container: HTMLElement, option: ExportOption) => {
  if (option.type === 'png') {
    if (container.firstChild instanceof HTMLElement) {
      const nodeDrawer = container.querySelector('.node-drawer') as HTMLElement;
      if (!nodeDrawer) return;
      const nodeDrawerRect = nodeDrawer.getBoundingClientRect();
      const canvas = await html2canvas(nodeDrawer, {
        backgroundColor: option.transparent ? null : undefined,
        useCORS: true,
        x: 0,
        y: 0,
        width: nodeDrawerRect.width - 100,
        height: nodeDrawerRect.height + WATER_MARK_HEIGHT,
        scale: window.devicePixelRatio,
        ignoreElements: (element) => {
          if (element.classList.contains('hidden-textarea')) return true;
          return false;
        },
      });
      return canvas;
    }
  }
};
export const updateFlowUsWatermark = (
  canvas: HTMLCanvasElement,
  container: HTMLElement,
  option: {
    transparent: boolean;
    watermark: string;
    repeatWatermark: string;
    isDarkMode: boolean;
    scale?: number;
  }
) => {
  if (container.firstChild instanceof HTMLElement) {
    const containerChildRect = container.firstChild.getBoundingClientRect();
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.save();
    ctx.font = '14px normal';
    ctx.textAlign = 'left';
    // 清空水印高度的矩形范围
    const mindMapWidth = canvas.width / (option.scale ?? window.devicePixelRatio);
    const mindMapHeight = canvas.height / (option.scale ?? window.devicePixelRatio);
    const offsetX = (containerChildRect.width - mindMapWidth) / 2;
    const offsetY = (containerChildRect.height - mindMapHeight) / 2;
    // 我不记得为啥要这么算了，反正结果是正确的-，-
    ctx.translate(containerChildRect.left + offsetX, containerChildRect.top + offsetY);
    ctx.clearRect(0, mindMapHeight, mindMapWidth, mindMapHeight + WATER_MARK_HEIGHT);
    if (option.watermark) {
      ctx.fillStyle = option.isDarkMode ? 'white' : 'grey';
      const textWidth = ctx.measureText(option.watermark).width;
      ctx.fillText(
        option.watermark,
        (mindMapWidth - textWidth) * 0.5,
        mindMapHeight + (WATER_MARK_HEIGHT - 10) / (option.scale ?? window.devicePixelRatio)
      );
    }
    if (option.repeatWatermark) {
      const tm = ctx.measureText(option.repeatWatermark);
      const textWidth = tm.width;
      const space = 30;
      const textHeight = 45;
      const rotateDeg = -120;
      ctx.globalAlpha = 0.045;
      // 不要尝试优化，谢谢
      for (let j = 0; j < mindMapHeight; j += textHeight + space) {
        for (let i = 0; i < mindMapWidth; i += textWidth + space) {
          ctx.translate(i, j);
          ctx.rotate(rotateDeg);
          ctx.fillText(option.repeatWatermark, 0, 0);
          ctx.rotate(-rotateDeg);
          ctx.translate(-i, -j);
        }
      }
    }
    ctx.restore();
  }
};
// export const updateWatermark = (
//   canvas: HTMLCanvasElement,
//   container: HTMLElement,
//   option: {
//     watermark: string;
//     isDarkMode: boolean;
//     scale?: number;
//   }
// ) => {
//   if (container.firstChild instanceof HTMLElement) {
//     const containerChildRect = container.firstChild.getBoundingClientRect();
//     const ctx = canvas.getContext('2d');
//     if (!ctx) return;
//     ctx.save();
//     ctx.font = '14px normal';
//     ctx.textAlign = 'left';
//     // 清空水印高度的矩形范围
//     const mindMapWidth = canvas.width / (option.scale ?? window.devicePixelRatio);
//     const mindMapHeight = canvas.height / (option.scale ?? window.devicePixelRatio);
//     const offsetX = 0;
//     const offsetY = 0;
//     // 我不记得为啥要这么算了，反正结果是正确的-，-
//     // ctx.translate(containerChildRect.left + offsetX, containerChildRect.top + offsetY);
//     // ctx.clearRect(0, 0, mindMapWidth, mindMapHeight);
//     if (option.watermark) {
//       // ctx.fillStyle = option.isDarkMode ? 'white' : 'grey';
//       ctx.fillStyle = 'red';
//       const tm = ctx.measureText(option.watermark);
//       const textWidth = tm.width;
//       const space = 10;
//       const textHeight = 40;
//       for (let j = 0; j < mindMapHeight; j += textHeight + space) {
//         for (let i = 0; i < mindMapWidth; i += textWidth + space) {
//           ctx.fillText(option.watermark, i, j);
//           console.log('i, j: ', i, j);
//         }
//       }
//       ctx.fillText('111', 100, 100);
//     }
//     ctx.restore();
//   }
// };
