import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import { Button } from 'src/common/components/button';
import { Input } from 'src/common/components/input';
import { useOpenModal } from 'src/common/components/next-modal';
import { EditorPersonList } from 'src/components/editor-person-list';
import { useUpdateGroup } from 'src/hooks/block/use-update-group';
import { realGroupName } from 'src/hooks/collection-view/table-groups';
import { useGroupPropertySchema } from 'src/hooks/collection-view/use-group-option';
import { checkRepeatName } from '../add-group';

interface Props {
  closeModal: () => void;
  isSubGroup?: boolean;
  groupName: string;
}

export const GroupEditor: FC<Props> = (props) => {
  const { closeModal, isSubGroup = false, groupName } = props;
  const { viewId } = useBitable();
  const updateGroup = useUpdateGroup();
  const openModal = useOpenModal();
  const valueRef = useRef('');
  const inputRef = useRef<HTMLInputElement>(null);
  const groupSchema = useGroupPropertySchema(viewId, isSubGroup);

  const submit = () => {
    const newGroupName = valueRef.current.replace(/,/g, '').trim();
    if (!newGroupName) {
      closeModal();
      return;
    }

    if (checkRepeatName(viewId, newGroupName, isSubGroup)) {
      openModal.warning({
        title: <>已存在同名分组</>,
        noCancel: true,
        confirm() {
          inputRef.current?.focus();
        },
      });
      return;
    }

    updateGroup(viewId, groupName, newGroupName, isSubGroup);
    closeModal();
  };

  const onSelect = (newGroupName: string) => {
    if (checkRepeatName(viewId, newGroupName, isSubGroup)) {
      openModal.warning({
        title: <>已存在同名分组</>,
        noCancel: true,
        confirm() {
          inputRef.current?.focus();
        },
      });
      return;
    }

    updateGroup(viewId, groupName, newGroupName, isSubGroup);
    closeModal();
  };

  if (groupSchema?.type === CollectionSchemaType.PERSON) {
    return (
      <div className="next-modal">
        <EditorPersonList onSelect={onSelect} />
      </div>
    );
  }

  return (
    <div className="next-modal box-border flex w-[320px] py-2 pr-3">
      <Input
        autoFocus
        showBorder={false}
        className="text-t2 h-8 flex-1 rounded-sm border-none !bg-transparent"
        onEnter={submit}
        defaultValue={realGroupName(groupName)}
        onChange={(text) => (valueRef.current = text)}
      />
      <Button
        onClick={submit}
        colorType="active"
        className="text-t4 flex h-[32px] w-[62px] items-center border-none text-white"
      >
        完成
      </Button>
    </div>
  );
};
