import { Icon } from 'src/common/components/icon';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType, PermissionRole } from '@next-space/fe-api-idl';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import type { SearchItem } from 'src/components/search';
import { SearchMenu } from 'src/components/search';
import { covertNextBlockToSearchItem } from 'src/components/search/covert-next-block-to-search-item';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getState } from 'src/redux/store';
import { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { setAppUiState, useAiPDFContents } from 'src/services/app';
import { transaction } from 'src/hooks/use-transaction';
import { textToSegments } from 'src/editor/utils/editor';
import { addBlock } from 'src/redux/managers/block/add';
import { request } from 'src/common/request';
import type { PDFViewApplication } from '../service/pdf-viewer-application';
import { ChatItem } from './chat-item';
import { PromptInput } from './prompt-input';
import { getLocationOrigin } from 'src/utils/location-utils';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { usePermissions } from 'src/hooks/share/use-permissions';
import './style.css';
import { useReadonly } from 'src/hooks/page';
import { getToken } from 'src/utils/get-next-auth';
import { $searchParams } from 'src/utils';
import { useGetPageId } from 'src/utils/getPageId';
import { useCurrentSpace } from 'src/hooks/space';

interface Props {
  uuid?: string;
  recordId?: string;
  propertyId?: string;
  ossName: string;
  application: PDFViewApplication;
}

enum ReadingStatus {
  READING,
  STOP,
  ERROR,
  SUCCESS,
}

// const getPdfKey = (params: Props) => {
//   const { uuid, recordId, propertyId, ossName } = params;
//   const blockId = uuid ?? recordId;

//   let key = `blockId=${blockId}`;
//   if (propertyId) key += `propertyId=${propertyId}`;
//   key += `ossName=${ossName}`;

//   return key;
// };

export const AISideBar: FC<Props> = (props) => {
  const { uuid, recordId, propertyId, ossName, application } = props;
  const blockId = uuid ?? recordId;
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  const aiContents = useAiPDFContents();
  const [status, setStatus] = useState(ReadingStatus.READING);
  const [ready, setReady] = useState(false);
  const cancelRequest = useRef<AbortController>();
  const { enableAI, isAllowed } = useEnableAI();
  const { role } = usePermissions(blockId);
  const readonly = useReadonly(blockId);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const currentSpace = useCurrentSpace();
  const spaceId = getCurrentSpaceId();

  const editable =
    enableAI && isAllowed && (role === PermissionRole.WRITER || role === PermissionRole.EDITOR);

  // useEffect(() => {
  //   if (readonly) return;
  //   // 检测空间成员是否有AI权限
  //   const spaceId = getCurrentSpaceId();
  //   void request.infra.getSpaceAiMembers(spaceId).then((data) => {
  //     setAppUiState({ $aiMembers: data });
  //   });
  // }, [readonly]);

  useEffect(() => {
    cancelRequest.current?.abort();
    setStatus(ReadingStatus.READING);
    getFileAiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, recordId, ossName, propertyId]);

  const getFileAiData = () => {
    if (!blockId) return;

    if (readonly && !location.pathname.includes('preview')) {
      // !location.pathname.includes('preview') TODO readonly 的值有问题，空白空间第一次上传会有问题，需刷新
      setReady(true);
      setStatus(ReadingStatus.SUCCESS);
      void request.ai.getAiChatFile(ossName, spaceId, blockId, propertyId).then((res) => {
        setAppUiState({ $pdfAIContents: res });
      });

      return;
    }

    const params = { ossName, blockId, propertyId, spaceId: currentSpace.uuid };
    cancelRequest.current = new AbortController();

    const headers: any = { 'Content-Type': 'application/json' };

    const token = getToken();
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    void fetch('/api/chat/file', {
      signal: cancelRequest.current.signal,
      method: 'POST',
      headers,
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code !== 200) {
          message.error(res.msg);
          setStatus(ReadingStatus.ERROR);
          return;
        }

        if (res) {
          setReady(true);
          setStatus(ReadingStatus.SUCCESS);
          void request.ai.getAiChatFile(ossName, spaceId, blockId, propertyId).then((res) => {
            // 这里应该是 ready 的意思，而不是是否显示侧边栏
            application.setAIShowSideBar(true);
            if (propertyId) {
              if (
                pageId === recordId &&
                $searchParams.ossName === ossName &&
                $searchParams.propertyId === propertyId
              ) {
                setAppUiState({ $pdfAIContents: res });
              }
            } else {
              if (pageId === uuid) {
                setAppUiState({ $pdfAIContents: res });
              }
            }
          });
        }
      })
      .catch(() => {
        setStatus(ReadingStatus.ERROR);
      });
  };

  const exportContents = (pageId: string) => {
    transaction(() => {
      aiContents.forEach((item) => {
        const { quote, user, ai, links } = item;
        if (quote) {
          addBlock(
            { type: BlockType.QUOTE, data: { segments: textToSegments(quote) } },
            { parentId: pageId, last: true }
          );
        }

        let segments: SegmentDTO[] = [];
        let str = '';
        if (user) str = `${str + user}\n`;
        if (ai) str = `${str + ai}\n`;
        if (links) {
          const isQuote = links.find((item) => item.type === 'quote');
          segments = textToSegments(str);

          if (isQuote && uuid) {
            links.forEach((item, index) => {
              if (index > 0) {
                segments.push({ enhancer: {}, text: '  ', type: TextType.TEXT });
              }

              segments.push({
                enhancer: {},
                text: ['页码', item.pageNumber, index === links.length - 1 ? '\n' : ''].join(''),
                type: TextType.URL,
                url: `${getLocationOrigin()}/preview/${uuid}?annotationPos=${item.pageNumber}`,
              });
            });
          } else {
            links.forEach((item) => {
              if (item.content) str = `${str + item.content}\n`;
            });

            segments = textToSegments(str);
          }
        }

        addBlock(
          { type: BlockType.TEXTAREA, data: { segments } },
          { parentId: pageId, last: true }
        );
      });

      message.success({
        duration: 5000,
        content: (
          <div className="flex items-center justify-between w-40">
            <div className="text-t2">导出成功</div>

            <a
              href={`/${pageId}`}
              target="_blank"
              className="text-t4 text-active_color animate-click"
            >
              跳转查看
            </a>
          </div>
        ),
      });
    });
  };

  const handleClick = async (event: React.MouseEvent) => {
    const popcorn = event.currentTarget;
    const spaceId = getCurrentSpaceId();

    const { blocks } = getState();

    const defaultItems = (blocks[spaceId]?.subNodes ?? [])
      .map((id) => {
        const itemBlock = blocks[id];
        if (!itemBlock) return undefined;

        if (
          itemBlock.type === BlockType.PAGE ||
          itemBlock.type === BlockType.COLLECTION_VIEW ||
          itemBlock.type === BlockType.COLLECTION_VIEW_PAGE
        ) {
          return covertNextBlockToSearchItem(itemBlock);
        }

        return undefined;
      })
      .filter((item): item is SearchItem => !!item);

    openModal.dropdown({
      popcorn,
      placement: 'bottom-end',
      offset: [0, 10],
      content: ({ onCloseModal }) => (
        <SearchMenu
          onSelect={(target) => {
            if (target.type !== BlockType.PAGE) {
              message.warning('不支持该页面类型');
              return;
            }

            exportContents(target.uuid);
            onCloseModal();
          }}
          defaultValue={defaultItems}
          searchType="page"
          source="move"
        />
      ),
    });
  };

  const showText = {
    [ReadingStatus.READING]: '正在阅读文档...',
    [ReadingStatus.STOP]: '已停止阅读，请重试',
    [ReadingStatus.ERROR]: '处理失败，请重试',
  };

  return (
    <div
      className="w-full h-full bg-white2 border-l overflow-auto flex flex-col"
      ref={scrollContainer}
    >
      <div className="h-10 flex items-center text-t2-medium justify-between px-4 border-b sticky top-0 bg-white2 z-10">
        <span>AI 聊天</span>

        {ready && !readonly && (
          <button className={'text-t2 text-active_color p-1'} onClick={handleClick}>
            导出
          </button>
        )}
      </div>

      {status !== ReadingStatus.SUCCESS && (
        <div className="text-t2 flex items-center mt-4 px-4 pb-20">
          <div className="w-6 h-6 mr-2 rounded-full flex items-center justify-center">
            <Icon size="small" name="IcAi" />
          </div>
          {showText[status]}
          <span
            className="ml-auto cursor-pointer text-grey3"
            onClick={() => {
              if (status === ReadingStatus.READING) {
                cancelRequest.current?.abort();
                setStatus(ReadingStatus.STOP);
              } else {
                setStatus(ReadingStatus.READING);
                getFileAiData();
              }
            }}
          >
            {status === ReadingStatus.READING ? '停止' : '重试'}
          </span>
        </div>
      )}

      {status === ReadingStatus.SUCCESS && (
        <>
          <div className="mt-4 px-4 pb-20 flex-1">
            {aiContents.map((item) => {
              return (
                <ChatItem
                  {...props}
                  key={item.uuid}
                  chatInfo={item}
                  editable={editable}
                  container={scrollContainer}
                />
              );
            })}
          </div>
          {editable && <PromptInput {...props} container={scrollContainer} />}
        </>
      )}
    </div>
  );
};
