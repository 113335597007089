import type { PDFAnnotationDTO } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { getState } from 'src/redux/store';

import { useTransaction } from '../use-transaction';

/**
 * 创建新记录, 默认不排序
 * @returns
 */

export const useUpdatePDFAnnotation = () => {
  const transaction = useTransaction();

  const updatePDFAnnotation = (
    blockId: string,
    patch: Partial<PDFAnnotationDTO>,
    localUrl?: string
  ) => {
    return transaction(() => {
      transaction(() => {
        const { blocks } = getState();
        const block = blocks[blockId];
        if (!block) return;

        if (!block.data.pdfAnnotation) return;
        updateBlock(blockId, {
          data: {
            localUrl,
            pdfAnnotation: {
              ...block.data.pdfAnnotation,
              ...patch,
            },
          },
        });
      });
    });
  };

  return useCallback(updatePDFAnnotation, [transaction]);
};
