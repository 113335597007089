import type { CSSProperties, ReactNode } from 'react';

export enum BarType {
  left,
  right,
  rightBottom,
  leftBottom,
  bottom,
}

export interface MoveBarProps {
  type: BarType;
  moveRes: {
    deltaX: number;
    deltaY: number;
  };
}

export interface CalcSizeParams {
  size: { width: number; height: number };
  stateSize: { width: number; height: number };
  blockFullWidth?: boolean;
  breakPoints?: boolean;
  maxHeight?: number | null;
  minHeight: number;
  maxWidth: number;
  minWidth: number;
  widthBreakPoints: number[];
  fullHeight?: boolean;
}

export interface ResizeElementProps extends Omit<JSX.IntrinsicElements['div'], 'onChange'> {
  className?: string;
  /** 只读，不可resize */
  readonly?: boolean;
  /** 说明块的外层div className */
  outerLayoutClassName?: string;
  /** 说明块的外层div style */
  outerLayoutStyle?: CSSProperties;
  /** children外层的div className */
  childrenClassName?: string;
  /** 最大宽度 */
  maxWidth?: number;
  /** 最大高度 */
  maxHeight?: number;
  /** 最小高度 */
  minHeight?: number;
  /** 最小宽度 */
  minWidth?: number;
  /** 默认高度 */
  defaultHeight?: number;
  /** 默认宽度 */
  defaultWidth: number; // 如果是外链并且没有设置过宽度的话，这个为0
  /** 是否自适应100%宽度 */
  defaultBlockFullWidth?: boolean;
  /** 居中时，需要打开双倍resize */
  doubleResize?: boolean;
  /** 高度可控制 */
  resizeHeight?: boolean;
  /** 宽度可控制 */
  resizeWidth?: boolean;
  /** 防抖阈值 */
  debounceSize?: number;
  /** fullHeight时，必须要有maxHeight，就是外层的盒子高度 */
  fullHeight?: boolean;
  /** 禁用宽高样式，让内容自适应撑开 */
  disableStyle?: boolean;
  /** 宽度吸附节点 */
  widthBreakPoints?: number[];
  /** 拖拽改变大小时回调 */
  onChange?: (e: { width: number; height?: number; blockFullWidth: boolean }) => void;
  /** 自定义children下方的div内容，使其在此组件内，但又不属于resize的区域 */
  customFooter?: ReactNode;
  /** 获取最新的渲染宽高，因为元素的宽高data数据可能是100%自适应，为了方便获取，则提供出一个渲染数据 */
  onRenderSize?: (size: { width: number; height: number }) => void;
  onKeyDownMoveBar?: () => void;
  onKeyUpMoveBar?: () => void;
}
