import { BlockType } from '@next-space/fe-api-idl';

export const NEW_SUB_PAGE_NAMES = {
  [BlockType.PAGE]: '创建子页面',
  [BlockType.FOLDER]: '创建子文件夹',
  [BlockType.COLLECTION_VIEW_PAGE]: '创建记录',
};

export enum TocType {
  /** 个人页面 */
  PRIVATE,
  /** 空间页面 */
  SPACE,
  /** 分享页面 */
  SHARE,
  /** 快速访问 */
  FAVORITE,
  /** 精选栏目推荐 */
  RECOMMEND,
}

export const TocTypeName = {
  [TocType.PRIVATE]: 'private',
  [TocType.SPACE]: 'space',
  [TocType.SHARE]: 'share',
  [TocType.FAVORITE]: 'favorite',
  [TocType.RECOMMEND]: 'recommend',
};

export const PREFIX_TOC_GROUP_HIDDEN = 'toc_group_hidden';
