import { cx } from '@flowus/common/cx';
import { imageGalleryClassNames, imageGalleryStyle } from '@flowus/common/style';
import type { FC } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { useVisible } from 'src/common/hooks/use-visible';
import { useTryFetchPageBlock } from 'src/components/page-preview/use-try-fetch-page-block';
import { ThumbPageCardDescContent } from 'src/editor/component/page-block';
import { segmentsToText } from 'src/editor/utils/editor';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import { BackgroundContainer } from './background-container';
import { getImageBlocks } from './get-image-block-util';
import { ImageBlockElement } from './image-block-render';
import type { PageProps } from './types';
import { useCoverImageUrl } from './use-cover-image-url';

export const SmallThumbImagesCard: FC<PageProps> = (props) => {
  const { id, illegal, showCover, desc, isInColumn } = props;
  const hasCover = !!useCoverImageUrl(id) && showCover;
  const [visible, setRef] = useVisible<HTMLDivElement>();
  const loading = useTryFetchPageBlock(id, visible);
  const block = usePickBlock(id, ['subNodes', 'data'], ['segments']);
  const divRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>();
  const title = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);
  const [imageIds, setImageIds] = useState<string[]>([]);

  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '250px',
    };
  }
  useEffect(() => {
    if (loading) return;
    const ids = getImageBlocks(id);
    setImageIds(ids);
  }, [id, loading]);

  useLayoutEffect(() => {
    if (!visible || loading) return;

    const footer = divRef.current?.querySelector('.footer');
    if (footer instanceof HTMLElement) {
      footer.hidden = true;
    }
    const pageContainer =
      divRef.current?.querySelector(`#${ID_DRIVE}`) ??
      divRef.current?.querySelector('.next-space-page');
    if (pageContainer instanceof HTMLElement) {
      pageContainer.childNodes.forEach((c) => {
        if (c instanceof HTMLElement) {
          c.setAttribute('style', `padding-left:12px;padding-right:12px`);
        }
      });
    }
  }, [loading, visible]);

  return (
    <BackgroundContainer
      blockId={id}
      ref={(ref) => {
        setRef(ref);
        if (ref != null) {
          container.current = ref;
        }
      }}
      showCover={showCover}
      {...attribute}
      className={cx('overflow-hidden flex relative w-full h-[90px] animate-hover border', {
        'w-full': isInColumn,
      })}
      onClick={(e) => {
        const selection = window.getSelection();
        if (!selection) return;
        if (selection.isCollapsed) {
          props.onClick?.(e);
        }
      }}
    >
      <ThumbPageCardDescContent
        small
        title={title}
        blockType={block?.type}
        desc={desc}
        hasCover={hasCover}
      />
      <ImageGallery2
        ids={imageIds}
        className={cx('ml-4 mt-4', {
          'absolute right-3 ml-0': !isInColumn,
        })}
      />
    </BackgroundContainer>
  );
};

const ImageGallery2: FC<{ ids: string[]; className?: string }> = ({ ids, className }) => {
  const id1 = ids[0];
  const id2 = ids[1];
  const id3 = ids[2];
  return (
    <PageSceneContext.Provider value={PageScene.ONLY_IMAGE}>
      <div className={cx('relative w-[280px]', className)}>
        <div className={imageGalleryClassNames.id1} style={{ ...imageGalleryStyle }}>
          {id1 && <ImageBlockElement uuid={id1} />}
        </div>
        <div className={imageGalleryClassNames.id2} style={{ ...imageGalleryStyle }}>
          {id2 && <ImageBlockElement uuid={id2} />}
        </div>
        <div className={imageGalleryClassNames.id3} style={{ ...imageGalleryStyle }}>
          {id3 && <ImageBlockElement uuid={id3} />}
        </div>
      </div>
    </PageSceneContext.Provider>
  );
};
