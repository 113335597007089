export const getElementToBodyDistance = (element: HTMLElement) => {
  return getElementToElementDistance(element, document.body);
};

export const getElementToElementDistance = (element?: HTMLElement, container?: HTMLElement) => {
  const obj = {
    left: 0,
    top: 0,
  };

  while (element !== container && element) {
    obj.left += element.offsetLeft || 0;
    obj.top += element.offsetTop || 0;
    // @ts-ignore dom
    element = element.offsetParent;
  }
  return obj;
};

export const getDomRect = (node: HTMLElement): DOMRect => {
  return node.getBoundingClientRect();
};
/**
 * 如果是在MainContent上找element，建议使用这个方法,其它情况请直接使用document.querySelector(selectors)
 *
 * 由于多维表打开页面的时候是在右边新增了一个panel，query的时候都没加上对应的class，
 * 会导致右边的right-panel找了main-panel的element，从而导致一些奇奇怪怪的现象，这个方法是解决这个问题的。
 */
export const querySelectorFromMainContent = (
  selectors: string,
  isRightPanel?: boolean
): Element | null => {
  if (isRightPanel) {
    return document.querySelector(`.right-panel ${selectors}`);
  }
  return document.querySelector(`.main-panel ${selectors}`);
};
/** SelectorAll 同上 */
export const querySelectorAllFromMainContent = (
  selectors: string,
  isRightPanel?: boolean
): NodeListOf<Element> => {
  if (isRightPanel) {
    return document.querySelectorAll(`.right-panel ${selectors}`);
  }
  return document.querySelectorAll(`.main-panel ${selectors}`);
};

export const blurDocument = (clearSelection?: boolean) => {
  (document.activeElement as HTMLElement | null)?.blur();
  if (clearSelection) {
    window.getSelection().removeAllRanges();
    document.dispatchEvent(new Event('_clearTextSelection'));
  }
};

/** 模拟a标签点击 */
export const createAnchorDomClick = (href?: string) => {
  if (!href) return;
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.setAttribute('href', href);
  link.click();
};
