import { GroupLevel } from '@next-space/fe-api-idl';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { ONE_DAY } from 'src/bitable/timeline-view/const';
import { DATE_FORMAT } from 'src/common/const';

export const getGroupNameFromDate = (timestamp: number, groupLevel?: GroupLevel) => {
  if (!groupLevel) return;

  switch (groupLevel) {
    case GroupLevel.RELATIVE: {
      const date = dayjs(dayjs(timestamp).format(DATE_FORMAT)).valueOf();
      const today = dayjs(dayjs(Date.now()).format(DATE_FORMAT)).valueOf();

      if (date === today) return 'today';
      if (date === today + ONE_DAY) return 'tomorrow';
      if (date === today - ONE_DAY) return 'yesterday';
      if (date > today + ONE_DAY && date <= today + 7 * ONE_DAY) return 'next_7_day';
      if (date > today + 7 * ONE_DAY && date <= today + 30 * ONE_DAY) return 'next_30_day';
      if (date < today - ONE_DAY && date >= today - 7 * ONE_DAY) return 'last_7_day';
      if (date < today - 7 * ONE_DAY && date >= today - 30 * ONE_DAY) return 'last_30_day';
      if (date > today + 30 * ONE_DAY) return 'after_next_30_day';
      if (date < today - 30 * ONE_DAY) return 'before_last_30_day';

      return '';
    }

    case GroupLevel.DAY: {
      return dayjs(timestamp).format(DATE_FORMAT);
    }

    case GroupLevel.WEEK: {
      const date = timestamp - ONE_DAY * ((dayjs(timestamp).day() || 7) - 1);
      return `${dayjs(date).format(DATE_FORMAT)}`;
    }

    case GroupLevel.MONTH: {
      return `${dayjs(timestamp).date(1).format(DATE_FORMAT)}`;
    }

    case GroupLevel.YEAR: {
      return `${dayjs(timestamp).month(0).date(1).format(DATE_FORMAT)}`;
    }

    default:
      break;
  }
};

export const getDateFromGroupName = (groupName: string) => {
  const today = dayjs(dayjs(Date.now()).format(DATE_FORMAT)).valueOf();
  if (groupName === 'today') return today;
  if (groupName === 'tomorrow') return today + ONE_DAY;
  if (groupName === 'yesterday') return today - ONE_DAY;
  if (groupName === 'next_7_day') return today + 2 * ONE_DAY;
  if (groupName === 'next_30_day') return today + 8 * ONE_DAY;
  if (groupName === 'after_next_30_day') return today + 31 * ONE_DAY;
  if (groupName === 'last_7_day') return today - 2 * ONE_DAY;
  if (groupName === 'last_30_day') return today - 8 * ONE_DAY;
  if (groupName === 'before_last_30_day') return today - 31 * ONE_DAY;

  return dayjs(groupName).valueOf();
};

export const getNumberGroupName = (num: number, start: number, end: number, size: number) => {
  let groupName = '';

  if (num >= start && num < end) {
    const newStart = new BigNumber(start).plus(
      new BigNumber(Math.floor((num - start) / size)).multipliedBy(new BigNumber(size))
    );

    groupName = `${newStart},${BigNumber.sum(newStart, size).toNumber()}`;
  } else {
    groupName = 'out_of_range';
  }

  return groupName;
};
