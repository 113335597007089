import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import React, { useMemo, useRef } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { calculateListFormat } from 'src/utils/block-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockSymbol } from '../component/block-symbol-container';
import { NodeWrapper } from '../component/node-wrapper';
import { useLastWidthBeforeEditing } from '../hook/use-last-width-before-editing';
import { useMindNodePadding } from '../hook/use-mind-node-style';
import { getDefaultPlaceHolder } from '../utils/mind-node-util';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';

const formatMap = {
  disc: '•',
  circle: '◦',
  square: '▪',
};
export const BulletListNode: MindNodeElement = React.memo((props) => {
  const listFormat = useObservableStore(
    ({ blocks }) => getListFormat(props.id, blocks),
    [props.id]
  );
  const block = usePickBlock(props.id, ['data'], ['segments']);
  const placeHolder = getDefaultPlaceHolder(props.level);
  const updateSegments = useThrottleUpdateSegments(props.id);

  const isEmpty = useMemo(() => {
    return !segmentsToText(block?.data.segments);
  }, [block?.data.segments]);

  const containerRef = useRef<HTMLDivElement>(null);
  const lastWidth = useLastWidthBeforeEditing(props.id, containerRef, isEmpty, props.level);
  const { paddingLeft, paddingRight } = useMindNodePadding(props.id, props.level);

  if (!block) return null;

  return (
    <NodeWrapper
      id={props.id}
      ref={containerRef}
      className="flex items-center"
      level={props.level}
      style={{
        paddingLeft: `${paddingLeft}px`,
        minWidth: lastWidth,
      }}
    >
      <BlockSymbol
        symbol={
          <div
            className="pseudoBefore mr-1.5"
            style={{ '--pseudoBefore--content': `"${listFormat}"` } as any}
          />
        }
        editorNode={
          <MindMapRichText
            placeholder={placeHolder}
            uuid={props.id}
            className="whitespace-pre-wrap break-words self-start"
            segments={block.data.segments}
            onChange={(
              segments: SegmentDTO[],
              prevContent: IContent,
              prevSelection: ISelection | null
            ) => {
              updateSegments(segments, [prevContent, prevSelection]);
            }}
            style={{
              paddingRight: `${paddingRight}px`,
            }}
          />
        }
      />
    </NodeWrapper>
  );
});

export function getListFormat(uuid: string, blocks: Record<string, NextBlock>) {
  const formatInfo = calculateListFormat(uuid, blocks, BlockType.LIST);
  const format = formatInfo?.format || formatInfo?.defaultFormat;
  // @ts-ignore ignore
  if (format) return formatMap[format] ?? formatMap.disc;
  return formatMap.disc;
}
