import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';

import type { BiUserResolver } from '../utils/resolvers';
import { BiArrayValueType } from './_abstract';
import { BiUserType } from './user';

export class BiUsersType extends BiArrayValueType<string> {
  constructor(private resolver: BiUserResolver) {
    super();
  }

  get elementType(): BiUserType {
    return new BiUserType(this.resolver);
  }

  fromSegments(segments: SegmentDTO[] | undefined): string[] | undefined {
    if (segments === undefined) return undefined;
    const ids: string[] = [];
    for (const segment of segments) {
      if (segment.type === TextType.USER && segment.uuid !== undefined) {
        ids.push(segment.uuid);
      }
    }
    if (ids.length === 0) return undefined;
    return ids;
  }

  toSegments(value: string[]): SegmentDTO[] | undefined {
    return value.map((it) => {
      return {
        type: TextType.USER,
        enhancer: {},
        text: '@',
        uuid: it,
      };
    });
  }

  compareElement(value1: string, value2: string): number {
    return this.elementType.compare(value1, value2);
  }
}
