import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import { memo, useEffect, useRef } from 'react';
import { useKeys } from 'src/editor/editor/plugin/table/helper';
import { useIndexRanges } from 'src/editor/editor/plugin/table/hook';
import { TableBody } from 'src/editor/editor/plugin/table/table-body';
import { ReadonlyContext } from 'src/hooks/block/use-block-locked';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { NodeWrapper } from '../component/node-wrapper';
import type { MindNodeElement } from './all-node-renderer';

export const TableNode: MindNodeElement = memo(({ id, level, children }) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const isDragging = useObservableStore(({ simpleTable }) => !!simpleTable.draggingStart, [], {
    obsSimpleTable: true,
  });

  useKeys(id);

  useEffect(() => {
    const root = document.getElementById('root');
    if (isDragging) {
      root?.classList.add('dragging');
    } else {
      root?.classList.remove('dragging');
    }
  }, [isDragging]);

  const indexRanges = useIndexRanges(id);

  return (
    <NodeWrapper id={id} level={level}>
      <ReadonlyContext.Provider value={true}>
        <div
          className={cx(
            'overflow-x-auto overflow-y-hidden relative',
            level === 0 && '-ml-[104px] pl-24 sm:-ml-12 sm:pl-12'
          )}
          onScroll={() => emitter.emit('removeHoverMenu')}
        >
          <table ref={tableRef} className="w-max mt-3 mx-2 mb-[18px]">
            <TableBody tableId={id} indexRanges={indexRanges} />
          </table>
        </div>
      </ReadonlyContext.Provider>
      {children}
    </NodeWrapper>
  );
});
