import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IEditorModel, ISelection } from '@next-space/fe-inlined';
import type { MutableRefObject } from 'react';
import { deepEqualLoose } from '@flowus/common/utils/tools';
import { wrapEmoji } from 'src/editor/editor/inline/emoji-utils';
import { convertSegmentsToContent } from 'src/editor/utils/segments';

export const SYNCED_SEGMENTS_REF_SYMBOL = Symbol('SYNCED_SEGMENTS_REF_SYMBOL');
export const SYNCED_REVISION_REF_SYMBOL = Symbol('SYNCED_REVISION_REF_SYMBOL');

export const syncSegmentsToEditorModel = (
  editorModel: IEditorModel,
  segments: SegmentDTO[] | undefined,
  selection?: ISelection | null
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const syncedSegmentsRef = (editorModel as any)[SYNCED_SEGMENTS_REF_SYMBOL] as MutableRefObject<
    SegmentDTO[] | undefined
  >;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const syncedRevisionRef = (editorModel as any)[
    SYNCED_REVISION_REF_SYMBOL
  ] as MutableRefObject<number>;
  if (syncedSegmentsRef && !deepEqualLoose(syncedSegmentsRef.current, segments)) {
    const { wrapSegments, hasNewEmoji } = wrapEmoji(segments ?? []);
    const content = hasNewEmoji
      ? convertSegmentsToContent(wrapSegments)
      : convertSegmentsToContent(segments);

    editorModel.performChange((ctx) => {
      ctx.load(content);
      if (selection !== undefined) {
        ctx.select(selection?.offset ?? 0, selection?.endOffset ?? 0);
      }
    });
    syncedSegmentsRef.current = segments;
    syncedRevisionRef.current = editorModel.revision;
  } else {
    if (selection !== undefined) {
      editorModel.performChange((ctx) => {
        ctx.select(selection?.offset ?? 0, selection?.endOffset ?? 0);
      });
    }
  }
};
