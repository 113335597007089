import { cx } from '@flowus/common/cx';
import { emitter } from '@flowus/common/utils/emitter';
import type { FC, MouseEvent } from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { DEFAULT_PAGE_WIDTH, UNTITLED, UUID_NAMESPACE } from 'src/common/const';
import { NotFound } from 'src/components/empty';
import { Footer } from 'src/components/footer';
import { LinkPageTabs } from 'src/components/link-page-tabs';
import { AiBlankArea, BlankArea } from 'src/editor/editor/uikit/blank-area';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import type { BlockEditor as BlockEditor0 } from 'src/editor/editor/uikit/editor';
import {
  getBlockLength,
  getVisibleThresholdConfig,
} from 'src/editor/editor/uikit/get-block-length';
import { getMouseBlock } from 'src/editor/utils/block';
import { segmentsToText } from 'src/editor/utils/editor';
import { useReadonly, useSaveLayoutInfo } from 'src/hooks/page';
import { ID_HOVER_MENU } from 'src/hooks/page/use-dnd/helper';
import { useObserverPageSize } from 'src/hooks/page/use-observer-page-size';
import { usePageLayoutStyle } from 'src/hooks/page/use-page-layout';
import { useCurrentUserSelector, useUpdateUser } from 'src/hooks/user';
import { getState } from 'src/redux/store';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { StoreContextProvider } from 'src/services/store-context/provider';
import { $currentUserCache } from 'src/services/user/current-user';
import { $searchParams } from 'src/utils';
import { focusEditable } from 'src/utils/editor';
import { getEditorKey } from 'src/utils/editor-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { PageOpenDiscussions } from 'src/views/comments/page-discussions';
import { PageScene, usePageScene } from '../scene-context';
import { PageScrollRefContext, useIsLiteSize } from './context';
import { DirectoryMenu } from './directory-menu';
import { DIRECTORY_LITE_WIDTH, DIRECTORY_MAX_WIDTH } from './directory-menu/type';
import { PageHeader } from './page-header';
import { PageProperties } from './page-properties';

let BlockEditor: typeof BlockEditor0;

export const antiCycleSet_BlockEditor = (BlockEditor0: typeof BlockEditor) => {
  BlockEditor = BlockEditor0;
};
interface Props {
  uuid: string;
}
export const PageDoc: FC<Props> = ({ uuid, children }) => {
  const isMouseMove = useRef(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const pageRef = useRef<HTMLDivElement>(null);
  const isLiteSize = useIsLiteSize();
  const page = usePage(uuid);
  const commentAlignment =
    useObservableBlock(uuid, (block) => block?.data.format?.commentAlignment) ?? 'top';
  const readonly = useReadonly(uuid);
  const isInRight = useIsInRight();

  const editableNamespace = useContext(EditableNamespace);
  // 展示slash弹窗时，禁止文档区域滚动
  // const createBlockMenuListId = useCreateBlockMenuListId();
  useSaveLayoutInfo(uuid, scrollRef);
  const enableHighPerformance = useCurrentUserSelector(
    (state) => state.setting?.enableHighPerformance
  );
  const openModal = useOpenModal();
  const updateUser = useUpdateUser();
  const { fontStyle, pageLayoutClassName } = usePageLayoutStyle(uuid);
  useObserverPageSize(pageRef);
  const pageScene = usePageScene();
  const isPageLitePreview = pageScene === PageScene.PAGE_LITE_PREVIEW;

  useEffect(() => {
    if (readonly) return;
    if (typeof enableHighPerformance !== 'undefined') return;
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    const update = (enable: boolean) => {
      void updateUser({ setting: { ...$currentUserCache.setting, enableHighPerformance: enable } });
    };

    const { count } = getBlockLength(block.subNodes);
    const { COUNT_THRESHOLD } = getVisibleThresholdConfig(false);
    if (count > COUNT_THRESHOLD) {
      openModal.warning({
        title: (
          <div>
            <p className="mb-4">在全局设置中开启高性能模式，将可以在编辑时获取极致流畅的体验</p>
            <p className="mb-4">但会无法使用一些功能，例如浏览器自带的搜索功能</p>
          </div>
        ),
        cancelText: '以后再说',
        confirmText: '马上开启',
        confirm: () => update(true),
        cancel: () => update(false),
        closeBeforeCallBackForClickOutSide: () => {
          update(false);
        },
      });
    }
  }, [enableHighPerformance, openModal, readonly, updateUser, uuid]);

  if (!page) return <NotFound />;

  const handleClick = (event: MouseEvent) => {
    const { selectedBlocks } = getState().ui;

    if (selectedBlocks.length || isMouseMove.current) return;

    if (
      ['input', 'textarea'].includes(document.activeElement?.tagName?.toLowerCase() ?? '') &&
      !(event.target as HTMLElement).closest('.CodeMirror')
    ) {
      return;
    }

    if ((event.target as HTMLElement).closest('table')) return;

    if (event.target instanceof Element) {
      // 如果本身就focus在editable上，就不需要处理了
      if (
        event.target.closest('[contenteditable]') ||
        event.target.closest('.ignore-focus-click')
      ) {
        return;
      }
    }

    const { blockId, node } = getMouseBlock(event, scrollRef.current);
    const target =
      node?.id === ID_HOVER_MENU
        ? (document.querySelector(`[data-block-id="${blockId}"]`) as HTMLElement)
        : (event.target as HTMLElement);
    const editorKey = getEditorKey(blockId, node?.dataset.syncId);

    if (
      !readonly &&
      editorKey &&
      !target.isContentEditable &&
      event.currentTarget.contains(target) &&
      !target.closest('.CodeMirror')
    ) {
      focusEditable(editorKey, event.clientX, event.clientY);
    }
  };

  // 默认打开固定页面宽度
  const isPrint = $searchParams.print;
  const { directoryMenu, pageFixedWidth = true } = page;
  const showDirectoryMenu =
    !isPrint &&
    directoryMenu &&
    !(editableNamespace === UUID_NAMESPACE.PAGE_HISTORY) &&
    pageScene &&
    [PageScene.MAIN, PageScene.SHARE].includes(pageScene);
  // for hot reload
  if (BlockEditor === undefined) return null;

  /** DEFAULT_PAGE_WIDTH + 标题宽度 */
  let contentWidth = '100%';
  if (pageFixedWidth) {
    if (!isLiteSize && showDirectoryMenu) {
      contentWidth = `${DEFAULT_PAGE_WIDTH + DIRECTORY_MAX_WIDTH}px`;
    } else if (showDirectoryMenu) {
      contentWidth = `${DEFAULT_PAGE_WIDTH + DIRECTORY_LITE_WIDTH}px`;
    } else {
      contentWidth = `${DEFAULT_PAGE_WIDTH}px`;
    }
  }
  // 如果是scene是lite-preview，说明是卡片页面预览，不能有最大宽度(因为会先设置一个300%多的宽度再缩小到30%)
  if (isPageLitePreview) {
    contentWidth = '100%';
  }

  return (
    <PageScrollRefContext.Provider value={scrollRef}>
      <div
        ref={scrollRef}
        onPointerEnter={() => (isMouseMove.current = true)}
        onPointerLeave={() => (isMouseMove.current = false)}
        onClick={handleClick}
        id="next-space-page"
        className={cx(
          pageLayoutClassName,
          'next-space-page overflow-y-auto',
          !isPrint && 'flex flex-col flex-1 overflow-x-hidden'
          // createBlockMenuListId ? 'overflow-y-hidden' : 'overflow-y-auto'
        )}
        onScroll={() => emitter.emit('removeHoverMenu')}
        style={fontStyle}
      >
        <div
          ref={pageRef}
          className={cx(
            'transition-all items-center',
            !isPrint && 'flex relative flex-1 flex-col',
            isLiteSize || isInRight ? 'px-12' : 'px-24',
            showDirectoryMenu && 'pl-28 pr-6',
            showDirectoryMenu && (isLiteSize || isInRight) && 'pl-12 pr-6',
            isPrint && 'px-0'
          )}
        >
          {!isPageLitePreview && (
            <div
              className={'w-full transition-all flex-shrink-0'}
              style={{ maxWidth: contentWidth }}
            >
              <PageHeader uuid={uuid}>
                {!$searchParams.blog && <PageProperties uuid={uuid} />}
              </PageHeader>
            </div>
          )}
          {isPrint ? (
            <BlockEditor uuid={uuid} readonly={readonly} />
          ) : (
            <div
              className={'flex flex-grow flex-shrink-0 transition-all w-full cursor-text'}
              style={{ maxWidth: contentWidth }}
            >
              <article className="flex flex-col relative w-full block-content transition-all flex-1 min-w-0">
                <BlockEditor visibleRender uuid={uuid} readonly={readonly} />
                {!readonly && <BlankArea uuid={uuid} />}
                {commentAlignment === 'bottom' && (
                  <PageOpenDiscussions pageId={uuid} isBottom={true} />
                )}
                <AiBlankArea />
                <StoreContextProvider wait={1000} waitMode="debounce">
                  <LinkPageTabs uuid={uuid} />
                </StoreContextProvider>
              </article>
              {showDirectoryMenu && <DirectoryMenu uuid={uuid} />}
            </div>
          )}
        </div>
        {children}
        {readonly && <Footer addSpace={!!children} />}
      </div>
    </PageScrollRefContext.Provider>
  );
};

const usePage = (uuid: string) => {
  return useObservableBlock(uuid, (block) => {
    if (!block) return;
    return {
      title: segmentsToText(block.data.segments) || UNTITLED,
      icon: block.data.icon,
      iconUrl: block.data.iconUrl,
      pageFixedWidth: $searchParams.print ? false : block.data.pageFixedWidth,
      directoryMenu: block.data.directoryMenu,
    };
  });
};
