import { TextType } from '@next-space/fe-api-idl';
import { getState } from 'src/redux/store';
import type { RootState } from 'src/redux/types';
import { MemoizeByBlockVersion } from 'src/services/memoize/cache';

export const getRelationRecords = MemoizeByBlockVersion.create(
  (recordId: string, propertyId: string, blocks: RootState['blocks'] = getState().blocks) => {
    const block = blocks[recordId];
    const collectionId = block?.parentId;
    const collection = blocks[collectionId ?? ''];
    const propertySchema = collection?.data.schema?.[propertyId];

    const { collectionId: relationCollectionId } = propertySchema ?? {};
    const relationCollection = blocks[relationCollectionId ?? ''];

    const segments = block?.data.collectionProperties?.[propertyId] ?? [];

    const subNodes = new Set(relationCollection?.subNodes ?? []);
    const relationRecords = new Set<string>();
    segments.forEach((segment) => {
      if (segment.type === TextType.LINK_PAGE && segment.uuid) {
        if (subNodes.has(segment.uuid)) {
          relationRecords.add(segment.uuid);
        }
      }
    });

    return {
      relationRecords: [...relationRecords],
      relationCollection,
    };
  },
  (recordId, propertyId) => [recordId, propertyId]
);
