import { Button } from '@flowus/common/components';
import type { FC } from 'react';
import { useCurrentUser } from 'src/hooks/user';
import { SettingModalCommon } from '../common';
import { useOpenChangePhoneOrEmailDialog } from './account-phone';

export const AccountEmail: FC = () => {
  const currentUser = useCurrentUser();

  const openChangePhoneOrEmailDialog = useOpenChangePhoneOrEmailDialog();

  return (
    <SettingModalCommon.Item
      leftChild={
        <div className="space-y-0.5">
          <div>邮箱</div>
          <div className="text-t4 text-grey3">{currentUser.email}</div>
        </div>
      }
      rightChild={<Button onClick={() => openChangePhoneOrEmailDialog('email')}>换绑邮箱</Button>}
    />
  );
};
