import type { CollectionViewType } from '@next-space/fe-api-idl';
import { createContext, useContext } from 'react';

export interface BitableContext {
  collectionId: string;
  viewId: string;
  viewParentId: string;
  readonly: boolean;
  managerReadonly?: boolean;
  isLocked?: boolean;
  embed?: boolean;
  changeView(viewId: string): void;
  isColumnListChildren?: boolean;
  isFoldListChildren?: boolean;
  viewType?: CollectionViewType;
  pageManager?: React.MutableRefObject<HTMLDivElement | null>;
  relationEditor?: boolean;
  tableCellWrap?: boolean;
}

export const BitableContext = createContext<BitableContext | undefined>(undefined);

export const useBitable = () => {
  const context = useContext(BitableContext);
  if (!context) {
    throw new Error('useBitable');
  }
  return context;
};
