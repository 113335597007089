import { cx } from '@flowus/common/cx';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { isInColumnList } from 'src/utils/block-utils';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import type { BlockElement } from '../core/type';
import { RawPage } from '../raw/raw-page';
import { BlockDrop } from './dnd/block-drop';

/**
 * page block
 */
export const PageBlockElement: BlockElement = ({ id, root, ownerBlockId }) => {
  const blockId = ownerBlockId ?? id;
  // 被风控了
  const { illegal } = usePermissions(id);
  const pageId = useGetPageId();
  const block = usePickBlock(id, ['subNodes', 'data', 'hidden'], ['format']);

  const cardFormat = block?.data.format?.cardFormat;
  const hidden = block?.hidden;
  const inColumn = isInColumnList(blockId, pageId);

  if (hidden) return null;
  return (
    <BlockDrop
      id={blockId}
      key={blockId}
      className={cx(
        'my-1',
        illegal && 'opacity-30',
        cardFormat?.showType === 'large' ? (inColumn ? 'w-full' : 'w-fit') : ''
      )}
      horizontal={root}
      data-draggable
    >
      <RawPage id={id} root={root} ownerBlockId={ownerBlockId} />
    </BlockDrop>
  );
};
