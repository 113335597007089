import type { PDFAnnotationDTO, PDFAnnotationType } from '@next-space/fe-api-idl';

import type { PDFAnnotation } from '../type';
import { AnnotationType } from '../type';

export const convertAnnotation = (annotation: PDFAnnotation): PDFAnnotationDTO => {
  if (annotation.type === AnnotationType.HIGHLIGHT) {
    return {
      pageNumber: annotation.pageNumber,
      type: annotation.type as unknown as PDFAnnotationType,
      color: annotation.color,
      pdfRects: annotation.pdfRects,
      text: annotation.text,
    };
  }
  return {
    pageNumber: annotation.pageNumber,
    type: annotation.type as unknown as PDFAnnotationType,
    color: annotation.color,
    pdfRects: annotation.pdfRects,
    path: annotation.path,
    text: annotation.text,
    width: annotation.width,
    height: annotation.height,
  };
};
