/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef } from 'react';

export interface DragInfo {
  type: 'beforestart' | 'start' | 'move' | 'end';
  startX: number;
  startY: number;
  x: number;
  y: number;
  stop: boolean;
  [key: string]: unknown;
}

export interface DragHandleProps<T extends HTMLElement> {
  children: (args: { ref: React.RefObject<T> }) => void;
  disabled?: boolean;
  touchSlorp?: number /* default: 5 */;
  onDragStart?: (info: DragInfo, ref: React.RefObject<T>) => void;
  onDragMove?: (info: DragInfo, ref: React.RefObject<T>) => void;
  onDragEnd?: (info: DragInfo, ref: React.RefObject<T>) => void;
}

export function DragHandle<T extends HTMLElement = HTMLElement>(props: DragHandleProps<T>) {
  const ref = useRef<T>(null);
  const propsRef = useRef<DragHandleProps<T>>();
  propsRef.current = props;
  useEffect(() => {
    const element = ref.current!;

    let dragInfo: DragInfo | null = null;
    element.addEventListener('pointerdown', (event) => {
      if (propsRef.current!.disabled) return;
      if (dragInfo !== null) return;

      dragInfo = {
        type: 'beforestart',
        startX: event.clientX,
        startY: event.clientY,
        x: event.clientX,
        y: event.clientY,
        stop: false,
      };

      const cleanup = () => {
        if (dragInfo == null) return;
        document.removeEventListener('pointermove', handlePointerMove);
        document.removeEventListener('pointerup', handlePointerUp);
        dragInfo = null;
      };

      const handlePointerMove = (event: PointerEvent) => {
        if (dragInfo === null) return cleanup();
        dragInfo.x = event.clientX;
        dragInfo.y = event.clientY;

        if (dragInfo.type === 'beforestart') {
          if (
            Math.max(
              Math.abs(dragInfo.x - dragInfo.startX),
              Math.abs(dragInfo.y - dragInfo.startY)
            ) >= (propsRef.current?.touchSlorp ?? 5)
          ) {
            dragInfo.type = 'start';
            propsRef.current!.onDragStart?.(dragInfo, ref);
            if (dragInfo.stop) return cleanup();
          } else {
            return;
          }
        }

        dragInfo.type = 'move';
        propsRef.current!.onDragMove?.(dragInfo, ref);
        event.preventDefault();
      };

      const handlePointerUp = (event: PointerEvent) => {
        if (dragInfo === null) return cleanup();

        if (event.button !== 0) return;
        if (dragInfo.type === 'beforestart') {
          setTimeout(() => {
            event.target!.dispatchEvent(
              new MouseEvent('click', {
                button: event.button,
                clientX: event.clientX,
                clientY: event.clientY,
              })
            );
          });
          return cleanup();
        }

        dragInfo.type = 'end';
        dragInfo.x = event.clientX;
        dragInfo.y = event.clientY;
        propsRef.current!.onDragEnd?.(dragInfo, ref);
        if (dragInfo.stop) return cleanup();

        event.preventDefault();
        return cleanup();
      };

      event.preventDefault();
      document.addEventListener('pointermove', handlePointerMove);
      document.addEventListener('pointerup', handlePointerUp);
    });
  }, []);
  return <>{props.children({ ref })}</>;
}
