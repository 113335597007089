import { PermissionRole } from '@next-space/fe-api-idl';
import type { Store } from '@reduxjs/toolkit';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { getUpdatedAtAndUpdatedBy } from 'src/hooks/user/get-updated-user';
import { isCollection, isPageLike } from 'src/utils/block-type-utils';

import { UPDATE_BLOCK } from '../../actions';
import { dispatch as dispatch0, getState as getState0 } from '../../store';
import type { NextBlock } from '../../types';

export const updateTime = (
  uuid: string,
  ignoreOp = false,
  store?: Store<ReturnType<typeof getState0>>
) => {
  const getState = store ? store.getState.bind(store) : getState0;
  const dispatch = store ? store.dispatch.bind(store) : dispatch0;

  const { blocks } = getState();
  const block = blocks[uuid];
  if (!block) return;

  const curr: NextBlock | undefined = block;
  const pageId = getOwnerPage(curr.uuid);
  const isCollectionPage = isCollection(blocks[pageId ?? '']?.type);

  /**
   * 参照notion：
   * 内嵌多维表更新，所在文档页面不更新，只会更新内嵌多维表块的时间
   */
  if (!isPageLike(block.type) || isCollectionPage) {
    const page = blocks[pageId ?? ''];

    if (page && getPermissions(page.uuid).role !== PermissionRole.READER) {
      dispatch(
        UPDATE_BLOCK({
          uuid: page.uuid,
          patch: getUpdatedAtAndUpdatedBy(),
          ignoreOp,
        })
      );
    }
  }

  dispatch(
    UPDATE_BLOCK({
      uuid,
      patch: getUpdatedAtAndUpdatedBy(),
      ignoreOp,
    })
  );
};
