import { Button } from '@flowus/common/components';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import { Regex } from '@flowus/login/utils';
import CryptoJS from 'crypto-js';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from 'src/common/components/input';
import { useUpdateUserSetting } from '../helper';

export const useOpenAccountPin = () => {
  const openModal = useOpenModal();
  const { handleSubmit, register } = useForm<{ pin: string }>();
  const updateUserSetting = useUpdateUserSetting();
  return useCallback(() => {
    openModal.modal({
      content: (_) => (
        <form
          className="p-5 space-y-5 text-t1-medium w-72"
          onSubmit={handleSubmit(async (e) => {
            if (!Regex.VerifyCode.test(e.pin)) {
              message.error('PIN 码格式错误');
              return;
            }
            await updateUserSetting({
              pin: CryptoJS.MD5(e.pin).toString(),
            });
            message.success('设置成功');
            _.onCloseModal();
          })}
        >
          <div>设置 PIN 码</div>
          <FormInput
            inputProps={register('pin', { required: true })}
            autoFocus
            className="h-8 textSecurity"
            maxLength={6}
            placeholder="输入 6 位数字"
          />
          <Button colorType="active">完成</Button>
        </form>
      ),
    });
  }, [handleSubmit, openModal, register, updateUserSetting]);
};
