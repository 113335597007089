import { cx } from '@flowus/common/cx';
import type { AggregationAction } from '@next-space/fe-api-idl';
import type { VirtualElement } from '@popperjs/core';
import type { FC } from 'react';
import { useCallback } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useViewParentId } from 'src/hooks/collection-view/use-collection-view';
import { useReadonly } from 'src/hooks/page';
import { ICON_MAP } from '../../../const';
import { AggregationActionUiMap } from '../../footer/helper';
import { getRollupAggregations } from '../../footer/menu-item';
import { SelectPropertyList } from './select-property';
import { SelectRelation } from './select-relation';

interface RollupSettingProps {
  propertyId: string;
  collectionId: string;
  viewId?: string;
}

export const RollupSetting: FC<RollupSettingProps> = ({ viewId, collectionId, propertyId }) => {
  const openModal = useOpenModal();
  const viewParentId = useViewParentId(viewId) ?? collectionId;
  const collectionReadonly = useReadonly(viewParentId);
  const isLocked = useBlockLocked(viewParentId);
  const readonly = collectionReadonly || isLocked;
  const updatePropertySchema = useUpdatePropertySchema();

  const { propertySchema, targetPropertySchema, relationPropertySchema } = usePropertySchema(
    collectionId,
    propertyId
  );
  const { targetProperty } = propertySchema ?? {};

  if (!propertySchema) return null;

  const selectRelation = (event: React.MouseEvent) => {
    if (readonly) return;
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <SelectRelation
            collectionId={collectionId}
            propertyId={propertyId}
            closeSelf={onCloseModal}
          />
        );
      },
    });
  };

  const selectProperty = (event: React.MouseEvent) => {
    if (readonly) return;
    if (!relationPropertySchema) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <SelectPropertyList
            collectionId={collectionId}
            propertyId={propertyId}
            closeSelf={onCloseModal}
          />
        );
      },
    });
  };

  const selectAggregationType = (event: React.MouseEvent) => {
    if (readonly) return;

    if (!relationPropertySchema?.collectionId || !targetProperty) return;

    const allAggregations = getRollupAggregations(
      relationPropertySchema.collectionId,
      targetProperty,
      targetPropertySchema?.type
    );
    const items = allAggregations.map((item) => ({
      type: ListItemType.OPERABLE_BLOCK_TEXT,
      data: item,
    }));

    const handleClick = (aggregation: AggregationAction) => {
      updatePropertySchema(collectionId, propertyId, {
        ...propertySchema,
        showAs: undefined,
        aggregation,
      });
    };

    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: ({ onCloseModal }) => {
        return (
          <ListView
            className="w-[200px] py-2 next-modal-scroll"
            items={items}
            onItemClick={(item) => {
              handleClick(item.data.type);
              onCloseModal();
            }}
          />
        );
      },
    });
  };

  return (
    <div className="flex flex-col w-[240px] next-modal">
      <div className="flex flex-col mb-[5px]">
        <div className="text-t2 text-grey3 mb-[4px] mx-4 mt-2.5">关联</div>
        <div
          onClick={selectRelation}
          className="flex items-center justify-between h-10 px-2 mx-2 rounded cursor-pointer active-bg animate-hover text-ellipsis"
        >
          <div className="flex min-w-0 items-center">
            {relationPropertySchema && (
              <Icon name={ICON_MAP[relationPropertySchema.type]} size="middle" />
            )}
            <span className="flex-1 min-w-0 overflow-hidden text-black whitespace-nowrap overflow-ellipsis text-t2 ml-2">
              {relationPropertySchema?.name ?? '请选择'}
            </span>
          </div>
          {!readonly && <Icon name="IcMenuNext" size="middle" className="text-grey4 rotate-90" />}
        </div>
      </div>

      <div className={'flex flex-col mb-[5px]'}>
        <div className="text-t2 text-grey3 mb-[4px] mx-4 mt-2.5">汇总属性</div>
        <div
          onClick={selectProperty}
          className={cx(
            'flex items-center justify-between h-10 px-2 mx-2 rounded cursor-pointer text-ellipsis',
            !relationPropertySchema ? 'opacity-30' : 'animate-hover active-bg'
          )}
        >
          <div className="flex min-w-0 items-center">
            {targetPropertySchema && (
              <Icon name={ICON_MAP[targetPropertySchema.type]} size="middle" />
            )}
            <span className="flex-1 min-w-0 overflow-hidden text-black whitespace-nowrap overflow-ellipsis text-t2 ml-2">
              {targetPropertySchema?.name ?? '请选择'}
            </span>
          </div>
          {!readonly && <Icon name="IcMenuNext" size="middle" className="text-grey4 rotate-90" />}
        </div>
      </div>

      <div className={cx('flex flex-col mb-[5px]')}>
        <div className="text-t2 text-grey3 mb-[4px] mx-4 mt-2.5">汇总方式</div>
        <div
          onClick={selectAggregationType}
          className={cx(
            'flex items-center justify-between h-10 px-2 mx-2 rounded cursor-pointer text-ellipsis',
            !relationPropertySchema ? 'opacity-30' : 'animate-hover active-bg'
          )}
        >
          <div className="flex min-w-0 items-center">
            <span className="flex-1 min-w-0 overflow-hidden text-black whitespace-nowrap overflow-ellipsis text-t2">
              {propertySchema.aggregation
                ? AggregationActionUiMap[propertySchema.aggregation]
                : '显示 原始值'}
            </span>
          </div>
          {!readonly && <Icon name="IcMenuNext" size="middle" className="text-grey4 rotate-90" />}
        </div>
      </div>
    </div>
  );
};

export const useOpenRollupSetting = (collectionId: string) => {
  const openModal = useOpenModal();

  const openRollupSetting = (
    event: React.MouseEvent,
    propertyId: string,
    viewId: string,
    popcorn?: VirtualElement
  ) => {
    event.stopPropagation();

    openModal.dropdown({
      popcorn: popcorn ?? event.currentTarget,
      placement: 'bottom',
      content: () => (
        <RollupSetting collectionId={collectionId} propertyId={propertyId} viewId={viewId} />
      ),
    });
  };

  return useCallback(openRollupSetting, [collectionId, openModal]);
};
