import { cx } from '@flowus/common/cx';
import {
  BlockStatus,
  BlockType,
  CollectionSchemaType,
  PermissionRole,
  TextType,
} from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PersonValueView } from 'src/bitable/table-view/cell/person';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useOpenModal } from 'src/common/components/next-modal';
import { publicLink } from 'src/common/const';
import { request } from 'src/common/request';
import { formatTimestamp } from 'src/common/utils/formatter';
import { useSharePanel } from 'src/components/share/components/use-share-panel';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useCollectionViewId } from 'src/hooks/collection-view/use-collection-view-id';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { usePageTotalCount } from 'src/hooks/page/use-page-word-count';
import { useSubPages } from 'src/hooks/page/use-sub-pages';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { CollapseCount } from 'src/mind-map/component/collapse-count';
import { Modals } from 'src/modals';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useFineSelector } from 'src/services/store-context/use-fine-context-selector';
import { isCollection, isPageLike } from 'src/utils/block-type-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { TestIds } from 'src/utils/qa-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { PageHistoryModal } from '../../header/page-history';
import { ProIcon } from '../../header/pro_icon';
import { PageUserList } from './page-user-list';
import { Row } from './row';
import { RowTitle } from './row-title';

/** 页面统计信息 */
export const PageInfo: FC = () => {
  const pageId = useGetPageId();
  const [historyCount, setHistoryCount] = useState<number>();
  const blockType = useFineSelector((state) => state.blocks[pageId]?.type);
  let DataPanel: FC<PanelProps> = PageDataPanel;
  if (isCollection(blockType)) {
    DataPanel = CollectionDataPanel;
  } else if (blockType === BlockType.FOLDER) {
    DataPanel = FolderDataPanel;
  }
  const onHistoryCount = useCallback((count) => {
    setHistoryCount(count);
  }, []);
  return (
    <div className="w-full overflow-auto">
      <BasicDataPanel pageId={pageId} historyCount={historyCount} />
      <div className="w-full bg-grey6 h-px my-2" />
      <DataPanel
        pageId={pageId}
        showHelpIcon={!__BUILD_IN__}
        onClickHelpIcon={() => {
          window.open(publicLink.dataPanel);
        }}
      />
      <div className="w-full bg-grey6 h-px my-2" />
      {!(__BUILD_IN__ || __PRIVATE__) && (
        <ShareDataPanel
          pageId={pageId}
          showHelpIcon={true}
          onHistoryCount={onHistoryCount}
          onClickHelpIcon={() => {
            window.open(publicLink.shareDataPanel);
          }}
        />
      )}

      <CollaborateInfo
        pageId={pageId}
        showHelpIcon={__BUILD_IN__}
        onClickHelpIcon={() => {
          window.open(publicLink.collaborateInfo);
        }}
      />
    </div>
  );
};

interface PanelProps {
  pageId: string;
  showHelpIcon?: boolean;
  onClickHelpIcon?: () => void;
  historyCount?: number;
  onHistoryCount?: (count: number) => void;
}
/** 基数数据 */
const BasicDataPanel: FC<PanelProps> = (props) => {
  const [expand, setExpand] = useState(true);
  const block = usePickBlock(props.pageId, ['createdBy', 'createdAt', 'updatedBy', 'updatedAt']);
  const openModal = useOpenModal();
  return (
    <div className="w-full px-3">
      <RowTitle
        title="基本信息"
        expand={expand}
        onExpand={setExpand}
        showHelpIcon={props.showHelpIcon}
        onClickHelpIcon={props.onClickHelpIcon}
      />
      {expand && (
        <>
          <Row
            leftNode="创建者"
            rightNode={
              <PersonValueView userId={block?.createdBy ?? ''} className="text-t2 !mx-0 !px-0" />
            }
          />
          <Row leftNode="创建时间" rightNode={formatTimestamp(block?.createdAt ?? 0)} />
          <Row
            leftNode="最后编辑者"
            rightNode={
              <PersonValueView
                userId={block?.updatedBy ?? ''}
                className="text-t2 !mx-0 !px-0"
                size="small"
              />
            }
          />
          <Row leftNode="最后编辑时间" rightNode={formatTimestamp(block?.updatedAt ?? 0)} />
          <Row
            leftNode="页面版本"
            rightNode={
              <div className="flex items-center">
                {props.historyCount === undefined ? (
                  <LoadingContainer className="w-5 h-5" size="middle" />
                ) : (
                  <span className="whitespace-pre-wrap">{`第${props.historyCount}版`}</span>
                )}
                <div className="ml-2 flex justify-end items-center h-10 text-t3 text-active_color w-full text-right underline underline-offset-2 ">
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      openModal.modal({
                        modalId: Modals.DOC_HISTORY_MODAL_ID,
                        content: () => {
                          return <PageHistoryModal docId={props.pageId} />;
                        },
                      });
                    }}
                  >
                    查看页面历史
                  </span>
                </div>
              </div>
            }
          />
        </>
      )}
    </div>
  );
};

/** 页面内容数据统计 */
const PageDataPanel: FC<PanelProps> = (props) => {
  const [expand, setExpand] = useState(true);
  const block = usePickBlock(props.pageId, ['subNodes']);
  const { wordCount, blockCount } = usePageTotalCount(props.pageId);
  const subPageCount = useSubPages(props.pageId).length;
  const fileStatistics = useMemo(() => {
    let fileCount = 0;
    let fileSize = 0;
    const loop = (id: string) => {
      const b = cache.blocks[id];
      if (isPageLike(b?.type)) return;
      if (b?.data.ossName && b?.type === BlockType.FILE) {
        fileCount++;
        fileSize += b?.data.size ?? 0;
      }
      b?.subNodes.forEach(loop);
    };
    block?.subNodes.forEach(loop);
    const fileSizeDesc = getReadableFileSizeString(fileSize);
    return `${fileCount}（共计 ${fileSizeDesc}）`;
  }, [block?.subNodes]);
  return (
    <div className="w-full px-3">
      <RowTitle
        title="页面内容数据统计"
        expand={expand}
        onExpand={setExpand}
        showHelpIcon={props.showHelpIcon}
        onClickHelpIcon={props.onClickHelpIcon}
      />
      {expand && (
        <>
          <Row leftNode="字数统计" rightNode={wordCount} />
          <Row leftNode="块数统计" rightNode={blockCount} />
          {[BlockType.MIND_MAPPING, BlockType.MIND_MAPPING_PAGE].includes(block?.type ?? 0) ? (
            <Row
              leftNode="节点数"
              rightNode={<CollapseCount blockId={props.pageId} mindMapId={props.pageId} />}
            />
          ) : (
            <Row leftNode="子页面数" rightNode={subPageCount} />
          )}
          <Row leftNode="文件数" rightNode={fileStatistics} />
        </>
      )}
    </div>
  );
};
const FolderDataPanel: FC<PanelProps> = (props) => {
  const [expand, setExpand] = useState(true);
  const block = usePickBlock(props.pageId, ['subNodes']);
  const { blockCount } = usePageTotalCount(props.pageId);
  const folderCount = useMemo(() => {
    return block?.subNodes.filter((subId) => cache.blocks[subId]?.type === BlockType.FOLDER).length;
  }, [block?.subNodes]);
  const fileStatistics = useMemo(() => {
    let fileCount = 0;
    let fileSize = 0;
    block?.subNodes.forEach((subId) => {
      const subBlock = cache.blocks[subId];

      if (subBlock?.data.ossName && subBlock?.type === BlockType.FILE) {
        fileCount++;
        fileSize += subBlock?.data.size ?? 0;
      }
    });
    const fileSizeDesc = getReadableFileSizeString(fileSize);
    return `${fileCount}（共计 ${fileSizeDesc}）`;
  }, [block?.subNodes]);
  return (
    <div className="w-full px-3">
      <RowTitle
        title="页面内容数据统计"
        expand={expand}
        onExpand={setExpand}
        showHelpIcon={props.showHelpIcon}
        onClickHelpIcon={props.onClickHelpIcon}
      />
      {expand && (
        <>
          <Row leftNode="块数统计" rightNode={blockCount} />
          <Row leftNode="文件夹" rightNode={folderCount} />
          <Row leftNode="文件数" rightNode={fileStatistics} />
        </>
      )}
    </div>
  );
};
/** 多维表数据统计 */
const CollectionDataPanel: FC<PanelProps> = (props) => {
  const [expand, setExpand] = useState(true);
  const block = usePickBlock(
    props.pageId,
    ['subNodes', 'views', 'data'],
    ['collectionPageProperties']
  );
  const { blockCount } = usePageTotalCount(props.pageId);
  const [viewId] = useCollectionViewId(props.pageId, true);
  const [properties = []] = useProperties(viewId);
  const fileStatistics = useFineSelector((state) => {
    let count = 0;
    const block = state.blocks[props.pageId];
    properties.forEach((p) => {
      if (p.type === CollectionSchemaType.FILE) {
        block?.subNodes.forEach((recordId) => {
          const record = state.blocks[recordId];
          const segments = record?.data.collectionProperties?.[p.property];
          count += segments?.filter((s) => s.type === TextType.URL && s.url).length ?? 0;
        });
      }
    });
    return count;
  });
  return (
    <div className="w-full px-3">
      <RowTitle
        title="页面内容数据统计"
        expand={expand}
        onExpand={setExpand}
        showHelpIcon={props.showHelpIcon}
        onClickHelpIcon={props.onClickHelpIcon}
      />
      {expand && (
        <>
          <Row leftNode="块数统计" rightNode={blockCount} />
          <Row leftNode="多维表记录数" rightNode={block?.subNodes.length ?? 0} />
          <Row leftNode="视图数" rightNode={block?.views?.length ?? 0} />
          <Row leftNode="多维表属性列" rightNode={properties.length} />
          <Row leftNode="文件媒体列文件数" rightNode={fileStatistics} />
        </>
      )}
    </div>
  );
};

/** 分享内容数据统计 */
const ShareDataPanel: FC<PanelProps> = (props) => {
  const isInRight = useIsInRight();
  const [shareExpand, setShareExpand] = useState(true);
  const [loading, setLoading] = useState(true);

  const {
    shared,
    password,
    publicRole,
    allowSelectionCopy,
    allowDuplicate,
    allowDownload,
    allowSeo,
    allowSubscribe,
  } = usePermissions(props.pageId);

  const [pageInfo, setPageInfo] = useState<Awaited<ReturnType<typeof request.editor.getDocInfo>>>();
  const canComment = publicRole === PermissionRole.COMMENTER;
  const shareStatus = [
    shared ? '已开启分享' : '未开启分享',
    password ? '已设置密码访问' : '密码访问未开启',
  ].join(' | ');
  const subscribeStatus = allowSubscribe ? '允许订阅' : '未允许订阅';
  const seoStatus = allowSeo ? '已开启搜索引擎推广' : '未开启搜索引擎推广';

  const { onHistoryCount } = props;
  useEffect(() => {
    void request.editor
      .getDocInfo(props.pageId)
      .then((res) => {
        setPageInfo(res);
        onHistoryCount?.(res.historyNum);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [onHistoryCount, props.pageId, shared]);

  useEffect(() => {
    if (!shared) return;
    void getSpaceGuests(getCurrentSpaceId());
  }, [shared]);

  const discussionCount = useObservableStore(
    (state) => {
      let numOpen = 0;
      const loop = (blockId: string, countPage?: boolean) => {
        const block = state.blocks[blockId];
        if (isPageLike(block?.type) && !countPage) {
          return;
        }
        block?.discussions?.forEach((discussionId) => {
          const discussion = state.discussions[discussionId];
          if (discussion != null && !discussion.resolved) {
            for (const commentId of discussion.comments) {
              const comment = state.comments[commentId];
              if (comment != null && comment.status === BlockStatus.NORMAL) {
                numOpen += 1;
              }
            }
          }
        });
        block?.subNodes.forEach((subId) => loop(subId, false));
      };
      loop(props.pageId, true);
      return numOpen;
    },
    [props.pageId]
  );

  const openShareSetting = (openInvitePanel?: boolean) => {
    const el = querySelectorFromMainContent(`[data-test-id=${TestIds.driveFileShare}]`, isInRight);
    if (el instanceof HTMLElement) {
      el.click();
    }
    if (openInvitePanel) {
      setTimeout(() => {
        const el = document.querySelector('.invite-bar');
        if (el instanceof HTMLElement) {
          el.click();
        }
      }, 0);
    }
  };
  if (!shared) return null;

  return (
    <>
      <div className="w-full px-3 relative">
        <RowTitle
          title="页面分享数据统计"
          expand={shareExpand}
          onExpand={setShareExpand}
          showHelpIcon={props.showHelpIcon}
          onClickHelpIcon={props.onClickHelpIcon}
        />
        {shareExpand && (
          <>
            <Row leftNode="当前订阅人数" rightNode={pageInfo?.currentSubscribedNum} />
            <Row leftNode="累计订阅人数" rightNode={pageInfo?.totalSubscribedNum} />
            <Row leftNode="累积查看次数" rightNode={pageInfo?.totalViewNum} />
            <Row leftNode="累计访问人次" rightNode={pageInfo?.userViewNum} />
            <Row leftNode="累计拷贝副本次数" rightNode={pageInfo?.copyNum} />
            <Row leftNode="累计评论次数" rightNode={discussionCount} />
            <div className="hidden border-grey5 border h-8 items-center justify-center rounded">
              <span className="text-t2">更多详细数据</span>
              <div className="relative">
                <ProIcon className="left-1.5 right-[undefined] top-[-8px]" />
              </div>
            </div>
            <Row leftNode="分享状态" rightNode={shareStatus} />
            <Row leftNode="订阅状态" rightNode={subscribeStatus} />
            <Row leftNode="推广状态" rightNode={seoStatus} />
            {shared && (
              <>
                <Row
                  leftNode="分享访问权限"
                  rightNode={['任何获得分享链接的人都', canComment ? '可评论' : '可查看'].join(' ')}
                />
                {allowSelectionCopy && <Row leftNode="" rightNode={`已允许复制内容`} />}
                {allowDuplicate && <Row leftNode="" rightNode={`已允许拷贝副本`} />}
                {allowDownload && <Row leftNode="" rightNode={`已允许下载和打印`} />}
              </>
            )}
            <div className="flex justify-end items-center h-10 text-t3 text-active_color w-full text-right underline underline-offset-2 ">
              <span
                className="cursor-pointer"
                onClick={() => {
                  openShareSetting();
                }}
              >
                修改分享设置
              </span>
            </div>
            {loading && <LoadingContainer className="absolute top-0" />}
          </>
        )}
      </div>
      <div className="w-full bg-grey6 h-px my-2" />
    </>
  );
};
const CollaborateInfo: FC<PanelProps> = (props) => {
  const [collaborateExpand, setCollaborateExpand] = useState(true);
  const isInRight = useIsInRight();
  const share = useSharePanel(props.pageId);
  const openShareSetting = (openInvitePanel?: boolean) => {
    const el = querySelectorFromMainContent(`[data-test-id=${TestIds.driveFileShare}]`, isInRight);
    if (el instanceof HTMLElement) {
      el.click();
    }
    if (openInvitePanel) {
      setTimeout(() => {
        const el = document.querySelector('.invite-bar');
        if (el instanceof HTMLElement) {
          el.click();
        }
      }, 0);
    }
  };
  const hasGuest = share?.permissions.some((p) => p.isGuest);
  const hasSpaceUser = share?.permissions.some((p) => !p.isGuest);
  return (
    <div className="w-full px-3">
      <RowTitle
        title="协作信息"
        expand={collaborateExpand}
        onExpand={setCollaborateExpand}
        showHelpIcon={props.showHelpIcon}
        onClickHelpIcon={props.onClickHelpIcon}
      />
      {collaborateExpand && (
        <>
          <div
            className={cx('flex items-start', {
              'items-baseline': !hasSpaceUser,
            })}
          >
            <span className="shrink-0 text-t3 text-grey3">空间协作者</span>
            <div className="ml-2">
              {hasSpaceUser ? (
                <>
                  <PageUserList
                    showType="onlySpaceUser"
                    filterUserNoInSpace={true}
                    className="-mt-2"
                    permissions={share?.permissions ?? []}
                  />
                  <div className="ml-1 flex items-center h-10 text-t3 text-active_color w-full text-right underline underline-offset-2">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        openShareSetting();
                      }}
                    >
                      权限设置
                    </span>
                  </div>
                </>
              ) : (
                <span className="text-t3 text-grey3 -mt-4">无</span>
              )}
            </div>
          </div>
          <div
            className={cx('flex items-start', {
              'items-baseline': !hasGuest,
            })}
          >
            <span className="shrink-0 text-t3 text-grey3">外部协作者</span>
            <div className="ml-2">
              {hasGuest ? (
                <PageUserList
                  showType="onlyGuest"
                  filterUserNoInSpace={true}
                  className="-mt-2"
                  permissions={share?.permissions ?? []}
                />
              ) : (
                <span className="text-t3 text-grey3 -mt-4">无</span>
              )}
              <div className="ml-1 flex items-center h-10 text-t3 text-active_color w-full text-right underline underline-offset-2">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    openShareSetting(true);
                  }}
                >
                  邀请外部协作者
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
