import type { FC } from 'react';
import { Switch } from 'src/common/components/switch';
import type { SortSwitchProps } from './sort-switch';
import { SortSwitch } from './sort-switch';
import { cx } from '@flowus/common/cx';

interface Props extends SortSwitchProps {
  className?: string;
  showFilter?: boolean;
  onShowFilter: () => void;
  showCurrentPageSwitch: boolean; // 是否显示开关，分享页的根页面不显示
  searchInCurrentPage: boolean;
  onClickSearchInCurrentPage: (open: boolean) => void;
}
/**
 * 搜索结果的header
 */
export const SearchResultHeader: FC<Props> = (props) => {
  return (
    <div className={cx('mx-[15px] flex items-center justify-between text-t2', props.className)}>
      <div className="flex items-center">
        <div className="text-grey3">排序:</div>
        <SortSwitch sortType={props.sortType} onSwitchType={props.onSwitchType} />
      </div>
      {props.showCurrentPageSwitch && (
        <div className="flex items-center">
          <div className="text-t2 text-grey3">在当前页面搜索</div>
          <Switch
            bgClassName="ml-2"
            open={props.searchInCurrentPage}
            onSwitch={props.onClickSearchInCurrentPage}
          />
        </div>
      )}
      {/* {!props.showFilter && props.type === 'normal' && (
        <div
          className="cursor-pointer flex px-2 py-[2px] rounded-sm hover:bg-active_color/10"
          onClick={props.onShowFilter}
        >
          <Icon className="mr-1 text-active_color" name="IcFilter" size="middle" />
          <div className="text-active_color">筛选</div>
        </div>
      )} */}
    </div>
  );
};
