import { cx } from '@flowus/common/cx';
import type { CommentDTO } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useCloseModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { RichTextDiff } from 'src/components/rich-text-diff';
import { TimeAgo } from 'src/components/time-ago';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { Modals } from 'src/modals';
import { cache } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { sleep } from 'src/utils/async-utils';
import { querySelectorFromMainContent } from 'src/utils/dom';
import { useGetPageId } from 'src/utils/getPageId';
import { lookupPageId } from 'src/utils/lookup-page-id';
import { setPageDiscussionExpand } from 'src/views/comments/page-discussions';
import { useJumpTop } from 'src/views/main/page-doc/use-jump-top';
import { PageScene, usePageScene } from 'src/views/main/scene-context';

export const CommentChange = (props: {
  discussionId: string;
  from: CommentDTO | undefined;
  to: CommentDTO | undefined;
  className?: string;
}) => {
  const currPageId = useGetPageId();
  const history = useHistory();
  const jumpTop = useJumpTop();
  const { discussionId, from, to } = props;
  const comment = to ?? from;
  const userName = useUserName(comment?.updatedBy ?? '');
  const discussion = useObservableStore((state) => state.discussions[discussionId], [discussionId]);
  const pageScene = usePageScene();
  const closeModal = useCloseModal();

  if (discussion == null) return null;
  if (comment == null) return null;
  return (
    <div className={cx('flex', props.className)}>
      <div
        className="flex-1 ml-1  animate-hover"
        onClick={() => {
          const blockId = discussion.parentId;
          const pageId = lookupPageId(blockId);
          const isPageComment = blockId === pageId;
          const openComment = () => {
            let count = 0;
            if (cache.blocks[blockId]?.status !== BlockStatus.NORMAL) {
              return;
            }
            if (isPageComment) {
              // 如果是页面评论,需要先展开
              setPageDiscussionExpand(pageId, true);
            }
            const timer = setInterval(() => {
              let node: Element | null;
              if (isPageComment) {
                // 如果是页面评论
                node = querySelectorFromMainContent(`[data-comment-id="${comment.uuid}"]`);
              } else {
                node = querySelectorFromMainContent(`[data-comment-block-id="${blockId}"]`);
              }

              count++; // 超过一定次数不成功就取消
              if ((node && node instanceof HTMLElement) || count > 15) {
                if (isPageComment) {
                  const commentTextEl = node?.querySelector('.comment-text') as HTMLElement;
                  if (commentTextEl) {
                    commentTextEl?.setAttribute(
                      'style',
                      'border-color:var(--active_color);opacity: 100'
                    );
                    commentTextEl?.classList.add('animate__flash');
                    void sleep(1500).then(() => {
                      commentTextEl.classList.remove('animate__flash');
                      commentTextEl.removeAttribute('style');
                    });
                  }
                  node?.scrollIntoView();
                  setTimeout(() => {
                    commentTextEl?.removeAttribute('style');
                  }, 5000);
                } else {
                  (node as HTMLElement)?.click();
                }
                clearInterval(timer);
              }
            }, 500);
            const unlisten = history.listen(() => {
              unlisten();
              clearInterval(timer);
            });
          };
          if (pageId != null && pageId !== currPageId) {
            history.push(`/${pageId}#${blockId}`);
            openComment();
          } else {
            if (isPageComment) {
              openComment();
            } else {
              void jumpTop(discussion.parentId, {
                callback: (animate) => {
                  if (animate === 'animateScrollEnd') {
                    openComment();
                  }
                },
              });
            }
          }
          if (pageScene === PageScene.NOTIFICATION) {
            closeModal(Modals.MESSAGE_NOTIFICATION);
          }
        }}
      >
        <div className="mb-1.5">
          <span className="text-t2 text-grey3">{userName}</span>
          <span className="ml-1.5 text-t4 text-grey4">
            <Tooltip popup={dayjs(comment.updatedAt).format(DATE_TIME_FORMAT)} className="inline">
              <TimeAgo timestamp={comment.updatedAt ?? Date.now()} />
            </Tooltip>
          </span>
        </div>
        <div>
          <RichTextDiff
            className="whitespace-pre-wrap"
            segmentsA={from?.text}
            segmentsB={to?.text}
          />
        </div>
      </div>
    </div>
  );
};
