import { completeGlobalModalQueue } from '@flowus/common/activity-queue';
import { sleep } from '@flowus/common/async';
import { cx } from '@flowus/common/cx';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import {
  BlockType,
  CollectionViewType,
  PermissionRole,
  PermissionType,
} from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/components/button';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { makeInitialCollection } from 'src/editor/component/menu-list';
import { Import } from 'src/editor/editor/uikit/import';
import { VITE_CDN_HOST } from 'src/env';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useCreateDocPage } from 'src/hooks/page';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { transaction } from 'src/hooks/use-transaction';
import { getCurrentUser } from 'src/hooks/user';
import { useAddSubNode } from 'src/mind-map/hook/use-add-sub-node';
import { Modals } from 'src/modals';
import { addBlock } from 'src/redux/managers/block/add';
import { TocType } from 'src/views/main/aside/toc/types';
import type { ResourceCenter } from 'src/views/main/resource-center';

let ResourceCenter0: typeof ResourceCenter;
export const antiCycleSet_ResourceCenter = (component: typeof ResourceCenter) => {
  ResourceCenter0 = component;
};

export const NewPageGuide: FC<{ closeSelf: () => void; type: TocType }> = ({ closeSelf, type }) => {
  const [pageType, setPageType] = useState<string | undefined>(PageList[0]?.type);
  const openModal = useOpenModal();
  const updateTask = useUpdateTask();
  const addSubNode = useAddSubNode();
  const createPage = useCreateDocPage();
  const history = useHistory();

  const handleClick = () => {
    const item = PageList.find((item) => item.type === pageType);
    if (!item) {
      closeSelf();
      return;
    }

    const permissions: PermissionDTO[] = [];
    if (type === TocType.PRIVATE) {
      permissions.push({
        role: PermissionRole.EDITOR,
        type: PermissionType.USER,
        userId: getCurrentUser().uuid,
      });
    }
    if (type === TocType.SPACE) {
      permissions.push({
        role: PermissionRole.EDITOR,
        type: PermissionType.SPACE,
      });
    }

    let pageId: string | undefined = '';
    switch (pageType) {
      case 'template': {
        pageId = createPage(BlockType.PAGE, { parentId: getCurrentSpaceId(), first: true }, type);
        if (pageId) {
          history.push(`/${pageId}`);
        }

        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: true,
        });

        openModal.modal({
          modalId: Modals.TEMPLATE_CENTER,
          content: () => <ResourceCenter0 onlyShowTab="template" from="page" />,
        });

        closeSelf();
        return;
      }
      case 'import': {
        pageId = createPage(BlockType.PAGE, { parentId: getCurrentSpaceId(), first: true }, type);
        if (pageId) {
          history.push(`/${pageId}`);
        }

        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: true,
        });

        openModal.modal({
          content: () => <Import pageId={pageId} from="page" />,
        });

        closeSelf();
        return;
      }
      case 'table':
      case 'board':
      case 'gallery':
      case 'list':
      case 'timeline':
      case 'calendar':
      case 'form': {
        transaction(() => {
          pageId = addBlock(
            { type: BlockType.COLLECTION_VIEW_PAGE, permissions },
            { parentId: getCurrentSpaceId(), first: true },
            false,
            true
          );
          makeInitialCollection(pageId, item.viewType);
        });

        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: pageType === 'list' || pageType === 'board' || pageType === 'gallery',
        });
        if (pageType === 'table') {
          void updateTask(ActivityIds.CREATE_BITABLE_EXPLORE, ActivitiesListType.basicList, {
            isFirstTask: true,
          });
        } else {
          if (item.taskId) {
            void updateTask(item.taskId, ActivitiesListType.basicList, { isFirstTask: true });
          }
        }
        break;
      }
      case 'folder': {
        pageId = createPage(BlockType.FOLDER, { parentId: getCurrentSpaceId(), first: true }, type);
        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: true,
        });
        break;
      }
      case 'mindmap': {
        transaction(() => {
          pageId = createPage(
            BlockType.MIND_MAPPING_PAGE,
            { parentId: getCurrentSpaceId(), first: true },
            type
          );

          if (pageId) {
            addSubNode({ parentId: pageId, last: true }, pageId);
            addSubNode({ parentId: pageId, last: true }, pageId);
            addSubNode({ parentId: pageId, last: true }, pageId);
          }
        });
        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: true,
        });
        break;
      }
      case 'doc': {
        pageId = createPage(BlockType.PAGE, { parentId: getCurrentSpaceId(), first: true }, type);
        void updateTask(ActivityIds.CREATE_NEW_PAGE, ActivitiesListType.basicList, {
          isFirstTask: true,
        });
        break;
      }
      default:
        break;
    }

    if (pageId) {
      void sleep(1000).then(() => history.push(`/${pageId}`));
    }
    closeSelf();
  };

  return (
    <div className="w-[960px]">
      <div className="flex items-center h-[80px] pl-[30px] border-b">
        <div className="flex flex-col">
          <h2 className="text-t1-medium flex items-center">页面类型</h2>
          <p className="text-t2 text-grey3 mt-0.5" hidden={__BUILD_IN__}>
            首次创建页面得 ⭐️+5 可累积抵现兑会员
          </p>
        </div>
      </div>

      <div className="py-2.5 min-h-[440px] flex ">
        <div className="w-[240px]">
          {PageList.map((item) => {
            const { title, icon, type } = item;

            return (
              <div className="px-2 h-10" key={title}>
                <div
                  onClick={() => setPageType(type)}
                  className={cx(
                    'flex items-center h-full rounded animate-hover-black3 pl-[42px] relative',
                    pageType === type && 'bg-black_006'
                  )}
                >
                  <Icon name={icon} size="middle" className="absolute left-5 top-2.5" />
                  <span className="text-t2-medium text-grey2">{title}</span>
                </div>
              </div>
            );
          })}
        </div>

        <div className="pt-4 px-[60px]">
          <img
            src={`${VITE_CDN_HOST}activity/page/${
              PageList.find((item) => item.type === pageType)?.type
            }.png`}
            className="w-[600px] h-[338px] border rounded-md"
          />

          <div className="flex items-center mt-4">
            <Button colorType="active" onClick={handleClick}>
              创建
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const useOpenNewPageGuide = () => {
  const openModal = useOpenModal();

  return useCallback(
    (type) => {
      openModal.modal({
        autoClose: false,
        closeAfterCallBack: async () => {
          await sleep(1000);
          completeGlobalModalQueue(Modals.CREATE_PAGE_TIP);
        },
        content: (_) => <NewPageGuide closeSelf={_.onCloseModal} type={type} />,
      });
    },
    [openModal]
  );
};

const PageList = [
  {
    icon: 'IcPageEmpty',
    title: '页面',
    type: 'doc',
    btn: '',
  },
  {
    icon: 'IcMindmap',
    title: '思维导图',
    type: 'mindmap',
    btn: '',
  },
  {
    icon: 'IcFolder',
    title: '文件夹',
    type: 'folder',
    btn: '',
  },
  {
    icon: 'MIcNaviImport',
    title: '导入',
    type: 'import',
    btn: '',
  },
  {
    icon: 'IcTable',
    title: '表格',
    viewType: CollectionViewType.TABLE,
    taskId: ActivityIds.CREATE_BITABLE_EXPLORE,
    type: 'table',
    btn: '',
  },
  {
    icon: 'IcBoard',
    title: '看板',
    viewType: CollectionViewType.BOARD,
    type: 'board',
    btn: '',
  },
  {
    icon: 'IcGallery',
    title: '画廊',
    viewType: CollectionViewType.GALLERY,
    type: 'gallery',
    btn: '',
  },
  {
    icon: 'IcList',
    title: '目录',
    viewType: CollectionViewType.LIST,
    type: 'list',
    btn: '',
  },
  {
    icon: 'IcTimeline',
    title: '时间轴',
    viewType: CollectionViewType.TIMELINE,
    taskId: ActivityIds.CREATE_TIMELINE_VIEW,
    type: 'timeline',
    btn: '',
  },
  {
    icon: 'IcCalendar',
    title: '日历',
    viewType: CollectionViewType.CALENDAR,
    taskId: ActivityIds.CREATE_CALENDAR_VIEW,
    type: 'calendar',
    btn: '',
  },
  {
    icon: 'IcForm',
    title: '收集表',
    viewType: CollectionViewType.FORM,
    taskId: ActivityIds.CREATE_FORM_VIEW,
    type: 'form',
    btn: '',
  },
  {
    icon: 'IcTemplate',
    title: '模板库',
    type: 'template',
    btn: '',
  },
] as {
  icon: IconName;
  title: string;
  type: string;
  btn: string;
  viewType?: CollectionViewType;
  taskId?: string;
}[];
