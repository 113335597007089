import { useCallback } from 'react';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { removeDuplicateWith } from 'src/utils/array-util';
import { sequence } from 'src/utils/async-utils';
import { getOwnerPage } from './use-get-owner-page';

type BackLinksType = Awaited<ReturnType<typeof request.editor.getDocBacklinks>>['results'];
const fetchers: Record<
  string,
  Promise<{ result: BackLinksType; pageCount: number } | undefined>
> = {};

export const useGetBackLink = () => {
  return useCallback(async (blockId: string, syncBlockId: string) => {
    let task = fetchers[blockId];
    if (!task) {
      task = sequence(async () => {
        const res = await request.editor.getDocBacklinks.raw(blockId);
        if (res.code === 200) {
          dispatch(
            blocksActions.update({ blocks: res.data.recordMap.blocks as Record<string, NextBlock> })
          );
          const result = removeDuplicateWith(res.data.results, 'pageId');
          // 由于双向链不包含 源同步块，所以这里要加上去
          result.unshift({
            pageId: getOwnerPage(syncBlockId),
            uuid: syncBlockId,
            spaceId: cache.blocks[syncBlockId]?.spaceId,
          });
          return {
            result,
            // @ts-ignore  total
            pageCount: Number(res.data.backlinkPageCount),
          };
        }
      });
    }
    return task;
  }, []);
};
