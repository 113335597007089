import { sleep } from '@flowus/common/async';
import { BlockType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { useEditorModelKey } from 'src/editor/editor/uikit/editable/helper';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { indentBlock } from 'src/redux/managers/block/indent';
import { outdentBlock } from 'src/redux/managers/block/outdent';
import { isLikeFoldBlockAndInFold, patchExpand } from 'src/redux/managers/ui/use-fold';
import { cache, dispatch } from 'src/redux/store';
import { isMindMap } from 'src/utils/block-type-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { usePageLazyListLoading } from 'src/views/main/page-doc/context';

export const useTabKey = (uuid?: string) => {
  const transaction = useTransaction();
  const editorKey = useEditorModelKey(uuid ?? '');
  const focusEditableAt = useFocusEditableByBlockId();
  const pageLazyListLoading = usePageLazyListLoading();

  const handleTab = (event: KeyboardEvent) => {
    if (!isHotkey(['Tab', 'Shift+Tab'])(event)) return;
    if (pageLazyListLoading) {
      message.loading({
        key: 'tab-key-page-loading',
        content: 'Tab功能请等待文档加载完毕',
        duration: 3000,
      });
      return;
    }

    const { blocks, ui } = cache;
    const { selectedBlocks } = ui;

    const ids = selectedBlocks.length ? selectedBlocksToIds(selectedBlocks) : uuid ? [uuid] : [];
    const isInMindMap = selectedBlocks.some((selectedBlock) => selectedBlock.mindMapId);
    const id = ids[0];

    if (!id) return;

    const block = blocks[id];
    if (!block) return;

    if ([BlockType.PAGE].includes(block.type) || isInMindMap) {
      return;
    }
    event.preventDefault();
    transaction(() => {
      if (event.shiftKey) {
        outdentBlock(ids);
      } else {
        indentBlock(ids);

        // 如果缩进后的 parent 是折叠列表, 则展开折叠列表
        const newBlock = cache.blocks[block.uuid];

        if (newBlock) {
          const newParent = blocks[newBlock.parentId];
          if (
            newParent?.type === BlockType.FOLD_LIST ||
            newParent?.type === BlockType.TOGGLE_HEADER ||
            isLikeFoldBlockAndInFold(newParent?.uuid)
          ) {
            patchExpand(newBlock.parentId, true);
          }
        }
      }

      if (uuid) {
        const ownerPageId = getOwnerPage(uuid);
        if (!ownerPageId) return;
        if (isMindMap(cache.blocks[ownerPageId]?.type)) return; // indent到内嵌思维导图下不需要focus光标
        const selection = getEditorModelByEditorKey(editorKey)?.selection;
        if (!selection) return;
        const { offset } = selection;
        void sleep(50).then(() => {
          dispatch(
            HISTORY_EFFECTS({
              init() {
                focusEditableAt(uuid, offset);
              },
              redo() {
                focusEditableAt(uuid, offset);
              },
              undo() {
                focusEditableAt(uuid, offset);
              },
            })
          );
        });
      }
    });
  };

  return useCallback(handleTab, [
    editorKey,
    focusEditableAt,
    pageLazyListLoading,
    transaction,
    uuid,
  ]);
};
