import isHotkey from 'is-hotkey';
import type { CSSProperties, FC } from 'react';
import type { RichTextEditProps } from 'src/editor/editor/uikit/editable';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { INLINE_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useIsInFixWidthContext } from 'src/mind-map/fix-width-context-provider';

export const MindMapRichText: FC<RichTextEditProps & { style?: CSSProperties }> = (props) => {
  const readonly = useReadonly(props.uuid);
  const inFixContext = useIsInFixWidthContext();
  const { uuid, ...rest } = props;
  const id = inFixContext ? `fixWidth${uuid}` : uuid;
  return (
    <RichTextEdit
      uuid={id}
      comments={true}
      keydownOption={INLINE_KEYDOWN_OPTION}
      readonly={readonly}
      onKeyDown={(e) => {
        if (isHotkey('Enter')(e)) {
          e.preventDefault();
        }
      }}
      {...rest}
    />
  );
};
