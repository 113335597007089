import { CollectionViewType } from '@next-space/fe-api-idl';
import { useDebounceEffect } from 'ahooks';
import { useCreateGroupProperty } from 'src/hooks/block/use-convert-collection-view';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import type { BiTableGroups } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useBitable } from '../context';

export const useUpdateGroups = (biTableGroups?: BiTableGroups) => {
  const { viewId, viewType, managerReadonly, readonly } = useBitable();

  const createGroupProperty = useCreateGroupProperty();

  useDebounceEffect(
    () => {
      if (managerReadonly || readonly) return;
      if (!biTableGroups) return;

      const callback = () => {
        const isBoard = viewType === CollectionViewType.BOARD;
        if (biTableGroups.withoutValidGroup && isBoard) {
          createGroupProperty(viewId);
          return;
        }

        if (biTableGroups.needUpdateGroups) {
          updateViewFormat(viewId, {
            [`${isBoard ? 'boardGroups' : 'collectionGroups'}`]: biTableGroups.newViewGroups,
          });
        }

        if (biTableGroups.needUpdateSubGroups) {
          updateViewFormat(viewId, { collectionGroups: biTableGroups.newViewSubGroups });
        }
      };
      callback();
    },
    [biTableGroups, createGroupProperty, managerReadonly, readonly, viewId, viewType],
    { wait: 300 }
  );
};
