import { useBitable } from 'src/bitable/context';
import { usePickBlock } from 'src/utils/pick-block';
import { useCollectionView } from './use-collection-view';
import { cache } from 'src/redux/store';

/** 获取当前多维表视图默认的模板 */
export const useDefaultTemplateId = () => {
  const { collectionId, viewId } = useBitable();
  const collectionView = useCollectionView(viewId);
  const block = usePickBlock(collectionId, ['data'], ['defaultTemplateId']);
  return collectionView?.format.defaultTemplateId || block?.data.defaultTemplateId;
};

export const getDefaultTemplateId = (collectionId: string, viewId: string) => {
  const collection = cache.blocks[collectionId];
  const collectionView = cache.collectionViews[viewId];
  return collectionView?.format.defaultTemplateId || collection?.data.defaultTemplateId || 'empty';
};
