import { omit } from 'lodash-es';
import { useCallback } from 'react';
import { useNextModalConfig, useSetNextModalState } from '.';
import { sleep } from '../../async';
import { emitter } from '../../utils/emitter';
import { globalModalPopper } from './state';

export const useCloseModal = () => {
  const setOpenModal = useSetNextModalState();
  const nextModalConfig = useNextModalConfig();
  // const obs$ = useNextModalObs();

  return useCallback(
    (
      modalId?: string,
      opts?: { noAnimation?: boolean; closeAfterCallBack?: () => void; ignoreEmitter?: boolean }
    ) => {
      if (!modalId) return;

      const findSelector = (key: string) => {
        const dom = nextModalConfig?.getRootDom?.()?.querySelector
          ? nextModalConfig.getRootDom?.()?.querySelector(key)
          : document.querySelector(key);
        return dom;
      };

      const closeAfter = () => {
        // const cur = obs$.getValue()[modalId];
        // const result = cur?.config?.closeBeforeCallBack?.();
        const popper = globalModalPopper.get(modalId);
        popper?.deref()?.destroy();
        globalModalPopper.delete(modalId);

        setOpenModal((preState) => omit(preState, modalId));

        opts?.closeAfterCallBack?.();
        // cur?.config?.closeAfterCallBack?.();
      };

      const noAnimation = opts?.noAnimation ?? false;
      try {
        const dom = findSelector(`[data-modal-id="${modalId}"]`);
        if (dom) {
          if (!opts?.ignoreEmitter) {
            emitter.emit('closeModal', modalId);
          }
          if (noAnimation) {
            closeAfter();
          } else {
            if (dom.className.includes('animate__fadeIn')) {
              dom.classList.add('animate__fadeOut');
            } else {
              dom.classList.add('dropdown_hidden');
            }

            const mask = findSelector(`[data-modal-mask-id="${modalId}"]`);
            mask?.remove();
            void sleep(100).then(() => {
              closeAfter();
            });
          }
        } else {
          closeAfter();
        }
      } catch {
        closeAfter();
      }
    },
    [nextModalConfig, setOpenModal]
  );
};
