import { CollectionViewType } from '@next-space/fe-api-idl';
import type { IconName } from 'src/common/components/icon';
import { helperLink } from 'src/components/helper-icon';

export const ViewNameMap = {
  [CollectionViewType.TABLE]: '表格',
  [CollectionViewType.BOARD]: '看板',
  [CollectionViewType.GALLERY]: '画廊',
  [CollectionViewType.LIST]: '目录',
  [CollectionViewType.TIMELINE]: '时间轴',
  [CollectionViewType.CALENDAR]: '日历',
  [CollectionViewType.FORM]: '收集表',
  [CollectionViewType.SPACE]: '',
};

export const ViewIconMap: Record<CollectionViewType, IconName | null> = {
  [CollectionViewType.TABLE]: 'IcTable',
  [CollectionViewType.BOARD]: 'IcBoard',
  [CollectionViewType.GALLERY]: 'IcGallery',
  [CollectionViewType.LIST]: 'IcList',
  [CollectionViewType.TIMELINE]: 'IcTimeline',
  [CollectionViewType.CALENDAR]: 'IcCalendar',
  [CollectionViewType.FORM]: 'IcForm',
  [CollectionViewType.SPACE]: null,
};

export const TableViewMap = [
  {
    name: '表格',
    type: CollectionViewType.TABLE,
    helperLink: helperLink.tableViewLink,
  },
  {
    name: '看板',
    type: CollectionViewType.BOARD,
    helperLink: helperLink.boardViewLink,
  },
  {
    name: '画廊',
    type: CollectionViewType.GALLERY,
    helperLink: helperLink.galleryViewLink,
  },
  {
    name: '目录',
    type: CollectionViewType.LIST,
    helperLink: helperLink.listViewLink,
  },
  {
    name: '时间轴',
    type: CollectionViewType.TIMELINE,
    helperLink: helperLink.timeViewLink,
  },
  {
    name: '日历',
    type: CollectionViewType.CALENDAR,
    helperLink: helperLink.calendarViewLink,
  },
  {
    name: '收集表',
    type: CollectionViewType.FORM,
    helperLink: helperLink.formViewLink,
  },
];
