import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { getDateStrBySegment } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { CollectionSchema, SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, FormPermission, TextType } from '@next-space/fe-api-idl';
import { useSetAtom } from 'jotai';
import { toNumber } from 'lodash-es';
import type { FC, MouseEvent, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { message } from 'src/common/components/message';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';

import { Dropdown } from '@flowus/common/next-modal/dropdown';
import { FileRegex } from '@flowus/common/regex';
import type { SortableListRenderDragHandle } from 'src/common/components/sortable-list';
import { SortableList } from 'src/common/components/sortable-list';
import { Tooltip } from 'src/common/components/tooltip';
import { Checkbox } from 'src/components/check-box';
import { EditorPersonList } from 'src/components/editor-person-list';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { DateTimePicker } from 'src/editor/editor/plugin/date-picker';
import { RichTextEdit } from 'src/editor/editor/uikit/editable';
import { newFileSegment, segmentsToText, textToSegments } from 'src/editor/utils/editor';
import { buildDateSegment, readDateFromDateSegment } from 'src/editor/utils/segments';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { useCreatePropertyOption } from 'src/hooks/block/use-create-property-options';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useRemovePropertyOption } from 'src/hooks/block/use-remove-property-option';
import { useUpdatePropertyOption } from 'src/hooks/block/use-update-property-option';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { FROM_KEYDOWN_OPTION } from 'src/hooks/editor/config';
import { useCurrentSpaceUsers } from 'src/hooks/space/use-current-space-users';
import { useUpload, useUploadWithoutLogin } from 'src/hooks/space/use-upload';
import { useGetMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { cache } from 'src/redux/store';
import { setAppUiState } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { querySelectorAllFromMainContent } from 'src/utils/dom';
import { normalizeHref } from 'src/utils/embed';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { isPhoneNumber } from 'src/utils/number';
import { pickRandomValue } from 'src/utils/pick-random-value';
import { useIsInRight } from 'src/utils/right-utils';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { v4 as uuidV4 } from 'uuid';
import isEmail from 'validator/lib/isEmail';
import isURL from 'validator/lib/isURL';
import type { HandleChangeSubmitDataType } from '.';
import { COLORS, PROPERTIES } from '../const';
import { useBitable } from '../context';
import { UploadArea } from '../table-view/cell/files';
import { readUrlFromSegments } from '../table-view/cell/helpers';
import { PersonTag } from '../table-view/cell/person';
import { PropertyManagePlace } from '../table-view/types';
import { useOpenPropertyWidget } from '../table-view/widgets/property';
import { $highlightProperty } from './form-logic-atoms';
import { useUpdateFormProperties } from './use-update-form-properties';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

/** 每个选项的Props */
interface FormComponentProps {
  readonly: boolean;
  /** 列id */
  property: string;
  /** 表 Id */
  collectionId: string;
  /** 收集表id */
  viewId: string;
  /** space Id */
  spaceId?: string;
  /** 修改填写信息 */
  handleChangeSubmitData: HandleChangeSubmitDataType;
  /** 填写的信息 */
  submitData?: SegmentDTO[] | undefined;
  /** 列的数据 */
  schema?: CollectionSchema;
}

// #region 每个选项的容器
interface ContainerProps {
  index: number;
  property: string;
  viewId: string;
  title: string;
  required?: boolean;
  formSerialNumber?: boolean;
  showDescription?: boolean;
  description?: SegmentDTO[];
  collectionId: string;
  renderDragHandle: SortableListRenderDragHandle;
  titleAfter?: ReactNode;
  titleBefore?: ReactNode;
  readonly: boolean;
}
const Container: FC<ContainerProps> = (props) => {
  const {
    required,
    children,
    index,
    renderDragHandle,
    title,
    description,
    showDescription,
    formSerialNumber = true,
    viewId,
    property,
    titleAfter,
    titleBefore,
    readonly,
  } = props;
  const openPropertyWidget = useOpenPropertyWidget();
  const updateFormProperties = useUpdateFormProperties(viewId, property);
  const collectionView = useCollectionView(viewId);
  const formLogic = collectionView?.format.formLogic ?? [];
  const associatedRules = formLogic.filter(
    (it) => it.condition.property === property || it.gotoProperty === property
  );
  const setHighlightProperty = useSetAtom($highlightProperty);

  const handleClickMore = (event: MouseEvent) => {
    openPropertyWidget(
      props.property,
      elementToGetBoundingClientRect(event.currentTarget),
      PropertyManagePlace.FORM_PROPERTIES,
      'left'
    );
  };

  return (
    <div className="mb-5 px-2" data-form-property={property}>
      {/* 标题 */}
      <div className="text-t1-medium flex items-center min-h-10 py-2.5 relative group">
        <div className="flex w-full">
          {!readonly &&
            renderDragHandle({
              className:
                'absolute -left-6 top-3 pr-1.5 opacity-0 group-hover:opacity-80 transition-opacity',
              popup: '拖拽排列顺序',
              onClick: (event) => {
                event.preventDefault();
                handleClickMore(event);
              },
            })}
          {titleBefore && <span className="flex-shrink-0">{titleBefore}</span>}
          {formSerialNumber && <span className="flex-shrink-0">{index}.</span>}
          <div className={cx('break-words', title ? '' : 'min-w-[80px]')}>
            {title ?? '未命名标题'}
          </div>
          {required && <span className="text-red">*</span>}
          {titleAfter && <span className="flex-shrink-0">{titleAfter}</span>}
          {associatedRules.length >= 1 && !readonly && (
            <span
              onClick={() => {
                setHighlightProperty(() => property);
              }}
              className="cursor-pointer text-t4 text-[#717374] bg-grey bg-opacity-20 rounded-sm inline-block px-1.5 my-1 leading-5 ml-2"
            >
              跳题逻辑 {associatedRules.length}项
            </span>
          )}
        </div>
      </div>
      {/* 选项说明介绍 */}
      {showDescription && ((readonly && description) || !readonly) && (
        <div className="py-2" onPointerDown={(event) => event.stopPropagation()}>
          <RichTextEdit
            keydownOption={FROM_KEYDOWN_OPTION}
            readonly={readonly}
            uuid={property}
            className="text-grey3 text-t1 whitespace-pre-wrap "
            placeholder={readonly ? '' : '请输入说明介绍'}
            segments={description}
            onChange={(segments) => {
              if (segmentsToText(segments).length <= 1000) {
                updateFormProperties('description', segments);
              }
            }}
          />
        </div>
      )}
      {/* 填写框 */}
      <div onPointerDown={(event) => event.stopPropagation()}>{children}</div>
    </div>
  );
};

// #endregion

// #region 勾选框
const CheckBox: FC<FormComponentProps & {}> = (props) => {
  const { submitData, property, handleChangeSubmitData } = props;
  const checked = formatCheckBoxValue(segmentsToText(submitData));

  const handleClick = () => {
    const text = checked ? 'NO' : 'YES';
    handleChangeSubmitData(property, textToSegments(text));
  };

  return (
    <div
      className="h-11 flex items-center w-full animate-hover-black3 rounded-none"
      onClick={handleClick}
    >
      <IconUiSizeBox size={24} className="cursor-pointer">
        <Checkbox checked={checked} />
      </IconUiSizeBox>
    </div>
  );
};
// #endregion

// #region 单选多选
const Select: FC<
  FormComponentProps & {
    type: CollectionSchemaType;
  }
> = (props) => {
  const { type, schema, submitData, property, handleChangeSubmitData, collectionId, readonly } =
    props;
  const { options = [] } = schema || {};
  const isMultiple = type === CollectionSchemaType.MULTI_SELECT;
  const openModal = useOpenModal();
  const createPropertyOption = useCreatePropertyOption();
  const updatePropertySchema = useUpdatePropertySchema();
  const updateOption = useUpdatePropertyOption();
  const removeOption = useRemovePropertyOption();
  const isInRight = useIsInRight();

  const handleClick = (valueStr: string) => {
    let valueArr = segmentsToText(submitData).split(',');
    const selected = valueArr.some((i) => valueStr === i && valueStr && i);
    if (selected && submitData) {
      valueArr = valueArr.filter((i) => i !== valueStr);
    } else {
      if (isMultiple) {
        valueArr.push(valueStr);
      } else {
        valueArr = [valueStr];
      }
    }

    handleChangeSubmitData(property, textToSegments(valueArr.join(',')));
  };

  const changeSort = (items: typeof options) => {
    updatePropertySchema(collectionId, property, {
      options: items,
    });
  };

  const newOptionColor = useMemo(() => {
    const pool = COLORS.map((item) => item.key);
    return pickRandomValue(pool, schema?.options?.map((item) => item.color) ?? []) ?? COLORS[0].key;
  }, [schema?.options]);

  const createSelect = () => {
    const id = uuidV4();
    const value = ['选项', options.length + 1].join(' ');
    createPropertyOption(collectionId, property, {
      id,
      value,
      color: newOptionColor,
    });
    setTimeout(() => {
      const element = querySelectorAllFromMainContent(
        `[data-form-property="${property}"] [data-form-select-option-id="${id}"]`,
        isInRight
      )[0] as HTMLInputElement | undefined;
      if (element) {
        element.click();
      }
    }, 0);
  };

  const changeOptionName = (id: string, value: string) => {
    updateOption(collectionId, property, {
      id,
      value,
    });
  };

  const deleteOption = (id: string) => {
    openModal.warning({
      title: <div className="mt-5 mb-5 w-80">是否确认删除该选项？</div>,
      confirm: () => {
        removeOption(collectionId, property, id);
      },
    });
  };

  const openNameInputModal = (event: MouseEvent, id: string, defaultValue: string) => {
    event.stopPropagation();
    let value = defaultValue;
    let isEsc = false;

    const rect = (event.currentTarget.parentNode as HTMLElement).getBoundingClientRect();
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      offset: [-4, 0],
      escCloseBeforeCallBack: () => {
        isEsc = true;
      },
      closeAfterCallBack: () => {
        value = value.split(',').join('');
        if (isEsc || !value) return;
        if (options.some((it) => it.value === value && it.id !== id)) {
          message.error('已存在同名选项');
        } else {
          changeOptionName(id, value);
        }
      },
      content: (_) => (
        <div className="next-modal p-2" style={{ width: rect.width }}>
          <Input
            defaultValue={value}
            autoFocus
            showBorder={false}
            inputClassName="text-t2 bg-white2 px-1"
            className="bg-white2"
            onChange={(v) => {
              v.split(',').join('');
              if (options.some((it) => it.value === value && it.id !== id)) {
                message.error('已存在同名选项');
              }
              value = v;
            }}
            onEnter={() => _.onCloseModal()}
          />
        </div>
      ),
    });
  };

  return (
    <>
      <SortableList
        disabled={readonly}
        items={options}
        onChange={changeSort}
        renderItemContent={({ item, renderDragHandle }) => {
          const selected = segmentsToText(submitData)
            .split(',')
            .some((i) => i === item.value && i && item.value);
          return (
            <div
              className="group relative flex w-full animate-hover-black3 rounded-none py-2 mx-1 mr-4"
              onClick={() => handleClick(item.value)}
            >
              {!readonly &&
                renderDragHandle({
                  className:
                    'absolute -left-5 top-3 opacity-0 group-hover:opacity-80 transition-opacity',
                })}
              {isMultiple ? (
                <Checkbox size="large" checked={selected} />
              ) : (
                <Icon
                  className={cx('mr-1.5 mt-0.5', selected && 'text-active_color')}
                  name={selected ? 'MIcRadioSelected' : 'IcRadio'}
                  size="middle"
                />
              )}
              <div
                data-form-select-option-id={item.id}
                className={cx('break-words w-full', readonly && 'pointer-events-none')}
                onClick={(e) => openNameInputModal(e, item.id, item.value)}
              >
                <Tooltip popup="点击修改选项名">
                  <AutoHeightTextArea
                    readonly
                    className={'cursor-pointer min-w-[80px]'}
                    boxClassName="w-full pr-4"
                    placeholder="输入选项名"
                    value={item.value}
                  />
                </Tooltip>
              </div>

              {!readonly && (
                <Icon
                  className="ml-2 absolute right-1 text-grey4 opacity-0 group-hover:opacity-100 transition-opacity"
                  name="IcOptionDelete"
                  size="middle"
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteOption(item.id);
                  }}
                />
              )}
            </div>
          );
        }}
      />
      {!readonly && (
        <div
          className="flex items-center px-1 cursor-pointer animate-hover text-t1 text-grey4 py-1.5"
          onClick={createSelect}
        >
          <Icon name="IcAdd" size="middle" className="mr-1 opacity-60" />
          <span>添加选项</span>
        </div>
      )}
    </>
  );
};
// #endregion

// #region 时间
const DateElement: FC<FormComponentProps & {}> = (props) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const { submitData, handleChangeSubmitData, property, collectionId } = props;
  const segment = submitData?.find((it) => [TextType.DATE, TextType.DATETIME].includes(it.type));
  const date = segment ? readDateFromDateSegment(segment) : null;
  const updatePropertySchema = useUpdatePropertySchema();
  const schema = usePropertySchema(collectionId, property);
  const dateStr =
    segment &&
    getDateStrBySegment(
      segment,
      schema.propertySchema?.dateFormat,
      segment?.type === TextType.DATETIME,
      schema.propertySchema?.timeFormat
    );

  return (
    <Dropdown
      visible={visibleModal}
      onClick={() => setVisibleModal(true)}
      onChange={setVisibleModal}
      placement="bottom"
      content={
        <DateTimePicker
          uiOption={{
            hasFormatSetting: !props.readonly,
          }}
          dateInfo={{
            from: date ?? new Date(),
            includeTime: segment?.type === TextType.DATETIME,
            dateFormat: schema.propertySchema?.dateFormat,
            timeFormat: schema.propertySchema?.timeFormat,
          }}
          onChange={(info) => {
            handleChangeSubmitData(property, [buildDateSegment(info)]);
            if (!props.readonly) {
              updatePropertySchema(collectionId, property, {
                dateFormat: info.dateFormat,
                timeFormat: info.timeFormat,
              });
            }
          }}
          onClear={() => {
            handleChangeSubmitData(property, textToSegments(''));
            setVisibleModal(false);
          }}
        />
      }
    >
      <div
        className={cx(
          'text-t1 py-2.5 px-2 border border-grey6 rounded cursor-pointer',
          !dateStr && 'text-grey6'
        )}
      >
        {dateStr || '年/月/日'}
      </div>
    </Dropdown>
  );
};
// #endregion

// #region 文件
const File: FC<
  FormComponentProps & {
    uploadFileList?: { id: string; url: string }[];
    pushUploadFileList: (property: string, id: string, url: string) => void;
  }
> = (props) => {
  const {
    submitData,
    handleChangeSubmitData,
    property,
    pushUploadFileList,
    uploadFileList = [],
    readonly,
    spaceId,
    viewId,
  } = props;
  const upload = useUpload();
  const uploadWithoutLogin = useUploadWithoutLogin();
  const getMediaInfo = useGetMediaInfo();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();

  const uploadFiles = (files: FileList) => {
    let uploadLen = 0;
    const messageLoadId = uuidV4();
    if (!readonly || !spaceId) {
      message.error('当前状态无法上传');
      return;
    }
    const file = files[0];
    if (!file) return;
    const isNotLogin =
      cache.collectionViews[viewId]?.format.formPermission === FormPermission.NO_LOGIN;
    if (!isNotLogin) {
      Array.from(files).forEach((f) => {
        void upload({
          file: f,
          type: 'file',
          spaceId,
          isDatabase: true,
          onProgress(progress) {
            openModal.loading({
              modalId: messageLoadId,
              title: `正在上传 ${progress}%`,
            });
          },
          async onComplete(ret) {
            uploadLen++;
            if (uploadLen === files.length) {
              closeModal(messageLoadId);
            }
            if (ret.success) {
              const mediaInfo = await getMediaInfo(f);
              const segment = newFileSegment({
                name: f.name,
                ossName: ret.ossName,
                ...mediaInfo,
              });
              message.success(`${f.name}上传成功`);
              handleChangeSubmitData(property, [segment], {
                concatSegment: true,
              });
              pushUploadFileList(property, `${segment.uuid}`, URL.createObjectURL(f));
            }
          },
        });
      });
    } else {
      Array.from(files).forEach((f) => {
        void uploadWithoutLogin({
          file: f,
          type: 'file',
          spaceId,
          viewId,
          isDatabase: true,
          onProgress(progress) {
            openModal.loading({
              modalId: messageLoadId,
              title: `正在上传 ${progress}%`,
            });
          },
          async onComplete(ret) {
            uploadLen++;
            if (uploadLen === files.length) {
              closeModal(messageLoadId);
            }
            if (ret.success) {
              const mediaInfo = await getMediaInfo(f);
              const segment = newFileSegment({
                name: f.name,
                ossName: ret.ossName,
                ...mediaInfo,
              });
              message.success(`${f.name}上传成功`);
              handleChangeSubmitData(property, [segment], {
                concatSegment: true,
              });
              pushUploadFileList(property, `${segment.uuid}`, URL.createObjectURL(f));
            }
          },
        });
      });
    }
  };

  const removeFile = (id?: string): void => {
    if (!id) return;
    const segments = submitData?.filter((it) => it.uuid !== id);
    handleChangeSubmitData(property, segments);
  };

  const changeSort = (segments: SegmentDTO[]) => {
    handleChangeSubmitData(property, segments);
  };

  if (!submitData?.length) {
    return (
      <Tooltip popup="支持多选">
        <UploadArea
          className="relative flex items-center text-t1 w-full text-grey4 h-11 rounded bg-grey8 cursor-pointer px-2"
          multiple
          onSelect={(files) => {
            uploadFiles(files);
          }}
        >
          <Icon name="IcBlockFile" size="middle" className="mr-1" />
          点击上传文件
        </UploadArea>
      </Tooltip>
    );
  }

  return (
    <Dropdown
      placement="bottom"
      content={
        <div className="next-modal max-w-lg min-w-[500px]">
          <div className="overflow-y-auto max-h-[50vh]">
            <SortableList
              modifiers={[restrictToVerticalAxis]}
              items={submitData.map((i) => ({ id: `${i.uuid}`, data: i }))}
              onChange={(items) => changeSort(items.map((i) => i.data))}
              renderItemContent={({ item, renderDragHandle }) => {
                const isImage = FileRegex.image.test(getFileNameInfo(item.data.text).extName);
                const imageSrc = uploadFileList.find((i) => i.id === item.data.uuid)?.url;
                let content = (
                  <span className="text-t2 px-1 flex items-center w-full">
                    <Icon
                      name={getFileIcon(getFileNameInfo(item.data.text).extName)}
                      size="middle"
                      className="mr-2"
                    />
                    {item.data.text}
                  </span>
                );
                if (isImage) {
                  content = <img className="max-h-[165px]" src={imageSrc} alt={item.data.text} />;
                }
                return (
                  <div key={item.data.uuid} className="flex px-4 py-2.5 pr-11 relative">
                    {renderDragHandle({ className: 'mr-2 mt-1' })}
                    {content}
                    <Tooltip
                      popup="移除文件"
                      className="absolute right-4 top-3 animate-hover cursor-pointer text-grey4"
                    >
                      <Icon
                        onClick={() => removeFile(item.data.uuid)}
                        name="IcOptionDelete"
                        size="middle"
                      />
                    </Tooltip>
                  </div>
                );
              }}
            />
          </div>
          <UploadArea
            className="animate-hover rounded-none relative flex items-center text-t2 w-full text-grey3 h-10 border-t border-grey6 cursor-pointer px-4"
            multiple
            onSelect={(files) => {
              uploadFiles(files);
            }}
          >
            <Icon name="IcAdd" size="middle" className="mr-1" />
            添加附件或图片
          </UploadArea>
        </div>
      }
    >
      <div
        className={cx(
          'border border-grey6 rounded px-1 pt-2 cursor-pointer flex items-center flex-wrap',
          submitData.length === 0 && 'pb-2'
        )}
      >
        {submitData.map((item) => {
          const isImage = FileRegex.image.test(getFileNameInfo(item.text).extName);
          const imageSrc = uploadFileList.find((i) => i.id === item.uuid)?.url;
          if (!(imageSrc && isImage)) {
            return (
              <span key={item.uuid} className="text-t2 px-1 bg-grey6 mr-1.5 mb-2">
                {item.text}
              </span>
            );
          }
          return <img key={item.uuid} className="h-7 mr-1.5 mb-2" src={imageSrc} alt={item.text} />;
        })}
      </div>
    </Dropdown>
  );
};
// #endregion

// #region 多种格式的文字
interface FormatTextareaProps {
  type?: CollectionSchemaType;
}
const FormatTextarea: FC<FormComponentProps & FormatTextareaProps> = (props) => {
  const { property, schema, submitData, handleChangeSubmitData, type } = props;

  const rightButton = useMemo(() => {
    const params = {
      tooltip: '',
      icon: '',
      url: readUrlFromSegments(submitData),
      href: '',
    };
    switch (type) {
      case CollectionSchemaType.EMAIL:
        if (isEmail(params.url)) {
          params.tooltip = '发送邮件';
          params.icon = 'IcTitleEmail';
          params.href = `mailto:${params.url}`;
        }
        break;
      case CollectionSchemaType.PHONE:
        if (isPhoneNumber(params.url)) {
          params.tooltip = '拨打电话';
          params.icon = 'IcTitlePhone';
          params.href = `tel:${params.url}`;
        }
        break;
      case CollectionSchemaType.URL:
        if (isURL(params.url)) {
          params.tooltip = '访问链接';
          params.icon = 'IcTitleUrl';
          params.href = normalizeHref(params.url);
        }
        break;
      default:
        return null;
    }
    return params;
  }, [submitData, type]);

  const handleChange = (value: string) => {
    const checkNum = /^\d+$/.test(value);
    if (CollectionSchemaType.PHONE === type && !checkNum && value) {
      return;
    }

    handleChangeSubmitData(property, textToSegments(value));
  };

  const formatNumber = (text: string) => {
    let num = toNumber(text);
    if (Number.isNaN(num)) {
      num = toNumber(text.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
    }
    return Number.isNaN(num) ? '' : `${num}`;
  };

  const placeholder = PROPERTIES.find((i) => i.type === schema?.type)?.title || '文本';

  const wordWrap = Boolean(
    type && [CollectionSchemaType.TEXT, CollectionSchemaType.TITLE].includes(type)
  );

  return (
    <div className={'border border-grey6 rounded text-t1 py-2.5 px-2 relative group'}>
      <AutoHeightTextArea
        singleLine={!wordWrap}
        shiftEnter={wordWrap}
        fontClassName={cx(wordWrap && 'max-h-[100px] overflow-y-auto')}
        onBlur={() => {
          if (CollectionSchemaType.NUMBER === type) {
            const value = formatNumber(segmentsToText(submitData || []));
            handleChangeSubmitData(property, textToSegments(value));
          }
        }}
        placeholder={`请输入${placeholder}`}
        value={segmentsToText(submitData || [])}
        onChange={(event) => {
          handleChange(event.currentTarget.value);
        }}
      />
      {rightButton?.href && (
        <Tooltip
          popup={rightButton.tooltip}
          className="opacity-0 group-hover:opacity-100 transition-opacity absolute right-2 top-3"
        >
          <a
            href={rightButton.href}
            target="_blank"
            rel="noopener noreferrer"
            className="w-6 h-6 flex items-center justify-center border rounded bg-white1 animate-hover-opaque"
          >
            <Icon name={rightButton.icon as IconName} size="middle" className="text-grey3" />
          </a>
        </Tooltip>
      )}
    </div>
  );
};
// #endregion

// #region 成员
const Person: FC<FormComponentProps & {}> = (props) => {
  const { submitData, handleChangeSubmitData, property } = props;
  const users = useCurrentSpaceUsers();
  const openModal = useOpenModal();

  const onUpdate = (values: SegmentDTO[] | undefined) => {
    handleChangeSubmitData(property, values);
  };

  const PersonListDropdown: FC<{
    defaultValue?: SegmentDTO[];
    onUpdate: (value?: SegmentDTO[]) => void;
    onCloseModal: ModalSchema.CloseModalType;
  }> = ({ defaultValue, onUpdate, onCloseModal }) => {
    const [value, setValue] = useState(defaultValue);
    return (
      <div className="next-modal">
        <EditorPersonList
          value={value}
          includeGroup={false}
          onClose={() => onCloseModal()}
          onUpdate={(v) => {
            setValue(v);
            onUpdate(v);
          }}
        />
      </div>
    );
  };

  const openPersonList = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      content: (_) => (
        <PersonListDropdown
          defaultValue={submitData}
          onUpdate={onUpdate}
          onCloseModal={_.onCloseModal}
        />
      ),
    });
  };

  return (
    <div
      onClick={openPersonList}
      className={cx(
        'px-2 border border-grey6 rounded cursor-pointer',
        submitData?.length ? 'py-0.5' : 'py-2'
      )}
    >
      {submitData?.length ? (
        <div className="flex items-center flex-wrap cursor-pointer py-px">
          {submitData.map((item) => {
            if (!(item.uuid && users[item.uuid])) {
              return null;
            }
            return <PersonTag key={item.uuid} userId={item.uuid} />;
          })}
        </div>
      ) : (
        <div className="text-t1 text-grey4">添加成员</div>
      )}
    </div>
  );
};
// #endregion

// #region 创建题目
const CreateFormProperty: FC<{ collectionId: string; viewId: string }> = ({
  collectionId,
  viewId,
}) => {
  const { isLocked } = useBitable();
  const createProperty = useCreateProperty();
  const openPropertyWidget = useOpenPropertyWidget();
  if (isLocked) return null;

  const handleCreateProperty = (event: MouseEvent) => {
    bizTracker.event('bitable_property_create', { from_scene: 'form' });
    const newPropertyId = createProperty({
      collectionId,
      viewId,
    });
    if (newPropertyId) {
      setAppUiState({
        $newTableProperty: {
          propertyId: newPropertyId,
          from: PropertyManagePlace.FORM_PROPERTIES,
        },
      });

      openPropertyWidget(
        newPropertyId,
        elementToGetBoundingClientRect(event.currentTarget),
        PropertyManagePlace.FORM_PROPERTIES,
        'bottom-start'
      );
    }
  };

  return (
    <div
      className="flex items-center px-2 text-t2 animate-hover text-grey4 py-2"
      onClick={handleCreateProperty}
    >
      <Icon name="IcAdd" size="middle" className="mr-1 opacity-60" />
      <span>添加属性</span>
    </div>
  );
};
// #endregion

export const FormComponent = {
  Person,
  CheckBox,
  Select,
  DateElement,
  File,
  FormatTextarea,
  Container,
  CreateFormProperty,
};
