import { lookupPageIdCommon } from '@flowus/common/block/permissions';
import { segmentsToText } from 'src/editor/utils/editor';
import { getState } from 'src/redux/store';
import { $spacesCache } from 'src/services/spaces/spaces';

export const lookupPageId = (uuid: string, getState2 = getState()) => {
  return lookupPageIdCommon(uuid, {
    ...getState2,
    segmentsToText,
    spaces: $spacesCache,
  });
};
