import { Provider as JotaiProvider } from 'jotai';
import type { FC } from 'react';
import { NextModalProvider } from 'src/common/components/next-modal';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { antiCycleSet_PreviewPageContent } from 'src/views/main/header/page-history';
import { $pageId, PAGE_SCOPE } from 'src/views/main/page-states';
import type { PageScene } from 'src/views/main/scene-context';
import { PageSceneContext } from 'src/views/main/scene-context';
import type { PageContent } from './page-preview/page-content';

let PageContent0: typeof PageContent;

export const antiCycleSet_PageContent1 = (pageContent: typeof PageContent) => {
  PageContent0 = pageContent;
};
export const PreviewPageContent: FC<{
  docId: string;
  pageScene: PageScene;
  nameSpace?: string;
}> = ({ docId, pageScene, nameSpace }) => {
  return (
    <JotaiProvider scope={PAGE_SCOPE} initialValues={[[$pageId, docId]]}>
      <PageSceneContext.Provider value={pageScene}>
        <EditableNamespace.Provider value={nameSpace}>
          <NextModalProvider>
            <PageContent0 uuid={docId} />
          </NextModalProvider>
        </EditableNamespace.Provider>
      </PageSceneContext.Provider>
    </JotaiProvider>
  );
};
antiCycleSet_PreviewPageContent(PreviewPageContent);
