import { useCallback, useMemo } from 'react';
import { Switch } from 'src/common/components/switch';
import { useCurrentSpace, useUpdateSpace } from 'src/hooks/space';
import { useIsFreeSpaceLimit, useIsShowAd } from 'src/hooks/space/use-is-pro-space';
import { transaction } from 'src/hooks/use-transaction';
import { bizTracker } from 'src/utils/biz-tracker';
import { Option } from '../option';

/** 页面广告 */
export const PageAd = () => {
  const currentSpace = useCurrentSpace();
  const updateSpace = useUpdateSpace();
  const isFreeSpaceLimit = useIsFreeSpaceLimit();
  const isShowAd = useIsShowAd();

  const onSwitch = useCallback(
    (status: boolean) => {
      bizTracker.event('ad_switch_set', { is_open: status });
      transaction(() => {
        void updateSpace(currentSpace.uuid, {
          isShowAd: status,
        });
      });
    },
    [currentSpace.uuid, updateSpace]
  );

  const rightPart = useMemo(
    () => <Switch open={isShowAd} onSwitch={onSwitch} disabled={isFreeSpaceLimit} />,
    [isFreeSpaceLimit, isShowAd, onSwitch]
  );

  return (
    <Option
      title="分享页底部广告"
      showSpacePlan
      description="关闭后，分享页面底部将不再显示广告"
      rightPart={rightPart}
    />
  );
};
