import { fastEqual } from '@flowus/common/utils/tools';
import { createCache, createSetState, createStore } from '@flowus/common/zustand/utils';
import type { SpaceViewDTO } from '@next-space/fe-api-idl';
import produce from 'immer';
import { assign } from 'lodash-es';

type SpaceViewsStateType = Record<string, SpaceViewDTO>;
export const $spaceViewsState = createStore<SpaceViewsStateType>(() => ({}));

const $setSpaceView = createSetState($spaceViewsState);

export const $spaceViewsCache = createCache($spaceViewsState);

export const useSpaceViewsSelector = <T>(selector: (state: SpaceViewsStateType) => T) => {
  return $spaceViewsState(selector, fastEqual);
};

export const updateSpaceViews = (state: SpaceViewsStateType) => {
  $setSpaceView(() => state);
};

export const spaceViewsOperation = {
  create: (payload: { spaceView: SpaceViewDTO }) => {
    $setSpaceView(
      produce((state) => {
        state[payload.spaceView.uuid] = payload.spaceView;
      })
    );
  },
  delete: (payload: { uuid: string }) => {
    $setSpaceView(
      produce((state) => {
        delete state[payload.uuid];
      })
    );
  },
  updateSpaceView: (payload: { uuid: string; patch: Partial<Omit<SpaceViewDTO, 'uuid'>> }) => {
    $setSpaceView(
      produce((state) => {
        const spaceView = state[payload.uuid];
        if (!spaceView) return;
        const { setting, ...rest } = payload.patch;
        assign(spaceView, rest);
        if (!spaceView.setting) {
          spaceView.setting = {};
        }
        if (setting) {
          assign(spaceView.setting, setting);
        }
        state[payload.uuid] = spaceView;
      })
    );
  },
  listRemoveFavorite: (payload: { parentId: string; uuid: string }) => {
    $setSpaceView(
      produce((state) => {
        const spaceView = state[payload.parentId];
        if (!spaceView) return;
        const index = spaceView.favoritePages.indexOf(payload.uuid);
        if (index > -1) {
          spaceView.favoritePages.splice(index, 1);
        }
      })
    );
  },
  listBeforeFavorite: (payload: {
    parentId: string;
    uuid: string;
    before?: string | undefined;
    ignoreOp?: boolean | undefined;
  }) => {
    $setSpaceView(
      produce((state) => {
        const spaceView = state[payload.parentId];
        if (!spaceView) return;
        if (payload.before) {
          const beforeIndex = spaceView.favoritePages.indexOf(payload.before);
          if (beforeIndex < 0) {
            spaceView.favoritePages.splice(0, 0, payload.uuid);
          } else {
            spaceView.favoritePages.splice(beforeIndex, 0, payload.uuid);
          }
        } else {
          spaceView.favoritePages.splice(0, 0, payload.uuid);
        }
      })
    );
  },
  listAfterFavorite: (payload: {
    uuid: string;
    parentId: string;
    after?: string | undefined;
    ignoreOp?: boolean | undefined;
  }) => {
    $setSpaceView(
      produce((state) => {
        const spaceView = state[payload.parentId];
        if (!spaceView) return;
        if (payload.after) {
          const afterIndex = spaceView.favoritePages.indexOf(payload.after);
          if (afterIndex < 0) {
            spaceView.favoritePages.splice(0, 0, payload.uuid);
          } else {
            spaceView.favoritePages.splice(afterIndex + 1, 0, payload.uuid);
          }
        } else {
          spaceView.favoritePages.splice(0, 0, payload.uuid);
        }
      })
    );
  },
};
