import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { setAppUiState } from 'src/services/app';
import { getCurrentSpace } from './use-current-space';

export const getSpaceGuests = async (spaceId: string) => {
  const { results, recordMap } = await request.infra.getSpaceGuests(spaceId);
  setAppUiState({ $guestsList: results });

  dispatch(
    usersActions.updateSpaceUsers({ users: recordMap.users ?? {}, space: getCurrentSpace() })
  );

  dispatch(blocksActions.update({ blocks: recordMap.blocks as Record<string, NextBlock> }));
};
