import { useEffect, useLayoutEffect, useRef } from 'react';

export const useCellEditor = (opts: {
  onClose: () => void;
  onUpdate: () => void;
  onSave: () => void;
}) => {
  const optsRef = useRef() as { current: typeof opts };
  optsRef.current = opts;

  useEffect(() => {
    return () => {
      optsRef.current.onSave();
      setTimeout(optsRef.current.onClose);
    };
  }, []);

  useLayoutEffect(() => {
    optsRef.current.onUpdate();
  }, []);
};
