import type { CollectionSchema } from '@next-space/fe-api-idl';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import { getState } from 'src/redux/store';

export const formulaIsValid = (
  collectionId: string,
  propertyId?: string,
  formula?: CollectionSchema['formula']
) => {
  if (!formula || !propertyId) {
    return false;
  }

  const formulaTool = getFormulaTool(collectionId);
  return !!formulaTool.getType(propertyId);
};

export const getGroupSchemaType = (collectionId: string, propId?: string) => {
  if (!propId) {
    return;
  }

  const { propertySchema } = getPropertySchema(collectionId, propId);
  if (!propertySchema) {
    return;
  }

  let schemaType = propertySchema.type;

  if (
    propertySchema.type === CollectionSchemaType.FORMULA &&
    formulaIsValid(collectionId, propId, propertySchema.formula)
  ) {
    const tableSchema = getState().blocks[collectionId]?.data.schema;
    if (tableSchema) {
      const formulaTool = getFormulaTool(collectionId);
      schemaType = formulaTool.getTypeAsCollectionSchemaType(propId);
    }
  }

  if (
    schemaType === CollectionSchemaType.TITLE ||
    schemaType === CollectionSchemaType.TEXT ||
    schemaType === CollectionSchemaType.PHONE ||
    schemaType === CollectionSchemaType.EMAIL ||
    schemaType === CollectionSchemaType.RELATION ||
    schemaType === CollectionSchemaType.URL
  ) {
    return 'text';
  }

  if (
    schemaType === CollectionSchemaType.DATE ||
    schemaType === CollectionSchemaType.UPDATED_AT ||
    schemaType === CollectionSchemaType.CREATED_AT
  ) {
    return 'date';
  }

  if (schemaType === CollectionSchemaType.NUMBER) {
    return 'number';
  }

  return schemaType;
};
