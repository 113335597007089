import PropTypes from 'prop-types';
import React from 'react';

const anchorNames = {
  search: '搜索',
  recent: '最近',
  people: '表情',
  nature: '动物',
  foods: '食物',
  activity: '活动',
  places: '地点',
  objects: '物件',
  symbols: '符号',
  flags: '旗帜',
  xuexibangong: '学习办公',
  shumashebei: '数码设备',
  IT: <>&nbsp;IT</>,
  jigefuhao: '几何符号',
  shenghuo: '生活',
  biaoqing: '表情',
  tiyu: '体育',
  lvxingdidian: '旅行地点',
  yingyinyouxi: '影音游戏',
  dongwuzhiwu: '动物植物',
  shiwuyinliao: '食物饮料',
  pinpai: '品牌',
  // 学习办公: '学习办公',
  // 数码设备: '数码设备',
  // IT: <>&nbsp;IT</>,
  // 几何符号: '几何符号',
  // 生活: '生活',
  // 表情: '表情',
  // 体育: '体育',
  // 旅行地点: '旅行地点',
  // 影音游戏: '影音游戏',
  // 动物植物: '动物植物',
  // 食物饮料: '食物饮料',
  // 品牌: '品牌',
};
export default class Anchors extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selected: 'people', //表情
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    const index = e.currentTarget.getAttribute('data-index');
    const { categories, onAnchorClick } = this.props;
    onAnchorClick(categories[index], index);
  }

  render() {
    const { categories, i18n } = this.props,
      { selected } = this.state;
    return (
      <nav
        className="flex justify-between h-[45px] bg-white2/90 rounded-b px-4 items-center w-full overflow-x-auto space-x-2"
        aria-label={i18n.categorieslabel}
      >
        {categories.map((category, i) => {
          const { id, anchor } = category,
            isSelected = id === selected;
          if (anchor === false) {
            return null;
          }

          const iconId = id.startsWith('custom-') ? 'custom' : id;
          return (
            <div
              key={id}
              data-index={i}
              aria-label={i18n.categories[iconId]}
              onClick={this.handleClick}
              title={i18n.categories[iconId]}
              className={`shrink-0 text-t2 cursor-pointer ${isSelected ? '' : 'text-grey4'}`}
            >
              {anchorNames[id]}
            </div>
          );
        })}
      </nav>
    );
  }
}

Anchors.propTypes /* remove-proptypes */ = {
  categories: PropTypes.array,
  onAnchorClick: PropTypes.func,
  icons: PropTypes.object,
};

Anchors.defaultProps = {
  categories: [],
  onAnchorClick: () => {},
  icons: {},
};
