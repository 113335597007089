import { PRODUCT_ALL_NAME } from 'src/const/title';

export interface ExportOption {
  type: 'png' | 'markdown' | 'csv';
  transparent: boolean;
  watermark: string;
  alreadySnapShot: boolean;
  isDarkMode: boolean;
}
export const WATERMARK = `由${PRODUCT_ALL_NAME}提供技术支持`;
