import { useEffect } from 'react';
import { useCurrentUser } from '../user';
import { computeTextWidth, defaultSettings, useIsDarkMode, watermark } from '@flowus/common';
/**
 * 付费订阅水印
 */
export const useSharePayWatermark = (id: string, watermarkId: string) => {
  const user = useCurrentUser();
  const userName = user.nickname;
  const { phone } = user;
  const isDarkMode = useIsDarkMode();
  useEffect(() => {
    if (!userName) return; // need login
    const tag = userName + phone.slice(-4);
    const textWidth = computeTextWidth(tag, {
      fontSize: defaultSettings.watermark_fontsize,
      font: defaultSettings.watermark_font,
      textAlign: 'center',
      display: 'block',
    });
    watermark.load({
      watermark_id: watermarkId,
      watermark_x_space: 50,
      watermark_y_space: 50,
      watermark_angle: 20,
      watermark_width: textWidth,
      watermark_color: isDarkMode ? 'white' : 'black',
      watermark_alpha: isDarkMode ? 0.035 : 0.05,
      watermark_txt: tag,
    });
    return () => {
      watermark.remove(watermarkId);
    };
  }, [isDarkMode, phone, userName, watermarkId]);
};
