/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LOCAL_LNG } from '@flowus/common/const';
import { formula } from '@flowus/formula';
import type {
  CollectionFilterGroup,
  CollectionSchema,
  CollectionFilter,
} from '@next-space/fe-api-idl';
import { AggregationAction, CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import * as _ from 'lodash-es';
import { DATE_FORMAT } from 'src/common/const';
import { segmentsToText } from 'src/editor/utils/editor';
import {
  convertSegmentsToNumber,
  convertSegmentsToTimestamp,
  personSegmentsToUserIds,
} from 'src/editor/utils/segments';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import { getState, cache } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { ONE_DAY } from 'src/utils/date-utils';
import { percentToNumber } from 'src/utils/number';
import { getRelationRecords } from '../cell/relation/get-relation-records';
import { getRollupValue } from '../cell/rollup/get-rollup-value';
import { isDateAggregation, isPercentAggregation, isShowOriginalValue } from '../footer/helper';
import { createdAtGetter, genericGetter, titleGetter, updatedAtGetter } from './sorters';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';

if (!__BUILD_IN__) {
  dayjs.extend(weekday);
  dayjs.locale('zh-cn');
}

type RecordFilter = (record: NextBlock) => boolean;

interface OperatorMap<T, P = T> {
  [op: string]: (value: T | undefined | null, operand: P) => boolean;
}

const dayRange = (d: Dayjs): [start: Dayjs, end: Dayjs] => {
  return [d.startOf('day'), d.endOf('day')];
};

const weekRange = (d: Dayjs): [start: Dayjs, end: Dayjs] => {
  const start = d.startOf('day').valueOf() - ((d.day() || 7) - 1) * ONE_DAY;
  const end = start + 7 * ONE_DAY - 1000;
  return [dayjs(start), dayjs(end)];
};

const monthRange = (d: Dayjs): [start: Dayjs, end: Dayjs] => {
  return [d.startOf('month'), d.endOf('month')];
};

const yearRange = (d: Dayjs): [start: Dayjs, end: Dayjs] => {
  return [d.startOf('year'), d.endOf('year')];
};

export const RELATIVE_DATE_RANGES: Record<
  string,
  {
    day: boolean;
    fn: () => [start: Dayjs, end: Dayjs];
    title: string;
  }
> = {
  today: { title: '今天', day: true, fn: () => dayRange(dayjs()) },
  yesterday: { title: '昨天', day: true, fn: () => dayRange(dayjs().add(-1, 'day')) },
  tomorrow: { title: '明天', day: true, fn: () => dayRange(dayjs().add(1, 'day')) },
  current_week: { title: '本周', day: false, fn: () => weekRange(dayjs()) },
  last_week: { title: '上周', day: false, fn: () => weekRange(dayjs().add(-1, 'week')) },
  next_week: { title: '下周', day: false, fn: () => weekRange(dayjs().add(1, 'week')) },
  current_month: { title: '本月', day: false, fn: () => monthRange(dayjs()) },
  last_month: { title: '上月', day: false, fn: () => monthRange(dayjs().add(-1, 'month')) },
  next_month: { title: '下月', day: false, fn: () => monthRange(dayjs().add(1, 'month')) },
  current_year: { title: '今年', day: false, fn: () => yearRange(dayjs()) },
  last_year: { title: '去年', day: false, fn: () => yearRange(dayjs().add(-1, 'year')) },
  next_year: { title: '明年', day: false, fn: () => yearRange(dayjs().add(1, 'year')) },
  a_week_ago: { title: '一周前', day: true, fn: () => dayRange(dayjs().add(-1, 'week')) },
  a_week_later: { title: '一周后', day: true, fn: () => dayRange(dayjs().add(1, 'week')) },
  a_month_ago: { title: '一个月前', day: true, fn: () => dayRange(dayjs().add(-1, 'month')) },
  a_month_later: { title: '一个月后', day: true, fn: () => dayRange(dayjs().add(1, 'month')) },
  last_7_days: {
    title: '过去7天',
    day: false,
    fn: () => [dayjs().add(-6, 'day').startOf('day'), dayjs().endOf('day')],
  },
  next_7_days: {
    title: '未来7天',
    day: false,
    fn: () => [dayjs().add(1, 'day').startOf('day'), dayjs().add(7, 'day').endOf('day')],
  },
  last_30_days: {
    title: '过去30天',
    day: false,
    fn: () => [dayjs().add(-29, 'day').startOf('day'), dayjs().endOf('day')],
  },
  next_30_days: {
    title: '未来30天',
    day: false,
    fn: () => [dayjs().add(1, 'day').startOf('day'), dayjs().add(30, 'day').endOf('day')],
  },
};

const addRelDateSupport = (f: (value: string | null | undefined, operand: string) => boolean) => {
  return (value: string | null | undefined, operand: string) => {
    if (_.has(RELATIVE_DATE_RANGES, operand)) {
      return f(value, RELATIVE_DATE_RANGES[operand]!.fn()[0].format(DATE_FORMAT));
    }
    return f(value, operand);
  };
};

// NOTE: 键的顺序是有意的，不要随意调整
const OPERATORS: {
  string: OperatorMap<string>;
  number: OperatorMap<number>;
  date: OperatorMap<string, string>;
  tag: OperatorMap<string, string>;
  tags: OperatorMap<string[], string>;
  persons: OperatorMap<string[], string>;
  relations: OperatorMap<string[], string>;
  rollupUnique: OperatorMap<string[], string>;
  other: OperatorMap<void>;
} = {
  string: {
    in: (value, operand) => {
      return (value ?? '').includes(operand);
    },
    nin: (value, operand) => {
      return !(value ?? '').includes(operand);
    },
    eq: (value, operand) => {
      return (value ?? '') === operand;
    },
    neq: (value, operand) => {
      return (value ?? '') !== operand;
    },
    startsWith: (value, operand) => {
      return (value ?? '').startsWith(operand);
    },
    endsWith: (value, operand) => {
      return (value ?? '').endsWith(operand);
    },
  },
  tag: {
    eq: (value, operand) => {
      return value === operand;
    },
    neq: (value, operand) => {
      return value !== operand;
    },
  },
  tags: {
    in: (value, operand) => {
      return (value ?? []).includes(operand);
    },
    nin: (value, operand) => {
      return !(value ?? []).includes(operand);
    },
  },
  number: {
    eq: (value, operand) => {
      return (value ?? NaN) === operand;
    },
    neq: (value, operand) => {
      return (value ?? NaN) !== operand;
    },
    gt: (value, operand) => {
      return (value ?? NaN) > operand;
    },
    lt: (value, operand) => {
      return (value ?? NaN) < operand;
    },
    gte: (value, operand) => {
      return (value ?? NaN) >= operand;
    },
    lte: (value, operand) => {
      return (value ?? NaN) <= operand;
    },
  },
  date: {
    eq: addRelDateSupport((value, operand) => {
      return value === operand;
    }),
    neq: addRelDateSupport((value, operand) => {
      return value !== operand;
    }),
    in: (value, operand) => {
      if (value == null) return false;

      if (_.has(RELATIVE_DATE_RANGES, operand)) {
        const relRange = RELATIVE_DATE_RANGES[operand]!;
        const [start, end] = relRange.fn();
        return dayjs(value, DATE_FORMAT).isBetween(start, end, 'day', '[]');
      }

      if (operand?.includes(',')) {
        const startend = operand.split(',');
        const start = dayjs(startend[0], DATE_FORMAT);
        const end = dayjs(startend[1], DATE_FORMAT);
        return dayjs(value, DATE_FORMAT).isBetween(start, end, 'day', '[]');
      }

      return false;
    },
    lt: addRelDateSupport((value, operand) => {
      return value != null && value < operand;
    }),
    gt: addRelDateSupport((value, operand) => {
      return value != null && value > operand;
    }),
    lte: addRelDateSupport((value, operand) => {
      return value != null && value <= operand;
    }),
    gte: addRelDateSupport((value, operand) => {
      return value != null && value >= operand;
    }),
  },
  persons: {
    in: (value, operand) => {
      return (value ?? []).includes(operand);
    },
    nin: (value, operand) => {
      return !(value ?? []).includes(operand);
    },
  },
  relations: {
    in: (value, operand) => {
      if (operand === '') return true;
      const ids = (operand ?? '').split(',').filter(Boolean);
      return (value ?? []).some((item) => ids.includes(item));
    },
    nin: (value, operand) => {
      if (operand === '') return true;
      const ids = (operand ?? '').split(',').filter(Boolean);
      return (value ?? []).every((item) => !ids.includes(item));
    },
  },
  rollupUnique: {
    in: (value, operand) => {
      return (value ?? []).some((item) => {
        return item === operand;
      });
    },
    nin: (value, operand) => {
      return (value ?? []).every((item) => {
        return item !== operand;
      });
    },
  },

  other: {},
};

export const OPERATOR_LABEL_MAP: {
  [key: string]: {
    _default: string;
    [type: string]: string;
  };
} = {
  eq: { _default: '是', [CollectionSchemaType.NUMBER]: '=' },
  neq: { _default: '不是', [CollectionSchemaType.NUMBER]: '≠' },
  lt: {
    _default: '小于',
    [CollectionSchemaType.NUMBER]: '<',
    [CollectionSchemaType.DATE]: '早于',
  },
  lte: {
    _default: '小于等于',
    [CollectionSchemaType.NUMBER]: '≤',
    [CollectionSchemaType.DATE]: '不晚于',
  },
  gt: {
    _default: '大于',
    [CollectionSchemaType.NUMBER]: '>',
    [CollectionSchemaType.DATE]: '晚于',
  },
  gte: {
    _default: '大于等于',
    [CollectionSchemaType.NUMBER]: '≥',
    [CollectionSchemaType.DATE]: '不早于',
  },
  in: { _default: '包含', [CollectionSchemaType.DATE]: '位于某段时间内' },
  nin: { _default: '不包含' },
  startsWith: { _default: '开始为' },
  endsWith: { _default: '结束为' },
  isEmpty: { _default: LOCAL_LNG.isEmpty, [CollectionSchemaType.CHECKBOX]: '未勾选' },
  isNotEmpty: { _default: LOCAL_LNG.isNotEmpty, [CollectionSchemaType.CHECKBOX]: '已勾选' },
};

export function getFilterValueType(
  type: string,
  collectionId?: string,
  propId?: string,
  state?: ReturnType<typeof getState>
  // @ts-ignore dont find me,find yuxiang
): keyof typeof OPERATORS {
  if (type === CollectionSchemaType.FORMULA && collectionId && propId) {
    const formulaTool = getFormulaTool(collectionId, state);

    type = formulaTool.getTypeAsCollectionSchemaType(propId);
  }
  // eslint-disable-next-line default-case
  switch (type) {
    case CollectionSchemaType.TITLE:
    case CollectionSchemaType.TEXT:
    case CollectionSchemaType.URL:
    case CollectionSchemaType.EMAIL:
    case CollectionSchemaType.PHONE:
      return 'string';
    case CollectionSchemaType.CREATED_AT:
    case CollectionSchemaType.UPDATED_AT:
    case CollectionSchemaType.DATE:
      return 'date';
    case CollectionSchemaType.NUMBER:
      return 'number';
    case CollectionSchemaType.SELECT:
      return 'tag';
    case CollectionSchemaType.MULTI_SELECT:
      return 'tags';
    case CollectionSchemaType.PERSON:
    case CollectionSchemaType.CREATED_BY:
    case CollectionSchemaType.UPDATED_BY:
      return 'persons';
    case CollectionSchemaType.RELATION:
      return 'relations';
    case CollectionSchemaType.ROLLUP:
    case CollectionSchemaType.CHECKBOX:
    case CollectionSchemaType.FILE:
      return 'other';
    case CollectionSchemaType.FORMULA:
      throw new Error('Assertion failed');
  }
}

export function getAvailableOperators(
  collectionId: string,
  propId: string
): {
  value: CollectionFilter['operator'];
  title: string;
}[] {
  const { propertySchema, relationPropertySchema, targetPropertySchema } = getPropertySchema(
    collectionId,
    propId
  );
  const { type, aggregation, targetProperty } = propertySchema ?? {};

  let schemaType: string = type ?? CollectionSchemaType.TEXT;

  if (type === CollectionSchemaType.CREATED_AT || type === CollectionSchemaType.UPDATED_AT) {
    schemaType = CollectionSchemaType.DATE;
  }

  if (schemaType === CollectionSchemaType.ROLLUP) {
    if (isShowOriginalValue(aggregation)) {
      const targetPropertyType = targetPropertySchema?.type ?? CollectionSchemaType.TEXT;
      if (targetPropertyType === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(relationPropertySchema?.collectionId ?? '');

        schemaType = formulaTool.getTypeAsCollectionSchemaType(targetProperty ?? '');
        // TODO: 汇总公式暂不考虑
        return [];
      }
      schemaType = targetPropertyType;
    } else if (isDateAggregation(aggregation)) {
      schemaType = CollectionSchemaType.DATE;
    } else {
      schemaType = CollectionSchemaType.NUMBER;
    }
  } else if (type === CollectionSchemaType.FORMULA) {
    const formulaTool = getFormulaTool(collectionId);

    schemaType = formulaTool.getTypeAsCollectionSchemaType(propId);
  }

  const filterValuetype = getFilterValueType(schemaType, collectionId, propId);
  const operators = OPERATORS[filterValuetype];
  return [...Object.keys(operators), ...['isNotEmpty', 'isEmpty']].map((it) => {
    const op = it as CollectionFilter['operator'];
    const title = OPERATOR_LABEL_MAP[op]?.[schemaType] ?? OPERATOR_LABEL_MAP[op]?._default ?? '?';
    return {
      value: op,
      title,
      shortTitle:
        title === OPERATOR_LABEL_MAP['in']![CollectionSchemaType.DATE] ? '位于' : undefined,
    };
  });
}

export function buildFilterFunc(
  group: CollectionFilter | CollectionFilterGroup | undefined,
  opts: {
    collectionId: string;
    schemas: Record<string, CollectionSchema> | undefined;
    userId?: string;
    state?: ReturnType<typeof getState>;
    curpageId?: string;
  }
): RecordFilter {
  const { collectionId, schemas, userId, curpageId } = opts;
  const buildFilter = (filter: CollectionFilter): RecordFilter => {
    const { propertySchema, relationPropertySchema, targetPropertySchema } = getPropertySchema(
      collectionId,
      filter.property
    );
    const {
      type = CollectionSchemaType.TEXT,
      options = [],
      aggregation,
      targetProperty,
    } = propertySchema ?? {};
    const targetPropertyType = targetPropertySchema?.type ?? CollectionSchemaType.TEXT;

    const buildGetter = (property: string) => {
      // eslint-disable-next-line default-case
      switch (type) {
        case CollectionSchemaType.TITLE:
          return (record: NextBlock) => segmentsToText(titleGetter(record));
        case CollectionSchemaType.CREATED_AT:
          return (record: NextBlock) => {
            const timestamp = createdAtGetter(record);
            return dayjs(timestamp).format(DATE_FORMAT);
          };
        case CollectionSchemaType.UPDATED_AT:
          return (record: NextBlock) => {
            const timestamp = updatedAtGetter(record);
            return dayjs(timestamp).format(DATE_FORMAT);
          };
        case CollectionSchemaType.DATE: {
          return (record: NextBlock) => {
            const segments = genericGetter(property)(record);
            const timestamp = convertSegmentsToTimestamp(segments);
            return timestamp && dayjs(timestamp).format(DATE_FORMAT);
          };
        }
        case CollectionSchemaType.TEXT:
        case CollectionSchemaType.URL:
        case CollectionSchemaType.EMAIL:
        case CollectionSchemaType.PHONE:
          return (record: NextBlock) => segmentsToText(genericGetter(property)(record));
        case CollectionSchemaType.CHECKBOX:
          return (record: NextBlock) => {
            const text = segmentsToText(genericGetter(property)(record));
            return formatCheckBoxValue(text) ? 'YES' : null;
          };
        case CollectionSchemaType.FILE:
          return (record: NextBlock) => {
            const segments = genericGetter(property)(record);
            return (segments ?? []).filter(
              (it) => it.type === TextType.URL && it.fileStorageType === 'internal'
            ).length > 0
              ? '<not_null>'
              : null;
          };
        case CollectionSchemaType.NUMBER:
          return (record: NextBlock) => convertSegmentsToNumber(genericGetter(property)(record));
        case CollectionSchemaType.SELECT:
        case CollectionSchemaType.MULTI_SELECT: {
          const getTagIds = (record: NextBlock) => {
            const segments = genericGetter(property)(record);
            if (segments == null) return undefined;
            const text = segmentsToText(segments).trim();
            const tags = text.split(/\s*,\s*/g);
            return tags
              .map((tag) => options.find((option) => option.value === tag)?.id)
              .filter((it) => !!it);
          };
          return type === CollectionSchemaType.MULTI_SELECT
            ? getTagIds
            : (record: NextBlock) => {
                return getTagIds(record)?.[0];
              };
        }
        case CollectionSchemaType.PERSON:
          return (record: NextBlock) => {
            const userIds = personSegmentsToUserIds(genericGetter(property)(record));
            return userIds !== undefined ? userIds : [];
          };
        case CollectionSchemaType.CREATED_BY:
          return (record: NextBlock) => {
            return record.createdBy !== undefined ? [record.createdBy] : [];
          };
        case CollectionSchemaType.UPDATED_BY:
          return (record: NextBlock) => {
            return record.updatedBy !== undefined ? [record.updatedBy] : [];
          };
        case CollectionSchemaType.RELATION: {
          return (record: NextBlock) => {
            return getRelationRecords(record.uuid, property, opts.state?.blocks).relationRecords;
          };
        }
        case CollectionSchemaType.ROLLUP:
          return (record: NextBlock) => getRollupValue(record.uuid, property, opts.state);
        case CollectionSchemaType.FORMULA:
          return (record: NextBlock) => {
            const formulaTool = getFormulaTool(collectionId, opts.state);

            const type = formulaTool.getType(property);
            const value = formulaTool.getValue(record.uuid, property);
            if (type === formula.ValueTool.dateType) {
              if (value == null) return null;
              return dayjs(value as Date).format(DATE_FORMAT);
            } else if (type === formula.ValueTool.booleanType) {
              return value ? 'YES' : '';
            }
            return value;
          };
        default:
          return () => undefined;
      }
    };

    const isRollup = type === CollectionSchemaType.ROLLUP;
    const getter = buildGetter(filter.property);

    if (filter.operator === 'isEmpty' || filter.operator === 'isNotEmpty') {
      const isEmptyOperator = filter.operator === 'isEmpty';

      if (isRollup) {
        if (!isShowOriginalValue(aggregation)) {
          return (record: NextBlock) => {
            const rollupValue = getRollupValue(record.uuid, filter.property, opts.state);
            return isEmptyOperator ? rollupValue?.isEmpty === true : rollupValue?.isEmpty !== true;
          };
        }

        let formulaValueType: CollectionSchemaType | undefined;

        if (targetPropertyType === CollectionSchemaType.FORMULA) {
          const formulaTool = getFormulaTool(
            relationPropertySchema?.collectionId ?? '',
            opts.state
          );

          formulaValueType = formulaTool.getTypeAsCollectionSchemaType(targetProperty ?? '');

          return () => true;
        }

        return (record: NextBlock) => {
          let { allCellValues = [] } =
            getRollupValue(record.uuid, filter.property, opts.state) ?? {};
          const isShowUniqueValue = aggregation === AggregationAction.SHOW_UNIQUE;

          if (allCellValues.length === 0) {
            if (isShowUniqueValue) return false; // 唯一值默认 any

            if (filter.mode === 'every' || filter.mode === 'any') {
              return false;
            }

            return true;
          }

          if (
            targetPropertyType === CollectionSchemaType.CHECKBOX ||
            formulaValueType === CollectionSchemaType.CHECKBOX
          ) {
            if (isShowUniqueValue) {
              return allCellValues.some(isEmptyOperator ? isNotChecked : isChecked);
            }

            if (filter.mode === 'every') {
              return allCellValues.every(isEmptyOperator ? isNotChecked : isChecked);
            } else if (filter.mode === 'any') {
              return allCellValues.some(isEmptyOperator ? isNotChecked : isChecked);
            }

            return allCellValues.every(isEmptyOperator ? isChecked : isNotChecked);
          }

          if (
            targetPropertyType === CollectionSchemaType.DATE ||
            targetPropertyType === CollectionSchemaType.CREATED_AT ||
            targetPropertyType === CollectionSchemaType.UPDATED_AT ||
            formulaValueType === CollectionSchemaType.DATE
          ) {
            allCellValues = allCellValues.map((item) => {
              return typeof item === 'string' ? dayjs(item).format(DATE_FORMAT) : undefined;
            });
          }

          if (isShowUniqueValue) {
            return allCellValues.some(isEmptyOperator ? isEmpty : isNotEmpty);
          }

          if (filter.mode === 'every') {
            return allCellValues.every(isEmptyOperator ? isEmpty : isNotEmpty);
          } else if (filter.mode === 'any') {
            return allCellValues.some(isEmptyOperator ? isEmpty : isNotEmpty);
          }

          return allCellValues.every(isEmptyOperator ? isNotEmpty : isEmpty);
        };
      }

      if (isEmptyOperator) {
        return (record: NextBlock) => {
          const value = getter(record);
          return value == null || value === '' || (Array.isArray(value) && value.length === 0);
        };
      }

      return (record: NextBlock) => {
        const value = getter(record);
        return !(value == null || value === '' || (Array.isArray(value) && value.length === 0));
      };
    }

    let filterValueType = getFilterValueType(type, collectionId, filter.property, opts.state);
    if (isRollup) {
      let formulaValueType: CollectionSchemaType | undefined;
      const isFormula = targetPropertyType === CollectionSchemaType.FORMULA;

      if (targetPropertyType === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(relationPropertySchema?.collectionId ?? '', opts.state);

        formulaValueType = formulaTool.getTypeAsCollectionSchemaType(targetProperty ?? '');

        return () => true;
      }

      if (isShowOriginalValue(aggregation)) {
        return (record: NextBlock) => {
          const rollupValue = getRollupValue(record.uuid, filter.property, opts.state);
          let allCellValues: (string | string[] | number | undefined)[] =
            rollupValue?.allCellValues ?? [];

          const isShowUniqueValue = aggregation === AggregationAction.SHOW_UNIQUE;

          if (allCellValues.length === 0) {
            if (isShowUniqueValue) return false;

            if (filter.mode === 'every' || filter.mode === 'any') {
              return false;
            }
            return true;
          }

          if (
            targetPropertyType === CollectionSchemaType.DATE ||
            targetPropertyType === CollectionSchemaType.CREATED_AT ||
            targetPropertyType === CollectionSchemaType.UPDATED_AT ||
            formulaValueType === CollectionSchemaType.DATE
          ) {
            allCellValues = allCellValues.map((item) => {
              return typeof item === 'string' ? dayjs(item).format(DATE_FORMAT) : undefined;
            });
          } else if (targetPropertyType === CollectionSchemaType.NUMBER) {
            allCellValues = allCellValues.map((item) => {
              return typeof item === 'string' ? Number(item) : undefined;
            });
          }

          const schemaType = isFormula
            ? formulaValueType ?? CollectionSchemaType.TEXT
            : targetPropertyType;
          const filterValueType = getFilterValueType(schemaType, undefined, undefined, opts.state);
          const operator = OPERATORS[filterValueType][filter.operator];
          const operand = (() => {
            let { value } = filter;
            if (filterValueType === 'number') {
              return Number(value);
            }
            if (filterValueType === 'persons' && value === 'me') {
              return userId;
            }

            if (schemaType === CollectionSchemaType.RELATION) {
              const ids = (String(value) ?? '').split(',');
              const { blocks } = opts.state ?? getState();
              const collection = blocks[targetPropertySchema?.collectionId ?? ''];
              const subNodesSet = new Set(collection?.subNodes);
              const validIds = ids.filter(
                (uuid) =>
                  uuid && (subNodesSet.has(uuid) || collection?.templatePages?.includes(uuid))
              );
              value = validIds.join(',');
            }

            return value;
          })();

          if (isShowUniqueValue) {
            // 原始唯一值统一按 any 处理
            return allCellValues.some((item) => {
              if (schemaType === CollectionSchemaType.RELATION && operand === '') {
                return true;
              }
              if (!operator) return true;
              return (operator as any)(item, operand);
            });
          }

          if (filter.mode === 'every') {
            return allCellValues.every((item) => {
              if (schemaType === CollectionSchemaType.RELATION && operand === '') {
                return true;
              }
              if (!operator) return true;
              return (operator as any)(item, operand);
            });
          } else if (filter.mode === 'any') {
            return allCellValues.some((item) => {
              if (schemaType === CollectionSchemaType.RELATION && operand === '') {
                return true;
              }
              if (!operator) return true;
              return (operator as any)(item, operand);
            });
          }

          return allCellValues.every((item) => {
            if (schemaType === CollectionSchemaType.RELATION && operand === '') {
              return true;
            }
            if (!operator) return true;
            return !(operator as any)(item, operand);
          });
        };
      } else if (isDateAggregation(aggregation)) {
        filterValueType = 'date';
      } else {
        filterValueType = 'number';
      }
    }

    const operator = OPERATORS[filterValueType][filter.operator];
    const operand = (() => {
      const { value } = filter;
      if (filterValueType === 'number') {
        return Number(value);
      }
      if (filterValueType === 'persons' && value === 'me') {
        return userId;
      }
      return value;
    })();

    return operator == null
      ? () => false
      : (record: NextBlock) => {
          if (isRollup) {
            const { dateStartTimestamp, aggregationValue } =
              getRollupValue(record.uuid, filter.property, opts.state) ?? {};
            let value: string | number | undefined = aggregationValue;
            if (isDateAggregation(aggregation)) {
              value = dateStartTimestamp && dayjs(dateStartTimestamp).format(DATE_FORMAT);
            } else if (isPercentAggregation(aggregation)) {
              value =
                typeof aggregationValue === 'string'
                  ? percentToNumber(aggregationValue)
                  : undefined;
            } else {
              value = Number(aggregationValue);
            }

            return (operator as any)(value, operand);
          }

          const value = getter(record);

          if (propertySchema?.type === CollectionSchemaType.RELATION) {
            let ids = (String(operand) ?? '').split(',');
            const { blocks } = getState();
            const collection = blocks[propertySchema.collectionId ?? ''];
            const subNodeSet = new Set(collection?.subNodes);
            const templateSet = new Set(collection?.templatePages);
            ids = ids.map((id) => {
              // feat: auto-filter 如果是模板中心的id，则需要替换为当前页面的
              if (templateSet.has(id)) {
                return curpageId ?? id;
              }
              return id;
            });
            const validIds = ids.filter(
              (uuid) => uuid && (subNodeSet.has(uuid) || templateSet.has(uuid))
            );
            return (operator as any)(value, validIds.join(','));
          }
          return (operator as any)(value, operand);
        };
  };

  const buildGroup = (group: CollectionFilterGroup): RecordFilter => {
    const filters = group.filters
      .filter((it) => isFilterValid(it, schemas))
      .filter((it) => {
        if (it.disable) return false;
        if (it.type === 'filter' && !schemas?.[it.property]) return false;
        if (it.type === 'filter' && ['isEmpty', 'isNotEmpty'].includes(it.operator)) return true;
        if (it.type === 'filter' && it.value === '') return false;
        return true;
      })
      .map((it) => buildFilterPoly(it));
    if (filters.length === 0) {
      return () => true;
    }

    // eslint-disable-next-line default-case
    switch (group.operator) {
      case 'and':
        return (record: NextBlock) => {
          return filters.every((it) => it(record));
        };
      case 'or':
        return (record: NextBlock) => {
          return filters.some((it) => it(record));
        };
    }
  };

  const buildFilterPoly = (
    filter: CollectionFilter | CollectionFilterGroup | undefined,
    default_ = false
  ): RecordFilter => {
    if (filter == null || filter.disable || !isFilterValid(filter, schemas)) {
      return () => default_;
    }

    switch (filter.type) {
      case 'group':
        return buildGroup(filter);
      case 'filter':
        return buildFilter(filter);
      default:
        return () => default_;
    }
  };

  return buildFilterPoly(group, true);
}

export const isFilterValid = (
  filter: CollectionFilter | CollectionFilterGroup | undefined,
  schema: Record<string, CollectionSchema> | undefined
): boolean => {
  if (filter == null) return false;
  if (filter.type === 'filter') {
    const propSchema = schema?.[filter.property];
    if (propSchema == null) {
      return false;
    }
    if (filter.propertyType != null && propSchema.type !== filter.propertyType) {
      return false;
    }
    return true;
  }
  if (filter.filters.length <= 0) return false;
  return !filter.filters.every((filter) => !isFilterValid(filter, schema));
};

export const isFilterEnabled = (
  filter: CollectionFilter | CollectionFilterGroup | undefined,
  schema: Record<string, CollectionSchema> | undefined
): boolean => {
  if (!filter) return false;
  if (filter.disable) return false;
  if (filter.type === 'filter') {
    return isFilterValid(filter, schema);
  }
  if (filter.filters.length <= 0) return false;
  return filter.filters.some((it) => isFilterEnabled(it, schema));
};

export const pruneFilter = <T extends CollectionFilter | CollectionFilterGroup>(
  filter: T,
  schema: Record<string, CollectionSchema>
): T => {
  if (filter.type === 'group') {
    const newFilters = filter.filters
      .filter((it) => isFilterValid(it, schema))
      .map((it) => pruneFilter(it, schema));
    return {
      ...filter,
      filters: newFilters,
    };
  }
  return filter;
};

const isChecked = (value: string | string[] | undefined) => {
  return typeof value === 'string' && formatCheckBoxValue(value);
};

const isNotChecked = (value: string | string[] | undefined) => !isChecked(value);

const isEmpty = (value: string | string[] | undefined) => {
  return (
    (typeof value === 'string' && value === '') ||
    (Array.isArray(value) && value.length === 0) ||
    typeof value === 'undefined'
  );
};

const isNotEmpty = (value: string | string[] | undefined) => !isEmpty(value);

const format = (value: string, type: CollectionSchemaType) => {
  if (type === CollectionSchemaType.NUMBER) {
    let num = parseFloat(value);
    if (Number.isNaN(num)) {
      num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
    }
    return isNaN(num) ? '' : `${num}`;
  } else if (
    type === CollectionSchemaType.CREATED_AT ||
    type === CollectionSchemaType.UPDATED_AT ||
    type === CollectionSchemaType.DATE
  ) {
    const date = dayjs(value, DATE_FORMAT);
    if (date.isValid()) {
      return date.format(DATE_FORMAT);
    }
    return '';
  } else if (type === CollectionSchemaType.CHECKBOX) {
    if (formatCheckBoxValue(value)) {
      return 'YES';
    } else if (value.toUpperCase() === 'NO') {
      return 'NO';
    }
    return '';
  }
  return value;
};

export const updateFilterField = (
  collectionId: string,
  filter: CollectionFilter,
  field: string,
  value: string
) => {
  const schema = cache.blocks[collectionId]?.data?.schema ?? {};
  const capable = (from: CollectionSchemaType, to: CollectionSchemaType) => {
    if (
      (from === CollectionSchemaType.NUMBER ||
        from === CollectionSchemaType.TEXT ||
        from === CollectionSchemaType.TITLE) &&
      (to === CollectionSchemaType.TEXT ||
        to === CollectionSchemaType.TITLE ||
        to === CollectionSchemaType.NUMBER)
    ) {
      return true;
    }
    return false;
  };

  if (field === 'property') {
    if (
      capable(
        schema[filter.property]?.type ?? CollectionSchemaType.TEXT,
        schema[value]?.type ?? CollectionSchemaType.TEXT
      )
    ) {
      filter.value = format(filter.value, schema[value]?.type ?? CollectionSchemaType.TEXT);
    } else {
      filter.value = '';
    }

    if (schema[value]?.type === CollectionSchemaType.ROLLUP) {
      filter.mode = 'any';
    } else {
      filter.mode = undefined;
    }
  }

  // 当切换日期属性范围筛选时候，filter.value格式发生变化，需要清空filter.value
  if (
    field === 'operator' &&
    filter.operator !== value &&
    (filter.operator === 'in' || value === 'in') &&
    dayjs(filter.value, DATE_FORMAT).isValid()
  ) {
    filter.value = '';
  }

  (filter as any)[field] = value;
  const type = schema[filter.property]?.type ?? CollectionSchemaType.TEXT;
  filter.propertyType = type;
  const avaiableOperators = getAvailableOperators(collectionId, filter.property);
  if (!avaiableOperators.map((it) => it.value).includes(filter.operator)) {
    filter.operator = avaiableOperators[0]?.value as CollectionFilter['operator'];
    filter.value = '';
  }
};
export const fixGet = (obj: any, path: (string | number)[]) => {
  if (path.length === 0) return obj;
  return _.get(obj, path);
};
export const changeFilterField = (
  tableInfo: {
    collectionId: string;
    viewId: string;
    path: (string | number)[];
    managerReadonly: boolean | undefined;
  },
  index: number,
  field: string,
  value: string
) => {
  const { viewId, path, collectionId, managerReadonly } = tableInfo;
  const view = getState().collectionViews?.[viewId];
  if (!view) return;

  const newFilter = _.cloneDeep(view.format.filter);
  const filter = fixGet(newFilter, [...path, 'filters', index]) as
    | (CollectionFilter & {
        [key: string]: string;
      })
    | undefined;
  if (!filter) return;
  updateFilterField(collectionId, filter, field, value);

  updateViewFormat(viewId, { filter: newFilter }, managerReadonly);
};
