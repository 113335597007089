import { type FC } from 'react';
import { usePieChartStyle, useUpdateChartFormatStyle } from 'src/hooks/chart';
import { useChartBlockId } from '../chart-config-context';
import { CheckboxText } from 'src/views/logoff/checkbox-text';
import { MultiSwitchText } from '../multi-switch-text';
import { ChartLine } from '../../chart-line';
import { DragProgressbar } from 'src/mind-map/component/drag-progressbar';

const labelPositionData: { pos: 'outside' | 'inside'; desc: string }[] = [
  { pos: 'outside', desc: '外部' },
  { pos: 'inside', desc: '内部' },
];

export const PieStyle: FC = () => {
  const chartBlockId = useChartBlockId();
  const updateChartFormatStyle = useUpdateChartFormatStyle();
  const { pieLabelPosition, showDataLabel, pieInsideRadius, pieOutsideRadius } =
    usePieChartStyle(chartBlockId);
  return (
    <>
      <CheckboxText
        className="mt-4"
        defaultChecked={showDataLabel}
        content="数据标签"
        onCheck={(checked) => {
          updateChartFormatStyle(chartBlockId, {
            showDataLabel: checked,
          });
        }}
      />
      {showDataLabel && (
        <div className="mt-4 text-t2 flex items-center justify-between">
          <div>标签位置</div>
          <MultiSwitchText
            active={labelPositionData.find((v) => v.pos === pieLabelPosition)!}
            values={labelPositionData}
            onSwicth={(value) => {
              updateChartFormatStyle(chartBlockId, {
                pieLabelPosition: value.pos,
              });
            }}
          />
        </div>
      )}
      <ChartLine className="my-4" />
      <div className="text-t2-medium">半径</div>
      <div className="mt-2 text-t2 text-grey3">
        <span>饼图内半径：</span>
        <span className="text-active_color">{pieInsideRadius}%</span>
      </div>
      <div className="pr-8">
        <DragProgressbar
          className="mt-4 p-0"
          range={{ min: 0, max: 100, step: 5 }}
          scale={pieInsideRadius}
          onZoom={(scale) => {
            updateChartFormatStyle(chartBlockId, {
              pieInsideRadius: scale,
            });
          }}
        />
      </div>
      <div className="mt-10 text-t2 text-grey3">
        <span>饼图外半径：</span>
        <span className="text-active_color">{pieOutsideRadius}%</span>
      </div>
      <div className="pr-8">
        <DragProgressbar
          className="mt-6 p-0"
          range={{ min: 0, max: 100, step: 5 }}
          scale={pieOutsideRadius}
          onZoom={(scale) => {
            updateChartFormatStyle(chartBlockId, {
              pieOutsideRadius: scale,
            });
          }}
        />
      </div>
    </>
  );
};
