import type { FC } from 'react';
import { Input } from 'src/common/components/input';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';

import { SettingModalCommon, SettingProvider } from '../common';
import { useModel } from 'src/common/create-model';

export const SpaceName: FC = () => {
  const {
    spaceSettingState: { spaceName, setSpaceName, saveSpaceSetting },
  } = useModel(SettingProvider);
  return (
    <>
      <SettingModalCommon.SettingDivider className="mb-2.5" />
      <div className={SettingModalCommon.commonStyles.name}>空间名</div>
      <Input
        {...bindDataTestId(TestIds.settingSpaceInput)}
        maxLength={20}
        placeholder="请输入空间名，长度需在2-20个字符之间"
        className={SettingModalCommon.commonStyles.input}
        value={spaceName || ''}
        onChange={(str) => setSpaceName(str || '')}
        onEnter={saveSpaceSetting}
      />
    </>
  );
};
