import { emitter } from '@flowus/common/utils/emitter';
import isHotkey from 'is-hotkey';
import { throttle } from 'lodash-es';
import type { PDFDocumentLoadingTask, PDFDocumentProxy } from 'pdfjs-dist';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { PDFCursorTools } from 'pdfjs-dist/lib/web/pdf_cursor_tools';
import { PDFRenderingQueue } from 'pdfjs-dist/lib/web/pdf_rendering_queue';
import {
  EventBus,
  NullL10n,
  PDFFindController,
  PDFHistory,
  PDFLinkService,
  PDFViewer,
} from 'pdfjs-dist/web/pdf_viewer';
import { isBuildIn, VITE_CDN_HOST } from 'src/env';
import { PDFThumbnailViewer } from '../lib/pdf_thumbnail_viewer';
import type { OP, PDFAnnotation, PDFRect, SelectionRange } from '../type';
import { AnnotationType, OperationType, PermissionFlag, TextLayerMode } from '../type';
import { AnnotationRender } from './annotation-render';
import { HistoryStack } from './history';
import { RectAnnotationEditor } from './rect-annotation-editor';
import { renderSelectionBorder } from './render-selection-border';
import { ShapeEditorManager } from './shape-editor-manager';
import { WebViewerWheel } from './web-viewer-wheel';

// GlobalWorkerOptions.workerSrc =
//   __HOST_LOCAL__ && !isFlowUsApp.check
//     ? '../../node_modules/pdfjs-dist/build/pdf.worker.js'
//     : 'https://cdn.allflow.cn/assets/pdf/pdf.worker.min.js';

// TODO VITE_CDN_HOST
GlobalWorkerOptions.workerSrc = isBuildIn()
  ? `${VITE_CDN_HOST}pdfjs/pdf.worker.min.js`
  : `${VITE_CDN_HOST}assets/pdf/pdf.worker.min.js`;

const cMapUrl = isBuildIn() ? `${VITE_CDN_HOST}/cmaps/` : `${VITE_CDN_HOST}assets/cmaps/`;

export class PDFViewApplication {
  public pdfDocument?: PDFDocumentProxy;
  public loadingTask?: PDFDocumentLoadingTask;
  public pdfViewer?: PDFViewer;
  public pdfThumbnailViewer?: PDFThumbnailViewer;
  public pdfLinkService?: any;
  public findController?: any;
  public pdfRenderingQueue?: any;
  public eventBus?: any;
  public pdfCursorTools?: any;

  public isThumbnailViewEnabled = true;

  public shapeEditorManager?: ShapeEditorManager;
  public annotationType = AnnotationType.NONE;
  public annotationColor = '#000';
  public annotationLineWidth = 1;
  public annotations: Record<string, PDFAnnotation> = {}; // 为了滚动到指定页面的时候渲染
  public annotationRenders: Record<string, AnnotationRender> = {};

  public disposed = false;
  public history?: HistoryStack;

  public wheelUnusedTicks = 0;
  public webViewerWheel?: WebViewerWheel;

  public showAISideBar = false;

  public _selectionRange?: SelectionRange;

  public onPassword?: Function;
  public onProgress?: Function;
  public onCopyPermission?: Function;
  public onPagesInit?: () => void;
  public onAddAnnotation?: (annotation: PDFAnnotation) => void;
  public onUpdateAnnotation?: (annotation: PDFAnnotation) => void;
  public onDeleteAnnotation?: (annotation: PDFAnnotation) => void;
  public onAnnotationContextMenu?: (event: MouseEvent, annotation: PDFAnnotation) => void;
  public onHistoryChange?: (history: HistoryStack) => void;
  public onAIButtonClick?: (page: number, str: string, rect?: PDFRect) => void;

  constructor(
    private fileUrl: string | Uint8Array,
    public scrollContainer: HTMLDivElement,
    public thumbnailContainer: HTMLDivElement,
    public pageContainer: HTMLDivElement,
    options?: {
      color?: string;
      annotationType?: AnnotationType;
      annotations?: Record<string, PDFAnnotation>;
      showAISideBar?: boolean;
    }
  ) {
    if (options?.annotations) {
      this.annotations = options?.annotations;
    }
    if (options?.color) {
      this.annotationColor = options.color;
    }
    if (options?.annotationType) {
      this.setAnnotationType(options.annotationType);
    }
    if (options?.showAISideBar) {
      this.setAIShowSideBar(true);
    }

    this.history = new HistoryStack(this);
  }

  async loadPDF(password?: string) {
    this.initialize();

    let params: any;
    if (typeof this.fileUrl === 'string') {
      params = {
        url: this.fileUrl,
        cMapUrl,
        cMapPacked: true,
        rangeChunkSize: 65536,
        disableAutoFetch: false, // true 的 时候会影响到 print, 但是可以分片
        disableStream: true,
        password,
      };
    } else {
      params = this.fileUrl;
    }

    this.loadingTask = getDocument(params);
    if (!this.loadingTask) return;

    if (this.onPassword) {
      this.loadingTask.onPassword = this.onPassword;
    }

    this.loadingTask.onProgress = ({ loaded, total }: { loaded: number; total: number }) => {
      this.onProgress?.(loaded, total);
      return loaded / total;
    };

    this.pdfDocument = await this.loadingTask.promise;
    // 这是处理 pdf 未加载成功就关掉组件，导致pdf加载完成后未被销毁
    if (this.disposed) {
      void this.loadingTask?.destroy();
      void this.pdfDocument.destroy();
      return;
    }
    this.loadViewer();
  }

  initialize = () => {
    const eventBus = new EventBus();
    this.eventBus = eventBus;

    const pdfRenderingQueue = new PDFRenderingQueue();
    pdfRenderingQueue.onIdle = this.cleanup;
    pdfRenderingQueue.isThumbnailViewEnabled = this.isThumbnailViewEnabled;
    this.pdfRenderingQueue = pdfRenderingQueue;

    const pdfLinkService = new PDFLinkService({ eventBus });
    this.pdfLinkService = pdfLinkService;

    const findController = new PDFFindController({
      eventBus,
      linkService: pdfLinkService,
    });
    this.findController = findController;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.pdfViewer = new PDFViewer({
      container: this.scrollContainer,
      viewer: this.pageContainer,
      l10n: NullL10n,
      eventBus,
      linkService: pdfLinkService,
      findController,
      renderingQueue: pdfRenderingQueue,
      textLayerMode: TextLayerMode.ENABLE_ENHANCE,
    });
    pdfRenderingQueue.setViewer(this.pdfViewer);
    pdfLinkService.setViewer(this.pdfViewer);

    this.pdfThumbnailViewer = new PDFThumbnailViewer({
      container: this.thumbnailContainer,
      eventBus,
      renderingQueue: pdfRenderingQueue,
      linkService: pdfLinkService,
      l10n: NullL10n,
    });
    pdfRenderingQueue.setThumbnailViewer(this.pdfThumbnailViewer);

    // history => bookmark
    const pdfHistory = new PDFHistory({
      linkService: pdfLinkService,
      eventBus,
    });
    pdfLinkService.setHistory(pdfHistory);

    const pdfCursorTools = new PDFCursorTools({
      container: this.scrollContainer,
      eventBus,
      cursorToolOnLoad: 0,
    });
    this.pdfCursorTools = pdfCursorTools;
  };

  loadViewer = () => {
    const { pdfDocument, pdfViewer, eventBus } = this;
    // if(location.href.includes('localhost')) {
    //   window.pdfViewer = pdfViewer;
    // }

    if (!pdfDocument || !pdfViewer || !eventBus) return;

    pdfViewer.setDocument(pdfDocument);
    this.pdfLinkService?.setDocument(pdfDocument, null);
    this.pdfThumbnailViewer?.setDocument(pdfDocument);

    pdfViewer.firstPagePromise
      .then(() => {
        eventBus.on('pagerendered', this.onPageRendered);
        eventBus.on('textlayerrendered', this.onTextLayerRendered);
        eventBus.on('rotationchanging', (event: any) => {
          if (this.pdfThumbnailViewer) {
            this.pdfThumbnailViewer.pagesRotation = event.pagesRotation;
          }

          this.pdfRenderingQueue.renderHighestPriority();
          pdfViewer.currentPageNumber = event.pageNumber;
        });

        eventBus.on('resize', () => {
          if (!pdfViewer) return;

          pdfViewer.updateContainerHeightCss();

          const { currentScaleValue } = pdfViewer;
          if (
            currentScaleValue === 'auto' ||
            currentScaleValue === 'page-fit' ||
            currentScaleValue === 'page-width'
          ) {
            pdfViewer.currentScaleValue = currentScaleValue;
          }
          pdfViewer.update();
        });

        eventBus.on('annotationlayerrendered', (event: any) => {
          if (!pdfViewer) return;

          if (
            this.annotationType === AnnotationType.PENCIL ||
            this.annotationType === AnnotationType.POLYGON ||
            this.annotationType === AnnotationType.ELLIPSE
          ) {
            this.shapeEditorManager?.ensureShapeEditor(
              event.source,
              this.annotationType,
              this.annotationColor
            );
          }
        });

        this.onPagesInit?.();
        void this.checkPermissions();

        window.addEventListener('resize', this.handleResize);
        this.webViewerWheel = new WebViewerWheel(pdfViewer, this.scrollContainer);
        this.shapeEditorManager = new ShapeEditorManager(pdfViewer, this.addAnnotation);
      })
      .catch(() => {
        // this.onPagesInit?.();
      })
      .then(() => {
        pdfViewer.update();
      });
  };

  cleanup() {
    this.pdfViewer?.cleanup();
    this.pdfThumbnailViewer?.cleanup();
  }

  checkPermissions = async () => {
    if (!this.pdfDocument) return;

    const permissions = await this.pdfDocument.getPermissions();
    if (!permissions) return;

    if (!permissions.includes(PermissionFlag.COPY)) {
      const handleKeydown = (event: KeyboardEvent) => {
        if (isHotkey('mod+a')(event)) {
          event.preventDefault();
          return;
        }

        if (isHotkey('mod+c')(event)) {
          const selection = window.getSelection();
          if (!selection) return;

          const range = selection.getRangeAt(0);
          if (range.collapsed) return;

          const container = range.commonAncestorContainer;
          const { endContainer } = range;
          const { startContainer } = range;

          if (
            this.pageContainer.contains(container) ||
            this.pageContainer.contains(endContainer) ||
            this.pageContainer.contains(startContainer)
          ) {
            event.preventDefault();
            this.onCopyPermission?.();
          }
        }
      };

      window.addEventListener('keydown', handleKeydown);
    }
  };

  handleResize = throttle(() => {
    if (!this.pdfViewer) return;

    this.pdfViewer?.eventBus?.dispatch('resize', {});
  }, 60);

  onPageRendered = (event: any) => {
    if (!this.pdfViewer) return;

    const { pageNumber } = event;
    const {
      div,
      textLayer: { textLayerDiv },
    } = this.pdfViewer.getPageView(pageNumber - 1);

    const annotationContainer = div.querySelector(
      '.next-space-annotationLayer'
    ) as HTMLElement | null;
    if (annotationContainer) {
      annotationContainer.style.transform = textLayerDiv.style.transform;
      annotationContainer.style.transformOrigin = textLayerDiv.style.transformOrigin;
    }
  };

  onTextLayerRendered = (data: any) => {
    const { pageNumber, source } = data;

    Object.values(this.annotations).forEach((annotation) => {
      if (annotation.pageNumber !== pageNumber) return;
      if (annotation.live === false) return;

      this.renderAnnotation(annotation);
    });

    const textDiv = source.textLayerDiv as HTMLDivElement;
    if (!this.pdfViewer) return;

    if (this.selectionRange) {
      renderSelectionBorder(this.selectionRange, this.pdfViewer, this.onAIButtonClick);
    }

    const handleMousedown = () => {
      const nodes = document.querySelectorAll('.pdf-selection-box');
      nodes.forEach((item) => item.remove());
      this.selectionRange = undefined;

      this.pageContainer.classList.add('disableAnnotation');
      document.addEventListener('mouseup', () => {
        this.pageContainer.classList.remove('disableAnnotation');
      });
    };
    textDiv.addEventListener('mousedown', handleMousedown);

    // eslint-disable-next-line no-new
    new RectAnnotationEditor(textDiv, pageNumber, this);

    emitter.emit('pdfRenderPage', data);
  };

  renderAnnotation = (annotation: PDFAnnotation) => {
    if (!this.pdfViewer) return;

    this.annotationRenders[annotation.uuid] = new AnnotationRender(
      this.pdfViewer,
      annotation,
      this.getAnnotationType,
      this.updateAnnotation,
      this.deleteAnnotation,
      this.onAnnotationContextMenu
    );
  };

  addAnnotation = (annotation: PDFAnnotation) => {
    this.annotations[annotation.uuid] = annotation;
    this.onAddAnnotation?.(annotation);

    this.renderAnnotation(annotation);

    this.history?.record({ type: OperationType.ADD, uuid: annotation.uuid });
  };

  updateAnnotation = (annotation: PDFAnnotation, needUpdateUI = true) => {
    this.history?.record(
      { type: OperationType.UPDATE, uuid: annotation.uuid, patch: annotation },
      this.annotations[annotation.uuid]
    );

    this.annotations[annotation.uuid] = annotation;
    this.onUpdateAnnotation?.(annotation);

    if (needUpdateUI) {
      this.annotationRenders[annotation.uuid]?.update(annotation);
    }
  };

  deleteAnnotation = (annotation: PDFAnnotation, needUpdateUI = true) => {
    const oldAnnotation = this.annotations[annotation.uuid];
    if (!oldAnnotation) return;

    oldAnnotation.live = false;
    this.onDeleteAnnotation?.(annotation);

    this.history?.record({ type: OperationType.DELETE, uuid: annotation.uuid });

    if (needUpdateUI) {
      this.annotationRenders[annotation.uuid]?.remove();
      delete this.annotationRenders[annotation.uuid];
    }
  };

  syncAddAnnotation = (annotation: PDFAnnotation) => {
    this.annotations[annotation.uuid] = annotation;
    this.renderAnnotation(annotation);
  };

  syncUpdateAnnotation = (annotation: PDFAnnotation) => {
    this.annotations[annotation.uuid] = annotation;
    this.annotationRenders[annotation.uuid]?.update(annotation);
  };

  setAnnotationType = (type: AnnotationType) => {
    if (this.annotationType === type) return;

    this.annotationType = type;

    this.pageContainer.classList.remove(
      AnnotationType.ERASER,
      AnnotationType.POLYGON,
      AnnotationType.RECTANGLE,
      AnnotationType.HIGHLIGHT,
      AnnotationType.ELLIPSE
    );
    if (
      type === AnnotationType.ERASER ||
      type === AnnotationType.POLYGON ||
      type === AnnotationType.ELLIPSE ||
      type === AnnotationType.RECTANGLE ||
      type === AnnotationType.HIGHLIGHT
    ) {
      this.pageContainer.classList.add(type);
    }

    if (type === AnnotationType.POLYGON || type === AnnotationType.ELLIPSE) {
      this.shapeEditorManager?.enableAll(type, this.annotationColor);
    } else {
      this.shapeEditorManager?.disableAll();
    }
  };

  setAnnotationColor = (color: string) => {
    this.annotationColor = color;
    this.shapeEditorManager?.setEditorColor(color);
  };

  set selectionRange(params: SelectionRange | undefined) {
    this._selectionRange = params;
    if (this._selectionRange) {
      renderSelectionBorder(this._selectionRange, this.pdfViewer, this.onAIButtonClick);
    }
  }
  get selectionRange() {
    return this._selectionRange;
  }
  setAIShowSideBar = (show: boolean) => {
    this.showAISideBar = show;
    if (!show) {
      const nodes = document.querySelectorAll('.pdf-selection-box');
      nodes.forEach((item) => item.remove());
    }
  };

  getAIShowSideBar = () => {
    return this.showAISideBar;
  };

  getAnnotationType = () => this.annotationType;
  getAnnotationColor = () => this.annotationColor;
  getAnnotations = () => this.annotations;

  redo = () => this.history?.redo();
  undo = () => this.history?.undo();

  applyOP = (op: OP) => {
    const annotation = this.annotations[op.uuid];
    if (!annotation) return;

    switch (op.type) {
      case OperationType.ADD: {
        annotation.live = true;
        this.renderAnnotation(annotation);
        break;
      }

      case OperationType.DELETE: {
        annotation.live = false;
        this.annotationRenders[op.uuid]?.remove();
        delete this.annotationRenders[op.uuid];
        break;
      }

      case OperationType.UPDATE: {
        if (!op.patch) return;

        const newAnnotation = { ...annotation, ...op.patch };
        this.annotations[op.uuid] = newAnnotation;
        this.annotationRenders[op.uuid]?.update(newAnnotation);
        break;
      }

      default:
        break;
    }
  };

  dispose() {
    window.removeEventListener('resize', this.handleResize);

    this.disposed = true;
    this.webViewerWheel?.destroy();

    if (this.loadingTask) {
      void this.loadingTask.destroy();
    }

    if (this.pdfDocument) {
      void this.pdfDocument.destroy();
    }
  }
}

// 密码相关
// https://stackoverflow.com/questions/40576914/pdf-js-password-callback-issue-when-wrong-password-is-entered
// https://github.com/mozilla/pdf.js/blob/7f6a607ea537a6237802fe11e211afb2b19af9cf/web/app.js#L649
// https://github.com/mozilla/pdf.js/blob/7f6a607ea537a6237802fe11e211afb2b19af9cf/src/shared/util.js#L391

// 字体相关
// https://stackoverflow.com/questions/32764773/what-is-a-pdf-bcmap-file/40634723
