import type { PcIconName } from '@flowus/common';
import { getLocalObject, setLocalStorage } from '@flowus/common';
import { isPageLike } from '@flowus/common/block/permissions';
import { Tooltip } from '@flowus/common/components/tooltip';
import { cx } from '@flowus/common/cx';
import { PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState, type FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { ILLEGAL_TEXT } from 'src/common/const';
import { request } from 'src/common/request';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { getCurrentUser } from 'src/hooks/user';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { ViewPath } from 'src/utils';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { SegmentPlainText } from '../../aside/toc/helper';
import { Breadcrumb } from '../../header/breadcrumb';
import { SettingModalCommon } from '../common';
import { SpaceShareSubscribe } from '../space-share-subscribe';
import { useCloseSettingModal } from '../use-open-setting-modal';

const KEY = 'only_watch_my_share_pages';
export const SpaceSharePages: FC = () => {
  const domRef = useRef<HTMLDivElement>(null);
  const [onlyWatchMine, setOnlyWatchMine] = useState(getLocalObject(KEY) === 'true');
  const currentSpace = useCurrentSpace();
  // 如果有值就展示该页面的订阅人数
  const [showSubscribeItem, setShowSubscribeItem] = useState<{
    uuid: string;
    subscribedNum: number;
  }>();

  const [pageItems, setPageItems] =
    useState<Awaited<ReturnType<typeof request.infra.getSpaceSharedPages>>['results']>();
  const result = useMemo(() => {
    if (!onlyWatchMine) return pageItems;
    return pageItems?.filter((p) => {
      return cache.blocks[p.uuid]?.createdBy === getCurrentUser().uuid;
    });
  }, [onlyWatchMine, pageItems]);

  useEffect(() => {
    if (currentSpace.uuid) {
      void request.infra.getSpaceSharedPages(currentSpace.uuid).then((res) => {
        dispatch(
          blocksActions.update({ blocks: res.recordMap.blocks as Record<string, NextBlock> })
        );
        // 排个序
        const ret = res.results?.sort((r1, r2) => {
          const r1CreateAt = res.recordMap.blocks?.[r1.uuid]?.permissions.find(
            (p) => p.type === PermissionType.PUBLIC
            // @ts-ignore typecheck
          )?.createdAt;
          const r2CreateAt = res.recordMap.blocks?.[r2.uuid]?.permissions.find(
            (p) => p.type === PermissionType.PUBLIC
            // @ts-ignore typecheck
          )?.createdAt;
          return r2CreateAt - r1CreateAt;
        });
        setPageItems(ret);
      });
    }
  }, [currentSpace.uuid]);

  const content = useMemo(() => {
    const count = result?.length ?? 0;

    if (showSubscribeItem) {
      return (
        <SpaceShareSubscribe
          pageId={showSubscribeItem.uuid}
          // subscribeNum={showSubscribeItem.subscribedNum}
          goBack={() => {
            setShowSubscribeItem(undefined);
          }}
          onKickout={() => {
            // subscribedNum减1
            setPageItems((items) => {
              const rest = items?.filter((item) => item.uuid !== showSubscribeItem.uuid);
              if (rest) {
                return [
                  ...rest,
                  { ...showSubscribeItem, subscribedNum: showSubscribeItem.subscribedNum - 1 },
                ];
              }
              return items;
            });
            setShowSubscribeItem({
              ...showSubscribeItem,
              subscribedNum: showSubscribeItem.subscribedNum - 1,
            });
          }}
        />
      );
    }

    return (
      <>
        <SettingModalCommon.Header title="公开分享" />
        {/* <RebatePlan /> */}
        <SettingModalCommon.Item
          leftChild={`所有正在公开分享的页面（${count}）`}
          rightChild={
            <>
              <div className="flex items-center">
                <Icon
                  className={cx(onlyWatchMine ? 'text-active_color' : 'dark:text-white')}
                  name={onlyWatchMine ? 'IcCheckboxCheck' : 'IcCheckbox'}
                  size="middle"
                  onClick={() => {
                    setLocalStorage(KEY, onlyWatchMine ? 'false' : 'true');
                    setOnlyWatchMine(!onlyWatchMine);
                  }}
                />
                <span className="ml-1 text-t3">只看我创建的页面</span>
              </div>
            </>
          }
        />
        <SettingModalCommon.SettingDivider />
        <div className="mt-2.5 space-y-2">
          <Virtuoso
            customScrollParent={domRef.current || undefined}
            totalCount={count}
            overscan={100}
            itemContent={(index) => {
              const info = result?.[index];
              if (!info) return null;
              return (
                <PageItem
                  uuid={info.uuid}
                  subscribedNum={info.subscribedNum}
                  gotoSubscribePage={() => {
                    setShowSubscribeItem(info);
                  }}
                />
              );
            }}
          />
        </div>
      </>
    );
  }, [onlyWatchMine, result, showSubscribeItem]);

  return (
    <div className={SettingModalCommon.commonStyles.container} ref={domRef}>
      {content}
    </div>
  );
};

const useShowItem = (uuid: string) => {
  const {
    password,
    allowSubscribe,
    allowDuplicate,
    allowDownload,
    allowSeo,
    publicRole,
    accessFee = 0,
  } = usePermissions(uuid);

  return [
    {
      icon: 'IcMoney',
      desc: '付费设置',
      status: accessFee > 0 ? '付费分享' : '免费分享',
      isHidden: __PRIVATE__ || __BUILD_IN__,
      enable: accessFee > 0,
    },
    {
      icon: 'IcLock',
      desc: '开启密码',
      status: password ? '已开启' : '未开启',
      enable: password,
    },

    {
      icon: 'IcSubscription',
      desc: '页面订阅',
      status: allowSubscribe ? '允许' : '未开启',
      isHidden: __PRIVATE__,
      enable: allowSubscribe,
    },
    {
      icon: 'IcCopy',
      desc: '页面拷贝',
      status: allowDuplicate ? '允许' : '未开启',
      enable: allowDuplicate,
    },
    {
      icon: 'IcMenuPrint',
      desc: '页面下载、打印',
      status: allowDownload ? '允许' : '未开启',
      enable: allowDownload,
    },
    {
      icon: 'IcComment',
      desc: '开启评论',
      status: publicRole === PermissionRole.COMMENTER ? '已开启' : '未开启',
    },
    {
      icon: 'IcMenuOriginalLink',
      desc: '搜索引擎推广',
      status: allowSeo ? '允许' : '未开启',
      enable: allowSeo,
    },
  ].filter((i) => !i.isHidden);
};

interface ItemProps {
  uuid: string;
  subscribedNum: number;
  gotoSubscribePage: () => void;
}
const PageItem: FC<ItemProps> = (props) => {
  const { uuid, subscribedNum } = props;
  const block = usePickBlock(uuid, ['permissions']);
  const history = useHistory();
  const closeSettingModal = useCloseSettingModal();
  const { illegal, role, allowSubscribe } = usePermissions(uuid);
  const title = illegal ? ILLEGAL_TEXT : <SegmentPlainText uuid={uuid} />;
  const isWriter = role === PermissionRole.WRITER || role === PermissionRole.EDITOR;
  const createdAt = useMemo(() => {
    const permission = block?.permissions.find((p) => p.type === PermissionType.PUBLIC);
    // @ts-ignore typecheck
    return permission?.createdAt;
  }, [block?.permissions]);

  const data = useShowItem(uuid);

  const copyPageLink = async () => {
    if (isPageLike(block?.type)) {
      void writeTextInClipboard(`${getLocationOrigin()}${isWriter ? '/' : ViewPath.share}${uuid}`);
    } else {
      void writeTextInClipboard(
        `${getLocationOrigin()}${isWriter ? ViewPath.preview : ViewPath.share}${uuid}`
      );
    }
  };
  const gotoPage = () => {
    if (isPageLike(block?.type)) {
      history.push(`${isWriter ? '/' : ViewPath.share}${uuid}`);
    } else {
      history.push(`${isWriter ? ViewPath.preview : ViewPath.share}${uuid}`);
    }
    closeSettingModal();
  };

  return (
    <div className="mt-2 space-y-1">
      <div className="flex items-center justify-between">
        <div
          className="min-w-[20px] max-w-[300px] flex items-center cursor-pointer animate-hover p-1 space-x-1"
          onClick={gotoPage}
        >
          <IconTrigger
            blockId={uuid}
            trigger={false}
            iconSize={18}
            defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
          />
          <span className="text-ellipsis">{title}</span>
        </div>
        <div
          className="flex items-center p-1 animate-hover"
          onClick={() => {
            void copyPageLink();
          }}
        >
          <Icon name={'IcLink'} size="middle" />
        </div>
      </div>
      <div className="ml-8">
        <Breadcrumb pageId={uuid} styleVariant="messages" hideIfPageIsRoot={true} />
      </div>
      <div className="mt-0 ml-9 flex items-center justify-between ">
        <div className="flex items-center">
          <span className="text-t4 text-grey3 space-x-1">
            <span>{dayjs(createdAt).format('YY-MM-DD HH:mm')}</span>
            <span>{'分享'}</span>
          </span>
          {allowSubscribe && (
            <div
              className="flex items-center ml-2 animate-hover p-1 text-grey3 space-x-1"
              onClick={() => {
                props.gotoSubscribePage();
              }}
            >
              <span className="text-t4">
                {!subscribedNum ? '无人订阅' : `${subscribedNum} 人订阅`}
              </span>
              <Icon name={'IcArrowUnfold'} size="xxxsmall" />
            </div>
          )}
        </div>
        <Tooltip
          interactive={true}
          theme="none"
          popupClass={'next-modal'}
          animation="shift-away"
          maxWidth={'unset'}
          placement="left"
          popup={<ShareSetting pageId={uuid} gotoPage={gotoPage} isWriter={isWriter} />}
        >
          <div className="flex items-center self-end space-x-3">
            {data.map((item) => (
              <Icon
                name={item.icon as IconName}
                size="small"
                className={cx({
                  'text-grey4': !item.enable,
                })}
              />
            ))}
            {/* {!__PRIVATE__ && (
              <Icon
                name={'IcMoney'}
                size="small"
                className={cx({
                  'text-grey4': accessFee === 0,
                })}
              />
            )}
            <Icon
              name={'IcLock'}
              size="small"
              className={cx({
                'text-grey4': !password,
              })}
            />
            {!__PRIVATE__ && (
              <Icon
                name={'IcSubscription'}
                size="small"
                className={cx({
                  'text-grey4': !allowSubscribe,
                })}
              />
            )}
            <Icon
              name={'IcCopy'}
              size="small"
              className={cx({
                'text-grey4': !allowDuplicate,
              })}
            />
            <Icon
              name={'IcMenuPrint'}
              size="small"
              className={cx({
                'text-grey4': !allowDownload,
              })}
            />
            <Icon
              name={'IcComment'}
              size="small"
              className={cx({
                'text-grey4': publicRole !== PermissionRole.COMMENTER,
              })}
            />
            <Icon
              name={'IcMenuOriginalLink'}
              size="small"
              className={cx({
                'text-grey4': !allowSeo,
              })}
            /> */}
          </div>
        </Tooltip>
      </div>
      <div className="border-b border-grey6 w-full h-px mt-2" />
    </div>
  );
};

interface ShareSettingProps {
  isWriter: boolean;
  pageId: string;
  gotoPage: () => void;
}
const ShareSetting: FC<ShareSettingProps> = (props) => {
  const { pageId, gotoPage, isWriter } = props;
  const data = useShowItem(pageId);

  return (
    <div className="w-[360px] pt-2">
      <div className="w-full px-4 select-none">
        {data.map((d, index) => {
          return (
            <div key={index} className="flex items-center h-10 justify-between">
              <div className="flex items-center">
                <Icon size="middle" name={d.icon as PcIconName} />
                <span className="text-t2 ml-2">{d.desc}</span>
              </div>
              <span
                className={cx('text-t2', {
                  'text-grey3': !d.enable,
                })}
              >
                {d.status}
              </span>
            </div>
          );
        })}
      </div>
      {isWriter && (
        <div
          className="px-4 flex items-center h-10 border-t border-grey6 cursor-pointer hover:bg-black_006"
          onClick={gotoPage}
        >
          <Icon size="middle" name={'IcTitleShare'} className="text-grey3" />
          <span className="ml-2 text-t2 text-grey3">修改分享权限设置</span>
        </div>
      )}
    </div>
  );
};
