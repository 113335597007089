import { cx } from '@flowus/common/cx';
import { cloneDeep, debounce, floor } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DragHandle } from 'src/common/components/drag-handle';
import { TransitionBox } from 'src/common/components/transition-box';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { Images } from 'src/image';
import { useIsMobileSize } from 'src/services/app/hook';
import { $searchParams } from 'src/utils';
import { toggleBodyScrollbar } from '@flowus/common/utils/toggle-body-scrollbar';
import type { CalcSizeParams, MoveBarProps, ResizeElementProps } from './type';
import { BarType } from './type';

const calcSize = (params: CalcSizeParams) => {
  const {
    size,
    stateSize,
    blockFullWidth,
    breakPoints = true,
    widthBreakPoints,
    maxWidth,
    minWidth,
    fullHeight,
    maxHeight,
    minHeight,
  } = params;

  const newSize = cloneDeep(size);
  const resultSize = { ...stateSize };
  if (!blockFullWidth) {
    // 吸附节点
    if (breakPoints) {
      [...new Set([...widthBreakPoints, maxWidth].filter((n) => n > 0))]
        .sort((a, b) => b - a)
        .some((i) => {
          const lessWidth = Math.abs(i - newSize.width);
          if (lessWidth <= 15) {
            newSize.width = i;
            return true;
          }
          return false;
        });
    }

    if (newSize.width >= maxWidth) {
      if (maxWidth) {
        resultSize.width = maxWidth;
      }
    } else if (newSize.width <= minWidth) {
      resultSize.width = minWidth;
    } else {
      resultSize.width = newSize.width;
    }
  } else {
    resultSize.width = maxWidth;
  }
  if (!fullHeight) {
    // 无限制
    if (!maxHeight && !minHeight) {
      resultSize.height = newSize.height;
      // 限制最大/最小
    } else if (minHeight || maxHeight) {
      if (minHeight && newSize.height <= minHeight) {
        resultSize.height = minHeight;
      } else if (maxHeight && newSize.height > maxHeight) {
        resultSize.height = maxHeight;
      } else {
        resultSize.height = newSize.height;
      }
    }
  } else {
    resultSize.height = newSize.height;

    if (maxHeight) {
      resultSize.height = maxHeight;
    }
  }

  resultSize.height = floor(resultSize.height, 2);
  resultSize.width = floor(resultSize.width, 2);

  return { size: resultSize, blockFullWidth: resultSize.width === maxWidth };
};

// #region element
/** 可拖拽大小的元素，推荐搭配useResize使用 */
export const ResizeElement: FC<ResizeElementProps> = memo((props) => {
  const {
    readonly,
    resizeHeight,
    resizeWidth = true,
    children,
    className,
    maxHeight = null,
    maxWidth = DEFAULT_PAGE_WIDTH,
    defaultHeight = 500,
    defaultWidth, // width不要给默认值
    minWidth = 100,
    debounceSize = 0,
    minHeight = 0,
    defaultBlockFullWidth = false,
    doubleResize,
    fullHeight,
    disableStyle,
    onChange,
    onRenderSize,
    childrenClassName,
    widthBreakPoints = [maxWidth, minWidth],
    customFooter,
    outerLayoutClassName,
    outerLayoutStyle = {},
    onKeyDownMoveBar,
    onKeyUpMoveBar,
    ...reset
  } = props;
  const isMobileSize = useIsMobileSize();
  const elementRef = useRef<HTMLDivElement>(null);
  const debounceDelta = useRef({
    deltaX: 0,
    deltaY: 0,
  });

  /** 是否处于拖拽状态 */
  const [isMove, setIsMove] = useState(false);

  /** 最后赋值的size */
  const [resize, setResize] = useState<{ width: number; height: number }>({
    width: defaultWidth,
    height: defaultHeight,
  });

  /** 最后一次手动修改 */
  const lastSize = useRef<{
    size: { width: number; height: number };
    blockFullWidth: boolean;
  }>({
    size: resize,
    blockFullWidth: defaultBlockFullWidth,
  });

  const realSize = useDebounceElementSize(elementRef.current, { mode: 'throttle', wait: 200 });

  /** 真实宽高发生变化时同步到外面 */
  useEffect(() => {
    onRenderSize?.({
      width: realSize.width || 0,
      height: realSize.height || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realSize]);

  /** 开始拖拽前的size */
  const startSize = useRef<{ width: number; height: number }>({
    width: elementRef.current?.getBoundingClientRect().width || 0,
    height: elementRef.current?.getBoundingClientRect().height || 0,
  });

  const changeSize = useCallback(
    (params: MoveBarProps) => {
      if (!elementRef.current) return null;
      if (!startSize.current.width) {
        startSize.current.width = elementRef.current.getBoundingClientRect().width;
      }
      const newSize = { ...startSize.current };
      let deltaX = Math.abs(params.moveRes.deltaX) * (doubleResize ? 2 : 1);
      let deltaY = Math.abs(params.moveRes.deltaY);

      if (debounceSize > 0) {
        debounceDelta.current = {
          deltaX: debounceDelta.current.deltaX + deltaX,
          deltaY: debounceDelta.current.deltaY + deltaY,
        };

        if (debounceDelta.current.deltaX >= debounceSize) {
          deltaX = debounceDelta.current.deltaX;
          debounceDelta.current.deltaX = 0;
        } else {
          deltaX = 0;
        }

        if (debounceDelta.current.deltaY >= debounceSize) {
          deltaY = debounceDelta.current.deltaY;
          debounceDelta.current.deltaY = 0;
        } else {
          deltaY = 0;
        }
      }

      // left or right
      if (
        [BarType.left, BarType.right, BarType.rightBottom, BarType.leftBottom].includes(params.type)
      ) {
        const isLeft = [BarType.left, BarType.leftBottom].includes(params.type);
        if (isLeft) {
          if (params.moveRes.deltaX > 0) {
            newSize.width -= deltaX;
          } else {
            newSize.width += deltaX;
          }
        } else {
          if (params.moveRes.deltaX > 0) {
            newSize.width += deltaX;
          } else {
            newSize.width -= deltaX;
          }
        }
      }

      // bottom
      if ([BarType.rightBottom, BarType.leftBottom, BarType.bottom].includes(params.type)) {
        // newSize.height
        if (params.moveRes.deltaY > 0) {
          newSize.height += deltaY;
        } else {
          newSize.height -= deltaY;
        }
      }

      const resultSize = calcSize({
        size: newSize,
        stateSize: resize,
        fullHeight,
        maxHeight,
        maxWidth,
        minHeight,
        minWidth,
        widthBreakPoints,
      });

      setResize(resultSize.size);
    },
    [
      doubleResize,
      debounceSize,
      resize,
      fullHeight,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      widthBreakPoints,
    ]
  );

  const debounceCalcSize = useRef(
    debounce(
      (
        params: CalcSizeParams & {
          isMaxWidthChange: boolean;
          callback: (newSize: ReturnType<typeof calcSize>) => void;
        }
      ) => {
        const { callback, ...args } = params;
        const newSize = calcSize(args);
        callback(newSize);
      },
      50
    )
  );

  useEffect(() => {
    const commonParams = {
      stateSize: resize,
      fullHeight,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      widthBreakPoints,
      blockFullWidth: defaultBlockFullWidth,
    };

    if (
      lastSize.current.size.width !== defaultWidth ||
      lastSize.current.size.height !== defaultHeight
    ) {
      debounceCalcSize.current({
        ...commonParams,
        size: {
          width: defaultWidth,
          height: defaultHeight,
        },
        breakPoints: true,
        isMaxWidthChange: false,
        callback: (newSize) => {
          lastSize.current = {
            ...newSize,
            blockFullWidth: defaultBlockFullWidth,
          };
          setResize(newSize.size);
        },
      });
    } else {
      debounceCalcSize.current({
        ...commonParams,
        size: lastSize.current.size,
        breakPoints: true,
        isMaxWidthChange: true,
        callback: (newSize) => {
          lastSize.current = {
            ...newSize,
            blockFullWidth: defaultBlockFullWidth,
          };
          setResize(newSize.size);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultHeight, defaultWidth, defaultBlockFullWidth, maxWidth]);

  const commonClass = cx(
    'absolute group-hover:opacity-100 transition-opacity',
    !isMove && 'opacity-0'
  );

  const changeIsMove = (status: boolean) => {
    if (readonly) return;
    if (status) {
      startSize.current = {
        width: resize.width,
        height: resize.height,
      };
      toggleBodyScrollbar.hidden();
    } else {
      toggleBodyScrollbar.show();
      lastSize.current = {
        size: {
          width: resize.width,
          height: resize.height,
        },
        blockFullWidth: resize.width === maxWidth,
      };
      onChange?.({
        width: lastSize.current.size.width,
        height: lastSize.current.size.height,
        blockFullWidth: lastSize.current.blockFullWidth,
      });
    }

    setIsMove(status);
  };

  const handleKeyDown = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onKeyDownMoveBar?.();
  };

  const handleKeyUp = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onKeyUpMoveBar?.();
  };

  const style = disableStyle
    ? {}
    : {
        // 不允许修改宽度，并自动铺满宽度的时候直接给100%
        width:
          isMobileSize || (lastSize.current.blockFullWidth && !resizeWidth)
            ? 'auto'
            : resize.width // 特殊情况不需要宽度
            ? resize.width
            : undefined,
        height: fullHeight ? 'auto' : resize.height,
      };

  // 图片打印问题（先这么改试试看）
  if ($searchParams.print) {
    return (
      <div
        className={cx('max-w-full', outerLayoutClassName)}
        style={{
          width: style.width,
          ...outerLayoutStyle,
        }}
      >
        <div className={cx('max-w-full', className)} style={{ ...style, ...outerLayoutStyle }}>
          {children}
        </div>
        {customFooter}
      </div>
    );
  }

  const resizeBarProps = {
    onMoveStart: changeIsMove,
    onMoveEnd: changeIsMove,
    onMoveBar: changeSize,
    onPointerDown: handleKeyDown,
    onPointerUp: handleKeyUp,
  };

  return (
    <div
      className={cx('flex flex-col justify-center flex-shrink-0', outerLayoutClassName)}
      style={{
        ...outerLayoutStyle,
      }}
    >
      <TransitionBox
        config={{ duration: 100 }}
        className={cx(
          'relative group flex-shrink-0 overflow-hidden max-w-full',
          !isMove && 'transition-all duration-75 ease-in-out',
          className
        )}
        domRef={elementRef}
        style={style}
        {...reset}
      >
        <div className={cx('w-full h-full', isMove && 'pointer-events-none', childrenClassName)}>
          {children}
        </div>
        {!readonly && (
          <>
            {/* left */}
            <ResizeBar
              {...resizeBarProps}
              type={BarType.left}
              className={cx(
                commonClass,
                'flex items-center justify-center top-0 left-0 h-full w-2 cursor-[col-resize] py-1 z-10',
                !resizeWidth && 'hidden'
              )}
            >
              <div className="w-1.5 h-full border border-white max-h-20 rounded-tr-md rounded-br-md bg-black-base/75" />
            </ResizeBar>
            {/* left-bottom */}
            <ResizeBar
              {...resizeBarProps}
              className={cx(
                commonClass,
                'bottom-0 left-0 z-[11] cursor-move',
                (!resizeHeight || !resizeWidth) && 'hidden'
              )}
              type={BarType.leftBottom}
            >
              <img className="w-6 h-6" src={Images.resizeLeft} />
            </ResizeBar>
            {/* right */}
            <ResizeBar
              {...resizeBarProps}
              type={BarType.right}
              className={cx(
                commonClass,
                'flex items-center justify-center top-0 right-0 h-full w-2 cursor-[col-resize] py-1 z-10',
                !resizeWidth && 'hidden'
              )}
            >
              <div className="w-1.5 h-full border border-white max-h-20 rounded-tl-md rounded-bl-md bg-black-base/75" />
            </ResizeBar>
            {/* right-bottom */}
            <ResizeBar
              {...resizeBarProps}
              type={BarType.rightBottom}
              className={cx(
                commonClass,
                'bottom-0 right-0 z-[11] cursor-move',
                (!resizeHeight || !resizeWidth) && 'hidden'
              )}
            >
              <img className="w-6 h-6 rotate-[270deg]" src={Images.resizeLeft} />
            </ResizeBar>
            {/* bottom */}
            <ResizeBar
              {...resizeBarProps}
              type={BarType.bottom}
              className={cx(
                commonClass,
                'items-center flex justify-center bottom-0 left-0 w-full h-2 cursor-[row-resize] z-10',
                !resizeHeight && 'hidden'
              )}
            >
              <div className="w-20 h-1.5 border border-white rounded-tl-md rounded-tr-md bg-black-base/75" />
            </ResizeBar>
          </>
        )}
      </TransitionBox>
      {customFooter}
    </div>
  );
});
// #endregion

// #region bar
interface ResizeBar {
  type: BarType;
  onMoveBar: (params: MoveBarProps) => void;
  onMoveStart?: (isMove: boolean) => void;
  onMoveEnd?: (isMove: boolean) => void;
}
const ResizeBar: FC<JSX.IntrinsicElements['div'] & ResizeBar> = (props) => {
  const { children, type, onMoveBar, onMoveStart, onMoveEnd, ...rest } = props;

  return (
    <DragHandle
      onDragStart={() => onMoveStart?.(true)}
      onDragMove={(info) => {
        onMoveBar({
          type,
          moveRes: {
            deltaX: info.x - info.startX,
            deltaY: info.y - info.startY,
          },
        });
      }}
      onDragEnd={() => onMoveEnd?.(false)}
    >
      {({ ref }) => (
        <div {...rest} ref={ref as any}>
          {children}
        </div>
      )}
    </DragHandle>
  );
};
// #endregion
