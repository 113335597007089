import { useCallback } from 'react';
import { getAvailableOperators, pruneFilter } from '../table-view/body/filters';
import type { CollectionFilter } from '@next-space/fe-api-idl/lib/models/CollectionFilter';
import { getState } from 'src/redux/store';
import * as _ from 'lodash-es';
import type { CollectionFilterGroup } from '@next-space/fe-api-idl/lib/models/CollectionFilterGroup';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import type { DatabaseInfo } from './type';

export const useCreateFilter = () => {
  return useCallback(
    (
      databaseInfo: DatabaseInfo,
      propertyId: string,
      managerReadonly: boolean | undefined,
      group?: boolean
    ) => {
      const { viewId, collectionId } = databaseInfo;
      const view = getState().collectionViews?.[viewId];
      const collection = getState().blocks?.[collectionId];
      if (!view) return;
      const schema = collection?.data.schema;
      if (!schema) return;

      let newFilter: typeof view.format.filter;
      if (!view.format.filter) {
        newFilter = {
          type: 'group',
          filters: [],
          operator: 'and',
        };
      } else {
        newFilter = _.cloneDeep(view.format.filter);
      }
      if (!newFilter) return;
      if (newFilter.filters.length === 0) {
        newFilter.disable = false;
      }

      const avaiableOperators = getAvailableOperators(collectionId, propertyId);
      const filter0: CollectionFilter = {
        type: 'filter',
        property: propertyId,
        propertyType: schema[propertyId]?.type,
        operator: avaiableOperators[0]?.value ?? 'isNotEmpty',
        value: '',
      };
      const filter: CollectionFilter | CollectionFilterGroup = group
        ? {
            type: 'group',
            operator: 'and',
            filters: [filter0],
          }
        : filter0;
      newFilter.filters.push(filter);
      newFilter = pruneFilter(newFilter, schema);

      updateViewFormat(viewId, { filter: newFilter }, managerReadonly);
    },
    []
  );
};
