import { useCallback } from 'react';
import type { MindNode } from '../../core/types';
import { useMindMapEngine } from '../context';
import shallowEqual from '../utils';
import { useMindMapSelector } from './use-mind-map-selector';

type PartialMindNode<PickField extends keyof MindNode> = {
  [K in keyof Pick<MindNode, PickField>]: MindNode[K];
};

export function useMindMapNode<PickField extends keyof MindNode>(
  id: string,
  pickFields?: PickField[]
) {
  return useMindMapSelector((state) => {
    const node = state.mindNodes[id];
    if (node) {
      if (pickFields && pickFields?.length > 0) {
        const copyNode = {} as PartialMindNode<PickField>;
        copyNode &&
          pickFields?.forEach((field) => {
            copyNode[field] = node[field];
          });
        return copyNode;
      }
    }
    return node;
  }, shallowEqual);
}

export const useMindMapNodes = () => {
  return useMindMapSelector((state) => state.mindNodes, shallowEqual);
};

export const useRootMindMapNode = () => {
  return useMindMapSelector((state) => {
    return state.mindNodes[state.rootId];
  }, shallowEqual);
};

export const useMindMapScale = () => {
  return useMindMapSelector((state) => state.scale);
};
export const useMindMapOffset = () => {
  return useMindMapSelector((state) => {
    return { offsetX: state.offsetX, offsetY: state.offsetY };
  }, shallowEqual);
};

export const useMindMapHoverId = () => {
  return useMindMapSelector((state) => state.hoverId);
};

export const useFitCenter = () => {
  const engine = useMindMapEngine();
  return useCallback(() => {
    engine.fitCenter();
  }, [engine]);
};

export const useMindMapDropInfo = () => {
  return useMindMapSelector((state) => state.dropInfo);
};
