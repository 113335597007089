import type { DraggableSyntheticListeners } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { getBoardGroupBackgroundColor } from '@flowus/common/block/color/get-block-color';
import { cx } from '@flowus/common/cx';
import { Virtuoso } from 'react-virtuoso';
import { SortableList } from 'src/common/components/sortable-list';
import { MembersCoordinate } from 'src/editor/editor/uikit/members-coordinate';
import { Selector } from 'src/editor/editor/uikit/selector';
import { realGroupName, useBiTableGroups } from 'src/hooks/collection-view/table-groups';
import {
  useCardImageDir,
  useCardSize,
  useManualSortGroup,
} from 'src/hooks/collection-view/use-collection-view';
import { useEnableBoardColor } from 'src/hooks/collection-view/use-enable-board-color';
import { useGroupOption } from 'src/hooks/collection-view/use-group-option';
import { useHoveringEmpty } from 'src/hooks/page/use-dnd/hooks';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { AddRecord } from '../add-record';
import { useHandleGroupSort } from '../bitable-manager/group-list';
import { getCardSize, OVERSCAN } from '../const';
import { useBitable } from '../context';
import { BitableLoadMoreContext, useBitableLoadMoreContext } from '../hooks';
import { BitableLoadMore } from '../load-more';
import { BoardGroupHeader } from './board-group-header';
import { GroupCard } from './card';
import { HiddenGroups } from './hidden-groups';

export const BoardGroups = () => {
  const { viewId, managerReadonly, isLocked, readonly, embed } = useBitable();
  const { tableGroups } = useBiTableGroups(viewId);
  const { visibleGroups } = tableGroups ?? {};
  const handleGroupSort = useHandleGroupSort();
  const manualSortGroup = useManualSortGroup();

  if (!visibleGroups) return null;

  return (
    <div className="flex pt-4 border-t border-grey6">
      <SortableList
        className="flex"
        isHorizontalAxis
        disabled={managerReadonly || isLocked || !manualSortGroup}
        strategy={horizontalListSortingStrategy}
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        items={visibleGroups.map((group) => ({ id: group.value, ...group }))}
        onChange={(_, event) => handleGroupSort(viewId, event)}
        onlyHandleDraggable
        renderItemContent={({ item, listeners }) => {
          return (
            <BitableLoadMoreContext recordIds={item.recordIds}>
              <BoardColumn
                recordIds={item.recordIds}
                groupName={item.value}
                groupProperty={item.groupProperty}
                listeners={listeners}
              />
            </BitableLoadMoreContext>
          );
        }}
      />

      <AddGroup />

      <HiddenGroups />

      {!readonly && !embed && <Selector type="collection" />}
    </div>
  );
};

interface GroupProps {
  groupName: string;
  groupProperty: string;
  recordIds: string[];
  listeners?: DraggableSyntheticListeners;
}

export const BoardColumn = (props: GroupProps) => {
  useIsDarkMode();
  const { groupProperty, groupName, recordIds, listeners } = props;
  const { viewId, embed } = useBitable();
  const isEnableBoardColor = useEnableBoardColor(viewId);
  const option = useGroupOption(viewId, realGroupName(groupName));
  const cardSize = useCardSize(viewId);
  const imageDir = useCardImageDir(viewId);
  const hoveringEmpty = useHoveringEmpty(viewId);
  const { recordIds: renderIds } = useBitableLoadMoreContext();

  const pageRef = usePageScrollRef();
  if (!pageRef.current) return null;

  const listStyle = {
    height: 'fit-content',
    width: getCardSize(cardSize, imageDir) + 10,
    backgroundColor: isEnableBoardColor ? getBoardGroupBackgroundColor(option?.color) : undefined,
  };

  return (
    <div
      data-is-empty={recordIds.length === 0 ? true : null}
      data-is-board
      data-group-property={groupProperty}
      data-group-value={groupName}
      data-view-id={viewId}
      style={{ width: getCardSize(cardSize, imageDir) + 10 }}
      className={cx('mr-2.5 h-full flex flex-col board-group group')}
    >
      <BoardGroupHeader groupName={groupName} recordIds={recordIds} listeners={listeners} />

      <div
        style={listStyle}
        className={cx(
          'w-full relative flex-1 block-list flex flex-col items-center rounded-b-md pb-[5px] px-[5px]',
          isEnableBoardColor && 'bg-grey/5'
        )}
      >
        <Virtuoso
          className="w-full"
          customScrollParent={pageRef.current}
          overscan={OVERSCAN}
          increaseViewportBy={OVERSCAN}
          totalCount={renderIds.length}
          itemContent={(index) => {
            const recordId = renderIds[index];
            if (!recordId) return null;
            return (
              <div className="py-1" key={recordId} data-card-id={recordId}>
                <GroupCard recordId={recordId} groupValue={groupName} />
              </div>
            );
          }}
        />

        {!embed && <MembersCoordinate type="board" />}

        {hoveringEmpty && (
          <div className="absolute top-0 left-0 w-full h-1 bg-black/30 opacity-0 group-hover:opacity-100"></div>
        )}

        <BitableLoadMore />

        <AddRecord
          where={{ after: recordIds[recordIds.length - 1] }}
          groupName={groupName}
          color={isEnableBoardColor ? option?.color : undefined}
        />
      </div>
    </div>
  );
};
