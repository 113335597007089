import { calculateRemindTime, canPreViewImage, getDateStrBySegment } from '@flowus/common';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { LOCAL_LNG } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { deepEqual } from '@flowus/common/utils/tools';
import { formula } from '@flowus/formula';
import type { CollectionSchema, SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import React, { memo, useMemo } from 'react';
import { COLORS } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { Checkbox } from 'src/components/check-box';
import { FilePreViewIcon } from 'src/components/file-preview-icon';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { segmentsToText } from 'src/editor/utils/editor';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { percentToNumber } from 'src/utils/number';
import {
  getRollupValueType,
  isDateAggregation,
  isNumberAggregation,
  isPercentAggregation,
  isShowOriginalValue,
} from '../../footer/helper';
import { useOpenRollupSetting } from '../../widgets/rollup-setting';
import { ALIYUN_IMGPROCESS_SIZE_LIMIT } from '../files';
import { generateGetLinkUrl } from '../link';
import { formatNumber } from '../num';
import { renderNumProgress } from '../num/render-num-process';
import { PersonValueView } from '../person';
import { TagItem } from '../select/tag-item';
import { TextValueView } from '../text';
import type { CellViewProps } from '../types';
import { Site } from '../types';
import { getRollupValue } from './get-rollup-value';

export const RollupValue: FC<CellViewProps> = memo(({ className, recordId, propertyId, site }) => {
  const { collectionId, viewId, tableCellWrap } = useBitable();
  const openRollupSetting = useOpenRollupSetting(collectionId);
  const { propertySchema, targetPropertySchema } = usePropertySchema(collectionId, propertyId);
  const { aggregation } = propertySchema ?? {};

  const rollupValue = useObservableStore(
    (state) => {
      return getRollupValue(recordId, propertyId, state);
    },
    [recordId, propertyId]
  );

  const renderValue: ReactNode = useMemo(() => {
    let _renderValue: React.ReactNode;
    if (rollupValue) {
      const {
        originValues,
        targetProperty,
        targetPropertySchema,
        aggregationValue,
        relationRecords,
        formulaType,
      } = rollupValue;

      /* 展示原始值 */
      if (isShowOriginalValue(aggregation)) {
        _renderValue = renderOriginValue({
          relationRecords,
          propertyId: targetProperty,
          propertySchema: targetPropertySchema,
          rollupSchema: propertySchema,
          blocks: getState().blocks,
          originValues,
          site,
          formulaType,
          tableCellWrap,
        });
      }

      if (typeof aggregationValue !== 'undefined') {
        const needFormatNumber =
          targetPropertySchema.type === CollectionSchemaType.NUMBER &&
          !!targetPropertySchema.numberFormat &&
          isNumberAggregation(aggregation);

        let resultValue = aggregationValue;

        if (needFormatNumber) {
          const result = formatNumber(Number(aggregationValue), targetPropertySchema.numberFormat);
          if (typeof result === 'string') {
            resultValue = result;
          } else if (typeof result === 'number') {
            resultValue = String(result);
          } else {
            return null;
          }
        }

        let numProgress = null;
        if (isPercentAggregation(aggregation)) {
          numProgress = renderNumProgress({
            num: percentToNumber(aggregationValue),
            showAs: propertySchema?.showAs,
            numberFormat: 'percent',
            from: 'rollup',
          });
        } else {
          const num = Number(aggregationValue);
          if (!isNaN(num)) {
            if (getRollupValueType(aggregation) === 'number') {
              numProgress = renderNumProgress({
                num,
                showAs: propertySchema?.showAs,
                numberFormat: isNumberAggregation(aggregation)
                  ? targetPropertySchema.numberFormat
                  : undefined,
                from: 'rollup',
              });
            }
          }
        }

        const { showNumber } = propertySchema?.showAs ?? {};

        // aggregation 只有[数字,百分比]和[日期]三种类型
        _renderValue = (
          <div
            className={cx(
              'w-full',
              numProgress && 'flex items-center whitespace-nowrap',
              numProgress && site === Site.CELL && 'justify-end',
              site === Site.CELL && !isDateAggregation(aggregation) ? 'text-right ' : 'text-left'
            )}
          >
            {numProgress && !showNumber ? null : resultValue}
            {numProgress}
          </div>
        );
      }
    }
    return _renderValue;
  }, [aggregation, propertySchema, rollupValue, site, tableCellWrap]);

  const targetPropertyType = targetPropertySchema?.type;
  const isShowValue = isShowOriginalValue(aggregation);
  const notNeedPadding =
    isShowValue &&
    (targetPropertyType === CollectionSchemaType.PERSON ||
      targetPropertyType === CollectionSchemaType.UPDATED_BY ||
      targetPropertyType === CollectionSchemaType.CREATED_BY);
  return (
    <div
      className={cx(
        'rollup-box h-full w-full',
        !notNeedPadding && (site === Site.CELL || site === Site.FIELD) && 'p-2',
        className
      )}
    >
      {renderValue}

      {!renderValue && site === Site.FIELD && (
        <span className="text-grey4">{LOCAL_LNG.isEmpty}</span>
      )}

      {(site === Site.CELL || site === Site.FIELD) && (
        <button
          onClick={(event) => openRollupSetting(event, propertyId, viewId)}
          className={cx(
            'animate-hover-opaque border-grey6 absolute top-1.5 right-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded border bg-white1 opacity-0'
          )}
        >
          <Icon name="IcBtnEdit" size="middle" className="text-grey3" />
        </button>
      )}
    </div>
  );
}, deepEqual);

interface RenderOriginValueParams {
  propertyId: string;
  propertySchema?: CollectionSchema;
  rollupSchema?: CollectionSchema;
  relationRecords: string[];
  originValues: string[];
  blocks?: Record<string, NextBlock>;
  site: Site;
  formulaType?: formula.ValueType | null;
  tableCellWrap?: boolean;
}
/**
 * 渲染指定 collectionId 中 relationRecords 中 propertyId 的所有值
 * @param param0
 * @returns
 */
const renderOriginValue = ({
  propertyId,
  relationRecords,
  blocks = getState().blocks,
  propertySchema,
  rollupSchema,
  originValues,
  site,
  formulaType,
  tableCellWrap,
}: RenderOriginValueParams) => {
  if (!propertySchema) return null;
  const targetPropertyType = propertySchema.type;

  const renderNum = (value: string, index: number) => {
    let resultValue = value;
    if (propertySchema.numberFormat) {
      const result = formatNumber(Number(value), propertySchema.numberFormat);
      if (typeof result === 'string') {
        resultValue = result;
      } else if (typeof result === 'number') {
        resultValue = String(result);
      } else {
        return null;
      }
    }

    let numProgress = null;
    const num = Number(value);
    if (!isNaN(num)) {
      numProgress = renderNumProgress({
        num,
        showAs: rollupSchema?.showAs,
        numberFormat: propertySchema.numberFormat,
        from: 'property-value',
      });
    }

    if (rollupSchema?.showAs && rollupSchema?.showAs.type) {
      return (
        <div
          className={cx(
            'flex items-center whitespace-nowrap',
            site === Site.CELL && 'justify-end',
            (site === Site.LIST || site === Site.CALENDAR) && 'inline-flex ml-2'
          )}
          key={index}
        >
          {numProgress && !rollupSchema?.showAs?.showNumber ? undefined : resultValue}
          {numProgress}
        </div>
      );
    }

    return (
      <TextValueView key={index} className="inline leading-5 ">
        {index === originValues.length - 1 ? resultValue : `${resultValue}, `}
      </TextValueView>
    );
  };

  if (targetPropertyType === CollectionSchemaType.TITLE) {
    return (
      <>
        {relationRecords.map((uuid) => {
          return (
            <InlinePage
              key={uuid}
              uuid={uuid}
              className="mr-2 whitespace-nowrap p-0 leading-none"
            />
          );
        })}
      </>
    );
  } else if (targetPropertyType === CollectionSchemaType.TEXT) {
    return (
      <>
        {relationRecords.map((blockId: string, index) => {
          const block = blocks[blockId];
          const segments = block?.data.collectionProperties?.[propertyId];
          if (!segmentsToText(segments)) return null;

          return (
            <React.Fragment key={blockId}>
              <RichText
                className="inline leading-[20px]"
                segments={segments}
                interactable={false}
              />
              {index !== originValues.length - 1 && ', '}
            </React.Fragment>
          );
        })}
      </>
    );
  }
  if (targetPropertyType === CollectionSchemaType.DATE) {
    return (
      <>
        {relationRecords.map((blockId: string, index) => {
          const block = blocks[blockId];
          const segment = block?.data.collectionProperties?.[propertyId]?.[0];
          if (!segment) return null;
          const dateStr = getDateStrBySegment(
            segment,
            propertySchema.dateFormat,
            segment.type === TextType.DATETIME,
            segment.timeFormat
          );
          const time = calculateRemindTime(segment);
          const invalid = Date.now() - (time ?? 0) > 0;
          if (segment.reminder) {
            return (
              <TextValueView
                key={index}
                className={cx('inline leading-5 text-red', {
                  'line-through text-red/70': invalid,
                })}
              >
                {dateStr}
                <Icon
                  name={'IcMenuAlarm'}
                  size="normal"
                  className={cx('text-red ml-[2px]', {
                    'text-red/70': invalid,
                  })}
                />
                {index === originValues.length - 1 ? '' : `, `}
              </TextValueView>
            );
          }
          return (
            <TextValueView key={index} className="inline leading-5 ">
              {index === originValues.length - 1 ? dateStr : `${dateStr}, `}
            </TextValueView>
          );
        })}
      </>
    );
  } else if (targetPropertyType === CollectionSchemaType.NUMBER) {
    return (
      <>
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;
          return renderNum(value, index);
        })}
      </>
    );
  } else if (
    targetPropertyType === CollectionSchemaType.CREATED_AT ||
    targetPropertyType === CollectionSchemaType.UPDATED_AT
  ) {
    return (
      <>
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;

          return (
            <TextValueView key={index} className="inline leading-5 ">
              {index === originValues.length - 1 ? value : `${value}, `}
            </TextValueView>
          );
        })}
      </>
    );
  } else if (
    targetPropertyType === CollectionSchemaType.PHONE ||
    targetPropertyType === CollectionSchemaType.EMAIL ||
    targetPropertyType === CollectionSchemaType.URL
  ) {
    return (
      <>
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;

          const getLinkUrl = generateGetLinkUrl(targetPropertyType);
          const url = getLinkUrl(value);
          return (
            <React.Fragment key={index}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className="animate-hover border-grey5 my-px inline cursor-pointer rounded-none border-b leading-5 text-black/95 hover:rounded-sm"
              >
                {value}
              </a>
              {index !== originValues.length - 1 && ', '}
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (targetPropertyType === CollectionSchemaType.CHECKBOX) {
    return (
      <>
        {originValues.map((value, index) => {
          const checked = typeof value === 'string' && formatCheckBoxValue(value);
          const isNormalCell = site === Site.CELL || site === Site.FIELD;

          let name = '';
          if (checked) {
            name = isNormalCell ? 'IcCheckboxCheck' : 'IcCheckbox2Check';
          } else {
            name = isNormalCell ? 'IcCheckbox' : 'icCheckbox2';
          }

          return (
            <Checkbox
              key={index}
              size={isNormalCell ? 'large' : 'small'}
              className={'mr-2'}
              name={name as IconName}
              checked={checked}
            />
          );
        })}
      </>
    );
  } else if (
    targetPropertyType === CollectionSchemaType.CREATED_BY ||
    targetPropertyType === CollectionSchemaType.UPDATED_BY ||
    targetPropertyType === CollectionSchemaType.PERSON
  ) {
    return (
      <div
        className={cx(
          'flex',
          site === Site.LIST || site === Site.CALENDAR
            ? 'text-ellipsis whitespace-nowrap'
            : 'flex-wrap'
        )}
      >
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;
          return <PersonValueView key={index} className="my-px px-2 py-1.5" userId={value} />;
        })}
      </div>
    );
  } else if (
    targetPropertyType === CollectionSchemaType.SELECT ||
    targetPropertyType === CollectionSchemaType.MULTI_SELECT
  ) {
    const { options } = propertySchema;
    if (!options) return null;

    return (
      <div
        className={cx(
          'flex',
          site === Site.LIST || site === Site.CALENDAR || !tableCellWrap
            ? 'text-ellipsis whitespace-nowrap'
            : 'flex-wrap'
        )}
      >
        {originValues.map((id, index) => {
          if (typeof id !== 'string') return null;
          const option = options.find((option) => option.id === id);
          if (!option) return null;

          return (
            <TagItem
              key={index}
              color={option.color}
              value={option.value}
              label={option.value}
              className={cx(
                'flex-shrink-0',
                site !== Site.CELL && site !== Site.FIELD ? 'text-t4 mr-1' : 'm-[3px]',
                site === Site.CALENDAR ? 'my-[1px]' : 'my-0.5'
              )}
            />
          );
        })}
      </div>
    );
  } else if (targetPropertyType === CollectionSchemaType.FILE) {
    const allFiles: (SegmentDTO & { blockId: string })[] = [];

    relationRecords.forEach((uuid) => {
      const block = blocks[uuid];
      if (!block) return;
      const segments = block.data.collectionProperties?.[propertyId] ?? [];

      segments.forEach((item) => {
        if (item.type === TextType.URL && item.fileStorageType === 'internal') {
          allFiles.push({
            ...item,
            blockId: uuid,
          });
        }
      });
    });

    return (
      <div
        className={cx(
          'flex',
          site === Site.LIST || site === Site.CALENDAR
            ? 'text-ellipsis whitespace-nowrap py-px'
            : 'flex-wrap'
        )}
      >
        {allFiles.map((item, index) => {
          const canPreView = canPreViewImage(item.url);
          const tagItem = (
            <TagItem
              key={index}
              label={item.text}
              color={COLORS[0].key}
              className={cx(
                'max-w-[calc(100%-6px)] align-middle',
                site === Site.CELL || site === Site.FIELD ? 'm-[3px]' : 'mr-1'
              )}
            />
          );
          if (
            canPreView &&
            item.uuid &&
            (!item.size || item.size <= ALIYUN_IMGPROCESS_SIZE_LIMIT)
          ) {
            return (
              <FilePreViewIcon
                key={index}
                uuid={item.uuid}
                ossName={item.url}
                defaultIcon={tagItem}
                className={cx(
                  'h-5 min-h-[20px] min-w-[20px] cursor-pointer overflow-hidden rounded-sm',
                  site === Site.CELL || site === Site.FIELD ? 'm-[3px]' : 'mr-1'
                )}
              />
            );
          }
          return tagItem;
        })}
      </div>
    );
  } else if (targetPropertyType === CollectionSchemaType.RELATION) {
    return (
      <>
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;
          return (
            <InlinePage
              uuid={value}
              key={index}
              className="mr-2 whitespace-nowrap p-0 leading-none"
            />
          );
        })}
      </>
    );
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (targetPropertyType === CollectionSchemaType.FORMULA) {
    return (
      <>
        {originValues.map((value, index) => {
          if (typeof value !== 'string') return null;

          if (formulaType === formula.ValueTool.booleanType) {
            const isChecked = formatCheckBoxValue(value);
            return <Checkbox key={index} size="large" checked={isChecked} />;
          }

          if (formulaType === formula.ValueTool.numberType) {
            return renderNum(value, index);
          }

          return (
            <TextValueView key={index} className="inline leading-5 ">
              {index === originValues.length - 1 ? value : `${value}, `}
            </TextValueView>
          );
        })}
      </>
    );
  }

  return null;
};
