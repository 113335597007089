import { cx } from '@flowus/common/cx';
import { Modals } from '@flowus/shared';
import type { FC } from 'react';
import { IconWidget } from 'src/common/components/emoji-widget';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { useWallet } from 'src/hooks/user/use-wallet';
import { useExChangeCodeRedBadge } from 'src/services/app/hook';
import { useUserBalance } from 'src/services/user/user-balance';
import { useSetting } from '../common';
import { SettingMenuType } from '../type';
import { useOpenWelfareCode } from '../use-open-welfare-code';
import { useOpenIntegralHistory } from './integral-history';
import { ScoreShop } from './score-shop';

interface Props {}
/** 我的积分 */
export const MyPoint: FC<Props> = () => {
  const { setCurrentSettingMenu } = useSetting();
  const { integralInfo } = useUserBalance();
  const openModal = useOpenModal();
  const openWelfareCode = useOpenWelfareCode();
  const exChangeCodeRedBadge = useExChangeCodeRedBadge();
  const openIntegralHistory = useOpenIntegralHistory();
  const [wallet] = useWallet();

  const openDistribution = () => {
    setCurrentSettingMenu(SettingMenuType.profit);
  };

  const openWelfare = () => {
    openModal.modal({
      modalId: Modals.SCORE_SHOP,
      content: () => <ScoreShop />,
    });
  };

  const renderList = [
    {
      hidden: __BUILD_IN__,
      className: 'bg-yellow/10 border-yellow/20',
      title: (
        <>
          <span>我的积分</span>
          <span className="text-t1-medium mx-1">{integralInfo?.currentScore}</span>
          {!!integralInfo?.todayScore && (
            <span className="text-t4-bold text-white1 bg-yellow px-1 py-px rounded-sm">
              今日 +{integralInfo.todayScore}
            </span>
          )}
        </>
      ),
      descClass: 'text-yellow',
      emoji: '💎',
      desc: (
        <div className="space-x-2">
          <span>去兑换</span>
          <span
            className="text-t4-medium text-grey3"
            onClick={(e) => {
              e.stopPropagation();
              openIntegralHistory();
            }}
          >
            积分历史
          </span>
        </div>
      ),
      onClick: openWelfare,
    },
    {
      className: 'bg-green/10 border-green/20',
      title: '我的卡券',
      emoji: '🎫',
      point: exChangeCodeRedBadge,
      descClass: 'text-green',
      desc: '去查看',
      onClick: openWelfareCode,
    },
    {
      className: 'bg-blue/10 border-blue/20',
      hidden: __BUILD_IN__,
      title: (
        <div className="space-x-1">
          <span>我的收益</span>
          <span>{wallet?.totalAmount || 0}元</span>
        </div>
      ),
      emoji: '💰',
      desc: '去提现',
      descClass: 'text-blue',
      onClick: openDistribution,
    },
  ];

  return (
    <div className="grid grid-cols-3 gap-x-2 mt-1">
      {renderList.map((item) => (
        <div
          key={item.className}
          className={cx(
            'border rounded flex-1 py-4 px-3 flex justify-between animate-click',
            item.className
          )}
          onClick={item.onClick}
          hidden={item.hidden}
        >
          <div>
            <div className="text-t1-medium flex items-center space-x-1.5">
              <IconUiSizeBox size={24} className="self-start">
                <IconWidget emojiStr={item.emoji} iconSize={20} disableHover />
              </IconUiSizeBox>
              <div>{item.title}</div>
              <span hidden={!item.point} className="w-2.5 h-2.5 bg-red rounded-full" />
            </div>
            <div className={cx('text-t2-medium mt-1.5 ml-7', item.descClass)}>{item.desc}</div>
          </div>
          <IconUiSizeBox size={24} className="text-grey3 text-t4 self-center">
            <Icon size="xxxsmall" name="IcArrowUnfold" className="ml-1" />
          </IconUiSizeBox>
        </div>
      ))}
    </div>
  );
};
