import type { SegmentDTO } from '@next-space/fe-api-idl';

import { segmentsToText, textToSegments } from '../utils/segments-utils';
import { BiArrayValueType } from './_abstract';
import { BiSelectType } from './select';

export class BiMultiSelectType extends BiArrayValueType<number> {
  private optionValueToIndex: Map<string, number>;

  constructor(public options: { id: string; value: string; color: string }[]) {
    super();
    this.optionValueToIndex = new Map(this.options.map(({ value }, index) => [value, index]));
  }

  get elementType(): BiSelectType {
    return new BiSelectType(this.options);
  }

  fromSegments(segments: SegmentDTO[] | undefined): number[] | undefined {
    const text = segmentsToText(segments);
    const tags = text.split(/\s*,\s*/g);
    const indexes: number[] = [];
    for (const tag of tags) {
      const index = this.optionValueToIndex.get(tag);
      if (index !== undefined) {
        indexes.push(index);
      }
    }
    if (indexes.length === 0) return undefined;
    return indexes;
  }

  toSegments(value: number[]): SegmentDTO[] | undefined {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return textToSegments(value.map((it) => this.options[it]!.value).join(', '));
  }

  compareElement(value1: number, value2: number): number {
    return this.elementType.compare(value1, value2);
  }
}
