import { getFileNameInfo, Role } from '@flowus/common';
import { useOpenModal } from '@flowus/common/next-modal';
import { checkImagePreviewFormat, FileRegex } from '@flowus/common/regex';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { atom, useAtom } from 'jotai';
import { useMemo } from 'react';
import { OPEN_WPS } from 'src/feature-flags_';
import { useGetFolder } from 'src/hooks/block/use-get-folder';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { useCurrentUser } from 'src/hooks/user';
import { cache } from 'src/redux/store';
import { findDescendantBlock } from 'src/utils/block-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { OpenSettingFrom } from 'src/views/main/setting-modal/type';
import { useOpenUpgradeSpace } from 'src/views/main/setting-modal/use-open-setting-modal';
import { useOpenWpsAuthDialog } from './wps-auth';

export const FILE_PREVIEW_SCOPE = Symbol('File Preview Scope');

interface Props {
  uuid: string;
  pageId?: string;
}
export const useFileImageBlockIds = (props: Props) => {
  const { uuid = '', pageId = '' } = props;
  const pageBlock = usePickBlock(pageId, []);
  const { files } = useGetFolder(pageId);

  const fileImageBlockIds = useMemo(() => {
    let imageList = findDescendantBlock(
      uuid,
      [BlockType.FILE, BlockType.EXTERNAL_FILE, BlockType.REFERENCE],
      (b) => {
        if (b.type === BlockType.EXTERNAL_FILE && b.data.display === 'image' && b.data.link) {
          return true;
        }
        if (b.type === BlockType.FILE) {
          if ((b.data.display === 'image' || b.data.display === 'file') && b.data.ossName) {
            // NOTE: 未必oss尾缀一定是已知图片格式才能展示
            if (checkImagePreviewFormat(getFileNameInfo(b.data.ossName).extName)) {
              return true;
            }
          }

          const isImageExtName = FileRegex.image.test(getFileNameInfo(b.data.ossName).extName);
          if (
            b.data.display === undefined &&
            (isImageExtName || FileRegex.image.test(b.data.extName ?? ''))
          ) {
            return true;
          }
        }
        const refBlockId = b?.data?.ref?.uuid;
        if (b.type === BlockType.REFERENCE && refBlockId) {
          const refBlock = cache.blocks[refBlockId];
          if (refBlock?.type === BlockType.FILE && refBlock?.data.ossName) {
            if (FileRegex.image.test(getFileNameInfo(refBlock.data.ossName).extName)) {
              return true;
            }
          }
          if (
            refBlock?.type === BlockType.PDF_ANNOTATION &&
            refBlock?.data.pdfAnnotation?.ossName
          ) {
            return true;
          }
        }
        return false;
      }
    );
    if (pageBlock?.type === BlockType.FOLDER) {
      imageList = files?.filter((b) => imageList.includes(b.uuid)).map((v) => v.uuid);
    }
    return imageList.length ? imageList : [];
  }, [files, pageBlock?.type, uuid]);

  return fileImageBlockIds;
};

interface FilePreviewModeContextProps {
  uuid?: string;
  editor: boolean;
}

export const $filePreviewModel = atom<FilePreviewModeContextProps>({
  editor: false,
  uuid: '',
});

export const useFilePreviewMode = () => {
  const [state, setState] = useAtom($filePreviewModel, FILE_PREVIEW_SCOPE);
  const isProSpace = useIsProSpace();
  const openModal = useOpenModal();
  const openUpgradeSpace = useOpenUpgradeSpace();
  const currentUser = useCurrentUser();

  const { role } = usePermissions(state.uuid);
  const readOnly = useReadonly();
  const openWpsAuthDialog = useOpenWpsAuthDialog();

  const officeOptions = {
    canEditor: !readOnly && Role.contains(role, PermissionRole.WRITER) && OPEN_WPS,
    editor: state.editor,
    openEditor: async () => {
      if (!isProSpace) {
        openModal.warning({
          title: '升级到“个人专业版/小组版/团队版”体验完整功能',
          confirm() {
            openUpgradeSpace(OpenSettingFrom.wps);
          },
          confirmText: '立即升级',
        });
        return;
      }
      if (!currentUser.setting.wpsAuth) {
        await openWpsAuthDialog();
      }
      setState((pre) => ({ ...pre, editor: true }));
    },
  };

  return { officeOptions };
};
