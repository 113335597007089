// copy from https://github.com/logseq/logseq/blob/master/src/main/frontend/extensions/pdf/utils.js
// https://github.com/logseq/logseq/blob/master/src/main/frontend/utils.js

type CheckRectIntersect<T = boolean> = (A: DOMRect, B: DOMRect) => T;

// A 全部在 B 的里面
const inside: CheckRectIntersect = (A, B) => {
  return A.top > B.top && A.left > B.left && A.bottom < B.bottom && A.right < B.right;
};

// A 和 B 在同一行(高度差和 top 值差小于指定阈值则表名在同一行)
const sameLine: CheckRectIntersect = (A, B, yMargin = 10) => {
  return Math.abs(A.top - B.top) < yMargin && Math.abs(A.height - B.height) < yMargin;
};

// A 的右边和 B 的左边有重叠部分
const overlaps: CheckRectIntersect = (A, B) => {
  return A.left <= B.left && B.left <= A.right;
};

// A 后面紧挨着 B，AB之间有一定的间距(小于10)
const nextTo: CheckRectIntersect = (A, B, xMargin = 10) => {
  return A.left <= B.left && A.right <= B.right && B.left - A.right <= xMargin;
};

export const optimizeClientRects = (clientRects: DOMRect[], pageWidth: number) => {
  let rects = clientRects
    .filter(
      (rect) => rect.width > 0.1 && rect.height > 0.1 && Math.abs(rect.width - pageWidth) > 10
    )
    .sort((A, B) => {
      const top = A.top - B.top;
      return top === 0 ? A.left - B.left : top;
    });

  rects = rects.filter((A) => rects.every((B) => !inside(A, B)));

  // 原来这个双循环执行了三次, 为什么？
  const needRemove = new Set();

  rects.forEach((A, index) => {
    if (needRemove.has(A)) return;

    rects.slice(index + 1).forEach((B) => {
      if (needRemove.has(B)) return;

      if (!sameLine(A, B)) return;

      if (overlaps(A, B) || nextTo(A, B)) {
        A.width = Math.max(A.width, B.width - (A.left - B.left));
        A.height = Math.max(A.height, B.height);
        needRemove.add(B);
      }
    });
  });

  return rects.filter((rect) => !needRemove.has(rect));
};
