import {
  createCache,
  createPersistStore,
  createSetState,
  createStore,
} from '@flowus/common/zustand/utils';
import produce from 'immer';
import { assign } from 'lodash-es';
import { PERSIST_STORE } from 'src/feature-flags_';

export const $doneLoadPage = PERSIST_STORE
  ? createPersistStore<Record<string, boolean>>('$doneLoadPage', () => ({}))
  : createStore<Record<string, boolean>>(() => ({}));

const $setDoneLoadPage = createSetState($doneLoadPage);

export const $doneLoadPageCache = createCache($doneLoadPage);

export const useFetchPageCompleteIds = () => $doneLoadPage((state) => state);

export const addFetchPageCompleteId = (pageId: string) => {
  $setDoneLoadPage(produce((pre) => assign(pre, { [pageId]: true })));
};
