import type { PermissionDTO } from '@next-space/fe-api-idl';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';

import { UserList } from './share/components/user-list';
import type { User } from './share/types';

export const UsersPermissionsList: FC<{
  back?: () => void;
  permissions: PermissionDTO[];
  role: PermissionRole;
  title: string;
  isForm?: boolean;
  onChange: (role: PermissionRole, permission: User) => void;
  onlyUserList?: boolean;
}> = (props) => {
  const { back, permissions, role, title, onChange, onlyUserList, isForm } = props;

  if (onlyUserList) {
    return (
      <UserList
        showRight
        isForm={isForm}
        onChange={role === PermissionRole.EDITOR ? onChange : undefined}
        permissions={permissions}
      />
    );
  }

  return (
    <>
      <div onClick={back} className="p-4 flex items-center animate-click">
        <Icon name="IcArrowBack" size="middle" />
        <span className="text-t2 ml-1">{title}</span>
      </div>

      <div className="pl-4 text-t3 text-grey4">{`共 ${permissions.length} 个协作者`}</div>

      <div className="p-4">
        <UserList
          showRight
          isForm={isForm}
          onChange={role === PermissionRole.EDITOR ? onChange : undefined}
          permissions={permissions}
        />
      </div>
    </>
  );
};
