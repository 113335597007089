import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useCloseModal } from 'src/common/components/next-modal';
import { getActivity } from 'src/hooks/activities/use-activity';
import { Images } from 'src/image';
import { sleep } from 'src/utils/async-utils';
import './style.css';

export const IntegralAnimation: FC<{ taskId: string; resolve: (value: unknown) => void }> = ({
  taskId,
  resolve,
}) => {
  const activity = getActivity(taskId);
  const closeModal = useCloseModal();
  const [score, setScore] = useState(0);
  const [pos, setPos] = useState({ right: 0, bottom: 0 });

  useEffect(() => {
    if (score > 0) {
      setTimeout(() => {
        closeModal('score-modal');
        resolve(0);
      }, 600);
    } else {
      void sleep(1700).then(() => {
        const serviceCenter = document.querySelector('.service-center');
        if (!serviceCenter) return;
        const rect = serviceCenter.getBoundingClientRect();
        setPos({
          right: window.innerWidth - rect.left - rect.width / 2,
          bottom: window.innerHeight - rect.top,
        });
        setScore(activity?.score ?? 0);
      });
    }
  }, [score, activity?.score, closeModal, resolve]);

  if (score > 0) {
    return (
      <div
        className="text-t4-medium text-yellow flex items-center translate-x-1/2 absolute"
        style={{ right: `${pos.right}px`, bottom: `${pos.bottom}px` }}
      >
        <img src={Images.littleMedal} alt="" className="w-6" />+{score}
      </div>
    );
  }

  return (
    <div className="lazyhidden relative h-[300px] w-[300px] overflow-hidden">
      <img
        className="fadein absolute left-1/2 h-[100px] w-[100px] -translate-x-1/2 -translate-y-1/2 "
        src={Images.medal}
      />
      {/* <img
        className="lazyshow absolute left-1/2 top-1/2 h-[150px] w-[150px] -translate-x-1/2 -translate-y-1/2 "
        src={Images.medalBg}
      /> */}
    </div>
  );
};
