import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { message } from 'src/common/components/message';
import { NextModalProvider, useOpenModal } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { request } from 'src/common/request';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateActivity } from 'src/hooks/activities/use-update-activity';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { useReportIllegal } from 'src/hooks/share/use-report-illegal';
import { Modals } from 'src/modals';
import { $currentUserCache } from 'src/services/user/current-user';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { HeaderCover } from 'src/views/main/page-doc/header-cover';
import { v4 as uuidV4 } from 'uuid';
import type { FormViewContent, SubmitDataType } from '.';
import { BitableContext, useBitable } from '../context';
import { FormContainer } from './form-container';
import { FormEndPage } from './form-end-page/form-end-page';
import { FormErrorPageView } from './form-error-page-view';

interface ShareFormViewProps {
  shareId?: string;
}
let FormViewContent0: typeof FormViewContent;
export const set_cycle_formViewContent = (content: typeof FormViewContent) => {
  FormViewContent0 = content;
};

export const ShareFormView: FC<ShareFormViewProps> = ({ shareId }) => {
  const updateActivity = useUpdateActivity();
  const { collectionId, viewId } = useBitable();
  const [nextPage, setNextPage] = useState<ReactNode | null>(null);

  const collectionView = useCollectionView(viewId);
  /** 是否风控 */
  const { formIllegal, formOnlyOneSubmit } = collectionView?.format || {};
  const report = useReportIllegal(viewId, { blockType: 'form' });
  const block = usePickBlock(collectionId, ['data'], ['cover', 'coverPos']);
  const insertRecord = useInsertRecordUI({ ignoreSorters: true });
  const isPreview = !shareId;

  // 特殊空间
  const officialSpaceShare = useOfficialSpaceShare(collectionId);
  const SubmittedView = (
    <FormEndPage
      viewId={viewId}
      formOnlyOneSubmit={formOnlyOneSubmit}
      resubmit={() => {
        setNextPage(null);
      }}
    />
  );

  const onSubmit = (submitData: SubmitDataType) => {
    // 通过shareId提交收集表
    if (shareId) {
      if (!collectionView) return message.success('提交失败，请刷新后重试');
      const { title, ...collectionProperties } = submitData;
      void request.editor.formViewPostBlock
        .raw(shareId, {
          id: uuidV4(),
          spaceId: collectionView.spaceId,
          segments: title,
          collectionProperties,
        })
        .then((res) => {
          if (res.code === 200) {
            setNextPage(SubmittedView);
            if ($currentUserCache.uuid) {
              void updateActivity(ActivityIds.LIMITED_TIME_COLLECTION_TABLE, {
                createdBy: collectionView.createdBy,
              });
            }
          } else {
            setNextPage(<FormErrorPageView icon="😑" description={res.msg} />);
          }
        });
      bizTracker.event('submit_form');
    } else {
      void insertRecord({
        viewId,
        where: {},
        propertyValues: submitData,
      });
      setNextPage(SubmittedView);
    }
  };

  if (!block) return null;

  return (
    <FormContainer
      className={'p-0'}
      containerClassName={'relative min-h-[100%]'}
      header={
        <HeaderCover
          shareId={shareId}
          uuid={block.uuid}
          ossName={block.data.cover || 'cover/flowus2'}
          hiddenEditorButton
          height={320}
        />
      }
      report={!officialSpaceShare && !formIllegal ? report : undefined}
      hiddenFooter={isPreview}
    >
      {nextPage ? (
        nextPage
      ) : (
        <FormViewContent0 shareOrPreview className="px-20" onSubmit={onSubmit} />
      )}
    </FormContainer>
  );
};

/** 打开预览收集表 */
export const useOpenPreViewForm = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params: { viewId: string; collectionId: string }) => {
      const { viewId, collectionId } = params;
      const context: BitableContext = {
        viewId,
        collectionId,
        viewParentId: collectionId,
        readonly: true,
        managerReadonly: true,
        changeView: () => {},
        isColumnListChildren: false,
        isFoldListChildren: false,
        viewType: CollectionViewType.FORM,
      };
      openModal.modal({
        modalId: Modals.PREVIEW_FORM,
        className: 'form-scroll-container w-[90vw] h-[90vh] pb-10 overflow-y-auto',
        content: () => (
          <BitableContext.Provider value={context}>
            <NextModalProvider>
              <EditableNamespace.Provider value={UUID_NAMESPACE.FORM_PREVIEW}>
                <ShareFormView />
              </EditableNamespace.Provider>
            </NextModalProvider>
          </BitableContext.Provider>
        ),
      });
    },
    [openModal]
  );
};
