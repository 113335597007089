import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { publicLink } from 'src/common/const';
import { request } from 'src/common/request';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { useReadonly } from 'src/hooks/page';
import { getPermissions } from 'src/hooks/share/use-permissions';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { BackLinkGroup } from './components/back-link-group';
import { BackLink } from './components/back-link-group/back-link';
import { EmptyLink } from './components/empty-link';
import { Tip } from './components/tip';
import type { TabProps } from './types';

type BackLinks = {
  pageId: string;
  subNodes: Set<string>;
}[];

export const LinkCurrentPage: FC<TabProps> = ({ uuid, setState }) => {
  const [backLinks, setBackLinks] = useState<BackLinks>([]);

  const isLocked = useBlockLocked(uuid);
  const readonly = useReadonly(uuid, false);

  const validBackLinks = useObservableStore(
    ({ blocks }) => {
      return backLinks.filter(({ pageId, subNodes }) => {
        if (subNodes.size === 0) return false;
        const { role } = getPermissions(pageId, { ...cache, blocks });
        return role !== PermissionRole.NONE;
      });
    },
    [backLinks]
  );

  const fetchBackLinks = useCallback(
    async (pageBlock: NextBlock) => {
      setState((pre) => ({ ...pre, currentLoading: true }));

      try {
        const res = await request.editor.getDocBacklinks.raw(uuid);
        if (res.code !== 200) return;

        const { recordMap, results } = res.data;
        dispatch(
          blocksActions.update({
            blocks: recordMap.blocks as Record<string, NextBlock>,
          })
        );
        const data: BackLinks = [];
        results.forEach(({ pageId, uuid: id }) => {
          if (!recordMap.blocks || !pageId || !id) return;
          const block = recordMap.blocks[id];
          if (!block) return;
          const { segments } = block.data;
          // 过滤行内页面
          if (segments?.some((s) => pageBlock.parentId === id && s.uuid === uuid)) return;
          const backLink = data.find((o) => o.pageId === pageId);
          if (backLink) {
            backLink.subNodes.add(id);
          } else {
            const subNodes = new Set<string>();
            subNodes.add(id);
            data.push({ pageId, subNodes });
          }
        });
        setBackLinks(data);
      } finally {
        setState((pre) => ({ ...pre, init: false, currentLoading: false }));
      }
    },
    [setState, uuid]
  );

  useEffect(() => {
    const pageBlock = cache.blocks[uuid];
    if (!readonly && pageBlock && !pageBlock.local) {
      void fetchBackLinks(pageBlock);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LENGTH = validBackLinks.length;

  useEffect(() => {
    setState((pre) => ({ ...pre, currentNum: LENGTH }));
  }, [LENGTH, setState]);

  const cancelLink = useCallback((pageId: string, subId: string) => {
    setBackLinks((pre) =>
      pre.map((o) => {
        if (o.pageId === pageId) {
          o.subNodes.delete(subId);
        }
        return o;
      })
    );
  }, []);

  if (readonly) return null;

  return (
    <>
      {!__BUILD_IN__ && (
        <Tip
          type="LINK_TABS_CURRENT"
          link={publicLink.quotePage}
          describe="💡 在其他页面中引用了当前页面，将在此展示被引用记录"
          tip="如何引用页面"
        />
      )}
      {LENGTH ? (
        validBackLinks.map(({ pageId, subNodes }) => (
          <BackLinkGroup key={pageId} uuid={pageId}>
            {[...subNodes].map((id, i) => (
              <BackLink
                key={id}
                pageId={pageId}
                uuid={id}
                index={i}
                isLocked={isLocked}
                callback={() => cancelLink(pageId, id)}
              />
            ))}
          </BackLinkGroup>
        ))
      ) : (
        <EmptyLink>当前页面暂未被其他页面引用</EmptyLink>
      )}
    </>
  );
};
