import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import type { FC, MutableRefObject } from 'react';
import { memo, useCallback, useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { DATE_FORMAT } from 'src/common/const';
import { deepEqual } from '@flowus/common/utils/tools';
import { useSize } from 'src/common/utils/use-size';
import { Direction, DirectionMap, UnitWidthMap } from '../const';
import { useTimeline } from '../context';
import { getTimelineDateRange, getUnitLength } from '../utils/get-timeline-dates';
import { useRenderTime } from './record-card';

interface Props {
  recordId: string;
  card: MutableRefObject<HTMLDivElement | null>;
}

export const IndicatorArrow: FC<Props> = memo(({ recordId, card }) => {
  const { setTimelineDates, container, scrollLeft, stepLength, timelineDates, zoomLevel } =
    useTimeline();
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  const renderTime = useRenderTime(recordId);
  const containerWidth = useSize(container.current).width;

  const { renderStartTime, renderEndTime } = renderTime ?? {};

  const updateArrow = useCallback(() => {
    if (!container.current || !card.current) return;

    const cardRect = card.current.getBoundingClientRect();
    const containerRect = container.current.getBoundingClientRect();

    if (cardRect.left < containerRect.left !== leftArrow) {
      setLeftArrow(cardRect.left < containerRect.left);
    }

    if (cardRect.right > containerRect.right !== rightArrow) {
      setRightArrow(cardRect.right > containerRect.right);
    }
  }, [card, container, leftArrow, rightArrow]);

  useEffect(() => {
    if (!container.current) return;
    const containerNode = container.current;

    containerNode.addEventListener('scroll', updateArrow);
    return () => containerNode.addEventListener('scroll', updateArrow);
  }, [container, updateArrow]);

  useEffect(() => {
    updateArrow();
  }, [updateArrow, renderStartTime, renderEndTime, timelineDates, containerWidth]);

  const handleClick = (direction: Direction) => {
    if (!container.current) return;
    if (!renderStartTime || !renderEndTime) return;

    const edgeOffset = 100;
    const isLeftDirection = direction === Direction.left;

    const dates = getTimelineDateRange({
      zoomLevel,
      base: isLeftDirection ? renderStartTime : renderEndTime,
      length: stepLength.current,
    });

    if (isLeftDirection) {
      scrollLeft.current =
        getUnitLength(renderStartTime, dates[0] as number, zoomLevel) * UnitWidthMap[zoomLevel] -
        edgeOffset;
    } else {
      scrollLeft.current =
        getUnitLength(renderEndTime, dates[0] as number, zoomLevel) * UnitWidthMap[zoomLevel] -
        (container.current.clientWidth - edgeOffset);
    }

    setTimelineDates(dates);

    if (isLeftDirection) {
      setLeftArrow(false);
    } else {
      setRightArrow(false);
    }
  };

  if (!renderStartTime || !renderEndTime) return null;

  return (
    <>
      {DirectionMap.map((direction) => {
        if (!container.current) return null;

        const isRightDirection = direction === Direction.right;
        const isShow = isRightDirection ? rightArrow : leftArrow;

        return (
          <button
            key={direction}
            onClick={() => handleClick(direction)}
            className={cx(
              'sticky h-full px-2 flex items-center pointer-events-auto group z-[20] duration-150'
            )}
            style={{
              left: isRightDirection ? container.current.clientWidth - 32 : 0,
              opacity: isShow ? 1 : 0,
              transform: isShow ? 'scale(1)' : 'scale(0)',
            }}
          >
            <Icon
              name="IcDateBack"
              size="small"
              className={cx('text-white1 bg-black/30 rounded', isRightDirection && 'rotate-180')}
            />

            <span
              className={cx(
                'absolute px-1 flex items-center text-black bg-white1 rounded text-t4 opacity-0 group-hover:opacity-100 pointer-events-none',
                isRightDirection ? 'right-[30px]' : 'left-[30px]'
              )}
            >
              {dayjs(renderStartTime).format(DATE_FORMAT)}
              <Icon name="IcDateEnd" size="xxsmall" className="mx-0.5" />
              {dayjs(Math.max(renderStartTime, renderEndTime)).format(DATE_FORMAT)}
            </span>
          </button>
        );
      })}
    </>
  );
}, deepEqual);
