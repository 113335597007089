import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo } from 'react';
import type { Position } from 'src/hooks/page/use-dnd/types';
import { getState } from 'src/redux/store';

interface Props {
  position?: Position;
  viewId?: string;
  zIndex: number;
}

export const Indicator: FC<Props> = memo(({ position, zIndex, viewId }) => {
  if (
    viewId &&
    position &&
    getState().collectionViews[viewId]?.type === CollectionViewType.GALLERY
  ) {
    // gallery 视图时转换 top 和 bottom 为 left 和 right
    if (position === 'top') {
      position = 'left';
    } else {
      position = 'right';
    }
  }
  if (position === 'top') {
    return <div className="absolute h-1 bg-black/30 inset-x-0 -top-0.5" />;
  }

  if (position === 'bottom') {
    return (
      <div
        className={cx(
          'absolute h-1 inset-x-0 bg-black/30',
          zIndex === 0 ? 'bottom-[-3px]' : '-bottom-1'
        )}
        style={{ zIndex: zIndex + 10 }}
      >
        <span className="w-0.5 h-1 absolute bg-white1 z-[9999] bottom-0 -left-px" />
      </div>
    );
  }

  if (position === 'left') {
    return <div className="absolute inset-y-0 w-1 bg-black/30 pointer-events-none -left-3" />;
  }

  if (position === 'right') {
    return <div className="absolute inset-y-0 w-1 bg-black/30 pointer-events-none -right-3" />;
  }

  return null;
});
