import { LOCAL_LNG } from '@flowus/common/const';
import { CreatePageWidthMode } from 'src/hooks/public/get-page-width-fixed';
import { SettingThemeMode } from 'src/services/app/type';

export const themeList = [
  {
    title: '跟随系统',
    value: SettingThemeMode.system,
  },
  {
    title: '浅色',
    value: SettingThemeMode.light,
  },
  {
    title: '深色',
    value: SettingThemeMode.dark,
  },
];

export enum OpenPageMode {
  JUMP = 'jump',
  RIGHT_PAGE = 'rightPage',
}

export const openPageModeList = [
  {
    title: '跳转',
    value: OpenPageMode.JUMP,
  },
  {
    title: '右侧打开',
    value: OpenPageMode.RIGHT_PAGE,
  },
];

export const createPageWidthList = [
  {
    title: LOCAL_LNG.fixed,
    value: CreatePageWidthMode.FIXED,
  },
  {
    title: '自适应宽度',
    value: CreatePageWidthMode.AUTO,
  },
];
export const createPageWidthFont = [
  {
    title: '默认字体',
    value: 'default',
  },
];
if (__BUILD_IN__) {
  createPageWidthFont.push(
    ...[
      {
        title: 'Lora',
        value: 'loraVariable',
      },
      {
        title: 'DM Mono',
        value: 'dmMono',
      },
    ]
  );
} else {
  createPageWidthFont.push(
    ...[
      {
        title: '霞鹜文楷',
        value: 'lxgwWenKai',
      },
      {
        title: '思源宋体',
        value: 'sourceHanSerif',
      },
    ]
  );
}

export const createPageWidthLayout = [
  {
    title: '紧密布局',
    value: 'compact',
  },
  {
    title: '默认布局',
    value: 'default',
  },
  {
    title: '宽松布局',
    value: 'loose',
  },
];
