import { IpcMessage } from '@flowus/shared';
import * as clipboard from 'clipboard-polyfill';
import { message } from 'src/common/components/message';
import { ipcSendMessage, isFlowUsApp } from 'src/utils/electron-util';

export const handleSpace = (text = '') => {
  // \u00a0 这种空格到某些编辑器里显示异常
  return text.replace(/\u00a0/g, ' ');
};

/** 统一剪切板写入方法 */
export const writeTextInClipboard = async (
  str: string,
  opt?: {
    message?: string;
  }
) => {
  if (isFlowUsApp.check) {
    ipcSendMessage(IpcMessage.CopyText, str);
  }

  await clipboard.writeText(handleSpace(str));

  if (opt?.message === 'none') return;
  message.success(opt?.message ?? '复制成功');
};

export const writeImageInClipboard = async (blob: Blob) => {
  if (isFlowUsApp.check) {
    const fs = require('fs');
    const os = require('os');
    const path = `${os.tmpdir()}/${Date.now()}.png`;
    const arrayBuffer = await blob.arrayBuffer();
    fs.writeFileSync(path, Buffer.from(arrayBuffer));
    return ipcSendMessage(IpcMessage.CopyImage, path);
  }
  const clipboardItemInput = new ClipboardItem({ [blob.type]: blob });
  void navigator.clipboard.write([clipboardItemInput]);
};
