import type { CSSProperties, FC } from 'react';
/**
 * 空页面，可通过style自定义高,图案文字居中就行了
 */
export const EmptyView: FC<{ desc: string; style?: CSSProperties }> = ({ desc, style }) => (
  <div
    className="flex flex-col items-center pointer-events-none relative"
    style={{
      ...style,
      top: 'calc(50% - 116px + 50px)', // 116px是图案+文字的高度， 50px是顶部tab高度
    }}
  >
    <div className="text-[70px] leading-[80px]">👀</div>
    <div className="text-grey4 text-t1">{desc}</div>
  </div>
);
