export enum MenuListActionType {
  DELETE = 1,
  COPY,
  RENAME,
  MOVETO,
  TURN_INTO,
  CREATE,
  COLOR,
  // 下面两个是内嵌多维表特殊的菜单，转换为页面以及从页面转换为内嵌多维表
  TURN_TO_PAGE,
  TURN_TO_EMBED, // 转换为内嵌，包括多维表和思维导图
  TEMPLATE,
  TABLE_CONVERT,
  COPY_MOVE,
  CUT,
  COPY_CLIPBOARD,
  OPEN_RIGHT_PAGE,
  AI_EDITOR,
  OPEN_TAB,
}

export enum InlineContentType {
  DATE = 'date',
  PEOPLE = 'people',
  LINK_PAGE = 'link_page',
  EQUATION = 'equation',
  EMOJI = 'emoji',
  PAGE = 'page',
}

export enum MenuCategory {
  aiAssistantWithEmptyDoc = 'AI 辅助',
  aiAssistantWithNotEmptyDoc = 'AI 辅助',
  basicBlock = '基础块',
  media = '媒体',
  embed = '嵌入内容',
  inlineElement = '行内元素',
  layout = '布局',
  turninto = '转换为',
  action = '快捷操作',
  lastUsedColor = '上次使用颜色',
  color = '文字颜色',
  colorBackground = '背景颜色',
  database = '多维表',
  advanced = '高级块',
}
