import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { VirtualElement } from '@popperjs/core';
import type { FC } from 'react';
import React from 'react';
import { getModalStyle } from 'src/bitable/bitable-manager/view-list-manager/select-record-toolbar';
import { ICON_MAP, READONLY_PROPERTIES } from 'src/bitable/const';
import { CellEditor } from 'src/bitable/table-view/cell/editor';
import { Site } from 'src/bitable/table-view/cell/types';
import { useOpenRollupSetting } from 'src/bitable/table-view/widgets/rollup-setting';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { segmentsToText } from 'src/editor/utils/editor';
import { useUpdatePropertyValue } from 'src/hooks/block/use-update-property-value';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { selectedBlocksToIds } from 'src/utils/select-block-util';

interface PropertyListProps {
  popcorn: VirtualElement;
  blockId: string;
  collectionId: string;
  selectedBlocks: SelectBlock[];
}

export const PropertyList: FC<PropertyListProps> = ({
  popcorn,
  blockId,
  collectionId,
  selectedBlocks,
}) => {
  const openRollupSetting = useOpenRollupSetting(collectionId);
  const updateProperty = useUpdatePropertyValue();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const schemas = useObservableStore(
    ({ blocks }) =>
      Object.entries(blocks[collectionId]?.data.schema ?? {})
        .map(([key, schema]) => {
          return { ...schema, propertyId: key };
        })
        // No.属性不允许编辑
        .filter((v) => v.type !== CollectionSchemaType.ID_NUMBER),
    [collectionId]
  );

  const handleItemClick = (
    propertyId: string,
    schemaType: CollectionSchemaType,
    event: React.MouseEvent
  ) => {
    const ids = selectedBlocksToIds(selectedBlocks);
    const firstSelectRecord = selectedBlocks[0];
    const firstRecordId = ids[0];
    if (!firstRecordId || !firstSelectRecord) return;

    if (schemaType === CollectionSchemaType.CHECKBOX) {
      const { blocks } = cache;
      const value = blocks[firstRecordId]?.data.collectionProperties?.[propertyId];
      updateProperty(ids, propertyId, formatCheckBoxValue(segmentsToText(value)) ? 'NO' : 'YES');
    } else if (schemaType === CollectionSchemaType.ROLLUP) {
      openRollupSetting(event, propertyId, firstSelectRecord.viewId ?? '', popcorn);
    } else {
      const modalStyle = getModalStyle(schemaType);

      dispatch(uiActions.update({ selectedBlocks: [] }));
      openModal.dropdown({
        popcorn,
        placement: 'right',
        content: ({ onCloseModal }) => {
          return (
            <div className="next-modal" style={modalStyle}>
              <CellEditor
                site={Site.CELL}
                recordIds={ids}
                viewId={firstSelectRecord.viewId}
                recordId={firstRecordId}
                propertyId={propertyId}
                onUpdate={() => {}}
                onClose={onCloseModal}
              />
            </div>
          );
        },
      });
    }
  };

  const items: ListItem<any>[] = [];
  schemas.forEach((property) => {
    if (READONLY_PROPERTIES.includes(property.type)) return;
    items.push({
      type: ListItemType.OPERATION,
      data: {
        propertyId: property.propertyId,
        title: property.name,
        schemaType: property.type,
        icon: ICON_MAP[property.type],
      },
    });
  });

  return (
    <ListView
      className="next-modal-scroll w-[200px] py-[6px]"
      items={items}
      onItemClick={(res, _, event) => {
        const { propertyId, schemaType } = res.data;
        closeModal(blockId);
        handleItemClick(propertyId, schemaType, event);
      }}
    />
  );
};
