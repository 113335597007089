import { Role } from '@flowus/common';
import { PermissionRole } from '@next-space/fe-api-idl';
import { useContext, useMemo } from 'react';
import { $searchParams } from 'src/utils';
import { PageScene, usePageScene } from '../../views/main/scene-context';
import { LockedContext, ReadonlyContext } from '../block/use-block-locked';
import { usePermissions } from '../share/use-permissions';

/**
 * 是否只读
 * includeLocked 默认都是true，页面锁的文档部分暂时只做成跟share page一样，后面产品要求有差异再调
 * checkComment 默认是false，有评论权限也会返回true，如果设置为true，有评论权限就会返回false
 */
export const useReadonly = (uuid?: string, includeLocked = true, checkComment = false) => {
  const scene = usePageScene();
  let pageSceneReadonly =
    scene === PageScene.PAGE_HISTORY ||
    scene === PageScene.PAGE_FEEDS ||
    // 因为分享页可评论，这里需要去掉，但不确定会不会有其他case有问题，因此过2个版本再删除这行代码
    // scene === PageScene.SHARE ||
    scene === PageScene.TEMPLATE ||
    scene === PageScene.NOTIFICATION ||
    scene === PageScene.UPDATE_LOG ||
    scene === PageScene.FORM_ENG_PAGE ||
    scene === PageScene.PAGE_CHART ||
    scene === PageScene.PAGE_LITE_PREVIEW;
  // 评论在动态以及消息通知里需要支持回复，因此如果是在这两者里的话readonly需要设置为false
  if (checkComment && scene && [PageScene.NOTIFICATION, PageScene.PAGE_FEEDS].includes(scene)) {
    pageSceneReadonly = false;
  }

  const locked = useContext(LockedContext);
  const readonly = useContext(ReadonlyContext);
  const { role } = usePermissions(uuid);

  const checkRole = uuid
    ? Role.contains(role, checkComment ? PermissionRole.COMMENTER : PermissionRole.WRITER)
    : true;

  return useMemo(
    () =>
      readonly ||
      !!$searchParams.print ||
      pageSceneReadonly ||
      !checkRole ||
      (includeLocked && locked),
    [checkRole, includeLocked, locked, pageSceneReadonly, readonly]
  );
};
