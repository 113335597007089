import { useCallback } from 'react';
import { request } from 'src/common/request';

import { getActivity } from './use-activity';
import { useFetchActivities } from './use-fetch-activities';

/** 更新普通的活动，非基础功能活动，新用户基础活动请用 ```updateTask``` */
export const useUpdateActivity = () => {
  const fetchActivities = useFetchActivities();

  return useCallback(
    async (
      uuid: string,
      params?: {
        completed?: boolean;
        createdBy?: string;
        onlyViewed?: boolean;
        ignorePresence?: boolean;
      }
    ) => {
      const activity = getActivity(uuid);
      // 没有活动|下线|已完成
      if (!activity && !params?.ignorePresence) return;

      if (params?.onlyViewed) {
        await request.infra.completedActivity.raw(uuid, { viewed: true });
      } else {
        if (['abandoned', 'completed'].includes(activity?.status || '')) return;
        await request.infra.completedActivity.raw(uuid, {
          completed: params?.completed ?? true,
          viewed: true,
          createdBy: params?.createdBy,
        });
      }

      void fetchActivities();
    },
    [fetchActivities]
  );
};
