import { createPersistStore, createSetState } from '@flowus/common/zustand/utils';
import type { Timeout } from 'ahooks/lib/useRequest/src/types';
import { keys, omit } from 'lodash-es';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';

type Result = Awaited<ReturnType<typeof request.infra.getTasks>>['results'] extends Record<
  string,
  infer T
>
  ? T
  : never;

type Params = Result['result'] extends infer P | undefined ? P : never;

type Fn = (args: Omit<Params, 'status' | 'msg'>) => Promise<void> | void;

interface Callbacks {
  success?: Fn;
  fail?: Fn | Function;
  resData?: Result;
  startTime?: number;
  lastTime?: number;
  body?: Parameters<typeof request.infra.enqueueTask>[0];
}

const taskIds = new Map<string, Callbacks>();

export const $taskState = createPersistStore<Record<string, Callbacks>>(
  `$enqueue-task-id-list-1`,
  () => ({}),
  {
    isSpaceScope: true,
  }
);

const $setTaskState = createSetState($taskState, { assign: true });

const updateTaskState = () => {
  // 定义一个对象来存储转换后的数据
  const callbacksObject: { [key: string]: Callbacks } = {};

  // 遍历 taskIds Map，将其转换为对象结构
  taskIds.forEach((value, key) => {
    callbacksObject[key] = omit(value, ['success', 'fail']);
  });

  $setTaskState(callbacksObject, { replace: true });
};

export const refreshTaskFromState = () => {
  const _tasks = $taskState.getState();

  keys(_tasks).forEach((key) => {
    const cur = _tasks[key];
    if (!taskIds.has(key) && cur?.resData?.status === 'in_progress') {
      taskIds.set(key, cur || {});
    }
  });

  getTask();
};

export const deleteDoneTaskByState = (taskId: string) => {
  const _tasks = $taskState.getState();

  $setTaskState(omit(_tasks, taskId), { replaceAll: true });
};

export const enqueueTask = async (
  body: Parameters<typeof request.infra.enqueueTask>[0],
  callbacks: Callbacks = {}
) => {
  const { taskId } = await request.infra.enqueueTask(body);

  taskIds.set(taskId, {
    body,
    startTime: Date.now(),
    ...callbacks,
  });

  getTask();

  return taskId;
};

let timer: Timeout | null = null;
let retry = 0;

const getTask = () => {
  if (!taskIds.size) return;

  if (timer) {
    clearTimeout(timer);
  }
  updateTaskState();

  timer = setTimeout(async () => {
    const res = await request.infra.getTasks.raw({ taskIds: [...taskIds.keys()] });
    if (res.code === 200) {
      retry = 0;
      const { results } = res.data;
      const ary = Object.values(results);

      // 如果是空的，说明这些任务都结束了
      if (!ary.length) {
        [...taskIds.keys()].forEach((taskId) => {
          const task = taskIds.get(taskId);

          if (task) {
            task.fail?.({});
            task.lastTime = Date.now();
            if (task.resData?.status) {
              task.resData.status = 'fail';
            }
            // 同步下 store 状态
            taskIds.set(taskId, task);
            updateTaskState();
          }
        });
      }

      ary.forEach(handleResult);
      getTask();
    } else {
      if (retry === 5) {
        retry = 0;
        taskIds.forEach((cb) => {
          cb.fail?.({});
        });
        return;
      }
      retry++;
      getTask();
    }
  }, 2000);
};

const handleResult = (value: Result) => {
  const { status, taskId, result } = value;
  const task = taskIds.get(taskId);

  if (task) {
    task.resData = value;
    task.lastTime = Date.now();
    // 同步下 store 状态
    taskIds.set(taskId, task);
    updateTaskState();
  }

  if (status === 'success') {
    message.closeMessage(taskId);

    if (result) {
      const { status, msg, ...rest } = result;

      if (status === 'success') {
        void task?.success?.(rest);
      } else if (msg) {
        void task?.fail?.(rest);
        message.error(msg);
      }
    }

    taskIds.delete(taskId);
  }
};
