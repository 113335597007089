import { getCardBackgroundColor } from '@flowus/common/block/color/get-block-color';
import { CardColorSource, CollectionSchemaType } from '@next-space/fe-api-idl';
import { segmentsToText } from 'src/editor/utils/editor';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useCardColorSetting } from '../collection-view/use-collection-view';

export const useRecordBgColor = (viewId: string, recordId: string) => {
  const colorSetting = useCardColorSetting(viewId);

  return useObservableStore(
    ({ blocks }) => {
      const block = blocks[recordId];
      if (!block) return;

      let color: string | undefined = '';
      if (colorSetting?.type === CardColorSource.CUSTOM) {
        color = block.data.collectionCardColor;
      }

      if (colorSetting?.type === CardColorSource.PROPERTY) {
        if (!colorSetting.propertyId) return;

        const schema = blocks[block.parentId]?.data.schema?.[colorSetting.propertyId ?? ''];
        if (!schema || schema.type !== CollectionSchemaType.SELECT) return;

        const segments = block.data.collectionProperties?.[colorSetting.propertyId ?? ''];
        const value = segmentsToText(segments);
        const option = schema.options?.find((item) => item.value === value.trim());

        color = option?.color;
      }

      return getCardBackgroundColor(color);
    },
    [viewId, recordId]
  );
};
