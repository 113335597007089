import { Subject } from 'rxjs';
import { fastEqual } from '@flowus/common/utils/tools';
import { getIpcRenderer, isFlowUsApp } from 'src/utils/electron-util';

interface DownloadTaskDetail {
  id: string;
  title: string;
  savePath: string;
  state: 'progressing' | 'completed' | 'cancelled' | 'interrupted';
  startTime: number;
  receivedBytes: number;
  totalBytes: number;
  numItems: number;
  itemIndex: number;
}

interface MainDownloadManager {
  // on(event: 'change', listener: () => void): void;
  getTasks(): DownloadTaskDetail[];
  download(opts: { id: string; url: string; fileName?: string }): Promise<void>;
  downloadMany(opts: { id: string; list: { url: string; fileName: string }[] }): Promise<void>;
  cancel(item: string): void;
  remove(item: string): void;
}

// 下载管理器，仅 electron 下使用
class DownloadManager {
  private mainMgr?: any;
  private _tasks: DownloadTaskDetail[] = [];
  private change$ = new Subject<void>();
  private pollTimer?: number;
  private allowStopSince = -Infinity;

  get tasks() {
    return this._tasks;
  }

  get onChange() {
    return this.change$.asObservable();
  }

  constructor() {
    if (!isFlowUsApp.check) return;
    const { port1, port2 } = new MessageChannel();
    const ipcRenderer = getIpcRenderer();
    ipcRenderer?.postMessage('comlink-download-manager-port', null, [port1]);
    void import('comlink-electron-renderer').then((Comlink) => {
      this.mainMgr = Comlink.wrap<MainDownloadManager>(port2);
      this.startPoll();
    });
  }

  // REMARK: 原先设计的是通过注册 this.mainMgr 的 change 事件
  // 但是会存在内存泄露问题（反注册可能会失败），并且 comlink 这边一直没调通。所以改成了 poll 模式
  startPoll(minDuration = 1000) {
    this.allowStopSince = Math.max(this.allowStopSince, Date.now() + minDuration);
    if (this.pollTimer || this.mainMgr == null) return;
    this.pollTimer = window.setInterval(async () => {
      if (!this.mainMgr) return;
      const tasks = await this.mainMgr.getTasks();
      if (!fastEqual(tasks, this._tasks)) {
        this._tasks = tasks;
        this.change$.next();
      }
      if (
        !this._tasks.some((it) => it.state === 'progressing') &&
        Date.now() >= this.allowStopSince
      ) {
        clearInterval(this.pollTimer);
        this.pollTimer = undefined;
      }
    }, 50);
  }

  async download(opts: { id: string; url: string; fileName?: string }) {
    await this.mainMgr?.download(opts);
    this.startPoll();
  }

  async downloadMany(opts: { id: string; list: { url: string; fileName: string }[] }) {
    await this.mainMgr?.downloadMany(opts);
    this.startPoll();
  }

  async cancel(id: string) {
    await this.mainMgr?.cancel(id);
    this.startPoll();
  }

  async remove(id: string) {
    await this.mainMgr?.remove(id);
    this.startPoll();
  }
}

export const $downloadManager = new DownloadManager();
