import type { AIMemberDTO } from '@next-space/fe-api-idl';
import { createSetState, createStore } from '../../zustand/utils';

interface AIStore {
  aiMembers: AIMemberDTO[];
}

const $aiStore = createStore<AIStore>(() => {
  return {
    aiMembers: [],
  };
});

export const $setAiStore = createSetState($aiStore, { assign: true });

export const useAIStore = $aiStore;
