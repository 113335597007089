export const PERSIST_STORE = false;
export const OPEN_RX = true;
export const AI_MIND_MAP = true;
export const OPEN_WPS = false;
// export const CYCLE_STATUS_BUTTON = !__BUILD_IN__;
export const FREEZE_TABLE_COLUMN = true;
export const CHART = true;
export const AI_TIP = !__BUILD_IN__;
// export const OPEN_DEBUG = __HOST_LOCAL__;
export const OPEN_DEBUG = false;
export const OPEN_BUILDIN_TEMPLATES = true;
