import { registerSupportItem } from './helper';
import { BlockItem } from './item/block-item';
import { BlockText, OperableBlockText } from './item/block-text';
import { BlockTransform } from './item/block-transform';
import { LineItem } from './item/line-item';
import { OperationItem } from './item/operation-item';
import { SearchFileItem } from './item/search-file-item';
import { SearchItem } from './item/search-item';
import { TextDescription } from './item/text-description';
import { TextSwitchItem } from './item/text-switch-item';
import { ListItemType } from './types';

registerSupportItem(ListItemType.OPERATION, OperationItem);
registerSupportItem(ListItemType.SEARCH, SearchItem);
registerSupportItem(ListItemType.SEARCH_FILE, SearchFileItem);
registerSupportItem(ListItemType.LINE, LineItem, true);
registerSupportItem(ListItemType.BLOCK_ITEM, BlockItem);
registerSupportItem(ListItemType.TEXT_SWITCH, TextSwitchItem);
registerSupportItem(ListItemType.BLOCK_TEXT, BlockText, true);
registerSupportItem(ListItemType.OPERABLE_BLOCK_TEXT, OperableBlockText);
registerSupportItem(ListItemType.BLOCK_TRANSFORM, BlockTransform);
registerSupportItem(ListItemType.TEXT_DESCRIPTION, TextDescription);
