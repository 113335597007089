// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck fucking the i18n
import { isElectron } from 'react-device-detect';

import type { CombineViewProps, LoginProps } from '../types';
import { ViewType } from '../types';
import { lazyLoad } from '../utils';

/**web */
const { Login: LoginWeb } = lazyLoad(() => import('./web/login'));
const { ForgetPassword: ForgetPasswordWeb } = lazyLoad(() => import('./web/forget-password'));
const { BindPhone: BindPhoneWeb } = lazyLoad(() => import('./web/bind-phone'));
const { WechatLogin } = lazyLoad(() => import('./web/wechat-login'));
const { WechatLoginElectron } = lazyLoad(() => import('./web/wechat-login-electron'));

const { QQLogin } = lazyLoad(() => import('./web/qq-login'));
const { Complete: CompleteWeb } = lazyLoad(() => import('./web/complete'));
const { Register: RegisterWeb } = lazyLoad(() => import('./web/register'));

/**buildin web */
const { Login: LoginWebOversea } = lazyLoad(() => import('./web-buildin/login'));
const { ForgetPassword: ForgetPasswordWebOversea } = lazyLoad(
  () => import('./web-buildin/forget-password')
);

const { Complete: CompleteWebOversea } = lazyLoad(() => import('./web-buildin/complete'));
const { Register: RegisterWebOversea } = lazyLoad(() => import('./web-buildin/register'));

/**h5 */
const { Login: LoginH5 } = lazyLoad(() => import('./h5/login'));
const { Register: RegisterH5 } = lazyLoad(() => import('./h5/register'));
const { VerifyCodeLogin: VerifyCodeLoginH5 } = lazyLoad(() => import('./h5/verify-code-login'));
const { ForgetPassword: ForgetPasswordH5 } = lazyLoad(() => import('./h5/forget-password'));
const { Complete: CompleteH5 } = lazyLoad(() => import('./h5/complete'));
const { BindPhone: BindPhoneH5 } = lazyLoad(() => import('./h5/bind-phone'));

/**h5-buildin */
const { Login: LoginH5Oversea } = lazyLoad(() => import('./h5-buildin/login'));
const { Register: RegisterH5Oversea } = lazyLoad(() => import('./h5-buildin/register'));
const { VerifyCodeLogin: VerifyCodeLoginH5Oversea } = lazyLoad(
  () => import('./h5-buildin/verify-code-login')
);
const { ForgetPassword: ForgetPasswordH5Oversea } = lazyLoad(
  () => import('./h5-buildin/forget-password')
);
const { Complete: CompleteH5Oversea } = lazyLoad(() => import('./h5-buildin/complete'));

/** common */

export const getView = (
  viewType: ViewType,
  styleType: LoginProps['styleType']
): React.ElementType<CombineViewProps> => {
  switch (viewType) {
    case ViewType.LOGIN: {
      switch (styleType) {
        case 'default':
          return LoginWeb;
        case 'lite':
          return LoginH5;
        case 'build-in-pc':
          return LoginWebOversea;
        case 'build-in-lite':
          return LoginH5Oversea;
        default:
      }
      break;
    }
    case ViewType.FORGET_PASSWORD: {
      switch (styleType) {
        case 'default':
          return ForgetPasswordWeb;
        case 'lite':
          return ForgetPasswordH5;
        case 'build-in-pc':
          return ForgetPasswordWebOversea;
        case 'build-in-lite':
          return ForgetPasswordH5Oversea;
        default:
      }
      break;
    }
    case ViewType.REGISTER: {
      switch (styleType) {
        case 'default':
          return RegisterWeb;
        case 'lite':
          return RegisterH5;
        case 'build-in-pc':
          return RegisterWebOversea;
        case 'build-in-lite':
          return RegisterH5Oversea;
        default:
      }
      break;
    }
    case ViewType.BIND_PHONE: {
      switch (styleType) {
        case 'default':
          return BindPhoneWeb;
        case 'lite':
          return BindPhoneH5;
        default:
      }
      break;
    }
    case ViewType.WECHAT_LOGIN:
      return isElectron ? WechatLoginElectron : WechatLogin;
    case ViewType.COMPLETE: {
      switch (styleType) {
        case 'default':
          return CompleteWeb;
        case 'lite':
          return CompleteH5;
        case 'build-in-pc':
          return CompleteWebOversea;
        case 'build-in-lite':
          return CompleteH5Oversea;
        default:
      }
      break;
    }
    case ViewType.QQ_LOGIN:
      return QQLogin;
    /**下面是h5专有 */
    case ViewType.LOGIN_VERIFY_CODE:
      return styleType === 'lite' ? VerifyCodeLoginH5 : VerifyCodeLoginH5Oversea;
    default:
      return () => {
        return null;
      };
  }
};
