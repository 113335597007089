import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC, Key, PropsWithChildren } from 'react';
import React, { Children, useEffect, useRef, useState } from 'react';
import { bindDataTestId } from 'src/utils/qa-utils';
import { Icon } from '../icon';
import type { TabPaneProps } from './tab-panel';

export interface TabProps {
  defaultActiveIndex?: number;
  className?: string;
  active?: number;
  /**
   * 自定义button，在最后面
   */
  customButtons?: () => React.ReactNode | undefined;
  onChangeTab?: (index: number) => void;
  displayMode?: 'singleNode' | 'preview' | 'lazyLoad'; // 单节点显示,全节点显示,延迟加载
  style?: CSSProperties;
  border?: boolean; // default true
  tabBarClassName?: string;
}
interface TabPaneInfo extends TabPaneProps {
  key: Key | null;
  node: React.ReactElement<TabPaneProps>;
}
function parseTabPaneList(children: React.ReactNode): TabPaneInfo[] {
  const info: TabPaneInfo[] = [];
  Children.toArray(children).forEach((node) => {
    if (React.isValidElement(node)) {
      info.push({
        key: node.key,
        ...node.props,
        node,
      });
    }
  });
  return info;
}
/**
 * tab切换,使用时需要用className设置宽度,非受控
 */
export const Tab: FC<PropsWithChildren<TabProps>> = ({
  className,
  children,
  defaultActiveIndex = 0,
  customButtons,
  onChangeTab,
  active,
  displayMode = 'lazyLoad',
  style,
  border = true,
  tabBarClassName,
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  const divRef = useRef<HTMLDivElement>(null);
  const loadIndexSet = useRef(new Set([activeIndex]));
  useEffect(() => {
    if (active !== undefined) {
      setActiveIndex(active);
    }
  }, [active]);
  const tabInfoList = parseTabPaneList(children);

  loadIndexSet.current.add(activeIndex); // 记录选中的index,表示需要加载
  return (
    <div className={className} style={style} data-no-cancel-selected>
      <div
        ref={divRef}
        className={cx(
          'flex p-2 justify-between',
          { 'border-b border-grey6 ': border },
          tabBarClassName
        )}
      >
        <span className="flex items-center space-x-2">
          {tabInfoList.map((item, index) => (
            <span
              {...item}
              {...bindDataTestId(item.title)}
              key={item.key ?? index}
              onClick={() => {
                setActiveIndex(index);
                onChangeTab?.(index);
              }}
              className={cx(
                'text-t2 py-1 px-1.5 rounded flex-shrink-0 space-x-1',
                activeIndex === index ? 'bg-black_006 animate-click' : 'animate-hover'
              )}
            >
              {item.icon && <Icon size="normal" name={item.icon} />}
              {item.title}
            </span>
          ))}
        </span>
        {customButtons?.()}
      </div>
      {displayMode === 'singleNode' && tabInfoList[activeIndex]?.node}
      {displayMode !== 'singleNode' &&
        tabInfoList.map((item, index) => {
          const loaded = loadIndexSet.current.has(index) || displayMode === 'preview';
          return loaded ? (
            <div key={item.key || index} className={cx({ hidden: index !== activeIndex })}>
              {item.node}
            </div>
          ) : null;
        })}
    </div>
  );
};
