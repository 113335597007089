import type { FC, MouseEvent } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { cx } from '../cx';
import { isInAlipay } from '../url';
import { useIsDarkMode } from '../utils/get-dark';
import './index.css';
import { mermaidTask } from './mermaid-task';

interface Props {
  text?: string;
  className?: string;
  onRender?: (status: boolean) => void;
  onClick?: (event: MouseEvent) => void;
}
export const Mermaid: FC<Props> = memo((props) => {
  const { text = '', className, onRender, onClick } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [isError, setIsError] = useState(false);
  const refId = useRef(v4());
  const isDarkMode = useIsDarkMode();
  const [mermaid, setMermaid] = useState<any>((window as any).mermaid);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (!mermaid && !isInAlipay) {
      timer = setInterval(() => {
        setMermaid((window as any).mermaid);
      }, 1000);
    }
    return () => {
      timer && clearInterval(timer);
    };
  }, [mermaid]);

  useEffect(() => {
    mermaid?.mermaidAPI.initialize({ theme: isDarkMode ? 'dark' : 'neutral' });
  }, [isDarkMode, mermaid]);

  useEffect(() => {
    const { mermaid } = window as any;
    const dom = ref.current;
    if (dom && mermaid) {
      if (!text) {
        setIsError(true);
        dom.innerHTML = '';
        onRender?.(false);
        return;
      }
      mermaidTask.render({
        text,
        id: `mermaid-${refId.current}`,
        onSuccess: (res) => {
          setIsError(false);
          dom.innerHTML = res;
          onRender?.(true);
        },
        onError: (err) => {
          setIsError(true);
          dom.innerHTML = err;
          onRender?.(false);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, mermaid]);

  if (!text) return null;

  return (
    <>
      <div
        onClick={onClick}
        key={refId.current}
        className={cx('flex justify-center', isError && 'text-red text-t3', className)}
        ref={ref}
      />
      {isError && ref.current?.innerText.includes('dynamically') && (
        <div className="text-t3 text-red text-center">资源加载失败，请尝试重新登录</div>
      )}
    </>
  );
});
