import type { PermissionDTO } from '@next-space/fe-api-idl';
import { REMOVE_BLOCK_PERMISSION, SET_BLOCK_PERMISSION } from 'src/redux/actions';
import { dispatch } from 'src/redux/store';

import { updateTime } from './update-time';

export const removeBlockPermission = (uuid: string, permission: PermissionDTO) => {
  updateTime(uuid);
  dispatch(REMOVE_BLOCK_PERMISSION({ uuid, permission }));
};

export const setBlockPermission = (uuid: string, permission: PermissionDTO) => {
  updateTime(uuid);
  dispatch(SET_BLOCK_PERMISSION({ uuid, permission }));
};
