import { cx } from '@flowus/common/cx';
import type { ChangeEvent, CSSProperties, FC, InputHTMLAttributes } from 'react';

interface Props {
  title: string;
  onSelect?: (file: File[]) => void;
  inputAttribute: InputHTMLAttributes<HTMLInputElement>;
  singleFile?: boolean;
  style?: CSSProperties;
  labelClassName?: string;
}
/**
 *
 * 上传本地文件组件
 */
export const UploadLocalFile: FC<Props> = (props) => {
  const { singleFile = false, labelClassName = 'w-[300px]' } = props;
  const processFile = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    props.onSelect?.(Array.from(fileList));
  };
  return (
    <div className="flex justify-center items-center h-[62px] w-[full]" style={props.style}>
      <label className={cx('h-8 w-full', labelClassName)}>
        <div className="flex justify-center items-center text-t4-medium rounded active:opacity-50 text-align-center h-8 px-3.5 bg-active_color text-white hover:bg-active_color/90 cursor-pointer">
          {props.title || '请选择一个文件'}
        </div>
        <input
          type="file"
          multiple={!singleFile}
          {...props.inputAttribute}
          className="hidden"
          onChange={processFile}
        />
      </label>
    </div>
  );
};
