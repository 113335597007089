import type { ComponentProps, FC } from 'react';
import { memo } from 'react';
import type { EnhanceImageProps } from 'src/common/components/enhance-image';
import { EnhanceImage } from 'src/common/components/enhance-image';
import { deepEqual } from '@flowus/common/utils/tools';
import { ImageUploading } from 'src/editor/component/image/image-uploading';
import { UploadStatus } from 'src/redux/types';
import { useUploadInfoById } from 'src/services/upload';

interface ImageLoadBoxProps extends ComponentProps<'img'>, EnhanceImageProps {
  uuid: string;
  src: string;
  showLoading?: boolean;
}
/** 一个带有上传loading效果的图片标签，例如封面图、头像、图片等组件可以通用 */
export const ImageUploadBox: FC<ImageLoadBoxProps> = memo((props) => {
  const { uuid, showLoading = true, src, ...reset } = props;
  const uploadInfo = useUploadInfoById(uuid);
  const progress = uploadInfo?.progress ?? -1;
  return (
    <>
      {showLoading && uploadInfo && uploadInfo.status === UploadStatus.uploading && (
        <ImageUploading progress={progress} />
      )}
      <EnhanceImage src={src} draggable={false} {...reset} />
    </>
  );
}, deepEqual);
