import type { Rect } from '../type';

function isNodeInRange(range: Range, node: Node) {
  try {
    const length = node.nodeValue?.length ?? node.childNodes.length;
    return range.comparePoint(node, 0) <= 0 && range.comparePoint(node, length) >= 0;
  } catch (e) {
    return false;
  }
}

export function wholeTextNodesInRange(range: Range, container?: HTMLElement) {
  if (range.collapsed) return [];

  let root: Node | null = range.commonAncestorContainer;
  if (root.nodeType !== Node.ELEMENT_NODE) {
    root = root.parentElement;
  }
  if (!root) return [];

  const { ownerDocument } = root;
  if (!ownerDocument) return [];

  const textNodes: Text[] = [];
  const nodeIter = ownerDocument.createNodeIterator(root, NodeFilter.SHOW_TEXT);

  let node;
  while ((node = nodeIter.nextNode())) {
    if (!isNodeInRange(range, node)) {
      continue;
    }
    if (container && !container.contains(node)) {
      continue;
    }

    const text = node;
    if (!(text instanceof Text)) continue;

    if (text === range.startContainer && range.startOffset > 0) {
      text.splitText(range.startOffset);
      continue;
    }

    if (text === range.endContainer && range.endOffset < text.data.length) {
      text.splitText(range.endOffset);
    }

    textNodes.push(text);
  }

  return textNodes;
}

export function highlightRange(range: Range, container?: HTMLElement) {
  const textNodes = wholeTextNodesInRange(range, container);

  let textNodeSpans: Text[][] = [];
  let prevNode: Text | null = null;
  let currentSpan = null;

  textNodes.forEach((node) => {
    if (prevNode && prevNode.nextSibling === node) {
      currentSpan.push(node);
    } else {
      currentSpan = [node];
      textNodeSpans.push(currentSpan);
    }
    prevNode = node;
  });

  const whitespace = /^\s*$/;
  textNodeSpans = textNodeSpans.filter((span) => span.some((node) => !whitespace.test(node.data)));

  const highlights: HTMLElement[] = [];
  textNodeSpans.forEach((nodes) => {
    const highlightEl = document.createElement('hypothesis-highlight');
    highlightEl.className = 'hypothesis-highlight';

    const text = nodes[0];
    if (!text) return;
    const parent = text.parentNode;
    if (!parent) return;
    parent.replaceChild(highlightEl, text);
    nodes.forEach((node) => highlightEl.appendChild(node));

    highlights.push(highlightEl);
  });

  const rects = highlights.map((node) => node.getBoundingClientRect());
  removeAllHighlights(range.commonAncestorContainer as HTMLElement);

  return rects;
}

function replaceWith(node: ChildNode, replacements: Node[]) {
  const parent = node.parentNode;
  if (!parent) return;
  replacements.forEach((r) => parent.insertBefore(r, node));
  node.remove();
}

export function removeAllHighlights(root: HTMLElement) {
  const highlights = Array.from(root.querySelectorAll('hypothesis-highlight'));
  removeHighlights(highlights);
}

function removeHighlights(highlights: Element[]) {
  for (const h of highlights) {
    if (h.parentNode) {
      const children = Array.from(h.childNodes);
      replaceWith(h, children);
    }
  }
}

export function getMinWrapperRects(rects: Rect[]) {
  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  rects.forEach((rect) => {
    minX = Math.min(minX, rect.x);
    minY = Math.min(minY, rect.y);
    maxX = Math.max(maxX, rect.x + rect.width);
    maxY = Math.max(maxY, rect.y + rect.height);
  });

  const width = maxX - minX;
  const height = maxY - minY;

  return {
    left: minX,
    top: minY,
    width,
    height,
    right: maxX,
    bottom: maxY,
  };
}
