import { formula } from '@flowus/formula';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { Placement, VirtualElement } from '@popperjs/core';
import { cloneDeep } from 'lodash-es';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfigTableFilter } from 'src/bitable/bitable-manager/config-table-filter';
import { useBitable } from 'src/bitable/context';
import { checkPropsChangeCauseNewFormLogic } from 'src/bitable/form-view/form-logic-utils';
import { useUpdateFormProperties } from 'src/bitable/form-view/use-update-form-properties';
import { useCreateFilter } from 'src/bitable/hooks/use-create-filter';
import { Input } from 'src/common/components/input';
import { ListView } from 'src/common/components/list-view';
import type { ListItem } from 'src/common/components/list-view/types';
import { ListItemType } from 'src/common/components/list-view/types';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { FeatureFlags } from 'src/feature-flags';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useCopyProperty } from 'src/hooks/block/use-copy-property';
import { useCreateProperty } from 'src/hooks/block/use-create-property';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { usePropertySchema } from 'src/hooks/block/use-property-schema';
import { useRemoveProperty } from 'src/hooks/block/use-remove-property';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { updatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { useUpdatePropertyVisible } from 'src/hooks/block/use-update-property-visible';
import { useCollectionView } from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { Modals } from 'src/modals';
import { getState } from 'src/redux/store';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { bizTracker } from 'src/utils/biz-tracker';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { ConfigTableSort } from '../../bitable-manager/config-table-sort';
import { PROPERTIES_FORM_MAP, PROPERTIES_MAP, TableClassName } from '../../const';
import { OptionRender } from '../cell/select/option-manager';
import { getRollupValueType, isPercentAggregation, isShowOriginalValue } from '../footer/helper';
import { PropertyManagePlace } from '../types';
import { SelectNumberFormat } from './number-format';
import { NumberShowStyle } from './number-show-style';
import { PropertyTypeList } from './property-type-list';
import { RollupSetting } from './rollup-setting';

interface PropertyWidgetProps {
  viewId: string;
  collectionId: string;
  propertyId: string;
  onClose(): void;
  closeCallbackRef: MutableRefObject<(() => void) | undefined>;
  from: PropertyManagePlace;
  popcorn: VirtualElement;
}

const OperationEnum = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
  VISIBLE_STATE: 'VISIBLE_STATE',
  DELETE: 'DELETE',
  COPY: 'COPY',
  INSERT_LEFT: 'INSERT_LEFT',
  INSERT_RIGHT: 'INSERT_RIGHT',
  OPTION_SELECT: 'OPTION_SELECT',
  NUMBER_SHOW_AS: 'NUMBER_SHOW_AS',
  NUMBER_FORMAT: 'NUMBER_FORMAT',
  FILTER: 'FILTER',
  FULL_URL: 'FULL_URL',
};

export const PropertyWidget: FC<PropertyWidgetProps> = ({
  viewId,
  collectionId,
  propertyId,
  closeCallbackRef,
  onClose,
  from,
  popcorn,
}) => {
  const { viewType, pageManager, readonly, managerReadonly } = useBitable();
  const collectionView = useCollectionView(viewId);
  const [properties] = useProperties(viewId, { visible: true, includeIdNumber: true });
  const freezeColumnIndex = useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return view?.format.tableFreezeColumnIndex ?? -1;
    },
    [viewId]
  );
  const propertyIndex = properties?.findIndex((v) => v.property === propertyId) ?? 0;
  const isColumnFreeze = freezeColumnIndex === propertyIndex;
  const updateFormProperties = useUpdateFormProperties(viewId, propertyId);
  const isTableView = viewType === CollectionViewType.TABLE;
  const isFormView = viewType === CollectionViewType.FORM;
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const isTimelineTable =
    isTimeline &&
    (from === PropertyManagePlace.TABLE_HEADER ||
      from === PropertyManagePlace.PROPERTY_LIST_TIMELINE_TABLE);

  const { propertySchema: schema, relationPropertySchema } = usePropertySchema(
    collectionId,
    propertyId
  );
  const colVisible = useObservableStore(
    (state) => {
      const { collectionViews, blocks } = state;
      if (from === PropertyManagePlace.PAGE_PROPERTIES) {
        const property = (blocks[collectionId]?.data.collectionPageProperties ?? []).find(
          ({ property }) => property === propertyId
        );
        return property?.visible ?? true;
      }

      const view = collectionViews[viewId];
      if (!view) return;
      const { properties, timelineTableProperties = [] } = getViewFormat(
        view,
        state.blocks,
        state.collectionViews
      );

      const property = (isTimelineTable ? timelineTableProperties : properties).find(
        ({ property }) => property === propertyId
      );
      return property?.visible;
    },
    [collectionId, propertyId, viewId, from, isTimelineTable]
  );

  const updateVisible = useUpdatePropertyVisible();
  const removeProperty = useRemoveProperty();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const createProperty = useCreateProperty();
  const copyProperty = useCopyProperty();
  const [inputItemName, setInputItemName] = useState(schema?.name ?? '');
  const [errorMessage, setErrorMessage] = useState('');
  const itemName = inputItemName.trim();
  const createFilter = useCreateFilter();

  const isTitle = schema?.type === CollectionSchemaType.TITLE;
  const isRelation = schema?.type === CollectionSchemaType.RELATION;
  const isRollup = schema?.type === CollectionSchemaType.ROLLUP;
  const isNumber = schema?.type === CollectionSchemaType.NUMBER;
  const isFormula = schema?.type === CollectionSchemaType.FORMULA;
  const isSelect =
    schema?.type === CollectionSchemaType.SELECT ||
    schema?.type === CollectionSchemaType.MULTI_SELECT;
  const isUrl = schema?.type === CollectionSchemaType.URL;
  const formulaType = useObservableStore(
    (state) => {
      if (isFormula) {
        return getFormulaTool(collectionId, state).getType(propertyId);
      }
    },
    [collectionId, propertyId]
  );

  const checkItemName = useCheckPropertyName();

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const input = inputRef.current;
    if (input) {
      input.setSelectionRange(0, input.value.length);
    }
  }, []);

  useEffect(() => {
    if (checkItemName(collectionId, inputItemName, propertyId)) {
      setErrorMessage('此多维表已经存在相同名称的属性，请修改');
    } else {
      setErrorMessage('');
    }
  }, [checkItemName, collectionId, inputItemName, propertyId]);

  const formViewItems = useMemo(() => {
    if (!isFormView) return [];

    const property = collectionView?.format.formProperties?.find((i) => i.property === propertyId);

    return [
      {
        type: ListItemType.TEXT_SWITCH,
        data: {
          switch: property?.required,
          title: '必填',
          onSwitch: (status: boolean) => {
            updateFormProperties('required', status);
          },
        },
      },
      {
        type: ListItemType.TEXT_SWITCH,
        data: {
          switch: property?.showDescription,
          title: '添加说明',
          onSwitch: (status: boolean) => {
            updateFormProperties('showDescription', status);
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: managerReadonly,
        data: {
          icon: colVisible ? 'IcMenuHide' : 'IcMenuShow',
          type: OperationEnum.VISIBLE_STATE,
          visible: colVisible,
          title: colVisible ? '隐藏' : '显示',
        },
      },
    ];
  }, [
    colVisible,
    collectionView?.format.formProperties,
    isFormView,
    managerReadonly,
    propertyId,
    updateFormProperties,
  ]);

  const items = useMemo(() => {
    if (!schema) return [];

    const items: ListItem<any>[] = [
      {
        type: ListItemType.LINE,
        data: {},
        isHidden: isTitle && from === PropertyManagePlace.PROPERTY_LIST,
        disableClick: true,
      },
      ...formViewItems,
      {
        type: ListItemType.OPERATION,
        isHidden: from !== PropertyManagePlace.TABLE_HEADER || readonly || managerReadonly,
        data: {
          icon: 'IcMenuInsertLeft',
          type: OperationEnum.INSERT_LEFT,
          title: '左边插入列',
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: from !== PropertyManagePlace.TABLE_HEADER || readonly || managerReadonly,
        data: {
          icon: 'IcMenuInsertRight',
          type: OperationEnum.INSERT_RIGHT,
          title: '右边插入列',
        },
      },
      {
        type: ListItemType.LINE,
        data: {},
        isHidden: from !== PropertyManagePlace.TABLE_HEADER || readonly || managerReadonly,
        disableClick: true,
      },
      {
        type: ListItemType.OPERATION,
        isHidden:
          [PropertyManagePlace.PAGE_PROPERTIES, PropertyManagePlace.FORM_PROPERTIES].includes(
            from
          ) || managerReadonly,
        data: {
          icon: 'IcMenuAscending',
          type: OperationEnum.ASCENDING,
          title: '升序',
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden:
          [PropertyManagePlace.PAGE_PROPERTIES, PropertyManagePlace.FORM_PROPERTIES].includes(
            from
          ) || managerReadonly,
        data: {
          icon: 'IcMenuDescending',
          type: OperationEnum.DESCENDING,
          title: '降序',
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden:
          [PropertyManagePlace.PAGE_PROPERTIES, PropertyManagePlace.FORM_PROPERTIES].includes(
            from
          ) || managerReadonly,
        data: {
          icon: 'IcFilter',
          type: OperationEnum.FILTER,
          title: '筛选',
        },
      },
      {
        type: ListItemType.LINE,
        data: {},
        isHidden:
          [PropertyManagePlace.PAGE_PROPERTIES, PropertyManagePlace.FORM_PROPERTIES].includes(
            from
          ) || managerReadonly,
        disableClick: true,
      },
      {
        type: ListItemType.OPERATION,
        isHidden: (isTitle && !isTimelineTable) || managerReadonly || isFormView,
        data: {
          icon: colVisible ? 'IcMenuHide' : 'IcMenuShow',
          type: OperationEnum.VISIBLE_STATE,
          visible: colVisible,
          title: colVisible ? '隐藏' : '显示',
        },
      },
      {
        type: ListItemType.OPERATION,
        // 只有tableview的header可以冻结
        isHidden:
          !FeatureFlags.FREEZE_TABLE_COLUMN ||
          !(isTableView && from === PropertyManagePlace.TABLE_HEADER) ||
          readonly ||
          managerReadonly,
        data: {
          switch: isColumnFreeze,
          icon: isColumnFreeze ? 'IcUnfreezecolumns' : 'IcFreezecolumns',
          visible: colVisible,
          title: isColumnFreeze ? '清除冻结列' : '冻结至该列',
          onClick: () => {
            closeModal(Modals.BITABLE_PROPERTY_WIDGET);
            updateViewFormat(viewId, {
              // 如果之前是冻结，测需要设置为-1表示解除冻结
              tableFreezeColumnIndex: isColumnFreeze ? -1 : propertyIndex,
            });
          },
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isTitle || readonly || managerReadonly || isRelation,
        data: {
          icon: 'IcDuplicate',
          type: OperationEnum.COPY,
          title: '拷贝',
        },
      },
      {
        type: ListItemType.OPERATION,
        isHidden: isTitle || readonly || managerReadonly,
        data: {
          icon: 'IcTrash',
          type: OperationEnum.DELETE,
          title: '删除',
        },
      },
    ];

    if (isTitle) {
      items.unshift({
        type: ListItemType.OPERATION,
        data: {
          icon: 'IcTitle',
          type: CollectionSchemaType.TITLE,
          title: '标题',
        },
      });
    } else {
      if (isNumber) {
        items.unshift(
          {
            type: ListItemType.OPERATION,
            data: {
              icon: 'IcMenuNum',
              title: '格式',
              type: OperationEnum.NUMBER_FORMAT,
            },
          },
          {
            type: ListItemType.OPERATION,
            data: {
              icon: 'MIcRing',
              title: '显示样式',
              type: OperationEnum.NUMBER_SHOW_AS,
            },
          }
        );
      }

      if (isFormula && formulaType === formula.ValueTool.numberType) {
        items.unshift({
          type: ListItemType.OPERATION,
          data: {
            icon: 'MIcRing',
            title: '显示样式',
            type: OperationEnum.NUMBER_SHOW_AS,
          },
        });
      }

      if (isRollup) {
        const isShowStyleSetting =
          isPercentAggregation(schema.aggregation) ||
          getRollupValueType(schema?.aggregation) === 'number' ||
          (isShowOriginalValue() &&
            (schema?.targetPropertyType === CollectionSchemaType.NUMBER ||
              (schema?.targetPropertyType === CollectionSchemaType.FORMULA &&
                getFormulaTool(relationPropertySchema?.collectionId ?? '').getType(
                  schema?.targetProperty ?? ''
                ) === formula.ValueTool.numberType)));

        items.unshift({
          type: ListItemType.OPERATION,
          isHidden: !isShowStyleSetting,
          data: {
            icon: 'MIcRing',
            title: '显示样式',
            type: OperationEnum.NUMBER_SHOW_AS,
          },
        });

        items.unshift({
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcTitleRollup',
            title: '汇总设置',
            hasArrow: !readonly,
            renderSubMenu: () => {
              if (readonly) return null;
              return <RollupSetting collectionId={collectionId} propertyId={propertyId} />;
            },
          },
        });
      }

      if (isSelect) {
        items.unshift({
          type: ListItemType.OPERATION,
          data: {
            icon: 'IcMenuSettings',
            type: OperationEnum.OPTION_SELECT,
            title: '管理选项',
          },
        });
      } else if (isUrl) {
        items.unshift({
          type: ListItemType.TEXT_SWITCH,
          data: {
            switch: !!schema?.fullUrl,
            icon: 'IcLink',
            iconSize: 'small',
            type: OperationEnum.FULL_URL,
            title: '显示完整URL',
            onSwitch: (status: boolean) => {
              updatePropertySchema(collectionId, propertyId, {
                fullUrl: status,
              });
            },
          },
        });
      }

      const propertiesMap = isFormView ? PROPERTIES_FORM_MAP : PROPERTIES_MAP;
      items.unshift({
        type: ListItemType.OPERATION,
        data: {
          ...propertiesMap[schema.type],
          hasArrow: !readonly,
          renderSubMenu: () => {
            if (readonly) return null;

            return (
              <PropertyTypeList
                viewId={viewId}
                collectionId={collectionId}
                propertyId={propertyId}
                propertyType={schema.type}
                isFormView={isFormView}
                popcorn={popcorn}
              />
            );
          },
        },
      });
    }

    return items;
  }, [
    schema,
    isTitle,
    from,
    formViewItems,
    readonly,
    managerReadonly,
    isTimelineTable,
    isFormView,
    colVisible,
    isTableView,
    isColumnFreeze,
    isRelation,
    closeModal,
    viewId,
    propertyIndex,
    isNumber,
    isFormula,
    formulaType,
    isRollup,
    isSelect,
    isUrl,
    relationPropertySchema?.collectionId,
    collectionId,
    propertyId,
    popcorn,
  ]);

  closeCallbackRef.current = useCallback(() => {
    if (errorMessage) return 'prevent';
    if (itemName && itemName !== schema?.name) {
      updatePropertySchema(collectionId, propertyId, { name: itemName });
    }
  }, [collectionId, errorMessage, propertyId, itemName, schema?.name]);

  const handleItemClick = useCallback(
    async (res: ListItem<any>) => {
      switch (res.data.type) {
        case OperationEnum.ASCENDING:
        case OperationEnum.DESCENDING: {
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);

          const view = getState().collectionViews?.[viewId];
          if (!view) return;
          const newSorters = cloneDeep(view.format.sorters) ?? [];

          newSorters.forEach((sorter) => {
            sorter.disable = false;
          });
          const sorter = newSorters.find((it) => it.property === propertyId);
          const direction = res.data.type === OperationEnum.ASCENDING ? 'asc' : 'desc';
          if (!sorter) {
            newSorters.push({ property: propertyId, direction, disable: false });
          } else {
            sorter.direction = direction;
          }
          updateViewFormat(viewId, { sorters: newSorters });

          if (pageManager?.current) {
            const sortManagerNode = pageManager.current.querySelector(
              `.${TableClassName.tableSortManager}`
            );

            const computePos = () => {
              const rect = pageManager.current?.getBoundingClientRect() as DOMRect;
              return {
                getBoundingClientRect: () => ({
                  ...rect,
                  x: rect.x + rect.width / 2,
                  y: rect.y,
                  left: rect.left + rect.width / 2,
                  top: rect.top,
                  width: rect.width / 2,
                  height: rect.height,
                }),
              };
            };

            openModal.dropdown({
              popcorn: sortManagerNode
                ? elementToGetBoundingClientRect(sortManagerNode)
                : computePos(),
              placement: 'bottom',
              modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
              content() {
                return <ConfigTableSort />;
              },
            });
          }

          break;
        }
        case OperationEnum.FILTER: {
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          createFilter(
            {
              collectionId,
              viewId,
            },
            propertyId,
            managerReadonly
          );
          if (pageManager?.current) {
            const sortManagerNode = pageManager.current.querySelector(
              `.${TableClassName.tableSortManager}`
            );

            const computePos = () => {
              const rect = pageManager.current?.getBoundingClientRect() as DOMRect;
              return {
                getBoundingClientRect: () => ({
                  ...rect,
                  x: rect.x + rect.width / 2,
                  y: rect.y,
                  left: rect.left + rect.width / 2,
                  top: rect.top,
                  width: rect.width / 2,
                  height: rect.height,
                }),
              };
            };

            openModal.dropdown({
              popcorn: sortManagerNode
                ? elementToGetBoundingClientRect(sortManagerNode)
                : computePos(),
              placement: 'bottom',
              modifiers: [{ name: 'preventOverflow', options: { padding: 16 } }],
              content() {
                return <ConfigTableFilter />;
              },
            });
          }
          break;
        }
        case OperationEnum.INSERT_LEFT:
        case OperationEnum.INSERT_RIGHT: {
          bizTracker.event('bitable_property_create', { from_scene: 'view' });

          createProperty({
            collectionId,
            viewId,
            position: {
              property: propertyId,
              left: res.data.type === OperationEnum.INSERT_LEFT,
            },
            visible: true,
            isTimelineTable,
          });
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          break;
        }

        case OperationEnum.COPY: {
          bizTracker.event('bitable_property_create', { from_scene: 'duplicate' });

          copyProperty(collectionId, viewId, propertyId, isTimelineTable);
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          break;
        }

        case OperationEnum.VISIBLE_STATE: {
          const newVisible = !res.data.visible;
          if (viewType === CollectionViewType.FORM && !newVisible) {
            const view = getState().collectionViews[viewId];
            const oldProps = view?.format.formProperties ?? [];
            const formLogic = view?.format.formLogic ?? [];
            const newFormLogic = checkPropsChangeCauseNewFormLogic(
              collectionId,
              viewId,
              oldProps
                .filter((it) => (it.property === propertyId ? newVisible : it.visible))
                .map((it) => it.property),
              formLogic
            );
            if (newFormLogic != null) {
              const confirmed = await new Promise((resolve) => {
                openModal.warning({
                  title: <>确认隐藏题目吗？</>,
                  content: <>会导致部分跳题逻辑失效，将自动删除失效逻辑。</>,
                  confirm() {
                    resolve(true);
                  },
                  cancel() {
                    resolve(false);
                  },
                });
              });
              if (!confirmed) {
                return;
              }
              updateViewFormat(viewId, { formLogic });
            }
          }
          updateVisible(collectionId, viewId, propertyId, newVisible, isTimelineTable);
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          break;
        }

        case OperationEnum.DELETE: {
          openModal.warning({
            title: <>是否确认删除该属性？</>,
            confirm: () => {
              closeModal(Modals.BITABLE_PROPERTY_WIDGET, {
                closeAfterCallBack: () => {
                  removeProperty(collectionId, propertyId);
                },
              });
            },
          });
          break;
        }

        case OperationEnum.OPTION_SELECT: {
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          openModal.dropdown({
            popcorn,
            placement: 'bottom',
            content: ({ onCloseModal }) => {
              if (readonly) return null;

              return (
                <OptionRender
                  viewId={viewId}
                  collectionId={collectionId}
                  propertyId={propertyId}
                  onClose={onCloseModal}
                />
              );
            },
          });
          break;
        }

        case OperationEnum.NUMBER_SHOW_AS: {
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          openModal.dropdown({
            popcorn,
            placement: 'bottom',
            content: ({ onCloseModal }) => {
              if (readonly) return null;

              return <NumberShowStyle propertyId={propertyId} onCloseModal={onCloseModal} />;
            },
          });
          break;
        }

        case OperationEnum.NUMBER_FORMAT: {
          closeModal(Modals.BITABLE_PROPERTY_WIDGET);
          openModal.dropdown({
            popcorn,
            placement: 'bottom',
            content: ({ onCloseModal }) => {
              if (readonly) return null;

              return <SelectNumberFormat propertyId={propertyId} closeModal={onCloseModal} />;
            },
          });
          break;
        }

        default:
          break;
      }
    },
    [
      closeModal,
      viewId,
      pageManager,
      propertyId,
      openModal,
      createFilter,
      collectionId,
      managerReadonly,
      createProperty,
      isTimelineTable,
      copyProperty,
      viewType,
      updateVisible,
      removeProperty,
      popcorn,
      readonly,
    ]
  );

  return (
    <div className="flex flex-col w-[240px] next-modal-scroll">
      {!readonly && (
        <div className="flex flex-row items-center w-full">
          <Input
            inputRef={inputRef}
            autoFocus
            placeholder="请输入名称"
            value={inputItemName}
            className="h-8 mx-2.5 mt-2.5 flex-1"
            onEnter={() => {
              if (errorMessage) return;
              onClose();
            }}
            onChange={setInputItemName}
          />
        </div>
      )}
      {errorMessage && <div className="mx-4 mb-2 text-t4 text-red">{errorMessage}</div>}

      <div className="flex flex-col mb-[5px]">
        <div className="text-t2 text-grey3 mb-[4px] ml-4 mt-2.5">属性类型</div>
        <ListView onItemClick={handleItemClick} items={items} />
      </div>
    </div>
  );
};

export const useOpenPropertyWidget = () => {
  const { viewId, collectionId } = useBitable();
  const openModal = useOpenModal();
  const closeCallbackRef = useRef<() => void>();

  return useCallback(
    (
      propertyId: string,
      popcorn: VirtualElement,
      from = PropertyManagePlace.TABLE_HEADER,
      placement?: Placement,
      closeBeforeCallBack?: () => void
    ) => {
      openModal.dropdown({
        popcorn,
        closeBeforeCallBack: () => {
          closeBeforeCallBack?.();
          closeCallbackRef.current?.();
        },
        modalId: Modals.BITABLE_PROPERTY_WIDGET,
        placement: placement || 'bottom',
        content: ({ onCloseModal }) => {
          return (
            <PropertyWidget
              viewId={viewId}
              collectionId={collectionId}
              closeCallbackRef={closeCallbackRef}
              propertyId={propertyId}
              onClose={onCloseModal}
              popcorn={popcorn}
              from={from}
            />
          );
        },
      });
    },
    [collectionId, openModal, viewId]
  );
};

/**
 * 判断 name 是否可以添加到表内
 */
const useCheckPropertyName = () => {
  const checkPropertyName = (collectionId: string, name: string, propertyId: string) => {
    const collection = getState().blocks[collectionId];
    if (!collection?.data.schema) return false;
    const cols = Object.keys(collection.data.schema).map((property: string) => {
      return {
        ...(collection.data.schema?.[property] ?? {}),
        property,
      };
    });
    return cols.some((item) => propertyId !== item.property && item.name === name);
  };

  return useCallback(checkPropertyName, []);
};
