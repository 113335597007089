import type { IContentItem, IMarker, ISelection } from '@next-space/fe-inlined';
import { findEditorElement, readSelectionFromDOM } from '@next-space/fe-inlined/dom-utils';
import {
  findContainerItemPath,
  findItemPathInRange,
  getElementByPath,
  getItemLength,
} from '@next-space/fe-inlined/utils';
import { css } from 'otion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BehaviorSubject, debounceTime, filter, first, map, merge, of, skip, throttle } from 'rxjs';
import { fastEqual } from '@flowus/common/utils/tools';
import {
  CODE_TAG,
  INLINE_DATE_TAG,
  INLINE_EQUATION_TAG,
  INLINE_LINK_PAGE_TAG,
  INLINE_MENTION_BLOCK_TAG,
  INLINE_PERSON_TAG,
  LINK_TAG,
} from 'src/editor/editor/inline/const';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { useGetRefValue } from '@flowus/common/hooks/react-utils';

const defaultQueryResult = {
  getRect: null,
  disableLink: false,
  disableEquation: false,
  inLink: null,
  inCode: false,
  inEquation: false,
  disableAI: false,
};

export const useShortcutToolBar = (uuid: string) => {
  const syncId = useSyncId();
  const [queryResult, setQueryResult] = useState<{
    getRect: (() => DOMRect) | null;
    disableLink: boolean;
    disableEquation: boolean;
    inLink: string | null;
    inCode: boolean;
    inEquation: boolean;
    disableAI: boolean;
  }>(defaultQueryResult);
  const getEditorModel = useGetOrInitEditorModel();
  const fakeFocusRef = useRef(null);
  const $fakeFocus = useGetRefValue(fakeFocusRef, () => new BehaviorSubject(false));
  const model = getEditorModel(uuid);
  const markerRef = useRef<IMarker | null>(null);

  useEffect(() => {
    if (!model) return;
    const getSelection = () => {
      try {
        if (model.readonly) {
          // NOTE: fe-inlined里inactive的editor还保留着原来的selection，不是我们想要的结果
          // 这里hack一下
          const result = readSelectionFromDOM();
          if (result == null || result[0] !== model.editorKey) return null;
          return model.selection;
        }
        return model.active || $fakeFocus.value ? model.selection : null;
      } catch {
        return null;
      }
    };

    const setStream = merge(
      model.onContentChange,
      model.onActiveChange,
      $fakeFocus.pipe(skip(1)),
      model.onSelectionChange
    ).pipe(
      debounceTime(100),
      throttle(() => (model.isContentRendered ? of(0) : model.onContentRendered.pipe(first()))),
      // 到这里的时候可能Editor已经销毁，先过滤一下
      filter(() => !!findEditorElement(model.editorKey)),
      map(getSelection)
    );

    const query = (selection: ISelection | null) => {
      if (selection === null || selection.isCollapsed) {
        const { $toolbarInfo } = $appUiStateCache;
        if (
          syncId &&
          $toolbarInfo &&
          $toolbarInfo.showing &&
          $toolbarInfo.syncId === syncId &&
          $toolbarInfo.uuid === uuid
        ) {
          setAppUiState({ $toolbarInfo: { syncId, showing: false, uuid } });
        }
        return {
          getRect: null,
          disableLink: false,
          inLink: null,
          inCode: false,
          inEquation: false,
          disableEquation: false,
          disableAI: false,
        };
      }
      const linkPath = findItemPathInRange(
        model.content,
        selection.offset,
        selection.endOffset,
        (item, offset) => {
          if (item.type === 'element' && item.tag === LINK_TAG) {
            if (selection.isCollapsed) {
              return true;
            }
            const endOffset = offset + getItemLength(item);
            return selection.endOffset > offset && selection.offset < endOffset;
          }
          return false;
        }
      );
      const inlinePage = findItemPathInRange(
        model.content,
        selection.offset,
        selection.endOffset,
        (item, offset) => {
          if (item.type === 'element' && item.tag === INLINE_LINK_PAGE_TAG) {
            if (selection.isCollapsed) {
              return true;
            }
            const endOffset = offset + getItemLength(item);
            return selection.endOffset > offset && selection.offset < endOffset;
          }
          return false;
        }
      );
      // 当有可点击元素在选区内时，不允许加超链接
      const isClickableElement = (item: IContentItem) => {
        if (
          item.type === 'element' &&
          [
            INLINE_LINK_PAGE_TAG,
            INLINE_MENTION_BLOCK_TAG,
            INLINE_DATE_TAG,
            INLINE_PERSON_TAG,
          ].includes(item.tag)
        ) {
          return true;
        }
        return false;
      };
      const hasAnyClickableElementInRange =
        findItemPathInRange(
          model.content,
          selection.offset,
          selection.endOffset,
          (item, offset) => {
            if (isClickableElement(item)) {
              if (selection.isCollapsed) {
                return true;
              }
              const endOffset = offset + getItemLength(item);
              return selection.endOffset > offset && selection.offset < endOffset;
            }
            return false;
          }
        ).length > 0;

      const codePath = findContainerItemPath(
        model.content,
        selection.offset,
        selection.endOffset,
        (item) => item.type === 'element' && item.tag === CODE_TAG
      );

      const equationPath = findItemPathInRange(
        model.content,
        selection.offset + 1,
        selection.endOffset,
        (item, offset) => {
          if (item.type === 'element' && item.tag === INLINE_EQUATION_TAG) {
            const endOffset = offset + getItemLength(item);
            return selection.endOffset > offset && selection.offset < endOffset;
          }
          return false;
        }
      );

      return {
        getRect: () =>
          model.getBoundingClientRectOfRange(selection.offset, selection.endOffset) ??
          new DOMRect(-9999, -9999, 0, 0),
        disableLink: hasAnyClickableElementInRange || equationPath.length > 0,
        inLink:
          linkPath.length > 0
            ? (getElementByPath(model.content, linkPath)?.props.url as string)
            : null,
        inCode: codePath.length > 0,
        inEquation: equationPath.length > 0,
        disableEquation: hasAnyClickableElementInRange,
        disableAI: inlinePage.length > 0,
      };
    };

    let lastQueryGetRect: any = null;
    const subscription = setStream.subscribe(() => {
      const queryRet = query(getSelection());
      setQueryResult((pre) => {
        if (fastEqual(pre, queryRet)) {
          return pre;
        }
        return queryRet;
      });
      if (lastQueryGetRect === null && queryRet.getRect) {
        // 菜单弹出时
        if (syncId) {
          setAppUiState({ $toolbarInfo: { syncId, showing: true, uuid } });
        }
      }
      lastQueryGetRect = queryRet.getRect;
    });

    const selection = getSelection();
    if (selection === null) {
      setQueryResult(query(null));
      lastQueryGetRect = null;
    }

    return () => {
      subscription.unsubscribe();
      $fakeFocus.next(false);
    };
  }, [model, $fakeFocus, setQueryResult, syncId, uuid]);

  useEffect(() => {
    if (!model) return;
    const subscription = merge(model.onActiveChange, $fakeFocus.pipe(skip(1))).subscribe(() => {
      if (markerRef.current === null) {
        markerRef.current = model.mark(0, 0, {
          className: css({
            backgroundColor: 'var(--selection)',
          }),
        });
      }
      const marker = markerRef.current;
      if (!model.active && $fakeFocus.value) {
        marker.updateRange(model.selection?.offset ?? 0, model.selection?.endOffset ?? 0);
      } else {
        marker.updateRange(0, 0);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [model, $fakeFocus]);

  const setFakeFocus = useCallback(
    (fakeFocus: boolean) => {
      $fakeFocus.next(fakeFocus);
    },
    [$fakeFocus]
  );

  return { queryResult, $fakeFocus, setFakeFocus };
};

export type QueryResultType = ReturnType<typeof useShortcutToolBar>['queryResult'];
export type ShortcutToolBarType = ReturnType<typeof useShortcutToolBar>;
