import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { useConvertCollectionView } from 'src/hooks/block/use-convert-collection-view';
import { updateCollectionView } from 'src/hooks/block/use-update-collection-view';
import type { NextCollectionView } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { useBitable } from '../../context';
import { ViewIconMap, ViewNameMap } from '../const';
import { ViewsItemManager } from './view-item';

export const ViewButton: FC<{ uuid: string }> = ({ uuid }) => {
  const { viewId, changeView, managerReadonly, isLocked } = useBitable();
  const view = useObservableStore(({ collectionViews }) => collectionViews[uuid], [uuid]);

  const convertCollectionView = useConvertCollectionView();
  const openModal = useOpenModal();

  const viewTitleRef = useRef(view?.title ?? '');
  useEffect(() => {
    viewTitleRef.current = view?.title ?? '';
  }, [view?.title]);

  const showMorePanel = (popcorn: HTMLElement, view: NextCollectionView) => {
    if (managerReadonly || isLocked) return;

    openModal.dropdown({
      popcorn,
      placement: 'bottom',
      closeBeforeCallBack() {
        const newTitle = viewTitleRef.current;
        if (newTitle !== view.title) {
          updateCollectionView(uuid, { title: newTitle });
        }
      },
      content({ onCloseModal }) {
        return (
          <ViewsItemManager
            viewId={uuid}
            onCloseModal={onCloseModal}
            onClose={() => {}}
            viewTitleRef={viewTitleRef}
          />
        );
      },
    });
  };

  if (!view) return null;
  const isSelected = viewId === uuid;
  return (
    <div
      data-disable-select
      className={cx(
        'flex items-center mr-3 text-grey3 h-full',
        isSelected && 'border-b-2 border-grey1 text-grey1 pt-0.5'
      )}
    >
      <button
        className={'flex items-center p-1 text-t2-medium animate-hover'}
        onContextMenu={(event) => {
          event.preventDefault();
          event.stopPropagation();
          showMorePanel(event.currentTarget, view);
        }}
        onClick={(event) => {
          if (isSelected) {
            showMorePanel(event.currentTarget, view);
          } else {
            changeView(uuid);
          }
        }}
      >
        <Icon className="mr-1" name={ViewIconMap[view.type]} size="middle" />
        <span className="max-w-[150px] text-ellipsis">{view.title || ViewNameMap[view.type]}</span>
      </button>
    </div>
  );
};
