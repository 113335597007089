import type { AIEditType } from './const';
import { isAiMindMap } from './const';

/** 目前仅生成思维导图类型需要修复一下prompt */
export const fixPrompt = (prompt: string, aiEditType: AIEditType) => {
  if (isAiMindMap.has(aiEditType)) {
    return __BUILD_IN__
      ? `${prompt},Return the data in outline form, the first line needs a title, remove non-outline content, no need for a conclusion at the end, return in the format of """# "$\{HEADING1}"\n ## "$\{HEADING2}"\n ### "$\{HEADING3}"\n #### "$\{HEADING4}"\n """  `
      : `${prompt},以大纲形式返回数据，第一行需要有标题，去掉非大纲的内容，结尾不需要总结，返回的格式为 """# "$\{标题}"\n ## "$\{二级标题}"\n ### "$\{三级标题}"\n #### "$\{四级标题}"\n """  `;
  }
  return prompt;
};
