import { Icon } from 'src/common/components/icon';

/**
 * 旋转加载菊花component
 */
export const InfiniteProgress = () => {
  return (
    <div className="flex items-center animate-spin">
      <Icon
        name="IcNaviLoading"
        size="middle"
        style={{
          fill: 'white',
        }}
      />
    </div>
  );
};
