import type { FC, PropsWithChildren } from 'react';

export interface TabPaneProps {
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  dataTestId?: string;
}
export const TabPane: FC<PropsWithChildren<TabPaneProps>> = ({ children }) => {
  return <>{children}</>;
};
