export const toggleBodyScrollbar = {
  hidden: () => {
    document.body.setAttribute('style', 'pointer-events:none;');
    document.body.classList.add('hiddenScroll');
  },
  show: () => {
    document.body.removeAttribute('style');
    document.body.classList.remove('hiddenScroll');
  },
};

export const isSupportOverlay = () => {
  return 'CSS' in window && CSS.supports('overflow', 'overlay');
};

/** 获取当前滚动条是否一直处于打开状态 */
export const getScrollbarWidth = (overflow = 'scroll') => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.width = '100px';
  outer.style.overflow = overflow;
  document.body.append(outer);
  const { offsetWidth, clientWidth } = outer;
  outer.remove();
  return offsetWidth - clientWidth;
};

/**
 * 增强滚动条∏
 */
export const enhanceScrollbar = () => {
  if (getScrollbarWidth() !== 0) {
    // 浏览器兼容性不好
    // if (isSupportOverlay() && getScrollbarWidth('overlay') === 0) {
    //   document.documentElement.classList.add('next-scrollbar-overlay');
    // } else {
    document.documentElement.classList.add('next-scrollbar');
    // }
  }
};
