//copy from react-redux

function is(x: unknown, y: unknown) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }
  // eslint-disable-next-line no-self-compare
  return x !== x && y !== y;
}

export default function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true;

  if (typeof objA !== 'object' || objA === null || typeof objB !== 'object' || objB === null) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < keysA.length; i++) {
    if (
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]!) ||
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      !is(objA[keysA[i]!], objB[keysA[i]!])
    ) {
      return false;
    }
  }

  return true;
}
