import { assign } from 'lodash-es';
import { useCallback } from 'react';
import { request } from 'src/common/request';
import { capacityDefaultValue, setSpaceCapacity } from 'src/services/capacity';
import { getCurrentSpaceId } from '../space/get-space';

/** 获取空间当前已使用空间容量和允许使用的最大容量 */
export const useFetchSpaceCapacity = () => {
  return useCallback(fetchSpaceCapacity, []);
};

export const fetchSpaceCapacity = async (spaceId = getCurrentSpaceId()) => {
  const res = await request.editor.capacityBlock(spaceId);
  setSpaceCapacity(spaceId, assign(capacityDefaultValue, res));
  return res;
};
