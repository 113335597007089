import { CollectionViewType } from '@next-space/fe-api-idl';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

export const useEnableBoardColor = (viewId: string) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];
      return (
        view?.type === CollectionViewType.BOARD &&
        view.format.boardGroupBy?.enableBoardColorColumns !== false
      );
    },
    [viewId]
  );
};

export const useHiddenEmptyColumns = (viewId: string, isSubGroup = false) => {
  return useObservableStore(
    ({ collectionViews }) => {
      const view = collectionViews[viewId];

      if (view?.type === CollectionViewType.BOARD && !isSubGroup) {
        return view.format.boardGroupBy?.hideEmptyGroups === true;
      }

      return view?.format.collectionGroupBy?.hideEmptyGroups === true;
    },
    [viewId, isSubGroup]
  );
};
