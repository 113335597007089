import { useCallback } from 'react';
import { archiveBlock } from 'src/redux/managers/block/archive';

import { useTransaction } from '../use-transaction';

export const useDeleteTemplateRecord = () => {
  const transaction = useTransaction();
  return useCallback(
    (templatePageId: string) => {
      return transaction(() => {
        archiveBlock([templatePageId]);
      });
    },
    [transaction]
  );
};
