import { isElectron, isMacOs } from 'react-device-detect';
import { lazy } from 'react';
import SparkMd5 from 'spark-md5';
import { v4 as uuid } from 'uuid';
import { loginInjector } from './init';
import type { Argument } from 'classnames';

export const cx = (...args: Argument[]) => {
  return loginInjector.cx(args);
};

export const USER_ID = 'userId';
export const WECHAT_CODE = 'code';
export const WECHAT_STATE = 'state';
export const WEAPP_CODE = 'wc';
export const AUTH_PATH = '/auth-callback';
export const PAY_ACCESS = '/pay-access';

/**判断是否是在微信登录所在iframe重定向到login页面 */
export const getWechatCallbackParams = (search: string) => {
  const params = new URLSearchParams(search);
  const code = params.get(WECHAT_CODE);
  const state = params.get(WECHAT_STATE);
  if (code && state) {
    return { code, state };
  }
};

export const md5Sum = (value: string, salt = 'flowus', upperCase = true) => {
  const result = SparkMd5.hash(value + salt);
  return upperCase ? result.toUpperCase() : result;
};

export const Regex = {
  Email:
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/,
  Mobile: /^1[3456789]\d{9}$/,
  Password:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+-~|\\}\\{\\"\\:\\?\\>\\<,.;'\\[\]]{6,16}$/,
  VerifyCode: /^[0-9]{6}$/,
};
/**用于微信登录iframe通信校验 */
export const randomCode = uuid();

export function lazyLoad<T extends () => Promise<any>>(loader: T) {
  type Mod = T extends () => Promise<infer M> ? M : unknown;
  return new Proxy({} as Mod, {
    get(_, key) {
      return lazy(() => {
        return loader().then((mod) => ({ default: mod[key] }));
      });
    },
  });
}
export const sleep = (durationInMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, durationInMs);
  });
};

export const ScoreRuleUrl = 'https://flowus.cn/share/c7e03855-8742-4453-abea-ab0625209955';

export const isInWechat = () => navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
export const isInWeapp = () => (window as any).__wxjs_environment === 'miniprogram';
export const isInAlipay = () => navigator.userAgent.toLowerCase().indexOf('alipayclient') !== -1;

/**易顿验证码 */
export const yidunCaptchaId = '9118367871f44629ab8eb16d6e274280';
/**临时记录一下，用于切换页面时保留输入的账号 */
export const tmpAccount = 'fl_account';

export const setTmpAccount = (account: string) => {
  sessionStorage.setItem(tmpAccount, account);
};

export const clearTmpAccount = () => {
  sessionStorage.setItem(tmpAccount, '');
};

export const getBizTrackerPlatform = () => {
  if (!isElectron) {
    return {
      x_device_type: 'web',
      x_platform: 'web',
      platform: 'web',
    };
  }

  return {
    x_device_type: 'desktop',
    x_platform: isMacOs ? 'mac' : 'windows',
    platform: isMacOs ? 'mac' : 'windows',
  };
};

/** 加密电话 */
export const encryptionPhone = (phone = '') => {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
};

export const GoogleClientId =
  '791725156680-k9ltfk4l1d91b5sieoj3m6vj7hv2igdq.apps.googleusercontent.com';
