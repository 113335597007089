import { Compare } from '@flowus/common';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { TextType } from '@next-space/fe-api-idl';
import { getUserName } from 'src/hooks/user/use-remark-name';
import type { BiUserResolver } from '../utils/resolvers';
import { BiValueType } from './_abstract';

export class BiUserType extends BiValueType<string> {
  constructor(private resolver: BiUserResolver) {
    super();
  }

  fromSegments(segments: SegmentDTO[] | undefined): string | undefined {
    return segments?.find((it) => it.type === TextType.USER)?.uuid;
  }

  toSegments(value: string): SegmentDTO[] | undefined {
    return [
      {
        type: TextType.USER,
        text: '@',
        enhancer: {},
        uuid: value,
      },
    ];
  }

  isNonNullEmpty(value: string): boolean {
    return this.resolver.findUser(value) === undefined;
  }

  compare(value1: string, value2: string): number {
    const nickname1 = getUserName(value1);
    const nickname2 = getUserName(value2);
    return Compare.pinyin(nickname1, nickname2);
  }
}
