import type { BlockDTO, CollectionSchema, DocInfoDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import { readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { segmentsToText } from 'src/editor/utils/editor';
import { checkPageSort } from 'src/hooks/block/use-insert-record';
import { request } from '../request';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';

export interface DataHandler<R> {
  convertToTargets: (
    items: BlockDTO[],
    collection: BlockDTO,
    propertyMapping: PropertyMapping
  ) => R[];
  onError?: (error: { code: number; msg: string }) => void;
  getViewId?: (views?: string[]) => string;
  onDataUpdate?: (data: DocInfoDTO) => void;
  filter?: (item: BlockDTO, collection: BlockDTO, propertyMapping: PropertyMapping) => boolean;
}

export const fetchCollection = async <R>(id: string, handler: DataHandler<R>) => {
  const res = await request.editor.getDoc.raw(id);
  if (res.code !== 200) {
    handler.onError?.(res);
    return;
  }
  const collection = res.data.blocks[id];
  if (!collection || !collection.data.schema) {
    handler.onError?.({ code: -1, msg: 'collection not found' });
    return;
  }
  const viewId = handler.getViewId?.(collection.views) ?? collection.views?.[0];
  if (!viewId) {
    handler.onError?.({ code: -1, msg: 'viewId not found' });
    return;
  }
  handler.onDataUpdate?.(res.data);
  let recordBlocks = getRecordBlocks(res.data, id, viewId);
  const propertyMapping = getPropertyMapping(collection);
  if (handler.filter) {
    const { filter } = handler;
    recordBlocks = recordBlocks.filter((item) => {
      return filter(item, collection, propertyMapping);
    });
  }
  const targets: R[] = handler.convertToTargets(recordBlocks, collection, propertyMapping);
  return targets;
};

export function getRecordBlocks<R extends Awaited<ReturnType<typeof request.editor.getDoc>>>(
  res: R,
  collectionId: string,
  viewId: string
) {
  const view = res.collectionViews?.[viewId];
  const collection = res.blocks[collectionId];
  if (!view || !collection) return [];

  const recordBlocks: BlockDTO[] = [];
  const { newPageSort } = checkPageSort(view.pageSort, collection.subNodes);
  for (const pageId of newPageSort) {
    const block = res.blocks[pageId];
    if (block) recordBlocks.push(block);
  }
  return recordBlocks;
}
type PropertyMapping = Record<string, { id: string; schema: CollectionSchema }>;

// 多个文件
export const getPropertyValueWithFiles = (
  blockData: BlockDTO,
  propertyMapping: PropertyMapping,
  propertyName: string
) => {
  const property = propertyMapping[propertyName];
  const id = property?.id;
  if (!id) return [];

  switch (property.schema.type) {
    case CollectionSchemaType.FILE: {
      const segments = blockData.data.collectionProperties?.[id];
      return (
        segments
          ?.filter((i) => i.fileStorageType === 'internal' && i.type === TextType.URL)
          .map((s) => s.url ?? '') ?? []
      );
    }
    default:
      return [];
  }
};

export const getPropertyValue = (
  blockData: BlockDTO,
  propertyMapping: PropertyMapping,
  propertyName: string
) => {
  const property = propertyMapping[propertyName];
  const id = property?.id;
  if (!id) return '';

  switch (property.schema.type) {
    case CollectionSchemaType.TITLE:
      return segmentsToText(blockData.data.segments);

    case CollectionSchemaType.FILE:
    case CollectionSchemaType.URL: {
      const segments = blockData.data.collectionProperties?.[id];
      return readUrlFromSegments(segments);
    }
    case CollectionSchemaType.CHECKBOX: {
      return formatCheckBoxValue(segmentsToText(blockData.data.collectionProperties?.[id]))
        ? 'YES'
        : 'NO';
    }

    default:
      return segmentsToText(blockData.data.collectionProperties?.[id]);
  }
};
export const getPropertySegments = (
  blockData: BlockDTO,
  propertyMapping: PropertyMapping,
  propertyName: string
) => {
  const property = propertyMapping[propertyName];
  const id = property?.id;
  if (!id) return [];

  switch (property.schema.type) {
    case CollectionSchemaType.TITLE:
      return blockData.data.segments;
    default:
      return blockData.data.collectionProperties?.[id];
  }
};
const getPropertyMapping = (collection: BlockDTO) => {
  const propertyMapping: PropertyMapping = {};

  Object.entries(collection.data.schema ?? {}).forEach(([id, schema]) => {
    propertyMapping[schema.name] = { id, schema };
  });

  return propertyMapping;
};
