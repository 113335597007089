import { cx } from '@flowus/common/cx';
import { CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { useBitable } from 'src/bitable/context';
import { GroupMore } from 'src/bitable/group-more';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { deepEqual } from '@flowus/common/utils/tools';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { usePageFixedWidth } from 'src/hooks/page/use-page-fixed-width';
import { $appUiStateCache, setAppUiState, useTableGroupIsUnFolded } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { setLocalStorage } from 'src/utils/local-storage';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { getGroupUniqueId } from '../bitable-utils';
import { EMBED_PAGE_MANAGER_HEIGHT, PAGE_MANAGER_HEIGHT } from '../timeline-view/const';
import { CreateRecord } from './create-record';
import { GroupName, GroupNameScene } from './group-name';
import { GroupAggregation } from './group-aggregation';

interface Props {
  isSubGroup?: boolean;
  tableGroup: TableGroupData;
  isInTable?: boolean;
  index?: number;
}

export const GroupHeader: FC<Props> = memo((props) => {
  const {
    isSubGroup = false,
    tableGroup: { recordIds, value: groupName, visible },
    isInTable,
    index,
  } = props;
  const {
    viewId,
    readonly,
    managerReadonly,
    embed,
    isColumnListChildren,
    isFoldListChildren,
    viewType,
  } = useBitable();

  const isUnFolded = useTableGroupIsUnFolded(viewId, groupName);
  const scene = usePageScene();
  const groupHeaderRef = useRef<HTMLDivElement>(null);
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const pageFixedWidth = usePageFixedWidth();

  useEffect(() => {
    if ((!embed && !(isTimeline && !isInTable)) || $searchParams.print) return;

    const groupHeader = groupHeaderRef.current;
    const nextSpacePage = groupHeader?.closest('.next-space-page');
    if (!nextSpacePage || !groupHeader) return;

    const handleScroll = () => {
      const embedTable = groupHeader.parentElement;
      const embedTableRect = embedTable?.getBoundingClientRect();
      if (!embedTableRect) return;

      const nextSpacePageRect = nextSpacePage.getBoundingClientRect();
      const offset = isTimeline && !isInTable ? 74 : 0;
      const nextSpacePageRectY = nextSpacePageRect.y + offset;

      if (nextSpacePageRectY > embedTableRect.y) {
        let translateY = Math.abs(nextSpacePageRectY - embedTableRect.y);

        const nextGroup = embedTable?.nextSibling;
        if (nextGroup) {
          const nextGroupRect = (nextGroup as HTMLElement).getBoundingClientRect();
          if (nextGroupRect.y - nextSpacePageRectY < groupHeader.clientHeight) {
            translateY =
              translateY - (groupHeader.clientHeight - (nextGroupRect.y - nextSpacePageRectY));
          }
        }

        const footerRect = embedTable?.querySelector('.table-footer')?.getBoundingClientRect();
        if (footerRect && footerRect.y - nextSpacePageRectY < groupHeader.clientHeight) {
          translateY =
            translateY - (groupHeader.clientHeight - (footerRect.y - nextSpacePageRectY));
        }

        groupHeader.style.transform = `translateY(${
          translateY + (embed ? EMBED_PAGE_MANAGER_HEIGHT : PAGE_MANAGER_HEIGHT)
        }px)`;
        groupHeader.style.backgroundColor = `var(--white1)`;
      } else {
        groupHeader.style.transform = 'none';
        if (isTimeline) {
          groupHeader.style.backgroundColor = 'unset';
        }
      }
    };

    nextSpacePage.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [embed, isInTable, isTimeline]);

  const handleClick = () => {
    const timelineGroupVisible = $appUiStateCache.$tableGroupFoldStatus ?? {};

    setAppUiState({
      $tableGroupFoldStatus: {
        ...timelineGroupVisible,
        [viewId]: {
          ...timelineGroupVisible[viewId],
          [groupName]: !isUnFolded,
        },
      },
    });

    setLocalStorage(getGroupUniqueId(viewId, groupName, isSubGroup), JSON.stringify(!isUnFolded));
  };

  return (
    <div
      className={cx(
        'sticky z-20',
        !isSubGroup && 'border-t border-grey6',
        !(isTimeline && !isInTable) && 'bg-white1'
      )}
      style={{
        top: !embed
          ? scene === PageScene.PAGE_LITE_PREVIEW
            ? PAGE_MANAGER_HEIGHT - 11
            : PAGE_MANAGER_HEIGHT - 1
          : undefined,
      }}
      ref={groupHeaderRef}
    >
      <div
        className={cx(
          'sticky flex h-[38px] w-96 items-center bg-white1 pl-1',
          embed
            ? pageFixedWidth
              ? 'left-8'
              : 'left-0'
            : scene === PageScene.PAGE_LITE_PREVIEW
            ? 'left-10'
            : 'left-24',
          isTimeline && (isInTable ? 'h-[37px] w-auto' : 'bg-transparent'),
          !(isColumnListChildren || isFoldListChildren) && '-translate-x-8'
        )}
      >
        <button className="animate-hover mr-1.5 h-6 w-6 rounded text-grey4" onClick={handleClick}>
          <Icon
            name="IcToggleBlackUnfold"
            size="large"
            className="transition-all duration-200 print:hidden"
            style={{
              transform: isUnFolded ? 'rotate(0deg)' : 'rotate(-90deg)',
            }}
          />
        </button>

        <GroupName
          isSubGroup={isSubGroup}
          groupName={groupName}
          scene={GroupNameScene.GROUP_HEADER}
        />
        <GroupAggregation groupbyFieldName={'collectionGroupBy'} recordIds={recordIds} />

        {!$searchParams.print && (
          <>
            <GroupMore
              isSubGroup={isSubGroup}
              groupName={groupName}
              visible={visible}
              className="ml-3 mr-1 text-grey3"
            />

            {readonly || managerReadonly ? null : (
              <CreateRecord
                groupName={groupName}
                before={recordIds[0]}
                isSubGroup={isSubGroup}
                className="text-grey3"
              />
            )}
          </>
        )}
      </div>

      {isTimeline && isInTable && visible && index === 0 && (
        <Tooltip
          popup="收起表格"
          onClick={(event) => {
            event.stopPropagation();
            updateViewFormat(viewId, { timelineShowTable: false });
          }}
        >
          <Icon
            name="IcUnfold"
            size="middle"
            className="animate-hover absolute right-2 top-2 rotate-180 text-grey3"
          />
        </Tooltip>
      )}
    </div>
  );
}, deepEqual);
