import type { FC } from 'react';
import { useRef } from 'react';
import { useOfficialSpaceShare } from 'src/hooks/public/use-official-space-share';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { judgeSharePage } from 'src/utils/getPageId';

export const SpeedLimitButtonTips: FC<{ uuid: string; className?: string }> = (props) => {
  const { uuid, children, className } = props;
  const ref = useRef<HTMLDivElement>(null);
  // const openButtonTips = useOpenButtonTips({ localKey: LocalStorageKey.SPEED_LIMIT, endDay: 3 });
  const isSharePage = judgeSharePage();
  const { illegal, allowDuplicate } = usePermissions(uuid);

  const officialSpaceShare = useOfficialSpaceShare(uuid);
  // const popup = `拷贝页面到空间，极速查看文件内容。`;
  const show = isSharePage && allowDuplicate && !illegal && !officialSpaceShare;

  // useLayoutEffect(() => {
  //   if (show) {
  //     void sleep(2000).then(() => {
  //       openButtonTips(ref, { content: popup });
  //     });
  //   }
  // }, [openButtonTips, popup, show]);

  if (!show) return null;

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};
