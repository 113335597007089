import { DaySetting } from '@next-space/fe-api-idl';
import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';

export const getCalenderDates = (
  startDay: DaySetting,
  type: 'init' | 'append' = 'init',
  time?: number
) => {
  let firstSunday = 0,
    lastSaturday = 0;

  if (type === 'init') {
    const date = time ? new Date(time) : new Date();
    const month = date.getMonth(); // 当天所在月
    const firstDate = date.setMonth(month, 1); // 当月第一天
    const endDate = date.setMonth(month + 2, 0); // 下个月最后一天

    const firstMonday = getDateTimeStamp(
      firstDate - ((new Date(firstDate).getDay() || 7) - 1) * ONE_DAY
    );
    firstSunday = firstMonday - ONE_DAY;
    const lastMonday = getDateTimeStamp(
      endDate - ((new Date(endDate).getDay() || 7) - 1) * ONE_DAY
    );
    lastSaturday = lastMonday + ONE_DAY * 5;
  }

  if (type === 'append' && time) {
    const startDate = new Date(time);
    const endDate = startDate.setMonth(startDate.getMonth() + 2, 0);

    firstSunday = time;
    const lastMonday = getDateTimeStamp(
      endDate - ((new Date(endDate).getDay() || 7) - 1) * ONE_DAY
    );
    lastSaturday = lastMonday + ONE_DAY * 5;
  }

  const first =
    type === 'append' ? time : startDay === DaySetting.MON ? firstSunday + ONE_DAY : firstSunday;
  const last = startDay === DaySetting.MON ? lastSaturday + ONE_DAY : lastSaturday;

  const dates: number[] = [];
  let currentDate = first;
  if (!currentDate) return dates;

  while (currentDate <= last) {
    dates.push(currentDate);
    currentDate += ONE_DAY;
  }

  return dates;
};

export const getOneMonthDates = (startDay: DaySetting, time: number = Date.now()) => {
  const date = new Date(time);
  const month = date.getMonth(); // 当天所在月
  const firstDate = date.setMonth(month, 1); // 当月第一天
  const endDate = date.setMonth(month + 1, 0); // 当月最后一天
  const firstMonday = getDateTimeStamp(
    firstDate - ((new Date(firstDate).getDay() || 7) - 1) * ONE_DAY
  );
  const firstSunday = firstMonday - ONE_DAY;
  const lastMonday = getDateTimeStamp(endDate - ((new Date(endDate).getDay() || 7) - 1) * ONE_DAY);
  const lastSaturday = lastMonday + ONE_DAY * 5;

  const first = startDay === DaySetting.MON ? firstMonday : firstSunday;
  const last = startDay === DaySetting.MON ? lastSaturday + ONE_DAY : lastSaturday;

  const dates = [];
  let currentDate = first;
  while (currentDate <= last) {
    dates.push(currentDate);
    currentDate += ONE_DAY;
  }

  return dates;
};
