import { cx } from '@flowus/common/cx';
import { useState, type FC } from 'react';
import { DataSourceConfig } from './datasource-config';
import { StyleConfig } from './style/style-config';
import { ChartLine } from '../chart-line';

export const AsideConfig: FC<{ className?: string }> = (props) => {
  const { className } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={className}>
      <div className="px-3 flex items-center space-x-3">
        <Title
          title="数据和类型"
          active={activeIndex === 0}
          onClick={() => {
            setActiveIndex(0);
          }}
        />
        <Title
          title="设置样式"
          active={activeIndex === 1}
          onClick={() => {
            setActiveIndex(1);
          }}
        />
      </div>
      <ChartLine />
      <div className="chart-config-scroller overflow-auto h-[calc(100%-42px)]">
        {activeIndex === 0 && <DataSourceConfig />}
        {activeIndex === 1 && <StyleConfig />}
      </div>
    </div>
  );
};

const Title: FC<{ title: string; active: boolean; onClick: () => void }> = (props) => {
  return (
    <div className="flex items-center h-10 cursor-pointer" onClick={props.onClick}>
      <div
        className={cx('text-t2', {
          'text-t2-medium text-active_color underline underline-offset-[13px]': props.active,
        })}
        style={{
          textDecorationThickness: '2px',
        }}
      >
        {props.title}
      </div>
    </div>
  );
};
