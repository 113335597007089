import type { FC } from 'react';
import { useMemo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { FileNameUiKit } from 'src/components/file-name-uikit';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import type { ReadonlyProp } from 'src/redux/types';
import { UploadStatus } from 'src/redux/types';
import { useDisableInteractive } from 'src/services/app/hook/use-drop-info';
import { useUploadInfoById } from 'src/services/upload';
import { isInColumnList } from 'src/utils/block-utils';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import type { Instance, Props } from 'tippy.js';
import { ViewModelNormal } from '../view-mode';
import { EmptyFile } from './empty-file';
import { PagePreviewWrapper } from './page-preview-wrapper';

export interface FileProps extends ReadonlyProp {
  fileName?: string;
  size?: number;
  showNotFound: boolean;
  progress: number; // -1表示不是在上传
  defaultOpenUploadPanel: boolean; // 是否打开上传panel
  root?: boolean;
  uuid: string;
  ownerBlockId?: string;
}

export const File: FC<FileProps> = (props) => {
  const {
    fileName,
    progress,
    showNotFound,
    defaultOpenUploadPanel,
    uuid,
    readonly,
    ownerBlockId,
    root,
    size,
  } = props;
  const disableInteractive = useDisableInteractive();
  const fileBlock = usePickBlock(uuid, ['data'], ['ossName', 'link']);
  const uploading = progress !== -1 && progress !== 100;
  const uploadInfo = useUploadInfoById(uuid);
  const tippyInstance = useRef<Instance<Props>>();
  const pageId = useGetPageId();
  const inColumn = useMemo(() => isInColumnList(uuid, pageId), [pageId, uuid]);

  // 上传的时候会用到本地的 filename，这里存一下
  const { extName } = getFileNameInfo(fileName);

  if (uploading) {
    return (
      <div className="flex items-center w-full h-12.5 py-2.5 pl-3 rounded bg-grey8 cursor-pointer my-1 mx-[2px]">
        <Icon size="large" name="IcUploadFile" className="text-grey4" />
        <div className="flex flex-col">
          <span className="ml-2 text-t2 text-grey3">{uploadInfo?.name}</span>
          <span className="ml-2 text-t4 text-grey3">{progress}%</span>
        </div>
      </div>
    );
  }
  if (!fileBlock?.data.ossName && !fileBlock?.data.link) {
    return (
      <BlockDrop id={ownerBlockId || uuid} horizontal={root} className="my-1">
        <EmptyFile
          title={
            uploadInfo?.status === UploadStatus.failure
              ? [uploadInfo?.name ?? '', '上传失败'].join('')
              : undefined
          }
          uuid={uuid}
          ownerBlockId={ownerBlockId}
          readonly={readonly}
          defaultOpenPanel={defaultOpenUploadPanel}
        />
      </BlockDrop>
    );
  }
  return (
    <Tooltip
      interactive={!disableInteractive}
      lazyLoad={true}
      onShow={(instance) => {
        tippyInstance.current = instance;
      }}
      theme="none"
      popupClass="next-modal"
      className="w-full"
      offset={[0, 8]}
      placement={inColumn ? 'left' : 'top-start'}
      maxWidth={10000}
      delay={[500, 50]}
      animation={'shift-away'}
      popup={
        <PagePreviewWrapper
          ownerBlockId={ownerBlockId}
          uuid={uuid}
          onCloseOutsideModel={() => {
            tippyInstance.current?.hide();
          }}
        />
      }
    >
      <ViewModelNormal
        {...{
          ownerBlockId,
          root,
          uuid,
          readonly,
          isFile: true,
          iconName: getFileIcon(extName),
          title: showNotFound ? '文件已被删除' : <FileNameUiKit fileName={fileName} />,
          context: showNotFound ? '0 KB' : getReadableFileSizeString(size),
        }}
      />
    </Tooltip>
  );
};
