import dayjs from 'dayjs';
import type { FC } from 'react';
import { useMemo } from 'react';
import { Button } from 'src/common/components/button';
import { useModel } from 'src/common/create-model';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { PRODUCT_AI_PAY_TITLE, PRODUCT_AI_TITLE } from 'src/const/title';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { getCurrentSpace } from 'src/hooks/space';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { SpacePlanType } from 'src/redux/types';
import { useLimitConfig } from 'src/services/app/hook/subscription-data';
import { useIsAiPlan, useSpaceCapacity } from 'src/services/capacity';
import { useCurrentSpacePlanInfo } from 'src/services/capacity/space-plans';
import { getSpacePlanTypeName } from 'src/utils/block-utils';
import { SettingModalCommon, SettingProvider } from '../common';
import { OpenSettingFrom } from '../type';

const useUpgradeSettingHeader = () => {
  const currentSpace = getCurrentSpace();
  const { spacePlan, currentSpacePlanConfig } = useCurrentSpacePlanInfo();
  const { autoRenewal } = useSpaceCapacity();
  const { blocksMaxLimit } = useLimitConfig();

  /** 是不是自动续费套餐 */
  const month = spacePlan?.month ?? 0;
  const timeFormat = dayjs(spacePlan?.endAt).format('YYYY.MM.DD');

  const content = useMemo(() => {
    const isCycleText: Record<number, string> = {
      1: '月',
      3: '季',
      12: '年',
    };

    const lastTime = `有效期至${timeFormat}`;

    const cycleTips = autoRenewal && isCycleText[month] ? <>，连续包{isCycleText[month]}</> : '';

    let result = (
      <>
        {lastTime}
        {cycleTips}
      </>
    );

    // 小组版
    if (currentSpace.planType === SpacePlanType.smallTeam) {
      result = (
        <>
          {lastTime}
          {cycleTips}，升级到团队版每增加1席位空间存储容量可扩容150GB
        </>
      );
    }

    // 小组试用
    if (currentSpace.planType === SpacePlanType.freeTeam) {
      result = <>{`，免费块数${blocksMaxLimit}，升级获得无限块和300GB空间存储容量`}</>;
    }

    // 个人专业
    if (currentSpace.planType === SpacePlanType.freePersonal) {
      result = <>{`${lastTime}，升级到个人专业版，支持超大文件上传`}</>;
    }

    if (currentSpacePlanConfig?.description?.guide) {
      result = <>{currentSpacePlanConfig.description.guide}</>;
    }

    return result;
  }, [
    blocksMaxLimit,
    currentSpace.planType,
    currentSpacePlanConfig?.description?.guide,
    autoRenewal,
    month,
    timeFormat,
  ]);
  return { content, currentSpace, timeFormat };
};

// 付费版空间公告
export const UpgradeSettingHeader: FC = () => {
  const { spacePlan } = useCurrentSpacePlanInfo();
  const { content, currentSpace } = useUpgradeSettingHeader();
  if (!currentSpace) return null;
  return (
    <div className="text-t2 h-10 flex items-center space-x-2 text-grey3 flex-shrink-0">
      <div className="whitespace-nowrap space-x-2">
        <span>当前版本</span>
        <span className="text-t2-bold text-black">
          {getSpacePlanTypeName(currentSpace.planType)}空间
        </span>
        <span>席位</span>
        <span className="text-t2-bold text-black">{spacePlan?.maxPeople}</span>
      </div>
      <div>{content}</div>
    </div>
  );
};

const FuAiHeader: FC = () => {
  const spaceCapacity = useSpaceCapacity();
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const isProSpace = useIsProSpace();
  const { enableAI } = useEnableAI();
  const tips = isProSpace ? '' : '升级可享用无限使用次数';

  const handleClick = () => {
    openUpgradeAiModal({
      isFrom: OpenSettingFrom.package,
    });
  };

  return (
    <>
      <UpgradeSettingHeader />
      <SettingModalCommon.SettingDivider className="mb-2.5" />
      {enableAI && (
        <>
          <SettingModalCommon.Item
            leftChild={PRODUCT_AI_TITLE}
            rightChild={isProSpace ? '无限次' : ''}
          />
          {!isProSpace && (
            <SettingModalCommon.Item
              leftChild={`已使用 ${spaceCapacity.usage}次/${spaceCapacity.aiRestUsage}次`}
              rightChild={
                isProSpace ? (
                  <></>
                ) : (
                  <Button onClick={handleClick}>购买 {PRODUCT_AI_PAY_TITLE}</Button>
                )
              }
            />
          )}
          {!isProSpace && (
            <SettingModalCommon.Item
              leftChild={`购买 ${PRODUCT_AI_PAY_TITLE}，空间每日使用上限可拓展至最少100次；${tips}`}
            />
          )}
          <SettingModalCommon.SettingDivider className="mt-2.5 mb-5" />
        </>
      )}
    </>
  );
};

const SpacePlanInfo = () => {
  const isProSpace = useIsProSpace();
  const { currentSpace, timeFormat } = useUpgradeSettingHeader();
  const { spacePlan } = useCurrentSpacePlanInfo();

  return (
    <>
      <p>当前空间</p>
      <p>
        <span className="text-h4 text-black mr-2">
          {getSpacePlanTypeName(currentSpace.planType)}
        </span>
        <span>{spacePlan?.maxPeople} 席位</span>
      </p>
      <p hidden={!isProSpace}>{timeFormat} 到期</p>
    </>
  );
};

const BuildInAiHeader: FC = () => {
  const spaceCapacity = useSpaceCapacity();
  const isAiPlan = useIsAiPlan();
  const { toUpgradeAi } = useModel(SettingProvider);

  const todayUsed = spaceCapacity.gpt3_5.todayUsed + spaceCapacity.pdf.todayUsed;
  const gpt3Rest = isAiPlan
    ? spaceCapacity.gpt3_5.monthLimit - spaceCapacity.gpt3_5.monthUsed
    : spaceCapacity.gpt3_5.dayLimit - spaceCapacity.gpt3_5.todayUsed;
  const pdfRest = isAiPlan
    ? spaceCapacity.pdf.dayLimit - spaceCapacity.pdf.todayUsed
    : spaceCapacity.pdf.monthLimit - spaceCapacity.pdf.monthUsed;

  return (
    <div className="mt-2.5 mb-5 border flex-shrink-0 overflow-auto text-grey3">
      <div className="min-w-[720px] h-40 grid grid-cols-4">
        <div className="border-r p-2.5 space-y-2">
          <SpacePlanInfo />
        </div>
        <div className="col-span-3 p-2.5 w-full flex flex-col">
          <div className="flex justify-between items-center border-b pb-2.5 w-full">
            <div className="space-y-2">
              <p>当前 AI 权益</p>
              <p className="text-h4 text-black">{isAiPlan ? '专业' : '免费'}</p>
            </div>
            <div className="space-x-2">
              <span>AI 查询次数不够用？</span>
              <span className="text-active_color animate-click" onClick={() => toUpgradeAi()}>
                购买查询额度
              </span>
              {/* <span hidden={!isProSpace} className="bg-red rounded-sm px-2 text-black">
                付费空间享优惠价格
              </span> */}
            </div>
          </div>
          <div className="pt-2.5 grid grid-cols-2 flex-1">
            {[
              { num: todayUsed, title: '今日 AI 查询次数' },
              { num: gpt3Rest + pdfRest, title: '当前剩余查询次数' },
            ].map((item) => (
              <div className="flex flex-col justify-center items-center space-y-2" key={item.title}>
                <p>{item.num}</p>
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpgradeSettingAiHeader = __BUILD_IN__ ? BuildInAiHeader : FuAiHeader;
