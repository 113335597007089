import { Button } from '@flowus/common/components';
import { message } from '@flowus/common/components/message';
import { cx } from '@flowus/common/cx';
import { useOpenModal } from '@flowus/common/next-modal';
import type { FC } from 'react';
import { request } from 'src/common/request';

interface Props {
  className?: string;
  uuid: string;
}
export const PublishButton: FC<Props> = (props) => {
  const { className, uuid } = props;
  const openModal = useOpenModal();

  const onPublish = async () => {
    const id = message.loading('推送中');
    await request.infra.subscribePublishChange(uuid);
    message.closeMessage(id);
    message.success('推送成功');
  };

  const handleClick = () => {
    openModal.warning({
      title: '确认推送给所有订阅者吗？',
      confirmText: '发布推送',
      confirm: onPublish,
    });
  };

  if (__PRIVATE__) return null;

  return (
    <Button className={cx('flex-shrink-0', className)} onClick={handleClick}>
      发布
    </Button>
  );
};
