import { Role } from '@flowus/common';
import { BlockType, PermissionRole } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { useCloseModal, useSetNextModalState } from 'src/common/components/next-modal';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { getPermissions } from 'src/hooks/share/use-permissions';
import type { RootState } from 'src/redux/types';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';

/** 关闭 + / 菜单 */
export const useCloseCreateBlockMenuList = () => {
  const closeModal = useCloseModal();

  return useCallback(() => {
    const { $createBlockMenuListId } = $appUiStateCache;
    if ($createBlockMenuListId) {
      closeModal($createBlockMenuListId, {
        closeAfterCallBack: () => {
          setAppUiState({
            $createBlockMenuListId: '',
            $menuListKeywords: '',
            $slashSelection: {},
            $textareaPlaceHolder: undefined,
          });
        },
      });
    }
  }, [closeModal]);
};

/** 关闭六个点菜单 */
export const useCloseBlockMenuList = () => {
  const closeModal = useCloseModal();
  return useCallback(() => {
    closeModal($appUiStateCache.$blockMenuListId);
    setAppUiState({ $blockMenuListId: '' });
  }, [closeModal]);
};

/** 更新createBlock菜单id */
export const useSetCreateBlockMenuListId = () => {
  return useCallback((id: string) => {
    setAppUiState({
      $createBlockMenuListId: id,
      $textareaPlaceHolder: {
        blockId: id,
        placeHolder: '搜索插入内容',
        alwaysShow: false,
      },
      $focusedInSyncBlockId: '',
    });
  }, []);
};

/**
 * 是否选中当前block
 * 为了更好的支持多选 内嵌多维表条目
 * ps:
 * 1.对于分栏，如果是整个分栏下的subNode都被选中了，则只存分栏id，不存subNode id
 * 2.对于内嵌多维表,如果多维表被选中，并且多维表下的条目也被选中，则条目是不需要显示选中背景的(背景会重叠)
 */
export const useIsSelected = (
  uuid: string,
  viewId?: string,
  groupValue?: string,
  subGroupValue?: string
) => {
  const syncId = useSyncId();

  return useObservableStore(
    ({ ui: { selectedBlocks }, blocks }) => {
      return getIsSelected({
        blocks,
        groupValue,
        selectedBlocks,
        subGroupValue,
        syncId,
        uuid,
        viewId,
      });
    },
    [uuid, viewId, groupValue, subGroupValue, syncId],
    {
      obsSelectBlocks: [
        {
          blockId: uuid,
          viewId,
          syncId,
        },
      ],
      wait: 50,
      ignoreDeep: true,
    }
  );
};

export const getIsSelected = (params: {
  selectedBlocks: RootState['ui']['selectedBlocks'];
  blocks: RootState['blocks'];
  uuid: string;
  viewId?: string;
  syncId?: string;
  groupValue?: string;
  subGroupValue?: string;
}) => {
  const { selectedBlocks, blocks, uuid, viewId, syncId, groupValue, subGroupValue } = params;
  // TODO 调试，可以查看到一次操作被执行了几次
  if (!selectedBlocks.length) return false;
  const block = blocks[uuid ?? ''];
  if (!block) return false;

  const parentBlock = blocks[block.parentId];

  if (parentBlock?.type === BlockType.COLLECTION_VIEW) {
    if (selectedBlocks.find((item) => item.blockId === block.parentId)) {
      // 如果内嵌多维表被选中，条目就不需要展示背景了
      return false;
    }
  }
  return !!selectedBlocks.find((item) => {
    const isSelected = item.blockId === uuid && item.viewId === viewId && item.syncId === syncId;
    if (!isSelected) return false;

    if (!groupValue || !item.groupValues) return true;

    const values = Object.keys(item.groupValues);
    if (!values.includes(groupValue)) return false;

    if (!subGroupValue) return true;
    return item.groupValues[groupValue]?.includes(subGroupValue);
  });
};

export const useIsAllSelected = (uuid: string, viewId: string, sortedRecordIds: string[]) => {
  const syncId = useSyncId();

  return useObservableStore(
    ({ ui: { selectedBlocks } }) => {
      if (!selectedBlocks.length) return false;
      const map = new Map();
      selectedBlocks.forEach((v) => {
        map.set(v.blockId, v.viewId);
      });
      // 有些记录没写权限，过滤掉
      return sortedRecordIds
        .filter((v) => {
          const permission = getPermissions(v);
          return Role.contains(permission.role, PermissionRole.WRITER);
        })
        .every((id) => {
          const _viewId = map.get(id);
          return _viewId && _viewId === viewId;
        });
    },
    [uuid, viewId, syncId],
    {
      obsSelectBlocks: [
        {
          blockId: uuid,
          viewId,
          syncId,
        },
      ],
    }
  );
};

/** 关闭所有弹窗并清空uiState相关数据 */
export const useCloseAllModal = () => {
  const setOpenModalList = useSetNextModalState();
  const setCreateBlockMenuListId = useSetCreateBlockMenuListId();
  const closeBlockMenuList = useCloseBlockMenuList();
  const closeCreateBlockMenuList = useCloseCreateBlockMenuList();

  return useCallback(() => {
    setCreateBlockMenuListId('');
    closeBlockMenuList();
    closeCreateBlockMenuList();
    setOpenModalList(() => ({}));
  }, [setOpenModalList, closeBlockMenuList, closeCreateBlockMenuList, setCreateBlockMenuListId]);
};
