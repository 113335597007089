import { createSetState, createStore } from '@flowus/common/zustand/utils';
import produce from 'immer';
import { assign, omit, values } from 'lodash-es';
import { fastEqual } from '@flowus/common/utils/tools';
import type { UploadInfo } from 'src/redux/types';
import { UploadStatus } from 'src/redux/types';
import { $downloadManager } from '../download-manager';

const $uploadInfo = createStore<Record<string, UploadInfo>>(() => ({}));
const $setUploadInfo = createSetState($uploadInfo);

export const getUploadInfo = () => {
  return $uploadInfo.getState();
};

export const setUploadInfo = (newState: Record<string, UploadInfo>) => {
  $setUploadInfo(
    produce((pre) => assign(pre, newState)),
    { replace: true }
  );
};

// set
export const removeUploadInfoByKey = (key: string) => {
  $setUploadInfo(
    produce((pre) => omit(pre, key)),
    { replace: true }
  );
};

export const clearUploadInfo = () => {
  $setUploadInfo(
    (pre) => {
      const newState: typeof pre = {};
      values(pre).forEach((info) => {
        if (info.status === UploadStatus.uploading) {
          newState[info.key] = info;
        }
      });
      return newState;
    },
    { replace: true }
  );

  $downloadManager.tasks.forEach((task) => {
    if (task.state !== 'progressing') {
      void $downloadManager.remove(task.id);
    }
  });
};

export const useUploadInfo = () => {
  return $uploadInfo((state) => state, fastEqual);
};

export const useUploadInfoSize = () => {
  return $uploadInfo((state) => state.length);
};

export const useUploadInfoById = (id: string) => {
  return $uploadInfo(
    (state) => Object.values(state).find((info) => info.key.endsWith(id)),
    fastEqual
  );
};

export const updateUploadInfo = (newState: UploadInfo) => {
  const { key } = newState;
  if (key) {
    const uploadInfo = getUploadInfo()[key] ?? {};
    setUploadInfo({ [key]: { ...uploadInfo, ...newState } });
  }
};

export const useUploadFiles = (uuid: string) => {
  return $uploadInfo((state) => {
    return (
      Object.entries(state)
        .filter(([key, info]) => {
          return (
            key.startsWith(uuid) &&
            info.status &&
            [UploadStatus.uploading, UploadStatus.failure].includes(info.status)
          );
        })
        .flatMap(([_, info]) => info)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .sort((a, b) => b.createdAt! - a.createdAt!)
    );
  }, fastEqual);
};

/** 获取文件夹有几个正在上传的任务 */
export const useFolderUploadingCount = (folderId: string) => {
  return $uploadInfo((state) => {
    return Object.entries(state).filter(([key, info]) => {
      return (
        key.startsWith(folderId) &&
        info.status &&
        [UploadStatus.uploading, UploadStatus.failure].includes(info.status)
      );
    }).length;
  });
};
