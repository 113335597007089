import { useCallback } from 'react';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache } from 'src/redux/store';

import { useTransaction } from '../use-transaction';

export const useDeleteSelectedTableCells = () => {
  const transaction = useTransaction();

  return useCallback(() => {
    transaction(() => {
      cache.ui.selectedCells.forEach((o) => {
        const row = cache.blocks[o.recordId];
        if (row) {
          const { [o.propertyId]: _, ...rest } = row.data.collectionProperties ?? {};
          updateBlock(o.recordId, {
            data: {
              ...row.data,
              collectionProperties: rest,
            },
          });
        }
      });
    });
  }, [transaction]);
};
