import type { NextBlock } from '@flowus/common/block/type';
import { ScrollLoadList } from '@flowus/common/components/scroll-load-list';
import { useOpenModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import { BlockType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Fragment, memo, useCallback, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { UNTITLED_FILE } from 'src/common/const';
import { request } from 'src/common/request/request';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { ImagesProvider } from 'src/components/images-provider';
import { IframeLitePage } from 'src/components/page-preview/lite-page';
import { segmentsToText } from 'src/editor/utils/editor';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useBlock } from 'src/hooks/block/use-block';
import { isDeleted } from 'src/hooks/block/use-block-status';
import { getNavs } from 'src/hooks/drive/use-search-block';
import { useCurrentSpace } from 'src/hooks/space';
import { blocksActions } from 'src/redux/reducers/blocks';
import { collectionViewsActions } from 'src/redux/reducers/collection-views';
import { dispatch } from 'src/redux/store';
import { getFileIcon, getSegmentsFileNameInfo } from 'src/utils/file';
import { getBlockPageLikeTitle } from 'src/utils/get-untitled-name';
import { v4 } from 'uuid';

const MODAL_ID = v4();
const SpaceFileList: FC = () => {
  const container = useRef<HTMLDivElement>(null);
  const currentSpace = useCurrentSpace();
  const openModal = useOpenModal();
  const getApi = async (params: { pageIndex: number; pageSize: number }) => {
    const res = await request.infra.getResourcesList(
      currentSpace.uuid,
      params.pageIndex,
      params.pageSize
    );

    const blocks = res.recordMap?.blocks as Record<string, NextBlock>;
    if (res.recordMap?.blocks) {
      dispatch(blocksActions.update({ blocks }));
    }

    if (res.recordMap?.collectionViews) {
      dispatch(collectionViewsActions.update(res.recordMap.collectionViews));
    }

    const list = res.results.map((item) => {
      return { ...item, nav: getNavs(blocks, item.blockId) };
    });

    return {
      list,
      more: list.length !== 0,
    };
  };

  return (
    <ImagesProvider uuid={currentSpace.uuid}>
      <div className="flex items-center justify-between px-2.5 py-2 border-b text-t2-medium">
        <span>空间文件</span>
        <Icon
          onClick={() => openModal.closeModal(MODAL_ID)}
          size="middle"
          name="IcToastClose"
          className="animate-hover animate-click"
        />
      </div>
      <div ref={container} className="max-h-120 overflow-auto w-125 py-2 px-1">
        <ScrollLoadList
          className="space-y-2"
          container={container}
          getApi={getApi}
          renderItem={(item) => <FileItem {...item} />}
        />
      </div>
    </ImagesProvider>
  );
};

const FileItem = memo(
  (props: { blockId: string; fileName: string; size: number; nav?: NextBlock[] }) => {
    const openModal = useOpenModal();
    const { blockId, size, nav = [], fileName: _fileName } = props;
    const openFilePreView = useOpenFilePreview();
    const file = useBlock(blockId);
    const fileName = segmentsToText(file?.data.segments) || _fileName || UNTITLED_FILE;
    const { extName } = getSegmentsFileNameInfo(file?.data.segments);

    const handleClick = () => {
      openFilePreView(blockId);
    };

    const toPage = (pageId: string) => {
      openModal.modal({
        content: (_) => (
          <div className="flex flex-col h-[80vh] w-[80vw]">
            <div className="flex items-center justify-between px-4 py-2 text-t1-medium bg-white1">
              <div>预览页面</div>
              <Icon
                className="animate-hover animate-click"
                size="middle"
                name="IcBannerClose"
                onClick={() => _.onCloseModal()}
              />
            </div>
            <div className="h-full w-full">
              <IframeLitePage id={pageId}></IframeLitePage>
            </div>
          </div>
        ),
      });
    };

    return (
      <div
        className="w-full py-1 px-2 space-x-2.5 flex items-center animate-hover animate-click"
        onClick={handleClick}
      >
        <Icon size="xlarge" name={getFileIcon(extName)} />
        <div className="w-4/5 space-y-1">
          <div className="max-w-full text-t2-medium space-x-2 flex items-center">
            <span className="text-ellipsis">{fileName}</span>
            {isDeleted(blockId) && (
              <span className="text-red text-t5 whitespace-nowrap">回收站</span>
            )}
          </div>
          <div className="space-x-2 text-grey3 text-t4 flex items-center">
            {!!nav.length && (
              <div className="space-x-0.5 flex items-center flex-wrap">
                {nav.map((page, index) => (
                  <Fragment key={page.uuid}>
                    <Tooltip
                      popup="预览文件所处页面"
                      onClick={(e) => {
                        e.stopPropagation();
                        toPage(page.uuid);
                      }}
                      className="text-t4 text-grey1 px-0.5 animate-hover animate-click flex-shrink-0"
                    >
                      {getBlockPageLikeTitle(BlockType.PAGE, page?.data.segments)}
                    </Tooltip>
                    {nav.length !== index + 1 && <span>|</span>}
                  </Fragment>
                ))}
              </div>
            )}
            <div className="text-grey3 text-t4">{getReadableFileSizeString(size)}</div>
          </div>
        </div>
      </div>
    );
  }
);

export const useOpenSpaceFileList = () => {
  const openModal = useOpenModal();

  return useCallback(() => {
    openModal.modal({
      modalId: MODAL_ID,
      keyboard: false,
      content: () => <SpaceFileList />,
    });
  }, [openModal]);
};
