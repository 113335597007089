import type { FC } from 'react';
import type { StoreContextProps } from './context';
import { StoreContext, useStoreContext } from './context';

export const StoreContextProvider: FC<StoreContextProps> = (props) => {
  return <>{props.children}</>;
};

export const IStoreContextProvider: FC<StoreContextProps> = (props) => {
  const { children, once = false, ...reset } = props;

  const { state, isInit } = useStoreContext(reset);

  return (
    <StoreContext.Provider value={{ $state: state, isInit, once }}>
      {children}
    </StoreContext.Provider>
  );
};
