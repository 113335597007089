import type { FC } from 'react';
import type { TemplateCategory } from 'src/hooks/template/types';

import { TemplateItem } from './template-item';

interface Props {
  category: TemplateCategory;
  /** 直接预览 */
  preview?: boolean;
  onPreviewOpen: (uuid: string) => void;
  targetId?: string;
}
export const Category: FC<Props> = ({ category, preview, onPreviewOpen, targetId }) => {
  const hasTemplates = (category?.templates ?? []).length > 0;
  return (
    <div>
      <div>
        <div className="text-t1 mt-9 mb-2.5">{category.name}</div>
        <div className="bg-grey6 w-full h-px mb-[14px]"></div>
      </div>
      <div
        className="w-full pb-9 pt-5 grid gap-4"
        style={{ gridTemplateColumns: `repeat(auto-fill, 360px)` }}
      >
        {category.templates?.map((item) => {
          return (
            <TemplateItem
              template={item}
              key={item.pageId}
              preview={preview}
              targetId={targetId}
              onPreviewOpen={onPreviewOpen}
            />
          );
        })}
        {!hasTemplates && <Empty />}
      </div>
    </div>
  );
};

const Empty = () => {
  return (
    <div className="px-[30px] relative w-fit flex flex-col items-center">
      <div style={{ fontSize: '60px' }}>☕️</div>
      <div className="text-grey4 text-t2">暂无模板</div>
    </div>
  );
};
