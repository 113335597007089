import type { NextBlock } from 'src/redux/types';
import { setLocalStorage } from 'src/utils/local-storage';

/**
 * 着重block更换icon/背景颜色/文字颜色之后，下一次新建或者转换时会使用到上一次的数据，这里是记录上一次使用的数据
 */
export interface MarkBlockInfo {
  icon?: string;
  textColor?: string;
  backgroundColor?: string;
}
export function saveLastMarkBlockInfo(info: MarkBlockInfo) {
  if (info.icon) {
    setLocalStorage('last_mark_info_icon', info.icon);
  }

  if (info.textColor) {
    setLocalStorage('last_mark_info_text_color', info.textColor);
    localStorage.removeItem('last_mark_info_background_color');
  } else if (info.backgroundColor) {
    setLocalStorage('last_mark_info_background_color', info.backgroundColor);
    localStorage.removeItem('last_mark_info_text_color');
  }
}
export function getLastMarkBlockInfo(): Partial<NextBlock> {
  // 为了不让key有undefined或者null,有其他更好的写法?
  const params: Partial<NextBlock> = {};
  const textColor = localStorage.getItem('last_mark_info_text_color');
  const backgroundColor = localStorage.getItem('last_mark_info_background_color');
  const data = { icon: localStorage.getItem('last_mark_info_icon') };
  if (textColor) {
    params.textColor = textColor;
  } else if (backgroundColor) {
    params.backgroundColor = backgroundColor;
  }
  if (data.icon) {
    params.data = { icon: { value: data.icon, type: 'emoji' } };
  }
  return params;
}
