import { API, apiAxios } from '@flowus/common/api/index';
import { getSessionStorage, SessionKeys } from '@flowus/common/storage';
import { ViewPath } from 'src/utils';
import { isFlowUsApp } from 'src/utils/electron-util';
import { getToken } from 'src/utils/get-next-auth';
import { currentVersion, TOKEN, USER_ID } from '../const';
import { checkViewPath, getDomain } from '../utils/url-utils';
import { SENDER_ID } from './axios';

const headers = {
  'X-SENDER-ID': SENDER_ID,
  'x-platform': isFlowUsApp.check ? 'desktop' : 'web-cookie',
  'x-product': __BUILD_IN__ ? 'buildin' : 'flowus',
  app_version_name: currentVersion,
  'x-app-origin': 'web',
  'Accept-Language': __BUILD_IN__ ? 'en-US,en;q=0.9,zh;q=0.8' : undefined,
};

apiAxios.interceptors.request.use((config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  const psw = getSessionStorage(SessionKeys.PASSWORD);
  if (psw) {
    config.headers['share-password'] = psw;
  }

  Object.assign(config.headers, headers);

  return config;
});

apiAxios.interceptors.response.use((res: any) => {
  if (res.status === 401 || (res.data && res.data.code === 401)) {
    // 补丁，只有这个接口不自动跳/login
    if (res.config.url === '/users/me') {
      return res;
    }

    localStorage.removeItem(USER_ID);
    localStorage.removeItem(TOKEN);

    if (!checkViewPath(ViewPath.login)) {
      // 未登录也可以访问公开主页
      if (window.location.pathname === ViewPath.main && getDomain()) {
        return res;
      }
      if (checkViewPath(ViewPath.form, 'startsWith')) {
        return;
      }

      window.location.pathname = ViewPath.login;
    }
  } else {
    const token = res.headers['x-set-token'];
    if (token) {
      localStorage.setItem(TOKEN, token);
    }
  }

  return res;
});

export const request = API;
