import { getFormatUrl } from '@flowus/common/url';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button } from 'src/common/components/button';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { downloadUrlFile } from 'src/common/utils/download-utils';
import { LRUCache } from 'src/common/utils/lru-cache';
import { bizTracker } from 'src/utils/biz-tracker';

import { v4 } from 'uuid';

const modalId = v4();

interface OpenWeappQrProps {
  url: string;
}

const shortUrlCache = new LRUCache<string, string>(localStorage, 'short-url');
const miniProgramCache = new LRUCache<string, string>(localStorage, 'mini-program');

const getShortUrl = async (url: string) => {
  const localUrl = shortUrlCache.get(url);

  if (localUrl) {
    return localUrl;
  }

  const shortUrl = await request.infra.createShortUrl({ url });
  shortUrlCache.put(url, shortUrl);

  return shortUrl;
};

const getMiniProgram = async (scene: string) => {
  const isDev = __HOST_LOCAL__ || __HOST_TEST__;
  const page = 'pages/webview/index';
  const env_version = isDev ? 'trial' : 'release';
  const cacheKey = `${env_version}-${page}-${scene}`;
  const qrUrl = miniProgramCache.get(cacheKey);

  if (qrUrl) {
    return qrUrl;
  }

  const qr = await request.infra.createMiniProgramQr({
    check_path: !isDev,
    env_version,
    page,
    scene,
  });

  miniProgramCache.put(cacheKey, qr);
  return qr;
};

const WeappQR: FC<OpenWeappQrProps> = (props) => {
  const { url } = props;
  const [imageResource, setImageResource] = useState<string>('');
  const closeModal = useCloseModal();

  useEffect(() => {
    void (async () => {
      const shortUrl = await getShortUrl(url);
      if (shortUrl) {
        const qr = await getMiniProgram(
          `shortId=${getFormatUrl(shortUrl)?.pathname.replace('/', '') ?? shortUrl}`
        );
        setImageResource(qr);
      }
    })();
  }, [url]);

  const onCloseModal = () => {
    closeModal(modalId);
  };

  const onDownload = () => {
    void downloadUrlFile(imageResource, `${Date.now()}.jpeg`);
  };

  useEffect(() => {
    setImageResource('');
  }, []);

  return (
    <div className="next-modal w-96">
      <div className="h-20 flex items-center px-8">小程序分享</div>
      <div className="flex items-center justify-center px-20">
        {imageResource ? (
          <img
            src={imageResource}
            className="w-full p-4 bg-white-base rounded-md"
            alt="小程序二维码"
          />
        ) : (
          <LoadingContainer className="w-56 h-56" />
        )}
      </div>
      <div className="flex items-center justify-end h-20 px-8">
        <Button className="mr-2.5" onClick={() => onCloseModal()}>
          取消
        </Button>
        <Button colorType="active" onClick={onDownload}>
          下载图片
        </Button>
      </div>
    </div>
  );
};

export const useOpenWeappQR = () => {
  const openModal = useOpenModal();

  return useCallback(
    (params: OpenWeappQrProps) => {
      bizTracker.event('click_share_btn', {
        type: 'weapp_qr',
      });
      openModal.modal({
        modalId,
        content: () => <WeappQR {...params} />,
      });
    },
    [openModal]
  );
};
