import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { useState } from 'react';
import { capitalizeFirstLetter } from 'src/utils/string-util';

export type PayTimeType = 'year' | 'month' | 'all';

export const dateKey2Unit = (key: Omit<PayTimeType, 'all'>) => {
  if (key === 'year') {
    return '年';
  }
  if (key === 'month') {
    return '月';
  }
};

export const getDateUnit = (
  monthNum?: number,
  opt?: { prefix?: string; low?: boolean; ly?: boolean }
) => {
  const { prefix, low = true, ly = false } = opt || {};
  if (!monthNum) return '';
  let unit = '';

  if (monthNum >= 12) {
    unit = 'year';
  } else if (monthNum >= 3) {
    unit = 'season';
  } else if (monthNum >= 1) {
    unit = 'month';
  }

  if (__BUILD_IN__) {
    if (!low) {
      unit = capitalizeFirstLetter(unit);
    }
    if (ly) {
      unit = `${unit}ly`;
    }
  } else {
    const lng: Record<string, string> = {
      year: '年',
      season: '季',
      month: '月',
    };
    unit = lng[unit] as string;
  }

  if (!unit || !prefix) return unit;
  return `${prefix}${unit}`;
};

export const usePayCycleButton = (props: { defaultTimeType?: PayTimeType }) => {
  const [switchPayType, setSwitchPayType] = useState<PayTimeType>(props.defaultTimeType ?? 'month');
  return { switchPayType, setSwitchPayType };
};

interface Props {
  payType: PayTimeType;
  onSwitch: (value: PayTimeType) => void;
}
export const PayCycleButton: FC<Props> = (props) => {
  const { onSwitch, payType } = props;
  return (
    <div className="p-2">
      {[
        { name: '月付', type: 'month' },
        { name: '年付', type: 'year' },
      ].map((item) => (
        <div
          hidden={payType === 'all'}
          key={item.type}
          className={cx(
            'w-1/2 text-center inline-block p-2 rounded-sm animate-click',
            payType === item.type && 'text-black text-t2-bold bg-black_006'
          )}
          onClick={() => onSwitch(item.type as PayTimeType)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};
