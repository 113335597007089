import { useCallback, useMemo } from 'react';
import { getCurrentSpace, useCurrentSpace } from './use-current-space';
import { useSpaces } from './use-all-spaces';

export const useGetSpaceRolePermission = (spaceId?: string) => {
  const spaces = useSpaces();
  const currentSpace = useCurrentSpace();

  const space = useMemo(() => {
    if (spaceId) {
      if (spaces[spaceId]) {
        return spaces[spaceId];
      }
      return currentSpace;
    }
    return currentSpace;
  }, [currentSpace, spaceId, spaces]);

  return useCallback(
    (userId: string) => {
      const userSpace = space?.permissions?.find((i) => i.userId === userId);

      return {
        reader: userSpace?.role === 'reader',
        writer: userSpace?.role === 'writer',
        editor: userSpace?.role === 'editor',
      };
    },
    [space?.permissions]
  );
};

export const getSpaceRolePermission = (userId: string) => {
  const currentSpace = getCurrentSpace();

  const userSpace = currentSpace.permissions?.find((i) => i.userId === userId);

  return {
    reader: userSpace?.role === 'reader',
    writer: userSpace?.role === 'writer',
    editor: userSpace?.role === 'editor',
  };
};
