import { MindMapContext } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { useCallback, useContext } from 'react';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { useNameSpace } from 'src/editor/editor/uikit/editable-context';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { makeEditorKey } from 'src/editor/editor/uikit/editable/helper';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { segmentsToText } from 'src/editor/utils/editor';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_EFFECTS } from 'src/redux/actions';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { convertBlock } from 'src/redux/managers/block/convert';
import { mergeBlock } from 'src/redux/managers/block/merge';
import { outdentBlock } from 'src/redux/managers/block/outdent';
import { dispatch, getState } from 'src/redux/store';
import type { SegmentType } from 'src/redux/types';
import { showCaption } from 'src/utils/block-type-utils';
import { getAllEditorInfoFromDomContent } from 'src/utils/block-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { getOwnerPage } from '../block/use-get-owner-page';

export const useBackspaceKey = (uuid: string, segmentType: SegmentType) => {
  const transaction = useTransaction();
  const isInRight = useIsInRight();
  const syncId = useSyncId();
  const focusEditableAt = useFocusEditableByBlockId();
  const getEditorModel = useGetOrInitEditorModel();
  const engine = useContext(MindMapContext);
  const namespace = useNameSpace();

  const backSpaceKey = (event: globalThis.KeyboardEvent) => {
    const { blocks } = getState();
    const pageId = getOwnerPage(uuid);
    if (!pageId) return;

    const block = blocks[uuid];
    if (!block) return;

    const parent = blocks[block.parentId];
    if (!parent) return;

    const selection = getEditorModel(uuid)?.selection;
    if (!selection) return;
    if (!selection.isCollapsed || selection.offset !== 0) return;
    event.preventDefault();
    event.stopPropagation();
    // 1. 不是 textarea 类型的则新转成 textarea 类型
    if (
      block.type !== BlockType.TEXTAREA &&
      block.type !== BlockType.CODE &&
      segmentType === 'segments'
    ) {
      transaction(() => {
        convertBlock([block.uuid], {
          type: BlockType.TEXTAREA,
        });
        dispatch(
          HISTORY_EFFECTS({
            init() {},
            redo() {
              focusEditableAt(block.uuid, 0);
            },
            undo() {
              focusEditableAt(block.uuid, 0);
            },
          })
        );
      });
      return;
    }

    // 折叠列表的唯一一个，需向上合并，不 outdent
    // 或者是模板按钮的倒数第一个，不outdent
    const lastFoldListItem =
      ([BlockType.FOLD_LIST, BlockType.TOGGLE_HEADER].includes(parent.type) &&
        parent.subNodes.length === 1 &&
        last(parent.subNodes) === uuid) ||
      (BlockType.TEMPLATE === parent.type && last(parent.subNodes) === uuid);

    // 2. 是 textarea 类型, 但不是根节点, 则 outdent
    if (
      parent.type !== BlockType.PAGE &&
      parent.type !== BlockType.COLUMN &&
      parent.type !== BlockType.SYNC_CONTAINER &&
      last(parent.subNodes) === uuid &&
      !lastFoldListItem &&
      segmentType === 'segments'
    ) {
      transaction(() => {
        outdentBlock([uuid]);
        dispatch(
          HISTORY_EFFECTS({
            init() {
              focusEditableAt(uuid, 0);
            },
            redo() {
              focusEditableAt(uuid, 0);
            },
            undo() {
              focusEditableAt(uuid, 0);
            },
          })
        );
      });
      return;
    }
    // 3. 否则与最近的一个可以编辑 block 合并
    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const index = editableList.findIndex((v) => v.blockId === uuid && v.syncId === syncId);
    // 如果是code block的caption按backspace，caption要合并到codeblock上
    const prev =
      editableList[block.type === BlockType.CODE && segmentType === 'caption' ? index : index - 1];
    if (prev && !engine) {
      // 脑图里的节点不合并
      const editorKey = makeEditorKey(prev.blockId, namespace, prev.syncId);
      const editorModel = getEditorModelByEditorKey(editorKey);
      transaction(() => {
        const preBlockFocusType: SegmentType = showCaption(blocks[prev.blockId])
          ? 'caption'
          : 'segments';
        mergeBlock(block.uuid, prev.blockId);
        dispatch(
          HISTORY_EFFECTS({
            init() {
              focusEditableAt(prev.blockId, editorModel?.content.length || 0, preBlockFocusType, {
                syncId: prev.syncId,
              });
            },
            redo() {
              focusEditableAt(prev.blockId, editorModel?.content.length || 0, preBlockFocusType, {
                syncId: prev.syncId,
              });
            },
            undo() {
              focusEditableAt(uuid, 0, segmentType);
            },
          })
        );
      });
      return;
    }

    // 5. 如果前一个可编辑块不存在, 则和标题合并
    if (parent.type === BlockType.PAGE && segmentType === 'segments') {
      const originTitle = segmentsToText(parent.data.segments);
      transaction(() => {
        mergeBlock(block.uuid, parent.uuid);
        dispatch(
          HISTORY_EFFECTS({
            init() {
              focusEditableAt(parent.uuid, originTitle.length);
            },
            redo() {
              focusEditableAt(parent.uuid, originTitle.length);
            },
            undo() {
              focusEditableAt(uuid, 0);
            },
          })
        );
      });
    }
    // 如果是同步块且前一个可编辑块不存在，则删掉同步块内的最后那个块
    if (parent.type === BlockType.SYNC_CONTAINER) {
      transaction(() => {
        archiveBlock(block.uuid);
      });
    }
  };

  return useCallback(backSpaceKey, [
    engine,
    focusEditableAt,
    getEditorModel,
    isInRight,
    namespace,
    segmentType,
    syncId,
    transaction,
    uuid,
  ]);
};
