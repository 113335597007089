import type { FC } from 'react';
import { createContext, useContext } from 'react';
import { ReadonlyContext } from 'src/hooks/block/use-block-locked';
const FixWidthContext = createContext(false);
export const useIsInFixWidthContext = () => {
  return useContext(FixWidthContext);
};

/** 由于需要节点真实宽度， 所以只能再屏幕外渲染另一个相同的节点，如果有更好的方案请务必告诉我 */
export const FixWidthContextProvider: FC = (props) => {
  return (
    <FixWidthContext.Provider value={true}>
      <ReadonlyContext.Provider value={true}>{props.children}</ReadonlyContext.Provider>
    </FixWidthContext.Provider>
  );
};
