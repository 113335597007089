import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import React from 'react';
import { ToolbarButton } from 'src/editor/editor/uikit/editable-toolbar/button';

interface Props {
  className?: string;
  style?: CSSProperties;
  customButton?: React.ReactNode;
  showType: 'default' | 'small' | 'large';
  onClick: (showType?: 'default' | 'small' | 'large') => void;
}
export const FloatToolbar: FC<Props> = (props) => {
  return (
    <div className={cx('flex p-[2px] disable-select', props.className)} style={props.style}>
      {props.customButton}
      <ToolbarButton
        title={'默认样式'}
        className="px-0"
        boxClassName="px-0 py-0"
        icon="IcMenuViewNormal"
        active={props.showType === 'default'}
        onClick={() => {
          props.onClick('default');
        }}
      />
      <ToolbarButton
        title={'小卡片样式'}
        className="px-0 ml-0.5"
        boxClassName="px-0 py-0"
        icon="IcMenuViewCard"
        active={props.showType === 'small'}
        onClick={() => {
          props.onClick('small');
        }}
      />
      <ToolbarButton
        title={'大卡片样式'}
        className="px-0 ml-0.5"
        boxClassName="px-0 py-0"
        icon="IcMenuViewPreview"
        active={props.showType === 'large'}
        onClick={() => {
          props.onClick('large');
        }}
      />
    </div>
  );
};
