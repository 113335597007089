import {
  useAlreadyLayout,
  useMindMapEngine,
  useMindMapOffset,
  useMindMapScale,
  useRootMindMapNode,
} from '@flowus/mind-map';
import { debounce } from 'lodash-es';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { EmbedContext } from 'src/hooks/block/use-block-locked';
import { useIsDarkMode } from 'src/hooks/public/use-theme';
import { usePressedSpace } from 'src/services/app';
import { useGetPageId } from 'src/utils/getPageId';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { useMindMapShortcut } from '../hook/use-mind-map-short-cut';
import { getMindMapScrollInfo, saveMindMapScrollInfo } from '../utils/mind-engine-utils';

type Props = React.HTMLProps<HTMLDivElement> & { id: string };

export const MindMapContainer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const relativeDivRef = useRef<HTMLDivElement>(null);
  const scale = useMindMapScale();
  const pageId = useGetPageId();
  const pageScrollRef = usePageScrollRef();
  const { offsetX, offsetY } = useMindMapOffset();
  const rootNode = useRootMindMapNode();
  const mindMapShortcut = useMindMapShortcut();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => containerRef.current!, []);
  const scalePercent = (scale * 1.0) / 100;
  const alreadyLayout = useAlreadyLayout();
  const engine = useMindMapEngine();
  const isEmbedMindMap = useContext(EmbedContext);

  const isDark = useIsDarkMode();
  const pressedSpace = usePressedSpace();

  useEffect(() => {
    engine.setLineColor(isDark ? '#b8b8b8' : '#000000');
  }, [engine, isDark]);

  useEffect(() => {
    if (!rootNode?.id) return;
    const scroller = pageScrollRef.current;
    if (!scroller) return;
    // 记录滚动的参数，下次进来还原
    const onScroll = debounce(() => {
      // some time scrollTop is zero,I don't know why
      if (scroller.scrollTop === 0) return;
      saveMindMapScrollInfo(rootNode.id, isEmbedMindMap, {
        left: scroller.scrollLeft,
        top: scroller.scrollTop,
      });
    }, 500);
    scroller.addEventListener('scroll', onScroll);
    return () => {
      scroller.removeEventListener('scroll', onScroll);
    };
  }, [isEmbedMindMap, pageScrollRef, rootNode?.id]);

  useEffect(() => {
    if (alreadyLayout && rootNode?.id) {
      const scrollInfo = getMindMapScrollInfo(rootNode?.id, isEmbedMindMap) ?? undefined;
      scrollInfo && pageScrollRef.current?.scroll(scrollInfo);
      // 二次矫正
      setTimeout(() => {
        scrollInfo && pageScrollRef.current?.scroll(scrollInfo);
      }, 500);
      if (!scrollInfo) {
        engine.fitCenter();
        setTimeout(() => {
          // 有时候加载慢这里需要再对齐一下
          engine.fitCenter();
        }, 200);
      }
    }
  }, [engine, alreadyLayout, isEmbedMindMap, rootNode?.id, pageScrollRef]);

  useEffect(() => {
    if (!pageScrollRef.current) return;
    const scroller = pageScrollRef.current;
    scroller.style.cursor = pressedSpace ? 'grab' : '';
  }, [pageScrollRef, pressedSpace]);

  useEffect(() => {
    const isMindMapPage = pageId === props.id;
    if (!containerRef.current) return;
    const container = isMindMapPage ? document : containerRef.current;
    const onKeyDownCapture = (e: Event) => {
      if (e instanceof KeyboardEvent) {
        mindMapShortcut(e);
      }
    };
    container.addEventListener('keydown', onKeyDownCapture);
    return () => {
      container.removeEventListener('keydown', onKeyDownCapture);
    };
  }, [mindMapShortcut, pageId, props.id]);
  let width = undefined;
  if (rootNode?.regionWidth) {
    width = rootNode?.regionWidth + 100; // 100是为了宽度大一点，要不然最右边的节点编辑时会因为超出宽度而换行
  }
  return (
    <article
      tabIndex={-1} // 有这个才会focus，才能监听按键
      ref={containerRef}
      // data-disable-select={true} //加上这个才不会有内嵌框选问题
      className="w-full block-content "
    >
      <div
        ref={relativeDivRef}
        className="flex relative node-drawer w-full"
        style={{
          transformOrigin: `center`,
          transform: `scale(${scalePercent},${scalePercent})`, // translate(${offsetX}px,${offsetY}px)
          left: offsetX,
          top: offsetY,
          width,
          height: rootNode?.regionHeight || undefined,
          opacity: alreadyLayout ? undefined : 0,
        }}
      >
        {props.children}
      </div>
    </article>
  );
});
