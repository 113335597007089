import { BlockStatus } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { useCallback } from 'react';
import { CREATE_BLOCK, LIST_AFTER_BLOCK } from 'src/redux/actions';
import * as CollectionViewManager from 'src/redux/managers/collection-view';
import { dispatch, getState } from 'src/redux/store';
import type { SelectBlock } from 'src/redux/types';
import { checkIsMaximumCapacityDialog } from 'src/services/capacity/hook';
import { $currentUserCache } from 'src/services/user/current-user';
import { sequence } from 'src/utils/async-utils';
import { selectedBlocksToIds } from 'src/utils/select-block-util';
import { v4 as uuidV4 } from 'uuid';
import { useTransaction } from '../use-transaction';
import { useCheckCopyRecord } from './use-check-copy';
import { useCopySubNodesApi } from './use-copy-sub-nodes-api';

interface CopyIDMap {
  uuid: string;
  targetId: string;
  syncId?: string;
}

export const useCopyRecord = () => {
  const checkCopyRecord = useCheckCopyRecord();
  const transaction = useTransaction();
  const copySubNodesApi = useCopySubNodesApi();

  const copyRecord = (
    viewId: string,
    recordBlocks: SelectBlock[],
    options?: {
      after?: string;
      effects?: (ids: SelectBlock[]) => void;
    }
  ) => {
    checkIsMaximumCapacityDialog();
    const newRecordIds: CopyIDMap[] = [];

    let after = options?.after;
    if (!options?.after) {
      after = last(recordBlocks)?.blockId;
    }

    const check = checkCopyRecord(selectedBlocksToIds(recordBlocks));

    if (!check.status) {
      return;
    }

    transaction(
      () => {
        recordBlocks.reduce((after, recordBlock) => {
          const newId = copy(viewId, recordBlock, after);
          newRecordIds.push({
            uuid: recordBlock.blockId,
            targetId: newId,
            syncId: recordBlock.syncId,
          });
          return newId;
        }, after);

        options?.effects?.(
          newRecordIds.map((obj) => {
            return { blockId: obj.targetId, syncId: obj.syncId, viewId };
          })
        );
      },
      { noThrottle: true }
    );

    void sequence(() => copySubNodesApi({ list: newRecordIds }));
  };

  return useCallback(copyRecord, [checkCopyRecord, copySubNodesApi, transaction]);
};

const copy = (viewId: string, recordBlock: SelectBlock, after?: string): string => {
  const { blocks, collectionViews } = getState();

  const block = blocks[recordBlock.blockId];
  const collection = blocks[block?.parentId ?? ''];
  const view = collectionViews[viewId];
  if (!block || !collection || !view) return '';

  const data = { ...block.data };

  // 如果没设置过这个，需要设置默认值为true，弥补之前的漏缺
  if (typeof data.pageFixedWidth !== 'boolean') {
    data.pageFixedWidth = true;
  }

  const newRecordId = uuidV4();
  const newRecord = {
    ...block,
    uuid: newRecordId,
    data,
    version: 0,
    status: BlockStatus.NORMAL,
    subNodes: [],
    permissions: [],
    createdBy: block.createdBy,
    createdAt: block.createdAt,
    updatedBy: $currentUserCache.uuid,
    updatedAt: Date.now(),
  };

  // pageSort 排序
  const pageSort = [...view.pageSort];
  if (after === 'head') {
    pageSort.unshift(newRecord.uuid);
  } else if (after === 'tail') {
    const sortedSet = new Set(pageSort);
    for (const id of collection.subNodes) {
      if (!sortedSet.has(id)) {
        pageSort.push(id);
      }
    }
    pageSort.push(newRecord.uuid);
  } else {
    const index = view.pageSort.indexOf(after ?? '');
    if (index > -1) {
      pageSort.splice(index + 1, 0, newRecord.uuid);
    } else {
      const sortedSet = new Set(pageSort);
      for (const id of collection.subNodes) {
        if (!sortedSet.has(id)) {
          pageSort.push(id);
        }
        if (id === after) {
          pageSort.push(newRecord.uuid);
          break;
        }
      }
    }
  }

  dispatch(CREATE_BLOCK({ block: newRecord, local: false }));
  dispatch(LIST_AFTER_BLOCK({ uuid: newRecord.uuid, parentId: block.parentId, after }));
  CollectionViewManager.update(viewId, {
    pageSort,
  });

  return newRecordId;
};
