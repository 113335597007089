import { cx } from '@flowus/common/cx';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { KeyboardShortcut } from 'src/components/keyboard-shortcut';

// 8 = 32px, 9 = 36px
const HEIGHT = 'h-9';

// #region ToolbarButton
interface ToolbarButtonProps {
  title?: any;
  icon?: IconName;
  iconClass?: string;
  label?: ReactNode;
  disabled?: boolean;
  highlight?: boolean;
  labelBorder?: boolean;
  arrow?: boolean;
  className?: string;
  boxClassName?: string;
  active?: boolean;
  onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
  id?: string;
}
export const ToolbarButton: FC<ToolbarButtonProps> = (props) => {
  const {
    title,
    icon,
    iconClass,
    label,
    labelBorder,
    disabled,
    highlight,
    arrow,
    className,
    boxClassName,
    active,
    onClick,
    id,
  } = props;
  return (
    <Action
      id={id}
      title={title}
      disabled={disabled}
      className={cx((label || arrow) && 'px-[7px] !w-auto', className)}
      boxClassName={boxClassName}
      active={active}
      onClick={(event) => {
        if (disabled) return;
        // NOTE: currentTarget of event.nativeElement is wrong.
        onClick?.(event as any);
      }}
    >
      {icon && (
        <Icon
          name={icon}
          size="small"
          className={cx('w-4', iconClass, highlight ? 'text-active_color' : 'text-black')}
        />
      )}
      {label && (
        <span
          className={cx(
            'text-t3 border-grey4 leading-[14px]',
            labelBorder && 'border-b',
            highlight ? 'text-active_color' : 'text-black'
          )}
        >
          {label}
        </span>
      )}
      {arrow && <Icon size="auto" name="IcArrowDown01" className="ml-[6.75px] w-[9px] h-[9px]" />}
    </Action>
  );
};
// #endregion

// #region Action
interface ActionProps {
  more?: boolean;
  disabled?: boolean;
  title?: ReactNode;
  boxClassName?: string;
  active?: boolean;
}
const Action: FC<ActionProps & JSX.IntrinsicElements['span']> = memo((props) => {
  const { title, more, children, disabled, boxClassName, active, ...otherProps } = props;
  return (
    <Tooltip className={cx(boxClassName)} popup={title}>
      <span
        {...otherProps}
        className={cx(
          HEIGHT,
          'flex items-center justify-center w-[30px] whitespace-nowrap rounded-none',
          !disabled ? 'animate-hover animate-click' : 'cursor-not-allowed opacity-40',
          { 'bg-black_006': active },
          props.className
        )}
      >
        {children}
        {more && <Icon name="IcArrowDown01" size="xxxsmall" className="ml-1.5" />}
      </span>
    </Tooltip>
  );
});
// #endregion

// #region tooltipShortcut
export const tooltipShortcut = (title: string, shortcut: string) => (
  <div className="flex flex-col justify-center items-center">
    <div>{title}</div>
    <KeyboardShortcut className="text-center" shortcut={shortcut} />
  </div>
);
// #endregion
