import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';

export const KeyboardShortcut: FC<{ className?: string; shortcut: string }> = (props) => {
  const { className, shortcut } = props;
  return (
    <span className={cx('text-grey3 text-t4-medium', className)}>
      {ShortcutSystemSymbol}+{shortcut}
    </span>
  );
};
