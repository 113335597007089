import type { BlockSearchDTO } from '@next-space/fe-api-idl';
import { request } from 'src/common/request';
import type { FileType, SearchType } from 'src/components/search';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch, getState } from 'src/redux/store';
import type { NextBlock } from 'src/redux/types';
import { isPageLike } from 'src/utils/block-type-utils';
import { getCurrentSpaceId } from '../space/get-space';
import { useDispatch, useStore } from 'react-redux';

export interface SearchInfo {
  spaceId?: string;
  pageNo: number;
  search: string;
  perPage: number;
  fileType?: FileType;
  type?: SearchType;
  source?: 'move' | 'trash' | 'quickFind' | 'collectionSettingsSource' | 'relationMenu'; // collectionSettingsSource => 搜索多维表，relationMenu => 搜索指定 parent 下面的记录
  parentId?: string;
}

/**
 * 搜索的逻辑如下，憋问我为啥这么搞，问就是notion也是这样的
 * https://alpha.allflow.cn/81f0cc6d-ee44-4fc7-889d-357d3c3ea08d
 */
export const useSearchBlock = () => {
  const dispatch = useDispatch();
  const searchBlockInner = async (searchInfo: SearchInfo, updateBlocks = true) => {
    const {
      spaceId = getCurrentSpaceId(),
      pageNo,
      perPage,
      search,
      fileType,
      type,
      source,
      parentId,
    } = searchInfo;
    const res = await request.editor.searchDocs(
      spaceId,
      pageNo,
      perPage,
      search,
      fileType,
      type,
      source,
      parentId
    );

    const { blocks } = res.recordMap ?? {};
    if (!blocks) return { hasMore: false, result: [], total: res.total ?? 0 };
    const getNavs = (uuid: string) => {
      const navs = [];
      let curr = blocks[uuid];

      while (curr && curr.spaceId !== curr.uuid) {
        curr = blocks[curr.parentId];
        if (curr && isPageLike(curr.type)) {
          navs.unshift(curr);
        }
      }
      return navs;
    };

    if (updateBlocks) {
      dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
    }

    const resultBlocks = res.results
      .map((item) => {
        const block = blocks[item.uuid];
        if (!block) return undefined;

        return {
          block,
          coverUrl: item.coverUrl,
          navs: getNavs(item.uuid),
        } as BlockSearchDTO;
      })
      .filter((item): item is BlockSearchDTO => !!item);
    return {
      // @ts-ignore idl error
      hasMore: res.more as boolean,
      result: resultBlocks,
      total: res.total ?? 0,
    };
  };
  const searchBlock = async (searchInfo: SearchInfo, updateBlocks = true) => {
    let hasMore = true;
    const resultBlocks: BlockSearchDTO[] = [];
    let count = 0;
    const maxCount = 10; // 最大10次请求
    let total = 0;
    do {
      const ret = await searchBlockInner(
        { ...searchInfo, pageNo: searchInfo.pageNo + count },
        updateBlocks
      );
      hasMore = ret.hasMore;
      resultBlocks.push(...ret.result);
      count++;
      total = ret.total;
    } while (hasMore && resultBlocks.length < searchInfo.perPage && count < maxCount);
    return {
      hasMore,
      total,
      newPageNo: searchInfo.pageNo + count - 1, // 这个是多次请求后需要修正的pageNo
      resultBlocks,
    };
  };

  return searchBlock;
};

// 这块代码需要抽出一个通用的
export const useGetTrashBlock = () => {
  const getTrashBlockInner = async (
    spaceId: string,
    pageNo: number,
    perPage: number,
    updateBlocks = true
  ) => {
    const res = await request.infra.getSpaceTrash(spaceId, pageNo, perPage);

    const blocks = res.recordMap?.blocks;
    if (!blocks) return { hasMore: false, result: [], total: res.total ?? 0 };
    if (updateBlocks) {
      dispatch(blocksActions.update({ blocks: blocks as Record<string, NextBlock> }));
    }

    const resultBlocks = res.results
      .map((item) => {
        const block = blocks[item.uuid];
        if (!block) return undefined;
        block.deletedAt = item.deletedAt;

        return {
          block,
          coverUrl: item.coverUrl,
          navs: getNavs(blocks as Record<string, NextBlock>, item.uuid),
        } as BlockSearchDTO;
      })
      .filter((item): item is BlockSearchDTO => !!item);
    return {
      // @ts-ignore idl error
      hasMore: res.more as boolean,
      result: resultBlocks,
      total: res.total ?? 0,
    };
  };
  const getTrashBlock = async (
    spaceId: string,
    pageNo: number,
    perPage: number,
    updateBlocks = true
  ) => {
    let hasMore = true;
    const resultBlocks: BlockSearchDTO[] = [];
    let count = 0;
    const maxCount = 10; // 最大10次请求
    let total = 0;
    do {
      const ret = await getTrashBlockInner(spaceId, pageNo + count, perPage, updateBlocks);
      hasMore = ret.hasMore;
      resultBlocks.push(...ret.result);
      count++;
      total = ret.total;
    } while (hasMore && resultBlocks.length < perPage && count < maxCount);
    return {
      hasMore,
      total,
      newPageNo: pageNo + count - 1, // 这个是多次请求后需要修正的pageNo
      resultBlocks,
    };
  };

  return getTrashBlock;
};

export const getNavs = (blocks = getState().blocks, uuid: string) => {
  const navs = [];
  let curr = blocks[uuid];

  while (curr && curr.spaceId !== curr.uuid) {
    curr = blocks[curr.parentId];
    if (curr && isPageLike(curr.type)) {
      navs.unshift(curr);
    }
  }
  return navs;
};
