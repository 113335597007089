import type { FC } from 'react';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { SettingModalCommon } from '../common';
import { useCloseSettingModal } from '../use-open-setting-modal';
import { SpaceInfoAI } from './space-info-ai';
import { SpaceInfoAvatar } from './space-info-avatar';
import { SpaceInfoCapacity } from './space-info-capacity';
import { SpaceInfoMembers } from './space-info-members';
import { SpaceInfoPlan } from './space-info-plan';
import { SpaceIncome } from './space-income';

interface Props {}
export const SpaceInfoSetting: FC<Props> = () => {
  const closeSettingModal = useCloseSettingModal();
  const { enableAI } = useEnableAI();

  return (
    <>
      <div className={SettingModalCommon.commonStyles.container}>
        <SettingModalCommon.Header title="空间信息" />
        <div className="py-2.5">
          <SpaceInfoAvatar />
          <SpaceInfoPlan />
          {!__PRIVATE__ && <SpaceIncome />}
          <SpaceInfoCapacity />
          <SpaceInfoMembers />
          {enableAI && <SpaceInfoAI />}
        </div>
      </div>
      <SettingModalCommon.FooterBar cancelText="关闭" cancel={() => closeSettingModal()} />
    </>
  );
};
