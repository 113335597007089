import type { FC } from 'react';

import { useLogin } from '../../../hook/use-login';
import { Button } from '../../../uikit/web/button';
import { Input } from '../../../uikit/web/input';
import { md5Sum, Regex } from '../../../utils';
import { loginInjector } from '../../../utils/init';

interface VerifyPasswordProps {
  userId: string;
  onSuccess: (authorizationCode: string) => void;
}
export const VerifyPasswordCode: FC<VerifyPasswordProps> = (props) => {
  const { userId, onSuccess } = props;
  const { password, setPassword, passwordError, setPasswordError } = useLogin();

  const tryVerifySmsCode = async () => {
    if (!Regex.Password.test(password)) {
      setPasswordError('密码不对');
      return;
    }
    const res = await loginInjector.request.infra.passwordAuthorization.raw({
      uuid: userId,
      password: md5Sum(password),
    });
    if (res.code === 200) {
      if (res.data.authorizationCode) {
        onSuccess(res.data.authorizationCode);
        setPasswordError('验证失败');
      }
    } else {
      setPasswordError(res.msg);
    }
  };

  return (
    <div className="next-modal pt-10 pb-5 px-7 flex flex-col items-center min-w-[400px]">
      <div className="mb-5 text-t1-medium">验证登录密码</div>
      <Input
        autoFocus
        inputType="password"
        value={password}
        className="mb-10 w-full"
        placeholder="请输入当前登录密码"
        inputClassName="px-3 h-8 text-t2"
        onEnter={() => void tryVerifySmsCode()}
        warning={passwordError}
        onChange={(v) => setPassword(v.toString())}
      />
      <Button
        disable={!password}
        className="text-t2 h-8"
        size="auto"
        onClick={() => void tryVerifySmsCode()}
      >
        下一步
      </Button>
    </div>
  );
};
