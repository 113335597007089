import { CountDown } from '@flowus/common/components';
import { cx } from '@flowus/common/cx';
import type { CaptchaSendType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { request } from 'src/common/request';
import { isBuildIn } from 'src/env';

interface CaptchaCompType {
  onError?: (msg: string) => void;
  onClick?: () => void;
  autoClick?: boolean;
  sendCode: () => Promise<void>;
  className?: string;
}

/** 验证码倒计时组件 */
export const CaptchaComp: FC<CaptchaCompType> = ({
  onClick,
  onError,
  autoClick,
  sendCode,
  className,
}) => {
  const [isReady, setIsReady] = useState(false);
  const [start, setStart] = useState(false);
  const [buttonText, setButtonText] = useState('获取验证码');
  const disabled = useRef(false);

  const sendVerifyCode = useCallback(async () => {
    onClick?.();
    if (!disabled.current) {
      disabled.current = true;
      try {
        await sendCode();
        setStart(true);
        setButtonText('重新获取验证码');
      } catch (error: any) {
        // eslint-disable-next-line require-atomic-updates
        disabled.current = false;
        onError?.(error.message);
      }
    }
  }, [onClick, onError, sendCode]);

  const onFinish = useCallback(() => {
    setStart(false);
    disabled.current = false;
  }, []);

  useEffect(() => {
    if (autoClick) {
      void sendVerifyCode().then(() => setIsReady(true));
    } else {
      setIsReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      className={cx(
        'flex items-center w-full h-full font-normal text-black text-t4',
        { hidden: !isReady },
        className
      )}
      type="button"
      onClick={sendVerifyCode}
    >
      {start ? (
        <CountDown time={60} className="text-grey3" prefix="倒计时" onFinish={onFinish} />
      ) : (
        <>
          {buttonText}
          <Icon
            className="ml-1 animate__animated animate__headShake"
            name="IcLoginCode"
            size="xxsmall"
          />
        </>
      )}
    </button>
  );
};

/** 验证手机号 */
export const CaptchaPhone: FC<{
  className?: string;
  account: string;
  type?: CaptchaSendType;
  onError?: (msg: string) => void;
  onClick?: () => void;
  autoClick?: boolean;
}> = (props) => {
  const { account, type = 0, ...reset } = props;

  const sendCode = async () => {
    if (isBuildIn()) {
      await request.infra.emailCaptcha({
        email: account,
        type,
      });
    } else {
      await request.infra.createPhoneCaptcha(account, { type });
    }
  };

  return <CaptchaComp sendCode={sendCode} {...reset} />;
};

/** 验证邮箱 */
export const CaptchaEmail: FC<{
  className?: string;
  email: string;
  onError?: (msg: string) => void;
  onClick?: () => void;
  autoClick?: boolean;
}> = (props) => {
  const { email: _email, ...reset } = props;

  const sendCode = async () => {
    // await request.infra.email(email);
  };

  return <CaptchaComp sendCode={sendCode} {...reset} />;
};
