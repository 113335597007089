import type { FC } from 'react';
import { cx } from '../../cx';
import type { IconProp } from '../../icon-svg/pc-icon';

interface IconLoadingProps extends Omit<IconProp, 'name' | 'size'> {
  size?: 'middle' | 'max' | 'large' | 'small';
}

const sizeStyle: Record<string, string> = {
  middle: '0.5',
  max: '1',
  large: '0.75',
  small: '0.25',
};

export const LoadingIcon: FC<IconLoadingProps> = ({ className, size = 'max', style, ...args }) => {
  return (
    <span
      className={cx('loader-icon', className)}
      style={{ ...style, zoom: sizeStyle[size] }}
      {...args}
    />
  );
};
