import dict_combo from 'pinyin/data/dict-zi-web';
import Pinyin from 'pinyin/lib/pinyin';

// 解压拼音库。
// @param {Object} dict_combo, 压缩的拼音库。
// @param {Object} 解压的拼音库。
function buildPinyinCache(dict_combo) {
  let hans;
  const uncomboed = {};

  for (const py in dict_combo) {
    hans = dict_combo[py];
    for (let i = 0, han, l = hans.length; i < l; i++) {
      han = hans.charCodeAt(i);
      if (!Object.prototype.hasOwnProperty.call(uncomboed, han)) {
        uncomboed[han] = py;
      } else {
        uncomboed[han] += `,${py}`;
      }
    }
  }

  return uncomboed;
}

export const PINYIN_DICT = buildPinyinCache(dict_combo);

export const pinyin = new Pinyin(PINYIN_DICT);

export default pinyin.convert.bind(pinyin);
export const compare = pinyin.compare.bind(pinyin);
// export const compact = pinyin.compact.bind(pinyin);

export const { STYLE_NORMAL } = Pinyin;
export const { STYLE_TONE } = Pinyin;
export const { STYLE_TONE2 } = Pinyin;
export const { STYLE_TO3NE } = Pinyin;
export const { STYLE_INITIALS } = Pinyin;
export const { STYLE_FIRST_LETTER } = Pinyin;
