import { cx } from '@flowus/common/cx';
import { imageGalleryClassNames, imageGalleryStyle } from '@flowus/common/style';
import type { FC } from 'react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ILLEGAL_TEXT } from 'src/common/const';
import { useVisible } from 'src/common/hooks/use-visible';
import { useTryFetchPageBlock } from 'src/components/page-preview/use-try-fetch-page-block';
import { ThumbPageCardDescContent } from 'src/editor/component/page-block';
import { segmentsToText } from 'src/editor/utils/editor';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScene, PageSceneContext } from 'src/views/main/scene-context';
import { BackgroundContainer } from './background-container';
import { getImageBlocks } from './get-image-block-util';
import { ImageBlockElement } from './image-block-render';
import type { PageProps } from './types';
import { useCoverImageUrl } from './use-cover-image-url';

export const LargeThumbImagesCard: FC<PageProps> = (props) => {
  const { id, illegal, showCover, desc, isInColumn } = props;
  const hasCover = !!useCoverImageUrl(id) && showCover;
  const [visible, setRef] = useVisible<HTMLDivElement>();
  const loading = useTryFetchPageBlock(id, visible);
  const block = usePickBlock(id, ['subNodes', 'data'], ['segments']);
  const divRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>();
  const title = illegal ? ILLEGAL_TEXT : segmentsToText(block?.data.segments);

  const [imageIds, setImageIds] = useState<string[]>([]);
  let attribute = {};
  if (isInColumn) {
    attribute = {
      'data-column-item-min-width': '250px',
    };
  }
  useEffect(() => {
    if (loading) return;
    const ids = getImageBlocks(id);
    setImageIds(ids);
  }, [id, loading]);

  useLayoutEffect(() => {
    if (!visible || loading) return;

    const footer = divRef.current?.querySelector('.footer');
    if (footer instanceof HTMLElement) {
      footer.hidden = true;
    }
    const pageContainer =
      divRef.current?.querySelector(`#${ID_DRIVE}`) ??
      divRef.current?.querySelector('.next-space-page');
    if (pageContainer instanceof HTMLElement) {
      pageContainer.childNodes.forEach((c) => {
        if (c instanceof HTMLElement) {
          c.setAttribute('style', `padding-left:12px;padding-right:12px`);
        }
      });
    }
  }, [loading, visible]);
  // 显示说明文字的ui跟不显示说明文字的ui布局不一样
  if (desc || isInColumn) {
    return (
      <BackgroundContainer
        blockId={id}
        ref={(ref) => {
          setRef(ref);
          if (ref != null) {
            container.current = ref;
          }
        }}
        showCover={showCover}
        {...attribute}
        className={cx('overflow-hidden flex relative w-[400px] h-[184px] animate-hover border', {
          'w-full': isInColumn,
        })}
        onClick={(e) => {
          const selection = window.getSelection();
          if (!selection) return;
          if (selection.isCollapsed) {
            props.onClick?.(e);
          }
        }}
      >
        <ThumbPageCardDescContent
          title={title}
          blockType={block?.type}
          desc={desc}
          hasCover={hasCover}
        />
        <ImageGallery2 ids={imageIds} className="ml-4 mt-10" />
      </BackgroundContainer>
    );
  }

  return (
    <BackgroundContainer
      blockId={id}
      ref={(ref) => {
        setRef(ref);
        if (ref != null) {
          container.current = ref;
        }
      }}
      showCover={showCover}
      {...attribute}
      className={cx(
        'overflow-hidden flex flex-col relative w-[240px] h-[184px] animate-hover border',
        {
          'w-full': isInColumn,
        }
      )}
      onClick={(e) => {
        const selection = window.getSelection();
        if (!selection) return;
        if (selection.isCollapsed) {
          props.onClick?.(e);
        }
      }}
    >
      <div
        className={cx('text-ellipsis ml-4 mt-4 text-t1-bold text-black', {
          'text-white': hasCover,
        })}
      >
        {title || getUntitledName(block?.type)}
      </div>
      <ImageGallery1 ids={imageIds} className="ml-4 mt-4" />
    </BackgroundContainer>
  );
};

const ImageGallery2: FC<{ ids: string[]; className?: string }> = ({ ids, className }) => {
  const id1 = ids[0];
  const id2 = ids[1];
  const id3 = ids[2];
  return (
    <PageSceneContext.Provider value={PageScene.ONLY_IMAGE}>
      <div className={cx('relative', className)}>
        <div className={imageGalleryClassNames.id1} style={{ ...imageGalleryStyle }}>
          {id1 && <ImageBlockElement uuid={id1} />}
        </div>
        <div className={imageGalleryClassNames.id2} style={{ ...imageGalleryStyle }}>
          {id2 && <ImageBlockElement uuid={id2} />}
        </div>
        <div className={imageGalleryClassNames.id3} style={{ ...imageGalleryStyle }}>
          {id3 && <ImageBlockElement uuid={id3} />}
        </div>
      </div>
    </PageSceneContext.Provider>
  );
};
/** 傻逼外包设计搞2套ui */
const ImageGallery1: FC<{ ids: string[]; className?: string }> = ({ ids, className }) => {
  const id1 = ids[0];
  const id2 = ids[1];
  const id3 = ids[2];
  return (
    <PageSceneContext.Provider value={PageScene.ONLY_IMAGE}>
      <div className={cx('relative', className)}>
        <div
          className="absolute rounded-sm left-0 top-0 w-[150px] h-[100px] overflow-hidden"
          style={{
            ...imageGalleryStyle,
            transform: 'matrix(0.99, -0.12, 0.15, 0.99, 0, 0)',
            zIndex: 3,
          }}
        >
          {id1 && <ImageBlockElement uuid={id1} />}
        </div>
        <div
          className="absolute rounded-sm left-[50px] top-[56px] w-[150px] h-[100px] overflow-hidden"
          style={{
            ...imageGalleryStyle,
            transform: 'matrix(0.97, -0.25, 0.31, 0.95, 0, 0)',
            zIndex: 2,
          }}
        >
          {id2 && <ImageBlockElement uuid={id2} />}
        </div>
        <div
          className="absolute rounded-sm left-[104px] top-[22px] w-[150px] h-[100px] overflow-hidden"
          style={{
            ...imageGalleryStyle,
            transform: 'matrix(1, -0.07, 0.09, 1, 0, 0)',
            zIndex: 1,
          }}
        >
          {id3 && <ImageBlockElement uuid={id3} />}
        </div>
      </div>
    </PageSceneContext.Provider>
  );
};
