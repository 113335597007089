export const move = (array: unknown[], fromIndex: number, toIndex: number) => {
  if (fromIndex === toIndex) return;
  const values = array.splice(fromIndex, 1);
  array.splice(toIndex, 0, values[0]);
};

/** 简单的数组对象去重 */
export function removeDuplicateWith<T extends unknown>(array: T[], key: keyof T) {
  const result: T[] = [];
  array.forEach((item) => {
    if (!result.some((i) => i[key] === item[key])) {
      result.push(item);
    }
  });
  return result;
}
