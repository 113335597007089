import { useMindMapNode } from '@flowus/mind-map';
import isHotkey from 'is-hotkey';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { cache } from 'src/redux/store';
import { setAppUiState } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { supportCaption } from 'src/utils/block-type-utils';
import { findEditorModel } from '../utils/mind-node-util';

interface Props {
  container: React.RefObject<HTMLDivElement>;
}
/** 选中节点可直接编辑功能 */
export const HiddenTextarea: FC<Props> = (props) => {
  const selectedBlock = useObservableStore((state) => state.ui.selectedBlocks, [], {
    obsSelectBlocks: [{ all: true }],
  });
  const blockId = selectedBlock[0]?.blockId;
  const mindNode = useMindMapNode(blockId ?? '', ['left', 'top']);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const focusRef = useMonitorFocused(props.container);
  const onlyOneSelectedBlock = selectedBlock.length === 1;
  const getEditorModel = useGetOrInitEditorModel();
  const model = findEditorModel(selectedBlock[0], getEditorModel);
  const focusEditable = useFocusEditableByBlockId();
  const isComposition = useRef(false);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (onlyOneSelectedBlock && !focusRef.current) {
      // when input 'tab' to add new node, engine.update call will make blur so we need settimeout
      timer.current = setTimeout(() => {
        textareaRef.current?.focus();
      }, 100);
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [focusRef, onlyOneSelectedBlock, blockId]);

  if (!onlyOneSelectedBlock) return null;

  if (!mindNode) return null;
  // 把文字传递给富文本并focus
  const transformToEditable = () => {
    if (!textareaRef.current) return;
    const { value } = textareaRef.current;
    if ((value || isComposition.current) && model && !model.readonly) {
      setAppUiState({ $editingBlockId: blockId });
      textareaRef.current?.blur();
      textareaRef.current.value = '';
      model.performChange((ctx) => {
        ctx
          .select(0, model.content.length)
          .applyFormat({
            underline: false,
          })
          .replace(value)
          .collapse('end');
      });
      const support = supportCaption(cache.blocks[blockId ?? '']);
      focusEditable(blockId, value.length, support ? 'caption' : 'segments');
    }
  };
  let top = mindNode?.top;
  if (top !== undefined) {
    top += 15; // 微调输入法位置
  }
  return (
    <textarea
      data-editor={blockId}
      ref={textareaRef}
      onCompositionUpdate={() => {
        if (model?.readonly) return;
        setTimeout(() => {
          const text = textareaRef.current?.value;
          if (text) {
            model?.performChange((ctx) => {
              ctx.select(0, model.content.length).applyFormat({ underline: true }).replace(text);
            });
          }
        }, 0);
      }}
      onCompositionStart={() => {
        isComposition.current = true;
      }}
      onCompositionEnd={() => {
        transformToEditable();
        isComposition.current = false;
      }}
      onChange={() => {
        if (isComposition.current) return;
        transformToEditable();
      }}
      onKeyDown={(e) => {
        if (isComposition.current && isHotkey(['Backspace', 'Delete'], { byKey: true })(e)) {
          e.stopPropagation();
        }
      }}
      className="absolute opacity-0 hidden-textarea"
      style={{
        left: mindNode?.left ?? '-9999px',
        top: top ?? '-9999px',
      }}
    />
  );
};

const useMonitorFocused = (divRef: React.RefObject<HTMLElement>) => {
  const focusRef = useRef(false);
  useEffect(() => {
    const divContainer = divRef.current;
    if (!divContainer) return;
    const focusin = () => {
      // 要延迟一下，等doubleClick执行之后再设置这个值
      setTimeout(() => {
        focusRef.current = true;
      }, 100);
    };
    const focusout = () => {
      focusRef.current = false;
    };

    divContainer.addEventListener('focusin', focusin);
    divContainer.addEventListener('focusout', focusout);

    return () => {
      divContainer.removeEventListener('focusin', focusin);
      divContainer.removeEventListener('focusout', focusout);
    };
  }, [divRef]);
  return focusRef;
};
