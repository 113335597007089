import { isElectron, isMacOs } from 'react-device-detect';

export const getBizTrackerPlatform = () => {
  if (!isElectron) {
    return {
      x_device_type: 'web',
      x_platform: 'web',
      platform: 'web',
    };
  }

  return {
    x_device_type: 'desktop',
    x_platform: isMacOs ? 'mac' : 'windows',
    platform: isMacOs ? 'mac' : 'windows',
  };
};
