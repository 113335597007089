import { MindMappingLineStyle } from '@next-space/fe-api-idl';
import type { Immutable } from 'immer';
import type { MindMapEngine } from '../mind-map-engine';
import { OUT_SIDE_SCREEN } from '../mind-map-engine';
import type { CanvasStyle, Line, MindNode, MindNodeDragInfo, Rect, Where } from '../types';
import { intersectRect, roundRect, walk } from '../util';

export interface LayoutProviderOption {
  verticalMargin: number;
  horizontalMargin: number;
}

export abstract class BaseLayoutProvider {
  protected engine;
  protected opt;
  width = 0;
  height = 0;
  //视图实际的宽高
  viewportWidth = 0;
  viewportHeight = 0;
  round = 8;
  container: HTMLElement | undefined;
  scroller: HTMLElement | undefined;

  constructor(engine: MindMapEngine, opt: LayoutProviderOption) {
    this.engine = engine;
    this.opt = opt;
  }
  //计算，触发渲染
  abstract layout: (id: string) => void;
  /**计算节点区域宽高 */
  protected abstract computeNodeRegionValue: (nodeId: string) => void;

  /**获取节点到节点的line对象，根据line对象来画线 */
  protected abstract getLine: (node: Immutable<MindNode>, childNode: Immutable<MindNode>) => Line;
  /**画线 */
  protected abstract drawLine: (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => void;
  /**获取拖拽节点时的line对象 */
  protected abstract getDragLine: (
    dragXInCanvas: number,
    dragYInCanvas: number,
    nodeId: string,
    parentId: string
  ) => Line | undefined;
  /**获取拖拽节点时的rect */
  protected abstract getDragNodeRect: (id: string) => Rect | undefined;
  /**获取方向键指向的下一个节点 */
  abstract getNextNodeIdByArrowKeyboard: (
    arrow: 'left' | 'right' | 'up' | 'down',
    currentId: string
  ) => string | undefined;
  //获取导图区域的中心点
  protected abstract getMindMapCenterPos: () => [number, number] | undefined;

  /**居中 */
  fitCenter = () => {
    this.engine.computeNodesCoordinate();
    if (!this.scroller) {
      return;
    }
    const rect = this.scroller.getBoundingClientRect();
    const state = this.engine.getState();
    const root = state.mindNodes[state.rootId];
    if (!root) return;
    const centerPos = this.getMindMapCenterPos();
    if (!centerPos) return;
    const [rootCenterX, rootCenterY] = centerPos;

    const centerX = rect.left + rect.width / 2 - this.scroller.scrollLeft;
    const centerY = rect.top + rect.height / 2 - this.scroller.scrollTop;
    //计算需要滚动多少距离才能让rootNode居中
    const offsetX = rootCenterX - centerX;
    const offsetY = rootCenterY - centerY;
    this.scroller?.scrollTo(offsetX, offsetY);
  };

  updateViewPort = () => {
    const state = this.engine.getDraftState();
    const rootNode = state.mindNodes[state.rootId];
    const scaleFactor = state.scale * 0.01;
    if (rootNode && this.container) {
      this.viewportWidth = this.width * 2 + rootNode.regionWidth * scaleFactor - 100; //100是靠边缘时露出来的部分
      this.viewportHeight = this.height * 2 + rootNode.regionHeight * scaleFactor - 100;
      this.container.style.height = `${this.viewportHeight}px`;
      this.container.style.width = `${this.viewportWidth}px`;
      this.engine.graph.setCanvasSize(rootNode.regionWidth, rootNode.regionHeight);
    }
  };
  /**修复边缘的节点位置 */
  fixRegionHeight = () => {
    const state = this.engine.getDraftState();
    let top = 0;
    const rootNode = state.mindNodes[state.rootId];
    if (!rootNode) return;
    const { regionHeight } = rootNode;
    let bottom = regionHeight;
    if (bottom < 0) return;
    walk(
      this.engine,
      state.rootId,
      '',
      (id) => {
        const node = state.mindNodes[id];
        if (!node) return false;
        if (node.top < top) {
          top = node.top;
        }
        const nodeBottom = node.top + node.height;
        if (nodeBottom > bottom) {
          bottom = nodeBottom;
        }
        //如果没展开就不要遍历子节点
        if (!node.expanded) return true;
      },
      (id) => {
        if (top !== 0) {
          const node = state.mindNodes[id];
          if (!node) return false;
          node.top += Math.abs(top);
        }
      },
      true,
      0
    );
    if (top !== 0 || bottom !== regionHeight) {
      rootNode.regionHeight = rootNode.regionHeight + Math.abs(top) + (bottom - regionHeight);
      this.updateViewPort();
    }
  };

  private drawDragNodeRect = () => {
    const dragCanvasContext = this.engine.graph.getDragCanvasContext();
    if (!dragCanvasContext) return;
    const { dragInfo } = this.engine;
    if (!dragInfo) return;
    const { ids } = dragInfo;
    let lastRect: Rect | undefined = undefined;
    ids.forEach((id) => {
      const node = this.engine.getMindNode(id);
      if (!node) return;
      dragCanvasContext.save();
      dragCanvasContext.strokeStyle = '#18A0FB';
      dragCanvasContext.lineWidth = 2;
      dragCanvasContext.lineCap = 'butt';
      dragCanvasContext.setLineDash([6]);
      const rect = this.getDragNodeRect(id);
      if (!rect) return;
      if (lastRect) {
        if (intersectRect(lastRect, rect)) {
          return;
        }
      }
      lastRect = rect;
      roundRect(dragCanvasContext, rect.x, rect.y, rect.width, rect.height);
      dragCanvasContext.restore();
    });
  };

  private drawMindNodes = () => {
    const { rootId, lineStyle } = this.engine.getState();
    const lines = this.computedLines(rootId);
    const context = this.engine.graph.getCanvasContext();
    if (!context) return;
    lines.forEach((line) => {
      context.beginPath();
      if (lineStyle === MindMappingLineStyle.NORMAL) {
        this.drawLine(context, line);
      } else {
        this.drawBezierLine(context, line);
      }
      context.stroke();
    });
  };
  /**更新节点的宽高 */
  updateMindNodeSize = (id: string, width: number, height: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[id];
    if (!node) {
      return;
    }
    node.width = Math.floor(width);
    node.height = Math.floor(height);
  };
  abstract updateScrollerPos: (oldRegionWidth: number, oldRegionHeight: number) => void;

  getLineColor = (nodeId: string) => {
    //一层一层往上找，找不到再用全局的
    const state = this.engine.getState();
    let foundId = nodeId;
    let themeColor: string | undefined = undefined;
    while (foundId) {
      const node = state.mindNodes[foundId];
      if (!node) break;
      if (node.themeColor) {
        themeColor = node.themeColor;
        break;
      }
      if (state.rootId === foundId) {
        break;
      }
      foundId = node.parentId;
    }
    let color;
    if (themeColor) {
      color = this.engine.option.getLineColor?.(themeColor, nodeId);
    }
    if (!color) {
      //如果没有就用默认的
      color = this.engine.option.lineColor ?? '#000000';
    }

    return color;
  };

  computeOffset = () => {
    const state = this.engine.getDraftState();
    const rootNode = state.mindNodes[state.rootId];
    if (!rootNode) return;

    //算出原始位置导图 到viewport中心点的偏移量
    const mindMapCenterX = rootNode.regionWidth * 0.5;
    const mindMapCenterY = rootNode.regionHeight * 0.5;
    const viewportCenterX = this.viewportWidth * 0.5;
    const viewportCenterY = this.viewportHeight * 0.5;
    state.offsetX = viewportCenterX - mindMapCenterX;
    state.offsetY = viewportCenterY - mindMapCenterY;
  };

  /**画线 */
  drawLines = () => {
    //节点之间的连线
    this.drawMindNodes();
    //拖拽相关的线以及框选
    this.drawDragNodeRect();
    this.drawDragLine();
  };
  protected drawDragLine = () => {
    const dragCanvasContext = this.engine.graph.getDragCanvasContext();
    if (!dragCanvasContext) return;
    const { dragInfo } = this.engine;
    if (!dragInfo) return;
    const { dropInfo, lineStyle } = this.engine.getState();
    if (!dropInfo) return;
    const [left, top] = this.engine.option.getCanvasPosition(
      this.engine,
      dragInfo.left,
      dragInfo.top
    );
    const line = this.getDragLine(left, top, dragInfo.id, dropInfo.parentId);
    if (!line) return;
    dragCanvasContext.beginPath();
    if (lineStyle === MindMappingLineStyle.NORMAL) {
      this.drawLine(dragCanvasContext, line, {
        strokeStyle: '#18A0FB',
      });
    } else {
      this.drawBezierLine(dragCanvasContext, line, {
        strokeStyle: '#18A0FB',
      });
    }
    dragCanvasContext.stroke();
  };
  computedLines = (nodeId: string) => {
    const node = this.engine.getMindNode(nodeId);
    if (!node) return [];
    const lines: Line[] = [];
    if (node.left === OUT_SIDE_SCREEN || node.top === OUT_SIDE_SCREEN) {
      return lines;
    }

    node.expanded &&
      node.childrenIds.forEach((cId) => {
        const c = this.engine.getMindNode(cId);
        if (!c) return;
        if (c.left === OUT_SIDE_SCREEN || c.top === OUT_SIDE_SCREEN) {
          return;
        }
        const line = this.getLine(node, c);
        lines.push(line);
        const childrenLines = this.computedLines(cId);
        lines.push(...childrenLines);
      });
    return lines;
  };

  setContainer = (container: HTMLElement) => {
    this.container = container;
  };

  setScroller = (scroller: HTMLDivElement) => {
    this.scroller = scroller;
  };

  setSize = (width: number, height: number) => {
    this.width = width;
    this.height = height;
    this.updateViewPort();
  };

  // abstract getDropPosition: (dragInfo: MindNodeDragInfo | undefined) => Where | undefined;
  abstract getConnectToWhere: (dragInfo: MindNodeDragInfo) => Where | undefined;

  getDropPosition = (dragInfo: MindNodeDragInfo | undefined) => {
    if (!dragInfo) return;
    const mindMapState = this.engine.getState();
    //计算拖拽后会到哪
    const mindNode = mindMapState.mindNodes[dragInfo.id];
    if (!mindNode) return;
    const rootNode = mindMapState.mindNodes[mindMapState.rootId];
    if (!rootNode) return;
    const where = this.getConnectToWhere(dragInfo);
    if (where) {
      if (this.checkDragRecycle(dragInfo.ids, where)) return;
      const support = this.engine.option.supportAppendChild(where.parentId);
      if (!support) return;
    }
    return where;
  };

  //计算nodes的x,y坐标
  computeNodesCoordinate = () => {
    const state = this.engine.getDraftState();
    const listNodes = this.engine.option.getAllElement?.();
    if (!listNodes) {
      return;
    }
    for (const node of listNodes) {
      const { blockId } = (node as HTMLElement).dataset;
      if (!blockId) return;
      const rect = node.getBoundingClientRect();
      const mindNode = state.mindNodes[blockId];
      if (mindNode) {
        mindNode.x = Math.floor(rect.x);
        mindNode.y = Math.floor(rect.y);
      }
    }
  };
  /**给方向键用的 */
  getParentId = (currentId: string) => {
    const node = this.engine.getMindNode(currentId);
    return node?.parentId;
  };
  /**给方向键用的 */
  getMiddleChild = (currentId: string) => {
    const node = this.engine.getMindNode(currentId);
    if (!node) return;
    if (!node.expanded) return;
    if (node.childrenIds.length === 0) return;
    const index = Math.floor((node.childrenIds.length - 1) / 2);
    return node.childrenIds[index];
  };

  /**递归更新节点left/top */
  updateNodeRecursive = (id: string, prop: 'top' | 'left', offset: number) => {
    const state = this.engine.getDraftState();
    const node = state.mindNodes[id];
    if (!node) return;
    node[prop] += offset;
    node.childrenIds.forEach((cId) => {
      this.updateNodeRecursive(cId, prop, offset);
    });
  };
  computeFixWidth = () => {
    if (!this.engine.alignSiblingNode) return;
    //遍历层级
    const state = this.engine.getDraftState();
    const fixWidthRecord = {} as Record<number, { id: string; fixWidth: number }>;
    walk(this.engine, state.rootId, '', undefined, (nodeId, parentId, _, layerIndex) => {
      const lastFixWidth = fixWidthRecord[layerIndex]?.fixWidth ?? 0;
      const node = state.mindNodes[nodeId];
      if (!node) return;
      if (node.reallyWidth > lastFixWidth) {
        fixWidthRecord[layerIndex] = { id: nodeId, fixWidth: node.reallyWidth };
      }
    });
    state.fixWidthRecord = fixWidthRecord;
  };
  computeNodesByLevelOrder = () => {
    const state = this.engine.getDraftState();
    const nodesByLevelOrder: Record<number, string[]> = {};
    const nodesByLevelMap: Record<string, number> = {};
    walk(this.engine, state.rootId, '', undefined, (nodeId, parentId, _, layerIndex) => {
      const node = state.mindNodes[nodeId];
      if (!node) return;
      if (!node.expanded) return;
      let nodes = nodesByLevelOrder[layerIndex];
      if (!nodes) {
        nodes = [];
        nodesByLevelOrder[layerIndex] = nodes;
      }
      nodes.push(nodeId);
      nodesByLevelMap[nodeId] = layerIndex;
    });
    this.engine.nodesByLevelMap = nodesByLevelMap;
    this.engine.nodesByLevelOrder = nodesByLevelOrder;
  };
  //检查拖拽不能拖到子节点逻辑,返回true表示拖到子节点了
  checkDragRecycle = (dragIds: string[], where: Where) => {
    return dragIds.some((dragId) => {
      let { parentId } = where;
      //不能拖到子节点
      while (parentId) {
        if (parentId === dragId) {
          return true;
        }
        parentId = this.engine.getMindNode(parentId)?.parentId ?? '';
      }
      return false;
    });
  };

  protected drawBezierLine = (
    canvasContext: CanvasRenderingContext2D,
    line: Line,
    style?: CanvasStyle
  ) => {
    const ctx = canvasContext;
    ctx.strokeStyle = line.color;
    ctx.lineWidth = 2;
    ctx.lineCap = 'butt';
    if (style) {
      Object.assign(ctx, style);
    }
    ctx.moveTo(line.startX, line.startY);
    const cx1 = line.startX + (line.endX - line.startX) / 2;
    const cy1 = line.startY;
    const cx2 = cx1;
    const cy2 = line.endY;
    ctx.bezierCurveTo(cx1, cy1, cx2, cy2, line.endX, line.endY);
  };

  scale = 1;

  static copyValueToNewProvider = (target: BaseLayoutProvider, source: BaseLayoutProvider) => {
    target.width = source.width;
    target.height = source.height;
    target.viewportWidth = source.viewportWidth;
    target.viewportHeight = source.viewportHeight;
    target.round = source.round;
    target.container = source.container;
    target.scroller = source.scroller;
  };
}
