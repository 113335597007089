import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import { useOpenModal } from 'src/common/components/next-modal';

import { IntegralAnimation } from './integral-animation';

export const useOpenIntegralAnimation = () => {
  const openModal = useOpenModal();

  return useCallback(
    (taskId: string) => {
      return new Promise((resolve) => {
        openModal.modal({
          modalId: 'score-modal',
          closeAfterCallBack: () => {
            resolve(0);
          },
          containerClassName: 'bg-transparent',
          className: 'shadow-none border-none bg-transparent',
          content: () => {
            return <IntegralAnimation taskId={taskId} resolve={resolve} />;
          },
        });
      }).catch((err) => {
        Sentry.captureException(err);
      });
    },
    [openModal]
  );
};
