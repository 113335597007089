import { create } from 'zustand';
import type { AppModalStateType } from './type';

const initState = {
  $settingState: {
    open: false,
  },
};

export const $appModalState = create<AppModalStateType>(() => initState);
