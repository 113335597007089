export interface Rectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

export const isIntersect = (rectangle1: Rectangle, rectangle2: Rectangle) => {
  const x1 = rectangle1.left;
  const y1 = rectangle1.top;
  const x2 = rectangle1.left + rectangle1.width;
  const y2 = rectangle1.top + rectangle1.height;

  const x3 = rectangle2.left;
  const y3 = rectangle2.top;
  const x4 = rectangle2.left + rectangle2.width;
  const y4 = rectangle2.top + rectangle2.height;

  // https://kknews.cc/tech/n63oqoq.html
  return (x4 - x1) * (x3 - x2) < 0 && (y4 - y1) * (y3 - y2) < 0;
};

export const getIntersectionRatio = (entry: Rectangle, target: Rectangle): number => {
  const top = Math.max(target.top, entry.top);
  const left = Math.max(target.left, entry.left);
  const right = Math.min(target.left + target.width, entry.left + entry.width);
  const bottom = Math.min(target.top + target.height, entry.top + entry.height);
  const width = right - left;
  const height = bottom - top;

  if (left < right && top < bottom) {
    const targetArea = target.width * target.height;
    const intersectionArea = width * height;
    const intersectionRatio = intersectionArea / targetArea;

    return Number(intersectionRatio.toFixed(4));
  }

  return 0;
};

interface Point {
  x: number;
  y: number;
}
export const centerOfRectangle = (rect: DOMRect): Point => {
  return {
    x: rect.left + rect.width * 0.5,
    y: rect.top + rect.height * 0.5,
  };
};

export function distanceBetween(p1: Point, p2: Point) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}
