const stringify = (obj: Record<string, unknown>) => {
  const searchParams = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        searchParams.append(key, `${item}`);
      });
    } else {
      searchParams.append(key, `${value ?? ''}`);
    }
  });

  return searchParams.toString();
};

const parse = <T extends Record<string, unknown>>(search: string) => {
  const searchParams = new URLSearchParams(search);

  const result: Record<string, unknown> = {};

  for (const [key, value] of searchParams.entries()) {
    if (result[key] != null) {
      if (!Array.isArray(result[key])) {
        result[key] = [result[key]];
      }
      (result[key] as unknown[]).push(value);
    } else {
      result[key] = value;
    }
  }

  return result as T;
};

export const qs = {
  stringify,
  parse,
};
