import { useCallback } from 'react';
import { request } from 'src/common/request';
import {
  useOpenFirstTaskModal,
  useOpenStageTaskModal,
} from 'src/components/activities/activity-task-modal';
import { useOpenAllTaskModal } from 'src/components/activities/use-open-all-task-modal';
import { getActivityState, updateActivitiesState } from 'src/services/activities/hook';
import { getCurrentSpaceId } from '../space/get-space';
import { ActivityIds } from './activity-ids';
import { ActivitiesListType } from './use-activity';

/** 更新活动列表 */
export const useFetchActivities = () => {
  const openFirstTaskModal = useOpenFirstTaskModal();
  const openStageTaskModal = useOpenStageTaskModal();
  const openAllTaskModal = useOpenAllTaskModal();

  return useCallback(
    async (params?: { openCompleteModal?: boolean; completeListType: ActivitiesListType }) => {
      const { completeListType } = params || {};
      // 留一手，判断在这之前，任务列表的完成状态

      const isNewVersionTasks = !!getActivityState().basicList.find(
        (item) => item.uuid === ActivityIds.CREATE_NEW_PAGE
      );
      // 老版本新手入门才走下面这个，新版本在 2023.4.5 上线，过了一段时间应该就可以删掉老版本的代码
      if (params?.openCompleteModal && !isNewVersionTasks) {
        const cacheBasicListCompleteAll = getActivityState().basicList.every(
          (i) => i.status === 'completed'
        );
        const cacheAdvancedListCompleteAll = getActivityState().advancedList.every(
          (i) => i.status === 'completed'
        );
        // 所有的任务早就都完成了，就不弹窗了
        if (cacheBasicListCompleteAll && cacheAdvancedListCompleteAll) {
          params.openCompleteModal = false;
        } else if (
          // 如果当前做的任务，列表里都是完成的，就不弹窗了
          (completeListType === ActivitiesListType.advancedList && cacheAdvancedListCompleteAll) ||
          (completeListType === ActivitiesListType.basicList && cacheBasicListCompleteAll)
        ) {
          params.openCompleteModal = false;
        }
      }
      const res = await request.infra.getActivityList('all', 'all', getCurrentSpaceId());
      updateActivitiesState(res);

      if (params?.openCompleteModal && !isNewVersionTasks) {
        const advancedLen = res.advancedList?.filter((it) => it.status === 'completed').length || 0;
        const basicLen = res.basicList?.filter((it) => it.status === 'completed').length || 0;

        if (advancedLen + basicLen === 0) return;

        // 完成首个任务
        if (advancedLen + basicLen === 1) {
          openFirstTaskModal();
          return;
        }

        const allAdvanced = advancedLen === res.advancedList?.length;
        const allBasicList = basicLen === res.basicList?.length;

        // 两个任务列表其中之一被做完了，并且现在完成的任务就是是当前的列表，弹窗
        if (
          ((completeListType === ActivitiesListType.advancedList && allAdvanced) ||
            (completeListType === ActivitiesListType.basicList && allBasicList)) &&
          !(allAdvanced && allBasicList)
        ) {
          openStageTaskModal();
          return;
        }

        if (allAdvanced && allBasicList) {
          openAllTaskModal();
        }
      }
    },
    [openAllTaskModal, openFirstTaskModal, openStageTaskModal]
  );
};
