import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';

import { BlockSubject } from '../subject';

export const BlockTextChange: FC<{
  from?: BlockDTO;
  to?: BlockDTO;
  onClick?: () => void;
}> = ({ from, to, onClick }) => {
  return (
    <div className="mt-1 ml-1 animate-hover" onClick={onClick}>
      <div className="pointer-events-none">
        {
          to != null ? (
            <BlockSubject block={to} diffAgainstTo={from == null ? 'created' : from} />
          ) : from != null ? (
            <BlockSubject block={from} diffAgainstTo={'deleted'} />
          ) : null /* never */
        }
      </div>
    </div>
  );
};
