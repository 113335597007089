import { calculateRemindTimeByTimestamp, getDateStrByMilliseconds } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { DateFormat, TimeFormat } from '@next-space/fe-api-idl';
import type { IElementComponent, IElementMeta } from '@next-space/fe-inlined';
import { newContent, newText, normalizeFormat, registerElementMeta } from '@next-space/fe-inlined';
import { lookupEditorElement } from '@next-space/fe-inlined/dom-utils';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Icon } from 'src/common/components/icon';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/common/const';
import { useOpenInlineDatePicker } from '../../component/menu-list/inline-panel/inline-date-panel';
import { EditableContext } from '../uikit/editable-context';
import { getEditorModelByEditorKey } from '../uikit/editable-models';
import { INLINE_DATE_TAG } from './const';
import type { InlinePlugin } from './inline-plugin';
import { useComputeTextStyle } from './utils';
import { useGetOrInitEditorModel } from '../uikit/editable/hook';

const INLINE_DATE_META: IElementMeta = {
  tag: INLINE_DATE_TAG,
  hasContent: false,
  legalize: (element) => {
    const text = dayjs(element.props.datetime as number).format(
      element.props.includeTime ?? false ? DATE_TIME_FORMAT : DATE_FORMAT
    );
    return newContent([newText(` ${text} `)]);
  },
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};
registerElementMeta(INLINE_DATE_META);

const InlineDate: IElementComponent = ({
  htmlDataProps,
  datetime: datetime0,
  includeTime: includeTime0,
  textFormat: textFormat0,
  dateFormat: dateFormat0,
  timeFormat: timeFormat0,
  reminder: reminder0,
  baseOffset,
}) => {
  const datetime = datetime0 as number;
  const includeTime = (includeTime0 as boolean | null) ?? false;
  const dateFormat = (dateFormat0 ?? DateFormat.YYYY_MM_DD) as DateFormat;
  const timeFormat = (timeFormat0 ?? TimeFormat.H_MM) as TimeFormat;
  const reminder = (reminder0 ?? undefined) as SegmentDTO['reminder'];
  const textFormat = normalizeFormat(textFormat0 as any);
  const textStyle = useComputeTextStyle(textFormat);
  const openInlineDatePicker = useOpenInlineDatePicker();
  const { readonly, interactable } = useContext(EditableContext);
  const dateString = getDateStrByMilliseconds(datetime, dateFormat, includeTime, timeFormat);
  const [invalid, setInvalid] = useState(false);
  const getEditorModalById = useGetOrInitEditorModel();

  useEffect(() => {
    const time = calculateRemindTimeByTimestamp(datetime, reminder);
    if (!time) return;
    // 提醒时间到了需要改变ui
    const restTime = time - Date.now();
    if (restTime > 0) {
      setInvalid(false);
      let timer: any;
      // 一天内提醒的才设置定时器检查
      if (restTime < 1 * 24 * 60 * 60 * 1000) {
        timer = setTimeout(() => {
          setInvalid(true);
        }, restTime);
      }
      // timeout时间过大会立刻执行。。浏览器内部用了int64位类型来记录这个值
      return () => {
        timer && clearTimeout(timer);
      };
    }
    setInvalid(true);
  }, [datetime, reminder]);
  return (
    <span
      {...htmlDataProps}
      contentEditable={false}
      className={cx(
        'flex-inline items-center px-1 h-full py-[3px]',
        !readonly && interactable && 'cursor-pointer',
        { 'line-through text-red/70': invalid }
      )}
      style={textStyle}
      onClick={
        readonly || !interactable
          ? undefined
          : (event) => {
              const editorElement = lookupEditorElement(event.target as HTMLSpanElement);
              if (!editorElement) return;
              const editorKey = editorElement.getAttribute('data-editor');
              if (!editorKey) return;

              event.stopPropagation();
              openInlineDatePicker(baseOffset, baseOffset + 1, () => {
                return getEditorModelByEditorKey(editorKey);
              });
            }
      }
    >
      <span
        className={cx(textStyle.color || 'text-grey4', {
          'text-red': reminder,
          'text-red/70': invalid,
        })}
      >
        @
      </span>
      <span
        className={cx(textStyle.color || 'text-grey3', {
          'text-red': reminder,
          'text-red/70': invalid,
        })}
      >
        {dateString}
      </span>
      {reminder && (
        <Icon
          name={'IcMenuAlarm'}
          size="normal"
          className={cx('text-red ml-[2px]', {
            'text-red/70': invalid,
          })}
        />
      )}
    </span>
  );
};

export const DateInlinePlugin: InlinePlugin = {
  elementMeta: INLINE_DATE_META,
  initialize(api) {
    api.setElementComponent(this.elementMeta.tag, InlineDate);
  },
};
