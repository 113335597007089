import { sample } from 'lodash-es';

/** 在一定规则下随机抽一个值 */
export const pickRandomValue = (allRandomValue: string[], alreadyExist: string[]) => {
  const alreadyExistSet = new Set(alreadyExist);
  if (allRandomValue.length === alreadyExistSet.size) {
    return sample(allRandomValue);
  }
  const restRandomValue = allRandomValue.filter((v) => !alreadyExistSet.has(v));
  return sample(restRandomValue);
};
