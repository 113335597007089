import { loginInjector } from '../utils/init';

const defaultImages = () => {
  const ossPath = `${loginInjector.cdnHost}fe-web-app-images/packges-login`;

  return {
    qqLogo: `${ossPath}/qq_logo.svg`,
    wechatBtn: `${ossPath}/wechat_btn.svg`,
    wechatLogoLogin: `${ossPath}/wechat_login_logo.svg`,
    IcTitleClose: `${ossPath}/ic_title_close.svg`,
    IcMenuItemShow: `${ossPath}/ic_menu_item_show.svg`,
    IcMenuItemHide: `${ossPath}/ic_menu_item_hide.svg`,
    IcLoginCode: `${ossPath}/ic_login_code.svg`,
    IcArrow: `${ossPath}/ic_arrow.svg`,
    IcArrowRight: `${ossPath}/ic_arrow_right.svg`,
    IcSelectedSpace: `${ossPath}/ic_space_selected.svg`,
    IcInviteInfo: `${ossPath}/ic_invite_info.svg`,
    IcWarning: `${ossPath}/ic_warning.svg`,
    googleLogo: `${ossPath}/google-logo.png`,
    partnership: `${ossPath}/partnership.svg`,
  };
};

export const Images = new Proxy(defaultImages(), {
  get: (_, key: keyof ReturnType<typeof defaultImages>) => {
    return defaultImages()[key];
  },
});
