import { cx } from '@flowus/common/cx';
import { FileRegex } from '@flowus/common/regex';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import type { IContent, ISelection } from '@next-space/fe-inlined';
import { memo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenFilePreview } from 'src/components/file-preview/use-open-file-preview';
import { IconTrigger } from 'src/components/icon-trigger';
import { segmentsToText } from 'src/editor/utils/editor';
import { getReadableFileSizeString } from 'src/editor/utils/size-utils';
import { useThrottleUpdateSegments } from 'src/hooks/block/use-throttle-update-block';
import { useReadonly } from 'src/hooks/page/use-read-only';
import { useIsSelected } from 'src/redux/managers/ui';
import { useTextareaPlaceHolder } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { getFileIcon, getFileNameInfo } from 'src/utils/file';
import { judgeSharePage } from 'src/utils/getPageId';
import { previewPrefix } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { NodeWrapper } from '../component/node-wrapper';
import { useMonitorFocused } from '../hook/use-monitor-focused';
import type { MindNodeElement } from './all-node-renderer';
import { MindMapRichText } from './editor/mind-map-rich-text';
import { ImageNode } from './image-node';
import { VideoNode } from './video-node';

export const FileNode: MindNodeElement = memo((props) => {
  const fileBlock = usePickBlock(props.id, ['data', 'local'], ['display']);
  if (!fileBlock) return null;
  switch (fileBlock.data.display) {
    case 'image': {
      return <ImageNode id={fileBlock.uuid} level={props.level} />;
    }
    case 'file': {
      return <File id={fileBlock.uuid} level={props.level} />;
    }
    case 'video': {
      return <VideoNode id={fileBlock.uuid} level={props.level} />;
    }
    default:
      return <File id={fileBlock.uuid} level={props.level} />;
  }
});

const File: MindNodeElement = memo((props) => {
  const openFilePreview = useOpenFilePreview();
  const fileBlock = usePickBlock(
    props.id,
    ['data', 'local'],
    ['segments', 'ossName', 'size', 'caption']
  );

  const updateSegments = useThrottleUpdateSegments(props.id, 'caption');
  const fileName = segmentsToText(fileBlock?.data.segments);
  const { extName } = getFileNameInfo(fileName);
  const iconName = getFileIcon(extName);
  const size = getReadableFileSizeString(fileBlock?.data.size);
  const isSelected = useIsSelected(props.id);
  const { alwaysShowPlaceHolder, placeHolder } = useTextareaPlaceHolder(props.id);
  const readonly = useReadonly(props.id);
  const containerRef = useRef<HTMLElement>(null);
  useMonitorFocused(containerRef, props.id, 'caption');

  const previewFile = (fileId: string) => {
    const isSharePage = judgeSharePage();
    // if (illegal && isSharePage) {
    //   message.error(ILLEGAL_TEXT);
    //   return;
    // }
    if (FileRegex.pdf.test(extName)) {
      window.open(`/${isSharePage ? 'share/' : 'preview/'}${fileId}`);
    } else {
      openFilePreview(fileId, props.ownerBlockId);
    }
  };
  if (!fileBlock) return null;
  const FileContent = (
    <>
      <IconTrigger
        trigger={false}
        className="mr-1 self-center"
        blockId={props.id}
        iconSize={18}
        defaultIcon={<Icon size="middle" name={iconName} />}
      />
      <div className="flex max-w-full">
        <div className="relative w-full text-t1-medium text-ellipsis">
          {fileName}
          <span className="absolute bottom-px left-0 h-px bg-grey5 w-full" />
        </div>
        <span className="ml-2 text-t4 self-center mt-1 text-grey3 flex-shrink-0">{size}</span>
      </div>
    </>
  );
  return (
    <NodeWrapper id={props.id} level={props.level} ref={containerRef}>
      <div className="w-full px-3">
        <MindMapRichText
          placeholder={placeHolder}
          uuid={props.id}
          alwaysShowPlaceholder={alwaysShowPlaceHolder}
          className={'whitespace-pre-wrap'}
          segments={fileBlock.data.caption}
          segmentType="caption"
          onChange={(
            segments: SegmentDTO[],
            prevContent: IContent,
            prevSelection: ISelection | null
          ) => {
            updateSegments(segments, [prevContent, prevSelection]);
          }}
        />
        <div
          className={cx('pl-0.5 group leading-9 flex justify-between items-center h-9', {
            'animate-hover': isSelected || readonly,
          })}
        >
          {$searchParams.print ? (
            <a
              href={`${window.location.origin}/${previewPrefix()}/${props.id}`}
              className="pr-10  text-ellipsis flex w-full cursor-pointer"
            >
              {FileContent}
            </a>
          ) : (
            <div
              className={cx('preview-file pr-10 text-ellipsis flex w-full', {
                'cursor-pointer': isSelected || readonly,
              })}
              onClick={() => {
                (isSelected || readonly) && previewFile(props.id);
              }}
            >
              {FileContent}
            </div>
          )}
        </div>
      </div>
    </NodeWrapper>
  );
});
