import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { segmentsToText } from 'src/editor/utils/editor';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import type { CellViewProps } from '../types';
import { TagItem } from './tag-item';

interface Props extends CellViewProps {
  multiple?: boolean;
  editable?: boolean;
  itemClassName?: string;
  className?: string;
  onChange?: (value: string) => void;
}

export const Tags: FC<Props> = ({
  recordId,
  propertyId,
  multiple = false,
  editable = false,
  onChange,
  className,
  itemClassName,
}) => {
  const { options, text } = useObservableStore(
    ({ blocks }) => {
      const collectionId = blocks[recordId]?.parentId ?? '';
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return {
        options: blocks[collectionId]?.data.schema?.[propertyId]?.options,
        text: segmentsToText(segments).trim(),
      };
    },
    [recordId, propertyId]
  );

  let tags = text.split(/\s*,\s*/g).filter((it) => options?.some((option) => option.value === it));
  if (!multiple) {
    tags = tags.slice(0, 1);
  }

  const deleteTag = (tag: string) => {
    onChange?.(tags.filter((it) => it && it !== tag).join(','));
  };

  if (tags.length === 0) return null;

  return (
    <div className={cx('relative', className)}>
      {tags.map((tag) => {
        const option = options?.find((option) => option.value === tag);
        if (option == null) return null;

        const handleDelete = () => {
          if (!option.value) return;
          deleteTag(option.value);
        };

        return (
          <TagItem
            className={cx('flex-shrink-0', itemClassName)}
            key={option.id}
            color={option.color}
            value={option.value}
            label={option.value}
            showClose={editable}
            onDelete={handleDelete}
          />
        );
      })}
    </div>
  );
};
