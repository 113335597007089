import { cx } from '@flowus/common/cx';
import type { BlockSearchDTO } from '@next-space/fe-api-idl';
import type { CSSProperties, FC, ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { Input } from 'src/common/components/input';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { SearchLoading } from 'src/common/components/list-view/search-loading';
import { LoadingIcon } from 'src/common/components/loading-icon';
import { segmentsToText } from 'src/editor/utils/editor';
import type { SearchInfo } from 'src/hooks/drive/use-search-block';
import type { NextBlock } from 'src/redux/types';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { useSearchUI } from './use-search-ui';

export const ITEM_HEIGHT = 40;
// 需要展示面包屑的高度
export const ITEM_HEIGHT_WITH_NAVS = 58;
export const MENU_MAX_HEIGHT = 256;
export const MENU_MIN_HEIGHT = 58;

export type SearchType = 'page' | 'file' | 'folder' | 'collection' | ''; // block类型
export type FileType = 'video' | 'audio' | 'image'; // 文件类型
export interface Props {
  onSelect?(pageInfo: SearchItem): void;
  defaultValue?: SearchItem[];
  placeholder?: string;
  searchType?: SearchType;
  footer?: ReactNode;
  header?: ReactNode;
  source?: SearchInfo['source'];
  parentId?: string;
  canSearchWithEmpty?: boolean;
  filterItems?: string[];
  fileType?: FileType;
  spaceId?: string;
  className?: string;
  style?: CSSProperties;
}
export type SearchItem = Pick<NextBlock, 'type' | 'uuid' | 'data' | 'parentId'> & {
  title: string;
  avatarTitle?: string;
  backgroundColor?: string;
  coverUrl?: string;
  navs?: BlockSearchDTO['navs'];
};

export const SearchMenu: FC<Props> = (props) => {
  return (
    <SearchContent {...props} className={cx('relative py-2.5 overflow-hidden next-modal w-60')} />
  );
};

export const SearchContent: FC<Props & { className?: string }> = (props) => {
  const IS_FOLDER = !!props.footer;
  const { filterItems = [] } = props;
  const { keyword, resultList, loading, hasMore, loadMore, onInputChange } = useSearchUI({
    defaultList: props.defaultValue,
    type: props.searchType,
    source: props.source,
    parentId: props.parentId,
    canSearchWithEmpty: props.canSearchWithEmpty,
    fileType: props.fileType,
    spaceId: props.spaceId,
  });

  useEffect(() => {
    if (props.canSearchWithEmpty) {
      onInputChange('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.canSearchWithEmpty]);

  const items = useMemo(() => {
    return resultList
      .map((searchItem: SearchItem) => ({
        type: ListItemType.SEARCH,
        data: {
          uuid: searchItem.uuid,
          title:
            segmentsToText(searchItem.data.segments) ||
            searchItem.title ||
            getUntitledName(searchItem.type),
          avatarTitle: searchItem.avatarTitle,
          icon: searchItem.data.icon,
          navs: searchItem.navs,
          type: searchItem.type,
          backgroundColor: searchItem.backgroundColor,
          ...bindDataTestId(TestIds.moveToItem),
        },
      }))
      .filter((item) => {
        return !filterItems.includes(item.data.uuid);
      });
  }, [filterItems, resultList]);

  const handleMenuItemClick = (_: unknown, index: number) => {
    const searchItem = items[index]?.data;
    if (searchItem) {
      const found = resultList.find((v) => v.uuid === searchItem.uuid);
      found && props.onSelect?.(found);
    }
  };
  const height = useMemo(() => {
    const FOOTER_HEIGHT = IS_FOLDER ? 40 : 0;
    const HEADER_HEIGHT = props.header ? 30 : 0;

    if (items.length === 0) {
      return MENU_MAX_HEIGHT / 2;
    }
    if (items.length > 5) {
      return MENU_MAX_HEIGHT + ITEM_HEIGHT / 2 + FOOTER_HEIGHT; // 增加半高度, 避免不知道下方还有内容
    }
    let itemsHeight = 0;
    items.forEach((item) => {
      // 有导航栏的
      if (item.data.navs && item.data.navs.length > 0) {
        itemsHeight += ITEM_HEIGHT_WITH_NAVS;
      } else {
        itemsHeight += ITEM_HEIGHT;
      }
    });
    // 由于listView内部用到了InfiniteScroll，必须提供整个menu的高度才能用（看了rv-list也需要提供高度）
    // 32是输入框的最大高度,20是最外层padding top/bottom,10是输入框下边距
    return itemsHeight + 32 + 20 + 10 + FOOTER_HEIGHT + HEADER_HEIGHT;
  }, [items, IS_FOLDER, props.header]);

  return (
    <div
      className={cx('relative max-h-[60vh]', props.className)}
      style={{ height, ...props.style }}
    >
      {props.header}
      <ListView
        items={items}
        hasMore={hasMore}
        loadMore={loadMore}
        scrollContainerStyle={{
          top: props.header ? '80px' : '52px',
          paddingBottom: IS_FOLDER ? '40px' : '0px',
        }}
        onItemClick={handleMenuItemClick}
        customHeader={
          <Input
            autoFocus
            className="h-8 mx-2.5"
            {...bindDataTestId(TestIds.moveToInput)}
            placeholder={props.placeholder || `搜索页面`}
            onChange={onInputChange}
          />
        }
        loadingView={loading && <SearchLoading />}
        customFooter={
          <>
            {loading && hasMore && (
              <div className="relative flex flex-row items-center justify-center h-10">
                <LoadingIcon size="middle" />
                <div className="flex items-center ml-2 text-center text-t2 text-grey4">
                  正在加载...
                </div>
              </div>
            )}
            {!loading && items.length === 0 && (
              <div className="relative flex justify-center items-center h-10 text-t2 text-grey4 mt-auto">
                {keyword ? '没有找到相关结果' : '搜点什么？'}
              </div>
            )}
          </>
        }
      />
      {props.footer}
    </div>
  );
};
