import { Compare } from '@flowus/common';
import type { BlockDTO, PageActivityDTO } from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType } from '@next-space/fe-api-idl';
import { every, isEmpty, omit } from 'lodash-es';
import type { FC, ReactNode } from 'react';
import { COMPUTED_PROPERTIES, READONLY_PROPERTIES } from 'src/bitable/const';
import { getPropValueType } from 'src/bitable/v2';
import { deepEqualLoose } from '@flowus/common/utils/tools';
import { segmentsToText } from 'src/editor/utils/editor';
import { normalizeSegments } from 'src/editor/utils/segments';
import { getState } from 'src/redux/store';
import { isEditableType, isPageLike } from 'src/utils/block-type-utils';
import { useJumpTop } from 'src/views/main/page-doc/use-jump-top';
import { BlockCaptionChange } from '../changes/block-caption-change';
import { BlockCreated, BlockDeleted } from '../changes/block-change';
import { BlockTextChange } from '../changes/block-text-change';
import { FileNameChange } from '../changes/file-name-change';
import { PageDescriptionChange } from '../changes/page-description-change';
import { PageIconChange } from '../changes/page-icon-change';
import { PageTitleChange } from '../changes/page-title-change';
import { RecordPropChange, RecordTextPropChange } from '../changes/record-prop-change';
import { TableRowChange } from '../changes/table-row-change';
import { PageSubject } from '../subject';
import { FeedTemplate } from './feed-template';

export const BlockFeed: FC<{ pageActivity: PageActivityDTO }> = ({ pageActivity }) => {
  const jumpTop = useJumpTop();

  const edits = pageActivity.edits.slice(0);
  // edits.sort(Cmp.reversed(Cmp.by((it) => it.timestamp)));

  const changes: ReactNode[] = [];
  for (const edit of edits) {
    const [blockA, blockB] =
      edit.type === 'blockCreated'
        ? [undefined, edit.blockData?.current?.blockValue]
        : edit.type === 'blockDeleted'
        ? [edit.blockData?.current?.blockValue, undefined]
        : [edit.blockData?.before?.blockValue, edit.blockData?.after?.blockValue];

    const block = blockB ?? blockA;
    const onClick = () => {
      const blockId = block?.uuid;
      if (blockId) {
        void jumpTop(blockId);
      }
    };

    if (shouldUseTextDiff(blockA, blockB)) {
      if (
        !deepEqualLoose(
          normalizeSegments(blockA?.data.segments),
          normalizeSegments(blockB?.data.segments)
        )
      ) {
        changes.push(
          <BlockTextChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
      }
      continue;
    }

    if (
      blockA != null &&
      blockB != null &&
      blockA.type === blockB.type &&
      !deepEqualLoose(
        normalizeSegments(blockA.data.caption),
        normalizeSegments(blockB.data.caption)
      )
    ) {
      const copyA = {
        ...omit(blockA, 'updatedAt', 'updatedBy', 'version'),
        data: omit(blockA.data, 'caption'),
      };
      const copyB = {
        ...omit(blockB, 'updatedAt', 'updatedBy', 'version'),
        data: omit(blockB.data, 'caption'),
      };
      if (deepEqualLoose(copyA, copyB)) {
        changes.push(
          <BlockCaptionChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
        continue;
      }
    }

    if (block?.type === BlockType.TABLE_ROW) {
      if (
        !(
          every(blockA?.data.collectionProperties, isEmpty) &&
          every(blockB?.data.collectionProperties, isEmpty)
        )
      ) {
        changes.push(
          <TableRowChange
            key={changes.length}
            from={blockA}
            to={blockB}
            onClick={() => {
              const blockId = block.parentId;
              if (blockId) {
                void jumpTop(blockId);
              }
            }}
          />
        );
      }
      continue;
    }

    // 文件块重命名
    if (
      blockA != null &&
      blockB != null &&
      blockA.type === blockB.type &&
      blockA.type === BlockType.FILE &&
      !deepEqualLoose(
        normalizeSegments(blockA.data.segments),
        normalizeSegments(blockB.data.segments)
      )
    ) {
      const copyA = {
        ...omit(blockA, 'updatedAt', 'updatedBy', 'version', 'title'),
        data: omit(blockA.data, 'segments'),
      };
      const copyB = {
        ...omit(blockB, 'updatedAt', 'updatedBy', 'version', 'title'),
        data: omit(blockB.data, 'segments'),
      };
      if (deepEqualLoose(copyA, copyB)) {
        changes.push(
          <FileNameChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
        continue;
      }
    }

    if (blockA != null && blockB != null && isPageLike(blockA.type) && isPageLike(blockB.type)) {
      if (
        !deepEqualLoose(
          normalizeSegments(blockA.data.segments),
          normalizeSegments(blockB.data.segments)
        )
      ) {
        changes.push(
          <PageTitleChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
      }

      if (!deepEqualLoose(blockA.data.icon ?? {}, blockB.data.icon ?? {})) {
        changes.push(
          <PageIconChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
      }

      if (!deepEqualLoose(blockA.data.description ?? [], blockB.data.description ?? [])) {
        changes.push(
          <PageDescriptionChange key={changes.length} from={blockA} to={blockB} onClick={onClick} />
        );
      }

      const resolver = {
        findSchema(collId: string, propId: string) {
          return edit.blockSchema?.[propId];
        },
        findUser(userId: string) {
          return getState().users[userId];
        },
        findRecord(recordId: string) {
          if (recordId === blockA.uuid) {
            return blockA;
          }
          if (recordId === blockB.uuid) {
            return blockB;
          }
          return getState().blocks[recordId] as BlockDTO;
        },
      };

      for (const [prop, propSchema] of Object.entries(edit.blockSchema ?? {})) {
        if (
          propSchema.type === CollectionSchemaType.TITLE ||
          READONLY_PROPERTIES.includes(propSchema.type) ||
          COMPUTED_PROPERTIES.includes(propSchema.type)
        ) {
          continue;
        }

        const segmentsA = blockA.data.collectionProperties?.[prop];
        const segmentsB = blockB.data.collectionProperties?.[prop];
        if (propSchema.type === CollectionSchemaType.TEXT) {
          if (!deepEqualLoose(normalizeSegments(segmentsA), normalizeSegments(segmentsB))) {
            changes.push(
              <RecordTextPropChange
                schema={propSchema}
                key={changes.length}
                from={segmentsA}
                to={segmentsB}
                onClick={onClick}
              />
            );
          }
          continue;
        }

        const valueType = getPropValueType('', prop, resolver);
        const valueA = valueType.fromSegments(segmentsA);
        const valueB = valueType.fromSegments(segmentsB);

        if (
          !Compare.deriveEqualFunc(Compare.nullsLast(valueType.compare.bind(valueType)))(
            valueA,
            valueB
          )
        ) {
          changes.push(
            <RecordPropChange
              key={changes.length}
              schema={propSchema}
              valueType={valueType}
              from={valueA}
              to={valueB}
              onClick={onClick}
            />
          );
        }
      }
      continue;
    }

    const TEXT_TYPES = new Set([BlockType.TEXTAREA, BlockType.HEADER]);

    let from = blockA;
    let to = blockB;

    // 如果原来是空文本块，则只显示新增块
    if (from != null && isEditableType(from.type) && segmentsToText(from.data.segments) === '') {
      from = undefined;
    }

    // 创建简单表格不进动态
    if (to != null && to.type === BlockType.TABLE) {
      to = undefined;
    }

    if (from != null) {
      if (TEXT_TYPES.has(from.type)) {
        changes.push(
          <BlockTextChange key={changes.length} from={from} to={undefined} onClick={onClick} />
        );
      } else {
        changes.push(<BlockDeleted key={changes.length} block={from} onClick={onClick} />);
      }
    }
    if (to != null) {
      changes.push(<BlockCreated key={changes.length} block={to} onClick={onClick} />);
    }
  }

  if (changes.length === 0) return null;

  return (
    <FeedTemplate
      edits={pageActivity.edits}
      action="编辑了"
      subject={<PageSubject pageId={pageActivity.parentId} />}
      timestamp={pageActivity.endTime}
      changes={changes}
    ></FeedTemplate>
  );
};

const shouldUseTextDiff = (blockA: BlockDTO | undefined, blockB: BlockDTO | undefined) => {
  if (blockA != null && blockB != null) {
    if (blockA.type !== blockB.type) return false;
    if (blockA.type === BlockType.HEADER) {
      if (blockA.data.level !== blockB.data.level) return false;
      return true;
    }
    // 着重块需判断图标是否发生变化
    if (blockA.type === BlockType.MARK) {
      if (blockA.data.icon !== blockB.data.icon) return false;
      return true;
    }
    // 产品要求勾选变化展示箭头
    if (blockA.type === BlockType.TODO) {
      if (blockA.data.checked !== blockB.data.checked) return false;
      return true;
    }
  }

  const block = blockA ?? blockB;
  if (block == null) return false;
  if (block.type === BlockType.CODE) return false;

  return isEditableType(block.type);
};
