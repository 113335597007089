import { cx } from '@flowus/common/cx';
import type { IconData, SegmentDTO, UserDTO } from '@next-space/fe-api-idl';
import { PermissionType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { css } from 'otion';
import type { FC } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import {
  AllSpaceUserView,
  MemberGroupView,
  PersonTag,
  PersonValueView,
} from 'src/bitable/table-view/cell/person';
import { AutoHeightTextArea } from 'src/common/components/auto-height-text-area';
import { Divider } from 'src/common/components/divider';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { buildPersonSegment } from 'src/editor/utils/segments';
import { useReadonly } from 'src/hooks/page';
import { getCurrentSpace } from 'src/hooks/space';
import { useSearchSpaceMembers } from 'src/hooks/space/use-search-space-members';

// 包含成员组
export type SegmentDTOExt = SegmentDTO & { permissionType?: PermissionType };
interface EditorPersonListProps {
  onUpdate?: (seg: SegmentDTOExt[] | undefined) => void;
  onSelect?: (uuid: string, type: PermissionType) => void;
  onClose?: () => void;
  value?: SegmentDTOExt[];
  /** 是否包含成员组 */
  includeGroup?: boolean;
}
export const EditorPersonList: FC<EditorPersonListProps> = ({
  value = [],
  onUpdate = () => {},
  onClose = () => {},
  onSelect,
  includeGroup = true,
}) => {
  const searchSpaceMembers = useSearchSpaceMembers();
  const readonly = useReadonly();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [search, setSearch] = useState('');
  const users = useMemo(() => {
    const result = searchSpaceMembers({
      keywords: search,
      includeGroup,
    });
    // 过滤掉已经选过的
    return result.filter((r) => {
      return !value.some((v) => {
        if (v.permissionType === r.type) {
          if (v.permissionType === PermissionType.SPACE) {
            return true;
          }
          return v.uuid === r.uuid;
        }
        return false;
      });
    });
  }, [includeGroup, search, searchSpaceMembers, value]);

  const saveValue = (values: SegmentDTOExt[] | undefined) => {
    onUpdate(values);
  };

  const addPerson = (uuid: string, type: PermissionType) => {
    if (value.findIndex((it) => it.uuid === uuid) === -1) {
      const segment = buildPersonSegment(uuid) as SegmentDTOExt;
      segment.permissionType = type;
      saveValue([...value, segment]);
    }
  };

  const deleteLastPerson = () => {
    if (value.length > 0) {
      const [_, ...rest] = value;
      saveValue(rest);
    }
  };

  const deletePerson = (uuid: string) => {
    const index = value.findIndex((it) => it.uuid === uuid);
    if (index >= 0) {
      const newValue: SegmentDTOExt[] = [];
      value.forEach((v, i) => {
        if (i !== index) {
          newValue.push(v);
        }
      });
      saveValue(newValue);
    }
  };

  const handleSelectPerson = (uuid: string, type: PermissionType) => {
    setSearch('');
    addPerson(uuid, type);
    onSelect?.(uuid, type);
    inputRef.current?.focus();
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (isHotkey('Esc')(event)) {
      onClose();
      return;
    }

    if (!search && isHotkey('Backspace')(event)) {
      deleteLastPerson();
    }
  };

  return (
    <>
      <div className="flex min-h-[42px] min-w-[260px] max-w-[480px] flex-wrap items-center px-2">
        {value.map((it, index) => {
          return (
            <PersonTag
              key={it.uuid}
              userId={it.uuid ?? ''}
              showClose
              onClose={() => {
                it.uuid && deletePerson(it.uuid);
              }}
              type={value[index]?.permissionType}
            />
          );
        })}
        {!readonly && (
          <AutoHeightTextArea
            ref={inputRef}
            autoFocus
            singleLine
            placeholder="搜索成员"
            fontClassName={css({ lineHeight: '24px', fontSize: 14, whiteSpace: 'nowrap' })}
            className={css({
              width: '100%',
              selectors: { '&::placeholder': { color: 'var(--grey4)' } },
            })}
            boxClassName={css({
              flexBasis: 'auto',
              flexGrow: 1,
              flexShrink: 0,
              minWidth: 120,
              maxWidth: '100%',
            })}
            value={search}
            onKeyDown={handleInputKeyDown}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        )}
      </div>
      <Divider />
      <PersonList className="py-1.5" users={users} onSelectPerson={handleSelectPerson} />
    </>
  );
};
// 可能包含成员组
type UserDTOExt = UserDTO & { type: PermissionType; iconData?: IconData };

export const PersonList: FC<{
  className?: string;
  value?: string;
  users: UserDTOExt[];
  onSelectPerson?: (uuid: string, type: PermissionType) => void;
  customHeader?: React.ReactNode;
}> = ({ className, users, value, onSelectPerson, customHeader }) => {
  const items = users.map((user) => {
    return {
      type: ListItemType.OPERATION,
      data: {
        content:
          user.type === PermissionType.GROUP ? (
            // 用户组
            <MemberGroupView name={user.nickname} icon={user.iconData} />
          ) : user.type === PermissionType.USER ? (
            <PersonValueView className="!px-0" userId={user.uuid} />
          ) : (
            // 全体成员
            <AllSpaceUserView
              backgroundColor={getCurrentSpace().backgroundColor}
              spaceName={getCurrentSpace().title}
              name={user.nickname}
              icon={user.iconData}
            />
          ),
        value: user.uuid,
        onClick: () => {
          onSelectPerson?.(user.uuid, user.type);
        },
      },
    };
  });

  return (
    <ListView
      customHeader={customHeader}
      items={items}
      customFooter={
        <>
          {items.length <= 0 && (
            <div className="text-t2 relative flex h-10 items-center justify-center text-grey4">
              没有找到相关结果
            </div>
          )}
        </>
      }
      className={cx('text-t2 max-h-[50vh] overflow-y-auto', className)}
      defaultActiveIndex={items.findIndex((item) => item.data.value === value)}
    />
  );
};
