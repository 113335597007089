import { useCallback } from 'react';
import { cache } from 'src/redux/store';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useCurrentUser } from '../user';

export const useIsOwner = () => {
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, ['createdBy', 'updatedBy']);
  const currentUser = useCurrentUser();

  return currentUser.uuid === block?.createdBy || currentUser.uuid === block?.updatedBy;
};

export const useGetIsOwner = () => {
  const currentUser = useCurrentUser();

  return useCallback(
    (uuid: string) => {
      const block = cache.blocks[uuid];
      return currentUser.uuid === block?.createdBy || currentUser.uuid === block?.updatedBy;
    },
    [currentUser.uuid]
  );
};
