import { fetchSpaceCapacity } from 'src/hooks/drive/use-fetch-space-capacity';
import { fetchSpaces } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { fetchCurrentSpacePlan, fetchCurrentSpacePlans } from 'src/services/capacity/space-plans';
import { fetchSubscriptionData } from './subscription-data';

export const refreshSpaceData = async (spaceId = getCurrentSpaceId()) => {
  await fetchSpaceCapacity(spaceId);
  await fetchSubscriptionData(spaceId);
  await fetchCurrentSpacePlan();
  await fetchCurrentSpacePlans(spaceId);
  await fetchSpaces();
};
