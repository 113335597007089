import { Button } from '@flowus/common/components';
import { FC, useState } from 'react';
import { Prompt } from './types';
import { usePromptStore } from './store';
import { Input } from 'src/common/components/input';
import { isSystemPrompt } from './data';
import { cx } from '@flowus/common/cx';
import { message } from '@flowus/common/components/message';

interface Props {}

export const PromptContent: FC<Props> = (props) => {
  const prompt = usePromptStore((s) => s.records[s.curentPromptId ?? '']);
  const curentPromptId = usePromptStore((s) => s.curentPromptId);
  const [editTitle, setEditTitle] = useState(false);
  const [draft, setDraft] = useState<Prompt>();

  const update = (prompt: Prompt) => {
    const store = usePromptStore.getState();
    if (!store.curentPromptId) return;
    const oldPrompt = store.records[store.curentPromptId];
    const newPrompt = { ...oldPrompt, ...prompt };
    usePromptStore.setState({
      records: { ...store.records, [store.curentPromptId]: newPrompt },
    });
  };
  const _setDraft = (prompt: Prompt) => {
    setDraft(prompt);
    usePromptStore.setState({
      hasUpdate: true,
    });
  };
  const showPrompt = draft || prompt;
  const systemPrompt = isSystemPrompt(curentPromptId);

  return (
    <div className="w-[70%] p-2">
      {!systemPrompt && (
        <div className="flex justify-between w-full flex-row-reverse">
          <div className="flex items-center space-x-1">
            <Button
              disable={!draft}
              colorType="active"
              onClick={() => {
                draft && update(draft);
                message.success('已保存');
              }}
            >
              保存
            </Button>
          </div>
        </div>
      )}
      {showPrompt && (
        <div className="flex flex-col mt-2 rounded bg-grey8 w-full h-[80%] ">
          <div className="h-[90%]">
            <div className="px-4 flex items-center mt-2 space-x-2">
              {!editTitle || systemPrompt ? (
                <div
                  className={cx(
                    'text-t1-medium max-w-[200px] text-ellipsis min-w-[30px] h-[22px] group-hover:opacity-100 transition-opacity',
                    {
                      'animate-hover': !systemPrompt,
                    }
                  )}
                  onClick={() => {
                    setEditTitle(true);
                  }}
                >
                  {showPrompt.name || '请输入指令名称'}
                </div>
              ) : (
                <Input
                  placeholder="请输入指令名称"
                  inputClassName="text-t1-medium px-0"
                  showBorder={false}
                  onBlur={() => {
                    setEditTitle(false);
                  }}
                  value={showPrompt.name}
                  onChange={(v) => {
                    //如果有draft说明已经修改过了，直接用draft
                    _setDraft({
                      ...showPrompt,
                      name: v,
                    });
                  }}
                />
              )}
              {systemPrompt && <div className="text-t2 p-1 bg-grey6 rounded">系统预置</div>}
            </div>
            <div className="w-full mt-2 h-px bg-grey6"></div>
            <textarea
              disabled={systemPrompt}
              placeholder="请输入你的AI指令"
              className="h-full py-2 px-3 w-full bg-grey9 rounded  focus-within-border text-t2 resize-none"
              value={showPrompt.minimax}
              onChange={(e) => {
                const { value } = e.target;
                _setDraft({
                  ...showPrompt,
                  minimax: value,
                });
              }}
            />
          </div>
        </div>
      )}
      <div
        className={cx('text-t4 mt-2 w-full text-grey3 flex justify-end', {
          'mt-4': systemPrompt,
        })}
      >
        {'使用 {input} 作为引用文本的占位符'}
      </div>
    </div>
  );
};
