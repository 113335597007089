import type { NextBlock } from '@flowus/common/block/type';
import { request } from 'src/common/request';
import { blocksActions } from 'src/redux/reducers/blocks';
import { dispatch } from 'src/redux/store';

export const checkPageId = async (pageId: string) => {
  const res = await request.editor.getBlock.raw(pageId);
  if (res.code === 200) {
    dispatch(blocksActions.update({ blocks: { [pageId]: res.data as NextBlock } }));
  }
  return res;
};
