import { cloneDeep } from 'lodash-es';
import type { StoreApi, UseBoundStore } from 'zustand';
// import {  create } from 'zustand';
import { createWithEqualityFn as create } from 'zustand/traditional';
import { persist } from 'zustand/middleware';
import { fastEqual } from '../utils/tools';

export const createCache = <T extends {}>(store: UseBoundStore<StoreApi<T>>) => {
  const $cache = new Proxy(
    {},
    {
      get: (_, p) => {
        return Reflect.get(store.getState(), p);
      },
    }
  );

  return $cache as ReturnType<typeof store.getState>;
};

export const createPersistStore = <T = {}>(
  key: string,
  fn: Parameters<typeof persist>[0],
  option?: {
    isSpaceScope?: boolean;
  }
) => {
  const { isSpaceScope = false } = option || {};
  const localId = 'default';

  const createPersist = persist(fn, { name: `persist:${localId}-${key}` });

  const store = create(createPersist);

  return store as UseBoundStore<StoreApi<T>>;
};

export const createStore = <T = {}>(fn: Parameters<typeof persist>[0]) => {
  const store = create(fn);
  return store as UseBoundStore<StoreApi<T>>;
};

export const createSetState = <T = unknown>(
  store: UseBoundStore<StoreApi<T>>,
  opt?: {
    assign?: boolean;
  }
) => {
  return (
    selector: ((state: T) => T) | Partial<T>,
    option?: { replace?: boolean; replaceAll?: boolean }
  ) => {
    const oldState = store.getState();
    const newValue = selector instanceof Function ? selector(cloneDeep(oldState)) : selector;
    let newState = newValue;
    if (opt?.assign && !option?.replaceAll) {
      newState = { ...oldState, ...newValue };
    }
    if (option?.replace || option?.replaceAll) {
      store.setState(newState, true);
    } else if (!fastEqual(newState, oldState)) {
      store.setState(newState);
    }
  };
};

export const getLocalPersistKey = () => {
  const localId = localStorage.getItem('persist-key');
  return localId;
};

export const setLocalPersistKey = (value: string) => {
  localStorage.setItem('persist-key', value);
};

export const getSpaceLocalPersistKey = () => {
  const localId = localStorage.getItem('persist-key-space');
  return localId;
};

export const setSpaceLocalPersistKey = (value: string) => {
  localStorage.setItem('persist-key-space', value);
};
