import type { ComponentProps } from 'react';
import { Icon } from 'src/common/components/icon';
import type { ReadonlyProp } from 'src/redux/types';
import './style.css';

interface UploadErrorProps extends ReadonlyProp, Pick<ComponentProps<'div'>, 'onClick'> {}

export function UploadError(props: UploadErrorProps) {
  return (
    <div
      className="flex flex-col justify-center items-center bg-grey8 h-[114px] cursor-pointer my-1"
      onClick={props.readonly ? undefined : props.onClick}
      contentEditable={false}
    >
      <Icon name="IcUploadImageFaild" className="text-grey4" size="large" />
      <div className="mt-2 text-t2 text-grey4">图片加载失败，请点击重新上传</div>
    </div>
  );
}
