import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import { loginInit } from '@flowus/login';
import { IconWidget } from 'src/common/components/emoji-widget';
import { VITE_CDN_HOST, VITE_PREFIX } from 'src/env';
import { bizTracker } from 'src/utils/biz-tracker';
import { cx } from '@flowus/common/cx';

export const initLogin = () => {
  loginInit({
    cx,
    request,
    event(name, params, flush) {
      bizTracker.event(name, params, flush);
    },
    message,
    EmojiIcon: (props) => {
      return <IconWidget iconSize={props.size ?? 24} emojiStr={props.value} disableHover={true} />;
    },
    afterLogin: () => {},
    wechatId: import.meta.env.VITE_WECHAT_APP_ID,
    origin: VITE_PREFIX,
    cdnHost: VITE_CDN_HOST,
  });
};
