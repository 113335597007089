import type { CSSProperties, HTMLAttributes, MouseEvent, ReactNode, SyntheticEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { commonInjector } from '../build-in';
import { getImageBlobByExternalUrl } from '../proxy-url';
import { getFormatUrl, isOssUrl } from '../url';

export const getBlankImage = () => `${commonInjector.cdnHost}fe-web-app-images/default-avatar.svg`;
export const PX_IMAGE = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/B8AAusB9FD6Pn0AAAAASUVORK5CYII=`;
export const enhanceImageCache = new Map<string, string>();

export interface EnhanceImageProps extends HTMLAttributes<HTMLImageElement> {
  className?: string;
  alt?: string;
  src: string;
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
  loader?: ReactNode;
  onTryProxy?: () => void;
  originSrc?: string;
}

export const useEnhanceImage = (props: EnhanceImageProps) => {
  const { src, onError: _onError, onTryProxy } = props;
  const [imgUrl, setImgUrl] = useState(src);
  const isProxyUrl = useRef(false);
  const tryHeic = useRef(false);
  const originSrc = useRef(src);
  const [proxyLoading, setProxyLoading] = useState(false);

  const tryProxyUrl = () => {
    onTryProxy?.();
    setProxyLoading(true);
    const proxyFormatUrl = getFormatUrl(originSrc.current);
    let proxyUrl = originSrc.current;

    if (proxyFormatUrl) {
      proxyFormatUrl.searchParams.delete('x-oss-process');
      proxyUrl = proxyFormatUrl.href;
    }

    void getImageBlobByExternalUrl(proxyUrl, {
      tryHeic: tryHeic.current,
      callback: async (res) => {
        setImgUrl(URL.createObjectURL(res.blob));
        enhanceImageCache.set(originSrc.current, URL.createObjectURL(res.blob));
        setProxyLoading(false);
      },
      onError: () => {
        setImgUrl(getBlankImage());
        setProxyLoading(false);
      },
    });
  };

  useEffect(() => {
    if (src !== imgUrl) {
      const cacheUrl = enhanceImageCache.get(src);
      setImgUrl(cacheUrl ?? src);
      originSrc.current = src;
      isProxyUrl.current = !!cacheUrl;
      tryHeic.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  const onError = (e: SyntheticEvent<HTMLImageElement>) => {
    if (proxyLoading) return;
    if (!originSrc.current.startsWith('blob')) {
      // 没代理过，试试代理
      if (!isProxyUrl.current) {
        isProxyUrl.current = true;
        // 如果是oss的图片，就直接转 heic，oss原本不需要代理
        if (isOssUrl(originSrc.current)) {
          tryHeic.current = true;
        }
        tryProxyUrl();
        return;
      }

      // 已经代理过了，但没试过转 heic
      if (isProxyUrl.current && !tryHeic.current) {
        tryHeic.current = true;
        tryProxyUrl();
        return;
      }
      // 已经代理过了，也试过转 heic，就不再试了
    }
    _onError?.(e);
    // 如果还是加载不出来，就显示空白图
    setImgUrl(getBlankImage());
  };

  return {
    imgUrl,
    setImgUrl,
    onError,
    isProxyUrl,
    tryHeic,
    proxyLoading,
  };
};
