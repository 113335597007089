import type { History, Location } from 'history';
import { createContext, useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { qs } from './querystring';
import { parsePageId } from './getPageId';

export const IsInRightContext = createContext<History | null>(null);

export const useIsInRight = () => {
  return !!useContext(IsInRightContext);
};

export const useIsMaster = () => {
  return !useContext(IsInRightContext);
};

export const useMasterHistory = () => {
  const masterHistory = useContext(IsInRightContext);
  const history = useHistory();
  return masterHistory ?? history;
};

export const parseRightPageId = (search: string) => {
  const obj = qs.parse(search);
  return obj['right-page-id'] as string | undefined;
};

export const useGetRightPageId = () => {
  // 需要监听路由，别动
  useLocation();
  return parseRightPageId(location.search);
};

export const getRightPageId = () => {
  return parseRightPageId(location.search);
};

export const useCloseRight = () => {
  const history = useMasterHistory();
  return useCallback(() => {
    const { location } = history;
    history.push({
      pathname: location.pathname,
      hash: location.hash,
    });
  }, [history]);
};

export const useOpenRight = () => {
  const history = useMasterHistory();
  return useCallback(() => {
    const loc = history.location;
    const pageId = parsePageId(loc.pathname);
    const rightPageId = parseRightPageId(loc.search);
    const newPathname = loc.pathname.replace(pageId, rightPageId ?? '');
    history.push({
      pathname: newPathname,
    });
  }, [history]);
};

export const fixLocation = (location: Location) => {
  // Workaround for BUG of react-router with memory history
  if (typeof (location as any).location === 'object') {
    location = (location as any).location;
  }
  return location;
};

export const rightPage = {
  lastPageId: '',
};
