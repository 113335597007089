import { cx } from '@flowus/common/cx';
import type { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { useOpenModal } from 'src/common/components/next-modal';
import { UpdateUserInfo } from 'src/components/update-user-info';
import { segmentsToText } from 'src/editor/utils/editor';
import { useUpdatePDFFormat } from 'src/hooks/block/use-update-PDFFormat';
import { useReadonly } from 'src/hooks/page';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { getState } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import { ExportSetting } from '../component/annotation-list/export-setting';
import { downloadPdf } from '../service/download-pdf';
import { pdfPreviewCache } from '../service/storage';
import type { PDFAnnotation } from '../type';
import { ScrollMode, SpreadMode } from '../type';

interface Props {
  uuid?: string;
  closeModal: () => void;
  pdfViewer: PDFViewer;
}

export const MoreSetting: FC<Props> = ({ uuid, pdfViewer }) => {
  const updatePDFFormat = useUpdatePDFFormat();
  const openModal = useOpenModal();
  const readonly = useReadonly(uuid);
  const block = usePickBlock(uuid, ['updatedBy', 'updatedAt', 'data'], ['isByAI']);

  const userName = useUserName(block?.updatedBy ?? '');

  const OperatorMap = [
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcArrowPageFirst',
        title: '跳转第一页',
        onClick: () => (pdfViewer.currentPageNumber = 1),
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcArrowPageLast',
        title: '跳转最后一页',
        onClick: () => (pdfViewer.currentPageNumber = pdfViewer.pagesCount),
      },
    },
    { type: ListItemType.LINE },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuSinglePage',
        title: '单页',
        onClick: () => {
          pdfViewer.spreadMode = 0;
          if (uuid) {
            const format = pdfPreviewCache.get(uuid ?? '') ?? {};
            pdfPreviewCache.put(uuid, { ...format, spreadMode: SpreadMode.SINGLE });
          }
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuDoublePage',
        title: '双页',
        onClick: () => {
          pdfViewer.spreadMode = 1;
          if (uuid) {
            const format = pdfPreviewCache.get(uuid ?? '') ?? {};
            pdfPreviewCache.put(uuid, { ...format, spreadMode: SpreadMode.DOUBLE });
          }
        },
      },
    },
    { type: ListItemType.LINE },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuFlipHorizontal',
        title: '水平翻页',
        onClick: () => {
          pdfViewer.scrollMode = 1;
          if (uuid) {
            const format = pdfPreviewCache.get(uuid ?? '') ?? {};
            pdfPreviewCache.put(uuid, { ...format, scrollMode: ScrollMode.HORIZONTAL });
          }
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuFlipVertical',
        title: '垂直翻页',
        onClick: () => {
          pdfViewer.scrollMode = 0;
          if (uuid) {
            const format = pdfPreviewCache.get(uuid ?? '') ?? {};
            pdfPreviewCache.put(uuid, { ...format, scrollMode: ScrollMode.VERTICAL });
          }
        },
      },
    },
    { type: ListItemType.LINE },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuRotateClockwise',
        title: '顺时针',
        onClick: () => {
          pdfViewer.pagesRotation += 90;
          if (uuid && !readonly) {
            updatePDFFormat(uuid, { rotation: pdfViewer.pagesRotation });
          }
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      data: {
        icon: 'IcMenuRotateAnti',
        title: '逆时针',
        onClick: () => {
          pdfViewer.pagesRotation -= 90;
          if (uuid && !readonly) {
            updatePDFFormat(uuid, { rotation: pdfViewer.pagesRotation });
          }
        },
      },
    },
    {
      type: ListItemType.LINE,
      isHidden: !uuid || readonly,
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !uuid || readonly,
      data: {
        icon: 'IcExportPage',
        title: '导出标注',
        label: (
          <span className="bg-grey6 text-t4 text-grey3 ml-1 rounded-sm px-1.5 py-0.5">限免</span>
        ),
        onClick: () => {
          if (!uuid) return;

          openModal.modal({
            content: ({ onCloseModal }) => (
              <ExportSetting onCloseModal={onCloseModal} uuid={uuid} />
            ),
          });
        },
      },
    },
    {
      type: ListItemType.OPERATION,
      isHidden: !uuid || readonly,
      data: {
        icon: 'IcDownload',
        title: '下载含标注的 PDF',
        onClick: async () => {
          if (!uuid) return;
          bizTracker.event('pdf_edited_download', { file_id: uuid });

          const { blocks } = getState();
          const block = blocks[uuid];
          if (!block) return;

          const annotationMap: Record<string, PDFAnnotation> = {};
          block.subNodes.forEach((uuid) => {
            const block = blocks[uuid];
            if (!block) return;

            const { pdfAnnotation } = block.data;
            if (!pdfAnnotation) return;

            annotationMap[uuid] = { ...pdfAnnotation, uuid } as unknown as PDFAnnotation;
          });

          const title = segmentsToText(block.data.segments);
          const rotation = block.data.format?.pdfFormat?.rotation ?? 0;
          const imageUrl = await urlFetcher.fetchDownloadUrl({
            blockId: uuid,
            ossName: block.data.ossName ?? '',
          });

          void downloadPdf(imageUrl, title, rotation, annotationMap);
        },
      },
    },
    { type: ListItemType.LINE, isHidden: !uuid || readonly },
  ];

  return (
    <div className="next-modal w-[200px] py-[5px]">
      <ListView items={OperatorMap} />
      {!readonly && block && (
        <UpdateUserInfo name={userName} updatedAt={block.updatedAt} isByAI={block.data.isByAI} />
      )}
    </div>
  );
};

interface MoreSettingButtonProps {
  className?: string;
  pdfViewer: PDFViewer;
  uuid?: string;
}
export const MoreButton: FC<MoreSettingButtonProps> = ({ uuid, className, pdfViewer }) => {
  const openModal = useOpenModal();

  const openSetting = (event: React.MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom-start',
      offset: [-10, 6],
      content: ({ onCloseModal }) => (
        <MoreSetting uuid={uuid} closeModal={onCloseModal} pdfViewer={pdfViewer} />
      ),
    });
  };

  return (
    <button
      className={cx('flex items-center animate-hover p-1 justify-center', className)}
      onClick={openSetting}
    >
      <Icon size="middle" name={'IcMore'} />
    </button>
  );
};
