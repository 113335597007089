import { cx } from '@flowus/common/cx';
import { PermissionRole } from '@next-space/fe-api-idl';
import { useUnmount } from 'ahooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { batch } from 'react-redux';
import { useCloseModal, useIsExistModalId, useOpenModal } from 'src/common/components/next-modal';
import type { request } from 'src/common/request';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { LockedContext } from 'src/hooks/block/use-block-locked';
import { useGetBackLink } from 'src/hooks/block/use-get-backlink';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useReadonly } from 'src/hooks/page';
import { ID_HOVER_MENU } from 'src/hooks/page/use-dnd/helper';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { addBlock } from 'src/redux/managers/block/add';
import { useCloseBlockMenuList } from 'src/redux/managers/ui';
import { $appUiStateCache, setAppUiState, useFocusedInSyncBlockId } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { emitter } from '@flowus/common/utils/emitter';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { IsInRightContext } from 'src/utils/right-utils';
import { SyncMenu } from '../../component/sync-menu';
import type { BlockElement } from '../core/type';
import { BlockDrop } from './dnd/block-drop';
import { SyncContext } from './sync-block-context';

/**
 * 同步块复制粘贴逻辑
 * https://flowus.cn/4344e935-df3e-40dc-a00a-2e1ca379e110
 * 影子块权限低于页面权限，如果页面只有可读状态，那影子同步块也必然不可编辑
 */
export const SyncBlockElement: BlockElement = ({
  id: syncBlockId,
  ownerBlockId: syncReferenceBlockId,
  children,
}) => {
  // 上面这么写为了让你不用猜这两个id分别是啥，一个是syncBlockId，另一个是syncReferenceBlockId（如果展示的block是源同步块，后者为空）
  const divRef = useRef<HTMLDivElement>(null);
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const menuId = useRef<any>();
  const blockId = syncReferenceBlockId || syncBlockId; // 实际id，用在对block的操作上，比如选中，data-block-id等赋值上
  const readonly = useReadonly(blockId);
  const { role: syncRole } = usePermissions(syncBlockId);
  const isFocused = useFocusedInSyncBlockId() === blockId;
  const closeBlockMenuList = useCloseBlockMenuList();
  const [backLinks, setBackLinks] = useState<
    Awaited<ReturnType<typeof request.editor.getDocBacklinks>>['results']
  >([]);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const timer = useRef<any>();
  const rightHistory = useContext(IsInRightContext);

  // 只选中了自己
  const onlySelected = useObservableStore(
    ({ ui: { selectedBlocks } }) => {
      if (!blockId) return false;
      return (
        selectedBlocks.length === 1 && selectedBlocks.findIndex((v) => v.blockId === blockId) !== -1
      );
    },
    [blockId],
    { obsSelectBlocks: [{ blockId }] }
  );
  const transition = useTransaction();
  const isEmpty = useMemo(() => {
    if (children === undefined) return true;
    if (Array.isArray(children)) {
      return children.length === 0;
    }
    return false;
  }, [children]);

  const block = usePickBlock(syncBlockId, []);
  const focusEditableAt = useFocusEditableByBlockId();
  const isHistoryModalOpened = useIsExistModalId(Modals.DOC_HISTORY_MODAL_ID);
  const showSyncMenu = !isHistoryModalOpened && (isFocused || onlySelected) && !readonly;
  const hasBlock = Boolean(block);
  const getBackLink = useGetBackLink();

  useEffect(() => {
    if (!hasBlock) return;
    if (judgeSharePage()) return;
    void getBackLink(blockId, syncBlockId).then((res) => {
      if (!res) return;
      batch(() => {
        setBackLinks(res.result);
        setTotalPageCount(res.pageCount);
      });
      if (syncReferenceBlockId) {
        emitter.emit('syncBlock', {
          uuid: syncReferenceBlockId,
          pageId: getOwnerPage(syncBlockId) ?? '',
          spaceId: getCurrentSpaceId(),
        });
      }
    });
  }, [blockId, syncBlockId, syncReferenceBlockId, hasBlock, getBackLink]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      openModal.closeModal('dropDownContent-sync');
    };
  }, [openModal]);

  useEffect(() => {
    const monitor = (info: { uuid: string; pageId: string; spaceId: string }) => {
      const { uuid, pageId, spaceId } = info;
      if (backLinks.length === 0) return;
      const result = backLinks.some((v) => v.pageId === pageId && v.uuid !== syncBlockId);
      if (result) return;
      setBackLinks([
        ...backLinks,
        {
          uuid,
          pageId,
          spaceId,
        },
      ]);
      setTotalPageCount((v) => v + 1);
    };
    // 监听新增的(主要是同一个页面的),删掉的就先不管了
    emitter.on('syncBlock', monitor);
    return () => {
      emitter.off('syncBlock', monitor);
    };
  }, [backLinks, syncBlockId]);

  useEffect(() => {
    if (judgeSharePage()) return;
    const divContainer = divRef.current;
    if (!divContainer) return;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      if (showSyncMenu) {
        if (menuId.current) return;
        menuId.current = openModal.dropdown({
          id: 'dropDownContent-sync',
          autoClose: false,
          mask: false,
          flip: false,
          placement: 'top-end',
          popcorn: divContainer,
          closeBeforeCallBack: () => {
            menuId.current = undefined;
          },
          content({ onCloseModal }) {
            return (
              <IsInRightContext.Provider value={rightHistory}>
                <SyncMenu
                  updateSyncInfo={(result, totalPage) => {
                    batch(() => {
                      setBackLinks(result);
                      // total返回的是影子同步块的个数，+1是包含同步块本身,减去duplicateCount就是去重之后的page数
                      setTotalPageCount(totalPage);
                    });
                  }}
                  syncBlockId={syncBlockId}
                  closeSyncMenu={onCloseModal}
                  backLinks={backLinks}
                  blockId={blockId}
                  total={totalPageCount}
                />
              </IsInRightContext.Provider>
            );
          },
        }).modalId;
      } else if (menuId.current) {
        closeModal(menuId.current, { noAnimation: true });
      }
    }, 50);
  }, [
    closeBlockMenuList,
    closeModal,
    syncBlockId,
    openModal,
    showSyncMenu,
    blockId,
    backLinks,
    totalPageCount,
    rightHistory,
  ]);

  useMonitorFocused(divRef, blockId);
  useUnmount(() => {
    if (menuId.current) {
      closeModal(menuId.current);
    }
  });

  if (!block) return null; // 有可能没有权限导致没有block，可以直接返回null
  return (
    <BlockDrop
      id={blockId}
      className="py-1"
      blockRef={divRef}
      data-sync-count={`${totalPageCount}`}
      data-sync-container-id={blockId}
      bindId={!isFocused} // focused状态下
    >
      <div
        className={cx(
          `px-[1px] rounded border border-transparent min-h-[36px]  `,
          { 'border-red border-opacity-70': onlySelected && !readonly },
          {
            'hover:border-red hover:border-opacity-40 focus-within:!border-red focus-within:!border-opacity-70':
              !readonly,
          },
          { 'flex items-center': isEmpty }
        )}
      >
        {/* 点击或拖拽添加内容，然后粘贴到其他页面进行同步  目前不支持拖拽 */}
        {isEmpty &&
          !readonly &&
          (syncRole === PermissionRole.EDITOR || syncRole === PermissionRole.WRITER) && (
            <BlockDrop dropInChild className="my-1" bindId={false} id={blockId}>
              <div
                className="cursor-pointer text-grey4"
                onClick={() => {
                  transition(() => {
                    const newBlockId = addBlock({}, { parentId: syncBlockId, last: true });
                    focusEditableAt(newBlockId, 0, undefined, { syncId: blockId });
                  });
                }}
              >
                点击或拖拽添加内容，然后粘贴到其他页面进行同步
              </div>
            </BlockDrop>
          )}
        {/* 如果当前block只读，同步块内的block即使有权限也还是只读，这里直接给个锁吧 */}
        <LockedContext.Provider value={readonly}>
          <SyncContext.Provider value={blockId}>{children}</SyncContext.Provider>
        </LockedContext.Provider>
      </div>
    </BlockDrop>
  );
};
/** 监听光标是否在syncBlock里面 */
const useMonitorFocused = (divRef: React.RefObject<HTMLDivElement>, blockId: string) => {
  useEffect(() => {
    const divContainer = divRef.current;
    const mouseDown = (e: MouseEvent) => {
      if (e.target instanceof Element) {
        if (e.target.closest('[data-no-cancel-selected]')) return;
        if (e.target.id === ID_HOVER_MENU) return;
        const syncContainer = e.target.closest('[data-sync-container-id]');
        if (syncContainer instanceof HTMLElement) {
          if (syncContainer.dataset.syncContainerId === blockId) {
            return;
          }
        }

        // 如果点击了外面，就需要置空
        if ($appUiStateCache.$focusedInSyncBlockId === blockId) {
          setAppUiState({ $focusedInSyncBlockId: '' });
        }
        globalListenerHelper.removeEventListener('mousedown', mouseDown);
      }
    };
    const focusin = () => {
      if ($appUiStateCache.$focusedInSyncBlockId !== blockId) {
        setAppUiState({ $focusedInSyncBlockId: blockId });
        emitter.emit('removeHoverMenu');
      }
      globalListenerHelper.addEventListener('mousedown', mouseDown);
    };

    divContainer?.addEventListener('focusin', focusin);

    return () => {
      divContainer?.removeEventListener('focusin', focusin);
      globalListenerHelper.removeEventListener('mousedown', mouseDown);
    };
  }, [blockId, divRef]);
};
