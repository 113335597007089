export interface KeydownOption {
  arrowKey?: boolean;
  tabKey?: boolean;
  backspaceKey?: boolean;
  enterKey?: boolean;
  bracketRightKey?: boolean;
  minusKey?: boolean;
  spaceKey?: boolean;
  shortcutKey?: boolean;
  slashKey?: boolean;
  editorInputKey?: boolean; // 包含[[ @等快捷键
}

export const DEFAULT_KEYDOWN_OPTION: KeydownOption = {
  arrowKey: true,
  tabKey: true,
  backspaceKey: true,
  enterKey: true,
  bracketRightKey: true,
  minusKey: true,
  spaceKey: true,
  shortcutKey: true,
  slashKey: true,
  editorInputKey: true,
};

/** 仅方向键 */
export const ARROW_KEY_OPTION: KeydownOption = {
  arrowKey: true,
};

export const INLINE_KEYDOWN_OPTION: KeydownOption = {
  enterKey: true,
  shortcutKey: true,
  editorInputKey: true,
};
/** 分享可评论 */
export const SHARE_COMMENT_KEYDOWN_OPTION: KeydownOption = {
  enterKey: true,
  shortcutKey: true,
  editorInputKey: false,
};

export const CAPTION_KEYDOWN_OPTION: KeydownOption = {
  arrowKey: true,
  enterKey: true,
  shortcutKey: true,
  backspaceKey: true,
  editorInputKey: true,
};

export const DESCRIPTION_KEYDOWN_OPTION: KeydownOption = {
  editorInputKey: false,
  shortcutKey: true,
};

export const FROM_KEYDOWN_OPTION: KeydownOption = {
  tabKey: true,
  bracketRightKey: true,
  minusKey: true,
  spaceKey: true,
  shortcutKey: true,
};
