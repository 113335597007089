import type { IEditorModel } from '@next-space/fe-inlined';
import { mapValues, omit, values } from 'lodash-es';
import type { RefObject } from 'react';
import type { ShortcutToolBarType } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-toolbar';
import type { SegmentType } from 'src/redux/types';

export interface ToolbarProps {
  uuid: string;
  model: IEditorModel;
  containerRef: RefObject<HTMLDivElement>;
  comments?: boolean;
  segmentType: SegmentType;
}

export interface ToolbarContentProps extends ToolbarProps {
  shortcutToolBar: ShortcutToolBarType;
}

export enum ToolbarBtnType {
  AI = 'AI',
  Turn = 'Turn',
  LinkPage = 'LinkPage',
  Comment = 'Comment',
  Bold = 'Bold',
  Italic = 'Italic',
  Underline = 'Underline',
  Delete = 'Delete',
  Code = 'Code',
  Equation = 'Equation',
  Url = 'Url',
  Color = 'Color',
  More = 'More',
}

export type ToolbarFnType = ToolbarBtnType;

const toolbarBtnTypeValues = values(ToolbarBtnType);

export const toolbarBaseBtns: ToolbarShortcutDTO = omit(
  mapValues(ToolbarBtnType, (id) => ({
    id,
    index: toolbarBtnTypeValues.findIndex((it) => it === id),
    type: 'base',
    fixed: true,
  })),
  [ToolbarBtnType.More]
);

export type ToolbarShortcutDTO = Record<
  string,
  {
    type: string;
    id: string;
    index: number;
    fixed: boolean;
    title?: string;
  }
>;

export interface RenderProps {
  showBorder?: boolean;
  id?: string;
}

export const toolbarId = 'toolbar';

export const toolbarPrefixId = `${toolbarId}-btn-`;
